<template>
  <div
    :id="'offer-' + offerData.type"
    :data-cy="'offer-' + offerData.type"
    class="offer-box relative cursor-pointer"
    :style="{
      background: 'url(' + pathImages + offerPath + offerData.content + '.avif) center no-repeat',
      backgroundSize: 'contain',
    }"
    @click="showOffer()"
  >
    <main
      class="offer-box-main relative text-36 text-texts-standard-dark font-bold flex justify-center"
    >
      <div class="offer-box-main-title flexing">
        <p
          class="offer-box-main-text text-center"
          v-html="$t('premiumGroup.wsm_chain_of_legends_about')"
        ></p>
      </div>
    </main>
    <footer class="offer-box-footer flex items-center absolute w-full flex-col justify-around">
      <app-timer
        v-if="showFooterTimer"
        icon="refresh-md"
        :time="offerData.remaining_time"
        @countdown-action="$emit('reloadData')"
      />
      <app-button
        btn-id="chain-offer-button"
        btn-type="secondary"
        :btn-text="$t('button.detail')"
        btn-size="md"
      />
    </footer>
  </div>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { OfferChainData } from '@/interfaces/premium/Offer'
import { GameVersion } from '@/enums/GameVersion'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'OffersChain',
  props: {
    offerData: {
      type: Object as PropType<Nullable<OfferChainData>>,
      default: () => null,
    },
  },
  emits: ['reloadData'],
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    showFooterTimer(): number {
      return this.offerData.remaining_time
    },
    offerPath(): string {
      const gameVersionPrefix = this.$isSsm ? GameVersion.SSM : GameVersion.WSM
      const typePostfix = 'halloween_' // zmenit na prazdny string ak chceme klasicky legendary chain a nie je stale poriesene backendovo
      return `premium/offers/offer-${gameVersionPrefix}_legendary_chain_offer_${typePostfix}`
    },
  },
  methods: {
    ...mapActions(usePremiumStore, {
      hideOfferDetail: 'hideOfferDetail',
    }),
    showOffer(): void {
      this.hideOfferDetail()
      this.$router.push({ name: this.$getWebView('PremiumChain') })
    },
  },
})
</script>

<style lang="scss" scoped>
.offer-box {
  height: 44.875rem;
  width: 78.75rem;
  margin-right: 1.5rem;
  position: relative;

  &-main {
    height: 31.5rem;

    &-title {
      text-align: center;
      position: absolute;
      top: 7rem;

      width: 90%;
      height: 2.875rem;
      background-image: linear-gradient(
        90deg,
        rgba(222, 194, 108, 0) 0%,
        rgba(237, 191, 71, 0.8) 25%,
        rgba(247, 212, 101, 0.8) 50%,
        rgba(237, 191, 71, 0.8) 75%,
        rgba(222, 194, 108, 0) 100%
      );
      border: 0.188rem solid;
      border-left: 0;
      border-image-slice: 1;
      border-image-source: linear-gradient(
        90deg,
        rgba(246, 237, 176, 0) 0%,
        rgba(249, 229, 133, 1) 25%,
        rgba(249, 229, 133, 1) 75%,
        rgba(246, 237, 176, 0) 100%
      );
    }
  }

  &-footer {
    bottom: 0.75rem;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 11.6rem;
  }

  .duration-timer {
    width: 33.125rem;
    height: 3.125rem;
    position: absolute;
    bottom: 5rem;
    background: linear-gradient(to right, transparent 5%, rgba(188, 27, 27, 1), transparent 95%);
  }
}
</style>
