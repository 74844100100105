import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const clubCarsRightToParkingCheckpoints: CheckpointsObjectInterface = {
  key: 'clubCarsRightToParkingCheckpoints',
  data: [
    {
      x: 3021,
      y: 1266,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsRightToParkingAnimations1',
        },
      },
    },
    {
      x: 3067.5,
      y: 1361,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsRightToParkingAnimations2',
        },
      },
    },
    {
      x: 2990,
      y: 1539,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsRightToParkingAnimations3',
        },
      },
    },
    {
      x: 2574,
      y: 1484,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsRightToParkingAnimations4',
        },
      },
    },
  ],
}
