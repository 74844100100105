<template>
  <div
    class="flex flex-col justify-center items-center"
    :class="$isMobile() && 'luckywheel-spins-wrapper-mobile'"
  >
    <section class="total-spins flex justify-center" :class="$isMobile() && 'total-spins-mobile'">
      <h1 class="text-50 uppercase font-bold italic gradient-text-gold">
        {{ $te('freeSpinCount') }}
      </h1>
      <section class="total-spins-info flexing text-60 font-bold gradient-text-blue mr-2">
        <p>{{ freeSpins }}</p>
        <div class="icon-events-EventsLuckyWheel-light icon-styler"></div>
      </section>
    </section>
    <div v-if="!eventCollectionActive" class="flexing">
      <app-timer class="timer" :time="refreshIn" @countdown-action="callRefresh" />
      <p class="text-60 font-bold gradient-text-blue">+{{ dailySpins }}</p>
      <div class="icon-events-EventsLuckyWheel-light icon-styler"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LuckyWheelSpins',
  props: {
    freeSpins: {
      type: Number,
      required: true,
    },
    refreshIn: {
      type: Number,
      required: true,
    },
    dailySpins: {
      type: Number,
      default: 1,
    },
  },
  emits: ['refreshSpin'],
  computed: {
    ...mapState(useEventInfoStore, {
      eventCollectionActive: 'getEventCollectionActive',
    }),
  },
  methods: {
    callRefresh() {
      window.location.reload()
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
@import '@/assets/styles/components/icons/events-icons.scss';

.total-spins {
  flex-direction: column;
  width: 49rem;
  height: 10.375rem;
  @include background(url($path-events + 'luckyWheel/lucky-wheel-spins.avif'), contain);
  position: relative;
  padding-right: 3rem;
  margin-bottom: 1rem;

  & h1 {
    background-image: linear-gradient(to top, #ffd562 13%, #ffe49b 100%);
    background-clip: text;
    text-shadow: none;
    color: transparent;
    margin-top: -0.5rem;
  }

  &-info {
    margin-top: -1rem;
  }
}

.icon-styler {
  margin-right: 3rem;
  margin-bottom: 0.5rem;
}

.luckywheel-spins-wrapper {
  width: 47rem;
}

.total-spins-mobile {
  width: 100% !important;
}
</style>
