<template>
  <div
    v-if="!hideForPages.includes($route.name.toString())"
    class="header w-full flexing absolute safe-area-x"
    :class="[
      screenOrMobileClass,
      mobileHeader,
      setMobileZIndex,
      {
        'header--parameterized': showParameters,
        'event-page': $route.path.toString().includes('events'),
      },
    ]"
  >
    <div
      v-show="modalWebOverlay"
      :class="{ 'tutorial-overlay-web': !$isMobile() && tutorialStep }"
    />
    <app-control-icon
      v-show="!showChatAndMenuIcons"
      :class="$isMobile() ? 'back-button-icon--mobile left-0' : 'left-3'"
      class="absolute back-button-icon"
      control="back"
    />

    <div v-if="showParameters" class="header-box h-full flexing">
      <div
        v-for="(param, index) in headerData"
        :key="index"
        class="box-first"
        :class="{ highlighted: isBonusActivated(param) }"
        @click="showPopup(param.parameter_name)"
      >
        <tippy class="mx-auto" theme="WSM" max-width="50rem">
          <router-link
            :to="{ name: toUrl(param.parameter_name) }"
            class="flex router"
            :data-event-badge-text="param?.free_redeem ? $te('free') : undefined"
          >
            <app-main-icon
              v-if="param.parameter_name"
              :icon-size="56"
              :icon-name="param.parameter_name"
              class="-ml-10 icon-header relative"
              :hide-tooltip="true"
            >
              <div v-if="iconPlus(param.parameter_name)" class="icon-plus" />
              <app-icon v-if="param.next_refresh" icon-name="time" class="time-icon" />
            </app-main-icon>

            <animated-number :param="param" class="pl-4 text-texts-standard-default text-36" />
          </router-link>

          <template #content>
            <vue-countdown
              v-if="
                param.next_refresh && param.next_refresh >= 0 && (isChildTimer || isParentTimer)
              "
              v-slot="{ days, hours, minutes, seconds }"
              :time="param.next_refresh * 1000"
            >
              <div class="flexing">
                {{ handleTickText(param) }}
                <app-main-icon :icon-name="param.parameter_name" :icon-size="48" />
                <span>in</span>
                <app-icon class="tooltip-text-icon-time" :icon-size="32" :icon-name="'time'" />
                {{ handleTickValue(param, days, hours, minutes, seconds) }}
              </div>
            </vue-countdown>

            <header-money-tooltip
              v-else-if="param.parameter_name === 'money'"
              :value="param.value"
            />

            <div v-else class="flex items-center">
              <p :class="{ 'mr-2': param.value >= 100000 }">
                {{ $t('map.' + param.parameter_name) + (param.value >= 100000 ? ':' : '') }}
              </p>
              <p v-show="param.value >= 100000" class="mr-5">
                {{ $filters.$formatNumber(param.value) }}
              </p>
            </div>
          </template>
        </tippy>
      </div>
    </div>
    <div class="flexing absolute" :class="$isMobile() ? 'right-buttons-mobile right-0' : 'right-3'">
      <tippy
        theme="WSM1"
        placement="bottom"
        :interactive="true"
        trigger="click"
        max-width="24.625rem"
      >
        <div
          v-show="!$isMobile() && !isWebModalHeader"
          class="flex mr-8 items-center icon-zoom"
          style="z-index: 2"
          @click="isZoomControlsOpen = true"
        />
        <template #content>
          <zoom-controls />
        </template>
      </tippy>

      <div
        v-show="!$isMobile() && !isWebModalHeader"
        class="flex mr-8 items-center icon-fullscreen"
        @click="toggleFullScreen"
      />

      <app-control-icon
        v-show="hasMechanic(MECHANIC_CLUB) && !isWebModalHeader && !tutorialStep"
        class="flex mr-4 items-center chat-notification relative"
        :class="{ 'chat-notification--mobile': $isMobile() }"
        style="z-index: 2"
        control="chat"
        @click="openChat"
      >
        <app-notification v-if="newChatMessage" class="notification-icon-24" />
      </app-control-icon>

      <app-control-icon
        v-show="showChatAndMenuIcons"
        class="flex items-center hamburger-menu-icon"
        :class="{ 'hamburger-menu-icon--mobile': $isMobile() }"
        control="menu"
        @click="$emit('showHamburgerMenu')"
      >
        <app-notification
          v-if="getUnreadMailCount + unreadNewsCount + getAccountNotificationsCount > 0"
          type="exclamationLabel"
          class="notification-icon-24"
        />
      </app-control-icon>
      <arrow-animation
        v-if="$isMobile()"
        :data-id="$isMobile() ? 'control-icon-home' : 'control-icon-close'"
        :tutorial="actualStageData && tutorialStep"
        :border="$isMobile() ? 'rounded' : 'box'"
        position="left-bottom"
        :add-class="$isMobile() ? 'home-button-arrow flexing' : 'close-button-arrow'"
      >
        <app-control-icon
          v-if="switchMenu() === 'home'"
          :icon-id="$isMobile() ? 'control-icon-home' : 'control-icon-close'"
          :class="[
            switchMenu() === 'home' ? header_rs : '',
            actualStageData
              ? 'home-control-icon-tutorial-mobile'
              : 'home-control-icon home-control-icon--mobile',
            { 'pointer-events-none': isTutorial && !tutorialStep },
          ]"
          :control="$isMobile() ? 'home' : 'close'"
        />
      </arrow-animation>
      <arrow-animation
        v-else-if="modalWebIcon"
        :tutorial="actualStageData && tutorialStep"
        :border="$isMobile() ? 'rounded' : 'box'"
        :data-id="$isMobile() ? 'control-icon-home' : 'control-icon-close'"
        position="left-bottom"
        :add-class="$isMobile() ? 'home-button-arrow' : 'close-button-arrow'"
      >
        <app-control-icon
          v-if="switchMenu() === 'home'"
          :icon-id="$isMobile() ? 'control-icon-home' : 'control-icon-close'"
          :class="[
            switchMenu() === 'home' ? header_rs : '',
            actualStageData ? 'home-control-icon-tutorial' : 'home-control-icon',
          ]"
          :control="$isMobile() ? 'home' : 'close'"
        />
      </arrow-animation>
    </div>

    <parameter-popup
      v-if="isPopupOpen == true"
      :parameter="parameter"
      @close-popup="closePopup()"
    />
    <popup-window
      v-if="isTrainingPopupOpen"
      popup-type="info"
      :popup-title="$t('trainingHall.aboutTrainingHeaderInfo')"
      @close="isTrainingPopupOpen = false"
    >
      <training-popup />
    </popup-window>
  </div>
</template>

<script lang="ts">
import AnimatedNumber from '@/components/AnimatedNumber.vue'
import ArrowAnimation from '@/components/ArrowAnimation.vue'
import ZoomControls from '@/components/GlobalComponents/ZoomControls.vue'
import HeaderMoneyTooltip from '@/components/Header/HeaderMoneyTooltip.vue'
import ParameterPopup from '@/components/Popup/Parameter/ParameterPopup.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import TrainingPopup from '@/components/Training/TrainingPopup.vue'
import {
  ENERGY,
  STARTS,
  MECHANIC_CLUB,
  UNIVERSAL_TRAINING_POINTS,
  userHeaderEndpoint,
  GAME_PASS_ENERGY_CAP,
  GAME_PASS_STARTS_CAP,
} from '@/globalVariables'
import { convertToSeconds, formatTime, playSound, toggleFullScreen } from '@/helpers'
import { useChatMessageStore } from '@/store/pinia/clubs/chatMessagesStore'
import { useMailStore } from '@/store/pinia/mailStore'
import { useNewsStore } from '@/store/pinia/newsStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useGamePassStore } from '@/store/pinia/gamePassStore'

import moment from 'moment'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type {
  HeaderParametersResponse,
  HeaderParameter,
} from '@/interfaces/responses/header/HeaderResponses'
import { useAccountStore } from '@/store/pinia/accountStore'

type MomentType = moment.Moment

interface DateParam {
  finishTime: MomentType
  max_value: number
  next_refresh: number
  parameter_id: number
  parameter_name: string
  value: number
  refresh_amount?: number
}

interface ComponentData {
  MECHANIC_CLUB: typeof MECHANIC_CLUB
  parameter: string
  isChildTimer: boolean
  isPopupOpen: boolean
  isTrainingPopupOpen: boolean
  hideForPages: string[]
  hideParametersForPages: string[]
  updatingParameter: number[]
  isZoomControlsOpen: boolean
  header_rs: string
}

export default defineComponent({
  name: 'HeaderComponent',
  components: {
    ParameterPopup,
    ArrowAnimation,
    PopupWindow,
    TrainingPopup,
    AnimatedNumber,
    HeaderMoneyTooltip,
    ZoomControls,
  },
  props: {
    isWebModalHeader: {
      type: Boolean,
      default: false,
    },
    modalWebOverlay: {
      type: Boolean,
      default: false,
    },
    modalWebIcon: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['showHamburgerMenu'],
  data(): ComponentData {
    return {
      MECHANIC_CLUB,
      parameter: '',
      isChildTimer: false,
      isPopupOpen: false,
      isTrainingPopupOpen: false,
      hideForPages: [
        'ChooseDisciplineTutorial',
        'ChooseDisciplineWeb',
        'CreateProfileWeb',
        'CreateProfileTutorial',
        'CreateProfileTutorialWeb',
        'ArenaReport',
        'ArenaReportTutorial',
        'SeasonsEnd',
        'SeasonsEndWeb',
      ],
      hideParametersForPages: ['CrossAccountLinkingWeb'],
      updatingParameter: [],
      isZoomControlsOpen: false,
      header_rs: '',
    }
  },
  computed: {
    ...mapState(useMailStore, ['getUnreadMailCount']),
    ...mapState(useChatMessageStore, {
      newChatMessage: 'isNewChatMessage',
    }),
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useNewsStore, {
      unreadNewsCount: 'getUnreadNewsCount',
    }),
    ...mapState(useAccountStore, ['hasVerifiedAccount']),
    ...mapState(useResponseTaskStore, {
      headerData: 'getHeaderParams',
      allowedParameters: 'getAllowedParameters',
      isParentTimer: 'getIsParentTimer',
      isFirstLoad: 'getFirstLoad',
      hasMechanic: 'hasMechanic',
    }),
    getAccountNotificationsCount(): number {
      return !this.hasVerifiedAccount ? 1 : 0
    },
    ...mapState(useGamePassStore, ['isEffectActive']),
    showParameters(): boolean {
      if (this.hideParametersForPages.includes(this.$route.name.toString())) return false
      return this.headerData.length === this.allowedParameters.length
    },
    showChatAndMenuIcons(): boolean {
      const mapScreens = ['LayoutView', 'ClubsMap', 'ClubsMapTutorial', 'LayoutViewTutorial']
      return (
        ((!this.isWebModalHeader && !this.$isMobile()) ||
          mapScreens.includes(this.$route.name.toString())) &&
        !this.tutorialStep
      )
    },
    tutorialStep(): boolean {
      const closeWindowSteps = [
        'closeTrainingHall',
        'closeArenaWindow',
        'closeEquipmentShop',
        'closeArenaAfterSimulations',
        'closeGrandPrize',
        'focusDisciplineBranchDetailCloseButton',
        'clickCloseBranchDetail',
        'clickClubhouseCloseButton',
        'focusCareerHomeButtonCareer',
      ]

      return this.actualStageData && closeWindowSteps.includes(this.actualStageData.name)
    },
    screenOrMobileClass(): string {
      return this.isWebModalHeader ? 'header-screen' : 'header-maps'
    },
    mobileHeader(): string {
      if (this.$isMobile() && this.$route.name !== 'LayoutView') {
        return 'mobile-header'
      }
      return ''
    },
    setMobileZIndex(): string {
      if (!this.actualStageData && this.$isMobile()) return 'mobile-zindex'

      return ''
    },
  },
  created(): void {
    if (this.isFirstLoad) {
      this.setParameters()
      this.setFirstLoad()
    }
    if (this.$route.path !== '/' && !this.$isMobile() && this.isParentTimer) {
      this.isChildTimer = true
      this.switchParentTimer()
    }
  },
  beforeUnmount(): void {
    if (!this.$isMobile()) {
      this.switchParentTimer()
    }
  },
  methods: {
    ...mapActions(useResponseTaskStore, [
      'setFirstLoad',
      'switchParentTimer',
      'updateParamNextRefresh',
      'setTasks',
      'updateParameter',
    ]),
    toggleFullScreen,
    ...mapActions(useChatMessageStore, ['setNewNotification']),
    iconPlus(icon: string): boolean {
      return ['energy', 'starts', 'gems'].includes(icon)
    },
    toUrl(path: string): string {
      if (path === 'training_points') {
        return this.$getWebView('TrainingDisciplines')
      } else if (path === 'gems') {
        return this.$getWebView('PremiumGems')
      } else if (path === 'money') {
        return ''
      }
    },
    showPopup(type: string): void {
      playSound('click-item-dialog-menu')
      if (type == null) this.isPopupOpen = false
      this.parameter = type

      switch (type) {
        case 'energy':
        case 'starts':
          this.isPopupOpen = true
          break

        case UNIVERSAL_TRAINING_POINTS:
          this.isTrainingPopupOpen = true
          break

        default:
          return
      }
    },
    switchMenu(): string {
      if (this.showChatAndMenuIcons) {
        return ''
      } else {
        // TODO: condition part on line 210 =>  || this.tutorialStep .. should be removed after tutorial web version will be finished
        this.header_rs = this.$isMobile() || this.tutorialStep ? 'screen-6:right-28' : ''
        return 'home'
      }
    },
    handleTickValue(
      param: DateParam,
      days: number,
      hours: number,
      minutes: number,
      seconds: number,
    ): string {
      const sec = convertToSeconds(days, hours, minutes) + seconds

      if (sec < param.next_refresh && !this.updatingParameter.includes(param.parameter_id)) {
        this.updatingParameter.push(param.parameter_id)
        this.updateParamNextRefresh({
          paramName: param.parameter_name,
          value: sec,
        })
        if (param.next_refresh === 0) this.validateAgainstNow(param)
        this.updatingParameter = this.updatingParameter.filter(
          (updatingParam: number): boolean => updatingParam !== param.parameter_id,
        )
      }
      const finalTime = formatTime(days, hours, minutes, seconds)

      return finalTime
    },
    handleTickText(param: DateParam): string {
      const amountSign = param?.refresh_amount > 0 ? '+' : ''
      return `${amountSign}${param?.refresh_amount.toString()}`
    },
    validateAgainstNow(param: DateParam): void {
      const differenceInMs = param.finishTime.diff(moment(), 'milliseconds')
      if (differenceInMs <= 0) {
        this.updateParameter(param.parameter_id)
        return
      }

      const nextRefreshNewValue = differenceInMs <= 2000 ? 2 : Math.ceil(differenceInMs / 1000)
      this.updateParamNextRefresh({
        paramName: param.parameter_name,
        value: nextRefreshNewValue,
      })
    },
    async setParameters(): Promise<void> {
      const parameters = await this.$axios.get<{}, HeaderParametersResponse>(userHeaderEndpoint)

      const paramsToBeRemoved = []
      for (const entry of Object.entries(parameters)) {
        const [key, value]: [string, HeaderParameter] = entry
        if (this.allowedParameters.includes(value.parameter_name) && value.next_refresh <= 0) {
          paramsToBeRemoved.push(key)
          this.updateParameter(value.parameter_id)
        }
        if (value?.next_refresh) value.finishTime = moment().add(value.next_refresh, 'seconds')
      }

      paramsToBeRemoved.forEach((prop: string): boolean => delete parameters[prop])
      this.setTasks(parameters)
    },
    closePopup(): void {
      this.isPopupOpen = false
    },
    openChat(): void {
      this.setNewNotification(false)

      if (this.$route.name === this.$getWebView('ClubsChat')) {
        return
      } else {
        this.$router.push({
          name: this.$getWebView('ClubsChat'),
          query: { redirectFrom: this.$route.name.toString() },
        })
      }
    },
    isBonusActivated(param: HeaderParameter): boolean {
      if (param.parameter_name === ENERGY && this.isEffectActive(GAME_PASS_ENERGY_CAP)) return true
      if (param.parameter_name === STARTS && this.isEffectActive(GAME_PASS_STARTS_CAP)) return true
      return false
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/components/header.scss';

.tutorial-overlay-web {
  position: absolute;
  top: 0;
  width: 122rem;
  height: 68rem;
  background: rgba(0, 0, 0, 0.6);
  z-index: 5;
}

.chat-notification {
  z-index: 2;

  &.chat-notification--mobile {
    margin: 0;
    padding-left: $nav-item-touch-sensitivity-padding;
    padding-right: $nav-item-touch-sensitivity-padding;
  }

  .notification-icon__wrapper {
    top: 0rem;
    right: 0.2rem;
  }
}

.hamburger-menu-icon {
  .notification-icon__wrapper {
    top: 0rem;
    right: 0rem;
  }
}

.icon-zoom {
  width: 3.5rem;
  height: 3.5rem;
  cursor: pointer;
}

.icon-fullscreen {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}

.home-control-icon-tutorial {
  position: absolute;
  right: 0;

  &-mobile {
    width: auto;
  }
}

.hamburger-menu-icon {
  &.hamburger-menu-icon--mobile {
    width: auto;
    padding-left: $nav-item-touch-sensitivity-padding;
    padding-right: $nav-item-touch-sensitivity-padding;
  }
}

.home-control-icon {
  width: auto;

  &.home-control-icon--mobile {
    width: auto;
    padding-left: $nav-item-touch-sensitivity-padding;
    padding-right: $nav-item-touch-sensitivity-padding;
  }
}

.back-button-icon--mobile {
  justify-content: flex-start;
  padding-left: $nav-item-touch-sensitivity-padding;
}

.right-buttons-mobile {
  right: $default-margin;
}

.page-title {
  left: 9.25rem;
}

.header-box .router[data-event-badge-text] {
  @apply relative;

  &::after {
    @apply text-22 text-texts-standard-default font-bold uppercase absolute -bottom-7 left-8 z-1;
    content: attr(data-event-badge-text);
    background-image: linear-gradient(to top, #0c9e0c, #2bc82f);

    @if $isWsm {
      @apply pl-2 pr-3 italic;
      clip-path: polygon(0% 0%, 100% 0%, 92% 100%, 0% 100%);
      text-shadow: 0.6px 0.8px 0.9px rgba(0, 0, 0, 0.51);
    }

    @if $isSsm {
      @apply px-2;
    }
  }
}
</style>
