import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const carsRightToBottomTunelCheckpoints: CheckpointsObjectInterface = {
  key: 'carsRightToBottomTunelCheckpoints',
  data: [
    {
      x: 3435,
      y: 677,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.PauseAction,
        action: {
          duration: 2000,
        },
      },
    },
    {
      x: 3441,
      y: 669,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightToBottomTunelAnimations1',
        },
      },
    },
    {
      x: 3512,
      y: 905,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightToBottomTunelAnimations2',
        },
      },
    },
    {
      x: 3553,
      y: 1148,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightToBottomTunelAnimations3',
        },
      },
    },
    {
      x: 3488,
      y: 1243,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightToBottomTunelAnimations4',
        },
      },
    },
    {
      x: 3351,
      y: 1260,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightToBottomTunelAnimations5',
        },
      },
    },
    {
      x: 3018,
      y: 1356,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightToBottomTunelAnimations6',
        },
      },
    },
    {
      x: 2921,
      y: 1444,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightToBottomTunelAnimations7',
        },
      },
    },
    {
      x: 2868,
      y: 1469,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightToBottomTunelAnimations8',
        },
      },
    },
    {
      x: 2785,
      y: 1476,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightToBottomTunelAnimations9',
        },
      },
    },
    {
      x: 2537.5,
      y: 1385,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightToBottomTunelAnimations10',
        },
      },
    },
    {
      x: 2375,
      y: 1297.5,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.PauseAction,
        action: {
          duration: 2000,
        },
      },
    },
    {
      x: 2380,
      y: 1306.5,
      width: 5,
      height: 5,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightToBottomTunelAnimations11',
        },
      },
    },
  ],
}
