<template>
  <footer class="club-building-panel-footer flexing flex-col absolute w-full">
    <section v-if="dataForUpgrade.level < dataForUpgrade.maxLevel">
      <section v-if="isClubManagerOrAssistant">
        <div class="text-36 font-bold flexing flex-row mb-7">
          <p class="text-texts-standard-default mr-2 uppercase">
            {{ $t('common.price') }}
          </p>
          <p
            :class="[
              isLowPrestige ? 'text-texts-standard-danger' : 'text-texts-standard-important',
            ]"
          >
            {{ $filters.$formatNumber(dataForUpgrade.price) }}
          </p>
          <app-main-icon :icon-size="32" icon-name="prestige" />
        </div>
        <app-button
          v-tippy="{
            theme: 'WSM',
            content: isLowPrestige ? $t('common.notEnoughPrestige') : null,
            placement: 'top',
            textAlign: 'center',
          }"
          btn-id="club-panel-footer-btn"
          btn-type="secondary"
          btn-size="md"
          :btn-text="$t('button.upgrade')"
          :disabled="isLowPrestige"
          @click="showUpgrade = true"
        />
        <popup-window
          v-if="showUpgrade"
          popup-type="info"
          :popup-title="$t(setPopupTitile)"
          @close="showUpgrade = false"
        >
          <clubs-upgrade-popup
            v-if="showUpgrade"
            :upgrade-data="dataForUpgrade"
            @upgrade="makeUpgrade"
          />
        </popup-window>
      </section>
      <p v-else class="text-texts-standard-default text-36 font-bold p-4">
        {{ $t('club.onlyAdminUpgrade') }}
      </p>
    </section>
    <section v-else>
      <p class="text-texts-standard-default text-36 font-bold">
        {{ $t('common.maximumLevel') }}
      </p>
    </section>
  </footer>
</template>

<script lang="ts">
import ClubsUpgradePopup from '@/components/Club/Popups/ClubsUpgradePopup.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import { CLUBARENA } from '@/globalVariables'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { ClubUpgradeData } from '@/interfaces/clubs/Clubs'

interface ComponentData {
  showUpgrade: boolean
}

export default defineComponent({
  name: 'ClubsBuildingPanelClubhouseFooter',
  components: {
    PopupWindow,
    ClubsUpgradePopup,
  },
  props: {
    dataForUpgrade: {
      type: Object as PropType<Nullable<ClubUpgradeData>>,
      required: true,
    },
  },
  emits: ['doActionFooter'],
  data(): ComponentData {
    return {
      showUpgrade: false,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      isClubManagerOrAssistant: 'isClubManagerOrAssistant',
    }),
    ...mapState(useClubStore, {
      getClubInfo: 'getClubInfo',
    }),
    setPopupTitile(): string {
      if (this.dataForUpgrade.name === CLUBARENA) return 'club.arenaUpgrade'
      else return 'club.clubHouseUpgrade'
    },
    isLowPrestige(): boolean {
      return this.dataForUpgrade.price > this.getClubInfo.prestige
    },
  },
  created(): void {
    this.loadClubInfo()
  },
  methods: {
    ...mapActions(useClubStore, {
      loadClubInfo: 'loadClubInfo',
    }),
    makeUpgrade(e): void {
      this.$emit('doActionFooter', e)
      this.showUpgrade = false
    },
  },
})
</script>

<style lang="scss" scoped>
.club-building-panel-footer {
  bottom: 2.75rem;
}
</style>
