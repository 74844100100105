<template>
  <div class="relative">
    <app-icon
      v-if="
        btnData.status === 'lock' || btnData.status === 'soon' || btnData.status === 'soon-lock'
      "
      :icon-name="btnData.status === 'lock' || btnData.status === 'soon-lock' ? 'lock-sm' : 'time'"
      class="btn-disabled-icons"
    />

    <button
      :id="btnData.id"
      v-tippy="{
        allowHTML: true,
        content:
          btnData.status === 'lock'
            ? $t('rankingsGroup.completeQuestsToUnlock')
            : btnData.status === 'soon' || btnData.status === 'soon-lock'
              ? 'Coming soon'
              : '',
        placement: 'top',
        theme: 'WSM',
      }"
      :data-cy="btnData.id"
      class="special-button"
      :class="[`btn-${btnData.type}`, btnData.status]"
      @click="open"
    >
      <div class="btn-bg" />

      <aside class="z-1" :class="`btn-${btnData.status}`">
        <div class="btn-icon">
          <div :class="'btn-icon-' + btnData.type" />
        </div>
        <span class="flexing btn-texts">
          <short-text
            class="text-texts-standard-dark text-36 font-bold uppercase revert-skew"
            :text="btnData.text"
            :first-cut="13"
          />
          <p class="btn-texts-count text-texts-standard-dark text-24">
            {{ btnData.count ? btnData.count : '' }}
          </p>
        </span>
      </aside>
    </button>
  </div>
</template>

<script lang="ts">
import ShortText from '@/components/ShortText.vue'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'

interface SpecialButtonData {
  id: string
  type: string
  text: string
  status: string
  route?: string
  params?: { user: string }
  count?: number
}

export default defineComponent({
  name: 'AppSpecialButtons',
  components: {
    ShortText,
  },

  props: {
    btnData: {
      type: Object as PropType<SpecialButtonData>,
      required: true,
    },
  },

  methods: {
    open(): void {
      if (this.btnData.status === 'open') {
        if (this.btnData.params) {
          this.$router.push({
            name: this.$getWebView(this.btnData.route),
            params: this.btnData.params,
          })
        } else {
          this.$router.push({ name: this.$getWebView(this.btnData.route) })
        }
      }
    },
  },
})
</script>

<style lang="scss" scoped>
$specialButtonsIcons: (
  0: 'trophy',
  1: 'achievment',
  2: 'lab',
);

.btn-disabled-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;
}

.special-button {
  width: 23.75rem;
  height: 5rem;
  box-shadow:
    inset 0.188rem 0 0 0 rgba(255, 255, 255, 0.4),
    inset -0.188rem 0 0 0 rgba(255, 255, 255, 0.4),
    inset 0 -0.125rem 0.063rem 0 rgba(255, 255, 255, 0.4),
    inset 0 0.25rem 0 0 rgba(255, 255, 255, 0.64);
  transform: $skewX-value;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;

  &.btn-trophy {
    background: linear-gradient(to right, #fbcd52, #fbbc41, #ae6e1c);
  }

  &.btn-lab {
    background: linear-gradient(to right, #d0d0d0, #bbb, #717171);
  }

  &.btn-achievment {
    background: linear-gradient(to right, #ffb99e, #ec8e69, #b54c23);
  }

  &.soon,
  &.lock,
  &.soon-lock {
    filter: brightness(0.4);
  }

  .btn-lock,
  .btn-soon,
  .btn-soon-lock {
    opacity: 0.7;
    background: rgb(59 59 59 / 60%);
    width: 100%;
    height: 100%;
    position: relative;
  }

  .btn-icon {
    width: 7rem;
    height: 6.688rem;
    position: absolute;
    bottom: 0;
    left: -0.375rem;
    transform: $skewX-revert-value;

    @each $iconPosition, $iconName in $specialButtonsIcons {
      .btn-icon-#{$iconName} {
        content: '';
        background: url($path-profile + 'ICO_PROFILE_SPRITE.avif') center no-repeat;
        background-size: 20rem;
        background-position: (-6.4rem * $iconPosition) 0;
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
      }
    }
  }

  .btn-bg {
    background: url($path-profile + 'BG_BUTTON_TEXTURE.avif') center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    z-index: 0;
  }

  .btn-texts {
    margin-left: 5.5rem;
    height: 100%;

    &-count {
      margin-left: 0.5rem;
      margin-top: 0.5rem;
    }
  }
}
</style>
