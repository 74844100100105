import { isMobile } from '@/plugins'
import type { App } from 'vue'

/**
 * Vrati hodnotu mena routy pre web
 * @param name - meno ktore hladame
 * @returns Hodnotu routy pre web
 */
export const getRouteWebName = (name: string): string =>
  isMobile() || name === 'LayoutView' || name === 'ClubsMap' ? name : name + 'Web'

export default {
  install: (app: App): void => {
    app.config.globalProperties.$getWebView = getRouteWebName
  },
}
