import {
  DAILY_LEAGUE_CONFIG,
  dailyLeagueDisciplinesEndpoint,
  gamesConfigEndpoint,
} from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import type DailyLeagueResponse from '@/interfaces/responses/starOfTheDay/DailyLeagueResponse'
import type { DailyLeagueDiscipline } from '@/interfaces/responses/starOfTheDay/DailyLeagueResponse'
import type DailyLeagueConfigResponse from '@/interfaces/responses/starOfTheDay/DailyLeagueConfigResponse'

interface StarOfTheDayState {
  dailyLeagueData: DailyLeagueResponse
  config: DailyLeagueConfigResponse
}

export const useStarOfTheDayStore = defineStore('starOfTheDay', {
  state: (): StarOfTheDayState => ({
    dailyLeagueData: null as DailyLeagueResponse,
    config: null as DailyLeagueConfigResponse,
  }),
  getters: {
    getDailyLeagueData(): StarOfTheDayState['dailyLeagueData'] {
      return this.dailyLeagueData
    },
    getDisciplineById(): (id: number) => DailyLeagueDiscipline {
      return (id: number): DailyLeagueDiscipline =>
        this.dailyLeagueData?.disciplines?.find(
          (discipline: DailyLeagueDiscipline): boolean => discipline.id === id,
        ) ?? null
    },
    getConfig(): StarOfTheDayState['config'] {
      return this.config
    },
  },
  actions: {
    async loadConfig(): Promise<void> {
      if (this.config) {
        return
      }
      this.config = await internalAxios.post<{}, DailyLeagueConfigResponse, { keys: string }>(
        gamesConfigEndpoint,
        {
          keys: DAILY_LEAGUE_CONFIG,
        },
      )
    },
    async loadDailyLeagueData(): Promise<void> {
      const data = await internalAxios.get<{}, DailyLeagueResponse>(
        dailyLeagueDisciplinesEndpoint + '?noOpponents',
      )
      data?.disciplines?.forEach((discipline: DailyLeagueDiscipline): void => {
        discipline.isPlayAvailable = discipline.dailyLeagueRematch
      })
      this.dailyLeagueData = data
    },
    updateDailyLeagueData(data: DailyLeagueResponse): void {
      this.dailyLeagueData = data
    },
  },
})
