<template>
  <table v-if="table" class="qualification-table m-auto table-standard">
    <thead>
      <tr
        class="flex items-center justify-between text-22 qualification-table-header uppercase italic"
      >
        <th v-for="(text, i) in tableHeader" :key="text" :class="{ active: active(i) }">
          <sorting-arrow
            v-if="![0, 7].includes(i) && !mastersLadder"
            class="sorting-hat"
            :active="activeSorter === i"
            :my-position="i"
            @state-changed="orderTable"
          >
            {{ text }}
          </sorting-arrow>
          <span v-else>{{ text }}</span>
        </th>
      </tr>
    </thead>
    <tbody ref="tbody">
      <app-scrollbar width="100%" height="39rem" scroll="y" slice="y" class="qual-scroll">
        <tr
          v-for="(player, i) in ourData"
          :key="player.user_games_id"
          ref="rows"
          :class="{ player: isPlayer(player.user_games_id) }"
          class="table-standard-row w-full flex items-center justify-between text-texts-standard-default text-32"
          :data-index="i"
          @mouseenter="activeRow = i"
          @mouseleave="activeRow = -1"
        >
          <td>{{ player.position || '-' }}</td>
          <td class="clickable">
            <app-user
              :id="player.user_games_id"
              :country="player.country"
              :no-country-text="true"
              :name="player.username"
            />
          </td>
          <template v-if="roundCount !== 0">
            <td v-for="j in roundCount" :key="j">
              <div class="flex items-center justify-center">
                <p class="flexing">
                  {{
                    getRoundInfo(player, j) ? $filters.$abbrNumber(getRoundInfo(player, j)) : '-'
                  }}
                  <app-main-icon
                    v-if="activeRound(j) && taskIcons"
                    :icon-size="32"
                    :icon-name="taskIcons[j]"
                    :hide-tooltip="$isMobile() ? !viewportRows.has(i) : activeRow !== i"
                    class="ml-2 mb-2"
                  />
                  <information-icon
                    v-if="getRoundData(player, j)"
                    :round="j"
                    :icon-name="taskIcons[j]"
                    :information-data="getRoundData(player, j)"
                    :hide-tooltip="$isMobile() ? !viewportRows.has(i) : activeRow !== i"
                    class="iconer"
                  />
                </p>
              </div>
            </td>
          </template>
          <td v-if="mastersLadder">
            <p class="flexing">
              {{ player.points }}
              <app-main-icon :icon-size="32" icon-name="masters_playoff_points" class="ml-2" />
            </p>
          </td>
          <td v-if="!isMasters">
            <information-medal
              :round-data="player.rounds"
              :medal="player.medals"
              :hide-tooltip="$isMobile() ? !viewportRows.has(i) : activeRow !== i"
            />
          </td>
          <td class="flexing">
            <league-information-component
              v-if="player.league"
              :league="player.league"
              :hide-tooltip="$isMobile() ? !viewportRows.has(i) : activeRow !== i"
            />
          </td>
        </tr>
        <tr
          v-if="needsSpecialPlayerRow"
          class="player table-standard-row w-full flex items-center justify-between text-texts-standard-default text-32 sticky-bottom"
        >
          <td>{{ 'position' in table?.player ? table.player.position : '-' }}</td>
          <td class="clickable">
            <app-user
              :id="table.player.user_games_id"
              :country="table.player.country"
              :no-country-text="true"
              :name="table.player.username"
            />
          </td>
          <template v-if="roundCount !== 0">
            <td v-for="j in roundCount" :key="j">
              <div class="flex items-center justify-center">
                <p class="flexing">
                  {{
                    getRoundInfo(table?.player, j)
                      ? $filters.$abbrNumber(getRoundInfo(table?.player, j))
                      : '-'
                  }}
                  <app-main-icon
                    v-if="activeRound(j) && taskIcons"
                    :icon-size="32"
                    :icon-name="taskIcons[j]"
                    :hide-tooltip="true"
                    class="ml-2 mb-2"
                  />
                  <information-icon
                    v-if="getRoundData(table?.player, j)"
                    :round="j"
                    :icon-name="taskIcons[j]"
                    :information-data="getRoundData(table?.player, j)"
                    :hide-tooltip="true"
                    class="iconer"
                  />
                </p>
              </div>
            </td>
          </template>
          <td v-if="mastersLadder">
            <p class="flexing">
              {{ table.player.points ? table.player.points : '-' }}
              <app-main-icon :icon-size="32" icon-name="masters_playoff_points" class="ml-2" />
            </p>
          </td>
          <td v-if="!isMasters">
            <information-medal
              :round-data="table.player.rounds"
              :medal="table.player.medals"
              :hide-tooltip="true"
            />
          </td>
          <td class="flexing">
            <league-information-component
              v-if="table.player.league"
              :league="table.player.league"
              :hide-tooltip="true"
            />
          </td>
        </tr>
      </app-scrollbar>
    </tbody>
  </table>
</template>

<script lang="ts">
import type { Player, Round, TableData, TaskIcons } from '@/interfaces/events/QualificationTable'
import { useUserStore } from '@/store/pinia/userStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import AppUser from '../../../GlobalComponents/AppUser.vue'
import InformationIcon from './InformationComponents/InformationIcon.vue'
import InformationMedal from './InformationComponents/InformationMedailon.vue'
import LeagueInformationComponent from './InformationComponents/LeagueInformationComponent.vue'
import SortingArrow from './SortingArrow.vue'
import { EventMechanics } from '@/interfaces/events/EventInfo'
import { PlayoffState } from '@/interfaces/events/Playoff'
import type { Nullable } from '@/interfaces/utils'

type PlayerWithPosition = Player & { position: number }

interface ComponentData {
  activeRow: number
  viewportRows: Set<number>
  playerRowObserver: Nullable<IntersectionObserver>
  rowsObserver: Nullable<IntersectionObserver>
  ourData: PlayerWithPosition[]
  activeSorter: number
  ordering: Record<number, number>
}

export default defineComponent({
  name: 'QualificationTable',
  components: {
    AppUser,
    LeagueInformationComponent,
    InformationIcon,
    InformationMedal,
    SortingArrow,
  },
  props: {
    table: {
      type: Object as PropType<TableData>,
      required: true,
    },
    mastersLadder: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Number,
      default: 0,
    },
    state: {
      type: String as PropType<PlayoffState>,
      required: true,
      validator: (state: PlayoffState): boolean =>
        [PlayoffState.Qualification, PlayoffState.Draw].includes(state),
    },
    taskIcons: {
      type: Object as PropType<Nullable<TaskIcons>>,
      default: () => null,
    },
    roundCount: {
      type: Number,
      default: 4,
    },
  },
  data(): ComponentData {
    return {
      activeRow: -1,
      viewportRows: new Set<number>(),
      playerRowObserver: null,
      rowsObserver: null,
      ourData: [],
      activeSorter: 0,
      ordering: {
        0: 0,
        1: 1,
        2: -1,
      },
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userId: 'getUserId',
    }),
    ...mapState(useEventInfoStore, {
      eventMechanics: 'getEventMechanics',
    }),
    isMasters(): boolean {
      return this.eventMechanics.includes(EventMechanics.MastersPlayoff)
    },
    tableHeader(): string[] {
      let allValues = [this.$te('positionShort'), this.$te('commonName')]
      if (this.roundCount) {
        for (let i = 1; i <= this.roundCount; i++) {
          allValues.push(this.$t(`event.playoffTableColumnR${i}`))
        }
      }
      if (this.isMasters && this.mastersLadder) {
        return [...allValues, ...[this.$te('mastersPoints'), this.$te('league')]]
      } else if (this.isMasters) {
        return [...allValues, ...[this.$te('league')]]
      } else {
        return [...allValues, ...[this.$te('medals'), this.$te('league')]]
      }
    },
    sortBy(): string[] {
      let allValues = ['poz', 'name']
      if (this.roundCount) {
        for (let i = 1; i <= this.roundCount; i++) {
          allValues.push(i.toString())
        }
      }
      return [...allValues, ...['medal', 'league']]
    },
    needsSpecialPlayerRow(): boolean {
      if (!this.table?.player) return false
      const filter = this.table.players.filter(
        ({ user_games_id }: Player): boolean => user_games_id === this.table?.player?.user_games_id,
      )
      return filter.length === 0
    },
  },
  created(): void {
    this.ourData = this.table.players.map(
      (player: Player, i: number): PlayerWithPosition => ({
        ...player,
        position: i + 1,
      }),
    )
  },
  mounted() {
    this.$nextTick((): void => {
      this.playerRowObserver = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]): void =>
          entries.forEach(
            ({
              isIntersecting,
              boundingClientRect,
              rootBounds,
              target: { classList },
            }: IntersectionObserverEntry): void => {
              const isStickyTop = boundingClientRect.top < rootBounds.top
              const isStickyBottom = boundingClientRect.bottom > rootBounds.bottom

              // !isIntersecting pridane kvoli app-scrollbar, kvoli ktoremu to nefungovalo korektne.
              if (!isStickyTop && !isStickyBottom && !isIntersecting)
                return classList.remove('sticky-top', 'sticky-bottom')
              if (isStickyTop) return classList.add('sticky-top')
              if (isStickyBottom) return classList.add('sticky-bottom')
            },
          ),
        {
          root: this.$refs.tbody as Element,
          rootMargin: '0px',
          threshold: 1,
        },
      )

      // TODO: @Peto
      // Treba zanalyzovat, preco tuto nemusi `this.$refs.rows` existovat v masters playoff,
      // ak sa tabulka zobrazuje, len ak mame nejakych hracov -> nejake riadky do tabulky.
      const playerRow: HTMLElement = this.$refs?.rows?.find(({ classList }: HTMLElement): boolean =>
        classList.contains('player'),
      )

      if (playerRow) {
        this.playerRowObserver.observe(playerRow)
      }

      if (!this.$isMobile()) return

      this.rowsObserver = new IntersectionObserver(
        (entries: IntersectionObserverEntry[], observer: IntersectionObserver): void =>
          entries.forEach(({ isIntersecting, target }: IntersectionObserverEntry): void => {
            //  add rows, that entered viewport
            if (isIntersecting) {
              this.viewportRows.add(+(target as HTMLElement).dataset.index)
              observer.unobserve(target)
            }

            // add or remove rows, that entered or left viewport
            // this.viewportRows[isIntersecting ? 'add' : 'remove'](+target.dataset.index)
          }),
        {
          root: this.$refs.tbody,
          rootMargin: '0px',
          threshold: 0,
        },
      )

      this.$refs.rows.forEach((row: HTMLElement): void => this.rowsObserver.observe(row))
    })
  },
  beforeUnmount(): void {
    if (this.playerRowObserver) {
      this.playerRowObserver.disconnect()
      this.playerRowObserver = null
    }

    if (this.rowsObserver) {
      this.rowsObserver.disconnect()
      this.rowsObserver = null
    }
  },
  methods: {
    active(index: number): boolean {
      if (this.state === PlayoffState.Draw) return false
      return index > 1 && index < this.tableHeader.length - 2 && index - 1 === this.round
    },
    activeRound(index: number): boolean {
      if (this.state === PlayoffState.Draw) return true
      return this.round >= index
    },
    isPlayer(id: string): boolean {
      return id === this.userId
    },
    getRoundInfo(player: Player, id: number): number {
      const requestedRound = player.rounds.filter(
        (roundData: Round): boolean => roundData.round === id,
      )
      if (requestedRound.length === 0) return null
      return requestedRound[0].points
    },
    getRoundData(player: Player, id: number): Round | boolean {
      const requestedRound = player.rounds.filter(
        (roundData: Round): boolean => roundData.round === id,
      )
      if (requestedRound.length === 0) return false
      return requestedRound[0]
    },
    orderTable(data: { state: number; position: number }): void {
      const orderingType = this.ordering[data.state]
      const sortByValue = this.sortBy[data.position]
      this.activeSorter = data.position

      if (orderingType === 0) {
        this.ourData = this.table.players.map(
          (player: Player, i: number): PlayerWithPosition => ({
            ...player,
            position: i + 1,
          }),
        )
        return
      }

      this.ourData.sort((a: Player, b: Player): number => {
        if (sortByValue === 'name')
          // Empty strings for generated users on local machine.
          return orderingType * ((a?.username || '') > (b?.username || '') ? 1 : -1)
        if (!isNaN(parseInt(sortByValue)))
          return (
            orderingType *
            (a.rounds.find((el: Round): boolean => el.round === parseInt(sortByValue)).points >
            b.rounds.find((el: Round): boolean => el.round === parseInt(sortByValue)).points
              ? 1
              : -1)
          )
        if (sortByValue === 'medal') return orderingType * (a.medals > b.medals ? 1 : -1)
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.qualification-table {
  width: 98%;
  margin-top: 0.75rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  overflow: scroll;

  .sorting-hat {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-header {
    color: #e4e4e4;
    height: 2.375rem;
    font-size: 1.375rem;
    padding: 0 0 0 0.9375rem;

    @if $isWsm {
      background-color: #09172a;
    }

    @if $isSsm {
      background-color: #292d30;
    }

    .active {
      background: linear-gradient(to right, transparent, rgba(203, 200, 45, 0.75), transparent);
      background-clip: content-box;
      border-image-source: linear-gradient(to top, #a68b1c, #f8d02e, #a68b1c);
    }

    th {
      font-weight: normal;
      white-space: nowrap;
    }

    th:first-child {
      width: 2rem;
    }

    th:nth-child(2) {
      width: 35rem;
      margin-right: auto;
    }

    th:nth-child(n + 3) {
      width: 11rem;
    }

    th:nth-child(8) {
      width: 8rem;
    }

    th:nth-child(9) {
      width: 11rem;
    }
  }

  span {
    white-space: nowrap;
  }

  tbody {
    margin-top: 0.125rem;

    .clickable {
      cursor: pointer;
      pointer-events: auto;
    }

    .qual-scroll {
      tr {
        &.player {
          @if $isWsm {
            background-color: #15856e;
          }

          @if $isSsm {
            background-image: none;
            background-color: rgba(205, 74, 139, 0.7);
            border: solid 0.125rem #c85493;
          }

          &.sticky-top {
            position: sticky;
            top: 0;
          }

          &.sticky-bottom {
            position: sticky;
            bottom: 0;
          }
        }

        margin-bottom: 0.375rem;
        padding-left: 1.4rem;
        height: 4.5625rem;

        td {
          white-space: nowrap;
        }

        td:first-child {
          width: 2rem;
        }

        td:nth-child(2) {
          width: 35rem;
          padding-left: 5rem;
          margin-right: auto;
        }

        td:nth-child(n + 3) {
          width: 11rem;
        }

        td:nth-child(8) {
          width: 8rem;
        }

        td:nth-child(9) {
          width: 11rem;
        }
      }
    }
  }
}
</style>
