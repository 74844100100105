<template>
  <div class="popup-rewards">
    <app-scrollbar width="100%" height="100%" scroll="y" slide="y">
      <div class="rewards-header flexing">
        <app-discipline-icon class="mx-4" :discipline-id="disciplineId" />
        <p class="font-bold text-40 uppercase">
          {{ disciplineTitle }}
        </p>
      </div>
      <div class="flex justify-evenly">
        <div v-for="column in columns" :key="column.name">
          <div class="flexing mt-4">
            <p class="text-36 text-texts-standard-default font-bold">
              <span class="mr-3">{{ $t(column.text) }}</span>
              {{ column.name === 'live' ? startsLiveNumber : startsBaseNumber }}
            </p>
            <app-main-icon :icon-size="48" icon-name="starts" />
          </div>
          <div
            v-for="(possibleRewards, i) in selectRewards(column)"
            :key="i"
            class="flex justify-center text-36 text-texts-standard-default rewards-content"
          >
            <reward-icon
              class="badge"
              :class="{
                'h-auto': $isSsm,
              }"
              :position="i + 1"
            />
            <div
              class="flex items-center justify-between rewards-content-box"
              :class="pickColor(i) + '-reward-border'"
            >
              <div class="flex items-center justify-between rewards-content-box-wrapper">
                <div
                  v-if="rankingPointsReward(possibleRewards)"
                  class="flex items-center justify-center rewards-content-box-wrapper-left-side"
                >
                  <div class="flex items-center rewards-content-box-wrapper-left-side-text">
                    <div
                      v-if="rankingPointsReward(possibleRewards)"
                      :class="
                        rankingPointsReward(possibleRewards).value > 0
                          ? 'rewards-content-arrow-green'
                          : 'rewards-content-arrow-red'
                      "
                    />
                    <p class="ranking-points">
                      {{
                        rankingPointsReward(possibleRewards).value *
                        (column.name === 'live' ? startsLiveNumber : startsBaseNumber)
                      }}
                    </p>
                    <app-main-icon :icon-size="48" :icon-name="RANKING_POINTS" />
                  </div>
                </div>
                <div
                  v-for="(reward, k) in filteredRewards(possibleRewards)"
                  :key="k"
                  class="flex reward-width"
                >
                  <p>
                    {{
                      reward.value * (column.name === 'live' ? startsLiveNumber : startsBaseNumber)
                    }}
                  </p>
                  <app-icon
                    v-if="reward.name === EVENT_REPUTATION"
                    :icon-name="reward.name"
                    :tooltip-text="$te('reputation')"
                    class="icon-in-arena ml-2"
                  />
                  <app-main-icon v-else :icon-size="48" :icon-name="reward.name" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-scrollbar>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { EVENT_REPUTATION, RANKING_POINTS } from '@/globalVariables'
import { getColor } from '@/helpers'
import { useUserStore } from '@/store/pinia/userStore'
import RewardIcon from '@/components/Arena/RewardIcon.vue'

import type { Nullable } from '@/interfaces/utils'
import type { PropType } from 'vue'
import type Reward from '@/interfaces/Reward'

interface Column {
  name: string
  text: string
}

interface ComponentData {
  RANKING_POINTS: typeof RANKING_POINTS
  EVENT_REPUTATION: typeof EVENT_REPUTATION
}

export default defineComponent({
  name: 'ArenaRewards',
  components: {
    RewardIcon,
  },
  props: {
    disciplineId: {
      type: Number,
      required: true,
    },
    disciplineTitle: {
      type: String,
      required: true,
    },
    rewards: {
      type: Object as PropType<Reward[][]>,
      required: true,
    },
    rewardsBase: {
      type: Object as PropType<Nullable<Reward[][]>>,
      default: () => null,
    },
    startsBaseNumber: {
      type: Number,
      required: true,
    },
    startsLiveNumber: {
      type: Number,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      RANKING_POINTS,
      EVENT_REPUTATION,
      //columns: [ uncomment after ABC testing and remove columns from computed
      //  { name: 'live', text: 'common.live' },
      //  { name: 'sim', text: 'common.simulation' },
      //],
    }
  },
  computed: {
    ...mapState(useUserStore, {
      isGroupB: 'isGroupB',
    }),
    columns(): Column[] {
      return this.isGroupB
        ? [{ name: 'sim', text: 'common.simulation' }]
        : [
            { name: 'live', text: 'common.live' },
            { name: 'sim', text: 'common.simulation' },
          ]
    },
  },
  methods: {
    filteredRewards(possibleRewards: Reward[]): Reward[] {
      return possibleRewards.filter((item: Reward): boolean => item.name !== RANKING_POINTS)
    },
    rankingPointsReward(rewards: Reward[]): Reward {
      return rewards.find((item: Reward): boolean => item.name === RANKING_POINTS)
    },

    pickColor: (position: number): string => getColor(position),
    selectRewards(column: Column) {
      return this.rewardsBase && column.name === 'live' ? this.rewardsBase : this.rewards
    },
  },
})
</script>

<style lang="scss" scoped>
.popup-rewards {
  width: 99.75rem;
  height: 43.313rem;
  padding-right: 1rem;

  .rewards-header {
    color: theme('colors.texts.standard.additional');
    height: 7.375rem;
    position: relative;
    margin: 2.813rem 15.375rem 0rem;

    @if $isWsm {
      background: linear-gradient(to right, transparent, #144a73, #144a73, transparent);
    }

    @if $isSsm {
      background-image: linear-gradient(to right, transparent, #56627f, #56627f, transparent);
    }

    &-logo {
      width: 5.625rem;
      height: 5.625rem;
      border: solid 0.125rem #3f6b93;
      margin-right: 1rem;
      background: linear-gradient(to top, #3f78a0, #254257);
    }
  }

  .rewards-content {
    margin: 2rem 0;

    .badge {
      background-size: contain;
      position: relative;
      left: 2.5rem;
      z-index: 1;
    }

    .ranking-points {
      margin-left: 0.625rem;
    }

    &-box {
      transform: $skew-value;
      height: 4.063rem;
      min-width: 33.875rem;

      &-wrapper {
        width: 100%;
        transform: $skew-revert-value;

        &-left-side {
          background: #0c253b;
          transform: $skew-value;
          height: 3.5rem;
          width: 12rem;

          &-text {
            transform: $skew-revert-value;
            position: relative;
            left: 1rem;
          }
        }

        .reward-width {
          width: 10rem;
          justify-content: center;
        }
      }
    }

    .yellow-reward-border {
      border: 0.063rem solid theme('colors.texts.standard.important');
    }

    .white-reward-border {
      border: 0.063rem solid theme('colors.texts.standard.silver');
    }

    .orange-reward-border {
      border: 0.063rem solid theme('colors.texts.standard.bronze');
    }

    .blue-reward-border {
      border: 0.063rem solid theme('colors.texts.standard.name');
    }

    &-arrow-green {
      margin-left: 0.625rem;
      border-top: 0.625rem solid transparent;
      border-bottom: 0.625rem solid transparent;
      border-left: 1.25rem solid green;
      transform: rotate(-90deg);
    }

    &-arrow-red {
      margin-left: 0.625rem;
      width: 0;
      height: 0;
      border-top: 0.625rem solid transparent;
      border-bottom: 0.625rem solid transparent;
      border-left: 1.25rem solid red;
      transform: rotate(90deg);
    }

    &-experience-icon {
      width: 3.5rem;
      height: 3.5rem;
      background-color: #b5deff;
      margin: 1.063rem 3.25rem 1.063rem 1.063rem;
    }
  }
}
</style>
