import {
  EQUIPMENT_DETAIL_ROUTE_NAME,
  EQUIPMENT_DETAIL_WEB_ROUTE_NAME,
  EQUIPMENT_ROUTE_NAME,
  EQUIPMENT_WEB_ROUTE_NAME,
  LAB_CAMPS_ROUTE_NAME,
  LAB_CAMPS_WEB_ROUTE_NAME,
  LAB_DISCIPLINES_ROUTE_NAME,
  LAB_DISCIPLINES_WEB_ROUTE_NAME,
  LAB_ECONOMICS_ROUTE_NAME,
  LAB_ECONOMICS_WEB_ROUTE_NAME,
} from '@/globalVariables/routeNameConstants'

export const NOT_REFRESH_EQUIPMENT_ROUTE_NAMES = [
  EQUIPMENT_ROUTE_NAME,
  EQUIPMENT_WEB_ROUTE_NAME,
  EQUIPMENT_DETAIL_ROUTE_NAME,
  EQUIPMENT_DETAIL_WEB_ROUTE_NAME,
]

export const NOT_REFRESH_LAB_ROUTE_NAMES = [
  LAB_DISCIPLINES_ROUTE_NAME,
  LAB_ECONOMICS_ROUTE_NAME,
  LAB_CAMPS_ROUTE_NAME,
  LAB_DISCIPLINES_WEB_ROUTE_NAME,
  LAB_ECONOMICS_WEB_ROUTE_NAME,
  LAB_CAMPS_WEB_ROUTE_NAME,
]
