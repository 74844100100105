<template>
  <div
    class="homepage-welcome-buttons flex items-center m-auto"
    :class="[justifyClass, { 'homepage-welcome-buttons--mobile': isMobile }]"
  >
    <a
      v-if="(showOsSpecificButton && ($isIphone() || $isIpad())) || !showOsSpecificButton"
      class="a-store h-full cursor-pointer"
      :href="appleStoreUrl"
      target="_blank"
    />
    <a
      v-if="(showOsSpecificButton && $isAndroid()) || !showOsSpecificButton"
      class="g-store h-full cursor-pointer clickable-element"
      :href="googleStoreUrl"
      target="_blank"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { appleStoreUrl, googleStoreUrl } from '@/globalVariables'

interface ComponentData {
  googleStoreUrl: typeof googleStoreUrl
  appleStoreUrl: typeof appleStoreUrl
}

export default defineComponent({
  name: 'AppStoreButtons',
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    showOsSpecificButton: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      appleStoreUrl,
      googleStoreUrl,
    }
  },
  computed: {
    justifyClass(): string {
      return this.showOsSpecificButton ? 'justify-center' : 'justify-between'
    },
  },
})
</script>

<style lang="scss" scoped>
.homepage-welcome {
  &-buttons {
    width: 34.25rem;
    height: 5.0625rem;
    margin-top: -3.5rem;

    .g-store {
      width: 17.125rem;
      background: url($path-homepage + 'BTN_GOOGLE_PLAY.avif') center no-repeat;
      background-size: contain;
    }

    .a-store {
      width: 15.1875rem;
      background: url($path-homepage + 'BTN_APP_STORE.avif') center no-repeat;
      background-size: contain;
    }

    &--mobile {
      justify-content: center;
      gap: 2.25rem;
      // transform scale correction
      margin-top: calc(1.8 * -3.75rem);

      .g-store {
        width: 15.125rem;
      }

      .a-store {
        width: 13.1875rem;
      }
    }
  }
}
</style>
