<template>
  <header class="teams-sub-header flexing w-full absolute" :data-event-theme="namespace">
    <div class="relative top-2" :class="'chain-tier-' + tier" />
  </header>
</template>

<script lang="ts">
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TeamsSubHeader',
  props: {
    tier: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
  },
})
</script>

<style lang="scss" scoped>
$tiers: (1, 2, 3);

[data-event-theme='challenge_cup'] {
  --events-teams-sub-header-bg: url('#{$path-events}challenge-cup/taskchain/teams/sub-header-bg.avif');
  @each $tier in $tiers {
    --events-teams-sub-header-tier-#{$tier}: url('#{$path-events}challenge-cup/taskchain/teams/tier-#{$tier}.avif');
  }
}

[data-event-theme='frozen'] {
  --events-teams-sub-header-bg: url('#{$path-events}frozen-championship/taskchain/teams/sub-header-bg.avif');
  @each $tier in $tiers {
    --events-teams-sub-header-tier-#{$tier}: url('#{$path-events}frozen-championship/taskchain/teams/tier-#{$tier}.avif');
  }
}

.teams-sub-header {
  top: 9.75rem; // height of header
  height: 6.625rem;
  @include background(var(--events-teams-sub-header-bg), contain);

  @each $tier in $tiers {
    .chain-tier-#{$tier} {
      width: 18.563rem;
      height: 7.813rem;
      @include background(var(--events-teams-sub-header-tier-#{$tier}), contain);
    }
  }
}
</style>
