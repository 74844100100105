import { useUserStore } from '@/store/pinia/userStore'
import type { Router } from 'vue-router'

export const isMyClub = (router: Router): boolean => {
  const useUser = useUserStore()
  const myClubId = useUser.getPlayerClubId
  if (!myClubId) return false

  const routeParamClubId = router.currentRoute.value.params?.clubId
  if (!routeParamClubId) return true

  return routeParamClubId === myClubId
}
