<template>
  <div class="app-page-wrapper absolute" data-cy="staroftheday-standings-wrapper">
    <div class="staroftheday-standings w-full h-full absolute flex flex-col items-center">
      <menu-component
        menu-type="training"
        :title="$t('map.starOfTheDayName')"
        :menu-items="{
          1: {
            text: $t('benefits.dailyLeague'),
            route: $getWebView('StarofthedayDisciplines'),
            current: $getWebView('StarofthedayStandings'),
          },
          2: {
            text: $t('starOfTheDay.athleteOfTheDay'),
            route: $getWebView('StarofthedayAthlete'),
          },
        }"
        :sub-menu-active="true"
        :sub-menu="{
          1: {
            text: $t('trainingHall.disciplines'),
            route: $getWebView('StarofthedayDisciplines'),
          },
          2: {
            text: $t('starOfTheDay.table'),
            route: $getWebView('StarofthedayStandings'),
          },
        }"
      />

      <section v-if="isLoaded" class="w-full h-full flex flex-col items-center safe-area">
        <template v-if="standingsData.state === IN_PROGRESS">
          <header class="text-32 uppercase flex justify-between items-center">
            <app-timer
              v-tippy="{
                content: $t('starOfTheDay.timeToEnd'),
                placement: 'right',
                theme: 'WSM',
              }"
              :time="standingsData.time_remaining"
              icon="refresh"
              @countdown-action="loadData"
            />
            <app-icon
              v-tippy="{
                content: `${$t('starOfTheDay.reachMaximumPoints')} ${$t('starOfTheDay.orderInfo')}`,
                placement: 'left',
                theme: 'WSM',
                trigger: 'click',
              }"
              icon-name="info-60"
            />
          </header>
          <staroftheday-podium
            :view-type="DailyLeagueViewType.Standings"
            :podium-data="standingsData?.standings?.slice(0, 3)"
          />
          <div
            class="staroftheday-standings-table-head table-head-standard flex items-center relative"
          >
            <p class="staroftheday-standings-table-head-pos">
              {{ $t('rankingsGroup.pos') }}
            </p>
            <p class="staroftheday-standings-table-head-name">
              {{ $t('common.name') }}
            </p>
            <p class="staroftheday-standings-table-head-played">
              {{ $t('trainingHall.disciplines') }}
            </p>
            <p class="staroftheday-standings-table-head-points">
              {{ $t('rankingsGroup.points') }}
            </p>
            <p class="staroftheday-standings-table-head-rewards">
              {{ $t('common.reward') }}
            </p>
          </div>
          <app-scrollbar
            width="117.5rem"
            height="21rem"
            scroll="y"
            slide="y"
            class="staroftheday-standings-table"
          >
            <div class="table-standard">
              <staroftheday-table-row
                v-for="(player, index) in standingsData.standings"
                :key="index"
                :view-type="DailyLeagueRowViewType.Standings"
                :rank="index + 1"
                :player-data="player"
              />
            </div>
          </app-scrollbar>
          <staroftheday-my-result-row
            :view-type="DailyLeagueRowViewType.Standings"
            :my-result="standingsData.playerResults"
          />
        </template>
        <staroftheday-locked
          v-else
          :time="standingsData.time_remaining"
          :timer-tooltip="$t('starOfTheDay.timeToStart')"
        />
      </section>

      <component-loading :is-loading="!isLoaded" />
    </div>
  </div>
</template>

<script lang="ts">
import StarofthedayLocked from '@/components/Staroftheday/StarofthedayLocked.vue'
import StarofthedayMyResultRow from '@/components/Staroftheday/StarofthedayMyResultRow.vue'
import StarofthedayPodium from '@/components/Staroftheday/StarofthedayPodium.vue'
import StarofthedayTableRow from '@/components/Staroftheday/StarofthedayTableRow.vue'
import { DailyLeagueViewType, DailyLeagueRowViewType } from '@/interfaces/DailyLeague'

import {
  IN_PROGRESS,
  STAR_OF_THE_DAY_STANDINGS,
  dailyLeagueStandingsEndpoint,
  pathImages,
} from '@/globalVariables'
import { useStarOfTheDayStore } from '@/store/pinia/starOfThedayStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'
import type { AthleteOfTheDayApiResponse } from '@/interfaces/responses/starOfTheDay/AthleteOfTheDay'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  DailyLeagueViewType: typeof DailyLeagueViewType
  DailyLeagueRowViewType: typeof DailyLeagueRowViewType
  STAR_OF_THE_DAY_STANDINGS: typeof STAR_OF_THE_DAY_STANDINGS
  IN_PROGRESS: typeof IN_PROGRESS
  pathImages: typeof pathImages
  standingsData: Nullable<AthleteOfTheDayApiResponse>
}

export default defineComponent({
  name: 'StarofthedayStandings',
  components: {
    StarofthedayLocked,
    StarofthedayPodium,
    StarofthedayTableRow,
    StarofthedayMyResultRow,
  },
  data(): ComponentData {
    return {
      DailyLeagueViewType,
      DailyLeagueRowViewType,
      STAR_OF_THE_DAY_STANDINGS,
      IN_PROGRESS,
      pathImages,
      standingsData: null,
    }
  },
  computed: {
    isLoaded(): boolean {
      return !!this.standingsData
    },
  },
  created(): void {
    this.loadData()
    this.loadConfig()
  },
  methods: {
    ...mapActions(useStarOfTheDayStore, ['loadConfig']),
    async loadData(): Promise<void> {
      this.standingsData = await this.$axios.get<{}, AthleteOfTheDayApiResponse>(
        dailyLeagueStandingsEndpoint,
      )
    },
  },
})
</script>

<style lang="scss">
.staroftheday-standings {
  header {
    width: 117.5rem;
    height: 3.625rem;
    margin: 0.875rem 0;
  }

  &-table-head {
    width: 117.5rem;
    margin-top: 0.8125rem;
    background: #09172a;
    text-transform: none;
    margin-bottom: 0.6875rem;

    &-pos {
      width: 4.938rem;
    }

    &-name {
      text-align: start;
      padding-left: 11rem;
      width: 44rem;
    }

    &-played {
      width: 12rem;
    }

    &-points {
      width: 16rem;
    }

    &-rewards {
      width: 40rem;
    }
  }

  &-table {
    &-player {
      width: 44rem;
      justify-content: flex-start;
      padding-left: 2rem;

      &-country {
        width: 3.75rem;
        font-size: 1.4375rem;
        margin: 1.8rem 0 0.875rem 0;
        @if $isWsm {
          font-weight: bold;
          font-style: italic;
        }
      }

      &-flag {
        background-repeat: no-repeat;
        background-size: contain;
        width: 3.15rem;
        min-width: 3.15rem;
        height: 2.2rem;
        margin: 1.25rem 1rem 0 0.5rem;
      }
    }

    &-played {
      width: 12rem;
    }

    &-points {
      width: 16rem;
    }

    &-rewards {
      width: 40rem;
    }
  }
}
</style>
