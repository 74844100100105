import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const stationTrainPath: PathObjectInterface = {
  mainPath: {
    x: 2139.499983631075,
    y: 25.000009462237216,
  },
  nextPaths: [
    {
      x: 2827.4999731257567,
      y: 326.2499970458449,
    },
    {
      x: 2987.999971002341,
      y: 308.49999717623007,
      control1X: 2916.499975599349,
      control1Y: 331.000006526708,
      control2X: 2916.499975599349,
      control2Y: 331.000006526708,
    },
    {
      x: 3630,
      y: 0,
    },
  ],
}
