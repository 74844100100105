<template>
  <div>
    <span class="flexing h-full">
      <super-multiplier :multiplier-type="Multiplier.Training" />
    </span>
  </div>
</template>

<script lang="ts">
import { MECHANIC_SUPERTRAINING, Multiplier } from '@/globalVariables'
import { defineComponent } from 'vue'
import SuperMultiplier from '@/components/GamePass/SuperMultiplier.vue'

interface ComponentData {
  MECHANIC_SUPERTRAINING: typeof MECHANIC_SUPERTRAINING
  confirmButtonData: {
    btnId: string
    btnType: string
    btnSize: string
  }
  Multiplier: typeof Multiplier
}

export default defineComponent({
  name: 'TrainingDisciplineFooterSupertraining',
  components: {
    SuperMultiplier,
  },
  data(): ComponentData {
    return {
      MECHANIC_SUPERTRAINING,
      confirmButtonData: {
        btnId: 'training-confirm-btn',
        btnType: 'credit',
        btnSize: 'md',
      },
      Multiplier,
    }
  },
})
</script>

<style lang="scss" scoped>
.training-supertraining-text {
  width: 20rem;
  margin-right: 1.5rem;
  text-align: right;
  line-height: 2.35rem;
}

.btn-confirm.super-off {
  background-image: none;

  @if $isWsm {
    background-color: #19600b;
  }

  @if $isSsm {
    color: theme('colors.texts.buttons.darker');
    background-color: #119780;
  }
}
</style>
