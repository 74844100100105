<template>
  <div class="popup-wrapper">
    <div
      v-for="(filter, index) in filterData"
      :key="index"
      class="filter-row"
      :class="'z-' + (filterData.length - index)"
    >
      <span class="text-texts-standard-additional text-40 text-left">
        {{ $t(filter.text) }}
      </span>
      <app-select
        class="filter-row-select"
        :options="filter.selectbox.option"
        :default="filter.selectbox.default"
        @select="filterSelected($event, index)"
      />
    </div>

    <div class="buttons flex justify-center items-center">
      <app-button
        btn-size="md"
        btn-type="primary"
        :btn-text="$t('club.applyFilter')"
        class="cell-button"
        @click="applyFilter"
      />

      <app-button
        btn-size="md"
        btn-type="danger"
        :btn-text="$t('club.resetFilter')"
        class="cell-button"
        @click="resetFilter"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { NameValueString } from '@/interfaces/Global'

interface FilterData {
  text: string
  selectbox: {
    option: NameValueString[]
    default: string
  }
}

interface ComponentData {
  selectedInFilter: FilterData['selectbox']['option']
  defaultFilterData: string[]
}
export default defineComponent({
  name: 'ClubsFilterPopup',
  props: {
    filterData: {
      type: Array as PropType<FilterData[]>,
      default: () => [],
    },
  },
  emits: ['close', 'apply', 'reset'],
  data(): ComponentData {
    return {
      selectedInFilter: [],
      defaultFilterData: ['', ''],
    }
  },
  created(): void {
    this.fillData()
  },
  methods: {
    fillData(): void {
      const filterDatasLength = this.filterData.length
      for (let index = 0; index < filterDatasLength; index++) {
        const defaultValue = this.filterData[index].selectbox.default
        const valueName =
          this.filterData[index].selectbox.option.find(
            (option: NameValueString): boolean => option.value === defaultValue,
          )?.name ?? ''
        this.selectedInFilter.push({
          name: valueName,
          value: defaultValue,
        })
      }
      this.defaultFilterData = [
        this.filterData[0].selectbox.default,
        this.filterData[1].selectbox.default,
      ]
    },
    filterSelected(filter: string, index: number): void {
      this.selectedInFilter[index] = this.filterData[index].selectbox.option.find(
        (option: NameValueString): boolean => option.value === filter,
      )
    },
    applyFilter(): void {
      this.$emit('apply', this.selectedInFilter)
      this.$emit('close', true)
    },
    resetFilter(): void {
      this.$emit('reset')
      this.$emit('close', true)
    },
  },
})
</script>

<style lang="scss" scoped>
.popup-wrapper {
  width: 100%;
  margin: 0 auto;
  margin-top: 1.563rem;
  padding: 0 1.375rem;

  .filter-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    margin: 1rem 0;
    padding: 1rem 2rem;
    text-shadow: 0.6px 0.8px 0.9px rgba(0, 0, 0, 0.71);
    @if $isWsm {
      font-style: italic;
      background: rgba(35, 109, 155, 0.5);
      border: 0.125rem solid #5ba3dc;
    }
    @if $isSsm {
      text-transform: uppercase;
      background: #1d1f2c;
      border: 0.125rem solid #4c648f;
    }
    &::before {
      @if $isWsm {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 1.563rem;
        height: 1.563rem;
        background: transparent;
        border-bottom: 0.094rem solid #b5deff;
        border-left: 0.094rem solid #b5deff;
      }
    }

    &::after {
      @if $isWsm {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 1.563rem;
        height: 1.563rem;
        background: transparent;
        border-top: 0.094rem solid #b5deff;
        border-right: 0.094rem solid #b5deff;
      }
    }

    :deep(.filter-row-select .multiselect) {
      width: 27rem;
    }
  }

  .buttons {
    margin: 2rem;

    button {
      &:first-child {
        margin-right: 1.25rem;
      }
    }
  }
}
</style>
