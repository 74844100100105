<template>
  <section class="team-info flexing h-full" :data-event-theme="namespace">
    <div
      class="team-avatar flexing flex-col h-full relative -top-20"
      :class="'team-avatar-' + teamId"
    >
      <div class="space-y-8 relative -bottom-3">
        <section class="team-box flexing flex-col relative" :class="'team-box-' + teamId">
          <p
            class="team-box-text uppercase z-10"
            :class="{
              'text-50': $isWsm,
              'text-32 text-texts-standard-default font-bold mb-4': $isSsm,
            }"
          >
            {{ $te(teamId === 1 ? 'moreEnergy' : 'moreStarts') }}
          </p>
        </section>
        <app-button
          v-if="!done"
          btn-type="secondary"
          :btn-text="$te('choose')"
          btn-size="sm"
          class="mx-auto"
          :disabled="isButtonLoading"
          :loading="isButtonLoading"
          @click="isOpen = true"
        />
        <info-popup
          v-if="isOpen"
          class="!mt-0"
          :popup-title="$te('taskchainTaskSerie')"
          @close="isOpen = false"
        >
          <div class="flexing flex-col p-12">
            <div class="mx-36 mb-12 space-y-2">
              <p class="text-36 text-texts-standard-default">
                {{ $te('confirmChain') }}
              </p>
              <p
                class="text-32"
                :class="{
                  'text-texts-standard-name': $isWsm,
                  'text-texts-standard-default': $isSsm,
                }"
              >
                {{ $te('chainCompletionWarning') }}
              </p>
            </div>
            <app-button
              btn-type="confirm"
              :btn-text="$te('choose')"
              btn-size="xl"
              :disabled="isButtonLoading"
              :loading="isButtonLoading"
              style="width: 26rem"
              @click="setTeam(teamId)"
            />
          </div>
        </info-popup>
        <div v-if="done" class="team-tasks-done relative flexing">
          <p class="text-36 gradient-text-green font-bold uppercase">
            {{ $te('doneTasksAllTiersOneTeam') }}
          </p>
          <app-icon class="ml-4" icon-name="done-md" />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useTaskChainStore } from '@/store/pinia/events/taskChainStore'
import { mapActions, mapState } from 'pinia'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

interface ComponentData {
  isOpen: boolean
  isButtonLoading: boolean
}

export default defineComponent({
  name: 'TeamsTeamInfo',
  components: {
    InfoPopup,
  },
  props: {
    teamId: {
      type: Number as PropType<1 | 2>,
      required: true,
    },
    done: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      isOpen: false,
      isButtonLoading: false,
    }
  },
  computed: {
    ...mapState(useTaskChainStore, {
      currentTeamId: 'getCurrentTeamId',
    }),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
  },
  watch: {
    currentTeamId(): void {
      this.isOpen = false
    },
  },
  methods: {
    ...mapActions(useTaskChainStore, {
      _setTeam: 'setTeam',
    }),
    async setTeam(teamId: 1 | 2): Promise<void> {
      this.isButtonLoading = true
      await this._setTeam({ teamId })
      this.isButtonLoading = false
    },
  },
})
</script>

<style lang="scss" scoped>
$teams: (1, 2);

[data-event-theme='challenge_cup'] {
  @each $team in $teams {
    --events-teams-avatar-#{$team}: url('#{$path-events}challenge-cup/taskchain/teams/team-avatar-#{$team}.avif');
    --events-teams-box-#{$team}: url('#{$path-events}challenge-cup/taskchain/teams/team-box-#{$team}.avif');
    --events-teams-box-text-#{$team}: url('#{$path-events}challenge-cup/taskchain/teams/team-box-text-#{$team}.avif');
  }
  --events-teams-tasks-done-bg: url('#{$path-events}shared/taskchain/teams/done-bg.avif');
}

[data-event-theme='frozen'] {
  @each $team in $teams {
    --events-teams-avatar-#{$team}: url('#{$path-events}frozen-championship/taskchain/teams/team-avatar-#{$team}.avif');
    --events-teams-box-#{$team}: url('#{$path-events}frozen-championship/taskchain/teams/team-box-#{$team}.avif');
    --events-teams-box-text-#{$team}: url('#{$path-events}frozen-championship/taskchain/teams/team-box-text-#{$team}.avif');
  }
  --events-teams-tasks-done-bg: url('#{$path-events}shared/taskchain/teams/done-bg.avif');
}

.team-info {
  align-items: flex-start;

  .team-avatar {
    width: 36.25rem;
    justify-content: flex-end;
    @include background(null, cover);

    @if $isWsm {
      background-position: center top;
    }

    @if $isSsm {
      background-position: center 1rem;
    }

    @each $team in $teams {
      &.team-avatar-#{$team} {
        background-image: var(--events-teams-avatar-#{$team});
      }
    }
  }

  .team-box {
    justify-content: flex-end;
    width: 36.438rem;
    height: 10.125rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    @each $team in $teams {
      &.team-box-#{$team} {
        background-image: var(--events-teams-box-#{$team});

        &::before {
          background-image: var(--events-teams-box-text-#{$team});
        }
      }
    }

    &-text {
      @if $isWsm {
        color: rgba(217, 236, 255, 0.87);
        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.86);
      }

      @if $isSsm {
        text-shadow: 0.6px 0.8px 0.9px rgba(0, 0, 0, 0.71);
      }
    }
  }

  .team-tasks-done {
    width: 35.5rem;
    height: 4.375rem;
    background-image: var(--events-teams-tasks-done-bg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

// Ked zacneme pouzivat mode: 'jit', tak toto moze ist doprec.
// https://v2.tailwindcss.com/docs/just-in-time-mode
.\!mt-0 {
  margin-top: 0 !important;
}
</style>
