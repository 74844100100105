<template>
  <div v-if="activeQuests" class="map-task-tracker-wrapper relative">
    <map-task-tracker-header
      :is-tracker-extended="isTrackerExtended"
      :all-branches="taskTrackerQuests?.quests.length"
      :active-branches="activeQuests?.length"
      @click="clickOnExtendArrow"
    />
    <map-task-tracker-body
      :quests="activeQuests"
      @show-arrow-for-extend="isTrackerExtended = false"
      @show-arrow-for-hide="isTrackerExtended = true"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { setTasksActiveStates } from '@/helpers'
import MapTaskTrackerBody from '@/components/MapTaskTracker/MapTaskTrackerBody.vue'
import MapTaskTrackerHeader from '@/components/MapTaskTracker/MapTaskTrackerHeader.vue'
import { TASK_TRACKER_LOCAL_STORAGE } from '@/globalVariables'
import { useTaskTrackerStore } from '@/store/pinia/career/taskTracker'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'

import type { TaskTrackerQuest } from '@/interfaces/Quest'

interface ComponentData {
  isTrackerExtended: boolean
}

export default defineComponent({
  name: 'MapTaskTracker',
  components: {
    MapTaskTrackerHeader,
    MapTaskTrackerBody,
  },
  data(): ComponentData {
    return {
      isTrackerExtended: false,
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useTaskTrackerStore, {
      taskTrackerQuests: 'getTaskTrackerQuests',
    }),
    ...mapState(useResponseTaskStore, {
      getUpdateTaskTracker: 'getUpdateTaskTracker',
    }),
    activeQuests(): TaskTrackerQuest[] {
      if (this.getUpdateTaskTracker) {
        this.loadTaskTrackerQuests()
        this.setUpdateTaskTracker()
      }

      return this.taskTrackerQuests.quests.filter(
        (quest: TaskTrackerQuest): boolean => !quest.isEmpty,
      )
    },
  },
  async created(): Promise<void> {
    if (this.isTutorial) this.isTrackerExtended = true

    let localStorageTasksStates = JSON.parse(localStorage.getItem(TASK_TRACKER_LOCAL_STORAGE))
    const isTaskActive = (task: boolean): boolean => task === true
    if (localStorageTasksStates?.every(isTaskActive)) this.isTrackerExtended = true

    await this.loadTaskTrackerQuests()
  },
  methods: {
    ...mapActions(useResponseTaskStore, ['setUpdateTaskTracker']),
    ...mapActions(useTaskTrackerStore, {
      loadTaskTrackerQuests: 'loadTaskTrackerQuests',
    }),
    clickOnExtendArrow(): void {
      this.isTrackerExtended = !this.isTrackerExtended

      this.activeQuests.forEach((quest: TaskTrackerQuest): void => {
        quest.isActive = this.isTrackerExtended
      })

      setTasksActiveStates({ state: this.isTrackerExtended, setAllTasks: true })
    },
  },
})
</script>

<style lang="scss" scoped>
.map-task-tracker-wrapper {
  height: auto;
}
</style>
