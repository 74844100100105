<template>
  <section class="action-information" :class="shouldShow ? 'gold-background' : ''">
    <div class="texts">
      <section v-if="!shouldShow" class="text-60 font-bold uppercase italic gradient-text-gold">
        {{ $te('spin') }}
      </section>
      <section v-else class="text-60 font-bold text-texts-standard-default">
        <div v-if="exactReward && exactReward.parameter" class="flexing italic">
          <span class="information-text">{{ $filters.$formatNumber(exactReward.amount) }}</span>
          <app-main-icon :icon-name="exactReward.parameter" :icon-size="72" />
        </div>
      </section>
    </div>
  </section>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { LuckyWheelPrice } from '../LuckyWheelController.vue'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'SpinWheelActionInformation',
  props: {
    shouldShow: {
      type: Boolean,
      default: false,
    },
    prices: {
      type: Array as PropType<LuckyWheelPrice[]>,
      default: () => [],
    },
    initialReward: {
      type: Object as PropType<Nullable<LuckyWheelPrice>>,
      default: () => null,
    },
    winnerId: {
      type: Number as PropType<LuckyWheelPrice['id']>,
      default: 0,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    exactReward(): Nullable<LuckyWheelPrice> {
      if (this.initialReward?.id)
        return (
          this.prices.filter(
            ({ id }: LuckyWheelPrice): boolean => id === this.initialReward.id,
          )[0] || null
        )
      return (
        this.prices.filter(({ id }: LuckyWheelPrice): boolean => id === this.winnerId)[0] || null
      )
    },
  },
})
</script>

<style lang="scss" scoped>
section.action-information {
  display: flex;
  @include background(url($path-events + 'luckyWheel/lucky-wheel-under-spinText.avif'), 65% 100%);
  width: 55.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
  margin-bottom: 1rem;
}

.texts {
  margin-top: -2rem;

  .information-text {
    margin-right: 1rem;
  }
}

section.gold-background {
  background: url($path-events + 'luckyWheel/lucky-wheel-under-spinText-gold.avif') center no-repeat;
  background-size: 50% 100%;
}
</style>
