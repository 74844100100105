<template>
  <div class="lab-disciplines-col relative">
    <div
      class="lab-disciplines-col__wrapper"
      :class="{ 'lab-disciplines-col__wrapper--single-line': isEveryCellSingleLine }"
    >
      <lab-disciplines-cell
        v-for="(cell, i) in columnData"
        :key="i"
        :style="{
          top:
            cell.parent || Number(columnIndex) === 1
              ? `${VERTICAL_SPACING * cell.positionLevel}rem`
              : 0,
        }"
        :cell-data="cell"
        :line-direction="lineDirection(cell)"
        :is-every-cell-single-line="isEveryCellSingleLine"
        :has-ongoing-research-tree="hasOngoingResearchTree"
        @select-skill="$emit('selectSkill', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LabDisciplinesCell from '@/components/Lab/Disciplines/LabDisciplinesCell.vue'
import type { PropType } from 'vue'
import type { Cell } from '@/interfaces/lab/Cell'
import { LineHeight, LineDirection } from '@/interfaces/lab/Cell'

const VERTICAL_SPACING = 8.5
interface ComponentData {
  LineHeight: typeof LineHeight
  VERTICAL_SPACING: typeof VERTICAL_SPACING
}

export default defineComponent({
  name: 'LabDisciplinesColumn',
  components: {
    LabDisciplinesCell,
  },
  props: {
    columnData: {
      type: Array as PropType<Cell[]>,
      required: true,
    },
    columnIndex: {
      type: Number,
      default: 0,
    },
    hasOngoingResearchTree: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectSkill', 'reloadData'],
  data(): ComponentData {
    return {
      LineHeight,
      VERTICAL_SPACING,
    }
  },
  computed: {
    isEveryCellSingleLine(): boolean {
      return this.columnData.every((cell: Cell): boolean => cell.placement === null)
    },
  },
  methods: {
    lineDirection(cell: Cell): LineDirection | null {
      // sluzi na vykreslenie smeru prepojovacej ciary. Ak ma bunka nastaveny placement (vpravo, vlavo) nastavime na opacnu hodnotu. Ak ma nejaku poziciu nastavenu parent, pouzijeme ju..inak davame 'top'
      if (!cell || !cell.parent) return null

      if (cell.placement)
        return cell.placement === LineDirection.Right ? LineDirection.Left : LineDirection.Right

      const parentPosition: LineDirection =
        this.columnData.find((parentCell: Cell): boolean => parentCell.gddId === cell.parent)
          ?.placement ?? null
      if (parentPosition) return parentPosition

      return LineDirection.Top
    },
  },
})
</script>

<style lang="scss" scoped>
.lab-disciplines-col {
  &__wrapper {
    width: 27rem;
    height: 100%;

    &--single-line {
      width: 7.5rem;
    }
  }
}
</style>
