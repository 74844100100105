<template>
  <header
    class="header-accessories uppercase text-texts-standard-default text-34 flex items-center relative"
  >
    <p class="font-bold">
      {{ textLeft }}
    </p>
    <div class="header-accessories-right h-full absolute flex items-center justify-end">
      <p>{{ textRight }}:</p>
      <p class="text-texts-standard-important text-36 amount-text font-bold">
        {{ amount }}
      </p>
      <app-main-icon :icon-size="48" :icon-name="iconType" />
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    textLeft: {
      type: String,
      default: '',
    },
    textRight: {
      type: String,
      default: '',
    },
    amount: {
      type: Number,
      default: 0,
    },
    iconType: {
      type: String,
      default: 'wild_card',
    },
  },
})
</script>

<style lang="scss" scoped>
.header-accessories {
  display: flex;
  place-content: space-between;
  overflow: hidden;
  height: 5.6875rem;
  padding-left: 2.0625rem;
  border-style: solid;
  border-width: 0.125rem;

  @if $isSsm {
    background: rgb(32, 47, 83, 0.5);
    border-color: #4c648f;
  }

  @if $isWsm {
    background: #1b2f47;
    box-shadow: inset 0 0 18px 0 rgba(40, 137, 214, 0.5);
    border-image-slice: 1;
    border-image-source: linear-gradient(0deg, #5ba3dc, #bfe3ff, #5ba3dc);
  }

  &-right {
    position: relative;
    padding: 0 1rem 0 2.5rem;
    right: 0;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @if $isSsm {
        background: rgb(38, 48, 77, 0.5);
      }

      @if $isWsm {
        background: #253f5e;
        transform: skewX(-8deg);
      }

      z-index: -1;
    }

    .amount-text {
      margin-left: 0.5rem;
    }
  }
}
</style>
