<template>
  <div class="arena-menu flexing" />
  <menu-component
    menu-type="arena"
    :title="$t('arena.header')"
    :menu-items="{
      1: {
        text: $t('arena.competitions'),
        route: $getWebView('ArenaView'),
        id: getDisciplineId ?? firstUnlockedDisciplineId,
      },
      2: {
        text: $t('common.building'),
        route: $getWebView('ArenaBuilding'),
      },
      3: {
        text: $t('arena.history'),
        route: $getWebView('ArenaHistory'),
      },
    }"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { ARENA_DISCIPLINE_ID } from '@/globalVariables'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'

interface ComponentData {
  ARENA_DISCIPLINE_ID: typeof ARENA_DISCIPLINE_ID
}

export default defineComponent({
  name: 'ArenaMenu',
  data(): ComponentData {
    return {
      ARENA_DISCIPLINE_ID,
    }
  },
  computed: {
    ...mapState(useDisciplineStore, {
      firstUnlockedDisciplineId: 'getFirstUnlockedDisciplineId',
    }),
    getDisciplineId(): string {
      return localStorage.getItem(ARENA_DISCIPLINE_ID)
    },
  },
})
</script>
