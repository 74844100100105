<template>
  <div class="item-box-quality-flag items-center flex justify-start" :style="setBackgroundImage">
    <p v-if="!isPremium" class="uppercase text-28 text-texts-standard-default">
      {{ rankData.levelName }}
    </p>
  </div>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import type { RankData } from '@/interfaces/Equipment'
import type { Nullable } from '@/interfaces/utils'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'EquipmentItemBoxRank',
  props: {
    rankData: {
      type: Object as PropType<Nullable<RankData>>,
      default: () => null,
    },
    isPremium: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    setBackgroundImage(): string {
      return this.isPremium
        ? `background-image: url(${pathImages}equipment/badge-unique.avif)`
        : `background-image: url(${pathImages}equipment/BG-RANK-${this.rankData.levelNumber}.avif) `
    },
  },
})
</script>

<style lang="scss" scoped>
.item-box-quality-flag {
  width: 9.6875rem;
  height: 2.5625rem;
  padding-top: 0.125rem;
  padding-left: 1.25rem;
  @include background(null, contain);
}
</style>
