<template>
  <div
    v-if="isLoading"
    class="component-loading text-30 flexing"
    :style="{
      width,
      height,
      backgroundColor,
    }"
  >
    <svg
      id="Layer_1"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="24px"
      height="30px"
      viewBox="0 0 24 30"
      style="enable-background: new 0 0 50 50"
      xml:space="preserve"
    >
      <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="0.2; 1; .2"
          begin="0s"
          dur="0.6s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="height"
          attributeType="XML"
          values="10; 20; 10"
          begin="0s"
          dur="0.6s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="y"
          attributeType="XML"
          values="10; 5; 10"
          begin="0s"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="0.2; 1; .2"
          begin="0.15s"
          dur="0.6s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="height"
          attributeType="XML"
          values="10; 20; 10"
          begin="0.15s"
          dur="0.6s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="y"
          attributeType="XML"
          values="10; 5; 10"
          begin="0.15s"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="0.2; 1; .2"
          begin="0.3s"
          dur="0.6s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="height"
          attributeType="XML"
          values="10; 20; 10"
          begin="0.3s"
          dur="0.6s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="y"
          attributeType="XML"
          values="10; 5; 10"
          begin="0.3s"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </rect>
    </svg>
    <p v-if="message" class="text-48 text-texts-standard-important font-bold uppercase mt-8">
      {{ message }}
    </p>
  </div>
</template>

<script lang="ts">
import { useCoreStore } from '@/store/pinia/coreStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ComponentLoading',
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: '100%',
    },
    type: {
      type: String,
      default: 'primary',
      validator(value: string): boolean {
        return ['primary', 'secondary'].includes(value)
      },
    },
    message: {
      type: String,
      default: '',
    },
  },
  computed: {
    backgroundColor(): string | undefined {
      if (this.$isWsm && this.type === 'secondary') return 'rgba(12, 31, 55, 0.7)'
      if (this.message) return 'rgba(0, 0, 0, 0.7)'
      return undefined
    },
  },
  watch: {
    isLoading(value: boolean): void {
      if (!value) {
        this.setComponentLoading(false)
      }
    },
  },
  created(): void {
    if (this.isLoading) {
      this.setComponentLoading(true)
    }
  },
  methods: {
    ...mapActions(useCoreStore, ['setComponentLoading']),
  },
})
</script>

<style lang="scss" scoped>
.component-loading {
  flex-direction: column;

  svg {
    width: 5.125rem;
    height: 5.125rem;
  }

  svg path,
  svg rect {
    fill: rgb(255, 255, 255);
  }
}
</style>
