<template>
  <div class="main-leftbox absolute pointer-events-auto">
    <tippy
      v-if="(eventTeaserActive || eventActive || eventCollectionActive) && !isTutorial"
      theme="WSM1"
      placement="right"
      max-width="50rem"
      :on-show="isTooltipVisible"
    >
      <div
        class="l-box relative text-24 cursor-pointer clickable-element"
        :class="eventAssetsDirectory"
        :style="{
          backgroundImage: `url(${pathImages}events/${eventAssetsDirectory}/event-left-box.webp)`,
        }"
        @click="onLeftBoxTeaserClick"
      >
        <vue-countdown
          v-if="eventLeftBoxTimer"
          v-slot="{ days, hours, minutes, seconds }"
          class="l-box-time flexing text-texts-standard-default italic absolute bottom-0"
          :time="eventLeftBoxTimer * 1e3"
          @end="onLeftBoxTimerComplete"
        >
          <app-icon
            :icon-name="eventCollectionActive ? 'event-left-box-gift' : 'event-left-box-watch'"
            class="l-box-time-icon inline mr-2"
          />
          {{ formatTime(days, hours, minutes, seconds) }}
        </vue-countdown>
        <app-icon v-if="eventLocked" class="box-icon-lock l-box--locked" icon-name="lock-sm" />
      </div>
      <template v-if="eventLocked" #content>
        <div class="lock-tooltip">
          <div class="lock-tooltip-title flexing">
            <p class="uppercase font-bold text-30">
              {{ eventName }}
            </p>
          </div>
          <p
            class="lock-tooltip-text-margin text-28"
            v-html="
              $replacePlaceholder(
                $replaceHtmlPlaceholders(
                  $replacePlaceholder($t('common.mainCareerTaskRequired'), '%s', '{b} %s {/b}'),
                  'b',
                  'text-texts-standard-important',
                ),
                '%s',
                getMechanicUnlock(eventMechanicByType),
              )
            "
          />
        </div>
      </template>
    </tippy>
  </div>
</template>

<script lang="ts">
import { formatTime } from '@/helpers'
import { pathImages } from '@/globalVariables'
import { getRouteWebName } from '@/plugins'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      eventName: 'getEventName',
      eventCustomProps: 'getEventCustomProps',
      eventAssetsDirectory: 'getEventAssetsDirectory',
      eventTeaserActive: 'getEventTeaserActive',
      eventActive: 'getEventActive',
      eventCollectionActive: 'getEventCollectionActive',
      eventLeftBoxTimer: 'getEventLeftBoxTimer',
      eventLocked: 'getEventLocked',
      eventMechanicByType: 'getEventMechanicByType',
    }),
    ...mapState(useTutorialStore, {
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useResponseTaskStore, { getMechanicUnlock: 'getMechanicUnlock' }),
  },
  methods: {
    ...mapActions(useEventInfoStore, ['loadEvent']),
    ...mapActions(usePhaserGameIntegrationStore, ['destroyEventBuilding']),
    formatTime,
    onLeftBoxTeaserClick(): void {
      if (this.eventLocked) return
      if (this.eventTeaserActive) {
        this.$router.push({
          name: getRouteWebName('EventsTeaser'),
        })
        return
      }
      if (this.eventCollectionActive) {
        this.$router.push({
          name: getRouteWebName(this.eventCustomProps.routes.event.collection),
        })
        return
      }
      this.$router.push({
        name: getRouteWebName(this.eventCustomProps.routes.event.progress),
      })
    },
    async onLeftBoxTimerComplete(): Promise<void> {
      await this.loadEvent(true)

      if (this.eventLeftBoxTimer == null) {
        this.destroyEventBuilding()

        if ((this.$route.name as string).startsWith('Events')) {
          this.$router.push('/')
        }
      }
    },
    // Toto musi byt metoda, nie computed.
    isTooltipVisible(): boolean {
      return !!this.eventLocked
    },
  },
})
</script>

<style lang="scss" scoped>
.l-box {
  width: 15rem;
  height: 11.5625rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  &--locked {
    position: absolute;
    right: -0.125rem;
    bottom: 0;
  }

  &.autumn-fair {
    background-position: bottom;
  }

  &-time {
    width: 15rem;
    height: 1.9rem;

    &-icon {
      width: 1em !important;
      height: 1em !important;
    }
  }
}
</style>
