import type { ObjectsConfigInterface } from '@/map-phaser-new/interfaces/scene/objects/objectsConfigInterface'
import { animatedObjectsWithPathsConfig } from './animatedObjectsWithPaths/mainConfig'
import { animatedObjectsWithoutPathsConfig } from './animatedObjectsWithoutPaths/mainConfig'
import { interactiveObjectsConfig } from './interactiveObjects/mainConfig'

export const objectsConfig: ObjectsConfigInterface = {
  animatedObjectsWithPaths: animatedObjectsWithPathsConfig,
  animatedObjectsWithoutPaths: animatedObjectsWithoutPathsConfig,
  interactiveObjects: interactiveObjectsConfig,
}
