<template>
  <div
    class="lab-cell cursor-pointer absolute"
    :class="`lab-cell-position--${cellPlacement}`"
    @click="$emit('selectSkill', cellData.id)"
  >
    <lab-connection-line
      v-if="lineDirection && cellData.parent"
      :direction="lineDirection"
      :line-style="
        cellData.maxLevel === cellData.currentLevel ? LineStyle.Special : LineStyle.Normal
      "
    />
    <lab-icons :icon-data="cellData" :has-ongoing-research-tree="hasOngoingResearchTree" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LabIcons from '@/components/Lab/LabIcons.vue'
import LabConnectionLine from '@/components/Lab/LabConnectionLine.vue'
import type { PropType } from 'vue'
import type { Cell } from '@/interfaces/lab/Cell'
import { LineDirection, LineStyle } from '@/interfaces/lab/Cell'

interface ComponentData {
  LineDirection: typeof LineDirection
  LineStyle: typeof LineStyle
}

export default defineComponent({
  name: 'LabDisciplinesCell',
  components: {
    LabIcons,
    LabConnectionLine,
  },
  props: {
    cellData: {
      type: Object as PropType<Cell>,
      required: true,
    },
    lineDirection: {
      type: [String, null] as PropType<LineDirection | null>,
      required: true,
      validator(value: LineDirection): boolean {
        return [LineDirection.Top, LineDirection.Left, LineDirection.Right, null].includes(value)
      },
    },
    isEveryCellSingleLine: {
      type: Boolean,
      default: false,
    },
    hasOngoingResearchTree: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectSkill', 'reloadData'],
  data(): ComponentData {
    return {
      LineDirection,
      LineStyle,
    }
  },
  computed: {
    cellPlacement(): string {
      if (this.cellData.placement === null) {
        if (this.isEveryCellSingleLine) {
          return 'null'
        } else {
          return 'center'
        }
      }
      return this.cellData.placement
    },
  },
})
</script>
<style lang="scss" scoped>
.lab-cell:last-child::after {
  // Important: Offset required for used absolute positioning, visible from below behind the last child
  content: '';
  position: relative;
  display: block;
  height: 3rem;
}

.lab-cell-position {
  &--null {
    left: 0;
  }
  &--center {
    left: 10rem;
  }
  &--left {
    left: 0;
  }
  &--right {
    left: 19.5rem;
  }
}
</style>
