<template>
  <tippy v-if="getHighestBadge !== null" theme="WSM1" placement="right" max-width="50rem">
    <event-badge-icon :badge-name="getHighestBadge.type" :total-count="getHighestBadge.value" />

    <template #content>
      <div class="relative event-badge-tooltip__wrapper flex flex-col">
        <div class="event-badge-tooltip__header flexing">
          <p class="text-texts-standard-default text-36 font-bold">
            {{ $t('profile.eventLaurels') }}
          </p>
        </div>
        <div class="event-badge-tooltip__items">
          <template v-for="(badge, index) in eventBadges" :key="index">
            <div
              class="event-badge-tooltip__row event-badge-item"
              :class="getBadgeData(badge)?.value > 0 ? 'opacity-100' : 'opacity-50'"
            >
              <event-badge-icon
                :badge-name="getBadgeData(badge)?.type"
                :total-count="
                  getBadgeData(badge)?.value !== null ? getBadgeData(badge)?.value : null
                "
                :is-tooltip="true"
                class="mr-7"
              />
              <span
                class="mr-3"
                :class="{ 'text-texts-standard-important': getBadgeData(badge)?.value > 0 }"
              >
                {{ getBadgeData(badge)?.value > 0 ? getBadgeData(badge)?.value + 'x' : '-' }}
              </span>
              <span>
                {{
                  getBadgeData(badge)?.type === 'gold'
                    ? $t('profile.eventMajorVictory')
                    : $replacePlaceholder(
                        $t('profile.eventMajorRank'),
                        '{rank}',
                        getRankNumber(getBadgeData(badge)?.type),
                      )
                }}
              </span>
            </div>
          </template>
        </div>
        <div class="event-badge-tooltip__footer flexing">
          <p class="text-texts-standard-default">
            {{ $t('profile.eventLaurelInfo') }}
          </p>
        </div>
      </div>
    </template>
  </tippy>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import EventBadgeIcon from '@/components/GlobalComponents/EventBadgeIcon.vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { EventBadgeInterface } from '@/interfaces/EventBadgeInterface'

interface ComponentData {
  eventBadges: string[]
}

export default defineComponent({
  name: 'EventBadge',
  components: {
    EventBadgeIcon,
  },
  props: {
    currentBadges: {
      type: Array as PropType<EventBadgeInterface[]>,
      default: () => [],
    },
  },
  data(): ComponentData {
    return {
      eventBadges: ['gold', 'silver', 'bronze'],
    }
  },
  computed: {
    getHighestBadge(): Nullable<EventBadgeInterface> {
      const highestBadge =
        this.currentBadges?.find((item: EventBadgeInterface): boolean => item.top === true) || null
      if (highestBadge !== null) {
        return {
          type: highestBadge.type,
          value: highestBadge.value,
          top: highestBadge.top,
        }
      } else {
        return null
      }
    },
  },
  methods: {
    getRankNumber(type: string): string {
      switch (type) {
        case 'silver':
          return '2'
        case 'bronze':
          return '3'
        default:
          return '–'
      }
    },
    getBadgeData(type: string): EventBadgeInterface {
      const badgeData =
        this.currentBadges?.find((item: EventBadgeInterface): boolean => item.type === type) || null
      if (badgeData !== null) {
        return badgeData
      } else {
        return { type, value: 0, top: false }
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.event-badge-tooltip {
  &__wrapper {
    padding: 0 2rem;
  }

  &__header {
    position: relative;
    height: 3.125rem;
    background: linear-gradient(to left, rgba(15, 40, 57, 0), #1e6c9b, rgba(15, 40, 57, 0));
    margin-bottom: 1.688rem;

    &:after {
      content: '';
      position: absolute;
      bottom: 0rem;
      left: 0;
      width: 100%;
      height: 0.063rem;
      background: linear-gradient(to right, #a4a4a400, #a4a4a4, #a4a4a4, #a4a4a400);
    }
  }

  &__items {
    & .event-badge-item:not(:last-child) {
      margin-bottom: 2.5rem;
    }
  }

  &__footer {
    position: relative;
    height: 4.688rem;
    margin-top: 2.188rem;

    &:before {
      content: '';
      position: absolute;
      top: 0rem;
      left: 0;
      width: 100%;
      height: 0.125rem;
      background: linear-gradient(to right, #a4a4a400, #a4a4a4, #a4a4a4, #a4a4a400);
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    height: 4rem;
    justify-content: flex-start;
    align-items: center;

    .icon {
      margin-right: 1.875rem;
    }
  }
}
</style>
