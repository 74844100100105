import type { AtlasDataInterface } from '@/map-phaser-new/interfaces'
import { pathImages } from '@/globalVariables'

export const clubsBuildingsAtlasLoadData: () => AtlasDataInterface[] = () => {
  return [
    {
      name: 'clubsBuildings',
      compressed: true,
      multiatlas: true,
      data: {
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/clubBuildings/club-buildings-etc2rgba.json',
        },
        astc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/clubBuildings/club-buildings-astc12.json',
        },
        img: {
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/clubBuildings/club-buildings.json',
        },
      },
    },
    {
      name: 'clubsBuildingsOutlines',
      compressed: true,
      multiatlas: true,
      data: {
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/clubOutlines/club-overlay-etc2rgba.json',
        },
        astc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/clubOutlines/club-overlay-astc12.json',
        },
        img: {
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/clubOutlines/club-overlay.json',
        },
      },
    },
    {
      name: 'clubsLockedBuildings',
      compressed: true,
      multiatlas: true,
      data: {
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/clubLocked/club-locked-etc2rgba.json',
        },
        astc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/clubLocked/club-locked-astc12.json',
        },
        img: {
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/clubLocked/club-locked.json',
        },
      },
    },
  ]
}
