import { MAP_ARENA } from '@/globalVariables'
import { GameType } from '@/globalVariables/gameTypes'

import { StageType } from '@/interfaces/Tutorial'
import type { TutorialStage } from '@/interfaces/Tutorial'

const summerStages: TutorialStage[] = [
  // narative saying you unlocked a new discipline
  {
    id: 59,
    name: 'unlockSprint100MetersNarrative',
    type: StageType.Narrative,
    overlay: false,
    pageName: 'LayoutViewTutorial',
    texts: [],
  },
  // focus on arena building with gsap animation
  {
    id: 60,
    name: 'clickOnArenaBuildingSprint100Meters',
    discipline: 11,
    type: StageType.Focus,
    overlay: false,
    pageName: 'LayoutViewTutorial',
    clientId: [MAP_ARENA],
    texts: [],
  },
  // focus on unlocked discipline with overlay and gsap animation
  {
    id: 61,
    name: 'clickOnDisciplineSprint100Meters',
    type: StageType.ClientInput,
    overlay: true,
    pageName: 'ArenaViewTutorial',
    clientId: ['arena-discipline-11'],
    discipline: 11,
    texts: [],
  },
]

const winterStages: TutorialStage[] = [
  // narative saying you unlocked a new discipline
  {
    id: 59,
    name: 'unlockDownhillNarrative',
    type: StageType.Narrative,
    overlay: false,
    pageName: 'LayoutViewTutorial',
    texts: [],
  },
  // focus on arena building with gsap animation
  {
    id: 60,
    name: 'clickOnArenaBuildingDownhill',
    discipline: 1,
    type: StageType.Focus,
    overlay: false,
    pageName: 'LayoutViewTutorial',
    clientId: [MAP_ARENA],
    texts: [],
  },
  // focus on unlocked discipline with overlay and gsap animation
  {
    id: 61,
    name: 'clickOnDisciplineDownhill',
    type: StageType.ClientInput,
    overlay: true,
    pageName: 'ArenaViewTutorial',
    clientId: ['arena-discipline-1'],
    discipline: 1,
    texts: [],
  },
]

/**
  Tutorial for discipline unlocked
 */
export default class TutorialUnlockDiscipline1 {
  stages(): TutorialStage[] {
    return window.gameName === GameType.Winter ? winterStages : summerStages
  }
}
