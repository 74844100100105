<template>
  <div class="prestige-info-popup">
    <main>
      <div class="prestige-info-popup-image" />
      <p class="text-texts-standard-default text-30">
        {{ $t('club.prestigeDesc1') }}
      </p>
      <h2 class="text-texts-standard-important text-36 uppercase">
        {{ $t('club.actualPrestige') }}
      </h2>
      <div class="actual-prestige flexing">
        <p class="text-texts-standard-default text-36 font-bold revert-skew mx-2">
          {{ $filters.$formatNumber(getClubInfo.prestige) }}
        </p>
        <app-main-icon :icon-size="48" icon-name="prestige" class="revert-skew" />
      </div>
      <p class="text-texts-standard-default text-30 font-bold text-margin-bottom">
        {{ $t('club.prestigeDesc2') }}
      </p>
    </main>
  </div>
</template>

<script lang="ts">
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PrestigeInfoPopup',
  computed: {
    ...mapState(useClubStore, {
      getClubInfo: 'getClubInfo',
    }),
  },
})
</script>

<style lang="scss" scoped>
.prestige-info-popup {
  width: 103.625rem;

  main {
    margin: 0 12.25rem;

    .prestige-info-popup-image {
      width: 43.375rem;
      height: 21.313rem;
      background-image: url($path-clubs + 'map/prestige-info-pupup-image.avif');
      background-size: cover;
      margin: 0 auto;
    }

    h2 {
      margin: 2.625rem 0;
    }

    .actual-prestige {
      width: 16.875rem;
      height: 4.063rem;
      border: 0.125rem solid #f6c717;
      margin: 0 auto 2rem;
      transform: $skew-value;
    }

    .text-margin-bottom {
      margin-bottom: 3.75rem;
    }
  }
}
</style>
