import type { ClubFormattedRanks, Ranking } from '@/types/rankingTypes'

const sortRanks = (rankings: Ranking | ClubFormattedRanks): [string, any][] => {
  return (
    Object.entries(rankings)
      // not the "player" row
      .filter((dataArr: [string, any]): boolean => {
        return dataArr[0] !== 'player'
      })
      // sort by points
      .sort((row1: [string, any], row2: [string, any]): number => {
        return row2[1].RANKING_POINTS - row1[1].RANKING_POINTS
      })
  )
}

export const addRanks = (rankings: Ranking | ClubFormattedRanks): Ranking | ClubFormattedRanks => {
  const sortedRankings = sortRanks(rankings)
  for (const userId in rankings) {
    // add "rank" property based on the sorted rankings array
    rankings[userId] = {
      rank:
        sortedRankings.findIndex((data: [string, any]): boolean => {
          return data[0] === userId
        }) + 1,
      ...rankings[userId],
    }
  }
  return rankings
}
