<template>
  <div
    v-if="!isContentLoading"
    class="events-warehouse-league flex flex-col items-center justify-center h-full w-full relative"
  >
    <!-- IF USER HAS ACCESS -->
    <div
      v-if="hasClub && club.members.length >= getMinimumClubMembers && isInCurrentRound"
      class="w-full h-full"
    >
      <div class="events-warehouse-league-top-banner flex justify-between items-center relative">
        <div class="events-warehouse-league-top-banner-left flexing">
          <app-icon icon-name="info-rounded" class="mr-4 pointer-events-none" />
          <p class="text-30 text-texts-standard-default text-left">
            {{ $te('clubLeagueInfo') }}
          </p>
        </div>
        <league-component v-if="!isRecalculating" :league-type="state.league" />
      </div>

      <div class="flexing w-full">
        <events-warehouse-league-club-panel />
        <div class="w-full h-full">
          <events-warehouse-league-table />
        </div>
      </div>
    </div>

    <!-- IF USER DOES NOT HAVE CLUB -->
    <div v-if="!hasClub" class="events-warehouse-league-locked flexing">
      <app-icon icon-name="lock-xxl mr-8" />
      <p class="events-warehouse-league-locked-text text-50 text-texts-standard-default font-bold">
        {{
          $replacePlaceholder($te('clubLeagueLockedInfo'), '%s', getMinimumClubMembers.toString())
        }}
      </p>
    </div>

    <div
      v-if="!hasClub"
      class="events-warehouse-league-locked-bottom-bar flex items-center justify-between"
    >
      <div class="flexing">
        <app-icon icon-name="info-rounded mr-6" />

        <p class="text-34 text-texts-standard-default">
          {{ $te('joinOrCreateClub') }}
        </p>
      </div>
      <app-button
        btn-id="league-go-to-clubs-btn"
        btn-size="md"
        btn-type="secondary"
        :btn-text="$te('clubs')"
        @click="goToClubs"
      />
    </div>

    <!-- IF USER HAS CLUB BUT NOT ENOUGH MEMBERS OR NEW ROUND DIDNT START YET -->
    <div
      v-if="(hasClub && club.members.length < getMinimumClubMembers) || isWaitingForNextRound"
      class="events-warehouse-league-members-locked flex justify-center items-center flex-col w-full"
    >
      <div v-if="club.members.length < getMinimumClubMembers || !hasClub" class="flexing mb-14">
        <app-icon icon-name="lock-md mr-8" />
        <p class="text-40 text-texts-standard-default font-bold">
          {{
            $replacePlaceholder($te('clubLeagueLockedInfo'), '%s', getMinimumClubMembers.toString())
          }}
        </p>
      </div>
      <div class="events-warehouse-league-members-locked-club">
        <div class="events-warehouse-league-members-locked-club-wrapper flexing">
          <clubs-avatar
            :background-id="Number(club?.logoBgId)"
            :logo-id="Number(club?.logoId)"
            class="events-warehouse-league-members-locked-club-avatar"
          />
          <div
            class="flex flex-col justify-center items-center events-warehouse-league-members-locked-right-side"
          >
            <div class="events-warehouse-league-members-locked-club-name">
              <p class="text-50 text-texts-standard-important uppercase font-bold px-12">
                {{ club?.name }}
              </p>
            </div>
            <div class="events-warehouse-league-members-locked-club-members-amount">
              <p class="text-60 text-texts-standard-default uppercase font-bold">
                {{ $te('memberCount') }}:
                <span class="text-texts-standard-important ml-2">{{ club.members.length }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <p
        v-if="hasClub && club.members.length >= getMinimumClubMembers"
        class="text-32 text-texts-standard-default mt-12"
      >
        {{
          $replacePlaceholder(
            $te('nextRoundOK'),
            '{date}',
            getFormattedDateFromYMD(config?.date_round_start),
          )
        }}
      </p>

      <div class="events-warehouse-league-members-locked-time flexing">
        <p class="text-32 text-texts-standard-default mr-8">{{ $te('nextRoundIn') }}</p>
        <app-timer :time="getRoundRemainingTime" />
      </div>
    </div>
  </div>

  <component-loading :is-loading="isContentLoading" />
</template>

<script lang="ts">
import ClubsAvatar from '@/components/Club/ClubsAvatar.vue'
import LeagueComponent from '@/components/Events/EventsPopups/LeagueComponent.vue'
import EventsWarehouseLeagueClubPanel from '@/components/Events/EventsWarehouse/EventsWarehouseLeague/EventsWarehouseLeagueClubPanel.vue'
import EventsWarehouseLeagueTable from '@/components/Events/EventsWarehouse/EventsWarehouseLeague/EventsWarehouseLeagueTable.vue'
import { getFormattedDateFromYMD } from '@/helpers'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useLeagueEventStore } from '@/store/pinia/events/leagueStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  isContentLoading: boolean
}

export default defineComponent({
  name: 'EventsWarehouseLeague',
  components: {
    LeagueComponent,
    EventsWarehouseLeagueClubPanel,
    EventsWarehouseLeagueTable,
    ClubsAvatar,
  },
  data(): ComponentData {
    return {
      isContentLoading: false,
    }
  },
  computed: {
    ...mapState(useLeagueEventStore, {
      state: 'getState',
      config: 'getConfig',
      getRoundRemainingTime: 'getRoundRemainingTime',
      getMinimumClubMembers: 'getMinimumClubMembers',
      isRecalculating: 'getIsRecalculating',
    }),
    ...mapState(useUserStore, {
      hasClub: 'getHasClub',
      playerClubId: 'getPlayerClubId',
    }),
    ...mapState(useClubStore, {
      club: 'getClubInfo',
    }),
    isInCurrentRound(): boolean {
      return this.state?.in_league
    },
    isWaitingForNextRound(): boolean {
      return (
        this.hasClub &&
        this.club.members.length >= this.getMinimumClubMembers &&
        !this.isInCurrentRound
      )
    },
  },
  async created(): Promise<void> {
    this.isContentLoading = true
    await this.loadConfig()
    await this.loadState()

    if (this.hasClub && this.playerClubId) {
      await this.loadClubInfo(this.playerClubId)
    }

    this.isContentLoading = false
  },
  methods: {
    ...mapActions(useLeagueEventStore, {
      loadConfig: 'loadConfig',
      loadState: 'loadState',
    }),
    ...mapActions(useClubStore, {
      loadClubInfo: 'loadClubInfo',
    }),
    getFormattedDateFromYMD,
    goToClubs(): void {
      this.$router.push({
        name: this.$getWebView('ClubsAvailable'),
        query: { homepage: 'true' },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
@mixin leagueDarkBackground {
  border-style: solid;
  border-width: 0.188rem;
  border-image-slice: 1;

  @if $isWsm {
    background-image: linear-gradient(
      to right,
      transparent 0%,
      rgba(22, 80, 138, 0.5) 25%,
      rgba(22, 80, 138, 0.5) 75%,
      transparent 100%
    );
    border-image-source: linear-gradient(
      to right,
      transparent 0%,
      rgba(32, 109, 186, 0.5) 25%,
      rgba(32, 109, 186, 0.5) 75%,
      transparent 100%
    );
  }

  @if $isSsm {
    background-image: linear-gradient(to right, transparent, #463a7c, transparent);
    border-image-source: linear-gradient(to right, transparent, #6857b2, transparent);
  }
}

.events-warehouse-league {
  &-top-banner {
    width: 100%;
    height: 6.5rem;
    padding: 0 2rem;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      @if $isWsm {
        background-image: url($path-events + 'frozen-championship/warehouse/convertor/infoheader-bg.avif');
      }

      @if $isSsm {
        background-image: url($path-events + 'frozen-championship/warehouse/league/infoheader-bg.avif');
      }
    }

    & > * {
      z-index: 1;
    }

    &-left {
      padding-bottom: 0.3rem;
    }
  }

  &-locked {
    @include leagueDarkBackground;
    padding: 2rem;
    width: 100%;
    margin-bottom: 8.625rem;

    &-text {
      width: 30%;
    }

    &-bottom-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 8.625rem;
      padding-left: $default-margin;
      padding-right: $default-margin;
      width: 100%;

      @if $isWsm {
        background-image: linear-gradient(
          to right,
          rgba(22, 80, 138, 0.3) 0%,
          rgba(22, 80, 138, 0.6) 10%,
          rgba(22, 80, 138, 0.6) 90%,
          rgba(22, 80, 138, 0.3) 100%
        );
      }

      @if $isSsm {
        background-image: linear-gradient(to right, transparent, #463a7c, transparent);
      }
    }
  }

  &-members-locked {
    height: 100%;

    &-right-side {
      margin-left: 0;
      margin-right: auto;
    }

    &-club {
      @include leagueDarkBackground;
      width: 100%;
      height: 14.375rem;
      margin-bottom: 3rem;
      margin-top: 1rem;

      &-wrapper {
        height: 100%;
        width: 70%;
        margin: 0 auto;
        padding-right: 4rem;
      }

      &-name {
        height: 5.938rem;
        padding: 1rem 1rem 1rem 0;

        @if $isWsm {
          background-image: linear-gradient(
            to right,
            transparent 0%,
            rgba(32, 109, 186, 0.4) 25%,
            rgba(32, 109, 186, 0.4) 75%,
            transparent 100%
          );
        }

        @if $isSsm {
          background-image: linear-gradient(to right, transparent, #302f5c, transparent);
        }
      }

      &-members-amount {
        margin-top: 1rem;
      }

      &-avatar {
        width: 20%;
        margin-right: 0;
      }
    }

    &-time {
      position: absolute;
      right: 0;
      top: 2rem;
    }
  }
}
</style>
