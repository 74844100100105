<template>
  <span
    v-tippy="{
      content: text,
      placement: 'bottom',
      theme: 'WSM',
    }"
    class="premium-rounded-icon flexing"
    :class="[type, `size-${size}`]"
  >
    <app-icon :icon-name="`${type}-${size}`" />
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

enum PremiumRoundedIconType {
  lock = 'lock',
  done = 'done',
}

export default defineComponent({
  name: 'PremiumRoundedIcon',
  props: {
    type: {
      type: String,
      required: true,
      validator(value: PremiumRoundedIconType): boolean {
        return [PremiumRoundedIconType.lock, PremiumRoundedIconType.done].includes(value)
      },
    },
    text: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
      validator(value: string): boolean {
        return ['sm', 'md'].includes(value)
      },
    },
  },
})
</script>

<style lang="scss" scoped>
.premium-rounded-icon {
  border-radius: 100%;

  &.size-md {
    width: 5.625rem;
    height: 5.625rem;
  }

  &.size-sm {
    width: 4.125rem;
    height: 4.125rem;
  }

  &.lock {
    @if $isWsm {
      background: radial-gradient(circle, rgba(55, 127, 184, 1) 35%, rgba(35, 52, 65, 1) 100%);
      border: 0.125rem solid rgba(255, 201, 73, 1);
    }
    @if $isSsm {
      background: radial-gradient(circle, #5c657f 35%, #22293e 100%);
      border: 0.125rem solid #e1e1e1;
    }
  }

  &.done {
    @if $isWsm {
      background: radial-gradient(circle, rgba(55, 127, 184, 1) 35%, rgba(35, 52, 65, 1) 100%);
      border: 0.125rem solid #3fe443;
    }
    @if $isSsm {
      background: radial-gradient(circle, #5c657f 35%, #22293e 100%);
      border: 0.125rem solid #0ee7cc;
    }
  }
}
</style>
