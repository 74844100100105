<template>
  <aside class="side-menu-tasks h-full">
    <app-scrollbar
      v-if="!isLoading"
      class="side-menu-tasks-scroll h-full"
      height="100%"
      width="23.5rem"
      :scroll="isTutorial ? 'x' : 'y'"
      :slide="isTutorial ? 'x' : 'y'"
      scroll-to-element=".side-menu-box.active-box"
      scroll-to-element-align="start"
    >
      <section
        v-for="(discipline, index) in disciplinesMenuData"
        :key="index"
        class="side-menu-box relative cursor-pointer"
        :class="{
          'active-box': $route.params?.id
            ? discipline.id === Number($route.params.id)
            : discipline.id === activeDisciplineBoxId,
        }"
        @click="clickOnDisciplineBox(discipline)"
      >
        <arrow-animation border="box" position="right" :tutorial="isTutorialStep(discipline)">
          <div
            v-if="discipline.branchType === CareerBranch.Main"
            class="main-career-image icon-career-gold-152"
            :class="{
              'locked-opacity': discipline.lock,
            }"
          />

          <div
            v-if="discipline.branchType !== CareerBranch.Main && discipline.levelToUnlock !== 99"
            :id="`side-menu-box-${index}`"
            :class="getAdequateClass(discipline)"
          />
          <div
            v-if="discipline.levelToUnlock === 99"
            class="coming-soon-image"
            :class="{ 'locked-opacity': discipline.lock }"
          />

          <footer
            class="flexing text-28 text-white absolute w-full uppercase"
            :class="{
              'active-bg': $route.params?.id
                ? discipline.id === Number($route.params.id)
                : discipline.id === activeDisciplineBoxId,
            }"
          >
            <p v-if="discipline.branchType === CareerBranch.Main">
              {{ $t('career.mainCareer') }}
            </p>
            <p v-else>
              {{ $translateDiscipline(discipline.id) }}
            </p>
          </footer>

          <div
            v-if="discipline.lock && discipline.levelToUnlock !== 99"
            class="locked-icon flex flex-col"
          >
            <app-icon
              icon-name="lock-lg"
              :class="{ 'temporary-lock-icon': discipline.temporaryLock }"
            />
            <p v-if="!discipline.temporaryLock" class="text-28 text-white locked-icon-text">
              {{ $t('career.lockedDiscipline') }}
            </p>
          </div>
          <div v-if="discipline.levelToUnlock === 99" class="coming-soon-text">
            <p class="text-texts-standard-additional uppercase text-40">Coming <br />soon</p>
          </div>
          <div
            v-if="discipline.isTracked"
            class="tracked-label absolute"
            :style="{
              backgroundImage: `url(${pathImages}career/tracked_label.avif)`,
            }"
          />
          <app-notification v-if="isNotification(discipline)" :count="1" />
        </arrow-animation>
      </section>
    </app-scrollbar>
    <component-loading :is-loading="isLoading" />
  </aside>
</template>

<script lang="ts">
import ArrowAnimation from '@/components/ArrowAnimation.vue'
import { pathImages } from '@/globalVariables'
import { GameType } from '@/globalVariables/gameTypes'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import { CareerBranch } from '@/enums/CareerBranch'
import type { PropType } from 'vue'
import type { ClassValue } from '@/interfaces/utils'
import type { DisciplineMenuData } from '@/interfaces/DisciplineMenu'
import type { NotificationCareerQuest } from '@/interfaces/global/Notification'

interface ComponentData {
  CareerBranch: typeof CareerBranch
  pathImages: typeof pathImages
  activeDisciplineBoxId: number
}

export default defineComponent({
  name: 'SideMenuBox',
  components: {
    ArrowAnimation,
  },
  props: {
    disciplinesMenuData: {
      type: Array as PropType<DisciplineMenuData[]>,
      default: () => [],
    },
  },
  emits: ['clickOnDiscipline'],
  data(): ComponentData {
    return {
      CareerBranch,
      pathImages,
      activeDisciplineBoxId: 0,
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      actualStage: 'getActualStage',
      isTutorial: 'isTutorial',
    }),
    ...mapState(useDisciplineStore, {
      isLoading: 'getIsLoading',
    }),
    ...mapState(useResponseTaskStore, {
      notifications: 'getNotifications',
    }),
  },
  methods: {
    getAdequateClass(discipline: DisciplineMenuData): ClassValue {
      if (this.$gameName === GameType.Summer) {
        return [
          `image-discipline-${Number(discipline.id) - 10}`,
          { 'locked-opacity': discipline.lock },
        ]
      }

      return [`image-discipline-${Number(discipline.id)}`, { 'locked-opacity': discipline.lock }]
    },
    isNotification(discipline: DisciplineMenuData): boolean {
      const notificationMain = this.notifications?.career_quests.some(
        (notification: NotificationCareerQuest): boolean =>
          notification.branch === CareerBranch.Main && discipline.branchType === CareerBranch.Main,
      )

      const notificationSecondary = this.notifications?.career_quests.some(
        (notification: NotificationCareerQuest): boolean =>
          notification.branch === CareerBranch.Secondary &&
          notification.discipline === discipline.id,
      )

      return notificationMain || notificationSecondary
    },
    clickOnDisciplineBox(discipline: DisciplineMenuData): void {
      this.activeDisciplineBoxId = discipline.id
      this.$emit('clickOnDiscipline', discipline)
    },
    isTutorialStep(discipline: DisciplineMenuData): boolean {
      return (
        this.actualStage?.name === 'focusDisciplineBranchCareer' &&
        discipline.branchType !== CareerBranch.Main &&
        !discipline.locked
      )
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/career-icons.scss';

aside {
  width: 25rem;

  .side-menu-tasks-scroll {
    padding-top: 1rem;
    // "TRACKED" label fix for career tasks
    scroll-padding-top: 20px;
  }

  .side-menu-box {
    height: 15.8125rem;
    width: 19.5rem;
    border-style: solid;
    border-width: 0.125rem;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.5);
    margin-bottom: 2rem;
    margin-left: 1.2rem;

    @if $isWsm {
      border-color: #3f6b93;
      background: #254257;
    }

    @if $isSsm {
      border-color: #586b9d;
      background: linear-gradient(to right, #34415d 0%, #273249 100%);
    }

    footer {
      bottom: 0rem;
      height: 3.9375rem;
      @if $isWsm {
        background: #0c1e36;
      }
      @if $isSsm {
        background: #273249;
      }
    }
  }

  .active-box {
    border-width: 0.125rem;
    border-style: solid;

    @if $isWsm {
      border-color: #f7cd27;
    }
    @if $isSsm {
      border-color: #ffc640;
    }

    &:after {
      z-index: 2;
      content: '';
      top: 50%;
      right: -1.2rem;
      transform: translate(0, -50%) rotate(-45deg);
      position: absolute;
      width: 0;
      height: 0;
      border-right: 1.5rem solid #f7cd27;
      border-left: 1.5rem solid transparent;
      border-top: 1.5rem solid transparent;
    }
  }
}

.active-box {
  border: 0.125rem solid #f7cd27;

  &:after {
    z-index: 2;
    content: '';
    top: 50%;
    right: -1.2rem;
    transform: translate(0, -50%) rotate(-45deg);
    position: absolute;
    width: 0;
    height: 0;
    border-right: 1.5rem solid #f7cd27;
    border-left: 1.5rem solid transparent;
    border-top: 1.5rem solid transparent;
  }
}

.main-career-image {
  margin: 0 auto;
  top: 1rem;
  position: relative;
}

@for $i from 1 through 14 {
  .image-discipline-#{$i} {
    background: url($path-career + "disciplines/"+#{$i}+".avif") center center no-repeat;
    width: 100%;
    height: 100%;
    background-size: contain;
  }
}

.locked-icon {
  position: absolute;
  top: 9%;
  left: 2%;
  width: 100%;
  align-items: center;

  &-text {
    margin-top: 1rem;
  }
}

.locked-opacity {
  opacity: 0.3;
}

.active-bg {
  color: #000000 !important;
  @if $isWsm {
    background: linear-gradient(
      10deg,
      rgba(255, 239, 132, 1) 0%,
      rgba(246, 199, 23, 1) 60%,
      rgba(246, 199, 23, 1) 100%
    ) !important;
  }
  @if $isSsm {
    background: #ffc640 !important;
  }
}

.coming-soon-text {
  position: absolute;
  top: 17%;
  left: 15%;
  width: 70%;
  border-left: 0.063rem solid #377fb8;
  border-right: 0.063rem solid #377fb8;
  align-items: center;
  line-height: 1.3;
}

.tracked-label {
  width: 8rem;
  height: 1.875rem;
  left: -1.5rem;
  top: -0.5rem;
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.coming-soon-image {
  background: url($path-career + 'coming_soon_bg.jpeg') center center no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
}

.temporary-lock-icon {
  transform: scale(0.6);
  position: absolute;
  right: -1.3rem;
  top: -4rem;
}
</style>
