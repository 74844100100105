<template>
  <section class="boss-fight-pregame relative">
    <main
      class="boss-fight-pregame-main relative w-full h-full text-texts-standard-default text-32"
    >
      <section>
        <section v-if="isSpecialBossFight" class="boss-fight-pregame-main-title">
          <p class="text-texts-standard-default text-40 uppercase font-bold">
            {{ specialBossFightTitle }}
          </p>
        </section>
        <section class="boss-fight-pregame-main-list h-full">
          <app-scrollbar width="58.125rem" :height="mainContentHeight" scroll="y" slice="y">
            <table-starts
              title-type="opponentPower"
              :opponents="opponents"
              :discipline-id="disciplineId"
            />
          </app-scrollbar>
        </section>
      </section>

      <pre-boss-fight-user-info
        :discipline-id="disciplineId"
        @start="getBoss"
        @refresh-opponents="getOpponents"
      />
    </main>

    <skip-tutorial-popup
      v-if="showSkipTutorialPopup"
      :discipline-id="disciplineId"
      :quest-id="questId"
      :multiplier="activeMultiplicator(Multiplier.Arena).name"
      :no-tutorial-mode="mode?.boss_fight"
      @hide-popup="hidePopup"
    />
  </section>
</template>

<script lang="ts">
import PreBossFightUserInfo from '@/components/Career/PreBossFight/PreBossFightUserInfo.vue'
import SkipTutorialPopup from '@/components/SkipTutorialPopup.vue'
import TableStarts from '@/components/Table/TableStarts.vue'
import { arenaBossFightPreFightOpponentsEndpoint, minigameUrl, Multiplier } from '@/globalVariables'
import { openMiniGame } from '@/helpers'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useModeStore } from '@/store/pinia/modeStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { useCareerDetailStore } from '@/store/pinia/career/careerDetail'

import type { BossFightOpponentsApiResponse } from '@/interfaces/responses/bossFight/bossFightApiResponse'
import type { CareerQuest } from '@/interfaces/Quest'
import { useUserStore } from '@/store/pinia/userStore'

interface ComponentData {
  minigameUrl: typeof minigameUrl
  opponents: BossFightOpponentsApiResponse
  showSkipTutorialPopup: boolean
  Multiplier: typeof Multiplier
}

export default defineComponent({
  name: 'PreBossFightContent',
  components: {
    TableStarts,
    PreBossFightUserInfo,
    SkipTutorialPopup,
  },
  props: {
    disciplineId: {
      type: Number,
      required: true,
    },
    questId: {
      type: Number,
      required: true,
    },
  },
  emits: ['hidePopup'],
  data(): ComponentData {
    return {
      minigameUrl,
      opponents: null,
      showSkipTutorialPopup: false,
      Multiplier,
    }
  },
  computed: {
    ...mapState(useModeStore, { mode: 'getMode' }),
    ...mapState(useTutorialStore, {
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useUserStore, {
      activeMultiplicator: 'getActiveMultiplicator',
    }),
    ...mapState(useDisciplineStore, {
      discipline: 'getSpecificDiscipline',
    }),
    ...mapState(useCareerDetailStore, ['getQuestById']),
    questData(): CareerQuest {
      return this.getQuestById(this.questId)
    },
    isSpecialBossFight(): boolean {
      return this.questData?.parameters?.boss_fight_type_rounds
    },
    mainContentHeight(): string {
      if (this.isSpecialBossFight) return '38rem'

      return '42.438rem'
    },
    specialBossFightTitle(): String {
      const currentRound: number = this.questData.bossFightRoundUnlocked ?? 0
      switch (currentRound) {
        case 1:
          return this.$t('career.quarterfinal')
        case 2:
          return this.$t('career.semifinal')
        case 3:
          return this.$t('career.final')

        default:
          return ''
      }
    },
  },
  async created(): Promise<void> {
    await this.loadSpecificDiscipline({ disciplineId: this.disciplineId })
    await this.getOpponents()
  },
  methods: {
    ...mapActions(useDisciplineStore, {
      loadSpecificDiscipline: 'loadSpecificDiscipline',
    }),
    openMiniGame,
    hidePopup(): void {
      this.showSkipTutorialPopup = false
      this.$emit('hidePopup')
    },
    getBoss(): void {
      if (this.discipline.isTutorialPassed || this.isTutorial) {
        this.$emit('hidePopup')
        this.openMiniGame(
          this.minigameUrl,
          {
            params: {
              mode: this.mode?.boss_fight,
              discipline_id: this.disciplineId,
              quest_id: this.questId,
            },
          },
          this.$router,
        )
      } else {
        this.showSkipTutorialPopup = true
      }
    },
    async getOpponents() {
      this.opponents = await this.$axios.get<{}, BossFightOpponentsApiResponse>(
        `${arenaBossFightPreFightOpponentsEndpoint}/${this.disciplineId}/${this.questId}`,
      )
    },
  },
})
</script>

<style lang="scss" scoped>
.boss-fight-pregame {
  width: 100rem;
  height: 45.125rem;

  &-main {
    padding: 0 2rem 0 2rem;
    display: flex;
    justify-content: space-between;

    &-title {
      margin-top: 0.938rem;
      position: relative;
      width: 58.125rem;
      height: 3.75rem;
      background: linear-gradient(
        90deg,
        rgba(160, 220, 255, 0) 0%,
        rgba(160, 220, 255, 0.5) 50%,
        rgba(160, 220, 255, 0) 100%
      );

      &::before,
      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }

    &-list {
      margin-top: 0.938rem;
      width: 70%;

      .boss-scroll {
        width: 99%;
        padding-right: 1rem;
      }
    }
  }
}
</style>
