import { metaPremiumRefreshBundle, REFRESHING_BUNDLE } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'

export const useOfferStore = defineStore('offerStore', {
  actions: {
    async setRefreshingBundle({ type, value }: { type: string; value: number }) {
      try {
        await internalAxios.post<{}, string>(metaPremiumRefreshBundle, {
          type: REFRESHING_BUNDLE,
          parameter_name: type,
          value,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
