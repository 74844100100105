import { STAR_OF_THE_DAY } from '@/globalVariables'
import { StageType } from '@/interfaces/Tutorial'
import type { TutorialStage } from '@/interfaces/Tutorial'

/**
  Tutorial for discipline unlocked
 */
export default class TutorialUnlockStarOfTheDay {
  stages(): TutorialStage[] {
    return [
      // narative saying you unlocked a new discipline
      {
        id: 82,
        name: 'unlockStarOfTheDayNarrative',
        type: StageType.Narrative,
        overlay: false,
        mechanic: 9,
        pageName: 'LayoutViewTutorial',
        texts: [],
      },
      // focus on arena building with gsap animation
      {
        id: 83,
        name: 'clickOnStarOfTheDayBuilding',
        type: StageType.Focus,
        mechanic: 9,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        clientId: [STAR_OF_THE_DAY],
        texts: [],
      },
    ]
  }
}
