<template>
  <div id="club-create" class="club-create app-page-wrapper absolute">
    <menu-component menu-type="club-create" :title="$t('club.createClub')" />
    <section class="w-full h-full safe-area">
      <main class="w-full">
        <app-scrollbar scroll="y" slide="y" width="100%" height="100%" class="club-settings-scroll">
          <div class="club-create-wrapper">
            <div class="club-create-prices">
              <clubs-create-price :prices="[foundationPrices.money, foundationPrices.gems]" />
            </div>

            <clubs-info-form
              :form-id="'createClubForm'"
              @submit="createClub"
              @scroll-to-error="scrollToTop"
              @input-watch="inputValidation"
            />

            <div class="club-create-info">
              <info-text :text="$t('club.createInfo')" />
            </div>

            <div class="club-create-buttons flexing">
              <app-multi-button1
                btn-id="create-club-by-money"
                btn-type="money"
                :check-count-props="isDisabledButtonMoney"
                :btn-text="$t('club.createClub')"
                :btn-count="foundationPrices.money"
                :disabled="isDisabledButton || isDisabledButtonMoney"
                btn-variant="submit"
                form="createClubForm"
                @click="moneyCreateBtn"
              />
              <app-multi-button1
                btn-id="create-club-by-gems"
                btn-type="credit"
                :btn-text="$t('club.createClub')"
                :btn-count="foundationPrices.gems"
                :disabled="isDisabledButton"
                btn-variant="submit"
                form="createClubForm"
                :popup-data="
                  $filters.$confirmPopupData({
                    title: $t('club.createClub'),
                    text: $t('club.clubCreateConfirm'),
                    btnId: 'btn-confirm-create-club',
                    btnText: $t('club.createClub'),
                    btnValue: foundationPrices.gems,
                  })
                "
                @click="confirmCreate"
                @action="createClubAction(temporarySubmitData)"
              />
            </div>
          </div>
        </app-scrollbar>
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import ClubsCreatePrice from '@/components/Club/ClubsCreatePrice.vue'
import ClubsInfoForm from '@/components/Club/ClubsInfoForm.vue'
import InfoText from '@/components/InfoText.vue'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { ClubInfo } from '@/types/clubStoreTypes'
import type { Nullable } from '@/interfaces/utils'
interface ComponentData {
  paidInGems: boolean
  temporarySubmitData: Nullable<ClubInfo>
  canCreateClub: boolean
  isDisabledButton: boolean
  isDisabledButtonMoney: boolean
}

export default defineComponent({
  name: 'ClubsCreateClub',
  components: {
    ClubsCreatePrice,
    ClubsInfoForm,
    InfoText,
  },
  data(): ComponentData {
    return {
      paidInGems: false,
      temporarySubmitData: null,
      canCreateClub: false,
      isDisabledButton: true,
      isDisabledButtonMoney: false,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      hasClub: 'getHasClub',
      myClubId: 'getPlayerClubId',
    }),
    ...mapState(useClubStore, {
      foundationPrices: 'getFoundationPrices',
    }),
    ...mapState(useResponseTaskStore, {
      getMoney: 'getMoney',
    }),
  },
  async created(): Promise<void> {
    await this.loadFoundationPrices()
    this.isDisabledButtonMoney = this.foundationPrices.money > this.getMoney.value
  },
  methods: {
    ...mapActions(useUserStore, ['loadUserData']),
    ...mapActions(useClubStore, {
      clubsCreateClub: 'createClub',
      loadFoundationPrices: 'loadFoundationPrices',
    }),
    createClubAction(temporarySubmitData: ClubInfo): void {
      this.canCreateClub = true
      this.createClub(temporarySubmitData)
    },
    async createClub(club: ClubInfo): Promise<void> {
      this.temporarySubmitData = club

      if (this.canCreateClub) {
        await this.clubsCreateClub({
          ...club,
          memberMinimumLevel: Number(club.memberMinimumLevel),
          paidInGems: this.paidInGems,
        })
        await this.loadUserData()
        if (!this.hasClub) return
        this.$router.push({ name: 'ClubsMap', params: { clubId: this.myClubId } })
      }
    },
    moneyCreateBtn(): void {
      if (this.isDisabledButton) {
        this.scrollToTop()
        return
      }

      if (!this.isDisabledButtonMoney) {
        this.canCreateClub = true
        this.paidInGems = false
      }
    },
    confirmCreate(): void {
      if (!this.isDisabledButton) {
        this.canCreateClub = false
        this.paidInGems = true
        this.createClub(this.temporarySubmitData)
      } else {
        this.scrollToTop()
      }
    },
    scrollToTop(): void {
      const scroll = document.querySelector('.club-settings-scroll')
      scroll.scrollTop = 0
    },
    inputValidation(input: string): void {
      const inp = input
      if (inp.length >= 3) this.isDisabledButton = false
      else this.isDisabledButton = true
    },
  },
})
</script>

<style lang="scss" scoped>
.club-create {
  main {
    width: 97%;
    height: 82%;
    margin: 0 auto;
  }

  &-wrapper {
    width: 99%;
  }

  &-prices {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &-info {
    margin-bottom: 2rem;
    background: rgba(41, 82, 121, 0.3);
    padding: 1rem 2rem 1rem 2rem;
  }

  &-buttons {
    margin-top: 2rem;
    margin-bottom: 3rem;

    button {
      margin: 0 1rem;
    }
  }
}
</style>
