<template>
  <div class="relative">
    <div v-if="btnType === 'horizontalMultiBtn'">
      <app-multi-button1
        btn-type="credit"
        btn-size="sm"
        class="inline-flex"
        :btn-text="$te('skip')"
        :btn-count="amount"
        :hide-action-popup="true"
        :disabled="isLoading"
        :loading="isLoading"
        @click="isOpen = true"
      >
      </app-multi-button1>
    </div>
    <div v-if="btnType === 'verticalColBtn'" class="flexing">
      <p class="btn-skip-text text-36 gradient-text-light uppercase absolute">
        {{ $te('skip') }}
      </p>
      <app-button
        btn-type="credit"
        btn-size="xsm"
        add-class="flexing"
        :loading="isLoading"
        :disabled="isLoading"
        @click="isOpen = true"
      >
        <div class="flexing">
          <p>{{ amount }}</p>
          <app-main-icon icon-name="gems" :icon-size="56" />
        </div>
      </app-button>
    </div>
  </div>
  <info-popup v-if="isOpen" :popup-title="$te('actualTaskFinish')" @close="isOpen = false">
    <div class="flex flex-col items-center text-36 text-texts-standard-default p-12 pb-6">
      <p class="mb-8">
        {{ $te('confirmActualTaskFinish') }}
      </p>
      <p class="flex flex-row font-bold mb-12">
        {{ $t('common.price') }}:
        {{ amount }}
        <app-main-icon icon-name="gems" :icon-size="56" />
      </p>
      <app-multi-button1
        btn-id="event-task-skip-by-gems"
        btn-type="credit"
        btn-size="xl"
        :btn-text="$t('event.finishNow')"
        :btn-count="amount"
        :disabled="isLoading"
        :loading="isLoading"
        @enough-resources-btn-click="onSkipClick"
      >
      </app-multi-button1>
    </div>
  </info-popup>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

interface ComponentData {
  isOpen: boolean
  isLoading: boolean
}

export default defineComponent({
  name: 'TasksSkipButton',
  components: {
    InfoPopup,
  },
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    btnType: {
      type: String as PropType<'verticalColBtn' | 'horizontalMultiBtn' | ''>,
      default: '',
      validator: (value: string): boolean =>
        ['verticalColBtn', 'horizontalMultiBtn', ''].includes(value),
    },
  },
  emits: ['skip'],
  data(): ComponentData {
    return {
      isOpen: false,
      isLoading: false,
    }
  },
  methods: {
    onSkipClick(): void {
      this.isLoading = true
      this.$emit('skip')
      this.isOpen = false
    },
  },
})
</script>

<style lang="scss" scoped>
.relative .btn-skip-text.absolute {
  top: -3.25rem;
  width: 100%;
  text-align: center;
}
</style>
