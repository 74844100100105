import type { App, Plugin } from 'vue'

const IPHONE_DEVICE_REGEXP = /iPhone/i

let cache: boolean

export const isIphone = (): boolean => {
  if (cache !== undefined) return cache
  return (cache = IPHONE_DEVICE_REGEXP.test(navigator.userAgent))
}

export const isIphonePlugin: Plugin = {
  install: (app: App): void => {
    app.config.globalProperties.$isIphone = isIphone
  },
}
