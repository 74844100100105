<template>
  <main v-if="results" class="masters-tournament-ladder-main">
    <ladder-sub-header :master-start="results.masters_playoff_start" />
    <qualification-table
      v-if="results?.players?.length > 0"
      :masters-ladder="true"
      :state="PlayoffState.Qualification"
      :table="results"
      :round-count="0"
    />
    <event-message
      v-else-if="results?.players?.length === 0"
      class="my-auto"
      :masters-not-started="true"
    />
    <component-loading v-else :is-loading="true" />
  </main>
</template>

<script lang="ts">
import { eventMasterRankings } from '@/globalVariables'
import type { MastersPlayersResponse } from '@/interfaces/events/QualificationTable'
import { defineComponent } from 'vue'
import EventMessage from '../../EventMessage.vue'
import QualificationTable from '../Qualification/QualificationTable.vue'
import LadderSubHeader from './LadderSubHeader.vue'
import { PlayoffMainComponent, PlayoffState } from '@/interfaces/events/Playoff'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  PlayoffState: typeof PlayoffState
  results: Nullable<MastersPlayersResponse>
}

export default defineComponent({
  name: PlayoffMainComponent.Ladder,
  components: {
    QualificationTable,
    LadderSubHeader,
    EventMessage,
  },
  data(): ComponentData {
    return {
      PlayoffState,
      results: null,
    }
  },
  async created(): Promise<void> {
    await this.getRankings()
  },
  methods: {
    async getRankings(): Promise<void> {
      try {
        this.results = await this.$axios.post<{}, MastersPlayersResponse>(eventMasterRankings)
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
main {
  .masters-tournament-ladder-main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: white;
  }
}
</style>
