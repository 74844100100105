<template>
  <teleport to="#app">
    <div
      v-if="showPopup"
      id="club-create-popup"
      class="club-create-popup flexing flex-col"
      :class="$isMobile() && 'club-create-popup-mobile'"
    >
      <level-up-title :texts="texts[type]" />
      <div class="club-create-popup-middle flexing">
        <div class="club-create-popup-logo-wrapper flexing relative">
          <div class="club-create-popup-logo">
            <clubs-avatar :logo-id="clubGetter.logoId" :background-id="clubGetter.logoBgId" />
          </div>
        </div>
      </div>
      <div class="info-message flex flex-col items-center">
        <header class="flexing">
          <section class="club-create-popup-name flexing font-bold">
            <app-user
              class="club-member-settings-player-name"
              :country="clubData.country"
              :name="clubData.name"
            />
          </section>
        </header>
        <main class="flex justify-around items-center flex-col">
          <p class="text-32 text-texts-standard-default uppercase font-bold">
            {{ $t('club.clubAdvantages') }}
          </p>
          <section class="club-create-popup-rewards flex">
            <div
              v-for="(reward, index) in rewards"
              :key="index"
              v-tippy="{
                theme: 'WSM',
                content: $t(reward.text),
                placement: 'top',
              }"
              class="reward-box mx-2 flexing flex-col"
            >
              <div
                class="reward-icon"
                :style="{
                  backgroundImage: `url(${pathImages}clubs/create/rewards/reward-${reward.type.toLowerCase()}.avif)`,
                }"
              />
            </div>
          </section>
          <slot />
        </main>
      </div>
      <div class="club-create-popup-footer w-full flex justify-end absolute safe-area-x">
        <app-button
          btn-id="club-create-popup-btn"
          btn-size="md"
          btn-type="secondary"
          :btn-text="$t('button.continue')"
          add-class="club-create-popup-btn absolute"
          @click="confirm"
        />
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import ClubsAvatar from '@/components/Club/ClubsAvatar.vue'
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import LevelUpTitle from '@/components/LevelUp/LevelUpTitle.vue'
import { CLUBARENA, CLUBHOUSE, pathImages } from '@/globalVariables'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  pathImages: typeof pathImages
  texts: { [key: string]: { first: string; second: string } }
  rewards: CreatePopupReward[]
  showPopup: boolean
}
interface CreatePopupReward {
  type: string
  text: string
}
interface CreatePopupData {
  name: string
  country: string
}

export default defineComponent({
  name: 'ClubsCreateJoinPopup',
  components: {
    LevelUpTitle,
    AppUser,
    ClubsAvatar,
  },
  props: {
    type: {
      type: String,
      default: 'create',
      validator(value: string): boolean {
        return ['create', 'join'].includes(value)
      },
    },
  },
  data(): ComponentData {
    return {
      pathImages,
      texts: {
        create: {
          first: 'common.congratulations',
          second: 'club.clubCreated', // Klub úspešne založený
        },
        join: {
          first: 'common.congratulations',
          second: 'club.clubJoined', // Stal si sa členom klubu
        },
      },
      rewards: [
        {
          type: 'tasks',
          text: 'club.clubAdvantageTooltip1',
        },
        {
          type: 'employees',
          text: 'club.clubAdvantageTooltip2',
        },
        {
          type: CLUBHOUSE,
          text: 'club.clubAdvantageTooltip3',
        },
        {
          type: CLUBARENA,
          text: 'club.clubAdvantageTooltip4',
        },
        {
          type: 'champs',
          text: 'club.clubAdvantageTooltip5',
        },
      ],
      showPopup: true,
    }
  },
  computed: {
    ...mapState(useClubStore, {
      clubGetter: 'getClubInfo',
    }),
    ...mapState(useResponseTaskStore, {
      notifications: 'getNotifications',
    }),
    clubData(): CreatePopupData {
      return {
        name: this.clubGetter.name,
        country: this.clubGetter.country,
      }
    },
  },
  methods: {
    ...mapActions(useTutorialStore, ['loadTutorial']),
    confirm(): void {
      this.showPopup = false
      if (this.notifications) this.notifications.club_accepted_request = false

      this.loadTutorial()
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/info-message.scss';
@import '@/assets/styles/components/level-up/rewards.scss';

.club-create-popup {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba($color: #000000, $alpha: 0.9);
  z-index: 1;

  .info-message {
    top: -7rem;
    position: relative;
    transform: none;
    left: auto;
  }

  &-middle {
    position: relative;
    background-image: url($path-clubs + '/create/bg-logo-decoration-popup.avif');
    background-size: cover;
    background-repeat: no-repeat;
    width: 71.688rem;
    height: 38.875rem;
    top: 3.25rem;
  }

  &-logo {
    width: 15.75rem;
    height: 15.75rem;

    &-wrapper {
      background-image: url($path-clubs + '/create/bg-logo-popup.avif');
      background-size: contain;
      background-repeat: no-repeat;
      width: 19.625rem; // 16.625rem + img spacing
      height: 19.625rem;
      top: -1.5rem;
      left: -1rem;
    }
  }

  &-rewards {
    .reward-box {
      width: 6.938rem;
      height: 8.55rem;
    }

    .reward-icon {
      width: 5rem;
      height: 5rem;
      background-size: contain;
    }
  }

  &-name {
    .flag {
      width: 3.0625rem;
      height: 2.0625rem;
      margin-right: 0.8125rem;
      margin-left: 0.5625rem;
    }
  }
  .club-create-popup-footer {
    bottom: 2.688rem;
    height: 5rem;
  }

  .club-create-popup-btn {
    margin-right: 1.875rem;
    height: 5rem;
  }
}

.club-create-popup-mobile {
  width: 100vw;
  height: 100vh;
}
</style>
