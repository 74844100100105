<template>
  <div
    class="club-table-header w-full text-28 text-texts-standard-additional uppercase flex items-center"
  >
    <p>{{ $t('club.nameTable') }}</p>
    <p>{{ $t('club.activityTable') }}</p>
    <p>{{ $t('club.dateTable') }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TableHeader',
})
</script>

<style scoped lang="scss">
.club-table-header {
  // Offset for the scrollbar without blending with the table
  width: calc(100% - 1.375rem);
  height: 2.9375rem;
  background: #09172a;
  margin-bottom: 0.6875rem;

  p:nth-child(1) {
    width: 30%;
    padding-left: 1.25rem;
    text-align: left;
  }

  p:nth-child(2) {
    width: 45%;
    padding-left: 0.5rem;
    text-align: left;
  }

  p:nth-child(3) {
    width: 25%;
  }
}
</style>
