<template>
  <div class="gp-main-title">
    <div v-show="getTitleText" class="text-bg-gradient flexing h-full">
      <p class="text-texts-standard-default text-36 uppercase" v-html="getTitleText" />
    </div>
  </div>
</template>

<script lang="ts">
import { useGrandPrizeDetailStore } from '@/store/pinia/grandPrize/detailStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'

interface GrandPrizeHeaderTitle {
  canOpenCount: number
  allRewardsOpened: boolean
}

export default defineComponent({
  name: 'GrandPrizeMainHeader',
  props: {
    titleData: {
      type: Object as PropType<Nullable<GrandPrizeHeaderTitle>>,
      default: () => null,
    },
  },
  computed: {
    ...mapState(useGrandPrizeDetailStore, {
      getOpenHelmetsLength: 'getOpenHelmetsLength',
    }),
    getTitleText(): string {
      if (this.titleData.allRewardsOpened) return ''
      if (this.getOpenHelmetsLength <= 1 && this.titleData.canOpenCount >= 1) {
        return this.$replaceHtmlPlaceholders(
          this.$replacePlaceholder(this.$t('grandPrize.openFreeSlots'), '{2to4}', 2),
          'b',
          'font-bold text-texts-standard-important',
        )
      }
      if (
        (this.getOpenHelmetsLength === 2 || this.getOpenHelmetsLength === 4) &&
        this.titleData.canOpenCount === 0
      ) {
        return this.$replaceHtmlPlaceholders(
          this.$replacePlaceholder(this.$t('grandPrize.openGemsSlots'), '{2to4}', 2),
          'b',
          'font-bold text-texts-standard-important',
        )
      }
      if (this.getOpenHelmetsLength >= 2 && this.titleData.canOpenCount !== 0) {
        return this.$replaceHtmlPlaceholders(
          this.$replacePlaceholder(this.$t('grandPrize.openSlots'), '{2to4}', 2),
          'b',
          'font-bold text-texts-standard-important',
        )
      }
      return ''
    },
  },
})
</script>
