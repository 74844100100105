<template>
  <main
    :data-event-theme="namespace"
    class="milestones-main w-full h-full safe-area"
    :class="{
      'personal-milestones-bg': milestonesComponent === MilestoneMainComponent.Personal,
    }"
  >
    <milestones-header
      v-if="
        [EventType.ChallengeCup, EventType.FrozenChampionship, EventType.AutumnFair].includes(
          namespace,
        )
      "
      @set-section="setMilestonesComponent"
      @show-popup="showPopup = true"
    />

    <event-discipline-header v-if="namespace === EventType.DisciplineEvent" class="my-4">
      <event-reputation-box direction="row-reverse" class="mr-auto ml-6" />
    </event-discipline-header>

    <component :is="milestonesComponent" v-if="!isLoading" />
    <component-loading :is-loading="isLoading" />

    <info-popup
      v-if="showPopup"
      :popup-title="$te('aboutMilestone')"
      class="milestones-popup"
      width="76rem"
      @close="showPopup = false"
    >
      <header
        class="milestones-popup-header text-texts-standard-default uppercase text-30 flex items-center text-left"
      >
        <app-icon icon-name="info-rounded" />
        <p class="milestones-popup-header-text">
          {{ $replacePlaceholder($te('aboutMilestonesMain'), '{event}', eventName) }}
        </p>
      </header>
      <section class="milestones-popup-content flex text-32 text-texts-standard-default">
        <p
          v-for="text in texts"
          :key="text"
          class="milestones-popup-content-text text-left relative"
        >
          {{
            $replacePlaceholder(
              $replacePlaceholder(
                $te(text),
                '{date}',
                `${getFormattedDateFromYMD(progressingEndDate)}, ${getFormattedTimeFromHMS(
                  progressingEndDate,
                )}`,
              ),
              '{datetime}',
              `${getFormattedDateFromYMD(rewardingEndDate)}, ${getFormattedTimeFromHMS(
                rewardingEndDate,
              )}`,
            )
          }}
        </p>
      </section>
    </info-popup>
  </main>
</template>

<script lang="ts">
import EventDisciplineHeader from '@/components/Events/EventDisciplineHeader.vue'
import EventReputationBox from '@/components/Events/EventReputationBox.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { getFormattedDateFromYMD, getFormattedTimeFromHMS } from '@/helpers'
import { useUserStore } from '@/store/pinia/userStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useMilestonesStore } from '@/store/pinia/events/milestonesStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import ClubMilestonesMain from './ClubsMilestones/ClubMilestonesMain.vue'
import MilestonesHeader from './MilestonesHeader.vue'
import PersonalMilestonesMain from './PersonalMilestones/PersonalMilestonesMain.vue'
import { EventType } from '@/interfaces/events/EventInfo'
import { MilestoneType, MilestoneMainComponent } from '@/interfaces/events/Milestones'

interface ComponentData {
  EventType: typeof EventType
  MilestoneMainComponent: typeof MilestoneMainComponent
  milestonesComponent: MilestoneMainComponent
  isLoading: boolean
  showPopup: boolean
  texts: string[]
}

export default defineComponent({
  name: 'MilestonesMain',
  components: {
    MilestonesHeader,
    ClubMilestonesMain,
    PersonalMilestonesMain,
    InfoPopup,
    EventDisciplineHeader,
    EventReputationBox,
  },
  data(): ComponentData {
    return {
      EventType,
      MilestoneMainComponent,
      milestonesComponent: MilestoneMainComponent.Personal,
      isLoading: false,
      showPopup: false,
      texts: [
        'aboutMilOne',
        'aboutMilTwo',
        'aboutMilThree',
        'aboutMilFour',
        'aboutMilFive',
        'aboutMilSix',
        'aboutMilSeven',
      ],
    }
  },
  computed: {
    ...mapState(useUserStore, {
      hasClub: 'getHasClub',
    }),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
      eventName: 'getEventName',
    }),
    ...mapState(useMilestonesStore, {
      progressingEndDate: 'getProgressingEndDate',
      rewardingEndDate: 'getRewardingEndDate',
    }),
  },
  watch: {
    async milestonesComponent(): Promise<void> {
      if (this.milestonesComponent === MilestoneMainComponent.Club && !this.hasClub) return

      this.isLoading = true
      await this.loadState({
        type:
          this.milestonesComponent === MilestoneMainComponent.Personal
            ? MilestoneType.Individual
            : MilestoneType.Club,
      })
      this.isLoading = false
    },
  },
  async created(): Promise<void> {
    this.isLoading = true
    await Promise.all([
      this.loadConfig(),
      this.loadState({ type: MilestoneType.Individual }),
      this.loadEventOffers(),
    ])
    this.isLoading = false
  },
  methods: {
    ...mapActions(useMilestonesStore, ['loadConfig', 'loadState']),
    ...mapActions(usePremiumStore, ['loadEventOffers']),
    getFormattedDateFromYMD,
    getFormattedTimeFromHMS,
    setMilestonesComponent({ component }: { component: MilestoneMainComponent }): void {
      this.milestonesComponent = component
    },
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='challenge_cup'] {
  --milestones-pass-active-bg: url('#{$path-events}challenge-cup/milestones/milestones-champion-pass-active-bg.avif');
}

[data-event-theme='frozen'] {
  --milestones-pass-active-bg: url('#{$path-events}frozen-championship/milestones/milestones-champion-pass-active-bg-v1.avif');
}

[data-event-theme='discipline'] {
  --milestones-pass-active-bg: url('#{$path-events}discipline-event/milestones/milestones-master-pass-active-bg.avif');
}

[data-event-theme='autumn'] {
  --milestones-pass-active-bg: url('#{$path-events}autumn-fair/milestones/milestones-champion-pass-active-bg.avif');
}

.milestones-main {
  &.personal-milestones-bg {
    @include background(var(--milestones-pass-active-bg), cover, bottom);
  }
}

.milestones-popup {
  &-header {
    height: 7.75rem;
    background: #0b1c30;
    padding: 0 1.625rem;

    &-text,
    .icon-info-rounded {
      display: flex;
      height: 100%;
      align-items: center;
    }

    &-text {
      margin-left: 1rem;
      line-height: 2.75rem;
    }

    .icon-info-rounded {
      background-position: center;
    }
  }

  &-content {
    height: 41.5rem;
    flex-direction: column;
    padding-left: 4.5rem;
    padding-top: 1.9375rem;

    &-text {
      margin: 0.5rem 0;
      padding-right: 1.625rem;

      &::before {
        content: '';
        position: absolute;
        left: -2rem;
        top: 1.15rem;
        background: #a5e5ff;
        width: 0.625rem;
        height: 0.625rem;
      }
    }
  }
}
</style>
