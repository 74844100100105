export const abbrNumber = (value: number): string | number => {
  if (!value) return value
  const thresholds = [1000000000, 1000000]
  const vladyConst = 1000
  const abbr = ['M', 'k']
  for (let i = 0; i < thresholds.length; i++) {
    if (value >= thresholds[i]) {
      return Math.floor(value / (thresholds[i] / vladyConst)).toFixed(0) + abbr[i]
    }
  }
  return value
}

export const abbrRewardValue = (value: number): string | number => {
  if (!value) return 0

  const thresholds = [1000000, 1000]
  if (value < Math.min(...thresholds)) return value

  const abbr = ['M', 'k']
  for (let i = 0; i < thresholds.length; i++) {
    if (value >= thresholds[i]) {
      const toFixedNumber = thresholds[i] === 1000000 ? 3 : 1
      return Number(parseFloat((value / thresholds[i]).toFixed(toFixedNumber))) + abbr[i]
    }
  }
  return value
}
