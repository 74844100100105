<template>
  <div>
    <span class="flexing">
      <app-button
        v-tippy="{
          theme: 'WSM',
          content: !hasMechanic(MECHANIC_REFRESH_OPPONENTS)
            ? $replacePlaceholder($t('common.levelRequired'), '%s', '11')
            : '',
          placement: 'top',
          textAlign: 'center',
        }"
        btn-id="arena-refresh-opponents"
        :btn-type="type === CLUB_CHAMPIONSHIP ? 'secondary' : 'credit'"
        btn-size="xxsm"
        :disabled="!hasMechanic(MECHANIC_REFRESH_OPPONENTS)"
        :locked="!hasMechanic(MECHANIC_REFRESH_OPPONENTS)"
        @click="type === CLUB_CHAMPIONSHIP ? $emit('refreshOpponents') : showRefreshPopup()"
      >
        <app-icon icon-name="refresh-lg" />
      </app-button>
      <div />
      <div class="arena-refresh-opponents-texts flexing flex-col">
        <span class="flexing">
          <p class="text-texts-standard-additional text-28 mr-2">
            {{
              type === CLUB_CHAMPIONSHIP
                ? $t('arena.newCompetitionIn') + ':'
                : $t('arena.newOpponentsIn')
            }}
          </p>
          <vue-countdown
            v-if="expiration !== null && expiration > 0"
            v-slot="{ days, hours, minutes, seconds }"
            :time="expiration * 1000"
            @end="$emit('loadOpponents')"
          >
            <p class="text-texts-standard-additional text-28 font-bold">
              {{ formatTime(days, hours, minutes, seconds) }}
            </p>
          </vue-countdown>
        </span>
        <span class="flexing">
          <short-text
            class="text-texts-standard-default text-32 font-bold uppercase"
            :text="$t('arena.refreshNow') + ' :'"
            first-custom="text-24"
          />

          <p class="text-texts-standard-important text-32 font-bold ml-4">
            {{ getRefreshPrice }}
          </p>
          <app-main-icon
            :icon-size="32"
            :icon-name="type === CLUB_CHAMPIONSHIP ? 'energy' : 'gems'"
          />
        </span>
      </div>
    </span>
  </div>

  <confirm-popup
    v-if="showConfirmPopup"
    :button-data="confirmButtonData"
    :popup-title="$t('arena.newOpponents')"
    :button-text-left="$t('arena.newOpponents')"
    :button-text-right="getRefreshPrice"
    @close="showConfirmPopup = false"
    @action="$emit('refreshOpponents', (showConfirmPopup = false))"
  >
    <div class="main text-white">
      {{ $t('arena.opponentsGenerateConfirm') }}
    </div>
  </confirm-popup>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { formatTime } from '@/helpers'
import { ARENA, CLUB_CHAMPIONSHIP, MECHANIC_REFRESH_OPPONENTS } from '@/globalVariables'
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import ShortText from '@/components/ShortText.vue'
import { useOponentsStore } from '@/store/pinia/arena/oponentsStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'

interface ComponentData {
  CLUB_CHAMPIONSHIP: typeof CLUB_CHAMPIONSHIP
  MECHANIC_REFRESH_OPPONENTS: typeof MECHANIC_REFRESH_OPPONENTS
  showConfirmPopup: boolean
  confirmButtonData: {
    btnId: string
    btnType: string
    btnSize: string
    customLeftWidth: number
  }
}

export default defineComponent({
  name: 'ArenaCompetitionsFooterRefreshOpponents',
  components: {
    ConfirmPopup,
    ShortText,
  },
  props: {
    expiration: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: ARENA,
      validator(value: string): boolean {
        return [ARENA, CLUB_CHAMPIONSHIP].includes(value)
      },
    },
  },
  emits: ['loadOpponents', 'refreshOpponents'],
  data(): ComponentData {
    return {
      CLUB_CHAMPIONSHIP,
      MECHANIC_REFRESH_OPPONENTS,
      showConfirmPopup: false,
      confirmButtonData: {
        btnId: 'training-confirm-btn',
        btnType: 'credit',
        btnSize: 'md',
        customLeftWidth: 20,
      },
    }
  },
  computed: {
    ...mapState(useOponentsStore, ['getRefreshPrice']),
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
    }),
  },
  methods: {
    formatTime,
    showRefreshPopup(): void {
      if (!this.hasMechanic(MECHANIC_REFRESH_OPPONENTS)) {
        return
      }
      this.showConfirmPopup = true
    },
  },
})
</script>

<style lang="scss" scoped>
.arena-refresh-opponents-texts {
  width: 20.75rem;
  height: 4.75rem;
  margin-left: 1.5rem;
  line-height: 2.5rem;
  align-items: flex-start;
}
</style>
