const COMMON_REGEXP = /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
const TO_SNAKE_CASE_REGEXP = COMMON_REGEXP
const TO_KEBAB_CASE_REGEXP = COMMON_REGEXP
const TO_CAMEL_CASE_REGEXP = /[^a-zA-Z0-9]+(.)/g
const TO_PASCAL_CASE_REGEXP = /\w\S*/g

const isString = (string: unknown): boolean => typeof string === 'string'

const formatMatches = (text: string, regexp: RegExp, separator: string): string => {
  if (!isString(text)) return ''

  const match = text.match(regexp)
  if (!match) return ''

  return match.map((part: string): string => part.toLowerCase()).join(separator)
}

// snake => divided_string
export const toSnakeCase = (text: string): string => formatMatches(text, TO_SNAKE_CASE_REGEXP, '_')

// kebab => divided-string
export const toKebabCase = (text: string): string => formatMatches(text, TO_KEBAB_CASE_REGEXP, '-')

// camel => dividedString
export const toCamelCase = (text: string): string => {
  // TODO unite
  if (!isString(text)) return ''

  return text
    .toLowerCase()
    .replace(TO_CAMEL_CASE_REGEXP, (_: string, character: string): string =>
      character.toUpperCase(),
    )
}

// pascal => DividedString
export const toPascalCase = (text: string): string => {
  // TODO unite
  if (!isString(text)) return ''

  return text.replace(
    TO_PASCAL_CASE_REGEXP,
    (match: string): string => match.charAt(0).toUpperCase() + match.substring(1).toLowerCase(),
  )
}
