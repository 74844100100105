<template>
  <div id="rules" class="rules app-page-wrapper absolute">
    <menu-component menu-type="rules" :title="$t('info.gameRules')" />
    <section class="w-full h-full safe-area">
      <rules-content />
    </section>
  </div>
</template>

<script lang="ts">
import RulesContent from '@/components/Info/RulesContent.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RulesComponent',
  components: {
    RulesContent,
  },
})
</script>
