import type { ExclamationNotificationConfigInterface } from '@/map-phaser-new/interfaces'
import { InteractiveObject } from '../abstractClasses'

export class ExclamationNotification extends InteractiveObject {
  private notificationImage: Phaser.GameObjects.Image | null = null
  private notificationAnimation: Phaser.Tweens.Tween | null = null

  constructor(positionX: number, positionY: number) {
    super(positionX, positionY)
  }

  public addToMap(activeScene: Phaser.Scene, config: ExclamationNotificationConfigInterface): void {
    this.createNotificationImage(activeScene, config)
    this.createNotificationAnimation(activeScene, config)
  }

  public removeFromMap(activeScene: Phaser.Scene): void {
    activeScene.tweens.killTweensOf(this.notificationImage)
    this.removeNotificationAnimation()
    this.removeNotificationImage()
  }

  private createNotificationImage(
    activeScene: Phaser.Scene,
    config: ExclamationNotificationConfigInterface,
  ): void {
    if (this.notificationImage) return

    this.notificationImage = activeScene.add
      .image(this.positionX, this.positionY, config.textureKey)
      .setOrigin(config.origin)
      .setDepth(config.depth)
      .setName('exclamation_notification')
  }

  private createNotificationAnimation(
    activeScene: Phaser.Scene,
    config: ExclamationNotificationConfigInterface,
  ): void {
    if (this.notificationAnimation) return

    this.notificationAnimation = activeScene.tweens.add({
      targets: this.notificationImage,
      y: { value: config.animationYIndent, ease: config.animationYEase },
      yoyo: config.yoyo,
      hold: config.yoyoHold,
      repeat: config.repeat,
      duration: config.duration,
    })
  }

  private removeNotificationImage(): void {
    if (!this.notificationImage) return

    this.notificationImage.destroy()
    this.notificationImage = null
  }

  private removeNotificationAnimation(): void {
    if (!this.notificationAnimation) return

    this.notificationAnimation.destroy()
    this.notificationAnimation = null
  }
}
