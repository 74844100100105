<template>
  <div>
    <arrow-animation
      position="left"
      border="button"
      :tutorial="
        (Number(id) === 5 && isClickOnGrandPrizeMenu) ||
        (Number(id) === 4 && isClickOnDailyTasks) ||
        isClickOnBenefits ||
        isFocusOnClubCentrum
      "
      :add-class="isFocusOnClubCentrum ? 'mr-4' : ''"
    >
      <div
        :id="`map-button-${id}`"
        data-cy="map-button"
        class="map-button clickable-skew-element justify-center items-center"
        :class="[
          {
            theme_3_w: isThirdTheme,
            'button-anim': isClickOnBenefits || isFocusOnClubCentrum,
            narrower: image === 'benefits',
          },
        ]"
      >
        <div
          v-if="timer"
          class="timer w-full text-24 text-white pointer-events-none absolute top-0"
        >
          <vue-countdown
            v-slot="{ days, hours, minutes, seconds }"
            :time="timer"
            @end="$emit('timerEnded')"
          >
            {{ formatTime(days, hours, minutes, seconds) }}
          </vue-countdown>
        </div>
        <app-notification
          v-if="notificationsSum"
          :count="notificationsSum"
          :is-skewed-parent="true"
        />
        <div v-if="onlinePlayers" class="map-button-online-players flexing revert-skew">
          <div class="map-button-online-players-status" />
          <p class="text-texts-standard-default text-24">
            {{ onlinePlayers }}
          </p>
        </div>
        <router-link
          :id="getIdForTutorial(id)"
          :to="{ name: isDisabled ? null : getRoute(id, route), query: routeQuery ?? {} }"
          class="map-btn absolute left-0"
          :class="[theme, { 'disabled-btn pointer-events-none': isButtonDisabled(id) }]"
          @click="playSound()"
        >
          <section :class="{ 'attribute-class': isThirdTheme }">
            <div
              v-if="(isFirstTheme || isSecondTheme) && image"
              :id="getIdForTutorial(id)"
              :class="{
                ['main-layout-icon ' + image]: !isClubMap,
                ['club-layout-icon club-' + image]: isClubMap,
                'revert-skew': currentGame === GameType.Winter,
              }"
            />
            <div
              v-if="(isFirstTheme || isThirdTheme) && text"
              class="text flexing w-full"
              :class="{ 'revert-skew': currentGame === GameType.Winter }"
            >
              <shrink-text
                v-if="theme === MapButtonThemes.Theme1"
                :key="shrinkTextComponentKey"
                :text="text"
                :forced-font-size="22"
                container-class-list="flexing"
                :animated-overflow="true"
              />
              <span v-else>{{ text }}</span>
              <app-main-icon v-if="icon" :icon-size="48" :icon-name="icon" />
            </div>
          </section>
        </router-link>
        <div
          v-show="isDisabled"
          v-tippy="{
            content: lockTooltip || $t('common.locked'),
            placement: 'top',
            theme: 'WSM',
            allowHTML: true,
          }"
          class="flexing bonus-class map-btn absolute left-0"
          :class="isDisabled ? `disabled-btn ${theme}` : ''"
        >
          <app-icon :icon-name="lockIcon" class="absolute revert-skew" />
        </div>
        <div v-if="strip" class="map-btn-strip" />
        <notification-tooltip v-if="hasJoinClubNotification" class="btn-notification">
          {{ $t('club.joinToClub') }}
        </notification-tooltip>
      </div>
    </arrow-animation>
  </div>
</template>

<script lang="ts">
import ArrowAnimation from '@/components/ArrowAnimation.vue'
import NotificationTooltip from '@/components/NotificationTooltip.vue'
import { MECHANIC_CLUB } from '@/globalVariables'
import { formatTime, playSound } from '@/helpers'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import ShrinkText from '@/components/ShrinkText.vue'

import type { PropType } from 'vue'
import type { LocationQueryRaw } from 'vue-router'
import type { Nullable } from '@/interfaces/utils'
import { MapButtonThemes } from '@/enums/MapButtonThemes'
import { GameType } from '@/globalVariables/gameTypes'

interface ComponentData {
  GameType: typeof GameType
  currentGame: string
  shrinkTextComponentKey: number
  MapButtonThemes: typeof MapButtonThemes
}

export default defineComponent({
  name: 'MapButtons',
  components: {
    ArrowAnimation,
    NotificationTooltip,
    ShrinkText,
  },
  props: {
    theme: {
      type: String as PropType<MapButtonThemes>,
      default: MapButtonThemes.Theme3,
      validator(value: MapButtonThemes): boolean {
        return [MapButtonThemes.Theme1, MapButtonThemes.Theme2, MapButtonThemes.Theme3].includes(
          value,
        )
      },
    },
    image: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    strip: {
      type: Boolean,
      default: false,
    },
    route: {
      type: String,
      default: '',
    },
    routeQuery: {
      type: Object as PropType<Nullable<LocationQueryRaw>>,
      default: () => null,
    },
    id: {
      type: Number,
      default: 0,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    sound: {
      type: String,
      default: '',
    },
    lockIcon: {
      type: String,
      default: 'time',
    },
    lockTooltip: {
      type: String,
      default: '',
    },
    isClubMap: {
      type: Boolean,
      default: false,
    },
    onlinePlayers: {
      type: Number,
      default: null,
    },
    notification: {
      type: Number,
      default: 0,
    },
    timer: {
      type: Number,
      default: 0,
    },
  },
  emits: ['timerEnded'],
  data(): ComponentData {
    return {
      GameType,
      currentGame: this.$gameName,
      shrinkTextComponentKey: 0,
      MapButtonThemes,
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
    }),
    ...mapState(useResponseTaskStore, {
      notifications: 'getNotifications',
    }),
    isFirstTheme(): boolean {
      return this.theme === 'theme_1'
    },
    isSecondTheme(): boolean {
      return this.theme === 'theme_2'
    },
    isThirdTheme(): boolean {
      return this.theme === 'theme_3'
    },
    notificationsSum(): number {
      if (this.notification) return this.notification

      if (this.image === 'task')
        return (
          this.notifications.daily_tasks.length +
          this.notifications.career_quests.length +
          this.notifications.daily_tasks_reset
        )
      if (this.image === 'grand_prize')
        return this.notifications.grand_prize_unopened + this.notifications.grand_prize_unclaimed
      if (this.image === 'news') return this.notifications.unread_reports

      if (this.image === MECHANIC_CLUB)
        return (
          (this.notifications.club_accepted_request ? 1 : 0) +
          this.notifications.employee_rewards_ready
        )

      if (this.image === MECHANIC_CLUB || this.image === 'managment')
        return this.notifications.clubs_requests

      return 0
    },
    isClickOnGrandPrizeMenu(): boolean {
      return this.actualStageData?.name === 'clickOnGrandPrizeMenu'
    },
    isClickOnDailyTasks(): boolean {
      return this.actualStageData?.name === 'clickOnDailyTasks'
    },
    isClickOnBenefits(): boolean {
      return this.actualStageData?.name === 'clickOnBenefitsButton' && this.image === 'benefits'
    },
    isFocusOnClubCentrum(): boolean {
      return this.actualStageData?.name === 'focusOnClubCentrum' && this.image === 'center'
    },
    hasJoinClubNotification(): boolean {
      return this.image === MECHANIC_CLUB && this.notifications.join_club_reminder
    },
  },
  watch: {
    text(): void {
      this.forceRerender()
    },
  },
  methods: {
    formatTime,
    playSound(): void {
      if (this.sound) {
        playSound(this.sound)
      }
    },
    getRoute(id: number, route: string): string {
      return id === 5 && this.isClickOnGrandPrizeMenu ? route + 'Tutorial' : route
    },
    isButtonDisabled(id: number): boolean {
      return (
        (id !== 5 && this.isClickOnGrandPrizeMenu) ||
        (id !== 4 && this.isClickOnDailyTasks) ||
        (this.image !== 'benefits' && this.actualStageData?.name === 'clickOnBenefitsButton')
      )
    },
    getIdForTutorial(param: number): string {
      if (param === 5) {
        return 'layout-premium-btn'
      } else if (param === 4) {
        return 'layout-tasks-btn'
      } else if (this.image === 'benefits') {
        return 'layout-benefits-btn'
      } else if (this.image === 'center') {
        return 'club-centrum-btn'
      } else {
        return ''
      }
    },
    forceRerender(): void {
      // SHRINK-TEXT: Changing the key causes the component to be rerendered
      this.shrinkTextComponentKey += 1
    },
  },
})
</script>

<style lang="scss" scoped>
// theme_1 => modry button s ikonkou a textom
// theme_2 => modry button len s ikonkou
// theme_3 => zeleny button

.map-button {
  height: 5.375rem;
  width: 11.25rem;
  transform: $skewX-value;
  margin-right: 1.25rem;

  .attribute-class {
    @if $isSsm {
      background-image: linear-gradient(to right, #5fffc8, #01e3cd);
    }
  }

  .bonus-class {
    @if $isSsm {
      background-color: rgba(52, 65, 93, 0.85);
    }
  }

  &.theme_3_w {
    width: 15.438rem;
  }

  &.narrower {
    height: 3.75rem;
    margin: unset;
  }

  section {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .map-button-online-players {
    min-width: 3.938rem;
    height: 1.938rem;
    border-radius: 6px;
    background-image: linear-gradient(to left, #1d5696, #0f2c4e);
    position: absolute;
    left: -1rem;
    top: -1rem;
    z-index: 1;

    &-status {
      width: 1.375rem;
      height: 1.375rem;
      box-shadow:
        0.2px 3px 1px 0 rgba(32, 32, 32, 0.22),
        inset 14px 0 21px 0 rgba(28, 115, 17, 0.23),
        inset 0px 6px 4px 0 rgba(255, 255, 255, 0.24);
      background-image: linear-gradient(to bottom, #aefc96, #58e863, #09c414, #00c012, #1eb622);
      border-radius: 5rem;
      margin: 0 0.2rem;
    }
  }

  .map-btn {
    &::before {
      content: '';
      position: absolute;
      left: 0.063rem;
      bottom: 0.063rem;
      width: 1.563rem;
      height: 1.563rem;
      background: transparent;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0.063rem;
      top: 0.063rem;
      width: 1.563rem;
      height: 1.563rem;
      background: transparent;
    }

    &.theme_1 {
      height: 5rem;
      width: 11.25rem;
      box-shadow: 0.061rem 0.121rem 0.188rem 0 rgba(0, 0, 0, 0.32);

      @if $isWsm {
        background: linear-gradient(
          80deg,
          rgba(58, 114, 153, 0.9) 0%,
          rgba(43, 85, 114, 0.9) 33%,
          rgba(28, 55, 74, 0.9) 100%
        );
        border: 0.125rem solid rgb(82, 142, 191);
      }

      @if $isSsm {
        background: linear-gradient(to right, rgba(52, 65, 93, 0.85), rgba(39, 50, 73, 0.85));
        border: 0.125rem solid #586b9d;
      }

      &::before {
        @if $isWsm {
          content: '';
          border-bottom: 0.094rem solid #b5deff;
          border-left: 0.094rem solid #b5deff;
        }
      }

      &::after {
        @if $isWsm {
          content: '';
          border-top: 0.094rem solid #b5deff;
          border-right: 0.094rem solid #b5deff;
        }
      }
    }

    &.theme_2 {
      height: 3.75rem;
      width: 11.25rem;

      @if $isWsm {
        background-image: linear-gradient(
          80deg,
          rgba(58, 114, 153, 0.85) 0%,
          rgba(43, 85, 114, 0.85) 33%,
          rgba(28, 55, 74, 0.85) 100%
        );
        border: 0.125rem solid rgb(82, 142, 191);
        box-shadow: 0.061rem 0.196rem 0.188rem 0 rgba(0, 0, 0, 0.32);
      }

      @if $isSsm {
        background-image: linear-gradient(to right, rgba(52, 65, 93, 0.85), rgba(39, 50, 73, 0.85));
        border: solid 0.125rem #586b9d;
        box-shadow: 0px 5px 3px 0 rgba(0, 0, 0, 0.36);
      }

      @if $isWsm {
        &::before {
          content: '';
          border-bottom: 0.094rem solid #b5deff;
          border-left: 0.094rem solid #b5deff;
        }

        &::after {
          content: '';
          border-top: 0.094rem solid #b5deff;
          border-right: 0.094rem solid #b5deff;
        }
      }
    }

    &.theme_3 {
      height: 3.75rem;
      width: 15.438rem;

      @if $isWsm {
        border: 0.125rem solid #4fab48;
        background-image: linear-gradient(80deg, rgb(70, 140, 17) 0%, rgb(100, 178, 40) 100%);
        box-shadow:
          inset 0 0 13px 12px rgb(38 102 48 / 40%),
          0.4px 3px 3px 0 rgb(22 12 12 / 59%);
      }

      @if $isSsm {
        border: 0.125rem solid #81ffee;
        background-image: linear-gradient(to right, #5fffc8, #01e3cd);
      }

      &::before {
        @if $isWsm {
          content: '';
          border-bottom: 0.094rem solid #82d46a;
          border-left: 0.094rem solid #82d46a;
        }
      }

      &::after {
        @if $isWsm {
          content: '';
          border-top: 0.094rem solid #82d46a;
          border-right: 0.094rem solid #82d46a;
        }
      }

      .text {
        height: 100%;
        font-size: 1.875rem;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;

        @if $isSsm {
          color: theme('colors.texts.standard.dark');
          font-weight: normal;
        }
      }
    }

    .main-layout-icon,
    .club-layout-icon {
      width: 3.3rem;
      height: 3.438rem;
      margin: 0 auto;
      background-image: url($path-layout + 'sprite_buttons_icons.avif');
      background-size: 6.8rem;

      &.news {
        background-position: 0 0;
      }

      &.ranking {
        background-position: 0 -3.563rem;
      }

      &.club {
        background-position: 0 -6.875rem;
      }

      &.task {
        background-position: -3.75rem 0;
      }

      &.grand_prize {
        background-position: -3.5rem -3.5rem;
      }

      &.benefits {
        background-position: -3.5rem -7rem;
        height: 3rem;
      }

      &.game-pass {
        @include background(url('#{$path-layout}game-pass-icon.avif'), contain);
        width: 100%;
        height: 100%;
        transform: scale(1.5);
      }
    }

    .club-layout-icon {
      background-image: url($path-clubs + 'map/sprite_buttons_icons.avif');
      background-size: 11rem;

      &.club-center {
        background-position: 0 0;
      }

      &.club-managment {
        background-position: -3.7rem 0;
      }

      &.club-shop {
        background-position: -7.5rem 0;
      }
    }

    .text {
      font-size: 1.25rem;
      height: 1.188rem;
      color: #fff;
      text-transform: uppercase;
      line-height: 1.188rem;
    }
  }

  .disabled-btn {
    width: 100%;

    @if $isWsm {
      background: #172c42;
    }

    @if $isSsm {
      background: #29344a;
    }

    &.theme_1 {
      height: 5rem;
    }

    &.theme_2 {
      height: 3.75rem;
    }

    &.theme_3 {
      height: 3.75rem;
    }
  }

  .map-btn-strip {
    position: absolute;
    bottom: -0.063rem;
    height: 0.313rem;
    width: 11.25rem;

    @if $isWsm {
      background: #f4d91e;
    }

    @if $isSsm {
      background: #feb942;
    }
  }

  & > #layout-premium-btn,
  #layout-tasks-btn,
  #layout-benefits-btn,
  #club-centrum-btn {
    & *,
    &::after {
      pointer-events: none;
    }
  }

  .btn-notification {
    position: absolute;
    top: -8rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

.arrow-wrapper {
  width: 11.25rem;
}

.animated-container {
  // *must have for animated ShrinkText in "px" to optimize animation behavior
  padding: 5px 6px;
}

.timer {
  background-image: linear-gradient(to right, #7e0404, #c31818, #c31818, #7e0404);
  border: solid 0.125rem #434343;
  transform: translateY(-100%);
}
</style>
