<template>
  <header
    class="playoff-header flex flex-row items-center justify-between relative px-8 pt-2"
    :data-event-theme="namespace"
  >
    <section v-if="isMasters" class="placeholder">
      <tippy theme="WSM" placement="top">
        <app-icon icon-name="info-70" />
        <template #content>
          <p>
            {{ $replacePlaceholder($te('dataUpdateTime'), '%s', '10') }}
          </p>
        </template>
      </tippy>
    </section>
    <app-button
      v-else-if="!!$route?.query?.tournamentId"
      btn-type="primary"
      :btn-text="$te('tournaments')"
      btn-size="md"
      @click="$router.push({ query: null })"
    />
    <app-button
      v-else
      btn-type="primary"
      :btn-text="$te('playoffRules')"
      btn-size="md"
      @click="showRulesPopup = true"
    />
    <div class="flexing space-x-4">
      <app-button
        v-for="(button, i) in buttons"
        :key="i"
        :btn-type="button === activeButton ? 'secondary' : 'inactive'"
        :btn-text="getButtonText(button)"
        btn-size="md"
        @click="setPlayoffSection(button)"
      />
    </div>
    <app-button
      v-if="isMasters"
      btn-type="primary"
      :btn-text="$te('playoffRules')"
      btn-size="md"
      @click="showMastersRulesPopup = true"
    />
    <app-button
      v-else
      btn-type="confirm"
      :btn-text="$te('commonRewards')"
      btn-size="md"
      @click="showRewardsPopup = true"
    />
  </header>
  <playoff-rules-popup v-if="showRulesPopup" @close="showRulesPopup = false" />
  <playoff-masters-rules-popup
    v-if="showMastersRulesPopup"
    @close="showMastersRulesPopup = false"
  />
  <playoff-rewards-popup v-if="showRewardsPopup" @close="showRewardsPopup = false" />
</template>

<script lang="ts">
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import PlayoffMastersRulesPopup from '../EventsPopups/PlayoffMastersRulesPopup.vue'
import PlayoffRewardsPopup from '../EventsPopups/PlayoffRewardsPopup.vue'
import PlayoffRulesPopup from '../EventsPopups/PlayoffRulesPopup.vue'
import { PlayoffMainComponent } from '@/interfaces/events/Playoff'

interface ComponentData {
  PlayoffMainComponent: typeof PlayoffMainComponent
  showRulesPopup: boolean
  showRewardsPopup: boolean
  showMastersRulesPopup: boolean
}

export default defineComponent({
  name: 'PlayoffHeader',
  components: {
    PlayoffRulesPopup,
    PlayoffRewardsPopup,
    PlayoffMastersRulesPopup,
  },
  props: {
    activeButton: {
      type: String as PropType<PlayoffMainComponent>,
      required: true,
    },
    isMasters: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['setSection'],
  data(): ComponentData {
    return {
      PlayoffMainComponent,
      showRulesPopup: false,
      showRewardsPopup: false,
      showMastersRulesPopup: false,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
    buttons(): PlayoffMainComponent[] {
      return this.isMasters
        ? [PlayoffMainComponent.Tournaments, PlayoffMainComponent.Ladder]
        : [PlayoffMainComponent.Qualification, PlayoffMainComponent.Playoff]
    },
  },
  methods: {
    getButtonText(component: PlayoffMainComponent): string {
      switch (component) {
        case PlayoffMainComponent.Tournaments: {
          return this.$te('tournaments')
        }
        case PlayoffMainComponent.Ladder: {
          return this.$te('tournamentRanking')
        }
        case PlayoffMainComponent.Qualification: {
          return this.$te('qualification')
        }
        case PlayoffMainComponent.Playoff: {
          return this.$te('playoff')
        }
      }
    },
    setPlayoffSection(component: PlayoffMainComponent): void {
      this.$emit('setSection', { component })
    },
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='frozen'] {
  --events-playoff-header-bg: rgba(0, 5, 25, 0.33);
}

.playoff-header {
  height: 7.625rem;
  background-color: var(--events-playoff-header-bg);

  .btn {
    min-width: 25rem;
  }

  .placeholder {
    min-width: 25rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
</style>
