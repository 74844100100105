import type { App, Plugin } from 'vue'

interface TextsCache {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

let cache: TextsCache

/**
 * Translates the given key from session storage
 * @param key - key given based on translation object
 * @param force - reload fresh texts from session storage
 * @returns - value returned in the specific key
 */
export const translate = (key: string, force?: boolean): string => {
  if (cache === undefined || force) cache = JSON.parse(sessionStorage.getItem('texts') || '{}')
  if (!cache) return ''
  if (cache[key]) return cache[key]

  const keys = key.split('.')
  return (
    (cache[keys[0]] && (cache[keys[0]][keys[1]] || cache[keys[0]][`${keys[1]}.${keys[2]}`])) || ''
  )
}

/**
 * Is mobile plugin. Use with app.use() at app creation time.
 */
export const translatorPlugin: Plugin = {
  install: (app: App): void => {
    app.config.globalProperties.$t = translate
  },
}
