<template>
  <div class="lab-detail-table">
    <div
      class="lab-detail-box-header flex items-center justify-between relative"
      :class="longRowClass"
    >
      <div class="lab-detail-box-header-info flexing"></div>
      <div
        v-if="skillData.affectedDisciplines"
        class="lab-detail-box-header-icons flex items-center justify-end relative"
      >
        <div
          v-for="(impro, indexImpro) in skillData.improvements"
          :key="indexImpro"
          class="lab-detail-box-header-discipline flexing affected"
        >
          <div
            v-for="(discipline, index) in skillData.affectedDisciplines"
            :key="index"
            class="lab-detail-box-header-text"
            :class="[mutedClass]"
          >
            <div
              v-tippy="{
                content: getTooltipText(impro.name, discipline),
                placement: 'top',
                theme: 'WSM',
              }"
              :class="iconClass(impro.name, discipline)"
            />
          </div>
        </div>
      </div>
      <div
        v-else-if="skillData.improvements"
        class="lab-detail-box-header-icons flex items-center justify-end relative"
        :class="`lab-detail-box-header-icons--${skillType === SkillType.Camps ? 3 : 4}`"
      >
        <div
          v-for="(impro, index) in skillData.improvements"
          :key="index"
          v-tippy="{
            content: getTooltipText(impro.name, $route.params.id?.toString()),
            placement: 'top',
            theme: 'WSM',
          }"
          class="lab-detail-box-header-text"
          :class="[mutedClass]"
        >
          <div :class="iconClass(impro.name, disciplineId !== 0 ? disciplineId : null)" />
        </div>
      </div>
    </div>
    <div
      v-for="level in Number(skillData.maxLevel)"
      :key="level"
      class="lab-detail-box flex items-center justify-between"
      :class="[
        isLevelCompleted(level)
          ? isPreviousLevelCompleted(level)
            ? 'lab-detail-box-complete'
            : 'lab-detail-box-disabled'
          : `${
              parseInt(skillData.currentLevel.toString()) > level ? 'lab-detail-box-disabled' : ''
            }`,
        { 'lab-detail-box-actual': isLevelCompleted(level) },
        longRowClass,
        mutedClass,
      ]"
    >
      <div class="lab-detail-box-level">
        <p
          class="text-34 uppercase"
          :class="[
            skillData.locked ? 'text-texts-standard-additional' : 'text-texts-standard-default',
            isLevelCompleted(level) && isPreviousLevelCompleted(level)
              ? 'text-texts-standard-important font-bold'
              : 'text-texts-standard-additional',
          ]"
        >
          {{ 'lvl ' + level }}
        </p>
        <app-icon
          v-if="isLevelCompleted(level) && isPreviousLevelCompleted(level)"
          :icon-name="'done-sm'"
        />
      </div>

      <div
        class="lab-detail-box__row-body"
        :class="`lab-detail-box__row-body--${skillType === SkillType.Camps ? 3 : 4}`"
      >
        <div
          v-for="(impro, index) in skillData.improvements"
          :key="index"
          class="lab-detail-box-count"
        >
          <div class="lab-detail-box-count__col flexing">
            <div
              class="text-34 lab-detail-box-count-by-disciplines"
              :class="[
                skillData.locked ? 'text-texts-standard-additional' : 'text-texts-standard-default',
                isLevelCompleted(level) && isPreviousLevelCompleted(level)
                  ? 'text-texts-standard-important font-bold'
                  : 'text-texts-standard-additional',
              ]"
            >
              {{ getCount(impro.name, Number(impro.value) * level) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="lab-detail-table__separators-wrapper">
      <div
        v-for="i in skillType === SkillType.Camps ? 3 : 4"
        :key="i"
        class="lab-detail-table__separator"
        :class="`lab-detail-table__separator--${skillType === SkillType.Camps ? 3 : 4}`"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { MECHANIC_RESEARCH } from '@/globalVariables'
import { SkillType, type Cell } from '@/interfaces/lab/Cell'
import type { Nullable } from '@/interfaces/utils'
import type { PropType } from 'vue'

interface ComponentData {
  SkillType: typeof SkillType
}

export default defineComponent({
  name: 'LabDetailTable',
  props: {
    skillType: {
      type: String as PropType<SkillType>,
      default: MECHANIC_RESEARCH,
      validator(value: SkillType): boolean {
        return [SkillType.Camps, SkillType.Research].includes(value)
      },
    },
    skillData: {
      type: Object as PropType<Nullable<Cell>>,
      default: () => null,
    },
    disciplineId: {
      type: Number,
      default: 0,
    },
  },
  data(): ComponentData {
    return {
      SkillType,
    }
  },
  computed: {
    longRowClass(): string {
      return Number(this.skillData.maxLevel) <= 1 || this.skillType === SkillType.Research
        ? 'long-row'
        : ''
    },
    mutedClass(): string {
      return this.skillData.locked ? 'muted' : ''
    },
  },
  methods: {
    iconClass(name: string, discipline: number): string {
      const disciplineId = discipline ?? this.$route.params.id
      const disciplineBonuses = [
        'attribute_research_base',
        'attribute_research_premium',
        'attribute_research_repeatable',
        'training_percentage',
      ]
      name =
        name === 'attribute_research_premium' || name === 'attribute_research_repeatable'
          ? 'attribute_research_base'
          : name
      const bonusName = disciplineBonuses.includes(name) ? `${name}-${disciplineId}` : name
      return `icon-lab-bonus-${bonusName}`
    },
    isLevelCompleted(level: number): boolean {
      return Number(this.skillData.currentLevel) === level
    },
    isPreviousLevelCompleted(level: number): boolean {
      return Number(this.skillData.currentLevel) >= level - 1
    },
    getTooltipText(name: string, discipline: string): string {
      const attributeResearchTypes = [
        'attribute_research_premium',
        'attribute_research_repeatable',
        'attribute_research_base',
      ]
      const mutatedName = attributeResearchTypes.includes(name) ? 'attribute_research_base' : name
      return this.$replacePlaceholder(
        this.$t(`researchCell.${mutatedName}`),
        '{discipline}',
        this.$translateDiscipline(discipline),
      )
    },
    isMultiplier(name: string): boolean {
      return name === 'energy_multiplier' || name === 'starts_multiplier'
    },

    setPlus(name: string): string {
      return this.isMultiplier(name) ? '' : '+'
    },

    setTimes(name: string): string {
      if (this.isMultiplier(name)) return 'x'
      if (name === 'training_percentage' || name === 'arena' || name === 'club') return '%'
      return ''
    },

    getCount(first: string, second: number): string {
      return this.setPlus(first) + second + this.setTimes(first)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';
@import '@/assets/styles/components/icons/lab-icons.scss';

.lab-detail-table {
  position: relative;
  width: 100%;
  border-top: 0.063rem solid rgba(217, 236, 255, 0.6);
  border-bottom: 0.063rem solid rgba(217, 236, 255, 0.6);

  &__separators-wrapper {
    position: absolute;
    width: calc(100% - 10rem);
    height: 100%;
    display: flex;
    flex-direction: row;
    top: 0;
    right: 0;
    z-index: 1;
  }

  &__separator {
    display: flex;
    height: 100%;
    box-shadow: inset 0 0 1.25rem 0 rgba(27, 47, 71, 0.6);

    &--3 {
      width: calc(100% / 3);
    }

    &--4 {
      width: calc(100% / 4);
    }
  }

  & .muted {
    @if $isWsm {
      opacity: 0.5;
    }

    @if $isSsm {
      .lab-detail-box-level > p {
        color: #eaeaea;
        opacity: 0.5;
      }

      .lab-detail-box__row-body {
        opacity: 0.5;
      }
    }
  }
}

.lab-detail-box {
  width: 100%;
  height: 100%;

  @if $isSsm {
    background-color: rgba(105, 123, 164, 0.3);
  }

  @if $isWsm {
    background-color: rgba(55, 127, 184, 0.3);
  }

  &__row-body {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    &--3 {
      .lab-detail-box-count {
        width: calc(100% / 3);
      }
    }

    &--4 {
      .lab-detail-box-count {
        width: calc(100% / 4);
      }
    }
  }

  &:nth-child(odd) {
    @if $isSsm {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.05);
        z-index: 1;
      }
    }

    @if $isWsm {
      background-color: rgba(110, 156, 192, 0.5);
    }

    &.lab-detail-box-complete {
      @if $isSsm {
        background-color: rgba(7, 160, 141, 0.6);
      }

      @if $isWsm {
        background-color: #1ba816;
      }
    }

    &.lab-detail-box-disabled {
      background-color: rgba(32, 78, 124, 0.5);
      opacity: 50%;
    }
  }

  &-complete {
    position: relative;

    @if $isSsm {
      background-color: rgba(7, 160, 141, 0.6);
    }

    @if $isWsm {
      background-color: rgba(27, 168, 22, 0.6);
    }

    .lab-detail-box:nth-child(odd) {
      background-color: unset;
    }

    .lab-detail-box-count {
      background-color: unset;
    }

    .lab-detail-box-level {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.813rem;
      background: transparent;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        @if $isSsm {
          background-color: rgba(105, 123, 164, 0.8);
        }

        @if $isWsm {
          background-color: rgba(55, 127, 184, 0.4);
        }
      }
    }
  }

  &-disabled {
    background-color: rgba(32, 78, 124, 0.5);
    opacity: 50%;
  }

  &-actual::after {
    content: '';
    position: absolute;
    width: calc(100% - 0.125rem);
    height: calc(100% - 0.125rem);
    top: 0.125rem;
    left: 0.125rem;
    z-index: 3;

    @if $isSsm {
      outline: #0ee7cc solid 0.125rem;
      box-shadow: 0rem 0rem 1rem 0.125rem inset #0ee7cc;
    }

    @if $isWsm {
      outline: #3fe443 solid 0.125rem;
      box-shadow: 0rem 0rem 1rem 0.125rem inset #3fe443;
    }
  }

  &-header {
    display: flex;
    height: 5.438rem;
    width: 100%;
    border-bottom: 0.063rem solid rgba(217, 236, 255, 0.6);

    @if $isSsm {
      background-color: rgba(42, 54, 85, 0.5);
    }

    @if $isWsm {
      background: #1b4262;
    }

    &.long-row {
      .lab-detail-box-header-info {
        width: 10rem;

        @if $isSsm {
          background-color: rgba(105, 123, 164, 0.2);
        }
      }

      .lab-detail-box-header-icons {
        flex: 1;
        height: 100%;
        z-index: 2;
        justify-content: flex-end;

        .lab-detail-box-header-text {
          display: flex;
          justify-content: center;
        }

        &--3 {
          .lab-detail-box-header-text {
            width: calc(100% / 3);
          }
        }

        &--4 {
          .lab-detail-box-header-text {
            width: calc(100% / 4);
          }
        }
      }

      .lab-detail-box-header-text {
        display: flex;
      }

      .lab-detail-box-header-discipline {
        margin-left: auto;
        background-color: unset;
        box-shadow: unset;

        &.affected {
          position: relative;
          margin-left: 0;
          width: calc(100% / 3);
          gap: 0.375rem;

          .lab-detail-box-header-text {
            margin-left: 0;
          }
        }
      }
    }

    &-info {
      width: 9.2rem;
      height: 100%;

      &-tooltip {
        margin: 0 auto;
        width: 100%;
      }
    }

    &-discipline {
      background-color: rgba(35, 52, 65, 0.5);
      width: 8rem;
      height: 100%;
      box-shadow: inset 0 0 20px 0 #1b2f47;

      &-icon {
        height: 5.438rem;
        width: 5.438rem;
        margin: 0 auto;
      }
    }
  }

  &.long-row {
    .lab-detail-box-discipline {
      margin: unset;
      margin-left: auto;
    }

    .lab-detail-box-count {
      box-shadow: unset;
      background-color: unset;
    }
  }

  &-count,
  &-level {
    min-width: 8rem;
    box-shadow: inset 0 0 20px 0 rgba(27, 47, 71, 0.6);
    position: relative;

    @if $isSsm {
      background-color: rgba(105, 123, 164, 0.8);
    }

    @if $isWsm {
      background-color: rgba(55, 127, 184, 0.3);
    }
  }

  &-level {
    width: 10rem;
    //background-color: rgba(55, 127, 184, 0.8);
    box-shadow: none;
  }

  &:first-child {
    width: 10rem;

    .lab-detail-box-count {
      background-color: rgba(55, 127, 184, 0.8);
      box-shadow: none;

      &:nth-child(odd) {
        background: rgba(96, 158, 206, 0.5);
      }
    }
  }
}

.lab-detail-tooltip {
  width: 30rem;

  section:last-child {
    .lab-detail-tooltip-row {
      &:before {
        content: '';
        background: unset;
      }
    }
  }

  &-row {
    width: 100%;
    height: 6rem;
    display: flex;
    position: relative;
    margin: 1rem 0;

    &:before {
      content: '';
      position: absolute;
      bottom: -0.5rem;
      left: 0;
      width: 100%;
      height: 0.125rem;
      background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
    }

    .lab-detail-tootlip-text {
      margin-left: 1.875rem;
      width: 80%;
    }

    &-icon {
      height: 5.438rem;
      width: 5.438rem;
    }
  }
}
</style>
