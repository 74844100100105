<template>
  <section v-if="summaryData" class="w-full flex flex-col items-center">
    <div class="championship-summary-league-info flexing">
      <p class="text-texts-standard-important text-32 font-bold">
        {{ setResultTitle }}
      </p>
    </div>
    <section class="relative">
      <div class="championship-summary-em-bg">
        <div class="result-effect" :class="summaryData.next_league_status" />
      </div>
      <div v-if="!isStay" class="championship-summary-leagues flexing">
        <aside class="flex items-center justify-end">
          <p class="text-texts-standard-important text-50 uppercase">
            {{ $t('club.' + summaryData.current_league) }}
          </p>
          <div
            class="championship-summary-leagues-logo"
            :class="'badge-' + toKebabCase(summaryData.current_league) + '-140'"
          />
        </aside>
        <div
          class="triangle triangle-left championship-summary-leagues-arrow"
          :class="isPromotion ? 'green' : 'red'"
        />
        <aside class="flex items-center justify-start">
          <div
            v-if="isPromotion"
            class="championship-summary-leagues-glow"
            :style="{
              backgroundImage:
                'url(' +
                pathImages +
                'clubs/championship/glows/' +
                toKebabCase(summaryData.next_league) +
                '-glow.avif)',
            }"
          />
          <div
            class="championship-summary-leagues-logo"
            :class="'badge-' + toKebabCase(summaryData.next_league) + '-140'"
          />
          <p class="text-texts-standard-important text-50 uppercase">
            {{ $t('club.' + summaryData.next_league) }}
          </p>
        </aside>
      </div>
      <div v-else class="championship-summary-leagues flexing">
        <section class="flexing relative">
          <div
            class="championship-summary-leagues-glow"
            :style="{
              backgroundImage:
                'url(' +
                pathImages +
                'clubs/championship/glows/' +
                toKebabCase(summaryData.next_league) +
                '-glow.avif)',
            }"
          />
          <div
            class="championship-summary-leagues-logo"
            :class="'badge-' + toKebabCase(summaryData.next_league) + '-140'"
          />
          <p class="text-texts-standard-important text-50 uppercase">
            {{ $t('club.' + summaryData.next_league) }}
          </p>
        </section>
      </div>
    </section>
  </section>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { toKebabCase } from '@/helpers'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { ClubChampsSummary } from '@/interfaces/clubs/ClubChampionships'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'ChampionshipSummaryNotWinner',

  props: {
    summaryData: {
      type: Object as PropType<ClubChampsSummary>,
      required: true,
    },
    isPromotion: {
      type: Boolean,
      default: false,
    },
    isStay: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    setResultTitle(): string {
      if (this.isStay) return this.$t('club.stayedLeague') // zotrvanie v lige
      if (this.isPromotion) return this.$t('club.clubChampAdvances') // postup do vyssej ligy
      return this.$t('club.clubChampDescents') // zostup do nizsej ligy
    },
  },
  methods: {
    toKebabCase,
  },
})
</script>
