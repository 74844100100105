import {
  warehouseBuildArtefactEndpoint,
  warehouseBuyPackageEndpoint,
  warehouseClaimIngredientEndpoint,
  warehouseConfigEndpoint,
  warehouseCreateIngredientEndpoint,
  warehouseMeltIngredientEndpoint,
  warehouseSkipProductionTimeEndpoint,
  warehouseStateEndpoint,
} from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'

import type Reward from '@/interfaces/Reward'
import type {
  WarehouseBuildArtefactActionPayload,
  WarehouseBuildArtefactApiResponse,
} from '@/interfaces/responses/events/warehouse/WarehouseBuildArtefactApiResponse'
import type {
  WarehouseBuyPackageActionPayload,
  WarehouseBuyPackageApiResponse,
} from '@/interfaces/responses/events/warehouse/WarehouseBuyPackageApiResponse'
import type { WarehouseClaimIngredientApiResponse } from '@/interfaces/responses/events/warehouse/WarehouseClaimIngredientApiResponse'
import type {
  ArtefactBuild,
  ConvertCost,
  WarehouseConfigApiResponse,
} from '@/interfaces/responses/events/warehouse/WarehouseConfigApiResponse'
import type {
  WarehouseCreateIngredientActionPayload,
  WarehouseCreateIngredientApiResponse,
} from '@/interfaces/responses/events/warehouse/WarehouseCreateIngredientApiResponse'
import type {
  WarehouseMeltIngredientActionPayload,
  WarehouseMeltIngredientApiResponse,
} from '@/interfaces/responses/events/warehouse/WarehouseMeltIngredientApiResponse'
import type {
  ConvertOperation,
  Ingredient,
  IngredientAmount,
} from '@/interfaces/responses/events/warehouse/WarehouseSharedInterface'
import type { WarehouseSkipProductionTimeApiResponse } from '@/interfaces/responses/events/warehouse/WarehouseSkipProductionTimeApiResponse'
import type { WarehouseStateApiResponse } from '@/interfaces/responses/events/warehouse/WarehouseStateApiResponse'
import { defineStore } from 'pinia'
import type { InferedTypeMerger } from '@/interfaces/utils'

interface ConvertingOperationState {
  converting_operation: ConvertOperation
  converting: boolean
  converting_started?: boolean
  claimed?: boolean
  skipped?: boolean
}

export interface ArtefactCraftingModalState {
  isModalOpen: boolean
  multiplier: number | null
  isChest?: boolean
  selectedArtefact?: Partial<ArtefactBuild> | 'chest' | null
  missingIngredients?: IngredientAmount[]
  isCraftedSuccessfully?: boolean
  rewards: InferedTypeMerger<[Reward, IngredientAmount]>[]
}
export interface WarehouseState {
  config: WarehouseConfigApiResponse | null
  state: WarehouseStateApiResponse | null
  modalState: ArtefactCraftingModalState
}

export const useWarehouseStore = defineStore('warehouseStore', {
  state: (): WarehouseState => ({
    config: null,
    state: null,
    modalState: {
      isModalOpen: false,
      isChest: false,
      selectedArtefact: null,
      multiplier: null,
      rewards: [],
      missingIngredients: [],
      isCraftedSuccessfully: false,
    },
  }),
  getters: {
    getConfig(): WarehouseConfigApiResponse | null {
      return this.config ?? null
    },

    getArtefactBuildsConfig(): ArtefactBuild[] | null {
      return this.config?.builds ?? null
    },

    getIngredientsConfig(): Ingredient[] | null {
      return this.config?.ingredients ?? null
    },

    getArtefactBuildMultipliers(): WarehouseState['config']['build_multipliers'] | null {
      return this.config?.build_multipliers ?? null
    },

    getPackageMultipliers(): WarehouseState['config']['package_multipliers'] | null {
      return this.config?.package_multipliers ?? null
    },

    getPackageCost(): WarehouseState['config']['package_cost'] | null {
      return this.config?.package_cost ?? null
    },

    getPackageStartsEquivalent(): WarehouseState['config']['package_starts_equivalent'] | null {
      return this.config?.package_starts_equivalent ?? null
    },

    getCommodityPointsConfig(): WarehouseState['config']['commodity_points'] | null {
      return this.config?.commodity_points ?? null
    },

    getExchangeDateEnd(): WarehouseState['config']['exchange_date_end'] {
      return this.config?.exchange_date_end ?? null
    },

    getEventEnd(): WarehouseState['config']['date_end'] {
      return this.config?.date_end ?? null
    },

    getIngredientsState(): IngredientAmount[] | null {
      return this.state?.ingredients ?? null
    },

    getIngredientStateById(): (id: number) => IngredientAmount {
      return (id: number): IngredientAmount =>
        this.state?.ingredients?.find(
          (item: IngredientAmount): boolean => item.ingredient.id === id,
        ) ?? null
    },

    isFreeForward(): WarehouseState['state']['free_fast_forward'] | null {
      return this.state?.free_fast_forward ?? false
    },

    getFreePackageAmount(): WarehouseState['state']['free_packages'] | null {
      return this.state?.free_packages ?? 0
    },

    getModalState(): ArtefactCraftingModalState {
      return this.modalState
    },

    getConvertingState(): ConvertingOperationState {
      return {
        converting_operation: this.state.converting_operation ?? null,
        converting: this.state.converting,
      }
    },

    getConversionCostsConfig(): ConvertCost[] | null {
      return this.config.convert_costs ?? null
    },

    getConversionCostByIngredientId(): (ingredientId: number) => ConvertCost {
      return (ingredientId: number): ConvertCost =>
        this.config.convert_costs.find(
          (convert: ConvertCost): boolean => convert.source_ingredient === ingredientId,
        ) ?? null
    },

    getIsRecalculating(): WarehouseState['config']['is_recalculating'] {
      return this.config?.is_recalculating ?? false
    },

    getTimeLeft(): WarehouseState['config']['time_left'] {
      return this.config?.time_left ?? 0
    },
  },
  actions: {
    clearConfig(): void {
      this.config = null
    },
    async loadConfig(force: boolean = false): Promise<void> {
      if (this.config !== null && !force) return
      try {
        const data = await internalAxios.get<{}, WarehouseConfigApiResponse>(
          warehouseConfigEndpoint,
        )

        if (data == null) return

        this.config = data
      } catch (error: unknown) {
        console.error(error)
      }
    },

    async loadState(): Promise<void> {
      try {
        const data = await internalAxios.get<{}, WarehouseStateApiResponse>(warehouseStateEndpoint)

        if (data == null) return

        this.state = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    setWarehouseModalState(state) {
      this.modalState = state
    },
    async buyPackage({ gems, multiplier }: WarehouseBuyPackageActionPayload): Promise<void> {
      try {
        const response = await internalAxios.post<{}, WarehouseBuyPackageApiResponse>(
          warehouseBuyPackageEndpoint,
          { multiplier, gems },
        )

        if (response == null) return

        this.state.ingredients = response?.ingredients

        this.modalState = {
          isModalOpen: true,
          isChest: true,
          isCraftedSuccessfully: true,
          multiplier,
          rewards: response?.gained_ingredients,
          selectedArtefact: {
            name: 'partsPackage',
          },
          missingIngredients: [],
        }

        if (!gems) {
          await this.loadState()
        }
      } catch (error: unknown) {
        console.error(error)
      }
    },

    async buildArtefact({
      build,
      multiplier,
    }: WarehouseBuildArtefactActionPayload): Promise<WarehouseBuildArtefactApiResponse> {
      try {
        const response = await internalAxios.post<{}, WarehouseBuildArtefactApiResponse>(
          warehouseBuildArtefactEndpoint,
          {
            build,
            multiplier,
          },
        )

        if (response == null) return

        this.state.ingredients = response.ingredients

        return response
      } catch (error: unknown) {
        console.error(error)
      }
    },

    async meltIngredient({
      ingredient,
      amount,
    }: WarehouseMeltIngredientActionPayload): Promise<void> {
      try {
        const response = await internalAxios.post<{}, WarehouseMeltIngredientApiResponse>(
          warehouseMeltIngredientEndpoint,
          {
            ingredient,
            amount,
          },
        )

        if (response == null) return

        this.state.ingredients = response.ingredients
      } catch (error: unknown) {
        console.error(error)
      }
    },

    async createIngredient({
      ingredient,
      amount,
    }: WarehouseCreateIngredientActionPayload): Promise<WarehouseCreateIngredientApiResponse> {
      try {
        const response = await internalAxios.post<{}, WarehouseCreateIngredientApiResponse>(
          warehouseCreateIngredientEndpoint,
          {
            ingredient,
            amount,
          },
        )

        this.state.ingredients = response?.ingredients

        const data = {
          converting_operation: response?.converting_operation,
          converting: response?.converting,
        }
        this.state.converting_operation = data.converting_operation
        this.state.converting = data.converting

        return response
      } catch (error: unknown) {
        console.error(error)
      }
    },

    async claimIngredient(): Promise<WarehouseClaimIngredientApiResponse> {
      try {
        const response = await internalAxios.post<{}, WarehouseClaimIngredientApiResponse>(
          warehouseClaimIngredientEndpoint,
        )

        this.state.ingredients = response?.ingredients
        const data = {
          converting_operation: response?.converting_operation,
          converting: response?.converting,
        }

        this.state.converting_operation = data.converting_operation
        this.state.converting = data.converting

        return response
      } catch (error: unknown) {
        console.error(error)
      }
    },

    async skipProductionTime(): Promise<WarehouseSkipProductionTimeApiResponse> {
      try {
        const response = await internalAxios.post<{}, WarehouseSkipProductionTimeApiResponse>(
          warehouseSkipProductionTimeEndpoint,
        )

        const data = {
          converting_operation: response?.converting_operation,
          converting: response?.converting,
        }

        this.state.converting_operation = data.converting_operation
        this.state.converting = data.converting

        return response
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
