<template>
  <div id="seasons-view" class="app-page-wrapper seasons absolute w-full h-full">
    <section class="w-full h-full safe-area">
      <div v-if="isSeasonFound">
        <menu-component
          menu-type="seasons"
          :title="$t('seasons.seasonOverview')"
          :right-box-icon="RANKING_POINTS.toLowerCase()"
          :right-box-value="Number(userRankingPoints)"
          @click="showSeasonsRankingPointsPopup = true"
        />

        <div class="seasons-content flex justify-center">
          <seasons-detail-box :season-stats="seasonStats" @load-season-stats="loadSeasonStats" />
          <seasons-rewards :rewards="seasonStats.rewards" />
        </div>
      </div>

      <div v-else class="flexing h-full text-white text-50">No season found.</div>

      <seasons-ranking-points-popup
        :show-popup="showSeasonsRankingPointsPopup"
        @close="showSeasonsRankingPointsPopup = false"
      />
    </section>
  </div>
</template>

<script lang="ts">
import SeasonsDetailBox from '@/components/Seasons/SeasonsDetailBox.vue'
import SeasonsRankingPointsPopup from '@/components/Seasons/SeasonsRankingPointsPopup.vue'
import SeasonsRewards from '@/components/Seasons/SeasonsRewards.vue'
import { CURRENT_SEASON, PREVIOUS_SEASON, RANKING_POINTS } from '@/globalVariables'
import { useMainSeasonStore } from '@/store/pinia/seasons/mainSeasonsStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { SeasonType } from '@/interfaces/Seasons'

interface ComponentData {
  RANKING_POINTS: typeof RANKING_POINTS
  showSeasonsRankingPointsPopup: boolean
}

export default defineComponent({
  name: 'SeasonsComponent',
  components: {
    SeasonsDetailBox,
    SeasonsRewards,
    SeasonsRankingPointsPopup,
  },
  data(): ComponentData {
    return {
      RANKING_POINTS,
      showSeasonsRankingPointsPopup: false,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userRankingPoints: 'getRankingPoints',
    }),
    ...mapState(useMainSeasonStore, {
      seasonStats: 'getSeasonStats',
    }),
    isSeasonFound(): boolean {
      const routeParam = this.$route.params.type

      return routeParam === CURRENT_SEASON || routeParam === PREVIOUS_SEASON
    },
  },
  created(): void {
    if (this.isSeasonFound) this.loadSeasonStats(this.$route.params.type as SeasonType)
  },
  methods: {
    ...mapActions(useMainSeasonStore, {
      loadSeasonStats: 'loadSeasonStats',
    }),
  },
})
</script>

<style lang="scss" scoped>
.seasons {
  &-content {
    width: 98%;
    margin: 0 auto;
    margin-top: 2rem;
  }
}
</style>
