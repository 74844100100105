import { openBoardGamesEvent } from '@/helpers'
import type { BoardGamesEventOsraConfigInterface } from '@/map-phaser-new/interfaces'

export const boardGamesEventOsra: BoardGamesEventOsraConfigInterface = {
  textureKey: 'board_games_event_osra_image',
  positionX: 2030,
  positionY: 1500,
  notificationX: 2030,
  notificationY: 1385,
  depth: 3,
  origin: 0.5,
  routeConfig: (/* router */): Promise<void> => openBoardGamesEvent(),
  inputConfig: {
    useHandCursor: true,
  },
  outlineConfig: {
    textureKey: 'board_games_event_osra_image_outline',
    depth: 3,
  },
}

export const iceAndFireEventOsra: BoardGamesEventOsraConfigInterface = {
  textureKey: 'ice_and_fire_event_osra_image',
  positionX: 2030,
  positionY: 1500,
  notificationX: 2030,
  notificationY: 1385,
  depth: 3,
  origin: 0.5,
  routeConfig: (/* router */): Promise<void> => openBoardGamesEvent(),
  inputConfig: {
    useHandCursor: true,
  },
  outlineConfig: {
    textureKey: 'ice_and_fire_event_osra_image_outline',
    depth: 3,
  },
}
