<template>
  <div v-if="discipline" class="equipment-discipline-info flex items-center justify-between">
    <div class="flex items-center">
      <app-discipline-icon class="ml-2" :discipline-id="disciplineId" :size="70" />
      <p class="text-texts-standard-additional text-40 uppercase font-bold ml-4">
        {{ $translateDiscipline(disciplineId) }}
      </p>
    </div>
    <div v-if="discipline?.[POWER]" class="flex items-center">
      <p class="text-texts-standard-additional text-36 uppercase">
        {{ `${$t('common.totalPower')}:` }}
      </p>
      <p class="text-texts-standard-important text-36 font-bold uppercase ml-4 mr-8">
        {{ `${$filters.$formatNumber(discipline[POWER].value)}` }}
      </p>
      <tippy theme="WSM" placement="left" max-width="35rem" class="info-side">
        <app-icon icon-name="info-70" />
        <template #content>
          <div class="tooltip-content text-texts-standard-default text-30">
            <profile-tooltip
              :rows="attributesMapper"
              :title="$t('common.totalPower')"
              :total-value="discipline?.POWER?.value"
              :disc-id="disciplineId"
            />
          </div>
        </template>
      </tippy>
    </div>
  </div>
</template>

<script lang="ts">
import AppDisciplineIcon from '@/components/GlobalComponents/AppDisciplineIcon.vue'
import ProfileTooltip from '@/components/Profile/ProfileTooltip.vue'
import {
  CATEGORY_BASE,
  EQUIPMENT,
  POWER,
  RESEARCH,
  pathImages,
  userDisciplinesAttributesEndpoint,
} from '@/globalVariables'
import { calculateAttributes } from '@/helpers'
import type { FinalObject } from '@/helpers'
import type Country from '@/interfaces/Country'
import { defineComponent } from 'vue'

interface Discipline {
  id: number
  name: 'Alpine skiing - Downhill' | string
}

interface UsersAvailableDisciplineUser {
  id: string
  username: string
  country: Country
  event_badges?: string[]
}

interface UsersAvailableDisciplineAttribute {
  attribute: {
    id: number
    name: string
    max_value: number
    category: string
  }
  attribute_value: number
}

interface UsersAvailableDiscipline {
  user: UsersAvailableDisciplineUser
  attributes: UsersAvailableDisciplineAttribute[]
}

interface DisciplineAttributesApiResponse {
  discipline: Discipline
  usersAvailableDisciplines: UsersAvailableDiscipline[]
}

interface ComponentData {
  pathImages: typeof pathImages
  CATEGORY_BASE: typeof CATEGORY_BASE
  EQUIPMENT: typeof EQUIPMENT
  RESEARCH: typeof RESEARCH
  POWER: typeof POWER
  discipline: FinalObject
  translateKeys: (typeof CATEGORY_BASE | typeof EQUIPMENT | typeof RESEARCH | typeof POWER)[]
}

interface ProfileTooltipRow {
  text: string
  value: number
}

export default defineComponent({
  name: 'EquipmentDisciplineInfo',
  components: {
    AppDisciplineIcon,
    ProfileTooltip,
  },
  props: {
    disciplineId: {
      type: Number,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
      CATEGORY_BASE,
      EQUIPMENT,
      RESEARCH,
      POWER,
      discipline: {} as FinalObject,
      translateKeys: [],
    }
  },
  computed: {
    attributesMapper(): ProfileTooltipRow[] {
      return [
        {
          text: this.$t('common.basicAttribute'),
          value: this.discipline[CATEGORY_BASE]?.value ?? 0,
        },
        {
          text: this.$t('common.equipment'),
          value: this.discipline[EQUIPMENT]?.value ?? 0,
        },
        {
          text: this.$t('common.research'),
          value: this.discipline[RESEARCH]?.value ?? 0,
        },
      ]
    },
  },
  watch: {
    async disciplineId(): Promise<void> {
      await this.loadDisciplineData()
    },
  },
  async created(): Promise<void> {
    await this.loadDisciplineData()
  },
  methods: {
    async loadDisciplineData(): Promise<void> {
      try {
        const data = await this.$axios.post<{}, DisciplineAttributesApiResponse>(
          `${userDisciplinesAttributesEndpoint}/${this.disciplineId}/user`,
        )
        if (data?.usersAvailableDisciplines) {
          calculateAttributes(data, this.discipline)
          this.translateKeys = [CATEGORY_BASE, EQUIPMENT, RESEARCH]
        }
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.equipment-discipline-info {
  position: relative;
  width: 96%;
  height: 5.25rem;
  margin-top: 0.375rem;

  & > * {
    z-index: 1;
  }

  @if $isWsm {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 55.313rem;
      height: 100%;
      background: url($path-equipment + 'discipline-header-bg.avif');
      background-size: cover;
      z-index: 0;
    }
  }
}
</style>
