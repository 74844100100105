<template>
  <div class="gems-free-pack relative text-texts-standard-default">
    <div v-if="availableAds.nextAdsCountdown" class="opacity-bg-time h-full absolute" />
    <div class="relative bonus">
      <div class="bonus-up uppercase text-30 font-bold flexing">
        <span>
          {{ $t('premiumGroup.freeGems') }}
        </span>
      </div>
      <div class="bonus-down flexing text-30 font-bold">
        <span class="relative top-0.5">{{ `${remainingAttempts}x` }}</span>
      </div>
    </div>
    <div
      class="gems-img absolute"
      :style="{
        background: 'url(' + pathImages + `premium/gemsPackage/wsm-gems-free.avif)center no-repeat`,
        backgroundSize: 'cover',
      }"
    >
      <div class="relative">
        <span class="pack-shine" />
        <span class="pack-shine" />
        <span class="pack-shine" />
      </div>
    </div>
    <article
      v-if="getReward?.value"
      class="gems-free-pack-amount absolute flexing text-texts-standard-important text-60 font-bold"
    >
      <p>
        {{ `+${getReward?.value}` }}
      </p>
      <app-main-icon :icon-name="getReward?.name" :icon-size="56" />
    </article>

    <footer class="gems-free-pack-footer absolute m-auto flex justify-center">
      <watch-for-reward
        :refresh-time="availableAds.nextAdsCountdown"
        :ad-type="VIDEO_ADS_FREE_GEMS"
        @reload-content="loadData()"
      />
    </footer>
  </div>
</template>

<script lang="ts">
import WatchForReward from '@/components/Premium/Accessories/WatchForReward.vue'
import { GEMS, VIDEO_ADS_FREE_GEMS, pathImages } from '@/globalVariables'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type Reward from '@/interfaces/Reward'

interface ComponentData {
  VIDEO_ADS_FREE_GEMS: typeof VIDEO_ADS_FREE_GEMS
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'PremiumGemsSmallPack',
  components: {
    WatchForReward,
  },
  emits: ['reloadPacks'],
  data(): ComponentData {
    return {
      VIDEO_ADS_FREE_GEMS,
      pathImages,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      availableAds: 'getAvailableAds',
    }),
    getReward(): Reward {
      return this.availableAds.reward?.find((reward: Reward): boolean => reward.name === GEMS)
    },
    remainingAttempts(): number {
      return this.availableAds.availableAds ?? 0
    },
  },
  created(): void {
    this.loadAvailableAds(VIDEO_ADS_FREE_GEMS)
  },
  methods: {
    ...mapActions(usePremiumStore, ['loadAvailableAds']),
    loadData(): void {
      this.$emit('reloadPacks')
      this.loadAvailableAds(VIDEO_ADS_FREE_GEMS)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/animations.scss';

.gems-free-pack {
  height: 19.188rem;
  width: 35.625rem;
  background: url($path-premium + 'gemsPackage/BG_OFFER_BOX_SMALL_1.webp?v2') center no-repeat;
  background-size: contain;

  &::before {
    content: '';
    width: 1.125rem;
    height: 100%;
    position: absolute;
    left: -0.5rem;
    top: 0;
    background: url($path-premium + 'gemsPackage/effect-smallblue-left.webp') center no-repeat;
    background-size: cover;
  }

  .opacity-bg-time {
    width: 100%;
    background: rgb(4 8 4 / 25%);
    z-index: 1;
  }

  .bonus {
    width: 21.0625rem;
    height: 5.625rem; // 2 items
    margin-top: 0.6875rem;
    margin-left: 1rem;

    &-up {
      position: absolute;
      background-image: linear-gradient(to left, #d92b2b, #ff2c2c);
      max-width: 100%;
      padding-left: 0.75rem;

      @if $isSsm {
        padding-right: 0.813rem;
      }

      @if $isWsm {
        clip-path: polygon(100% -100%, 0% 0%, 0% 100%, 90% 100%);
        padding-right: 2rem;
      }

      &:v-deep(.bonus-icon-gems) {
        width: 2rem;
        height: 2rem;
        position: relative;
        top: 0.175rem;
        left: -0.5rem;
        background: url($path-icons + 'main/main-icons-120_v4.avif') -6rem 0 no-repeat;
        background-size: 24rem;
        margin: 0 0.5rem;
        display: inline-block;
      }

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-down {
      position: absolute;
      min-width: 4.25rem;
      bottom: 0;

      @if $isSsm {
        background: linear-gradient(320deg, #119780 10%, #0ee7cc);
      }

      @if $isWsm {
        background: linear-gradient(to top, #068f19, #3fe443);
        clip-path: polygon(100% -100%, 0% 0%, 0% 100%, 90% 100%);
        padding-right: 0.188rem;
      }
    }
  }

  .gems-img {
    width: 22.5rem;
    height: 18.75rem;
    top: -1rem;
    right: -3rem;
  }

  &-amount {
    width: 20.5rem;
    height: 4.375rem;
    top: 7.5rem;

    @if $isSsm {
      background: linear-gradient(to left, transparent, rgba(88, 107, 157, 0.99), transparent);
    }

    @if $isWsm {
      background: linear-gradient(to left, transparent, rgba(193, 193, 193, 0.26), transparent);
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      background: linear-gradient(to left, transparent, rgba(255, 255, 255, 80%), transparent);
      width: 80%;
      height: 0.125rem;
    }
  }

  &-footer {
    width: 20rem;
    bottom: 1rem;
    left: 50%;
    transform: translate(-50%);
    z-index: 2;
  }
}

.pack-shine {
  position: absolute;
  width: 2.75rem;
  height: 2.75rem;
  top: 0;
  left: 0;
  // TODO switch assetu od Erika
  background-image: url(https://appspowerplaymanager.vshcdn.net/images/winter-sports/grandPrize/badges/badge-glow-1.webp?v2);
  background-size: cover;
  opacity: 0;
  z-index: 1;
  animation-name: twinkle;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &:nth-of-type(1) {
    top: 5rem;
    left: 6.5rem;
    animation-delay: 1950ms;
  }
  &:nth-of-type(2) {
    top: 4.5rem;
    left: 14rem;
    animation-delay: 1650ms;
  }
  &:nth-of-type(3) {
    top: 11rem;
    left: 8rem;
    animation-delay: 1350ms;
  }
}
</style>
