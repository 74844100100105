<template>
  <info-popup width="101.5rem" height="52.813rem" popup-type="info">
    <template #header>
      <pre-boss-fight-header
        :title="$replacePlaceholder($t('common.taskNumber'), '%s', taskNumber)"
        :sub-title="$t('common.personalBest')"
        :points="formatResult(bestScore, disciplineId)"
        :discipline-id="disciplineId"
        @close="closeButtonClicked"
      />
    </template>
    <pre-boss-fight-content
      :discipline-id="disciplineId"
      :quest-id="questId"
      @hide-popup="$emit('close')"
    />
  </info-popup>
</template>

<script lang="ts">
import PreBossFightContent from '@/components/Career/PreBossFight/PreBossFightContent.vue'
import PreBossFightHeader from '@/components/Career/PreBossFight/PreBossFightHeader.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { formatResult } from '@/helpers'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions } from 'pinia'

export default {
  name: 'PreBossFightMain',
  components: {
    InfoPopup,
    PreBossFightContent,
    PreBossFightHeader,
  },
  props: {
    questId: {
      type: Number,
      required: true,
    },
    disciplineId: {
      type: Number,
      required: true,
    },
    bestScore: {
      type: Number,
      default: 0,
    },
    taskNumber: {
      type: Number,
      default: 0,
    },
  },
  emits: ['close'],
  created(): void {
    this.loadBenefitsSlots()
    this.loadBenefitsData()
  },
  methods: {
    ...mapActions(useResponseTaskStore, {
      loadBenefitsSlots: 'loadBenefitsSlots',
      loadBenefitsData: 'loadBenefits',
    }),
    formatResult,
    closeButtonClicked(): void {
      this.$emit('close')
    },
  },
}
</script>
