<template>
  <transition appear name="anim">
    <div class="club-chat-cell" :class="whoWrites === 'me' ? 'me' : 'you'">
      <avatar-box
        class="club-chat-cell-avatar"
        :avatar-id="senderAvatar"
        :background-id="senderAvatarBackground"
        :avatar-gender="sex"
        :custom-width="7"
        :custom-height="7"
        :online-status="active ? 'online' : 'offline'"
        @click="sendToUserProfilePage"
      />
      <div class="club-chat-cell-message" :class="whoWrites === 'me' ? 'me' : 'you'">
        <section class="flex justify-between items-center w-full">
          <app-user
            :id="senderId"
            :country="senderCountry"
            :name="senderName"
            class="club-chat-cell-message-player"
          />
          <section class="flexing">
            <p class="club-chat-cell-message-time text-texts-standard-default text-36">
              {{ dateFormatted.time }}
            </p>
            <p
              class="club-chat-cell-message-time text-texts-standard-default text-36 font-bold ml-5"
            >
              {{ dateFormatted.date }}
            </p>
          </section>
        </section>
        <p class="text-texts-standard-default text-36 text-left word-break" v-html="cellContent" />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import AvatarBox from '@/components/Profile/AvatarBox.vue'
import { pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'

interface ComponentData {
  pathImages: typeof pathImages
}
interface ChatDateObject {
  time: string
  date: string
}
export default defineComponent({
  name: 'ChatCell',
  components: {
    AvatarBox,
    AppUser,
  },
  props: {
    cellContent: {
      type: String,
      default: '',
    },
    whoWrites: {
      // na zaklade tohoto by sa urcovali styly (farba bunky, ci je vlavo alebo vpravo ...)
      type: String,
      default: 'me', // you
    },
    senderName: {
      type: String,
      default: 'me',
    },
    senderId: {
      type: String,
      default: '',
    },
    senderCountry: {
      type: String,
      default: 'svk',
    },
    senderAvatar: {
      type: Number,
      default: 1,
    },
    senderAvatarBackground: {
      type: Number,
      default: 1,
    },
    sex: {
      type: String,
      default: 'male',
    },
    active: {
      type: Boolean,
      default: true,
    },
    date: {
      type: Number,
      default: 0,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    dateFormatted(): ChatDateObject {
      const dateFormat = new Date(this.date)
      let dateObject = {} as ChatDateObject
      dateObject.time = `${dateFormat.getHours()}:${String(dateFormat.getMinutes()).padStart(
        2,
        '0',
      )}`
      dateObject.date = `${dateFormat.getDate()}.${
        dateFormat.getMonth() + 1
      }.${dateFormat.getFullYear()}`
      return dateObject
    },
  },
  methods: {
    sendToUserProfilePage(): void {
      this.$router.push({
        name: this.$getWebView('ProfileOverview'),
        params: { id: 1, user: this.senderId },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.club-chat-cell {
  display: flex;
  align-items: center;
  margin-top: 1.25rem;

  &:first-of-type {
    margin-top: 0;
  }

  &.me {
    flex-direction: row-reverse;
  }

  &-avatar {
    margin-right: 1.25rem;

    :deep(.online-status) {
      width: 1.813rem;
      height: 1.813rem;
      z-index: 2;
    }
  }

  &-message {
    width: 71.813rem;
    min-height: 6.625rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 0.5rem 1.25rem;
    position: relative;
    user-select: text;

    /*  &:before {
      content: "";
      position: absolute;
      background: #6c9ec4;
      width: 0.5rem;
      top: 0;
      transform: skew(47deg);
    } */

    &.me {
      border: solid 0.063rem #6c9ec4;
      background-color: rgba(35, 155, 125, 0.7);
      // Skosene rozky. Aktualne ich neviem resisovat podla velkosti výšky boxu spravy tak vypínam
      // clip-path: polygon(2% 0%, -12% 100%, 100% 100%, 100% 0%, 100% 0);
      // clip-path: polygon(7px 0%, -200px 100%, 100% 100%, 100% 0%, 100% 0);

      /* &:before {
          height: 1.25rem;
          left: 0.2rem;
          transform: skew(-47deg);
      } */
    }

    &.you {
      border: solid 0.063rem #6c9ec4;
      background-color: rgba(35, 109, 155, 0.7);
      // clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 15%, 98% 0);

      /* &:before {
          height: 1.7rem;
          right: 0;
          transform: skew(47deg);
        } */
    }

    &-player {
      height: 2.5rem;
    }

    .word-break {
      word-break: break-word;
    }
  }
}

.anim-enter-from {
  opacity: 0;
  transform: scale(0.6);
}

.anim-enter-to {
  opacity: 1;
  transform: scale(1);
}

.anim-enter-active {
  transition: all 0.4s ease;
}
</style>
