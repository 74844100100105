import type { EventBadgeInterface } from '@/interfaces/EventBadgeInterface'
import type Reward from '@/interfaces/Reward'

export interface ReportData {
  is_temporary?: boolean
  dnf?: boolean
  dsq?: boolean
}

export interface RankingUser {
  id?: string
  name?: string
  points?: number
  level?: number
  clubId?: string
  clubLogoId?: number
  clubLogoBgId?: number
  eventBadges?: EventBadgeInterface[]
  username?: string
  club?: string
  club_id?: string
  country?: string
  LEVEL?: number
  RANKING_POINTS?: number
  experience?: number
  training_points?: number
  isBot?: boolean
  position?: number
  result?: number
  rounds?: {
    round: number
    result: number | null
    reportData?: ReportData
  }[]
  event_badges?: EventBadgeInterface[]
  club_logo_id?: number
  season?: string
  members?: number
  type?: string
  discipline_id?: number
  club_background_logo_id?: number
  rewards?: Reward[]
  titles?: number
  tournament_points?: number
  tournament_titles?: number
  reportData?: ReportData
}

export enum RankingType {
  Global = 'global',
  Discipline = 'discipline',
  Records = 'records',
  ClubActivity = 'clubActivity',
  ClubLeaderboard = 'clubLeaderboard',
  TournamentPoints = 'tournamentPoints',
  TournamentTitles = 'tournamentTitles',
  TournamentResults = 'tournamentResults',
  League = 'league',
}
