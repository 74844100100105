import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const carsRightTopToLeftTopPath: PathObjectInterface = {
  mainPath: { x: 3434, y: 603 },
  spline: [
    3434, 603, 3439, 649, 3433, 678, 3410, 708, 3377, 738, 3356, 755, 3339, 768, 3324, 773, 3324,
    746, 3315, 719, 3295, 695, 3260, 663, 3220, 635, 3150, 598, 3130, 588, 3082, 567, 3048, 552,
    3006, 540, 2957, 527, 2901, 515, 2846, 505, 2802, 499, 2768, 486, 2730, 482, 2692, 490, 2663,
    494, 2614, 498, 2578, 501, 2521, 513, 2469, 532, 2404, 548, 2353, 562, 2303, 575, 2224, 587,
    2178, 592, 2090, 608, 2048, 613, 2004, 619, 1962, 625, 1900, 631, 1835, 638, 1754, 653, 1699,
    666, 1666, 674, 1617, 683, 1583, 693, 1483, 699, 1398, 704, 1334, 733, 1235, 756, 1165, 765,
    1124, 768, 1089, 766, 1069, 772, 1031, 791, 974, 818, 924, 845, 886, 885, 858, 917, 828, 940,
    823, 956, 825, 975, 833, 995, 826, 1028, 807, 1055, 778, 1074, 749, 1093, 695, 1113, 649, 1123,
    610, 1128, 586, 1129, 546, 1120, 509, 1108, 475, 1091, 447, 1065, 419, 1027, 406, 1000, 415,
    971, 434, 940, 517, 857, 613, 777, 672, 725, 696, 669, 696, 620, 679, 566, 648, 533, 606, 496,
    583, 469, 572, 451, 563, 437,
  ],
}
