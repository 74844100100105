<template>
  <main v-if="tasks" class="tasks-main flex flex-col justify-between w-full h-full safe-area">
    <tasks-header :team-id="currentTeamId" />
    <tasks-sub-header
      :current-task="currentStep"
      :current-tier="currentDifficulty"
      :is-boss="isBossFight"
    />
    <div class="tasks-main-middle flex justify-center">
      <div class="tasks-main-middle-left h-full w-1/2 flex flex-wrap px-6 pt-2 pb-9">
        <tasks-stages :current-task="currentStep" />
      </div>
      <div class="tasks-main-middle-right flex flex-col justify-center h-full ml-12">
        <tasks-boss
          v-if="isBossFight && tasks.length"
          :task="tasks[0]"
          :boss="bossData"
          @skip-boss="skipTask"
        />
        <template v-else-if="currentDifficulty === 1">
          <tasks-box-vertical
            v-for="(task, index) in tasks"
            :key="index"
            :task="task"
            @skip="skipTask"
          />
        </template>
        <template v-else>
          <tasks-box-horizontal
            v-for="(task, index) in tasks"
            :key="index"
            :class="{ 'mb-4': index !== tasks.length - 1 }"
            :task="task"
            @skip="skipTask"
          />
        </template>
      </div>
    </div>
    <tasks-footer :rewards="rewards" :is-disabled="!canClaimRewards" />
  </main>
</template>

<script lang="ts">
import { useTaskChainStore } from '@/store/pinia/events/taskChainStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import TasksBoss from './TasksBoss.vue'
import TasksBoxHorizontal from './TasksBoxHorizontal.vue'
import TasksBoxVertical from './TasksBoxVertical.vue'
import TasksFooter from './TasksFooter.vue'
import TasksHeader from './TasksHeader.vue'
import TasksStages from './TasksStages.vue'
import TasksSubHeader from './TasksSubHeader.vue'

export default defineComponent({
  name: 'TasksMain',
  components: {
    TasksHeader,
    TasksFooter,
    TasksStages,
    TasksBoxVertical,
    TasksBoxHorizontal,
    TasksBoss,
    TasksSubHeader,
  },
  computed: {
    ...mapState(useTaskChainStore, {
      tasks: 'getTasks',
      currentTeamId: 'getCurrentTeamId',
      currentStep: 'getCurrentStep',
      currentDifficulty: 'getCurrentDifficulty',
      isBossFight: 'getIsBossFight',
      bossData: 'getBossData',
      canClaimRewards: 'getCanClaimRewards',
      rewards: 'getRewards',
    }),
  },
  methods: {
    ...mapActions(useTaskChainStore, {
      skipTask: 'skipTask',
    }),
  },
})
</script>

<style lang="scss" scoped>
.tasks-main {
  &-subheader {
    padding-left: 3.1875rem;

    p:first-child {
      width: 39.1875rem;
    }

    p:last-child {
      margin-left: 6rem;
    }
  }

  &-middle {
    height: 33rem;

    &-left {
      width: 53.625rem;
    }

    &-right {
      width: 65.375rem;
    }
  }
}
</style>
