import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const carsPath: PathObjectInterface = {
  mainPath: {
    x: 2900.9934717864776,
    y: 647.4457339130979,
  },
  nextPaths: [
    {
      x: 2838.1818295996395,
      y: 619.7045525594829,
    },
    {
      x: 2790.9091016438397,
      y: 622.1287950187545,
      control1X: 2809.8961704979006,
      control1Y: 611.6761971541634,
      control2X: 2838.1818295996395,
      control2Y: 619.7045525594829,
    },

    {
      x: 1951.0924037899463,
      y: 1020.3249049429509,
    },
    {
      x: 1853.214304195373,
      y: 1024.9643009894987,
      control1X: 1951.0924037899463,
      control1Y: 1020.3249049429509,
      control2X: 1893.9982373044982,
      control2Y: 1039.1121773334262,
    },

    {
      x: 1417.2174633559966,
      y: 829.7000532492988,
    },
    {
      x: 1461.2499896697702,
      y: 759.1249925028535,
      control1X: 1374.9999909549954,
      control1Y: 799.124991906807,
      control2X: 1403.749990526587,
      control2Y: 779.1249922048303,
    },

    {
      x: 1814.9999843984847,
      y: 592.8749949801714,
    },
    {
      x: 1785.1428571428576,
      y: 544.5714285714286,
      control1X: 1848.7499838955703,
      control1Y: 567.8749953527005,
      control2X: 1833.7499841190877,
      control2Y: 552.8749955762179,
    },

    {
      x: 1181.977405810631,
      y: 280.8669622555466,
    },
  ],
}
