<template>
  <div class="lab-economics-header flex items-center">
    <p v-for="(col, i) in headerData" :key="i" class="text-28 text-texts-standard-default">
      {{ col.text ? $t(col.text) : '' }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface HeaderData {
  text: string
}
export default defineComponent({
  name: 'LabEconomicsHeader',
  props: {
    headerData: {
      type: Array as PropType<HeaderData[]>,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.lab-economics-header {
  position: absolute;
  // context background calculation for header items padding: with calc + padding
  width: calc(100% + 5rem);
  padding: 0 2.5rem;
  height: 4.875rem;
  top: -5.1rem;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(20, 74, 115, 0.8) 22%,
    rgba(20, 74, 115, 0.8) 82%,
    transparent 100%
  );

  & * {
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2.125rem;
    background: linear-gradient(to right, transparent 0, #173a5b, transparent 100%);
    filter: blur(0.625rem);
    z-index: 0;
  }

  & > p {
    width: 10.6rem;
    line-height: 2rem;
  }
}
</style>
