<template>
  <article
    v-for="i in 15"
    :key="i"
    class="circle flexing"
    :class="{ 'task-current': i === currentTask, 'task-done': i < currentTask }"
  >
    <p
      v-if="currentTask <= i"
      class="text-texts-standard-default text-60 font-bold relative"
      :class="{ 'gradient-text-light': i % 5 === 0 }"
    >
      <span
        v-if="i % 5 === 0"
        class="boss-text absolute top-0 left-1/2 transform -translate-x-1/2"
      />
      {{ i }}
    </p>
    <app-icon v-else icon-name="done-md" />
  </article>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TasksStages',
  props: {
    currentTask: {
      type: Number,
      default: 1,
    },
  },
})
</script>

<style lang="scss" scoped>
.circle {
  @include background(url('#{$path-events}shared/taskchain/tasks/tasks-circle.avif'), contain);
  width: 14.25rem;
  height: 14.25rem;
  margin: -2.125rem;

  &:nth-of-type(5n) p {
    text-shadow: none;
    filter: drop-shadow(1px, 1px, 1px, #000);
  }

  p {
    margin-left: 0.25rem;
  }

  .boss-text {
    top: -1.125rem;
    width: 4.938rem;
    height: 1.875rem;
    @include background(url('#{$path-events}shared/taskchain/tasks/tasks-text-boss.avif'), contain);
  }
}

.task-current {
  @include background(
    url('#{$path-events}shared/taskchain/tasks/tasks-circle-actual.avif'),
    contain
  );
}

.task-done {
  @include background(url('#{$path-events}shared/taskchain/tasks/tasks-circle-done.avif'), contain);
}
</style>
