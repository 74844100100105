<template>
  <section class="video-ad-web">
    <div class="window-overlay" />
    <div id="video-ad-web-content">
      <div id="video-ad-web-content-box">
        <video id="video-ad-web-default" ref="videoDefault">
          <source src="https://storage.googleapis.com/interactive-media-ads/media/android.mp4" />
          <source src="https://storage.googleapis.com/interactive-media-ads/media/android.webm" />
        </video>
      </div>
      <div id="video-ad-web-container" ref="videoContainer" class="absolute top-0" />
    </div>

    <fail-video-popup v-if="isAdBlock" @close-fail-video-popup="closeAdsBlock" />
  </section>
</template>

<script lang="ts">
import FailVideoPopup from '@/components/Popup/FailVideoPopup.vue'
import { musicTheme } from '@/helpers'
import { useAdStore } from '@/store/pinia/adStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'VideoAdWeb',
  components: {
    FailVideoPopup,
  },
  emits: ['hideVideo'],
  computed: {
    ...mapState(useAdStore, { isAdBlock: 'getIsAdBlock' }),
    ...mapState(usePremiumStore, {
      isWebVideo: 'getWebVideoPlaying',
    }),
    ...mapState(useUserStore, {
      isMusicEnabled: 'getMusicVolume',
    }),
  },
  watch: {
    isWebVideo(value: boolean): void {
      if (this.$isMobile()) return

      if (musicTheme && this.isMusicEnabled) {
        if (!value && !musicTheme.playing()) {
          musicTheme.play()
        } else if (value && musicTheme.playing()) {
          musicTheme.stop()
        }
      }
    },
  },
  methods: {
    ...mapActions(useAdStore, {
      setAdBlockState: 'setAdBlockState',
    }),
    ...mapActions(usePremiumStore, {
      setVideoPlay: 'setVideoPlay',
      setWebVideoPlay: 'setWebVideoPlay',
    }),
    closeAdsBlock(): void {
      this.setWebVideoPlay(false)
      this.setAdBlockState(false)
      this.$emit('hideVideo')
      this.setVideoPlay(false)
    },
  },
})
</script>

<style lang="scss" scoped>
.video-ad-web {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  #video-ad-web-content {
    z-index: 1;
    width: 122rem;
    height: 68.625rem;

    video {
      width: 100%;
      height: 100%;
    }
  }
}

.video-ads-popup-text {
  height: 13rem;
  width: 90%;
  margin: 0 auto;
}
</style>
