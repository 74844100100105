<template>
  <section class="hamburger-menu-footer-id flexing">
    <div class="hamburger-menu-footer-id-content map-button">
      <div ref="copy-to-clipboard-btn" class="copy-to-clipboard-btn map-btn">
        <div
          class="relative flex flex-row copy-to-clipboard-btn__content-wrapper text-24 text-texts-standard-additional revert-skew"
        >
          <div class="copy-to-clipboard-btn__label flexing">
            <b>{{ $t('map.your_id') }}</b>
            :
          </div>
          <div class="copy-to-clipboard-btn__value">
            <shrink-text
              :key="shrinkTextKey"
              :text="userData.id"
              :font-size="34"
              container-class-list="flexing"
              text-class-list="text-texts-standard-important"
              :animated-overflow="true"
            />
          </div>
        </div>
        <div class="map-btn-copy" @click.prevent="copyText" />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import { GameType } from '@/globalVariables/gameTypes'
import { tippy } from 'vue-tippy'
import ShrinkText from '@/components/ShrinkText.vue'

interface ComponentData {
  GameType: typeof GameType
  currentGame: string
  shrinkTextKey: number
}

export default defineComponent({
  name: 'UserIdBox',
  components: {
    ShrinkText,
  },
  data(): ComponentData {
    return {
      GameType,
      currentGame: this.$gameName,
      shrinkTextKey: 0,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userData: 'getUserData',
    }),
  },
  mounted(): void {
    setTimeout((): void => {
      this.shrinkTextKey++
    }, 100)
  },
  methods: {
    copyText(): void {
      if (!navigator.clipboard) return
      if (this.userData?.id) {
        const button = this.$refs['copy-to-clipboard-btn']
        if (button) {
          const tooltip = tippy(button, {
            theme: 'WSM',
            content: 'Coppied to clipboard', // TODO add translation
            placement: 'top',
            maxWidth: '30rem',
            trigger: 'manual',
            hideOnClick: false,
            onShow(instance) {
              setTimeout((): void => {
                instance.hide()
              }, 1500)
            },
          })
          navigator.clipboard.writeText(this.userData.id)
          // show the tooltip after copying the text
          tooltip.show()
        }
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/hamburger-menu.scss';

.map-button {
  width: 90%;
}

.copy-to-clipboard-btn {
  &__content-wrapper {
    flex: 1 1 0;
    overflow: hidden;
  }

  &__label {
    flex-shrink: 0;
  }

  &__value {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
  }
}

.map-btn {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 5rem;
  padding: 0 !important;
  padding-left: 1rem !important;
  box-shadow: 0.061rem 0.196rem 0.188rem 0 rgba(0, 0, 0, 0.32);

  @if $isWsm {
    background: linear-gradient(to bottom, #1b2f47, #14426c);
    border: 0.125rem solid rgb(82, 142, 191);
  }
  @if $isSsm {
    border: 0.125rem solid #586b9d;
    background: linear-gradient(to right, rgba(52, 65, 93, 0.85), rgba(39, 50, 73, 0.85));
  }
  @if $isWsm {
    &:before {
      content: '';
      position: absolute;
      left: 0.063rem;
      bottom: 0.063rem;
      width: 1.563rem;
      height: 1.563rem;
      background: transparent;
      border-bottom: 0.094rem solid #b5deff;
      border-left: 0.094rem solid #b5deff;
    }
  }
  @if $isWsm {
    &:after {
      content: '';
      position: absolute;
      right: 0.063rem;
      top: 0.063rem;
      width: 1.563rem;
      height: 1.563rem;
      background: transparent;
      border-top: 0.094rem solid #b5deff;
      border-right: 0.094rem solid #b5deff;
    }
  }

  .text {
    flex: 1 1 0;
    color: #d9ecff;
    line-height: 5rem;
    font-weight: bold;

    span {
      color: #fadd1e;
      font-weight: normal;
    }
  }

  &-copy {
    width: 5rem;
    background-color: #22222c;
    height: 100%;
    cursor: pointer;
    background-image: url($path-icons + 'icon-copy.avif');
    background-size: 2.5rem;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
