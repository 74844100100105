<template>
  <section class="gdpr-main w-full h-full flex flex-col justify-center">
    <div class="gdpr-main-background-image w-full h-full" />
    <section
      class="gdpr-main-content text-texts-standard-important flex flex-col justify-center safe-area"
    >
      <div class="gdpr-main-content-logo" />
      <p
        class="gdpr-main-content-title-text not-italic text-50"
        :class="{
          'text-white': $isWsm,
          'text-texts-standard-important': $isSsm,
        }"
      >
        {{ $t('common.gdprWebTitle') }}
      </p>
      <p class="gdpr-main-content-body-text italic text-34 text-white">
        {{ `${$t('common.gdprWebBody1')} ${$t('common.gdprWebBody2')}` }}
      </p>
      <p class="gdpr-main-content-footer text-34 text-white">
        {{ $t('common.gdprWebFooter') }}
      </p>
      <a :href="privacyUrl" target="_blank">
        <p class="gdpr-main-content-footer-href-text text-texts-standard-important text-34">
          {{ $t('common.gdprWebHref') }}
        </p>
      </a>
      <section class="gdpr-main-content-footer-buttons flex">
        <app-button
          btn-type="primary"
          :btn-text="$t('common.gdprWebDecline')"
          add-class="gdpr-main-content-footer-buttons-decide"
          btn-size="xl"
          class="m-auto"
          @click="gdprAction(ConsentDecision.PARTIALLY_ACCEPTED)"
        />
        <app-button
          btn-type="secondary"
          :btn-text="$t('common.gdprWebAccept')"
          add-class="gdpr-main-content-footer-buttons-decide"
          btn-size="xl"
          class="m-auto"
          @click="gdprAction(ConsentDecision.ACCEPTED)"
        />
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import { ConsentDecision, ConsentType } from '@/enums/Consent'
import { userConsentEndpoint } from '@/globalVariables'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  ConsentDecision: typeof ConsentDecision
  privacyUrl: string
}

export default defineComponent({
  name: 'GdprView',
  data(): ComponentData {
    return {
      ConsentDecision,
      privacyUrl: import.meta.env.VITE_PRIVACY_POLICY_URL,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      getGdprCurrentVersion: 'getGdprCurrentVersion',
    }),
  },
  created(): void {
    this.setGdprPageShowed(true)
  },
  methods: {
    ...mapActions(useUserStore, ['setGdprPageShowed']),
    gdprAction(decision: ConsentDecision): void {
      try {
        this.$axios.put(userConsentEndpoint, {
          consent: ConsentType.GDPR,
          consents_state: decision,
          version: this.getGdprCurrentVersion,
        })
        this.setGdprPageShowed(false)

        this.$router.replace({ name: 'LayoutView' })
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.gdpr-main {
  &-background-image {
    position: fixed;
    background: url($path-images + 'gdpr_web/gdpr_web_background.avif') center bottom no-repeat;
    background-size: cover;
    z-index: 1;

    @if $isWsm {
      filter: brightness(40%);
    }
  }

  &-content {
    position: absolute;
    z-index: 2;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 82.875rem;
    &-logo {
      background: url($path-images + 'loading/loading-screen-logo.webp') center no-repeat;
      background-size: contain;
      width: 24.063rem;
      height: 12.25rem;
      margin-bottom: 7.875rem;
      margin-top: 7.063rem;
    }
    &-title-text {
      margin-bottom: 3.125rem;
      font-weight: bold;
    }
    &-body-text {
      margin-bottom: 7.313rem;
    }
    &-footer {
      &-href-text {
        margin-bottom: 5.625rem;
        text-decoration: underline;
      }
      &-buttons {
        margin-bottom: 6.313rem;
        justify-content: space-between;
        gap: 2.25rem;
        &-decide {
          width: 41.375rem;
        }
      }
    }
  }
}
</style>
