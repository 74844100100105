<template>
  <div class="clubs-about-popup">
    <main>
      <div v-for="(text, index) in texts" :key="index" class="clubs-about-popup-texts">
        <h2 class="text-texts-standard-important text-36 uppercase">
          {{ $t(text.title) }}
        </h2>
        <p
          v-for="(desc, i) in text.description"
          :key="i"
          class="text-texts-standard-default text-30"
        >
          {{ $t(desc) }}
        </p>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

interface ClubAboutText {
  title: string
  description: string[]
}

export default defineComponent({
  name: 'ClubsAboutPopup',
  computed: {
    texts(): ClubAboutText[] {
      return [
        {
          title: 'club.aboutClubs',
          description: ['club.aboutClubDesc1', 'club.aboutClubDesc2'],
        },
        {
          title: 'club.clubCentrum',
          description: ['club.aboutClubDesc3', 'club.aboutClubDesc4', 'club.aboutClubDesc5'],
        },
        {
          title: 'club.clubChampionship',
          description: ['club.aboutClubDesc6'],
        },
      ]
    },
  },
})
</script>

<style lang="scss" scoped>
.clubs-about-popup {
  width: 102.75rem;

  main {
    margin: 3.438rem 3.438rem 8rem;
    text-align: left;

    h2 {
      border-bottom: 0.063rem solid #438eb8;
    }

    p {
      margin: 1.313rem;
    }
  }
}
</style>
