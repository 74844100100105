import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const clubCarsRightToParkingAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'clubCarsRightToParkingAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '12',
      delay: 0,
      duration: 1700,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 12,
        end: 10,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsRightToParkingAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '10',
      delay: 0,
      duration: 2500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 10,
        end: 8,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsRightToParkingAnimations3',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '08',
      delay: 0,
      duration: 2500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 8,
        end: 1,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsRightToParkingAnimations4',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '01',
      delay: 0,
      duration: 2500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 1,
        end: 5,
        zeroPad: 2,
      },
    },
  },
]
