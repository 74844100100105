<template>
  <div class="slider relative">
    <section class="slider-bg w-full" />
    <section
      v-if="showStops"
      class="slider-stops w-full absolute flex justify-between items-center"
    >
      <div v-for="i in max" :key="i" class="slider-stop" />
    </section>
    <app-button
      v-if="showButtons"
      class="slider-button button-minus flexing"
      :class="{ disabled: isMinReached }"
      @contextmenu="!$isMobile() && handleRightClick()"
      @mousedown="!isMinReached && onMouseDown(-step)"
      @mouseup="onMouseUp"
      @mouseleave="onMouseUp"
      @touchstart.prevent="!isMinReached && onMouseDown(-step)"
      @touchend.prevent="onMouseUp"
    >
      -
    </app-button>
    <slider
      v-model="sliderValue"
      class="slider-ui"
      :step="step"
      :min="min"
      :max="max"
      :lazy="lazy"
      :disabled="disabled"
      :tooltips="!!tooltips"
      :tooltip-position="tooltips"
      @update="$emit('setSliderValue', sliderValue)"
    />

    <app-button
      v-if="showButtons"
      class="slider-button button-plus flexing"
      :class="{ disabled: isMaxReached }"
      @contextmenu="!$isMobile() && handleRightClick()"
      @mousedown="!isMaxReached && onMouseDown(step)"
      @mouseup="onMouseUp"
      @mouseleave="onMouseUp"
      @touchstart.prevent="!isMaxReached && onMouseDown(step)"
      @touchend.prevent="onMouseUp"
    >
      +
    </app-button>
    <section
      v-if="labels.length"
      class="slider-labels relative flex justify-between items-center uppercase"
    >
      <div v-for="(label, index) in labels" :key="index" class="slider-label">
        {{ label }}
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import Slider from '@vueform/slider'
import type { PropType } from 'vue'

interface ComponentData {
  sliderValue: number
  isButtonHold: boolean
  interval: ReturnType<typeof setInterval> | null
  timeout: ReturnType<typeof setTimeout> | null
}

export default {
  name: 'AppSlider',
  components: {
    Slider,
  },
  props: {
    defaultValue: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 5,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltips: {
      type: String,
      default: '',
    },
    labels: {
      type: Array as PropType<string[]>,
      default: (): string[] => [],
    },
    showStops: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: false,
    },
    forceReset: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['setSliderValue', 'reset'],
  data(): ComponentData {
    return {
      sliderValue: 0,
      isButtonHold: false,
      interval: null,
      timeout: null,
    }
  },
  computed: {
    isMinReached(): boolean {
      return this.sliderValue <= this.min
    },
    isMaxReached(): boolean {
      return this.sliderValue >= this.max
    },
  },
  updated(): void {
    if (this.forceReset) {
      this.$emit('setSliderValue', this.defaultValue)
      this.sliderValue = this.defaultValue
    }
  },
  created(): void {
    if (this.defaultValue < this.max && this.defaultValue > this.min) {
      this.sliderValue = this.defaultValue
      this.$emit('setSliderValue', this.defaultValue)
    } else {
      this.sliderValue = this.min
      this.$emit('setSliderValue', this.min)
    }
  },
  methods: {
    changeSliderValue(diff: number): void {
      const newVal: number = this.sliderValue + diff
      if (newVal >= this.min && newVal <= this.max) {
        this.sliderValue = newVal
        this.$emit('setSliderValue', newVal)
      }
    },
    // checking for two types of events - click and hold
    // if isButtonHold is still true after 500 ms, start +step or -step in 50ms interval
    onMouseDown(value: number): void {
      this.isButtonHold = true
      this.changeSliderValue(value)

      this.timeout = setTimeout((): void => {
        if (this.isButtonHold) {
          this.interval = setInterval((): void => {
            if (this.isMinReached || this.isMaxReached) {
              this.onMouseUp()
            } else {
              this.changeSliderValue(value)
            }
          }, 50)
        }
      }, 500)
    },
    onMouseUp(): void {
      this.isButtonHold = false
      clearInterval(this.interval)
      clearTimeout(this.timeout)
    },
    handleRightClick(): void {
      this.onMouseUp()
    },
  },
}
</script>

<style src="@vueform/slider/themes/default.css" />
<style lang="scss">
@import '@/assets/styles/variables.scss';

.slider {
  width: 50rem;
  height: 3rem;

  --slider-bg: #28275d;
  --slider-connect-bg: linear-gradient(
    0deg,
    rgb(255, 255, 70) 0%,
    rgb(238, 169, 51) 49%,
    rgb(255, 255, 70) 100%
  );
  --slider-handle-bg: #d7edfa;
  --slider-handle-width: 3rem;
  --slider-handle-height: 3rem;
  --slider-handle-ring-color: transparent;
  --slider-height: 1.5rem;
  --slider-radius: 18px;

  .slider-touch-area {
    background: url($path-icons + 'slider-btn.avif') center no-repeat;
    background-size: cover;
  }

  &-button {
    width: 4.25rem !important;
    height: 3.313rem !important;
    font-size: 3.75rem !important;
    position: absolute;
    top: -1rem;
    left: -6.5rem;
    transform: $skew-value;
    background: #c6e9fe;
    background: linear-gradient(to-bottom, #c6e9ff 0%, #9ab8cb 100%);
    color: #233441;
    font-weight: bold;
    cursor: pointer;
    user-select: none;

    &.button-plus {
      left: unset;
      right: -6.5rem;
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  &-tooltip {
    background: none;
    border: none;
    transform: translate(-35%) $skew-value !important;
    font-size: 2.5rem;
    color: theme('colors.texts.standard.important');

    &::before {
      display: none;
    }

    &.slider-tooltip-bottom {
      top: 4rem;
    }
  }
}
</style>
