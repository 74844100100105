import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const clubCarsMiddleToLeftAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'clubCarsMiddleToLeftAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '09',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 9,
        end: 8,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsMiddleToLeftAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '08',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 8,
        end: 6,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsMiddleToLeftAnimations3',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '06',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 6,
        end: 4,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsMiddleToLeftAnimations4',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '04',
      delay: 0,
      duration: 1300,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GeneratedFrameNames,
      data: [
        { key: 'animations', frame: '04' },
        { key: 'animations', frame: '03' },
        { key: 'animations', frame: '02' },
        { key: 'animations', frame: '01' },
        { key: 'animations', frame: '24' },
        { key: 'animations', frame: '23' },
      ],
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsMiddleToLeftAnimations5',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '23',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GeneratedFrameNames,
      data: [
        { key: 'animations', frame: '23' },
        { key: 'animations', frame: '01' },
      ],
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsMiddleToLeftAnimations6',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '01',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 1,
        end: 2,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsMiddleToLeftAnimations7',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '02',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 2,
        end: 3,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsMiddleToLeftAnimations8',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '03',
      delay: 0,
      duration: 1800,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GeneratedFrameNames,
      data: [
        { key: 'animations', frame: '03' },
        { key: 'animations', frame: '02' },
        { key: 'animations', frame: '01' },
        { key: 'animations', frame: '24' },
        { key: 'animations', frame: '23' },
        { key: 'animations', frame: '22' },
        { key: 'animations', frame: '21' },
        { key: 'animations', frame: '20' },
      ],
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsMiddleToLeftAnimations9',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '20',
      delay: 0,
      duration: 1800,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GeneratedFrameNames,
      data: [
        { key: 'animations', frame: '20' },
        { key: 'animations', frame: '21' },
        { key: 'animations', frame: '22' },
        { key: 'animations', frame: '23' },
        { key: 'animations', frame: '24' },
        { key: 'animations', frame: '01' },
      ],
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsMiddleToLeftAnimations10',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '01',
      delay: 0,
      duration: 2200,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GeneratedFrameNames,
      data: [
        { key: 'animations', frame: '01' },
        { key: 'animations', frame: '24' },
        { key: 'animations', frame: '23' },
        { key: 'animations', frame: '22' },
        { key: 'animations', frame: '21' },
        { key: 'animations', frame: '20' },
      ],
    },
  },
]
