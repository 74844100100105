import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const flyingHelicopterCheckpoints: CheckpointsObjectInterface = {
  key: 'flyingHelicopter',
  data: [
    {
      x: 2237,
      y: 863,
      width: 3,
      height: 3,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'flying_helicopter_turn_left',
        },
      },
    },
  ],
}
