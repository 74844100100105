import { defineStore } from 'pinia'
import type { Mode } from '@/interfaces/Mode'
import { ApiService } from '@/services/ApiService'

interface ModeStoreState {
  mode: Mode
}

export const useModeStore = defineStore('modeStore', {
  state: (): ModeStoreState => ({
    mode: {} as Mode,
  }),
  getters: {
    getMode(): Mode {
      return this.mode
    },
  },
  actions: {
    async loadMode(): Promise<void> {
      this.mode = await ApiService.get<Mode>('/minigame/modes')
    },
  },
})
