import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const boatRightToBottomAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'boatRightToBottomAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '31',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 31,
        end: 32,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatRightToBottomAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '32',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 32,
        end: 33,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatRightToBottomAnimations3',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '33',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 33,
        end: 34,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatRightToBottomAnimations4',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '34',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 34,
        end: 33,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatRightToBottomAnimations5',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '33',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 33,
        end: 35,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatRightToBottomAnimations6',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '35',
      delay: 200,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 35,
        end: 34,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatRightToBottomAnimations7',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '34',
      delay: 200,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 34,
        end: 32,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatRightToBottomAnimations8',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '32',
      delay: 200,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 32,
        end: 31,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatRightToBottomAnimations9',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '31',
      delay: 200,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 31,
        end: 29,
        zeroPad: 2,
      },
    },
  },
]
