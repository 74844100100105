import {
  EQUIPMENT_DELIVERY_TIME,
  EQUIPMENT_PRICE_CASH,
  EQUIPMENT_PRICE_RP,
  MINIMAL_PREMIUM_EQUIPMENT_LEVEL,
  ONE_HOUR_IN_SECONDS,
  shopAvailablePremiumItemEndPoint,
  shopItemEndPoint,
  shopItemEquipEndPoint,
  shopItemRepairEndPoint,
  shopItemUpgradeEndPoint,
  shopItemsEquipmentDisciplineEndPoint,
  shopItemsPremiumEquipmentDisciplineEndPoint,
  shopOfferEndPoint,
  shopOffersEndpoint,
} from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { useUserStore } from '@/store/pinia/userStore'
import { defineStore } from 'pinia'
import type { Nullable } from '@/interfaces/utils'
import type EquipmentOfferApiResponse from '@/interfaces/responses/equipment/EquipmentOfferApiResponse'
import type EquipmentShopItemApiResponse from '@/interfaces/responses/equipment/EquipmentShopItemApiResponse'
import type EquipmentItemApiResponse from '@/interfaces/responses/equipment/EquipmentItemApiResponse'
import type EquipmentEquipItemApiResponse from '@/interfaces/responses/equipment/EquipmentEquipItemApiResponse'
import type EquipmentUpgradeItemApiResponse from '@/interfaces/responses/equipment/EquipmentUpgradeItemApiResponse'
import type EquipmentRepairItemApiResponse from '@/interfaces/responses/equipment/EquipmentRepairItemApiResponse'
import type EquipmentShopOffersApiResponse from '@/interfaces/responses/equipment/EquipmentShopOffersApiResponse'
import type EquipmentPremiumOffersApiResponse from '@/interfaces/responses/equipment/EquipmentPremiumOffersApiResponse'
import type EquipmentPremiumItemsApiResponse from '@/interfaces/responses/equipment/EquipmentPremiumItemsApiResponse'

export interface EquipmentOfferData {
  disciplineId: EquipmentOfferApiResponse['discipline']
  operation: Nullable<{ itemId: -1 }>
  price: EquipmentOfferApiResponse['items'][0]['levels'][0]['parameters'][0]
  delivery: EquipmentOfferApiResponse['items'][0]['levels'][0]['parameters'][0]
  items: (Pick<EquipmentOfferApiResponse['items'][0], 'id' | 'levels' | 'name' | 'properties'> & {
    hookedItems: Nullable<EquipmentOfferApiResponse['items'][0]['hooked_items']>
    equipmentLevelsAvailable: Nullable<
      EquipmentOfferApiResponse['items'][0]['equipment_levels_available']
    >
  })[]
}

interface EquipmentItemDataByDisciplineIdBonusData {
  discipline_id: EquipmentShopItemApiResponse['games_discipline']['id']
  item_category: Nullable<
    EquipmentShopItemApiResponse['equipment_bonus_data']['attributes']['gdd_id']
  >
  level: EquipmentShopItemApiResponse['equipment_bonus_data']['attributes']['level']
  icon: string
  name: EquipmentShopItemApiResponse['equipment_bonus_data']['attributes']['item_name']
  attributes: {
    basic_equipment: {
      value: number
    }
    premium_bonus: {
      value: number
    }
    total: {
      value: number
    }
  }
}

export interface EquipmentItemDataByDisciplineId {
  id: EquipmentShopItemApiResponse['id']
  name: EquipmentShopItemApiResponse['name']
  equipmentLevelsAvailable: EquipmentShopItemApiResponse['equipment_levels_available']
  shopType: EquipmentShopItemApiResponse['shop_type']
  itemState: EquipmentShopItemApiResponse['item_state']
  itemStats: EquipmentShopItemApiResponse['item_stats']
  disciplineId: EquipmentShopItemApiResponse['games_discipline']['id']
  levels: EquipmentShopItemApiResponse['levels']
  hookedItems: EquipmentShopItemApiResponse['hooked_items']
  properties: EquipmentShopItemApiResponse['properties']
  starsUnlocks: EquipmentShopItemApiResponse['stars_unlocks']
  operation: EquipmentShopItemApiResponse['operation']
  premium: Nullable<{
    locked: boolean
    time: number
  }>
  isEventEquip: boolean
  equipmentBonusData: Nullable<EquipmentShopItemApiResponse['equipment_bonus_data']>
  bonusData: Nullable<EquipmentItemDataByDisciplineIdBonusData[]>
  prolong: {
    price: number
    effectDuration: number
    availableTo: number
    canProlong: boolean
  }
}

export interface EquipmentItemData {
  id: EquipmentItemApiResponse[0]['id']
  name: EquipmentItemApiResponse[0]['name']
  equipmentLevelsAvailable: EquipmentItemApiResponse[0]['equipment_levels_available']
  itemState: EquipmentItemApiResponse[0]['item_state']
  itemStats: EquipmentItemApiResponse[0]['item_stats']
  // itemCategory: EquipmentItemApiResponse[0]['item_category']
  hookedItems: EquipmentItemApiResponse[0]['hooked_items']
  levelParameters: EquipmentItemApiResponse[0]['level_parameters']
  properties: EquipmentItemApiResponse[0]['properties']
  starsUnlocks: EquipmentItemApiResponse[0]['stars_unlocks']
  // equipmentBonusData: EquipmentItemApiResponse[0]['equipment_bonus_data']
  operation: Nullable<EquipmentItemApiResponse[0]['operation']>
}

export interface EquipmentPremiumItemData {
  id: EquipmentPremiumItemsApiResponse[0]['id']
  name: EquipmentPremiumItemsApiResponse[0]['name']
  equipmentLevelsAvailable: EquipmentPremiumItemsApiResponse[0]['equipment_levels_available']
  itemState: EquipmentPremiumItemsApiResponse[0]['item_state']
  itemStats: EquipmentPremiumItemsApiResponse[0]['item_stats']
  // itemCategory: EquipmentPremiumItemsApiResponse[0]['item_category']
  // disciplineId: EquipmentPremiumItemsApiResponse[0]['discipline']
  hookedItems: EquipmentPremiumItemsApiResponse[0]['hooked_items']
  levelParameters: EquipmentPremiumItemsApiResponse[0]['level_parameters']
  // levelParameters: EquipmentPremiumItemsApiResponse[0]['level_parameters']
  properties: EquipmentPremiumItemsApiResponse[0]['properties']
  starsUnlocks: EquipmentPremiumItemsApiResponse[0]['stars_unlocks']
  // equipmentBonusData: EquipmentPremiumItemsApiResponse[0]['equipment_bonus_data']
  operation: Nullable<EquipmentPremiumItemsApiResponse[0]['operation']>
  premium: {
    locked: boolean
    time: number
  }
  expiredTime: number
  expired: boolean
  equipmentBonusData: Nullable<EquipmentPremiumItemsApiResponse[0]['equipment_bonus_data']>
}

export interface EquipmentShopOffersData {
  availableOffers: {
    id: EquipmentShopOffersApiResponse['availableOffers'][0]['id']
    name: EquipmentShopOffersApiResponse['availableOffers'][0]['name']
    unlocked: EquipmentShopOffersApiResponse['availableOffers'][0]['unlocked']
    disciplineId: EquipmentShopOffersApiResponse['availableOffers'][0]['discipline']
    valid: EquipmentShopOffersApiResponse['availableOffers'][0]['valid']
    gamesId: EquipmentShopOffersApiResponse['availableOffers'][0]['games_id']
    shopTypesId: EquipmentShopOffersApiResponse['availableOffers'][0]['shop_types_id']
    // repeatAfterMinutes: EquipmentShopOffersApiResponse['availableOffers'][0]['repeat_after_minutes']
    items: EquipmentShopOffersApiResponse['availableOffers'][0]['items']
    parameters: EquipmentShopOffersApiResponse['availableOffers'][0]['parameters']
    questRequired: EquipmentShopOffersApiResponse['availableOffers'][0]['career_quest_required']
  }[]
  operation: EquipmentShopOffersApiResponse['operation']
}

interface EquipmentStoreState {
  equipmentOffer: Nullable<EquipmentOfferData>
  shopItem: Nullable<EquipmentItemDataByDisciplineId>
  equipmentItem: EquipmentItemData[]
  premiumEquipmentItem: EquipmentPremiumItemData[]
  shopOffers: Nullable<EquipmentShopOffersData>
}

export const useEquipmentStore = defineStore('equipmentStore', {
  state: (): EquipmentStoreState => ({
    equipmentOffer: null,
    shopItem: null,
    equipmentItem: [],
    premiumEquipmentItem: [],
    shopOffers: null,
  }),
  getters: {
    getSpecificEquipmentOffer(): EquipmentStoreState['equipmentOffer'] {
      return this.equipmentOffer
    },
    getShopItem(): EquipmentStoreState['shopItem'] {
      return this.shopItem
    },
    getSpecificEquipmentItem(): EquipmentStoreState['equipmentItem'] {
      return this.equipmentItem
    },
    getSpecificPremiumEquipmentItem(): EquipmentStoreState['premiumEquipmentItem'] {
      return this.premiumEquipmentItem
    },
    getShopOffers(): EquipmentStoreState['shopOffers'] {
      return this.shopOffers
    },
    getTaskUnlockInfo(): EquipmentStoreState['shopItem']['starsUnlocks'] {
      return this.shopItem.starsUnlocks
    },
  },
  actions: {
    async loadSpecificEquipmentOffer(equipmentId: number): Promise<void> {
      try {
        const response = await internalAxios.get<{}, EquipmentOfferApiResponse>(
          shopOfferEndPoint + equipmentId,
        )

        type DataItem = EquipmentOfferData['items'][0]
        type Item = EquipmentOfferApiResponse['items'][0]
        type Parameter = Item['levels'][0]['parameters'][0]

        const data: EquipmentOfferData = {
          disciplineId: response.discipline,
          operation: response.operation ? { itemId: -1 } : null,
          price: response.items[0].levels[0].parameters.find((param: Parameter): boolean =>
            [EQUIPMENT_PRICE_CASH, EQUIPMENT_PRICE_RP].includes(param.name),
          ),
          delivery: response.items[0].levels[0].parameters.find(
            (param: Parameter): boolean => param.name === EQUIPMENT_DELIVERY_TIME,
          ),
          items: response.items.map((item: Item): DataItem => {
            const { id, levels, name, properties } = item

            return {
              id,
              levels,
              name,
              properties,
              hookedItems: item.hooked_items ?? null,
              equipmentLevelsAvailable: item.equipment_levels_available ?? null,
            }
          }),
        }

        const parameters = response.parameters
        data.price.value = parameters[EQUIPMENT_PRICE_CASH] ?? parameters[EQUIPMENT_PRICE_RP]
        data.delivery.value =
          parameters[EQUIPMENT_DELIVERY_TIME] ?? parameters[EQUIPMENT_DELIVERY_TIME]

        this.equipmentOffer = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadShopItem(equipmentId: number): Promise<void> {
      try {
        const response = await internalAxios.get<{}, EquipmentShopItemApiResponse>(
          shopItemEndPoint + equipmentId,
        )

        type Property = EquipmentShopItemApiResponse['properties'][0]
        type Statistic = EquipmentShopItemApiResponse['item_stats'][0]
        type HookedItem = EquipmentShopItemApiResponse['hooked_items'][0]
        type Parameter = EquipmentShopItemApiResponse['levels'][0]['parameters'][0]
        type EquipmentBonusDataKey = keyof EquipmentShopItemApiResponse['equipment_bonus_data']

        const isPremium = response.properties.find(
          (prop: Property): boolean => prop.name === 'premium_equipment_item',
        )

        let date = 0
        let timeLocked = 0

        const isAvailableLocked = !response.item_state

        if (isPremium) {
          const time = Number(
            response.item_stats?.find((stat: Statistic): boolean => stat.name === 'active_until')
              ?.value,
          )
          timeLocked = Number(
            response.properties.find((prop: Property): boolean => prop.name === 'effect_duration')
              ?.value,
          )
          date = Math.round((time * 1e3 - Date.now()) / 1e3)
        }

        const data: EquipmentItemDataByDisciplineId = {
          id: response.id,
          disciplineId: response.games_discipline.id,
          equipmentLevelsAvailable: response.equipment_levels_available,
          itemState: response.item_state,
          itemStats: response.item_stats,
          levels: response.levels,
          name: response.name,
          properties: response.properties,
          shopType: response.shop_type,
          hookedItems: response.hooked_items ?? null,
          operation: response.operation ?? null,
          starsUnlocks: response.stars_unlocks,
          premium: isPremium
            ? {
                locked: isAvailableLocked,
                time: isAvailableLocked ? timeLocked : date,
              }
            : null,
          isEventEquip:
            !!response.properties.find(
              (prop: Property): boolean => prop.name === 'premium_equipment_item',
            )?.value || false,
          equipmentBonusData: response.equipment_bonus_data ?? null,
          bonusData: response.equipment_bonus_data
            ? Object.keys(response.equipment_bonus_data).map(
                (bonus: EquipmentBonusDataKey): EquipmentItemDataByDisciplineIdBonusData => {
                  let icon = 'discipline'
                  let bonusAttr = 0
                  const cashHook = response.hooked_items.find(
                    (hook: HookedItem): boolean => hook.hook_slot.name === 'standard_cash',
                  )
                  const tpHook = response.hooked_items.find(
                    (hook: HookedItem): boolean => hook.hook_slot.name === 'standard_tp',
                  )
                  const basicAttr =
                    Number(response.equipment_bonus_data[bonus]?.attribute_bonus) ?? 0
                  switch (bonus) {
                    case 'attributes':
                      icon = 'discipline'
                      bonusAttr =
                        Number(
                          response.properties.find(
                            (prop: Property): boolean => prop.name === 'item_attribute_bonus',
                          )?.value,
                        ) ?? 0
                      break
                    case 'bonus_tp':
                      icon = 'universal_training_points'
                      bonusAttr =
                        Number(
                          tpHook?.levels[0]?.parameters.find(
                            (param: Parameter): boolean => param.name === 'upgrade_bonus',
                          )?.value,
                        ) ?? 0

                      break
                    case 'bonus_cash':
                      icon = 'money'
                      bonusAttr =
                        Number(
                          cashHook?.levels[0]?.parameters.find(
                            (param: Parameter): boolean => param.name === 'upgrade_bonus',
                          )?.value,
                        ) ?? 0
                  }
                  return {
                    discipline_id: response.games_discipline.id,
                    item_category: response.equipment_bonus_data[bonus]?.gdd_id ?? null,
                    level: response.equipment_bonus_data[bonus]?.level ?? '1',
                    icon: icon,
                    name: response.equipment_bonus_data[bonus]?.item_name,
                    attributes: {
                      basic_equipment: {
                        value: basicAttr,
                      },
                      premium_bonus: {
                        value: bonusAttr,
                      },
                      total: {
                        value: basicAttr + bonusAttr,
                      },
                    },
                  }
                },
              )
            : null,
          prolong: {
            price:
              Number(
                response.properties.find((prop: Property): boolean => prop.name === 'prolong_price')
                  ?.value,
              ) ?? 0,
            effectDuration: Math.round(
              Number(
                response.properties.find(
                  (prop: Property): boolean => prop.name === 'effect_duration',
                )?.value ?? 0,
              ) / ONE_HOUR_IN_SECONDS,
            ),
            availableTo:
              Number(
                response.properties.find(
                  (prop: Property): boolean => prop.name === 'time_to_prolong',
                )?.value ?? 0,
              ) / ONE_HOUR_IN_SECONDS,
            canProlong:
              response.item_stats?.find((prop: Property): boolean => prop.name === 'can_prolong')
                ?.value === 1 || false,
          },
        }

        this.shopItem = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadEquipmentByDisciplineId(disciplineId: number): Promise<void> {
      try {
        const response = await internalAxios.get<{}, EquipmentItemApiResponse>(
          `${shopItemsEquipmentDisciplineEndPoint}/${disciplineId}/detail`,
        )

        type Item = EquipmentItemApiResponse[0]

        const data = response.map((item: Item): EquipmentItemData => {
          return {
            id: item.id,
            equipmentLevelsAvailable: item.equipment_levels_available,
            itemState: item.item_state,
            itemStats: item.item_stats,
            levelParameters: item.level_parameters,
            name: item.name,
            properties: item.properties,
            operation: item.operation ?? null,
            starsUnlocks: item.stars_unlocks,
            hookedItems: item.hooked_items,
          }
        })

        this.equipmentItem = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadPremiumEquipmentByDisciplineId(disciplineId: number): Promise<void> {
      const userStore = useUserStore()
      if (Number(userStore.getLevel) < MINIMAL_PREMIUM_EQUIPMENT_LEVEL) return

      type Response =
        | EquipmentPremiumItemsApiResponse
        | [EquipmentPremiumOffersApiResponse['availableOffers']]

      let response: Response = []
      let premiumData: Nullable<EquipmentPremiumOffersApiResponse> = null

      try {
        premiumData = await internalAxios.get<{}, EquipmentPremiumOffersApiResponse>(
          `${shopAvailablePremiumItemEndPoint}${disciplineId}`,
        )
      } catch (error: unknown) {
        console.error(error)
      }

      if (!premiumData) return
      const isAvailableLocked = premiumData.offerBought === false

      if (
        (premiumData.offerBought === false || premiumData.offerBought === 0) &&
        premiumData.availableOffers
      ) {
        response = [premiumData.availableOffers] || []
      } else {
        try {
          response = await internalAxios.get<{}, EquipmentPremiumItemsApiResponse>(
            `${shopItemsPremiumEquipmentDisciplineEndPoint}/${disciplineId}/detail`,
          )
        } catch (error: unknown) {
          console.error(error)
        }
      }

      type Item = Response[0]
      type Statistic = EquipmentPremiumItemsApiResponse[0]['item_stats'][0]
      type Property = EquipmentPremiumItemsApiResponse[0]['properties'][0]

      const data: EquipmentPremiumItemData[] = response.map(
        (item: Item): EquipmentPremiumItemData => {
          const time = Number(
            item.item_stats?.find((stat: Statistic): boolean => stat.name === 'active_until')
              ?.value ?? 0,
          )
          const timeLocked = Number(
            item.properties.find((prop: Property): boolean => prop.name === 'effect_duration')
              ?.value ?? 0,
          )
          const date = Math.round((time * 1e3 - Date.now()) / 1e3)
          const expiredTime = Number(
            item.item_stats?.find((stat: Statistic): boolean => stat.name === 'can_prolong_until')
              ?.value ?? 0,
          )
          const expiredDate = Math.round((expiredTime * 1e3 - Date.now()) / 1e3)

          return {
            id: item.id,
            equipmentLevelsAvailable: item.equipment_levels_available,
            itemState: item.item_state,
            itemStats: item.item_stats,
            levelParameters: item.level_parameters,
            name: item.name,
            properties: item.properties,
            operation: item.operation ?? null,
            starsUnlocks: item.stars_unlocks,
            hookedItems: item.hooked_items,
            premium: {
              locked: time ? false : true,
              time: isAvailableLocked ? (time ? date : timeLocked) : date,
            },
            expiredTime: expiredDate > 0 ? expiredDate : 0,
            expired: item.item_state?.name === 'inactive',
            equipmentBonusData: item.equipment_bonus_data ?? null,
          }
        },
      )

      this.premiumEquipmentItem = data
    },
    async equipItem(equipmentId: number): Promise<void> {
      try {
        await internalAxios.put<{}, EquipmentEquipItemApiResponse, { item_id: number }>(
          shopItemEquipEndPoint,
          { item_id: equipmentId },
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async upgradeItem(data: { itemId: number; addonId?: number }): Promise<void> {
      try {
        await internalAxios.put<
          {},
          EquipmentUpgradeItemApiResponse,
          { item_id: number; upgrade_id: Nullable<number> }
        >(shopItemUpgradeEndPoint, {
          item_id: data.itemId,
          upgrade_id: data.addonId || null,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async buyOffer(offerId: number): Promise<void> {
      try {
        await internalAxios.post<{}, boolean, { offer_id: number }>(shopOfferEndPoint, {
          offer_id: offerId,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async repairItem(equipmentId: number): Promise<void> {
      try {
        await internalAxios.put<{}, EquipmentRepairItemApiResponse, { item_id: number }>(
          shopItemRepairEndPoint,
          {
            item_id: equipmentId,
          },
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadShopOffers(disciplineId: number | string): Promise<void> {
      try {
        const response = await internalAxios.get<{}, EquipmentShopOffersApiResponse>(
          `${shopOffersEndpoint}/${disciplineId}`,
        )

        type AvailableOffer = EquipmentShopOffersApiResponse['availableOffers'][0]
        type DataAvailableOffer = EquipmentShopOffersData['availableOffers'][0]

        const data: EquipmentShopOffersData = {
          operation: response.operation ?? null,
          availableOffers: response.availableOffers.map(
            (offer: AvailableOffer): DataAvailableOffer => {
              return {
                id: offer.id,
                gamesId: offer.games_id,
                disciplineId: offer.discipline,
                items: offer.items,
                name: offer.name,
                parameters: offer.parameters,
                shopTypesId: offer.shop_types_id,
                unlocked: offer.unlocked ?? false,
                valid: offer.valid,
                questRequired: offer.career_quest_required,
              }
            },
          ),
        }

        this.shopOffers = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
