import { allTranslationsEndpoint } from '@/globalVariables'
import { detectLanguage } from '@/helpers'
import { internalAxios } from '@/plugins/vueAxios'
import { useTextsStore } from '@/store/pinia/textStore'
import { useUserStore } from '@/store/pinia/userStore'
import { translate } from '@/plugins/translate'
import type { AllTranslationsApiResponse } from '@/interfaces/responses/language/AllTranslationsApiResponse'
// TODO: Presunut do helpers ale teraz som to uz tu nechal

export const translateLang = (): string => {
  const language = detectLanguage()
  const userStore = useUserStore()
  return userStore.getLanguage ?? language
}

export const loadTextsFromSessionStorage = async (): Promise<Record<string, string>> => {
  const textStore = useTextsStore()
  const languageFromSS = sessionStorage.getItem('language')
  if (translateLang() !== languageFromSS) {
    textStore.setLoadedTexts(false)
    sessionStorage.removeItem('texts')
  }
  const allTexts = sessionStorage.getItem('texts')

  if (!allTexts) {
    try {
      const textsObject = await internalAxios.get<{}, AllTranslationsApiResponse>(
        allTranslationsEndpoint + translateLang(),
      )
      sessionStorage.setItem('language', translateLang())
      sessionStorage.setItem('texts', JSON.stringify(textsObject))
      translate('map.attributes', true)
      textStore.setLoadedTexts(true)
    } catch (error: unknown) {
      console.error(error)
    }
  } else {
    textStore.setLoadedTexts(true)
  }
  return JSON.parse(sessionStorage.getItem('texts') || '{}')
}
