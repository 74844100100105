import { useCountriesStore } from '@/store/pinia/countriesStore'
import type { LanguageApiResponse } from '@/interfaces/responses/language/LanguageApiResponse'

export const detectLanguage = (): string => {
  const countryStore = useCountriesStore()
  const languages: LanguageApiResponse[] = countryStore.getLanguages
  const navigatorLanguage: string = window.navigator.language

  // for cases like en-GB, es-AR, take just first two chars
  const browserLanguage: string = navigatorLanguage.substring(0, 2)

  const isLanguageInList: boolean = languages.some(
    (item: LanguageApiResponse): boolean => item.language === browserLanguage,
  )
  const language: string =
    localStorage.getItem('language') ?? (isLanguageInList ? browserLanguage : 'en')

  return language
}
