<template>
  <div
    v-if="level || Object.keys(userStats).length"
    v-tippy="
      !level && {
        theme: 'WSM',
        allowHTML: true,
        content: `
      ${$t('map.level')} : ${userStats?.LEVEL ?? '-'} <br>
          ${$t('map.experience')} : ${userStats?.EXPERIENCE ?? '-'} <br>
          ${$t('map.next_level')} :
          ${!hasMaxLevel && userStats?.TARGET_EXPERIENCE ? userStats?.TARGET_EXPERIENCE : '-'}
      `,
        placement: 'bottom',
      }
    "
    class="player-level"
    :class="{ 'flexing flex-col space-y-0.5': $isSsm }"
    :style="{ width: widthProp + 'rem', height: heightProp + 'rem' }"
  >
    <div
      class="text-up flex items-center justify-center uppercase"
      :class="{
        'font-medium tracking-tight -mt-1.5': $isSsm,
      }"
    >
      {{ $isWsm ? 'LVL' : 'LEVEL' }}
    </div>
    <div class="lvl-number flex items-center justify-center">
      {{ setLevel }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { useUserStore } from '@/store/pinia/userStore'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { Stats } from '@/interfaces/User'

export default defineComponent({
  name: 'PlayerLevel',
  props: {
    widthProp: {
      type: Number,
      default: 4.4375,
    },
    heightProp: {
      type: Number,
      default: 4.4375,
    },
    otherUserStats: {
      type: Object as PropType<Nullable<Stats>>,
      default: () => null,
    },
    level: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState(useUserStore, {
      userData: 'getUserData',
      hasMaxLevel: 'hasMaxLevel',
    }),
    userStats(): Stats {
      return this.otherUserStats && Object.keys(this.otherUserStats).length
        ? this.otherUserStats
        : this.userData?.stats
    },
    setLevel(): string {
      if (this.level) return this.level.toString()
      else if (this.userStats) return this.userStats.LEVEL.toString()
      else return '-'
    },
  },
})
</script>

<style lang="scss" scoped>
.player-level {
  @if $isWsm {
    color: #233441;
    background-image: linear-gradient(to bottom, #ffe962, #ffe865);
    box-shadow:
      -0.309px 0.951px 7px 0px rgba(0, 0, 0, 0.7),
      inset 0px 5px 4px 0px rgba(255, 255, 255, 0.3),
      inset 0px 0px 0px 0px rgba(255, 255, 255, 0.64);
    transform: $skewX-value;
  }

  @if $isSsm {
    color: #333435;
    background-image: url('#{$path-images}/profile/profile-level-background.avif');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .text-up {
    height: 2.1875rem;
    transform: $skewX-revert-value;

    @if $isWsm {
      font-size: 1.5rem;
      font-style: italic;
    }

    @if $isSsm {
      font-size: 1.25rem;
    }
  }

  .lvl-number {
    font-size: 2.5rem;
    font-weight: 900;
    height: 2rem;
    width: 4rem;

    @if $isWsm {
      transform: skewX(8deg);
      font-style: italic;
      letter-spacing: -0.3125rem;
    }
  }
}
</style>
