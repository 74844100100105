<template>
  <section class="app-page-wrapper flex flex-col absolute">
    <premium-menu />
    <main class="flex w-full h-full flexing safe-area">
      <events-side-menu class="h-full" />
      <div
        v-if="eventBigOffers?.length || eventSmallOffers?.length"
        class="offer-wrapper h-full flexing relative"
      >
        <app-scrollbar
          class="offer-wrapper-scroll flexing flex-grow m-auto"
          width="98%"
          height="46.625rem"
          scroll="x"
          slide="x"
          :arrows="true"
          arrow-pos="top-1/2"
        >
          <offers-box v-for="(offer, i) in eventBigOffers" :key="i" :offer-data="offer" />
          <offers-smaller-box v-for="(offer, i) in eventSmallOffers" :key="i" :offer-data="offer" />
        </app-scrollbar>
      </div>
      <component-loading v-else :is-loading="true" type="secondary" width="100%" height="100%" />
    </main>
  </section>
</template>

<script lang="ts">
import PremiumMenu from '@/components/Premium/PremiumMenu.vue'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import EventsSideMenu from '@/components/Premium/Events/SideMenu.vue'
import OffersBox from '@/components/Premium/Offers/OffersBox.vue'
import OffersSmallerBox from '@/components/Premium/Offers/OffersSmallerBox.vue'

export default defineComponent({
  name: 'PremiumEventOffers',
  components: {
    PremiumMenu,
    EventsSideMenu,
    OffersBox,
    OffersSmallerBox,
  },
  computed: {
    ...mapState(usePremiumStore, {
      eventSmallOffers: 'getEventSmallOffers',
      eventBigOffers: 'getEventBigOffers',
    }),
  },
  watch: {
    async '$route.query.reloadData'(): Promise<void> {
      if (!this.$route.query.reloadData) return

      await this.loadEventOffers(true)

      this.$router.replace({
        query: {
          ...this.$route.query,
          reloadData: undefined,
        },
      })
    },
  },
  async created(): Promise<void> {
    await this.loadEventOffers()
  },
  methods: {
    ...mapActions(usePremiumStore, ['loadEventOffers']),
  },
})
</script>

<style lang="scss" scoped>
.offer-wrapper {
  width: calc(100% - 11.625rem);

  &-scroll {
    margin-top: 2.875rem;
    margin-left: $default-margin;
    margin-right: $default-margin;
    display: -webkit-inline-box;
  }
}
</style>
