import { MAP_ARENA } from '@/globalVariables'
import { StageType } from '@/interfaces/Tutorial'
import type { TutorialStage } from '@/interfaces/Tutorial'

/**
  Tutorial for discipline unlocked
 */
export default class TutorialUnlockSuperCompetition {
  stages(): TutorialStage[] {
    return [
      // narative saying you unlocked a new discipline
      {
        id: 75,
        name: 'unlockSuperCompetitionNarrative',
        type: StageType.Narrative,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        mechanic: 6,
        texts: [],
      },
      // focus on arena building with gsap animation
      {
        id: 76,
        name: 'clickOnArenaSuperCompetition',
        type: StageType.Focus,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        clientId: [MAP_ARENA],
        mechanic: 6,
        texts: [],
      },
      // focus on unlocked discipline with overlay and gsap animation
      {
        id: 77,
        name: 'clickOnSuperCompetitionButton',
        type: StageType.ClientInput,
        overlay: true,
        pageName: 'ArenaViewTutorial',
        // new multiplier buttons
        clientId: [
          'super-multiplier-btn-0',
          'super-multiplier-btn-1',
          'super-multiplier-btn-2',
          'super-multiplier-btn-3',
        ],
        mechanic: 6,
        texts: [],
      },
    ]
  }
}
