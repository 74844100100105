<template>
  <div
    v-for="pack in bigPack"
    :key="pack.id"
    class="big-card premium-big-card-item text-texts-standard-default cursor-pointer relative clickable-element animated-element--faded"
    :class="`card-background-${pack.parameters.type}-${pack.parameters.order}`"
    @click="buyPack(pack.id, pack.parameters.price_gems), $emit('popup')"
  >
    <app-plate-value
      :text-value="pack.parameters.order === 3 ? 'most popular' : 'best value'"
      :color="pack.parameters.order === 3 ? 'darkBlue' : 'red'"
      class="plate"
    />
    <div :class="`big-card-img big-card-${pack.parameters.type}-${pack.parameters.order}`" />
    <section
      class="big-card-reward text-texts-standard-important text-60 font-bold flexing relative"
      :class="pack.parameters.order === 4 ? 'bg-' + pack.parameters.type : ''"
    >
      <p>{{ `${pack.parameters.amount}x` }}</p>
    </section>
    <article class="flexing before-price text-40">
      <div>{{ $filters.$formatNumber(pack.parameters.price_gems_original) }}</div>
      <app-main-icon class="icon-margin" :icon-size="48" :icon-name="'gems'" />
    </article>
    <article class="flexing font-bold text-50">
      <app-price
        :decoration-color="getDecorationColor(pack.parameters.type, pack.parameters.order)"
        decoration-height="2.75rem"
      >
        <p class="flexing text-50 font-bold m-auto">
          {{ $filters.$formatNumber(pack.parameters.price_gems) }}
          <app-main-icon class="icon-margin price-icon" :icon-size="56" :icon-name="'gems'" />
        </p>
      </app-price>
    </article>
    <div class="badge uppercase absolute text-texts-standard-default font-bold">
      <p class="up flexing text-40 text-texts-standard-important">
        {{ `${pack.parameters.percentage_off}%` }}
      </p>
      <p class="down flexing text-36">off</p>
    </div>
  </div>
</template>

<script lang="ts">
import AppPlateValue from '@/components/GlobalComponents/AppPlateValue.vue'
import AppPrice from '@/components/GlobalComponents/AppPrice.vue'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { PremiumPack } from '@/interfaces/premium/PremiumPack'

export default defineComponent({
  components: {
    AppPlateValue,
    AppPrice,
  },
  props: {
    bigPack: {
      type: Array as PropType<PremiumPack[]>,
      default: () => [],
    },
  },
  emits: ['pack', 'popup'],
  methods: {
    buyPack(id: number, amount: string): void {
      this.$emit('pack', id, amount)
    },
    getDecorationColor(type: string, order: string): string {
      switch (type + '-' + order) {
        case 'energy_drink-3':
          return '#ffe29d'
        case 'energy_drink-4':
          return '#d684ff'
        case 'wild_card-3':
          return '#fff7e2'
        case 'wild_card-4':
          return '#c99dff'
        default:
          return ''
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.big-card {
  width: 31.25rem;
  height: 39.75rem;

  &:first-child {
    margin-right: 2.4375rem;
  }

  .plate {
    margin-top: 0.8125rem;
    margin-left: 12rem;
  }

  &-img {
    margin: 0 auto;
    height: 20.8125rem;
    width: 27.75rem;
  }

  @for $i from 3 through 4 {
    .big-card-wild_card-#{$i} {
      background: url($path-premium + 'WildEnergy/big-icon-wildCard-' + #{$i} + '.avif')
        center
        no-repeat;
      background-size: contain;
    }

    .big-card-energy_drink-#{$i} {
      background: url($path-premium + 'WildEnergy/big-icon-energyDrink-' + #{$i} + '.avif')
        center
        no-repeat;
      background-size: contain;
    }
  }

  .before-price {
    opacity: 0.7;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      background: #b52e2d;
      width: 7.8125rem;
      height: 0.25rem;
      left: 49%;
      transform: translate(-50%) skewY(-12deg);
      top: 45%;
    }
  }

  &-reward {
    height: 4.375rem;
    margin-bottom: 1.25rem;

    @if $isSsm {
      background: linear-gradient(to right, transparent, #836412, transparent);
    }

    @if $isWsm {
      background: linear-gradient(to right, transparent, #cc8d00, transparent);
    }

    &:before {
      content: '';
      position: absolute;
      width: 80%;
      height: 0.125rem;
      background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.8), transparent);
      top: 0;
    }
  }

  .bg-energy_drink {
    @if $isSsm {
      background: linear-gradient(to right, transparent, rgba(149, 25, 48, 1), transparent);
    }

    @if $isWsm {
      background: linear-gradient(to right, transparent, #cd0136bd, transparent);
    }
  }

  .bg-wild_card {
    @if $isSsm {
      background: linear-gradient(to right, transparent, rgba(108, 78, 175, 1), transparent);
    }

    @if $isWsm {
      background: linear-gradient(to right, transparent, rgba(132, 68, 160, 0.8), transparent);
    }
  }

  .badge {
    background: url($path-premium + 'WildEnergy/BG_OFF_STICKER.avif') center no-repeat;
    background-size: contain;
    width: 7.5625rem;
    height: 7.6875rem;
    right: 1rem;
    bottom: 1rem;

    .up {
      height: 3.2rem;
      margin-top: 1rem;
    }

    .down {
      height: 1rem;
    }
  }

  .icon-margin.price-icon {
    margin-right: 0;
  }
}

.card-background-energy_drink-3,
.card-background-wild_card-3 {
  background: url($path-premium + 'WildEnergy/bigBox-energyDrink-3.avif') center no-repeat;
  background-size: contain;
}

.card-background-energy_drink-4 {
  background: url($path-premium + 'WildEnergy/bigBox-energyDrink-4.avif') center no-repeat;
  background-size: contain;
}

.card-background-wild_card-4 {
  background: url($path-premium + 'WildEnergy/bigBox-wildCard-4.avif') center no-repeat;
  background-size: contain;
}
</style>
