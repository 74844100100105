<template>
  <section class="upgrade-benefit text-texts-standard-default text-34">
    <div class="upgrade-benefit-detail relative flexing">
      <p class="text-texts-standard-default text-40 font-bold mx-2">
        {{ upgradeFromBenefit.benefit_name }}
      </p>
      <app-main-icon
        class="upgrade-benefit-detail-icon mx-2"
        :icon-size="120"
        :icon-name="`benefit-${upgradeFromBenefit.benefit_gdd_id}`"
      />
      <p
        class="upgrade-benefit-detail-amount-to-upgrade text-texts-standard-default text-50 font-bold mx-4"
      >
        {{ selectedAmount * exchangeRate }}
      </p>
      <div class="upgrade-benefit-detail-arrow benefit-arrow mx-4" />
      <p class="upgrade-benefit-detail-amount-to-get text-texts-standard-important font-bold mx-4">
        {{ selectedAmount }}
      </p>
      <app-main-icon
        class="upgrade-benefit-detail-icon mx-2"
        :icon-size="120"
        :icon-name="`benefit-${upgradeToBenefit.benefit_gdd_id}`"
      />
      <p class="text-texts-standard-default text-40 font-bold mx-2">
        {{ upgradeToBenefit.benefit_name }}
      </p>
    </div>
    <div class="upgrade-benefit-amount">
      <p class="uppercase text-28 text-texts-standard-additional">
        {{ $t('benefits.setCountBenefits') }}
      </p>
      <app-slider
        class="upgrade-benefit-amount-slider"
        :min="minAmount"
        :max="maxAmount"
        :default-value="1"
        tooltips="bottom"
        show-buttons
        @set-slider-value="setExchangeAmount"
      />
    </div>
    <div class="upgrade-benefit-footer flex items-center justify-between">
      <div class="upgrade-benefit-footer-exchange flexing">
        <p class="upgrade-benefit-footer-exchange-from text-texts-standard-default text-40">
          {{ exchangeRate }}
        </p>
        <app-main-icon
          :icon-size="32"
          :icon-name="`benefit-${upgradeFromBenefit.benefit_gdd_id}`"
        />
        <div class="upgrade-benefit-footer-exchange-arrow benefit-arrow mx-4" />
        <p
          class="upgrade-benefit-footer-exchange-to text-texts-standard-important text-50 font-bold"
        >
          1
        </p>
        <app-main-icon :icon-size="32" :icon-name="`benefit-${upgradeToBenefit.benefit_gdd_id}`" />
      </div>
      <app-multi-button
        :btn-type="upgradePaymentType === 'money' ? 'confirm' : 'credit'"
        :btn-icon="upgradePaymentType"
        :btn-text="$t('benefits.upgradeBenefits')"
        btn-size="sm"
        :disabled="notEnoughtBenefitsToUpgrade || notEnoughResourcesToUpgrade"
        class="upgrade-benefit-footer-button"
        @click="notEnoughtBenefitsToUpgrade || notEnoughResourcesToUpgrade ? '' : upgrade()"
      >
        <template #rightPart>
          <div class="flexing">
            <span class="price">
              {{ upgradePrice }}
            </span>
            <app-main-icon
              v-if="upgradePaymentType === 'money'"
              :icon-size="56"
              :icon-name="upgradePaymentType"
            />
          </div>
        </template>
      </app-multi-button>
    </div>
  </section>
</template>

<script lang="ts">
import AppSlider from '@/components/GlobalComponents/AppSlider.vue'
import { GEMS, MONEY, shopBenefitsConvertEndpoint } from '@/globalVariables'
import { playSound } from '@/helpers'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import type { BenefitSlotData } from '@/interfaces/Benefits'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { ShopBenefitsConvertApiResponse } from '@/interfaces/responses/benefit/ShopBenefitsConvertApiResponse'

interface ComponentData {
  selectedAmount: number
}

export default defineComponent({
  name: 'UpgradeBenefitPopup',
  components: {
    AppSlider,
  },
  props: {
    upgradeFromBenefit: {
      type: Object as PropType<BenefitSlotData>,
      required: true,
    },
    upgradeToBenefit: {
      type: Object as PropType<BenefitSlotData>,
      required: true,
    },
  },
  emits: ['benefitUpgraded'],
  data(): ComponentData {
    return {
      selectedAmount: 0,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      gems: 'getGems',
      money: 'getMoney',
    }),
    notEnoughtBenefitsToUpgrade(): boolean {
      return (
        !Object.keys(this.upgradeFromBenefit).length ||
        Number(this.upgradeFromBenefit.amount) < this.exchangeRate
      )
    },
    notEnoughResourcesToUpgrade(): boolean {
      const currency = this.upgradePaymentType === 'money' ? this.money?.value : this.gems?.value
      return !currency || Number(this.upgradePrice) > currency
    },
    minAmount(): number {
      return this.notEnoughtBenefitsToUpgrade ? 0 : 1
    },
    maxAmount(): number {
      return Math.floor(Number(this.upgradeFromBenefit.amount) / this.exchangeRate)
    },
    exchangeRate(): number {
      return Number(this.upgradeFromBenefit.benefit_count_to_upgrade)
    },
    upgradePrice(): number {
      return (
        this.selectedAmount *
        Number(
          this.upgradeFromBenefit.benefit_upgrade_price_cash ??
            this.upgradeFromBenefit.benefit_upgrade_price_gems,
        )
      )
    },
    upgradePaymentType(): string {
      return this.upgradeFromBenefit.benefit_upgrade_price_cash ? MONEY : GEMS
    },
  },
  created(): void {
    this.selectedAmount = this.minAmount
  },
  methods: {
    setExchangeAmount(value: number): void {
      this.selectedAmount = value
    },
    async upgrade(): Promise<void> {
      if (this.upgradeToBenefit?.benefit_id) {
        try {
          await this.$axios.put<{}, ShopBenefitsConvertApiResponse[]>(shopBenefitsConvertEndpoint, {
            benefit_id: this.upgradeToBenefit?.benefit_id,
            amount: this.selectedAmount,
          })
          this.$emit('benefitUpgraded')
          playSound('benefits_upgrade')
        } catch (error: unknown) {
          console.error(error)
        }
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/benefits/upgrade.scss';
</style>
