import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { SpecificObjectHandler } from '@/map-phaser-new/utils/abstractClasses'
import { SHOW_WEATHER, HIDE_WEATHER } from '@/map-phaser-new/constants'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import type { WeatherConfigInterface } from '@/map-phaser-new/interfaces'

export class WeatherHandler extends SpecificObjectHandler {
  private weatherParticles: Phaser.GameObjects.Particles.ParticleEmitter

  constructor() {
    super()
  }

  public async setUp(activeScene: Phaser.Scene, config: WeatherConfigInterface): Promise<void> {
    return new Promise<void>((resolve) => {
      const gameIntegrationStore = usePhaserGameIntegrationStore()
      const eventEmitter = gameIntegrationStore.getPhaserEventEmitter
      this.createWeather(activeScene, config)

      this.addWeatherActionsToEmitter(eventEmitter)
      resolve()
    })
  }

  private createWeather(activeScene: Phaser.Scene, config: WeatherConfigInterface): void {
    if (this.weatherParticles) return

    const width = activeScene.textures.getFrame(config.mapFrameKey)?.width
    if (!width) return

    const positionX = {
      min: width + config.xCorrectionMin,
      max: width * config.xCorrectionMax,
    }
    this.weatherParticles = activeScene.add
      .particles(0, 0, config.textureKey, { x: positionX, ...config })
      .setDepth(config.depth)

    const tutorialStore = useTutorialStore()
    if (tutorialStore.getIsTutorial) {
      this.hideWeather()
    }
  }

  private hideWeather(): void {
    this.weatherParticles.setVisible(false)
  }

  private showWeather(): void {
    this.weatherParticles.setVisible(true)
  }

  private addWeatherActionsToEmitter(eventEmitter: Phaser.Events.EventEmitter): void {
    eventEmitter.on(
      SHOW_WEATHER,
      () => {
        this.showWeather()
      },
      this,
    )
    eventEmitter.on(
      HIDE_WEATHER,
      () => {
        this.hideWeather()
      },
      this,
    )
  }
}
