<template>
  <div class="seasons-badge flex flex-col">
    <span class="text-50 text-texts-standard-important">
      {{ seasonMonth }}
    </span>
    <span class="seasons-badge-text text-30 uppercase">
      {{ $t('seasons.season') }}
    </span>
    <span class="text-50 text-texts-standard-additional">
      {{ seasonYear }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SeasonsBadge',
  props: {
    seasonMonth: {
      type: String,
      required: true,
    },
    seasonYear: {
      type: String,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.seasons-badge {
  width: 100%;
  height: 100%;
  background-image: url($path-images + 'seasons/season-badge.avif');
  background-size: cover;
  padding-top: 1.9rem;

  &-text {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
}
</style>
