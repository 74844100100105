import { GAME_LIVE, GAME_MAINTENANCE, GAME_UPDATE, MAINTENANCE_ROUTE_NAME } from '@/globalVariables'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useGameStateStore } from '@/store/pinia/gameStateStore'
import type { Router } from 'vue-router'
import type { GameState } from '@/store/pinia/gameStateStore'

export const manageGameState = (state: GameState, gameReadyAt: GameState, router: Router): void => {
  const useGameState = useGameStateStore()
  if (useGameState.getGameState === state) return
  useGameState.setGameState(state)
  useGameState.setGameReadyAt(gameReadyAt)

  if (state === GAME_MAINTENANCE || state === GAME_UPDATE) {
    setTimeout(() => {
      if (!sessionStorage.getItem('isLoggedFromAdmin')) {
        const useCore = useCoreStore()
        useCore.setLoadingScreen(false)
        router.push({
          name: MAINTENANCE_ROUTE_NAME,
        })
      }
    }, 1000)

    window.gameStatusInterval = setInterval((): void => {
      useGameState.loadGameState()
    }, 60000)
  }

  if (state === GAME_LIVE) {
    clearInterval(window.gameStatusInterval)
  }
}
