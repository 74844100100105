<template>
  <section
    class="events-rankings flex flex-col w-full h-full safe-area"
    :data-event-theme="namespace"
  >
    <div class="flex flex-col flex-grow justify-between items-center">
      <event-discipline-header class="mt-8">
        <div class="flexing events-rankings-header-buttons">
          <app-button
            btn-size="md"
            :btn-type="rankingsType === RankingsTypes.Rounds ? 'secondary' : 'inactive'"
            :btn-text="$te('roundRankings')"
            class="mr-4 events-rankings-header-buttons-button"
            @click="rankingsType = RankingsTypes.Rounds"
          />
          <app-button
            btn-size="md"
            :btn-type="rankingsType === RankingsTypes.Total ? 'secondary' : 'inactive'"
            :btn-text="$te('totalRankings')"
            class="events-rankings-header-buttons-button"
            @click="rankingsType = RankingsTypes.Total"
          />
        </div>
      </event-discipline-header>
      <events-rankings-rounds v-if="rankingsType === RankingsTypes.Rounds" />
      <events-rankings-total v-if="rankingsType === RankingsTypes.Total" />
    </div>
  </section>
</template>

<script lang="ts">
import EventDisciplineHeader from '@/components/Events/EventDisciplineHeader.vue'
import EventsRankingsRounds from '@/components/Events/EventsRankings/EventsRankingsRounds.vue'
import EventsRankingsTotal from '@/components/Events/EventsRankings/EventsRankingsTotal.vue'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

enum RankingsTypes {
  Rounds = 'rounds',
  Total = 'total',
}

interface ComponentData {
  RankingsTypes: typeof RankingsTypes
  rankingsType: RankingsTypes
}

export default defineComponent({
  name: 'EventsRankings',
  components: {
    EventDisciplineHeader,
    EventsRankingsRounds,
    EventsRankingsTotal,
  },
  data(): ComponentData {
    return {
      RankingsTypes,
      rankingsType: RankingsTypes.Rounds,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
  },
})
</script>

<style lang="scss" scoped>
.events-rankings {
  &-header-buttons {
    margin-left: 7rem;

    &-button {
      width: 23.188rem;
    }
  }
}
</style>
