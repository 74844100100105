<template>
  <div
    class="main-duel-main-rightbox-footer-icon"
    :class="[
      color,
      {
        'text-60': $isWsm,
        'text-38 font-bold': $isSsm,
      },
    ]"
  >
    <div class="center-text-vertically">
      {{ position }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { getColor } from '@/helpers'

interface ComponentData {
  color: string | null
}

export default defineComponent({
  name: 'RewardIcon',
  props: {
    position: {
      type: Number,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      color: null,
    }
  },
  created(): void {
    this.color = getColor(this.position) + '-reward-icon'
  },
})
</script>

<style lang="scss">
@import '@/assets/styles/views/arena/arena.scss';

.yellow-reward-icon {
  @if $isWsm {
    color: theme('colors.texts.standard.important');
    background: url($path-arena + 'arena/BADGE_POSITION_GOLD.avif') center no-repeat;
    background-size: contain;
  }

  @if $isSsm {
    color: theme('colors.texts.buttons.bright');
    background-image: linear-gradient(to right, #daab36, #ffdf91);
    clip-path: polygon(0 0, 100% 0, 87.5% 100%, 0 100%);
    padding-right: 0.375rem;
  }
}

.white-reward-icon {
  @if $isWsm {
    color: theme('colors.texts.standard.silver');
    background: url($path-arena + 'arena/BADGE_POSITION_SILVER.avif') center no-repeat;
    background-size: contain;
  }

  @if $isSsm {
    color: theme('colors.texts.buttons.bright');
    background-image: linear-gradient(to right, #c3c3c3, #f5f0f0);
    clip-path: polygon(0 0, 100% 0, 87.5% 100%, 0 100%);
    padding-right: 0.375rem;
  }
}

.orange-reward-icon {
  @if $isWsm {
    color: theme('colors.texts.standard.bronze');
    background: url($path-arena + 'arena/BADGE_POSITION_BRONZE.avif') center no-repeat;
    background-size: contain;
  }

  @if $isSsm {
    color: theme('colors.texts.buttons.bright');
    background-image: linear-gradient(to right, #f58d57, #ffb28a);
    clip-path: polygon(0 0, 100% 0, 87.5% 100%, 0 100%);
    padding-right: 0.375rem;
  }
}

.blue-reward-icon {
  @if $isWsm {
    color: theme('colors.texts.standard.name');
    background: url($path-arena + 'arena/BADGE_POSITION_BLUE.avif') center no-repeat;
    background-size: contain;
  }

  @if $isSsm {
    color: theme('colors.texts.buttons.bright');
    background-color: #ffffff;
    clip-path: polygon(0 0, 100% 0, 87.5% 100%, 0 100%);
    padding-right: 0.375rem;
  }
}
</style>
