<template>
  <div class="league-club-panel flex flex-col items-center justify-center">
    <div class="item-border">
      <div class="league-club-panel-logo flex flex-col items-center justify-center">
        <clubs-avatar :background-id="Number(club?.logoBgId)" :logo-id="Number(club?.logoId)" />

        <div class="league-club-panel-logo-name flexing">
          <p class="text-34 text-texts-standard-default uppercase font-bold">
            <short-text :text="club?.name" :first-cut="20" />
          </p>
        </div>

        <div class="skew league-club-panel-logo-members" @click="isContributionModalVisible = true">
          <app-icon icon-name="members" class="league-club-panel-logo-members-icon" />
        </div>
      </div>
    </div>

    <div class="item-border mt-6">
      <div class="league-club-panel-league-info flex flex-col justify-center items-center">
        <p class="text-34 text-texts-standard-important uppercase leading-none font-bold mb-6">
          {{ $te('topLeagueAchieved') }}
        </p>
        <league-component :league-type="state?.highest_reached_league" custom-width="25rem" />
      </div>
    </div>
  </div>

  <club-contribution-popup
    :is-visible="isContributionModalVisible"
    :is-event-league="true"
    :event-league-icon="getCommodityPointsConfig?.icon"
    @close="isContributionModalVisible = false"
  />
</template>

<script lang="ts">
import ClubsAvatar from '@/components/Club/ClubsAvatar.vue'
import ClubContributionPopup from '@/components/Events/EventsPopups/ClubContributionPopup.vue'
import LeagueComponent from '@/components/Events/EventsPopups/LeagueComponent.vue'
import ShortText from '@/components/ShortText.vue'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useLeagueEventStore } from '@/store/pinia/events/leagueStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  isContributionModalVisible: boolean
}

export default defineComponent({
  name: 'EventsWarehouseLeagueClubPanel',
  components: {
    ClubsAvatar,
    LeagueComponent,
    ClubContributionPopup,
    ShortText,
  },
  data(): ComponentData {
    return {
      isContributionModalVisible: false,
    }
  },
  computed: {
    ...mapState(useClubStore, {
      club: 'getClubInfo',
    }),
    ...mapState(useLeagueEventStore, {
      state: 'getState',
      getCommodityPointsConfig: 'getCommodityPointsConfig',
    }),
  },
})
</script>

<style lang="scss" scoped>
.league-club-panel {
  background: url($path-events + 'frozen-championship/warehouse/league/league-club-panel-bg.avif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 2rem;
  padding-top: 0.5rem;

  @if $isSsm {
    position: relative;
    top: 0.375rem;
  }

  &-logo {
    height: 25rem;
    width: 26.5rem;
    background: rgba(16, 21, 42, 0.56);
    position: relative;
    padding: 1.5rem 2rem;
    box-shadow: inset 0px 3px 3px 0 rgba(0, 0, 0, 0.45);

    &-name {
      height: auto;
      margin-top: 1rem;
      padding: 0.5rem 1rem 0.5rem 1rem;

      @if $isWsm {
        background-image: linear-gradient(
          to right,
          transparent 0%,
          rgba(90, 159, 226, 0.5) 25%,
          rgba(90, 159, 226, 0.8) 50%,
          rgba(90, 159, 226, 0.5) 75%,
          transparent 100%
        );
        border-style: solid;
        border-width: 0.188rem;
        border-image-source: linear-gradient(
          to right,
          transparent 0%,
          rgba(162, 209, 255, 0.5) 15%,
          rgba(162, 209, 255, 1) 50%,
          rgba(162, 209, 255, 0.5) 85%,
          transparent 100%
        );
        border-image-slice: 1;
      }

      @if $isSsm {
        background-image: linear-gradient(to right, transparent, #7c7bb4, transparent);
      }
    }

    &-members {
      width: 4.375rem;
      height: 4.375rem;
      position: absolute;
      top: 1rem;
      right: 1.438rem;
      cursor: pointer;

      @if $isWsm {
        background-image: linear-gradient(184deg, #1b394f, #4787b4);
        border: 0.125rem solid #528ebf;
      }

      @if $isSsm {
        background-image: linear-gradient(to right, #2d425b, #4c6e96);
        border: 0.125rem solid #51799e;
      }

      &-icon {
        width: 4.375rem !important;
        height: 4.375rem !important;
      }

      &:active {
        transform: $skew-value scale(0.95);
      }
    }
  }

  &-league-info {
    background: rgba(16, 21, 42, 0.56);
    height: 12.063rem;
    width: 26.5rem;
    padding: 0.5rem 2rem;
    box-shadow: inset 0px 3px 3px 0 rgba(0, 0, 0, 0.45);
  }
}

.item-border {
  border: 1px solid #10152a;
  border-radius: 1.5rem;
  overflow: hidden;
}
</style>
