<template>
  <div id="settings" class="settings-wrapper app-page-wrapper absolute">
    <user-id-box class="absolute id-box" :class="{ 'id-box-web': !$isMobile() }" />
    <menu-component
      menu-type="settings"
      :title="$t('settings.settings')"
      :sub-menu-active="true"
      :sub-menu="{
        1: {
          text: $t('settings.global'),
          route: $getWebView('Settings'),
        },
        2: {
          text: $t('settings.audio'),
          route: $getWebView('Audio'),
          current: $getWebView('Audio'),
        },
        3: {
          text: $t('settings.account'),
          route: $getWebView('Account'),
        },
      }"
    />
    <section class="w-full h-full safe-area">
      <main class="settings relative w-full">
        <settings-box :title="$t('settings.music')">
          <app-toggle
            :texts="toggleTexts"
            :selected-button="formatToggleValue(music)"
            @action="startToggleAction($event)"
          />
        </settings-box>
        <settings-box :title="$t('settings.sounds')">
          <app-toggle
            :texts="toggleTexts"
            :selected-button="formatToggleValue(sound)"
            @action="toggle(SystemConfigNames.Sounds)"
          />
        </settings-box>
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import AppToggle from '@/components/GlobalComponents/AppToggle.vue'
import SettingsBox from '@/components/Settings/SettingsBox.vue'
import UserIdBox from '@/components/Settings/UserIdBox.vue'
import { musicTheme, sendToFlutter, sfxSound } from '@/helpers'
import { useUserStore } from '@/store/pinia/userStore'
import { isMobile as isMobilePlugin } from '@/plugins'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { formatToggleValue } from './formatToggleValue'
import { SystemConfigNames } from '@/interfaces/User'

interface ComponentData {
  SystemConfigNames: typeof SystemConfigNames
  setMusic: string
  toggleTexts: string[]
}

export default defineComponent({
  name: 'AudioComponent',
  components: {
    UserIdBox,
    SettingsBox,
    AppToggle,
  },
  data(): ComponentData {
    return {
      SystemConfigNames,
      // eslint-disable-next-line no-useless-escape
      setMusic: '{\r\n  "event": "setMusic",\r\n "enabled": true\r\n}',
      toggleTexts: ['On', 'Off'],
    }
  },
  computed: {
    ...mapState(useUserStore, {
      sound: 'getSoundVolume',
      music: 'getMusicVolume',
    }),
  },
  methods: {
    ...mapActions(useUserStore, ['setSystemConfig']),
    formatToggleValue,
    startToggleAction(option: number): void {
      this.toggle(SystemConfigNames.Music)
      this.setAudio(option)
    },
    toggle(category: string): void {
      const isMobile = isMobilePlugin()

      if (category === SystemConfigNames.Music) {
        if (!isMobile) {
          this.music ? musicTheme.stop() : musicTheme.play()
        }
        this.setSystemConfig([{ name: SystemConfigNames.Music, value: !this.music ? 1 : 0 }])
      }

      if (category === SystemConfigNames.Sounds) {
        sfxSound.volume(!this.sound ? 1 : 0)
        this.setSystemConfig([{ name: SystemConfigNames.Sounds, value: !this.sound ? 1 : 0 }])
      }
    },
    setAudio(status: number): void {
      // eslint-disable-next-line no-useless-escape
      this.setMusic = '{\r\n  "event": "setMusic",\r\n "enabled": ' + (status === 1) + ' \r\n}'
      sendToFlutter(this.setMusic)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/settings/settings.scss';
</style>
