<template>
  <info-popup
    v-if="showPopup"
    width="85rem"
    :popup-title="$t('seasons.aboutSeasons')"
    @close="$emit('close')"
  >
    <div class="seasons-about-popup flex flex-col justify-start text-30 text-white space-y-4">
      <dash-text
        v-for="value in 6"
        :key="value"
        :text="
          $replacePlaceholder(
            $t(`seasons.aboutSeasonText${value}`),
            '%s',
            value === 2 ? String(MIN_REDUCED_POINTS) : String(TOP_PLAYERS),
          )
        "
      />
    </div>
  </info-popup>
</template>

<script lang="ts">
import DashText from '@/components/DashText.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { MIN_REDUCED_POINTS, TOP_PLAYERS } from '@/globalVariables'
import { defineComponent } from 'vue'

interface ComponentData {
  TOP_PLAYERS: typeof TOP_PLAYERS
  MIN_REDUCED_POINTS: typeof MIN_REDUCED_POINTS
}

export default defineComponent({
  name: 'SeasonsAboutPopup',
  components: {
    DashText,
    InfoPopup,
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      TOP_PLAYERS,
      MIN_REDUCED_POINTS,
    }
  },
})
</script>

<style lang="scss" scoped>
.seasons-about-popup {
  padding: 2rem;
}
</style>
