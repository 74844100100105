<template>
  <div class="milestones-table flexing" :class="{ 'can-claim-all': isUnclaimedRewards }">
    <div class="table-title">
      <div>{{ $te('activityMilestones') }}</div>
    </div>
    <div class="table-header flexing">
      <div>{{ $te('milestone') }}</div>
      <div>{{ $t('common.reward') }}</div>
    </div>
    <app-scrollbar
      class="milestones-table-scroll-area pt-2"
      width="100%"
      height="100%"
      scroll="y"
      slide="y"
    >
      <div class="table-body flex flex-col">
        <milestones-table-row
          v-for="(milestone, index) in milestones"
          :key="index"
          :milestone="milestone"
          :milestone-index="index + 1"
          :is-disabled="isLoading || isDisabled"
          @is-loading="isDisabled = $event"
        />
      </div>
    </app-scrollbar>
    <div v-if="isUnclaimedRewards && !isAllRewardsClaimed" class="table-footer">
      <app-button
        :disabled="isLoading || isDisabled"
        :loading="isLoading"
        class="milestones-table-claim-all-btn"
        btn-type="confirm"
        :btn-text="$te('claimAllRewards')"
        :notifications-count="claimNotificationCount"
        force-notification
        @click="claimAll"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MilestonesTableRow from '@/components/Events/BoardComponents/BountyLeague/MilestonesTableRow.vue'
import { mapActions, mapState } from 'pinia'
import { useLeagueEventStore } from '@/store/pinia/events/leagueStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'

import type { PropType } from 'vue'
import type {
  CompetitionMilestone,
  State,
} from '@/interfaces/responses/events/league/LeagueStateApiResponse'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'

interface ComponentData {
  isLoading: boolean
  isDisabled: boolean
}

export default defineComponent({
  components: {
    MilestonesTableRow,
  },
  props: {
    milestones: {
      type: Array as PropType<CompetitionMilestone[]>,
      default: null,
    },
    state: {
      type: Object as PropType<State>,
      default: null,
    },
  },
  data(): ComponentData {
    return {
      isLoading: false,
      isDisabled: false,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
    ...mapState(useResponseTaskStore, {
      notifications: 'getNotifications',
    }),
    isUnclaimedRewards(): boolean {
      return this.state?.unclaimed_rewards
    },
    isAllRewardsClaimed(): boolean {
      return this.state?.all_rewards_claimed
    },
    claimNotificationCount(): number {
      if (this.namespace)
        return this.notifications?.events?.[this.namespace]?.unclaimed_competition_milestones ?? 0
      return 0
    },
  },
  methods: {
    ...mapActions(useLeagueEventStore, {
      claim: 'claim',
    }),
    async claimAll(): Promise<void> {
      if (this.isLoading) return

      this.isLoading = true
      await this.claim()
      this.isLoading = false
    },
  },
})
</script>

<style lang="scss" scoped>
.milestones-table {
  flex-direction: column;
  width: 42rem;
  height: 100%;
  gap: 0.5rem;

  &-scroll-area {
    gap: 1.5rem;
  }

  .table-title {
    width: 100%;
    height: 2.75rem;
    background-image: linear-gradient(
      to right,
      transparent,
      rgba(74, 77, 111, 0.7),
      #4a4d6f,
      #4a4d6f,
      rgba(74, 77, 111, 0.7),
      transparent
    );
    border-style: solid;
    border-width: 0.125rem;
    border-image-source: linear-gradient(
      to right,
      transparent,
      rgba(159, 159, 233, 0.7),
      rgba(195, 195, 255, 1),
      rgba(159, 159, 233, 0.7),
      transparent
    );
    border-image-slice: 1;
    border-left: none;
    border-right: none;

    & > div {
      flex: 1;
      // color: #feb942;
      color: transparent;
      background-image: linear-gradient(to top, #fec433, #ffe31d);
      background-clip: text;
      font-size: 1.875rem;
      font-weight: 700;
      text-align: center;
      // text-shadow: 2px 3.5px 0 rgba(39, 39, 39, 0.86);
      text-shadow: none;
      filter: drop-shadow(2px 3.5px 0 rgba(39, 39, 39, 0.86));
    }
  }

  &.can-claim-all .milestones-table-scroll-area {
    margin-bottom: 1rem;
  }

  &-claim-all-btn {
    width: 32rem;
    margin: 0 auto;
  }
}

.table-header {
  width: 100%;
  position: sticky;
  top: 0;
  height: 3rem;
  color: #ffffff;
  background-color: #292d30;
  font-size: 1.875rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 1rem !important;
  z-index: 10;

  & > div {
    flex: 1;
  }
}

.table-body {
  gap: 1.375rem;
  width: calc(100% - 1rem);
}

.table-footer {
  width: 100%;
}

.app-button {
  position: relative;

  .notification {
    position: absolute;
    top: -1rem;
    right: -1rem;
    width: 2rem;
    height: 2rem;
    color: #ffffff;
    background-image: linear-gradient(to top, #e70000, #ff5b5b);
    border-radius: 1rem;
    font-size: 1.375rem;
    font-weight: 700;
    padding-top: 0.1875rem;
  }
}
</style>
