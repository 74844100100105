import type { BuildingLabelNewConfigInterface } from '@/map-phaser-new/interfaces'

export class BuildingLabelNew {
  private labelNewImage: Phaser.GameObjects.Image

  constructor(
    private positionX: number,
    private positionY: number,
  ) {}

  public addToBuilding(activeScene: Phaser.Scene, config: BuildingLabelNewConfigInterface): void {
    this.createLabelNew(activeScene, config)
  }

  private createLabelNew(activeScene: Phaser.Scene, config: BuildingLabelNewConfigInterface): void {
    this.labelNewImage = activeScene.add
      .image(this.positionX, this.positionY, config.textureName)
      .setOrigin(config.originX)
      .setDepth(config.depth)
  }

  public removeLabelNewImage(): void {
    this.labelNewImage.destroy()
    this.labelNewImage = null
  }
}
