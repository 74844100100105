<template>
  <div class="shooting-range-row flex items-center justify-between">
    <div
      class="shooting-range-row__reward-box flexing"
      :class="{ 'shooting-range-row__reward-box--done': isCompleted || isClaimable }"
    >
      <div class="shooting-range-row__reward-box-target flexing flex-col items-center">
        <div
          class="shooting-range-row__reward-box-target-number text-30 font-bold text-texts-standard-default"
        >
          <span class="text-texts-standard-upgrade">{{ rowData.row_completed_targets }}</span>
          <span>/{{ rowData.targets.length }}</span>
        </div>
        <div class="shooting-range-row__reward-box-target-icon" />
      </div>
      <div class="shooting-range-row__reward-box-info flexing">
        <span class="text-texts-standard-name text-32 font-bold">
          {{ $filters.$formatNumber(rowData.row_completed_reward.reward.value) }}
        </span>
        <app-main-icon :icon-size="32" :icon-name="rowData.row_completed_reward.reward.type" />
        <app-icon
          v-if="!(isClaimable || isCompleted)"
          class="absolute box-icon--lock"
          icon-name="lock-xsm"
        />
        <app-icon v-if="isCompleted" class="absolute box-icon--done" icon-name="done-sm" />
      </div>
    </div>
    <div
      class="shooting-range-row__panel-box flexing"
      :class="{
        'shooting-range-row__panel-box--completed opacity-50': isCompleted && !isClaimable,
        'shooting-range-row__panel-box--claimable': isClaimable,
      }"
    >
      <div
        v-if="isClaimable"
        class="shooting-range-row__panel-box-cta flex items-center justify-between"
      >
        <span class="text-texts-standard-default text-32 font-bold">
          {{ $te('rowComplete') }}
        </span>
        <app-button
          btn-id="btn-shooting-range-panel-btn"
          btn-size="md"
          btn-type="confirm"
          :btn-text="$t('common.claim')"
          :notifications-count="1"
          force-notification
          @click="claim('row_reward', rowData.row)"
        />
      </div>
    </div>
    <div
      class="shooting-range-row__targets flexing"
      :class="{
        'shooting-range-row__targets--completed pointer-events-none': isCompleted,
        'opacity-40': isClaimable || isCompleted,
      }"
    >
      <target-item
        v-for="(target, index) in rowData.targets"
        :key="index"
        :target-data="{
          targetNumber: target.position,
          targetIcon: target.reward.type,
          targetValue: target.reward.value,
          isShot: target.claimed,
        }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TargetItem from '@/components/Events/CarnivalStandComponents/ShootingRange/TargetItem.vue'

import type { PropType } from 'vue'
import type { RangeRow } from '@/interfaces/responses/events/shooting-range/ShootingRangeStateApiResponse'
import { mapActions } from 'pinia'
import { useShootingRangeStore } from '@/store/pinia/events/shootingRangeStore'

export default defineComponent({
  name: 'ShootingRangeRow',
  components: {
    TargetItem,
  },
  props: {
    rowData: {
      type: Object as PropType<RangeRow>,
      default: null,
    },
  },
  computed: {
    isCompleted(): boolean {
      return this.rowData.row_completed_reward.claimed
    },
    isClaimable(): boolean {
      return this.rowData.row_completed_reward.claimable
    },
  },
  methods: {
    ...mapActions(useShootingRangeStore, {
      claim: 'claim',
    }),
  },
})
</script>

<style lang="scss" scoped>
.shooting-range-row {
  position: relative;

  &__reward {
    position: relative;

    &-box {
      position: relative;
      background: url($path-events + 'autumn-fair/shooting-range/row-box.avif') no-repeat center;
      background-size: contain;
      width: 18.563rem;
      height: 6.125rem;
      padding: 0.5rem;
      z-index: 3;

      &--done {
        background: url($path-events + 'autumn-fair/shooting-range/row-box--done.avif') no-repeat
          center;
        background-size: contain;
      }

      &-target {
        position: relative;
        width: 5.125rem;
        height: 100%;

        &-number {
          line-height: 1.875rem;
        }

        &-icon {
          position: relative;
          width: 2rem;
          height: 2rem;
          background: url($path-events + 'autumn-fair/shooting-range/target.avif') no-repeat center;
          background-size: contain;
        }
      }

      &-info {
        position: relative;
        flex: 1 1 auto;
        height: 4rem;

        .box-icon {
          &--lock {
            right: -0.5rem;
            top: -1rem;
          }

          &--done {
            right: -0.875rem;
            bottom: -0.5rem;
          }
        }
      }
    }
  }

  &__panel-box {
    position: absolute;
    width: 100%;
    height: 70%;
    z-index: 0;

    background: linear-gradient(
      to right,
      rgba(37, 46, 75, 0) 0%,
      rgba(37, 46, 75, 0.2) 35%,
      rgba(38, 48, 77, 1) 47.5%,
      rgba(38, 48, 77, 1) 72.5%,
      rgba(37, 46, 75, 0.2) 85%
    );

    &--completed {
      background: linear-gradient(
        to right,
        rgba(37, 46, 75, 0) 0%,
        rgba(14, 116, 105, 0.2) 35%,
        rgba(14, 116, 105, 1) 47.5%,
        rgba(14, 116, 105, 1) 72.5%,
        rgba(14, 116, 105, 0.2) 85%
      );
    }

    &--claimable {
      z-index: 2;
      background: linear-gradient(
        90deg,
        rgba(37, 48, 76, 1) 0%,
        rgba(37, 48, 76, 0.8) 50%,
        rgba(73, 87, 126, 0.8) 80%,
        rgba(73, 87, 126, 0) 100%
      );

      .shooting-range-row__panel-box-cta {
        width: 75%;
        margin-left: auto;
      }
    }
  }

  &__targets {
    position: relative;
    gap: 2.5rem;
    z-index: 1;
  }
}
</style>
