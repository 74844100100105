<template>
  <div className="w-full h-full fixed">
    <component-loadings :is-loading="true" />
  </div>
</template>

<script lang="ts">
import { saveGroupIdToLocalStorage } from '@/helpers'
import router from '@/router'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { defineComponent } from 'vue'
import type { RouteLocationNormalized } from 'vue-router'

export default defineComponent({
  name: 'GroupUserParser',
  beforeRouteEnter(to: RouteLocationNormalized): void {
    const groupIdParam = to.params.id
    if (groupIdParam.length > 0) {
      saveGroupIdToLocalStorage('last_id_group', groupIdParam.toString(), 0)
    }
    const idGroupLS = JSON.parse(localStorage.getItem('id_group'))
    if (groupIdParam.length > 0 && !idGroupLS) {
      saveGroupIdToLocalStorage('id_group', groupIdParam.toString(), 2592000000)
    }
    const useTutorial = useTutorialStore()
    if (!useTutorial.getIsTutorial) {
      router.push('/')
    }
  },
})
</script>
