<template>
  <div class="club-available-footer flexing w-full">
    <info-text :text="$t('club.chatLockText2')" />
    <div class="club-available-create-buttons flex">
      <app-button
        btn-id="club-available-invitations"
        btn-size="md"
        btn-type="primary"
        :btn-text="$t('club.invitations')"
        :notifications-count="notifications.clubs_requests"
        :disabled="hasClub"
        @click="openInvitations"
      />
      <app-button
        btn-id="club-available-create"
        btn-size="md"
        btn-type="secondary"
        :btn-text="$t('club.createClub')"
        :disabled="hasClub"
        @click="createClub"
      />
    </div>
  </div>
</template>

<script lang="ts">
import InfoText from '@/components/InfoText.vue'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClubsAvailableFooter',
  components: {
    InfoText,
  },
  emits: ['emitInvitations', 'emitCreate'],
  computed: {
    ...mapState(useUserStore, {
      hasClub: 'getHasClub',
    }),
    ...mapState(useResponseTaskStore, {
      notifications: 'getNotifications',
    }),
  },
  methods: {
    openInvitations(): void {
      this.$emit('emitInvitations', true)
    },
    createClub(): void {
      this.$emit('emitCreate', true)
    },
  },
})
</script>

<style lang="scss" scoped>
.club-available-footer {
  width: 98%;
  height: 100%;
  margin: 0 auto;
}

.club-available-create-buttons {
  button {
    margin: 0 0.5rem;
  }
}
</style>
