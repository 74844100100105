<template>
  <section class="app-page-wrapper game-pass-packs flex flex-col safe-area">
    <header class="flexing">
      <div class="packs-title flexing">
        <div class="packs-logo"></div>
        <app-button
          btn-id="bags-rewards-btn"
          btn-type="primary"
          btn-size="md"
          :force-notification="getNotifications.game_pass?.unclaimed_milestones > 0"
          :notifications-count="getNotifications.game_pass?.unclaimed_milestones"
          @click="goToPass"
        >
          <div class="flexing">
            <p class="rewards-text">{{ $t('common.rewards') }}</p>
            <span class="rewards-icon icon-present-56 ml-4"> </span>
          </div>
        </app-button>
        <div v-if="remainingTime" class="packs-timer">
          <game-pass-info-timer :time="remainingTime" />
        </div>
      </div>
      <section class="packs-info text-left">
        <app-icon icon-name="question" @click="showAboutPopup = true" />

        <game-pass-about-popup v-if="showAboutPopup" @close="showAboutPopup = false" />
      </section>
      <p class="packs-about text-32 text-texts-standard-dark font-bold absolute flexing">
        {{ getText($t('gamePass.tagline')) }}
      </p>
    </header>
    <component-loading :is-loading="!offers" />

    <main v-if="offers" class="flexing relative">
      <game-pass-pack
        v-for="pack in offers"
        :key="`big-pack-${pack.type}`"
        :pack-type="pack.type"
        :purchased="pack.purchased"
        :price="pack.price"
        :store-id="pack.storeId"
        :is-pass-ending="isPassEnding"
        :rewards="pack.rewards"
      />
    </main>
    <footer class="flexing relative">
      <app-icon icon-name="info-rounded" />
      <p class="text-texts-standard-default text-28 ml-4">
        {{ $t('gamePass.disclaimer') }}
      </p>
    </footer>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { replaceGamePassPlaceholders } from '@/helpers'
import GamePassPack from '@/components/Premium/GamePass/GamePassPack.vue'
import GamePassAboutPopup from '@/components/Premium/GamePass/GamePassAboutPopup.vue'
import GamePassInfoTimer from '@/components/Premium/GamePass/GamePassInfoTimer.vue'

import { useGamePassStore } from '@/store/pinia/gamePassStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions, mapState } from 'pinia'

interface ComponentData {
  showAboutPopup: boolean
}

export default defineComponent({
  name: 'PremiumGamePassPacks',
  components: {
    GamePassAboutPopup,
    GamePassPack,
    GamePassInfoTimer,
  },
  data(): ComponentData {
    return {
      showAboutPopup: false,
    }
  },
  computed: {
    ...mapState(useGamePassStore, ['offers', 'remainingTime', 'isPassEnding']),
    ...mapState(useResponseTaskStore, ['getNotifications']),
  },
  watch: {
    async '$route.query.reloadData'(): Promise<void> {
      if (!this.$route.query.reloadData) return

      await this.loadState(true)

      this.$router.replace({
        query: {
          ...this.$route.query,
          reloadData: undefined,
        },
      })
    },
  },
  async created(): Promise<void> {
    await this.loadData()
  },
  methods: {
    ...mapActions(useGamePassStore, ['loadState', 'loadConfig']),
    ...mapActions(useResponseTaskStore, ['loadNotifications']),
    getText(text: string): string {
      return replaceGamePassPlaceholders(text)
    },
    async loadData(): Promise<void> {
      this.loadState()
      this.loadConfig()
      this.loadNotifications()
    },
    goToPass(): void {
      this.$router.push({ name: this.$getWebView('PremiumGamePass') })
    },
  },
})
</script>

<style lang="scss" scoped>
.game-pass-packs {
  background: url($path-premium + 'gamePass/packs/game-pass-bg.avif') center bottom no-repeat;
  background-size: cover;

  & > header {
    position: relative;
    top: 8rem;

    .packs-info {
      right: 3.625rem;
      position: absolute;
    }
    .packs-timer {
      position: absolute;
      right: 8rem;
    }
    .packs-title {
      width: 85%;
      height: 6rem;
      border-style: solid;
      border-width: 0.25rem;
      border-image-slice: 1;
      @if $isWsm {
        background: linear-gradient(
          90deg,
          transparent 0%,
          rgba(76, 27, 122, 0.9) 40%,
          rgba(76, 27, 122, 0.9) 60%,
          transparent 100%
        );

        border-image-source: linear-gradient(
          90deg,
          transparent 0%,
          rgba(249, 186, 39, 0.9) 25%,
          rgba(249, 186, 39, 0.9) 75%,
          transparent 100%
        );
      }
      @if $isSsm {
        background: linear-gradient(
          90deg,
          transparent 0%,
          rgba(76, 27, 122, 0.9) 40%,
          rgba(76, 27, 122, 0.9) 60%,
          transparent 100%
        );

        border-image-source: linear-gradient(
          90deg,
          transparent 0%,
          rgba(249, 186, 39, 0.9) 25%,
          rgba(249, 186, 39, 0.9) 75%,
          transparent 100%
        );
      }
    }

    .packs-about {
      width: 87.5rem;
      height: 2.875rem;
      border-style: solid;
      border-width: 0.125rem;
      border-image-slice: 1;
      bottom: -5rem;
      @if $isWsm {
        background: linear-gradient(
          90deg,
          transparent 0%,
          rgba(237, 191, 71, 0.8) 25%,
          rgba(247, 212, 101, 0.8) 50%,
          rgba(237, 191, 71, 0.8) 75%,
          transparent 100%
        );
        border-image-source: linear-gradient(
          90deg,
          transparent 0%,
          rgba(249, 229, 133, 1) 25%,
          rgba(249, 229, 133, 1) 75%,
          transparent 100%
        );
      }
      @if $isSsm {
        background: linear-gradient(
          to right,
          transparent,
          #edbf47,
          #f7d465,
          rgba(237, 191, 71, 0.98),
          transparent
        );
        border-image-source: linear-gradient(
          90deg,
          transparent 0%,
          #f9e585 25%,
          #f9e585 75%,
          transparent 100%
        );
      }
    }

    .packs-logo {
      width: 57.875rem;
      height: 4.875rem;
      background: url($path-premium + 'gamePass/packs/game-pass-logo.avif') center bottom no-repeat;
      background-size: contain;
    }

    #bags-rewards-btn {
      position: absolute;
      left: 4rem;
      width: 23.813rem;
    }
  }

  & > main,
  & > footer {
    top: 13.5rem;
  }
}
</style>
