<template>
  <menu-component
    menu-type="club-management-menu"
    :title="$t('club.managementMenu')"
    :sub-menu="subMenu"
  >
    <app-button
      v-if="isClubManagerOrAssistant"
      btn-id="club-available-player-button"
      btn-size="md"
      btn-type="primary"
      :btn-text="$t('club.findPlayers')"
      @click="findPlayers"
    />
    <template v-if="isMembersPage" #subMenuExtraContent>
      <div class="update-info absolute">
        <tippy theme="WSM" placement="left" max-width="35rem">
          <app-icon icon-name="info-70" />
          <template #content>
            <div class="tooltip-content text-texts-standard-default text-30">
              <p class="tooltip-content-bottom w-full">
                {{ getTooltipText }}
              </p>
            </div>
          </template>
        </tippy>
      </div>
    </template>
  </menu-component>
</template>

<script lang="ts">
import { CLUB_MEMBERS_UPDATE_TIME } from '@/globalVariables'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { MenuObject } from '@/interfaces/MenuObject'

export default defineComponent({
  name: 'ClubsManagmentMenu',
  computed: {
    ...mapState(useUserStore, {
      isClubManagerOrAssistant: 'isClubManagerOrAssistant',
    }),
    isMembersPage(): boolean {
      return this.$route.name === this.$getWebView('ClubsMembers')
    },
    subMenu(): Record<number, MenuObject> {
      const publicRoutes = {
        1: {
          text: this.$t('club.membersMenu'),
          route: this.$getWebView('ClubsMembers'),
        },
        2: {
          text: this.$t('club.activityMenu'),
          route: this.$getWebView('ClubsActivity'),
        },
      }
      const privateRoutes = {
        3: {
          text: this.$t('club.mailMenu'),
          route: this.$getWebView('ClubsPostMessage'),
        },
        4: {
          text: this.$t('club.requestMenu'),
          route: this.$getWebView('ClubsApplication'),
        },
        5: {
          text: this.$t('club.informationMenu'),
          route: this.$getWebView('ClubsNotice'),
        },
      }
      return this.isClubManagerOrAssistant ? { ...publicRoutes, ...privateRoutes } : publicRoutes
    },
    getTooltipText(): string {
      return this.$replacePlaceholder(
        this.$t('common.dataUpdateTime'),
        '%s',
        CLUB_MEMBERS_UPDATE_TIME.toString(),
      )
    },
  },
  methods: {
    findPlayers(): void {
      this.$router.push({ name: this.$getWebView('ClubsAvailablePlayers') })
    },
  },
})
</script>

<style lang="scss" scoped>
#club-available-player-button {
  display: flex;
}

.update-info {
  right: 1rem;
  top: 0;
}
</style>
