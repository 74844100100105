<template>
  <div id="gp-progress" class="gp-progress app-page-wrapper absolute" data-cy="gp-progress">
    <menu-component
      menu-type="gp-progress"
      title="Grand Prize"
      :menu-items="{
        1: {
          text: $t('grandPrize.warehouse'),
          route: $getWebView('GrandPrizeWarehouse'),
        },
        2: {
          text: $t('grandPrize.progress'),
          route: $getWebView('GrandPrizeProgress'),
        },
        3: {
          text: $t('grandPrize.history'),
          route: $getWebView('GrandPrizeHistory'),
        },
      }"
    />
    <section class="w-full h-full safe-area">
      <main v-if="!isLoading" class="flexing text-texts-standard-default flex-col">
        <section class="my-progress w-full">
          <!-- {{ progress }} -->
          <header class="text-32 uppercase flex justify-between">
            <p class="pt-2">
              <b>{{ $t('grandPrize.yourProgress') }}</b>
            </p>
            <div class="progress-timer-wrapper flex">
              <p class="progress-timer-text not-italic">
                {{ $t('grandPrize.progressResetsIn') }}
              </p>
              <div class="progress-timer">
                <app-timer v-if="timeToReset" :time="timeToReset" @countdown-action="resetValues" />
              </div>
            </div>
          </header>
          <grand-prize-progress-item
            v-for="(progress, i) in myProgress"
            :key="i"
            :progress-data="progress"
          />
        </section>
        <section class="club-progress w-full">
          <!-- klubovy progress -->
          <header v-if="false" class="flex justify-between">
            <p class="text-32 uppercase">
              <b>{{ $t('grandPrize.clubProgress') }}</b>
            </p>
          </header>
          <grand-prize-progress-item
            v-for="(progress, i) in clubProgress"
            :key="i"
            :progress-data="progress"
          />
        </section>
      </main>

      <component-loading :height="'73%'" :is-loading="isLoading" />
    </section>
  </div>
</template>

<script lang="ts">
import GrandPrizeProgressItem from '@/components/GrandPrize/GrandPrizeProgressItem.vue'
import { grandPrizeProgressesEndPoint } from '@/globalVariables'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type {
  GrandPrizeProgressResponse,
  GrandPrizeProgress,
} from '@/interfaces/responses/grandPrize/GrandPrizeApiResponse'
import type { Nullable } from '@/interfaces/utils'
import type { GrandPrizeProgressData } from '@/interfaces/GrandPrize'

const TRAINING_EXP_PROGRESS = 'training_experiences'
const ARENA_RP_PROGRESS = 'arena_ranking_points'
const CHAMPIONSHIP_POINTS_PROGRESS = 'championship_points'
const CLUB_QUEST_PROGRESS = 'club_quest'

const PROGRESSES_MAPPING = {
  [TRAINING_EXP_PROGRESS]: {
    buildingId: 'training_hall',
    texts: {
      title: 'trainingHall.header',
      description: 'common.experiences',
    },
    url: 'TrainingDisciplines',
  },
  [ARENA_RP_PROGRESS]: {
    buildingId: 'arena',
    texts: {
      title: 'arena.header',
      description: 'rankingsGroup.rankingPoints',
    },
    url: 'ArenaView',
  },
  [CHAMPIONSHIP_POINTS_PROGRESS]: {
    buildingId: 'club_championship',
    texts: {
      title: 'common.clubChampionship',
      description: 'common.championshipPoints',
    },
    url: 'ClubsChampionshipCompetitions',
  },
  [CLUB_QUEST_PROGRESS]: {
    buildingId: 'clubhouse',
    texts: {
      title: 'grandPrize.clubQuest',
      description: 'rankingsGroup.rankingPoints',
    },
    url: 'ClubsHouse',
  },
}

interface ComponentData {
  myProgress: GrandPrizeProgressData[]
  clubProgress: GrandPrizeProgressData[]
  timeToReset: Nullable<number>
  isLoading: boolean
}

export default defineComponent({
  name: 'GrandPrizeProgress',
  components: {
    GrandPrizeProgressItem,
  },
  data(): ComponentData {
    return {
      myProgress: [],
      clubProgress: [],
      timeToReset: null,
      isLoading: false,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      hasClub: 'getHasClub',
    }),
  },
  created() {
    this.loadProgresses()
  },
  methods: {
    createTemplate(progress: GrandPrizeProgress): GrandPrizeProgressData {
      const progressCompleted = Number(progress.amount) >= Number(progress.target_value)
      return {
        ...PROGRESSES_MAPPING[progress.name],
        progress: {
          actual: progressCompleted ? progress.target_value : progress.amount,
          goal: progress.target_value,
        },
        gpType: progress.target_grand_prize,
        // TODO zmenit name na 'clubs_championship' aktualny nazov je nepresny
        locked: progress.name === 'championship_points' && !this.hasClub,
        completed: progressCompleted,
      }
    },
    async loadProgresses(): Promise<void> {
      this.isLoading = true
      const CLUB_MEMBERSHIP = [CLUB_QUEST_PROGRESS]
      const CLUBS_QUESTS = [CLUB_QUEST_PROGRESS]
      const response = await this.$axios.get<{}, GrandPrizeProgressResponse>(
        grandPrizeProgressesEndPoint + '/all',
      )
      this.timeToReset = response.remainingTime
      response.progress
        .filter((item: GrandPrizeProgress): boolean => item.name in PROGRESSES_MAPPING)
        .forEach((progress: GrandPrizeProgress): void => {
          if (CLUB_MEMBERSHIP.includes(progress.name) && !this.hasClub) {
            this.isLoading = false
            return
          }
          this.isLoading = false
          const template = this.createTemplate(progress)

          if (CLUBS_QUESTS.includes(progress.name)) {
            this.clubProgress.push(template)
            this.isLoading = false
            return
          }

          this.myProgress.push(template)
        })
    },
    resetValues(): void {
      this.myProgress = []
      this.clubProgress = []
      this.timeToReset = null

      this.loadProgresses()
    },
  },
})
</script>

<style lang="scss" scoped>
.gp-progress {
  main {
    width: 98%;
    margin: 1.625rem auto 0 auto;

    header {
      margin-bottom: 0.5rem;

      .progress-timer {
        width: 17.563rem;

        &-text {
          margin-right: 1.2rem;
        }
      }
    }
  }
}
</style>
