<template>
  <div class="avatar-menu">
    <div class="avatar-menu-content">
      <div
        class="avatar-menu-content-logo"
        :class="{
          'avatar-menu-content-logo-disabled': !isMyClub($router) && boxType !== 'avatar',
        }"
      >
        <avatar-menu-logo :logo-type="boxType" />
      </div>
      <avatar-menu-panel v-if="boxType === 'avatar'" />
      <avatar-menu-panel-club v-else />
      <div class="avatar-menu-content-buttons">
        <avatar-menu-buttons
          :btn-variant="boxType"
          @show-att="$emit('showAtt')"
          @prestige-info="$emit('prestigeInfo')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AvatarMenuButtons from '@/components/AvatarMenu/AvatarMenuButtons.vue'
import AvatarMenuLogo from '@/components/AvatarMenu/AvatarMenuLogo.vue'
import AvatarMenuPanel from '@/components/AvatarMenu/AvatarMenuPanel.vue'
import AvatarMenuPanelClub from '@/components/AvatarMenu/AvatarMenuPanelClub.vue'
import { isMyClub } from '@/helpers'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AvatarMenu',
  components: {
    AvatarMenuLogo,
    AvatarMenuPanel,
    AvatarMenuPanelClub,
    AvatarMenuButtons,
  },
  props: {
    boxType: {
      type: String,
      default: 'avatar',
    },
  },
  emits: ['showAtt', 'prestigeInfo'],
  methods: {
    isMyClub,
  },
})
</script>

<style lang="scss">
// Toto nie je scoped schvalne.
@import '@/assets/styles/components/profileAvatar.scss';
</style>
