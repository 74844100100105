<template>
  <div id="club-invitations" class="club-invitations app-page-wrapper absolute">
    <menu-component menu-type="club-invitations" :title="$t('club.invitations')" />
    <section class="w-full h-full safe-area">
      <div class="club-invitations-content">
        <main>
          <table-component
            :columns="columns"
            :items="invitations"
            :no-data-text="$t('club.noInvitations')"
            force-scroll-size="100%"
            :show-tools="showToolsData"
            which-id="id"
            @button-click="acceptApplication($event)"
            @delete-applications="deleteAllApplications()"
            @delete-cell="deleteApplication($event)"
            @sort-event="orderBy"
          />
        </main>
      </div>
      <popup-window
        v-if="showPopup"
        popup-type="info"
        :popup-title="$t('club.clubIsFull')"
        @close="showPopup = false"
      >
        <div class="club-invitations-full flexing">
          <p class="text-texts-standard-default text-36">
            {{ $t('club.clubIsFullDesc') }}
          </p>
        </div>
        <app-button
          btn-id="club-invitations-full-btn"
          btn-size="xl"
          btn-type="primary"
          :btn-text="$t('button.continue')"
          @click="closePopup"
        />
      </popup-window>
    </section>
  </div>
</template>

<script lang="ts">
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import TableComponent from '@/components/Table/TableComponent.vue'
import { clubTableKeys } from '@/components/Table/tableKeys'
import { getOrderBodyContent } from '@/helpers'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useInvitationStore } from '@/store/pinia/clubs/invitationsStore'
import { useCountriesStore } from '@/store/pinia/countriesStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { BodyContent } from '@/helpers'
import type { ClubsColumnData } from '@/interfaces/clubs/Clubs'
import type { NameValueString } from '@/interfaces/Global'
import type Country from '@/interfaces/Country'
import type { ClubInvitation } from '@/interfaces/clubs/ClubInvitations'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'

interface ToolsData {
  info: boolean
  button: boolean
  btnDisabled: boolean
}
interface ComponentData {
  clubTableKeys: typeof clubTableKeys
  showPopup: boolean
}

export default defineComponent({
  name: 'ClubsInvitations',
  components: {
    TableComponent,
    PopupWindow,
  },
  data(): ComponentData {
    return {
      clubTableKeys,
      showPopup: false,
    }
  },
  computed: {
    // TODO: Vsetci vzdy formatuju country aj po stiahnuti zo stavu prosim ak niekto bude cas robno prehodte na naformatovane country tieto veci
    ...mapState(useCountriesStore, {
      countries: 'getCountries',
    }),
    ...mapState(useInvitationStore, {
      invitations: 'getInvitations',
      quarantineEnd: 'getQuarantineEnd',
      isClubFull: 'getIsClubFull',
    }),
    formatedCountries(): Country[] {
      const data = []
      Object.entries(this.countries?.country).forEach(([key, value]) => {
        data.push({
          value: key,
          name: value,
          icon: `flags/102x68/${key}.avif`,
          abbr: key,
        })
      })
      return data
    },
    allCountries(): NameValueString {
      return { value: 'all', name: this.$t('club.allCountries') }
    },
    showToolsData(): ToolsData {
      return {
        info: true,
        button: true,
        btnDisabled: this.invitations.length <= 0,
      }
    },
    btnDisabledType(): { type: string; content: number } {
      if (this.quarantineEnd > 0) {
        return { type: 'quarantine', content: this.quarantineEnd }
      }
      return null
    },
    columns(): ClubsColumnData[] {
      return [
        // if it's icon pass to label icon name and set isIcon to true
        { label: this.$t('settings.country'), key: clubTableKeys.country, width: 10 },
        {
          label: this.$t('common.name'),
          key: clubTableKeys.clubNameComponent,
          align: 'left',
          width: 30,
          avatar: true,
        },
        { label: this.$t('club.clubPlayers'), key: clubTableKeys.members },
        {
          label: 'prestige',
          key: clubTableKeys.prestige,
          isIcon: true,
          width: 10,
        },
        { label: this.$t('common.type'), key: clubTableKeys.type },
        {
          label: '',
          key: clubTableKeys.button,
          buttonData: {
            type: 'secondary',
            size: 'md',
            text: 'club.acceptInvitations',
            class: 'cell-narrower-button',
            disabled: !!this.btnDisabledType,
          },
          width: 15,
        },
        { label: '', key: clubTableKeys.delete, width: 5 },
      ]
    },
  },
  created(): void {
    this.loadInvitations()
  },
  methods: {
    getOrderBodyContent,
    ...mapActions(useUserStore, ['loadUserData']),
    ...mapActions(useInvitationStore, {
      loadInvitations: 'loadInvitations',
      declineInvitation: 'declineInvitation',
      declineAllInvitations: 'declineAllInvitations',
      acceptInvitation: 'acceptInvitation',
    }),
    ...mapActions(useClubStore, {
      setJoinedToClub: 'setJoinedToClub',
    }),
    ...mapActions(useResponseTaskStore, {
      loadNotifications: 'loadNotifications',
    }),
    ...mapActions(usePhaserGameIntegrationStore, ['setClubChampionshipLabelInfo']),
    deleteAllApplications(): void {
      this.declineAllInvitations()
      this.loadNotifications()
    },
    async deleteApplication(data: { e: string; index: number }): Promise<void> {
      await this.declineInvitation(data.e)
      document.getElementById(`table-component-row-${data.index}`).remove()
      this.loadInvitations()
      this.loadNotifications()
    },
    async acceptApplication(id: string): Promise<void> {
      // Message o prijatí do klubu plus redirect na Kluby

      const selectedClub = this.invitations.find(
        (invitation: ClubInvitation): boolean => invitation.id === id,
      )

      if (selectedClub.clubCapacity <= selectedClub.membersCount) {
        // ak je klub plny
        this.showPopup = true
      } else {
        await this.acceptInvitation(id)
        if (this.isClubFull) this.showPopup = true
        else {
          this.loadInvitations()
          await this.loadUserData()
          this.setJoinedToClub()
          this.setClubChampionshipLabelInfo()
          this.$router.push({ name: this.$getWebView('ClubsMembers') })
        }
      }
    },
    orderBy(order: BodyContent): void {
      const bodyContent = this.getOrderBodyContent(order)
      this.loadInvitations(bodyContent)
    },
    closePopup(): void {
      this.showPopup = false
      this.loadInvitations({ no_cache: true })
    },
  },
})
</script>

<style lang="scss" scoped>
.club-invitations {
  &-full {
    width: 50rem;
    margin: 0 auto;
    padding: 3rem;
  }
}

#club-invitations-full-btn {
  margin: 0 auto;
}
</style>
