import { authApiUrl } from '@/globalVariables'
import {
  gameToken,
  getValidationToken,
  manageGameState,
  logoutUser,
  sendToFlutter,
} from '@/helpers'
import { loadTextsFromSessionStorage } from '@/plugins/getTexts'
import axios, { type AxiosRequestConfig } from 'axios'
import type { App, Plugin } from 'vue'
// Full config:  https://github.com/axios/axios#request-config

import type { Router } from 'vue-router'
import type { CoreStore } from '@/store/pinia/coreStore'
import type { TextsStore } from '@/store/pinia/textStore'
import { useUserStore } from '@/store/pinia/userStore'
import type { ResponseTaskStore } from '@/store/pinia/responseTaskStore'
import type { TutorialStore } from '@/store/pinia/tutorialStore'
import type { GameState } from '@/store/pinia/gameStateStore'
import { isMobile } from './isMobile'
import { ApiService } from '@/services/ApiService'
interface AxiosConfigInterface {
  baseURL: string
}

const config: AxiosRequestConfig<AxiosConfigInterface> = {
  baseURL: authApiUrl + 'api/',
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

let versionLoading = false

interface _Cache {
  router: Router | null
  coreStore: CoreStore | null
  textsStore: TextsStore | null
  responseTaskStore: ResponseTaskStore | null
  tutorialStore: TutorialStore | null
}

const _cache: _Cache = {
  router: null,
  coreStore: null,
  textsStore: null,
  responseTaskStore: null,
  tutorialStore: null,
}

const useRouter = async (): Promise<Router> => {
  if (!_cache.router) {
    const { default: router } = await import('@/router')
    _cache.router = router
  }
  return _cache.router
}

const useCoreStore = async (): Promise<CoreStore> => {
  if (!_cache.coreStore) {
    const { useCoreStore } = await import('@/store/pinia/coreStore')
    _cache.coreStore = useCoreStore()
  }
  return _cache.coreStore
}

const useTextsStore = async (): Promise<TextsStore> => {
  if (!_cache.textsStore) {
    const { useTextsStore } = await import('@/store/pinia/textStore')
    _cache.textsStore = useTextsStore()
  }
  return _cache.textsStore
}

const useResponseTaskStore = async (): Promise<ResponseTaskStore> => {
  if (!_cache.responseTaskStore) {
    const { useResponseTaskStore } = await import('@/store/pinia/responseTaskStore')
    _cache.responseTaskStore = useResponseTaskStore()
  }
  return _cache.responseTaskStore
}

const useTutorialStore = async (): Promise<TutorialStore> => {
  if (!_cache.tutorialStore) {
    const { useTutorialStore } = await import('@/store/pinia/tutorialStore')
    _cache.tutorialStore = useTutorialStore()
  }
  return _cache.tutorialStore
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    const accessToken = getValidationToken('token')
    config.headers.Authorization = 'Bearer ' + accessToken
    config.headers.common['PPS-Game-Token'] = gameToken

    return config
  },
  async function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

let canLogOut = true

// Add a response interceptor
_axios.interceptors.response.use(
  async function (response) {
    const router = await useRouter()
    const textsStore = await useTextsStore()

    const tokenExpiration = response.headers['access-token-expiration']
    const appVersionInHeader = response.headers['app-version']
    const gameStateInHeader = response.headers['game-state'] as GameState
    const gameReadyAtInHeader = response.headers['game-ready-at'] as GameState
    const feVersionInHeader = response.headers['app-version-fe']

    manageGameState(gameStateInHeader, gameReadyAtInHeader, router)

    const appVersionInSS = sessionStorage.getItem('appVersion')
    const feVersionInSS = sessionStorage.getItem('feVersion')
    const tokenExpirationinLS = localStorage.getItem('tokenExpiration')
    if (!tokenExpirationinLS && tokenExpiration) {
      localStorage.setItem('tokenExpiration', tokenExpiration)
    }

    if (!appVersionInSS) {
      sessionStorage.setItem('appVersion', appVersionInHeader)
    }
    if (
      appVersionInHeader &&
      appVersionInSS &&
      appVersionInSS !== appVersionInHeader &&
      !versionLoading
    ) {
      versionLoading = true
      if (sessionStorage.getItem('appVersion') !== appVersionInHeader) {
        sessionStorage.setItem('appVersion', appVersionInHeader)
        textsStore.setLoadedTexts(false)
        sessionStorage.removeItem('texts')
        if (useUserStore().getHasToken) {
          await useUserStore().loadUserData()
        }
        await loadTextsFromSessionStorage()
      }
      versionLoading = false
    }
    if (feVersionInHeader && (!feVersionInSS || feVersionInSS != feVersionInHeader)) {
      sessionStorage.setItem('feVersion', feVersionInHeader)
      textsStore.setLoadedTexts(false)
      sessionStorage.removeItem('texts')
      if (useUserStore().getHasToken) {
        await useUserStore().loadUserData()
      }
      await loadTextsFromSessionStorage()

      sendToFlutter(
        JSON.stringify({
          event: 'reloadPacks',
        }),
      )

      window.location.reload()
    }
    // Do something with response data
    if (Object.keys(response.data.tasks).length) {
      const responseTaskStore = await useResponseTaskStore()
      responseTaskStore.setTasks(response.data.tasks)
    }

    return response.data.data
  },
  async function (error) {
    const coreStore = await useCoreStore()

    // Do something with response error
    if (!error) {
      return
    }

    const responseTaskStore = await useResponseTaskStore()
    responseTaskStore.hidePaymentLoading()

    if (error.response?.data?.error === 'Unauthorized') {
      if (!canLogOut) return

      if (isMobile())
        sendToFlutter(
          JSON.stringify({
            event: 'disconnect',
          }),
        )
      logoutUser()
    } else if (error.response?.data?.error === 'Parent actions are not done yet!') {
      const tutorialStore = await useTutorialStore()
      tutorialStore.loadTutorial()
    } else {
      canLogOut = false
      coreStore.addAxiosError(error.response?.data?.error)
      await coreStore.postError({
        type: 'ajax-error',
        message: String(error.response?.data?.error),
      })
      canLogOut = true
    }
    console.error(error)

    return Promise.reject(error)
  },
)

export const internalAxios = _axios

const axiosPlugin: Plugin = {
  install: (app: App): void => {
    app.config.globalProperties.$axios = _axios
    app.config.globalProperties.$http = ApiService
  },
}

export default axiosPlugin
