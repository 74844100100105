<template>
  <div id="club-edit" class="club app-page-wrapper absolute">
    <menu-component menu-type="club-settings" :title="$t('club.clubSettings')" />
    <section class="w-full h-full safe-area">
      <main class="club-wrapper w-full">
        <app-scrollbar scroll="y" slide="y" width="100%" height="100%" class="club-settings-scroll">
          <clubs-info-form
            :is-delete-club-visible="true"
            :form-id="'editClubForm'"
            @submit="editClub"
            @scroll-to-error="scrollToTop"
          />

          <div class="flexing club-wrapper-button">
            <app-button
              btn-id="btn-save-changes"
              btn-type="secondary"
              :btn-text="$t('profile.saveChanges')"
              type="submit"
              form="editClubForm"
            />
          </div>
        </app-scrollbar>
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import ClubsInfoForm from '@/components/Club/ClubsInfoForm.vue'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { ClubInfo } from '@/types/clubStoreTypes'

export default defineComponent({
  name: 'ClubsEditClub',
  components: {
    ClubsInfoForm,
  },
  computed: {
    ...mapState(useUserStore, {
      myClubId: 'getPlayerClubId',
    }),
  },
  methods: {
    ...mapActions(useClubStore, {
      editClubAction: 'editClub',
    }),
    editClub(club: ClubInfo): void {
      this.editClubAction(club).then(() => {
        this.$router.push({
          name: 'ClubsMap',
          params: { clubId: this.myClubId },
        })
      })
    },
    scrollToTop(): void {
      const scroll = document.querySelector('.club-settings-scroll')
      scroll.scrollTop = 0
    },
  },
})
</script>

<style lang="scss" scoped>
.club-wrapper {
  width: 97%;
  height: 82%;
  margin: 0 auto;

  &-button {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
}
</style>
