import { careerListOfUnlocksEndpoint } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'

import type { ListOfUnlocksApiResponse } from '@/interfaces/responses/career/ListOfUnlocksApiResponse'
import type { CareerUnlockItem } from '@/interfaces/CareerUnlockItem'

interface ListOfUnlocksStoreState {
  isLoading: boolean
  listOfUnlocks: CareerUnlockItem[]
}

export const useListOfUnlocksStore = defineStore('listOfUnlockStore', {
  state: (): ListOfUnlocksStoreState => ({
    isLoading: false,
    listOfUnlocks: [] as CareerUnlockItem[],
  }),
  getters: {
    getListOfUnlocks: (state: ListOfUnlocksStoreState): ListOfUnlocksStoreState['listOfUnlocks'] =>
      state.listOfUnlocks,
  },
  actions: {
    async loadListOfUnlocks(careerId: string = ''): Promise<void> {
      this.isLoading = true
      const response = await internalAxios.get<{}, ListOfUnlocksApiResponse[]>(
        `${careerListOfUnlocksEndpoint}/${careerId}`,
      )

      const data = response.map((item: ListOfUnlocksApiResponse): CareerUnlockItem => {
        return {
          disciplineId: item.discipline_id,
          questOrder: item.quest_order,
          branchType: item.branch_type,
          image: item.unlocks.unlock_text,
          isClaimed: item.claimed,
          unlocks: {
            text: item.unlocks.unlock_text,
            placeholder: item.unlocks?.unlock_text_placeholder ?? null,

            // ONLY IF UNLOCK IS EQUIPMENT
            equipmentCategory: item.unlocks?.unlock_equipment_gdd_id ?? null,
            equipmentLevel: item.unlocks?.unlock_equipment_level ?? null,
          },
        }
      })

      this.isLoading = false
      this.listOfUnlocks = data
    },
  },
})
