import {
  CLUB_ACCEPTED_INVITATION,
  CLUB_MEMBER,
  CLUB_MEMBER_NOTIFICATION_UPDATED_ROLE,
  clubMembersNotifications,
} from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { useUserStore } from '@/store/pinia/userStore'
import { defineStore } from 'pinia'

export interface Notification {
  id: string
  name: string
  value: string
}

export interface NotificationStoreState {
  notifications: Notification[]
}

export const useNotificationStore = defineStore('useNotificationStore', {
  state: (): NotificationStoreState => ({
    notifications: [],
  }),
  getters: {
    getNotifications(): Notification[] {
      return this.notifications
    },
    getPromotedRole(): Notification['value'] {
      return this.notifications?.find(
        (notification: Notification): boolean =>
          notification.name === CLUB_MEMBER_NOTIFICATION_UPDATED_ROLE,
      )?.value
    },
    getInvitationAccepted(): boolean {
      return (
        !!this.notifications?.find(
          (notification: Notification): boolean => notification.name === CLUB_ACCEPTED_INVITATION,
        ) || false
      )
    },
  },
  actions: {
    async loadNotifications(): Promise<void> {
      const notifications = await internalAxios.get<{}, Notification[]>(clubMembersNotifications)

      notifications.forEach((notification: Notification): void => {
        if (
          !this.notifications.some(
            (stateNotification: Notification): boolean =>
              stateNotification.name !== notification.name,
          )
        ) {
          if (
            !(notification?.name === CLUB_MEMBER_NOTIFICATION_UPDATED_ROLE) &&
            !(notification?.value === CLUB_MEMBER)
          )
            this.notifications.push(notification)
          if (notification.name === CLUB_MEMBER_NOTIFICATION_UPDATED_ROLE) {
            const useUser = useUserStore()
            useUser.loadUserData()
          }
        }
      })
    },
    removeUpdatedRoleNotification(): void {
      const notificationsWithoutUpdatedRole = this.notifications.filter(
        (notification: Notification): boolean =>
          notification.name !== CLUB_MEMBER_NOTIFICATION_UPDATED_ROLE,
      )
      this.notifications = notificationsWithoutUpdatedRole
    },
  },
})
