import { useCoreStore } from '@/store/pinia/coreStore'
import { SCENES_KEYS } from '../constants'
import CustomCoreMapScene from './parentClass/CustomCoreMapScene'

export default class ClubsMapScene extends CustomCoreMapScene {
  constructor() {
    super(SCENES_KEYS.ClubsMapScene)
  }

  create() {
    this.runInitMethods()
  }

  private async runInitMethods(): Promise<void> {
    await this.setUpCommonSceneFunctionality()

    const useCore = useCoreStore()
    useCore.setLoadingScreen(false)
  }
}
