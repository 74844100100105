<template>
  <div v-if="timeToEnd" class="eq-item-timer">
    <app-timer :time="timeToEnd" @countdown-action="$emit('finishOperation')" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EquipmentItemTimer',
  props: {
    timeToEnd: {
      type: Number,
      required: true,
    },
  },
  emits: ['finishOperation'],
})
</script>

<style lang="scss" scoped>
.eq-item-timer {
  width: 14rem;
}
</style>
