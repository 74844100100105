import { seasonEndEndpoint } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import type { SeasonEndRewardSource, SeasonReward, SeasonEnd } from '@/interfaces/Seasons'

import type SeasonEndResponse from '@/interfaces/responses/season/SeasonEndResponse'
interface SeasonEndState {
  seasonEnd: SeasonEnd
}

export const useSeasonEndStore = defineStore('seasonEndStore', {
  state: (): SeasonEndState => ({
    seasonEnd: {
      season: '',
      seasonYear: '',
      seasonMonth: '',
      previousSeasonYear: '',
      previousSeasonMonth: '',
      remainingTime: null,
      seasonTexts: [],
      seasonalRp: 0,
      rewardsSources: [],
      rewards: [],
      currentArenaLevel: 0,
      previousArenaLevel: 0,
    },
  }),
  getters: {
    getSeasonEndStats(): SeasonEndState['seasonEnd'] {
      return this.seasonEnd
    },
  },
  actions: {
    async loadSeasonEndStats(): Promise<void> {
      const response = await internalAxios.get<{}, SeasonEndResponse>(seasonEndEndpoint)
      if (!response || Object.keys(response).length === 0) return

      this.seasonEnd = {
        season: response.season,
        seasonYear: response.seasonYear,
        seasonMonth: response.seasonMonth,
        previousSeasonYear: response.previousSeasonYear,
        previousSeasonMonth: response.previousSeasonMonth,
        remainingTime: response?.remainingTime ?? null,
        seasonTexts: response.seasonTexts,
        seasonalRp: response.seasonalRp,
        currentArenaLevel: response.currentArenaLevel,
        previousArenaLevel: response.previousArenaLevel,
        rewardsSources:
          response.rewardsSources?.map((source: SeasonEndRewardSource): SeasonEndRewardSource => {
            return {
              type: source.type,
              topValue: source.topValue,
            }
          }) ?? [],
        rewards: response.rewards?.map((reward: SeasonReward): SeasonReward => {
          return {
            amount: reward.amount,
            name: reward.name,
            type: reward.type,
            rarity: reward.rarity,
          }
        }),
      }
    },
  },
})
