import { cookiePluginUrl, cookiesLogEndpoint } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'

const DOMAIN = import.meta.env.VITE_SUPPORT_EMAIL.split('@').pop()

export const addCookieConsent = (): void => {
  const script = document.createElement('script')
  script.setAttribute('src', `${cookiePluginUrl}.js`)
  script.setAttribute('defer', '')
  document.body.appendChild(script)

  cookieConsentInit(script)
}

export const cookieConsentInit = (script: HTMLScriptElement): void => {
  const navigatorLanguage = window.navigator.language

  const language = sessionStorage.getItem('language') ?? navigatorLanguage.substring(0, 2)

  const currentLang = language === 'sk' || language === 'cs' ? language : 'en'
  script.onload = () => {
    // obtain plugin
    // eslint-disable-next-line no-undef
    const cc = initCookieConsent()

    if (!cc) return

    // run plugin with your configuration
    cc.run({
      current_lang: currentLang,
      autoclear_cookies: true, // default: false
      page_scripts: true, // default: false

      // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
      // delay: 0,                               // default: 0
      // auto_language: '',                      // default: null; could also be 'browser' or 'document'
      // autorun: true,                          // default: true
      // force_consent: false,                   // default: false
      //hide_from_bots: true,                    // default: true
      // remove_cookie_tables: false             // default: false
      // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
      cookie_expiration: 365, // default: 182 (days)
      cookie_necessary_only_expiration: 182, // default: disabled
      // cookie_domain: location.hostname,       // default: current domain
      // cookie_path: '/',                       // default: root
      // cookie_same_site: 'Lax',                // default: 'Lax'
      // use_rfc_cookie: false,                  // default: false
      revision: 0, // default: 0

      gui_options: {
        consent_modal: {
          layout: 'cloud', // box/cloud/bar
          position: 'bottom center', // bottom/middle/top + left/right/center
          transition: 'slide', // zoom/slide
          swap_buttons: false, // enable to invert buttons
        },
        settings_modal: {
          layout: 'box', // box/bar
          // position: 'left',           // left/right
          transition: 'slide', // zoom/slide
        },
      },
      onFirstAction: function (
        user_preferences: UserPreferences,
        cookie: SavedCookieContent,
      ): void {
        // callback triggered only once on the first accept/reject action
        internalAxios.post<{}, true>(cookiesLogEndpoint, {
          user_preferences,
          cookie,
          action: 'first',
        })
        eraseCookies()
      },

      onAccept: function (): void {
        // callback triggered on the first accept/reject action, and after each page load
        eraseCookies()
      },

      onChange: function (cookie: SavedCookieContent, changed_categories: string[]): void {
        // callback triggered when user changes preferences after consent has already been given
        internalAxios.post<{}, true>(cookiesLogEndpoint, {
          changed_categories,
          cookie,
          action: 'change',
        })
        eraseCookies()
      },

      languages: {
        sk: {
          consent_modal: {
            title: 'Cookies nám pomáhajú vylepšovať hru',
            description:
              'Táto webová stránka používa súbory cookies. Okrem nevyhnutných funkčných cookies používame aj výkonnostné a cielené cookies, ktoré nám pomáhajú optimalizovať pre vás hru a zobrazovať vám relevantné reklamy. Niektoré údaje zdieľame aj s tretími stranami.Svojim súhlasom s používaním všetkých typov cookies nám pomôžete priniesť vám najlepší zážitok z hry. Viac informácií sa dozviete <a href ="https://www.powerplay.studio/privacy-policy" target="_blank">tu</a>.',
            primary_btn: {
              text: 'Súhlasím',
              role: 'accept_all', // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: 'Nastavenia',
              role: 'settings', // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: 'Nastavenia cookies',
            save_settings_btn: 'Uložiť nastavenia',
            accept_all_btn: 'Prijať všetko',
            reject_all_btn: 'Odmietnuť všetko',
            close_btn_label: 'Close',

            cookie_table_headers: [
              { col1: 'Názov' },
              { col2: 'Doména' },
              { col3: 'Expirácia' },
              { col4: 'Popis' },
            ],
            blocks: [
              {
                title: 'Používanie cookies',
                description:
                  'Cookies sú malé množstvá informácií vo forme textových súborov, ktoré hra ukladá na zariadení hráča. Vďaka niektorým funguje naša stránka, no okrem nevyhnutných funkčných cookies používame aj výkonnostné a cielené cookies, ktoré nám pomáhajú zistiť, ako hra funguje, hru pre vás vylepšiť a zobrazovať vám relevantné reklamy. Niektoré údaje zdieľame aj s tretími stranami a pomohlo by nám, ak by sme mohli používať všetky tieto súbory cookies. Viac informácií sa dozviete <a href ="https://www.powerplay.studio/privacy-policy" target="_blank">tu</a>.<br><br>Tu si môžete upraviť výber používaných cookies. Výber môžete kedykoľvek zmeniť kliknutím na odkaz Nastavenia cookies v pätičke webovej stránky.',
              },
              {
                title: 'Nevyhnutné cookies',
                description:
                  'Nevyhnutné cookies nie je možné deaktivovať. Slúžia na správne a bezpečné fungovanie hry.',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              },
              {
                title: 'Výkonnostné cookies',
                description:
                  'Tieto cookies nám pomáhajú merať výkon webovej stránky, efektivitu jej používania a efektivitu marketingových kampaní. Bez týchto cookies nevieme hru optimalizovať a prinášať najlepší zážitok z hry.',
                toggle: {
                  value: 'analytics', // your cookie category
                  enabled: false,
                  readonly: false,
                },
                cookie_table: cookiesObject.analytics.sk,
              },
              {
                title: 'Cielené cookies',
                description:
                  'Vďaka týmto cookies vám my a naši partneri vieme priniesť relevantný obsah a reklamy, ktorý sa zobrazuje nielen na našich stránkach, ale aj na stránkach tretích strán. Bez týchto cookies vám nebudeme môcť ponúkať obsah a reklamy prispôsobené vašim potrebám a záujmom.',
                toggle: {
                  value: 'targeting',
                  enabled: false,
                  readonly: false,
                },
                cookie_table: cookiesObject.targeting.sk,
              },
            ],
          },
        },
        cs: {
          consent_modal: {
            title: 'Cookies nám pomáhají vylepšovat hru',
            description:
              'Tato webová stránka používá soubory cookies. Kromě nezbytných funkčních cookies používáme také výkonnostní a cílené cookies, které nám pomáhají optimalizovat pro vás hru a zobrazovat vám relevantní reklamy. Některé údaje sdílíme i s třetími stranami.Svým souhlasem s používáním všech typů cookies nám pomůžete přinést vám nejlepší zážitek ze hry. Více informací se dozvíte <a href ="https://www.powerplay.studio/privacy-policy" target="_blank">zde</a>.',
            primary_btn: {
              text: 'Souhlasím',
              role: 'accept_all', // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: 'Nastavení',
              role: 'settings', // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: 'Nastavení cookies',
            save_settings_btn: 'Uložit nastavení',
            accept_all_btn: 'Přijmout vše',
            reject_all_btn: 'Odmítnout vše',
            close_btn_label: 'Close',

            cookie_table_headers: [
              { col1: 'Název' },
              { col2: 'Doména' },
              { col3: 'Expirace' },
              { col4: 'Popis' },
            ],
            blocks: [
              {
                title: 'Používání cookies',
                description:
                  'Cookies jsou malá množství informací ve formě textových souborů, které hra ukládá na zařízení hráče. Díky některým funguje naše stránka, ale kromě nezbytných funkčních cookies používáme také výkonnostní a cílené cookies, které nám pomáhají zjistit, jak hra funguje, hru pro vás vylepšit a zobrazovat vám relevantní reklamy. Některé údaje sdílíme také se třetími stranami a pomohlo by nám, kdybychom mohli používat všechny tyto soubory cookies. Více informací se dozvíte <a href ="https://www.powerplay.studio/privacy-policy" target="_blank">zde</a>.<br><br>Zde si můžete upravit výběr používaných cookies. Výběr můžete kdykoli změnit kliknutím na odkaz Nastavení cookies v patičce webové stránky.',
              },
              {
                title: 'Nezbytné cookies',
                description:
                  'Nezbytné cookies nelze deaktivovat. Slouží ke správnému a bezpečnému fungování hry.',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              },
              {
                title: 'Výkonnostní cookies',
                description:
                  'Tyto cookies nám pomáhají měřit výkon webové stránky, efektivitu jejího používání a efektivitu marketingových kampaní. Bez těchto cookies neumíme hru optimalizovat a přinášet nejlepší zážitek ze hry.',
                toggle: {
                  value: 'analytics', // your cookie category
                  enabled: false,
                  readonly: false,
                },
                cookie_table: cookiesObject.analytics.cs,
              },
              {
                title: 'Cílené cookies',
                description:
                  'Díky těmto cookies vám my a naši partneři umíme přinést relevantní obsah a reklamy, který se zobrazuje nejen na našich stránkách, ale také na stránkách třetích stran. Bez těchto cookies vám nebudeme moci nabízet obsah a reklamy přizpůsobené vašim potřebám a zájmům.',
                toggle: {
                  value: 'targeting',
                  enabled: false,
                  readonly: false,
                },
                cookie_table: cookiesObject.targeting.cs,
              },
            ],
          },
        },
        en: {
          consent_modal: {
            title: 'Cookies help us improve the game',
            description:
              'This website uses cookies. In addition to the necessary functional cookies we also use performance and targeting cookies, which help us optimize the game for your use and display relevant ads. Some data is shared with third parties.<br>By giving your consent with the usage of all types of cookies, you help us bring you the best game experience. Click <a href ="https://www.powerplay.studio/privacy-policy" target="_blank">here</a> for more information.',
            primary_btn: {
              text: 'Accept',
              role: 'accept_all', // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: 'Settings',
              role: 'settings', // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: 'Cookies settings',
            save_settings_btn: 'Save settings',
            accept_all_btn: 'Accept all',
            reject_all_btn: 'Reject all',
            close_btn_label: 'Close',

            cookie_table_headers: [
              { col1: 'Name' },
              { col2: 'Domain' },
              { col3: 'Expiration' },
              { col4: 'Description' },
            ],
            blocks: [
              {
                title: 'Cookies usage',
                description:
                  'Cookies are small amounts of data in the form of text files which the game stores on the user’s device. Some cookies make it possible for the website to function, but in addition to the necessary functional cookies we also use performance and targeting cookies, which help us ascertain how the game is working, improve the game and display relevant ads. Some data are shared with third parties and it would be helpful if we could use all these cookies. Click <a href ="https://www.powerplay.studio/privacy-policy" target="_blank">here</a> for more information.<br><br>This is where you can manage the cookies that are used. You can change the selection at any time by clicking the Cookies settings in the footer of the page.',
              },
              {
                title: 'Strictly necessary cookies',
                description:
                  'Strictly necessary cookies cannot be disabled. They are used for correct and safe functioning of the game.',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              },
              {
                title: 'Performance cookies',
                description:
                  'These cookies help us measure the performance of the website, efficiency of its usage and efficiency of the marketing campaigns. Without these cookies we are unable to optimize the game and to bring the best game experience.',
                toggle: {
                  value: 'analytics', // your cookie category
                  enabled: false,
                  readonly: false,
                },
                cookie_table: cookiesObject.analytics.en,
              },
              {
                title: 'Targeting cookies',
                description:
                  'Thanks to these cookies we and our partners can bring you relevant content and ads, which appear not only on our site, but also on third party sites. Without these cookies we will not be able to offer you content and advertising tailored to your needs and preferences.',
                toggle: {
                  value: 'targeting',
                  enabled: false,
                  readonly: false,
                },
                cookie_table: cookiesObject.targeting.en,
              },
            ],
          },
        },
      },
    })

    window.cookieConsent = cc
  }
}

const cookiesObject = {
  analytics: {
    sk: [
      {
        col1: '^_ga', // match all cookies starting with "_ga"
        col2: `.${DOMAIN}`,
        col3: '2 roky',
        col4: 'Tento názov súboru cookie je priradený ku Google Universal Analytics – čo je významná aktualizácia bežnejšie používanej analytickej služby Google. Tento súbor cookie sa používa na rozlíšenie jedinečných používateľov priradením náhodne vygenerovaného čísla ako identifikátora klienta. Je zahrnutý v každej požiadavke na stránku a používa sa na výpočet údajov o návštevníkoch, reláciách a kampaniach pre analytické prehľady stránok.',
      },
    ],
    cs: [
      {
        col1: '^_ga', // match all cookies starting with "_ga"
        col2: `.${DOMAIN}`,
        col3: '2 roky',
        col4: 'Tento název souboru cookie je přiřazen ke Google Universal Analytics – což je významná aktualizace běžněji používané analytické služby Google. Tento soubor cookie se používá k rozlišení jedinečných uživatelů přiřazením náhodně vygenerovaného čísla jako identifikátoru klienta. Je zahrnut v každém požadavku na stránku a používá se pro výpočet údajů o návštěvnících, relacích a kampaních pro analytické přehledy stránek.',
      },
    ],
    en: [
      {
        col1: '^_ga', // match all cookies starting with "_ga"
        col2: `.${DOMAIN}`,
        col3: '2 years',
        col4: "This cookie name is associated with Google Universal Analytics - which is a significant update to Google's more commonly used analytics service. This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier. It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports.",
      },
    ],
  },
  targeting: {
    sk: [
      // list of all expected cookies
      {
        col1: 'VISITOR_INFO1_LIVE',
        col2: '.youtube.com',
        col3: '6 mesiacov',
        col4: 'Tento súbor cookie nastavuje spoločnosť Youtube na sledovanie preferencií používateľov pre videá YouTube vložené do stránok; môže tiež určiť, či návštevník webovej stránky používa novú alebo starú verziu rozhrania YouTube.',
      },
      {
        col1: 'IDE',
        col2: '.doubleclick.net',
        col3: '1 rok',
        col4: 'Tento súbor cookie je nastavený spoločnosťou Doubleclick a poskytuje informácie o tom, ako koncový používateľ používa webovú stránku, a o reklamách, ktoré koncový používateľ mohol vidieť pred návštevou uvedenej webovej stránky.',
      },
      {
        col1: 'test_cookie',
        col2: '.doubleclick.net',
        col3: '15 minút',
        col4: 'Tento súbor cookie nastavuje spoločnosť DoubleClick (ktorá je vo vlastníctve spoločnosti Google), aby zistila, či prehliadač návštevníka webovej stránky podporuje súbory cookie.',
      },
      {
        col1: 'YSC',
        col2: '.youtube.com',
        col3: 'Relácia',
        col4: 'Tento súbor cookie je nastavený službou YouTube na sledovanie prehratí vložených videí.',
      },
      {
        col1: '_gcl_au',
        col2: `.${DOMAIN}`,
        col3: '3 mesiace',
        col4: 'Služba Google AdSense používa tento súbor na experimentovanie s efektivitou reklám na webstránkach, ktoré využívajú ich služby.',
      },
      {
        col1: '_fbp',
        col2: `.${DOMAIN}`,
        col3: '3 mesiace',
        col4: 'Spoločnosť Meta používa tento súbor na dodanie série reklamných produktov ako napríklad aukcie tretích strán v reálnom čase.',
      },
    ],
    cs: [
      // list of all expected cookies
      {
        col1: 'VISITOR_INFO1_LIVE',
        col2: '.youtube.com',
        col3: '6 měsíců',
        col4: 'Tento soubor cookie nastavuje společnost Youtube pro sledování preferencí uživatelů pro videa YouTube vložená do stránek; může také určit, zda návštěvník webové stránky používá novou nebo starou verzi rozhraní YouTube.',
      },
      {
        col1: 'IDE',
        col2: '.doubleclick.net',
        col3: '1 rok',
        col4: 'Tento soubor cookie je nastaven společností Doubleclick a poskytuje informace o tom, jak koncový uživatel používá webovou stránku, a o reklamách, které koncový uživatel mohl vidět před návštěvou uvedené webové stránky.',
      },
      {
        col1: 'test_cookie',
        col2: '.doubleclick.net',
        col3: '15 minut',
        col4: 'Tento soubor cookie nastavuje společnost DoubleClick (která je ve vlastnictví společnosti Google), aby zjistila, zda prohlížeč návštěvníka webové stránky podporuje soubory cookie.',
      },
      {
        col1: 'YSC',
        col2: '.youtube.com',
        col3: 'Relace',
        col4: 'Tento soubor cookie je nastaven službou YouTube pro sledování přehrání vložených videí.',
      },
      {
        col1: '_gcl_au',
        col2: `.${DOMAIN}`,
        col3: '3 měsíce',
        col4: 'Služba Google AdSense používá tento soubor na experimentování s efektivitou reklam na webových stránkách, které využívají jejich služby.',
      },
      {
        col1: '_fbp',
        col2: `.${DOMAIN}`,
        col3: '3 měsíce',
        col4: 'Společnost Meta používá tento soubor na dodání série reklamních produktů jako například aukce třetích stran v reálném čase.',
      },
    ],
    en: [
      // list of all expected cookies
      {
        col1: 'VISITOR_INFO1_LIVE',
        col2: '.youtube.com',
        col3: '6 months',
        col4: 'This cookie is set by Youtube to keep track of user preferences for Youtube videos embedded in sites;it can also determine whether the website visitor is using the new or old version of the Youtube interface.',
      },
      {
        col1: 'IDE',
        col2: '.doubleclick.net',
        col3: '1 year',
        col4: 'This cookie is set by Doubleclick and carries out information about how the end user uses the website and any advertising that the end user may have seen before visiting the said website.',
      },
      {
        col1: 'test_cookie',
        col2: '.doubleclick.net',
        col3: '15 minutes',
        col4: "This cookie is set by DoubleClick (which is owned by Google) to determine if the website visitor's browser supports cookies.",
      },
      {
        col1: 'YSC',
        col2: '.youtube.com',
        col3: 'Session',
        col4: 'This cookie is set by YouTube to track views of embedded videos.',
      },
      {
        col1: '_gcl_au',
        col2: `.${DOMAIN}`,
        col3: '3 months',
        col4: 'Used by Google AdSense for experimenting with advertisement efficiency across websites using their services.',
      },
      {
        col1: '_fbp',
        col2: `.${DOMAIN}`,
        col3: '3 months',
        col4: 'Used by Meta to deliver a series of advertisement products such as real time bidding from third party advertisers.',
      },
    ],
  },
}

export const getCookieConsent = (): CookieConsent | undefined => window.cookieConsent

const eraseCookies = (): void => {
  const cookieConsent: CookieConsent | undefined = getCookieConsent()
  if (!cookieConsent) return

  if (!cookieConsent.allowedCategory('targeting')) {
    cookieConsent.eraseCookies(['_gcl_au', '_fbp'], '/', `.${DOMAIN}`)
  }
  if (!cookieConsent.allowedCategory('analytics')) {
    cookieConsent.eraseCookies(['_gat', '_ga', '_gid'], '/', `.${DOMAIN}`)
  }
}
export const isAllowedCookieCategory = (category: string): boolean => {
  const cookieConsent: CookieConsent | undefined = getCookieConsent()
  if (!cookieConsent) return

  return cookieConsent.allowedCategory(category)
}
