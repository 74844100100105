import { clubsEndpoint } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { translate } from '@/plugins/translate'
import { defineStore } from 'pinia'
import type { ClubFilter, Club } from '@/interfaces/clubs/Clubs'
import type { ClubResponse } from '@/interfaces/responses/club/ClubResponses'

interface AvailableClubsState {
  availableClubs: any[]
  isLoading: boolean
}

export const useAvailableClubStore = defineStore('availableClubStore', {
  state: (): AvailableClubsState => ({
    availableClubs: [] as unknown[],
    isLoading: false,
  }),
  getters: {
    getAvailableClubs(): AvailableClubsState['availableClubs'] {
      return this.availableClubs
    },
    isAvailableClubsLoading(): AvailableClubsState['isLoading'] {
      return this.isLoading
    },
  },
  actions: {
    async loadAvailableClubs(filter: ClubFilter = {}): Promise<void> {
      const body = filter
      if (!this.availableClubs.length) {
        this.isLoading = true
      }

      const data = await internalAxios.post<{}, ClubResponse[]>(clubsEndpoint, body)

      const mutatedData = data.reduce((finalArray: Club[], club: ClubResponse): Club[] => {
        finalArray.push({
          clubId: club.id,
          country: club.country.three_letter_code,
          name: club.name,
          clubCapacity: club.max_members,
          membersCount: club.members_count,
          type: translate(`club.${club.club_type === 'request' ? 'forRequest' : club.club_type}`),
          prestige: club.prestige,
          logoId: club.logo_id,
          backgroundId: club.logo_background_id,
        })

        return finalArray
      }, [])

      this.availableClubs = mutatedData
      this.isLoading = false
    },
  },
})
