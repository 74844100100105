import type { AnimatedObjectWithPathsInterface } from '@/map-phaser-new/interfaces'
import { FRAMES_ANIMATION_TYPES } from '@/map-phaser-new/constants'
import {
  carsRightTopToLeftTopAnimations,
  carsLeftTopToRightTopAnimations,
  carsRightToBottomTunelAnimations,
  carsBottomTunnelToLeftAnimations,
  boatLeftToRightAnimations,
  boatBottomToRightAnimations,
  boatRightToBottomAnimations,
  ballonAnimations,
  helicopterLeftToRightAnimations,
} from './animations'
import {
  carsBottomTunnelToLeftCheckpoints,
  carsLeftTopToRightTopCheckpoints,
  carsRightToBottomTunelCheckpoints,
  carsRightTopToLeftTopCheckpoints,
  boatLeftToRightCheckpoints,
  boatBottomToRightCheckpoints,
  boatRightToBottomCheckpoints,
  helicopterLeftToRightCheckpoints,
} from './checkPointsData'
import {
  carsLeftTopToRightTopPath,
  carsRightTopToLeftTopPath,
  carsRightToBottomTunelPath,
  carsBottomTunnelToLeftPath,
  boatLeftToRightPath,
  boatBottomToRightPath,
  boatRightToBottomPath,
  ballonTopLeftPath,
  ballonTopRightPath,
  helicopterLeftToRightPath,
  airplaneRightToLeftPath,
} from './pathsData'

export const animatedObjectsWithPathsConfig: AnimatedObjectWithPathsInterface[] = [
  {
    animationName: 'carsRightTopToLeftTop',
    textureKey: 'animations',
    pathsData: carsRightTopToLeftTopPath,
    checkpointsData: carsRightTopToLeftTopCheckpoints,
    animationsData: carsRightTopToLeftTopAnimations,
    variations: ['v01', 'v02', 'v03', 'v04', 'v05'],
    framePrefix: 'SSM_car_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '10',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 70000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'carsRightTopToLeftTop1',
        startDelay: 0,
      },
      {
        name: 'carsRightTopToLeftTop2',
        startDelay: 10000,
      },
      {
        name: 'carsRightTopToLeftTop3',
        startDelay: 25000,
      },
      {
        name: 'carsRightTopToLeftTop4',
        startDelay: 40000,
      },
    ],
  },
  {
    animationName: 'carsLeftTopToRightTop',
    textureKey: 'animations',
    pathsData: carsLeftTopToRightTopPath,
    checkpointsData: carsLeftTopToRightTopCheckpoints,
    animationsData: carsLeftTopToRightTopAnimations,
    variations: ['v01', 'v02', 'v03', 'v04', 'v05'],
    framePrefix: 'SSM_car_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '12',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 70000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'carsLeftTopToRightTop1',
        startDelay: 0,
      },
      {
        name: 'carsLeftTopToRightTop2',
        startDelay: 12000,
      },
      {
        name: 'carsLeftTopToRightTop3',
        startDelay: 28000,
      },
      {
        name: 'carsLeftTopToRightTop4',
        startDelay: 60000,
      },
    ],
  },
  {
    animationName: 'carsBottomTunnelToLeft',
    textureKey: 'animations',
    pathsData: carsBottomTunnelToLeftPath,
    checkpointsData: carsBottomTunnelToLeftCheckpoints,
    animationsData: carsBottomTunnelToLeftAnimations,
    variations: ['v01', 'v02', 'v03', 'v04', 'v05'],
    framePrefix: 'SSM_car_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '05',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 70000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'carsBottomTunnelToLeft1',
        startDelay: 0,
      },
      {
        name: 'carsBottomTunnelToLeft2',
        startDelay: 15000,
      },
      {
        name: 'carsBottomTunnelToLeft3',
        startDelay: 37000,
      },
    ],
  },
  {
    animationName: 'carsFromRightToBottomTunel',
    textureKey: 'animations',
    pathsData: carsRightToBottomTunelPath,
    checkpointsData: carsRightToBottomTunelCheckpoints,
    animationsData: carsRightToBottomTunelAnimations,
    variations: ['v01', 'v02', 'v03', 'v04', 'v05'],
    framePrefix: 'SSM_car_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '10',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 70000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'carsFromRightToBottomTunel1',
        startDelay: 5000,
      },
      {
        name: 'carsFromRightToBottomTunel2',
        startDelay: 30000,
      },
      {
        name: 'carsFromRightToBottomTunel3',
        startDelay: 55000,
      },
      {
        name: 'carsFromRightToBottomTunel4',
        startDelay: 62000,
      },
    ],
  },
  {
    animationName: 'boatLeftToRight',
    textureKey: 'animations',
    pathsData: boatLeftToRightPath,
    checkpointsData: boatLeftToRightCheckpoints,
    animationsData: boatLeftToRightAnimations,
    variations: ['v01'],
    framePrefix: 'SSM_boat_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '19',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 160000,
      from: 0,
      to: 1,
    },
    followersDepth: 0,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'boatLeftToRight1',
        startDelay: 0,
      },
    ],
  },
  {
    animationName: 'boatBottomToRight',
    textureKey: 'animations',
    pathsData: boatBottomToRightPath,
    checkpointsData: boatBottomToRightCheckpoints,
    animationsData: boatBottomToRightAnimations,
    variations: ['v01'],
    framePrefix: 'SSM_boat_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '15',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 80000,
      from: 0,
      to: 1,
    },
    followersDepth: 0,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'boatBottomToRight1',
        startDelay: 0,
      },
    ],
  },
  {
    animationName: 'boatRightToBottom',
    textureKey: 'animations',
    pathsData: boatRightToBottomPath,
    checkpointsData: boatRightToBottomCheckpoints,
    animationsData: boatRightToBottomAnimations,
    variations: ['v01'],
    framePrefix: 'SSM_boat_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '31',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 70000,
      from: 0,
      to: 1,
    },
    followersDepth: 0,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'boatRightToBottom1',
        startDelay: 0,
      },
    ],
  },
  {
    animationName: 'ballonTopLeft',
    textureKey: 'animations',
    pathsData: ballonTopLeftPath,
    animationsData: ballonAnimations,
    variations: ['v01', 'v02'],
    framePrefix: 'SSM_balloon_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    playAnimationWithoutCheckpoints: {
      animationKeyPostfix: 'balloonRotate',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: false,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: true,
    tweenBuilderConfig: {
      duration: 130000,
    },
    followersDepth: 11,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'ballonTopLeft1',
        startDelay: 0,
        yoyoDelay: 10000,
      },
    ],
  },
  {
    animationName: 'ballonTopRight',
    textureKey: 'animations',
    pathsData: ballonTopRightPath,
    variations: ['v01'],
    framePrefix: 'SSM_balloon_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    playAnimationWithoutCheckpoints: {
      animationKeyPostfix: 'balloonRotate',
    },
    turnOnRepeatListener: false,
    turnOnUpdateListener: false,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: true,
    tweenBuilderConfig: {
      duration: 130000,
    },
    followersDepth: 11,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'ballonTopRight1',
        startDelay: 0,
        yoyoDelay: 30000,
      },
    ],
  },
  {
    animationName: 'helicopterLeftToRight',
    textureKey: 'animations',
    pathsData: helicopterLeftToRightPath,
    checkpointsData: helicopterLeftToRightCheckpoints,
    animationsData: helicopterLeftToRightAnimations,
    variations: ['v01'],
    framePrefix: 'SSM_helicopter_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '14',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 50000,
      from: 0,
      to: 1,
    },
    followersDepth: 11,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'helicopterLeftToRight1',
        startDelay: 0,
      },
    ],
  },
  {
    animationName: 'airplaneRightToLeft',
    textureKey: 'animations',
    pathsData: airplaneRightToLeftPath,
    variations: ['v01'],
    framePrefix: 'SSM_airplane_',
    framePostfix: '_4f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    turnOnRepeatListener: false,
    turnOnUpdateListener: false,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 35000,
      from: 0,
      to: 1,
    },
    followersDepth: 11,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'airplaneRightToLeft1',
        startDelay: 20000,
        repeatDelay: 100000,
      },
    ],
  },
]
