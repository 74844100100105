<template>
  <div class="target-item flexing" :class="{ 'target-item--done': targetData.isShot }">
    <div v-if="targetData.isShot" class="target-item__content">
      <app-main-icon :icon-size="56" :icon-name="targetData.targetIcon" class="inline-block" />
      <div class="target-item__content-value flexing text-texts-standard-default text-30 font-bold">
        {{ $filters.$formatNumber(targetData.targetValue) }}
        <app-icon v-if="targetData.isShot" class="absolute box-icon--done" icon-name="done-sm" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import type { PropType } from 'vue'

interface TargetItem {
  targetNumber: number
  targetIcon: string
  targetValue: number
  isShot: boolean
}

export default defineComponent({
  name: 'TargetItem',
  props: {
    targetData: {
      type: Object as PropType<TargetItem>,
      default: null,
    },
  },
})
</script>

<style lang="scss" scoped>
.target-item {
  width: 7.5rem;
  height: 7.5rem;
  background: url($path-events + 'autumn-fair/shooting-range/target.avif') no-repeat center;
  background-size: contain;
  padding: 0.625rem;

  &--done {
    background: url($path-events + 'autumn-fair/shooting-range/target--done.avif') no-repeat center;
    background-size: contain;
  }

  &__content {
    flex: 1 1 auto;
    z-index: 2;

    &-value {
      position: relative;
      width: 7.25rem;
      height: 2.25rem;
      margin-bottom: -2rem;
      background-image: linear-gradient(
        to right,
        rgba(14, 116, 105, 0),
        rgba(14, 116, 105, 1),
        rgba(14, 116, 105, 1),
        rgba(14, 116, 105, 0)
      );

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 0.125rem;
        background-image: linear-gradient(
          to right,
          rgba(14, 231, 204, 0.01),
          rgba(14, 231, 204, 1),
          rgba(14, 231, 204, 0.01)
        );
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }

      .box-icon--done {
        position: absolute;
        right: 0;
        bottom: -0.5rem;
      }
    }
  }
}
</style>
