import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const clubCarsRightTopToLeftTopAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'clubCarsRightTopToLeftTopAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '03',
      delay: 0,
      duration: 600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 3,
        end: 5,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsRightTopToLeftTopAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '05',
      delay: 0,
      duration: 600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 5,
        end: 3,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsRightTopToLeftTopAnimations3',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '03',
      delay: 0,
      duration: 600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 3,
        end: 2,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsRightTopToLeftTopAnimations4',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '02',
      delay: 0,
      duration: 600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 2,
        end: 3,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsRightTopToLeftTopAnimations5',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '03',
      delay: 0,
      duration: 600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 3,
        end: 1,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsRightTopToLeftTopAnimations6',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '01',
      delay: 0,
      duration: 600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GeneratedFrameNames,
      data: [
        { key: 'animations', frame: '01' },
        { key: 'animations', frame: '24' },
        { key: 'animations', frame: '23' },
      ],
    },
  },
]
