import {
  researchInfoCampsEndpoint,
  researchInfoCellEndpoint,
  researchInfoDisciplineEndpoint,
  researchInfoEconomicsEndpoint,
  researchStatusEndpoint,
} from '@/globalVariables'
import { mapResearchCellData } from '@/helpers/index.js'
import { researchColumnsEconomy } from '@/helpers/lab'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import { LineHeight, SkillType } from '@/interfaces/lab/Cell'
import type { Cell, EconomicsInput, EconomicsColumnCell } from '@/interfaces/lab/Cell'
import type {
  OngoingResearchEndpoint,
  TreeEndpoint,
  Branch,
  CellEndpoint,
} from '@/interfaces/responses/lab/LabResponses'

import type ParamStatReward from '@/interfaces/lab/ParamStatReward'
import type { Nullable } from '@/interfaces/utils'

interface SkillTreeInterface {
  disciplines?: Cell[][][]
  economics?: Cell[][]
  camp?: Cell[][]
}

interface ResearchCell {
  skillType: string
  icon: string
  isIconRepeatable: Nullable<boolean>
  researchTimer: number
}

interface LabStoreState {
  skillTrees: SkillTreeInterface
  skillTreesLoaded: boolean
  selectedSkillData: Cell
  skillCount: number
  isResearch: OngoingResearchEndpoint | null
  currentResearchCell: ResearchCell
  currentResearchDiscipline: number | null
  isActiveEconomicsResearch: boolean
}

export const useLabStore = defineStore('labStore', {
  state: (): LabStoreState => ({
    skillTrees: null,
    skillTreesLoaded: false,
    selectedSkillData: null,
    skillCount: 0,
    isResearch: null,
    currentResearchCell: null,
    currentResearchDiscipline: null,
    isActiveEconomicsResearch: false,
  }),
  getters: {
    getSkillTreesLoaded(): LabStoreState['skillTreesLoaded'] {
      return this.skillTreesLoaded
    },
    getSkillTrees(): LabStoreState['skillTrees'] {
      return this.skillTrees
    },
    getSkillCount(): LabStoreState['skillCount'] {
      return this.skillCount
    },
    getResearchInProgress(): LabStoreState['isResearch'] {
      return this.isResearch
    },
    getCurrentResearchCell(): LabStoreState['currentResearchCell'] {
      return this.currentResearchCell
    },
    getCurrentResearchDiscipline(): LabStoreState['currentResearchDiscipline'] {
      return this.currentResearchDiscipline
    },
    hasActiveEconomicsResearch(): LabStoreState['isActiveEconomicsResearch'] {
      return this.isActiveEconomicsResearch
    },
  },
  actions: {
    async loadDisciplineTree(payload: number): Promise<void> {
      const disciplineTree = await internalAxios.get<{}, TreeEndpoint[]>(
        researchInfoDisciplineEndpoint + payload,
      )

      const mappedData = { disciplines: [] }
      mappedData.disciplines[payload] = disciplineTree[0].branches.map((branch: Branch): Cell[] => {
        return branch.cells.map((cell: CellEndpoint): Cell => mapResearchCellData(cell))
      })

      this.skillTreesLoaded = false
      this.skillTrees = mappedData

      if (mappedData) {
        this.skillTreesLoaded = true
      }
    },
    async loadEconomicsTree(): Promise<void> {
      this.skillTreesLoaded = false
      this.skillTrees = null

      const economicsTree = await internalAxios.get<{}, TreeEndpoint[]>(
        researchInfoEconomicsEndpoint,
      )

      const mappedData = { economics: [] }

      researchColumnsEconomy.forEach((column: EconomicsInput): void => {
        const economicsTrees =
          economicsTree.length > 1
            ? [...economicsTree[0].branches, ...economicsTree[1].branches]
            : economicsTree[0].branches

        const branch = economicsTrees.find(
          (element: Branch): boolean => element.name === column.name,
        )
        if (!branch) return

        // this is index for case when there are notApplySkills indexes in a row
        mappedData.economics.push(
          branch.cells.map((cell: CellEndpoint): Cell => {
            const gddId = cell.parameters.find(
              (cellData: ParamStatReward): boolean => cellData.name === 'gdd_id',
            )?.value
            // let's calculate space between this skill and next one
            cell.positionLevel =
              column.cells?.find(
                (cellConf: EconomicsColumnCell): boolean => cellConf.gddId === gddId,
              )?.positionLevel ?? 0
            // size of connection line between cells
            cell.lineSize =
              column.cells?.find(
                (cellConf: EconomicsColumnCell): boolean => cellConf.gddId === gddId,
              )?.lineSize ?? LineHeight.Sm
            return mapResearchCellData(cell)
          }),
        )
      })

      this.skillTreesLoaded = false
      this.skillTrees = mappedData
      if (mappedData) {
        this.skillTreesLoaded = true
      }
    },
    async loadCampsTree(): Promise<void> {
      this.skillTreesLoaded = false
      this.skillTrees = null

      const campsTree = await internalAxios.get<{}, TreeEndpoint[]>(researchInfoCampsEndpoint)

      const mappedData = { camp: [] }
      mappedData.camp = campsTree[0].branches.map((branch: Branch): Cell[] => {
        return branch.cells.map(
          (cell: CellEndpoint): Cell =>
            mapResearchCellData({ ...cell, ...{ skillType: SkillType.Camps } }, branch.name),
        )
      })

      this.skillTreesLoaded = false
      this.skillTrees = mappedData
      if (mappedData) {
        this.skillTreesLoaded = true
      }
    },

    async setResearchStatus(): Promise<void> {
      const response = await internalAxios.get<{}, OngoingResearchEndpoint>(researchStatusEndpoint)
      this.isResearch = response

      if (this.isResearch) {
        const cellId = this.isResearch.research_cells_user_games_id
        if (cellId) {
          const response = await internalAxios.post<{}, CellEndpoint[]>(researchInfoCellEndpoint, {
            cell_ids: [cellId],
          })
          if (response.length) {
            const skillData = mapResearchCellData(response[0])

            this.currentResearchCell = {
              skillType: skillData.skillType,
              icon: skillData.icon,
              isIconRepeatable: skillData.isIconRepeatable,
              researchTimer: skillData.researchTimer,
            }

            // DISCIPLINE research indicator
            this.currentResearchDiscipline = response[0].discipline_id

            // ECONOMICS research indicator
            if (this.skillTrees?.economics) {
              const currentResearchId = response[0].id

              const foundCurrentEconomicResearch = this.skillTrees.economics.find(
                (economicsSubArray: Cell[]): boolean =>
                  economicsSubArray.some(
                    (economicsCell: Cell): boolean => economicsCell.id === currentResearchId,
                  ),
              )

              this.isActiveEconomicsResearch =
                foundCurrentEconomicResearch && foundCurrentEconomicResearch.length > 0
            }
          }
        }
      } else {
        this.currentResearchCell = null
        this.currentResearchDiscipline = null
        this.isActiveEconomicsResearch = false
      }
    },
  },
})
