import Phaser from 'phaser'
import { BootScene, MainMapScene, ClubsMapScene } from '../../scenes'
import GesturesPlugin from 'phaser3-rex-plugins/plugins/gestures-plugin.js'

const width: number = window.innerWidth
const height: number = window.innerHeight
let dpr: number = window.devicePixelRatio
if (dpr > 2.7) {
  dpr = 2.7
}
const widthDPR: number = Math.round(width * dpr)
const heightDPR: number = Math.round(height * dpr)

export const commonConfig: Phaser.Types.Core.GameConfig = {
  type: Phaser.WEBGL,
  width: width,
  height: height,
  parent: 'map-container',
  scene: [BootScene, MainMapScene, ClubsMapScene],
  dom: {
    createContainer: true,
  },
  transparent: true,
  render: {
    antialias: true,
    antialiasGL: true,
    powerPreference: 'default',
    roundPixels: false,
  },
  fps: {
    min: 5,
    target: 30,
    limit: 30,
  },
  scale: {
    mode: Phaser.Scale.ENVELOP,
    parent: 'map-container',
    width: widthDPR,
    height: heightDPR,
    autoCenter: Phaser.Scale.NO_CENTER,
  },
  mipmapFilter: 'LINEAR',
  plugins: {
    scene: [
      {
        key: 'rexGestures',
        plugin: GesturesPlugin,
        mapping: 'rexGestures',
      },
    ],
  },
}
