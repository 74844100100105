<template>
  <div class="support-message-wrapper">
    <div class="support-message">
      <header
        class="support-message-header font-bold flex flex-col items-center justify-end"
      ></header>
      <main class="support-message-main flex flex-col items-center">
        <section class="support-message-main-name">
          <p class="text-texts-standard-default text-40 uppercase font-bold">
            {{ $isMobile() ? $t('error.unsupportedOS') : $t('error.unsupportedBrowser') }}
          </p>
        </section>
        <div class="flexing flex-col h-full">
          <p class="text-texts-standard-additional text-32">
            {{ $isMobile() ? $t('error.unsupportedOSInfo1') : $t('error.unsupportedBrowserInfo1') }}
          </p>

          <div>
            <div class="mt-3 text-white">
              <span class="text-28">
                {{
                  $isMobile() ? $t('error.unsupportedOSInfo2') : $t('error.unsupportedBrowserInfo2')
                }}
              </span>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SupportMessage',
})
</script>

<style lang="scss" scoped>
.support-message {
  width: 100%;
  z-index: 1;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(0.15rem);
    z-index: 100;
    pointer-events: none;
  }

  &-header {
    width: 70%;
    height: 20rem;
    margin: 0 auto;
    background: url($path-premium + 'afterPayment/BG_EFFECT_4.png') center bottom no-repeat;
    background-size: cover;
  }

  &-main {
    width: 100%;
    height: 20rem;
    box-shadow: 0px 0.313rem 0.813rem 0 #272727;
    background: linear-gradient(
      to right,
      transparent,
      #154e79 10%,
      #0f2a4e 40%,
      #0b1e37 50%,
      #0f2a4e 60%,
      #154e79 90%,
      transparent
    );
    background: url($path-premium + 'afterPayment/TABLE_BG.png') center no-repeat;
    background-size: cover;

    &-name {
      width: 100%;
      height: 5rem;
      background: url($path-premium + 'afterPayment/BG_TOP_CONTENT.png') center no-repeat;
      background-size: cover;
      line-height: 5rem;
      margin-bottom: 0;
    }
  }

  footer {
    width: 100%;
    height: 15rem;
  }
}
</style>
