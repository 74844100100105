<template>
  <section v-if="loaded" class="events-warehouse safe-area" :class="selectHeight">
    <events-warehouse-header @section="setWarehouseSection" />
    <div class="events-warehouse__section-wrapper relative flexing flex-col flex-grow w-full">
      <component :is="warehouseSection" />
    </div>
  </section>
  <events-warehouse-footer v-if="warehouseSection !== 'EventsWarehouseLeague'" />

  <events-warehouse-lab-session-message v-if="warehouseModalState.isModalOpen" />
</template>

<script lang="ts">
import { useLeagueEventStore } from '@/store/pinia/events/leagueStore'
import { useWarehouseStore } from '@/store/pinia/events/warehouseStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import ConvertorMain from './Convertor/ConvertorMain.vue'
import EventsWarehouseFooter from './EventsWarehouseFooter.vue'
import EventsWarehouseHeader from './EventsWarehouseHeader.vue'
import EventsWarehouseLab from './EventsWarehouseLab.vue'
import EventsWarehouseLabSessionMessage from './EventsWarehouseLabSessionMessage.vue'
import EventsWarehouseLeague from './EventsWarehouseLeague/EventsWarehouseLeague.vue'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  isContentLoading: boolean
  warehouseSection: string
  loaded: boolean
  timeoutId: Nullable<ReturnType<typeof setTimeout>>
}

export default defineComponent({
  name: 'EventsWarehouse',
  components: {
    EventsWarehouseLab,
    EventsWarehouseLabSessionMessage,
    ConvertorMain,
    EventsWarehouseLeague,
    EventsWarehouseHeader,
    EventsWarehouseFooter,
  },
  data(): ComponentData {
    return {
      isContentLoading: false,
      warehouseSection: 'EventsWarehouseLab',
      loaded: false,
      timeoutId: null,
    }
  },
  computed: {
    ...mapState(useWarehouseStore, {
      getTimeLeft: 'getTimeLeft',
      warehouseModalState: 'getModalState',
    }),
    selectHeight(): string {
      if (
        this.warehouseSection === 'EventsWarehouseLab' ||
        this.warehouseSection === 'ConvertorMain'
      ) {
        return this.$isMobile() ? 'height-with-footer-mobile' : 'height-with-footer'
      } else return 'h-full'
    },
  },
  async created(): Promise<void> {
    await this.setLeagueTimer()

    document.addEventListener('visibilitychange', this.onVisibilityChange)
  },
  beforeUnmount(): void {
    this.clearTimeout()

    document.removeEventListener('visibilitychange', this.onVisibilityChange)
  },
  methods: {
    ...mapActions(useLeagueEventStore, {
      loadLeagueState: 'loadState',
    }),
    ...mapActions(useWarehouseStore, {
      loadConfig: 'loadConfig',
    }),
    setWarehouseSection(e: { component: string }): void {
      this.warehouseSection = e.component
    },
    async setLeagueTimer(): Promise<void> {
      this.clearTimeout()

      await this.refreshLeague()
      if (!this.loaded) this.loaded = true

      if (!this.getTimeLeft || this.getTimeLeft < 0) return

      this.timeoutId = window.setTimeout(this.refreshLeague, this.getTimeLeft * 1e3)
    },
    async refreshLeague(): Promise<void[]> {
      return await Promise.all(
        [
          this.loadConfig(true),
          this.warehouseSection === 'EventsWarehouseLeague' && this.loadLeagueState(),
        ].filter(Boolean),
      )
    },
    async onVisibilityChange(): Promise<void> {
      if (document.visibilityState === 'hidden') {
        this.clearTimeout()
        return
      }

      await this.setLeagueTimer()
    },
    clearTimeout(): void {
      if (!this.timeoutId) return
      window.clearTimeout(this.timeoutId)
      this.timeoutId = null
    },
  },
})
</script>

<style lang="scss" scoped>
.events-warehouse {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &-items-scroll {
    padding-top: 1rem;
  }
}

.height-with-footer {
  height: 78%;
}

.height-with-footer-mobile {
  height: 80%;
}
</style>
