export enum MilestoneType {
  Individual = 'individual',
  Premium = 'premium',
}

export enum MilestoneState {
  Open = 'open',
  Locked = 'locked',
}

export interface MilestoneReward {
  name: string
  value: number
  level?: number
  rarity?: string
}

export interface Milestone {
  // active: boolean
  milestone: number
  points?: number
  [MilestoneType.Individual]: {
    milestoneId: number
    claimed: boolean
    reward: MilestoneReward
  }
  [MilestoneType.Premium]: {
    milestoneId: number
    claimed: boolean
    reward: MilestoneReward
  }
  state: MilestoneState
  repeatable?: boolean
}

export interface MilestoneProgress {
  milestone: number
  next_milestone: number
  points: number
  next_points: number
  repeatable?: boolean
}

export interface TournamentsMilestonesBoardGamesEventStateApiResponse {
  has_pass: boolean
  milestones: Milestone[]
  milestones_progress: MilestoneProgress
}
