<template>
  <header
    class="relative tasks-header flexing w-full mx-auto mt-10 mb-2 uppercase"
    :class="`team-header-${teamId}`"
    :data-event-theme="namespace"
  >
    <p
      class="team-text flexing flex-col text-40 uppercase pb-4 relative"
      :class="[`team-text-${teamId}`, { 'font-bold': $isSsm }]"
    >
      {{ $te(teamId === 1 ? 'moreEnergy' : 'moreStarts') }}
    </p>
    <app-button
      class="absolute right-10 z-10"
      btn-type="primary"
      :btn-text="$te('eventInformation')"
      btn-size="xsm"
      @click="isOpen = true"
    />
  </header>
  <info-popup
    v-if="isOpen"
    :popup-title="$te('infoTaskchain')"
    class="tasks-popup"
    width="76.125rem"
    @close="isOpen = false"
  >
    <section class="tasks-popup-info text-texts-standard-default text-34">
      <p class="chains-popup-info-text">
        {{ $te('aboutTaskchain') }}
      </p>
    </section>
  </info-popup>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { pathImages } from '@/globalVariables'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

interface ComponentData {
  pathImages: string
  isOpen: boolean
}

export default defineComponent({
  name: 'TasksHeader',
  components: {
    InfoPopup,
  },
  props: {
    teamId: {
      type: Number as PropType<1 | 2>,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
      isOpen: false,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
  },
})
</script>

<style lang="scss" scoped>
$teams: (1, 2);

[data-event-theme='challenge_cup'] {
  @each $team in $teams {
    --event-tasks-header-#{$team}-bg: url('#{$path-events}challenge-cup/taskchain/tasks/header-#{$team}-bg.avif');
    --event-tasks-avatar-#{$team}: url('#{$path-events}challenge-cup/taskchain/tasks/team-avatar-#{$team}.avif');
    --event-tasks-text-#{$team}: url('#{$path-events}challenge-cup/taskchain/tasks/team-text-#{$team}.avif');
  }
}

[data-event-theme='frozen'] {
  @each $team in $teams {
    --event-tasks-header-#{$team}-bg: url('#{$path-events}frozen-championship/taskchain/tasks/header-bg.avif');
    --event-tasks-avatar-#{$team}: url('#{$path-events}frozen-championship/taskchain/tasks/team-avatar-#{$team}.avif');
    --event-tasks-text-#{$team}: url('#{$path-events}frozen-championship/taskchain/tasks/team-text-#{$team}.avif');
  }
}

.tasks-header {
  height: 8.625rem;

  @each $team in $teams {
    &.team-header-#{$team} {
      @include background(var(--event-tasks-header-#{$team}-bg), cover);
    }
  }

  .team-text {
    justify-content: flex-end;
    width: 36.438rem;
    height: 10.125rem;
    color: rgba(217, 236, 255, 0.87);
    @include background(null, contain);

    @if $isWsm {
      text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.86);
    }

    @if $isSsm {
      text-shadow: 2px 3.5px 0 rgba(0, 0, 0, 0.86);
    }

    &::before {
      content: '';
      position: absolute;
      left: -10rem;
      bottom: 1rem;
      width: 17.188rem;
      height: 10.5rem;
      @include background(null, contain);
    }

    @each $team in $teams {
      &.team-text-#{$team} {
        &::before {
          background-image: var(--event-tasks-avatar-#{$team});
        }

        &.team-text-#{$team} {
          background-image: var(--event-tasks-text-#{$team});
        }
      }
    }
  }
}

.tasks-popup {
  &-info {
    height: 16.5625rem;
    padding: 3.1875rem 6.25rem;
    line-height: 2.5rem;

    &-text {
      line-height: 3rem;
    }
  }
}
</style>
