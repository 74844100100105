import { tournamentsActiveStateEndpoint as TOURNAMENTS_ACTIVE_STATE_ENDPOINT } from '@/globalVariables'
import type { TournamentsActiveStateApiResponse } from '@/interfaces/responses/tournaments/TournamentsActiveStateApiResponse'
import { ApiService } from '@/services/ApiService'
import { defineStore } from 'pinia'

interface TournamentsActiveStore {
  state: TournamentsActiveStateApiResponse[] | null
  mapRankingPanel: boolean
}

export const useTournamentsActiveStore = defineStore('tournamentsActiveStore', {
  state: (): TournamentsActiveStore => ({
    state: null,
    mapRankingPanel: true,
  }),
  getters: {
    activeTournaments(): TournamentsActiveStateApiResponse[] | null {
      return this.state ?? null
    },
    activeTournamentIds(): number[] | null {
      return (
        this.activeTournaments?.map(
          ({
            tournament_id: id,
          }: TournamentsActiveStateApiResponse): TournamentsActiveStateApiResponse['tournament_id'] =>
            id,
        ) ?? null
      )
    },
    isRankingPanelVisible(): boolean {
      return this.mapRankingPanel
    },
  },
  actions: {
    async loadState(force: boolean = false): Promise<void> {
      if (this.state && !force) return
      try {
        this.state = await ApiService.get<TournamentsActiveStateApiResponse[]>(
          TOURNAMENTS_ACTIVE_STATE_ENDPOINT,
          { force: true },
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
    hideRankingPanel(): void {
      this.mapRankingPanel = false
    },
  },
})
