<template>
  <aside class="right-side relative">
    <div
      class="right-side-table-header flex items-center w-full text-30 text-texts-standard-additional"
    >
      <p class="pos">
        {{ $t('rankingsGroup.pos') }}
      </p>
      <p class="name">
        {{ $t('common.name') }}
      </p>
      <p class="result">
        {{ $t('common.power') }}
      </p>
      <p class="points flexing">
        {{ $t('rankingsGroup.points') }}
      </p>
    </div>
    <app-scrollbar class="star-detail-scrollbar" width="100%" height="30.4rem" scroll="y" slide="y">
      <div class="table-standard">
        <div
          v-for="(opponent, index) in disciplineData.opponents"
          :key="index"
          class="table-standard-row text-texts-standard-default right-side-table-row w-full text-32 flex items-center relative"
          :class="{
            my: opponent.id === userId,
          }"
        >
          <p
            class="right-side-table-row-position h-full text-36 flexing"
            :class="{
              'text-texts-standard-dark': index < 3 || $isSsm,
              my: opponent.id === userId,
              'text-texts-standard-default': $isWsm,
            }"
          >
            {{ index + 1 }}
          </p>
          <app-user
            :id="opponent.id || ''"
            class="right-side-table-row-player"
            :country="opponent.country"
            :name="opponent.name"
            :is-bot="opponent.bot"
            :badges-data="opponent.event_badges ?? null"
          />
          <p class="right-side-table-row-result flexing">
            {{
              opponent.fail_status
                ? $t(`arena.${opponent.fail_status}`)
                : formatResult(opponent?.result, activeDisciplineId)
            }}
          </p>
          <p
            class="right-side-table-row-points absolute text-texts-standard-important text-32 h-full flexing"
            :class="{ my: opponent.id === userId }"
          >
            <arena-report-result-icon
              :discipline-id="activeDisciplineId"
              :report-data="opponent.reportData"
              :daily-league="true"
            />
            <span class="right-side-table-row-points-number">{{ opponent?.points ?? '-' }}</span>
          </p>
        </div>
      </div>
    </app-scrollbar>
    <div class="line absolute w-full pointer-events-none" :style="{ top: '79%' }" />
    <div class="star-player text-32 w-full flex items-center relative">
      <p
        class="star-player-position text-36 h-full font-bold flexing"
        :class="{
          'text-texts-standard-default': $isWsm,
          'text-texts-standard-dark': $isSsm,
        }"
      >
        {{ disciplineData.results.position }}
      </p>
      <app-user
        :id="disciplineData.results.userId"
        class="right-side-table-row-player"
        :country="disciplineData.results.country"
        :name="disciplineData.results.name"
        :badges-data="disciplineData.results.event_badges ?? null"
      />
      <p class="star-player-result text-texts-standard-default font-bold flexing">
        {{
          disciplineData?.results?.fail_status
            ? $t(`arena.${disciplineData?.results?.fail_status}`)
            : formatResult(disciplineData?.results?.currentResult, activeDisciplineId)
        }}
      </p>
      <p
        class="star-player-points h-full absolute flexing"
        :class="{ 'text-texts-standard-default': $isWsm, 'text-texts-standard-important': $isSsm }"
      >
        {{ disciplineData?.results?.points ?? '-' }}
      </p>
    </div>
  </aside>
</template>

<script lang="ts">
import ArenaReportResultIcon from '@/components/Arena/ArenaReportResultIcon.vue'
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import { pathImages } from '@/globalVariables'
import { formatResult } from '@/helpers'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { DailyLeagueDiscipline } from '@/interfaces/responses/starOfTheDay/DailyLeagueResponse'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'StarofthedayDetailRight',
  components: {
    ArenaReportResultIcon,
    AppUser,
  },

  props: {
    disciplineData: {
      type: Object as PropType<Nullable<DailyLeagueDiscipline>>,
      default: () => null,
    },
    activeDisciplineId: {
      type: Number,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userId: 'getUserId',
    }),
  },
  methods: {
    formatResult,
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';
@import '@/assets/styles/views/staroftheday/disciplineDetail.scss';

.star-detail-scrollbar {
  margin-top: 0.75rem;

  .table-standard-row {
    width: 99%;
  }
}
</style>
