<template>
  <header
    class="history-header w-full flex flex-row flex-shrink-0 justify-between items-center relative"
  >
    <div>
      <template v-if="!isLoading">
        <app-select
          :options="tournamentTypes"
          :default="tournamentType"
          class="selectbox-disciplines"
          :disabled="isDisabled"
          @select="$emit('set-tournament-type', $event)"
        />
      </template>
      <component-loading v-else :is-loading="true" height="100%" />
    </div>
    <div class="history-title flexing absolute">
      <p class="flexing text-texts-standard-default text-40 font-bold">
        {{ $t('tournaments.myTournaments') }}
      </p>
    </div>
    <div class="flexing space-x-8">
      <tournament-box :resources="[{ value: mulligans.value, icon: MULLIGANS }]" />
      <tournament-info-button />
    </div>
  </header>
</template>

<script lang="ts">
import { ARENA, MULLIGANS } from '@/globalVariables'
import TournamentBox from '../TournamentBox.vue'
import TournamentInfoButton from '../TournamentInfoButton.vue'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { defineComponent, type PropType } from 'vue'
import { mapState, mapActions } from 'pinia'

export enum TournamentType {
  All = 'All',
  Active = 'Active',
  Waiting = 'Waiting',
  Finished = 'Finished',
}

interface SelectOption {
  value: TournamentType
  name: string
}

interface ComponentData {
  MULLIGANS: typeof MULLIGANS
  isLoading: boolean
}

export default defineComponent({
  components: {
    TournamentBox,
    TournamentInfoButton,
  },
  props: {
    tournamentType: {
      type: String as PropType<SelectOption[keyof SelectOption]>,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['set-tournament-type'],
  data(): ComponentData {
    return {
      MULLIGANS,
      isLoading: false,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, { mulligans: 'getMulligans' }),
    tournamentTypes(): SelectOption[] {
      return [
        { value: TournamentType.All, name: this.$t('tournaments.allTournaments') },
        { value: TournamentType.Active, name: this.$t('tournaments.currentTournaments') },
        { value: TournamentType.Waiting, name: this.$t('tournaments.nextTournaments') },
        { value: TournamentType.Finished, name: this.$t('tournaments.endedTournaments') },
      ]
    },
  },
  async created(): Promise<void> {
    this.isLoading = true
    await this.loadDisciplinesForMenu(ARENA)
    this.isLoading = false
  },
  methods: {
    ...mapActions(useDisciplineStore, ['loadDisciplinesForMenu']),
  },
})
</script>

<style lang="scss" scoped>
.history-header {
  height: 7.375rem;
  padding: 1.25rem 1.875rem;

  .history-title {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 62.5rem;
    height: 4.875rem;
    @if $isSsm {
      background-image: linear-gradient(to right, transparent, #56627f, #56627f, transparent);
    }
    @if $isWsm {
      background-image: linear-gradient(to right, transparent, #144a73, #144a73, transparent);
    }
  }
}
</style>
