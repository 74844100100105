<template>
  <main class="arena-main flex flex-col w-full h-full safe-area">
    <arena-header />
    <arena-body />
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ArenaBody from './ArenaBody.vue'
import ArenaHeader from './ArenaHeader.vue'

export default defineComponent({
  name: 'ArenaMain',
  components: {
    ArenaHeader,
    ArenaBody,
  },
})
</script>
