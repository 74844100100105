<template>
  <div
    class="game-pass-pack items-center relative"
    :class="[`pack-${packType}`, { 'clickable-element cursor-pointer': !purchased, purchased }]"
    @click="buyOrConfirm"
  >
    <span
      class="pack-name text-texts-standard-default text-48 font-bold uppercase flexing relative"
    >
      <p>{{ $t(`gamePass.gamePass${capitalize(packType)}`) }}</p>
      <span v-if="purchased" class="icon-done-md absolute right-10" />
    </span>
    <span
      class="pack-bonuses-title text-texts-standard-important text-36 font-bold uppercase flexing relative"
    >
      <p>{{ $t('minigame.bonuses') }}</p>
    </span>
    <section v-if="rewards" class="pack-bonuses relative flex justify-start">
      <div
        v-for="(reward, index) in rewards"
        :key="index"
        class="pack-bonus flex items-center justify-start"
      >
        <app-icon
          :icon-name="`${getRewardData(reward)?.icon}-90`"
          class="flex-shrink-0 bonus-icon"
        />
        <p class="text-28 text-texts-standard-default">
          <span
            v-if="reward.bonus?.includes('x')"
            class="text-texts-standard-important font-bold mr-1"
          >
            {{ reward.bonus }}
          </span>
          {{ $t(getRewardData(reward)?.text) }}
          <span
            v-if="reward.bonus?.includes('+')"
            class="text-texts-standard-important font-bold ml-1"
            >{{ reward.bonus }}</span
          >
        </p>
      </div>
    </section>
    <span class="pack-bonuses-bottom text-texts-standard-default text-28 flexing relative">
      <p>{{ $t('gamePass.andMuchMore') }}</p>
    </span>
    <div class="pack-button flexing relative">
      <p
        v-if="purchased"
        class="claimed-text text-texts-standard-positive text-40 uppercase font-bold"
      >
        {{ $t('premiumGroup.purchased') }}
      </p>
      <app-multi-button
        v-else
        :btn-id="`game-pass-pack-btn-${packType}`"
        btn-type="confirm"
        btn-size="md"
        multi-width="27.125rem"
      >
        <template #leftPart>
          <span class="text-32 font-semibold"> {{ $t('button.purchase') }}</span>
        </template>
        <template #rightPart>
          <span class="text-32">
            {{ `${price.price}${price.currency}` }}
          </span>
        </template>
      </app-multi-button>
    </div>
    <confirm-popup
      v-if="showConfirm"
      :popup-title="$t(`gamePass.confirmPurchase`)"
      :button-data="{
        btnId: 'btn-pass-buy-confirm',
        btnType: 'secondary',
        btnSize: 'xl',
      }"
      :not-multi="true"
      :single-button-text="$t('premiumGroup.buy')"
      custom-width="65rem"
      @close="showConfirm = false"
      @action="buyPack(storeId)"
    >
      <div class="popup-wrapper">
        <p class="text-texts-standard-danger text-36 uppercase mt-12">
          {{
            $replacePlaceholder(
              $t('gamePass.passExpirationWarning'),
              '{pass}',
              $t('gamePass.gamePass'),
            )
          }}
        </p>
        <p class="text-texts-standard-default text-36 mt-12">
          {{ $t('gamePass.warningRewards') }}
        </p>
        <p class="text-texts-standard-default text-36 my-12">
          {{
            $replacePlaceholder(
              $t('gamePass.warningBonusExpiration'),
              '{pass}',
              $t('gamePass.gamePass'),
            )
          }}
        </p>
      </div>
    </confirm-popup>
    <payment-terminal
      v-if="terminal"
      :item-id="storeId"
      :wire-transfer-off="true"
      @close="terminal = false"
    />
  </div>
</template>

<script lang="ts">
import PaymentTerminal from '@/components/Popup/Premium/PaymentTerminal.vue'
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import { metaPremiumPackEndpoint, metaPremiumPayEndpoint } from '@/globalVariables'
import { getIsMobileLocalStorage, sendToFlutter } from '@/helpers'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import { capitalize, gamePassRewardsParser } from '@/helpers'
import type { Nullable } from '@/interfaces/utils'
import type Price from '@/interfaces/Price'
import type PremiumPackApiResponse from '@/interfaces/responses/premium/PremiumPackApiResponse'
import type PremiumPayApiResponse from '@/interfaces/responses/premium/PremiumPayApiResponse'
import { GamePassType } from '@/interfaces/premium/enums'

interface ComponentData {
  terminal: boolean
  showConfirm: boolean
}
interface RewardsData {
  name: string
  bonus: string
}

export default defineComponent({
  name: 'GamePassPack',
  components: {
    PaymentTerminal,
    ConfirmPopup,
  },
  props: {
    packType: {
      type: String,
      required: true,
      validator(propName: GamePassType): boolean {
        return [GamePassType.Pro, GamePassType.Elite].includes(propName)
      },
    },
    purchased: {
      type: Boolean,
      required: true,
    },
    price: {
      type: Object as PropType<Nullable<Price>>,
      default: () => null,
    },
    storeId: {
      type: String,
      default: '',
    },
    isPassEnding: {
      type: Boolean,
      default: false,
    },
    rewards: {
      type: Array as PropType<Nullable<RewardsData[]>>,
      default: null,
    },
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      terminal: false,
      showConfirm: false,
    }
  },
  computed: {
    isMobileLocalStorage(): boolean {
      return getIsMobileLocalStorage() === 1
    },
  },

  methods: {
    capitalize,
    ...mapActions(useResponseTaskStore, ['showPaymentLoading', 'showPaymentMessage']),
    getRewardData(reward: RewardsData): { text: string; icon: string } {
      return gamePassRewardsParser(reward)
    },
    buyOrConfirm(): void {
      if (this.isPassEnding) {
        this.showConfirm = true
      } else {
        this.buyPack(this.storeId)
      }
    },
    async buyPack(storeId: string): Promise<void> {
      this.showConfirm = false
      if (this.purchased) return
      if (!this.isMobileLocalStorage && this.price.price > 0) {
        this.terminal = true
        return
      }

      this.showPaymentLoading()

      const responsePay = await this.$axios.get<{}, PremiumPayApiResponse>(
        `${metaPremiumPayEndpoint}/pack/${storeId}${
          !this.isMobileLocalStorage ? '/bank_transfer' : ''
        }`,
      )
      if (responsePay?.event) {
        const message = {
          event: responsePay.event,
          productId: responsePay.productId,
        }
        sendToFlutter(JSON.stringify(message))
        return
      }

      const responsePack = await this.$axios.get<{}, PremiumPackApiResponse>(
        `${metaPremiumPackEndpoint}${storeId}`,
      )
      this.showPaymentMessage({
        pack: storeId,
        rewards: responsePack.rewards,
        failed: false,
        af_quantity: responsePack.firebaseData.af_quantity,
        af_store_id: responsePack.firebaseData.af_store_id,
        gtm_currency: responsePack.firebaseData.gtm_currency,
        gtm_revenue: responsePack.firebaseData.gtm_revenue,
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.game-pass-pack {
  width: 52.625rem;
  height: 44rem;
  background-repeat: no-repeat;
  background-size: contain;

  .pack-name {
    width: 100%;
    height: 6.25rem;
    top: 1.5rem;
    padding: 0 3rem;
  }
  .pack-bonuses {
    width: 100%;
    height: 23.5rem;
    top: 1.5rem;
    padding: 0 2.5rem;
    display: flex;
    flex-wrap: wrap;
    &-title {
      width: 100%;
      height: 3.12rem;
      top: 1.5rem;
      padding: 0 3rem;
    }
    &-bottom {
      top: 1.5rem;
    }
  }
  .pack-bonus {
    margin-right: 0.5rem;
    width: 48%;
    & > p {
      line-height: 1.8rem;
      text-align: left;
      margin-left: 1rem;
    }
    .bonus-icon {
      transform: scale(0.8);
    }
  }

  .pack-button {
    top: 1.5rem;
    height: 5.9rem;
  }

  &.pack-pro {
    background-image: url($path-premium + 'gamePass/packs/game-pass-pro-bg.avif');

    &.purchased {
      background-image: url($path-premium + 'gamePass/packs/game-pass-pro-purchased-bg.avif');
    }
  }

  &.pack-elite {
    background-image: url($path-premium + 'gamePass/packs/game-pass-elite-bg.avif');

    &.purchased {
      background-image: url($path-premium + 'gamePass/packs/game-pass-elite-purchased-bg.avif');
    }
  }

  .claimed-text {
    &:before {
      content: '[';
      margin-right: 1rem;
      font-style: normal;
      @if $isWsm {
        color: #5199c6;
      }
      @if $isSsm {
        color: #7b87b8;
      }
    }

    &:after {
      content: ']';
      margin-left: 1rem;
      font-style: normal;
      @if $isWsm {
        color: #5199c6;
      }
      @if $isSsm {
        color: #7b87b8;
      }
    }
  }
  &.purchased {
    .pack-bonuses,
    .pack-bonuses-title,
    .pack-bonuses-bottom {
      opacity: 0.5;
    }
  }
}
</style>
