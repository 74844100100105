<template>
  <div
    v-if="eventMechanics.includes(requiredEventMechanic) && eventActive"
    class="tasks-event app-page-wrapper absolute"
  >
    <menu-component
      menu-type="tasks"
      :title="$t('career.quests')"
      :sub-menu-active="true"
      :sub-menu="{
        1: {
          text: $t('career.dailyQuests'),
          route: $getWebView('DailyTask'),
          isDisabled: !hasMechanic(MECHANIC_DAILY_QUESTS),
          disabledText: $t('rankingsGroup.completeQuestsToUnlock'),
        },
        2: {
          text: $t('career.career'),
          route: $getWebView('TasksCareer'),
        },
        3: {
          text: $t('event.eventsTasks'),
          route: $getWebView('TasksEvent'),
          current: $getWebView('TasksEvent'),
        },
      }"
    />
    <section class="w-full h-full safe-area">
      <main class="flexing">
        <events-side-menu-box />
        <section class="rightside">
          <component :is="eventComponent" />
        </section>
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import EventBox from '@/components/Events/EventBox.vue'
import BountyBoardEventBox from '@/components/Events/BoardComponents/BountyBoard/BountyBoardEventBox.vue'
import EventsSideMenuBox from '@/components/Tasks/EventsSideMenuBox.vue'
import { MECHANIC_DAILY_QUESTS } from '@/globalVariables'
import { EventMechanics, EventType } from '@/interfaces/events/EventInfo'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

enum TasksEventComponent {
  EventBox = 'EventBox',
  BountyBoardEventBox = 'BountyBoardEventBox',
}

interface ComponentData {
  EventMechanics: typeof EventMechanics
  MECHANIC_DAILY_QUESTS: typeof MECHANIC_DAILY_QUESTS
  eventComponent: TasksEventComponent
}

export default defineComponent({
  name: 'TasksEvent',
  components: {
    EventBox,
    BountyBoardEventBox,
    EventsSideMenuBox,
  },
  data(): ComponentData {
    return {
      EventMechanics,
      MECHANIC_DAILY_QUESTS,
      eventComponent: TasksEventComponent.EventBox,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
    }),
    ...mapState(useEventInfoStore, {
      eventType: 'getEventType',
      eventMechanics: 'getEventMechanics',
      eventActive: 'getEventActive',
    }),
    requiredEventMechanic(): EventMechanics {
      if (this.eventType === EventType.AutumnFair) {
        return EventMechanics.BountyBoard
      }
      return EventMechanics.Taskchain
    },
  },
  created(): void {
    this.eventComponent =
      this.eventType === EventType.AutumnFair
        ? TasksEventComponent.BountyBoardEventBox
        : TasksEventComponent.EventBox
  },
})
</script>

<style lang="scss" scoped>
.tasks-event {
  main {
    width: 98%;
    height: 47.75rem;
    margin: 0 auto;
    margin-top: 2.5rem;

    .rightside {
      height: 47.25rem;
      width: 100%;
    }
  }
}
</style>
