<template>
  <div
    :id="'smaller-offer-' + offerData.store_id"
    :data-cy="'smaller-offer-' + offerData.store_id"
    :class="[
      'smaller-offer-box relative cursor-pointer',
      {
        'pointer-events-none': offerData.remaining_buys === 0,
      },
    ]"
    :style="{
      background:
        'url(' +
        pathImages +
        'premium/offers/smaller-offers/offer-' +
        offerBg +
        '.avif) center no-repeat',
      backgroundSize: 'contain',
    }"
  >
    <div v-show="offerData.remaining_buys === 0" class="offer-box-bought absolute w-full h-full">
      <app-price
        class="text-texts-standard-upgrade offer-box-bought-text uppercase"
        decoration-color="#5199c6"
      >
        <p>{{ $t('common.claimed') }}</p>
      </app-price>
    </div>
    <header>
      <offer-box-head :offer-head-data="offerData" @click="showBackSide = !showBackSide" />
    </header>
    <main @click="disableBuyOnClick ? null : buyPack()">
      <app-plate-value
        v-if="offerValue"
        :color="$isWsm ? 'purple' : 'orange'"
        class="absolute small-plate"
        :text-value="'<b>' + offerValue + '%</b>' + ' value'"
      />
      <div class="smaller-offer-box-timer">
        <app-timer :time="offerData.remaining_time" />
      </div>
      <div
        v-if="offerType || (isEventOffer && (isWinterSeriesEventOffer || isSummerSeriesEventOffer))"
        class="smaller-offer-box-rewards"
      >
        <reward-box
          v-for="reward in offerData.offer_content"
          :key="reward"
          :reward-icon-name="
            reward.name === 'benefit' && 'level' in reward
              ? `${reward.type}-${reward.level}`
              : reward.name
          "
          :reward-icon-size="80"
          :font-size="40"
          :reward-icon-rarity="'rarity' in reward && reward.rarity"
          :reward-number="Number(reward.value)"
          :event-offer-box-type="eventOfferBoxType"
          class="offer-reward-box"
        />
      </div>
      <div v-else-if="isThirdBundle" class="smaller-offer-box-two">
        <section
          v-for="(reward, i) in offerData.offer_content"
          :key="i"
          :class="
            'text-texts-standard-important smaller-offer-box-two-s relative text-60 font-bold flexing s-' +
            i
          "
        >
          <p>{{ reward.value + setMultiplierText(reward.value.toString()) }}</p>
        </section>
      </div>
      <div v-else class="smaller-offer-box-single">
        <img
          v-if="!refreshingBundleOffer"
          class="single-img m-auto"
          :src="
            pathImages + `premium/offers/smaller-offers/${offerData.offer_content[0].name}.avif`
          "
        />
        <div
          v-if="refreshingBundleOffer"
          class="no-img-placeholder no-img-placeholder--refreshing-bundle"
        />
        <p class="text-texts-standard-important text-60 font-bold mt-2">
          {{
            `${$filters.$formatNumber(offerData.offer_content[0].value)}` +
            setMultiplierText(offerData.offer_content[0].name)
          }}
        </p>
      </div>
      <div
        class="smaller-offer-box-price flexing"
        :class="{
          'grid-box-price': offerType,
          'event-box-price': isEventOffer && (isWinterSeriesEventOffer || isSummerSeriesEventOffer),
        }"
      >
        <app-price
          :old-price="!offerValue ? '' : Math.round(offerPrice) + ' ' + offerData?.price?.currency"
          :exchange-price="getForceRate ? formatForceRate(offerPrice) : ''"
          decoration-color="#5199c6"
        >
          <p>
            {{
              offerData?.price?.price && offerData.price.price > 0
                ? offerData.price.price + ' ' + offerData.price.currency
                : 'FREE'
            }}
          </p>
        </app-price>
      </div>
    </main>

    <offers-box-back v-show="showBackSide" :rewards="offerData.offer_content" size="small" />
    <payment-terminal v-if="terminal" :item-id="offerData.store_id" @close="terminal = false" />
  </div>
</template>

<script lang="ts">
import AppPlateValue from '@/components/GlobalComponents/AppPlateValue.vue'
import AppPrice from '@/components/GlobalComponents/AppPrice.vue'
import PaymentTerminal from '@/components/Popup/Premium/PaymentTerminal.vue'
import OfferBoxHead from '@/components/Premium/Offers/OfferBoxHead.vue'
import OffersBoxBack from '@/components/Premium/Offers/OffersBoxBack.vue'
import {
  REFRESHING_BUNDLE_PACKS,
  REFRESHING_BUNDLE,
  metaPremiumPackEndpoint,
  metaPremiumPayEndpoint,
  pathImages,
  MECHANIC_EVENT,
} from '@/globalVariables'
import { getIsMobileLocalStorage, sendToFlutter, setMultiplierText } from '@/helpers'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { Offer } from '@/interfaces/premium/Offer'
import { GameVersion } from '@/enums/GameVersion'

import type PremiumPackApiResponse from '@/interfaces/responses/premium/PremiumPackApiResponse'
import type PremiumPayApiResponse from '@/interfaces/responses/premium/PremiumPayApiResponse'

interface ComponentData {
  getIsMobileLocalStorage: typeof getIsMobileLocalStorage
  pathImages: typeof pathImages
  showBackSide: boolean
  terminal: boolean
  GameVersion: typeof GameVersion
}

export default defineComponent({
  name: 'OffersSmallerBox',
  components: {
    AppPrice,
    OffersBoxBack,
    PaymentTerminal,
    OfferBoxHead,
    AppPlateValue,
  },
  props: {
    offerData: {
      type: Object as PropType<Nullable<Offer>>,
      default: () => null,
    },
    disableBuyOnClick: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      getIsMobileLocalStorage,
      pathImages,
      showBackSide: false,
      terminal: false,
      GameVersion,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      getForceRate: 'getForceRate',
      formatForceRate: 'formatForceRate',
    }),
    isMobileLocalStorage(): boolean {
      return getIsMobileLocalStorage() === 1
    },
    offerValue(): string | number {
      return this.offerData?.parameters?.find((a) => a.name === 'value')?.value ?? ''
    },
    offerPrice(): number {
      if (!this.offerData?.price?.price || !this.offerValue) {
        return 0
      }
      return (this.offerData.price.price * Number(this.offerValue)) / 100
    },
    refreshingBundleOffer(): boolean {
      return this.offerData.store_id?.includes(REFRESHING_BUNDLE)
    },
    offerBg(): string {
      if (!this.offerData) return ''
      const offerBackground = this.offerData?.parameters
        ?.find((a) => a.name === 'image_name')
        ?.value.toString()
      return offerBackground ?? this.offerData?.store_id
    },
    offerType(): boolean {
      if (this.$isWsm) {
        return this.offerBg === `${GameVersion.WSM}_place_holder_small_multiple`
      }
      if (this.$isSsm) {
        return this.offerBg === `${GameVersion.SSM}_place_holder_small_multiple`
      }
      return false
    },
    isEventOffer(): boolean {
      return this.offerData.category === MECHANIC_EVENT
    },
    isWinterSeriesEventOffer(): boolean {
      return this.offerData.store_id.startsWith('wsm_winter_series_')
    },
    isSummerSeriesEventOffer(): boolean {
      return this.offerData.store_id.startsWith('ssm_summer_series_')
    },
    isThirdBundle(): boolean {
      return Object.values(REFRESHING_BUNDLE_PACKS).includes(this.offerData.store_id)
    },
    eventOfferBoxType(): string {
      if (!this.isEventOffer) return ''

      if (this.isWinterSeriesEventOffer)
        return this.offerData.store_id === 'wsm_winter_series_3' ? 'premium' : 'basic'

      if (this.isSummerSeriesEventOffer) return this.offerData.store_id.split('_').pop()

      return ''
    },
  },
  methods: {
    ...mapActions(useResponseTaskStore, {
      showPaymentLoading: 'showPaymentLoading',
      showPaymentMessage: 'showPaymentMessage',
    }),
    setMultiplierText,
    async buyPack(): Promise<void> {
      if (!this.isMobileLocalStorage) {
        this.terminal = true
        return
      }

      this.showPaymentLoading()

      const response = await this.$axios.get<{}, PremiumPayApiResponse>(
        `${metaPremiumPayEndpoint}/pack/${this.offerData.store_id}`,
      )

      if (response?.event) {
        const message = {
          event: response.event,
          productId: response.productId,
        }
        sendToFlutter(JSON.stringify(message))
        return
      }
      const responsePack = await this.$axios.get<{}, PremiumPackApiResponse>(
        metaPremiumPackEndpoint + this.offerData.store_id,
      )
      this.showPaymentMessage({
        pack: this.offerData.store_id,
        rewards: responsePack.rewards,
        failed: false,
        af_quantity: responsePack.firebaseData.af_quantity,
        af_store_id: responsePack.firebaseData.af_store_id,
        gtm_currency: responsePack.firebaseData.gtm_currency,
        gtm_revenue: responsePack.firebaseData.gtm_revenue,
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.smaller-offer-box {
  width: 32.063rem;
  height: 44.875rem;
  margin-right: 1.5rem;

  header {
    height: 6.3rem;
  }

  .small-plate {
    right: 1.5rem;
    width: 15.625rem;
  }

  &-timer {
    width: 15.563rem;
    margin: 0.75rem 1.5rem;
  }

  &-rewards {
    width: 65%;
    height: 24.5rem;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;

    & > .reward-box {
      width: 8.438rem;
      height: 10.375rem;
      margin: 1rem auto;
    }
  }

  &-single {
    height: 23.5rem;

    .single-img {
      width: 22.5rem;
      height: 18.75rem;
    }

    .no-img-placeholder {
      &--refreshing-bundle {
        width: 100%;
        height: 18.75rem;
      }
    }
  }

  &-two {
    padding-top: 2.9rem;
    height: 23.5rem;

    &-s {
      &:first-child {
        margin-left: 0;
        margin-top: 0.375rem;
      }

      margin-left: 12rem;
      height: 4.375rem;
      width: 18rem;
    }

    .s-0 {
      margin-bottom: 8.875rem;
    }
  }

  &-price {
    margin-top: 3rem;
    height: 8.5rem;

    &.grid-box-price {
      margin-top: 2rem;
    }

    &.event-box-price {
      padding-top: 2.5rem;
      margin-top: 0.5rem;
    }
  }
}

.offer-box-bought {
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;

  &-text {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
