<template>
  <div class="seasons-rewards">
    <div class="seasons-rewards-header">
      <p
        class="text-texts-standard-important text-36 uppercase"
        :class="{
          'font-bold': $isSsm,
        }"
      >
        {{ $t('seasons.yourCurrentRewards') }}
      </p>
      <seasons-about />
    </div>

    <div class="seasons-rewards-line" />

    <div v-if="!isContentLoading">
      <div v-for="(reward, key) in rewards" :key="key">
        <seasons-rewards-item :season-reward="reward" />
      </div>
    </div>

    <component-loading :is-loading="isContentLoading" height="75%" />
  </div>
</template>

<script lang="ts">
import SeasonsAbout from '@/components/Seasons/SeasonsAbout.vue'
import SeasonsRewardsItem from '@/components/Seasons/SeasonsRewardsItem.vue'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { SeasonRewardType } from '@/interfaces/Seasons'

export default defineComponent({
  name: 'SeasonsRewards',
  components: {
    SeasonsRewardsItem,
    SeasonsAbout,
  },
  props: {
    rewards: {
      type: Array as PropType<SeasonRewardType[]>,
      default: () => [],
    },
  },
  computed: {
    isContentLoading(): boolean {
      return this.rewards[0]?.type === '' || !this.rewards.length
    },
  },
})
</script>

<style lang="scss" scoped>
.seasons-rewards {
  width: 68%;
  margin-left: 2rem;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-line {
    height: 1px;
    width: 100%;
    background: linear-gradient(
      80deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
