<template>
  <div v-if="!!actualStageData && $isMobile()" class="mobile-wrapper w-full h-full absolute">
    <div
      v-if="
        (actualStageData.type === 'clientInput' && actualStageData.overlay) ||
        actualStageData.type === FOCUS
      "
      id="tutorial-overlay"
      :class="{ 'tutorial-overlay-focus': actualStageData.type === FOCUS }"
    />
    <story-container
      v-if="actualStageData.type === 'narrative' && actualTextValue"
      :tutorial-text="$replaceUntranslatablePlaceholder(actualTextValue, '{grandprize}')"
      :tutorial-boss="actualBoss"
      :tutorial-pose="actualPose"
      :tutorial="true"
      :is-annie-flipped="actualStageData.isAnnieFlipped"
      @click-to-narrative="clickNarrative"
    />
    <router-view />
  </div>
  <div v-else-if="actualStageData && !$isMobile()">
    <clubs-map-layout v-if="actualStageData.map === 'clubs'" />

    <div
      v-if="OVERLAY_STEPS.includes(actualStageData.name) || actualStageData.type === FOCUS"
      id="tutorial-overlay"
      :class="{ 'tutorial-overlay-focus': actualStageData.type === FOCUS }"
    />
    <avatar-menu v-show="$route.path !== '/tutorial/choose-discipline'" />
    <map-task-tracker v-if="!actualStageData.isTaskTrackerHidden" class="tutorial-task-tracker" />
    <web-window-component>
      <div
        v-if="
          ((actualStageData.type === 'clientInput' && actualStageData.overlay) ||
            actualStageData.type === FOCUS) &&
          !(actualStageData && CLOSE_BUTTON_STEPS.includes(actualStageData.name))
        "
        id="tutorial-overlay"
        :class="{ 'tutorial-overlay-focus': actualStageData.type === FOCUS }"
      />
    </web-window-component>
    <story-container
      v-if="actualStageData.type === 'narrative' && actualTextValue"
      :tutorial-text="$replaceUntranslatablePlaceholder(actualTextValue, '{grandprize}')"
      :tutorial-boss="actualBoss"
      :tutorial-pose="actualPose"
      :tutorial="true"
      :is-annie-flipped="actualStageData.isAnnieFlipped"
      @click-to-narrative="clickNarrative"
    />
  </div>
</template>

<script lang="ts">
import AvatarMenu from '@/components/AvatarMenu.vue'
import StoryContainer from '@/components/Career/StoryContainer.vue'
import WebWindowComponent from '@/components/Layout/WebWindowComponent.vue'
import MapTaskTracker from '@/components/MapTaskTracker/index.vue'
import { FOCUS, narrativeDialogues } from '@/globalVariables'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import ClubsMapLayout from '@/views/layouts/ClubsMapLayout.vue'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { playSound } from '@/helpers'

import type { TutorialText, TutorialStage } from '@/interfaces/Tutorial'
import { StageType } from '@/interfaces/Tutorial'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  FOCUS: typeof FOCUS
  narrativeDialogues: typeof narrativeDialogues
  actualText: number
  texts: TutorialText[]
  actualTextValue: string
  actualBoss: string
  actualPose: string
  CLOSE_BUTTON_STEPS: string[]
  OVERLAY_STEPS: string[]
}

export default defineComponent({
  name: 'TutorialView',
  components: {
    WebWindowComponent,
    MapTaskTracker,
    AvatarMenu,
    ClubsMapLayout,
    StoryContainer,
  },
  data(): ComponentData {
    return {
      FOCUS,
      actualText: 0,
      texts: [],
      actualTextValue: '',
      actualBoss: '',
      actualPose: '',
      narrativeDialogues,
      CLOSE_BUTTON_STEPS: [
        'closeTrainingHall',
        'closeArenaWindow',
        'closeEquipmentShop',
        'closeArenaAfterSimulations',
        'closeGrandPrize',
        'focusDisciplineBranchDetailCloseButton',
        'clickCloseBranchDetail',
        'clickOnMapItem',
        'clickClubhouseCloseButton',
        'focusCareerHomeButtonCareer',
      ],
      OVERLAY_STEPS: [
        'clickOnGrandPrizeMenu',
        'clickCareerManagementButton',
        'clickOnDailyTasks',
        'clickOnBenefitsButton',
        'focusOnClubCentrum',
      ],
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
      getValidButtonId: 'getValidButtonId',
    }),
  },
  watch: {
    actualStageData(stage: TutorialStage): void {
      if (!stage) {
        document.removeEventListener('click', this.clickListener, true)

        if (window.location.href.includes('tutorial')) {
          this.$router.push({ name: 'LayoutView' })
        }
        return
      }
      if (stage && stage.type === 'narrative' && stage.texts.length > 0) {
        this.loadNarrativeTexts(stage)
      }
      if (stage && (stage.type === StageType.Focus || stage.type === StageType.ClientInput)) {
        setTimeout((): void => {
          playSound('tutorial_yellow_line')
        }, 500)
      }
    },
    getValidButtonId(btnId: string): void {
      if (btnId) {
        this.clickListener(null, btnId)
      }
    },
  },
  async created(): Promise<void> {
    if (!this.actualStageData) await this.loadTutorial()

    if (this.actualStageData && this.actualStageData.type === 'narrative') {
      this.loadNarrativeTexts(this.actualStageData)
    }
    this.actualStageData?.onPreload?.checkStateValidity()
    document.addEventListener('click', this.clickListener, true)
  },
  beforeUnmount(): void {
    document.removeEventListener('click', this.clickListener, true)
  },
  methods: {
    ...mapActions(useTutorialStore, ['loadTutorial', 'logTutorialProgress', 'setRedrawFocus']),
    clickListener(e?: Nullable<Event>, manualId?: string): void {
      const targetId = manualId || (e?.target as HTMLElement)?.id || e.target?.dataset?.id

      if (!this.actualStageData) {
        return
      }

      if (this.actualStageData.name === 'setUserName' && !this.getValidButtonId) {
        return
      }

      if (this.actualStageData.redirect) this.redirectToPage()

      // this.actualStageData.onPreload?.checkStateValidity()
      if (
        this.actualStageData.type === StageType.ClientInput &&
        this.actualStageData.clientId.includes(targetId)
      ) {
        this.actualStageData.onSuccess?.sendData()
        this.logTutorialStep(e)
      } else if (targetId === 'tutorial-overlay' && e) {
        e.stopPropagation()
      }
    },
    clickNarrative($event: Event): void {
      // prechadzat pole s textami - rozlisovat kratky, dlhy text
      if (this.actualText < this.texts.length - 1) {
        this.actualText++
        this.actualTextValue = this.$t(this.texts[this.actualText].dialogue)
        this.actualBoss = this.texts[this.actualText].boss
        this.actualPose = this.texts[this.actualText].pose
      } else {
        this.logTutorialStep($event)
      }
    },
    logTutorialStep(e: Event): void {
      const target = e?.target as HTMLElement

      let stageName = this.actualStageData.name
      let skipStep = this.actualStageData.skipStep
      if (target?.id === 'arena-start-simulation') {
        stageName = this.actualStageData.secondStage
        skipStep = false
      }

      if (!skipStep) {
        this.logTutorialProgress(stageName)
      }

      this.texts = []
      this.actualTextValue = ''
      this.actualText = 0

      this.setRedrawFocus(true)
    },
    redirectToPage(): void {
      this.$router.push({
        name: this.actualStageData.redirect,
      })
    },
    loadNarrativeTexts(stage: TutorialStage): void {
      this.texts = stage.texts
      const keyToTranslate = this.texts[this.actualText]?.dialogue || ''
      this.actualTextValue = this.$t(keyToTranslate) || '...'
      this.actualBoss = this.texts[this.actualText].boss || 'anne'
      this.actualPose = this.texts[this.actualText].pose || 'pose_1'
    },
  },
})
</script>

<style scoped lang="scss">
#tutorial-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 5;
  position: absolute;
  top: 0;

  &.tutorial-overlay-focus {
    background: transparent;
  }
}

.tutorial-task-tracker {
  position: absolute;
  top: 21.8125rem;
  left: $default-margin;
  height: auto;
}
</style>
