<template>
  <teleport to="#app">
    <div class="popup-overlay fixed top-0 left-0" @click="$emit('close')" />
    <div
      class="info-popup-box bossfight-report-popup absolute left-1/2 top-1/2 flex flex-col items-center overflow-hidden"
      :class="[winner ? 'winner' : 'loser', { 'web-scale': !$isMobile() }]"
    >
      <boss-fight-header :discipline-id="disciplineId" class="w-full" @close="$emit('close')" />
      <div class="bossfight-report-popup-content w-full flexing mt-8">
        <boss-fight-report-player-info
          :winner="winner"
          :player="player"
          position="left"
          class="z-20"
        />
        <div class="bossfight-stripe flexing absolute" :class="[winner ? 'winner' : 'loser']">
          <p
            class="text-90 font-bold uppercase not-italic relative z-10"
            :class="winner ? 'text-texts-standard-important' : 'text-texts-standard-danger'"
          >
            {{ $t(winner ? 'event.bossFightYouWon' : 'event.bossFightYouLost') }}
          </p>
        </div>
        <boss-fight-report-player-info
          :player="boss"
          :winner="!winner"
          :boss="true"
          position="right"
          class="z-20"
        />
      </div>
      <footer
        class="bossfight-report-popup-footer w-full flexing"
        :class="winner ? 'winner' : 'loser'"
      >
        <app-button
          btn-type="secondary"
          :btn-text="$t(winner ? 'event.continue' : 'event.bossFightPlayAgain')"
          btn-size="md"
          @click="$emit(winner ? 'close' : 'playAgain')"
        />
      </footer>
    </div>
  </teleport>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import BossFightHeader from '../BossFight/BossFightHeader.vue'
import BossFightReportPlayerInfo from '../BossFightReport/BossFightReportPlayerInfo.vue'
import type { ReportPopupData } from '../TasksBoss.vue'

export default defineComponent({
  components: {
    BossFightHeader,
    BossFightReportPlayerInfo,
  },
  props: {
    player: {
      type: Object as PropType<ReportPopupData['player']>,
      required: true,
    },
    boss: {
      type: Object as PropType<ReportPopupData['boss']>,
      required: true,
    },
  },
  emits: ['close', 'playAgain'],
  computed: {
    disciplineId(): number {
      return this.player?.disciplineId
    },
    winner(): boolean {
      return this.player?.position === 1
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';
@import '@/assets/styles/components/popups.scss';

.scale-popup {
  transform: translate(-50%, -50%) scale(0.85);
}

.bossfight-report-popup {
  width: 91.125rem;
  height: 53.625rem;
  @include background(null, contain);

  @each $rank in ('winner', 'loser') {
    &.#{$rank} {
      background-image: url('#{$path-events}shared/taskchain/tasks/bossFight/bossfight-background-#{$rank}.avif');
      .bossfight-stripe::after {
        background-image: url('#{$path-events}shared/taskchain/tasks/bossFight/bossfight-stripe-#{$rank}.avif');
      }
    }
  }

  .bossfight-report-popup-content {
    height: 39rem;

    .bossfight-stripe {
      top: 15.5rem;
      left: 50%;
      width: 32.5rem;
      transform: translateX(-50%);

      &::after {
        position: absolute;
        content: '';
        width: 40rem;
        height: 8.5rem;
        @include background(null, contain);
      }

      .text-90 {
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: none;

        &.text-texts-standard-danger {
          background-image: linear-gradient(to top, #e57676, #ffe6e6);
        }

        &.text-texts-standard-important {
          background-image: linear-gradient(to top, #f7c852, #ffe779);
        }
      }
    }
  }

  .bossfight-report-popup-footer {
    height: 9.625rem;
    @include background(null, contain);

    @each $rank in ('winner', 'loser') {
      &.#{$rank} {
        background-image: url('#{$path-events}shared/taskchain/tasks/bossFight/bossfight-footer-background-#{$rank}.avif');
      }
    }
  }
}
</style>
