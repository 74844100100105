import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const carsBottomTunnelToLeftAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'carsBottomTunnelToLeftAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '05',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 5,
        end: 2,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsBottomTunnelToLeftAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '02',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GeneratedFrameNames,
      data: [
        { key: 'animations', frame: '02' },
        { key: 'animations', frame: '01' },
        { key: 'animations', frame: '24' },
        { key: 'animations', frame: '23' },
        { key: 'animations', frame: '22' },
        { key: 'animations', frame: '21' },
      ],
    },
  },
  {
    animationCreationData: {
      animationName: 'carsBottomTunnelToLeftAnimations3',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '21',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 21,
        end: 20,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsBottomTunnelToLeftAnimations4',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '20',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 20,
        end: 21,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsBottomTunnelToLeftAnimations5',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '21',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 21,
        end: 19,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsBottomTunnelToLeftAnimations6',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '19',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 19,
        end: 21,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsBottomTunnelToLeftAnimations7',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '21',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 21,
        end: 22,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsBottomTunnelToLeftAnimations8',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '22',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 22,
        end: 20,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsBottomTunnelToLeftAnimations9',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '20',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 20,
        end: 15,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsBottomTunnelToLeftAnimations10',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '15',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 15,
        end: 16,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsBottomTunnelToLeftAnimations11',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '16',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 16,
        end: 17,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsBottomTunnelToLeftAnimations12',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '17',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 17,
        end: 19,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsBottomTunnelToLeftAnimations13',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '19',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 19,
        end: 15,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsBottomTunnelToLeftAnimations14',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '15',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 15,
        end: 19,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsBottomTunnelToLeftAnimations15',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '19',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 19,
        end: 21,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsBottomTunnelToLeftAnimations16',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '21',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 21,
        end: 22,
        zeroPad: 2,
      },
    },
  },
]
