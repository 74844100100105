<template>
  <teleport to="#app_id">
    <div class="fullscreen-popup-wrapper offer-detail absolute w-full h-full flexing top-0">
      <main class="flexing w-full">
        <header class="offer-detail-header relative flexing">
          <img
            v-if="offerTitle === 'limited'"
            class="offer-detail-header-image-bg absolute"
            :src="pathImages + 'premium/offers/headers/BG_BOX_LIMITED_OFFER.avif'"
            alt="offer"
          />
          <img
            class="offer-detail-header-image"
            :class="{ limited: offerTitle === 'limited' }"
            :src="pathImages + 'premium/offers/headers/' + headerImages[offerTitle] + '.avif'"
            alt="offer-detail"
          />
          <app-control-icon class="close-icon absolute" control="close" @click="closeOffer" />
        </header>

        <offers-gem-bonus v-if="isGemsBonus" :offer-data="offerData as Offer" />

        <offers-piggy-bank v-else-if="isPiggyBank" :offer-data="offerData as Offer" />

        <offers-chain
          v-else-if="isChain"
          :offer-data="offerData as OfferChainData"
          @reload-data="hideOfferDetail"
        />

        <offers-game-pass v-else-if="isGamePass" :offer-data="offerData as Offer" />

        <offers-tournament-pass v-else-if="isTournamentPass" :offer-data="offerData as Offer" />

        <offers-box
          v-else-if="checkBigOfferDetail"
          :offer-data="getOfferData"
          disable-buy-on-click
        />

        <section v-else class="flexing">
          <!-- TODO BE WSM-8855 -->
          <offers-smaller-box
            v-for="box in offerDataArray"
            :key="box.store_id"
            :offer-data="box"
            disable-buy-on-click
          />
        </section>

        <footer v-if="showBuyButton" class="offer-detail-buttons relative flexing">
          <div v-if="isGemsBonus" class="offer-detail-buttons-wrap flexing">
            <app-button
              btn-type="credit"
              :btn-text="$t('premiumGroup.gem_bonus_buy')"
              class="offer-detail-button"
              @click="getToPremium()"
            />
          </div>
          <div
            v-else-if="!Object.keys(offerDataItemObject).length"
            class="offer-detail-buttons-wrap flexing"
          >
            <app-button
              btn-type="credit"
              :btn-text="offerIsPayed() ? $t('premiumGroup.buy') : 'FREE'"
              class="offer-detail-button"
              @click="purchaseOffer()"
            />
          </div>
          <div
            v-for="(box, index) in offerDataArray"
            v-else
            :key="index"
            class="offer-detail-buttons-wrap flexing"
          >
            <app-button
              btn-type="credit"
              :btn-text="offerArrayIsPayed(index) ? $t('premiumGroup.buy') : 'FREE'"
              class="offer-detail-button"
              @click="purchaseOffer(index)"
            />
          </div>
        </footer>
      </main>
    </div>

    <payment-terminal
      v-if="terminal"
      :item-id="selectedOfferStoreId"
      :wire-transfer-off="true"
      @close="terminal = false"
    />
    <confirm-popup
      v-if="popup"
      :popup-title="$t(`premiumGroup.${offerDataItemObject.store_id}`)"
      :button-data="btnUpgrade"
      :not-multi="true"
      :disabled="lackOfGems"
      :single-button-text="$t('premiumGroup.buy')"
      @close="popup = false"
      @action="buyWeeklyPack"
    >
      <div class="popup-wrapper">
        <p class="text-texts-standard-default text-38">
          {{ $t('premiumGroup.addOnConfirm') }}
        </p>
        <div class="flexing text-texts-standard-default text-40">
          <p>{{ $t('premiumGroup.price') }}:</p>
          <p
            class="flexing amount-text text-texts-standard-important"
            :class="{ 'text-texts-standard-danger': lackOfGems }"
          >
            {{ offerDataItemObject?.gems_price }}
          </p>
          <app-main-icon :icon-size="56" icon-name="gems" />
        </div>
      </div>
    </confirm-popup>
  </teleport>
</template>

<script lang="ts">
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import PaymentTerminal from '@/components/Popup/Premium/PaymentTerminal.vue'
import OffersBox from '@/components/Premium/Offers/OffersBox.vue'
import OffersChain from '@/components/Premium/Offers/OffersChain.vue'
import OffersGamePass from '@/components/Premium/Offers/OffersGamePass.vue'
import OffersPiggyBank from '@/components/Premium/Offers/OffersPiggyBank.vue'
import OffersGemBonus from '@/components/Premium/Offers/OffersGemBonus.vue'
import OffersSmallerBox from '@/components/Premium/Offers/OffersSmallerBox.vue'
import OffersTournamentPass from './Premium/Offers/OffersTournamentPass.vue'
import {
  LEGENDARY_CHAIN,
  OFFER_STATES,
  REFRESHING_BUNDLE,
  MECHANIC_EVENT,
  metaPremiumPackEndpoint,
  metaPremiumPayEndpoint,
  pathImages,
} from '@/globalVariables'
import {
  getIsMobileLocalStorage,
  sendToFlutter,
  toArray,
  isGamePass,
  isPiggyBank,
  isTournamentPass,
} from '@/helpers'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useRenderQueueStore } from '@/store/pinia/renderQueueStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Passes } from '@/interfaces/User'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { Offer, OfferChainData } from '@/interfaces/premium/Offer'
import type PremiumPackApiResponse from '@/interfaces/responses/premium/PremiumPackApiResponse'
import type PremiumPayApiResponse from '@/interfaces/responses/premium/PremiumPayApiResponse'

interface ComponentData {
  pathImages: typeof pathImages
  popup: boolean
  headerImages: {
    special: string
    one_time: string
    limited: string
    hot: string
    icy: string
    black_friday: string
  }
  btnUpgrade: {
    btnId: string
    btnType: string
    btnSize: string
  }
  offerTitle: string
  selectedOfferStoreId: string
  terminal: boolean
}

export default defineComponent({
  name: 'OfferDetail',
  components: {
    OffersBox,
    OffersSmallerBox,
    OffersGemBonus,
    OffersChain,
    PaymentTerminal,
    ConfirmPopup,
    OffersGamePass,
    OffersPiggyBank,
    OffersTournamentPass,
  },
  props: {
    offerData: {
      type: [Array, Object] as PropType<Offer[] | Offer>,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
      popup: false,
      headerImages: {
        special: 'SPECIAL_OFFER',
        one_time: 'ONE_TIME_OFFER',
        limited: 'LIMITED_OFFER',
        hot: 'HOT_DEALS',
        icy: 'ICY_DEALS',
        black_friday: 'BLACK_FRIDAY',
      },
      btnUpgrade: {
        btnId: 'btn-buy-access',
        btnType: 'credit',
        btnSize: 'xl',
      },
      offerTitle: '',
      selectedOfferStoreId: '',
      terminal: false,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      newOffersToShow: 'getNewOffersToShow',
    }),
    ...mapState(useUserStore, {
      passes: 'getAvailablePasses',
    }),
    ...mapState(useResponseTaskStore, {
      headerData: 'getGems',
    }),
    offerDataArray(): Offer[] {
      return toArray(this.offerData)
    },
    lackOfGems(): boolean {
      return this.headerData.value < Number(this.offerDataItemObject.gems_price)
    },
    isGemsBonus(): boolean {
      return this.offerDataItemObject.store_id?.includes('gem_bonus')
    },
    isGearDiscipline(): boolean {
      return this.offerDataItemObject.store_id?.includes('premium_gear_discipline')
    },
    isChain(): boolean {
      return this.offerDataItemObject.type?.includes(LEGENDARY_CHAIN)
    },
    isGamePass(): boolean {
      return isGamePass({ store_id: this.offerDataItemObject.store_id })
    },
    isTournamentPass(): boolean {
      return isTournamentPass({ store_id: this.offerDataItemObject.store_id })
    },
    isPiggyBank(): boolean {
      return isPiggyBank({ store_id: this.offerDataItemObject.store_id })
    },
    isRefreshingBundle(): boolean {
      return this.offerDataItemObject.store_id?.includes(REFRESHING_BUNDLE)
    },
    isEventOffer(): boolean {
      return this.offerDataItemObject.category === MECHANIC_EVENT
    },
    isWinterSeriesEventOffer(): boolean {
      return this.offerDataItemObject.store_id?.startsWith('wsm_winter_series_')
    },
    isSummerSeriesEventOffer(): boolean {
      return this.offerDataItemObject.store_id?.startsWith('ssm_summer_series_')
    },
    // FIXME: Toto musime v ramci refaktoru prerobit. Treba si najskor overit v spolupraci s BE, ci vsetky offre maju v params definovanu velkost a ak ano, tak podla toho sa rozhodovat a nie podla store_id.
    checkBigOfferDetail(): boolean {
      // 3 male offre
      if (this.isRefreshingBundle) return false

      // 1 maly offer
      if (this.isEventOffer && (this.isWinterSeriesEventOffer || this.isSummerSeriesEventOffer))
        return false

      // zbytok su velke offre
      return true
    },
    getOfferData(): Offer {
      return Array.isArray(this.offerData) ? this.offerData[0] : this.offerData
    },
    getRemainingBuys(): number {
      return this.getOfferData?.remaining_buys ?? 0
    },
    showBuyButton(): boolean {
      if (this.isChain || this.isGamePass) return false
      return (
        (this.passes.find(
          (pass: Passes): boolean => pass.store_id === this.offerDataItemObject.store_id,
        )?.state !== OFFER_STATES.active &&
          this.getRemainingBuys > 0) ||
        this.isGemsBonus
      )
    },
    isMobileLocalStorage(): boolean {
      return getIsMobileLocalStorage() === 1
    },
    offerDataItemObject(): Offer {
      return this.offerDataArray[0]
    },
  },
  created(): void {
    this.getOfferTitle()
  },
  methods: {
    ...mapActions(usePremiumStore, ['hideOfferDetail', 'showOfferDetail', 'saveNewOffers']),
    ...mapActions(useRenderQueueStore, ['setNextInRenderQueue']),
    ...mapActions(useResponseTaskStore, {
      showPaymentLoading: 'showPaymentLoading',
      showPaymentMessage: 'showPaymentMessage',
    }),
    ...mapActions(useUserStore, ['setProfileAttributes']),
    async closeOffer(): Promise<void> {
      if (this.isGamePass) {
        // set game_pass_seen to true
        await this.setProfileAttributes({
          name: 'game_pass_seen',
          value: true,
        })
      }

      if (this.isPiggyBank) {
        // set piggy_bank_seen to true
        await this.setProfileAttributes({
          name: 'piggy_bank_seen',
          value: true,
        })
      }

      if (this.newOffersToShow?.length) {
        const offerToShow = this.newOffersToShow.shift()
        if (offerToShow.content) {
          // save offer data to store
          this.showOfferDetail({ offerData: offerToShow.content })
          this.saveNewOffers(this.newOffersToShow)
        }
      }

      this.setNextInRenderQueue()
    },
    getOfferTitle(): void {
      if (Object.keys(this.offerDataItemObject).length) {
        if (this.isGemsBonus) {
          this.offerTitle = this.offerDataItemObject?.title ?? ''
          return
        }

        if (this.isChain || this.isGamePass || this.isPiggyBank || this.isTournamentPass) {
          this.offerTitle = 'special'
          return
        }

        this.offerTitle =
          (this.offerDataItemObject as Offer).parameters
            ?.find(
              (param: { name: string; value: string }): boolean => param.name === 'offer_title',
            )
            ?.value.toString() || ''
      }
    },
    getToPremium(): void {
      this.hideOfferDetail()
      this.$router.push({ name: this.$getWebView('PremiumGems') })
    },
    async purchaseOfferIndex(idx: number): Promise<void> {
      if (this.offerDataArray[idx]?.gems_price) {
        this.popup = true
        return
      }
      this.selectedOfferStoreId = this.offerDataArray[idx].store_id

      if (!this.isMobileLocalStorage && this.offerDataArray[idx].price?.price > 0) {
        this.terminal = true
        return
      }

      this.showPaymentLoading()

      const responsePay = await this.$axios.get<{}, PremiumPayApiResponse>(
        `${metaPremiumPayEndpoint}/pack/${this.selectedOfferStoreId}${
          !this.$isMobile() ? '/bank_transfer' : ''
        }`,
      )
      if (responsePay?.event) {
        const message = {
          event: responsePay.event,
          productId: responsePay.productId,
        }
        sendToFlutter(JSON.stringify(message))
        return
      }
      const responsePack = await this.$axios.get<{}, PremiumPackApiResponse>(
        metaPremiumPackEndpoint + this.selectedOfferStoreId,
      )
      this.showPaymentMessage({
        pack: this.selectedOfferStoreId,
        rewards: responsePack.rewards,
        failed: false,
        af_quantity: responsePack.firebaseData.af_quantity,
        af_store_id: responsePack.firebaseData.af_store_id,
        gtm_currency: responsePack.firebaseData.gtm_currency,
        gtm_revenue: responsePack.firebaseData.gtm_revenue,
      })
    },
    async purchaseOffer(offerIndex: number | null = null) {
      if (offerIndex !== null) {
        await this.purchaseOfferIndex(offerIndex)
        return
      }
      if (this.offerDataArray[offerIndex]?.gems_price) {
        this.popup = true
        return
      }
      if (Object.keys(this.offerDataItemObject).length && offerIndex != null) {
        this.selectedOfferStoreId = this.offerDataArray[offerIndex].store_id
      } else {
        this.selectedOfferStoreId = this.offerDataItemObject.store_id
      }

      if (!this.isMobileLocalStorage && this.offerDataItemObject.price?.price > 0) {
        this.terminal = true
        return
      }

      this.showPaymentLoading()

      const responsePay = await this.$axios.get<{}, PremiumPayApiResponse>(
        `${metaPremiumPayEndpoint}/pack/${this.selectedOfferStoreId}${
          !this.$isMobile() ? '/bank_transfer' : ''
        }`,
      )
      if (responsePay?.event) {
        const message = {
          event: responsePay.event,
          productId: responsePay.productId,
        }
        sendToFlutter(JSON.stringify(message))
        return
      }
      const responsePack = await this.$axios.get<{}, PremiumPackApiResponse>(
        metaPremiumPackEndpoint + this.selectedOfferStoreId,
      )
      this.showPaymentMessage({
        pack: this.selectedOfferStoreId,
        rewards: responsePack.rewards,
        failed: false,
        af_quantity: responsePack.firebaseData.af_quantity,
        af_store_id: responsePack.firebaseData.af_store_id,
        gtm_currency: responsePack.firebaseData.gtm_currency,
        gtm_revenue: responsePack.firebaseData.gtm_revenue,
      })
    },
    async buyWeeklyPack(): Promise<void> {
      if (!this.offerDataItemObject.gems_price) this.showPaymentLoading()

      try {
        const responsePay = await this.$axios.get<{}, PremiumPayApiResponse>(
          `${metaPremiumPayEndpoint}/pack/${this.offerDataItemObject.store_id}/credit_card`,
        )
        this.popup = false
        if (responsePay?.event) {
          const message = {
            event: responsePay.event,
            productId: responsePay.productId,
          }
          sendToFlutter(JSON.stringify(message))
          return
        }
        const responsePack = await this.$axios.get<{}, PremiumPackApiResponse>(
          metaPremiumPackEndpoint + this.offerDataItemObject.store_id,
        )
        this.showPaymentMessage({
          pack: this.offerDataItemObject.store_id,
          rewards: responsePack.rewards,
          failed: false,
          af_quantity: responsePack.firebaseData.af_quantity,
          af_store_id: responsePack.firebaseData.af_store_id,
          gtm_currency: responsePack.firebaseData.gtm_currency,
          gtm_revenue: responsePack.firebaseData.gtm_revenue,
        })
      } catch (error) {
        console.error(error)
      }
    },
    offerIsPayed(): boolean {
      return (this.offerDataItemObject?.price?.price || this.offerDataItemObject?.gems_price) > 0
    },
    offerArrayIsPayed(offerIndex: number): boolean {
      return (
        (this.offerDataArray[offerIndex]?.price?.price || this.offerDataItemObject?.gems_price) > 0
      )
    },
  },
})
</script>

<style lang="scss" scoped>
.offer-detail {
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;

  main {
    flex-direction: column;
  }

  &-header {
    width: 110rem;
    height: 10.5rem;
    margin: 0 auto;

    &-image {
      max-height: 10rem;
      filter: drop-shadow(0 0.25rem 0.25rem rgba(0, 0, 0, 0.7));

      &.limited {
        max-height: 9rem;
      }

      &-bg {
        top: -4rem;
        width: 70rem;
      }
    }

    & .close-icon {
      right: 5rem;
    }
  }

  &-buttons {
    width: 96rem;
    margin-top: 2rem;

    &-wrap {
      width: 33%;
    }
  }
}
</style>
