import {
  GAME_PASS_DOUBLE_ENERGY_REGEN,
  GAME_PASS_DOUBLE_START_REGEN,
  GAME_PASS_ENERGY_CAP,
  GAME_PASS_STARTS_CAP,
} from '@/globalVariables'

export const getResourceUnit = (resource_id: string, inText?: boolean): string => {
  switch (resource_id) {
    case 'event_pass_training_discount':
      return !inText ? '%' : '%'
    case 'event_pass_free_start_chance':
      return !inText ? '%' : '%'
    case 'event_pass_free_spins_lucky_wheel':
      return !inText ? 'x' : ''
    case 'event_pass_free_daily_energy_starts':
      return !inText ? 'x' : ''
    case 'event_pass_warehouse_bonus':
      return !inText ? '%' : '%'
    case 'event_pass_free_daily_pack':
      return !inText ? 'x' : ''
    case 'event_pass_convertor_skip':
      return !inText ? 'x' : ''
    case 'reputation_bonus':
      return !inText ? '%' : '%'
    case GAME_PASS_DOUBLE_ENERGY_REGEN:
      return !inText ? 'x' : ''
    case GAME_PASS_DOUBLE_START_REGEN:
      return !inText ? 'x' : ''
    case GAME_PASS_ENERGY_CAP:
      return !inText ? '+' : ''
    case GAME_PASS_STARTS_CAP:
      return !inText ? '+' : ''
  }
}
