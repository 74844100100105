<template>
  <div class="gp-popup popup-about" :class="{ 'popup-web': !$isMobile() }">
    <header>
      <app-main-icon v-if="tier?.gpName" :icon-name="`gp-${tier?.gpName}`" :icon-size="152" />
      <aside>
        <p class="text-texts-standard-default text-36 uppercase text-left">
          {{ $replacePlaceholder($t(getGpContentText()), '{grandprize}', GRAND_PRIZE) }}
        </p>
        <div v-if="tier" class="header-badges flex">
          <grand-prize-badges
            v-for="item in tier.badges"
            :key="item"
            class="flexing"
            :badge="item"
            size="sm"
          />
        </div>
      </aside>
      <div class="header-btn">
        <app-button btn-type="confirm" btn-size="sm" @click="changeContent">
          <div class="text-row">
            <div v-if="!switchContent">
              <span class="m-4">{{ $t('button.detail') }} </span>
              <span v-if="$isWsm" class="arrw"> ► </span>
            </div>
            <div v-else>
              <span v-if="$isWsm" class="arrw"> ◄ </span>
              <span class="m-4">{{ $t('button.back') }} </span>
            </div>
          </div>
        </app-button>
      </div>
    </header>
    <main>
      <section v-if="!switchContent">
        <template v-if="tier">
          <div v-for="(slot, i) in tier.gpSlots" :key="i" class="gp-popup-row">
            <div class="row-first flexing">
              <grand-prize-badges class="flexing" :badge="slot.slot_rarity" size="md" />
            </div>
            <div class="row-others">
              <div
                v-for="(possibleReward, index) in slot.possible_rewards"
                :key="index + possibleReward.parameter"
                class="row-standard flexing"
              >
                <aside>
                  <p class="text-texts-standard-additional text-30 uppercase">
                    {{
                      $replacePlaceholder(
                        $t('common.probability'),
                        '%s',
                        possibleReward.drop_rate.toString() + '%',
                      )
                    }}
                  </p>
                  <div class="numeral-values">
                    <p
                      v-tippy="{
                        content: `${$filters.$abbrNumber(
                          possibleReward.from,
                        )} - ${$filters.$abbrNumber(possibleReward.to)}`,
                        placement: 'top',
                        theme: 'WSM',
                      }"
                      class="text-texts-standard-default text-30"
                    >
                      {{ $filters.$abbrNumber(possibleReward.from) }}
                      -
                      {{ $filters.$abbrNumber(possibleReward.to) }}
                    </p>
                    <app-main-icon :icon-size="48" :icon-name="possibleReward.parameter" />
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </template>
      </section>
      <section v-else>
        <article class="flexing">
          <p>
            {{ $t('grandPrize.dropRateInfo') }}. <br /><br />
            {{ $replacePlaceholder($t('grandPrize.dropRateAbout'), '{grandprize}', GRAND_PRIZE) }}.
          </p>
        </article>
      </section>
    </main>
  </div>
</template>

<script lang="ts">
import GrandPrizeBadges from '@/components/GrandPrize/GrandPrizeBadges.vue'
import { GRAND_PRIZE } from '@/globalVariables'
import { useGrandPrizeStore } from '@/store/pinia/grandPrize/mainPrizeStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { Nullable } from '@/interfaces/utils'
import type { GrandPrizeTierData } from '@/interfaces/GrandPrize'

interface ComponentData {
  GRAND_PRIZE: typeof GRAND_PRIZE
  switchContent: boolean
}

export default defineComponent({
  name: 'GrandPrizePopup',
  components: {
    GrandPrizeBadges,
  },
  props: {
    level: {
      type: Number,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      GRAND_PRIZE,
      switchContent: false,
    }
  },
  computed: {
    ...mapState(useGrandPrizeStore, {
      getTierInfo: 'getTierInfo',
    }),
    tier(): Nullable<GrandPrizeTierData> {
      return this.getTierInfo(this.level) ?? null
    },
  },
  created(): void {
    this.loadTierInfo(this.level)
  },
  methods: {
    ...mapActions(useGrandPrizeStore, {
      loadTierInfo: 'loadTierInfo',
    }),
    changeContent(): void {
      this.switchContent = !this.switchContent
    },
    getGpContentText(): string {
      const mapping = {
        1: 'basicContent',
        2: 'bronzeContent',
        3: 'silverContent',
        4: 'goldenContent',
        5: 'diamondContent',
      }
      return 'grandPrize.' + mapping[this.level]
    },
  },
})
</script>

<style lang="scss" scoped>
.gp-popup {
  width: 85.45rem;
  height: 41.25rem;
  margin-top: 0.625rem;

  header {
    background: url($path-grand-prize + 'popup/BG_HEADER_PRIZES_BOX.avif') center no-repeat;
    width: 79.25rem;
    height: 9rem;
    margin: 0 auto;
    display: flex;
    align-items: center;

    aside {
      width: 41.5rem;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin-left: 2rem;

      .header-badges {
        & > div {
          margin: 0 0.75rem 0 0;
        }
      }

      .header-btn {
        display: flex;

        .btn-text {
          width: 80%;
        }
      }
    }
  }

  main {
    margin: 2.063rem 0;

    .gp-popup-row {
      height: 8.563rem;
      width: 83rem;
      display: flex;
      margin: 0.75rem auto 0;

      @if $isWsm {
        background-color: rgba($color: #236d9b, $alpha: 0.7);
        border: solid 0.063rem #6c9ec4;
      }

      @if $isSsm {
        background-color: rgba(153, 149, 217, 0.5);
        border: solid 0.125rem #7477a4;
      }

      .row-first {
        height: 100%;
        width: 12.688rem;
        clip-path: polygon(0% 0%, 100% 0%, 96% 100%, 0% 100%);

        @if $isWsm {
          background-color: rgba($color: #1b2f47, $alpha: 0.7);
        }

        @if $isSsm {
          background-color: #1a253f;
        }
      }

      .row-others {
        width: 70.25rem;
        display: flex;
        align-items: center;

        .row-standard {
          width: 14rem;
          height: 6.25rem;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            right: 0;
            width: 0.125rem;
            height: 100%;
            background: url($path-grand-prize + 'popup/icon-border-right.avif') center no-repeat;
          }

          &:last-child {
            &:before {
              content: '';
              background: unset;
            }
          }

          .numeral-values {
            display: flex;
            align-items: center;
            height: 3.5rem;
            justify-content: flex-end;
          }
        }
      }
    }

    article {
      height: 31rem;
      margin: 0 5.625rem;

      & > p {
        font-size: theme('fontSize.30');
        color: theme('colors.texts.standard.default');
      }
    }
  }
}
</style>
