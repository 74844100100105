<template>
  <div data-board-games-event-handler />
  <payment-terminal
    v-if="storeId"
    :item-id="storeId"
    :wire-transfer-off="true"
    @close="storeId = null"
  />
</template>

<script lang="ts">
import {
  MECHANIC_BOARD_GAMES_EVENT,
  metaPremiumPackEndpoint as META_PREMIUM_PACK_ENDPOINT,
  metaPremiumPayEndpoint as META_PREMIUM_PAY_ENDPOINT,
} from '@/globalVariables'
import { CREATE_BOARD_GAMES_EVENT_OSRA } from '@/map-phaser-new/constants'
import { openBoardGamesEventAfterLogin, getIsMobileLocalStorage, sendToFlutter } from '@/helpers'
import PaymentTerminal from '@/components/Popup/Premium/PaymentTerminal.vue'
import { useBoardGamesEventStore } from '@/store/pinia/boardGamesEventStore'
import { useUserStore } from '@/store/pinia/userStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useRenderQueueStore } from '@/store/pinia/renderQueueStore'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { PaymentMethods } from '@/interfaces/Payments'
import { RenderQueueComponent } from '@/enums/RenderQueueTypes'
import { BoardGamesEventPostMessageType } from '@/interfaces/BoardGamesEventPostMessageInterface'
import type PremiumPayApiResponse from '@/interfaces/responses/premium/PremiumPayApiResponse'
import type PremiumPackApiResponse from '@/interfaces/responses/premium/PremiumPackApiResponse'
import type { MessageData } from '@/views/BoardGamesEventView.vue'
import type { Nullable } from '@/interfaces/utils'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  storeId: Nullable<string>
}

export default defineComponent({
  name: 'MapBoardGamesEventHandler',
  components: {
    PaymentTerminal,
  },
  data(): ComponentData {
    return {
      storeId: null,
    }
  },
  computed: {
    ...mapState(useBoardGamesEventStore, ['isEventTeaserActive', 'isEventActive', 'isEventLocked']),
    ...mapState(useUserStore, ['isBoardGamesEventTeaserSeen', 'isBoardGamesEventSeen']),
    ...mapState(useTutorialStore, { isTutorial: 'getIsTutorial' }),
    ...mapState(useResponseTaskStore, { mechanics: 'getMechanics' }),
    ...mapState(useRenderQueueStore, { currentComponentInQueue: 'getCurrentComponentInQueue' }),
    ...mapState(usePhaserGameIntegrationStore, { phaserEventEmitter: 'getPhaserEventEmitter' }),
    isMobileLocalStorage(): boolean {
      return getIsMobileLocalStorage() === 1
    },
  },
  watch: {
    async currentComponentInQueue(): Promise<void> {
      if (this.currentComponentInQueue !== RenderQueueComponent.BoardGamesEventWindow) return
      await this.loadEvent(true)
      openBoardGamesEventAfterLogin()
    },
    mechanics: {
      async handler(): Promise<void> {
        if (!this.mechanics.includes(MECHANIC_BOARD_GAMES_EVENT)) return
        await this.createBoardGamesEventOsra()
      },
      deep: true,
    },
    async isEventTeaserActive(): Promise<void> {
      if (!this.isEventTeaserActive) return
      await this.createBoardGamesEventOsra()
    },
    async isEventActive(): Promise<void> {
      if (!this.isEventActive) return
      await this.createBoardGamesEventOsra()
    },
    async isEventLocked(): Promise<void> {
      if (this.isEventLocked) return
      await this.createBoardGamesEventOsra()
    },
  },
  async created(): Promise<void> {
    await this.createBoardGamesEventOsra()

    if (this.isTutorial) return
    if (!this.mechanics.includes(MECHANIC_BOARD_GAMES_EVENT)) return
    if (!this.isEventTeaserActive && !this.isEventActive) return
    if (this.isEventTeaserActive && this.isBoardGamesEventTeaserSeen) return
    if (this.isEventActive && this.isBoardGamesEventSeen) return

    this.addToRenderQueue(RenderQueueComponent.BoardGamesEventWindow)
  },
  mounted(): void {
    window.addEventListener('message', this.onMessage, { passive: true })
    document.addEventListener('visibilitychange', this.onVisibilityChange, { passive: true })
  },
  beforeUnmount(): void {
    window.removeEventListener('message', this.onMessage)
    document.removeEventListener('visibilitychange', this.onVisibilityChange)
  },
  methods: {
    ...mapActions(useBoardGamesEventStore, ['loadEvent']),
    ...mapActions(useRenderQueueStore, ['addToRenderQueue', 'setNextInRenderQueue']),
    ...mapActions(useResponseTaskStore, [
      'showPaymentLoading',
      'showPaymentMessage',
      'updateParametersAfterMinigame',
    ]),
    async onMessage({ data: { type, storeId } }: MessageData): Promise<void> {
      if (!type?.startsWith('BGE/')) return

      if (
        type === BoardGamesEventPostMessageType.Close &&
        this.currentComponentInQueue === RenderQueueComponent.BoardGamesEventWindow
      ) {
        this.setNextInRenderQueue()
      }

      if (
        [
          BoardGamesEventPostMessageType.Close,
          BoardGamesEventPostMessageType.RefreshResources,
        ].includes(type)
      ) {
        await this.updateParametersAfterMinigame()
      }

      if (type !== BoardGamesEventPostMessageType.Payment || !storeId) return

      if (!this.isMobileLocalStorage) {
        this.storeId = storeId
        return
      }

      this.showPaymentLoading()

      const responsePay = await this.$axios.get<{}, PremiumPayApiResponse>(
        `${META_PREMIUM_PAY_ENDPOINT}/pack/${storeId}/${
          !this.isMobileLocalStorage ? PaymentMethods.BankTransfer : ''
        }`,
      )

      if (responsePay?.event) {
        sendToFlutter(
          JSON.stringify({
            event: responsePay.event,
            productId: responsePay.productId,
          }),
        )
        return
      }

      const responsePack = await this.$axios.get<{}, PremiumPackApiResponse>(
        META_PREMIUM_PACK_ENDPOINT + storeId,
      )

      this.showPaymentMessage({
        pack: storeId,
        rewards: responsePack.rewards,
        failed: false,
        af_quantity: responsePack.firebaseData.af_quantity,
        af_store_id: responsePack.firebaseData.af_store_id,
        gtm_currency: responsePack.firebaseData.gtm_currency,
        gtm_revenue: responsePack.firebaseData.gtm_revenue,
      })
    },
    async onVisibilityChange(): Promise<void> {
      if (document.visibilityState === 'hidden') return
      if (this.$route.name === 'BoardGamesEventViewWeb') return

      await this.createBoardGamesEventOsra()
    },
    async createBoardGamesEventOsra(): Promise<void> {
      if (this.isTutorial) return
      if (!this.mechanics.includes(MECHANIC_BOARD_GAMES_EVENT)) return

      await this.loadEvent(true)

      if (this.isEventTeaserActive) return
      if (!this.isEventTeaserActive && !this.isEventActive) return
      if (this.isEventLocked) return

      this.phaserEventEmitter?.emit(CREATE_BOARD_GAMES_EVENT_OSRA)
    },
  },
})
</script>
