<template>
  <section class="app-page-wrapper premium-bags flex flex-col safe-area">
    <premium-menu />
    <component-loading :is-loading="!dataLoaded" />
    <main v-if="dataLoaded" class="flex h-full">
      <div class="premium-bags-left px-8 pt-6 h-full flex flex-col">
        <section class="premium-bags-info-wrapper text-left">
          <app-icon icon-name="question" @click="showAboutPopup = true" />

          <level-bags-about-popup :show-popup="showAboutPopup" @close="showAboutPopup = false" />
        </section>
        <section class="premium-bags-left-wrapper flex flex-col justify-between mt-8">
          <level-bag-small
            v-for="pack in levelBagOfferPacksData"
            :key="`bag-${pack.type}`"
            :pack-type="pack.type"
            :pack-status="pack.status"
            :price="pack.price"
            :value="pack.value"
            :store-id="pack.storeId"
          />
        </section>
      </div>
      <div class="premium-bags-right relative">
        <app-scrollbar
          height="96%"
          width="100%"
          scroll="x"
          slide="x"
          :arrows="true"
          arrow-pos="top-2/4"
          class="flex flex-col"
          scroll-to-element=".first-locked"
          scroll-to-element-align="end"
        >
          <div class="bags-header relative">
            <span
              v-for="level in levels"
              :key="`level-${level}`"
              class="header-item"
              :class="{ locked: level === getFirstlocked }"
            >
              <player-level
                class="bag-level"
                :class="{ 'opacity-50': level > parseInt(userLevel) }"
                :level="level"
              />
            </span>
          </div>
          <div class="flex reward-wrapper">
            <span
              v-for="level in levels"
              :key="`reward-col-${level}`"
              :class="{ 'first-locked': level === getFirstlocked }"
            >
              <span v-if="rewardsData[level]" class="reward-col flex flex-col justify-between">
                <level-bag-reward
                  v-for="(reward, index) in rewardsData[level]"
                  :key="index"
                  :reward-type="reward.type"
                  :reward-status="reward.status"
                  :reward="reward.reward"
                  :level="reward.level"
                  @reload-data="loadData()"
              /></span>
            </span>
          </div>
        </app-scrollbar>
      </div>
    </main>
  </section>
</template>

<script lang="ts">
import { levelBagsEndpoint } from '@/globalVariables'
import { defineComponent } from 'vue'

import LevelBagReward from '@/components/Premium/LevelBags/LevelBagReward.vue'
import LevelBagSmall from '@/components/Premium/LevelBags/LevelBagSmall.vue'
import LevelBagsAboutPopup from '@/components/Premium/LevelBags/LevelBagsAboutPopup.vue'
import PremiumMenu from '@/components/Premium/PremiumMenu.vue'
import PlayerLevel from '@/components/Profile/PlayerLevel.vue'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import type { RouteLocationNormalized } from 'vue-router'
import type {
  LevelContent,
  PremiumLevelBagsApiResponse,
  Reward,
} from '@/interfaces/responses/premium/PremiumLevelBagsApiResponse'
import type { Offer, LevelBagOfferPacksData, OfferParameter } from '@/interfaces/premium/Offer'

interface LevelBagsRewardsData {
  type: string
  status: string
  reward: Reward
  level: number
}

interface ComponentData {
  showAboutPopup: boolean
  levelBagOfferPacksData: LevelBagOfferPacksData[]
  levels: number[]
  rewardsData: Record<number, LevelBagsRewardsData[]>
  dataLoaded: boolean
}

export default defineComponent({
  name: 'PremiumLevelBags',
  components: {
    PremiumMenu,
    LevelBagsAboutPopup,
    LevelBagSmall,
    LevelBagReward,
    PlayerLevel,
  },
  data(): ComponentData {
    return {
      showAboutPopup: false,
      levelBagOfferPacksData: null,
      levels: null,
      rewardsData: [],
      dataLoaded: false,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userLevel: 'getLevel',
    }),
    getFirstlocked(): number {
      if (!this.levels) return 0
      return (
        this.levels.find((level: number): boolean => level > parseInt(this.userLevel)) ??
        this.levels[0]
      )
    },
  },
  watch: {
    $route(route: RouteLocationNormalized): void {
      if (route.query.reloadData) {
        this.loadData()
        if (route.query.reloadData) {
          this.$router.replace({
            ...this.$router.currentRoute,
            query: {
              reloadData: undefined,
            },
          })
        }
      }
    },
  },
  created(): void {
    this.loadData()
  },
  methods: {
    async loadData(): Promise<void> {
      const response = await this.$axios.get<{}, PremiumLevelBagsApiResponse>(levelBagsEndpoint)
      this.levels = response.levels

      response.levels.forEach((level: number): void => {
        this.rewardsData[level] = []
        response.content.map((reward: LevelContent): void => {
          if (reward.level !== level) {
            return
          }
          this.rewardsData[level].push({
            type: reward.type,
            status:
              response.states &&
              response.states[`lvl_bag_offer_${reward.type}`] &&
              response.states[`lvl_bag_offer_${reward.type}`][String(level)] &&
              level <= parseInt(this.userLevel)
                ? response.states[`lvl_bag_offer_${reward.type}`][String(level)]
                : 'lock',
            reward: reward.rewards,
            level: level,
          })
        })
      })
      this.levelBagOfferPacksData = [
        {
          type: 'bronze',
          status: 'claimed',
          price: {
            price: 0,
            currency: 'EUR',
          },
          storeId: 'bronze-1',
          value: 0,
        },
      ]
      const mutatedPacks = response.offers.map((pack: Offer): LevelBagOfferPacksData => {
        let type = ''
        if (pack.store_id.includes('silver')) {
          type = 'silver'
        }
        if (pack.store_id.includes('gold')) {
          type = 'gold'
        }
        if (pack.store_id.includes('diamond')) {
          type = 'diamond'
        }
        return {
          type: type,
          status: pack.remaining_buys < 1 ? 'claimed' : 'locked',
          value:
            parseInt(
              pack.parameters?.find((param: OfferParameter): boolean => param.name === 'value')
                ?.value,
            ) ?? 0,
          price: pack.price,
          storeId: pack.store_id,
        }
      })
      this.levelBagOfferPacksData = [...this.levelBagOfferPacksData, ...mutatedPacks]
      this.dataLoaded = true
    },
  },
})
</script>

<style lang="scss" scoped>
.premium-bags {
  &-left {
    width: 36.5rem;
    @if $isWsm {
      background: #1b2f47;
    }
    @if $isSsm {
      background: #22293e;
    }

    &-wrapper {
      height: 40rem;
    }
  }

  &-right {
    width: calc(100% - 36.5rem);
    padding-left: 1rem;
    padding-top: 1rem;
    padding-right: 1rem;
    .bags-header {
      height: 5rem;
      white-space: nowrap;
      margin-left: 0.5rem;
      top: 0.5rem;

      .header-item {
        display: inline-flex;
        width: 15.875rem;
        justify-content: center;
        padding-right: 1rem;
        box-sizing: content-box;
        @if $isWsm {
          background: rgba(20, 74, 115, 0.9);
        }
        @if $isSsm {
          background: rgba(86, 98, 127, 0.96);
        }

        &.locked {
          padding-left: 1rem;
        }

        &:first-child {
          @if $isWsm {
            background: linear-gradient(90deg, rgba(20, 74, 115, 0) 0%, rgba(20, 74, 115, 0.9) 25%);
          }
          @if $isSsm {
            background: linear-gradient(
              90deg,
              rgba(86, 98, 127, 0) 0%,
              rgba(86, 98, 127, 0.96) 25%
            );
          }
        }

        &:last-child {
          @if $isWsm {
            background: linear-gradient(
              90deg,
              rgba(20, 74, 115, 0.9) 75%,
              rgba(20, 74, 115, 0) 100%
            );
          }
          @if $isSsm {
            background: linear-gradient(
              90deg,
              rgba(86, 98, 127, 0.96) 75%,
              rgba(86, 98, 127, 0) 100%
            );
          }
        }
      }
    }

    .reward-wrapper {
      margin-top: 3.5rem;

      .reward-col {
        height: 37rem;
        margin-right: 1rem;
      }

      .first-locked {
        .reward-col {
          padding-left: 1rem;
          border-left: 0.13rem solid #7adefe;
        }
      }
    }
  }

  &-info {
    &-wrapper {
      width: 32.5rem;
    }
  }
}
</style>
