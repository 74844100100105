<template>
  <div
    class="training-discipline-detail app-page-wrapper absolute"
    :class="{ 'bg-none': isEventView }"
    data-cy="training-discipline-detail"
  >
    <training-menu
      v-if="!isEventView"
      :is-sub-menu-active="true"
      :current="$getWebView('TrainingDisciplineDetail')"
      :active-discipline="activeDiscipline"
    />

    <section class="absolute w-full h-full safe-area" :class="{ 'flexing flex-col': isEventView }">
      <main>
        <div v-if="!isEventView" class="view-discipline-header">
          <modal-head :title-popup="$t('trainingHall.aboutTrainingHeaderInfo')">
            <discipline-menu
              id-discipline="training-tutorial-menu"
              :progress="true"
              :emit-click-event="true"
              :type="TRAINING_HALL"
              @on-click-discipline="getDisciplineContent"
            />
            <template #popupContent>
              <training-popup />
            </template>
          </modal-head>
        </div>
        <training-discipline
          :mini-game-mode="mode.task_training"
          :discipline="activeDiscipline"
          :is-trainer="false"
          :active-discipline-id="activeDisciplineId"
          :is-event-view="isEventView"
          :is-attribute-upgrading="isInstantUpgrade"
        >
          <template #section-upgrade-attribute-btn="{ isButtonDisabled }">
            <arrow-animation
              data-id="training-instant-up-btn"
              add-class="training-instant-up-btn--tutorial"
              :tutorial="isTutorial"
              border="button"
              position="right"
            >
              <app-multi-button1
                v-tippy="{
                  theme: 'WSM',
                  content: tooltipDescription,
                  placement: 'left',
                }"
                btn-id="training-instant-up-btn"
                btn-size="md"
                btn-theme="confirm"
                class="relative"
                :loading="isInstantUpgrade"
                :btn-text="$t('button.levelUpNow')"
                :custom-width="31.875"
                :btn-count="levelUpPrice"
                :btn-type="UNIVERSAL_TRAINING_POINTS"
                :disabled="
                  levelUpPrice === null ||
                  !enoughUtpForLevelUp ||
                  buildingLimitReached ||
                  isButtonDisabled
                "
                @click="upgradeAttribute"
              />
            </arrow-animation>
          </template>
        </training-discipline>
      </main>
      <training-discipline-footer
        :benefits-slots="benefitTrainingHallSlots"
        @init-load="initLoad"
      />
    </section>
  </div>
</template>

<script lang="ts">
import DisciplineMenu from '@/components/DisciplineMenu.vue'
import ModalHead from '@/components/ModalHead.vue'
import TrainingDiscipline from '@/components/Training/TrainingDiscipline.vue'
import TrainingDisciplineFooter from '@/components/Training/TrainingDisciplineFooter.vue'
import TrainingPopup from '@/components/Training/TrainingPopup.vue'
import {
  BENEFITS_TRAINING_HALL,
  MECHANIC_PERSONAL_TRAINER,
  TRAINING_HALL,
  UNIVERSAL_TRAINING_POINTS,
  upgradeAttributeEndpoint,
} from '@/globalVariables'
import { playSound } from '@/helpers'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useModeStore } from '@/store/pinia/modeStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import TrainingMenu from '@/views/training/TrainingMenu.vue'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import ArrowAnimation from '@/components/ArrowAnimation.vue'

import { StageType } from '@/interfaces/Tutorial'
import type { RouteLocationNormalized } from 'vue-router'
import type { Nullable, TrueFalseInterface } from '@/interfaces/utils'
import type { DisciplineMenuData } from '@/interfaces/DisciplineMenu'
import type { BenefitSlot, UserBenefitsSlotsApiResponse } from '@/interfaces/Benefits'

interface ComponentData {
  TRAINING_HALL: typeof TRAINING_HALL
  UNIVERSAL_TRAINING_POINTS: typeof UNIVERSAL_TRAINING_POINTS
  MECHANIC_PERSONAL_TRAINER: typeof MECHANIC_PERSONAL_TRAINER
  activeDisciplineId: Nullable<number>
  textsForTranslate: string[]
  isInstantUpgrade: boolean
}

export default defineComponent({
  name: 'TrainingDisciplineDetail',
  components: {
    TrainingMenu,
    TrainingDiscipline,
    DisciplineMenu,
    ModalHead,
    TrainingPopup,
    ArrowAnimation,
    TrainingDisciplineFooter,
  },
  props: {
    isEventView: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      TRAINING_HALL,
      UNIVERSAL_TRAINING_POINTS,
      MECHANIC_PERSONAL_TRAINER,
      activeDisciplineId: null,
      textsForTranslate: [
        'trainingHall.header',
        'common.building',
        'trainingHall.tutorials',
        'trainingHall.training',
        'trainingHall.trainer',
        'trainingHall.disciplines',
        'trainingHall.notEnoughTpInfo',
        'button.levelUpNow',
        'trainingHall.aboutTrainingHeaderInfo',
        'trainingHall.aboutTrainingIncreaseAttributesInfo',
        'trainingHall.aboutTrainingTpInfo',
        'trainingHall.aboutTrainingUniversalTpInfo',
        'rankingsGroup.completeQuestsToUnlock',
      ],
      isInstantUpgrade: false,
    }
  },
  computed: {
    enoughUtpForLevelUp(): boolean {
      return this.universalTrainingPoints.value >= this.levelUpPrice
    },
    levelUpPrice(): Nullable<number> {
      if (this.activeDiscipline == null) return
      const output =
        this.activeDiscipline.trainingPointsTarget - this.activeDiscipline.userStats.trainingPoints
      return isNaN(output) ? null : output
    },
    isTutorial(): boolean {
      return (
        this.actualStageData?.name === 'clickOnLevelUpAttribute' &&
        this.actualStageData?.type === StageType.ClientInput
      )
    },
    ...mapState(useModeStore, { mode: 'getMode' }),
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
    }),
    ...mapState(useResponseTaskStore, {
      benefitsSlots: 'getBenefitsSlots',
      universalTrainingPoints: 'getUniversalTrainingPoints',
    }),
    ...mapState(useEventInfoStore, {
      eventDisciplineId: 'getEventDisciplineId',
    }),
    ...mapState(useDisciplineStore, {
      firstUnlockedDisciplineId: 'getFirstUnlockedDisciplineId',
      activeDiscipline: 'getSpecificDiscipline',
      unlockDisciplinesIds: 'getUnlockedDisciplinesIdsUnsorted',
    }),
    buildingLimitReached(): boolean {
      return this.activeDiscipline.buildingLimitReached
    },
    tooltipDescription(): string {
      if (this.buildingLimitReached) return this.$t('trainingHall.attributeLimitInfo')
      if (!this.enoughUtpForLevelUp) return this.$t('trainingHall.notEnoughTpInfo')
      return ''
    },
    benefitTrainingHall(): UserBenefitsSlotsApiResponse[] {
      return (
        this.benefitsSlots?.filter(
          (benefit: UserBenefitsSlotsApiResponse): boolean =>
            benefit.benefit_building === BENEFITS_TRAINING_HALL,
        ) ?? []
      )
    },
    benefitTrainingHallSlots(): BenefitSlot {
      const benefitTrainingHallDataSlots: BenefitSlot['slots'] = {}
      const benefitTrainingHallDataBenefitTypes: string[] = []

      this.benefitTrainingHall.forEach((element: UserBenefitsSlotsApiResponse) => {
        benefitTrainingHallDataSlots[element?.slot_id] = element.benefit
        benefitTrainingHallDataBenefitTypes.push(element.benefit_slot_type)
      })

      return {
        benefit_type: benefitTrainingHallDataBenefitTypes,
        slots: benefitTrainingHallDataSlots,
      }
    },
  },
  watch: {
    $route(to: RouteLocationNormalized, from: RouteLocationNormalized): void {
      if (to.name === this.$getWebView(this.$options.name) || to.query.reloadData) {
        this.activeDisciplineId =
          Number(this.$route.params.id as string) || this.firstUnlockedDisciplineId

        this.loadSpecificDiscipline({ disciplineId: this.activeDisciplineId })

        if (from.name !== this.$getWebView('TrainingDisciplineDetail')) {
          this.loadBenefitsSlots()
        }

        if (to.query.reloadData) {
          this.$router.replace({
            ...this.$router.currentRoute,
            query: {
              reloadData: undefined,
            },
          })
        }
      }
    },
    firstUnlockedDisciplineId(value: Nullable<number>): void {
      if (value) {
        this.initLoad()
      }
    },
  },
  async created(): Promise<void> {
    await this.initLoad()
    this.loadBenefitsSlots()
  },
  methods: {
    ...mapActions(useResponseTaskStore, {
      loadBenefitsSlots: 'loadBenefitsSlots',
    }),
    ...mapActions(useDisciplineStore, {
      loadSpecificDiscipline: 'loadSpecificDiscipline',
    }),
    initLoad(): void {
      if (this.isEventView) {
        this.activeDisciplineId = this.eventDisciplineId
      } else {
        this.activeDisciplineId = isNaN(Number(this.$route.params.id as string))
          ? this.firstUnlockedDisciplineId
          : this.unlockDisciplinesIds.includes(Number(this.$route.params.id as string))
            ? Number(this.$route.params.id as string)
            : null
      }
      if (this.activeDisciplineId) {
        this.loadSpecificDiscipline({ disciplineId: this.activeDisciplineId })
      }
    },
    async upgradeAttribute(): Promise<void> {
      this.isInstantUpgrade = true
      if (this.buildingLimitReached) {
        this.isInstantUpgrade = false

        return
      }
      const price = this.levelUpPrice
      if (price === null || !this.enoughUtpForLevelUp) {
        this.isInstantUpgrade = false

        return
      }
      await this.$axios.put<{}, TrueFalseInterface, { value: number; discipline_id: number }>(
        upgradeAttributeEndpoint,
        {
          value: price,
          discipline_id: this.activeDiscipline.id,
        },
      )

      playSound('click-button-upgrade-skill')
      this.isInstantUpgrade = false
    },
    getDisciplineContent(discipline: DisciplineMenuData): void {
      this.activeDisciplineId = discipline.id
    },
  },
})
</script>

<style lang="scss" scoped>
.view-discipline-header {
  width: 100%;
  height: 7rem;
  margin: 1.188rem auto 1.813rem;
  z-index: 0;
}

main {
  width: 120.563rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

#training-instant-up-btn {
  position: relative;
  margin-top: 2.625rem;
  left: 50%;
  transform: translate(-50%) $skew-value;

  &:active {
    transform: translate(-50%) $skewX-value scale(0.95) !important;
  }
}

.training-instant-up-btn--tutorial {
  position: relative;
  width: 31.875rem;
  height: 4.375rem;
  margin: 2.625rem auto 0;

  #training-instant-up-btn {
    margin-top: 0;
  }
}
</style>
