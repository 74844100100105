<template>
  <div
    id="club-championship-competitions"
    class="club-championship-competitions app-page-wrapper flex flex-col absolute w-full h-full"
  >
    <clubs-championship-menu />
    <section class="club-championship-competitions-wrapper w-full h-full safe-area">
      <div v-if="isLocked" class="club-championship-competitions-lock">
        <div class="club-championship-competitions-lock-content">
          <app-icon icon-name="question" @click="showAboutPopup = true" />
          <section class="flexing">
            <app-icon
              v-if="!hasClub || club.members.length < MINIMUM_CLUB_MEMBERS_TO_UNLOCK"
              class="task-lock-icon"
              icon-name="lock-xxl"
            />
            <div class="lock-content-message flexing">
              <p
                class="text-36 uppercase"
                :class="$isWsm ? 'text-texts-standard-name' : 'text-texts-standard-additional'"
                v-html="selectText"
              />
            </div>
            <app-button
              v-if="!isClubInChampionship && !hasClub"
              btn-id="championship-go-to-clubs-btn"
              btn-size="md"
              btn-type="secondary"
              :btn-text="$t('bugReport.clubs')"
              @click="goToClubs"
            />
            <app-button
              v-if="!isClubInChampionship && hasClub && isClubManagerOrAssistant"
              btn-id="championship-find-players-btn"
              btn-size="md"
              btn-type="secondary"
              :btn-text="$t('club.findPlayers')"
              @click="goToAvailablePlayers"
            />
          </section>
        </div>
      </div>
      <div v-else class="club-championship-competitions-unlock">
        <seasons-recalculation-popup />

        <section class="competitions-unlock-wrapper flex flex-col">
          <header class="w-full flex items-center mb-5">
            <app-icon icon-name="question" class="mr-8" @click="showAboutPopup = true" />
            <aside class="league-panel">
              <clubs-championship-league-info
                :league="getDetail.division?.type"
                :division="getDetail.division?.number"
                :rookie-league-number="getDetail.division?.rookieLeagueNumber"
              >
                <tippy theme="WSM" max-width="31.725rem" placement="bottom">
                  <div class="club-position-panel flex">
                    <div class="club-position-panel-left revert-skew">
                      <p class="text-texts-standard-default text-36 leading-8">
                        {{ $t('arena.yourPosition') }}
                      </p>
                      <div class="club-position-panel-left-count">
                        <p class="text-texts-standard-important text-36 font-bold">
                          {{ getDetail.position }}
                        </p>
                      </div>
                    </div>
                    <div class="club-position-panel-right flexing">
                      <div class="triangle-border triangle-skew absolute">
                        <div class="absolute" :class="getArrowPosition" />
                      </div>
                      <aside class="revert-skew flexing">
                        <p class="text-texts-standard-dark text-36 font-bold">
                          {{ $filters.$formatNumber(getDetail.championshipPoints ?? 0) }}
                        </p>
                        <app-main-icon
                          :icon-size="56"
                          :icon-name="CHAMPIONSHIP_POINTS"
                          class="icon-trophy"
                        />
                      </aside>
                    </div>
                  </div>
                  <template #content>
                    <div class="club-postion-panel-tooltip">
                      <div class="club-postion-panel-tooltip-title">
                        <p class="text-texts-standard-default text-32">
                          {{ $t('club.currentSeason') }}
                        </p>
                      </div>
                      <aside class="flexing my-4">
                        <div
                          class="club-postion-panel-tooltip-badge"
                          :class="'badge-' + toKebabCase(getActualLeagueBadge) + '-100'"
                        />
                        <p
                          class="text-texts-standard-important text-28 font-bold uppercase mx-4 club-postion-panel-tooltip-text"
                        >
                          {{ currentLeagueName }}
                        </p>
                      </aside>

                      <div class="club-postion-panel-tooltip-arrow">
                        <div class="triangle-border absolute">
                          <div class="absolute" :class="getArrowPosition" />
                        </div>
                      </div>

                      <div class="club-postion-panel-tooltip-title next mt-14">
                        <p class="text-texts-standard-default text-32">
                          {{ $t('club.nextSeason') }}
                        </p>
                      </div>
                      <aside class="flexing my-4">
                        <div
                          class="club-postion-panel-tooltip-badge"
                          :class="'badge-' + toKebabCase(getNextLeagueBadge) + '-100'"
                        />
                        <p
                          class="text-texts-standard-default text-28 uppercase mx-4 club-postion-panel-tooltip-text"
                        >
                          {{ nextLeagueName }}
                        </p>
                      </aside>
                    </div>
                  </template>
                </tippy>
              </clubs-championship-league-info>
            </aside>
          </header>
          <div class="club-wrapper flexing">
            <div class="main-duel-main flex justify-around item-center">
              <div class="main-duel-main-leftbox">
                <table-starts
                  v-if="discipline"
                  :opponents="duelOpponents"
                  :discipline-id="discipline.id"
                  :benefits-slots="benefitClubChampionshipSlots.slots"
                />
              </div>

              <arena-competitions-discipline-detail
                v-if="discipline"
                :active-discipline-id="discipline.id"
                :benefits-slots="benefitClubChampionshipSlots.slots"
                :multiplier-context="Multiplier.ClubChampionship"
                @play-duel="playDuel"
              />
            </div>
          </div>
          <footer class="championship-footer my-4">
            <arena-competitions-footer
              :allowed-benefit-types="benefitClubChampionshipSlots.benefit_type"
              :expiration="expiration"
              :benefits-slots="benefitClubChampionshipSlots.slots"
              :required-benefits="
                startsCostConfig.base * activeMultiplicator(Multiplier.ClubChampionship).value
              "
              :type="CLUB_CHAMPIONSHIP"
              @load-opponents="loadOpponents"
              @switch-match-type="switchMatchType"
              @refresh-opponents="refreshOpponents"
              @load-specific-discipline="loadOpponentsWithDisciplineInfo"
            />
          </footer>
        </section>
      </div>
      <popup-window
        v-if="showAboutPopup"
        popup-type="info"
        :popup-title="$t('club.clubChampionship')"
        @close="showAboutPopup = false"
      >
        <clubs-championship-about-popup />
      </popup-window>

      <parameter-popup
        v-if="isParameterPopupOpen"
        :parameter="'starts'"
        @close="isParameterPopupOpen = false"
      />

      <skip-tutorial-popup
        v-if="showSkipTutorialPopup"
        :discipline-id="discipline.id"
        :multiplier="activeMultiplicator(Multiplier.ClubChampionship).name"
        :no-tutorial-mode="modes.club_duel"
        @hide-popup="showSkipTutorialPopup = false"
      />
    </section>
  </div>
</template>

<script lang="ts">
import ArenaCompetitionsDisciplineDetail from '@/components/Arena/ArenaCompetitions/ArenaCompetitionsDisciplineDetail.vue'
import ArenaCompetitionsFooter from '@/components/Arena/ArenaCompetitions/ArenaCompetitionsFooter.vue'
import ClubsChampionshipAboutPopup from '@/components/Club/Championship/ClubsChampionshipAboutPopup.vue'
import ClubsChampionshipLeagueInfo from '@/components/Club/Championship/ClubsChampionshipLeagueInfo.vue'
import ClubsChampionshipMenu from '@/components/Club/Championship/ClubsChampionshipMenu.vue'
import ParameterPopup from '@/components/Popup/Parameter/ParameterPopup.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import SeasonsRecalculationPopup from '@/components/Seasons/SeasonsRecalculationPopup.vue'
import SkipTutorialPopup from '@/components/SkipTutorialPopup.vue'
import TableStarts from '@/components/Table/TableStarts.vue'
import {
  ADVANCES,
  ARENA,
  BENEFITS_CLUB_CHAMPIONSHIP,
  CHAMPIONSHIP_POINTS,
  CLUB_CHAMPIONSHIP,
  CLUB_CHAMPIONSHIP_STATES,
  CLUB_DUEL,
  DESCENTS,
  LIVE,
  MINIMUM_CLUB_MEMBERS_TO_UNLOCK,
  STARTS,
  STAYS,
  MULTIPLIER,
  arenaMatchEndpoint,
  arenaNewOpponentsEndpoint,
  arenaOpponentsEndpoint,
  minigameUrl,
  newStatusChampionshipBuilding,
  Multiplier,
} from '@/globalVariables'
import { openMiniGame, playSound, toKebabCase } from '@/helpers'
import { useDuelStore } from '@/store/pinia/arena/duelStore'
import { useOponentsStore } from '@/store/pinia/arena/oponentsStore'
import { useClubChampionshipStore } from '@/store/pinia/clubs/championshipStore'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useModeStore } from '@/store/pinia/modeStore'
import { useOfferStore } from '@/store/pinia/offerStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type {
  ArenaMatchResponse,
  ArenaOpponentsResponse,
  ArenaOpponent,
} from '@/interfaces/responses/arena/ArenaResponses'
import type { RouteLocationNormalized } from 'vue-router'

import type { ClubChampsClubProgress } from '@/interfaces/clubs/ClubChampionships'
import type { BenefitSlot, UserBenefitsSlotsApiResponse } from '@/interfaces/Benefits'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'

interface ComponentData {
  CHAMPIONSHIP_POINTS: typeof CHAMPIONSHIP_POINTS
  CLUB_CHAMPIONSHIP: typeof CLUB_CHAMPIONSHIP
  MINIMUM_CLUB_MEMBERS_TO_UNLOCK: typeof MINIMUM_CLUB_MEMBERS_TO_UNLOCK
  showAboutPopup: boolean
  isLocked: boolean
  duelOpponents: ArenaOpponent
  expiration: number
  timeToStart: number
  nextLeagueBadge: string
  isParameterPopupOpen: boolean
  showSkipTutorialPopup: boolean
  isLiveMatch: boolean
  ARROW_POSITIONS: {
    [key: string]: string
  }
  Multiplier: typeof Multiplier
}

export default defineComponent({
  name: 'ClubsChampionshipCompetitions',

  components: {
    ClubsChampionshipMenu,
    PopupWindow,
    ClubsChampionshipAboutPopup,
    ClubsChampionshipLeagueInfo,
    TableStarts,
    ArenaCompetitionsDisciplineDetail,
    ArenaCompetitionsFooter,
    ParameterPopup,
    SkipTutorialPopup,
    SeasonsRecalculationPopup,
  },

  data(): ComponentData {
    return {
      showAboutPopup: false,
      isLocked: true, // zamknute KM
      duelOpponents: null,
      expiration: null,
      timeToStart: 2400, // čas do zaciatku novy KM
      nextLeagueBadge: 'master',
      isParameterPopupOpen: false,
      showSkipTutorialPopup: false,
      isLiveMatch: false,
      CHAMPIONSHIP_POINTS,
      CLUB_CHAMPIONSHIP,
      ARROW_POSITIONS: {
        [ADVANCES]: 'triangle triangle-up green',
        [DESCENTS]: 'triangle triangle-down red position',
        [STAYS]: 'same-league-icon',
      },
      MINIMUM_CLUB_MEMBERS_TO_UNLOCK,
      Multiplier,
    }
  },

  computed: {
    ...mapState(useModeStore, { modes: 'getMode' }),
    ...mapState(useDuelStore, {
      startsCostConfig: 'getStartsCostConfig',
    }),
    ...mapState(useUserStore, {
      activeMultiplicator: 'getActiveMultiplicator',
      getShowResultScreen: 'getShowResultScreen',
      hasClub: 'getHasClub',
      isClubInChampionship: 'isClubInChampionship',
      isClubManagerOrAssistant: 'isClubManagerOrAssistant',
    }),
    ...mapState(useTutorialStore, {
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useClubChampionshipStore, {
      getDetail: 'getDetail',
      getClubChampionshipStart: 'getClubChampionshipStart',
    }),
    ...mapState(useClubStore, {
      club: 'getClubInfo',
    }),
    ...mapState(useResponseTaskStore, {
      benefitsSlots: 'getBenefitsSlots',
      userStarts: 'getStarts',
    }),
    ...mapState(useDisciplineStore, {
      discipline: 'getSpecificDiscipline',
    }),
    getProgress(): ClubChampsClubProgress {
      return this.getDetail.progress
    },
    getActualLeagueBadge(): string {
      return this.getDetail.division?.type
    },
    isEnoughClubPlayers(): boolean {
      return this.club.members.length >= MINIMUM_CLUB_MEMBERS_TO_UNLOCK
    },
    isChampionshipRunning(): boolean {
      return this.getClubChampionshipStart?.state === CLUB_CHAMPIONSHIP_STATES.running
    },
    getNextLeagueBadge(): string {
      return this.getProgress?.nextLeague
    },
    currentLeagueName(): string {
      return `${this.$t(`club.${this.getActualLeagueBadge}`)}
       ${
         this.getDetail.division?.rookieLeagueNumber !== 0
           ? this.getDetail.division?.rookieLeagueNumber
           : ''
       }`
    },
    nextLeagueName(): string {
      return `${this.$t(`club.${this.getNextLeagueBadge}`)} 
      ${
        this.getProgress?.rookieLeagueNumber && this.getProgress?.rookieLeagueNumber !== 0
          ? this.getProgress?.rookieLeagueNumber
          : ''
      }`
    },
    selectText(): string {
      if (!this.hasClub) {
        return (
          this.$replacePlaceholder(
            this.$replaceHtmlPlaceholders(
              this.$replacePlaceholder(this.$t('club.minimumPlayerText'), '%s', '{b} %s {/b}'),
              'b',
              'text-texts-standard-important',
            ),
            '%s',
            MINIMUM_CLUB_MEMBERS_TO_UNLOCK,
          ) +
          '<br> ' +
          this.$t('club.becomeMember')
        )
      } else if (!this.isEnoughClubPlayers) {
        return this.$replacePlaceholder(
          this.$replaceHtmlPlaceholders(
            this.$replacePlaceholder(this.$t('club.minimumPlayerText'), '%s', '{b} %s {/b}'),
            'b',
            'text-texts-standard-important',
          ),
          '%s',
          MINIMUM_CLUB_MEMBERS_TO_UNLOCK,
        )
      } else if (
        this.hasClub &&
        this.isEnoughClubPlayers &&
        (!this.isClubInChampionship || !this.isChampionshipRunning)
      ) {
        const date = this.getClubChampionshipStart?.nextStart?.date ?? ''
        const hours = this.getClubChampionshipStart?.nextStart?.time?.hours ?? ''
        const minutes = this.getClubChampionshipStart?.nextStart?.time?.minutes ?? ''
        const time = hours + ':' + minutes

        return this.$replacePlaceholder(
          this.$replacePlaceholder(
            this.$replaceHtmlPlaceholders(
              this.$replacePlaceholder(this.$t('club.recalculationText'), '%s', '{b} %s {/b}'),
              'b',
              'text-texts-standard-important',
            ),
            '%s',
            time,
          ),
          '{date}',
          date,
        )
      } else return ''
    },
    getArrowPosition(): string {
      return this.ARROW_POSITIONS[this.getProgress?.type ?? STAYS]
    },
    benefitClubChampionship(): UserBenefitsSlotsApiResponse[] {
      return (
        this.benefitsSlots?.filter(
          (benefit: UserBenefitsSlotsApiResponse): boolean =>
            benefit.benefit_building === BENEFITS_CLUB_CHAMPIONSHIP,
        ) ?? []
      )
    },
    benefitClubChampionshipSlots(): BenefitSlot {
      const benefitClubChampionshipDataSlots: BenefitSlot['slots'] = {}
      const benefitClubChampionshipDataBenefitTypes: string[] = []

      this.benefitClubChampionship.forEach((element: UserBenefitsSlotsApiResponse) => {
        benefitClubChampionshipDataSlots[element?.slot_id] = element.benefit
        benefitClubChampionshipDataBenefitTypes.push(element.benefit_slot_type)
      })

      return {
        benefit_type: benefitClubChampionshipDataBenefitTypes,
        slots: benefitClubChampionshipDataSlots,
      }
    },
  },
  watch: {
    $route(route: RouteLocationNormalized): void {
      if (!route.query.reloadData) return
      this.loadClubInfo()
      this.initMethods()
      this.loadBenefitsSlots()
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {
          reloadData: undefined,
          redirectFrom: route.query.redirectFrom ?? undefined,
        },
      })
    },
  },
  async created(): Promise<void> {
    if (this.hasClub) {
      try {
        this.$axios.put<{}, true>(newStatusChampionshipBuilding)
      } catch (error: unknown) {
        console.error(error)
      }
      await this.loadClubInfo()

      if (this.isClubInChampionship && this.isEnoughClubPlayers) {
        this.loadStartsConfig()
        await this.loadDetail()

        if (this.isChampionshipRunning) {
          this.isLocked = false
          this.loadOpponentsWithDisciplineInfo()
          this.loadOpponentsRefreshPrice()
          this.loadBenefitsSlots()
        }
      }
    }
  },
  beforeUnmount(): void {
    this.setSimulation(false)
  },
  methods: {
    openMiniGame,
    toKebabCase,
    ...mapActions(useOponentsStore, ['loadOpponentsRefreshPrice']),
    ...mapActions(useDuelStore, {
      loadStartsConfig: 'setStartsCosts',
    }),
    ...mapActions(useUserStore, {
      setUserExperience: 'setUserDataExperience',
      setUserDataTargetExperience: 'setUserDataTargetExperience',
      setUserDataLevel: 'setUserDataLevel',
      loadUserData: 'loadUserData',
    }),
    ...mapActions(useOfferStore, ['setRefreshingBundle']),
    ...mapActions(useCoreStore, ['setSimulation']),
    ...mapActions(useClubChampionshipStore, ['loadDetail']),
    ...mapActions(useClubStore, {
      loadClubInfo: 'loadClubInfo',
    }),
    ...mapActions(useResponseTaskStore, {
      loadBenefitsSlots: 'loadBenefitsSlots',
    }),
    ...mapActions(useDisciplineStore, {
      loadSpecificDiscipline: 'loadSpecificDiscipline',
    }),
    ...mapActions(usePhaserGameIntegrationStore, ['setClubChampionshipLabelInfo']),
    initMethods(): void {
      this.loadDetail()
      this.loadOpponentsWithDisciplineInfo()
      this.loadOpponentsRefreshPrice()
      this.loadBenefitsSlots()
      this.loadStartsConfig()
    },
    goToClubs(): void {
      this.$router.push({
        name: this.$getWebView('ClubsAvailable'),
        query: { homepage: 'true' },
      })
    },
    goToAvailablePlayers(): void {
      const redirectFrom =
        this.$route.query.redirectFrom === 'LayoutView' || this.$route.query.homepage === 'true'
          ? 'LayoutView'
          : 'ClubsMap'

      this.$router.push({
        name: this.$getWebView('ClubsAvailablePlayers'),
        query: { redirectFrom },
      })
    },
    async loadOpponents(): Promise<void> {
      const supermatchParam = `?${MULTIPLIER}=${
        this.activeMultiplicator(Multiplier.ClubChampionship).name
      }`

      const response = await this.$axios.get<{}, ArenaOpponentsResponse>(
        `${arenaOpponentsEndpoint}clubs${supermatchParam}`,
      )

      this.duelOpponents = response.opponents
      this.expiration = response.opponents_expiration
    },
    loadOpponentsWithDisciplineInfo(): void {
      this.loadOpponents().then(() => {
        this.loadSpecificDiscipline({ isClubChampionship: true })
      })
    },
    async switchMatchType(): Promise<void> {
      this.loadOpponentsWithDisciplineInfo()
    },

    async refreshOpponents(): Promise<void> {
      this.expiration = null
      const supermatchParam = `?${MULTIPLIER}=${
        this.activeMultiplicator(Multiplier.ClubChampionship).name
      }`
      const response = await this.$axios.post<{}, ArenaOpponentsResponse>(
        `${arenaNewOpponentsEndpoint}${supermatchParam}`,
        {
          mode: CLUB_DUEL,
        },
      )
      this.loadSpecificDiscipline({ isClubChampionship: true })

      this.duelOpponents = response.opponents
      this.expiration = response.opponents_expiration
    },
    async playDuel(isMinigame: boolean): Promise<void> {
      playSound('click-button-duel-play')
      this.isLiveMatch = isMinigame
      const startsCount = isMinigame ? this.startsCostConfig.live : this.startsCostConfig.base

      const startsMultiply =
        startsCount * this.activeMultiplicator(Multiplier.ClubChampionship).value
      if (this.userStarts.value < startsMultiply) {
        this.setRefreshingBundle({
          type: STARTS,
          value: startsMultiply,
        })
        this.isParameterPopupOpen = true
        return
      }

      if (isMinigame) {
        if (this.discipline.isTutorialPassed || this.isTutorial) {
          this.openMiniGame(
            minigameUrl,
            {
              params: {
                mode: this.modes.club_duel,
                discipline_id: this.discipline.id,
                multiplier: this.activeMultiplicator(Multiplier.ClubChampionship).name,
              },
            },
            this.$router,
          )

          return
        } else {
          this.showSkipTutorialPopup = true
        }
        return
      }
      this.setSimulation(true)
      this.expiration = null

      const response = await this.$axios.post<{}, ArenaMatchResponse>(arenaMatchEndpoint, {
        type: 2,
        discipline_id: this.discipline.id,
        multiplier: this.activeMultiplicator(Multiplier.ClubChampionship).name,
        simulation: true,
      })

      if (response?.EXPERIENCE) this.setUserExperience(response.EXPERIENCE)
      if (response?.TARGET_EXPERIENCE) this.setUserDataTargetExperience(response.TARGET_EXPERIENCE)
      if (response?.LEVEL) this.setUserDataLevel(response.LEVEL)
      this.expiration = response.opponents_expiration
      this.duelOpponents = response.newOpponents
      this.loadDetail()
      this.setClubChampionshipLabelInfo()

      if (this.getShowResultScreen) {
        this.loadReport(response.match_id, this.discipline.id)
        this.setSimulation(false)
        return
      }
      this.loadSpecificDiscipline({ isClubChampionship: true })
      this.loadUserData()
      this.setSimulation(false)
    },
    loadReport(matchId: string, disciplineId: number): void {
      this.$router.push({
        name: this.$getWebView('ClubsChampionshipReport'),
        query: {
          match_id: matchId,
          discipline_id: disciplineId,
          match_time: this.isLiveMatch ? LIVE : ARENA,
          redirectFrom: this.$route.query.redirectFrom ?? undefined,
        },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/clubs-championship-leagues.scss';

.club-championship-competitions {
  &-wrapper {
    display: flex;
    min-height: 0;
  }

  &-lock {
    display: flex;
    width: 100%;
    height: 100%;

    &-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 98%;
      height: 100%;
      margin: 0 auto;
      padding: 2rem 0;

      section {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;

        .lock-content-message {
          width: 100%;
          height: 10rem;

          margin: 2.438rem 0 3.125rem;
          @if $isWsm {
            background: linear-gradient(
              to left,
              transparent 0%,
              rgba(20, 74, 115, 0.6) 10%,
              rgba(20, 74, 115, 0.6) 90%,
              transparent 100%
            );
          }
          @if $isSsm {
            background: linear-gradient(
              to left,
              transparent 0%,
              rgba(#56627f, 0.6) 10%,
              rgba(86, 98, 127, 0.6) 90%,
              transparent 100%
            );
          }
        }
      }
    }
  }

  &-unlock {
    display: flex;
    width: 100%;
    height: 100%;

    .competitions-unlock-wrapper {
      width: 98%;
      margin: 0 auto;
      padding: 1rem 0 2rem;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;

      .league-panel {
        flex: 1 1 auto;
      }

      .club-position-panel {
        width: 40.75rem;
        height: 4.875rem;
        transform: $skew-value;
        @if $isWsm {
          box-shadow: inset 0 0 27px 0 rgba(0, 0, 0, 0.35);
          background-color: #0c253b;
          border: 0.125rem solid rgb(91, 163, 220, 0.75);
        }
        @if $isSsm {
          background-color: #363e5d;
          border: 0.125rem solid #f6c717;
        }
        &-left {
          width: 22.938rem;
          display: flex;
          align-items: center;
          padding: 0 1rem;
          position: relative;

          &-count {
            width: 7.25rem;
            height: 2.938rem;
            background: linear-gradient(
              to left,
              transparent 0%,
              rgba(22, 64, 103, 0.6) 40%,
              rgba(22, 64, 103, 0.6) 60%,
              transparent 100%
            );
          }
        }

        &-right {
          width: 17.813rem;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          @if $isWsm {
            background-image: linear-gradient(15deg, #ffef84 30%, #fadb4e 50%, #f6c717 100%);
          }
          @if $isSsm {
            background-image: linear-gradient(to right, #daab36, #ffdf91);
          }
        }
      }

      .club-wrapper {
        width: 100%;
        padding: 0 3.125rem;
      }

      .championship-footer {
        width: 100%;
        margin: 2.375rem auto 0;

        .main-duel-footer {
          justify-content: space-between;
        }
      }
    }
  }
}

.club-postion-panel-tooltip {
  &-title {
    width: 27.813rem;
    height: 3.125rem;
    background: linear-gradient(
      to left,
      transparent 0%,
      rgba(117, 182, 231, 0.7) 40%,
      rgba(117, 182, 231, 0.7) 60%,
      transparent 100%
    );
    margin: 0 auto;

    &.next {
      margin-top: 5rem;
    }
  }

  &-badge {
    width: 6.25rem;
    height: 6.25rem;
    flex: 0 0 6.25rem;
  }

  &-text {
    width: 20rem;
    text-align: left;
  }

  &-arrow {
    left: 50%;
    position: absolute;
    bottom: 63%;
  }
}

.triangle-border {
  left: -1.8rem;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  @if $isWsm {
    border: 0.25rem solid #f6c717;
    background: linear-gradient(to top, #31628d, #0f3251);
  }
  @if $isSsm {
    border: 0.25rem solid #f6c717;
    background: linear-gradient(to top, #586b9d, #24344f);
  }

  &.triangle-skew {
    transform: $skew-revert-value;
  }
}

.triangle {
  border-top-width: 1rem;
  border-bottom-width: 1rem;
  border-left-width: 1.6rem;
  right: 0.85rem;
  top: 0.6rem;
}

.position {
  top: 0.9rem !important;
}

.same-league-icon {
  right: 1rem;
  top: 1.5rem;
  width: 1.5rem;
  height: 0.3rem;
  background: #ffffff;
}
</style>
