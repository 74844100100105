import { TASK_TRACKER_LOCAL_STORAGE } from '@/globalVariables'

interface TasksTracker {
  state: boolean
  index?: number
  setAllTasks?: boolean
}

export const setTasksActiveStates = ({ state, index, setAllTasks = false }: TasksTracker): void => {
  const storage: Array<TasksTracker['state']> | null = JSON.parse(
    localStorage.getItem(TASK_TRACKER_LOCAL_STORAGE) || '[]',
  )
  if (!storage) return

  if (setAllTasks) {
    storage.forEach((_: TasksTracker['state'], index: number): void => {
      storage[index] = state
    })
  } else {
    storage[index] = state
  }

  localStorage.setItem(TASK_TRACKER_LOCAL_STORAGE, JSON.stringify(storage))
}
