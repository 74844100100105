<template>
  <main class="chains-main flex flex-col justify-between w-full h-full safe-area">
    <chains-header />
    <chains-sub-header />
    <chains-stages />
    <chains-message
      v-if="isChainsCompleted"
      :title="$te('tasksDoneTitle')"
      :message="$te('tasksDoneText')"
    />
  </main>
</template>

<script lang="ts">
import { useTaskChainStore } from '@/store/pinia/events/taskChainStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import ChainsHeader from './ChainsHeader.vue'
import ChainsMessage from './ChainsMessage.vue'
import ChainsStages from './ChainsStages.vue'
import ChainsSubHeader from './ChainsSubHeader.vue'

export default defineComponent({
  name: 'ChainsMain',
  components: {
    ChainsHeader,
    ChainsSubHeader,
    ChainsStages,
    ChainsMessage,
  },
  computed: {
    ...mapState(useTaskChainStore, {
      isChainsCompleted: 'getIsChainsCompleted',
    }),
  },
})
</script>
