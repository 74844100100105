<template>
  <info-message :info-message-data="popupObject">
    <div class="flexing m-4">
      <reward-box
        v-for="(reward, index) in rewards"
        :key="index"
        :reward-icon="72"
        :reward-icon-name="reward.type"
        :reward-number="reward.amount"
        :reward-icon-rarity="''"
      />
    </div>
  </info-message>
</template>

<script lang="ts">
import InfoMessage, { type InfoMessageData } from '@/components/InfoMessage.vue'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface RewardInterface {
  type: string
  amount: number
}

export default defineComponent({
  name: 'ClubsRewardsPopup',
  components: {
    InfoMessage,
  },
  props: {
    rewards: {
      type: Array as PropType<RewardInterface[]>,
      default: () => [
        { type: 'gems', amount: 10 },
        { type: 'energy', amount: 20 },
        { type: 'money', amount: 1000 },
      ],
    },
  },
  computed: {
    popupObject(): InfoMessageData {
      return {
        headerText: 'Odmeny prevzate',
        headStyle: 'important',
        infoText: 'Odmeny boli uspesne prevzate',
        hasDecoration: true,
        buttonText: 'Pokracovat',
      }
    },
  },
})
</script>
