<template>
  <section class="app-page-wrapper premium-chain flex flex-col absolute" :class="chainType">
    <header class="flexing">
      <div class="absolute chain-timer">
        <app-timer
          v-if="remainingTime"
          :time="remainingTime"
          :italic="false"
          @countdown-action="goToPremium()"
        />
      </div>
      <div class="chain-title flexing">
        <div class="chain-logo"></div>
      </div>
      <tippy theme="WSM" placement="left" max-width="55rem" class="chain-info absolute">
        <app-icon icon-name="info-70" />
        <template #content>
          <div class="tooltip-content text-texts-standard-default text-30">
            <p class="tooltip-content-bottom w-full">
              {{ $t(getChainData.texts.info1) }}
            </p>
            <div class="white-strip my-4" />
            <p class="tooltip-content-bottom w-full">
              {{ $t(getChainData.texts.info2) }}
            </p>
          </div>
        </template>
      </tippy>
      <p class="chain-about text-32 text-texts-standard-dark font-bold absolute">
        {{ $t(getChainData.texts.about) }}
      </p>
    </header>
    <main class="w-full h-full safe-area">
      <div class="premium-chain-content">
        <app-scrollbar
          v-if="offerData"
          height="100%"
          width="100%"
          scroll="x"
          slide="x"
          class="flex items-center"
          :arrows="true"
          scroll-to-element=".chain-pack-unlocked"
          scroll-to-element-align="start"
        >
          <section class="flex items-center mb-8">
            <premium-chain-pack
              v-for="(data, index) in offerData"
              :key="`${data.type}-${index}`"
              :price="data.price"
              :pack-status="data.status"
              :pack-type="data.type"
              :reward="data.reward"
              :store-id="data.storeId"
              :bonus="data.bonus"
              :chain-type="chainType"
              @reload-content="loadData()"
            />
          </section>
        </app-scrollbar>
        <component-loading :is-loading="!offerData" />
      </div>
    </main>
  </section>
</template>

<script lang="ts">
import PremiumChainPack from '@/components/Premium/Chain/PremiumChainPack.vue'
import { metaPremiumPacksEndpointType } from '@/globalVariables'
import type {
  ChainOffer,
  Offer,
  OfferParameter,
  PremiumPacksInterface,
} from '@/interfaces/premium/Offer'
import { defineComponent } from 'vue'
import type { RouteLocationNormalized } from 'vue-router'

export enum ChainType {
  Legends = 'legends',
  Halloween = 'halloween',
}

interface ChainData {
  type: ChainType
  texts: {
    about: string
    info1: string
    info2: string
  }
}

interface ComponentData {
  remainingTime: number
  chainType: string
  chainData: ChainData[]
  offerData: ChainOffer[]
}

export default defineComponent({
  name: 'PremiumChain',
  components: { PremiumChainPack },
  data(): ComponentData {
    return {
      chainType: ChainType.Halloween,
      remainingTime: 0,
      chainData: [
        {
          type: ChainType.Legends,
          texts: {
            about: 'premiumGroup.wsm_chain_of_legends_about',
            info1: 'premiumGroup.wsm_chain_of_legends_info1',
            info2: 'premiumGroup.wsm_chain_of_legends_info2',
          },
        },
        {
          type: ChainType.Halloween,
          texts: {
            about: 'premiumGroup.wsm_chain_of_legends_about',
            info1: 'premiumGroup.wsm_chain_of_legends_info1',
            info2: 'premiumGroup.wsm_chain_of_legends_info2',
          },
        },
      ],
      offerData: null,
    }
  },
  computed: {
    getChainData(): ChainData {
      return this.chainData.find((chain: ChainData): boolean => chain.type === this.chainType)
    },
  },
  watch: {
    $route(route: RouteLocationNormalized): void {
      if (route.query.reloadData) {
        this.loadData()
        if (route.query.reloadData) {
          this.$router.replace({
            ...this.$router.currentRoute,
            query: {
              reloadData: undefined,
            },
          })
        }
      }
    },
  },
  created(): void {
    this.loadData()
  },
  methods: {
    async loadData(): Promise<void> {
      const response = await this.$axios.get<{}, PremiumPacksInterface>(
        metaPremiumPacksEndpointType + 'legendary_chain_offer',
      )
      this.remainingTime = response.packs[0]?.remaining_time
      this.offerData = response.packs.map((pack: Offer): ChainOffer => {
        const isFree = pack.parameters.find(
          (param: OfferParameter): boolean => param.name === 'is_free',
        )
        const isVideo = pack.parameters.find(
          (param: OfferParameter): boolean => param.name === 'is_video_ad',
        )
        let packType = ''
        if (
          response.packs?.find(
            (otherPack: Offer): boolean => otherPack.store_id === pack.prerequisite,
          )?.locked &&
          (isFree || isVideo)
        ) {
          packType = 'unknown'
        } else if (pack.price?.price || (!isFree && !isVideo)) {
          packType = 'premium'
        } else if (isFree) {
          packType = 'free'
        } else if (isVideo) {
          packType = 'video'
        }

        let packStatus: string
        if (pack.remaining_buys === 0) {
          packStatus = 'claimed'
        } else if (pack.locked) {
          packStatus = 'locked'
        } else {
          packStatus = 'unlocked'
        }

        return {
          storeId: pack.store_id,
          type: packType,
          status: packStatus,
          price: {
            value: pack.price?.price ?? 0,
            currency: pack.price?.currency ?? '',
          },
          reward: {
            name: pack.offer_content[0].name,
            value: pack.offer_content[0].value,
            rarity: pack.offer_content[0].rarity ?? null,
            gdd_id: pack.offer_content[0].gdd_id ?? null,
            oldValue:
              parseInt(pack.parameters.find((param) => param.name === 'old_value')?.value) ?? 0,
          },
          bonus: {
            value: parseInt(pack.parameters.find((param) => param.name === 'value')?.value) ?? 0,
            sign:
              pack.parameters.find((param) => param.name === 'non_payers_only')?.value === '1'
                ? 'x value'
                : '% bonus',
          },
        }
      })
    },
    goToPremium(): void {
      this.$router.push({ name: this.$getWebView('PremiumOffers') })
    },
  },
})
</script>

<style lang="scss" scoped>
.premium-chain {
  & > header {
    position: relative;
    top: 8rem;

    .chain-timer {
      left: 2.5rem;
    }

    .chain-info {
      right: 3.625rem;
    }

    .chain-title {
      width: 85%;
      height: 6rem;
      border-style: solid;
      border-width: 0.25rem;
      border-image-slice: 1;
    }

    .chain-about {
      width: 75rem;
      height: 2.875rem;
      border-style: solid;
      border-width: 0.125rem;
      border-image-slice: 1;
      bottom: -5rem;
    }
  }

  &.legends,
  &.halloween {
    background: url($path-premium + 'chain/chain-of-legends-bg.avif') center bottom no-repeat;
    background-size: cover;

    .chain-title {
      background: linear-gradient(
        90deg,
        rgba(67, 33, 92, 0) 0%,
        rgba(52, 25, 120, 0.9) 40%,
        rgba(52, 25, 120, 0.9) 60%,
        rgba(67, 33, 92, 0) 100%
      );

      border-image-source: linear-gradient(
        90deg,
        rgba(246, 237, 176, 0) 0%,
        rgba(246, 237, 176, 1) 25%,
        rgba(246, 237, 176, 1) 75%,
        rgba(246, 237, 176, 0) 100%
      );
    }

    .chain-about {
      background: linear-gradient(
        90deg,
        rgba(222, 194, 108, 0) 0%,
        rgba(237, 191, 71, 0.8) 25%,
        rgba(247, 212, 101, 0.8) 50%,
        rgba(237, 191, 71, 0.8) 75%,
        rgba(222, 194, 108, 0) 100%
      );

      border-image-source: linear-gradient(
        90deg,
        rgba(246, 237, 176, 0) 0%,
        rgba(249, 229, 133, 1) 25%,
        rgba(249, 229, 133, 1) 75%,
        rgba(246, 237, 176, 0) 100%
      );
    }

    .chain-logo {
      width: 57.875rem;
      height: 4.875rem;
      background: url($path-premium + 'chain/chain-of-legends-logo.webp') center bottom no-repeat;
      background-size: contain;
    }
  }
  &.legends {
    background: url($path-premium + 'chain/chain-of-legends-bg.avif') center bottom no-repeat;
  }
  &.halloween {
    background: url($path-premium + 'chain/halloween/chain-of-legends-bg.avif') center bottom
      no-repeat;
  }

  &-content {
    width: 98%;
    margin: 0 auto;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    position: relative;
    top: 13rem;
  }
}
</style>
