import { io, Socket } from 'socket.io-client'
import {
  MECHANIC_TOURNAMENTS,
  TOURNAMENT_SIGNAL_EVENT,
  TOURNAMENT_END,
  TOURNAMENT_START,
  GameId,
} from '@/globalVariables'
import { getValidationToken } from '@/helpers'
import { getRouteWebName, isSsm, isWsm } from '@/plugins'
import { useUserStore } from '@/store/pinia/userStore'
import { useResponseTaskStore, type SessionData } from '@/store/pinia/responseTaskStore'
import { useTournamentsCalendarStore } from '@/store/pinia/tournaments/useTournamentsCalendarStore'
import { useTournamentsDetailStore } from '@/store/pinia/tournaments/useTournamentsDetailStore'
import { useTournamentsActiveStore } from '@/store/pinia/tournaments/useTournamentsActiveStore'
import router from '@/router'
import { TournamentState, type TournamentSignalResponse } from '@/interfaces/Tournaments'
import type { Nullable } from '@/interfaces/utils'

enum SocketIoDisconnectReason {
  IO_SERVER_DISCONNECT = 'io server disconnect',
  IO_CLIENT_DISCONNECT = 'io client disconnect',
  PING_TIMEOUT = 'ping timeout',
  TRANSPORT_CLOSE = 'transport close',
  TRANSPORT_ERROR = 'transport error',
}

class SocketioService {
  public socket: Socket = null

  protected isReconnecting: boolean = false

  protected debugOverlayElement: Nullable<HTMLElement> = null

  private activeSocket: boolean = false

  get getActiveSocket(): boolean {
    return this.activeSocket
  }

  public setupSocketConnection(): void {
    if (this.activeSocket) return
    this.activeSocket = true
    this.socket = io(import.meta.env.VITE_APP_SOCKET_PORT, {
      auth: {
        token: getValidationToken('token'),
      },
      transports: ['websocket'],
    })

    this.socket.on('connect', (): void => {
      this.debug(`socket ${this.isReconnecting ? 'reconnected' : 'connected'}`)

      const userStore = useUserStore()
      if (!userStore.getUserId) {
        this.isReconnecting = false
        return
      }

      if (!this.isReconnecting) {
        this.handleTournamentsSignals()
      }

      const isClubChatRoute = router.currentRoute.value.name === getRouteWebName('ClubsChat')

      this.debug(`user id: ${userStore.getUserId}`)
      this.debug(`has club: ${userStore.getHasClub}`)
      this.debug(`club id: ${userStore.getClub?.id}`)
      this.debug(`is club chat route: ${isClubChatRoute}`)

      if (this.isReconnecting) {
        this.socket.emit('join', { userGameId: userStore.getUserId })
        this.socket.emit('joinNotifications', { userGameId: userStore.getUserId })
      }

      if (userStore.getHasClub && isClubChatRoute) {
        this.socket.emit('getAllMessages', { userGameId: userStore.getUserId })
        this.socket.emit('getOnlineMembers', { userGameId: userStore.getUserId })
      }

      this.isReconnecting = false
    })

    this.socket.on('disconnect', (reason: SocketIoDisconnectReason): void => {
      this.debug(`socket disconnected: ${reason}`)

      if (this.socket && reason === SocketIoDisconnectReason.TRANSPORT_ERROR) {
        this.debug(`reconnecting socket`)

        this.isReconnecting = true
        this.socket.connect()
      }
    })
  }

  public disconnect(): void {
    if (this.socket) {
      this.activeSocket = false
      this.socket.disconnect()
      this.socket = null
    }
  }

  public leaveClub(userGameId: string): void {
    this.socket.emit('leave', { userGameId })
  }

  public handleTournamentsSignals(): void {
    this.socket.on(
      TOURNAMENT_SIGNAL_EVENT,
      async ({ game_id: gameId, tournament }: TournamentSignalResponse): Promise<void> => {
        if (gameId === GameId.WinterSports && !isWsm) return
        if (gameId === GameId.SummerSports && !isSsm) return

        const responseTaskStore = useResponseTaskStore()
        if (!responseTaskStore.hasMechanic(MECHANIC_TOURNAMENTS)) return

        if (
          [getRouteWebName('TournamentsCalendar'), getRouteWebName('TournamentsHistory')].includes(
            router.currentRoute.value.name as string,
          )
        ) {
          useTournamentsCalendarStore().loadState(true)
        }

        if (router.currentRoute.value.name === getRouteWebName('TournamentsDetail')) {
          if (tournament.tournament_id !== +router.currentRoute.value.params.id) return
          useTournamentsDetailStore().loadState(tournament.tournament_id, true)
        }

        const activeTournamentsStore = useTournamentsActiveStore()
        await activeTournamentsStore.loadState()
        if (!activeTournamentsStore.activeTournamentIds.includes(tournament.tournament_id)) return

        if ([TournamentState.Running, TournamentState.Finished].includes(tournament.status)) {
          responseTaskStore.addSessionMessage({
            type:
              tournament.status === TournamentState.Running
                ? TOURNAMENT_START
                : tournament.status === TournamentState.Finished
                  ? TOURNAMENT_END
                  : null,
            tournamentName: tournament.city,
            disciplineId: tournament.game_discipline_id.toString(),
          } as SessionData)
        }
      },
    )
  }

  public enableDebug() {
    const div = document.createElement('div')
    div.style.position = 'fixed'
    div.style.top = '6.5rem'
    div.style.right = '0.675rem'
    div.style.zIndex = '1000'
    div.style.backgroundColor = 'white'
    div.style.color = 'black'
    div.style.padding = '1rem'
    div.style.fontSize = '1rem'
    div.style.fontFamily = 'monospace'
    div.style.opacity = '0.875'
    div.style.pointerEvents = 'none'
    document.body.appendChild(div)

    const h1 = document.createElement('h1')
    h1.textContent = 'socket.io debug overlay'
    div.appendChild(h1)

    const hr = document.createElement('hr')
    hr.style.borderColor = 'black'
    hr.style.margin = '0.5rem 0'
    div.appendChild(hr)

    const ul = document.createElement('ul')
    ul.style.listStyleType = 'none'
    div.appendChild(ul)

    this.debugOverlayElement = ul
  }

  public debug(message: string): void {
    if (!this.debugOverlayElement) return

    const li = document.createElement('li')
    li.textContent = `[${new Date().toLocaleTimeString()}] ${message}`
    this.debugOverlayElement.appendChild(li)
  }
}

const socketService = new SocketioService()
// socketService.enableDebug()

export default socketService
