<template>
  <div class="gems-pack-delivary absolute flexing flex-col">
    <p class="text-32 text-texts-standard-default mb-2">{{ $t('premiumGroup.dailyDelivery') }}</p>
    <p class="text-28 text-texts-standard-additional">{{ $t('equipmentShop.total') }}</p>
    <section
      class="pack-price w-full text-60 font-bold flexing text-texts-standard-important mt-2 mb-6"
      :class="{ 'popular-shadow': packageData.popular }"
    >
      <p>{{ $filters.$formatNumber(packageData.rewardTotal) }}</p>
      <app-main-icon class="icon-margin" :icon-name="'gems'" :icon-size="56" />
    </section>
    <p class="text-32 text-texts-standard-additional flexing" :class="{ 'font-bold': $isSsm }">
      <span>{{
        `${
          packageData.remainingRepeatCount > 0
            ? packageData.remainingRepeatCount
            : packageData.maxRepeatCount
        }x ( ${packageData.dailyReward}`
      }}</span>
      <app-main-icon class="mx-4" :icon-name="'gems'" :icon-size="32" />
      <span class="mr-4">{{ '/' }}</span>
      <span class="time-icon"> <app-icon icon-name="time" /></span>
      <span class="ml-2">{{ ' 24h )' }}</span>
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { GEMS } from '@/globalVariables'

interface ComponentData {
  GEMS: typeof GEMS
}

export default defineComponent({
  name: 'GemsPackBack',

  props: {
    packageData: {
      type: Object,
      default: () => {},
    },
  },
  data(): ComponentData {
    return {
      GEMS,
    }
  },
})
</script>

<style lang="scss" scoped>
.gems-pack-delivary {
  background: url($path-premium + 'gemsPackage/BG_OFFER_BOX_SMALL_2_BACK.webp') center no-repeat;
  background-size: contain;
  width: 35.625rem;
  height: 19.188rem;
  z-index: 3;
  left: 0;
  top: 0;

  .pack-price {
    position: relative;
    background: linear-gradient(to left, transparent, rgba(144, 91, 221, 0.99), transparent);
    height: 4.375rem;
    width: 20rem;
    line-height: 4.375rem;

    &:before {
      content: '';
      position: absolute;
      height: 0.125rem;
      width: 80%;
      background-image: linear-gradient(
        to left,
        rgba(125, 156, 205, 0),
        rgba(255, 255, 255, 0.7),
        rgba(125, 156, 205, 0)
      );
      top: 0;
    }
  }

  .time-icon {
    transform: scale(0.85);
  }
}
</style>
