import type { App, Plugin } from 'vue'

const APPLE_DEVICE_REGEXP = new RegExp('iPad|iPhone|iPod|Mac', 'i')

let cache: boolean

/**
 * Zistime ci je apple
 * @returns Info ci mame apple
 */
export const isApple = (): boolean => {
  // Musi byt undef
  if (cache !== undefined) return cache
  return (cache = APPLE_DEVICE_REGEXP.test(navigator.userAgent))
}

export const isApplePlugin: Plugin = {
  install: (app: App): void => {
    app.config.globalProperties.$isApple = isApple
  },
}
