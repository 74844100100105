import { CURRENT_SEASON, PREVIOUS_SEASON } from '@/globalVariables'
import type { Nullable } from '@/interfaces/utils'

export interface Season {
  seasonYear: string
  seasonMonth: string
  remainingTime: Nullable<number>
  seasonalRp: number
  rewards: SeasonRewardType[]
}

export interface SeasonEnd {
  season: string
  seasonYear: string
  seasonMonth: string
  previousSeasonYear: string
  previousSeasonMonth: string
  remainingTime: Nullable<number>
  seasonTexts: string[]
  seasonalRp: number
  rewardsSources: SeasonEndRewardSource[]
  rewards: SeasonReward[]
  currentArenaLevel: number
  previousArenaLevel: number
}

export interface ArenaStats {
  arenaLevel: number
  rankingPoints: number
}

export enum SeasonType {
  Current = CURRENT_SEASON,
  Previous = PREVIOUS_SEASON,
}

export interface SessionalAttribute {
  disciplineId: number
  baseAttribute: number
  seasonalAttribute: number
}

export interface WorldRanking {
  disciplineId: number
  position: number
  rewards: SeasonReward[]
}

export interface SeasonReward {
  amount: number
  name: string
  type: string
  rarity: string
}

export interface SeasonRewardType {
  type: string
  topValue: number
  rewards: SeasonReward[]
}

export interface SeasonEndRewardSource {
  type: string
  topValue: number
}

export interface SeasonAttributesRow {
  disciplineId: number
  value1: number
  value2?: number
  rewards?: SeasonReward[]
}
