<template>
  <div id="profile-avatars" class="app-page-wrapper absolute profile-avatars">
    <section class="w-full h-full safe-area">
      <profile-choose :avatars="avatars" choose-type="avatar" />
    </section>
  </div>
</template>

<script lang="ts">
import ProfileChoose from '@/components/Profile/ProfileChoose.vue'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProfileAvatars',
  components: {
    ProfileChoose,
  },
  computed: {
    ...mapState(useUserStore, {
      avatars: 'getUserAvatars',
    }),
  },
  created(): void {
    this.loadAvatars()
  },
  methods: {
    ...mapActions(useUserStore, ['loadAvatars']),
  },
})
</script>
