<template>
  <div class="benefit-part">
    <h2 class="text-texts-standard-default text-36 font-bold uppercase">
      {{ categoryTitle }}
      <span v-if="!allowUpgrade" class="benefit-part-selected-count">
        {{ $t('benefits.selected') }}
        <span
          :class="selectedBenefit ? 'text-texts-standard-upgrade' : 'text-texts-standard-default'"
        >
          {{ selectedBenefit ? 1 : 0 }}
        </span>
        / 1
      </span>
    </h2>
    <div class="benefit-part-boxes">
      <benefit-box
        v-for="(benefit, index) in benefits"
        :key="index"
        :benefit-data="benefit"
        :is-selected="benefit.benefit_gdd_id === selectedBenefit"
        :inactive="benefit.amount < requiredBenefits"
        :possible-select="allowUpgrade"
        @click="
          allowUpgrade ? showDetailPopup(benefit) : selectBenefit(Number(benefit.benefit_gdd_id))
        "
      />
      <section v-if="allowUpgrade" class="benefit-type-arrow-section">
        <div
          v-for="(benefit, index) in benefits"
          :key="index"
          class="benefit-type-arrow"
          :class="'benefit-type-arrow-' + index"
          @click="showUpgradePopup(index - 1)"
        />
      </section>
    </div>
    <info-popup
      v-if="showPopup || showUpgrade"
      :popup-title="showPopup ? $t('common.benefits') : $t('benefits.upgradeBenefits')"
      :class-list="['upgrade-benefit-popup', 'overlay-bg-blur', 'animated-overlay']"
      @close="closeInfoPopUp"
    >
      <benefit-detail
        v-if="showPopup"
        :detail-data="dataForDetailPopup"
        @show-upgrade="showUpgradePopup(getBenefitIndex(dataForDetailPopup))"
      />
      <upgrade-benefit-popup
        v-else-if="showUpgrade"
        :upgrade-from-benefit="dataForUpgradePopup.upgradeFromBenefit"
        :upgrade-to-benefit="dataForUpgradePopup.upgradeToBenefit"
        @benefit-upgraded="processUpgrade"
      />
    </info-popup>
  </div>
</template>

<script lang="ts">
import BenefitBox from '@/components/Benefits/BenefitBox.vue'
import BenefitDetail from '@/components/Benefits/BenefitDetail.vue'
import UpgradeBenefitPopup from '@/components/Benefits/UpgradeBenefitPopup.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { BenefitSlotData } from '@/interfaces/Benefits'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  showPopup: boolean
  showUpgrade: boolean
  selectedBenefit: number
  selectedBenefits: BenefitSlotData[]
  dataForDetailPopup: Nullable<BenefitSlotData>
  dataForUpgradePopup: Record<string, BenefitSlotData>
}

export default defineComponent({
  name: 'BenefitCategory',
  components: {
    BenefitBox,
    InfoPopup,
    BenefitDetail,
    UpgradeBenefitPopup,
  },
  props: {
    categoryTitle: {
      type: String,
      default: '',
    },
    benefits: {
      type: Array as PropType<BenefitSlotData[]>,
      default: () => [],
    },
    allowUpgrade: {
      type: Boolean,
      default: false,
    },
    activeBenefit: {
      type: Number,
      default: 0,
    },
    requiredBenefits: {
      type: Number,
      default: 1,
    },
  },
  emits: ['selectBenefit', 'benefitUpgraded'],
  data(): ComponentData {
    return {
      showPopup: false,
      showUpgrade: false,
      selectedBenefit: 0,
      selectedBenefits: [],
      dataForDetailPopup: null,
      dataForUpgradePopup: {},
    }
  },
  created(): void {
    this.selectedBenefit = this.activeBenefit
  },
  methods: {
    ...mapActions(useResponseTaskStore, ['loadBenefitsSlots', 'loadBenefits']),
    showDetailPopup(benefit: BenefitSlotData): void {
      this.dataForDetailPopup = benefit
      this.showPopup = true
    },
    showUpgradePopup(index: number): void {
      this.dataForUpgradePopup = {
        upgradeFromBenefit: this.benefits[index],
        upgradeToBenefit: this.benefits[index + 1],
      }
      this.showUpgrade = true
      this.showPopup = false
    },
    getBenefitIndex(benefit: BenefitSlotData): number {
      return this.benefits.findIndex(
        (_benefit: BenefitSlotData): boolean => _benefit.benefit_gdd_id === benefit.benefit_gdd_id,
      )
    },
    selectBenefit(benefitId: number): void {
      this.selectedBenefit = this.selectedBenefit === benefitId ? 0 : benefitId
      this.$emit('selectBenefit', this.selectedBenefit)
    },
    async processUpgrade(): Promise<void> {
      this.showUpgrade = false
      await Promise.all([this.loadBenefits(), this.loadBenefitsSlots()])
      this.$emit('benefitUpgraded')
    },
    closeInfoPopUp(): void {
      this.showPopup = false
      this.showUpgrade = false
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/benefits/benefitCategory.scss';
</style>
