<template>
  <div ref="wrapperRef" class="relative flexing">
    <slot />
    <span
      class="w-full absolute top-1/2 left-1/2"
      :style="{
        backgroundColor: strikethroughLineColor,
        height: strikethroughLineHeight,
        transform: `translate(-50%, -50%) skewY(-${angle})`,
      }"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// Redukcia kvoli vyske fontu, resp. prazdnemu miestu nad/pod fontom.
const REDUCE_HEIGHT_COEFICIENT = 0.675

interface ComponentData {
  angle: string
}

const VALIDATOR_REGEXP = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i

export default defineComponent({
  name: 'AppStriketrough',
  props: {
    strikethroughLineHeight: {
      type: String,
      default: '0.25rem',
    },
    strikethroughLineColor: {
      type: String,
      default: '#b52e2d',
      validator: (value: string): boolean => VALIDATOR_REGEXP.test(value),
    },
  },
  data(): ComponentData {
    return {
      angle: '0deg',
    }
  },
  mounted(): void {
    this.setAngle()
  },
  methods: {
    setAngle(): void {
      if (!this.$refs.wrapperRef) return
      const { width, height } = this.$refs.wrapperRef.getBoundingClientRect()
      this.angle =
        (Math.atan((height * REDUCE_HEIGHT_COEFICIENT) / width) * (180 / Math.PI)).toFixed(2) +
        'deg'
    },
  },
})
</script>
