import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const clubHelicopterRoundAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'clubHelicopterRoundAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '03',
      delay: 0,
      duration: 600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 3,
        end: 2,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubHelicopterRoundAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '02',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 2,
        end: 4,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubHelicopterRoundAnimations3',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '04',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 4,
        end: 3,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubHelicopterRoundAnimations4',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '03',
      delay: 0,
      duration: 2000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 3,
        end: 8,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubHelicopterRoundAnimations5',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '08',
      delay: 0,
      duration: 2000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 8,
        end: 10,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubHelicopterRoundAnimations6',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '10',
      delay: 0,
      duration: 1400,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 10,
        end: 13,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubHelicopterRoundAnimations7',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '13',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 13,
        end: 16,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubHelicopterRoundAnimations8',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '16',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 16,
        end: 18,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubHelicopterRoundAnimations9',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '18',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 18,
        end: 19,
        zeroPad: 2,
      },
    },
  },
]
