import { defineStore } from 'pinia'

interface IframeStoreState {
  opened: boolean
  url: string
}

export const useIframeStore = defineStore('iframeStore', {
  state: (): IframeStoreState => ({
    opened: false,
    url: '',
  }),
  getters: {
    isIframeOpened(): boolean {
      return this.opened
    },
    getIframeUrl(): string {
      return this.url
    },
  },
  actions: {
    setIframe(isOpened: boolean): void {
      this.opened = isOpened
    },
    setIframeUrl(url: string): void {
      this.url = url
    },
  },
})
