<template>
  <aside class="lab-info flexing absolute" :class="treeType">
    <main class="lab-info-content">
      <div class="lab-info__header">
        <div v-if="treeType === 'economics'" class="icon-economy" />
        <app-discipline-icon v-else :discipline-id="disciplineId" />
        <h2 class="text-texts-standard-important text-36 uppercase font-bold">
          {{ treeType === 'economics' ? $t('research.economics') : disciplineName }}
        </h2>
        <span class="white-strip" />
      </div>
      <div class="lab-info-status">
        <div class="lab-info-status-box">
          <p class="text-texts-standard-default text-28">
            {{ $t('research.upgradeDone') }}
          </p>
        </div>
        <div class="lab-info-count">
          <span class="text-32 flexing">
            <p class="text-texts-standard-important font-bold">
              {{ researched[0] }}
            </p>
            <p class="text-texts-standard-default">{{ '/' + researched[1] }}</p>
          </span>
        </div>
      </div>
      <div class="lab-info-status">
        <div class="lab-info-status-box">
          <p class="text-texts-standard-default text-28">
            {{ $t('research.researchDone') }}
          </p>
        </div>
        <div class="lab-info-count">
          <span class="text-32 flexing">
            <p class="text-texts-standard-important font-bold">
              {{ finishedBranches[0] }}
            </p>
            <p class="text-texts-standard-default">
              {{ '/' + finishedBranches[1] }}
            </p>
          </span>
        </div>
      </div>
    </main>
  </aside>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { TreeType } from '@/interfaces/lab/Cell'

export default defineComponent({
  name: 'LabInfo',
  props: {
    researched: {
      type: Array as PropType<number[]>,
      required: true,
    },
    finishedBranches: {
      type: Array as PropType<number[]>,
      required: true,
    },
    discipline: {
      type: Number,
      default: 0,
    },
    treeType: {
      type: String as PropType<TreeType>,
      default: TreeType.Disciplines,
      validator(value: TreeType): boolean {
        return [TreeType.Camps, TreeType.Economics, TreeType.Disciplines].includes(value)
      },
    },
  },
  computed: {
    disciplineId(): number {
      return this.$route.params.id ? Number(this.$route.params.id) : this.discipline
    },
    disciplineName(): string {
      return this.$translateDiscipline(this.disciplineId ?? '')
    },
  },
})
</script>

<style lang="scss" scoped>
.lab-info {
  width: 22.5rem;
  height: 100%;

  @if $isSsm {
    background-image: linear-gradient(
      to bottom,
      rgba(35, 41, 62, 0) 0%,
      rgba(43, 49, 72, 1) 5%,
      rgba(94, 99, 140, 1) 50%,
      rgba(94, 99, 140, 1) 70%,
      rgba(35, 41, 62, 1) 90%,
      rgba(35, 41, 62, 0) 100%
    );
  }

  @if $isWsm {
    background-image: linear-gradient(
      to bottom,
      transparent,
      rgba(27, 47, 71, 1) 10%,
      rgba(40, 80, 118, 1) 55%,
      rgba(40, 80, 118, 1) 75%,
      rgba(17, 34, 55, 1) 90%,
      transparent
    );
  }

  &__header {
    width: 100%;
    height: 17.875rem;
    padding-top: 4.125rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url($path-lab + 'lab-research-box-bg.avif?v2') top center no-repeat;
    background-size: cover;

    .white-strip {
      background: linear-gradient(
        to right,
        transparent 10%,
        rgb(255, 255, 255, 0.5) 50%,
        transparent 90%
      );
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    h2 {
      display: flex;
      height: 4.5rem;
      line-height: normal;
      align-items: center;
      margin: 1.563rem 0 1.875rem;
      padding: 0 1.188rem;
    }

    .lab-info-status {
      margin-top: 2rem;
      width: 17.5rem;

      &-box {
        width: 100%;
        position: relative;

        @if $isSsm {
          background: linear-gradient(
            to left,
            transparent 10%,
            rgba(39, 50, 73, 0.85) 30%,
            rgba(39, 50, 73, 0.85) 70%,
            transparent 90%
          );
        }

        @if $isWsm {
          background: linear-gradient(
            to left,
            rgba(21, 78, 121, 0),
            rgba(21, 78, 121, 1),
            rgba(21, 78, 121, 0)
          );
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 0.125rem;
          left: 0;

          @if $isSsm {
            background: linear-gradient(
              90deg,
              rgba(88, 107, 157, 0) 5%,
              #586b9d,
              #586b9d,
              rgba(88, 107, 157, 0) 95%
            );
          }

          @if $isWsm {
            background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
          }
        }

        &::before {
          top: 0;
        }

        &::after {
          bottom: 0;
        }
      }
    }
  }

  .icon-economy {
    box-shadow: 0 0 1.125rem 0 rgb(0 0 0 / 50%);
    border: solid 0.125rem #3f6b93;
    background-color: linear-gradient(to top, #3f78a0, #254257);
  }
}
</style>
