<template>
  <app-scrollbar id="club-chat-scroll" width="100%" height="100%" slide="y" scroll="y">
    <chat-cell
      v-for="(msg, index) in messages"
      :key="index"
      :cell-content="msg.message"
      :who-writes="msg.sender"
      :sender-id="msg.userId"
      :sender-name="msg.senderName"
      :sender-country="msg.senderCountry"
      :sender-avatar="msg.senderAvatar"
      :sender-avatar-background="msg.senderAvatarBackground"
      :sex="msg.sex"
      :active="msg.active"
      :date="msg.date"
    />
  </app-scrollbar>
</template>
<script lang="ts">
import { sanitizeHtml } from '@/helpers'
import ChatCell from '@/components/Club/Chat/ChatCell.vue'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { ClubChatMessage } from '@/interfaces/clubs/Clubs'

// eslint-disable-next-line no-control-regex
const NON_ASCII_CHARACTERS_REGEX = /([^\x00-\x7F]+)/g

export default defineComponent({
  name: 'ChatHistory',
  components: {
    ChatCell,
  },
  props: {
    communication: {
      type: Array as PropType<ClubChatMessage[]>,
      required: true,
    },
  },
  computed: {
    messages(): ClubChatMessage[] {
      return [...this.communication]
        .map(
          (msg: ClubChatMessage): ClubChatMessage => ({
            ...msg,
            message:
              // sanitize message content to avoid XSS attacks
              sanitizeHtml(msg.message)
                // apply not-italic class to non-ASCII characters (emotes, etc.)
                .replace(
                  NON_ASCII_CHARACTERS_REGEX,
                  (match: string): string => `<span class="not-italic">${match}</span>`,
                ),
          }),
        )
        .sort((a: ClubChatMessage, b: ClubChatMessage): number => a.date - b.date)
    },
  },
})
</script>

<style lang="scss" scoped>
#club-chat-scroll {
  overflow-y: auto;
}
</style>
