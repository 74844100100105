<template>
  <button
    :id="id"
    type="button"
    class="clickable-skew-element event-button flexing text-36"
    :class="{ 'event-button-disabled': disabled, 'font-bold': $isWsm, 'font-medium': $isSsm }"
    :style="{ width: getSize }"
    :disabled="disabled"
    @click="$emit('onClick')"
  >
    <aside
      v-if="!loading"
      class="event-button-left-side revert-skew"
      :style="{ width: leftSideWidth + 'rem' }"
    >
      <slot name="leftSide" />
    </aside>
    <aside
      v-if="!loading"
      class="event-button-right-side revert-skew"
      :style="{ width: rightSideWidth + 'rem' }"
    >
      <slot name="rightSide" />
    </aside>

    <div
      v-if="loading"
      class="absolute revert-skew loading-btn top-0 right-0 w-full h-full"
      style="transform: skewX(0deg)"
    >
      <component-loading height="100%" width="100%" :is-loading="true" />
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EventButton',
  props: {
    id: {
      type: String,
      default: 'event-button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    leftSideWidth: {
      type: Number,
      default: 13.375,
    },
    rightSideWidth: {
      type: Number,
      default: 5,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onClick'],
  computed: {
    getSize() {
      return this.leftSideWidth + this.rightSideWidth + 'rem'
    },
  },
})
</script>

<style lang="scss" scoped>
.event-button {
  height: 4.375rem;
  outline: none;

  @if $isWsm {
    transform: $skewX-value;
    color: #ffffff;
    background-image: linear-gradient(80deg, rgb(73, 172, 46) 0%, rgb(22, 148, 16) 100%);
    box-shadow:
      inset 0.188rem 0 0 0 rgb(255 255 255 / 40%),
      inset -0.188rem 0 0 0 rgb(255 255 255 / 40%),
      inset 0 -0.125rem 0.063rem 0 rgb(255 255 255 / 40%),
      inset 0 0.25rem 0 0 rgb(255 255 255 / 64%);
  }

  @if $isSsm {
    color: theme('colors.texts.buttons.bright');
    background-image: linear-gradient(to right, #01e3cd, #5fffc8);
    border: solid 0.188rem #81ffee;
    text-shadow: none;
  }

  &-left-side,
  &-right-side {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &-right-side {
    height: 70%;

    @if $isWsm {
      border-left: 0.188rem solid #4dba31;
    }

    @if $isSsm {
      border-left: 0.188rem solid theme('colors.texts.buttons.bright');
    }
  }

  &-disabled {
    opacity: 0.6;
  }
}
</style>
