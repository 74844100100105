import type { AtlasDataInterface } from '@/map-phaser-new/interfaces'
import {
  eventOsraAstcJson as wsmEventOsraAstcJson,
  eventOsraEtcJson as wsmEventOsraEtcJson,
  eventOsraImgJson as wsmEventOsraImgJson,
  eventOsraOutlineAstcJson as wsmEventOsraOutlineAstcJson,
  eventOsraOutlineEtcJson as wsmEventOsraOutlineEtcJson,
  eventOsraOutlineImgJson as wsmEventOsraOutlineImgJson,
} from './dataFile/winter-sports'
import {
  eventOsraAstcJson as ssmEventOsraAstcJson,
  eventOsraEtcJson as ssmEventOsraEtcJson,
  eventOsraImgJson as ssmEventOsraImgJson,
  eventOsraOutlineAstcJson as ssmEventOsraOutlineAstcJson,
  eventOsraOutlineEtcJson as ssmEventOsraOutlineEtcJson,
  eventOsraOutlineImgJson as ssmEventOsraOutlineImgJson,
} from './dataFile/summer-sports'
import { pathImages } from '@/globalVariables'
import { isWsm } from '@/plugins'

export type EventsOsraAtlasLoadDataResponse = () => AtlasDataInterface[]

export const eventsOsraAtlasLoadData: EventsOsraAtlasLoadDataResponse = () => {
  return isWsm
    ? [
        {
          name: 'buildings.event_osra',
          compressed: true,
          data: {
            etc: {
              type: 'PVR',
              textureURL:
                pathImages + 'map/sprites/compressed/eventOsraWithGoal/osra-etc2rgba.pvr?v1',
              dataFile: wsmEventOsraEtcJson,
            },
            astc: {
              type: 'PVR',
              textureURL:
                pathImages + 'map/sprites/compressed/eventOsraWithGoal/osra-astc8x8.pvr?v1',
              dataFile: wsmEventOsraAstcJson,
            },
            img: {
              textureURL: pathImages + 'map/sprites/compressed/eventOsraWithGoal/osra-png8.png?v1',
              dataFile: wsmEventOsraImgJson,
            },
            image: pathImages + 'map/sprites/compressed/eventOsraWithGoal/osra-png8.png?v1',
            json: wsmEventOsraImgJson,
          },
        },
        {
          name: 'buildings.event_osra_outline',
          compressed: true,
          data: {
            etc: {
              type: 'PVR',
              textureURL:
                pathImages + 'map/sprites/compressed/eventOsraWithGoal/osra-hover-etc2rgba.pvr?v1',
              dataFile: wsmEventOsraOutlineEtcJson,
            },
            astc: {
              type: 'PVR',
              textureURL:
                pathImages + 'map/sprites/compressed/eventOsraWithGoal/osra-hover-astc8x8.pvr?v1',
              dataFile: wsmEventOsraOutlineAstcJson,
            },
            img: {
              textureURL:
                pathImages + 'map/sprites/compressed/eventOsraWithGoal/osra-hover-png8.png?v1',
              dataFile: wsmEventOsraOutlineImgJson,
            },
            image: pathImages + 'map/sprites/compressed/eventOsraWithGoal/osra-hover-png8.png?v1',
            json: wsmEventOsraOutlineImgJson,
          },
        },
      ]
    : [
        {
          name: 'buildings.event_osra',
          compressed: true,
          data: {
            astc: {
              type: 'KTX',
              textureURL: pathImages + 'map/sprites/compressed/eventOsraWithGoal/osra-astc8_v5.ktx',
              dataFile: ssmEventOsraAstcJson,
            },
            etc: {
              type: 'KTX',
              textureURL:
                pathImages + 'map/sprites/compressed/eventOsraWithGoal/osra-etc2rgba_v5.ktx',
              dataFile: ssmEventOsraEtcJson,
            },
            img: {
              textureURL: pathImages + 'map/sprites/compressed/eventOsraWithGoal/osra_v5.avif',
              dataFile: ssmEventOsraImgJson,
            },
            image: pathImages + 'map/sprites/compressed/eventOsraWithGoal/osra_v5.avif',
            json: ssmEventOsraImgJson,
          },
        },
        {
          name: 'buildings.event_osra_outline',
          compressed: true,
          data: {
            astc: {
              type: 'KTX',
              textureURL:
                pathImages + 'map/sprites/compressed/eventOsraWithGoal/osra-outline-astc8_v5.ktx',
              dataFile: ssmEventOsraOutlineAstcJson,
            },
            etc: {
              type: 'KTX',
              textureURL:
                pathImages +
                'map/sprites/compressed/eventOsraWithGoal/osra-outline-etc2rgba_v5.ktx',
              dataFile: ssmEventOsraOutlineEtcJson,
            },
            img: {
              textureURL:
                pathImages + 'map/sprites/compressed/eventOsraWithGoal/osra-outline_v5.avif',
              dataFile: ssmEventOsraOutlineImgJson,
            },
            image: pathImages + 'map/sprites/compressed/eventOsraWithGoal/osra-outline_v5.avif',
            json: ssmEventOsraOutlineImgJson,
          },
        },
      ]
}
