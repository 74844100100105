import { REPORT_MATCH_ID, REPORT_MATCH_TIME, ARENA_DISCIPLINE_ID } from '@/globalVariables'
import type { RouteLocationNormalized } from 'vue-router'

export interface ReportValues {
  disciplineId: number
  matchId: string
  matchTime: string
}

export const getReportValues = (route: RouteLocationNormalized): ReportValues => {
  const disciplineId = route.query.discipline_id
    ? Number(route.query.discipline_id)
    : Number(localStorage.getItem(ARENA_DISCIPLINE_ID))
  const matchId = String(route.query.match_id) ?? localStorage.getItem(REPORT_MATCH_ID)
  const matchTime = String(route.query.match_time) ?? localStorage.getItem(REPORT_MATCH_TIME)

  return { disciplineId, matchId, matchTime }
}
