import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const stationTrainCheckpoints: CheckpointsObjectInterface = {
  key: 'stationTrain',
  data: [
    {
      x: 2723.749974671752,
      y: 284.9999976605178,
      width: 8,
      height: 8,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.PauseAction,
        action: {
          duration: 5000,
        },
      },
    },
    {
      x: 2832.499973051251,
      y: 327.49999702721846,
      width: 8,
      height: 8,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'trainTurnRightBottom2Top',
        },
      },
    },
  ],
}
