<template>
  <section
    v-if="!eventCollectionActive && eventPass?.length"
    class="event-pass-summary-body flexing flex-grow w-full"
    :class="{ 'is-premium': $route.meta.isPremiumRoute }"
    :data-event-theme="namespace"
  >
    <div class="event-pass-summary-body-cards flexing w-full h-full">
      <div
        v-for="(pass, i) in eventPass"
        :key="i"
        class="event-pass-summary-body-card card"
        :class="[{ 'is-premium': $route.meta.isPremiumRoute }, `tier-${i + 1}`]"
      >
        <div
          class="card-header flexing w-full text-texts-standard-default text-center font-bold italic"
          :class="[$route.meta.isPremiumRoute ? 'text-40 mt-8' : 'text-50 mt-10']"
        >
          <span class="text-texts-standard-important mr-2">{{ $te('eventpass') }}</span>
          {{ $te('tier') }} {{ i + 1 }}
        </div>
        <div class="card-image w-full relative" :class="`tier-${i + 1}`">
          <div v-if="namespace === EventType.AutumnFair">
            <div v-for="(reward, j) in processedOffers[i]" :key="j">
              <p
                v-if="reward?.value"
                class="card-value flexing text-texts-standard-default text-center font-bold italic transform -translate-x-1/2 absolute"
                :class="[
                  `card-value__tier-${reward.tier}--value-${reward.reward}`,
                  $route.meta.isPremiumRoute ? 'text-28' : 'text-32',
                ]"
              >
                {{ `${reward.value}x` }}
              </p>
            </div>
          </div>
          <div v-else>
            <p
              v-for="(reward, j) in pass.offer_content"
              :key="j"
              class="flexing text-texts-standard-default text-center font-bold italic transform -translate-x-1/2 absolute"
              :class="[`card-value-${j + 1}`, $route.meta.isPremiumRoute ? 'text-28' : 'text-32']"
            >
              {{ reward.value }}{{ getResourceUnit(reward.name) }}
            </p>
          </div>
        </div>
        <div
          class="card-status flexing w-full"
          :class="[
            `tier-${i + 1}`,
            {
              purchased: !pass.remaining_buys && i !== activePassIndex,
              activated: !pass.remaining_buys && i === activePassIndex,
            },
          ]"
        >
          <p
            class="flexing text-texts-standard-important text-center font-bold italic uppercase"
            :class="[$route.meta.isPremiumRoute ? 'text-40' : 'text-50']"
          >
            <template v-if="!pass.remaining_buys">
              <template v-if="i === activePassIndex">
                {{ $te('activated') }}
                <app-icon
                  icon-name="done-md"
                  class="inline-block ml-4"
                  :style="$route.meta.isPremiumRoute && 'height: 2.5rem;'"
                />
              </template>
              <template v-else>{{ $te('purchased') }}</template>
            </template>
            <template v-else>
              <p
                v-if="hasOfferOldPrice(pass)"
                class="text-40 text-texts-standard-additional font-normal space-x-2"
                :class="[$route.meta.isPremiumRoute ? 'mr-6' : 'mr-8']"
              >
                <span class="price-crossed inline-flex relative">{{ getOfferOldPrice(pass) }}</span>
                <span class="inline-flex">{{ pass.price.currency }}</span>
              </p>
              <p class="gradient-text-light">
                {{ pass.price.price }}&nbsp;{{ pass.price.currency }}
              </p>
            </template>
          </p>
        </div>
        <div class="card-footer flexing">
          <app-button
            btn-size="md"
            :btn-type="$isWsm ? 'primary' : 'confirm'"
            :btn-text="$te('details')"
            @click="$router.push({ query: { pass: PassType.Event, tier: i + 1 } })"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { getOfferOldPrice, hasOfferOldPrice } from '@/helpers/getOfferOldPrice'
import { getResourceUnit } from '@/helpers/getResourceUnit'
import { PassType } from '@/interfaces/events/Passes'
import { EventType } from '@/interfaces/events/EventInfo'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import type { Offer, OfferContent, PremiumPacksInterface, Reward } from '@/interfaces/premium/Offer'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  PassType: typeof PassType
  EventType: typeof EventType
}

type OfferContentOrReward = OfferContent | Reward

interface TierItem {
  tier: number
  reward: number // index
  value: number
  multiplier?: number
}

export default defineComponent({
  name: 'EventPass3SummaryBody',
  data(): ComponentData {
    return {
      PassType,
      EventType,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      eventPass: 'getEventPass',
    }),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
      eventCollectionActive: 'getEventCollectionActive',
    }),
    activePassIndex(): number {
      return this.eventPass.findLastIndex(
        ({ remaining_buys }: PremiumPacksInterface['packs'][0]): boolean => remaining_buys === 0,
      )
    },
    processedOffers(): TierItem[][] {
      return this.eventPass.map((offer: Offer, index: number): TierItem[] => {
        const id = index + 1
        const tierArray = []

        const addons = offer.offer_content
          .filter(
            (content: OfferContentOrReward): boolean =>
              content.name === 'event_pass_free_daily_wild_cards' ||
              content.name === 'event_pass_free_daily_energy_drinks',
          )
          .reduce(
            (
              acc: OfferContentOrReward,
              content: OfferContentOrReward,
            ): OfferContentOrReward | TierItem => {
              if (!acc || acc.value !== content.value) {
                return { tier: id, reward: 1, value: content.value }
              }
              return acc
            },
            null,
          )
        if (addons) tierArray.push(addons)

        const basket_addons = offer.offer_content
          .filter(
            (content: OfferContentOrReward): boolean =>
              content.name === 'fortune_basket_free_bonuses',
          )
          .reduce(
            (
              acc: OfferContentOrReward,
              content: OfferContentOrReward,
            ): OfferContentOrReward | TierItem => {
              if (!acc || acc.value !== content.value) {
                return { tier: id, reward: 2, value: content.value }
              }
              return acc
            },
            null,
          )
        if (basket_addons) tierArray.push(basket_addons)

        const token_assets = offer.offer_content
          .filter(
            (content: OfferContentOrReward): boolean => content.name === 'bounty_board_token_bonus',
          )
          .reduce(
            (
              acc: OfferContentOrReward,
              content: OfferContentOrReward,
            ): OfferContentOrReward | TierItem => {
              if (!acc || acc.value !== content.value) {
                return { tier: id, reward: 3, value: content.value }
              }
              return acc
            },
            null,
          )
        if (token_assets) tierArray.push(token_assets)

        // Note: In this case, based on previous decisions, we have a graphically rendered multiplier,
        // so we do not let the unit value be declaratively filtered.

        return tierArray
      })
    },
  },
  methods: {
    getResourceUnit,
    hasOfferOldPrice,
    getOfferOldPrice,
  },
})
</script>

<style lang="scss" scoped>
$tiers: (1, 2, 3);

[data-event-theme='frozen'] {
  @each $tier in $tiers {
    --events-passes-event-card-#{$tier}-summary-bg: url('#{$path-events}frozen-championship/passes/event-pass/card-#{$tier}-summary-bg.webp?v=1');
  }

  --card-header-height: 5rem;
  --card-image-height: 14.5rem;
}

[data-event-theme='autumn'] {
  @each $tier in $tiers {
    --events-passes-event-card-#{$tier}-summary-bg: url('#{$path-events}autumn-fair/passes/event-pass/card-#{$tier}-summary-bg.avif?v2');
  }

  --card-header-height: 4rem;
  --card-image-height: 15.125rem;
}

.event-pass-summary-body {
  &-cards {
    justify-content: space-evenly;
  }

  .card {
    &-image {
      [class*='card-value-'] {
        width: 10rem;
      }
    }

    &-status {
      border-style: solid;
      border-width: 0.188rem;
      border-image-slice: 1;

      &.tier-1 {
        background-image: linear-gradient(
          90deg,
          rgba(53, 14, 7, 0.25),
          rgba(53, 14, 7, 0.85),
          rgba(53, 14, 7, 0.85),
          rgba(53, 14, 7, 0.25)
        );
        border-image-source: linear-gradient(
          90deg,
          transparent,
          rgba(143, 55, 39, 0.25),
          rgba(143, 55, 39, 0.85),
          rgba(143, 55, 39, 0.85),
          rgba(143, 55, 39, 0.25),
          transparent
        );
      }

      &.tier-2 {
        background-image: linear-gradient(
          90deg,
          rgba(33, 33, 33, 0.25),
          rgba(33, 33, 33, 0.85),
          rgba(33, 33, 33, 0.85),
          rgba(33, 33, 33, 0.25)
        );
        border-image-source: linear-gradient(
          90deg,
          transparent,
          rgba(119, 119, 119, 0.25),
          rgba(119, 119, 119, 0.85),
          rgba(119, 119, 119, 0.85),
          rgba(119, 119, 119, 0.25),
          transparent
        );
      }

      &.tier-3 {
        background-image: linear-gradient(
          90deg,
          rgba(60, 35, 0, 0.25),
          rgba(60, 35, 0, 0.85),
          rgba(60, 35, 0, 0.85),
          rgba(60, 35, 0, 0.25)
        );
        border-image-source: linear-gradient(
          90deg,
          transparent,
          rgba(144, 89, 7, 0.25),
          rgba(144, 89, 7, 0.85),
          rgba(144, 89, 7, 0.85),
          rgba(144, 89, 7, 0.25),
          transparent
        );
      }

      &.activated {
        background-image: linear-gradient(90deg, #125504, #135905, #1a7708, #135905, #125504);
        border-image-source: linear-gradient(
          90deg,
          transparent,
          #ff9600,
          #4fba39,
          #ff9600,
          transparent
        );
      }

      .price-crossed::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 0.25rem;
        background-color: #b52e2d;
        transform: translate(-50%, -50%) skewY(-14deg);
      }
    }
  }

  &.is-premium .card {
    width: 29rem;
    height: 100%;

    &-header {
      height: var(--card-header-height);
    }

    &-image {
      height: var(--card-image-height);

      &.tier-1 {
        .card-value-1 {
          left: 7.75rem;
          bottom: 2.5rem;
        }

        .card-value-2 {
          right: -1.625rem;
          bottom: 2.5rem;
        }
      }

      &.tier-2 {
        .card-value-1 {
          left: 5.25rem;
          bottom: 2.5rem;
        }

        .card-value-3 {
          left: 14.5rem;
          bottom: 5.75rem;
        }

        .card-value-2 {
          right: -4.25rem;
          bottom: 2.5rem;
        }
      }

      &.tier-3 {
        .card-value-1 {
          left: 10.5rem;
          bottom: 9.25rem;
        }

        .card-value-3 {
          left: 10.5rem;
          bottom: 2.25rem;
        }

        .card-value-2 {
          right: -4.25rem;
          bottom: 9.25rem;
        }

        .card-value-4 {
          right: -4.25rem;
          bottom: 2.25rem;
        }
      }
    }

    &-status {
      height: 3.75rem;
    }

    &-footer {
      height: 5.5rem;
    }

    .card-value {
      &__tier-1,
      &__tier-2,
      &__tier-3 {
        &--value-1 {
          @if $isSsm {
            left: 4.625rem;
          }

          @if $isWsm {
            left: 4.5rem;
          }
        }

        &--value-2 {
          @if $isSsm {
            right: 2.75rem;
          }

          @if $isWsm {
            right: 2.75rem;
          }
        }

        &--value-3 {
          bottom: 0.875rem;

          @if $isSsm {
            left: 4.625rem;
          }

          @if $isWsm {
            left: 4.5rem;
          }
        }
      }
    }
  }

  &:not(.is-premium) .card {
    width: 33.938rem;
    height: 36rem;
    @include background(null, contain);

    @each $tier in $tiers {
      &.tier-#{$tier} {
        background-image: var(--events-passes-event-card-#{$tier}-summary-bg);
      }
    }

    &-header {
      height: 5rem;
    }

    &-image {
      height: 17.4375rem;

      &.tier-1 {
        .card-value-1 {
          left: 9.25rem;
          bottom: 2.375rem;
        }

        .card-value-2 {
          right: -0.5rem;
          bottom: 2.375rem;
        }
      }

      &.tier-2 {
        .card-value-1 {
          left: 6.25rem;
          bottom: 2.375rem;
        }

        .card-value-3 {
          left: 17rem;
          bottom: 6.125rem;
        }

        .card-value-2 {
          right: -3.5rem;
          bottom: 2.375rem;
        }
      }

      &.tier-3 {
        .card-value-1 {
          left: 12rem;
          bottom: 11rem;
        }

        .card-value-3 {
          left: 12rem;
          bottom: 3rem;
        }

        .card-value-2 {
          right: -3.25rem;
          bottom: 11rem;
        }

        .card-value-4 {
          right: -3.25rem;
          bottom: 3rem;
        }
      }
    }

    &-status {
      height: 4.25rem;
    }

    &-footer {
      height: 6.8125rem;
    }
  }

  .card-value {
    &__tier-1 {
      // energy drink, wild cards
      &--value-1 {
        top: 0.875rem;

        @if $isSsm {
          left: 5.5rem;
        }

        @if $isWsm {
          left: 5.25rem;
        }
      }

      // 2x multipliers, refresh
      &--value-2 {
        top: 0.875rem;

        @if $isSsm {
          right: 3.25rem;
        }

        @if $isWsm {
          right: 3.375rem;
        }
      }
    }

    &__tier-2 {
      // energy drink, wild cards
      &--value-1 {
        top: 0.875rem;

        @if $isSsm {
          left: 5.5rem;
        }

        @if $isWsm {
          left: 5.25rem;
        }
      }

      // 3x multipliers, refresh
      &--value-2 {
        top: 0.875rem;

        @if $isSsm {
          right: 3.25rem;
        }

        @if $isWsm {
          right: 3.375rem;
        }
      }
    }

    &__tier-3 {
      // energy drink, wild cards
      &--value-1 {
        top: 0.875rem;

        @if $isSsm {
          left: 5.5rem;
        }

        @if $isWsm {
          left: 5.25rem;
        }
      }

      // 3x multipliers, refresh
      &--value-2 {
        top: 0.875rem;

        @if $isSsm {
          right: 3.25rem;
        }

        @if $isWsm {
          right: 3.375rem;
        }
      }

      // tokens
      &--value-3 {
        bottom: 1rem;

        @if $isSsm {
          left: 5.375rem;
        }

        @if $isWsm {
          left: 5.375rem;
        }
      }
    }
  }
}
</style>
