import {
  EQUIPMENT_ATTRIBUTE_BONUS,
  EQUIPMENT_CASH_BONUS,
  EQUIPMENT_DELIVERY_TIME,
  EQUIPMENT_DURABILITY,
  EQUIPMENT_LEVEL_NAME,
  EQUIPMENT_PRICE_CASH,
  EQUIPMENT_PRICE_RP,
  EQUIPMENT_REPAIR_CASH,
  EQUIPMENT_REPAIR_TIME,
  EQUIPMENT_TP_BONUS,
  SPEEDUP_PRICE,
  UPGRADE_BONUS,
  UPGRADE_PRICE_CASH,
  UPGRADE_PRICE_GEMS,
  UPGRADE_PRICE_RP,
  UPGRADE_TIME,
} from '@/globalVariables'
import type { Nullable } from '@/interfaces/utils'
import { clone } from '../clone'

interface Attributes {
  value: number // TODO: | string - Z BE ide string, ale my mame default hodnotu number a chovame sa vsade k tomu tak...
  suffix: string
  baseValue: number
  bonusValue: number
  currentValue?: number
  [EQUIPMENT_DURABILITY]?: unknown
  equipment_attribute_bonus?: {
    baseValue?: number
  }
}

const ATTRIBUTES = {
  value: 0,
  suffix: '',
  baseValue: 0,
  bonusValue: 0,
} as Attributes

export interface ItemParameters {
  item_attribute_bonus?: Attributes['value']
  item_money_bonus?: Attributes['value']
  item_tp_points_bonus?: Attributes['value']
}

export interface EquipmentParameters {
  [EQUIPMENT_ATTRIBUTE_BONUS]: Attributes
  [EQUIPMENT_TP_BONUS]: Attributes
  [EQUIPMENT_CASH_BONUS]: Attributes
  [EQUIPMENT_DURABILITY]: Attributes
}

const EQUIPMENT_PARAMETERS = {
  [EQUIPMENT_ATTRIBUTE_BONUS]: {
    ...ATTRIBUTES,
    suffix: '',
  },
  [EQUIPMENT_TP_BONUS]: {
    ...ATTRIBUTES,
    suffix: '%',
  },
  [EQUIPMENT_CASH_BONUS]: {
    ...ATTRIBUTES,
    suffix: '%',
  },
  [EQUIPMENT_DURABILITY]: {
    ...ATTRIBUTES,
    suffix: '',
    currentValue: 0,
  },
} as EquipmentParameters

interface Parameter {
  name: string
  value: number | string
}

interface Delivery {
  speedup: number
  time: number
  prices: Record<string, number>
}

interface Repair {
  speedup: number
  price: number
  time: number
}

interface Upgrade {
  speedup: number
  time: number
  bonus: number
  types: Record<string, number>
}

interface Premium {
  locked: boolean
  time: number
}

export interface ItemLevel {
  level: number
  parameters?: Parameter[]
  quality?: string
  delivery?: Delivery
  repair?: Repair
  upgrade?: Upgrade
  premium?: Premium
}

interface FormattedItemLevel extends Omit<ItemLevel, 'parameters'> {
  parameters: EquipmentParameters | (EquipmentParameters & ItemParameters)
}

const SPEEDUP_PRICE_REPAIR = SPEEDUP_PRICE + '_' + EQUIPMENT_REPAIR_TIME
const SPEEDUP_PRICE_DELIVERY = SPEEDUP_PRICE + '_' + EQUIPMENT_DELIVERY_TIME
const SPEEDUP_PRICE_UPGRADE = SPEEDUP_PRICE + '_' + UPGRADE_TIME

export const formatLevel = (
  itemLevel: Nullable<ItemLevel>,
  isEquipment: boolean = false,
): Nullable<FormattedItemLevel> => {
  if (!itemLevel) return null

  const data: FormattedItemLevel = {
    level: itemLevel.level,
    quality: '',
    parameters: clone(EQUIPMENT_PARAMETERS),
    delivery: {
      speedup: 0,
      time: 0,
      prices: {},
    },
    repair: {
      speedup: 0,
      price: 0,
      time: 0,
    },
    upgrade: {
      speedup: 0,
      time: 0,
      bonus: 0,
      types: {},
    },
  }

  itemLevel.parameters?.forEach((parameter: Parameter): void => {
    switch (parameter.name) {
      case EQUIPMENT_DELIVERY_TIME:
        // TODO-BE (value: number) string > number
        data.delivery.time = parseInt(parameter.value.toString()) * 1000
        break
      case EQUIPMENT_PRICE_CASH:
      case EQUIPMENT_PRICE_RP:
        // TODO-BE (value: number) string > number
        data.delivery.prices[parameter.name] = parseInt(parameter.value.toString())
        break
      case EQUIPMENT_LEVEL_NAME:
        data.quality = parameter.value as string
        break
      case EQUIPMENT_DURABILITY:
      case EQUIPMENT_ATTRIBUTE_BONUS:
      case EQUIPMENT_TP_BONUS:
      case EQUIPMENT_CASH_BONUS:
        // TODO-BE (value: number) string > number
        data.parameters[parameter.name].value = parseInt(parameter.value.toString())
        data.parameters[parameter.name].baseValue = data.parameters[parameter.name].value
        break
      case EQUIPMENT_REPAIR_CASH:
        data.repair.price = parameter.value as number
        break
      case EQUIPMENT_REPAIR_TIME:
        data.repair.time = (parameter.value as number) * 1000
        break
      case UPGRADE_PRICE_CASH:
      case UPGRADE_PRICE_GEMS:
      case UPGRADE_PRICE_RP:
        // TODO-BE (value: number) string > number
        data.delivery.prices[parameter.name] = parseInt(parameter.value.toString())
        data.upgrade.types[parameter.name] = parseInt(parameter.value.toString())
        break
      case UPGRADE_TIME:
        data.upgrade.time = parseInt(parameter.value.toString()) * 1000
        break
      case UPGRADE_BONUS:
        data.upgrade.bonus = parseInt(parameter.value.toString())
        break
      case SPEEDUP_PRICE_REPAIR:
        data.repair.speedup = parameter.value as number
        break
      case SPEEDUP_PRICE_UPGRADE:
        data.upgrade.speedup = parameter.value as number
        break
      case SPEEDUP_PRICE_DELIVERY:
        data.delivery.speedup = parameter.value as number
        break
    }
  })

  if (isEquipment) {
    delete data.upgrade
  } else {
    delete data.delivery
    delete data.repair
    delete data.quality
  }
  return data
}
