<template>
  <div
    v-for="(attribute, i) in getEqAttributes"
    :key="i"
    class="eq-item-row flexing flex-col"
    :class="[customClass, { 'eq-item-row-detail': isDetail }]"
  >
    <div v-if="isDetail && attribute.name === 'durability'" class="yellow-strip" />
    <p
      v-if="attribute.name === 'durability' && attributesData.premium"
      class="text-texts-standard-important uppercase"
      :class="isDetail ? 'text-36' : isOffer ? 'text-28 w-full text-right' : 'text-28 w-full'"
    >
      {{ $t('equipmentShop.indestructible') }}
    </p>
    <div v-else class="w-full flex justify-between">
      <p class="text-texts-standard-default">
        {{ setAttributeName(attribute.name) }}
      </p>
      <div class="flexing">
        <div
          v-if="hasArrows && attribute.value"
          :class="setTriangleParams(attribute.name, attribute.value)"
          class="mr-2"
        />
        <p :class="'font-bold'" v-html="setAttributeCount(attribute.value, attribute.name)" />
        <equipment-item-attributes-icons
          :icon-type="attribute.icon"
          :discipline-id="Number(attributesData.disciplineId)"
          :custom-icon-class="(customIconClass, 'mr-0')"
          :icon-size="48"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import EquipmentItemAttributesIcons from '@/components/Equipment/EquipmentItemAttributesIcons.vue'
import {
  EQUIPMENT_EQUIPPED,
  DISCIPLINE,
  DURABILITY,
  MONEY,
  TRAINING_POINTS,
} from '@/globalVariables'
import { convertNameValue } from '@/helpers'
import { useEquipmentStore } from '@/store/pinia/equipment/equipmentStore'
import type { FormattedAttributesData } from '@/interfaces/Equipment'
import type { EquipmentItemData } from '@/store/pinia/equipment/equipmentStore'
import type { Nullable } from '@/interfaces/utils'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface EquipmentAttribute {
  name: string
  value: number
  icon: string
}

interface EquipmentAttributes {
  attributeBonus: EquipmentAttribute
  cashBonus: EquipmentAttribute
  tpBonus: EquipmentAttribute
  durability: EquipmentAttribute
}

interface SelectedEqupmentItem extends EquipmentItemData {
  parameters: ReturnType<typeof convertNameValue>
}

export default defineComponent({
  name: 'EquipmentItemAttributes',
  components: {
    EquipmentItemAttributesIcons,
  },
  props: {
    attributesData: {
      type: Object as PropType<FormattedAttributesData>,
      required: true,
    },
    hasArrows: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    customIconClass: {
      type: String,
      default: '',
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    isOffer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useEquipmentStore, {
      equipmentItem: 'getSpecificEquipmentItem',
    }),
    selectedEq(): Nullable<SelectedEqupmentItem> {
      const item = this.equipmentItem.find(
        (item: EquipmentItemData): boolean => item.itemState.name === EQUIPMENT_EQUIPPED,
      ) as SelectedEqupmentItem

      if (!item) return null

      item.parameters = convertNameValue(
        'premium' in item && !!item.premium ? item.properties : item.levelParameters,
      )

      return item
    },
    getEqAttributes(): EquipmentAttributes {
      const attributeBase = this.attributesData.equipmentBonusData?.attributes?.attribute_bonus ?? 0
      const cashBase = this.attributesData.equipmentBonusData?.bonus_cash?.attribute_bonus ?? 0
      const tpBase = this.attributesData.equipmentBonusData?.bonus_tp?.attribute_bonus ?? 0
      return {
        attributeBonus: {
          name: 'attributeBonus',
          value:
            attributeBase +
            Number(
              this.attributesData.damaged
                ? 0
                : this.attributesData.parameters?.item_attribute_bonus
                  ? this.attributesData.parameters?.item_attribute_bonus
                  : this.attributesData.parameters?.equipment_attribute_bonus,
            ),
          icon: DISCIPLINE,
        },
        cashBonus: {
          name: 'moneyBonus',
          value:
            cashBase +
            Number(
              this.attributesData.parameters?.item_money_bonus ??
                this.attributesData.parameters?.equipment_cash_bonus,
            ),
          icon: MONEY,
        },
        tpBonus: {
          name: 'trainingBonus',
          value:
            tpBase +
            Number(
              this.attributesData.parameters?.item_tp_points_bonus ??
                this.attributesData.parameters?.equipment_tp_bonus,
            ),
          icon: TRAINING_POINTS,
        },
        durability: {
          name: 'durability',
          value: this.attributesData.parameters?.equipment_durability ?? 0,
          icon: DURABILITY,
        },
      }
    },
    damagedEq(): string {
      // ak mame poskodenu vybavu dame cislo pri durability cervenou
      if (this.attributesData.damaged) return '<p class="text-texts-standard-danger">'
      return '<p class="text-texts-standard-important">'
    },
  },
  methods: {
    // vyberieme typ bonusu a priradime mu hodnotu
    setTriangleParams(
      name: EquipmentAttribute['name'],
      value: EquipmentAttribute['value'],
    ): string {
      switch (name) {
        case 'attributeBonus':
          return this.getTriangleClass(
            value ?? 0,
            this.selectedEq?.parameters.equipment_attribute_bonus ?? 0,
          )
        case 'moneyBonus':
          return this.getTriangleClass(
            value ?? 0,
            this.selectedEq?.parameters.equipment_cash_bonus ?? 0,
          )
        case 'trainingBonus':
          return this.getTriangleClass(
            value ?? 0,
            this.selectedEq?.parameters.equipment_tp_bonus ?? 0,
          )
        default:
          return this.getTriangleClass(
            value ?? 0,
            this.selectedEq?.parameters.equipment_durability ?? 0,
          )
      }
    },
    // vyberieme typ sípky
    getTriangleClass(
      itemParamValue: EquipmentAttribute['value'],
      equippedItemParamValue: SelectedEqupmentItem['parameters'][keyof SelectedEqupmentItem['parameters']],
    ): string {
      if (Number(itemParamValue) === Number(equippedItemParamValue)) return ''
      else if (Number(itemParamValue) < Number(equippedItemParamValue))
        return 'triangle triangle-down red'
      return 'triangle triangle-up green'
    },
    setAttributeName(name: EquipmentAttribute['name']): string {
      return this.$t('equipmentShop.' + name)
    },
    setAttributeCount(
      count: EquipmentAttribute['value'],
      name: EquipmentAttribute['name'],
    ): string {
      // Ak vybava nepridana daný bonus zobrazíme " - "
      if (!count) return '<p class="text-texts-standard-default"> - </p>'
      // Ak zobrazujeme durability tak v pripade ze nemame vybavu zakupenu zobrazime len max durability,
      // Ak vybavu mame zakupenu zobrazime aktualnu durability / max durability
      if (name === 'durability') {
        if (this.attributesData.unlocked) {
          return '<p class="text-texts-standard-important">' + count + '</p>'
        }
        return (
          '<span class="flex">' +
          this.damagedEq +
          (this.attributesData.currentDurability ?? count) +
          '/</p><p class="text-texts-standard-default">' +
          count +
          '</p></span>'
        )
        // defaultne zobrazime hodnotu + atributu
      } else {
        // atributovy bonus je len cislo
        if (name === 'attributeBonus') {
          if (this.attributesData.damaged)
            return '<p class="text-texts-standard-danger"> +' + count + '</p>'
          return '<p class="text-texts-standard-important"> +' + count + '</p>'
        }
        // cash bonus a tp bonus percenta
        return '<p class="text-texts-standard-important"> +' + count + '%</p>'
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.box-back-wrapper {
  .eq-item-row {
    margin: 0 0 0.5rem;
  }
}
.eq-item-row {
  margin: 0 0 0 1rem;
  display: flex;
  align-items: center;

  &-detail {
    min-height: 5.25rem;
    margin: 0 1rem 0;
  }

  .yellow-strip {
    margin: 1rem auto;
  }
}
</style>
