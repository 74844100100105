import { clubBuildingsEndpoint } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { useRewardStore } from '@/store/pinia/clubs/rewardStore'
import { defineStore } from 'pinia'

import type { ClubBuildingsResponse } from '@/interfaces/responses/club/ClubBuildingsResponses'
import { usePhaserGameIntegrationStore } from '../map-new/phaserGameIntegrationStore'
import { SCENES_KEYS } from '@/map-phaser-new/constants'
interface LoadBuildingsEntry {
  clubId?: string
  buildingTypeId?: number
  refresh?: boolean
}
interface UpgradeBuildingEntry {
  clubsBuildingId: string
  buildingTypeId?: number
}
interface LoadBuildingsBody {
  clubs_id: string
  building_types_id: number
  refresh: boolean
}
interface ClubBuildingsState {
  buildings: ClubBuildingsResponse[]
  lastShowedMapClubId: string
  isLoadingBuildings: boolean
  buildingsIds: { [key: string]: number }
}

export const useBuildingsStore = defineStore('useBuilding', {
  state: (): ClubBuildingsState => ({
    buildings: [] as ClubBuildingsResponse[],
    lastShowedMapClubId: '',
    isLoadingBuildings: false,
    buildingsIds: null,
  }),
  getters: {
    getBuildings(): ClubBuildingsState['buildings'] {
      return this.buildings
    },
    getLastShowedMapClubId(): ClubBuildingsState['lastShowedMapClubId'] {
      return this.lastShowedMapClubId
    },
    getBuildingsIds(): ClubBuildingsState['buildingsIds'] {
      return this.buildingsIds
    },
  },
  actions: {
    // body contains clubId - optional, if ommited it will get all buildings for user which make request,
    // buildingTypeId - optional, use for filter specific building
    async loadBuildings(
      { clubId = null, buildingTypeId = 0, refresh = false } = {} as LoadBuildingsEntry,
    ): Promise<void> {
      if ((this.buildings.length > 0 || this.isLoadingBuildings) && !refresh) return
      // const useCore = useCoreStore()
      // useCore.setLoadingScreen(true)

      this.isLoadingBuildings = true
      const body = {} as LoadBuildingsBody
      if (clubId) body.clubs_id = clubId
      if (buildingTypeId) body.building_types_id = buildingTypeId

      this.buildings = await internalAxios.post<{}, ClubBuildingsResponse[]>(
        clubBuildingsEndpoint,
        body,
      )

      this.buildings.map((building) => {
        this.buildingsIds = {
          ...this.buildingsIds,
          ...{ [building.parameters.name]: building.building_type_id },
        }
      })
      this.buildingsIds = { ...this.buildingsIds, ...{ all: 0 } }

      this.isLoadingBuildings = false
      // useCore.setLoadingScreen(false)
    },
    async upgradeBuilding({ clubsBuildingId }: UpgradeBuildingEntry): Promise<void> {
      try {
        await internalAxios.put<{}, true>(clubBuildingsEndpoint, {
          clubs_buildings_id: clubsBuildingId,
        })
      } catch (error: unknown) {
        console.error(error)
      }
      usePhaserGameIntegrationStore().reloadBuildings(SCENES_KEYS.ClubsMapScene)
      const rewardStore = useRewardStore()
      rewardStore.loadAllClubRewards(true)
    },
    setLastShowedMapClubId(value: string): void {
      this.lastShowedMapClubId = value
    },
  },
})
