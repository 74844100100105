<template>
  <div
    class="header-info text-texts-standard-default flex items-center italic relative"
    :class="`text-${fontSize}`"
  >
    <app-icon icon-name="info-rounded" class="ml-4 mr-4" />
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { FONT_SIZES } from '@/globalVariables'

export default defineComponent({
  name: 'HeaderInfo',
  props: {
    fontSize: {
      type: Number,
      default: 28,
      validator: (value: number): boolean => FONT_SIZES.includes(value),
    },
  },
})
</script>

<style lang="scss" scoped>
.header-info {
  width: 100%;
  height: 6.25rem;
  line-height: initial;

  @if $isWsm {
    background-color: rgba(41, 82, 121, 0.2);
    box-shadow: inset 0 0.063rem 3.688rem 0 rgba(64, 156, 236, 0.23);

    &::before {
      content: '';
      position: absolute;
      width: 1.375rem;
      height: 1.375rem;
      left: 0;
      bottom: 0;
      border-left: 0.063rem solid #b5deff;
      border-bottom: 0.063rem solid #b5deff;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 0.188rem;
      right: 0;
      bottom: 0;
      background: rgb(255, 255, 255, 0.17);
    }
  }

  @if $isSsm {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: inset 0px 1px 59px 0 rgba(64, 156, 236, 0.23);
  }
}
</style>
