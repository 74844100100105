<template>
  <div data-cy="bug-report" class="bug-report app-page-wrapper absolute">
    <section class="w-full h-full safe-area">
      <div class="bug-report-content h-auto w-full mt-24">
        <app-modal-header :title="$t('bugReport.reportBug')">
          <div class="header-info-box flexing">
            <div class="header-info-box-text enabled-user-text-select center p-1">
              <p class="text-white font-bold">{{ $t('map.your_id') }}:</p>
              <p class="text-texts-standard-important">{{ userData?.id }}</p>
            </div>
          </div>
        </app-modal-header>
        <app-scrollbar
          width="100%"
          height="55rem"
          scroll="y"
          slide="y"
          class="report-bug-scrollbar"
        >
          <div class="info-panel flexing w-full mt-14">
            <app-icon icon-name="info-rounded" />
            <div class="info-panel-content flexing text-32">
              <p class="info-panel-content-text text-texts-standard-important center">
                {{ $t('bugReport.reportBug') }}: {{ $t('bugReport.monday') }} -
                {{ $t('bugReport.friday') }}: 9:00 - 00:00
                {{ $t('bugReport.timeZone') }}
              </p>
              <p class="info-panel-content-text text-texts-standard-important center">
                {{ $t('bugReport.reportInfo') }}
              </p>
            </div>
          </div>
          <div v-if="false" class="alert-panel-content">
            <p class="alert-panel-content-header block font-bold center text-white">
              {{ $t('bugReport.knownIssue') }}: {{ $t('bugReport.trainingHall') }}
            </p>
            <p class="alert-panel-content-text block center text-white">
              {{ $t('bugReport.alertInfo') }}
            </p>
          </div>
          <p class="select-text mx-auto font-bold text-white text-40 uppercase">
            {{ $t('bugReport.issueType') }}
          </p>
          <div class="grid grid-cols-3 gap-11 w-full">
            <div
              v-for="(issueType, key) in issueTypes"
              :key="key"
              class="grid-item flex items-center"
              :class="{ selected: menuInfo.selectedIssueType === key }"
              @click="selectIssueType(key)"
            >
              <app-report-icon
                class="mr-8"
                :icon-name="issueType.icon"
                :selected="menuInfo.selectedIssueType === key"
              />
              <p class="grid-item-text">
                {{ $t('bugReport.' + issueType.text) }}
              </p>
            </div>
          </div>
          <p v-if="isIssueSelected" class="select-text mx-auto font-bold text-white text-40">
            {{ $t('bugReport.deviceInfo') }}
          </p>
          <div v-if="isIssueSelected" class="flex space-x-12">
            <div
              class="grid-item flex justify-center items-center flex-grow"
              :class="{ selected: menuInfo.selectedDeviceType === 1 }"
              @click="selectDeviceType(1)"
            >
              <div
                :class="`mobile-image${menuInfo.selectedDeviceType === 1 ? '-selected' : ''} mr-10`"
              />
              <span class="grid-item-text">
                {{ $t('bugReport.mobileDevice') }}
              </span>
            </div>
            <div
              class="grid-item flex justify-center items-center flex-grow"
              :class="{ selected: menuInfo.selectedDeviceType === 2 }"
              @click="selectDeviceType(2)"
            >
              <div
                :class="`web-image${menuInfo.selectedDeviceType === 2 ? '-selected' : ''} mr-10`"
              />
              <span class="grid-item-text">
                {{ $t('bugReport.webDevice') }}
              </span>
            </div>
          </div>
          <p v-if="isDeviceSelected" class="select-text mx-auto font-bold text-white text-40">
            {{ $t('bugReport.deviceDetail') }}
          </p>
          <form
            v-if="isDeviceSelected"
            novalidate="true"
            class="text-input flex flex-col items-center"
            @submit.prevent="submit"
          >
            <app-report-bug-input
              v-for="(input, key) in inputs"
              :key="key"
              v-model="input.message"
              :name="input.name"
              :label="input.label"
              :type="input.type"
              :placeholder="input.placeholder ? input.placeholder : ''"
              :tooltip="input.tooltip ? input.tooltip : ''"
              :required="input.required ? true : false"
              :full-width="input.fullWidth ? true : false"
              :error="errors.includes(input.name)"
            />
            <app-button
              type="submit"
              class="send-button"
              :disabled="!formFilled"
              btn-type="secondary"
              :btn-text="$t('bugReport.send')"
              btn-size="sm"
            />
          </form>
        </app-scrollbar>
      </div>
    </section>
  </div>
  <confirm-popup
    v-if="showConfirm"
    :not-multi="true"
    :button-data="btnContinue"
    :popup-title="$t('bugReport.reportSent')"
    :single-button-text="$t('bugReport.continue')"
    @close="showConfirm = false"
    @action="showConfirm = false"
  >
    <div>
      <p class="block text-36 text-texts-standard-important">
        {{ $t('bugReport.reportConfirmTitle') }}
      </p>
      <p class="block text-36 text-white">
        {{ $t('bugReport.reportConfirmInfo') }}
      </p>
      <p class="block text-36 text-white">
        {{ $t('bugReport.reportConfirmAnswer') }}
      </p>
    </div>
  </confirm-popup>
</template>

<script lang="ts">
import AppModalHeader from '@/components/GlobalComponents/AppModalHeader.vue'
import AppReportBugInput from '@/components/GlobalComponents/AppReportBugInput.vue'
import AppReportIcon from '@/components/GlobalComponents/AppReportIcon.vue'
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import { sendBugReportMailEndpoint } from '@/globalVariables'
import { validateEmail } from '@/helpers'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface BugReportInput {
  message: string
  name: string
  label: string
  type: string
  required: boolean
  placeholder: string
  tooltip?: string
  fullWidth?: boolean
}

interface ComponentData {
  issueTypes: {
    text: string
    icon: string
  }[]
  deviceTypes: string[]
  showConfirm: boolean
  btnContinue: {
    btnId: string
    btnType: string
    btnSize: string
  }
  menuInfo: {
    selectedIssueType: number
    selectedDeviceType: number
  }
  inputs: BugReportInput[]
  errors: string[]
  isIssueSelected: boolean
  isDeviceSelected: boolean
}

export default defineComponent({
  name: 'BugReport',
  components: {
    AppReportIcon,
    ConfirmPopup,
    AppReportBugInput,
    AppModalHeader,
  },
  data(): ComponentData {
    return {
      issueTypes: [
        {
          text: 'shoppingIssue',
          icon: 'purchase',
        },
        {
          text: 'technicalIssue',
          icon: 'technical',
        },
        {
          text: 'graphicalIssue',
          icon: 'graphical',
        },
        {
          text: 'gameIssue',
          icon: 'general',
        },
        {
          text: 'translationIssue',
          icon: 'translation',
        },
        {
          text: 'otherIssue',
          icon: 'other',
        },
      ],
      deviceTypes: ['mobile', 'web'],
      showConfirm: false,
      btnContinue: {
        btnId: 'btn-continue',
        btnType: 'secondary',
        btnSize: 'sm',
      },
      menuInfo: {
        selectedIssueType: -1,
        selectedDeviceType: -1,
      },
      inputs: [
        {
          message: '',
          name: 'device-name',
          label: this.$isMobile() ? 'bugReport.deviceType' : 'bugReport.browser',
          tooltip: this.$isMobile() ? 'bugReport.deviceClue' : 'bugReport.webClue',
          type: 'text',
          required: true,
          placeholder: this.$isMobile() ? 'bugReport.deviceExample' : 'bugReport.browserExample',
        },
        {
          message: '',
          name: 'device-os',
          label: 'bugReport.os',
          tooltip: this.$isMobile() ? 'bugReport.deviceClue' : 'bugReport.webClue',
          required: true,
          type: 'text',
          placeholder: this.$isMobile() ? 'bugReport.osExampleMobile' : 'bugReport.osExampleWeb',
        },
        {
          message: '',
          name: 'email',
          label: 'mobileApp.email',
          required: true,
          type: 'text',
          placeholder: 'bugReport.enterEmailAddress',
        },
        {
          message: '',
          name: 'subject',
          label: 'bugReport.subject',
          fullWidth: true,
          type: 'text',
          required: true,
          placeholder: 'bugReport.subjectInfo',
        },
        {
          message: '',
          name: 'detail',
          label: 'bugReport.desc',
          fullWidth: true,
          required: true,
          type: 'textarea',
          placeholder: 'bugReport.descInfo',
        },
      ],
      errors: [],
      isIssueSelected: false,
      isDeviceSelected: false,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userData: 'getUserData',
    }),
    formFilled(): boolean {
      let inputsFilled = true
      this.inputs.forEach((input: BugReportInput): void => {
        if (input.name === 'email' && !this.userData.email.startsWith('powerplaymanager+')) {
          input.message = this.userData.email
        }
        if (this.menuInfo.selectedDeviceType === 1) {
          if (input.name === 'device-name') {
            input.label = 'bugReport.deviceType'
            input.tooltip = 'bugReport.deviceClue'
            input.placeholder = 'bugReport.deviceExample'
          }
          if (input.name === 'device-os') {
            input.tooltip = 'bugReport.deviceClue'
            input.placeholder = 'bugReport.osExampleMobile'
          }
        } else if (this.menuInfo.selectedDeviceType === 2) {
          if (input.name === 'device-name') {
            input.label = 'bugReport.browser'
            input.tooltip = 'bugReport.webClue'
            input.placeholder = 'bugReport.browserExample'
          }
          if (input.name === 'device-os') {
            input.tooltip = 'bugReport.webClue'
            input.placeholder = 'bugReport.osExampleWeb'
          }
        }
      })
      if (
        !inputsFilled ||
        this.menuInfo.selectedDeviceType === -1 ||
        this.menuInfo.selectedIssueType === -1
      )
        return false

      return true
    },
  },
  methods: {
    selectIssueType(index: number): void {
      this.isIssueSelected = true
      this.menuInfo.selectedIssueType = index
    },
    selectDeviceType(index: number): void {
      this.isDeviceSelected = true
      this.menuInfo.selectedDeviceType = index
    },
    confirm(): void {
      if (this.menuInfo.selectedIssueType === -1 || this.menuInfo.selectedDeviceType === -1) return

      this.showConfirm = false
    },
    submit(): void {
      this.errors = []
      this.inputs.forEach((input: BugReportInput): void => this.checkForm(input))
      if (!this.formFilled) return

      this.submitForm()
    },
    checkForm(input: BugReportInput): void {
      if ((input.name === 'email' && !validateEmail(input.message)) || !input.message) {
        this.errors.push(input.name)
      }
    },
    async submitForm(): Promise<void> {
      if (this.errors.length) return

      const finalBody = {
        selected_issue: this.issueTypes[this.menuInfo.selectedIssueType].text,
        selected_device: this.deviceTypes[this.menuInfo.selectedDeviceType - 1],
        device_name: this.inputs[0].message,
        device_os: this.inputs[1].message,
        email: this.inputs[2].message,
        subject: this.inputs[3].message,
        description: this.inputs[4].message,
        user_id: this.userData.id,
      }
      try {
        await this.$axios.post<{}, string>(sendBugReportMailEndpoint, finalBody)
      } catch (error: unknown) {
        console.error(error)
      }
      this.showConfirm = true
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/report-icons.scss';

.select-text {
  margin: 4rem 0;
  line-height: 2.5rem;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
}

.info-panel {
  position: relative;
  border-bottom: 0.063rem solid rgba(#fff, 0.5);
  @if $isWsm {
    background: rgba(#295279, 0.7);
  }
  @if $isSsm {
    background: rgba(#fff, 0.2);
  }
  &-content {
    width: 66rem;
    height: 8.125rem;
    flex-direction: column;

    &-text {
      line-height: 2rem;
    }
  }
  .icon-info-rounded {
    position: absolute;
    left: 3rem;
  }
}

.alert-panel {
  &-content {
    border: solid 1px #ff1717;
    background-color: #c31818;
    padding: 1.875rem 0;
    margin-top: 1.875rem;
    width: 100%;

    &-header {
      font-size: 2.5rem;
      line-height: 2.5rem;
      letter-spacing: 0.0625rem;
    }

    &-text {
      margin-top: 2.0625rem;
      line-height: 2.125rem;
      letter-spacing: 0.053125rem;
    }
  }
}

.grid-item {
  padding: 1.875rem;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.5);
  @if $isWsm {
    border: solid 0.063rem #3f6b93;
    background-image: linear-gradient(to top, #3f78a0, #254257);
  }
  @if $isSsm {
    border: solid 0.063rem #7b87b8;
    background: #3a425d;
  }

  &-text {
    @if $isWsm {
      text-shadow: 1.1px 1.7px 1px rgba(0, 0, 0, 0.46);
    }
    font-size: 2.25rem;
    font-weight: normal;
    line-height: 2.25rem;
    letter-spacing: normal;
    text-align: center;
    color: #d9ecff;
    text-transform: uppercase;
  }
}

.selected {
  @if $isWsm {
    box-shadow:
      inset 3px 0 0 0 rgba(255, 255, 255, 0.4),
      inset -3px 0px 0 0 rgba(255, 255, 255, 0.4),
      inset 0px -2px 1px 0 rgba(255, 255, 255, 0.4),
      inset 0px 4px 0 0 rgba(255, 255, 255, 0.64);
    background-image: linear-gradient(to top, #ffef84, #f6c717);
  }
  @if $isSsm {
    background-image: linear-gradient(to right, #ffaa3c, #ffe719);
  }
  .grid-item-text {
    color: #233441;
  }
}

.send-button {
  align-self: end;
  margin-top: -2.4375rem;
  margin-bottom: 3.375rem;
}

.popup-text {
  text-shadow: 1.1px 1.7px 1px rgba(0, 0, 0, 0.46);
  font-size: 3.125rem;
}

.text-input {
  margin-top: 5.1875rem;
}

.report-bug-scrollbar {
  padding: 0 3rem;
}

.header-info-box {
  margin-top: 1rem;
  width: 44.375rem;
  height: 4.375rem;
  position: absolute;
  right: 6rem;
  transform: $skewX-value;
  transform: scale(0.7);
  @if $isWsm {
    border: solid 0.125rem #528ebf;
    background-image: linear-gradient(to top, #4787b4, #2b4e67);
    box-shadow:
      inset -0.4375rem 0 1.3125rem 0 rgba(51, 123, 214, 0.19),
      inset -0.019625rem -0.1865rem 2.5rem 0 rgba(6, 34, 55, 0.48);

    &-icon {
      transform: scale(0.7) $skewX-revert-value;
    }
  }

  @if $isSsm {
    border: solid 0.125rem #586b9d;
    background-image: linear-gradient(to top, rgba(52, 65, 93, 0.85), rgba(39, 50, 73, 0.85));
  }

  &-text {
    font-size: 1.8rem;
    transform: $skewX-revert-value;
    display: flex;
    flex-direction: row;
    & > p:first-child {
      margin-right: 1rem;
    }
  }
}
</style>
