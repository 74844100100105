<template>
  <div>Loading ...</div>
</template>

<script lang="ts">
import { dispatchGameLogin, tokenValidationEndpoint } from '@/globalVariables'
import { saveTokenToLocalStorage, setIsMobileLocalStorage } from '@/helpers'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useGameStateStore } from '@/store/pinia/gameStateStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SetLogin',
  computed: {
    ...mapState(useResponseTaskStore, {
      isUserBlocked: 'isUserBlocked',
    }),
  },
  async created(): Promise<void> {
    const tokenId = this.$route.params.tokenId

    if (useGameStateStore().getGameState !== 'live') {
      this.setLoadingScreen(false)
      return
    }
    try {
      const res = await this.$axios.get<{}, { is_valid: boolean }>(
        tokenValidationEndpoint + tokenId,
      )
      const isFlutter = this.$route.params.isFlutter
      if (this.isUserBlocked) {
        setIsMobileLocalStorage(1, !!isFlutter)
        this.setLoadingScreen(false)
        return
      }
      if (res.is_valid) {
        localStorage.clear()
        saveTokenToLocalStorage(tokenId.toString())
        setIsMobileLocalStorage(1, !!isFlutter)
        this.$axios.post<{}, true>(dispatchGameLogin)

        const isMultiWebView = this.$route.params.isMultiWebView
        if (isMultiWebView) {
          localStorage.setItem('isMultiWebView', 'true')
        }
        this.resetTutorialState()
        this.setLoadingScreen(true)
      } else {
        localStorage.clear()
      }

      this.$router.push('/')
    } catch (error: unknown) {
      console.error(error)
    }
  },
  methods: {
    ...mapActions(useTutorialStore, {
      resetTutorialState: 'resetTutorialState',
    }),
    ...mapActions(useCoreStore, ['setLoadingScreen']),
  },
})
</script>
