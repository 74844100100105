<template>
  <header class="flex justify-between items-center equipment-item-box-header px-4">
    <p
      class="text-texts-standard-important text-40 font-bold uppercase"
      :class="[
        headerData.equipped && !headerData.expired
          ? 'text-texts-standard-dark'
          : 'text-texts-standard-important',
      ]"
    >
      {{ $t(`equipmentShop.${headerData.name}`) }}
    </p>
    <p v-if="headerData.equipped" class="text-32 text-texts-standard-important">
      {{ $t('equipmentShop.equipped') }}
    </p>
    <p v-if="headerData.owned" class="text-texts-standard-additional text-32">
      {{ $t('equipmentShop.owned') }}
    </p>
  </header>
</template>

<script lang="ts">
import type { HeaderData } from '@/interfaces/Equipment'
import type { Nullable } from '@/interfaces/utils'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'EquipmentItemBoxHeader',
  props: {
    headerData: {
      type: Object as PropType<Nullable<HeaderData>>,
      default: () => null,
    },
  },
})
</script>

<style lang="scss" scoped>
.equipment-item-box-header {
  height: 4.5rem;
}
</style>
