import type { AnimatedObjectWithPathsInterface } from '@/map-phaser-new/interfaces'
import { FRAMES_ANIMATION_TYPES } from '@/map-phaser-new/constants'
import {
  clubBallonAnimations,
  clubCarsLeftTopToRightTopAnimations,
  clubCarsMiddleToLeftAnimations,
  clubCarsRightToParkingAnimations,
  clubCarsRightTopToLeftTopAnimations,
  clubHelicopterRoundAnimations,
  clubBoatLeftToRightAnimations,
  clubCarsParkingToRightAnimations,
} from './animations'
import {
  clubCarsLeftTopToRightTopCheckpoints,
  clubCarsMiddleToLeftCheckpoints,
  clubCarsRightToParkingCheckpoints,
  clubCarsRightTopToLeftTopCheckpoints,
  clubHelicopterRoundCheckpoints,
  clubBoatLeftToRightCheckpoints,
  clubCarsParkingToRightCheckpoints,
} from './checkPointsData'
import {
  clubBallonRightPath,
  clubBallonTopMiddlePath,
  clubCarsLeftTopToRightTopPath,
  clubCarsMiddleToLeftPath,
  clubCarsRightToParkingPath,
  clubCarsRightTopToLeftTopPath,
  clubHelicopterRoundPath,
  clubBoatLeftToRightPath,
  clubCarsParkingToRightPath,
} from './pathsData'

export const animatedObjectsWithPathsConfig: AnimatedObjectWithPathsInterface[] = [
  {
    animationName: 'clubCarsRightTopToLeftTop',
    textureKey: 'animations',
    pathsData: clubCarsRightTopToLeftTopPath,
    animationsData: clubCarsRightTopToLeftTopAnimations,
    checkpointsData: clubCarsRightTopToLeftTopCheckpoints,
    variations: ['v01', 'v02', 'v03', 'v04', 'v05'],
    framePrefix: 'SSM_car_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 40000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'clubCarsRightTopToLeftTop1',
        startDelay: 0,
      },
      {
        name: 'clubCarsRightTopToLeftTop2',
        startDelay: 3000,
      },
      {
        name: 'clubCarsRightTopToLeftTop3',
        startDelay: 20000,
      },
      {
        name: 'clubCarsRightTopToLeftTop4',
        startDelay: 31000,
      },
    ],
  },
  {
    animationName: 'clubCarsLeftTopToRightTop',
    textureKey: 'animations',
    pathsData: clubCarsLeftTopToRightTopPath,
    animationsData: clubCarsLeftTopToRightTopAnimations,
    checkpointsData: clubCarsLeftTopToRightTopCheckpoints,
    variations: ['v01', 'v02', 'v03', 'v04', 'v05'],
    framePrefix: 'SSM_car_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '11',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 40000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'clubCarsLeftTopToRightTop1',
        startDelay: 0,
      },
      {
        name: 'clubCarsLeftTopToRightTop2',
        startDelay: 10000,
      },
      {
        name: 'clubCarsLeftTopToRightTop3',
        startDelay: 22000,
      },
      {
        name: 'clubCarsLeftTopToRightTop4',
        startDelay: 33000,
      },
    ],
  },
  {
    animationName: 'clubCarsMiddleToLeft',
    textureKey: 'animations',
    pathsData: clubCarsMiddleToLeftPath,
    animationsData: clubCarsMiddleToLeftAnimations,
    checkpointsData: clubCarsMiddleToLeftCheckpoints,
    variations: ['v01', 'v02', 'v03', 'v04', 'v05'],
    framePrefix: 'SSM_car_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '09',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 40000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'clubCarsMiddleToLeft1',
        startDelay: 0,
      },
      {
        name: 'clubCarsMiddleToLeft2',
        startDelay: 10000,
      },
      {
        name: 'clubCarsMiddleToLeft3',
        startDelay: 25000,
      },
    ],
  },
  {
    animationName: 'clubCarsRightToParking',
    textureKey: 'animations',
    pathsData: clubCarsRightToParkingPath,
    animationsData: clubCarsRightToParkingAnimations,
    checkpointsData: clubCarsRightToParkingCheckpoints,
    variations: ['v01', 'v02', 'v03', 'v04', 'v05'],
    framePrefix: 'SSM_car_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '12',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 30000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'clubCarsRightToParking1',
        startDelay: 0,
      },
      {
        name: 'clubCarsRightToParking2',
        startDelay: 13000,
      },
    ],
  },
  {
    animationName: 'clubCarsParkingToRight',
    textureKey: 'animations',
    pathsData: clubCarsParkingToRightPath,
    animationsData: clubCarsParkingToRightAnimations,
    checkpointsData: clubCarsParkingToRightCheckpoints,
    variations: ['v01', 'v02', 'v03', 'v04', 'v05'],
    framePrefix: 'SSM_car_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '04',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 30000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'clubCarsParkingToRight1',
        startDelay: 0,
      },
      {
        name: 'clubCarsParkingToRight2',
        startDelay: 13000,
      },
    ],
  },
  {
    animationName: 'clubBallonTopMiddle',
    textureKey: 'animations',
    animationsData: clubBallonAnimations,
    pathsData: clubBallonTopMiddlePath,
    variations: ['v01', 'v02'],
    framePrefix: 'SSM_balloon_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    playAnimationWithoutCheckpoints: {
      animationKeyPostfix: 'clubBalloonRotate',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: false,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: true,
    tweenBuilderConfig: {
      duration: 100000,
    },
    followersDepth: 11,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'clubBallonTopMiddle1',
        startDelay: 0,
        yoyoDelay: 20000,
      },
    ],
  },
  {
    animationName: 'clubBallonRight',
    textureKey: 'animations',
    pathsData: clubBallonRightPath,
    variations: ['v01'],
    framePrefix: 'SSM_balloon_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    playAnimationWithoutCheckpoints: {
      animationKeyPostfix: 'clubBalloonRotate',
    },
    turnOnRepeatListener: false,
    turnOnUpdateListener: false,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: true,
    tweenBuilderConfig: {
      duration: 20000,
    },
    followersDepth: 11,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'clubBallonRight1',
        startDelay: 0,
        yoyoDelay: 10000,
      },
    ],
  },
  {
    animationName: 'clubHelicopterRound',
    textureKey: 'animations',
    pathsData: clubHelicopterRoundPath,
    animationsData: clubHelicopterRoundAnimations,
    checkpointsData: clubHelicopterRoundCheckpoints,
    variations: ['v01'],
    framePrefix: 'SSM_helicopter_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 90000,
    },
    followersDepth: 11,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'clubHelicopterRound1',
        startDelay: 1000,
      },
    ],
  },
  {
    animationName: 'clubBoatLeftToRight',
    textureKey: 'animations',
    pathsData: clubBoatLeftToRightPath,
    animationsData: clubBoatLeftToRightAnimations,
    checkpointsData: clubBoatLeftToRightCheckpoints,
    variations: ['v01'],
    framePrefix: 'SSM_boat_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '01',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 150000,
    },
    followersDepth: 0,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'clubBoatLeftToRight1',
        startDelay: 0,
      },
    ],
  },
]
