<template>
  <header class="tasks-subheader w-full flex flex-row justify-center uppercase font-bold text-50">
    <div class="left flex flex-row justify-between px-12 relative">
      <p class="gradient-text-light z-10">{{ $te('eventTasks') }}</p>
      <p class="gradient-text-light z-10">
        {{ $te('tier') }}
        <span class="text-texts-standard-important">{{ currentTier }}</span>
      </p>
    </div>
    <div class="right flex flex-row justify-start ml-12 relative">
      <p class="gradient-text-light z-10 relative">
        {{ $replacePlaceholder($te('taskNumber'), '%s', currentTask.toString()) }}
        <span v-if="isBoss" class="boss-text absolute" />
      </p>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TasksSubHeader',
  props: {
    currentTask: {
      type: Number,
      default: null,
    },
    currentTier: {
      type: Number,
      default: null,
    },
    isBoss: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.tasks-subheader {
  .left,
  .right {
    &::before {
      content: '';
      position: absolute;
      left: 1.25rem;
      top: 1rem;
      @include background(null, contain);
    }
  }

  .left {
    width: 53.625rem;

    &::before {
      width: 46.188rem;
      height: 3.125rem;
      background-image: url('#{$path-events}shared/taskchain/tasks/tasks-title-left-bg.avif');
    }
  }

  .right {
    width: 65.375rem;

    &::before {
      width: 30.688rem;
      height: 3.125rem;
      background-image: url('#{$path-events}shared/taskchain/tasks/tasks-title-right-bg.avif');
    }
  }

  .boss-text {
    top: 0.25rem;
    right: -5.5rem;
    width: 4.938rem;
    height: 1.875rem;
    @include background(url('#{$path-events}shared/taskchain/tasks/tasks-text-boss.avif'), contain);
  }
}
</style>
