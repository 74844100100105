<template>
  <section class="progress-text flexing text-50 font-bold gradient-text-gold">
    <p class="mr-1">{{ spinPoints }}x</p>
    <app-main-icon icon-name="event_points" :icon-size="56" />
    <p>=</p>
    <p class="ml-4">1x</p>
    <div class="icon-events-EventsLuckyWheel-light"></div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LuckyWheelProgressText',
  props: {
    spinPoints: {
      type: Number,
      default: 20,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/events-icons.scss';

.progress-text {
  margin-bottom: 1.525rem;
}
</style>
