<template>
  <div
    v-if="Object.keys(newsDetail).length"
    id="news-message"
    class="app-page-wrapper news-message absolute"
  >
    <section class="w-full h-full safe-area">
      <header>
        <div class="news-message-header">
          <h2 class="text-texts-standard-default text-50 uppercase font-bold">
            {{ newsDetail.title }}
          </h2>
        </div>
      </header>
      <main>
        <news-row :btn="false" :news-data="newsDetail" />
        <div class="news-message-content">
          <app-scrollbar width="98%" height="95%" scroll="y" slice="y">
            <div class="news-message-content-textarea">
              <p class="text-texts-standard-additional text-28" v-html="newsDetail.text" />
            </div>
          </app-scrollbar>
        </div>
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import NewsRow from '@/components/News/NewsRow.vue'
import { playSound } from '@/helpers'
import { useNewsStore } from '@/store/pinia/newsStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NewsMessage',
  components: {
    NewsRow,
  },
  computed: {
    ...mapState(useNewsStore, {
      newsDetail: 'getNewsDetail',
      getAllNews: 'getAllNews',
    }),
  },
  created(): void {
    this.loadNewsData()
    playSound('click-news-item-open')
  },
  methods: {
    ...mapActions(useNewsStore, ['markAsRead', 'loadNewsDetail', 'setHideNewsOnMap']),
    async loadNewsData(): Promise<void> {
      if (!Object.keys(this.newsDetail).length && this.$route.params?.news_id) {
        await this.loadNewsDetail(Number(this.$route.params.news_id))
      }

      if (this.newsDetail?.id && this.newsDetail?.unread) {
        this.markAsRead(this.newsDetail.id)

        if (this.getAllNews[0].id === this.newsDetail.id) {
          this.setHideNewsOnMap(true)
        }
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.news-message {
  user-select: text;

  header {
    background: #0a1c35;
    height: 12.25rem;
  }

  &-header {
    margin: 0 auto;
    width: 70rem;
    height: 4.875rem;
    position: relative;
    top: 6.25rem;
    @if $isWsm {
      background: linear-gradient(to right, transparent 0, #144a73, transparent 100%);
    }
    @if $isSsm {
      background: linear-gradient(
        to right,
        transparent 0,
        rgba(86, 98, 127, 0.96) 50%,
        transparent 100%
      );
    }
  }

  &-content {
    width: 116rem;
    height: 39.688rem;
    margin: 0 auto;
    @if $isWsm {
      border: solid 0.063rem #5883a3;
      background-color: #0d2037;
    }
    @if $isSsm {
      border: solid 0.063rem #636790;
      background-color: #172341;
    }

    &-textarea {
      width: 100%;
      height: 100%;
      padding: 1.875rem 1.25rem;
      text-align: left;
    }
  }
}
</style>
