<template>
  <div class="equipment-d app-page-wrapper absolute" :class="{ premium: equipmentData?.premium }">
    <section v-if="equipmentData?.premium" class="premium-eq" />
    <menu-component
      menu-type="arena"
      :title="$t('equipmentShop.detailHeader')"
      right-box-icon="research_points"
      :right-box-value="researchPoints.value"
      :is-tooltip="true"
    />
    <section class="equipment-detail__wrapper w-full h-full safe-area z-1">
      <main
        v-if="!isLoading"
        class="equipment-detail flex flex-col"
        :class="$isMobile() && 'justify-center items-center'"
      >
        <equipment-detail-header
          :discipline-id="disciplineId"
          :text="currentLevel.quality"
          :equipment-name="equipmentData.name"
          :bonuses="bonuses"
          :upgrades="upgrades"
          :current-level="currentLevel"
          :premium="
            equipmentData.premium
              ? equipmentData.isEventEquip && equipmentData.prolong?.price
                ? 'event'
                : 'discipline'
              : ''
          "
          :time-limit="equipmentData.prolong?.availableTo"
        />
        <section class="equipment-center flex">
          <equipment-detail-box
            :current-level="currentLevel"
            :discipline-id="disciplineId"
            :is-offer="offer"
            :equipment-data="equipmentData"
            :ongoing-operation="ongoingOperation"
            :item-id="shopItem?.id"
            @set-operation="setOperation"
            @load-item-detail="checkProps"
          />
          <equipment-detail-item
            :equipment-data="currentLevel"
            :is-offer="offer"
            :item-category="itemCategory"
            :discipline-id="disciplineId"
            :ongoing-operation="ongoingOperation"
            :upgrades="upgrades"
            :item-id="shopItem?.id"
            :premium="equipmentData.premium ?? null"
            @set-operation="setOperation"
            @load-item-detail="loadItemDetail"
          />
        </section>
        <equipment-detail-footer
          v-if="!isLoading"
          :is-offer="offer"
          :item-id="itemId"
          :current-level="currentLevel"
          :next-level="nextLevel"
          :equipment-data="equipmentData"
          :ongoing-operation="ongoingOperation"
          :equipped-item="equippedItem"
          :discipline-id="disciplineId"
          @load-item-detail="loadItemDetail"
          @set-operation="setOperation"
          @set-item-id="setItemId"
          @set-equipped="equipmentData.equipped = true"
        />
      </main>

      <component-loading :height="'82%'" :is-loading="isLoading" />
    </section>
  </div>
</template>

<script lang="ts">
import EquipmentDetailBox from '@/components/Equipment/EquipmentDetailBox.vue'
import EquipmentDetailFooter from '@/components/Equipment/EquipmentDetailFooter.vue'
import EquipmentDetailHeader from '@/components/Equipment/EquipmentDetailHeader.vue'
import EquipmentDetailItem from '@/components/Equipment/EquipmentDetailItem.vue'
import {
  CONFIRM_SPEEDUP_BUYING_EQUIPMENT,
  DURABILITY,
  EQUIPMENT_ATTRIBUTE_BONUS,
  EQUIPMENT_CASH_BONUS,
  EQUIPMENT_DURABILITY,
  EQUIPMENT_TP_BONUS,
  EQUIPPED,
  GDD_ID,
  LEVEL,
  OPERATION_DELIVERY,
  OPERATION_END,
  OPERATION_REPAIR,
  OPERATION_TYPE,
  OPERATION_UPGRADE,
  SPEEDUP_PRICE,
  shopOperationCheckEndPoint,
} from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { convertNameValue, formatLevel, formatUpgrades } from '@/helpers'
import { useEquipmentStore } from '@/store/pinia/equipment/equipmentStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import type {
  EquipmentOfferData,
  EquipmentItemDataByDisciplineId,
  EquipmentItemData,
} from '@/store/pinia/equipment/equipmentStore'
import type {
  Bonuses,
  FormattedEquipmentData,
  FormattedEquippedItem,
  FormattedLevel,
  FormattedLevel_Equipment,
  OngoingOperation,
  Upgrades,
} from '@/interfaces/Equipment'
import type { Nullable } from '@/interfaces/utils'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { NavigationGuard } from 'vue-router'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { SHOP } from '@/map-phaser-new/constants/common'
import type { OperationRequest } from '@/types/phaserStoreTypes'

export const BONUS_TRANSLATION_MAPPING = {
  [EQUIPMENT_DURABILITY]: 'equipmentShop.durability',
  [EQUIPMENT_CASH_BONUS]: 'equipmentShop.moneyBonus',
  [EQUIPMENT_ATTRIBUTE_BONUS]: 'equipmentShop.attributeBonus',
  [EQUIPMENT_TP_BONUS]: 'equipmentShop.trainingBonus',
}

const OPERATION_MAPPING = {
  [OPERATION_REPAIR]: 'equipmentShop.repairing',
  [OPERATION_UPGRADE]: 'equipmentShop.upgrading',
  [OPERATION_DELIVERY]: 'equipmentShop.delivering',
}

interface LockedPremiumCurrentLevel extends Omit<FormattedLevel, 'parameters'> {
  parameters: ReturnType<typeof convertNameValue>
}

interface ComponentData {
  isLoading: boolean
  disciplineId: Nullable<number>
  itemCategory: Nullable<number>
  itemId: number
  offer: boolean
  currentLevel: Nullable<FormattedLevel>
  nextLevel: Nullable<FormattedLevel>
  equipmentData: Nullable<FormattedEquipmentData>
  ongoingOperation: Nullable<OngoingOperation>
  upgrades: Upgrades
  bonuses: Nullable<Bonuses>
  equippedItem: Nullable<FormattedEquippedItem>
}

export default defineComponent({
  name: 'EquipmentDetail',
  components: {
    EquipmentDetailFooter,
    EquipmentDetailBox,
    EquipmentDetailHeader,
    EquipmentDetailItem,
  },
  beforeRouteLeave(): ReturnType<NavigationGuard> {
    return this.actualStage?.name !== CONFIRM_SPEEDUP_BUYING_EQUIPMENT
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    isOffer: {
      type: String,
      default: '',
    },
  },
  data(): ComponentData {
    return {
      isLoading: false,
      disciplineId: null,
      itemCategory: null,
      itemId: Number(this.id),
      offer: Boolean(Number(this.isOffer)),
      currentLevel: null,
      nextLevel: null,
      equipmentData: null,
      ongoingOperation: null,
      upgrades: [],
      bonuses: null,
      equippedItem: null,
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      actualStage: 'getActualStage',
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useEquipmentStore, {
      equipmentOffer: 'getSpecificEquipmentOffer',
      shopItem: 'getShopItem',
      equipmentItem: 'getSpecificEquipmentItem',
    }),
    ...mapState(useResponseTaskStore, {
      researchPoints: 'getResearchPoints',
    }),
  },
  async created(): Promise<void> {
    this.checkProps()
    await this.loadNotifications()
  },
  methods: {
    ...mapActions(useEquipmentStore, {
      loadSpecificEquipmentOffer: 'loadSpecificEquipmentOffer',
      loadShopItem: 'loadShopItem',
      loadEquipmentByDisciplineId: 'loadEquipmentByDisciplineId',
    }),
    ...mapActions(useResponseTaskStore, {
      loadNotifications: 'loadNotifications',
    }),
    ...mapActions(usePhaserGameIntegrationStore, {
      manageProgressTimerVisibility: 'manageProgressTimerVisibility',
    }),
    setItemId(): void {
      this.itemId = this.equipmentData.id
      this.offer = false
    },
    async checkProps(): Promise<void> {
      if (!this.id) {
        this.itemId = this.$route.params.id ? Number(this.$route.params.id) : null
      }
      if (!this.isOffer) {
        this.offer = this.$route.query.offer ? Boolean(Number(this.$route.query.offer)) : null
      }
      if ((this.offer === null || this.itemId === null) && this.actualStage === null) {
        this.$router.go(-1) // go to previous page
        return
      }
      await this.loadItemDetail()
    },
    findCategory(
      data:
        | EquipmentOfferData['items'][0]['properties']
        | EquipmentItemDataByDisciplineId['properties'],
    ): number {
      return Number(
        data.find(
          (
            prop: (
              | EquipmentOfferData['items'][0]['properties']
              | EquipmentItemDataByDisciplineId['properties']
            )[0],
          ): boolean => prop.name === GDD_ID,
        ).value,
      )
    },
    setOperation(
      itemId: OngoingOperation['itemId'],
      time: OngoingOperation['time'],
      type: OngoingOperation['type'],
      price: OngoingOperation['price'],
      itemName: OngoingOperation['itemName'],
    ): void {
      let parentId: number = -1
      if (itemId !== this.equipmentData.id) {
        parentId = this.upgrades.some((upgrade: Upgrades[0]): boolean => upgrade?.id === itemId)
          ? this.equipmentData.id
          : parentId
      }

      this.ongoingOperation = {
        itemId,
        time,
        type,
        price,
        itemName: itemName ?? '',
        parentId,
      }

      if (!this.isTutorial) {
        const timerStart = Date.now()
        const timerEnd = timerStart + time

        this.manageProgressTimerVisibility({
          buildingName: SHOP,
          heading: this.$t(OPERATION_MAPPING[type]),
          timerStart: timerStart,
          timerEnd: timerEnd,
        })
      }
    },
    async loadItemDetail(): Promise<void> {
      this.ongoingOperation = null
      this.isLoading = true

      // LOAD OFFER IF YOU DIDNT BUY ITEM YET
      if (this.offer) {
        await this.loadSpecificEquipmentOffer(this.itemId)
        this.itemCategory = this.findCategory(this.equipmentOffer.items[0].properties)
        this.disciplineId = this.equipmentOffer.disciplineId
        this.setOffer(this.equipmentOffer.items[0])
        this.isLoading = false

        return
      }

      // LOAD BOUGHT/OPERATED ITEM

      // MUST HAVE: After operation timer expires and EP is called, operation is processed on BE and states are reflected
      await internalAxios.get<{}, OperationRequest>(shopOperationCheckEndPoint)

      // BE PROBLEM: it isn't queue job
      // in this call, when the operation (*repair) is finished, the correct data is returned
      // however, without previous call to shopOperationCheckEndPoint, we would have old/bad data
      await this.loadShopItem(this.itemId)

      this.itemCategory = this.findCategory(this.shopItem.properties)
      this.setItem(this.shopItem)

      if (!this.equipmentData.equipped) {
        await this.loadEquipmentByDisciplineId(this.disciplineId)

        const equippedItem = this.equipmentItem.find(
          (item: EquipmentItemData): boolean => item.itemState.name === EQUIPPED,
        )
        if (equippedItem) {
          this.setEquippedItem(equippedItem)
        }
      }

      this.isLoading = false
    },
    calculateBonuses(): void {
      this.bonuses = this.upgrades.reduce(
        (output: Bonuses, upgrade: Upgrades[0]): Bonuses => {
          if (upgrade?.currentLevel) {
            output[upgrade.type] += upgrade.currentLevel.upgrade.bonus
          }
          return output
        },
        {
          [EQUIPMENT_TP_BONUS]: 0,
          [EQUIPMENT_CASH_BONUS]: 0,
          [EQUIPMENT_ATTRIBUTE_BONUS]: 0,
          [EQUIPMENT_DURABILITY]: 0,
        },
      )

      const setParameters = (level: FormattedLevel, bonuses: Bonuses): void => {
        for (const parameter in level.parameters) {
          level.parameters[parameter].bonusValue = bonuses[parameter]
          level.parameters[parameter].value += bonuses[parameter]
        }
      }

      if (this.currentLevel) {
        setParameters(this.currentLevel, this.bonuses)
      }

      if (this.nextLevel) {
        setParameters(this.nextLevel, this.bonuses)
      }
    },
    formatOperation(operation: EquipmentItemDataByDisciplineId['operation']): void {
      if (!operation) return

      this.setOperation(
        operation.item ? operation.item.id : operation.offer.items[0].id,
        Number(
          operation.stats.find(
            (stat: EquipmentItemDataByDisciplineId['operation']['stats'][0]): boolean =>
              stat.name === OPERATION_END,
          ).value,
        ) * 1000,
        String(
          operation.stats.find(
            (stat: EquipmentItemDataByDisciplineId['operation']['stats'][0]): boolean =>
              stat.name === OPERATION_TYPE,
          ).value,
        ),
        Number(
          operation.stats.find(
            (stat: EquipmentItemDataByDisciplineId['operation']['stats'][0]): boolean =>
              stat.name === SPEEDUP_PRICE,
          ).value,
        ),
        operation.item ? operation.item.name : operation.offer.items[0]?.name,
      )
    },
    formatEquipmentData(data: EquipmentItemDataByDisciplineId): FormattedEquipmentData {
      return {
        id: data.id,
        name: data.name,
        damaged: this.currentLevel.parameters[EQUIPMENT_DURABILITY].currentValue === 0,
        equipped: data.itemState?.name === EQUIPPED,
        levels: data.levels,
        equipmentLevelsAvailable: data.equipmentLevelsAvailable,
        premium: data.premium,
        isEventEquip: data.isEventEquip && data.prolong?.price,
        bonusData: data.bonusData,
        equipmentBonusData: data.equipmentBonusData,
        prolong: data.prolong ?? null,
      }
    },
    setItem(item: EquipmentItemDataByDisciplineId): void {
      this.disciplineId = item.disciplineId
      const searchedLevel = Number(
        item.itemStats?.find(
          (stat: EquipmentItemDataByDisciplineId['itemStats'][0]): boolean => stat.name === LEVEL,
        ).value ?? 1,
      )

      this.currentLevel = formatLevel(
        item.levels.find(
          (itemLevel: EquipmentItemDataByDisciplineId['levels'][0]): boolean =>
            itemLevel.level === searchedLevel,
        ),
        true,
      )

      this.currentLevel.parameters[EQUIPMENT_DURABILITY].currentValue = Number(
        item.itemStats?.find(
          (stat: EquipmentItemDataByDisciplineId['itemStats'][0]): boolean =>
            stat.name === DURABILITY,
        ).value,
      )

      const next = item.levels.find(
        (itemLevel: EquipmentItemDataByDisciplineId['levels'][0]): boolean =>
          itemLevel.level === this.currentLevel.level + 1,
      )
      this.nextLevel = formatLevel(next, true)

      this.equipmentData = this.formatEquipmentData(item)
      if (item.hookedItems) {
        this.upgrades = formatUpgrades(item.hookedItems)
      }
      this.calculateBonuses()
      this.currentLevel.premium = item.premium
      if (this.currentLevel.premium?.locked) {
        ;(this.currentLevel as unknown as LockedPremiumCurrentLevel).parameters = convertNameValue(
          item.properties,
        )
      }
      this.formatOperation(item.operation)
    },
    setEquippedItem(equippedItem: EquipmentItemData): void {
      const reduced = convertNameValue(equippedItem.levelParameters)
      const params = {} as Record<
        keyof FormattedLevel_Equipment['parameters'],
        Pick<
          FormattedLevel_Equipment['parameters'][keyof FormattedLevel_Equipment['parameters']],
          'currentValue' | 'value' | 'suffix'
        >
      >
      Object.entries(this.currentLevel.parameters).forEach(
        ([key, current]: [
          keyof FormattedLevel_Equipment['parameters'],
          FormattedLevel_Equipment['parameters'][keyof FormattedLevel_Equipment['parameters']],
        ]): void => {
          params[key] = {
            currentValue:
              key === EQUIPMENT_DURABILITY
                ? Number(
                    equippedItem.itemStats.find(
                      (stat: EquipmentItemData['itemStats'][0]): boolean =>
                        stat.name === DURABILITY,
                    ).value,
                  )
                : 0,
            value: key in reduced ? Number(reduced[key]) : 0,
            suffix: current.suffix,
          }
        },
      )

      this.equippedItem = {
        level: Number(
          equippedItem.itemStats.find(
            (stat: EquipmentItemData['itemStats'][0]): boolean => stat.name === LEVEL,
          ).value,
        ),
        parameters: params,
      }
    },
    setOffer(offer: EquipmentOfferData['items'][0]): void {
      this.currentLevel = formatLevel(offer.levels[0], true)
      this.currentLevel.parameters[EQUIPMENT_DURABILITY].currentValue = Number(
        this.currentLevel.parameters[EQUIPMENT_DURABILITY].value,
      )
      this.equipmentData = this.formatEquipmentData(offer as EquipmentItemDataByDisciplineId)
      this.upgrades = formatUpgrades(offer.hookedItems)
      if (!this.equipmentData.damaged) {
        this.calculateBonuses()
      }
    },
  },
})
</script>
<style lang="scss" scoped>
.equipment-d {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(14, 38, 55, 0.6) 0%,
      transparent 40%,
      transparent 80%,
      rgba(0, 0, 0, 0.45) 100%
    );
  }

  & > * {
    position: relative;
    z-index: 1;
  }

  &.premium {
    background: url($path-equipment + 'bg-premium-equip.avif') center no-repeat;
    background-size: cover;
  }

  .premium-eq {
    background: url($path-equipment + 'premium-equip-detail-effect.avif') center no-repeat;
    height: 67.5rem;
    width: 108.125rem;
    background-size: contain;
    z-index: 0;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-27%);
  }
}

.equipment-detail {
  width: 100%;
  height: 79.5%;
  padding: 1.438rem 2.438rem 0 2.875rem;
}
</style>
