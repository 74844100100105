<template>
  <table-tools
    v-if="showTools"
    :show-tools="showTools"
    :filter-data="filterData"
    :checkbox-checked="checkboxChecked"
    @is-check="$emit('isCheck', $event)"
    @delete-applications="$emit('deleteApplications', $event)"
    @search-event="$emit('searchEvent', $event)"
    @apply-filter="$emit('applyFilter', $event)"
    @reset-filter="$emit('resetFilter', $event)"
  />
  <table
    v-if="!isContentLoading"
    class="table-component table-auto w-full"
    :style="{ borderSpacing }"
  >
    <div class="table-component-wrapper relative">
      <app-scrollbar
        width="100%"
        :height="
          forceScrollSize
            ? forceScrollSize
            : $isMobile()
              ? scrollSize.mobileY + 'rem'
              : scrollSize.webY + 'rem'
        "
        scroll="y"
        slide="y"
      >
        <div class="table-component-container">
          <thead
            v-if="!isDataEmpty"
            class="table-component-header text-28"
            :class="{
              'sticky top-0 z-1': $isSsm,
            }"
          >
            <tr>
              <th
                v-for="(column, index) in columns"
                :key="index"
                class="h-full"
                :style="{
                  textAlign: column.headerAlign ?? column.align ?? 'center',
                  color: column.color,
                  width: `${column.width}%`,
                  minWidth: `${column.minWidth}%`,
                  paddingLeft: column.paddings?.padL + 'rem',
                  paddingRight: column.paddings?.padR + 'rem',
                }"
              >
                <div
                  class="cell-container w-full h-full"
                  :style="{
                    justifyContent: getFlexContentPosition(column),
                  }"
                >
                  <div
                    class="flex items-center"
                    :style="{ justifyContent: column.headerAlign ?? column.align ?? 'center' }"
                  >
                    <div v-if="column.isIcon" class="flexing">
                      <app-main-icon
                        v-if="
                          column.label === 'prestige' ||
                          column.label === 'ranking_points' ||
                          column.label === CHAMPIONSHIP_POINTS ||
                          column.label === TOURNAMENT_TITLES
                        "
                        class="table-component-header-icon"
                        :icon-name="column.label"
                        :icon-size="32"
                      />
                      <app-icon
                        v-else
                        class="table-component-header-icon"
                        :icon-size="32"
                        :icon-name="column.label"
                      />
                    </div>
                    <div v-if="column.onlineStatus">
                      <div class="online-status is-online" />
                    </div>
                    <sort-header
                      v-else-if="column.label && !column.disableSort"
                      ref="sortHeaders"
                      :highlighted="clickedColumn === column.key"
                      :label="column.isIcon ? '' : column.label"
                      @sort-event="emitClickSort($event, column.key)"
                    />
                    <div v-else>{{ column.label }}</div>
                    <app-icon
                      v-if="column.tooltip != null"
                      v-tippy="{
                        theme: 'WSM',
                        content: column.tooltip,
                        placement: 'bottom',
                        textAlign: 'center',
                      }"
                      class="ml-4"
                      icon-name="info-50"
                    />
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody
            class="table-component-body text-32"
            :class="{ 'logged-user-styles': showLoggedUser }"
          >
            <tr v-for="(item, index) in items" :id="`table-component-row-${index}`" :key="index">
              <table-cell
                v-for="(column, indexColumn) in columns"
                :key="indexColumn"
                :item="item"
                :column="column"
                :which-id="whichId"
                @button-click="emitButtonClick($event)"
                @delete-cell="emitDeleteCell($event, index)"
                @member-settings="$emit('memberSettings', $event)"
              />
            </tr>
            <tr
              v-if="showLoggedUser && loggedUser"
              id="table-component-row-last"
              class="logged-user-row"
            >
              <table-cell
                v-for="(column, indexColumn) in columns"
                :key="indexColumn"
                :item="loggedUser"
                :column="column"
              />
            </tr>
          </tbody>
        </div>
      </app-scrollbar>
    </div>
  </table>

  <div v-if="isDataEmpty" class="flexing empty-table-text">
    <p class="text-texts-standard-default text-40">
      {{ noDataText }}
    </p>
  </div>

  <component-loading :is-loading="isContentLoading" width="100%" height="100%" />
</template>

<script lang="ts">
import { CHAMPIONSHIP_POINTS, TOURNAMENT_TITLES } from '@/globalVariables'
import { defineComponent } from 'vue'
import SortHeader from './SortHeader.vue'
import TableCell from './TableCell.vue'
import TableTools from './TableTools.vue'

export default defineComponent({
  name: 'TableComponent',
  components: {
    TableCell,
    SortHeader,
    TableTools,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    noDataText: {
      type: String,
      default: '',
    },
    showTools: {
      type: Object,
      default: () => {},
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    scrollSize: {
      type: Object,
      default() {
        return {
          mobileY: 55,
          webY: 46,
        }
      },
    },
    forceScrollSize: {
      type: String,
      default: null,
    },
    filterData: {
      type: Array,
      default: () => [],
    },
    checkboxChecked: {
      type: Boolean,
      default: false,
    },
    whichId: {
      type: String,
      default: 'id',
    },
    borderSpacing: {
      type: String,
      default: '0',
    },
    showLoggedUser: {
      type: Boolean,
      default: false,
    },
    loggedUser: {
      type: Object,
      default: () => {},
    },
  },
  emits: [
    'buttonClick',
    'isCheck',
    'deleteApplications',
    'deleteCell',
    'memberSettings',
    'searchEvent',
    'sortEvent',
    'applyFilter',
    'resetFilter',
  ],
  data() {
    return {
      CHAMPIONSHIP_POINTS,
      TOURNAMENT_TITLES,
      maxRows: 20,
      clickedColumn: '',
    }
  },
  computed: {
    isDataEmpty(): boolean {
      return this.items?.length === 0
    },
    isDataPaginated(): boolean {
      return this.pagination && this.items?.length > this.maxRows
    },
    isContentLoading(): boolean {
      return this.items == null
    },
  },

  methods: {
    emitButtonClick(e) {
      this.$emit('buttonClick', e)
    },
    emitDeleteCell(e, index) {
      this.$emit('deleteCell', { e, index })
    },
    emitClickSort(e, key) {
      this.clickedColumn = key
      this.$emit('sortEvent', { orderType: e, key: key })
    },
    getFlexContentPosition(column): string {
      // flex-start, flex-end, center
      if (column.align === 'left') return 'flex-start'
      if (column.align === 'right') return 'flex-end'
      return 'center'
    },
  },
})
</script>

<style lang="scss" scoped>
.table-component {
  display: flex; // must have for dynamic height
  color: #ffffff;
  border-collapse: separate !important;
  width: 100%;
  margin: 0 auto;
  flex: 1 1 auto;

  &-wrapper {
    width: 100%;
    display: table;
  }

  // very important for full width spreadsheet
  &-container {
    display: table;
    width: 100%;
  }

  &-header {
    tr {
      th {
        height: 3.688rem; // (i) Zeplin: 2.938rem + border-bottom 0.75rem
        padding: 0;
        border-bottom: 0.75rem solid transparent;
        font-weight: normal;

        .cell-container {
          display: flex;
          align-items: center;
          padding-left: 0;
          padding-right: 0;

          @if $isWsm {
            color: #d9ecff;
            background-color: #09172a;
            font-style: italic;
          }

          @if $isSsm {
            background-color: #292d30;
          }
        }
      }
    }

    &-icon {
      margin: 0 auto !important;
    }

    .online-status {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      margin: 0 1rem;
      bottom: 0;
      right: 0;

      &.is-online {
        border: solid 0.125rem #0ac506;
        background-image: radial-gradient(circle at 0 0, #3fe443, #18c110);
        box-shadow:
          0 0 13.5px 1.5px rgb(63 228 67 / 30%),
          inset 0px 5px 5px 0 rgb(63 228 67 / 50%);
      }

      &.is-recently {
        border: solid 0.125rem #ffa200;
        background-image: radial-gradient(circle at 0 0, #eb9600, #fdb333);
        box-shadow:
          0 0 13.5px 1.5px rgba(255, 162, 0, 0.3),
          inset 0px 5px 5px 0 rgba(255, 162, 0, 0.5);
      }

      &.is-offline {
        border: solid 0.125rem #4f4f4f;
        background-image: radial-gradient(circle at 0 0, #505050, #515151);
        box-shadow:
          0 0 13.5px 1.5px rgba(102, 102, 102, 0.3),
          inset 0px 5px 5px 0 rgba(53, 53, 53, 0.5);
      }
    }
  }

  &-body {
    tr {
      &:nth-child(even) {
        td {
          :deep() {
            .cell-container {
              background-color: theme('backgroundColor.table-even');
            }
          }
        }
      }

      td {
        height: 6.375rem; // (i) Zeplin: 5.625rem + border-bottom 0.75rem
        padding: 0;
        border-bottom: 0.75rem solid transparent;

        :deep() {
          .cell-container {
            padding-left: 0;
            padding-right: 0;
            background-color: theme('backgroundColor.table-odd');

            @if $isWsm {
              border-bottom: 1px solid #5883a3;
              border-top: 1px solid #5883a3;
            }

            @if $isSsm {
              border-bottom: 1px solid #7477a4;
              border-top: 1px solid #7477a4;
            }
          }
        }
      }
    }

    &-pagination {
      color: #ffffff;
    }
  }

  .logged-user-row {
    bottom: -4.7rem;
    width: 100%;
    position: sticky;
    bottom: 0;
    opacity: 1;

    :deep() {
      td {
        border-bottom: none;

        .cell-container {
          position: relative;
          background: transparent !important;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;

            @if $isWsm {
              background-color: #15856e !important;
            }

            @if $isSsm {
              background-color: #cd4a8b !important;
            }
          }
        }
      }
    }
  }
}

.empty-table-text {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
