<template>
  <main
    v-if="masterPass?.length"
    class="master-pass flexing flex-col flex-grow w-full relative"
    :data-event-theme="namespace"
  >
    <div
      v-if="!$route.meta.isPremiumRoute"
      class="master-pass-rewards-bottom-glow absolute"
      :class="{ 'is-mobile': $isMobile() }"
    />
    <div
      v-if="!$route.meta.isPremiumRoute"
      class="master-pass-rewards absolute"
      :class="{ 'is-mobile': $isMobile() }"
    />
    <div class="master-pass-text" :class="{ 'mt-2': !$route.meta.isPremiumRoute }">
      <p
        class="font-bold uppercase mx-auto"
        :class="$route.meta.isPremiumRoute ? 'text-32' : 'text-36'"
      >
        {{ $replacePlaceholder($te('masterPass.perksTitle'), '{pass}', $te('masterpass')) }}<br />
        - {{ $te('masterPass.perk1Text') }}<br />
        -
        {{
          $replacePlaceholder(
            $te('masterPass.perk2Text'),
            '{percent}',
            `${reward.value}${getResourceUnit(reward.name, true)}`,
          )
        }}
      </p>
    </div>
    <footer class="flexing w-full" :class="[$route.meta.isPremiumRoute ? 'mb-6' : 'mb-8']">
      <buy-pass-button
        :item-id="itemId"
        :price="price"
        :currency="currency"
        :purchased="isPassActivated"
      />
    </footer>
  </main>
</template>

<script lang="ts">
import { getResourceUnit } from '@/helpers'
import type PremiumMasterPassApiResponse from '@/interfaces/responses/premium/PremiumMasterPassApiResponse'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import BuyPassButton from '../BuyPassButton.vue'

export default defineComponent({
  name: 'MasterPassMain',
  components: { BuyPassButton },
  computed: {
    ...mapState(usePremiumStore, {
      masterPass: 'getMasterPass',
    }),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
    isPassActivated(): boolean {
      return this.masterPass?.[0]?.remaining_buys === 0
    },
    itemId(): PremiumMasterPassApiResponse['packs'][0]['store_id'] {
      return this.masterPass?.[0]?.store_id
    },
    price(): PremiumMasterPassApiResponse['packs'][0]['price']['price'] {
      return this.masterPass?.[0]?.price?.price
    },
    currency(): PremiumMasterPassApiResponse['packs'][0]['price']['currency'] {
      return this.masterPass?.[0]?.price?.currency
    },
    reward(): PremiumMasterPassApiResponse['packs'][0]['offer_content'][0] {
      return this.masterPass?.[0]?.offer_content?.find(
        (reward: PremiumMasterPassApiResponse['packs'][0]['offer_content'][0]): boolean =>
          reward.name === 'reputation_bonus',
      )
    },
  },
  methods: {
    getResourceUnit,
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='discipline'] {
  --events-passes-master-pass-rewards-bg: url('#{$path-events}discipline-event/passes/master-pass-img.webp');
  --events-passes-master-pass-bottom-glow-bg: url('#{$path-events}discipline-event/passes/master-pass-bottom-glow-bg.webp?v=1');
}

.master-pass {
  justify-content: space-between;
  position: relative;

  &-rewards {
    position: absolute;
    top: 52.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 87.5rem;
    height: 31.25rem;
    @include background(var(--events-passes-master-pass-rewards-bg));

    &.is-mobile {
      bottom: 55%;
    }

    &-bottom-glow {
      position: absolute;
      bottom: 0%;
      left: 50%;
      transform: translateX(-50%);
      width: 93.5rem;
      height: 40rem;
      @include background(var(--events-passes-master-pass-bottom-glow-bg));

      &.is-mobile {
        bottom: -2.5%;
      }
    }
  }

  &-text {
    width: 85%;
    color: #ffedbd;
  }
}
</style>
