<template>
  <info-popup
    v-if="isVisible"
    :popup-title="isProductionPopup ? $te('partProduction') : $te('partMelting')"
    width="76.125rem"
    @close="$emit('close')"
  >
    <div
      class="container-upgrade text-36 text-texts-standard-default italic convertor-popup-wrapper"
    >
      <div class="main-content">
        <p v-if="isProductionPopup">
          {{ $te('partProductionConfirm') }}
        </p>
        <p v-else>
          {{ $te('partMeltingConfirm') }}
          <br />
          {{ $te('partMeltingInfo') }}
        </p>

        <convertor-side-exchange-visualization
          class="convertor-visualization"
          size="lg"
          :input-ingredient="inputIngredient"
          :output-ingredient="outputIngredient"
          :input-value="inputValue"
          :output-value="outputValue"
        />

        <div v-if="isProductionPopup" class="flexing mt-12 mb-4 pl-2">
          <p class="mr-8">{{ $te('productionTime') }}:</p>
          <app-timer
            :time="isTier3 ? 0 : productionTime"
            :no-countdown="true"
            :timer-end-text="isTier3 ? $te('instant') : ''"
          />
        </div>
      </div>

      <div v-if="isProductionTimeLongerThanEvent && isProductionPopup" class="mt-8">
        <p class="text-texts-standard-important">
          {{ eventEndText }}
        </p>
      </div>

      <div class="no-more-confirmation flexing">
        <app-check-box
          :is-checked="isCheckboxChecked"
          class="mr-8"
          @checked-action="isCheckboxChecked = !isCheckboxChecked"
        />
        <p class="text-32">{{ $t('common.noConfirmWindow') }}</p>
      </div>

      <app-button
        btn-type="confirm"
        :btn-text="isProductionPopup ? $te('produceButton') : $te('meltButton')"
        :disabled="disabled"
        :loading="disabled"
        class="m-auto"
        @click="clickOnButton"
      />
    </div>
  </info-popup>
</template>

<script lang="ts">
import ConvertorSideExchangeVisualization from '@/components/Events/EventsWarehouse/Convertor/ConvertorSideExchangeVisualization.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { CONVERTOR_CONFIRMATION_BLOCK } from '@/globalVariables'
import { getFormattedTimeFromHMS } from '@/helpers'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { Ingredient } from '@/interfaces/responses/events/warehouse/WarehouseSharedInterface'
import { useWarehouseStore } from '@/store/pinia/events/warehouseStore'
import type { PropType } from 'vue'

interface ComponentData {
  isCheckboxChecked: boolean
}

export default defineComponent({
  name: 'ConvertorOperationConfirmPopup',
  components: {
    ConvertorSideExchangeVisualization,
    InfoPopup,
  },
  props: {
    isVisible: {
      type: Boolean,
      reqired: true,
    },
    type: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    productionTime: {
      type: Number,
      default: 0,
    },
    isTier3: {
      type: Boolean,
      default: false,
    },
    inputIngredient: {
      type: Object as PropType<Ingredient>,
      required: true,
    },
    outputIngredient: {
      type: Object as PropType<Ingredient>,
      required: true,
    },
    inputValue: {
      type: Number,
      default: 0,
    },
    outputValue: {
      type: Number,
      default: 0,
    },
  },
  emits: ['close', 'click', 'setBlockState'],
  data(): ComponentData {
    return {
      isCheckboxChecked: false,
    }
  },
  computed: {
    ...mapState(useWarehouseStore, {
      getExchangeDateEnd: 'getExchangeDateEnd',
    }),
    isProductionPopup(): boolean {
      return this.type === 'manufacture'
    },
    remainingEventExchangeTimeInSeconds(): number {
      if (!this.getExchangeDateEnd) return 0

      const targetDate: Date = new Date(this.getExchangeDateEnd)
      const currentDate: Date = new Date()

      const remainingTime: number = Math.abs(targetDate.getTime() - currentDate.getTime()) / 1000

      return remainingTime >= 0 ? Math.round(remainingTime) : 0
    },
    isProductionTimeLongerThanEvent(): boolean {
      return this.productionTime > this.remainingEventExchangeTimeInSeconds
    },
    eventEndText(): string {
      return this.$replacePlaceholder(
        this.$te('warningEventEnd'),
        '{time}',
        getFormattedTimeFromHMS(this.getExchangeDateEnd).toString(),
      )
    },
  },
  methods: {
    clickOnButton(): void {
      if (this.isCheckboxChecked) {
        this.$emit('setBlockState', true)
        localStorage.setItem(CONVERTOR_CONFIRMATION_BLOCK, 'true')
      }

      this.$emit('click')
    },
  },
})
</script>

<style lang="scss" scoped>
.convertor-popup-wrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.main-content {
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-style: solid;
  border-width: 0.183rem;
  border-image-slice: 1;

  @if $isWsm {
    background-image: linear-gradient(
      to right,
      rgba(24, 51, 78, 0.03),
      #18334e,
      #18334e,
      rgba(24, 51, 78, 0.9),
      #18334e
    );
    border-image-source: linear-gradient(
      to right,
      #18334e,
      #20446a,
      #2a5582,
      rgba(29, 63, 99, 0.9),
      #18334e
    );
    box-shadow: 4px 5px 11px 0 rgba(0, 0, 0, 0.22);
  }

  @if $isSsm {
    background-image: linear-gradient(
      to right,
      transparent 0%,
      #222d4d 20%,
      #222d4d 50%,
      #222d4d 80%,
      transparent 100%
    );
    border-image-source: linear-gradient(
      to right,
      transparent 0%,
      #2a5482 20%,
      #2a5482 50%,
      #2a5482 80%,
      transparent 100%
    );
  }
}

.convertor-visualization {
  margin-top: 2rem;
}

.no-more-confirmation {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
</style>
