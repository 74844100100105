import {
  CLUB_BUILDINGS,
  clubRewards,
  clubRewardsBuildingEndpoint,
  clubRewardsEmployeesEndpoint,
} from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import { useEmployeeState } from './employeeStore'
import type { ClubRewardResponse } from '@/interfaces/clubs/ClubRewards'
import type { Employee, EmployeesSlot } from '@/interfaces/clubs/Employees'
import { usePhaserGameIntegrationStore } from '../map-new/phaserGameIntegrationStore'
import router from '@/router'

interface ClubRewardsState {
  timers: { [key: string]: number }
  allClubRewards: ClubRewardResponse[]
  loadRewardsTooltips: boolean
  isLoadingRewards: boolean
}

export const useRewardStore = defineStore('rewardStore', {
  state: (): ClubRewardsState => ({
    allClubRewards: [],
    timers: {},
    loadRewardsTooltips: false,
    isLoadingRewards: false,
  }),
  getters: {
    getAllClubRewards(): ClubRewardsState['allClubRewards'] {
      return this.allClubRewards
    },

    getAllClubRewardsBySpecificId(): (id: number) => ClubRewardResponse {
      return (id: number): ClubRewardResponse => {
        return this.allClubRewards.filter(
          (reward: ClubRewardResponse): boolean => reward.building_type.building_type_id === id,
        )[0]
      }
    },
    getAllClubReadyForClaimRewards(): ClubRewardsState['allClubRewards'] {
      return this.allClubRewards.filter(
        (reward: ClubRewardResponse): boolean => reward.next_claim <= 0,
      )
    },
    getAllClubReadyForClaimRewardsBySpecificId(): (
      id: number,
    ) => ClubRewardsState['allClubRewards'] {
      return (id: number): ClubRewardsState['allClubRewards'] => {
        return this.allClubRewards.filter(
          (reward: ClubRewardResponse): boolean =>
            reward.building_type.building_type_id === id && reward.next_claim <= 0,
        )
      }
    },
    getLoadRewardsTooltips(): ClubRewardsState['loadRewardsTooltips'] {
      return this.loadRewardsTooltips
    },
  },
  actions: {
    async loadAllClubRewards(refresh: boolean = false): Promise<void> {
      if ((this.allClubRewards.length > 0 || this.isLoadingRewards) && !refresh) return

      this.isLoadingRewards = true
      Object.keys(this.timers).forEach((id: string): void => {
        clearInterval(this.timers[id])
        delete this.timers[id]
      })
      const response = await internalAxios.get<{}, ClubRewardResponse[]>(clubRewards)
      this.allClubRewards = response

      this.allClubRewards.forEach((reward: ClubRewardResponse): void => {
        if (reward.next_claim <= 0) return

        const timer = setInterval(() => {
          if (reward.next_claim === 0) {
            clearInterval(this.timers[id])
            delete this.timers[id]
            this.loadRewardsTooltips = true
            this.syncRewards()
          }
          const rewardForUpdate = this.allClubRewards.find(
            (reward: ClubRewardResponse): boolean => reward.club_reward_id === id,
          )
          if (rewardForUpdate) --rewardForUpdate.next_claim
        }, 1000)
        const id = reward.club_reward_id
        this.timers[id] = timer
      })
      this.loadRewardsTooltips = true
      this.isLoadingRewards = false
      usePhaserGameIntegrationStore().setBuildingRewards()

      // If it is a club building route, return so that employees are not overwritten with an empty payload that loads all employees
      if (router.currentRoute.value.meta?.isClubBuildingRoute) return
      const employeeState = useEmployeeState()
      employeeState.loadEmployees({ buildingTypeId: CLUB_BUILDINGS.all, refresh: true })
    },
    // body contains club_reward_id - optional, if ommited it will claim all available rewards
    async claimRewards(rewardId: string = ''): Promise<void> {
      const body = rewardId ? { club_reward_id: rewardId } : {}
      try {
        await internalAxios.post<{}, true>(clubRewards, body)
      } catch (error: unknown) {
        console.error(error)
      }
      this.loadAllClubRewards(true)
    },
    async claimEmployeesRewards(): Promise<void> {
      try {
        await internalAxios.post<{}, true>(clubRewardsEmployeesEndpoint)
      } catch (error: unknown) {
        console.error(error)
      }
      this.loadAllClubRewards(true)
      const employeeState = useEmployeeState()
      employeeState.loadEmployees({ buildingTypeId: CLUB_BUILDINGS.all, refresh: true })
    },
    async claimBuildingRewards(data: {
      clubBuildingId: string
      buildingTypeId?: number
    }): Promise<void> {
      try {
        await internalAxios.post<{}, true>(clubRewardsBuildingEndpoint, {
          clubs_buildings_id: data.clubBuildingId,
        })
      } catch (error: unknown) {
        console.error(error)
      } finally {
        this.loadAllClubRewards(true)
      }
    },
    setLoadRewardsTooltips(value: boolean): void {
      this.loadRewardsTooltips = value
    },
    syncRewards(): void {
      const rewards = {}
      this.allClubRewards.forEach((reward: ClubRewardResponse): void => {
        rewards[reward.clubs_employees_id] = reward.next_claim <= 0 ? 1 : 0
      })

      const employeeStore = useEmployeeState()
      const employees = employeeStore.getEmployees.map((employee: Employee): Employee => {
        const tmpEmployee = { ...employee }
        tmpEmployee.rewardsToClaim = rewards[employee.employeeId] ?? 0
        return tmpEmployee
      })
      employeeStore.setEmployee(employees)
      const slots = null
      Object.entries(employeeStore.getEmployeesSlots).forEach(
        ([index, slot]: [index: string, slot: EmployeesSlot]): void => {
          const tmpslot = JSON.parse(JSON.stringify(slot))
          if (tmpslot.club.employeeId) {
            tmpslot.club.rewardsToClaim = rewards[tmpslot.club.employeeId] ?? 0
          }
          if (tmpslot.personal.employeeId) {
            tmpslot.personal.rewardsToClaim = rewards[tmpslot.personal.employeeId] ?? 0
          }
          slots[index] = tmpslot
        },
      )
      employeeStore.setEmployeeSlots(slots)
    },
  },
})
