<template>
  <section class="playoff-info flex flex-col justify-between mt-12">
    <article v-if="nextDrawIn !== 0" class="playoff-info-article">
      <header class="rewards-header flexing relative mb-2 mt-2">
        <!-- Toto bolo povodne text-50, ale pre urgentnost situacie sme zmenili na text-40. Pri refaktore sa to poriesi korektne. -->
        <p class="gradient-text-light text-40 font-bold uppercase">{{ title }}</p>
      </header>
      <app-timer class="timer-playoff" :time="nextDrawIn" />
      <header class="rewards-header flexing relative mb-2">
        <!-- Toto bolo povodne text-50, ale pre urgentnost situacie sme zmenili na text-40. Pri refaktore sa to poriesi korektne. -->
        <p class="gradient-text-light text-40 font-bold uppercase">
          {{ $te('actualTasksForPlayoff') }}
        </p>
      </header>
      <p
        class="playoff-info-article-text relative flexing text-32 font-bold text-texts-standard-important"
      >
        <span>
          {{ task }}
          <app-main-icon
            v-if="icon"
            :icon-size="56"
            :icon-name="icon"
            class="ml-4"
            style="margin-bottom: -0.75rem; margin-left: 0.25rem"
        /></span>
      </p>
    </article>
    <article v-else class="playoff-info-article">
      <header class="flexing relative mb-8">
        <!-- Toto bolo povodne text-50, ale pre urgentnost situacie sme zmenili na text-40. Pri refaktore sa to poriesi korektne. -->
        <p class="tricks gradient-text-light text-50 font-bold uppercase">{{ title }}</p>
      </header>
      <header class="flexing relative mb-2 mt-12">
        <!-- Toto bolo povodne text-50, ale pre urgentnost situacie sme zmenili na text-40. Pri refaktore sa to poriesi korektne. -->
        <p class="text-texts-standard-important text-40 font-bold">
          <span>
            {{ task }}
            <app-main-icon
              v-if="icon"
              :icon-size="56"
              :icon-name="icon"
              class="ml-4"
              style="margin-bottom: -0.75rem; margin-left: 0.25rem"
          /></span>
        </p>
      </header>
    </article>
    <article class="playoff-info-rewards mt-8">
      <header class="rewards-header flexing relative mb-2 tasks">
        <!-- Toto bolo povodne text-50, ale pre urgentnost situacie sme zmenili na text-40. Pri refaktore sa to poriesi korektne. -->
        <p class="gradient-text-light text-40 font-bold uppercase">
          {{ $te('commonRewards') }}
        </p>
      </header>
      <section class="rewards flex flex-row flex-wrap justify-center relative px-8 py-2">
        <template v-if="rewards.length">
          <template v-for="(round, i) in rewards" :key="i">
            <div
              v-for="(reward, j) in round.rewards"
              :key="j"
              class="rewards-wrap w-1/2 flexing text-50 text-texts-standard-default"
            >
              <p class="mr-2">{{ reward.value }}</p>
              <app-main-icon :icon-name="reward.type" :icon-size="56" />
            </div>
          </template>
        </template>
        <p v-else class="flexing text-32 font-bold text-texts-standard-default">
          {{ $te('playoffRewardsNotAvailable') }}
        </p>
      </section>
    </article>
  </section>
</template>

<script lang="ts">
import { playoffRewards } from '@/globalVariables'
import { formatTime } from '@/helpers'
import { useEventPlayoffStore } from '@/store/pinia/events/playoffStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PlayoffRewardsApiResponse_Draw } from '@/interfaces/responses/events/playoff/PlayoffRewardsApiResponse'

interface ComponentData {
  rewards: PlayoffRewardsApiResponse_Draw['rewards'][0]['rounds'][0][]
}

export default defineComponent({
  name: 'PlayoffRewardsInfo',
  props: {
    state: {
      type: String,
      required: true,
    },
    league: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    task: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    currentRound: {
      type: Number,
      required: true,
    },
    eliminatedInRound: {
      type: Number,
      required: true,
    },
    nextDrawIn: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data(): ComponentData {
    return {
      rewards: [],
    }
  },
  computed: {
    ...mapState(useEventPlayoffStore, {
      playoffId: 'getPlayoffId',
    }),
  },
  async created(): Promise<void> {
    await this.getRewards()
  },
  methods: {
    formatTime,
    async getRewards(): Promise<void> {
      try {
        const { rewards } = await this.$axios.post<{}, PlayoffRewardsApiResponse_Draw>(
          playoffRewards,
          {
            playoff_id: this.playoffId,
            state: this.state,
          },
        )
        rewards
          ?.find(
            ({ league }: PlayoffRewardsApiResponse_Draw['rewards'][0]): boolean =>
              league === this.league,
          )
          ?.rounds.forEach(
            (reward: PlayoffRewardsApiResponse_Draw['rewards'][0]['rounds'][0]): void => {
              if (
                this.currentRound < reward.rounds ||
                (this.eliminatedInRound < reward.rounds && this.eliminatedInRound !== -1)
              )
                return
              this.rewards.push(reward)
            },
          )
        // Zobrazujeme odmenu len z posledneho kola, nie za vsetky predchadzajuce kola.
        this.rewards = this.rewards.slice(-1)
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.tricks {
  line-height: 3.5rem;
}

.playoff-info {
  width: 29.5rem;
  min-height: 30rem;

  &-article {
    header {
      height: 3.75rem;

      &.tasks {
        height: 3.125em;
      }
    }

    &-text {
      height: 10rem;

      @if $isWsm {
        background-image: linear-gradient(
          to right,
          transparent,
          rgb(90, 159, 226, 0.6),
          transparent
        );
      }

      @if $isSsm {
        background-image: linear-gradient(
          to right,
          transparent,
          rgba(111, 95, 166, 0.6),
          transparent
        );
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        width: 80%;
        height: 0.125rem;
        transform: translate(-50%);

        @if $isWsm {
          background-image: linear-gradient(
            to right,
            transparent,
            rgba(162, 209, 255, 0.375),
            transparent
          );
        }

        @if $isSsm {
          background-image: linear-gradient(
            to right,
            transparent,
            rgba(177, 159, 239, 0.375),
            transparent
          );
        }
      }

      &::after {
        bottom: 0;
      }

      &::before {
        top: 0;
      }
    }
  }

  &-rewards {
    header {
      height: 3.125em;
    }

    .rewards {
      height: 10rem;
      background: linear-gradient(to right, transparent, #c48721bd, transparent);

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        width: 80%;
        height: 0.125rem;
        background: linear-gradient(to right, transparent, rgba(196, 135, 33, 0.375), transparent);
        transform: translate(-50%);
      }

      &::after {
        bottom: 0;
      }

      &::before {
        top: 0;
      }
    }
  }

  header.rewards-header {
    background: linear-gradient(to right, transparent, #fa4b4c87, transparent);

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      width: 80%;
      height: 0.125rem;
      background: linear-gradient(to right, transparent, #ffb3b4, transparent);
      transform: translate(-50%);
    }

    &::after {
      bottom: 0;
    }

    &::before {
      top: 0;
    }
  }

  .timer-playoff {
    position: relative;
    left: 2rem;
    margin: 0.5rem auto 2rem auto;
    width: 17.56rem;
  }
}
</style>
