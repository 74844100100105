import type { CameraConfigInterface } from '@/map-phaser-new/interfaces/camera/cameraConfigInterface'

export const cameraConfig: CameraConfigInterface = {
  breakpointMobile: 1000,
  breakpointTablet: 1370,
  breakpoint4K: 3200,
  breakpoint2K: 2400,
  zoomChangeValue: 0.05,
  zoomLevels: {
    subTwo: {
      WebDefault: {
        defaultZoom: 0.8,
        minZoom: 0.07,
        maxZoom: 1.1,
        defaultZoomApple: 0.8,
        minZoomApple: 0.07,
        maxZoomApple: 1.1,
      },
      //overene cez macbook pro 13
      Web2k: {
        defaultZoom: 1.1,
        minZoom: 0.1,
        maxZoom: 1.1,
        defaultZoomApple: 1.1,
        minZoomApple: 0.1,
        maxZoomApple: 1.1,
      },
      //overene cez macbook pro 13
      Web4k: {
        defaultZoom: 1.4,
        minZoom: 0.05,
        maxZoom: 1.7,
        defaultZoomApple: 1.4,
        minZoomApple: 0.05,
        maxZoomApple: 1.7,
      },
      MobileDefault: {
        defaultZoom: 1.1,
        minZoom: 0.1,
        maxZoom: 1.3,
        defaultZoomApple: 1.5,
        minZoomApple: 0.1,
        maxZoomApple: 1.7,
      },
      //iphone XR, SE. Android > galaxy s3
      MobileSmall: {
        defaultZoom: 0.6,
        minZoom: 0.09,
        maxZoom: 0.8,
        defaultZoomApple: 0.8,
        minZoomApple: 0.2,
        maxZoomApple: 1.0,
      },
      //ipad air, mini. Android > surface pro 7,
      Tablet: {
        defaultZoom: 1.3,
        minZoom: 0.04,
        maxZoom: 1.4,
        defaultZoomApple: 1.2,
        minZoomApple: 0.1,
        maxZoomApple: 1.4,
      },
    },
    subThree: {
      WebDefault: {
        defaultZoom: 0.65,
        minZoom: 0.04,
        maxZoom: 0.8,
        defaultZoomApple: 0.65,
        minZoomApple: 0.04,
        maxZoomApple: 0.8,
      },
      Web2k: {
        defaultZoom: 1,
        minZoom: 0.1,
        maxZoom: 1.1,
        defaultZoomApple: 1,
        minZoomApple: 0.1,
        maxZoomApple: 1.1,
      },
      Web4k: {
        defaultZoom: 1.4,
        minZoom: 0.1,
        maxZoom: 1.7,
        defaultZoomApple: 1.4,
        minZoomApple: 0.1,
        maxZoomApple: 1.7,
      },
      MobileDefault: {
        defaultZoom: 1.1,
        minZoom: 0.1,
        maxZoom: 1.3,
        defaultZoomApple: 1.1,
        minZoomApple: 0.1,
        maxZoomApple: 1.3,
      },
      //iphone X, iphone 14 pro max. Android > Samsung s5, pixel 7, fold 5, pixel 3, note 3
      MobileSmall: {
        defaultZoom: 0.8,
        minZoom: 0.05,
        maxZoom: 1.05,
        defaultZoomApple: 1.0,
        minZoomApple: 0.04,
        maxZoomApple: 1.25,
      },
      //Ipad pro, Android tab s4
      Tablet: {
        defaultZoom: 1.2,
        minZoom: 0.03,
        maxZoom: 1.4,
        defaultZoomApple: 1.5,
        minZoomApple: 0.1,
        maxZoomApple: 1.7,
      },
    },
    aboveThree: {
      WebDefault: {
        defaultZoom: 0.65,
        minZoom: 0.04,
        maxZoom: 0.8,
        defaultZoomApple: 0.65,
        minZoomApple: 0.04,
        maxZoomApple: 0.8,
      },
      Web2k: {
        defaultZoom: 1,
        minZoom: 0.1,
        maxZoom: 1.1,
        defaultZoomApple: 1,
        minZoomApple: 0.1,
        maxZoomApple: 1.1,
      },
      Web4k: {
        defaultZoom: 1.4,
        minZoom: 0.1,
        maxZoom: 1.7,
        defaultZoomApple: 1.4,
        minZoomApple: 0.1,
        maxZoomApple: 1.7,
      },
      MobileDefault: {
        defaultZoom: 1.1,
        minZoom: 0.1,
        maxZoom: 1.3,
        defaultZoomApple: 1.1,
        minZoomApple: 0.1,
        maxZoomApple: 1.3,
      },
      //Android > samsung s20ultra, s8plus,
      MobileSmall: {
        defaultZoom: 1.2,
        minZoom: 0.1,
        maxZoom: 1.35,
        defaultZoomApple: 1.0,
        minZoomApple: 0.04,
        maxZoomApple: 1.25,
      },
      Tablet: {
        defaultZoom: 1.4,
        minZoom: 0.03,
        maxZoom: 1.5,
        defaultZoomApple: 1.4,
        minZoomApple: 0.03,
        maxZoomApple: 1.5,
      },
    },
  },
}
