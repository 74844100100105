<template>
  <div>
    <menu-component menu-type="avatar" :title="$t('profile.appearance')" />
    <main class="flex main-wrapper">
      <div class="flex flex-col left-wrapper">
        <div class="flex justify-between items-center mt-10">
          <app-button
            btn-size="xl"
            :btn-type="
              $route.name === $getWebView('ProfileAvatars')
                ? 'secondary'
                : $isWsm
                  ? 'inactive'
                  : 'primary'
            "
            :btn-text="$t('profile.avatars')"
            @click="
              $router.push({
                name: $getWebView('ProfileAvatars'),
              })
            "
          />
          <app-button
            class="ml-8"
            btn-size="xl"
            :btn-type="
              $route.name === $getWebView('ProfileBackgrounds')
                ? 'secondary'
                : $isWsm
                  ? 'inactive'
                  : 'primary'
            "
            :btn-text="$t('profile.backgrounds')"
            @click="
              $router.push({
                name: $getWebView('ProfileBackgrounds'),
              })
            "
          />
        </div>
        <div class="profile-avatar-content bordered-1">
          <app-scrollbar width="100%" height="44rem" slide="y" scroll="y" class="mt-4">
            <div class="profile-avatar-boxes flex flex-wrap">
              <profile-choose-avatar
                :avatar-data="avatars"
                :image-choice="chooseType"
                @set-avatar="setAvatarImage"
                @set-bg="setBgImage"
              />
            </div>
          </app-scrollbar>

          <div
            v-if="userAvatarData?.noBg && chooseType === 'background'"
            class="no-background-overlay text-40 text-texts-standard-default flexing w-full h-full flex-col absolute left-0 top-0"
          >
            <app-icon class="mb-8" icon-name="lock-xxl" />
            <p>{{ $t('profile.avatarCantChangeBackground') }}</p>
          </div>
        </div>
      </div>

      <div class="profile-avatar-look">
        <div class="profile-avatar-look-head-title overflow-hidden">
          <h2 class="text-texts-standard-important text-40 font-bold uppercase truncate">
            {{ $t(`avatarsAndBg.avatar_${idAvatar}`) }}
            <!-- TODO nazvy avatarov aj BG z konfigu https://docs.google.com/spreadsheets/d/1ah9Jp-LP0q-i9Z5ovT9t8ahLha2DVVrie0i84xs4o-c/edit#gid=0  neprekladatelne -->
          </h2>
        </div>
        <p class="text-texts-standard-default text-32">
          {{ $t(`avatarsAndBg.bg_${idBg}`) }}
        </p>
        <avatar-box
          class="profile-avatar-image"
          :avatar-id="idAvatar"
          :background-id="idBg"
          :avatar-gender="sex"
        />
        <app-button
          :btn-type="$isWsm ? 'confirm' : 'secondary'"
          :disabled="userAvatarData?.noBg && chooseType === 'background'"
          btn-size="md"
          :btn-text="$t('profile.saveChanges')"
          class="m-auto"
          @click="saveChanges()"
        />
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { userProfileEndpoint } from '@/globalVariables'
import AvatarBox from '@/components/Profile/AvatarBox.vue'
import ProfileChooseAvatar from '@/components/Profile/ProfileChooseAvatar.vue'
import { useUserStore } from '@/store/pinia/userStore'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { UpdatedAvatar, UpdatedBackgrounds } from '@/interfaces/User'
import type { UserProfile } from '@/types/userProfile'
import type { NameValueString } from '@/interfaces/Global'

interface ComponentData {
  idAvatar: Nullable<number>
  idBg: Nullable<number>
}

export default defineComponent({
  name: 'ProfileChoose',
  components: {
    ProfileChooseAvatar,
    AvatarBox,
  },
  props: {
    avatars: {
      type: Array as PropType<UpdatedAvatar[] | UpdatedBackgrounds[]>,
      default: () => [],
    },
    chooseType: {
      type: String,
      default: 'avatar',
    },
  },
  data(): ComponentData {
    return {
      idAvatar: null,
      idBg: null,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      avatar: 'getAvatar',
      avatarBg: 'getAvatarBg',
      sex: 'getSex',
      userAvatarData: 'getUserAvatarData',
    }),
  },
  created(): void {
    const idAvatarFromStorage = sessionStorage.getItem('idAvatar')
    if (idAvatarFromStorage) {
      this.idAvatar = parseInt(idAvatarFromStorage)
    } else {
      this.idAvatar = this.avatar
    }

    const idBgFromStorage = sessionStorage.getItem('idBg')
    if (idBgFromStorage) {
      this.idBg = parseInt(idBgFromStorage)
    } else {
      this.idBg = this.avatarBg
    }
  },
  methods: {
    ...mapActions(useUserStore, ['loadUserData']),
    setAvatarImage(id: number): void {
      this.idAvatar = id
      sessionStorage.setItem('idAvatar', id.toString())
    },
    setBgImage(id: number): void {
      this.idBg = id
      sessionStorage.setItem('idBg', id.toString())
    },
    async saveChanges(): Promise<void> {
      // retrieve avatar and background IDs from sessionStorage, or use store values
      const idAvatarFromStorage = sessionStorage.getItem('idAvatar')
      const idBgFromStorage = sessionStorage.getItem('idBg')

      const idAvatar = idAvatarFromStorage ?? this.idAvatar.toString()
      const idBg = idBgFromStorage ?? this.idBg.toString()

      await this.$axios.post<
        {},
        Omit<UserProfile, 'group' | 'multiplicators'>,
        { profile_attributes: NameValueString[] }
      >(userProfileEndpoint, {
        profile_attributes: [
          {
            name: 'avatar',
            value: idAvatar,
          },
          {
            name: 'avatar_background',
            value: idBg,
          },
        ],
      })

      this.loadUserData()
    },
  },
})
</script>

<style lang="scss" scoped>
.profile-avatar-content {
  height: 45.125rem;
  margin-top: 2.5rem;
  @if $isWsm {
    border: 0.063rem solid #5ba3dc;
    background-color: #1b2f47;
  }
  @if $isSsm {
    border: 0.063rem solid #586b9d;
    background: linear-gradient(to right, rgba(52, 65, 93, 0.85), rgba(39, 50, 73, 0.85));
  }

  .profile-avatar-boxes {
    width: 100%;
    padding-left: 0.5rem;
  }
}

.profile-avatar-look {
  width: 46.5%;
  padding-top: 8rem;

  &-head-title {
    position: relative;
    height: 5rem;
    width: 50%;
    margin: 0 auto;

    &:before {
      content: '';
      width: 100%;
      height: 0.125rem;
      position: absolute;
      bottom: 0.75rem;
      left: 0;
      background: linear-gradient(
        to right,
        rgba(251, 227, 110, 0),
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.5),
        rgba(251, 227, 110, 0)
      );
    }
  }

  .profile-avatar-image {
    width: 21.563rem;
    height: 21.563rem;
    margin: 3.313rem auto 6.188rem;
  }
}

.main-wrapper {
  width: 95%;
  margin: 0 auto;
}

.left-wrapper {
  width: 53.5%;
}

.no-background-overlay {
  background: rgba(0, 0, 0, 0.5);
}
</style>
