import { mailClubMessageEndpoint, mailReceivedEndpoint } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import { ApiService } from '@/services/ApiService'

interface ClubMessage {
  subject: string
  message: string
}

interface Payload {
  clubMessage: ClubMessage
}

interface MailStoreState {
  unreadMail: number
  clubMessage: ClubMessage
}

export const useMailStore = defineStore('mailStore', {
  state: (): MailStoreState => ({
    unreadMail: 0,
    clubMessage: {
      subject: '',
      message: '',
    },
  }),
  getters: {
    getUnreadMailCount(): number {
      return this.unreadMail
    },
  },
  actions: {
    setMailUnread(unreadMailCount: number): void {
      this.unreadMail = unreadMailCount
    },
    setClubMessage(payload: Payload): void {
      this.clubMessage = { ...payload.clubMessage, ...payload }
    },
    async loadReceivedMail(): Promise<void> {
      const allMail = await ApiService.get<{ unread_count: number }>(
        mailReceivedEndpoint + '/offset/0',
        { force: true },
      )
      this.unreadMail = allMail?.unread_count ?? 0
    },
    async sendClubMessage(message: ClubMessage): Promise<void> {
      const data = {
        subject: message.subject,
        message: message.message,
      }
      await internalAxios.post<{}, true>(mailClubMessageEndpoint, data)
    },
  },
})
