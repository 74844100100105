export enum ActiveState {
  NotStarted = 'not_started',
  Qualification = 'qualification',
  Draw = 'draw',
  Ended = 'ended',
}

export enum JoinStatus {
  CanJoin = 'can_join',
  NotJoined = 'not_joined',
  Joined = 'joined',
}

export enum ResultType {
  QuarterFinal = 'quarter_final',
  SemiFinal = 'semi_final',
  Final = 'final',
}

export enum EliminationEnum {
  Qualification = 'qualification',
  QuarterFinal = 'quarter_final',
  SemiFinal = 'semi_final',
  Final = 'final',
}

export interface ParametersInterface {
  name: string
  value: string
}

export enum DrawState {
  QuarterFinal = 'quarter_final',
  SemiFinal = 'semi_final',
  Final = 'final',
}

export interface MastersSetupObject {
  playoff_id: number
  playoff_name: string
  state: ActiveState
  draw_state: DrawState
  start_date?: Date
  end_date?: Date
  is_master_playoff: boolean
  join_status: JoinStatus
  player_advancing?: boolean
  qualification_position?: number
  best_draw_result?: ResultType
  is_playoff_winner?: boolean
  elimination_reason?: EliminationEnum
  ranking_position?: number
  is_recalculating: boolean
  task: {
    parameters: ParametersInterface[]
  }
}

export interface PlayoffTextExchange {
  playoff_advancing: number
  playoff_round_length: number
  masters_playoff_name: string
}
