import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const landingHelicopterAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'landing_helicopter_descending',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '15',
      delay: 0,
      duration: 750,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GeneratedFrameNames,
      data: [
        { key: 'animations', frame: '15' },
        { key: 'animations', frame: '16' },
        { key: 'animations', frame: '17' },
      ],
    },
  },
  {
    animationCreationData: {
      animationName: 'landing_helicopter_ascending',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '17',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GeneratedFrameNames,
      data: [
        { key: 'animations', frame: '17' },
        { key: 'animations', frame: '18' },
        { key: 'animations', frame: '19' },
        { key: 'animations', frame: '20' },
        { key: 'animations', frame: '21' },
        { key: 'animations', frame: '22' },
      ],
    },
  },
]
