import { CLUBHOUSE } from '@/globalVariables'

import { StageType } from '@/interfaces/Tutorial'
import type { TutorialStage } from '@/interfaces/Tutorial'

/**
  Tutorial on club map
 */

export default class TutorialUnlockFirstClubJoin {
  stages(): TutorialStage[] {
    return [
      // narative on club map
      {
        id: 86,
        name: 'clubsMapNarrative',
        type: StageType.Narrative,
        overlay: false,
        pageName: 'ClubsMapTutorial',
        texts: [],
        map: 'clubs',
      },
      // focus on club house
      {
        id: 87,
        name: 'focusOnClubHouseBuilding',
        type: StageType.Focus,
        overlay: false,
        pageName: 'ClubsMapTutorial',
        clientId: [CLUBHOUSE],
        texts: [],
        map: 'clubs',
      },
      // clubhouse narrative 1
      {
        id: 88,
        name: 'clubHouseNarrative1',
        type: StageType.Narrative,
        overlay: false,
        pageName: 'ClubsHouseTutorial',
        texts: [],
        map: 'clubs',
      },
      // clubhouse narrative 2
      {
        id: 89,
        name: 'clubHouseNarrative2',
        type: StageType.Narrative,
        overlay: false,
        pageName: 'ClubsHouseTutorial',
        texts: [],
        map: 'clubs',
      },
      // focus on close button
      {
        id: 90,
        name: 'clickClubhouseCloseButton',
        type: StageType.ClientInput,
        overlay: true,
        pageName: 'ClubsHouseTutorial',
        clientId: ['control-icon-home', 'control-icon-close'],
        texts: [],
        map: 'clubs',
      },
      {
        id: 91,
        name: 'focusOnClubCentrumNarrative',
        type: StageType.Narrative,
        overlay: false,
        pageName: 'ClubsMapTutorial',
        texts: [],
        map: 'clubs',
      },
      // focus on club centrum button
      {
        id: 92,
        name: 'focusOnClubCentrum',
        type: StageType.ClientInput,
        overlay: true,
        pageName: 'ClubsMapTutorial',
        clientId: ['club-centrum-btn'],
        texts: [],
        map: 'clubs',
      },
    ]
  }
}
