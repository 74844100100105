<template>
  <div
    class="app-checkbox-wrapper cursor-pointer flexing"
    :class="{ 'flex-row-reverse': labelPlace === 'right' }"
    @click="checkBoxClicked"
  >
    <p
      v-if="label.length"
      class="app-checkbox-text text-34 text-texts-standard-additional uppercase"
      :class="[labelPlace === 'right' ? 'ml-4' : 'mr-4']"
    >
      {{ label }}
    </p>
    <div
      class="app-checkbox flexing"
      :class="'app-checkbox-' + size"
      :style="isTransparentBackground && { background: 'transparent', border: '0' }"
    >
      <app-icon v-if="isChecked" :icon-name="'done-' + size" class="app-checkbox-icon" />
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppCheckBox',
  props: {
    isChecked: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      default: 'md',
    },
    label: {
      type: String,
      default: '',
    },
    labelPlace: {
      type: String,
      default: 'left',
    },
    isTransparentBackground: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['checkedAction'],
  methods: {
    checkBoxClicked(): void {
      this.$emit('checkedAction')
    },
  },
})
</script>

<style lang="scss" scoped>
.app-checkbox {
  @if $isWsm {
    border: 0.125rem solid #447aa5;
    background-image: linear-gradient(to top, #4787b4, #2b4e67),
      linear-gradient(to bottom, #152436, #152436);
  }
  @if $isSsm {
    border: 0.125rem solid #586b9d;
    background-image: linear-gradient(to top, rgba(52, 65, 93, 0.85), rgba(39, 50, 73, 0.85));
  }
  &-icon {
    margin: auto;
  }

  &-md {
    width: 3.7rem;
    height: 3.7rem;
  }

  &-sm {
    width: 2.563rem;
    height: 2.563rem;
    background-color: transparent;
  }
}
</style>
