<template>
  <div
    class="avatar-menu-content-panel avatar-menu-content-panel-club"
    :class="{ 'avatar-menu-content-panel-disabled': !isMyClub($router) }"
  >
    <div class="avatar-menu-content-panel-up w-full flex items-center">
      <avatar-menu-name :name-prop="getClubName" @click="openClubMember" />
    </div>
    <div class="avatar-menu-content-panel-bottom" @click="openClubMember">
      <p class="text-texts-standard-additional text-28" v-html="getClubText"></p>
    </div>
  </div>
</template>

<script lang="ts">
import AvatarMenuName from '@/components/AvatarMenu/AvatarMenuName.vue'
import { pathImages } from '@/globalVariables'
import { isMyClub } from '@/helpers/isMyClub'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'AvatarMenuPanelClub',
  components: {
    AvatarMenuName,
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      clubName: 'getClubName',
      club: 'getClub',
    }),
    ...mapState(useClubStore, {
      clubInfo: 'getClubInfo',
    }),
    getMembersLength(): number {
      return isMyClub(this.$router) ? this.club.member_count : this.clubInfo.members.length
    },
    getMaxMembersLength(): number {
      return isMyClub(this.$router) ? this.club.members_max_length : this.clubInfo.membersMaxLength
    },
    getClubText(): string {
      return (
        this.$t('club.clubPlayers') +
        ': ' +
        '<span class="text-texts-standard-important">' +
        this.getMembersLength +
        '</span>' +
        '/' +
        (this.getMaxMembersLength ?? ' -')
      )
    },
    getClubName() {
      return this.isMyClub(this.$router) ? this.clubName : this.clubInfo.name
    },
  },
  methods: {
    isMyClub,
    openClubMember(): void {
      if (!this.isMyClub(this.$router)) return
      this.$router.push({ name: this.$getWebView('ClubsMembers') })
    },
  },
})
</script>
