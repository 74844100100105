<template>
  <section
    class="staroftheday-podium flex items-end justify-between"
    :class="`staroftheday-${viewType}-podium`"
  >
    <staroftheday-podium-rank
      v-for="rank in 3"
      :key="rank"
      :view-type="viewType"
      :rank="rank"
      :country="podiumData[rank - 1].country"
      :is-bot="podiumData[rank - 1].bot"
      :user-id="podiumData[rank - 1].id"
      :user-name="podiumData[rank - 1].name"
      :points="podiumData[rank - 1].points ?? podiumData[rank - 1].rankingPoints"
      :event-badges="podiumData[rank - 1].event_badges"
    />
  </section>
</template>

<script lang="ts">
import StarofthedayPodiumRank from '@/components/Staroftheday/StarofthedayPodiumRank.vue'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { DailyLeagueViewType } from '@/interfaces/DailyLeague'
import type { DailyLeagueOpponent } from '@/interfaces/responses/starOfTheDay/DailyLeagueResponse'

export default defineComponent({
  name: 'StarofthedayPodium',
  components: {
    StarofthedayPodiumRank,
  },
  props: {
    viewType: {
      type: String as PropType<DailyLeagueViewType>,
      default: DailyLeagueViewType.Standings,
      validator(value: DailyLeagueViewType): boolean {
        return [DailyLeagueViewType.Athlete, DailyLeagueViewType.Standings].includes(value)
      },
    },
    podiumData: {
      type: Array as PropType<DailyLeagueOpponent[]>,
      default: () => [],
    },
  },
})
</script>

<style lang="scss" scoped>
.staroftheday-podium {
  width: 117.5rem;
  height: 14.375rem;
}
</style>
