<template>
  <teleport to="#app">
    <div
      ref="animation0"
      class="level-up__wrapper"
      :class="{ 'simple-background': showUnlockGamePass }"
    >
      <div
        v-if="!showUnlockDiscipline && !showUnlockGamePass"
        id="level-up"
        class="fullscreen-popup-wrapper level-up"
        :class="$isMobile() && 'level-up-mobile'"
      >
        <level-up-title :texts="texts" />
        <div ref="animation1" class="level-up-middle animated-element--faded">
          <div ref="animation1a" class="level-up-badge animated-element--faded">
            <aside>
              <p class="level-up-badge-text1 text-50">Level</p>
              <p
                class="level-up-badge-text2"
                :class="
                  levelUpData.level.toString().length === 1
                    ? 'level-up-badge-text2--single-digit'
                    : 'level-up-badge-text2--two-digit'
                "
              >
                {{ levelUpData.level }}
              </p>
            </aside>
          </div>
        </div>
        <main ref="animation2" class="animated-element--faded">
          <app-scrollbar width="100%" height="95%" scroll="x" slide="x" class="flexing">
            <div v-for="(unlock, item) in levelData.base" :key="item">
              <div
                v-if="unlock"
                class="level-up-main-rewards flexing"
                :class="isUnlock(item) ? 'unlock' : ''"
              >
                <div class="level-up-main-title">
                  <p class="text-texts-standard-default text-40 uppercase font-bold flexing">
                    {{ $t(isUnlock(item) ? 'common.youUnlocked' : 'common.rewards') }}
                  </p>
                </div>
                <div class="level-up-main-rewards-box flexing">
                  <level-up-rewards
                    v-for="(reward, index) in unlock"
                    :key="index"
                    class="reward-box-item animated-element--faded"
                    :reward-icon-name="reward.iconName"
                    :reward-value="reward.rewardValue"
                    :is-unlock="reward.isUnlock"
                    :increase-max="reward.increaseMaxLimit"
                    :param-type="reward.parameterType"
                    :main-title="reward.mainTitle"
                    :subtitle="reward.subtitle"
                    :bottom-text="reward.bottomText"
                  />
                  <template v-if="showCostLive(item)">
                    <div
                      class="reward-box mx-2 flexing flex-col reward-box-item animated-element--faded"
                    >
                      <tippy theme="WSM" placement="top" max-width="25rem">
                        <div class="flexing flex-col">
                          <div class="discipline-box icon-starts" />
                          <div class="reward-box-text font-bold flexing text-28">
                            <p class="text-texts-standard-default reward-box-text-wrap">
                              {{ $t('common.livePlayingCost') }}
                            </p>
                          </div>
                        </div>
                        <template #content>
                          <div class="level-up-tooltip-title">
                            <p class="text-texts-standard-default text-36">
                              {{ $t('common.livePlayingCost') }}
                            </p>
                          </div>
                          <div class="level-up-tooltip-infoText flexing">
                            <div class="discipline-box icon-starts m-6" />
                          </div>
                          <div>
                            <div class="white-strip" />
                            <p class="text-texts-standard-default text-30 mt-4">
                              {{
                                $replacePlaceholder(
                                  $t('arena.livePlayingCostStarts'),
                                  '{2to4}',
                                  startsCostConfig.live,
                                )
                              }}
                            </p>
                          </div>
                        </template>
                      </tippy>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </app-scrollbar>
        </main>
        <section class="level-up-footer__wrapper w-full h-full safe-area">
          <div
            ref="animation3"
            class="level-up-footer animated-element--faded"
            :class="{ 'level-up-footer--transparent': !showAdRewards }"
          >
            <div v-if="showAdRewards && isRewardedAdLoaded" class="level-up-footer__ad-rewards">
              <app-icon
                icon-name="plus-gold"
                class="mx-2"
                :class="
                  isVideoRewardEarned || isClaimedGamePassRewards ? 'opacity-100' : 'opacity-50'
                "
              />
              <div class="level-up-main-rewards flexing">
                <div
                  class="level-up-main-rewards-box flexing"
                  :class="
                    isVideoRewardEarned || isClaimedGamePassRewards ? 'opacity-100' : 'opacity-50'
                  "
                >
                  <template v-for="(reward, index) in levelData.adRewards" :key="index">
                    <level-up-rewards
                      :ad-reward-icon-size="56"
                      :reward-icon-name="reward.iconName"
                      :reward-value="reward.rewardValue"
                      :is-unlock="reward.isUnlock"
                      :param-type="reward.parameterType"
                      :main-title="reward.mainTitle"
                      :subtitle="reward.subtitle"
                      :highlighted-reward="true"
                      :is-ad-context="true"
                    />
                  </template>
                </div>
              </div>
              <div v-if="!isVideoRewardEarned && !isClaimedGamePassRewards" class="flexing">
                <app-icon icon-name="caret-right-gold" class="mx-2 opacity-50" />
                <app-button
                  v-if="isNoVideoAds"
                  btn-id="game-pass-claim-button"
                  btn-size="md"
                  btn-type="confirm"
                  :btn-text="$t('common.claim')"
                  :loading="isLoadingGamePassRewards"
                  :disabled="isLoadingGamePassRewards || isClaimedGamePassRewards"
                  @click="claimGamePassRewards"
                />
                <app-multi-button
                  v-else
                  btn-type="glowing"
                  btn-size="sm"
                  :btn-text="$t('button.watch')"
                  multi-width="25.6875rem"
                  :loading="isMobilePlayingVideo"
                  @click="watchVideo()"
                >
                  <template #rightPart>
                    <app-icon icon-name="play-ad" />
                  </template>
                </app-multi-button>
              </div>
            </div>

            <arrow-animation
              add-class="arrow-tut"
              position="right"
              border="button"
              :tutorial="actualStage && actualStage.name === 'clickLevelUpBtn'"
            >
              <app-button
                btn-id="level-up-btn"
                btn-size="md"
                btn-type="secondary"
                :btn-text="$t('button.continue')"
                add-class="level-up-btn absolute"
                @click="confirm"
              />
            </arrow-animation>
          </div>
        </section>
        <video-ad-web v-show="isWebVideo" class="level-up-video-ad" />
      </div>
      <level-up-unlock-discipline
        v-else-if="showUnlockDiscipline"
        :discipline-id="unlockedDiscipline"
        @close="close"
        @back="back"
      />
      <level-up-unlock-mechanic
        v-else-if="showUnlockGamePass"
        :mechanic="MECHANIC_GAME_PASS"
        @close="close"
      />
      <video
        v-if="currentGame === GameType.Winter"
        ref="video"
        class="video-footage video-footage--snow"
        autoplay
        loop
        muted
        playsinline
        poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAArEAAAKxAFmbYLUAAAADUlEQVQImWP4//8/AwAI/AL+hc2rNAAAAABJRU5ErkJggg=="
      >
        <!-- If the browser's video player is unable to play the MP4 file, nothing will be rendered except the poster image (transparent  1x1px). -->
        <source type="video/mp4" :src="`${pathImages}videos/wsm-snowfall--medium.mp4`" />
      </video>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import {
  getIsMobileLocalStorage,
  playSound,
  requestWebAd,
  sendToFlutter,
  animateElement,
  resetAnimateElement,
  skipAnimationOnClick,
} from '@/helpers'
import {
  MECHANIC_CAREER,
  STARTS_LIVE_INCREASE_LEVELS,
  metaPremiumAdsClaimEndpoint,
  pathImages,
  GameType,
  MECHANIC_GAME_PASS,
} from '@/globalVariables'
import ArrowAnimation from '../ArrowAnimation.vue'
import LevelUpTitle from '@/components/LevelUp/LevelUpTitle.vue'
import LevelUpUnlockDiscipline from '@/components/LevelUp/LevelUpUnlockDiscipline.vue'
import LevelUpUnlockMechanic from '@/components/LevelUp/LevelUpUnlockMechanic.vue'
import LevelUpRewards from '@/components/LevelUpRewards.vue'
import VideoAdWeb from '@/components/Premium/Ads/VideoAdWeb.vue'
import { useAdStore } from '@/store/pinia/adStore'
import { useDuelStore } from '@/store/pinia/arena/duelStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { useGamePassStore } from '@/store/pinia/gamePassStore'

import type { Nullable } from '@/interfaces/utils'
import type { PropType } from 'vue'
import type {
  LevelUpDataItem,
  FormattedLevelUpDataItem,
  FormattedLevelUpData,
  LevelUpData,
} from './LevelUp.interface'
import type { PremiumVideoAdsClaimApiResponse } from '@/interfaces/responses/premium/PremiumVideoAdsClaimApiResponse'
import { VideoAdState } from '@/enums/VideoAdStates'

interface ComponentData {
  MECHANIC_GAME_PASS: typeof MECHANIC_GAME_PASS
  pathImages: typeof pathImages
  showUnlockDiscipline: boolean
  showUnlockGamePass: boolean
  unlockedMechanic: Nullable<string>
  unlockedDiscipline: Nullable<string>
  texts: {
    first: string
    second: string
  }
  levels: number[]
  isVideoRewardEarned: boolean
  adCategory: string
  mobileAdPlayed: boolean
  GameType: typeof GameType
  currentGame: string
  isAnimationSkipped: boolean
  isLoadingGamePassRewards: boolean
  isClaimedGamePassRewards: boolean
}

const NEW_TH_LEVEL = 'training_hall_new_level'
const NEW_DISCIPLINE_UNLOCKED = 'discipline_unlocked'
const MECHANIC_UNLOCKED = 'mechanic_unlocked'
const BAGS = 'bags'

const ICONS_MAPPING = {
  [NEW_TH_LEVEL]: 'training_hall',
  [NEW_DISCIPLINE_UNLOCKED]: 'discipline',
  [BAGS]: 'level-up-bags',
  [MECHANIC_CAREER]: 'career-gold',
  [MECHANIC_GAME_PASS]: 'game-pass',
}
const UNLOCKS = [NEW_TH_LEVEL, NEW_DISCIPLINE_UNLOCKED, MECHANIC_UNLOCKED]

export default defineComponent({
  name: 'LevelUp',
  components: {
    LevelUpRewards,
    LevelUpUnlockDiscipline,
    LevelUpUnlockMechanic,
    LevelUpTitle,
    ArrowAnimation,
    VideoAdWeb,
  },
  props: {
    levelUpData: {
      type: Object as PropType<LevelUpData>,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      MECHANIC_GAME_PASS,
      pathImages,
      showUnlockDiscipline: false,
      showUnlockGamePass: false,
      unlockedDiscipline: null,
      unlockedMechanic: null,
      texts: {
        first: 'common.congratulations',
        second: 'common.newLevelReached',
      },
      levels: [12, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75],
      isVideoRewardEarned: false,
      adCategory: '',
      mobileAdPlayed: false,
      GameType,
      currentGame: this.$gameName,
      isAnimationSkipped: false,
      isLoadingGamePassRewards: false,
      isClaimedGamePassRewards: false,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      isMobilePlayingVideo: 'getMobileVideoPlaying',
      isWebVideo: 'getWebVideoPlaying',
      isRewardedAdLoaded: 'getRewardedAdLoaded',
    }),
    ...mapState(useTutorialStore, {
      actualStage: 'getActualStage',
    }),
    ...mapState(useDuelStore, {
      startsCostConfig: 'getStartsCostConfig',
    }),
    ...mapState(useGamePassStore, ['isNoVideoAds']),
    levelData(): FormattedLevelUpData {
      const isNewUnlockedItem = (type: string): boolean => UNLOCKS.includes(type)
      const translateItem = (item: LevelUpDataItem): string => {
        switch (item.type) {
          case NEW_DISCIPLINE_UNLOCKED:
            return this.$translateDiscipline(item.value)
          case NEW_TH_LEVEL:
            return this.$t('map.trainingHallName')
          case MECHANIC_UNLOCKED:
            if (item.value === BAGS) {
              return this.$t('premiumGroup.level_up_bags')
            }
            if (item.value === MECHANIC_GAME_PASS) {
              return this.$t('gamePass.gamePass')
            }
            if (item.value === MECHANIC_CAREER) {
              return this.$t('career.career')
            }
            break

          default:
            return ''
        }
      }

      const translateBottomText = (item: LevelUpDataItem): string => {
        switch (item.type) {
          case MECHANIC_UNLOCKED:
            if (item.value === BAGS) {
              return this.$t('premiumGroup.level_up_bags')
            }
            if (item.value === MECHANIC_GAME_PASS) {
              return this.$t('gamePass.gamePass')
            }
            if (item.value === MECHANIC_CAREER) {
              return this.$t('career.career')
            }
            break
          default:
            return ''
        }
      }

      const translateTitle = (item: LevelUpDataItem): string => {
        switch (item.type) {
          case NEW_DISCIPLINE_UNLOCKED:
            return this.$t('common.newDiscipline')
          case NEW_TH_LEVEL:
            return this.$t('trainingHall.newTrainingHallLevel')
          case MECHANIC_UNLOCKED:
            if (item.value === BAGS) {
              return this.$t('premiumGroup.new_premium_rewards')
            }
            if (item.value === MECHANIC_GAME_PASS) {
              return this.$t('gamePass.newFeature')
            }
            if (item.value === MECHANIC_CAREER) {
              return this.$t('career.career')
            }
            break

          default:
            return ''
        }
      }

      const translateSubtitle = (item: LevelUpDataItem): string => {
        switch (item.type) {
          case NEW_DISCIPLINE_UNLOCKED:
            return this.$translateDiscipline(item.value)
          case NEW_TH_LEVEL:
            return this.$replacePlaceholder(this.$t('minigame.level'), '%s', item.value)
          default:
            return ''
        }
      }

      const formatLevelUpData = (data: LevelUpDataItem[]): FormattedLevelUpDataItem[] => {
        return data.map((item: LevelUpDataItem): FormattedLevelUpDataItem => {
          if (item.type === NEW_DISCIPLINE_UNLOCKED) {
            this.unlockedDiscipline = item.value
          }
          if (item.type === MECHANIC_UNLOCKED) {
            item.parameter = ICONS_MAPPING[item.value]

            if (item.value === MECHANIC_GAME_PASS) {
              this.unlockedMechanic = item.value
            }
          }

          return {
            iconName: item.parameter ?? ICONS_MAPPING[item.type],
            rewardValue: isNewUnlockedItem(item.type) ? translateItem(item) : String(item.value),
            parameterType: String(item.value),
            isUnlock: isNewUnlockedItem(item.type),
            mainTitle: translateTitle(item),
            bottomText: translateBottomText(item),
            category: item.category,
            subtitle: translateSubtitle(item),
            increaseMaxLimit: item.increaseMaxLimit,
            limits: {}, // for now no limits/bonuses will be displayed
          }
        })
      }

      const data: FormattedLevelUpData = {
        base: {
          rewards: null,
          unlocks: null,
        },
        adRewards: null,
      } as FormattedLevelUpData

      if (this.levelUpData.rewards.length) {
        data.base.rewards = formatLevelUpData(this.levelUpData.rewards)
      }
      if (this.levelUpData.unlocks.length) {
        data.base.unlocks = formatLevelUpData(this.levelUpData.unlocks)
      }
      if (this.levelUpData.adRewards.length) {
        data.adRewards = formatLevelUpData(this.levelUpData.adRewards)
      }

      return data
    },
    showAdRewards(): boolean {
      return this.levelUpData.level >= 3 && this.levelData.adRewards?.length > 0
    },
  },
  mounted(): void {
    window.rewardedVideoEarned = this.rewardedVideoEarned
    window.failedToLoadVideoAds = this.rewardedVideoFailed
    window.rewardedVideoEnd = this.rewardedVideoEnd
    this.animateScene()
  },
  created(): void {
    if (this.$isMobile()) {
      this.pauseScene()
    }
    playSound('action-dialog-level-up')
    if (getIsMobileLocalStorage() === 1) this.sendDataToFlutter()
    this.setUserStats()
    this.loadStartsConfig()

    // skip animation on click
    window.addEventListener('click', this.handleSkipAnimationClick)
  },
  beforeUnmount(): void {
    if (this.$isMobile()) {
      this.resumeScene()
    }
    window.removeEventListener('click', this.handleSkipAnimationClick)
  },
  methods: {
    ...mapActions(useAdStore, ['setAdBlockState']),
    ...mapActions(useDuelStore, {
      loadStartsConfig: 'setStartsCosts',
    }),
    ...mapActions(useDisciplineStore, {
      loadDisciplines: 'loadDisciplines',
    }),
    ...mapActions(usePremiumStore, {
      webVideoPlay: 'setWebVideoPlay',
      setVideoPlay: 'setVideoPlay',
      setRewardType: 'setRewardtype',
      setReward: 'setReward',
    }),
    ...mapActions(useUserStore, {
      setUserExperience: 'setUserDataExperience',
      setUserDataTargetExperience: 'setUserDataTargetExperience',
      setUserDataStartingExperience: 'setUserDataStartingExperience',
      setUserDataLevel: 'setUserDataLevel',
    }),
    ...mapActions(useTutorialStore, ['loadTutorial']),
    ...mapActions(usePhaserGameIntegrationStore, ['pauseScene', 'resumeScene']),
    ...mapActions(useResponseTaskStore, {
      hideUserLevelUp: 'hideUserLevelUp',
      loadMechanics: 'loadMechanics',
    }),
    isUnlock(type: string): boolean {
      return type === 'unlocks'
    },
    showCostLive(item: string): boolean {
      if (STARTS_LIVE_INCREASE_LEVELS.includes(this.levelUpData.level)) {
        if (this.levelData?.base['unlocks']) {
          if (this.isUnlock(item)) {
            return true
          }
          return false
        }
        return true
      }
    },
    back(): void {
      this.showUnlockDiscipline = false
      this.showUnlockGamePass = false
      this.$nextTick((): void => {
        this.restartAnimations()
      })
    },
    async close(): Promise<void> {
      this.hideUserLevelUp()
      localStorage.removeItem('userLevelUpToLevel2')

      if (
        this.levelUpData.unlocks.find(
          (item: LevelUpDataItem): boolean => item.type === NEW_DISCIPLINE_UNLOCKED,
        )
      ) {
        this.loadDisciplines()
        this.loadTutorial()
      }

      if (
        this.levelUpData.unlocks.find(
          (item: LevelUpDataItem): boolean => item.type === MECHANIC_UNLOCKED,
        )
      ) {
        this.loadMechanics(true)
      }
    },
    confirm(): void {
      if (this.unlockedMechanic === MECHANIC_GAME_PASS) {
        this.showUnlockGamePass = true
        return
      }
      if (this.unlockedDiscipline) {
        this.loadDisciplines()
        this.showUnlockDiscipline = true
        this.restartAnimations()
        return
      }
      this.close()
    },
    sendDataToFlutter(): void {
      if (this.levelUpData.level === 5) {
        // eslint-disable-next-line no-useless-escape
        sendToFlutter(
          '{\r\n  "event": "analyticsEvent",\r\n  "eventName": "af_level_achieved",\r\n  "af_level": 5\r\n}',
        )
        sendToFlutter(
          '{\r\n  "event": "analyticsEvent",\r\n  "eventName": "af_basic_early_event",\r\n  "af_level": 5\r\n}',
        )
      }
      if (this.levels.includes(this.levelUpData.level)) {
        // eslint-disable-next-line no-useless-escape
        sendToFlutter(
          '{\r\n  "event": "analyticsEvent",\r\n  "eventName": "af_multilevel_achieved",\r\n  "af_level": ' +
            this.levelUpData.level +
            '\r\n}',
        )
        sendToFlutter(
          '{\r\n  "event": "analyticsEvent",\r\n  "eventName": "af_basic_early_event",\r\n  "af_level": ' +
            this.levelUpData.level +
            '\r\n}',
        )
      }
    },
    setUserStats(): void {
      this.setUserExperience(this.levelUpData.EXPERIENCE)
      this.setUserDataTargetExperience(this.levelUpData.TARGET_EXPERIENCE)
      this.setUserDataStartingExperience(this.levelUpData.STARTING_EXPERIENCE)
      this.setUserDataLevel(this.levelUpData.level)
    },
    watchVideo(): void {
      this.adCategory = this.levelData.adRewards[0]?.category
      if (typeof this.adCategory === 'undefined') return

      this.setVideoPlay(true)

      this.watchAds()

      if (!this.$isMobile()) {
        requestWebAd({
          id: 'video-ad-web-default',
          onRequest: (): void => {
            this.webVideoPlay(true)
          },
          onSuccess: (): void => {
            this.webVideoPlay(true)
          },
          onError: (): void => {
            this.webVideoPlay(false)
          },
          onCallbackComplete: (): void => {
            this.rewardedVideoEarned()
            this.webVideoPlay(false)
            this.setReward(true)
            this.setRewardType(this.adCategory)
          },
        })
      }
    },
    async watchAds(): Promise<void> {
      try {
        await this.$axios.put<{}, PremiumVideoAdsClaimApiResponse>(metaPremiumAdsClaimEndpoint, {
          type: this.adCategory,
          state: VideoAdState.Started,
        })
        if (this.$isMobile()) {
          sendToFlutter('{\r\n "event":"playRewarded"\r\n}')
        }
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async rewardedVideoEarned(): Promise<void> {
      try {
        await this.$axios.put<{}, PremiumVideoAdsClaimApiResponse>(metaPremiumAdsClaimEndpoint, {
          type: this.adCategory,
          state: VideoAdState.Finished,
        })
        this.isVideoRewardEarned = true

        if (this.$isMobile()) this.mobileAdPlayed = true
        this.getAds()
        this.setVideoPlay(false)
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async getAds(): Promise<void> {
      if (this.$isMobile()) this.mobileAdPlayed = false
    },
    rewardedVideoFailed(): void {
      this.setAdBlockState(true)
      this.setVideoPlay(false)
    },
    rewardedVideoEnd(): void {
      this.setVideoPlay(false)
    },
    animateScene(): void {
      animateElement(
        this.$refs.animation1,
        'fade-in, zoom-reset',
        '1s',
        800,
        'cubic-bezier(.26,.02,.22,1)',
      )
      animateElement(
        this.$refs.animation1a,
        'fade-in, zoom-reset-max',
        '.7s',
        820,
        'cubic-bezier(.97,0,.51,.99)',
      )
      animateElement(this.$refs.animation2, 'fade-in', '1s', 1000)

      const animatedElements = this.$refs.animation2.querySelectorAll('.reward-box-item')
      animatedElements.forEach((element: HTMLElement, index: number): void => {
        const delay = 1400 + index * 300
        animateElement(element, 'fade-in', '1s', delay)
      })

      animateElement(this.$refs.animation3, 'fade-in', '1s', 2200)

      setTimeout((): void => {
        if (!this.isAnimationSkipped) {
          // assigning a class will start the animation
          this.$refs.animation0.classList.add('animated-element--gradiently-flashed', 'blue-flare')
        }
      }, 750)
    },
    resetAnimateScene(): void {
      resetAnimateElement(this.$refs.animation1)
      resetAnimateElement(this.$refs.animation2)
      const animatedElements = this.$refs.animation2.querySelectorAll('.reward-box-item')
      animatedElements.forEach((element: HTMLElement): void => {
        resetAnimateElement(element)
      })
      resetAnimateElement(this.$refs.animation3)
    },
    restartAnimations(): void {
      this.resetAnimateScene()
      this.animateScene()
    },
    handleSkipAnimationClick(e: MouseEvent): void {
      this.isAnimationSkipped = true
      skipAnimationOnClick(e, this.$el, this.$refs, !!this.levelUpData, [
        ['animation2', '.reward-box-item'],
      ])
    },
    async claimGamePassRewards(): Promise<void> {
      if (!this.isNoVideoAds) return
      if (this.isLoadingGamePassRewards || this.isClaimedGamePassRewards) return

      const adCategory = this.levelData.adRewards[0]?.category
      if (!adCategory) return

      try {
        this.isLoadingGamePassRewards = true
        this.isClaimedGamePassRewards = false
        await this.$axios.put<{}, PremiumVideoAdsClaimApiResponse>(metaPremiumAdsClaimEndpoint, {
          type: adCategory,
          state: VideoAdState.Started,
        })
        this.isClaimedGamePassRewards = true
      } catch (error: unknown) {
        console.error(error)
      } finally {
        this.isLoadingGamePassRewards = false
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/level-up/main.scss';
@import '@/assets/styles/components/level-up/rewards.scss';
@import '@/assets/styles/animations.scss';

.icon-starts {
  background-image: url($path-images + 'components/aboutPopup/arena/icon-live.webp');
  background-size: cover;
  width: 5.625rem;
  height: 5.625rem;
}

.video-footage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // Full screen video setting regardless of its resolution
  object-fit: cover;
  opacity: 0.4;
  z-index: 1;
}
</style>
