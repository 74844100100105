<template>
  <div class="game-pass-header flex justify-between items-center pl-8">
    <div class="flexing">
      <app-icon class="iconer mr-4" icon-name="question" @click="showAboutPopup = true" />
      <p class="text-32 text-texts-standard-default">{{ $t('event.information') }}</p>
      <game-pass-about-popup v-if="showAboutPopup" @close="showAboutPopup = false" />
    </div>
    <div class="game-pass-header-progress h-full">
      <div class="text-36 uppercase text-texts-standard-default pt-2">
        <p v-html="milestoneText" />
      </div>
      <div class="progress-wrapper flex items-end">
        <div class="flex items-end">
          <span class="uppercase text-36 text-texts-standard-default">
            {{ milestoneInfo.actual }}
          </span>
          <app-main-icon :icon-size="56" icon-name="experience" />
        </div>
        <div class="px-4">
          <app-progress-bar
            class="mb-4"
            :bar-width="39.563"
            :bar-height="1.5"
            :actual="milestoneInfo.actual"
            :goal="milestoneInfo.goal"
          />
        </div>
        <div class="flex items-end">
          <span class="uppercase text-36 text-texts-standard-important">
            {{ milestoneInfo.goal }}
          </span>
          <app-main-icon :icon-size="56" icon-name="experience" />
        </div>
      </div>
    </div>
    <game-pass-info-timer
      :time="remainingTime"
      :is-pass-ending="isPassEnding"
      @load-data="$emit('loadData')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { replaceGamePassPlaceholders } from '@/helpers'

import GamePassAboutPopup from './GamePassAboutPopup.vue'
import GamePassInfoTimer from './GamePassInfoTimer.vue'

import { useGamePassStore } from '@/store/pinia/gamePassStore'
import { mapState } from 'pinia'

interface ComponentData {
  showAboutPopup: boolean
  gamePassWarning: boolean
}

interface MilestoneInfo {
  actual: number
  goal: number
  milestone: number
}

export default defineComponent({
  name: 'GamePassHeader',
  components: {
    GamePassAboutPopup,
    GamePassInfoTimer,
  },
  props: {
    milestoneInfo: {
      type: Object as PropType<MilestoneInfo>,
      required: true,
    },
  },
  emits: ['loadData'],
  data(): ComponentData {
    return {
      showAboutPopup: false,
      gamePassWarning: true,
    }
  },
  computed: {
    ...mapState(useGamePassStore, ['remainingTime', 'isPassEnding']),
    milestoneText(): string {
      const replaceNumber = `<span class="text-texts-standard-important">${this.milestoneInfo.milestone}</span>`
      return this.$replacePlaceholder(
        this.$t('gamePass.milestoneNumber'),
        '{number}',
        replaceNumber,
      )
    },
  },
  methods: {
    replaceInText(text: string): string {
      return replaceGamePassPlaceholders(text)
    },
  },
})
</script>

<style lang="scss" scoped>
.game-pass-header {
  background: #00ffff;
  height: 6.125rem;
  position: relative;
  @if ifwsm {
    background: #144a73;
  }
  @if $isSsm {
    background: #22283e;
  }
  &-progress {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    .progress-wrapper {
      position: relative;
      bottom: 1rem;
    }
  }
  &-timer {
    .tooltip {
      right: 2rem;
      top: 0.2rem;
    }
  }
}
</style>
