import { fbId, gtmId, gtmUrl } from '@/globalVariables'

export const addGtmScript = (): void => {
  const script = document.createElement('script')
  const gtmScript = `(function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = '${gtmUrl}/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', '${gtmId}')`
  script.text = `${gtmScript}`
  document.head.appendChild(script)

  const noscript = document.createElement('noscript')
  const iframeTag = document.createElement('iframe')
  iframeTag.src = `${gtmUrl}/ns.html?id=${gtmId}`

  iframeTag.setAttribute('width', '0')
  iframeTag.setAttribute('height', '0')
  iframeTag.setAttribute('style', 'display:none;visibility:hidden')

  noscript.appendChild(iframeTag)
  document.body.appendChild(noscript)

  const meta = document.createElement('meta')
  meta.setAttribute('name', 'facebook-domain-verification')
  meta.setAttribute('content', fbId)
  document.head.appendChild(meta)
}
