import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const tunelTrainPath: PathObjectInterface = {
  mainPath: {
    x: 1154.9999980479483,
    y: 199.25000084564095,
  },
  nextPaths: [
    {
      x: 1303.7499958314006,
      y: 274.2499997280539,
    },
    {
      x: 1446.2499937079854,
      y: 267.9999998211862,
      control1X: 1324.999995514751,
      control1Y: 279.2499996535481,
      control2X: 1378.7499947138137,
      control2Y: 281.7499996162952,
    },
    {
      x: 1788.7499886043377,
      y: 97.50000045448544,
    },
  ],
}
