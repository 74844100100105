import { canOpenBoardGamesEvent } from '@/helpers'
import {
  CREATE_BOARD_GAMES_EVENT_OSRA,
  DESTROY_BOARD_GAMES_EVENT_OSRA,
} from '@/map-phaser-new/constants'
import { SpecificObjectHandler } from '@/map-phaser-new/utils/abstractClasses'
import { BoardGamesEventOsra } from '@/map-phaser-new/models/objects/boardGamesEventOsra'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import type {
  AdditionalConfigsInterface,
  BoardGamesEventOsraConfigInterface,
  ExclamationNotificationConfigInterface,
} from '@/map-phaser-new/interfaces'

export class BoardGamesEventOsraHandler extends SpecificObjectHandler {
  private osraInstance: BoardGamesEventOsra

  constructor() {
    super()
  }

  public async setUp(
    activeScene: Phaser.Scene,
    osraConfig: BoardGamesEventOsraConfigInterface,
    additionalConfigs: AdditionalConfigsInterface,
  ): Promise<void> {
    return new Promise((resolve: CallableFunction): void => {
      this.createOsra(activeScene, osraConfig, additionalConfigs.exclamationNotification)

      const eventEmitter = usePhaserGameIntegrationStore().getPhaserEventEmitter
      this.addOsraActionsToEmitter(
        eventEmitter,
        activeScene,
        osraConfig,
        additionalConfigs.exclamationNotification,
      )

      resolve()
    })
  }

  private createOsra(
    activeScene: Phaser.Scene,
    config: BoardGamesEventOsraConfigInterface,
    notificationConfig: ExclamationNotificationConfigInterface,
  ): void {
    if (this.osraInstance) return
    if (!canOpenBoardGamesEvent()) return

    this.osraInstance = new BoardGamesEventOsra(config.positionX, config.positionY)
    this.osraInstance.addToMap(activeScene, config, notificationConfig)
  }

  private destroyOsra(activeScene: Phaser.Scene): void {
    if (!this.osraInstance) return

    this.osraInstance.removeOsra(activeScene)
    this.osraInstance = null
  }

  private addOsraActionsToEmitter(
    eventEmitter: Phaser.Events.EventEmitter,
    activeScene: Phaser.Scene,
    osraConfig: BoardGamesEventOsraConfigInterface,
    notificationConfig: ExclamationNotificationConfigInterface,
  ): void {
    if (this.osraInstance) return

    eventEmitter.on(
      CREATE_BOARD_GAMES_EVENT_OSRA,
      (): void => {
        this.createOsra(activeScene, osraConfig, notificationConfig)
      },
      this,
    )

    eventEmitter.on(
      DESTROY_BOARD_GAMES_EVENT_OSRA,
      (): void => {
        this.destroyOsra(activeScene)
      },
      this,
    )
  }
}
