import type { BuildingRewardConfigInterface } from '@/map-phaser-new/interfaces'

export const buildingRewards: BuildingRewardConfigInterface = {
  textureKey: 'clubsTooltipsRewards',
  frameKey: 'reward-tooltip-bg',
  rewardDepth: 7,
  backgroundTextureName: 'backgroundReward',
  rewardTextureName: 'rewardReward',
  rewardTextureScale: 0.6,
  rewardTextureOrigin: { x: 0.5, y: 0.9 },
  valueTextureOrigin: { x: 0.5, y: -0.3 },
  backgroundSize: { width: '123px', height: '161px' },
  htmlTextConfig: {
    fontName: 'Roboto Condensed',
    fontSize: '32px',
    fontWeight: 'normal',
    fontStyle: 'italic',
    fontColor: '#fff',
  },
}
