<template>
  <footer
    id="equipment-detail-footer"
    class="equipment-detail-footer flex items-center text-texts-standard-default text-28"
    :class="[justifyClass, { 'equipment-detail-footer--mobile': $isMobile() }]"
  >
    <template
      v-if="
        !equipmentData.premium &&
        !isOffer &&
        ongoingOperation?.itemId !== itemId &&
        ongoingOperation?.parentId !== itemId
      "
    >
      <tippy
        v-if="!fullUpgrade"
        theme="WSM"
        placement="top"
        max-width="42.5rem"
        :on-show="isVisibleTootlip"
      >
        <app-button
          btn-size="md"
          btn-type="primary"
          class="equipment-detail-footer__btn--upgrade btn-xxl"
          :btn-text="$t('button.upgrade')"
          :disabled="!availableUpgrade || !!ongoingOperation"
          @click="showUpgradePopup = true"
        />
        <template #content>
          <p v-if="ongoingOperation">
            {{ $t('equipmentShop.anotherQuestIsActive') }}
          </p>
          <equipment-detail-footer-task-tooltip v-else />
        </template>
      </tippy>
      <div v-else class="maximum text-texts-standard-default text-36 flexing uppercase font-bold">
        <p>{{ $t('equipmentShop.upgradeMaxed') }}</p>
      </div>
    </template>
    <section
      class="equipment-info__wrapper flex items-center"
      :class="{
        'equipment-info--centered': !ongoingOperation && !equipmentData.premium,
      }"
    >
      <info-text
        v-if="getInfoDescription.length && ongoingOperation"
        :text="$t(getInfoDescription)"
      />
      <div v-else class="info-text--custom">
        <span v-if="equipmentData.premium" class="info-text__icon mr-4" />
        <p class="info-text__description text-28 text-center">
          <span>{{ $t(getInfoDescription) }}</span>
          <span v-if="equipmentData.isEventEquip && equipmentData.prolong?.price">
            {{ $t('equipmentShop.premiumInfoExtend') }}
          </span>
        </p>
      </div>
    </section>
    <template v-if="ongoingOperation?.itemId === itemId || ongoingOperation?.parentId === itemId">
      <div class="flexing" :class="$isMobile() ? 'timer-mobile' : 'timer'">
        <p class="text-34 mr-4" :class="getOperationDescription.class">
          {{ $t(getOperationDescription.text) }}
        </p>
        <app-icon icon-name="time" />
        <p class="text-texts-standard-default text-36 not-italic">
          <vue-countdown
            v-slot="{ days, hours, minutes, seconds }"
            :time="ongoingOperation.time"
            @end="$emit('loadItemDetail', true)"
          >
            <p class="text-texts-standard-default font-bold pl-2">
              {{ formatTime(days, hours, minutes, seconds) }}
            </p>
          </vue-countdown>
        </p>
        <div />
      </div>
    </template>

    <div
      v-if="isOffer && !ongoingOperation && !equipmentData.premium"
      class="price text-texts-standard-default flexing text-34"
    >
      <p>{{ $t('common.price') }}:</p>
      <p class="font-bold text-36 ml-4">
        {{ $filters.$formatNumber(currentLevel.delivery.prices[EQUIPMENT_PRICE_CASH]) }}
      </p>
      <app-main-icon :icon-size="48" icon-name="money" />
    </div>
    <arrow-animation
      v-if="isOffer"
      position="right"
      border="button"
      :tutorial="isPurchaseEquipment"
    >
      <app-multi-button1
        id="buy-equipment"
        v-tippy="{
          theme: 'WSM',
          content: buyTooltipText,
          placement: 'top',
          textAlign: 'center',
        }"
        btn-theme="confirm"
        :disabled="!canBuy || hasOperation"
        :btn-text="$t('button.purchase')"
        @click="buyEquipmentOffer"
      >
        <template #rightPart>
          <span class="flexing">
            <app-icon icon-name="time" class="mr-2" />
            <p class="text-texts-standard-default text-36">
              <vue-countdown
                v-slot="{ days, hours, minutes, seconds }"
                :time="currentLevel.delivery.time"
                :auto-start="false"
              >
                {{ formatTime(days, hours, minutes, seconds) }}
              </vue-countdown>
            </p>
          </span>
        </template>
      </app-multi-button1>
    </arrow-animation>
    <template v-else>
      <arrow-animation
        v-if="ongoingOperation?.itemId === itemId || ongoingOperation?.parentId === itemId"
        position="right"
        border="button"
        :tutorial="isClickSpeedUpEquipment"
      >
        <app-multi-button1
          btn-id="speedup-equipment"
          btn-type="credit"
          :btn-text="$t('common.speedUp')"
          :btn-count="ongoingOperation.price"
          :disabled="!shopItem?.operation"
          :popup-data="
            $filters.$confirmPopupData({
              title: $t('equipmentShop.questSpeedUp'),
              btnId: 'btn-equiped',
              btnText: $t('common.speedUp'),
              btnValue: ongoingOperation.price,
            })
          "
          class="speedup-equipment__button"
          @action="speedupOperation"
          @click="isTutorial ? (showConfirm = true) : ''"
        >
          <template #popupSlot>
            <div class="container-upgrade text-36 text-texts-standard-default">
              <p>
                {{ $t(OPERATION_MAPPING[ongoingOperation.type]) }}
              </p>
              <p class="text-texts-standard-important">
                {{ $t(`equipmentShop.${ongoingOperation.itemName}`) }}
              </p>
            </div>
          </template>
        </app-multi-button1>
      </arrow-animation>
      <arrow-animation
        v-else-if="!equipmentData.equipped"
        position="right"
        border="button"
        :tutorial="isConfirmSpeedUpBuyingEquipment"
      >
        <app-button
          v-if="equipmentData.premium?.locked"
          btn-id="btn-equiped"
          btn-size="md"
          btn-type="credit"
          :btn-text="$t('equipmentShop.premiumButton')"
          class="btn-xxl"
          @click="$router.push({ name: $getWebView('PremiumOffers') })"
        />
        <app-button
          v-else
          btn-id="btn-equiped"
          btn-size="md"
          btn-type="secondary"
          :btn-text="$t('equipmentShop.equip')"
          class="btn-xxl"
          :disabled="equipmentData.premium?.time <= 0"
          @click="showEquipPopUp"
        />
      </arrow-animation>
      <div
        v-else
        class="flex items-center justify-end"
        :style="$isMobile() ? 'equiped-mobile' : 'equiped'"
      >
        <p
          class="uppercase text-36 mx-2"
          :class="{
            'text-texts-standard-important': $isWsm,
            'text-texts-standard-upgrade': $isSsm,
          }"
        >
          {{ $t('equipmentShop.equipped') }}
        </p>
        <app-icon icon-name="done-md" />
      </div>
    </template>
    <popup-window
      v-if="showUpgradePopup"
      popup-type="info"
      :popup-title="$t('equipmentShop.upgradeEquipment')"
      @close="showUpgradePopup = false"
    >
      <equipment-upgrade-change
        type="upgrade"
        :current-level="currentLevel"
        :next-level="nextLevel"
        :levels="equipmentData.levels"
        :equipped="equipmentData.equipped"
        :discipline-id="disciplineId"
        @upgrade=";(showUpgradePopup = false), upgrade()"
      />
    </popup-window>

    <info-popup
      v-if="showChangePopup"
      :popup-title="$t('equipmentShop.changeEquipment')"
      @close="showChangePopup = false"
    >
      <equipment-upgrade-change
        type="change"
        :current-level="currentLevel"
        :next-level="nextLevel"
        :levels="equipmentData.levels"
        :equipped="equipmentData.equipped"
        :damaged="equipmentData.damaged"
        :equipped-item="equippedItem"
        :discipline-id="disciplineId"
        @equip="hideEquipPopup"
      />
    </info-popup>
    <!-- TODO popup pre tutorial pretoze confirm popup z multi buttonu nam tutorial killuje NO ONE KNOW WHY-->
    <popup-window
      v-if="showConfirm && isTutorial"
      popup-type="confirm"
      :popup-data="
        $filters.$confirmPopupData({
          title: $t('equipmentShop.questSpeedUp'),
          btnId: 'btn-equiped',
          btnText: $t('common.speedUp'),
          btnValue: ongoingOperation.price,
        })
      "
      :hide-close="isTutorial"
      @action="speedupOperation"
      @close="showConfirm = false"
    >
      <div class="container-upgrade text-36 text-texts-standard-default">
        <p>
          {{ $t(OPERATION_MAPPING[ongoingOperation.type]) }}
        </p>
        <p class="text-texts-standard-important">
          {{ $t(`equipmentShop.${ongoingOperation.itemName}`) }}
        </p>
      </div>
    </popup-window>
  </footer>
</template>

<script lang="ts">
import ArrowAnimation from '@/components/ArrowAnimation.vue'
import EquipmentDetailFooterTaskTooltip from '@/components/Equipment/EquipmentDetailFooterTaskTooltip.vue'
import EquipmentUpgradeChange from '@/components/Equipment/EquipmentUpgradeChange.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import InfoText from '@/components/InfoText.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import {
  EQUIPMENT_PRICE_CASH,
  LEVEL,
  OPERATION_DELIVERY,
  OPERATION_REPAIR,
  OPERATION_UPGRADE,
  UPGRADE_PRICE_GEMS,
} from '@/globalVariables'
import { formatTime } from '@/helpers'
import type {
  FormattedEquipmentData,
  FormattedEquippedItem,
  FormattedLevel,
  OngoingOperation,
} from '@/interfaces/Equipment'
import { useEquipmentStore } from '@/store/pinia/equipment//equipmentStore'
import { useEquipmentSpeedUpStore } from '@/store/pinia/equipment/speedUpStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import type { Nullable } from '@/interfaces/utils'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { CLEAR_AND_HIDE_TIMER, SHOP } from '@/map-phaser-new/constants/common'

const EQUIPMENT_DELIVERY = 'equipment_delivery'

const PRICE_MAPPING = {
  [EQUIPMENT_PRICE_CASH]: 'money',
  [UPGRADE_PRICE_GEMS]: 'gems',
}

const OPERATION_MAPPING = {
  [OPERATION_REPAIR]: 'equipmentShop.repairSpeedUpConfirm',
  [OPERATION_UPGRADE]: 'equipmentShop.upgradeSpeedUpConfirm',
  [OPERATION_DELIVERY]: 'equipmentShop.deliverySpeedUpConfirm',
}

interface OperationDescription {
  text: string
  class: string
}

interface ComponentData {
  OPERATION_MAPPING: typeof OPERATION_MAPPING
  OPERATION_UPGRADE: typeof OPERATION_UPGRADE
  EQUIPMENT_PRICE_CASH: typeof EQUIPMENT_PRICE_CASH
  EQUIPMENT_DELIVERY: typeof EQUIPMENT_DELIVERY
  equiped: boolean
  showUpgradePopup: boolean
  showChangePopup: boolean
  showConfirm: boolean
}

export default defineComponent({
  name: 'EquipmentDetailFooter',
  components: {
    EquipmentUpgradeChange,
    EquipmentDetailFooterTaskTooltip,
    InfoPopup,
    InfoText,
    PopupWindow,
    ArrowAnimation,
  },
  props: {
    equipmentData: {
      type: Object as PropType<Nullable<FormattedEquipmentData>>,
      default: () => null,
    },
    isOffer: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: Number,
      required: true,
    },
    currentLevel: {
      type: Object as PropType<Nullable<FormattedLevel>>,
      default: () => null,
    },
    equippedItem: {
      type: Object as PropType<Nullable<FormattedEquippedItem>>,
      default: () => null,
    },
    nextLevel: {
      type: Object as PropType<Nullable<FormattedLevel>>,
      default: () => null,
    },
    ongoingOperation: {
      type: Object as PropType<OngoingOperation>,
      default: () => null,
    },
    disciplineId: {
      type: Number,
      required: true,
    },
  },
  emits: ['loadItemDetail', 'setOperation', 'setItemId', 'setEquipped'],
  data(): ComponentData {
    return {
      OPERATION_MAPPING,
      OPERATION_UPGRADE,
      EQUIPMENT_PRICE_CASH,
      EQUIPMENT_DELIVERY,
      equiped: false,
      showUpgradePopup: false,
      showChangePopup: false,
      showConfirm: false,
    }
  },
  computed: {
    ...mapState(usePhaserGameIntegrationStore, { phaserEmitter: 'getPhaserEventEmitter' }),

    ...mapState(useTutorialStore, {
      actualStage: 'getActualStage',
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useEquipmentStore, {
      getTaskUnlockInfo: 'getTaskUnlockInfo',
      getSpecificEquipmentOffer: 'getSpecificEquipmentOffer',
      shopItem: 'getShopItem',
    }),
    ...mapState(useResponseTaskStore, {
      gems: 'getGems',
      money: 'getMoney',
    }),
    justifyClass(): string {
      return this.isOffer && !this.ongoingOperation && !this.equipmentData.premium
        ? 'justify-end'
        : 'justify-between'
    },
    canBuy(): boolean {
      const currency = Object.keys(this.currentLevel.delivery.prices)[0]
      return this.enoughSources(currency, this.currentLevel.delivery.prices[currency])
    },
    getInfoDescription(): string {
      if (this.equipmentData.premium) return 'equipmentShop.premiumInfo3'
      if (this.isOffer || this.fullUpgrade) return ''
      if (this.ongoingOperation)
        return this.ongoingOperation?.itemId === this.itemId ||
          this.ongoingOperation?.parentId === this.itemId
          ? 'equipmentShop.itemNotAvailable'
          : ''

      return 'equipmentShop.upgradeInfo'
    },
    getOperationDescription(): OperationDescription {
      switch (this.ongoingOperation.type) {
        case EQUIPMENT_DELIVERY:
          return {
            text: 'equipmentShop.delivering',
            class: '',
          }
        case OPERATION_REPAIR:
          return {
            text: 'equipmentShop.repairing',
            class: 'text-texts-standard-positive',
          }
        case OPERATION_UPGRADE:
          return {
            text: 'equipmentShop.upgrading',
            class: 'text-texts-standard-important',
          }
        default:
          return {
            text: '',
            class: '',
          }
      }
    },
    fullUpgrade(): boolean {
      return this.currentLevel.level === this.equipmentData.levels.length
    },
    availableUpgrade(): boolean {
      return this.currentLevel.level < this.equipmentData.equipmentLevelsAvailable
    },
    buyTooltipText(): Nullable<string> {
      if (this.canBuy && !this.hasOperation) return null

      return this.$t(
        this.hasOperation ? 'equipmentShop.anotherQuestIsActive' : 'common.notEnoughMoney',
      )
    },
    isPurchaseEquipment(): boolean {
      return this.actualStage?.name === 'purchaseEquipment'
    },
    isClickSpeedUpEquipment(): boolean {
      return this.actualStage?.name === 'clickSpeedUpEquipment'
    },
    isConfirmSpeedUpBuyingEquipment(): boolean {
      return this.actualStage?.name === 'confirmSpeedUpBuyingEquipment'
    },
    hasOperation(): boolean {
      return !!this.getSpecificEquipmentOffer.operation
    },
  },
  methods: {
    ...mapActions(useEquipmentSpeedUpStore, ['repairSpeedup', 'upgradeSpeedup', 'offerSpeedup']),
    ...mapActions(useEquipmentStore, ['equipItem', 'upgradeItem', 'buyOffer']),
    formatTime,
    enoughSources(currency: string, price: number): boolean {
      return PRICE_MAPPING[currency]
        ? this[PRICE_MAPPING[currency]] && this[PRICE_MAPPING[currency]].value >= price
        : false
    },
    async speedupOperation(): Promise<void> {
      const id = this.shopItem.operation.offer?.id ?? this.shopItem.operation.item.id
      switch (this.ongoingOperation.type) {
        case OPERATION_REPAIR:
          await this.repairSpeedup(String(id))
          break
        case OPERATION_UPGRADE:
          await this.upgradeSpeedup({
            itemId: +this.shopItem.operation.parent_item_id,
            addonId: this.shopItem.operation.item.id,
          })
          break
        case OPERATION_DELIVERY:
          await this.offerSpeedup(String(id))
          break
      }

      this.$emit('loadItemDetail')
      this.phaserEmitter?.emit(CLEAR_AND_HIDE_TIMER, SHOP)
    },
    isVisibleTootlip(): boolean {
      return !this.availableUpgrade || !!this.ongoingOperation
    },
    async upgrade(): Promise<void> {
      this.$emit(
        'setOperation',
        this.itemId,
        this.nextLevel.delivery.time,
        OPERATION_UPGRADE,
        this.nextLevel.delivery.speedup,
        this.equipmentData.name,
      )

      await this.upgradeItem({
        itemId: this.itemId,
      })
      this.$emit('loadItemDetail')
    },
    async buyEquipmentOffer(): Promise<void> {
      if (!this.canBuy || this.ongoingOperation || this.hasOperation) return
      await this.buyOffer(this.itemId)
      this.setItem()
    },
    showEquipPopUp(): void {
      if (this.equippedItem && LEVEL in this.equippedItem) {
        this.showChangePopup = true
        return
      }
      this.equip()
    },
    hideEquipPopup(): void {
      this.equip()
      this.showChangePopup = false
    },
    async equip(): Promise<void> {
      await this.equipItem(this.itemId)
      this.$emit('setEquipped')
    },
    setItem(): void {
      this.$emit('setItemId')

      this.$router.replace({
        params: { id: this.equipmentData.id },
        query: { offer: 0 },
      })

      this.$emit('loadItemDetail')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/icons.scss';

.equipment-detail-footer {
  width: 100%;
  height: 5rem;
  margin-top: 2.125rem;

  .info-text--custom {
    display: flex;
    flex-direction: row;
    align-items: center;

    .info-text {
      &__icon {
        display: flex;
        background: url($path-images + 'sprites/icons/ICO_INFO_ROUNDED_56.avif') center no-repeat;
        background-size: contain;
        width: 3.25rem;
        height: 3.25rem;
      }
      &__description {
        max-width: 58.625rem;
        line-height: normal;
      }
    }
  }

  .equipment-info {
    &__wrapper {
      flex: 1;
      padding-right: 1rem;
    }

    &--centered {
      justify-content: center;
      padding-right: 0;
    }
  }

  .speedup-equipment__button {
    width: 25.688rem;
  }

  .price {
    margin: 0 2rem;
  }

  .btn-xxl {
    width: 30.688rem;
  }

  #speedup-equipment {
    //height: 5rem;
    position: relative;

    &:active {
      left: -0.063rem;
    }
  }

  .maximum {
    width: 32.4375rem;
    height: 4.375rem;
    position: relative;
    @if $isWsm {
      background: linear-gradient(
        to left,
        rgba(210, 148, 32, 0),
        #be9949,
        #cb9f3f,
        #cb9f3f,
        rgba(255, 232, 142, 0)
      );
    }
    @if $isSsm {
      background: linear-gradient(
        to right,
        rgba(209, 192, 118, 0.01),
        rgba(208, 188, 114, 0.7),
        #e0c572,
        #c7a655,
        rgba(169, 134, 53, 0.7),
        rgba(164, 126, 40, 0.01)
      );
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.125rem;
      @if $isWsm {
        background: linear-gradient(90deg, transparent, #fff, transparent);
      }
      @if $isSsm {
        background: linear-gradient(
          to right,
          transparent,
          rgba(244, 233, 167, 0.7),
          rgba(244, 233, 167, 1),
          rgba(244, 233, 167, 0.7),
          transparent
        );
      }
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  }

  .equiped {
    width: 32.4375rem;
  }

  .equiped > .equiped-done {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__btn {
    &--upgrade {
      margin: 0 1.125rem;
    }
  }

  &--mobile {
    .equipment-detail-footer__btn {
      &--upgrade {
        margin: 0;
      }
    }
  }
}

.container-text {
  height: 10rem;

  .popup-red-text {
    margin-top: 2rem;
    color: theme('colors.texts.standard.danger');
  }
}

.container-upgrade {
  height: 10rem;

  p:nth-child(2) {
    margin-top: 2rem;
  }
}

.arrow-wrapper {
  height: 4.375rem;

  #buy-equipment {
    margin-left: -0.063rem;
  }
}

.equipment-detail-footer-task-tooltip {
  header {
    .gray-strip {
      width: 90%;
      height: 0.094rem;
      margin: 1rem auto;
    }
  }
}

.timer {
  justify-content: flex-end;
  margin-right: 4rem;
}

.timer-mobile {
  width: auto;
  margin-right: 4rem;
}

.equipped-mobile {
  width: auto;
}
</style>
