<template>
  <div class="arena-footer-benefits flexing">
    <section v-for="(slot, slotId) in benefitsSlots" :key="slotId">
      <use-benefit-button
        btn-size="sm"
        :source="ARENA"
        :benefit-usage="slot ? getBenefitUsage : undefined"
        :benefit-usage-live="slot ? getBenefitsUsageLive : undefined"
        :slot-data="slot ? slot : undefined"
        :slot-id="slot ? slotId.toString() : undefined"
        @click="showSelectBenefitPopup = true"
        @reload-data="$emit('loadSpecificDiscipline')"
      />
    </section>
    <section v-if="!hasMechanic(MECHANIC_BENEFIT)" class="flexing">
      <use-benefit-button
        v-for="int in 2"
        :key="int"
        :lock-icon="true"
        :source="ARENA"
        btn-size="sm"
      />
    </section>
  </div>

  <info-popup
    v-if="showSelectBenefitPopup"
    :popup-title="$t('benefits.buttonActiveBenefit')"
    @close="showSelectBenefitPopup = false"
  >
    <select-benefit
      :allowed-benefit-types="allowedBenefitTypes"
      :required-benefits="requiredBenefits"
      :current-slots="getBenefitSlots()"
      @close-select-popup="closeSelectAndRefreshSlots"
      @load-opponents="$emit('loadOpponents')"
    />
  </info-popup>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { ARENA, MECHANIC_BENEFIT, Multiplier } from '@/globalVariables'
import SelectBenefit from '@/components/Benefits/SelectBenefit.vue'
import UseBenefitButton from '@/components/Button/UseBenefitButton.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { useDuelStore } from '@/store/pinia/arena/duelStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { ActiveBenefitSlots, BenefitSlot } from '@/interfaces/Benefits'

interface ComponentData {
  ARENA: typeof ARENA
  MECHANIC_BENEFIT: typeof MECHANIC_BENEFIT
  showSelectBenefitPopup: boolean
}

export default defineComponent({
  name: 'ArenaCompetitionsFooterBenefits',
  components: {
    InfoPopup,
    SelectBenefit,
    UseBenefitButton,
  },
  props: {
    allowedBenefitTypes: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    benefitsSlots: {
      type: Object as PropType<Nullable<BenefitSlot['slots']>>,
      default: () => null,
    },
    requiredBenefits: {
      type: Number,
      default: () => 1,
    },
  },
  emits: ['loadOpponents', 'loadSpecificDiscipline'],
  data(): ComponentData {
    return {
      ARENA,
      MECHANIC_BENEFIT,
      showSelectBenefitPopup: false,
    }
  },
  computed: {
    ...mapState(useDuelStore, {
      startsCostConfig: 'getStartsCostConfig',
    }),
    ...mapState(useUserStore, {
      activeMultiplicator: 'getActiveMultiplicator',
    }),
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
    }),
    getBenefitUsage(): number {
      return this.startsCostConfig.base * this.activeMultiplicator(Multiplier.Arena).value
    },
    getBenefitsUsageLive(): number {
      return this.startsCostConfig.live * this.activeMultiplicator(Multiplier.Arena).value
    },
  },
  methods: {
    getBenefitSlots(): ActiveBenefitSlots {
      const slotsRaw = this.benefitsSlots
      const slots = {}
      for (const slotId in slotsRaw) {
        slots[slotId] = slotsRaw[slotId] ? slotsRaw[slotId].benefit_gdd_id : 0
      }
      return slots
    },
    async closeSelectAndRefreshSlots(): Promise<void> {
      this.showSelectBenefitPopup = false
    },
  },
})
</script>
