import type Country from '@/interfaces/Country'

interface InputCountries {
  country: {
    [key: string]: string
  }
}

export const formatCountries = (countries: InputCountries): Country[] => {
  if (countries.country == null) return []
  const data: Country[] = []

  Object.entries(countries.country).forEach(([key, value]: [string, string]): void => {
    data.push({
      value: key,
      name: value,
      icon: `flags/102x68/${key}.avif`,
      abbr: key,
    })
  })
  return data.sort((a: Country, b: Country): number => a.name.localeCompare(b.name))
}
