<template>
  <section v-if="eventEnded" class="event-message-info flexing w-full relative">
    <p class="text-60 gradient-text-light font-bold uppercase">
      {{ $te('eventEnded') }}
    </p>
  </section>
  <section v-else-if="inRecalculation" class="event-message-info flexing flex-col w-full relative">
    <template v-if="namespace === EventType.AutumnFair">
      <p class="text-40 gradient-text-light font-bold">
        {{
          $replacePlaceholder(
            $te('calculationInProgress'),
            '{time}',
            getFormattedTimeFromHMS(timer),
          )
        }}
      </p>
    </template>
    <template v-else>
      <p class="text-60 text-texts-standard-default font-bold uppercase">
        {{ $te('eventRecalculation') }}
      </p>
      <p v-if="timer" class="text-50 gradient-text-light">
        {{
          $replacePlaceholder($te('nextRoundStartsIn'), '{time}', getFormattedTimeFromHMS(timer))
        }}
      </p>
    </template>
  </section>
  <section v-else-if="notStarted" class="event-message-info flexing flex-col w-full relative">
    <p v-if="timer" class="text-60 text-texts-standard-default font-bold uppercase">
      {{ $replacePlaceholder($te('playoffStartsAt'), '{time}', getFormattedTimeFromHMS(timer)) }}
    </p>
  </section>
  <section v-else-if="inProgress" class="event-message-info flexing w-full relative">
    <p class="text-60 gradient-text-light font-bold uppercase">
      {{ $te('playoffQualificationOngoing') }}
    </p>
  </section>
  <section v-else-if="eliminated" class="event-message-error flexing w-full relative">
    <p class="text-60 gradient-text-red font-bold uppercase">
      {{ $te('playoffEliminated') }}
    </p>
  </section>
  <section v-else-if="mastersNotStarted" class="event-message-info flexing w-full relative">
    <p class="text-50 gradient-text-light">
      {{ $te('rankingNotAvailable') }}
    </p>
  </section>
</template>

<script lang="ts">
import { getFormattedTimeFromHMS } from '@/helpers'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { EventType } from '@/interfaces/events/EventInfo'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  EventType: typeof EventType
}

export default defineComponent({
  name: 'EventMessage',
  props: {
    eventEnded: {
      type: Boolean,
      default: false,
    },
    timer: {
      type: String,
      default: '',
    },
    inRecalculation: {
      type: Boolean,
      default: false,
    },
    notStarted: {
      type: Boolean,
      default: false,
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
    eliminated: {
      type: Boolean,
      default: false,
    },
    mastersNotStarted: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      EventType,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
  },
  methods: {
    getFormattedTimeFromHMS,
  },
})
</script>

<style lang="scss" scoped>
.event-message-info {
  height: 12.0625rem;

  @if $isWsm {
    background-image: linear-gradient(to right, transparent, #134f78, transparent);
  }

  @if $isSsm {
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(19, 20, 40, 0.7) 10%,
      rgba(60, 62, 97, 0.9) 50%,
      rgba(19, 20, 40, 0.7) 90%,
      transparent 100%
    );
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 80%;
    height: 0.1875rem;

    @if $isWsm {
      background-image: linear-gradient(to right, transparent, #156aa5, transparent);
    }

    @if $isSsm {
      background-image: linear-gradient(
        to right,
        transparent 0%,
        rgba(82, 84, 119, 1) 30%,
        rgba(82, 84, 119, 1) 70%,
        transparent 100%
      );
    }
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

.event-message-error {
  height: 18.8125rem;

  @if $isWsm {
    background-image: linear-gradient(to right, transparent, #892525, transparent);
  }

  @if $isSsm {
    background-image: linear-gradient(
      to right,
      rgba(111, 20, 1, 0.2) 0%,
      rgba(111, 20, 1, 0.9) 50%,
      rgba(111, 20, 1, 0.2) 100%
    );
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 80%;
    height: 0.1875rem;

    @if $isWsm {
      background-image: linear-gradient(to right, transparent, #cf3636, transparent);
    }

    @if $isSsm {
      background-image: linear-gradient(
        to right,
        transparent 0%,
        rgba(157, 30, 5, 1) 30%,
        rgba(157, 30, 5, 1) 70%,
        transparent 100%
      );
    }
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}
</style>
