<template>
  <div class="premium-gems app-page-wrapper flex flex-col absolute">
    <premium-menu @select-country="selectCountry" />
    <section class="w-full h-full safe-area">
      <main v-if="!isContentLoading" class="premium-gems-main flex items-center m-auto">
        <app-scrollbar
          scroll="x"
          slide="x"
          width="100%"
          height="42.8125rem"
          class="flex items-center"
          :arrows="true"
          :scroll-arrows-safe-zone-indented="true"
        >
          <section ref="animation1" class="premium-gems-main-small flex flex-col">
            <div ref="animation1a" class="spin-border spin-border--indigo">
              <premium-gems-free-pack
                class="premium-gems-small-item animated-element--faded"
                @reload-packs="loadData"
              />
            </div>
            <premium-gems-small-pack
              v-for="(box, index) in getSmallPacks"
              :key="index"
              :package-data="box"
              class="premium-gems-small-item animated-element--faded"
              @claim-reward="claimPackReward"
              @buy="buyPack(box)"
              @reload-packs="loadData"
            />
          </section>
          <section ref="animation2" class="premium-gems-main-big flex">
            <premium-gems-big-pack
              v-for="(box, index) in getBigPacks"
              :key="index"
              :package-data="box"
              :index="index"
              class="premium-gems-big-item animated-element--faded"
              @buy="buyPack(box)"
            />
          </section>
        </app-scrollbar>
      </main>

      <component-loading height="71%" :is-loading="isContentLoading" />
      <gems-popup v-if="gems" @close="gems = false" />
      <payment-terminal
        v-if="terminal"
        :item-id="selectedPackId"
        :is-gems="true"
        @close="terminal = false"
      />
      <video-ad-web v-show="isWebVideo" @hide-video="webVideoPlay(false)" />
    </section>
  </div>
</template>

<script lang="ts">
import GemsPopup from '@/components/Popup/Premium/GemsPopup.vue'
import PaymentTerminal from '@/components/Popup/Premium/PaymentTerminal.vue'
import VideoAdWeb from '@/components/Premium/Ads/VideoAdWeb.vue'
import PremiumGemsBigPack from '@/components/Premium/GemsPack/PremiumGemsBigPack.vue'
import PremiumGemsFreePack from '@/components/Premium/GemsPack/PremiumGemsFreePack.vue'
import PremiumGemsSmallPack from '@/components/Premium/GemsPack/PremiumGemsSmallPack.vue'
import PremiumMenu from '@/components/Premium/PremiumMenu.vue'
import {
  metaPremiumExchangeEndpoint,
  metaPremiumPackEndpoint,
  metaPremiumPacksEndpoint,
  metaPremiumPayEndpoint,
  metaPremiumRetGemClaimEndpoint,
} from '@/globalVariables'
import { getIsMobileLocalStorage, playSound, sendToFlutter, animateElement } from '@/helpers'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type PremiumOfferApiResponse from '@/interfaces/responses/premium/PremiumOfferApiResponse'
import type { ExchangeRate } from '@/interfaces/premium/ExchangeRate'
import type { Offer, OfferParameter, Property, Stat } from '@/interfaces/premium/Offer'
import type { RouteLocationNormalized } from 'vue-router'
import type { GemsPack } from '@/interfaces/premium/GemsPack'
import type PremiumPackApiResponse from '@/interfaces/responses/premium/PremiumPackApiResponse'
import type PremiumPayApiResponse from '@/interfaces/responses/premium/PremiumPayApiResponse'

enum PackProperty {
  FIRST_TIME_GEM_BONUS = 'first_time_gem_bonus',
  BASE_GEMS = 'base_gems',
  QUANTITY_GEM_BONUS = 'quantity_gem_bonus',
}

enum ParameterName {
  IMAGE_NAME = 'image_name',
  PACK_NAME = 'pack_name',
  GEMS_TOTAL = 'gems_total',
  GEM_BONUS_PERCENTAGE = 'gem_bonus_percentage',
  GEM_BONUS = 'gem_bonus',
  GEMS_DAILY = 'gems_daily',
  RETENTION_COUNT = 'retention_count',
  GEM_RETENTION = 'gem_retention',
}

enum StatName {
  NEXT_CLAIM = 'next_claim',
  RETENTION_COUNT = 'retention_count',
}

interface MetaPremiumExchangeResponse {
  exchange_rate: number
  currency: string
}

interface ComponentData {
  sendToFlutter: typeof sendToFlutter
  gems: boolean
  smallPacksFirst: boolean
  smallPacks: GemsPack[]
  bigPacks: GemsPack[]
  terminal: boolean
  selectedPackId: string
  exchangeRate: number
  altCurrency: string
  platform: string
  selectedCountry: string
  showPaymentFailedPopup: boolean
}

export default defineComponent({
  name: 'PremiumGems',
  components: {
    PremiumMenu,
    PremiumGemsSmallPack,
    PremiumGemsFreePack,
    PremiumGemsBigPack,
    GemsPopup,
    PaymentTerminal,
    VideoAdWeb,
  },
  data(): ComponentData {
    return {
      sendToFlutter,
      gems: false,
      smallPacksFirst: true,
      smallPacks: [],
      bigPacks: [],
      terminal: false,
      selectedPackId: '',
      exchangeRate: null,
      altCurrency: '',
      platform: 'web',
      selectedCountry: '',
      showPaymentFailedPopup: false,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      isWebVideo: 'getWebVideoPlaying',
    }),
    ...mapState(useUserStore, {
      usersCountryPayment: 'getCountryPayment',
      userLanguage: 'getLanguage',
    }),
    ...mapState(useResponseTaskStore, {
      getForceRate: 'getForceRate',
      formatForceRate: 'formatForceRate',
    }),
    languageCode(): string {
      return this.userLanguage === 'sk' || this.userLanguage === 'cs' ? 'sk' : 'en'
    },
    getSmallPacks(): GemsPack[] {
      return this.smallPacks
    },
    getBigPacks(): GemsPack[] {
      return this.bigPacks
    },
    isContentLoading(): boolean {
      return this.smallPacks.length === 0 && this.bigPacks.length === 0
    },
    isMobileLocalStorage(): boolean {
      return getIsMobileLocalStorage() === 1
    },
  },
  watch: {
    $route(route: RouteLocationNormalized): void {
      if (route.query.reloadData) {
        this.loadData()
        if (route.query.reloadData) {
          this.$router.replace({
            ...this.$router.currentRoute,
            query: {
              reloadData: undefined,
            },
          })
        }
      }
    },
    isContentLoading(loading: boolean): void {
      if (!loading) {
        this.$nextTick((): void => {
          this.animateScene()
        })
      }
    },
  },
  created(): void {
    this.selectedCountry = localStorage.getItem('country_payment') ?? this.usersCountryPayment
    this.loadData()
    if (this.$route.params.pack_id && this.$route.params.res !== 'fail') {
      this.showPaymentSuccess()
    } else if (this.$route.params.res === 'fail') {
      this.setPaymentFailed(true)
    }
    playSound('building-premium-shop')
  },
  methods: {
    ...mapActions(usePremiumStore, {
      webVideoPlay: 'setWebVideoPlay',
    }),
    ...mapActions(useResponseTaskStore, {
      showPaymentLoading: 'showPaymentLoading',
      showPaymentMessage: 'showPaymentMessage',
      setPaymentFailed: 'setPaymentFailed',
    }),
    getPrice(price: number): number {
      if (!price) {
        return 0
      }
      return Number(Number(price).toFixed(2))
    },
    getForeignPriceTag(newCurrency: string, price: number): string {
      price = this.getPrice(price)
      return this.altCurrency && newCurrency !== this.altCurrency
        ? (this.getPrice(price) * this.exchangeRate).toFixed(2) + ' ' + this.altCurrency
        : ''
    },
    mapPackData(pack: Offer): GemsPack {
      const packProperties = pack.properties as Property[]
      const packParameters = pack.parameters
      const firstTimeBonus =
        parseInt(
          packProperties?.find(
            (parameter: Property): boolean => parameter.name === PackProperty.FIRST_TIME_GEM_BONUS,
          )?.value as string,
        ) || 0
      const reward =
        parseInt(
          packProperties?.find(
            (parameter: Property): boolean => parameter.name === PackProperty.BASE_GEMS,
          )?.value as string,
        ) || 0
      const bonus =
        parseInt(
          packProperties?.find(
            (parameter: Property): boolean => parameter.name === PackProperty.QUANTITY_GEM_BONUS,
          )?.value as string,
        ) || 0

      const packData = {
        storeId: pack.store_id,
        imageName:
          packParameters?.find(
            (parameter: OfferParameter): boolean => parameter.name === ParameterName.IMAGE_NAME,
          )?.value ?? '',
        packName:
          (packProperties?.find(
            (parameter: Property): boolean => parameter.name === ParameterName.PACK_NAME,
          )?.value as string) ?? '',
        title:
          packParameters?.find(
            (parameter: OfferParameter): boolean => parameter.name === ParameterName.PACK_NAME,
          )?.value ?? '',
        popular: pack.most_popular ?? false,
        isDaily: pack.category === ParameterName.GEM_RETENTION,
        isBestValue: pack.best_value ?? false,
        rewardTotal:
          parseInt(
            packProperties?.find(
              (parameter: Property): boolean => parameter.name === ParameterName.GEMS_TOTAL,
            )?.value as string,
          ) || 0,
        reward,
        bonus,
        gemBonusPercentage:
          parseInt(
            packProperties?.find(
              (parameter: Property): boolean =>
                parameter.name === ParameterName.GEM_BONUS_PERCENTAGE,
            )?.value as string,
          ) || 0,
        gemBonus:
          parseInt(
            packProperties?.find(
              (parameter: Property): boolean => parameter.name === ParameterName.GEM_BONUS,
            )?.value as string,
          ) || 0,
        firstTimeBonus,
        price: this.getPrice(pack.price?.price),
        currencySymbol: pack.price?.currency ?? '',
        canClaim:
          Number(
            pack.stats?.find((parameter: Stat): boolean => parameter.name === StatName.NEXT_CLAIM)
              ?.value,
          ) < 0,
        ownedPack: !!pack.stats?.length,
        maxRepeatCount:
          parseInt(
            packProperties?.find(
              (parameter: Property): boolean => parameter.name === ParameterName.RETENTION_COUNT,
            )?.value as string,
          ) || 0,
        remainingRepeatCount:
          parseInt(
            pack.stats?.find(
              (parameter: Stat): boolean => parameter.name === StatName.RETENTION_COUNT,
            )?.value as string,
          ) || 0,
        dailyReward:
          parseInt(
            packProperties?.find(
              (parameter: Property): boolean => parameter.name === ParameterName.GEMS_DAILY,
            )?.value as string,
          ) || 0,
        countdown:
          parseInt(
            pack.stats?.find((parameter: Stat): boolean => parameter.name === StatName.NEXT_CLAIM)
              ?.value,
          ) || 0,
        oldReward: firstTimeBonus > 0 ? Number(reward) + Number(bonus) : null,
        alternativePrice: this.getForceRate
          ? this.formatForceRate(pack.price.price)
          : this.getForeignPriceTag(pack.price?.currency, pack.price.price),
      }

      return packData
    },
    getExchangeRate(rateData: ExchangeRate): void {
      if (rateData && Object.keys(rateData).length) {
        this.exchangeRate = rateData.exchange_rate ?? 1
        this.altCurrency = rateData.currency ?? ''
      }
    },
    processPacks(allPacks: Offer[]): void {
      for (const packIndex in allPacks) {
        const currentPack = allPacks[packIndex]
        if (Array.isArray(currentPack) && currentPack.length > 0) {
          this.smallPacks = currentPack.map(this.mapPackData) ?? []
          allPacks[packIndex] = null
          this.smallPacksFirst = Number(packIndex) === 0
        }
      }
      this.bigPacks =
        (allPacks
          .filter((pack: Offer): boolean => {
            return !!pack
          })
          .map(this.mapPackData) as GemsPack[]) ?? []
    },
    async loadData(): Promise<void> {
      this.platform = !this.$isMobile() ? 'web' : this.$isIphone() ? 'ios' : 'android'
      const response = await this.$axios.get<{}, PremiumOfferApiResponse>(metaPremiumPacksEndpoint)

      this.getExchangeRate(response.exchange_rate)
      if (response.packs?.length) {
        this.processPacks(response.packs)
      }
    },
    async selectCountry(country: string): Promise<void> {
      const response = await this.$axios.get<{}, MetaPremiumExchangeResponse>(
        metaPremiumExchangeEndpoint + country,
      )
      if (response.exchange_rate && response.currency) {
        this.exchangeRate = response.exchange_rate
        this.altCurrency = response.currency

        this.smallPacks = this.smallPacks.map((pack: GemsPack): GemsPack => {
          pack.alternativePrice = this.getForceRate
            ? this.formatForceRate(pack.price)
            : this.getForeignPriceTag(pack.currencySymbol, pack.price)
          return pack
        })
        this.bigPacks = this.bigPacks.map((pack: GemsPack): GemsPack => {
          pack.alternativePrice = this.getForceRate
            ? this.formatForceRate(pack.price)
            : this.getForeignPriceTag(pack.currencySymbol, pack.price)
          return pack
        })
      }
    },
    async claimPackReward(packId: string): Promise<void> {
      await this.$axios.put<{}, true>(metaPremiumRetGemClaimEndpoint, { store_id: packId })
      this.loadData()
    },
    async buyPack(packData: GemsPack): Promise<void> {
      this.selectedPackId = packData.storeId
      if (!this.isMobileLocalStorage) {
        this.terminal = true
        return
      }

      this.showPaymentLoading()

      const response = await this.$axios.get<{}, PremiumPayApiResponse>(
        `${metaPremiumPayEndpoint}/pack/${this.selectedPackId}`,
      )
      const message = { event: response.event, productId: response.productId }
      playSound('click-button-buy-gems')
      this.sendToFlutter(JSON.stringify(message))
      // this.showPaymentSuccess(false)
    },
    async showPaymentSuccess(webPayment: boolean = true): Promise<void> {
      const pack =
        webPayment && this.$route.params.pack_id ? this.$route.params.pack_id : this.selectedPackId
      const response = await this.$axios.get<{}, PremiumPackApiResponse>(
        metaPremiumPackEndpoint + pack,
      )

      this.showPaymentMessage({
        pack: pack as string,
        failed: false,
        rewards: response.rewards,
        af_quantity: response.firebaseData.af_quantity,
        af_store_id: response.firebaseData.af_store_id,
        gtm_currency: response.firebaseData.gtm_currency,
        gtm_revenue: response.firebaseData.gtm_revenue,
      })
    },
    async showPaymentFail(): Promise<void> {
      this.showPaymentFailedPopup = true
    },
    animateScene(): void {
      const animatedSmallElements = this.$refs.animation1.querySelectorAll(
        '.premium-gems-small-item',
      )
      animatedSmallElements.forEach((element: HTMLElement, index: number): void => {
        const delay = 67 + index * 100
        animateElement(
          element,
          'fade-in, rotate-in',
          '0.87s',
          delay,
          'cubic-bezier(0.22, 1, 0.36, 1)',
        )
      })

      setTimeout((): void => {
        // assigning a class will start the animation
        this.$refs.animation1a.classList.add('spin-border--animated')
      }, 500)

      const animatedBigElements = this.$refs.animation2.querySelectorAll('.premium-gems-big-item')
      animatedBigElements.forEach((element: HTMLElement, index: number): void => {
        const delay = 240 + index * 200
        animateElement(
          element,
          'fade-in, rotate-in',
          '0.87s',
          delay,
          'cubic-bezier(0.22, 1, 0.36, 1)',
        )
      })

      setTimeout((): void => {
        // assigning a class will start the animation
        const conditionallyAnimatedElement =
          this.$refs.animation2.querySelector('.spin-border--gold')

        if (conditionallyAnimatedElement) {
          conditionallyAnimatedElement.classList.add('spin-border--animated')
        }
      }, 1333)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/animations.scss';

.premium-gems {
  &-main {
    width: 98%;
    height: 100%;

    &-small {
      width: 35.4375rem;
      height: 95%;
      gap: 1.4rem;
      margin-right: 1.5rem;
      margin-left: 0.562rem;
    }

    &-big {
      height: 95%;
    }
  }
}
</style>
