<template>
  <popup-window
    popup-type="info"
    :popup-title="$t('common.blockedAccount')"
    :hide-close="true"
    @close="closePopup"
  >
    <div class="block-user-popup m-4">
      <p class="text-texts-standard-default text-32 mt-4">
        {{ $replacePlaceholder($t('common.blockedAccountInfo'), '{game}', PROJECT_NAME) }}
      </p>
      <p class="text-texts-standard-default text-32 mt-4 mb-8" v-html="getContactMailText" />
      <app-button
        v-if="!$isMobile()"
        btn-type="secondary"
        :btn-text="$t('premiumGroup.close')"
        btn-size="xl"
        class="m-auto"
        @click="closePopup"
      />
    </div>
  </popup-window>
</template>

<script lang="ts">
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import { PROJECT_NAME, supportMail } from '@/globalVariables'
import { logoutUser } from '@/helpers/misc'
import { defineComponent } from 'vue'
import { sendToFlutter } from '@/helpers'

interface ComponentData {
  PROJECT_NAME: typeof PROJECT_NAME
}

export default defineComponent({
  name: 'BlockedUserPopup',
  components: {
    PopupWindow,
  },
  data(): ComponentData {
    return {
      PROJECT_NAME,
    }
  },
  computed: {
    getContactMailText(): string {
      let mailText = this.$t('common.contactSupport')
      mailText = mailText.replace(
        /{email}/g,
        '<a href="mailto:' +
          supportMail +
          '" class="email-addresse font-bold">' +
          supportMail +
          '</a>',
      )
      return mailText
    },
  },

  methods: {
    logoutUser,
    closePopup(): void {
      if (this.$isMobile())
        sendToFlutter(
          JSON.stringify({
            event: 'disconnect',
          }),
        )
      this.logoutUser()
    },
  },
})
</script>
