<template>
  <div class="icons flexing">
    <div
      v-if="iconType === 'discipline'"
      class="eq-icon-attribute"
      :class="[customIconClass, `eq-icon-attribute--${iconSize}`]"
    >
      <div :class="`icon-discipline-${disciplineId}-light-${iconSize}`" />
    </div>
    <div v-else-if="iconType === 'durability'" class="icon-equipment-durability-32 mx-2" />
    <app-main-icon v-else :icon-size="32" :icon-name="iconType" :class="customIconClass" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EquipmentItemAttributesIcons',
  props: {
    iconType: {
      type: String,
      required: true,
    },
    disciplineId: {
      type: Number,
      required: true,
    },
    customIconClass: {
      type: String,
      default: '',
    },
    iconSize: {
      type: Number,
      default: 32,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/equipment-icons.scss';
@import '@/assets/styles/components/icons/discipline-icons.scss';

.eq-icon-attribute {
  width: 2rem;
  height: 2.5rem;
  margin: 0 0.5rem;

  &.mr-0 {
    margin-right: 0;
  }

  &--48 {
    width: 3rem;
    height: 3.5rem;
  }
}

.icons .equipment-item-attribute-icon.offer-box-back__icon--margin-0 {
  margin-right: 0;
}
</style>
