<template>
  <div
    class="sort-arrow ml-2"
    :class="[
      arrowUp && highlighted && 'sort-arrow-up',
      !arrowUp && highlighted && 'sort-arrow-down',
      highlighted ? 'highlighted' : 'sort-arrow-right',
    ]"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SortArrow',
  props: {
    highlighted: {
      type: Boolean,
      default: false,
    },
    arrowUp: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.sort-arrow {
  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid white;
  cursor: pointer;
  margin-left: 0.5rem;

  &.highlighted {
    border-bottom-color: theme('colors.texts.standard.important');
  }
}

.sort-arrow-right {
  transform: rotate(90deg);
}

.sort-arrow-up {
  transform: unset;
}

.sort-arrow-down {
  transform: rotate(180deg);
}
</style>
