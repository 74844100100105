import { StageType } from '@/interfaces/Tutorial'
import type { TutorialStage } from '@/interfaces/Tutorial'

/**
  Tutorial for discipline unlocked
 */
export default class TutorialUnlockBenefits {
  stages(): TutorialStage[] {
    return [
      // narative saying you unlocked a new discipline
      {
        id: 80,
        name: 'unlockBenefitsNarrative',
        type: StageType.Narrative,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        texts: [],
      },
      // focus on arena building with gsap animation
      {
        id: 81,
        name: 'clickOnBenefitsButton',
        type: StageType.ClientInput,
        overlay: true,
        pageName: 'LayoutViewTutorial',
        clientId: ['layout-benefits-btn'],
        texts: [],
      },
    ]
  }
}
