<template>
  <app-main-icon :icon-size="90" icon-name="trophy-gold" class="margin-icon" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TournamentIcon',
})
</script>

<style lang="scss" scoped>
.margin-icon {
  margin: 0 1.5rem;
}
</style>
