<template>
  <footer class="teaser-footer flexing w-full z-10" :data-event-theme="namespace">
    <app-button
      btn-size="sm"
      btn-type="secondary"
      :btn-text="$te('buttonContinue')"
      style="width: 25.625rem"
      @click="onContinueClick"
    />
  </footer>
</template>

<script lang="ts">
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TeaserFooter',
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
      eventCustomProps: 'getEventCustomProps',
      eventTeaserActive: 'getEventTeaserActive',
    }),
  },
  methods: {
    onContinueClick(): void {
      if (this.eventTeaserActive) return
      this.$router.push({ name: this.$getWebView(this.eventCustomProps.routes.event.progress) })
    },
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='challenge_cup'] {
  --events-teaser-footer-bg: url('#{$path-events}challenge-cup/teaser/teaser-footer-bg.avif');
}

[data-event-theme='frozen'] {
  --events-teaser-footer-bg: url('#{$path-events}frozen-championship/teaser/teaser-footer-bg.avif');
}

[data-event-theme='discipline'] {
  --events-teaser-footer-bg: url('#{$path-events}discipline-event/teaser/teaser-footer-bg.avif');
}

[data-event-theme='autumn'] {
  --events-teaser-footer-bg: url('#{$path-events}autumn-fair/teaser/teaser-footer-bg.avif');
}

.teaser-footer {
  height: 10.125rem;
  @include background(var(--events-teaser-footer-bg), cover, top);
}
</style>
