<template>
  <div class="championship-summary-details">
    <p class="text-texts-standard-additional text-30">
      {{ $t('button.detail') }}
    </p>
    <div class="championship-summary-details-bg flexing">
      <div class="detail-text-left">
        <span class="flex items-center justify-end">
          <p class="text-30 text-texts-standard-default">{{ $t('common.championshipPoints') }}:</p>
          <p class="text-30 text-texts-standard-additional font-bold ml-2">
            {{ summaryData.total_points }}
          </p>
          <app-main-icon :icon-size="32" icon-name="championship_points" />
        </span>
      </div>
      <div class="detail-text-middle flexing">
        <span class="text-texts-standard-additional text-32"> | </span>
      </div>
      <div class="detail-text-right">
        <span class="flex items-center justify-start">
          <p class="text-30 text-texts-standard-default pl-2">{{ $t('club.finalStanding') }}:</p>
          <p
            class="text-30 font-bold ml-2"
            :class="
              isPromotion || isStay ? 'text-texts-standard-important' : 'text-texts-standard-danger'
            "
          >
            {{ summaryData.position }}
          </p>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { ClubChampsSummary } from '@/interfaces/clubs/ClubChampionships'

export default defineComponent({
  name: 'ChampionshipSummaryDetails',

  props: {
    summaryData: {
      type: Object as PropType<ClubChampsSummary>,
      required: true,
    },
    isPromotion: {
      type: Boolean,
      default: false,
    },
    isStay: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
