<template>
  <div
    class="career-report app-page-wrapper w-full h-full absolute"
    data-cy="career-report-wrapper"
  >
    <section class="w-full h-full safe-area">
      <div v-if="matchHistoryData" class="career-report-wrapper">
        <header class="flex items-center">
          <report-head :discipline-id="disciplineId">
            <p
              class="text-34 text-texts-standard-additional uppercase"
              v-html="
                $replacePlaceholder(
                  $t('common.taskNumber'),
                  '%s',
                  `<b class='text-texts-standard-important'>
                ${matchHistoryData?.bossFightData?.questOrder ?? ''}
              </b>`,
                )
              "
            />
          </report-head>
        </header>
        <table-report
          :rows-data="matchHistoryData?.final_standings"
          :discipline-id="disciplineId"
          :allowed-columns="tableColumns"
        />
        <footer class="w-full">
          <report-boss-fight-footer
            :boss-fight-data="matchHistoryData.bossFightData"
            :discipline-id="disciplineId"
            :result-position="matchHistoryData.position"
          />
        </footer>
      </div>
      <component-loading :is-loading="isContentLoading" />
    </section>
  </div>
</template>

<script lang="ts">
import ReportBossFightFooter from '@/components/Reports/ReportBossFightFooter.vue'
import ReportHead from '@/components/Reports/ReportHead.vue'
import TableReport from '@/components/Table/TableReport.vue'
import { LIVE, MECHANIC_CAREER, REPORT_TABLE_COLUMNS } from '@/globalVariables'
import { formatResult, getReportValues, playSound } from '@/helpers'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useMatchesStore } from '@/store/pinia/matchesStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { Nullable } from '@/interfaces/utils'
import type ArenaMatchReportApiResponse from '@/interfaces/responses/reports/ArenaMatchReportApiResponse'

interface ComponentData {
  REPORT_TABLE_COLUMNS: typeof REPORT_TABLE_COLUMNS
  matchTime: Nullable<string>
  matchId: string
  disciplineId: number
  matchHistoryData: Nullable<ArenaMatchReportApiResponse>
}

export default defineComponent({
  name: 'CareerReport',
  components: {
    TableReport,
    ReportHead,
    ReportBossFightFooter,
  },
  data(): ComponentData {
    return {
      REPORT_TABLE_COLUMNS,
      matchTime: null,
      matchId: '',
      disciplineId: 1,
      matchHistoryData: null,
    }
  },
  computed: {
    ...mapState(useMatchesStore, {
      matchReport: 'getMatchReport',
    }),
    tableColumns(): string[] {
      return Object.values(this.REPORT_TABLE_COLUMNS).filter(
        (column: string): boolean => column !== this.REPORT_TABLE_COLUMNS.RANKING_POINTS,
      )
    },

    isContentLoading(): boolean {
      return this.matchHistoryData == null
    },
  },

  async created(): Promise<void> {
    const { matchId, matchTime, disciplineId } = getReportValues(this.$route)

    this.matchId = matchId
    this.matchTime = matchTime
    this.disciplineId = disciplineId

    this.loadTableData()
    await this.loadDisciplinesForMenu(MECHANIC_CAREER)
  },

  methods: {
    formatResult,
    ...mapActions(useMatchesStore, ['loadMatchReport']),
    ...mapActions(useDisciplineStore, ['loadDisciplinesForMenu']),
    async loadTableData(): Promise<void> {
      this.matchId = await this.loadMatchReport({
        matchId: this.matchId,
        matchTime: LIVE,
      })

      this.matchHistoryData = this.matchReport(this.matchId)
      if (this.matchHistoryData?.position < 4) {
        playSound('action-dialog-report-win')
      } else {
        playSound('action-dialog-report-lost')
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.career-report {
  &-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: auto 0;
    justify-content: space-between;
  }

  header {
    width: 117.5rem;
    height: 5.625rem;
    margin: 1.5rem auto;
    display: flex;
    align-items: center;
  }
}
</style>
