import type { ConnectAccountOsraConfigInterface } from '@/map-phaser-new/interfaces'

export const connectAccountOsra: ConnectAccountOsraConfigInterface = {
  textureKey: 'link_account_image',
  depth: 8,
  origin: 0.5,
  useHandCursor: true,
  appMessage: '{\r\n  "event": "connectAccount"\r\n}',
  positionX: 1488,
  positionY: 1326,
  notificationX: 1488,
  notificationY: 1206,
  outlineConfig: {
    textureKey: 'link_account_image_outline',
    depth: 8,
  },
}
