<template>
  <main class="clubs-employees-box-center relative flexing flex-col">
    <div class="clubs-employees-box-center-avatar" :class="`employee-${employeeData.employeeId}`" />
    <div class="clubs-employees-box-center-tier absolute" :class="`tier-${employeeData.tier}`" />
    <app-timer
      v-if="employeeData.contractSeconds"
      :time="employeeData.contractSeconds"
      :no-countdown="!employeeData.countdown"
      class="clubs-employees-box-center-timer absolute"
    />
    <clubs-employees-box-name
      :box-color="employeeData.boxColor"
      :text="employeeData.employeeName"
    />
  </main>
</template>

<script lang="ts">
import ClubsEmployeesBoxName from '@/components/Club/ClubsEmployeesBoxName.vue'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Employee } from '@/interfaces/clubs/Employees'

export default defineComponent({
  name: 'ClubsEmployeesBoxCenter',
  components: {
    ClubsEmployeesBoxName,
  },
  props: {
    employeeData: {
      type: Object as PropType<Employee>,
      default: () => ({
        boxColor: 'blue',
        tier: 1,
        contractSeconds: 0,
        countdown: false,
        employeeName: '',
        employeeId: 1,
        warning: false,
      }),
      validator(obj: Employee): boolean {
        return (
          ['blue', 'gold', 'purple', 'green'].includes(obj.boxColor) &&
          (!obj.tier || [1, 2, 3].includes(obj.tier))
        )
      },
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/club/employees.scss';
</style>
