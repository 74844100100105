<template>
  <aside class="side-menu-tasks" :data-event-theme="namespace">
    <app-scrollbar
      v-if="!isLoading"
      class="side-menu-tasks-scroll h-full"
      height="100%"
      width="23.5rem"
      scroll="y"
      slide="y"
    >
      <section class="side-menu-box relative cursor-pointer active-box">
        <div class="image-event-mechanic" />
        <footer class="flexing text-28 text-white absolute w-full uppercase active-bg">
          <p>
            {{ $t('event.eventsTasks') }}
          </p>
        </footer>
      </section>
    </app-scrollbar>
    <component-loading :is-loading="isLoading" />
  </aside>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  pathImages: typeof pathImages
  activeDisciplineBoxId: number
}

export default defineComponent({
  name: 'EventsSideMenuBox',
  data(): ComponentData {
    return {
      pathImages,
      activeDisciplineBoxId: 0,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
    ...mapState(useDisciplineStore, {
      isLoading: 'getIsLoading',
    }),
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='challenge_cup'] {
  --events-tasks-image: url('#{$path-events}challenge-cup/tasks/tasks-image.avif');
}

[data-event-theme='frozen'] {
  --events-tasks-image: url('#{$path-events}frozen-championship/tasks/tasks-image.avif');
}

[data-event-theme='autumn'] {
  --events-tasks-image: url('#{$path-events}autumn-fair/tasks/tasks-image.avif');
}

aside {
  width: 25rem;
  align-self: flex-start;

  .side-menu-tasks-scroll {
    padding-top: 1rem;
  }

  .side-menu-box {
    height: 15.8125rem;
    width: 19.5rem;
    border: solid 2px #3f6b93;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.5);
    background: #254257;
    margin-bottom: 1.625rem;
    margin-left: 1.2rem;

    footer {
      bottom: 0rem;
      background: #0c1e36;
      height: 3.375rem;
    }
  }

  .active-box {
    &::after {
      z-index: 2;
      content: '';
      top: 50%;
      right: -1rem;
      transform: translate(0, -50%) rotate(-45deg);
      position: absolute;
      width: 0;
      height: 0;
      border-right: 1.5rem solid #f7cd27;
      border-left: 1.5rem solid transparent;
      border-top: 1.5rem solid transparent;
    }
  }
}

.image-event-mechanic {
  width: 100%;
  height: 100%;
  border: 0.125rem solid #f7cd27;
  background-image: var(--events-tasks-image);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.locked-icon {
  position: absolute;
  top: 9%;
  left: 2%;
  width: 100%;
  align-items: center;

  &-text {
    margin-top: 1rem;
  }
}

.locked-opacity {
  opacity: 0.3;
}

.active-bg {
  background: linear-gradient(
    10deg,
    rgba(255, 239, 132, 1) 0%,
    rgba(246, 199, 23, 1) 60%,
    rgba(246, 199, 23, 1) 100%
  ) !important;
  color: #000000 !important;
}

.coming-soon-text {
  position: absolute;
  top: 17%;
  left: 15%;
  width: 70%;
  border-left: 0.063rem solid #377fb8;
  border-right: 0.063rem solid #377fb8;
  align-items: center;
  line-height: 1.3;
}

.coming-soon-image {
  background: url($path-career + 'coming_soon_bg.jpeg') center center no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
}
</style>
