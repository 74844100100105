<template>
  <info-popup
    v-if="showPopup"
    width="85rem"
    height="46.25rem"
    :popup-title="$t('seasons.worldRanking')"
    @close="$emit('close')"
  >
    <app-scrollbar
      class="world-rankings-popup-scroll"
      width="85rem"
      height="40rem"
      scroll="y"
      slide="y"
    >
      <div class="seasons-world-rankings-popup flex flex-col justify-start text-30 text-white">
        <div class="flex flex-col space-y-4">
          <dash-text
            :text="$replacePlaceholder($t(`seasons.worldRankingText`), '%s', TOP_PLAYERS)"
          />
        </div>
      </div>
      <div class="seasons-world-rankings-popup-table">
        <seasons-table :titles="titles" :rows="worldRankingRows" />
      </div>
    </app-scrollbar>
  </info-popup>
</template>

<script lang="ts">
import DashText from '@/components/DashText.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import SeasonsTable from '@/components/Seasons/SeasonsTable.vue'
import { TOP_PLAYERS } from '@/globalVariables'
import { useMainSeasonStore } from '@/store/pinia/seasons/mainSeasonsStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type {
  WorldRanking,
  SeasonType,
  SeasonReward,
  SeasonAttributesRow,
} from '@/interfaces/Seasons'

interface ComponentData {
  TOP_PLAYERS: typeof TOP_PLAYERS
  titles: string[]
}

export default defineComponent({
  name: 'SeasonsWorldRankingPopup',
  components: {
    DashText,
    InfoPopup,
    SeasonsTable,
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      TOP_PLAYERS,
      titles: ['common.discipline', 'seasons.position', 'seasons.reward'],
    }
  },
  computed: {
    ...mapState(useMainSeasonStore, {
      worldRankingStats: 'getSeasonWorldRankingStats',
    }),
    worldRankingRows(): SeasonAttributesRow[] {
      const rows = this.worldRankingStats?.map((item: WorldRanking) => {
        return {
          disciplineId: item.disciplineId,
          value1: item.position,
          rewards: item.rewards.map((reward: SeasonReward): SeasonReward => {
            return {
              name: reward.name,
              amount: reward.amount,
              type: reward.type,
              rarity: reward.rarity,
            }
          }),
        }
      })

      return rows ?? ([] as SeasonAttributesRow[])
    },
  },
  created(): void {
    this.loadSeasonWorldRankingStats(this.$route.params.type as SeasonType)
  },
  methods: {
    ...mapActions(useMainSeasonStore, {
      loadSeasonWorldRankingStats: 'loadSeasonWorldRankingStats',
    }),
  },
})
</script>

<style lang="scss" scoped>
.seasons-world-rankings-popup {
  padding: 2rem 2rem 0 2rem;

  &-table {
    width: 95%;
    margin: 0 auto;
  }
}
</style>
