import { ALL, CLUB_LEVELS, CLUB_LEVELS_PLAYERS } from '@/globalVariables'
import { translate } from '@/plugins/translate'

interface ClubRequiredLevels {
  value: string
  name: string
}

export const getClubRequiredLevels = (
  hasAllOption: boolean = false,
  players: boolean = false,
): ClubRequiredLevels[] => {
  const levels = hasAllOption
    ? [
        {
          value: ALL,
          name: translate('grandPrize.filterAll'),
        },
      ]
    : []

  Object.values(players ? CLUB_LEVELS_PLAYERS : CLUB_LEVELS).forEach((level: string): void => {
    levels.push({
      value: level,
      name: level,
    })
  })

  return levels
}
