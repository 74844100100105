<template>
  <div class="relative piggy-bank-item-wrapper flex flex-col">
    <app-icon class="piggy-bank-item-progress-icon" icon-name="unlocked-sm" />
    <app-progress-bar
      class="piggy-bank-item-progress"
      :class="{ 'piggy-bank-item-progress--done': actualProgress >= progressThreshold }"
      :bar-width="26.5"
      :bar-height="0.813"
      :actual="actualProgress"
      :goal="progressThreshold"
      bar-background="#031726"
      counts
      show-bottom-status
      :show-side-counts="false"
      :status-icon="`piggy_bank_${reward}`"
      :show-custom-content="!isLocked"
    >
      <template #customContent>
        <div class="flexing gap-2 text-texts-standard-important">
          <span>{{ $t('common.unlocked') }}</span>
          <app-icon icon-name="done-sm" class="relative" />
        </div>
      </template>
    </app-progress-bar>
    <div
      v-tippy="
        isPrerequisiteRequired && {
          content: $replacePlaceholder(
            $t('piggyBank.breakingPiggyBank'),
            '{piggybank}',
            $t('piggyBank.piggyBank'),
          ),
          placement: 'top',
          theme: 'WSM',
          trigger: 'click',
        }
      "
      class="piggy-bank-item"
      :class="[
        `piggy-bank-item--${rarity}`,
        `piggy-bank-item--${rarity}-${isLocked || isPrerequisiteRequired ? 'locked' : 'unlocked'}`,
        {
          'piggy-bank-item--purchased': isPurchased,
          'cursor-pointer': !isPurchased && !isLocked && !isPrerequisiteRequired,
        },
      ]"
      @click="handleClick"
    >
      <app-notification v-if="showNotification" :count="1" />
      <div
        class="piggy-bank-item-header text-28"
        :class="{
          'piggy-bank-item-header--locked': isLocked || isPrerequisiteRequired,
          'piggy-bank-item-header--purchased': isPurchased,
        }"
      >
        <span
          v-if="!isPurchased && !isLocked && !isPrerequisiteRequired"
          class="text-texts-standard-upgrade uppercase"
        >
          {{ $t('piggyBank.break') }}
        </span>
        <app-icon v-else-if="isPurchased" icon-name="done-md" class="relative z-2" />
        <span v-else class="flexing gap-3 text-texts-standard-default">
          {{ $t('common.locked') }}
          <app-icon icon-name="lock-sm" />
        </span>
      </div>
      <div
        class="piggy-bank-item-image"
        :class="[
          `piggy-bank-item-image--${rarity}-${reward}`,
          { 'piggy-bank-item-image--locked': isLocked || isPrerequisiteRequired },
        ]"
      />
      <div class="piggy-bank-item-footer">
        <p class="text-30 text-texts-standard-default mb-2.5">
          {{ packName }}
        </p>
        <div
          class="pack-price w-full text-60 font-bold flexing text-texts-standard-important mt-2 mb-6"
        >
          <p>{{ $filters.$formatNumber(offerData.reward.value) }}</p>
          <app-main-icon class="icon-margin" :icon-name="reward" :icon-size="56" />
        </div>
        <div class="mt-5 text-36 uppercase font-bold">
          <app-price
            v-if="!isPurchased"
            class="text-texts-standard-default"
            decoration-color="#5199c6"
          >
            {{ `${$filters.$formatNumber(offerData.price.value)} ${offerData.price.currency}` }}
          </app-price>
          <p v-else class="relative purchased-text text-texts-standard-positive z-2">
            {{ $t('premiumGroup.purchased') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { capitalize } from '@/helpers'

import AppPrice from '@/components/GlobalComponents/AppPrice.vue'
import { usePiggyBankStore } from '@/store/pinia/piggyBankStore'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { PiggyBankOfferPacksData } from '@/interfaces/premium/Offer'

export default defineComponent({
  name: 'PiggyBankItem',
  components: {
    AppPrice,
  },
  props: {
    offerData: {
      type: Object as PropType<Nullable<PiggyBankOfferPacksData>>,
      required: true,
    },
  },
  emits: ['buy'],
  computed: {
    ...mapState(usePiggyBankStore, {
      progressData: 'getProgress',
    }),
    offerOrder(): number {
      return this.offerData.order
    },
    isLocked(): boolean {
      return this.progressData.state[this.offerOrder] === 'progressable'
    },
    isPurchased(): boolean {
      return this.progressData.state[this.offerOrder] === 'purchased'
    },
    isPrerequisiteRequired(): boolean {
      return this.progressData.state[this.offerOrder] === 'previous_required'
    },
    showNotification(): boolean {
      return !this.isPurchased && !this.isLocked && !this.isPrerequisiteRequired
    },
    progressThreshold(): number {
      return this.progressData.thresholds[this.offerOrder]
    },
    actualProgress(): number {
      return this.progressData.progress.total
    },
    rarity(): string {
      return this.offerData.rarity
    },
    reward(): string {
      return this.offerData.reward.name.toLowerCase()
    },
    packName(): string {
      return this.$t(`piggyBank.piggyBank${capitalize(this.rarity)}`)
    },
  },
  methods: {
    handleClick(): void {
      if (this.isPurchased || this.isLocked || this.isPrerequisiteRequired) return
      this.$emit('buy')
    },
  },
})
</script>

<style lang="scss" scoped>
.piggy-bank-item-wrapper {
  position: relative;
  width: 26.5rem;
}

.piggy-bank-item {
  position: relative;
  width: 26.5rem;
  height: 39.75rem;
  background: #333;
  top: 3.25rem;
  background-size: contain;

  @each $rarity in ('bronze', 'silver', 'gold', 'diamond') {
    &--#{$rarity} {
      background-image: url($path-premium + 'piggy-bank/box-bg-#{$rarity}.avif');
    }

    &--#{$rarity}-unlocked {
      &.piggy-bank-item--#{$rarity} {
        background-image: url($path-premium + 'piggy-bank/box-bg-#{$rarity}-unlocked.avif');
        @if $isSsm {
          box-shadow: 0 0 1.25rem 0 #0ee7cc;
        }

        @if $isWsm {
          box-shadow: 0 0 1.25rem 0 #3fe443;
        }
      }
    }
  }

  &--purchased {
    box-shadow: none !important;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(12, 17, 43, 0.8);
      z-index: 1;
    }

    .purchased-text {
      width: auto;
      height: 2.0625rem;
      line-height: 2.0625rem;
    }
  }

  &-header {
    position: absolute;
    top: 0.938rem;
    right: 1.5rem;

    &--locked {
      top: 0.625rem;
      right: 0.625rem;
    }

    &--purchased {
      top: 0.625rem;
      right: 0.625rem;
    }
  }

  &-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: contain;

    @each $rarity in ('bronze', 'silver', 'gold', 'diamond') {
      @each $reward in ('money', 'gems', 'universal_training_points') {
        &--#{$rarity}-#{$reward} {
          background-image: url($path-premium + 'piggy-bank/piggy-#{$rarity}-#{$reward}.avif');
        }
      }
    }

    &--locked {
      opacity: 0.5;
    }
  }

  &-footer {
    position: relative;
    top: 28rem;
    width: 100%;

    .pack-price {
      position: relative;
      width: 25rem;
      height: 4.375rem;
      line-height: 4.375rem;
      padding-top: 0.2rem;
      margin: -0.325rem auto 0.5rem;
      background: linear-gradient(to left, transparent, rgba(88, 107, 157, 0.99), transparent);

      &::before {
        content: '';
        position: absolute;
        height: 0.125rem;
        width: 80%;
        background-image: linear-gradient(
          to left,
          transparent,
          rgba(255, 255, 255, 0.8),
          transparent
        );
        top: 0;
      }
    }
  }
}

.piggy-bank-item-progress-icon {
  position: absolute;
  top: -1.5rem;
  right: 1rem;
  z-index: 1;
  transform: rotate(-12deg);
}

:deep() {
  .progress-bar-main.piggy-bank-item-progress {
    &.piggy-bank-item-progress--done {
      .progress-bar-main-line {
        opacity: 0.5;
      }
    }

    .progress-bar-counts .progress-status-bottom {
      left: 0;
      transform: none;
      width: auto;
      bottom: 0;
      top: calc(0rem + 0.813rem + 0.625rem);
      height: 1.75rem;

      &--actual {
        color: theme('colors.texts.standard.important');
        font-weight: bold;
      }
    }
  }
}
</style>
