import {
  availableAdsEndpoint,
  metaPremiumPacksEndpointType,
  metaRightBoxesEndpoint,
  premiumEventShop,
  announcementsEndpoint as ANNOUNCEMENTS_ENDPOINT,
  metaPremiumPackOfferEndpoint as META_PREMIUM_OFFERS_ENDPOINT,
  metaPremiumWeeklyOffersEndpoint as META_PREMIUM_WEEKLY_OFFERS_ENDPOINT,
} from '@/globalVariables'
import { isEventOffer, isEventPass, isChampionPass, isMasterPass } from '@/helpers'
import { internalAxios } from '@/plugins/vueAxios'
import type { TrueFalseInterface } from '@/interfaces/utils'
import { defineStore } from 'pinia'
import type {
  PremiumPacksInterface,
  RefreshingOfferInterface,
  Offer,
  OfferParameter,
} from '@/interfaces/premium/Offer'
import type { AnnouncementsResponse } from '@/interfaces/premium/Announcements'
import type { VideoAdsDataInterface } from '@/interfaces/premium/PremiumAds'
import type { PremiumVideoAdsApiResponse } from '@/interfaces/responses/premium/PremiumVideoAdsApiResponse'
import { useRenderQueueStore } from './renderQueueStore'
import { RenderQueueComponent } from '@/enums/RenderQueueTypes'
import type { Nullable } from '@/interfaces/utils'
import { ApiService } from '@/services/ApiService'

export interface OfferData {
  offerData: Nullable<Offer | Offer[]>
}

// nullable: rightBoxes levels_bags_offer
export interface OfferInterface {
  type: string
  content: Nullable<Offer>
  remaining_time: Nullable<number>
  show?: boolean
}

interface PremiumState {
  isMobileVideoPlaying: boolean
  isWebVideoPlaying: boolean
  rewardType: string
  hasReward: boolean
  isRewardedAdLoaded: boolean
  isInterstitialAdLoaded: boolean
  offerDetail: OfferData['offerData']
  newOffersToShow: OfferInterface[]
  rightboxes: OfferInterface[]
  announcements: AnnouncementsResponse[]
  eventOffers: Nullable<PremiumPacksInterface>
  offers: Nullable<PremiumPacksInterface>
  weeklyOffers: Nullable<PremiumPacksInterface>
  availableAds: VideoAdsDataInterface
}

export const usePremiumStore = defineStore('premiumStore', {
  state: (): PremiumState => ({
    isMobileVideoPlaying: false,
    isWebVideoPlaying: false,
    rewardType: '',
    hasReward: false,
    isRewardedAdLoaded: true,
    isInterstitialAdLoaded: true,
    offerDetail: null,
    newOffersToShow: [],
    rightboxes: [],
    announcements: [],
    eventOffers: null,
    offers: null,
    weeklyOffers: null,
    availableAds: {
      availableAds: 0,
      nextAdsCountdown: 0,
      reward: [],
    },
  }),
  getters: {
    getMobileVideoPlaying(): PremiumState['isMobileVideoPlaying'] {
      return this.isMobileVideoPlaying
    },
    getWebVideoPlaying(): PremiumState['isWebVideoPlaying'] {
      // This is just pure info for rendering <video-ad-web component
      return this.isWebVideoPlaying
    },
    getRewardType(): PremiumState['rewardType'] {
      return this.rewardType
    },
    getHasReward(): PremiumState['hasReward'] {
      return this.hasReward
    },
    getRewardedAdLoaded(): PremiumState['isRewardedAdLoaded'] {
      return this.isRewardedAdLoaded
    },
    getInterstitialAdLoaded(): PremiumState['isInterstitialAdLoaded'] {
      return this.isInterstitialAdLoaded
    },
    getOfferDetail(): PremiumState['offerDetail'] {
      return this.offerDetail
    },
    getNewOffersToShow(): PremiumState['newOffersToShow'] {
      return this.newOffersToShow
    },
    getAllRightBoxes(): PremiumState['rightboxes'] {
      return !Array.isArray(this.rightboxes) ? Object.values(this.rightboxes) : this.rightboxes
    },
    getVisibleRightBoxes(): PremiumState['rightboxes'] {
      return this.getAllRightBoxes.filter(({ show }: OfferInterface): boolean => !!show)
    },
    getAnnouncements(): PremiumState['announcements'] {
      return this.announcements
    },
    getEventPass(): Offer[] {
      return (
        this.eventOffers?.packs
          ?.filter(isEventPass)
          ?.sort(
            (a: Offer, b: Offer): number =>
              Number(a.parameters.find((param) => param.name === 'pass_tier')?.value ?? 0) -
              Number(b.parameters.find((param) => param.name === 'pass_tier')?.value ?? 0),
          ) ?? []
      )
    },
    getChampionPass(): Offer[] {
      return this.eventOffers?.packs?.filter(isChampionPass) ?? []
    },
    getMasterPass(): Offer[] {
      return this.eventOffers?.packs?.filter(isMasterPass) ?? []
    },
    getOffers(): PremiumState['offers'] {
      return this.offers ?? null
    },
    getWeeklyOffers(): PremiumState['weeklyOffers'] {
      return this.weeklyOffers ?? null
    },
    getSmallOffers(): Offer[] {
      return (
        this.offers?.packs?.filter(
          (offer: Offer): OfferParameter =>
            offer.parameters.find(
              (parameter: OfferParameter): boolean => parameter.value === 'small',
            ),
        ) ?? []
      )
    },
    getBigOffers(): Offer[] {
      return (
        this.offers?.packs?.filter(
          (offer: Offer): OfferParameter =>
            offer.parameters.find(
              (parameter: OfferParameter): boolean => parameter.value === 'big',
            ),
        ) ?? []
      )
    },
    getAvailableAds(): PremiumState['availableAds'] {
      return this.availableAds
    },
    getEventSmallOffers(): Offer[] {
      return (
        this.eventOffers?.packs?.filter(
          (offer: Offer): boolean =>
            isEventOffer(offer) &&
            offer.parameters.some(
              (offer: OfferParameter): boolean =>
                offer.name === 'offer_size' && offer.value === 'small',
            ),
        ) ?? []
      )
    },
    getEventBigOffers(): Offer[] {
      return (
        this.eventOffers?.packs?.filter(
          (offer: Offer): boolean =>
            isEventOffer(offer) &&
            offer.parameters.some(
              (offer: OfferParameter): boolean =>
                offer.name === 'offer_size' && offer.value === 'big',
            ),
        ) ?? []
      )
    },
  },
  actions: {
    setWebVideoPlay(status: boolean): void {
      this.isWebVideoPlaying = status
    },
    setVideoPlay(status: boolean): void {
      this.isMobileVideoPlaying = status
    },
    setRewardtype(item: string): void {
      this.rewardType = item
    },
    setReward(item: boolean): void {
      this.hasReward = item
    },
    setRewardedAdLoaded(status: boolean): void {
      this.isRewardedAdLoaded = status
    },
    setInterstitialAdLoaded(status: boolean): void {
      this.isInterstitialAdLoaded = status
    },
    showOfferDetail({ offerData }: OfferData): void {
      if (window.isTutorial()) return

      const renderStore = useRenderQueueStore()
      renderStore.addToRenderQueue(RenderQueueComponent.OfferDetail)
      this.offerDetail = offerData
    },
    hideOfferDetail(): void {
      this.offerDetail = null
    },
    saveNewOffers(offers: OfferInterface[]): void {
      this.newOffersToShow = offers
    },
    async showOffers(offers: OfferInterface[]): Promise<void> {
      const promoOffersData = offers
      if (promoOffersData.length) {
        const offer = promoOffersData.shift()
        if (offer.content) {
          const contentData = offer.content
          if (offer.type) {
            contentData.type = offer.type
          }
          if (offer.remaining_time) {
            contentData.remaining_time = offer.remaining_time
          }
          this.showOfferDetail({ offerData: contentData })
        }
        this.saveNewOffers(promoOffersData)
      }
    },
    async showRefreshingOffers(data: RefreshingOfferInterface): Promise<void> {
      this.showOfferDetail({ offerData: data.packs })
    },
    async loadRightboxes(force: boolean = false): Promise<void> {
      if (this.rightboxes.length && !force) return
      try {
        this.rightboxes = await internalAxios.get<{}, OfferInterface[]>(metaRightBoxesEndpoint)

        if (this.rightboxes.length > 1) {
          // TODO BE: TEMP FIX
          // TODO if needed ['ssm_summer_series_', 'wsm_winter_series_']
          const filters: string[] = ['ssm_summer_series_']

          // Filter the offers based on the defined filters
          const filteredOffers = this.rightboxes.filter((offer: OfferInterface): boolean =>
            filters.some((filter: string): boolean => offer.content?.store_id?.startsWith(filter)),
          )

          // Take the first one, and then add all the other offers that are not filtered
          if (filteredOffers.length > 1) {
            this.rightboxes = filteredOffers
              .slice(0, 1)
              .concat(
                this.rightboxes.filter(
                  (offer: OfferInterface): boolean => !filteredOffers.includes(offer),
                ),
              )
          }
        }
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadAnnouncements(force: boolean = false): Promise<void> {
      if (this.announcements.length && !force) return
      try {
        this.announcements = await internalAxios.get<{}, AnnouncementsResponse[]>(
          ANNOUNCEMENTS_ENDPOINT,
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadEventOffers(force: boolean = false): Promise<void> {
      if (this?.eventOffers !== null && !force) return

      try {
        this.eventOffers = await ApiService.get<PremiumPacksInterface>(premiumEventShop, { force })
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadOffers(force: boolean = false): Promise<void> {
      if (this?.offers !== null && !force) return

      try {
        this.offers = await internalAxios.get<{}, PremiumPacksInterface>(
          META_PREMIUM_OFFERS_ENDPOINT,
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadWeeklyOffers(force: boolean = false): Promise<void> {
      if (this?.weeklyOffers !== null && !force) return

      try {
        this.weeklyOffers = await internalAxios.get<{}, PremiumPacksInterface>(
          META_PREMIUM_WEEKLY_OFFERS_ENDPOINT,
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async generateOffers(): Promise<void> {
      try {
        await ApiService.get<TrueFalseInterface>(metaPremiumPacksEndpointType + 'generate')
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadAvailableAds(type: string): Promise<void> {
      this.availableAds = {} as VideoAdsDataInterface
      try {
        const data = await internalAxios.get<{}, PremiumVideoAdsApiResponse>(
          `${availableAdsEndpoint}${type}`,
        )
        const commitedData = {
          availableAds: data.available_ads,
          nextAdsCountdown: data.next_ads_countdown,
          reward: data.reward,
        }
        this.availableAds = commitedData
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
