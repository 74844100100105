<template>
  <popup-window
    popup-type="submit"
    :popup-data="getDeleteSubmit"
    @close="$emit('close', false)"
    @action="deleteClubMember"
  >
    <div class="flexing flex-col">
      <p class="text-texts-standard-default text-36 my-4">
        {{ $t('club.deleteMemberConfirm') }}
      </p>
      <app-user
        :id="playerData.userId"
        class="club-member-settings-player-name"
        :country="playerData.threeLetterCode"
        :name="playerData.name"
        :role="playerData.role"
      />
    </div>
  </popup-window>
</template>

<script lang="ts">
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import { useManagementStore } from '@/store/pinia/clubs/managementStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { ClubPlayerData } from '@/interfaces/clubs/Clubs'
import type { PopupData } from '@/interfaces/PopupData'

export default defineComponent({
  name: 'ClubsDeletePopup',
  components: {
    PopupWindow,
    AppUser,
  },
  props: {
    playerData: {
      type: Object as PropType<Nullable<ClubPlayerData>>,
      default: () => null,
    },
  },
  emits: ['close', 'update'],
  computed: {
    getDeleteSubmit(): PopupData {
      return this.$filters.$submitPopupData({
        title: this.$t('club.deleteMember'),
        btnText: this.$t('common.confirm'),
        btnType: 'danger',
        btnId: 'delete-club-memmber-button',
      })
    },
  },
  methods: {
    ...mapActions(useManagementStore, ['deleteMember']),
    async deleteClubMember(): Promise<void> {
      await this.deleteMember(this.playerData.userId)
      this.$emit('close', false)
      this.$emit('update', true)
    },
    translateRoles(role: string): string {
      if (role === 'member') return this.$t('club.member')
      else if (role === 'assistant') return this.$t('club.assistant')
      else return this.$t('club.manager')
    },
  },
})
</script>
