<template>
  <div v-if="opponents" class="table-starts">
    <div v-if="titleType === 'startList'" class="relative table-head-standard flexing startlist">
      <p>{{ $t('arena.startList') }}</p>
    </div>
    <div
      v-if="titleType === 'opponentPower'"
      class="relative table-head-standard justify-between pl-3 pr-5 mb-3"
    >
      <p>{{ $t('common.name') }}</p>
      <p>{{ $t('career.strength') }}</p>
    </div>
    <div class="table-starts-wrapper">
      <div
        v-for="(opponent, index) in opponents"
        :key="'opponent' + index"
        class="table-starts-box flex items-center relative"
        :class="{
          'me-bg': opponent.player,
          'club-friend': opponent.club,
        }"
      >
        <app-user
          :id="index"
          :country="opponent.country.three_letter_code"
          :name="opponent.username"
          :is-bot="opponent.bot"
          :badges-data="opponent.event_badges ?? null"
        />

        <div
          class="table-starts-box-corner flex justify-center items-center"
          :class="{
            'font-bold': $isWsm,
            'font-semibold': $isSsm,
          }"
        >
          <div class="table-start-discipline">
            <div
              class="discipline attributeIcon"
              :class="'icon-discipline-' + disciplineId + '-light-48'"
            />
          </div>
          <div class="attribute text-texts-standard-important italic">
            {{ opponent.attributes.POWER }}
          </div>
        </div>
        <span v-if="opponent.player && !enoughAttributeBenefits && !isGroupB" class="not-benefits">
          <not-enough-benefits-for-live
            v-if="opponent.player"
            type="attribute"
            :discipline-id="disciplineId"
            :live-value="opponent.attributes.POWER_WITHOUT_BENEFIT"
            :simulation-value="opponent.attributes.POWER"
          />
        </span>
      </div>
    </div>
  </div>
  <component-loading :is-loading="!opponents" />
</template>

<script lang="ts">
import NotEnoughBenefitsForLive from '@/components/Benefits/NotEnoughBenefitsForLive.vue'
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import { ATTRIBUTE, Multiplier, pathImages } from '@/globalVariables'
import type { Nullable } from '@/interfaces/utils'
import { useDuelStore } from '@/store/pinia/arena/duelStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { BenefitSlot } from '@/interfaces/Benefits'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'TableStarts',
  components: {
    AppUser,
    NotEnoughBenefitsForLive,
  },
  props: {
    opponents: {
      // TODO any
      type: Object as PropType<any>,
      default: () => {},
    },
    disciplineId: {
      type: Number,
      default: 1,
    },
    titleType: {
      type: String,
      default: 'startList',
      validator(value: string): boolean {
        return ['startList', 'opponentPower'].includes(value)
      },
    },
    benefitsSlots: {
      type: Object as PropType<Nullable<BenefitSlot['slots']>>,
      default: null,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    ...mapState(useDuelStore, {
      startsCostConfig: 'getStartsCostConfig',
    }),
    ...mapState(useUserStore, {
      activeMultiplicator: 'getActiveMultiplicator',
      isGroupB: 'isGroupB',
    }),
    enoughAttributeBenefits(): boolean {
      if (!this.benefitsSlots) return true

      for (const slotId in this.benefitsSlots) {
        const slot = this.benefitsSlots[slotId]

        if (slot && slot.benefit_type === ATTRIBUTE) {
          const filteredSlot = slot

          if (filteredSlot) {
            return (
              filteredSlot?.amount >= this.liveStartsCost ||
              this.baseStartsCost > filteredSlot.amount
            )
          }
        }
      }
      return true
    },
    liveStartsCost(): number {
      return this.startsCostConfig.live * this.activeMultiplicator(Multiplier.Arena).value
    },
    baseStartsCost(): number {
      return this.startsCostConfig.base * this.activeMultiplicator(Multiplier.Arena).value
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';

.table-starts {
  width: 99%;

  &-box {
    width: 100%;
    height: 3.99rem;
    margin-bottom: 0.5rem;

    @if $isWsm {
      background-color: rgba(33, 97, 135, 0.6);
      border: 0.063rem solid #5689ac;
    }

    @if $isSsm {
      background-color: rgba(114, 114, 176, 0.7);
      border: 0.125rem solid #7477a4;
    }

    &:nth-child(even) {
      @if $isWsm {
        background-color: rgba(54, 109, 140, 0.6);
      }

      @if $isSsm {
        background-color: rgba(82, 84, 121, 0.7);
      }
    }

    &-country {
      width: 3.75rem;
      font-size: 1.4375rem;
      font-style: italic;
      font-weight: bold;
      margin: 1.8rem 0 0.875rem 0;
    }

    &-flag {
      background-repeat: no-repeat;
      background-size: contain;
      width: 3.15rem;
      height: 2.2rem;
    }

    &-name {
      max-width: 40.25rem;
      font-size: 2rem;
      margin-left: 1.0625rem;
    }

    &-corner {
      position: absolute;
      right: 0rem;
      width: 10.1875rem;
      height: 100%;
      clip-path: polygon(7.5% 0%, 100% 0%, 100% 100%, 0% 100%);

      @if $isWsm {
        background-color: #234965;
      }

      @if $isSsm {
        background-color: #353958;
      }

      .table-start-discipline {
        width: 3rem;
        height: 3rem;
      }

      .attribute {
        font-size: 2.125rem;
        text-shadow: 0.643px 0.766px 0.93px rgba(0, 0, 0, 0.71);
      }
    }

    &.me-bg {
      @if $isWsm {
        background-color: #15856e;
      }

      @if $isSsm {
        background-color: #cd4a8b;
      }

      .table-starts-box-corner {
        @if $isSsm {
          background-color: rgba(20, 56, 88, 0.5);
        }
      }
    }

    &.club-friend {
      background: #216f6b;
    }
  }

  .not-benefits {
    position: absolute;
    right: 11rem;
  }
}

.startlist {
  margin-bottom: 0.5rem;
}
</style>
