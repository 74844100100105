<template>
  <div id="club-members" class="club-members-view flex flex-col app-page-wrapper absolute">
    <clubs-managment-menu />
    <section class="club-members-wrapper flex w-full h-full safe-area">
      <main class="club-members-main flex flex-row">
        <clubs-panel />
        <div
          class="club-members-main-table flex"
          :class="$isMobile() ? 'club-members-main-table-mobile' : 'club-members-main-table-web'"
        >
          <table-component
            :columns="clubPosition()"
            which-id="userId"
            :items="getMembersInfo"
            force-scroll-size="100%"
            @member-settings="openMemberSettings"
            @delete-cell="openDeletePopup($event)"
            @sort-event="orderBy"
          />
        </div>
      </main>
      <clubs-member-settings
        v-if="showMemeberSettings"
        :player-data="playerData"
        @close="showMemeberSettings = false"
        @update="refreshData"
      />
      <clubs-delete-popup
        v-if="showDeletePopup"
        :player-data="playerData"
        @close="closeDeletePopup"
        @update="refreshData"
      />
    </section>
  </div>
</template>

<script lang="ts">
import ClubsDeletePopup from '@/components/Club/ClubsDeletePopup.vue'
import ClubsManagmentMenu from '@/components/Club/ClubsManagmentMenu.vue'
import ClubsMemberSettings from '@/components/Club/ClubsMemberSettings.vue'
import ClubsPanel from '@/components/Club/ClubsPanel.vue'
import TableComponent from '@/components/Table/TableComponent.vue'
import { clubTableKeys } from '@/components/Table/tableKeys'
import { CHAMPIONSHIP_POINTS, PRESTIGE, TOURNAMENT_TITLES } from '@/globalVariables'
import { getOrderBodyContent } from '@/helpers'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useManagementStore } from '@/store/pinia/clubs/managementStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { BodyContent } from '@/helpers'
import type { ClubsColumnData, ClubPlayerData } from '@/interfaces/clubs/Clubs'
import type { Nullable } from '@/interfaces/utils'
import type { ClubMember } from '@/interfaces/clubs/ClubManagement'

interface ComponentData {
  showMemeberSettings: boolean
  showDeletePopup: boolean
  playerData: Nullable<ClubPlayerData>
  clubTableKeys: typeof clubTableKeys
  columns: ClubsColumnData[]
}

export default defineComponent({
  name: 'ClubsMembers',
  components: {
    ClubsManagmentMenu,
    ClubsPanel,
    ClubsMemberSettings,
    TableComponent,
    ClubsDeletePopup,
  },
  data(): ComponentData {
    return {
      showMemeberSettings: false,
      showDeletePopup: false,
      playerData: null,
      clubTableKeys,
      columns: [
        // if it's icon pass to label icon name and set isIcon to true
        {
          label: this.$t('rankingsGroup.pos'),
          key: clubTableKeys.pos,
          align: 'center',
          width: 5,
          disableSort: true,
        },
        {
          label: ' ',
          key: clubTableKeys.onlineStatus,
          onlineStatus: true,
          width: 2,
        },
        {
          label: this.$t('common.name'),
          key: clubTableKeys.nameComponent,
          align: 'left',
          width: 30,
        },
        {
          label: this.$t('common.level'),
          key: clubTableKeys.level,
          width: 13,
        },
        {
          label: PRESTIGE,
          key: clubTableKeys.prestige,
          isIcon: true,
          width: 15,
        },
        {
          label: CHAMPIONSHIP_POINTS,
          key: clubTableKeys.championshipPoints,
          isIcon: true,
          width: 15,
        },
        {
          label: TOURNAMENT_TITLES,
          key: clubTableKeys.tournamentTitles,
          isIcon: true,
          width: 15,
        },
      ],
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userData: 'getUserData',
    }),
    ...mapState(useManagementStore, ['getMembersInfo']),
    ...mapState(useClubStore, {
      getClubInfo: 'getClubInfo',
    }),
  },
  async created(): Promise<void> {
    await this.loadClubInfo()
    await this.loadMembersInfo({ clubId: this.getClubInfo.id })
  },
  methods: {
    getOrderBodyContent,
    ...mapActions(useUserStore, ['loadUserData']),
    ...mapActions(useManagementStore, ['loadMembersInfo']),
    ...mapActions(useClubStore, {
      loadClubInfo: 'loadClubInfo',
    }),
    clubPosition(): ClubsColumnData[] {
      if (this.userData.club_stats.role === 'manager') {
        return [...this.columns, { label: '', key: clubTableKeys.settings, width: 5 }]
      } else if (this.userData.club_stats.role === 'assistant') {
        return [...this.columns, { label: '', key: clubTableKeys.delete, width: 5 }]
      } else {
        return [...this.columns, { label: '' }]
      }
    },
    openMemberSettings(id: string): void {
      this.setPlayerData(id)
      this.showMemeberSettings = true
    },
    openDeletePopup(data: { e: string }): void {
      this.showDeletePopup = true
      this.setPlayerData(data.e)
    },
    closeDeletePopup(): void {
      this.showDeletePopup = false
    },
    setPlayerData(id: string): void {
      const player = this.getMembersInfo.find((member: ClubMember): boolean => member.userId === id)
      this.playerData = {
        clubId: player.clubId,
        userId: player.userId,
        name: player.name.name,
        threeLetterCode: player.name.country,
        role: player.name.role,
      }
    },
    async refreshData(): Promise<void> {
      await this.loadMembersInfo({ clubId: this.getClubInfo.id })
      this.clubPosition()
      await this.loadUserData()
      this.loadClubInfo()
    },
    orderBy(order: BodyContent): void {
      const bodyContent = this.getOrderBodyContent(order)
      this.loadMembersInfo({
        clubId: this.getClubInfo.id,
        filter: { bodyContent },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.club-members-wrapper {
  flex: 1 1 auto;
  min-height: 0;

  .club-members-main {
    width: 98%;
    margin: 0.75rem auto 1.25rem;
    display: flex;
    gap: 1.25rem;

    &-table {
      height: 100%;

      &-web,
      &-mobile {
        flex: 1 1 auto;
      }
    }
  }

  :deep() {
    .table-component {
      display: flex;
      width: 100%;
    }

    .table-component-wrapper {
      // Positional correction of the table in contrast to display: table
      display: flex;
      height: 100%;

      .y-scroll {
        // Offset for the scrollbar without blending with the table
        padding-right: 1.375rem;
        height: 100% !important;
      }
    }
  }
}
</style>
