import type { TutorialConfigInterface } from '@/map-phaser-new/interfaces'

export class TutorialItems {
  private tutorialCircle: Phaser.GameObjects.Image | null = null
  private tutorialCircleAnimation: Phaser.Tweens.Tween | null = null
  private tutorialArrows: Phaser.GameObjects.Group | null = null
  private tutorialArrowsAnimation: Phaser.Tweens.Tween | null = null
  private tutorialOverlay: Phaser.GameObjects.RenderTexture | null = null

  public addToMap(
    activeScene: Phaser.Scene,
    buildingImage: Phaser.GameObjects.Image,
    config: TutorialConfigInterface | null = null,
    isMapItem: boolean = false,
  ): void {
    const centeredBuildingX = buildingImage.x + (isMapItem ? 0 : buildingImage.width / 2)
    const centeredBuildingY = buildingImage.y + (isMapItem ? 0 : buildingImage.height / 2)
    const arrowsStartPositionX = centeredBuildingX - config?.arrowsIndent

    this.addTutorialCircle(activeScene, centeredBuildingX, centeredBuildingY, config)
    this.addTutorialArrows(activeScene, arrowsStartPositionX, centeredBuildingY, config)
    this.addTutorialOverlay(activeScene, config)
  }

  public focusCamera(
    activeScene: Phaser.Scene,
    config: TutorialConfigInterface,
    building: Phaser.GameObjects.Image,
  ): void {
    activeScene.cameras.main.pan(
      building.x + building.width / 2,
      building.y + building.height / 2,
      config.panDuration,
      config.panAnimation,
    )
  }

  public destroyTutorialElements(activeScene: Phaser.Scene): void {
    activeScene.tweens.killTweensOf([this.tutorialCircle, this.tutorialArrows])

    this.destroyTutorialArrowsAnimation()
    this.destroyTutorialArrows()
    this.destroyTutorialCircleAnimation()
    this.destroyTutorialCircle()
    this.destroyTutorialOverlay()
  }

  private addTutorialCircle(
    activeScene: Phaser.Scene,
    positionX: number,
    positionY: number,
    config: TutorialConfigInterface,
  ): void {
    // tutorial circle image
    this.tutorialCircle = activeScene.add
      .image(positionX, positionY, config.textureKey)
      .setDepth(config.tutorialDepth)
      .setOrigin(config.circleOriginX, config.circleOriginY)

    // animation of tutorial circle
    this.tutorialCircleAnimation = activeScene.tweens.add({
      targets: this.tutorialCircle,
      duration: config.circleDuration,
      repeat: config.repeat,
      ease: config.circleAnimation,
      opacity: config.circleOpacityEnd,
      scale: config.circleScaleEnd,
    })
  }

  private addTutorialArrows(
    activeScene: Phaser.Scene,
    positionX: number,
    positionY: number,
    config: TutorialConfigInterface,
  ): void {
    // tutorial arrows images group
    this.tutorialArrows = activeScene.add
      .group({
        key: config.arrowTextureKey,
        repeat: config.arrowGroupRepeat,
        setXY: { x: positionX, y: positionY },
        setRotation: { value: config.arrowRotation },
      })
      .setDepth(config.tutorialDepth)
      .scaleXY(config.arrowScaleX, config.arrowScaleY)
      .setAlpha(config.arrowsGroupOfImagesAlphaStart)

    // animation of tutorial arrows
    this.tutorialArrowsAnimation = activeScene.tweens.add({
      x: positionX + config.arrowsEndPosition,
      targets: this.tutorialArrows.getChildren(),
      duration: config.arrowsAnimationDuration,
      repeat: config.repeat,
      ease: config.arrowsEase,
      alpha: config.arrowsAnimationAlphaEnd,
      delay: activeScene.tweens.stagger(config.arrowsStaggerDelay, {}),
    })
  }

  private addTutorialOverlay(activeScene: Phaser.Scene, config: TutorialConfigInterface): void {
    this.tutorialOverlay = activeScene.add.renderTexture(
      0,
      0,
      config.overlayWidth,
      config.overlayHeight,
    )
    this.tutorialOverlay
      .fill(config.overlayColor, config.overlayOpacity)
      .setDepth(config.overlayDepth)
      .setOrigin(config.overlayOriginX, config.overlayOriginY)
  }

  private destroyTutorialArrows(): void {
    if (!this.tutorialArrows) return

    this.tutorialArrows.clear(true, true)
    this.tutorialArrows.destroy(true)
    this.tutorialArrows = null
  }

  private destroyTutorialArrowsAnimation(): void {
    if (!this.tutorialArrowsAnimation) return

    this.tutorialArrowsAnimation.destroy()
    this.tutorialArrowsAnimation = null
  }

  private destroyTutorialCircle(): void {
    if (!this.tutorialCircle) return

    this.tutorialCircle.destroy()
    this.tutorialCircle = null
  }

  private destroyTutorialCircleAnimation(): void {
    if (!this.tutorialCircleAnimation) return

    this.tutorialCircleAnimation.destroy()
    this.tutorialCircleAnimation = null
  }

  private destroyTutorialOverlay(): void {
    if (!this.tutorialOverlay) return

    this.tutorialOverlay.destroy()
    this.tutorialOverlay = null
  }
}
