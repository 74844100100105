<template>
  <div data-cy="training-disciplines" class="training-disciplines app-page-wrapper absolute">
    <training-menu :is-sub-menu-active="true" />
    <section class="w-full h-full safe-area">
      <modal-head class="ml-9" :title-popup="$t('trainingHall.aboutTrainingHeaderInfo')">
        <p class="text-texts-standard-default font-bold uppercase italic z-1 text-48">
          {{ $t('discipline.chooseDiscipline') }}
        </p>

        <template #popupContent>
          <training-popup />
        </template>
      </modal-head>
      <div class="training-disciplines-content">
        <app-scrollbar
          v-if="!isTutorial"
          height="100%"
          width="100%"
          scroll="x"
          slide="x"
          class="flex items-center"
          :arrows="true"
          :scroll-arrows-safe-zone-indented="true"
        >
          <training-disciplines-boxes />
        </app-scrollbar>
        <training-disciplines-boxes v-else hide-overflow />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import ModalHead from '@/components/ModalHead.vue'
import TrainingDisciplinesBoxes from '@/components/Training/TrainingDisciplinesBoxes.vue'
import TrainingPopup from '@/components/Training/TrainingPopup.vue'
import { playSound } from '@/helpers'
import { useTrainingDisciplineStore } from '@/store/pinia/trainingDisciplinesStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import TrainingMenu from '@/views/training/TrainingMenu.vue'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  textsForTranslate: string[]
}

export default defineComponent({
  name: 'TrainingDisciplines',
  components: {
    TrainingMenu,
    ModalHead,
    TrainingPopup,
    TrainingDisciplinesBoxes,
  },
  data(): ComponentData {
    return {
      textsForTranslate: [
        'trainingHall',
        'common.levelRequired',
        'common.locked',
        'discipline.chooseDiscipline',
      ],
    }
  },
  computed: {
    isTutorial(): boolean {
      return (
        this.actualStageData?.name === 'chooseFirstDisciplineNarrative' ||
        this.actualStageData?.name === 'clickOnDisciplineTH'
      )
    },
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
    }),
  },
  created(): void {
    this.loadInitialDisciplinesData(false, true)
    playSound('building-practice-arena')
  },
  methods: {
    ...mapActions(useTrainingDisciplineStore, ['loadInitialDisciplinesData']),
  },
})
</script>

<style lang="scss" scoped>
.training-disciplines-content {
  width: 98%;
  margin: 0 auto;
}
</style>
