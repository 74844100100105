<template>
  <training-discipline-footer-pass-active
    v-if="isRookiePassActive"
    :timer-seconds="getRemainingSecondsFromDate(rookiePassInfo.remaining_datetime)"
    :pass-name="`premiumGroup.wsm_starter_pass`"
    @timer-finished="$emit('initLoad')"
  />
  <app-button
    v-else
    btn-type="credit"
    :btn-text="$t('premiumGroup.wsm_starter_pass')"
    btn-size="md"
    @click="showRookiePassDetail"
  />
</template>

<script lang="ts">
import TrainingDisciplineFooterPassActive from '@/components/Training/TrainingDisciplineFooterPassActive.vue'
import { OFFER_STATES } from '@/globalVariables'
import { getOfferData } from '@/helpers/premium'
import { getRemainingSecondsFromDate } from '@/helpers/timeFormatter.js'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  OFFER_STATES: typeof OFFER_STATES
}

export default defineComponent({
  name: 'TrainingDisciplineFooterPass',
  components: {
    TrainingDisciplineFooterPassActive,
  },
  emits: ['initLoad'],
  data(): ComponentData {
    return {
      OFFER_STATES,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      rookiePassInfo: 'getRookiePassInfo',
    }),
    isRookiePassActive(): boolean {
      return this.rookiePassInfo.state === OFFER_STATES.active
    },
  },
  methods: {
    getRemainingSecondsFromDate,
    showRookiePassDetail(): void {
      getOfferData(this.rookiePassInfo.store_id, 'offer', this.$axios)
    },
  },
})
</script>
