<template>
  <div id="club-fitness" class="club-fitness flex flex-col app-page-wrapper absolute">
    <menu-component menu-type="club-fitness" :title="$t('club.fitness')">
      <app-icon
        v-tippy="{
          theme: 'WSM',
          content: $t('club.clubEmployeeDesc'),
          placement: 'left',
          maxWidth: '62rem',
        }"
        icon-name="info-70"
        class="modal-title-icon"
      />
    </menu-component>
    <section class="w-full h-full safe-area">
      <clubs-building-employees building-type="fitness" />
    </section>
  </div>
</template>

<script lang="ts">
import ClubsBuildingEmployees from '@/components/Club/ClubsBuildingEmployees.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClubsFitness',
  components: {
    ClubsBuildingEmployees,
  },
})
</script>
