<template>
  <teleport to="#app">
    <div class="fullscreen-popup-wrapper gp-achievement absolute">
      <div class="gp-achievement__container flexing">
        <h2
          ref="animation1"
          class="gp-achievement-congrat not-italic text-50 uppercase animated-element--faded"
        >
          {{ $t(`common.congratulations`) }}
        </h2>
        <h1
          ref="animation2"
          class="gp-achievement-earn not-italic text-60 uppercase font-bold animated-element--faded"
          :class="`text-color-${achievement.name}`"
        >
          {{ $replaceUntranslatablePlaceholder($t(achievementTypeKey), '{grandprize}') }}
        </h1>
        <div ref="animation3" class="gp-achievement__icon-wrapper animated-element--faded">
          <div ref="animation3a">
            <app-main-icon
              class="gp-achievement__icon gp-logo"
              :icon-name="`gp-${achievement.name}`"
              :icon-size="360"
              :class="$isMobile() && 'gp-logo-mobile'"
              :hide-tooltip="true"
            />
          </div>
          <div
            ref="animation3b"
            class="gp-achievement__icon-decoration"
            :class="`gp-achievement__icon-decoration--${achievement.name}`"
          />
        </div>
        <footer class="gp-achievement-footer flex-col flexing">
          <div class="gp-achievement-footer__cta flexing">
            <typewriter-effect
              class="gp-achievement-info text-texts-standard-default text-28"
              :array="[$t('grandPrize.openDecide')]"
              :type-speed="isAnimationSkipped ? 0 : 20"
              :start-delay="isAnimationSkipped ? 0 : 400"
            />
          </div>
          <div class="gp-achievement-buttons">
            <div ref="animation4" class="animated-element--faded">
              <app-button
                class="gp-achievement-button-late"
                btn-type="secondary"
                :btn-text="$t('grandPrize.openLater')"
                btn-size="sm"
                @click="closePopup"
              />
            </div>
            <div ref="animation5" class="animated-element--faded">
              <app-button
                class="gp-achievement-button-now"
                btn-type="confirm"
                :btn-text="$t('grandPrize.openNow')"
                btn-size="sm"
                :disabled="achievement.has_opened"
                @click="openGp"
              />
            </div>
          </div>
        </footer>
      </div>

      <div class="video-footage-wrapper" :class="`video-footage-wrapper--${achievement.name}`">
        <video
          ref="video"
          class="video-footage"
          autoplay
          loop
          muted
          playsinline
          poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAArEAAAKxAFmbYLUAAAADUlEQVQImWP4//8/AwAI/AL+hc2rNAAAAABJRU5ErkJggg=="
        >
          <!-- If the browser's video player is unable to play the MP4 file, nothing will be rendered except the poster image (transparent  1x1px). -->
          <source type="video/mp4" :src="`${pathImages}videos/particles-explosion--center.mp4`" />
        </video>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'pinia'
import { animateElement, resetAnimateElement, skipAnimationOnClick } from '@/helpers'
import { grandPrizeEndPoint, pathImages } from '@/globalVariables'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import TypewriterEffect from '@/components/TypewriterEffect.vue'

import type { PropType } from 'vue'
import type { OpenGrandPrizeApiResponse } from '@/interfaces/responses/grandPrize/GrandPrizeApiResponse'
import type { Nullable } from '@/interfaces/utils'

interface GrandPrizeAchievement {
  id: string
  name: string
  has_opened: boolean
}

interface ComponentData {
  achievementTypeKey: string
  interval: Nullable<ReturnType<typeof setInterval>>
  pathImages: typeof pathImages
  isAnimationSkipped: boolean
}

export default defineComponent({
  name: 'GrandPrizeAchievement',
  components: { TypewriterEffect },
  props: {
    achievement: {
      type: Object as PropType<GrandPrizeAchievement>,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      achievementTypeKey: '',
      interval: null,
      pathImages,
      isAnimationSkipped: false,
    }
  },
  created(): void {
    this.achievementTypeKey = `grandPrize.${this.achievement.name}Received`
  },
  mounted(): void {
    this.animateScene()

    // skip animation on click
    window.addEventListener('click', this.handleSkipAnimationClick)
  },
  beforeUnmount(): void {
    clearInterval(this.interval)
    window.removeEventListener('click', this.handleSkipAnimationClick)
  },
  methods: {
    ...mapActions(useResponseTaskStore, ['hideAchievementPopUp']),
    closePopup(): void {
      this.hideAchievementPopUp()
    },
    async openGp(): Promise<void> {
      if (this.achievement.has_opened) return

      await this.$axios.put<{}, OpenGrandPrizeApiResponse, { grand_prize_id: string }>(
        grandPrizeEndPoint,
        {
          grand_prize_id: this.achievement.id,
        },
      )

      this.closePopup()
      this.$router.push({
        name: this.$getWebView('GrandPrizeMain'),
        params: { id: this.achievement.id },
      })
    },
    animateScene(): void {
      animateElement(this.$refs.animation1, 'fade-in, zoom-reset', '1s', 300)
      animateElement(this.$refs.animation2, 'fade-in, zoom-reset', '1s', 600)
      animateElement(this.$refs.animation3, 'fade-in', '1s', 800)

      //repeats the process every 10 seconds
      const animation3a = this.$refs.animation3a
      animateElement(animation3a, 'spin, flash-blur', '1.4s', 800, 'cubic-bezier(.46,.25,.18,.82)')

      this.interval = setInterval((): void => {
        animateElement(
          animation3a,
          'spin, flash-blur',
          '1.5s',
          0,
          'cubic-bezier(.46,.25,.18,.82)',
          'infinite',
        )

        setTimeout((): void => {
          resetAnimateElement(animation3a)
        }, 1500)
      }, 6000)
      this.$refs.animation3b.classList.add('animated-element--rotate-slow')

      animateElement(this.$refs.animation4, 'fade-in', '1s', 600)
      animateElement(this.$refs.animation5, 'fade-in', '1s', 800)
    },
    handleSkipAnimationClick(e: MouseEvent): void {
      this.isAnimationSkipped = true
      skipAnimationOnClick(e, this.$el, this.$refs, !!this.achievement, null)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/grand-prize/gradPrizeAchievement.scss';
@import '@/assets/styles/animations.scss';

.video-footage-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    mix-blend-mode: overlay;
    opacity: 0.5;
  }

  &--basic {
    &::before {
      background: #6893bf;
    }
  }
  &--bronze {
    &::before {
      background: #e77447;
    }
  }
  &--silver {
    &::before {
      background: #d7d7d7;
    }
  }
  &--gold {
    &::before {
      background: #fadd1e;
    }
  }
  &--diamond {
    &::before {
      background: #b94bcf;
    }
  }
}
.video-footage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // Full screen video setting regardless of its resolution
  object-fit: cover;
  opacity: 0.4;
  filter: brightness(2);
  z-index: 0;
}
</style>
