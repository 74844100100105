<template>
  <footer class="w-full flex items-center justify-between">
    <div v-if="match === 0" class="start-match flex items-center justify-between w-full h-full">
      <template v-if="hasMechanic(MECHANIC_BENEFIT)">
        <template v-for="(slot, slotId) in getBenefitSlots" :key="slotId">
          <use-benefit-button
            v-if="slot"
            btn-size="sm"
            :benefit-usage="EVENT_BENEFIT_USAGE"
            :slot-data="slot"
            source="event"
            :slot-id="slotId.toString()"
            @click="showSelectBenefitPopup = true"
          />
          <use-benefit-button v-else btn-size="sm" @click="showSelectBenefitPopup = true" />
        </template>
      </template>
      <use-benefit-button v-else btn-size="sm" :lock-icon="true" :disabled="true" />
      <info-popup
        v-if="showSelectBenefitPopup"
        :popup-title="$t('benefits.buttonActiveBenefit')"
        @close="showSelectBenefitPopup = false"
      >
        <select-benefit
          :allowed-benefit-types="benefitEventSlots.benefit_type"
          :current-slots="getBenefitSlotsIdMapping"
          :required-benefits="EVENT_STARTS_NUMBER_BOSSFIGHT"
          @close-select-popup="showSelectBenefitPopup = false"
        />
      </info-popup>
      <app-multi-button
        btn-id="arena-start-live"
        btn-type="secondary"
        :btn-text="$t('button.start')"
        class="inline-flex align-top mx-2"
        :loading="isMinigame"
        @click="playBossFight"
      >
        <template #rightPart>
          <span class="text-texts-standard-default">
            {{ EVENT_STARTS_NUMBER_BOSSFIGHT }}
          </span>
          <app-main-icon :icon-size="48" icon-name="starts" />
        </template>
      </app-multi-button>
    </div>
    <div v-else class="done-match flexing w-full h-full">
      <app-button btn-size="md" btn-type="primary" :btn-text="$t('common.continue')" />
    </div>
  </footer>
  <parameter-popup
    v-if="isParameterPopupOpen"
    :parameter="STARTS"
    @close="isParameterPopupOpen = false"
  />
</template>

<script lang="ts">
import SelectBenefit from '@/components/Benefits/SelectBenefit.vue'
import UseBenefitButton from '@/components/Button/UseBenefitButton.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import ParameterPopup from '@/components/Popup/Parameter/ParameterPopup.vue'
import {
  BENEFITS_EVENT,
  EVENT_BENEFIT_USAGE,
  EVENT_STARTS_NUMBER_BOSSFIGHT,
  MECHANIC_BENEFIT,
  STARTS,
} from '@/globalVariables'
import type {
  ActiveBenefitSlots,
  BenefitSlot,
  BenefitSlotData,
  UserBenefitsSlotsApiResponse,
} from '@/interfaces/Benefits'
import type { Nullable } from '@/interfaces/utils'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useEventBossFightsStore } from '@/store/pinia/events/bossFightStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  MECHANIC_BENEFIT: typeof MECHANIC_BENEFIT
  EVENT_BENEFIT_USAGE: typeof EVENT_BENEFIT_USAGE
  EVENT_STARTS_NUMBER_BOSSFIGHT: typeof EVENT_STARTS_NUMBER_BOSSFIGHT
  STARTS: typeof STARTS
  showSelectBenefitPopup: boolean
  isParameterPopupOpen: boolean
}

export default defineComponent({
  components: {
    InfoPopup,
    SelectBenefit,
    UseBenefitButton,
    ParameterPopup,
  },
  props: {
    match: {
      type: Number,
      default: 0,
    },
  },
  emits: ['minigame'],
  data(): ComponentData {
    return {
      MECHANIC_BENEFIT,
      EVENT_BENEFIT_USAGE,
      EVENT_STARTS_NUMBER_BOSSFIGHT,
      STARTS,
      showSelectBenefitPopup: false,
      isParameterPopupOpen: false,
    }
  },
  computed: {
    ...mapState(useCoreStore, ['isMinigame']),
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
      benefitsSlots: 'getBenefitsSlots',
      userStarts: 'getStarts',
    }),
    benefitEvent(): UserBenefitsSlotsApiResponse[] {
      return (
        this.benefitsSlots?.filter(
          (benefit: UserBenefitsSlotsApiResponse): boolean =>
            benefit.benefit_building === BENEFITS_EVENT,
        ) ?? []
      )
    },
    benefitEventSlots(): BenefitSlot {
      const benefitEventDataSlots: BenefitSlot['slots'] = {}
      const benefitEventDataBenefitTypes: string[] = []

      this.benefitEvent.forEach((element: UserBenefitsSlotsApiResponse) => {
        benefitEventDataSlots[element?.slot_id] = element.benefit
        benefitEventDataBenefitTypes.push(element.benefit_slot_type)
      })

      return {
        benefit_type: benefitEventDataBenefitTypes,
        slots: benefitEventDataSlots,
      }
    },
    getBenefitSlots(): BenefitSlot['slots'] {
      return this.benefitEventSlots.slots
    },
    getBenefitSlotsIdMapping(): ActiveBenefitSlots {
      const slotsRaw = this.getBenefitSlots

      const slots = {}
      for (const slotId in slotsRaw) {
        slots[slotId] = slotsRaw[slotId] ? slotsRaw[slotId].benefit_gdd_id : 0
      }

      return slots
    },
  },
  watch: {
    getBenefitSlots(newVal: BenefitSlot['slots']): void {
      const benefit: BenefitSlotData[] = []
      for (const slotId in newVal) {
        const slot: Nullable<BenefitSlotData> = newVal[slotId] ?? null
        if (slot && Object.keys(slot).length > 0) benefit.push(slot)
      }
      const eventBossFightStore = useEventBossFightsStore()
      if (benefit.length > 0) {
        eventBossFightStore.setEventBenefits(benefit[0].benefit_effect)
      } else {
        eventBossFightStore.setEventBenefits(0)
      }
    },
  },
  async created(): Promise<void> {
    await this.loadBenefitsSlots()
  },
  methods: {
    ...mapActions(useResponseTaskStore, {
      loadBenefitsSlots: 'loadBenefitsSlots',
    }),
    playBossFight(): void {
      if (this.userStarts.value < EVENT_STARTS_NUMBER_BOSSFIGHT) this.isParameterPopupOpen = true
      else this.$emit('minigame')
    },
  },
})
</script>

<style lang="scss" scoped>
footer {
  padding: 0 3.75rem;
  height: 9.25rem;
  @include background(
    url('#{$path-events}shared/taskchain/tasks/bossFight/bossfight-footer-basic.avif'),
    contain
  );
}
</style>
