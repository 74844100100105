import {
  ARENA,
  PREVIOUS_SEASON,
  SEASONAL_ATTRIBUTES,
  WORLD_RANKING,
  seasonEndpoint,
} from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import router from '@/router'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { defineStore } from 'pinia'
import { useRenderQueueStore } from '../renderQueueStore'
import { RenderQueueComponent } from '@/enums/RenderQueueTypes'
import type SeasonResponse from '@/interfaces/responses/season/SeasonResponse'
import { SeasonType } from '@/interfaces/Seasons'
import type {
  SeasonReward,
  SeasonRewardType,
  SessionalAttribute,
  WorldRanking,
  Season,
  ArenaStats,
} from '@/interfaces/Seasons'
import type {
  SeasonArenaStatsResponse,
  SeasonalAttributesResponse,
  SeasonWorldRankingStatsResponse,
} from '@/interfaces/responses/season/SeasonStatsResponses'

interface SeasonMainState {
  isModalVisible: boolean
  season: Season
  arenaStats: ArenaStats
  seasonalAttributes: SessionalAttribute[]
  worldRanking: WorldRanking[]
}

export const useMainSeasonStore = defineStore('mainSeasonStore', {
  state: (): SeasonMainState => ({
    isModalVisible: false,
    season: {
      seasonYear: '',
      seasonMonth: '',
      remainingTime: null,
      seasonalRp: 0,
      rewards: [],
    },
    arenaStats: {
      arenaLevel: 0,
      rankingPoints: 0,
    },
    seasonalAttributes: [],
    worldRanking: [],
  }),
  getters: {
    getSeasonStats(): SeasonMainState['season'] {
      return this.season
    },
    getPreviousSeasonArenaStats(): SeasonMainState['arenaStats'] {
      return this.arenaStats
    },
    getSeasonalAttributes(): SeasonMainState['seasonalAttributes'] {
      return this.seasonalAttributes
    },
    getSeasonWorldRankingStats(): SeasonMainState['worldRanking'] {
      return this.worldRanking
    },
    getSeasonModalState(): SeasonMainState['isModalVisible'] {
      return this.isModalVisible
    },
  },
  actions: {
    async loadSeasonStats(type: SeasonType = SeasonType.Current): Promise<void> {
      const response = await internalAxios.get<{}, SeasonResponse>(`${seasonEndpoint}/${type}`)

      if (!response || Object.keys(response).length === 0) return
      const data = {
        seasonYear: response.seasonYear,
        seasonMonth: response.seasonMonth,
        remainingTime: response?.remainingTime ?? null,
        seasonalRp: response.seasonalRp,
        rewards: response.rewards.map((item: SeasonRewardType): SeasonRewardType => {
          return {
            type: item.type,
            topValue: item.topValue,
            rewards: item.rewards.map((reward) => {
              return {
                name: reward.name,
                amount: reward.amount,
                rarity: reward.rarity,
                type: reward.type,
              }
            }),
          }
        }),
      }

      this.season = data
    },
    async loadPreviousSeasonArenaStats(): Promise<void> {
      const response = await internalAxios.get<{}, SeasonArenaStatsResponse>(
        `${seasonEndpoint}/${PREVIOUS_SEASON}/${ARENA}`,
      )

      if (!response) return

      const data = {
        arenaLevel: response.arenaLevel,
        rankingPoints: response.rankingPoints,
      }

      this.arenaStats = data
    },
    async loadSeasonalAttributesStats(type: SeasonType = SeasonType.Current): Promise<void> {
      const response = await internalAxios.get<{}, SeasonalAttributesResponse[]>(
        `${seasonEndpoint}/${type}/${SEASONAL_ATTRIBUTES}`,
      )

      const data = response.map((item: SeasonalAttributesResponse): SessionalAttribute => {
        return {
          disciplineId: item.discipline,
          baseAttribute: item.baseAttribute ?? 0,
          seasonalAttribute: item.seasonalAttribute ?? 0,
        }
      })

      this.seasonalAttributes = data
    },
    async loadSeasonWorldRankingStats(type: SeasonType = SeasonType.Current): Promise<void> {
      const response = await internalAxios.get<{}, SeasonWorldRankingStatsResponse[]>(
        `${seasonEndpoint}/${type}/${WORLD_RANKING}`,
      )

      const data = response.map((item: SeasonWorldRankingStatsResponse): WorldRanking => {
        return {
          disciplineId: item.discipline,
          position: item.position,
          rewards: item.rewards.map((reward: SeasonReward): SeasonReward => {
            return {
              name: reward.name,
              amount: reward.amount,
              rarity: reward.rarity,
              type: reward.type,
            }
          }),
        }
      })

      this.worldRanking = data
    },
    showSeasonSummary(): void {
      const useTutorial = useTutorialStore()
      const useCore = useCoreStore()
      if (useTutorial.getIsTutorial) return
      if (useCore.isLoadingScreen) {
        const checkLoading = (): void => {
          if (useCore.isLoadingScreen) return
          clearInterval(intervalId)
          router.push({ name: 'SeasonsEnd' })
        }
        const intervalId = setInterval(checkLoading, 500)
        return
      }
    },
    setSeasonModalState(modalState: boolean): void {
      if (!this.isModalVisible) {
        const renderStore = useRenderQueueStore()
        renderStore.addToRenderQueue(RenderQueueComponent.SeasonsEnd)
      }

      const tutorialStore = useTutorialStore()
      this.isModalVisible = tutorialStore.getIsTutorial ? false : modalState
    },
  },
})
