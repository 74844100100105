import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const shortDistanceBalloonPath: PathObjectInterface = {
  mainPath: {
    x: 660,
    y: 750,
  },
  nextPaths: [
    {
      x: 660,
      y: 700,
    },
  ],
}
