<template>
  <app-scrollbar
    :id="getRandomClassName"
    scroll="x"
    slide="x"
    width="100%"
    height="18rem"
    class="flex items-center logos-scrollbar"
    :arrows="true"
    :arrow-pos-right="'-7rem'"
    :arrow-pos-left="'-7rem'"
    :arrow-click-scroll-size="148"
  >
    <section class="clubs-info-form-logos">
      <div v-for="(id, index) in showOnlyBackground ? backgroundIds : logoIds" :key="index">
        <clubs-avatar
          class="clubs-avatar"
          :background-id="showOnlyBackground ? id : 0"
          :logo-id="showOnlyBackground ? null : id"
          :selected="showOnlyBackground ? selectedBackground : selectedLogo"
          @click="$emit(showOnlyBackground ? 'selectBackground' : 'selectLogo', index + 1)"
        />
      </div>
    </section>
  </app-scrollbar>
</template>

<script lang="ts">
import ClubsAvatar from '@/components/Club/ClubsAvatar.vue'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'ClubsInfoFormLogos',
  components: {
    ClubsAvatar,
  },
  props: {
    logoIds: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    backgroundIds: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    selectedLogo: {
      type: Number,
      default: 1,
    },
    selectedBackground: {
      type: Number,
      default: 1,
    },
    showOnlyBackground: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectLogo', 'selectBackground'],
  computed: {
    getRandomClassName(): string {
      return `logos-scrollbar-${Math.floor(Math.random() * 1000 + 1)}`
    },
  },
})
</script>

<style lang="scss" scoped>
.clubs-info-form-logos {
  display: flex;
  margin: 0 auto;
}

.club-avatar {
  width: 14rem;
  height: 14rem;
  margin-right: 0.7rem;
  margin-left: 0.7rem;
}
</style>
