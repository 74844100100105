<template>
  <div
    class="club-avatar m-auto flexing"
    :class="selected && (backgroundId === selected || logoId === selected) ? 'selected' : ''"
    :style="{
      backgroundImage: `url(${pathImages}clubs/logos/background/club-bg-${backgroundId}.avif)`,
    }"
  >
    <div
      v-if="logoId"
      class="club-avatar-head"
      :style="{
        backgroundImage: `url(${pathImages}clubs/logos/logo/club-logo-${logoId}.avif)`,
      }"
    />
  </div>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'ClubsAvatar',
  props: {
    backgroundId: {
      type: Number,
      default: 0,
    },
    logoId: {
      type: Number,
      default: 0,
    },
    selected: {
      type: Number,
      default: null,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/global.scss';

.club-avatar,
.club-avatar-head {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.club-avatar {
  width: 100%;
  height: 100%;

  &-head {
    width: 75%;
    height: 75%;
  }
}

.selected {
  border: 0.35rem solid #f4d91e;
  box-shadow:
    inset 0 0 13px #f4d91e,
    0 0 8px #f4d91e;
  border-radius: 50%;
}
</style>
