<template>
  <div class="report-rewards-tooltip w-full h-full relative">
    <div class="report-rewards-tooltip-title w-full relative flexing">
      <p class="text-texts-standard-default font-bold text-34">
        {{ $t('reports.otherRewards') }}
      </p>
    </div>
    <div class="flexing">
      <reward-box
        v-for="(reward, i) in rewards"
        :key="i"
        :reward-icon-size="72"
        :reward-icon-name="getIconName(reward)"
        :reward-number="reward.value"
        class="pt-3"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type Reward from '@/interfaces/Reward'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'ReportRewardsTooltip',
  props: {
    rewards: {
      type: Array as PropType<Reward[]>,
      default: () => [],
    },
  },
  methods: {
    getIconName(reward: Reward): string {
      if (reward.name === 'grandPrize') return 'gp-' + reward.rarity
      return reward.name
    },
  },
})
</script>

<style lang="scss" scoped>
.report-rewards-tooltip {
  &-title {
    height: 3.125rem;
    top: -1.525rem;
    background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      #236d9b,
      rgba(255, 255, 255, 0)
    );
  }
}
</style>
