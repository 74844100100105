<template>
  <div id="news-row" class="news-row">
    <div
      class="news-row-image"
      :style="newsData.image ? { backgroundImage: 'url(' + newsData.image + ')' } : {}"
    >
      <div v-if="newsData.unread" class="news-row-image-new flexing">
        <p class="text-texts-standard-default text-28 uppercase">
          {{ $t('common.new') }}
        </p>
      </div>
    </div>
    <div class="news-row-textarea" :class="{ 'news-row-textarea-long': !rightside }">
      <h2
        class="text-36 uppercase"
        :class="$isWsm ? 'text-texts-standard-name' : 'text-texts-standard-default'"
      >
        {{ newsData.title }}
      </h2>
      <p
        class="news-row-textarea-content text-texts-standard-additional text-30"
        v-html="getText"
      />
    </div>
    <div v-if="rightside" class="news-row-rightside flexing" :class="{ 'no-btn': !btn }">
      <div class="news-row-rightside-info">
        <span class="news-row-rightside-info-date">
          <p class="text-texts-standard-additional text-30">
            {{ newsData.date }}
          </p>
        </span>
        <p class="text-texts-standard-additional text-30">
          {{ newsData.time }}
        </p>
      </div>
      <app-button
        v-if="btn"
        btn-type="secondary"
        :btn-text="$t('common.readMore')"
        btn-size="md"
        class="news-row-rightside-btn"
        @click="openDetail()"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { useNewsStore } from '@/store/pinia/newsStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { UserNewsDetail } from '@/interfaces/News'

interface ComponentData {
  pathImages: typeof pathImages
  maxMsgLength: number
}

export default defineComponent({
  name: 'NewsRow',
  props: {
    newsData: {
      type: Object as PropType<Nullable<UserNewsDetail>>,
      default: () => null,
    },
    btn: {
      type: Boolean,
      default: true,
    },
    rightside: {
      type: Boolean,
      default: true,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
      maxMsgLength: 160,
    }
  },
  computed: {
    getText(): string {
      const suffix = this.newsData.text?.length > this.maxMsgLength ? '...' : ''
      return this.newsData.text?.slice(0, this.maxMsgLength) + suffix
    },
  },
  methods: {
    ...mapActions(useNewsStore, ['setNewsDetail']),
    openDetail(): void {
      this.setNewsDetail(this.newsData)
      this.$router.push({
        name: this.$getWebView('NewsMessage'),
        params: { news_id: this.newsData.id },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.news-row {
  width: 116rem;
  min-height: 12.5rem;
  margin: 0.75rem auto 0;
  display: flex;
  @if $isWsm {
    border: solid 0.063rem #6c9ec4;
    background-color: #236d9b;
  }
  @if $isSsm {
    border: solid 0.063rem #7477a4;
    background-color: rgba(#7272b0, 0.7);
  }
  &:nth-child(odd) {
    @if $isWsm {
      border: solid 0.063rem #5883a3;
      background-color: #417491;
    }
    @if $isSsm {
      border: solid 0.063rem #7477a4;
      background-color: rgba(#525479, 0.7);
    }
  }

  &-image {
    width: 20rem;
    height: 11.25rem;
    margin: 0.625rem;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    &-new {
      width: 4.563rem;
      height: 2rem;
      position: absolute;
      top: 0.625rem;
      left: -0.625rem;
      background-image: linear-gradient(to bottom, #ff1a1a, #ff0606);
    }
  }

  &-textarea {
    width: 62.563rem;
    height: 100%;
    margin: 2rem 3rem 0 1.75rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;

    &.news-row-textarea-long {
      width: 100%;
    }

    &-content {
      max-height: 5.5rem;
      overflow: hidden;
    }
  }

  &-rightside {
    flex-direction: column;

    &.no-btn {
      justify-content: flex-start;
      margin-left: auto;
      position: relative;
      right: 2.813rem;
      top: 3.063rem;
      align-items: center;
      height: 7rem;
    }

    &-info {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;

      &-date {
        position: relative;
        margin-right: 2.25rem;

        &:before {
          content: '';
          position: absolute;
          height: 2.5rem;
          width: 0.25rem;
          background-color: rgba(255, 255, 255, 0.3);
          right: -1.125rem;
        }
      }
    }
  }
}
</style>
