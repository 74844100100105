<template>
  <menu-component
    :menu-type="PREMIUM"
    :title="$t('map.premiumName')"
    :sub-menu="subMenu"
    @select-country="selectCountry"
  />
</template>

<script lang="ts">
import { MECHANIC_BAGS, MECHANIC_BENEFIT, PREMIUM } from '@/globalVariables'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { MenuObject } from '@/interfaces/MenuObject'

interface ComponentData {
  PREMIUM: typeof PREMIUM
}

export default defineComponent({
  name: 'PremiumMenu',
  emits: ['select-country'],
  data(): ComponentData {
    return {
      PREMIUM,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
      getMechanicUnlock: 'getMechanicUnlock',
    }),
    ...mapState(useEventInfoStore, {
      eventActive: 'getEventActive',
      eventCustomProps: 'getEventCustomProps',
      eventCollectionActive: 'getEventCollectionActive',
      eventLocked: 'getEventLocked',
      eventMechanicByType: 'getEventMechanicByType',
    }),
    subMenu(): Record<number, MenuObject> {
      return {
        1: {
          text: this.$t('premiumGroup.specialOffers'),
          route: this.$getWebView('PremiumOffers'),
        },
        2: {
          text: this.$t('premiumGroup.gems'),
          route: this.$getWebView('PremiumGems'),
        },
        3: {
          text: this.$t('premiumGroup.addOns'),
          route: this.$getWebView('PremiumWildCard'),
          active: ['PremiumWildCard', 'PremiumEnergyDrink']
            .map(this.$getWebView)
            .includes(this.$route.name.toString()),
        },
        /**
         * zakomentovane na zaklade tiketu https://powerplaystudio.atlassian.net/browse/WSM-7648,
         * komponenty zatial nemazeme aby sme sa v pripade potreby vedeli k funkcionalite rychlo
         * vratit
         **/
        // 4: {
        //   text: this.$t('premiumGroup.rewards'),
        //   route: this.$getWebView('PremiumAds'),
        // },

        4: {
          text: this.$t('premiumGroup.level_up_bags'),
          route: this.$getWebView('PremiumLevelBags'),
          isDisabled: !this.hasMechanic(MECHANIC_BAGS),
          disabledText: this.$replacePlaceholder(
            this.$t('common.levelRequired'),
            '%s',
            this.getMechanicUnlock(MECHANIC_BAGS),
          ),
        },

        5: {
          text: this.$t('premiumGroup.weeklyDeals'),
          route: this.$getWebView('PremiumWeeklyDeals'),
          isDisabled: !this.hasMechanic(MECHANIC_BENEFIT),
          disabledText: this.$replacePlaceholder(
            this.$t('common.mainCareerTaskRequired'),
            '%s',
            this.getMechanicUnlock(MECHANIC_BENEFIT),
          ),
        },
        6: {
          text: 'Event',
          route: this.eventCustomProps
            ? this.$getWebView(
                this.eventCustomProps.routes.premium[
                  this.eventCollectionActive ? 'collection' : 'progress'
                ],
              )
            : '',
          active: [
            'PremiumEventOffers',
            'PremiumEventPasses',
            // 'PremiumEventDeals',
          ]
            .map(this.$getWebView)
            .includes(this.$route.name.toString()),
          isDisabled: this.eventLocked,
          disabledText: this.$replacePlaceholder(
            this.$t('common.mainCareerTaskRequired'),
            '%s',
            this.getMechanicUnlock(this.eventMechanicByType),
          ),
          isHidden: !this.eventActive && !this.eventCollectionActive,
        },
      }
    },
  },
  methods: {
    selectCountry(country: string): void {
      this.$emit('select-country', country)
    },
  },
})
</script>
