<template>
  <section class="spinning-wheel" @click="requestStop = true">
    <section class="finish" />
    <section ref="pricesSection" class="prices">
      <section class="wheel-middle" />
      <section
        v-for="(price, i) in prices"
        :key="i"
        class="price"
        :class="[`set-${i + 1}`, { victory: activeClaim === i }]"
      >
        <span class="text">
          {{ $filters.$formatNumber(price.amount) }}
          <span class="icon">
            <app-main-icon :icon-size="48" :icon-name="price.parameter" />
          </span>
        </span>
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import gsap from 'gsap'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { LuckyWheelPrice } from '../LuckyWheelController.vue'
import type { Nullable } from '@/interfaces/utils'

export interface Price {
  id: number | string
  parameter: string
  amount: number | string
}

export interface AnimationInterface {
  spinAnim?: gsap.core.Timeline
}

interface ComponentData {
  requestStop: boolean
  stopAt: number
  loopIteration: number
  gsapAnimations: AnimationInterface
  fullSpins: number
  setupGsap: boolean
  retryCount: number
  activeClaim: number
}

export default defineComponent({
  name: 'SpinningWheel',
  props: {
    spin: {
      type: Boolean,
      default: false,
    },
    prices: {
      type: Array as PropType<LuckyWheelPrice[]>,
      default: () => [],
    },
    initialReward: {
      type: Object as PropType<Nullable<LuckyWheelPrice>>,
      default: () => null,
    },
    winnerId: {
      type: Number as PropType<LuckyWheelPrice['id']>,
      default: 0,
    },
  },
  emits: ['spinCompleted'],
  data(): ComponentData {
    return {
      requestStop: false,
      stopAt: 0,
      loopIteration: 0,
      gsapAnimations: {},
      fullSpins: 1,
      setupGsap: false,
      retryCount: 0,
      activeClaim: -1,
    }
  },
  computed: {
    stopRotation(): number {
      return 360 + this.stopAt
    },
  },
  watch: {
    spin(newVal: boolean): void {
      if (!newVal) return
      this.spinWheel()
    },
    winnerId(newVal: LuckyWheelPrice['id']): void {
      if (!newVal) return
      this.setupGsapAnimation(newVal)
    },
  },
  mounted(): void {
    this.setInitialRewardPosition()
  },
  methods: {
    spinWheel(): void {
      if (this.setupGsap) {
        this.gsapAnimations.spinAnim?.pause(0)
        this.gsapAnimations.spinAnim?.play()
      } else {
        this.retryCount++
        if (this.retryCount === 4 && this.winnerId) this.setupGsapAnimation(this.winnerId)
        this.$nextTick((): void => this.spinWheel())
      }
    },
    setupGsapAnimation(priceId: LuckyWheelPrice['id']): void {
      this.activeClaim = -1
      const realIndex = this.prices.findIndex(({ id }: LuckyWheelPrice): boolean => id === priceId)
      this.stopAt = realIndex * -45
      gsap.to(this.$refs.pricesSection, {
        rotation: 0,
        duration: 0,
      })
      this.gsapAnimations.spinAnim = gsap
        .timeline({
          paused: true,
          onComplete: (): void => {
            this.loopIteration = 0
            this.requestStop = false
            this.setupGsap = false
            this.retryCount = 0
            this.gsapAnimations = {}
            this.$emit('spinCompleted')
          },
        })
        .to(
          this.$refs.pricesSection,
          {
            rotation: '+=360',
            ease: 'power1.in',
            duration: 1,
          },
          'startAnim',
        )
        .to(
          this.$refs.pricesSection,
          {
            rotation: '+=360',
            ease: 'none',
            duration: 0.5,
            repeat: this.fullSpins,
            onStart: (): void => {
              if (!this.requestStop) return
              this.gsapAnimations.spinAnim?.play('finishAnim')
            },
            onRepeat: (): void => {
              if (!this.requestStop) return
              this.gsapAnimations.spinAnim?.play('finishAnim')
            },
          },
          'loopAnim',
        )
        .to(
          this.$refs.pricesSection,
          {
            rotation: `+=${this.stopRotation}`,
            // Calculate the new duration to make the
            // transition between spinning and stopping as smooth as possible.
            duration: this.stopRotation / 360,
            onComplete: (): void => {
              this.activeClaim = realIndex
            },
          },
          'finishAnim',
        )
      this.setupGsap = true
    },
    setInitialRewardPosition(): void {
      if (!this.initialReward || !this.initialReward.id) return
      const realIndex = this.prices.findIndex(
        ({ id }: LuckyWheelPrice): boolean => id === this.initialReward?.id,
      )
      this.activeClaim = realIndex
      gsap.to(this.$refs.pricesSection, {
        rotation: realIndex * -45,
        duration: 0,
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.spinning-wheel {
  box-sizing: border-box;
  position: relative;
  width: 38.8125rem;
  height: 38.8125rem;

  .finish {
    position: absolute;
    z-index: 1;
    width: 14.625rem;
    height: 18.4375rem;
    background: url($path-events + 'luckyWheel/lucky-wheel-gold-triangle.png') center no-repeat;
    background-size: contain;
    top: 5%;
    left: 31%;
  }

  .prices {
    width: 38.8125rem;
    height: 38.8125rem;
    border-radius: 50%;
    background-color: #ccc;
    position: relative;
    overflow: hidden;
    background: url($path-events + 'luckyWheel/lucky-wheel-wheel.png') center no-repeat;
    background-size: contain;

    .wheel-middle {
      width: 6.25rem;
      height: 6.5625rem;
      position: absolute;
      top: 42%;
      left: 42%;
      z-index: 5;
      background: url($path-events + 'luckyWheel/lucky-wheel-middle-circle.png') center no-repeat;
      background-size: contain;
    }

    .price {
      height: 50%;
      width: 21.75rem;
      position: absolute;
      clip-path: polygon(100% 0, 50% 100%, 0 0);
      transform: translateX(-50%);
      transform-origin: bottom;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.7);
      font-size: 2.25rem;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      font-weight: bold;
      left: 8.4375rem;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      @if $isWsm {
        font-style: italic;
      }

      .icon {
        margin-top: 2rem;
        margin-bottom: 1rem;
      }
    }

    .set-1 {
      background-color: rgba(107, 26, 53, 0);
      left: 50%;
    }

    .set-2 {
      background-color: rgba(107, 26, 53, 0);
      transform: rotate(45deg);
    }
    .set-3 {
      background-color: rgba(107, 26, 53, 0);
      transform: rotate(90deg);
    }
    .set-4 {
      background-color: rgba(107, 26, 53, 0);
      transform: rotate(135deg);
    }
    .set-5 {
      background-color: rgba(107, 26, 53, 0);
      transform: rotate(180deg);
    }
    .set-6 {
      background-color: rgba(107, 26, 53, 0);
      transform: rotate(225deg);
    }
    .set-7 {
      background-color: rgba(107, 26, 53, 0);
      transform: rotate(270deg);
    }
    .set-8 {
      background-color: rgba(13, 34, 56, 0);
      transform: rotate(315deg);
    }

    .victory:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: url($path-events + 'luckyWheel/lucky-wheel-win.png');
      background-repeat: no-repeat;
      background-position-y: 2.0625rem;
      background-position-x: 3.375rem;
      background-size: 67%;
      z-index: -1;
      animation: fadeinquicklywheel 1s linear 0s normal forwards 1;
    }
  }

  @keyframes fadeinquicklywheel {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    40% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
