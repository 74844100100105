<template>
  <div class="premium-level-bag flex items-center relative" :class="[`bag-${packType}`]">
    <span class="bag-name text-texts-standard-default text-36 font-bold uppercase absolute flexing">
      <p>
        {{ packType }}
      </p>
    </span>
    <span class="bag-icon absolute flexing" :class="{ locked: packStatus === 'locked' }">
      <app-icon v-if="packStatus === 'locked'" icon-name="lock-sm" />
    </span>
    <span v-if="canBuy" class="bag-value absolute">
      <app-plate-value color="red" :text-value="`<span class='font-bold'>${value}x</span> value`" />
    </span>
    <div class="flexing bag-footer">
      <app-multi-button
        v-if="canBuy"
        :btn-id="`small-bag-btn-${packType}`"
        btn-type="confirm"
        btn-size="md"
        multi-width="21.5rem"
        @click="buyPack(storeId)"
      >
        <template #leftPart>
          <span class="text-32"> {{ $t('button.purchase') }}</span>
        </template>
        <template #rightPart>
          <span class="text-32">
            {{ `${price.price}${price.currency}` }}
          </span>
        </template>
      </app-multi-button>
      <p
        v-if="isClaimed"
        class="claimed-text text-texts-standard-positive text-40 uppercase font-bold"
      >
        {{ $t('premiumGroup.purchased') }}
      </p>
      <p v-if="isFree" class="text-texts-standard-default text-48 uppercase font-bold">
        {{ 'free' }}
      </p>
    </div>
    <payment-terminal
      v-if="terminal"
      :item-id="storeId"
      :wire-transfer-off="true"
      @close="terminal = false"
    />
  </div>
</template>

<script lang="ts">
import { metaPremiumPackEndpoint, metaPremiumPayEndpoint } from '@/globalVariables'
import { getIsMobileLocalStorage, sendToFlutter } from '@/helpers'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'
import AppPlateValue from '@/components/GlobalComponents/AppPlateValue.vue'
import PaymentTerminal from '@/components/Popup/Premium/PaymentTerminal.vue'

import { LevelUpBagType } from '@/interfaces/premium/enums'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type Price from '@/interfaces/Price'
import type PremiumPackApiResponse from '@/interfaces/responses/premium/PremiumPackApiResponse'
import type PremiumPayApiResponse from '@/interfaces/responses/premium/PremiumPayApiResponse'

interface ComponentData {
  terminal: boolean
}

export default defineComponent({
  name: 'LevelBagSmall',
  components: {
    AppPlateValue,
    PaymentTerminal,
  },
  props: {
    packType: {
      type: String,
      required: true,
      validator(propName: LevelUpBagType): boolean {
        return [
          LevelUpBagType.Bronze,
          LevelUpBagType.Silver,
          LevelUpBagType.Gold,
          LevelUpBagType.Diamond,
        ].includes(propName)
      },
    },
    packStatus: {
      type: String,
      required: true,
      validator(propName: string) {
        return ['locked', 'claimed'].includes(propName)
      },
    },
    price: {
      type: Object as PropType<Nullable<Price>>,
      default: () => null,
    },
    value: {
      type: Number,
      default: 0,
    },
    storeId: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      terminal: false,
    }
  },
  computed: {
    isMobileLocalStorage(): boolean {
      return getIsMobileLocalStorage() === 1
    },
    canBuy(): boolean {
      return this.packStatus === 'locked' && this.price.price > 0
    },
    isClaimed(): boolean {
      return this.packStatus === 'claimed' && this.price.price > 0
    },
    isFree(): boolean {
      return this.price.price === 0
    },
  },
  methods: {
    ...mapActions(useResponseTaskStore, {
      showPaymentLoading: 'showPaymentLoading',
      showPaymentMessage: 'showPaymentMessage',
    }),
    async buyPack(storeId: string): Promise<void> {
      if (!this.isMobileLocalStorage && this.price.price > 0) {
        this.terminal = true
        return
      }

      this.showPaymentLoading()

      const responsePay = await this.$axios.get<{}, PremiumPayApiResponse>(
        `${metaPremiumPayEndpoint}/pack/${storeId}${
          !this.isMobileLocalStorage ? '/bank_transfer' : ''
        }`,
      )
      if (responsePay?.event) {
        const message = {
          event: responsePay.event,
          productId: responsePay.productId,
        }
        sendToFlutter(JSON.stringify(message))
        return
      }
      const responsePack = await this.$axios.get<{}, PremiumPackApiResponse>(
        `${metaPremiumPackEndpoint}${storeId}`,
      )
      this.showPaymentMessage({
        pack: storeId,
        rewards: responsePack.rewards,
        failed: false,
        af_quantity: responsePack.firebaseData.af_quantity,
        af_store_id: responsePack.firebaseData.af_store_id,
        gtm_currency: responsePack.firebaseData.gtm_currency,
        gtm_revenue: responsePack.firebaseData.gtm_revenue,
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.premium-level-bag {
  width: 32.5rem;
  height: 8.75rem;
  background-repeat: no-repeat;
  background-size: contain;

  .bag-icon {
    width: 8.438rem;
    height: 7.688rem;
    background-repeat: no-repeat;
    background-size: contain;
    left: 1rem;
  }

  .bag-name {
    width: 18.5rem;
    height: 2.5rem;
    border-style: solid;
    border-width: 0.125rem;
    border-image-slice: 1;
    top: 0.75rem;
    left: 4.5rem;
  }

  .bag-value {
    top: 0.3rem;
    right: 0.25rem;
    transform: scale(0.85);
  }

  .bag-footer {
    position: absolute;
    bottom: 0;
    right: 1.25rem;
    width: 22.5rem;
    height: 5.5rem;
  }

  &.bag-bronze {
    background-image: url($path-premium + 'bags/tab/bg-pack-bronze.webp');

    .bag-icon {
      background-image: url($path-premium + 'bags/tab/small-bag-bronze.webp');
    }

    .bag-name {
      background: linear-gradient(
        90deg,
        rgba(158, 78, 42, 0) 0%,
        rgba(158, 78, 42, 1) 30%,
        rgba(158, 78, 42, 1) 50%,
        rgba(158, 78, 42, 0) 100%
      );
      border-image-source: linear-gradient(
        90deg,
        rgba(227, 138, 98, 0) 15%,
        rgba(227, 138, 98, 1) 35%,
        rgba(227, 138, 98, 1) 75%,
        rgba(227, 138, 98, 0) 95%
      );
    }
  }

  &.bag-silver {
    background-image: url($path-premium + 'bags/tab/bg-pack-silver.webp');

    .bag-icon {
      background-image: url($path-premium + 'bags/tab/small-bag-silver.webp');

      &.locked {
        background-image: url($path-premium + 'bags/tab/small-bag-silver-locked.webp');
      }
    }

    .bag-name {
      background: linear-gradient(
        90deg,
        rgba(89, 89, 89, 0) 0%,
        rgba(89, 89, 89, 1) 30%,
        rgba(89, 89, 89, 1) 50%,
        rgba(89, 89, 89, 0) 100%
      );
      border-image-source: linear-gradient(
        90deg,
        rgba(158, 158, 158, 0) 15%,
        rgba(158, 158, 158, 1) 35%,
        rgba(158, 158, 158, 1) 75%,
        rgba(158, 158, 158, 0) 95%
      );
    }
  }

  &.bag-gold {
    background-image: url($path-premium + 'bags/tab/bg-pack-gold.webp');

    .bag-icon {
      background-image: url($path-premium + 'bags/tab/small-bag-gold.webp');

      &.locked {
        background-image: url($path-premium + 'bags/tab/small-bag-gold-locked.webp');
      }
    }

    .bag-name {
      background: linear-gradient(
        90deg,
        rgba(152, 105, 17, 0) 0%,
        rgba(152, 105, 17, 1) 30%,
        rgba(152, 105, 17, 1) 50%,
        rgba(152, 105, 17, 0) 100%
      );
      border-image-source: linear-gradient(
        90deg,
        rgba(223, 174, 78, 0) 15%,
        rgba(223, 174, 78, 1) 35%,
        rgba(223, 174, 78, 1) 75%,
        rgba(223, 174, 78, 0) 95%
      );
    }
  }

  &.bag-diamond {
    background-image: url($path-premium + 'bags/tab/bg-pack-diamond.webp');

    .bag-icon {
      background-image: url($path-premium + 'bags/tab/small-bag-diamond.webp');

      &.locked {
        background-image: url($path-premium + 'bags/tab/small-bag-diamond-locked.webp');
      }
    }

    .bag-name {
      background: linear-gradient(
        90deg,
        rgba(102, 36, 169, 0) 0%,
        rgba(102, 36, 169, 1) 30%,
        rgba(102, 36, 169, 1) 50%,
        rgba(102, 36, 169, 0) 100%
      );
      border-image-source: linear-gradient(
        90deg,
        rgba(168, 100, 228, 0) 15%,
        rgba(168, 100, 228, 1) 35%,
        rgba(168, 100, 228, 1) 75%,
        rgba(168, 100, 228, 0) 95%
      );
    }
  }

  .claimed-text {
    &:before {
      content: '[';
      margin-right: 1rem;
      font-style: normal;
      @if $isWsm {
        color: #5199c6;
      }
      @if $isSsm {
        color: #7b87b8;
      }
    }

    &:after {
      content: ']';
      margin-left: 1rem;
      font-style: normal;
      @if $isWsm {
        color: #5199c6;
      }
      @if $isSsm {
        color: #7b87b8;
      }
    }
  }
}
</style>
