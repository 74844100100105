<template>
  <div
    class="history-item w-full relative flex items-center"
    :class="{ hurry, 'is-claimed': historyData.status === GRAND_PRIZE_CLAIMED }"
  >
    <div class="info-icon-triangle cursor-pointer clickable-element" @click="showPopup = true">
      <info-popup
        v-if="showPopup"
        :popup-title="$t('grandPrize.dropRatesHeader')"
        @close="showPopup = false"
      >
        <grand-prize-popup :level="historyData.gpLevel" />
      </info-popup>
    </div>
    <app-main-icon
      :icon-size="120"
      :icon-name="'gp-' + historyData.gpType"
      class="history-item-icon absolute"
    />
    <div class="history-item-texts text-left">
      <div class="history-item-texts-top flex items-baseline">
        <p class="text-texts-standard-important uppercase text-36">
          {{ historyData.gpType }}
        </p>
        <p class="text-texts-standard-name text-24">
          <app-time-formatter :timer="historyData.existsTime" />
        </p>
      </div>
      <div class="history-item-texts-bottom">
        <p class="text-28">
          {{
            $replaceUntranslatablePlaceholder(
              $t('grandPrize.' + historyData.gpProgressName),
              '{grandprize}',
            )
          }}
        </p>
      </div>
    </div>

    <div class="row flex justify-end">
      <div class="row-skew flex items-center">
        <section class="flex items-center w-full">
          <div v-if="historyData.status !== GRAND_PRIZE_UNOPENED" class="history-item-rewards flex">
            <div
              v-for="(reward, index) in historyData.rewards"
              :key="index"
              :class="
                'flex ' +
                (historyData.rewards.length >= 6 ? 'gp-badges-margin-lg' : 'gp-badges-margin')
              "
            >
              <grand-prize-badges :badge="reward.rarity" :reward="reward" size="md" />
            </div>
          </div>
          <div
            v-if="historyData.status === GRAND_PRIZE_CLAIMED"
            class="text-36 history-item-right claimed text-texts-standard-positive uppercase flexing font-bold"
          >
            <p class="pr-2">
              {{ $t('common.rewardsClaimed') }}
            </p>
            <app-icon icon-name="done-md" />
          </div>
          <div
            v-else
            class="text-28 history-item-right text-texts-standard-default uppercase flexing flex-col"
          >
            <div class="flex mb-2">
              <p class="pr-2 not-italic">{{ $t('common.timeRemaining') }}:</p>
              <div class="icon-time" />
              <vue-countdown
                v-slot="{ days, hours, minutes, seconds }"
                :time="historyData.time"
                @end="$emit('timeOut')"
              >
                <span class="text-texts-standard-important not-italic pl-2 font-bold">
                  {{ formatTime(days, hours, minutes, seconds) }}
                </span>
              </vue-countdown>
            </div>
            <app-button
              v-if="openable"
              class="history-item-right-button"
              btn-type="secondary"
              :btn-text="$t('grandPrize.open')"
              @click="$emit('open', historyData)"
            />
            <app-button
              v-else
              class="history-item-right-button"
              btn-type="confirm"
              :btn-text="$t('common.claim')"
              @click="$emit('claim', historyData)"
            />
          </div>
        </section>
      </div>
      <div class="row-skew-strip" />
    </div>
  </div>
</template>

<script lang="ts">
import GrandPrizeBadges from '@/components/GrandPrize/GrandPrizeBadges.vue'
import GrandPrizePopup from '@/components/GrandPrize/GrandPrizePopup.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { GRAND_PRIZE_CLAIMED, GRAND_PRIZE_UNCLAIMED, GRAND_PRIZE_UNOPENED } from '@/globalVariables'
import { formatTime } from '@/helpers'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { GrandPrizeHistoryData } from '@/interfaces/GrandPrize'

interface ComponentData {
  GRAND_PRIZE_UNOPENED: typeof GRAND_PRIZE_UNOPENED
  GRAND_PRIZE_CLAIMED: typeof GRAND_PRIZE_CLAIMED
  showPopup: boolean
}

export default defineComponent({
  name: 'GrandPrizeHistoryItem',
  components: {
    InfoPopup,
    GrandPrizePopup,
    GrandPrizeBadges,
  },
  props: {
    historyData: {
      type: Object as PropType<Nullable<GrandPrizeHistoryData>>,
      default: () => null,
    },
  },
  emits: ['claim', 'open', 'timeOut'],
  data(): ComponentData {
    return {
      GRAND_PRIZE_UNOPENED,
      GRAND_PRIZE_CLAIMED,
      showPopup: false,
    }
  },
  computed: {
    openable(): boolean {
      return this.historyData.status === GRAND_PRIZE_UNOPENED
    },
    hurry(): boolean {
      const hurryStates = [GRAND_PRIZE_UNOPENED, GRAND_PRIZE_UNCLAIMED]
      return (
        hurryStates.includes(this.historyData.status) &&
        this.historyData.time / 1000 < this.historyData.timeWarning
      )
    },
  },
  methods: {
    formatTime,
  },
})
</script>

<style lang="scss" scoped>
.history-item {
  border: solid 0.063rem theme('borderColor.table-odd');
  background-color: theme('backgroundColor.table-odd');
  height: 9.75rem;
  margin-bottom: 0.625rem;

  &:nth-of-type(even) {
    border: solid 0.063rem theme('borderColor.table-even');
    background-color: theme('backgroundColor.table-even');
  }

  &.is-claimed {
    @if $isSsm {
      border: solid 0.125rem theme('colors.texts.standard.positive');
    }
  }

  .row {
    width: 67.375rem;

    &-skew {
      clip-path: polygon(2.4% 0%, 100% 0%, 100% 100%, 0% 100%);
      width: 98%;
    }
  }

  &.hurry {
    background-color: #e81414;
    border-color: #e81414;

    .row {
      &-skew,
      &-skew-strip {
        background: #600a0a;
      }
    }
  }

  &-icon {
    left: 1rem;
  }

  &-texts {
    position: relative;
    left: 12rem;
    width: 38rem;

    &-top {
      align-items: baseline;

      p {
        margin-right: 0.5rem;
      }
    }

    &-bottom {
      p {
        margin-right: 5.75rem;
      }
    }
  }

  &-rewards {
    margin-left: 2rem;
    margin-top: 1rem;

    .gp-badges-margin {
      margin-right: 1rem;
    }

    .gp-badges-margin-lg {
      margin-right: 0.5rem;
    }
  }

  &-right {
    position: absolute;
    right: 0.813rem;

    &.claimed {
      width: 18.75rem;
    }

    &-button {
      width: 20.313rem;
    }
  }
}
</style>
