<template>
  <div class="events-warehouse-footer flexing safe-area">
    <events-warehouse-footer-item
      v-for="(ingredient, index) in filteredItems"
      :key="index"
      :ingredient="ingredient"
    />
  </div>
</template>

<script lang="ts">
import type { IngredientAmount } from '@/interfaces/responses/events/warehouse/WarehouseSharedInterface'
import { useWarehouseStore } from '@/store/pinia/events/warehouseStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import EventsWarehouseFooterItem from './EventsWarehouseFooterItem.vue'

export default defineComponent({
  name: 'EventsWarehouseFooter',
  components: {
    EventsWarehouseFooterItem,
  },
  computed: {
    ...mapState(useWarehouseStore, {
      getIngredientsState: 'getIngredientsState',
    }),
    filteredItems(): IngredientAmount[] {
      // Show ingredients for footer - without winter essence type of ingredient - should be always last in array from BE
      if (!this.getIngredientsState) return
      return this.getIngredientsState.slice(0, this.getIngredientsState.length - 1)
    },
  },
  async created(): Promise<void> {
    await this.loadState()
  },

  methods: {
    ...mapActions(useWarehouseStore, {
      loadState: 'loadState',
    }),
  },
})
</script>

<style lang="scss" scoped>
.events-warehouse-footer {
  height: 9rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
