import {
  CATEGORY_BENEFIT,
  CHAMPIONSHIP_POINTS,
  CLUB_CHAMPIONSHIP_LEAGUES,
  clubChampionshipsDetailEndpoint,
  clubChampionshipsLeaguesEndpoint,
} from '@/globalVariables'
import { getIconName } from '@/helpers'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import type {
  ClubChampsLeague,
  ClubChampsDivision,
  ClubChampionshipsDetail,
} from '@/interfaces/clubs/ClubChampionships'
import type {
  ClubChampsResponse,
  ClubChampsDivisionResponse,
  ClubChampsDivisionDetailResponse,
  ClubChampsDivisionStanding,
} from '@/interfaces/responses/club/ClubChampionshipsResponses'

interface ClubChampsState {
  leagues: ClubChampsLeague[]
  division: ClubChampsDivision[]
  detail: ClubChampionshipsDetail
  remainingTime: number
}

export const useClubChampionshipStore = defineStore('useClubChampionshipStore', {
  state: (): ClubChampsState => ({
    leagues: [],
    division: [],
    detail: {
      position: 0,
      progress: null,
      championshipPoints: 0,
      championshipState: {
        nextStart: {
          date: '',
          time: {
            hours: 0,
            minutes: 0,
          },
        },
        state: '',
      },
      division: {
        id: '',
        type: '',
        number: 0,
      },
    },
    remainingTime: 0,
  }),
  getters: {
    getLeagues(): ClubChampsState['leagues'] {
      return this.leagues
    },
    getLeagueByType(): (type: string) => ClubChampsLeague[] {
      return (type: string): ClubChampsLeague[] =>
        this.leagues.filter((league: ClubChampsLeague): boolean => league.leagueType === type)
    },
    getRookieLeagueByNumber(): (number: number) => ClubChampsLeague[] {
      return (number: number) =>
        this.leagues.filter(
          (league: ClubChampsLeague): boolean =>
            league.leagueType === CLUB_CHAMPIONSHIP_LEAGUES.rookie &&
            league.rookieLeagueNumber === number,
        )
    },
    getDetail(): ClubChampsState['detail'] {
      return this.detail
    },
    getDivision(): ClubChampsState['division'] {
      return this.division
    },
    getRemainingTime(): ClubChampsState['remainingTime'] {
      return this.remainingTime
    },
    getClubChampionshipStart(): ClubChampsState['detail']['championshipState'] {
      return this.detail.championshipState
    },
  },
  actions: {
    async loadDetail(): Promise<void> {
      const data = await internalAxios.get<{}, ClubChampsResponse>(clubChampionshipsDetailEndpoint)
      const mutatedData = {
        position: data.division.position,
        progress: data.division.progress,
        championshipPoints: data.division.championship_points ?? 0,
        championshipState: {
          nextStart: {
            date: data.club_championship_state.next_start.date,
            time: {
              hours: data.club_championship_state.next_start.time.hours,
              minutes: data.club_championship_state.next_start.time.minutes,
            },
          },
          state: data.club_championship_state.state,
        },
        division: {
          id: data.division.division.division_id,
          type: data.division.division.league?.parameters.league_type,
          number: data.division.division.division_number,
          rookieLeagueNumber: data.division.division.rookie_league_number ?? 0,
        },
      }
      this.detail = mutatedData
    },
    async loadLeagues(): Promise<void> {
      const data = await internalAxios.get<{}, ClubChampsDivisionResponse[]>(
        clubChampionshipsLeaguesEndpoint,
      )
      const mutatedData = data.map((league: ClubChampsDivisionResponse) => {
        return {
          divisionId: league.division_id,
          leagueType: league.league.parameters.league_type,
          divisionNumber: league.division_number,
          rookieLeagueNumber: league.rookie_league_number ?? 0,
        }
      })

      this.leagues = mutatedData
    },
    async loadDivision(divisionId: string): Promise<void> {
      const data = await internalAxios.post<{}, ClubChampsDivisionDetailResponse>(
        clubChampionshipsLeaguesEndpoint,
        {
          division_id: divisionId,
        },
      )

      const mutatedData = data.standings.map(
        (division: ClubChampsDivisionStanding): ClubChampsDivision => {
          return {
            position: division.position,
            club: {
              name: division.club.stats.name,
              country: division.club.stats.country.three_letter_code,
              logoId: division.club.stats.logo_id,
              logoBgId: division.club.stats.logo_background_id,
              id: division.club.id,
            },
            members: division.club.members_count,
            points: {
              count: division.championship_points ?? 0,
              icon: CHAMPIONSHIP_POINTS,
            },
            rewards: division.rewards.map((reward) => {
              if (reward.type === CATEGORY_BENEFIT) {
                return {
                  type: getIconName(reward),
                  value: reward.value,
                }
              }
              return reward
            }),
            progress: division.progress,
          }
        },
      )

      this.remainingTime = data?.club_championship_state?.remaining_time
      this.division = mutatedData
    },
  },
})
