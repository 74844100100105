import { defineStore } from 'pinia'
import { internalAxios } from '@/plugins/vueAxios'

import {
  bountyBoardConfigEndpoint as TASKBOARD_CONFIG_ENDPOINT,
  bountyBoardStateEndpoint as TASKBOARD_STATE_ENDPOINT,
  bountyBoardActivateTaskEndpoint as TASKBOARD_ACTIVATE_TASK_ENDPOINT,
  bountyBoardDeactivateTaskEndpoint as TASKBOARD_DEACTIVATE_TASK_ENDPOINT,
  bountyBoardClaimEndpoint as TASKBOARD_CLAIM_ENDPOINT,
  bountyBoardSkipTaskEndpoint as TASKBOARD_SKIP_TASK_ENDPOINT,
  bountyBoardResetTasksEndpoint as TASKBOARD_RESET_TASKS_ENDPOINT,
} from '@/globalVariables'

import type TaskBoardConfigApiResponse from '@/interfaces/responses/events/task-board/TaskBoardConfigApiResponse'
import type TaskBoardStateApiResponse from '@/interfaces/responses/events/task-board/TaskBoardStateApiResponse'

export interface TaskBoardState {
  config: TaskBoardConfigApiResponse | null
  state: TaskBoardStateApiResponse | null
}

export const useTaskBoardStore = defineStore('taskBoardStore', {
  state: (): TaskBoardState => ({
    config: null,
    state: null,
  }),
  getters: {
    getConfig(): TaskBoardConfigApiResponse | null {
      return this.config
    },
    getTasks(): TaskBoardStateApiResponse['tasks'] | null {
      return this.state?.tasks ?? null
    },
    getCompetitionMilestones(): TaskBoardStateApiResponse['competition_milestones'] | null {
      return this.state?.competition_milestones ?? null
    },
    getShowConfirmation(): TaskBoardConfigApiResponse['show_confirmation'] {
      return this.config?.show_confirmation ?? 0
    },
  },
  actions: {
    async loadConfig(): Promise<void> {
      try {
        const data = await internalAxios.get<{}, any>(TASKBOARD_CONFIG_ENDPOINT)
        if (!data) return
        this.config = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadState(): Promise<void> {
      try {
        const data = await internalAxios.get<{}, TaskBoardStateApiResponse>(
          TASKBOARD_STATE_ENDPOINT,
        )
        if (!data) return
        this.state = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async activateTask(taskId: number, hideConfirmWindow: boolean): Promise<void> {
      try {
        const data = await internalAxios.post<{}, any>(TASKBOARD_ACTIVATE_TASK_ENDPOINT, {
          taskId,
          hideConfirm: hideConfirmWindow,
        })
        if (!data) return
        this.state = data

        // Call config refresh to reflect updated confirmation window display
        await this.loadConfig()
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async deactivateTask(): Promise<void> {
      try {
        const data = await internalAxios.post<{}, any>(TASKBOARD_DEACTIVATE_TASK_ENDPOINT)
        if (!data) return
        this.state = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async skipTask(taskId: number): Promise<void> {
      try {
        const data = await internalAxios.post<{}, any>(TASKBOARD_SKIP_TASK_ENDPOINT, {
          taskId: taskId,
        })
        if (!data) return
        this.state = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async resetTasks(): Promise<void> {
      try {
        const data = await internalAxios.post<{}, any>(TASKBOARD_RESET_TASKS_ENDPOINT)
        if (!data) return
        this.state = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async claim(): Promise<void> {
      try {
        const data = await internalAxios.post<{}, any>(TASKBOARD_CLAIM_ENDPOINT)
        if (!data) return
        this.state = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
