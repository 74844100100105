<template>
  <info-message :info-message-data="popupObject" @info-btn-click="goToHome" />
</template>

<script lang="ts">
import InfoMessage, { type InfoMessageData } from '@/components/InfoMessage.vue'
import { REJON_CLUB_HOURS } from '@/globalVariables'
import { defineComponent } from 'vue'

type HeadStyle = 'additional' | 'default' | 'important' | 'name'

export interface ClubsSettingsPopupInterface {
  headerText?: string
  headStyle: HeadStyle
  buttonText: string
  infoText: string
}

export default defineComponent({
  name: 'ClubsSettingsPopup',
  components: {
    InfoMessage,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator(value: string): boolean {
        return ['settings', 'delete'].includes(value)
      },
    },
  },
  computed: {
    // TODO doriesit default
    popupObject(): InfoMessageData {
      const popupData: Partial<ClubsSettingsPopupInterface> = {}

      switch (this.type) {
        case 'settings':
          popupData.headerText = this.$t('club.clubSettings')
          popupData.headStyle = (this.$isWsm ? 'name' : 'default') as HeadStyle
          popupData.buttonText = ''
          popupData.infoText = this.$t('club.changesSaved')
          break
        case 'delete':
          popupData.headerText = this.$t('club.clubDeleted')
          popupData.headStyle = 'important' as HeadStyle
          popupData.buttonText = this.$t('career.home')
          popupData.infoText = this.$replacePlaceholder(
            this.$t('club.afterClubDeleted'),
            '{number}',
            String(REJON_CLUB_HOURS),
          )
          break
      }
      return popupData
    },
  },
  methods: {
    goToHome(): void {
      if (this.popupObject.buttonText) {
        this.$router.push('/')
      }
    },
  },
})
</script>
