<template>
  <div id="equipment-addon" data-cy="equipment-addon" class="equipment-addon">
    <equipment-popup-header-parameters :premium="addon.premium" />
    <header :class="addon.premium ? 'premium-header__wrapper' : 'normal-header__wrapper'">
      <div class="flexing" :class="addon.premium ? 'premium-header' : 'normal-header'">
        <h2 class="text-texts-standard-additional text-40 font-bold uppercase">
          {{ $t(`${getUpgradeAddonName(addon)}`) }}
        </h2>
      </div>
    </header>
    <main>
      <div class="equipment-addon-wrapper flex">
        <equipment-addon-popup-box
          v-if="addon"
          :title="$t('equipmentShop.currentLevel')"
          :discipline-id="disciplineId"
          :current-level="currentLevel"
          :addon="addon"
        />
        <div class="equipment-addon-bonus-up flexing">
          <div class="triangle triangle-left green" />
          <div class="equipment-addon-bonus-up-value flexing">
            <p class="text-texts-standard-upgrade text-28">
              {{ calculateBonusDifference() }}
            </p>
          </div>
          <div class="triangle triangle-left green" />
        </div>
        <equipment-addon-popup-box
          :is-next-level="true"
          :title="$t('equipmentShop.nextLevel')"
          :discipline-id="disciplineId"
          :current-level="currentLevel"
          :addon="addon"
        />
      </div>
    </main>
    <footer v-if="!isContentLoading" class="flex items-center">
      <div v-if="isNextLevelAvailable" class="ml-4">
        <info-text :text="$t('equipmentShop.upgradeInfo')" />
      </div>
      <div
        v-else-if="isOngoingOperation && !isContentLoading"
        class="equipment-addon-footer equipment-addon-footer--ongoing"
      >
        <p class="text-texts-standard-important text-30 mr-8">
          {{ $t('equipmentShop.upgrading') }}
        </p>
        <equipment-item-timer
          :time-to-end="itemActualTime ?? 0"
          class="eq-item-timer-margin"
          @finish-operation="$emit('finishOperation')"
        />
        <app-multi-button1
          btn-id="eq-addon-buy-speedup-btn"
          :btn-text="$t('common.speedUp')"
          :btn-count="itemActualPrice"
          :btn-type="CREDIT_OPERATION"
          :popup-data="
            $filters.$confirmPopupData({
              title: $t('equipmentShop.questSpeedUp'),
              btnId: 'btn-equiped',
              btnText: $t('common.speedUp'),
              btnValue: itemActualPrice,
            })
          "
          @action="$debounce(speedupOperation)"
        >
          <!-- EQ TODO neozobrauje zatial -->
          <template #popupSlot>
            <div class="container-upgrade text-36 text-texts-standard-default">
              <p>
                <!--     {{ $t( OPERATION_MAPPING[ongoingOperation.type]) }} -->
              </p>
              <p class="text-texts-standard-important">
                {{ $t(`equipmentShop.${ongoingOperation.itemName}`) }}
              </p>
            </div>
          </template>
        </app-multi-button1>
      </div>
      <div v-else class="equipment-addon-footer equipment-addon-footer--cta">
        <div class="equipment-addon-footer-price flexing">
          <p class="text-texts-standard-default text-34">{{ $t('common.price') }}:</p>
          <price-per-purchase
            v-for="item in prices"
            :key="item.type"
            :price-type="item.type"
            :purchased-price="item.value"
            class="ml-4"
          />
        </div>
        <!-- EQ TODO multi button vlastny confirm popup-->
        <app-multi-button1
          v-tippy="{
            theme: 'WSM',
            allowHTML: true,
            content: ongoingOperation ? $t('equipmentShop.anotherQuestIsActive') : missingSources,
            placement: 'top',
            textAlign: 'center',
            maxWidth: '38rem',
          }"
          btn-id="eq-addon-buy-btn"
          :btn-theme="addon.premium ? 'credit' : 'primary'"
          :btn-text="$t('button.upgrade')"
          :disabled="isLocked"
          :is-icon="false"
          class="app-multi-button--timer"
          @click.stop
          @click="confirm"
        >
          <template #rightPart>
            <span class="flexing">
              <span class="icon-time mr-2" />
              <p class="text-texts-standard-default text-36">
                <vue-countdown
                  v-slot="{ days, hours, minutes, seconds }"
                  :time="addon.nextLevel.upgrade.time"
                  :auto-start="false"
                >
                  {{ formatTime(days, hours, minutes, seconds, true) }}
                </vue-countdown>
              </p>
            </span>
          </template>
        </app-multi-button1>
      </div>
    </footer>
    <component-loading :is-loading="isContentLoading" height="30%" />
  </div>
  <confirm-popup
    v-if="showConfirm"
    :button-data="{
      btnId: 'btn-equiped',
      btnType: 'credit',
      btnSize: 'md',
    }"
    :button-text-left="$t('button.upgrade')"
    :button-text-right="prices[0].value"
    :popup-title="$t('equipmentShop.addOn')"
    @close="showConfirm = false"
    @action="confirm()"
  >
    <template #popupHeader>
      <p>{{ $t('equipmentShop.changeEquipment') }}</p>
    </template>
    <div class="container-upgrade text-36 text-texts-standard-default italic">
      <p>
        {{ $t('equipmentShop.addonUpgradeConfirm') }}
      </p>
      <p class="text-texts-standard-important">
        {{ $t(`equipmentShop.${addon.name}`) }}
      </p>
    </div>
  </confirm-popup>
</template>

<script lang="ts">
import EquipmentAddonPopupBox from '@/components/Equipment/EquipmentAddonPopupBox.vue'
import EquipmentItemTimer from '@/components/Equipment/EquipmentItemTimer.vue'
import EquipmentPopupHeaderParameters from '@/components/Equipment/EquipmentPopupHeaderParameters.vue'
import InfoText from '@/components/InfoText.vue'
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import PricePerPurchase from '@/components/PricePerPurchase.vue'
import {
  CREDIT_OPERATION,
  GEMS,
  MONEY,
  OPERATION_DELIVERY,
  OPERATION_REPAIR,
  OPERATION_UPGRADE,
  RESEARCH_POINTS,
  UPGRADE_PRICE_CASH,
  UPGRADE_PRICE_GEMS,
  UPGRADE_PRICE_RP,
} from '@/globalVariables'
import { formatTime } from '@/helpers'
import { useEquipmentStore } from '@/store/pinia/equipment/equipmentStore'
import { useEquipmentSpeedUpStore } from '@/store/pinia/equipment/speedUpStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'
import type { OngoingOperation, Upgrades } from '@/interfaces/Equipment'
import type { Nullable } from '@/interfaces/utils'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { CLEAR_AND_HIDE_TIMER, SHOP } from '@/map-phaser-new/constants/common'

const MISSING_SOURCES_MAPPING = {
  [UPGRADE_PRICE_CASH]: 'common.notEnoughMoney',
  [UPGRADE_PRICE_GEMS]: 'common.notEnoughGems',
  [UPGRADE_PRICE_RP]: 'common.notEnoughResearchPoints',
}

const ICONS_MAPPING = {
  [UPGRADE_PRICE_CASH]: MONEY,
  [UPGRADE_PRICE_GEMS]: GEMS,
  [UPGRADE_PRICE_RP]: RESEARCH_POINTS,
}

interface EquipmentPrice {
  type: typeof MONEY | typeof GEMS | typeof RESEARCH_POINTS
  value: number
}

interface ComponentData {
  CREDIT_OPERATION: typeof CREDIT_OPERATION
  showConfirm: boolean
  prices: EquipmentPrice[]
  missingSources: string
}

export default defineComponent({
  name: 'EquipmentAddonPopup',
  components: {
    PricePerPurchase,
    ConfirmPopup,
    EquipmentAddonPopupBox,
    EquipmentPopupHeaderParameters,
    EquipmentItemTimer,
    InfoText,
  },
  props: {
    addon: {
      type: Object as PropType<Nullable<Upgrades[0]>>,
      required: true,
      default: () => {
        return {
          name: '',
          level: 0,
          premium: true,
          levels: [],
        }
      },
    },
    currentLevel: {
      type: Number,
      required: true,
    },
    ongoingOperation: {
      type: Object as PropType<Nullable<OngoingOperation>>,
      default: () => null,
    },
    disciplineId: {
      type: Number,
      required: true,
    },
    itemId: {
      type: Number,
      required: true,
    },
    processTime: {
      type: Number,
      default: null,
    },
  },
  emits: ['upgrade', 'finishOperation', 'loadItemDetail'],
  data(): ComponentData {
    return {
      CREDIT_OPERATION,
      showConfirm: false,
      prices: [],
      missingSources: '',
    }
  },
  computed: {
    ...mapState(useUserStore, ['getNotShowConfirm']),
    ...mapState(usePhaserGameIntegrationStore, { phaserEmitter: 'getPhaserEventEmitter' }),

    ...mapState(useEquipmentStore, {
      shopItem: 'getShopItem',
    }),
    ...mapState(useResponseTaskStore, {
      [GEMS]: 'getGems',
      [MONEY]: 'getMoney',
      [RESEARCH_POINTS]: 'getResearchPoints',
    }),
    itemActualTime(): number | undefined {
      const value = this.shopItem.operation?.stats[0].value
      return value !== undefined ? Number(value) : undefined
    },
    itemActualPrice(): number | undefined {
      const value = this.shopItem.operation?.stats[3].value
      return value !== undefined ? Number(value) : undefined
    },
    isLocked(): boolean {
      return Boolean(
        !this.enoughSources() || this.addon.level >= this.currentLevel || this.ongoingOperation,
      )
    },
    isNextLevelAvailable(): boolean {
      return this.currentLevel && this.addon.level === this.currentLevel
    },
    isOngoingOperation(): boolean {
      return (
        this.ongoingOperation &&
        this.addon.name === this.ongoingOperation.itemName &&
        this.ongoingOperation.itemId === this.addon.id
      )
    },
    isContentLoading(): boolean {
      return (
        this.isOngoingOperation !== null &&
        this.itemActualTime === undefined &&
        this.itemActualPrice === undefined
      )
    },
  },
  async created(): Promise<void> {
    this.formatPrices()
    await this.loadShopItem(this.itemId)
  },
  methods: {
    ...mapActions(useEquipmentSpeedUpStore, ['repairSpeedup', 'upgradeSpeedup', 'offerSpeedup']),
    ...mapActions(useEquipmentStore, ['loadShopItem']),
    formatTime,
    enoughSources(): boolean {
      return !this.prices.find(
        (price: EquipmentPrice): boolean =>
          this[price.type] && this[price.type].value < price.value,
      )
    },
    confirm(): void {
      if (this.isLocked || this.ongoingOperation) return

      if (this.addon.premium && !this.getNotShowConfirm && !this.showConfirm) {
        this.showConfirm = true
        return
      }

      this.showConfirm = false
      this.$emit('upgrade')
    },
    formatPrices(): void {
      type TypeKey = keyof Upgrades[0]['nextLevel']['upgrade']['types']
      type TypeValue = Upgrades[0]['nextLevel']['upgrade']['types'][TypeKey]

      const data: EquipmentPrice[] = []
      Object.entries(this.addon.nextLevel.upgrade.types).forEach(
        ([key, value]: [TypeKey, TypeValue]): void => {
          if (this[ICONS_MAPPING[key]].value < value) {
            this.missingSources += '<p>' + this.$t(MISSING_SOURCES_MAPPING[key]) + '</p>'
          }
          data.push({ type: ICONS_MAPPING[key], value: value })
        },
      )
      this.prices = data
    },
    calculateBonusDifference(): string {
      const actualLevelCount = this.addon.currentLevel ? this.addon.currentLevel.upgrade.bonus : 0

      return (
        '+' +
        (this.addon.nextLevel.upgrade.bonus - actualLevelCount) +
        this.addon.nextLevel.parameters[this.addon.type].suffix
      )
    },
    async speedupOperation(): Promise<void> {
      const id = this.ongoingOperation.itemId
      switch (this.ongoingOperation.type) {
        case OPERATION_REPAIR:
          await this.repairSpeedup(String(id))
          break
        case OPERATION_UPGRADE:
          await this.upgradeSpeedup({
            itemId: this.ongoingOperation?.parentId ?? id,
            addonId: id,
          })
          break
        case OPERATION_DELIVERY:
          await this.offerSpeedup(String(id))
          break
      }

      this.$emit('loadItemDetail')
      this.phaserEmitter?.emit(CLEAR_AND_HIDE_TIMER, SHOP)
    },
    getUpgradeAddonName(upgrade: Upgrades[0]): string {
      const name = upgrade.properties.find(
        (prop: Upgrades[0]['properties'][0]): boolean => prop.name === 'text_name',
      )?.value
      return 'equipmentShop.' + (name ?? upgrade.name)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/stars-icons.scss';

.equipment-addon {
  width: 64.25rem;
  height: 31rem;

  .normal-header,
  .premium-header {
    width: 100%;
    height: 100%;

    @if $isSsm {
      background: transparent;
    }

    @if $isWsm {
      background: url($path-equipment + 'addon/equipment-addon-popup-bg.avif') center no-repeat;
      background-size: 100% 100%;
    }
  }

  header {
    width: 85%;
    height: 4.375rem;
    margin: 0.625rem auto 2rem;
    position: relative;

    &.normal-header__wrapper {
      @if $isWsm {
        background: linear-gradient(to right, transparent, #5ba3dc, transparent);
      }
      @if $isSsm {
        background: linear-gradient(to right, transparent, #4c648f, transparent);
      }
    }

    &.premium-header__wrapper {
      @if $isWsm {
        background: linear-gradient(to right, transparent, #742cad, transparent);
      }
      @if $isSsm {
        background: linear-gradient(to right, transparent, #861cd4, transparent);
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0rem;
      height: 0.125rem;
      @if $isWsm {
        background: linear-gradient(to right, transparent, #d9ecff, transparent);
      }
      @if $isSsm {
        background: linear-gradient(to right, transparent, #fff, transparent);
      }
    }
  }

  main {
    .equipment-addon-wrapper {
      margin: 0 auto;

      .equipment-addon-bonus-up {
        width: 10rem;
        height: 4rem;
        position: relative;
        top: 10rem;

        &-value {
          width: 6.25rem;
          height: 1.875rem;
          margin: 0 1rem;
          @if $isWsm {
            background: linear-gradient(
              to right,
              transparent 0%,
              #154e79 40%,
              #154e79,
              transparent 100%
            );
          }
          @if $isSsm {
            background: linear-gradient(to right, transparent 0%, #4c648f, transparent 100%);
          }
        }
      }
    }
  }

  footer {
    width: 100%;
    height: 6.875rem;
    position: absolute;
    bottom: 0;
    @if $isWsm {
      background: #154e79;
      box-shadow: 0 0 0.25rem #1b2f47;
    }
    @if $isSsm {
      background: linear-gradient(to right, #1d1f2c, #2e3c63, #1d1f2c);
    }
  }

  &-footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
  }
}
</style>
