import type { RouteRecordCustom } from './RouteInterface'
import { MECHANIC_BENEFIT, MECHANIC_EVENT } from '@/globalVariables'

// PREMIUM OBRAZOVKY

import PremiumGems from '@/views/premium/PremiumGems.vue'
// import PremiumAds from '@/views/premium/PremiumAds.vue'
import PremiumEnergyDrink from '@/views/premium/PremiumEnergyDrink.vue'
import PremiumOffers from '@/views/premium/PremiumOffers.vue'
import PremiumWeeklyDeals from '@/views/premium/PremiumWeeklyDeals.vue'
import PremiumWildCard from '@/views/premium/PremiumWildCard.vue'

import PremiumEventOffers from '@/views/premium/event/PremiumEventOffers.vue'
import PremiumEventPasses from '@/views/premium/event/PremiumEventPasses.vue'

import PremiumChain from '@/views/premium/PremiumChain.vue'
import PremiumLevelBags from '@/views/premium/PremiumLevelBags.vue'
import PremiumGamePass from '@/views/premium/PremiumGamePass.vue'
import PremiumLevelBagsPacks from '@/views/premium/PremiumLevelBagsPacks.vue'
import PremiumGamePassPacks from '@/views/premium/PremiumGamePassPacks.vue'

import PremiumPiggyBank from '@/views/premium/PremiumPiggyBank.vue'

import HeaderComponent from '@/components/Header/HeaderComponent.vue'

export const premiumRoutesWeb: RouteRecordCustom[] = [
  {
    path: 'premium/gems-web',
    component: PremiumGems,
    name: 'PremiumGemsWeb',
    meta: {
      showCookies: true,
      requiresAuth: true,
    },
  },
  // {
  //   path: 'premium/ads-web',
  //   component: PremiumAds,
  //   name: 'PremiumAdsWeb',
  //   meta: { requiresAuth: true },
  // },
  {
    path: 'premium/offers-web',
    component: PremiumOffers,
    name: 'PremiumOffersWeb',
    meta: { requiresAuth: true },
  },
  {
    path: 'premium/weekly-deals-web',
    component: PremiumWeeklyDeals,
    name: 'PremiumWeeklyDealsWeb',
    meta: { requiresMechanic: MECHANIC_BENEFIT, requiresAuth: true },
  },
  {
    path: 'premium/wild-card-web',
    component: PremiumWildCard,
    name: 'PremiumWildCardWeb',
    meta: {
      showCookies: true,
      requiresAuth: true,
    },
  },
  {
    path: 'premium/energy-drink-web',
    component: PremiumEnergyDrink,
    name: 'PremiumEnergyDrinkWeb',
    meta: {
      showCookies: true,
      requiresAuth: true,
    },
  },
  {
    path: 'premium/purchased-web/:pack_id?/:res?',
    component: PremiumGems,
    name: 'PremiumPurchasedWeb',
    meta: { requiresAuth: true },
  },
  {
    path: 'premium/events-passes-web',
    component: PremiumEventPasses,
    name: 'PremiumEventPassesWeb',
    meta: { mechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: 'premium/events-offers-web',
    component: PremiumEventOffers,
    name: 'PremiumEventOffersWeb',
    meta: { mechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/premium/chain-web',
    component: PremiumChain,
    name: 'PremiumChainWeb',
    meta: {
      showCookies: true,
      requiresAuth: true,
    },
  },
  {
    path: '/premium/level-bags-web',
    component: PremiumLevelBags,
    name: 'PremiumLevelBagsWeb',
    meta: { requiresAuth: true },
  },
  {
    path: '/premium/game-pass-web',
    component: PremiumGamePass,
    name: 'PremiumGamePassWeb',
    meta: { requiresAuth: true },
  },
  {
    path: '/premium/level-bags-packs-web',
    component: PremiumLevelBagsPacks,
    name: 'PremiumLevelBagsPacksWeb',
    meta: { requiresAuth: true },
  },
  {
    path: '/premium/game-pass-packs-web',
    component: PremiumGamePassPacks,
    name: 'PremiumGamePassPacksWeb',
    meta: { requiresAuth: true },
  },
  {
    path: '/premium/piggy-bank-web',
    component: PremiumPiggyBank,
    name: 'PremiumPiggyBankWeb',
    meta: { requiresAuth: true },
  },
]

export const premiumRoutesMobile: RouteRecordCustom[] = [
  {
    path: '/premium/gems',
    name: 'PremiumGems',
    components: {
      default: PremiumGems,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true },
  },
  // {
  //   path: '/premium/ads',
  //   name: 'PremiumAds',
  //   components: {
  //     default: PremiumAds,
  //     header: HeaderComponent,
  //   },
  //   meta: { requiresAuth: true },
  // },
  {
    path: '/premium/offers',
    name: 'PremiumOffers',
    components: {
      default: PremiumOffers,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/premium/weekly-deals',
    name: 'PremiumWeeklyDeals',
    components: {
      default: PremiumWeeklyDeals,
      header: HeaderComponent,
    },
    meta: { requiresMechanic: MECHANIC_BENEFIT, requiresAuth: true },
  },
  {
    path: '/premium/wild-card',
    name: 'PremiumWildCard',
    components: {
      default: PremiumWildCard,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/premium/energy-drink',
    name: 'PremiumEnergyDrink',
    components: {
      default: PremiumEnergyDrink,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/premium/purchased/:pack_id?/:res?',
    name: 'PremiumPurchased',
    components: {
      default: PremiumGems,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/premium/events-passes',
    component: PremiumEventPasses,
    name: 'PremiumEventPasses',
    components: {
      default: PremiumEventPasses,
      header: HeaderComponent,
    },
    meta: { mechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/premium/events-offers',
    component: PremiumEventOffers,
    name: 'PremiumEventOffers',
    components: {
      default: PremiumEventOffers,
      header: HeaderComponent,
    },
    meta: { mechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/premium/chain',
    component: PremiumChain,
    name: 'PremiumChain',
    components: {
      default: PremiumChain,
      header: HeaderComponent,
    },
    meta: {
      showCookies: true,
      requiresAuth: true,
    },
  },
  {
    path: '/premium/level-bags',
    component: PremiumLevelBags,
    name: 'PremiumLevelBags',
    components: {
      default: PremiumLevelBags,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/premium/game-pass',
    component: PremiumGamePass,
    name: 'PremiumGamePass',
    components: {
      default: PremiumGamePass,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/premium/level-bags-packs',
    component: PremiumLevelBagsPacks,
    name: 'PremiumLevelBagsPacks',
    components: {
      default: PremiumLevelBagsPacks,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/premium/game-pass-packs',
    component: PremiumGamePassPacks,
    name: 'PremiumGamePassPacks',
    components: {
      default: PremiumGamePassPacks,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/premium/piggy-bank',
    component: PremiumPiggyBank,
    name: 'PremiumPiggyBank',
    components: {
      default: PremiumPiggyBank,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true },
  },
]
