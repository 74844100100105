<template>
  <div
    :class="{
      'pointer-events-none': actualStage?.name === 'clickOnItemDetail',
    }"
  >
    <equipment-item-box-header :header-data="getHeaderData" />
    <equipment-item-box-empty
      v-if="isLocked || itemData.premium?.locked || itemData.expired"
      :unlock-level="
        itemData.premium?.locked
          ? 0
          : 'questRequired' in itemData
            ? (itemData.questRequired as number)
            : 0
      "
      :is-premium="itemData.premium?.locked || itemData.expired"
      :expired-time="itemData.expired && itemData.expiredTime ? itemData.expiredTime : 0"
      @load-data="$emit('finishOperation')"
    />
    <div
      class="left-side"
      :class="[
        `left-side-${itemData.premium ? 'premium' : itemData.itemStats?.level}`,
        { expired: itemData.expired },
      ]"
    >
      <equipment-item-box-image :image-data="getImageData" />
      <section v-if="itemData.premium" class="flexing stars-container">
        <div v-for="i in 3" :key="i" class="icon-star-full-32" />
      </section>
      <div v-else class="addon-info text-30 text-texts-standard-default flex">
        <p class="text-texts-standard-important">{{ getCurrentAddons }}</p>
        <span v-if="getCurrentAddons && getMaxAddons"></span>/
        <p>{{ getMaxAddons }}</p>
      </div>
    </div>
    <div class="right-side">
      <equipment-item-box-attributes
        :attributes-data="getAttributesData"
        @finish-operation="$emit('finishOperation')"
      />
      <div ref="equipmentItemFooter" class="flexing flex-1">
        <equipment-item-box-footer
          :footer-data="getFooterData"
          @reload-data="$emit('finishOperation')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import EquipmentItemBoxAttributes from '@/components/Equipment/EquipmentItemBoxAttributes.vue'
import EquipmentItemBoxEmpty from '@/components/Equipment/EquipmentItemBoxEmpty.vue'
import EquipmentItemBoxFooter from '@/components/Equipment/EquipmentItemBoxFooter.vue'
import EquipmentItemBoxHeader from '@/components/Equipment/EquipmentItemBoxHeader.vue'
import EquipmentItemBoxImage from '@/components/Equipment/EquipmentItemBoxImage.vue'
import { EQUIPMENT_EQUIPPED } from '@/globalVariables'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import type {
  NormalizedItemData,
  OfferData,
  HeaderData,
  RankData,
  ImageData,
  AttributesData,
  FooterData,
} from '@/interfaces/Equipment'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { animateElement, resetAnimateElement } from '@/helpers'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  EQUIPMENT_EQUIPPED: typeof EQUIPMENT_EQUIPPED
  interval: Nullable<ReturnType<typeof setInterval>>
}

export default defineComponent({
  name: 'EquipmentItemBox',
  components: {
    EquipmentItemBoxHeader,
    EquipmentItemBoxImage,
    EquipmentItemBoxAttributes,
    EquipmentItemBoxFooter,
    EquipmentItemBoxEmpty,
  },
  props: {
    itemData: {
      type: Object as PropType<NormalizedItemData>,
      required: true,
    },
  },
  emits: ['finishOperation'],
  data(): ComponentData {
    return {
      EQUIPMENT_EQUIPPED,
      interval: null,
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      actualStage: 'getActualStage',
    }),
    isLocked(): boolean {
      return Number(this.itemData.itemStats?.level) === 0
    },
    getHeaderData(): HeaderData {
      return {
        name: 'items' in this.itemData ? this.itemData.items[0].name : this.itemData.name,
        equipped: this.itemData.equipped,
        owned: this.itemData.not_equipped,
        expired: this.itemData.expired,
      }
    },
    getRankData(): RankData {
      return {
        levelName: this.itemData.parameters.equipment_level_name,
        levelNumber: this.itemData.itemStats?.level,
      }
    },
    getImageData(): ImageData {
      return {
        isOffer: this.itemData.isOffer,
        properties: this.itemData?.properties,
        items:
          'items' in this.itemData
            ? (this.itemData?.items as OfferData['availableOffers'][0]['items'])
            : null,
        disciplineId: this.itemData.disciplineId,
        level: this.itemData.itemStats?.level ?? '1',
        locked: this.isLocked ?? false,
      }
    },
    getAttributesData(): AttributesData {
      return {
        parameters: this.itemData.parameters,
        unlocked:
          'unlocked' in this.itemData
            ? (this.itemData.unlocked as OfferData['availableOffers'][0]['unlocked'])
            : null,
        currentDurability: this.itemData.itemStats?.durability,
        equipped: this.itemData[EQUIPMENT_EQUIPPED],
        durabilityState: this.itemData.itemDurabilityState,
        operationStats: this.itemData.operationStats,
        operation: this.itemData.operation ?? null,
        stars: this.itemData.levels,
        timeToEnd: this.itemData.timeToEnd,
        disciplineId: this.itemData.disciplineId,
        premium: this.itemData.premium,
        equipmentBonusData: this.itemData.equipmentBonusData,
        repair: this.itemData.repair,
        upgrade: this.itemData.upgrade,
        delivering: !this.itemData.premium ? this.itemData.delivering : false,
      }
    },
    getCurrentAddons(): string {
      if (!this.itemData.hookedItems) return ''

      const currentLevel = this.itemData.hookedItems.reduce(
        (acc: number, item: NormalizedItemData['hookedItems'][0]): number => {
          if (!item.item_stats) return 0
          const level = Number(
            item.item_stats?.find(
              (nextItem: NormalizedItemData['hookedItems'][0]['item_stats'][0]): boolean =>
                nextItem.name === 'level',
            ).value,
          )
          acc += level
          return acc
        },
        0,
      )

      return String(currentLevel)
    },
    getMaxAddons(): string {
      if (!this.itemData.hookedItems) return ''

      const availableLevels = this.itemData.equipmentLevelsAvailable ?? 1
      const maxAddons = this.itemData.hookedItems.length * availableLevels

      return String(maxAddons)
    },
    getFooterData(): FooterData {
      return {
        durabilityState: this.itemData.itemDurabilityState,
        stars: this.itemData.levels,
        unlocked:
          'unlocked' in this.itemData
            ? (this.itemData.unlocked as OfferData['availableOffers'][0]['unlocked'])
            : null,
        // delivering: this.itemData.delivering,
        // repair: this.itemData.repair,
        // upgrade: this.itemData.upgrade,
        premium: this.itemData.premium,
        expired: this.itemData.expired,
      }
    },
    isFirstAvailableOffer(): boolean {
      return this.itemData.isOffer && this.itemData.isFirstOffer
    },
  },
  mounted(): void {
    if (this.isFirstAvailableOffer) {
      this.animateScene()
    }
  },
  beforeUnmount(): void {
    clearInterval(this.interval)
  },
  methods: {
    animateScene(): void {
      const animation = this.$refs.equipmentItemFooter
      const animationLoopTiming = 2000
      const animationLoopBreak = 1000

      animateElement(animation, 'heartbeat', '1s', 0, 'cubic-bezier(.46,.25,.18,.82)')
      this.interval = setInterval((): void => {
        animateElement(animation, 'heartbeat', '1s', 0, 'linear', 'infinite')

        setTimeout((): void => {
          resetAnimateElement(animation)
        }, animationLoopBreak)
      }, animationLoopTiming)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/stars-icons.scss';

.eq-rank-position {
  position: absolute;
  right: 0;
  top: 16.5rem;
}

.left-side {
  width: 31rem;
  height: 18.8rem;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0.45rem;
  left: 0.25rem;

  &-premium {
    background-image: url($path-equipment + 'box-backgrounds/new/bg-equipment-left-premium.avif');
  }

  @for $bg from 1 through 6 {
    &-#{$bg} {
      background-image: url($path-equipment + 'box-backgrounds/new/bg-equipment-left-'+ #{$bg} + '.avif');
    }
  }

  .addon-info {
    position: absolute;
    bottom: 0;
    left: 0.5rem;
  }

  .stars-container {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
  }
}

.right-side {
  display: flex;
  flex-direction: column;
  width: 28.125rem;
  height: 19rem;
  position: absolute;
  bottom: 0.4rem;
  right: 0.2rem;
  padding-left: 3.125rem;
  padding-right: 1.25rem;
}
</style>
