import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const clubCarsParkingToRightCheckpoints: CheckpointsObjectInterface = {
  key: 'clubCarsParkingToRightCheckpoints',
  data: [
    {
      x: 1578.5,
      y: 1598.5,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsParkingToRightAnimations1',
        },
      },
    },
    {
      x: 1580.5,
      y: 1506.5,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.PauseAction,
        action: {
          duration: 2000,
        },
      },
    },
    {
      x: 1665,
      y: 1461,
      width: 10,
      height: 20,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsParkingToRightAnimations2',
        },
      },
    },
    {
      x: 2017.5,
      y: 1532,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsParkingToRightAnimations3',
        },
      },
    },
    {
      x: 2324,
      y: 1490,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsParkingToRightAnimations4',
        },
      },
    },
    {
      x: 2818,
      y: 1589,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsParkingToRightAnimations5',
        },
      },
    },
    {
      x: 2985,
      y: 1563.5,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsParkingToRightAnimations6',
        },
      },
    },
    {
      x: 3093.5,
      y: 1385,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsParkingToRightAnimations7',
        },
      },
    },
  ],
}
