<template>
  <section class="buttons">
    <app-multi-button1
      btn-theme="confirm"
      btn-size="md"
      :btn-text="$te('spin')"
      :btn-count="multiplierNumber.multiplier"
      :hide-action-popup="true"
      :disabled="disabledButtons"
      :is-icon="false"
      :is-resources-status-colored="false"
      @click="spinNormal"
    >
      <template #rightPart>
        <div class="icon-events-EventsLuckyWheel-light ml-2" />
      </template>
    </app-multi-button1>
    <app-multi-button1
      :btn-type="CREDIT_OPERATION"
      btn-size="md"
      :btn-text="$te('spin')"
      :btn-count="multiplierNumber.cost"
      :hide-action-popup="isEnoughGemsBeforeClick"
      :disabled="isSpinning"
      @click="$debounce(spinGem)"
    />
  </section>
</template>

<script lang="ts">
import { CREDIT_OPERATION } from '@/globalVariables'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { LuckyWheelMultiplier } from '../LuckyWheelController.vue'

interface ComponentData {
  CREDIT_OPERATION: typeof CREDIT_OPERATION
  isEnoughGemsBeforeClick: boolean
}

export default defineComponent({
  name: 'SpinActionButton',
  props: {
    disabledButtons: {
      type: Boolean,
      default: false,
    },
    multiplierNumber: {
      type: Object as PropType<LuckyWheelMultiplier>,
      required: true,
    },
    isSpinning: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['spin'],
  data(): ComponentData {
    return {
      CREDIT_OPERATION,
      isEnoughGemsBeforeClick: true,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      gems: 'getGems',
    }),
  },
  methods: {
    spinNormal(): void {
      if (this.disabledButtons) return
      this.$emit('spin', false)
    },
    spinGem(): void {
      this.isEnoughGemsBeforeClick = this.gems?.value >= this.multiplierNumber.cost ? true : false
      this.$emit('spin', true)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/events-icons.scss';

.buttons {
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -3rem;
  position: relative;
}

.button-styles {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .marginer {
    margin-left: 2rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
</style>
