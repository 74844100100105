<template>
  <div>
    <section v-for="(slot, slotId) in benefitsSlots.slots" :key="slotId">
      <use-benefit-button
        v-if="slot"
        :slot-data="slot"
        :slot-id="slotId.toString()"
        :benefit-usage="getBenefitUsage"
        @click="showSelectBenefitPopup = true"
      />
      <use-benefit-button v-else @click="showSelectBenefitPopup = true" />
    </section>
    <section v-if="!hasMechanic(MECHANIC_BENEFIT)" class="flexing">
      <use-benefit-button :lock-icon="true" btn-size="sm" />
    </section>
    <info-popup
      v-if="showSelectBenefitPopup"
      :popup-title="$t('benefits.buttonActiveBenefit')"
      @close="showSelectBenefitPopup = false"
    >
      <select-benefit
        :allowed-benefit-types="benefitsSlots?.benefit_type"
        :current-slots="getBenefitSlots()"
        @close-select-popup="closeSelectAndRefreshSlots()"
      />
    </info-popup>
  </div>
</template>

<script lang="ts">
import SelectBenefit from '@/components/Benefits/SelectBenefit.vue'
import UseBenefitButton from '@/components/Button/UseBenefitButton.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { MECHANIC_BENEFIT, Multiplier, TRAINING_BENEFIT_USAGE } from '@/globalVariables'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { ActiveBenefitSlots, BenefitSlot } from '@/interfaces/Benefits'

interface ComponentData {
  MECHANIC_BENEFIT: typeof MECHANIC_BENEFIT
  TRAINING_BENEFIT_USAGE: typeof TRAINING_BENEFIT_USAGE
  showSelectBenefitPopup: boolean
}

export default defineComponent({
  name: 'TrainingDisciplineFooterBenefits',
  components: {
    InfoPopup,
    SelectBenefit,
    UseBenefitButton,
  },
  props: {
    benefitsSlots: {
      type: Object as PropType<Nullable<BenefitSlot>>,
      default: () => null,
    },
    isTrainer: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      MECHANIC_BENEFIT,
      TRAINING_BENEFIT_USAGE,
      showSelectBenefitPopup: false,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      activeMultiplicator: 'getActiveMultiplicator',
    }),
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
    }),
    getBenefitUsage(): number {
      if (this.isTrainer) return this.TRAINING_BENEFIT_USAGE

      return this.TRAINING_BENEFIT_USAGE * this.activeMultiplicator(Multiplier.Training).value
    },
  },
  methods: {
    //TODO: move this method into helper or all "benefits code" into one component (duplicate of Arena, Prebossfight, buildings, star of the day...)
    getBenefitSlots(): ActiveBenefitSlots {
      const slotsRaw = this.benefitsSlots?.slots
      const slots = {}
      for (const slotId in slotsRaw) {
        slots[slotId] = slotsRaw[slotId] ? slotsRaw[slotId].benefit_gdd_id : 0
      }
      return slots
    },
    closeSelectAndRefreshSlots(): void {
      this.showSelectBenefitPopup = false
    },
  },
})
</script>
