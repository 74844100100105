<template>
  <section class="upgrade-change-box-wrapper">
    <p
      class="text-texts-standard-default text-34 uppercase upgrade-change-box-head"
      :class="{ 'text-texts-standard-important': !isCurrent }"
    >
      {{
        type === CHANGE
          ? isCurrent
            ? $t('equipmentShop.currentEquipment')
            : $t('equipmentShop.newEquipment')
          : isCurrent
            ? $t('equipmentShop.currentLevel')
            : $t('equipmentShop.nextLevel')
      }}
    </p>
    <div class="upgrade-change-box" :class="{ new: !isCurrent }">
      <header class="flex items-center">
        <div class="logo">
          <div class="icon-equipment-equipment-80" />
        </div>

        <div class="stars flex items-center">
          <div v-for="n in levels" :key="n" class="star-wrapper relative">
            <div class="absolute" :class="'icon-star-' + getStarType(n) + '-48'" />
          </div>
        </div>
      </header>
      <section>
        <div v-for="(parameter, key, i) in currentParameters" :key="i">
          <div v-if="key === EQUIPMENT_DURABILITY" class="yellow-strip" />
          <p
            v-if="key === EQUIPMENT_DURABILITY && premium"
            class="text-texts-standard-important text-28 uppercase"
          >
            {{ $t('equipmentShop.indestructible') }}
          </p>
          <div v-else class="bonus-line flex justify-between items-center">
            <p class="text-texts-standard-default text-28">
              {{ $t(BONUS_TRANSLATION_MAPPING[key]).toString() }}
            </p>
            <aside class="flexing">
              <p class="text-texts-standard-default text-28 font-bold">
                <span
                  v-if="type === CHANGE && key === EQUIPMENT_DURABILITY"
                  class="text-texts-standard-important"
                  >{{ getDurability }}/</span
                >
                <span :class="getTextColor(key)">{{ calculateBonus(parameter, key) }} </span>
              </p>
              <equipment-item-attributes-icons
                :icon-type="getAttibutesIcons(key)"
                :discipline-id="disciplineId"
              />
            </aside>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script lang="ts">
import EquipmentItemAttributesIcons from '@/components/Equipment/EquipmentItemAttributesIcons.vue'
import { BONUS_TRANSLATION_MAPPING } from './EquipmentDetail.vue'
import { EQUIPMENT_ATTRIBUTE_BONUS, EQUIPMENT_DURABILITY } from '@/globalVariables'
import { getAttibutesIcons } from '@/helpers'
import type { FormattedEquippedItem } from '@/interfaces/Equipment'
import type { Nullable } from '@/interfaces/utils'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

const UPGRADE = 'upgrade'
const CHANGE = 'change'

interface ComponentData {
  UPGRADE: typeof UPGRADE
  CHANGE: typeof CHANGE
  EQUIPMENT_DURABILITY: typeof EQUIPMENT_DURABILITY
  BONUS_TRANSLATION_MAPPING: typeof BONUS_TRANSLATION_MAPPING
}

export default defineComponent({
  name: 'EquipmentUpgradeChangeBox',
  components: {
    EquipmentItemAttributesIcons,
  },
  props: {
    isCurrent: {
      type: Boolean,
      default: false,
    },
    currentLevel: {
      type: Number,
      required: true,
    },
    levels: {
      type: Number,
      required: true,
    },
    currentParameters: {
      type: Object as PropType<FormattedEquippedItem['parameters']>,
      required: true,
    },
    previousParameters: {
      type: Object as PropType<Nullable<FormattedEquippedItem['parameters']>>,
      required: false,
      default: () => null,
    },
    type: {
      type: String,
      default: UPGRADE,
      validator(value: string): boolean {
        return [UPGRADE, CHANGE].includes(value)
      },
    },
    disciplineId: {
      type: Number,
      required: true,
    },
    premium: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      UPGRADE,
      CHANGE,
      EQUIPMENT_DURABILITY,
      BONUS_TRANSLATION_MAPPING,
    }
  },
  computed: {
    getDurability():
      | FormattedEquippedItem['parameters']['equipment_durability']['currentValue']
      | FormattedEquippedItem['parameters']['equipment_durability']['value'] {
      return this.previousParameters
        ? this.currentParameters[EQUIPMENT_DURABILITY].currentValue
        : this.currentParameters[EQUIPMENT_DURABILITY].value
    },
  },
  methods: {
    getAttibutesIcons,
    getStarType(index: number): string {
      return index <= this.currentLevel ? 'full' : 'empty'
    },
    calculateBonus(
      parameter: FormattedEquippedItem['parameters'][keyof FormattedEquippedItem['parameters']],
      key: keyof FormattedEquippedItem['parameters'],
    ): string {
      const prefix = key === EQUIPMENT_DURABILITY ? '' : '+'
      if (
        this.currentParameters[EQUIPMENT_DURABILITY].currentValue === 0 &&
        key === EQUIPMENT_ATTRIBUTE_BONUS &&
        this.type === CHANGE
      )
        return (parameter.currentValue || 0) + parameter.suffix

      return parameter.value > 0 ? prefix + parameter.value + parameter.suffix : '-'
    },
    getTextColor(key: string): string {
      if (
        !this.previousParameters ||
        this.currentParameters[key].value === this.previousParameters[key].value
      ) {
        return 'text-texts-standard-default'
      }
      return this.currentParameters[key].value < this.previousParameters[key].value
        ? 'text-texts-standard-danger'
        : 'text-texts-standard-important'
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/stars-icons.scss';
@import '@/assets/styles/components/icons/equipment-icons.scss';

.upgrade-change-box {
  width: 24.688rem;
  height: 18.75rem;
  padding: 0.75rem;
  @if $isWsm {
    background-color: #1b2f47;
    border: 0.125rem solid #5ba3dc;
    box-shadow: inset 0rem 0rem 5rem 1rem #154e79;
  }
  @if $isSsm {
    background: #1d1f2c;
    border: 0.125rem solid #4c648f;
  }

  &-wrapper {
    width: 24.688rem;
    margin: 0 1rem;
  }

  &-head {
    margin: 0.75rem 0;
  }

  &.new {
    border-color: #fadd1e;
  }

  header {
    .logo {
      width: 5rem;
      height: 5rem;
    }

    .stars {
      width: 17rem;
      margin-left: 0.5rem;
    }

    .star {
      left: -0.625rem;

      &-wrapper {
        width: 5rem;
        height: 5rem;
        display: flex;
        align-items: center;
      }
    }
  }

  .yellow-strip {
    margin: 0 auto;
  }

  .bonus-line {
    margin: 0.4rem 0;
  }
}
</style>
