<template>
  <section class="flexing flex-col flex-grow w-full relative">
    <arena-view :is-event-view="true" />
  </section>
</template>

<script lang="ts">
import ArenaView from '@/views/arena/ArenaView.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ArenaBody',
  components: {
    ArenaView,
  },
})
</script>
