<template>
  <section>
    <div class="club-chat-unavailable">
      <app-icon icon-name="lock-xxl" />
      <p class="text-texts-standard-default text-36 club-chat-unavailable-text">
        {{ $t('club.chatLockText1') }} <br />
        {{ $t('club.chatLockText2') }}
      </p>
      <app-button
        btn-type="secondary"
        :btn-text="$t('bugReport.clubs')"
        btn-size="xl"
        @click="goToClubs"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ChatUnavailable',
  methods: {
    goToClubs(): void {
      this.$router.push({
        name: this.$getWebView('ClubsAvailable'),
        query: { homepage: 'true' },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.club-chat {
  &-unavailable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 15rem auto;
    top: 50%;

    &-text {
      margin: 1.75rem 0 3.75rem;
    }
  }
}
</style>
