import { InteractiveObject } from '../abstractClasses'
import { isCanvasInactive, playSound } from '@/helpers'
import { internalAxios } from '@/plugins/vueAxios'
import type {
  RewardedItemConfigInterface,
  ClaimEndpointResponseInterface,
  TutorialConfigInterface,
  IndicatorConfigInterface,
  PositionIndicatorConfig,
} from '@/map-phaser-new/interfaces'
import { TutorialItems } from './tutorialItems'
import { Indicator } from './indicator'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import router from '@/router'

export class RewardedItem extends InteractiveObject {
  public claimed: boolean
  public hasTutorial: boolean = false
  public indicator: Indicator | null = null
  private rewardItemImage: Phaser.GameObjects.Image
  private tutorialItems?: TutorialItems | null

  constructor(positionX: number, positionY: number) {
    super(positionX, positionY)
  }

  addToMap(activeScene: Phaser.Scene, baseConfig: RewardedItemConfigInterface): void {
    // It's called also when this object is restored after timer finished, that's why we have to set claimed variable to false again
    this.claimed = false
    this.rewardItemImage = activeScene.add
      .image(this.positionX, this.positionY, baseConfig.textureKey, baseConfig.frameKey)
      .setScale(baseConfig.scale)
      .setDepth(baseConfig.depth)

    // Adding an extended clickable/touchable area to the image
    // Simplifying the interaction with interactive elements by providing a larger target area
    const padding = baseConfig.hitAreaPadding

    this.rewardItemImage.setInteractive({
      hitArea: new Phaser.Geom.Rectangle(
        // creating a negative offset for the hit area or padding around the image
        -padding,
        -padding,
        // padding * 2 is used to account for the padding on both sides of the image
        this.rewardItemImage.width + padding * 2,
        this.rewardItemImage.height + padding * 2,
      ),
      hitAreaCallback: (hitArea: Phaser.Geom.Rectangle, x: number, y: number): boolean => {
        return Phaser.Geom.Rectangle.Contains(hitArea, x, y)
      },
      useHandCursor: baseConfig.useHandCursor,
    })

    this.addCollectRewardClickListener(activeScene, baseConfig)
  }

  private async addCollectRewardClickListener(
    activeScene: Phaser.Scene,
    baseConfig: RewardedItemConfigInterface,
  ): Promise<void> {
    const phaserStore = usePhaserGameIntegrationStore()
    this.rewardItemImage.on(
      'pointerdown',
      (event: (MouseEvent | TouchEvent) & { downElement: HTMLCanvasElement }) => {
        if (isCanvasInactive(event) || (useTutorialStore().getIsTutorial && !this.hasTutorial))
          return

        this.claimReward(activeScene, baseConfig)
        phaserStore.setClaimedItem()
      },
    )
  }

  private async claimReward(
    activeScene: Phaser.Scene,
    baseConfig: RewardedItemConfigInterface,
  ): Promise<void> {
    if (this.claimed) return

    this.claimed = true
    this.removeIndicator(activeScene)

    try {
      const earnedReward = await internalAxios.post<{}, ClaimEndpointResponseInterface>(
        baseConfig.claimEndpoint,
      )

      playSound(baseConfig.claimRewardSound)
      activeScene.tweens.add({
        targets: this.rewardItemImage,
        alpha: 0,
        ease: baseConfig.claimRewardAnimationEase,
        duration: baseConfig.claimRewardAnimationDuration,
        onComplete: (): void => {
          this.showPowerupReward(activeScene, baseConfig, earnedReward)
        },
      })
      this.checkTutorial()
    } catch (error: unknown) {
      this.removeRewardItemImage()
    }
  }

  private async checkTutorial(): Promise<void> {
    const useTutorial = useTutorialStore()
    if (!useTutorial.getActualStage) return

    await useTutorial.logTutorialProgress(useTutorial.getActualStage.name)
    const phaserStore = usePhaserGameIntegrationStore()
    phaserStore.hideMainMapTutorial(true)
    if (useTutorial.getActualStage) return

    this.hasTutorial = false
    router.push({
      name: 'LayoutView',
    })
  }

  private showPowerupReward(
    activeScene: Phaser.Scene,
    baseConfig: RewardedItemConfigInterface,
    earnedReward: ClaimEndpointResponseInterface,
  ): void {
    const text = this.addRewardValueText(activeScene, baseConfig, earnedReward.value.toString())
    const icon = this.addRewardIcon(
      activeScene,
      text.displayWidth + 18,
      earnedReward.reward,
      baseConfig,
    )
    this.removeRewardItemImage()

    this.addRewardAnimation(activeScene, [text, icon], baseConfig)
  }

  private removeRewardItemImage() {
    if (this.rewardItemImage === null) return

    this.rewardItemImage.removeAllListeners()
    this.rewardItemImage.destroy()
    this.rewardItemImage = null
  }

  private addRewardValueText(
    scene: Phaser.Scene,
    baseConfig: RewardedItemConfigInterface,
    value: string,
  ): Phaser.GameObjects.Text {
    const { color, font, fontStyle, size, shadow, outlineColor, outlineThickness } =
      baseConfig.fontData
    const { offsetX, offsetY, color: shadowColor, blur } = shadow

    return scene.add
      .text(this.rewardItemImage.x, this.rewardItemImage.y, value, {
        fontFamily: font,
        fontSize: size,
        fontStyle: fontStyle,
        color: color,
        stroke: outlineColor,
        strokeThickness: outlineThickness,
      })
      .setDepth(baseConfig.claimedRewardTextDepth)
      .setOrigin(baseConfig.claimedRewardTextOriginX)
      .setShadow(offsetX, offsetY, shadowColor, blur)
  }

  private addRewardIcon(
    activeScene: Phaser.Scene,
    xPositionConstant: number,
    reward: string,
    baseConfig: RewardedItemConfigInterface,
  ): Phaser.GameObjects.Image {
    return activeScene.add
      .image(
        this.rewardItemImage.x + xPositionConstant,
        this.rewardItemImage.y,
        baseConfig.textureKey,
        reward,
      )
      .setDepth(baseConfig.claimedRewardIconDepth)
      .setScale(baseConfig.claimedRewardIconScale)
  }

  private addRewardAnimation(
    activeScene: Phaser.Scene,
    target: [Phaser.GameObjects.Text, Phaser.GameObjects.Image],
    baseConfig: RewardedItemConfigInterface,
  ): void {
    activeScene.tweens.add({
      delay: baseConfig.claimedRewardAnimationDelay,
      targets: target,
      scale: baseConfig.claimedRewardAnimationScale,
      ease: baseConfig.claimRewardAnimationEase,
      duration: baseConfig.claimRewardAnimationDuration,
      onComplete: () => {
        this.rewardAnimationComplete(activeScene, baseConfig, ...target)
      },
    })
  }

  private rewardAnimationComplete(
    activeScene: Phaser.Scene,
    baseConfig: RewardedItemConfigInterface,
    text: Phaser.GameObjects.Text,
    icon: Phaser.GameObjects.Image,
  ): void {
    activeScene.tweens.add({
      targets: [text, icon],
      alpha: baseConfig.claimedRewardAnimationCompleteAlpha,
      ease: baseConfig.claimedRewardAnimationCompleteEase,
      duration: baseConfig.claimedRewardAnimationCompleteDuration,
      onComplete: () => {
        text.destroy()
        text = null
        icon.destroy()
        icon = null
      },
    })
  }

  public setClaimedAndRemoveImage() {
    this.claimed = true
    this.removeRewardItemImage()
  }

  public addTutorial(activeScene: Phaser.Scene, tutorialConfigData: TutorialConfigInterface): void {
    this.hasTutorial = true
    this.tutorialItems = new TutorialItems()
    this.tutorialItems.addToMap(activeScene, this.rewardItemImage, tutorialConfigData, true)
    this.rewardItemImage.setDepth(tutorialConfigData.tutorialDepth)
    this.tutorialItems.focusCamera(activeScene, tutorialConfigData, this.rewardItemImage)
  }

  public removeTutorial(activeScene: Phaser.Scene): void {
    this.tutorialItems?.destroyTutorialElements(activeScene)
    this.tutorialItems = null
  }

  public disableInteractivity(): void {
    if (!this.rewardItemImage) return

    this.rewardItemImage.disableInteractive()
  }

  public addIndicator(
    activeScene: Phaser.Scene,
    indicatorConfig: IndicatorConfigInterface,
    positionIndicatorConfig: PositionIndicatorConfig,
  ) {
    if (this.indicator) return

    this.indicator = new Indicator(
      this.positionX -
        this.rewardItemImage.displayOriginX / 2 +
        positionIndicatorConfig.xCorrectionValue,
      this.positionY -
        this.rewardItemImage.displayOriginY +
        positionIndicatorConfig.yCorrectionValue,
    )
    this.indicator.addToMap(activeScene, indicatorConfig)
  }

  public removeIndicator(activeScene: Phaser.Scene): void {
    if (!this.indicator) return

    this.indicator.removeFromMap(activeScene)
    this.indicator = null
  }

  public setIndicatorVisibility(visible: boolean): void {
    if (!this.indicator) return

    this.indicator.setIndicatorVisibility(visible)
  }
}
