import type { App, Plugin } from 'vue'

export const replacePlaceholder = (
  text: string = '',
  pattern: string | RegExp = '',
  replaceValue: string | number = '',
): string => {
  return text.replace(
    typeof pattern === 'string' ? new RegExp(pattern, 'g') : pattern,
    replaceValue.toString(),
  )
}

export const replacePlaceholderPlugin: Plugin = {
  install: (app: App): void => {
    app.config.globalProperties.$replacePlaceholder = replacePlaceholder
  },
}
