<template>
  <div class="equipment-addon-popup-box">
    <header class="flexing">
      <p
        class="text-34 uppercase"
        :class="isNextLevel ? 'text-texts-standard-important' : 'text-texts-standard-default'"
      >
        {{ title }}
      </p>
    </header>
    <main class="relative">
      <div
        class="flexing flex-col equipment-box-wrapper"
        :class="[
          isNextLevel ? 'equipment-box-gold' : 'equipment-box-normal',
          { 'opacity-50': isLockNextLevel },
        ]"
      >
        <div class="equipment-box-stars flexing">
          <div
            v-for="level in addon.levels"
            :key="level.id"
            :class="'icon-star-' + showStars(level.level, addon.level) + '-48'"
          />
        </div>
        <div class="gray-strip my-4" />
        <div
          class="equipment-box-attributes text-36"
          :class="isNextLevel ? 'text-texts-standard-important' : 'text-texts-standard-default'"
        >
          <div class="equipment-box-attributes-value flexing">
            <p>{{ getAttributeBonus }}</p>
            <equipment-item-attributes-icons
              :icon-type="getAttibutesIcons(addon.type)"
              :discipline-id="disciplineId"
            />
          </div>
          <p>{{ $t(BONUS_TRANSLATION_MAPPING[addon.type]).toString() }}</p>
        </div>
      </div>
      <div v-if="isLockNextLevel" class="equipment-box-lock">
        <app-icon icon-name="lock-md" />
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import EquipmentItemAttributesIcons from '@/components/Equipment/EquipmentItemAttributesIcons.vue'
import { BONUS_TRANSLATION_MAPPING } from './EquipmentDetail.vue'
import { getAttibutesIcons } from '@/helpers'
import type { Upgrades } from '@/interfaces/Equipment'
import type { Nullable } from '@/interfaces/utils'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface ComponentData {
  BONUS_TRANSLATION_MAPPING: typeof BONUS_TRANSLATION_MAPPING
}

export default defineComponent({
  name: 'EquipmentAddonPopupBox',
  components: {
    EquipmentItemAttributesIcons,
  },
  props: {
    disciplineId: {
      type: Number,
      default: 1,
    },
    currentLevel: {
      type: Number,
      default: 1,
    },
    isNextLevel: {
      type: Boolean,
      default: false,
    },
    addon: {
      type: Object as PropType<Nullable<Upgrades[0]>>,
      default: () => null,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data(): ComponentData {
    return {
      BONUS_TRANSLATION_MAPPING,
    }
  },
  computed: {
    isLockNextLevel(): boolean {
      return this.currentLevel === this.addon.level && this.isNextLevel
    },
    getAttributeBonus(): string {
      if (!this.addon) return '0'

      const level = this.isNextLevel ? this.addon.nextLevel : this.addon.currentLevel
      return level ? '+' + level.upgrade.bonus + level.parameters[this.addon.type].suffix : '0'
    },
  },
  methods: {
    getAttibutesIcons,
    showStars(
      level: Upgrades[0]['levels'][0]['level'],
      upgradeLevel: Upgrades[0]['level'],
    ): string {
      if (!level && !upgradeLevel) return ''
      if (this.isNextLevel) return this.nextStar(level, upgradeLevel)
      return this.currentStar(level, upgradeLevel)
    },
    currentStar(level: Upgrades[0]['levels'][0]['level'], upgradeLevel: Upgrades[0]['level']) {
      if (!level && !upgradeLevel) return ''
      if (level <= upgradeLevel) return 'full'
      if (level <= this.currentLevel) return 'empty'
      return ''
    },
    nextStar(level: Upgrades[0]['levels'][0]['level'], upgradeLevel: Upgrades[0]['level']): string {
      if (!level && !upgradeLevel) return ''
      if (level <= upgradeLevel + 1) return 'full'
      if (level <= this.currentLevel) return 'empty'
      return ''
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/stars-icons.scss';

.equipment-addon-popup-box {
  margin: 0 auto;

  main {
    .equipment-box-wrapper {
      width: 24.5rem;
      height: 13.125rem;
      position: relative;
      @if $isSsm {
        background: #1d1f2c;
      }

      &.equipment-box-normal {
        @if $isWsm {
          border: 0.156rem solid #5ba3dc;
        }
        @if $isSsm {
          border: 0.125rem solid #4c648f;
        }
      }

      &.equipment-box-gold {
        border: 0.156rem solid theme('colors.texts.standard.important');
        @if $isWsm {
          box-shadow: inset 0 0 2.5rem rgb(40, 137, 214, 0.55);
        }
      }

      .equipment-box-attributes {
        width: 100%;
        height: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 2.9rem;
      }
    }

    .equipment-box-lock {
      position: absolute;
      top: -1.25rem;
      right: -0.85rem;
    }
  }
}
</style>
