<template>
  <div class="equipment-about-popup flexing">
    <popup-about-type-one
      about-id="equipment-about-popup"
      :popup-data="[
        {
          img: 'components/aboutPopup/equipment/ICO_EQUIPMENT.avif',
          title: $t('equipmentShop.detailHeader'),
          text: $t('equipmentShop.equipmentAbout'),
        },
        {
          img: 'components/aboutPopup/equipment/ICO_EQUIPMENT_LEVEL.avif',
          title: $t('equipmentShop.equipmentLevels'),
          text: $replacePlaceholder(
            $t('equipmentShop.upgradeAbout'),
            '%s',
            String(EQUIPMENT_LEVELS_COUNT),
          ),
        },
        {
          img: 'components/aboutPopup/equipment/ICO_ADDON.avif',
          title: $t('equipmentShop.addOns'),
          text: $t('equipmentShop.addonsAbout'),
        },
        {
          img: 'components/aboutPopup/equipment/ICO_BONUS_CASH.avif',
          title: $t('equipmentShop.moneyBonus'),
          text: $t('equipmentShop.moneyBonusAbout'),
        },
        {
          img: 'components/aboutPopup/equipment/ICO_BONUS_TRENING.avif',
          title: $t('equipmentShop.trainingBonus'),
          text: $t('equipmentShop.trainingBonusAbout'),
        },
        {
          img: 'components/aboutPopup/equipment/ICO_DURABILITY.avif',
          title: $t('equipmentShop.durability'),
          text: $t('equipmentShop.durabilityAbout'),
        },
      ]"
    />
  </div>
</template>

<script lang="ts">
import PopupAboutTypeOne from '@/components/Popup/PopupAboutTypeOne.vue'
import { EQUIPMENT_LEVELS_COUNT } from '@/globalVariables'
import { defineComponent } from 'vue'

interface ComponentData {
  EQUIPMENT_LEVELS_COUNT: typeof EQUIPMENT_LEVELS_COUNT
}

export default defineComponent({
  name: 'EquipmentPopup',
  components: {
    PopupAboutTypeOne,
  },
  data(): ComponentData {
    return {
      EQUIPMENT_LEVELS_COUNT,
    }
  },
})
</script>
