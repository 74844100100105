<template>
  <div>
    <div
      :class="[
        { 'tooltip-icon': isTooltip },
        'clickable-element event-badge__icon event-badge__icon--' + badgeName,
      ]"
    >
      <span class="event-badge__count text-white text-28 italic font-bold">{{
        totalCount ? totalCount : '–'
      }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EventBadgeIcon',

  props: {
    badgeName: {
      type: String,
      required: true,
    },
    totalCount: {
      type: Number,
      default: null,
    },
    isTooltip: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.event-badge {
  &__icon {
    position: relative;

    &--bronze {
      background-image: url($path-icons + '/badges/event_badges/bronze.webp');
      width: 2.625rem;
      height: 2.625rem;
      background-size: contain;
    }

    &--silver {
      background-image: url($path-icons + '/badges/event_badges/silver.webp');
      width: 2.625rem;
      height: 2.625rem;
      background-size: contain;
    }

    &--gold {
      background-image: url($path-icons + '/badges/event_badges/gold.webp');
      width: 2.625rem;
      height: 2.625rem;
      background-size: contain;
    }

    &.tooltip-icon {
      width: 4rem;
      height: 4rem;
    }
  }

  &__count {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
