<template>
  <menu-component
    menu-type="training"
    :title="$t('trainingHall.header')"
    :menu-items="{
      1: {
        text: $t('trainingHall.training'),
        route: $getWebView('TrainingDisciplines'),
        current: current,
      },
      2: {
        text: $t('common.building'),
        route: $getWebView('TrainingBuildings'),
      },
      3: {
        text: $t('trainingHall.tutorials'),
        route: $getWebView('TrainingTutorial'),
      },
    }"
    :sub-menu-active="isSubMenuActive"
    :sub-menu="
      isSubMenuActive
        ? {
            1: {
              text: $t('trainingHall.disciplines'),
              route: $getWebView('TrainingDisciplines'),
              active:
                $route.name === $getWebView('TrainingDisciplines') ||
                $route.name === $getWebView('TrainingDisciplineDetail'),
            },
            2: {
              text: $t('trainingHall.trainer'),
              route: $getWebView('TrainingTrainer'),
              isDisabled: !hasMechanic(MECHANIC_PERSONAL_TRAINER),
              disabledText: $t('rankingsGroup.completeQuestsToUnlock'),
              active:
                $route.name === $getWebView('TrainingTrainer') ||
                $route.name === $getWebView('TrainingTrainerDetail'),
            },
          }
        : {}
    "
  >
    <template #subMenuExtraContent>
      <pass-activated-info
        v-if="$route.name === $getWebView('TrainingDisciplineDetail') && isRookiePassActive"
        :type="TRAINING_POINTS"
        :date-time="rookiePassInfo.remaining_datetime"
        pass-name="premiumGroup.wsm_starter_pass"
        tooltip-position="bottom"
        :rewards="activePassRewards"
      />
    </template>

    <template #subMenuExtraContent2>
      <pass-activated-info
        v-if="$route.name === $getWebView('TrainingDisciplineDetail') && isEventPassActive"
        :type="MECHANIC_EVENT"
        :date-time="eventPassInfo.remaining_datetime"
        pass-name="event.eventpass"
        tooltip-position="bottom"
        :rewards="eventPassInfo.rewards"
      />
    </template>
  </menu-component>
</template>

<script lang="ts">
import PassActivatedInfo from '@/components/PassActivatedInfo.vue'
import {
  MECHANIC_EVENT,
  MECHANIC_PERSONAL_TRAINER,
  OFFER_STATES,
  TRAINING_POINTS,
} from '@/globalVariables'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  MECHANIC_PERSONAL_TRAINER: typeof MECHANIC_PERSONAL_TRAINER
  TRAINING_POINTS: typeof TRAINING_POINTS
  OFFER_STATES: typeof OFFER_STATES
  MECHANIC_EVENT: typeof MECHANIC_EVENT
}

export default defineComponent({
  name: 'TrainingMenu',
  components: {
    PassActivatedInfo,
  },
  props: {
    isSubMenuActive: {
      type: Boolean,
      default: false,
    },
    current: {
      type: String,
      default: '',
    },
  },
  data(): ComponentData {
    return {
      MECHANIC_PERSONAL_TRAINER,
      TRAINING_POINTS,
      OFFER_STATES,
      MECHANIC_EVENT,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      rookiePassInfo: 'getRookiePassInfo',
      eventPassInfo: 'getEventPassInfo',
      activePassRewards: 'getActivePassRewards',
    }),
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
    }),
    isRookiePassActive(): boolean {
      return this.rookiePassInfo.state === OFFER_STATES.active
    },
    isEventPassActive(): boolean {
      return this.eventPassInfo.state === OFFER_STATES.active
    },
  },
})
</script>

<style lang="scss" scoped></style>
