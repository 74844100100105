<template>
  <matches-report
    :history-data="matchReport(matchId)"
    redirect-link="ArenaView"
    :is-history="true"
  />
</template>

<script lang="ts">
import MatchesReport from '@/components/Reports/MatchesReport.vue'
import { ARENA } from '@/globalVariables'
import { getReportValues } from '@/helpers'
import { useMatchesStore } from '@/store/pinia/matchesStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  matchId: Nullable<String>
}

export default defineComponent({
  name: 'ArenaHistoryReport',
  components: {
    MatchesReport,
  },
  data(): ComponentData {
    return {
      matchId: null,
    }
  },
  computed: {
    ...mapState(useMatchesStore, {
      matchReport: 'getMatchReport',
    }),
  },
  async created(): Promise<void> {
    const { matchId } = getReportValues(this.$route)

    this.matchId = matchId

    await this.loadMatchReport({
      matchId: this.matchId,
      matchTime: ARENA,
    })
  },
  methods: {
    ...mapActions(useMatchesStore, ['loadMatchReport']),
  },
})
</script>
