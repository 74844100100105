import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const clubCarsParkingToRightAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'clubCarsParkingToRightAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '04',
      delay: 0,
      duration: 2300,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GeneratedFrameNames,
      data: [
        { key: 'animations', frame: '04' },
        { key: 'animations', frame: '03' },
        { key: 'animations', frame: '02' },
        { key: 'animations', frame: '01' },
        { key: 'animations', frame: '24' },
        { key: 'animations', frame: '23' },
        { key: 'animations', frame: '22' },
        { key: 'animations', frame: '21' },
        { key: 'animations', frame: '20' },
        { key: 'animations', frame: '19' },
        { key: 'animations', frame: '18' },
      ],
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsParkingToRightAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '18',
      delay: 0,
      duration: 2500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 18,
        end: 15,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsParkingToRightAnimations3',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '15',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 15,
        end: 18,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsParkingToRightAnimations4',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '18',
      delay: 0,
      duration: 1800,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 18,
        end: 13,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsParkingToRightAnimations5',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '13',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 13,
        end: 18,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsParkingToRightAnimations6',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '18',
      delay: 0,
      duration: 1600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 18,
        end: 22,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubCarsParkingToRightAnimations7',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '22',
      delay: 0,
      duration: 1600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 22,
        end: 24,
        zeroPad: 2,
      },
    },
  },
]
