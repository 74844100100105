<template>
  <div v-if="!isContentLoading" class="events-rankings-total flexing w-full">
    <ranking-box-position
      :ranking-position="mappedTopRankings"
      points-type="reputation"
      class="ml-6 mr-2"
    />

    <div class="events-rankings-total-standings flex flex-col justify-center items-center">
      <div class="events-rankings-total-standings-info flex justify-between items-center">
        <div class="flexing">
          <app-icon
            class="iconer mr-4"
            icon-name="question"
            @click="showRankingTiersPopup = true"
          />
          <p class="text-32 text-texts-standard-default uppercase">{{ $te('totalStandings') }}</p>
        </div>
        <p class="flexing text-28 text-texts-standard-default">
          {{
            $replacePlaceholder(
              $t('rankingsGroup.rankingRefreshTime'),
              '%s',
              refreshPeriod.toString(),
            )
          }}
        </p>
      </div>

      <table-component
        :columns="headerColumns"
        :items="tableItems"
        :scroll-size="{ mobileY: 42, webY: 42 }"
        :show-logged-user="true"
        :logged-user="loggedUser"
        :pagination="false"
      />
    </div>
  </div>

  <league-reward-tiers-popup
    v-if="showRankingTiersPopup"
    :is-visible="showRankingTiersPopup"
    @close="showRankingTiersPopup = false"
  />

  <component-loading v-if="isContentLoading" :is-loading="true" height="42rem" />
</template>

<script lang="ts">
import LeagueRewardTiersPopup from '@/components/Events/EventsPopups/LeagueRewardTiersPopup.vue'
import RankingBoxPosition from '@/components/Rankings/RankingBoxPosition.vue'
import TableComponent from '@/components/Table/TableComponent.vue'
import { clubTableKeys } from '@/components/Table/tableKeys'
import type Reward from '@/interfaces/Reward'
import type { PlayerInfo } from '@/interfaces/responses/events/league/LeagueStateApiResponse'
import type { Ranking } from '@/interfaces/responses/events/league/LeagueTotalRankingsApiResponse'
import { useLeagueEventStore } from '@/store/pinia/events/leagueStore'
import { useUserStore } from '@/store/pinia/userStore'
import type { Nullable } from '@/interfaces/utils'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface HeaderColumn {
  label: string
  disableSort: boolean
  key: string
  align: string
  width: number
  coloredPos?: boolean
  headerAlign?: string
  onlyHeaderPadding?: boolean
  minWidth?: string
  background?: string
  isBackgroundSkew?: boolean
  tooltip?: string
}

interface TableItem {
  pos: number
  userId: string
  club?: {
    clubId: string
    name: string
    backgroundId: number
    logoId: number
  }
  name: {
    name: string
    country: string
  }
  points: number
  rewards: Reward[]
}

interface TopRanking {
  id: string
  name: string
  country: string
  points: number
  club: string
  clubId: string
  clubLogoId: number
  clubLogoBgId: number
}

interface ComponentData {
  currentRound: Nullable<number>
  clubTableKeys: typeof clubTableKeys
  headerColumns: HeaderColumn[]
  showRankingTiersPopup: boolean
  refreshPeriod: number
}

export default defineComponent({
  name: 'EventsRankingsTotal',
  components: {
    RankingBoxPosition,
    TableComponent,
    LeagueRewardTiersPopup,
  },
  data(): ComponentData {
    return {
      currentRound: null,
      showRankingTiersPopup: false,
      clubTableKeys,
      headerColumns: [
        {
          label: this.$t('rankingsGroup.pos'),
          disableSort: true,
          key: clubTableKeys.pos,
          align: 'center',
          coloredPos: true,
          width: 2,
        },
        {
          label: this.$t('common.name'),
          disableSort: true,
          key: clubTableKeys.nameComponent,
          onlyHeaderPadding: true,
          align: 'left',
          headerAlign: 'left',
          width: 40,
        },
        {
          label: this.$te('eventReputation'),
          disableSort: true,
          key: clubTableKeys.reputation,
          align: 'center',
          width: 20,
          minWidth: '7.5rem',
        },
        {
          label: this.$t('common.rewards'),
          disableSort: true,
          key: clubTableKeys.rewards,
          tooltip: this.$te('rewardsInfoTotal'),
          background: this.$isWsm ? '#234965' : undefined,
          isBackgroundSkew: true,
          align: 'center',
          width: 37,
        },
      ],
      // FIXME: Toto by mal BE zapracovat u seba a nam posielat. Pre hotfix to nechavam hard-coded.
      refreshPeriod: 60,
    }
  },
  computed: {
    ...mapState(useLeagueEventStore, {
      totalRankings: 'getTotalRankings',
      state: 'getState',
      config: 'getConfig',
    }),
    ...mapState(useUserStore, {
      userData: 'getUserData',
    }),
    tableItems(): TableItem[] {
      return this.totalRankings?.rankings?.map((player: Ranking) => {
        const selectedPlayerConfig = this.totalRankings?.players.find(
          (item: PlayerInfo): boolean => item.user_games_id === player.user_games_id,
        )

        return {
          pos: player?.position,
          userId: player?.user_games_id,
          name: {
            name: selectedPlayerConfig?.username,
            country: selectedPlayerConfig?.country,
          },
          club: {
            clubId: selectedPlayerConfig?.club?.clubs_id,
            name: selectedPlayerConfig?.club?.name,
            backgroundId: selectedPlayerConfig?.club?.logo_background_id,
            logoId: selectedPlayerConfig?.club?.logo_id,
          },
          points: player?.points,
          rewards: player?.rewards,
        }
      })
    },
    loggedUser(): TableItem {
      const loggedPlayerRanking: Ranking = this.totalRankings?.player_ranking

      return {
        pos: loggedPlayerRanking?.position,
        userId: loggedPlayerRanking?.user_games_id,
        name: {
          name: this.userData?.username,
          country: this.userData?.country?.ioc_code,
        },
        points: loggedPlayerRanking?.points,
        rewards: loggedPlayerRanking?.rewards,
      }
    },
    isContentLoading(): boolean {
      return this.totalRankings == null
    },
    mappedTopRankings(): TopRanking[] {
      const topRankings: TableItem[] = this.tableItems?.filter(
        (item: TableItem): boolean => item.pos <= 3,
      )

      return topRankings?.map((item: TableItem): TopRanking => {
        return {
          id: item.userId,
          name: item.name.name,
          country: item.name.country,
          points: item.points,
          clubId: item.club?.clubId,
          club: item.club?.name,
          clubLogoBgId: item.club?.backgroundId,
          clubLogoId: item.club?.logoId,
        }
      })
    },
  },
  async created(): Promise<void> {
    await this.loadTotalRankings()

    if (this.config == null) await this.loadConfig()
    if (this.state == null) await this.loadState()
  },
  methods: {
    ...mapActions(useLeagueEventStore, {
      loadTotalRankings: 'loadTotalRankings',
      loadConfig: 'loadConfig',
      loadState: 'loadState',
    }),
  },
})
</script>

<style lang="scss" scoped>
.events-rankings-total {
  &-standings {
    width: 100%;

    &-info {
      height: 3.875rem;
      width: 97%; // Rovnako ako tabulka.
      padding-left: 1rem;
      padding-right: 1rem;

      margin-top: 0.6rem;

      @if $isWsm {
        background: linear-gradient(
          to right,
          rgba(43, 78, 103, 0.8) 0%,
          rgba(71, 135, 180, 0.6) 25%,
          transparent 100%
        );
      }

      @if $isSsm {
        background: linear-gradient(
          to right,
          rgba(75, 109, 149, 0.95) 0%,
          transparent 75%,
          transparent 100%
        );
      }

      .iconer {
        width: 2.813rem;
        height: 2.813rem;
      }
    }
  }
}
</style>
