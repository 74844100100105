import type { BuildingConfigInterface } from '@/map-phaser-new/interfaces'
import { getRouteWebName } from '@/plugins'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import type { Router } from 'vue-router'

export const eventOsraConfig: BuildingConfigInterface = {
  textureKey: 'buildings.event_osra',
  frameKey: null, // will be added dynamically based on discipline id
  routeConfig: (router: Router): void => {
    const eventInfoStore = useEventInfoStore()
    router.push({
      name: eventInfoStore.getEventTeaserActive
        ? getRouteWebName('EventsTeaser')
        : getRouteWebName(
            eventInfoStore.getEventCustomProps?.routes?.event?.[
              eventInfoStore.getEventCollectionActive ? 'collection' : 'progress'
            ],
          ),
    })
  },
  positionX: 1400,
  positionY: 620,
  depth: 2,
  originX: 0,
  keyForTranslateTitle: 'map.eventName',
  outlineConfig: {
    textureKey: 'buildings.event_osra',
    framePostFix: '_outline',
    depth: 2,
    originX: 0,
    positionByLevel: {
      '1': { positionX: 1400, positionY: 620 },
    },
  },
  inputConfig: {
    useHandCursor: true,
  },
  // buildingExclamationNotificationConfig: {
  //   correctionPositionX: 130,
  //   correctionPositionY: -30,
  // },
}
