<template>
  <div class="maintenance-popup flexing">
    <div class="maintenance-wrapper">
      <div v-if="isUpdate" class="maintenance-update">
        <p class="maintenance-update-title text-48 text-texts-standard-default uppercase font-bold">
          NEW UPDATE COMING UP
        </p>
      </div>
      <div class="maintenance-header flexing">
        <p
          class="maintenance-header-title text-40 text-texts-standard-important uppercase font-bold"
        >
          {{
            $replacePlaceholder(
              isUpdate
                ? isTranslatedTextAvailable
                  ? $t('common.gameInUpdate')
                  : `${PROJECT_NAME} is currently unavailable due to ongoing updates.`
                : isTranslatedTextAvailable
                  ? $t('common.gameInMaintenance')
                  : `${PROJECT_NAME} is currently unavailable due to technical reasons.`,
              '{game}',
              PROJECT_NAME,
            )
          }}
        </p>
      </div>
      <div class="maintenance-content flexing flex-col">
        <div
          class="maintenance-content-icon"
          :class="isUpdate ? 'icon-maintenance-update' : 'icon-maintenance'"
        />
        <p
          v-if="getFormattedDate"
          class="maintenance-content-text text-32 text-texts-standard-default"
        >
          {{
            isTranslatedTextAvailable
              ? $replacePlaceholder($t('common.gameShouldBeOnlineIn'), '{time}', getFormattedDate)
              : `You should be able to access the game again at ${getFormattedDate}.`
          }}
          <br />
          {{
            isTranslatedTextAvailable
              ? $t('common.thanksForUndestanding')
              : ' Thank you for understanding.'
          }}
        </p>
      </div>
      <div class="maintenance-redirect flexing flex-col">
        <p class="text-32 text-texts-standard-additional">
          {{
            isTranslatedTextAvailable
              ? $t('common.tryOtherGames')
              : 'You can try one of our other games in the meantime.'
          }}
        </p>
        <div class="maintenance-redirect-buttons flexing">
          <app-button
            v-for="(item, index) in buttons"
            :key="index"
            class="maintenance-redirect-buttons-button relative"
            @click="$routeToExternalUrl(item.url)"
          >
            {{ item.title }}
            <div
              class="maintenance-redirect-buttons-button-logo"
              :style="{
                backgroundImage: `url('${pathImages}maintenance/${item.logo}.avif')`,
              }"
            />
          </app-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  GAME_LIVE,
  GAME_MAINTENANCE,
  GAME_UPDATE,
  PROJECT_NAME,
  amUrl,
  bmUrl,
  pathImages,
  sjm3Url,
} from '@/globalVariables'
import { defineComponent } from 'vue'

interface MaintenanceButton {
  title: string
  url: string
  logo: string
}
interface ComponentData {
  PROJECT_NAME: typeof PROJECT_NAME
  pathImages: typeof pathImages
  buttons: MaintenanceButton[]
}

export default defineComponent({
  name: 'MaintenancePopup',
  props: {
    gameState: {
      type: String,
      default: GAME_LIVE,
      validator(value: string): boolean {
        return [GAME_LIVE, GAME_MAINTENANCE, GAME_UPDATE].includes(value)
      },
    },
    time: {
      type: String,
      default: '',
    },
  },
  data(): ComponentData {
    return {
      PROJECT_NAME,
      pathImages,
      buttons: [
        {
          title: 'Athletics mania',
          url: amUrl,
          logo: 'logo-am',
        },
        {
          title: 'ski jump mania 3',
          url: sjm3Url,
          logo: 'logo-sjm3',
        },
        {
          title: 'biathlon mania',
          url: bmUrl,
          logo: 'logo-bm',
        },
      ],
    }
  },
  computed: {
    isUpdate(): boolean {
      return this.gameState === GAME_UPDATE
    },
    isTranslatedTextAvailable(): string {
      return this.$t('common.thanksForUndestanding')
    },
    getFormattedDate(): string {
      if (!this.time) return

      const date = new Date(this.time)
      return `${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`
    },
  },
})
</script>

<style lang="scss" scoped>
.maintenance-popup {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  .maintenance-wrapper {
    width: 100%;
    height: 45.5rem;
  }

  .maintenance {
    &-update {
      width: 100%;
      height: 4.938rem;
      background: linear-gradient(
        90deg,
        rgba(255, 233, 142, 0) 0%,
        rgba(255, 233, 142, 1) 10%,
        rgba(255, 233, 142, 1) 25%,
        rgba(190, 153, 73, 1) 50%,
        rgba(255, 254, 206, 1) 65%,
        rgba(203, 159, 63, 1) 80%,
        rgba(235, 190, 93, 1) 90%,
        rgba(255, 233, 142, 0) 100%
      );
      transform: translate(0, 0);

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 0.125rem;
        background: linear-gradient(
          90deg,
          rgba(255, 243, 173, 0) 0%,
          rgba(255, 243, 173, 1) 20%,
          rgba(255, 243, 173, 1) 80%,
          rgba(255, 243, 173, 0) 100%
        );
        left: 0;
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }
    }

    &-header {
      width: 100%;
      height: 9rem;
      background: url($path-images + 'maintenance/maintenance-header-bg.avif') no-repeat;
      background-size: 100% 100%;

      &-title {
        width: 50%;
        margin: 0 auto;
      }
    }

    &-content {
      width: 100%;
      height: 16.438rem;
      background: linear-gradient(
        90deg,
        rgba(14, 40, 69, 0) 0%,
        rgba(14, 40, 69, 1) 20%,
        rgba(15, 48, 85, 1) 50%,
        rgba(14, 40, 69, 1) 80%,
        rgba(14, 40, 69, 0) 100%
      );

      &-icon {
        width: 6rem;
        height: 6rem;
      }

      &-text {
        margin-top: 1.5rem;
      }
    }

    &-redirect {
      padding-top: 3rem;

      &-buttons {
        margin-top: 2.5rem;

        &-button {
          margin-left: 3rem;
          margin-right: 3rem;

          &-logo {
            position: absolute;
            left: -6rem;
            background-size: 100% 100%;
            width: 6.938rem;
            height: 4.938rem;
          }
        }
      }
    }
  }
}
</style>
