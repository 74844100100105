import type { App } from 'vue'
import { getIsMobileLocalStorage, isFlutterApp } from '../helpers/mobileApp'

const MOBILE_DEVICE_REGEXP = /Android|iPhone|iPad/i // iPad sa uz takto od iPadOS13++ neodchytava co sposobuje problem pri zaloznej hodnote pokial este nemame nacitanu hodnotu kluca is_mobile

// const iPadOS13Up = /MacIntel/i.test(navigator.userAgent) && navigator.maxTouchPoints > 1
// let cache: boolean

/**
 * Helper function for detection of mobile user agent.
 *
 * @returns {boolean}
 */
export const isMobile = (): boolean => {
  // premenna cache nemoze byt undefined, nakolko undefined je len jej vychodzi stav
  // a pri volani tejto funkcie sa uz vzdy naplni booleanom

  // if (cache !== undefined && !force) return cache
  // return (cache = MOBILE_DEVICE_REGEXP.test(navigator.userAgent) || getIsMobileLocalStorage() === 1) // ee problem je sposob ukladania cache

  return (
    MOBILE_DEVICE_REGEXP.test(navigator.userAgent) ||
    getIsMobileLocalStorage() === 1 ||
    isFlutterApp()
  )
}

/**
 * Is mobile plugin. Use with app.use() at app creation time.
 */
export default {
  install: (app: App): void => {
    app.config.globalProperties.$isMobile = isMobile
  },
}
