export enum MilestoneType {
  Free = 'free',
  Premium = 'premium',
}

export interface MilestoneReward {
  name: string
  value: string
}

export interface Milestone {
  id: number
  position: number
  points_limit: number
  repeatable: 0 | 1
  reward: MilestoneReward
}

export interface TournamentsMilestonesConfigApiResponse {
  milestones: {
    [MilestoneType.Free]: Milestone[]
    [MilestoneType.Premium]: Milestone[]
  }
}
