<template>
  <div
    class="gems-small-pack relative text-texts-standard-default"
    :class="{
      'daily-bg': packageData.isDaily && !countdownToNextClaim,
      'claim-bg': canClaim || (packageData.isDaily && countdownToNextClaim),
      'best-bg': packageData.isBestValue,
      'pointer-events-none': !active,
      'cursor-pointer': canClaim || canBuy,
    }"
    @click="canClaim ? $emit('claimReward', packageData.storeId) : canBuy ? $emit('buy') : null"
  >
    <div
      v-if="canClaim || (packageData.isDaily && countdownToNextClaim)"
      :class="[
        'h-full absolute',
        { 'opacity-bg-time': packageData.isDaily && countdownToNextClaim },
      ]"
    />
    <!-- FIRST TIME BONUS TEXT -->
    <div v-if="!packageData.isDaily && packageData.firstTimeBonus" class="bonus uppercase text-30">
      <div class="bonus-up">
        <div class="flex items-center pl-3 italic" v-html="getBonusText" />
      </div>
      <div v-if="packageData.firstTimeBonus" class="bonus-down text-24 text-left pl-3">
        <p>{{ $t('premiumGroup.firstPurchaseBonus') }}</p>
      </div>
    </div>
    <!-- -->
    <!-- IF YOU BUY SMALL BOX UPPER TEXT , REMAINING BOXES -->
    <div
      v-if="packageData.isDaily && packageData.ownedPack"
      class="remaining-package text-texts-standard-default text-32 flex items-center justify-start relative"
    >
      <p>{{ $t('common.remaining') }}:&nbsp;</p>
      <p class="text-texts-standard-important font-bold">{{ packageData.remainingRepeatCount }}x</p>
    </div>
    <!-- -->
    <div v-if="packageData.isBestValue" class="best-value text-30 font-bold flexing absolute">
      <p>{{ $t('premiumGroup.bestValue') }}</p>
    </div>
    <!-- DAILY CREDIT BONUS TEXT UP -->
    <div v-if="packageData.isDaily && !packageData.ownedPack" class="daily-bonus text-32">
      <div class="daily-bonus-up flexing">
        <span class="daily-bonus-up-left relative flexing">
          <p>
            {{ packageData.dailyReward }}
          </p>
          <app-main-icon class="icon-margin" :icon-name="'gems'" :icon-size="32" />
        </span>
        <span class="daily-bonus-up-right relative flexing">
          <span class="time-icon">
            <app-icon icon-name="time" />
          </span>
          <p class="mx-2">24h</p>
        </span>
      </div>
      <div class="daily-bonus-up-two flexing">
        <p class="font-bold relative">{{ packageData.maxRepeatCount }}x</p>
      </div>
      <div v-if="packageData.firstTimeBonus" class="daily-bonus-down text-30 flexing">
        <p class="text-texts-standard-important font-bold">+{{ packageData.firstTimeBonus }}</p>
        <app-main-icon class="icon-margin" :icon-name="'gems'" :icon-size="32" />
        <p class="uppercase">
          {{ $t('premiumGroup.immediately') }}
        </p>
      </div>
    </div>
    <div
      class="gems-img absolute"
      :style="{
        background:
          'url(' +
          pathImages +
          `premium/gemsPackage/small-${packageData.imageName}.avif) center no-repeat`,
        backgroundSize: 'cover',
      }"
    >
      <div class="relative">
        <span class="pack-shine" />
        <span class="pack-shine" />
        <span class="pack-shine" />
      </div>
    </div>
    <div v-if="isGemBonus" class="badge">
      <gems-bonus-badge :bonus="packageData.gemBonusPercentage" size="small" />
    </div>
    <div v-if="isGemBonus" class="pack-sell text-40 flexing">
      <div class="pack-sell-text italic">
        {{ packageData.oldReward }}
      </div>
      <app-main-icon class="icon-margin" icon-name="gems" :icon-size="32" />
    </div>
    <article
      class="gems-small-pack-amount absolute flexing text-texts-standard-important text-60 font-bold"
      :class="{
        'gems-small-pack-amount--claim': !canBuy && (canClaim || countdownToNextClaim),
      }"
    >
      <p>
        {{
          $filters.$formatNumber(
            packageData.isDaily && packageData.ownedPack
              ? packageData.dailyReward
              : packageData.rewardTotal,
          )
        }}
      </p>
      <app-main-icon icon-name="gems" :icon-size="56" />
    </article>

    <!-- -->
    <div class="icon-position absolute">
      <tippy v-if="!packageData.isDaily" theme="WSM" placement="right" max-width="50rem">
        <app-icon v-show="showTooltip" :icon-name="'question'" />
        <template #content>
          <div class="small-pack-tooltip text-texts-standard-default text-24">
            <gems-pack-back
              class="absolute small-pack-tooltip-content"
              :package-data="packageData"
            />
          </div>
        </template>
      </tippy>
      <app-icon v-else :icon-name="'question'" @click.stop="setShowBack()" />
    </div>
    <footer
      class="price absolute m-auto"
      :class="[
        (!$isMobile() && packageData.alternativePrice) || getForceRate ? '' : 'single-price-tag',
        {
          'price--countdown': !canBuy && countdownToNextClaim,
          'single-price-tag': !canBuy && canClaim,
        },
      ]"
    >
      <app-price
        :class="{ 'price-section--countdown': countdownToNextClaim }"
        :decoration-color="getDecorationColor"
      >
        <p class="text-36 m-auto price-text uppercase">
          {{ canBuy ? getPrice : canClaim ? $t('common.claim') : '' }}
        </p>

        <app-timer
          v-if="countdownToNextClaim"
          :time="packageData.countdown"
          transparent
          centered
          @countdown-action="$emit('reloadPacks')"
        />
      </app-price>
      <div
        v-if="canBuy && ((!$isMobile() && packageData.alternativePrice) || getForceRate)"
        class="text-texts-standard-additional price-exchanged text-32"
      >
        ( {{ packageData.alternativePrice }} )
      </div>
    </footer>
    <gems-pack-delivery
      v-if="showBack"
      :package-data="packageData"
      @click.stop="showBack = false"
    />
  </div>
</template>

<script lang="ts">
import AppPrice from '@/components/GlobalComponents/AppPrice.vue'
import GemsBonusBadge from '@/components/Premium/GemsPack/GemsBonusBadge.vue'
import GemsPackBack from '@/components/Premium/GemsPack/GemsPackBack.vue'
import GemsPackDelivery from '@/components/Premium/GemsPack/GemsPackDelivery.vue'
import { mapState } from 'pinia'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import {
  PREMIUM_LINE_GOLD_COLOR,
  PREMIUM_LINE_GREEN_COLOR,
  PREMIUM_LINE_PURPLE_COLOR,
  pathImages,
} from '@/globalVariables'

import { formatTime } from '@/helpers'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { GemsPack } from '@/interfaces/premium/GemsPack'

interface ComponentData {
  pathImages: typeof pathImages
  showBack: boolean
}

export default defineComponent({
  name: 'PremiumGemsSmallPack',
  components: {
    GemsPackBack,
    AppPrice,
    GemsBonusBadge,
    GemsPackDelivery,
  },
  props: {
    packageData: {
      type: Object as PropType<Nullable<GemsPack>>,
      default: () => null,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['claimReward', 'buy', 'reloadPacks'],
  data(): ComponentData {
    return {
      pathImages,
      showBack: false,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      getForceRate: 'getForceRate',
    }),
    isGemBonus(): boolean {
      return this.packageData.storeId.includes('gems_bonus')
    },
    getPrice(): string {
      return this.packageData.price + ' ' + this.packageData.currencySymbol
    },
    canBuy(): boolean {
      return !this.packageData.isDaily || (this.packageData.isDaily && !this.packageData.ownedPack)
    },
    canClaim(): boolean {
      return this.packageData.isDaily && this.packageData.canClaim
    },
    countdownToNextClaim(): boolean {
      return this.packageData.ownedPack && this.packageData.isDaily && !this.packageData.canClaim
    },
    getBonusText(): string {
      let bonusText = this.$t('premiumGroup.bonus')
      bonusText = this.$replacePlaceholder(bonusText, '%s', '{b} %s {icon}{/b}')
      bonusText = this.$replaceHtmlPlaceholders(bonusText, 'b', 'font-bold')
      bonusText = bonusText.replace(/{icon}/g, '<div class="bonus-icon-gems"></div>')
      bonusText = this.$replacePlaceholder(bonusText, '%s', '+' + this.packageData.firstTimeBonus)

      return bonusText
    },
    getDecorationColor(): string {
      if (this.packageData.isDaily && !this.countdownToNextClaim && !this.canClaim)
        return PREMIUM_LINE_PURPLE_COLOR
      if (this.canClaim || (this.packageData.isDaily && this.countdownToNextClaim))
        return PREMIUM_LINE_GREEN_COLOR
      if (this.packageData.isBestValue) return PREMIUM_LINE_GOLD_COLOR
      return ''
    },
  },
  methods: {
    formatTime,
    setShowBack(): void {
      this.showBack = true
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/animations.scss';

.gems-small-pack {
  height: 19.188rem;
  width: 35.625rem;
  background: url($path-premium + 'gemsPackage/BG_OFFER_BOX_SMALL_1.webp?v2') center no-repeat;
  background-size: contain;

  &::before {
    content: '';
    width: 1.125rem;
    height: 100%;
    position: absolute;
    left: -0.5rem;
    top: 0;
    background: url($path-premium + 'gemsPackage/effect-smallblue-left.webp') center no-repeat;
    background-size: cover;
  }

  .opacity-bg-time {
    width: 100%;
    background: rgb(4 8 4 / 25%);
    z-index: 1;
  }

  .bonus {
    width: 21.0625rem;
    height: 4.9375rem;
    margin-top: 0.6875rem;
    margin-left: 1rem;

    &-up {
      clip-path: polygon(100% -100%, 0% 0%, 0% 100%, 90% 100%);
      background-image: linear-gradient(to left, #d92b2b, #ff2c2c);
      padding-right: 1rem;

      &:v-deep(.bonus-icon-gems) {
        width: 2rem;
        height: 2rem;
        position: relative;
        top: 0.175rem;
        left: -0.5rem;
        background: url($path-icons + 'main/main-icons-120_v4.avif') -6rem 0 no-repeat;
        background-size: 24rem;
        margin: 0 0.5rem;
        display: inline-block;
      }
    }

    &-down {
      clip-path: polygon(100% -100%, 0% 0%, 0% 100%, 90% 100%);
      width: 19.2rem;
      padding-right: 1rem;
      background-image: linear-gradient(to left, #8727c4, #8727c4);
    }
  }

  .remaining-package {
    left: 2rem;
    z-index: 2;
    top: 1rem;
  }

  .best-value {
    background: url($path-premium + 'gemsPackage/NOT_MOST_POPULAR.avif') center no-repeat;
    background-size: cover;
    width: 14.9375rem;
    height: 2.4375rem;
    top: -1rem;
    right: -1.2rem;
    z-index: 1;
  }

  .daily-bonus {
    width: 15.0625rem;
    height: 4.9375rem;
    margin-top: 0.6875rem;
    margin-left: 1rem;

    &-up {
      @if $isSsm {
        font-weight: bold;
        background: linear-gradient(to right, #c2973f, #e6c465, #c2973f);
      }

      @if $isWsm {
        background: linear-gradient(to right, #c2973f, #e6c465, #cea343);
        clip-path: polygon(100% -100%, 0% 0%, 0% 100%, 90% 100%);
        padding-right: 1rem;
      }

      &-left {
        &:after {
          content: '';
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
          position: absolute;
          width: 0.06rem;
          height: 1.875rem;
          background: #ffedbb;
        }
      }

      &-right {
        padding-left: 1rem;

        &:before {
          content: '';
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          position: absolute;
          width: 0.06rem;
          height: 1.875rem;
          background: #b48522;
        }

        .time-icon {
          transform: scale(0.8);
        }
      }
    }

    &-up-two {
      @if $isSsm {
        background-image: linear-gradient(to left, #119780, #0ee7cc);
      }

      @if $isWsm {
        clip-path: polygon(100% -100%, 0% 0%, 0% 100%, 78% 100%);
        background-image: linear-gradient(to left, #2bc82f, #0c9e0c);
        padding-right: 1rem;
      }
      width: 6rem;
    }

    &-down {
      width: 13.2rem;
    }
  }

  .gems-img {
    width: 22.5rem;
    height: 18.75rem;
    top: -1rem;
    right: -3rem;
  }

  &-amount {
    width: 20.5rem;
    height: 4.375rem;
    top: 8.5rem;

    @if $isSsm {
      background: linear-gradient(to left, transparent, rgba(144, 91, 221, 0.99), transparent);
    }

    @if $isWsm {
      background: linear-gradient(to left, transparent, rgba(193, 193, 193, 0.26), transparent);
    }

    &--claim {
      @if $isSsm {
        background: linear-gradient(
          to left,
          transparent 5%,
          rgba(29, 185, 166, 0.99) 40%,
          rgba(29, 185, 166, 0.99) 60%,
          transparent 95%
        );
      }

      @if $isWsm {
        background: linear-gradient(to left, transparent, rgba(193, 193, 193, 0.26), transparent);
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      background: linear-gradient(to left, transparent, rgba(255, 255, 255, 80%), transparent);
      width: 80%;
      height: 0.125rem;
    }
  }

  .icon-position {
    width: 5rem;
    height: 4.3rem;
    bottom: 1rem;
    right: 0.5rem;
    z-index: 2;
  }

  &-daily-text {
    left: 1.6875rem;
    bottom: 1.5rem;
    width: 6rem;
    line-height: 1.8rem;
  }

  .price {
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    z-index: 2;

    &-exchanged {
      width: 100%;
      margin-bottom: 0.5rem;

      @if $isWsm {
        font-style: italic;
      }
    }

    &.single-price-tag:not(.price--countdown) {
      padding-bottom: 1.25rem;
    }

    .price-section--countdown {
      height: auto;
      bottom: 1.25rem;

      .timer-component {
        min-width: max-content;
        height: auto;
        padding-left: 0;

        &:deep(.icon-time) {
          height: 2.25rem;
          background-position: center;
          margin-right: 0.125rem;
        }

        @if $isSsm {
          font-weight: normal;
        }

        @if $isWsm {
          font-weight: bold;
        }
      }
    }
  }
}

.daily-bg {
  background: url($path-premium + 'gemsPackage/BG_OFFER_BOX_SMALL_2.webp?v2') center no-repeat;
  background-size: contain;

  &::before {
    background: url($path-premium + 'gemsPackage/effect-smallpurple-left.webp') center no-repeat;
    background-size: cover;
  }
}

.claim-bg {
  background: url($path-premium + 'gemsPackage/BG_OFFER_BOX_SMALL_3.webp?v2') center no-repeat;
  background-size: contain;

  &::before {
    background: url($path-premium + 'gemsPackage/effect-smallgreen-left.webp?v2') center no-repeat;
    background-size: cover;
  }
}

.best-bg {
  background: url($path-premium + 'gemsPackage/BG_OFFER_BOX_SMALL_4.webp') center no-repeat;
  background-size: contain;

  &::before {
    background: url($path-premium + 'gemsPackage/effect-smallgold-left.webp') center no-repeat;
    background-size: cover;
  }
}

.small-pack-tooltip {
  width: 25.8rem;
  height: 36.75rem;
  position: relative;

  &-content {
    top: -1.5rem;
    left: -3.6rem;
  }
}

.pack-sell {
  width: 20.5rem;

  &.placeholder {
    height: 3.75rem;
  }

  &-text {
    color: rgb(255 255 255 / 76%);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      background: #b52e2d;
      width: 6.8125rem;
      height: 0.25rem;
      left: 73%;
      transform: translate(-50%) skewY(-12deg);
      top: 45%;
    }
  }
}

.badge {
  position: absolute;
  left: 48%;
  top: 5.5rem;
}

.pack-shine {
  position: absolute;
  width: 2.75rem;
  height: 2.75rem;
  top: 0;
  left: 0;
  // TODO switch assetu od Erika
  background-image: url(https://appspowerplaymanager.vshcdn.net/images/winter-sports/grandPrize/badges/badge-glow-1.webp?v2);
  background-size: cover;
  opacity: 0;
  z-index: 1;
  animation-name: twinkle;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &:nth-of-type(1) {
    top: 5rem;
    left: 6.5rem;
    animation-delay: 1950ms;
  }
  &:nth-of-type(2) {
    top: 4.5rem;
    left: 14rem;
    animation-delay: 1650ms;
  }
  &:nth-of-type(3) {
    top: 11rem;
    left: 8rem;
    animation-delay: 1350ms;
  }
}
</style>
