<template>
  <div
    class="bounty-board-task-panel-rewards flex flex-row items-center justify-between"
    :class="{ 'bounty-board-task-panel-rewards--active': isActive }"
  >
    <div
      class="bounty-board-task-panel-rewards__label flex items-center text-32 text-texts-standard-name font-bold"
    >
      {{ $t('common.rewards') }}
    </div>
    <div class="bounty-board-task-panel-rewards__rewards mr-6">
      <rewards :reward-data="rewards" :icon-size="32" modifier-tag="autumn-fair" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import Rewards from '@/components/Rewards.vue'
import type Reward from '@/interfaces/Reward'

export default defineComponent({
  name: 'RewardPanel',
  components: {
    Rewards,
  },
  props: {
    rewards: {
      type: Array as PropType<Reward[]>,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.bounty-board-task-panel-rewards {
  width: 35.563rem;
  height: 4.375rem;
  border-width: 0.125rem;
  background: url($path-events + 'autumn-fair/bounty-board/wooden-box-rewards-bg.avif') no-repeat
    center;
  background-size: 100% 100%;
  border-image-source: linear-gradient(to top, #ffdc24, #ffab3c);
  border-image-slice: 1;

  &--active {
    border-image-source: linear-gradient(to bottom, #a2d1ff, #9dcaf7, #537597);
    border-image-slice: 1;
  }

  &__label {
    width: 11.563rem;
    height: 100%;
    text-align: left;
    padding: 0 1.375rem;
    margin-right: 1.5rem;
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);

    @if $isWsm {
      padding: 0 2.375rem 0 1.375rem;
      background: rgba(24, 30, 46, 0.4);
    }

    @if $isSsm {
      background: rgba(24, 30, 46, 0.4);
    }
  }

  :deep(p) {
    @apply text-32;
  }
}
</style>
