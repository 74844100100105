import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const cableCarObservatoryPath: PathObjectInterface = {
  mainPath: {
    x: 1909,
    y: 1353,
  },
  nextPaths: [
    {
      x: 2544,
      y: 1271,
      control1X: 2092,
      control1Y: 1375,
      control2X: 2313,
      control2Y: 1337,
    },
  ],
}
