<template>
  <main>
    <div class="profile-header-wrapper flex justify-between items-center">
      <div class="profile-header-wrapper-left flexing">
        <div class="profile-header-wrapper-avatar">
          <clubs-avatar
            :logo-id="clubInfo.logoId"
            :background-id="clubInfo.logoBgId"
            class="profile-header-wrapper-avatar-inner"
          />
        </div>
        <div class="flex flex-col">
          <div class="profile-header-wrapper-club-name flex justify-start items-center">
            <div class="flex">
              <flag-component :country="clubInfo.country" />
            </div>
            <p class="text-40">
              {{ clubInfo.name }}
            </p>
          </div>
          <div class="profile-header-wrapper-club-stats flex justify-between">
            <tippy
              v-for="(stat, index) in clubStats"
              :key="index"
              theme="WSM"
              placement="top"
              :content="$t(stat.tooltip)"
            >
              <div class="profile-header-wrapper-club-stats-box flexing skew box-border-3 mr-4">
                <p class="text-texts-standard-default text-36 font-bold revert-skew">
                  {{ $filters.$formatNumber(stat.value) }}
                </p>
                <app-main-icon
                  v-if="stat.iconName === 'prestige'"
                  :hide-tooltip="true"
                  :icon-name="stat.iconName"
                  :icon-size="32"
                />
                <app-icon v-else :icon-size="32" :icon-name="stat.iconName" />
              </div>
            </tippy>
          </div>
        </div>
      </div>

      <div class="profile-header-wrapper-right flex justify-center items-end flex-col mt-2">
        <app-button
          v-if="hasMechanic(MECHANIC_CLUB)"
          btn-size="md"
          btn-type="confirm"
          :btn-text="$t('club.clubMap')"
          @click="redirectToClubMap"
        />
        <div class="flexing profile-header-wrapper-club-level">
          <p class="text-28 uppercase">{{ $t('club.clubLevel') }}:</p>
          <span
            class="text-36 font-bold text-texts-standard-important profile-header-wrapper-club-level-number"
          >
            {{ clubInfo.clubhouseLevel }}
          </span>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import ClubsAvatar from '@/components/Club/ClubsAvatar.vue'
import FlagComponent from '@/components/FlagComponent.vue'
import { MECHANIC_CLUB } from '@/globalVariables'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
interface ClubStat {
  iconName: string
  value: number
  tooltip: string
}
interface ComponentData {
  MECHANIC_CLUB: typeof MECHANIC_CLUB
}

export default defineComponent({
  name: 'ClubsProfileHeader',
  components: {
    ClubsAvatar,
    FlagComponent,
  },
  data(): ComponentData {
    return {
      MECHANIC_CLUB,
    }
  },
  computed: {
    ...mapState(useClubStore, {
      clubInfo: 'getClubInfo',
    }),
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
    }),
    clubStats(): ClubStat[] {
      return [
        {
          iconName: 'prestige',
          value: this.clubInfo.prestige || 0,
          tooltip: 'club.prestigePoints',
        },
        {
          iconName: 'tournament-titles',
          value: this.clubInfo.tournament_titles || 0,
          tooltip: 'club.tournamentTitles',
        },
        {
          iconName: 'master-titles',
          value: this.clubInfo.master_titles || 0,
          tooltip: 'club.championshipTitles',
        },
      ]
    },
  },
  methods: {
    redirectToClubMap(): void {
      this.$router.push({
        name: 'ClubsMap',
        params: { clubId: this.clubInfo.id },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.profile-header-wrapper {
  height: 14rem;
  color: #ffffff;
  background: url($path-clubs + 'components/profile/bg_top_header.avif') center no-repeat;
  background-size: 105% 100%;
  padding: 0 2rem 0 2rem;

  &-avatar {
    &-inner {
      width: 11.188rem;
      height: 11.188rem;
    }
  }

  &-left {
    width: 67%;
    justify-content: flex-start;
  }

  &-right {
    width: 33%;
    margin-left: 1rem;
  }

  &-club-name {
    background: url($path-clubs + 'components/profile/bg_club_name.avif') left no-repeat;
    background-size: 80% 100%;
    padding: 1rem 0 1rem 5rem;
    margin-left: -2rem;
  }

  &-club-stats {
    margin-left: 2rem;
    margin-top: 1rem;
    height: 4.3rem;

    &-box {
      width: 16.875rem;
      height: 100%;
      line-height: 2rem;
      @if $isWsm {
        background: #0f3251;
      }
      @if $isSsm {
        background: #363e5d;
      }

      & > div {
        transform: $skew-revert-value;
        margin: 0 0.5rem;
      }
    }
  }

  &-club-level {
    margin-top: 1.188rem;
    padding: 0 5rem 0 5rem;
    transform: translate(0, 0);

    @if $isWsm {
      background: linear-gradient(
        90deg,
        rgba(5, 25, 58, 0) 0%,
        rgba(5, 25, 58, 1) 50%,
        rgba(5, 25, 58, 0) 100%
      );
    }
    @if $isSsm {
      background: linear-gradient(
        90deg,
        rgba(5, 25, 58, 0) 0%,
        rgba(39, 50, 73, 0.85) 50%,
        rgba(5, 25, 58, 0) 100%
      );
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 0.125rem;
      left: 0;
      @if $isWsm {
        background: linear-gradient(
          90deg,
          rgba(106, 139, 195, 0) 0%,
          rgba(106, 139, 195, 1) 50%,
          rgba(106, 139, 195, 0) 100%
        );
      }
      @if $isSsm {
        background: linear-gradient(
          90deg,
          rgba(106, 139, 195, 0) 0%,
          rgba(#586b9d, 1) 50%,
          rgba(106, 139, 195, 0) 100%
        );
      }
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

    &-number {
      margin-left: 0.7rem;
    }
  }
}
</style>
