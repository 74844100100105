<template>
  <div class="training-discipline-footer flexing">
    <training-discipline-footer-pass
      v-if="isFooterPassAllowed"
      class="training-discipline-footer-pass"
      @init-load="$emit('initLoad')"
    />
    <training-discipline-footer-benefits :benefits-slots="benefitsSlots" :is-trainer="isTrainer" />
    <training-discipline-footer-supertraining
      v-if="!isTrainer"
      class="training-discipline-footer-supertraining"
    />
  </div>
</template>

<script lang="ts">
import TrainingDisciplineFooterBenefits from '@/components/Training/TrainingDisciplineFooterBenefits.vue'
import TrainingDisciplineFooterPass from '@/components/Training/TrainingDisciplineFooterPass.vue'
import TrainingDisciplineFooterSupertraining from '@/components/Training/TrainingDisciplineFooterSupertraining.vue'
import { OFFER_STATES } from '@/globalVariables'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { BenefitSlot } from '@/interfaces/Benefits'

export default defineComponent({
  name: 'TrainingDisciplineFooter',
  components: {
    TrainingDisciplineFooterSupertraining,
    TrainingDisciplineFooterBenefits,
    TrainingDisciplineFooterPass,
  },
  props: {
    benefitsSlots: {
      type: Object as PropType<Nullable<BenefitSlot>>,
      default: () => null,
    },
    isTrainer: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['initLoad'],
  data() {
    return {
      OFFER_STATES,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      rookiePassInfo: 'getRookiePassInfo',
    }),
    isFooterPassAllowed() {
      return (
        !this.isTrainer &&
        [OFFER_STATES.available, OFFER_STATES.active].includes(this.rookiePassInfo.state)
      )
    },
  },
})
</script>

<style lang="scss" scoped>
.training-discipline-footer {
  width: 120.563rem;
  position: relative;
  top: 1.5rem;
  margin: 0 auto;

  &-supertraining {
    position: absolute;
    right: 2rem;
    // tutorial: safe layering
    z-index: 0;
  }

  &-pass {
    position: absolute;
    left: 2rem;
  }
}
</style>
