<template>
  <app-multi-button1
    v-if="!purchased"
    btn-size="md"
    btn-theme="confirm"
    btn-type="realCurrency"
    :btn-text="$t('button.purchase')"
    :is-icon="false"
    :style="isLarge && { height: '5rem' }"
    @click="buyItem"
  >
    <template #rightPart>{{ price }}&nbsp;{{ currency }}</template>
  </app-multi-button1>
  <div v-else class="purchased flexing relative">
    <p class="flexing text-50 gradient-text-gold font-bold uppercase z-1">
      {{ $replacePlaceholder($te('eventPassActive'), '{pass}', $te(`${$route.query?.pass}pass`)) }}
    </p>
  </div>
  <payment-terminal
    v-if="terminal"
    :item-id="itemId"
    :wire-transfer-off="true"
    @close="terminal = false"
  />
</template>

<script lang="ts">
import PaymentTerminal from '@/components/Popup/Premium/PaymentTerminal.vue'
import { metaPremiumPackEndpoint, metaPremiumPayEndpoint } from '@/globalVariables'
import { getIsMobileLocalStorage, sendToFlutter } from '@/helpers'
import type PremiumPackApiResponse from '@/interfaces/responses/premium/PremiumPackApiResponse'
import type PremiumPayApiResponse from '@/interfaces/responses/premium/PremiumPayApiResponse'
import { useWarehouseStore } from '@/store/pinia/events/warehouseStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { PaymentMethods } from '@/interfaces/Payments'
// Vo wsm-admin si treba claimnut platbu a nasledne v konzole spustit `purchaseFinished('wsm_event_pass_1', true)`.

interface ComponentData {
  terminal: boolean
}

export default defineComponent({
  name: 'BuyPassButton',
  components: { PaymentTerminal },
  props: {
    itemId: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    purchased: {
      type: Boolean,
      default: false,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      terminal: false,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, ['isWarehouseConfigReset']),
    ...mapState(useWarehouseStore, {
      getWarehouseConfig: 'getConfig',
    }),
    isMobileLocalStorage(): boolean {
      return getIsMobileLocalStorage() === 1
    },
  },
  methods: {
    ...mapActions(useResponseTaskStore, ['showPaymentLoading', 'showPaymentMessage']),
    ...mapActions(useWarehouseStore, {
      clearWarehouseConfig: 'clearConfig',
    }),
    async buyItem(): Promise<void> {
      if (!this.isMobileLocalStorage) {
        this.terminal = true
        return
      }

      this.showPaymentLoading()

      const responsePay = await this.$axios.get<{}, PremiumPayApiResponse>(
        `${metaPremiumPayEndpoint}/pack/${this.itemId}/${
          !this.isMobileLocalStorage ? PaymentMethods.BankTransfer : ''
        }`,
      )

      if (responsePay?.event) {
        const message = {
          event: responsePay.event,
          productId: responsePay.productId,
        }
        sendToFlutter(JSON.stringify(message))
        return
      }

      const responsePack = await this.$axios.get<{}, PremiumPackApiResponse>(
        metaPremiumPackEndpoint + this.itemId,
      )

      this.showPaymentMessage({
        pack: this.itemId,
        rewards: responsePack.rewards,
        failed: false,
        af_quantity: responsePack.firebaseData.af_quantity,
        af_store_id: responsePack.firebaseData.af_store_id,
        gtm_currency: responsePack.firebaseData.gtm_currency,
        gtm_revenue: responsePack.firebaseData.gtm_revenue,
      })

      if (this.isWarehouseConfigReset && this.getWarehouseConfig !== null) {
        this.clearWarehouseConfig()
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.purchased {
  height: 4.56rem;
  width: 44.5rem;
  background: linear-gradient(to right, transparent, #b86d02, transparent);

  &::before,
  &::after {
    content: '';
    height: 0.225rem;
    width: 80%;
    background: linear-gradient(to right, transparent, #e09e2e, transparent);
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}
</style>
