const exportedEtcJson = {
  frames: {
    event_osra_discipline_11_1: {
      rotated: false,
      trimmed: false,
      sourceSize: {
        w: 212,
        h: 212,
      },
      spriteSourceSize: {
        x: 0,
        y: 0,
        w: 212,
        h: 212,
      },
      frame: {
        x: 424,
        y: 0,
        w: 212,
        h: 212,
      },
    },
    event_osra_discipline_12_1: {
      rotated: false,
      trimmed: false,
      sourceSize: {
        w: 212,
        h: 212,
      },
      spriteSourceSize: {
        x: 0,
        y: 0,
        w: 212,
        h: 212,
      },
      frame: {
        x: 0,
        y: 0,
        w: 212,
        h: 212,
      },
    },
    event_osra_discipline_13_1: {
      rotated: false,
      trimmed: false,
      sourceSize: {
        w: 212,
        h: 212,
      },
      spriteSourceSize: {
        x: 0,
        y: 0,
        w: 212,
        h: 212,
      },
      frame: {
        x: 0,
        y: 212,
        w: 212,
        h: 212,
      },
    },
    event_osra_discipline_14_1: {
      rotated: false,
      trimmed: false,
      sourceSize: {
        w: 212,
        h: 212,
      },
      spriteSourceSize: {
        x: 0,
        y: 0,
        w: 212,
        h: 212,
      },
      frame: {
        x: 636,
        y: 0,
        w: 212,
        h: 212,
      },
    },
    event_osra_discipline_15_1: {
      rotated: false,
      trimmed: false,
      sourceSize: {
        w: 212,
        h: 212,
      },
      spriteSourceSize: {
        x: 0,
        y: 0,
        w: 212,
        h: 212,
      },
      frame: {
        x: 212,
        y: 0,
        w: 212,
        h: 212,
      },
    },
  },
}

const exportedAstcJson = {
  frames: {
    event_osra_discipline_11_1: {
      rotated: false,
      trimmed: false,
      sourceSize: {
        w: 212,
        h: 212,
      },
      spriteSourceSize: {
        x: 0,
        y: 0,
        w: 212,
        h: 212,
      },
      frame: {
        x: 424,
        y: 0,
        w: 212,
        h: 212,
      },
    },
    event_osra_discipline_12_1: {
      rotated: false,
      trimmed: false,
      sourceSize: {
        w: 212,
        h: 212,
      },
      spriteSourceSize: {
        x: 0,
        y: 0,
        w: 212,
        h: 212,
      },
      frame: {
        x: 0,
        y: 0,
        w: 212,
        h: 212,
      },
    },
    event_osra_discipline_13_1: {
      rotated: false,
      trimmed: false,
      sourceSize: {
        w: 212,
        h: 212,
      },
      spriteSourceSize: {
        x: 0,
        y: 0,
        w: 212,
        h: 212,
      },
      frame: {
        x: 0,
        y: 212,
        w: 212,
        h: 212,
      },
    },
    event_osra_discipline_14_1: {
      rotated: false,
      trimmed: false,
      sourceSize: {
        w: 212,
        h: 212,
      },
      spriteSourceSize: {
        x: 0,
        y: 0,
        w: 212,
        h: 212,
      },
      frame: {
        x: 636,
        y: 0,
        w: 212,
        h: 212,
      },
    },
    event_osra_discipline_15_1: {
      rotated: false,
      trimmed: false,
      sourceSize: {
        w: 212,
        h: 212,
      },
      spriteSourceSize: {
        x: 0,
        y: 0,
        w: 212,
        h: 212,
      },
      frame: {
        x: 212,
        y: 0,
        w: 212,
        h: 212,
      },
    },
  },
}

const exportedImgJson = {
  frames: {
    event_osra_discipline_11_1: {
      rotated: false,
      trimmed: false,
      sourceSize: {
        w: 212,
        h: 212,
      },
      spriteSourceSize: {
        x: 0,
        y: 0,
        w: 212,
        h: 212,
      },
      frame: {
        x: 424,
        y: 0,
        w: 212,
        h: 212,
      },
      anchor: {
        x: 0.5,
        y: 0.5,
      },
    },
    event_osra_discipline_12_1: {
      rotated: false,
      trimmed: false,
      sourceSize: {
        w: 212,
        h: 212,
      },
      spriteSourceSize: {
        x: 0,
        y: 0,
        w: 212,
        h: 212,
      },
      frame: {
        x: 0,
        y: 0,
        w: 212,
        h: 212,
      },
      anchor: {
        x: 0.5,
        y: 0.5,
      },
    },
    event_osra_discipline_13_1: {
      rotated: false,
      trimmed: false,
      sourceSize: {
        w: 212,
        h: 212,
      },
      spriteSourceSize: {
        x: 0,
        y: 0,
        w: 212,
        h: 212,
      },
      frame: {
        x: 0,
        y: 212,
        w: 212,
        h: 212,
      },
      anchor: {
        x: 0.5,
        y: 0.5,
      },
    },
    event_osra_discipline_14_1: {
      rotated: false,
      trimmed: false,
      sourceSize: {
        w: 212,
        h: 212,
      },
      spriteSourceSize: {
        x: 0,
        y: 0,
        w: 212,
        h: 212,
      },
      frame: {
        x: 636,
        y: 0,
        w: 212,
        h: 212,
      },
      anchor: {
        x: 0.5,
        y: 0.5,
      },
    },
    event_osra_discipline_15_1: {
      rotated: false,
      trimmed: false,
      sourceSize: {
        w: 212,
        h: 212,
      },
      spriteSourceSize: {
        x: 0,
        y: 0,
        w: 212,
        h: 212,
      },
      frame: {
        x: 212,
        y: 0,
        w: 212,
        h: 212,
      },
      anchor: {
        x: 0.5,
        y: 0.5,
      },
    },
  },
}

export const eventOsraEtcJson = exportedEtcJson
export const eventOsraAstcJson = exportedAstcJson
export const eventOsraImgJson = exportedImgJson
