<template>
  <div class="club-chat-online-players">
    <!-- <app-select
      :options="allPlayers"
      :default="allPlayersDefault"
      :placeholder="'Online'"
      :has-disabled-option="true"
    >
      <template #select-option>
       
      </template>
    </app-select> -->
    <div class="online-select flex items-center justify-end">
      <p class="text-texts-standard-default text-32 uppercase font-bold">Online</p>
      <div class="online-status" />
      <p class="text-texts-standard-default text-32 font-bold">
        {{ onlinePlayersCount }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ChatOnlinePlayers',
  props: {
    onlinePlayersCount: {
      type: Number,
      default: 0,
    },
    // allPlayersDefault: {
    //   type: String,
    //   required: true
    // }
  },
})
</script>

<style lang="scss" scoped>
.club-chat {
  .online-select {
    width: 21rem;

    .online-status {
      width: 1.5rem;
      height: 1.5rem;
      border: solid 0.125rem #0ac506;
      background-image: radial-gradient(circle at center, #3fe443 10%, #18c110 50%);
      border-radius: 50%;
      margin: 0 1rem;
      box-shadow:
        0 0 13.5px 1.5px rgba(63, 228, 67, 0.3),
        inset 0px 5px 5px 0 rgba(63, 228, 67, 0.5);
    }
  }
}
</style>
