<template>
  <div class="clubs-employees-box-lock absolute flexing text-30 text-texts-standard-default">
    <div class="clubs-employees-box-lock-content flexing flex-col">
      <app-icon icon-name="lock-lg" />
      <!-- eslint-disable vue/no-v-html -->
      <p v-html="unlockText" />
      <!-- eslint-enable -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClubsEmployeesBoxLock',
  props: {
    unlockLevel: {
      type: Number,
      default: 0,
    },
    isPersonal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    unlockText(): string {
      return this.$replacePlaceholder(
        this.$replaceHtmlPlaceholders(
          this.$replacePlaceholder(
            this.$t(this.isPersonal ? 'club.fromUserLevel' : 'club.fromClubLevel'),
            '{level}',
            '{b}{level}{/b}',
          ),
          'b',
          'text-texts-standard-important',
        ),
        '{level}',
        String(this.unlockLevel),
      )
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/club/employees.scss';
</style>
