<template>
  <tippy
    v-if="(isEventTeaserActive || isEventActive) && !isTutorial"
    theme="WSM1"
    placement="left"
    max-width="50rem"
    :on-show="isTooltipVisible"
  >
    <div
      class="board-games-event-rbox text-24 cursor-pointer clickable-element pointer-events-auto relative"
      :class="{ 'ice-fire': isIceAndFireEvent }"
      @click="onRBoxClick"
    >
      <div class="title text-texts-standard-default text-left italic uppercase absolute bottom-8">
        {{ isIceAndFireEvent ? 'Ice and Fire' : $t('boardGames.eventBoardGames') }}
      </div>
      <vue-countdown
        v-if="eventTimer"
        v-slot="{ days, hours, minutes, seconds }"
        class="time flexing text-texts-standard-default italic absolute bottom-0"
        :time="eventTimer"
        @end="onEventTimerComplete"
      >
        <app-icon icon-name="time" class="time-icon inline mr-2" />
        {{ formatTime(days, hours, minutes, seconds) }}
      </vue-countdown>
      <app-icon v-if="isEventLocked" class="lock" icon-name="lock-sm" />
    </div>
    <template v-if="isEventLocked" #content>
      <div class="lock-tooltip">
        <div class="lock-tooltip-title flexing">
          <p class="uppercase font-bold text-30">{{ $t('boardGames.eventBoardGames') }}</p>
        </div>
        <div
          class="lock-tooltip-text-margin text-28"
          v-html="
            $replacePlaceholder(
              $replaceHtmlPlaceholders(
                $replacePlaceholder($t('common.mainCareerTaskRequired'), '%s', '{b} %s {/b}'),
                'b',
                'text-texts-standard-important',
              ),
              '%s',
              getMechanicUnlock(MECHANIC_BOARD_GAMES_EVENT),
            )
          "
        />
      </div>
    </template>
  </tippy>
</template>

<script lang="ts">
import { MECHANIC_BOARD_GAMES_EVENT } from '@/globalVariables'
import { DESTROY_BOARD_GAMES_EVENT_OSRA } from '@/map-phaser-new/constants'
import { formatTime, openBoardGamesEvent } from '@/helpers'
import { useBoardGamesEventStore } from '@/store/pinia/boardGamesEventStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'

interface ComponentData {
  MECHANIC_BOARD_GAMES_EVENT: typeof MECHANIC_BOARD_GAMES_EVENT
}

export default defineComponent({
  data(): ComponentData {
    return {
      MECHANIC_BOARD_GAMES_EVENT,
    }
  },
  computed: {
    ...mapState(useBoardGamesEventStore, [
      'isEventTeaserActive',
      'isEventActive',
      'isEventLocked',
      'eventTimer',
      'isIceAndFireEvent',
    ]),
    ...mapState(useTutorialStore, { isTutorial: 'getIsTutorial' }),
    ...mapState(usePhaserGameIntegrationStore, { phaserEventEmitter: 'getPhaserEventEmitter' }),
    ...mapState(useResponseTaskStore, { getMechanicUnlock: 'getMechanicUnlock' }),
  },
  methods: {
    ...mapActions(useBoardGamesEventStore, ['loadEvent']),
    formatTime,
    async onRBoxClick(): Promise<void> {
      await openBoardGamesEvent()
    },
    async onEventTimerComplete(): Promise<void> {
      await this.loadEvent(true)

      if (!this.eventTimer) {
        this.phaserEventEmitter.emit(DESTROY_BOARD_GAMES_EVENT_OSRA)
      }
    },
    // Toto musi byt metoda, nie computed.
    isTooltipVisible(): boolean {
      return !!this.isEventLocked
    },
  },
})
</script>

<style lang="scss" scoped>
.board-games-event-rbox {
  @include background(
    url('#{$path-events}/board-games-event/rb-board-games-event.avif?v=2'),
    contain,
    bottom
  );
  width: 100%;
  height: 11.5625rem;
  &.ice-fire {
    @include background(
      url('#{$path-events}/board-games-event/rb-ice-and-fire-event.avif?v=3'),
      contain,
      bottom
    );
  }

  .lock {
    position: absolute;
    right: 0.125rem;
    bottom: 0.125rem;
  }

  .title {
    width: 100%;
    height: 1.75rem;
    padding-left: 0.9rem;
  }

  .time {
    width: 100%;
    height: 2rem;

    &-icon {
      width: 1em !important;
      height: 1em !important;
    }
  }
}
</style>
