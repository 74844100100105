<template>
  <section class="lucky-progress flex items-center" :class="$isMobile() && 'lucky-progress-mobile'">
    <lucky-wheel-progress-text :spin-points="configProperties.pointPerSpin" />
    <app-progress-bar
      :bar-width="30.875"
      :bar-height="1.625"
      :text-size="2.25"
      :actual="configProperties.actual"
      :goal="configProperties.goal"
      :counts="true"
      :show-bottom-status="false"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import LuckyWheelProgressText from './LuckyWheelProgressText.vue'
import type { LuckyWheelConfigProgressProperties } from './LuckyWheelMain.vue'

export default defineComponent({
  name: 'LuckyWheelProgress',
  components: {
    LuckyWheelProgressText,
  },
  props: {
    configProperties: {
      type: Object as PropType<LuckyWheelConfigProgressProperties>,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.lucky-progress {
  flex-direction: column;
  width: 47rem;
  margin-bottom: 3rem;
}

.lucky-progress-mobile {
  width: 90%;
}
</style>
