<template>
  <div>
    <matches-report
      :history-data="historyData"
      :redirect-link="($route.query.redirect as string) ?? 'ArenaView'"
      :is-video-reward-earned="isVideoRewardEarned"
      @watches="watchAds"
      @rewarded-watch="rewardedVideoEarned"
    />
    <video-ad-web v-show="isWebVideo" />
  </div>
</template>

<script lang="ts">
import VideoAdWeb from '@/components/Premium/Ads/VideoAdWeb.vue'
import MatchesReport from '@/components/Reports/MatchesReport.vue'
import { metaPremiumAdsClaimEndpoint } from '@/globalVariables'
import { getReportValues, sendToFlutter } from '@/helpers'
import { useAdStore } from '@/store/pinia/adStore'
import { useMatchesStore } from '@/store/pinia/matchesStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useGamePassStore } from '@/store/pinia/gamePassStore'
import type { Nullable } from '@/interfaces/utils'
import type ArenaMatchReportApiResponse from '@/interfaces/responses/reports/ArenaMatchReportApiResponse'
import type { ReportValues } from '@/helpers/getReportValues'
import type { PremiumVideoAdsClaimApiResponse } from '@/interfaces/responses/premium/PremiumVideoAdsClaimApiResponse'
import { VideoAdState } from '@/enums/VideoAdStates'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'

interface ComponentData {
  adCategory: string
  historyData: Nullable<ArenaMatchReportApiResponse>
  isVideoRewardEarned: boolean
  matchId: Nullable<string>
  matchTime: Nullable<ReportValues['matchTime']>
  reportData: Nullable<string>
}

export default defineComponent({
  name: 'ArenaReport',
  components: {
    MatchesReport,
    VideoAdWeb,
  },
  data(): ComponentData {
    return {
      adCategory: '',
      historyData: null,
      isVideoRewardEarned: false,
      matchId: null,
      matchTime: null,
      reportData: null,
    }
  },
  computed: {
    ...mapState(useMatchesStore, {
      matchReport: 'getMatchReport',
    }),
    ...mapState(usePremiumStore, {
      isWebVideo: 'getWebVideoPlaying',
    }),
    ...mapState(useResponseTaskStore, {
      userLevelUp: 'getUserLevelUp',
    }),
    ...mapState(useGamePassStore, ['isNoVideoAds']),
  },
  watch: {
    userLevelUp(): void {
      // after the lvlUp video ad has been completely watched and the screen is closing,
      // a post-match screen with an active first place and a space for watching a video ad remains in the background
      // we identify the hiding of the lvlUp screen and update the reference to this correct method, resolving the watching of the arena ad
      window.rewardedVideoEarned = this.rewardedVideoEarned
      window.failedToLoadVideoAds = this.rewardedVideoFailed
      window.rewardedVideoEnd = this.rewardedVideoEnd
    },
  },
  async created(): Promise<void> {
    const { matchId, matchTime } = getReportValues(this.$route)

    this.matchId = matchId
    this.matchTime = matchTime

    this.reportData = await this.loadMatchReport({
      matchId: this.matchId,
      matchTime: this.matchTime,
    })
    try {
      this.historyData = this.matchReport(this.matchId ? this.matchId : this.reportData)
    } catch (err: unknown) {
      console.error(err)
    }
  },
  mounted(): void {
    // if the lvlUp is triggered after the match is played (first place)
    // the screen of the arena after-match is temporarily covered by the lvlUp window
    // we don't want this 'arena method' to be instantiated for the finishing status of the lvlUp video ad
    if (this.userLevelUp) return
    window.rewardedVideoEarned = this.rewardedVideoEarned
    window.failedToLoadVideoAds = this.rewardedVideoFailed
    window.rewardedVideoEnd = this.rewardedVideoEnd
  },
  methods: {
    ...mapActions(useAdStore, ['setAdBlockState']),
    ...mapActions(useMatchesStore, ['loadMatchReport']),
    ...mapActions(usePremiumStore, ['setVideoPlay']),
    async watchAds(adType: string): Promise<void> {
      this.adCategory = adType
      try {
        await this.$axios.put<{}, PremiumVideoAdsClaimApiResponse>(metaPremiumAdsClaimEndpoint, {
          type: adType,
          state: VideoAdState.Started,
        })
        if (!this.isNoVideoAds && this.$isMobile()) {
          sendToFlutter('{\r\n "event":"playRewarded"\r\n}')
        }
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async rewardedVideoEarned(): Promise<void> {
      try {
        await this.$axios.put<{}, PremiumVideoAdsClaimApiResponse>(metaPremiumAdsClaimEndpoint, {
          type: this.adCategory,
          state: VideoAdState.Finished,
        })
        this.isVideoRewardEarned = true
        this.setVideoPlay(false)
      } catch (error: unknown) {
        console.error(error)
      }
    },
    rewardedVideoFailed(): void {
      this.setAdBlockState(true)
      this.setVideoPlay(false)
    },
    rewardedVideoEnd(): void {
      this.setVideoPlay(false)
    },
  },
})
</script>
