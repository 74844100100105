import type { InteractiveObjectsInterface } from '@/map-phaser-new/interfaces'
import { clubsMapBuildings } from './clubsMapBuildings'
import { tutorial, buildingRewards } from '@/map-phaser-new/config/winter-sports/commonObjects'
import { weatherConfig } from './weather'

export const interactiveObjectsConfig: InteractiveObjectsInterface = {
  buildings: clubsMapBuildings,
  weather: weatherConfig,
  additionalConfigs: {
    tutorial,
    buildingRewards,
  },
}
