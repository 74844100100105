<template>
  <div class="hamburger-icon" :class="`hamburger-icon-${iconName}`" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppHamburgerIcon',
  props: {
    iconName: {
      type: String,
      required: true,
      validator(value: string): boolean {
        return [
          'admin',
          'faq',
          'contact',
          'rules',
          'bugs',
          'tutorial',
          'log-off',
          'news',
          'post',
          'settings',
          'external-link',
          'connectAccount',
          'sound',
          'music',
        ].includes(value)
      },
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/hamburger-icons.scss';
</style>
