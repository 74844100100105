<template>
  <section class="history-view w-full min-h-0 flex flex-col flex-grow safe-area">
    <main v-if="!isLoading" class="w-full h-full flex flex-col">
      <history-header
        :tournament-type="tournamentType"
        :is-disabled="!tournamentCount"
        @set-tournament-type="tournamentType = $event"
      />
      <div
        class="history-main w-full min-h-0 flex flex-col flex-grow px-8 py-4 space-x-8 relative"
        :class="{
          'no-shadow': !tournamentCount,
        }"
      >
        <history-empty v-if="!tournamentCount" />
        <app-scrollbar
          v-else
          class="history-scrollbar justify-center items-center space-y-5 pr-4"
          width="100%"
          height="100%"
          scroll="y"
          slide="y"
        >
          <template v-if="tournamentType === TournamentType.All">
            <template v-for="(type, j) in Object.keys(tournaments)" :key="j">
              <div class="header relative top-2">
                <p
                  class="flex flex-row justify-start items-center text-28 text-texts-standard-default uppercase px-4"
                >
                  <template v-if="type === TournamentType.Active">
                    {{ $t('tournaments.currentTournaments') }}
                  </template>
                  <template v-else-if="type === TournamentType.Waiting">
                    {{ $t('tournaments.nextTournaments') }}
                  </template>
                  <template v-else-if="type === TournamentType.Finished">
                    {{ $t('tournaments.endedTournaments') }} ({{
                      $replacePlaceholder(
                        $t('tournaments.lastXDays'),
                        '{number}',
                        TOURNAMENTS_HISTORY_LIMIT.toString(),
                      )
                    }})
                  </template>
                </p>
              </div>
              <tournament-row
                v-for="(tournament, i) in tournaments[type]"
                :key="i"
                :tournament="tournament"
                :meta="meta"
                :context="TournamentButtonContext.History"
                :index="i"
                @reload-data="loadState(true)"
              />
            </template>
          </template>
          <template v-else>
            <div class="header relative top-2">
              <p
                class="flex flex-row justify-start items-center text-28 text-texts-standard-default uppercase px-4"
              >
                <template v-if="tournamentType === TournamentType.Active">
                  {{ $t('tournaments.currentTournaments') }}
                </template>
                <template v-else-if="tournamentType === TournamentType.Waiting">
                  {{ $t('tournaments.nextTournaments') }}
                </template>
                <template v-else-if="tournamentType === TournamentType.Finished">
                  {{ $t('tournaments.endedTournaments') }} ({{
                    $replacePlaceholder(
                      $t('tournaments.lastXDays'),
                      '{number}',
                      TOURNAMENTS_HISTORY_LIMIT.toString(),
                    )
                  }})
                </template>
              </p>
            </div>
            <tournament-row
              v-for="(tournament, i) in tournaments[tournamentType]"
              :key="i"
              :tournament="tournament"
              :meta="meta"
              :context="TournamentButtonContext.History"
              :index="i"
              @reload-data="loadState(true)"
            />
          </template>
        </app-scrollbar>
      </div>
    </main>
    <component-loading v-else :is-loading="true" height="100%" />
  </section>
</template>

<script lang="ts">
import { TOURNAMENTS_HISTORY_LIMIT } from '@/globalVariables'
import HistoryHeader, { TournamentType } from './HistoryHeader.vue'
import HistoryEmpty from './HistoryEmpty.vue'
import TournamentRow from '../TournamentRow.vue'
import { TournamentButtonContext } from '../TournamentButton.vue'
import { useTournamentsHistoryStore } from '@/store/pinia/tournaments/useTournamentsHistoryStore'
import { TournamentState, TournamentUserState, type Tournament } from '@/interfaces/Tournaments'
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'

interface TournamentsByType {
  [TournamentType.Active]: Tournament[]
  [TournamentType.Waiting]: Tournament[]
  [TournamentType.Finished]: Tournament[]
}

interface ComponentData {
  TOURNAMENTS_HISTORY_LIMIT: typeof TOURNAMENTS_HISTORY_LIMIT
  TournamentButtonContext: typeof TournamentButtonContext
  TournamentType: typeof TournamentType
  tournamentType: TournamentType
  isLoading: boolean
}

export default defineComponent({
  components: {
    HistoryHeader,
    HistoryEmpty,
    TournamentRow,
  },
  data(): ComponentData {
    return {
      TOURNAMENTS_HISTORY_LIMIT,
      TournamentButtonContext,
      TournamentType,
      tournamentType: TournamentType.All,
      isLoading: false,
    }
  },
  computed: {
    ...mapState(useTournamentsHistoryStore, {
      _tournaments: 'tournaments',
      meta: 'meta',
    }),
    tournaments(): TournamentsByType {
      if (!this._tournaments)
        return {
          [TournamentType.Active]: [],
          [TournamentType.Waiting]: [],
          [TournamentType.Finished]: [],
        }

      const tournaments = [...this._tournaments].sort(
        (a: Tournament, b: Tournament): number =>
          new Date(a.start_date).getTime() - new Date(b.start_date).getTime(),
      )

      const currentTimestamp = Date.now()
      const sevenDaysAgoTimestamp =
        currentTimestamp - TOURNAMENTS_HISTORY_LIMIT * 24 * 60 * 60 * 1e3

      return {
        [TournamentType.Active]: tournaments.filter(
          ({
            start_date,
            end_date,
            evaluate_end_date,
            tournament_state,
            user_state,
          }: Tournament): boolean =>
            new Date(start_date).getTime() < currentTimestamp &&
            new Date(evaluate_end_date || end_date).getTime() > currentTimestamp &&
            [TournamentState.Running, TournamentState.Evaluating].includes(tournament_state) &&
            [TournamentUserState.Running, TournamentUserState.Finished].includes(user_state),
        ),
        [TournamentType.Waiting]: tournaments.filter(
          ({ start_date, tournament_state, user_state }: Tournament): boolean =>
            new Date(start_date).getTime() > currentTimestamp &&
            tournament_state === TournamentState.Open &&
            user_state === TournamentUserState.Joined,
        ),
        [TournamentType.Finished]: tournaments
          .filter(
            ({ start_date, end_date, tournament_state, user_state }: Tournament): boolean =>
              new Date(start_date).getTime() > sevenDaysAgoTimestamp &&
              new Date(end_date).getTime() < currentTimestamp &&
              tournament_state === TournamentState.Finished &&
              user_state === TournamentUserState.Finished,
          )
          .sort(
            (a: Tournament, b: Tournament): number =>
              new Date(b.end_date).getTime() - new Date(a.end_date).getTime(),
          ),
      }
    },
    tournamentCount(): number {
      return Object.values(this.tournaments).reduce(
        (acc: number, tournaments: Tournament[]): number => acc + tournaments.length,
        0,
      )
    },
  },
  async created(): Promise<void> {
    this.isLoading = true
    await this.loadState(true)
    this.isLoading = false
  },
  methods: {
    ...mapActions(useTournamentsHistoryStore, ['loadState']),
  },
})
</script>

<style lang="scss" scoped>
.history-view {
  .history-main {
    &:not(.no-shadow)::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 5rem;
      background-image: linear-gradient(0deg, rgba(2, 20, 34, 0.7) 40%, rgba(35, 52, 65, 0) 100%);
    }
  }

  .header {
    height: 3rem;
    @if $isSsm {
      background-color: #292d30;
    }
    @if $isWsm {
      background-color: #09172a;
    }
  }
}
</style>
