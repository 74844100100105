<template>
  <div class="championship-summary-rewards flexing flex-col">
    <div class="championship-summary-rewards-title">
      <p class="text-32 text-texts-standard-important font-bold">
        {{ $t('common.rewards') }}
      </p>
    </div>
    <div class="championship-summary-rewards-box flexing">
      <aside v-if="summaryData.rewards?.length > 0" class="flexing">
        <reward-box
          v-for="(reward, index) in setRewards"
          :key="index"
          :reward-icon="72"
          :reward-icon-name="reward.type"
          :reward-number="reward.value"
        />
      </aside>
      <aside v-else>
        <p class="text-texts-standard-additional text-32">
          {{ $t('club.noReward') }}
        </p>
      </aside>
    </div>
  </div>
</template>

<script lang="ts">
import { CATEGORY_BENEFIT } from '@/globalVariables'
import { getIconName } from '@/helpers'
import { defineComponent } from 'vue'
import type Reward from '@/interfaces/Reward'
import type { PropType } from 'vue'
import type { ClubChampsSummary } from '@/interfaces/clubs/ClubChampionships'

export default defineComponent({
  name: 'ChampionshipSummaryRewards',
  props: {
    summaryData: {
      type: Object as PropType<ClubChampsSummary>,
      required: true,
    },
  },

  computed: {
    setRewards(): Reward[] {
      return this.summaryData.rewards.map((reward: Reward): Reward => {
        if (reward.type === CATEGORY_BENEFIT) {
          return {
            type: getIconName(reward),
            value: reward.value,
          }
        }
        return reward
      })
    },
  },
})
</script>
