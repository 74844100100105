<template>
  <div id="benefits-inventory" class="benefits-inventory app-page-wrapper absolute">
    <menu-component
      menu-type="benefits"
      :title="$t('benefits.header')"
      :menu-items="{
        1: {
          text: $t('benefits.inventory'),
          route: $getWebView('BenefitsInventory'),
        },
        2: {
          text: $t('benefits.setting'),
          route: $getWebView('BenefitsBuildings'),
        },
      }"
    />
    <section class="w-full h-full safe-area">
      <main v-if="benefitData">
        <div class="benefits-inventory-head">
          <info-text :text="$t('benefits.about')" />
        </div>
        <app-scrollbar width="98%" height="48rem" slide="y" scroll="y" class="m-auto">
          <benefit-category
            v-for="(benefits, benefitCategory) in benefitData"
            :key="benefitCategory"
            :category-title="$t(setText(benefitCategory.toString()))"
            :benefits="benefits"
            :allow-upgrade="true"
            @benefit-upgraded="loadBenefits()"
          />
        </app-scrollbar>
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import BenefitCategory from '@/components/Benefits/BenefitCategory.vue'
import InfoText from '@/components/InfoText.vue'
import type { BenefitSlotData } from '@/interfaces/Benefits'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface BenefitData {
  attribute?: BenefitSlotData[]
  cash?: BenefitSlotData[]
  training_points?: BenefitSlotData[]
}

interface ComponentData {
  showPopup: boolean
  benefitData: BenefitData
}

export default defineComponent({
  name: 'InventoryComponent',
  components: {
    BenefitCategory,
    InfoText,
  },
  data(): ComponentData {
    return {
      showPopup: false,
      benefitData: null,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      benefits: 'getBenefits',
    }),
  },
  async created(): Promise<void> {
    await this.loadBenefits()
  },
  methods: {
    ...mapActions(useResponseTaskStore, {
      _loadBenefits: 'loadBenefits',
      _loadBenefitsSlots: 'loadBenefitsSlots',
    }),
    async loadBenefits(): Promise<void> {
      await Promise.all([this._loadBenefits(), this._loadBenefitsSlots()])

      const benefits = this.benefits
      if (benefits.length > 0) {
        this.benefitData = benefits.reduce(
          (finalObj: BenefitData, benefit: BenefitSlotData): BenefitData => {
            finalObj[benefit.benefit_type] = [...(finalObj[benefit.benefit_type] ?? []), benefit]
            return finalObj
          },
          {},
        )

        for (const key in this.benefitData) {
          this.benefitData[key] = this.benefitData[key].sort(
            ({ benefit_id: a }: BenefitSlotData, { benefit_id: b }: BenefitSlotData): number =>
              a - b,
          )
        }
      }
    },
    setText(name: string): string {
      if (name === 'attribute') return 'benefits.attributes'
      else if (name === 'cash') return 'benefits.moneyRewards'
      else if (name === 'training_points') return 'benefits.training'
    },
  },
})
</script>

<style lang="scss" scoped>
.benefits-inventory {
  &-head {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 1.438rem auto;
  }

  &-scroll {
    height: 54rem;
  }
}
</style>
