<template>
  <div
    id="club-post-message"
    class="club-post-message-view flex flex-col app-page-wrapper absolute"
  >
    <clubs-managment-menu />
    <section class="club-post-message-wrapper w-full h-full safe-area">
      <div class="club-post-message">
        <main>
          <header-info>
            <h2 class="text-texts-standard-default text-28">
              {{ $t('club.postMessageHeader') }}
            </h2>
          </header-info>
          <div class="mail-new-message club-post-message-wrapper text-left w-full">
            <span class="new-mail-label flex justify-between">
              <p class="text-texts-standard-additional text-30 uppercase">
                {{ $t('post.subject') }}
              </p>
              <p class="normal-case text-texts-standard-additional text-30">
                {{ `*${$t('post.optional')}` }}
              </p>
            </span>
            <div
              class="new-mail-input-wrapper w-full relative"
              :class="{ 'input-ok': isSubjectOk() }"
            >
              <app-input
                id="searchInput"
                class="club-post-message-input"
                :value="subject"
                :placeholder="$t('post.inputSubject')"
                theme="theme-2"
                no-tooltip
                @input="(value) => (subject = value)"
              />
            </div>
            <span class="flex justify-between w-full mt-3">
              <p class="text-texts-standard-additional text-30 uppercase">
                {{ $t('post.message') }}
              </p>
              <p class="text-texts-standard-danger text-30 normal-case">
                {{ `*${$t('post.required')}` }}
              </p>
            </span>
            <app-textarea
              class="club-post-message-textarea"
              :message="message"
              :placeholder="$t('post.inputMessage')"
              theme="theme-2"
              @input="setMessage"
            />
          </div>
        </main>
        <footer class="flex justify-end mt-4">
          <app-button
            btn-type="secondary"
            :btn-text="$t('post.send')"
            :disabled="!canSend"
            @click="sendMessage"
          />
        </footer>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import ClubsManagmentMenu from '@/components/Club/ClubsManagmentMenu.vue'
import AppTextarea from '@/components/GlobalComponents/AppTextarea.vue'
import HeaderInfo from '@/components/Header/HeaderInfo.vue'
import { useMailStore } from '@/store/pinia/mailStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  subject: string
  message: string
}

export default defineComponent({
  name: 'ClubsPostMessage',
  components: {
    ClubsManagmentMenu,
    HeaderInfo,
    AppTextarea,
  },
  data(): ComponentData {
    return {
      subject: '',
      message: '',
    }
  },
  computed: {
    canSend(): boolean {
      return this.message && this.message.length > 0
    },
  },
  methods: {
    ...mapActions(useMailStore, ['sendClubMessage']),
    sendMessage(): void {
      this.sendClubMessage({
        subject: this.subject,
        message: this.message,
      })
      this.subject = ''
      this.message = ''
    },
    isSubjectOk(): boolean {
      return this.subject.length > 0
    },
    setMessage(input: Event) {
      this.message = (input.target as HTMLInputElement).value
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/mail/mail.scss';

.club-post-message-view {
  gap: 0.938rem;

  .club-post-message {
    width: 98%;
    height: 100%;
    margin: 0 auto;

    main {
      .club-post-message-wrapper {
        height: auto;
        margin-top: 1rem;
        padding: 1.625rem;

        .club-post-message-input {
          width: 100%;
          height: 5rem;
        }

        .club-post-message-textarea {
          height: 20rem;
        }
      }
    }

    :deep() {
      .app-input {
        font-size: 2.25rem;
      }

      .app-textarea-content {
        font-size: 2rem;
      }
    }
  }
}
</style>
