<template>
  <div
    class="main-duel-footer"
    :class="benefitsSlots ? 'flexing' : 'flex align-items justify-between'"
    :style="isTutorialStep ? { zIndex: '5' } : {}"
  >
    <arena-competitions-footer-refresh-opponents
      :expiration="expiration"
      :type="type"
      @refresh-opponents="$emit('refreshOpponents')"
      @load-opponents="$emit('loadOpponents')"
    />

    <arena-competitions-footer-benefits
      :allowed-benefit-types="allowedBenefitTypes"
      :required-benefits="requiredBenefits"
      :benefits-slots="benefitsSlots"
      @load-opponents="$emit('loadOpponents')"
      @load-specific-discipline="$emit('loadSpecificDiscipline')"
    />

    <arena-competitions-footer-supermatch
      :type="type"
      @switch-match-type="$emit('switchMatchType')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import ArenaCompetitionsFooterBenefits from '@/components/Arena/ArenaCompetitions/ArenaCompetitionsFooterBenefits.vue'
import ArenaCompetitionsFooterRefreshOpponents from '@/components/Arena/ArenaCompetitions/ArenaCompetitionsFooterRefreshOpponents.vue'
import ArenaCompetitionsFooterSupermatch from '@/components/Arena/ArenaCompetitions/ArenaCompetitionsFooterSupermatch.vue'
import { ARENA, CLUB_CHAMPIONSHIP } from '@/globalVariables'
import { useTutorialStore } from '@/store/pinia/tutorialStore'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { BenefitSlot } from '@/interfaces/Benefits'

export default defineComponent({
  name: 'ArenaCompetitionFooter',
  components: {
    ArenaCompetitionsFooterBenefits,
    ArenaCompetitionsFooterSupermatch,
    ArenaCompetitionsFooterRefreshOpponents,
  },
  props: {
    benefitsSlots: {
      type: Object as PropType<Nullable<BenefitSlot['slots']>>,
      default: () => null,
    },
    expiration: {
      type: Number,
      default: null,
    },
    allowedBenefitTypes: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    requiredBenefits: {
      type: Number,
      default: () => 1,
    },
    type: {
      type: String,
      default: ARENA,
      validator(value: string): boolean {
        return [ARENA, CLUB_CHAMPIONSHIP].includes(value)
      },
    },
  },
  emits: ['loadOpponents', 'refreshOpponents', 'switchMatchType', 'loadSpecificDiscipline'],
  computed: {
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
    }),
    isTutorialStep(): boolean {
      return this.actualStageData?.name === 'clickOnSuperCompetitionButton'
    },
  },
})
</script>
