<template>
  <info-popup
    v-if="isVisible"
    :popup-title="$te('clubContributors')"
    width="76.125rem"
    height="41.5rem"
    class="rewards"
    @close="$emit('close')"
  >
    <section v-if="loaded" class="contributors justify-center">
      <header
        class="contributors-header uppercase m-auto flex items-center text-texts-standard-default text-28 justify-between"
      >
        <p>{{ $t('common.name') }}</p>
        <p>{{ $t('arena.points') }}</p>
      </header>
      <app-scrollbar class="m-auto" width="97%" height="32rem" scroll="y" slice="y">
        <section class="table-standard">
          <article
            v-for="(clubMember, i) in contributions"
            :key="i"
            class="table-standard-row contributors-row flex items-center relative"
          >
            <div class="contributors-row-level text-texts-standard-dark uppercase">
              <p class="contributors-row-level-text h-1/2 revert-skew">lvl</p>
              <p class="font-bold text-30 h-1/2 revert-skew">{{ clubMember.level }}</p>
            </div>
            <app-user
              class="uppercase"
              :no-country-text="true"
              :country="clubMember.country"
              :name="clubMember.name"
            />
            <div class="points flexing text-32 text-texts-standard-default absolute right-0">
              <app-icon v-if="isEventLeague" :icon-name="eventLeagueIcon" />
              <app-main-icon v-else icon-name="event_points" :icon-size="56" />

              <p>{{ clubMember.points }}</p>
            </div>
          </article>
        </section>
      </app-scrollbar>
    </section>
    <component-loading :is-loading="!loaded" height="100%" />
  </info-popup>
</template>

<script lang="ts">
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import type { Contribution } from '@/interfaces/responses/events/league/LeagueContributorsApiResponse'
import { useMilestonesStore } from '@/store/pinia/events/milestonesStore'
import { useLeagueEventStore } from '@/store/pinia/events/leagueStore'
import { mapActions, mapState } from 'pinia'

interface ComponentData {
  contributions: Contribution[]
  loaded: boolean
}

export default {
  name: 'ClubContributionPopup',
  components: {
    InfoPopup,
    AppUser,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    isEventLeague: {
      type: Boolean,
      default: false,
    },
    eventLeagueIcon: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      contributions: [],
      loaded: false,
    }
  },
  computed: {
    ...mapState(useLeagueEventStore, {
      leagueContributors: 'getLeagueContributors',
    }),
    ...mapState(useMilestonesStore, {
      clubContributors: 'getClubContributors',
    }),
  },
  watch: {
    isVisible(): void {
      if (!this.loaded) this.getContributors()
    },
  },
  methods: {
    ...mapActions(useLeagueEventStore, {
      loadLeagueContributors: 'loadContributors',
    }),
    ...mapActions(useMilestonesStore, ['loadClubContributors']),
    async getContributors(): Promise<void> {
      if (this.isEventLeague) {
        await this.loadLeagueContributors()
        this.contributions = this.leagueContributors
        this.loaded = true
        return
      }

      await this.loadClubContributors()
      try {
        this.contributions = this.clubContributors
        this.loaded = true
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.contributors {
  &-header {
    margin-top: 0.8rem;
    width: 73.3125rem;

    height: 2.375rem;
    padding: 0 2.9375rem 0 1.3125rem;

    @if $isWsm {
      background-color: #09172a;
    }

    @if $isSsm {
      background-color: #292d30;
    }
  }

  &-row {
    height: 4.5625rem;
    width: 73.3125rem;
    margin: 0.1875rem auto;
    padding-left: 1rem;

    &-level {
      margin-right: 0.9rem;
      line-height: 1.5rem;

      @if $isWsm {
        width: 3.7rem;
        height: 3.4375rem;
        background-image: linear-gradient(to top, #ffec4f, #ffe868);
        box-shadow: 1px 1px 6px black;
        transform: skew(-10deg);
      }

      @if $isSsm {
        @include background(url('#{$path-events}/shared/milestones/contributor-badge.avif'));
        width: 2.813rem;
        height: 2.688rem;
      }

      &-text {
        font-size: 1.1rem;
      }
    }

    .points {
      width: 10.6875rem;
    }
  }
}
</style>
