import Phaser from 'phaser'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { assetsHandler, sceneHandler, cameraHandler } from '../utils'
import { SCENES_KEYS } from '../constants'
import router from '@/router'
import { isMobile } from '@/plugins/isMobile'

export default class BootScene extends Phaser.Scene {
  constructor() {
    super('BootScene')
  }

  init(): void {
    const emitter = new Phaser.Events.EventEmitter()
    this.setUpHandlerClasses(emitter)
    this.addEmitterToStore(emitter)
  }

  preload(): void {
    this.loadAssets()
  }

  create(): void {
    if (!isMobile()) {
      this.addResizeListener()
    }
  }

  private setUpHandlerClasses(emitter: Phaser.Events.EventEmitter): void {
    sceneHandler.setUp(this.game, emitter)
    assetsHandler.setUp(this.game, emitter)
    cameraHandler.setUp(this.game)
  }

  private addEmitterToStore(emitter: Phaser.Events.EventEmitter): void {
    const gameIntegrationStore = usePhaserGameIntegrationStore()
    gameIntegrationStore.setPhaserEventEmitter(emitter)
  }

  private async loadAssets(): Promise<void> {
    await assetsHandler.runLoaders()
    this.addLoaderCompleteListener()
  }

  private addLoaderCompleteListener(): void {
    // this have to be after assetsHandler.runLoaders()
    // inside it calls (imageLoader, atlasLoader, spriteLoader)
    // we inform Phaser internal loader to start loading assets (scene.load.start())
    // it means we are adding them into queue
    // so Promise assetsHandler.runLoaders() is done but Phaser internally load assets which are in queue
    // this listener - Phaser.Loader.Events.COMPLETE listen to
    // state when all assets are loaded - queue is empty
    this.load.once(Phaser.Loader.Events.COMPLETE, () => {
      const willBecomeActiveScene: string =
        (router.currentRoute.value.meta?.activeScene as string) ?? SCENES_KEYS.MainMapScene
      sceneHandler.startScene(willBecomeActiveScene)
    })
  }

  private addResizeListener(): void {
    // this listener is needed for web - to make map responsive
    window.addEventListener(
      'resize',
      () => {
        const width: number = window.innerWidth
        const height: number = window.innerHeight
        let dpr: number = window.devicePixelRatio
        if (dpr > 2.7) {
          dpr = 2.7
        }
        const widthDPR: number = Math.round(width * dpr)
        const heightDPR: number = Math.round(height * dpr)

        // Sets the size of the parent element (if any)
        this.scale.setParentSize(width, height)

        // Sets the physical size of the canvas
        this.scale.setGameSize(widthDPR, heightDPR)
      },
      true,
    )
  }
}
