<template>
  <header class="milestones-header w-full flex flex-row justify-between items-center relative">
    <div>
      <app-button
        v-if="!isTournamentsPassActive"
        btn-type="credit"
        btn-size="md"
        @click="onTournamentPassClick"
      >
        {{ $t('tournaments.tournamentPass') }}
      </app-button>
      <div v-else class="pass-active-bg">
        <p
          class="pass-active-text gradient-text-purple gradient-text-last-letter-cut-helper text-36 font-bold"
        >
          {{
            $replacePlaceholder(
              $t('tournaments.tournamentPassActive'),
              '{pass}',
              $t('tournaments.tournamentPass'),
            )
          }}
        </p>
      </div>
    </div>
    <div class="season-timer flexing text-texts-standard-default text-32 space-x-2 relative">
      <template v-if="!isLoading">
        <p>
          {{ $t('tournaments.season') }}
          <span class="text-texts-standard-important">
            {{ seasonStats.seasonMonth }} / {{ seasonStats.seasonYear }}
          </span>
        </p>
        <p class="flexing">
          {{ $t('tournaments.milestonesResetIn') }}
          <app-timer
            v-if="seasonStats.remainingTime > 0"
            :time="seasonStats.remainingTime"
            class="ml-4"
          />
        </p>
      </template>
      <component-loading v-else :is-loading="true" height="100%" />
    </div>
    <div class="info-button absolute z-1">
      <tournament-info-button />
    </div>
  </header>
</template>

<script lang="ts">
import { TOURNAMENT_PASS } from '@/globalVariables'
import { isTournamentPass } from '@/helpers'
import TournamentInfoButton from '../TournamentInfoButton.vue'
import { useTournamentsMilestonesStore } from '@/store/pinia/tournaments/useTournamentsMilestonesStore'
import { useMainSeasonStore } from '@/store/pinia/seasons/mainSeasonsStore'
import { usePremiumStore, type OfferInterface } from '@/store/pinia/premiumStore'
import { SeasonType } from '@/interfaces/Seasons'
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'

interface ComponentData {
  isLoading: boolean
}

export default defineComponent({
  components: {
    TournamentInfoButton,
  },
  data(): ComponentData {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapState(useTournamentsMilestonesStore, ['isTournamentsPassActive']),
    ...mapState(useMainSeasonStore, {
      seasonStats: 'getSeasonStats',
    }),
    ...mapState(usePremiumStore, {
      rightboxes: 'getAllRightBoxes',
    }),
  },
  async created(): Promise<void> {
    this.isLoading = true
    await this.loadSeasonStats(SeasonType.Current)
    this.isLoading = false
  },
  methods: {
    ...mapActions(useMainSeasonStore, ['loadSeasonStats']),
    ...mapActions(usePremiumStore, ['showOfferDetail']),
    onTournamentPassClick(): void {
      const offer = this.rightboxes.find(
        (box: OfferInterface): boolean =>
          box.type.includes(TOURNAMENT_PASS) &&
          isTournamentPass({ store_id: box.content.store_id }),
      )

      if (offer) {
        this.showOfferDetail({ offerData: offer.content })
        return
      }

      this.$router.push({ name: this.$getWebView('PremiumOffers') })
    },
  },
})
</script>

<style lang="scss" scoped>
.milestones-header {
  height: 7.625rem;
  padding: 0 1.875rem;

  .season-timer {
    width: 55rem;
    height: 4.25rem;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 75rem;
      height: 4.25rem;
      background-image: linear-gradient(
        to right,
        rgba(132, 30, 17, 0) 0%,
        rgba(132, 30, 17, 0.2) 20%,
        rgba(132, 30, 17, 1) 50%,
        rgba(132, 30, 17, 0.2) 80%,
        rgba(132, 30, 17, 0) 100%
      );
      z-index: 0;
    }

    * {
      z-index: 1;
    }

    :deep(.timer-component) {
      min-width: 14rem;
    }
  }

  .info-button {
    top: 50%;
    right: 1.875rem;
    transform: translateY(-50%);
  }

  .pass-active-bg {
    position: relative;
    z-index: 0;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: -$default-margin;
      width: calc(100% + 2 * $default-margin);
      height: 4.5rem;
      background-image: linear-gradient(
        90deg,
        transparent 0%,
        rgba(140, 0, 178, 0.4) 10%,
        rgba(140, 0, 178, 0.8) 50%,
        rgba(140, 0, 178, 0.4) 90%,
        transparent 100%
      );
      border-style: solid;
      border-width: 0.188rem;
      border-image-slice: 1;
      border-image-source: linear-gradient(
        90deg,
        transparent 0%,
        rgba(207, 7, 249, 0.4) 10%,
        rgba(207, 7, 249, 0.8) 50%,
        rgba(207, 7, 249, 0.4) 90%,
        transparent 100%
      );
      transform: translateY(-50%);
    }

    .pass-active-text {
      position: relative;
      z-index: 1;
    }
  }
}
</style>
