<template>
  <div id="club-activity" class="club-activity-view flex flex-col app-page-wrapper absolute">
    <clubs-managment-menu />
    <section class="club-activity-wrapper flex w-full h-full safe-area">
      <main class="club-activity-main flex flex-row">
        <clubs-panel />
        <div v-if="!isContentLoading" class="club-activity-main-right flex flex-col">
          <table-header />
          <app-scrollbar
            class="club-activity-main-right-scroll"
            scroll="y"
            slide="y"
            height="100%"
            width="100%"
          >
            <clubs-activity-table :rows="getActivity" />
          </app-scrollbar>
        </div>

        <component-loading class="club-activity-loading" :is-loading="isContentLoading" />
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import ClubsActivityTable from '@/components/Club/ClubsActivityTable.vue'
import ClubsManagmentMenu from '@/components/Club/ClubsManagmentMenu.vue'
import ClubsPanel from '@/components/Club/ClubsPanel.vue'
import TableHeader from '@/components/Club/TableHeader.vue'
import { useManagementStore } from '@/store/pinia/clubs/managementStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClubsActivity',
  components: {
    ClubsManagmentMenu,
    ClubsPanel,
    ClubsActivityTable,
    TableHeader,
  },
  computed: {
    ...mapState(useManagementStore, ['getActivity']),
    isContentLoading() {
      return this.getActivity == null
    },
  },
  created() {
    this.loadActivity()
  },
  methods: {
    ...mapActions(useManagementStore, ['loadActivity']),
  },
})
</script>

<style lang="scss" scoped>
.club-activity-wrapper {
  flex: 1 1 auto;
  min-height: 0;

  .club-activity-main {
    width: 98%;
    margin: 0.75rem auto 1.25rem;
    gap: 1.25rem;

    &-right {
      height: 100%;
      flex: 1 1 auto;

      :deep() {
        .y-scroll {
          // Offset for the scrollbar without blending with the table
          padding-right: 1.375rem;
          height: 100% !important;
        }
      }
    }

    .club-activity-loading {
      flex: 1 1 auto;
    }
  }
}
</style>
