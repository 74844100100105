import { defineStore } from 'pinia'
import type { PiggyBankOfferPacksData } from '@/interfaces/premium/Offer'
import { ApiService } from '@/services/ApiService'

import {
  piggyBankConfigEndpoint as PIGGY_BANK_CONFIG_ENDPOINT,
  piggyBankStateEndpoint as PIGGY_BANK_STATE_ENDPOINT,
} from '@/globalVariables'

export interface PiggyBankProgressData {
  state: {
    [key: string]: 'unlocked' | 'previous_required' | 'progressable' | 'purchased'
  }
  progress: {
    current: number
    uptick: number
    total: number
  }
  thresholds: {
    [key: string]: number
  }
  current_milestone: number
  time_remaining: number
  config_table: Record<string, string | number>
}

interface PiggyBankState {
  offersData: PiggyBankOfferPacksData[] | null
  progressData: PiggyBankProgressData | null
}

export const usePiggyBankStore = defineStore('piggyBankStore', {
  state: (): PiggyBankState => ({
    offersData: null,
    progressData: null,
  }),
  actions: {
    async loadOffers(): Promise<void> {
      try {
        const response = await ApiService.get<PiggyBankState['offersData']>(
          PIGGY_BANK_CONFIG_ENDPOINT,
          { force: true },
        )

        this.offersData = response.content
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadProgress(): Promise<void> {
      try {
        this.progressData = await ApiService.get<PiggyBankState['progressData']>(
          PIGGY_BANK_STATE_ENDPOINT,
          { force: true },
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
  getters: {
    getOffers(): PiggyBankOfferPacksData[] | null {
      return this.offersData ?? null
    },
    getProgress(): PiggyBankProgressData | null {
      return this.progressData ?? null
    },
    isActive(): boolean {
      return this.progressData?.time_remaining > 0
    },
  },
})
