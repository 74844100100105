<template>
  <popup-window popup-type="info" :hide-close="true" @close="closePopup">
    <div class="account-connected-popup m-4 flexing flex-col">
      <p class="text-texts-standard-default text-32 mt-4">
        {{ $t('common.accountConnected') }}
      </p>
      <app-button
        btn-type="secondary"
        :btn-text="$t('button.continue')"
        btn-size="xl"
        class="m-auto"
        @click="closePopup"
      />
    </div>
  </popup-window>
</template>

<script lang="ts">
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AccountConnectedPopup',
  components: {
    PopupWindow,
  },
  methods: {
    ...mapActions(useUserStore, ['setShowAccountConnectedPopup']),
    closePopup(): void {
      this.setShowAccountConnectedPopup(false)
    },
  },
})
</script>

<style lang="scss" scoped>
.account-connected-popup {
  min-height: 18rem;
}
</style>
