<template>
  <div class="select-avatar-options flexing">
    <avatar-box
      class="select-avatar-box"
      :avatar-id="optionData.avatar"
      :avatar-gender="optionData.sex"
      :custom-width="5"
      :custom-height="5"
      :online-status="optionData.onlineStatus"
    />
    <app-user
      class="club-member-settings-player-name"
      :country="optionData?.three_letter_code"
      :name="optionData?.name"
    />
  </div>
</template>

<script lang="ts">
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import AvatarBox from '@/components/Profile/AvatarBox.vue'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'

interface OptionData {
  avatar: string
  sex: string
  onlineStatus: string
  three_letter_code?: string
  name?: string
}

export default defineComponent({
  name: 'SelectWithAvatar',
  components: {
    AvatarBox,
    AppUser,
  },
  props: {
    optionData: {
      type: Object as PropType<Nullable<OptionData>>,
      default: () => null,
    },
  },
})
</script>

<style lang="scss" scoped>
.select-avatar-options {
  width: 100%;
  height: 5.938rem;
  border-bottom: 1px solid #438eb8;
  justify-content: flex-start;

  .select-avatar-box {
    margin-right: 2rem;
  }
}
</style>
