import type { EventBadgeInterface } from '@/interfaces/EventBadgeInterface'
import { STAR_OF_THE_DAY_ATHLETE, STAR_OF_THE_DAY_STANDINGS } from '@/globalVariables'
export interface DailyLeaguePlayerData {
  name: string
  country: string
  userId: string
  eventBadges: EventBadgeInterface[]
}

export enum DailyLeagueViewType {
  Athlete = 'athlete',
  Standings = 'standings',
}

export enum DailyLeagueRowViewType {
  Athlete = STAR_OF_THE_DAY_ATHLETE,
  Standings = STAR_OF_THE_DAY_STANDINGS,
}
