<template>
  <section
    class="box-back w-full absolute uppercase z-1"
    :class="{ 'box-back-small': size === 'small' }"
    :data-equip-type="isEventEquip ? 'event' : 'discipline'"
  >
    <p
      v-if="!isEventEquip"
      :class="[
        'box-back-text text-texts-standard-default text-32 text-left',
        { 'box-back-text--event absolute': isEventEquip },
      ]"
    >
      {{ $t('premiumGroup.offerContains') }}
    </p>

    <section v-if="size === 'small'" class="box-back-wrapper-small relative">
      <app-scrollbar
        :id="createRandomId('randomId')"
        width="100%"
        height="31.75rem"
        scroll="y"
        slide="y"
      >
        <div
          v-for="(reward, i) in rewards"
          :key="i"
          class="box-back-wrapper-rewards flex items-center text-texts-standard-default text-32"
        >
          <reward-box
            :reward-icon-size="80"
            :reward-icon-name="getRewardIconNameWithoutBonuxPostfix(reward)"
            :reward-icon-rarity="reward.rarity ? reward.rarity : ''"
            :reward-data="reward || null"
          />
          <p class="rewards-text text-left line-clamp-3">
            <span class="box-back-wrapper-rewards-text text-texts-standard-important font-bold">
              {{ $filters.$formatNumber(reward.value) }}
            </span>
            {{ translateIconNames(reward) }}
          </p>
        </div>
      </app-scrollbar>
    </section>
    <section v-else class="box-back-wrapper">
      <app-scrollbar
        :id="createRandomId('randomId')"
        class="box-back-wrapper-scroll"
        width="100%"
        :height="isEventEquip ? '37rem' : '32.5rem'"
        scroll="y"
        slide="y"
      >
        <section
          v-if="equipmentReward"
          :class="[
            'equipment-wrapper flex items-center justify-between',
            { 'equipment-wrapper--event mb-4': isEventEquip },
          ]"
        >
          <div class="flex flex-col" :class="{ 'h-full flex-grow min-w-0 pr-8': isEventEquip }">
            <div class="flex flex-row" :class="{ 'pt-1': isEventEquip }">
              <p v-if="isEventEquip" class="text-texts-standard-default text-32 text-left mx-3">
                {{ $t('premiumGroup.offerContains') }}
              </p>
            </div>
            <div class="flex justify-start flex-row" :class="{ 'pt-5': isEventEquip }">
              <reward-box
                :reward-icon="72"
                :reward-icon-name="getRewardIconNameWithoutBonuxPostfix(equipmentReward)"
                :reward-data="equipmentReward || null"
                class="flex-none"
              />
              <section class="ml-4 equipment-left flex-grow min-w-0">
                <div class="flex">
                  <p class="text-texts-standard-important text-40 truncate">
                    {{ $t(`equipmentShop.${equipmentReward?.rewardData.name}`) }}
                  </p>
                  <equipment-item-box-rank :is-premium="true" class="ml-4 flex-none" />
                </div>
                <p
                  class="mt-2 text-36 text-texts-standard-additional normal-case text-left truncate"
                >
                  {{ $translateDiscipline(equipmentReward?.rewardData.discipline_id) }}
                </p>
                <div class="equipment-timer-offer__wrapper relative flex items-center mt-4">
                  <app-timer
                    class="equipment-timer-offer normal-case"
                    :italic="false"
                    :time="equipmentReward?.rewardData?.effect_duration"
                    :no-countdown="true"
                  />
                  <tippy
                    theme="WSM1"
                    trigger="click"
                    :placement="isEventEquip ? 'right' : 'bottom'"
                    max-width="60rem"
                    class="info-side"
                  >
                    <section class="flex items-center">
                      <p class="text-32 text-texts-standard-default mr-4 normal-case truncate">
                        {{ $t('premiumGroup.durationSK') }}
                      </p>
                      <app-icon icon-name="info-50" class="flex-none" />
                    </section>
                    <template #content>
                      <div class="relative flex flex-col text-texts-standard-default text-30">
                        <div v-if="isEventEquip">
                          <div class="attribute-bonus-tooltip__header flexing">
                            <p class="text-texts-standard-default text-36 font-bold">
                              {{ $t('equipmentShop.premiumEquipment') }}
                            </p>
                          </div>
                          <div class="px-11 py-6">
                            <ul class="list-disc">
                              <li class="mb-8">
                                <p class="text-left w-full">
                                  {{ $t('equipmentShop.premiumInfo4') }}
                                </p>
                              </li>
                              <li class="mb-8">
                                <p class="text-left w-full">
                                  {{ $t('equipmentShop.premiumInfo5') }}
                                </p>
                              </li>
                              <li class="mb-8">
                                <p class="text-left w-full">
                                  {{ $t('equipmentShop.premiumInfo6') }}
                                </p>
                              </li>
                              <li>
                                <p class="text-left w-full">
                                  {{ $t('equipmentShop.premiumInfo7') }}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div v-else class="p-10">
                          <ul class="list-disc">
                            <li>
                              <p class="text-left w-full">
                                {{ $t('premiumGroup.premiumEquipmentInfo1') }}
                              </p>
                            </li>
                            <li>
                              <p class="text-left w-full">
                                {{ $t('premiumGroup.premiumEquipmentInfo2') }}
                              </p>
                            </li>
                            <li>
                              <p class="text-left w-full">
                                {{ $t('premiumGroup.premiumEquipmentInfo3') }}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </template>
                  </tippy>
                </div>
              </section>
            </div>
            <div v-if="isEventEquip" class="flex items-center justify-start flex-row h-full">
              <app-icon icon-name="info-rounded" class="mx-3" />
              <p class="text-30 text-texts-standard-default normal-case ml-1">
                {{ $t('equipmentShop.moreWinsGuaranteed') }}
              </p>
            </div>
          </div>

          <section class="equipment-right" :class="isEventEquip ? 'mt-0' : 'mt-2'">
            <div v-if="isEventEquip && getBonusData" class="circle-stack mt-6 mb-6">
              <template v-for="(item, index) in getBonusData" :key="index">
                <attribute-circle-tooltip
                  :item="item"
                  :is-last-item="index === getBonusData.length - 1"
                />
              </template>
            </div>
            <equipment-item-attributes
              :attributes-data="getDataForAttribute"
              custom-class="text-28 normal-case"
              :is-offer="true"
              custom-icon-class="offer-box-back__icon--margin-0 equipment-item-attribute-icon"
            />
          </section>
        </section>
        <section class="rewards-wrapper flex items-center">
          <div
            v-for="reward in noEquipmentRewards"
            :key="reward"
            class="box-back-wrapper-rewards flex items-center text-texts-standard-default text-32"
          >
            <reward-box
              :reward-icon-size="80"
              :reward-icon-name="getRewardIconNameWithoutBonuxPostfix(reward)"
              :reward-icon-rarity="(reward as Reward).rarity ? (reward as Reward).rarity : ''"
              :reward-data="reward || null"
              :sex="sex"
            />
            <section v-if="REWARD_AVATAR_TYPES.includes((reward as Reward)?.name)">
              <p class="text-left rewards-text">
                {{
                  (reward as Reward).name === AVATAR_BGS
                    ? $t('club.clubBackground')
                    : (reward as Reward).name === AVATARS
                      ? $t('map.avatar')
                      : $t('premiumGroup.animatedAvatar')
                }}
              </p>
            </section>
            <p
              v-else-if="isRookiePass || isSeasonPass"
              class="rewards-text text-left"
              v-html="getPassText(reward)"
            ></p>
            <p v-else class="text-left rewards-text line-clamp-3">
              <span class="box-back-wrapper-rewards-text text-texts-standard-important font-bold">
                {{ $filters.$formatNumber((reward as Reward).value) }}
              </span>
              {{ translateIconNames(reward) }}
            </p>
          </div>
        </section>
      </app-scrollbar>
    </section>
  </section>
</template>

<script lang="ts">
import EquipmentItemAttributes from '@/components/Equipment/EquipmentItemAttributes.vue'
import EquipmentItemBoxRank from '@/components/Equipment/EquipmentItemBoxRank.vue'
import AttributeCircleTooltip from '@/components/Premium/Offers/AttributeCircleTooltip.vue'
import {
  AVATARS,
  AVATAR_BGS,
  PREMIUM_EQUIPMENT_ITEM,
  REWARD_AVATAR_TYPES,
  ROOKIE_PASS,
  SEASON_PASS,
} from '@/globalVariables'
import { createRandomId, getRewardIconNameWithoutBonuxPostfix } from '@/helpers'
import type { EquipmentOfferContent } from '@/interfaces/premium/Offer'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type Reward from '@/interfaces/Reward'
import type { OfferEquipmentBonus } from '@/interfaces/premium/OfferEquipmentBonus'

interface ComponentData {
  REWARD_AVATAR_TYPES: typeof REWARD_AVATAR_TYPES
  AVATARS: typeof AVATARS
  AVATAR_BGS: typeof AVATAR_BGS
  PREMIUM_EQUIPMENT_ITEM: typeof PREMIUM_EQUIPMENT_ITEM
}

export default defineComponent({
  components: {
    EquipmentItemBoxRank,
    EquipmentItemAttributes,
    AttributeCircleTooltip,
  },
  props: {
    rewards: {
      type: Object as PropType<Nullable<Reward[]>>,
      default: () => null,
    },
    size: {
      type: String,
      default: '',
    },
    offerType: {
      type: String,
      default: '',
    },
    equipmentBonus: {
      type: Object as PropType<Nullable<OfferEquipmentBonus>>,
      default: () => null,
    },
  },
  data(): ComponentData {
    return {
      REWARD_AVATAR_TYPES,
      AVATARS,
      AVATAR_BGS,
      PREMIUM_EQUIPMENT_ITEM,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      sex: 'getSex',
    }),
    isRookiePass(): boolean {
      return this.offerType === ROOKIE_PASS
    },
    isSeasonPass(): boolean {
      return this.offerType === SEASON_PASS
    },
    isEventEquip(): boolean {
      return (this.equipmentReward as Reward)?.rewardData.gdd_id === 6
    },
    getBonusData(): Nullable<EquipmentOfferContent[]> {
      if (this.equipmentBonus && this.equipmentReward) {
        return Object.keys(this.equipmentBonus).map((bonus) => {
          let icon = 'discipline'
          let bonusAttr = 0

          const basicAttr = this.equipmentBonus[bonus]?.attribute_bonus ?? 0
          switch (bonus) {
            case 'attributes':
              icon = 'discipline'
              bonusAttr =
                Number((this.equipmentReward as Reward).rewardData?.item_attribute_bonus) ?? 0
              break
            case 'bonus_tp':
              icon = 'universal_training_points'
              bonusAttr =
                Number((this.equipmentReward as Reward).rewardData?.item_tp_points_bonus) ?? 0

              break
            case 'bonus_cash':
              icon = 'money'
              bonusAttr = Number((this.equipmentReward as Reward).rewardData?.item_money_bonus) ?? 0
          }
          return {
            discipline_id: (this.equipmentReward as Reward).rewardData?.discipline_id,
            item_category: this.equipmentBonus[bonus]?.gdd_id ?? null,
            level: this.equipmentBonus[bonus]?.level ?? 1,
            icon: icon,
            name: this.equipmentBonus[bonus]?.item_name,
            attributes: {
              basic_equipment: {
                value: basicAttr,
              },
              premium_bonus: {
                value: bonusAttr,
              },
              total: {
                value: basicAttr + bonusAttr,
              },
            },
          }
        })
      }
      return null
    },
    getDataForAttribute(): {
      parameters: Record<string, string | number>
      premium: boolean
      disciplineId: number
    } {
      return {
        parameters: this.formatAttributeData,
        premium: true,
        disciplineId: (this.equipmentReward as Reward)?.rewardData?.discipline_id ?? 1,
      }
    },
    equipmentReward(): Reward | void {
      if (!this.rewards) return
      return this.rewards.find(({ name }: Reward): boolean => name === PREMIUM_EQUIPMENT_ITEM)
    },
    formatAttributeData(): Record<string, string | number> {
      const attributeBase = this.equipmentBonus?.attributes?.attribute_bonus ?? 0
      const cashBase = this.equipmentBonus?.bonus_cash?.attribute_bonus ?? 0
      const tpBase = this.equipmentBonus?.bonus_tp?.attribute_bonus ?? 0
      return {
        equipment_attribute_bonus:
          attributeBase +
          Number((this.equipmentReward as Reward)?.rewardData?.item_attribute_bonus),
        equipment_cash_bonus:
          cashBase + Number((this.equipmentReward as Reward)?.rewardData?.item_money_bonus),
        equipment_tp_bonus:
          tpBase + Number((this.equipmentReward as Reward)?.rewardData?.item_tp_points_bonus),
      }
    },
    noEquipmentRewards(): Reward[] | void {
      if (!this.rewards) return
      return this.rewards.filter(
        (reward: Reward): boolean => reward.name !== PREMIUM_EQUIPMENT_ITEM,
      )
    },
  },
  methods: {
    createRandomId,
    getRewardIconNameWithoutBonuxPostfix,

    translateIconNames(reward: Reward): string {
      if (reward.name === 'grand_prize')
        return this.$replaceUntranslatablePlaceholder(
          this.$t(`rewards.${reward.name}_${reward.rarity}`),
          '{grandprize}',
        )
      else if (reward?.eventCurrency) return this.$t(`event.${reward?.name}`)
      else if (reward.name === 'research_points') return this.$t('research.researchPoints')
      else if (reward.name === 'benefit') return this.$t(`rewards.${reward.type}_${reward.level}`)
      else if (reward.name === 'event_points_multiplier')
        return this.$t('event.eventPointsMultiplier')
      else return this.$t('rewards.' + reward.name)
    },
    formattedRewardText(rewardValue: number, rewardText: string): string {
      if (rewardText.includes('{percent}')) return `+${rewardValue.toString()}%`
      if (rewardText.includes('{number}')) return `+${rewardValue}`
      return `${rewardValue}`
    },
    getPassText(reward: Reward): string {
      const rewardText = this.$t(`premiumGroup.${this.offerType}_content_${reward.name}`)
      return this.$replacePlaceholder(
        this.$replacePlaceholder(
          rewardText,
          /{number}|{percent}|%s/gi,
          `<span class="text-texts-standard-important">${this.formattedRewardText(
            reward.value,
            rewardText,
          )}</span>`,
        ),
        '{benefit}',
        this.$t(`rewards.${reward.type}_${reward.level}`),
      )
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';

[data-equip-type='discipline'] {
  --equipment-slot-bg: url('#{$path-images}premium/offers/equipment-slot.avif') center no-repeat;
}

[data-equip-type='event'] {
  --equipment-slot-bg: url('#{$path-images}premium/offers/equipment-slot-event.avif') center
    no-repeat;
}

.box-back {
  height: 38.5rem;
  bottom: 0.125rem;
  width: calc(100% - 0.25rem);
  margin: 0 auto;
  left: 0.125rem;

  @if $isSsm {
    background: #1d233c;
  }

  @if $isWsm {
    background: #071734;
  }

  &-small {
    width: calc(100% - 0.25rem);
    height: 38.5rem;
    bottom: 0.125rem;
    left: 0.125rem;

    .box-back-text {
      margin: 1rem 0 1rem 1.5rem;
    }

    .box-back-wrapper-scroll {
      display: grid;
      grid-template-columns: 33rem 33rem;
      grid-template-rows: 12rem;
      column-gap: 1rem;
    }
  }

  &-text {
    margin: 1rem 0 1rem 2.75rem;
  }

  &-wrapper {
    &-rewards {
      height: 10.0625rem;
      width: 100%;
      display: inline-flex;

      .rewards-text {
        width: 22rem;
        padding-left: 1.3715rem;
      }

      &-text {
        margin-right: 0.5rem;
      }
    }
  }

  &-wrapper-small {
    padding: 0rem 0.9375rem 0;
  }

  .equipment-wrapper {
    background: var(--equipment-slot-bg);
    width: 100%;
    height: 13.813rem;
    background-size: contain;
    padding: 0 2rem;

    .reward-box {
      width: 8.438rem;
      height: 10.375rem;
    }

    &--event {
      height: 20.8rem;
    }
  }

  .rewards-wrapper {
    padding-left: 2rem;
    display: grid;
    grid-template-columns: 36rem 36rem;
  }

  .equipment-left {
    position: relative;
    display: flex;
    flex-direction: column;
    line-height: 2.7rem;

    .info-side {
      position: absolute;
      right: 0;
      width: 12rem;
    }
  }

  .equipment-right {
    width: 24rem;
  }

  .box-back-text {
    &--event {
      margin-left: 2.938rem;
    }
  }

  .circle-stack {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .equipment-timer-offer {
    &__wrapper {
      max-width: 25rem;
    }
  }
}

.attribute-bonus-tooltip {
  &__header {
    position: relative;
    height: 3.125rem;
    background: linear-gradient(to left, rgba(15, 40, 57, 0), #1e6c9b, rgba(15, 40, 57, 0));
    margin-bottom: 0.625rem;

    &:after {
      content: '';
      position: absolute;
      bottom: 0rem;
      left: 0;
      width: 100%;
      height: 0.063rem;
      background: linear-gradient(to right, #a4a4a400, #a4a4a4, #a4a4a4, #a4a4a400);
    }
  }
}
</style>
