import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const clubBoatLeftToRightAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'clubBoatLeftToRightAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '01',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 1,
        end: 2,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubBoatLeftToRightAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '02',
      delay: 0,
      duration: 1300,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 2,
        end: 4,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubBoatLeftToRightAnimations3',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '04',
      delay: 0,
      duration: 2700,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 4,
        end: 6,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubBoatLeftToRightAnimations4',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '06',
      delay: 0,
      duration: 2000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 6,
        end: 9,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubBoatLeftToRightAnimations5',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '09',
      delay: 0,
      duration: 1500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 9,
        end: 11,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubBoatLeftToRightAnimations6',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '11',
      delay: 0,
      duration: 1500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 11,
        end: 12,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubBoatLeftToRightAnimations7',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '12',
      delay: 0,
      duration: 3500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 12,
        end: 16,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubBoatLeftToRightAnimations8',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '16',
      delay: 0,
      duration: 3500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 16,
        end: 19,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'clubBoatLeftToRightAnimations9',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '19',
      delay: 0,
      duration: 3500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 19,
        end: 15,
        zeroPad: 2,
      },
    },
  },
]
