<template>
  <info-popup :popup-title="'not enough gold'">
    <div class="gems-popup uppercase text-texts-standard-default text-40">
      <p class="m-auto gems-popup-text">Some text about article that you dont have Gems</p>
      <section class="gems-popup-section flex items-center m-auto justify-between">
        <premium-gems-small-pack :active="false" :small-packages="smallBoxes" :icon="false" />
      </section>
      <router-link :to="{ name: $getWebView('PremiumGems') }">
        <app-button class="m-auto" :btn-type="'secondary'" :btn-size="'xl'">
          more offers
        </app-button>
      </router-link>
    </div>
  </info-popup>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import PremiumGemsSmallPack from '@/components/Premium/GemsPack/PremiumGemsSmallPack.vue'
import { defineComponent } from 'vue'

interface SmallBox {
  bestValue: boolean
}
interface ComponentData {
  smallBoxes: SmallBox[]
}

export default defineComponent({
  components: {
    InfoPopup,
    PremiumGemsSmallPack,
  },
  data(): ComponentData {
    return {
      smallBoxes: [{ bestValue: false }, { bestValue: true }],
    }
  },
})
</script>

<style lang="scss" scoped>
.gems-popup {
  width: 80rem;
  height: 36.0625rem;

  &-text {
    margin-top: 2.8125rem;
  }

  &-section {
    width: 73.75rem;
    height: 19.1875rem;
    margin: 2.625rem auto 2.625rem auto;
  }
}
</style>
