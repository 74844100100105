<template>
  <div
    class="daily-task-sub-box daily-task-sub-box__table table-standard"
    :class="{
      'is-mobile': $isMobile(),
    }"
  >
    <section
      v-for="(box, index) in subBoxes"
      :key="index"
      class="subtask-box m-auto relative table-standard-row flex items-center"
    >
      <div v-if="!box.lock" class="subtask-box-unlock flex items-center relative w-full h-full">
        <div
          class="subtask-box-unlock-section relative"
          :class="{ purple: box.exchangePrice && isAssigned(box) }"
        >
          <div
            v-if="box.exchangePrice && isAssigned(box)"
            class="subtask-box-unlock-section-repeat absolute cursor-pointer"
            @click="$emit('popup', box)"
          >
            <div class="subtask-box-unlock-section-repeat-img m-auto" />
          </div>
        </div>
        <div
          class="subtask-box-unlock-texts h-full text-texts-standard-default text-36 text-left flex flex-col justify-center"
        >
          <p class="text-unlock">
            {{ box.text }}
          </p>
          <app-progress-bar
            class="subtask-progress"
            :goal="box.goal"
            :actual="box.current"
            :show-bottom-status="false"
            :counts="true"
          />
        </div>
        <div class="subtask-box-unlock-rewards flex items-center justify-end">
          <reward-box
            v-for="reward in box.rewards"
            :key="reward.name"
            :reward-number="reward.amount"
            :reward-icon-name="reward.name"
            :reward-icon="72"
            :reward-icon-rarity="reward.rarity ? reward.rarity : ''"
          />
        </div>
        <app-button
          v-if="box.state !== CLAIMED"
          :btn-type="box.current >= box.goal ? 'secondary' : 'primary'"
          class="btn-claim absolute"
          :disabled="isDisabled(box.id)"
          :loading="isDisabled(box.id)"
          :btn-text="box.current >= box.goal ? $t('common.claim') : $t('button.start')"
          @click="box.current >= box.goal ? claimTask(box.id) : startTask(box.link)"
        />
        <section v-else class="claimed-reward w-full h-full flex items-center justify-end absolute">
          <div
            class="claimed-reward-text text-texts-standard-upgrade font-bold text-32 flexing uppercase"
          >
            <p>{{ $t('common.rewardsClaimed') }}</p>
            <app-icon :icon-name="'done-sm'" />
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { CLAIMED, ASSIGNED, redirectViews } from '@/globalVariables'

import type { DailyTaskBox } from '@/interfaces/DailyTask'
import type { PropType } from 'vue'

interface ComponentData {
  CLAIMED: typeof CLAIMED
}

export default defineComponent({
  props: {
    subBoxes: {
      type: Array as PropType<DailyTaskBox[]>,
      default: () => [],
    },
    isButtonDisabled: {
      type: Object as PropType<Record<string, boolean>>,
      default: () => ({}),
    },
  },
  emits: ['popup', 'claim'],
  data(): ComponentData {
    return {
      CLAIMED,
    }
  },
  methods: {
    claimTask(id: string): void {
      if (this.isDisabled(id)) return
      this.$emit('claim', id)
    },
    startTask(taskLink: string): void {
      const route = redirectViews[taskLink.replace('-', '_')]
      if (route) {
        this.$router.push({ name: this.$getWebView(route) })
      }
    },
    isDisabled(id: string): boolean {
      return id in this.isButtonDisabled
    },
    isAssigned(box: DailyTaskBox): boolean {
      return box.state === ASSIGNED
    },
  },
})
</script>

<style lang="scss" scoped>
.daily-task-sub-box {
  &__table {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 99%;
    margin: 0 auto;

    &.is-mobile::after {
      content: '';
      // Important: Minimum relative height for fixing cut off flex margin from last inner children
      position: relative;
      height: 2.25rem;
      width: 100%;
      top: 0;
      left: 0;
      background: transparent;
    }
  }
}
.subtask-box {
  width: 100%;
  height: 10rem;
  margin-bottom: 0.75rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  &-unlock {
    &-section {
      background: url($path-images + 'dailyTask/STRIPES_BLUE.avif') center no-repeat;
      width: 2.1rem;
      height: 100%;
      background-size: contain;

      &-repeat {
        top: 50%;
        left: 0.5rem;
        height: 3.75rem;
        width: 3.75rem;
        background: linear-gradient(to bottom, #8727c4, #9f48ac, #9b5cd5);
        transform: translate(-50%, -50%) $skewX-value;
        border: 2px solid #9b5cd5;

        &-img {
          background: url($path-images + 'dailyTask/BTN_REFRESH.avif') center no-repeat;
          width: 3rem;
          height: 3rem;
          background-size: cover;
          transform: $skewX-revert-value;
          margin-top: 0.2rem;
        }
      }
    }

    .purple {
      background: url($path-images + 'dailyTask/STRIPES_PURPLE.avif') center no-repeat;
      background-size: contain;
    }

    &-texts {
      margin-left: 2.75rem;
      width: 48rem;

      .subtask-progress {
        margin-left: 6.2rem;
        margin-top: 0.5rem;
      }
    }

    &-rewards {
      width: 26.3625rem;
    }

    .btn-claim {
      right: 3.625rem;
    }

    .claimed-reward {
      @if $isWsm {
        background: rgba(0, 0, 0, 0.5);
      }
      @if $isSsm {
        background: rgba(#525479, 0.5);
      }

      &-text {
        margin-right: 5.6875rem;
      }
    }
  }

  .lock-tasks {
    background: rgba(0, 0, 0, 0.5);

    &-text {
      right: 4.875rem;
    }
  }
}
</style>
