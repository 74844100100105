<template>
  <div class="mail-table-head table-head-standard flex items-center relative">
    <p class="head-checkbox" />
    <p class="head-subject">
      {{ $t('post.subject') }}
    </p>
    <p class="head-user">
      {{ $t(sent ? 'post.recipient' : 'post.sender') }}
    </p>
    <p class="head-date">
      {{ $t('post.date') }}
    </p>
    <p class="head-icon" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MailTableHeader',
  props: {
    sent: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.head {
  &-checkbox {
    width: 4rem;
  }

  &-subject {
    width: 30rem;
    text-align: left;
    padding-left: 3.3rem;
  }

  &-user {
    width: 30rem;
    text-align: left;
    padding-left: 6.9rem;
  }

  &-date {
    width: 30rem;
  }

  &-icon {
    width: 18.875rem;
  }
}
</style>
