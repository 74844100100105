<template>
  <div class="super-multiplier-wrapper">
    <div v-if="supermatchConfig" class="super-multiplier">
      <tippy
        v-for="(state, idx) in supermatchConfig"
        :key="state.name"
        theme="WSM1"
        placement="top"
        max-width="50rem"
      >
        <button
          :id="`super-multiplier-btn-${idx}`"
          class="super-multiplier-btn flexing text-36 font-bold"
          :class="getItemClass(state)"
          @click="handleClick(state)"
        >
          <app-icon v-if="isUnopened(state)" class="lock" icon-name="lock-sm" />
          <span>{{ `${state.value}x` }}</span>
        </button>
        <template v-if="isUnopened(state)" #content>
          <super-multiplier-tooltip :index="idx" :state="state" :multiplier-type="multiplierType" />
        </template>
      </tippy>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/store/pinia/userStore'
import { Multiplier } from '@/globalVariables'
import { playSound } from '@/helpers'

import SuperMultiplierTooltip from './SuperMultiplierTooltip.vue'

import type { PropType } from 'vue'
import type { MultiplicatorItem } from '@/types/userProfile'

interface ComponentData {
  supermatchConfig: MultiplicatorItem[]
  currentMultiplier: MultiplicatorItem
}

export default defineComponent({
  name: 'SuperMultiplier',
  components: { SuperMultiplierTooltip },
  props: {
    multiplierType: {
      type: String as PropType<Multiplier>,
      default: null,
      validator: (value: string): boolean => {
        return Object.values(Multiplier).includes(value as Multiplier)
      },
    },
  },
  emits: ['switchMatchType', 'switchedEventMultiplier'],
  data(): ComponentData {
    return {
      supermatchConfig: null,
      currentMultiplier: null,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      getSuperMultipliers: 'getSuperMultipliers',
    }),
  },
  async mounted(): Promise<void> {
    await this.loadUserData()

    const buildingMultipliers: MultiplicatorItem[] = this.getSuperMultipliers[this.multiplierType]
    this.supermatchConfig = buildingMultipliers.filter(
      (item: MultiplicatorItem): boolean => item.name !== 'multiplicator_season_pass',
    )
  },
  methods: {
    ...mapActions(useUserStore, ['setProfileAttributes', 'loadUserData', 'setMultiplicatorState']),
    isUnopened(state: MultiplicatorItem): boolean {
      return state.opened === false
    },
    getItemClass(state: MultiplicatorItem): string {
      if (this.isUnopened(state)) {
        return 'super-multiplier-btn--disabled text-texts-standard-default'
      } else if (state.active) {
        return 'super-multiplier-btn--active text-texts-standard-dark clickable-element cursor-pointer'
      } else {
        return 'super-multiplier-btn--available text-texts-standard-silver clickable-element cursor-pointer'
      }
    },
    async handleClick(state: MultiplicatorItem): Promise<void> {
      if (this.isUnopened(state) || state === this.currentMultiplier) return

      this.supermatchConfig = this.supermatchConfig.map(
        (item: MultiplicatorItem): MultiplicatorItem => ({
          ...item,
          active: item === state,
        }),
      )

      this.currentMultiplier = this.supermatchConfig.find(
        (item: MultiplicatorItem): boolean => item.active,
      )

      // only after clicking on an available item
      playSound('super_competition')

      this.$emit('switchMatchType') // arena or club championship
      this.setMultiplicatorState({ building: this.multiplierType, name: state.name })

      await this.setProfileAttributes({
        name: state.name,
        value: 1,
      })
      this.$emit('switchedEventMultiplier') // event
    },
  },
})
</script>

<style lang="scss" scoped>
.super-multiplier {
  display: flex;
  flex-direction: row;

  @if $isWsm {
    transform: skewX(-8deg);
  }

  &-btn {
    position: relative;
    height: 4.375rem;

    @if $isSsm {
      width: 5.875rem;
      border: solid 0.188rem;
      border-color: #51799e;
      background-image: linear-gradient(to right, #2d425b, #4c6e96);

      &--active {
        border-color: #ffdc96;
        background-image: linear-gradient(to right, #ffaa3c, #ffe719);
        text-shadow: none;
      }

      &--disabled {
        border-image-source: linear-gradient(to right, #939393, #bcbcbc);
        border-image-slice: 1;
        background-image: linear-gradient(to right, #696969, #9b9b9b);
      }
    }

    &--disabled {
      span {
        opacity: 0.5;
      }
    }

    @if $isWsm {
      width: 6.375rem;
      border: solid 0;
      box-shadow:
        inset 0.188rem 0 0 0 rgba(255, 255, 255, 0.13),
        inset -0.188rem 0 0 0 rgba(255, 255, 255, 0.11),
        inset 0 -0.125rem 0.063rem 0 rgba(255, 255, 255, 0.11),
        inset 0 0.25rem 0 0 rgba(255, 255, 255, 0.3);
      background-image: linear-gradient(to top, #4386b6, #305e7f);

      &--active {
        box-shadow:
          inset 0.188rem 0 0 0 rgba(255, 255, 255, 0.4),
          inset -0.188rem 0 0 0 rgba(255, 255, 255, 0.4),
          inset 0 -0.125rem 0.063rem 0 rgba(255, 255, 255, 0.4),
          inset 0 0.25rem 0 0 rgba(255, 255, 255, 0.64);
        background-image: linear-gradient(to top, #ffef84, #f6c717);
        text-shadow: none;
      }

      &--disabled {
        box-shadow:
          inset 0.188rem 0 0 0 rgba(255, 255, 255, 0.13),
          inset -0.188rem 0 0 0 rgba(255, 255, 255, 0.11),
          inset 0 -0.125rem 0.063rem 0 rgba(255, 255, 255, 0.11),
          inset 0 0.25rem 0 0 rgba(255, 255, 255, 0.3);
        background-image: linear-gradient(to top, #838383, #474747);
      }

      & > * {
        transform: skewX(8deg);
      }
    }

    .lock {
      position: absolute;
      top: -1.5rem;
      right: -0.625rem;
      z-index: 7; // tutorial arrow
    }
  }
}
</style>
