import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const carsBottomTunnelToLeftPath: PathObjectInterface = {
  mainPath: { x: 2709, y: 1247 },
  spline: [
    2709, 1247, 2615, 1266, 2395, 1300, 2227, 1309, 2035, 1340, 1823, 1381, 1521, 1440, 1401, 1405,
    1396, 1340, 1492, 1271, 1639, 1163, 1744, 1112, 1840, 1072, 1907, 1004, 1915, 968, 1912, 925,
    1953, 894, 2011, 863, 2071, 858, 2131, 880, 2219, 884, 2335, 870, 2435, 827, 2553, 774, 2815,
    726, 2896, 721, 3052, 750, 3254, 786, 3376, 755, 3417, 721, 3443, 688, 3455, 623,
  ],
}
