<template>
  <div v-if="isPlayerLoading" class="player-overlay" />
  <div class="player-wrapper">
    <div id="player" class="video-player" />
  </div>
</template>

<script lang="ts">
import { isAllowedCookieCategory } from '@/helpers'
import { defineComponent } from 'vue'

interface ComponentData {
  player: YT.Player
  isPlayerLoading: boolean
}

export default defineComponent({
  name: 'VideoBackground',
  props: {
    videoId: {
      type: String,
      default: '',
    },
  },
  data(): ComponentData {
    return {
      player: null,
      isPlayerLoading: true,
    }
  },
  mounted(): void {
    const videoId = this.videoId
    const tag = document.createElement('script')

    tag.src = 'https://www.youtube.com/iframe_api'
    const firstScriptTag = document.getElementsByTagName('script')[0] as HTMLScriptElement
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

    window.onYouTubeIframeAPIReady = (): void => {
      // eslint-disable-next-line no-undef
      this.player = new YT.Player('player', {
        width: '100%',
        height: '100%',
        videoId: videoId,
        host: isAllowedCookieCategory('targeting')
          ? 'https://www.youtube.com'
          : 'https://www.youtube-nocookie.com',
        playerVars: {
          controls: 0,
          disablekb: 1,
          modestbranding: 1,
          enablejsapi: 1,
          rel: 0,
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      })
    }

    const onPlayerReady = (): void => {
      this.player.playVideo()
      this.player.mute()
    }

    const onPlayerStateChange = (state: YT.OnStateChangeEvent): void => {
      // eslint-disable-next-line no-undef
      if (state.data === YT.PlayerState.ENDED) {
        this.player.playVideo()
      }

      // eslint-disable-next-line no-undef
      if (state.data === YT.PlayerState.PLAYING) {
        this.isPlayerLoading = false
      }
    }
  },
})
</script>

<style lang="scss" scoped>
.player-overlay {
  background: url($path-homepage + 'BG_FULLSCREEN_MAP_2K.avif') center no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
}

.player-wrapper {
  width: 100%;
  height: calc((100vw * 9) / 16);
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  pointer-events: none;

  @media (max-aspect-ratio: 16/9) {
    width: calc((100vh * 16) / 9);
    height: 100vh;
  }

  .video-player {
    height: 100vh;
    overflow: hidden;

    @media (max-aspect-ratio: 16/9) {
      width: 100%;
      overflow: hidden;
    }
  }
}
</style>
