<template>
  <footer
    class="tasks-footer w-full flex items-center uppercase justify-between mx-auto px-10 relative bottom-2.5"
  >
    <p class="gradient-text-light text-50 font-bold">
      {{ $t('common.rewards') }}
    </p>
    <section class="rewards flexing">
      <rewards :reward-data="formatRewards(rewards, 'type', 'value')" :icon-size="72" />
    </section>
    <app-button
      btn-type="confirm"
      :btn-text="$t('common.takeRewards')"
      btn-size="md"
      :disabled="isDisabled || isClaiming"
      :loading="isClaiming"
      @click="claimRewards"
    />
  </footer>
</template>

<script lang="ts">
import Rewards from '@/components/Rewards.vue'
import { formatRewards } from '@/helpers/formatRewards'
import type Reward from '@/interfaces/Reward'
import { useTaskChainStore } from '@/store/pinia/events/taskChainStore'
import { mapActions } from 'pinia'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

interface ComponentData {
  isClaiming: boolean
}

export default defineComponent({
  name: 'TasksFooter',
  components: {
    Rewards,
  },
  props: {
    rewards: {
      type: Array as PropType<Reward[]>,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      isClaiming: false,
    }
  },
  methods: {
    ...mapActions(useTaskChainStore, {
      _claimRewards: 'claimRewards',
    }),
    formatRewards,
    async claimRewards(): Promise<void> {
      if (this.isClaiming) return
      this.isClaiming = true
      await this._claimRewards()
      this.isClaiming = false
    },
  },
})
</script>

<style lang="scss" scoped>
.tasks-footer {
  height: 7.375rem;
  @include background(url('#{$path-events}shared/taskchain/tasks/tasks-footer-bg.avif'), contain);
}
</style>
