import { ITEM_OPPONENTS_GENERATION, shopEndPoint } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import type {
  AvailableOffer,
  Item,
  ShopConsumablesApiResponse,
} from '@/interfaces/responses/shopConsumables/ShopConsumablesApiResponse'
import { defineStore } from 'pinia'

interface OponentsStoreState {
  refreshPrice: number
}

export const useOponentsStore = defineStore('oponents', {
  state: (): OponentsStoreState => ({
    refreshPrice: 0,
  }),
  getters: {
    getRefreshPrice(): number {
      return this.refreshPrice
    },
  },
  actions: {
    async loadOpponentsRefreshPrice(): Promise<void> {
      if (this.refreshPrice !== 0) return
      const offers = await internalAxios.get<{}, ShopConsumablesApiResponse>(
        shopEndPoint + '/consumables_shop',
      )
      const item = offers.availableOffers.find(
        (offer: AvailableOffer): Item =>
          offer.items.find((item: Item): boolean => item.name === ITEM_OPPONENTS_GENERATION),
      )

      this.refreshPrice = item ? Number(item.parameters.consumable_price_gems) : 0
    },
  },
})
