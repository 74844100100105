<template>
  <footer
    class="flexing w-full absolute left-0"
    :class="[$route.meta.isPremiumRoute ? 'bottom-6' : 'bottom-8']"
  >
    <buy-pass-button
      :item-id="itemId"
      :price="price"
      :currency="currency"
      :purchased="isPassActivated"
    />
  </footer>
</template>

<script lang="ts">
import type PremiumEventPassApiResponse from '@/interfaces/responses/premium/PremiumEventPassApiResponse'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import BuyPassButton from '../BuyPassButton.vue'

export default defineComponent({
  name: 'EventPass1Footer',
  components: { BuyPassButton },
  computed: {
    ...mapState(usePremiumStore, {
      eventPass: 'getEventPass',
    }),
    isPassActivated(): boolean {
      return this.eventPass?.[0]?.remaining_buys === 0
    },
    itemId(): PremiumEventPassApiResponse['packs'][0]['store_id'] {
      return this.eventPass?.[0]?.store_id
    },
    price(): PremiumEventPassApiResponse['packs'][0]['price']['price'] {
      return this.eventPass?.[0]?.price?.price
    },
    currency(): PremiumEventPassApiResponse['packs'][0]['price']['currency'] {
      return this.eventPass?.[0]?.price?.currency
    },
  },
})
</script>
