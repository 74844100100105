import { defineStore } from 'pinia'
import { internalAxios } from '@/plugins/vueAxios'
import {
  shootingRangeConfigEndpoint as SHOOTING_RANGE_CONFIG_ENDPOINT,
  shootingRangeStateEndpoint as SHOOTING_RANGE_STATE_ENDPOINT,
  shootingRangeBuyAmmoEndpoint as SHOOTING_RANGE_BUY_AMMO_ENDPOINT,
  shootingRangeShootEndpoint as SHOOTING_RANGE_SHOOT_ENDPOINT,
  shootingRangeClaimEndpoint as SHOOTING_RANGE_CLAIM_ENDPOINT,
} from '@/globalVariables'

import type ShootingRangeConfigApiResponse from '@/interfaces/responses/events/shooting-range/ShootingRangeConfigApiResponse'
import type ShootingRangeStateApiResponse from '@/interfaces/responses/events/shooting-range/ShootingRangeStateApiResponse'

interface ShootingRangeState {
  config: ShootingRangeConfigApiResponse | null
  state: ShootingRangeStateApiResponse | null
}

export const useShootingRangeStore = defineStore('shootingRangeStore', {
  state: (): ShootingRangeState => ({
    config: null,
    state: null,
  }),
  getters: {
    getCompletedTargets(): ShootingRangeStateApiResponse['completed_targets'] {
      return this.state?.completed_targets ?? 0
    },
    getTotalTargets(): ShootingRangeStateApiResponse['total_targets'] {
      return this.state?.total_targets ?? 0
    },
    getChest(): ShootingRangeStateApiResponse['chest'] | null {
      return this.state?.chest ?? null
    },
    getRange(): ShootingRangeStateApiResponse['range'] | null {
      return this.state?.range ?? null
    },
    getTargetRewards(): ShootingRangeStateApiResponse['target_rewards'] {
      return this.state?.target_rewards ?? null
    },
    getGunpowder(): ShootingRangeStateApiResponse['gunpowder'] {
      return this.state?.gunpowder ?? 0
    },
    getPurchasableGunpowder(): ShootingRangeStateApiResponse['purchasable_gunpowder'] {
      return this.state?.purchasable_gunpowder ?? 0
    },
    getPotentialGunpowder(): ShootingRangeStateApiResponse['potential_gunpowder'] {
      return this.state?.potential_gunpowder ?? 0
    },
    getRemainingSeconds(): ShootingRangeStateApiResponse['seconds_left'] {
      return this.state?.seconds_left ?? 0
    },
    getDestroyTargetCost(): ShootingRangeConfigApiResponse['destroy_target_cost'] {
      return this.config?.destroy_target_cost ?? 0
    },
    getValuePerConsumable(): ShootingRangeConfigApiResponse['gold_ammo_buys_base_ammo'] {
      return this.config?.gold_ammo_buys_base_ammo ?? 0
    },
    getGemValuePerConsumable(): ShootingRangeConfigApiResponse['golden_ammo_price'] {
      return this.config?.golden_ammo_price ?? 0
    },
  },
  actions: {
    async loadConfig(force: boolean = false): Promise<void> {
      if (this.config && !force) return

      try {
        const data = await internalAxios.get<{}, ShootingRangeConfigApiResponse>(
          SHOOTING_RANGE_CONFIG_ENDPOINT,
        )
        if (!data) return
        this.config = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadState(): Promise<void> {
      try {
        const data = await internalAxios.get<{}, ShootingRangeStateApiResponse>(
          SHOOTING_RANGE_STATE_ENDPOINT,
        )
        if (!data) return
        this.state = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async buyAmmo(amount: number): Promise<void> {
      try {
        const data = await internalAxios.post<{}, any>(SHOOTING_RANGE_BUY_AMMO_ENDPOINT, {
          amount,
        })
        if (!data) return
        this.state = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async shoot(): Promise<void> {
      try {
        const data = await internalAxios.post<{}, any>(SHOOTING_RANGE_SHOOT_ENDPOINT)
        if (!data) return
        this.state = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async claim(type: string, position: number): Promise<void> {
      try {
        const data = await internalAxios.post<{}, any>(SHOOTING_RANGE_CLAIM_ENDPOINT, {
          type, // row_reward, chest
          position, // len pre riadok 1/4, pre chest 1
        })
        if (!data) return
        this.state = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
