<template>
  <div
    v-tippy="{
      theme: 'WSM',
      content: notEnoughTokens && !isTargetShot ? $te('notEnoughTokens') : null,
      placement: 'top',
      textAlign: 'center',
    }"
    class="target-item flexing"
    :class="{
      'target-item--done': targetData.isShot,
      'target-item--best': targetData.isShot && targetData.isBest,
    }"
    @click="$debounce(handleClick)"
  >
    <div v-if="targetData.isShot" class="target-item__content">
      <app-main-icon :icon-size="56" :icon-name="targetData.targetIcon" class="inline-block" />
      <div class="target-item__content-value flexing text-texts-standard-default text-30 font-bold">
        {{ $filters.$formatNumber(targetData.targetValue) }}
        <app-icon v-if="targetData.isShot" class="absolute box-icon--done" icon-name="done-sm" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useFortuneBasketStore } from '@/store/pinia/events/fortuneBasketStore'
import { mapActions, mapState } from 'pinia'

import type { PropType } from 'vue'
import type { SpecialItem } from '@/interfaces/responses/events/fortune-basket/FortuneBasketStateApiResponse'

interface TargetItem {
  position: number
  targetIcon: string
  targetValue: number
  isShot: boolean
  isBest: boolean
}

export default defineComponent({
  name: 'TargetItem',
  props: {
    targetData: {
      type: Object as PropType<TargetItem>,
      default: null,
    },
  },
  computed: {
    ...mapState(useFortuneBasketStore, {
      specialItems: 'getSpecialItems',
    }),
    getTokens(): SpecialItem {
      return this.specialItems?.find(
        (item: SpecialItem): boolean => item?.type === 'fortune_basket_token',
      )
    },
    notEnoughTokens(): boolean {
      return this.getTokens?.value === 0
    },
    isTargetShot(): boolean {
      return this.targetData.isShot
    },
  },
  methods: {
    ...mapActions(useFortuneBasketStore, ['claim']),
    handleClick(): void {
      if (this.notEnoughTokens) return
      if (this.targetData.isShot) return
      this.claim({ position: this.targetData.position })
    },
  },
})
</script>

<style lang="scss" scoped>
.target-item {
  position: relative;
  width: 9.688rem;
  height: 9.438rem;
  background: url($path-events + 'autumn-fair/fortune-basket/pumpkin--full.avif') no-repeat center;
  background-size: contain;

  &:hover {
    &::before {
      content: '';
      position: absolute;
      width: 9.688rem;
      height: 9.438rem;
      background: url($path-events + 'autumn-fair/fortune-basket/pumpkin--overlay.avif') no-repeat
        center;
      background-size: contain;
    }
  }

  &--done {
    background: url($path-events + 'autumn-fair/fortune-basket/pumpkin--opened.avif') no-repeat
      center;
    background-size: contain;
  }

  &--best {
    background: url($path-events + 'autumn-fair/fortune-basket/pumpkin--best.avif') no-repeat center;
    background-size: contain;
  }

  &__content {
    flex: 1 1 auto;
    z-index: 2;

    &-value {
      position: relative;
      margin: 0 auto;
      width: 7.25rem;
      height: 2.25rem;
      margin-bottom: -4rem;
      background-image: linear-gradient(
        to right,
        rgba(14, 116, 105, 0),
        rgba(14, 116, 105, 1),
        rgba(14, 116, 105, 1),
        rgba(14, 116, 105, 0)
      );

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 0.125rem;
        background-image: linear-gradient(
          to right,
          rgba(14, 231, 204, 0.01),
          rgba(14, 231, 204, 1),
          rgba(14, 231, 204, 0.01)
        );
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }

      .box-icon--done {
        position: absolute;
        right: -1rem;
        bottom: -0.5rem;
      }
    }
  }
}
</style>
