<template>
  <div class="app-textarea relative">
    <textarea
      v-model="model"
      name="message-input"
      :placeholder="placeholder"
      :maxlength="maxLength"
      :class="'app-textarea-content ' + theme"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

interface ComponentData {
  hasInputContent: boolean
}

export default defineComponent({
  name: 'AppTextarea',
  props: {
    message: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },

    theme: {
      type: String,
      default: 'theme-1',
      validator(value: string): boolean {
        return ['theme-1', 'theme-2'].includes(value)
      },
    },
    maxLength: {
      type: Number,
      default: 100000,
    },
  },
  emits: ['messageText'],
  data(): ComponentData {
    return {
      hasInputContent: false,
    }
  },
  computed: {
    model: {
      get(): string {
        return this.message
      },
      set(value: string): void {
        this.$emit('messageText', value)
      },
    },
  },
})
</script>

<style lang="scss">
.app-textarea {
  width: 100%;
  height: 100%;

  &-content {
    width: 100%;
    height: 100%;
    padding: 1.875rem;
    color: theme('colors.texts.standard.default');
    font-size: theme('fontSize.36');
    resize: none;
    outline: none;
    @if $isWsm {
      font-style: italic;
    }

    &.theme-1 {
      background: #0a1b2f;
      border: 0.063rem solid #5ba3dc;
      @if $isWsm {
        background: #0a1b2f;
        border: 0.063rem solid #5ba3dc;
      }
      @if $isSsm {
        background: #1d1f2c;
        border: 0.063rem solid #456786;
      }

      &::placeholder {
        color: theme('colors.texts.standard.notImportant');
        opacity: 50%;
        font-style: italic;
      }
    }

    &.theme-2 {
      background: #194662;
      border: 0.063rem solid #5ba3dc;
      @if $isWsm {
        background: #194662;
        border: 0.063rem solid #5ba3dc;
      }
      @if $isSsm {
        border: solid 0.125rem #4c648f;
        background-color: #1d1f2c;
      }

      &::placeholder {
        color: theme('colors.texts.standard.additional');
        opacity: 50%;
      }
    }

    &:focus::placeholder {
      color: transparent;
      text-shadow: none;
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 1.563rem;
    height: 1.563rem;
    background: transparent;
    z-index: 1;
  }

  &::before {
    @if $isWsm {
      left: 0;
      bottom: 0;
      border-bottom: 0.094rem solid #b5deff;
      border-left: 0.094rem solid #b5deff;
    }
  }

  &::after {
    @if $isWsm {
      right: 0;
      top: 0;
      border-top: 0.094rem solid #b5deff;
      border-right: 0.094rem solid #b5deff;
    }
  }
}
</style>
