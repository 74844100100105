import { GameType } from '@/globalVariables'
import type { App, Plugin } from 'vue'

export const isWsm = import.meta.env.VITE_GAME === GameType.Winter

export const isSsm = import.meta.env.VITE_GAME === GameType.Summer

export const gamePlugin: Plugin = {
  install: (app: App): void => {
    app.config.globalProperties.$isWsm = isWsm
    app.config.globalProperties.$isSsm = isSsm
  },
}
