<template>
  <info-popup
    v-if="textData"
    :popup-title="$te('playoffRules')"
    width="76.125rem"
    @close="$emit('close')"
  >
    <section class="playoff-info justify-center">
      <app-scrollbar width="100%" height="100%" scroll="y" slice="y">
        <article v-for="rule in rulesTexts" :key="rule.title" class="justify-center">
          <header class="font-bold text-50 uppercase relative flexing">
            <p class="gradient-text-gold">
              {{ rule.title }}
            </p>
          </header>
          <div
            v-for="text in rule.texts"
            :key="text"
            class="text text-34 relative text-texts-standard-default text-left"
          >
            {{ text }}
          </div>
        </article>
      </app-scrollbar>
    </section>
  </info-popup>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import type { PlayoffTextExchange } from '@/interfaces/events/MastersPlayoffInterfaces'
import { useMastersEventStore } from '@/store/pinia/events/mastersStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  textData: Nullable<PlayoffTextExchange>
}

export default defineComponent({
  name: 'PlayoffMastersRulesPopup',
  components: {
    InfoPopup,
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      textData: null,
    }
  },
  computed: {
    rulesTexts(): { title: string; texts: string[] }[] {
      return [
        {
          title: this.$te('qualification'),
          texts: [
            this.$te('qualificationRules1'),
            this.$replacePlaceholder(
              this.$te('qualificationRules2'),
              '%s',
              this.textData?.playoff_advancing ? this.textData.playoff_advancing.toString() : '',
            ),
            this.$te('playoffRulesFive'),
            this.$te('playoffTwo'),
          ],
        },
        {
          title: this.$te('playoff'),
          texts: [
            this.$replacePlaceholder(
              this.$te('mastersRules3'),
              '{number}',
              ((this.textData?.playoff_round_length ?? 0) * 24).toString() || '',
            ),
            this.$te('playoffTwo'),
          ],
        },
        {
          title: this.$te('frozenMasters'),
          texts: [
            this.$replacePlaceholder(
              this.$te('mastersRules1'),
              '{masters}',
              this.textData.masters_playoff_name || '',
            ),
            this.$te('mastersRules2'),
            this.$te('playoffTwo'),
          ],
        },
      ]
    },
  },
  async created(): Promise<void> {
    await this.getTextData()
  },
  methods: {
    ...mapActions(useMastersEventStore, ['loadTextsChanger']),
    async getTextData(): Promise<void> {
      try {
        this.textData = await this.loadTextsChanger()
      } catch (err) {
        console.error(err)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.playoff-info {
  height: 41.5rem;
  flex-direction: column;
  padding-top: 2.125rem;

  header {
    height: 5.25rem;
    margin: 0.625rem 0;

    @if $isWsm {
      background-image: linear-gradient(to right, transparent 17%, #0b1c2f, transparent 83%);

      &::before,
      &::after {
        content: '';
        background-image: linear-gradient(to right, transparent, #31506b, transparent);
        position: absolute;
        width: 80%;
        left: 50;
        transform: translate(0, -50%);
        height: 0.125rem;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }
  }

  .text {
    padding-left: 5rem;
    width: 70.4375rem;
    line-height: 2.8rem;
    margin: 1rem 0;

    &:before {
      content: '';
      position: absolute;
      left: -1rem;
      top: 1rem;
      transform: translate(4rem);
      width: 0.625rem;
      height: 0.625rem;
      background: #fff;
    }
  }
}
</style>
