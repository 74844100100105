<template>
  <div class="clubs-employees-head">
    <app-button
      btn-size="md"
      btn-type="primary"
      :btn-text="$t('club.editFilter')"
      class="cell-button"
      @click="showPopup = true"
    />
    <div class="filter-selected flexing text-texts-standard-default text-34 uppercase">
      <p>{{ $t('club.typeOfEmployee') }}:</p>
      <p class="text-texts-standard-important font-bold">
        {{ employeeTypeSelected }}
      </p>
      <p class="without-margin">,</p>
      <p>{{ $t('career.state') }}:</p>
      <p class="text-texts-standard-default font-bold">
        {{ employeeStatusSelected }}
      </p>
      <app-control-icon v-if="isFilterApplied" class="ml-4" control="delete" @click="resetFilter" />
    </div>
    <popup-window
      v-if="showPopup"
      popup-type="info"
      :popup-title="$t('club.hireEmployee')"
      :class-list="['clubs-employees-popup', 'filter-popup']"
      @close="showPopup = false"
    >
      <clubs-filter-popup
        :filter-data="filterData"
        @apply="changeFilter"
        @close="closeFilter"
        @reset="resetFilter"
      />
    </popup-window>
  </div>
</template>

<script lang="ts">
import ClubsFilterPopup from '@/components/Club/Popups/ClubsFilterPopup.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import { useEmployeeState } from '@/store/pinia/clubs/employeeStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { NameValueString } from '@/interfaces/Global'

const FILTER_MAPPING = {
  0: {
    one: 'default',
    two: 'personal',
    three: 'club',
  },
  1: {
    one: 'default',
    two: 'active',
    three: 'inactive',
  },
}
const REVERSE_FILTER_MAPPING = {
  personal: 'club.personal',
  club: 'club.clubEmployee',
  active: 'club.active',
  inactive: 'club.inactive',
  default: 'club.everyone',
}

interface ComponentData {
  showPopup: boolean
  employeeTypeSelected: string
  employeeStatusSelected: string
  filterData: {
    text: string
    selectbox: {
      option: NameValueString[]
      default: string
    }
  }[]
}

export default defineComponent({
  name: 'ClubsEmployeesHead',
  components: {
    PopupWindow,
    ClubsFilterPopup,
  },
  data(): ComponentData {
    return {
      showPopup: false,
      employeeTypeSelected: '',
      employeeStatusSelected: '',
      filterData: [],
    }
  },
  computed: {
    ...mapState(useEmployeeState, {
      getEmployeesFilter: 'getEmployeesFilter',
    }),
    employeeType(): string {
      return this.filterData[0].selectbox.option[0].name
    },
    isFilterApplied(): boolean {
      return (
        this.employeeTypeSelected !== this.filterData[0].selectbox.option[0].name ||
        this.employeeStatusSelected !== this.filterData[1].selectbox.option[0].name
      )
    },
  },
  created(): void {
    this.filterData = [
      {
        text: 'club.typeOfEmployee',
        selectbox: {
          option: [
            { name: this.$t('club.everyone'), value: 'one' },
            { name: this.$t('club.personal'), value: 'two' },
            { name: this.$t('club.clubEmployee'), value: 'three' },
          ],
          default: 'one',
        },
      },
      {
        text: 'career.state',
        selectbox: {
          option: [
            { name: this.$t('club.everyone'), value: 'one' },
            { name: this.$t('club.active'), value: 'two' },
            { name: this.$t('club.inactive'), value: 'three' },
          ],
          default: 'one',
        },
      },
    ]
    const filter = this.getEmployeesFilter
    this.employeeTypeSelected = this.$t(REVERSE_FILTER_MAPPING[filter.filter_type])
    this.employeeStatusSelected = this.$t(REVERSE_FILTER_MAPPING[filter.filter_hired])
  },

  methods: {
    ...mapActions(useEmployeeState, ['setEmployeesFilter']),
    resetFilter(): void {
      this.employeeTypeSelected = this.filterData[0].selectbox.option[0].name
      this.employeeStatusSelected = this.filterData[1].selectbox.option[0].name
      this.filterData[0].selectbox.default = 'one'
      this.filterData[1].selectbox.default = 'one'
      this.setEmployeesFilter({
        filter_type: 'default',
        filter_hired: 'default',
      })
    },

    closeFilter(): void {
      this.showPopup = false
    },
    changeFilter(e: NameValueString): void {
      let filterType
      let filterHired
      if (e[0]?.name) {
        this.employeeTypeSelected = e[0]?.name
        this.filterData[0].selectbox.default = e[0]?.value
        filterType = FILTER_MAPPING[0][e[0]?.value]
      }
      if (e[1]?.name) {
        this.employeeStatusSelected = e[1]?.name
        this.filterData[1].selectbox.default = e[1]?.value
        filterHired = FILTER_MAPPING[1][e[1]?.value]
      }
      this.setEmployeesFilter({
        filter_type: filterType,
        filter_hired: filterHired,
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.clubs-employees-head {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .filter-selected {
    p {
      margin: 0 0.25rem;

      &.without-margin {
        margin: 0 0.25rem 0 0;
      }
    }
  }
}
</style>
