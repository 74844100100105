import type { ImageLoadData } from '@/map-phaser-new/interfaces'
import { pathImages } from '@/globalVariables'
/**
 * This method is used to load specific image - compressed or base image.
 * @param scene - instance of Phaser.Scene
 * @param loadArray - array of images
 * @returns A Promise that resolves when all loadings of images are complete.
 */

export const loadImageData = (scene: Phaser.Scene, loadArray: ImageLoadData[]): Promise<void> => {
  return new Promise<void>((resolve) => {
    const promises: Promise<void>[] = []

    loadArray.forEach((imageData) => {
      const loadPromise = new Promise<void>((innerResolve) => {
        if (imageData.compressed) {
          loadCompressedImage(scene, pathImages, imageData)
        } else {
          scene.load.image(imageData.name, pathImages + imageData.data.place)
        }

        innerResolve()
      })

      promises.push(loadPromise)
    })

    Promise.all(promises).then(() => {
      scene.load.start()
      resolve()
    })
  })
}

function loadCompressedImage(
  scene: Phaser.Scene,
  externalAssetsPath: string,
  imageData: ImageLoadData,
): void {
  const { data } = imageData
  const { astc, etc, img } = data

  scene.load.texture(imageData.name, {
    ASTC: {
      type: astc.type,
      textureURL: externalAssetsPath + astc.textureURL,
    },
    ETC: {
      type: etc.type,
      textureURL: externalAssetsPath + etc.textureURL,
    },
    IMG: {
      textureURL: externalAssetsPath + img.textureURL,
    },
  })
}
