<template>
  <div class="grand-prize-about">
    <app-scrollbar width="100%" height="49.313rem" scroll="y" slide="y">
      <div class="grand-prize-header flexing">
        <div class="grand-prize-header-fog" />
        <app-main-icon icon-name="gp-gold" :icon-size="184" />
      </div>
      <div class="flex text-36 text-texts-standard-default grand-prize-content">
        <div class="flex-column text-30">
          <p class="content-text">
            <span class="content-dash"> - </span>
            {{ $replacePlaceholder($t('grandPrize.about1'), '{grandprize}', GRAND_PRIZE) }}
          </p>
          <p class="content-text">
            <span class="content-dash"> - </span
            >{{ $replacePlaceholder($t('grandPrize.about2'), '{grandprize}', GRAND_PRIZE) }}
          </p>
          <p class="content-text">
            <span class="content-dash"> - </span
            >{{
              $replacePlaceholder(
                $replacePlaceholder($t('grandPrize.about3'), '{grandprize}', GRAND_PRIZE),
                '%s',
                '72',
              )
            }}
          </p>
          <p class="content-text">
            <span class="content-dash"> - </span
            >{{
              $replacePlaceholder(
                $replacePlaceholder($t('grandPrize.about4'), '{grandprize}', GRAND_PRIZE),
                '%s',
                '5',
              )
            }}
          </p>
        </div>
      </div>
    </app-scrollbar>
  </div>
</template>

<script lang="ts">
import { GRAND_PRIZE } from '@/globalVariables'
import { defineComponent } from 'vue'

interface ComponentData {
  GRAND_PRIZE: typeof GRAND_PRIZE
}

export default defineComponent({
  name: 'GrandPrizeAbout',
  data(): ComponentData {
    return {
      GRAND_PRIZE,
    }
  },
})
</script>

<style lang="scss" scoped>
.grand-prize-about {
  width: 103.75rem;
  height: 49.313rem;

  .grand-prize-header {
    margin-top: 2.813rem;
    width: 100%;

    &-fog {
      position: absolute;
      width: 26.25rem;
      height: 12rem;
      background: url($path-grand-prize + 'about/BG-ABOUT-BADGE.avif') center no-repeat;
      background-size: cover;
    }
  }

  .grand-prize-content {
    margin: 2.75rem;
    text-align: left;

    .content-dash {
      color: theme('colors.texts.standard.important');
      font-weight: bold;
    }

    .content-text {
      margin: 2rem;
    }
  }
}
</style>
