<template>
  <div id="club-employees" class="flex flex-col club-employees app-page-wrapper absolute">
    <clubs-centrum-menu />
    <section class="w-full h-full safe-area">
      <main v-if="!isContentLoading">
        <clubs-employees-head />
        <app-scrollbar
          class="club-employees-boxes flex items-center"
          width="100%"
          height="43rem"
          scroll="x"
          slide="x"
          arrows
        >
          <clubs-employees-box
            v-for="(employee, i) in employees"
            :key="i"
            :employee="employee"
            :is-club-center="true"
            @rewards-claimed="rewardsClaimed"
          />
        </app-scrollbar>
        <div class="club-employees-btn">
          <app-button
            btn-size="md"
            btn-type="confirm"
            :btn-text="$t('common.takeRewards')"
            class="club-employees-claim-button"
            :disabled="!canClaim"
            @click="canClaim ? claimRewards() : null"
          />
        </div>
      </main>
      <component-loading :is-loading="isContentLoading" width="100%" :height="'80%'" />
    </section>
  </div>
</template>

<script lang="ts">
import ClubsCentrumMenu from '@/components/Club/ClubsCentrumMenu.vue'
import ClubsEmployeesBox from '@/components/Club/ClubsEmployeesBox.vue'
import ClubsEmployeesHead from '@/components/Club/ClubsEmployeesHead.vue'
import { CLUB_BUILDINGS } from '@/globalVariables'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useEmployeeState } from '@/store/pinia/clubs/employeeStore'
import { useRewardStore } from '@/store/pinia/clubs/rewardStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { Employee } from '@/interfaces/clubs/Employees'
import type { ClubRewardResponse } from '@/interfaces/clubs/ClubRewards'

interface ComponentData {
  employees: Nullable<Employee[]>
}

export default defineComponent({
  name: 'ClubsEmployees',
  components: {
    ClubsCentrumMenu,
    ClubsEmployeesBox,
    ClubsEmployeesHead,
  },
  data(): ComponentData {
    return {
      employees: null,
    }
  },
  computed: {
    ...mapState(useEmployeeState, {
      getEmployees: 'getEmployees',
      getEmployeesFilter: 'getEmployeesFilter',
    }),
    ...mapState(useRewardStore, {
      getAllClubReadyForClaimRewards: 'getAllClubReadyForClaimRewards',
    }),
    canClaim(): boolean {
      const currentEmployees = this.employees.reduce(
        (output: boolean[], e: Employee): boolean[] => {
          output[e.employeeId] = true
          return output
        },
        [],
      )
      return !!this.getAllClubReadyForClaimRewards.find(
        (reward: ClubRewardResponse): boolean =>
          reward.clubs_employees_id && reward.clubs_employees_id in currentEmployees,
      )
    },
    isContentLoading(): boolean {
      return this.employees == null
    },
  },
  watch: {
    getEmployees(): void {
      this.setEmployees()
    },
    getEmployeesFilter(): void {
      this.setEmployees()
    },
  },
  created(): void {
    this.loadClubInfo()
    this.loadEmployees({ buildingTypeId: CLUB_BUILDINGS.all, refresh: false })
    this.loadAllClubRewards()
  },
  methods: {
    ...mapActions(useEmployeeState, ['loadEmployees']),
    ...mapActions(useRewardStore, ['loadAllClubRewards', 'claimEmployeesRewards']),
    ...mapActions(useClubStore, {
      loadClubInfo: 'loadClubInfo',
    }),
    rewardsClaimed(): void {
      this.loadEmployees({ buildingTypeId: CLUB_BUILDINGS.all, refresh: true })
    },
    claimRewards(): void {
      this.claimEmployeesRewards()
    },
    setEmployees(): void {
      const filter = this.getEmployeesFilter
      const filterType = ['club', 'personal'].includes(filter.filter_type)
      const filterHired = ['active', 'inactive'].includes(filter.filter_hired)
      this.employees = this.getEmployees.filter((employee: Employee): boolean => {
        const status = filterType ? employee.employeeType === filter.filter_type : true
        if (!status) return status
        const isHired = filter.filter_hired === 'active'
        return filterHired ? employee.isHired === isHired : true
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.club-employees {
  &-boxes {
    gap: 1.438rem;

    .clubs-employees-box {
      height: 39.625rem;
      width: 31.25rem;

      :deep(.clubs-employees-box-header) {
        padding: 1.125rem 0 0;
      }
    }
  }

  main {
    position: relative;
    width: 98%;
    height: 100%;
    margin: 0 auto 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .temporary-class {
      height: 21.25rem;
    }

    .club-employees-btn {
      display: flex;
      justify-content: flex-end;
      position: relative;
      top: 0.5rem;
    }
  }
}
</style>
