import {
  EQUIPMENT_ATTRIBUTE_BONUS,
  EQUIPMENT_CASH_BONUS,
  EQUIPMENT_DURABILITY,
  DISCIPLINE,
  DURABILITY,
  MONEY,
  TRAINING_POINTS,
} from '@/globalVariables'

type EquipmentAttributeIcon =
  | typeof DISCIPLINE
  | typeof DURABILITY
  | typeof MONEY
  | typeof TRAINING_POINTS

// preklad equipment typov na nazvy ikoniek
export const getAttibutesIcons = (type: string): EquipmentAttributeIcon => {
  switch (type) {
    case EQUIPMENT_ATTRIBUTE_BONUS:
      return DISCIPLINE
    case EQUIPMENT_DURABILITY:
      return DURABILITY
    case EQUIPMENT_CASH_BONUS:
      return MONEY
    default:
      return TRAINING_POINTS
  }
}
