<template>
  <p class="dash-text relative pl-6">
    <span class="dash-text-dash absolute top-0 left-0">-</span>
    {{ text }}
  </p>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// TODO vyhodit a nahradit novym ShrinkText komponentom

export default defineComponent({
  name: 'DashText',
  props: {
    text: {
      type: String || Function,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.dash-text {
  text-align: left;

  &-dash {
    color: theme('colors.texts.standard.important');
    font-weight: bold;
  }
}
</style>
