const exportedEtcJson = {
  frames: {
    event_osra_discipline_2_1: {
      frame: { x: 220, y: 195, w: 107, h: 86 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 15, w: 107, h: 86 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_4_1: {
      frame: { x: 1, y: 1, w: 110, h: 103 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 1, w: 110, h: 103 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_8_1: {
      frame: { x: 108, y: 198, w: 110, h: 86 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 15, w: 110, h: 86 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_1_1: {
      frame: { x: 1, y: 106, w: 110, h: 90 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 14, w: 110, h: 90 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_6_1: {
      frame: { x: 225, y: 1, w: 100, h: 100 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 10, y: 5, w: 100, h: 100 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_7_1: {
      frame: { x: 113, y: 100, w: 110, h: 93 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 17, w: 110, h: 93 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_3_1: {
      frame: { x: 1, y: 198, w: 105, h: 90 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 10, w: 105, h: 90 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_5_1: {
      frame: { x: 113, y: 1, w: 110, h: 97 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 2, w: 110, h: 97 },
      sourceSize: { w: 110, h: 110 },
    },
  },
}

const exportedAstcJson = {
  frames: {
    event_osra_discipline_2_1: {
      frame: { x: 220, y: 195, w: 107, h: 86 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 15, w: 107, h: 86 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_4_1: {
      frame: { x: 1, y: 1, w: 110, h: 103 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 1, w: 110, h: 103 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_8_1: {
      frame: { x: 108, y: 198, w: 110, h: 86 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 15, w: 110, h: 86 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_1_1: {
      frame: { x: 1, y: 106, w: 110, h: 90 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 14, w: 110, h: 90 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_6_1: {
      frame: { x: 225, y: 1, w: 100, h: 100 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 10, y: 5, w: 100, h: 100 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_7_1: {
      frame: { x: 113, y: 100, w: 110, h: 93 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 17, w: 110, h: 93 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_3_1: {
      frame: { x: 1, y: 198, w: 105, h: 90 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 10, w: 105, h: 90 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_5_1: {
      frame: { x: 113, y: 1, w: 110, h: 97 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 2, w: 110, h: 97 },
      sourceSize: { w: 110, h: 110 },
    },
  },
}

const exportedImgJson = {
  frames: {
    event_osra_discipline_2_1: {
      frame: { x: 220, y: 195, w: 107, h: 86 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 15, w: 107, h: 86 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_4_1: {
      frame: { x: 1, y: 1, w: 110, h: 103 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 1, w: 110, h: 103 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_8_1: {
      frame: { x: 108, y: 198, w: 110, h: 86 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 15, w: 110, h: 86 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_1_1: {
      frame: { x: 1, y: 106, w: 110, h: 90 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 14, w: 110, h: 90 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_6_1: {
      frame: { x: 225, y: 1, w: 100, h: 100 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 10, y: 5, w: 100, h: 100 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_7_1: {
      frame: { x: 113, y: 100, w: 110, h: 93 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 17, w: 110, h: 93 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_3_1: {
      frame: { x: 1, y: 198, w: 105, h: 90 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 10, w: 105, h: 90 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_5_1: {
      frame: { x: 113, y: 1, w: 110, h: 97 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 2, w: 110, h: 97 },
      sourceSize: { w: 110, h: 110 },
    },
  },
}

export const eventOsraEtcJson = exportedEtcJson
export const eventOsraAstcJson = exportedAstcJson
export const eventOsraImgJson = exportedImgJson
