<template>
  <div
    class="app-page-wrapper rankings-disciplines absolute"
    data-cy="rankings-disciplines-wrapper"
  >
    <ranking-menu :current="2" />
    <section class="w-full h-full safe-area">
      <main class="ranking-disciplines-wrapper m-auto">
        <header class="ranking-disciplines-wrapper-header w-full">
          <discipline-menu
            id-discipline="arena-main-menu"
            emit-click-event
            :selected-default="selectedDiscipline"
            @on-click-discipline="selectDiscipline"
          />
        </header>
        <ranking-sub-menu :discipline="$t('discipline.discipline_' + selectedDiscipline)" />
        <div class="ranking-disciplines-wrapper-table">
          <ranking-table-head :ranking-type="RankingType.Discipline" />
          <app-scrollbar
            class="ranking-discipline-global-scroll"
            width="100%"
            :height="$isMobile() ? '30rem' : '29rem'"
            scroll="y"
            slide="y"
          >
            <ranking-row-table :rows="rankings" table="discipline" width="116.125rem" />
          </app-scrollbar>
          <ranking-player :player-data="myRanking" :ranking-type="RankingType.Discipline" />
        </div>
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import DisciplineMenu from '@/components/DisciplineMenu.vue'
import RankingMenu from '@/components/Rankings/RankingMenu.vue'
import RankingPlayer from '@/components/Rankings/RankingPlayer.vue'
import RankingRowTable from '@/components/Rankings/RankingRowTable.vue'
import RankingSubMenu from '@/components/Rankings/RankingSubMenu.vue'
import RankingTableHead from '@/components/Rankings/RankingTableHead.vue'
import { arenaRankingGlobalDisciplineEndpoint, pathImages } from '@/globalVariables'
import { RankingType } from '@/interfaces/RankingsInterfaces'
import { defineComponent } from 'vue'

import type Reward from '@/interfaces/Reward'
import type { Nullable } from '@/interfaces/utils'
import type { RankingUser } from '@/interfaces/RankingsInterfaces'
import type { DisciplineMenuData } from '@/interfaces/DisciplineMenu'
import type { RankingsApiResponse } from '@/interfaces/responses/rankings/RankingsApiResponse'
import { mapState } from 'pinia'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'

interface ComponentData {
  pathImages: typeof pathImages
  RankingType: typeof RankingType
  selectedDiscipline: number
  myRanking: RankingsApiResponse['ranking']['player']
  rankings: Nullable<RankingUser[]>
  rewards: Reward[]
}

export default defineComponent({
  name: 'RankingDisciplines',
  components: {
    RankingMenu,
    RankingRowTable,
    DisciplineMenu,
    RankingSubMenu,
    RankingTableHead,
    RankingPlayer,
  },
  data(): ComponentData {
    return {
      pathImages,
      RankingType,
      selectedDiscipline: 1,
      myRanking: {} as RankingsApiResponse['ranking']['player'],
      rankings: null,
      rewards: [],
    }
  },

  computed: {
    ...mapState(useDisciplineStore, {
      firstUnlockedDisciplineId: 'getFirstUnlockedDisciplineId',
    }),
  },
  async created(): Promise<void> {
    if (this.$route.params.id) {
      this.selectedDiscipline = Number(this.$route.params.id)
    } else {
      this.selectedDiscipline = this.firstUnlockedDisciplineId
    }

    this.getRankings()
  },
  methods: {
    async getRankings(): Promise<void> {
      const response = await this.$axios.get<{}, RankingsApiResponse>(
        arenaRankingGlobalDisciplineEndpoint + '/' + this.selectedDiscipline,
      )

      this.rewards = response?.rewards ?? []
      this.myRanking = response?.ranking?.player
      if (this.myRanking) {
        this.myRanking.rewards = this.getRewardsByRank(this.myRanking.position)
      }

      this.rankings =
        Object.keys(response?.ranking).reduce(
          (finalArray: RankingUser[], userId: string, index: number): RankingUser[] => {
            if (userId !== 'player') {
              finalArray.push({
                id: userId,
                name: response.ranking[userId].username,
                level: response.ranking[userId].LEVEL,
                points: response.ranking[userId].RANKING_POINTS,
                rewards: this.getRewardsByRank(index + 1),
                clubId: response.ranking[userId].club_id,
                clubLogoId: response.ranking[userId].club_logo_id,
                clubLogoBgId: response.ranking[userId].club_background_logo_id,
                eventBadges: response.ranking[userId].event_badges ?? null,
                ...response.ranking[userId],
              })
            }
            return finalArray
          },
          [],
        ) ?? []
    },
    getRewardsByRank(rank: number): Reward[] {
      return (
        this.rewards.find(
          (rewardObj: Reward) => rank >= rewardObj.rank_min && rank <= rewardObj.rank_max,
        )?.rewards || []
      )
    },
    selectDiscipline(disciplineData: DisciplineMenuData): void {
      if (this.selectedDiscipline !== disciplineData.id) {
        this.selectedDiscipline = disciplineData.id
        this.getRankings()
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.ranking-disciplines-wrapper {
  width: 116.25rem;
  height: 48.0625rem;
  margin-top: 1.1875rem;

  &-header {
    height: 5.625rem;
    margin-bottom: 1.25rem;
    @if $isWsm {
      background: linear-gradient(to right, transparent 0, #144a73, transparent 100%);
    }
    @if $isSsm {
      background: linear-gradient(
        to right,
        transparent 0,
        rgba(86, 98, 127, 0.9),
        transparent 100%
      );
    }
  }

  &-table {
    margin-top: 1.25rem;
    height: 40.4375rem;
  }
}
</style>
