<template>
  <main v-if="getBuildingsIds" :class="'club-' + buildingType">
    <clubs-building-panel :building-type="buildingType" @do-action="claimRewards" />
    <div class="club-building-right-side">
      <div class="club-building-right-side-title">
        <div class="club-building-right-side-title-content flexing">
          <h2 class="text-texts-standard-default text-36 uppercase">
            {{ $t('club.employees') }}
          </h2>
        </div>
      </div>
      <div v-if="getEmployeesData.length" class="club-building-right-side-employees">
        <div v-for="(employee, i) in getEmployeesData" :key="i" class="h-full">
          <clubs-employees-box-empty
            v-if="employee.boxEmpty"
            :box-color="employee.boxColor"
            :title="getTitle(employee)"
            :can-hire="employee.canHire"
            @hire="openHirePopup(employee.employeeType)"
          />
          <clubs-employees-box v-else :employee="employee" @rewards-claimed="claimRewards(true)" />
          <div />
        </div>

        <popup-window
          v-if="showEmployeesPopup"
          popup-type="info"
          :popup-title="$t('club.hireEmployee')"
          @close="closeHirePopup"
        >
          <clubs-hire-employee-popup :employee-type="employeeType" @close="closeHirePopup" />
        </popup-window>
      </div>

      <component-loading width="93%" :is-loading="!getEmployeesData.length" />
    </div>
  </main>
</template>

<script lang="ts">
import ClubsBuildingPanel from '@/components/Club/ClubsBuildingPanel.vue'
import ClubsEmployeesBox from '@/components/Club/ClubsEmployeesBox.vue'
import ClubsEmployeesBoxEmpty from '@/components/Club/ClubsEmployeesBoxEmpty.vue'
import ClubsHireEmployeePopup from '@/components/Club/Popups/ClubsHireEmployeePopup.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import { useBuildingsStore } from '@/store/pinia/clubs/buildingStore'
import { useEmployeeState } from '@/store/pinia/clubs/employeeStore'
import { useRewardStore } from '@/store/pinia/clubs/rewardStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { Employee } from '@/interfaces/clubs/Employees'

interface ComponentData {
  showEmployeesPopup: boolean
  employeeType: string
}

export default defineComponent({
  name: 'ClubsBuildingEmployees',
  components: {
    ClubsBuildingPanel,
    ClubsEmployeesBox,
    ClubsEmployeesBoxEmpty,
    ClubsHireEmployeePopup,
    PopupWindow,
  },
  props: {
    buildingType: {
      type: String,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      showEmployeesPopup: false, // popup for employee hire
      employeeType: null,
    }
  },
  computed: {
    ...mapState(useEmployeeState, {
      getEmployeesSlot: 'getEmployeesSlot',
    }),
    ...mapState(useBuildingsStore, ['getBuildingsIds']),
    getEmployeesData(): Employee[] {
      const slots = this.getEmployeesSlot(this.getBuildingsIds[this.buildingType])
      const slotsArray = []
      if (slots) {
        slotsArray.push(slots.personal, slots.club)
      }

      return slotsArray
    },
  },
  async created(): Promise<void> {
    if (!this.getBuildingsIds) {
      await this.loadBuildings()
    }
    this.loadEmployees({
      buildingTypeId: this.getBuildingsIds[this.buildingType],
      refresh: false,
    })
    this.loadAllClubRewards()
  },
  methods: {
    ...mapActions(useEmployeeState, ['loadEmployees']),
    ...mapActions(useRewardStore, ['loadAllClubRewards', 'claimBuildingRewards']),
    ...mapActions(useBuildingsStore, ['loadBuildings']),
    claimRewards(claimed: boolean = false): void {
      const employee = this.getEmployeesData.find(
        (employee: Employee): boolean =>
          employee.buildingTypeId != null && employee.clubBuildingId != null,
      )

      if (!employee) return
      if (claimed) {
        this.loadEmployees({
          buildingTypeId: employee.buildingTypeId,
          refresh: true,
        })
      } else {
        this.claimBuildingRewards({
          clubBuildingId: employee.clubBuildingId,
          buildingTypeId: employee.buildingTypeId,
        })
      }
    },
    openHirePopup(employee: string): void {
      // hire a new employee
      this.showEmployeesPopup = true
      this.employeeType = employee
    },
    closeHirePopup(): void {
      this.showEmployeesPopup = false
    },
    getTitle(employee: Employee): string {
      return 'club.' + (employee.employeeType === 'club' ? 'clubEmployee' : 'personalEmployee')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/club/employees.scss';
@import '@/assets/styles/views/club/buildingEmployees.scss';
</style>
