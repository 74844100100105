<template>
  <div class="lab-about-popup flexing">
    <popup-about-type-one about-id="lab-about-popup" :popup-data="getPopupData" />
  </div>
</template>

<script lang="ts">
import PopupAboutTypeOne from '@/components/Popup/PopupAboutTypeOne.vue'
import { defineComponent } from 'vue'

interface PopupData {
  img: string
  title: string
  text: string
}

export default defineComponent({
  name: 'LabPopup',
  components: {
    PopupAboutTypeOne,
  },
  computed: {
    getPopupData(): PopupData[] {
      return [
        {
          img: 'icons/icon-research.avif',
          title: this.$t('research.lab'),
          text: this.$t('research.aboutLab'),
        },
        {
          img: 'icons/icon-research-points.avif',
          title: this.$t('research.researchPoints'),
          text: this.$t('research.pontsLabText'),
        },
        {
          img: 'icons/icon-training-camps.avif',
          title: this.$t('research.trainingCamp'),
          text: this.$t('research.trainingCampText'),
        },
      ]
    },
  },
})
</script>

<style lang="scss" scoped>
.popup-about-lab {
  width: 102.75rem;
  height: 49.375rem;

  .popup-about-box {
    margin: 0.625rem 0;

    &-pic {
      width: 15%;
      height: 12rem;
      background: transparent;
      display: inline-block;
      vertical-align: top;
      position: relative;

      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    &-textarea {
      width: 85%;
      font-size: 1.25rem;
      display: inline-block;
      font-style: italic;

      .popup-about-box-title {
        font-size: 2rem;
        width: 95%;
        height: 3rem;
        line-height: 3rem;
        display: block;
        text-align: left;
        text-transform: uppercase;

        @if $isWsm {
          border-bottom: 0.063rem solid skyblue;
        }

        @if $isSsm {
          border-bottom: 0.063rem solid #4c648f;
        }
      }

      .popup-about-box-text {
        font-size: 1.75rem;
        width: 95%;
        display: block;
        text-align: left;
        padding: 1rem 0;
      }
    }
  }
}
</style>
