<template>
  <div
    class="flex flex-col items-center justify-center bordered-6 text-texts-standard-default club-house-progress"
  >
    <div class="club-house-progress-title w-full flexing">
      <p class="text-36 uppercase flexing club-house-progress-title-text">
        {{ $t('bugReport.grandPrize') }}
      </p>
    </div>
    <div class="club-house-progress-grandprize flexing text-30">
      <div class="club-house-progress-grandprize-wrapper">
        <div class="club-house-progress-grandprize-wrapper-icon-club flex flex-col">
          <clubs-avatar
            :background-id="clubLogoBg"
            :logo-id="clubLogo"
            class="club-house-progress-grandprize-wrapper-icon-club-avatar"
          />
          <div class="flexing club-house-progress-grandprize-wrapper-icon-club-value">
            <p class="text-30 text-texts-standard-important">
              {{ dailyTask.progress.currentValue }}
            </p>
            <app-main-icon :icon-name="dailyTask.task.type" :icon-size="32" />
          </div>
        </div>
        <div v-if="progressItems" class="club-house-progress-grandprize-wrapper-progress relative">
          <app-progress-bar
            class="club-house-progress-grandprize-wrapper-progressbar"
            :bar-height="1.8"
            :text-size="2.25"
            :actual="dailyTask.progress.currentValue"
            :goal="progressGoal"
          />
          <div class="zero-div absolute">0</div>
          <div
            v-for="(item, index) in progressItems"
            :key="index"
            class="club-house-progress-grandprize-wrapper-icon-gp flex flex-col"
            :class="[
              `club-house-progress-grandprize-wrapper-icon-gp--${item.iconName}`,
              {
                'club-house-progress-grandprize-wrapper-icon-gp--focused':
                  item.iconName === firstInCompleteOrLast.iconName,
              },
            ]"
            :style="{ left: `calc(${item.positionValue}% - 4rem)` }"
          >
            <div class="club-house-progress-grandprize-wrapper-icon-gp-inner flexing">
              <app-main-icon
                :icon-name="`gp-${item.iconName}`"
                :class="{ 'gp-done': item.isCompleted }"
                :icon-size="80"
              />

              <div
                v-if="item.isCompleted"
                class="icon icon-done-md club-house-progress-grandprize-wrapper-icon-gp-inner-done"
              />
            </div>

            <div class="club-house-progress-grandprize-wrapper-icon-gp-divider" />

            <div class="flexing mt-2">
              <p :class="{ 'text-texts-standard-positive': item.isCompleted }" class="text-30">
                {{ item.value }}
              </p>
              <app-main-icon :icon-name="dailyTask.task.type" :icon-size="32" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ClubsAvatar from '@/components/Club/ClubsAvatar.vue'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { ClubDailyTask } from '@/interfaces/clubs/ClubDailyTasks'

interface ProgressData {
  iconName: string
  positionValue: number
  value: number
  isCompleted: boolean
}

interface ComponentData {
  progressItems: ProgressData[]
}

export default defineComponent({
  name: 'ClubsHouseProgressBox',
  components: {
    ClubsAvatar,
  },
  props: {
    dailyTask: {
      type: Object as PropType<ClubDailyTask>,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      progressItems: null,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      clubLogo: 'getClubLogo',
      clubLogoBg: 'getClubLogoBg',
    }),
    progressGoal(): number {
      const milestones = this.dailyTask.progress.milestones

      return milestones[milestones.length - 1]
    },
    firstInCompleteOrLast(): ProgressData {
      return (
        this.progressItems.find((item: ProgressData): boolean => !item.isCompleted) ||
        this.progressItems[this.progressItems.length - 1]
      )
    },
  },
  mounted(): void {
    this.setProgressItems()
  },
  methods: {
    setProgressItems(): void {
      this.progressItems = this.dailyTask.task.rewards.map((item) => {
        return {
          iconName: item.reward.rarity,
          positionValue: this.calculatePercentage(item.milestone_target),
          value: item.milestone_target,
          isCompleted: item.milestone_reached,
        }
      })
    },
    calculatePercentage(part: number): number {
      return (part / this.progressGoal) * 100
    },
  },
})
</script>

<style lang="scss" scoped>
.club-house-progress {
  width: 99%;
  height: 19rem;
  background: url($path-clubs + 'components/clubhouse/BG_GRAND_PRIZE_CONTENT.avif') center no-repeat;
  background-size: 100% 100%;
  border: 0.125rem solid #a533b9;
  margin-bottom: 0.688rem;
  // tutorial safe layering
  z-index: 0;

  &-title {
    height: 4.375rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    @if $isWsm {
      background: linear-gradient(
        90deg,
        rgba(104, 23, 108, 0) 0%,
        rgba(104, 23, 108, 1) 50%,
        rgba(104, 23, 108, 0) 100%
      );
    }
    @if $isSsm {
      background: linear-gradient(
        90deg,
        rgba(104, 23, 108, 0) 0%,
        rgba(170, 65, 164, 0.99) 50%,
        rgba(104, 23, 108, 0) 100%
      );
    }

    &-text {
      width: 100%;
      height: 4.375rem;
    }
  }

  &-grandprize {
    height: 18rem;
    width: 100%;

    &-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      margin-left: 4.5rem;
      margin-right: 7.125rem;
      padding-top: 0.781rem;
      padding-bottom: 1.5rem;
      gap: 5rem;

      &-icon {
        &-club {
          &-avatar {
            width: 11.188rem;
            height: 11.188rem;

            .club-avatar-head {
              position: relative;
              top: -0.5rem;
            }
          }

          &-value {
            margin-top: -2rem;
            transform: translate(0, 0);

            @if $isWsm {
              background: linear-gradient(
                90deg,
                rgba(32, 72, 118, 0) 0%,
                rgba(32, 72, 118, 1) 50%,
                rgba(32, 72, 118, 0) 100%
              );
            }
            @if $isSsm {
              background: linear-gradient(
                90deg,
                transparent 0%,
                #34415d 20%,
                #273249 50%,
                #34415d 80%,
                transparent 100%
              );
            }

            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 0.125rem;

              left: 0;
              @if $isWsm {
                background: linear-gradient(
                  90deg,
                  rgba(106, 139, 195, 0) 0%,
                  rgba(106, 139, 195, 1) 50%,
                  rgba(106, 139, 195, 0) 100%
                );
              }
              @if $isSsm {
                background: linear-gradient(
                  90deg,
                  rgba(106, 139, 195, 0) 0%,
                  #586b9d 50%,
                  rgba(106, 139, 195, 0) 100%
                );
              }
            }

            &:before {
              top: 0;
            }

            &:after {
              bottom: 0;
            }
          }
        }

        &-gp {
          position: absolute;
          // universal unifying width value for calculations
          width: 8rem;
          top: -4.7rem;
          align-items: center;

          &--focused {
            .club-house-progress-grandprize-wrapper-icon-gp-inner {
              &::before {
                content: '';
                position: absolute;
                width: 85%;
                height: 100%;
                background: #c933cb;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                filter: blur(1rem);
                z-index: 0;
              }

              & > div {
                z-index: 1;
              }
            }
          }

          &-inner {
            position: relative;
            margin: 0 auto;

            .gp-done {
              filter: brightness(40%);
            }

            &-done {
              position: absolute;
            }
          }

          &-divider {
            height: 1.5rem;
            width: 0.1rem;
            margin: 0 auto;
            z-index: 1;

            @if $isWsm {
              background: #647e92;
              transform: skew(-12deg);
            }
            @if $isSsm {
              background: #eaeaea;
            }
          }

          &--gold {
            .club-house-progress-grandprize-wrapper-icon-gp-divider {
              opacity: 0;
            }
          }
        }
      }

      &-progress {
        flex: 1 1 auto;

        .progress-bar-main {
          width: 100% !important;
        }
      }

      .zero-div {
        margin-top: 0.42rem;
        left: -0.5rem;
      }
    }
  }
}
</style>
