<template>
  <teleport to="#app">
    <div
      class="level-up fullscreen-popup-wrapper absolute"
      :class="$isMobile() && 'level-up-mobile'"
    >
      <div class="level-up-header-text uppercase m-auto">
        {{ $t('common.congratulations') }}
      </div>
      <div class="level-up-header-congratulation uppercase font-bold m-auto">
        {{ $t('career.careerFinishedForDiscipline') }}
      </div>
      <div class="level-up-discipline m-auto relative flexing">
        <app-discipline-icon :theme="'light'" :size="184" :discipline-id="disciplineId" />
        <div class="level-up-discipline-glow" />
      </div>
      <p class="level-up-text font-bold uppercase text-50" :class="disciplineNameColorClass">
        {{ disciplineName }}
      </p>
      <div class="level-up-rewards m-auto text-28 text-texts-standard-default">
        <slot />
      </div>
      <div class="level-up-footer flex items-center justify-center m-auto">
        <app-button
          class="layout-premium-btn"
          btn-id="level-up-discipline-btn"
          btn-type="secondary"
          :btn-text="$t('button.continue')"
          btn-size="sm"
          @click="closePopup"
        />
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { useCareerDetailStore } from '@/store/pinia/career/careerDetail'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { CareerBranch } from '@/enums/CareerBranch'

export default defineComponent({
  name: 'LevelUpDiscipline',
  props: {
    disciplineId: {
      type: Number,
      default: 1,
    },
  },
  emits: ['close'],
  computed: {
    ...mapState(useCareerDetailStore, {
      careerDetail: 'getCareerDetail',
      activeQuest: 'getActiveQuest',
    }),
    disciplineName(): string {
      return this.$translateDiscipline(this.disciplineId ?? '')
    },
    disciplineNameColorClass(): string {
      if (this.$isWsm) {
        return 'text-texts-standard-name'
      }
      return 'text-texts-standard-default'
    },
  },
  methods: {
    ...mapActions(useResponseTaskStore, {
      hideDisciplineBranchCompleted: 'hideDisciplineBranchCompleted',
    }),
    closePopup(): void {
      this.hideDisciplineBranchCompleted()

      this.$router.push({
        name: this.$getWebView('CareerDetail'),
        params: {
          careerId: this.careerDetail.careerId,
          id: this.careerDetail.careerBranch.disciplineId,
          task:
            this.careerDetail.careerBranch.type === CareerBranch.Main
              ? this.activeQuest?.parameters.id
              : this.careerDetail.quests[this.careerDetail.quests.length - 1].parameters.id,
        },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.level-up {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); // rgba(0,0,0,0.8)
  top: 0;
  z-index: 1;

  &-header-text {
    margin-top: 6.25rem;
    background: linear-gradient(to bottom, #fff 0%, #d7d7d7 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3.125rem;
  }

  &-header-congratulation {
    background: linear-gradient(to bottom, #fff196 0%, #fadd1e 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 4.3125rem;
  }

  &-rewards {
    margin-top: 5rem;
  }

  &-discipline {
    width: 41.5rem;
    height: 27.5rem;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 56%;
      left: 68%;
      transform: translate(-50%, -50%);
      width: 17.5rem;
      height: 1.5rem;
      border-radius: 50%;
      mix-blend-mode: overlay;
      background: #3e7fa74f;
      box-shadow: 0 0 8.9375rem 13.625rem rgb(54 104 133 / 39%);
    }
  }

  &-footer {
    margin-top: 2.0625rem;
    width: 116.25rem;
    height: 4.5rem;
    bottom: 5rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -5rem);

    .advert {
      background: url($path-icons + 'main/PPS_LOGO_100x72px.avif') center no-repeat;
      background-size: 7rem;
      background-color: white;
      width: 13.625rem;
      height: 5.688rem;
      padding: 0.5rem 0;
    }
  }
}

.level-up-mobile {
  width: 100vw;
  height: 100vh;
}
</style>
