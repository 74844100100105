<template>
  <main
    v-if="tournaments.length > 0 && isLoading === false"
    class="masters-tournament-listing-main"
  >
    <app-scrollbar width="100%" height="48.1rem" scroll="y" slice="y">
      <master-tournament-row
        v-for="tournament in tournaments"
        :key="tournament.playoff_id"
        :tournament-row-config="tournament"
        @get-data="getTournaments"
      />
    </app-scrollbar>
  </main>
  <component-loading v-else />
</template>

<script lang="ts">
import { eventTournamentStatus } from '@/globalVariables'
import type { MastersSetupObject } from '@/interfaces/events/MastersPlayoffInterfaces'
import { defineComponent } from 'vue'
import MasterTournamentRow from './MasterTournamentRow.vue'
import { PlayoffMainComponent, type PlayoffType } from '@/interfaces/events/Playoff'

interface MastersStatusInterface {
  mechanic: PlayoffType.Masters
  playoffs: MastersSetupObject[]
}

interface ComponentData {
  tournaments: MastersSetupObject[]
  isLoading: boolean
}

export default defineComponent({
  name: PlayoffMainComponent.Tournaments,
  components: {
    MasterTournamentRow,
  },
  data(): ComponentData {
    return {
      tournaments: [],
      isLoading: false,
    }
  },
  async created(): Promise<void> {
    await this.getTournaments()
  },
  methods: {
    async getTournaments(): Promise<void> {
      this.isLoading = true
      try {
        const { playoffs } = await this.$axios.post<{}, MastersStatusInterface>(
          eventTournamentStatus,
        )
        this.tournaments = playoffs.map((tournament: MastersSetupObject): MastersSetupObject => {
          tournament.start_date = new Date(tournament.start_date)
          if (tournament.end_date) tournament.end_date = new Date(tournament.end_date)
          return tournament
        })
      } catch (error: unknown) {
        console.error(error)
      }
      this.isLoading = false
    },
  },
})
</script>

<style lang="scss" scoped>
main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding: 0 1.5rem;

  @if $isWsm {
    border-top-width: 0.188rem;
    border-top-style: solid;
    border-image-source: linear-gradient(
      to right,
      rgba(236, 236, 236, 0.06) 0%,
      rgba(236, 236, 236, 0.75) 25%,
      rgba(236, 236, 236, 0.75) 75%,
      rgba(236, 236, 236, 0.06) 100%
    );
    border-image-slice: 1;
  }

  .masters-tournament-listing-main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    width: 100%;
    color: white;
    padding-top: 0.5rem;
  }
}
</style>
