import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const boatRightToBottomCheckpoints: CheckpointsObjectInterface = {
  key: 'boatRightToBottomCheckpoints',
  data: [
    {
      x: 3722,
      y: 1496,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatRightToBottomAnimations1',
        },
      },
    },
    {
      x: 3475,
      y: 1590,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatRightToBottomAnimations2',
        },
      },
    },
    {
      x: 3285,
      y: 1667,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatRightToBottomAnimations3',
        },
      },
    },
    {
      x: 3069,
      y: 1729,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatRightToBottomAnimations4',
        },
      },
    },
    {
      x: 2793,
      y: 1801,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatRightToBottomAnimations5',
        },
      },
    },
    {
      x: 2438,
      y: 1806,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatRightToBottomAnimations6',
        },
      },
    },
    {
      x: 2227,
      y: 1840,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatRightToBottomAnimations7',
        },
      },
    },
    {
      x: 2047,
      y: 1940,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatRightToBottomAnimations8',
        },
      },
    },
    {
      x: 1936,
      y: 2029,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatRightToBottomAnimations9',
        },
      },
    },
  ],
}
