import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const clubCarsParkingToRightPath: PathObjectInterface = {
  mainPath: { x: 1692, y: 1608 },
  spline: [
    1692, 1608, 1631, 1610, 1598, 1608, 1554, 1592, 1536, 1578, 1535, 1548, 1555, 1519, 1581, 1501,
    1638, 1484, 1703, 1485, 1782, 1488, 1884, 1507, 1965, 1528, 2036, 1538, 2115, 1528, 2199, 1514,
    2301, 1493, 2373, 1483, 2496, 1477, 2598, 1509, 2667, 1532, 2742, 1560, 2794, 1582, 2853, 1592,
    2918, 1586, 2989, 1560, 3052, 1507, 3088, 1440, 3097, 1387, 3079, 1325, 3064, 1295, 3027, 1250,
    2964, 1186, 2915, 1145, 2862, 1108, 2823, 1071,
  ],
}
