<template>
  <div class="header-discipline flexing">
    <app-discipline-icon :discipline-id="disciplineId" :size="90" />
    <div class="header-texts flex flex-col justify-center">
      <p class="text-40 text-texts-standard-default uppercase font-bold">
        {{ $translateDiscipline(disciplineId) }}
      </p>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ReportHead',
  props: {
    disciplineId: {
      type: Number,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.header-texts {
  text-align: left;
  margin-left: 1rem;
}
</style>
