<template>
  <main v-if="loaded" class="events-offers flex flex-col w-full h-full safe-area">
    <div v-if="eventCollectionActive" class="flexing flex-col w-full h-full">
      <event-message :event-ended="true" />
    </div>
    <template v-else>
      <div class="header w-full flexing uppercase p-2 relative">
        <h1 class="text-60 gradient-text-light font-bold italic uppercase">
          {{ $te('tabOffers') }}
        </h1>
      </div>
      <div class="content w-full h-full px-8 py-4 mt-4 relative">
        <div class="offer-wrapper flex flex-col items-center">
          <app-scrollbar
            class="offer-wrapper-scroll"
            width="100%"
            height="46.625rem"
            scroll="x"
            slide="x"
            :arrows="true"
            arrow-pos="top-1/2"
          >
            <offers-box
              v-for="(offer, i) in eventBigOffers"
              :key="i"
              :offer-data="offer"
              :class="i === eventBigOffers.length - 1 && '!mr-0'"
            />
          </app-scrollbar>
        </div>
      </div>
    </template>
  </main>
</template>

<script lang="ts">
import OffersBox from '@/components/Premium/Offers/OffersBox.vue'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import EventMessage from '@/components/Events/EventMessage.vue'

interface ComponentData {
  loaded: boolean
}

export default defineComponent({
  name: 'OffersMain',
  components: {
    OffersBox,
    EventMessage,
  },
  data(): ComponentData {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      eventBigOffers: 'getEventBigOffers',
    }),
    ...mapState(useEventInfoStore, {
      eventCollectionActive: 'getEventCollectionActive',
    }),
  },
  watch: {
    async '$route.query.reloadData'(): Promise<void> {
      if (!this.$route.query.reloadData) return

      await this.loadEventOffers(true)

      this.$router.replace({
        query: {
          ...this.$route.query,
          reloadData: undefined,
        },
      })
    },
  },
  async created(): Promise<void> {
    await this.loadEventOffers()
    this.loaded = true
  },
  methods: {
    ...mapActions(usePremiumStore, ['loadEventOffers']),
  },
})
</script>

<style lang="scss" scoped>
.events-offers {
  .header {
    margin-top: 1.875rem;
    padding: 0 3.75rem;
    height: 5.1875rem;
    background: linear-gradient(to right, transparent, #0e4e75b5, transparent);

    &:before,
    &:after {
      content: '';
      position: absolute;
      background: linear-gradient(to right, transparent, #5b6872, transparent);
      height: 0.125rem;
      width: 80%;
      left: 50%;
      transform: translate(-50%);
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  }

  .offer-wrapper {
    &-scroll {
      display: -webkit-inline-box;
    }
  }
}
</style>
