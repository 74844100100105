export const redirectViews = {
  arena: 'ArenaView',
  stadium: 'ArenaView',
  training_hall: 'TrainingDisciplineDetail',
  training: 'TrainingDisciplines',
  main_map: 'LayoutView',
  daily_tasks: 'DailyTask',
  grand_prize: 'GrandPrizeWarehouse',
  daily_league: 'StarofthedayDisciplines',
  equipment_shop: 'Equipment',
  boss_fight: '',
  trainer: 'TrainingTrainer',
  personal_trainer: 'TrainingTrainer',
  clubs_championship: 'ClubsChampionshipCompetitions',
  research: 'LabDisciplines',
}
