export enum EventType {
  ChallengeCup = 'challenge_cup',
  FrozenChampionship = 'frozen',
  // WinterSeries = 'discipline',
  DisciplineEvent = 'discipline',
  AutumnFair = 'autumn',
  BoardGamesEvent = 'board_games_event',
  IceAndFireEvent = 'ice_and_fire_event',
}

export enum EventMechanics {
  Taskchain = 'taskchain',
  Milestones = 'milestones',
  Playoff = 'playoff',
  MastersPlayoff = 'masters_playoff',
  LuckyWheel = 'lucky_wheel',
  BountyBoard = 'bounty_board',
}
