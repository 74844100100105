<template>
  <teleport to="#app">
    <div class="skip-tutorial">
      <header class="flexing">
        <h2 class="text-texts-standard-additional text-40 uppercase">
          {{ $translateDiscipline(String(disciplineId)) }}
        </h2>
      </header>
      <main class="flexing flex-col">
        <div class="skip-tutorial-text">
          <p class="text-texts-standard-default text-32">
            {{ $t('minigame.tutorialsText') }}
          </p>
        </div>
        <div class="skip-tutorial-buttons flexing">
          <app-button
            btn-id="play-tutorial-btn"
            class="skip-tutorial-buttons-button"
            btn-type="secondary"
            :btn-text="$t('minigame.playTutorial')"
            @click="selectMinigameTutorialBeforePlay(true)"
          />
          <app-button
            btn-id="skip-tutorial-btn"
            :btn-text="$t('minigame.skipTutorial')"
            @click="selectMinigameTutorialBeforePlay(false)"
          />
        </div>
      </main>
    </div>
  </teleport>
</template>

<script lang="ts">
import { minigameUrl, pathImages } from '@/globalVariables'
import { openMiniGame } from '@/helpers'
import { useModeStore } from '@/store/pinia/modeStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { MultiplicatorItem } from '@/types/userProfile'

interface ComponentData {
  showPopup: boolean
  pathImages: typeof pathImages
  openMiniGame: typeof openMiniGame
}

export default defineComponent({
  name: 'SkipTutorialPopup',
  props: {
    disciplineId: {
      type: Number,
      required: true,
    },
    multiplier: {
      type: String as PropType<MultiplicatorItem['name']>,
      default: null,
    },
    noTutorialMode: {
      type: Number,
      default: 1,
    },
    questId: {
      type: Number,
      default: null,
    },
  },
  emits: ['hidePopup'],
  data(): ComponentData {
    return {
      showPopup: false,
      pathImages,
      openMiniGame,
    }
  },
  computed: {
    ...mapState(useModeStore, ['getMode']),
  },
  methods: {
    selectMinigameTutorialBeforePlay(isMinigameTutorial: boolean): void {
      this.$emit('hidePopup')
      this.openMiniGame(
        minigameUrl,
        {
          params: {
            mode: isMinigameTutorial ? this.getMode.tutorial : this.noTutorialMode,
            discipline_id: this.disciplineId,
            multiplier: this.multiplier,
            ...(isMinigameTutorial && { redirect: this.$route.name }),
            ...(this.questId && { quest_id: this.questId }),
          },
        },
        this.$router,
      )
    },
  },
})
</script>

<style lang="scss" scoped>
.skip-tutorial {
  width: 100%;
  height: 16.938rem;
  z-index: 5;
  position: absolute;
  left: 50%;
  top: 43%;
  transform: translate(-50%, -43%);

  @if $isWsm {
    background-image: linear-gradient(
      to right,
      transparent,
      #154e79 10%,
      #0f2a4e 40%,
      #0b1e37 50%,
      #0f2a4e 60%,
      #154e79 90%,
      transparent
    );
    box-shadow: 0px 0.313rem 0.813rem 0 #272727;
  }

  @if $isSsm {
    background-image: linear-gradient(to top, #4f4d79, #21273c);
  }

  header {
    width: 100%;
    height: 5rem;
    background-image: url($path-components + 'progressMessage/BG_TOP_CONTENT.avif');
    background-size: cover;
    background-repeat: no-repeat;
  }

  main {
    width: 100%;
    height: 8.125rem;

    .skip-tutorial-text {
      margin-top: 3.5rem;
      margin-bottom: 1rem;
    }

    .skip-tutorial-buttons {
      display: flex;
      height: 60%;
      margin-top: 0.5rem;

      &-button {
        margin-right: 2rem;
      }
    }
  }
}
</style>
