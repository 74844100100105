<template>
  <section
    class="sub-header w-full items-center flex justify-between relative font-bold"
    :class="{
      'is-ssm-gc': $isSsm && namespace === EventType.FrozenChampionship,
    }"
  >
    <p class="gradient-text-light uppercase text-40" :class="{ 'w-full': title }">
      <template v-if="round?.round">
        {{ $replacePlaceholder($te('round'), '%s', round.round.toString()) }}
      </template>
      <template v-else-if="title">
        {{ title }}
      </template>
    </p>
    <p v-if="round?.task" class="gradient-text-light text-40 flexing">
      {{
        $replacePlaceholder(
          $t(`event.${round.task}`),
          '{discipline}',
          $translateDiscipline(round.disciplineName),
        )
      }}
      <app-main-icon v-if="round?.icon" :icon-size="56" :icon-name="round.icon" class="ml-4" />
    </p>
    <app-timer
      v-if="round?.time"
      :time="round?.round === roundCount ? round.time + 60 : round.time"
      @countdown-action="$emit('reloadOperation')"
    />
  </section>
</template>

<script lang="ts">
import { EventType } from '@/interfaces/events/EventInfo'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'

export interface SubHeaderData {
  round: number
  task: string
  icon: string
  time: number
  disciplineName: string
}

interface ComponentData {
  EventType: typeof EventType
}

export default defineComponent({
  name: 'QualificationSubHeader',
  props: {
    round: {
      type: Object as PropType<Nullable<SubHeaderData>>,
      default: () => null,
    },
    title: {
      type: String,
      default: '',
    },
    roundCount: {
      type: Number,
      default: 4,
    },
  },
  emits: ['reloadOperation'],
  data(): ComponentData {
    return {
      EventType,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
  },
})
</script>

<style lang="scss" scoped>
.sub-header {
  padding: 0 1.625rem;
  height: 5.1875rem;

  @if $isWsm {
    background-image: linear-gradient(to right, transparent, #0e4e75b5, transparent);
  }

  @if $isSsm {
    &:not(.is-ssm-gc) {
      background-image: linear-gradient(
        to right,
        rgba(79, 83, 135, 0.1) 0%,
        rgba(79, 83, 135, 0.5) 15%,
        rgba(79, 83, 135, 0.5) 85%,
        rgba(79, 83, 135, 0.1) 100%
      );
    }

    &.is-ssm-gc {
      @include background(
        url('#{$path-events}/frozen-championship/playoff/sub-header-bg.avif'),
        contain
      );
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 0.125rem;
    width: 80%;
    left: 50%;
    transform: translate(-50%);

    @if $isWsm {
      background-image: linear-gradient(to right, transparent, #5b6872, transparent);
    }

    @if $isSsm {
      &:not(.is-ssm-gc) {
        background-image: linear-gradient(
          to right,
          transparent 0%,
          rgba(111, 110, 172, 0.5) 15%,
          rgba(111, 110, 172, 0.5) 85%,
          transparent 100%
        );
      }
    }
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}
</style>
