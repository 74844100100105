import { DISCIPLINE_ID } from '@/globalVariables'
import { translate } from '@/plugins/translate'

export const formatTime = (
  days: number,
  hours: number,
  minutes: number,
  seconds: number,
  noTwoDigit: boolean = false,
): string => {
  if (days > 0) {
    return `${days}d ${formatZero(twoDigitNumber(hours, noTwoDigit), 'h')}`
  }
  if (hours > 0) {
    return `${hours}h ${formatZero(twoDigitNumber(minutes, noTwoDigit), 'm')}`
  }
  if (minutes >= 1) {
    return `${minutes}m ${formatZero(twoDigitNumber(seconds, noTwoDigit), 's')}`
  }
  return `${seconds}s`
}

const formatZero = (time: number | string, suffix: string): string => {
  if (time === 0) {
    return ''
  }
  return time + suffix
}

export const convertTime = (time: number): string => {
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time % 3600) / 60)
  const seconds = Math.round(time % 60)
  return [hours, minutes > 9 ? minutes : '0' + minutes, seconds > 9 ? seconds : '0' + seconds]
    .filter(Boolean)
    .join(':')
}

export const formatSeconds = (
  input: number,
  decimals: number = 0,
  formatZeroValue: boolean = false,
): string => {
  // Ak je null alebo undefined alebo zaporne cislo, vrat pomlcku (neplatna hodnota).
  if ((!input && input !== 0) || input < 0) return '-'
  // Ak je nula a nechceme naformatovat nulu, vrat pomlcku (inak naformatuj nulu).
  if (input === 0 && !formatZeroValue) return '-'

  const minutes = Math.trunc(input / 60)
  const factor = Math.pow(10, decimals)
  const seconds = (Math.ceil(+((input % 60) * factor).toFixed(1)) / factor).toFixed(decimals) // zaokruhlenie nahor pomocou ceil

  return [
    minutes,
    seconds
      .split('.')
      .map((part: string, index: number) => (!index && minutes ? part.padStart(2, '0') : part))
      .join('.'),
  ] // ak su minuty, tak sekundy musia mat 2 znaky
    .filter(Boolean) // odstranime minuty, ak su 0
    .join(':') // spojime minuty a sekundy
}

export const formatSecondsToHMS = (sec: number): [number, number, number, number] => {
  const time = Number(sec)
  const days = Math.floor(time / (3600 * 24))
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time % 3600) / 60)
  const seconds = Math.round(time % 60)
  return [days, hours, minutes, seconds]
}

export const formatSecondsToHMSCalculate = (sec: number): [number, number, number, number] => {
  let time = Number(sec)
  const days = Math.floor(time / (3600 * 24))
  time -= days * (3600 * 24)
  const hours = Math.floor(time / 3600)
  time -= hours * 3600
  const minutes = Math.floor((time % 3600) / 60)
  time -= minutes * 60
  const seconds = Math.round(time % 60)
  return [days, hours, minutes, seconds]
}

export const convertToSeconds = (
  days: number = 0,
  hours: number = 0,
  minutes: number = 0,
): number => days * 86400 + hours * 3600 + minutes * 60

export const formatResult = (
  result: number,
  disciplineId: number,
  failStatus: string | null = null,
  formatZeroValue: boolean = false,
): string => {
  // Ak je null alebo undefined alebo zaporne cislo, vrat pomlcku (neplatna hodnota).
  if ((!result && result !== 0) || result < 0) return '-'
  // Ak je nula a nechceme naformatovat nulu, vrat pomlcku (inak naformatuj nulu).
  if (result === 0 && !formatZeroValue) return '-'

  if (failStatus) return failStatus === 'is_temporary' ? '...' : translate(`arena.${failStatus}`)

  switch (disciplineId) {
    case DISCIPLINE_ID.archery:
    case DISCIPLINE_ID.trap: {
      const decimals = 0
      return result.toFixed(decimals) // cislo, 0 desatinnych miest
    }

    case DISCIPLINE_ID.skiJump: {
      const decimals = 1
      return result.toFixed(decimals) // cislo, 1 desatinne miesto
    }

    case DISCIPLINE_ID.biathlon:
    case DISCIPLINE_ID.xCountrySkiing: {
      const decimals = 1
      return formatSeconds(result, decimals, formatZeroValue) // cas, 1 desatinne miesto
    }

    case DISCIPLINE_ID.luge:
    case DISCIPLINE_ID.keirin:
    case DISCIPLINE_ID.kayak: {
      const decimals = 3
      return formatSeconds(result, decimals, formatZeroValue) // cas, 3 desatinne miesta
    }

    default: {
      const decimals = 2
      return formatSeconds(result, decimals, formatZeroValue) // cas, 2 desatinne miesta
    }
  }
}

function twoDigitNumber(number: number, noTwoDigit: boolean = false): number | string {
  // Change state number !== 0 after polish for equipment
  return number < 10 && !noTwoDigit ? `0${number}` : number
}

export const getFormattedDateFromYMD = (dateData: number | string | Date): string => {
  const dateInstance = new Date(dateData)
  const date = dateInstance.getDate()
  const month = dateInstance.getMonth() + 1
  const year = dateInstance.getUTCFullYear()
  return `${twoDigitNumber(date)}.${twoDigitNumber(month)}.${twoDigitNumber(year)}`
}

export const getFormattedTimeFromHMS = (dateData: number | string | Date): string => {
  const dateInstance = new Date(dateData)
  const hours = dateInstance.getHours()
  const minutes = dateInstance.getMinutes()
  return `${twoDigitNumber(hours)}:${twoDigitNumber(minutes)}`
}

export const getRemainingSecondsFromDate = (date: Date): number => {
  return (new Date(date).getTime() - Date.now()) / 1000
}

export const getDaysFromSeconds = (seconds: number): number => {
  return Math.round(Number(seconds) / 3600 / 24)
}

export const getEstimatedDateInSeconds = (date: Date): number => {
  const dateNow = new Date()
  const estimatedDate = new Date(date)
  const dateNowUTC = dateNow.toUTCString()
  const estimatedDateUTC = estimatedDate.toUTCString()

  return Math.abs(new Date(estimatedDateUTC).getTime() - new Date(dateNowUTC).getTime()) / 1000
}

export const timeLeft = (timestamp: number, inSeconds: boolean = false): number =>
  Math.round(Math.abs(Number(new Date()) - timestamp) / (inSeconds ? 1e3 : 1))
