<template>
  <div class="career-branch-locked flex flex-col items-center justify-center">
    <div class="flex flex-col items-center">
      <app-icon icon-name="lock-xxl" />
      <p class="text-34 text-white career-branch-locked-text-discipline">
        {{ $t('career.lockedDiscipline') }}
      </p>
    </div>

    <div class="flex flex-col items-center justify-center career-branch-locked-discipline">
      <p class="text-60 text-texts-standard-important uppercase">
        {{ $translateDiscipline(disciplineId) }}
      </p>
      <p v-if="unlockLevel === 99 || unlockLevel === 1000" class="text-50 text-white">
        Coming soon
      </p>
      <div v-else class="flexing">
        <p class="text-50 text-white">
          {{ $replacePlaceholder($t('career.reachLevel'), '%s', unlockLevel) }}
        </p>
      </div>
    </div>

    <p v-if="temporaryLock" class="text-36 font-bold text-white mt-6">
      {{ $t('career.completedQuests') }}:
      <span class="text-texts-standard-important">{{ completedQuests }}</span>
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CareerBranchLocked',
  props: {
    disciplineId: {
      type: Number,
      default: 0,
    },
    unlockLevel: {
      type: Number,
      default: 0,
    },
    temporaryLock: {
      type: Boolean,
      default: false,
    },
    completedQuests: {
      type: Number,
      default: 0,
    },
  },
})
</script>

<style lang="scss" scoped>
.career-branch-locked {
  width: 100%;
  height: 90%;

  &-text-discipline {
    margin-top: 1rem;
  }

  &-discipline {
    height: 13.938rem;
    width: 83.125rem;
    transform: translate(0, 0);
    margin-top: 1rem;

    @if $isWsm {
      background: linear-gradient(
        90deg,
        rgba(17, 54, 81, 0) 0%,
        rgba(17, 54, 81, 1) 25%,
        rgba(17, 54, 81, 1) 50%,
        rgba(17, 54, 81, 1) 75%,
        rgba(17, 54, 81, 0) 100%
      );
    }

    @if $isSsm {
      background: linear-gradient(
        90deg,
        rgba(#2f3158, 0) 0%,
        rgba(#2f3158, 1) 25%,
        rgba(#4f4d79, 1) 50%,
        rgba(#2f3158, 1) 75%,
        rgba(#2f3158, 0) 100%
      );
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 0.125rem;

      left: 0;
      @if $isWsm {
        background: linear-gradient(
          90deg,
          rgba(106, 139, 195, 0) 0%,
          rgba(106, 139, 195, 1) 50%,
          rgba(106, 139, 195, 0) 100%
        );
      }
      @if $isSsm {
        background: linear-gradient(
          90deg,
          rgba(#d9ecff, 0) 0%,
          rgba(#d9ecff, 1) 50%,
          rgba(#d9ecff, 0) 100%
        );
      }
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  }
}
</style>
