<template>
  <div
    class="level-bag-reward flexing relative"
    :class="[
      `reward-${rewardType}`,
      {
        claimed: isClaimed,
        'clickable-element cursor-pointer': isUnlocked,
        'opacity-50': isLocked,
      },
    ]"
    @click="isUnlocked ? claimReward(rewardType, level) : () => {}"
  >
    <span v-if="isUnlocked" class="claim-button absolute flexing">
      <p class="text-36 text-texts-standard-dark font-bold mb-4">{{ $t('common.claim') }}</p>
    </span>
    <span v-if="(isLocked && level <= Number(userLevel)) || isClaimed" class="info-icon absolute">
      <premium-rounded-icon :type="isClaimed ? 'done' : 'lock'" size="sm" />
    </span>
    <span class="reward-wrapper flexing" :class="{ unlocked: isUnlocked }">
      <rewards
        reward-id="event-box-rewards"
        class="ml-10"
        :class="{ 'opacity-50': isClaimed }"
        :reward-data="formatRewards([reward], 'name', 'value')"
        :icon-size="48"
        :custom-font-size="36"
      />
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { levelBagsEndpoint } from '@/globalVariables'
import { formatRewards } from '@/helpers'

import PremiumRoundedIcon from '@/components/Premium/Accessories/RoundedIcon.vue'
import Rewards from '@/components/Rewards.vue'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type Reward from '@/interfaces/Reward'
import { LevelUpBagType } from '@/interfaces/premium/enums'

export default defineComponent({
  name: 'LevelBagReward',
  components: { Rewards, PremiumRoundedIcon },
  props: {
    rewardType: {
      type: String,
      required: true,
      validator(propName: LevelUpBagType): boolean {
        return [
          LevelUpBagType.Bronze,
          LevelUpBagType.Silver,
          LevelUpBagType.Gold,
          LevelUpBagType.Diamond,
        ].includes(propName)
      },
    },
    rewardStatus: {
      type: String,
      required: true,
      validator(propName: string): boolean {
        return ['lock', 'open', 'claim'].includes(propName)
      },
    },
    reward: {
      type: Object as PropType<Nullable<Reward>>,
      default: () => null,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  emits: ['reloadData'],
  computed: {
    ...mapState(useUserStore, {
      userLevel: 'getLevel',
    }),
    isUnlocked(): boolean {
      return this.rewardStatus === 'open'
    },
    isClaimed(): boolean {
      return this.rewardStatus === 'claim'
    },
    isLocked(): boolean {
      return this.rewardStatus === 'lock'
    },
  },
  methods: {
    formatRewards,
    async claimReward(type: string, level: number): Promise<void> {
      try {
        await this.$axios.put<{}, null>(`${levelBagsEndpoint}/claim`, {
          type,
          level,
        })
      } catch (error: unknown) {
        console.error(error)
      }

      this.$emit('reloadData')
    },
  },
})
</script>

<style lang="scss" scoped>
.level-bag-reward {
  width: 15.875rem;
  height: 6.25rem;
  background-repeat: no-repeat;
  background-size: contain;

  .reward-wrapper {
    height: 3.15rem;
    white-space: nowrap;

    &.unlocked {
      border-style: solid;
      border-width: 0.125rem;
      border-image-slice: 1;
      @if $isWsm {
        background: linear-gradient(
          90deg,
          transparent 0%,
          rgba(11, 162, 14, 1) 30%,
          rgba(11, 162, 14, 1) 50%,
          transparent 100%
        );
        border-image-source: linear-gradient(
          90deg,
          transparent 15%,
          rgba(63, 228, 67, 1) 35%,
          rgba(63, 228, 67, 1) 75%,
          transparent 95%
        );
      }
      @if $isSsm {
        background: linear-gradient(
          90deg,
          transparent 0%,
          rgba(0, 142, 124, 0.8) 30%,
          rgba(0, 142, 124, 0.8) 50%,
          transparent 100%
        );
        border-image-source: linear-gradient(
          90deg,
          transparent 15%,
          #0ee7cc 35%,
          #0ee7cc 75%,
          transparent 95%
        );
      }
    }
  }

  &.reward-bronze {
    background-image: url($path-premium + 'bags/tab/reward-box-bronze.avif');
    &.claimed {
      background-image: url($path-premium + 'bags/tab/reward-box-bronze-claimed.avif');
    }
  }

  &.reward-silver {
    background-image: url($path-premium + 'bags/tab/reward-box-silver.avif');
    &.claimed {
      background-image: url($path-premium + 'bags/tab/reward-box-silver-claimed.avif');
    }
  }

  &.reward-gold {
    background-image: url($path-premium + 'bags/tab/reward-box-gold.avif');
    &.claimed {
      background-image: url($path-premium + 'bags/tab/reward-box-gold-claimed.avif');
    }
  }

  &.reward-diamond {
    background-image: url($path-premium + 'bags/tab/reward-box-diamond.avif');
    &.claimed {
      background-image: url($path-premium + 'bags/tab/reward-box-gold-claimed.avif');
    }
  }

  .claim-button {
    width: 12.5rem;
    height: 4.563rem;
    top: -2.5rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url($path-premium + 'bags/tab/claim-btn.webp');
    animation: bounce 4s ease infinite;
  }

  @keyframes bounce {
    70% {
      transform: translateY(0%);
    }

    80% {
      transform: translateY(-15%);
    }

    90% {
      transform: translateY(0%);
    }

    95% {
      transform: translateY(-7%);
    }

    97% {
      transform: translateY(0%);
    }

    99% {
      transform: translateY(-3%);
    }

    100% {
      transform: translateY(0);
    }
  }

  .info-icon {
    top: -2.5rem;
  }
}
</style>
