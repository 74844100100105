import { TASK_TRACKER_LOCAL_STORAGE, careerTaskTrackerEndpoint } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { usePhaserStore } from '@/store/pinia/map/phaser'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { defineStore } from 'pinia'

import type {
  TaskTrackerApiResponse,
  TaskTrackerQuestResponse,
} from '@/interfaces/responses/career/TaskTrackerApiResponse'
import type { TaskTrackerQuest } from '@/interfaces/Quest'

interface TaskTrackerStoreState {
  taskTrackerQuests: {
    isTrackerFull: boolean
    quests: TaskTrackerQuest[]
  }
}

export const useTaskTrackerStore = defineStore('taskTrackerStore', {
  state: (): TaskTrackerStoreState => ({
    taskTrackerQuests: {
      isTrackerFull: false,
      quests: [] as TaskTrackerQuest[],
    },
  }),
  getters: {
    getTaskTrackerQuests: (
      state: TaskTrackerStoreState,
    ): TaskTrackerStoreState['taskTrackerQuests'] => state.taskTrackerQuests,
    getTaskTrackerCapacityStatus: (
      state: TaskTrackerStoreState,
    ): TaskTrackerStoreState['taskTrackerQuests']['isTrackerFull'] =>
      state.taskTrackerQuests.isTrackerFull,
  },
  actions: {
    async loadTaskTrackerQuests(): Promise<TaskTrackerApiResponse> {
      const response = await internalAxios.get<{}, TaskTrackerApiResponse>(
        careerTaskTrackerEndpoint,
      )

      const trackerActiveStates = JSON.parse(localStorage.getItem(TASK_TRACKER_LOCAL_STORAGE))
      const activeTaskTrackerQuests = response.task_tracker_data.filter(
        (quest: TaskTrackerQuestResponse): string => quest.task_id,
      )
      const data: TaskTrackerStoreState['taskTrackerQuests'] = {
        isTrackerFull: response.is_full,
        quests: activeTaskTrackerQuests.map(
          (quest: TaskTrackerQuestResponse, index: number): TaskTrackerQuest => {
            return {
              careerId: quest.task_id,
              disciplineId: quest.additional?.discipline_id,
              type: quest.type,
              isActive: trackerActiveStates ? trackerActiveStates[index] : false,
              progress: {
                currentValue: quest.progress?.current_value,
                targetValue: quest.progress?.target_value,
                isClaimable: quest.progress?.claimable,
              },
              parameters: quest.parameters ?? null,
              isEmpty: quest?.type === 'empty' || false,
              branchType: quest.additional?.branch_type,
            }
          },
        ),
      }

      const useTutorial = useTutorialStore()
      if (!useTutorial.getIsTutorial) {
        const usePhaser = usePhaserStore()
        usePhaser.getEmitter?.emit('reloadNotifications')
      }
      this.taskTrackerQuests = data

      return response
    },
    async addTrackingTask(careerId: string): Promise<void> {
      await internalAxios.post<{}, TaskTrackerApiResponse, { task_type: string; task_id: string }>(
        careerTaskTrackerEndpoint,
        {
          task_type: 'career_task',
          task_id: careerId,
        },
      )
    },
    async removeTrackingTask(careerId: string): Promise<void> {
      await internalAxios.delete<{}, TaskTrackerApiResponse, { task_id: string }>(
        careerTaskTrackerEndpoint,
        {
          data: {
            task_id: careerId,
          },
        },
      )
    },
  },
})
