<template>
  <div
    v-if="loaded"
    class="bounty-league-wrapper flexing w-full h-full"
    :class="{
      'is-loading': isLoading,
    }"
  >
    <div v-if="!isLoading" class="bounty-league flexing flex-col w-full h-full">
      <div class="bounty-league__header flexing">
        <info-text :text="$te('autumnLeagueCTA')" />

        <div v-if="!isRecalculating" class="menu-info-box flex justify-between items-center">
          <p v-if="isEnded" class="text-32 text-texts-standard-default uppercase font-bold mr-8">
            {{ $te('leagueEnded') }}
          </p>
          <template v-else>
            <p class="text-32 text-texts-standard-default uppercase font-medium mr-8">
              <shrink-text
                :text="$te('timeLeftRound') + ':'"
                :font-size="32"
                :animated-overflow="true"
              />
            </p>
            <app-timer
              v-if="getRoundRemainingTime > 0"
              :time="getRoundRemainingTime"
              @countdown-action="loadLeague"
            />
          </template>
        </div>
      </div>
      <div v-if="isRecalculating" class="flexing flex-col w-full h-full">
        <event-message in-recalculation :timer="config?.date_round_start" />
      </div>
      <template v-else>
        <div class="bounty-league__content flex">
          <!-- <players-table /> -->
          <div class="bounty-league__content-left flexing flex-col">
            <ranking-table-head ranking-type="league" />
            <app-scrollbar
              class="ranking-discipline-global-scroll"
              width="100%"
              height="100%"
              scroll="y"
              slide="y"
            >
              <ranking-row-table
                :rows="formattedLeagueData"
                :table="RankingType.League"
                width="100%"
              />
            </app-scrollbar>
            <ranking-player
              v-if="myLeagueData"
              :player-data="myLeagueData"
              :ranking-type="RankingType.League"
              :hide-rewards="true"
            />
          </div>
          <milestones-table
            :milestones="competitionMilestones.milestones"
            :state="competitionMilestones.state"
          />
        </div>
      </template>
    </div>
    <component-loading v-else class="loading-indicator" />
  </div>
  <component-loading v-else :is-loading="true" height="100%" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
// import { mapState, mapActions } from 'pinia'
import RankingRowTable from '@/components/Rankings/RankingRowTable.vue'
import RankingTableHead from '@/components/Rankings/RankingTableHead.vue'
import RankingPlayer from '@/components/Rankings/RankingPlayer.vue'
import MilestonesTable from '@/components/Events/BoardComponents/BountyLeague/MilestonesTable.vue'
import InfoText from '@/components/InfoText.vue'
import ShrinkText from '@/components/ShrinkText.vue'
import EventMessage from '@/components/Events/EventMessage.vue'
import { useLeagueEventStore } from '@/store/pinia/events/leagueStore'
import { mapActions, mapState } from 'pinia'
import type {
  CompetitionMilestones,
  PlayerInfo,
  PlayerStats,
} from '@/interfaces/responses/events/league/LeagueStateApiResponse'
import type { RankingUser } from '@/interfaces/RankingsInterfaces'
import { RankingType } from '@/interfaces/RankingsInterfaces'
import { useUserStore } from '@/store/pinia/userStore'

interface ComponentData {
  isLoading: boolean
  isRecalculationScreenActive: boolean
  loaded: boolean
  RankingType: typeof RankingType
}

export default defineComponent({
  name: 'BountyLeagueMain',
  components: {
    RankingRowTable,
    RankingTableHead,
    RankingPlayer,
    MilestonesTable,
    InfoText,
    ShrinkText,
    EventMessage,
  },
  data(): ComponentData {
    return {
      loaded: false,
      isLoading: false,
      isRecalculationScreenActive: false,
      RankingType,
    }
  },
  computed: {
    ...mapState(useLeagueEventStore, {
      config: 'getConfig',
      state: 'getState',
    }),
    ...mapState(useUserStore, {
      userId: 'getUserId',
    }),
    formattedLeagueData(): RankingUser[] {
      return this.state?.results?.players.map((player: PlayerStats): RankingUser => {
        const selectedPlayerConfig = this.state.players.find(
          (item: PlayerInfo): boolean => item.user_games_id === player.user_games_id,
        )

        return {
          position: player.position,
          id: player?.user_games_id,
          name: selectedPlayerConfig?.username,
          country: selectedPlayerConfig?.country,
          points: player?.points,
          rewards: player?.rewards,
        }
      })
    },
    myLeagueData(): RankingUser {
      const myPlayer = this.formattedLeagueData?.find(
        (player: RankingUser): boolean => player.id === this.userId,
      )

      return {
        position: myPlayer?.position,
        country: myPlayer?.country,
        username: myPlayer?.name,
        points: myPlayer?.points,
        rewards: myPlayer?.rewards,
      }
    },
    getRoundRemainingTime(): number {
      return this.state?.time_left ?? 0
    },
    isRecalculating(): boolean {
      return this.state?.is_recalculating ?? false
    },
    isEnded(): boolean {
      return false
    },
    competitionMilestones(): CompetitionMilestones {
      return this.state?.competition_milestones ?? null
    },
  },
  async created(): Promise<void> {
    this.loadLeague()
  },
  methods: {
    ...mapActions(useLeagueEventStore, {
      loadConfig: 'loadConfig',
      loadState: 'loadState',
    }),
    async loadLeague(): Promise<void> {
      this.loaded = false

      await this.loadConfig()
      await this.loadState(this.config?.round)

      this.loaded = true
    },
  },
})
</script>

<style lang="scss" scoped>
.bounty-league {
  &.is-loading {
    position: relative;

    .loading-indicator {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 12.5rem;
      color: #ffffff;
    }
  }

  &__header {
    position: relative;
    width: 100%;
    height: 7.438rem;
    margin: 0.25rem 0 0;
    padding-left: 2rem;
    background: linear-gradient(
      to right,
      transparent,
      rgba(74, 77, 111, 0.7),
      rgba(74, 77, 111, 1),
      rgba(74, 77, 111, 1),
      rgba(74, 77, 111, 0.7),
      transparent
    );

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.125rem;
      background: linear-gradient(
        to right,
        transparent,
        rgba(159, 159, 233, 0.7),
        rgba(195, 195, 255, 1),
        rgba(195, 195, 255, 1),
        rgba(159, 159, 233, 0.7),
        transparent
      );
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    .menu-info-box {
      width: 56.25rem;
    }
  }
  &__content {
    width: 100%;
    padding-top: 0.75rem;
    flex: 1 1 0;
    min-height: 0;
    gap: 1.5rem;

    &-left {
      flex: 1 1 auto;
      min-width: 0;

      .ranking-discipline-global-scroll {
        flex: 1 1 auto;
        min-height: 0;
      }
    }
  }

  :deep() {
    .ranking-table-header {
      .pos {
        flex-shrink: 0;
        margin-right: 1rem;
      }

      .name {
        width: 26rem;
        flex-shrink: 0;
      }

      .points {
        width: 10rem;
        flex-shrink: 0;
      }
    }

    .table-row {
      &-position:first-child {
        flex-shrink: 0;
      }

      &-player {
        padding-left: 0;
        width: 26rem;
        flex-shrink: 0;
        overflow: hidden;

        .app-user {
          width: 100%;

          &-country {
            flex-shrink: 0;
          }

          &-flag {
            flex-shrink: 0;
          }

          &-name-wrapper {
            @apply truncate;
            flex: 1 1 auto;
            min-width: 0;

            .app-user-name {
              text-align: left;
            }
          }
        }
      }

      &-content {
        .global-table-league-points {
          width: 10rem;
        }
      }
    }

    .player-position {
      &-position {
        flex-shrink: 0;
      }

      &-player {
        padding-left: 0;
        width: 26rem;
        flex-shrink: 0;
        overflow: hidden;
      }

      &-points {
        width: 10rem;
      }

      &-rewards {
        min-width: 30rem;
        width: 30rem;
        margin-left: 1rem;
      }
    }

    .player-position-position.is-rank-1 {
      @if $isWsm {
        background-image: linear-gradient(to top, #ffef84, #f6c717);
      }
      @if $isSsm {
        background-image: linear-gradient(to top, #daab36, #ffdf91);
      }
    }

    .player-position-position.is-rank-2 {
      @if $isWsm {
        background-image: linear-gradient(to top, #a4a4a4, #fff);
      }
      @if $isSsm {
        background-image: linear-gradient(to top, #c3c3c3, #f5f0f0);
      }
    }

    .player-position-position.is-rank-3 {
      @if $isWsm {
        background-image: linear-gradient(to top, #e77447, #ffb99e);
      }
      @if $isSsm {
        background-image: linear-gradient(to top, #f58d57, #ffb28a);
      }
    }

    .event-message-info {
      height: 19.75rem;
      background: linear-gradient(
        to right,
        transparent 0%,
        rgba(74, 77, 111, 0.7) 10%,
        rgba(74, 77, 111, 1) 50%,
        rgba(74, 77, 111, 0.7) 90%,
        transparent 100%
      );

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 80%;
        height: 0.1875rem;

        @if $isWsm {
          background-image: linear-gradient(to right, transparent, #156aa5, transparent);
        }

        @if $isSsm {
          background-image: linear-gradient(
            to right,
            transparent 0%,
            rgba(195, 195, 255, 1) 30%,
            rgba(195, 195, 255, 1) 70%,
            transparent 100%
          );
        }
      }
    }
  }
}
</style>
