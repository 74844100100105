<template>
  <span class="flexing">
    <img
      v-if="optionData?.icon"
      class="app-select-option-icon"
      :src="pathImages + optionData?.icon"
    />
    <span v-if="optionData?.name" class="select-flag-text line-clamp-2">{{
      optionData?.name
    }}</span>
  </span>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'

interface OptionData {
  name?: string
  icon?: string
}

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'SelectWithFlag',
  props: {
    optionData: {
      type: Object as PropType<Nullable<OptionData>>,
      default: () => null,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
})
</script>

<style>
.select-flag-text {
  font-size: 1.8rem;
}
</style>
