import type { IndicatorConfigInterface } from '@/map-phaser-new/interfaces'

export const indicator: IndicatorConfigInterface = {
  textureKey: 'careerArrow',
  depth: 6,
  animation: {
    y: { value: '-=25', ease: 'Back' },
    yoyo: true,
    repeat: -1,
    duration: 1500,
  },
  originX: 0,
  originY: 0,
}
