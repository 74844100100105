<template>
  <div class="stars-icon flexing justify-between">
    <div
      v-for="(star, i) in starsData"
      :key="i"
      :class="'icon-star-' + showStars(star.id) + '-' + starsSize"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'AppStarsIcon',
  props: {
    starsData: {
      type: Array as PropType<{ id: number }[]>,
      required: true,
    },
    starsSize: {
      type: Number,
      default: 72,
    },
  },

  methods: {
    showStars(id: number): string {
      switch (id) {
        case 0:
          return 'lock'
        case 1:
          return 'empty'
        default:
          return 'full'
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/stars-icons.scss';
</style>
