<template>
  <div class="flex flex-col leading-8">
    <div class="text h-full flexing">
      <div class="first-text" :class="{ 'text-texts-standard-important': isImportant(param) }">
        <p :class="{ 'font-yellow': isImportant(param) }">
          {{ prettyValue }}
        </p>
      </div>
      <p v-show="showMaxValue && param.max_value > 0" class="second-text text-36">
        /{{ prettyLimit }}
      </p>
    </div>

    <animated-number-progress-bar v-if="showProgressBar && param.next_refresh" :param="param" />
  </div>
</template>

<script lang="ts">
import AnimatedNumberProgressBar from '@/components/AnimatedNumberProgressBar.vue'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { AnimatedNumber } from '@/interfaces/global/AnimatedNumber'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  paramValue: number
  paramLimit: number
  animationDuration: number
  animationStepSize: number
  animationStepDuration: number
  animationGoingUp: boolean
}

export default defineComponent({
  name: 'AnimatedNumber',
  components: {
    AnimatedNumberProgressBar,
  },
  props: {
    param: {
      type: Object as PropType<Nullable<AnimatedNumber>>,
      default: () => null,
    },
    showProgressBar: {
      type: Boolean,
      default: true,
    },
    showMaxValue: {
      type: Boolean,
      default: true,
    },
  },
  data(): ComponentData {
    return {
      paramValue: 0,
      paramLimit: 0,
      animationDuration: 1000,
      animationStepSize: 1,
      animationStepDuration: 10,
      animationGoingUp: false,
    }
  },
  computed: {
    prettyValue(): string | number {
      if (this.showMaxValue)
        return this.$filters.$formatNumber(this.$filters.$abbrNumber(this.paramValue))
      else return this.$filters.$formatNumber(this.paramValue)
    },
    prettyLimit(): string | number {
      return this.$filters.$formatNumber(this.$filters.$abbrNumber(this.paramLimit))
    },
  },
  watch: {
    param: {
      handler(newVal: AnimatedNumber, oldVal: AnimatedNumber): void {
        if (Math.abs(newVal.value - oldVal.value) > 1) {
          this.setAnimationParams(oldVal.value, newVal.value)
        } else {
          this.updateValue(newVal.value)
        }
        if (this.showMaxValue) {
          this.updateLimit(newVal.max_value)
        }
      },
      deep: true,
    },
  },
  created(): void {
    this.updateValue(this.param?.value)
    if (this.showMaxValue && this.param?.max_value) this.updateLimit(this.param?.max_value)
  },
  methods: {
    isImportant(param: AnimatedNumber): boolean {
      return this.showMaxValue && param.value >= param.max_value
    },
    updateValue(val: number): void {
      this.paramValue = val || 0
    },
    updateLimit(val: number): void {
      this.paramLimit = val || 0
    },
    setAnimationParams(currentValue: number, newValue: number): void {
      const diff = Math.abs(newValue - currentValue)

      if (diff <= 100) {
        if (diff <= 10) this.animationStepDuration = 1
        else this.animationStepDuration = Math.floor(this.animationDuration / diff)
      } else {
        this.animationStepSize = diff / (this.animationDuration / this.animationStepDuration)
        if (this.animationStepSize < 1) {
          this.animationStepSize = 1
        }
      }

      this.animationGoingUp = currentValue < newValue

      const animateChange = (): void => {
        currentValue = this.setNewValue(currentValue, newValue)
        if (this.isAnimationDone(currentValue, newValue)) {
          clearInterval(intervalId)
          this.updateValue(this.param.value)
        }
      }
      const intervalId = setInterval(animateChange, this.animationStepDuration)
    },
    setNewValue(currentValue: number, newValue: number): number {
      currentValue += this.animationGoingUp ? this.animationStepSize : -this.animationStepSize
      currentValue = Math.floor(currentValue)
      // if remaining difference is less than step value, take all of it
      if (Math.abs(newValue - this.animationStepSize) < this.animationStepSize) {
        currentValue = newValue
      }
      this.updateValue(currentValue)
      return currentValue
    },
    isAnimationDone(currentValue: number, newValue: number): boolean {
      return (
        (this.animationGoingUp && currentValue >= newValue) ||
        (!this.animationGoingUp && currentValue <= newValue)
      )
    },
  },
})
</script>

<style scoped lang="scss">
.font-yellow {
  @if $isWsm {
    color: #f6db26;
  }

  @if $isSsm {
    color: theme('colors.texts.standard.important');
  }
}

@import '@/assets/styles/components/header.scss';
</style>
