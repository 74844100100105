<template>
  <section
    class="clubs-employees-box"
    :class="[
      `box-color-${employee.boxColor}`,
      {
        warning: employee.warning,
        empty: employee.boxEmpty,
        'small-box': !employee.building,
      },
    ]"
  >
    <div v-if="!employee.boxEmpty" class="relative flex flex-col w-full h-full">
      <app-notification
        v-if="employee.rewardsToClaim"
        class="clubs-employees-box-notification"
        :count="employee.rewardsToClaim"
      />
      <header
        v-if="employee.building && !hideTitle"
        class="clubs-employees-box-header text-34 font-bold text-texts-standard-default uppercase"
      >
        <p class="clubs-employees-box-header-title">
          {{ $t(getTitle()) }}
        </p>
      </header>
      <clubs-employees-box-center
        v-if="employee.tier"
        :class="{ 'small-box': !employee.building }"
        :employee-data="employee"
      />
      <clubs-employees-box-footer :footer-data="employee" @hire="hire" @claim-rewards="claim" />
    </div>
    <clubs-employees-box-lock
      v-if="employee.locked"
      :unlock-level="employee.unlockLevel"
      :is-personal="employee.isPersonal"
    />
  </section>
</template>

<script lang="ts">
import ClubsEmployeesBoxCenter from '@/components/Club/ClubsEmployeesBoxCenter.vue'
import ClubsEmployeesBoxFooter from '@/components/Club/ClubsEmployeesBoxFooter.vue'
import ClubsEmployeesBoxLock from '@/components/Club/ClubsEmployeesBoxLock.vue'
import { useEmployeeState } from '@/store/pinia/clubs/employeeStore'
import { useRewardStore } from '@/store/pinia/clubs/rewardStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Employee } from '@/interfaces/clubs/Employees'
import type { ClubRewardResponse } from '@/interfaces/clubs/ClubRewards'

export default defineComponent({
  name: 'ClubsEmployeesBox',
  components: {
    ClubsEmployeesBoxLock,
    ClubsEmployeesBoxCenter,
    ClubsEmployeesBoxFooter,
  },
  props: {
    employee: {
      type: Object as PropType<Employee>,
      default: () => ({
        boxColor: 'blue', // box color: 'blue', 'gold', 'purple', 'green'
        warning: false, // red border around box
        rewardsToClaim: 0, // red notification circle with number in top right corner of the box
        boxEmpty: false, // no employee
        canHire: false, // can I hire this employee?
        unlockLevel: 0, // locked box, level needed to unlock
        building: '', // title in box header. no title => small box format
        tier: 1, // employee tier
        employeeName: '',
        employeeId: 1, // currently 2 option: 1 - man in suit, 2 - man in a jacker
        seconds: 0, // seconds to count down in a timer
        noCountdown: false, // static timer (no countdown, just show the time)
        rewardValue: 0, // employee reward amount
        rewardType: 'money', // employee reward type
        rewardTime: 0, // time needeed to get a reward from this employee; in seconds
        detailBtn: false, // show detail button in bottom part
        validForDays: 0, // for how long is the employee available
        hiringPrice: 0, // amount needed to hire this employee
        hiringCurrency: 'money', // type of resource needed to hire this employee
        isPersonal: false, // is this personal or club employee?
      }),
      validator(obj: Employee): boolean {
        return (
          ['blue', 'gold', 'purple', 'green'].includes(obj.boxColor) &&
          (!obj.tier || [1, 2, 3].includes(obj.tier))
        )
      },
    },
    isClubCenter: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['rewardsClaimed', 'hire'],
  computed: {
    ...mapState(useRewardStore, {
      getAllClubRewards: 'getAllClubRewards',
    }),
  },
  methods: {
    ...mapActions(useEmployeeState, ['hireEmployee']),
    ...mapActions(useRewardStore, ['claimRewards']),
    async claim(): Promise<void> {
      const reward = this.getAllClubRewards.find(
        (reward: ClubRewardResponse): boolean =>
          reward.clubs_employees_id === this.employee.employeeId,
      )?.club_reward_id
      if (!reward) return
      await this.claimRewards(reward)
      this.$emit('rewardsClaimed')
    },
    hire(): void {
      this.$emit('hire')
      this.hireEmployee({
        employee: this.employee,
        reloadAll: this.isClubCenter,
      })
    },
    getTitle(): string {
      if (this.isClubCenter) {
        return this.employee.building
      }
      return 'club.' + (this.employee.employeeType === 'club' ? 'clubEmployee' : 'personalEmployee')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/club/employees.scss';
</style>
