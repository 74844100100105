import { clubDailyTaskContributionsEndpoint, clubDailyTaskEndpoint } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import type { ClubDailyTasksContributor, ClubDailyTask } from '@/interfaces/clubs/ClubDailyTasks'

import type {
  ClubDailyTasksResponse,
  ClubDailyTasksContributorResponse,
} from '@/interfaces/responses/club/ClubDailyTasksResponses'
interface ClubDailyTasksState {
  clubDailyTask: ClubDailyTask
  clubDailyTaskContributions: ClubDailyTasksContributor[]
}

export const useClubsDailyTasks = defineStore('clubsDailyTasks', {
  state: (): ClubDailyTasksState => ({
    clubDailyTask: null,
    clubDailyTaskContributions: null,
  }),
  getters: {
    getClubDailyTask(): ClubDailyTasksState['clubDailyTask'] {
      return this.clubDailyTask
    },
    getClubDailyTaskContributions(): ClubDailyTasksState['clubDailyTaskContributions'] {
      return this.clubDailyTaskContributions
    },
  },
  actions: {
    async loadClubDailyTask(): Promise<void> {
      const response = await internalAxios.get<{}, ClubDailyTasksResponse>(clubDailyTaskEndpoint)
      const data = {
        id: response.club_daily_task_id,
        state: response.state,
        timeRemaining: response.time_remaining,
        progress: {
          currentValue: response.stats.current_value,
          milestones: response.stats.progress_milestones,
        },
        task: {
          taskId: response.task.daily_task_id,
          description: response.task.parameters.description,
          rewards: response.task.daily_tasks_rewards,
          type: response.task.parameters.contribution_type,
        },
      }

      this.clubDailyTask = data
    },
    async loadClubDailyTaskContributions(): Promise<void> {
      const response = await internalAxios.get<{}, ClubDailyTasksContributorResponse[]>(
        clubDailyTaskContributionsEndpoint,
      )
      const data = response.map(
        (contributor: ClubDailyTasksContributorResponse): ClubDailyTasksContributor => {
          return {
            username: contributor.username,
            country: contributor.country,
            value: contributor.value,
            type: contributor.contribution_type,
            eventBadges: contributor.event_badges ?? null,
          }
        },
      )

      this.clubDailyTaskContributions = data
    },
  },
})
