import { MECHANIC_EVENT } from '@/globalVariables'
import EventsTeaser from '@/components/Events/TeaserComponents/TeaserMain.vue'
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import EventsMainView from '@/views/events/EventsMainView.vue'
import TasksEvent from '@/views/events/TasksEvent.vue'
import type { RouteRecordCustom } from './RouteInterface'

// WEB ROUTES
export const eventRouterWeb: RouteRecordCustom[] = [
  {
    path: '/events/events-teaser-web',
    component: EventsTeaser,
    name: 'EventsTeaserWeb',
    components: {
      header: HeaderComponent,
      default: EventsTeaser,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/events-playoff-web',
    component: EventsMainView,
    name: 'EventsPlayOffWeb',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/events-taskchain-web',
    component: EventsMainView,
    name: 'EventsTaskchainWeb',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/events-board-web',
    component: EventsMainView,
    name: 'EventsBoardWeb',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/events-carnival-stand-web',
    component: EventsMainView,
    name: 'EventsCarnivalStandWeb',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/events-milestones-web',
    component: EventsMainView,
    name: 'EventsMilestonesWeb',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/events-luckyWheel-web',
    component: EventsMainView,
    name: 'EventsLuckyWheelWeb',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/events-pass-web',
    component: EventsMainView,
    name: 'EventsPassesWeb',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/events-offers-web',
    component: EventsMainView,
    name: 'EventsOffersWeb',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/warehouse-web',
    component: EventsMainView,
    name: 'EventsWarehouseWeb',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/overview-web',
    component: EventsMainView,
    name: 'EventsOverviewWeb',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/training-web',
    component: EventsMainView,
    name: 'EventsTrainingWeb',

    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/arena-web',
    component: EventsMainView,
    name: 'EventsArenaWeb',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/rankings-web',
    component: EventsMainView,
    name: 'EventsRankingsWeb',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/tasks/event-web',
    name: 'TasksEventWeb',
    components: {
      header: HeaderComponent,
      default: TasksEvent,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
]

// MOBILE ROUTES
export const eventRouterMobile: RouteRecordCustom[] = [
  {
    path: '/events/events-teaser',
    component: EventsTeaser,
    name: 'EventsTeaser',
    components: {
      header: HeaderComponent,
      default: EventsTeaser,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/events-playoff',
    component: EventsMainView,
    name: 'EventsPlayOff',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/events-taskchain',
    component: EventsMainView,
    name: 'EventsTaskchain',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/events-board',
    component: EventsMainView,
    name: 'EventsBoard',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/events-carnival-stand',
    component: EventsMainView,
    name: 'EventsCarnivalStand',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/events-milestones',
    component: EventsMainView,
    name: 'EventsMilestones',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/events-luckyWheel',
    component: EventsMainView,
    name: 'EventsLuckyWheel',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/events-pass',
    component: EventsMainView,
    name: 'EventsPasses',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/events-offers',
    component: EventsMainView,
    name: 'EventsOffers',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/warehouse',
    component: EventsMainView,
    name: 'EventsWarehouse',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/overview',
    component: EventsMainView,
    name: 'EventsOverview',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/training',
    component: EventsMainView,
    name: 'EventsTraining',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/arena',
    component: EventsMainView,
    name: 'EventsArena',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/events/rankings',
    component: EventsMainView,
    name: 'EventsRankings',
    components: {
      header: HeaderComponent,
      default: EventsMainView,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
  {
    path: '/tasks/event',
    name: 'TasksEvent',
    components: {
      header: HeaderComponent,
      default: TasksEvent,
    },
    meta: { requiresMechanic: MECHANIC_EVENT, requiresAuth: true },
  },
]
