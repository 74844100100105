<template>
  <main class="training-main flex flex-col w-full h-full safe-area">
    <training-header />
    <training-sub-header />
    <training-body />
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TrainingBody from './TrainingBody.vue'
import TrainingHeader from './TrainingHeader.vue'
import TrainingSubHeader from './TrainingSubHeader.vue'

export default defineComponent({
  name: 'TrainingMain',
  components: {
    TrainingHeader,
    TrainingSubHeader,
    TrainingBody,
  },
})
</script>
