import { translate } from '@/plugins/translate'
import { GameType } from '@/globalVariables'

type MetaManagerResult = {
  title: string
  description: void
  keywords: void
}

export const metaManager = (): MetaManagerResult => {
  const titleText = 'homepage.pageTitle'
  const descriptionText = 'homepage.beChampionTodayBody'
  const keywordsTexts = [
    'homepage.discipline1',
    'homepage.discipline2',
    'homepage.discipline3',
    'homepage.discipline4',
    'homepage.discipline5',
    'homepage.discipline6',
    'homepage.discipline7',
    'homepage.discipline8',
    'homepage.discipline9',
    'homepage.discipline10',
  ]

  if (import.meta.env.VITE_GAME === GameType.Summer) {
    document.title = `Summer Sports Mania - ${
      translate(titleText)?.length > 0 ? translate(titleText) : 'Free online summer sports game'
    }`
  } else if (import.meta.env.VITE_GAME === GameType.Winter) {
    document.title = `Winter Sports Mania - ${
      translate(titleText)?.length > 0 ? translate(titleText) : 'Free online winter sports game'
    }`
  }

  const title = document.title

  document
    .getElementsByTagName('meta')
    .namedItem('description')
    .setAttribute('content', translate(descriptionText))

  document
    .getElementsByTagName('meta')
    .namedItem('keywords')
    .setAttribute(
      'content',
      keywordsTexts.map((keyword: string): string => translate(keyword)).join(','),
    )

  return { title }
}
