<template>
  <footer
    v-if="!hasClub"
    class="club-milestones-footer-lock w-full flex items-center justify-between"
  >
    <aside class="lock-info text-28 text-texts-standard-default flex items-center">
      <app-icon icon-name="info-rounded" />
      <p class="club-milestones-footer-lock-text text-left ml-5">
        {{ $te('onlyForClubMilestone') }}
      </p>
    </aside>
    <span class="trick">
      <notification-tooltip v-if="!hasMechanic(MECHANIC_CLUB)" class="tricky">
        {{
          $replacePlaceholder(
            $te('commonMainCareerTaskRequired'),
            '%s',
            getMechanicUnlock(MECHANIC_CLUB).toString(),
          )
        }}
      </notification-tooltip>
      <app-button
        btn-type="secondary"
        :btn-text="$te('clubs')"
        btn-size="sm"
        :disabled="!hasMechanic(MECHANIC_CLUB)"
        @click="$router.push({ name: $getWebView('ClubsAvailable') })"
      />
    </span>
  </footer>
  <footer v-else class="club-milestones-footer flex items-center">
    <aside class="footer-summary h-full">
      <p class="text-50 font-bold gradient-text-gold text-left uppercase">
        {{ $te('sumRewards') }}
      </p>
      <p class="text-texts-standard-default footer-summary-text text-left">
        {{ $te('taskEndofEvent') }}
      </p>
    </aside>
    <section class="footer-rewards relative h-full">
      <p class="gradient-text-gold font-bold text-40 uppercase">
        {{ $te('commonRewards') }}
      </p>
      <div class="wrapper-rewards flex items-center justify-center flex-wrap">
        <article
          v-for="(reward, i) in combinedRewards"
          :key="i"
          class="text-texts-standard-important text-40 font-bold rewards flexing"
        >
          <p class="mr-2">{{ $filters.$formatNumber(reward.value) }}</p>
          <app-main-icon :icon-size="56" :icon-name="reward.type" />
        </article>
      </div>
    </section>
    <aside class="footer-recieve absolute">
      <div v-if="progressingEndsIn > 0" class="text-40 event-end">
        <p class="gradient-text-gold uppercase">{{ $t('event.eventEnd') }}</p>
        <app-timer :time="progressingEndsIn" />
      </div>
      <app-button
        v-else-if="rewardingEndsIn > 0 && !isClubMilestoneClaimed"
        btn-type="confirm"
        :btn-text="$te('commonClaim')"
        btn-size="sm"
        :disabled="!currentMilestone || clubClaimButtonLock[currentMilestone]"
        @click="claim"
      />
      <div v-else class="rewards-claimed uppercase font-bold text-36 flexing">
        <p class="gradient-text-light">{{ $te('commonRewardsClaimed') }}</p>
      </div>
    </aside>
  </footer>
</template>

<script lang="ts">
import { MECHANIC_CLUB } from '@/globalVariables'
import { toUnique } from '@/helpers/array'
import { useMilestonesStore } from '@/store/pinia/events/milestonesStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'
import { MilestoneType } from '@/interfaces/events/Milestones'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import NotificationTooltip from '@/components/NotificationTooltip.vue'
import type MilestonesConfigApiResponse from '@/interfaces/responses/events/milestones/MilestonesConfigApiResponse'

interface CombinedReward {
  type: MilestonesConfigApiResponse['branches'][0]['milestones'][0]['reward'][0]['type']
  value: MilestonesConfigApiResponse['branches'][0]['milestones'][0]['reward'][0]['value']
}

interface ComponentData {
  MECHANIC_CLUB: typeof MECHANIC_CLUB
  clubClaimButtonLock: Record<number, boolean>
}

export default defineComponent({
  name: 'ClubMilestonesFooter',
  components: {
    NotificationTooltip,
  },
  data(): ComponentData {
    return {
      MECHANIC_CLUB,
      clubClaimButtonLock: {},
    }
  },
  computed: {
    ...mapState(useUserStore, {
      hasClub: 'getHasClub',
    }),
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
      getMechanicUnlock: 'getMechanicUnlock',
    }),
    ...mapState(useMilestonesStore, {
      progressingEndsIn: 'getProgressingEndsIn',
      rewardingEndsIn: 'getRewardingEndsIn',
      clubTasks: 'getClubBranchTasks',
      currentMilestone: 'getCurrentMilestoneNumber',
      isClubMilestoneClaimed: 'isClubMilestoneClaimed',
    }),
    combinedRewards(): CombinedReward[] {
      // Vytiahneme si vsetky mozne typy rewardov.
      const rewardTypes: CombinedReward['type'][] = toUnique(
        this.clubTasks
          .map(
            ({
              reward: rewards,
            }: MilestonesConfigApiResponse['branches'][0]['milestones'][0]): MilestonesConfigApiResponse['branches'][0]['milestones'][0]['reward'][0]['type'][] =>
              rewards.map(
                ({
                  type,
                }: MilestonesConfigApiResponse['branches'][0]['milestones'][0]['reward'][0]): MilestonesConfigApiResponse['branches'][0]['milestones'][0]['reward'][0]['type'] =>
                  type,
              ),
          )
          .flat(),
      )

      // Vyrobime si strukturu pre CombinedReward[] pricom rovno aj najdeme sumu vsetkych dosiahnutych rewardov podla jednotlivych typov.
      const combinedRewards: CombinedReward[] = rewardTypes.map(
        (type: CombinedReward['type']): CombinedReward => ({
          type,
          value: this.clubTasks.reduce(
            (
              total: number,
              { reward: rewards }: MilestonesConfigApiResponse['branches'][0]['milestones'][0],
              index: number,
            ): number =>
              total +
              (index <= this.currentMilestone - 1
                ? rewards.find(
                    (
                      reward: MilestonesConfigApiResponse['branches'][0]['milestones'][0]['reward'][0],
                    ): boolean => reward.type === type && !isNaN(reward.value) && reward.value > 0,
                  )?.value ?? 0
                : 0),
            0,
          ),
        }),
      )

      return combinedRewards
    },
  },
  methods: {
    ...mapActions(useMilestonesStore, ['claimRewards']),
    async claim(): Promise<void> {
      if (this.clubClaimButtonLock[this.currentMilestone]) return
      this.clubClaimButtonLock[this.currentMilestone] = true

      await this.claimRewards({ milestone: this.currentMilestone, type: MilestoneType.Club })
    },
  },
})
</script>

<style lang="scss" scoped>
.club-milestones-footer {
  height: 12.8125rem;

  .footer-summary {
    padding-left: 2.125rem;
    padding-top: 0.6rem;
    @include background(url('#{$path-events}shared/milestones/milestones-club-all.avif'), contain);
    width: 67.0625rem;

    &-text {
      width: 30.875rem;
      height: 5.9375rem;
      font-size: 1.75rem;
      line-height: 1.8rem;
    }
  }

  .footer-rewards {
    @include background(
      url('#{$path-events}shared/milestones/milestones-club-rewards.avif'),
      contain
    );
    width: 64.8125rem;
    right: 25rem;

    p {
      padding-top: 0.3rem;
    }

    .wrapper-rewards {
      width: 90%;
      height: 8rem;

      .rewards {
        margin-left: 2rem;
        height: 3.25rem;
      }
    }
  }

  .footer-recieve {
    right: 3.375rem;

    .rewards-claimed {
      background: linear-gradient(to right, transparent, #b79c22, transparent);
      width: 29.75rem;
      height: 3.9375rem;

      &:before,
      &:after {
        position: absolute;
        content: '';
        width: 100%;
        background: linear-gradient(to right, transparent, #dbba27, transparent);
        height: 0.1875rem;
        left: 50%;
        transform: translate(-50%);
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }
    }
  }

  &-lock {
    padding: 0 3.875rem;
    @include background(
      url('#{$path-events}shared/milestones/club-lock-footer.avif'),
      contain,
      left
    );
    height: 12.8125rem;

    &-text {
      width: 75rem;
    }
  }

  &-lock {
    .trick {
      position: relative;
      z-index: 100;

      &:hover {
        .tricky {
          visibility: visible;
        }
      }

      .tricky {
        position: absolute;
        top: -7rem;
        left: 50%;
        visibility: hidden;
      }
    }
  }
}
</style>
