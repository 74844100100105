<template>
  <div
    v-tippy="{
      theme: 'WSM',
      content: '',
      placement: 'right',
      textAlign: 'center',
    }"
    class="lab-icon-wrapper flexing"
    :class="`cell-${cellType}`"
  >
    <div
      :class="[
        {
          'camps--default': iconData.skillType === SkillType.Camps,
        },
        {
          'camps--active':
            iconData.skillType === SkillType.Camps &&
            !iconData.locked &&
            iconData.state === RepeatableStates.RepeatableActivated,
        },
        {
          'camps--locked':
            iconData.skillType === SkillType.Camps &&
            (iconData.locked || iconData.state === RepeatableStates.RepeatableDisabled),
        },
      ]"
    >
      <div class="lab-icon" :class="iconClass" />
    </div>
    <app-main-icon v-if="iconData.gemOnly" :icon-size="48" icon-name="gems" class="lab-icon-gems" />
    <app-icon
      v-if="isActiveResearch && hasOngoingResearchTree"
      icon-name="time"
      class="active-research-timer"
    />
    <div v-if="iconData.isNew" class="new-reaserch-cell flexing">
      <p class="text-texts-standard-default text-28 font-bold">NEW</p>
    </div>

    <app-icon v-if="iconData.locked" icon-name="lock-sm" class="absolute" />
    <app-icon
      v-if="iconData.state === RepeatableStates.RepeatableDisabled"
      icon-name="blocked"
      class="absolute"
    />
    <div
      v-if="!reasearchComplete && !iconData.locked && iconData.skillType !== SkillType.Camps"
      class="lab-progress"
    >
      <div
        v-for="field in iconData.maxLevel"
        :key="field"
        class="lab-progress-field"
        :class="[
          { 'field-once': iconData.maxLevel === 1 },
          { 'field-complete': !(countOfComplete < field) },
        ]"
      />
    </div>
    <p
      v-if="iconData.locked && iconData.unlockedAfterLevel"
      class="text-texts-standard-additional text-29 absolute level-info"
    >
      {{ `LVL ${iconData.unlockedAfterLevel}` }}
    </p>

    <app-timer
      v-if="remainingTime"
      :time="remainingTime"
      class="lab-cell-timer absolute"
      @countdown-action="$emit('reloadData')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { RepeatableStates } from '@/interfaces/lab/States'
import { SkillType, CellType, type Icon } from '@/interfaces/lab/Cell'
import { getEstimatedDateInSeconds } from '@/helpers'
import type { Nullable } from '@/interfaces/utils'
import { mapState } from 'pinia'
import { useLabStore } from '@/store/pinia/labStore'

interface ComponentData {
  RepeatableStates: typeof RepeatableStates
  SkillType: typeof SkillType
  isActiveResearch: boolean
}

export default defineComponent({
  name: 'LabIcons',
  props: {
    iconData: {
      type: Object as PropType<Nullable<Icon>>,
      default: () => null,
    },
    hasOngoingResearchTree: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['reloadData'],
  data(): ComponentData {
    return {
      RepeatableStates,
      SkillType,
      isActiveResearch: false,
    }
  },
  computed: {
    ...mapState(useLabStore, {
      currentResearchCell: 'getCurrentResearchCell',
    }),
    cellType(): CellType {
      if (
        this.iconData.gemOnly &&
        this.iconData.maxLevel !== this.iconData.currentLevel &&
        !this.iconData.locked
      )
        // bunka ktora sa odomyka za gemy
        return CellType.Gem
      // zamknuta bunka
      else if (this.iconData.locked) return CellType.Unavailable
      // bunka ktora ma vyskumane vsetky dostupne levely
      else if (this.iconData.maxLevel === this.iconData.currentLevel) return CellType.Complete
      // klasicka bunka
      else return CellType.Basic
    },
    remainingTime(): number {
      if (!this.iconData.estimatedDate) return 0

      return getEstimatedDateInSeconds(this.iconData.estimatedDate)
    },
    reasearchComplete(): boolean {
      return this.iconData.maxLevel === this.iconData.currentLevel
    },
    countOfComplete(): number {
      return (
        parseInt(this.iconData.maxLevel.toString()) -
        (parseInt(this.iconData.maxLevel.toString()) -
          parseInt(this.iconData.currentLevel.toString()))
      )
    },
    iconClass(): string {
      if (this.iconData.skillType === SkillType.Camps) {
        return `camp-${this.iconData.icon}`
      } else if (this.reasearchComplete) {
        return this.iconData.isIconRepeatable
          ? `lab-dark-${this.iconData.icon}--repeatable`
          : `lab-dark-${this.iconData.icon}`
      } else if (this.iconData.locked) {
        return this.iconData.isIconRepeatable
          ? `lab-icon--locked lab-${this.iconData.icon}--repeatable`
          : `lab-icon--locked lab-${this.iconData.icon}`
      } else {
        return this.iconData.isIconRepeatable
          ? `lab-${this.iconData.icon}--repeatable`
          : `lab-${this.iconData.icon}`
      }
    },
  },
  watch: {
    iconData(): void {
      if (this.isActiveResearch) {
        this.handleActiveResearchCell()
      }
    },
  },
  mounted(): void {
    this.handleActiveResearchCell()
  },
  methods: {
    handleActiveResearchCell(): void {
      this.isActiveResearch = false // reset
      if (this.currentResearchCell) {
        if (this.currentResearchCell.skillType !== SkillType.Research) return

        if (
          this.currentResearchCell.researchTimer > 0 &&
          this.currentResearchCell.icon === this.iconData.icon &&
          this.currentResearchCell.isIconRepeatable === this.iconData.isIconRepeatable
        ) {
          this.isActiveResearch = true
          this.hideTimerAfterDelay(this.currentResearchCell.researchTimer)
        }
      }
    },
    hideTimerAfterDelay(delay: number): void {
      setTimeout((): void => {
        this.isActiveResearch = false
      }, delay * 1000)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';

.lab-icon-wrapper {
  position: relative;
  background: url($path-lab + 'lab-cells-bgs.avif?v2');
  width: 7.5rem;
  height: 7.5rem;
  background-size: cover;
  flex-direction: column;
  z-index: 1;

  &.cell-basic {
    background-position: -7.5rem 0rem;
  }

  &.cell-complete {
    background-position: -15rem 0rem;
  }

  &.cell-gem {
    background-position: -22.5rem 0rem;
  }

  &.cell-unavailable {
    background-position: 0rem 0rem;
  }

  .camps {
    &--default,
    &--locked {
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: url($path-lab + 'lab-camps-flag-inactive.avif') center no-repeat;
        background-size: contain;
        z-index: 1;
      }
    }

    &--active {
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: url($path-lab + 'lab-camps-flag-active.avif') center no-repeat;
        background-size: contain;
      }
    }

    &--locked {
      .lab-icon {
        opacity: 0.5;
        filter: brightness(0.5) grayscale(0.5);
      }
    }
  }

  .lab-icon {
    height: 5.875rem;
    width: 5.875rem;
    background-size: contain;
    background-repeat: no-repeat;

    &--locked {
      opacity: 0.2;
    }
  }

  .lab-icon-gems {
    position: absolute;
    bottom: 0;
    right: -1rem;
    z-index: 1;
  }

  .new-reaserch-cell {
    width: 4.563rem;
    height: 1.875rem;
    background-image: linear-gradient(to top, #ff0606, #ff1a1a);
    position: absolute;
    right: 0rem;
    top: 0.75rem;
  }

  @if $isSsm {
    @for $i from 1 through 119 {
      .lab-icon_#{$i} {
        background-image: url($path-images + "lab/icons/cells/individual/light/icon_"+#{$i}+".avif");
      }

      .lab-dark-icon_#{$i} {
        background-image: url($path-images + "lab/icons/cells/individual/dark/icon_"+#{$i}+".avif");
      }

      .lab-icon_#{$i}--repeatable {
        background-image: url($path-images + "lab/icons/cells/repeatable/light/icon_"+#{$i}+".avif");
      }

      .lab-dark-icon_#{$i}--repeatable {
        background-image: url($path-images + "lab/icons/cells/repeatable/dark/icon_"+#{$i}+".avif");
      }
    }
  }

  @if $isWsm {
    @for $i from 1 through 119 {
      .lab-icon_#{$i} {
        background-image: url($path-images + "lab/icons/cells/light/icon_"+#{$i}+".avif");
      }

      .lab-dark-icon_#{$i} {
        background-image: url($path-images + "lab/icons/cells/dark/icon_"+#{$i}+".avif");
      }
    }
  }

  $camps: (
    'AUT',
    'BEL',
    'CAN',
    'COL',
    'CYP',
    'CZE',
    'DEU',
    'DNK',
    'ESP',
    'FIN',
    'FRA',
    'GBR',
    'GRC',
    'HRV',
    'HUN',
    'CHE',
    'CHN',
    'ITA',
    'JPN',
    'KOR',
    'LVA',
    'MCO',
    'MEX',
    'NLD',
    'NOR',
    'POL',
    'PRT',
    'QAT',
    'RUS',
    'SVN',
    'SWE',
    'TUR',
    'USA'
  );

  @each $camp in $camps {
    .camp-icon_#{$camp} {
      height: 6.5rem;
      width: 7.5rem;
      background-image: url($path-images + "lab/icons/camps/icon_"+#{$camp}+".avif");
      background-size: contain;
    }
  }

  .lab-progress {
    position: relative;
    display: flex;

    &-field {
      width: 1.125rem;
      height: 0.25rem;
      background: #153343;
      margin: -0.313rem 0.125rem 0;
      transform: $skew-value;

      &.field-once {
        width: 1.688rem;
      }

      &.field-complete {
        background: #f8fbff;
      }
    }
  }

  .active-research-timer {
    position: absolute;
    top: 0.5rem;
    left: -0.5rem;

    &:before {
      content: '';
      position: absolute;
      background: #091b33;
      border-radius: 50%;
      width: 90%;
      height: 90%;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
}

.footer-lab-icon-wrapper,
.tippy-content {
  .active-research-timer {
    display: none;
  }
}

.level-info {
  bottom: 0.5rem;
}
.lab-cell-timer {
  transform: scale(0.8) $skew-value;
  bottom: -2rem;
  left: -3.5rem;
  z-index: 1;
}
</style>
