import { StageType } from '@/interfaces/Tutorial'
import type { TutorialStage } from '@/interfaces/Tutorial'

/**
  Tutorial for discipline unlocked
 */
export default class TutorialUnlockMapItems {
  stages(): TutorialStage[] {
    return [
      // narative saying you unlocked a new discipline
      {
        id: 68,
        name: 'unlockMapItemsNarrative',
        type: StageType.Narrative,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        texts: [],
        loadRewardedItems: true,
      },
      // focus on arena building with gsap animation
      {
        id: 69,
        name: 'clickOnMapItem',
        type: StageType.Focus,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        clientId: ['item'],
        texts: [],
      },
    ]
  }
}
