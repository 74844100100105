<template>
  <teleport to="#app">
    <div class="popup-overlay fixed top-0 left-0" />
    <section
      class="info-popup-box boss-fight-wrapper absolute border-1 bordered-md"
      :class="{ 'boss-fight-wrapper--desktop': !$isMobile() }"
    >
      <div class="boss-fight-wrapper__inner-container">
        <boss-fight-header :discipline-id="bossInfo.discipline_id" @close="$emit('close', false)" />
        <section class="boss-fight-body relative flexing">
          <boss-fight-info :boss-info="bossInfo" />
          <boss-fight-info :boss-info="bossInfo" :boss="true" />
        </section>
        <boss-fight-footer :match="bossInfo.played" @minigame="playMiniGame()" />
      </div>
    </section>
  </teleport>
</template>

<script lang="ts">
import { minigameUrl } from '@/globalVariables'
import { openMiniGame } from '@/helpers'
import type TaskchainStateApiResponse from '@/interfaces/responses/events/TaskchainStateApiResponse'
import { useModeStore } from '@/store/pinia/modeStore'
import { mapState } from 'pinia'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import BossFightFooter from '../BossFight/BossFightFooter.vue'
import BossFightHeader from '../BossFight/BossFightHeader.vue'
import BossFightInfo from '../BossFight/BossFightInfo.vue'
import type { Nullable } from '@/interfaces/utils'

export default defineComponent({
  components: {
    BossFightInfo,
    BossFightFooter,
    BossFightHeader,
  },
  props: {
    bossInfo: {
      type: Object as PropType<Nullable<TaskchainStateApiResponse['boss_data']>>,
      default: () => null,
    },
  },
  emits: ['close'],
  computed: {
    ...mapState(useModeStore, { modes: 'getMode' }),
  },
  methods: {
    playMiniGame(): void {
      openMiniGame(
        minigameUrl,
        {
          params: {
            mode: this.modes.event_boss_fight,
            discipline_id: this.bossInfo.discipline_id,
          },
        },
        this.$router,
      )
      this.$emit('close')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';
@import '@/assets/styles/components/popups.scss';

.boss-fight-wrapper {
  width: 91.125rem;
  height: 53.625rem;
  @include background(
    url('#{$path-events}shared/taskchain/tasks/bossFight/bossfight-basic-bg.avif'),
    contain
  );
  left: 50%;
  top: 50%;

  &--desktop {
    transform: translate(-50%, -50%) $scale-default;
  }

  &__inner-container {
    overflow: hidden;
    // subtract the width of the window borders
    width: calc(100% - 0.126rem);
    height: 100%;
    margin: 0 auto;
  }

  .boss-fight-body {
    height: 39.3125rem;
  }
}
</style>
