<template>
  <img :src="activeImage" class="image-ad text-wrapper-box" />
</template>

<script lang="ts">
import type { Nullable } from '@/interfaces/utils'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

interface ComponentData {
  interval: Nullable<ReturnType<typeof setInterval>>
  activeIndex: number
}

export default defineComponent({
  name: 'TeaserDynamicImage',
  props: {
    imageData: {
      type: Array as PropType<string[]>,
      required: true,
    },
    intervalValue: {
      type: Number,
      default: 3000,
    },
  },
  data(): ComponentData {
    return {
      interval: null,
      activeIndex: 0,
    }
  },
  computed: {
    activeImage(): string {
      return this.imageData[this.activeIndex]
    },
  },
  mounted(): void {
    this.interval = setInterval(this.updateImageValue, this.intervalValue)
  },
  beforeUnmount(): void {
    clearInterval(this.interval)
  },
  methods: {
    updateImageValue(): void {
      const length = this.imageData.length
      if (length === 1) return
      const newIndex = this.activeIndex + 1
      if (newIndex >= length) {
        this.activeIndex = 0
      } else {
        this.activeIndex = newIndex
      }
    },
  },
})
</script>

<style scoped lang="scss">
.text-wrapper {
  padding: 1rem 3rem;

  &.reverse {
    .image-ad {
      margin: unset;
      margin-right: 3rem;
    }
  }

  .image-ad {
    margin-left: 3rem;
  }

  &-box {
    width: 18.5625rem;
    height: 9.375rem;
  }
}
</style>
