<template>
  <tippy
    theme="WSM"
    :placement="isTier3Pass ? 'left' : 'top'"
    :max-width="isTier3Pass ? '55rem' : '27.5rem'"
  >
    <app-icon icon-name="info-70" />
    <template #content>
      <p v-if="isTier3Pass">
        {{ $te('eventPassInfo1') }}<br />
        {{ $te('eventPassInfo2') }}<br />
        {{ $te('eventPassInfo3') }}
      </p>
      <p>
        {{
          $replacePlaceholder(
            $replacePlaceholder($te('eventPassDurationTooltip'), '{datetime}', getFormattedEndDate),
            '{pass}',
            $te(`${$route.query?.pass}pass`),
          )
        }}
      </p>
    </template>
  </tippy>
</template>

<script lang="ts">
import { getFormattedDateFromYMD, getFormattedTimeFromHMS } from '@/helpers'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PassesInfoElement',
  computed: {
    ...mapState(usePremiumStore, {
      eventPass: 'getEventPass',
    }),
    ...mapState(useEventInfoStore, {
      eventEnd: 'getEventEnd',
    }),
    getPassMaxTier(): number {
      return this.eventPass.length
    },
    isTier3Pass(): boolean {
      return this.getPassMaxTier === 3
    },
    getFormattedEndDate(): string {
      if (!this.eventEnd) return ''
      const date = getFormattedDateFromYMD(this.eventEnd)
      const time = getFormattedTimeFromHMS(this.eventEnd)
      return `${date} ${time}`
    },
  },
})
</script>
