<template>
  <section v-if="textChanges" class="sub-header">
    <p class="text-texts-standard-default text-30 flexing">
      <app-icon icon-name="info-rounded" class="ml-4 mr-4" />
      {{
        $replacePlaceholder(
          $replacePlaceholder(
            $te('topPlayersQualifyMessage'),
            '{masters}',
            textChanges.masters_playoff_name,
          ),
          '%s',
          textChanges?.playoff_advancing ? textChanges.playoff_advancing.toString() : '',
        )
      }}
    </p>
    <app-timer v-if="mastersStart !== null && timeEnd" :time="timeEnd" />
    <section v-else class="placeholder" />
  </section>
</template>

<script lang="ts">
import type { PlayoffTextExchange } from '@/interfaces/events/MastersPlayoffInterfaces'
import { useMastersEventStore } from '@/store/pinia/events/mastersStore'
import { timeLeft } from '@/helpers'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  textChanges: Nullable<PlayoffTextExchange>
}

export default defineComponent({
  name: 'LadderSubHeader',
  props: {
    mastersStart: {
      type: String,
      default: null,
    },
  },
  data(): ComponentData {
    return {
      textChanges: null,
    }
  },
  computed: {
    timeEnd(): number {
      return timeLeft(new Date(this.mastersStart).getTime(), true)
    },
  },
  async created(): Promise<void> {
    this.textChanges = await this.loadTextsChanger()
  },
  methods: {
    ...mapActions(useMastersEventStore, ['loadTextsChanger']),
  },
})
</script>

<style lang="scss" scoped>
.sub-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  margin-bottom: 0.625rem;
  height: 5.25rem;

  @if $isWsm {
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(
      to right,
      rgba(181, 230, 255, 0),
      rgba(181, 230, 255, 0.8),
      rgba(181, 230, 255, 0.6),
      rgba(182, 230, 255, 0.53),
      rgba(182, 230, 255, 0.33),
      rgba(181, 230, 255, 0)
    );
    border-image-slice: 1;
    background-image: linear-gradient(
      to right,
      rgba(72, 162, 238, 0),
      rgba(72, 162, 238, 0.8),
      rgba(72, 162, 238, 0.6),
      rgba(72, 162, 238, 0.56),
      rgba(72, 162, 238, 0.36),
      rgba(72, 162, 238, 0)
    );
  }

  @if $isSsm {
    @include background(
      url('#{$path-events}/frozen-championship/playoff/sub-header-bg.avif'),
      contain
    );
  }

  .placeholder {
    width: 100px;
  }
}
</style>
