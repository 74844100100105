export enum PackStatus {
  Locked = 'locked',
  Unlocked = 'unlocked',
  Claimed = 'claimed',
}

export enum PackType {
  Premium = 'premium',
  Video = 'video',
  Free = 'free',
  Unknown = 'unknown',
}

export enum LevelUpBagType {
  Bronze = 'bronze',
  Silver = 'silver',
  Gold = 'gold',
  Diamond = 'diamond',
}

export enum GamePassType {
  Basic = 'basic',
  Pro = 'pro',
  Elite = 'elite',
}

export enum GamePassMilestoneStatus {
  Unoptained = 'unobtained',
  UnoptainedLocked = 'unobtained_locked',
  NotClaimed = 'not_claimed',
  NotClaimedLocked = 'not_claimed_locked',
  Claimed = 'claimed',
}
