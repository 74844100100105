export enum MilestoneType {
  Individual = 'individual',
  Premium = 'premium',
  Club = 'club',
}

export enum MilestoneMainComponent {
  Personal = 'PersonalMilestonesMain',
  Club = 'ClubMilestonesMain',
}
