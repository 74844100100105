import { StageType } from '@/interfaces/Tutorial'
import type { TutorialStage } from '@/interfaces/Tutorial'

/**
  Tutorial for discipline unlocked
 */
export default class TutorialUnlockDailyTasks {
  stages(): TutorialStage[] {
    return [
      // narative saying you unlocked a new discipline
      {
        id: 70,
        name: 'unlockDailyTasksNarrative',
        type: StageType.Narrative,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        texts: [],
      },
      {
        id: 71,
        name: 'clickOnDailyTasks',
        type: StageType.ClientInput,
        overlay: true,
        mechanic: 3,
        pageName: 'LayoutViewTutorial',
        texts: [],
        clientId: ['layout-tasks-btn'],
      },
    ]
  }
}
