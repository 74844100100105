import { createRandomId } from '@/helpers'
import { internalAxios } from '@/plugins/vueAxios'
import type { Nullable } from '@/interfaces/utils'
import type { AxiosRequestConfig, AxiosResponse, Method } from 'axios'

interface InternalApiRequestOptions {
  cache: boolean // Mame response hladat v cache a chceme ju ulozit do cache
  force: boolean // Nehladajme response v cache a vzdy spravme novy request
}

type CustomApiRequestOptions = Nullable<Partial<InternalApiRequestOptions>>

interface CallbackListItem {
  resolve: CallableFunction
  reject: CallableFunction
}

interface CachedResponse {
  response: any
  timestamp: number
}

const STORAGE_KEY = 'ApiServiceCache'

const ENABLE_DEBUG = false

export const ApiService = class {
  protected static apiCache = new Map<string, CachedResponse>()
  protected static apiCallbackList = new Map<string, CallbackListItem[]>()
  protected static apiControllerList = new Map<string, AbortController>()

  protected static isFirstLoad: boolean = false
  protected static firstLoadCleanupKeys = new Set<string>(['{"method":"GET","url":"us/tutorial"}'])

  public static setIsFirstLoad(value: boolean): void {
    if (ENABLE_DEBUG) console.debug('[debug] ApiService: first load:', value)

    this.isFirstLoad = value

    // Clear cache after first load
    if (!this.isFirstLoad) {
      if (ENABLE_DEBUG) console.debug('[debug] ApiService: clearing first load cache')

      this.firstLoadCleanupKeys.forEach((key: string): void => {
        if (!this.apiCache.has(key)) return
        this.apiCache.delete(key)
      })
    }
  }

  protected static async request<
    ResponseData extends any = any,
    RequestData extends any = undefined,
  >(
    method: Method | string,
    url: string,
    data?: Nullable<RequestData>,
    options?: CustomApiRequestOptions,
  ): Promise<ResponseData> {
    if (ENABLE_DEBUG)
      console.debug('[debug] ApiService: perform request', {
        method,
        url,
        data,
        options: JSON.stringify(options),
        isFirstLoad: this.isFirstLoad,
      })

    // Return promise
    return new Promise(
      (resolve: CallableFunction, reject: CallableFunction): Promise<ResponseData> => {
        // Normalize method
        method = method.toUpperCase() as Method

        // Check if request is GET request
        const isGetRequest = method === 'GET'

        // Merge options with default values
        options = {
          cache: isGetRequest,
          force: false,
          ...options,
        }

        // Override options for first load
        if (this.isFirstLoad) {
          options = {
            cache: true,
            force: false,
          }
        }

        // Create unique key for cache
        const key = JSON.stringify({ method, url, data: data ? data : undefined })

        // Check if data is cached
        if (options.cache && this.apiCache.has(key) && !options.force) {
          // Return cached value
          resolve(this.apiCache.get(key).response)
          return
        }

        // Check if request is already pending and callback to pending list
        const hasKey = this.apiCallbackList.has(key)
        const callbackItem = { resolve, reject }
        this.apiCallbackList.set(
          key,
          hasKey ? [...this.apiCallbackList.get(key), callbackItem] : [callbackItem],
        )
        if (hasKey) return

        // Create new controller for cancellation
        const controllerId = createRandomId('axios', '.')
        const controller = new AbortController()
        this.apiControllerList.set(controllerId, controller)

        // Send request
        internalAxios
          .request<{}, AxiosResponse<ResponseData>, AxiosRequestConfig<RequestData>>({
            method,
            url,
            data,
            signal: controller.signal,
          })
          .then((response: AxiosResponse<ResponseData>): void => {
            // Add response to cache
            if (options.cache) {
              this.apiCache.set(key, {
                response,
                timestamp: Date.now(),
              })
            }

            // Get all pending callbacks
            const resolves = [...(this.apiCallbackList.get(key) || [])].map(
              ({ resolve }: CallbackListItem): CallableFunction => resolve,
            )

            // Clear pending requests
            this.apiCallbackList.delete(key)
            this.apiControllerList.delete(controllerId)

            // Resolve all pending callbacks
            Promise.all(resolves.map((resolve: CallableFunction): void => resolve(response)))
          })
          .catch((error: Error): void => {
            console.error('ApiService error:', error)

            // Get all pending callbacks
            const rejects = [...(this.apiCallbackList.get(key) || [])].map(
              ({ reject }: CallbackListItem): CallableFunction => reject,
            )

            // Clear pending requests
            this.apiCallbackList.delete(key)
            this.apiControllerList.delete(controllerId)

            // Reject all pending callbacks
            Promise.all(rejects.map((reject: CallableFunction): void => reject(error)))
          })
      },
    )
  }

  public static get<ResponseData extends any = any>(
    url: string,
    options?: CustomApiRequestOptions,
  ): Promise<ResponseData> {
    return this.request<ResponseData>('GET', url, undefined, options)
  }

  public static post<ResponseData extends any = any, RequestData extends any = undefined>(
    url: string,
    data?: RequestData,
    options?: CustomApiRequestOptions,
  ): Promise<ResponseData> {
    return this.request<ResponseData, RequestData>('POST', url, data, options)
  }

  public static put<ResponseData extends any = any, RequestData extends any = undefined>(
    url: string,
    data?: RequestData,
    options?: CustomApiRequestOptions,
  ): Promise<ResponseData> {
    return this.request<ResponseData, RequestData>('PUT', url, data, options)
  }

  public static delete<ResponseData extends any = any>(
    url: string,
    options?: CustomApiRequestOptions,
  ): Promise<ResponseData> {
    return this.request<ResponseData>('DELETE', url, undefined, options)
  }

  public static patch<ResponseData extends any = any, RequestData extends any = undefined>(
    url: string,
    data?: RequestData,
    options?: CustomApiRequestOptions,
  ): Promise<ResponseData> {
    return this.request<ResponseData, RequestData>('PATCH', url, data, options)
  }

  public static $store(storage: Storage = sessionStorage): void {
    storage.setItem(STORAGE_KEY, JSON.stringify(Array.from(this.apiCache.entries())))
  }

  public static $restore(storage: Storage = sessionStorage): void {
    this.$abort()
    this.$clear()

    const storedCache = storage.getItem(STORAGE_KEY)
    if (storedCache) {
      this.apiCache = new Map(JSON.parse(storedCache))
    }

    storage.removeItem(STORAGE_KEY)
  }

  public static $clear(): void {
    if (ENABLE_DEBUG) console.debug('[debug] ApiService: clearing cache')

    this.apiCache.clear()
  }

  public static $abort(): void {
    if (ENABLE_DEBUG) console.debug('[debug] ApiService: aborting all pending requests')

    this.apiControllerList.forEach((controller: AbortController): void => {
      controller.abort()
    })

    this.apiCallbackList.clear()
    this.apiControllerList.clear()
  }
}

// for debug purposes
// window._ApiService = ApiService
