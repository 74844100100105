<template>
  <div class="events-warehouse-footer-item flexing">
    <div class="flexing w-full events-warehouse-footer-item-content">
      <app-icon
        :icon-name="ingredient.ingredient.icon"
        :tooltip-text="$te(ingredient.ingredient.name)"
        class="events-warehouse-footer-item-content-img"
      />

      <animated-number
        class="text-40 gradient-text-gold uppercase font-bold events-warehouse-footer-item-content-value"
        :param="{ value: ingredient.amount }"
        :show-max-value="false"
        :show-progress-bar="false"
      />
    </div>
  </div>
</template>

<script lang="ts">
import AnimatedNumber from '@/components/AnimatedNumber.vue'
import { pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'

import type { IngredientAmount } from '@/interfaces/responses/events/warehouse/WarehouseSharedInterface'
import type { PropType } from 'vue'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'EventsWarehouseFooterItem',
  components: {
    AnimatedNumber,
  },
  props: {
    ingredient: {
      type: Object as PropType<IngredientAmount>,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
})
</script>

<style lang="scss" scoped>
.events-warehouse-footer-item {
  width: 16%;
  height: 9rem;
  @include background(
    url($path-events + 'frozen-championship/warehouse/footer-item-bg.avif'),
    cover
  );

  &-content {
    &-img {
      width: 8rem !important;
      height: 8rem !important;
      margin-top: 0.2rem;
    }

    &-value {
      width: 51%;
      padding-top: 0.6rem;
      padding-right: 1rem;
    }
  }
}
</style>
