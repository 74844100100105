<template>
  <div
    class="staroftheday-disciplines app-page-wrapper absolute"
    data-cy="staroftheday-disciplines-wrapper"
  >
    <div class="w-full h-full absolute flex flex-col items-center">
      <menu-component
        menu-type="training"
        :title="$t('map.starOfTheDayName')"
        :menu-items="{
          1: {
            text: $t('benefits.dailyLeague'),
            route: $getWebView('StarofthedayDisciplines'),
          },
          2: {
            text: $t('starOfTheDay.athleteOfTheDay'),
            route: $getWebView('StarofthedayAthlete'),
          },
        }"
        :sub-menu-active="true"
        :sub-menu="{
          1: {
            text: $t('trainingHall.disciplines'),
            route: $getWebView('StarofthedayDisciplines'),
          },
          2: {
            text: $t('starOfTheDay.table'),
            route: $getWebView('StarofthedayStandings'),
          },
        }"
      />

      <section v-if="isLoaded" class="w-full h-full flex flex-col items-center safe-area">
        <staroftheday-locked
          v-if="dailyLeagueData.state !== IN_PROGRESS"
          :time="dailyLeagueData.time_remaining"
          :timer-tooltip="$t('starOfTheDay.timeToStart')"
        />
        <div v-else class="pb-8">
          <div class="staroftheday-disciplines-wrapper">
            <header class="w-full text-32 flex justify-between items-center">
              <app-timer
                v-tippy="{
                  content: $t('starOfTheDay.timeToEnd'),
                  placement: 'right',
                  theme: 'WSM',
                }"
                :time="dailyLeagueData.time_remaining"
                icon="refresh"
                @countdown-action="loadData"
              />
              <div class="staroftheday-header-position skew flex justify-between">
                <div class="staroftheday-header-position-left flexing revert-skew">
                  <span class="w-full flex justify-around items-center">
                    <p class="text-texts-standard-default font-bold text-32 leading-8">
                      {{ $t('arena.yourPosition') }}:
                    </p>
                    <p class="text-texts-standard-important font-bold text-36">
                      {{ dailyLeagueData.position }}
                    </p>
                  </span>
                </div>
                <div class="staroftheday-header-position-right flexing">
                  <p class="text-texts-standard-dark text-36 font-bold revert-skew">
                    {{ dailyLeagueData.points }}p
                  </p>
                </div>
              </div>
            </header>
            <div class="staroftheday-disciplines-table-head table-head-standard">
              <div class="staroftheday-disciplines-table-head-discipline text-left">
                {{ $t('common.discipline') }}
              </div>
              <div class="staroftheday-disciplines-table-head-time">
                {{ $t('common.power') }}
              </div>
              <div class="staroftheday-disciplines-table-head-position">
                {{ $t('rankingsGroup.pos') }}
              </div>
              <div class="staroftheday-disciplines-table-head-detail" />
            </div>
          </div>
          <app-scrollbar width="100%" :height="$isMobile() ? '58vh' : '39rem'" scroll="y" slide="y">
            <div class="staroftheday-disciplines-table table-standard italic">
              <div
                v-for="(discipline, index) in dailyLeagueData.disciplines"
                :key="index"
                class="staroftheday-disciplines-table-row table-standard-row mb-2.5 flex items-center"
                :class="{ selected: !!discipline.results.currentResult }"
              >
                <div
                  class="staroftheday-disciplines-table-col staroftheday-disciplines-discipline uppercase font-bold flex items-center"
                >
                  <app-discipline-icon
                    :discipline-id="discipline.id"
                    :size="70"
                    class="staroftheday-disciplines-discipline-logo inline-block"
                  />
                  <span class="staroftheday-disciplines-discipline-name text-32">
                    {{ $translateDiscipline(discipline.id) }}
                  </span>
                </div>
                <div class="staroftheday-disciplines-table-col staroftheday-disciplines-time">
                  {{
                    discipline.results.fail_status
                      ? $t(`arena.${discipline.results.fail_status}`)
                      : formatResult(discipline.results.currentResult, discipline.id)
                  }}
                </div>
                <div
                  class="staroftheday-disciplines-table-col staroftheday-disciplines-position font-bold"
                >
                  {{ discipline.results.position }}
                </div>
                <div
                  class="staroftheday-disciplines-table-col staroftheday-disciplines-detail flex justify-end items-center"
                >
                  <app-button
                    :btn-type="!discipline.isPlayAvailable ? 'primary' : 'secondary'"
                    :btn-text="
                      !discipline.isPlayAvailable ? $t('button.detail') : $t('button.start')
                    "
                    btn-size="md"
                    @click="goToDetail(discipline.id)"
                  />
                </div>
              </div>
            </div>
          </app-scrollbar>
        </div>

        <component-loading :is-loading="!isLoaded" />
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import StarofthedayLocked from '@/components/Staroftheday/StarofthedayLocked.vue'
import { IN_PROGRESS } from '@/globalVariables'
import { formatResult, playSound } from '@/helpers'
import { useStarOfTheDayStore } from '@/store/pinia/starOfThedayStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  IN_PROGRESS: typeof IN_PROGRESS
}

export default defineComponent({
  name: 'StarofthedayDisciplines',
  components: {
    StarofthedayLocked,
  },
  data(): ComponentData {
    return {
      IN_PROGRESS,
    }
  },
  computed: {
    ...mapState(useStarOfTheDayStore, {
      dailyLeagueData: 'getDailyLeagueData',
    }),
    isLoaded(): boolean {
      return !!this.dailyLeagueData
    },
  },
  created(): void {
    this.loadData()
    playSound('building-star_of_the_day')
  },
  methods: {
    ...mapActions(useStarOfTheDayStore, ['loadConfig', 'loadDailyLeagueData']),
    formatResult,
    goToDetail(disciplineId: number | string): void {
      this.$router.push({
        name: this.$getWebView('StarofthedayDisciplineDetail'),
        params: { id: disciplineId },
      })
    },
    loadData(): void {
      this.loadDailyLeagueData()
      this.loadConfig()
    },
  },
})
</script>

<style lang="scss">
@import '@/assets/styles/views/staroftheday/disciplines.scss';
</style>
