<template>
  <section class="flexing flex-col flex-grow w-full relative">
    <training-view :is-event-view="true" />
  </section>
</template>

<script lang="ts">
import TrainingView from '@/views/training/TrainingDisciplineDetail.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TrainingBody',
  components: {
    TrainingView,
  },
})
</script>
