<template>
  <div
    class="level-up-rewards-images"
    :class="{ 'level-up-rewards-images--discipline': iconName === 'discipline' }"
  >
    <div
      v-if="iconName === 'arena'"
      class="reward-building-wrapper"
      :class="{ tooltip: isTooltip }"
    >
      <div
        class="reward-building"
        :style="{
          backgroundImage:
            'url(' +
            pathImages +
            'arena/building/BUILDING_ARENA_SMALL_LVL' +
            getBuildingImageByLevel(Number(param)) +
            '.avif)',
        }"
      />
    </div>
    <div
      v-if="iconName === 'training_hall'"
      class="reward-building-wrapper"
      :class="{ tooltip: isTooltip }"
    >
      <div
        class="reward-building"
        :style="{
          backgroundImage:
            'url(' +
            pathImages +
            'training/building/BUILDING_TRAINING_HALL_SMALLEST_LVL' +
            getBuildingImageByLevel(Number(param)) +
            '.avif)',
        }"
      />
    </div>
    <div v-if="iconName === 'experience'" class="icon-discipline">
      <div :class="'icon-discipline-' + param + '-light-90'" />
    </div>
    <div v-if="iconName === 'level-up-bags'">
      <div class="icon-level-up-bags-120" />
    </div>
    <div v-if="iconName === 'game-pass'">
      <div class="icon-game-pass-120" />
    </div>
    <div v-if="iconName === 'career-gold'">
      <div class="icon-career-gold-120" />
    </div>
    <app-discipline-icon
      v-if="iconName === 'discipline'"
      :discipline-id="Number(param)"
      :has-tooltip="false"
      :size="120"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { pathImages } from '@/globalVariables'
import { getBuildingImageByLevel } from '@/helpers'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'LevelUpRewardsImages',
  props: {
    iconName: {
      type: String,
      required: true,
    },
    param: {
      type: String,
      required: true,
    },
    isTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  methods: {
    getBuildingImageByLevel,
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';

.level-up-rewards-images {
  margin-top: 0.688rem;

  .icon-discipline {
    width: 5.625rem;
    height: 5.625rem;
  }

  .reward-building-wrapper {
    width: 8rem;
    height: 5rem;

    &.tooltip {
      width: 11.5rem;
      height: 6.438rem;
    }

    .reward-building {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
</style>
