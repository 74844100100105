import { defineStore } from 'pinia'
import { internalAxios } from '@/plugins/vueAxios'
import type {
  PhaserGameIntegrationStoreState,
  MapItemsData,
  ProgressTimer,
} from '@/types/phaserNewStoreTypes'
import {
  SET_MAIN_MAP_REWARDED_ITEMS_TIMER,
  SET_MAIN_MAP_NEW_REWARDED_ITEMS,
  RELOAD_BUILDINGS,
  SET_BUILDINGS_TITLES_VISIBILITY,
  SET_CLUB_CHAMPIONSHIP_LABEL_INFO,
  DESTROY_CONNECT_ACCOUNT_OSRA,
  SET_BUBBLE_NOTIFICATIONS,
  MANAGE_INDICATOR_VISIBILITY,
  HIDE_MAP_TUTORIAL,
  SHOW_MAP_TUTORIAL,
  LOAD_CLUBS_DATA,
  SET_PREMIUM_NOTIFICATION,
  RELOAD_TEXTS,
  ZOOM_IN,
  ZOOM_OUT,
  ZOOM_RESET,
  SET_BUILDING_REWARDS,
  SCENES_KEYS,
  MANAGE_PROGRESS_TIMER_VISIBILITY,
  SHOP,
  LABORATORY,
  SHOW_WEATHER,
  HIDE_WEATHER,
  EQUIPMENT_OPERATION_MAPPING,
  LOAD_EVENT_ASSETS,
  DESTROY_EVENT_BUILDING,
  CREATE_EVENT_BUILDING,
  CLEAR_AND_HIDE_TIMER,
  CLAIMED_ITEM,
  REFRESH_PROGRESS_TIMERS,
  RESUME_SCENE,
  PAUSE_SCENE,
  EVENT_OSRA,
  EVENT_SHIP,
} from '@/map-phaser-new/constants'
import {
  CLUB_CHAMPIONSHIP,
  metaBuildingsEndpoint,
  userParameterMapItemEndpoint,
  shopOperationCheckEndPoint,
  researchStatusEndpoint,
} from '@/globalVariables'
import type {
  BuildingMainMapResponseInterface,
  ProgressTimerDataInterface,
  Unlock,
} from '@/map-phaser-new/interfaces'
import { EventType } from '@/interfaces/events/EventInfo'
import type { OperationRequest, Parameter, ResearchStatusRequest } from '@/types/phaserStoreTypes'
import { convertNameValue } from '@/helpers'
import { ApiService } from '@/services/ApiService'

export const usePhaserGameIntegrationStore = defineStore('phaserGameIntegrationStore', {
  state: (): PhaserGameIntegrationStoreState => ({
    phaserEventEmitter: null,
    mainMapRewardedItemsData: {
      value: 0,
      max_value: 0,
      parameter_name: '',
      parameter_id: 0,
    },
    mainMapBuildings: [],
    timers: null,
    zoomLevel: 0,
    zoomInProgress: false,
    activeEventType: null,
  }),
  getters: {
    getPhaserEventEmitter(): PhaserGameIntegrationStoreState['phaserEventEmitter'] | null {
      return this.phaserEventEmitter
    },
    getMainMapRewardedItemsData(): PhaserGameIntegrationStoreState['mainMapRewardedItemsData'] {
      return this.mainMapRewardedItemsData
    },
    getMainMapEventsBuildings(): PhaserGameIntegrationStoreState['mainMapBuildings'] {
      if (!this.activeEventType) return []
      return [
        this.activeEventType !== EventType.DisciplineEvent
          ? {
              meta_buildings_id: 1073, // random id
              level: 1,
              name: EVENT_SHIP,
              parameters: [],
              locked: false,
              unlock: {} as Unlock,
              stats: [],
            }
          : {
              meta_buildings_id: 1075, // random id
              level: 1,
              name: EVENT_OSRA,
              parameters: [],
              locked: false,
              unlock: {} as Unlock,
              stats: [],
            },
      ]
    },
    getMainMapBuildings(): PhaserGameIntegrationStoreState['mainMapBuildings'] {
      return (
        this.mainMapBuildings
          // remove unused event building from response
          .filter(({ name }: BuildingMainMapResponseInterface): boolean => name !== 'event')
          .concat(this.getMainMapEventsBuildings)
      )
    },
    wasClubChampionshipOpened(): boolean {
      type Building = BuildingMainMapResponseInterface
      type Stat = Building['stats'][0]

      return this.mainMapBuildings
        .find((building: Building): boolean => building.name === CLUB_CHAMPIONSHIP)
        ?.stats.find((stat: Stat): boolean => stat.name === 'opened')
    },
    getIsMainMapEventBuildingCreated(): boolean {
      return this.activeEventType !== null
    },
    getTimers(): ProgressTimer {
      return this.timers
    },
    getZoomLevel(): PhaserGameIntegrationStoreState['zoomLevel'] {
      return this.zoomLevel
    },
    isZoomInProgress(): PhaserGameIntegrationStoreState['zoomInProgress'] {
      return this.zoomInProgress
    },
  },
  actions: {
    setPhaserEventEmitter(emiter: Phaser.Events.EventEmitter): void {
      this.phaserEventEmitter = emiter
    },

    async loadMainMapRewardedItems(): Promise<void> {
      // load - init or refresh
      try {
        const response = await internalAxios.get<{}, MapItemsData>(userParameterMapItemEndpoint)
        this.updateMainMapRewardedItems(response)
      } catch (error: unknown) {
        console.error(error)
      }
    },

    updateMainMapRewardedItems(data: MapItemsData): void {
      // update - response tasks or after init
      this.mainMapRewardedItemsData = data
      const nextRefresh = data.next_refresh ?? 0

      this.phaserEventEmitter?.emit(SET_MAIN_MAP_REWARDED_ITEMS_TIMER, nextRefresh)
    },

    async refreshMainMapRewardedItems(): Promise<void> {
      // refresh - after getting back from background or another tab
      await this.loadMainMapRewardedItems()
      this.phaserEventEmitter?.emit(SET_MAIN_MAP_NEW_REWARDED_ITEMS)
    },

    async loadMainMapBuildings(): Promise<void> {
      try {
        this.mainMapBuildings = await ApiService.get<BuildingMainMapResponseInterface[]>(
          metaBuildingsEndpoint,
          { force: true }, // Phaser si sam manazuje budovy, preto to musi byt force.
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },

    async reloadBuildings(reloadOnly?: SCENES_KEYS, isForeignMap?: boolean): Promise<void> {
      this.phaserEventEmitter?.emit(RELOAD_BUILDINGS, {
        reloadOnly,
        isForeignMap,
      })
    },

    setBuildingsTitlesVisibility(visible: boolean): void {
      this.phaserEventEmitter?.emit(SET_BUILDINGS_TITLES_VISIBILITY, visible)
    },

    setClubChampionshipLabelInfo(): void {
      this.phaserEventEmitter?.emit(SET_CLUB_CHAMPIONSHIP_LABEL_INFO)
    },

    removeConnectAccountOsra(): void {
      this.phaserEventEmitter?.emit(DESTROY_CONNECT_ACCOUNT_OSRA)
    },

    hideMainMapTutorial(isFocusItem: boolean = false): void {
      this.phaserEventEmitter?.emit(HIDE_MAP_TUTORIAL, isFocusItem)
    },

    showMapTutorial(tutorialClientId: string, isFocusItem: boolean): void {
      this.phaserEventEmitter?.emit(SHOW_MAP_TUTORIAL, {
        tutorialClientId,
        isFocusItem,
      })
    },

    manageCareerIndicatorVisibility(questEntities: string[]): void {
      this.phaserEventEmitter?.emit(MANAGE_INDICATOR_VISIBILITY, questEntities)
    },

    manageProgressTimerVisibility(timerData: ProgressTimerDataInterface): void {
      this.phaserEventEmitter?.emit(MANAGE_PROGRESS_TIMER_VISIBILITY, timerData)
    },

    loadClubAssets(): void {
      this.phaserEventEmitter?.emit(LOAD_CLUBS_DATA)
    },

    setPremiumNotification(): void {
      this.phaserEventEmitter?.emit(SET_PREMIUM_NOTIFICATION)
    },

    reloadTexts(): void {
      this.phaserEventEmitter?.emit(RELOAD_TEXTS)
    },

    setZoomIn(): void {
      this.phaserEventEmitter?.emit(ZOOM_IN)
    },

    setZoomOut(): void {
      this.phaserEventEmitter?.emit(ZOOM_OUT)
    },

    setZoomReset(): void {
      this.phaserEventEmitter?.emit(ZOOM_RESET)
    },

    setZoomLevel(value: number): void {
      this.zoomLevel = value
    },

    setBubbleNotifications(): void {
      this.phaserEventEmitter?.emit(SET_BUBBLE_NOTIFICATIONS)
    },

    setBuildingRewards(): void {
      this.phaserEventEmitter?.emit(SET_BUILDING_REWARDS)
    },

    showWeather(): void {
      this.phaserEventEmitter?.emit(SHOW_WEATHER)
    },

    hideWeather(): void {
      this.phaserEventEmitter?.emit(HIDE_WEATHER)
    },

    setZoomInProgress(value: boolean): void {
      this.zoomInProgress = value
    },

    loadEventBuilding(): void {
      this.phaserEventEmitter?.emit(LOAD_EVENT_ASSETS)
    },

    createEventBuilding(): void {
      this.phaserEventEmitter?.emit(CREATE_EVENT_BUILDING)
    },

    destroyEventBuilding(): void {
      this.phaserEventEmitter?.emit(DESTROY_EVENT_BUILDING)
    },

    setActiveEventType(newEventType: EventType | null): void {
      this.activeEventType = newEventType
    },

    clearTimer(id: string): void {
      if (!this.timers[id]) return
      delete this.timers[id]
    },

    clearAndHideTimer(id: string): void {
      this.phaserEventEmitter?.emit(CLEAR_AND_HIDE_TIMER, id)
    },

    setClaimedItem(): void {
      this.phaserEventEmitter?.emit(CLAIMED_ITEM)
    },

    async loadTimers(): Promise<void> {
      /**
       * EQUIPMENT timer
       */
      const equipmentOperationData = await internalAxios.get<{}, OperationRequest>(
        shopOperationCheckEndPoint,
      )
      const equipmentOperationStatus = equipmentOperationData?.operation?.stats
        ? convertNameValue(equipmentOperationData.operation.stats)
        : null
      let equipmentTimerData = { heading: '', timerStartedAt: 0, timerEndsAt: 0 }
      if (equipmentOperationStatus) {
        const duration = equipmentOperationStatus.operation_duration || 0
        const remainingTime = equipmentOperationStatus.operation_end || 0
        const now = Date.now()

        equipmentTimerData = {
          heading: EQUIPMENT_OPERATION_MAPPING[equipmentOperationStatus.operation_type],
          timerStartedAt: now - (duration - remainingTime) * 1000,
          timerEndsAt: now + equipmentOperationStatus.operation_end * 1000,
        }
      }

      /**
       * RESEARCH timer
       */
      const researchOperationStatus = await internalAxios.get<{}, ResearchStatusRequest>(
        researchStatusEndpoint,
      )
      let researchTimerData = { heading: '', timerStartedAt: 0, timerEndsAt: 0 }
      if (researchOperationStatus) {
        const duration =
          researchOperationStatus.parameters?.find((param: Parameter): boolean => {
            return param.name === 'research_time_duration'
          })?.value || 0
        const remainingTime = researchOperationStatus.remaining_time || 0
        const now = Date.now()
        researchTimerData = {
          heading: 'research.researchCounting',
          timerStartedAt: now - (+duration - remainingTime) * 1000,
          timerEndsAt: now + researchOperationStatus?.remaining_time * 1000,
        }
      }

      // timer tooltips above the buildings
      const timers = {
        [SHOP]: {
          ...equipmentTimerData,
        },
        [LABORATORY]: {
          ...researchTimerData,
        },
      }
      this.timers = timers
    },

    refresProgressTimers(): void {
      this.phaserEventEmitter?.emit(REFRESH_PROGRESS_TIMERS)
    },
    pauseScene(): void {
      this.phaserEventEmitter?.emit(PAUSE_SCENE)
    },
    resumeScene(): void {
      this.phaserEventEmitter?.emit(RESUME_SCENE)
    },
  },
})
