<template>
  <div class="milestones-table-row flexing" :class="`is-${milestoneStatus}`">
    <div
      class="milestones-table-row__cell milestones-table-row__cell--highlight flexing"
      :data-index="milestoneIndex"
    >
      <div class="reward flexing">
        <div>{{ $filters.$formatNumber(milestone.threshold) }}</div>
        <app-main-icon icon-name="league_points" :icon-size="48" />
      </div>
      <div v-if="isLocked" class="lock-box">
        <app-icon icon-name="lock-sm" :tooltip="false" />
      </div>
    </div>
    <div class="milestones-table-row__cell flexing">
      <div class="reward flexing" :class="{ 'opacity-50': isClaimed }">
        <span class="text-36 font-bold text-texts-standard-important">{{
          $filters.$formatNumber(milestoneReward.value)
        }}</span>
        <app-main-icon :icon-name="milestoneReward.type" :icon-size="48" />
      </div>
      <app-button
        v-if="!isClaimed"
        width="14.25rem"
        btn-size="md"
        btn-type="confirm"
        class="milestone-claim-btn"
        :disabled="isLoading || isDisabled || isLocked"
        :loading="isLoading"
        :notifications-count="1"
        :force-notification="!isLocked"
        @click="claimMilestone"
      >
        {{ $t('common.claim') }}
      </app-button>
      <div v-if="isClaimed && !isClaimable" class="milestone-claim-status flexing gap-1.5">
        <span class="text-32 font-bold text-texts-standard-upgrade">
          {{ $t('common.claimed') }}
        </span>
        <app-icon icon-name="done-sm" :tooltip="false" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useLeagueEventStore } from '@/store/pinia/events/leagueStore'
import { mapActions } from 'pinia'
import { defineComponent, type PropType } from 'vue'

import type { CompetitionMilestone } from '@/interfaces/responses/events/league/LeagueStateApiResponse'
import type Reward from '@/interfaces/Reward'

enum MilestoneStatus {
  Claimed = 'claimed',
  Claimable = 'claimable',
  Locked = 'locked',
}

interface ComponentData {
  isLoading: boolean
}

export default defineComponent({
  props: {
    milestone: {
      type: Object as PropType<CompetitionMilestone>,
      required: true,
    },
    milestoneIndex: {
      type: Number,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      isLoading: false,
    }
  },
  computed: {
    milestoneReward(): Reward {
      return this.milestone.rewards
    },
    isLocked(): boolean {
      return !this.isClaimable && !this.isClaimed
    },
    isClaimable(): boolean {
      return !!this.milestone.claimable
    },
    isClaimed(): boolean {
      return !!this.milestone.claimed
    },
    milestoneStatus(): string {
      if (this.isClaimed) return MilestoneStatus.Claimed
      if (this.isClaimable) return MilestoneStatus.Claimable
      return MilestoneStatus.Locked
    },
  },
  methods: {
    ...mapActions(useLeagueEventStore, {
      claim: 'claim',
    }),
    async claimMilestone(): Promise<void> {
      if (this.isLoading) return

      this.isLoading = true
      await this.claim(this.milestone.position)
      this.isLoading = false
    },
  },
})
</script>

<style lang="scss" scoped>
.milestones-table-row {
  width: 100%;
  height: 4.625rem;

  &.is-locked {
    .milestones-table-row__cell {
      &:first-of-type {
        position: relative;

        .lock-box {
          position: absolute;
          top: 0;
          right: 0;
          transform: translateX(50%);
          width: 4.375rem;
          height: 4.375rem;
          background-image: linear-gradient(to right, #5d5d91, #7271b0),
            linear-gradient(to right, #44446d, #7272b0);
          clip-path: polygon(15.5% 0%, 100% 0%, 84.5% 100%, 0% 100%);
          z-index: 1;
        }
      }
    }
  }

  &.is-claimable {
    .milestones-table-row__cell {
      &:first-of-type::before {
        background-image: linear-gradient(to right, #0f8f7f, #0ee2c7);
        border-image-source: linear-gradient(to top, #08a793, #befff7, #0ee2c7);
      }
    }
  }

  &.is-claimed {
    .milestones-table-row__cell {
      border-style: solid;
      border-width: 0.125rem;
      border-image-source: linear-gradient(to top, #08a793, #befff7, #0ee2c7);
      border-image-slice: 1;

      &::before {
        display: none;
      }

      &:first-of-type {
        border-right: none;
      }

      &:last-of-type {
        border-left: none;
      }
    }
  }

  &.is-claimable,
  &.is-claimed {
    .milestones-table-row__cell:first-of-type::after {
      background: url($path-events + '/autumn-fair/bounty-league/milestone-circle--done.avif')
        no-repeat center;
      background-size: contain;
    }
  }

  &__cell {
    gap: 0.5rem;
    padding: 0 1rem;
    flex: 1 1 auto;
    min-width: 0;
    height: 100%;
    color: #ffffff;
    background-color: #525479;
    border: solid 0.125rem #7477a4;
    font-size: 2.25rem;
    text-align: center;

    &:first-of-type {
      border-right: none;
    }

    &:last-of-type {
      border-left: none;
    }

    &:first-of-type {
      position: relative;
      margin-left: 3rem;
      padding-left: 2.5rem;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to right, #44446d, #7272b0);
        clip-path: polygon(0% 0%, 100% 0%, 96% 100%, 0% 100%);
        border-width: 0.125rem;
        border-image-source: linear-gradient(to top, #44446d, #8d8dcf, #7272b0);
        border-image-slice: 1;
        border-left: none;
      }

      &::after {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: url($path-events + '/autumn-fair/bounty-league/milestone-circle--progress.avif')
          no-repeat center;
        background-size: contain;
        content: attr(data-index);
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
        display: flex;
        width: 5.25rem;
        height: 5.25rem;
        color: #ffffff;
        font-size: 2.5rem;
        font-weight: 700;
        text-shadow: 0.6px 0.8px 0.9px rgba(0, 0, 0, 0.71);
        padding-top: 0.25rem;
      }

      & > div:first-of-type {
        z-index: 1;
      }

      .lock-box {
        display: none;
      }
    }

    &--highlight {
      width: 12rem;
      flex: 0 0 auto;
      padding: 0;
    }

    .milestone-claim-btn,
    .milestone-claim-status {
      width: 14.25rem;
    }
  }
}

.reward {
  width: 10rem;
  gap: 0.5rem;
}

.app-button {
  position: relative;

  .notification {
    position: absolute;
    top: -1rem;
    right: -1rem;
    width: 2rem;
    height: 2rem;
    color: #ffffff;
    background-image: linear-gradient(to top, #e70000, #ff5b5b);
    border-radius: 1rem;
    font-size: 1.375rem;
    font-weight: 700;
    padding-top: 0.1875rem;
  }
}
</style>
