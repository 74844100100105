<template>
  <div
    id="club-available-players"
    class="club-available-players-view flex flex-col app-page-wrapper absolute"
  >
    <menu-component menu-type="club-available-players" :title="$t('club.playersWithoutClub')" />
    <section class="club-available-players-wrapper flex w-full h-full safe-area">
      <main class="club-available-players-main flex flex-col w-full">
        <table-component
          :columns="columns"
          :items="getPlayers"
          :show-tools="showToolsData"
          :no-data-text="$t('club.noPlayersFound')"
          :filter-data="filterData"
          force-scroll-size="100%"
          @button-click="sendInvite"
          @apply-filter="sendFilter"
          @reset-filter="sendFilter"
          @search-event="searchByName"
          @sort-event="orderBy"
        />
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import TableComponent from '@/components/Table/TableComponent.vue'
import { clubTableKeys } from '@/components/Table/tableKeys'
import { ALL } from '@/globalVariables'
import { formatCountries, getClubRequiredLevels, getOrderBodyContent } from '@/helpers'
import { useClubsPlayersStore } from '@/store/pinia/clubs/clubsPlayersStore'
import { useCountriesStore } from '@/store/pinia/countriesStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { ClubsColumnData } from '@/interfaces/clubs/Clubs'
import type { BodyContent } from '@/helpers/orderBy'
import type { NameValueString } from '@/interfaces/Global'
import type { ClubAvailablePlayer } from '@/interfaces/clubs/ClubAvailablePlayer'

interface PlayerFilterData {
  id: string
  text: string
  selectbox: {
    option: NameValueString[]
    default: string
  }
}

interface ComponentData {
  clubTableKeys: typeof clubTableKeys
  formatCountries: typeof formatCountries
  columns: ClubsColumnData[]
  items: []
  showToolsData: {
    search: boolean
    filter: boolean
  }
  filterValue: {
    country: string
    level: string
  }
}

export default defineComponent({
  name: 'ClubsAvailablePlayers',
  components: {
    TableComponent,
  },
  data(): ComponentData {
    return {
      clubTableKeys,
      formatCountries,
      columns: [
        // if it's icon pass to label icon name and set isIcon to true
        {
          label: this.$t('settings.country'),
          key: clubTableKeys.country,
          width: 10,
        },
        {
          label: this.$t('common.name'),
          key: clubTableKeys.username,
          align: 'left',
          width: 30,
        },
        {
          label: this.$t('common.level'),
          key: clubTableKeys.level,
        },
        {
          label: 'prestige',
          key: clubTableKeys.prestige,
          isIcon: true,
        },
        {
          label: 'ranking_points',
          key: clubTableKeys.rankingPoints,
          isIcon: true,
        },
        {
          label: '',
          key: clubTableKeys.button,
          buttonData: {
            type: 'secondary',
            size: 'sm',
            text: 'club.invite',
            afterClick: true,
          },
          width: 25,
          align: 'right',
          text: 'club.inviteSent',
        },
      ],
      items: [],
      showToolsData: {
        search: true,
        filter: true,
      },
      filterValue: {
        country: ALL,
        level: ALL,
      },
    }
  },
  computed: {
    ...mapState(useCountriesStore, {
      countries: 'getCountries',
    }),
    ...mapState(useClubsPlayersStore, {
      availablePlayers: 'getAvailablePlayers',
    }),
    getPlayers(): { [key: string]: string | number }[] {
      const players = this.availablePlayers.map(
        (player: ClubAvailablePlayer): { [key: string]: string | number } => {
          return {
            [clubTableKeys.id]: player.id,
            [clubTableKeys.userId]: player.userId,
            [clubTableKeys.country]: player.country.three_letter_code,
            [clubTableKeys.name]: player.username,
            [clubTableKeys.level]: player.level,
            [clubTableKeys.rankingPoints]: player.rankingPoints,
            [clubTableKeys.prestige]: player.prestige,
          }
        },
      )

      return players
    },
    filterData(): PlayerFilterData[] {
      return [
        {
          id: 'country',
          text: 'settings.country',
          selectbox: {
            option: this.countriesData,
            default: this.filterValue.country,
          },
        },
        {
          id: 'level',
          text: 'common.level',
          selectbox: {
            option: this.getClubRequiredLevels(true, true),
            default: this.filterValue.level,
          },
        },
      ]
    },
    countriesData(): NameValueString[] {
      const defaultCountry = [{ value: ALL, name: this.$t('club.allCountries') }]
      const countries = this.formatCountries(this.countries)
      const mergedCountries = defaultCountry.concat(countries)
      return mergedCountries
    },
  },
  async created(): Promise<void> {
    await this.loadAvailablePlayers()
  },
  methods: {
    getOrderBodyContent,
    getClubRequiredLevels,
    ...mapActions(useClubsPlayersStore, ['sendRequestToJoinClub', 'loadAvailablePlayers']),
    sendInvite(playerId: string): void {
      this.sendRequestToJoinClub(playerId)
    },
    sendFilter(event: NameValueString): void {
      if (!event) {
        this.filterValue.country = ALL
        this.filterValue.level = ALL
      } else {
        this.filterValue.country = event[0].value ?? ALL
        this.filterValue.level = event[1].value ?? ALL
      }
      const countryValue = this.filterValue.country !== ALL ? this.filterValue.country : null
      let minLevelValue = null
      let maxLevelValue = null
      if (this.filterValue.level !== ALL) {
        const levelValues = this.filterValue.level.split(' - ')
        minLevelValue = Number(levelValues[0])
        maxLevelValue = Number(levelValues[1])
      }
      this.loadAvailablePlayers({
        filter_country: countryValue,
        filter_level_min: minLevelValue,
        filter_level_max: maxLevelValue,
      })
    },
    searchByName(e: string): void {
      this.loadAvailablePlayers({
        filter_name: e,
      })
    },
    orderBy(order: BodyContent): void {
      const bodyContent = this.getOrderBodyContent(order)
      this.loadAvailablePlayers(bodyContent)
    },
  },
})
</script>

<style lang="scss" scoped>
.club-available-players {
  &-wrapper {
    flex: 1 1 auto;
    min-height: 0;

    .club-available-players-main {
      width: 98%;
      margin: 0 auto;

      // TODO if the global table system is solved, we can delete this deep
      :deep() {
        .table-component {
          display: flex;
          width: 100%;
          flex: 1 1 auto;
          overflow: hidden;
          margin: 0 auto 1.25rem;
        }

        .table-component-wrapper {
          // Positional correction of the table in contrast to display: table
          display: flex;
          height: 100%;

          .y-scroll {
            // Offset for the scrollbar without blending with the table
            padding-right: 1.375rem;
          }
        }
      }
    }
  }
}
</style>
