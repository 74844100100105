export interface ClubFilter {
  filter_country?: string
  filter_name?: string
  order_asc?: boolean
  order_type?: string
  filter_level_min?: number
  filter_level_max?: number
  request_types?: string
  club_id?: string
  no_cache?: boolean
}

export interface Club {
  clubId: string
  country: string
  name: string
  clubCapacity: number
  membersCount: number
  type: string
  prestige: number
  logoId: number
  backgroundId: number
}

export interface ClubBuildingData {
  box?: string
  buildingTypeId?: number
  clubHouseLevelRequired?: number
  employees?: ClubActiveEmployee[]
  hasReward?: boolean
  incomeData?: { rewardValue: number; rewardInterval: number; rewardName: string }
  level?: number
  lock?: boolean
  name?: string
  text?: string
}

export interface ClubActiveEmployee {
  type: string
  active: boolean
  rewards: {
    type: string
    value: number
  }
  canClaim: boolean
}

export interface ClubUpgradeData {
  name: string
  level: number
  maxLevel: number
  currentValue: number
  type: string
  nextValue: number
  price: number
  time: number
}

export interface ClubPlayerData {
  clubId: string
  userId: string
  name: string
  threeLetterCode: string
  role: string
}

export enum ClubInfoFormFlex {
  Col = 'col',
  Row = 'row',
}

export interface ClubsColumnData {
  label: string
  key?: string
  align?: string
  width?: number
  isIcon?: boolean
  avatar?: boolean
  disableSort?: boolean
  text?: string
  onlineStatus?: boolean
  buttonData?: {
    type: string
    size: string
    text: string
    class?: string
    disabled?: boolean
    afterClick?: boolean
  }
}

export interface ClubChatMessage {
  message: string
  sender: string
  userId: string
  senderName: string
  senderCountry: string
  senderAvatar: number
  senderAvatarBackground: number
  sex: string
  active: boolean
  date: number
}
