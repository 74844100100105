import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const carsRightTopToLeftTopCheckpoints: CheckpointsObjectInterface = {
  key: 'carsRightTopToLeftTopCheckpoints',
  data: [
    {
      x: 3441,
      y: 652,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations1',
        },
      },
    },
    {
      x: 3418,
      y: 692,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations2',
        },
      },
    },
    {
      x: 3379.5,
      y: 738.5,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations3',
        },
      },
    },
    {
      x: 3328.5,
      y: 747.5,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.PauseAction,
        action: {
          duration: 2000,
        },
      },
    },
    {
      x: 3318,
      y: 768.5,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations4',
        },
      },
    },
    {
      x: 3314,
      y: 719,
      width: 10,
      height: 5,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations5',
        },
      },
    },
    {
      x: 3166.5,
      y: 599,
      width: 10,
      height: 5,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations6',
        },
      },
    },
    {
      x: 2719,
      y: 492,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations7',
        },
      },
    },
    {
      x: 2123,
      y: 596,
      width: 10,
      height: 5,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations8',
        },
      },
    },
    {
      x: 1800,
      y: 620,
      width: 10,
      height: 5,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations9',
        },
      },
    },
    {
      x: 894,
      y: 873,
      width: 10,
      height: 5,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations10',
        },
      },
    },
    {
      x: 819,
      y: 946,
      width: 10,
      height: 5,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations11',
        },
      },
    },
    {
      x: 827,
      y: 995,
      width: 10,
      height: 5,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations12',
        },
      },
    },
    {
      x: 776,
      y: 1065,
      width: 10,
      height: 5,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations13',
        },
      },
    },
    {
      x: 649,
      y: 1120,
      width: 10,
      height: 5,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations14',
        },
      },
    },
    {
      x: 567,
      y: 1123,
      width: 10,
      height: 5,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations15',
        },
      },
    },
    {
      x: 510,
      y: 1099,
      width: 10,
      height: 5,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations16',
        },
      },
    },
    {
      x: 447,
      y: 1061,
      width: 10,
      height: 5,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations17',
        },
      },
    },
    {
      x: 406,
      y: 990,
      width: 10,
      height: 5,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations18',
        },
      },
    },
    {
      x: 426,
      y: 949,
      width: 10,
      height: 5,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations19',
        },
      },
    },
    {
      x: 690,
      y: 696.5,
      width: 10,
      height: 5,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsRightTopToLeftTopAnimations20',
        },
      },
    },
  ],
}
