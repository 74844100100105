<template>
  <div class="event-discipline-header flex justify-between items-center w-full">
    <event-pass-button :pass="PassType.Master" />
    <slot />
    <div class="flexing">
      <div class="flexing">
        <p class="text-32 text-texts-standard-default mr-6">{{ $te('eventEnd') }}</p>
        <app-timer class="event-discipline-header-timer" icon="time" :time="eventTimer" />
      </div>
      <app-icon class="iconer" icon-name="question" @click="showAboutPopup = true" />
    </div>
  </div>

  <discipline-event-about-popup
    v-if="showAboutPopup"
    :is-visible="showAboutPopup"
    @close="showAboutPopup = false"
  />
</template>

<script lang="ts">
import DisciplineEventAboutPopup from '@/components/Events/EventsPopups/DisciplineEventAboutPopup.vue'
import EventPassButton from '@/components/Events/GlobalEventsComponents/EventPassButton.vue'
import { PassType } from '@/interfaces/events/Passes'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  showAboutPopup: boolean
  PassType: typeof PassType
}

export default defineComponent({
  name: 'EventDisciplineHeader',
  components: {
    EventPassButton,
    DisciplineEventAboutPopup,
  },
  data(): ComponentData {
    return {
      showAboutPopup: false,
      PassType,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      eventTimer: 'getEventLeftBoxTimer',
    }),
  },
})
</script>

<style lang="scss" scoped>
.event-discipline-header {
  padding-left: $default-margin;
  padding-right: $default-margin;
  margin-top: 2rem;
  margin-bottom: 2rem;

  &-timer {
    min-width: 16.063rem !important;
  }
}
</style>
