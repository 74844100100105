<template>
  <div class="error-message-wrapper">
    <div class="error-message">
      <header
        class="error-message-header font-bold flex flex-col items-center justify-end"
      ></header>
      <main class="error-message-main flex flex-col items-center">
        <section class="error-message-main-name">
          <p class="text-texts-standard-default text-40 uppercase font-bold">
            {{ $t('error.error') }}
          </p>
        </section>
        <div class="flexing flex-col h-full">
          <p class="text-texts-standard-default text-36 font-bold">
            {{ $t('error.errorAlertSubheading') }}
          </p>
          <p class="text-texts-standard-additional text-32" v-html="getEmailText" />

          <div v-if="appEnv !== PROD">
            <div v-for="(error, i) in errors" :key="i" class="mt-3 text-white">
              <span class="text-28">{{ errorText(error) }}</span>
            </div>
          </div>
        </div>
      </main>
      <footer class="error-message-footer flexing">
        <app-button
          btn-id="error-message-footer-btn"
          btn-size="md"
          btn-type="secondary"
          :btn-text="$t('button.continue')"
          @click="removeErrors"
        />
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import { PROD, supportMail } from '@/globalVariables'
import { useCoreStore } from '@/store/pinia/coreStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface ComponentData {
  supportMail: typeof supportMail
  appEnv: ImportMetaEnv['VITE_ENV']
  PROD: typeof PROD
}

export default defineComponent({
  name: 'ErrorMessage',
  props: {
    errors: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      supportMail,
      appEnv: import.meta.env.VITE_ENV,
      PROD,
    }
  },
  computed: {
    getEmailText(): string {
      return this.$replacePlaceholder(
        this.$replaceHtmlPlaceholders(
          this.$replacePlaceholder(this.$t('error.errorAlertInfo'), '{email}', '{i}{email}{/i}'),
          'i',
          'text-texts-standard-important',
        ),
        '{email}',
        this.supportMail,
      )
    },
  },
  methods: {
    ...mapActions(useCoreStore, {
      removeErrors: 'removeAxiosErrors',
    }),
    errorText(error: string): string {
      if (!error) return

      if (error.includes('$ERRORMSG$')) {
        return this.$t(error.replace('$ERRORMSG$', ''))
      }
      return error
    },
  },
})
</script>

<style lang="scss" scoped>
.error-message {
  width: 100%;
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &-wrapper {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(0.15rem);
    z-index: 9;
  }

  &-header {
    width: 70%;
    height: 20rem;
    margin: 0 auto;
    background: url($path-premium + 'afterPayment/BG_EFFECT_4.avif') center bottom no-repeat;
    background-size: cover;
  }

  &-main {
    width: 100%;
    height: 20rem;
    box-shadow: 0px 0.313rem 0.813rem 0 #272727;
    background: linear-gradient(
      to right,
      transparent,
      #154e79 10%,
      #0f2a4e 40%,
      #0b1e37 50%,
      #0f2a4e 60%,
      #154e79 90%,
      transparent
    );
    background: url($path-premium + 'afterPayment/TABLE_BG.avif') center no-repeat;
    background-size: cover;

    &-name {
      width: 100%;
      height: 5rem;
      background: url($path-premium + 'afterPayment/BG_TOP_CONTENT.avif') center no-repeat;
      background-size: cover;
      line-height: 5rem;
      margin-bottom: 0;
    }
  }

  footer {
    width: 100%;
    height: 15rem;
  }
}
</style>
