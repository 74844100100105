import type { TutorialConfigInterface } from '@/map-phaser-new/interfaces'

export const tutorial: TutorialConfigInterface = {
  textureKey: 'tutorial-circle',
  arrowTextureKey: 'tutorial-arrow',
  arrowsIndent: 320,
  panDuration: 2000,
  panAnimation: 'Sine.easeInOut',
  tutorialDepth: 102,
  circleOriginX: 0.5,
  circleOriginY: 0.5,
  circleDuration: 2000,
  circleAnimation: 'Sine.easeIn',
  circleOpacityEnd: 0,
  circleScaleEnd: 0,
  repeat: -1,
  arrowGroupRepeat: 2,
  arrowRotation: 3.1,
  arrowScaleX: -0.2,
  arrowScaleY: -0.2,
  arrowsGroupOfImagesAlphaStart: 0,
  arrowsAnimationAlphaEnd: 1,
  arrowsStaggerDelay: 500,
  arrowsEase: 'power2.inOut',
  arrowsAnimationDuration: 1500,
  arrowsEndPosition: 150,
  overlayWidth: 3840,
  overlayHeight: 2160,
  overlayDepth: 101,
  overlayColor: 0x000000,
  overlayOpacity: 0.8,
  overlayOriginX: 0,
  overlayOriginY: 0,
}
