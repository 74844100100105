import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const boatBottomToRightCheckpoints: CheckpointsObjectInterface = {
  key: 'boatBottomToRightCheckpoints',
  data: [
    {
      x: 1701,
      y: 1943,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatBottomToRightAnimations1',
        },
      },
    },
    {
      x: 2282,
      y: 1729,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatBottomToRightAnimations2',
        },
      },
    },
    {
      x: 2836,
      y: 1679,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatBottomToRightAnimations3',
        },
      },
    },
  ],
}
