import { LineHeight } from '@/interfaces/lab/Cell'
export const researchColumnsCamps = [
  {
    disciplines: [1, 6],
    text: '',
  },
  {
    disciplines: [2, 8],
    text: '',
  },
  {
    disciplines: [3, 9],
    text: '',
  },
  {
    disciplines: [4, 7],
    text: '',
  },
  {
    disciplines: [5, 10],
    text: '',
  },
  {
    disciplines: null,
    text: 'research.regenerationEnergy',
  },
  {
    disciplines: null,
    text: 'research.regenerationStart',
  },
]
export const researchColumnsEconomy = [
  {
    name: 'arena',
    text: 'map.arenaName',
    cells: [
      {
        gddId: 1,
        positionLevel: 0,
        lineSize: LineHeight.Xs,
      },
      {
        gddId: 2,
        positionLevel: 2,
        lineSize: LineHeight.Md,
      },
      {
        gddId: 3,
        positionLevel: 4,
        lineSize: LineHeight.Md,
      },
      {
        gddId: 4,
        positionLevel: 6,
        lineSize: LineHeight.Md,
      },
    ],
  },
  {
    name: 'arena+club',
    text: '',
    cells: [
      {
        gddId: 9,
        positionLevel: 3,
        lineSize: LineHeight.Xs,
      },
      {
        gddId: 10,
        positionLevel: 5,
        lineSize: LineHeight.Md,
      },
    ],
  },
  {
    name: 'club',
    text: 'map.clubChampionshipsName',
    cells: [
      {
        gddId: 5,
        positionLevel: 0,
        lineSize: LineHeight.Xs,
      },
      {
        gddId: 6,
        positionLevel: 2,
        lineSize: LineHeight.Md,
      },
      {
        gddId: 7,
        positionLevel: 4,
        lineSize: LineHeight.Md,
      },
      {
        gddId: 8,
        positionLevel: 6,
        lineSize: LineHeight.Md,
      },
    ],
  },
  {
    name: 'energy',
    text: 'map.energy',
    cells: [
      {
        gddId: 17,
        positionLevel: 0,
        lineSize: LineHeight.Xs,
      },
      {
        gddId: 18,
        positionLevel: 1,
        lineSize: LineHeight.Xs,
      },
      {
        gddId: 19,
        positionLevel: 3,
        lineSize: LineHeight.Md,
      },
      {
        gddId: 20,
        positionLevel: 5,
        lineSize: LineHeight.Md,
      },
      {
        gddId: 21,
        positionLevel: 7,
        lineSize: LineHeight.Md,
      },
      {
        gddId: 22,
        positionLevel: 9,
        lineSize: LineHeight.Md,
      },
    ],
  },
  {
    name: 'starts+energy',
    text: '',
    cells: [
      {
        gddId: 23,
        positionLevel: 2,
        lineSize: LineHeight.Xs,
      },
      {
        gddId: 24,
        positionLevel: 4,
        lineSize: LineHeight.Md,
      },
      {
        gddId: 25,
        positionLevel: 6,
        lineSize: LineHeight.Md,
      },
      {
        gddId: 26,
        positionLevel: 8,
        lineSize: LineHeight.Md,
      },
    ],
  },
  {
    name: 'starts',
    text: 'map.starts',
    cells: [
      {
        gddId: 11,
        positionLevel: 0,
        lineSize: LineHeight.Xs,
      },
      {
        gddId: 12,
        positionLevel: 1,
        lineSize: LineHeight.Xs,
      },
      {
        gddId: 13,
        positionLevel: 3,
        lineSize: LineHeight.Md,
      },
      {
        gddId: 14,
        positionLevel: 5,
        lineSize: LineHeight.Md,
      },
      {
        gddId: 15,
        positionLevel: 7,
        lineSize: LineHeight.Md,
      },
      {
        gddId: 16,
        positionLevel: 9,
        lineSize: LineHeight.Md,
      },
    ],
  },
  {
    name: 'trainer',
    text: 'trainingHall.trainer',
    cells: [
      {
        gddId: 27,
        positionLevel: 0,
        lineSize: LineHeight.Xs,
      },
      {
        gddId: 28,
        positionLevel: 4.5,
        lineSize: LineHeight.Xl,
      },
      {
        gddId: 29,
        positionLevel: 9,
        lineSize: LineHeight.Xl,
      },
    ],
  },
  {
    name: 'map_items+daily_tasks',
    text: 'research.difference',
    cells: [
      {
        gddId: 30,
        positionLevel: 0,
        lineSize: LineHeight.Xs,
      },
      {
        gddId: 31,
        positionLevel: 2,
        lineSize: LineHeight.Md,
      },
      {
        gddId: 32,
        positionLevel: 3,
        lineSize: LineHeight.Xs,
      },
      {
        gddId: 33,
        positionLevel: 5,
        lineSize: LineHeight.Md,
      },
      {
        gddId: 34,
        positionLevel: 7,
        lineSize: LineHeight.Md,
      },
      {
        gddId: 35,
        positionLevel: 9,
        lineSize: LineHeight.Md,
      },
    ],
  },
]
