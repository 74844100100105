import {
  MECHANIC_MAP_ITEMS,
  SET_MAIN_MAP_NEW_REWARDED_ITEMS,
  SET_MAIN_MAP_REWARDED_ITEMS_TIMER,
  SHOW_MAP_TUTORIAL,
  HIDE_MAP_TUTORIAL,
  MANAGE_INDICATOR_VISIBILITY,
  CLAIMED_ITEM,
} from '@/map-phaser-new/constants'
import type {
  AdditionalConfigsInterface,
  IndicatorConfigInterface,
  RewardedItemConfigInterface,
  RewardedItemsPositionsInterface,
  TutorialConfigInterface,
} from '@/map-phaser-new/interfaces'
import { RewardedItem } from '@/map-phaser-new/models'
import { SpecificObjectHandler } from '@/map-phaser-new/utils/abstractClasses'
import { internalAxios } from '@/plugins/vueAxios'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'

export class RewardedItemsHandler extends SpecificObjectHandler {
  private createdRewardedItems: RewardedItem[] = []
  private rewardedItemsTimerEvent: Phaser.Time.TimerEvent
  private indexOfCurrentIndicator: number | null = null
  private activeMapItems: number = 0
  private creationRunning = false

  constructor() {
    super()
  }

  public async setUp(
    activeScene: Phaser.Scene,
    rewardedItemsConfig: RewardedItemConfigInterface,
    additionalConfigs: AdditionalConfigsInterface,
  ): Promise<void> {
    const gameIntegrationStore = usePhaserGameIntegrationStore()
    const eventEmitter = gameIntegrationStore.getPhaserEventEmitter
    this.addRewardedItemsActionsToEmitters(
      activeScene,
      eventEmitter,
      rewardedItemsConfig,
      additionalConfigs,
    )

    const hasRewardItemsMechanic = useResponseTaskStore().hasMechanic(MECHANIC_MAP_ITEMS)
    if (!hasRewardItemsMechanic) return

    await gameIntegrationStore.loadMainMapRewardedItems()
    // add random items from config to map based on store state
    this.setNewRewardedItems(activeScene, rewardedItemsConfig)

    this.initTutorial(activeScene, additionalConfigs.tutorial)

    const careerQuestIndicatorRewardItems = useResponseTaskStore().getCareerQuestIndicators
    if (!careerQuestIndicatorRewardItems) return

    this.manageIndicatorsVisibility(
      activeScene,
      rewardedItemsConfig,
      careerQuestIndicatorRewardItems,
      additionalConfigs.indicator,
    )
  }

  private initTutorial(
    activeScene: Phaser.Scene,
    tutorialConfigData: TutorialConfigInterface,
  ): void {
    const tutorialStore = useTutorialStore()

    if (!tutorialStore.isFocusItem) return

    this.showTutorial(activeScene, tutorialConfigData)
  }

  private showTutorial(
    activeScene: Phaser.Scene,
    tutorialConfigData: TutorialConfigInterface,
  ): void {
    if (!this.createdRewardedItems?.length) return

    this.createdRewardedItems[0].addTutorial(activeScene, tutorialConfigData)
  }

  private hideTutorial(activeScene: Phaser.Scene): void {
    if (!this.createdRewardedItems?.length) return

    for (const existingItem of this.createdRewardedItems) {
      existingItem.removeTutorial(activeScene)
    }
  }

  private setCurrentIndicatorVisibility(visible: boolean): void {
    if (this.indexOfCurrentIndicator === null) return

    const rewardItemWithIndicator = this.createdRewardedItems[this.indexOfCurrentIndicator]
    if (!rewardItemWithIndicator) return

    rewardItemWithIndicator.setIndicatorVisibility(visible)
  }

  private addRewardedItemsActionsToEmitters(
    activeScene: Phaser.Scene,
    eventEmitter: Phaser.Events.EventEmitter,
    rewardedItemsConfig: RewardedItemConfigInterface,
    additionalConfigs: AdditionalConfigsInterface,
  ): void {
    eventEmitter.on(
      SET_MAIN_MAP_NEW_REWARDED_ITEMS,
      () => this.setNewRewardedItems(activeScene, rewardedItemsConfig),
      this,
    )
    eventEmitter.on(
      CLAIMED_ITEM,
      () => {
        if (this.activeMapItems !== 0) this.activeMapItems--
      },
      this,
    )
    eventEmitter.on(
      SET_MAIN_MAP_REWARDED_ITEMS_TIMER,
      (newTime: number) => {
        this.setRewardedItemsTimer(activeScene, newTime, rewardedItemsConfig)
      },
      this,
    )
    eventEmitter.on(
      SHOW_MAP_TUTORIAL,
      (emitData: { tutorialClientId: string; isFocusItem: boolean }) => {
        this.setCurrentIndicatorVisibility(false)
        if (!emitData.isFocusItem) return

        this.showTutorial(activeScene, additionalConfigs.tutorial)
      },
      this,
    )
    eventEmitter.on(
      HIDE_MAP_TUTORIAL,
      (isFocusItem: boolean) => {
        this.setCurrentIndicatorVisibility(true)
        if (!isFocusItem) return

        this.hideTutorial(activeScene)
      },
      this,
    )
    eventEmitter.on(
      MANAGE_INDICATOR_VISIBILITY,
      (questEntities: string[]): void =>
        this.manageIndicatorsVisibility(
          activeScene,
          rewardedItemsConfig,
          questEntities,
          additionalConfigs.indicator,
        ),
      this,
    )
  }

  private setRewardedItemsTimer(
    activeScene: Phaser.Scene,
    newTime: number,
    config: RewardedItemConfigInterface,
  ): void {
    if (this.rewardedItemsTimerEvent) {
      if (newTime <= 0) {
        this.rewardedItemsTimerEvent.paused = true
        return
      }

      activeScene.time.removeEvent(this.rewardedItemsTimerEvent)
      this.rewardedItemsTimerEvent.reset(this.returnTimerConfig(activeScene, newTime, config))
      activeScene.time.addEvent(this.rewardedItemsTimerEvent)

      return
    }

    if (newTime <= 0) return

    this.rewardedItemsTimerEvent = activeScene.time.addEvent(
      this.returnTimerConfig(activeScene, newTime, config),
    )
  }

  private returnTimerConfig(
    activeScene: Phaser.Scene,
    newTime: number,
    config: RewardedItemConfigInterface,
  ): Phaser.Types.Time.TimerEventConfig {
    return {
      delay: newTime * 1000,
      callback: () => {
        this.setNewRewardedItems(activeScene, config, true)
      },
    }
  }

  private async setNewRewardedItems(
    activeScene: Phaser.Scene,
    config: RewardedItemConfigInterface,
    reloadCurrentData: boolean = false,
  ): Promise<void> {
    if (this.creationRunning) return

    this.creationRunning = true
    const phaserGameStore = usePhaserGameIntegrationStore()

    if (reloadCurrentData) {
      // check current data for items from BE
      await internalAxios.put(
        config.updateParamEndpoint + phaserGameStore.getMainMapRewardedItemsData.parameter_id,
      )
    }

    const usedPositions = [...this.createdRewardedItems].map(
      ({ positionX, positionY }: RewardedItem): string => `${positionX}|${positionY}`,
    )

    const availablePositions = [...config.rewardedItemsPositions].filter(
      ({ x, y }: RewardedItemsPositionsInterface): boolean => !usedPositions.includes(`${x}|${y}`),
    )

    this.createdRewardedItems = this.createdRewardedItems.filter(
      (item: RewardedItem): boolean => !item.claimed,
    )

    if (this.createdRewardedItems.length > phaserGameStore.getMainMapRewardedItemsData.value) {
      // number of items from BE is less than number of currently drawed
      // so let's calculate difference
      const itemsCorrectionLength =
        this.createdRewardedItems.length - phaserGameStore.getMainMapRewardedItemsData.value
      for (let index = 0; index < itemsCorrectionLength; index++) {
        // let's mark rewarded item as claimed, it will be removed from this.createdRewardedItems
        // after next call of setNewRewardedItems() with this.createdRewardedItems.filter
        this.createdRewardedItems[index]?.setClaimedAndRemoveImage()
      }
    }

    // loop while uniqueItemsPositions array is equal with current value of value from store and while if it passes safeguards for max count of items
    while (
      availablePositions.length > 0 &&
      this.createdRewardedItems.length < phaserGameStore.getMainMapRewardedItemsData.value &&
      [
        phaserGameStore.getMainMapRewardedItemsData.value, // number of items to create according BE
        phaserGameStore.getMainMapRewardedItemsData.max_value, // maximum number of items according BE
        config.rewardedItemsPositions.length, // maximum number of items according FE
      ].every((value: number): boolean => this.activeMapItems < value)
    ) {
      const point = Phaser.Math.RND.pick(availablePositions)
      availablePositions.splice(availablePositions.indexOf(point), 1)

      this.addRewardedItemToMap(activeScene, point, config)
      this.activeMapItems++
    }

    this.creationRunning = false
  }

  private addRewardedItemToMap(
    activeScene: Phaser.Scene,
    position: RewardedItemsPositionsInterface,
    config: RewardedItemConfigInterface,
  ): void {
    // if random position from config is not included in store, create item and add it to scene
    const rewardItem = new RewardedItem(position.x, position.y)
    rewardItem.addToMap(activeScene, config)
    this.createdRewardedItems.push(rewardItem)
  }

  private removeCurrentIndicator(activeScene: Phaser.Scene): void {
    if (this.indexOfCurrentIndicator === null) return

    const rewardItemWithIndicator = this.createdRewardedItems[this.indexOfCurrentIndicator]
    if (!rewardItemWithIndicator) return

    rewardItemWithIndicator.removeIndicator(activeScene)
    this.indexOfCurrentIndicator = null
  }

  private manageIndicatorsVisibility(
    activeScene: Phaser.Scene,
    rewardedItemsConfig: RewardedItemConfigInterface,
    questEntities: string[],
    indicatorConfig?: IndicatorConfigInterface,
  ): void {
    if (!questEntities.length) {
      // handle case, when user claim all rewards without indicator - indicator get stuck on map
      this.removeCurrentIndicator(activeScene)
      return
    }

    if (
      (questEntities.length && questEntities[0] !== MECHANIC_MAP_ITEMS) ||
      !indicatorConfig ||
      useTutorialStore().getIsTutorial
    )
      return

    const alreadyHasIndicator = this.createdRewardedItems.filter(
      (item: RewardedItem) => item.indicator,
    )
    if (alreadyHasIndicator.length > 0) return

    // the 'show' method is enough because the claim itself solves the removal of the reward item indicator (e.g., helmets)
    if (questEntities.length) {
      this.showIndicator(activeScene, indicatorConfig, rewardedItemsConfig)
      return
    }
  }

  private showIndicator(
    activeScene: Phaser.Scene,
    indicatorConfig: IndicatorConfigInterface,
    rewardedItemsConfig: RewardedItemConfigInterface,
  ): void {
    if (!this.createdRewardedItems?.length) return

    const tutorialStore = useTutorialStore()
    const tasksStore = useResponseTaskStore()

    if (tutorialStore.isTutorial || tasksStore?.getQuestCompleted?.unlocks) return

    this.indexOfCurrentIndicator = this.createdRewardedItems.findIndex(
      (item: RewardedItem): boolean =>
        !item.claimed && item.indicator === null && !item.hasTutorial,
    )
    if (this.indexOfCurrentIndicator === -1) {
      this.indexOfCurrentIndicator = null
      return
    }

    const positionIndicatorConfig = rewardedItemsConfig.positionIndicatorConfig
    if (!positionIndicatorConfig) return

    this.createdRewardedItems[this.indexOfCurrentIndicator].addIndicator(
      activeScene,
      indicatorConfig,
      positionIndicatorConfig,
    )
  }
}
