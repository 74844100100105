<template>
  <main class="event-pass flexing flex-col flex-grow w-full relative">
    <event-pass1-header />
    <event-pass1-body />
    <event-pass1-footer />
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import EventPass1Body from './EventPass1Body.vue'
import EventPass1Footer from './EventPass1Footer.vue'
import EventPass1Header from './EventPass1Header.vue'

export default defineComponent({
  name: 'EventPass1Main',
  components: {
    EventPass1Header,
    EventPass1Body,
    EventPass1Footer,
  },
})
</script>
