<template>
  <info-popup
    v-if="visibleSettings"
    :popup-title="$t('equipmentShop.equipmentSettings')"
    @close="$emit('close')"
  >
    <div :class="'equipment-settings flex flex-col items-center'" data-cy="equipment-settings">
      <section
        v-for="(setting, i) in settingsData"
        :key="i"
        class="flex flex-col items-center relative"
      >
        <p
          class="text-40 uppercase"
          :class="{ 'text-texts-standard-name': $isWsm, 'text-texts-standard-default': $isSsm }"
        >
          {{ $t(setting.texts.header) }}
        </p>
        <app-icon
          v-tippy="{
            theme: 'WSM',
            content: $t(setting.texts.tooltip),
            placement: 'left',
          }"
          icon-name="info-50"
        />
        <app-toggle
          :texts="[$t(setting.toggleValues.texts[0]), $t(setting.toggleValues.texts[1])]"
          :selected-button="setting.value"
          @action="setting.action"
        />
      </section>
    </div>
  </info-popup>
</template>

<script lang="ts">
import AppToggle from '@/components/GlobalComponents/AppToggle.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface SettingsData {
  type: number
  value: number
  texts: {
    header: string
    tooltip: string
  }
  toggleValues: {
    texts: string[]
  }
  action: CallableFunction
}

interface ComponentData {
  settingsData: {
    repair: SettingsData
    bestEquip: SettingsData
  }
}

export default defineComponent({
  name: 'EquipmentSetting',
  components: {
    InfoPopup,
    AppToggle,
  },
  props: {
    visibleSettings: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      settingsData: {
        repair: {
          type: 1,
          value: 2,
          texts: {
            header: 'equipmentShop.automaticRepairs',
            tooltip: 'equipmentShop.autoRepairTooltip',
          },
          toggleValues: {
            texts: ['common.on', 'common.off'],
          },
          action: this.autoRepairClicked,
        },
        bestEquip: {
          type: 2,
          value: 2,
          texts: {
            header: 'equipmentShop.useBestEquipment',
            tooltip: 'equipmentShop.useBestEquipmentTooltip',
          },
          toggleValues: {
            texts: ['common.on', 'common.off'],
          },
          action: this.bestEquipClicked,
        },
      },
    }
  },
  computed: {
    ...mapState(useUserStore, {
      autoRepair: 'getAutoRepair',
      bestEquip: 'getAutoBestEquip',
    }),
  },
  created(): void {
    this.settingsData.repair.value = this.autoRepair ? 1 : 2
    this.settingsData.bestEquip.value = this.bestEquip ? 1 : 2
  },
  methods: {
    ...mapActions(useUserStore, ['setProfileAttributes']),
    autoRepairClicked(): void {
      this.setProfileAttributes({
        name: 'auto_repair',
        value: Number(!this.autoRepair),
      })
    },
    bestEquipClicked(): void {
      this.setProfileAttributes({
        name: 'auto_best_equip',
        value: Number(!this.bestEquip),
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.equipment-settings {
  width: 64.375rem;
  height: 30rem;
  padding: 0 1rem;

  section {
    width: 100%;

    > p {
      margin: 2.188rem 0;
      width: 45rem;
    }

    .icon-info-50 {
      position: absolute;
      right: 6rem;
      top: 2.4rem;
      width: 3.125rem;
      height: 3.125rem;
    }

    &:first-child {
      padding-bottom: 3.75rem;
      @if $isWsm {
        border-bottom: 0.063rem solid #438eb8;
      }
      @if $isSsm {
        border-bottom: 0.125rem solid rgba(#fff, 0.3);
      }
    }
  }
}
</style>
