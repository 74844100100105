<template>
  <footer class="mail-footer flex">
    <section class="mail-footer-container flex justify-between items-center">
      <app-button
        v-tippy="{
          theme: 'WSM',
          content: !blockedUsers ? $t('post.noBlockedUsers') : null,
          placement: 'top',
          textAlign: 'center',
        }"
        btn-id="btn-mail-blocked"
        btn-size="md"
        btn-type="primary"
        :btn-text="$t('post.blockedUsers')"
        :disabled="!blockedUsers"
        @click="goTo('MailBlocked')"
      />
      <app-button
        btn-id="btn-mail-new"
        btn-size="md"
        btn-type="secondary"
        class="mail-new-btn"
        @click="goTo('MailNew')"
      >
        <span class="mail-new-btn-icon" />
        <span class="mail-new-btn-text">{{ $t('post.newMessage') }} </span>
      </app-button>
    </section>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MailFooter',
  props: {
    blockedUsers: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectOption', 'selectAll'],
  methods: {
    goTo(view: string): void {
      this.$router.push({ name: this.$getWebView(view) })
    },
  },
})
</script>

<style lang="scss" scoped>
.mail-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 8rem;
  background: rgb(14 14 14 / 82%);
  box-shadow: 0px 0px 32px 16px rgb(14 14 14 / 82%);

  &-container {
    width: 98%;
    margin: 0 auto;
  }

  #btn-mail-blocked,
  #btn-mail-new {
    width: 29.375rem;
    white-space: nowrap;
  }

  .mail-new-btn {
    flex-direction: row;

    &-icon {
      background: url($path-mail + 'new-message.avif') 0 0 no-repeat;
      width: 3.5rem;
      height: 3.5rem;
      background-size: contain;
      position: absolute;
      left: -2rem;
    }

    &-text {
      padding-left: 4rem;
      white-space: normal;
    }
  }
}
</style>
