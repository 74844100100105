<template>
  <div v-if="message" id="mail-detail-view" class="app-page-wrapper mail absolute">
    <menu-component menu-type="mail" :title="$t('post.heeader')" />
    <section class="w-full h-full safe-area">
      <section class="mail-detail-wrapper text-texts-standard-default text-50">
        <div
          class="mail-detail-head table-head-standard flex items-center justify-between relative"
        >
          <p class="head-user">
            {{ $t('post.sender') }}
          </p>
          <p class="head-date">
            {{ $t('post.date') }}
          </p>
        </div>
        <div class="mail-detail-table flex justify-between items-center relative">
          <div
            v-if="userId !== message.sender.id"
            class="icon-settings-wrapper flexing absolute clickable-skew-element cursor-pointer"
            @click="showOptions = !showOptions"
          >
            <app-icon icon-name="settings" />
          </div>
          <div v-if="showOptions" class="blocked-dropdown cursor-pointer">
            <ul class="blocked-options">
              <li class="blocked-option" @click="blockUser()">
                <p>{{ $t('post.blockSender') }}</p>
              </li>
            </ul>
          </div>
          <app-user
            :id="message.sender.id || ''"
            :country="message.sender.country.three_letter_code"
            :name="message.sender.username"
            :badges-data="message.sender.event_badges ?? null"
          />
          <div class="table-date italic">
            <span class="text-32 font-bold pr-2">
              {{ getFormattedDateFromYMD(message.sent_at) }}</span
            >
            <span class="text-28"> {{ getFormattedTimeFromHMS(message.sent_at) }}</span>
          </div>
        </div>
        <div class="mail-detail-message text-texts-standard-additional text-left">
          <p class="detail-subject-title text-30 uppercase">
            {{ $t('post.subject') }}
          </p>
          <p class="detail-subject-content text-36 font-bold flex items-center">
            {{ message.subject }}
          </p>
          <p class="detail-message-title text-30 uppercase">
            {{ $t('post.message') }}
          </p>
          <p class="detail-message-content text-32">
            <app-scrollbar width="100%" height="100%" scroll="y" slice="y">
              <p v-html="message.text.replace(/(?:\n)/g, '<br />')" />
            </app-scrollbar>
          </p>
        </div>
        <footer class="flex justify-between mail-detail-footer">
          <div class="footer-left">
            <app-button
              btn-id="btn-mail-delete"
              btn-size="md"
              btn-type="danger"
              :btn-text="$t('post.delete')"
              class="mail-delete-btn"
              @click="showDeleteConfirm = true"
            />
          </div>
          <div class="footer-right flex justify-end">
            <app-button
              btn-id="btn-mail-mark"
              btn-size="md"
              btn-type="primary"
              :btn-text="$t('post.markAsUnread')"
              class="mail-mark-btn"
              @click="markAsUnread()"
            />
            <app-button
              btn-id="btn-mail-forward"
              btn-size="md"
              btn-type="primary"
              :btn-text="$t('post.forward')"
              class="mail-forward-btn"
              @click="
                $router.push({
                  name: $getWebView('MailNew'),
                  query: { type: FORWARD, id: messageId },
                })
              "
            />
            <app-button
              btn-id="btn-mail-reply"
              btn-size="md"
              btn-type="secondary"
              :btn-text="$t('post.reply')"
              class="mail-reply-btn"
              @click="
                $router.push({
                  name: $getWebView('MailNew'),
                  query: { type: REPLY, id: messageId },
                })
              "
            />
          </div>
        </footer>
      </section>
      <confirm-popup
        v-if="showDeleteConfirm"
        :not-multi="true"
        :button-data="confirmButtonData"
        :popup-title="$t('post.deleteMessage')"
        :single-button-text="$t('common.confirm')"
        @close="showDeleteConfirm = false"
        @action="deleteMessage()"
      >
        <div>
          <p class="block text-36 text-texts-standard-default">
            {{ $t('post.deleteMessageConfirm') }}
          </p>
        </div>
      </confirm-popup>
    </section>
  </div>
</template>

<script lang="ts">
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import {
  FORWARD,
  REPLY,
  mailBlockEndpoint,
  mailDetailEndpoint,
  mailMarkUnreadEndpoint,
  mailReceivedEndpoint,
} from '@/globalVariables'
import { getFormattedDateFromYMD, getFormattedTimeFromHMS } from '@/helpers'
import { useMailStore } from '@/store/pinia/mailStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { Nullable } from '@/interfaces/utils'
import type { MailMessage } from '@/interfaces/MailMessage'
import type { MailBlockApiResponse } from '@/interfaces/responses/mail/MailBlockApiResponse'
import { useUserStore } from '@/store/pinia/userStore'

interface ComponentData {
  message: Nullable<MailMessage>
  confirmButtonData: {
    btnId: string
    btnType: string
    btnSize: string
  }
  showDeleteConfirm: boolean
  messageId: string
  FORWARD: typeof FORWARD
  REPLY: typeof REPLY
  showOptions: boolean
}

export default defineComponent({
  name: 'MailDetail',
  components: {
    AppUser,
    ConfirmPopup,
  },
  data(): ComponentData {
    return {
      message: null,
      confirmButtonData: {
        btnId: 'mail-delete-confirm',
        btnType: 'danger',
        btnSize: 'md',
      },
      showDeleteConfirm: false,
      messageId: '',
      FORWARD,
      REPLY,
      showOptions: false,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userId: 'getUserId',
    }),
  },
  async created(): Promise<void> {
    this.messageId = Array.isArray(this.$route.params.id) ? '' : this.$route.params.id
    this.loadMessage()
    this.loadReceivedMail()
  },
  methods: {
    ...mapActions(useMailStore, ['loadReceivedMail']),
    getFormattedDateFromYMD,
    getFormattedTimeFromHMS,
    async loadMessage(): Promise<void> {
      this.message = await this.$axios.get<{}, MailMessage>(
        `${mailDetailEndpoint}${this.messageId}`,
      )
    },
    async deleteMessage(): Promise<void> {
      await this.$axios.delete<{}, number, { messages_ids: string[] }>(mailReceivedEndpoint, {
        data: {
          messages_ids: [this.messageId],
        },
      })

      this.$router.push({ name: this.$getWebView('MailReceived') })
    },
    async markAsUnread(): Promise<void> {
      await this.$axios.put<{}, number, { messages_ids: string[] }>(mailMarkUnreadEndpoint, {
        messages_ids: [this.messageId],
      })

      this.$router.push({ name: this.$getWebView('MailReceived') })
    },
    async blockUser(): Promise<void> {
      try {
        await this.$axios.post<{}, MailBlockApiResponse>(mailBlockEndpoint, {
          blocked_user_id: this.message.sender.id,
        })
        this.showOptions = !this.showOptions
        this.$router.push({ name: this.$getWebView('MailBlocked') })
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.mail-detail {
  &-wrapper {
    width: 98%;
    margin: 2.375rem auto;
  }

  &-head {
    .head-user {
      width: 35rem;
    }

    .head-date {
      width: 30rem;
    }
  }

  &-table {
    height: 5rem;
    @if $isWsm {
      background-color: #0b1e37;
    }
    @if $isSsm {
      background-color: #22222c;
    }
    .app-user {
      width: 35rem;
      justify-content: flex-start;
      padding-left: 6rem;
    }

    .table-date {
      width: 30rem;
    }

    .icon-settings-wrapper {
      width: 5.688rem;
      height: 5rem;
      border: solid 0.125rem #315f7f;
      background-image: linear-gradient(to top, #112437, #1b4d6d);
      transform: $skewX-value;
      top: -2rem;
      left: -1rem;

      & > div {
        transform: $skewX-revert-value;
        width: 2.563rem;
        height: 2.563rem;
      }
    }
  }

  &-message {
    height: 35.938rem;
    padding: 1rem 1.5rem;
    @if $isWsm {
      border: solid 0.063rem #6c9ec4;
      background-color: #236d9b;
    }
    @if $isSsm {
      border: solid 0.125rem #4c648f;
      background-color: #23293e;
    }

    .detail-message-title {
      margin-top: 0.5rem;
    }

    .detail-subject-content,
    .detail-message-content {
      padding: 1.875rem;
      @if $isWsm {
        border: solid 0.125rem rgba(68, 122, 165, 0.36);
        background-color: #214b69;
      }
      @if $isSsm {
        border: solid 0.125rem #4c648f;
        background-color: #1d1f2c;
      }
    }

    .detail-subject-content {
      height: 5rem;
    }

    .detail-message-content {
      height: 22.5rem;
    }
  }

  &-footer {
    margin-top: 2rem;

    .mail-delete-btn {
      width: 19.438rem;
    }

    .mail-mark-btn {
      width: 28.813rem;
    }

    .mail-forward-btn,
    .mail-reply-btn {
      width: 25.688rem;
      margin-left: 0.5rem;
    }
  }
}

.blocked-dropdown {
  top: 3rem;
  position: absolute;
  background-image: linear-gradient(to top, #0f2839, #021b2c),
    linear-gradient(to bottom, #1b2f47, #1b2f47);
  box-shadow:
    0 0.188rem 0.313rem 0 rgba(0, 0, 0, 0.35),
    inset 0 0 0.5rem 0.5rem rgba(117, 182, 231, 0.2);
  border: solid 0.125rem #75b6e7;
  padding: 0.938rem;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
}

.blocked-options {
  width: 100%;
  max-height: 30rem;
  color: #fff;
}

.blocked-option {
  font-size: 2rem;
  background: none;

  &:hover,
  &:focus {
    background: #f6c717;
    background-image: linear-gradient(to top, #fff284, #f6c717);
    color: #233441;
  }
}
</style>
