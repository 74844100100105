<template>
  <button
    ref="googleLoginBtn"
    type="button"
    class="google-login-button flexing w-full text-28 px-4 space-x-4"
    :class="`is-${status}`"
    :disabled="isDisabled"
  >
    <svg
      v-if="monochrome"
      class="google-login-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 488 512"
    >
      <path
        d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
        fill="currentColor"
      />
    </svg>
    <svg v-else class="google-login-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
        fill="#4285F4"
      />
      <path
        d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
        fill="#34A853"
      />
      <path
        d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
        fill="#FBBC05"
      />
      <path
        d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
        fill="#EA4335"
      />
      <path d="M1 1h22v22H1z" fill="none" />
    </svg>
    <span
      class="google-login-title"
      :class="{
        'font-bold': $isWsm,
        'font-medium': $isSsm,
        '!mr-auto': status !== ConnectedAccountStatus.NotConnected,
      }"
      v-html="
        context === LoginFormContext.LOGIN || status === ConnectedAccountStatus.NotConnected
          ? $replacePlaceholder($t('homepage.continue_with_platform'), '{platform}', 'Google')
          : getConnectedAccountStatusText(status)
      "
    />
    <app-icon v-if="status === ConnectedAccountStatus.Connected" icon-name="done-sm" />
  </button>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { authApiUrl, dispatchGameLogin, googleId } from '@/globalVariables'
import { gameToken as GAME_TOKEN, getConnectedAccountStatusText } from '@/helpers'
import { useCoreStore } from '@/store/pinia/coreStore'
import { mapActions, mapState } from 'pinia'
import { LoginFormContext, ConnectedAccountStatus } from '@/enums/LoginForm'
import { useUserStore } from '@/store/pinia/userStore'

interface ComponentData {
  LoginFormContext: typeof LoginFormContext
  ConnectedAccountStatus: typeof ConnectedAccountStatus
  child: Window
}

export default defineComponent({
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    monochrome: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String as PropType<ConnectedAccountStatus>,
      default: ConnectedAccountStatus.NotConnected,
      validator: (value: ConnectedAccountStatus) =>
        Object.values(ConnectedAccountStatus).includes(value),
    },
    context: {
      type: String as PropType<LoginFormContext>,
      default: '',
      validator: (value: LoginFormContext) => Object.values(LoginFormContext).includes(value),
    },
  },
  emits: ['googleConnection'],
  data(): ComponentData {
    return {
      LoginFormContext,
      ConnectedAccountStatus,
      child: null,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userId: 'getUserId',
    }),
  },
  mounted() {
    if (this.status !== ConnectedAccountStatus.NotConnected) return

    this.onLoad()
  },
  methods: {
    ...mapActions(useCoreStore, ['setLoadingScreen']),
    getConnectedAccountStatusText,
    onLoad(): void {
      // 1. set up Google auth popup
      this.$refs.googleLoginBtn.addEventListener('click', (): void => {
        const redirect_uri =
          this.context === LoginFormContext.CONNECTION
            ? `${authApiUrl}api/oauth/connect/google/callback`
            : `${authApiUrl}api/oauth/register/google/callback`
        const auth_endpoint = 'https://accounts.google.com/o/oauth2/v2/auth'
        const client_id = googleId

        // 2. set up popup
        const p = new URLSearchParams()
        p.set('client_id', client_id)
        p.set('response_type', 'code')
        p.set('prompt', 'select_account')
        p.set('scope', 'openid email profile')
        if (this.context === LoginFormContext.CONNECTION) {
          // p.set('state', `${GAME_TOKEN}|${this.userId}`) // alt. case for BE
          p.set('state', GAME_TOKEN + this.userId)
        } else {
          p.set('state', GAME_TOKEN)
        }
        p.set('redirect_uri', redirect_uri)

        // 3. open popup
        this.child = window.open(
          auth_endpoint + '?' + p.toString(),
          'a',
          ['popup', 'width=550', 'height=500', 'top=100', 'left=100'].join(','),
        )

        window.addEventListener('storage', (e: StorageEvent): void => {
          // handle the local storage change event
          if (e.key === 'token') {
            localStorage.removeItem('last_id_group')

            // 4. close popup
            this.child.close()
            this.setLoadingScreen(true)

            try {
              this.$axios.post<{}, true>(dispatchGameLogin)
            } catch (error: unknown) {
              console.error(error)
            }
            this.$router.push('/')
          }
          if (e.key === 'googleConnection') {
            // close popup
            this.child.close()
            // emit data refresh event to parent
            this.$emit('googleConnection')
          }
        })
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.google-login {
  &-button {
    height: 4.25rem;
    color: #000;
    background-color: #fff;
    border: 0.188rem solid darken(#fff, 5%);

    &:hover {
      background-color: darken(#fff, 5%);
    }

    &:active {
      transform: scale(0.95);
    }

    &:not(.is-not-connected) {
      color: #fff;
      background-color: transparent;
      background-image: linear-gradient(to top, rgba(#3be6c9, 0.25) 0%, transparent);
      border: solid 0.125rem #3be6c9;
      pointer-events: none;
      cursor: default;

      &:hover {
        background-color: inherit;
      }

      &:active {
        transform: none;
      }
    }
  }

  &-icon {
    width: 1.5em;
    height: 1.5em;
    margin: -0.25rem;
  }

  &-title {
    margin-top: 0.25rem;
  }
}

// Ked zacneme pouzivat mode: 'jit', tak toto moze ist doprec.
// https://v2.tailwindcss.com/docs/just-in-time-mode
.\!mr-auto {
  margin-right: auto !important;
}
</style>
