import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const carsLeftTopToRightTopPath: PathObjectInterface = {
  mainPath: { x: 540, y: 430 },
  spline: [
    540, 430, 683, 601, 638, 733, 398, 976, 467, 1103, 592, 1139, 715, 1115, 820, 1069, 851, 1007,
    842, 954, 890, 901, 1187, 750, 1586, 707, 1775, 661, 1948, 640, 2073, 618, 2171, 601, 2450, 548,
    2563, 508, 2721, 515, 2918, 536, 2997, 546, 3069, 568, 3175, 618, 3266, 704, 3338, 774, 3424,
    719, 3448, 664, 3458, 610,
  ],
}
