<template>
  <div class="lock-tooltip">
    <div class="lock-tooltip-title flexing">
      <p class="uppercase font-medium text-30">
        {{ $t('gamePass.multiplier') }}
        <span class="text-texts-standard-important"> {{ `${state.value}x` }} </span>
      </p>
    </div>
    <div
      v-if="multiplierType === Multiplier.AutumnFair"
      class="lock-tooltip-info px-12 py-6"
      v-html="
        $replacePlaceholder(
          `${$te('taskMultiplierInfo2')} ${eventAutumnFairTooltipInfo}.`,
          '{pass}',
          $te('eventpass'),
        )
      "
    />
    <div
      v-else
      class="lock-tooltip-info px-12 py-6"
      v-html="
        state.quest_gdd_id
          ? $replacePlaceholder(tooltipConfig[index], '{quest_number}', state.quest_gdd_id)
          : tooltipConfig[index]
      "
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { useTaskBoardStore } from '@/store/pinia/events/taskBoardStore'
import { Multiplier } from '@/globalVariables'

import type { PropType } from 'vue'
import type { MultiplicatorItem } from '@/types/userProfile'
import type { EventMultiplier } from '@/interfaces/responses/events/task-board/TaskBoardConfigApiResponse'

interface ComponentData {
  tooltipConfig: Record<number, string>
  Multiplier: typeof Multiplier
}

export default defineComponent({
  name: 'SuperMultiplierTooltip',
  props: {
    state: {
      type: Object as PropType<MultiplicatorItem>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    multiplierType: {
      type: String as PropType<Multiplier>,
      default: null,
      validator: (value: string): boolean => {
        return Object.values(Multiplier).includes(value as Multiplier)
      },
    },
  },
  data(): ComponentData {
    return {
      tooltipConfig: {
        // (i) Tooltips will only be possible for 2-4 states
        1: this.$replacePlaceholder(
          this.$t('common.mainCareerTaskRequired'),
          '%s',
          '<span class="text-texts-standard-important">{quest_number}</span>',
        ),
        2: this.$replacePlaceholder(
          this.$t('gamePass.availableInPassPro'),
          '{passpro}',
          `<span class="text-texts-standard-important">${this.$t('gamePass.gamePassPro')}</span>`,
        ),
        3: this.$replacePlaceholder(
          this.$t('gamePass.availableInPassElite'),
          '{passelite}',
          `<span class="text-texts-standard-important">${this.$t('gamePass.gamePassElite')}</span>`,
        ),
      },
      Multiplier,
    }
  },
  computed: {
    ...mapState(useTaskBoardStore, {
      autumnConfig: 'getConfig',
    }),
    eventAutumnFairTooltipInfo(): string {
      if (!this.autumnConfig) return null

      const autumnMultiplierTier = this.autumnConfig?.multipliers_tooltip_info.find(
        (eventMultiplier: EventMultiplier): boolean => eventMultiplier.tier === this.state?.tier,
      )

      return (
        'Tier ' +
        autumnMultiplierTier.tier +
        ': ' +
        autumnMultiplierTier.multipliers
          .map((multiplier: number): string => `${multiplier}x`)
          .join(', ')
      )
    },
  },
})
</script>

<style lang="scss" scoped></style>
