<template>
  <popup-window
    popup-type="submit"
    :popup-data="getLeaveClubData"
    @close="$emit('close', false)"
    @action="leaveClubAction"
  >
    <div>
      <p class="text-texts-standard-default text-36 my-4 font-bold">
        {{ $t('club.confirmLeaveClub') }}
      </p>
      <p class="text-texts-standard-default text-36">
        {{ $replacePlaceholder($t('club.confirmLeaveClubInfo'), '%s', REJON_CLUB_HOURS) }}
      </p>
    </div>
  </popup-window>
</template>

<script lang="ts">
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import { REJON_CLUB_HOURS } from '@/globalVariables'
import ioService from '@/socket/SocketService'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useManagementStore } from '@/store/pinia/clubs/managementStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PopupData } from '@/interfaces/PopupData'

interface ComponentData {
  REJON_CLUB_HOURS: typeof REJON_CLUB_HOURS
}

export default defineComponent({
  name: 'ClubsLeavePopup',
  components: {
    PopupWindow,
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      REJON_CLUB_HOURS,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userData: 'getUserData',
      userId: 'getUserId',
    }),
    getLeaveClubData(): PopupData {
      return this.$filters.$submitPopupData({
        title: this.$t('club.leaveClub'),
        btnText: null,
        btnType: 'danger',
        btnId: 'confirm-leave-club-button',
      })
    },
  },
  methods: {
    ...mapActions(useUserStore, ['loadUserData']),
    ...mapActions(useManagementStore, ['leaveClub']),
    ...mapActions(useClubStore, {
      clearUserClub: 'clearUserClub',
    }),
    async leaveClubAction(): Promise<void> {
      this.$emit('close', false)
      await this.leaveClub(this.userData.club_stats.id)
      await this.$router.push({ name: 'LayoutView' })
      ioService.leaveClub(this.userId)
      this.clearUserClub()
      this.loadUserData()
    },
  },
})
</script>
