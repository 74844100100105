<template>
  <div class="last-opened-wrapper flex-column">
    <div class="last-opened-wrapper-header flex justify-between">
      <p
        class="last-opened-wrapper-header-icon text-30 text-texts-standard-additional pl-5 uppercase not-italic"
      >
        {{ $replacePlaceholder($t('grandPrize.lastOpened'), '{grandprize}', GRAND_PRIZE) }}
      </p>
      <p class="last-opened-wrapper-header-text text-30 text-texts-standard-default pr-5 uppercase">
        {{ $replacePlaceholder($t('grandPrize.dailyVideoAds'), '{grandprize}', GRAND_PRIZE) }}:
        <span class="text-texts-standard-important">{{ remainingAds }} </span>/{{ totalAds }}
      </p>
    </div>
    <div class="last-opened-wrapper-box flexing">
      <p
        v-if="openedRewards.length === 0 || lastOpenedGp.item_state === GRAND_PRIZE_UNOPENED"
        class="text-30 text-texts-standard-important pr-5 uppercase"
      >
        {{ $replacePlaceholder($t('grandPrize.openGrandPrize'), '{grandprize}', GRAND_PRIZE) }}
      </p>
      <div
        v-else
        class="last-opened-wrapper-box-inner w-full h-full justify-between flex"
        :class="{
          'pl-5': $isWsm,
        }"
      >
        <div class="last-opened-wrapper-box-left-side flex">
          <div class="last-opened-wrapper-box-left-side-content flexing">
            <app-main-icon
              class="absolute"
              :icon-name="`gp-${
                lastOpenedGp.parameters.find((item) => item.name === GRAND_PRIZE_NAME)?.value
              }`"
              :icon-size="120"
            />
            <div
              class="flex-col text-30 text-texts-standard-default ml-7 last-opened-wrapper-box-left-side-content-text not-italic"
            >
              <p class="text-texts-standard-important uppercase font-bold">
                {{ $replacePlaceholder($t('grandPrize.tierBasic'), '{grandprize}', GRAND_PRIZE) }}
              </p>
              <p class="last-opened-wrapper-box-left-side-content-text-subtext">
                {{
                  $replacePlaceholder($t(getTextForLastOpenedGp()), '{grandprize}', GRAND_PRIZE)
                }}.
              </p>
            </div>
          </div>
          <div class="last-opened-wrapper-box-left-side-icons">
            <div v-for="(reward, i) in openedRewards" :key="i" class="mr-2.5">
              <grand-prize-badges :badge="reward.rarity" :reward="reward" size="md" />
            </div>
          </div>
        </div>
        <div class="last-opened-wrapper-box-right-side">
          <div
            v-if="lastOpenedGp.expire_in && !isLastClaimed"
            class="text-28 last-opened-wrapper-box-right-side text-texts-standard-default uppercase flexing flex-col"
          >
            <div class="flex mb-2">
              <p class="pr-2">{{ $t('common.remaining') }}:</p>
              <div class="icon-time" />
              <vue-countdown
                v-slot="{ days, hours, minutes, seconds }"
                :time="lastOpenedGp.expire_in * 1000"
                @end="removeOpenedRewards"
              >
                <p class="text-texts-standard-important pl-2 font-bold">
                  {{ formatTime(days, hours, minutes, seconds) }}
                </p>
              </vue-countdown>
            </div>
            <app-button
              v-if="openedRewards.length > 0"
              class="last-opened-wrapper-box-right-side-button"
              btn-type="confirm"
              :btn-text="$t('common.claim')"
              :disabled="isClaiming"
              @click="$debounce(claimReward, [false])"
            />
            <app-button
              v-else
              class="last-opened-wrapper-box-right-side-button"
              btn-type="confirm"
              :btn-text="$t('grandPrize.open')"
              @click="showDetailScreen"
            />
          </div>
          <div
            v-else
            class="text-32 last-opened-wrapper-box-right-side text-texts-standard-positive uppercase flexing font-bold"
          >
            <p class="pr-2">
              {{ $t('common.rewardsClaimed') }}
            </p>
            <app-icon icon-name="done-md" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import GrandPrizeBadges from '@/components/GrandPrize/GrandPrizeBadges.vue'
import {
  GRAND_PRIZE,
  GRAND_PRIZE_CLAIMED,
  GRAND_PRIZE_OPENED,
  GRAND_PRIZE_UNOPENED,
  grandPrizesClaimEndPoint,
  grandPrizesLastOpenedEndPoint,
  GRAND_PRIZE_NAME,
} from '@/globalVariables'
import { formatTime } from '@/helpers'
import { defineComponent } from 'vue'

import type { GrandPrizeLastOpenedApiResponse } from '@/interfaces/responses/grandPrize/GrandPrizeApiResponse'
import type Reward from '@/interfaces/Reward'

interface ComponentData {
  GRAND_PRIZE: typeof GRAND_PRIZE
  GRAND_PRIZE_UNOPENED: typeof GRAND_PRIZE_UNOPENED
  GRAND_PRIZE_NAME: typeof GRAND_PRIZE_NAME
  lastOpenedGp: GrandPrizeLastOpenedApiResponse
  isClaiming: boolean
}

export default defineComponent({
  name: 'GrandPrizeLastOpened',
  components: {
    GrandPrizeBadges,
  },
  props: {
    totalAds: {
      type: Number,
      default: 0,
    },
    remainingAds: {
      type: Number,
      default: 0,
    },
  },
  data(): ComponentData {
    return {
      GRAND_PRIZE,
      GRAND_PRIZE_UNOPENED,
      GRAND_PRIZE_NAME,
      lastOpenedGp: {} as GrandPrizeLastOpenedApiResponse,
      isClaiming: false,
    }
  },
  computed: {
    openedRewards(): Reward[] {
      if (!this.lastOpenedGp || Object.keys(this.lastOpenedGp).length === 0) {
        return []
      }

      return this.lastOpenedGp.rewards.filter(
        (reward: Reward): boolean =>
          reward.slot_state === GRAND_PRIZE_OPENED || reward.slot_state === GRAND_PRIZE_CLAIMED,
      )
    },
    isLastClaimed(): boolean {
      return this.lastOpenedGp?.item_state === GRAND_PRIZE_CLAIMED
    },
  },
  created(): void {
    this.loadLastOpenedGp()
  },
  methods: {
    formatTime,
    async loadLastOpenedGp(): Promise<void> {
      const response = await this.$axios.get<{}, GrandPrizeLastOpenedApiResponse>(
        grandPrizesLastOpenedEndPoint,
      )

      this.lastOpenedGp = response
      this.isClaiming = false
    },
    async claimReward(): Promise<void> {
      this.isClaiming = true
      await this.$axios.put<{}, boolean, { grand_prizes_id: string }>(grandPrizesClaimEndPoint, {
        grand_prizes_id: this.lastOpenedGp.id,
      })

      this.loadLastOpenedGp()
    },
    removeOpenedRewards(): void {
      this.lastOpenedGp = null
      this.loadLastOpenedGp()
    },
    showDetailScreen(): void {
      this.$router.push({
        name: this.$getWebView('GrandPrizeMain'),
        params: { id: this.lastOpenedGp.id },
      })
    },
    updateLastOpenedGp(newGp: GrandPrizeLastOpenedApiResponse): void {
      this.lastOpenedGp = newGp
    },
    getTextForLastOpenedGp(): string {
      return 'grandPrize.' + this.lastOpenedGp.stats?.gp_progress_name ?? ''
    },
  },
})
</script>

<style scoped lang="scss">
.last-opened-wrapper {
  height: 12.938rem;
  width: 98%;

  &-header {
    text-align: left;
    height: 2.844rem;
    position: relative;

    &-text {
      position: absolute;
      right: 0rem;
    }

    &-icon {
      position: absolute;
      left: 0rem;
      background: url($path-grand-prize + 'warehouse/BG_BOTTOM_BOX_HEADER.avif') center no-repeat;
      background-size: cover;
      width: 40.875rem;
    }
  }

  &-box {
    height: 10.094rem;

    @if $isWsm {
      background-color: #1b2f47;
      border: 0.063rem solid #5ba3dc;
      border-top: 0.125rem solid #f8d031;
    }

    @if $isSsm {
      background-color: rgba(82, 84, 121, 0.7);
      border: solid 0.125rem #4c648f;
      border-top: 0.25rem solid theme('colors.texts.standard.important');
    }

    &-left-side {
      width: 88.125rem;
      height: 100%;
      align-items: center;

      &-content {
        text-align: left;
        justify-content: flex-start;
        width: 44.063rem;
        height: 100%;

        &-text {
          width: 25.031rem;
          margin-left: 11rem;

          &-subtext {
            line-height: 1.1;
          }
        }
      }

      &-icons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 1rem;
        height: 100%;
        width: 100%;

        &-icon {
          margin-right: 2.813rem;
          width: 6.875rem;
          height: 8.5rem;
          background-color: grey;
        }
      }
    }

    &-right-side {
      clip-path: polygon(6% 0%, 100% 0%, 100% 100%, 0% 100%);

      width: 27.938rem;
      height: 100%;

      @if $isWsm {
        background-color: #253f5e;
      }

      @if $isSsm {
        background-color: #232b46;
      }

      &-button {
        width: 24.438rem;
      }
    }
  }
}
</style>
