import type { BubbleNotificationConfigInterface } from '@/map-phaser-new/interfaces'
import { InteractiveObject } from '../abstractClasses'

// Red colored (bubble) notification with number

export class BubbleNotification extends InteractiveObject {
  private bubbleNotificationContainer?: Phaser.GameObjects.Container
  private bubbleNotificationAnimation: Phaser.Tweens.Tween | null = null

  constructor(positionX: number, positionY: number) {
    super(positionX, positionY)
  }

  public addToMap(activeScene: Phaser.Scene, config: BubbleNotificationConfigInterface): void {
    this.createBubbleNotification(activeScene, config)
    this.createBubbleNotificationAnimation(activeScene, config)
  }

  public removeFromMap(): void {
    this.removeNotificationAnimation()
    this.removeNotification()
  }

  private createBubbleNotification(
    activeScene: Phaser.Scene,
    config: BubbleNotificationConfigInterface,
  ): void {
    if (this.bubbleNotificationContainer) return

    this.bubbleNotificationContainer = activeScene.add
      .container(this.positionX, this.positionY)
      .setDepth(config.depth)

    const backgroundImage = activeScene.add
      .image(0, 0, config.textureKey)
      .setOrigin(config.origin)
      .setScale(config.scale)

    this.bubbleNotificationContainer.add(backgroundImage)
  }

  private createBubbleNotificationAnimation(
    activeScene: Phaser.Scene,
    config: BubbleNotificationConfigInterface,
  ): void {
    if (this.bubbleNotificationAnimation) return

    this.bubbleNotificationAnimation = activeScene.tweens.add({
      targets: this.bubbleNotificationContainer,
      y: { value: config.animationYIndent, ease: config.animationYEase },
      yoyo: config.yoyo,
      hold: config.yoyoHold,
      repeat: config.repeat,
      duration: config.duration,
    })
  }

  private removeNotification(): void {
    if (!this.bubbleNotificationContainer) return

    this.bubbleNotificationContainer.removeAll(true)
    this.bubbleNotificationContainer.destroy()
    this.bubbleNotificationContainer = null
  }

  private removeNotificationAnimation(): void {
    if (!this.bubbleNotificationAnimation) return

    this.bubbleNotificationAnimation.destroy()
    this.bubbleNotificationAnimation = null
  }
}
