import type { AtlasDataInterface } from '@/map-phaser-new/interfaces'
import { pathImages } from '@/globalVariables'

export type EventsOsraAtlasLoadDataResponse = () => AtlasDataInterface[]

export const eventsOsraAtlasLoadData: EventsOsraAtlasLoadDataResponse = () => {
  return [
    {
      name: 'buildings.event_osra',
      compressed: true,
      multiatlas: true,
      data: {
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/eventOsraWithGoal/wsm-osra-etc2rgba.json',
        },
        astc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/eventOsraWithGoal/wsm-osra-astc12.json',
        },
        img: {
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/eventOsraWithGoal/wsm-osra.json',
        },
      },
    },
  ]
}
