import router from '@/router'
import { internalAxios } from '@/plugins/vueAxios'
import { formatSecondsToHMSCalculate, formatTime } from '@/helpers'
import { useLabStore } from '@/store/pinia/labStore'
import {
  LABORATORY,
  NOT_REFRESH_EQUIPMENT_ROUTE_NAMES,
  NOT_REFRESH_LAB_ROUTE_NAMES,
  SHOP,
} from '@/map-phaser-new/constants/common'
import { shopEndPoint } from '@/globalVariables'
import type {
  ProgressTimerConfigInterface,
  ProgressTimerDataInterface,
  BuildingHtmlTextConfigInterface,
} from '@/map-phaser-new/interfaces'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'

export class ProgressTimer {
  private headingText: Phaser.GameObjects.DOMElement
  private countdownText: Phaser.GameObjects.DOMElement
  private progressBackground: Phaser.GameObjects.Image
  private progressBaseBackground: Phaser.GameObjects.NineSlice
  private progressFill: Phaser.GameObjects.NineSlice
  private progressAnimation: Phaser.Tweens.Tween
  private progressContainer: Phaser.GameObjects.Container
  private timedEvent: Phaser.Time.TimerEvent
  private buildingName: string
  private timeInSeconds: number
  private totalTime: number
  private timerStart: number

  public addToBuilding(
    activeScene: Phaser.Scene,
    config: ProgressTimerConfigInterface,
    timerData: ProgressTimerDataInterface,
    isVisible: boolean,
  ): void {
    this.buildingName = timerData.buildingName

    this.progressContainer = activeScene.add
      .container(config.positionX, config.positionY)
      .setDepth(config.depth)
      .setVisible(isVisible)

    this.progressBackground = this.addProgressBackground(activeScene, config)

    this.progressBaseBackground = this.addProgressBaseBackground(activeScene, config).setOrigin(
      0,
      0,
    )

    // since Phaser Container positions to the center
    this.progressBaseBackground.setPosition(
      this.progressContainer.width / 2 - this.progressBaseBackground.width / 2,
      this.progressBackground.height / 2 -
        config.progressAssetsConfig.progressBaseBackground.progressBaseBackgroundYCorrection,
    )

    this.progressFill = this.addProgressFill(activeScene, config)
    this.progressFill.setPosition(this.progressBaseBackground.x, this.progressBaseBackground.y)
    this.progressFill.setOrigin(0, 0)
    this.progressFill.setName(config.progressAssetsConfig.progressFill.name)

    // heading text
    this.headingText = this.addTitleText(
      activeScene,
      config.htmlTextConfig,
      timerData.heading,
      config.progressAssetsConfig.headingText.color,
    )

    const firstTextPositionY =
      -this.progressBackground.height / 2 +
      this.headingText.height +
      config.progressAssetsConfig.headingText.headingTextYCorrection
    this.headingText
      .setOrigin(0.5)
      .setPosition(0, firstTextPositionY)
      .setName(config.progressAssetsConfig.headingText.name)

    // secondary text
    this.countdownText = this.addTitleText(activeScene, config.htmlTextConfig, '')

    this.countdownText
      .setOrigin(0.5)
      .setName(config.progressAssetsConfig.countdownText.name)
      .setPosition(0, firstTextPositionY + this.headingText.height)

    this.progressContainer.add([
      this.progressBackground,
      this.progressBaseBackground,
      this.progressFill,
      this.headingText,
      this.countdownText,
    ])

    // conversion of time
    this.totalTime = Math.floor((timerData.timerEnd - timerData.timerStart) / 1000)
    this.timerStart = timerData.timerStart
    this.timeInSeconds = this.countdown(timerData.timerEnd)

    // animation
    this.createProgressAnimation(activeScene, config)
    this.addTimerEvent(activeScene)
  }

  private addTimerEvent(activeScene: Phaser.Scene): void {
    this.timedEvent = activeScene.time.addEvent({
      delay: 1000,
      callback: (): void => {
        this.countdownText.setText(
          `${formatTime(...formatSecondsToHMSCalculate(this.timedEvent.repeatCount))}`,
        )
        if (this.timedEvent.repeatCount === 0) {
          // session messages
          this.handleTimerFinish()
        }
      },
      callbackScope: this,
      repeat: this.timeInSeconds,
    })
  }

  private handleTimerFinish(): void {
    const usePhaser = usePhaserGameIntegrationStore()

    // updated session messages, even from the map without opening the building
    const routerName = router.currentRoute.value.name.toString()

    if (this.buildingName === SHOP && !NOT_REFRESH_EQUIPMENT_ROUTE_NAMES.includes(routerName)) {
      internalAxios.get(`${shopEndPoint}/equipment_shop`)
      usePhaser.clearAndHideTimer(SHOP)
    }
    if (this.buildingName === LABORATORY && !NOT_REFRESH_LAB_ROUTE_NAMES.includes(routerName)) {
      const phaserLabStore = useLabStore()
      phaserLabStore.setResearchStatus()
      usePhaser.clearAndHideTimer(LABORATORY)
    }
  }

  private addTitleText(
    activeScene: Phaser.Scene,
    config: BuildingHtmlTextConfigInterface,
    title: string,
    color?: string,
  ): Phaser.GameObjects.DOMElement {
    return activeScene.add.dom(
      0,
      0,
      'span',
      `color: ${color ? color : config.fontColor}; font-size: ${config.fontSize}; font-family: ${
        config.fontName
      }; text-transform: uppercase; font-weight: ${config.fontWeight}; font-style: ${
        config.fontStyle
      }`,
      title,
    )
  }

  private addProgressBackground(
    activeScene: Phaser.Scene,
    config: ProgressTimerConfigInterface,
  ): Phaser.GameObjects.Image {
    return activeScene.add.image(
      0,
      0,
      config.backgroundImageConfig.textureName,
      config.progressAssetsConfig.progressBackground.frame,
    )
  }

  private addProgressBaseBackground(
    activeScene: Phaser.Scene,
    config: ProgressTimerConfigInterface,
  ): Phaser.GameObjects.NineSlice {
    return activeScene.add
      .nineslice(
        0,
        0,
        config.backgroundImageConfig.textureName,
        config.progressAssetsConfig.progressBaseBackground.frame,
        config.progressAssetsConfig.progressBaseBackground.width,
        config.progressAssetsConfig.progressBaseBackground.height,
      )
      .setOrigin(0)
  }

  private addProgressFill(
    activeScene: Phaser.Scene,
    config: ProgressTimerConfigInterface,
  ): Phaser.GameObjects.NineSlice {
    return activeScene.add
      .nineslice(
        0,
        0,
        config.backgroundImageConfig.textureName,
        config.progressAssetsConfig.progressFill.frame,
        config.progressAssetsConfig.progressFill.width,
        config.progressAssetsConfig.progressFill.height,
      )
      .setOrigin(0)
  }

  private createProgressAnimation(
    activeScene: Phaser.Scene,
    progressTimerConfig: ProgressTimerConfigInterface,
  ): void {
    if (this.progressAnimation) return

    this.progressAnimation = activeScene.tweens.add({
      targets: this.progressFill,
      width: progressTimerConfig.animationConfig.width,
      duration: this.timeInSeconds * 1000,
      ease: progressTimerConfig.animationConfig.ease,
      yoyo: progressTimerConfig.animationConfig.yoyo,
      // ploting the progress at time, eg. refresh
      onUpdate: (): void => {
        // limit the width to 270 pixels (max)
        const maxWidth = progressTimerConfig.animationConfig.width

        const now = Date.now()
        const elapsedSeconds = Math.floor((now - this.timerStart) / 1000)
        const newWidth = Math.min((elapsedSeconds / this.totalTime) * maxWidth, maxWidth)

        this.progressFill.width = newWidth
      },
    })
  }

  private countdown(timerEnd: number): number {
    const now = Date.now()
    let timerEndsAt = timerEnd

    if (timerEndsAt <= now) {
      timerEndsAt = 0
    }
    const remainingSeconds = Math.floor((timerEndsAt - now) / 1000)
    return remainingSeconds
  }

  public removeFromMap(activeScene: Phaser.Scene): void {
    // animations
    activeScene.tweens.killTweensOf(this.progressFill)
    this.removeProgressAnimation()

    // assets
    this.removeProgressFill()
    this.removeProgressBaseBackground()
    this.removeProgressBackground()

    // event + container
    this.destroyContainerAndTimer(activeScene)
  }

  private destroyContainerAndTimer(activeScene: Phaser.Scene): void {
    if (this.timedEvent) {
      activeScene.time.removeEvent(this.timedEvent)
      this.timedEvent.remove(true)
      this.timedEvent.destroy()
      this.timedEvent = null
    }

    if (this.progressContainer) {
      this.progressContainer.removeAll(true)
      this.progressContainer.destroy()
      this.progressContainer = null
    }
  }

  private removeProgressBackground(): void {
    if (!this.progressBackground) return

    this.progressBackground.destroy()
    this.progressBackground = null
  }

  private removeProgressBaseBackground(): void {
    if (!this.progressBaseBackground) return

    this.progressBaseBackground.destroy()
    this.progressBaseBackground = null
  }

  private removeProgressFill(): void {
    if (!this.progressFill) return

    this.progressFill.destroy()
    this.progressFill = null
  }

  private removeProgressAnimation(): void {
    if (!this.progressAnimation) return

    this.progressAnimation.destroy()
    this.progressAnimation = null
  }
}
