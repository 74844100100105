<template>
  <info-popup width="100%" :popup-title="$t('gamePass.gamePass')" @close="$emit('close')">
    <app-scrollbar width="103.2rem" height="50rem" scroll="y" slide="y">
      <div class="game-pass-about-popup flex flex-col justify-start text-30 text-white">
        <ul class="list-disc mx-8 text-left">
          <p
            v-for="i in 3"
            :key="`pass-about-${i}`"
            class="text-texts-standard-default text-36 mb-8"
          >
            {{ replaceInText($t(`gamePass.passInfo${i}`)) }}
          </p>
        </ul>
        <popup-about-type-one
          v-if="arenaData"
          about-id="arena-about-popup"
          :popup-data="arenaData"
        />
      </div>
    </app-scrollbar>
  </info-popup>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import PopupAboutTypeOne from '@/components/Popup/PopupAboutTypeOne.vue'

import { replaceGamePassPlaceholders } from '@/helpers'

import { defineComponent } from 'vue'

interface PopupData {
  img: string
  title: string
  text: string
}

export default defineComponent({
  name: 'GamePassAboutPopup',
  components: {
    InfoPopup,
    PopupAboutTypeOne,
  },
  emits: ['close'],
  computed: {
    arenaData(): PopupData[] {
      return [
        {
          img: 'icons/game-pass/icon-energy-starts-limit-120.avif',
          title: this.$t('gamePass.passInfoHeading1'),
          text: this.replaceInText(this.$t('gamePass.passInfo4')),
        },
        {
          img: 'icons/game-pass/icon-energy-starts-regeneration-120.avif',
          title: this.$t('gamePass.passInfoHeading2'),
          text: this.replaceInText(this.$t('gamePass.passInfo5')),
        },
        {
          img: 'icons/game-pass/icon-multiplier-10x-184.avif',
          title: this.$t('gamePass.passInfoHeading3'),
          text: this.replaceInText(this.$t('gamePass.passInfo6')),
        },
        {
          img: 'icons/game-pass/icon-daily-tasks-plus-184.avif',
          title: this.$t('mechanics.daily_quests'),
          text: this.replaceInText(this.$t('gamePass.passInfo7')),
        },
        {
          img: 'icons/game-pass/icon-instant-repair-184.avif',
          title: this.$t('gamePass.instantRepair'),
          text: this.replaceInText(this.$t('gamePass.passInfo8')),
        },
        {
          img: 'icons/game-pass/icon-fast-research-184.avif',
          title: this.$t('gamePass.fastResearch'),
          text: this.replaceInText(this.$t('gamePass.passInfo9')),
        },
        {
          img: 'icons/game-pass/icon-no-rewards-ads-184.avif',
          title: this.$t('gamePass.noAdsRewards'),
          text: this.replaceInText(this.$t('gamePass.passInfo10')),
        },
      ]
    },
  },
  methods: {
    replaceInText(text: string): string {
      return replaceGamePassPlaceholders(text)
    },
  },
})
</script>

<style lang="scss" scoped>
.game-pass-about-popup {
  padding: 2rem 2rem 0 2rem;
}
</style>
