import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const carsAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'carTurnLeftTop2Bottom',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '02',
      delay: 0,
      duration: 600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 2,
        end: 7,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carTurnLeftBottom2Top',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '07',
      delay: 0,
      duration: 1200,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 7,
        end: 1,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carTurnRightBottom2Top',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '01',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GeneratedFrameNames,
      data: [
        { key: 'animations', frame: '01' },
        { key: 'animations', frame: '24' },
        { key: 'animations', frame: '23' },
        { key: 'animations', frame: '22' },
        { key: 'animations', frame: '21' },
        { key: 'animations', frame: '20' },
        { key: 'animations', frame: '19' },
      ],
    },
  },
  {
    animationCreationData: {
      animationName: 'carTurnRightTop2Bottom',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '19',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GeneratedFrameNames,
      data: [
        { key: 'animations', frame: '19' },
        { key: 'animations', frame: '20' },
        { key: 'animations', frame: '21' },
        { key: 'animations', frame: '22' },
        { key: 'animations', frame: '23' },
        { key: 'animations', frame: '24' },
        { key: 'animations', frame: '01' },
      ],
    },
  },
  {
    animationCreationData: {
      animationName: 'carTurnLeftTop2BottomRev',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '13',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GeneratedFrameNames,
      data: [
        { key: 'animations', frame: '13' },
        { key: 'animations', frame: '12' },
        { key: 'animations', frame: '10' },
        { key: 'animations', frame: '09' },
        { key: 'animations', frame: '08' },
        { key: 'animations', frame: '07' },
      ],
    },
  },
  {
    animationCreationData: {
      animationName: 'carTurnRightTop2BottomRev',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '07',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 7,
        end: 13,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carTurnRightBottom2TopRev',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '13',
      delay: 0,
      duration: 1200,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 13,
        end: 19,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carTurnLeftBottom2TopRev',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '19',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 19,
        end: 14,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carTurn2Park',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '19',
      delay: 0,
      duration: 1200,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 19,
        end: 13,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carTurn2ParkRev',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '13',
      delay: 0,
      duration: 1500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 13,
        end: 7,
        zeroPad: 2,
      },
    },
  },
]
