<template>
  <div class="training-disciplines-boxes">
    <div
      v-if="!isContentLoading"
      class="training-disciplines-boxes-wrapper"
      :class="{ 'hide-overflow': hideOverflow }"
    >
      <router-link
        v-for="discipline in disciplines"
        :key="discipline.id"
        :to="{
          name: $getWebView(getRouteName),
          params: { id: discipline.id },
        }"
        :class="[
          'flex training-disciplines-boxes-wrapper-margin',
          { 'pointer-events-none': discipline.locked, 'is-tutorial': isTutorial },
        ]"
      >
        <arrow-animation :tutorial="isTutorial && !discipline.locked" border="box">
          <training-box
            :id="'training-discipline-box-' + discipline.id"
            :trainer="false"
            :data-cy="'training-discipline-box-' + discipline.id"
            :discipline-data="discipline"
            :text-attr-value="$t('trainingHall.attributeValue')"
            :text-locked="$t('common.locked')"
            :text-level-required="$t('common.levelRequired')"
          >
            {{ item }}
          </training-box>
        </arrow-animation>
      </router-link>
    </div>

    <component-loading
      v-for="id in 4"
      :key="id"
      class="bordered-1 component-loading"
      :width="'31.25rem'"
      :height="'40.75rem'"
      :type="'secondary'"
      :is-loading="isContentLoading"
    />
  </div>
</template>

<script lang="ts">
import ArrowAnimation from '@/components/ArrowAnimation.vue'
import TrainingBox from '@/components/Training/TrainingBox.vue'
import { useTrainingDisciplineStore } from '@/store/pinia/trainingDisciplinesStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TrainingDisciplinesBoxes',
  components: {
    ArrowAnimation,
    TrainingBox,
  },
  props: {
    hideOverflow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
    }),
    ...mapState(useTrainingDisciplineStore, {
      disciplines: 'getDisciplines',
    }),
    getRouteName(): string {
      return this.actualStageData?.name === 'clickOnDisciplineTH'
        ? 'TrainingDisciplineDetailTutorial'
        : 'TrainingDisciplineDetail'
    },
    isTutorial(): boolean {
      return (
        this.actualStageData?.name === 'chooseFirstDisciplineNarrative' ||
        this.actualStageData?.name === 'clickOnDisciplineTH'
      )
    },
    isContentLoading(): boolean {
      return this.disciplines.length === 0
    },
  },
})
</script>

<style lang="scss" scoped>
.training-disciplines-boxes {
  display: flex;

  &-wrapper {
    position: relative;
    display: flex;

    &.hide-overflow {
      overflow: hidden;
    }

    &-margin {
      display: flex;
      margin: 0 0.687rem 1.18rem;
      overflow: hidden;

      &.is-tutorial {
        overflow: initial;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      .arrow-wrapper {
        &:active {
          transform: scale(0.95);
        }

        .training-box {
          transform: unset;
        }
      }
    }
  }
}

.component-loading {
  border-style: solid;
  border-width: 0.125rem;
  border-image-source: linear-gradient(to top, #5ba3dc, #bfe3ff, #5ba3dc);
  border-image-slice: 1;
  margin: 0.5rem 1.25rem;
}
</style>
