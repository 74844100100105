import {
  ANIMATION_VARIATIONS_FRAMES,
  CHECKPOINT_ACTION_TYPE,
  FRAMES_ANIMATION_TYPES,
} from '@/map-phaser-new/constants'
import type {
  ActiveFollowersInterface,
  AnimationInstanceData,
  AnimatedObjectWithPathsInterface,
  CheckpointsObjectInterface,
  CheckpointsDataHandlerInterface,
  CheckpointsNextHitInterface,
  PathObjectInterface,
  VariationsDataGenerateFrameNames,
  VariationsDataGeneratedFrameNames,
  PauseAction,
  CheckpointActionInterface,
  PlayAnimationWithoutCheckpoints,
} from '@/map-phaser-new/interfaces'
import type { SpecificObjectInterface } from '@/map-phaser-new/interfaces/scene/objects/specificObjects/specificObjectInterface'
import { BaseObjectHandler } from '@/map-phaser-new/utils/abstractClasses'

export class AnimatedObjectsWithPathsHandler extends BaseObjectHandler<SpecificObjectInterface[]> {
  private checkpointsForAction: CheckpointsDataHandlerInterface
  private nextHitCheckpointsData: CheckpointsNextHitInterface
  private activeFollowers: ActiveFollowersInterface

  constructor(gameFromEnv: string, activeScene: Phaser.Scene) {
    super(gameFromEnv, activeScene)
    this.checkpointsForAction = {}
    this.nextHitCheckpointsData = {}
    this.activeFollowers = {}
  }

  public async setUp(configData: AnimatedObjectWithPathsInterface[]): Promise<void> {
    const promises = configData.map((animationConfig) =>
      this.createAnimationDataAndInstances(animationConfig),
    )
    await Promise.all(promises)
  }

  private async createAnimationDataAndInstances(
    animationConfig: AnimatedObjectWithPathsInterface,
  ): Promise<void> {
    // wait for all three methods, because we need animations and checkpoints when follower starts, so to be 100% sure they are done we have to wait
    const [objectPaths] = await Promise.all([
      this.createAnimationPath(animationConfig.pathsData),
      this.createAnimations(animationConfig),
      this.createCheckpointsForFramesChange(animationConfig.checkpointsData),
    ])

    for (const animationInstanceConfig of animationConfig.animatedInstances) {
      this.createAnimationInstance(animationConfig, animationInstanceConfig, objectPaths)
    }
  }

  private createAnimationInstance(
    animationConfig: AnimatedObjectWithPathsInterface,
    animationInstanceConfig: AnimationInstanceData,
    objectPaths: Phaser.Curves.Path,
  ): void {
    const variationIndex = Phaser.Math.Between(0, animationConfig.variations.length - 1)
    const variation = animationConfig.variations[variationIndex]

    const frameName = this.getFrameName(
      animationConfig,
      variation,
      animationConfig.startFrameData.startFrameKey,
    )

    this.activeFollowers[animationInstanceConfig.name] = this.activeScene.add.follower(
      objectPaths,
      0,
      0,
      animationConfig.textureKey,
      frameName,
    )
    const activeFollower = this.activeFollowers[animationInstanceConfig.name]
    activeFollower.setDepth(animationConfig.followersDepth)

    let yoyoHold = null
    if (animationConfig.yoyo) {
      yoyoHold =
        animationInstanceConfig.yoyoDelay ??
        Phaser.Math.Between(
          animationInstanceConfig.yoyoDelayRandom.mathBetweenMin,
          animationInstanceConfig.yoyoDelayRandom.mathBetweenMax,
        )
    }

    this.nextHitCheckpointsData[animationInstanceConfig.name] = {
      type: animationConfig.startFrameData.startFrameType,
    }
    this.setFollowerToStartFollow(
      activeFollower,
      animationConfig,
      animationInstanceConfig,
      variation,
      yoyoHold,
    )
  }

  private setFollowerToStartFollow(
    activeFollower: Phaser.GameObjects.PathFollower,
    animationConfig: AnimatedObjectWithPathsInterface,
    animationInstanceConfig: AnimationInstanceData,
    variation: string,
    yoyoHold: number,
  ): void {
    activeFollower.startFollow({
      ...animationConfig.tweenBuilderConfig,
      yoyo: animationConfig.yoyo,
      hold: yoyoHold,
      ease: animationConfig.startFollow.ease,
      repeat: animationConfig.startFollow.repeat,
      repeatDelay: animationInstanceConfig.repeatDelay,
      delay: animationInstanceConfig.startDelay,
      positionOnPath: animationConfig.startFollow.positionOnPath,
    })

    this.playAnimationWithoutCheckpoints(
      variation,
      activeFollower,
      animationConfig.playAnimationWithoutCheckpoints,
    )
    this.setUpFollowerListeners(activeFollower, animationConfig, animationInstanceConfig, variation)
  }

  private setUpFollowerListeners(
    activeFollower: Phaser.GameObjects.PathFollower,
    animationConfig: AnimatedObjectWithPathsInterface,
    animationInstanceConfig: AnimationInstanceData,
    variation: string,
  ): void {
    if (animationConfig.turnOnUpdateListener) {
      activeFollower.pathTween.on('update', () => {
        this.doActionAtCheckpoint(
          activeFollower,
          animationInstanceConfig.name,
          animationConfig.checkpointsData.key,
          variation,
        )
      })
    }

    if (animationConfig.turnOnYoyoListener) {
      activeFollower.pathTween.on('yoyo', () => {
        variation = this.setNewVariationAndFrame(
          animationConfig,
          activeFollower,
          animationInstanceConfig.name,
          variation,
          animationConfig.yoyoCalculateNewVariation,
          animationConfig.yoyoFrameData?.yoyoFrameType,
          animationConfig.yoyoFrameData?.yoyoFrameKey,
        )
      })
    }

    if (animationConfig.turnOnRepeatListener) {
      activeFollower.pathTween.on('repeat', () => {
        variation = this.setNewVariationAndFrame(
          animationConfig,
          activeFollower,
          animationInstanceConfig.name,
          variation,
          animationConfig.repeatCalculateNewVariation,
          animationConfig.startFrameData.startFrameType,
          animationConfig.startFrameData.startFrameKey,
        )
      })
    }

    if (animationConfig.startFollow.repeat !== -1) {
      activeFollower.pathTween.on('complete', () => {
        this.cleanUpAnimationInstanceData(animationInstanceConfig.name)
      })
    }
  }

  private cleanUpAnimationInstanceData(animationInstanceName: string): void {
    delete this.nextHitCheckpointsData[animationInstanceName]
    this.activeFollowers[animationInstanceName].removeAllListeners()
    this.activeFollowers[animationInstanceName].destroy()
    delete this.activeFollowers[animationInstanceName]
  }

  private getFrameName(
    animationConfig: AnimatedObjectWithPathsInterface,
    variation: string,
    frameNameVariation: string,
  ): string {
    return (
      animationConfig.framePrefix + variation + animationConfig.framePostfix + frameNameVariation
    )
  }

  private playAnimationWithoutCheckpoints(
    variation: string,
    activeFollower: Phaser.GameObjects.PathFollower,
    playAnimationWithoutCheckpoints?: PlayAnimationWithoutCheckpoints,
  ): void {
    if (!playAnimationWithoutCheckpoints) return

    const animationToPlay = variation + '_' + playAnimationWithoutCheckpoints.animationKeyPostfix
    activeFollower.play(animationToPlay)
  }

  private setNewVariationAndFrame(
    animationConfig: AnimatedObjectWithPathsInterface,
    activeFollower: Phaser.GameObjects.PathFollower,
    animationInstanceName: string,
    variation: string,
    calculateNewVariation: boolean,
    frameType?: FRAMES_ANIMATION_TYPES,
    frameNameVariation?: string,
  ): string {
    this.nextHitCheckpointsData[animationInstanceName] = {
      type: frameType,
    }
    let newVariation = variation
    if (calculateNewVariation) {
      const variations = animationConfig.variations
      const variationIndex = Phaser.Math.Between(0, variations.length - 1)
      newVariation = variations[variationIndex]
    }

    const newFrameName = this.getFrameName(animationConfig, newVariation, frameNameVariation)
    activeFollower.setFrame(newFrameName)
    this.playAnimationWithoutCheckpoints(
      newVariation,
      activeFollower,
      animationConfig.playAnimationWithoutCheckpoints,
    )

    return newVariation
  }

  private doActionAtCheckpoint(
    activeFollower: Phaser.GameObjects.PathFollower,
    animationInstanceName: string,
    checkpointObjectKey: string,
    variation: string,
  ): void {
    const checkPoints = this.checkpointsForAction[checkpointObjectKey]
    const nextCheckpointData = this.nextHitCheckpointsData[animationInstanceName]

    let nearestCheckpointIndex: number
    let nextCheckpointIndexCalculator: number
    if (nextCheckpointData.type === FRAMES_ANIMATION_TYPES.NormalFrameType) {
      nearestCheckpointIndex = nextCheckpointData.index ?? 0
      if (nearestCheckpointIndex >= checkPoints.length) return
      nextCheckpointIndexCalculator = 1
    } else {
      nearestCheckpointIndex = nextCheckpointData.index ?? checkPoints.length - 1
      if (nearestCheckpointIndex < 0) return
      nextCheckpointIndexCalculator = -1
    }

    const checkpointData = checkPoints[nearestCheckpointIndex]
    const followerBounds = activeFollower.getBounds()
    const checkPointBounds = checkpointData.rectangle.getBounds()
    followerBounds.width = followerBounds.width - 20
    followerBounds.height = followerBounds.height - 20

    if (Phaser.Geom.Intersects.RectangleToRectangle(checkPointBounds, followerBounds)) {
      this.playCheckpointAnimation(
        nextCheckpointData.type,
        variation,
        activeFollower,
        checkpointData.actionData,
      )
      this.makeFollowerWait(activeFollower, checkpointData.actionData)
      nextCheckpointData.index = nearestCheckpointIndex + nextCheckpointIndexCalculator
    }
  }

  private playCheckpointAnimation(
    frameType: FRAMES_ANIMATION_TYPES,
    variation: string,
    activeFollower: Phaser.GameObjects.PathFollower,
    checkpointActionData: CheckpointActionInterface,
  ): void {
    if (checkpointActionData.type !== CHECKPOINT_ACTION_TYPE.AnimationAction) return

    const animationKey = variation + '_' + checkpointActionData.action[frameType]
    activeFollower.play(animationKey)
  }

  private makeFollowerWait(
    activeFollower: Phaser.GameObjects.PathFollower,
    checkpointActionData: CheckpointActionInterface,
  ): void {
    if (checkpointActionData.type !== CHECKPOINT_ACTION_TYPE.PauseAction) return

    activeFollower.pauseFollow()
    const pauseData = checkpointActionData.action as PauseAction
    this.activeScene.time.delayedCall(pauseData.duration, () => activeFollower.resumeFollow())
  }

  private async createAnimationPathFromSpline(
    pathsData: PathObjectInterface,
  ): Promise<Phaser.Curves.Path> {
    return new Promise<Phaser.Curves.Path>((resolve) => {
      const path = new Phaser.Curves.Path(pathsData.mainPath.x, pathsData.mainPath.y)
      const pointsArray = []
      for (let i = 0; i < pathsData.spline.length; i += 2) {
        const x = pathsData.spline[i]
        const y = pathsData.spline[i + 1]
        pointsArray.push(new Phaser.Math.Vector2(x, y))
      }
      path.splineTo(pointsArray)

      resolve(path)
    })
  }

  private async createAnimationPathFromLineOrCubicBezier(
    pathsData: PathObjectInterface,
  ): Promise<Phaser.Curves.Path> {
    return new Promise<Phaser.Curves.Path>((resolve) => {
      const path = new Phaser.Curves.Path(pathsData.mainPath.x, pathsData.mainPath.y)
      for (const objectPath of pathsData.nextPaths) {
        if (objectPath.control1X) {
          path.cubicBezierTo(
            objectPath.x,
            objectPath.y,
            objectPath.control1X,
            objectPath.control1Y,
            objectPath.control2X,
            objectPath.control2Y,
          )
        } else {
          path.lineTo(objectPath.x, objectPath.y)
        }
      }

      resolve(path)
    })
  }

  private async createAnimationPath(pathsData: PathObjectInterface): Promise<Phaser.Curves.Path> {
    let createdPath: Phaser.Curves.Path
    if (pathsData.spline) {
      createdPath = await this.createAnimationPathFromSpline(pathsData)
    } else {
      createdPath = await this.createAnimationPathFromLineOrCubicBezier(pathsData)
    }

    return createdPath
  }

  private async createAnimations(animationConfig: AnimatedObjectWithPathsInterface): Promise<void> {
    if (!animationConfig.animationsData) {
      return
    }

    const promises: Promise<void>[] = []
    for (const variation of animationConfig.variations) {
      const frameName = animationConfig.framePrefix + variation + animationConfig.framePostfix
      const promise = this.createAnimationAsync(variation, frameName, animationConfig)
      promises.push(promise)
    }

    await Promise.all(promises)
  }

  private createAnimationAsync(
    variation: string,
    frameName: string,
    animationConfig: AnimatedObjectWithPathsInterface,
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      for (const data of animationConfig.animationsData) {
        const frames = this.getFrames(frameName, data.framesData, animationConfig.textureKey)
        this.activeScene.anims.create({
          key: variation + '_' + data.animationCreationData.animationName,
          frames: frames,
          skipMissedFrames: data.animationCreationData.skipMissedFrames,
          defaultTextureKey: frameName + data.animationCreationData.defaultTextureKeyPostfix,
          delay: data.animationCreationData.delay,
          duration: data.animationCreationData.duration,
          showOnStart: data.animationCreationData.showOnStart,
          hideOnComplete: data.animationCreationData.hideOnComplete,
          repeat: data.animationCreationData.repeat,
        })
      }
      resolve()
    })
  }

  private getFrames(
    frameName: string,
    framesData: VariationsDataGenerateFrameNames | VariationsDataGeneratedFrameNames,
    textureKey: string,
  ): Phaser.Types.Animations.AnimationFrame[] {
    if (framesData.type === ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames) {
      const framesDataToGenerate = framesData as VariationsDataGenerateFrameNames
      return this.activeScene.anims.generateFrameNames(textureKey, {
        prefix: frameName,
        start: framesDataToGenerate.frames.start,
        end: framesDataToGenerate.frames.end,
        zeroPad: framesDataToGenerate.frames.zeroPad,
      })
    }

    const alreadyGeneratedData = framesData as VariationsDataGeneratedFrameNames
    return alreadyGeneratedData.data.map((generatedData) => ({
      key: generatedData.key,
      frame: frameName + generatedData.frame,
    }))
  }

  private async createCheckpointsForFramesChange(
    checkpointsObject?: CheckpointsObjectInterface,
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      if (!checkpointsObject) {
        resolve()
        return
      }

      this.checkpointsForAction[checkpointsObject.key] = checkpointsObject.data.map(
        ({ x, y, width, height, actionData }) => {
          return {
            actionData: actionData,
            rectangle: this.activeScene.add.rectangle(x, y, width, height),
          }
        },
      )

      resolve()
    })
  }
}
