<template>
  <div class="convertor__side-part flexing mt-2" :class="`convertor__${type}`">
    <div class="conversion-wrapper relative flexing">
      <div class="heading" :style="{ '--header-bg': getBackground('header-bg', true) }">
        <p class="text-texts-standard-important text-50 uppercase font-bold ml-4">
          {{ selectedHeader }}
        </p>
      </div>

      <template v-if="state !== OperationStates.Default">
        <div
          v-if="getConvertingState.converting_operation != null"
          class="conversion-management-box"
          :style="{
            height: setBoxHeight,
            marginBottom: state === OperationStates.Claim && '1.5rem',
          }"
        >
          <div
            v-if="state === OperationStates.Production"
            class="conversion-management-box--active"
          >
            <div class="conversion-management-box__message-wrapper">
              <p class="text-texts-standard-default text-36">
                {{ $te('productionInProgress') }}
              </p>
            </div>
            <div class="conversion-management-box__footer">
              <div class="box-time text-texts-standard-default text-28 flexing">
                <app-icon icon-name="time" class="mr-2 text-26" />
                <vue-countdown
                  v-slot="{ days, hours, minutes, seconds }"
                  :time="getConvertingState.converting_operation?.seconds_left * 1000"
                  @end="onCountdownEnd"
                >
                  {{ formatTime(days, hours, minutes, seconds) }}
                </vue-countdown>
              </div>
            </div>
          </div>
          <div
            v-if="state === OperationStates.Claim && getConvertingState !== null"
            class="conversion-management-box--done"
          >
            <div class="flex flex-col justify-center items-center">
              <p class="text-texts-standard-important font-bold text-32 mt-4 mb-1">
                {{ $te('productionCompleted') }}
              </p>
              <app-icon
                :icon-name="getConvertingState.converting_operation?.ingredient.icon"
                :tooltip-text="$te(getConvertingState.converting_operation?.ingredient.name)"
                class="conversion-management-box-icon"
              />
              <div class="number-block">
                <p
                  class="text-40 font-bold gradient-text-gold uppercase"
                  :class="{
                    '-mt-2': $isSsm,
                  }"
                >
                  {{ getConvertingState.converting_operation?.amount }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div
          class="conversion-selection"
          :style="{ '--conversion-smoky-bg': getBackground('conversion-smoky-bg', true) }"
        >
          <div class="conversion-selection__group">
            <div
              class="conversion-selection__arrow-left clickable-element icon cursor-pointer"
              @click="onArrowClick(ConvertorDirection.Left)"
            />

            <app-icon
              :icon-name="selectedIngredient?.ingredient.icon"
              :tooltip-text="$te(selectedIngredient?.ingredient.name)"
              class="conversion-selection__preview"
            />
            <div
              class="conversion-selection__arrow-right clickable-element icon cursor-pointer"
              @click="onArrowClick(ConvertorDirection.Right)"
            />
          </div>
          <div
            class="conversion-detail"
            :style="{ '--conversion-detail-bg': getBackground('conversion-detail-bg', true) }"
          >
            <div class="flexing text-28 text-texts-standard-default font-bold">
              <div class="flexing">
                <p class="w-full">
                  {{ isDeconstructor ? '1x' : `${selectedConversionCost.ratio}x` }}
                </p>
                <app-icon
                  :icon-name="
                    isDeconstructor
                      ? selectedIngredient?.ingredient.icon
                      : universalIngredient?.ingredient.icon
                  "
                  :tooltip-text="
                    $te(
                      isDeconstructor
                        ? selectedIngredient?.ingredient.name
                        : universalIngredient?.ingredient.name,
                    )
                  "
                />
              </div>
              <p class="ml-2">=</p>
              <div class="flexing">
                <p class="w-full">
                  {{ isDeconstructor ? `${selectedConversionCost.ratio}x` : '1 x' }}
                </p>
                <app-icon
                  :icon-name="
                    isDeconstructor
                      ? universalIngredient?.ingredient.icon
                      : selectedIngredient?.ingredient.icon
                  "
                  :tooltip-text="
                    $te(
                      isDeconstructor
                        ? universalIngredient?.ingredient.name
                        : selectedIngredient?.ingredient.name,
                    )
                  "
                />
              </div>
            </div>
          </div>
        </div>

        <div class="progress-bar-controller">
          <app-slider
            :max="getMaxAmount"
            show-buttons
            :min="0"
            :disabled="getMaxAmount === 0"
            :step="isManufacture ? selectedConversionCost.ratio : 1"
            :force-reset="forceSliderReset"
            @set-slider-value="setExchangeAmount"
          />
        </div>
      </template>

      <convertor-side-exchange-visualization
        v-if="state === OperationStates.Default || state === OperationStates.Production"
        :input-ingredient="
          isDeconstructor ? selectedIngredient?.ingredient : universalIngredient?.ingredient
        "
        :output-ingredient="
          isDeconstructor
            ? universalIngredient?.ingredient
            : state === OperationStates.Production
              ? getConvertingState.converting_operation?.ingredient
              : selectedIngredient?.ingredient
        "
        :input-value="getVisualizationInputValue"
        :output-value="getVisualizationOutputValue"
      />

      <app-button
        v-if="isDeconstructor"
        btn-id="select-benefit-btn-confirm"
        btn-size="md"
        btn-type="confirm"
        :btn-text="$te('meltButton')"
        :disabled="selectedAmount === 0 || isDeconstructorButtonDisabled"
        :loading="isDeconstructorButtonDisabled"
        @click="isModalBlocked ? meltIngredient() : (showOperationConfirmModal = true)"
      />

      <app-multi-button1
        v-if="
          isManufacture &&
          (state === OperationStates.Production || state === OperationStates.Default)
        "
        :popup-data="{
          title: $t('common.speedUp'),
          btnText: $t('common.speedUp'),
          btnType: 'credit',
          btnValue: getGemPrice,
          btnId: 'skip-production-btn-id',
          text: $te('speedUpConfirm'),
        }"
        :btn-theme="state === OperationStates.Default ? 'confirm' : ''"
        :btn-type="state === OperationStates.Production ? 'credit' : ''"
        :btn-count="getGemPrice"
        :disabled="isButtonDisabled"
        :is-resources-status-colored="false"
        :btn-text="
          !isManufactureButtonDisabled
            ? state === OperationStates.Production
              ? $t('common.speedUp')
              : $te('produceButton')
            : undefined
        "
        class="app-multi-button--timer"
        @action="state === OperationStates.Production ? skipProductionTime() : () => {}"
        @click="
          state === OperationStates.Default && !isButtonDisabled
            ? isModalBlocked
              ? createIngredient()
              : (showOperationConfirmModal = true)
            : () => {}
        "
      >
        <template v-if="isManufactureButtonDisabled" #leftPart>
          <component-loading :is-loading="true" />
        </template>
        <template v-if="state === OperationStates.Default" #rightPart>
          <p v-if="isTier3">{{ $te('instant') }}</p>
          <span v-else class="flexing">
            <app-icon icon-name="time" class="mr-2" />
            <p class="text-texts-standard-default text-36">
              <vue-countdown
                v-slot="{ days, hours, minutes, seconds }"
                :time="expectedProductionTime * 1000"
                :auto-start="false"
              >
                {{ formatTime(days, hours, minutes, seconds) }}
              </vue-countdown>
            </p>
          </span>
        </template>
      </app-multi-button1>

      <app-button
        v-if="isManufacture && state === OperationStates.Claim"
        btn-type="secondary"
        btn-size="md"
        :btn-text="$t('common.claim')"
        :disabled="isManufactureButtonDisabled"
        :loading="isManufactureButtonDisabled"
        @click="claimIngredient"
      />
    </div>
  </div>

  <!-- MODAL FOR MELT AND CREATE INGREDIENT BUTTON (IF ENABLED) -->
  <convertor-operation-confirm-popup
    :is-visible="showOperationConfirmModal && !isModalBlocked"
    :type="type"
    :production-time="expectedProductionTime"
    :disabled="isDeconstructor ? isDeconstructorButtonDisabled : isManufactureButtonDisabled"
    :input-ingredient="
      isDeconstructor ? selectedIngredient?.ingredient : universalIngredient?.ingredient
    "
    :output-ingredient="
      isDeconstructor ? universalIngredient?.ingredient : selectedIngredient?.ingredient
    "
    :input-value="getVisualizationInputValue"
    :output-value="getVisualizationOutputValue"
    :is-tier-3="isTier3"
    @set-block-state="setModalBlockStatus"
    @close="showOperationConfirmModal = false"
    @click="isDeconstructor ? meltIngredient() : createIngredient()"
  />
</template>

<script lang="ts">
import ConvertorOperationConfirmPopup from '@/components/Events/EventsPopups/ConvertorOperationConfirmPopup.vue'
import { OperationStates } from '@/components/Events/EventsWarehouse/Convertor/ConvertorMain.vue'
import ConvertorSideExchangeVisualization from '@/components/Events/EventsWarehouse/Convertor/ConvertorSideExchangeVisualization.vue'
import { CONVERTOR_CONFIRMATION_BLOCK, pathImages } from '@/globalVariables'
import { formatTime } from '@/helpers'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { ConvertCost } from '@/interfaces/responses/events/warehouse/WarehouseConfigApiResponse'
import type { IngredientAmount } from '@/interfaces/responses/events/warehouse/WarehouseSharedInterface'
import { useWarehouseStore } from '@/store/pinia/events/warehouseStore'

enum ConvertorType {
  Deconstructor = 'deconstructor',
  Manufacture = 'manufacture',
}

enum ConvertorDirection {
  Left = 'left',
  Right = 'right',
}

interface ComponentData {
  OperationStates: typeof OperationStates
  ConvertorDirection: typeof ConvertorDirection
  pathImages: typeof pathImages
  minAmount: number
  maxAmount: number
  selectedAmount: number
  forceSliderReset: boolean
  selectedIngredientId: number
  isManufactureButtonDisabled: boolean
  isDeconstructorButtonDisabled: boolean
  showOperationConfirmModal: boolean
  isLocalBlockStatusActive: boolean
}

export default defineComponent({
  name: 'ConvertorSide',
  components: {
    ConvertorSideExchangeVisualization,
    ConvertorOperationConfirmPopup,
  },
  props: {
    type: {
      type: String,
      default: 'deconstructor',
      validator(value: ConvertorType): boolean {
        return [ConvertorType.Deconstructor, ConvertorType.Manufacture].includes(value)
      },
    },
    state: {
      type: String,
      default: 'default',
      validator(value: OperationStates): boolean {
        return [
          OperationStates.Default,
          OperationStates.Production,
          OperationStates.Claim,
        ].includes(value)
      },
    },
    isTier3: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['setState'],
  data(): ComponentData {
    return {
      OperationStates,
      ConvertorDirection,
      pathImages,
      minAmount: 0,
      maxAmount: 100,
      selectedAmount: 0,
      forceSliderReset: false,
      selectedIngredientId: 1,
      isManufactureButtonDisabled: false,
      isDeconstructorButtonDisabled: false,
      showOperationConfirmModal: false,
      isLocalBlockStatusActive: false,
    }
  },
  computed: {
    ...mapState(useWarehouseStore, {
      getConversionCostByIngredientId: 'getConversionCostByIngredientId',
      getIngredientStateById: 'getIngredientStateById',
      getIngredientsConfig: 'getIngredientsConfig',
      getIngredientsState: 'getIngredientsState',
      getConvertingState: 'getConvertingState',
    }),
    isDeconstructor(): boolean {
      return this.type === ConvertorType.Deconstructor
    },
    isManufacture(): boolean {
      return this.type === ConvertorType.Manufacture
    },
    selectedConversionCost(): ConvertCost {
      return this.getConversionCostByIngredientId(this.selectedIngredientId)
    },
    selectedIngredient(): IngredientAmount {
      return this.getIngredientStateById(this.selectedIngredientId)
    },
    // winter essence, magic dust, etc. - ingredient used in convertor
    universalIngredient(): IngredientAmount {
      if (!this.getIngredientsState) return

      return this.getIngredientsState[this.getIngredientsState.length - 1]
    },
    // get selected value from slider and calculate output multiplied/divided by ratio
    getSelectedAmountConversion(): number {
      if (!this.selectedConversionCost) return

      if (this.isDeconstructor) return this.selectedAmount * this.selectedConversionCost.ratio
      else return this.selectedAmount / this.selectedConversionCost.ratio
    },
    expectedProductionTime(): number {
      // in seconds
      return this.selectedConversionCost.time_cost * this.getSelectedAmountConversion
    },
    selectedHeader(): string {
      return this.isDeconstructor ? this.$te('melting') : this.$te('production')
    },
    setBoxHeight(): string {
      if (this.state === OperationStates.Claim) return '20.313rem'
      if (this.state === OperationStates.Production) return '14.668rem'

      return '0'
    },
    getButtonThemeAttributes(): { themeName: string } {
      let name: string = ''
      if (this.state === OperationStates.Production) {
        name = 'credit'
      } else {
        name = 'confirm'
      }

      return {
        themeName: name,
      }
    },
    // getClosestDivisibleNumber will calculate -as the function says- the closest divisible number
    // (because user can create only full ingredients, not half)
    //  while decostracting, user can use max of his universal ingredient amount
    getMaxAmount(): number {
      const manufactureMaxAmount: number = this.getClosestDivisibleNumber(
        this.universalIngredient?.amount,
        this.selectedConversionCost.ratio,
      )

      return this.isDeconstructor ? this.selectedIngredient.amount : manufactureMaxAmount
    },
    isButtonDisabled(): boolean {
      if (this.isManufactureButtonDisabled) return true

      if (this.state === OperationStates.Default) {
        if (this.isManufacture) return this.getVisualizationOutputValue === 0
        else return this.selectedAmount === 0
      } else return false
    },
    // calculate the gem price based on seconds left
    // TODO: make it as countdown
    getGemPrice(): number {
      if (this.state === OperationStates.Production) {
        return Math.ceil(
          this.getConvertingState.converting_operation?.seconds_left /
            this.getConvertingState.converting_operation?.seconds_per_gem,
        )
      } else return 0
    },
    isModalBlocked(): boolean {
      return this.isLocalBlockStatusActive || !!localStorage.getItem(CONVERTOR_CONFIRMATION_BLOCK)
    },
    getVisualizationInputValue(): number {
      return this.state === OperationStates.Production && this.isManufacture
        ? this.getConversionCostByIngredientId(
            this.getConvertingState.converting_operation?.ingredient?.id,
          )?.ratio * this.getConvertingState.converting_operation?.amount
        : this.selectedAmount
    },
    getVisualizationOutputValue(): number {
      return this.state === OperationStates.Production && this.isManufacture
        ? this.getConvertingState.converting_operation?.amount
        : this.getSelectedAmountConversion
    },
  },
  created(): void {
    this.selectedAmount = this.minAmount
  },
  methods: {
    ...mapActions(useWarehouseStore, {
      meltIngredientAction: 'meltIngredient',
      claimIngredientAction: 'claimIngredient',
      createIngredientAction: 'createIngredient',
      skipProductionTimeAction: 'skipProductionTime',
      loadState: 'loadState',
    }),
    formatTime,
    async onCountdownEnd(): Promise<void> {
      await this.loadState()

      if (!this.getConvertingState.converting) {
        this.$emit('setState', OperationStates.Claim)
      }
    },
    setExchangeAmount(value: number): void {
      this.forceSliderReset = false
      this.selectedAmount = value
    },
    getClosestDivisibleNumber(number: number, divisor: number): number {
      return Math.floor(number / divisor) * divisor
    },
    // change item in carousel & reset slider values
    onArrowClick(direction: ConvertorDirection): void {
      this.forceSliderReset = true
      this.selectedAmount = 0

      if (direction === ConvertorDirection.Left) {
        this.selectedIngredientId !== this.getIngredientsConfig[0].id
          ? this.selectedIngredientId--
          : (this.selectedIngredientId = this.getIngredientsConfig.length - 1)
      }

      if (direction === ConvertorDirection.Right) {
        this.selectedIngredientId < this.getIngredientsConfig.length - 1
          ? this.selectedIngredientId++
          : (this.selectedIngredientId = this.getIngredientsConfig[0].id)
      }
    },
    getBackground(partName: string, isSideDistinguished: boolean = false): string {
      let url: string

      if (isSideDistinguished) {
        switch (this.type) {
          case 'deconstructor': {
            url = `${this.pathImages}events/frozen-championship/warehouse/convertor/${partName}--red.avif`
            break
          }
          case 'manufacture': {
            url = `${this.pathImages}events/frozen-championship/warehouse/convertor/${partName}--green.avif`
            break
          }
        }
      } else {
        url = `${this.pathImages}events/frozen-championship/warehouse/convertor/${partName}.avif`
      }

      return `url(${url})`
    },
    async meltIngredient(): Promise<void> {
      this.isDeconstructorButtonDisabled = true

      await this.meltIngredientAction({
        ingredient: this.selectedIngredient.ingredient.id,
        amount: this.selectedAmount,
      })

      this.selectedAmount = 0
      this.forceSliderReset = true

      if (!this.isModalBlocked) this.showOperationConfirmModal = false
      this.isDeconstructorButtonDisabled = false
    },
    async createIngredient(): Promise<void> {
      this.isManufactureButtonDisabled = true
      const response = await this.createIngredientAction({
        ingredient: this.selectedIngredient.ingredient.id,
        amount: this.getSelectedAmountConversion,
      })

      if (response?.converting_started) {
        if (this.isTier3) {
          this.$emit('setState', OperationStates.Claim)
        } else {
          this.$emit('setState', OperationStates.Production)
        }
      }

      if (!this.isModalBlocked) this.showOperationConfirmModal = false
      this.isManufactureButtonDisabled = false
    },
    async skipProductionTime(): Promise<void> {
      this.isManufactureButtonDisabled = true
      const response = await this.skipProductionTimeAction()

      if (response?.skipped) {
        this.$emit('setState', OperationStates.Claim)
      }

      this.isManufactureButtonDisabled = false
    },
    async claimIngredient(): Promise<void> {
      this.isManufactureButtonDisabled = true
      const response = await this.claimIngredientAction()

      if (response?.claimed) {
        this.$emit('setState', OperationStates.Default)
      }

      this.isManufactureButtonDisabled = false
    },
    setModalBlockStatus(status: boolean): void {
      this.isLocalBlockStatusActive = status
    },
  },
})
</script>

<style lang="scss" scoped>
.convertor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;

  &__side-part {
    align-items: flex-start;
    width: 38.813rem;
    height: 100%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 37.438rem;
      @include background(
        url($path-events + 'frozen-championship/warehouse/convertor/table-bg.avif'),
        cover
      );
      opacity: 0.8;
      z-index: 0;
    }

    & .heading {
      position: relative;
      width: 29.75rem;
      height: 4.125rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4.563rem;
        top: 0;
        left: 0;
        @include background(var(--header-bg), cover);
        z-index: 0;
      }

      & > * {
        z-index: 1;
      }
    }

    .conversion-wrapper {
      flex-direction: column;
      overflow: hidden;

      .conversion-management-box {
        width: 28.75rem;
        margin: 2rem 0.813rem 0.3rem 2.313rem;
        border-radius: 1.5rem;
        box-shadow: inset 0px 3px 3px 0 rgba(0, 0, 0, 0.45);
        border: solid 2px rgba(39, 67, 87, 0.48);
        background-color: rgb(12 25 34 / 45%);
        overflow: hidden;

        &--done {
          padding-bottom: 1rem;
        }

        &--active {
          padding: 0;
          margin: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
        }

        &__message-wrapper {
          padding: 1.5rem 3rem 1rem;
          margin-bottom: 3.125rem;
        }

        &__footer {
          width: 100%;
          height: 3.125rem;
          background: #821b1f;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 0;
          bottom: 0;
        }

        .number-block {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2.063rem 0 1.438rem;
          position: relative;
          width: 11.813rem;
          height: 4.625rem;
          margin-top: 1rem;
          @include background(
            url($path-events + 'frozen-championship/warehouse/convertor/ice-block.avif'),
            contain
          );
        }

        &-icon {
          height: 9rem;
          width: 9rem;
        }
      }

      .conversion-selection {
        position: relative;
        width: 90%;
        height: 15.75rem;
        @include background(var(--conversion-smoky-bg), cover);
        margin-top: -2rem;

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 12.313rem;
        }

        &__group {
          display: flex;
          height: 100%;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }

        &__arrow-left {
          @include background(
            url($path-events + 'frozen-championship/warehouse/convertor/arrow-left.avif'),
            cover
          );
        }

        &__arrow-right {
          @include background(
            url($path-events + 'frozen-championship/warehouse/convertor/arrow-right.avif'),
            cover
          );
        }

        &__arrow-left,
        &__arrow-right {
          width: 2.688rem;
          height: 5.25rem;
          z-index: 1;
        }

        &__preview {
          width: 9.313rem;
          height: 9.063rem;
          margin: 0 3.063rem;
          z-index: 0;
        }

        .conversion-detail {
          position: absolute;
          width: 29.75rem;
          height: 2.813rem;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          justify-content: center;
          align-items: center;

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 3.313rem;
            top: 0;
            left: 0;
            @include background(var(--conversion-detail-bg), cover);
            z-index: 0;
          }

          & > * {
            position: relative;
            z-index: 1;
          }
        }
      }
    }

    .progress-bar-controller {
      width: 55%;
      position: relative;
      margin-top: 1rem;
      margin-bottom: -1rem;
      height: 4.25rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: '';
        position: absolute;
        top: -1rem;
        left: 0;
        width: 100%;
        height: 4.75rem;
        @include background(
          url($path-events + 'frozen-championship/warehouse/convertor/progress-bar-bg.avif'),
          100%
        );
        z-index: 0;
      }

      & * {
        z-index: 1;
      }

      &__element {
        width: 25.25rem;
        height: 1.5rem;
        @include background(
          url($path-events + 'frozen-championship/warehouse/convertor/progress-bar-field.avif'),
          100%
        );
      }
    }
  }
}
</style>
