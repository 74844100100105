import type { BuildingConfigInterface } from '@/map-phaser-new/interfaces'
import { getRouteWebName } from '@/plugins'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import type { Router } from 'vue-router'

export const eventShipConfig: BuildingConfigInterface = {
  textureKey: 'buildings.event_ship',
  routeConfig: (router: Router): void => {
    const eventInfoStore = useEventInfoStore()
    router.push({
      name: eventInfoStore.getEventTeaserActive
        ? getRouteWebName('EventsTeaser')
        : getRouteWebName(
            eventInfoStore.getEventCustomProps?.routes?.event?.[
              eventInfoStore.getEventCollectionActive ? 'collection' : 'progress'
            ],
          ),
    })
  },
  positionX: 1410,
  positionY: 1360,
  depth: 5,
  originX: 0,
  keyForTranslateTitle: 'map.eventName',
  buildingTitleConfig: {
    positionX: 1660,
    positionY: 1750,
    depth: 6,
    textConfig: {
      originX: 0.5,
      positionX: -40,
      fontName: 'TT Lakes Condensed',
      fontSize: '24px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontColor: '#fff',
    },
    backgroundImageConfig: {
      textureName: 'common',
      frameNameRightSide: 'right_side',
      frameNameRightSideLocked: 'right_side_locked',
      frameNameLeftSide: 'left_side',
      frameNameLeftSideLocked: 'left_side_locked',
      originX: 0.5,
      widthCorrection: 100,
      heightCorrection: 10,
    },
  },
  outlineConfig: {
    textureKey: 'buildings.event_ship_outline',
    depth: 5,
    originX: 0,
    positionByLevel: {
      '1': { positionX: 1410, positionY: 1360 },
    },
  },
  inputConfig: {
    useHandCursor: true,
  },
  tween: {
    from: { positionX: -512, positionY: 1850 },
    to: { positionX: 1410, positionY: 1360 },
    duration: 7500,
    ease: 'Sine.easeOut',
    camera: {
      pan: {
        positionX: 1250,
        positionY: 1500,
        duration: 1000,
        ease: 'Sine.easeInOut',
      },
    },
  },
}
