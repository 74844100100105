<template>
  <main class="playoff-main event-bg flex flex-col w-full h-full safe-area">
    <playoff-header
      :is-masters="isMastersHeader"
      :active-button="playoffComponent"
      @set-section="setPlayoffComponent"
    />
    <component
      :is="playoffComponent"
      v-if="loaded"
      @reload-operation="loadIndividualPlayoffState"
    />
    <div v-if="!loaded" class="flexing flex-col flex-grow">
      <component-loading :is-loading="true" />
    </div>
  </main>
</template>

<script lang="ts">
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useMastersEventStore } from '@/store/pinia/events/mastersStore'
import { useEventPlayoffStore } from '@/store/pinia/events/playoffStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import PlayoffMastersTournamentLadderMain from './MastersTournamentLadder/MastersTournamentLadder.vue'
import PlayoffMastersTournamentListingMain from './MastersTournamentListing/MastersTournamentListingMain.vue'
import PlayoffMain from './Playoff/PlayoffMain.vue'
import PlayoffHeader from './PlayoffHeader.vue'
import PlayoffQualificationMain from './Qualification/PlayoffQualificationMain.vue'
import { PlayoffMainComponent } from '@/interfaces/events/Playoff'
import { EventMechanics } from '@/interfaces/events/EventInfo'
import type { RouteLocationNormalized } from 'vue-router'

interface ComponentData {
  playoffComponent: PlayoffMainComponent
  loaded: boolean
}

export default defineComponent({
  name: 'PlayoffComponent',
  components: {
    PlayoffHeader,
    PlayoffMain,
    PlayoffQualificationMain,
    PlayoffMastersTournamentListingMain,
    PlayoffMastersTournamentLadderMain,
  },
  data(): ComponentData {
    return {
      playoffComponent: PlayoffMainComponent.Qualification,
      loaded: false,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      eventMechanics: 'getEventMechanics',
    }),
    ...mapState(useEventPlayoffStore, {
      playoffState: 'getPlayoffState',
    }),
    isMasters(): boolean {
      return this.eventMechanics.includes(EventMechanics.MastersPlayoff)
    },
    isMastersHeader(): boolean {
      return [PlayoffMainComponent.Tournaments, PlayoffMainComponent.Ladder].includes(
        this.playoffComponent,
      )
    },
  },
  watch: {
    $route(to: RouteLocationNormalized, from: RouteLocationNormalized): void {
      if (!this.isMasters) return

      if (from?.query?.tournamentId && !to?.query?.tournamentId) {
        this.playoffComponent = PlayoffMainComponent.Tournaments
        return
      }

      if (to?.query?.component === 'ladder') {
        this.playoffComponent = PlayoffMainComponent.Ladder
        return
      }

      if (to?.query?.tournamentId) {
        this.loadMastersPlayoffState(parseInt(to?.query?.tournamentId as string))
        return
      }
    },
    async playoffComponent(): Promise<void> {
      this.loaded = false
      await this.loadPlayoffState()
      this.loaded = true
    },
  },
  async created(): Promise<void> {
    this.setupMastersPlayoffComponent()

    if (this.playoffComponent === PlayoffMainComponent.Tournaments) {
      // FIXME: @Peto Preco sa tu vola config s force parametrom? A nemoze byt hned na zaciatku, kedze sa vola aj za returnom.
      await this.loadPlayoffConfig(true)
      await this.loadTextsChanger()
      this.loaded = true
      return
    }

    // Pozor nemoze byt subezne jeden ma dependency druhy req.
    await this.loadPlayoffConfig()
    await this.loadIndividualPlayoffState()
  },
  methods: {
    ...mapActions(useEventPlayoffStore, ['loadPlayoffState', 'loadPlayoffConfig']),
    ...mapActions(useMastersEventStore, ['loadTextsChanger']),
    // FIXME: @Peto Tieto 2 metody by sa mohli zjednotit. A neviem dojst na to, preco sa v tejto masters metode vola metoda, ktora je urcena pre basic playoff. Nema za nou este nasledovat masters varianta?
    async loadMastersPlayoffState(playoffId: number): Promise<void> {
      this.loaded = false
      await this.loadPlayoffState(playoffId)
      this.setupIndividualPlayoffComponent()
      this.loaded = true
    },
    async loadIndividualPlayoffState(): Promise<void> {
      this.loaded = false
      await this.loadPlayoffState()
      if (!this.isMasters) this.setupIndividualPlayoffComponent()
      this.loaded = true
    },
    setupMastersPlayoffComponent(): void {
      if (!this.isMasters || this.playoffComponent !== PlayoffMainComponent.Qualification) return

      if (this.$route.query?.tournamentId) {
        this.$router.replace({
          query: { tournamentId: '' },
        })
      }

      this.playoffComponent = PlayoffMainComponent.Tournaments
    },
    setupIndividualPlayoffComponent(): void {
      this.playoffComponent =
        this.playoffState[0].state === 'qualification'
          ? PlayoffMainComponent.Qualification
          : PlayoffMainComponent.Playoff
    },
    async setPlayoffComponent({ component }: { component: PlayoffMainComponent }): Promise<void> {
      if (component === PlayoffMainComponent.Tournaments) {
        this.$router.push({
          query: { component: '' },
        })
      }

      this.playoffComponent = component
    },
  },
})
</script>
