import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const carsBottomTunnelToLeftCheckpoints: CheckpointsObjectInterface = {
  key: 'carsBottomTunnelToLeftCheckpoints',
  data: [
    {
      x: 1521,
      y: 1440,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsBottomTunnelToLeftAnimations1',
        },
      },
    },
    {
      x: 1401,
      y: 1405,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsBottomTunnelToLeftAnimations2',
        },
      },
    },
    {
      x: 1396,
      y: 1340,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsBottomTunnelToLeftAnimations3',
        },
      },
    },
    {
      x: 1492,
      y: 1271,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsBottomTunnelToLeftAnimations4',
        },
      },
    },
    {
      x: 1639,
      y: 1163,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsBottomTunnelToLeftAnimations5',
        },
      },
    },
    {
      x: 1840,
      y: 1072,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsBottomTunnelToLeftAnimations6',
        },
      },
    },
    {
      x: 1915,
      y: 968,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsBottomTunnelToLeftAnimations7',
        },
      },
    },
    {
      x: 1912,
      y: 925,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsBottomTunnelToLeftAnimations8',
        },
      },
    },
    {
      x: 2011,
      y: 863,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsBottomTunnelToLeftAnimations9',
        },
      },
    },
    {
      x: 2131,
      y: 880,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsBottomTunnelToLeftAnimations10',
        },
      },
    },
    {
      x: 2219,
      y: 884,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsBottomTunnelToLeftAnimations11',
        },
      },
    },
    {
      x: 2335,
      y: 870,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsBottomTunnelToLeftAnimations12',
        },
      },
    },
    {
      x: 2815,
      y: 726,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsBottomTunnelToLeftAnimations13',
        },
      },
    },
    {
      x: 3254,
      y: 786,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsBottomTunnelToLeftAnimations14',
        },
      },
    },
    {
      x: 3417,
      y: 721,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsBottomTunnelToLeftAnimations15',
        },
      },
    },
    {
      x: 3443,
      y: 688,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsBottomTunnelToLeftAnimations16',
        },
      },
    },
  ],
}
