import Phaser from 'phaser'
import { commonConfig } from './config'
import { ENV } from './constants'
import { currentEnv } from '@/globalVariables'

export const launch = (): void => {
  const game = new Phaser.Game(commonConfig)
  window.game = game
  // PixiJS Devtools (works with Phaser too).
  // https://github.com/bfanger/pixi-inspector
  if ([ENV.Dev, ENV.Qa].includes(currentEnv as ENV)) window.__PHASER_GAME__ = game
}
