<template>
  <div class="star-discipline-detail app-page-wrapper absolute">
    <section v-if="isLoadedData" class="w-full h-full safe-area">
      <header class="star-discipline-detail-header flexing mt-20">
        <div class="header-text uppercase text-50 text-texts-standard-default relative">
          <p class="index-txt">
            {{ $t('benefits.dailyLeague') }}
          </p>
          <div class="elipse" />
        </div>
      </header>
      <section class="star-discipline-detail-subheader flex justify-between items-center m-auto">
        <div class="left-text flex items-center relative">
          <app-discipline-icon
            :size="70"
            theme="light"
            :discipline-id="activeDisciplineId"
            class="disc-icon m-auto"
          />
          <p class="discipline-text text-texts-standard-additional uppercase text-40 font-bold">
            {{ $translateDiscipline(activeDisciplineId) }}
          </p>
          <div class="yellow-strip absolute bottom-0" />
        </div>
      </section>
      <main class="star-discipline-detail-main flex m-auto">
        <staroftheday-detail-left
          :player-data="getPlayerData"
          :active-discipline-id="activeDisciplineId"
          :attributes-keys="attributesKeys"
          :discipline="discipline"
        />
        <staroftheday-detail-right
          :discipline-data="disciplineData"
          :active-discipline-id="activeDisciplineId"
        />
      </main>
      <footer
        v-if="disciplineData.isPlayAvailable"
        class="star-discipline-detail-footer m-auto flex items-center"
        :class="hasBenefits ? 'justify-between' : 'justify-end'"
      >
        <div v-if="hasBenefits" class="discipline-left-footer flexing">
          <section v-for="(slot, slotId) in slots" :key="slotId">
            <use-benefit-button
              v-if="slot"
              :benefit-usage="config.cost"
              :slot-data="slot"
              :slot-id="slotId.toString()"
              :disabled="!isBenefitEnabled(slot)"
              @click="showSelectBenefitPopup = true"
              @reload-data="initMethods()"
            />
            <use-benefit-button v-else @click="showSelectBenefitPopup = true" />
          </section>
        </div>
        <section class="star-discipline-detail-footer-play flex">
          <app-multi-button
            btn-id="staroftheday-start-live"
            btn-type="secondary"
            btn-size="sm"
            multi-width="23.125rem"
            :btn-text="$t('button.start')"
            class="inline-flex align-top mx-4"
            :disabled="disablePlay || isSimulation || isButtonDisabled !== PlayButtonLock.Unset"
            :loading="isMinigame || isButtonDisabled === PlayButtonLock.Live"
            @click="onButtonClick(true)"
          >
            <template #rightPart>
              <span class="text-texts-standard-default">{{ config.cost }}</span>
              <app-main-icon :icon-size="48" :icon-name="config.parameter" />
            </template>
          </app-multi-button>
          <app-multi-button
            btn-id="staroftheday-start-simulation"
            btn-type="primary"
            btn-size="sm"
            multi-width="23.125rem"
            :btn-text="$t('button.simulate')"
            class="inline-flex align-top mx-4"
            :disabled="disablePlay || isButtonDisabled !== PlayButtonLock.Unset"
            :loading="isSimulation || isButtonDisabled === PlayButtonLock.Simulation"
            @click="onButtonClick(false)"
          >
            <template #rightPart>
              <span class="text-texts-standard-default">{{ config.cost }}</span>
              <app-main-icon :icon-size="48" :icon-name="config.parameter" />
            </template>
          </app-multi-button>
        </section>
      </footer>
      <footer v-else class="star-discipline-detail-footer m-auto flex items-center justify-between">
        <div class="result flex items-center">
          <section
            class="result-btn text-36 flexing relative"
            :class="{
              'text-texts-standard-default': $isWsm,
              'text-texts-standard-dark': $isSsm,
            }"
          >
            <p class="flexing left h-full">
              {{
                disciplineData?.results?.fail_status
                  ? $t(`arena.${disciplineData?.results?.fail_status}`)
                  : formatResult(disciplineData?.results?.currentResult, activeDisciplineId)
              }}
            </p>
            <p class="flexing right h-full text-texts-standard-important">
              {{ disciplineData?.results?.points ?? '-' }}
            </p>
          </section>
          <p class="result-text font-bold text-30 uppercase text-texts-standard-default">
            {{ $t('starOfTheDay.yourPerformance') }}
          </p>
        </div>
        <section class="star-discipline-detail-footer-play flex">
          <app-multi-button
            btn-id="detail-again"
            btn-type="credit"
            btn-size="md"
            multi-width="28.6875rem"
            :btn-text="$t('starOfTheDay.retry')"
            class="inline-flex align-top mx-4"
            :disabled="disableRetry"
            @click="retryPopUp"
          >
            <template #rightPart>
              <span class="text-texts-standard-default">
                {{ config.rematchCost }}
              </span>
            </template>
          </app-multi-button>
        </section>
      </footer>
      <info-popup v-if="popup" :popup-title="$t('starOfTheDay.retry')" @close="popup = false">
        <div class="rematch">
          <p class="text-up text-texts-standard-default text-36">
            {{ $t('starOfTheDay.anotherTryPopUpFirst') }}
          </p>
          <p class="text-bottom text-texts-standard-important text-36">
            {{ $t('starOfTheDay.anotherTryPopUpSecond') }}
          </p>
          <app-multi-button
            btn-id="detail-again"
            btn-type="credit"
            btn-size="md"
            multi-width="47.4375rem"
            :btn-text="$t('starOfTheDay.retry')"
            class="inline-flex align-top mx-4"
            @click="buyOffer"
          >
            <template #rightPart>
              <span class="text-texts-standard-default">
                {{ config.rematchCost }}
              </span>
            </template>
          </app-multi-button>
        </div>
      </info-popup>
      <info-popup
        v-if="showSelectBenefitPopup"
        popup-title="Activate benefit"
        @close="showSelectBenefitPopup = false"
      >
        <select-benefit
          :allowed-benefit-types="allowedBenefitTypes"
          :current-slots="getBenefitSlots()"
          :required-benefits="config.cost"
          @close-select-popup="closeSelectAndRefreshSlots()"
        />
      </info-popup>
      <skip-tutorial-popup
        v-if="showSkipTutorialPopup"
        :discipline-id="activeDisciplineId"
        :multiplier="activeMultiplicator(Multiplier.Arena).name"
        :no-tutorial-mode="mode.daily_league"
        @hide-popup="showSkipTutorialPopup = false"
      />
      <parameter-popup v-if="isPopupOpen" parameter="starts" @close="isPopupOpen = false" />
    </section>
    <component-loading :is-loading="!isLoadedData" />
  </div>
</template>

<script lang="ts">
import SelectBenefit from '@/components/Benefits/SelectBenefit.vue'
import UseBenefitButton from '@/components/Button/UseBenefitButton.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import ParameterPopup from '@/components/Popup/Parameter/ParameterPopup.vue'
import SkipTutorialPopup from '@/components/SkipTutorialPopup.vue'
import StarofthedayDetailLeft from '@/components/Staroftheday/StarofthedayDetailLeft.vue'
import StarofthedayDetailRight from '@/components/Staroftheday/StarofthedayDetailRight.vue'
import {
  BENEFITS_STAR_OF_THE_DAY,
  CATEGORY_BASE,
  EQUIPMENT,
  MECHANIC_BENEFIT,
  POWER,
  RESEARCH,
  STARTS,
  CATEGORY_SEASONAL,
  arenaMatchEndpoint,
  dailyLeagueDisciplinesEndpoint,
  minigameUrl,
  shopOfferEndPoint,
  userDisciplinesAttributesEndpoint,
  Multiplier,
} from '@/globalVariables'
import { calculateAttributes, formatResult, openMiniGame } from '@/helpers'
import { useConsumablesStore } from '@/store/pinia/consumablesStore'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useModeStore } from '@/store/pinia/modeStore'
import { useOfferStore } from '@/store/pinia/offerStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useStarOfTheDayStore } from '@/store/pinia/starOfThedayStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import type DailyLeagueResponse from '@/interfaces/responses/starOfTheDay/DailyLeagueResponse'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { RouteLocationNormalized } from 'vue-router'
import type { DailyLeagueDiscipline } from '@/interfaces/responses/starOfTheDay/DailyLeagueResponse'
import type { DisciplineData } from '@/helpers'
import type {
  ActiveBenefitSlots,
  BenefitSlot,
  UserBenefitsSlotsApiResponse,
} from '@/interfaces/Benefits'
import { PlayButtonLock } from '@/interfaces/Buttons'
import type { Nullable } from '@/interfaces/utils'
import type { BenefitSlotData } from '@/interfaces/Benefits'
import type { DailyLeaguePlayerData } from '@/interfaces/DailyLeague'

interface ComponentData {
  POWER: typeof POWER
  disciplineData: DailyLeagueDiscipline
  activeDisciplineId: number
  popup: boolean
  rematch: boolean
  isPopupOpen: boolean
  attributesKeys: string[]
  showSelectBenefitPopup: boolean
  showSkipTutorialPopup: boolean
  slots: Nullable<BenefitSlot['slots']>
  allowedBenefitTypes: BenefitSlot['benefit_type']
  PlayButtonLock: typeof PlayButtonLock
  isButtonDisabled: PlayButtonLock
  Multiplier: typeof Multiplier
}

export default defineComponent({
  components: {
    UseBenefitButton,
    InfoPopup,
    SelectBenefit,
    StarofthedayDetailLeft,
    StarofthedayDetailRight,
    SkipTutorialPopup,
    ParameterPopup,
  },
  data(): ComponentData {
    return {
      POWER,
      disciplineData: null,
      activeDisciplineId: 0,
      popup: false,
      rematch: false,
      isPopupOpen: false,
      attributesKeys: [],
      showSelectBenefitPopup: false,
      showSkipTutorialPopup: false,
      slots: null,
      allowedBenefitTypes: [],
      PlayButtonLock,
      isButtonDisabled: PlayButtonLock.Unset,
      Multiplier,
    }
  },
  computed: {
    ...mapState(useModeStore, { mode: 'getMode' }),
    ...mapState(useStarOfTheDayStore, {
      config: 'getConfig',
      getDisciplineById: 'getDisciplineById',
    }),
    ...mapState(useUserStore, {
      activeMultiplicator: 'getActiveMultiplicator',
      club: 'getClub',
      sex: 'getSex',
      avatar: 'getAvatar',
      country: 'getCountry',
      userData: 'getUserData',
    }),
    ...mapState(useTutorialStore, {
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useCoreStore, ['isMinigame', 'isSimulation']),
    ...mapState(useConsumablesStore, {
      getRematchOfferByDiscipline: 'getDlRematchOfferByDiscipline',
    }),
    ...mapState(useResponseTaskStore, {
      userGems: 'getGems',
      userStarts: 'getStarts',
      benefitsSlots: 'getBenefitsSlots',
    }),
    ...mapState(useDisciplineStore, {
      discipline: 'getSpecificDiscipline',
    }),
    disableRetry(): boolean {
      return this.userGems.value < this.config.rematchCost
    },
    disablePlay(): boolean {
      return this.userStarts.value < this.config.cost
    },
    getPlayerData(): Nullable<DailyLeaguePlayerData> {
      return {
        name: this.disciplineData.results?.name,
        country: this.disciplineData.results?.country,
        userId: this.disciplineData.results?.userId,
        eventBadges: this.disciplineData.results?.event_badges ?? null,
      }
    },
    hasBenefits(): boolean {
      return !!(this.slots && Object.keys(this.slots).length)
    },
    isLoadedData(): boolean {
      return !!(this.disciplineData && this.config)
    },
  },
  watch: {
    $route(route: RouteLocationNormalized): void {
      if (!route.query.reloadData) {
        return
      }
      this.initMethods()
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {
          reloadData: undefined,
        },
      })
    },
    isMinigame(newVal: boolean): void {
      if (newVal) return
      if (this.$route.query.reloadData) return
      this.isButtonDisabled = PlayButtonLock.Unset
    },
    isSimulation(newVal: boolean): void {
      if (newVal) return
      this.isButtonDisabled = PlayButtonLock.Unset
    },
  },
  created(): void {
    this.initMethods()
  },
  methods: {
    ...mapActions(useStarOfTheDayStore, [
      'loadConfig',
      'loadDailyLeagueData',
      'updateDailyLeagueData',
    ]),
    ...mapActions(useOfferStore, ['setRefreshingBundle']),
    ...mapActions(useCoreStore, ['setSimulation']),
    ...mapActions(useConsumablesStore, ['loadConsumablePriceGems']),
    ...mapActions(useResponseTaskStore, {
      loadBenefitsSlots: 'loadBenefitsSlots',
    }),
    ...mapActions(useDisciplineStore, {
      loadSpecificDiscipline: 'loadSpecificDiscipline',
    }),
    openMiniGame,
    formatResult,
    onButtonClick(isLive: boolean) {
      if (this.disablePlay) {
        this.setRefreshingBundle({
          type: STARTS,
          value: this.config.rematchCost,
        })
        this.isPopupOpen = true
        return
      }

      if (this.isButtonDisabled !== PlayButtonLock.Unset || this.isSimulation || this.isMinigame)
        return

      this.isButtonDisabled = isLive ? PlayButtonLock.Live : PlayButtonLock.Simulation
      this.$debounce((): void => {
        this[isLive ? 'play' : 'simulate']()
      })
    },
    isBenefitEnabled(data: BenefitSlotData): boolean {
      return Number(data?.amount) >= this.config.cost
    },
    async initMethods(): Promise<void> {
      this.activeDisciplineId = parseInt(this.$route.params?.id?.toString())
      this.loadConfig()
      await this.loadSpecificDiscipline({
        disciplineId: this.activeDisciplineId,
      })
      this.setDisciplineData()
      await this.loadDisciplineAttributes()
      await this.loadBenefitsSlots()
      this.getBenefitSlotData()
      this.setSimulation(false)
    },
    async setDisciplineData(): Promise<void> {
      await this.loadDailyLeagueData()
      this.disciplineData = this.getDisciplineById(this.activeDisciplineId)

      const opponentsData = await this.$axios.get<{}, DailyLeagueResponse>(
        `${dailyLeagueDisciplinesEndpoint}?discipline=${this.activeDisciplineId}`,
      )

      this.disciplineData.opponents = opponentsData?.disciplines?.find(
        (dataRow: DailyLeagueDiscipline): boolean => dataRow.id === this.activeDisciplineId,
      )?.opponents
    },
    play(): void {
      if (this.disablePlay) {
        this.setRefreshingBundle({
          type: STARTS,
          value: this.config.rematchCost,
        })
        this.isPopupOpen = true
        return
      }
      this.popup = false

      if (this.discipline.isTutorialPassed || this.isTutorial) {
        this.openMiniGame(
          minigameUrl,
          {
            params: {
              mode: this.mode.daily_league,
              discipline_id: this.activeDisciplineId,
              multiplier: this.activeMultiplicator(Multiplier.Arena).name,
            },
          },
          this.$router,
        )
      } else {
        this.showSkipTutorialPopup = true
      }
    },
    async simulate(): Promise<void> {
      if (this.disablePlay) {
        this.setRefreshingBundle({
          type: STARTS,
          value: this.config.cost,
        })
        this.isPopupOpen = true

        return
      }
      this.popup = false

      this.setSimulation(true)
      const response = await this.$axios.post<
        {},
        DailyLeagueResponse,
        {
          type: number
          discipline_id: number
          multiplier: string
          simulation: true
        }
      >(arenaMatchEndpoint, {
        type: this.mode.daily_league,
        discipline_id: this.activeDisciplineId,
        multiplier: this.activeMultiplicator(Multiplier.Arena).name,
        simulation: true,
      })
      this.updateDailyLeagueData(response)
      this.disciplineData = this.getDisciplineById(this.activeDisciplineId)
      this.setSimulation(false)
    },
    async loadDisciplineAttributes(): Promise<void> {
      const disciplineAttributesResponse = await this.$axios.post<{}, DisciplineData>(
        `${userDisciplinesAttributesEndpoint}/${this.activeDisciplineId}/user`,
      )

      if (!disciplineAttributesResponse?.usersAvailableDisciplines) return

      calculateAttributes(disciplineAttributesResponse, this.discipline)
      this.attributesKeys = [CATEGORY_BASE, EQUIPMENT, RESEARCH, CATEGORY_SEASONAL]
    },
    getBenefitSlotData(): void {
      const benefitStarOfTheDayDataSlots = {}
      const benefitStarOfTheDayDataBenefitTypes: string[] = []

      const benefitStarOfTheDayData =
        this.benefitsSlots?.filter(
          (benefit: UserBenefitsSlotsApiResponse): boolean =>
            benefit.benefit_building === BENEFITS_STAR_OF_THE_DAY,
        ) ?? []

      benefitStarOfTheDayData.forEach((element: UserBenefitsSlotsApiResponse) => {
        benefitStarOfTheDayDataSlots[element?.slot_id] = element.benefit
        benefitStarOfTheDayDataBenefitTypes.push(element.benefit_slot_type)
      })

      this.slots = benefitStarOfTheDayDataSlots
      this.allowedBenefitTypes = benefitStarOfTheDayDataBenefitTypes

      if (!this.slots || !this.discipline[POWER]) return

      // info tooltip – benefit value
      const benefit = Object.values(this.slots)[0]

      this.discipline[MECHANIC_BENEFIT] = {
        translateKey: 'common.benefit',
        value: Number(
          this.isBenefitEnabled(benefit) && benefit?.benefit_effect ? benefit?.benefit_effect : '0',
        ),
      }
      this.discipline[POWER].value =
        this.discipline[POWER].value + this.discipline[MECHANIC_BENEFIT].value
      if (!this.attributesKeys.includes(MECHANIC_BENEFIT)) {
        this.attributesKeys.push(MECHANIC_BENEFIT)
      }
    },
    getBenefitSlots(): ActiveBenefitSlots {
      const slotsRaw = this.slots
      const slots = {}
      for (const slotId in slotsRaw) {
        slots[slotId] = slotsRaw[slotId] ? slotsRaw[slotId].benefit_gdd_id : 0
      }

      return slots
    },
    closeSelectAndRefreshSlots(): void {
      this.discipline[POWER].value -= this.discipline[MECHANIC_BENEFIT].value ?? 0
      this.showSelectBenefitPopup = false
      this.getBenefitSlotData()
    },
    retryPopUp(): void {
      if (this.disableRetry) return
      this.popup = true
    },
    async buyOffer(): Promise<void> {
      this.popup = false
      await this.loadConsumablePriceGems()
      const rematchOffer = this.getRematchOfferByDiscipline(this.activeDisciplineId)
      if (!rematchOffer) return

      await this.$axios.post(shopOfferEndPoint, { offer_id: rematchOffer.id })
      this.disciplineData.isPlayAvailable = true
      this.setSimulation(false)
      this.getBenefitSlotData()
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';
@import '@/assets/styles/views/staroftheday/disciplineDetail.scss';
</style>
