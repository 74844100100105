<template>
  <div
    v-if="buildingData"
    class="club-centrum-building-small-box relative"
    :class="buildingData.hasReward ? 'box-has-reward bordered-2' : 'bordered-1'"
  >
    <header>
      <p class="text-texts-standard-additional text-40 uppercase">
        {{ $t('club.' + buildingData.name) }}
      </p>
    </header>
    <main>
      <p
        v-if="buildingData.hasReward"
        class="text-texts-standard-upgrade text-36 font-bold uppercase absolute right-0 mx-4"
      >
        {{ $t('common.claim') }}
      </p>
      <div
        class="club-centrum-building"
        :style="{
          backgroundImage:
            'url(' + pathImages + 'clubs/buildings/building-' + buildingData.name + '.avif)',
        }"
      />
    </main>
    <footer>
      <div class="club-centrum-building-employees">
        <clubs-active-employees :employees="buildingData.employees" />
      </div>
    </footer>
    <!-- docasne kym nemame pustenú galeriu -->
    <div
      v-if="buildingData.lock && buildingData.name === 'media'"
      class="club-centrum-building-small-box-lock"
    >
      <div class="club-centrum-building-small-box-comming">
        <app-icon class="club-centrum-building-small-box-comming-icon" icon-name="time" />
        <div class="training-box-locked-text">
          <p class="text-texts-standard-additional text-28 uppercase">
            {{ $t('common.comingSoon') }}
          </p>
        </div>
      </div>
    </div>
    <div v-else-if="buildingData.lock" class="club-centrum-building-small-box-lock">
      <div class="club-centrum-building-small-box-lock-content">
        <app-icon class="mb-3" icon-name="lock-lg" />
        <p
          class="text-texts-standard-default text-28"
          v-html="
            $replacePlaceholder(
              $replaceHtmlPlaceholders(
                $replacePlaceholder($t('club.unlockLevel'), '%s', '{b} %s {/b}'),
                'b',
                'text-texts-standard-important',
              ),
              '%s',
              buildingData.clubHouseLevelRequired,
            )
          "
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ClubsActiveEmployees from '@/components/Club/ClubsActiveEmployees.vue'
import { CLUB_CHAMPIONSHIP, pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { ClubBuildingData } from '@/interfaces/clubs/Clubs'
import type { Nullable } from '@/interfaces/utils'
import { capitalize } from '@/helpers'

interface ComponentData {
  pathImages: typeof pathImages
  CLUB_CHAMPIONSHIP: typeof CLUB_CHAMPIONSHIP
}

export default defineComponent({
  name: 'ClubsCentrumBuildingSmallBox',
  components: {
    ClubsActiveEmployees,
  },
  props: {
    buildingData: {
      type: Object as PropType<Nullable<ClubBuildingData>>,
      default: () => null,
    },
  },
  data(): ComponentData {
    return {
      CLUB_CHAMPIONSHIP,
      pathImages,
    }
  },
  methods: {
    goToBuilding(): void {
      this.$router.push({
        name: this.$getWebView('Clubs' + capitalize(this.buildingData.name)),
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.club-centrum-building-small-box {
  width: 36.875rem;
  height: 23.625rem;
  background-image: url($path-clubs + 'centrum/bg-small-box.avif');
  background-repeat: no-repeat;
  background-size: contain;
  border: 0.063rem solid #2794c1;
  cursor: pointer;

  &.box-has-reward {
    @if $isWsm {
      border: 0.125rem solid #04e358;
      box-shadow: 0 0 0.625rem #21c65e;
    }
    @if $isSsm {
      border: 0.125rem solid #0ee7cc;
    }
  }

  &-lock {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);

    &-content {
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  // Docasne kym nemame galeriu
  &-comming {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    &-icon {
      width: 4rem;
      height: 4rem;
    }

    .training-box-locked-text {
      border-left: 0.125rem solid #377fb8;
      border-right: 0.125rem solid #377fb8;
      padding: 0 1rem;
      height: 2.313rem;
      line-height: 2rem;
      margin: 1rem auto;
    }
  }

  // !!

  header {
    width: 100%;
    height: 3.75rem;
    background-image: url($path-clubs + 'centrum/bg-small-box-header.avif');
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: left;
    // Offset for diacritical marks from above
    padding: 0.5rem 1.375rem 0;
    position: relative;
    z-index: 0;
  }

  main {
    width: 100%;
    height: 84%;

    .club-centrum-building {
      width: 100%;
      height: 83%;
      background-repeat: no-repeat;
      background-position: top center;
      background-size: contain;
      position: relative;
      top: -1rem;
      z-index: 0;
    }
  }

  footer {
    width: 100%;
    height: 5.313rem;
    background-image: url($path-clubs + 'centrum/bg-small-box-footer.avif');
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    bottom: 0;

    .club-centrum-building-employees {
      height: 100%;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
