import {
  gamePassConfigEndpoint as GAME_PASS_CONFIG_ENDPOINT,
  gamePassStateEndpoint as GAME_PASS_STATE_ENDPOINT,
  gamePassActiveEffects as GAME_PASS_ACTIVE_EFFECTS_ENDPOINT,
  GAME_PASS_SKIP_REWARDED_ADS,
  MECHANIC_GAME_PASS,
} from '@/globalVariables'
import type GamePassConfigApiResponse from '@/interfaces/responses/gamePass/GamePassConfigApiResponse'
import type GamePassStateApiResponse from '@/interfaces/responses/gamePass/GamePassStateApiResponse'
import type GamePassEffectApiResponse from '@/interfaces/responses/gamePass/GamePassEffectsApiResponse'
import type {
  GamePassMilestoneProgress,
  GamePassMilestoneData,
} from '@/interfaces/responses/gamePass/GamePassStateApiResponse'
import type { GamePassOfferPacksData } from '@/interfaces/premium/Offer'
import { GamePassType } from '@/interfaces/premium/enums'
import type { Offer } from '@/interfaces/premium/Offer'

import { defineStore } from 'pinia'
import { ApiService } from '@/services/ApiService'
import { useResponseTaskStore } from './responseTaskStore'

const lastDaysOfPass = 345600 // 4 days in seconds

interface GamePassState {
  notification: number | null
  config: GamePassConfigApiResponse | null
  state: GamePassStateApiResponse | null
  offersData: GamePassOfferPacksData[] | null
  activeEffects: GamePassEffectApiResponse[] | null
}

export const useGamePassStore = defineStore('gamePassStore', {
  state: (): GamePassState => ({
    notification: null,
    config: null,
    state: null,
    offersData: null,
    activeEffects: null,
  }),
  getters: {
    getNotification(): number {
      return this.notification || 0
    },
    remainingTime(): GamePassState['config']['game_pass']['time_remaining'] {
      return this.config?.game_pass?.time_remaining || 0
    },
    offers(): GamePassState['offersData'] {
      return this.offersData
    },
    isPassEnding(): boolean {
      const remainingTime = this.config?.game_pass?.time_remaining || 0
      return remainingTime < lastDaysOfPass
    },
    milestonesCount(): GamePassState['config']['game_pass']['milestone_count'] {
      return this.config?.game_pass?.milestone_count || 0
    },
    currentMilestone(): number {
      return this.state?.milestones.free?.current_milestone || 0
    },
    milestoneProgress(): GamePassMilestoneProgress {
      return this.state?.milestones.free?.progress
    },
    milestonesConfig(): GamePassState['config']['content'] {
      return this.config.content
    },
    isNoVideoAds(): boolean {
      return this.isEffectActive(GAME_PASS_SKIP_REWARDED_ADS) || false
    },
    milestonesStateByType(): (type: string) => GamePassMilestoneData {
      return (type: string): GamePassMilestoneData => this.state?.milestones[type]
    },
    getActiveEffects(): GamePassEffectApiResponse[] {
      return this.activeEffects
    },
    isEffectActive(): (effect: string) => boolean {
      return (effect: string): boolean =>
        !!this.activeEffects?.find(
          (activeEffect: GamePassEffectApiResponse): boolean => activeEffect.name === effect,
        )
    },
    isGamePassUnlocked(): boolean {
      const responseStore = useResponseTaskStore()
      return responseStore.hasMechanic(MECHANIC_GAME_PASS)
    },
    isGamePassInitialized(): boolean {
      return !!this.config
    },
  },
  actions: {
    setNotification(value: number): void {
      this.notification = value
    },
    async loadConfig(force: boolean = false): Promise<void> {
      if (this?.config !== null && !force) return

      try {
        this.config = await ApiService.get<GamePassConfigApiResponse>(GAME_PASS_CONFIG_ENDPOINT, {
          force,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadState(force: boolean = false): Promise<void> {
      if (this?.state !== null && !force) return

      try {
        const response = await ApiService.get<GamePassStateApiResponse>(GAME_PASS_STATE_ENDPOINT, {
          force,
        })

        if (!response) return

        const mutatedPacks = response.offers.map((pack: Offer): GamePassOfferPacksData => {
          let type = ''
          if (pack.store_id.includes('game_pass_1')) {
            type = GamePassType.Pro
          }
          if (pack.store_id.includes('game_pass_2')) {
            type = GamePassType.Elite
          }

          return {
            type: type,
            purchased: pack.remaining_buys < 1,
            price: pack.price,
            storeId: pack.store_id,
            rewards: pack.offer_content,
          }
        })
        this.offersData = mutatedPacks
        this.state = response
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadEffects(force: boolean = false): Promise<void> {
      if (this?.activeEffects !== null && !force) return

      try {
        this.activeEffects = await ApiService.get<GamePassEffectApiResponse[]>(
          GAME_PASS_ACTIVE_EFFECTS_ENDPOINT,
          {
            force,
          },
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
