<template>
  <main v-if="eventPass?.length" class="event-pass-summary flexing flex-col w-full h-full">
    <event-pass3-summary-header />
    <event-pass3-summary-body />
  </main>
</template>

<script lang="ts">
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import EventPass3SummaryBody from './EventPass3SummaryBody.vue'
import EventPass3SummaryHeader from './EventPass3SummaryHeader.vue'

export default defineComponent({
  name: 'EventPass3SummaryMain',
  components: {
    EventPass3SummaryHeader,
    EventPass3SummaryBody,
  },
  computed: {
    ...mapState(usePremiumStore, {
      eventPass: 'getEventPass',
    }),
  },
})
</script>

<style lang="scss" scoped>
.event-pass-summary {
  flex-direction: column;
}
</style>
