import { isCanvasInactive } from '@/helpers'
import { cameraHandler } from '@/map-phaser-new/utils'
import { InteractiveObject } from '@/map-phaser-new/models/abstractClasses'
import type {
  ExclamationNotificationConfigInterface,
  PiggyBankOsraConfigInterface,
} from '@/map-phaser-new/interfaces'
import router from '@/router'
import { ExclamationNotification } from './exclamationNotification'
import { getRouteWebName } from '@/plugins'

export class PiggyBankOsra extends InteractiveObject {
  private osraImage: Phaser.GameObjects.Image
  private osraOutlineImage: Phaser.GameObjects.Image
  private osraNotification: ExclamationNotification

  constructor(positionX: number, positionY: number) {
    super(positionX, positionY)
  }

  public addToMap(
    activeScene: Phaser.Scene,
    baseConfig: PiggyBankOsraConfigInterface,
    notificationConfig: ExclamationNotificationConfigInterface,
  ): void {
    this.addOsraImage(activeScene, baseConfig)
    this.addOsraOutlineImage(activeScene, baseConfig)
    this.addOsraNotification(activeScene, baseConfig, notificationConfig)
    this.addOsraHoverListener()
    this.addOsraClickListener()
  }

  private addOsraImage(activeScene: Phaser.Scene, baseConfig: PiggyBankOsraConfigInterface): void {
    if (this.osraImage) return

    this.osraImage = activeScene.add
      .image(this.positionX, this.positionY, baseConfig.textureKey)
      .setDepth(baseConfig.depth)
      .setOrigin(baseConfig.origin)
      .setInteractive({ useHandCursor: baseConfig.inputConfig.useHandCursor })
      .setName(baseConfig.textureKey)
  }

  private addOsraOutlineImage(
    activeScene: Phaser.Scene,
    baseConfig: PiggyBankOsraConfigInterface,
  ): void {
    if (this.osraOutlineImage) return

    this.osraOutlineImage = activeScene.add
      .image(this.positionX, this.positionY, baseConfig.outlineConfig.textureKey)
      .setDepth(baseConfig.outlineConfig.depth)
      .setOrigin(baseConfig.origin)
      .setVisible(false)
      .setName(baseConfig.outlineConfig.textureKey)
  }

  private addOsraNotification(
    activeScene: Phaser.Scene,
    baseConfig: PiggyBankOsraConfigInterface,
    notificationConfig: ExclamationNotificationConfigInterface,
  ): void {
    if (this.osraNotification) return

    this.osraNotification = new ExclamationNotification(
      baseConfig.notificationX,
      baseConfig.notificationY,
    )
    this.osraNotification.addToMap(activeScene, notificationConfig)
  }

  private addOsraHoverListener(): void {
    this.osraImage.on('pointerover', (): void => {
      this.osraOutlineImage.setVisible(true)
    })

    this.osraImage.on('pointerout', (): void => {
      this.osraOutlineImage.setVisible(false)
    })
  }

  private addOsraClickListener(): void {
    this.osraImage.on(
      'pointerup',
      (event: (MouseEvent | TouchEvent) & { downElement: HTMLCanvasElement }): void => {
        if (cameraHandler.isDragging() || isCanvasInactive(event)) return

        router.push({ name: getRouteWebName('PremiumPiggyBank') })
      },
    )

    this.osraImage.on(
      'pointerdown',
      (event: (MouseEvent | TouchEvent) & { downElement: HTMLCanvasElement }): void => {
        if (isCanvasInactive(event)) return

        cameraHandler.setIsDrag(false)
      },
    )
  }

  public removeOsra(activeScene: Phaser.Scene): void {
    if (this.osraImage) {
      this.osraImage.removeAllListeners()
      this.osraImage.destroy()
      this.osraImage = null
    }

    if (this.osraOutlineImage) {
      this.osraOutlineImage.removeAllListeners()
      this.osraOutlineImage.destroy()
      this.osraOutlineImage = null
    }

    if (this.osraNotification) {
      this.osraNotification.removeFromMap(activeScene)
      this.osraNotification = null
    }
  }
}
