import { useCoreStore } from '@/store/pinia/coreStore'
import { useIframeStore } from '@/store/pinia/iframeStore'
import type { App } from 'vue'
import { gameStateHandler } from './gameStateHandler'

/**
 * Globalny visibilityListener aby sme sledovali ci sme v backgrounde a ci nie
 * Nefunguje na IoS neodpaluje ten event!!!
 */
export const visibilityListener = (): void => {
  document.addEventListener('visibilitychange', (): void => {
    // TODO: osetrenie inicializacneho stavu -> Pinia takyto nema takze by to nemal byt problem
    // Logika socketu
    if (document.visibilityState === 'visible') {
      const useIframe = useIframeStore()
      if (useIframe.isIframeOpened) return
      gameStateHandler()
    }
  })
}

/**
 * Globalny listener errorov
 * @param {*} app - instancia vue app
 */
export const globalErrorListener = (app: App): void => {
  const useCore = useCoreStore()
  // Production global property config
  // odchytavanie vue chyb v templateoch
  if (import.meta.env.NODE_ENV === 'production') {
    app.config.errorHandler = function (err: unknown): void {
      useCore.postError({ type: 'vue-error', message: String(err) })
    }

    // odchytavanie mimo vue chyb, napr. phaser
    window.addEventListener('error', (event: any): void => {
      useCore.postError({
        type: 'global-js-error',
        message: JSON.stringify({
          text: event.message,
          source: `${event.filename} ${event.lineno}:${event.colno}`,
        }),
      })
    })
  }
}
