import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const carParkPath: PathObjectInterface = {
  mainPath: {
    x: 2900.9934717864776,
    y: 647.4457339130979,
  },
  nextPaths: [
    {
      x: 2833.7501256,
      y: 615.2478469722295,
    },
    {
      x: 2762.871907072188,
      y: 626.1521970905037,
      control1X: 2791.9500468976794,
      control1Y: 609.7957672805226,
      control2X: 2811.94134909002,
      control2Y: 606.160933045135,
    },
    {
      x: 1939.463029571429,
      y: 1017.5825882914277,
    },
    {
      x: 1864.3749836627396,
      y: 1013.6249944325539,
      control1X: 1914.463029943958,
      control1Y: 1031.3325880865368,
      control2X: 1866.9630306517631,
      control2Y: 1026.3325881610426,
    },
    {
      x: 1417.2174633559966,
      y: 829.7000532492988,
    },
    {
      x: 1450.624989828095,
      y: 770.4999904260042,
      control1X: 1374.9999909549954,
      control1Y: 799.124991906807,
      control2X: 1403.749990526587,
      control2Y: 779.1249922048303,
    },
    {
      x: 1814.9999843984847,
      y: 592.8749949801714,
    },
    {
      x: 1793.7499847151344,
      y: 539.1249957811089,
      control1X: 1840.6249840166422,
      control1Y: 577.9999932944777,
      control2X: 1846.87498392351,
      control2Y: 564.2499934993687,
    },
    {
      x: 1538.749996144325,
      y: 427.5000050738454,
    },
    {
      x: 1536.249996181578,
      y: 408.75000535324216,
      control1X: 1528.7499962933366,
      control1Y: 417.500005222857,
      control2X: 1528.7499962933366,
      control2Y: 417.500005222857,
    },
    {
      x: 1584.2499917708344,
      y: 392.9999999105931,
    },
    {
      x: 1557.4999958649282,
      y: 382.50000574439764,
    },
  ],
}
