<template>
  <main class="flex flex-col items-end mt-7 mb-5">
    <section v-if="!isInAction">
      <equipment-item-attributes
        :attributes-data="getDataForAttribute"
        :has-arrows="true"
        custom-class="text-28"
      />
      <div v-if="isRepairing || isBroken" class="eq-item-repair-notice flexing">
        <p class="text-texts-standard-default text-34 uppercase font-bold">
          {{ $t('equipmentShop.broken') }}
        </p>
      </div>
    </section>

    <!-- Stav keď prebieha nejaka akcia (vyroba, oprava, upgrade..) -->
    <section v-else class="flexing flex-col">
      <p
        v-if="isAddonUpgrade"
        class="text-texts-standard-default text-36 font-bold uppercase leading-10"
      >
        {{ isAddonUpgrade }}
      </p>
      <div v-else-if="isUpgrade" class="eq-item-upgrade-stars flexing">
        <div
          v-for="(star, i) in nextLevelStars"
          :key="i"
          :class="[
            'icon-star-' + getStarType(star.type) + '-32',
            { 'blink-star': purchasedStars === i + 1 },
          ]"
        />
      </div>

      <shrink-text
        :text="$t(setItemStatusText)"
        :font-size="36"
        text-class-list="text-texts-standard-important font-bold uppercase leading-10"
        :animated-overflow="true"
      />
      <div class="eq-item-process flexing">
        <div :class="'eq-icon-action pulse action-' + setActionIcon" />
        <equipment-item-timer
          :time-to-end="attributesData.timeToEnd ?? 0"
          class="eq-item-timer-margin"
          @finish-operation="$emit('finishOperation')"
        />
      </div>
    </section>
  </main>
  <div class="white-strip" />
</template>

<script lang="ts">
import EquipmentItemAttributes from '@/components/Equipment/EquipmentItemAttributes.vue'
import EquipmentItemTimer from '@/components/Equipment/EquipmentItemTimer.vue'
import { EQUIPMENT_BROKEN, EQUIPMENT_PURCHASED, EQUIPMENT_UNLOCKED } from '@/globalVariables'
import type { AttributesData } from '@/interfaces/Equipment'
import type { Nullable } from '@/interfaces/utils'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import ShrinkText from '@/components/ShrinkText.vue'

interface AttributeData {
  parameters: AttributesData['parameters']
  damaged: boolean
  unlocked: boolean
  disciplineId: AttributesData['disciplineId']
  currentDurability: AttributesData['currentDurability']
  premium: AttributesData['premium']
  equipmentBonusData: AttributesData['equipmentBonusData']
}

interface ComponentData {
  nextLevelStars: AttributesData['stars'][0][]
}

export default defineComponent({
  name: 'EquipmentItemBoxAttributes',
  components: {
    EquipmentItemTimer,
    EquipmentItemAttributes,
    ShrinkText,
  },
  props: {
    attributesData: {
      type: Object as PropType<Nullable<AttributesData>>,
      default: () => null,
    },
  },
  emits: ['finishOperation'],
  data(): ComponentData {
    return {
      nextLevelStars: [],
    }
  },
  computed: {
    isInAction(): boolean {
      return !!(this.isRepairing || this.isDelivering || this.isUpgrade)
    },
    isBroken(): boolean {
      return this.attributesData.durabilityState === EQUIPMENT_BROKEN
    },
    isUnlocked(): boolean {
      return !!this.attributesData.unlocked
    },
    isDelivering(): boolean {
      return !!this.attributesData.delivering
    },
    isRepairing(): boolean {
      return !!this.attributesData.repair
    },
    isUpgrade(): boolean {
      return !!this.attributesData.upgrade && !!this.attributesData.timeToEnd
    },
    isAddonUpgrade(): string {
      if (this.attributesData.operation?.item?.name?.includes('equipmentUpgrade')) {
        return this.$t(`equipmentShop.${this.attributesData.operation.item.name}`)
      }
      return ''
    },
    purchasedStars(): number {
      return this.nextLevelStars.filter(
        (star: AttributesData['stars'][0]): boolean => star.type === EQUIPMENT_PURCHASED,
      ).length
    },
    setItemStatusText(): string {
      if (this.isUnlocked) return 'common.unlocked'
      if (this.isRepairing) return 'equipmentShop.repairing'
      if (this.isUpgrade) return 'equipmentShop.upgrading'
      if (this.isDelivering) return 'equipmentShop.delivering'
      return ''
    },
    setActionIcon(): string {
      if (this.isRepairing) return 'repair'
      if (this.isUpgrade) return 'upgrade'
      if (this.isDelivering) return 'delivering'
      return ''
    },
    getDataForAttribute(): AttributeData {
      return {
        parameters: this.attributesData.parameters,
        damaged: this.isRepairing || this.isBroken,
        unlocked: this.isUnlocked,
        disciplineId: this.attributesData.disciplineId,
        currentDurability: this.attributesData.currentDurability,
        premium: this.attributesData.premium,
        equipmentBonusData: this.attributesData.equipmentBonusData,
      }
    },
  },
  created(): void {
    this.callNextLevel()
  },
  methods: {
    // Dalsi level v hviezdickach volame len ak mame vybavu v stave upgrade
    callNextLevel() {
      if (this.isUpgrade) this.starsForNextLevel()
    },
    // Funkcia ktora mi zobrazi nasledovny level (v hviezdickach) vybavy.
    starsForNextLevel(): void {
      if (!this.attributesData.stars) return

      const purchasedStarsLength = this.attributesData.stars?.filter(
        (star: AttributesData['stars'][0]): boolean => star.type === EQUIPMENT_PURCHASED,
      ).length

      this.nextLevelStars = [...this.attributesData.stars]
      this.nextLevelStars[purchasedStarsLength] = { type: EQUIPMENT_PURCHASED }
    },
    // typ hviezdicky
    getStarType(type: AttributesData['stars'][0]['type']): string {
      switch (type) {
        case EQUIPMENT_PURCHASED:
          return 'full'
        case EQUIPMENT_UNLOCKED:
          return 'empty'
        default:
          return 'lock'
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/equipment-icons.scss';
@import '@/assets/styles/components/icons/discipline-icons.scss';
@import '@/assets/styles/components/icons/stars-icons.scss';

main {
  position: relative;

  section {
    width: 100%;
  }

  .eq-item-row {
    margin: 0 0.5rem 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .gray-strip {
    margin: 0.75rem auto;
  }

  .eq-item-repair-notice {
    position: absolute;
    width: 26.25rem;
    height: 4.938rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url($path-equipment + 'box-backgrounds/new/bg-damaged.avif');
    top: 3.2rem;
    right: -1.25rem;
  }

  .eq-item-upgrade-stars {
    margin: 0.75rem auto;
  }

  .eq-item-timer-margin {
    margin: 1.5rem auto;
  }

  .eq-item-process {
    width: 90%;
    height: 5rem;
    margin-top: 0.5rem;

    .eq-icon-action {
      width: 4rem;
      height: 4rem;
      background-size: contain;

      &.action {
        &-repair {
          background-image: url($path-equipment + 'icons/icon-repair.avif');
        }

        &-delivering {
          background-image: url($path-equipment + 'icons/icon-delivering.avif');
        }

        &-upgrade {
          background-image: url($path-equipment + 'icons/icon-upgrade.avif');
        }
      }
    }
  }

  .blink-star {
    animation: blinker 3s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  .pulse {
    animation: pulse-animation 2s infinite;
  }

  @keyframes pulse-animation {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
}

.white-strip {
  margin: 0 auto;
}
</style>
