<template>
  <div class="title cursor-pointer relative">
    <img
      v-if="
        [EventType.FrozenChampionship, EventType.ChallengeCup, EventType.AutumnFair].includes(
          namespace,
        )
      "
      class="title-logo h-full"
      :src="`${pathImages}events/${eventAssetsDirectory}/event-logo.avif`"
      :class="{ 'pointer-events-none': eventCollectionActive }"
      @click="onClick"
    />
    <app-icon
      v-if="
        !eventCollectionActive &&
        [EventType.FrozenChampionship, EventType.ChallengeCup, EventType.AutumnFair].includes(
          namespace,
        )
      "
      icon-name="info-rounded"
      class="title-icon pointer-events-none absolute top-0 -right-4"
    />
  </div>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import { EventType } from '@/interfaces/events/EventInfo'

interface ComponentData {
  pathImages: typeof pathImages
  EventType: typeof EventType
}

export default defineComponent({
  name: 'EventsTitle',
  data(): ComponentData {
    return {
      pathImages,
      EventType,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      eventAssetsDirectory: 'getEventAssetsDirectory',
      eventCollectionActive: 'getEventCollectionActive',
      namespace: 'getEventType',
    }),
  },
  methods: {
    onClick(): void {
      if (this.eventCollectionActive) return
      this.$router.push({ name: this.$getWebView('EventsTeaser') })
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.title {
  height: 6.125rem;

  &:active {
    transform: scale(0.95);
  }

  &-icon {
    transform: $scale-default;
  }
}
</style>
