<template>
  <div class="final-quest flex flex-col justify-center items-center">
    <div class="icon-done-lg" />
    <p class="text-60 text-texts-standard-important uppercase final-quest-title">
      {{ $t('career.congratulations') }}
    </p>
    <p class="text-50 text-texts-standard-default uppercase">
      {{ $t('career.youFinishedMainBranch') }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CareerTaskFinal',
})
</script>

<style lang="scss" scoped>
.final-quest {
  height: 38.2rem;
  background: url($path-career + 'detail/final_quest_bg.avif') center no-repeat;
  background-size: 100% 100%;
  padding-left: 25rem;
  padding-bottom: 5rem;

  &-title {
    text-shadow: 0.125rem 0.219rem 0 rgba(0, 0, 0, 0.86);
  }
}
</style>
