<template>
  <section class="club-milestone" :class="{ 'no-club': !hasClub }">
    <milestones-sub-header :is-club="true" />
    <club-milestones-middle />
    <club-milestones-footer />
  </section>
</template>

<script lang="ts">
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import MilestonesSubHeader from '../MilestonesSubHeader.vue'
import ClubMilestonesFooter from './ClubMilestonesFooter.vue'
import ClubMilestonesMiddle from './ClubMilestonesMiddle.vue'
import { MilestoneMainComponent } from '@/interfaces/events/Milestones'

export default defineComponent({
  name: MilestoneMainComponent.Club,
  components: {
    MilestonesSubHeader,
    ClubMilestonesMiddle,
    ClubMilestonesFooter,
  },
  computed: {
    ...mapState(useUserStore, {
      hasClub: 'getHasClub',
    }),
  },
})
</script>

<style scoped lang="scss">
.no-club {
  background: #0000008f;
}
</style>
