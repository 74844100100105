<template>
  <section
    class="events-overview flex flex-col justify-center w-full h-full relative safe-area"
    :data-event-theme="namespace"
  >
    <div class="events-overview-right flex flex-col items-center">
      <div class="flexing mt-8 mb-7">
        <p class="text-32 text-texts-standard-default mr-8">{{ $te('eventEnd') }}</p>
        <app-timer :time="eventTimer" />
      </div>
      <div class="events-overview-right-title mb-6 flexing">
        <p class="text-48 text-texts-standard-default uppercase font-bold">
          {{ $te('eventDescription') }}
        </p>
      </div>
      <p
        class="text-32 text-texts-standard-default mb-3"
        v-html="
          $replacePlaceholder(
            $replaceHtmlPlaceholders(
              $replacePlaceholder(
                $te('eventDisciplineFocus'),
                '{discipline}',
                '{b}{discipline}{/b}',
              ),
              'b',
              'text-texts-standard-important',
            ),
            '{discipline}',
            $t(`discipline.discipline_${eventDisciplineId}`),
          )
        "
      />
      <p
        class="text-32 text-texts-standard-default mb-6"
        v-html="
          $replaceHtmlPlaceholders($te('eventOverviewInfo'), 'b', 'text-texts-standard-important')
        "
      />
      <app-button
        btn-type="confirm"
        btn-size="sm"
        :btn-text="$te('eventMoreInfo')"
        @click="showAboutPopup = true"
      />
      <div class="events-overview-right-reputation flexing mt-9">
        <event-reputation-box direction="col" />
      </div>
      <div class="flex flex-col justify-center items-center mt-4">
        <p class="text-32 text-texts-standard-additional">{{ $te('eventIncreaseReputation') }}</p>
        <div class="flexing space-x-8 mt-4">
          <app-button
            btn-size="sm"
            :btn-text="$te('eventCompetition')"
            @click="$router.push({ name: $getWebView('EventsArena') })"
          />
          <app-button
            btn-size="sm"
            :btn-text="$te('eventTraining')"
            @click="$router.push({ name: $getWebView('EventsTraining') })"
          />
        </div>
      </div>
    </div>
    <div
      class="absolute events-overview-discipline flex flex-col justify-between items-center"
      :class="{ [`events-overview-img-${eventDisciplineId}`]: !!eventDisciplineId }"
    >
      <div
        class="events-overview-discipline-pass flexing mt-9"
        :class="{ 'pass-background': !isMasterPassActive }"
      >
        <event-pass-button btn-size="sm" :is-overview="true" :pass="PassType.Master" />
      </div>
      <!-- THIS BUTTON IS HIDDEN FOR NOW -->
      <app-button v-if="false" btn-size="sm" class="mb-7" btn-type="secondary" @click="() => {}">
        Museum
      </app-button>
    </div>
  </section>

  <discipline-event-about-popup
    v-if="showAboutPopup"
    :is-visible="showAboutPopup"
    @close="showAboutPopup = false"
  />
</template>

<script lang="ts">
import EventReputationBox from '@/components/Events/EventReputationBox.vue'
import DisciplineEventAboutPopup from '@/components/Events/EventsPopups/DisciplineEventAboutPopup.vue'
import EventPassButton from '@/components/Events/GlobalEventsComponents/EventPassButton.vue'
import { PassType } from '@/interfaces/events/Passes'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import { usePremiumStore } from '@/store/pinia/premiumStore'

interface ComponentData {
  PassType: typeof PassType
  showAboutPopup: boolean
}

export default defineComponent({
  name: 'EventsOverview',
  components: {
    EventPassButton,
    EventReputationBox,
    DisciplineEventAboutPopup,
  },
  data(): ComponentData {
    return {
      PassType,
      showAboutPopup: false,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      masterPass: 'getMasterPass',
    }),
    ...mapState(useEventInfoStore, {
      eventDisciplineId: 'getEventDisciplineId',
      namespace: 'getEventType',
      eventTimer: 'getEventLeftBoxTimer',
    }),
    isMasterPassActive(): boolean {
      return this?.masterPass?.[0]?.remaining_buys === 0
    },
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='discipline'] {
  @for $i from 1 through 20 {
    --events-overview-img-#{$i}: url('#{$path-events}discipline-event/teaser/teaser-img-#{$i}.webp');
  }
}

.events-overview {
  &-right {
    margin-right: 1rem;
    width: 53%;
    margin-left: auto;

    &-title {
      height: 4.875rem;
      width: 100%;

      @if $isWsm {
        @include background(
          url($path-events + 'discipline-event/overview/EventsOverview-title-bg.avif'),
          contain
        );
      }

      @if $isSsm {
        background-image: linear-gradient(
          to right,
          transparent 0%,
          #30446c 20%,
          #30446c 80%,
          transparent 100%
        );
        border-style: solid;
        border-width: 0.188rem;
        border-image-source: linear-gradient(
          to right,
          transparent 0%,
          rgba(255, 255, 255, 0.3) 25%,
          rgba(255, 255, 255, 0.3) 50%,
          rgba(255, 255, 255, 0.3) 75%,
          transparent 100%
        );
        border-image-slice: 1;
      }
    }

    &-reputation {
      height: 10rem;
      width: 100%;
      border-style: solid;
      border-width: 0.188rem;
      border-image-slice: 1;
      padding-bottom: 0.2rem;

      @if $isWsm {
        background-image: linear-gradient(
          to right,
          transparent 0%,
          #1a507d 25%,
          #24699d 50%,
          #1a507d 75%,
          transparent 100%
        );
        border-image-source: linear-gradient(
          to right,
          transparent 0%,
          #a0dcff 25%,
          #a0dcff 75%,
          transparent 100%
        );
      }

      @if $isSsm {
        background-image: linear-gradient(
          to right,
          transparent 0%,
          rgba(48, 68, 108, 0.7) 20%,
          rgba(48, 68, 108, 0.7) 80%,
          transparent 100%
        );
        border-image-source: linear-gradient(
          to right,
          transparent 0%,
          rgba(255, 255, 255, 0.3) 25%,
          rgba(255, 255, 255, 0.3) 50%,
          rgba(255, 255, 255, 0.3) 75%,
          transparent 100%
        );
      }
    }
  }

  &-discipline {
    top: 50%;
    transform: translateY(-50%);
    width: 58.9375rem;
    height: 57rem;
    padding-right: 10rem;
    @include background(null, cover, right);

    @for $i from 1 through 20 {
      &.events-overview-img-#{$i} {
        background-image: var(--events-overview-img-#{$i});
      }
    }

    &-pass {
      height: 5.813rem;
      width: 41.25rem;
    }

    .pass-background {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      border-style: solid;
      border-width: 0.188rem;
      border-image-slice: 1;

      @if $isWsm {
        background: linear-gradient(
          to right,
          transparent 0%,
          rgba(12, 29, 52, 0.8) 25%,
          rgba(12, 29, 52, 0.8) 75%,
          transparent 100%
        );
        border-image-source: linear-gradient(
          to right,
          transparent 0%,
          rgba(55, 127, 184, 0.8) 25%,
          rgba(55, 127, 184, 0.8) 75%,
          transparent 100%
        );
      }

      @if $isSsm {
        background-image: linear-gradient(
          to right,
          transparent,
          #30446c 20%,
          #30446c 80%,
          transparent 100%
        );
        border-style: solid;
        border-width: 0.188rem;
        border-image-source: linear-gradient(
          to right,
          transparent 0%,
          rgba(255, 255, 255, 0.3) 25%,
          rgba(255, 255, 255, 0.3) 50%,
          rgba(255, 255, 255, 0.3) 75%,
          transparent 100%
        );
        border-image-slice: 1;
      }
    }
  }
}
</style>
