<template>
  <div class="lab-economics app-page-wrapper flex flex-col absolute">
    <menu-component
      menu-type="lab"
      :title="$t('research.lab')"
      right-box-icon="research_points"
      :right-box-value="researchPoints.value"
      :is-tooltip="true"
      :sub-menu-active="true"
      :sub-menu="{
        1: {
          text: $t('research.Disciplines'),
          route: $getWebView('LabDisciplines'),
        },
        2: {
          text: $t('research.economics'),
          route: $getWebView('LabEconomics'),
        },
        3: {
          text: $t('research.camps'),
          route: $getWebView('LabCamps'),
        },
      }"
    />
    <section class="relative w-full h-full flex flex-col">
      <div v-if="showDetail" class="lab-overlay" @click="hideDetail" />
      <div class="relative w-full h-full flex flex-col safe-area">
        <header class="lab-economics-header flexing relative">
          <modal-head
            :title-popup="$t('research.lab')"
            :hide-content="true"
            class="modal-head--backgroundless"
          >
            <template #popupContent>
              <lab-popup />
            </template>
          </modal-head>
        </header>
        <main v-if="showContent" class="lab-economics-main m-auto flex justify-center relative">
          <div class="lab-left-side absolute">
            <lab-info
              :researched="[currentlyResearched, researchMaxCount]"
              :finished-branches="[currentlyFinished, getSkillCount]"
              :tree-type="TreeType.Economics"
            />
          </div>
          <div class="lab-right-side flexing">
            <app-scrollbar width="100%" height="100%" scroll="y" slide="y">
              <lab-economics-tree
                :key="labTreeComponentKey"
                :lab-data="skillTrees?.economics"
                :has-ongoing-research-tree="isActiveEconomicsResearch"
                @select-skill="selectSkill"
              />
            </app-scrollbar>
          </div>
        </main>
        <lab-detail
          v-if="showDetail && selectedSkillId"
          ref="researchDetail"
          :skill-id="selectedSkillId"
          :skill-type="SkillType.Research"
          @research-cell="researchCell"
          @finished-research="finishedResearch"
          @speedup="speedup"
        />
        <lab-footer ref="researchStatus" @speedup="speedup" @finished-research="finishedResearch" />

        <component-loading height="100%" :is-loading="!showContent" />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import LabDetail from '@/components/Lab/LabDetail.vue'
import LabFooter from '@/components/Lab/LabFooter.vue'
import LabInfo from '@/components/Lab/LabInfo.vue'
import LabPopup from '@/components/Lab/LabPopup.vue'
import ModalHead from '@/components/ModalHead.vue'
import LabEconomicsTree from '@/components/Lab/Economics/LabEconomicsTree.vue'
import {
  researchCellEndpoint,
  researchSeenEndpoint,
  speedUpResearchEndpoint,
} from '@/globalVariables'
import {
  allSkillsCount,
  currentlyFinishedSkillCount,
  currentlyResearchedSkillCount,
  newUnlockedCells,
} from '@/helpers'
import { useLabStore } from '@/store/pinia/labStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { SkillType, TreeType } from '@/interfaces/lab/Cell'
import type {
  ResearchCellApiRespopnse,
  ResearchSeenApiResponse,
} from '@/interfaces/responses/lab/LabResponses'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { CLEAR_AND_HIDE_TIMER, LABORATORY } from '@/map-phaser-new/constants/common'

interface ComponentData {
  activateStarted: boolean
  showDetail: boolean
  selectedSkillId: string
  SkillType: typeof SkillType
  TreeType: typeof TreeType
  labTreeComponentKey: number
}

export default defineComponent({
  name: 'LabEconomics',
  components: {
    ModalHead,
    LabPopup,
    LabInfo,
    LabFooter,
    LabDetail,
    LabEconomicsTree,
  },
  data(): ComponentData {
    return {
      activateStarted: false,
      showDetail: false,
      selectedSkillId: '',
      TreeType,
      SkillType,
      labTreeComponentKey: 0,
    }
  },
  computed: {
    ...mapState(usePhaserGameIntegrationStore, { phaserEmitter: 'getPhaserEventEmitter' }),

    ...mapState(useLabStore, {
      skillTreeLoaded: 'getSkillTreesLoaded',
      skillTrees: 'getSkillTrees',
      getSkillCount: 'getSkillCount',
      researchInProgress: 'getResearchInProgress',
      isActiveEconomicsResearch: 'hasActiveEconomicsResearch',
    }),
    ...mapState(useResponseTaskStore, {
      researchPoints: 'getResearchPoints',
    }),
    showContent(): boolean {
      return this.skillTreeLoaded && this.skillTrees?.economics?.length > 0
    },

    currentlyResearched(): number {
      if (this.skillTrees?.economics) {
        return currentlyResearchedSkillCount(this.skillTrees.economics)
      }
      return 0
    },
    researchMaxCount(): number {
      if (this.skillTrees?.economics) {
        return allSkillsCount(this.skillTrees.economics)
      }
      return 0
    },
    currentlyFinished(): number {
      if (this.skillTrees?.economics) {
        return currentlyFinishedSkillCount(this.skillTrees.economics)
      }
      return 0
    },
    unlockedUnseenCells(): string[] {
      if (this.skillTrees?.economics) {
        return newUnlockedCells(this.skillTrees.economics)
      }
      return []
    },
  },

  async created(): Promise<void> {
    this.initMethod()
  },
  methods: {
    ...mapActions(useLabStore, {
      loadEconomy: 'loadEconomicsTree',
      setResearchStatus: 'setResearchStatus',
    }),
    ...mapActions(usePhaserGameIntegrationStore, {
      manageProgressTimerVisibility: 'manageProgressTimerVisibility',
    }),
    async initMethod(): Promise<void> {
      this.hideDetail()
      await this.loadEconomy()
      this.setResearchStatus()
      this.markSeen()
    },
    hideDetail(): void {
      this.selectedSkillId = ''
      this.showDetail = false
    },
    async markSeen(): Promise<void> {
      try {
        await this.$axios.put<{}, ResearchSeenApiResponse>(researchSeenEndpoint, {
          cell_ids: this.unlockedUnseenCells,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async researchCell(skillId: string): Promise<void> {
      if (this.activateStarted) {
        return
      }
      if (skillId) {
        this.activateStarted = true
        Promise.all([
          await this.$axios.put<{}, ResearchCellApiRespopnse>(researchCellEndpoint, {
            cell_id: skillId,
          }),
          await this.setResearchStatus(),
          this.loadEconomy(),
        ])
          .then((): void => {
            if (this.researchInProgress?.remaining_time) {
              const timerStart = Date.now()
              const timerEnd = timerStart + this.researchInProgress.remaining_time * 1000

              this.manageProgressTimerVisibility({
                buildingName: LABORATORY,
                heading: this.$t('research.researchCounting'),
                timerStart: timerStart,
                timerEnd: timerEnd,
              })
            }

            this.$refs.researchStatus.getStatus()
            if (this.showDetail) {
              this.$refs.researchDetail.getSkillData(skillId)
            }
            this.initMethod()
            this.activateStarted = false
          })
          .catch((error: unknown): void => {
            console.error(error)
          })

        this.forceRerender()
      }
    },
    forceRerender(): void {
      // Changing the key causes the component to be rerendered
      this.labTreeComponentKey += 1
    },
    async speedup(operationId: string): Promise<void> {
      Promise.all([
        this.$axios.put<{}, null>(speedUpResearchEndpoint, { operation_id: operationId }),
        this.setResearchStatus(),
        this.loadEconomy(),
      ])
        .then((): void => {
          this.phaserEmitter?.emit(CLEAR_AND_HIDE_TIMER, LABORATORY)

          this.$refs.researchStatus.getStatus()
          if (this.showDetail) {
            this.$refs.researchDetail.getSkillData()
          }
          this.initMethod()
        })
        .catch((error: unknown): void => {
          console.error(error)
        })
    },
    async finishedResearch(): Promise<void> {
      Promise.all([this.setResearchStatus(), this.loadEconomy()])
        .then((): void => {
          this.phaserEmitter?.emit(CLEAR_AND_HIDE_TIMER, LABORATORY)

          this.$refs.researchStatus.getStatus()
          if (this.showDetail) {
            this.$refs.researchDetail.getSkillData()
          }
          this.initMethod()
        })
        .catch((error: unknown): void => {
          console.error(error)
        })
    },
    selectSkill(id: string): void {
      this.selectedSkillId = id
      this.showDetail = true
    },
  },
})
</script>

<style lang="scss" scoped>
.lab-economics {
  &.app-page-wrapper {
    background: url($path-backgrounds + 'lab-wrapper-bg.jpg?v2') center bottom no-repeat;
    background-size: cover;
  }

  &-header {
    width: 97%;
    margin-top: 1.125rem;
  }
}

.lab-economics-main {
  width: 110rem;
  height: 100%;
}

.lab-left-side {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.lab-right-side {
  position: relative;
  width: 86rem;
  left: 13rem;
  height: calc(100% + 1rem);
  top: -1rem;
}

.lab-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
}
</style>
