import { MECHANIC_TOURNAMENTS } from '@/globalVariables'
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import TournamentsView from '@/views/tournaments/TournamentsView.vue'
import type { RouteRecordCustom } from './RouteInterface'

// WEB ROUTES
export const tournamentsRoutesWeb: RouteRecordCustom[] = [
  {
    name: 'TournamentsCalendarWeb',
    path: '/tournaments/calendar-web',
  },
  {
    name: 'TournamentsHistoryWeb',
    path: '/tournaments/history-web',
  },
  {
    name: 'TournamentsDetailWeb',
    path: '/tournaments/detail-web/:id',
  },
  {
    name: 'TournamentsMilestonesWeb',
    path: '/tournaments/milestones-web',
  },
  {
    name: 'TournamentsRankingsPointsWeb',
    path: '/tournaments/rankings/points-web',
  },
  {
    name: 'TournamentsRankingsTitlesWeb',
    path: '/tournaments/rankings/titles-web',
  },
].map(
  (route: RouteRecordCustom): RouteRecordCustom => ({
    ...route,
    component: TournamentsView,
    meta: { requiresMechanic: MECHANIC_TOURNAMENTS, requiresAuth: true },
  }),
)

// MOBILE ROUTES
export const tournamentsRoutesMobile: RouteRecordCustom[] = [
  ...tournamentsRoutesWeb.map(
    (route: RouteRecordCustom): RouteRecordCustom => ({
      name: (route.name as string).replace(/Web$/, ''),
      path: (route.path as string).replace(/-web/, ''), // nie je tam $, lebo nemusi to byt na konci, ak mame napr. tournament_id
      meta: route.meta,
      components: {
        header: HeaderComponent,
        default: route.component,
      },
    }),
  ),
]
