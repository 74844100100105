<template>
  <div
    class="arena-history app-page-wrapper absolute flex flex-col items-center"
    data-cy="arena-history-wrapper"
  >
    <arena-menu />

    <section class="w-full h-full safe-area-x">
      <matches-history :history="getMatchesHistory" redirect-link="ArenaHistoryReport" />
    </section>
  </div>
</template>

<script lang="ts">
import ArenaMenu from '@/components/Arena/ArenaMenu.vue'
import MatchesHistory from '@/components/Reports/MatchesHistory.vue'
import { useMatchesStore } from '@/store/pinia/matchesStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ArenaHistory',
  components: {
    MatchesHistory,
    ArenaMenu,
  },
  computed: {
    ...mapState(useMatchesStore, ['getMatchesHistory']),
  },
  async created(): Promise<void> {
    await this.loadMatchesHistory()
  },
  methods: {
    ...mapActions(useMatchesStore, ['loadMatchesHistory']),
  },
})
</script>
