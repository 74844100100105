// Generated by https://quicktype.io
import { CLUB, FOCUS, NARRATIVE, CLIENT_INPUT } from '@/globalVariables'
import type { Nullable } from '@/interfaces/utils'

export interface TutorialState {
  isTutorial: boolean
  actualStage: Nullable<TutorialStage>
  report: Nullable<Report>
  redrawFocus: boolean
  tutorialId: Nullable<string>
  validButtonId: string
  tutorialInProgress: boolean
}

export interface TutorialStage {
  id?: number
  type: StageType
  name: string
  overlay: boolean
  clientId?: string[] | string
  loadRewardedItems?: boolean
  texts: TutorialText[]
  map?: string
  pageName: string
  mechanic?: number
  isTaskTrackerHidden?: boolean
  discipline?: number
  unlocks?: Unlock[]
  redirect?: string
  skipStep?: boolean
  secondStage?: string
  isAnnieFlipped?: boolean
  onSuccess?: any // TODO
  onPreload?: any // TODO
  logToStepAfterError?: string
}

export interface Unlock {
  type: string
  progress_value: number
}

export interface Report {
  reportId: string
  disciplineId: number
}

export enum StageType {
  Club = CLUB,
  Focus = FOCUS,
  Narrative = NARRATIVE,
  ClientInput = CLIENT_INPUT,
  BackFromMinigame = 'navrat z minihry', // TODO: do we need this?
}

export interface TutorialText {
  dialogue: string
  boss: string
  pose: string
}

export interface Dialogue {
  dialogue: string
  boss: string
  pose: string
}

export enum Name {
  Energy = 'energy',
  EnergyDrink = 'energy_drink',
  Experience = 'EXPERIENCE',
  GrandPrize = 'grand_prize',
  Money = 'money',
  PowerBoost = 'PowerBoost',
  Starts = 'starts',
  UniversalTrainingPoints = 'UNIVERSAL_TRAINING_POINTS',
  WildCard = 'wild_card',
}

export enum Rarity {
  Diamond = 'diamond',
  Gold = 'gold',
}

export enum Type {
  ShopOfferReward = 'shop_offer_reward',
}
