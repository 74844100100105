<template>
  <section class="user-info relative flexing flex-col">
    <div class="user-info-image relative flexing">
      <avatar-box class="user-info-avatar" :avatar-id="Number(avatar)" :avatar-gender="sex" />
      <player-level class="user-info-level absolute" />
      <div v-if="hasClub" class="user-info-club">
        <avatar-menu-club-logo />
      </div>
    </div>
    <app-user
      :id="userData?.id"
      class="user-info-user flexing"
      :country="country"
      :name="userData?.username"
      :badges-data="eventBadges ?? null"
    />
    <div class="user-info-skill flexing">
      <div :class="`discipline icon-discipline-${disciplineId}-light-70`" />
      <p
        v-if="disciplineAttributes[POWER]"
        class="power font-bold text-texts-standard-important text-50"
      >
        {{ $filters.$formatNumber(disciplineAttributes[POWER].value) }}
      </p>
      <tippy theme="WSM2" placement="left" max-width="31rem">
        <div class="user-info-icon" />
        <template #content>
          <profile-tooltip
            :rows="attributesMapper"
            :title="$t('common.totalPower')"
            :total-value="disciplineAttributes?.POWER?.value"
          />
        </template>
      </tippy>
    </div>

    <div v-if="hasMechanic(MECHANIC_BENEFIT)" class="user-info-benefits flexing">
      <section v-for="(slot, slotId) in slots" :key="slotId">
        <use-benefit-button
          v-if="slot"
          :benefit-usage="startsNumber"
          :slot-data="slot"
          :slot-id="slotId.toString()"
          @click="showSelectBenefitPopup = true"
          @reload-data="reloadDataWithOpponents"
        />
        <use-benefit-button v-else @click="showSelectBenefitPopup = true" />
      </section>
    </div>
    <div v-else>
      <use-benefit-button :lock-icon="true" :disabled="true" />
    </div>
    <app-multi-button
      btn-id="career-boss-fight-start"
      btn-type="secondary"
      :btn-text="$t('button.start')"
      btn-size="sm"
      multi-width="25.688rem"
      class="inline-flex align-top mt-6"
      :disabled="isStartDisabled"
      :loading="isMinigame"
      @click="start"
    >
      <template #rightPart>
        <span class="text-texts-standard-default">
          {{ startsNumber }}
        </span>
        <app-main-icon :icon-size="48" icon-name="starts" />
      </template>
    </app-multi-button>
  </section>
  <info-popup
    v-if="showSelectBenefitPopup"
    :popup-title="$t('common.useBenefit')"
    @close="showSelectBenefitPopup = false"
  >
    <select-benefit
      :allowed-benefit-types="allowedBenefitTypes"
      :required-benefits="startsNumber"
      :current-slots="getBenefitSlots()"
      @close-select-popup="closeSelectAndRefreshSlots()"
    />
  </info-popup>
  <parameter-popup
    v-if="isParameterPopupOpen"
    parameter="starts"
    @close="isParameterPopupOpen = false"
  />
</template>

<script lang="ts">
import AvatarMenuClubLogo from '@/components/AvatarMenu/AvatarMenuClubLogo.vue'
import SelectBenefit from '@/components/Benefits/SelectBenefit.vue'
import UseBenefitButton from '@/components/Button/UseBenefitButton.vue'
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import ParameterPopup from '@/components/Popup/Parameter/ParameterPopup.vue'
import AvatarBox from '@/components/Profile/AvatarBox.vue'
import PlayerLevel from '@/components/Profile/PlayerLevel.vue'
import ProfileTooltip from '@/components/Profile/ProfileTooltip.vue'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import {
  BENEFIT_CAREER,
  BOSS_FIGHT_CONFIG,
  CATEGORY_BASE,
  CATEGORY_BENEFIT,
  CATEGORY_SEASONAL,
  EQUIPMENT,
  MECHANIC_BENEFIT,
  POWER,
  RESEARCH,
  STARTS,
  gamesConfigEndpoint,
  userDisciplinesAttributesEndpoint,
} from '@/globalVariables'
import { calculateAttributes } from '@/helpers'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useOfferStore } from '@/store/pinia/offerStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'

import type { BossFightConfigApiResponse } from '@/interfaces/responses/bossFight/bossFightApiResponse'
import type { DisciplineData, FinalObject } from '@/helpers/disciplineAttributesCalculator'
import type {
  ActiveBenefitSlots,
  BenefitData,
  BenefitSlot,
  BenefitSlotData,
  UserBenefitsSlotsApiResponse,
} from '@/interfaces/Benefits'

interface ComponentData {
  startsNumber: number
  showSelectBenefitPopup: boolean
  MECHANIC_BENEFIT: typeof MECHANIC_BENEFIT
  CATEGORY_BASE: typeof CATEGORY_BASE
  EQUIPMENT: typeof EQUIPMENT
  RESEARCH: typeof RESEARCH
  POWER: typeof POWER
  disciplineAttributes: FinalObject
  slots: BenefitSlot['slots']
  allowedBenefitTypes: string[]
  isParameterPopupOpen: boolean
  attributesKeys: string[]
}

export default defineComponent({
  name: 'PreBossFightUserInfo',
  components: {
    InfoPopup,
    AvatarBox,
    PlayerLevel,
    AppUser,
    UseBenefitButton,
    SelectBenefit,
    ProfileTooltip,
    ParameterPopup,
    AvatarMenuClubLogo,
  },
  props: {
    disciplineId: {
      type: Number,
      required: true,
    },
  },
  emits: ['start', 'refreshOpponents'],
  data(): ComponentData {
    return {
      startsNumber: 3,
      showSelectBenefitPopup: false,
      MECHANIC_BENEFIT,
      CATEGORY_BASE,
      EQUIPMENT,
      RESEARCH,
      POWER,
      disciplineAttributes: {} as FinalObject,
      slots: {} as BenefitSlot['slots'],
      allowedBenefitTypes: [],
      isParameterPopupOpen: false,
      attributesKeys: [],
    }
  },
  computed: {
    ...mapState(useUserStore, {
      avatar: 'getAvatar',
      sex: 'getSex',
      userData: 'getUserData',
      country: 'getCountry',
      hasClub: 'getHasClub',
      eventBadges: 'getEventBadges',
    }),
    ...mapState(useCoreStore, ['isMinigame']),
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
      userStarts: 'getStarts',
      benefitsSlots: 'getBenefitsSlots',
    }),
    attributesMapper(): { text: string; value: number; highlight?: boolean }[] {
      return [
        {
          text: this.$t('common.basicAttribute'),
          value: this.disciplineAttributes[CATEGORY_BASE]?.value ?? 0,
        },
        {
          text: this.$t('common.equipment'),
          value: this.disciplineAttributes[EQUIPMENT]?.value ?? 0,
        },
        {
          text: this.$t('common.research'),
          value: this.disciplineAttributes[RESEARCH]?.value ?? 0,
        },
        {
          text: this.$t('common.benefit'),
          value: this.disciplineAttributes[CATEGORY_BENEFIT]?.value ?? 0,
        },
        {
          text: this.$t('common.seasonalAttribute'),
          value: this.disciplineAttributes[CATEGORY_SEASONAL]?.value ?? 0,
          highlight: true,
        },
      ]
    },
    isStartDisabled(): boolean {
      return this.userStarts.value < this.startsNumber
    },
  },
  watch: {
    async benefitsSlots(value: BenefitData[]): Promise<void> {
      const hasBenefitCareer = value.filter(
        (benefit: UserBenefitsSlotsApiResponse): boolean =>
          benefit.benefit_building === BENEFIT_CAREER,
      )

      if (hasBenefitCareer.length > 0) {
        await this.loadDisciplineAttributes()
        this.getBenefitSlotData()
      }
    },
  },
  created(): void {
    if (!this.hasMechanic(MECHANIC_BENEFIT)) {
      this.loadDisciplineAttributes()
    }
    this.loadConfig()
  },
  methods: {
    ...mapActions(useOfferStore, ['setRefreshingBundle']),
    ...mapActions(useResponseTaskStore, {
      loadBenefitsData: 'loadBenefits',
    }),
    ...mapActions(useDisciplineStore, {
      loadSpecificDiscipline: 'loadSpecificDiscipline',
    }),
    async reloadDataWithOpponents(): Promise<void> {
      await this.reloadData()
      this.$emit('refreshOpponents')
    },
    async reloadData(): Promise<void> {
      await this.loadBenefitsData()
      await this.loadSpecificDiscipline({ disciplineId: this.disciplineId })
      this.loadDisciplineAttributes()
      this.loadConfig()
    },
    isBenefitEnabled(data: BenefitSlotData): boolean {
      return Number(data?.amount) >= this.startsNumber
    },
    getBenefitSlotData(): void {
      const benefitCareerData =
        this.benefitsSlots?.filter(
          (benefit: UserBenefitsSlotsApiResponse): boolean =>
            benefit.benefit_building === BENEFIT_CAREER,
        ) ?? []

      const benefitCareerDataSlots = {}
      const benefitCareerDataBenefitTypes: string[] = []

      benefitCareerData.forEach((element: UserBenefitsSlotsApiResponse) => {
        benefitCareerDataSlots[element?.slot_id] = element.benefit
        benefitCareerDataBenefitTypes.push(element.benefit_slot_type)
      })

      this.slots = benefitCareerDataSlots
      this.allowedBenefitTypes = benefitCareerDataBenefitTypes

      if (!this.slots || !this.disciplineAttributes[POWER]) return

      // info tooltip – benefit value
      const benefit = Object.values(this.slots)[0]

      this.disciplineAttributes[MECHANIC_BENEFIT] = {
        translateKey: 'common.benefit',
        value: Number(
          this.isBenefitEnabled(benefit) && benefit?.benefit_effect ? benefit?.benefit_effect : '0',
        ),
      }
      this.disciplineAttributes[POWER].value =
        this.disciplineAttributes[POWER].value + this.disciplineAttributes[MECHANIC_BENEFIT].value
      this.attributesKeys.push(MECHANIC_BENEFIT)
    },
    getBenefitSlots(): ActiveBenefitSlots {
      const slotsRaw = this.slots

      const slots = {}
      for (const slotId in slotsRaw) {
        slots[slotId] = slotsRaw[slotId] ? slotsRaw[slotId].benefit_gdd_id : 0
      }

      return slots
    },
    closeSelectAndRefreshSlots(): void {
      this.disciplineAttributes[POWER].value -=
        this.disciplineAttributes[MECHANIC_BENEFIT].value ?? 0
      this.showSelectBenefitPopup = false
      this.getBenefitSlotData()
      this.$emit('refreshOpponents')
    },
    async loadDisciplineAttributes(): Promise<void | boolean> {
      const disciplineAttributesResponse = await this.$axios.post<{}, DisciplineData>(
        `${userDisciplinesAttributesEndpoint}/${this.disciplineId}/user`,
      )

      if (!disciplineAttributesResponse?.usersAvailableDisciplines) return

      calculateAttributes(disciplineAttributesResponse, this.disciplineAttributes)
      this.attributesKeys = [CATEGORY_BASE, EQUIPMENT, RESEARCH, CATEGORY_SEASONAL]
      return true
    },
    async loadConfig(): Promise<void> {
      const bossFightConfig = await this.$axios.post<
        {},
        BossFightConfigApiResponse,
        { keys: string }
      >(gamesConfigEndpoint, {
        keys: BOSS_FIGHT_CONFIG,
      })

      this.startsNumber = bossFightConfig?.cost ?? this.startsNumber
    },
    start(): void {
      if (this.isStartDisabled) {
        this.setRefreshingBundle({
          type: STARTS,
          value: this.startsNumber,
        })
        this.isParameterPopupOpen = true
        return
      }
      this.$emit('start')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';

.user-info {
  width: 30%;
  padding: 1.75rem 2.875rem 0 2.875rem;
  margin-bottom: 2.313rem;
  margin-right: 1.188rem;

  &-user,
  &-skill {
    position: relative;
    width: 36.875rem;
    height: 5rem;
    @if $isWsm {
      background: linear-gradient(
        to right,
        rgba(108, 158, 196, 0),
        #71c2ff75,
        rgba(108, 158, 196, 0)
      );
    }
    @if $isSsm {
      background: linear-gradient(
        to right,
        rgba(39, 50, 73, 0) 0%,
        rgba(39, 50, 73, 0.85) 25%,
        rgba(52, 65, 93, 1) 50%,
        rgba(39, 50, 73, 0.85) 75%,
        rgba(39, 50, 73, 0) 100%
      );
    }

    &::before,
    &::after {
      position: absolute;
      content: '';
      height: 0.1rem;
      width: 100%;
      left: 50%;
      transform: translate(-50%);
      @if $isWsm {
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.01),
          #fff,
          rgba(255, 255, 255, 0.01)
        );
      }
      @if $isSsm {
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.01),
          #586b9d,
          rgba(255, 255, 255, 0.01)
        );
      }
    }
    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  &-user {
    margin-top: 2.75rem;
    height: 3.75rem;
  }

  &-skill {
    margin-top: 0.625rem;
    margin-bottom: 2.313rem;
    @if $isWsm {
      background: linear-gradient(
        to right,
        rgba(108, 158, 196, 0),
        #71c2ff40,
        rgba(108, 158, 196, 0)
      );
    }
    @if $isSsm {
      background: linear-gradient(
        to right,
        rgba(39, 50, 73, 0) 0%,
        rgba(39, 50, 73, 0.85) 25%,
        rgba(52, 65, 93, 1) 50%,
        rgba(39, 50, 73, 0.85) 75%,
        rgba(39, 50, 73, 0) 100%
      );
    }

    & .discipline {
      width: 4rem;
      height: 4rem;
    }
  }

  &-level {
    top: 0rem;
    left: 0rem;
    z-index: 1;
  }

  &-icon {
    background: url($path-images + 'sprites/icons/ICO_INFO_ROUNDED_56.avif') center no-repeat;
    background-size: contain;
    width: 1.938rem;
    height: 1.938rem;
    margin: 0.813rem 0 0.75rem 1.25rem;
  }

  &-club {
    z-index: 1;
  }
}
</style>
