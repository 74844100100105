<template>
  <section class="app-page-wrapper game-pass flex flex-col safe-area">
    <menu-component menu-type="game-pass" :title="$t('gamePass.gamePass')">
      <app-button
        btn-type="credit"
        :btn-text="$t('premiumGroup.buy')"
        @click="$router.push({ name: $getWebView('PremiumGamePassPacks') })"
      />
    </menu-component>
    <component-loading :is-loading="!dataLoaded" />
    <main v-if="dataLoaded">
      <game-pass-header v-if="milestoneInfo" :milestone-info="milestoneInfo" />
      <section class="flex">
        <div
          class="game-pass-left px-6 pt-4 h-full flex flex-col"
          :class="{ 'is-mobile': $isMobile() }"
        >
          <section class="game-pass-left-wrapper flex flex-col justify-between mt-2">
            <game-pass-purchase-button
              v-for="pass in offers.toReversed()"
              :key="`pass-btn-${pass.type}`"
              :pass-type="pass.type"
              :is-bought="pass.purchased"
            />
            <p class="milestone-label text-texts-standard-dark text-32 flexing font-bold">
              {{ $t('event.tabMilestones') }}
            </p>
            <game-pass-purchase-button pass-type="basic" />
          </section>
        </div>
        <div class="game-pass-right relative mt-2">
          <section class="reward-glow absolute h-full w-full">
            <div
              v-for="pass in offers.toReversed()"
              :key="`pass-glow-${pass.type}`"
              class="reward-glow-row w-full"
              :class="pass.type"
            />
            <div class="reward-glow-row basic w-full"></div>
          </section>
          <app-scrollbar
            height="100%"
            width="100%"
            scroll="x"
            slide="x"
            :arrows="true"
            arrow-pos="top-1/2"
            class="flex flex-col"
            :scroll-to-element="scrollToElement"
            scroll-to-element-align="end"
          >
            <div class="flex reward-wrapper">
              <span
                v-for="milestone in milestonesCount"
                :key="`reward-col-${milestone}`"
                :class="{
                  'first-locked': milestone === getFirstlocked,
                  'actual-milestone': milestone === currentMilestone,
                }"
              >
                <span
                  v-if="premiumRewards && basicRewards"
                  class="reward-col flex flex-col justify-center"
                >
                  <game-pass-reward
                    v-for="reward in premiumRewards[milestone]"
                    :key="reward.id"
                    :reward-type="reward.rewardType"
                    :reward-status="reward.rewardStatus"
                    :reward="reward.reward"
                    :milestone-id="reward.id"
                    @reload-data="loadData"
                  />
                  <section class="milestone-progress-wrapper relative">
                    <span
                      :style="{ width: `${getProgress(milestone)}%` }"
                      class="progress-fill absolute top-0"
                      :class="{
                        full: getProgress(milestone) >= 100,
                      }"
                    ></span>
                    <p
                      class="text-texts-standard-default text-36 font-bold flexing w-full h-full absolute top-0 left-0 pt-1 revert-skew"
                    >
                      {{ milestone }}
                    </p>
                  </section>
                  <game-pass-reward
                    v-for="reward in basicRewards[milestone]"
                    :key="reward.id"
                    :reward-type="reward.rewardType"
                    :reward-status="reward.rewardStatus"
                    :reward="reward.reward"
                    :milestone-id="reward.id"
                    @reload-data="loadData"
                  />
                </span>
              </span>
              <game-pass-milestones-extension v-if="extraMilestone" :extra-data="extraMilestone" />
            </div>
          </app-scrollbar>
        </div>
      </section>
    </main>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import GamePassReward from '@/components/Premium/GamePass/GamePassReward.vue'
import GamePassPurchaseButton from '@/components/Premium/GamePass/GamePassPurchaseButton.vue'
import GamePassHeader from '@/components/Premium/GamePass/GamePassHeader.vue'
import GamePassMilestonesExtension from '@/components/Premium/GamePass/GamePassMilestonesExtension.vue'

import type { RouteLocationNormalized } from 'vue-router'
import type Reward from '@/interfaces/Reward'
import { useGamePassStore } from '@/store/pinia/gamePassStore'
import { mapActions, mapState } from 'pinia'
import type { GamePassContent } from '@/interfaces/responses/gamePass/GamePassConfigApiResponse'
import { GamePassType } from '@/interfaces/premium/enums'
import { GamePassMilestoneStatus } from '@/interfaces/premium/enums'
import type { GamePassExtraMilestones } from '@/interfaces/responses/gamePass/GamePassStateApiResponse'

interface LevelBagsRewardsData {
  type: string
  status: string
  reward: Reward
  level: number
}

interface ComponentData {
  rewardsData: Record<number, LevelBagsRewardsData[]>
  dataLoaded: boolean
}

interface MilestoneInfo {
  actual: number
  goal: number
  milestone: number
}

interface PassReward {
  id: number
  rewardType: string
  rewardStatus: string
  reward: Reward
}

export default defineComponent({
  name: 'PremiumGamePass',
  components: {
    GamePassHeader,
    GamePassPurchaseButton,
    GamePassReward,
    GamePassMilestonesExtension,
  },
  data(): ComponentData {
    return {
      rewardsData: [],
      dataLoaded: false,
    }
  },
  computed: {
    ...mapState(useGamePassStore, [
      'milestonesCount',
      'currentMilestone',
      'milestoneProgress',
      'offers',
      'milestonesConfig',
      'milestonesStateByType',
    ]),
    getFirstlocked(): number {
      return this.currentMilestone < this.milestonesCount ? this.currentMilestone + 1 : 0
    },
    scrollToElement(): string {
      if (this.extraMilestone) return '.milestones-extension-wrapper'

      return '.actual-milestone'
    },
    premiumRewards(): Record<number, PassReward[]> {
      const rewards = []

      this.offers.toReversed().forEach((offer) => {
        this.milestonesConfig[offer.type].forEach((milestone: GamePassContent): void => {
          rewards[milestone.order] = rewards[milestone.order]?.length
            ? rewards[milestone.order]
            : []
          rewards[milestone.order].push(this.getMilestoneReward(milestone, offer.type))
        })
      })

      return rewards
    },
    basicRewards(): Record<number, PassReward[]> {
      const rewards = []

      this.milestonesConfig.free.forEach((milestone: GamePassContent): void => {
        rewards[milestone.order] = []
        rewards[milestone.order].push(this.getMilestoneReward(milestone, GamePassType.Basic))
      })

      return rewards
    },
    extraMilestone(): GamePassExtraMilestones {
      if (!this.milestonesStateByType('extra')) return null

      return this.milestonesStateByType('extra')[0]
    },
    milestoneInfo(): MilestoneInfo {
      if (!this.milestoneProgress || !this.currentMilestone) return null

      return {
        actual: this.milestoneProgress.current,
        goal: this.milestoneProgress.uptick
          ? this.milestoneProgress.uptick
          : this.milestoneProgress.next,
        milestone: this.currentMilestone,
      }
    },
  },
  watch: {
    $route(route: RouteLocationNormalized): void {
      if (route.query.reloadData) {
        this.loadData()
        if (route.query.reloadData) {
          this.$router.replace({
            ...this.$router.currentRoute,
            query: {
              reloadData: undefined,
            },
          })
        }
      }
    },
  },
  created(): void {
    this.loadData()
  },
  methods: {
    ...mapActions(useGamePassStore, ['loadConfig', 'loadState', 'loadEffects']),
    async loadData(): Promise<void> {
      await Promise.all([this.loadEffects(true), this.loadConfig(true), this.loadState(true)])

      this.dataLoaded = true
    },
    getProgress(milestone: number): number {
      if (milestone > this.currentMilestone || !this.milestoneInfo) return 0
      if (milestone < this.currentMilestone) return 100

      const progressGoal = this.milestoneInfo.goal
      const progressActual = this.milestoneInfo.actual
      const lengthPercentage = (progressActual / progressGoal) * 100

      return lengthPercentage > 100 ? 100 : lengthPercentage
    },
    getMilestoneReward(milestone: GamePassContent, type: GamePassType): PassReward {
      const milestoneState = this.milestonesStateByType(type === GamePassType.Basic ? 'free' : type)

      let status =
        milestone.order > this.currentMilestone
          ? GamePassMilestoneStatus.Unoptained
          : GamePassMilestoneStatus.UnoptainedLocked

      if (milestoneState.state[milestone.order] === 'claimed') {
        status = GamePassMilestoneStatus.Claimed
      }

      if (milestoneState.state[milestone.order] === 'claimable') {
        status = milestoneState.locked
          ? GamePassMilestoneStatus.NotClaimedLocked
          : GamePassMilestoneStatus.NotClaimed
      }

      return {
        id: milestone.id,
        rewardType: type,
        rewardStatus: status,
        reward: milestone.reward,
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.game-pass {
  &-left {
    background-color: #26304d;
    height: 49.3rem;
    &.is-mobile {
      height: 100vh;
    }
    &-wrapper {
      height: 45rem;
      .milestone-label {
        width: 16.125rem;
        height: 2.375rem;
        @if $isWsm {
          border: solid 0.125rem #fadd1e;
          background-image: linear-gradient(to top, #f6db24, #fff07e);
        }
        @if $isSsm {
          border: solid 0.125rem #feb942;
          background-image: linear-gradient(to top, #feb942, #ffc868);
        }
      }
    }
  }
  &-right {
    width: calc(100% - 19.125rem);
    padding-right: 1rem;
    height: 48rem;

    .reward-wrapper {
      margin-top: 1rem;

      .reward-col {
        height: 45rem;
        margin-right: 1rem;
        margin-left: 1rem;
      }

      .first-locked {
        .reward-col {
          padding-left: 2rem;
          @if $isWsm {
            border-left: 0.13rem solid #fadd1e;
          }
          @if $isSsm {
            border-left: 0.13rem solid #feb942;
          }
        }
      }
    }
    .milestone-progress-wrapper {
      width: 13.875rem;
      height: 2.375rem;
      padding-top: 0.25rem;
      transform: $skew-value;
      background: #031726;
      flex-shrink: 0;

      .progress-fill {
        display: flex;
        height: 100%;
        @if $isWsm {
          background-image: linear-gradient(to right, #f6db24, #fff07e);
        }
        @if $isSsm {
          background-image: linear-gradient(to right, #feb942, #ffc868);
        }
        &.full {
          @if $isWsm {
            background-image: linear-gradient(to right, #13a00c, #25bf29);
          }
          @if $isSsm {
            background-image: linear-gradient(to right, #00cab1, #0ee7cc);
          }
        }
      }
    }
  }
  .reward-glow {
    left: 0;
    top: 2.2rem;
    pointer-events: none;
    .reward-glow-row {
      height: 11.875rem;
      margin-bottom: 2rem;
    }
    .elite {
      background: linear-gradient(
        to top,
        transparent,
        rgba(204, 93, 249, 0.4),
        rgba(204, 93, 249, 0.4),
        transparent
      );
    }
    .pro {
      background: linear-gradient(
        to top,
        transparent,
        rgba(250, 144, 30, 0.4),
        rgba(250, 144, 30, 0.4),
        transparent
      );
    }
    .basic {
      margin-top: 4.8rem;
      background: linear-gradient(
        to top,
        transparent,
        rgba(141, 163, 225, 0.4),
        rgba(141, 163, 225, 0.4),
        transparent
      );
    }
  }
}
</style>
