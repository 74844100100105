<template>
  <teleport to="#app">
    <div
      class="fullscreen-popup-wrapper tutorial-completed w-full h-full absolute"
      :class="$isMobile() && 'tutorial-completed-mobile'"
    >
      <header
        ref="animation1"
        class="tutorial-completed-header uppercase font-bold text-60 animated-element--faded"
        :class="$isMobile() && 'tutorial-completed-mobile-header'"
      >
        <h2>{{ $t('tutorial.tutorialEnd') }}</h2>
      </header>
      <main class="tutorial-completed-main m-auto relative">
        <section class="tutorial-completed-main-header flexing">
          <span ref="animation2" class="animated-element--faded">
            <p v-if="firstPage" class="text-40 uppercase font-bold text-texts-standard-important">
              {{ $t('common.congratulations') }}
            </p>
            <p v-else class="text-36 text-texts-standard-default font-bold">
              {{ $t('tutorial.tutorialEndNext2') }}
            </p>
          </span>
        </section>
        <div class="yellow-strip" />
        <section v-if="firstPage" class="flexing tutorial-completed-main-reward">
          <p
            ref="animation3A"
            class="text-32 text-texts-standard-default title animated-element--faded"
          >
            {{ $t('tutorial.tutorialEndInfo1') }}
          </p>
          <div ref="animation4Aa" class="blue-strip animated-element--faded" />
          <div
            ref="animation4A"
            class="rewards-wrapper flexing"
            :class="{ 'py-3': isVideoRewardEarned }"
          >
            <reward-box
              v-for="(reward, index) in rewards"
              :key="index"
              class="reward-box-item animated-element--faded"
              :reward-icon="56"
              :reward-icon-name="REWARD_MAPPER[reward.type]"
              :reward-number="
                isVideoRewardEarned ? reward.progress_value * 2 : reward.progress_value
              "
              :is-double-reward-active="isVideoRewardEarned"
            />
            <div
              v-if="!isVideoRewardEarned && isRewardedAdLoaded"
              ref="animation5A"
              class="vertical-ad-rewards animated-element--faded"
            >
              <hr class="vertical-ad-rewards__separator" />
              <div class="vertical-ad-rewards__wrapper">
                <p class="text-32 text-texts-standard-default">
                  {{ $t('tutorial.tutorialEndInfoReward') }}
                </p>
                <app-multi-button
                  btn-type="glowing"
                  btn-size="sm"
                  :btn-text="$t('button.watch')"
                  multi-width="20.25rem"
                  :loading="isMobilePlayingVideo"
                  @click="watchVideo()"
                >
                  <template #rightPart>
                    <app-icon :icon-name="'play-ad-md'" />
                  </template>
                </app-multi-button>
              </div>
            </div>
          </div>
          <div ref="animation4Ab" class="blue-strip animated-element--faded" />
          <p
            ref="animation6A"
            class="text-32 text-texts-standard-name what-next-title animated-element--faded"
            :class="{ 'text-texts-standard-additional': $isSsm }"
          >
            {{ $t('tutorial.tutorialEndNext1') }}
          </p>
          <div ref="animation6Aa" class="gray-strip animated-element--faded" />
          <ul class="text-texts-standard-default text-30 what-next-list list-disc">
            <li ref="animation7A" class="animated-element--faded">
              <p>{{ $t('tutorial.tutorialEndInfo2') }}</p>
            </li>
            <li ref="animation8A" class="animated-element--faded">
              <p>{{ $t('tutorial.tutorialEndInfo3') }}</p>
            </li>
          </ul>
        </section>
        <div v-else class="scroll-wrapper">
          <app-scrollbar class="flex m-auto" height="100%" scroll="x" slide="x" width="100%">
            <div ref="animation3B" class="flex tutorial-completed-main-hints">
              <div
                v-for="(hint, index) in hints"
                :key="index"
                class="hint flex-col animated-element--faded"
              >
                <p class="text-32 uppercase font-bold text-texts-standard-important title">
                  {{ $t(hint.title) }}
                </p>
                <img :src="pathImages + hint.img" />
                <p class="text-28 text-texts-standard-default">
                  {{ $t(hint.subtitle) }}
                </p>
              </div>
            </div>
          </app-scrollbar>
        </div>
      </main>
      <footer v-if="firstPage" class="tutorial-completed-footer m-auto flexing">
        <app-button :btn-type="'secondary'" :btn-text="$t('button.continue')" @click="switchPage" />
      </footer>
      <footer v-else class="tutorial-completed-footer m-auto flexing">
        <app-button
          class="btn-rewards"
          :btn-type="'primary'"
          :btn-text="$t('button.back')"
          @click="switchPage"
        />
        <app-button
          class="btn-continue"
          btn-id="finalButtonContinue"
          :btn-type="'secondary'"
          :btn-text="$t('button.continue')"
        />
      </footer>
      <video-ad-web v-show="isWebVideo" class="tutorial-completed-video-ad" />
    </div>
  </teleport>
</template>

<script lang="ts">
import VideoAdWeb from '@/components/Premium/Ads/VideoAdWeb.vue'
import {
  VIDEO_AD_DURATION,
  claimedAdTypeEndpoint,
  metaPremiumAdsClaimEndpoint,
  pathImages,
} from '@/globalVariables'
import {
  requestWebAd,
  sendToFlutter,
  animateElement,
  resetAnimateElement,
  playSound,
} from '@/helpers'
import { useAdStore } from '@/store/pinia/adStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { Unlock } from '@/interfaces/Tutorial'
import type { PropType } from 'vue'
import type { PremiumVideoAdsClaimApiResponse } from '@/interfaces/responses/premium/PremiumVideoAdsClaimApiResponse'
import { VideoAdState } from '@/enums/VideoAdStates'

interface AdsResponse {
  is_claimed: boolean
}
interface Hint {
  title: string
  img: string
  subtitle: string
}
interface ComponentData {
  pathImages: typeof pathImages
  firstPage: boolean
  REWARD_MAPPER: {
    addGems: string
    addMoney: string
    addUniversalTrainingPoints: string
  }
  hints: Hint[]
  adType: string
  isVideoRewardEarned: boolean
}

export default defineComponent({
  name: 'TutorialCompleted',
  components: {
    VideoAdWeb,
  },
  props: {
    rewards: {
      type: Array as PropType<Unlock[]>,
      default: () => [],
    },
  },
  data(): ComponentData {
    return {
      pathImages,
      firstPage: true,
      REWARD_MAPPER: {
        // pozor pri edite,
        // hodnoty musia korespondovat s tab. games_parameters
        addGems: 'gems',
        addMoney: 'money',
        addUniversalTrainingPoints: 'UNIVERSAL_TRAINING_POINTS',
      },
      hints: [
        {
          title: 'rankingsGroup.club',
          img: 'tutorial/tutorial-end-club.avif',
          subtitle: 'tutorial.tutorialEndClubInfo',
        },
        {
          title: 'common.clubChampionship',
          img: 'tutorial/tutorial-end-club-championships.avif',
          subtitle: 'tutorial.tutorialEndClubChampionshipInfo',
        },
        {
          title: 'career.research',
          img: 'tutorial/tutorial-end-research.avif',
          subtitle: 'tutorial.tutorialEndResearchInfo',
        },
        {
          title: 'tutorial.tutorialEndDailyLeague',
          img: 'tutorial/tutorial-end-dl.avif',
          subtitle: 'tutorial.tutorialEndDailyLeagueInfo',
        },
      ],
      adType: 'tutorial_completed_video_ads_free_pack',
      isVideoRewardEarned: false,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      isMobilePlayingVideo: 'getMobileVideoPlaying',
      isWebVideo: 'getWebVideoPlaying',
      isRewardedAdLoaded: 'getRewardedAdLoaded',
    }),
  },
  watch: {
    firstPage(): void {
      // *must have for animations & layout switch to work
      this.$nextTick((): void => {
        this.restartAnimations()
      })
    },
  },
  created(): void {
    this.getClaimedAdTypeState()
    window.rewardedVideoEarned = this.rewardedVideoEarned
    window.failedToLoadVideoAds = this.rewardedVideoFailed
    window.rewardedVideoEnd = this.rewardedVideoEnd
  },
  mounted(): void {
    playSound('tutorial_finished')
    this.animateScene()
  },
  methods: {
    ...mapActions(useAdStore, ['setAdBlockState']),
    ...mapActions(usePremiumStore, {
      webVideoPlay: 'setWebVideoPlay',
      setVideoPlay: 'setVideoPlay',
      setRewardType: 'setRewardtype',
      setReward: 'setReward',
    }),
    switchPage(): void {
      this.firstPage = !this.firstPage
    },
    watchVideo(): void {
      this.setVideoPlay(true)
      setTimeout(() => {
        this.setVideoPlay(false)
      }, VIDEO_AD_DURATION)
      this.watchAds()

      if (!this.$isMobile()) {
        requestWebAd({
          id: 'video-ad-web-default',
          onRequest: () => {
            this.webVideoPlay(true)
          },
          onSuccess: () => {
            this.webVideoPlay(true)
          },
          onError: () => {
            this.webVideoPlay(false)
          },
          onCallbackComplete: () => {
            this.rewardedVideoEarned()
            this.webVideoPlay(false)
            this.setReward(true)
            this.setRewardType(this.adType)
          },
        })
      }
    },
    async watchAds(): Promise<void> {
      try {
        await this.$axios.put<{}, PremiumVideoAdsClaimApiResponse>(metaPremiumAdsClaimEndpoint, {
          type: this.adType,
          state: VideoAdState.Started,
        })
        if (this.$isMobile()) {
          sendToFlutter('{\r\n "event":"playRewarded"\r\n}')
        }
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async rewardedVideoEarned(): Promise<void> {
      try {
        await this.$axios.put<{}, PremiumVideoAdsClaimApiResponse>(metaPremiumAdsClaimEndpoint, {
          type: this.adType,
          state: VideoAdState.Finished,
        })
        this.isVideoRewardEarned = true
        this.setVideoPlay(false)
      } catch (error: unknown) {
        console.error(error)
      }
    },
    rewardedVideoFailed(): void {
      this.setAdBlockState(true)
      this.setVideoPlay(false)
    },
    rewardedVideoEnd(): void {
      this.setVideoPlay(false)
    },
    getClaimedAdTypeState(): void {
      this.$axios
        .get<{}, AdsResponse>(`${claimedAdTypeEndpoint}/${this.adType}`)
        .then((response) => {
          this.isVideoRewardEarned = response.is_claimed
        })
        .catch((err) => {
          console.error(err)
        })
    },
    animateScene(): void {
      animateElement(this.$refs.animation1, 'fade-in, zoom-reset', '0.67s', 133)
      animateElement(this.$refs.animation2, 'fade-in, zoom-reset', '0.67s', 400)

      if (this.firstPage) {
        animateElement(this.$refs.animation3A, 'fade-in', '0.44s', 400)

        animateElement(this.$refs.animation4Aa, 'fade-in', '0.44s', 533)
        const animatedElements = this.$refs.animation4A.querySelectorAll('.reward-box-item')
        animatedElements.forEach((element: HTMLElement, index: number): void => {
          const delay = 622 + index * 133
          animateElement(element, 'fade-in', '0.44s', delay)
        })

        const conditionallyAnimatedElement = this.$refs.animation5A
        if (conditionallyAnimatedElement) {
          animateElement(conditionallyAnimatedElement, 'fade-in', '0.44s', 1067)
        }
        animateElement(this.$refs.animation4Ab, 'fade-in', '0.44s', 1100)

        animateElement(this.$refs.animation6A, 'fade-in', '0.44s', 900)
        animateElement(this.$refs.animation6Aa, 'fade-in', '0.44s', 900)
        // (i) this type of animation must be started in time before the previous one
        animateElement(
          this.$refs.animation7A,
          'fade-in, slide-in-to-right',
          '0.44s',
          900,
          'cubic-bezier(.77,.01,.51,1)',
        )
        animateElement(
          this.$refs.animation8A,
          'fade-in, slide-in-to-right',
          '0.44s',
          968,
          'cubic-bezier(.77,.01,.51,1)',
        )
      } else {
        const animatedElements = this.$refs.animation3B.querySelectorAll('.hint')
        animatedElements.forEach((element: HTMLElement, index: number): void => {
          const delay = 600 + index * 200
          animateElement(element, 'fade-in', '0.67s', delay)
        })
      }
    },
    resetAnimateScene(): void {
      resetAnimateElement(this.$refs.animation2)
      if (this.firstPage) {
        resetAnimateElement(this.$refs.animation3A)
        const animatedElements = this.$refs.animation4A.querySelectorAll('.reward-box-item')
        animatedElements.forEach((element: HTMLElement): void => {
          resetAnimateElement(element)
        })
        resetAnimateElement(this.$refs.animation4Aa)
        resetAnimateElement(this.$refs.animation4Ab)
        const conditionallyAnimatedElement = this.$refs.animation5A
        if (conditionallyAnimatedElement) {
          resetAnimateElement(conditionallyAnimatedElement)
        }
        resetAnimateElement(this.$refs.animation6A)
        resetAnimateElement(this.$refs.animation6Aa)
        resetAnimateElement(this.$refs.animation7A)
        resetAnimateElement(this.$refs.animation8A)
      } else {
        const animatedElements = this.$refs.animation3B.querySelectorAll('.hint')
        animatedElements.forEach((element: HTMLElement): void => {
          resetAnimateElement(element)
        })
      }
    },
    restartAnimations(): void {
      this.resetAnimateScene()
      // *must have for animations & layout switch to work
      setTimeout((): void => {
        this.animateScene()
      }, 100)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/stars-icons.scss';
@import '@/assets/styles/components/icons/career-icons.scss';
@import '@/assets/styles/animations.scss';

.scroll-wrapper {
  width: 100%;
  padding-left: 4.063rem;
  padding-right: 4.063rem;
  padding-bottom: 0.938rem;
  min-height: 29.25rem;
}

.tutorial-completed {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  z-index: 1;
  overflow: hidden;

  &-header {
    margin-top: 13.625rem;
    margin-bottom: 1.813rem;

    h2 {
      background-clip: text;
      -webkit-text-fill-color: transparent;

      @if $isWsm {
        background-image: linear-gradient(to bottom, #fff196 0%, #fadd1e 100%);
      }

      @if $isSsm {
        background-image: linear-gradient(to top, #fadd1e, #fff196),
          linear-gradient(to bottom, #fbb642, #fbb642);
      }
    }
  }

  &-main {
    width: 100%;
    min-height: 13.125rem;

    @if $isWsm {
      background-image: linear-gradient(0.25turn, #0b1e37, #154e79, #0b1e37);
    }

    @if $isSsm {
      background-image: linear-gradient(
        to right,
        transparent,
        #16203b,
        #605e8f,
        #16203b,
        transparent
      );
    }

    .yellow-strip {
      height: 0.125rem;

      @if $isSsm {
        background-color: theme('colors.texts.standard.important');
      }
    }

    &-header {
      background-image: url($path-tutorial + 'tutorial-end-header.avif');
      background-size: contain;
      height: 5rem;
    }

    &-reward {
      min-height: 29.25rem;
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;

      .what-next-list {
        text-align: left;
        margin-top: 1.563rem;
        margin-bottom: 2.875rem;
      }

      .what-next-title {
        margin-top: 2rem;
        margin-bottom: 0.938rem;
      }

      .rewards-wrapper {
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;

        @if $isSsm {
          background-image: linear-gradient(
            to right,
            transparent,
            rgba(39, 50, 73, 0.85),
            rgba(52, 65, 93, 1),
            rgba(39, 50, 73, 0.85),
            transparent
          );
          border-style: solid;
          border-width: 0.125rem;
          border-image-source: linear-gradient(
            to right,
            transparent,
            #586b9d,
            #586b9d,
            transparent
          );
          border-image-slice: 1;
        }

        .vertical-ad-rewards {
          display: flex;
          flex-direction: row;

          &__separator {
            border: none;
            height: 8.5rem;
            margin: 0.625rem 2.125rem 0.625rem 1.5rem;

            @if $isWsm {
              border-left: 0.125rem solid #fadd1e;
            }

            @if $isSsm {
              border-left: 0.125rem solid theme('colors.texts.standard.important');
            }
          }

          &__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }

      .blue-strip {
        height: 0.125rem;
        width: 56.25rem;

        @if $isWsm {
          background-image: linear-gradient(to right, transparent, #a0dcff, #a0dcff, transparent);
        }
      }

      .gray-strip {
        height: 0.125rem;
        width: 32.375rem;
      }

      .title {
        margin-top: 1.563rem;
        margin-bottom: 1.25rem;
      }
    }

    &-hints {
      width: 100%;
      margin-bottom: 2.938rem;
      margin-top: 1.813rem;
      align-items: flex-start;
      justify-content: center;

      .hint {
        margin-left: 1.094rem;
        margin-right: 1.094rem;
        width: 30rem;

        .title {
          margin-bottom: 1.25rem;
        }
      }

      img {
        width: 30rem;
        height: 16.875rem;
        background-size: cover;
      }
    }
  }

  &-footer {
    width: 100%;
    margin-top: 4.25rem;

    .btn-continue {
      margin-left: 1.125rem;
    }
  }

  .tutorial-completed-video-ad {
    &.video-ad-web {
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

.tutorial-completed-mobile {
  width: 100vw;
  height: 100vh;

  &-header {
    margin-top: 9rem;
  }
}
</style>
