export enum PlayoffMainComponent {
  Qualification = 'PlayoffQualificationMain',
  Playoff = 'PlayoffMain',
  Tournaments = 'PlayoffMastersTournamentListingMain',
  Ladder = 'PlayoffMastersTournamentLadderMain',
}

export enum PlayoffState {
  Qualification = 'qualification',
  Draw = 'draw',
}

export enum PlayoffType {
  Individual = 'individual',
  Masters = 'masters',
}
