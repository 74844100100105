<template>
  <div id="profile-overview" class="flex flex-col profile-overview app-page-wrapper absolute">
    <menu-component
      menu-type="profile"
      :title="$t('profile.profile')"
      :is-icon="'<b>' + $t('mobileApp.player_id') + '</b>: ' + userId"
    />
    <section
      class="profile-overview__container flex flex-row w-full h-full safe-area"
      :class="{ 'profile-overview__container--mobile': $isMobile() }"
    >
      <profile-left-box
        :badge="profileBadge"
        :user-id="userId"
        :class="{ 'profile-left-box__wrapper--mobile': $isMobile() }"
      />
      <aside v-if="isContentLoaded">
        <div class="profile-discipline-menu">
          <modal-head
            :show-info="false"
            :is-full-width="true"
            :is-gradient-overflowing="!$isMobile()"
          >
            <discipline-menu
              id-discipline="training-tutorial-menu"
              :selected-default="activeDisciplineId"
              :custom-discipline-data="disciplines"
              :is-another-player-menu="itsNotMe"
              :is-slim="true"
            />
            <template #popupContent>
              <training-popup />
            </template>
          </modal-head>
        </div>
        <arrows-component :array-of-ids="unlockedDisciplinesIds" class="profile-arrows" />
        <div class="profile-overview-wrapper">
          <div class="profile-overview-wrapper-left">
            <div class="head-block">
              <h2 class="text-texts-standard-important text-40 font-bold uppercase">
                {{ $translateDiscipline(activeDisciplineId) }}
              </h2>
              <span class="flex items-center text-texts-standard-default">
                <p class="text-30 text-texts-standard-additional">
                  {{ $t('common.personalBest') }}:
                </p>
                <p class="text-34 font-bold ml-2.5">
                  {{ disciplineMaxScore }}
                </p>
              </span>
            </div>
            <div class="content-block">
              <shrink-text
                :text="$t('common.totalPower')"
                :font-size="32"
                text-class-list="text-texts-standard-default"
              />
              <div class="content-block-box">
                <div class="content-block-box__icon icon-discipline__wrapper icon-wrapper">
                  <div :class="'icon-discipline-' + activeDisciplineId + '-light-70'" />
                </div>
                <p class="text-wrapper text-texts-standard-important text-50 font-bold">
                  {{ activeDisciplineStats?.totalStrength }}
                </p>
                <tippy theme="WSM2" placement="right" max-width="35.75rem">
                  <app-icon icon-name="info-50" />
                  <template #content>
                    <profile-tooltip
                      :rows="attributesTooltip"
                      :disc-id="activeDisciplineId"
                      :total-value="activeDisciplineStats.totalStrength"
                      :with-plus="true"
                      :title="$t('common.totalPower')"
                    />
                  </template>
                </tippy>
              </div>
            </div>
            <div class="content-block">
              <shrink-text
                :text="$t('profile.rankingPointsDiscipline')"
                :font-size="32"
                text-class-list="text-texts-standard-default"
              />
              <div class="content-block-box">
                <app-main-icon
                  :icon-size="56"
                  icon-name="ranking_points"
                  class="content-block-box__icon"
                />
                <p class="text-wrapper text-texts-standard-default text-50 font-bold">
                  {{ rankingPoints }}
                </p>
              </div>
              <div class="content-block-detail">
                <span v-for="(ranking, index) in rankings" :key="index" class="flex">
                  <p class="text-texts-standard-additional text-30">
                    {{ ranking.text }}
                  </p>
                  <p class="text-texts-standard-important text-32 font-bold ml-4">
                    {{ ranking.value }}
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div class="profile-overview-wrapper-middle">
            <div
              class="profile-character"
              :style="{
                background:
                  'url(' +
                  pathImages +
                  'profile/character/character-' +
                  sex +
                  '-' +
                  activeDisciplineId +
                  '.avif)center no-repeat',
                backgroundSize: 'contain',
              }"
            />
          </div>
          <div class="profile-overview-wrapper-right justify-end">
            <app-button
              v-if="overalls"
              btn-type="secondary"
              :btn-text="$t('profile.changeOverall')"
              btn-size="md"
            />
            <div v-if="overalls" class="head-block">
              <p class="text-texts-standard-default text-30">{{ $t('profile.choosenOverall') }}:</p>
              <p class="text-texts-standard-important text-34 uppercase">
                <!-- TODO spávane nazvy vybavy -->
              </p>
            </div>
            <div v-if="currentEquipment && equipmentItem" class="content-block">
              <tippy theme="WSM1" placement="left" max-width="24.75rem" class="info-styles">
                <app-icon icon-name="info-50" />
                <template #content>
                  <profile-tooltip
                    :rows="equipmentTooltip"
                    :title="$t('profile.equipmentFeatures')"
                  />
                </template>
              </tippy>
              <div class="my-4">
                <p class="text-texts-standard-default text-30">{{ $t('common.equipment') }}:</p>
                <p class="text-texts-standard-important text-34 uppercase">
                  {{ $t(`equipmentShop.${currentEquipment.name}`) }}
                </p>
              </div>
              <app-stars-icon :stars-data="stars" :stars-size="48" />
            </div>
          </div>
        </div>
        <div class="profile-footer flexing">
          <app-special-buttons
            v-for="(btn, num) in btns"
            :key="num"
            :btn-data="btn"
            :user-id="userId"
          />
        </div>
      </aside>

      <component-loading :width="'75%'" :height="'75%'" :is-loading="!isContentLoaded" />
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { formatResult, convertNameValue } from '@/helpers'
import ArrowsComponent from '@/components/ArrowsComponent.vue'
import DisciplineMenu from '@/components/DisciplineMenu.vue'
import AppSpecialButtons from '@/components/GlobalComponents/AppSpecialButtons.vue'
import AppStarsIcon from '@/components/GlobalComponents/AppStarsIcon.vue'
import ModalHead from '@/components/ModalHead.vue'
import ProfileLeftBox from '@/components/Profile/ProfileLeftBox.vue'
import ProfileTooltip from '@/components/Profile/ProfileTooltip.vue'
import TrainingPopup from '@/components/Training/TrainingPopup.vue'
import {
  BEST_SCORE,
  EQUIPMENT_ATTRIBUTE_BONUS,
  EQUIPMENT_CASH_BONUS,
  EQUIPMENT_EQUIPPED,
  EQUIPMENT_TP_BONUS,
  MECHANIC_RESEARCH,
  RANKING_POINTS,
  arenaStatsDisciplineEndpoint,
  arenaUserBuildingEndpoint,
  pathImages,
  shopItemEndPoint,
  shopItemsEquipmentDisciplineEndPoint,
  userDisciplineStatsEndpoint,
  userProfileUserEndpoint,
} from '@/globalVariables'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useRankingStore } from '@/store/pinia/rankingStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTrainingDisciplineStore } from '@/store/pinia/trainingDisciplinesStore'
import { useUserStore } from '@/store/pinia/userStore'
import ShrinkText from '@/components/ShrinkText.vue'
import type Discipline from '@/interfaces/global/Discipline'
import type { RouteLocationNormalized } from 'vue-router'
import type { ArenaBuilding } from '@/components/Arena/ArenaSharedInterface'
import type { UserProfile } from '@/types/userProfile'
import type EquipmentItemApiResponse from '@/interfaces/responses/equipment/EquipmentItemApiResponse'

interface Star {
  id: number
}

interface Tooltip {
  text: string
  value: number
  highlight?: boolean
}

interface ProfileBadge {
  level: number
  rankName: string
  image: string
}

interface ProfileRank {
  level: number
  rankName: string
  image: string
}

interface Button {
  id: string
  type: string
  text: string
  status: string
  route?: string
  params?: {
    user: string
  }
}

interface DisciplineMaxScoreResponse {
  user_games_id: string
  stats: {
    name: string
    value: string | number
  }[]
}

interface DisciplineStat {
  name: string
  value: number
}

interface ComponentData {
  userId: string
  overalls: boolean
  activeDisciplineId: number | null
  currentEquipment: EquipmentItemApiResponse[0]
  equipmentItem: EquipmentItemApiResponse[0]
  disciplineMaxScore: string
  rankingPoints: number
  arenaBuilding: ArenaBuilding
  maxLevelForBadge: number
  pathImages: string
  otherUserData: UserProfile
  profileRanks: ProfileRank[]
}

export default defineComponent({
  name: 'ProfileOverview',
  components: {
    ProfileLeftBox,
    TrainingPopup,
    DisciplineMenu,
    ModalHead,
    ProfileTooltip,
    AppStarsIcon,
    ArrowsComponent,
    AppSpecialButtons,
    ShrinkText,
  },

  data(): ComponentData {
    return {
      userId: '',
      overalls: false,
      activeDisciplineId: null,
      currentEquipment: null,
      equipmentItem: null,
      disciplineMaxScore: '-',
      rankingPoints: 0,
      arenaBuilding: {} as ArenaBuilding,
      maxLevelForBadge: 19,
      pathImages,
      otherUserData: {} as UserProfile,
      profileRanks: [
        {
          level: 1,
          rankName: 'novice',
          image: 'novice',
        },
        {
          level: 3,
          rankName: 'beginner',
          image: 'beginner',
        },
        {
          level: 5,
          rankName: 'amateur',
          image: 'amateur',
        },
        {
          level: 7,
          rankName: 'professional',
          image: 'professional',
        },
        {
          level: 9,
          rankName: 'expert',
          image: 'expert',
        },
        {
          level: 11,
          rankName: 'champion',
          image: 'champion',
        },
        {
          level: 13,
          rankName: 'elite',
          image: 'elite',
        },
        {
          level: 15,
          rankName: 'star',
          image: 'star',
        },
        {
          level: 17,
          rankName: 'superStar',
          image: 'super-star',
        },
        {
          level: 19,
          rankName: 'legend',
          image: 'legend',
        },
      ],
    }
  },
  computed: {
    ...mapState(useUserStore, {
      myUserData: 'getUserData',
      myCountry: 'getCountry',
      mySex: 'getSex',
    }),
    ...mapState(useRankingStore, {
      myGlobalRankPosition: 'getGlobalDisciplinePosition',
      myNationalRankPosition: 'getNationalDisciplinePosition',
      globalRankings: 'getGlobalDisciplineRankings',
      nationalRankings: 'getNationalDisciplineRankings',
    }),
    ...mapState(useDisciplineStore, {
      myUnlockedDisciplinesIds: 'getUnlockedDisciplinesIdsUnsorted',
    }),
    ...mapState(useTrainingDisciplineStore, {
      disciplinesLoaded: 'getDisciplinesLoaded',
      myDisciplines: 'getDisciplines',
      playerDisciplines: 'getPlayerDisciplines',
      playerUnlockedDisciplinesIds: 'getPlayerUnlockedDisciplines',
    }),
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
    }),
    itsNotMe(): boolean {
      return this.myUserData?.id !== this.userId
    },
    disciplines(): Discipline[] {
      return this.itsNotMe ? this.playerDisciplines : this.myDisciplines
    },
    country(): string {
      return this.itsNotMe
        ? this.otherUserData?.user_data?.country?.three_letter_code
        : this.myCountry
    },
    sex(): string {
      return this.itsNotMe ? this.otherUserData?.attributes?.sex : this.mySex
    },
    globalRankPosition(): number | string {
      if (!this.itsNotMe) {
        return this.myGlobalRankPosition
      } else {
        return this.globalRankings?.ranking && this.globalRankings.ranking[this.userId]
          ? this.globalRankings.ranking[this.userId].rank
          : ''
      }
    },
    nationalRankPosition(): number | string {
      if (!this.itsNotMe) {
        return this.myNationalRankPosition
      } else {
        return this.nationalRankings?.ranking && this.nationalRankings.ranking[this.userId]
          ? this.nationalRankings.ranking[this.userId].rank
          : ''
      }
    },
    isContentLoaded(): boolean {
      return (
        (this.disciplinesLoaded || this.playerDisciplines.length > 0) &&
        this.activeDisciplineStats !== undefined
      )
    },
    btns(): Button[] {
      return [
        {
          id: 'profile-trophy-btn', // id elementu (pre testerov hlavne)
          type: 'trophy', // typ buttonu
          text: this.$t('profile.trophies'), // text pre dany button
          status: 'soon', // stav ci dana mechaniak je uz k dispozicii
          // count: '(7)' // pocet trofeji/vyzkumanych skills/ocenení ktore mam
        },
        {
          id: 'profile-lab-btn',
          type: 'lab',
          text: this.$t('common.research'),
          status:
            !this.itsNotMe && this.hasMechanic(MECHANIC_RESEARCH)
              ? 'open'
              : this.itsNotMe
                ? 'soon-lock'
                : 'lock',
          route: 'LabDisciplines',
          params: this.itsNotMe ? { user: this.userId } : null,
          //  count: '(9)'
        },
        {
          id: 'profile-achievment-btn',
          type: 'achievment',
          text: this.$t('profile.achievment'),
          status: 'soon',
          //  count: '(12)'
        },
      ]
    },
    stars(): Star[] {
      const currentLevel = this.currentEquipment.item_stats.find(
        (stat: EquipmentItemApiResponse[0]['item_stats'][0]): boolean => stat.name === 'level',
      )
      return this.equipmentItem.levels.map(
        (itemLevel: EquipmentItemApiResponse[0]['levels'][0]): Star => {
          let id = 0
          if (itemLevel.level <= parseInt(currentLevel.value as string)) {
            id = 2
          } else if (itemLevel.level <= this.equipmentItem.equipment_levels_available) {
            id = 1
          }
          return { id }
        },
      )
    },
    activeDisciplineStats(): Discipline {
      return this.disciplines.find(
        (discipline: Discipline): boolean => discipline.id === this.activeDisciplineId,
      )
    },
    attributesTooltip(): Tooltip[] {
      const disciplineAttributes = this.activeDisciplineStats.attributes
      return [
        {
          text: this.$t('common.basicAttribute'),
          value: disciplineAttributes.BASE,
        },
        {
          text: this.$t('common.equipment'),
          value:
            disciplineAttributes.EQUIPMENT +
            disciplineAttributes.EQUIPMENT_PREMIUM_UPGRADE +
            disciplineAttributes.EQUIPMENT_UPGRADE,
        },
        {
          text: this.$t('common.research'),
          value:
            disciplineAttributes.RESEARCH_BASE +
            disciplineAttributes.RESEARCH_PREMIUM +
            disciplineAttributes.RESEARCH_REPEATABLE,
        },
        {
          text: this.$t('common.seasonalAttribute'),
          value: disciplineAttributes.SEASONAL,
          highlight: true,
        },
      ]
    },
    equipmentTooltip(): Tooltip[] {
      const attributeBonus = this.currentEquipment.level_parameters.find(
        (param: EquipmentItemApiResponse[0]['level_parameters'][0]): boolean =>
          param.name === EQUIPMENT_ATTRIBUTE_BONUS,
      )
      const cashBonus = this.currentEquipment.level_parameters.find(
        (param: EquipmentItemApiResponse[0]['level_parameters'][0]): boolean =>
          param.name === EQUIPMENT_CASH_BONUS,
      )
      const tpBonus = this.currentEquipment.level_parameters.find(
        (param: EquipmentItemApiResponse[0]['level_parameters'][0]): boolean =>
          param.name === EQUIPMENT_TP_BONUS,
      )
      const durabilty = this.currentEquipment.item_stats.find(
        (stat: EquipmentItemApiResponse[0]['item_stats'][0]): boolean => stat.name === 'durability',
      )
      return [
        {
          text: this.$t('equipmentShop.attributeBonus'),
          value: attributeBonus?.value ? attributeBonus.value : 0,
        },
        {
          text: this.$t('equipmentShop.moneyBonus'),
          value: cashBonus?.value ? `${cashBonus.value}%` : 0,
        },
        {
          text: this.$t('equipmentShop.trainingBonus'),
          value: tpBonus?.value ? `${tpBonus.value}%` : 0,
        },
        {
          text: this.$t('equipmentShop.durability'),
          value: durabilty?.value ? durabilty.value : 0,
        },
      ]
    },
    rankings(): Tooltip[] {
      return [
        {
          text: this.$t('common.worldRanking'),
          value: this.globalRankPosition,
        },
        {
          text: this.$t('rankingsGroup.nationalRanking'),
          value: this.nationalRankPosition,
        },
      ]
    },
    profileBadge(): ProfileBadge {
      const level =
        this.arenaBuilding.level > this.maxLevelForBadge
          ? this.maxLevelForBadge
          : this.arenaBuilding.level
      const badge = this.profileRanks.find(
        (element: ProfileRank): boolean => element.level >= level,
      )
      return badge
    },
    unlockedDisciplinesIds(): number[] {
      return this.itsNotMe ? this.playerUnlockedDisciplinesIds : this.myUnlockedDisciplinesIds
    },
  },
  watch: {
    $route(route: RouteLocationNormalized): void {
      if (route.name === this.$getWebView(this.$options.name)) {
        this.activeDisciplineId = this.getDisciplineId()

        if (this.activeDisciplineId) {
          this.initMethods()
        }
      }
    },
  },

  async created(): Promise<void> {
    this.loadInitialDisciplinesData()
    this.userId = this.myUserData?.id
    if (this.$route.params.user) {
      this.userId = this.$route.params.user as string
      await this.loadUserData()
    }
    await this.loadDisciplines(this.itsNotMe ? this.userId : '')
    this.activeDisciplineId = this.getDisciplineId()
    if (this.activeDisciplineId) {
      this.initMethods()
    }
  },

  methods: {
    ...mapActions(useRankingStore, {
      loadGlobalRankings: 'loadGlobalDisciplineRankings',
      loadNationalRankings: 'loadNationalDisciplineRankings',
    }),
    ...mapActions(useTrainingDisciplineStore, ['loadInitialDisciplinesData', 'loadDisciplines']),
    initMethods(): void {
      this.getDisciplineMaxScore()
      this.getRankingPoints()
      this.loadGlobalRankings(this.activeDisciplineId)
      this.loadNationalRankings({
        country: this.country,
        disciplineId: this.activeDisciplineId,
      })
      this.loadEquipmentByDiscipline()
      this.getCurrentArenaStats()
    },
    async loadUserData(): Promise<void> {
      const response = await this.$axios.post<{}, UserProfile>(userProfileUserEndpoint, {
        user_games_ids: [this.userId],
      })
      const user = { ...response[0] }
      if (!user) return
      user.attributes = convertNameValue(user.attributes)
      this.otherUserData = user
    },
    getDisciplineId(): number {
      if (!this.$route.params.id) {
        this.$router.push({ params: { id: this.unlockedDisciplinesIds[0] } })
      }

      const urlId = parseInt(this.$route.params.id as string)

      if (this.unlockedDisciplinesIds.includes(urlId)) {
        return urlId
      } else {
        this.$router.push({ params: { id: this.unlockedDisciplinesIds[0] } })
      }
    },
    async getDisciplineMaxScore(): Promise<void> {
      const params = this.itsNotMe ? { users: [this.userId] } : ''
      const disciplineMaxScoreResponse = await this.$axios.post<{}, DisciplineMaxScoreResponse>(
        arenaStatsDisciplineEndpoint + this.activeDisciplineId,
        params,
      )
      if (!disciplineMaxScoreResponse || !Object.keys(disciplineMaxScoreResponse).length) {
        this.disciplineMaxScore = '0'
        return
      }
      const bestScoreResponse = disciplineMaxScoreResponse[0].stats.find(
        (stat: EquipmentItemApiResponse[0]['item_stats'][0]): boolean => stat.name === BEST_SCORE,
      )
      this.disciplineMaxScore = bestScoreResponse?.value
        ? formatResult(bestScoreResponse.value, this.activeDisciplineId)
        : '-'
    },
    async getRankingPoints(): Promise<void> {
      const params = this.itsNotMe ? `?user_games_id=${this.userId}` : ''
      const disciplineStats = await this.$axios.get<{}, DisciplineStat[]>(
        `${userDisciplineStatsEndpoint}${this.activeDisciplineId}${params}`,
      )
      const rankingFromResponse = disciplineStats.find(
        (stat: DisciplineStat): boolean => stat.name === RANKING_POINTS,
      )
      if (rankingFromResponse) {
        this.rankingPoints = rankingFromResponse.value
      }
    },
    async loadEquipmentByDiscipline(): Promise<void> {
      const params = this.itsNotMe ? `?user_games_id=${this.userId}` : ''
      const data = await this.$axios.get<{}, EquipmentItemApiResponse>(
        `${shopItemsEquipmentDisciplineEndPoint}/${this.activeDisciplineId}/detail${params}`,
      )
      this.currentEquipment = data.find(
        (equipment: EquipmentItemApiResponse[0]): boolean =>
          equipment.item_state.name === EQUIPMENT_EQUIPPED,
      )
      if (this.currentEquipment) {
        this.equipmentItem = await this.$axios.get<{}, EquipmentItemApiResponse[0]>(
          `${shopItemEndPoint}${this.currentEquipment.id}`,
        )
      }
    },
    async getCurrentArenaStats(): Promise<void> {
      this.arenaBuilding = await this.$axios.get<{}, ArenaBuilding>(
        `${arenaUserBuildingEndpoint}?user_games_id=${this.userId}`,
      )
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';

.profile-overview__container {
  justify-content: space-between;
  height: 100%;
  padding-top: 1.688rem;
  padding-left: 1.938rem;

  &--mobile {
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding-top: 1.688rem;
    padding-left: var(--safe-area-inset-left);

    aside {
      width: 100%;
    }

    .wrapper {
      margin-left: 1.875rem;
    }

    .profile-overview-wrapper,
    .profile-footer {
      width: 85%;
      margin: 0 auto;
    }

    .profile-overview-wrapper {
      margin-top: 1rem;
    }

    .profile-footer {
      margin-top: auto;
    }
  }
}

aside {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
  height: 52.375rem;

  .profile-discipline-menu {
    .modal-head {
      width: 100%;
      margin: 0 0 1.188rem;
    }
  }

  .profile-arrows {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 85%;
    transform: translate(-50%);
    z-index: 2;
  }

  .profile-overview-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 1rem 5.688rem 2.5rem;

    &-left {
      width: 35%;
      display: flex;
      flex-direction: column;
      text-align: left;
      position: relative;
      z-index: 1;

      .head-block {
        text-align: left;
      }

      .content-block {
        position: relative;
        margin-top: 3rem;

        &:after {
          content: '';
          width: 87%;
          height: 0.125rem;
          position: absolute;
          top: -1.5rem;
          left: -2rem;
          @if $isWsm {
            background: linear-gradient(to right, transparent, rgba(12, 31, 55, 0.5), transparent);
          }
          @if $isSsm {
            background: linear-gradient(
              to right,
              transparent,
              rgba(255, 255, 255, 0.5),
              transparent
            );
          }
        }

        &-box {
          height: 4.75rem;
          width: 26.688rem;
          position: relative;
          display: flex;
          align-items: center;
          padding-left: 1.375rem;
          margin-top: 1.063rem;
          @if $isWsm {
            background: linear-gradient(
              to right,
              rgba(13, 32, 55, 0.5),
              rgba(21, 78, 121, 0.5),
              rgba(55, 127, 184, 0)
            );
            border-left: 0.125rem solid rgba(255, 255, 255, 0.5);
          }
          @if $isSsm {
            background: linear-gradient(
              to right,
              rgba(#273249, 0.5),
              rgba(#34415d, 0.5),
              rgba(55, 127, 184, 0)
            );
            border-left: 0.125rem solid #586b9d;
          }

          &:after,
          &:before {
            content: '';
            width: 100%;
            height: 0.125rem;
            position: absolute;
            left: 0rem;
            @if $isWsm {
              background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0.5),
                rgba(255, 255, 255, 0.5) 0%,
                rgba(251, 227, 110, 0) 90%
              );
            }
            @if $isSsm {
              background: linear-gradient(to right, rgba(#586b9d, 0.5) 0%, transparent 100%);
            }
          }

          &:after {
            top: 0;
          }

          &:before {
            bottom: 0;
          }

          .icon-wrapper {
            width: 4.15rem;
            height: 4.15rem;
          }

          .text-wrapper {
            margin-left: 1.313rem;
            width: 12.063rem;
          }

          &__icon {
            margin: 0;

            &.icon-discipline__wrapper {
              position: relative;
              margin-left: -0.375rem;
            }
          }
        }

        &-detail {
          margin-left: 1.375rem;
          padding-left: 0.688rem;
          padding-top: 1rem;
          @if $isWsm {
            border-left: 0.125rem solid rgb(255 255 255 / 50%);
          }
          @if $isSsm {
            border-left: 0.125rem solid #586b9d;
          }
        }
      }
    }

    &-middle {
      position: absolute;
      width: 51.5rem;
      height: 40.563rem;
      top: -0.5rem;
      left: 50%;
      transform: translateX(-50%);

      .profile-character {
        width: 51.5rem;
        height: 40.563rem;
        position: absolute;
        background: url($path-profile + 'CHARACTER.avif') center no-repeat;
        background-size: cover;

        &:before {
          content: '';
          position: absolute;
          top: 40%;
          left: 50%;
          width: 45%;
          height: 60%;
          transform: translate(-50%, -50%);
          background: #2794c1;
          border-radius: 25rem;
          filter: blur(5rem);
          z-index: -1;
        }
      }
    }

    &-right {
      width: 35%;
      display: flex;
      flex-direction: column;
      text-align: right;
      align-items: flex-end;
      position: relative;
      z-index: 1;

      .head-block {
        margin-top: 1.625rem;
      }

      .content-block {
        margin-top: 14.125rem;

        .info-styles {
          display: flex;
          margin-left: auto;
          width: 3.125rem;
        }

        &-starts {
          width: 27.6875rem;
          height: 5.0625rem;
        }
      }
    }
  }

  .profile-footer {
    height: 6rem;
    margin: 0 5.688rem 0 4.688rem;
    justify-content: space-between;
  }
}
</style>
