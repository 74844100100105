<template>
  <div class="typewriter">
    <p v-if="hasIcon">
      {{ textBefore }}
      <app-main-icon :icon-size="32" :icon-name="iconReplace" />
      {{ textAfter }}
    </p>
    <p v-else class="typewriter-text">{{ text }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface ComponentData {
  text: string
  arrayIndex: number
  charIndex: number
  hasIcon: boolean
  textBefore: string
  textAfter: string
}

export default defineComponent({
  name: 'TypewriterEffect',
  props: {
    array: {
      type: Array as PropType<string[]>,
      required: true,
    },
    typeSpeed: {
      type: Number,
      default: 70,
    },
    startDelay: {
      type: Number,
      default: 0,
    },
    iconReplace: {
      type: String,
      default: '',
    },
  },
  emits: ['showFullText'],
  data(): ComponentData {
    return {
      text: '',
      arrayIndex: 0,
      charIndex: 0,
      hasIcon: false,
      textBefore: '',
      textAfter: '',
    }
  },
  created(): void {
    setTimeout(this.typewriter, this.startDelay)
  },
  methods: {
    typewriter(): void {
      if (this.charIndex < this.array[this.arrayIndex].length) {
        this.text += this.array[this.arrayIndex].charAt(this.charIndex)
        this.charIndex += 1

        if (this.typeSpeed === 0) {
          this.text = this.array[this.arrayIndex]
          this.setIconPlaceholder()
        } else setTimeout(this.typewriter, this.typeSpeed)
      } else {
        this.setIconPlaceholder()
        this.$emit('showFullText')
      }
    },
    setIconPlaceholder(): void {
      if (this.text.includes('{icon}')) {
        const textValue = this.text
        const separator = '{icon}'
        const index = textValue.indexOf(separator)
        const separatorLength = separator.length
        const splittedText = [textValue.slice(0, index), textValue.slice(index + separatorLength)]

        this.hasIcon = true
        this.textBefore = splittedText[0]
        this.textAfter = splittedText[1]
      }
    },
  },
})
</script>
