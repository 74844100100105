<template>
  <div class="benefits-building relative w-full flex justify-between items-center">
    <div class="benefits-building-details relative flex justify-start items-center">
      <div class="benefits-building-details-image relative h-full flexing flex-col">
        <img :src="setImageSource" />
      </div>
      <div
        class="benefits-building-details-name text-texts-standard-important text-50 uppercase italic font-bold text-left"
      >
        {{ name }}
      </div>
    </div>
    <div class="benefits-building-slots h-full relative flex justify-end items-center">
      <div class="benefits-building-slots-content relative flex justify-end items-center">
        <slot name="slot-1" />
        <slot name="slot-2" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BenefitsBuilding',
  props: {
    name: {
      type: String,
      default: '',
    },
    imgSrc: {
      type: String,
      default: 'arena/building/BUILDING_ARENA_SMALL_LVL5.avif',
    },
  },
  computed: {
    setImageSource(): string {
      return `${pathImages}${this.imgSrc}`
    },
  },
})
</script>

<style lang="scss" scoped>
.benefits-building {
  height: 10.5rem;
  border: solid 0.063rem theme('borderColor.table-odd');
  margin: 1.875rem 0;
  z-index: 1;
  @if $isWsm {
    background: rgba(65, 116, 145, 0.8);
  }
  @if $isSsm {
    background: #273b6a;
  }

  &-details,
  &-slots {
    width: 40%;
    padding: 0.938rem 0;
  }

  &-details {
    padding-left: 2.5rem;

    &-image {
      min-width: 15.625rem;

      & img {
        height: 8.125rem;
      }

      &-placeholder {
        width: 100%;
        height: 8.125rem;
        background: rgba(255, 255, 255, 0.3);
        border: 0.063rem solid theme('borderColor.table-odd');
      }
    }

    &-name {
      margin-left: 3.625rem;
    }
  }

  &-slots {
    width: 60%;
    padding-right: 2.5rem;
    border-bottom: solid 0.063rem theme('borderColor.table-odd');

    &::before {
      content: '';
      height: 0;
      width: 93.5%;
      position: absolute;
      top: 0;
      right: 0;
      border-bottom-width: 10.3rem;
      border-bottom-style: solid;
      border-left: 1.75rem solid transparent;
      z-index: 0;
      @if $isWsm {
        border-bottom-color: rgba(21, 76, 118, 0.6);
      }
      @if $isSsm {
        border-bottom-color: rgba(#232b46, 0.5);
      }
    }

    &::after {
      content: '';
      height: 10.3rem;
      width: 6%;
      position: absolute;
      top: 0;
      left: 0.5rem;
      transform: skewX(-9deg);
      @if $isWsm {
        background: rgba(21, 76, 118, 0.6);
      }
      @if $isSsm {
        background: rgba(#232b46, 0.5);
      }
    }

    &-content {
      width: 90%;
    }
  }
}
</style>
