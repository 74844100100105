<template>
  <a
    v-if="showAnnouncement"
    :href="announcement.urlToRoute"
    target="_blank"
    class="r-box relative cursor-pointer clickable-element text-24 m-auto block"
    :style="setBackgroundImage()"
  ></a>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { AnnouncementsResponse } from '@/interfaces/premium/Announcements'

export default defineComponent({
  props: {
    announcement: {
      type: Object as PropType<Nullable<AnnouncementsResponse>>,
      default: () => null,
    },
  },
  computed: {
    showAnnouncement(): boolean {
      if (!this.announcement.showPercentage) return false

      return Math.random() < this.announcement.showPercentage
    },
  },
  methods: {
    setBackgroundImage(): string {
      return `background-image: url(${pathImages}components/announcements/${this.announcement.imgToShow}.avif)`
    },
  },
})
</script>

<style lang="scss" scoped>
.r-box {
  width: 15rem;
  height: 11.5625rem;
  pointer-events: all;
  @include background(null);
}
</style>
