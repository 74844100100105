<template>
  <div class="table-tools flex items-center justify-between">
    <div v-if="showTools.search" class="table-tools-search-box flex items-center text-32">
      <app-input
        id="searchInput"
        class="table-tools-search-box-input"
        :value="searchTerm"
        name="clubsSearch"
        :placeholder="$t('club.searchByName')"
        no-tooltip
        @input="(value: string) => (searchTerm = value)"
      />
      <div
        class="table-tools-search-box-icon cursor-pointer flexing"
        @click="$emit('searchEvent', searchTerm)"
      >
        <app-icon icon-name="search" />
      </div>
    </div>
    <div v-if="showTools.info" class="table-tools-info-text">
      <info-text :text="$t('club.invitationsInfoText')" />
    </div>
    <div v-if="showTools.checkbox" class="flexing">
      <app-check-box class="mr-10" :is-checked="checkboxChecked" @checked-action="setCheck">
        <p class="pl-5 pr-5 cursor-pointer text-texts-standard-important text-32 uppercase">
          {{ $t('common.notifications') }}
        </p>
      </app-check-box>
      <app-button
        btn-size="md"
        btn-type="danger"
        :btn-text="$t('club.rejectAll')"
        class="cell-button"
        :disabled="showTools.btnDisabled"
        @click="showDeletePopup = true"
      />
    </div>
    <app-button
      v-if="showTools.button"
      :disabled="showTools.btnDisabled"
      btn-size="md"
      btn-type="danger"
      :btn-text="$t('club.rejectAll')"
      class="cell-button"
      @click="showDeletePopup = true"
    />
    <app-button
      v-if="showTools.filter"
      btn-size="md"
      btn-type="primary"
      :btn-text="$t('club.editFilter')"
      class="cell-button"
      @click="showPopup = true"
    />
    <div v-if="showTools.about" class="table-tools-about-clubs flexing">
      <p class="text-texts-standard-default text-32 mx-8">
        {{ $t('club.aboutClubs') }}
      </p>
      <app-icon icon-name="question" @click="showAboutClubs = true" />
    </div>
  </div>

  <!-- MODAL FOR FILTER -->
  <popup-window
    v-if="showPopup"
    popup-type="info"
    :popup-title="$t('club.editFilter')"
    @close="showPopup = false"
  >
    <clubs-filter-popup
      :filter-data="filterData"
      @apply="$emit('applyFilter', $event)"
      @close="showPopup = false"
      @reset="$emit('resetFilter', $event)"
    />
  </popup-window>

  <popup-window
    v-if="showAboutClubs"
    popup-type="info"
    :popup-title="$t('club.aboutClubs')"
    @close="showAboutClubs = false"
  >
    <clubs-about-popup />
  </popup-window>
  <popup-window
    v-if="showDeletePopup"
    popup-type="submit"
    :popup-data="getDeleteAllData"
    @close="showDeletePopup = false"
    @action="deleteAllApplications"
  />
</template>

<script lang="ts">
import ClubsAboutPopup from '@/components/Club/ClubsAboutPopup.vue'
import ClubsFilterPopup from '@/components/Club/Popups/ClubsFilterPopup.vue'
import InfoText from '@/components/InfoText.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TableTools',
  components: {
    PopupWindow,
    ClubsAboutPopup,
    ClubsFilterPopup,
    InfoText,
  },
  props: {
    showTools: {
      type: Object,
      default: () => {},
    },
    filterData: {
      type: Array,
      default: () => [],
    },
    checkboxChecked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['isCheck', 'deleteApplications', 'searchEvent', 'applyFilter', 'resetFilter'],
  data() {
    return {
      searchTerm: '',
      showPopup: false,
      showAboutClubs: false,
      showDeletePopup: false,
    }
  },
  computed: {
    getDeleteAllData() {
      return this.$filters.$submitPopupData({
        title: this.$t('club.rejectAll'),
        btnText: null,
        btnType: 'danger',
        btnId: 'confirm-delete-club-button',
        text: this.$t('club.rejectAllConfirm'),
      })
    },
  },
  methods: {
    setCheck() {
      this.$emit('isCheck', !this.checkboxChecked)
    },
    deleteAllApplications() {
      this.$emit('deleteApplications', true)
      this.showDeletePopup = false
    },
  },
})
</script>

<style lang="scss" scoped>
.table-tools {
  margin: 2rem 0;

  &-search-box {
    &-icon {
      height: 4.4rem;
      padding-top: 0.3rem;
      @if $isWsm {
        background-image: linear-gradient(to top, #4787b4, #2b4e67);
        box-shadow:
          inset 0 0 0 0 #010101,
          inset 0 0 0 0.125rem #528ebf;
      }
      @if $isSsm {
        background-image: linear-gradient(to top, #354d69, #446286);
        border: 0.125rem solid #456786;
      }
    }
  }

  &-checkbox {
    position: relative;
    left: 13rem;
  }
}
</style>
