import { clubConfigEndpoint, clubDetailEndpoint, clubEndpoint } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import type { ClubState, ProfileInfo, FoundationPrices, ClubInfo } from '@/types/clubStoreTypes'
import type {
  ClubInfoResponse,
  CreateClubApiResponse,
  EditClubApiResponse,
  FoundationPricesResponse,
} from '@/interfaces/responses/club/ClubResponses'
import { useUserStore } from '@/store/pinia/userStore'

export const useClubStore = defineStore('clubstore', {
  state: (): ClubState => ({
    club: {
      id: '',
      name: '',
      type: '',
      country: '',
      memberMinimumLevel: 1,
      logoId: 1,
      logoBgId: 1,
      description: '',
      members: [],
      membersMaxLength: 0,
      prestige: 0,
      clubhouseLevel: 1,
      quarantineEndsIn: 0,
      paidInGems: false,
    },
    foundationPrices: { gems: 0, money: 0 },
    changesSaved: false,
    clubDeleted: false,
    clubCreated: false,
    joinedToClub: false,
    isLoadingClub: false,
    profileInfo: {
      hasPendingInvitation: '',
      hasPendingRequests: false,
    },
  }),
  getters: {
    getClubInfo(): ClubInfo {
      return this.club
    },
    getFoundationPrices(): FoundationPrices {
      return this.foundationPrices
    },
    getProfileInfo(): ProfileInfo {
      return this.profileInfo
    },
    getPrestige(): ClubState['club']['prestige'] {
      return this.club.prestige
    },
  },
  actions: {
    setChangesSaved(bool: boolean): void {
      this.changesSaved = bool
    },
    setClubDeleted(bool: boolean): void {
      this.clubDeleted = bool
    },
    async createClub(clubData: ClubInfo): Promise<void> {
      const data = {
        name: clubData.name,
        club_type: clubData.type,
        country: clubData.country,
        member_minimum_level: clubData.memberMinimumLevel,
        logo_id: clubData.logoId,
        logo_background_id: clubData.logoBgId,
        description: clubData.description,
        paid_in_gems: clubData.paidInGems,
      }

      try {
        await internalAxios.post<{}, CreateClubApiResponse>(clubEndpoint, data)
        this.setClubCreated(true)
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async setClubCreated(bool: boolean = true): Promise<void> {
      this.clubCreated = bool
    },
    async setJoinedToClub(bool: boolean = true): Promise<void> {
      this.joinedToClub = bool
    },
    async editClub(data: ClubInfo): Promise<void> {
      try {
        await internalAxios.put<{}, EditClubApiResponse>(clubEndpoint, {
          club_id: data.id,
          name: data.name,
          club_type: data.type,
          country: data.country,
          member_minimum_level: data.memberMinimumLevel,
          logo_id: data.logoId,
          logo_background_id: data.logoBgId,
          description: data.description,
        })
        this.loadClubInfo(data.id)
        const userStore = useUserStore()
        userStore.loadUserData()
        this.changesSaved = true
      } catch (error: unknown) {
        console.error(error)
      }
    },

    async deleteClub(clubId: string): Promise<void> {
      try {
        await internalAxios.delete<{}, true>(`${clubEndpoint}/${clubId}`)
        this.clubDeleted = true
      } catch (error: unknown) {
        console.error(error)
      }
    },

    async loadClubInfo(clubId: string = ''): Promise<void> {
      if (this.isLoadingClub) return
      this.isLoadingClub = true

      const endpoint = clubId ? `${clubDetailEndpoint}/${clubId}` : clubDetailEndpoint
      const data = await internalAxios.get<{}, ClubInfoResponse>(endpoint)

      const mutatedData = {
        id: data.id,
        name: data.stats.name,
        type: data.stats.club_type,
        country: data.stats.country.three_letter_code,
        memberMinimumLevel: data.stats.member_minimum_level,
        logoId: data.stats.logo_id,
        logoBgId: data.stats.logo_background_id,
        description: data.stats.description,
        members: data.members,
        membersMaxLength: data.clubhouse_stats.max_members,
        clubhouseLevel: data.clubhouse_stats.level,
        prestige: data.parameters.prestige,
        tournament_titles: data.parameters.tournament_titles,
        master_titles: data.parameters.master_titles,
        quarantineEndsIn: data.quarantine_ends_in,
      }

      const userStore = useUserStore()
      if (!clubId || clubId === userStore.getClub?.id) {
        userStore.setClubMaxMembers(mutatedData.membersMaxLength)
        userStore.setClubPrestige(mutatedData.prestige)
      }

      this.isLoadingClub = false
      this.club = { ...this.club, ...mutatedData }

      this.profileInfo = {
        hasPendingInvitation: data.has_pending_invitation,
        hasPendingRequests: data.has_pending_requests,
      }
    },
    async loadFoundationPrices(): Promise<void> {
      const data = await internalAxios.post<{}, FoundationPricesResponse>(clubConfigEndpoint, {
        keys: 'club_foundation_prices',
      })

      this.foundationPrices = data
    },

    clearUserClub(): void {
      this.club = {
        id: '',
        name: '',
        type: '',
        country: '',
        memberMinimumLevel: 1,
        logoId: 1,
        logoBgId: 1,
        description: '',
        members: [],
        membersMaxLength: 0,
        prestige: 0,
        clubhouseLevel: 1,
        quarantineEndsIn: 0,
        paidInGems: false,
      }
    },
    updateClubPrestige(prestige: number): void {
      const mutatedData = { ...this.club }
      mutatedData.prestige = prestige
      this.club = { ...this.club, ...mutatedData }
    },
  },
})
