<template>
  <section class="w-full flex flex-col items-center">
    <div class="championship-summary-leagues-legendary">
      <div class="championship-summary-leagues-legendary-glow" />
      <div class="championship-summary-leagues-legendary-badge" />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ChampionshipSummaryWinner',
})
</script>
