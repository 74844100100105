<template>
  <div
    v-if="isNoticeVisible"
    :class="{ 'is-mobile': $isMobile() }"
    class="account-deletion"
    @click="$router.push({ name: $getWebView('Account') })"
  >
    <div class="account-deletion-upper flexing">
      <p class="text-texts-standard-additional text-28 leading-8">
        {{ $t('settings.timeToDeleteAccount') }}:
      </p>
      <div class="account-deletion-upper-time flexing">
        <app-icon class="tooltip-text-icon-time mr-2" :icon-size="32" icon-name="time" />
        <vue-countdown
          v-slot="{ days, hours, minutes, seconds }"
          class="text-texts-standard-important text-28 font-bold"
          :time="timeToAccountDeletion * 1000"
          :auto-start="true"
          @end="finishDeletion"
        >
          {{ formatTime(days, hours, minutes, seconds) }}
        </vue-countdown>
        <div class="account-deletion-upper-hide" @click.stop="hideNotice">
          <div class="control-icon control-icon-close" />
        </div>
      </div>
    </div>
    <div class="white-strip" />
    <div class="account-deletion-lower flexing">
      <p class="text-texts-standard-default text-30 leading-8">
        {{ $t('settings.cancelDeleteAccountClick') }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { deleteRequest } from '@/globalVariables'
import { formatTime, sendToFlutter } from '@/helpers'
import { logoutUser } from '@/helpers/misc'
import { useUserStore } from '@/store/pinia/userStore'
import type { AccountRequestDeleteApiResponse } from '@/interfaces/responses/account/AccountRequestDeleteApiResponse'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  isNoticeVisible: boolean
}

export default defineComponent({
  name: 'AccountDeletion',
  data(): ComponentData {
    return {
      isNoticeVisible: false,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      timeToAccountDeletion: 'getTimeToAccountDeletion',
    }),
  },
  watch: {
    timeToAccountDeletion(): void {
      if (this.timeToAccountDeletion) this.isNoticeVisible = true
      else this.isNoticeVisible = false
    },
  },

  created(): void {
    this.checkNoticeVisibility()
  },

  methods: {
    formatTime,
    logoutUser,
    sendToFlutter,

    // nadstavenie ci je oznam o vymazaní na hlavnej mape
    checkNoticeVisibility(): void {
      if (!sessionStorage.getItem('hideDeleteAccountMessage') && this.timeToAccountDeletion)
        this.isNoticeVisible = true
    },

    // skrytie spravy setne do session zaznma ktory sa premaze az reloadom
    hideNotice(): void {
      sessionStorage.setItem('hideDeleteAccountMessage', 'true')
      this.isNoticeVisible = false
    },

    async finishDeletion(): Promise<void> {
      await this.$axios.put<{}, AccountRequestDeleteApiResponse>(deleteRequest)
      if (this.$isMobile())
        this.sendToFlutter(
          JSON.stringify({
            event: 'disconnect',
          }),
        )
      this.logoutUser()
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/control-icons.scss';

.account-deletion {
  height: 9.563rem;
  background: rgba(99, 0, 1, 0.9);
  border: 0.125rem solid #e81414;
  box-shadow: inset 0 0px 10px 3px rgba(196, 19, 19, 0.77);
  position: absolute;
  bottom: 7.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  padding: 0 1rem;
  cursor: pointer;

  @if $isWsm {
    width: 37.5rem;
  }

  @if $isSsm {
    width: 40rem;
  }

  &-upper {
    width: 87%;
    height: 51%;

    p {
      max-width: 68%;
    }

    &-time {
      margin-left: 1rem;
    }

    &-hide {
      width: 4.375rem;
      height: 4.375rem;
      border: 0.063rem solid rgba(255, 255, 255, 0.5);
      position: absolute;
      right: 0.125rem;

      &:active {
        transform: scale(0.95);

        & .control-icon-close {
          background-position: -13.2rem 0;
        }
      }

      & .control-icon-close {
        background-position: -13.18rem -0.1rem;
      }
    }
  }

  &-lower {
    width: 100%;
    height: 49%;
  }
}

.is-mobile {
  bottom: 9.5rem;
}
</style>
