<template>
  <matches-report :history-data="historyData" redirect-link="ClubsChampionshipCompetitions" />
</template>

<script lang="ts">
import MatchesReport from '@/components/Reports/MatchesReport.vue'
import { getReportValues } from '@/helpers'
import { useMatchesStore } from '@/store/pinia/matchesStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type ArenaMatchReportApiResponse from '@/interfaces/responses/reports/ArenaMatchReportApiResponse'

interface ComponentData {
  matchId: Nullable<string>
  matchTime: Nullable<string>
  historyData: Nullable<ArenaMatchReportApiResponse>
}

export default defineComponent({
  name: 'ClubsChampionshipReport',
  components: {
    MatchesReport,
  },
  data(): ComponentData {
    return {
      matchId: null,
      matchTime: null,
      historyData: null,
    }
  },
  computed: {
    ...mapState(useMatchesStore, ['getMatchReport']),
  },
  async created(): Promise<void> {
    const { matchId, matchTime } = getReportValues(this.$route)

    this.matchId = matchId
    this.matchTime = matchTime

    const newID = await this.loadMatchReport({
      matchId: this.matchId,
      matchTime: this.matchTime,
    })

    this.matchId = newID
    try {
      this.historyData = this.getMatchReport(this.matchId)
    } catch (err: unknown) {
      console.error(err)
    }
  },

  methods: {
    ...mapActions(useMatchesStore, ['loadMatchReport']),
  },
})
</script>
