<template>
  <div data-piggy-bank-handler />
</template>

<script lang="ts">
import { MECHANIC_PIGGY_BANK } from '@/globalVariables'
import { CREATE_PIGGY_BANK_OSRA } from '@/map-phaser-new/constants'
import { usePiggyBankStore } from '@/store/pinia/piggyBankStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MapPiggyBankHandler',
  computed: {
    ...mapState(usePiggyBankStore, { isPiggyBankActive: 'isActive' }),
    ...mapState(useTutorialStore, { isTutorial: 'getIsTutorial' }),
    ...mapState(useResponseTaskStore, { mechanics: 'getMechanics' }),
    ...mapState(usePhaserGameIntegrationStore, { phaserEventEmitter: 'getPhaserEventEmitter' }),
  },
  watch: {
    mechanics: {
      handler(): void {
        if (!this.mechanics.includes(MECHANIC_PIGGY_BANK)) return
        this.createPiggyBankOsra()
      },
      deep: true,
    },
    async isPiggyBankActive(): Promise<void> {
      if (!this.isPiggyBankActive) return
      await this.createPiggyBankOsra()
    },
  },
  async created(): Promise<void> {
    await this.createPiggyBankOsra()
  },
  mounted(): void {
    document.addEventListener('visibilitychange', this.onVisibilityChange, { passive: true })
  },
  beforeUnmount(): void {
    document.removeEventListener('visibilitychange', this.onVisibilityChange)
  },
  methods: {
    ...mapActions(usePiggyBankStore, { loadPiggyBankProgress: 'loadProgress' }),
    async onVisibilityChange(): Promise<void> {
      if (document.visibilityState === 'hidden') return
      await this.createPiggyBankOsra()
    },
    async createPiggyBankOsra(): Promise<void> {
      if (this.isTutorial) return
      if (!this.mechanics.includes(MECHANIC_PIGGY_BANK)) return

      await this.loadPiggyBankProgress()

      if (!this.isPiggyBankActive) return

      this.phaserEventEmitter?.emit(CREATE_PIGGY_BANK_OSRA)
    },
  },
})
</script>
