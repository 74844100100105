<template>
  <info-popup
    :popup-title="$t('premiumGroup.adsBlockHeader')"
    @close="$emit('closeFailVideoPopup')"
  >
    <section class="fail-video-popup flex flex-col justify-around">
      <aside class="video-ads-popup-text flexing">
        <p class="text-texts-standard-default text-40">
          {{ $t('premiumGroup.adsBlockContent') }}
        </p>
      </aside>
      <aside class="flexing">
        <app-button
          btn-type="secondary"
          :btn-text="$t('button.continue')"
          @click="$emit('closeFailVideoPopup')"
        />
      </aside>
    </section>
  </info-popup>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import InfoPopup from './InfoPopup.vue'

export default defineComponent({
  name: 'FailVideoPopup',
  components: { InfoPopup },
  emits: ['closeFailVideoPopup'],
})
</script>

<style lang="scss" scoped>
.fail-video-popup {
  min-height: 25rem;
}
</style>
