import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const carsCheckpoints: CheckpointsObjectInterface = {
  key: 'animations',
  data: [
    {
      x: 1829.9999879896648,
      y: 575.2499990724028,
      width: 16,
      height: 26,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          reverse: 'carTurnRightTop2Bottom',
          normal: 'carTurnLeftTop2BottomRev',
        },
      },
    },
    {
      x: 1392.4999945089228,
      y: 796.7499928958717,
      width: 4,
      height: 4,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          reverse: 'carTurnRightBottom2Top',
          normal: 'carTurnRightTop2BottomRev',
        },
      },
    },
    {
      x: 1895.4999869093306,
      y: 1023.9999894499783,
      width: 24,
      height: 18,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          reverse: 'carTurnLeftBottom2Top',
          normal: 'carTurnRightBottom2TopRev',
        },
      },
    },
    {
      x: 2789.999969869853,
      y: 602.9999948292972,
      width: 4,
      height: 4,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          reverse: 'carTurnLeftTop2Bottom',
          normal: 'carTurnLeftBottom2TopRev',
        },
      },
    },
  ],
}
