<template>
  <info-popup
    v-if="showPopup"
    width="85rem"
    :popup-title="$t('map.arenaName')"
    @close="$emit('close')"
  >
    <div class="popup flexing text-30 text-white">
      <div class="popup-left space-y-4">
        <dash-text
          v-for="value in 3"
          :key="value"
          :text="
            $replacePlaceholder(
              $t(`seasons.aboutArenaText${value}`),
              '%s',
              value === 1 ? String(MIN_REDUCED_POINTS) : String(ARENA_LEVEL),
            )
          "
        />
      </div>
      <div class="popup-right flex flex-col">
        <p
          :class="{
            'text-texts-standard-additional': $isWsm,
            'text-texts-standard-default': $isSsm,
          }"
        >
          {{ $t('seasons.maxLevelReached') }}
        </p>
        <div class="badge-level popup-right-badge">
          {{ isCurrentSeason ? arenaLevel : arenaStats.arenaLevel }}
        </div>
        <seasons-leaderboards-maximum
          :value="isCurrentSeason ? Number(userRankingPoints) : arenaStats.rankingPoints"
        />
      </div>
    </div>
  </info-popup>
</template>

<script lang="ts">
import DashText from '@/components/DashText.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import SeasonsLeaderboardsMaximum from '@/components/Seasons/SeasonsLeaderboardsMaximum.vue'
import {
  ARENA,
  ARENA_LEVEL,
  CURRENT_SEASON,
  MIN_REDUCED_POINTS,
  TOP_PLAYERS,
  pathImages,
} from '@/globalVariables'
import type { BuildingMainMapResponseInterface } from '@/map-phaser-new/interfaces'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { useMainSeasonStore } from '@/store/pinia/seasons/mainSeasonsStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  TOP_PLAYERS: typeof TOP_PLAYERS
  MIN_REDUCED_POINTS: typeof MIN_REDUCED_POINTS
  ARENA_LEVEL: typeof ARENA_LEVEL
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'SeasonsAboutPopup',
  components: {
    DashText,
    InfoPopup,
    SeasonsLeaderboardsMaximum,
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      ARENA_LEVEL,
      MIN_REDUCED_POINTS,
      TOP_PLAYERS,
      pathImages,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userRankingPoints: 'getRankingPoints',
    }),
    ...mapState(usePhaserGameIntegrationStore, {
      buildings: 'getMainMapBuildings',
    }),
    ...mapState(useMainSeasonStore, {
      arenaStats: 'getPreviousSeasonArenaStats',
    }),
    isCurrentSeason(): boolean {
      return this.$route.params.type === CURRENT_SEASON
    },
    arenaLevel(): number {
      return (
        this.buildings.find((building: BuildingMainMapResponseInterface) => building.name === ARENA)
          ?.level ?? 1
      )
    },
  },
  created(): void {
    if (!this.isCurrentSeason) this.loadPreviousSeasonArenaStats()
  },
  methods: {
    ...mapActions(useMainSeasonStore, {
      loadPreviousSeasonArenaStats: 'loadPreviousSeasonArenaStats',
    }),
  },
})
</script>

<style lang="scss" scoped>
.popup {
  padding: 2rem 2rem 0 2rem;

  &-left {
    align-self: flex-start;
    width: 50%;
  }

  &-right {
    position: relative;
    width: 50%;
    padding-left: 4rem;

    @if $isWsm {
      height: 21rem;
    }

    @if $isSsm {
      padding-bottom: 2rem;
    }

    &:before {
      background: url($path-images + 'arena/building/BUILDING_ARENA_SMALL_LVL5.avif') center
        no-repeat;
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.2;

      @if $isSsm {
        background-size: 80%;
        background-position: bottom;
      }
    }

    &-badge {
      position: relative;
      margin: 0 auto;

      @if $isWsm {
        margin-bottom: 1rem;
      }

      @if $isSsm {
        margin-bottom: 4.5rem;
      }
    }
  }
}
</style>
