<template>
  <div
    id="club-application"
    class="club-application-view flex flex-col app-page-wrapper absolute w-full h-full"
  >
    <clubs-managment-menu />
    <section class="club-application-wrapper flex w-full h-full safe-area">
      <main class="club-application-main flex flex-col relative">
        <table-component
          :columns="columns"
          :items="getRequests"
          :no-data-text="$t('club.noPlayersFound')"
          force-scroll-size="100%"
          :show-tools="showToolsData"
          :checkbox-checked="checked"
          @button-click="acceptApplication($event)"
          @is-check="hasCheck"
          @delete-applications="deleteAllApplications"
          @delete-cell="deleteApplication($event)"
          @search-event="searchByName($event)"
          @sort-event="orderBy($event)"
        />
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import ClubsManagmentMenu from '@/components/Club/ClubsManagmentMenu.vue'
import TableComponent from '@/components/Table/TableComponent.vue'
import { clubTableKeys } from '@/components/Table/tableKeys'
import { getOrderBodyContent } from '@/helpers'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useClubRequestsStore } from '@/store/pinia/clubs/requests'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { BodyContent } from '@/helpers/orderBy'
import type { ClubsColumnData } from '@/interfaces/clubs/Clubs'

interface ToolsData {
  search: boolean
  checkbox: boolean
  btnDisabled: boolean
}
interface ComponentData {
  clubTableKeys: typeof clubTableKeys
  checked: boolean
}

export default defineComponent({
  name: 'ClubsApplication',
  components: {
    ClubsManagmentMenu,
    TableComponent,
  },
  data(): ComponentData {
    return {
      clubTableKeys,
      checked: true,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      getClubRequestNotifications: 'getClubRequestNotifications',
    }),
    ...mapState(useClubRequestsStore, {
      getRequests: 'getRequests',
    }),
    ...mapState(useClubStore, {
      getClubInfo: 'getClubInfo',
    }),
    columns(): ClubsColumnData[] {
      return [
        // if it's icon pass to label icon name and set isIcon to true
        { label: this.$t('settings.country'), key: clubTableKeys.country, width: 10 },
        {
          label: this.$t('common.name'),
          key: clubTableKeys.username,
          align: 'left',
          width: 30,
        },
        { label: this.$t('common.level'), key: clubTableKeys.level },
        {
          label: 'prestige',
          key: clubTableKeys.prestige,
          isIcon: true,
          width: 10,
        },
        {
          label: 'ranking_points',
          key: clubTableKeys.rankingPoints,
          isIcon: true,
          width: 15,
        },
        {
          label: '',
          key: clubTableKeys.button,
          buttonData: {
            type: 'secondary',
            size: 'md',
            text: 'common.accept',
            class: 'cell-narrower-button',
            disabled: this.isClubFull ?? false,
            afterClick: true,
          },
          width: 15,
        },
        { label: '', key: clubTableKeys.delete, width: 5 },
      ]
    },
    showToolsData(): ToolsData {
      return {
        search: true,
        checkbox: true,
        btnDisabled: this.getRequests.length <= 0,
      }
    },
    isClubFull(): boolean {
      return (
        this.getClubInfo.members.length > 0 &&
        this.getClubInfo.members.length >= this.getClubInfo.membersMaxLength
      )
    },
  },
  async created(): Promise<void> {
    await Promise.all([this.loadClubInfo(), this.loadRequests(), this.loadNotifications()])

    this.checked = this.getClubRequestNotifications
  },
  methods: {
    getOrderBodyContent,
    ...mapActions(useUserStore, {
      setProfileAttributes: 'setProfileAttributes',
    }),
    ...mapActions(useClubRequestsStore, [
      'loadRequests',
      'acceptRequest',
      'declineRequest',
      'declineAllRequests',
    ]),
    ...mapActions(useClubStore, {
      loadClubInfo: 'loadClubInfo',
    }),
    ...mapActions(useResponseTaskStore, {
      loadNotifications: 'loadNotifications',
    }),
    async hasCheck(): Promise<void> {
      this.checked = !this.checked
      await this.setProfileAttributes({
        name: 'club_requests_notifications',
        value: this.checked,
      })
      this.loadNotifications()
    },
    deleteAllApplications(): void {
      this.declineAllRequests()
      this.loadNotifications()
    },
    async deleteApplication(data: { e: string; index: number }): Promise<void> {
      await this.declineRequest(data.e)
      document.getElementById(`table-component-row-${data.index}`).remove()
      this.loadNotifications()
    },
    async acceptApplication(id: string): Promise<void> {
      await this.acceptRequest(id)
      await this.loadClubInfo()
      this.loadNotifications()
    },
    searchByName(name: string): void {
      this.loadRequests({ filter_name: name })
    },
    orderBy(order: BodyContent): void {
      const bodyContent = this.getOrderBodyContent(order)
      this.loadRequests(bodyContent)
    },
  },
})
</script>

<style lang="scss" scoped>
.club-application {
  &-wrapper {
    flex: 1 1 auto;
    min-height: 0;

    .club-application-main {
      width: 98%;
      margin: 0 auto;

      // TODO if the global table system is solved, we can delete this deep
      :deep() {
        .table-component {
          display: flex;
          width: 100%;
          flex: 1 1 auto;
          overflow: hidden;
          margin: 0 auto 1.25rem;
        }

        .table-component-wrapper {
          // Positional correction of the table in contrast to display: table
          display: flex;
          height: 100%;

          .y-scroll {
            // Offset for the scrollbar without blending with the table
            padding-right: 1.375rem;
          }
        }
      }
    }
  }
}
</style>
