<template>
  <section class="chains-message flexing w-full z-50 absolute">
    <div class="progress-message">
      <header class="progress-message-header flexing">
        <h2 class="text-40 text-texts-standard-additional uppercase">{{ title }}</h2>
      </header>
      <article class="progress-message-text flexing">
        <p class="text-32 text-texts-standard-default uppercase">{{ message }}</p>
      </article>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EventMessage',
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.chains-message {
  top: 19rem;
  left: 0;
  height: 49.25rem;
  background-color: rgba(0, 0, 0, 0.72);

  // Copied from ProgressMessage.
  .progress-message {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 20rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0.313rem 0.813rem 0 #272727;
    background: linear-gradient(
      to right,
      transparent,
      #154e79 10%,
      #0f2a4e 40%,
      #0b1e37 50%,
      #0f2a4e 60%,
      #154e79 90%,
      transparent
    );

    &-header {
      width: 100%;
      height: 5rem;
      @include background(url('#{$path-components}progressMessage/BG_TOP_CONTENT.avif'), cover);
    }

    &-text {
      flex-grow: 1;
    }
  }
}
</style>
