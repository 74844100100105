<template>
  <div v-if="!isContentLoading" class="events-warehouse-chest">
    <div class="events-warehouse-chest-title flexing">
      <p class="text-40 gradient-text-gold uppercase font-bold w-full">
        {{ $te('partsPackage') }}
      </p>
    </div>
    <div class="events-warehouse-chest-image">
      <div class="events-warehouse-chest-amount flexing">
        <event-button
          id="btn-buy-event-chest"
          :disabled="isFreePackage || isBuyButtonDisabled || eventCollectionActive"
          @click="useMultiplier"
        >
          <template #leftSide>
            {{ chestMultiplier }}X
            <app-icon icon-name="chest" class="ml-4" />
          </template>
          <template #rightSide>
            <span class="text-50">+</span>
          </template>
        </event-button>
        <app-icon
          v-tippy="{
            content: $replacePlaceholder(
              $te('getPartsPackage'),
              '%s',
              getPackageStartsEquivalent.toString(),
            ),
            placement: 'top',
            theme: 'WSM',
          }"
          icon-name="info-70"
          class="info-icon ml-4"
        />
      </div>

      <app-button
        v-if="!isFreePackage"
        btn-id="btn-events-warehouse-chest-buy"
        class="events-warehouse-chest-buy"
        btn-type="credit"
        btn-size="xsm"
        :disabled="isBuyButtonDisabled || eventCollectionActive"
        :loading="isBuyButtonDisabled"
        @click="showModal"
      >
        <div class="flex flex-row items-center justify-center">
          <span>{{ showValueWithMultiplier(packageCost) }}</span>
          <app-main-icon :icon-size="48" icon-name="gems" />
        </div>
      </app-button>
      <div
        v-if="isFreePackage"
        class="flex flex-col justify-center items-center events-warehouse-chest-free-packages"
      >
        <div class="events-warehouse-chest-free-packages-text">
          <p class="text-32 font-bold text-texts-standard-default">
            {{ $te('freePacksReady') }}
          </p>
        </div>
        <app-button
          btn-id="btn-events-warehouse-chest-free-packages-claim"
          btn-type="confirm"
          :btn-text="$t('common.claim')"
          btn-size="sm"
          :loading="isBuyButtonDisabled"
          @click="claimFreeChests"
        />
      </div>
    </div>
  </div>
  <no-gems-popup :is-visible="isNoGemsModalVisible" @close="isNoGemsModalVisible = false" />
  <confirm-package-buy-popup
    :is-visible="isConfirmModalVisible"
    :price="showValueWithMultiplier(packageCost)"
    @close="isConfirmModalVisible = false"
    @click="buyPackageChest"
  />
</template>

<script lang="ts">
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useWarehouseStore } from '@/store/pinia/events/warehouseStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import NoGemsPopup from '../../Popup/NoGemsPopup.vue'
import EventButton from '../EventButton.vue'
import ConfirmPackageBuyPopup from '../EventsPopups/ConfirmPackageBuyPopup.vue'

interface ComponentData {
  chestMultiplier: number
  chestMultiplierIndex: number
  isNoGemsModalVisible: boolean
  isConfirmModalVisible: boolean
  isBuyButtonDisabled: boolean
}

export default defineComponent({
  name: 'EventsWarehouseChest',
  components: {
    ConfirmPackageBuyPopup,
    EventButton,
    NoGemsPopup,
  },
  data(): ComponentData {
    return {
      chestMultiplier: 1,
      chestMultiplierIndex: 0,
      isNoGemsModalVisible: false,
      isConfirmModalVisible: false,
      isBuyButtonDisabled: false,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      userGems: 'getGems',
    }),
    ...mapState(useWarehouseStore, {
      getPackageMultipliers: 'getPackageMultipliers',
      packageCost: 'getPackageCost',
      getPackageStartsEquivalent: 'getPackageStartsEquivalent',
      getFreePackageAmount: 'getFreePackageAmount',
    }),
    ...mapState(useEventInfoStore, {
      eventCollectionActive: 'getEventCollectionActive',
    }),
    isContentLoading(): boolean {
      return !this.packageCost && !this.getPackageMultipliers
    },
    isFreePackage(): boolean {
      return this.getFreePackageAmount > 0
    },
  },
  methods: {
    ...mapActions(useWarehouseStore, {
      buyPackage: 'buyPackage',
    }),
    useMultiplier(): [number] | void {
      if (this.getPackageMultipliers.length === 0) return [1]

      const enabledValues: number[] = this.getPackageMultipliers

      this.chestMultiplierIndex = (this.chestMultiplierIndex + 1) % enabledValues.length
      this.chestMultiplier = enabledValues[this.chestMultiplierIndex]
    },
    showValueWithMultiplier(value: number): number {
      return value * this.chestMultiplier
    },
    async buyPackageChest(): Promise<void> {
      this.isConfirmModalVisible = false
      this.isBuyButtonDisabled = true
      await this.buyPackage({
        gems: true,
        multiplier: this.chestMultiplier,
      })
      this.isBuyButtonDisabled = false
    },
    showModal(): void {
      if (this.userGems.value >= this.showValueWithMultiplier(this.packageCost)) {
        this.isConfirmModalVisible = true
      } else {
        this.isNoGemsModalVisible = true
      }
    },
    async claimFreeChests(): Promise<void> {
      this.isBuyButtonDisabled = true
      await this.buyPackage({
        gems: false,
        multiplier: this.getFreePackageAmount,
      })
      this.isBuyButtonDisabled = false
    },
  },
})
</script>

<style lang="scss" scoped>
.events-warehouse-chest {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 40%;

  &-title {
    width: 90%;
    height: 5.375rem;

    @if $isWsm {
      background-image: linear-gradient(
        to right,
        transparent 0%,
        #405871 30%,
        #405871 70%,
        transparent 100%
      );
      border-style: solid;
      border-width: 0.188rem;
      border-image-source: linear-gradient(
        to right,
        transparent 0%,
        #5f7589 30%,
        #5f7589 70%,
        transparent 100%
      );
      border-image-slice: 1;
    }

    @if $isSsm {
      @include background(
        url($path-events + 'frozen-championship/warehouse/title-bg.avif'),
        contain
      );
      width: 31.25rem;
      height: 6.75rem;
    }
  }

  &-image {
    width: 105%;
    height: 33rem;
    @include background(
      url($path-events + 'frozen-championship/warehouse/chest-locked.avif'),
      cover
    );
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @if $isSsm {
      background-size: contain;
      background-position: center 2rem;
    }
  }

  &-amount {
    width: 100%;
    margin-top: 1rem;

    @if $isWsm {
      background-image: linear-gradient(
        to right,
        transparent,
        rgba(25, 35, 47, 0.8),
        #19232f,
        rgba(25, 35, 47, 0.8),
        transparent
      );
    }

    @if $isSsm {
      position: relative;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      z-index: 0;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          to right,
          transparent,
          rgba(25, 35, 47, 0.8),
          #19232f,
          rgba(25, 35, 47, 0.8),
          transparent
        );
        border-style: solid;
        border-width: 0.125rem;
        border-image-source: linear-gradient(
          to right,
          transparent 15%,
          rgba(83, 119, 153, 0.98) 85%,
          transparent 100%
        );
        border-image-slice: 1;
        opacity: 0.65;
        z-index: -1;
      }
    }
  }

  &-buy {
    margin-bottom: 1.6rem;
    width: 20.625rem !important;
  }

  &-free-packages {
    &-text {
      margin-bottom: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      width: 115%;
      background: linear-gradient(
        90deg,
        rgba(124, 82, 17, 0) 0%,
        rgba(147, 93, 14, 1) 20%,
        rgba(182, 108, 3, 1) 50%,
        rgba(147, 93, 14, 0.8) 80%,
        rgba(124, 82, 17, 0) 100%
      );
      border-style: solid;
      border-width: 0.188rem;
      border-image-source: linear-gradient(
        90deg,
        rgba(240, 144, 8, 0) 0%,
        rgba(240, 144, 8, 1) 20%,
        rgba(240, 144, 8, 1) 80%,
        rgba(240, 144, 8, 0) 100%
      );
      border-image-slice: 1;
    }
  }
}
</style>
