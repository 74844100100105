<template>
  <section
    v-for="(task, i) in boxes"
    :key="i"
    class="dt-main-box"
    :class="[task.isLocked ? 'is-locked' : 'main-box-diamond flex items-center relative']"
  >
    <template v-if="!task.isLocked">
      <div class="dt-main-box-texts h-full text-36 text-texts-standard-default text-left">
        <p class="font-bold complete-text">
          {{ task.text }}
        </p>
        <p>
          {{ $t('dailyTasksUi.completedTasks') }}
          <span class="text-texts-standard-important italic">
            {{ task.current }}
          </span>
          /
          <span class="italic">
            {{ task.goal }}
          </span>
        </p>
      </div>
      <div class="dt-main-box-rewards flex justify-end items-center">
        <reward-box
          v-for="(reward, j) in task.rewards"
          :key="j"
          :reward-number="reward.amount"
          :reward-icon-name="reward.name"
          :reward-icon-rarity="reward.rarity ? reward.rarity : ''"
          :reward-icon="72"
        />
      </div>
      <app-button
        v-if="!task.isClaimed"
        class="btn-claim absolute"
        btn-type="secondary"
        :btn-text="$t('common.claim')"
        :disabled="task.current < task.goal || isDisabled(task.id)"
        :loading="isDisabled(task.id)"
        @click="$emit('claim', task.id)"
      />
      <section v-else class="claimed-reward w-full h-full flex items-center justify-end absolute">
        <div
          class="claimed-reward-text flexing text-32 text-texts-standard-upgrade font-bold uppercase"
        >
          <p>{{ $t('common.rewardsClaimed') }}</p>
          <app-icon :icon-name="'done-sm'" class="icon-done" />
        </div>
      </section>
    </template>
    <div v-else class="flexing w-full h-full space-x-8">
      <app-icon icon-name="lock-lg" class="pointer-events-none" />
      <p class="text-34 text-white career-branch-locked-text-discipline">
        <!-- // TODO: text -->
      </p>
    </div>
  </section>
</template>

<script lang="ts">
import type { DailyTaskMainBoxData } from '@/interfaces/DailyTask'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

export interface RewardType {
  amount: number
  name: string
  rarity: string
}

export default defineComponent({
  props: {
    boxes: {
      type: Array as PropType<DailyTaskMainBoxData[]>,
      default: () => [],
    },
    isButtonDisabled: {
      type: Object as PropType<Record<string, boolean>>,
      default: () => ({}),
    },
  },
  emits: ['claim'],
  methods: {
    isDisabled(id: string): boolean {
      return id in this.isButtonDisabled
    },
  },
})
</script>

<style lang="scss" scoped>
.dt-main-box {
  border: 0.0625rem solid #fadd1e;
  width: 100%;
  height: 10rem;
  margin: 0 auto;
  margin-bottom: 0.75rem;

  &.is-locked {
    background-color: #3b434a;
    border: solid 0.125rem #7477a4;
    opacity: 0.8;
  }

  &.main-box-diamond {
    background: url($path-images + 'dailyTask/BG-BOX-DIAMOND.avif') center no-repeat;
    background-size: cover;
  }

  &-rewards {
    width: 23.325rem;
  }

  &-texts {
    margin-left: 17.75rem;
    width: 40rem;

    .complete-text {
      margin-top: 1.8rem;
    }
  }

  .btn-claim {
    right: 3.625rem;
  }

  .claimed-reward {
    background: rgba(0, 0, 0, 0.5);

    &-text {
      margin-right: 5.6875rem;

      .icon-done {
        margin-left: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}
</style>
