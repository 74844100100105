<template>
  <div
    v-if="$isMobile() && (control === 'home' || control === 'back')"
    class="control-icon-wrapper flexing"
    :class="iconType"
    @click="controlClick"
  >
    <div :id="iconId" :class="'clickable-element control-icon control-icon-' + control" />
  </div>
  <div v-else :class="iconType">
    <div
      :id="iconId"
      :class="'clickable-element control-icon control-icon-' + control"
      @click="controlClick"
    />
    <slot />
  </div>
</template>

<script lang="ts">
import { createRandomId, playSound } from '@/helpers'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { Nullable } from '@/interfaces/utils'
import router from '@/router'
import { SCENES_KEYS } from '@/map-phaser-new/constants'

enum ControlActions {
  Home = 'home',
  Back = 'back',
  Hide = 'hide',
  Close = 'close',
  Delete = 'delete',
}

interface ComponentData {
  isAvailable: boolean
  timeout: Nullable<ReturnType<typeof setTimeout>>
}

export default defineComponent({
  name: 'AppControlIcon',
  props: {
    control: {
      type: String,
      required: true,
    },
    iconId: {
      type: String,
      default: createRandomId('icon'),
    },
  },
  data(): ComponentData {
    return {
      isAvailable: true,
      timeout: null,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      offerDetail: 'getOfferDetail',
    }),
    ...mapState(useUserStore, {
      hasClub: 'getHasClub',
      myClubId: 'getPlayerClubId',
    }),
    ...mapState(useTutorialStore, {
      actualStage: 'getActualStage',
    }),
    ...mapState(useResponseTaskStore, {
      getPlayInterstitial: 'getPlayInterstitial',
    }),
    iconType(): string {
      if (
        this.control === ControlActions.Close ||
        this.control === ControlActions.Hide ||
        (this.control === ControlActions.Back && !this.$isMobile())
      ) {
        return 'control-icon-box'
      } else if (this.control === ControlActions.Delete) return 'control-icon-box red'
      return ''
    },
    tutorialStep(): boolean {
      const closeWindowSteps = [
        'closeTrainingHall',
        'closeArenaWindow',
        'closeEquipmentShop',
        'closeArenaAfterSimulations',
        'closeGrandPrize',
        'focusDisciplineBranchDetailCloseButton',
        'clickCloseBranchDetail',
        'clickClubhouseCloseButton',
        'focusCareerHomeButtonCareer',
      ]
      return this.actualStage && closeWindowSteps.includes(this.actualStage.name)
    },
  },
  beforeUnmount(): void {
    clearTimeout(this.timeout)
    this.timeout = null
  },
  methods: {
    ...mapActions(usePremiumStore, {
      hideOfferDetail: 'hideOfferDetail',
    }),
    ...mapActions(useResponseTaskStore, {
      playAd: 'playAd',
    }),
    controlClick(): void {
      if (!this.isAvailable) return
      this.isAvailable = false
      this.timeout = setTimeout(() => (this.isAvailable = true), 700)
      // TODO!: Refactor this mess
      if (this.control === ControlActions.Home || this.control === ControlActions.Close) {
        playSound('click-button-dialog-close')
        if (this.tutorialStep) {
          if (this.$route.path.includes('clubs')) {
            this.$router.push({ name: 'ClubsMapTutorial' })
          } else {
            this.$router.push('/tutorial/layout')
          }
        } else {
          if (
            this.$route.path.includes('career') &&
            !this.$route.path.includes('tasks/career') &&
            !this.$route.query.homepage
          ) {
            this.$router.push('/')
          } else if (this.offerDetail) {
            if (this.offerDetail[0]?.category === 'no_ads' && this.getPlayInterstitial) {
              this.playAd()
            }
            this.hideOfferDetail()
          } else if (this.$route.path.includes('clubs') && this.hasClub) {
            if (this.$route.query.redirectFrom && this.$route.query.redirectFrom !== 'ClubsMap') {
              this.$router.push({ name: this.$route.query.redirectFrom as string })
            } else {
              if (this.$route.query.homepage === 'true') {
                this.$router.push('/')
              } else {
                this.$router.push({
                  name: 'ClubsMap',
                  params: { clubId: this.myClubId },
                })
              }
            }
          } else if (this.$route.path.includes('clubs') && !this.hasClub) {
            const activeScene: string =
              (router.currentRoute.value.meta?.activeScene as string) ?? SCENES_KEYS.MainMapScene

            if (
              this.control === ControlActions.Close ||
              (this.control === ControlActions.Home && this.$isMobile())
            ) {
              // Regular expression to validate a club path with a UUID
              // e.g. /clubs/6e8bbf9e-f947-4b6b-abea-e7c646685436
              const regex =
                /^\/clubs\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

              if (regex.test(this.$route.path)) {
                return
              } else {
                if (activeScene === SCENES_KEYS.ClubsMapScene) {
                  // e.g. club chat on a foreign map
                  router.push({ path: this.$router.options.history.state.back.toString() })
                } else {
                  this.$router.push('/')
                }
              }
            }
            return
          } else {
            this.$router.push('/')
          }
        }
      } else if (this.control === ControlActions.Back) {
        if (
          this.$route.path.includes('career/detail') ||
          window.history.state.back.includes('tutorial') ||
          !window.history.state.back
        ) {
          this.$router.push('/')
        } else if (this.$route.name.toString().includes('StarofthedayDisciplineDetail')) {
          this.$router.push({
            name: this.$getWebView('StarofthedayDisciplines'),
          })
        } else if (this.$route.path.includes('list-of-unlocks')) {
          this.$router.push({ name: this.$getWebView('CareerDetail') })
        } else if (
          this.$route.name.toString().includes('TournamentsDetail') &&
          history.state?.back === history.state?.current
        ) {
          this.$router.push({ name: this.$getWebView('TournamentsCalendar') })
        } else {
          this.$router.back()
        }
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/control-icons.scss';
</style>
