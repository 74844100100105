<template>
  <div id="mail-blocked-view" class="app-page-wrapper mail absolute">
    <menu-component menu-type="mail" :title="$t('post.blockedUsers')" />
    <section class="w-full h-full safe-area">
      <div v-if="blockedUsers" class="mail-blocked">
        <p class="mail-blocked-info text-40 text-texts-standard-default">
          {{ `${$t('post.blockedUsers')} (${blockedUsers.length}/50)` }}
        </p>
        <div class="mail-blocked-head table-head-standard flex items-center relative">
          <p class="head-user">
            {{ $t('post.name') }}
          </p>
          <p class="head-date">
            {{ $t('post.date') }}
          </p>
          <p class="head-note">
            {{ $t('post.note') }}
          </p>
        </div>
        <app-scrollbar width="98%" height="60%" scroll="y" slice="y" class="absolute pr-4">
          <div class="table-standard" :class="{ 'skew-right': $isWsm }">
            <div
              v-for="(user, index) in blockedUsers"
              :key="user.id"
              class="mail-blocked-row cursor-pointer table-standard-row relative text-34 text-texts-standard-default flex items-center"
            >
              <div class="table-standard-col h-full flexing col-user">
                <app-user
                  :id="user.id || ''"
                  :country="user.country.three_letter_code"
                  :name="user.username"
                  :badges-data="user.event_badges ?? null"
                />
              </div>
              <div class="table-standard-col h-full flexing col-date flex-col">
                <p class="font-bold text-32">
                  {{ getFormattedDateFromYMD(user.blocked_at) }}
                </p>
                <p class="text-28">
                  {{ getFormattedTimeFromHMS(user.blocked_at) }}
                </p>
              </div>
              <div class="table-standard-col h-full flex justify-between items-center col-note">
                <div class="blocked-input-wrapper flex">
                  <div
                    v-if="editInput !== user.id"
                    class="blocked-input flex items-center w-full text-28"
                    @click="editNote(user.id, index)"
                  >
                    <p :class="[!user.note ? 'has-placeholder' : 'text-texts-standard-additional']">
                      {{ user.note ? user.note : $t('post.inputNote') }}
                    </p>
                  </div>
                  <input
                    v-show="editInput === user.id"
                    ref="users"
                    v-model="user.note"
                    class="blocked-input text-texts-standard-additional flex items-center w-full text-28"
                  />
                  <app-mini-button
                    v-if="editInput !== user.id"
                    btn-type="primary"
                    btn-icon="edit"
                    @click="editNote(user.id, index)"
                  />
                  <app-mini-button
                    v-else
                    btn-type="secondary"
                    btn-icon="save"
                    @click="saveNote(user)"
                  />
                </div>
                <div
                  class="control-icon-box red cursor-pointer flex items-center"
                  @click="removeFromBlocked(user.id)"
                >
                  <div class="clickable-element control-icon control-icon-delete" />
                </div>
              </div>
            </div>
          </div>
        </app-scrollbar>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import AppMiniButton from '@/components/GlobalComponents/AppMiniButton.vue'
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import { mailBlockEndpoint, mailBlockedEndpoint } from '@/globalVariables'
import { getFormattedDateFromYMD, getFormattedTimeFromHMS } from '@/helpers'
import { defineComponent } from 'vue'

import type { Nullable } from '@/interfaces/utils'
import type { EventBadgeInterface } from '@/interfaces/EventBadgeInterface'
import type { DeleteMailBlockApiResponse } from '@/interfaces/responses/mail/DeleteMailBlockApiResponse'

interface BlockedMailUser {
  blocked_at: Date
  country: { name: string; two_letter_code: string; three_letter_code: string }
  event_badges: EventBadgeInterface[]
  id: string
  note: string
  username: string
}

interface ComponentData {
  editInput: Nullable<string>
  blockedUsers: BlockedMailUser[]
}

export default defineComponent({
  name: 'MailBlocked',
  components: {
    AppUser,
    AppMiniButton,
  },
  data(): ComponentData {
    return {
      editInput: null,
      blockedUsers: [],
    }
  },
  created(): void {
    this.loadBlockedUsers()
  },
  methods: {
    getFormattedDateFromYMD,
    getFormattedTimeFromHMS,
    editNote(id: string, index: number): void {
      this.editInput = id

      this.$refs.users[index].focus()
    },
    async loadBlockedUsers(): Promise<void> {
      this.blockedUsers = await this.$axios.get<{}, BlockedMailUser[]>(mailBlockedEndpoint)

      if (!this.blockedUsers || this.blockedUsers.length === 0) {
        this.$router.push({ name: this.$getWebView('MailReceived') })
      }
    },
    async saveNote(user: BlockedMailUser): Promise<void> {
      await this.$axios.post<
        {},
        Omit<BlockedMailUser, 'event_badges' | 'country'>,
        { blocked_user_id: string; note: string }
      >(mailBlockEndpoint, {
        blocked_user_id: user.id,
        note: user.note,
      })

      this.editInput = null
    },
    async removeFromBlocked(id: string): Promise<void> {
      try {
        await this.$axios.delete<{}, DeleteMailBlockApiResponse[]>(`${mailBlockEndpoint}/${id}`)
      } catch (error: unknown) {
        console.error(error)
      }
      this.loadBlockedUsers()
    },
  },
})
</script>
<style lang="scss" scoped>
.mail-blocked {
  width: 98%;
  margin: 0 auto;

  &-info {
    margin: 2.3rem 0;
  }

  &-row {
    height: 5.125rem;
    margin-top: 1rem;
    line-height: 2.2rem;
  }

  .table-standard.skew-right .table-standard-col {
    clip-path: polygon(2% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}

.head-user,
.col-user {
  width: 31rem;
}

.head-user {
  text-align: left;
  padding-left: 6.9rem;
}

.col-user {
  justify-content: left;
  padding-left: 2rem;

  &-flag {
    width: 3.063rem;
    height: 2rem;
    margin-right: 1.5rem;
    background-size: contain;
  }
}

.head-date,
.col-date {
  width: 30rem;
}

.head-note,
.col-note {
  width: 65.75rem;
}

.control-icon-box {
  width: 4.375rem;
  height: 4.375rem;
  border: solid 1px #6c9ec4;
  margin-right: 0.5rem;
  @if $isSsm {
    background: linear-gradient(to right, #e70000, #ff5b5b);
    border: 0.125rem solid #f59a8f;
  }
}

.control-icon {
  width: 4.375rem;
  height: 4.375rem;
  background-image: url($path-icons + 'control/control-icon-sprite.avif');
  background-size: cover;
  cursor: pointer;

  &-delete {
    background-position: -13.18rem 0;
  }
}

.blocked-input {
  padding: 1.875rem;
  height: 3.75rem;
  transform: $skewX-value;
  @if $isWsm {
    border: solid 0.125rem rgba(68, 122, 165, 0.36);
    background-color: #214b69;
  }
  @if $isSsm {
    border: solid 0.125rem #4c648f;
    background-color: #1d1f2c;
  }
  & > p {
    transform: $skewX-revert-value;

    &.has-placeholder {
      color: rgba(217, 236, 255, 0.5);
    }
  }

  &-wrapper {
    position: relative;
    width: 47.438rem;
    padding-left: 3.25rem;

    .mini-btn {
      position: absolute;
      right: -2rem;
    }
  }
}
</style>
