<template>
  <div
    v-if="tutorial && !isMinigame"
    :id="addId"
    v-tippy="{
      content: tooltipContent,
      placement: 'top',
      theme: 'WSM',
      showOnCreate: true,
      maxWidth: '23rem',
      interactive: true,
      onHide: () => false,
    }"
    class="arrow-wrapper relative"
    :class="[borderShadow, addClass]"
    :data-id="dataId"
  >
    <slot />
    <div class="arrows-group-animation" :class="`arrow-anim-${position}`" />
  </div>
  <slot v-else />
</template>

<script lang="ts">
import { useCoreStore } from '@/store/pinia/coreStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  endedAnimation: boolean
}

enum Direction {
  Right = 'right',
  Left = 'left',
  Top = 'top',
  Bottom = 'bottom',
  LeftBottom = 'left-bottom',
}

enum BorderStyle {
  Button = 'button',
  Box = 'box',
  Rounded = 'rounded',
  RoundedGP = 'rounded_gp',
  Default = 'default',
}

export default defineComponent({
  props: {
    position: {
      type: String,
      default: 'left',
      validator(value: string): boolean {
        return Object.values(Direction).includes(value as Direction)
      },
    },
    tutorial: {
      type: Boolean,
      default: false,
    },
    addClass: {
      type: String,
      default: '',
    },
    dataId: {
      type: String,
      default: '',
    },
    border: {
      type: String,
      default: 'default',
      validator(value: string): boolean {
        return Object.values(BorderStyle).includes(value as BorderStyle)
      },
    },
    // shadow: {
    //   type: Boolean,
    //   default: true,
    // },
    addId: {
      type: String,
      default: '',
    },
    // customStyle: {
    //   type: Object as PropType<Nullable<CSSProperty>>,
    //   default: () => null,
    // },
    // disableArrows: {
    //   type: Boolean,
    //   default: false,
    // },
    tooltipContent: {
      type: String,
      default: '',
    },
  },
  data(): ComponentData {
    return {
      endedAnimation: false,
    }
  },
  computed: {
    ...mapState(useCoreStore, ['isMinigame']),
    borderShadow(): string {
      if (!this.tutorial || this.endedAnimation) return ''

      switch (this.border) {
        case 'box':
          return 'box-border'
        case 'button':
          return 'button-border'
        case 'rounded':
          return 'rounded-border'
        case 'rounded_gp':
          return 'rounded-border-gp'
        case 'default':
          return 'default-border'
        default:
          return ''
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.arrows-group-animation {
  position: absolute;
  width: 10.625rem;
  height: 5.313rem;
  background: url($path-images + 'test/tutorial-arrows.webp') no-repeat;
  background-size: contain;
  z-index: 12;
  pointer-events: none;
}

.arrow {
  &-anim-top {
    left: 40%;
    bottom: -14rem;
    transform: rotate(90deg);
  }

  &-anim-bottom {
    left: 40%;
    top: -14rem;
    transform: rotate(-90deg);
  }

  &-anim-right {
    left: -12rem;
    top: 0%;
  }

  &-anim-left {
    right: -12rem;
    top: 0;
    transform: rotate(-180deg);
  }

  &-anim-left-bottom {
    left: -8rem;
    bottom: -8rem;
    transform: rotate(300deg);
  }
}

@import '@/assets/styles/components/arrow-animation.scss';
</style>
