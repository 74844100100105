<template>
  <div>
    <!-- info popup -->
    <popup-template
      v-if="popupType === 'info'"
      :class-list="[...classList, 'info-popup']"
      :popup-title="popupTitle"
      :hide-close="hideClose"
      @close="closePopup()"
    >
      <slot />
    </popup-template>

    <!-- confirm popup   :popup-content="popupData?.text"-->
    <popup-template
      v-if="popupType === 'confirm'"
      :class-list="[...classList, 'confirm-popup']"
      :popup-title="popupData?.title"
      :hide-close="hideClose"
      @close="closePopup()"
    >
      <div class="confirm-popup-wrapper">
        <div class="confirm-popup-wrapper-textfield flexing">
          <slot />
          <p v-show="popupData?.text" class="text-texts-standard-default text-36">
            {{ popupData?.text }}
          </p>
        </div>
        <app-multi-button1
          :btn-id="popupData?.btnId"
          btn-type="credit"
          btn-size="xl"
          :btn-text="popupData?.btnText"
          :btn-count="popupData?.btnValue"
          class="confirm-popup-wrapper-button"
          @click="$emit('action', $event)"
        />
      </div>
    </popup-template>

    <!-- submit popup -->
    <popup-template
      v-if="popupType === 'submit'"
      :class-list="[...classList, 'submit-popup']"
      :popup-title="popupData?.title"
      @close="closePopup()"
    >
      <div class="submit-popup-wrapper">
        <div class="submit-popup-wrapper-textfield flexing">
          <slot />
          <p v-show="popupData?.text" class="text-texts-standard-default text-36">
            {{ popupData?.text }}
          </p>
        </div>
        <div class="submit-popup-buttons flexing">
          <app-button
            :btn-id="popupData?.btnId"
            :btn-type="popupData?.btnType"
            :btn-text="submitBtnText"
            btn-size="md"
            @click="$emit('action', true)"
          />
          <app-button
            btn-id="submit-popup-button-back"
            btn-type="primary"
            :btn-text="$t('button.back')"
            btn-size="md"
            @click="closePopup()"
          />
        </div>
      </div>
    </popup-template>

    <!-- error popup -->
    <popup-template
      v-if="popupType === 'error'"
      :class-list="[...classList, 'error-popup']"
      :popup-title="'Chyba'"
      @close="closePopup()"
    />
  </div>
</template>

<script lang="ts">
import PopupTemplate from '@/components/Popup/PopupTemplate.vue'
import { CONFIRM_TYPE, ERROR_TYPE, INFO_TYPE, SUBMIT_TYPE } from '@/globalVariables'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'

interface PopupBtnData {
  title: string
  btnText: string
  btnType?: string
  btnId: string
  text: string
  btnValue?: string
}

export default defineComponent({
  name: 'PopupWindow',
  components: {
    PopupTemplate,
  },
  props: {
    popupType: {
      type: String,
      required: true,
      validator(value: string): boolean {
        return [INFO_TYPE, CONFIRM_TYPE, SUBMIT_TYPE, ERROR_TYPE].includes(value)
      },
    },
    classList: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    popupTitle: {
      type: String,
      default: '',
    },
    popupData: {
      type: Object as PropType<Nullable<PopupBtnData>>,
      default: () => null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'action'],
  computed: {
    unpackedClass(): string {
      return this.classList.join(',')
    },
    submitBtnText(): string {
      if (this.popupType === 'submit' && this.popupData?.btnText) {
        return this.popupData?.btnText
      } else {
        return this.$t('common.confirm')
      }
    },
  },

  methods: {
    closePopup(): void {
      this.$emit('close', false)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/popups.scss';

.scale-popup {
  transform: translate(-50%, -50%) scale(0.7);
}
</style>
