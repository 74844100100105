<template>
  <div
    class="watch-video-card text-texts-standard-default font-bold relative"
    :class="[`card-background-${addonType}`]"
  >
    <div v-if="availableAds.nextAdsCountdown" class="all-claimed absolute w-full h-full"></div>
    <div class="bonus">
      <div class="bonus-up flexing text-30 font-bold">
        <span class="relative top-0.5">{{ `${remainingAttempts}x` }}</span>
      </div>
    </div>
    <div :class="'absolute watch-video-card-img-' + addonType" />
    <section
      v-if="getReward?.value"
      :class="
        'watch-video-card-amount relative text-texts-standard-important text-60 flexing bg-' +
        addonType
      "
    >
      <p class="reward-label text-texts-standard-default text-28">
        {{ translateLabel(getRewardIcon) }}
      </p>
      <p>{{ `+${getReward?.value}` }}</p>
      <app-main-icon :icon-name="getRewardIcon" :icon-size="56" />
    </section>
    <footer class="gems-free-pack-footer absolute m-auto flex justify-center">
      <watch-for-reward
        :refresh-time="availableAds.nextAdsCountdown"
        :ad-type="getAdType"
        @reload-content="loadData()"
      />
    </footer>
  </div>
</template>

<script lang="ts">
import WatchForReward from '@/components/Premium/Accessories/WatchForReward.vue'
import {
  ENERGY,
  ENERGY_DRINK,
  STARTS,
  VIDEO_ADS_FREE_ENERGY,
  VIDEO_ADS_FREE_STARTS,
  WILD_CARD,
} from '@/globalVariables'
import { formatTime } from '@/helpers'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type Reward from '@/interfaces/Reward'

export default defineComponent({
  name: 'WatchVideoCard',
  components: {
    WatchForReward,
  },
  props: {
    addonType: {
      type: String,
      required: true,
      validator(value: string): boolean {
        return [ENERGY_DRINK, WILD_CARD].includes(value)
      },
    },
  },
  emits: ['reloadData'],
  computed: {
    ...mapState(usePremiumStore, {
      availableAds: 'getAvailableAds',
    }),
    getReward(): Reward {
      return this.availableAds.reward?.find(
        (reward: Reward): boolean => reward.name === this.getRewardIcon,
      )
    },
    getRewardIcon(): string {
      if (this.addonType === ENERGY_DRINK) {
        return ENERGY
      }
      return STARTS
    },
    getAdType(): string {
      if (this.addonType === ENERGY_DRINK) {
        return VIDEO_ADS_FREE_ENERGY
      }
      return VIDEO_ADS_FREE_STARTS
    },
    remainingAttempts(): number {
      return this.availableAds.availableAds ?? 0
    },
  },
  created(): void {
    this.loadAvailableAds(this.getAdType)
  },
  methods: {
    formatTime,
    ...mapActions(usePremiumStore, ['loadAvailableAds']),
    loadData(): void {
      this.$emit('reloadData')
      this.loadAvailableAds(this.getAdType)
    },
    translateLabel(name: string): string {
      return this.$t('map.' + name).length === 0 ? name : this.$t('map.' + name)
    },
  },
})
</script>

<style lang="scss" scoped>
.watch-video-card {
  width: 35.625rem;
  height: 19.1875rem;

  .bonus {
    position: absolute;
    top: 0.625rem;
    left: 0.625rem;

    &-up {
      min-width: 4.25rem;

      @if $isSsm {
        font-weight: bold;
        background: linear-gradient(320deg, #119780 10%, #0ee7cc);
      }

      @if $isWsm {
        background: linear-gradient(to top, #068f19, #3fe443);
        clip-path: polygon(100% -100%, 0% 0%, 0% 100%, 90% 100%);
        padding-right: 0.188rem;
      }
    }
  }

  footer {
    position: absolute;
    bottom: 1.5rem;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 2;
  }

  &-img-wild_card,
  &-img-energy_drink {
    width: 11.75rem;
    height: 12.438rem;
    right: 2rem;
    top: 2.125rem;
  }

  &-img-wild_card {
    background: url($path-premium + 'WildEnergy/watch-video-wild-icon.avif') center no-repeat;
    background-size: contain;
    z-index: 1;
  }

  &-img-energy_drink {
    background: url($path-premium + 'WildEnergy/watch-video-energy-icon.avif') center no-repeat;
    background-size: contain;
    z-index: 1;
  }

  &-amount {
    width: 25rem;
    height: 4.375rem;
    top: 7.5rem;

    &:before {
      content: '';
      position: absolute;
      width: 80%;
      height: 0.125rem;
      background: linear-gradient(to right, transparent, #bc91cf, transparent);
      top: 0;
    }
  }

  .bg-energy_drink {
    @if $isSsm {
      background: linear-gradient(to right, transparent, rgba(76, 100, 143, 1), transparent);
    }

    @if $isWsm {
      background: linear-gradient(to right, transparent, #cd0136bd, transparent);
    }
  }

  .bg-wild_card {
    @if $isSsm {
      background: linear-gradient(to right, transparent, rgba(76, 100, 143, 1), transparent);
    }

    @if $isWsm {
      background: linear-gradient(to right, transparent, rgba(132, 68, 160, 0.8), transparent);
    }
  }

  .reward-label {
    position: absolute;
    top: -2.75rem;
    text-transform: uppercase;
  }
}

.card-background-wild_card {
  background: url($path-premium + 'WildEnergy/watch-video-wild-bg.avif') center no-repeat;
  background-size: contain;
}

.card-background-energy_drink {
  background: url($path-premium + 'WildEnergy/watch-video-energy-bg.avif') center no-repeat;
  background-size: contain;
}

.all-claimed {
  background: rgb(4 8 4 / 25%);
  left: 0;
  z-index: 2;
  top: 0;
}
</style>
