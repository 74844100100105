import type { MapImageConfigInterface } from '@/map-phaser-new/interfaces'

export const mapImageConfig: MapImageConfigInterface = {
  textureKey: 'clubsMap',
  mapOverlaysConfig: [
    {
      textureKey: 'club-overlay-ships',
      depth: 1,
    },
    {
      textureKey: 'club-overlay-cars',
      depth: 3,
    },
  ],
}
