import { clubRequestDeclineAllEndpoint, clubRequestsEndpoint } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import type { ClubRequestsResponse } from '@/interfaces/responses/club/ClubRequestsResponses'
import type { ClubRequest } from '@/interfaces/clubs/ClubRequests'
import type { ClubFilter } from '@/interfaces/clubs/Clubs'
import { ClubRequestType } from '@/enums/ClubRequestTypes'

interface ClubRequestsState {
  requests: ClubRequest[]
}

export const useClubRequestsStore = defineStore('requestStore', {
  state: (): ClubRequestsState => ({
    requests: [] as ClubRequest[],
  }),
  getters: {
    getRequests(): ClubRequestsState['requests'] {
      return this.requests
    },
  },
  actions: {
    async loadRequests(filter: ClubFilter = {}): Promise<void> {
      let requestsBody = {
        request_types: 'request',
      }
      if (filter) {
        requestsBody = { ...requestsBody, ...filter }
      }
      const data = await internalAxios.post<{}, ClubRequestsResponse[]>(
        clubRequestsEndpoint,
        requestsBody,
      )
      const mutatedData = data.map((request: ClubRequestsResponse): ClubRequest => {
        return {
          id: request.id,
          userId: request.oauth_user_games_id,
          country: request.country.three_letter_code,
          name: request.username,
          level: request.level,
          rankingPoints: request.ranking_points,
          prestige: request.prestige,
          eventBadges: request.event_badges ?? null,
        }
      })
      this.requests = mutatedData
    },
    async acceptRequest(id: string = ''): Promise<void> {
      try {
        await internalAxios.post<{}, string>(`${clubRequestsEndpoint}/accept`, {
          request_id: id,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async declineRequest(id: string = ''): Promise<void> {
      try {
        await internalAxios.post<{}, string>(`${clubRequestsEndpoint}/decline`, {
          request_id: id,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async declineAllRequests(): Promise<void> {
      try {
        await internalAxios.post<{}, string>(clubRequestDeclineAllEndpoint, {
          request_type: ClubRequestType.Request,
        })
      } catch (error: unknown) {
        console.error(error)
      }
      this.requests = []
    },
  },
})
