<template>
  <div class="warehouse-box bordered-1 flex flex-col">
    <div class="icon-area">
      <div class="info-icon-triangle cursor-pointer clickable-element" @click="showInfoPopup">
        <info-popup
          v-if="showPopup"
          :popup-title="$t('grandPrize.dropRatesHeader')"
          @close="showPopup = false"
        >
          <grand-prize-popup :level="level" />
        </info-popup>
      </div>
      <div class="flex justify-items-center" :class="'gp-badge-rounded-' + badgeType" />
      <div v-if="!disableButton" class="points-area flexing">
        <p class="text-40 text-texts-standard-dark font-bold">
          {{ numberOfRewards >= 99 ? '99+' : numberOfRewards }}
        </p>
      </div>
    </div>
    <div class="buttons-area" :class="{ 'warehouse-box-disabled': disableButton }">
      <arrow-animation
        position="left"
        border="button"
        :add-class="isTutorialStep ? 'wrapper-anim' : ''"
        :tutorial="isTutorialAndBadge"
      >
        <app-button
          v-tippy="disableButton ? { trigger: 'click', theme: 'WSM' } : {}"
          btn-type="secondary"
          :btn-text="$t('grandPrize.open')"
          btn-id="warehouse-box-button"
          class="warehouse-box-button"
          :class="{
            'button-anim': isTutorialStep,
          }"
          :content="
            disableButton
              ? $replaceUntranslatablePlaceholder($t('grandPrize.gpNotFound') + '.', '{grandprize}')
              : ''
          "
          @click="buttonClicked()"
        />
      </arrow-animation>
      <p class="text-30 text-texts-standard-default uppercase not-italic">
        {{ $t('grandPrize.openInstant') }}
      </p>
      <app-button
        v-tippy="disableButton ? { trigger: 'click', theme: 'WSM' } : {}"
        btn-type="credit"
        class="warehouse-box-button"
        :content="
          disableButton
            ? $replaceUntranslatablePlaceholder($t('grandPrize.gpNotFound') + '.', '{grandprize}')
            : ''
        "
        :disabled="instaOpenGems > userGems.value"
        @click="buttonClicked(instaOpenGems)"
      >
        <div class="flex items-center justify-center">
          <p>
            {{ instaOpenGems }}
          </p>
          <app-main-icon :icon-size="56" icon-name="gems" />
        </div>
      </app-button>
    </div>
  </div>
</template>

<script lang="ts">
import ArrowAnimation from '@/components/ArrowAnimation.vue'
import GrandPrizePopup from '@/components/GrandPrize/GrandPrizePopup.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { playSound } from '@/helpers'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  showPopup: boolean
}

export default defineComponent({
  name: 'GrandPrizeCard',
  components: {
    InfoPopup,
    GrandPrizePopup,
    ArrowAnimation,
  },
  props: {
    badgeType: {
      type: String,
      required: true,
    },
    numberOfRewards: {
      type: Number,
      required: true,
    },
    instaOpenGems: {
      type: Number,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
  },
  emits: ['buttonsAction'],
  data(): ComponentData {
    return {
      showPopup: false,
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
    }),
    ...mapState(useResponseTaskStore, {
      userGems: 'getGems',
    }),
    disableButton(): boolean {
      return this.numberOfRewards === 0
    },
    isTutorialStep(): boolean {
      return this.actualStageData && this.actualStageData.name === 'clickOnOpenGrandPrize'
    },
    isTutorialAndBadge(): boolean {
      return this.isTutorialStep && this.badgeType === 'basic'
    },
  },
  methods: {
    buttonClicked(gems?: number): void {
      if (this.disableButton) return
      if (gems == null) playSound('action-daily-rewards')
      this.$emit('buttonsAction', this.badgeType, gems)
    },
    showInfoPopup(): void {
      this.showPopup = true
    },
  },
})
</script>

<style scoped lang="scss">
.warehouse-box {
  height: 32.438rem;
  width: 22.125rem;
  margin: 1.25rem 0rem;
  background-size: contain;
  border-style: solid;
  border-width: 0.125rem;
  border-image-source: linear-gradient(to top, #c38816, #ffe98e, #ffffff);
  border-image-slice: 1;

  &-button {
    width: 19.938rem;
    height: 4.375rem;
    margin: 0.938rem 1rem 1rem 1rem;
  }

  &-disabled {
    opacity: 0.6;
  }
}

.icon-area {
  height: 16.75rem;
  background: url($path-grand-prize + 'warehouse/BG_CONTENT_BOX_TOP.avif') center no-repeat;
  background-size: cover;

  .points-area {
    width: 4.313rem;
    height: 3.625rem;
    position: absolute;
    bottom: 16.5rem;
    left: 13.8rem;
    transform: $skew-value;
    background-size: cover;

    @if $isWsm {
      background-image: linear-gradient(to top, #ffe868, #ffec4f);
    }

    @if $isSsm {
      background-image: linear-gradient(to right, #daab36, #ffdf91);
      border-style: solid;
      border-width: 0.125rem;
      border-image-source: linear-gradient(to right, #daab36, #ffdf91);
      border-image-slice: 1;
    }

    p {
      transform: $skew-revert-value;
    }
  }

  [class*='gp-badge-rounded'] {
    width: 15.375rem;
    height: 17.875rem;
    background-image: url($path-grand-prize + 'badges-rounded/SPRITE_BADGE_ROUNDED_SHINE_246x286_2.avif');
    background-size: 30rem;
    margin: auto;
  }

  .gp-badge-rounded-basic {
    background-position: 0rem 0rem;
  }

  .gp-badge-rounded-gold {
    background-position: -15rem 0rem;
  }

  .gp-badge-rounded-bronze {
    background-position: 0rem -17rem;
  }

  .gp-badge-rounded-diamond {
    background-position: -15rem -17rem;
  }

  .gp-badge-rounded-silver {
    background-position: 0rem -34rem;
  }
}

.buttons-area {
  height: 15.688rem;

  @if $isWsm {
    background-color: #0c304a;
  }
}

.wrapper-anim {
  width: 19.938rem !important;
  height: 4.375rem;
  margin-left: 1rem !important;
}

.button-anim {
  margin: 0.938rem 1rem 1rem 0;
}
</style>
