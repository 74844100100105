import type { EventBadgeInterface } from '@/interfaces/EventBadgeInterface'
import type { ClubFilter } from './Clubs'
import { CLUB_MANAGER, CLUB_ASSISTANT, CLUB_MEMBER } from '@/globalVariables'

export interface ClubUserPromoted {
  status: boolean
  user: {
    country: string
    name: string
    role?: string
  }
}

export interface ClubMember {
  clubId: string
  pos: number
  userId: string
  onlineStatus: string
  offlineThreshold: number
  name: {
    name: string
    country: string
    role: string
  }
  level: number
  prestige: number
  championshipPoints: number
  tournamentTitles: number
  eventBadges: EventBadgeInterface[]
}

export interface ClubMembersInfoBody {
  clubId?: string
  filter?: { bodyContent: ClubFilter }
}

export enum ClubMemberRoles {
  Manager = CLUB_MANAGER,
  Assistant = CLUB_ASSISTANT,
  Member = CLUB_MEMBER,
}
