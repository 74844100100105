<template>
  <section
    :class="[
      { masters: tournamentRowConfig.is_master_playoff },
      tournamentRowConfig.state,
      activeStateOfPlay,
      finalState,
    ]"
    class="master-tournament-row"
  >
    <section class="tour-info">
      <tournament-icon />
      <tournament-information
        :active-state="tournamentRowConfig.state"
        :join-status="tournamentRowConfig.join_status"
        :description="createDescription"
        :is-masters="tournamentRowConfig.is_master_playoff"
        :title="$te(tournamentRowConfig.playoff_name)"
      />
    </section>
    <section class="tour-info">
      <tournament-timer
        :active-state="tournamentRowConfig.state"
        :start-date="tournamentRowConfig.start_date"
        :end-date="tournamentRowConfig.end_date"
        :draw-state="tournamentRowConfig.draw_state"
        :join-status="tournamentRowConfig.join_status"
        :is-recalculating="tournamentRowConfig.is_recalculating"
        @get-data="$emit('get-data')"
      />
      <tournament-cta
        :draw-state="tournamentRowConfig.draw_state"
        :elimination-reason="tournamentRowConfig.elimination_reason"
        :tournament-id="tournamentRowConfig.playoff_id"
        :is-winning="activeStateOfPlay !== '' && activeStateOfPlay !== 'is-losing'"
        :qualification-position="tournamentRowConfig.qualification_position"
        :ranking-position="tournamentRowConfig.ranking_position"
        :join-status="tournamentRowConfig.join_status"
        :is-recalculating="tournamentRowConfig.is_recalculating"
        :tournament-status="tournamentRowConfig.state"
        :not-qualified="tournamentRowConfig.qualification_position < 3"
        :is-masters="tournamentRowConfig.is_master_playoff"
        :final-state="finalState"
        @join-tournament="joinTournament"
      />
    </section>
  </section>
</template>

<script lang="ts">
import { participateEvent } from '@/globalVariables'
import {
  ActiveState,
  JoinStatus,
  type MastersSetupObject,
} from '@/interfaces/events/MastersPlayoffInterfaces'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import TournamentCta from './RowComponents/TournamentCta.vue'
import TournamentIcon from './RowComponents/TournamentIcon.vue'
import TournamentInformation from './RowComponents/TournamentInformation.vue'
import TournamentTimer from './RowComponents/TournamentTimer.vue'
import type { PlayoffType } from '@/interfaces/events/Playoff'

interface MappedArray {
  [key: string]: string
}

interface JoinTournamentResponse {
  mechanic: PlayoffType.Masters
  participating: boolean
  playoff_id: number
}

interface ComponentData {
  isCalling: boolean
}

export default defineComponent({
  name: 'MasterTournamentRow',
  components: {
    TournamentIcon,
    TournamentInformation,
    TournamentTimer,
    TournamentCta,
  },
  props: {
    tournamentRowConfig: {
      type: Object as PropType<MastersSetupObject>,
      required: true,
    },
  },
  emits: ['get-data'],
  data(): ComponentData {
    return {
      isCalling: false,
    }
  },
  computed: {
    createDescription(): string {
      const setupObject: MappedArray = this.tournamentRowConfig.task.parameters.reduce(
        (acc: MappedArray, val: MastersSetupObject['task']['parameters'][0]): MappedArray => {
          acc[val.name] = val.value
          return acc
        },
        {},
      )
      return (
        this.$replacePlaceholder(
          this.$te(setupObject['translation_key']),
          '{discipline}',
          this.$translateDiscipline(setupObject.discipline_id),
        ) || 'unknown'
      )
    },
    activeStateOfPlay(): string {
      if (
        (this.tournamentRowConfig.state !== ActiveState.Qualification &&
          this.tournamentRowConfig.state !== ActiveState.Draw) ||
        this.tournamentRowConfig.join_status === JoinStatus.NotJoined
      )
        return ''
      if (this.tournamentRowConfig?.player_advancing) {
        return 'is-winning'
      }
      return 'is-losing'
    },
    finalState(): string {
      if (
        this.tournamentRowConfig.state === ActiveState.Ended &&
        this.tournamentRowConfig.join_status === JoinStatus.Joined &&
        !this.tournamentRowConfig.is_recalculating
      ) {
        if (this.tournamentRowConfig.elimination_reason) {
          return 'loss'
        } else {
          return 'won'
        }
      }
      return ''
    },
  },
  methods: {
    async joinTournament(): Promise<void> {
      if (this.isCalling) return
      this.isCalling = true
      try {
        const res = await this.$axios.post<{}, JoinTournamentResponse>(participateEvent, {
          playoff_id: this.tournamentRowConfig.playoff_id,
        })
        if (res.participating === true) {
          this.$emit('get-data')
        }
      } catch (error: unknown) {
        console.error(error)
      }
      this.isCalling = false
    },
  },
})
</script>

<style lang="scss" scoped>
.master-tournament-row {
  color: white;
  width: 100%;
  height: 8.875rem;
  padding-left: 0.75rem;
  margin: 0.813rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:nth-child(even) {
    @if $isWsm {
      background-color: #0c253d;
    }

    @if $isSsm {
      background-color: rgba(61, 76, 117, 0.9);
    }

    &.loss {
      @if $isWsm {
        background-color: #501618;
      }

      @if $isSsm {
        background-color: rgba(80, 22, 24, 0.9);
      }
    }

    &.won {
      @if $isWsm {
        background-color: #0b3e19;
      }

      @if $isSsm {
        background-color: rgba(11, 62, 25, 0.9);
      }
    }
  }

  &:nth-child(odd) {
    @if $isWsm {
      background-color: #091f35;
    }

    @if $isSsm {
      background-color: rgba(45, 58, 90, 0.9);
    }

    &.loss {
      @if $isWsm {
        background-color: #501618;
      }

      @if $isSsm {
        background-color: rgba(80, 22, 24, 0.9);
      }
    }

    &.won {
      @if $isWsm {
        background-color: #0b3e19;
      }

      @if $isSsm {
        background-color: rgba(11, 62, 25, 0.9);
      }
    }
  }

  &.is-winning {
    border-left: solid 5px #127904;
  }

  &.is-losing {
    border-left: solid 5px #941c1c;
  }

  &.masters {
    @include background(
      url($path-events + '/frozen-championship/playoff-ladder/BG_TABLE_GOLD.avif'),
      cover
    );

    &.is-losing {
      @include background(
        url($path-events + '/frozen-championship/playoff-ladder/BG_TABLE_RED.avif'),
        cover
      );
    }

    &.is-winning {
      @include background(
        url($path-events + '/frozen-championship/playoff-ladder/BG_TABLE_GREEN.avif'),
        cover
      );
    }
  }

  .tour-info {
    display: flex;
    height: 100%;
    align-items: center;
  }
}
</style>
