import {
  shopItemRepairEndPoint,
  shopItemUpgradeEndPoint,
  shopOfferSpeedupEndPoint,
} from '@/globalVariables'
import type EquipmentSpeedUpRepairApiResponse from '@/interfaces/responses/equipment/EquipmentSpeedUpRepairApiResponse'
import type EquipmentSpeedUpUpgradeApiResponse from '@/interfaces/responses/equipment/EquipmentSpeedUpUpgradeApiResponse'
import type EquipmentSpeedUpOfferApiResponse from '@/interfaces/responses/equipment/EquipmentSpeedUpOfferApiResponse'
import { internalAxios } from '@/plugins/vueAxios'
import type { Nullable } from '@/interfaces/utils'
import { defineStore } from 'pinia'

export const useEquipmentSpeedUpStore = defineStore('equipmentSpeedUpStore', {
  actions: {
    async repairSpeedup(equipmentId: string): Promise<void> {
      try {
        await internalAxios.put<
          {},
          EquipmentSpeedUpRepairApiResponse,
          {
            item_id: string
            speedup: true
          }
        >(shopItemRepairEndPoint, {
          item_id: equipmentId,
          speedup: true,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async upgradeSpeedup(data: { itemId: number; addonId?: number }): Promise<void> {
      try {
        await internalAxios.put<
          {},
          EquipmentSpeedUpUpgradeApiResponse,
          {
            item_id: number
            upgrade_id: Nullable<number>
            speedup: true
          }
        >(shopItemUpgradeEndPoint, {
          item_id: data.itemId,
          upgrade_id: data.addonId ?? null,
          speedup: true,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async offerSpeedup(offerId: string): Promise<void> {
      try {
        await internalAxios.put<{}, EquipmentSpeedUpOfferApiResponse, { offer_id: string }>(
          shopOfferSpeedupEndPoint,
          { offer_id: offerId },
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
