import {
  FlashNewsEndpoint as FLASH_NEWS_ENDPOINT,
  FlashNewsClaimEndpoint as FLASH_NEWS_CLAIM_ENDPOINT,
} from '@/globalVariables'
import type { Reward } from '@/interfaces/responses/premium/PremiumLevelBagsApiResponse'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import { ApiService } from '@/services/ApiService'

interface FlashNewsMessage {
  id: number
  text: string
  reward?: Reward
}

interface FlashNewsResponse {
  flash_news: FlashNewsMessage[]
}

interface FlashNewsClaimRequest {
  id: FlashNewsMessage['id']
}

type FlashNewsClaimResponse = []

interface FlashNewsState {
  messages: FlashNewsMessage[]
}

export const useFlashNewsStore = defineStore('flashNews', {
  state: (): FlashNewsState => ({
    messages: null,
  }),
  getters: {
    getMessage(): FlashNewsState['messages'][0] | null {
      return this.messages?.[0] ?? null
    },
    getShowFlashNewsMessage(): boolean {
      return !!this.messages?.length
    },
  },
  actions: {
    async loadFlashNews(): Promise<void> {
      try {
        this.messages =
          (await ApiService.get<FlashNewsResponse>(FLASH_NEWS_ENDPOINT))?.flash_news ?? null
      } catch (error: unknown) {
        return Promise.reject(error)
      }
    },
    async claimFlashNewsMessageReward(id: FlashNewsMessage['id']): Promise<void> {
      try {
        await internalAxios.post<{}, FlashNewsClaimResponse, FlashNewsClaimRequest>(
          FLASH_NEWS_CLAIM_ENDPOINT,
          { id },
        )
        this.hideFlashNewsMessage(id)
      } catch (error: unknown) {
        return Promise.reject(error)
      }
    },
    hideFlashNewsMessage(id: FlashNewsMessage['id']): void {
      this.messages = this.messages.filter(
        (message: FlashNewsMessage): boolean => message.id !== id,
      )
    },
  },
})
