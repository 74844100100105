<template>
  <div id="show-more-content" data-cy="show-more-content" class="show-more-content">
    <div class="smc-title" :class="{ open: showMore }" @click="showMore = !showMore">
      <p
        class="text-36 non-italic uppercase"
        :class="$isWsm ? 'text-texts-standard-additional' : 'text-texts-standard-important'"
      >
        {{ title }}
      </p>
      <div
        class="smc-triangle triangle white"
        :class="showMore ? 'triangle-up' : 'triangle-down'"
      />
    </div>
    <transition name="slidedown">
      <div v-show="showMore" class="smc-description">
        <aside v-if="descriptionType === 1">
          <p class="text-texts-standard-important text-32 not-italic">
            {{ descriptionTitle }}
          </p>
          <p class="text-texts-standard-default text-30 not-italic">
            {{ descriptionText }}
          </p>
        </aside>
        <aside v-else class="smc-description-type2">
          <div
            v-for="(desc, index) in descData"
            :key="index"
            class="smc-description-type2-wrapper flexing"
          >
            <p
              v-if="desc.descTitle"
              class="text-texts-standard-default text-32 not-italic bold smc-title-width"
            >
              {{ $t(desc.descTitle) }}
            </p>
            <p
              v-if="desc.descTitle"
              class="text-texts-standard-default text-32 not-italic mx-4 smc-dash-width"
            >
              -
            </p>
            <aside class="smc-text-width" :class="{ 'full-row': !desc.descTitle }">
              <div class="text-texts-standard-default text-30 not-italic">
                <section v-if="isFacebook">
                  <span
                    v-show="!desc.descTitle"
                    v-html="
                      '● ' +
                      $replaceLinkPlaceholder(
                        $t(desc.descText),
                        FacebookUrl,
                        'text-texts-standard-important font-bold',
                      )
                    "
                  />
                  <br v-show="desc.descText1" />
                  <span v-show="!desc.descTitle && desc.descText1">●</span>
                  {{ setPlaceholders(desc, desc.descText1) }} <br v-show="desc.descText2" />
                  <span
                    v-show="!desc.descTitle2"
                    v-html="
                      '● ' +
                      $replaceLinkPlaceholder(
                        $t(desc.descText2),
                        FacebookUrl,
                        'text-texts-standard-important font-bold',
                      )
                    "
                  />
                  <br v-show="desc.descText1" />
                </section>
                <section v-else>
                  <span v-show="!desc.descTitle">●</span>
                  {{ setPlaceholders(desc, desc.descText) }} <br v-show="desc.descText1" />
                  <span v-show="!desc.descTitle && desc.descText1">●</span>
                  {{ setPlaceholders(desc, desc.descText1) }} <br v-show="desc.descText2" />
                  <span v-show="!desc.descTitle && desc.descText2">●</span>
                  {{ setPlaceholders(desc, desc.descText2) }}
                </section>
              </div>
            </aside>
          </div>
        </aside>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { FacebookUrl } from '@/globalVariables'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface ComponentData {
  showMore: boolean
  FacebookUrl: typeof FacebookUrl
}

export default defineComponent({
  name: 'ShowMoreContent',
  props: {
    title: {
      type: String,
      required: true,
    },
    descData: {
      // TODO prerobit
      type: Array as PropType<
        {
          descTitle?: string
          descTitle2?: string
          descText?: string
          descText1?: string
          descText2?: string
          replaceDesc?: string | number
          replaceDesc1?: string | number
        }[]
      >,
      default: () => [],
    },
    descriptionType: {
      type: Number,
      default: 1,
    },
    descriptionTitle: {
      type: String,
      default: '',
    },
    descriptionText: {
      type: String,
      default: '',
    },
    isFacebook: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      showMore: false,
      FacebookUrl,
    }
  },
  methods: {
    // TODO prerobit komponent
    setPlaceholders(item, text: string): string {
      if (!item || !text) return ''
      if (item.replaceDesc) {
        return this.$replacePlaceholder(this.$t(text), /{grandprize}|%s/gi, item.replaceDesc)
      } else if (item.replaceDesc1) {
        return this.$replacePlaceholder(this.$t(text), /{grandprize}|%s/gi, item.replaceDesc1)
      } else if (item.replaceDesc2) {
        return this.$replacePlaceholder(this.$t(text), /{grandprize}|%s/gi, item.replaceDesc2)
      } else return this.$t(text)
    },
  },
})
</script>

<style lang="scss" scoped>
.show-more-content {
  margin-bottom: 1.25rem;

  .smc-title {
    width: 100%;
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.25rem;
    cursor: pointer;
    @if $isWsm {
      background-color: #09172a;
    }
    @if $isSsm {
      background-color: #172341;
      border: solid 0.063rem #636790;
    }

    &.open {
      @if $isWsm {
        border: solid 0.063rem #5ba3dc;
        background-image: linear-gradient(to right, #154e79, #09172a);
      }
    }

    .smc-triangle {
      margin-right: 1.25rem;
    }
  }

  .smc-description {
    width: 99%;
    margin: 0 auto;
    padding: 1.188rem 1.438rem;
    text-align: left;
    @if $isWsm {
      background: rgba(9, 23, 42, 0.7);
      border: solid 0.063rem #6c9ec4;
      border-top: none;
    }
    @if $isSsm {
      background: rgba(#172341, 0.5);
    }

    &-type2 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-wrapper {
        width: 100%;
        margin: 1rem 0;
      }

      .smc-title-width {
        width: 9%;
      }

      .smc-dash-width {
        width: 1%;
      }

      .smc-text-width {
        width: 90%;

        &.full-row {
          width: 100%;
        }
      }
    }
  }

  .slidedown-enter-active,
  .slidedown-leave-active {
    transition: max-height 0.1s ease-in-out;
  }

  .slidedown-enter-to,
  .slidedown-leave-from {
    overflow: hidden;
    max-height: 60rem;
  }

  .slidedown-enter-from,
  .slidedown-leave-to {
    overflow: hidden;
    max-height: 0;
  }

  .slide-enter,
  .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }
}
</style>
