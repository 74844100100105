import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const flyingHelicopterPath: PathObjectInterface = {
  mainPath: {
    x: 1170,
    y: 1600,
  },
  nextPaths: [
    {
      x: 2190,
      y: 890,
    },
    {
      x: 2250,
      y: 830,
      control1X: 2239,
      control1Y: 863,
      control2X: 2239,
      control2Y: 863,
    },
    {
      x: 2400,
      y: -100,
    },
  ],
}
