<template>
  <div id="club-chat" class="club-chat app-page-wrapper absolute flex flex-col">
    <menu-component menu-type="chat" :title="$t('club.chat')">
      <chat-online-players
        :online-players-count="onlineMembers.length"
        :all-players-default="allPlayersDefault"
      />
    </menu-component>
    <section class="club-chat-content safe-area">
      <main>
        <chat-unavailable v-if="!hasClub" />
        <section v-else class="flex flex-col w-full h-full mx-4">
          <chat-history
            v-if="communication.length > 0"
            class="chat-history"
            :communication="communication"
          />
          <chat-no-messages v-else />
          <chat-new-message @send-message="sendMessage" />
        </section>
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import ChatHistory from '@/components/Club/Chat/ChatHistory.vue'
import ChatNewMessage from '@/components/Club/Chat/ChatNewMessage.vue'
import ChatNoMessages from '@/components/Club/Chat/ChatNoMessages.vue'
import ChatOnlinePlayers from '@/components/Club/Chat/ChatOnlinePlayers.vue'
import ChatUnavailable from '@/components/Club/Chat/ChatUnavailable.vue'
import ioService from '@/socket/SocketService'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { ClubChatMessage } from '@/interfaces/clubs/Clubs'

interface ComponentData {
  communication: ClubChatMessage[]
  onlineMembers: any[] // TODO FIXME zistit a doplnit spravny typ
}

export default defineComponent({
  name: 'ClubsChat',
  components: {
    ChatHistory,
    ChatOnlinePlayers,
    ChatUnavailable,
    ChatNoMessages,
    ChatNewMessage,
  },
  data(): ComponentData {
    return {
      communication: [],
      onlineMembers: [],
    }
  },
  computed: {
    ...mapState(useUserStore, {
      hasClub: 'getHasClub',
      userId: 'getUserId',
    }),
    allPlayersDefault(): string {
      return 'ONLINE'
    },
  },
  mounted(): void {
    if (!this.hasClub) return
    this.setupListeners()
    this.onVisibilityChange()

    document.addEventListener('visibilitychange', this.onVisibilityChange, { passive: true })
  },
  beforeUnmount(): void {
    document.removeEventListener('visibilitychange', this.onVisibilityChange)
  },
  methods: {
    onVisibilityChange(): void {
      if (document.visibilityState === 'hidden') return

      ioService.socket.emit('getAllMessages', { userGameId: this.userId })
      ioService.socket.emit('getOnlineMembers', { userGameId: this.userId })
    },
    updateActiveStatus(): void {
      this.communication = this.communication.map((com: ClubChatMessage): ClubChatMessage => {
        com.active = this.onlineMembers.includes(com.userId)
        return com
      })
    },
    async sendMessage(message: string): Promise<void> {
      await ioService.socket.emit('sendMessage', {
        userGameId: this.userId,
        text: message,
      })
    },
    scrollToEnd(): void {
      const container = this.$el.querySelector('#club-chat-scroll')

      if (container) {
        container.scrollTop = container.scrollHeight
      }
    },
    setupListeners() {
      // TODO FIXME zistit a doplnit spravny typ zo socketov
      ioService.socket.on('newMessage', (data) => {
        if (data.userId === this.userId) {
          this.communication.push({
            message: data.text,
            sender: 'me',
            senderCountry: data.senderCountry,
            senderName: data.senderName,
            senderAvatar: Number(data.senderAvatar),
            senderAvatarBackground: Number(data.senderAvatarBackground),
            sex: data.sex,
            userId: data.userId,
            active: true,
            date: data.date,
          })
        } else {
          this.communication.push({
            sender: 'you',
            message: data.text,
            senderCountry: data.senderCountry,
            senderName: data.senderName,
            senderAvatar: Number(data.senderAvatar),
            senderAvatarBackground: Number(data.senderAvatarBackground),
            sex: data.sex,
            userId: data.userId,
            active: true,
            date: data.date,
          })
        }

        this.$nextTick(() => {
          this.scrollToEnd()
        })
      })
      ioService.socket.on('joined', () => ioService.socket.emit('getAllMessages'))
      ioService.socket.on('allMessages', (data) => {
        this.communication = data.map((message) => {
          return {
            ...message,
            message: message.text,
            sender: message.userId === this.userId ? 'me' : 'you',
          }
        })
        this.updateActiveStatus()
        this.$nextTick(() => {
          this.scrollToEnd()
        })
      })
      ioService.socket.on('onlineMembers', (data) => {
        this.onlineMembers = data
        this.updateActiveStatus()
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.club-chat {
  &-content {
    width: 100%;
    height: 100%;
    min-height: 0;
  }

  main {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    justify-content: center;

    .chat-history {
      margin-top: 1.25rem;
    }
  }
}
</style>
