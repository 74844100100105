<template>
  <div class="p-offers app-page-wrapper flex flex-col absolute">
    <premium-menu />
    <section class="w-full h-full safe-area">
      <main v-if="!isContentLoading" class="p-offers-main w-full flex items-center">
        <app-scrollbar
          class="p-offers-main-scroll flex m-auto"
          width="98%"
          height="46.625rem"
          scroll="x"
          slide="x"
          :arrows="true"
          arrow-pos="arrow-position"
          :scroll-arrows-safe-zone-indented="true"
        >
          <offers-chain v-if="chainData" :offer-data="chainData" @reload-data="reloadOffers" />
          <offers-box v-for="(big, index) in filteredBigOffers" :key="index" :offer-data="big" />
          <offers-tournament-pass
            v-if="tournamentPassData"
            :offer-data="tournamentPassData"
            @reload-data="reloadOffers"
          />
          <offers-game-pass
            v-if="gamePassData"
            :offer-data="gamePassData"
            @reload-data="reloadOffers"
          />
          <offers-piggy-bank
            v-if="piggyBankData"
            :offer-data="piggyBankData"
            @reload-data="reloadOffers"
          />
          <offers-bags v-if="bagsData" :offer-data="bagsData" />
          <offers-smaller-box
            v-for="(small, i) in smallOffers"
            :key="i + 100"
            :offer-data="small"
          />
        </app-scrollbar>
      </main>

      <component-loading height="71%" :is-loading="isContentLoading" />
    </section>
  </div>
</template>

<script lang="ts">
import OffersBags from '@/components/Premium/Offers/OffersBags.vue'
import OffersGamePass from '@/components/Premium/Offers/OffersGamePass.vue'
import OffersPiggyBank from '@/components/Premium/Offers/OffersPiggyBank.vue'
import OffersBox from '@/components/Premium/Offers/OffersBox.vue'
import OffersChain from '@/components/Premium/Offers/OffersChain.vue'
import OffersSmallerBox from '@/components/Premium/Offers/OffersSmallerBox.vue'
import PremiumMenu from '@/components/Premium/PremiumMenu.vue'
import OffersTournamentPass from '@/components/Premium/Offers/OffersTournamentPass.vue'
import {
  LEGENDARY_CHAIN,
  LEVEL_BAGS,
  GAME_PASS,
  PIGGY_BANK,
  TOURNAMENT_PASS,
} from '@/globalVariables'
import { playSound } from '@/helpers'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { Offer, OfferChainData, Property } from '@/interfaces/premium/Offer'

interface ComponentData {
  position: string
}

export default defineComponent({
  name: 'PremiumOffers',
  components: {
    PremiumMenu,
    OffersBox,
    OffersSmallerBox,
    OffersChain,
    OffersBags,
    OffersGamePass,
    OffersPiggyBank,
    OffersTournamentPass,
  },
  data(): ComponentData {
    return {
      position: '',
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      smallOffers: 'getSmallOffers',
      bigOffers: 'getBigOffers',
    }),
    isContentLoading(): boolean {
      return this.bigOffers.length === 0 && this.smallOffers.length === 0
    },
    gamePassData(): Offer {
      const gamePassOffer = this.bigOffers.find((offer: Offer): boolean =>
        offer.category.includes(GAME_PASS),
      )
      if (!gamePassOffer) return null
      let offerData = gamePassOffer
      if (gamePassOffer.category) {
        offerData.type = gamePassOffer.category
      }
      return offerData
    },
    piggyBankData(): Offer {
      const piggyBankOffer = this.bigOffers.find((offer: Offer): boolean =>
        offer.category.includes(PIGGY_BANK),
      )
      if (!piggyBankOffer) return null
      let offerData = piggyBankOffer
      if (piggyBankOffer.category) {
        offerData.type = piggyBankOffer.category
      }
      return offerData
    },
    bagsData(): Offer {
      const bagsOffer = this.bigOffers.find((offer: Offer): boolean =>
        offer.category.includes(LEVEL_BAGS),
      )
      if (!bagsOffer) return null
      let offerData = bagsOffer
      if (bagsOffer.category) {
        offerData.type = bagsOffer.category
      }
      return offerData
    },
    tournamentPassData(): Offer {
      const bagsOffer = this.bigOffers.find((offer: Offer): boolean =>
        offer.category.includes(TOURNAMENT_PASS),
      )
      if (!bagsOffer) return null
      let offerData = bagsOffer
      if (bagsOffer.category) {
        offerData.type = bagsOffer.category
      }
      return offerData
    },
    chainData(): OfferChainData {
      const chainOffer = this.bigOffers.find((offer: Offer): boolean =>
        offer.category.includes(LEGENDARY_CHAIN),
      )
      if (!chainOffer) return null

      const chainOfferProperties = chainOffer.properties as Property
      const offerData: OfferChainData = {
        // INFO: Property[] => properties: Object { content: "value" }
        content: (chainOfferProperties?.content as string) ?? '',
      }
      if (!offerData) return
      if (chainOffer.category && offerData?.type) {
        offerData.type = chainOffer.category
      }
      if (chainOffer.remaining_time) {
        offerData.remaining_time = chainOffer.remaining_time
      }
      return offerData
    },
    filteredBigOffers(): Offer[] {
      const excludedOffers = [LEVEL_BAGS, LEGENDARY_CHAIN, GAME_PASS, PIGGY_BANK, TOURNAMENT_PASS]
      return this.bigOffers.filter(
        (offer: Offer): boolean => !excludedOffers.includes(offer.category),
      )
    },
  },
  watch: {
    async '$route.query.reloadData'(): Promise<void> {
      if (!this.$route.query.reloadData) return

      await this.loadOffers(true)

      this.$router.replace({
        query: {
          ...this.$route.query,
          reloadData: undefined,
        },
      })
    },
  },
  async created(): Promise<void> {
    playSound('action-special-offer')
    await this.loadOffers()
  },
  methods: {
    ...mapActions(usePremiumStore, ['loadOffers']),
    async reloadOffers(): Promise<void> {
      await this.loadOffers(true)
    },
  },
})
</script>

<style lang="scss" scoped>
.p-offers {
  &-main {
    margin: 2.875rem 0 0 0;
    height: 71%;

    &-scroll {
      display: -webkit-inline-box;
    }
  }
}
</style>
