<template>
  <div class="pre-start-header">
    <div class="pre-start-header-content flex items-center text-left">
      <app-discipline-icon
        v-show="disciplineId"
        class="pre-start-header-logo"
        :discipline-id="disciplineId"
      />
      <div class="pre-start-header-content-text uppercase flex text-texts-standard-default">
        <div
          v-show="disciplineId"
          class="pre-start-header-content-text-title font-bold text-texts-standard-additional"
        >
          <p>
            {{ $translateDiscipline(disciplineId) }}
            <span class="pre-start-header-content-text-title-yellow-text"> {{ title }}</span>
          </p>
        </div>
        <div class="pre-start-header-content-text-subtitle">
          <p>
            {{ subTitle }}:
            <span class="pre-start-header-content-text-subtitle-yellow-text font-bold">{{
              points
            }}</span>
          </p>
        </div>
      </div>
      <app-control-icon
        class="close-button cursor-pointer absolute right-20 flex items-center"
        control="hide"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PreBossFightHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    points: {
      type: [Number, String],
      default: 0,
    },
    disciplineId: {
      type: Number,
      default: 0,
    },
  },
  emits: ['close'],
})
</script>

<style lang="scss" scoped>
.pre-start {
  &-header {
    background-image: url($path-components + '/confirmPopup/bg-confirm-popup-header.avif');
    background-size: cover;
    width: 100%;
    height: 7.563rem;

    &:before {
      @if $isWsm {
        content: '';
        position: absolute;
        left: -0.063rem;
        bottom: -0.063rem;
        width: 1.563rem;
        height: 1.563rem;
        border-bottom: 0.094rem solid #b5deff;
        border-left: 0.094rem solid #b5deff;
      }
    }

    &:after {
      @if $isWsm {
        content: '';
        position: absolute;
        right: -0.063rem;
        top: -0.063rem;
        width: 1.563rem;
        height: 1.563rem;
        border-top: 0.094rem solid #b5deff;
        border-right: 0.094rem solid #b5deff;
      }
    }

    &-logo {
      margin-right: 1.063rem;
      margin-left: 1.125rem;
    }

    &-content {
      height: 100%;
      padding-left: 1.25rem;

      &-text {
        line-height: 2.7rem;
        height: 5rem;
        flex-direction: column;
        @if $isWsm {
          font-style: italic;
        }

        &-title {
          font-size: 2.875rem;

          &-yellow-text {
            color: #fadd1e;
          }
        }

        &-subtitle {
          font-size: 2rem;

          &-yellow-text {
            color: #fadd1e;
          }
        }
      }
    }

    .close-button {
      right: 0.438rem;
      top: 0.5rem;
    }
  }

  &-content {
    min-height: 13rem;
    margin: 1rem;

    &-textarea {
      font-size: 2rem;
      min-height: 13rem;
    }
  }
}
</style>
