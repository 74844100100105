import { MECHANIC_BOARD_GAMES_EVENT } from '@/globalVariables'
import { gameToken, sendToFlutter } from '@/helpers'
import { getRouteWebName, isMobile } from '@/plugins'
import { useBoardGamesEventStore } from '@/store/pinia/boardGamesEventStore'
import { useUserStore } from '@/store/pinia/userStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import router from '@/router'

export const openBoardGamesEventAfterLogin = async (): Promise<void> => {
  if (!canOpenBoardGamesEvent()) return
  if (useTutorialStore().getIsTutorial) return

  const boardGamesEventStore = useBoardGamesEventStore()
  const userStore = useUserStore()

  if (boardGamesEventStore.isEventTeaserActive && !userStore.isBoardGamesEventTeaserSeen) {
    await userStore.setProfileAttributes({
      name: 'board_games_teaser_seen',
      value: 1,
    })

    return await openBoardGamesEvent({
      redirect: 'teaser',
    })
  }

  if (boardGamesEventStore.isEventActive && !userStore.isBoardGamesEventSeen) {
    // referencia na aktualny stav, nakolko dalsia operacia nam ho uz prepise
    const openTeaser = !userStore.isBoardGamesEventTeaserSeen

    if (openTeaser) {
      await userStore.setProfileAttributes({
        name: 'board_games_teaser_seen',
        value: 1,
      })
    }

    await userStore.setProfileAttributes({
      name: 'board_games_seen',
      value: 1,
    })

    return await openBoardGamesEvent({
      redirect: openTeaser ? 'teaser' : 'offers',
    })
  }
}

export const openBoardGamesEvent = async (searchParams?: Record<string, string>): Promise<void> => {
  if (!canOpenBoardGamesEvent()) return

  const boardGamesEventStore = useBoardGamesEventStore()
  await boardGamesEventStore.loadEvent(true)

  if (isMobile()) {
    const queryString =
      boardGamesEventStore.eventQueryString +
      (searchParams && Object.keys(searchParams).length
        ? `&${new URLSearchParams(searchParams).toString()}`
        : '') +
      `&gameToken=${gameToken}`

    sendToFlutter(
      JSON.stringify({
        event: 'openMinigame',
        url: `${import.meta.env.VITE_BOARD_GAMES_EVENT_URL}/set-params?${queryString}`,
      }),
    )
    return
  }

  router.push({ name: getRouteWebName('BoardGamesEventView'), query: searchParams })
}

export const canOpenBoardGamesEvent = (): boolean => {
  if (!useResponseTaskStore().hasMechanic(MECHANIC_BOARD_GAMES_EVENT)) return false

  const boardGamesEventStore = useBoardGamesEventStore()
  return (
    (boardGamesEventStore.isEventTeaserActive || boardGamesEventStore.isEventActive) &&
    !boardGamesEventStore.isEventLocked
  )
}
