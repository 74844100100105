<template>
  <section class="app-page-wrapper flex flex-col absolute">
    <premium-menu />
    <main class="flex w-full h-full safe-area">
      <events-side-menu class="h-full" />
      <div class="premium-event-passes-wrapper h-full flexing relative bordered-1">
        <passes-main v-if="isLoaded" />
        <component-loading v-else :is-loading="true" type="secondary" width="100%" height="100%" />
      </div>
    </main>
  </section>
</template>

<script lang="ts">
import PassesMain from '@/components/Events/PassesComponents/PassesMain.vue'
import EventsSideMenu from '@/components/Premium/Events/SideMenu.vue'
import PremiumMenu from '@/components/Premium/PremiumMenu.vue'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PremiumEventPasses',
  components: {
    PremiumMenu,
    EventsSideMenu,
    PassesMain,
  },
  computed: {
    ...mapState(usePremiumStore, {
      eventPass: 'getEventPass',
      championPass: 'getChampionPass',
      masterPass: 'getMasterPass',
    }),
    isLoaded(): boolean {
      return !!(this.eventPass || this.championPass || this.masterPass)
    },
  },
  // Passes component will react for this route change.
  /*
  watch: {
    async '$route.query.reloadData'(): Promise<void> {
      if (!this.$route.query.reloadData) return

      await this.loadEventOffers(true)

      this.$router.replace({
        query: {
          ...this.$route.query,
          reloadData: undefined,
        },
      })
    },
  },
  */
  async created(): Promise<void> {
    await this.loadEventOffers()
  },
  methods: {
    ...mapActions(usePremiumStore, {
      loadEventOffers: 'loadEventOffers',
    }),
  },
})
</script>

<style lang="scss" scoped>
.premium-event-passes-wrapper {
  width: 93.25rem;
  height: 43.875rem;
  margin: 2rem;
  outline: 0.063rem solid #5966be;
}
</style>
