<template>
  <footer class="club-building-panel-footer flexing flex-col absolute w-full">
    <p
      class="text-36 mr-2 mb-7 uppercase"
      :class="$isWsm ? 'text-texts-standard-name' : 'text-texts-standard-additional'"
    >
      {{ $t('club.activeEmployees') }}
    </p>
    <div class="active-employees-wrapper relative">
      <clubs-active-employees :employees="employeesData" />
    </div>
    <app-button
      v-if="activeEmployees"
      btn-id="club-panel-footer-btn"
      btn-type="confirm"
      :btn-text="$t('common.claimAll')"
      btn-size="md"
      :disabled="!canClaim"
      add-class="mt-10"
      @click="$emit('doActionFooter')"
    />
    <p v-else class="text-texts-standard-danger text-30 uppercase font-bold mt-7">
      {{ $t('club.noActiveEmployees') }}
    </p>
  </footer>
</template>

<script lang="ts">
import ClubsActiveEmployees from '@/components/Club/ClubsActiveEmployees.vue'
import {
  CLUBARENA,
  CLUBHOUSE,
  DIRECTORATE,
  FITNESS,
  HOTEL,
  MEDIA,
  MEDICAL,
  PARKING,
  RELAX,
  RESTAURANT,
  SWIMMING,
  WELLNESS,
} from '@/globalVariables'
import { useEmployeeState } from '@/store/pinia/clubs/employeeStore'
import { useBuildingsStore } from '@/store/pinia/clubs/buildingStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { ClubActiveEmployee } from '@/interfaces/clubs/Clubs'
import type { Employee } from '@/interfaces/clubs/Employees'

export default defineComponent({
  name: 'ClubsBuildingPanelBuildingFooter',
  components: {
    ClubsActiveEmployees,
  },
  props: {
    buildingName: {
      type: String,
      required: true,
      validator(value: string): boolean {
        return [
          CLUBHOUSE,
          CLUBARENA,
          DIRECTORATE,
          FITNESS,
          HOTEL,
          MEDIA,
          MEDICAL,
          PARKING,
          RELAX,
          RESTAURANT,
          SWIMMING,
          WELLNESS,
        ].includes(value)
      },
    },
  },
  emits: ['doActionFooter'],

  computed: {
    ...mapState(useEmployeeState, {
      getEmployeesSlot: 'getEmployeesSlot',
    }),
    ...mapState(useBuildingsStore, ['getBuildingsIds']),

    activeEmployees(): boolean {
      return this.employeesData.some((employee: ClubActiveEmployee): boolean => employee.active)
    },
    canClaim(): boolean {
      return this.employeesData.some((employee: ClubActiveEmployee): boolean => employee.canClaim)
    },
    employeesData(): ClubActiveEmployee[] {
      const slots = this.getEmployeesSlot(this.getBuildingsIds[this.buildingName])
      const employees = []
      if (slots) {
        const personalEmployee = slots.personal
        const clubEmployee = slots.club
        employees.push(this.setEmployee(personalEmployee))
        employees.push(this.setEmployee(clubEmployee))
      }
      return employees
    },
  },
  methods: {
    setEmployee(employee: Employee): ClubActiveEmployee {
      return {
        type: employee.employeeType,
        active: employee.isHired ?? false,
        rewards: {
          type: employee.rewardType ?? '',
          value: employee.rewardValue ?? 0,
        },
        canClaim: employee.rewardsToClaim && employee.rewardsToClaim > 0,
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.club-building-panel-footer {
  bottom: 2rem;
}

.active-employees-wrapper {
  @if $isWsm {
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(5, 18, 33, 1) 25%,
      rgba(5, 18, 33, 1) 75%,
      transparent 100%
    );
  }
  @if $isSsm {
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(121, 127, 181, 0.99) 50%,
      transparent 100%
    );
  }

  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 0.125rem;
    position: absolute;
    left: 0;
    background: linear-gradient(
      to right,
      transparent 0%,
      #1e436c 20%,
      #1e436c 50%,
      #1e436c 70%,
      transparent 100%
    );
  }

  &:before {
    bottom: 0;
  }

  &:after {
    top: 0;
  }
}
</style>
