<template>
  <header class="teams-header flexing w-full py-8 relative" :data-event-theme="namespace">
    <app-button
      v-if="Object.keys(chains).length === 3"
      class="absolute top-7 left-10 z-10"
      :btn-type="$isWsm ? 'primary' : 'inactive'"
      :btn-text="$te('back')"
      btn-size="xsm"
      :btn-icon="$isWsm ? 'icon icon-back absolute left-4' : undefined"
      style="width: 18.75rem"
      @click="setChain({ chainId: null })"
    />
    <p class="text-32 text-texts-standard-default leading-tight mt-4">
      {{ title }}
    </p>
    <app-button
      class="absolute top-7 right-10 z-10"
      btn-type="primary"
      :btn-text="$te('eventInformation')"
      btn-size="xsm"
      style="width: 18.75rem"
      @click="isOpen = true"
    />
  </header>
  <info-popup
    v-if="isOpen"
    :popup-title="$te('infoTaskchain')"
    class="teams-popup"
    width="76.125rem"
    @close="isOpen = false"
  >
    <section class="teams-popup-info text-34 text-texts-standard-default">
      <p class="teams-popup-info-text">{{ $te('aboutTaskchain') }}</p>
    </section>
  </info-popup>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useTaskChainStore } from '@/store/pinia/events/taskChainStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  isOpen: boolean
}

export default defineComponent({
  name: 'TeamsHeader',
  components: { InfoPopup },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapState(useTaskChainStore, {
      chains: 'getChains',
    }),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
  },
  methods: {
    ...mapActions(useTaskChainStore, ['setChain']),
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='challenge_cup'] {
  --events-teams-header-bg: none;
}

[data-event-theme='frozen'] {
  --events-teams-header-bg: url('#{$path-events}frozen-championship/taskchain/teams/header-bg.webp');
}

.teams-header {
  align-items: flex-start;
  height: 9.75rem;
  @include background(var(--events-teams-header-bg), cover);

  p {
    width: 70rem;
  }
}

.teams-popup {
  &-info {
    height: 16.5625rem;
    padding: 3.1875rem 6.25rem;
    line-height: 2.5rem;

    &-text {
      line-height: 3rem;
    }
  }
}
</style>
