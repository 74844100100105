<template>
  <div class="flexing">
    <p
      class="text-32 mr-4"
      :class="{
        'text-texts-standard-default': $isWsm,
        'text-texts-standard-additional': $isSsm,
      }"
    >
      {{ $t('seasons.aboutSeasons') }}
    </p>
    <app-icon icon-name="question" @click="showAboutSeasonsPopup = true" />

    <seasons-about-popup
      :show-popup="showAboutSeasonsPopup"
      @close="showAboutSeasonsPopup = false"
    />
  </div>
</template>

<script lang="ts">
import SeasonsAboutPopup from '@/components/Seasons/SeasonsAboutPopup.vue'
import { defineComponent } from 'vue'

interface ComponentData {
  showAboutSeasonsPopup: boolean
}

export default defineComponent({
  name: 'SeasonsAbout',
  components: {
    SeasonsAboutPopup,
  },
  data(): ComponentData {
    return {
      showAboutSeasonsPopup: false,
    }
  },
})
</script>
