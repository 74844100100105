<template>
  <transition name="attribute">
    <div
      v-show="show"
      ref="wrapper"
      class="attribute-wrapper w-full h-full absolute"
      @click="clickOutside"
    >
      <aside class="att-menu absolute h-full">
        <app-control-icon control="close" class="close-icon absolute" @click="hide" />
        <p class="att-menu-text font-bold text-texts-standard-important text-34 flexing uppercase">
          {{ $t('map.attributes') }}
        </p>
        <section class="att-menu-power w-full relative text-texts-standard-default flex flex-col">
          <p class="text-30 font-bold">
            {{ $t('map.overall_strength') }}
          </p>
          <p class="text-texts-standard-additional text-28">
            {{ $t('map.click_to_detail') }}
          </p>
        </section>
        <app-scrollbar v-if="!isLoading" width="100%" height="80%" scroll="y" slice="y">
          <article
            v-for="(discipline, index) in disciplines"
            :key="index"
            class="att-menu-disc cursor-pointer relative flex items-center"
            :class="{
              'pointer-events-none': discipline.lock,
              'active-section': discipline.id === selectedDiscipline?.id,
              'hover-effect': !discipline.lock || discipline.temporaryLock,
              'temporary-lock-hover': discipline.temporaryLock,
            }"
            @click="!discipline.lock && selectDiscipline(discipline.id)"
          >
            <div
              v-if="!discipline.lock"
              class="discipline dark-icon"
              :class="[
                { 'opacity-lock': discipline.lock },
                `icon-discipline-${discipline.id}-dark-56`,
              ]"
            />
            <div
              class="discipline light-icon"
              :class="[
                { 'opacity-lock': discipline.lock || discipline.temporaryLock },
                `icon-discipline-${discipline.id}-light-56`,
              ]"
            />
            <p
              class="att-menu-disc-text text-34 font-bold text-texts-standard-additional uppercase"
              :class="{
                'opacity-lock': discipline.lock || discipline.temporaryLock,
                'att-menu-disc-text-selected': disciplineIndex === index,
              }"
            >
              {{ $translateDiscipline(discipline.id) }}
            </p>
            <div
              class="att-menu-disc-power-wrapper flex items-center"
              :class="[discipline.lock ? 'justify-center' : 'justify-end']"
              :style="{ marginRight: discipline.lock ? '1.3rem' : '1rem' }"
            >
              <div v-if="!discipline.lock" class="att-menu-disc-power">
                <p class="text-32 text-texts-standard-additional font-bold flexing h-full">
                  {{ discipline.totalStrength }}
                </p>
              </div>
              <div v-if="!discipline.lock" class="triangle white" />
              <app-icon
                v-if="discipline.lock"
                v-tippy="{
                  content: getLockedText(Number(discipline.lock)),
                  placement: 'right',
                  theme: 'WSM',
                }"
                :icon-name="getLockedIcon(Number(discipline.lock))"
              />
            </div>
          </article>
        </app-scrollbar>

        <component-loading :is-loading="isLoading" height="50%" />
        <section
          v-if="showExtension && selectedDiscipline"
          class="att-menu-section h-full absolute"
        >
          <app-discipline-icon
            v-if="selectedDiscipline?.id"
            :size="70"
            theme="light"
            :discipline-id="selectedDiscipline.id"
            class="disc-icon m-auto"
          />
          <p class="text-texts-standard-default font-bold uppercase text-40 discipline-text">
            {{ $translateDiscipline(selectedDiscipline?.id) }}
          </p>
          <p class="text-texts-standard-additional text-30">
            {{ $t('common.totalPower') }}
          </p>
          <div
            class="att-menu-section-str text-40 font-bold"
            :class="{
              'text-texts-standard-important': $isWsm,
              'text-texts-standard-default': $isSsm,
            }"
          >
            <p>{{ selectedDiscipline?.totalStrength }}</p>
          </div>
          <article
            v-for="(attribute, key) in filteredAttributes"
            :key="key"
            class="att-menu-section-powers text-texts-standard-default w-full"
          >
            <div
              v-if="!attribute.webView"
              v-tippy="{
                theme: 'WSM',
                content: $t('rankingsGroup.completeQuestsToUnlock'),
                placement: 'top',
                textAlign: 'center',
              }"
              class="att-menu-section-powers-item"
            >
              <p class="text-texts-standard-additional text-30">
                {{ $t(`common.${key}`) }}
              </p>
              <p class="text-34">
                {{ attribute.value ? attribute.value : '-' }}
              </p>
              <app-icon icon-name="plus-blue" class="absolute plus-blue" />
              <div class="triangle white" />
            </div>
            <router-link
              v-else
              :to="
                selectedDiscipline.temporaryLock
                  ? ''
                  : {
                      name: $getWebView(attribute.webView),
                      params: { id: selectedDiscipline.id },
                    }
              "
              @click="!selectedDiscipline.temporaryLock && hide()"
            >
              <p class="text-texts-standard-additional text-30">
                {{ $t('common.' + key) }}
              </p>
              <p class="text-34">
                {{ attribute.value ? attribute.value : '-' }}
              </p>
              <app-icon icon-name="plus-blue" class="absolute plus-blue" />
              <div class="triangle white" />
            </router-link>
          </article>
          <article
            v-if="selectedDiscipline"
            class="att-menu-section-season"
            :class="{
              opacity: attributes[seasonalAttribute].value === 0,
              'att-menu-section-powers': selectedDiscipline.temporaryLock,
            }"
          >
            <p class="text-texts-standard-important text-30">
              {{ $t('common.seasonalAttribute') }}
            </p>
            <p class="text-texts-standard-important text-34">
              {{
                attributes[seasonalAttribute].value === 0
                  ? '-'
                  : attributes[seasonalAttribute].value
              }}
            </p>
            <div class="triangle white" />
          </article>

          <article
            v-if="selectedDiscipline.temporaryLock"
            class="flex flex-col justify-center items-center mt-18"
          >
            <p class="text-36 text-texts-standard-default uppercase font-bold">
              {{ $t('common.locked') }}
            </p>
            <p class="text-32 text-texts-standard-additional">
              {{ $replacePlaceholder($t('common.levelRequired'), '%s', '') }}
              <span class="text-texts-standard-important">
                {{ selectedDiscipline.temporaryLockLevel }}
              </span>
            </p>
          </article>
        </section>
      </aside>
    </div>
  </transition>
</template>

<script lang="ts">
import {
  CATEGORY_BASE,
  CATEGORY_EQUIPMENT,
  CATEGORY_EQUIPMENT_PREMIUM_UPGRADE,
  CATEGORY_EQUIPMENT_UPGRADE,
  CATEGORY_RESEARCH_BASE,
  CATEGORY_RESEARCH_PREMIUM,
  CATEGORY_RESEARCH_REPEATABLE,
  CATEGORY_SEASONAL,
  MECHANIC_RESEARCH,
} from '@/globalVariables'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTrainingDisciplineStore } from '@/store/pinia/trainingDisciplinesStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { Attribute } from '@/interfaces/global/Discipline'
import type Discipline from '@/interfaces/global/Discipline'

// translations keys
const BASIC_ATTRIBUTE = 'basicAttribute'
const RESEARCH = 'research'
const SEASONAL_ATTRIBUTE = 'seasonalAttribute'
const EQUIPMENT = 'equipment'

const ATTRIBUTES_MAPPING = {
  [CATEGORY_BASE]: BASIC_ATTRIBUTE,
  [CATEGORY_EQUIPMENT]: EQUIPMENT,
  [CATEGORY_EQUIPMENT_UPGRADE]: EQUIPMENT,
  [CATEGORY_EQUIPMENT_PREMIUM_UPGRADE]: EQUIPMENT,
  [CATEGORY_RESEARCH_BASE]: RESEARCH,
  [CATEGORY_RESEARCH_PREMIUM]: RESEARCH,
  [CATEGORY_RESEARCH_REPEATABLE]: RESEARCH,
  [CATEGORY_SEASONAL]: SEASONAL_ATTRIBUTE,
}

interface AttributeData {
  value: number
  webView: string
}

interface FilteredAttributesData {
  basicAttribute?: AttributeData
  equipment?: AttributeData
  research?: AttributeData
}

interface ComponentData {
  seasonalAttribute: string
  research: string
  disciplineIndex: number
  season: boolean
  selectedDiscipline: Discipline
  attributes: {
    basicAttribute?: AttributeData
    equipment?: AttributeData
    research?: AttributeData
    seasonalAttribute?: AttributeData
  }
  showExtension: boolean
}

export default defineComponent({
  name: 'AttributeMenu',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['hideAttribute'],
  data(): ComponentData {
    return {
      seasonalAttribute: SEASONAL_ATTRIBUTE,
      research: MECHANIC_RESEARCH,
      disciplineIndex: 0,
      season: false,
      selectedDiscipline: null,
      attributes: {},
      showExtension: false,
    }
  },
  computed: {
    ...mapState(useTrainingDisciplineStore, {
      disciplines: 'getDisciplines',
      isLoading: 'getIsLoading',
    }),
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
    }),
    filteredAttributes(): FilteredAttributesData {
      const data = { ...this.attributes }
      delete data[SEASONAL_ATTRIBUTE]
      return data
    },
  },
  watch: {
    show(): void {
      if (this.show) {
        this.loadDisciplines()
      }
    },
  },
  methods: {
    hide(): void {
      this.showExtension = null
      this.$emit('hideAttribute')
    },
    clickOutside(e: Event): void {
      if (e.target !== this.$refs.wrapper) return
      this.hide()
    },
    cominSoon(id: number): boolean {
      return ![1, 2, 3].includes(id) // tmp solutuion, because only dis. 1 and 3 will be in release
    },
    lock(discipline: Discipline): boolean {
      return [1, 2, 3].includes(discipline.id) && !!discipline.lock // tmp solutuion
    },
    ...mapActions(useTrainingDisciplineStore, ['loadDisciplines']),
    selectDiscipline(key: number): void {
      /* if (!this.disciplines[key].attributes) {
        return
      } */
      this.selectedDiscipline = this.disciplines.find(
        (disc: Discipline): boolean => disc.id === key,
      )
      this.showExtension = true
      const data = {
        [BASIC_ATTRIBUTE]: this.setAttribute(0, 'TrainingDisciplines'),
        [EQUIPMENT]: this.setAttribute(0, 'Equipment'),
        [RESEARCH]: this.setAttribute(
          0,
          this.hasMechanic(MECHANIC_RESEARCH) ? 'LabDisciplines' : '',
        ),
        [SEASONAL_ATTRIBUTE]: this.setAttribute(0, 'TrainingDisciplines'),
      }
      Object.entries(this.selectedDiscipline.attributes).forEach(
        ([attribute, value]: [string, Attribute]): void => {
          if (ATTRIBUTES_MAPPING[attribute] in data) {
            data[ATTRIBUTES_MAPPING[attribute]].value += value
          }
        },
      )
      this.attributes = data
    },
    setAttribute(value: number, webView: string): AttributeData {
      return {
        value,
        webView,
      }
    },
    getLockedText(lockLevel: number): string {
      return lockLevel >= 99
        ? 'Coming soon'
        : this.$replacePlaceholder(this.$t('common.levelRequired'), '%s', lockLevel)
    },
    getLockedIcon(lockLevel: number): string {
      return lockLevel >= 99 ? 'time' : 'lock-sm'
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';
@import '@/assets/styles/global.scss';
@import '@/assets/styles/components/icons/main-icons.scss';
@import '@/assets/styles/components/icons/icons.scss';

.attribute-enter-from,
.attribute-leave-to {
  opacity: 0;
  left: -5rem;
}

.attribute-enter-to,
.attribute-leave-from {
  opacity: 1;
  left: 0;
}

.attribute-enter-active,
.attribute-leave-active {
  transition: all 0.5s ease;
}

.attribute-wrapper {
  z-index: 3;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    pointer-events: auto;
    z-index: 0;
  }

  .att-menu {
    width: 36.095rem;
    z-index: 1;
    top: 0;

    @if $isWsm {
      background-image: linear-gradient(to left, #18334e, #18517f, #061123);
    }

    @if $isSsm {
      background-image: linear-gradient(to top, #1d273a, #4a4f79, #283149);
      box-shadow: 15px 0px 38px 2px rgba(6, 17, 35, 0.4);
    }

    .close-icon {
      top: 0.5rem;
      left: 0.94rem;
      transform: $scale-default;
    }

    &-text {
      height: 5.63rem;
    }

    &-power {
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;

      @if $isWsm {
        background-image: linear-gradient(to right, transparent, #71c2ff54, transparent);
      }

      @if $isSsm {
        background-image: linear-gradient(
          to right,
          transparent,
          rgba(144, 144, 193, 0.5),
          transparent
        );
      }

      &::before,
      &::after {
        position: absolute;
        content: '';
        height: 0.1rem;
        width: 100%;
        left: 50%;
        transform: translate(-50%);
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.01),
          #fff,
          rgba(255, 255, 255, 0.01)
        );
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }

    &-disc {
      height: 5.34rem;
      padding-left: 1.175rem;

      .discipline {
        width: 12%;
        height: 75.8%;

        &.dark-icon {
          display: none;
        }
      }

      &-text {
        line-height: 1;
        margin-left: 1.125rem;
        width: 70%;
        text-align: left;
      }

      &-power-wrapper {
        width: 30%;
        margin-right: 1rem;
      }

      &-power {
        width: 7.03rem;
        height: 2.34rem;
        transform: translate(0, 0);

        @if $isWsm {
          background-image: linear-gradient(to right, transparent, #153a61, #153a61, transparent);
        }

        @if $isSsm {
          background-image: linear-gradient(to right, transparent, #172142, #172142, transparent);
        }

        &::before,
        &::after {
          position: absolute;
          content: '';
          height: 0.1rem;
          width: 100%;
          left: 50%;
          transform: translate(-50%);
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.01),
            #fff,
            rgba(255, 255, 255, 0.01)
          );
        }

        &::before {
          top: 0;
        }

        &::after {
          bottom: 0;
        }
      }

      &::after {
        position: absolute;
        content: '';
        height: 0.1rem;
        width: 100%;
        left: 50%;
        transform: translate(-50%);
        bottom: 0;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.01),
          #fff,
          rgba(255, 255, 255, 0.01)
        );
      }

      .icon-time {
        width: 2.49rem;
        height: 2.59rem;
        pointer-events: auto;
      }

      .icon-lock-sm {
        pointer-events: auto;
      }
    }

    .hover-effect:hover,
    .active-section {
      @if $isWsm {
        background-image: linear-gradient(to right, #b58804, #fadd1e, #b58804);
        box-shadow: inset 5px 0 30px 0 rgba(209, 130, 5, 0.73);
      }

      @if $isSsm {
        background-image: linear-gradient(
          to right,
          rgba(231, 148, 5, 0.7),
          rgba(255, 196, 93, 0.7),
          rgba(231, 148, 5, 0.7)
        );
      }

      & .att-menu-disc-text {
        color: theme('colors.texts.standard.dark');
        text-shadow: none;
      }

      & .discipline.dark-icon {
        display: block;
      }

      & .discipline.light-icon {
        display: none;
      }
    }

    &-section {
      width: 30rem;
      right: -30rem;
      top: 0;
      padding-top: 3.25rem;

      @if $isWsm {
        background-image: linear-gradient(to left, #172742, #14426c, #0e3961, #0b1d3c);
      }

      @if $isSsm {
        background-image: linear-gradient(to right, #283149, #373e5d, #283149);
        box-shadow: 15px 0px 38px 2px rgba(6, 17, 35, 0.4);
      }

      .discipline-text {
        margin-top: 1.4375rem;
        margin-bottom: 2.9rem;
      }

      &-str {
        width: 25.375rem;
        height: 3.75rem;
        position: relative;
        left: 50%;
        transform: translate(-50%);
        margin-top: 1.375rem;

        @if $isWsm {
          background-image: linear-gradient(to right, transparent, #153a61, #153a61, transparent);
        }

        @if $isSsm {
          background-image: linear-gradient(
            to right,
            transparent,
            #c93d90,
            #d43d80,
            #c93d90,
            transparent
          );
        }

        &::before,
        &::after {
          position: absolute;
          content: '';
          height: 0.1rem;
          width: 100%;
          left: 50%;
          transform: translate(-50%);
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.01),
            #fff,
            rgba(255, 255, 255, 0.01)
          );
        }

        &::before {
          top: 0;
        }

        &::after {
          bottom: 0;
        }
      }

      &-powers {
        height: 7.75rem;
        position: relative;
        padding-top: 1rem;

        &::after {
          position: absolute;
          content: '';
          height: 0.1rem;
          bottom: 0;
          width: 90%;
          left: 50%;
          transform: translate(-50%);
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.01),
            #fff,
            rgba(255, 255, 255, 0.01)
          );
        }

        .plus-blue {
          left: 50%;
          bottom: -1rem;
          transform: translate(-50%);
          background-size: contain;
        }

        .triangle {
          position: absolute;
          right: 2rem;
          top: 50%;
          transform: translate(-50%);

          &:hover {
            border-left-color: #fadd1e;
          }
        }
      }

      &-season {
        height: 7.75rem;
        padding-top: 1.8rem;
        position: relative;

        .triangle {
          position: absolute;
          right: 2rem;
          top: 50%;
          transform: translate(-50%);
        }
      }

      .opacity {
        opacity: 0.5;
      }
    }

    .attribute-scroll {
      width: 44.625rem;
      height: 80%;
    }

    .opacity-lock {
      opacity: 0.5;
    }
  }
}

.temporary-lock-hover {
  &:hover {
    .att-menu-disc-text {
      color: theme('colors.texts.standard.dark') !important;
      opacity: 1;
      text-shadow: none;
    }
  }
}
</style>
