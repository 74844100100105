<template>
  <div id="contact" class="contact-wrapper app-page-wrapper absolute">
    <menu-component menu-type="contact" :title="$t('common.contact')" />
    <section class="w-full h-full safe-area">
      <main class="contact-main relative mx-auto">
        <app-scrollbar width="100%" height="100%" scroll="y" slide="y">
          <section class="contact text-white text-36">
            <article
              v-for="(item, index) in contactInfo[languageCode]"
              :key="index"
              class="contact-row w-full flex justify-between items-center"
              :class="index === 0 ? 'header' : ''"
            >
              <p class="contact-row-title uppercase">
                {{ item.title }}
              </p>
              <p class="contact-row-content">
                {{ item.content }}
              </p>
            </article>

            <article class="contact-row footer text-32">
              <p v-for="(item, index) in additionalInfo[languageCode]" :key="index">
                {{ item }}
              </p>
            </article>
          </section>
        </app-scrollbar>
        <section class="contact-buttons flex justify-between items-center">
          <a
            class="clickable-element"
            :href="'https://www.powerplay.studio/' + languageCode + '/license/'"
            target="_blank"
          >
            <app-button
              class="contact-button"
              btn-id="contact-button-conditions"
              btn-type="primary"
              :btn-text="$t('common.conditions')"
              btn-size="xl"
            />
          </a>
          <a
            class="clickable-element"
            :href="'https://www.powerplay.studio/' + languageCode + '/privacy-policy/'"
            target="_blank"
          >
            <app-button
              class="contact-button"
              btn-id="contact-button-gdpr"
              btn-type="primary"
              :btn-text="$t('common.privacy')"
              btn-size="xl"
            />
          </a>
        </section>
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  contactInfo: {
    sk: { title: string; content: string }[]
    en: { title: string; content: string }[]
  }
  additionalInfo: {
    sk: string[]
    en: string[]
  }
}

export default defineComponent({
  name: 'ContactComponent',
  data(): ComponentData {
    return {
      contactInfo: {
        sk: [
          { title: 'POWERPLAY MANAGER, s.r.o.', content: 'IČO 36 722 405' },
          {
            title: 'Adresa',
            content: 'Budatínska 57, 851 06 Bratislava, Slovensko',
          },
          {
            title: 'Reklamácie',
            content: this.$isWsm
              ? 'finance@wintersportsmania.com'
              : 'finance@summersportsmania.com',
          },
          {
            title: 'E-mail',
            content: this.$isWsm
              ? 'support@wintersportsmania.com'
              : 'support@summersportsmania.com',
          },
          { title: 'Telefón', content: '+421 915 637 243' },
        ],
        en: [
          {
            title: 'POWERPLAY MANAGER, s.r.o.',
            content: 'Company registration number 36 722 405',
          },
          {
            title: 'Address',
            content: 'Budatínska 57, 851 06 Bratislava, Slovakia',
          },
          {
            title: 'Claims',
            content: this.$isWsm
              ? 'finance@wintersportsmania.com'
              : 'finance@summersportsmania.com',
          },
          {
            title: 'E-mail',
            content: this.$isWsm
              ? 'support@wintersportsmania.com'
              : 'support@summersportsmania.com',
          },
          { title: 'Phone', content: '+421 915 637 243' },
        ],
      },
      additionalInfo: {
        sk: [
          'Spoločnosť zapísaná v obchodnom registri Okresného súdu Bratislava I. v odd: Sro, vložka číslo: 43978/B',
          'Dohľad nad činnosťou poskytovateľa služieb vykonáva Slovenská obchodná inšpekcia, Inšpektorát SOI pre Bratislavský kraj, Prievozská 32, 827 99 Bratislava – Ružinov.',
        ],
        en: [
          'Registered in the Commercial register of the Bratislava I District Court dep: Sro, entry number: 43978/B',
          'The activity of the service provider is under supervision of the Slovak commercial inspection, Inspectorate of the Slovak commercial inspection for the Bratislava region, Prievozská 32, 827 99 Bratislava – Ružinov.',
        ],
      },
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userLanguage: 'getLanguage',
    }),
    languageCode(): string {
      return this.userLanguage === 'sk' || this.userLanguage === 'cs' ? 'sk' : 'en'
    },
  },
})
</script>

<style lang="scss" scoped>
.contact {
  width: 98%;
  margin: 0 auto;

  &-main {
    margin-top: 2rem;
  }

  &-row {
    height: 6.375rem;
    margin: 0.75rem 0;
    padding: 1rem 2rem;
    text-shadow: 0.6px 0.8px 0.9px rgba(0, 0, 0, 0.71);
    @if $isWsm {
      background: rgba(77, 128, 160, 0.7);
      border: 0.063rem solid #6c9ec4;
    }
    @if $isSsm {
      background: rgba(69, 71, 100, 0.7);
      border: 0.125rem solid #7477a4;
    }

    &:first-child {
      margin-top: 0;
      margin-bottom: 0.938rem;
    }

    &:nth-child(even) {
      @if $isWsm {
        background: rgba(35, 109, 155, 0.7);
      }
      @if $isSsm {
        background: rgba(114, 114, 176, 0.7);
      }
    }

    &.header,
    &.footer {
      border: none;
      @if $isWsm {
        background: rgba(21, 78, 121, 0.5);
      }
      @if $isSsm {
        background: #292d30;
      }
    }

    &.header {
      font-size: 2.5rem;
      color: #d9ecff;

      & .contact-row-title {
        font-weight: bold;
      }
    }
    &.footer {
      margin: 1.063rem auto 0;
      height: 9.563rem;

      p {
        line-height: 1.36;
      }

      @if $isSsm {
        background: #3c405b;
      }
    }
  }

  &-buttons {
    width: 98%;
    margin: 1.75rem auto 0;

    .contact-button {
      width: 37.5rem;
    }
  }
}
</style>
