<template>
  <div
    class="playoff-box flex flex-col z-10"
    :class="[
      { 'is-large': isLarge, 'is-active': player?.user_games_id === userId, [player?.color]: true },
      advancingState,
    ]"
  >
    <div v-if="player" class="playoff-box-border z-20">
      <div
        class="playoff-box-player flex flex-row flex-start items-center text-32 text-texts-standard-default cursor-pointer"
        :class="[isLarge ? 'h-14' : 'h-12']"
      >
        <app-user
          :id="player.user_games_id"
          :name="player.username"
          :country="player.country"
          :no-country-text="true"
          :user-class="isLarge ? '!text-32' : '!text-28'"
          :flag-class="'playoff-box-flag ' + (isLarge ? '!w-8 !h-6' : '!w-7 !h-5')"
        />
      </div>
      <div
        class="playoff-box-rewards flex flex-row justify-between items-center text-texts-standard-default italic"
        :class="[isLarge ? 'h-11 text-28' : 'h-9 text-24']"
      >
        <div class="mx-2">
          <span class="inline-flex flex-col align-middle w-[1em] h-[1em] mr-2">
            <app-main-icon class="!mx-0" :icon-name="activeIcon" :icon-size="24" />
          </span>
          <span class="inline-flex flex-col align-middle">
            {{ $filters.$formatNumber(player.points) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import { pathImages } from '@/globalVariables'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type PlayoffResultsApiResponse from '@/interfaces/responses/events/playoff/PlayoffResultsApiResponse'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'PlayoffBox',
  components: {
    AppUser,
  },
  props: {
    activeIcon: {
      type: String,
      default: '',
    },
    isLarge: {
      type: Boolean,
      required: false,
      default: false,
    },
    player: {
      type: Object as PropType<
        Nullable<PlayoffResultsApiResponse['rounds'][0]['pairs'][0]['players'][0]>
      >,
      default: () => null,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userId: 'getUserId',
    }),
    advancingState(): string {
      // Pozor musi byt explicitne false
      if (this.player?.advancing !== null && this.player?.advancing === false) return 'lost'
      return ''
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/global.scss';
$playoff_colors: ('blue', 'green', 'lightgreen', 'red', 'grey');

.playoff-box {
  &.lost {
    opacity: 0.4;
  }

  width: 16.75rem;
  height: 5.25rem;
  @include background(url($path-events + 'playoff/playoff-box-grey.avif'), contain, right);
  @each $color in $playoff_colors {
    &.#{$color} {
      background-image: url($path-events + 'playoff/playoff-box-#{$color}.avif');
    }
  }

  &.is-large {
    width: 20rem;
    height: 6.25rem;
  }

  &.is-active {
    @include background(url($path-events + 'playoff/playoff-box-grey-me.avif'), contain, right);
    @each $color in $playoff_colors {
      &.#{$color} {
        background-image: url($path-events + 'playoff/playoff-box-#{$color}-me.avif');
      }
    }

    & > .playoff-box-border {
      @include background(url($path-events + 'playoff/playoff-box-me.avif'), contain, right);
      width: 100%;
      height: 100%;
    }
  }
}
</style>
