<template>
  <div class="carnival-header relative flex items-center justify-between px-8 pt-2">
    <event-pass-button :pass="PassType.Event" :show-active-pass-bg="true" />

    <div class="flexing space-x-4">
      <app-button
        v-for="(button, i) in buttons"
        :key="i"
        :btn-type="button === activeButton ? 'secondary' : 'inactive'"
        :btn-text="
          $te(
            button === CarnivalStandMainComponent.FortuneBasket ? 'pumpkinPatch' : 'shootingRange',
          )
        "
        btn-size="md"
        @click="setBoardSection(button)"
      />
    </div>

    <app-button
      btn-type="primary"
      :btn-text="$te('eventInformation')"
      btn-size="md"
      @click="$emit('showPopup')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { PassType } from '@/interfaces/events/Passes'
import EventPassButton from '@/components/Events/GlobalEventsComponents/EventPassButton.vue'
import { CarnivalStandMainComponent } from '@/interfaces/events/CarnivalStand'

interface ComponentData {
  PassType: typeof PassType
  CarnivalStandMainComponent: typeof CarnivalStandMainComponent
  activeButton: string
  buttons: string[]
}

export default defineComponent({
  name: 'CarnivalStandHeader',
  components: {
    EventPassButton,
  },
  emits: ['setSection', 'showPopup'],
  data(): ComponentData {
    return {
      PassType,
      CarnivalStandMainComponent,
      activeButton: CarnivalStandMainComponent.FortuneBasket,
      buttons: [CarnivalStandMainComponent.FortuneBasket, CarnivalStandMainComponent.ShootingRange],
    }
  },
  methods: {
    setBoardSection(component: string): void {
      this.activeButton = component
      this.$emit('setSection', { component })
    },
  },
})
</script>

<style lang="scss" scoped>
.carnival-header {
  width: 100%;
  margin: 0 auto;
  height: 7.625rem;

  .active-pass-bg {
    width: 30%;
  }
}
</style>
