<template>
  <div
    class="possible-upgrade bounce-animation"
    :class="hasRevertSkew ? 'revert-skew bounce-animation-with-revert' : 'bounce-animation'"
    :style="
      customPosition && {
        right: customPosition?.right,
        bottom: customPosition?.bottom,
        top: customPosition?.top,
        left: customPosition?.left,
      }
    "
  >
    <div class="possible-upgrade-icon" :class="'size-' + iconSize" />
  </div>
</template>

<script lang="ts">
import type { Nullable } from '@/interfaces/utils'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'EquipmentUpgradeIcon',
  props: {
    hasRevertSkew: {
      type: Boolean,
      default: false,
    },
    customPosition: {
      type: Object as PropType<
        Nullable<{
          top?: string
          right?: string
          bottom?: string
          left?: string
        }>
      >,
      default: () => null,
    },
    iconSize: {
      type: String,
      default: 'md',
      validator: (value: string): boolean => {
        return ['sm', 'md'].includes(value)
      },
    },
  },
})
</script>

<style lang="scss" scoped>
.possible-upgrade {
  z-index: 1;

  &-icon {
    @include background(url($path-equipment + 'icons/icon-possible-upgrade.avif'), contain);

    &.size {
      &-md {
        width: 3.75rem;
        height: 4.688rem;
      }

      &-sm {
        width: 2.625rem;
        height: 3.25rem;
      }
    }
  }
}

.bounce-animation {
  animation: bounce 4s ease infinite;
}

@keyframes bounce {
  70% {
    transform: translateY(0%);
  }

  80% {
    transform: translateY(-15%);
  }

  90% {
    transform: translateY(0%);
  }

  95% {
    transform: translateY(-7%);
  }

  97% {
    transform: translateY(0%);
  }

  99% {
    transform: translateY(-3%);
  }

  100% {
    transform: translateY(0);
  }
}

.bounce-animation-with-revert {
  animation: bounce-with-revert 4s ease infinite;
}

@keyframes bounce-with-revert {
  70% {
    transform: translateY(0%) skew(10deg);
  }

  80% {
    transform: translateY(-15%) skew(10deg);
  }

  90% {
    transform: translateY(0%) skew(10deg);
  }

  95% {
    transform: translateY(-7%) skew(10deg);
  }

  97% {
    transform: translateY(0%) skew(10deg);
  }

  99% {
    transform: translateY(-3%) skew(10deg);
  }

  100% {
    transform: translateY(0) skew(10deg);
  }
}
</style>
