<template>
  <confirm-popup
    v-if="isVisible"
    :button-data="buttonData"
    :popup-title="$te('partsPackage')"
    :button-text-left="$t('button.purchase')"
    :button-text-right="price"
    @close="$emit('close')"
    @action="$emit('click')"
  >
    <template #popupHeader>
      <p>{{ $te('partsPackage') }}</p>
    </template>
    <div class="container-upgrade text-36 text-texts-standard-default italic">
      <p>
        {{ $te('partsPackageConfirmMessage') }}
      </p>
    </div>
  </confirm-popup>
</template>

<script lang="ts">
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import { defineComponent } from 'vue'

interface ComponentData {
  buttonData: {
    btnId: string
    btnType: string
    btnSize: string
  }
}

export default defineComponent({
  name: 'ConfirmPackageBuyPopup',
  components: {
    ConfirmPopup,
  },
  props: {
    isVisible: {
      type: Boolean,
      reqired: true,
    },
    price: {
      type: Number,
      default: 0,
    },
  },
  emits: ['close', 'click'],
  data(): ComponentData {
    return {
      buttonData: {
        btnId: 'btn-confirm-package-buy',
        btnType: 'credit',
        btnSize: 'md',
      },
    }
  },
})
</script>

<style lang="scss" scoped>
.confirm-package-buy-popup-content {
  width: 95%;
  height: 14rem;
  margin: 0 auto;

  &-btn {
    margin: 0 auto;
  }
}
</style>
