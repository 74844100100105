<template>
  <div class="change-device-orientation-message flex flex-col justify-between">
    <div class="game-logo" />

    <div class="flexing flex-col">
      <div class="svg-icon">
        <RotateDeviceHorizontally />
      </div>
      <p class="text-26 uppercase">{{ $t('info.ctaPortraitMode') }}</p>
    </div>

    <div class="flexing flex-col">
      <info-text :text-size="26" :text="$t('info.ctaDownloadApp')" />
      <app-store-buttons show-os-specific-button />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AppStoreButtons from '@/components/Login/AppStoreButtons.vue'
import InfoText from '@/components/InfoText.vue'

import RotateDeviceHorizontally from '../assets/svg/rotateDeviceHorizontally.svg?component'

export default defineComponent({
  name: 'ChangeDeviceOrientationMessage',
  components: {
    AppStoreButtons,
    InfoText,
    RotateDeviceHorizontally,
  },
})
</script>

<style lang="scss" scoped>
.change-device-orientation-message {
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background: url($path-images + 'backgrounds/change-device-orientation-message-bg.avif') center
    repeat;
  background-size: cover;
  color: #fff;
  top: 0;
  left: 0;
  font-size: 2rem;
  padding: 2rem 3rem;
  z-index: 90;

  .game-logo {
    width: 32.061rem;
    height: 15.425rem;
    background-size: contain;

    @if $isWsm {
      background-image: url('https://appspowerplaymanager.vshcdn.net/images/winter-sports/homepage/LOGO_WSM_LOGIN_NEW.avif');
    }

    @if $isSsm {
      background-image: url('https://appspowerplaymanager.vshcdn.net/images/summer-sports/homepage/LOGO_SSM_LOGIN_NEW.avif');
    }
  }

  .svg-icon {
    width: 14rem;
    height: 14rem;
    margin-bottom: 3.125rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .homepage-welcome-buttons {
    margin-top: 1rem;
  }

  :deep(.info-text) {
    justify-content: center;

    .icon {
      width: 2rem;
      height: 2rem;
    }
  }
}
</style>
