<template>
  <div class="progress-item w-full relative flex items-center">
    <div
      v-if="progressData.locked"
      class="progress-item-locked flex items-center absolute left-0 top-0 w-full h-full text-30 justify-center"
    >
      <app-icon icon-name="lock-md" />
      <p class="text-text-standard-default">
        {{ $t('grandPrize.becomeClubMember') }}
      </p>
    </div>
    <div class="progress-item-logo" :style="getBuildingImage" />
    <p
      class="progress-item-name uppercase text-texts-standard-important text-40 text-left leading-none z-1"
    >
      <b>{{ $t(progressData.texts.title) }}</b>
    </p>
    <div class="row flex justify-end">
      <div class="row-skew flex items-center">
        <section v-if="!progressData.locked" class="flex items-center justify-evenly w-full">
          <div class="progress-item-bar" :class="{ 'opacity-50': progressData.completed }">
            <p class="progress-item-bar-text text-30 text-left text-text-standard-default">
              {{ $t(progressData.texts.description) }}
            </p>
            <app-progress-bar
              :bar-width="32.313"
              :bar-height="1.688"
              :counts="true"
              :actual="progressData.progress?.actual"
              :goal="progressData.progress?.goal"
              :text-size="2.25"
              :show-bottom-status="false"
            />
          </div>
          <app-main-icon
            :icon-name="'gp-' + progressData.gpType"
            :icon-size="104"
            :tooltip="translateIconNames(progressData)"
            :class="{ 'opacity-50': progressData.completed }"
          />
          <div
            v-if="progressData.completed"
            class="progress-item-completed flexing text-texts-standard-positive font-bold uppercase leading-none"
            :class="{
              'text-32': $isWsm,
              'text-36': $isSsm,
            }"
          >
            <p class="pr-2">
              {{ $t('career.questDone') }}
            </p>
            <app-icon icon-name="done-md" class="flex-shrink-0" />
          </div>
          <app-button
            v-else
            btn-type="confirm"
            :btn-text="$t('button.start')"
            btn-size="xsm"
            @click="
              $router.push({
                name: $getWebView(progressData.url),
                query: { homepage: 'true' },
              })
            "
          />
        </section>
      </div>
      <div class="row-skew-strip" />
    </div>
  </div>
</template>

<script lang="ts">
import { GRAND_PRIZE, pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { StyleValue } from 'vue'
import type { GrandPrizeProgressData } from '@/interfaces/GrandPrize'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'GrandPrizeProgressItem',

  props: {
    progressData: {
      type: Object as PropType<Nullable<GrandPrizeProgressData>>,
      default: () => null,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    getBuildingImage(): Nullable<StyleValue> {
      const buildingsStyles = {
        arena: {
          background:
            'url(' +
            pathImages +
            'arena/building/BUILDING_ARENA_SMALLEST_LVL1.avif) center no-repeat', // todo replace with current level
        },
        training_hall: {
          background:
            'url(' +
            pathImages +
            'training/building/BUILDING_TRAINING_HALL_SMALLEST_LVL1.avif) center no-repeat', // todo replace with current level
        },
        club_championship: {
          background:
            'url(' +
            pathImages +
            'clubs/championship/building-club-championship.avif) center no-repeat',
        },
        clubhouse: {
          background:
            'url(' +
            pathImages +
            'clubs/buildings/building-clubhouse-smallest-1.avif) center no-repeat', // todo replace with current level
        },
      }
      return buildingsStyles[this.progressData.buildingId]
        ? {
            ...buildingsStyles[this.progressData.buildingId],
            backgroundSize: 'contain',
          }
        : null
    },
  },

  methods: {
    translateIconNames(data: GrandPrizeProgressData): string {
      return this.$replacePlaceholder(
        this.$t('grandPrize.tier' + data.gpType?.charAt(0).toUpperCase() + data.gpType?.slice(1)),
        '{grandprize}',
        GRAND_PRIZE,
      )
    },
  },
})
</script>

<style lang="scss" scoped>
.progress-item {
  border: solid 0.063rem theme('borderColor.table-odd');
  background-color: theme('backgroundColor.table-odd');
  height: 8.75rem;
  padding-left: 0.625rem;
  margin-bottom: 0.625rem;

  &-logo {
    width: 12.5rem;
    height: 7.5rem;
  }

  &-name {
    margin-left: 1.688rem;
    width: 18rem;
  }

  &:nth-of-type(even) {
    border: solid 0.063rem theme('borderColor.table-even');
    background-color: theme('backgroundColor.table-even');
  }

  .row {
    width: 79.9375rem;

    .row-skew {
      clip-path: polygon(2% 0%, 100% 0%, 100% 100%, 0% 100%);
      width: 98.5%;
    }
  }

  .club-progress {
    margin-top: 2.5rem;
  }

  &-bar {
    margin: 0 6.5rem;

    &-text {
      position: relative;

      @if $isWsm {
        top: -1.375rem;
      }

      @if $isSsm {
        top: -1rem;
        left: -4.5rem;
      }
    }
  }

  &-locked {
    background: rgba($color: #0b1e35, $alpha: 0.8);
    z-index: 2;

    & > div:first-of-type {
      margin-left: 14rem;
      margin-right: 2rem;
    }
  }

  &-completed {
    width: 16.875rem;
  }
}
</style>
