<template>
  <div>
    <div
      v-if="!isContentLoading"
      class="discipline-menu flex justify-center items-center relative z-1"
      :class="{ 'discipline-menu--slim': isSlim }"
    >
      <div
        v-for="(discipline, i) in getData"
        :id="discipline.id + '-' + i"
        :key="discipline.id"
        :data-cy="discipline.id + '-' + i"
        class="discipline-menu-tab cursor-pointer text-texts-standard-default text-2xl"
        :class="{
          selected:
            selectedDisciplineTab === discipline.id &&
            (!discipline.lock || actualStageData?.discipline === discipline.id),
          complete: discipline.completed && showOnlyTrainer,
          available: !discipline.lock || actualStageData?.discipline === discipline.id,
        }"
        :data-event-badge-text="
          eventActive && eventDisciplineId === discipline.id
            ? $te('disciplineMenu.event')
            : undefined
        "
      >
        <tippy theme="WSM1" :class="['w-full h-full block']" placement="bottom" max-width="50rem">
          <template v-if="!discipline.locked" #content>
            <div class="lock-tooltip">
              <div class="lock-tooltip-title no-line flexing">
                <p class="uppercase font-bold text-30">
                  {{
                    discipline.branchType === 'main'
                      ? $t('careerQuestUnlocks.mainBranch')
                      : $t(`discipline.discipline_${getItemId(discipline)}`)
                  }}
                </p>
              </div>
            </div>
          </template>

          <app-notification
            v-if="getNotificationsByType(i, discipline.id) !== 0"
            :count="getNotificationsByType(i, discipline.id)"
            :is-skewed-parent="true"
          />
          <arrow-animation
            position="right"
            border="box"
            :tutorial="isDisciplineUnlockTutorial(discipline.id)"
            add-class="discipline-arrow"
          >
            <router-link
              v-if="!discipline.lock || actualStageData?.discipline === discipline.id"
              :id="'arena-discipline-' + getItemId(discipline)"
              class="discipline-menu-tab-active flexing"
              :class="{
                selected: selectedDisciplineTab === discipline.id,
              }"
              :to="{
                name: isDisciplineTutorial ? $getWebView('ArenaView') : $route.name.toString(),
                params: {
                  id: discipline.id,
                  task: $route.params.task ? discipline.id : '',
                },
                query: $route.query.homepage
                  ? {
                      homepage: 'true',
                    }
                  : {},
              }"
              @click="menuClick(discipline)"
            >
              <div class="discipline-menu-tab-icon-wrapper">
                <div
                  :class="[
                    'revert-skew',
                    selectedDisciplineTab === discipline.id ||
                    (discipline.lock && actualStageData?.discipline !== discipline.id)
                      ? iconClass(discipline, 'dark')
                      : iconClass(discipline, 'light'),
                  ]"
                />
              </div>
              <app-icon
                v-if="showCompletedIcons && discipline.completed"
                icon-name="done-md"
                class="absolute"
              />
              <app-progress-bar
                v-else-if="progress"
                class="fixed bottom-0 discipline-menu-tab-progress"
                :bar-width="5.863"
                :bar-height="0.563"
                :actual="discipline.stat ?? discipline.currentValue"
                :goal="discipline.statTarget ?? discipline.maxValue"
              />
            </router-link>

            <div v-else class="flexing w-full h-full revert-skew">
              <tippy
                theme="WSM1"
                :class="['flexing w-full h-full']"
                placement="bottom"
                max-width="50rem"
              >
                <div class="discipline-menu-tab-icon-wrapper">
                  <div :class="'icon-discipline-' + getItemId(discipline) + '-light-70'" />
                </div>
                <div class="discipline-menu-tab-lock-background skew"></div>
                <app-icon
                  :icon-name="lockedIcon(getItemId(discipline))"
                  class="absolute"
                  :class="{ 'temporary-lock-icon': discipline.temporaryLock }"
                />
                <app-progress-bar
                  v-if="discipline.temporaryLock && progress"
                  class="temporary-lock-progress-bar opacity-50"
                  :bar-width="5.863"
                  :bar-height="0.563"
                  :actual="discipline.stat ?? discipline.currentValue"
                  :goal="discipline.statTarget ?? discipline.maxValue"
                />
                <template #content>
                  <div class="lock-tooltip">
                    <div class="lock-tooltip-title flexing">
                      <p class="uppercase font-bold text-30">
                        {{ $t(`discipline.discipline_${getItemId(discipline)}`) }}
                      </p>
                    </div>
                    <p
                      v-if="!isAnotherPlayerMenu"
                      class="lock-tooltip-text-margin text-30"
                      v-html="lockedText(getItemId(discipline))"
                    />
                    <div v-if="discipline.temporaryLock" class="temporary-lock-border" />
                    <p v-if="discipline.temporaryLock" class="text-30 lock-tooltip-text-margin">
                      {{ $t('common.progressSaved') }}
                    </p>
                  </div>
                </template>
              </tippy>
            </div>
          </arrow-animation>
        </tippy>
      </div>
    </div>

    <component-loading :is-loading="isContentLoading" />
  </div>
</template>

<script lang="ts">
import { DISCIPLINE_TRAINED, MECHANIC_CAREER, pathImages } from '@/globalVariables'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import ArrowAnimation from './ArrowAnimation.vue'
import type {
  NotificationCareerQuest,
  NotificationEquipment,
  TreeData,
  TreeDataNotification,
} from '@/interfaces/global/Notification'

import { CareerBranch } from '@/enums/CareerBranch'
import type { DisciplineMenuData } from '@/interfaces/DisciplineMenu'
import type Discipline from '@/interfaces/global/Discipline'

interface ComponentData {
  CareerBranch: typeof CareerBranch
  DISCIPLINE_TRAINED: typeof DISCIPLINE_TRAINED
  pathImages: typeof pathImages
  selectedDisciplineTab: number
  activeTask: boolean
  temporaryTab: number
  temporaryLock: boolean
}

enum BuildingType {
  TrainingHall = 'training_hall',
  Arena = 'arena',
  Career = 'career',
}

export default defineComponent({
  name: 'DisciplineMenu',
  components: {
    ArrowAnimation,
  },
  props: {
    customDisciplineData: {
      type: Array as PropType<Discipline[]>,
      default: null,
    },
    type: {
      type: String,
      default: null,
      validator(value: string): boolean {
        return Object.values(BuildingType).includes(value as BuildingType)
      },
    },
    progress: {
      type: Boolean,
      default: false,
    },
    notificationType: {
      type: String,
      default: '',
      validator(value: string): boolean {
        return ['', 'shop', 'career', 'laboratory'].includes(value)
      },
    },
    emitClickEvent: {
      type: Boolean,
      default: false,
    },
    selectedDefault: {
      type: Number,
      default: null,
    },
    showCompletedIcons: {
      type: Boolean,
      default: false,
    },
    showOnlyUnlocked: {
      type: Boolean,
      default: false,
    },
    showOnlyTrainer: {
      type: Boolean,
      default: false,
    },
    isAnotherPlayerMenu: {
      type: Boolean,
      default: false,
    },
    isSlim: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onClickDiscipline'],
  data(): ComponentData {
    return {
      CareerBranch,
      DISCIPLINE_TRAINED,
      pathImages,
      selectedDisciplineTab: null,
      activeTask: true,
      temporaryTab: null,
      temporaryLock: true,
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useDisciplineStore, {
      disciplinesMenuData: 'getDisciplinesMenuData',
      isLoading: 'getIsLoading',
      unlockDisciplinesIds: 'getUnlockedDisciplinesIdsUnsorted',
    }),
    ...mapState(useResponseTaskStore, {
      notifications: 'getNotifications',
    }),
    ...mapState(useEventInfoStore, {
      eventDisciplineId: 'getEventDisciplineId',
      eventActive: 'getEventActive',
    }),
    isContentLoading(): boolean {
      return this.isLoading || this.disciplinesMenuData == null
    },
    isDisciplineTutorial(): boolean {
      const disciplineTutorials = [
        'clickOnDisciplineDownhill',
        'clickOnDisciplineBiathlon',
        'clickOnDisciplineSkijump',
        'clickOnDisciplineSprint100Meters',
        'clickOnDisciplineArchery',
        'clickOnDisciplineTrap',
      ]
      return disciplineTutorials.includes(this.actualStageData?.name)
    },
    getData(): DisciplineMenuData[] {
      // TODO: customize for menu of another player (needs to be done on BE first)
      if (this.isAnotherPlayerMenu) {
        return this.customDisciplineData as DisciplineMenuData[]
      }

      if (this.showOnlyUnlocked) {
        const unlockedDisciplines =
          this.disciplinesMenuData?.filter(
            (discipline: DisciplineMenuData): boolean => discipline.locked === false,
          ) ?? []

        return unlockedDisciplines
      }

      if (this.showOnlyTrainer) {
        const trainerDisciplines =
          this.disciplinesMenuData?.filter(
            (discipline: DisciplineMenuData): boolean => discipline.isTrainer === true,
          ) ?? []

        return trainerDisciplines
      }

      return this.disciplinesMenuData
    },
  },
  watch: {
    $route(): void {
      this.selectedDisciplineTab = Number(this.$route.params.id)
    },
  },
  async created(): Promise<void> {
    await this.loadDisciplinesForMenu(this.type)
    this.selectedDisciplineTab =
      this.selectedDefault != null ? this.selectedDefault : Number(this.$route.params.id)
    if (this.isDisciplineTutorial) {
      const oldDisciplinesIds = this.unlockDisciplinesIds.filter(
        (discipline) => discipline !== this.actualStageData.discipline,
      )
      this.selectedDisciplineTab = oldDisciplinesIds[0]
    }

    this.temporaryTab = Number(this.$route.params.id)
    this.loadNotifications()
  },
  methods: {
    ...mapActions(useDisciplineStore, ['loadDisciplinesForMenu']),
    ...mapActions(useResponseTaskStore, {
      loadNotifications: 'loadNotifications',
    }),
    isDisciplineUnlockTutorial(id: number): boolean {
      return this.isTutorial && this.actualStageData.discipline === id
    },
    menuClick(param: DisciplineMenuData): void {
      if (param.temporaryLock) return

      if (param == null || (this.isTutorial && this.actualStageData.discipline !== param.id)) return
      this.selectedDisciplineTab = param.id

      if (this.emitClickEvent) {
        this.$emit('onClickDiscipline', param)
      }
    },
    lockedIcon(item: number): string {
      const discipline = this.disciplinesMenuData?.find(
        (x: DisciplineMenuData): boolean => x.id === item,
      )
      if (discipline?.levelToUnlock >= 99) return 'time'
      else return 'lock-sm'
    },
    lockedText(item: number): string {
      const lockLevel = this.disciplinesMenuData?.find(
        (level: DisciplineMenuData): boolean => level.id === item,
      )
      return lockLevel?.levelToUnlock >= 99
        ? this.$t('common.comingSoon')
        : this.$replacePlaceholder(
            this.$replaceHtmlPlaceholders(
              this.$replacePlaceholder(this.$t('common.levelRequired'), '%s', '{b} %s {/b}'),
              'b',
              'text-texts-standard-important',
            ),
            '%s',
            lockLevel.levelToUnlock ?? '',
          )
    },
    iconClass(discipline: DisciplineMenuData, theme: string): string {
      return discipline.branchType === CareerBranch.Main
        ? `icon-career-${theme === 'light' && this.$isSsm ? 'gold' : theme}-72`
        : `icon-discipline-${discipline.id}-${theme}-70`
    },
    getItemId(item: DisciplineMenuData): number {
      return item.id
    },
    getNotificationsByType(index: number, disciplineId: number): number {
      if (this.notificationType === MECHANIC_CAREER) {
        const notifications = this.notifications.career_quests.filter(
          (notification: NotificationCareerQuest): boolean =>
            index !== 1 ? notification.discipline + 1 === index : notification.discipline === null,
        )
        return notifications ? notifications.length : 0
      }

      if (this.notificationType === 'shop') {
        const notifications = this.notifications.equipment_notifications?.filter(
          (notification: NotificationEquipment): boolean => {
            return notification.discipline === disciplineId
          },
        )

        return notifications ? notifications.length : 0
      }

      if (this.notificationType === 'laboratory') {
        const disciplineNotifications = this.notifications.research_notification.trees?.find(
          (e: TreeData): boolean => {
            return e.tree === 'discipline'
          },
        )
        const notifications = disciplineNotifications?.notifications?.find(
          (notification: TreeDataNotification): boolean => {
            return notification.discipline_id === disciplineId
          },
        )

        return notifications?.cells_id ? notifications.cells_id.length : 0
      }

      return 0
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/discipline-menu.scss';
@import '@/assets/styles/components/icons/discipline-icons.scss';
@import '@/assets/styles/components/icons/career-icons.scss';

.discipline-arrow {
  height: 100% !important;
}

.discipline-menu {
  &--slim {
    width: 85%;
    margin: 0 auto;
    .discipline-menu-tab {
      width: 7rem;
      margin: 0 1rem;
    }
  }
}

.discipline-menu-tab {
  box-shadow: 0 0 1.125rem 0 rgba(0, 0, 0, 0.5);

  &.available {
    &:hover {
      transform: scale(0.95) $skew-value;

      @if $isWsm {
        background-image: linear-gradient(to top, #d2bf58 0%, #a89947 25%, #0a1c34 100%);
        outline: solid 0.125rem #f7cd27;
      }

      @if $isSsm {
        background-image: linear-gradient(to right, #ffe719, #ffaa3c);
        outline: solid 0.188rem #ffdc96;
      }
    }

    &:active {
      transform: scale(0.85) $skew-value;

      @if $isWsm {
        outline: solid 0.125rem #f7cd27;
      }

      @if $isSsm {
        outline: solid 0.188rem #ffdc96;
      }
    }
  }

  &.active {
    box-shadow: unset;
    outline: solid 0.125rem #f7cd27;

    &:before {
      content: '';
      width: 1.813rem;
      height: 1.813rem;
      position: absolute;
      top: -0.75rem;
      transform: translateX(-50%);
      left: 50%;
      background: url($path-career + '/selected-arrow.avif') center no-repeat;
    }
  }

  &.selected {
    pointer-events: none;

    @if $isWsm {
      background-image: linear-gradient(to top, #ffef84, #f6c717);
      outline: 0.125rem solid transparent;
      box-shadow:
        inset 0.188rem 0 0 0 rgba(255, 255, 255, 0.4),
        inset -0.188rem 0 0 0 rgba(255, 255, 255, 0.4),
        inset 0 -0.125rem 0.063rem 0 rgba(255, 255, 255, 0.4),
        inset 0 0.25rem 0 0 rgba(255, 255, 255, 0.64);
    }

    @if $isSsm {
      background-image: linear-gradient(to right, #ffaa3c, #ffe719);
      outline: solid 0.188rem #ffdc96;
      box-shadow: 0.6px 5px 3px 0 rgba(0, 0, 0, 0.36);
    }
  }

  &.complete {
    @if $isWsm {
      box-shadow:
        inset 0.188rem 0 0 0 rgba(255, 255, 255, 0.3),
        inset -0.188rem 0 0 0 rgba(255, 255, 255, 0.3),
        inset 0 -0.125rem 0.063rem 0 rgba(255, 255, 255, 0.3),
        inset 0 0.25rem 0 0 rgba(255, 255, 255, 0.28);
      background-image: linear-gradient(to top, #49ac2e, #169410);
    }
    @if $isSsm {
      border: 0.125rem solid #7bf4e5;
      background-image: linear-gradient(to top, rgba(14, 231, 204, 0.85), rgba(8, 202, 178, 0.85));
    }

    &.selected {
      background: linear-gradient(to bottom, #f6c717, #feec7d);
      border: 0.125rem solid #fbe691;
      color: theme('colors.texts.standard.dark');
    }
  }

  .discipline-done-icon {
    position: absolute;
    width: 2rem;
    height: 2rem;
    bottom: -0.45rem;
    right: -0.45rem;
  }

  &-lock-background {
    width: 100%;
    height: 100%;
    background: rgba(11, 30, 53, 0.8);
    position: absolute;
    left: 0;
    top: 0;
  }

  &[data-event-badge-text] {
    @apply relative;

    &::after {
      @apply px-2 text-22 text-texts-standard-default font-bold uppercase absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
      content: attr(data-event-badge-text);
      background-image: linear-gradient(to top, #9f48ac, #8727c4, #9b5cd5);

      @if $isWsm {
        @apply italic;
        clip-path: polygon(8% 0%, 100% 0%, 92% 100%, 0% 100%);
        text-shadow: 0.6px 0.8px 0.9px rgba(0, 0, 0, 0.51);
      }
    }
  }
}

.temporary-lock {
  &-border {
    height: 0.125rem;
    width: 100%;
    background: linear-gradient(
      to right,
      rgba(139, 160, 175, 0),
      rgba(255, 255, 255, 0.5),
      rgba(139, 160, 175, 0)
    );
  }

  &-icon {
    transform: scale(0.6);
    top: -1.3rem;
    right: -1.5rem;
  }

  &-progress-bar {
    position: fixed;
    bottom: 0;
    left: -0.3rem;
  }
}
</style>
