<template>
  <section class="timer-section">
    <section v-if="activeState === ActiveState.NotStarted" class="waiting-to-start italic">
      <p class="text-texts-standard-default text-28">{{ $te('start') }}</p>
      <p class="text-texts-standard-default text-40">
        {{ tournamentBeginsInDate }}
        <span class="text-texts-standard-important">{{ tournamentBeginsInTime }}</span>
      </p>
    </section>
    <section
      v-if="
        (activeState === ActiveState.Draw ||
          activeState === ActiveState.Qualification ||
          isRecalculating) &&
        joinStatus === JoinStatus.Joined
      "
      class="in-progress italic"
    >
      <p class="text-texts-standard-default text-32 marginer">
        {{ getText }}
      </p>
      <app-timer
        v-if="timeEnd"
        :is-masters="true"
        class="timer"
        :time="timeEnd"
        @countdown-action="$emit('get-data')"
      />
    </section>
    <section
      v-if="activeState === ActiveState.Ended || joinStatus === JoinStatus.NotJoined"
      class="finished italic"
    />
  </section>
</template>

<script lang="ts">
import { getFormattedDateFromYMD, getFormattedTimeFromHMS, timeLeft } from '@/helpers'
import { ActiveState, DrawState, JoinStatus } from '@/interfaces/events/MastersPlayoffInterfaces'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface ComponentData {
  ActiveState: typeof ActiveState
  JoinStatus: typeof JoinStatus
}

export default defineComponent({
  name: 'TournamentTimer',
  props: {
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: false,
      default: new Date(),
    },
    activeState: {
      type: String as PropType<ActiveState>,
      required: true,
    },
    joinStatus: {
      type: String as PropType<JoinStatus>,
      required: true,
    },
    isRecalculating: {
      type: Boolean,
      required: true,
    },
    drawState: {
      type: String as PropType<DrawState>,
      default: '',
    },
  },
  emits: ['get-data'],
  data(): ComponentData {
    return {
      ActiveState,
      JoinStatus,
    }
  },
  computed: {
    tournamentBeginsInDate(): string {
      return getFormattedDateFromYMD(this.startDate)
    },
    tournamentBeginsInTime(): string {
      return getFormattedTimeFromHMS(this.startDate)
    },
    timeEnd(): number {
      return timeLeft(new Date(this.endDate).getTime(), true)
    },
    getText(): string {
      let state: string = ''
      if (this.activeState === ActiveState.Qualification) {
        state = this.$te('playoffQualificationOngoing')
      }
      if (this.activeState === ActiveState.Draw) {
        if (this.drawState === DrawState.QuarterFinal) {
          state = this.$te('quarterfinalInProgress')
        } else if (this.drawState === DrawState.SemiFinal) {
          state = this.$te('semifinalInProgress')
        } else {
          state = this.$te('finalInProgress')
        }
      }
      if (this.isRecalculating) state = this.$te('eventRecalculation')
      return state
    },
  },
})
</script>

<style lang="scss" scoped>
.timer-section {
  display: flex;
  flex-direction: column;
  margin-right: 3rem;
  width: 25rem;
}

.finished {
  width: 200px;
}

.marginer {
  margin-right: 3rem;
  margin-bottom: 0.2rem;
}

.in-progress {
  display: flex;
  width: 25rem;
  flex-direction: column;
  align-items: center;
}
</style>
