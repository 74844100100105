<template>
  <div id="club-member-settings">
    <popup-window
      popup-type="info"
      :popup-title="$t('club.membershipSettings')"
      @close="$emit('close', true)"
    >
      <div class="club-member-settings">
        <div class="club-member-settings-player flexing">
          <app-user
            :id="playerData.userId"
            class="club-member-settings-player-name"
            :country="playerData.threeLetterCode"
            :name="playerData.name"
            :role="playerData.role"
          />
        </div>
        <div v-for="(box, index) in settingsOptions" :key="index" class="club-member-settings-box">
          <p
            class="text-32 uppercase"
            :class="$isWsm ? 'text-texts-standard-name' : 'text-texts-standard-additional'"
          >
            {{ box.text }}
          </p>
          <app-button
            v-if="index === 0"
            btn-id="club-member-setting-button"
            btn-type="danger"
            :btn-text="$t('common.confirm')"
            @click="deleteMemberPopup"
          />

          <app-select
            v-else
            :options="clubRoles"
            :default="currentRole"
            :placeholder="currentRole"
            class="selectbox"
            @select="selectRole"
          />
        </div>
        <div class="club-member-settings-button">
          <app-button
            btn-id="club-member-setting-button"
            btn-type="primary"
            :btn-text="$t('profile.saveChanges')"
            :disabled="!isSaveAvailable"
            @click="saveChanges"
          />
        </div>

        <clubs-delete-popup
          v-if="deletePopup"
          :player-data="playerData"
          @close="closeDeletePopup"
          @update="deleteClubMember"
        />
        <popup-window
          v-if="savePopup"
          popup-type="submit"
          :popup-data="getChangeRoleSubmit"
          @close="savePopup = false"
          @action="changePlayerRole"
        />
      </div>
    </popup-window>
  </div>
</template>

<script lang="ts">
import ClubsDeletePopup from '@/components/Club/ClubsDeletePopup.vue'
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import { useManagementStore } from '@/store/pinia/clubs/managementStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { ClubPlayerData } from '@/interfaces/clubs/Clubs'
import { ClubMemberRoles } from '@/interfaces/clubs/ClubManagement'
import type { PopupData } from '@/interfaces/PopupData'

interface ClubRole {
  value: string
  name: string
}

interface ComponentData {
  ClubMemberRoles: typeof ClubMemberRoles
  currentRole: string
  deletePopup: boolean
  savePopup: boolean
  savePopupText: string
  selectedRole: Nullable<ClubRole>
  roleFromDatabase: string
  changedRole: string
}

export default defineComponent({
  name: 'ClubsMemberSettings',
  components: {
    PopupWindow,
    AppUser,
    ClubsDeletePopup,
  },
  props: {
    playerData: {
      type: Object as PropType<Nullable<ClubPlayerData>>,
      default: () => null,
    },
  },
  emits: ['close', 'update'],
  data(): ComponentData {
    return {
      ClubMemberRoles,
      currentRole: '',
      deletePopup: false,
      savePopup: false,
      savePopupText: '',
      selectedRole: null,
      roleFromDatabase: '',
      changedRole: '',
    }
  },
  computed: {
    settingsOptions(): { text: string }[] {
      return [{ text: this.$t('club.deleteMember') }, { text: this.$t('club.memberFunction') }]
    },
    clubRoles(): ClubRole[] {
      return [
        { value: ClubMemberRoles.Member, name: this.$t('club.member') },
        { value: ClubMemberRoles.Assistant, name: this.$t('club.assistant') },
        { value: ClubMemberRoles.Manager, name: this.$t('club.manager') },
      ]
    },
    getChangeRoleSubmit(): PopupData {
      return this.$filters.$submitPopupData({
        title: this.$t('club.memberFunction'),
        btnText: this.$t('common.confirm'),
        btnType: 'danger',
        btnId: 'delete-club-memmber-button',
        text: this.$t(this.getChangeRoleText),
      })
    },
    isSaveAvailable(): boolean {
      return this.roleFromDatabase !== this.changedRole // aktivny button len aky mam zmennu poziciu
    },
    getChangeRoleText(): string {
      if (this.selectedRole.value === ClubMemberRoles.Member) return 'club.removeAssistant'
      else if (this.selectedRole.value === ClubMemberRoles.Assistant) return 'club.addAssistant'
      return 'club.changeManager'
    },
  },
  created(): void {
    this.currentRole = this.playerData.role // na zaciatku ze zhodna z roleFromDatabase.
    this.roleFromDatabase = this.playerData.role // nastavim si poziciu ktoru hrac ma pred menením
    this.changedRole = this.playerData.role
  },

  methods: {
    ...mapActions(useManagementStore, ['changeRole']),
    saveChanges(): void {
      if (this.isSaveAvailable) {
        this.savePopup = true
      }
      this.savePopupText = ''
    },

    selectRole(role: ClubMemberRoles): void {
      this.selectedRole = this.clubRoles.find((item: ClubRole): boolean => item.value === role)
      this.currentRole = this.selectedRole.name // nastavenie zmeny pozicie
      this.selectRoleForDb(role)
    },
    selectRoleForDb(role: ClubMemberRoles): void {
      if (role === ClubMemberRoles.Manager) this.changedRole = ClubMemberRoles.Manager
      else if (role === ClubMemberRoles.Assistant) this.changedRole = ClubMemberRoles.Assistant
      else this.changedRole = ClubMemberRoles.Member
    },

    deleteMemberPopup(): void {
      this.deletePopup = true
    },

    closeDeletePopup(): void {
      this.deletePopup = false
      this.$emit('close', true)
    },

    deleteClubMember(event: boolean): void {
      this.$emit('update', event)
    },

    async changePlayerRole(): Promise<void> {
      await this.changeRole({
        member_id: this.playerData.userId,
        role: this.changedRole,
        user: this.playerData,
      })
      this.$emit('close', true)
      this.$emit('update', true)
    },

    translateRoles(role: ClubMemberRoles): string {
      if (role === ClubMemberRoles.Member) return this.$t('club.member')
      else if (role === ClubMemberRoles.Assistant) return this.$t('club.assistant')
      else return this.$t('club.manager')
    },
  },
})
</script>

<style lang="scss" scoped>
.club-member-settings {
  padding: 0 2.438rem;

  &-player {
    width: 100%;
    flex-direction: column;
    margin: 2.188rem 0;

    &-name {
      height: 3rem;
    }
  }

  &-box {
    width: 59.375rem;
    height: 5.625rem;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    @if $isWsm {
      background: #236d9b;
      border: 0.063rem solid #6c9ec4;
    }
    @if $isSsm {
      background: #1d1f2c;
      border: 0.063rem solid #4c648f;
    }
  }

  &-button {
    width: 100%;
    margin: 1.75rem 0;

    button {
      margin: 0 auto;
    }
  }
}
</style>
