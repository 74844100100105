export enum LoginFormContext {
  LOGIN = 'login',
  PASSWORD_RESET = 'password-reset',
  CONNECTION = 'connection',
  EMAIL_CHANGE = 'email-change',
}

export enum ConnectedAccountType {
  Email = 'email',
  Google = 'google',
  Facebook = 'facebook',
  Apple = 'apple',
}

export enum ConnectedAccountStatus {
  NotConnected = 'not-connected',
  Connected = 'connected',
  VerificationPending = 'verification-pending',
  CannotBeVerified = 'cannot-be-verified',
}
