<template>
  <div
    class="gems-bonus-badge flexing flex-col text-texts-standard-default uppercase"
    :class="[size, { 'no-bg': transparent }, `text-${textSize[size].text1}`]"
  >
    <p v-if="type === 'progressive'">{{ 'up to' }}</p>
    <p class="font-bold" :class="[`text-${textSize[size].text2}`]">{{ `+${bonus}%` }}</p>
    <p>{{ 'bonus' }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

interface ComponentData {
  textSize: {
    small: {
      text1: number
      text2: number
    }
    medium: {
      text1: number
      text2: number
    }
    big: {
      text1: number
      text2: number
    }
  }
}

export default defineComponent({
  name: 'GemsBonusBadge',
  props: {
    type: {
      type: String,
      default: 'flat',
      validator(value: string): boolean {
        return ['flat', 'progressive'].includes(value)
      },
    },
    bonus: {
      type: Number,
      default: 0,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
      validator(value: string): boolean {
        return ['small', 'medium', 'big'].includes(value)
      },
    },
  },
  data(): ComponentData {
    return {
      textSize: {
        small: {
          text1: 24,
          text2: 40,
        },
        medium: {
          text1: 32,
          text2: 48,
        },
        big: {
          text1: 50,
          text2: 72,
        },
      },
    }
  },
})
</script>
<style lang="scss" scoped>
.gems-bonus-badge {
  background: url($path-images + 'premium/gemsPackage/bonus-badge.avif') center no-repeat;
  background-size: contain;
  width: 8.5rem;
  height: 8.5rem;

  .no-bg {
    background: none;
  }

  & > p {
    line-height: 1.8rem;
  }

  &.medium {
    width: 10.625rem;
    height: 10.625rem;

    & > p {
      line-height: 2.4rem;
    }
  }

  &.big {
    width: 17rem;
    height: 17rem;

    & > p {
      line-height: 3.3rem;
    }
  }
}
</style>
