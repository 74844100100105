import {
  GAME_PASS_STARTS_CAP,
  GAME_PASS_ENERGY_CAP,
  GAME_PASS_DOUBLE_START_REGEN,
  GAME_PASS_ARENA_MULTIPLIER,
  GAME_PASS_TRAINING_MULTIPLIER,
  GAME_PASS_DOUBLE_ENERGY_REGEN,
  GAME_PASS_RESEARCH_SPEED_UP,
  GAME_PASS_INSTANT_EQUIPMENT_REPAIR,
  GAME_PASS_SKIP_REWARDED_ADS,
  GAME_PASS_DAILY_TASK_SLOT,
} from '@/globalVariables'

export const gamePassRewardsParser = (reward: {
  name: string
  bonus: string
}): { text: string; icon: string; subText1: string; subText2: string } => {
  let text = ''
  let icon = ''
  let subText1 = ''
  let subText2 = ''
  switch (reward.name) {
    case GAME_PASS_STARTS_CAP:
      text = 'researchCell.starts'
      icon = 'starts-limit'
      subText1 = 'gamePass.startLimitIncreased'
      subText2 = 'gamePass.bonusValidUntil'
      break
    case GAME_PASS_ENERGY_CAP:
      text = 'researchCell.energy'
      icon = 'energy-limit'
      subText1 = 'gamePass.energyLimitIncreased'
      subText2 = 'gamePass.bonusValidUntil'
      break
    case GAME_PASS_DOUBLE_START_REGEN:
      text = 'research.regenerationStart'
      icon = 'starts-regeneration'
      subText1 = 'gamePass.startRegenIncreased'
      subText2 = 'gamePass.bonusValidUntil'
      break
    case GAME_PASS_ARENA_MULTIPLIER:
      text = 'gamePass.competitionMultiplier'
      icon = `multiplier-${reward.bonus}`
      subText1 = 'gamePass.compMultiplierUnlocked'
      subText2 = 'gamePass.multiplierValidUntil'
      break
    case GAME_PASS_TRAINING_MULTIPLIER:
      text = 'mechanics.supertraining'
      icon = `multiplier-${reward.bonus}`
      subText1 = 'gamePass.trainingMultiplierUnlocked'
      subText2 = 'gamePass.multiplierValidUntil'
      break
    case GAME_PASS_DOUBLE_ENERGY_REGEN:
      text = 'research.regenerationEnergy'
      icon = 'energy-regeneration'
      subText1 = 'gamePass.energyRegenIncreased'
      subText2 = 'gamePass.bonusValidUntil'
      break
    case GAME_PASS_RESEARCH_SPEED_UP:
      text = 'gamePass.fastResearch'
      icon = 'fast-research'
      subText1 = 'gamePass.fastResearchUnlocked'
      subText2 = 'gamePass.bonusValidUntil'
      break
    case GAME_PASS_INSTANT_EQUIPMENT_REPAIR:
      text = 'gamePass.instantRepair'
      icon = 'instant-repair'
      subText1 = 'gamePass.instantRepairUnlocked'
      subText2 = 'gamePass.bonusValidUntil'
      break
    case GAME_PASS_SKIP_REWARDED_ADS:
      text = 'gamePass.noAdsRewards'
      icon = 'no-rewards-ads'
      subText1 = 'gamePass.noAdsRewardsUnlocked'
      subText2 = 'gamePass.bonusValidUntil'
      break
    case GAME_PASS_DAILY_TASK_SLOT:
      text = 'gamePass.extraDailyTasks'
      icon = 'daily-tasks-plus'
      subText1 = 'gamePass.extraDailyTasksUnlocked'
      subText2 = 'gamePass.bonusValidUntil'
      break
  }
  return { text, icon, subText1, subText2 }
}
