import {
  RANKING_POINTS,
  GEMS,
  MONEY,
  ENERGY,
  STARTS,
  TRAINING_POINTS,
  PRESTIGE,
  RESEARCH_POINTS,
  GALLERY_PIECES,
  BENEFITS,
  EXPERIENCE,
  CHAMPIONSHIP_POINTS,
  CATEGORY_BENEFIT,
} from '@/globalVariables'
import type Reward from '@/interfaces/Reward'

const iconsMapping = {
  [RANKING_POINTS]: 'ranking_points',
  [GEMS]: 'gems',
  [MONEY]: 'money',
  [ENERGY]: 'energy',
  [STARTS]: 'starts',
  [TRAINING_POINTS]: 'training_points',
  [PRESTIGE]: 'prestige',
  [RESEARCH_POINTS]: 'research_points',
  [GALLERY_PIECES]: 'gallery_pieces',
  [BENEFITS]: 'benefits',
  [CHAMPIONSHIP_POINTS]: 'championship_points',
  [EXPERIENCE]: 'experience', // todo tmp solution because of appmainicon and arenaview
}

type IconKey = keyof typeof iconsMapping

type IconValue = (typeof iconsMapping)[IconKey]

export const rewardIconsMapping = (reward: IconKey): IconValue => iconsMapping[reward]

export const getIconName = (reward: Reward): Reward['type'] => {
  if (!reward) return ''
  return reward.gdd_id ? `${reward.type}-${reward.gdd_id}` : reward.type
}

export const getRewardIconNameWithoutBonuxPostfix = (reward: Reward): Reward['name'] => {
  return reward.name === CATEGORY_BENEFIT
    ? `${reward.type}-${reward.level}`
    : (reward.name || reward.type).replace('_bonus', '')
}
