<template>
  <div class="club-active-employees text-36 text-texts-standard-default flex items-center">
    <div
      v-for="(employee, index) in employees"
      :key="index"
      :class="`${employee.type}-employee w-1/2 flex items-center ${
        employee.type === 'personal' ? 'justify-end' : 'justify-start'
      } `"
    >
      <span
        v-if="employee.type === 'club'"
        class="employee-icon"
        :class="{ active: employee.active }"
      />
      <section
        v-if="employee.active"
        class="text flex items-center employee-reward"
        :class="{ decoration: employee.canClaim }"
      >
        <p
          :class="[
            employee.canClaim ? 'text-texts-standard-positive' : 'text-texts-standard-additional',
          ]"
        >
          {{ $filters.$formatNumber(employee.rewards.value) }}
        </p>
        <app-main-icon :icon-size="32" :icon-name="employee.rewards.type" />
      </section>
      <span
        v-if="employee.type === 'personal'"
        class="employee-icon"
        :class="{ active: employee.active }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { ClubActiveEmployee } from '@/interfaces/clubs/Clubs'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'ClubsActiveEmployees',
  props: {
    employees: {
      type: Array as PropType<ClubActiveEmployee[]>,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.club-active-employees {
  width: 34rem;
  height: 5.938rem;

  .club-employee,
  .personal-employee {
    height: 4.188rem;
  }

  .personal-employee {
    border-right: 0.063rem solid #1a475e;
  }
}

.employee-icon {
  width: 5.375rem;
  height: 5.375rem;
  background: url($path-clubs + 'components/employees/icon-employee-sprite-86x86px.avif') 0 0
    no-repeat;
  display: inline-block;
  background-size: cover;

  &.active {
    background-position-x: -5.375rem;
  }
}

.employee-reward {
  z-index: 1;
  position: relative;

  &.decoration {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 93%;
      height: 0;
      mix-blend-mode: overlay;
      box-shadow: 0 0 31px 25px rgba(21, 105, 49, 0.5);
    }
  }
}
</style>
