<template>
  <div
    :id="'offer-' + offerData.store_id"
    :data-cy="'offer-' + offerData.store_id"
    class="offer-box relative"
    :style="{
      background:
        'url(' + pathImages + 'premium/offers/offer-' + offerName + '.avif) center no-repeat',
      backgroundSize: 'contain',
    }"
  >
    <main class="offer-box-main relative text-36 text-texts-standard-default uppercase">
      <aside class="offer-box-main-upper">
        <div class="offer-box-main-title flexing">
          <p class="offer-box-main-text text-center" v-html="getText"></p>
        </div>
        <div class="badge">
          <gems-bonus-badge :bonus="+offerData.bonus" size="big" :type="offerData.type" />
        </div>
      </aside>
    </main>
    <footer class="offer-box-footer flex items-center absolute w-full justify-between">
      <app-timer v-if="showFooterTimer" icon="refresh-md" :time="offerData.remaining_time" />
    </footer>
  </div>
</template>

<script lang="ts">
import GemsBonusBadge from '@/components/Premium/GemsPack/GemsBonusBadge.vue'
import { pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { Offer } from '@/interfaces/premium/Offer'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'OffersGemBonus',
  components: {
    GemsBonusBadge,
  },
  props: {
    offerData: {
      type: Object as PropType<Nullable<Offer>>,
      default: () => null,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    getText(): string {
      let text = this.$t(`premiumGroup.${this.offerData.store_id}_about`)
      text = this.$replaceHtmlPlaceholders(text, 'b', 'text-texts-standard-important')
      text = this.$replacePlaceholder(text, '%s', `${this.offerData.bonus}%`)
      return text
    },
    offerName() {
      return this.offerData.store_id
    },
    showFooterTimer(): number {
      return this.offerData.remaining_time
    },
  },
})
</script>

<style lang="scss" scoped>
.offer-box {
  height: 44.875rem;
  width: 78.75rem;
  margin-right: 1.5rem;
  position: relative;

  &-main {
    height: 38.5rem;

    &-title {
      text-align: center;
      width: 100%;
      height: 7rem;
      position: absolute;
      bottom: 0.4rem;
    }
  }

  &-footer {
    bottom: 0.75rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .duration-timer {
    width: 33.125rem;
    height: 3.125rem;
    position: absolute;
    bottom: 5rem;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(to right, transparent 5%, rgba(188, 27, 27, 1), transparent 95%);
  }

  .badge {
    position: absolute;
    left: 40rem;
    top: 8rem;
  }
}
</style>
