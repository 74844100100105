import { cameraConfig as CameraConfigWSM } from '@/map-phaser-new/config/winter-sports/camera/mainConfig'
import { cameraConfig as CameraConfigSSM } from '@/map-phaser-new/config/summer-sports/camera/mainConfig'
import {
  WEB_DEFAULT,
  WEB_2K,
  WEB_4K,
  MOBILE_DEFAULT,
  MOBILE_SMALL,
  TABLET,
  GAMES_KEYS,
} from '@/map-phaser-new/constants'
import type { CameraConfigInterface } from '@/map-phaser-new/interfaces/camera/cameraConfigInterface'
import { isApple } from '@/plugins'
import { isMobile } from '@/plugins/isMobile'

const cameraConfigMapper = {
  [GAMES_KEYS.WinterSports]: CameraConfigWSM,
  [GAMES_KEYS.SummerSports]: CameraConfigSSM,
}

type pixelRatioClass = 'subTwo' | 'subThree' | 'aboveThree'

export class CameraConfigurationResolver {
  private isAppleDevice: boolean
  private cameraConfig: CameraConfigInterface
  private defaultZoomKey: string
  private minZoomKey: string
  private maxZoomKey: string
  private zoomChangeValueKey: string
  private webZoomLevel: string
  private mobileZoomLevel: string
  private ratioClass: pixelRatioClass
  private memoizeObject: Record<string, number> = {}

  public constructor(gameKey: string) {
    this.cameraConfig = cameraConfigMapper[gameKey]
    this.isAppleDevice = isApple()
  }

  /**
   * Gets the default zoom value for the current device caches and returns it
   * @returns {number}
   */
  public getDefaultZoom(): number {
    if (this.memoizeObject[this.defaultZoomKey]) return this.memoizeObject[this.defaultZoomKey]

    this.defaultZoomKey = this.getDeviceKeyExtension('defaultZoom')
    this.memoizeObject[this.defaultZoomKey] =
      this.cameraConfig.zoomLevels[this.getPixelRatioClass()][this.getZoomLevelKey()][
        this.defaultZoomKey
      ]
    return this.memoizeObject[this.defaultZoomKey]
  }

  /**
   * Gets the minimum zoom value for the current device caches and returns it
   * @returns {number}
   */
  public getMinZoom(): number {
    if (this.memoizeObject[this.minZoomKey]) return this.memoizeObject[this.minZoomKey]

    this.minZoomKey = this.getDeviceKeyExtension('minZoom')
    this.memoizeObject[this.minZoomKey] =
      this.cameraConfig.zoomLevels[this.getPixelRatioClass()][this.getZoomLevelKey()][
        this.minZoomKey
      ]
    return this.memoizeObject[this.minZoomKey]
  }

  /**
   * Gets the maximum zoom value for the current device caches and returns it
   * @returns {number}
   */
  public getMaxZoom(): number {
    if (this.memoizeObject[this.maxZoomKey]) return this.memoizeObject[this.maxZoomKey]

    this.maxZoomKey = this.getDeviceKeyExtension('maxZoom')
    this.memoizeObject[this.maxZoomKey] =
      this.cameraConfig.zoomLevels[this.getPixelRatioClass()][this.getZoomLevelKey()][
        this.maxZoomKey
      ]
    return this.memoizeObject[this.maxZoomKey]
  }

  /**
   * Gets the zoom change value for the current device caches and returns it
   * @returns {number}
   */
  public getZoomChangeValue() {
    if (this.memoizeObject[this.zoomChangeValueKey])
      return this.memoizeObject[this.zoomChangeValueKey]

    this.zoomChangeValueKey = 'zoomChangeValue'
    this.memoizeObject[this.zoomChangeValueKey] = this.cameraConfig.zoomChangeValue
    return this.memoizeObject[this.zoomChangeValueKey]
  }

  private getPixelRatioClass(): pixelRatioClass {
    if (this.ratioClass) return this.ratioClass

    if (window.devicePixelRatio <= 2) {
      this.ratioClass = 'subTwo'
    } else if (window.devicePixelRatio <= 3) {
      this.ratioClass = 'subThree'
    } else {
      this.ratioClass = 'aboveThree'
    }

    return this.ratioClass
  }

  /**
   * Gets the zoom level key for the current device
   * @returns {string}
   */
  private getZoomLevelKey(): string {
    if (isMobile()) {
      return this.getMobileZoomLevelKey(window.innerWidth)
    }

    return this.getWebZoomLevelKey(window.innerWidth * window.devicePixelRatio)
  }

  /**
   * Gets the zoom level key for mobile devices
   * @param innerWidth {number}
   * @returns {string}
   */
  private getMobileZoomLevelKey(innerWidth: number): string {
    if (this.mobileZoomLevel) return this.mobileZoomLevel

    if (
      innerWidth > this.cameraConfig.breakpointMobile &&
      innerWidth < this.cameraConfig.breakpointTablet
    ) {
      this.mobileZoomLevel = TABLET
    } else if (innerWidth <= this.cameraConfig.breakpointMobile) {
      this.mobileZoomLevel = MOBILE_SMALL
    } else {
      this.mobileZoomLevel = MOBILE_DEFAULT
    }

    return this.mobileZoomLevel
  }

  /**
   * Gets the zoom level key for web devices
   * @param innerWidth {number}
   * @returns {string}
   */
  private getWebZoomLevelKey(innerWidth: number): string {
    if (this.webZoomLevel) return this.webZoomLevel

    if (innerWidth >= this.cameraConfig.breakpoint4K) {
      this.webZoomLevel = WEB_4K
    } else if (
      innerWidth > this.cameraConfig.breakpoint2K &&
      innerWidth < this.cameraConfig.breakpoint4K
    ) {
      this.webZoomLevel = WEB_2K
    } else {
      this.webZoomLevel = WEB_DEFAULT
    }

    return this.webZoomLevel
  }

  /**
   * Gets the device key extension
   * @param key {string}
   * @returns {string}
   */
  private getDeviceKeyExtension(key: string): string {
    return this.isAppleDevice ? `${key}Apple` : key
  }
}
