<template>
  <div :id="detailData.benefit_gdd_id.toString()" class="benefit-detail flexing">
    <div ref="animation0" class="benefit-detail-leftpart">
      <div class="benefit-detail-leftpart-bg" />
      <div ref="animation0a" class="benefit-detail-leftpart-image" :style="setBackgroundImage" />
    </div>
    <div class="benefit-detail-rightpart">
      <h2
        ref="animation1"
        class="text-texts-standard-important text-50 font-bold animated-element--faded"
      >
        {{ detailData.benefit_name }}
      </h2>
      <div
        ref="animation2"
        class="benefit-detail-rightpart-rarity animated-element--faded"
        :class="'benefit-detail-rightpart-rarity-' + detailData.benefit_rarity"
      >
        <p class="text-texts-standard-default text-36 uppercase">
          {{ detailData.benefit_rarity }}
        </p>
      </div>
      <div
        ref="animation3"
        class="benefit-detail-rightpart-texarea text-texts-standard-default text-36 uppercase animated-element--faded"
      >
        <!-- eslint-disable vue/no-v-html -->
        <p class="benefit-detail-rightpart-texarea-center" v-html="amountText" />
        <!-- eslint-enable -->
      </div>
      <div ref="animation4" class="benefit-detail-rightpart-own animated-element--faded">
        <p
          class="own-text text-30 uppercase text-left"
          :class="$isWsm ? 'text-texts-standard-name' : 'text-texts-standard-additional'"
        >
          {{ $t('benefits.count') }}
        </p>
        <div class="benefit-detail-rightpart-own-box">
          <p class="text-texts-standard-default text-60 font-bold not-italic">
            {{ $filters.$formatNumber(detailData.amount) }}
          </p>
        </div>
      </div>
      <div
        v-if="detailData.benefit_rarity !== 'gold'"
        ref="animation5"
        class="animated-element--faded"
      >
        <app-button
          btn-id="benefit-detail-rightpart-btn"
          btn-size="md"
          btn-type="secondary"
          :btn-text="$t('benefits.upgrade')"
          @click="$emit('showUpgrade')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ATTRIBUTE, pathImages } from '@/globalVariables'
import { animateElement } from '@/helpers'

import type { BenefitSlotData } from '@/interfaces/Benefits'
import type { PropType } from 'vue'

interface ComponentData {
  showUpgrade: boolean
  rarities: string[]
}

export default defineComponent({
  name: 'BenefitDetail',
  props: {
    detailData: {
      type: Object as PropType<BenefitSlotData>,
      required: true,
    },
  },
  emits: ['showUpgrade'],
  data(): ComponentData {
    return {
      showUpgrade: false,
      rarities: ['bronze', 'silver', 'gold'],
    }
  },
  computed: {
    setBackgroundImage(): string {
      return `background-image: url(${pathImages}benefits/detail/benefit-detail-${this.detailData.benefit_gdd_id}.avif);`
    },
    nextRarity(): string {
      return this.rarities[
        this.rarities.findIndex(
          (rarity: string): boolean => rarity === this.detailData.benefit_rarity,
        ) + 1
      ]
    },
    setText(): string {
      if (this.detailData.benefit_type === ATTRIBUTE) return 'benefits.benefit_attribute'
      else if (this.detailData.benefit_type === 'cash') return 'benefits.benefit_money'
      else if (this.detailData.benefit_type === 'training_points')
        return 'benefits.benefit_training_points'
      else return ''
    },
    amountText(): string {
      return this.$replacePlaceholder(
        this.$replaceHtmlPlaceholders(
          this.$replacePlaceholder(this.$t(this.setText), '%s', '{b} %s {/b}'),
          'b',
          'text-texts-standard-important',
        ),
        '%s',
        this.detailData.benefit_effect +
          (this.detailData.benefit_effect_unit === 'percentage' ? '%' : ''),
      )
    },
  },
  mounted(): void {
    this.animateScene()
  },
  methods: {
    animateScene(): void {
      animateElement(this.$refs.animation1, 'fade-in', '0.67s', 67)
      animateElement(this.$refs.animation2, 'fade-in', '0.67s', 200)
      animateElement(this.$refs.animation3, 'fade-in', '0.67s', 333)

      // assigning a class will start the animation
      this.$refs.animation0.classList.add('animated-element--opacity-breathe-loop-medium')
      animateElement(this.$refs.animation0a, 'flash-blur', '2.33s', 67)

      animateElement(this.$refs.animation4, 'fade-in', '0.67s', 467)

      const conditionallyAnimatedElement = this.$refs.animation5
      if (conditionallyAnimatedElement) {
        animateElement(conditionallyAnimatedElement, 'fade-in', '0.67s', 600)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/benefits/benefitDetail.scss';
@import '@/assets/styles/animations.scss';
</style>
