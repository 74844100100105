<template>
  <div class="grand-prize-badges">
    <div :class="'gp-types flexing type-' + badge + '-' + (size === 'xl' ? 'lg' : size)">
      <div v-if="reward && reward.name" class="reward">
        <app-main-icon :icon-size="getIconSizeValue(size)" :icon-name="reward.name" />
        <p :class="'text-texts-standard-default ' + getTextSizeValue(size)">
          {{ reward.value }}
        </p>
        <div
          v-if="hasBadgeText"
          :class="[
            'badge-text badge-text-top badge-text-' + badge + '-' + (size === 'xl' ? 'lg' : size),
          ]"
        />
      </div>
      <section v-else>
        <div :class="'badge-star badge-star-' + badge + '-' + size" />
        <div :class="'badge-text badge-text-' + badge + '-' + size" />
      </section>
      <div v-if="isGlowable && hasEffect">
        <div class="glow--main" />
        <div v-if="badge === 'gold'">
          <div v-for="n in 3" :key="n" class="glow--complementary" />
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type Reward from '@/interfaces/Reward'

export default defineComponent({
  name: 'GrandPrizeBadges',
  props: {
    badge: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'md',
      validator(value: string): boolean {
        return ['sm', 'md', 'lg', 'xl'].includes(value)
      },
    },
    reward: {
      type: Object as PropType<Nullable<Reward>>,
      default: () => null,
    },
    hasBadgeText: {
      type: Boolean,
      default: true,
    },
    isGlowable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasEffect(): boolean {
      return ['silver', 'gold'].includes(this.badge)
    },
  },
  methods: {
    getIconSizeValue(size: string): number {
      switch (size) {
        case 'xl':
          return 120
        case 'lg':
          return 80
        default:
          return 48
      }
    },
    getTextSizeValue(size: string): string {
      switch (size) {
        case 'xl':
          return 'text-36'
        case 'lg':
          return 'text-34'
        default:
          return 'text-24'
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/grand-prize/grandPrizeBages.scss';
</style>
