<template>
  <div
    class="tooltip-season flex flex-col justify-center items-center text-texts-standard-additional"
  >
    <p
      class="tooltip-season-header text-36 font-bold uppercase"
      :class="{
        'text-36': $isWsm,
        'text-40': $isSsm,
      }"
    >
      {{ $t('seasons.season') }}
      <span class="text-texts-standard-important">
        {{ isPreviousSeason ? seasonEndStats.previousSeasonMonth : seasonEndStats.seasonMonth }}
      </span>
      /
      <span>
        {{ isPreviousSeason ? seasonEndStats.previousSeasonYear : seasonEndStats.seasonYear }}
      </span>
    </p>
    <p class="tooltip-season-title text-32 uppercase">
      {{ $t('seasons.arenaLevel') }}
    </p>

    <div class="tooltip-season-arena">
      <div class="badge-level tooltip-season-arena-badge">
        {{
          isPreviousSeason ? seasonEndStats.previousArenaLevel : seasonEndStats.currentArenaLevel
        }}
      </div>
    </div>

    <p class="tooltip-season-title text-32 uppercase">
      {{ $t('seasons.rankingPoints') }}
    </p>
    <parameter-box
      class="tooltip-season-box"
      :value="isPreviousSeason ? seasonEndStats.seasonalRp : currentRankingPoints"
    />
  </div>
</template>

<script lang="ts">
import ParameterBox from '@/components/ParameterBox.vue'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { SeasonEnd } from '@/interfaces/Seasons'

export default defineComponent({
  name: 'SeasonsEndTooltipSeason',
  components: { ParameterBox },
  props: {
    seasonEndStats: {
      type: Object as PropType<SeasonEnd>,
      required: true,
    },
    currentRankingPoints: {
      type: Number,
      required: true,
    },
    isPreviousSeason: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.tooltip-season {
  &-header {
    margin-bottom: 2rem;
  }

  &-title {
    padding-right: 3rem;
    padding-left: 3rem;

    @if $isWsm {
      background-image: linear-gradient(
        90deg,
        rgba(108, 158, 196, 0) 0%,
        rgba(108, 158, 196, 0.5) 50%,
        rgba(108, 158, 196, 0) 100%
      );
    }

    @if $isSsm {
      background-image: linear-gradient(
        to right,
        transparent,
        rgba(86, 98, 127, 0.9),
        rgba(86, 98, 127, 0.9),
        transparent
      );
    }
  }

  &-arena {
    background: url($path-images + 'arena/building/BUILDING_ARENA_SMALLEST_LVL5.avif') center
      no-repeat;
    background-size: 100% 100%;
    width: 25rem;
    height: 12.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    position: relative;

    &-badge {
      position: absolute;
      top: -2rem;
      right: 1rem;
    }
  }

  &-box {
    font-weight: 400;
    margin-top: 1rem;
  }
}
</style>
