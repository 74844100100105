<template>
  <div class="daily-task app-page-wrapper absolute">
    <div class="daily-task-wrapper relative">
      <menu-component
        menu-type="dailyTask"
        :title="$t('career.quests')"
        :sub-menu-active="true"
        :sub-menu="subMenu"
      />
      <div class="daily-task-main-wrapper w-full safe-area" :class="$isMobile() ? 'mobile' : 'web'">
        <header
          v-if="hasMechanic(MECHANIC_DAILY_QUESTS)"
          class="daily-task-main-header w-full items-center flex text-texts-standard-default relative"
        >
          <app-icon icon-name="info-rounded" />
          <p class="text-28 header-text">
            {{ tasksCountText }}
          </p>
          <div
            v-if="nextRefresh !== undefined"
            class="timer-info absolute text-28 text-texts-standard-default flexing"
          >
            <p class="uppercase">
              {{ $t('dailyTasksUi.resetTasksIn') }}
            </p>
            <app-timer class="daily-timer" :time="nextRefresh" @countdown-action="getTasksData" />
          </div>
        </header>
        <app-scrollbar
          v-if="hasMechanic(MECHANIC_DAILY_QUESTS)"
          height="100%"
          width="100%"
          slide="y"
          scroll="y"
        >
          <daily-task-main-box
            :boxes="mainBoxes"
            :is-button-disabled="isButtonDisabled"
            @claim="claimRewards"
          />
          <daily-task-sub-box
            :sub-boxes="boxes"
            :is-button-disabled="isButtonDisabled"
            @popup="showNewTaskPopup"
            @claim="claimRewards"
          />
        </app-scrollbar>
        <info-popup
          v-if="showPopup"
          :popup-title="$t('dailyTasksUi.newTask')"
          @close="showPopup = false"
        >
          <section class="popup-container m-auto">
            <section
              class="font-bold text-36 text-texts-standard-important tasks-text flex items-center m-auto"
            >
              <p class="tasks-text-text revert-skew">
                {{ $t('dailyTasksUi.possibleTasks') }}
              </p>
              <div class="tooltip">
                <tippy theme="WSM" placement="right" max-width="43rem">
                  <app-icon icon-name="info-70" class="tasks-icon" />
                  <template #content>
                    <div class="tooltip-content text-texts-standard-default">
                      <p class="tooltip-content-top text-36 font-bold w-full relative">
                        {{ $t('dailyTasksUi.possibleTasks') }}
                      </p>
                      <section
                        v-for="(option, index) in possibleTasks"
                        :key="index"
                        class="tooltip-content-rows m-auto flex items-center text-30 text-texts-standard-default relative justify-start"
                      >
                        <p class="line-clamp-2">
                          {{
                            processTaskName(
                              option.task.name,
                              option.task.target_value,
                              option.task.discipline_id,
                            )
                          }}
                        </p>
                      </section>
                    </div>
                  </template>
                </tippy>
              </div>
            </section>
            <p class="text-36 text-texts-standard-default popup-container-text">
              {{ $t('dailyTasksUi.newTaskGenerateConfirm') }}
            </p>
            <app-multi-button
              :count="newTaskPrice"
              btn-type="credit"
              class="popup-button"
              multi-width="47.375rem"
              :btn-text="$t('dailyTasksUi.newTask')"
              :disabled="gems.value < newTaskPrice"
              @click="generateNewTask"
            >
              <template #rightPart>
                {{ newTaskPrice }}
              </template>
            </app-multi-button>
          </section>
        </info-popup>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import DailyTaskMainBox from '@/components/DailyTask/DailyTaskMainBox.vue'
import DailyTaskSubBox from '@/components/DailyTask/DailyTaskSubBox.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import {
  ASSIGNED,
  CLAIMED,
  MECHANIC_DAILY_QUESTS,
  careerDailyTasksClaimEndpoint,
  careerDailyTasksEndpoint,
  careerGenerateNewDailyTaskEndpoint,
} from '@/globalVariables'
import type {
  CareerDailyTasksResponse,
  DailyTask,
  DailyTaskMainBoxData,
  DailyTaskBox,
  DailyTaskStat,
  MainDailyTaskReward,
  Parameter,
  PossibleTask,
} from '@/interfaces/DailyTask'
import { EventMechanics, EventType } from '@/interfaces/events/EventInfo'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { MenuObject } from '@/interfaces/MenuObject'

interface ComponentData {
  MECHANIC_DAILY_QUESTS: typeof MECHANIC_DAILY_QUESTS
  showPopup: boolean
  nextRefresh: number
  mainBoxes: DailyTaskMainBoxData[]
  boxes: DailyTaskBox[]
  newTaskPrice: number
  possibleTasks: PossibleTask[]
  exchangeTaskId: string
  totalTasks: number
  fulfilledTasks: number
  isButtonDisabled: Record<string, boolean>
}

export default defineComponent({
  components: {
    DailyTaskMainBox,
    DailyTaskSubBox,
    InfoPopup,
  },
  data(): ComponentData {
    return {
      MECHANIC_DAILY_QUESTS,
      showPopup: false,
      nextRefresh: undefined,
      mainBoxes: [],
      boxes: [],
      newTaskPrice: 0,
      possibleTasks: [],
      exchangeTaskId: null,
      totalTasks: 0,
      fulfilledTasks: 0,
      isButtonDisabled: {},
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      gems: 'getGems',
      hasMechanic: 'hasMechanic',
      getMechanicUnlock: 'getMechanicUnlock',
    }),
    ...mapState(useEventInfoStore, {
      eventType: 'getEventType',
      eventMechanics: 'getEventMechanics',
      eventTeaserActive: 'getEventTeaserActive',
      eventActive: 'getEventActive',
      eventCollectionActive: 'getEventCollectionActive',
      eventLocked: 'getEventLocked',
      eventMechanicByType: 'getEventMechanicByType',
    }),
    tasksCountText(): string {
      return `${this.$t('dailyTasksUi.uncompletedTasks')}: 
      ${this.fulfilledTasks}/${this.totalTasks}`
    },
    requiredEventMechanic(): EventMechanics {
      if (this.eventType === EventType.AutumnFair) {
        return EventMechanics.BountyBoard
      }
      return EventMechanics.Taskchain
    },
    subMenu(): Record<number, MenuObject> {
      return {
        1: {
          text: this.$t('career.dailyQuests'),
          route: this.$getWebView('DailyTask'),
          current: this.$getWebView('DailyTask'),
        },
        2: {
          text: this.$t('career.career'),
          route: this.$getWebView('TasksCareer'),
        },
        3: {
          text: this.$t('event.eventsTasks'),
          route: this.$getWebView('TasksEvent'),
          isDisabled: this.eventLocked, // Zamknute len ked nema hrac nema splneny karierny task 9.
          disabledText: this.eventType
            ? this.$replacePlaceholder(
                this.$t('common.mainCareerTaskRequired'),
                '%s',
                this.getMechanicUnlock(this.eventMechanicByType).toString(),
              )
            : '',
          isHidden:
            !this.eventMechanics.includes(this.requiredEventMechanic) ||
            !this.eventActive ||
            this.eventTeaserActive ||
            this.eventCollectionActive, // Schovane ak nie je aktivna taskchain mechanika alebo event alebo je teaser alebo je preberacia faza.
        },
      }
    },
  },
  async created(): Promise<void> {
    await this.getTasksData()
  },
  methods: {
    async getTasksData(): Promise<void> {
      const tasksData = await this.$axios.get<{}, CareerDailyTasksResponse>(
        careerDailyTasksEndpoint,
      )
      this.nextRefresh = tasksData?.next_refresh
      if (tasksData.daily_tasks && tasksData.daily_tasks.length) {
        this.getMainBoxes(tasksData.daily_tasks)
        this.getBoxes(tasksData.daily_tasks)
        this.totalTasks = tasksData.daily_tasks.length
        this.fulfilledTasks = tasksData.daily_tasks.filter(
          (task: DailyTask): boolean => task.state === ASSIGNED,
        ).length
      }
    },
    getRewardIconName(rewardName: string, rewardRarity: string = null): string {
      if (rewardName === 'grandPrize') {
        return 'gp-' + rewardRarity
      } else {
        return rewardName
      }
    },
    processTaskName(taskName: string, goal: number, discipline: number = 0): string {
      taskName = this.$t('dailyTask.' + taskName)
      taskName = this.$replacePlaceholder(taskName, '%s', goal)
      taskName = this.$replacePlaceholder(taskName, '{2to4}', goal)
      taskName = this.$replacePlaceholder(
        taskName,
        '{discipline}',
        this.$translateDiscipline(discipline),
      )
      return taskName
    },
    getTaskText(task: DailyTask): string {
      let taskName = task.task?.parameters
        ?.find((param: Parameter): boolean => param.name === 'name')
        ?.value.toString()
      const goal = Number(
        task.daily_task_stats?.find((stat: DailyTaskStat): boolean => stat.name === 'target_value')
          ?.value,
      )
      const discipline = Number(
        task.daily_task_stats?.find((stat: DailyTaskStat): boolean => stat.name === 'discipline_id')
          ?.value,
      )
      if (taskName) {
        taskName = this.processTaskName(taskName, goal, discipline)
      }
      return taskName
    },
    getMainBoxes(tasks: DailyTask[]): void {
      this.mainBoxes = tasks
        .filter((task: DailyTask): boolean => task.slot === null)
        .map((task: DailyTask): DailyTaskMainBoxData => {
          const goal = Number(
            task?.daily_task_stats?.find(
              (stat: DailyTaskStat): boolean => stat.name === 'target_value',
            )?.value,
          )
          return {
            id: task.id,
            text: this.$replacePlaceholder(this.$t('dailyTask.task1'), '{2to4}', goal),
            current: Number(
              task?.daily_task_stats?.find(
                (stat: DailyTaskStat): boolean => stat.name === 'current_value',
              )?.value,
            ),
            goal,
            rewards: task?.task?.rewards.map(
              (reward: MainDailyTaskReward): MainDailyTaskReward => ({
                ...reward,
                name: this.getRewardIconName(reward.name, reward.rarity),
              }),
            ),
            isClaimed: task?.state === CLAIMED,
            isLocked: task?.locked,
          }
        })
    },
    getBoxes(tasks: DailyTask[]): void {
      this.boxes = tasks.reduce((finalArray: DailyTaskBox[], task: DailyTask): DailyTaskBox[] => {
        if (task.slot) {
          const finalTask = {
            id: task.id,
            slots: task.slots,
            state: task.state,
            goal: Number(
              task.daily_task_stats?.find(
                (stat: DailyTaskStat): boolean => stat.name === 'target_value',
              )?.value,
            ),
            current: Number(
              task.daily_task_stats?.find(
                (stat: DailyTaskStat): boolean => stat.name === 'current_value',
              )?.value,
            ),
            text: this.getTaskText(task),
            link: task.task?.parameters
              ?.find((param: Parameter): boolean => param.name === 'link')
              ?.value.toString(),
            rewards: task.task?.rewards.map((reward: MainDailyTaskReward): MainDailyTaskReward => {
              reward.name = this.getRewardIconName(reward.name, reward.rarity)
              return reward
            }),
            exchangePrice: 0,
            possibleTasks: [] as PossibleTask[],
          }
          if (task.possible_tasks?.length) {
            finalTask.exchangePrice = parseInt(
              task.task?.parameters
                ?.find((param: Parameter): boolean => param.name === 'new_task_price')
                ?.value.toString(),
            )
            finalTask.possibleTasks = task.possible_tasks
          }
          finalArray.push(finalTask)
        }
        return finalArray
      }, [])
    },
    async claimRewards(taskId: string): Promise<void> {
      if (!taskId) return
      if (taskId in this.isButtonDisabled) return

      const task = [...this.mainBoxes, ...this.boxes].find(
        (box: DailyTaskBox): boolean => box.id === taskId,
      )
      if (task.current < task.goal) return

      try {
        this.isButtonDisabled[taskId] = true
        await this.$axios.put<{}, true>(careerDailyTasksClaimEndpoint, {
          daily_task_id: taskId,
        })
        this.isButtonDisabled[taskId] = false
      } catch (error: unknown) {
        console.error(error)
      }

      this.getTasksData()
    },
    showNewTaskPopup(currentTask: DailyTaskBox): void {
      this.newTaskPrice = currentTask.exchangePrice
      this.possibleTasks = currentTask.possibleTasks
      this.exchangeTaskId = currentTask.id
      this.showPopup = true
    },
    async generateNewTask(): Promise<void> {
      if (this.exchangeTaskId && this.gems.value >= this.newTaskPrice) {
        this.showPopup = false
        try {
          await this.$axios.put<{}, DailyTask>(careerGenerateNewDailyTaskEndpoint, {
            daily_task_id: this.exchangeTaskId,
          })
        } catch (error: unknown) {
          console.error(error)
        }
        this.getTasksData()
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.daily-task-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  position: relative;
}

.daily-task-main-wrapper {
  display: flex;
  // do not define any wrapping width in rem because it will negatively affect the iPhone,
  // however, for the iPad, it would be okay
  min-height: 0;
  flex-direction: column;
  width: 97%;
  margin: 0 auto;
}

.daily-task-main {
  &-header {
    width: 100%;
    height: 6.25rem;
    margin: 0 auto;

    .header-text {
      margin-left: 1.3125rem;
    }

    .timer-info {
      right: 0;

      .daily-timer {
        margin-left: 1rem;
      }
    }
  }
}

.popup-container {
  width: 47.4375rem;

  .tasks-text {
    margin-top: 3.125rem;
    width: 25.6875rem;
    height: 4.375rem;
    transform: $skewX-value;
    @if $isWsm {
      border: 0.125rem solid #528ebf;
      background: linear-gradient(to top, #4787b4, #2b4e67);
    }
    @if $isSsm {
      border: 0.125rem solid #586b9d;
      background: linear-gradient(to right, rgba(52, 65, 93, 0.85), rgba(39, 50, 73, 0.85));
    }

    &-text {
      width: 21rem;
    }

    .tooltip {
      position: absolute;
      right: -0.5rem;
    }

    .tasks-icon {
      @if $isWsm {
        transform: skewX(6deg);
      }
    }
  }

  &-text {
    margin-top: 3.125rem;
  }

  .popup-button {
    margin-top: 2rem;
    margin-bottom: 1.875rem;
  }
}

.tooltip-content {
  &-top {
    width: 30.25rem;
    margin-top: -1.5rem;
    height: 3.125rem;
    background: radial-gradient(circle farthest-corner at top center, #377fb8 7%, #fffdfd00 78%);
    margin-bottom: 0.625rem;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      height: 0.125rem;
      opacity: 0.5;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.016),
        #fff,
        rgba(255, 255, 255, 0)
      );
    }
  }

  &-rows {
    width: 30.25rem;
    height: fit-content;
    padding: 1rem 0;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      height: 0.125rem;
      opacity: 0.5;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.016),
        #fff,
        rgba(255, 255, 255, 0)
      );
    }
  }

  &-rows:nth-last-child(1):after {
    display: none;
  }
}
</style>
