<template>
  <div class="app-timer-progressbar relative">
    <app-button
      btn-type="confirm"
      btn-size="md"
      :text-upper-case="false"
      class="app-timer-progressbar-time"
    >
      <div class="flexing">
        <app-icon icon-name="time" />
        <p class="text-texts-standard-default text-36 font-bold">
          <vue-countdown
            v-slot="{ days, hours, minutes, seconds }"
            :time="timeRemaining < 0 ? 0 : timeRemaining * 1000"
            @end="$emit('timerAction')"
          >
            <p class="text-texts-standard-default pl-2">
              {{ formatTime(days, hours, minutes, seconds) }}
            </p>
          </vue-countdown>
        </p>
      </div>
    </app-button>
    <div class="app-timer-progressbar-progress relative">
      <div :style="{ width: progressBarWidth }" class="app-timer-progressbar-progress-inner" />
    </div>
  </div>
</template>

<script lang="ts">
import { formatTime } from '@/helpers'
import { defineComponent } from 'vue'

interface ComponentData {
  currentTime: number
}

export default defineComponent({
  name: 'AppTimerProgressbar',
  props: {
    timeMax: {
      type: Number,
      default: 0,
    },
    timeRemaining: {
      type: Number,
      default: 0,
    },
    automatic: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['timerAction'],
  data(): ComponentData {
    return {
      currentTime: 0,
    }
  },
  computed: {
    progressBarWidth(): string {
      if (!this.timeRemaining || this.currentTime >= this.timeMax) return '100%'
      return Math.round((this.currentTime * 100) / this.timeMax) + '%'
    },
  },
  watch: {
    timeRemaining(): void {
      if (this.automatic) return
      this.currentTime = this.timeMax - this.timeRemaining
    },
  },
  created(): void {
    if (!this.automatic) return
    this.currentTime = this.timeMax - this.timeRemaining
    const updateTime = (): void => {
      this.currentTime++
      if (this.currentTime >= this.timeMax) clearInterval(intervalId)
    }
    const intervalId = setInterval(updateTime, 1000)
  },
  methods: {
    formatTime,
  },
})
</script>

<style lang="scss" scoped>
.app-timer-progressbar {
  width: 100%;

  &-time {
    width: 100%;
    cursor: default;
    pointer-events: none;

    :deep() {
      .icon-time {
        width: 2rem;
        height: 2rem;
        background-size: auto 100%;
      }

      @if $isSsm {
        background: #079881;
      }

      @if $isWsm {
        // transparent only WSM
        background: transparent;
        background-image: linear-gradient(to top, rgba(73, 172, 46, 0.6), rgba(22, 148, 16, 0.6));
      }
    }
  }

  &-progress {
    width: 100%;
    background: #000;

    @if $isWsm {
      left: -0.4rem;
    }

    &-inner {
      width: 100%;
      height: 0.5rem;
      background: linear-gradient(90deg, rgba(246, 199, 23, 1) 0%, rgba(255, 239, 132, 1) 100%);
      transition: width 0.5s;
    }
  }
}
</style>
