<template>
  <div class="lab-economics-col relative">
    <lab-economics-cell
      v-for="(cell, i) in columnData"
      :key="i"
      class="absolute"
      :style="{
        top: `${VERTICAL_SPACING * cell.positionLevel}rem`,
      }"
      :cell-data="cell"
      :has-ongoing-research-tree="hasOngoingResearchTree"
      @select-skill="$emit('selectSkill', $event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LabEconomicsCell from '@/components/Lab/Economics/LabEconomicsCell.vue'
import type { PropType } from 'vue'
import type { Cell } from '@/interfaces/lab/Cell'

const VERTICAL_SPACING = 8.5
interface ComponentData {
  VERTICAL_SPACING: typeof VERTICAL_SPACING
}

export default defineComponent({
  name: 'LabEconomicsColumn',
  components: {
    LabEconomicsCell,
  },
  props: {
    columnData: {
      type: Array as PropType<Cell[]>,
      required: true,
    },
    hasOngoingResearchTree: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectSkill'],
  data(): ComponentData {
    return {
      VERTICAL_SPACING,
    }
  },
})
</script>

<style lang="scss" scoped>
.lab-economics-col {
  left: -4.5rem;
}
</style>
