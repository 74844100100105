<template>
  <footer class="clubs-employees-box-footer flex flex-col justify-around items-center">
    <div v-if="footerData.rewardValue" class="clubs-employees-box-footer-reward">
      <p class="uppercase text-28 text-texts-standard-additional">
        {{ $t('club.regularReward') }}
      </p>
      <div class="clubs-employees-box-footer-reward-income">
        <clubs-periodic-payment-box
          :reward-value="footerData.rewardValue"
          :reward-type="footerData.rewardType"
          :reward-interval="footerData.rewardTime"
          :reward-size="30"
          :reward-icon-size="32"
        />
      </div>
    </div>
    <div
      v-if="footerData.validForDays && !footerData.isHired"
      class="clubs-employees-box-footer-valid flexing"
    >
      <p
        class="clubs-employees-box-footer-valid-text font-bold text-32 text-texts-standard-default uppercase"
      >
        {{ $t('club.valid') }}
      </p>
      <p
        class="clubs-employees-box-footer-valid-days font-bold text-32 text-texts-standard-important uppercase"
      >
        {{ footerData.validForDays }}{{ 'd' }}
      </p>
      <app-icon class="clubs-employees-box-footer-valid-icon" icon-name="time" />
    </div>
    <app-multi-button1
      v-if="showHire() && canHire()"
      btn-id="clubs-employees-box-footer-button"
      :popup-data="{
        title: $t('club.hireEmployee'),
        btnText: $t('club.hire'),
        btnType: 'confirm',
        btnValue: footerData.hiringPrice,
        btnId: 'hire-employee-id',
        text: $t('club.hireEmployeeConfirm'),
      }"
      :btn-type="setButtonHiringType"
      :btn-text="$t('club.hire')"
      :disabled="isDisabled"
      :btn-count="footerData.hiringPrice"
      :short-cut-value="7"
      @click="
        footerData.hiringCurrency != 'gems' && enoughSources() && !isDisabled ? $emit('hire') : null
      "
      @action="$emit('hire')"
    >
    </app-multi-button1>
    <p v-if="showHire() && !canHire()" class="text-28 text-texts-standard-default text-cannot-hire">
      {{ $t('club.cantHire') }}
    </p>
    <app-timer-progressbar
      v-if="!footerData.rewardsToClaim && footerData.rewardSeconds && !footerData.detailBtn"
      :time-max="footerData.rewardTime"
      :time-remaining="footerData.rewardSeconds"
    />
    <app-multi-button1
      v-else-if="footerData.rewardsToClaim && !footerData.detailBtn"
      btn-id="clubs-employees-box-footer-button"
      btn-theme="confirm"
      :btn-text="$t('common.takeRewards')"
      :btn-count="footerData.rewardValue"
      @click="$emit('claimRewards')"
    >
      <template #rightPart>
        <app-main-icon :icon-size="48" :icon-name="footerData.rewardType" />
      </template>
    </app-multi-button1>
    <app-button
      v-else-if="footerData.detailBtn"
      btn-type="primary"
      btn-size="md"
      :btn-text="$t('button.detail')"
      class="clubs-employees-box-footer-button detail"
      @click="$emit('detail')"
    />
  </footer>
</template>

<script lang="ts">
import ClubsPeriodicPaymentBox from '@/components/Club/ClubsPeriodicPaymentBox.vue'
import AppTimerProgressbar from '@/components/GlobalComponents/AppTimerProgressbar.vue'
import { CREDIT_OPERATION, GEMS, MONEY, PRESTIGE } from '@/globalVariables'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Employee } from '@/interfaces/clubs/Employees'

export default defineComponent({
  name: 'ClubsEmployeesBoxFooter',
  components: {
    ClubsPeriodicPaymentBox,
    AppTimerProgressbar,
  },
  props: {
    footerData: {
      type: Object as PropType<Employee>,
      default: () => ({
        rewardsToClaim: 0,
        rewardSeconds: 0,
        rewardValue: 0,
        rewardType: 'money',
        rewardTime: 0,
        detailBtn: false,
        validForDays: 0,
        hiringPrice: 0,
        hiringCurrency: 'money',
      }),
    },
  },
  emits: ['hire', 'claimRewards', 'detail'],
  computed: {
    ...mapState(useUserStore, {
      isClubManagerOrAssistant: 'isClubManagerOrAssistant',
    }),
    ...mapState(useClubStore, {
      getClubInfo: 'getClubInfo',
    }),
    ...mapState(useResponseTaskStore, {
      gems: 'getGems',
      money: 'getMoney',
    }),
    isDisabled(): boolean {
      return this.footerData.isDisabled
    },
    setButtonHiringType(): string {
      switch (this.footerData.hiringCurrency) {
        case GEMS:
          return CREDIT_OPERATION
        case MONEY:
          return MONEY
        case PRESTIGE:
          return PRESTIGE
      }
      return ''
    },
  },
  methods: {
    enoughSources(): boolean {
      if ([GEMS, MONEY].includes(this.footerData.hiringCurrency)) {
        return this[this.footerData.hiringCurrency].value >= this.footerData.hiringPrice
      }
      return this.getClubInfo[this.footerData.hiringCurrency] >= this.footerData.hiringPrice
    },
    showHire(): boolean {
      return this.footerData.hiringPrice && !this.footerData.isHired && !this.footerData.locked
    },
    canHire(): boolean {
      return this.footerData.isPersonal ? true : !!this.isClubManagerOrAssistant
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/club/employees.scss';

.app-timer-progressbar {
  margin: 1rem auto;
  width: 80%;
}

.text-right {
  width: auto !important;
}

.text-cannot-hire {
  padding: 0 2rem;
}
</style>
