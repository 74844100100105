<template>
  <div class="short-text">
    <p :class="getLength">
      {{ text }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ShortText',
  props: {
    text: {
      type: String,
      default: '',
    },
    firstCut: {
      type: Number,
      default: 100,
    },
    secondCut: {
      type: Number,
      default: 200,
    },
    firstCustom: {
      type: String,
      default: '',
    },
    secondCustom: {
      type: String,
      default: '',
    },
  },
  computed: {
    getLength(): string {
      const textLength = this.text.length
      if (textLength > this.firstCut && textLength < this.secondCut) {
        return this.firstCustom.length > 0 ? this.firstCustom : 'first-cut'
      }
      if (textLength > this.secondCut) {
        return this.secondCustom.length > 0 ? this.secondCustom : 'second-cut'
      } else return 'without-cut'
    },
  },
})
</script>

<style lang="scss" scoped>
.short-text {
  .without-cut {
    font-size: 100%;
  }

  .first-cut {
    font-size: 75%;
  }

  .second-cut {
    font-size: 50%;
  }
}
</style>
