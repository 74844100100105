<template>
  <div class="notification-icon__wrapper">
    <span :class="getClasses()">
      {{ getText() }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { GameType } from '@/globalVariables/gameTypes'

const NUMBER_LABEL = 'numberLabel'
const NEW_LABEL = 'newLabel'
const EXCLAMATION_LABEL = 'exclamationLabel'

interface ComponentData {
  GameType: typeof GameType
  currentGame: string
}

export default defineComponent({
  name: 'AppNotification',
  props: {
    count: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: NUMBER_LABEL,
      validator(value: string): boolean {
        return (
          value in
          {
            [NUMBER_LABEL]: true,
            [NEW_LABEL]: true,
            [EXCLAMATION_LABEL]: true,
          }
        )
      },
    },
    size: {
      type: Number,
      default: 33,
    },
    isSkewedParent: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      GameType,
      currentGame: this.$gameName,
    }
  },
  methods: {
    getClasses(): string[] {
      // The red notifications and the NEW label are without skew and italic
      const classes = ['flexing', 'not-italic']

      switch (this.type) {
        case NUMBER_LABEL:
        case EXCLAMATION_LABEL:
          classes.push('notification-icon', 'notification-icon-' + this.size)
          break
        case NEW_LABEL:
          classes.push('notification-new-icon', 'notification-new-icon-' + this.size)
          break
        default:
          break
      }

      if (this.currentGame === GameType.Winter && this.isSkewedParent) {
        classes.push('revert-skew')
      }

      return classes
    },
    getText(): string {
      switch (this.type) {
        case NUMBER_LABEL:
          return this.count !== 0 ? this.count.toString() : ''
        case NEW_LABEL:
          return 'NEW'
        case EXCLAMATION_LABEL:
          return '!'
        default:
          return ''
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';
@import '@/assets/styles/animations.scss';

.notification-icon {
  border-radius: 100%;
  background-image: linear-gradient(to top, #ff1a1a, #ff0606);
  font-size: 1.313rem;
  color: #fff;
  text-align: center;
  text-shadow: 0.069rem 0.106rem 0.063rem rgba(0, 0, 0, 0.46);
  font-weight: bold;
  font-stretch: normal;

  &__wrapper {
    position: absolute;
    top: -1rem;
    right: -1rem;
    z-index: 1;
    animation: bounce 4s ease infinite;
  }

  &-33 {
    width: 2.0625rem;
    height: 2.0625rem;
    line-height: 2.0625rem;
  }

  &-24 {
    .notification-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.notification-new-icon {
  text-shadow: 1.1px 1.7px 1px rgba(0, 0, 0, 0.46);
  background-image: linear-gradient(to top, #ff1a1a, #ff0606);
  font-size: 1.625rem;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-stretch: condensed;

  &-33 {
    width: 4.563rem;
    height: 1.875rem;
    line-height: 1.875rem;
  }
}
</style>
