import { MAP_TRAINING_HALL } from '@/globalVariables'
import { StageType } from '@/interfaces/Tutorial'
import type { TutorialStage } from '@/interfaces/Tutorial'

/**
  Tutorial for discipline unlocked
 */
export default class TutorialUnlockPersonalCoach {
  stages(): TutorialStage[] {
    return [
      // narative saying you unlocked a new discipline
      {
        id: 72,
        name: 'unlockPersonalCoachNarrative',
        type: StageType.Narrative,
        overlay: false,
        mechanic: 5,
        pageName: 'LayoutViewTutorial',
        texts: [],
      },
      // focus on arena building with gsap animation
      {
        id: 73,
        name: 'clickOnTrainingHallBuilding',
        type: StageType.Focus,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        clientId: [MAP_TRAINING_HALL],
        texts: [],
        mechanic: 5,
      },
      // focus on unlocked discipline with overlay and gsap animation
      {
        id: 74,
        name: 'clickOnTrainingHallTrainerTab',
        type: StageType.ClientInput,
        overlay: true,
        pageName: 'TrainingDisciplinesTutorial',
        clientId: ['menu-coach'],
        mechanic: 5,
        texts: [],
      },
    ]
  }
}
