import {
  countriesTranslateEndpoint,
  languagestranslationEndpoint,
  nonAuthLanguagestranslationEndpoint,
} from '@/globalVariables'
import { formatCountries } from '@/helpers/formatCountries'
import { defineStore } from 'pinia'
import type Country from '@/interfaces/Country'
import type { LanguageApiResponse } from '@/interfaces/responses/language/LanguageApiResponse'
import type { Nullable } from '@/interfaces/utils'
import { ApiService } from '@/services/ApiService'

export interface CountryResponse {
  country: { [key: string]: string }
}

export interface FormattedLanguages {
  value: string
  name: string
  icon: string
}

interface CountriesStoreState {
  languages: LanguageApiResponse[]
  countries: Nullable<CountryResponse>
  formattedLanguages: FormattedLanguages[]
  formattedCountries: Country[]
  selectedCountries: string[]
}

export const useCountriesStore = defineStore('countriesStore', {
  state: (): CountriesStoreState => ({
    languages: [],
    countries: null,
    formattedLanguages: [],
    formattedCountries: [],
    selectedCountries: [
      'aus',
      'aut',
      'bel',
      'bra',
      'can',
      'cze',
      'dnk',
      'esp',
      'fin',
      'fra',
      'gbr',
      'ger',
      'grc',
      'ita',
      'jam',
      'nld',
      'nor',
      'pol',
      'rus',
      'sui',
      'svk',
      'swe',
      'tur',
      'usa',
      'zaf',
    ],
  }),
  getters: {
    getLanguages(): LanguageApiResponse[] {
      return this.languages
    },
    getFormattedLanguages(): FormattedLanguages[] {
      return this.formattedLanguages
    },
    getCountries(): CountryResponse {
      // Tu je nutny fallback, nakolko sa nas kod spolieha, ze tu vratime objekt.
      return this.countries || ({} as CountryResponse)
    },
    getFormattedCountries(): Country[] {
      return this.formattedCountries
    },
    getSelectedCountries(): string[] {
      return this.selectedCountries
    },
  },
  actions: {
    async loadCountries(force: boolean = false): Promise<void> {
      if (this.countries && this.formattedCountries.length && !force) return

      const data = await ApiService.post<CountryResponse, string[]>(
        countriesTranslateEndpoint,
        ['country'],
        { cache: true, force },
      )

      this.countries = data
      this.formattedCountries = formatCountries(data)
    },
    /**
     * Metoda stahujuca jazyk podla prihlasenia a formatovanie udajov
     * @param {*} isAuthDone - ci uz prebehol auth
     * @returns
     */
    async loadLanguages(isAuthDone: boolean): Promise<LanguageApiResponse[]> {
      try {
        let data: LanguageApiResponse[]
        if (isAuthDone) {
          data = await ApiService.get<LanguageApiResponse[]>(languagestranslationEndpoint)
        } else {
          const groupId = JSON.parse(localStorage.getItem('last_id_group'))?.value ?? null
          data = await ApiService.post<LanguageApiResponse[], { group_id: number | string }>(
            nonAuthLanguagestranslationEndpoint,
            { group_id: groupId },
            { cache: true },
          )
        }
        const formattedLanguages =
          data
            ?.map(
              (language: LanguageApiResponse): FormattedLanguages => ({
                value: language.language,
                name: language.name,
                icon: `flags/102x68/${language.flag_country_iso}.avif`,
              }),
            )
            ?.sort((a: FormattedLanguages, b: FormattedLanguages): number =>
              a.name.localeCompare(b.name),
            ) ?? []
        this.languages = data
        this.formattedLanguages = formattedLanguages
        return data
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
