import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const ballonAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'balloonRotate',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '03',
      delay: 0,
      duration: 20000,
      showOnStart: true,
      hideOnComplete: false,
      repeat: -1,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 3,
        end: 12,
        zeroPad: 2,
      },
    },
  },
]
