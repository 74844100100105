<template>
  <popup-window
    popup-type="info"
    :popup-title="$t(parameterTypes[parameter].headerText)"
    @close="closeOff"
  >
    <div class="popup-supplement">
      <parameter-popup-sub-header :param-type="parameterTypes[parameter]" />
      <parameter-popup-body
        :param-type="parameterTypes[parameter]"
        :total-special-consumables="consumable.value"
        :value-per-consumable="consumable.valuePerConsumable"
        :gem-value-per-consumable="consumable.consumablePriceGems"
        :is-disabled="isDisabled"
        @use-special-consumables="useSpecialConsumables"
        @buy-offer="buyOffer"
        @close-offer="closeOff"
        @close="closeOff"
      />
      <parameter-popup-footer
        :param-type="parameterTypes[parameter]"
        :total-special-consumables="consumable.value"
        @close-popup="closeOff"
      />
    </div>
    <div v-if="isGamePassInitialized" class="prompt-wrapper">
      <game-pass-parameters-prompt @close-popup="closeOff" />
    </div>
  </popup-window>
</template>

<script lang="ts">
import ParameterPopupBody from '@/components/Popup/Parameter/ParameterPopupBody.vue'
import ParameterPopupFooter from '@/components/Popup/Parameter/ParameterPopupFooter.vue'
import ParameterPopupSubHeader from '@/components/Popup/Parameter/ParameterPopupSubHeader.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import GamePassParametersPrompt from '@/components/Premium/GamePass/GamePassParametersPrompt.vue'

import { parameterTypes, shopItemSpendEndpoint, shopOfferEndPoint } from '@/globalVariables'
import { playSound } from '@/helpers'
import { useConsumablesStore } from '@/store/pinia/consumablesStore'
import { useGamePassStore } from '@/store/pinia/gamePassStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { Consumable } from '@/interfaces/HeaderParams'

interface ComponentData {
  parameterTypes: typeof parameterTypes
  isDisabled: boolean
}

export default defineComponent({
  components: {
    PopupWindow,
    ParameterPopupSubHeader,
    ParameterPopupBody,
    ParameterPopupFooter,
    GamePassParametersPrompt,
  },
  props: {
    parameter: {
      type: String,
      default: parameterTypes.energy.value,
    },
  },
  emits: ['closePopup'],
  data: (): ComponentData => {
    return {
      parameterTypes,
      isDisabled: false,
    }
  },
  computed: {
    ...mapState(useConsumablesStore, ['getConsumable']),
    ...mapState(useGamePassStore, ['isGamePassInitialized']),
    consumableType(): string {
      return parameterTypes[this.parameter].specialConsumable.value
    },
    consumable(): Consumable {
      return this.getConsumable(this.consumableType)
    },
  },
  watch: {
    parameter(): void {
      this.loadConsumableData(this.consumableType)
    },
  },
  created(): void {
    this.loadConsumableData(this.consumableType)
  },
  methods: {
    ...mapActions(useConsumablesStore, ['loadConsumableData']),
    async buyOffer(amount: number): Promise<void> {
      try {
        this.$axios.post<{}, true>(shopOfferEndPoint, {
          offer_id: this.consumable.offerId,
          amount,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async useSpecialConsumables(amount: number): Promise<void> {
      if (this.isDisabled) return
      try {
        this.isDisabled = true
        await this.$axios.put(shopItemSpendEndpoint, {
          shop_item_id: this.consumable.id,
          amount,
        })
        this.isDisabled = false
        playSound(this.consumable.id === 1 ? 'energy-drink' : 'wild-card')
      } catch (error: unknown) {
        console.error(error)
      }
    },
    closeOff(): void {
      this.$emit('closePopup', true)
    },
  },
})
</script>

<style lang="scss" scoped>
.popup-supplement {
  height: 44.85rem;
  width: 82.375rem;
}
.prompt-wrapper {
  position: absolute;
  bottom: -7rem;
}
</style>
