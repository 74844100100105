import {
  OPERATION_DELIVERY,
  OPERATION_REPAIR,
  OPERATION_UPGRADE,
  researchStatusEndpoint,
  shopOperationCheckEndPoint,
} from '@/globalVariables'
import { convertNameValue } from '@/helpers'
import { internalAxios } from '@/plugins/vueAxios'
import type { OperationRequest, ResearchStatusRequest } from '@/types/phaserStoreTypes'
import { defineStore } from 'pinia'

const OPERATION_MAPPING = {
  [OPERATION_REPAIR]: 'equipmentShop.repairing',
  [OPERATION_UPGRADE]: 'equipmentShop.upgrading',
  [OPERATION_DELIVERY]: 'equipmentShop.delivering',
}

interface TimerInterface {
  [key: string]: {
    title(title: any): unknown
    x(
      id: string,
      x: any,
      y: any,
      title: string,
      arg4: boolean,
      timerStartedAt: number,
      timerEndsAt: number,
    ): unknown
    y(
      id: string,
      x: any,
      y: any,
      title: string,
      arg4: boolean,
      timerStartedAt: number,
      timerEndsAt: number,
    ): unknown
    timerStartedAt?: number
    timerEndsAt?: number
    data?: string
  }
}

export const usePhaserStore = defineStore('phaserStore', {
  state: () => ({
    timersLoaded: false,
    timers: {} as TimerInterface,
    emitter: null as Phaser.Events.EventEmitter,
    clubEmitter: null as Phaser.Events.EventEmitter,
    gameState: true,
  }),
  getters: {
    getEmitter: (state) => state.emitter,
    getClubEmitter: (state) => state.clubEmitter,
    getGameState: (state) => state.gameState,
  },
  actions: {
    setTime(payload: { id: string; timerStartedAt: number; timerEndsAt: number }) {
      if (!this.timers[payload.id]) return
      this.timers[payload.id].timerStartedAt = payload.timerStartedAt
      this.timers[payload.id].timerEndsAt = payload.timerEndsAt
    },
    // 2023-08-16 Zakomentovane, kedze metoda setTime pocita s tym, ze struktura bude uz existovat.
    clearTimer(/* id: string */) {
      // if (!this.timers[id]) return
      // delete this.timers[id]
    },
    createTimer(payload: { id: string; data: string }) {
      this.timers[payload.id] = payload.data
    },
    changeGameState(payload: boolean) {
      this.gameState = payload
    },
    setEmitter(emiter: Phaser.Events.EventEmitter) {
      this.emitter = emiter
    },
    setClubEmitter(emiter: Phaser.Events.EventEmitter) {
      this.clubEmitter = emiter
    },
    async loadTimers() {
      // equipment timer
      const equipmentOperationData = await internalAxios.get<{}, OperationRequest>(
        shopOperationCheckEndPoint,
      )
      const equipmentOperationStatus = equipmentOperationData?.operation?.stats
        ? convertNameValue(equipmentOperationData.operation.stats)
        : null
      let equipmentTimerData = { title: '', timerStartedAt: 0, timerEndsAt: 0 }
      if (equipmentOperationStatus) {
        const duration = equipmentOperationStatus.operation_duration || 0
        const remainingTime = equipmentOperationStatus.operation_end || 0
        const now = Date.now()
        equipmentTimerData = {
          title: OPERATION_MAPPING[equipmentOperationStatus.operation_type],
          timerStartedAt: now - (duration - remainingTime) * 1000,
          timerEndsAt: now + equipmentOperationStatus.operation_end * 1000,
        }
      }
      // research timer
      const researchOperationStatus = await internalAxios.get<{}, ResearchStatusRequest>(
        researchStatusEndpoint,
      )
      let researchTimerData = { title: '', timerStartedAt: 0, timerEndsAt: 0 }
      if (researchOperationStatus) {
        const duration =
          researchOperationStatus.parameters?.find((param) => {
            return param.name === 'research_time_duration'
          })?.value || 0
        const remainingTime = researchOperationStatus.remaining_time || 0
        const now = Date.now()
        researchTimerData = {
          title: 'research.researchCounting',
          timerStartedAt: now - (+duration - remainingTime) * 1000,
          timerEndsAt: now + researchOperationStatus?.remaining_time * 1000,
        }
      }
      // timer tooltips above all buildings
      const timers = {
        [EQUIPMENT_TIMER]: {
          x: 1420,
          y: 175,
          ...equipmentTimerData,
        },
        [RESEARCH_TIMER]: {
          x: 1918,
          y: 372,
          ...researchTimerData,
        },
        // other buildings positions
        // 1: {
        //   x: 895,
        //   y: 275,
        // },
        // 2: {
        //   x: 1182,
        //   y: 363,
        // },
        // 3: {
        //   x: 1918,
        //   y: 372,
        // },
        // 4: {
        //   x: 1339,
        //   y: 910,
        // },
        // 5: {
        //   x: 1975,
        //   y: 1000,
        // },
        // 6: {
        //   x: 2356,
        //   y: 803,
        // },
        // 7: {
        //   x: 2265,
        //   y: 258,
        // },
        // 8: {
        //   x: 2238,
        //   y: 531,
        // },
        // 9: {
        //   x: 628,
        //   y: 433,
        // },
        // 10: {
        //   x: 1663,
        //   y: 650,
        // },
        // 11: {
        //   x: 985,
        //   y: 685,
        // }
      }
      this.timersLoaded = true
      this.timers = timers
    },
    // TODO load stored timers from BE ?
  },
})
