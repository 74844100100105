<template>
  <info-popup
    v-if="showPopup"
    width="103rem"
    :popup-title="$t('career.career')"
    @close="$emit('close')"
  >
    <div class="popup-about-career">
      <div v-for="(section, index) in careerPopup" :key="index" class="about-career-box">
        <div class="about-career-box-pic flexing">
          <div v-if="section.icon.includes('discipline')" class="pic-discipline">
            <div class="discipline-box" :class="`icon-${section.icon}-152`" />
          </div>
          <app-icon v-else-if="section.icon === 'plus-white'" icon-name="plus-white-152" />
          <app-main-icon v-else :icon-size="152" :icon-name="section.icon" :hide-tooltip="true" />
        </div>
        <div class="about-career-box-textarea">
          <p class="about-career-box-title text-texts-standard-important">
            {{ $t(section.header) }}
          </p>
          <p class="about-career-box-text text-texts-standard-default">
            {{ $t(section.text) }}
          </p>
        </div>
      </div>
    </div>
  </info-popup>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { defineComponent } from 'vue'

interface ComponentData {
  careerPopup: {
    header: string
    icon: string
    text: string
  }[]
}

export default defineComponent({
  name: 'CareerPopup',
  components: {
    InfoPopup,
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      careerPopup: [
        {
          header: 'career.mainBranch',
          icon: 'career-gold',
          text: 'career.aboutMainV2',
        },
        {
          header: 'career.secondaryBranch',
          icon: this.$isSsm ? 'discipline-11-light' : 'discipline-1-light',
          text: 'career.aboutSecondary',
        },
      ],
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/career-icons.scss';
@import '@/assets/styles/components/icons/discipline-icons.scss';
@import '@/assets/styles/components/icons/stars-icons.scss';

.popup-about-career {
  width: 102.75rem;
  height: auto;

  .about-career-box {
    height: 13.75rem;
    margin: 0.625rem 0;
    display: flex;
    margin-bottom: 2rem;

    &-pic {
      width: 20%;
      height: 100%;
      padding-top: 3rem;

      .pic-discipline {
        width: 9.5rem;
        height: 9.5rem;
      }
    }

    &-textarea {
      width: 79%;
      font-size: 1.25rem;
      display: inline-block;

      .about-career-box-title {
        font-size: 2rem;
        width: 95%;
        height: 3rem;
        line-height: 3rem;
        display: block;
        text-align: left;
        text-transform: uppercase;

        @if $isWsm {
          border-bottom: 0.063rem solid skyblue;
        }

        @if $isSsm {
          border-bottom: 0.063rem solid #4c648f;
        }
      }

      .about-career-box-text {
        font-size: 1.75rem;
        width: 95%;
        display: block;
        text-align: left;
        padding: 1rem 0 0 0;
      }
    }
  }
}
</style>
