<template>
  <div class="clubs-championship-table-header m-auto flexing">
    <section class="header-up">
      <app-icon icon-name="question" class="absolute left-0" @click="showAboutPopup = true" />
      <aside v-if="isLeaguesReady" class="flex">
        <app-select
          :options="leagues.options"
          :default="leagues.selected"
          class="mx-4"
          @select="selectLeague($event)"
        />
        <app-select
          :options="divisions.options"
          :default="divisions.selected"
          class="mx-4"
          @select="selectDivision($event)"
        />
      </aside>
      <popup-window
        v-if="showAboutPopup"
        popup-type="info"
        :popup-title="$t('club.clubChampionship')"
        @close="showAboutPopup = false"
      >
        <clubs-championship-about-popup />
      </popup-window>
    </section>
    <section class="w-full flex item-center">
      <clubs-championship-League-info
        :league="selectedLeague"
        :division="selectedDivisionName"
        :rookie-league-number="defaultSelectedRookieLeague"
      >
        <slot />
      </clubs-championship-League-info>
    </section>
  </div>
</template>

<script lang="ts">
import ClubsChampionshipAboutPopup from '@/components/Club/Championship/ClubsChampionshipAboutPopup.vue'
import ClubsChampionshipLeagueInfo from '@/components/Club/Championship/ClubsChampionshipLeagueInfo.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import { CLUB_CHAMPIONSHIP_LEAGUES } from '@/globalVariables'
import { useClubChampionshipStore } from '@/store/pinia/clubs/championshipStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type {
  ClubChampionshipsDetail,
  ClubChampsLeague,
} from '@/interfaces/clubs/ClubChampionships'
import type { NameValueString } from '@/interfaces/Global'

interface ComponentData {
  showAboutPopup: boolean
  defaultSelectedLeague: string
  defaultSelectedDivisionId: string
  defaultSelectedDivisionNumber: number
  defaultSelectedRookieLeague: number
  divisions: {
    options: NameValueString[]
    selected: string
    selectedDivisionName: number
  }
  leagues: {
    options: NameValueString[]
    selected: string
  }
}

export default defineComponent({
  name: 'ClubsChampionshipTableHeader',
  components: {
    PopupWindow,
    ClubsChampionshipLeagueInfo,
    ClubsChampionshipAboutPopup,
  },
  data(): ComponentData {
    return {
      showAboutPopup: false,
      defaultSelectedLeague: '',
      defaultSelectedDivisionId: '',
      defaultSelectedDivisionNumber: 0,
      defaultSelectedRookieLeague: 0,
      divisions: {
        options: [{ name: '', value: '' }],
        selected: '',
        selectedDivisionName: 0,
      },
      leagues: {
        options: [{ name: '', value: '' }],
        selected: '',
      },
    }
  },
  computed: {
    ...mapState(useClubChampionshipStore, {
      getLeagueByType: 'getLeagueByType',
      getRookieLeagueByNumber: 'getRookieLeagueByNumber',
      getDetail: 'getDetail',
      leaguesGetter: 'getLeagues',
    }),
    selectedLeague(): string {
      return this.defaultSelectedLeague
    },
    selectedDivisionId(): string {
      return this.defaultSelectedDivisionId
    },
    selectedDivisionName(): number {
      return this.defaultSelectedDivisionNumber
    },
    isLeaguesReady(): boolean {
      if (this.selectedLeague && this.selectedDivisionId) {
        this.setLeagues()
        this.setDivisions()
        return true
      }
      return false
    },
  },
  watch: {
    getDetail(value: ClubChampionshipsDetail): void {
      this.defaultSelectedLeague =
        value.division.type !== CLUB_CHAMPIONSHIP_LEAGUES.rookie
          ? value.division.type
          : `${value.division.type}-${value.division.rookieLeagueNumber}`
      this.defaultSelectedDivisionId = value.division.id
      this.defaultSelectedDivisionNumber = value.division.number
      this.defaultSelectedRookieLeague = value.division.rookieLeagueNumber
    },
  },
  async created(): Promise<void> {
    await this.loadLeagues()

    if (!this.getDetail?.division.id) {
      this.defaultSelectedDivisionId = this.leaguesGetter[0]?.divisionId ?? ''
      this.defaultSelectedLeague = this.leaguesGetter[0]?.leagueType ?? ''
      this.defaultSelectedDivisionNumber = this.leaguesGetter[0].divisionNumber
    }
  },
  methods: {
    ...mapActions(useClubChampionshipStore, ['loadDivision', 'loadLeagues']),
    setLeagues(): void {
      const league = []
      const rookieLeaguesNumbers = []
      for (const item of Object.values(CLUB_CHAMPIONSHIP_LEAGUES)) {
        if (this.getLeagueByType(item).length > 0) {
          if (item !== CLUB_CHAMPIONSHIP_LEAGUES.rookie) {
            league.push({ name: this.$t('club.' + item), value: item })
          } else {
            for (const league of this.getLeagueByType(item)) {
              if (!rookieLeaguesNumbers.includes(league.rookieLeagueNumber)) {
                rookieLeaguesNumbers.push(league.rookieLeagueNumber)
              }
            }
          }
        }
      }
      const sortedRookieLeagues = rookieLeaguesNumbers.sort()
      for (const rookieLeague of sortedRookieLeagues) {
        league.push({
          name: `${this.$t('club.' + CLUB_CHAMPIONSHIP_LEAGUES.rookie)}  ${rookieLeague}`,
          value: `${CLUB_CHAMPIONSHIP_LEAGUES.rookie}-${rookieLeague}`,
        })
      }
      const isRookieLeagueString = this.selectedLeague.includes('-')
      this.leagues = {
        options: league,
        selected:
          this.defaultSelectedRookieLeague && !isRookieLeagueString
            ? `${this.selectedLeague}-${this.defaultSelectedRookieLeague}`
            : this.selectedLeague,
      }
    },
    setDivisions(): void {
      const mutatedSelected = this.selectedLeague.includes(CLUB_CHAMPIONSHIP_LEAGUES.rookie)
        ? CLUB_CHAMPIONSHIP_LEAGUES.rookie
        : this.selectedLeague
      const division = []
      if (this.getLeagueByType(mutatedSelected).length > 0) {
        if (mutatedSelected !== CLUB_CHAMPIONSHIP_LEAGUES.rookie) {
          for (const item of this.getLeagueByType(mutatedSelected)) {
            division.push({
              value: item.divisionId,
              divisionNumber: item.divisionNumber,
              name: this.$replacePlaceholder(this.$t('club.division'), '%s', item.divisionNumber),
            })
          }
        } else {
          for (const item of this.getRookieLeagueByNumber(this.defaultSelectedRookieLeague)) {
            division.push({
              value: item.divisionId,
              divisionNumber: item.divisionNumber,
              name: this.$replacePlaceholder(this.$t('club.division'), '%s', item.divisionNumber),
            })
          }
        }
        division.sort((a: ClubChampsLeague, b: ClubChampsLeague) =>
          a.divisionNumber > b.divisionNumber ? 1 : -1,
        )
        this.divisions = {
          options: division,
          selected: this.selectedDivisionId,
          selectedDivisionName: this.selectedDivisionName,
        }
      }
    },
    selectLeague(choice: string) {
      const isRookieLeague = choice.includes(CLUB_CHAMPIONSHIP_LEAGUES.rookie)
      let splitedChoice = []
      this.defaultSelectedRookieLeague = 0
      if (isRookieLeague) {
        splitedChoice = choice.split('-')
        this.defaultSelectedRookieLeague = Number(splitedChoice[1])
      }
      this.defaultSelectedLeague = isRookieLeague ? splitedChoice[0] : choice
      const selectedLeague =
        this.selectedLeague !== CLUB_CHAMPIONSHIP_LEAGUES.rookie
          ? this.getLeagueByType(this.defaultSelectedLeague)
          : this.getRookieLeagueByNumber(this.defaultSelectedRookieLeague)
      const divisionOne = selectedLeague[0]
      this.defaultSelectedDivisionId = divisionOne.divisionId
      this.defaultSelectedDivisionNumber = divisionOne.divisionNumber
      this.setDivisions()
      this.loadDivision(this.defaultSelectedDivisionId)
    },
    selectDivision(choice: string): void {
      this.defaultSelectedDivisionId = choice
      const selectedLeague =
        this.selectedLeague !== CLUB_CHAMPIONSHIP_LEAGUES.rookie
          ? this.getLeagueByType(this.selectedLeague)
          : this.getRookieLeagueByNumber(this.defaultSelectedRookieLeague)
      const currentLeague = selectedLeague.find(
        (league: ClubChampsLeague): boolean => league.divisionId === choice,
      )
      this.defaultSelectedDivisionNumber = currentLeague.divisionNumber
      this.loadDivision(choice)
    },
  },
})
</script>

<style lang="scss" scoped>
.clubs-championship-table-header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  .header-up {
    height: 8.063rem;
    display: flex;
    align-items: center;
  }

  .league-information {
    width: 100%;
    height: 6.063rem;
    margin-bottom: 1rem;
    position: relative;
    display: flex;
    align-items: center;
    @if $isWsm {
      background: linear-gradient(
        to left,
        transparent 0%,
        #144a73 10%,
        #144a73 90%,
        transparent 100%
      );
    }
    @if $isSsm {
      background: linear-gradient(
        to left,
        transparent 0%,
        rgba(52, 65, 93, 0.85) 10%,
        rgba(39, 50, 73, 0.85) 90%,
        transparent 100%
      );
      &::before,
      &::after {
        content: '';
        position: absolute;
        background: linear-gradient(to right, transparent, #586b9d, transparent);
        width: 80%;
        height: 0.1875rem;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }

    &-logo {
      width: 6.125rem;
      height: 6.125rem;
    }

    &-title {
      font-size: theme('fontSize.40');
      font-weight: bold;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      margin: 0 1.5rem;
    }
  }
}
</style>
