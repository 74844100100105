<template>
  <div class="about-training-content">
    <div class="about-training-box text-texts-standard-default text-xl">
      <div class="text-30 my-4">
        <span class="font-bold italic">
          {{ $t('trainingHall.aboutTrainingIncreaseAttributesInfo') }}
        </span>
      </div>
      <div class="about-training-box-pic flex justify-center my-4">
        <app-main-icon :icon-size="120" :icon-name="UNIVERSAL_TRAINING_POINTS" />
      </div>
    </div>
    <div class="about-training-box text-texts-standard-default text-xl">
      <div class="text-30 my-4 italic">
        {{ $t('trainingHall.aboutTrainingTpInfo') }}
      </div>
      <app-progress-bar
        v-if="activeDiscipline"
        class="my-8"
        :bar-width="18.75"
        :bar-height="0.8125"
        :counts="true"
        :actual="activeDiscipline.userStats?.trainingPoints ?? 1"
        :goal="activeDiscipline.trainingPointsTarget ?? 2"
        :counts-actual="
          activeDiscipline.userAttributes?.base?.value ?? activeDiscipline.userAttribute
        "
        :show-bottom-status="false"
      />
    </div>
    <div class="about-training-box text-texts-standard-default text-xl">
      <div class="text-30 my-4 italic">
        {{
          $replacePlaceholder(
            $t('trainingHall.aboutTrainingUniversalTpInfo'),
            '{grandprize}',
            GRAND_PRIZE,
          )
        }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { GRAND_PRIZE, UNIVERSAL_TRAINING_POINTS } from '@/globalVariables'
import type Discipline from '@/interfaces/global/Discipline'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useTrainingDisciplineStore } from '@/store/pinia/trainingDisciplinesStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  UNIVERSAL_TRAINING_POINTS: typeof UNIVERSAL_TRAINING_POINTS
  GRAND_PRIZE: typeof GRAND_PRIZE
  activeDiscipline: Discipline
}

export default defineComponent({
  name: 'TrainingPopup',
  data(): ComponentData {
    return {
      UNIVERSAL_TRAINING_POINTS,
      GRAND_PRIZE,
      activeDiscipline: null,
    }
  },
  computed: {
    ...mapState(useDisciplineStore, {
      firstUnlockedDisciplineId: 'getFirstUnlockedDisciplineId',
    }),
    ...mapState(useTrainingDisciplineStore, {
      getDisciplineById: 'getDisciplineById',
    }),
  },
  async created(): Promise<void> {
    const activeDisciplineId =
      parseInt(this.$route.params.id as string) || this.firstUnlockedDisciplineId
    this.activeDiscipline = this.getDisciplineById(activeDisciplineId)
  },
})
</script>

<style lang="scss" scoped>
.about-training-content {
  .about-training-box {
    border-bottom: 0.094rem solid #55a8d7;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1rem auto;

    &-textarea {
      width: 79%;
      font-size: 1.25rem;
      display: inline-block;
      font-style: italic;
    }
  }

  .about-training-box:last-child {
    border-bottom: unset;
  }

  div .text-30 {
    line-height: normal;
  }
}
</style>
