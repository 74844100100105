<template>
  <footer class="w-full">
    <section v-if="skillData.locked" class="mt-5">
      <app-icon :icon-name="'lock-lg'" class="m-auto" />
      <p
        v-if="lockedLevelNeeded"
        class="text-32 text-texts-standard-default my-4"
        v-html="
          $replaceHtmlPlaceholders(
            $replacePlaceholder(
              $t('research.availableFrom'),
              '%s',
              skillData.unlockedAfterLevel.toString(),
            ),
            'b',
            ' text-texts-standard-important',
          )
        "
      />
      <p v-else-if="lockedPreviousNeeded" class="text-32 text-texts-standard-default my-4">
        {{ $t('research.searchBefore') }}
      </p>
    </section>
    <section
      v-else-if="
        skillData.currentLevel !== skillData.maxLevel ||
        skillData.state === RepeatableStates.RepeatableDisabled
      "
      class="w-full"
    >
      <div v-if="!skillData.researchInProgress" class="lab-detail-footer-box w-full">
        <p class="lab-detail-footer-box-text text-texts-standard-important text-34 uppercase">
          {{ $t('research.price') }}
        </p>
        <div class="white-strip white-strip--2/5 mx-auto opacity-50" />
        <div class="lab-detail-footer-box-reward flexing">
          <template v-for="(amount, resource, index) in skillData.requiredForResearch" :key="index">
            <aside v-if="amount" class="flexing">
              <p
                class="text-texts-standard-default text-36 font-bold"
                :class="{ 'text-texts-standard-danger': !iHaveEnough(resource) }"
              >
                {{ $filters.$formatNumber(parseInt(amount.toString())) }}
              </p>
              <app-main-icon :icon-size="48" :icon-name="getResourceIcon(resource)" />
            </aside>
          </template>
        </div>
      </div>
      <app-multi-button1
        v-if="skillType === MECHANIC_RESEARCH && !skillData.locked && !skillData.researchInProgress"
        v-tippy="!canStartResearch ? { theme: 'WSM', content: researchBtnTooltip } : {}"
        class="lab-detail-footer-btn app-multi-button--timer"
        :btn-text="$t('research.research')"
        :btn-theme="skillData.gemOnly ? 'credit' : 'secondary'"
        :is-icon="false"
        :disabled="!canStartResearch"
        :is-custom-action="true"
        @click="canStartResearch ? handleResearchBtnClick() : () => {}"
      >
        <template #rightPart>
          <span class="icon-time mr-2" />
          <p class="text-texts-standard-default text-36">
            <vue-countdown
              v-slot="{ days, hours, minutes, seconds }"
              :time="parseInt(skillData.researchTimeDuration.toString()) * 1000"
              :auto-start="false"
            >
              {{ formatTime(days, hours, minutes, seconds, true) }}
            </vue-countdown>
          </p>
        </template>
      </app-multi-button1>
      <div
        v-else-if="skillType === MECHANIC_RESEARCH && skillData.researchInProgress"
        class="w-full research-in-progress"
      >
        <div class="text-texts-standard-important text-36 my-2">
          {{ $t('research.researchCounting') }}
          <div class="white-strip white-strip--2/5 my-2 mx-auto opacity-50" />
          <app-timer
            :time="Number(skillData.researchTimer)"
            transparent
            centered
            @countdown-action="$emit('finishedResearch')"
          />
        </div>
        <app-multi-button1
          v-tippy="enoughGems ? {} : { theme: 'WSM', content: $t('research.dontGems') }"
          btn-type="credit"
          :btn-icon="'gems'"
          btn-size="md"
          :disabled="!enoughGems"
          class="lab-button lab-button--in-progress m-auto"
          :btn-text="$t('research.speedUp')"
          :is-custom-action="true"
          @click="enoughGems ? (showConfirmSpeedup = true) : () => {}"
        >
          <template #rightPart>
            <div class="flexing">
              <span class="price">
                {{ skillData.speedupPrice }}
              </span>
            </div>
          </template>
        </app-multi-button1>
      </div>
      <app-button
        v-else-if="
          skillType !== MECHANIC_RESEARCH && !skillData.locked && !skillData.researchInProgress
        "
        v-tippy="!canStartResearch ? { theme: 'WSM', content: researchBtnTooltip } : {}"
        class="lab-detail-footer-btn"
        btn-id="lab-detail-footer-btn"
        btn-type="secondary"
        :btn-text="$t('research.activeResearch')"
        btn-size="sm"
        :disabled="!canStartResearch"
        @click="canStartResearch ? handleResearchBtnClick() : () => {}"
      />
    </section>
    <section v-else-if="skillType === MECHANIC_RESEARCH">
      <div class="lab-detail-footer-complete flexing mt-10 mx-auto">
        <p class="text-texts-standard-default text-36 uppercase font-bold">
          {{ $t('research.maxLvl') }}
        </p>
      </div>
    </section>
    <section v-else>
      <div class="lab-detail-footer-complete flexing mt-10 mx-auto">
        <p class="text-texts-standard-default text-36 uppercase font-bold">
          {{ $t('research.alreadyActive') }}
        </p>
      </div>
    </section>

    <confirm-popup
      v-if="showConfirm && skillData"
      :button-data="btnConfirm"
      :popup-title="$t('research.premiumCell')"
      :button-text-left="$t('research.research')"
      @close="showConfirm = false"
      @action="researchCellConfirmed"
    >
      <div class="container-upgrade text-36 text-texts-standard-default italic">
        <p>{{ $t('research.reallyResearch') }}</p>
        <p class="lab-detail-footer-box-text text-texts-standard-default text-34 mt-5">
          {{ $t('research.price') }}
        </p>
        <div class="lab-detail-footer-box-reward flexing">
          <aside
            v-for="(amount, resource, index) in skillData.requiredForResearch"
            :key="index"
            class="flexing"
          >
            <p
              v-if="amount"
              class="text-texts-standard-default text-36 font-bold"
              :class="{ 'text-texts-standard-danger': !iHaveEnough(resource) }"
            >
              {{ $filters.$formatNumber(parseInt(amount.toString())) }}
            </p>
            <app-main-icon v-if="amount" :icon-size="48" :icon-name="getResourceIcon(resource)" />
          </aside>
        </div>
      </div>
      <template #buttonTextRight>
        <!-- TODO -->
        <span class="icon-time mr-2" />
        <p class="text-texts-standard-default text-36">
          <vue-countdown
            v-slot="{ days, hours, minutes, seconds }"
            :time="parseInt(skillData.researchTimeDuration.toString()) * 1000"
            :auto-start="false"
          >
            {{ formatTime(days, hours, minutes, seconds, true) }}
          </vue-countdown>
        </p>
      </template>
    </confirm-popup>
    <confirm-popup
      v-if="showConfirmSpeedup"
      :button-data="btnConfirmIcon"
      :popup-title="$t('research.speedUp')"
      :button-text-left="$t('research.speedUp')"
      :button-text-right="skillData.speedupPrice"
      @close="showConfirmSpeedup = false"
      @action="confirmSpeedup"
    >
      <div class="container-upgrade text-36 text-texts-standard-default italic">
        <p>
          {{ $t('research.reallySpeed') }}
        </p>
      </div>
    </confirm-popup>
  </footer>
</template>

<script lang="ts">
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import { MECHANIC_RESEARCH } from '@/globalVariables'
import { formatTime } from '@/helpers'
import { useLabStore } from '@/store/pinia/labStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import { RepeatableStates } from '@/interfaces/lab/States'

import type { PropType } from 'vue'
import { SkillType, type Cell } from '@/interfaces/lab/Cell'
import type { Nullable } from '@/interfaces/utils'

interface BtnData {
  btnId: string
  btnType: string
  btnSize: string
  noIcon?: boolean
}

interface ComponentData {
  RepeatableStates: typeof RepeatableStates
  MECHANIC_RESEARCH: typeof MECHANIC_RESEARCH
  enoughResources: boolean
  showConfirm: boolean
  showConfirmSpeedup: boolean
  btnConfirm: BtnData
  btnConfirmIcon: BtnData
}

export default defineComponent({
  name: 'LabDetailFooter',
  components: {
    ConfirmPopup,
  },
  props: {
    skillType: {
      type: String as PropType<SkillType>,
      default: MECHANIC_RESEARCH,
      validator(value: SkillType): boolean {
        return [SkillType.Camps, SkillType.Research].includes(value)
      },
    },
    skillData: {
      type: Object as PropType<Nullable<Cell>>,
      default: () => null,
    },
  },
  emits: ['researchCell', 'activate', 'finishedResearch', 'speedup'],
  data(): ComponentData {
    return {
      RepeatableStates,
      MECHANIC_RESEARCH,
      enoughResources: false,
      showConfirm: false,
      showConfirmSpeedup: false,
      btnConfirm: {
        btnId: 'btn-research-premium',
        btnType: 'credit',
        btnSize: 'md',
        noIcon: true,
      },
      btnConfirmIcon: {
        btnId: 'btn-research-premium',
        btnType: 'credit',
        btnSize: 'md',
      },
    }
  },
  computed: {
    ...mapState(useLabStore, {
      researchInProgress: 'getResearchInProgress',
    }),
    ...mapState(useResponseTaskStore, {
      researchPoints: 'getResearchPoints',
      money: 'getMoney',
      gems: 'getGems',
    }),
    lockedLevelNeeded(): boolean {
      return this.skillData.state === 'locked_level_needed'
    },
    lockedPreviousNeeded(): boolean {
      return this.skillData.state === 'locked_previous_not_researched'
    },
    canStartResearch(): boolean {
      return (
        this.enoughResources &&
        !this.skillData.locked &&
        (!this.researchInProgress || this.skillData.repeatable) &&
        this.skillData.state !== RepeatableStates.RepeatableDisabled
      )
    },
    enoughGems(): boolean {
      return this.skillData.speedupPrice
        ? this.gems.value > Number(this.skillData.speedupPrice)
        : false
    },
    researchBtnTooltip(): string {
      if (!this.canStartResearch) {
        if (this.researchInProgress) {
          return this.$t('research.researchCounting')
        }
        if (this.skillData.state === RepeatableStates.RepeatableDisabled) {
          return this.$t('research.anotherCampIsResearched')
        }
      }
      return ''
    },
  },
  created(): void {
    this.calculateResources()
  },
  methods: {
    formatTime,
    calculateResources(): void {
      const myResources = {
        researchPoints: this.researchPoints,
        money: this.money,
        gems: this.gems,
      }
      this.enoughResources = true
      for (const resource in this.skillData.requiredForResearch) {
        if (
          this.skillData.requiredForResearch[resource] &&
          Number(myResources[resource].value) < Number(this.skillData.requiredForResearch[resource])
        ) {
          this.enoughResources = false
          break
        }
      }
    },
    getResourceIcon(resourceName: string): string {
      return resourceName === 'researchPoints' ? 'research_points' : resourceName
    },
    iHaveEnough(resource: string): boolean {
      const myResources = {
        researchPoints: this.researchPoints,
        money: this.money,
        gems: this.gems,
      }
      return (
        Number(myResources[resource].value) >= Number(this.skillData.requiredForResearch[resource])
      )
    },
    handleResearchBtnClick(): void {
      if (this.skillData.gemOnly) {
        this.showConfirm = true
      } else {
        this.$emit('researchCell')
      }
    },
    researchCellConfirmed(): void {
      this.showConfirm = false
      this.$emit('researchCell')
    },
    confirmSpeedup(): void {
      this.showConfirmSpeedup = false
      this.$emit('speedup', this.skillData.operationId)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';

footer {
  .lab-detail-footer-box {
    &-text {
      width: 100%;
      position: relative;
    }

    &-reward {
      margin: 1.125rem 0 1.938rem;
      gap: 1.5rem;
    }
  }

  .lab-detail-footer-btn,
  .lab-button--in-progress {
    width: calc(100% - 3rem);
    margin: 0 auto;
  }

  .lab-detail-footer-complete {
    width: 30rem;
    height: 4.375rem;
    position: relative;

    @if $isSsm {
      background: linear-gradient(
        to right,
        transparent,
        rgba(208, 188, 114, 0.7),
        #e0c572,
        #c7a655,
        rgba(169, 134, 53, 0.7),
        rgba(164, 126, 40, 0.01)
      );
    }

    @if $isWsm {
      background: linear-gradient(to left, #d2942000, #fccb6180, #fccb6180, #d2942000);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.125rem;

      @if $isSsm {
        background: linear-gradient(
          to right,
          transparent,
          rgba(244, 233, 167, 0.7),
          rgba(244, 233, 167, 1),
          rgba(244, 233, 167, 0.7),
          transparent
        );
      }

      @if $isWsm {
        background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
      }
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  }
}
</style>
