<template>
  <template v-if="!isContentLoading">
    <div class="flexing relative h-full w-full" :class="{ 'py-4': $isWsm }">
      <events-warehouse-lab-chest />

      <app-scrollbar
        class="events-warehouse-items-scroll flex items-center mb-4"
        width="100%"
        height="100%"
        scroll="x"
        slide="x"
        :arrows="true"
        arrow-pos-left="30rem"
        arrow-post-right="2rem"
      >
        <div class="flexing space-x-10 ml-8">
          <events-warehouse-lab-item v-for="(item, index) in artefacts" :key="index" :item="item" />
        </div>
      </app-scrollbar>
    </div>
  </template>

  <component-loading v-else :is-loading="true" width="100%" />
</template>

<script lang="ts">
import { useWarehouseStore } from '@/store/pinia/events/warehouseStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import EventsWarehouseLabChest from './EventsWarehouseLabChest.vue'
import EventsWarehouseLabItem from './EventsWarehouseLabItem.vue'

interface ComponentData {
  isContentLoading: boolean
}

export default defineComponent({
  name: 'EventsWarehouseLab',
  components: {
    EventsWarehouseLabChest,
    EventsWarehouseLabItem,
  },
  data(): ComponentData {
    return {
      isContentLoading: false,
    }
  },
  computed: {
    ...mapState(useWarehouseStore, {
      artefacts: 'getArtefactBuildsConfig',
    }),
  },
  async created(): Promise<void> {
    this.isContentLoading = true
    await this.loadConfig()

    this.isContentLoading = false
  },
  methods: {
    ...mapActions(useWarehouseStore, {
      loadConfig: 'loadConfig',
    }),
  },
})
</script>

<style lang="scss" scoped>
.events-warehouse-items-scroll {
  padding-top: 1rem;
  margin-right: $default-margin;
}
</style>
