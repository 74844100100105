<template>
  <div class="avatar-menu-content-panel absolute">
    <div class="avatar-menu-content-panel-up w-full flex items-center">
      <div class="avatar-menu-content-panel-up-section-player flex items-center">
        <player-level class="ml-2" />
        <div class="avatar-menu-content-panel-up-section ml-5">
          <avatar-menu-name
            :name-prop="userData?.username"
            :badges-data="getEventBadges"
            @click="openProfile()"
          />
          <div class="avatar-menu-content-panel-up-section-progress flex items-center">
            <div
              v-tippy="{
                theme: 'WSM',
                content:
                  'Experience: ' + userData?.stats?.EXPERIENCE ??
                  '-' + '/' + userData?.stats?.TARGET_EXPERIENCE ??
                  '-',
                placement: 'bottom',
              }"
            >
              <app-progress-bar
                v-tippy="{
                  theme: 'WSM',
                  allowHTML: true,
                  content: `
                  ${$t('map.level')} : ${userData?.stats?.LEVEL ?? '-'} <br>
                                  ${$t('map.experience')} : ${
                                    userData?.stats?.EXPERIENCE ?? '-'
                                  } <br>
                        ${$t('map.next_level')} :
                        ${
                          !hasMaxLevel && userData?.stats?.TARGET_EXPERIENCE
                            ? userData?.stats?.TARGET_EXPERIENCE
                            : '-'
                        }
                  `,
                  placement: 'bottom',
                }"
                :actual="Number(userData?.stats?.EXPERIENCE ?? 0)"
                :goal="userData?.stats?.TARGET_EXPERIENCE ?? 0"
                :experience="userData?.stats?.STARTING_EXPERIENCE ?? 0"
                :bar-width="18.75"
                :bar-height="0.8125"
              />
            </div>
            <app-button
              v-if="showAdminSkipButtons"
              btn-type="secondary"
              :btn-text="ADMIN_ADD_XP"
              btn-size="xxsm"
              :class="getEventBadges?.length > 0 ? 'ml-24' : 'ml-5'"
              @click="fastForwardExperience"
            />
            <app-button
              v-if="showAdminSkipButtons"
              btn-type="secondary"
              :btn-text="ADMIN_ADD_LVL"
              btn-size="xxsm"
              class="ml-5"
              style="pointer-events: all"
              @click="levelUp"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="avatar-menu-content-panel-bottom" @click="goToPage()">
      <p class="text-texts-standard-additional text-28">
        {{ setBottomText }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import AvatarMenuName from '@/components/AvatarMenu/AvatarMenuName.vue'
import PlayerLevel from '@/components/Profile/PlayerLevel.vue'
import { ADMIN_ADD_LVL, ADMIN_ADD_XP, MECHANIC_CLUB, pathImages } from '@/globalVariables'
import { playSound } from '@/helpers'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  pathImages: typeof pathImages
  ADMIN_ADD_XP: typeof ADMIN_ADD_XP
  ADMIN_ADD_LVL: typeof ADMIN_ADD_LVL
}

export default defineComponent({
  name: 'AvatarMenuPanel',
  components: {
    PlayerLevel,
    AvatarMenuName,
  },
  data(): ComponentData {
    return {
      pathImages,
      ADMIN_ADD_XP,
      ADMIN_ADD_LVL,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userData: 'getUserData',
      hasClub: 'getHasClub',
      clubName: 'getClubName',
      hasMaxLevel: 'hasMaxLevel',
      showAdminSkipButtons: 'showAdminSkipButtons',
      getEventBadges: 'getEventBadges',
    }),
    ...mapState(useTutorialStore, {
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useDisciplineStore, {
      getUnlockedDisciplinesIds: 'getUnlockedDisciplinesIds',
    }),
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
    }),
    setBottomText() {
      if (this.hasClub) return this.clubName
      else return this.$t('profile.withoutClub')
    },
  },

  methods: {
    ...mapActions(useUserStore, ['fastForwardExperience', 'levelUp']),
    goToPage(): void {
      if (this.hasMechanic(MECHANIC_CLUB)) {
        // robit prelinkovanie len ak mam odomknutu mechaniku klubov
        if (this.hasClub) {
          // ak som clenom klubu presmerovat na mapu
          if (!this.isTutorial) {
            this.$router.push({ name: this.$getWebView('ClubsMap') })
          }
        } else {
          // ak nemám klub presmerovat na dostupne kluby
          this.$router.push({
            name: this.$getWebView('ClubsAvailable'),
            query: { homepage: 'true' },
          })
        }
      }
    },
    openProfile(): void {
      playSound('profil_open')
      this.$router.push({
        name: this.$getWebView('ProfileOverview'),
        params: { id: this.getUnlockedDisciplinesIds[0] },
      })
    },
  },
})
</script>

<style lang="scss"></style>
