import type GSAP from 'gsap'
/**
 * Configurak na gsap
 * @param {*} gsap instancia gsapu
 */
export const configureGsap = (gsap: typeof GSAP): void => {
  gsap.config({
    force3D: false,
  })
}
