<template>
  <div
    class="session-modal flexing w-full h-full absolute top-0 left-0 z-10"
    :data-event-theme="namespace"
  >
    <div class="session-modal-text flexing flex-col w-full mx-auto relative">
      <p class="text-40 text-texts-standard-important font-bold py-3 mb-4">
        {{ $te('sessionModalTasksDoneText') }}
      </p>
      <p class="text-50 gradient-text-light font-bold">
        {{ $te('yourReward') }}
      </p>
      <rewards
        class="py-7 mb-12"
        :reward-data="formatRewards(rewards, 'type', 'value')"
        :icon-size="72"
      />
      <p class="flexing">
        <app-button
          btn-type="confirm"
          :btn-text="$t('button.continue')"
          btn-size="md"
          class="px-12 py-10"
          @click="setEventTaskchainSessionModalData({ rewards: null })"
        />
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import Rewards from '@/components/Rewards.vue'
import { EVENT_PROGRESS_DATA, pathImages } from '@/globalVariables'
import { formatRewards } from '@/helpers/formatRewards'
import type Reward from '@/interfaces/Reward'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useTaskChainStore } from '@/store/pinia/events/taskChainStore'
import { mapActions, mapState } from 'pinia'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
interface ComponentData {
  pathImages: typeof pathImages
  EVENT_PROGRESS_DATA: typeof EVENT_PROGRESS_DATA
}

export default defineComponent({
  name: 'SessionModal',
  components: { Rewards },
  props: {
    rewards: {
      type: Array as PropType<Reward[]>,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
      EVENT_PROGRESS_DATA,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
  },
  methods: {
    ...mapActions(useTaskChainStore, {
      setEventTaskchainSessionModalData: 'setTaskChainSessionModalData',
    }),
    formatRewards,
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='challenge_cup'] {
  --event-tasks-done-bg: url('#{$path-events}challenge-cup/taskchain/tasks/done-bg.avif');
  --event-tasks-done-avatars-bg: url('#{$path-events}challenge-cup/taskchain/tasks/done-avatars-bg.webp');
  --event-tasks-done-text-bg: url('#{$path-events}shared/taskchain/tasks/done-texts-bg.webp');
}

[data-event-theme='frozen'] {
  --event-tasks-done-bg: url('#{$path-events}frozen-championship/taskchain/tasks/done-bg.avif');
  --event-tasks-done-avatars-bg: url('#{$path-events}frozen-championship/taskchain/tasks/done-avatars-bg.webp');
  --event-tasks-done-text-bg: url('#{$path-events}shared/taskchain/tasks/done-texts-bg.webp');
}

.session-modal {
  // WSM-7672
  // width: 120rem;
  // height: 67.5rem;
  @include background(var(--event-tasks-done-bg), cover);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include background(var(--event-tasks-done-avatars-bg), contain);
  }

  &-text {
    justify-content: flex-start;
    width: 103.063rem;
    height: 19.563rem;
    @include background(var(--event-tasks-done-text-bg), contain);

    &.relative {
      top: 12rem;
    }
  }
}
</style>
