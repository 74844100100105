<template>
  <div
    class="game-pass-purchase-btn flex-col flexing relative"
    :class="[
      `pass-type-${passType}`,
      { 'cursor-pointer clickable-element': !isBought && !isBasic },
    ]"
    @click="goToPassOffer"
  >
    <div v-if="!isBought && !isBasic" class="btn-buy">
      <p class="text-36 text-texts-standard-default font-bold mt-1">{{ $t('button.purchase') }}</p>
    </div>
    <p v-if="isBought" class="text-texts-standard-important uppercase text-34 font-bold">
      {{ $t('event.activated') }}
    </p>
    <p class="btn-text text-40 uppercase font-bold">
      {{ $t(`gamePass.gamePass${capitalize(passType)}`) }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { capitalize } from '@/helpers'

import { GamePassType } from '@/interfaces/premium/enums'

interface ComponentData {
  GamePassType: typeof GamePassType
}

export default defineComponent({
  name: 'GamePassPurchaseButton',
  props: {
    passType: {
      type: String,
      required: true,
      validator(propName: GamePassType): boolean {
        return [GamePassType.Basic, GamePassType.Pro, GamePassType.Elite].includes(propName)
      },
    },
    isBought: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return { GamePassType }
  },
  computed: {
    isBasic(): boolean {
      return this.passType === GamePassType.Basic
    },
  },
  methods: {
    capitalize,
    goToPassOffer(): void {
      if (this.isBought || this.isBasic) return
      this.$router.push({ name: this.$getWebView('PremiumGamePassPacks') })
    },
  },
})
</script>

<style lang="scss" scoped>
.game-pass-purchase-btn {
  width: 16.125rem;
  height: 13.375rem;
  background-repeat: no-repeat;
  background-size: contain;

  .btn-text {
    line-height: 3rem;
    background-clip: text;
    text-shadow: none;
    color: transparent;
    background-image: linear-gradient(to top, #ffedbd, #fffcf1),
      linear-gradient(to bottom, #fbb642, #fbb642);
  }

  .btn-buy {
    width: 15.75rem;
    height: 4.563rem;
    background: url($path-premium + 'gamePass/main/pass-btn-buy.avif') no-repeat center;
    background-size: contain;
    position: relative;
    top: -1rem;
  }

  &.pass-type-basic {
    background-image: url($path-premium + 'gamePass/main/pass-btn-basic.avif');
  }

  &.pass-type-pro {
    background-image: url($path-premium + 'gamePass/main/pass-btn-pro.avif');
  }

  &.pass-type-elite {
    background-image: url($path-premium + 'gamePass/main/pass-btn-elite.avif');
  }
}
</style>
