import type { AtlasDataInterface } from '@/map-phaser-new/interfaces'
import { clubsBuildingsAtlasLoadData } from './clubsBuildingsAtlasLoadData'

/**
 * This function is used to collect all imports of clubs atlases, to make loading at BootScene easier
 * @returns Promise<AtlasDataInterface[]>
 */
export const exportedClubsAtlases: () => Promise<AtlasDataInterface[]> = async () => [
  ...clubsBuildingsAtlasLoadData(),
]
