<template>
  <div class="clubs-championship-about">
    <app-scrollbar width="99.5%" height="100%" scroll="y" slice="y">
      <div class="clubs-championship-about-texts text-32 text-texts-standard-default">
        <ul>
          <li>
            <p>
              {{ $replacePlaceholder($t('club.championshipAboutText1'), '%s', 10) }}
            </p>
          </li>
          <li>
            <p>
              {{ $replacePlaceholder($t('club.championshipAboutText2'), '%s', 72) }}
            </p>
          </li>
          <li>
            <p>{{ $t('club.championshipAboutText3') }}</p>
          </li>
          <li>
            <p>{{ $t('club.championshipAboutText4') }}</p>
          </li>
          <li>
            <p>{{ $t('club.championshipAboutText5') }}</p>
          </li>
        </ul>
      </div>
      <div
        class="clubs-championship-about-table-head text-36 text-texts-standard-default uppercase"
      >
        <p>{{ $t('club.league') }}</p>
        <p>{{ $t('club.divisionCount') }}</p>
      </div>
      <div
        v-for="(row, index) in getLeagues"
        :key="index"
        class="clubs-championship-about-table-content"
      >
        <div :class="[`badge-${toKebabCase(row.badge)}-120`, { 'ml-4 mr-4': $isSsm }]" />
        <p class="text-texts-standard-important text-36 font-bold uppercase mx-4">
          {{ row.text }}
        </p>
        <p
          class="clubs-championship-about-division text-36 uppercase mx-4"
          :class="$isWsm ? 'text-texts-standard-name' : 'text-texts-standard-additional'"
        >
          {{ row.divisions }}
        </p>
      </div>
      <p class="text-32 text-texts-standard-default my-4 ml-10 text-left">
        * {{ $t('club.rookieLeagueInfo') }}
      </p>
    </app-scrollbar>
  </div>
</template>

<script lang="ts">
import { CLUB_CHAMPIONSHIP_DIVISIONS, CLUB_CHAMPIONSHIP_LEAGUES } from '@/globalVariables'
import { toKebabCase } from '@/helpers'
import { defineComponent } from 'vue'

interface ChampionshipLeaguePopupData {
  badge: string
  text: string
  divisions: number
}

export default defineComponent({
  name: 'ClubsChampionshipAboutPopup',
  computed: {
    getLeagues(): ChampionshipLeaguePopupData[] {
      return [
        {
          badge: CLUB_CHAMPIONSHIP_LEAGUES.legendary,
          text: this.$t('club.legendaryLeague'),
          divisions: CLUB_CHAMPIONSHIP_DIVISIONS._1,
        },
        {
          badge: CLUB_CHAMPIONSHIP_LEAGUES.master,
          text: this.$t('club.masterLeague'),
          divisions: CLUB_CHAMPIONSHIP_DIVISIONS._2,
        },
        {
          badge: CLUB_CHAMPIONSHIP_LEAGUES.elite,
          text: this.$t('club.eliteLeague'),
          divisions: CLUB_CHAMPIONSHIP_DIVISIONS._4,
        },
        {
          badge: CLUB_CHAMPIONSHIP_LEAGUES.professional,
          text: this.$t('club.professionalLeague'),
          divisions: CLUB_CHAMPIONSHIP_DIVISIONS._8,
        },
        {
          badge: CLUB_CHAMPIONSHIP_LEAGUES.amateur,
          text: this.$t('club.amateurLeague'),
          divisions: CLUB_CHAMPIONSHIP_DIVISIONS._16,
        },
        {
          badge: CLUB_CHAMPIONSHIP_LEAGUES.rookie,
          text: this.$t('club.rookieLeague') + ' * 1+ ',
          divisions: CLUB_CHAMPIONSHIP_DIVISIONS._32,
        },
      ]
    },
  },
  methods: {
    toKebabCase,
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/clubs-championship-leagues.scss';

.clubs-championship-about {
  width: 103.625rem;
  height: 44.188rem;

  &-texts {
    width: 92%;
    margin: 2.5rem auto;

    li {
      list-style-type: disc;
      text-align: left;
      margin: 1rem 0;
    }
  }

  &-table-head {
    width: 95%;
    height: 3.688rem;

    @if $isWsm {
      background: #0f1a27;
    }

    @if $isSsm {
      background: #292d30;
    }

    margin: 1.25rem auto 0.75rem;
    padding: 0 1.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-table-content {
    width: 95%;

    display: flex;
    align-items: center;

    @if $isWsm {
      height: 4.625rem;
      margin: 3.5rem auto;
      background: linear-gradient(
        to left,
        transparent 0%,
        #144a73 10%,
        #144a73 90%,
        transparent 100%
      );
    }
    @if $isSsm {
      height: 7.5rem;
      margin: 0.5rem auto;
      background: rgba(114, 114, 176, 0.7);
    }
  }

  &-division {
    margin-left: auto;
    margin-right: 8rem;
  }
}
</style>
