<template>
  <div class="bounty-board-milestone flex">
    <div class="bounty-board-milestone-base">
      <div class="bounty-board-milestone-progress" :style="{ width: `${progressWidth}%` }" />
      <div class="bounty-board-milestone-items flex flex-row justify-between">
        <div
          v-for="(item, index) in milestone"
          :key="item.id"
          class="bounty-board-milestone-item text-36 text-texts-standard-name font-bold flexing pt-0.5"
          :class="`bounty-board-milestone-item--${index} bounty-board-milestone-item--${item.state}`"
        >
          <div v-if="item.state === TaskState.Focus" class="bounty-board-milestone-item-arrow" />
          <span class="bounty-board-milestone-item-text">{{ index + 1 }}</span>
        </div>
      </div>
    </div>
    <div class="bounty-board-milestone-button gap-2 flex justify-between">
      <div
        class="bounty-board-milestone-button-text flexing text-36 text-texts-standard-name font-bold"
      >
        <shrink-text
          :text="`${totalTokenCount} Free`"
          :font-size="36"
          container-class-list="flex-1 min-w-0"
          text-class-list="bounty-board-milestone-button-free-text"
          animated-overflow
        />
        <app-main-icon
          :icon-size="48"
          icon-name="fortune-basket-token"
          class="bounty-board-milestone-button-icon"
        />
      </div>
      <tippy theme="WSM" placement="bottom" max-width="50rem">
        <app-icon icon-name="info-50" />
        <template #content>
          <p>
            {{
              isEventPassMaxTierActive
                ? $replacePlaceholder(
                    $replacePlaceholder($te('tokensForTasks'), '{2to4}', totalTokenCount),
                    '{number}',
                    10,
                  )
                : $replacePlaceholder($te('tokenForTasks'), '{number}', 10)
            }}
          </p>
        </template>
      </tippy>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import ShrinkText from '@/components/ShrinkText.vue'

import type { PropType } from 'vue'
import type TaskBoardStateApiResponse from '@/interfaces/responses/events/task-board/TaskBoardStateApiResponse'
import type { Offer, OfferParameter } from '@/interfaces/premium/Offer'

enum TaskState {
  Done = 'done',
  Focus = 'focus',
  Gold = 'gold',
}

interface MilestoneItem {
  id: number
  state: string
}

interface ComponentData {
  TaskState: typeof TaskState
}

export default defineComponent({
  name: 'BountyBoardMilestone',
  components: {
    ShrinkText,
  },
  props: {
    milestones: {
      type: Object as PropType<TaskBoardStateApiResponse['competition_milestones']>,
      default: null,
    },
  },
  data(): ComponentData {
    return {
      TaskState,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      eventPass: 'getEventPass',
    }),
    progressWidth(): number {
      return this.milestones?.progress ? this.setPercentage(this.milestones?.progress - 1) : 0
    },
    totalTokenCount(): number {
      return this.milestones?.reward?.count ?? 0
    },
    milestone(): MilestoneItem[] {
      return Array.from(
        { length: this.milestones.milestone },
        (_: undefined, index: number): MilestoneItem => {
          if (index < this.milestones.progress) return { id: index, state: TaskState.Done }
          if (index === this.milestones.progress) return { id: index, state: TaskState.Focus }
          if (index === this.milestones.milestone - 1) return { id: index, state: TaskState.Gold }
          return { id: index, state: 'next' }
        },
      )
    },
    isEventPassMaxTierActive(): boolean {
      return !!this?.eventPass?.find(
        ({ parameters, remaining_buys }: Offer): boolean =>
          parameters?.find(
            ({ name, value }: OfferParameter): boolean => name === 'pass_tier' && value === 3,
          ) && remaining_buys === 0,
      )
    },
  },
  methods: {
    setPercentage(index: number): number {
      // 100 / 9
      return index * 11.1
    },
  },
})
</script>

<style lang="scss" scoped>
.bounty-board-milestone-base {
  position: relative;
  top: -0.25rem;
  width: 58.375rem;
  height: 5rem;
  background: url($path-events + 'autumn-fair/bounty-board/task-progressbar-bg.avif');
  background-size: contain;

  .bounty-board-milestone-progress {
    position: absolute;
    height: 100%;
    background: url($path-events + 'autumn-fair/bounty-board/task-progressbar-fill.avif');
    background-size: contain;
  }

  .bounty-board-milestone-items {
    position: relative;
    top: -0.375rem;
  }
}

.bounty-board-milestone-item {
  position: relative;
  width: 5.625rem;
  height: 5.625rem;

  &-text {
    background-image: linear-gradient(to top, #e3e3e3, #fff);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &--0 {
    margin-left: -2.8125rem;
  }

  &--9 {
    margin-right: -2.8125rem;
  }

  &--done {
    background: url($path-events + 'autumn-fair/bounty-board/task-progress-circle--green.avif')
      no-repeat center;
    background-size: contain;
  }

  &--focus {
    background: url($path-events + 'autumn-fair/bounty-board/task-progress-circle--blue-green.avif')
      no-repeat center;
    background-size: contain;
  }

  &--next {
    background: url($path-events + 'autumn-fair/bounty-board/task-progress-circle--blue.avif')
      no-repeat center;
    background-size: contain;
  }

  &--gold {
    background: url($path-events + 'autumn-fair/bounty-board/task-progress-circle--gold.avif')
      no-repeat center;
    background-size: contain;
  }

  .bounty-board-milestone-item-arrow {
    position: absolute;
    top: -0.375rem;
    left: 50%;
    transform: translateX(-50%);
    width: 2rem;
    height: 0.813rem;
    background: url($path-events + 'autumn-fair/bounty-board/ico-arrow.avif') no-repeat center;
    background-size: contain;
  }
}

.bounty-board-milestone-button {
  width: 17rem;
  height: 4.5rem;
  padding: 0.625rem 0.625rem 0.625rem 3.25rem;
  background: url($path-events + 'autumn-fair/bounty-board/golden-box-bg.avif') no-repeat center;
  background-size: 100% 100%;
  border: 0.125rem solid #ffe161;

  &-text {
    flex: 1 1 auto;
    min-width: 0;

    :deep() {
      .animated-container {
        padding: 2px;
      }
    }
  }

  &-free-text {
    background-image: linear-gradient(to top, #ffe2bd, #fffcf1);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
