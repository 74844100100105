import { SCENES_KEYS } from '@/map-phaser-new/constants'

export abstract class CoreHandler {
  protected abstract game: Phaser.Game

  abstract setUp(game: Phaser.Game, emitter?: Phaser.Events.EventEmitter): void

  protected getActiveScene(): Phaser.Scene {
    //TODO: remove window.game to get scene, not to use SceneManager (it switches scene immediately)
    // but ScenePlugin (switches scene in next render)
    // explain -> all methods on the ScenePlugin, like this.scene.switch() are queued, they go into
    // the Scene Manager which will run the operation on the next game step, when everything has
    // finished for this render. The methods in the SceneManager, like this.game.scene.switch() are
    // immediate, they don't care what state the Scene or render is in, so if you fire them off the
    // back of an input event, or physics collision, it can often cause the Scene to never even
    // process the current step fully, leading to all kinds of potential side-effects
    return window.game.scene.getScenes(true)[0] ?? window.game.scene.getScene(SCENES_KEYS.BootScene)
  }
}
