<template>
  <div class="lab-camps app-page-wrapper flex flex-col absolute">
    <menu-component
      menu-type="lab"
      :title="$t('research.lab')"
      right-box-icon="research_points"
      :right-box-value="researchPoints.value"
      :is-tooltip="true"
      :sub-menu-active="true"
      :sub-menu="{
        1: {
          text: $t('research.Disciplines'),
          route: $getWebView('LabDisciplines'),
        },
        2: {
          text: $t('research.economics'),
          route: $getWebView('LabEconomics'),
        },
        3: {
          text: $t('research.camps'),
          route: $getWebView('LabCamps'),
        },
      }"
    />
    <section class="relative w-full h-full flex flex-col">
      <div v-if="showDetail" class="lab-overlay" @click="hideDetail" />
      <div class="relative w-full h-full flex flex-col safe-area">
        <header class="lab-camps-header flexing relative">
          <modal-head :title-popup="$t('research.lab')" :hide-content="true">
            <template #popupContent>
              <lab-popup />
            </template>
          </modal-head>
        </header>
        <main v-if="showContent" class="lab-camp-main m-auto flex justify-center relative">
          <div class="lab-camp-main-wrapper flexing relative">
            <app-scrollbar width="100%" height="100%" scroll="y" slide="y">
              <lab-camps-tree
                :lab-data="skillTrees?.camp"
                @select-skill="selectSkill"
                @reload-data="initMethod"
              />
            </app-scrollbar>
          </div>
        </main>
        <lab-detail
          v-if="showDetail && selectedSkill"
          ref="researchDetail"
          :skill-type="SkillType.Camps"
          :skill-id="selectedSkillId"
          @research-cell="checkConfirm"
          @finished-research="finishedResearch"
          @speedup="speedup"
          @activate="activate"
        />
        <confirm-popup
          v-if="showPopup"
          :not-multi="true"
          :button-data="btnActivate"
          :popup-title="$t('research.trainingCamp')"
          :single-button-text="$t('common.confirm')"
          @close="confirmActivate(false)"
          @action="confirmActivate"
        >
          <section class="flexing flex-col">
            <p class="container-upgrade text-36 text-texts-standard-important italic my-5">
              {{ $t('research.reallyNewCamp') }}
            </p>
            <lab-icons :icon-data="iconData" />
            <p class="container-upgrade text-36 text-texts-standard-default italic mt-5">
              {{ $t('research.deactivateCampBonus') }}
            </p>

            <p class="lab-detail-footer-box-text text-texts-standard-default text-34 mt-5">
              {{ $t('research.price') }}
            </p>
            <div class="white-strip my-3" />
            <div class="lab-detail-footer-box-reward flexing mb-5 gap-6">
              <template v-for="(amount, resource, i) in selectedSkill.requiredForResearch" :key="i">
                <aside v-if="amount" class="flexing">
                  <p class="text-texts-standard-default text-36 font-bold">
                    {{ $filters.$formatNumber(parseInt(amount.toString())) }}
                  </p>
                  <app-main-icon :icon-size="48" :icon-name="getResourceIcon(resource)" />
                </aside>
              </template>
            </div>
          </section>
        </confirm-popup>
        <component-loading height="100%" :is-loading="!showContent" />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import LabDetail from '@/components/Lab/LabDetail.vue'
import LabIcons from '@/components/Lab/LabIcons.vue'
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import ModalHead from '@/components/ModalHead.vue'
import LabPopup from '@/components/Lab/LabPopup.vue'
import LabCampsTree from '@/components/Lab/Camps/LabCampsTree.vue'
import {
  researchApplyEndpoint,
  researchCellEndpoint,
  researchSeenEndpoint,
  speedUpResearchEndpoint,
} from '@/globalVariables'
import { newUnlockedCells, toSnakeCase } from '@/helpers'
import { useLabStore } from '@/store/pinia/labStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { Cell, Icon } from '@/interfaces/lab/Cell'
import { RepeatableStates } from '@/interfaces/lab/States'
import { SkillType } from '@/interfaces/lab/Cell'
import type { Nullable } from '@/interfaces/utils'
import type {
  ResearchCellApiRespopnse,
  ResearchSeenApiResponse,
} from '@/interfaces/responses/lab/LabResponses'

interface BtnData {
  btnId: string
  btnType: string
  btnSize: string
}

interface ComponentData {
  SkillType: typeof SkillType
  activeParent: number
  showDetail: boolean
  selectedSkillId: string
  selectedBranchIndex: number
  btnActivate: BtnData
  activateStarted: boolean
  showPopup: boolean
}

export default defineComponent({
  name: 'LabCamps',
  components: {
    ConfirmPopup,
    LabDetail,
    LabIcons,
    ModalHead,
    LabPopup,
    LabCampsTree,
  },
  data(): ComponentData {
    return {
      activateStarted: false,
      showPopup: false,
      btnActivate: {
        btnId: 'btn-camps-activate',
        btnType: 'secondary',
        btnSize: 'xl',
      },
      activeParent: null,
      showDetail: false,
      selectedSkillId: '',
      selectedBranchIndex: null,
      SkillType,
    }
  },
  computed: {
    ...mapState(useLabStore, {
      skillTreeLoaded: 'getSkillTreesLoaded',
      skillTrees: 'getSkillTrees',
    }),
    ...mapState(useResponseTaskStore, {
      researchPoints: 'getResearchPoints',
    }),
    showContent(): boolean {
      return this.skillTreeLoaded && this.skillTrees?.camp?.length > 0
    },
    unlockedUnseenCells(): string[] {
      if (this.skillTrees?.camp) {
        return newUnlockedCells(this.skillTrees.camp)
      }
      return []
    },
    selectedSkill(): Nullable<Cell> {
      if (!this.selectedSkillId && !this.selectedBranchIndex) return null

      return (
        this.skillTrees?.camp[this.selectedBranchIndex]?.find(
          (tree: Cell): boolean => tree.id === this.selectedSkillId,
        ) ?? null
      )
    },
    iconData(): Icon {
      if (!this.selectedSkill) return
      return {
        skillType: SkillType.Camps,
        currentLevel: this.selectedSkill.currentLevel ? this.selectedSkill.currentLevel : 0,
        maxLevel: this.selectedSkill.maxLevel,
        gemOnly: this.selectedSkill.gemOnly,
        locked: this.selectedSkill.locked,
        isNew: this.selectedSkill.isNew,
        icon: this.selectedSkill.icon,
        unlockedAfterLevel: this.selectedSkill.unlockedAfterLevel,
      }
    },
  },

  async created(): Promise<void> {
    this.initMethod()
  },
  methods: {
    ...mapActions(useLabStore, ['loadCampsTree', 'setResearchStatus']),
    async initMethod(): Promise<void> {
      this.hideDetail()
      await this.loadCampsTree()
      this.markSeen()
    },
    hideDetail(): void {
      this.selectedSkillId = ''
      this.selectedBranchIndex = null
      this.showDetail = false
    },
    async markSeen(): Promise<void> {
      try {
        await this.$axios.put<{}, ResearchSeenApiResponse>(researchSeenEndpoint, {
          cell_ids: this.unlockedUnseenCells,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    },
    confirmActivate(research: boolean = true): void {
      this.activateStarted = false

      if (research && this.selectedSkill) {
        this.researchCell(this.selectedSkill.id)
      }
      this.showPopup = false
    },
    checkConfirm(skillId: string): void {
      let activatedBranch = null
      this.activeParent = null

      if (!skillId || this.selectedBranchIndex === null) return

      // hladame branchu ktora obsahuje aktivovany kamp
      activatedBranch = this.skillTrees.camp[this.selectedBranchIndex]?.find(
        (cell: Cell): boolean => cell.state === RepeatableStates.RepeatableActivated,
      )

      if (activatedBranch && !this.showPopup) {
        this.showPopup = true
        this.activeParent = activatedBranch
        return
      }
      if (!this.activeParent) {
        this.researchCell(skillId)
      }
    },
    async researchCell(skillId: string): Promise<void> {
      if (this.activateStarted) {
        return
      }
      if (skillId) {
        this.activateStarted = true
        Promise.all([
          this.$axios.put<{}, ResearchCellApiRespopnse>(researchCellEndpoint, { cell_id: skillId }),
          this.setResearchStatus(),
          this.loadCampsTree(),
        ])
          .then((): void => {
            if (this.showDetail) {
              this.$refs.researchDetail.getSkillData(skillId)
            }
            this.initMethod()
            this.activateStarted = false
          })
          .catch((error: unknown): void => {
            console.error(error)
          })
      }
    },
    async speedup(operationId: string): Promise<void> {
      Promise.all([
        this.$axios.put<{}, null>(speedUpResearchEndpoint, { operation_id: operationId }),
        this.setResearchStatus(),
        this.loadCampsTree(),
      ])
        .then((): void => {
          if (this.showDetail) {
            this.$refs.researchDetail.getSkillData()
          }
          this.initMethod()
        })
        .catch((error: unknown): void => {
          console.error(error)
        })
    },
    async finishedResearch(): Promise<void> {
      Promise.all([this.setResearchStatus(), this.loadCampsTree()])
        .then((): void => {
          if (this.showDetail) {
            this.$refs.researchDetail.getSkillData()
          }
          this.initMethod()
        })
        .catch((error: unknown): void => {
          console.error(error)
        })
    },
    async activate(): Promise<void> {
      if (this.selectedSkill) {
        try {
          await this.$axios.put<{}, string>(researchApplyEndpoint)
          this.initMethod()
        } catch (error: unknown) {
          console.error(error)
        }
      }
    },
    getResourceIcon(resourceName: string): string {
      return resourceName === 'researchPoints' ? toSnakeCase(resourceName) : resourceName
    },
    selectSkill(id: string, index: number): void {
      this.selectedSkillId = id
      this.selectedBranchIndex = index
      this.showDetail = true
    },
  },
})
</script>

<style lang="scss" scoped>
.lab-camps {
  &.app-page-wrapper {
    background: url($path-backgrounds + 'lab-wrapper-bg.jpg?v2') center bottom no-repeat;
    background-size: cover;
  }

  &-header {
    width: 97%;
    margin-top: 1.125rem;
  }
}
.lab-camp-main {
  width: 120rem;
  height: 100%;

  & > #lab-map-container {
    position: relative;
    top: 1.625rem;
  }
  &-wrapper {
    width: 90%;
  }
}

.lab-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.white-strip {
  width: 20rem;
}
</style>
