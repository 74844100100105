<template>
  <section v-if="textChanges" class="information-text">
    <h3 class="text-texts-standard-important text-40 uppercase italic">{{ title }}</h3>
    <p
      v-if="isMasters && activeState === ActiveState.NotStarted"
      class="text-texts-standard-default text-28"
    >
      {{
        $replacePlaceholder(
          $te('mastersDescription'),
          '%s',
          textChanges?.playoff_advancing ? textChanges.playoff_advancing.toString() : '',
        )
      }}
    </p>
    <p
      v-else-if="activeState === ActiveState.Ended || joinStatus === JoinStatus.NotJoined"
      class="text-texts-standard-default text-28"
    >
      {{ $te('playoffEnded') }}
    </p>
    <p v-else class="text-texts-standard-default text-28">{{ description }}</p>
  </section>
</template>

<script lang="ts">
import {
  ActiveState,
  JoinStatus,
  type PlayoffTextExchange,
} from '@/interfaces/events/MastersPlayoffInterfaces'
import { useMastersEventStore } from '@/store/pinia/events/mastersStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  ActiveState: typeof ActiveState
  JoinStatus: typeof JoinStatus
  textChanges: Nullable<PlayoffTextExchange>
}

export default defineComponent({
  name: 'TournamentInformation',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    activeState: {
      type: String as PropType<ActiveState>,
      required: true,
    },
    joinStatus: {
      type: String as PropType<JoinStatus>,
      required: true,
    },
    isMasters: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      textChanges: null,
      ActiveState,
      JoinStatus,
    }
  },
  async created(): Promise<void> {
    this.textChanges = await this.loadTextsChanger()
  },
  methods: {
    ...mapActions(useMastersEventStore, ['loadTextsChanger']),
  },
})
</script>

<style lang="scss" scoped>
.information-text {
  text-align: left;
}
</style>
