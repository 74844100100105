<template>
  <table class="table-auto table-wrapper">
    <thead>
      <tr class="text-28 uppercase">
        <th
          :class="{
            'text-texts-standard-additional': $isWsm,
            'text-texts-standard-default': $isSsm,
          }"
          :style="{ width: '45%' }"
        >
          {{ $t(titles[0]) }}
        </th>
        <th
          :class="{
            'text-texts-standard-additional': $isWsm,
            'text-texts-standard-default': $isSsm,
          }"
          :style="{ width: '30%' }"
        >
          {{ $t(titles[1]) }}
        </th>
        <th
          :class="{
            'text-texts-standard-important':
              $isWsm || ($isSsm && titles[2] === 'common.seasonalAttribute'),
            'text-texts-standard-default': $isSsm && titles[2] !== 'common.seasonalAttribute',
          }"
          :style="{ width: '25%' }"
        >
          {{ $t(titles[2]) }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, key) in rows" :key="key">
        <td class="discipline flexing">
          <app-discipline-icon
            :size="70"
            :discipline-id="row.disciplineId"
            :has-tooltip="false"
            class="discipline-icon"
          />
          <p
            class="text-36 discipline-name font-bold"
            :class="{
              'text-texts-standard-additional': $isWsm,
              'text-texts-standard-default': $isSsm,
            }"
          >
            {{ $translateDiscipline(row.disciplineId) }}
          </p>
        </td>
        <td
          class="text-36"
          :class="{
            'text-texts-standard-additional': $isWsm,
            'text-texts-standard-default font-bold': $isSsm,
          }"
        >
          {{ row.value1 }}
        </td>
        <td>
          <span
            v-if="'value2' in row"
            class="text-36 background"
            :class="{
              'text-texts-standard-important': $isWsm,
              'text-texts-standard-default font-bold': $isSsm,
            }"
          >
            {{ row.value2 === 0 ? '-' : row.value2 }}
          </span>
          <div v-if="row.rewards">
            <div v-if="row.rewards.length" class="flexing">
              <div v-for="(reward, i) in row.rewards" :key="i" class="flexing ml-2">
                <p
                  class="text-texts-standard-default text-36"
                  :class="{
                    'font-bold': $isWsm,
                  }"
                >
                  {{ reward.amount }}
                </p>
                <app-main-icon :icon-name="getRewardName(reward)" :icon-size="56" />
              </div>
            </div>
            <span
              v-else
              class="text-white text-36"
              :class="{
                'font-bold': $isSsm,
              }"
            >
              -
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { SeasonAttributesRow, SeasonReward } from '@/interfaces/Seasons'

export default defineComponent({
  name: 'SeasonsTable',
  props: {
    titles: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    rows: {
      type: Array as PropType<SeasonAttributesRow[]>,
      default: () => [],
    },
  },
  methods: {
    getRewardName(reward: SeasonReward): SeasonReward['name'] {
      if (reward.name === 'grand_prize') return 'gp-' + reward.rarity
      if (
        reward.type === 'attribute' ||
        reward.type === 'training_points' ||
        reward.type === 'cash'
      )
        return `${reward.name}_${reward.rarity}`

      return reward.name
    },
  },
})
</script>

<style lang="scss" scoped>
.table-wrapper {
  width: 100%;
  margin-top: 2rem;
  border-spacing: 0 0.5rem;
  border-collapse: separate;

  @if $isWsm {
    font-style: italic;
  }

  thead {
    height: 2.9rem;
    text-align: center;

    @if $isWsm {
      background-color: #09172a;
    }

    @if $isSsm {
      background-color: #292d30;
    }

    th {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      font-weight: 400;

      &:first-child {
        padding-left: 1rem;
        text-align: left;
      }
    }
  }

  tbody {
    text-align: left;

    tr {
      height: 6.875rem;

      td {
        @if $isWsm {
          background-color: #236d9b;
          border-top: solid 0.125rem #6c9ec4;
          border-bottom: solid 0.125rem #6c9ec4;
        }

        @if $isSsm {
          background-color: rgba(82, 84, 121, 0.7);
          border-top: solid 0.125rem #7477a4;
          border-bottom: solid 0.125rem #7477a4;
        }

        &:first-of-type {
          @if $isWsm {
            border-left: solid 0.125rem #6c9ec4;
          }

          @if $isSsm {
            border-left: solid 0.125rem #7477a4;
          }
        }

        &:last-of-type {
          @if $isWsm {
            border-right: solid 0.125rem #6c9ec4;
          }

          @if $isSsm {
            border-right: solid 0.125rem #7477a4;
          }
        }
      }

      &:nth-child(even) td {
        @if $isWsm {
          background-color: #417491;
          border-color: #5883a3;
        }

        @if $isSsm {
          background-color: rgba(114, 114, 176, 0.7);
          border-color: #7477a4;
        }
      }

      .discipline {
        height: inherit;

        &-icon {
          margin-left: 1rem;
          margin-right: auto;
        }

        &-name {
          width: 80%;
        }
      }

      td {
        text-align: center;

        &:first-child {
          text-align: left;
        }

        .background {
          padding: 0.5rem 3rem 0.5rem 3rem;

          @if $isWsm {
            background-image: linear-gradient(
              90deg,
              rgba(9, 23, 42, 0) 0%,
              rgba(9, 23, 42, 0.5) 30%,
              rgba(9, 23, 42, 0.6) 50%,
              rgba(9, 23, 42, 0.5) 70%,
              rgba(9, 23, 42, 0) 100%
            );
          }

          @if $isSsm {
            background-image: linear-gradient(
              to right,
              rgba(35, 42, 64, 0) 0%,
              rgba(35, 42, 64, 0.5) 30%,
              rgba(35, 42, 64, 0.6) 50%,
              rgba(35, 42, 64, 0.5) 70%,
              rgba(35, 42, 64, 0) 100%
            );
          }
        }
      }
    }
  }
}
</style>
