<template>
  <button
    :id="btnId"
    ref="btn"
    :data-cy="btnId"
    class="clickable-skew-element flex justify-center items-center multi btn"
    :class="[
      'btn-' + btnType,
      'btn-size-' + btnSize,
      addClass,
      buttonInteractionStateClass,
      { 'pointer-events-none': loading },
    ]"
    :style="[{ width: multiWidth ? multiWidth : '', height: multiHeight }]"
    @click="$emit('click')"
  >
    <div v-if="btnType === 'glowing'" class="glowing-overlay" />
    <div v-if="btnText" class="btn-text flexing px-2">
      <shrink-text
        :key="shrinkTextComponentKey"
        :text="btnText"
        :font-size="buttonFontSize"
        container-class-list="flexing pt-1"
        :text-class-list="[
          'uppercase',
          {
            'font-bold': $isWsm,
            'font-medium': $isSsm,
          },
        ]"
        :animated-overflow="true"
      />
    </div>
    <section
      v-else
      :data-cy="btnId + '-text'"
      class="btn-text font-bold uppercase center-text-vertically leading-7 pt-1 flex-col"
    >
      <slot name="leftPart" />
    </section>
    <div ref="btnCount" class="btn-count font-bold center-text-vertically">
      <div v-if="icon && !noIcon" :data-cy="btnId + '-icon'" :class="'btn-icon ' + icon" />
      <span v-if="count">{{ count }}</span>
      <slot v-else name="rightPart" />
      <app-main-icon v-if="btnType === 'credit' && !noIcon" :icon-size="48" :icon-name="'gems'" />
    </div>
    <div v-if="locked" class="btn-locked-overlay w-full h-full absolute top-0 left-0 flexing">
      <app-icon icon-name="lock-sm" class="btn-locked-overlay-icon revert-skew" />
    </div>
    <div
      v-if="isCoolDown && !loading"
      class="flexing w-full h-full"
      style="background: rgba(0, 0, 0, 0.3); transform: skewX(0deg)"
    >
      <span style="transform: skewX(8deg)">
        <app-timer
          :time="getCoolDown"
          :centered="true"
          :abc-testing="true"
          @countdown-action="clearCoolDown"
        />
      </span>
    </div>
    <div
      v-if="loading"
      class="absolute revert-skew loading-btn top-0 right-0 w-full h-full"
      style="transform: skewX(0deg)"
    >
      <component-loading height="100%" width="100%" :is-loading="true" />
    </div>
  </button>
</template>

<script lang="ts">
import { createRandomId, formatTime } from '@/helpers'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import ShrinkText from '@/components/ShrinkText.vue'

interface ComponentData {
  icon: string
  shrinkTextComponentKey: number
  rightPartObserver: MutationObserver
}

export default defineComponent({
  name: 'AppMultiButton',
  components: {
    ShrinkText,
  },
  props: {
    btnId: {
      type: String,
      default: createRandomId('button'),
    },
    count: {
      type: String,
      default: null,
    },
    btnText: {
      type: String,
      default: null,
    },
    btnType: {
      type: String,
      default: 'primary',
      validator(value: string): boolean {
        return [
          'primary',
          'secondary',
          'credit',
          'danger',
          'inactive',
          'confirm',
          'position',
          'glowing',
        ].includes(value)
      },
    },
    btnSize: {
      type: String,
      default: 'md',
      validator(value: string): boolean {
        return ['sm', 'md', 'xl'].includes(value)
      },
    },
    btnIcon: {
      type: String,
      default: '',
    },
    addClass: {
      type: String,
      default: '',
    },
    multiWidth: {
      type: String,
      default: '',
    },
    multiHeight: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isCoolDown: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  data(): ComponentData {
    return {
      icon: '',
      shrinkTextComponentKey: 0,
      rightPartObserver: null,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      getCoolDown: 'getCoolDown',
    }),
    buttonFontSize(): number {
      switch (this.btnSize) {
        case 'sm':
          return 36
        case 'md':
          return 36
        case 'xl':
          return 36
        default:
          return 36
      }
    },
    buttonInteractionStateClass(): string {
      if (this.disabled) {
        return !this.locked ? 'disabled pointer-events-none' : 'cursor-default'
      }
      return 'active'
    },
  },
  created(): void {
    if (this.btnType === 'credit') {
      this.icon = 'icon-credit'
    } else {
      this.icon = this.btnIcon
    }
  },
  mounted(): void {
    // *must have – init
    this.recalculateActiveRightSize()

    const rightPartObserver = new MutationObserver(this.dispatchRightPartChanges)
    rightPartObserver.observe(this.$refs.btnCount, {
      childList: true,
      subtree: true,
    })
    this.rightPartObserver = rightPartObserver
  },
  beforeUnmount(): void {
    this.rightPartObserver.disconnect()
  },
  methods: {
    formatTime,
    ...mapActions(useResponseTaskStore, {
      clearCoolDown: 'clearCoolDown',
    }),
    recalculateActiveRightSize(): void {
      this.$nextTick((): void => {
        const element = this.$refs.btnCount as HTMLElement
        const btn = this.$refs.btn as HTMLElement

        if (!element || !btn) return

        // Get a width including internal padding
        const computedStyle = window.getComputedStyle(element)
        const computedWidth = parseFloat(computedStyle.width)
        if (!isNaN(computedWidth)) {
          btn.style.setProperty('--active-right-size', computedWidth + 'px')
        }
      })
    },
    forceRerender(): void {
      // SHRINK-TEXT: Changing the key causes the component to be rerendered
      this.shrinkTextComponentKey += 1
    },
    dispatchRightPartChanges(): void {
      // *must have – multiplicator & dynamic updates
      this.recalculateActiveRightSize()
      // *must have – dynamic text updates
      this.forceRerender()
    },
  },
})
</script>

<style lang="scss" scoped>
button {
  &.btn {
    display: flex;
    flex-direction: row;
    transform: $skewX-value;
    outline: none;

    @if $isWsm {
      font-style: italic;
      box-shadow:
        inset 0.188rem 0 0 0 rgba(255, 255, 255, 0.4),
        inset -0.188rem 0 0 0 rgba(255, 255, 255, 0.4),
        inset 0 -0.125rem 0.063rem 0 rgba(255, 255, 255, 0.4),
        inset 0 0.25rem 0 0 rgba(255, 255, 255, 0.64);
    }

    .btn-icon {
      margin: 0 0.313rem;
    }

    & > div,
    section {
      transform: $skewX-revert-value;
    }

    section,
    .btn-count {
      pointer-events: none;
    }

    &-primary {
      @if $isWsm {
        color: theme('colors.texts.buttons.bright');
        background-image: linear-gradient(
          80deg,
          rgb(215, 237, 250) 0%,
          rgb(189, 225, 247) 49%,
          rgb(137, 187, 215) 100%
        );
      }

      @if $isSsm {
        color: theme('colors.texts.buttons.bright');
        background-image: linear-gradient(to right, #b9b9dd, #eeedf6);
        border: solid 0.188rem #f6f6f9;
      }

      &.inactive {
        &::after {
          content: '';
          width: 100%;
          height: 100%;
          background: rgba($color: #89bbd7, $alpha: 0.4);
          position: absolute;
          left: 0;
          top: 0;
        }

        &:active {
          transform: $skewX-value !important;
        }
      }
    }

    &-secondary {
      @if $isWsm {
        color: theme('colors.texts.buttons.bright');
        background-image: linear-gradient(
          80deg,
          rgb(255, 239, 132) 0%,
          rgb(251, 219, 78) 33%,
          rgb(246, 199, 23) 100%
        );
      }

      @if $isSsm {
        color: theme('colors.texts.buttons.bright');
        background-image: linear-gradient(to right, #ffaa3c, #ffe719);
        border: solid 0.188rem #ffdc96;
      }
    }

    &-credit {
      @if $isWsm {
        color: theme('colors.texts.buttons.darker');
        background-image: linear-gradient(
          80deg,
          rgb(199, 111, 224) 0%,
          rgb(159, 59, 210) 100%,
          rgb(159, 72, 172) 100%
        );
      }

      @if $isSsm {
        color: theme('colors.texts.buttons.darker');
        background-image: linear-gradient(to right, #861cd4, #cc5df9);
        border: solid 0.188rem #d47cfd;
      }
    }

    &-confirm {
      @if $isWsm {
        color: theme('colors.texts.buttons.darker');
        background-image: linear-gradient(80deg, rgb(73, 172, 46) 0%, rgb(22, 148, 16) 100%);
      }

      @if $isSsm {
        color: theme('colors.texts.buttons.bright');
        background-image: linear-gradient(to right, #01e3cd, #5fffc8);
        border: solid 0.188rem #81ffee;

        .btn-count {
          color: theme('colors.texts.buttons.darker');
        }
      }
    }

    &-danger {
      background-image: linear-gradient(
        80deg,
        rgb(159, 72, 172) 0%,
        rgb(255, 87, 62) 0%,
        rgb(234, 44, 20) 100%
      );
      color: theme('colors.texts.buttons.darker');
    }

    &-inactive {
      background-image: linear-gradient(
        80deg,
        rgba(71, 135, 180, 0.6) 0%,
        rgba(57, 107, 142, 0.6) 33%,
        rgba(43, 78, 103, 0.6) 100%
      );
      border-color: rgb(82, 142, 191);
      border-width: 0.125rem;
      box-shadow: none;
      color: theme('colors.texts.buttons.darkerInactive');
    }

    &-position {
      background-color: #0f3251;
      border-image-source: linear-gradient(to top, #fff284, #f6c717);
      border-image-slice: 1;
      border-width: 0.125rem;
      box-shadow:
        inset -7px 0px 21px 0 rgba(51, 123, 214, 0.19),
        inset -0.3px -3px 40px 0 rgba(6, 34, 55, 0.48);
    }

    &-glowing {
      position: relative;
      background-image: linear-gradient(15deg, #f7b724 5%, #eba543 20%, #f78d1f 55%);
      // left / bottom / right / top
      box-shadow:
        inset 0.188rem 0 0 0 rgba(249, 202, 36, 0.4),
        inset 0 -0.125rem 0 0 rgba(249, 202, 36, 0.4),
        inset -0.188rem 0 0 0 rgba(249, 202, 36, 0.4),
        inset 0 0.25rem 0 0 rgba(249, 202, 36, 0.64);
      color: #233441;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 0.188rem #ff7815;
        animation: shadow-glowing 1500ms infinite ease-out;
      }

      .glowing-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(15deg, #ffdc7d 5%, #fadb4e 20%, #feaa32 55%);
        // left / bottom / right / top
        box-shadow:
          inset 0.188rem 0 0 0 rgba(255, 220, 125, 0.4),
          inset 0 -0.125rem 0 0 rgba(255, 220, 125, 0.4),
          inset -0.188rem 0px 0 0 rgba(255, 220, 125, 0.4),
          inset 0 0.25rem 0 0 rgba(255, 220, 125, 0.64);
        opacity: 0;
        z-index: -1;
        transform: skewX(0deg);
        animation: inner-glowing 1500ms infinite ease-out;
      }
    }

    @keyframes shadow-glowing {
      0% {
        // box-shadow: 0 0 0.188rem #ff7815;
        box-shadow: 0 0 0.188rem #ffbc22;
      }

      50% {
        // box-shadow: 0 0 1.563rem #ff7815; // original
        box-shadow: 0 0 2.188rem #ffbc22;
      }

      100% {
        // box-shadow: 0 0 0.188rem #ff7815;
        box-shadow: 0 0 0.188rem #ffbc22;
      }
    }

    @keyframes inner-glowing {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }
  }
}

button {
  &.multi {
    position: relative;

    .btn-text {
      flex: 1;
      position: relative;
      overflow: hidden;
    }

    .btn-count {
      flex: 0 0 auto;
      position: relative;
      justify-content: center;
      align-items: center;
      padding: 0 1rem 0 1.5rem;
      width: auto;

      .center-text-vertically {
        height: 100%;
      }
    }

    &.btn-glowing,
    &.btn-iconized {
      .btn-count {
        padding: 0 1.5rem;
      }
    }

    .btn-locked-overlay {
      background: rgba(0, 0, 0, 0.6);
      transform: none;
      pointer-events: none;

      &-icon {
        left: 50%;
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: var(--active-right-size, 0);

      @if $isSsm {
        height: 100%;
        top: 0;
        right: 0;
      }

      @if $isWsm {
        height: 93%;
        top: 0.188rem;
        right: 0.188rem;
      }
    }

    &.btn-primary {
      &:before {
        content: '';

        @if $isWsm {
          background-color: #446c84;
        }

        @if $isSsm {
          background-color: #6c6c94;
        }
      }
    }

    &.btn-secondary {
      &:before {
        content: '';
        @if $isWsm {
          background-color: #887528;
        }

        @if $isSsm {
          background-color: #cb8a33;
        }
      }
    }

    &.btn-credit {
      &:before {
        content: '';
        @if $isWsm {
          background: #591488;
        }
        @if $isSsm {
          background: #6e09b2;
        }
      }
    }

    &.btn-confirm {
      &:before {
        content: '';
        @if $isWsm {
          background: #19600b;
        }
        @if $isSsm {
          background: #119780;
        }
      }
    }

    &.btn-position {
      &:before {
        content: '';
        background-image: linear-gradient(to top, #d29520, #ffe98e);
      }
    }

    &.btn-glowing {
      &:before {
        content: '';
        background: #f68209;
        height: calc(100% - 0.25rem - 0.125rem);
        top: 0.25rem;
        animation: sub-glowing 1500ms infinite ease-out;
      }
    }

    @keyframes sub-glowing {
      0% {
        background: #f68209;
      }

      50% {
        background: #ff9b25;
      }

      100% {
        background: #f68209;
      }
    }
  }

  .loading-btn {
    background: rgba($color: #254257, $alpha: 0.8);
  }
}
</style>
