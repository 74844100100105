<template>
  <div class="parameters-in-head flexing">
    <div v-for="(parameter, i) in headerParameters" :key="i" class="parameter-wrapper flexing">
      <app-main-icon :icon-size="56" :icon-name="parameter.name" class="parameter-icon" />
      <p class="text-texts-standard-default text-36 parameter-text">
        {{ parameter.value }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface HeaderParameter {
  name: string
  value: number | string
}

export default defineComponent({
  name: 'EquipmentPopupHeaderParameters',
  props: {
    premium: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      money: 'getMoney',
      gems: 'getGems',
      researchPoints: 'getResearchPoints',
    }),
    headerParameters(): HeaderParameter[] {
      return [
        {
          name: this.premium ? this.gems.parameter_name : this.money.parameter_name,
          value: this.$filters.$formatNumber(
            this.$filters.$abbrNumber(this.premium ? this.gems.value : this.money.value),
          ),
        },
        {
          name: this.researchPoints.parameter_name,
          value: this.$filters.$formatNumber(this.$filters.$abbrNumber(this.researchPoints.value)),
        },
      ]
    },
  },
})
</script>

<style lang="scss" scoped>
.parameters-in-head {
  position: absolute;
  top: 1rem;
  right: 9rem;
  width: 20rem;
  height: 3.5rem;

  .parameter-wrapper {
    width: 12.5rem;
    height: 2.563rem;
    position: relative;
    margin: 0 1rem;
    @if $isWsm {
      background-image: linear-gradient(to right, #010b1c 50%, transparent 100%);
    }
    @if $isSsm {
      background: #1f2336;
    }
    .parameter-icon {
      position: absolute;
      left: -2rem;
    }

    .parameter-text {
      width: 9rem;
      margin-left: 3.5rem;
      text-align: left;
    }
  }
}
</style>
