<template>
  <div
    :class="{
      'pass-bg': $isSsm,
      'active-pass-bg': showActivePassBg && isActive && pass === PassType.Event,
    }"
  >
    <app-button
      v-if="!isActive"
      :btn-type="[PassType.Champion, PassType.Master].includes(pass) ? 'credit' : 'primary'"
      :btn-text="$te(`${pass}pass`)"
      :btn-size="btnSize"
      :class="{ disabled: isDisabled }"
      :disabled="isDisabled"
      @click="$router.push({ name: $getWebView('EventsPasses'), query: { pass } })"
    />
    <div
      v-else
      class="flexing"
      :class="{
        'event-pass-active-text': pass === PassType.Event,
        'champion-pass-active-text relative': pass === PassType.Champion,
        'master-pass-active-text relative': pass === PassType.Master,
        'is-overview': isOverview,
      }"
    >
      <p
        class="flexing font-bold uppercase"
        :class="{
          'event-pass-active-text': pass === PassType.Event,
          'text-48': $isWsm && pass === PassType.Event,
          'text-42': $isSsm && pass === PassType.Event,
          'text-36 gradient-text-purple gradient-text-last-letter-cut-helper z-1':
            pass === PassType.Champion,
          'text-32 gradient-text-purple gradient-text-last-letter-cut-helper z-1':
            pass === PassType.Master,
        }"
      >
        {{ $replacePlaceholder($te('eventPassActive'), '{pass}', $te(`${pass}pass`)) }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { PassType } from '@/interfaces/events/Passes'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import type { PremiumPacksInterface } from '@/interfaces/premium/Offer'
import { mapActions, mapState } from 'pinia'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

interface ComponentData {
  PassType: typeof PassType
}

export default defineComponent({
  name: 'EventPassButon',
  props: {
    pass: {
      type: String as PropType<PassType>,
      required: true,
      validator: (value: PassType): boolean => Object.values(PassType).includes(value),
    },
    showActivePassBg: {
      type: Boolean,
      default: false,
    },
    isOverview: {
      type: Boolean,
      default: false,
    },
    btnSize: {
      type: String,
      default: 'md',
      validator(value: string) {
        // TODO: Importnut z AppButton.
        return ['xxsm', 'xsm', 'sm', 'md', 'xl'].includes(value)
      },
    },
  },
  data(): ComponentData {
    return {
      PassType,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      eventPass: 'getEventPass',
      championPass: 'getChampionPass',
      masterPass: 'getMasterPass',
    }),
    ...mapState(useEventInfoStore, {
      eventCollectionActive: 'getEventCollectionActive',
    }),
    isActive(): boolean {
      if (this.pass === PassType.Champion) {
        return this?.championPass?.[0]?.remaining_buys === 0
      } else if (this.pass === PassType.Master) {
        return this?.masterPass?.[0]?.remaining_buys === 0
      } else {
        // Event Pass je aktivny, ak mas zakupeny minimalne aspon 1 tier.
        return this?.eventPass?.some(
          ({ remaining_buys }: PremiumPacksInterface['packs'][0]): boolean => remaining_buys === 0,
        )
      }
    },
    isDisabled(): boolean {
      return this.eventCollectionActive && this.pass === PassType.Event
    },
  },
  async created(): Promise<void> {
    try {
      await this.loadEventOffers()
    } catch (error: unknown) {
      console.error(error)
    }
  },
  methods: {
    ...mapActions(usePremiumStore, {
      loadEventOffers: 'loadEventOffers',
    }),
  },
})
</script>

<style lang="scss" scoped>
@if $isSsm {
  .pass-bg {
    position: relative;
    z-index: 0;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 55.063rem;
      height: 7rem;
      @include background(url($path-events + 'shared/event-pass-active-bg.webp'), cover);
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }
}

.active-pass-bg {
  width: 100%;

  @if $isWsm {
    @include background(url($path-events + 'shared/event-pass-active-bg.webp'), cover);
  }

  @if $isSsm {
    position: relative;
    z-index: 0;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-image: linear-gradient(
        to right,
        transparent,
        rgba(184, 109, 2, 0.8),
        rgba(184, 109, 2, 1),
        rgba(184, 109, 2, 0.8),
        transparent
      );
      border-style: solid;
      border-width: 0.188rem;
      border-image-source: linear-gradient(
        to right,
        transparent,
        rgba(224, 158, 46, 1),
        transparent
      );
      border-image-slice: 1;
      z-index: -1;
    }
  }
}

.event-pass-active-text {
  background-image: linear-gradient(to top, #ffe08b, #fff5da);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  text-shadow: none;
}

.champion-pass-active-text {
  &::after {
    content: '';
    position: absolute;
    top: -0.5rem;
    left: -3.625rem;
    width: 43.813rem;
    height: 5.063rem;
    @include background(
      url('#{$path-events}shared/milestones/milestones-champion-pass-active-button-bg.avif'),
      contain
    );
  }

  p {
    padding-top: 0.213rem;
  }
}

.master-pass-active-text {
  &:not(.is-overview)::after {
    content: '';
    position: absolute;
    left: -$default-margin;
    width: 120%;
    height: 4.5rem;
    background-image: linear-gradient(
      90deg,
      transparent 0%,
      rgba(140, 0, 178, 0.4) 10%,
      rgba(140, 0, 178, 0.8) 50%,
      rgba(140, 0, 178, 0.4) 90%,
      transparent 100%
    );
    border-style: solid;
    border-width: 0.188rem;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      90deg,
      transparent 0%,
      rgba(207, 7, 249, 0.4) 10%,
      rgba(207, 7, 249, 0.8) 50%,
      rgba(207, 7, 249, 0.4) 90%,
      transparent 100%
    );
  }

  &.is-overview::after {
    content: '';
    position: absolute;
    width: 32.5rem;
    height: 5.063rem;
    border: 0.188rem solid;
    border-right: 0;
    border-image-slice: 1;

    @if $isWsm {
      background-image: linear-gradient(to right, rgba(140, 0, 178, 0.8) 75%, transparent 100%);
      border-image-source: linear-gradient(to right, rgba(207, 7, 249, 0.8) 75%, transparent 100%);
      transform: skew(-8deg);
    }

    @if $isSsm {
      background-image: linear-gradient(
        to right,
        transparent 0%,
        rgba(140, 0, 178, 0.4) 10%,
        rgba(140, 0, 178, 0.8) 50%,
        rgba(140, 0, 178, 0.4) 90%,
        transparent 100%
      );
      border-image-source: linear-gradient(
        to right,
        transparent 0%,
        rgba(207, 7, 249, 0.4) 10%,
        rgba(207, 7, 249, 0.8) 50%,
        rgba(207, 7, 249, 0.4) 90%,
        transparent 100%
      );
    }
  }
}
</style>
