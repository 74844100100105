import type { SpriteDataInterface } from '@/map-phaser-new/interfaces'
import { pathImages } from '@/globalVariables'
/**
 * This method is used to load spritesheet, which has ability to use frames - animations.
 * @param scene - instance of Phaser.Scene
 * @param loadArray - array of spritesheets
 * @returns A Promise that resolves when all loadings of sprites are complete.
 */

export const loadSpriteData = (
  scene: Phaser.Scene,
  loadArray: SpriteDataInterface[],
): Promise<void> => {
  return new Promise<void>((resolve) => {
    const promises: Promise<void>[] = []

    loadArray.forEach(({ name, path, settings }) => {
      const loadPromise = new Promise<void>((innerResolve) => {
        scene.load.spritesheet(name, pathImages + path, settings)
        innerResolve()
      })

      promises.push(loadPromise)
    })

    Promise.all(promises).then(() => {
      scene.load.start()
      resolve()
    })
  })
}
