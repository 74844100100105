<template>
  <section class="use-benefit-btn-wrapper">
    <button
      v-if="slotData"
      class="use-benefit-btn benefit-active clickable-skew-element relative flexing btn btn-size-md align-top mx-4"
      :class="[disabled ? 'disabled' : '', 'benefit-level-' + slotData.benefit_rarity]"
    >
      <div class="use-benefit-btn-icon relative">
        <app-main-icon :icon-size="80" :icon-name="'benefit-' + slotData.benefit_gdd_id" />
      </div>
      <div class="use-benefit-btn-amount" :class="{ live: benefitUsageLive }">
        <p
          class="font-bold uppercase text-36"
          :class="[
            isLiveDisable || isBaseDisable
              ? 'text-texts-standard-danger'
              : 'text-texts-standard-default',
          ]"
        >
          {{ slotData.amount }}
        </p>
      </div>
      <aside
        v-if="benefitUsageLive && !hideStartsInfo && !hideLiveInfo"
        class="use-benefit-btn-usage flexing leading-10 live"
        :class="{ 'disable-benefits': isLiveDisable }"
      >
        <p
          class="h-full revert-skew text-28 font-bold"
          :class="{
            'text-texts-standard-additional': $isWsm,
            'text-texts-standard-default': $isSsm,
          }"
        >
          -{{ benefitUsageLive }}
        </p>
      </aside>
      <aside
        v-if="!hideStartsInfo"
        class="use-benefit-btn-usage flexing leading-10"
        :class="{ 'disable-benefits': isBaseDisable }"
      >
        <p
          class="h-full revert-skew text-28 font-bold"
          :class="{
            'text-texts-standard-additional': $isWsm,
            'text-texts-standard-default': $isSsm,
          }"
        >
          -{{ benefitUsage }}
        </p>
      </aside>
      <aside class="control-icon-box absolute remove-benefit" @click.stop="removeBenefit()">
        <div class="clickable-element revert-skew control-icon control-icon-close" />
      </aside>
    </button>
    <div v-else class="relative">
      <div
        v-if="lockIcon"
        v-tippy="{
          content: $replacePlaceholder(
            $t('common.mainCareerTaskRequired'),
            '%s',
            getMechanicUnlock(MECHANIC_BENEFIT),
          ),
          placement: 'top',
          theme: 'WSM',
        }"
        class="lock-layout flexing h-full"
      >
        <app-icon icon-name="lock-sm" class="revert-skew" />
      </div>
      <button
        class="use-benefit-btn clickable-skew-element flex btn btn-size-md align-top justify-center items-center mx-4"
        :class="disabled ? 'disabled' : ''"
      >
        <div class="button-multiple-lines w-full">
          <p class="font-bold uppercase text-texts-standard-default text-36">
            {{ $t('common.useBenefit') }}
          </p>
          <p class="font-bold uppercase text-texts-standard-additional text-24">
            {{ $t('common.emptySlot') }}
          </p>
        </div>
      </button>
      <app-icon v-if="!lockIcon" icon-name="plus-gold" class="plus absolute" />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { ARENA, MECHANIC_BENEFIT, userBenefitsSlotsEndpoint } from '@/globalVariables'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'

import type { PropType } from 'vue'
import type { BenefitData, BenefitSlotData } from '@/interfaces/Benefits'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  MECHANIC_BENEFIT: typeof MECHANIC_BENEFIT
}

export default defineComponent({
  name: 'UseBenefitButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    lockIcon: {
      type: Boolean,
      default: false,
    },
    slotData: {
      type: Object as PropType<Nullable<BenefitSlotData>>,
      default: () => null,
    },
    benefitUsage: {
      type: Number,
      default: 1,
    },
    benefitUsageLive: {
      type: Number,
      default: 0,
    },
    slotId: {
      type: String,
      default: '',
    },
    hideStartsInfo: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: '',
    },
  },
  emits: ['reloadData'],
  data(): ComponentData {
    return {
      MECHANIC_BENEFIT,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      isGroupB: 'isGroupB',
    }),
    ...mapState(useTutorialStore, {
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useResponseTaskStore, {
      getMechanicUnlock: 'getMechanicUnlock',
    }),
    isLiveDisable(): boolean {
      return this.source === ARENA && Number(this.slotData.amount) < this.benefitUsageLive
    },
    isBaseDisable(): boolean {
      return Number(this.slotData.amount) < this.benefitUsage
    },
    hideLiveInfo(): boolean {
      return this.isGroupB && !this.isTutorial && this.source === ARENA
    },
  },
  created(): void {
    this.clearSlotsInEventBossFight()
  },
  methods: {
    ...mapActions(useResponseTaskStore, ['loadBenefitsSlots']),
    async removeBenefit(): Promise<void> {
      try {
        await this.$axios.put<{}, BenefitData>(userBenefitsSlotsEndpoint, {
          slots: [{ slot_id: this.slotId }],
        })
        this.$emit('reloadData')
      } catch (error: unknown) {
        console.error(error)
      }
      if (this.$route.name !== this.$getWebView('BenefitsBuildings')) {
        await this.loadBenefitsSlots()
      }
    },
    clearSlotsInEventBossFight(): void {
      if (this.slotData && this.source === 'event' && this.isBaseDisable) {
        this.removeBenefit()
        this.loadBenefitsSlots()
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '../../assets/styles/components/buttons.scss';
@import '@/assets/styles/views/benefits/useBenefitButton.scss';
@import '@/assets/styles/components/icons/control-icons.scss';

.lock-layout {
  background: rgba(11, 30, 53, 0.8);
  transform: $skewX-value;
  width: 25.575rem;
  border: 0.063rem solid #5a5f86;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 1rem;
}

.plus {
  top: -1.2rem;
  right: -0.5rem;
  z-index: 2;
}

.icon-lock-sm {
  &:active {
    transform: $skew-revert-value;
  }
}
</style>
