import type { ImageLoadData } from '@/map-phaser-new/interfaces'

export const clubsMapImageLoadData: () => ImageLoadData[] = () => [
  {
    name: 'clubsMap',
    compressed: true,
    data: {
      astc: {
        type: 'KTX',
        textureURL: 'map/compressed/club_map_astc12x12.ktx',
      },
      etc: {
        type: 'KTX',
        textureURL: 'map/compressed/club_map_etc2rgba.ktx',
      },
      img: {
        textureURL: 'map/club_map.avif?v2',
      },
      place: 'map/club_map.avif?v2',
    },
  },
  {
    name: 'club-overlay-cars',
    compressed: true,
    data: {
      astc: {
        type: 'KTX',
        textureURL: 'map/compressed/club-overlay-cars-astc12x12.ktx',
      },
      etc: {
        type: 'KTX',
        textureURL: 'map/compressed/club-overlay-cars-etc2RGBA.ktx',
      },
      img: {
        textureURL: 'map/club-overlay-cars.avif',
      },
      place: 'map/club-overlay-cars.avif',
    },
  },
  {
    name: 'club-overlay-ships',
    compressed: true,
    data: {
      astc: {
        type: 'KTX',
        textureURL: 'map/compressed/club-overlay-ships-astc12x12.ktx',
      },
      etc: {
        type: 'KTX',
        textureURL: 'map/compressed/club-overlay-ships-etc2RGBA.ktx',
      },
      img: {
        textureURL: 'map/club-overlay-ships.avif',
      },
      place: 'map/club-overlay-ships.avif',
    },
  },
]
