import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const clubCarsMiddleToLeftCheckpoints: CheckpointsObjectInterface = {
  key: 'clubCarsMiddleToLeftCheckpoints',
  data: [
    {
      x: 2025,
      y: 1170.5,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsMiddleToLeftAnimations1',
        },
      },
    },
    {
      x: 1908,
      y: 1271,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsMiddleToLeftAnimations2',
        },
      },
    },
    {
      x: 1717,
      y: 1340,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsMiddleToLeftAnimations3',
        },
      },
    },
    {
      x: 1401,
      y: 1348,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsMiddleToLeftAnimations4',
        },
      },
    },
    {
      x: 1265,
      y: 1199,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsMiddleToLeftAnimations5',
        },
      },
    },
    {
      x: 1161.5,
      y: 1136,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsMiddleToLeftAnimations6',
        },
      },
    },
    {
      x: 1050,
      y: 1103.5,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsMiddleToLeftAnimations7',
        },
      },
    },
    {
      x: 873.5,
      y: 1082,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsMiddleToLeftAnimations8',
        },
      },
    },
    {
      x: 1024,
      y: 852,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsMiddleToLeftAnimations9',
        },
      },
    },
    {
      x: 758.5,
      y: 655,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsMiddleToLeftAnimations10',
        },
      },
    },
  ],
}
