import { translate } from '@/plugins'

export const replaceGamePassPlaceholders = (text: string): string => {
  const replacements = {
    '{pass}': translate('gamePass.gamePass'),
    '{passelite}': translate('gamePass.gamePassElite'),
    '{passpro}': translate('gamePass.gamePassPro'),
    '{number}': 14,
    '{2to4}': 3,
    '{grandprize}': translate('bugReport.grandPrize'),
  }
  let str = text

  str = str.replace(/{\w+}/g, function (all: string): string {
    return replacements[all] || all
  })

  return str
}
