import {
  EVENT_CREATE_EVENT_BUILDING,
  eventInfo as EVENT_INFO_ENDPOINT,
  LAYOUT_VIEW_ROUTE_NAME,
} from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { initEventTranslations } from '@/plugins/translateEvent'
import { getEventCustomProps } from '@/helpers/event'
import { PassType } from '@/interfaces/events/Passes'
import type EventInfoConfigApiResponse from '@/interfaces/responses/events/EventInfoConfigApiResponse'
import { getRouteWebName } from '@/plugins'
import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router'
import type { Nullable } from '@/interfaces/utils'

const REDIRECT_ROUTE = { name: LAYOUT_VIEW_ROUTE_NAME }

let eventData: Nullable<EventInfoConfigApiResponse> = null
let validUntil = 0

// Ked sa odomkne event, musime tieto data invalidovat, lebo nam ostane v cache, ze event je stale zamknuty.
document.addEventListener(
  EVENT_CREATE_EVENT_BUILDING,
  (): void => {
    validUntil = 0
  },
  {
    passive: true,
  },
)

export const eventRouteGuardPromise = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): Promise<boolean | RouteLocationRaw> => {
  const redirect = from?.meta?.isPremiumRoute ? false : REDIRECT_ROUTE

  try {
    if (!eventData || validUntil < Date.now()) {
      eventData = await internalAxios.get<{}, EventInfoConfigApiResponse>(EVENT_INFO_ENDPOINT)
      validUntil =
        [
          eventData?.events?.[0]?.event_teaser_ends_in,
          eventData?.events?.[0]?.event_progressing_ends_in,
          eventData?.events?.[0]?.event_ends_in,
        ].find((seconds: number): boolean => !isNaN(seconds) && seconds > 0) *
          1e3 +
        Date.now()
    }

    initEventTranslations(eventData?.events?.[0]?.event_type)
    const isEventActive = (eventData?.events?.[0]?.event_ends_in ?? 0) > 0
    const isEventLocked = eventData?.events?.[0]?.event_locked ?? false
    const isTeaserActive = (eventData?.events?.[0]?.event_teaser_ends_in ?? 0) > 0
    const isEventCollectionActive =
      (eventData?.events?.[0]?.event_progressing_ends_in < 0 &&
        eventData?.events?.[0]?.event_ends_in > 0) ??
      false

    if (!isEventActive || isEventLocked) return redirect

    if (isTeaserActive && to.name !== getRouteWebName('EventsTeaser')) return redirect

    if (
      isEventCollectionActive &&
      (to.name === getRouteWebName('EventsTeaser') ||
        to.name === getRouteWebName('TasksEvent') ||
        to.name === getRouteWebName('PremiumEventOffers') ||
        (to.name === getRouteWebName('PremiumEventPasses') && to.query?.pass === PassType.Event))
    )
      return redirect

    if (['EventsPasses', 'PremiumEventPasses'].map(getRouteWebName).includes(to.name.toString())) {
      const eventCustomProps = getEventCustomProps(eventData?.events?.[0]?.event_type)
      if (!to.query?.pass) {
        return {
          name: to.name,
          query: {
            ...to.query,
            pass: eventCustomProps.routes[to.meta.isPremiumRoute ? 'premium' : 'passes'][
              isEventCollectionActive ? 'collection.query.pass' : 'progress.query.pass'
            ],
          },
        }
      }

      if (!Object.values(eventCustomProps.passes).includes(to.query?.pass as PassType))
        return redirect
    }

    return true
  } catch (error: unknown) {
    console.error(error)
    return false
  }
}
