import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const helicopterLeftToRightCheckpoints: CheckpointsObjectInterface = {
  key: 'helicopterLeftToRightCheckpoints',
  data: [
    {
      x: 947,
      y: 1091,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'helicopterLeftToRightAnimations1',
        },
      },
    },
    {
      x: 1675,
      y: 964,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'helicopterLeftToRightAnimations2',
        },
      },
    },
    {
      x: 2882,
      y: 872,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'helicopterLeftToRightAnimations3',
        },
      },
    },
    {
      x: 3563,
      y: 479,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'helicopterLeftToRightAnimations4',
        },
      },
    },
    {
      x: 3753,
      y: 424,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'helicopterLeftToRightAnimations5',
        },
      },
    },
  ],
}
