import { eventInfo, MECHANIC_EVENT, MECHANIC_DISCIPLINE_EVENT } from '@/globalVariables'
import { getEventAssetsDirectory, getEventCustomProps } from '@/helpers/event'
import { EventType } from '@/interfaces/events/EventInfo'
import type EventInfoConfigApiResponse from '@/interfaces/responses/events/EventInfoConfigApiResponse'
import { initEventTranslations } from '@/plugins/translateEvent'
import { defineStore } from 'pinia'
import { ApiService } from '@/services/ApiService'

export interface EventInfoState {
  config: EventInfoConfigApiResponse | null
}

export const useEventInfoStore = defineStore('eventInfo', {
  state: (): EventInfoState => ({
    config: null,
  }),
  getters: {
    getPrimaryEvent(): EventInfoState['config']['events'][0] | null {
      return this.config?.events?.[0] ?? null
    },
    getEventName(): EventInfoState['config']['events'][0]['event_name'] | null {
      return this.getPrimaryEvent?.event_name ?? null
    },
    getEventType(): EventInfoState['config']['events'][0]['event_type'] | null {
      return this.getPrimaryEvent?.event_type ?? null
    },
    getEventDisciplineId(): EventInfoState['config']['events'][0]['games_disciplines_id'] | null {
      return this.getPrimaryEvent?.games_disciplines_id ?? null
    },
    getEventDisciplineNew(): EventInfoState['config']['events'][0]['new_discipline_event'] | null {
      return this.getPrimaryEvent?.new_discipline_event ?? null
    },
    getEventDisciplineTheme():
      | EventInfoState['config']['events'][0]['discipline_event_theme']
      | null {
      return this.getPrimaryEvent?.discipline_event_theme ?? null
    },
    getEventMechanics(): EventInfoState['config']['events'][0]['event_mechanics'] {
      return this.getPrimaryEvent?.event_mechanics ?? []
    },
    getEventAssetsDirectory(): ReturnType<typeof getEventAssetsDirectory> {
      return getEventAssetsDirectory(this.getEventType)
    },
    getEventCustomProps(): ReturnType<typeof getEventCustomProps> {
      return getEventCustomProps(this.getEventType)
    },
    getEventState(): EventInfoState['config']['events'][0]['event_state'] | null {
      return this.getPrimaryEvent?.event_state ?? null
    },
    getEventTeaserStart(): Date | null {
      return this.getPrimaryEvent ? new Date(this.getPrimaryEvent?.event_teaser_start) : null
    },
    getEventTeaserEnd(): Date | null {
      return this.getPrimaryEvent ? new Date(this.getPrimaryEvent?.event_progressing_start) : null
    },
    getEventTeaserActive(): boolean | null {
      return this.getPrimaryEvent ? this.getPrimaryEvent?.event_teaser_ends_in > 0 : null
    },
    getEventStart(): Date | null {
      return this.getPrimaryEvent ? new Date(this.getPrimaryEvent?.event_progressing_start) : null
    },
    getEventEnd(): Date | null {
      return this.getPrimaryEvent ? new Date(this.getPrimaryEvent?.event_progressing_end) : null
    },
    getEventActive(): boolean | null {
      return this.getPrimaryEvent
        ? this.getPrimaryEvent?.event_teaser_ends_in < 0 &&
            this.getPrimaryEvent?.event_progressing_ends_in > 0
        : null
    },
    getEventCollectionStart(): Date | null {
      return this.getPrimaryEvent ? new Date(this.getPrimaryEvent?.event_progressing_end) : null
    },
    getEventCollectionEnd(): Date | null {
      return this.getPrimaryEvent ? new Date(this.getPrimaryEvent?.event_end) : null
    },
    getEventCollectionActive(): boolean | null {
      return this.getPrimaryEvent
        ? this.getPrimaryEvent?.event_progressing_ends_in < 0 &&
            this.getPrimaryEvent?.event_ends_in > 0
        : null
    },
    getEventLeftBoxTimer():
      | EventInfoState['config']['events'][0]['event_teaser_ends_in']
      | EventInfoState['config']['events'][0]['event_progressing_ends_in']
      | EventInfoState['config']['events'][0]['event_ends_in']
      | null {
      return this.getPrimaryEvent
        ? [
            this.getPrimaryEvent?.event_teaser_ends_in,
            this.getPrimaryEvent?.event_progressing_ends_in,
            this.getPrimaryEvent?.event_ends_in,
          ].find((seconds: number): boolean => !isNaN(seconds) && seconds > 0)
        : null
    },
    // Hrac nema splnenu kariernu ulohu 9 (alebo 5 v pripade disciplinoveho eventu).
    getEventLocked(): EventInfoState['config']['events'][0]['event_locked'] | null {
      return !!this.getPrimaryEvent?.event_locked || null
    },
    getEventMechanicByType(): string {
      return this.getEventType === EventType.DisciplineEvent
        ? MECHANIC_DISCIPLINE_EVENT
        : MECHANIC_EVENT
    },
  },
  actions: {
    async loadEvent(force: boolean = false): Promise<void> {
      if (this?.config !== null && !force) return

      try {
        const data = await ApiService.get<EventInfoConfigApiResponse>(eventInfo, { force })
        initEventTranslations(data?.events?.[0]?.event_type)
        this.config = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
