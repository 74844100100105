import SoundsSprite from '@/assets/sound/sfx_audio.json'
import { pathImages } from '@/globalVariables'
import { Howl } from 'howler'

const soundSrc = `${pathImages}sound/sfx_audio-v3.mp3?v2`
const musicSrc = `${pathImages}sound/background_music.mp3`
const eventShipSrc = `${pathImages}events/sound/event-ship-sound.mp3`

const eventShipSoundMaxVolume = 0.25

const sfxSoundOptions: HowlOptions = {
  src: [soundSrc],
  // @ts-ignore
  sprite: SoundsSprite[import.meta.env.VITE_GAME],
}

export const sfxSound: Howler = new Howl(sfxSoundOptions)

export const playSound = (sound: string): void => {
  if (!sfxSound) return
  sfxSound.play(sound)
}

const musicThemeOptions: HowlOptions = {
  html5: true,
  src: musicSrc,
  loop: true,
  volume: 0.8,
}

export const musicTheme: Howler = new Howl(musicThemeOptions)

const eventShipSoundOptions: HowlOptions = {
  html5: true,
  src: eventShipSrc,
  volume: eventShipSoundMaxVolume,
}

export const eventShipSound: Howler = new Howl(eventShipSoundOptions)

sfxSound.on('volume', (): void => {
  if (eventShipSound) {
    eventShipSound.volume(!sfxSound.volume() ? 0 : eventShipSoundMaxVolume)
  }
})

// https://github.com/goldfire/howler.js
// https://github.com/tonistiigi/audiosprite

// https://medium.com/game-development-stuff/how-to-create-audiosprites-to-use-with-howler-js-beed5d006ac1
// https://www.wikihow.com/Install-FFmpeg-on-Windows

// zdrojove subory: /adresar-hry/sound/source_sounds
// command na vytvorenie audiospritu: audiosprite --output nazov_vystupeno_suboru -f howler2 --export mp3 adresar_so_zvukmi/*.mp3

// konvertovat na 48kbps (https://www.konwerter.net/en/audio/)
