<template>
  <div
    id="career-box"
    :class="[
      isTutorial ? 'z-0' : 'z-1',
      'career-box bordered-lg ' +
        (quest.isDone ? 'box-border-2' : quest.isActive ? 'box-border-3' : 'box-border-1'),
    ]"
    data-cy="career-box"
  >
    <header
      v-if="!quest.isFinalQuest"
      :class="[
        { 'header-active': quest.isActive },
        { 'header-completed': quest.isDone },
        { 'header-locked': quest.isLocked },
      ]"
    >
      <div
        v-if="!disciplineId"
        class="career-box-icon"
        :class="{
          'locked-opacity': quest.isLocked,
          'icon-career-light-90': $isWsm,
          'icon-career-gold-90': $isSsm,
        }"
      />
      <div
        v-else
        class="career-box-icon"
        :class="[
          { 'locked-opacity': quest.isLocked },
          'icon-discipline-' + disciplineId + '-light-90',
        ]"
      />
      <div class="career-box-titles" :class="{ 'locked-opacity': quest.isLocked }">
        <p class="text-texts-standard-default text-40 font-bold uppercase">
          {{ !disciplineId ? $t('career.mainCareer') : $translateDiscipline(disciplineId) }}
        </p>
        <p class="text-texts-standard-important text-40 uppercase">
          {{
            (quest.parameters.id != null
              ? $replacePlaceholder($t('common.taskNumber'), '%s', quest.parameters.order)
              : '') + (quest.parameters?.boss_fight ? ' - ' + $t('career.internationalCup') : '')
          }}
        </p>
      </div>
      <div class="career-box-task" :class="{ 'flexing more-width': quest.isLocked }">
        <p
          class="text-36 uppercase text-texts-standard-default font-bold"
          :class="[
            { 'text-texts-standard-upgrade': quest.isDone },
            { 'text-texts-standard-important': quest.isActive },
            { 'mr-5': quest.isLocked },
          ]"
        >
          {{ selectTaskStateText }}
        </p>
        <div v-if="quest.isLocked" class="icon-lock-sm career-box-task-locked-icon" />
      </div>
    </header>
    <main :class="{ completed: quest.isFinalQuest }">
      <career-task-normal
        v-if="!quest.parameters?.boss_fight && !quest.isFinalQuest"
        :quest="quest"
        :discipline-id="disciplineId"
      />

      <career-task-boss
        v-if="quest.parameters?.boss_fight"
        :quest="quest"
        :career-id="careerId"
        :unlock-item="unlockItem"
      />

      <career-task-final v-if="quest.isFinalQuest" />
    </main>
    <footer
      v-if="!quest.isFinalQuest"
      class="flex"
      :class="[
        {
          'justify-between': isClaimRewardsButtonVisible,
          'footer-locked': quest.isLocked,
          'boss-claimed': quest.parameters.boss_fight && quest.stats.rewardsClaimed === 3,
        },
      ]"
    >
      <div
        v-if="!quest.parameters?.boss_fight"
        class="career-box-rewards flex justify-start items-center"
        :class="{ 'locked-opacity': quest.isLocked }"
      >
        <p class="text-texts-standard-important text-34 mr-2">{{ $t('common.rewards') }}:</p>
        <rewards
          reward-id="career-box-rewards"
          :reward-data="formatedData || []"
          :icon-size="48"
          :custom-font-size="36"
          :has-unlocks="quest.hasUnlocks"
          :unlock-id="quest.parameters.order"
          :career-id="careerId"
          :unlock-item="unlockItem"
          :is-video-ad-available="showVideoAds"
          :is-video-reward-earned="showVideoAdsEarnedState"
          :is-font-bold="false"
          modifier-tag="career"
          @watches="watchAds"
          @rewarded-watch="rewardedVideoEarned"
        />
        <video-ad-web v-show="isWebVideo" />
      </div>
      <div
        v-if="quest.parameters?.boss_fight && !quest.isDone"
        class="career-box-rewards flex justify-between items-center"
        :class="quest.parameters?.boss_fight && 'career-box-reward-boss'"
      >
        <span>
          <p class="text-texts-standard-important text-34 text-left">
            {{ getTaskText }}
          </p>
          <p
            v-if="!quest.parameters.boss_fight_type_rounds"
            class="text-texts-standard-additional text-30 text-left"
          >
            {{ getTaskTextInfo }}
          </p>
        </span>
        <app-button
          v-if="showAdminSkipButtons && quest.parameters?.boss_fight"
          btn-type="confirm"
          :btn-text="ADMIN_FORWARD"
          btn-size="md"
          @click="forward"
        />
        <app-button
          v-if="!isClaimRewardsButtonVisible"
          class="mr-8"
          btn-type="secondary"
          :btn-text="$t('button.start')"
          btn-size="md"
          :disabled="quest.isLocked"
          @click="bossFightStart"
        />
      </div>
      <app-button
        v-if="
          showAdminSkipButtons &&
          quest.isActive &&
          quest.stats.currentValue < quest.parameters.target_value
        "
        btn-type="confirm"
        :btn-text="ADMIN_FORWARD"
        btn-size="md"
        @click="forward"
      />
      <app-button
        v-if="quest.isActive && !quest.parameters?.boss_fight && !quest.isDone"
        btn-type="confirm"
        :btn-text="$t('button.start')"
        btn-size="md"
        class="ml-5 mr-5"
        @click="goTo()"
      />
      <div v-if="isClaimRewardsButtonVisible" class="career-box-button">
        <app-button
          btn-type="confirm"
          :btn-text="$t('common.takeRewards')"
          btn-size="md"
          :locked="quest.stats.currentValue < quest.parameters.target_value"
          :disabled="quest.stats.currentValue < quest.parameters.target_value"
          @click="claimReward"
        />
        <app-notification v-if="isNotification(quest)" :count="1" class="notification-rewards" />
      </div>
      <aside
        v-else-if="
          quest.parameters.boss_fight
            ? quest.stats.rewardsClaimed === 3
            : quest.stats.rewardsClaimed === 1
        "
        class="flexing reward-claimed"
      >
        <p class="text-texts-standard-upgrade text-36 uppercase font-bold pr-4">
          {{ $t('common.rewardsClaimed') }}
        </p>
        <div class="icon icon-done-md" />
      </aside>
    </footer>
  </div>

  <pre-boss-fight-main
    v-if="showBossFightPopup"
    :quest-id="quest.parameters.id"
    :discipline-id="disciplineId"
    :best-score="activeDiscipline?.mechanicStats?.bestScore"
    :task-number="quest.parameters.order"
    @close="showBossFightPopup = false"
  />
</template>

<script lang="ts">
import CareerTaskBoss from '@/components/Career/CareerTaskBoss.vue'
import CareerTaskFinal from '@/components/Career/CareerTaskFinal.vue'
import CareerTaskNormal from '@/components/Career/CareerTaskNormal.vue'
import PreBossFightMain from '@/components/Career/PreBossFight/PreBossFightMain.vue'
import VideoAdWeb from '@/components/Premium/Ads/VideoAdWeb.vue'
import Rewards from '@/components/Rewards.vue'
import {
  ADMIN_FORWARD,
  ARENA,
  EXPERIENCE,
  claimRewards,
  metaPremiumAdsClaimEndpoint,
  redirectViews,
} from '@/globalVariables'
import { formatRewards, playSound, sendToFlutter } from '@/helpers'
import { useAdStore } from '@/store/pinia/adStore'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { useGamePassStore } from '@/store/pinia/gamePassStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { MappedReward } from '@/helpers/formatRewards'
import type { CareerQuest, QuestReward } from '@/interfaces/Quest'
import type { NotificationCareerQuest } from '@/interfaces/global/Notification'
import type { CareerUnlockItem } from '@/interfaces/CareerUnlockItem'
import type Reward from '@/interfaces/Reward'
import type { PremiumVideoAdsClaimApiResponse } from '@/interfaces/responses/premium/PremiumVideoAdsClaimApiResponse'
import { VideoAdState } from '@/enums/VideoAdStates'

interface ComponentData {
  ADMIN_FORWARD: typeof ADMIN_FORWARD
  claimRewards: typeof claimRewards
  taskNumber: number
  showBossFightPopup: boolean
  isVideoRewardEarned: boolean
  adCategory: string
  mobileAdPlayed: boolean
}

export default defineComponent({
  name: 'CareerBox',
  components: {
    CareerTaskNormal,
    CareerTaskBoss,
    CareerTaskFinal,
    Rewards,
    PreBossFightMain,
    VideoAdWeb,
  },
  props: {
    quest: {
      type: Object as PropType<Nullable<CareerQuest>>,
      default: () => null,
    },
    disciplineId: {
      type: Number,
      default: 0,
    },
    careerId: {
      type: String,
      default: '',
    },
    unlockItem: {
      type: Object as PropType<Nullable<CareerUnlockItem>>,
      default: () => null,
    },
  },
  emits: ['loadDetail'],
  data(): ComponentData {
    return {
      ADMIN_FORWARD,
      claimRewards,
      taskNumber: 0,
      showBossFightPopup: false,
      isVideoRewardEarned: false,
      adCategory: '',
      mobileAdPlayed: false,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      isWebVideo: 'getWebVideoPlaying',
    }),
    ...mapState(useUserStore, {
      showAdminSkipButtons: 'showAdminSkipButtons',
    }),
    ...mapState(useTutorialStore, {
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useResponseTaskStore, {
      questCompleted: 'getQuestCompleted',
      notifications: 'getNotifications',
      getMechanicUnlock: 'getMechanicUnlock',
    }),
    ...mapState(useEventInfoStore, {
      getPrimaryEvent: 'getPrimaryEvent',
      eventMechanicByType: 'getEventMechanicByType',
    }),
    ...mapState(useDisciplineStore, {
      activeDiscipline: 'getSpecificDiscipline',
      firstUnlockedDisciplineId: 'getFirstUnlockedDisciplineId',
    }),
    ...mapState(useGamePassStore, ['isNoVideoAds']),
    selectTaskStateText(): string {
      if (this.quest.isDone) return this.$t('career.questDone')
      if (this.quest.isActive) return this.$t('career.activeQuest')
      if (this.quest.isLocked) return this.$t('career.questLocked')

      return this.$t('career.availableQuest')
    },
    isClaimRewardsButtonVisible(): boolean {
      if (this.quest.parameters.boss_fight)
        return (
          this.quest.stats.rewardsClaimed !== 3 &&
          this.quest.stats.bossFightTreshold &&
          this.quest.stats.rewardsClaimed < this.quest.stats.currentValue
        )
      return this.quest.isDone && !this.quest.stats.rewardsClaimed
    },
    formatedData(): MappedReward[] {
      return formatRewards(this.quest?.rewards as Reward[], 'name', 'amount')
    },
    formatedRewards(): { parameter: string; value: number }[] {
      return (
        this.quest?.rewards?.map((val: QuestReward): { parameter: string; value: number } => {
          let consumed: string
          switch (val.name) {
            case 'wildCard':
              consumed = 'wild_card'
              break
            case 'energyDrink':
              consumed = 'energy_drink'
              break
            case 'grandPrize':
              consumed = 'gp-' + val.rarity
              break
            default:
              consumed = val.name
              break
          }
          return {
            parameter: consumed,
            value: val.amount,
          }
        }) ?? []
      )
    },
    getTaskText(): string {
      if (this.quest.parameters.boss_fight_type_rounds) {
        const stage = this.quest.bossFightRoundUnlocked
        if (!stage) return ''

        if (stage === 1) return this.$t('career.internationalCupSemi')
        if (stage === 2) return this.$t('career.internationalCupFinal')
        if (stage === 3) return this.$t('career.internationalCupWin')
      }
      if (this.quest.stats.currentValue === 2) return this.$t('career.internationalCupWin')
      return this.$replacePlaceholder(
        this.$t('career.internationalCupPlaceIn'),
        '%s',
        this.quest.parameters?.target_value[this.quest.stats?.bossFightTreshold + 1],
      )
    },
    getTaskTextInfo(): string {
      if (this.quest.stats.currentValue < 1) return this.$t('career.needStarToUnlock')
      if (this.quest.stats.currentValue >= 3) return this.$t('career.internationalCupCompleted')
      return this.$t('career.youUnlockedRepeatableQuest')
    },
    showVideoAds(): boolean {
      return this.quest.isActive && this.quest.isDone && !this.quest.stats.adSeen
    },
    showVideoAdsEarnedState(): boolean {
      return this.isVideoRewardEarned || !!this.quest.stats.adSeen
    },
  },
  created(): void {
    this.taskNumber = Number(this.$route.params.task)
  },
  mounted(): void {
    window.rewardedVideoEarned = this.rewardedVideoEarned
    window.failedToLoadVideoAds = this.rewardedVideoFailed
    window.rewardedVideoEnd = this.rewardedVideoEnd
  },
  methods: {
    ...mapActions(useAdStore, ['setAdBlockState']),
    ...mapActions(usePremiumStore, {
      setVideoPlay: 'setVideoPlay',
    }),
    ...mapActions(useUserStore, {
      fastForwardCareerQuest: 'fastForwardCareerQuest',
      loadUserData: 'loadUserData',
    }),

    ...mapActions(useEventInfoStore, ['loadEvent']),
    ...mapActions(usePhaserGameIntegrationStore, ['loadEventBuilding']),
    ...mapActions(useDisciplineStore, {
      loadSpecificDiscipline: 'loadSpecificDiscipline',
      loadDisciplines: 'loadDisciplines',
    }),
    async claimReward(): Promise<void> {
      if (!this.quest?.parameters.id) return
      try {
        await this.$axios.post<{}, null>(claimRewards, {
          quest_id: this.quest?.parameters.id,
        })
        // aby po splneni prvej ulohy nam reloadlo user data kvoli tomu ze chceme aby nam pribudla moznost prepojenia konta pre mobily aj bez refreshu
        if (
          this.quest?.parameters.id === 1 ||
          this.questCompleted?.rewards.some(
            (reward: Reward): boolean => reward.parameter === EXPERIENCE,
          ) ||
          this.quest?.hasUnlocks
        ) {
          this.loadUserData()
        }

        if (
          this.getPrimaryEvent &&
          this.quest?.parameters?.id === this.getMechanicUnlock(this.eventMechanicByType)
        ) {
          await this.loadEvent(true)
          this.loadEventBuilding()
          this.loadDisciplines()
        }
        this.$emit('loadDetail')
        playSound('career_reward')
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async forward(): Promise<void> {
      await this.fastForwardCareerQuest(this.quest?.parameters.id)
      this.$emit('loadDetail')
    },
    bossFightStart(): void {
      if (this.quest.stats.currentValue >= 3) return

      this.loadSpecificDiscipline({
        disciplineId: this.disciplineId,
        type: ARENA,
      })
      this.showBossFightPopup = true
    },
    goTo(): void {
      const targetDiscipline = this.disciplineId
      const noIdPages = ['LayoutView', 'GrandPrizeWarehouse']
      let paramId = ''
      if (noIdPages.includes(redirectViews[this.quest.parameters.target_view])) {
        paramId = undefined
      } else {
        paramId = !targetDiscipline
          ? this.firstUnlockedDisciplineId.toString()
          : targetDiscipline.toString()
      }

      this.$router.push({
        name: this.$getWebView(redirectViews[this.quest.parameters.target_view]),
        params: paramId
          ? {
              id: paramId,
            }
          : null,
      })
    },
    isNotification(quest: CareerQuest): boolean {
      const mainBranch = this.notifications.career_quests.filter(
        (notification: NotificationCareerQuest): boolean =>
          Number(notification.id) === quest.parameters.id && notification.discipline == null,
      )

      const disciplineBranch = this.notifications.career_quests.filter(
        (notification: NotificationCareerQuest): boolean =>
          Number(notification.id) === quest.parameters.id &&
          notification.discipline === this.disciplineId,
      )

      return this.disciplineId === 0 ? mainBranch.length > 0 : disciplineBranch.length > 0
    },
    async watchAds(adType: string): Promise<void> {
      this.adCategory = adType
      try {
        await this.$axios.put<{}, PremiumVideoAdsClaimApiResponse>(metaPremiumAdsClaimEndpoint, {
          type: adType,
          state: VideoAdState.Started,
        })

        if (!this.isNoVideoAds && this.$isMobile()) {
          sendToFlutter('{\r\n "event":"playRewarded"\r\n}')
        }
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async rewardedVideoEarned(): Promise<void> {
      try {
        await this.$axios.put<{}, PremiumVideoAdsClaimApiResponse>(metaPremiumAdsClaimEndpoint, {
          type: this.adCategory,
          state: VideoAdState.Finished,
          quest_id: this.quest.parameters.id,
        })

        this.isVideoRewardEarned = true

        if (this.$isMobile()) this.mobileAdPlayed = true
        this.getAds()
        this.setVideoPlay(false)
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async getAds(): Promise<void> {
      if (this.$isMobile()) this.mobileAdPlayed = false
    },
    rewardedVideoFailed(): void {
      this.setAdBlockState(true)
      this.setVideoPlay(false)
    },
    rewardedVideoEnd(): void {
      this.setVideoPlay(false)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';
@import '@/assets/styles/components/icons/career-icons.scss';

.career-box {
  // width: 93.313rem;
  width: 100%;
  //  height: 38.438rem;
  background: url($path-career + 'components/BG-CONTENT-BOX.avif') center no-repeat;
  background-size: 100% 100%;
  position: relative;

  header,
  footer {
    width: 100%;
    height: 6.438rem;
    display: flex;
    align-items: center;
  }

  header {
    .career-box-icon {
      width: 5.625rem;
      height: 5.625rem;
      margin: 0 0.4rem;
    }

    .career-box-titles {
      width: 75%;
      display: flex;
      align-items: center;
      justify-content: left;
      flex-direction: column;
      text-align: left;
      line-height: 2.75rem;

      p {
        width: 100%;
        margin-left: 0.5rem;
      }
    }

    .career-box-task {
      width: 25%;
      margin: 0 auto;

      &-locked-icon {
        background-size: 100% 100%;
      }
    }

    .more-width {
      width: 30%;
    }
  }

  main {
    height: 32rem;

    &.completed {
      height: auto;
    }
  }

  .header-active {
    @if $isWsm {
      background: linear-gradient(160deg, rgba(22, 40, 58, 1) 0%, rgba(100, 90, 43, 1) 100%);
    }

    @if $isSsm {
      background: linear-gradient(160deg, #273249 0%, rgba(254, 185, 66, 0.5) 100%);
    }
  }

  .header-completed {
    @if $isWsm {
      background: linear-gradient(160deg, rgba(20, 43, 66, 1) 0%, rgba(18, 95, 37, 1) 100%);
    }
    @if $isSsm {
      background: linear-gradient(160deg, #273249 0%, rgba(14, 231, 204, 0.5) 100%);
    }
  }

  .header-locked {
    @if $isWsm {
      background: linear-gradient(160deg, rgba(23, 41, 64, 1) 0%, rgba(89, 99, 112, 1) 100%);
    }
    @if $isSsm {
      background: linear-gradient(160deg, #273249 0%, rgba(161, 161, 161, 0.5) 100%);
    }
  }

  footer {
    position: absolute;
    bottom: 0;
    @if $isWsm {
      background: rgba(11, 44, 79, 0.85);
      box-shadow: inset 0 0.125rem 0.438rem 0 rgba(0, 0, 0, 0.42);
    }
    @if $isSsm {
      background: #273249;
      &.boss-claimed {
        background: linear-gradient(to right, #273249 0%, rgba(14, 231, 204, 0.4) 100%);
      }
    }
    .career-box-rewards {
      position: relative;
      flex: 1;
      margin-left: 1.25rem;

      .single-reward {
        margin-left: 3.188rem;
      }
    }

    .career-box-reward-boss {
      width: 100% !important;
    }

    .career-box-button {
      margin: 0 1rem;
      margin-left: auto;
    }

    .reward-claimed {
      height: 100%;
      margin-right: 1rem;
      margin-left: auto;
    }
  }

  .footer-locked {
    background: rgba(0, 0, 0, 0.42);
  }

  .locked-opacity {
    opacity: 0.6;
  }

  .notification-rewards {
    top: 0;
    right: 0.5rem;
  }
}
</style>
