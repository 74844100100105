import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const flyingHelicopterAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'flying_helicopter_turn_left',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '20',
      delay: 0,
      duration: 500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GeneratedFrameNames,
      data: [
        { key: 'animations', frame: '20' },
        { key: 'animations', frame: '21' },
        { key: 'animations', frame: '22' },
      ],
    },
  },
]
