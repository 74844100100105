import {
  DAILY_LEAGUE_REMATCH,
  ENERGY_DRINK,
  ENERGY_DRINK_SHOP_ITEM_NAME,
  WILD_CARD,
  WILD_CARD_SHOP_ITEM_NAME,
  rewardsEndpoint,
  shopEndPoint,
  shopItemStatsEndpoint,
  shopItemsEndpoint,
} from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import type {
  AvailableOffer,
  ConsumableRewards,
  ShopItemStat,
  PremiumShopItem,
  ItemStat,
  Item,
} from '@/interfaces/responses/shopConsumables/ShopConsumablesApiResponse'
import { defineStore } from 'pinia'

interface Consumable {
  value: number
  valuePerConsumable: number
  consumablePriceGems: number
  offer: string
  offerId: number
  shopItem: string
  id: number
  available: boolean
}

interface Consumables {
  energy_drink?: Consumable
  wild_card?: Consumable
}

interface ConsumablesStoreState {
  allowedConsumables: string[]
  dailyLeagueRematchConsumables: AvailableOffer[]
  wild_card: Consumable
  energy_drink: Consumable
  loaded: string[]
}

export const useConsumablesStore = defineStore('consumables', {
  state: (): ConsumablesStoreState => ({
    allowedConsumables: [WILD_CARD, ENERGY_DRINK],
    dailyLeagueRematchConsumables: [],
    [WILD_CARD]: {
      value: 0,
      valuePerConsumable: 15,
      consumablePriceGems: 100,
      offer: 'Wild cards',
      offerId: null,
      shopItem: WILD_CARD_SHOP_ITEM_NAME,
      id: null,
      available: false,
    },
    [ENERGY_DRINK]: {
      value: 0,
      valuePerConsumable: 300,
      consumablePriceGems: 100,
      offer: 'Energy drink',
      offerId: null,
      shopItem: ENERGY_DRINK_SHOP_ITEM_NAME,
      id: null,
      available: false,
    },
    loaded: [],
  }),
  getters: {
    isLoaded(): (key: string) => boolean {
      return (key: string): boolean => {
        return this.loaded.includes(key)
      }
    },
    getAllowedConsumables(): string[] {
      return this.allowedConsumables
    },
    getConsumables(): Consumables {
      return this.allowedConsumables.reduce(
        (output: Consumables, consumable: string): Consumables => {
          output[consumable] = this[consumable]
          return output
        },
        {},
      )
    },
    getConsumable(): (type: string) => Consumable {
      return (type: string): Consumable => {
        return this.allowedConsumables.includes(type) ? this[type] : null
      }
    },
    getDlRematchOfferByDiscipline(): (disciplineId: number) => AvailableOffer {
      return (disciplineId: number): AvailableOffer => {
        return this.dailyLeagueRematchConsumables.find(
          (consumable: AvailableOffer): boolean => consumable.discipline === disciplineId,
        )
      }
    },
  },
  actions: {
    setConsumableValue(data: { type: string; value: number }): void {
      const { type, value } = data
      if (typeof value !== 'number') return
      if (type in this) {
        this[type].value = value
        return
      }
      if (this[WILD_CARD].shopItem === type) {
        this[WILD_CARD].value = value
        return
      }
      if (this[ENERGY_DRINK].shopItem === type) {
        this[ENERGY_DRINK].value = value
      }
    },
    clearConsumables(): void {
      this.loaded = []
    },
    async loadConsumableData(type: string): Promise<void> {
      this.loadValuePerConsumable(type)
      await this.loadConsumablePriceGems() // need await because of item id
      await this.loadConsumablesAvailability()
      Object.entries(this.getConsumables).forEach(([key, element]: [string, Consumable]): void => {
        this.loadAmountOfConsumables([element.id, key])
      })
    },
    async loadValuePerConsumable(type: string): Promise<void> {
      const loadedKey = `${type}_value_per_consumable`
      if (!this.getAllowedConsumables.includes(type) || this.isLoaded(loadedKey)) return
      try {
        const response = await internalAxios.get<{}, ConsumableRewards>(rewardsEndpoint + type)
        this.loaded.push(loadedKey)

        const rewrite = { type, value: response.value }
        const { value } = rewrite
        if (!this.allowedConsumables.includes(type) || typeof value !== 'number') return
        this[type].valuePerConsumable = value
      } catch (error) {
        console.error(error)
      }
    },
    async loadConsumablePriceGems(): Promise<void> {
      try {
        const loadedKey = 'consumablePriceGems'
        if (this.isLoaded(loadedKey)) return
        const response = await internalAxios.get<
          {},
          {
            operation: any // TODO
            availableOffers: AvailableOffer[]
          }
        >(shopEndPoint + '/consumables_shop')
        this.loaded.push(loadedKey)
        Object.entries(this.getConsumables).forEach(
          ([key, element]: [string, Consumable]): void => {
            const offer = response.availableOffers.find(
              (offer: AvailableOffer): boolean => element.offer === offer.name,
            )
            if (!offer) return
            const rewrite = { type: key, value: offer.id }
            const { type, value } = rewrite
            if (!this.allowedConsumables.includes(type) || typeof value !== 'number') return
            this[type].offerId = value

            const price = offer.parameters?.consumable_price_gems
            if (price) {
              const rewrite = {
                type: key,
                value: Number(price),
              }
              const { type, value } = rewrite

              if (!this.allowedConsumables.includes(type) || typeof value !== 'number') return
              this[type].consumablePriceGems = value
            }
            const item = offer.items.find((item: Item): boolean => item.name === element.shopItem)
            if (!item) return

            const rewriteConsumable = { type: key, value: item.id }
            if (
              !this.allowedConsumables.includes(rewriteConsumable.type) ||
              typeof rewriteConsumable.value !== 'number'
            )
              return
            this[rewriteConsumable.type].id = rewriteConsumable.value
          },
        )

        const dlRematchConsumables = response.availableOffers.reduce(
          (finalArray: AvailableOffer[], consumable: AvailableOffer): AvailableOffer[] => {
            if (consumable.items[0]?.name === DAILY_LEAGUE_REMATCH) finalArray.push(consumable)
            return finalArray
          },
          [],
        )
        this.dailyLeagueRematchConsumables = dlRematchConsumables
      } catch (error) {
        console.error(error)
      }
    },
    async loadConsumablesAvailability(): Promise<void> {
      try {
        const loadedKey = 'consumablesIds'
        if (this.isLoaded(loadedKey)) return
        const response = await internalAxios.get<{}, PremiumShopItem[]>(
          shopItemsEndpoint + 'premium_shop',
        )
        this.loaded.push(loadedKey)
        Object.entries(this.getConsumables).forEach(
          ([key, element]: [string, Consumable]): void => {
            const item = response.find(
              (item: PremiumShopItem): boolean => item.name === element.shopItem,
            )
            if (!item) return

            const rewrite = { type: key, value: true }
            const { type, value } = rewrite
            if (!this.allowedConsumables.includes(type) || typeof value !== 'boolean') return
            this[type].available = value
          },
        )
      } catch (error) {
        console.error(error)
      }
    },
    async loadAmountOfConsumables([id, key]: [number, string]): Promise<void> {
      try {
        const loadedKey = `amountOfConsumables${id}`
        if (typeof id !== 'number') {
          this.loaded.push(loadedKey)
          return
        }
        if (this.isLoaded(loadedKey)) return
        if (!this.getConsumable(key)?.available) return
        const response = await internalAxios.get<{}, ShopItemStat>(shopItemStatsEndpoint + id)
        this.loaded.push(loadedKey)
        const amount = response[0]?.item_stats.find(
          (stat: ItemStat): boolean => stat.name === 'amount',
        )
        if (!amount) return
        const rewrite = { type: key, value: Number(amount.value) }
        const { type, value } = rewrite
        if (typeof value !== 'number') return
        if (type in this) {
          this[type].value = value
          return
        }
        if (this[WILD_CARD].shopItem === type) {
          this[WILD_CARD].value = value
          return
        }
        if (this[ENERGY_DRINK].shopItem === type) {
          this[ENERGY_DRINK].value = value
        }
      } catch (error) {
        console.error(error)
      }
    },
  },
})
