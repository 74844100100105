import { defineStore } from 'pinia'
import { internalAxios } from '@/plugins/vueAxios'
import {
  fortuneBasketConfigEndpoint as FORTUNE_BASKET_CONFIG_ENDPOINT,
  fortuneBasketStateEndpoint as FORTUNE_BASKET_STATE_ENDPOINT,
  fortuneBasketClaimEndpoint as FORTUNE_BASKET_CLAIM_ENDPOINT,
  fortuneBasketShuffleEndpoint as FORTUNE_BASKET_SHUFFLE_ENDPOINT,
  fortuneBasketBuyTokensEndpoint as FORTUNE_BASKET_BUY_TOKENS_ENDPOINT,
  fortuneBasketCollectEndpoint as FORTUNE_BASKET_COLLECT_ENDPOINT,
} from '@/globalVariables'

import type FortuneBasketConfigApiResponse from '@/interfaces/responses/events/fortune-basket/FortuneBasketConfigApiResponse'
import type FortuneBasketStateApiResponse from '@/interfaces/responses/events/fortune-basket/FortuneBasketStateApiResponse'

interface FortuneBasketClaimActionPayload {
  position: number
}

interface FortuneBasketState {
  config: FortuneBasketConfigApiResponse | null
  state: FortuneBasketStateApiResponse | null
  multiplierState: boolean
}

export const useFortuneBasketStore = defineStore('fortuneBasketStore', {
  state: (): FortuneBasketState => ({
    config: null,
    state: null,
    multiplierState: false,
  }),
  getters: {
    getSpecialItems(): FortuneBasketStateApiResponse['special_items'] | null {
      return this.state?.special_items ?? null
    },
    getBestReward(): FortuneBasketStateApiResponse['best_reward'][0] | null {
      return this.state?.best_reward?.[0] ?? null
    },
    getRewards(): FortuneBasketStateApiResponse['rewards'] | null {
      return this.state?.rewards ?? null
    },
    getTooltipRewards(): FortuneBasketStateApiResponse['tooltip_rewards'] | null {
      return this.state?.tooltip_rewards ?? null
    },
    getNextReset(): FortuneBasketStateApiResponse['next_reset'] | null {
      return this.state?.next_reset ?? null
    },
    getFoundDailyAmmo(): FortuneBasketStateApiResponse['found_daily_ammo'] | null {
      return this.state?.found_daily_ammo ?? null
    },
    getMultiplierState(): boolean {
      return this.multiplierState
    },
    getShowDailyReset(): FortuneBasketStateApiResponse['show_daily_reset'] | null {
      return this.state?.show_daily_reset ?? null
    },
    getDailyEventPassBonuses(): FortuneBasketStateApiResponse['daily_event_pass_bonuses'] | null {
      return this.state?.daily_event_pass_bonuses ?? null
    },
    getTokensForGems(): FortuneBasketStateApiResponse['tokens_for_gems'] | null {
      return this.state?.tokens_for_gems ?? null
    },
  },
  actions: {
    async loadConfig(force: boolean = false): Promise<void> {
      if (this.config && !force) return

      try {
        const data = await internalAxios.get<{}, FortuneBasketConfigApiResponse>(
          FORTUNE_BASKET_CONFIG_ENDPOINT,
        )
        if (!data) return
        this.config = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadState(): Promise<void> {
      try {
        const data = await internalAxios.get<{}, FortuneBasketStateApiResponse>(
          FORTUNE_BASKET_STATE_ENDPOINT,
        )
        if (!data) return
        this.state = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    toggleMultiplierState(): void {
      this.multiplierState = !this.multiplierState
    },
    async claim({ position }: FortuneBasketClaimActionPayload): Promise<void> {
      try {
        const data = await internalAxios.post<{}, FortuneBasketStateApiResponse>(
          FORTUNE_BASKET_CLAIM_ENDPOINT,
          {
            multiplierActive: this.multiplierState,
            position,
          },
        )
        if (!data) return
        this.state = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async shuffle(): Promise<void> {
      try {
        const data = await internalAxios.post<{}, FortuneBasketStateApiResponse>(
          FORTUNE_BASKET_SHUFFLE_ENDPOINT,
        )
        if (!data) return
        this.state = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async buyTokens(packId: number): Promise<void> {
      try {
        const data = await internalAxios.post<{}, FortuneBasketStateApiResponse>(
          FORTUNE_BASKET_BUY_TOKENS_ENDPOINT,
          {
            packId,
          },
        )
        if (!data) return
        this.state = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async collect(): Promise<void> {
      try {
        const data = await internalAxios.post<{}, FortuneBasketStateApiResponse>(
          FORTUNE_BASKET_COLLECT_ENDPOINT,
        )
        if (!data) return
        this.state = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
