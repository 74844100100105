<template>
  <div class="svg-icon">
    <svg
      v-if="iconName === 'bracket'"
      class="svg-icon__bracket mx-2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 37.2 190.6"
      :style="getCustomStyle('width', 'height')"
    >
      <polygon
        :class="{ 'svg-icon--flipped': verticalFlip }"
        :style="getCustomStyle('fill')"
        points="36.6,8.7 36.6,0.7 0.6,0.7 0.6,189.9 36.6,189.9 36.6,182 8.6,182 8.6,8.7"
      />
    </svg>

    <svg
      v-if="iconName === 'rewardBox'"
      class="svg-icon__reward-box"
      viewBox="0 0 135.3 165.6"
      style="enable-background: new 0 0 135.3 165.6"
    >
      <g id="base_and_stroke">
        <g transform="translate(-1118.12 -753.219)">
          <linearGradient
            id="reward-box__border-gradient"
            gradientUnits="userSpaceOnUse"
            x1="3422.01"
            y1="1429.219"
            x2="3422.01"
            y2="1594.819"
            gradientTransform="matrix(1 0 0 1 -2236.24 -676)"
          >
            <template v-if="$isWsm">
              <stop offset="0" style="stop-color: #22638c" />
              <stop offset="1" style="stop-color: #22638c" />
            </template>
            <template v-if="$isSsm">
              <stop offset="0" style="stop-color: #7b87b8" />
              <stop offset="1" style="stop-color: #7b87b8" />
            </template>
          </linearGradient>
          <polygon
            class="reward-box__base"
            points="1118.6,753.7 1252.9,753.7 1252.9,904.7 1240.5,918.3 1118.6,918.3"
          />
        </g>
      </g>
      <g id="borders">
        <polygon
          v-if="$isWsm"
          id="reward-box-border-top"
          class="reward-box__corner-border"
          points="134.1,0 120.8,0 120.8,1.2 134.1,1.2 134.1,14.5 135.3,14.5 135.3,1.2 135.3,0"
        />
        <polygon
          v-if="$isWsm"
          id="reward-box-border-bottom"
          class="reward-box__corner-border"
          points="1.2,165.6 14.5,165.6 14.5,164.4 1.2,164.4 1.2,151 0,151 0,164.4 0,165.6"
        />
      </g>
      <g id="glow">
        <g transform="translate(-1118.12 -753.219)">
          <polygon
            class="reward-box__glow-inner"
            points="1119.2,754.6 1252.4,754.6 1252.4,904.3 1240.1,917.8 1119.2,917.8"
          />
        </g>
        <defs>
          <filter
            id="OpacityMaskFilter"
            filterUnits="userSpaceOnUse"
            x="1.1"
            y="1.4"
            width="133.2"
            height="163.2"
          >
            <feColorMatrix
              type="matrix"
              values="-1 0 0 0 1  0 -1 0 0 1  0 0 -1 0 1  0 0 0 1 0"
              color-interpolation-filters="sRGB"
              result="source"
            />
          </filter>
        </defs>
        <mask
          id="inner-glow-mask"
          maskUnits="userSpaceOnUse"
          x="1.1"
          y="1.4"
          width="133.2"
          height="163.2"
        >
          <g class="reward-box__opacity-mask">
            <!-- TODO premenovat a presunut asset -->
            <image
              style="overflow: visible"
              width="138"
              height="168"
              xlink:href="https://appspowerplaymanager.vshcdn.net/images/winter-sports/test/1AA123AA68C084E6.jpg"
              transform="matrix(1 0 0 1 -1 -1.4)"
            ></image>
          </g>
        </mask>
        <g style="opacity: 0.8; mask: url(#inner-glow-mask)">
          <g transform="translate(-1118.12 -753.219)">
            <polygon
              class="reward-box__glow"
              points="1119.2,754.6 1252.4,754.6 1252.4,904.3 1240.1,917.8 1119.2,917.8"
            />
          </g>
        </g>
      </g>
      <!-- TODO dynamicke podklady pre orezovu masku (zlate pozadia a pod) -->
      <g v-if="backgroundImage" id="img_clipping_mask">
        <defs>
          <polygon
            id="clipping-mask-polygon"
            points="1.1,1.2 134.2,1.2 134.2,150.9 121.9,164.4 1.1,164.4"
          />
        </defs>
        <clipPath id="clipping-mask-clippath">
          <use xlink:href="#clipping-mask-polygon" style="overflow: visible" />
        </clipPath>
        <g id="image" style="clip-path: url(#clipping-mask-clippath)">
          <image
            style="overflow: visible"
            width="95"
            height="116"
            xlink:href="https://appspowerplaymanager.vshcdn.net/images/winter-sports/disciplines/2-training-practice.avif"
            transform="matrix(1.4233 0 0 1.4233 4.440183e-02 0.25)"
          ></image>
        </g>
      </g>
    </svg>

    <svg
      v-if="iconName === 'watchButton'"
      id="Layer_1"
      class="svg-icon__watch-button"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 411.3902283 151.1029663"
      style="enable-background: new 0 0 411.3902283 151.1029663"
      xml:space="preserve"
    >
      <g class="watch-button-group">
        <g>
          <g>
            <g>
              <linearGradient
                id="watch-button-gradient-fill"
                gradientUnits="userSpaceOnUse"
                x1="251.9291534"
                y1="158.2024536"
                x2="248.9444275"
                y2="15.829"
              >
                <template v-if="$isWsm">
                  <stop offset="0" style="stop-color: #fdb172" />
                  <stop offset="1" style="stop-color: #ec6615" />
                </template>
                <template v-if="$isSsm">
                  <stop offset="0" style="stop-color: #fdb172" />
                  <stop offset="1" style="stop-color: #ec6615" />
                </template>
              </linearGradient>
              <polygon
                class="background"
                points="405.1682434,112.8434372 205.6953583,144.6724701 6.2224479,112.8434372 6.2224479,6.3798933 405.1682434,6.3798933"
              />
            </g>
          </g>
        </g>
        <g>
          <polygon
            class="right-side"
            points="402.7492371,8.6020231 285.1382141,8.6020231 285.1382141,129.7207947 402.7492371,111.279686"
          />
        </g>
        <path
          class="stroke"
          d="M205.6953583,148.723114L2.2222116,116.2553329V2.3798554h406.9458008v113.8754807L205.6953583,148.723114z M10.2222118,109.4311142l195.4731445,31.1904373l195.472641-31.1904373V10.3798552H10.2222118V109.4311142z"
        />
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppSvgIcon',
  props: {
    iconName: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    fill: {
      type: String,
      default: '',
    },
    verticalFlip: {
      type: Boolean,
      default: false,
    },
    backgroundImage: {
      type: String,
      default: null,
    },
  },
  methods: {
    getCustomStyle(...attributes) {
      return attributes.reduce((styles, attribute) => {
        if (this[attribute] !== '') {
          styles[attribute] = this[attribute]
        }
        return styles
      }, {})
    },
  },
})
</script>

<style lang="scss" scoped>
.svg-icon {
  &__bracket {
    width: 1rem;
    height: 2rem;
    fill: white;
  }

  &--flipped {
    -moz-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1);
    transform-origin: center;
  }

  &__reward-box {
    width: 100%;
    height: auto;

    .reward-box__base {
      opacity: 1;
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke: url(#reward-box__border-gradient);
      stroke-width: 0.125rem;
      stroke-miterlimit: 10;

      @if $isWsm {
        fill: rgba(18, 61, 87, 0.8);
      }

      @if $isSsm {
        fill: rgba(58, 66, 93, 0.7);
      }
    }
    .reward-box__corner-border {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #b5deff;
    }
    .reward-box__glow-inner {
      opacity: 0.8;
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: transparent;
      enable-background: new;
    }
    .reward-box__opacity-mask {
      filter: url(#OpacityMaskFilter);
    }
    .reward-box__glow {
      opacity: 0.8;
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: rgba(40, 137, 214, 0.2);
    }
  }

  &__watch-button {
    width: 8.5rem;

    .background {
      fill: url(#watch-button-gradient-fill);
    }

    .stroke {
      fill: #f8b356;
    }

    .right-side {
      fill: #884e29;
    }
  }
}
</style>
