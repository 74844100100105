import { careerDetailEndpoint, tutorialEndPoint } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import router from '@/router'

import { tutorialClasses } from '@/data/tutorial'
import { capitalize, getValidationToken, musicTheme, sendToFlutter } from '@/helpers'
import { isMobile } from '@/plugins'
import { useUserStore } from '@/store/pinia/userStore'
import { defineStore } from 'pinia'
import { useCoreStore } from './coreStore'
import { useResponseTaskStore } from './responseTaskStore'
import { SystemConfigNames } from '@/interfaces/User'
import { ApiService } from '@/services/ApiService'

import type { CareerDetailApiResponse } from '@/interfaces/responses/career/CareerDetailApiResponse'
import type { TutorialResponse } from '@/interfaces/responses/tutorial/TutorialResponses'
import type { NavigationFailure } from 'vue-router'
import type { TutorialState, TutorialStage, Report } from '@/interfaces/Tutorial'
import { StageType } from '@/interfaces/Tutorial'
import { usePhaserGameIntegrationStore } from './map-new/phaserGameIntegrationStore'
import type { Nullable } from '@/interfaces/utils'

export const useTutorialStore = defineStore('tutorialStore', {
  state: (): TutorialState => ({
    isTutorial: false,
    actualStage: null,
    report: null,
    redrawFocus: true,
    tutorialId: null,
    validButtonId: '',
    tutorialInProgress: false,
  }),
  getters: {
    getActualStage(): Nullable<TutorialStage> {
      return this.actualStage
    },

    getIsTutorial(): TutorialState['isTutorial'] {
      return this.isTutorial
    },
    getReport(): TutorialState['report'] {
      return this.report
    },
    getRedrawFocus(): TutorialState['redrawFocus'] {
      return this.redrawFocus
    },
    getTutorialId(): TutorialState['tutorialId'] {
      return this.tutorialId
    },
    getValidButtonId(): TutorialState['validButtonId'] {
      return this.validButtonId
    },
    isFocusType(): boolean {
      return this.actualStage?.type === StageType.Focus
    },
    isFocusItem(): boolean {
      return this.isFocusType && this.actualStage?.clientId.includes('item')
    },
    getTutorialBossName(): string {
      return this.getActualStage?.texts?.[0]?.boss || 'anne'
    },
    getTutorialBossPose(): string {
      return this.getActualStage?.texts?.[0]?.pose || 'pose_1'
    },
  },
  actions: {
    setTutorial(state: boolean): void {
      this.isTutorial = state
    },
    setActualStage(newVal: TutorialStage | null): void {
      this.actualStage = newVal
    },
    setReport(newValue: Report): void {
      this.report = newValue
    },
    setTutorialId(value: string | null): void {
      this.tutorialId = value
    },
    setValidButtonId(newValue: string): void {
      this.validButtonId = newValue
    },
    async logTutorialProgress(stage: string): Promise<void> {
      try {
        if (this.tutorialInProgress) return

        this.tutorialInProgress = true
        const response = await internalAxios.put<{}, TutorialResponse>(tutorialEndPoint, {
          action_name: stage,
        })

        if (!response) {
          this.tutorialInProgress = false
          router.push({ name: 'LayoutView' })
          return
        }

        const isTutorial = response.state === 'in_progress'
        if (!isTutorial) {
          if (this.getTutorialId === 'init') {
            this.finishTutorial()
          } else {
            this.$reset()
          }
          const phaserStore = usePhaserGameIntegrationStore()
          phaserStore.showWeather()
          this.tutorialInProgress = false
          return
        }
        const tutorialId = response.tutorial_name
        const TutorialClass = tutorialClasses[`Tutorial${capitalize(tutorialId)}`]
        const tutorial = new TutorialClass()

        const currentStage = response.current_actions[0]
        const secondStage = response.current_actions.length > 1 ? response.current_actions[1] : null
        const currentStageName = currentStage.action_name

        const actualStage = tutorial
          .stages()
          .find((stage: TutorialStage): boolean => stage.name === currentStageName)
        if (currentStage.discipline) {
          actualStage.discipline = currentStage.discipline
        }
        if (currentStage.unlocks) {
          actualStage.unlocks = currentStage.unlocks
        }
        if (currentStage.dialogues?.length) {
          actualStage.texts = currentStage.dialogues
        }
        if (secondStage) {
          actualStage.secondStage = secondStage.action_name
        }

        this.actualStage = actualStage
        this.isTutorial = isTutorial

        this.tutorialId = tutorialId
        const useUser = useUserStore()
        if (response?.EXPERIENCE) useUser.setUserDataExperience(response.EXPERIENCE)
        if (response?.TARGET_EXPERIENCE)
          useUser.setUserDataTargetExperience(response.TARGET_EXPERIENCE)
        if (response?.STARTING_EXPERIENCE)
          useUser.setUserDataStartingExperience(response.STARTING_EXPERIENCE)
        if (response?.LEVEL) useUser.setUserDataLevel(response.LEVEL)

        if (this.isFocusType) {
          const phaserStore = usePhaserGameIntegrationStore()
          phaserStore.showMapTutorial(this.actualStage.clientId[0], this.isFocusItem)
          phaserStore.hideWeather()
        }

        this.tutorialInProgress = false
      } catch (e: unknown) {
        console.error(e)
        this.tutorialInProgress = false
      }
    },
    setRedrawFocus(value: boolean): void {
      this.redrawFocus = value
    },
    async finishTutorial(): Promise<void> {
      this.$reset()

      const responseStore = useResponseTaskStore()
      await responseStore.loadMechanics(true)
      const useStore = useUserStore()
      useStore.setSystemConfig([{ name: SystemConfigNames.Music, value: 1 }])

      await router.push({ name: 'LayoutView' })

      if (!isMobile() && musicTheme && !musicTheme.playing()) {
        musicTheme.play()
      } else {
        sendToFlutter('{\r\n  "event": "setMusic",\r\n "enabled": true\r\n}')
      }
      window.location.reload()
    },
    async loadTutorial(): Promise<boolean> {
      const tutorialData = await ApiService.get<TutorialResponse>(tutorialEndPoint, {
        force: true,
      })
      const isTutorial = tutorialData.state === 'in_progress'

      if (!isTutorial) {
        this.$reset()

        if (window.location.href.includes('tutorial')) {
          router.push({ name: 'LayoutView' })
        }
        return
      }
      const tutorialId = tutorialData.tutorial_name
      return new Promise((resolve: CallableFunction): Promise<boolean> => {
        if (!getValidationToken('token')) {
          return resolve(false)
        }
        this.isTutorial = isTutorial
        this.tutorialId = tutorialId
        if (!isTutorial) {
          return resolve(false)
        }
        const TutorialClass = tutorialClasses[`Tutorial${capitalize(tutorialId)}`]
        const tutorial = new TutorialClass()

        const currentStage = tutorialData.current_actions[0]
        const currentStageName = currentStage.action_name
        const secondStage =
          tutorialData.current_actions.length > 1 ? tutorialData.current_actions[1] : null

        const actualStage = tutorial
          .stages()
          .find((e: TutorialStage): boolean => e.name === currentStageName)

        if (currentStage.discipline) {
          actualStage.discipline = currentStage.discipline
        }
        if (currentStage.unlocks) {
          actualStage.unlocks = currentStage.unlocks
        }
        if (currentStage.dialogues?.length) {
          actualStage.texts = currentStage.dialogues
        }
        if (secondStage) {
          actualStage.secondStage = secondStage.action_name
        }

        this.actualStage = actualStage
        if (!actualStage) {
          return resolve(false)
        }

        if (actualStage.loadRewardedItems) {
          usePhaserGameIntegrationStore().refreshMainMapRewardedItems()
        }

        if (actualStage.pageName !== '') {
          if (
            actualStage.pageName === 'ArenaReportTutorial' &&
            this.getReport?.reportId &&
            this.getReport?.disciplineId
          ) {
            router.push({
              name: 'ArenaReportTutorial',
            })
          } else if (
            actualStage.pageName === 'CareerDetailTutorial' &&
            (actualStage.name === 'clickTrackTaskCareer' ||
              actualStage.name === 'openDisciplineBranchDetailNarrativeCareer' ||
              actualStage.name === 'focusCareerHomeButtonCareer')
          ) {
            internalAxios
              .get<{}, CareerDetailApiResponse>(`${careerDetailEndpoint}/discipline`)
              .then((res: CareerDetailApiResponse): Promise<void | NavigationFailure> => {
                return router.push({
                  name: isMobile() ? 'CareerDetailTutorial' : 'CareerDetailWebTutorial',
                  params: {
                    id: res.careers_branch.discipline_id,
                    task: res.careers_quests[0].careers_quests_parameters.id,
                    careerId: res.career_id,
                  },
                })
              })
          } else {
            const tutorialMaps = [
              'LayoutViewTutorial',
              'ClubsMapTutorial',
              'ChooseDisciplineTutorial',
            ]
            router.push({
              name: actualStage.pageName,
              query: {
                reloadData: 'true',
              },
            })
            if (!tutorialMaps.includes(actualStage.pageName)) {
              const useCore = useCoreStore()
              useCore.setLoadingScreen(false)
            }
          }
        }

        resolve(true)
      })
    },
    resetTutorialState(): void {
      this.$reset()
    },
  },
})

export type TutorialStore = ReturnType<typeof useTutorialStore>
