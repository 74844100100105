<template>
  <div class="view-trainer app-page-wrapper absolute">
    <training-menu :current="$getWebView('TrainingTrainer')" :is-sub-menu-active="true" />
    <section class="w-full h-full safe-area">
      <modal-head class="ml-9" :show-info="false" :title-tooltip="$t('trainingHall.trainerInfo')">
        <p
          class="text-texts-standard-default text-30 z-1 px-4 lexing font-bold uppercase trainer-info"
        >
          <span class="trainer-info__cta" v-html="getCTAText"></span>
        </p>
        <div class="training-header-timer">
          <app-timer
            v-if="trainerDisciplines"
            v-tippy="{
              theme: 'WSM',
              content: $t('trainingHall.newDisciplinesIn'),
              placement: 'top',
              textAlign: 'center',
            }"
            :time="trainerDisciplines[0]?.expiration ?? null"
          />
        </div>
        <template #popupContent>
          <training-popup />
        </template>
      </modal-head>
      <div class="training-trainer-content">
        <div
          class="training-trainer-avatar"
          :class="{ 'training-trainer-avatar--mobile': $isMobile() }"
        />
        <div class="training-trainer-aside">
          <app-scrollbar
            height="100%"
            width="100%"
            scroll="x"
            slide="x"
            class="flex items-center"
            :arrows="true"
          >
            <div v-if="!isContentLoading" class="training-trainer-boxes-wrapper">
              <router-link
                v-for="discipline in getTrainerDisciplines"
                :key="discipline.id"
                :to="{
                  name: $getWebView('TrainingTrainerDetail'),
                  params: { id: discipline.id },
                }"
                :class="[
                  'flex training-trainer-boxes-wrapper-margin',
                  {
                    'pointer-events-none': discipline.locked,
                  },
                ]"
              >
                <training-box
                  :id="'training-trainer-box-' + discipline.id"
                  :trainer="true"
                  :data-cy="'training-trainer-box-' + discipline.id"
                  :discipline-data="discipline"
                  :text-attr-value="$t('trainingHall.attributeValue')"
                  :text-train-completed="$t('trainingHall.trainingCompleted')"
                  @click="showDetail = true"
                />
              </router-link>
            </div>

            <div class="flexing">
              <component-loading
                v-for="id in 3"
                :key="id"
                class="bordered-1 component-loading"
                width="31.25rem"
                height="40.75rem"
                type="secondary"
                :is-loading="isContentLoading"
              />
            </div>
          </app-scrollbar>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import ModalHead from '@/components/ModalHead.vue'
import TrainingBox from '@/components/Training/TrainingBox.vue'
import TrainingPopup from '@/components/Training/TrainingPopup.vue'
import { DISCIPLINE_TRAINED, pathImages } from '@/globalVariables'
import { useTrainingDisciplineStore } from '@/store/pinia/trainingDisciplinesStore'
import TrainingMenu from '@/views/training/TrainingMenu.vue'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type Discipline from '@/interfaces/global/Discipline'

interface ComponentData {
  DISCIPLINE_TRAINED: typeof DISCIPLINE_TRAINED
  pathImages: typeof pathImages
  buttonData: {
    btnId: string
    btnType: string
    btnSize: string
  }
  showDetail: boolean
  textsForTranslate: string[]
  ctaMultipleNumber: number
}

export default defineComponent({
  name: 'TrainingTrainer',
  components: {
    TrainingMenu,
    ModalHead,
    TrainingBox,
    TrainingPopup,
  },
  data(): ComponentData {
    return {
      DISCIPLINE_TRAINED,
      pathImages,
      buttonData: {
        btnId: 'training-confirm-btn',
        btnType: 'confirm',
        btnSize: 'md',
      },
      showDetail: false,
      textsForTranslate: [
        'trainingHall.attributeValue',
        'trainingHall.trainingCompleted',
        'trainingHall.header',
        'common.building',
        'trainingHall.tutorials',
        'trainingHall.training',
        'trainingHall.trainer',
        'trainingHall.disciplines',
        'trainingHall.aboutTrainingHeaderInfo',
        'trainingHall.aboutTrainingIncreaseAttributesInfo',
        'trainingHall.aboutTrainingTpInfo',
        'trainingHall.aboutTrainingUniversalTpInfo',
        'trainingHall.trainerInfo',
        'trainingHall.newDisciplinesIn',
        'trainingHall.trainerCallToActionMessage',
      ],
      ctaMultipleNumber: 3,
    }
  },
  computed: {
    ...mapState(useTrainingDisciplineStore, {
      trainerDisciplines: 'getDisciplines',
    }),
    isContentLoading(): boolean {
      return this.trainerDisciplines.length === 0
    },
    getTrainerDisciplines(): Discipline[] {
      return this.trainerDisciplines.filter(
        (discipline: Discipline): boolean => discipline.slotId !== null,
      )
    },
    getCTAText(): string {
      let ctaText = this.$t('trainingHall.trainerCallToActionMessage')
      ctaText = ctaText.replace(/{icon}/g, '<div class="silver-tp-icon"></div>')
      ctaText = this.$replacePlaceholder(
        ctaText,
        '{number}',
        '<b class="text-texts-standard-important ml-2">' + this.ctaMultipleNumber + '</b>',
      )
      return ctaText
    },
  },
  created(): void {
    this.loadInitialDisciplinesData(true)
  },
  methods: {
    ...mapActions(useTrainingDisciplineStore, ['loadInitialDisciplinesData']),
  },
})
</script>

<style lang="scss" scoped>
.view-trainer {
  &.app-page-wrapper {
    overflow: hidden;
  }

  .trainer-info {
    position: absolute;
    width: 65%;
    max-width: 70%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;

    &__cta {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:deep(.silver-tp-icon) {
      width: 3.3rem;
      height: 3.5rem;
      position: relative;
      background: url($path-icons + 'main/main-icons-120_v5.avif') -15.6rem 0.1rem no-repeat;
      background-size: 44rem;
      margin: 0 0.5rem;
      display: inline-block;
    }
  }

  .training-header-timer {
    position: absolute;
    right: 0;
    z-index: 1;
  }

  .training-trainer-content {
    height: 43.75rem;
    width: 98%;
    margin: 0 0 0 1rem;

    .training-trainer-avatar {
      position: absolute;
      @if $isWsm {
        background: url($path-figures + '/Leo-Bates.avif') center no-repeat;
        width: 28.188rem;
      }
      @if $isSsm {
        background: url($path-figures + '/Zuzana-Stefecekova.avif') center no-repeat;
        width: 31.75rem;
      }
      background-size: contain;
      height: 43.75rem;
      left: -1.52rem;
      bottom: 0;

      &--mobile {
        left: env(safe-area-inset-left);
      }
    }

    .training-trainer-aside {
      position: relative;
      width: calc(100% - 30.25rem);
      left: 30.25rem;
    }

    .training-trainer-boxes-wrapper {
      position: relative;
      display: flex;
      justify-content: space-between;

      &-margin {
        margin: 0 0.687rem 1.18rem;
        overflow: hidden;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.component-loading {
  border-style: solid;
  border-width: 0.125rem;
  border-image-source: linear-gradient(to top, #5ba3dc, #bfe3ff, #5ba3dc);
  border-image-slice: 1;
  margin-right: 0.5rem;
}
</style>
