<template>
  <div
    class="scroll-shadow relative overflow-y-auto"
    :class="{
      'shadow-top': showTopShadow,
      'shadow-bottom': showBottomShadow,
      'shadow-left': showLeftShadow,
      'shadow-right': showRightShadow,
    }"
    style="height: 10rem"
    @scroll="updateShadows"
  >
    <span class="text-texts-standard-default text-nowrap">
      {{ text }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

interface ComponentData {
  showTopShadow: boolean
  showBottomShadow: boolean
  showLeftShadow: boolean
  showRightShadow: boolean
}

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      showTopShadow: false,
      showBottomShadow: false,
      showLeftShadow: false,
      showRightShadow: false,
    }
  },
  mounted(): void {
    // Initial update of shadows
    this.updateShadows()
  },
  methods: {
    updateShadows(): void {
      const scrollLeft = this.$el.scrollLeft
      const maxScrollLeft = this.$el.scrollWidth - this.$el.clientWidth

      const scrollTop = this.$el.scrollTop
      const maxScrollTop = this.$el.scrollHeight - this.$el.clientHeight

      // Disable both horizontal shadows if there's no horizontal scrollable area
      if (this.$el.scrollWidth <= this.$el.clientWidth) {
        this.showLeftShadow = false
        this.showRightShadow = false
      } else {
        this.showLeftShadow = scrollLeft > 0
        this.showRightShadow = scrollLeft < maxScrollLeft
      }

      // Disable both vertical shadows if there's no vertical scrollable area
      if (this.$el.scrollHeight <= this.$el.clientHeight) {
        this.showTopShadow = false
        this.showBottomShadow = false
      } else {
        this.showTopShadow = scrollTop > 0
        this.showBottomShadow = scrollTop < maxScrollTop
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.scroll-shadow {
  width: calc(100% + 1rem);
  padding-right: 1rem;

  &.shadow-left.shadow-right {
    mask-image: linear-gradient(to right, transparent, black 25%, black 75%, transparent 100%);
  }

  &.shadow-top.shadow-bottom {
    mask-image: linear-gradient(to bottom, transparent, black 25%, black 75%, transparent 100%);
  }

  &.shadow-top {
    mask-image: linear-gradient(to bottom, transparent, black 25%, black 100%);
  }

  &.shadow-bottom {
    mask-image: linear-gradient(to top, transparent, black 25%, black 100%);
  }

  &.shadow-left {
    mask-image: linear-gradient(to right, transparent, black 25%, black 100%);
  }

  &.shadow-right {
    mask-image: linear-gradient(to left, transparent, black 25%, black 100%);
  }
}
</style>
