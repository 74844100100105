<template>
  <article class="relative back-bonuses-basic flex items-center justify-between">
    <div class="back-bonuses-basic-text-wrapper grow text-wrapper text-left flex">
      <p v-show="showPlus" class="text-36 text-texts-standard-default mr-4 plus absolute">+</p>
      <p class="back-bonuses-basic-text text-texts-standard-important">
        {{ text }}
      </p>
    </div>
    <div class="grow-0 amount-group flexing text-texts-standard-default">
      <p class="font-bold">
        {{ $filters.$formatNumber(gems) }}
      </p>
      <app-main-icon class="icon-margin" :icon-name="GEMS" :icon-size="32" />
    </div>
  </article>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { GEMS } from '@/globalVariables'

interface ComponentData {
  GEMS: typeof GEMS
}

export default defineComponent({
  name: 'GemsPackBackLine',
  props: {
    text: {
      type: String,
      default: '',
    },
    gems: {
      type: Number,
      default: 0,
    },
    showPlus: {
      type: Boolean,
      default: true,
    },
  },
  data(): ComponentData {
    return {
      GEMS,
    }
  },
})
</script>

<style lang="scss" scoped>
.back-bonuses-basic {
  &-text-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 2.4rem;
  }

  .plus {
    top: -1.5rem;
  }

  .amount-group {
    min-width: 12rem;
    justify-content: flex-end;
  }
}
</style>
