import { pathImages } from '@/globalVariables'
import {
  BUILDING_LOCKED_TOOLTIP_CONTAINER_NAMES,
  BUILDING_LOCKED_TOOLTIP_POSITION,
} from '@/map-phaser-new/constants'
import type {
  BuildingLockedConfigInterface,
  BuildingLockedTooltipConfigInterface,
  BuildingTooltipTextsOriginsInterface,
  BuildingTooltipContainerCoordinatesInterface,
  BuildingTooltipTextsInterface,
} from '@/map-phaser-new/interfaces'
import { translate } from '@/plugins'
export class BuildingLockedTooltip {
  private lockedTooltipContainer?: Phaser.GameObjects.Container
  private lockedTooltipTimer?: Phaser.Time.TimerEvent

  /**
   *
   * @param activeScene Scene where tooltip will be added.
   * @param lockedBuildingImage Image of building for which tooltip will be added.
   * @param config Contains config data for tooltip.
   * @param title
   * @param unlock
   */
  public addToBuilding(
    activeScene: Phaser.Scene,
    lockedBuildingImage: Phaser.GameObjects.Image,
    config: BuildingLockedConfigInterface,
    title: string,
    unlock: string,
  ): void {
    this.addLockedBuildingTooltip(activeScene, lockedBuildingImage, config, title, unlock)
    this.addLockedBuildingTooltipEvent(activeScene)
  }

  public destroyTooltipAndTimer(activeScene: Phaser.Scene): void {
    activeScene.time.removeEvent(this.lockedTooltipTimer)
    this.lockedTooltipTimer.remove(true)
    this.lockedTooltipTimer.destroy()
    this.lockedTooltipTimer = null

    this.lockedTooltipContainer.removeAll(true)
    this.lockedTooltipContainer.destroy()
    this.lockedTooltipContainer = null
  }

  public showTooltip(activeScene: Phaser.Scene, delay: number): void {
    if (this.lockedTooltipTimer.getRemainingSeconds() > 0) return

    this.lockedTooltipContainer.setVisible(true)
    activeScene.time.removeEvent(this.lockedTooltipTimer)
    this.lockedTooltipTimer.reset({
      delay: delay,
      callback: () => {
        this.lockedTooltipContainer.setVisible(false)
      },
    })
    activeScene.time.addEvent(this.lockedTooltipTimer)
  }

  public reloadTooltipTexts(
    activeScene: Phaser.Scene,
    config: BuildingLockedConfigInterface,
    title: string,
    unlock: string,
  ): void {
    const titleTextOld: Phaser.GameObjects.DOMElement = this.lockedTooltipContainer.getByName(
      BUILDING_LOCKED_TOOLTIP_CONTAINER_NAMES.TitleTooltip,
    )
    const subtitleTextOld: Phaser.GameObjects.DOMElement = this.lockedTooltipContainer.getByName(
      BUILDING_LOCKED_TOOLTIP_CONTAINER_NAMES.SubtitleTooltip,
    )
    const backgroundImage: Phaser.GameObjects.DOMElement = this.lockedTooltipContainer.getByName(
      BUILDING_LOCKED_TOOLTIP_CONTAINER_NAMES.BackgroundTooltip,
    )
    const { titleTextObject, subTitleTextObject } = this.addTooltipTexts(
      activeScene,
      backgroundImage,
      config.tooltipConfig,
      title,
      unlock,
    )

    this.lockedTooltipContainer.replace(titleTextOld, titleTextObject, true)
    this.lockedTooltipContainer.replace(subtitleTextOld, subTitleTextObject, true)

    titleTextObject.setName(BUILDING_LOCKED_TOOLTIP_CONTAINER_NAMES.TitleTooltip)
    subTitleTextObject.setName(BUILDING_LOCKED_TOOLTIP_CONTAINER_NAMES.SubtitleTooltip)
  }

  private addTooltipTexts(
    activeScene: Phaser.Scene,
    backgroundImage: Phaser.GameObjects.DOMElement,
    config: BuildingLockedTooltipConfigInterface,
    title: string,
    unlock: string,
  ): BuildingTooltipTextsInterface {
    const { titleOriginX, subTitleOriginX, subTitleOriginY } =
      this.getOriginsBasedOnTooltipPosition(config)

    const titleTextObject = activeScene.add
      .dom(
        0,
        (-1 * backgroundImage.height) / 2.75,
        'span',
        `color: ${config.titleConfig.fontColor}; font-size: ${config.titleConfig.fontSize}; font-family: ${config.titleConfig.fontName}; text-transform: uppercase; font-weight: ${config.titleConfig.fontWeight}; font-style: ${config.titleConfig.fontStyle}`,
        title,
      )
      .setDepth(config.depth)
      .setOrigin(titleOriginX, config.titleConfig.originX)

    const subTitleTextObject = activeScene.add
      .dom(
        0,
        0,
        'span',
        `color: ${config.subtitleConfig.fontColor}; font-size: ${config.subtitleConfig.fontSize}; font-family: ${config.subtitleConfig.fontName}; font-weight: ${config.subtitleConfig.fontWeight}; font-style: ${config.subtitleConfig.fontStyle}`,
        config.subtitleConfig.tooltipSubtitleReplaceTags
          ? this.replaceTags(
              config.subtitleConfig.tooltipSubtitleTranslateKey,
              config.subtitleConfig.tooltipSubtitleReplaceTags,
              config.subtitleConfig.tooltipSubtitleReplaceFlag,
              unlock,
            )
          : this.replaceFlag(
              config.subtitleConfig.tooltipSubtitleTranslateKey,
              config.subtitleConfig.tooltipSubtitleReplaceFlag,
              unlock,
            ),
      )
      .setDepth(config.depth)
      .setOrigin(subTitleOriginX, subTitleOriginY)

    return { titleTextObject, subTitleTextObject }
  }

  private replaceFlag(text: string, flag: string, replaceString: string): string {
    return translate(text).replace(flag, replaceString)
  }

  private replaceTags(
    text: string,
    tags: { start: string; end: string },
    flag: string,
    replaceString: string,
  ): string {
    const reg = new RegExp(Object.values(tags).join('|'), 'g')
    return translate(text).replace(reg, '').replace(flag, replaceString)
  }

  private addLockedBuildingTooltip(
    activeScene: Phaser.Scene,
    lockedBuildingImage: Phaser.GameObjects.Image,
    config: BuildingLockedConfigInterface,
    title: string,
    unlock: string,
  ): void {
    const { tooltipContainerX, tooltipContainerY, textureName, textureSize } =
      this.getConfigAccordingPosition(config.tooltipConfig, lockedBuildingImage)

    this.lockedTooltipContainer = activeScene.add
      .container(tooltipContainerX, tooltipContainerY)
      .setDepth(config.tooltipConfig.depth)
      .setVisible(false)

    const backgroundImage = activeScene.add
      .dom(
        0,
        0,
        'span',
        `width: ${textureSize.width}; height: ${textureSize.height}; background: url(${pathImages}/map/${textureName}.avif) no-repeat 0 0; z-index:1;`,
      )
      .setDepth(config.tooltipConfig.depth)
      .setName(BUILDING_LOCKED_TOOLTIP_CONTAINER_NAMES.BackgroundTooltip)

    const { titleTextObject, subTitleTextObject } = this.addTooltipTexts(
      activeScene,
      backgroundImage,
      config.tooltipConfig,
      title,
      unlock,
    )
    titleTextObject.setName(BUILDING_LOCKED_TOOLTIP_CONTAINER_NAMES.TitleTooltip)
    subTitleTextObject.setName(BUILDING_LOCKED_TOOLTIP_CONTAINER_NAMES.SubtitleTooltip)

    this.lockedTooltipContainer.add([titleTextObject, subTitleTextObject, backgroundImage])
  }

  private getConfigAccordingPosition(
    config: BuildingLockedTooltipConfigInterface,
    lockedBuildingImage: Phaser.GameObjects.Image,
  ): BuildingTooltipContainerCoordinatesInterface {
    switch (config.tooltipPosition) {
      case BUILDING_LOCKED_TOOLTIP_POSITION.Left:
        return {
          tooltipContainerX: lockedBuildingImage.x - lockedBuildingImage.width * 0.1,
          tooltipContainerY: lockedBuildingImage.y + lockedBuildingImage.height / 2,
          textureName: config.tooltipLeftTextureName,
          textureSize: { width: config.tooltipTextureWidth, height: config.tooltipTextureHeight },
        }
      case BUILDING_LOCKED_TOOLTIP_POSITION.Right:
        return {
          tooltipContainerX: lockedBuildingImage.x + lockedBuildingImage.width * 1.1,
          tooltipContainerY: lockedBuildingImage.y + lockedBuildingImage.height / 2,
          textureName: config.tooltipRightTextureName,
          textureSize: { width: config.tooltipTextureWidth, height: config.tooltipTextureHeight },
        }
      default:
        return {
          tooltipContainerX: lockedBuildingImage.x + lockedBuildingImage.width / 2,
          tooltipContainerY: lockedBuildingImage.y - lockedBuildingImage.height / 10,
          textureName: config.tooltipTopTextureName,
          textureSize: { width: config.tooltipTextureWidth, height: config.tooltipTextureHeight },
        }
    }
  }

  private getOriginsBasedOnTooltipPosition(
    config: BuildingLockedTooltipConfigInterface,
  ): BuildingTooltipTextsOriginsInterface {
    let titleOriginX = config.titleConfig.originX
    let subTitleOriginX = config.subtitleConfig.originX
    let subTitleOriginY = config.subtitleConfig.originY

    if (config.tooltipPosition === BUILDING_LOCKED_TOOLTIP_POSITION.Left) {
      titleOriginX += 0.1
      subTitleOriginX += 0.1
      subTitleOriginY = 0
    }

    if (config.tooltipPosition === BUILDING_LOCKED_TOOLTIP_POSITION.Right) {
      titleOriginX -= 0.1
      subTitleOriginX -= 0.1
      subTitleOriginY = 0
    }

    return { titleOriginX, subTitleOriginX, subTitleOriginY }
  }

  private addLockedBuildingTooltipEvent(activeScene: Phaser.Scene): void {
    this.lockedTooltipTimer = activeScene.time.addEvent({
      delay: 0,
      paused: true,
    })
  }
}
