<template>
  <section
    class="avatar-box relative"
    :style="{ height: customHeight + 'rem', width: customWidth + 'rem' }"
    @click="$emit('on-click', avatarId)"
  >
    <div
      v-if="isAvatar"
      class="avatar-box-background absolute w-full h-full z-1"
      :style="{
        background: 'url(' + avatarImgPath + ') center no-repeat',
        backgroundSize: 'cover',
      }"
    />
    <div
      v-if="isBackground"
      class="avatar-box-image relative w-full h-full"
      :style="{
        background: 'url(' + bgImgPath + ') center no-repeat',
        backgroundSize: 'cover',
      }"
    />
    <div v-if="isSelected" class="avatar-box-selected absolute w-full h-full" />
    <div v-if="onlineStatus" class="online-status" :class="'is-' + onlineStatus" />
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { animatedAvatars, pathImages } from '@/globalVariables'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'AvatarBox',
  props: {
    avatarId: {
      type: [Number, String],
      default: 0,
    },
    avatarGender: {
      type: String,
      default: 'male',
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    backgroundId: {
      type: [Number, String],
      default: 1,
    },
    isAvatar: {
      type: Boolean,
      default: true,
    },
    isBackground: {
      type: Boolean,
      default: true,
    },
    customWidth: {
      type: Number,
      default: null,
    },
    customHeight: {
      type: Number,
      default: null,
    },
    onlineStatus: {
      type: String,
      default: '',
    },
  },
  emits: ['on-click'],
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    avatarImgPath(): string {
      if (this.avatarId) {
        return `${this.pathImages}avatars/${this.avatarGender}/${this.avatarId}${
          animatedAvatars.includes(String(this.avatarId)) ? '.gif' : '.avif'
        }`
      } else {
        return this.pathImages + 'components/profileAvatar/profile-avatar-player.avif'
      }
    },
    bgImgPath(): string {
      return `${this.pathImages}avatars/backgrounds/${this.backgroundId}.avif`
    },
  },
})
</script>

<style lang="scss" scoped>
.avatar-box {
  width: 13.75rem;
  height: 13.75rem;
  margin: 0 1rem;
  cursor: pointer;
  border-radius: 100%;
  box-shadow: 0 0.625rem 1.563rem 0 rgba(0, 0, 0, 0.45);

  &-image {
    border-radius: 100%;
  }

  &-selected {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%;
    border: 0.188rem solid theme('colors.texts.standard.important');
    z-index: 2;
  }

  .online-status {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 0;

    &.is-online {
      border: solid 0.125rem #0ac506;
      background-image: radial-gradient(circle at center, #3fe443 10%, #18c110 50%);
      box-shadow:
        0 0 13.5px 1.5px rgb(63 228 67 / 30%),
        inset 0px 5px 5px 0 rgb(63 228 67 / 50%);
    }

    &.is-recently {
      border: solid 0.125rem #ffa200;
      background-image: radial-gradient(circle at 0 0, #eb9600, #fdb333);
      box-shadow:
        0 0 13.5px 1.5px rgba(255, 162, 0, 0.3),
        inset 0px 5px 5px 0 rgba(255, 162, 0, 0.5);
    }

    &.is-offline {
      border: solid 0.125rem #4f4f4f;
      background-image: radial-gradient(circle at 0 0, #505050, #515151);
      box-shadow:
        0 0 13.5px 1.5px rgba(102, 102, 102, 0.3),
        inset 0px 5px 5px 0 rgba(53, 53, 53, 0.5);
    }
  }
  &--offer {
    width: 14.75rem;
    height: 14.75rem;
  }
}
</style>
