import type { App, Plugin } from 'vue'
import { formatNumber } from './filters/formatNumber'
import { abbrNumber, abbrRewardValue } from './filters/abbrNumber'
import type { Nullable } from '@/interfaces/utils'

export interface ConfigPopupInterface {
  title: string
  text: Nullable<string>
  btnId: string
  btnText: string
  btnValue: string
}

export interface SubmitPopupData {
  title: string
  btnText: string
  btnType: string
  btnId: string
  text: Nullable<string>
}

export const submitPopupData = ({
  title,
  btnText,
  btnType,
  btnId,
  text,
}: SubmitPopupData): SubmitPopupData => {
  return {
    title,
    btnText,
    btnType,
    btnId,
    text,
  }
}

export const confirmPopupData = ({
  title,
  text = null,
  btnId = 'buttonConfirmDefaultId',
  btnText,
  btnValue,
}: ConfigPopupInterface): ConfigPopupInterface => {
  return {
    title,
    text,
    btnId,
    btnText,
    btnValue,
  }
}

export const filtersPlugin: Plugin = {
  install: (app: App): void => {
    app.config.globalProperties.$filters = {
      $abbrNumber: abbrNumber,
      $abbrRewardValue: abbrRewardValue,
      $formatNumber: formatNumber,
      $submitPopupData: submitPopupData,
      $confirmPopupData: confirmPopupData,
    }
  },
}
