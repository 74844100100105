import {
  EQUIPMENT_ATTRIBUTE_BONUS,
  EQUIPMENT_CASH_BONUS,
  EQUIPMENT_DURABILITY,
  EQUIPMENT_TP_BONUS,
  LEVEL,
} from '@/globalVariables'

import { formatLevel } from '@/helpers'
import type { ItemLevel } from '@/helpers'

const SLOT_NAMES = {
  standard_attribute: 'standard_attribute',
  standard_durability: 'standard_durability',
  standard_cash: 'standard_cash',
  standard_tp: 'standard_tp',
  premium_attribute: 'premium_attribute',
  premium_durability: 'premium_durability',
  premium_cash: 'premium_cash',
  premium_tp: 'premium_tp',
}
const BONUS_MAPPINGS = {
  standard_durability: EQUIPMENT_DURABILITY,
  standard_attribute: EQUIPMENT_ATTRIBUTE_BONUS,
  standard_tp: EQUIPMENT_TP_BONUS,
  standard_cash: EQUIPMENT_CASH_BONUS,
  premium_durability: EQUIPMENT_DURABILITY,
  premium_attribute: EQUIPMENT_ATTRIBUTE_BONUS,
  premium_tp: EQUIPMENT_TP_BONUS,
  premium_cash: EQUIPMENT_CASH_BONUS,
  order: [
    SLOT_NAMES.standard_attribute,
    SLOT_NAMES.standard_durability,
    SLOT_NAMES.standard_cash,
    SLOT_NAMES.standard_tp,
    SLOT_NAMES.premium_attribute,
    SLOT_NAMES.premium_durability,
    SLOT_NAMES.premium_cash,
    SLOT_NAMES.premium_tp,
  ],
}

interface Upgrade {
  id: number
  name: string
  level: number
  type: string
  premium: boolean
  currentLevel: ItemLevel | null
  nextLevel: ItemLevel | null
  levels: any[]
  properties: any
}

interface ItemStat {
  name: string
  value: string
}

interface UpgradeData {
  id: number
  name: string
  hook_slot: {
    name: string
  }
  item_stats?: ItemStat[]
  levels: any[]
  properties: any
}

export const formatUpgrades = (upgrades: UpgradeData[]): Upgrade[] => {
  return BONUS_MAPPINGS.order.map((hookSlotName: string): Upgrade => {
    const hook = upgrades.find((e: UpgradeData): boolean => e.hook_slot.name === hookSlotName)
    if (!hook) return
    const searchedLevel =
      parseInt(hook.item_stats?.find((stat: ItemStat): boolean => stat.name === LEVEL).value) ?? 0
    const currentLevel = hook.levels.find(
      (itemLevel: ItemLevel): boolean => itemLevel.level === searchedLevel,
    )
    const nextLevel = currentLevel
      ? hook.levels.find(
          (itemLevel: ItemLevel): boolean => itemLevel.level === currentLevel.level + 1,
        )
      : hook.levels[0]
    return {
      id: hook.id,
      name: hook.name,
      level: searchedLevel,
      type: BONUS_MAPPINGS[hook.hook_slot.name],
      premium: hook.hook_slot.name.startsWith('premium'),
      currentLevel: formatLevel(currentLevel),
      nextLevel: formatLevel(nextLevel),
      levels: hook.levels,
      properties: hook.properties,
    }
  })
}
