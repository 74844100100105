<template>
  <div class="energy-drink app-page-wrapper flex flex-col absolute">
    <premium-menu />
    <section class="w-full h-full safe-area">
      <main class="energy-drink-main h-full flex">
        <side-menu />
        <section class="energy-drink-main-wrapper">
          <header-accessories
            :amount="consumableEnergyDrink.value"
            :icon-type="'energy_drink'"
            :text-left="$t('parameters.energy_drink')"
            :text-right="$t('parameters.yourEnergyDrinks')"
          />
          <div v-if="!isContentLoading" class="wrapper relative flex">
            <app-scrollbar
              scroll="x"
              slide="x"
              width="100%"
              height="100%"
              class="flex items-center"
            >
              <section ref="animation1" class="wrapper-left">
                <div class="premium-small-card-item animated-element--faded">
                  <watch-video-card :addon-type="ENERGY_DRINK" @reload-data="loadData()" />
                </div>
                <div class="premium-small-card-item animated-element--faded">
                  <small-card
                    :small-pack="smallCard"
                    @pack="pack"
                    @popup="popup = true"
                    @buy-pack="buyPack"
                    @load-data="loadData"
                  />
                </div>
              </section>
              <section ref="animation2" class="wrapper-right flex">
                <big-card :big-pack="bigCard" @pack="pack" @popup="popup = true" />
              </section>
            </app-scrollbar>
          </div>

          <component-loading height="39.75rem" :is-loading="isContentLoading" />
        </section>
      </main>
      <confirm-popup
        v-if="popup"
        :popup-title="$t('parameters.energy_drink')"
        :button-data="btnUpgrade"
        :not-multi="true"
        :single-button-text="
          headerData.value > amount
            ? $t('premiumGroup.buy')
            : $t('premiumGroup.buy') + ' ' + $t('premiumGroup.gems')
        "
        @close="popup = false"
        @action="buyPack"
      >
        <div class="popup-wrapper">
          <p class="text-texts-standard-default text-38">
            {{ $t('premiumGroup.addOnConfirm') }}
          </p>
          <div class="flexing text-texts-standard-default text-40">
            <p>{{ $t('premiumGroup.price') }}:</p>
            <p
              class="flexing amount-text text-texts-standard-important"
              :class="{ 'text-texts-standard-danger': headerData.value < amount }"
            >
              {{ amount === 0 ? 'FREE' : amount }}
            </p>
            <app-main-icon :icon-size="56" icon-name="gems" />
          </div>
        </div>
      </confirm-popup>
    </section>
    <video-ad-web v-show="isWebVideo" @hide-video="webVideoPlay(false)" />
  </div>
</template>

<script lang="ts">
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import BigCard from '@/components/Premium/Accessories/BigCard.vue'
import HeaderAccessories from '@/components/Premium/Accessories/HeaderAccessories.vue'
import SideMenu from '@/components/Premium/Accessories/SideMenu.vue'
import SmallCard from '@/components/Premium/Accessories/SmallCard.vue'
import WatchVideoCard from '@/components/Premium/Accessories/WatchVideoCard.vue'
import VideoAdWeb from '@/components/Premium/Ads/VideoAdWeb.vue'
import PremiumMenu from '@/components/Premium/PremiumMenu.vue'
import { ENERGY_DRINK, premiumAddonEndpoint, shopOfferEndPoint } from '@/globalVariables'
import { animateElement } from '@/helpers'
import { useConsumablesStore } from '@/store/pinia/consumablesStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { Consumable } from '@/interfaces/HeaderParams'
import type { PremiumAddon } from '@/interfaces/premium/Offer'
import type { PremiumPack } from '@/interfaces/premium/PremiumPack'

interface ButtonUpgrade {
  btnId: string
  btnType: string
  btnSize: string
}

interface ComponentData {
  ENERGY_DRINK: typeof ENERGY_DRINK
  drinks: PremiumPack[]
  smallCard: PremiumPack[]
  bigCard: PremiumPack[]
  id: string
  amount: number
  popup: boolean
  btnUpgrade: ButtonUpgrade
}

export default defineComponent({
  name: 'PremiumEnergyDrink',
  components: {
    SideMenu,
    PremiumMenu,
    HeaderAccessories,
    SmallCard,
    BigCard,
    WatchVideoCard,
    ConfirmPopup,
    VideoAdWeb,
  },
  data(): ComponentData {
    return {
      ENERGY_DRINK,
      drinks: null,
      smallCard: null,
      bigCard: null,
      id: '',
      amount: 0,
      popup: false,
      btnUpgrade: {
        btnId: 'btn-buy-access',
        btnType: 'credit',
        btnSize: 'xl',
      },
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      isWebVideo: 'getWebVideoPlaying',
    }),
    ...mapState(useConsumablesStore, ['getConsumable']),
    ...mapState(useResponseTaskStore, {
      headerData: 'getGems',
    }),
    consumableEnergyDrink(): Consumable {
      return this.getConsumable(ENERGY_DRINK)
    },
    isContentLoading(): boolean {
      return this.bigCard == null && this.smallCard == null
    },
  },
  watch: {
    isContentLoading(loading: boolean): void {
      if (!loading) {
        this.$nextTick((): void => {
          this.animateScene()
        })
      }
    },
  },
  async created(): Promise<void> {
    this.loadData()
    this.loadConsumableData(ENERGY_DRINK)
  },
  methods: {
    ...mapActions(usePremiumStore, {
      webVideoPlay: 'setWebVideoPlay',
    }),
    ...mapActions(useConsumablesStore, ['loadConsumableData']),
    pack(id: string, amount: number): void {
      this.id = id
      this.amount = amount
    },
    async buyPack(): Promise<void> {
      if (this.headerData.value < this.amount) {
        this.$router.push({ name: this.$getWebView('PremiumGems') })
      } else {
        await this.$axios.post<{}, true>(shopOfferEndPoint, { offer_id: this.id })
        this.loadData()
      }
      this.popup = false
    },
    async loadData(): Promise<void> {
      const data = await this.$axios.get<{}, PremiumAddon>(premiumAddonEndpoint)

      this.drinks = data.availableOffers.filter(
        (a: PremiumPack): boolean => a.parameters.type === 'energy_drink',
      )
      this.smallCard = this.drinks.filter(
        (a: PremiumPack): boolean => parseInt(a.parameters.order as string) <= 2,
      )
      this.bigCard = this.drinks.filter(
        (a: PremiumPack): boolean => parseInt(a.parameters.order as string) > 2,
      )
    },
    animateScene(): void {
      const animatedSmallElements = this.$refs.animation1.querySelectorAll(
        '.premium-small-card-item',
      )
      animatedSmallElements.forEach((element: HTMLElement, index: number): void => {
        const delay = 67 + index * 100
        animateElement(
          element,
          'fade-in, rotate-in',
          '0.87s',
          delay,
          'cubic-bezier(0.22, 1, 0.36, 1)',
        )
      })

      const animatedBigElements = this.$refs.animation2.querySelectorAll('.premium-big-card-item')
      animatedBigElements.forEach((element: HTMLElement, index: number): void => {
        const delay = 240 + index * 200
        animateElement(
          element,
          'fade-in, rotate-in',
          '0.87s',
          delay,
          'cubic-bezier(0.22, 1, 0.36, 1)',
        )
      })
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/animations.scss';

.energy-drink-main {
  &-wrapper {
    width: 100%;
    margin: 1.125rem 2.1875rem 0;

    .wrapper {
      margin-top: 1.125rem;
      height: 39.75rem;

      &-left {
        display: flex;
        flex-direction: column;
        width: 35.625rem;
        height: 100%;
        gap: 1.4rem;
      }

      &-right {
        margin-left: 2.375rem;
        width: 64.9375rem;
      }
    }
  }
}

.popup-wrapper {
  padding-top: 2.5rem;

  .amount-text {
    margin-left: 0.5rem;
  }
}
</style>
