import type { Multiplicator } from '@/types/userProfile'
import type { EventBadgeInterface } from '@/interfaces/EventBadgeInterface'
import type Country from '@/interfaces/Country'
import type Reward from '@/interfaces/Reward'
export interface UserSystemConfigInterface {
  id: string
  username: string
  system_config: SystemConfig[]
  blocked: boolean
}

export enum SystemConfigNames {
  Music = 'music',
  Sounds = 'sounds',
}

export interface SystemConfig {
  name: SystemConfigNames | string
  value: number
}

export interface UserAvatarInterface {
  avatars: Avatar[]
  backgrounds: Background[]
}

export interface Avatar {
  name: string
  locked_bg: boolean
}

export interface Background {
  name: string
}

export interface UserLevelUpInterface {
  stat: Stat
  newStat: any[] // TODO any
}

export interface Stat {
  id: number
  value: number
  user_games_id: string
  created_at: string
  updated_at: string
  TARGET_EXPERIENCE: number
  type: Type
}

export interface Type {
  id: number
  name: string
  description: string
  created_at: string
  updated_at: string
}

export interface UserProfile {
  attributes: UserAttributes
  event_badges?: EventBadgeInterface[]
  group: string
  multipliers: Multiplicator
  user_data: UserData
  user_id: string
}

export interface UserProfileEndpointInterface {
  user_id: string
  attributes: Attribute[]
  user_data: UserData
  group?: string
  multiplicators?: Multiplicator[]
}

export interface Attribute {
  name: string
  value: number | string | boolean
}

export interface Passes {
  state: string
  store_id: string
  remaining_datetime: string
  rewards?: Reward[]
}

export interface UserData {
  id: string
  username: string
  email?: string
  role?: string
  anonymous?: boolean
  country: Country
  stats?: Stats
  platforms?: Platforms
  club_stats?: ClubStats
  season?: Season
  blocked: boolean
  show_link_account?: boolean
  passes?: Passes[]
  delete_account_countdown?: number
  invite_button_tooltip?: string
  first_discipline?: number
}

// TODO: delete this after club types are prepared in club store and import from there
export interface ClubStats {
  club_type: string
  paid_in_gems: boolean
  member_minimum_level: number
  name: string
  logo_id: number
  description: string
  country: Country
  logo_background_id: number
  id: string
  role: string
  isInClubChampionship: boolean
  member_count: number
  members_max_length?: number
  prestige?: number
}

export interface Platforms {
  ios: boolean
  fb: boolean
  android: boolean
  web: boolean
}

export interface Season {
  seasonYear: number
  seasonMonth: number
}

export interface Stats {
  LEVEL: number
  EXPERIENCE: number
  RANKING_POINTS: number
  TRAINING_POINTS: number
  TARGET_EXPERIENCE: number
  STARTING_EXPERIENCE: number
  MAX_LEVEL_REACHED: boolean
}

export interface UpdatedAvatar {
  name: string
  number: number
  noBg: boolean
}

export interface UpdatedBackgrounds {
  name: string
  number: number
}

export interface UserAttributes {
  sex: string
  race: string
  auto_repair: boolean
  auto_best_equip: boolean
  language: string
  show_result_screen: boolean
  avatar_background: number
  currency: string
  avatar: number
  buildings_names: boolean
  gems_transactions_warnings: boolean
  push_notifications: boolean
  country_payment: string
  club_requests_notifications: boolean
  training_hall_attribute_limit_warning: boolean
  join_club_reminder: boolean
  grand_prize_leaving_popup: boolean
  multiplicator_training: boolean
  multiplicator_season_pass: boolean
  avatarExt: string
  event_ship_arrived: boolean // toto sa automaticky maze po skonceni eventu
  event_teaser_seen: boolean // toto sa automaticky maze po skonceni eventu
  board_games_teaser_seen: boolean // toto sa automaticky maze po skonceni teasru a po skonceni eventu
  board_games_seen: boolean // toto sa automaticky maze kazdy den eventu a po skonceni eventu
  game_pass_seen: boolean
  piggy_bank_seen: boolean
}

export interface NewValues {
  attributes: Attribute[]
  profile_attributes: Attribute[]
  username: string
  country: string
  user_data: UserData
}

export interface UserState {
  userState: UserProfile
  notShowConfirm: boolean
  hasToken: boolean
  avatars: UpdatedAvatar[]
  backgrounds: UpdatedBackgrounds[]
  allowedENVs: string[]
  showAccountConnectedPopup: boolean
  isSoundEnabled: number
  isMusicEnabled: number
  gdprPageShowed: boolean
  gdprCurrentVersion: string
}
