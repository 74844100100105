import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const clubCarsLeftTopToRightTopPath: PathObjectInterface = {
  mainPath: { x: 1642, y: 127 },
  spline: [
    1642, 127, 1641, 148, 1645, 173, 1654, 217, 1681, 246, 1710, 263, 1773, 282, 1865, 297, 1993,
    311, 2066, 319, 2148, 334, 2245, 363, 2323, 385, 2405, 409, 2509, 434, 2593, 443, 2676, 442,
    2751, 434, 2849, 433, 2929, 443, 3050, 460, 3115, 466, 3188, 465, 3290, 458, 3374, 441,
  ],
}
