import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const boatLeftToRightPath: PathObjectInterface = {
  mainPath: { x: -10, y: 1458 },
  spline: [
    67, 1458, 571, 1468, 854, 1439, 945, 1381, 1019, 1357, 1149, 1292, 1223, 1271, 1415, 1247, 1519,
    1184, 1598, 1120, 1672, 1069, 1823, 1026, 2032, 983, 2147, 971, 2248, 964, 2373, 990, 2546,
    1024, 2596, 1069, 2585, 1170, 2723, 1199, 2882, 1194, 3038, 1132, 3129, 1055, 3311, 1012, 3628,
    937, 3803, 935, 3880, 944,
  ],
}
