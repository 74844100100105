<template>
  <div
    :class="[
      addClass,
      'timer-component skew',
      {
        transparent: transparent,
        centered: centered,
        'with-icon': !noIcon,
        abc: abcTesting,
        'no-min-width': noMinWidth,
      },
    ]"
  >
    <section class="revert-skew flexing">
      <app-icon v-if="!noIcon" :icon-name="icon" />
      <p
        class="timer-text t"
        :style="`font-size: ${textSize};`"
        :class="[
          importantText ? 'text-texts-standard-important' : 'text-texts-standard-default',
          { masters: isMasters },
        ]"
      >
        <vue-countdown
          v-if="!noCountdown"
          v-slot="{ days, hours, minutes, seconds }"
          :time="Number(time) * 1000"
          @end="$emit('countdownAction')"
        >
          <p v-if="(Number(time) === 0 || !Number(time)) && timerEndText" class="pl-2 uppercase">
            {{ timerEndText }}
          </p>
          <p v-else class="pl-2" :class="{ 'not-italic': !italic }">
            {{ formatTime(days, hours, minutes, seconds) }}
          </p>
        </vue-countdown>
        <span v-if="noCountdown" class="pl-2">
          {{
            Number(time) === 0 && timerEndText
              ? timerEndText
              : formatTime(...formatSecondsToHMSCalculate(Number(time)))
          }}
        </span>
      </p>
    </section>
  </div>
</template>

<script lang="ts">
import { formatSecondsToHMSCalculate, formatTime } from '@/helpers'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppTimer',
  props: {
    time: {
      type: [Number, String],
      default: 0,
    },
    italic: {
      type: Boolean,
      default: true,
    },
    addClass: {
      type: String,
      default: '',
    },
    textSize: {
      type: String,
      default: '2.25rem',
    },
    icon: {
      type: String,
      // do not change the default 'time'
      default: 'time',
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    // static timer, just showing the time w/o counting down
    noCountdown: {
      type: Boolean,
      default: false,
    },
    timerEndText: {
      type: String,
      default: null,
    },
    noMinWidth: {
      type: Boolean,
      default: false,
    },
    importantText: {
      type: Boolean,
      default: false,
    },
    abcTesting: {
      type: Boolean,
      default: false,
    },
    isMasters: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['countdownAction'],
  methods: {
    formatTime,
    formatSecondsToHMSCalculate,
  },
})
</script>

<style lang="scss" scoped>
.timer-component {
  height: 3.563rem;
  min-width: 18.063rem;

  @if $isSsm {
    background: linear-gradient(
      90deg,
      rgba(195, 24, 24, 0.8) 0%,
      rgba(195, 24, 24, 0.65) 42%,
      transparent 100%
    );
  }

  @if $isWsm {
    background: linear-gradient(
      90deg,
      rgba(159, 29, 34, 0.8) 0%,
      rgba(195, 24, 24, 0.8) 42%,
      transparent 100%
    );
  }

  .masters {
    color: #fadd1e;
  }

  font-size: 2.25rem;
  align-items: center;
  display: flex;
  justify-content: flex-start;

  &.with-icon {
    padding-left: 1rem;
  }

  &.transparent {
    background: none;
  }

  &.centered {
    justify-content: center;
  }

  &.no-min-width {
    min-width: auto;
  }

  &:not(.transparent):before {
    content: '';
    width: 0.313rem;
    height: 100%;
    background: #ff1717;
    position: absolute;
    left: -0.45rem;
  }

  & > div,
  .icon-time {
    margin-right: 0.5rem;
  }

  &.abc {
    background: #9f1d22;
    background: linear-gradient(90deg, #9f1d22 0%, #c31818 42%, transparent 100%);
  }
}
</style>
