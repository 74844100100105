<template>
  <div class="lab-camps-tree flex justify-around">
    <lab-camps-header class="lab-camps-tree-header" :header-data="researchColumnsCamps" />
    <lab-camps-column
      v-for="(col, i) in labData"
      :key="i"
      :column-data="col"
      :active-research="isActiveResearch(i)"
      @select-skill="$emit('selectSkill', $event.id, i)"
      @reload-data="$emit('reloadData')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LabCampsHeader from '@/components/Lab/Camps/LabCampsHeader.vue'
import LabCampsColumn from '@/components/Lab/Camps/LabCampsColumn.vue'
import type { PropType } from 'vue'
import type { Cell } from '@/interfaces/lab/Cell'
import { RepeatableStates } from '@/interfaces/lab/States'
import { gameDisciplinesEndpoint } from '@/globalVariables'
import type Discipline from '@/interfaces/global/Discipline'
import type { Nullable } from '@/interfaces/utils'

interface HeaderData {
  disciplines: number[]
  text: string
}

interface ComponentData {
  researchColumnsCamps: HeaderData[]
  disciplines: Discipline[]
  formattedDisciplineBranchNames: (string | [string, string])[]
}

interface CampResearchColumn {
  disciplines: number[]
  text: string
}

type MatchingDisciplines = (Nullable<Discipline> | Discipline[])[]

export default defineComponent({
  name: 'LabCampsTree',
  components: {
    LabCampsHeader,
    LabCampsColumn,
  },
  props: {
    labData: {
      type: Array as PropType<Cell[][]>,
      required: true,
    },
  },
  emits: ['selectSkill', 'reloadData'],
  data(): ComponentData {
    return {
      researchColumnsCamps: null,
      disciplines: null,
      formattedDisciplineBranchNames: [],
    }
  },
  async mounted(): Promise<void> {
    await this.loadDisciplines()
    this.formatLabCampsData()

    if (this.disciplines) {
      const matchingDisciplines = this.matchDisciplines()
      const matchingDisciplineIds = this.getMatchDisciplinesIds(matchingDisciplines)
      this.researchColumnsCamps = this.createCampResearchColumns(matchingDisciplineIds)
    }
  },
  methods: {
    isActiveResearch(index: number): boolean {
      const activeResearch = this.labData[index].find(
        (cell: Cell): boolean => cell.state === RepeatableStates.RepeatableActivated,
      )
      return !!activeResearch
    },
    editDisciplineName(disciplineName: string): string {
      const editedName = disciplineName.toLowerCase().replace(/[\s-]+/g, '_')

      // 'ski_jumping' | 'snowboarding' > branch "skijump+snowboard"
      if (editedName === 'ski_jumping') return 'skijump'
      if (editedName === 'snowboarding') return 'snowboard'

      // "alpine_skiing_downhill", "alpine_skiing_giant_slalom" > branch "downhill+giant_slalom"
      // 'short_track_500m' > branch "speed_skating+short_track"
      // "cross_country_skiing" > branch "biathlon+cross_country"
      return editedName.replace(/alpine_skiing_|_skiing|_500m/g, '')
    },
    formatLabCampsData(): void {
      this.labData.forEach((item: Cell[]): void => {
        const branchName = item[0].branchName
        if (branchName.includes('+')) {
          const splitNames = branchName.split('+')
          this.formattedDisciplineBranchNames.push([splitNames[0], splitNames[1]])
        } else {
          this.formattedDisciplineBranchNames.push(branchName)
        }
      })
    },
    async loadDisciplines(): Promise<void> {
      try {
        this.disciplines = await this.$axios.get<{}, Discipline[]>(gameDisciplinesEndpoint)

        this.disciplines.forEach((item: Discipline): void => {
          item.name = this.editDisciplineName(item.name)
        })
      } catch (error: unknown) {
        console.error(error)
      }
    },
    matchDisciplines(): MatchingDisciplines {
      return this.formattedDisciplineBranchNames.map(
        (item: string | [string, string]): Discipline[] | Discipline => {
          if (Array.isArray(item)) {
            return item.map(
              (subItem: string): Discipline =>
                this.disciplines.find(
                  (discipline: Discipline): boolean => discipline.name === subItem,
                ),
            )
          } else {
            return this.disciplines.find(
              (discipline: Discipline): boolean => discipline.name === item,
            )
          }
        },
      )
    },
    getMatchDisciplinesIds(matchingDisciplines: MatchingDisciplines): number[][] {
      return matchingDisciplines.map((item: Discipline): Nullable<number[]> => {
        if (Array.isArray(item)) {
          return item.map((subItem: Discipline): number => (subItem ? subItem.id : null))
        } else {
          return item ? [item.id, null] : null
        }
      })
    },
    createCampResearchColumns(matchingDisciplineIds: number[][]): CampResearchColumn[] {
      return matchingDisciplineIds.map((item: number[], index: number): CampResearchColumn => {
        if (Array.isArray(item)) {
          return {
            disciplines: item,
            text: '',
          }
        } else {
          if (index === 5) {
            return {
              disciplines: null,
              text: 'research.regenerationEnergy',
            }
          } else if (index === 6) {
            return {
              disciplines: null,
              text: 'research.regenerationStart',
            }
          }
        }
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.lab-camps-tree {
  padding-bottom: 2rem;
  &-header {
    position: absolute;
    top: -6rem;
  }
}
</style>
