<template>
  <section class="rightside">
    <header v-if="!premium" class="flexing relative m-auto">
      <p class="text-texts-standard-default uppercase text-32">
        {{ $t('equipmentShop.chooseAddOn') }}
      </p>
    </header>

    <main class="rightside-main relative">
      <div class="rightside-main-center m-auto flexing" :class="{ 'is-premium': premium }">
        <div
          class="item"
          :class="[
            `item-${disciplineId}-${itemCategory}-${equipmentData.level}`,
            { locked: premium?.locked },
          ]"
        />
        <div v-if="premium?.locked" class="absolute">
          <app-icon icon-name="lock-xxl" />
        </div>
      </div>
      <section v-if="!premium">
        <button
          v-for="(upgrade, i) in upgrades"
          :key="i"
          :class="[
            'rightside-main-box bordered-sm attribute-button-' + (i + 1),
            upgrade.premium ? 'premium' : 'normal',
            {
              'opacity-80 cursor-default': isOffer || isDelivered,
              'cursor-default rightside-main-fully': isFullyUpgrade(upgrade.level),
            },
          ]"
          @click="openAddon(upgrade)"
        >
          <div
            class="rightside-main-box-wrapper"
            :class="{
              'rightside-main-box-wrapper-progress-bg': addonProgress(upgrade.name),
            }"
          >
            <section
              class="rightside-main-box-wrapper-content revert-skew"
              :class="{ 'opacity-50': addonProgress(upgrade.name) }"
            >
              <shrink-text
                :text="$t(getUpgradeName(upgrade))"
                :font-size="26"
                container-class-list="text-left mr-2 text-max-width"
                text-class-list="text-texts-standard-default uppercase"
              />
              <aside class="flexing">
                <p class="text-texts-standard-important text-28 font-bold">
                  {{ getAddonBonus(upgrade) }}
                </p>
                <equipment-item-attributes-icons
                  :icon-type="getAttibutesIcons(upgrade.type)"
                  :discipline-id="disciplineId"
                  :icon-size="48"
                  custom-icon-class="mr-0"
                />
              </aside>
            </section>
            <section v-if="addonProgress(upgrade.name)" class="addon-in-progress flexing">
              <p class="text-texts-standard-default text-32 revert-skew">
                {{ $t('equipmentShop.upgrading') }}
              </p>
            </section>
          </div>
          <div class="point" />
          <div class="star-container absolute flexing -mr-1 revert-skew">
            <div
              v-for="(_, j) in upgrade.levels"
              :key="j"
              :class="'icon-star-' + showStars(upgrade, j + 1) + '-32'"
            />
          </div>
          <equipment-upgrade-icon
            v-if="isPossibleUpgrade(upgrade.level) && !addonProgress(upgrade.name) && !isOffer"
            :has-revert-skew="true"
            :custom-position="{
              right: '-1.4rem',
              bottom: '3rem',
            }"
            icon-size="sm"
            class="absolute"
          />
        </button>
      </section>
      <info-popup
        v-if="showPopup && !isOffer"
        :popup-title="$t('equipmentShop.addOn')"
        @close="showPopup = false"
      >
        <equipment-addon-popup
          :addon="addonData"
          :current-level="equipmentData.level"
          :ongoing-operation="ongoingOperation"
          :discipline-id="disciplineId"
          :item-id="itemId"
          :process-time="equipmentData.delivery.time"
          @upgrade=";(showPopup = false), upgradeAddon()"
          @load-item-detail="loadItemDetail"
        />
      </info-popup>
    </main>
  </section>
</template>

<script lang="ts">
import EquipmentAddonPopup from '@/components/Equipment/EquipmentAddonPopup.vue'
import EquipmentItemAttributesIcons from '@/components/Equipment/EquipmentItemAttributesIcons.vue'
import EquipmentUpgradeIcon from '@/components/Equipment/EquipmentUpgradeIcon.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import ShrinkText from '@/components/ShrinkText.vue'
import {
  EQUIPMENT_ATTRIBUTE_BONUS,
  EQUIPMENT_DURABILITY,
  OPERATION_UPGRADE,
} from '@/globalVariables'
import { getAttibutesIcons } from '@/helpers'
import { useEquipmentStore } from '@/store/pinia/equipment/equipmentStore'
import type {
  FormattedEquipmentData,
  FormattedLevel,
  OngoingOperation,
  Upgrades,
} from '@/interfaces/Equipment'
import type { Nullable } from '@/interfaces/utils'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface ComponentData {
  openSessionMsg: boolean
  showPopup: boolean
  addonData: Nullable<Upgrades[0]>
}

export default defineComponent({
  name: 'EquipmentDetailItem',
  components: {
    InfoPopup,
    EquipmentAddonPopup,
    EquipmentUpgradeIcon,
    EquipmentItemAttributesIcons,
    ShrinkText,
  },
  props: {
    equipmentData: {
      type: Object as PropType<Nullable<FormattedLevel>>,
      default: () => null,
    },
    isOffer: {
      type: Boolean,
      required: true,
    },
    upgrades: {
      type: Array as PropType<Upgrades>,
      required: true,
    },
    itemCategory: {
      type: Number,
      required: true,
    },
    disciplineId: {
      type: Number,
      required: true,
    },
    ongoingOperation: {
      type: Object as PropType<Nullable<OngoingOperation>>,
      default: () => null,
    },
    itemId: {
      type: Number,
      default: 1,
    },
    premium: {
      type: Object as PropType<Nullable<FormattedEquipmentData['premium']>>,
      default: () => null,
    },
  },
  emits: ['setOperation', 'loadItemDetail'],
  data(): ComponentData {
    return {
      openSessionMsg: false,
      showPopup: false,
      addonData: null,
    }
  },
  computed: {
    isDelivered(): boolean {
      return (
        this.ongoingOperation &&
        this.itemId &&
        this.itemId === this.ongoingOperation.itemId &&
        this.ongoingOperation.type === 'equipment_delivery'
      )
    },
  },
  methods: {
    getAttibutesIcons,
    ...mapActions(useEquipmentStore, ['upgradeItem']),
    getAddonBonus(upgrage: Upgrades[0]): string {
      if (!this.isOffer && upgrage.currentLevel)
        return '+' + upgrage.currentLevel.upgrade.bonus + this.hasPercentage(upgrage.type)
      return '-'
    },
    hasPercentage(name: string) {
      if ([EQUIPMENT_DURABILITY, EQUIPMENT_ATTRIBUTE_BONUS].includes(name)) return ''
      return '%'
    },
    getUpgradeName(upgrade: Upgrades[0]): string {
      const name = upgrade.properties.find(
        (prop: Upgrades[0]['properties'][0]): boolean => prop.name === 'text_name',
      )?.value
      return 'equipmentShop.' + (name ?? upgrade.name)
    },
    isPossibleUpgrade(level: Upgrades[0]['level']): boolean {
      if (this.isOffer || level === this.equipmentData.level) return false
      return true
    },
    addonProgress(name: string): boolean {
      if (!this.ongoingOperation) return false
      if (
        name === this.ongoingOperation.itemName &&
        this.upgrades.some(
          (upgrade: Upgrades[0]): boolean => upgrade.id === this.ongoingOperation.itemId,
        )
      ) {
        return true
      }
      return false
    },
    async upgradeAddon(): Promise<void> {
      await this.upgradeItem({
        itemId: this.itemId,
        addonId: this.addonData.id,
      })
      this.loadItemDetail()
      this.$emit(
        'setOperation',
        this.addonData.id,
        this.addonData.nextLevel.upgrade.time,
        OPERATION_UPGRADE,
        this.addonData.nextLevel.upgrade.speedup,
        this.addonData.name,
      )
    },
    showStars(upgrade: Upgrades[0], level: number): string {
      if (level <= upgrade.level) return 'full'
      if (upgrade.premium && level <= this.equipmentData.level) return 'premium-empty'
      if (level <= this.equipmentData.level) return 'empty'
      return ''

      // return level <= upgrade.level ? 'full' : level <= this.equipmentData.level ? 'empty' : 'lock'
    },
    setAddon(upgrade: Upgrades[0]): void {
      this.addonData = upgrade
    },
    openAddon(upgrade: Upgrades[0]): void {
      if (this.isFullyUpgrade(upgrade.level) || this.isDelivered) return
      this.showPopup = true
      this.setAddon(upgrade)
    },
    isFullyUpgrade(level: Upgrades[0]['level']) {
      if (level === 5) return true
      return false
    },
    loadItemDetail(): void {
      this.$emit('loadItemDetail')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/stars-icons.scss';

.rightside {
  width: 76.3125rem;
  height: 39.3125rem;
  margin-left: 4.75rem;
  position: relative;

  header {
    width: 31.25rem;
    height: 3rem;
    padding: 0.5rem 0;
    @if $isWsm {
      background: linear-gradient(to right, #101b2b00, #101b2b, #11507e, #11507e, #06112300);
    }
    @if $isSsm {
      background: linear-gradient(
        to right,
        transparent,
        rgba(52, 65, 93, 0.85),
        rgba(39, 50, 73, 0.85),
        transparent
      );
    }

    &:before {
      content: '';
      top: 0;
      position: absolute;
      height: 0.125rem;
      width: 100%;
      @if $isWsm {
        background: linear-gradient(to right, #8bc5f700, #8bc5f7, #8bc5f700);
      }
      @if $isSsm {
        background: linear-gradient(to right, transparent, #586b9d, transparent);
      }
    }

    &:after {
      content: '';
      bottom: 0;
      position: absolute;
      height: 0.125rem;
      width: 100%;
      @if $isWsm {
        background: linear-gradient(to right, #8bc5f700, #8bc5f7, #8bc5f700);
      }
      @if $isSsm {
        background: linear-gradient(to right, transparent, #586b9d, transparent);
      }
    }
  }

  &-main {
    width: 100%;
    height: calc(100% - 2.5625rem);

    &-center {
      background: url($path-equipment + 'BG-VYBAVA-ITEM.avif') center no-repeat;
      background-size: contain;
      width: 65.1875rem;
      height: 40.875rem;

      &.is-premium {
        background: none;
      }

      .item {
        background-size: contain;
        background-position: center;
        width: 15.375rem;
        height: 32.375rem;
        background-repeat: no-repeat;
        // globalne zvacsenie itemov
        transform: scale(1.2);

        @for $discipline from 1 through 18 {
          @for $category from 1 through 6 {
            @for $level from 1 through 5 {
              &.item-#{$discipline}-#{$category}-#{$level} {
                background-image: url($path-equipment + 'items/detail/'+#{$discipline}+'/'+#{$category}+'-'+#{$level}+'.avif');
                @if $discipline >= 4 {
                  width: 28.375rem;
                }

                @if $category ==5 or $category ==6 {
                  width: 40rem;
                }

                &.locked {
                  background-image: url($path-equipment + 'items/detail/'+#{$discipline}+'/'+#{$category}+'-'+#{$level}+'-locked.avif');
                }
              }
            }
          }
        }
      }
    }

    &-box {
      outline: transparent;
      width: 20.625rem;
      height: 5rem;
      transform: $skewX-value;
      margin: 0 0.625rem;
      position: absolute;
      text-shadow: 1.1px 1.7px 1px rgba(0, 0, 0, 0.46);
      box-shadow: 0.3rem 0.544rem 0.563rem 0 rgba(0, 0, 0, 0.08);

      &-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        line-height: 1.5rem;
        position: relative;

        &-progress-bg {
          @if $isWsm {
            background: rgb(8, 27, 82, 0.8);
          }
        }

        &-content {
          width: 100%;
          margin: 0 0.938rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .text-max-width {
          width: 10.5rem;
          overflow-wrap: break-word;
        }

        .addon-in-progress {
          position: absolute;
          width: 100%;
          height: 2.875rem;
          top: 1rem;
          @if $isWsm {
            background: linear-gradient(
              to left,
              rgba(210, 148, 32, 0.1) 0%,
              #be9949,
              #cb9f3f,
              #cb9f3f,
              rgba(255, 232, 142, 0.1) 100%
            );
          }
          @if $isSsm {
            background: linear-gradient(
              to right,
              rgba(209, 192, 118, 0.01),
              rgba(208, 188, 114, 0.7),
              #e0c572,
              #c7a655,
              rgba(169, 134, 53, 0.7),
              rgba(164, 126, 40, 0.01)
            );
          }
          &:before,
          &:after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 0.063rem;
            @if $isWsm {
              background: linear-gradient(90deg, transparent, #fff, transparent);
            }
            @if $isWsm {
              background: linear-gradient(90deg, transparent, rgba(244, 233, 167, 1), transparent);
            }
          }

          &:before {
            top: 0;
          }

          &:after {
            bottom: 0;
          }
        }
      }

      .star-container {
        width: 100%;
        height: 3rem;
        bottom: -1.5rem;
      }

      .point {
        height: 0.125rem;
        width: 12.85rem;
        position: absolute;
        top: 50%;
        right: -13rem;
        transform: $skewX-revert-value;

        &:before {
          content: '';
          border-radius: 50%;
          background: #fff;
          position: absolute;
          width: 0.625rem;
          height: 0.625rem;
          right: -0.3rem;
          top: -0.2rem;
        }

        &:after {
          content: '';
          background: #fff;
          position: absolute;
          width: 0.125rem;
        }
      }
    }

    .normal {
      @if $isWsm {
        background: linear-gradient(10deg, #305e7f 25%, #234863 75%);
        border: 0.125rem solid rgb(82, 142, 191);
      }
      @if $isSsm {
        background: linear-gradient(to right, #34415d, #273249);
        border: 0.125rem solid #586b9d;
      }

      .point {
        background: linear-gradient(to left, #ffffff, #4883ae);

        &:after {
          right: 0;
        }
      }
    }

    .premium {
      @if $isWsm {
        background: linear-gradient(190deg, rgb(153 28 232 / 70%) 15%, rgba(155, 92, 213, 0.6) 85%),
          linear-gradient(200deg, rgb(46, 43, 43) 15%, rgba(255, 255, 255, 0.4) 85%),
          linear-gradient(
            160deg,
            rgb(255, 255, 255) 30%,
            rgb(144, 68, 200) 65%,
            rgb(255, 255, 255) 85%
          );
        border: 0.125rem solid #9b5cd5;
        box-shadow:
          inset 0 0 0.313rem rgb(145 79 206 / 70%),
          0 0 0.313rem rgb(145 79 206 / 70%);
      }
      @if $isSsm {
        background: linear-gradient(to right, #b072e7, #7f26b8);
        border: 0.125rem solid #9b5cd5;
        box-shadow: 0 0 0.625rem 0 rgba(155, 92, 213, 0.8);
      }

      .point {
        background: linear-gradient(to right, #ffffff, #9a5ad5);
        left: -13rem;

        &:after {
          left: -0.1rem;
        }

        &:before {
          right: 12.7rem;
        }
      }
    }

    & &-fully {
      @if $isWsm {
        border: 0.125rem solid#fadd1e;
      }
      @if $isSsm {
        border: 0.125rem solid #feb942;
      }
    }

    & &-progress-bg {
      background: #234863;
    }

    .attribute-button-1 {
      top: 2rem;
      left: 3rem;

      .point {
        &:after {
          height: 6.5625rem;
          right: -0.1rem;
        }

        &:before {
          top: 6.3rem;
        }
      }
    }

    .attribute-button-2 {
      top: 11rem;
      left: 0;
    }

    .attribute-button-3 {
      top: 20.75rem;
      left: 0;

      .point {
        &:after {
          height: 4.1875rem;
          top: -4.2rem;
        }

        &:before {
          top: -4.1875rem;
        }
      }
    }

    .attribute-button-4 {
      top: 30.5rem;
      left: 3rem;

      .point {
        &:after {
          height: 6.5625rem;
          top: -6.4rem;
        }

        &:before {
          top: -6.5625rem;
        }
      }
    }

    .attribute-button-5 {
      top: 1.9rem;
      right: 3rem;

      .point {
        &:after {
          height: 7.5rem;
        }

        &:before {
          top: 7.5rem;
        }
      }
    }

    .attribute-button-6 {
      top: 10.9rem;
      right: 0;

      .point {
        &:after {
          height: 1.75rem;
        }

        &:before {
          top: 1.75rem;
        }
      }
    }

    .attribute-button-7 {
      top: 20.65rem;
      right: 0;

      .point {
        &:after {
          top: -0.9rem;
          height: 1rem;
        }

        &:before {
          top: -1.1rem;
        }
      }
    }

    .attribute-button-8 {
      top: 30.4rem;
      right: 3rem;

      .point {
        &:after {
          top: -6.4rem;
          height: 6.5625rem;
        }

        &:before {
          top: -6.4rem;
        }
      }
    }
  }
}
</style>
