import { OPERATION_DELIVERY, OPERATION_REPAIR, OPERATION_UPGRADE } from '@/globalVariables'

export const enum FRAMES_ANIMATION_TYPES {
  NormalFrameType = 'normal',
  ReverseFrameType = 'reverse',
}

export const enum CHECKPOINT_ACTION_TYPE {
  AnimationAction = 'animationAction',
  PauseAction = 'pauseAction',
}

export const enum ANIMATION_VARIATIONS_FRAMES {
  GenerateFrameNames = 'generateFrameNames',
  GeneratedFrameNames = 'generatedFrameNames',
}

export const enum BUILDING_LOCKED_TOOLTIP_POSITION {
  Top = 'top',
  Left = 'left',
  Right = 'right',
}

export const BUILDINGS = 'buildings'
export const REWARDED_ITEMS = 'rewardedItems'
export const OSRA_ITEMS = 'osraItems'

export const enum BUILDING_LOCKED_TOOLTIP_CONTAINER_NAMES {
  TitleTooltip = 'titleTooltip',
  SubtitleTooltip = 'subtitleTooltip',
  BackgroundTooltip = 'backgroundTooltip',
}

export const enum BUILDING_TITLE_CONTAINER_NAMES {
  TextTitle = 'textTitle',
  BackgroundTitleRight = 'backgroundTitleRight',
  BackgroundTitleLeft = 'backgroundTitleLeft',
}

export const enum BUBBLE_NOTIFICATION_CONTAINER_NAMES {
  TextNotification = 'bubbleNotificationContainerText',
}

export const enum BUILDING_REWARD_CONTAINER_NAMES {
  BackgroundReward = 'backgroundReward',
}

export const EQUIPMENT_OPERATION_MAPPING = {
  [OPERATION_REPAIR]: 'equipmentShop.repairing',
  [OPERATION_UPGRADE]: 'equipmentShop.upgrading',
  [OPERATION_DELIVERY]: 'equipmentShop.delivering',
}
