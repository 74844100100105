<template>
  <div id="club-championship-table" class="club-championship-table app-page-wrapper absolute">
    <clubs-championship-menu />
    <section class="w-full h-full safe-area">
      <section class="championship-table-wrapper">
        <header>
          <clubs-championship-table-header>
            <app-timer
              v-if="getRemainingTime > 0 && !recalculation"
              v-tippy="{
                theme: 'WSM',
                content: $t('starOfTheDay.timeToEnd'),
                placement: 'top',
              }"
              icon="refresh-md"
              :time="getRemainingTime"
              @countdown-action="recalculation = true"
            />
            <div v-else-if="recalculation">
              <p class="text-texts-standard-danger text-50 font-bold uppercase">
                {{ $t('club.recalculation') }}
              </p>
            </div>
          </clubs-championship-table-header>
        </header>
        <main class="w-full">
          <app-scrollbar width="100%" height="100%" scroll="y" slice="y">
            <div class="table-head-standard">
              <div
                v-for="(row, index) in headTables"
                :key="index"
                class="table-head-standard-row flexing"
                :style="{ width: row.widthCol }"
              >
                {{ row.text }}
                <!--   ak by sme chceli do hlavicky davat ikonky
              <app-main-icon
                v-if="row.icon"
                :icon-size="32"
                :icon-name="row.icon"
              /> -->
              </div>
            </div>
            <div class="table-standard">
              <div
                v-for="(row, index) in getDivision"
                :key="index"
                class="club-championship-table-table table-standard-row"
                :class="{ my: myClubId === row.club.id }"
              >
                <div
                  v-for="(col, key, number) in row"
                  :key="key"
                  class="table-standard-col"
                  :class="key !== 'club' ? 'flexing' : ''"
                  :style="{ width: headTables[number].widthCol }"
                >
                  <div v-if="key === 'position'" class="table-standard-col-position flexing">
                    <p class="text-32 font-bold" :class="{ 'text-texts-standard-dark': col <= 3 }">
                      {{ col }}
                    </p>
                  </div>
                  <app-club-name
                    v-else-if="key === 'club'"
                    class="club-name h-full px-4"
                    :name="col.name"
                    :country="col.country"
                    :club-id="col.id"
                    :club-logo-id="col.logoId"
                    :clubs-background-id="col.logoBgId"
                    :is-homepage-screen="false"
                  />

                  <rewards
                    v-else-if="key === 'rewards'"
                    reward-id="career-box-rewards"
                    :reward-data="col || []"
                    :icon-size="48"
                  />
                  <div v-else-if="key === 'progress'">
                    <div
                      v-if="col?.type === ADVANCES || col?.type === DESCENTS"
                      v-tippy="{
                        theme: 'WSM',
                        content:
                          col?.type === ADVANCES ? $t('club.promotion') : $t('club.relagation'),
                        placement: 'left',
                      }"
                      class="triangle"
                      :class="[col?.type === ADVANCES ? 'green triangle-up' : 'red triangle-down']"
                    />
                    <p v-else class="text-72">-</p>
                  </div>
                  <div v-else-if="col.icon" class="flexing">
                    <p class="text-32">
                      {{ col.count }}
                    </p>
                    <app-main-icon :icon-size="32" :icon-name="col.icon" />
                  </div>
                  <p v-else class="text-32">
                    {{ col }}
                  </p>
                </div>
              </div>
            </div>
          </app-scrollbar>
        </main>
      </section>
    </section>
  </div>
</template>

<script lang="ts">
import ClubsChampionshipMenu from '@/components/Club/Championship/ClubsChampionshipMenu.vue'
import ClubsChampionshipTableHeader from '@/components/Club/Championship/ClubsChampionshipTableHeader.vue'
import AppClubName from '@/components/GlobalComponents/AppClubName.vue'
import Rewards from '@/components/Rewards.vue'
import { ADVANCES, DESCENTS, MINIMUM_CLUB_MEMBERS_TO_UNLOCK } from '@/globalVariables'
import { useClubChampionshipStore } from '@/store/pinia/clubs/championshipStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import type { ClubChampionshipsDetail } from '@/interfaces/clubs/ClubChampionships'

interface HeadTable {
  text: string
  widthCol: string
  icon?: string
}

interface ComponentData {
  recalculation: boolean
  ADVANCES: typeof ADVANCES
  DESCENTS: typeof DESCENTS
  headTables: HeadTable[]
}

export default defineComponent({
  name: 'ClubsChampionshipTable',
  components: {
    ClubsChampionshipMenu,
    ClubsChampionshipTableHeader,
    AppClubName,
    Rewards,
  },
  data(): ComponentData {
    return {
      recalculation: false,
      ADVANCES,
      DESCENTS,
      headTables: [
        {
          text: this.$t('rankingsGroup.pos'),
          widthCol: '5rem',
        },
        {
          text: this.$t('rankingsGroup.club'),
          widthCol: '35rem',
        },
        {
          text: this.$t('club.clubPlayers'),
          widthCol: '15rem',
        },
        {
          text: this.$t('rankingsGroup.points'),
          widthCol: '15rem',
          icon: 'championship_points',
        },
        {
          text: this.$t('common.reward'),
          widthCol: '45rem',
        },
        {
          text: '',
          widthCol: '11rem',
        },
      ],
    }
  },
  computed: {
    ...mapState(useUserStore, {
      myClubId: 'getPlayerClubId',
      hasClub: 'getHasClub',
      isClubInChampionship: 'isClubInChampionship',
    }),
    ...mapState(useClubChampionshipStore, {
      getDetail: 'getDetail',
      getDivision: 'getDivision',
      getRemainingTime: 'getRemainingTime',
      getLeagues: 'getLeagues',
    }),
    ...mapState(useClubStore, {
      club: 'getClubInfo',
    }),
  },
  watch: {
    getDetail(detail: ClubChampionshipsDetail): void {
      this.loadDivision(detail.division.id)
    },
  },
  async created(): Promise<void> {
    if (this.hasClub) {
      await this.loadClubInfo()
    }

    if (
      this.isClubInChampionship &&
      this.hasClub &&
      this.club.members.length >= MINIMUM_CLUB_MEMBERS_TO_UNLOCK
    ) {
      this.loadDetail()
    } else {
      await this.loadLeagues()
      await this.loadDivision(this.getLeagues[0].divisionId)
    }
  },
  methods: {
    ...mapActions(useClubChampionshipStore, ['loadDetail', 'loadDivision', 'loadLeagues']),
    ...mapActions(useClubStore, {
      loadClubInfo: 'loadClubInfo',
    }),
  },
})
</script>

<style lang="scss" scoped>
.club-championship-table {
  .championship-table-wrapper {
    width: 98%;
    margin: 0 auto;

    main {
      height: 40rem;
    }

    .table-head-standard {
      width: 99.5%;
    }

    .table-standard {
      &-col {
        height: 100%;

        &-position {
          width: 100%;
          height: 100%;
          clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
          background: rgba(20, 56, 88, 0.5);
        }

        &:last-child {
          background: rgba(20, 56, 88, 0.5);
          clip-path: polygon(5% 0%, 100% 0%, 100% 100%, 0% 100%);
        }
      }

      &-row {
        color: theme('colors.texts.standard.default');

        &:nth-of-type(1) {
          .table-standard-col-position {
            background: linear-gradient(to top, #e9d27a, #f6c717);
          }
        }

        &:nth-of-type(2) {
          .table-standard-col-position {
            background: linear-gradient(to top, #8e8e8e, #dbdbdb);
          }
        }

        &:nth-of-type(3) {
          .table-standard-col-position {
            background: linear-gradient(to top, #b75a32, #ef9f73);
          }
        }
      }
    }

    .club-championship-table-table {
      width: 99.5%;
      height: 3.938rem;
      margin: 0.25rem 0;
      display: flex;
      align-items: center;
      flex-direction: row;
      overflow: hidden;
    }
  }
}
</style>
