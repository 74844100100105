<template>
  <header
    class="event-pass-detail-sub-header flexing w-full my-12 relative"
    :class="`tier-${$route.query?.tier}`"
    :data-event-theme="namespace"
  >
    <p class="flexing text-60 gradient-text-light font-bold italic uppercase">
      {{ $te('eventpass') }} - {{ $te('tier') }} {{ $route.query?.tier }}
    </p>
    <passes-info-element class="absolute top-0 right-14" />
  </header>
</template>

<script lang="ts">
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import PassesInfoElement from '../../PassesInfoElement.vue'

export default defineComponent({
  name: 'EventPass3DetailSubHeader',
  components: { PassesInfoElement },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
  },
})
</script>

<style lang="scss" scoped>
$tiers: (1, 2, 3);

[data-event-theme='frozen'] {
  @each $tier in $tiers {
    --events-passes-event-tier-#{$tier}-subheader-bg: url('#{$path-events}frozen-championship/passes/event-pass-tier-#{$tier}-header-bg.webp');
  }
}

[data-event-theme='autumn'] {
  @each $tier in $tiers {
    --events-passes-event-tier-#{$tier}-subheader-bg: url('#{$path-events}autumn-fair/passes/event-pass-tier-#{$tier}-header-bg.avif');
  }
}

.event-pass-detail-sub-header {
  height: 5.75rem;
  @include background(null, contain);

  @each $tier in $tiers {
    &.tier-#{$tier} {
      background-image: var(--events-passes-event-tier-#{$tier}-subheader-bg);
    }
  }
}
</style>
