import { defineStore } from 'pinia'

interface AdStoreState {
  isAdBlock: boolean
}

export const useAdStore = defineStore('adStore', {
  state: (): AdStoreState => ({
    isAdBlock: false,
  }),
  getters: {
    getIsAdBlock(): AdStoreState['isAdBlock'] {
      return this.isAdBlock
    },
  },
  actions: {
    setAdBlockState(value: boolean): void {
      this.isAdBlock = value
    },
  },
})
