<template>
  <div class="popup-template-header flex items-center">
    <p
      class="text-texts-standard-additional uppercase"
      :class="{
        'text-40 ml-4': $isWsm,
        'text-42 ml-8': $isSsm,
      }"
    >
      {{ titleText }}
    </p>
    <app-control-icon
      v-if="!hideClose"
      class="close-button cursor-pointer absolute right-20 flex items-center"
      control="hide"
      @click="$emit('close', false)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppComponentPopupHeader',
  props: {
    titleText: {
      type: String,
      required: true,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/popups.scss';
</style>
