<template>
  <div id="club-stadium" class="club-stadium flex flex-col app-page-wrapper absolute">
    <menu-component menu-type="club-stadium" :title="$t('club.clubArena')">
      <tippy theme="WSM" placement="left" max-width="50rem">
        <app-icon icon-name="info-70" />
        <template #content>
          <p v-html="getArenaInfoText" />
        </template>
      </tippy>
    </menu-component>
    <section class="flex w-full h-full safe-area">
      <main v-if="dataForUpgrade" class="flexing">
        <clubs-building-panel
          v-if="arenaData"
          :building-type="CLUBARENA"
          :data-for-upgrade="dataForUpgrade"
          @do-action="improveBuilding()"
        />
        <aside class="flex flex-col club-stadium-right bordered-1">
          <clubs-stadium-levels
            :stadium-levels-data="stadiumLevels"
            :current-level="currentLevel"
          />
          <clubs-stadium-income
            :reward-value="dataForUpgrade.currentValue"
            :reward-type="dataForUpgrade.type"
            :reward-interval="dataForUpgrade.time"
            :reward-level="dataForUpgrade.level"
            :seconds="stadiumIncome.seconds"
            @claim="claimReward"
          />
        </aside>
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import ClubsBuildingPanel from '@/components/Club/ClubsBuildingPanel.vue'
import ClubsStadiumIncome from '@/components/Club/ClubsStadiumIncome.vue'
import ClubsStadiumLevels from '@/components/Club/ClubsStadiumLevels.vue'
import { CLUBARENA } from '@/globalVariables'
import { useBuildingsStore } from '@/store/pinia/clubs/buildingStore'
import { useRewardStore } from '@/store/pinia/clubs/rewardStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import type {
  ClubBuildingsResponse,
  ClubBuildingLevel,
} from '@/interfaces/responses/club/ClubBuildingsResponses'
import type { ClubRewardResponse } from '@/interfaces/clubs/ClubRewards'
import type { ClubUpgradeData } from '@/interfaces/clubs/Clubs'
import type { Nullable } from '@/interfaces/utils'
import type { StadiumLevel } from '@/interfaces/responses/club/ClubBuildingsResponses'

interface StadiumIncome {
  level: number
  income: number
  timeInterval: number
  rewardType: string
  seconds: number
}
interface ComponentData {
  CLUBARENA: typeof CLUBARENA
}

export default defineComponent({
  name: 'ClubsStadium',
  components: {
    ClubsBuildingPanel,
    ClubsStadiumLevels,
    ClubsStadiumIncome,
  },
  data(): ComponentData {
    return {
      CLUBARENA,
    }
  },

  computed: {
    ...mapState(useRewardStore, ['getAllClubRewards']),
    ...mapState(useBuildingsStore, {
      getBuildings: 'getBuildings',
      getBuildingsIds: 'getBuildingsIds',
    }),
    currentLevel(): number {
      if (this.arenaData) {
        return this.arenaData.stats.level
      }
      return 0
    },
    dataForUpgrade(): Nullable<ClubUpgradeData> {
      if (this.arenaData) {
        const currentLevel = this.arenaData.levels.find(
          (level: ClubBuildingLevel): boolean => level.level === this.arenaData.stats.level,
        )
        const nextLevel =
          this.arenaData.stats.level < this.arenaData.levels.length
            ? this.arenaData.levels.find(
                (level: ClubBuildingLevel): boolean =>
                  level.level === this.arenaData.stats.level + 1,
              )
            : this.arenaData.levels.find(
                (level: ClubBuildingLevel): boolean => level.level === this.arenaData.levels.length,
              )
        return {
          name: CLUBARENA,
          level: this.arenaData.stats.level,
          maxLevel: this.arenaData.levels.length,
          currentValue: currentLevel.reward?.value ?? 0,
          type: currentLevel.reward?.type ?? '',
          nextValue: nextLevel.reward?.value ?? 0,
          price: nextLevel.parameters.upgrade_price ?? 0,
          time: currentLevel?.parameters?.frequency
            ? currentLevel?.parameters?.frequency * 3600
            : 0,
        }
      }
      return null
    },
    stadiumLevels(): StadiumLevel[] {
      const levels = []
      if (this.arenaData) {
        this.arenaData.levels.map((level: ClubBuildingLevel): number =>
          levels.push({
            level: level.level,
            income: level.reward?.value ?? 0,
            timeInterval: level.parameters?.frequency ? level.parameters?.frequency * 3600 : 0,
            rewardType: level.reward?.type ?? '',
          }),
        )
      }
      return levels
    },
    arenaData(): ClubBuildingsResponse {
      const buildings = this.getBuildings
      let stadium = null
      if (buildings) {
        stadium = this.getBuildings.find(
          (building: ClubBuildingsResponse): boolean => building.parameters.name === 'clubArena',
        )
      }
      return stadium
    },
    stadiumIncome(): StadiumIncome {
      let income = {
        level: 0,
        income: 0,
        timeInterval: 0,
        rewardType: '',
        seconds: 0,
      }
      if (this.stadiumLevels && this.currentLevel) {
        const actualLevel = this.stadiumLevels.find(
          (level: StadiumLevel): boolean => level.level === this.currentLevel,
        )
        const reward = this.getAllClubRewards.find(
          (rewardData: ClubRewardResponse): boolean =>
            rewardData.building_type.building_type_id === this.getBuildingsIds.clubArena,
        )
        income = {
          level: actualLevel.level,
          income: actualLevel.reward?.value ?? 0,
          timeInterval: actualLevel.parameters?.frequency
            ? actualLevel.parameters?.frequency * 3600
            : 0,
          rewardType: actualLevel.reward?.type ?? '',
          seconds: reward?.next_claim ?? 0,
        }
      }
      return income
    },
    getArenaInfoText(): string {
      return this.$replacePlaceholder(
        this.$replaceHtmlPlaceholders(
          this.$replacePlaceholder(this.$t('club.clubsArenaInfo'), '{level}', '{b} {level} {/b}'),
          'b',
          'text-texts-standard-important',
        ),
        '{level}',
        2,
      )
    },
  },
  async created(): Promise<void> {
    if (!this.getBuildingsIds?.clubhouse) {
      await this.loadBuildings()
    }
    this.loadBuildings({ buildingTypeId: this.getBuildingsIds.clubArena })
    this.loadAllClubRewards()
  },
  methods: {
    ...mapActions(useRewardStore, {
      loadAllClubRewards: 'loadAllClubRewards',
      claimBuildingRewards: 'claimBuildingRewards',
    }),
    ...mapActions(useBuildingsStore, ['loadBuildings', 'upgradeBuilding']),
    ...mapActions(useClubStore, {
      loadClubInfo: 'loadClubInfo',
    }),
    async improveBuilding(): Promise<void> {
      await this.upgradeBuilding({
        clubsBuildingId: this.arenaData.clubs_buildings_id,
        buildingTypeId: this.getBuildingsIds.clubArena,
      })
      this.loadClubInfo()
    },
    claimReward(): void {
      this.claimBuildingRewards({
        clubBuildingId: this.arenaData.clubs_buildings_id,
        buildingTypeId: this.getBuildingsIds.clubArena,
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.club-stadium {
  main {
    width: 98%;
    margin: 0 auto;
    gap: 1.25rem;

    .club-stadium-right {
      display: flex;
      flex-direction: column;
      height: 52.188rem;
      flex: 1 1 auto;

      @if $isWsm {
        background: #0c2844;
        border: 0.125rem solid #5ba3dc;
      }
      @if $isSsm {
        background: #23293e;
        border: 0.125rem solid #4c648f;
      }
    }
  }
}
</style>
