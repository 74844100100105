import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const landingHelicopterPath: PathObjectInterface = {
  mainPath: {
    x: 1684,
    y: -50,
  },
  nextPaths: [
    {
      x: 1860,
      y: 205,
      control1X: 1764,
      control1Y: 219,
      control2X: 1764,
      control2Y: 219,
    },
    {
      x: 1860,
      y: 205,
    },
    {
      x: 1873,
      y: 205,
      control1X: 1867,
      control1Y: 203,
      control2X: 1867,
      control2Y: 203,
    },
    {
      x: 1873,
      y: 258,
    },
    {
      x: 1873,
      y: -10,
    },
  ],
}
