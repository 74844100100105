import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const clubCarsRightToParkingPath: PathObjectInterface = {
  mainPath: { x: 2795, y: 1041 },
  spline: [
    2795, 1041, 2838, 1097, 2874, 1131, 2916, 1166, 2968, 1213, 2996, 1238, 3036, 1288, 3062, 1333,
    3074, 1369, 3070, 1414, 3054, 1460, 3035, 1499, 2997, 1535, 2944, 1562, 2896, 1578, 2860, 1583,
    2796, 1566, 2746, 1546, 2698, 1525, 2638, 1505, 2599, 1493, 2559, 1482, 2514, 1466, 2479, 1461,
    2445, 1463, 2385, 1468, 2323, 1475, 2262, 1488, 2198, 1503, 2153, 1514, 2117, 1520, 2089, 1520,
    2037, 1517, 1973, 1509, 1913, 1497, 1829, 1486, 1786, 1479, 1721, 1473, 1691, 1470,
  ],
}
