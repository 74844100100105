import type {
  BuildingHtmlTextConfigInterface,
  BuildingClLabelInfoConfigInterface,
  BuildingLabelInfoInterface,
} from '@/map-phaser-new/interfaces'

export class BuildingLabelChampionship {
  private labelContainer: Phaser.GameObjects.Container | null

  constructor(
    private positionX: number,
    private positionY: number,
  ) {}

  public addToBuilding(
    activeScene: Phaser.Scene,
    infoData: BuildingLabelInfoInterface,
    configData: BuildingClLabelInfoConfigInterface,
  ): void {
    this.createLabelContainer(activeScene, infoData, configData)
  }

  public removeLabel(): void {
    if (!this.labelContainer) return

    this.labelContainer.removeAll(true)
    this.labelContainer.destroy()
    this.labelContainer = null
  }

  private createLabelContainer(
    activeScene: Phaser.Scene,
    infoData: BuildingLabelInfoInterface,
    configData: BuildingClLabelInfoConfigInterface,
  ): void {
    this.labelContainer = activeScene.add
      .container(this.positionX, this.positionY)
      .setDepth(configData.depth)

    const backgroundImage = activeScene.add.image(
      0,
      0,
      configData.texture,
      configData.backgroundFrame,
    )
    const arrowType = infoData.arrowType ?? configData.arrowLineFrame
    const arrowImage = activeScene.add
      .image(
        configData.arrowX,
        configData.arrowY,
        configData.texture,
        `${arrowType}${configData.arrowFramePostfix}`,
      )
      .setOrigin(configData.arrowOriginX)
    const badgeImage = activeScene.add
      .image(
        configData.badgeX,
        configData.badgeY,
        configData.texture,
        `${infoData.badgeType}${configData.badgeFramePostfix}`,
      )
      .setOrigin(configData.badgeOriginX)
    const text = this.addTitleText(activeScene, configData.htmlTextConfig, infoData.position)
    this.labelContainer.add([backgroundImage, arrowImage, badgeImage, text])
  }

  private addTitleText(
    activeScene: Phaser.Scene,
    config: BuildingHtmlTextConfigInterface,
    title: string,
  ): Phaser.GameObjects.DOMElement {
    return activeScene.add
      .dom(
        0,
        0,
        'span',
        `color: ${config.fontColor}; font-size: ${config.fontSize}; font-family: ${config.fontName}; text-transform: uppercase; font-weight: ${config.fontWeight}; font-style: ${config.fontStyle}`,
        title,
      )
      .setOrigin(config.originX)
  }
}
