import { useUserStore } from '@/store/pinia/userStore'

interface ValidationTokenPayload {
  expiry: number
  value: string
}

export const getValidationToken = (key: string): string | null => {
  const itemStr = localStorage.getItem(key)
  const useUser = useUserStore()
  // TODO: HTML-security token on BE side
  // vytiahne na zaklade key hodnotu z local storage, pokial tam nie je, funkcia vracia null
  if (!itemStr) {
    useUser.setHasToken(false)
    return null
  }

  const { value, expiry } = JSON.parse(itemStr) as ValidationTokenPayload
  const now = new Date()

  // porovna sa aktualny datum s datumom expiracie, ktory je ulozeny v local storage
  // ak je mensi, zaznam sa vymaze a pouzivatel bude odhlaseny
  if (now.getTime() > expiry) {
    localStorage.removeItem(key)
    useUser.setHasToken(false)
    return null
  }

  useUser.setHasToken(true)
  // ak zaznam v local storage existuje a datum je vacsi ako datum expiracie,
  // funkcia vrati access_token
  return value
}
