<template>
  <div class="unlock-mechanic h-full w-full z-2">
    <level-up-title :texts="mechanics[mechanic].titleTexts" />
    <main class="unlock-mechanic-content flexing flex-col">
      <div
        ref="animation1"
        class="unlock-mechanic-logo animated-element--faded flexing"
        :class="mechanics[mechanic].className"
      >
        <div class="mechanic-logo"></div>
      </div>
      <div ref="animation2" class="animated-element--faded">
        <p class="text-texts-standard-default text-28">
          {{ $t(mechanics[mechanic].infoText) }}
        </p>
      </div>
    </main>
    <section class="unlock-mechanic-footer-wrapper w-full safe-area">
      <footer class="unlock-mechanic-footer flexing w-full">
        <app-button
          btn-size="md"
          btn-type="credit"
          :btn-text="$t('gamePass.gamePass')"
          add-class=" mr-8"
          :disabled="!isGamePassInitialized"
          @click="goToOffer"
        />
        <app-button
          btn-size="md"
          btn-type="secondary"
          :btn-text="$t('button.continue')"
          @click="$emit('close')"
        />
      </footer>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { pathImages, MECHANIC_GAME_PASS } from '@/globalVariables'
import { animateElement, skipAnimationOnClick } from '@/helpers'
import LevelUpTitle from '@/components/LevelUp/LevelUpTitle.vue'
import { useGamePassStore } from '@/store/pinia/gamePassStore'
import { mapState } from 'pinia'

interface Mechanics {
  [key: string]: {
    titleTexts: {
      first: string
      second: string
      third: string
    }
    className: string
    infoText: string
  }
}
interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'LevelUpUnlockMechanic',
  components: {
    LevelUpTitle,
  },
  props: {
    mechanic: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    ...mapState(useGamePassStore, ['isGamePassInitialized']),
    mechanics(): Mechanics {
      return {
        [MECHANIC_GAME_PASS]: {
          titleTexts: {
            first: 'common.congratulations',
            second: 'common.youUnlocked',
            third: 'gamePass.gamePass',
          },
          className: 'game-pass',
          infoText: 'gamePass.unlockScreenInfo',
        },
      }
    },
  },
  mounted(): void {
    this.animateScene()

    // skip animation on click
    window.addEventListener('click', this.handleSkipAnimationClick)
  },
  beforeUnmount(): void {
    window.removeEventListener('click', this.handleSkipAnimationClick)
  },
  methods: {
    animateScene(): void {
      animateElement(
        this.$refs.animation1,
        'fade-in, slide-in-to-right',
        '1s',
        200,
        'cubic-bezier(.77,.01,.51,1)',
      )
      animateElement(
        this.$refs.animation2,
        'fade-in, slide-in-to-right',
        '1s',
        300,
        'cubic-bezier(.77,.01,.51,1)',
      )
    },
    handleSkipAnimationClick(e: MouseEvent): void {
      if ((e.target as HTMLElement).id === 'level-up-btn') return

      // it is necessary to use querySelector,
      // ref is allocated only for animated component objects
      const el = this.$el.querySelector('.unlock-mechanic-content')
      skipAnimationOnClick(e, el, this.$refs, !!this.mechanic, null)
    },
    goToOffer(): void {
      this.$emit('close')
      if (!this.isGamePassInitialized) return

      if (this.mechanic === MECHANIC_GAME_PASS) {
        this.$router.push({ name: this.$getWebView('PremiumGamePass') })
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.unlock-mechanic {
  position: relative;
  &-content {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  &-logo {
    &.game-pass {
      background-image: url($path-premium + 'gamePass/levelUp/logo-effect.avif');
      background-size: contain;
      width: 67.813rem;
      height: 39.438rem;
      .mechanic-logo {
        background-image: url($path-premium + 'gamePass/levelUp/game-pass-logo.avif');
        background-size: contain;
        width: 34.063rem;
        height: 15.063rem;
      }
    }
  }
  &-footer {
    &-wrapper {
      position: absolute;
      bottom: 2.5rem;
      height: 4.375rem;
    }
  }
}
</style>
