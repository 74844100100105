<template>
  <div class="lab-camps-col relative flexing flex-col">
    <div class="text-32 text-texts-standard-default flexing relative left-16">
      <p>
        {{ activeResearchCount }} / <span class="text-texts-standard-important font-bold">1</span>
      </p>
    </div>
    <section class="relative top-8">
      <lab-camps-cell
        v-for="(cell, i) in columnData"
        :key="i"
        class="absolute"
        :style="{
          top: `${VERTICAL_SPACING * i}rem`,
        }"
        :cell-data="cell"
        :line-type="i === 0 ? LineHeight.Xs : LineHeight.Sm"
        @select-skill="$emit('selectSkill', $event)"
      />
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LabCampsCell from '@/components/Lab/Camps/LabCampsCell.vue'
import type { PropType } from 'vue'
import type { Cell } from '@/interfaces/lab/Cell'
import { LineHeight } from '@/interfaces/lab/Cell'

const VERTICAL_SPACING = 12.75
interface ComponentData {
  LineHeight: typeof LineHeight
  VERTICAL_SPACING: typeof VERTICAL_SPACING
}

export default defineComponent({
  name: 'LabCampsColumn',
  components: {
    LabCampsCell,
  },
  props: {
    columnData: {
      type: Array as PropType<Cell[]>,
      required: true,
    },
    activeResearch: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectSkill', 'reloadData'],
  data(): ComponentData {
    return {
      LineHeight,
      VERTICAL_SPACING,
    }
  },
  computed: {
    activeResearchCount(): number {
      return +this.activeResearch
    },
  },
})
</script>

<style lang="scss" scoped>
.lab-camps-col {
  left: -4.5rem;
}
</style>
