<template>
  <info-message :info-message-data="popupObject">
    <app-user :country="user.country" :name="user.name" />
    <div class="flexing">
      <span v-if="user.role === CLUB_MANAGER" class="icon-star-full-32" />
      <p :class="[`text-texts-standard-${popupObject.footStyle}`, 'text-28']">
        {{ popupObject.footText }}
      </p>
    </div>
  </info-message>
</template>

<script lang="ts">
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import InfoMessage, { type InfoMessageData } from '@/components/InfoMessage.vue'
import { CLUB_MANAGER } from '@/globalVariables'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { ClubUserPromoted } from '@/interfaces/clubs/ClubManagement'

interface ComponentData {
  CLUB_MANAGER: typeof CLUB_MANAGER
}

export default defineComponent({
  name: 'ClubsManagementPopup',
  components: {
    InfoMessage,
    AppUser,
  },
  props: {
    user: {
      type: Object as PropType<ClubUserPromoted['user']>,
      required: true,
    },
    isPromoted: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      CLUB_MANAGER,
    }
  },
  computed: {
    // TODO doriesit default
    popupObject(): InfoMessageData {
      const popupData = {} as InfoMessageData
      const roleToCompare = this.isPromoted ? `promoted_${this.user.role}` : this.user.role
      switch (roleToCompare) {
        case 'manager':
          popupData.headerText = this.$t('club.memberFunction')
          popupData.headStyle = 'additional'
          popupData.infoText = this.$t('club.playerAppointedManager')
          popupData.hasDecoration = false
          popupData.footText = this.$t('club.manager')
          popupData.footStyle = 'important'
          break
        case 'assistant':
          popupData.headerText = this.$t('club.memberFunction')
          popupData.headStyle = 'additional'
          popupData.infoText = this.$t('club.playerAppointedAssistant')
          popupData.hasDecoration = false
          popupData.footText = this.$t('club.assistant')
          popupData.footStyle = 'bronze'
          break
        case 'member':
          popupData.headerText = this.$t('club.memberFunction')
          popupData.headStyle = 'additional'
          popupData.infoText = this.$t('club.playerAppointedMember')
          popupData.hasDecoration = false
          popupData.footText = this.$t('club.member')
          popupData.footStyle = 'name'
          break
        case 'promoted_manager':
        case 'promoted_assistant':
          if (this.user.role === CLUB_MANAGER) {
            popupData.infoText = this.$t('club.newManager')
            popupData.footText = this.$t('club.manager')
          } else {
            popupData.infoText = this.$t('club.newAssistant')
            popupData.footText = this.$t('club.assistant')
          }
          popupData.headerText = this.$t('common.congratulations')
          popupData.headStyle = 'important'
          popupData.hasDecoration = true
          popupData.footStyle = 'important'
          break
      }
      return popupData
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/stars-icons.scss';
</style>
