<template>
  <div class="board-container w-full h-full safe-area">
    <div class="board w-full h-full flex flex-col">
      <div class="board__header">
        <board-header @set-section="setBoardComponent" @show-popup="showPopup = true" />
      </div>
      <div class="board__content">
        <component :is="boardComponent" v-if="!isLoading" />
        <component-loading :is-loading="isLoading" />
      </div>
    </div>

    <info-popup
      v-if="showPopup"
      :popup-title="$te('autumnFair')"
      class="board-popup"
      width="76rem"
      @close="showPopup = false"
    >
      <div class="board-popup__content">
        <app-scrollbar width="100%" height="100%" scroll="y" slice="y">
          <header
            class="board-popup-header text-texts-standard-default text-30 flex items-center text-left"
          >
            <app-icon icon-name="info-rounded" class="mr-5" />
            <p class="board-popup-header-text">
              {{ $te('noticeBoardCTA') }}
            </p>
          </header>
          <section class="board-popup-content flex flex-col text-32 text-texts-standard-default">
            <div class="board-popup-about-title flexing">
              <span class="pt-1.5 text-50 font-bold gradient-text-gold uppercase">
                {{ $te('noticeBoard') }}
              </span>
            </div>
            <ul class="board-popup-feature-text-list">
              <li
                v-for="text in noticeBoardTexts"
                :key="text"
                class="text-32 mb-8 board-popup-content-text text-left relative"
              >
                {{ $replacePlaceholder($replacePlaceholder($te(text), '%s', 10), '{2to4}', 4) }}
              </li>
            </ul>
            <div class="board-popup-about-title flexing">
              <span class="pt-1.5 text-50 font-bold gradient-text-gold uppercase">
                {{ $te('autumnLeague') }}
              </span>
            </div>
            <ul class="board-popup-feature-text-list">
              <li
                v-for="text in autumnLeagueTexts"
                :key="text"
                class="text-32 mb-8 board-popup-content-text text-left relative"
              >
                {{ $replacePlaceholder($replacePlaceholder($te(text), '%s', 10), '{2to4}', 4) }}
              </li>
            </ul>
          </section>
        </app-scrollbar>
      </div>
    </info-popup>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BoardHeader from '@/components/Events/BoardComponents/BoardHeader.vue'
import BountyBoardMain from '@/components/Events/BoardComponents/BountyBoard/BountyBoardMain.vue'
import BountyLeagueMain from '@/components/Events/BoardComponents/BountyLeague/BountyLeagueMain.vue'
import { BoardMainComponent } from '@/interfaces/events/Board'
import InfoPopup from '@/components/Popup/InfoPopup.vue'

interface ComponentData {
  isLoading: boolean
  showPopup: boolean
  boardComponent: BoardMainComponent
  noticeBoardTexts: string[]
  autumnLeagueTexts: string[]
}

export default defineComponent({
  name: 'BoardMain',
  components: {
    BoardHeader,
    BountyBoardMain,
    BountyLeagueMain,
    InfoPopup,
  },
  data(): ComponentData {
    return {
      isLoading: false,
      showPopup: false,
      boardComponent: BoardMainComponent.BountyBoard,
      noticeBoardTexts: Array.from(
        { length: 6 },
        (_: undefined, i: number): string => `noticeBoardInfo${i + 1}`,
      ),
      autumnLeagueTexts: Array.from(
        { length: 5 },
        (_: undefined, i: number): string => `autumnLeagueInfo${i + 1}`,
      ),
    }
  },
  methods: {
    setBoardComponent({ component }: { component: BoardMainComponent }): void {
      this.boardComponent = component
    },
  },
})
</script>

<style lang="scss" scoped>
.board {
  &-container {
    flex: 1 1 auto;
    min-height: 0;
  }

  &__content {
    width: 98%;
    margin: 0 auto;
    flex: 1 1 auto;
    min-height: 0;
    padding-bottom: 1.25rem;
  }

  &-popup {
    &__content {
      height: 35.375rem;

      .board-popup {
        &-header {
          height: 7.75rem;
          background: #0b1c30;
          margin-bottom: 1.25rem;
          padding: 0 1.5rem;
        }

        &-about-title {
          position: relative;
          width: 100%;
          height: 5.188rem;
          margin: 0.25rem 0 1.375rem;
          background: linear-gradient(
            to right,
            transparent,
            rgba(11, 28, 47, 0.7) 25%,
            rgba(11, 28, 47, 1),
            rgba(11, 28, 47, 1),
            rgba(11, 28, 47, 0.7) 75%,
            transparent
          );

          &::before,
          &::after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 0.125rem;
            background: linear-gradient(
              to right,
              transparent,
              rgba(49, 80, 107, 0.7) 25%,
              rgba(49, 80, 107, 1),
              rgba(49, 80, 107, 1),
              rgba(49, 80, 107, 0.7) 75%,
              transparent
            );
          }

          &::before {
            top: 0;
          }

          &::after {
            bottom: 0;
          }
        }
      }
    }

    &-feature-text-list {
      padding-left: 5.313rem;
      padding-right: 2rem;
      list-style: square;
    }
  }
}
</style>
