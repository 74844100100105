<template>
  <div class="authentificator app-page-wrapper absolute" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { emailVerification as EMAIL_VERIFICATION_ENDPOINT } from '@/globalVariables'
import { mapActions, mapState } from 'pinia'
import { useAccountStore } from '@/store/pinia/accountStore'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'

interface ComponentData {
  token: string
}

export default defineComponent({
  name: 'AccountVerificator',
  data(): ComponentData {
    return {
      token: (this.$route.query.token as string) || '',
    }
  },
  computed: {
    ...mapState(useAccountStore, ['hasVerifiedAccount']),
  },
  async mounted(): Promise<void> {
    try {
      await this.$axios.post<{}, any>(EMAIL_VERIFICATION_ENDPOINT, {
        token: this.token,
        platform: 'web',
      })
      await this.loadConnectedAccounts(true)

      if (this.hasVerifiedAccount) this.removeConnectAccountOsra()
    } catch (error: unknown) {
      console.error(error)
    } finally {
      // Web URL redirect
      this.$router.push({ name: 'CrossAccountLinkingWeb' })
    }
  },
  methods: {
    ...mapActions(usePhaserGameIntegrationStore, ['removeConnectAccountOsra']),
    ...mapActions(useAccountStore, ['loadConnectedAccounts']),
  },
})
</script>

<style lang="scss" scoped>
.authentificator {
  width: 100%;
  height: 100%;
  background: #fff;
}
</style>
