<template>
  <template v-if="prefix">{{ prefix }}</template>
  <tippy v-if="link" theme="WSM1" placement="bottom" max-width="40rem">
    <span class="underline">{{ link }}</span>
    <template #content>
      <div class="lock-tooltip">
        <p class="lock-tooltip-title flexing text-32 font-semibold uppercase">
          {{ $t('homepage.PPSAccount') }}
        </p>
        <p class="lock-tooltip-text-margin">
          {{ $t('homepage.PPSAccountInfo') }}
        </p>
        <div class="flexing p-8 pt-4 space-x-8">
          <div class="w-1/4 relative">
            <app-icon
              v-if="games.has(GameId.WinterSports)"
              icon-name="done-sm"
              class="absolute -top-2 -right-2"
            />
            <img
              src="https://appspowerplaymanager.vshcdn.net/images/winter-sports/homepage/LOGO_WSM_LOGIN_NEW.avif"
              alt="WSM"
            />
          </div>
          <div class="w-1/4 relative">
            <app-icon
              v-if="games.has(GameId.SummerSports)"
              icon-name="done-sm"
              class="absolute -top-2 -right-2"
            />
            <img
              src="https://appspowerplaymanager.vshcdn.net/images/summer-sports/homepage/LOGO_SSM_LOGIN_NEW.avif"
              alt="SSM"
            />
          </div>
        </div>
      </div>
    </template>
  </tippy>
  <template v-if="suffix">{{ suffix }}</template>
</template>

<script lang="ts">
import { GameId } from '@/globalVariables'
import AppIcon from '../GlobalComponents/AppIcon.vue'
import { defineComponent, type PropType } from 'vue'

const SPLIT_TEXT_REGEXP = /^(?<prefix>.*?)\{link\}(?<link>.*?)\{\/link\}(?<suffix>.*?)$/

interface ComponentData {
  GameId: typeof GameId
}

export default defineComponent({
  components: {
    AppIcon,
  },
  props: {
    text: {
      type: String,
      required: true,
      validator: (value: string): boolean => SPLIT_TEXT_REGEXP.test(value),
    },
    games: {
      required: true,
      type: Set as PropType<Set<GameId>>,
    },
  },
  data(): ComponentData {
    return {
      GameId,
    }
  },
  computed: {
    groups(): Record<string, string> {
      return SPLIT_TEXT_REGEXP.exec(this.text)?.groups || {}
    },
    prefix(): string {
      return this.groups.prefix
    },
    link(): string {
      return this.groups.link
    },
    suffix(): string {
      return this.groups.suffix
    },
  },
})
</script>

<style lang="scss" scoped></style>
