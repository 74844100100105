<template>
  <div
    class="arena-competitions-footer-supermatch"
    :class="{ 'arena-competitions-footer-supermatch--tutorial': isTutorialStep }"
  >
    <span class="flexing h-full">
      <arrow-animation position="right" border="button" :tutorial="isTutorialStep">
        <super-multiplier
          :multiplier-type="type === ARENA ? Multiplier.Arena : Multiplier.ClubChampionship"
          @switch-match-type="$emit('switchMatchType')"
        />
      </arrow-animation>
    </span>
  </div>
</template>

<script lang="ts">
import { ARENA, CLUB_CHAMPIONSHIP, Multiplier } from '@/globalVariables'
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import ArrowAnimation from '@/components/ArrowAnimation.vue'
import SuperMultiplier from '@/components/GamePass/SuperMultiplier.vue'

import type { PropType } from 'vue'

interface ComponentData {
  ARENA: typeof ARENA
  Multiplier: typeof Multiplier
}

export default defineComponent({
  name: 'ArenaCompetitionsFooterSupermatch',
  components: {
    ArrowAnimation,
    SuperMultiplier,
  },
  props: {
    type: {
      type: String as PropType<typeof ARENA | typeof CLUB_CHAMPIONSHIP>,
      default: ARENA,
      validator(value: string): boolean {
        return [ARENA, CLUB_CHAMPIONSHIP].includes(value)
      },
    },
  },
  emits: ['switchMatchType'],
  data(): ComponentData {
    return {
      ARENA,
      Multiplier,
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
    }),
    isTutorialStep(): boolean {
      return this.actualStageData?.name === 'clickOnSuperCompetitionButton'
    },
  },
})
</script>

<style lang="scss" scoped>
.arena-competitions-footer-supermatch {
  // tutorial: safe layering
  z-index: 0;

  &--tutorial {
    z-index: 2;
  }
}
</style>
