<template>
  <header class="flex text-texts-standard-default text-32 w-full relative items-center">
    <div class="bg-light flex items-center">
      <app-main-icon class="header-icon" :icon-name="paramType.icon" :icon-size="152" />
    </div>
    <p class="header-text">
      {{ $t(paramType.initialText) }}
    </p>
    <div class="roll-icon cursor-pointer" @click="showDropdown = !showDropdown">
      <div :class="{ active: showDropdown }" class="icon-arr" />
    </div>
    <div v-show="showDropdown" class="dropdown absolute w-full">
      <section class="m-auto flexing">
        <div class="center-bg flex items-center text-36">
          <app-icon :icon-name="'time'" class="absolute time" />
          <app-main-icon :icon-name="paramType.icon" :icon-size="56" />
          <animated-number
            :param="getParamData(paramType.value)"
            class="text-texts-standard-default text-36"
          />
        </div>
      </section>
      <div class="dropdown-footer flex w-full">
        <div class="dropdown-footer-section h-full">
          <p
            class="text-32 text-texts-standard-default dropdown-text m-auto"
            v-html="
              $replaceHtmlPlaceholders(
                $t(paramType.rechargeInfoLimitText),
                'b',
                'font-bold text-texts-standard-upgrade',
              )
            "
          />
          <div class="dropdown-info m-auto text-28 flexing">
            <p class="text-texts-standard-danger">
              -{{ getParamData(paramType.value)?.refresh_amount?.toString().replace('-', '') }}
            </p>
            <app-main-icon :icon-name="paramType.icon" :icon-size="32" />
            <p>
              {{
                $replacePlaceholder(
                  everyMinuteText,
                  everyMinuteReplacemet,
                  paramType.autoTransactionInterval,
                )
              }}
            </p>
          </div>
        </div>
        <div class="dropdown-footer-section h-full">
          <p
            class="text-32 text-texts-standard-default dropdown-text m-auto"
            v-html="
              $replaceHtmlPlaceholders(
                $t(paramType.rechargeInfoText),
                'b',
                'font-bold text-texts-standard-danger',
              )
            "
          />
          <div class="dropdown-info m-auto text-28 text-texts-standard-default flexing">
            <p class="text-texts-standard-upgrade">
              +{{ getParamData(paramType.value)?.refresh_amount?.toString().replace('-', '') }}
            </p>
            <app-main-icon :icon-name="paramType.icon" :icon-size="32" />
            <p>
              {{
                $replacePlaceholder(
                  everyMinuteText,
                  everyMinuteReplacemet,
                  paramType.autoTransactionInterval,
                )
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import AnimatedNumber from '@/components/AnimatedNumber.vue'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { HeaderParam, ParamType } from '@/interfaces/HeaderParams'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  showDropdown: boolean
}

export default defineComponent({
  name: 'ParameterPopupSubHeader',
  components: {
    AnimatedNumber,
  },
  props: {
    paramType: {
      type: Object as PropType<Nullable<HeaderParam>>,
      default: () => null,
    },
  },
  data: (): ComponentData => {
    return {
      showDropdown: false,
    }
  },
  computed: {
    everyMinuteText(): string {
      return this.$replacePlaceholder(this.$t(this.paramType.everyMinuteText), '{amount}', '')
    },
    everyMinuteReplacemet(): string {
      if (this.everyMinuteText.includes('{minutes}')) return '{minutes}'
      return '{2to4}'
    },
    ...mapState(useResponseTaskStore, {
      headerData: 'getHeaderParams',
    }),
  },
  methods: {
    getParamData(paramName: string): ParamType {
      return this.headerData.find((item: ParamType): boolean => item.parameter_name === paramName)
    },
  },
})
</script>

<style lang="scss" scoped>
header {
  height: 7.5rem;
  position: relative;

  @if $isWsm {
    background-color: #0c1e36;
    box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.5);
  }

  @if $isSsm {
    background-color: #1f2438;
  }

  .bg-light {
    position: absolute;
    left: 0;
    top: -4rem;
    background: url($path-components + 'header/BG_LIGHT_SHINE.avif') center no-repeat;
    background-size: contain;
    width: 27.5rem;
    height: 19.4375rem;
    z-index: 3;

    .header-icon {
      margin-left: 3.4rem;
      margin-top: -4.2rem;
    }
  }

  .header-text {
    width: 49.1875rem;
    margin-left: 15.5rem;
    text-align: left;
  }

  .roll-icon {
    position: absolute;
    right: 1rem;
    transform: $skewX-value;
    width: 5.6875rem;
    height: 5rem;

    @if $isWsm {
      background-image: linear-gradient(to bottom, #112437, #1b4d6d);
      border: solid 0.125rem #315f7f;
    }

    @if $isSsm {
      background-image: linear-gradient(to top, #373e5d 0%, #232a40 100%);
      border: solid 0.063rem #7477a4;
    }

    .icon-arr {
      width: 2.625rem;
      height: 2.625rem;
      position: absolute;
      top: 35%;
      left: 51%;
      transform: translate(-50%, -50%) rotate(45deg);
      border-right: 0.3rem solid #fff;
      border-bottom: 0.3rem solid #fff;
      box-shadow: inset -1px -1px 1px #00000099;

      @if $isWsm {
        filter: drop-shadow(0px 0px 4px white);
      }

      &.active {
        transform: translate(-50%, 0%) rotate(-135deg);
      }
    }
  }

  .dropdown {
    height: 20.625rem;
    bottom: -21rem;
    z-index: 2;

    @if $isWsm {
      background-color: #0c1e36;
    }

    @if $isSsm {
      background-color: #1f2438;
    }

    section {
      width: 77.3125rem;
      height: 4.5rem;

      @if $isWsm {
        background-image: linear-gradient(to right, transparent, #71c2ff54, transparent);
      }

      @if $isSsm {
        background-image: linear-gradient(
          to right,
          transparent,
          rgba(198, 207, 255, 0.3),
          transparent
        );
      }

      .center-bg {
        position: relative;
        background: linear-gradient(to right, transparent, #010b1c, transparent);
        height: 2.5625rem;

        .time {
          transform: scale(0.5);
          left: -1.5rem;
          top: 1rem;
        }
      }
    }

    .dropdown-footer {
      height: 12.5rem;
      margin-top: 2rem;

      &-section {
        width: 41.0625rem;

        @if $isWsm {
          border-right: 0.063rem solid #a0dcff;
        }

        @if $isSsm {
          border-right: 0.063rem solid rgba(114, 114, 176, 0.6);
        }

        &:nth-child(2) {
          border: none;
        }

        .dropdown-text {
          width: 35.625rem;
          height: 8.4375rem;
        }

        .dropdown-info {
          margin-top: 0.8rem;
          width: 17.5rem;
          height: 3.125rem;
          background: linear-gradient(
            to right,
            rgba(108, 158, 196, 0),
            #71c2ff54,
            rgba(108, 158, 196, 0)
          );
        }
      }
    }
  }
}
</style>
