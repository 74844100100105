import { defineStore } from 'pinia'

interface TextStoreState {
  textsLoaded: boolean
}

export const useTextsStore = defineStore('textStore', {
  state: (): TextStoreState => ({
    textsLoaded: false,
  }),
  getters: {
    isTextsLoaded(): TextStoreState['textsLoaded'] {
      return this.textsLoaded
    },
  },
  actions: {
    setLoadedTexts(textsLoaded: boolean): void {
      this.textsLoaded = textsLoaded
    },
  },
})

export type TextsStore = ReturnType<typeof useTextsStore>
