<template>
  <div class="flex items-center">
    <section
      class="training-discipline-footer-pass-active whitespace-nowrap uppercase text-34 text-texts-standard-default flex skew items-center"
    >
      <p class="revert-skew">
        {{ $replacePlaceholder($t('event.eventPassActive'), '{pass}', $t(passName)) }}
      </p>
    </section>
    <app-timer
      v-if="timerSeconds > 0"
      :time="timerSeconds"
      add-class="training-discipline-footer-pass-active-timer text-34 normal-case"
      @countdown-action="timerFinishedCallback"
    />
  </div>
</template>

<script lang="ts">
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TrainingDisciplineFooterPassActive',
  props: {
    passName: {
      type: String,
      default: '',
    },
    timerSeconds: {
      type: Number,
      default: 0,
    },
  },
  emits: ['timerFinished'],
  methods: {
    ...mapActions(useUserStore, ['loadUserData']),
    timerFinishedCallback(): void {
      this.loadUserData()
      this.$emit('timerFinished')
    },
  },
})
</script>

<style lang="scss" scoped>
.training-discipline-footer-pass-active {
  width: 32.5rem;
  height: 4rem;
  background-image: linear-gradient(to right, #8c00b2, transparent 95%);
  border: 0.188rem solid;
  border-right: 0;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #cf07f9, transparent 95%);
  padding-left: 2rem;

  &-timer {
    position: absolute;
    right: -14rem;
    height: 3rem;
  }
}
</style>
