import {
  clubActivityEndpoint,
  clubChangeRoleEndpoint,
  clubDeleteMemberEndpoint,
  clubLeaveEndpoint,
  clubMembersInfoEndpoint,
} from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import type { Nullable } from '@/interfaces/utils'
import type {
  ClubActivityResponse,
  ClubMemberOperationApiResponse,
  ClubMemberResponse,
} from '@/interfaces/responses/club/ClubManagementResponse'
import type {
  ClubUserPromoted,
  ClubMember,
  ClubMembersInfoBody,
} from '@/interfaces/clubs/ClubManagement'

interface ClubManagementState {
  role: {
    member_id: string
    role: string
  }
  members: Nullable<ClubMember[]>
  activity: Nullable<ClubActivityResponse[]>
  userPromoted: ClubUserPromoted
}

interface Info {
  member_id: string
  role: string
  user: {
    clubId: string
    userId: string
    name: string
    threeLetterCode: string
    role: string
  }
}

export const useManagementStore = defineStore('managementStore', {
  state: (): ClubManagementState => ({
    role: {
      member_id: null,
      role: '',
    },
    members: null,
    activity: null,
    userPromoted: { status: false, user: { country: '', name: '', role: '' } },
  }),
  getters: {
    getMembersInfo(): ClubManagementState['members'] {
      return this.members
    },
    getActivity(): ClubManagementState['activity'] {
      return this.activity
    },
    getUserPromoted(): ClubManagementState['userPromoted'] {
      return this.userPromoted
    },
  },
  actions: {
    setUserPromotedStatus(bool: boolean): void {
      this.userPromoted.status = bool
    },
    async changeRole(info: Info): Promise<void> {
      const data = {
        member_user_game_id: info.member_id,
        role: info.role,
      }
      try {
        await internalAxios.put<{}, ClubMemberOperationApiResponse>(clubChangeRoleEndpoint, data)
      } catch (error: unknown) {
        console.error(error)
      }
      const newData = { ...info.user, ...{ role: info.role } }
      this.userPromoted = {
        status: true,
        user: {
          name: newData.name,
          country: newData.threeLetterCode,
          role: newData.role,
        },
      }
    },
    async deleteMember(memberId: string): Promise<void> {
      try {
        await internalAxios.delete<{}, ClubMemberOperationApiResponse>(
          `${clubDeleteMemberEndpoint}/${memberId}`,
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async leaveClub(clubId: string): Promise<void> {
      try {
        await internalAxios.delete<{}, ClubMemberOperationApiResponse>(
          `${clubLeaveEndpoint}/${clubId}`,
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },

    async loadMembersInfo(info: ClubMembersInfoBody): Promise<void> {
      let requestsBody = {
        club_id: info.clubId,
      }
      if (info.filter) {
        requestsBody = { ...requestsBody, ...info.filter.bodyContent }
      }
      const data = await internalAxios.post<{}, ClubMemberResponse[]>(
        `${clubMembersInfoEndpoint}`,
        requestsBody,
      )
      const members = data.map((member: ClubMemberResponse, i: number): ClubMember => {
        return {
          clubId: member.clubs_id,
          pos: i + 1,
          userId: member.user_id,
          onlineStatus: member.online_status ?? 'offline',
          offlineThreshold: member.offline_threshold ?? 0,
          name: {
            name: member.username,
            country: member.country.three_letter_code,
            role: member.role,
          },
          level: member.stats.level,
          prestige: member.parameters?.prestige ?? 0,
          championshipPoints: member.parameters?.championship_points ?? 0,
          tournamentTitles: member.parameters?.tournament_titles ?? 0,
          eventBadges: member.event_badges,
        }
      })
      this.members = members
    },

    async loadActivity(): Promise<void> {
      const data = await internalAxios.get<{}, ClubActivityResponse[]>(clubActivityEndpoint)
      this.activity = data
    },
  },
})
