<template>
  <div
    :id="'offer-' + offerData.store_id"
    :data-cy="'offer-' + offerData.store_id"
    class="offer-box relative"
    :class="{ locked: offerData.locked }"
    :style="{
      background:
        'url(' + pathImages + 'premium/offers/offer-' + imageName + '.avif) center no-repeat',
      backgroundSize: 'contain',
    }"
  >
    <section v-if="offerData.locked" class="locked">
      <app-icon icon-name="lock-lg" />
      <p class="info text-36 uppercase">
        <span class="vertical-line">|</span>
        {{
          $replacePlaceholder(
            $t('premiumGroup.buyPreviousBundle'),
            '{bundlename}',
            $t('premiumGroup.' + offerData.prerequisite),
          )
        }}
        <span class="vertical-line">|</span>
      </p>
    </section>
    <div
      v-show="offerData.remaining_buys === 0 && !passData"
      class="offer-box-bought absolute w-full h-full pointer-none"
    >
      <app-price
        class="text-texts-standard-upgrade offer-box-bought-text uppercase"
        decoration-color="#5199c6"
      >
        <p>{{ $t('common.claimed') }}</p>
      </app-price>
    </div>
    <header>
      <offer-box-head :offer-head-data="offerData" @click="showBackSide = !showBackSide" />
    </header>
    <main
      class="offer-box-main relative text-32 text-texts-standard-default uppercase"
      :class="disableBuyOnClick ? '' : 'cursor-pointer'"
      @click="disableBuyOnClick ? null : buyPack()"
    >
      <aside class="offer-box-main-upper">
        <section v-if="isLevelUpOffer">
          <div class="offer-level-up-box flexing">
            <p class="text-texts-standard-dark text-50 uppercase ml-4 mt-4">Level</p>
            <p class="text-texts-standard-dark level-up-count-size font-bold">
              {{ levelUpLevel }}
            </p>
          </div>
        </section>
        <section v-if="isGearDiscipline || isGearEvent" class="gear-discipline-content">
          <div class="equipment-wrapper" :class="{ 'gear-event': isGearEvent }">
            <div
              v-tippy="{
                theme: 'WSM',
                content: $t('equipmentShop.premiumEquipment'),
                placement: 'top',
              }"
              class="equipment-item"
              :class="itemClass"
            />
            <div class="unique-badge" />
            <div class="duration-badge text-28 text-texts-standard-default flexing font-bold">
              <p class="mr-4">{{ $t('premiumGroup.durationEN') }}</p>
              <p>
                <vue-countdown
                  v-slot="{ days, hours, minutes, seconds }"
                  :time="Number(equipmentReward?.rewardData?.effect_duration) * 1000"
                  :auto-start="false"
                >
                  {{ formatTime(days, hours, minutes, seconds, true) }}
                </vue-countdown>
              </p>
            </div>
          </div>
          <div
            v-if="isGearDiscipline"
            v-tippy="{
              theme: 'WSM',
              content: $t('premiumGroup.animatedAvatar'),
              placement: 'top',
            }"
            class="avatar-wrapper"
          >
            <avatar-box
              class="user-info-avatar avatar-box--offer"
              :avatar-id="Number(avatarId)"
              :avatar-gender="sex"
            />
          </div>
        </section>
        <div
          v-if="passData"
          class="duration-timer flexing normal-case text-34"
          :class="{ 'season-pass': isSeasonPass && isPassActive }"
        >
          <p class="text-texts-standard-default mr-2">
            {{
              `${$t(isPassActive ? 'premiumGroup.remaining_time' : 'premiumGroup.durationTime')}:`
            }}
          </p>
          <app-timer
            v-if="isPassActive || isSeasonPass"
            :transparent="true"
            :no-icon="true"
            :no-min-width="true"
            :time="getRemainingSecondsFromDate(new Date(passData.remaining_datetime))"
            :important-text="true"
            add-class="text-texts-standard-important"
          />
          <p v-else class="text-texts-standard-important">
            {{ $replacePlaceholder($t('common.days'), '%s', passDuration) }}
          </p>
        </div>
        <div
          class="offer-box-main-title"
          :class="[
            'title-' + offerData.store_id,
            { 'no-ads-text': isNoAdsOffer },
            { 'season-pass flexing': isSeasonPass },
          ]"
        >
          <p
            v-if="isSeasonPass"
            class="offer-box-main-text text-center line-clamp-2 text-30 leading-9 pt-1"
            :class="getOfferRibbonTextStyle"
          >
            {{
              $replacePlaceholder(
                $t(`premiumGroup.${textValue}_about`),
                '{pass}',
                $t(`premiumGroup.${textValue}`),
              )
            }}
          </p>
          <shrink-text
            v-else
            :text="
              $replacePlaceholder(
                $t(`premiumGroup.${textValue}_about`),
                '{pass}',
                $t(`premiumGroup.${textValue}`),
              )
            "
            :font-size="30"
            container-class-list="flexing h-full"
            :text-class-list="[
              'offer-box-main-text',
              'text-center',
              getOfferRibbonTextStyle,
              { 'pt-1.5': !isNoAdsOffer },
            ]"
            :max-line-limit="isNoAdsOffer ? 2 : null"
          />
        </div>
      </aside>
      <aside>
        <section v-if="!isNoAdsOffer" class="offer-box-main-rewards absolute w-full flexing">
          <!-- TODO: :force-percentage upravit v druhej iteracii tak,
          aby tam neboli odmeny napevno -->
          <div v-if="isSeasonPass" class="daily-badge left"></div>
          <reward-box
            v-for="reward in offerData.offer_content"
            :key="reward"
            :reward-icon-name="getRewardIconNameWithoutBonuxPostfix(reward)"
            :reward-margin-size="2"
            :reward-icon-size="80"
            :font-size="40"
            :reward-icon-rarity="(reward as Reward).rarity ?? ''"
            :reward-number="reward.value"
            :force-percentage="OFFER_BONUSES.includes(reward.name)"
            :is-pass="passData !== null"
            :reward-data="reward || null"
            :sex="sex"
            class="offer-reward-box"
          />
          <div v-if="isSeasonPass" class="daily-badge right"></div>
        </section>
        <section v-else class="ads-reward text-texts-standard-important font-bold text-60 flexing">
          <p>{{ gemsAmount }}</p>
          <app-main-icon :icon-size="56" icon-name="gems" />
        </section>
      </aside>
    </main>
    <footer class="offer-box-footer flex items-center absolute w-full justify-between">
      <app-timer v-if="showFooterTimer" :time="offerData.remaining_time" />
      <p
        v-if="isPassActive"
        class="pass-activated absolute text-texts-standard-default text-36 flexing uppercase"
      >
        {{
          $replacePlaceholder(
            $t('event.eventPassActive'),
            '{pass}',
            $t('premiumGroup.' + offerData.store_id),
          )
        }}
      </p>
      <div v-else class="offer-box-footer-price absolute text-texts-standard-default">
        <app-price
          v-if="offerData.price"
          :old-price="!offerValue ? '' : Math.round(offerPrice) + ' ' + offerData.price?.currency"
          :exchange-price="
            getForceRate
              ? formatForceRate(
                  !isFree && offerData?.price?.price && offerData.price.price > 0
                    ? offerData.price?.price
                    : 0,
                )
              : ''
          "
          decoration-color="#5199c6"
        >
          <p>
            {{
              !isFree && offerData?.price?.price && offerData.price.price > 0
                ? offerData.price?.price + ' ' + offerData.price?.currency
                : 'FREE'
            }}
          </p>
        </app-price>
        <app-price v-else class="bottom-2">
          {{ offerData?.gems_price }}
          <app-main-icon class="icon-margin price-icon" icon-name="gems" :icon-size="48" />
        </app-price>
      </div>
      <div v-if="isRookiePass" class="faster-progress-badge" />
      <div v-else-if="!isPassActive" class="offer-box-footer-value">
        <app-plate-value
          v-if="offerValue || percentageOff"
          :color="$isWsm ? 'purple' : 'orange'"
          :text-value="getOfferValue"
        />
      </div>
    </footer>
    <!-- BACK SIDE OF OFFER -->
    <offers-box-back
      v-show="showBackSide"
      class="z-indexer"
      :rewards="offerData.offer_content"
      :offer-type="offerData.store_id"
      :equipment-bonus="offerData.equipment_bonus_data"
    />
    <payment-terminal
      v-if="terminal"
      :item-id="offerData.store_id"
      :wire-transfer-off="true"
      @close="terminal = false"
    />
    <confirm-popup
      v-if="popup"
      :popup-title="$t(`premiumGroup.${offerData.store_id}`)"
      :button-data="btnUpgrade"
      :not-multi="true"
      :disabled="lackOfGems"
      :single-button-text="$t('premiumGroup.buy')"
      @close="popup = false"
      @action="buyWeeklyPack"
    >
      <div class="popup-wrapper">
        <p class="text-texts-standard-default text-38">
          {{ $t('premiumGroup.addOnConfirm') }}
        </p>
        <div class="flexing text-texts-standard-default text-40">
          <p>{{ $t('premiumGroup.price') }}:</p>
          <p
            class="flexing amount-text text-texts-standard-important"
            :class="{ 'text-texts-standard-danger': lackOfGems }"
          >
            {{ offerData?.gems_price }}
          </p>
          <app-main-icon :icon-size="56" icon-name="gems" />
        </div>
      </div>
    </confirm-popup>
  </div>
</template>

<script lang="ts">
import AppPlateValue from '@/components/GlobalComponents/AppPlateValue.vue'
import AppPrice from '@/components/GlobalComponents/AppPrice.vue'
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import PaymentTerminal from '@/components/Popup/Premium/PaymentTerminal.vue'
import OfferBoxHead from '@/components/Premium/Offers/OfferBoxHead.vue'
import OffersBoxBack from '@/components/Premium/Offers/OffersBoxBack.vue'
import AvatarBox from '@/components/Profile/AvatarBox.vue'
import ShrinkText from '@/components/ShrinkText.vue'
import {
  GEMS,
  OFFER_BONUSES,
  OFFER_STATES,
  PREMIUM_EQUIPMENT_ITEM,
  REWARD_AVATAR_TYPES,
  ROOKIE_PASS,
  SEASON_PASS,
  metaPremiumPackEndpoint,
  metaPremiumPayEndpoint,
  pathImages,
  GAME_VERSION_PREFIX,
} from '@/globalVariables'
import {
  formatTime,
  getDaysFromSeconds,
  getIsMobileLocalStorage,
  getRemainingSecondsFromDate,
  getRewardIconNameWithoutBonuxPostfix,
  sendToFlutter,
} from '@/helpers'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { Offer } from '@/interfaces/premium/Offer'
import type { Passes } from '@/interfaces/User'
import type PremiumPackApiResponse from '@/interfaces/responses/premium/PremiumPackApiResponse'
import type PremiumPayApiResponse from '@/interfaces/responses/premium/PremiumPayApiResponse'
import type Reward from '@/interfaces/Reward'
import type { OfferParameter } from '@/interfaces/premium/Offer'

interface ComponentData {
  pathImages: typeof pathImages
  showBackSide: boolean
  terminal: boolean
  showPaymentFailedPopup: boolean
  GEMS: typeof GEMS
  popup: boolean
  btnUpgrade: {
    btnId: string
    btnType: string
    btnSize: string
  }
  OFFER_STATES: typeof OFFER_STATES
  OFFER_BONUSES: typeof OFFER_BONUSES
}

export default defineComponent({
  name: 'OffersBox',
  components: {
    AppPrice,
    OffersBoxBack,
    OfferBoxHead,
    AppPlateValue,
    PaymentTerminal,
    ConfirmPopup,
    AvatarBox,
    ShrinkText,
  },
  props: {
    offerData: {
      type: Object as PropType<Nullable<Offer>>,
      default: () => null,
    },
    disableBuyOnClick: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
      showBackSide: false,
      terminal: false,
      showPaymentFailedPopup: false,
      GEMS,
      popup: false,
      btnUpgrade: {
        btnId: 'btn-buy-access',
        btnType: 'credit',
        btnSize: 'xl',
      },
      OFFER_STATES,
      OFFER_BONUSES,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userLevel: 'getLevel',
      passes: 'getAvailablePasses',
      sex: 'getSex',
    }),
    ...mapState(useResponseTaskStore, {
      headerData: 'getGems',
      getForceRate: 'getForceRate',
      formatForceRate: 'formatForceRate',
    }),
    gamePrefix(): string {
      if (this.$isSsm) return 'ssm'
      return 'wsm'
    },
    isMobileLocalStorage(): boolean {
      return getIsMobileLocalStorage() === 1
    },
    offerValue(): string | number {
      return (
        this.offerData?.parameters?.find(
          (parameter: OfferParameter): boolean => parameter.name === 'value',
        )?.value ?? ''
      )
    },
    getOfferValue(): string {
      if (this.percentageOff) {
        return '<b>' + this.percentageOff + '%</b>' + ' OFF'
      }
      return '<b>' + this.offerValue + '%</b>' + ' value'
    },
    percentageOff(): string | number {
      return (
        this.offerData?.parameters?.find(
          (parameter: OfferParameter): boolean => parameter.name === 'percentage_off',
        )?.value ?? ''
      )
    },
    gemsAmount(): string | number {
      return this.offerData.offer_content.find((content) => content.name === GEMS)?.value ?? 0
    },
    passDuration(): number {
      const passDurationInSeconds =
        this.offerData?.parameters?.find(
          (param: OfferParameter): boolean => param.name === 'pass_duration',
        )?.value ?? 0
      return getDaysFromSeconds(Number(passDurationInSeconds))
    },
    offerPrice(): number {
      if (this.isFree) {
        return Number(this.oldPrice)
      }
      if (!this.offerData?.price?.price || !this.offerValue) {
        return 0
      }
      return (this.offerData.price.price * Number(this.offerValue)) / 100
    },
    getAlternativeStoreId(): string {
      const storeId = this.offerData.stats?.find((stat) => stat.name === 'texts_images_key')
      if (storeId) {
        return storeId.value
      }
      return ''
    },
    textValue(): string {
      if (this.getAlternativeStoreId) {
        return this.getAlternativeStoreId
      }
      return this.offerData.store_id
    },
    imageName(): string {
      const imageName = this.offerData?.parameters
        ?.find((a) => a.name === 'image_name')
        ?.value.toString()

      if (this.getAlternativeStoreId) {
        return this.getAlternativeStoreId
      }
      if (this.showBackSide && this.isGearDiscipline)
        return `${this.gamePrefix}_premium_gear_discipline_back`
      if (this.isGearEvent) return `${this.gamePrefix}_premium_gear_event`
      if (this.isGearDiscipline) return `${this.gamePrefix}_premium_gear_discipline_1`
      return (
        imageName ??
        (this.isLevelUpOffer ? `${this.gamePrefix}_level_up_bundle` : this.offerData.store_id)
      )
    },
    isLevelUpOffer(): boolean {
      return this.offerData?.store_id?.includes('level_up')
    },
    levelUpLevel(): string {
      return (
        this.offerData?.stats?.find((e) => e.name === 'level_bundle_level')?.value ??
        this.userLevel.toString()
      )
    },
    isNoAdsOffer(): boolean {
      return this.offerData?.store_id?.includes('no_ads')
    },
    isGearDiscipline(): boolean {
      return this.offerData && this.offerData?.store_id?.includes('premium_gear_discipline')
    },
    isGearEvent(): boolean {
      return this.offerData && this.offerData?.store_id?.includes('premium_equipment_discipline')
    },
    lackOfGems(): boolean {
      return this.headerData.value < this.offerData?.gems_price
    },
    isFree(): number | string {
      return this.offerData?.parameters?.find((a) => a.name === 'is_free')?.value
    },
    oldPrice(): number | string {
      return this.offerData?.parameters?.find((a) => a.name === 'old_price')?.value
    },
    passData(): Passes {
      return (
        this.passes.find((pass: Passes): boolean => pass.store_id === this.offerData?.store_id) ??
        null
      )
    },
    isPassActive(): boolean {
      return this.passData?.state === OFFER_STATES.active
    },
    showFooterTimer(): boolean {
      return this.offerData?.remaining_time && !this.isPassActive
    },
    isRookiePass(): boolean {
      return this.offerData?.store_id === ROOKIE_PASS
    },
    isSeasonPass(): boolean {
      return this.offerData?.store_id === SEASON_PASS
    },
    equipmentData(): Reward {
      return this.offerData.offer_content.find(
        (e: { name: string }) => e.name === PREMIUM_EQUIPMENT_ITEM,
      )?.rewardData
    },
    itemClass(): string {
      return `item-${this.equipmentData?.discipline_id}-${this.equipmentData?.gdd_id}-1`
    },
    equipmentReward(): Reward {
      return this.offerData.offer_content.find(
        (e: { name: string }) => e.name === PREMIUM_EQUIPMENT_ITEM,
      )
    },
    avatarId(): number {
      return (
        this.offerData.offer_content.find((item: { name: string }): boolean =>
          REWARD_AVATAR_TYPES.includes(item?.name),
        )?.value ?? 0
      )
    },
    getOfferRibbonTextStyle(): string {
      return [
        `${GAME_VERSION_PREFIX}_no_ads_offer_2`,
        `${GAME_VERSION_PREFIX}_no_ads_offer_1`,
      ].includes(this.offerData.store_id)
        ? 'text-texts-standard-default'
        : 'text-texts-standard-important'
    },
  },
  methods: {
    ...mapActions(useResponseTaskStore, {
      showPaymentLoading: 'showPaymentLoading',
      showPaymentMessage: 'showPaymentMessage',
    }),
    formatTime,
    getRemainingSecondsFromDate,
    getRewardIconNameWithoutBonuxPostfix,
    async buyArrayOfferPack(): Promise<void> {
      const pack = this.offerData.store_id
      if (this.offerData.gems_price) {
        this.popup = true
        return
      }

      if (!this.isMobileLocalStorage && this.offerData.price?.price > 0) {
        this.terminal = true
        return
      }

      this.showPaymentLoading()

      const responsePay = await this.$axios.get<{}, PremiumPayApiResponse>(
        `${metaPremiumPayEndpoint}/pack/${pack}${
          !this.isMobileLocalStorage ? '/bank_transfer' : ''
        }`,
      )
      if (responsePay?.event) {
        const message = {
          event: responsePay.event,
          productId: responsePay.productId,
        }
        sendToFlutter(JSON.stringify(message))
        return
      }
      const responsePack = await this.$axios.get<{}, PremiumPackApiResponse>(
        metaPremiumPackEndpoint + pack,
      )
      this.showPaymentMessage({
        pack: pack,
        rewards: responsePack.rewards,
        failed: false,
        af_quantity: responsePack.firebaseData.af_quantity,
        af_store_id: responsePack.firebaseData.af_store_id,
        gtm_currency: responsePack.firebaseData.gtm_currency,
        gtm_revenue: responsePack.firebaseData.gtm_revenue,
      })
    },
    async buyPack(): Promise<void> {
      if (this.offerData && Object.keys(this.offerData).length === 1) {
        await this.buyArrayOfferPack()
        return
      }
      const pack = this.offerData.store_id
      if (this.offerData?.gems_price) {
        this.popup = true
        return
      }

      if (!this.isMobileLocalStorage && this.offerData.price?.price > 0) {
        this.terminal = true
        return
      }

      this.showPaymentLoading()

      const responsePay = await this.$axios.get<{}, PremiumPayApiResponse>(
        `${metaPremiumPayEndpoint}/pack/${pack}${
          !this.isMobileLocalStorage ? '/bank_transfer' : ''
        }`,
      )
      if (responsePay?.event) {
        const message = {
          event: responsePay.event,
          productId: responsePay.productId,
        }
        sendToFlutter(JSON.stringify(message))
        return
      }
      const responsePack = await this.$axios.get<{}, PremiumPackApiResponse>(
        metaPremiumPackEndpoint + pack,
      )
      this.showPaymentMessage({
        pack: pack,
        rewards: responsePack.rewards,
        failed: false,
        af_quantity: responsePack.firebaseData.af_quantity,
        af_store_id: responsePack.firebaseData.af_store_id,
        gtm_currency: responsePack.firebaseData.gtm_currency,
        gtm_revenue: responsePack.firebaseData.gtm_revenue,
      })
    },
    async buyWeeklyPack(): Promise<void> {
      this.showPaymentLoading()

      try {
        const responsePay = await this.$axios.get<{}, PremiumPayApiResponse>(
          `${metaPremiumPayEndpoint}/pack/${this.offerData.store_id}/bank_transfer`,
        )
        this.popup = false
        if (responsePay?.event) {
          const message = {
            event: responsePay.event,
            productId: responsePay.productId,
          }
          sendToFlutter(JSON.stringify(message))
          return
        }
        const responsePack = await this.$axios.get<{}, PremiumPackApiResponse>(
          metaPremiumPackEndpoint + this.offerData.store_id,
        )
        this.showPaymentMessage({
          pack: this.offerData.store_id,
          rewards: responsePack.rewards,
          failed: false,
          af_quantity: responsePack.firebaseData.af_quantity,
          af_store_id: responsePack.firebaseData.af_store_id,
          gtm_currency: responsePack.firebaseData.gtm_currency,
          gtm_revenue: responsePack.firebaseData.gtm_revenue,
        })
      } catch (error) {
        console.error(error)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.offer-reward-box.reward-box {
  width: 8.438rem;
  height: 10.375rem;
}

.offer-box {
  height: 44.875rem;
  width: 78.75rem;
  margin-right: 1.5rem;
  position: relative;

  &:nth-last-child(2) {
    margin-right: unset;
  }

  &-bought {
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;

    &-text {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  section.locked {
    position: absolute;
    z-index: 2;
    inset: 0;
    background-color: rgba(11, 30, 53, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #d9ecff;

    .info {
      text-shadow: 0.6px 0.8px 0.9px rgba(0, 0, 0, 0.51);
      font-weight: normal;
      font-stretch: condensed;
      line-height: 1.32;
      color: #d9ecff;
      margin-top: 2.375rem;

      @if $isWsm {
        font-style: italic;
      }

      .vertical-line {
        font-style: normal;
        color: #377fb8;
      }
    }
  }

  header {
    height: 6.25rem;
  }

  &-main {
    height: 38.5rem;

    &-upper {
      position: relative;
      height: 20.8rem;
    }

    &-title {
      text-align: center;
      width: 100%;
      height: 2.7rem;
      position: absolute;
      bottom: 0;
      padding: 0 1rem;

      &.season-pass {
        height: 4.8rem;
        line-height: 2.1rem;
      }
    }

    .no-ads-text {
      bottom: 14rem;
      height: 5rem;
      line-height: 2rem;
      padding: 0 25%;
    }

    .offer-level-up-box {
      background: url($path-images + 'premium/offers/lvl-up-box.avif') center no-repeat;
      background-size: contain;
      width: 16.688rem;
      height: 16rem;
      margin-left: 5.875rem;
      flex-direction: column;

      .level-up-count-size {
        font-size: 8.75rem;
        height: 7.75rem;
        line-height: 7.75rem;
      }
    }

    &-amount {
      width: 21.6875rem;
      height: 4.5625rem;
      top: 21.75rem;
      right: 9.8125rem;
      background: linear-gradient(to right, transparent, rgba(7, 30, 68, 0.99), transparent);

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 80%;
        height: 0.125rem;
        background: linear-gradient(to left, transparent, rgba(144, 198, 255, 0.99), transparent);
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }
    }

    &-rewards {
      height: 11.25rem;
      bottom: 6rem;
    }

    .ads-reward {
      width: 21.6875rem;
      height: 4.5625rem;
      position: absolute;
      right: 9rem;
      bottom: 11.5rem;
    }
  }

  &-footer {
    bottom: 0.75rem;
    padding-left: 2rem;
    padding-right: 2rem;

    &-price {
      left: 50%;
      bottom: 0.5rem;
      transform: translate(-50%);

      &-before:before {
        content: '';
        position: absolute;
        width: 5rem;
        height: 0.2rem;
        background: #b52e2d;
        top: 1.3rem;
        left: 1rem;
        transform: skewY(-14deg);
      }
    }

    .pass-activated {
      left: 50%;
      bottom: 0rem;
      transform: translate(-50%);
      width: 43.75rem;
      height: 4rem;
      background: linear-gradient(to right, transparent 5%, #8c00b2, transparent 95%);

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 0.125rem;
        left: 0rem;
        background: linear-gradient(to right, transparent 5%, #cf07f9, transparent 95%);
      }

      &:before {
        top: 0rem;
      }

      &:after {
        bottom: 0rem;
      }
    }

    .faster-progress-badge {
      width: 12.75rem;
      height: 12.75rem;
      background: url($path-images + 'premium/offers/faster-progress-badge.avif') center no-repeat;
      background-size: contain;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .duration-timer {
    width: 33.125rem;
    height: 3.125rem;
    position: absolute;
    bottom: 5rem;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(to right, transparent 5%, rgba(188, 27, 27, 1), transparent 95%);

    &.season-pass {
      background: linear-gradient(to right, transparent 5%, #8c00b2, transparent 95%);
    }
  }

  .daily-badge {
    width: 14rem;
    height: 11.188rem;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;

    &.left {
      background-image: url($path-images + 'premium/offers/daily-left.avif');
      left: 0.2rem;
    }

    &.right {
      background-image: url($path-images + 'premium/offers/daily-right.avif');
      right: 0.2rem;
    }
  }

  .icon-margin.price-icon {
    margin-right: 0;
  }
}

// Ked zacneme pouzivat mode: 'jit', tak toto moze ist doprec.
// https://v2.tailwindcss.com/docs/just-in-time-mode
.\!mr-0 {
  margin-right: 0 !important;
}

.z-indexer {
  z-index: 2;
}

.gear-discipline-content {
  display: flex;
  align-items: center;
  position: absolute;
  height: 17.5rem;
  width: 100%;

  .avatar-wrapper {
    position: absolute;
    right: 13.7rem;
    top: 1.5rem;
  }

  .equipment-wrapper {
    position: absolute;
    left: 12rem;

    &.gear-event {
      left: 42rem;
    }

    .unique-badge {
      background: url($path-images + 'premium/offers/unique-badge.avif');
      width: 9.5rem;
      height: 3.688rem;
      position: absolute;
      right: 1rem;
      background-size: contain;
      bottom: 2rem;
    }

    .duration-badge {
      background: url($path-images + 'premium/offers/time-badge.avif');
      width: 19.438rem;
      height: 3.688rem;
      position: absolute;
      right: 1rem;
      background-size: contain;
      bottom: -1rem;
    }

    .equipment-item {
      background-repeat: no-repeat;
      background-size: contain;
      width: 26rem;
      height: 14.313rem;

      @for $discipline from 1 through 14 {
        &.item-#{$discipline}-5-1 {
          background-image: url($path-equipment + 'items/list/' + #{$discipline} + '/5-1.avif');
        }

        &.item-#{$discipline}-6-1 {
          background-image: url($path-equipment + 'items/list/' + #{$discipline} + '/6-1.avif');
        }
      }
    }
  }
}
</style>
