<template>
  <header class="chains-subheader flexing w-full" :data-event-theme="namespace">
    <p class="text-30 text-texts-standard-important font-bold italic">
      {{ $te('chainsSubHeaderText') }}
    </p>
  </header>
</template>

<script lang="ts">
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ChainsSubHeader',
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='frozen'] {
  --events-chains-header-bg: url('#{$path-events}frozen-championship/taskchain/chains/header-bg-v1.avif');
}

.chains-subheader {
  height: 4.438rem;
  @include background(var(--events-chains-header-bg), contain);
}
</style>
