<template>
  <teleport to="#app">
    <div
      id="championship-summary"
      class="championship-summary flexing"
      :class="[setBg, $isMobile() && 'championship-summary-mobile']"
    >
      <div class="championship-summary-promotion" />
      <header>
        <championship-summary-header :is-promotion="isPromotion" :is-winner="isWinner" />
      </header>
      <main>
        <championship-summary-not-winner
          v-if="!isWinner"
          :summary-data="summaryData"
          :is-promotion="isPromotion"
          :is-stay="isStay"
        />

        <championship-summary-winner v-else :summary-data="summaryData" />
        <championship-summary-details
          :summary-data="summaryData"
          :is-promotion="isPromotion"
          :is-stay="isStay"
        />
        <championship-summary-rewards :summary-data="summaryData" />
      </main>
      <footer>
        <app-button
          btn-id="championship-summary-btn"
          btn-size="md"
          btn-type="secondary"
          :btn-text="$t('button.continue')"
          @click="closeMessage()"
        />
      </footer>
    </div>
  </teleport>
</template>

<script lang="ts">
import ChampionshipSummaryDetails from '@/components/Club/Championship/ChampionshipSummaryDetails.vue'
import ChampionshipSummaryHeader from '@/components/Club/Championship/ChampionshipSummaryHeader.vue'
import ChampionshipSummaryNotWinner from '@/components/Club/Championship/ChampionshipSummaryNotWinner.vue'
import ChampionshipSummaryRewards from '@/components/Club/Championship/ChampionshipSummaryRewards.vue'
import ChampionshipSummaryWinner from '@/components/Club/Championship/ChampionshipSummaryWinner.vue'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { ClubChampsSummary } from '@/interfaces/clubs/ClubChampionships'

export default defineComponent({
  name: 'ChampionshipSummary',
  components: {
    ChampionshipSummaryHeader,
    ChampionshipSummaryWinner,
    ChampionshipSummaryNotWinner,
    ChampionshipSummaryDetails,
    ChampionshipSummaryRewards,
  },
  props: {
    summaryData: {
      type: Object as PropType<ClubChampsSummary>,
      required: true,
    },
  },
  computed: {
    setBg(): string {
      if (this.isWinner) return 'legendary'
      return 'basic'
    },
    isWinner(): boolean {
      return (
        this.summaryData.current_league === 'legendaryLeague' &&
        this.summaryData.next_league === 'legendaryLeague' &&
        this.summaryData.position === 1
      )
    },
    isPromotion(): boolean {
      return this.summaryData.next_league_status === 'advances'
    },
    isStay(): boolean {
      return this.summaryData.next_league_status === 'stays'
    },
  },
  methods: {
    ...mapActions(useResponseTaskStore, {
      hideChampionshipSumamry: 'hideChampionshipSumamry',
    }),
    closeMessage(): void {
      this.hideChampionshipSumamry()
    },
  },
})
</script>

<style lang="scss">
@import '@/assets/styles/views/club/clubChampionshipSummary.scss';
</style>
