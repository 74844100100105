<template>
  <main v-if="!isLoadingClub">
    <div class="flex justify-between items-center club-profile-table-header">
      <div class="empty" />
      <p class="text-32 uppercase">
        {{ $t('club.members') }}
      </p>
      <div class="flexing">
        <p class="text-32 font-bold club-profile-table-header-capacity">
          {{ clubInfo.members.length }}/{{ clubInfo.membersMaxLength }}
        </p>
        <app-icon
          v-tippy="{
            theme: 'WSM',
            content: $t('club.clubPlayers'),
            placement: 'top',
          }"
          class="ml-3"
          :icon-size="56"
          icon-name="club-members"
        />
      </div>
    </div>

    <div class="bordered-1 club-profile-table-wrapper">
      <div class="w-full club-profile-table-content">
        <div class="flex justify-between club-profile-table-content-row leader">
          <app-user
            :id="clubLeader.id"
            :country="clubLeader.country"
            :name="clubLeader.name"
            class="club-profile-player"
            :badges-data="clubLeader.eventBadges ?? null"
          />
          <div class="flexing">
            <p class="text-32 uppercase">
              {{ $t(`club.${clubLeader.role}`) }}
            </p>
            <app-icon class="ml-3" :icon-size="56" icon-name="crown" />
          </div>
        </div>
        <app-scrollbar
          class="clubs-profile-table-scrollbar"
          width="100%"
          height="100%"
          scroll="y"
          slide="y"
        >
          <div
            v-for="(member, index) in clubMembers"
            :key="index"
            class="flex justify-between club-profile-table-content-row"
            :class="{ 'is-even': (index + 1) % 2 === 0 }"
          >
            <app-user
              :id="member.user_id"
              :country="member.country.three_letter_code"
              :name="member.username"
              class="club-profile-player"
              :badges-data="member.event_badges ?? null"
            />
            <div class="flexing">
              <p class="text-32 uppercase">
                {{ $t(`club.${member.role}`) }}
              </p>
            </div>
          </div>
          <div
            v-for="(emptySlot, index) in emptySlots"
            :key="index"
            class="flexing text-32 uppercase text-texts-standard-name empty-slot"
          >
            <p>
              {{ $t('club.emptySlot') }}
            </p>
          </div>
        </app-scrollbar>
      </div>
    </div>
  </main>

  <component-loading width="62%" height="35rem" :is-loading="isLoadingClub" />
</template>

<script lang="ts">
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { Member } from '@/types/clubStoreTypes'

interface ClubLeader {
  name: Member['username']
  country: Member['country']['three_letter_code']
  role: Member['role']
  id: Member['user_id']
  eventBadges: Member['event_badges']
}

export default defineComponent({
  name: 'ClubsProfileTable',
  components: {
    AppUser,
  },
  computed: {
    ...mapState(useClubStore, {
      clubInfo: 'getClubInfo',
      isLoadingClub: 'isLoadingClub',
    }),
    clubLeader(): ClubLeader {
      const manager = this.clubInfo.members.find(
        (member: Member): boolean => member.role === 'manager',
      )
      return {
        name: manager?.username ?? '',
        country: manager?.country.three_letter_code ?? '',
        role: manager?.role ?? '',
        id: manager?.user_id ?? '',
        eventBadges: manager?.event_badges,
      }
    },
    clubMembers(): Member[] {
      const members = this.clubInfo.members.filter(
        (member: Member): boolean => member.role !== 'manager',
      )
      return members
    },
    emptySlots(): number {
      return this.clubInfo.membersMaxLength - this.clubInfo.members.length
    },
  },
})
</script>

<style lang="scss" scoped>
main {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  .club-profile-table {
    &-header {
      width: 100%;
      height: 4rem;
      padding: 0.5rem 1.5rem 0.5rem 1.5rem;

      @if $isWsm {
        background: linear-gradient(
          90deg,
          rgba(5, 25, 58, 0) 0%,
          rgba(5, 25, 58, 1) 50%,
          rgba(5, 25, 58, 0) 100%
        );
      }
      @if $isSsm {
        background: linear-gradient(
          90deg,
          rgba(5, 25, 58, 0) 0%,
          #23293e 50%,
          rgba(5, 25, 58, 0) 100%
        );
      }
      transform: translate(0, 0);

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 0.063rem;
        left: 0;
        @if $isWsm {
          background: linear-gradient(
            90deg,
            rgba(106, 139, 195, 0) 0%,
            rgba(106, 139, 195, 1) 50%,
            rgba(106, 139, 195, 0) 100%
          );
        }
        @if $isSsm {
          background: linear-gradient(
            90deg,
            rgba(106, 139, 195, 0) 0%,
            #fff 50%,
            rgba(106, 139, 195, 0) 100%
          );
        }
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }

      &-capacity {
        color: $color-important;
      }

      .empty {
        width: 8rem;
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1 1 0;
      min-height: 0;
      margin-top: 0.375rem;
      padding: 0;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      @if $isWsm {
        border: 0.125rem solid #5ba3dc;
      }
      @if $isSsm {
        border: 0.125rem solid #4c648f;
        background-color: #202f53;
      }

      .clubs-profile-table-scrollbar {
        // (i) according to the scrollbar macOS chrome, firefox and safari
        padding-right: 1.375rem;
      }
    }

    &-content {
      width: 100%;
      min-height: 0;
      display: flex;
      flex-direction: column;

      &-row {
        height: 4rem;
        width: 100%;
        margin-bottom: 0.375rem;
        padding: 0.5rem 1rem 0.5rem 1rem;

        @if $isWsm {
          background: rgba(77, 128, 160, 0.7);
          border: 0.06875rem solid #6c9ec4;
        }
        @if $isSsm {
          background: #525479;
          border: 0.06875rem solid #7477a4;
        }
      }

      .leader {
        background: url($path-clubs + 'components/profile/bg-table-leader.avif') center no-repeat;
        background-size: 100% 100%;
        margin-bottom: 1.125rem;
        margin-top: 0.813rem;
      }

      .is-even {
        @if $isWsm {
          background: rgba(35, 109, 155, 0.7);
        }
        @if $isSsm {
          background: #7272b0;
        }
      }
    }
  }

  .empty-slot {
    height: 4rem;
    width: 100%;
    margin-bottom: 0.375rem;

    @if $isWsm {
      background: rgba(60, 98, 121, 0.7);
      border: 0.06875rem solid #5a8aad;
    }
    @if $isSsm {
      background: #525479;
      border: 0.06875rem solid #7477a4;
    }
  }
}
</style>
