<template>
  <popup-window
    v-if="isVisible"
    popup-type="info"
    :popup-title="$t('common.notEnoughGemsTitle')"
    @close="$emit('close')"
  >
    <div class="no-gems-popup-content flexing">
      <p class="text-texts-standard-default text-36">
        {{ $t('common.notEnoughGems') }}
      </p>
    </div>
    <app-button
      btn-id="no-enought-gems-btn"
      class="no-gems-popup-content-btn"
      btn-size="xl"
      btn-type="credit"
      :btn-text="$t('map.premiumName')"
      @click="goToPremium"
    />
  </popup-window>
</template>

<script lang="ts">
import PopupWindow from '@/components/Popup/PopupWindow.vue'

export default {
  name: 'NoGemsPopup',
  components: {
    PopupWindow,
  },
  props: {
    isVisible: {
      type: Boolean,
      reqired: true,
    },
  },
  emits: ['close'],
  methods: {
    goToPremium(): void {
      this.$emit('close')
      this.$router.push({ name: this.$getWebView('PremiumGems') })

      return
    },
  },
}
</script>

<style lang="scss" scoped>
.no-gems-popup-content {
  width: 95%;
  height: 14rem;
  margin: 0 auto;

  &-btn {
    margin: 0 auto;
  }
}
</style>
