<template>
  <main class="flexing flex-col flex-grow w-full">
    <event-pass3-summary-main v-if="!$route.query?.tier" />
    <event-pass3-detail-main v-else />
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import EventPass3DetailMain from './Detail/EventPass3DetailMain.vue'
import EventPass3SummaryMain from './Summary/EventPass3SummaryMain.vue'

export default defineComponent({
  name: 'EventPass3Main',
  components: {
    EventPass3SummaryMain,
    EventPass3DetailMain,
  },
})
</script>
