<template>
  <div
    class="component-box bordered-lg"
    :class="{ selected: isActive }"
    :style="{
      backgroundImage:
        Number(data.lock) < 99 && `url(${pathImages}disciplines/${data.id}-training-tutorial.avif)`,
    }"
    @click="!data.lock ? selectItem(data.id) : ''"
  >
    <div v-show="isActive" class="item-selected">
      <p class="text-texts-standard-important text-30 font-bold uppercase">
        {{ $t('benefits.selected') }}
      </p>
    </div>

    <tippy
      v-if="data.lock"
      :theme="Number(data.lock) === 99 ? 'WSM' : 'WSM1'"
      :class="['component-box-locked', 'absolute', 'flexing']"
    >
      <app-icon :icon-name="lockBox" class="-mt-5" />
      <template #content>
        <div v-if="Number(data.lock) != 99" class="lock-tooltip">
          <div class="lock-tooltip-title">
            <p class="uppercase font-bold">{{ $t('common.locked') }}</p>
          </div>
          <p class="lock-tooltip-text-margin" v-html="lockText"></p>
        </div>
        <div v-else>{{ lockText }}</div>
      </template>
    </tippy>
    <footer :class="{ 'even-footer': [2, 4, 6, 8, 10].includes(data.id) }">
      <app-discipline-icon :discipline-id="data.id" :size="70" class="component-box-icon" />
      <div class="component-box-name text-texts-standard-default">
        <short-text
          class="text-36 uppercase font-bold"
          :text="$translateDiscipline(data.id)"
          first-custom="text-32"
          :first-cut="16"
        />
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import ShortText from '@/components/ShortText.vue'
import { pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

import type Discipline from '@/interfaces/global/Discipline'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  pathImages: typeof pathImages
  showInfoPopup: boolean
}

export default defineComponent({
  name: 'TrainingTutorialBox',
  components: {
    ShortText,
  },
  props: {
    data: {
      type: Object as PropType<Nullable<Discipline>>,
      default: () => null,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectItem'],
  data(): ComponentData {
    return {
      pathImages,
      showInfoPopup: false,
    }
  },
  computed: {
    lockBox(): string {
      return Number(this.data.lock) === 99 ? 'time-lg' : 'lock-lg'
    },
    lockText(): string {
      return Number(this.data.lock) >= 99
        ? this.$t('common.comingSoon')
        : this.$replacePlaceholder(
            this.$replaceHtmlPlaceholders(
              this.$replacePlaceholder(this.$t('common.levelRequired'), '%s', '{b} %s {/b}'),
              'b',
              'text-texts-standard-important',
            ),
            '%s',
            this.data.lock.toString(),
          )
    },
  },
  methods: {
    selectItem(id: string | number): void {
      this.$emit('selectItem', id)
    },
  },
})
</script>

<style lang="scss" scoped>
.component-box {
  width: 27.25rem;
  height: 18.5rem;
  margin: 1.1875rem 1.1875rem 0 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-style: solid;
  border-width: 0.125rem;
  border-image-slice: 1;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  @if $isWsm {
    border-image-source: linear-gradient(to top, #5ba3dc, #bfe3ff, #5ba3dc);
  }
  @if $isSsm {
    border-color: #636790;
  }

  &:active {
    transform: scale(0.95);
  }

  .item-selected {
    height: 100%;
    width: 100%;
    text-align: left;
    padding-left: 1rem;
    position: relative;
    @if $isWsm {
      background-color: rgba(250, 215, 66, 0.2);
    }
    @if $isSsm {
      background-color: rgba(#52450f, 0.2);
    }

    &:after {
      @if $isWsm {
        position: absolute;
        content: '';
        bottom: -1.4rem;
        right: -0.4rem;
        z-index: 2;
        transform: rotate(45deg);
        width: 0;
        height: 0;
        border-top: 2.063rem solid transparent;
        border-bottom: 2.063rem solid transparent;
        border-left: 2.063rem solid theme('colors.texts.standard.important');
      }
    }
  }

  &.locked {
    pointer-events: none;
  }

  &.selected {
    border-style: solid;
    border-width: 0.125rem;
    border-image-source: linear-gradient(
      to top,
      theme('colors.texts.standard.important'),
      #ffec6c,
      theme('colors.texts.standard.important')
    );
    border-image-slice: 1;
  }

  &-locked {
    width: 100%;
    height: 100%;
    z-index: 2;
    @if $isWsm {
      background: rgba(12, 48, 74, 0.8);
    }
    @if $isSsm {
      background: rgba(#3b434a, 0.5);
    }

    .lock {
      transform: translate(-50%, -50%);
    }

    .lock-text {
      bottom: 1rem;
      width: 80%;
      height: 6rem;
      left: 50%;
      transform: translate(-50%);
    }
  }

  footer {
    background-size: cover;
    width: 100%;
    height: 3.875rem;
    position: absolute;
    bottom: -0.063rem;
    display: flex;
    justify-content: flex-end;
    z-index: 2;
    @if $isWsm {
      background: url($path-training + 'trainer/BG-DISCIPLINE-NAME-TUTORIAL.avif')
        top
        left
        no-repeat;
    }
    @if $isSsm {
      background: rgba(#1d1e2b, 0.8);
    }

    &.even-footer {
      bottom: 0;
    }

    .component-box-icon {
      position: absolute;
      left: 0.5rem;
      bottom: 0.5rem;
    }

    .component-box-name {
      width: 78%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
    }
  }
}

.componet-box-tooltip {
  min-width: 17rem;

  .componet-box-tooltip-title {
    width: 100%;
    background: linear-gradient(to right, transparent, #236d9b, transparent);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 0.125rem;
      background: linear-gradient(to left, transparent, rgba(255, 255, 255, 0.5), transparent);
      bottom: 0;
      left: 0;
    }
  }

  .componet-box-tooltip-text-margin {
    margin: 1.5rem 2.5rem 2rem;
  }
}
</style>
