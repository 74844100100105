<template>
  <div class="app-page-wrapper absolute">
    <div class="flexing flutter-test">
      <aside>
        <div v-for="(btn, i) in btns" :key="i" class="flutter-button-wrapper">
          <app-button btn-type="primary" :btn-text="btn.text" btn-size="md" @click="btn.link" />
        </div>
      </aside>
    </div>
  </div>
</template>

<script lang="ts">
import { sendToFlutter } from '@/helpers'
import { defineComponent } from 'vue'
interface ComponentData {
  interstitialAd: string
  rewardedAd: string
  appsflerFirebase: string
  purchase: string
  showATT: string
  connectAccount: string
  logout: string
  disconnect: string
  login: string
  setMusic: string
  setNotifications: string
  setLanguage: string
  setInAppReview: string
  btns: {
    link: () => void
    text: string
  }[]
}
export default defineComponent({
  name: 'FlutterTestView',
  data(): ComponentData {
    return {
      interstitialAd: '{\r\n  "event": "playInterstitial",\r\n  "place": "A"\r\n}',
      rewardedAd: '{\r\n  "event": "playRewarded",\r\n  "place": "A"\r\n}',
      appsflerFirebase:
        '{\r\n  "event": "analyticsEvent",\r\n  "eventName": "levelReached",\r\n  "level": 7, \r\n  "gold": 123,\r\n  "eurosSpent": 157,\r\n  "hasClub": true,\r\n  "clubName": "Coconuts on ice"\r\n}',
      purchase: '{\r\n  "event": "buyProduct",\r\n  "productId": "wsm_test_purchase2"\r\n}',
      showATT: '{\r\n  "event": "showATT"\r\n}',
      connectAccount: '{\r\n  "event": "connectAccount"\r\n}',
      logout: '{\r\n  "event": "logout"\r\n}',
      disconnect: '{\r\n  "event": "disconnect"\r\n}',
      login: '{\r\n  "event": "login"\r\n}',
      setMusic: '{\r\n  "event": "setMusic",\r\n "enabled": true\r\n}',
      setNotifications: '{\r\n  "event": "setNotifications",\r\n "enabled": true\r\n}',
      setLanguage: '{\r\n  "event": "setLanguage",\r\n "language": "es"\r\n}',
      setInAppReview: '{\r\n  "event": "inAppReview"\r\n}',

      btns: [
        {
          link: () => sendToFlutter(this.interstitialAd.toString()),
          text: 'Play interstitial ad',
        },
        {
          link: () => sendToFlutter(this.rewardedAd.toString()),
          text: 'prehrat rewarded video',
        },
        {
          link: () => sendToFlutter(this.appsflerFirebase.toString()),
          text: 'odoslat appsfler/ firebase event',
        },
        {
          link: () => sendToFlutter(this.purchase.toString()),
          text: 'kupit mobilny pack',
        },
        {
          link: () => sendToFlutter(this.showATT.toString()),
          text: 'vyvolat ATT okno',
        },
        {
          link: () => sendToFlutter(this.connectAccount.toString()),
          text: 'connect account',
        },
        {
          link: () => sendToFlutter(this.logout.toString()),
          text: 'logout',
        },
        {
          link: () => sendToFlutter(this.disconnect.toString()),
          text: 'disconnect',
        },
        {
          link: () => sendToFlutter(this.login.toString()),
          text: 'login',
        },
        {
          link: () => sendToFlutter(this.setMusic.toString()),
          text: 'set music',
        },
        {
          link: () => sendToFlutter(this.setNotifications.toString()),
          text: 'set notification',
        },
        {
          link: () => sendToFlutter(this.setLanguage.toString()),
          text: 'set language',
        },
        {
          link: () => this.purchaseFinished('pack', 'true'),
          text: 'Purchase Finished',
        },
        {
          link: () => this.rewardedVideoNotLoaded(),
          text: 'rewarded Video Not Loaded',
        },
        {
          link: () => this.rewardedVideoEarned(),
          text: 'rewarded Video Earned',
        },
        {
          link: () => this.rewardedVideoFailed(),
          text: 'rewarded Video Failed',
        },
        {
          link: () => sendToFlutter(this.setInAppReview.toString()),
          text: 'set intAppReview',
        },
      ],
    }
  },
  methods: {
    sendToFlutter,
  },
})
</script>

<style scoped>
.flutter-test {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 6rem;
}

.flutter-button-wrapper {
  width: 26rem;
  height: 6rem;
  margin: 2rem;
  display: inline-flex;
}
</style>
