<template>
  <div class="connected-accounts flexing space-x-4 relative">
    <template v-if="!isLoading">
      <div
        v-for="(status, account) in connectedAccounts"
        :key="account"
        v-tippy="{
          content: getConnectedAccountStatusText(status, account),
          allowHTML: true,
          placement: 'top',
          theme: 'WSM',
        }"
        class="account-icon relative"
        :class="[`is-${account}-account-icon`, `is-${status}`]"
      >
        <app-icon
          v-if="status === ConnectedAccountStatus.Connected"
          icon-name="done-md"
          class="absolute -top-5 -right-5 transform scale-50"
        />
        <app-notification
          v-if="status === ConnectedAccountStatus.VerificationPending"
          type="exclamationLabel"
          class="!-top-2 !-right-2"
        />
      </div>
      <app-button v-if="!hideLinkButton" btn-type="secondary" btn-size="md" @click="onClick">
        {{ $t('settings.link') }}
      </app-button>
    </template>
    <component-loading :is-loading="isLoading" />
  </div>
</template>

<script lang="ts">
import { getConnectedAccountStatusText, sendToFlutter } from '@/helpers'
import { useAccountStore } from '@/store/pinia/accountStore'
import { ConnectedAccountStatus } from '@/enums/LoginForm'
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'

interface ComponentData {
  ConnectedAccountStatus: typeof ConnectedAccountStatus
}

export default defineComponent({
  props: {
    hideLinkButton: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      ConnectedAccountStatus,
    }
  },
  computed: {
    ...mapState(useAccountStore, { connectedAccounts: 'getConnectedAccounts' }),
    isLoading(): boolean {
      return this.connectedAccounts === null
    },
  },
  async created(): Promise<void> {
    await this.loadConnectedAccounts(true)
  },
  methods: {
    sendToFlutter,
    ...mapActions(useAccountStore, ['loadConnectedAccounts']),
    getConnectedAccountStatusText,
    onClick(): void {
      if (this.$isMobile()) {
        this.sendToFlutter('{"event": "connectAccount"}')
        return
      }

      this.$router.push({ name: 'CrossAccountLinkingWeb' })
    },
  },
})
</script>

<style lang="scss" scoped>
.connected-accounts {
  .account-icon {
    $icon-size: 5.75rem;

    @include background(
      url('#{$path-icons}/account/account-icons-184-v2.avif'),
      $icon-size $icon-size * 13
    );
    @include square($icon-size);

    &.is-google-account-icon {
      background-position: 0 $icon-size * 0;

      &.is-connected {
        background-position: 0 $icon-size * -1;
      }
    }

    &.is-apple-account-icon {
      background-position: 0 $icon-size * -3;

      &.is-connected {
        background-position: 0 $icon-size * -4;
      }
    }

    &.is-facebook-account-icon {
      background-position: 0 $icon-size * -6;

      &.is-cannot-be-verified {
        background-position: 0 $icon-size * -7;
      }

      &.is-connected {
        background-position: 0 $icon-size * -7;
      }
    }

    &.is-email-account-icon {
      background-position: 0 $icon-size * -9;

      &.is-verification-pending {
        background-position: 0 $icon-size * -10;
      }

      &.is-connected {
        background-position: 0 $icon-size * -10;
      }
    }

    &.is-not-connected {
      opacity: 0.5;
    }
  }
}

// Ked zacneme pouzivat mode: 'jit', tak toto moze ist doprec.
// https://v2.tailwindcss.com/docs/just-in-time-mode
.\!-top-2 {
  top: -0.5rem !important;
}
.\!-right-2 {
  right: -0.5rem !important;
}
</style>
