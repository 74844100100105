<template>
  <div class="training-trainer-detail app-page-wrapper absolute" data-cy="training-trainer-detail">
    <training-menu :is-sub-menu-active="true" :current="$getWebView('TrainingTrainerDetail')" />
    <section class="absolute w-full h-full safe-area">
      <main>
        <div class="view-trainer-header">
          <modal-head
            class="ml-9"
            :show-info="false"
            :title-tooltip="$t('trainingHall.trainerInfo')"
          >
            <discipline-menu
              id-discipline="training-tutorial-menu"
              :progress="true"
              :type="TRAINING_HALL"
              :show-completed-icons="true"
              :show-only-trainer="true"
              :emit-click-event="true"
              @on-click-discipline="getDisciplineContent"
            />
            <div class="absolute right-0">
              <app-timer
                v-if="activeDiscipline"
                v-tippy="{
                  theme: 'WSM',
                  content: $t('trainingHall.newDisciplinesIn'),
                  placement: 'top',
                  textAlign: 'center',
                }"
                :time="activeDiscipline?.expiration ?? null"
              />
            </div>
            <template #popupContent>
              <training-popup />
            </template>
          </modal-head>
        </div>
        <training-discipline
          :mini-game-mode="mode.task_training_trainer"
          :discipline="activeDiscipline"
          :is-trainer="true"
          :active-discipline-id="activeDisciplineId"
        >
          <template #section-header>
            <div class="trainer-section-tp flexing">
              <p class="font-bold text-50 text-texts-standard-default">3x</p>
              <app-main-icon :icon-size="56" :icon-name="'training_points'" class="-ml-1" />
            </div>
          </template>
        </training-discipline>
      </main>
      <training-discipline-footer :benefits-slots="benefitTrainingHallSlots" :is-trainer="true" />
    </section>
  </div>
</template>

<script lang="ts">
import DisciplineMenu from '@/components/DisciplineMenu.vue'
import ModalHead from '@/components/ModalHead.vue'
import TrainingDiscipline from '@/components/Training/TrainingDiscipline.vue'
import TrainingDisciplineFooter from '@/components/Training/TrainingDisciplineFooter.vue'
import TrainingPopup from '@/components/Training/TrainingPopup.vue'
import { BENEFITS_TRAINING_HALL, TRAINING_HALL } from '@/globalVariables'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useModeStore } from '@/store/pinia/modeStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import TrainingMenu from '@/views/training/TrainingMenu.vue'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { RouteLocationNormalized } from 'vue-router'
import type { Nullable } from '@/interfaces/utils'
import type { DisciplineMenuData } from '@/interfaces/DisciplineMenu'
import type { BenefitSlot, UserBenefitsSlotsApiResponse } from '@/interfaces/Benefits'

interface ComponentData {
  TRAINING_HALL: typeof TRAINING_HALL
  activeDisciplineId: Nullable<number>
  textsForTranslate: string[]
}

export default defineComponent({
  name: 'TrainingTrainerDetail',
  components: {
    TrainingMenu,
    TrainingDiscipline,
    DisciplineMenu,
    ModalHead,
    TrainingPopup,
    TrainingDisciplineFooter,
  },
  data(): ComponentData {
    return {
      TRAINING_HALL,
      activeDisciplineId: null,
      textsForTranslate: [
        'trainingHall.header',
        'common.building',
        'trainingHall.tutorials',
        'trainingHall.training',
        'trainingHall.trainer',
        'trainingHall.disciplines',
        'trainingHall.aboutTrainingHeaderInfo',
        'trainingHall.aboutTrainingIncreaseAttributesInfo',
        'trainingHall.aboutTrainingTpInfo',
        'trainingHall.aboutTrainingUniversalTpInfo',
        'trainingHall.newDisciplinesIn',
      ],
    }
  },
  computed: {
    ...mapState(useModeStore, { mode: 'getMode' }),
    ...mapState(useDisciplineStore, {
      firstUnlockedDisciplineId: 'getFirstUnlockedDisciplineId',
      activeDiscipline: 'getSpecificDiscipline',
    }),
    ...mapState(useResponseTaskStore, {
      benefitsSlots: 'getBenefitsSlots',
    }),
    benefitTrainingHall(): UserBenefitsSlotsApiResponse[] {
      return (
        this.benefitsSlots?.filter(
          (benefit: UserBenefitsSlotsApiResponse): boolean =>
            benefit.benefit_building === BENEFITS_TRAINING_HALL,
        ) ?? []
      )
    },
    benefitTrainingHallSlots(): BenefitSlot {
      const benefitTrainingHallDataSlots: BenefitSlot['slots'] = {}
      const benefitTrainingHallDataBenefitTypes: string[] = []

      this.benefitTrainingHall.forEach((element: UserBenefitsSlotsApiResponse) => {
        benefitTrainingHallDataSlots[element?.slot_id] = element.benefit
        benefitTrainingHallDataBenefitTypes.push(element.benefit_slot_type)
      })

      return {
        benefit_type: benefitTrainingHallDataBenefitTypes,
        slots: benefitTrainingHallDataSlots,
      }
    },
  },
  watch: {
    $route(to: RouteLocationNormalized, from: RouteLocationNormalized): void {
      if (to.name === this.$getWebView(this.$options.name) || to.query.reloadData) {
        this.setSpecificDiscipline(null)

        this.activeDisciplineId = Number(this.$route.params.id) || this.firstUnlockedDisciplineId
        this.loadSpecificDiscipline({
          disciplineId: this.activeDisciplineId,
          isTrainer: true,
        })

        if (from.name !== this.$getWebView('TrainingTrainerDetail')) {
          this.loadBenefitsSlots()
        }

        if (to.query.reloadData) {
          this.$router.replace({
            ...this.$router.currentRoute,
            query: {
              reloadData: undefined,
            },
          })
        }
      }
    },
  },
  created(): void {
    this.activeDisciplineId = isNaN(Number(this.$route.params.id))
      ? this.firstUnlockedDisciplineId
      : Number(this.$route.params.id)

    this.loadSpecificDiscipline({
      disciplineId: this.activeDisciplineId,
      isTrainer: true,
    })
    this.loadBenefitsSlots()
  },
  beforeUnmount(): void {
    this.setSpecificDiscipline(null)
  },
  methods: {
    ...mapActions(useDisciplineStore, ['setSpecificDiscipline', 'loadSpecificDiscipline']),
    ...mapActions(useResponseTaskStore, {
      loadBenefitsSlots: 'loadBenefitsSlots',
    }),
    getDisciplineContent(discipline: DisciplineMenuData): void {
      this.activeDisciplineId = discipline.id
    },
  },
})
</script>

<style lang="scss" scoped>
.view-trainer-header {
  margin-top: 2.25rem;
  width: 100%;
  height: 5.625rem;
  margin-bottom: 1.5625rem;
}

.trainer-section-tp {
  background: url($path-training + 'trainer/BG-BADGE-TP.avif') center no-repeat;
  width: 37.8125rem;
  height: 13.875rem;
  background-size: contain;
  margin: 0 auto;
  padding-left: 1rem;
  margin-bottom: -5rem;
  position: relative;
  top: -1.5rem;
}
</style>
