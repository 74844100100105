<template>
  <div class="w-full">
    <div class="arena-history-wrapper my-3">
      <header-info>{{ $t('arena.historyInfo') }}</header-info>
      <!-- TODO: Use existing reusable table component from clubs-->
      <div class="arena-history-table-head table-head-standard leading-8">
        <div class="arena-history-table-head-discipline text-left">
          {{ $t('common.discipline') }}
        </div>
        <div class="arena-history-table-head-position">
          {{ $t('arena.yourPosition') }}
        </div>
        <div class="arena-history-table-head-time">
          {{ $t('common.time') }}
        </div>
        <div class="arena-history-table-head-type">
          {{ $t('common.type') }}
        </div>
        <div class="arena-history-table-head-super">
          {{ $t('arena.supermatch') }}
        </div>
        <div class="arena-history-table-head-detail" />
      </div>
    </div>
    <aside v-if="history?.length > 0">
      <app-scrollbar width="98%" height="44rem" scroll="y" slide="y" class="mx-auto relative">
        <div class="arena-history-table table-standard italic">
          <div
            v-for="record in history"
            :key="record.matchId"
            class="arena-history-table-row table-standard-row flex items-center"
          >
            <div
              class="arena-history-table-col arena-history-discipline uppercase font-bold flex items-center"
            >
              <app-discipline-icon
                :discipline-id="record.disciplineId"
                :size="70"
                class="arena-history-discipline-logo inline-block"
              />
              <span class="arena-history-discipline-name text-32">
                {{ $translateDiscipline(record.disciplineId) }}
              </span>
            </div>
            <div class="arena-history-table-col arena-history-position font-bold">
              {{ record.position }}
            </div>
            <div class="arena-history-table-col arena-history-time text-32">
              <app-time-formatter :timer="record.date" />
            </div>
            <div class="arena-history-table-col arena-history-type text-32">
              {{
                record.type === ArenaMatchReportType.Live
                  ? $t('arena.liveDuel')
                  : $t('arena.simDuel')
              }}
            </div>
            <div class="arena-history-table-col arena-history-super flexing">
              <app-icon v-if="record.supermatch" icon-name="done-md" />
              <div v-else>---</div>
            </div>
            <div class="arena-history-table-col arena-history-detail">
              <app-button
                :btn_type="'primary'"
                btn_size="md"
                :btn-text="$t('button.detail')"
                @click="loadDetail(record.matchId, record.disciplineId)"
              />
            </div>
          </div>
        </div>
      </app-scrollbar>
      <div class="table-bottom-shadow table-bottom-shadow-width" />
    </aside>
    <div v-else class="arena-history-table empty italic flex justify-center flex-col">
      <component-loading :is-loading="isContentLoading" />
      <div v-if="!isContentLoading" class="arena-history-empty-text-2">
        {{ $t('arena.noHistory') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import HeaderInfo from '@/components/Header/HeaderInfo.vue'
import { ArenaMatchReportType } from '@/interfaces/reports/ArenaMatchReport'
import type ArenaMatchHistoryReportApiResponse from '@/interfaces/responses/reports/ArenaMatchHistoryReportApiResponse'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
interface ComponentData {
  ArenaMatchReportType: typeof ArenaMatchReportType
}

export default defineComponent({
  name: 'MatchesHistory',
  components: {
    HeaderInfo,
  },
  props: {
    history: {
      type: Object as PropType<Nullable<ArenaMatchHistoryReportApiResponse>>,
      default: null,
    },
    redirectLink: {
      type: String,
      default: 'ArenaReport',
    },
  },
  data(): ComponentData {
    return {
      ArenaMatchReportType,
    }
  },
  computed: {
    isContentLoading(): boolean {
      return this.history == null
    },
  },
  methods: {
    loadDetail(matchId: string, disciplineId: number): void {
      if (matchId && disciplineId) {
        this.$router.push({
          name: this.$getWebView(this.redirectLink),
          query: {
            match_id: matchId,
            discipline_id: disciplineId.toString(),
            redirectFrom: this.$route.query.redirectFrom ?? undefined,
          },
        })
      }
    },
  },
})
</script>

<style lang="scss">
@import '@/assets/styles/views/arena/history.scss';
</style>
