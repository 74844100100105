<template>
  <span @click="emitSort">
    <slot />
    <section
      class="sort-arrow"
      :class="[
        state === 1 && 'sort-arrow-up',
        state === 2 && 'sort-arrow-down',
        state !== 0 ? 'highlighted' : 'sort-arrow-right',
      ]"
    />
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

interface ComponentData {
  state: number
  availableStates: number[]
}

export default defineComponent({
  name: 'SortArrow',
  props: {
    myPosition: {
      type: Number,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['stateChanged'],
  data(): ComponentData {
    return {
      state: 0,
      availableStates: [0, 1, 2],
    }
  },
  watch: {
    active(newVal: boolean): void {
      if (!newVal) this.state = 0
    },
  },
  methods: {
    emitSort(): void {
      this.state++
      if (!this.availableStates.includes(this.state)) this.state = this.availableStates[0]
      this.$emit('stateChanged', { state: this.state, position: this.myPosition })
    },
  },
})
</script>

<style lang="scss" scoped>
span {
  cursor: pointer;
}

.sort-arrow {
  width: 0;
  height: 0;
  border-left: 0.7rem solid transparent;
  border-right: 0.7rem solid transparent;
  border-bottom: 0.7rem solid white;
  margin-left: 0.5rem;

  &.highlighted {
    border-bottom-color: theme('colors.texts.standard.important');
  }
}

.sort-arrow-right {
  transform: rotate(90deg);
}

.sort-arrow-up {
  transform: unset;
}

.sort-arrow-down {
  transform: rotate(180deg);
}
</style>
