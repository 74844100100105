<template>
  <div class="view-training-tutorial app-page-wrapper absolute">
    <training-menu />
    <section class="w-full h-full safe-area">
      <div class="training-tutorial-content">
        <modal-head class="ml-3" :title-popup="$t('trainingHall.aboutTrainingHeaderInfo')">
          <span class="text-texts-standard-default font-bold uppercase italic z-1 text-48">
            {{ $t('discipline.chooseDiscipline') }}
          </span>
          <template #popupContent>
            <training-popup />
          </template>
        </modal-head>

        <div v-if="!isContentLoading">
          <div class="training-tutorial-wrapper">
            <app-scrollbar
              class="flex items-center"
              width="100%"
              height="101%"
              scroll="x"
              slide="x"
              :arrows="true"
            >
              <div class="training-tutorial-boxes">
                <training-tutorial-box
                  v-for="(discipline, i) in disciplines"
                  :key="i"
                  :data="discipline"
                  :is-active="activeDiscId == discipline.id"
                  @select-item="selectItem(discipline.id)"
                />
              </div>
            </app-scrollbar>
          </div>
          <footer class="items-center">
            <div class="footer-info-panel">
              <info-text :text="$t('trainingHall.tutorialInfo')" />
            </div>
            <app-button
              btn-id="training-tutorial-play"
              btn-type="secondary"
              :btn-text="$t('trainingHall.playTutorial')"
              btn-size="md"
              :disabled="activeDiscId === 0"
              :loading="isMinigame"
              @click="
                activeDiscId > 0
                  ? openMiniGame(
                      minigameUrl,
                      {
                        params: {
                          mode: mode.training_tutorial,
                          discipline_id: activeDiscId,
                        },
                      },

                      $router,
                    )
                  : {}
              "
            />
          </footer>
        </div>

        <component-loading height="75%" :is-loading="isContentLoading" />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import TrainingTutorialBox from '@/components/Training/TrainingTutorialBox.vue'
import InfoText from '@/components/InfoText.vue'
import ModalHead from '@/components/ModalHead.vue'
import TrainingPopup from '@/components/Training/TrainingPopup.vue'
import { minigameUrl } from '@/globalVariables'
import { openMiniGame } from '@/helpers'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useModeStore } from '@/store/pinia/modeStore'
import { useTrainingDisciplineStore } from '@/store/pinia/trainingDisciplinesStore'
import TrainingMenu from '@/views/training/TrainingMenu.vue'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  minigameUrl: typeof minigameUrl
  activeDiscId: number
  popupText: string
  textsForTranslate: string[]
}

export default defineComponent({
  name: 'TrainingTurorial',
  components: {
    TrainingMenu,
    ModalHead,
    TrainingTutorialBox,
    TrainingPopup,
    InfoText,
  },
  data(): ComponentData {
    return {
      minigameUrl,
      activeDiscId: 0,
      popupText: '',
      textsForTranslate: [
        'discipline.chooseDiscipline',
        'trainingHall.header',
        'common.building',
        'trainingHall.tutorials',
        'trainingHall.training',
        'trainingHall.aboutTrainingHeaderInfo',
        'trainingHall.aboutTrainingIncreaseAttributesInfo',
        'trainingHall.aboutTrainingTpInfo',
        'trainingHall.aboutTrainingUniversalTpInfo',
      ],
    }
  },
  computed: {
    ...mapState(useModeStore, { mode: 'getMode' }),
    ...mapState(useCoreStore, ['isMinigame']),
    ...mapState(useTrainingDisciplineStore, {
      disciplines: 'getDisciplines',
    }),
    isContentLoading(): boolean {
      return this.disciplines.length === 0
    },
  },
  async created(): Promise<void> {
    await this.loadDisciplines()
  },
  methods: {
    ...mapActions(useTrainingDisciplineStore, ['loadDisciplines']),
    openMiniGame,
    selectItem(id: number): void {
      this.activeDiscId = id
    },
  },
})
</script>

<style lang="scss" scoped>
.training-tutorial-content {
  position: relative;
  height: 81%;

  .tutorial-head {
    width: 116.1875rem;
    margin: 0 auto;
    margin-top: 1rem;
  }

  .training-tutorial-wrapper {
    height: 75%;
    width: 98%;
    margin: 1rem auto 1.5rem;

    .ps {
      width: 100%;
    }

    .training-tutorial-boxes {
      flex-flow: column wrap;
      display: flex;
      width: 28rem;
      height: 40rem;
      margin-bottom: 0.8rem;
    }
  }

  footer {
    margin: 0 auto;
    margin-top: 1.0675rem;
    width: 96%;
    display: flex;
    justify-content: space-between;

    .footer-info-panel {
      width: 75rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &-text {
        width: 92%;
        margin: 0 auto;
        display: flex;
      }
    }
  }
}

.default-font-size {
  font-size: 2rem;
}
</style>
