import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const carsRightToBottomTunelAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'carsRightToBottomTunelAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '10',
      delay: 0,
      duration: 1500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 10,
        end: 11,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightToBottomTunelAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '11',
      delay: 0,
      duration: 1500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 11,
        end: 10,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightToBottomTunelAnimations3',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '10',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 10,
        end: 9,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightToBottomTunelAnimations4',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '09',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 9,
        end: 4,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightToBottomTunelAnimations5',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '04',
      delay: 0,
      duration: 1600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 4,
        end: 6,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightToBottomTunelAnimations6',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '06',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 6,
        end: 8,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightToBottomTunelAnimations7',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '08',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 8,
        end: 6,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightToBottomTunelAnimations8',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '06',
      delay: 0,
      duration: 1300,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 6,
        end: 4,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightToBottomTunelAnimations9',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '04',
      delay: 0,
      duration: 2300,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 4,
        end: 2,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightToBottomTunelAnimations10',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '02',
      delay: 0,
      duration: 2000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 2,
        end: 24,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightToBottomTunelAnimations11',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '24',
      delay: 0,
      duration: 1600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 24,
        end: 17,
        zeroPad: 2,
      },
    },
  },
]
