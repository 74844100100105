<template>
  <div class="bossfight-info w-1/2">
    <div class="avatar m-auto flexing" :class="[!boss ? 'avatar--left' : 'avatar--right']">
      <div class="avatar-inside" :style="setBackground" />
      <div v-if="boss" class="avatar-boss absolute" />
    </div>
    <div :class="[!boss ? 'mr-20' : 'ml-20']">
      <article class="name relative flexing" :class="[!boss ? 'name--left' : 'name--right']">
        <app-user
          class="flex items-center justify-center"
          :country="getCountry"
          :no-country-text="true"
          :name="getName"
          user-class="text-50"
        />
      </article>
      <article
        class="discipline-info relative text-60 text-texts-standard-default flex items-center justify-center"
        :class="[!boss ? 'discipline-info--left' : 'discipline-info--right']"
      >
        <div :class="`discipline-info-icon icon-discipline-${bossInfo.discipline_id}-light-70`" />
        <p v-if="boss || !isLoading" class="font-bold ml-4">{{ getDisciplineScore }}</p>
        <tippy
          :key="bossInfo.discipline_id"
          theme="WSM1 bossfight"
          placement="right-end"
          max-width="50rem"
          append-to="parent"
        >
          <app-icon v-show="!boss" icon-name="info-50" class="info-icon" />
          <template #content>
            <div class="tooltip-content">
              <div class="lock-tooltip-title flex items-center justify-center">
                <p class="uppercase font-bold text-30">
                  {{ $t('common.totalPower') }}
                </p>
              </div>
              <div
                class="tooltip-content tooltip-report text-texts-standard-default flex flex-col justify-end"
              >
                <div
                  class="tooltip-discipline-info text-60 text-texts-standard-default flex items-center justify-center"
                >
                  <div
                    :class="`discipline-info-icon icon-discipline-${bossInfo.discipline_id}-light-70`"
                  />
                  <span
                    v-if="boss || !isLoading"
                    class="text-texts-standard-important font-bold ml-5"
                  >
                    {{ getDisciplineScore }}
                  </span>
                </div>
                <div v-if="activeDisciplineStats">
                  <div v-for="(attribute, key) in attributesTooltip" :key="key">
                    <div
                      class="tooltip-report-row flex items-center justify-between"
                      :class="{ 'with-line': key < attributesTooltip.length - 1 }"
                    >
                      <div
                        class="text-texts-standard-default text-left pl-6 tooltip-report-row-first"
                      >
                        {{ attribute.text }}
                      </div>
                      <div
                        class="value-text text-center tooltip-report-row-second text-texts-standard-important"
                      >
                        {{ formatUserBossfightValue(attribute.value) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </tippy>
      </article>
    </div>
  </div>
</template>

<script lang="ts">
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import { pathImages } from '@/globalVariables'
import type TaskchainStateApiResponse from '@/interfaces/responses/events/TaskchainStateApiResponse'
import { useEventBossFightsStore } from '@/store/pinia/events/bossFightStore'
import { useTrainingDisciplineStore } from '@/store/pinia/trainingDisciplinesStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { Nullable } from '@/interfaces/utils'

import type Discipline from '@/interfaces/global/Discipline'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'BossFightInfo',
  components: { AppUser },
  props: {
    bossInfo: {
      type: Object as PropType<Nullable<TaskchainStateApiResponse['boss_data']>>,
      default: () => null,
    },
    boss: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userInfo: 'getUserData',
      userAttribute: 'getAttributes',
    }),
    ...mapState(useTrainingDisciplineStore, {
      disciplines: 'getDisciplines',
      isLoading: 'getIsLoading',
    }),
    ...mapState(useEventBossFightsStore, {
      getBenefitInfo: 'getEventBenefitInfo',
    }),
    setBackground(): {
      backgroundImage: string
      backgroundSize: string
    } {
      return {
        backgroundImage: this.boss
          ? `url(${pathImages}events/avatars/${this.bossInfo.sex}/${this.bossInfo.avatar_id}.avif)`
          : `url(${pathImages}avatars/${this.userAttribute.sex}/${this.userAttribute.avatar}.avif)`,
        backgroundSize: 'contain',
      }
    },
    getName(): string {
      return this.boss ? this.bossInfo.name : this.userInfo.username
    },
    getCountry(): string {
      return this.boss ? this.bossInfo.country : this.userInfo.country.three_letter_code
    },
    activeDisciplineStats(): Discipline {
      return this.disciplines.find(
        (discipline: Discipline): boolean => discipline.id === this.bossInfo.discipline_id,
      )
    },
    getDisciplineScore(): number {
      return this.boss
        ? this.bossInfo.boss_attribute.value
        : this.activeDisciplineStats.totalStrength + (Number(this.getBenefitInfo) ?? 0)
    },
    attributesTooltip(): {
      text: string
      value: number
    }[] {
      const disciplineAttributes = this.activeDisciplineStats.attributes
      if (!disciplineAttributes) return []
      return [
        {
          text: this.$t('common.basicAttribute'),
          value: disciplineAttributes.BASE,
        },
        {
          text: this.$t('common.equipment'),
          value:
            disciplineAttributes.EQUIPMENT +
            disciplineAttributes.EQUIPMENT_PREMIUM_UPGRADE +
            disciplineAttributes.EQUIPMENT_UPGRADE,
        },
        {
          text: this.$t('common.research'),
          value:
            disciplineAttributes.RESEARCH_BASE +
            disciplineAttributes.RESEARCH_PREMIUM +
            disciplineAttributes.RESEARCH_REPEATABLE,
        },
        {
          text: this.$t('common.benefit'),
          value: disciplineAttributes.BENEFIT + (Number(this.getBenefitInfo) ?? 0),
        },
        {
          text: this.$t('common.seasonalAttribute'),
          value: disciplineAttributes.SEASONAL,
        },
      ]
    },
  },
  async created(): Promise<void> {
    if (this.boss) return
    await this.loadDisciplines(this.userInfo.id)
  },
  methods: {
    ...mapActions(useTrainingDisciplineStore, ['loadDisciplines']),
    formatUserBossfightValue(value: number): number | string {
      return value > 0 ? value : '-'
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/components/icons/discipline-icons.scss';

.bossfight-info {
  padding-top: 2rem;

  .avatar {
    width: 25.0625rem;
    height: 21.625rem;
    @include background(
      url('#{$path-events}shared/taskchain/tasks/bossFight/bossfight-circle-avatar.avif'),
      contain
    );
    position: relative;
    top: 1rem;

    &--left {
      margin-left: 7rem;
    }

    &--right {
      margin-right: 7rem;
    }

    &-inside {
      position: relative;
      width: 17.5rem;
      height: 17.5rem;
      top: 0.525rem;
      left: -0.325rem;
    }

    &-boss {
      width: 12rem;
      height: 2.625rem;
      bottom: 1.4rem;
      left: 50%;
      transform: translateX(-50%);
      @include background(
        url('#{$path-events}shared/taskchain/tasks/bossFight/bossfight-text-boss.avif'),
        contain
      );
    }
  }

  .name {
    height: 4.5625rem;
    margin-bottom: 0.25rem;
    margin-top: 4rem;
    background: linear-gradient(to right, rgba(28, 52, 90, 0.33), transparent);

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 0.1875rem;
      background: linear-gradient(to right, rgba(52, 186, 255, 0.33), transparent);
      width: 100%;
      left: 0;
    }

    &--right {
      background: linear-gradient(to left, rgba(28, 52, 90, 0.33), transparent);

      &::before,
      &::after {
        background: linear-gradient(to left, rgba(52, 186, 255, 0.33), transparent);
      }
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  .discipline-info {
    height: 6.125rem;
    background: linear-gradient(to right, rgba(74, 93, 120, 0.5), transparent);

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 0.1875rem;
      background: linear-gradient(to right, rgba(52, 186, 255, 0.33), transparent);
      width: 100%;
      left: 0;
    }

    &--right {
      background: linear-gradient(to left, rgba(74, 93, 120, 0.33), transparent);

      &::before,
      &::after {
        background: linear-gradient(to left, rgba(52, 186, 255, 0.33), transparent);
      }
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    &-icon {
      width: 3.75rem;
      height: 4.375rem;
    }

    .info-icon {
      margin-left: 1.313rem;
    }
  }
}

.tooltip-report {
  height: auto;
}

.tooltip-discipline-info {
  position: relative;
  margin: 1.5rem auto;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12.813rem;
    height: 3.313rem;
    border-radius: 50%;
    background: #377fb8;
    filter: blur(15px);
    -webkit-filter: blur(15px);
    z-index: -1;
    transform: translateX(-50%) translateY(-50%);
  }
}

.discipline-info-icon {
  width: 3.75rem;
  height: 4.375rem;
}

.lock-tooltip-title {
  height: 4.25rem;

  &::after {
    display: none;
  }
}
</style>
