<template>
  <confirm-popup
    :popup-title="$t('common.connectionLost')"
    :button-data="btnChange"
    :hide-close="true"
    :not-multi="true"
    single-button-text="OK"
  >
    <section class="flexing flex-col">
      <div class="icon-connection-lost" />
      <p class="main text-texts-standard-default text-36 p-4">
        {{ $t('common.connectionLostMore') }}
      </p>
    </section>
  </confirm-popup>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ConfirmPopup from './ConfirmPopup.vue'

interface ComponentData {
  btnChange: {
    btnId: string
    btnType: string
    btnSize: string
  }
}

export default defineComponent({
  name: 'OfflinePopup',
  components: { ConfirmPopup },
  data(): ComponentData {
    return {
      btnChange: {
        btnId: 'btn-changed',
        btnType: 'secondary',
        btnSize: 'xl',
      },
    }
  },
})
</script>

<style lang="scss" scoped>
.scale-popup {
  transform: translate(-50%, -50%) scale(0.7);
}

.icon-connection-lost {
  margin-top: 0.5rem;
  background-size: contain;
}
</style>
