<template>
  <div v-if="visibleAnnouncement" class="map-announcement">
    <header>
      <div class="header-text">
        <p class="text-texts-standard-default text-30 uppercase">
          {{ $t('club.informationMenu') }}
        </p>
      </div>
      <app-control-icon control="close" class="announcement-close" @click="hide" />
    </header>
    <main>
      <app-scrollbar
        class="map-announcement-scrollbar"
        width="100%"
        height="100%"
        scroll="y"
        slide="y"
      >
        <p class="text-texts-standard-default text-24">
          {{ getNotice.message }}
        </p>
      </app-scrollbar>
    </main>
  </div>
</template>

<script lang="ts">
import { useClubNoticeStore } from '@/store/pinia/clubs/noticeStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  visibleAnnouncement: boolean
}

export default defineComponent({
  name: 'MapAnnouncement',
  data(): ComponentData {
    return {
      visibleAnnouncement: false,
    }
  },
  computed: {
    ...mapState(useClubNoticeStore, {
      getNotice: 'getCurrentNotice',
    }),
  },
  async created(): Promise<void> {
    await this.loadNotice()
    this.isNoticeVisible()
  },
  methods: {
    ...mapActions(useClubNoticeStore, ['loadNotice']),
    isNoticeVisible(): void {
      if (
        this.getNotice.message &&
        this.getNotice.message.length > 0 &&
        localStorage.getItem('clubAnnouncementHide') === null
      ) {
        this.visibleAnnouncement = true
      } else this.visibleAnnouncement = false
    },
    hide(): void {
      this.visibleAnnouncement = false
      localStorage.setItem('clubAnnouncementHide', 'true')
    },
  },
})
</script>

<style lang="scss" scoped>
.map-announcement {
  width: 31.25rem;
  height: 13.438rem;
  box-shadow: 0.4px 3px 3px 0 rgba(0, 0, 0, 0.59);
  position: absolute;
  bottom: 1rem;
  right: 1.5rem;

  @if $isWsm {
    background: linear-gradient(to top, rgba(44, 84, 120, 0.7), rgba(95, 168, 218, 0.7)),
      linear-gradient(to bottom, rgba(27, 47, 71, 0.7), rgba(27, 47, 71, 0.7));
    border: solid 0.125rem #75b6e7;
  }
  @if $isSsm {
    background: rgba(#232a40, 0.6);
    border: solid 0.125rem #4c648f;
  }

  header {
    width: 100%;
    height: 2.813rem;
    background: url($path-clubs + 'map/bg-annoucement-header.avif') center no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;

    .header-text {
      width: 83%;
      text-align: left;
      margin-left: 1.5rem;
      position: relative;

      &:after {
        content: '';
        border-left: 0.313rem solid#fadd1e;
        height: 1.813rem;
        position: absolute;
        top: 0.5rem;
        left: -0.75rem;
        @if $isSsm {
          border-color: #feb942;
        }
      }
    }

    .announcement-close {
      transform: scale(0.55);
      margin-right: -1rem;
    }
  }

  main {
    height: 10.438rem;
    padding: 0.85rem 0.25rem 0.125rem 1.25rem;
    text-align: left;
    line-height: 1.85rem;

    & p {
      width: 97%;
    }
  }
}
</style>
