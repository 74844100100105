<template>
  <section
    v-if="getModalState.isModalOpen"
    class="events-warehouse-session-message flexing flex-col absolute safe-area"
    :class="
      getModalState.isCraftedSuccessfully
        ? 'events-warehouse-session-message-success-bg'
        : 'events-warehouse-session-message-fail-bg'
    "
  >
    <div
      v-if="!getModalState.isCraftedSuccessfully"
      class="events-warehouse-session-message-top-img-fail flexing"
    >
      <img
        :src="`${pathImages}events/frozen-championship/warehouse/session-messages/artefacts/fail/${getModalState.selectedArtefact.icon}-fail.avif`"
      />
    </div>

    <div
      v-if="getModalState.isCraftedSuccessfully"
      class="events-warehouse-session-message-top-img-success flexing"
      :class="{ 'mobile-image-margin ': $isMobile() }"
    >
      <img
        v-if="getModalState.isChest"
        :src="`${pathImages}events/frozen-championship/warehouse/session-messages/artefacts/success/chest-success.webp`"
      />
      <img
        v-else
        :src="`${pathImages}events/frozen-championship/warehouse/session-messages/artefacts/success/${getModalState.selectedArtefact.icon}-success.webp`"
      />
    </div>

    <div class="events-warehouse-session-message-content flex flex-col">
      <div
        v-if="!getModalState.isCraftedSuccessfully && getModalState?.missingIngredients?.length > 0"
        class="events-warehouse-session-message-content-missing"
      >
        <div class="events-warehouse-session-message-content-missing-title flexing">
          <p class="text-texts-standard-important text-50 uppercase font-bold">
            {{ $te('missingParts') }}
          </p>
        </div>
        <div class="events-warehouse-session-message-content-missing-item flexing">
          <div
            v-for="(missingIngredient, key) in getModalState.missingIngredients"
            :key="key"
            class="flexing ml-6 mr-6"
          >
            <app-icon
              class="events-warehouse-session-message-content-missing-item-icon mr-4"
              :icon-name="missingIngredient.ingredient?.icon"
              :tooltip-text="
                $te(missingIngredient.ingredient?.name) ?? $t(missingIngredient.ingredient?.name)
              "
            />
            <p class="text-texts-standard-danger text-72 font-bold">
              {{ missingIngredient.amount }}
            </p>
          </div>
        </div>
      </div>

      <div class="events-warehouse-session-message-content-message">
        <div class="events-warehouse-session-message-content-message-title flexing">
          <p class="text-texts-standard-default text-50 mr-4 font-bold">
            {{ getModalState.multiplier }}x
          </p>
          <p class="text-texts-standard-important text-50 uppercase font-bold">
            {{ $te(getModalState.selectedArtefact.name) }}
          </p>
        </div>

        <div class="events-warehouse-session-message-content-message-main">
          <div class="events-warehouse-session-message-content-message-main-text flexing">
            <p class="text-40 gradient-text-light uppercase font-bold">
              {{
                getModalState.isCraftedSuccessfully
                  ? $t('common.youReceived')
                  : $replacePlaceholder(
                      $te('getPartsPackage'),
                      '%s',
                      getPackageStartsEquivalent.toString(),
                    )
              }}
            </p>
          </div>
          <div
            v-if="!getModalState.isCraftedSuccessfully"
            class="events-warehouse-session-message-content-message-main-buttons flexing"
          >
            <event-button
              id="btn-buy-event-chest"
              class="mr-4"
              :disabled="isBuyButtonDisabled || eventCollectionActive"
              @click="useMultiplier"
            >
              <template #leftSide>
                {{ chestMultiplier }}X
                <app-icon icon-name="chest" class="ml-4 mt-1" />
              </template>
              <template #rightSide>
                <span class="text-50">+</span>
              </template>
            </event-button>
            <app-button
              class="events-warehouse-session-message-content-message-main-buttons-gems ml-4"
              btn-id="crafting-message-main-buttons-gems"
              btn-type="credit"
              :loading="isBuyButtonDisabled"
              :disabled="isBuyButtonDisabled || eventCollectionActive"
              @click="showModal"
            >
              <div class="flex items-center justify-center">
                <p>{{ packageCost * chestMultiplier }}</p>
                <app-main-icon :icon-size="56" :icon-name="'gems'" />
              </div>
            </app-button>
          </div>
          <div
            v-if="getModalState.isCraftedSuccessfully"
            class="events-warehouse-session-message-content-message-main-output flexing"
          >
            <div v-for="(output, index) in getModalState.rewards" :key="index" class="flexing">
              <p class="text-60 text-texts-standard-default mx-3">
                {{ $filters.$formatNumber(getModalState.isChest ? output.amount : output.value) }}
              </p>

              <app-icon
                v-if="getModalState.isChest || output?.type === COMMODITY_POINTS"
                :icon-name="
                  output.type === COMMODITY_POINTS
                    ? getCommodityPointsConfig.icon
                    : output.ingredient.icon
                "
                :tooltip-text="
                  $te(
                    output.type === COMMODITY_POINTS
                      ? getCommodityPointsConfig.name
                      : output.ingredient.name,
                  )
                "
                class="mr-12 events-warehouse-session-message-content-message-main-output-icon"
              />
              <app-main-icon
                v-else
                :icon-name="output.type"
                :icon-size="72"
                class="mr-12 events-warehouse-session-message-content-message-main-output-icon"
              />
            </div>
          </div>
        </div>
      </div>

      <app-button
        class="events-warehouse-session-message-content-continue-btn"
        btn-id="crafting-message-continue-btn"
        btn-type="confirm"
        :btn-text="$t('button.continue')"
        @click="setModalState({ isModalOpen: false })"
      />
    </div>
  </section>

  <no-gems-popup :is-visible="isNoGemsModalVisible" @close="isNoGemsModalVisible = false" />
  <confirm-package-buy-popup
    :is-visible="isConfirmModalVisible"
    :price="packageCost * chestMultiplier"
    @close="isConfirmModalVisible = false"
    @click="buyPackageChest"
  />
</template>

<script lang="ts">
import { COMMODITY_POINTS, pathImages } from '@/globalVariables'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useWarehouseStore } from '@/store/pinia/events/warehouseStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import NoGemsPopup from '../../Popup/NoGemsPopup.vue'
import EventButton from '../EventButton.vue'
import ConfirmPackageBuyPopup from '../EventsPopups/ConfirmPackageBuyPopup.vue'
import type { ArtefactCraftingModalState } from '@/store/pinia/events/warehouseStore'
import type { ArtefactBuild } from '@/interfaces/responses/events/warehouse/WarehouseConfigApiResponse'

interface ComponentData {
  COMMODITY_POINTS: typeof COMMODITY_POINTS
  pathImages: typeof pathImages
  chestMultiplierIndex: number
  chestMultiplier: number
  isNoGemsModalVisible: boolean
  isConfirmModalVisible: boolean
  isBuyButtonDisabled: boolean
}

export default defineComponent({
  name: 'EventsWarehouseLabSessionMessage',
  components: {
    ConfirmPackageBuyPopup,
    EventButton,
    NoGemsPopup,
  },
  data(): ComponentData {
    return {
      COMMODITY_POINTS,
      pathImages,
      chestMultiplierIndex: 0,
      chestMultiplier: 1,
      isNoGemsModalVisible: false,
      isConfirmModalVisible: false,
      isBuyButtonDisabled: false,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      userGems: 'getGems',
    }),
    ...mapState(useWarehouseStore, {
      _getModalState: 'getModalState',
      packageCost: 'getPackageCost',
      getPackageMultipliers: 'getPackageMultipliers',
      getPackageStartsEquivalent: 'getPackageStartsEquivalent',
      getCommodityPointsConfig: 'getCommodityPointsConfig',
    }),
    ...mapState(useEventInfoStore, {
      eventCollectionActive: 'getEventCollectionActive',
    }),
    getModalState(): Omit<ArtefactCraftingModalState, 'selectedArtefact'> & {
      selectedArtefact: ArtefactBuild
    } {
      return this._getModalState as Omit<ArtefactCraftingModalState, 'selectedArtefact'> & {
        selectedArtefact: ArtefactBuild
      }
    },
  },
  methods: {
    ...mapActions(useWarehouseStore, {
      buyPackage: 'buyPackage',
      setModalState: 'setWarehouseModalState',
    }),
    useMultiplier(): number[] | void {
      if (this.getPackageMultipliers.length === 0) return [1]

      const enabledValues: number[] = this.getPackageMultipliers

      this.chestMultiplierIndex = (this.chestMultiplierIndex + 1) % enabledValues.length
      this.chestMultiplier = enabledValues[this.chestMultiplierIndex]
    },
    showModal(): void {
      if (this.userGems.value >= this.packageCost * this.chestMultiplier) {
        this.isConfirmModalVisible = true
      } else {
        this.isNoGemsModalVisible = true
      }
    },
    async buyPackageChest(): Promise<void> {
      this.isBuyButtonDisabled = true
      this.isConfirmModalVisible = false
      await this.buyPackage({ gems: true, multiplier: this.chestMultiplier })
      this.isBuyButtonDisabled = false
    },
  },
})
</script>

<style lang="scss" scoped>
.events-warehouse-session-message {
  height: 100%;
  width: 100%;
  z-index: 10;
  position: absolute;
  top: 0;
  padding-bottom: 4rem;

  &-success-bg {
    @include background(
      url($path-events + 'frozen-championship/warehouse/crafting-message-success.avif'),
      cover
    );
  }

  &-fail-bg {
    @include background(
      url($path-events + 'frozen-championship/warehouse/crafting-message-fail.avif'),
      cover
    );
  }

  &-top-img-fail {
    width: 25rem;
    height: auto;
    margin: 0 auto;
  }

  &-top-img-success {
    margin: 0 auto;
    margin-bottom: -16rem;
    width: 100%;

    img {
      width: auto;
      height: 50rem;
    }
  }

  .mobile-image-margin {
    margin-bottom: -10rem;
  }

  &-content {
    width: 80%;
    margin: 0 auto;

    &-missing {
      &-title {
        height: 5.125rem;
        width: 100%;
        background: linear-gradient(
          90deg,
          rgba(113, 24, 28, 0) 0%,
          rgba(113, 24, 28, 1) 25%,
          rgba(113, 24, 28, 1) 75%,
          rgba(113, 24, 28, 0) 100%
        );

        @if $isWsm {
          box-shadow: 0px 7px 3px 0 rgb(0 0 0 / 80%);
        }
      }

      &-item {
        height: 10.75rem;
        width: 100%;
        background: linear-gradient(
          90deg,
          rgba(100, 1, 1, 0) 0%,
          rgba(100, 1, 1, 0.6) 25%,
          rgba(100, 1, 1, 0.6) 75%,
          rgba(100, 1, 1, 0) 100%
        );
        border-style: solid;
        border-width: 0.188rem;
        border-image-source: linear-gradient(
          90deg,
          rgba(104, 32, 40, 0) 0%,
          rgba(104, 32, 40, 1) 25%,
          rgba(104, 32, 40, 1) 75%,
          rgba(104, 32, 40, 0) 100%
        );
        border-image-slice: 1;
        margin-top: 0.2rem;
        margin-bottom: 0.625rem;

        &-icon {
          width: 4.5rem !important;
          height: 4.5rem !important;
        }
      }
    }

    &-message {
      &-title {
        height: 4.688rem;
        width: 100%;
        background: linear-gradient(
          90deg,
          rgba(140, 83, 2, 0) 0%,
          rgba(140, 83, 2, 1) 25%,
          rgba(140, 83, 2, 1) 75%,
          rgba(140, 83, 2, 0) 100%
        );
        border-style: solid;
        border-width: 0.188rem;
        border-image-source: linear-gradient(
          90deg,
          rgba(191, 123, 9, 0) 0%,
          rgba(191, 123, 9, 1) 25%,
          rgba(191, 123, 9, 1) 75%,
          rgba(191, 123, 9, 0) 100%
        );
        border-image-slice: 1;
        margin-bottom: 0.625rem;
      }

      &-main {
        height: 13rem;
        width: 100%;
        padding-top: 0.625rem;
        border-style: solid;
        border-width: 0.188rem;
        border-image-slice: 1;

        @if $isWsm {
          background-image: linear-gradient(
              to right,
              transparent 0%,
              rgba(22, 106, 189, 0.65) 25%,
              rgba(22, 106, 189, 0.65) 75%,
              transparent 100%
            ),
            url($path-events + 'frozen-championship/warehouse/crafting-message-small-bg.avif');
          border-image-source: linear-gradient(
            to right,
            transparent 0%,
            #338de5 25%,
            #338de5 75%,
            transparent 100%
          );
        }

        @if $isSsm {
          background-image: linear-gradient(
            to right,
            transparent 0%,
            rgba(22, 106, 189, 0.65) 25%,
            rgba(22, 106, 189, 0.65) 75%,
            transparent 100%
          );
          border-image-source: linear-gradient(
            to right,
            transparent 0%,
            #399dff 25%,
            #399dff 75%,
            transparent 100%
          );
        }

        &-text {
          height: 4.125rem;
          width: 100%;
          border-style: solid;
          border-width: 0.188rem;
          border-image-slice: 1;

          @if $isWsm {
            background: linear-gradient(
              to right,
              transparent 0%,
              rgba(90, 159, 226, 0.3) 25%,
              rgba(90, 159, 226, 0.3) 75%,
              transparent 100%
            );
            border-image-source: linear-gradient(
              90deg,
              transparent 0%,
              rgba(162, 209, 255, 0.3) 25%,
              rgba(162, 209, 255, 0.3) 75%,
              transparent 100%
            );
          }

          @if $isSsm {
            background-image: linear-gradient(
              to right,
              transparent 0%,
              rgba(90, 159, 226, 0.8) 25%,
              rgba(90, 159, 226, 0.8) 75%,
              transparent 100%
            );
            border-image-source: linear-gradient(
              to right,
              transparent 0%,
              rgba(162, 209, 255, 0.8) 25%,
              rgba(162, 209, 255, 0.8) 75%,
              transparent 100%
            );
          }
        }

        &-buttons {
          margin-top: 1.65rem;

          &-gems {
            width: 20.625rem !important;
          }
        }

        &-output {
          margin-top: 1.65rem;

          &-icon {
            width: 4.5rem !important;
            height: 4.5rem !important;
          }
        }
      }
    }

    &-continue-btn {
      margin: 0 auto;
      margin-top: 3.375rem;
    }
  }
}
</style>
