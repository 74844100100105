<template>
  <transition name="anim">
    <teleport to="#app">
      <!-- v-if="hasOverlay <= 1"  podmienka pre popupOverlay aby sa vykreslil len raz-->
      <div class="popup-overlay fixed" @click="closePopup" />
      <div class="popup-template bordered-1" :class="[classList, { 'web-scale': !$isMobile() }]">
        <popup-header :title-text="popupTitle" :hide-close="hideClose" @close="closePopup" />
        <div class="popup-template-box-content">
          <slot />
        </div>
      </div>
    </teleport>
  </transition>
</template>

<script lang="ts">
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import PopupHeader from './PopupHeader.vue'

export default defineComponent({
  name: 'PopupTemplate',
  components: {
    PopupHeader,
  },
  props: {
    classList: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    popupTitle: {
      type: String,
      default: '',
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],

  data() {
    return {
      mamOverlay: null,
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      isTutorial: 'getIsTutorial',
    }),
    unpackedClass(): string {
      return this.classList.join(',')
    },
  },
  methods: {
    closePopup(): void {
      if (this.isTutorial || this.hideClose) return
      this.$emit('close', false)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/popups.scss';

.web-scale {
  transform: translate(-50%, -50%) scale(0.7);
}

.anim-enter-from {
  opacity: 0;
  transform: scale(0.6);
}

.anim-enter-to {
  opacity: 1;
  transform: scale(1);
}

.anim-enter-active {
  transition: all 0.4s ease;
}
</style>
