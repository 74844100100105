<template>
  <div class="lab-cell cursor-pointer" @click="$emit('selectSkill', cellData)">
    <lab-connection-line
      v-if="cellData.parent || (!cellData.parent && cellData.positionLevel === 0)"
      :direction="LineDirection.Top"
      :top-height="lineType"
    />
    <lab-icons :icon-data="cellData" @reload-data="$emit('reloadData')" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LabIcons from '@/components/Lab/LabIcons.vue'
import LabConnectionLine from '@/components/Lab/LabConnectionLine.vue'
import type { PropType } from 'vue'
import type { Cell } from '@/interfaces/lab/Cell'
import { LineDirection, LineHeight } from '@/interfaces/lab/Cell'

interface ComponentData {
  LineDirection: typeof LineDirection
}

export default defineComponent({
  name: 'LabCampsColumn',
  components: {
    LabIcons,
    LabConnectionLine,
  },
  props: {
    cellData: {
      type: Object as PropType<Cell>,
      required: true,
    },
    lineType: {
      type: String as PropType<LineHeight>,
      default: LineHeight.Xs,
      validator(value: LineHeight): boolean {
        return [LineHeight.Xs, LineHeight.Sm, LineHeight.Md, LineHeight.Xl].includes(value)
      },
    },
  },
  emits: ['selectSkill', 'reloadData'],
  data(): ComponentData {
    return {
      LineDirection,
    }
  },
})
</script>

<style lang="scss" scoped>
.lab-cell:last-child::after {
  // Important: Offset required for used absolute positioning, visible from below behind the last child
  content: '';
  position: relative;
  display: block;
  height: 3rem;
}
</style>
