import { ORDER_TYPES, ORDER_NAMES } from '@/globalVariables'

export interface BodyContent {
  orderType: (typeof ORDER_TYPES)[keyof typeof ORDER_TYPES]
  key: keyof typeof ORDER_NAMES | 'membersMenu' | 'clubNameComponent' | 'nameComponent'
}

interface OrderBodyContent {
  order_asc: boolean
  order_type: (typeof ORDER_NAMES)[keyof typeof ORDER_NAMES]
  filter_country?: string
}

export const getOrderBodyContent = ({ orderType, key }: BodyContent): OrderBodyContent => {
  let orderBy: string

  switch (key) {
    case 'membersMenu':
      orderBy = ORDER_NAMES.membersCount
      break
    case 'type':
      orderBy = ORDER_NAMES.clubType
      break
    case 'clubNameComponent':
      orderBy = ORDER_NAMES.name
      break
    case 'nameComponent':
      orderBy = ORDER_NAMES.username
      break
    default:
      orderBy = ORDER_NAMES[key as keyof typeof ORDER_NAMES]
      break
  }

  return {
    order_asc: orderType === ORDER_TYPES.asc,
    order_type: orderBy,
  }
}
