<template>
  <div id="benefits-buildings" class="benefits-buildings app-page-wrapper absolute">
    <menu-component
      menu-type="benefits"
      :title="$t('common.benefits')"
      :menu-items="{
        1: {
          text: $t('benefits.inventory'),
          route: $getWebView('BenefitsInventory'),
        },
        2: {
          text: $t('benefits.setting'),
          route: $getWebView('BenefitsBuildings'),
        },
      }"
    />
    <section class="w-full h-full safe-area">
      <div class="benefits-buildings-info flex items-center justify-start">
        <info-text :text="$t('benefits.description')" />
      </div>
      <div class="benefits-buildings-list flex flex-col flex-grow space-x-8">
        <app-scrollbar width="100%" height="100%" slide="y" scroll="y" class="m-auto pr-4">
          <benefits-building
            v-for="(building, buildingName) in buildingsData"
            :key="buildingName"
            :name="$t(buildingsData[buildingName].translateName)"
            :img-src="buildingsData[buildingName].imgSrc"
          >
            <template
              v-for="(slot, slotId, slotIndex) in buildingsData[buildingName].slots"
              #[slotName(slotIndex)]
            >
              <use-benefit-button
                v-if="slot"
                :key="slotId"
                :slot-id="slotId.toString()"
                :hide-starts-info="true"
                :slot-data="slot as BenefitSlotData"
                @click="showSelectPopup(buildingName)"
                @reload-data="initData()"
              />
              <use-benefit-button v-else :key="slotIndex" @click="showSelectPopup(buildingName)" />
            </template>
          </benefits-building>
        </app-scrollbar>
      </div>
      <info-popup v-if="showPopup" popup-title="Activate benefit" @close="resetSelectPopup()">
        <select-benefit
          :allowed-benefit-types="buildingsData[clickedBuilding].allowedBenefitTypes"
          :current-slots="getSlots()"
          @close-select-popup="closeSelectAndRefreshSlots()"
        />
      </info-popup>
    </section>
  </div>
</template>

<script lang="ts">
import BenefitsBuilding from '@/components/Benefits/BenefitsBuilding.vue'
import SelectBenefit from '@/components/Benefits/SelectBenefit.vue'
import UseBenefitButton from '@/components/Button/UseBenefitButton.vue'
import InfoText from '@/components/InfoText.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import type {
  ActiveBenefitSlots,
  BenefitSlotData,
  UserBenefitsSlotsApiResponse,
} from '@/interfaces/Benefits'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import type { Nullable } from '@/interfaces/utils'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface BuildingDataAttributes {
  translateName: string
  imgSrc: string
  allowedBenefitTypes?: string[]
  slots?: {
    [key: string]: Nullable<{
      [key: string]: Nullable<BenefitSlotData>
    }>
  }
}

interface ComponentData {
  clickedBuilding: string
  showPopup: boolean
  buildingsData: {
    benefit_arena: BuildingDataAttributes
    benefit_training_hall: BuildingDataAttributes
    benefit_club_championship: BuildingDataAttributes
    benefit_star_of_the_day: BuildingDataAttributes
    benefit_tournaments: BuildingDataAttributes
  }
}

export default defineComponent({
  name: 'BuildingsComponent',
  components: {
    BenefitsBuilding,
    UseBenefitButton,
    InfoText,
    InfoPopup,
    SelectBenefit,
  },
  data(): ComponentData {
    return {
      clickedBuilding: null,
      showPopup: false,
      buildingsData: {
        benefit_arena: {
          translateName: 'map.arenaName',
          imgSrc: 'benefits/benefit_building_arena.avif',
          slots: null,
          allowedBenefitTypes: [],
        },
        benefit_training_hall: {
          translateName: 'map.trainingHallName',
          imgSrc: 'benefits/benefit_building_training.avif',
          slots: null,
          allowedBenefitTypes: [],
        },
        benefit_club_championship: {
          translateName: 'map.clubChampionshipsName',
          imgSrc: 'benefits/benefit_building_club.avif',
          slots: null,
          allowedBenefitTypes: [],
        },
        benefit_star_of_the_day: {
          translateName: 'map.starOfTheDayName',
          imgSrc: 'benefits/benefit_building_daily_league.avif',
          slots: null,
          allowedBenefitTypes: [],
        },
        benefit_tournaments: {
          translateName: 'map.tournamentsName',
          imgSrc: 'benefits/benefit_building_tournaments.avif',
          slots: null,
          allowedBenefitTypes: [],
        },
      },
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      benefitsSlots: 'getBenefitsSlots',
    }),
  },
  async created(): Promise<void> {
    this.initData()
  },
  methods: {
    ...mapActions(useResponseTaskStore, ['loadBenefitsSlots']),
    async initData(): Promise<void> {
      await this.loadBenefitsSlots()
      this.resetSelectPopup()
      this.getBuildingsData()
    },
    slotName(slotIndex: number): string {
      return 'slot-' + (slotIndex + 1)
    },
    showSelectPopup(buildingName: string): void {
      this.clickedBuilding = buildingName
      this.showPopup = true
    },
    resetSelectPopup(): void {
      this.clickedBuilding = null
      this.showPopup = false
    },
    getBuildingsData(): void {
      this.benefitsSlots.forEach((benefitBuildingSlot: UserBenefitsSlotsApiResponse): void => {
        const benefitBuilding = benefitBuildingSlot.benefit_building

        if (this.buildingsData[benefitBuilding]) {
          if (this.buildingsData[benefitBuilding].slots === null) {
            this.buildingsData[benefitBuilding].slots = {}
          }

          this.buildingsData[benefitBuilding].slots[benefitBuildingSlot?.slot_id] =
            benefitBuildingSlot.benefit

          this.buildingsData[benefitBuilding].allowedBenefitTypes.push(
            benefitBuildingSlot.benefit_slot_type,
          )
        }
      })
    },
    getSlots(): ActiveBenefitSlots {
      const slotsRaw: BuildingDataAttributes['slots'] =
        this.buildingsData[this.clickedBuilding]?.slots

      const slots = {}
      for (const slotId in slotsRaw) {
        slots[slotId] = slotsRaw[slotId] ? slotsRaw[slotId].benefit_gdd_id : 0
      }

      return slots
    },
    closeSelectAndRefreshSlots(): void {
      this.resetSelectPopup()
      this.getBuildingsData()
    },
  },
})
</script>

<style lang="scss" scoped>
.benefits-buildings {
  &-info {
    width: 90%;
    margin: 1.438rem auto;
    text-align: left;
  }

  &-list {
    width: 96%;
    margin: 0 auto;
    height: 48rem;
  }
}
</style>
