import type { App, Plugin } from 'vue'
import { replacePlaceholder } from '@/plugins'
import { translate } from '@/plugins/translate'

export const getText = (text: string, seconds: number): string => {
  return replacePlaceholder(translate(text), '%s', Math.floor(seconds))
}

export const beforeTimeText = (seconds: number): string => {
  enum TimeInSeconds {
    Day = 86400,
    Hour = 3600,
    Minute = 60,
    Second = 1,
  }

  if (seconds <= TimeInSeconds.Second) {
    return getText('common.beforeSingleSecond', TimeInSeconds.Second)
  } else if (seconds < TimeInSeconds.Minute) {
    return getText('common.beforeMultipleSeconds', seconds)
  } else if (seconds < TimeInSeconds.Minute * 2) {
    return getText('common.beforeSingleMinute', TimeInSeconds.Second)
  } else if (seconds < TimeInSeconds.Hour) {
    return getText('common.beforeMultipleMinutes', seconds / TimeInSeconds.Minute)
  } else if (seconds < TimeInSeconds.Hour * 2) {
    return getText('common.beforeSingleHour', TimeInSeconds.Second)
  } else if (seconds < TimeInSeconds.Day) {
    return getText('common.beforeMultipleHours', seconds / TimeInSeconds.Hour)
  }
  return getText('common.beforeDays', seconds / TimeInSeconds.Day)
}

export const beforeTimeTextPlugin: Plugin = {
  install: (app: App): void => {
    app.config.globalProperties.$beforeTimeText = beforeTimeText
  },
}
