<template>
  <div class="staroftheday-locked w-full h-full flex justify-center">
    <app-timer
      v-tippy="{
        content: timerTooltip,
        placement: 'right',
        theme: 'WSM',
      }"
      :time="time"
      icon="refresh"
      class="staroftheday-locked-timer"
    />
    <p
      v-if="config"
      class="staroftheday-locked-text text-5xl font-bold text-center text-white leading-normal"
      v-html="$replacePlaceholder($t('starOfTheDay.dayLeagueStartsAt'), '%s', timeText)"
    />
  </div>
</template>

<script lang="ts">
import { useStarOfTheDayStore } from '@/store/pinia/starOfThedayStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'StarofthedayLocked',
  props: {
    time: {
      type: Number,
      required: true,
    },
    timerTooltip: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(useStarOfTheDayStore, {
      config: 'getConfig',
    }),
    timeText(): string {
      return `<span class="text-texts-standard-important"> ${this.config.in_progress.hours}:${this.config.in_progress.minutes} </span>`
    },
  },
})
</script>

<style lang="scss" scoped>
.staroftheday-locked {
  position: relative;
  background-image: url($path-images + 'staroftheday/BG_FULLSCREEN_OVERLAY.avif'),
    url($path-images + 'staroftheday/BG_CUPS.avif'),
    url($path-images + 'grandPrize/main/bg-main.avif');
  background-position: bottom, bottom, center;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: cover, 70%, cover;

  &-timer {
    position: absolute;
    top: 0.625rem;
    left: 0;
    width: 17.5625rem;
    height: 3.625rem;
    margin-top: 0.9375rem;
    margin-left: 4rem;
  }

  &-text {
    margin-top: 14.56rem;
    width: 71.6875rem;
    height: 4.875rem;
    @if $isWsm {
      background: linear-gradient(
        to right,
        rgba(251, 227, 110, 0),
        rgba(20, 74, 115, 1),
        rgba(20, 74, 115, 1),
        rgba(251, 227, 110, 0)
      );
    }
    @if $isSsm {
      background: linear-gradient(
        to right,
        transparent,
        #56627f,
        rgba(86, 98, 127, 0.96),
        transparent
      );
    }
  }
}
</style>
