<template>
  <div id="settings" class="settings-wrapper app-page-wrapper absolute">
    <section class="flex flex-col w-full h-full safe-area">
      <div
        class="cross-account-linking__header flexing font-medium text-36 uppercase text-texts-standard-important"
      >
        {{ $t('settings.connect_account_heading') }}
      </div>
      <div class="cross-account-linking relative">
        <app-scrollbar
          :height="'100%'"
          width="100%"
          slide="y"
          scroll="y"
          class="settings-scroll"
          :options="{ suppressScrollY: false, suppressScrollX: true }"
        >
          <div>
            <div class="cross-account-linking__user flexing flex-row gap-8 uppercase">
              <player-level :width-prop="4.125" :height-prop="4.125" class="ml-2" />
              <app-user
                :id="user.id"
                :country="user.country.three_letter_code"
                :name="user.username"
                :no-country-text="true"
              />
            </div>
            <div
              class="connected-accounts__container"
              :class="{ 'is-gems-bg': !hasVerifiedAccount }"
            >
              <div
                class="connected-accounts__header flexing font-bold text-28 uppercase"
                :class="{ 'is-gems-message-bg text-white': !hasVerifiedAccount }"
              >
                <template v-if="!hasVerifiedAccount">
                  {{ gemsMessage[0] }}
                  <app-main-icon :icon-name="GEMS" :icon-size="48" />
                  {{ gemsMessage[1] }}
                </template>
                <template v-else>
                  {{ $t('settings.accountLinkedTo') }}
                </template>
              </div>
              <connected-accounts class="py-4" :hide-link-button="true" />
              <div class="shadow-line-container">
                <div class="shadow-line shadow-l"></div>
                <div class="shadow-line shadow-r"></div>
              </div>
            </div>
          </div>
          <login-form-with-flow :context="LoginFormContext.CONNECTION" class="mx-auto" />
        </app-scrollbar>
      </div>
      <div v-html="renderPrivacyLinks" />
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { useUserStore } from '@/store/pinia/userStore'
import { GEMS, pathImages } from '@/globalVariables'
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import ConnectedAccounts from '@/components/Settings/ConnectedAccounts.vue'
import PlayerLevel from '@/components/Profile/PlayerLevel.vue'
import LoginFormWithFlow from '@/components/Login/LoginFormWithFlow.vue'
import { useAccountStore } from '@/store/pinia/accountStore'
import { LoginFormContext } from '@/enums/LoginForm'

interface ComponentData {
  GEMS: typeof GEMS
  pathImages: string
  LoginFormContext: typeof LoginFormContext
}

export default defineComponent({
  name: 'CrossAccountLinking',
  components: {
    PlayerLevel,
    AppUser,
    LoginFormWithFlow,
    ConnectedAccounts,
  },
  data(): ComponentData {
    return {
      GEMS,
      pathImages,
      LoginFormContext,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      user: 'getUserData',
      userLanguage: 'getLanguage',
    }),
    ...mapState(useAccountStore, ['hasVerifiedAccount']),
    languageCode(): string {
      if (this.userLanguage)
        return this.userLanguage === 'sk' || this.userLanguage === 'cs' ? 'sk' : 'en'

      return localStorage.getItem('language') === 'sk' || localStorage.getItem('language') === 'cs'
        ? 'sk'
        : 'en'
    },
    gemsMessage(): [string, string] {
      const text = this.$replacePlaceholder(
        this.$t('settings.linkAccountPrompt'),
        '{number}',
        '150',
      )

      const separator = '{icon}'
      if (!text.includes(separator)) return [text, '']

      const index = text.indexOf(separator)
      return [text.slice(0, index), text.slice(index + separator.length)] as [string, string]
    },
    renderPrivacyLinks(): string {
      return `
        <div class="flexing py-4 text-texts-standard-default gap-2 text-32">
          <a
            class="clickable-element underline"
            href="https://www.powerplay.studio/${this.languageCode}/license/"
            target="_blank"
          >
            ${this.$t('homepage.license')}
          </a>
          <span>/</span>
          <a
            class="clickable-element underline"
            href="https://www.powerplay.studio/${this.languageCode}/privacy-policy/"
            target="_blank"
          >
            ${this.$t('homepage.privacyPolicy')}
          </a>
        </div>
      `
    },
  },
  created(): void {
    const googleConnection = this.$route?.query?.google_connection
    if (googleConnection) {
      localStorage.setItem('googleConnection', googleConnection.toString())
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/settings/settings.scss';

.cross-account-linking {
  overflow: hidden;
  flex: 1 1 auto;

  &__header {
    position: relative;
    width: 50%;
    height: 5rem;
    margin: 2rem auto 0;
    background: linear-gradient(
      to right,
      rgba(209, 192, 118, 0) 0%,
      rgb(50 62 100) 30%,
      rgb(50 62 100) 70%,
      rgba(164, 126, 40, 0) 100%
    );

    &::after {
      content: '';
      position: absolute;
      height: 0.125rem;
      width: 100%;
      left: 50%;
      bottom: 0;
      transform: translate(-50%);
      background: linear-gradient(to right, transparent, rgb(244, 233, 167), transparent);
    }
  }

  &__user {
    margin: 2.5rem auto;
  }

  .connected-accounts {
    width: 100%;
    height: 8.313rem;

    &__container {
      width: 80%;
      margin: 0 auto;
      position: relative;
      background: linear-gradient(
        to right,
        rgba(209, 192, 118, 0) 0%,
        rgba(255, 255, 255, 0.02) 50%,
        rgba(164, 126, 40, 0) 100%
      );

      &.is-gems-bg {
        &::after {
          @include background(url('#{$path-images}account/gems-bg.avif'), cover);
          content: '';
          position: absolute;
          height: 20.75rem;
          width: 92.75rem;
          left: 50%;
          bottom: 0;
          transform: translate(-50%);
        }
      }
    }

    &__header {
      height: 3.875rem;
      margin: 0 auto;
      background-image: linear-gradient(
        to right,
        rgba(209, 192, 118, 0) 0%,
        rgb(199, 166, 85) 50%,
        rgba(164, 126, 40, 0) 100%
      );
      padding-top: 0.0625rem;

      &.is-gems-message-bg {
        background-image: linear-gradient(
          to right,
          transparent 0%,
          rgba(205, 74, 139, 0.7) 20%,
          rgba(205, 74, 139, 1) 45%,
          rgba(205, 74, 139, 1) 55%,
          rgba(205, 74, 139, 0.7) 80%,
          transparent 100%
        );
        border-style: solid;
        border-width: 0.188rem;
        border-image-source: linear-gradient(
          to right,
          transparent 0%,
          rgba(238, 115, 182, 0.7) 15%,
          rgba(238, 115, 182, 1) 50%,
          rgba(238, 115, 182, 0.7) 85%,
          transparent 100%
        );
        border-image-slice: 1;
      }
    }
  }

  .shadow-line-container {
    position: absolute;
    width: 80%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    left: 50%;
    transform: translate(-50%);

    &::before {
      content: '';
      position: absolute;
      height: 0.125rem;
      width: 100%;
      top: -0.125rem;
      left: 50%;
      transform: translate(-50%);
      background: linear-gradient(to right, transparent 10%, rgb(123, 122, 179), transparent 90%);
      z-index: 1;
    }

    .shadow-line {
      position: relative;
      height: 10px;
      flex: 1 1 auto;
      min-width: 10%;
      overflow: hidden;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: -20px;
        right: 0;
        height: 10px;
        border-radius: 100%;
      }
    }

    .shadow-l {
      &::before {
        right: -100%;
        box-shadow: 10px 10px 10px 1px;
      }
    }

    .shadow-r {
      &::before {
        left: -100%;
        box-shadow: -10px 10px 10px 1px;
      }
    }
  }

  .settings-scroll {
    display: flex;
    flex-direction: column;

    .login-form-with-flow {
      flex: 1 1 auto;
    }
  }
}
</style>
