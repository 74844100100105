<template>
  <section v-show="prices.length > 0" class="wheeler">
    <section class="spinner">
      <person-component class="woman-component" :class="$isMobile() && 'person-component-mobile'" />
      <person-component
        class="man-component"
        :class="$isMobile() && 'person-component-mobile'"
        :is-man="true"
      />
      <spinning-wheel
        v-if="prices.length > 0 && renderWheel"
        :spin="isSpin"
        :prices="modifiedPrices"
        :winner-id="winnerId"
        :initial-reward="initialReward"
        @spin-completed="spinFinished"
      />
      <multiplier-controller
        v-if="multipliers.length > 0 && !isSpinned"
        :multiplier-info="multipliers"
        :is-disabled="isMultiplierDisabled"
        :active-multiplier="activeMultiplier"
        @multiplier-change="updateMultiplier"
      />
    </section>

    <div class="luckywheel-information-wrapper">
      <spin-wheel-action-information
        :should-show="isSpinned"
        :prices="modifiedPrices"
        :winner-id="winnerId"
        :initial-reward="initialReward"
        class="information-position"
      />
      <spin-action-buttons
        v-if="!isSpinned && activeMultiplier"
        :multiplier-number="activeMultiplier"
        :is-spinning="isSpin"
        :disabled-buttons="disabledButtons || disabledButtonsParent || !isEnoughSpins"
        @spin="spinWheel"
      />
      <claim-button v-else @claim-reward="claimReward" />
    </div>
  </section>
</template>

<script lang="ts">
import { claimLuckyWheelReward, getLuckyWheelRewards, spinLuckyWheel } from '@/globalVariables'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import ClaimButton from './ControllerComponents/ClaimButton.vue'
import MultiplierController from './ControllerComponents/MultiplierController.vue'
import PersonComponent from './ControllerComponents/PersonComponent.vue'
import SpinningWheel from './ControllerComponents/SpinningWheel.vue'
import SpinActionButtons from './ControllerComponents/SpinActionButtons.vue'
import SpinWheelActionInformation from './ControllerComponents/SpinWheelActionInformation.vue'
import type LuckyWheelStateApiResponse from '@/interfaces/responses/events/lucky-wheel/LuckyWheelStateApiResponse'
import type LuckyWheelRewardsApiResponse from '@/interfaces/responses/events/lucky-wheel/LuckyWheelRewardsApiResponse'
import type LuckyWheelSpinApiResponse from '@/interfaces/responses/events/lucky-wheel/LuckyWheelSpinApiResponse'
import type LuckyWheelClaimApiResponse from '@/interfaces/responses/events/lucky-wheel/LuckyWheelClaimApiResponse'
import type { Nullable } from '@/interfaces/utils'

type LuckyWheelReward = LuckyWheelRewardsApiResponse['rewards'][0]

type LuckyWheelLastReward = LuckyWheelStateApiResponse['last_reward'][0]

export interface LuckyWheelPrice {
  id: LuckyWheelReward['id']
  parameter: LuckyWheelReward['reward_parameters']['type']
  amount: LuckyWheelReward['reward_parameters']['value']
}
export type LuckyWheelMultipliers = LuckyWheelRewardsApiResponse['spin_credit_costs']

export type LuckyWheelMultiplier = LuckyWheelRewardsApiResponse['spin_credit_costs'][0]

interface ComponentData {
  isSpin: boolean
  prices: LuckyWheelPrice[]
  winnerId: Nullable<LuckyWheelPrice['id']>
  pointsPerSpin: LuckyWheelRewardsApiResponse['points_per_spin']
  multipliers: LuckyWheelMultipliers
  activeMultiplier: Nullable<LuckyWheelMultiplier>
  isSpinned: boolean
  disabledButtons: boolean
  initialReward: Nullable<LuckyWheelPrice>
  renderWheel: boolean
  isMultiplierDisabled: boolean
}

export default defineComponent({
  name: 'LuckyWheelController',
  components: {
    SpinningWheel,
    SpinWheelActionInformation,
    SpinActionButtons,
    PersonComponent,
    MultiplierController,
    ClaimButton,
  },
  props: {
    initState: {
      type: Boolean,
      required: true,
    },
    previousReward: {
      type: Object as PropType<Nullable<LuckyWheelLastReward>>,
      default: () => null,
    },
    disabledButtonsParent: {
      type: Boolean,
      default: false,
    },
    freeSpins: {
      type: Number,
      default: 0,
    },
  },
  emits: ['wheelSpinned', 'claimedReward'],
  data(): ComponentData {
    return {
      isSpin: false,
      prices: [],
      winnerId: null,
      pointsPerSpin: 0,
      multipliers: [],
      activeMultiplier: null,
      isSpinned: false,
      disabledButtons: false,
      initialReward: null,
      renderWheel: false,
      isMultiplierDisabled: false,
    }
  },
  computed: {
    modifiedPrices(): LuckyWheelPrice[] {
      // Musite uznat tento old school trik vzdy zaberie :D
      const priceCopy = JSON.parse(JSON.stringify(this.prices))
      return priceCopy.map((price: LuckyWheelPrice): LuckyWheelPrice => {
        price.amount = price.amount * this.activeMultiplier.multiplier
        return price
      })
    },
    isEnoughSpins(): boolean {
      return this.freeSpins >= this.activeMultiplier.multiplier
    },
  },
  async created(): Promise<void> {
    await this.getRewardData()
    if (this.previousReward) {
      this.setPreviousRewardToClaim()
    } else {
      this.renderWheel = true
    }
    this.isSpinned = this.initState
  },
  methods: {
    async getRewardData(): Promise<void> {
      try {
        const rewardData = await this.$axios.get<{}, LuckyWheelRewardsApiResponse>(
          getLuckyWheelRewards,
        )
        if (rewardData.mechanic !== 'lucky_wheel') console.error('Wrong mechanic downloaded')
        this.prices = rewardData.rewards.map(
          (reward: LuckyWheelReward): LuckyWheelPrice => ({
            id: reward.id,
            parameter: reward.reward_parameters.type,
            amount: reward.reward_parameters.value,
          }),
        )

        this.pointsPerSpin = rewardData.points_per_spin
        this.multipliers = rewardData.spin_credit_costs

        if (this.previousReward) {
          const multiplierObject = this.multipliers.filter(
            (multiplierObject: LuckyWheelMultiplier): boolean =>
              multiplierObject.multiplier === this.previousReward.reward_parameters.multiplier,
          )
          this.activeMultiplier = multiplierObject[0]
        } else {
          this.activeMultiplier = this.multipliers[0]
        }
      } catch (error: unknown) {
        console.error(error)
      }
    },
    updateMultiplier(multiplierState: LuckyWheelMultiplier): void {
      this.activeMultiplier = multiplierState
    },
    spinFinished(): void {
      this.disabledButtons = false
      this.isSpinned = true
    },
    async spinWheel(isGem: boolean): Promise<void> {
      this.isMultiplierDisabled = true
      try {
        const response = await this.$axios.post<{}, LuckyWheelSpinApiResponse>(spinLuckyWheel, {
          multiplier: this.activeMultiplier.multiplier,
          spin_for_gems: isGem,
        })

        if (!response.spin_result) {
          this.isMultiplierDisabled = false

          console.error('The spin result is false. You have to claim first')
          return
        }
        if (response.reward.length === 0) {
          this.isMultiplierDisabled = false

          console.error('No reward returned')
          return
        }
        this.initialReward = null
        this.winnerId = response.reward[0].id
        this.isSpin = true
        this.disabledButtons = true
        this.$emit('wheelSpinned')
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async claimReward(): Promise<void> {
      try {
        await this.$axios.post<{}, LuckyWheelClaimApiResponse>(claimLuckyWheelReward)
        this.isSpin = false
        this.isSpinned = false
        this.isMultiplierDisabled = false
        this.$emit('claimedReward')
      } catch (error: unknown) {
        console.error(error)
      }
    },
    setPreviousRewardToClaim(): void {
      this.initialReward = {
        id: this.previousReward.id,
        parameter: this.previousReward.reward_parameters.type,
        amount: this.previousReward.reward_parameters.value * this.activeMultiplier.multiplier,
      }
      this.renderWheel = true
    },
  },
})
</script>

<style lang="scss" scoped>
section.wheeler {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .spinner {
    position: relative;
  }

  .man-component {
    position: absolute;
    right: -59%;
    top: -1.5rem;
  }

  .woman-component {
    position: absolute;
    left: -68%;
    top: -1.5rem;
  }

  .person-component-mobile {
    top: 0 !important;
  }

  .information-position {
    z-index: 2;
    height: 9.375rem;
  }

  .luckywheel-information-wrapper {
    height: 11rem;
    margin-top: -1rem;
  }
}
</style>
