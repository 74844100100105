<template>
  <footer class="flex items-center w-full relative">
    <div
      class="image-special-currency"
      :style="{
        background:
          'url(' +
          pathImages +
          'components/header/' +
          paramType.specialConsumable.img +
          ') center no-repeat',
        backgroundSize: 'contain',
      }"
    />
    <p class="text-texts-standard-default text-34">
      {{ $t(paramType.specialConsumable.amountText) }}:
    </p>
    <div class="special-currency text-36 text-texts-standard-default flexing">
      <p :class="{ 'text-texts-standard-danger': totalSpecialConsumables === 0 }">
        {{ totalSpecialConsumables ?? '-' }}
      </p>
      <app-main-icon :icon-name="paramType.specialConsumable.value" :icon-size="56" />
    </div>
    <app-button
      class="absolute button-buy"
      btn-type="secondary"
      :btn-text="$t(paramType.specialConsumable.buyText)"
      btn-size="md"
      @click="openPremium()"
    />
  </footer>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { HeaderParam } from '@/interfaces/HeaderParams'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'ParameterPopupFooter',
  props: {
    paramType: {
      type: Object as PropType<Nullable<HeaderParam>>,
      default: () => null,
    },
    totalSpecialConsumables: {
      type: Number,
      default: null,
    },
  },
  emits: ['closePopup'],
  data: (): ComponentData => {
    return {
      pathImages,
    }
  },
  methods: {
    openPremium(): void {
      switch (this.paramType.value) {
        case 'energy':
          this.$router.push({ name: this.$getWebView('PremiumEnergyDrink') })
          break
        case 'starts':
          this.$router.push({ name: this.$getWebView('PremiumWildCard') })
          break
      }
      this.$emit('closePopup')
    },
  },
})
</script>

<style lang="scss" scoped>
footer {
  height: 6.25rem;
  position: absolute;
  bottom: 0;

  @if $isWsm {
    background-image: linear-gradient(to right, #18334e, #215d84, #061123);
  }

  @if $isSsm {
    background-color: #1f2438;
  }

  .image-special-currency {
    width: 14.5625rem;
    height: 12rem;
    background: url($path-components + 'header/card-fire.avif') center no-repeat;
    background-size: contain;
    margin-bottom: 5.6rem;
    margin-left: 0.6rem;
    margin-right: 0.5rem;
  }

  .special-currency {
    margin-left: 1rem;
    width: 9.1875rem;
    height: 3.75rem;
    position: relative;

    @if $isWsm {
      background-image: linear-gradient(to right, transparent, #71c2ff54, transparent);
    }

    @if $isSsm {
      background-image: linear-gradient(
        to right,
        transparent,
        rgba(198, 207, 255, 0.3),
        transparent
      );
    }

    .icon {
      margin: 0;
    }

    &::before,
    &::after {
      position: absolute;
      content: '';
      height: 0.1rem;
      width: 100%;
      left: 50%;
      transform: translate(-50%);
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.01),
        #fff,
        rgba(255, 255, 255, 0.01)
      );
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  .button-buy {
    right: 1rem;
    width: 31.125rem;
  }
}

.opacity {
  opacity: 0.6;
}
</style>
