<template>
  <div class="arena-building-main-right">
    <div v-if="!isContentLoading" class="relative arena-building-main-right-content">
      <div class="arena-building-main-right-info">
        <header-info>
          <p class="header-info-text text-left text-28 text-texts-standard-default">
            {{ $t('arena.buildingInfo') }}
          </p>
          <div class="header-info-box flex justify-center items-center">
            <div class="box-text font-bold italic">
              {{ $filters.$formatNumber(Number(stats?.RANKING_POINTS)) ?? '-' }}
            </div>
            <app-main-icon :icon-size="56" icon-name="ranking_points" class="icon-trophy" />
          </div>
        </header-info>
      </div>

      <div class="arena-building-main-right-table w-full table-head-standard">
        <div class="text-one">
          {{ $t('common.level') }}
        </div>
        <div class="text-two flex justify-center" />
        <div class="text-three flex justify-center">
          {{ $t('common.require') }}
        </div>
        <div class="text-four flexing">
          <p>{{ $t('common.bonus') }}</p>
          <app-icon
            v-tippy="{
              theme: 'WSM',
              content: $t('equipmentShop.moneyBonus'),
              placement: 'top',
              maxWidth: '30rem',
              textAlign: 'center',
            }"
            icon-name="info-50"
            class="arena-building-main-left-footer-info"
          />
        </div>
      </div>
      <app-scrollbar
        id="arena-building-grades-scroll"
        slide="y"
        scroll="y"
        width="101%"
        height="100%"
      >
        <div
          v-for="(level, index) in arenaGrades"
          :id="'arena-building-level-' + level?.level"
          :key="index"
          class="arena-building-main-right-boxes w-full relative flex items-center"
          :class="{
            'user-box': currentBuilding?.level == level?.level,
          }"
        >
          <template v-if="!!level.level">
            <div
              class="arena-building-main-right-boxes-position flex items-center justify-center text-texts-standard-important italic"
              :class="{
                'font-bold': $isSsm,
              }"
            >
              {{ level.level }}
            </div>
            <div
              v-show="currentBuilding?.level == level.level"
              class="arena-building-main-right-boxes-arrow"
            />
            <div
              class="arena-building-main-right-boxes-arena"
              :style="{
                backgroundImage:
                  'url(' +
                  pathImages +
                  'arena/building/BUILDING_ARENA_SMALL_LVL' +
                  getBuildingImageByLevel(level.level) +
                  '.avif)',
              }"
            />
            <div
              class="arena-building-main-right-boxes-current text-texts-standard-dark font-bold italic uppercase text-left"
            >
              <div v-show="currentBuilding?.level == level.level" class="txt">
                {{ $t('arena.currentLevel') }}
              </div>
            </div>
            <div
              v-if="level.level > 1"
              class="arena-building-main-right-boxes-box flex absolute justify-center items-center text-texts-standard-default"
              :class="{
                'user-small-box': currentBuilding?.level == level.level,
              }"
            >
              <div class="box-text font-bold italic">
                {{ $filters.$formatNumber(level.parameters.min_user_stats) }}
              </div>
              <app-main-icon :icon-size="56" icon-name="ranking_points" class="icon-trophy" />
            </div>
            <div class="arena-building-main-right-boxes-corner flex absolute justify-between">
              <div
                class="corner-slide h-full"
                :class="{
                  'corner-color': currentBuilding?.level == level.level,
                }"
              />
              <div
                class="corner-section h-full flex items-center italic font-bold"
                :class="[
                  { 'corner-color': currentBuilding?.level == level.level },
                  level.level > 1 ? 'percentage-count' : 'justify-center',
                ]"
              >
                <div
                  class="text-percent uppercase leading-8"
                  :class="{
                    'text-texts-standard-important text-40':
                      currentBuilding?.level != level.level && level.level !== 1,
                    'text-texts-standard-default': level.level !== currentBuilding?.level,
                    'text-texts-standard-dark': level.level === currentBuilding?.level,
                    'text-28': level.level === 1,
                  }"
                >
                  {{
                    level.parameters.bonus == 0
                      ? $t('common.noBonus')
                      : '+' + level.parameters.bonus + '%'
                  }}
                </div>
                <app-main-icon v-show="index !== 0" :icon-size="56" icon-name="money" />
              </div>
            </div>
          </template>
        </div>
      </app-scrollbar>
      <div class="table-bottom-shadow table-bottom-shadow-width" />
    </div>

    <component-loading :is-loading="isContentLoading" type="secondary" />
  </div>
</template>

<script lang="ts">
import HeaderInfo from '@/components/Header/HeaderInfo.vue'
import { arenaBuildingEndpoint, pathImages } from '@/globalVariables'
import { convertNameValue, getBuildingImageByLevel } from '@/helpers'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { CurrentArenaBuildingResponse, ArenaBuilding } from '../ArenaSharedInterface'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  pathImages: typeof pathImages
  arenaGrades: ArenaBuilding[]
}

export default defineComponent({
  name: 'ArenaBuildingGrades',
  components: {
    HeaderInfo,
  },
  props: {
    currentBuilding: {
      type: Object as PropType<Nullable<CurrentArenaBuildingResponse>>,
      default: () => null,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
      arenaGrades: [],
    }
  },
  computed: {
    ...mapState(useUserStore, {
      stats: 'getStats',
    }),
    isContentLoading(): boolean {
      return this.arenaGrades.length === 0 || this.stats == null
    },
    arenaBuildingImage(): string {
      return ''
    },
  },

  watch: {
    currentBuilding(): void {
      this.scrollToActual()
    },
  },
  created(): void {
    this.getGrades()
  },

  methods: {
    getBuildingImageByLevel,
    async getGrades(): Promise<void> {
      try {
        const response = await this.$axios.get<{}, ArenaBuilding[]>(arenaBuildingEndpoint)

        this.arenaGrades = response.reduce(
          (finalArray: ArenaBuilding[], grade: ArenaBuilding): ArenaBuilding[] => {
            if (grade.level > 0) {
              // TODO helper: chalani ked vam napadne ako fixnut toto
              grade.parameters = convertNameValue(grade.parameters)
              finalArray.push(grade)
            }

            return finalArray
          },
          [],
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
    scrollToActual(): void {
      const container: HTMLElement = this.$el.querySelector('#arena-building-grades-scroll')
      if (container) {
        this.$el
          .querySelector('#arena-building-level-' + this.currentBuilding.level ?? 1)
          .scrollIntoView({ behavior: 'smooth' })
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/arena/buildings.scss';
</style>
