<template>
  <div
    class="tooltip-report-row flex items-center justify-between"
    :class="{ highlight: highlighted, muted, 'with-line': withLine }"
  >
    <div class="text-left tooltip-report-row-first">
      {{ $t(text) }}
    </div>
    <div v-for="(value, index) in columns" :key="index" class="tooltip-report-row-second">
      {{ value }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'ArenaReportResultTooltipLine',
  props: {
    text: {
      type: String,
      default: '',
    },
    columns: {
      type: Array as PropType<(string | number)[]>,
      default: () => [],
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    withLine: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
