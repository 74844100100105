<template>
  <div
    v-if="getGameName"
    :id="'offer-' + offerData.type"
    :data-cy="'offer-' + offerData.type"
    class="offer-box relative cursor-pointer"
    :style="{
      background: `url(${pathImages}premium/offers/offer-${getGameName}_game_pass.avif) center no-repeat`,
      backgroundSize: 'contain',
    }"
    @click="showOffer"
  >
    <main
      :class="isPassEnding ? 'text-36' : 'text-32'"
      class="offer-box-main relative text-texts-standard-dark font-bold flex justify-center"
    >
      <div class="offer-box-main-title flexing">
        <p class="offer-box-main-text text-center" v-html="getInfoText"></p>
      </div>
    </main>
    <footer class="offer-box-footer flex items-center absolute w-full flex-col justify-around">
      <app-timer
        v-if="offerData.remaining_time"
        icon="refresh-md"
        :time="offerData.remaining_time"
        @countdown-action="$emit('reloadData')"
      />
      <app-button
        btn-id="game-pass-offer-button"
        btn-type="secondary"
        :btn-text="isPassEnding ? $t('button.detail') : $t('gamePass.callToAction')"
        btn-size="md"
      />
    </footer>
  </div>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useGamePassStore } from '@/store/pinia/gamePassStore'

import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { Offer } from '@/interfaces/premium/Offer'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'OffersGamePass',
  props: {
    offerData: {
      type: Object as PropType<Nullable<Offer>>,
      default: () => null,
    },
  },
  emits: ['reloadData'],
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    ...mapState(useGamePassStore, ['isPassEnding']),
    getInfoText(): string {
      const replacements = {
        '{pass}': this.$t('gamePass.gamePass'),
        '{passelite}': this.$t('gamePass.gamePassElite'),
        '{passpro}': this.$t('gamePass.gamePassPro'),
      }
      let str = this.isPassEnding ? this.$t('gamePass.tagline') : this.$t('gamePass.popUpInfo')

      str = str.replace(/{\w+}/g, function (all: string): string {
        return replacements[all] || all
      })

      return str
    },
    getGameName(): string {
      if (this.$isWsm) {
        return 'wsm'
      }
      if (this.$isSsm) {
        return 'ssm'
      }
      return ''
    },
  },
  methods: {
    ...mapActions(usePremiumStore, {
      hideOfferDetail: 'hideOfferDetail',
    }),
    showOffer(): void {
      this.hideOfferDetail()
      this.$router.push({ name: this.$getWebView('PremiumGamePassPacks') })
    },
  },
})
</script>

<style lang="scss" scoped>
.offer-box {
  height: 44.875rem;
  width: 78.75rem;
  margin-right: 1.5rem;
  position: relative;
  &-main {
    height: 31.5rem;

    &-title {
      text-align: center;
      position: absolute;
      top: 7.5rem;
      height: 2.875rem;
    }
  }

  &-footer {
    bottom: 0.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 11.6rem;
  }
}
</style>
