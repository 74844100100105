<template>
  <section
    v-if="isPaymentView ? paymentPassInfo : eventPass?.length"
    class="event-pass-detail-body flex flex-col flex-grow w-full"
    :data-event-theme="namespace"
  >
    <div
      class="event-pass-detail-body-cards flexing flex-grow w-full"
      :class="[pass.offer_content.length === 4 ? 'space-x-6' : 'space-x-16']"
    >
      <div v-if="namespace === EventType.AutumnFair" class="flexing gap-6">
        <div
          v-for="(reward, index) in processedOffers"
          :key="index"
          v-tippy="{
            theme: 'WSM',
            content: isPaymentView ? getDescription(reward, index + 1) : '',
            allowHTML: true,
            placement: 'top',
            textAlign: 'center',
          }"
          class="event-pass-detail-body-card card flex flex-col"
          :class="[
            `tier-${isPaymentView ? tier : $route.query?.tier}`,
            {
              'is-payment-view': isPaymentView,
              'relative top-4': !isPaymentView,
            },
          ]"
        >
          <div
            class="card-image w-full -mt-4 mx-10"
            :class="`reward__tier-${currentPassNumber}--reward-${index + 1}`"
          />
          <div class="card-header flexing w-full my-5 mx-12">
            <p class="flexing pt-2.5 text-50 gradient-text-gold text-center font-bold italic">
              {{ reward.isMultiplier ? $t('common.unlocked') : `${reward.value}x` }}
            </p>
          </div>
          <div v-if="!isPaymentView" class="card-footer flexing w-full mt-2 mx-12">
            <span
              class="flexing flex-col gap-4 text-30 leading-8 text-texts-standard-default text-center font-bold italic"
              v-html="getDescription(reward)"
            />
          </div>
        </div>
      </div>
      <div
        v-for="(reward, i) in pass.offer_content"
        v-else
        :key="i"
        v-tippy="{
          theme: 'WSM',
          content: isPaymentView ? getDescription(reward, i + 1) : '',
          placement: 'top',
          textAlign: 'center',
        }"
        class="event-pass-detail-body-card card"
        :class="[
          `tier-${isPaymentView ? tier : $route.query?.tier}`,
          {
            'is-payment-view': isPaymentView,
            'relative top-4': !isPaymentView,
          },
        ]"
      >
        <div class="card-image w-full -mt-4 mb-5 mx-10" :class="`reward-${i + 1}`" />
        <div class="card-header flexing w-full mt-5 mb-12 mx-12">
          <p class="flexing pt-2.5 text-50 gradient-text-gold text-center font-bold italic">
            {{ reward.value }}{{ getResourceUnit(reward.name, true) }}
          </p>
        </div>
        <div v-if="!isPaymentView" class="card-footer flexing w-full mt-12 mx-12">
          <p class="flexing text-30 text-texts-standard-default text-center font-bold italic">
            {{ getDescription(reward, i + 1) }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="!isPaymentView" class="event-pass-detail-footer flexing w-full my-12 relative">
      <template v-if="!pass.remaining_buys">
        <p
          class="flexing text-50 text-texts-standard-important text-center font-bold italic uppercase mx-auto"
          :class="{
            purchased: !pass.remaining_buys && currentPassIndex !== activePassIndex,
            activated: !pass.remaining_buys && currentPassIndex === activePassIndex,
          }"
        >
          <template v-if="currentPassIndex === activePassIndex">
            {{ $te('activated') }}
            <app-icon icon-name="done-md" class="inline-block ml-4" />
          </template>
          <template v-else>{{ $te('purchased') }}</template>
        </p>
      </template>
      <div v-else>
        <p
          v-if="hasOfferOldPrice(pass)"
          class="flexing text-40 text-texts-standard-additional font-normal space-x-2 absolute -top-18 left-1/2 transform -translate-x-1/2"
        >
          <span class="price-crossed inline-flex relative">{{ getOfferOldPrice(pass) }}</span>
          <span class="inline-flex">{{ pass.price.currency }}</span>
        </p>
        <buy-pass-button
          :item-id="pass.store_id"
          :price="pass.price.price"
          :currency="pass.price.currency"
          :is-large="true"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { premiumEventShop } from '@/globalVariables'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import { EventType } from '@/interfaces/events/EventInfo'
import BuyPassButton from '../../BuyPassButton.vue'
import { getResourceUnit } from '@/helpers/getResourceUnit'
import { hasOfferOldPrice, getOfferOldPrice } from '@/helpers/getOfferOldPrice'

import type PremiumEventPassApiResponse from '@/interfaces/responses/premium/PremiumEventPassApiResponse'
import type { OfferContent, PremiumPacksInterface, Reward } from '@/interfaces/premium/Offer'

interface ComponentData {
  paymentPassInfo: PremiumEventPassApiResponse | null
  EventType: typeof EventType
}

type OfferContentOrReward = OfferContent | Reward

interface TierItem {
  tier: number
  reward: number // index
  value: number
  multipleValue?: number
  isMultiplier?: boolean
}

export default defineComponent({
  name: 'EventPass3DetailBody',
  components: { BuyPassButton },
  props: {
    isPaymentView: {
      type: Boolean,
      default: false,
    },
    storeId: {
      type: String,
      default: '',
    },
  },
  data(): ComponentData {
    return {
      paymentPassInfo: null,
      EventType,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      eventPass: 'getEventPass',
    }),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
    activePassIndex(): number {
      return this.eventPass.findLastIndex(
        ({ remaining_buys }: PremiumPacksInterface['packs'][0]): boolean => remaining_buys === 0,
      )
    },
    currentPassNumber(): number {
      if (this.isPaymentView)
        return Number(
          this.pass?.parameters?.find(({ name }: { name: string }) => name === 'pass_tier')
            ?.value ?? 0,
        )
      return Number(this.$route.query?.tier)
    },
    currentPassIndex(): number {
      return this.currentPassNumber - 1
    },
    pass(): PremiumEventPassApiResponse['packs'][0] {
      return this.isPaymentView ? this.paymentPassInfo : this.eventPass[this.currentPassIndex]
    },
    tier(): number {
      return Number(
        this.pass?.parameters?.find(
          ({ name }: PremiumEventPassApiResponse['packs'][0]['parameters'][0]): boolean =>
            name === 'pass_tier',
        )?.value,
      )
    },
    processedOffers(): TierItem[] {
      const offer = this.pass
      const tierArray = []

      const addons = offer.offer_content
        .filter(
          (content: OfferContentOrReward): boolean =>
            content.name === 'event_pass_free_daily_wild_cards' ||
            content.name === 'event_pass_free_daily_energy_drinks',
        )
        .reduce(
          (
            acc: OfferContentOrReward,
            content: OfferContentOrReward,
          ): OfferContentOrReward | TierItem => {
            if (!acc || acc.value !== content.value) {
              return { tier: this.currentPassNumber, reward: 1, value: content.value }
            }
            return acc
          },
          null,
        )
      if (addons) tierArray.push(addons)

      const task_multiplier = offer.offer_content
        .filter(
          (content: OfferContentOrReward): boolean =>
            content.name === 'bounty_board_task_multiplier',
        )
        .reduce(
          (
            acc: OfferContentOrReward,
            content: OfferContentOrReward,
          ): OfferContentOrReward | TierItem => {
            if (!acc || acc.value !== content.value) {
              return {
                tier: this.currentPassNumber,
                reward: 2,
                value: content.value,
                isMultiplier: true,
              }
            }
            return acc
          },
          null,
        )
      if (task_multiplier) tierArray.push(task_multiplier)

      const basket_addons = offer.offer_content
        .filter(
          (content: OfferContentOrReward): boolean =>
            content.name === 'fortune_basket_free_bonuses',
        )
        .reduce(
          (
            acc: OfferContentOrReward,
            content: OfferContentOrReward,
          ): OfferContentOrReward | TierItem => {
            if (!acc || acc.value !== content.value) {
              return {
                tier: this.currentPassNumber,
                reward: 3,
                multipleValue: this.currentPassNumber > 1 ? 3 : 2,
                value: content.value,
              }
            }
            return acc
          },
          null,
        )
      if (basket_addons) tierArray.push(basket_addons)

      const token_assets = offer.offer_content
        .filter(
          (content: OfferContentOrReward): boolean => content.name === 'bounty_board_token_bonus',
        )
        .reduce(
          (
            acc: OfferContentOrReward,
            content: OfferContentOrReward,
          ): OfferContentOrReward | TierItem => {
            if (!acc || acc.value !== content.value) {
              return { tier: this.currentPassNumber, reward: 4, value: content.value }
            }
            return acc
          },
          null,
        )
      if (token_assets) tierArray.push(token_assets)

      return tierArray
    },
  },
  async created(): Promise<void> {
    if (!this.isPaymentView || !this.storeId) return

    try {
      this.paymentPassInfo = (
        await this.$axios.get<{}, PremiumEventPassApiResponse>(
          `${premiumEventShop}?storeId=${this.storeId}`,
        )
      )?.packs?.pop()
    } catch (error: unknown) {
      console.error(error)
    }
  },
  methods: {
    getResourceUnit,
    hasOfferOldPrice,
    getOfferOldPrice,
    getDescription(
      reward: PremiumEventPassApiResponse['packs'][0]['offer_content'][0] | TierItem,
      index: number = null,
    ): string {
      if (this.namespace === EventType.AutumnFair) {
        let text = this.$te(`eventPassPerk${reward.reward}${reward.value === 1 ? 'A' : 'B'}`)

        if (reward.reward === 2)
          text = this.$te(`eventPassPerk${reward.reward}${reward.value === 1 ? 'A' : 'B'}`)

        if (reward.reward === 3)
          text = `<span>${this.$te(`eventPassPerk${reward.reward}${reward.value === 1 ? 'A' : 'C'}`)}</span> ${reward?.multipleValue === 3 ? `<span>${this.$te('eventPassPerk4B')}</span>` : ''}`

        if (reward.reward === 4) text = this.$te(`eventPassPerk5${reward.value === 1 ? 'A' : 'B'}`)

        if (text.includes('{2to4}') && text.includes('%s'))
          return this.$replacePlaceholder(
            this.$replacePlaceholder(text, '{2to4}', `${reward.value}`),
            '%s',
            10, // TODO BE missing value, should be 10 by spec
          )

        return this.$replacePlaceholder(
          this.$replacePlaceholder(text, '{2to4}|%s', `${reward.value}`),
          '{number}',
          reward?.value,
        )
      }
      return this.$replacePlaceholder(
        this.$te(`eventPassPerk${index}${reward.value === 1 ? 'A' : 'B'}`),
        '{2to4}|%s',
        `${reward.value}${this.getResourceUnit(reward.name, true)}`,
      )
    },
  },
})
</script>

<style lang="scss" scoped>
$tiers: (1, 2, 3);
$rewards: (1, 2, 3, 4);

[data-event-theme='frozen'] {
  @each $tier in $tiers {
    --events-passes-event-card-#{$tier}-detail-bg: url('#{$path-events}frozen-championship/passes/event-pass/card-#{$tier}-detail-bg.webp');
    --events-passes-event-payment-card-#{$tier}-detail-bg: url('#{$path-events}frozen-championship/passes/event-pass/payment-card-#{$tier}-detail-bg.webp');
  }

  @each $reward in $rewards {
    --events-passes-event-card-reward-#{$reward}-bg: url('#{$path-events}frozen-championship/passes/event-pass/reward-#{$reward}-bg.webp?v=1');
  }
}

[data-event-theme='autumn'] {
  @each $tier in $tiers {
    --events-passes-event-card-#{$tier}-detail-bg: url('#{$path-events}autumn-fair/passes/event-pass/card-#{$tier}-detail-bg.avif');

    --events-passes-event-payment-card-#{$tier}-detail-bg: url('#{$path-events}autumn-fair/passes/event-pass/payment-card-#{$tier}-detail-bg.avif');

    @each $reward in $rewards {
      --events-passes-event-card-reward-tier-#{$tier}-reward-#{$reward}-bg: url('#{$path-events}autumn-fair/passes/event-pass/event-card-reward-tier-#{$tier}-reward-#{$reward}-bg.avif?v2');
    }
  }
}

.event-pass-detail {
  &-body {
    .card {
      width: 28.563rem;
      height: 37.438rem;
      @include background(null, contain);

      @each $tier in $tiers {
        &.tier-#{$tier} {
          &:not(.is-payment-view) {
            background-image: var(--events-passes-event-card-#{$tier}-detail-bg);
          }

          &.is-payment-view {
            height: 21.25rem;
            background-image: var(--events-passes-event-payment-card-#{$tier}-detail-bg);
          }
        }
      }

      &-image {
        width: 23.875rem;
        height: 13.5rem;
        @include background(null, contain);
        background-position: center bottom;

        @each $reward in $rewards {
          &.reward-#{$reward} {
            background-image: var(--events-passes-event-card-reward-#{$reward}-bg);
          }
        }
      }

      &-header {
        width: 23rem;
        height: 5rem;
      }

      &-footer {
        width: 23rem;
        height: 11.5rem;
      }
    }
  }

  &-body[data-event-theme='autumn'] {
    .card {
      &-image {
        @each $tier in $tiers {
          @each $reward in $rewards {
            &.reward__tier-#{$tier}--reward-#{$reward} {
              background-image: var(
                --events-passes-event-card-reward-tier-#{$tier}-reward-#{$reward}-bg
              );
            }
          }
        }
      }

      &-footer {
        height: 14rem;
      }
    }
  }

  &-footer {
    .activated,
    .purchased {
      width: 40rem;
      border-style: solid;
      border-width: 0.188rem;
      border-image-slice: 1;
    }

    .activated {
      background-image: linear-gradient(
        90deg,
        transparent,
        #0e4503,
        rgba(19, 89, 5, 0.97),
        #1a7708,
        rgba(19, 89, 5, 0.97),
        #0e4503,
        transparent
      );
      border-image-source: linear-gradient(
        90deg,
        transparent,
        #ff9600,
        #358f23,
        #ff9600,
        transparent
      );
    }

    .purchased {
      background-image: linear-gradient(
        90deg,
        transparent,
        #654008,
        rgba(124, 82, 17, 0.97),
        #b66c03,
        rgba(124, 82, 17, 0.97),
        #654008,
        transparent
      );
      border-image-source: linear-gradient(
        90deg,
        transparent,
        #ff9600,
        #f09008,
        #ff9600,
        transparent
      );
    }

    .price-crossed::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 0.25rem;
      background-color: #b52e2d;
      transform: translate(-50%, -50%) skewY(-14deg);
    }
  }
}
</style>
