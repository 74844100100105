<template>
  <div
    class="relative"
    :class="[
      'report-bug-input-wrapper',
      error ? 'wrapper-before-error' : 'wrapper-before',
      error ? '' : 'wrapper-after',
      { 'w-full': fullWidth },
    ]"
  >
    <label v-if="label" :for="name" class="report-bug-input-label">
      {{ $t(label) }}
    </label>
    <tippy
      v-if="type === 'text'"
      :ref="name"
      theme="RED"
      :placement="fullWidth ? 'bottom' : 'left'"
      trigger="manual"
      append-to="parent"
    >
      <input
        v-model="model"
        :name="name"
        :placeholder="$t(placeholder)"
        class="report-bug-input report-bug-input-text"
        :class="[
          error ? 'error-border' : 'default-border',
          fullWidth ? 'w-full' : 'report-bug-input-minimal-width',
        ]"
      />
      <template #content>
        <div>{{ $t('bugReport.requiredField') }}</div>
      </template>
    </tippy>
    <tippy
      v-else-if="type === 'textarea'"
      :ref="name"
      theme="RED"
      :placement="fullWidth ? 'bottom' : 'left'"
      trigger="manual"
      append-to="parent"
    >
      <textarea
        v-model="model"
        :name="name"
        :placeholder="$t(placeholder)"
        class="report-bug-input report-bug-input-textarea"
        :class="[
          error ? 'error-border' : 'default-border',
          fullWidth ? 'w-full' : 'report-bug-input-minimal-width',
        ]"
      />
      <template #content>
        <div>{{ $t('bugReport.requiredField') }}</div>
      </template>
    </tippy>
    <div v-if="required" class="report-bug-input-required">
      {{ $t('bugReport.required') }}
    </div>
    <app-icon
      v-if="type !== 'textarea' && tooltip"
      v-tippy="{
        allowHTML: true,
        content: $t(tooltip),
        placement: 'right',
        theme: 'WSM',
      }"
      class="report-bug-input-icon"
      icon-name="info-70"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppReportBugInput',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'text-input',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    tooltip: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get(): string {
        return this.modelValue
      },
      set(value: string): void {
        this.$emit('update:modelValue', value)
      },
    },
  },
  watch: {
    error: {
      immediate: true,
      handler(newValue: boolean): void {
        if (newValue) this.showErrorTooltip()
      },
    },
  },
  methods: {
    showErrorTooltip(): void {
      this.$refs[this.name].show()
    },
  },
})
</script>

<style lang="scss" scoped>
.report-bug-input {
  min-width: 56.625rem;
  position: relative;
  padding: 1.25rem 1.875rem;
  font-size: 2.5rem;
  color: #d9ecff;
  @if $isWsm {
    font-style: italic;
  }
  background: #0a1b2f;

  &-text {
    height: 5.1875rem;
  }

  &-textarea {
    height: 20.8125rem;
    resize: none;
  }

  &-minimal-width {
    min-width: 56.625rem;
  }

  &:focus {
    outline: none;

    @if $isWsm {
      background-color: #092844;
    }
  }

  &-wrapper {
    margin-bottom: 4.25rem;
  }

  &-label {
    position: absolute;
    top: -1.25rem;
    transform: translate(0, -100%);
    font-size: 1.3125rem;
    line-height: 1.3125rem;
    color: #d9ecff;
    @if $isWsm {
      font-style: italic;
    }
  }

  &-required {
    position: absolute;
    top: -1.25rem;
    right: 0;
    transform: translate(0, -100%);
    font-size: 1.3125rem;
    line-height: 1.3125rem;
    color: #d9ecff;
    @if $isWsm {
      font-style: italic;
    }
  }

  &-icon {
    position: absolute;
    top: 0.375rem;
    right: 0.375rem;
    font-size: 1.3125rem;
    line-height: 1.3125rem;
    color: #d9ecff;
    @if $isWsm {
      font-style: italic;
    }
  }
}

.wrapper-before-error {
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 1.4375rem 1.4375rem;
    border-color: transparent transparent transparent #fb4343;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 1;
  }
}

.wrapper-before {
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 1.563rem;
    height: 1.563rem;
    background: transparent;
    border-bottom: 0.094rem solid #b5deff;
    border-left: 0.094rem solid #b5deff;
    z-index: 1;
  }
}

.wrapper-after {
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 1.563rem;
    height: 1.563rem;
    background: transparent;
    border-top: 0.094rem solid #b5deff;
    border-right: 0.094rem solid #b5deff;
  }
}

.error-border {
  border: 1px solid #fb4343;
}

.default-border {
  border: 1px solid #5ba3dc;
}
</style>
