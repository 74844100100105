import type Reward from '@/interfaces/Reward'
import {
  ALL_GRAND_PRIZES,
  GRAND_PRIZE_OPENED,
  GRAND_PRIZE_UNOPENED,
  GRAND_PRIZE_UNCLAIMED,
  GRAND_PRIZE_CLAIMED,
} from '@/globalVariables'

export interface GrandPrizeHistoryData {
  existsTime: Date
  gpLevel: number
  gpProgressName: string
  gpType: string
  id: string
  rewards: Reward[]
  status: string
  time: number
  timeWarning: number
}

export enum GrandPrizeHistoryPreference {
  All = ALL_GRAND_PRIZES,
  Opened = GRAND_PRIZE_OPENED,
  Unopened = GRAND_PRIZE_UNOPENED,
  Unclaimed = GRAND_PRIZE_UNCLAIMED,
  Claimed = GRAND_PRIZE_CLAIMED,
}

export interface GrandPrizeTierSlot {
  slot_rarity_id: number
  slot_rarity: string
  amount: number
  possible_rewards: GrandPrizeTierPossibleReward[]
}
interface GrandPrizeTierPossibleReward {
  from: number
  to: number
  drop_rate: number
  parameter: string
}
export interface GrandPrizeTier {
  [key: string]: GrandPrizeTierData
}

export interface GrandPrizeTierData {
  tier: number
  badges: string[]
  gpName?: string
  gpSlots?: GrandPrizeTierSlot[]
}

export interface GrandPrizeMainReward {
  id: number
  accessible: boolean
  reward: Reward
}

export interface GrandPrizeProgressData {
  id: number
  name: string
  amount: number
  target_value: number
  target_grand_prize: string
  buildingId: string
  completed: boolean
  gpType: string
  locked: boolean
  progress: {
    actual: number
    goal: number
  }
  texts: {
    description: string
    title: string
  }
  url: string
}
