<template>
  <div
    class="modal-head flexing"
    :class="{
      'modal-head--mobile': $isMobile(),
      'modal-head--w-full': isFullWidth,
      'modal-head--gradient-overflowing': isGradientOverflowing,
    }"
  >
    <app-icon v-if="showInfo && !isIconHidden" icon-name="question" @click="showInfoPopup = true" />
    <app-icon
      v-if="!showInfo && !isIconHidden"
      v-tippy="{
        theme: 'WSM',
        content: titleTooltip,
        placement: 'right',
        textAlign: 'center',
      }"
      icon-name="info-70"
      class="arena-building-main-left-header-info"
    />
    <div class="modal-head-content flexing" :class="{ 'hide-content': hideContent }">
      <slot />
      <span v-if="$isWsm" class="elipse" />
    </div>
    <slot name="rightSide" />
    <info-popup v-if="showInfoPopup" :popup-title="titlePopup" @close="showInfoPopup = false">
      <div class="popup-about" :class="{ 'popup-web': !$isMobile() }">
        <app-scrollbar width="99.5%" height="39rem" scroll="y" slide="y">
          <slot name="popupContent" />
        </app-scrollbar>
      </div>
    </info-popup>
  </div>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { defineComponent } from 'vue'

interface ComponentData {
  showInfoPopup: boolean
}

export default defineComponent({
  name: 'ModalHead',
  components: {
    InfoPopup,
  },
  props: {
    titlePopup: {
      type: String,
      default: 'Title',
    },
    titleTooltip: {
      type: String,
      default: 'Title',
    },
    showInfo: {
      type: Boolean,
      default: true,
    },
    hideContent: {
      type: Boolean,
      default: false,
    },
    isIconHidden: {
      type: Boolean,
      default: false,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    isGradientOverflowing: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      showInfoPopup: false,
    }
  },
})
</script>

<style lang="scss" scoped>
.modal-head {
  width: calc(100% - 4%);
  height: 4.875rem;
  margin: 1rem 2.25rem 1.188rem;
  z-index: 0;

  &--mobile {
    // treatment of dimensionally overflowing training-header-timer
    position: relative;
  }

  &--backgroundless {
    .modal-head-content {
      background: transparent;

      .elipse {
        display: none;
      }
    }
  }

  &--w-full {
    .modal-head-content {
      width: 100%;
      min-width: 100%;
    }
  }

  &--gradient-overflowing {
    .modal-head-content {
      @if $isWsm {
        background: linear-gradient(
          to right,
          transparent -10%,
          #2a5171 22%,
          #2a5171 82%,
          transparent 110%
        );
      }
      @if $isSsm {
        background: linear-gradient(
          to right,
          transparent 0%,
          #56627f 22%,
          #56627f 82%,
          transparent 100%
        );
      }

      .elipse {
        background: linear-gradient(
          to bottom,
          transparent 0,
          #173a5b 30%,
          #173a5b 60%,
          transparent 100%
        );
        width: 100%;
        height: 3.125rem;
      }
    }
  }

  &-content {
    width: 100%;
    margin: 0 auto;
    min-width: 102rem;
    height: 4.875rem;

    @if $isWsm {
      background-image: linear-gradient(
        to right,
        transparent 0%,
        #2a5171 22%,
        #2a5171 82%,
        transparent 100%
      );
    }

    @if $isSsm {
      background-image: linear-gradient(to right, transparent, #56627f, #56627f, transparent);
    }

    // zrusenie bg pre pripad, ze v niektorych pripadoch nechceme zobrazit
    &.hide-background {
      background: none;
    }
    .elipse {
      width: 108rem;
      height: 2.125rem;
      position: absolute;
      background: linear-gradient(to right, transparent 0, #173a5b, transparent 100%);
      filter: blur(0.625rem);
    }
  }
}

.popup-about {
  width: 102.75rem;
  margin-top: 1rem;
}
</style>
