<template>
  <div class="daily-bonus app-page-wrapper absolute">
    <menu-component :menu-type="'daily-bonus'" :title="'daily-bonus'" />
    <section class="w-full h-full safe-area">
      <header
        class="daily-bonus-header bordered-1 bordered-1-sm m-auto text-28 flex items-center text-texts-standard-default"
      >
        <app-icon :icon-name="'info-rounded'" />
        <!-- TODO ak sa komponent planuje pouzivat > nahradit staticky text -->
        <p class="text-left">Kazdy den tu na teba caka odmena.Nenechaj si ju ujst</p>
      </header>
      <main class="daily-bonus-main m-auto flex items-center">
        <section v-for="(bonus, index) in bonuses" :key="index" class="daily relative">
          <div
            v-show="bonus.status === 2"
            class="daily-active-bg absolute"
            :style="{
              background:
                'url(' + pathImages + 'dailyBonus/bg_' + bonus.gp + '_flare.avif)center no-repeat',
              backgroundSize: 'contain',
            }"
          />
          <div class="daily-gp flexing relative w-full">
            <app-icon
              v-show="bonus.status === 1"
              :style="{ zIndex: 3 }"
              class="absolute m-auto"
              :icon-name="'done-lg'"
            />
            <app-main-icon
              :class="{ disabled: bonus.status !== 2 }"
              class="daily-gp-icon"
              :icon-name="'gp-' + bonus.gp"
              :icon-size="184"
            />
            <app-timer v-show="bonus.next" :time="60" class="gp-time" />
          </div>
          <div class="daily-slider relative">
            <div
              class="daily-slider-done h-full"
              :style="{ width: sliderWidth(bonus.status) + '%' }"
            />
            <div
              class="triangle triangle-up absolute"
              :class="[bonus.status === 1 || bonus.status === 2 ? 'yellow' : 'lightblue']"
            />
          </div>
          <p
            class="uppercase font-bold text-36 text-texts-standard-default m-auto"
            :class="{ 'text-texts-standard-important': bonus.status === 2 }"
          >
            Day {{ index + 1 }}
          </p>
        </section>
      </main>
      <footer class="daily-bonus-footer m-auto relative">
        <section
          v-if="timer"
          class="daily-bonus-footer-timer uppercase flexing text-texts-standard-default text-28"
        >
          <p>Denny bonus sa resetuje o:</p>
          <app-timer class="footer-timer" :time="10000" />
        </section>
        <app-button
          class="footer-btn absolute"
          :btn-size="'xl'"
          :btn-type="'secondary'"
          :disabled="timer"
        >
          Prevziat odmenu
        </app-button>
      </footer>
    </section>
  </div>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'

interface ComponentData {
  pathImages: typeof pathImages
  timer: boolean
  bonuses: {
    gp: string
    status: number
    next: boolean
  }[]
}

export default defineComponent({
  data(): ComponentData {
    return {
      pathImages,
      timer: true,
      bonuses: [
        { gp: 'basic', status: 1, next: false },
        { gp: 'bronze', status: 1, next: false },
        { gp: 'bronze', status: 1, next: false },
        { gp: 'silver', status: 1, next: false },
        { gp: 'silver', status: 2, next: false },
        { gp: 'gold', status: 3, next: false },
        { gp: 'diamond', status: 3, next: false },
      ],
    }
  },
  methods: {
    sliderWidth(status: number): number {
      if (status === 2) {
        return 56
      } else if (status === 1) {
        return 100
      } else return 0
    },
  },
})
</script>

<style lang="scss" scoped>
.daily-bonus {
  &-header {
    height: 5.4375rem;
    width: 116.25rem;
    margin-top: 1.375rem;
    background: #2952794f;
    box-shadow:
      inset 0.063rem 0 1.25rem 0 rgba(25, 73, 105, 0.8),
      inset 0.75rem 0.188rem 1.25rem 0 rgba(25, 73, 105, 0.8),
      inset 0 0 1.25rem 0 rgba(25, 73, 105, 0.8),
      inset -0.688rem -0.188rem 1.25rem 0 rgba(25, 73, 105, 0.8);
    padding-left: 1.375rem;
    border-bottom: rgba(255, 255, 255, 0.18) solid 0.1875rem;

    .text-left {
      margin-left: 1.6875rem;
    }
  }

  &-main {
    height: 30.0625rem;
    width: 116.25rem;
    margin-top: 3.75rem;

    .daily {
      width: 18.9375rem;

      &-active-bg {
        left: -18rem;
        top: -4rem;
        width: 52.3125rem;
        height: 34.875rem;
      }

      &-gp {
        height: 20rem;

        &-icon {
          z-index: 2;
        }

        .disabled {
          opacity: 0.5;
        }

        .gp-time {
          z-index: 3;
          background: linear-gradient(to right, #9f1d2100, #c31818, #c31818, rgba(159, 29, 33, 0));
          position: absolute;
          width: 13.625rem;
          min-width: 13.625rem;
          height: 3.625rem;
          justify-content: center;

          &:before {
            display: none;
          }
        }
      }

      &-slider {
        margin-bottom: 2.5rem;
        background: #4bacd9;
        height: 1rem;
        z-index: 2;

        &-done {
          background: #fadd1e;
        }

        .triangle {
          left: 47%;
          top: -1.3rem;
        }
      }
    }
  }

  &-footer {
    margin-top: 4.3125rem;
    height: 9.875rem;
    width: 47.5625rem;

    &-timer {
      margin-bottom: 1.875rem;

      .footer-timer {
        margin-left: 1.2rem;
      }
    }

    .footer-btn {
      bottom: 0;
    }
  }
}
</style>
