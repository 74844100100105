<template>
  <div class="club-chat-new">
    <div class="club-chat-new-input">
      <app-input
        id="club-chat-new-text"
        :value="newMessage"
        :placeholder="$t('club.chatMessageText')"
        class="club-chat-new-input-field text-texts-standard-default text-40 italic"
        :max-length="255"
        :sending-by-enter="true"
        @input="(value: string) => (newMessage = value)"
        @enter="sendMessage"
      />
    </div>
    <div class="button">
      <app-button
        btn-type="secondary"
        :btn-text="$t('bugReport.send')"
        btn-size="md"
        :disabled="!newMessage"
        @click="sendMessage"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

interface ComponentData {
  newMessage: string
}

export default defineComponent({
  name: 'ChatNewMessage',
  emits: ['sendMessage'],
  data(): ComponentData {
    return {
      newMessage: '',
    }
  },
  methods: {
    sendMessage(): void {
      if (!this.newMessage) return
      this.$emit('sendMessage', this.newMessage)
      this.newMessage = ''
    },
    setMessage(e: string): void {
      this.newMessage = e
    },
  },
})
</script>

<style lang="scss" scoped>
.club-chat {
  &-new {
    width: 98%;
    height: 5rem;
    margin: 1.563rem auto 2.188rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-input {
      flex: 1 1 auto;
      margin-right: 2.5rem;

      &-field {
        width: 100%;
        height: 5rem;
      }
    }
  }
}
</style>
