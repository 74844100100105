import { careerDetailEndpoint } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import { CareerBranch } from '@/enums/CareerBranch'
import type {
  CareerDetailApiResponse,
  CareerQuestResponse,
  CareerQuestRewardResponse,
} from '@/interfaces/responses/career/CareerDetailApiResponse'
import type { QuestReward, CareerQuest } from '@/interfaces/Quest'

interface FormattedCareerDetailData {
  careerId: string
  careerBranch: {
    id: number
    type: CareerBranch.Main | CareerBranch.Secondary
    disciplineId?: number | string
  }
  doneQuests: number
  quests: CareerQuest[]
}

interface CareerDetailStoreState {
  careerDetailData: FormattedCareerDetailData
  questText: string
  isQuestsLoading: boolean
}

export const useCareerDetailStore = defineStore('careerStore', {
  state: (): CareerDetailStoreState => ({
    careerDetailData: {} as FormattedCareerDetailData,
    questText: '',
    isQuestsLoading: false,
  }),
  getters: {
    getCareerDetail: (state: CareerDetailStoreState): CareerDetailStoreState['careerDetailData'] =>
      state.careerDetailData,
    getActiveQuest: (state: CareerDetailStoreState): CareerQuest =>
      state.careerDetailData.quests?.find((quest: CareerQuest): boolean => quest.isActive),
    getQuestById:
      (state: CareerDetailStoreState) =>
      (id: number): CareerQuest =>
        state.careerDetailData.quests?.find(
          (quest: CareerQuest): boolean => quest.parameters.id === Number(id),
        ),
    showFinalQuest: (state: CareerDetailStoreState): boolean =>
      state.careerDetailData.quests?.filter(
        (quest: CareerQuest): number => quest.stats?.rewardsClaimed,
      ).length ===
      state.careerDetailData.quests?.filter((quest: CareerQuest): boolean => !quest.isFinalQuest)
        .length,
    getFinalQuest: (state: CareerDetailStoreState): CareerQuest =>
      state.careerDetailData.quests?.find((quest: CareerQuest): boolean => quest.isFinalQuest),
  },
  actions: {
    async loadCareerDetail(careerId: string = ''): Promise<void> {
      this.isQuestsLoading = true
      const response = await internalAxios.get<{}, CareerDetailApiResponse>(
        `${careerDetailEndpoint}/${careerId}`,
      )

      const data: FormattedCareerDetailData = {
        careerId: response.career_id,
        careerBranch: {
          id: response.careers_branch?.id,
          type: response.careers_branch?.type,
          disciplineId: response.careers_branch?.discipline_id,
        },
        doneQuests: response.careers_quests_done_count,
        quests: response.careers_quests?.map((quest: CareerQuestResponse): CareerQuest => {
          return {
            hasUnlocks: quest.has_unlocks,
            isActive: quest.is_active,
            isTracked: quest?.is_tracked ?? false,
            isDone: quest.quest_done === 1,
            isLocked: quest.quest_locked,
            bossFightRoundUnlocked: quest.boss_fight_round_unlocked,
            parameters: quest.careers_quests_parameters,
            stats: {
              currentValue: quest.careers_quests_stats?.current_value ?? null,
              rewardsClaimed: quest.careers_quests_stats?.rewards_claimed ?? null,
              bossFightTreshold: quest.careers_quests_stats?.boss_fight_threshold ?? null,
              adSeen: quest.careers_quests_stats?.ad_seen ?? null,
            },
            rewards: quest.careers_quest_game_rewards.map(
              (reward: CareerQuestRewardResponse): QuestReward => {
                return {
                  amount: reward.amount,
                  name: reward.name,
                  type: reward.type,
                  category: reward.category,
                  rarity: reward?.rarity ?? null,
                  level: reward?.level ?? null,
                  bossfightThreshold: reward?.bossfight_threshold ?? null,
                }
              },
            ),
          }
        }),
      }

      // THIS IS IN CASE PLAYER FINISH ALL PLAYABLE QUESTS => SHOW HIM "FINAL QUEST"
      const lastPlayableQuest = data.quests[data.quests?.length - 1]
      if (data.careerBranch.type === CareerBranch.Main && lastPlayableQuest.isDone) {
        const finalQuest = {
          parameters: {
            id: lastPlayableQuest.parameters.id + 1,
            order: lastPlayableQuest.parameters.order + 1,
          },
          isLocked: false,
          isActive: lastPlayableQuest.isDone,
          isFinalQuest: true,
        }

        data.quests.push(finalQuest)

        this.isQuestsLoading = false
        this.careerDetailData = data

        return
      }

      this.isQuestsLoading = false
      this.careerDetailData = data
    },
  },
})
