import type { Nullable } from '@/interfaces/utils'

// USAGE:
// 1. import a collection of effects into the animated scene: src/assets/styles/animations.scss

// 2. compose the animateScene method using:
// >> animateElement(element, 'animation-name', 'animation-duration', 'animation-delay')

// 3. statically assign appropriate init animation state to each animated element in the form of CSS class; e.g. animated-element--faded

export const animateElement = (
  element: HTMLElement,
  style: string,
  duration: string = '1s',
  delay: number,
  timingFunction: Nullable<string> = null,
  iterationCount: Nullable<string> = null,
): void => {
  element.style.animationName = style
  element.style.animationDuration = duration
  element.style.animationFillMode = 'forwards'
  element.style.animationDelay = `${delay}ms`

  if (timingFunction) {
    element.style.animationTimingFunction = timingFunction
  }

  if (iterationCount) {
    element.style.animationIterationCount = iterationCount
  }
}

export const resetAnimateElement = (element: HTMLElement): void => {
  element.style.animationName = ''
  element.style.animationDuration = ''
  element.style.animationFillMode = ''
  element.style.animationDelay = ''
}

export const skipAnimationOnClick = (
  e: MouseEvent,
  element: HTMLElement,
  refs: Record<string, HTMLElement>,
  condition: boolean,
  selectors: [selector: string, cssClass: string][],
): void => {
  if (condition) {
    if (!element.contains(e.target)) {
      const elements = refs
      Object.keys(elements).forEach((refElement: string): void => {
        if (elements[refElement]) {
          elements[refElement].classList.remove('animated-element--faded')
          resetAnimateElement(elements[refElement])
        }
      })

      if (selectors) {
        selectors.forEach(([selector, cssClass]: [string, string]): void => {
          handleElements(elements[selector], cssClass)
        })
      }
    }
  }
}

const handleElements = (element: HTMLElement, cssClass: string): void => {
  if (element) {
    const selectedElements = element.querySelectorAll(cssClass)
    selectedElements.forEach((el: HTMLElement): void => {
      el.classList.remove('animated-element--faded')
      resetAnimateElement(el)
    })
  }
}
