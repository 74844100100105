const exportedEtcJson = {
  frames: {
    event_osra_discipline_2_1_outline: {
      frame: { x: 215, y: 213, w: 105, h: 105 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 5, w: 105, h: 105 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_4_1_outline: {
      frame: { x: 1, y: 225, w: 104, h: 94 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 15, w: 104, h: 94 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_8_1_outline: {
      frame: { x: 215, y: 108, w: 106, h: 103 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 7, w: 106, h: 103 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_1_1_outline: {
      frame: { x: 107, y: 225, w: 103, h: 93 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 2, y: 14, w: 103, h: 93 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_6_1_outline: {
      frame: { x: 1, y: 1, w: 110, h: 110 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 0, w: 110, h: 110 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_7_1_outline: {
      frame: { x: 215, y: 1, w: 106, h: 105 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 5, w: 106, h: 105 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_3_1_outline: {
      frame: { x: 1, y: 113, w: 105, h: 110 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 0, w: 105, h: 110 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_5_1_outline: {
      frame: { x: 108, y: 113, w: 105, h: 110 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 0, w: 105, h: 110 },
      sourceSize: { w: 110, h: 110 },
    },
  },
}

const exportedAstcJson = {
  frames: {
    event_osra_discipline_2_1_outline: {
      frame: { x: 215, y: 213, w: 105, h: 105 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 5, w: 105, h: 105 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_4_1_outline: {
      frame: { x: 1, y: 225, w: 104, h: 94 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 15, w: 104, h: 94 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_8_1_outline: {
      frame: { x: 215, y: 108, w: 106, h: 103 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 7, w: 106, h: 103 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_1_1_outline: {
      frame: { x: 107, y: 225, w: 103, h: 93 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 2, y: 14, w: 103, h: 93 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_6_1_outline: {
      frame: { x: 1, y: 1, w: 110, h: 110 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 0, w: 110, h: 110 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_7_1_outline: {
      frame: { x: 215, y: 1, w: 106, h: 105 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 5, w: 106, h: 105 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_3_1_outline: {
      frame: { x: 1, y: 113, w: 105, h: 110 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 0, w: 105, h: 110 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_5_1_outline: {
      frame: { x: 108, y: 113, w: 105, h: 110 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 0, w: 105, h: 110 },
      sourceSize: { w: 110, h: 110 },
    },
  },
}

const exportedImgJson = {
  frames: {
    event_osra_discipline_2_1_outline: {
      frame: { x: 215, y: 213, w: 105, h: 105 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 5, w: 105, h: 105 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_4_1_outline: {
      frame: { x: 1, y: 225, w: 104, h: 94 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 15, w: 104, h: 94 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_8_1_outline: {
      frame: { x: 215, y: 108, w: 106, h: 103 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 7, w: 106, h: 103 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_1_1_outline: {
      frame: { x: 107, y: 225, w: 103, h: 93 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 2, y: 14, w: 103, h: 93 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_6_1_outline: {
      frame: { x: 1, y: 1, w: 110, h: 110 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 0, w: 110, h: 110 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_7_1_outline: {
      frame: { x: 215, y: 1, w: 106, h: 105 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 5, w: 106, h: 105 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_3_1_outline: {
      frame: { x: 1, y: 113, w: 105, h: 110 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 0, w: 105, h: 110 },
      sourceSize: { w: 110, h: 110 },
    },
    event_osra_discipline_5_1_outline: {
      frame: { x: 108, y: 113, w: 105, h: 110 },
      rotated: false,
      trimmed: true,
      spriteSourceSize: { x: 0, y: 0, w: 105, h: 110 },
      sourceSize: { w: 110, h: 110 },
    },
  },
}

export const eventOsraOutlineEtcJson = exportedEtcJson
export const eventOsraOutlineAstcJson = exportedAstcJson
export const eventOsraOutlineImgJson = exportedImgJson
