<template>
  <div class="seasons-detail">
    <div
      v-if="!isContentLoading"
      class="seasons-detail-box flex bordered-5 flex-col text-32 text-white"
    >
      <div class="seasons-detail-box-header flexing uppercase text-40 font-bold">
        <p>
          {{ $t('seasons.season') }}
          <span class="text-texts-standard-important">
            {{ seasonStats.seasonMonth }}
          </span>
          / {{ seasonStats.seasonYear }}
        </p>
      </div>
      <div class="seasons-detail-box-content flex flex-col">
        <div class="seasons-detail-box-content-badge flexing">
          <div class="seasons-detail-box-content-badge-img">
            <seasons-badge
              :season-month="seasonStats.seasonMonth"
              :season-year="seasonStats.seasonYear"
            />
          </div>
        </div>
        <p
          :class="[
            {
              'actual-season-height text-texts-standard-important': isCurrentSeason,
              'text-texts-standard-additional': isPreviousSeason && $isWsm,
              'text-texts-standard-important': isPreviousSeason && $isSsm,
              uppercase: $isSsm,
            },
          ]"
          class="seasons-detail-box-content-title text-36 font-bold"
        >
          {{ isCurrentSeason ? $t('seasons.currentSeason') : $t('seasons.previousSeason') }}
        </p>
        <div v-if="isPreviousSeason" class="gray-strip mx-auto" />

        <seasons-leaderboards-maximum
          v-if="isPreviousSeason"
          class="mt-2"
          :value="seasonStats.seasonalRp"
        />
        <div class="seasons-detail-box-content-time text-texts-standard-additional flex flex-col">
          <p>{{ seasonStats.remainingTime ? $t('seasons.timeTillSeasonEnd') : '&nbsp;' }}</p>
          <app-timer
            class="seasons-detail-box-content-time-timer"
            icon="refresh"
            :centered="true"
            :time="seasonStats.remainingTime"
            :timer-end-text="$t('seasons.ended')"
          />
        </div>

        <div class="gray-strip my-8 mx-auto" />

        <app-button
          v-if="isCurrentSeason"
          class="seasons-detail-box-content-button"
          btn-type="primary"
          :btn-text="$t('seasons.previousSeason')"
          @click="goToPreviousSeason"
        />

        <app-button
          v-if="isPreviousSeason"
          class="seasons-detail-box-content-button"
          btn-type="secondary"
          :btn-text="$t('seasons.currentSeason')"
          @click="goToCurrentSeason"
        />
      </div>
    </div>

    <component-loading :is-loading="isContentLoading" height="100%" type="secondary" />
  </div>
</template>

<script lang="ts">
import SeasonsBadge from '@/components/Seasons/SeasonsBadge.vue'
import SeasonsLeaderboardsMaximum from '@/components/Seasons/SeasonsLeaderboardsMaximum.vue'
import { CURRENT_SEASON, PREVIOUS_SEASON } from '@/globalVariables'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Season } from '@/interfaces/Seasons'
import type { Nullable } from '@/interfaces/utils'

export default defineComponent({
  name: 'SeasonsDetailBox',
  components: {
    SeasonsBadge,
    SeasonsLeaderboardsMaximum,
  },
  props: {
    seasonStats: {
      type: Object as PropType<Nullable<Season>>,
      default: () => null,
    },
  },
  emits: ['loadSeasonStats'],
  computed: {
    isCurrentSeason(): boolean {
      return this.$route.params.type === CURRENT_SEASON
    },
    isPreviousSeason(): boolean {
      return this.$route.params.type === PREVIOUS_SEASON
    },
    isContentLoading(): boolean {
      return this.seasonStats.seasonYear === ''
    },
  },
  methods: {
    goToPreviousSeason(): void {
      this.$router.push({
        name: this.$getWebView('Seasons'),
        params: { type: 'previous' },
      })

      this.$emit('loadSeasonStats', PREVIOUS_SEASON)
    },
    goToCurrentSeason(): void {
      this.$router.push({
        name: this.$getWebView('Seasons'),
        params: { type: 'current' },
      })

      this.$emit('loadSeasonStats', CURRENT_SEASON)
    },
  },
})
</script>

<style lang="scss" scoped>
.seasons-detail {
  height: 52.375rem;
  width: 30%;

  &-box {
    width: 100%;
    height: 100%;
    border: 0.063rem solid #a88741;

    &-header {
      background-image: url($path-images + 'seasons/box-header-bg.avif');
      background-size: 100% auto;
      height: 5rem;
      width: 100%;
    }

    &-content {
      height: 100%;
      background-image: url($path-images + 'seasons/box-content-bg.avif');
      background-size: cover;

      &-badge {
        height: 17rem;

        &-img {
          width: 13.688rem;
          height: 13.875rem;
          margin-top: 1rem;
        }
      }

      &-title {
        padding-top: 1rem;
      }

      &-time {
        margin-top: 2rem;

        &-timer {
          width: 30%;
          margin: 0 auto;
          padding-right: 2rem;
          padding-left: 0 !important;
        }
      }

      &-button {
        width: 93%;
        margin: 0 auto;
      }
    }
  }
}

.actual-season-height {
  height: 12.5rem;
}
</style>
