<template>
  <header class="rankings-header relative w-full flex justify-end">
    <app-select
      :options="periods"
      :default="defaultPeriod"
      class="selectbox-periods"
      @select="$emit('set-period', $event)"
    />
    <tippy theme="WSM" placement="left" max-width="35rem">
      <app-icon icon-name="info-70" />
      <template #content>
        <div class="tooltip-content text-texts-standard-default text-30">
          <p>
            {{ $replacePlaceholder($t('rankingsGroup.rankingRefreshTime'), '%s', '30') }}
          </p>
        </div>
      </template>
    </tippy>
  </header>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import type { SelectOption } from './RankingsMain.vue'

export default defineComponent({
  props: {
    periods: {
      type: Array as PropType<SelectOption[]>,
      default: () => [],
    },
    defaultPeriod: {
      type: String,
      default: '',
    },
  },
  emits: ['set-period'],
})
</script>

<style lang="scss" scoped>
.rankings-header {
  height: 4.375rem;
  margin-bottom: 1.25rem;

  .selectbox-periods {
    position: absolute;
    left: 50%;
    transform: translate(-50%) $skewX-value !important;
  }
}
</style>
