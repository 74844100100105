<template>
  <tippy theme="WSM1" placement="top" max-width="36.25rem">
    <app-icon icon-name="warning-50" />
    <template #content>
      <div class="no-starts-tooltip p-4">
        <div class="head-text text-30">{{ $t('arena.notEnoughBenefitsForLive') }}</div>
        <div
          class="undertext relative text-texts-standard-important text uppercase font-bold text-34"
        >
          {{ $t(typeData[type].text) }}
        </div>
        <section class="flex justify-around mt-8">
          <div v-for="n in 2" :key="n" class="info-box w-1/2">
            <span class="text-28">{{ $t(n === 1 ? 'common.live' : 'common.simulation') }}</span>
            <div class="flexing">
              <div
                v-if="type === 'attribute'"
                :class="'icon-discipline icon-discipline-' + disciplineId + '-light-48'"
              />
              <app-main-icon v-else :icon-size="48" :icon-name="'money'" />
              <span class="text-34 text-texts-standard-important">{{
                n === 1 ? liveValue : simulationValue
              }}</span>
            </div>
          </div>
        </section>
      </div>
    </template>
  </tippy>
</template>

<script lang="ts">
import { ATTRIBUTE, CASH } from '@/globalVariables'
import { defineComponent } from 'vue'

interface ComponentData {
  typeData: Record<string, Record<'text', string>>
}

export default defineComponent({
  name: 'NotEnoughBenefitsForLive',
  props: {
    type: {
      type: String,
      required: true,
      validator(value: string): boolean {
        return [CASH, ATTRIBUTE].includes(value)
      },
    },
    disciplineId: {
      type: Number,
      default: 1,
    },
    liveValue: {
      type: Number,
      default: 0,
    },
    simulationValue: {
      type: Number,
      default: 0,
    },
  },
  data(): ComponentData {
    return {
      typeData: {
        cash: {
          text: 'common.reward',
        },
        attribute: {
          text: 'common.totalPower',
        },
      },
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';

.no-starts-tooltip {
  width: 36.25rem;
  height: 19.813rem;

  .icon-discipline {
    width: 3rem;
    height: 3rem;
  }

  .head-text {
    border-bottom: 0.063rem solid #438eb8;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }

  .undertext {
    &:after {
      content: '';
      background-color: rgba($color: #366885, $alpha: 0.9);
      width: 50%;
      height: 90%;
      position: absolute;
      opacity: 0.2;
      box-shadow: 0 0 3rem 2rem #366885;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
    }
  }

  .info-box:first-child {
    border-right: 0.063rem solid #438eb8;
  }
}
</style>
