import { CHANGE_SCENE, SCENES_KEYS, RESUME_SCENE, PAUSE_SCENE } from '@/map-phaser-new/constants'
import { CoreHandler } from '../../abstractClasses/coreHandler'
import router from '@/router'

class SceneHandler extends CoreHandler {
  protected game: Phaser.Game

  public setUp(game: Phaser.Game, emitter: Phaser.Events.EventEmitter) {
    this.game = game
    emitter.on(CHANGE_SCENE, this.setActiveScene, this)
    emitter.on(PAUSE_SCENE, this.pauseScene, this)
    emitter.on(RESUME_SCENE, this.resumeScene, this)
  }

  public pauseScene() {
    this.getActiveScene().scene.pause()
  }

  public resumeScene() {
    const activeScene: string =
      (router.currentRoute.value.meta?.activeScene as string) ?? SCENES_KEYS.MainMapScene

    this.getActiveScene().scene.resume(activeScene)
  }

  public startScene(sceneName: string): void {
    this.getActiveScene().scene.start(sceneName)
  }

  public setActiveScene(willBecomeActiveScene: string): void {
    const currentActiveScene: string = this.getActiveScene().scene.key

    if (
      !currentActiveScene ||
      currentActiveScene === SCENES_KEYS.BootScene ||
      willBecomeActiveScene === currentActiveScene
    )
      return

    this.getActiveScene().game.scene.switch(currentActiveScene, willBecomeActiveScene)
  }
}

// This class have to be singleton because is used by all scenes. We don't want to allow create
// new instances every time is this class needed.
export const sceneHandler = new SceneHandler()
