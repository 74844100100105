<template>
  <menu-component
    menu-type="club-championship-menu"
    :show-homepage-scene="isHomepage"
    :title="$t('common.clubChampionship')"
    :menu-items="{
      1: {
        text: $t('arena.competitions'),
        route: $getWebView('ClubsChampionshipCompetitions'),
      },
      2: {
        text: $t('starOfTheDay.table'),
        route: $getWebView('ClubsChampionshipTable'),
      },
      3: {
        text: $t('arena.history'),
        route: $getWebView('ClubsChampionshipHistory'),
      },
    }"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClubsChampionshipMenu',
  computed: {
    isHomepage(): boolean {
      return (
        this.$route.query.redirectFrom === 'LayoutView' || this.$route.query.homepage === 'true'
      )
    },
  },
})
</script>
