<template>
  <div class="component-arrows">
    <div
      v-if="isCareer ? true : currentIndex > 0"
      class="arrow-left absolute top-1/2 arr-left"
      :class="{
        'arr-left-career': isCareer,
        'arrow-opacity': isCareer && currentIndex === 0,
        'arrow-left-mobile': !isCareer && $isMobile(),
      }"
      @click="handleArrowClick(-1)"
    />
    <div
      v-if="isCareer ? true : currentIndex < arrayOfIds.length - 1"
      class="arrow-right absolute top-1/2 arr-right"
      :class="{
        'arr-right-career': isCareer,
        'arrow-opacity': isCareer && currentIndex === arrayOfIds.length - 1,
        'arrow-right-mobile': !isCareer && $isMobile(),
      }"
      @click="handleArrowClick(1)"
    />
  </div>
</template>

<script lang="ts">
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface ComponentData {
  currentIndex: number
}

export default defineComponent({
  name: 'ArrowsComponent',
  props: {
    arrayOfIds: {
      type: Array as PropType<number[]>,
      required: true,
    },
    defaultId: {
      type: Number,
      default: 1,
    },
    isCareer: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      currentIndex: 0,
    }
  },
  watch: {
    $route(): void {
      this.setCurrentIndex()
    },
  },
  created(): void {
    this.setCurrentIndex()
  },
  methods: {
    ...mapActions(useDisciplineStore, {
      loadSpecificDiscipline: 'loadSpecificDiscipline',
    }),
    setCurrentIndex(): void {
      const routeId = this.isCareer
        ? this.$route.params.task
          ? Number(this.$route.params.task)
          : 1
        : Number(this.$route.params.id) || this.defaultId

      this.currentIndex = this.arrayOfIds.findIndex((id: number): boolean => id === routeId)
    },
    handleArrowClick(nextIdModifier: number): void {
      if (this.currentIndex >= this.arrayOfIds.length) return
      if (this.currentIndex < 0) return

      const nextId = this.arrayOfIds[this.currentIndex + nextIdModifier]

      if (this.isCareer) {
        this.$router.push({
          name: this.$getWebView('CareerDetail'),
          params: {
            id: this.$route.params?.id ?? null,
            task: nextId,
            careerId: this.$route.params?.careerId ?? null,
          },
        })
      } else {
        this.$router.push({
          name: this.$route.name,
          params: { id: nextId },
          query: this.$route.query.homepage
            ? {
                homepage: 'true',
              }
            : {},
        })
      }

      // temp fix for training
      if (this.$route.name === this.$getWebView('TrainingDisciplineDetail')) {
        this.loadSpecificDiscipline({ disciplineId: nextId })
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.component-arrows {
  &--lab {
    .arr-left {
      left: -5.5rem;
    }

    .arr-right {
      right: -6rem;
    }
  }
}
.arr-left {
  left: -6rem; //-left-24
}

.arr-right {
  right: -6rem; //-right-24
}

.arr-left-career {
  left: -5.3rem;
  top: 1.5rem;
}

.arr-right-career {
  right: -5.5rem;
  top: 1.5rem;
}

.arrow-opacity {
  opacity: 0.5;
  pointer-events: none;
}
</style>
