<template>
  <info-popup
    v-if="showPopup"
    width="85rem"
    height="46.25rem"
    :popup-title="$t('seasons.seasonalAttributes')"
    @close="$emit('close')"
  >
    <app-scrollbar
      class="seasonal-attributes-popup-scroll"
      width="85rem"
      height="40rem"
      scroll="y"
      slide="y"
    >
      <div class="seasons-seasonal-attributes-popup flex flex-col justify-start text-30 text-white">
        <div class="flex flex-col space-y-4">
          <dash-text
            v-for="value in 2"
            :key="value"
            :text="$t(`seasons.seasonalAttributesText${value}`)"
          />
        </div>
      </div>
      <div class="seasons-seasonal-attributes-popup-table">
        <seasons-table :titles="titles" :rows="seasonalAttributesRows" />
      </div>
    </app-scrollbar>
  </info-popup>
</template>

<script lang="ts">
import DashText from '@/components/DashText.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import SeasonsTable from '@/components/Seasons/SeasonsTable.vue'
import { useMainSeasonStore } from '@/store/pinia/seasons/mainSeasonsStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { SessionalAttribute, SeasonType, SeasonAttributesRow } from '@/interfaces/Seasons'

interface ComponentData {
  titles: string[]
}

export default defineComponent({
  name: 'SeasonsSeasonalAttributesPopup',
  components: {
    DashText,
    InfoPopup,
    SeasonsTable,
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      titles: ['common.discipline', 'common.basicAttribute', 'common.seasonalAttribute'],
    }
  },
  computed: {
    ...mapState(useMainSeasonStore, {
      seasonalAttributes: 'getSeasonalAttributes',
    }),
    seasonalAttributesRows(): SeasonAttributesRow[] {
      const rows = this.seasonalAttributes?.map((item: SessionalAttribute): SeasonAttributesRow => {
        return {
          disciplineId: item.disciplineId,
          value1: item.baseAttribute,
          value2: item.seasonalAttribute,
        }
      })

      return rows
    },
  },
  created(): void {
    this.loadSeasonalAttributesStats(this.$route.params.type as SeasonType)
  },
  methods: {
    ...mapActions(useMainSeasonStore, {
      loadSeasonalAttributesStats: 'loadSeasonalAttributesStats',
    }),
  },
})
</script>

<style lang="scss" scoped>
.seasons-seasonal-attributes-popup {
  padding: 2rem 2rem 0 2rem;

  &-table {
    width: 95%;
    margin: 0 auto;
  }
}
</style>
