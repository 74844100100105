<template>
  <div
    v-for="(item, i) in items"
    :key="i"
    class="equipment-items-box"
    :class="[
      {
        'is-tutorial': actualStage?.name === 'clickOnItemDetail',
        equipped: item[EQUIPMENT_EQUIPPED],
        damaged: item.itemDurabilityState === EQUIPMENT_BROKEN,
        premium: item.premium,
        'premium-equipped': item.premium && item[EQUIPMENT_EQUIPPED] && !item.expired,
        'premium-expired': item.premium && item.expired,
        'equipment-items-box--mobile': $isMobile(),
      },
    ]"
  >
    <arrow-animation
      position="left"
      border="box"
      :tutorial="!isLocked(item) && actualStage?.name === 'clickOnItemDetail'"
      add-class="flex"
    >
      <div
        ref="animation1a"
        class="w-full h-full"
        :class="{
          'spin-border spin-border--custom spin-border--animated': isFirstAvailableOffer(item),
        }"
      >
        <div
          :id="`item-box-${item.name}`"
          class="item-box relative"
          :class="[
            { 'pointer-events-none': isLocked(item) },
            isLocked(item) ? 'item-box-unlocked' : `item-box-${i}`,
            `item-box-type item-box-type-${item.itemStats?.level}`,
          ]"
          @click="openDetail(item)"
        >
          <app-notification
            v-if="getEquipmentNotifications(item) !== 0"
            :count="getEquipmentNotifications(item)"
          />
          <equipment-item-box :item-data="item" @finish-operation="$emit('finishOperation')" />
        </div>
      </div>
    </arrow-animation>
  </div>
</template>

<script lang="ts">
import ArrowAnimation from '@/components/ArrowAnimation.vue'
import {
  EQUIPMENT_BROKEN,
  EQUIPMENT_EQUIPPED,
  EQUIPMENT_FUNCTIONAL,
  OPERATION_DELIVERY,
  OPERATION_REPAIR,
  OPERATION_UPGRADE,
  pathImages,
} from '@/globalVariables'
// import ShortText from '@/components/ShortText.vue'
import EquipmentItemBox from '@/components/Equipment/EquipmentItemBox.vue'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import type { NormalizedItemData } from '@/interfaces/Equipment'
import type { NotificationEquipment } from '@/interfaces/global/Notification'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface ItemTypeText {
  text: string
}

interface ItemTypeClass {
  class: string
}

interface ComponentData {
  pathImages: typeof pathImages
  EQUIPMENT_EQUIPPED: typeof EQUIPMENT_EQUIPPED
  EQUIPMENT_BROKEN: typeof EQUIPMENT_BROKEN
  itemTypes: {
    [OPERATION_REPAIR]: ItemTypeText
    [OPERATION_UPGRADE]: ItemTypeText
    [OPERATION_DELIVERY]: ItemTypeText
    [EQUIPMENT_FUNCTIONAL]: ItemTypeClass
    [EQUIPMENT_BROKEN]: ItemTypeClass
  }
}

export default defineComponent({
  name: 'EquipmentItems',
  components: {
    ArrowAnimation,
    EquipmentItemBox,
  },
  props: {
    items: {
      type: Array as PropType<NormalizedItemData[]>,
      required: true,
    },
  },
  emits: ['finishOperation'],
  data(): ComponentData {
    return {
      pathImages,
      EQUIPMENT_EQUIPPED,
      EQUIPMENT_BROKEN,
      itemTypes: {
        [OPERATION_REPAIR]: {
          text: 'equipmentShop.repairing',
        },
        [OPERATION_UPGRADE]: {
          text: 'equipmentShop.upgrading',
        },
        [OPERATION_DELIVERY]: {
          text: 'equipmentShop.delivering',
        },
        [EQUIPMENT_FUNCTIONAL]: {
          class: 'text-texts-standard-important',
        },
        [EQUIPMENT_BROKEN]: {
          class: 'red-text',
        },
      },
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      actualStage: 'getActualStage',
    }),
    ...mapState(useResponseTaskStore, {
      notifications: 'getNotifications',
    }),
  },
  methods: {
    isLocked(item: NormalizedItemData): boolean {
      return item.itemStats?.level === '0'
    },

    openDetail(item: NormalizedItemData): void {
      this.$router.push({
        name: this.$getWebView(
          this.actualStage?.name === 'clickOnItemDetail'
            ? 'EquipmentDetailTutorial'
            : 'EquipmentDetail',
        ),
        params: { id: item.id },
        query: { offer: item.isOffer ? '1' : '0' },
      })
    },
    getEquipmentNotifications(item: NormalizedItemData): 0 | 1 {
      return this.notifications.equipment_notifications?.find(
        (equipment: NotificationEquipment): boolean => equipment.shop_items_id === item.id,
      )
        ? 1
        : 0
    },
    isFirstAvailableOffer(item: NormalizedItemData): boolean {
      return item.isOffer && item.isFirstOffer && !this.isLocked(item)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/stars-icons.scss';
@import '@/assets/styles/animations.scss';

.spin-border--custom {
  @if $isWsm {
    --bottom-left-color: #fadd1e;
    --top-right-color: #fadd1e;
  }

  @if $isSsm {
    --bottom-left-color: #feb942;
    --top-right-color: #feb942;
  }

  &:before,
  &:after {
    filter: blur(0.3rem);
  }
}

.equipment-items {
  &-box {
    display: flex;
    width: 56.5rem;
    height: 23.8rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url($path-equipment + 'box-backgrounds/new/bg-normal.avif');

    &--mobile {
      margin-right: 0;
      margin-bottom: 0;
    }

    &.equipped {
      background-image: url($path-equipment + 'box-backgrounds/new/bg-normal-equipped.avif');
    }

    &.damaged {
      background-image: url($path-equipment + 'box-backgrounds/new/bg-normal-damaged.avif');
    }

    &.premium {
      background-image: url($path-equipment + 'box-backgrounds/new/bg-premium.avif');
    }

    &.premium-equipped {
      background-image: url($path-equipment + 'box-backgrounds/new/bg-premium-equipped.avif');
    }

    &.premium-expired {
      background-image: url($path-equipment + 'box-backgrounds/new/bg-premium-expired.avif');
    }

    .notification-icon {
      top: -1rem;
      right: -1rem;
    }

    .arrow-wrapper:active {
      transform: scale(0.98);
    }
  }

  .border-danger {
    // border: 0.2rem solid #f43a1d;
    border-image-slice: 1;
  }

  .item-box {
    width: 100%;
    height: 100%;
    cursor: pointer;

    header {
      height: 4.5rem;
    }

    &-quality-flag {
      width: 9.6875rem;
      height: 2.5625rem;
      background-size: contain;
      right: 0;
      top: 5.5rem;
      padding-left: 1.1rem;
    }

    section {
      width: 100%;
      position: relative;

      .item-box-center {
        background-repeat: no-repeat;
        background-size: contain;
        width: 23.313rem;
        height: 14.438rem;
        margin: 0 auto;
        margin-top: 4.3125rem;

        &.repair {
          margin-top: 3.3125rem;
        }

        @for $discipline from 1 through 14 {
          @for $category from 1 through 6 {
            @for $level from 1 through 5 {
              &.item-#{$discipline}-#{$category}-#{$level} {
                background-image: url($path-equipment + 'items/list/'+#{$discipline}+'/'+#{$category}+'-'+#{$level}+'.avif');
              }
            }
          }
        }
      }

      .item-box-center-info {
        width: 100%;
        height: 4.375rem;
        top: 50%;
        transform: translate(0, -50%);

        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 0.125rem;
          background: linear-gradient(90deg, rgba(98, 154, 197, 0), #629ac5, rgba(98, 154, 197, 0));
          left: 0;
        }

        &:before {
          top: 0;
        }

        &:after {
          bottom: 0;
        }
      }

      .blue-bg {
        background: linear-gradient(to left, #7dbff500, #3396e2c2, #3396e2c2, #7dbff500);
      }

      .gold-bg {
        background: linear-gradient(to left, #d2942000, #fccb61bb, #fccb61bb, #d2942000);
      }
    }

    .middle-text {
      margin-top: -2rem;
      width: 26rem;
    }

    footer {
      height: 12.1875rem;

      .eq-item-row {
        width: 90%;
      }

      .timer {
        width: 17.563rem;
      }

      .need-repair {
        width: 100%;
        height: 4.375rem;
        background: linear-gradient(to left, #ff2f2fc4, #f43a1d, #f43a1d, #ff2f2fc4);
        bottom: 5rem;

        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 0.125rem;
          background: linear-gradient(90deg, rgba(98, 154, 197, 0), #f43a1d, rgba(98, 154, 197, 0));
          left: 0;
        }

        &:before {
          top: 0;
        }

        &:after {
          bottom: 0;
        }
      }
    }
  }

  .red-text {
    color: theme('colors.texts.standard.danger');
  }

  .equipment-items-scroll {
    width: max-content;
  }
}

.arrow-wrapper {
  left: 0rem;
  top: 0rem;
  width: 100%;

  &:active {
    transform: unset;
  }

  .item-box {
    margin: unset;
  }
}

#item-box-storm {
  * {
    pointer-events: none;
  }
}
</style>
