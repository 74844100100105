<template>
  <section class="routing-section">
    <routing-component
      v-for="(routeData, i) in routes"
      :key="i"
      :indice="i"
      :route-data="routeData"
      :style="{
        zIndex: routes.length - i,
      }"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import RoutingComponent from './RoutingComponent.vue'
import type { EventRoute, EventView, EventViews } from '@/interfaces/events/EventViews'

export default defineComponent({
  name: 'RoutingSection',
  components: {
    RoutingComponent,
  },
  props: {
    views: {
      type: [Array, Object] as PropType<EventViews>,
      required: true,
    },
  },
  computed: {
    routes(): EventRoute[] {
      return Object.keys(this.views)
        .filter((view: EventView): boolean => !view.endsWith('Web'))
        .map((routeName: EventView): EventRoute => ({ routeName }))
    },
  },
})
</script>

<style lang="scss" scoped>
section.routing-section {
  display: flex;
}
</style>
