<template>
  <div class="flash-news-message-wrapper">
    <div class="flash-news-message">
      <header
        class="flash-news-message-header font-bold flex flex-col items-center justify-end"
      ></header>
      <main class="flash-news-message-main flex flex-col items-center">
        <section class="flash-news-message-main-name">
          <p class="text-texts-standard-default text-40 font-bold uppercase">
            {{ $t('common.extraAnnouncement') }}
          </p>
        </section>
        <div class="flexing flex-col flex-grow space-y-6">
          <p class="text-texts-standard-default text-36">
            {{ getMessage.text }}
          </p>
          <div v-if="getMessage.reward" class="flexing">
            <reward-box
              :reward-icon="56"
              :reward-icon-name="getRewardIconNameWithoutBonuxPostfix(getMessage.reward)"
              :reward-number="getMessage.reward.value"
              :reward-icon-rarity="getMessage.reward.rarity ?? ''"
            />
          </div>
        </div>
      </main>
      <footer class="flash-news-message-footer flexing">
        <app-button
          v-if="getMessage.reward"
          btn-id="flash-news-message-footer-btn"
          btn-size="md"
          btn-type="confirm"
          :btn-text="$t('common.claim')"
          :loading="isLoading"
          :disabled="isLoading"
          @click="claim"
        />
        <app-button
          v-else
          btn-id="flash-news-message-footer-btn"
          btn-size="md"
          btn-type="secondary"
          :btn-text="$t('button.continue')"
          @click="hide"
        />
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import { getRewardIconNameWithoutBonuxPostfix } from '@/helpers'
import { useFlashNewsStore } from '@/store/pinia/flashNews'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  isLoading: boolean
}

export default defineComponent({
  name: 'FlashNewsMessage',
  data(): ComponentData {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapState(useFlashNewsStore, ['getMessage']),
  },
  methods: {
    ...mapActions(useFlashNewsStore, ['claimFlashNewsMessageReward', 'hideFlashNewsMessage']),
    getRewardIconNameWithoutBonuxPostfix,
    async claim(): Promise<void> {
      try {
        this.isLoading = true
        await this.claimFlashNewsMessageReward(this.getMessage.id)
      } catch (error: unknown) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    hide(): void {
      this.hideFlashNewsMessage(this.getMessage.id)
    },
  },
})
</script>

<style lang="scss" scoped>
.flash-news-message {
  width: 100%;
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &-wrapper {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(0.15rem);
    z-index: 9;
  }

  &-header {
    width: 70%;
    height: 20rem;
    margin: 0 auto;
    background: url($path-premium + 'afterPayment/BG_EFFECT_4.avif') center bottom no-repeat;
    background-size: cover;
  }

  &-main {
    width: 100%;
    height: 20rem;
    box-shadow: 0px 0.313rem 0.813rem 0 #272727;
    background: linear-gradient(
      to right,
      transparent,
      #154e79 10%,
      #0f2a4e 40%,
      #0b1e37 50%,
      #0f2a4e 60%,
      #154e79 90%,
      transparent
    );
    background: url($path-premium + 'afterPayment/TABLE_BG.avif') center no-repeat;
    background-size: cover;

    &-name {
      width: 100%;
      height: 5rem;
      background: url($path-premium + 'afterPayment/BG_TOP_CONTENT.avif') center no-repeat;
      background-size: cover;
      line-height: 5rem;
      margin-bottom: 0;
    }
  }

  footer {
    width: 100%;
    height: 15rem;
  }
}
</style>
