import { tournamentsCalendarStateEndpoint as TOURNAMENTS_CALENDAR_STATE_ENDPOINT } from '@/globalVariables'
import { extractMetaFromResponse } from '@/helpers'
import type { TournamentMeta } from '@/interfaces/Tournaments'
import type { TournamentsCalendarStateApiResponse } from '@/interfaces/responses/tournaments/TournamentsCalendarStateApiResponse'
import { ApiService } from '@/services/ApiService'
import { defineStore } from 'pinia'

interface TournamentsCalendarStore {
  state: TournamentsCalendarStateApiResponse | null
}

export const useTournamentsCalendarStore = defineStore('tournamentsCalendarStore', {
  state: (): TournamentsCalendarStore => ({
    state: null,
  }),
  getters: {
    meta(): TournamentMeta {
      return extractMetaFromResponse(this.state)
    },
    tournaments(): TournamentsCalendarStateApiResponse['tournaments'] | null {
      return this.state?.tournaments ?? null
    },
  },
  actions: {
    async loadState(force: boolean = false): Promise<void> {
      if (this.state && !force) return
      try {
        this.state = await ApiService.get<TournamentsCalendarStateApiResponse>(
          TOURNAMENTS_CALENDAR_STATE_ENDPOINT,
          { force: true },
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
