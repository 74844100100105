import { GAME_MAINTENANCE, GAME_UPDATE, LOGIN_VIEW_ROUTE_NAME } from '@/globalVariables'
import { getValidationToken } from '@/helpers/index.js'
import { useGameStateStore } from '@/store/pinia/gameStateStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import type { RouteLocationRaw } from 'vue-router'
const REDIRECT_ROUTE = { name: LOGIN_VIEW_ROUTE_NAME }

export const authRouteGuard = (): boolean | RouteLocationRaw => {
  // getValidationToken function checks for a valid access token in local storage, indicating that we are logged in
  if (getValidationToken('token') && !useResponseTaskStore().isUserBlocked) {
    return ![GAME_MAINTENANCE, GAME_UPDATE].includes(useGameStateStore().getGameState)
  }

  // when not logged in, it redirects to the login window
  // requiring the 'requiresAuth: true' to be set in the route meta for specific route
  return REDIRECT_ROUTE
}
