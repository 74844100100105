<template>
  <div
    :class="[
      'flex items-center justify-end text-34 text-texts-standard-default plate-value plate-value-' +
        color,
    ]"
  >
    <p class="revert-skew" v-html="textValue" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    textValue: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'green',
      validator(value: string): boolean {
        return ['green', 'red', 'darkBlue', 'puprle', 'purple', 'orange'].includes(value)
      },
    },
  },
})
</script>

<style lang="scss" scoped>
.plate-value {
  height: 3.625rem;
  width: 17.5625rem;
  transform: $skewX-value;
  text-transform: uppercase;
  padding-right: 1rem;

  &:before {
    content: '';
    width: 0.313rem;
    height: 100%;
    position: absolute;
    right: -0.45rem;
  }

  &-red {
    @if $isSsm {
      background: linear-gradient(
        to left,
        #c31818,
        rgba(195, 24, 24, 0.65),
        rgba(195, 24, 24, 0.65),
        transparent
      );
    }

    @if $isWsm {
      background: linear-gradient(to left, #9f1d22, transparent);
    }

    &:before {
      @if $isSsm {
        background: #ff1717;
      }

      @if $isWsm {
        background: #ff0900;
      }
    }
  }

  &-green {
    background: linear-gradient(to left, #39be14, transparent);

    &:before {
      background: #40cc32;
    }
  }

  &-darkBlue {
    @if $isSsm {
      background-image: linear-gradient(
        to left,
        rgba(35, 71, 156, 0.8),
        rgba(35, 71, 156, 0.65),
        transparent
      );
    }

    @if $isWsm {
      background: linear-gradient(to left, #0048ffad, transparent);
    }

    &:before {
      background: #23479c;
    }
  }

  &-purple {
    background: linear-gradient(to left, #6747ab, transparent);

    &:before {
      background: #9032d3;
    }
  }

  &-orange {
    background: linear-gradient(
      to left,
      rgba(255, 169, 21, 0.65),
      rgba(255, 169, 21, 0.65),
      transparent
    );

    &:before {
      background: #ffa915;
    }
  }
}
</style>
