<template>
  <div class="wrapper">
    <div
      v-if="dataLoaded"
      id="profile-left-box"
      class="profile-left-box bordered-md"
      :style="{ backgroundImage: userCountryStyle }"
    >
      <div class="profile-left-box-image" />
      <main class="absolute z-1 w-full">
        <div class="profile-left-box-badge flexing" :class="'badge-' + badge.image">
          <h2
            class="text-texts-standard-dark text-36 font-bold uppercase"
            :class="
              badge.image === 'legend' ? 'text-texts-standard-default' : 'text-texts-standard-dark'
            "
          >
            {{ $t(`profile.${badge.rankName}`) }}
          </h2>
        </div>
        <div class="profile-left-box-name__wrapper">
          <div class="profile-left-box-name">
            <p
              class="player-country-code text-texts-standard-additional text-22 uppercase font-bold"
            >
              {{ country }}
            </p>
            <div
              class="profile-left-box-flag mt-0.5"
              :style="{
                backgroundImage: 'url(' + pathImages + 'flags/102x68/' + country + '.avif)',
              }"
            />
            <div class="relative leading-8">
              <short-text
                class="player-name text-texts-standard-default text-32 leading-8 font-bold"
                :text="username"
                :first-cut="18"
                :second-cut="30"
              />
              <event-badge
                :current-badges="itsNotMe ? otherUserData?.event_badges : myEventBadges"
                class="profile-left-box__event-badge"
              />
            </div>
          </div>
        </div>
        <div class="white-strip profile-strip-margin" />
        <div class="club-info__wrapper">
          <div
            v-if="(!itsNotMe && hasClub) || otherUserData.user_data?.club_stats != null"
            class="profile-left-box-club flexing cursor-pointer"
            @click="goToClubProfile"
          >
            <div class="profile-left-box-club-logo">
              <clubs-avatar
                :background-id="otherClubsData ? otherClubsData.logo_background_id : clubLogoBg"
                :logo-id="otherClubsData ? otherClubsData.logo_id : clubLogo"
              />
            </div>
            <short-text
              class="player-name text-texts-standard-default text-30"
              :text="otherClubsData ? otherClubsData.name : userData?.club_stats?.name"
              :first-cut="20"
              :second-cut="30"
            />
          </div>
          <div v-else class="player-name text-texts-standard-default text-30 uppercase">
            {{ $t('profile.withoutClub') }}
          </div>
        </div>
        <div class="profile-left-box-avatar">
          <div class="avatar-circle">
            <avatar-box
              class="avatar-circle-person"
              :avatar-id="avatar"
              :avatar-gender="sex"
              :background-id="avatarBg"
            />
            <player-level
              class="profile-player-level-box"
              :other-user-stats="itsNotMe ? otherUserData?.user_data?.stats : null"
            />
          </div>
        </div>
        <app-hamburger-icon
          v-if="isAdmin"
          icon-name="admin"
          class="absolute right-3 admin-icon-margin"
        />
        <div class="profile-left-box-status">
          <div class="profile-left-box-status-title">
            <p class="text-texts-standard-default text-28">
              {{ $t('profile.rankingPointsTotal') }}
            </p>
          </div>
          <div class="profile-left-box-status-count flexing">
            <app-main-icon :icon-size="48" :icon-name="'ranking_points'" />
            <p class="text-texts-standard-important text-50 uppercase font-bold">
              {{ $filters.$formatNumber(Number(rankingPoints)) }}
            </p>
            <tippy theme="WSM1" placement="right" class="absolute right-8" max-width="24.75rem">
              <app-icon icon-name="info-50" />
              <template #content>
                <profile-tooltip :rows="tooltipArray" :title="$t('profile.overallRanking')" />
              </template>
            </tippy>
          </div>
        </div>
        <div
          class="profile-left-box-btn flexing"
          :class="{
            'not-me': itsNotMe,
            'not-me--double-btn': itsNotMe && hasClub && isClubManagerOrAssistant,
          }"
        >
          <app-button
            v-if="!itsNotMe"
            btn-type="primary"
            :btn-text="$t('profile.appearance')"
            btn-size="md"
            @click="openAppearance()"
          />
          <app-button
            v-if="itsNotMe && hasClub && isClubManagerOrAssistant"
            v-tippy="{
              theme: 'WSM',
              content: showButtonTooltipContent,
              placement: 'top',
            }"
            btn-type="secondary"
            btn-size="xsm"
            add-class="not-me-btn"
            :disabled="isButtonDisabled || isManuallyDisabled"
            @click="inviteToClub()"
          >
            <div class="flexing">
              <app-icon icon-name="invite-to-club" />
            </div>
          </app-button>
          <app-button
            v-if="itsNotMe"
            btn-type="confirm"
            :btn-size="hasClub && !isClubManagerOrAssistant ? 'md' : 'xsm'"
            @click="sendMessage()"
          >
            <div class="not-me-btn">
              <app-hamburger-icon icon-name="post" class="not-me-btn__icon" />
              <div v-if="hasClub && !isClubManagerOrAssistant" class="not-me-btn__text flexing">
                <shrink-text
                  :text="$t('profile.sendMessage')"
                  :font-size="36"
                  :text-class-list="{ 'font-bold': $isWsm, 'font-medium': $isSsm }"
                  :animated-overflow="true"
                />
              </div>
            </div>
          </app-button>
        </div>
      </main>
    </div>
    <component-loading
      class="component-loading bordered-1"
      type="secondary"
      width="100%"
      :is-loading="!dataLoaded"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { internalAxios } from '@/plugins/vueAxios'
import { convertNameValue } from '@/helpers/arrayNameValueConverter'
import ClubsAvatar from '@/components/Club/ClubsAvatar.vue'
import AppHamburgerIcon from '@/components/GlobalComponents/AppHamburgerIcon.vue'
import EventBadge from '@/components/GlobalComponents/EventBadge.vue'
import AvatarBox from '@/components/Profile/AvatarBox.vue'
import PlayerLevel from '@/components/Profile/PlayerLevel.vue'
import ProfileTooltip from '@/components/Profile/ProfileTooltip.vue'
import ShortText from '@/components/ShortText.vue'
import { ADMIN, SUPER_ADMIN, pathImages, userProfileUserEndpoint } from '@/globalVariables'
import { useInvitationStore } from '@/store/pinia/clubs/invitationsStore'
import { useCountriesStore } from '@/store/pinia/countriesStore'
import { useRankingStore } from '@/store/pinia/rankingStore'
import { useUserStore } from '@/store/pinia/userStore'
import ShrinkText from '@/components/ShrinkText.vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type {
  UserProfile,
  UserProfileEndpointInterface,
  ClubStats,
  UserAttributes,
} from '@/interfaces/User'
import type { UserBadge } from '@/types/userProfile'

interface ComponentData {
  pathImages: typeof pathImages
  otherUserData: UserProfile
  isManuallyDisabled: boolean
}

export default defineComponent({
  name: 'ProfileLeftBox',
  components: {
    ShortText,
    AvatarBox,
    PlayerLevel,
    ProfileTooltip,
    AppHamburgerIcon,
    ClubsAvatar,
    EventBadge,
    ShrinkText,
  },
  props: {
    badge: {
      type: Object as PropType<Nullable<UserBadge>>,
      default: () => null,
    },
    userId: {
      type: String,
      default: '',
    },
  },
  data(): ComponentData {
    return {
      pathImages,
      otherUserData: {} as UserProfile,
      isManuallyDisabled: false,
    }
  },
  computed: {
    ...mapState(useCountriesStore, {
      selectedCountries: 'getSelectedCountries',
    }),
    ...mapState(useUserStore, {
      myCountry: 'getCountry',
      myRankingPoints: 'getRankingPoints',
      userData: 'getUserData',
      myAvatar: 'getAvatar',
      myAvatarBg: 'getAvatarBg',
      mySex: 'getSex',
      myAttributes: 'getAttributes',
      isAdmin: 'isAdmin',
      hasClub: 'getHasClub',
      clubLogo: 'getClubLogo',
      clubLogoBg: 'getClubLogoBg',
      isClubManagerOrAssistant: 'isClubManagerOrAssistant',
      myEventBadges: 'getEventBadges',
    }),
    ...mapState(useRankingStore, {
      myGlobalRankPosition: 'getGlobalPosition',
      myNationalRankPosition: 'getNationalPosition',
      globalRankings: 'getGlobalRankings',
      nationalRankings: 'getNationalRankings',
    }),
    itsNotMe(): boolean {
      return this.userData?.id !== this.userId
    },
    dataLoaded(): boolean {
      return (
        ((this.itsNotMe && Object.keys(this.otherUserData).length) ||
          (!this.itsNotMe && Object.keys(this.userData).length)) &&
        !!this.badge
      )
    },
    username(): string {
      return this.itsNotMe ? this.otherUserData?.user_data?.username : this.userData.username
    },
    country(): string {
      return this.itsNotMe
        ? this.otherUserData?.user_data?.country?.three_letter_code
        : this.myCountry
    },
    rankingPoints(): string {
      return this.itsNotMe
        ? this.otherUserData?.user_data?.stats?.RANKING_POINTS
        : this.myRankingPoints
    },
    avatar(): string | number {
      return this.itsNotMe ? this.otherUserData?.attributes?.avatar : this.myAvatar
    },
    avatarBg(): string | number {
      return this.itsNotMe ? this.otherUserData?.attributes?.avatar_background : this.myAvatarBg
    },
    sex(): string {
      return this.itsNotMe ? this.otherUserData?.attributes?.sex : this.mySex
    },
    attributes(): UserAttributes {
      return this.itsNotMe ? this.otherUserData?.attributes : this.myAttributes
    },
    globalRankPosition(): number {
      if (!this.itsNotMe) {
        return this.myGlobalRankPosition
      } else {
        return this.globalRankings?.ranking && this.globalRankings.ranking[this.userId]
          ? this.globalRankings.ranking[this.userId].rank
          : 0
      }
    },
    nationalRankPosition(): number {
      if (!this.itsNotMe) {
        return this.myNationalRankPosition
      } else {
        return this.nationalRankings?.ranking && this.nationalRankings.ranking[this.userId]
          ? this.nationalRankings.ranking[this.userId].rank
          : 0
      }
    },
    isAdmin(): boolean {
      return [SUPER_ADMIN, ADMIN].includes(
        this.itsNotMe ? this.otherUserData?.user_data?.role : this.userData?.role,
      )
    },
    userCountryStyle(): string {
      const country = this.country.toUpperCase()
      if (this.selectedCountries.includes(this.country)) {
        return 'url(' + pathImages + 'profile/flags/BG-BOX_FLAG_' + country + '.avif'
      }
      return ''
    },
    tooltipArray(): { text: string; value: number }[] {
      return [
        {
          text: this.$t('common.worldRanking'),
          value: this.globalRankPosition,
        },
        {
          text: this.$t('rankingsGroup.nationalRanking'),
          value: this.nationalRankPosition,
        },
      ]
    },
    // TODO: import proper club stats intertface when it will be created
    otherClubsData(): ClubStats {
      return this.otherUserData?.user_data?.club_stats ?? null
    },
    isButtonDisabled(): boolean {
      return (
        !!this.otherClubsData ||
        !this.isClubManagerOrAssistant ||
        this.otherUserData.user_data.invite_button_tooltip !== null
      )
    },
    showButtonTooltipContent(): string {
      const tooltipText = this.otherUserData.user_data.invite_button_tooltip

      if (tooltipText !== null) {
        return this.$t(`profile.${tooltipText}`)
      }

      return this.$t('profile.inviteToClub')
    },
  },
  watch: {
    async userId(): Promise<void> {
      await this.loadUserData()
    },
  },
  async created(): Promise<void> {
    if (this.itsNotMe) {
      await this.loadUserData()
    }
    this.loadNationalRankings(this.country)
    this.loadGlobalRankings()
  },
  methods: {
    ...mapActions(useRankingStore, {
      loadGlobalRankings: 'loadGlobalRankings',
      loadNationalRankings: 'loadNationalRankings',
    }),
    ...mapActions(useInvitationStore, {
      inviteToClubAction: 'inviteToClub',
    }),
    openAppearance(): void {
      this.$router.push({ name: this.$getWebView('ProfileAvatars') })
    },
    async loadUserData(): Promise<void> {
      if (!this.userId) return

      const response = await internalAxios.post<
        {},
        UserProfileEndpointInterface,
        { user_games_ids: string[] }
      >(userProfileUserEndpoint, {
        user_games_ids: [this.userId],
      })

      const user = { ...response[0] }
      if (!user) return
      user.attributes = convertNameValue(user.attributes)
      this.otherUserData = user
    },
    sendMessage(): void {
      this.$router.push({
        name: this.$getWebView('MailNew'),
        params: { recipient: this.username },
      })
    },
    inviteToClub(): void {
      // TODO zistit ci je user v klube a dovolit odoslat pozvanku iba ked nie je v ziadnom
      this.inviteToClubAction(this.userId)
      this.isManuallyDisabled = true
    },
    goToClubProfile(): void {
      this.$router.push({
        name: this.$getWebView('ClubsProfile'),
        params: { clubProfileId: this.otherClubsData?.id },
        query: { homepage: 'true' },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
$not-me-btn-gap: 0.875rem;
$not-me-btn-icon-size: 4.2rem;

.wrapper {
  position: relative;
  width: 32.5rem;
  height: 52.375rem;
  flex-shrink: 0;

  &.profile-left-box__wrapper--mobile {
    .profile-left-box {
      border: solid 0.125rem #5ba3dc;
    }
  }

  .profile-left-box {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @if $isWsm {
      border: 0.063rem solid #5ba3dc;
      background-color: #1b2f47;
    }

    @if $isSsm {
      border: 0.063rem solid #586b9d;
    }

    .profile-left-box-image {
      background: url($path-profile + 'BG_FLAG_BOX.avif') center no-repeat;
      width: 100%;
      height: 32.375rem;
      position: absolute;
      background-size: cover;
    }

    .profile-left-box-badge {
      background: url($path-profile + 'badges/BADGE_AMATER.avif') center no-repeat;
      background-size: cover;
      width: 27.125rem;
      height: 4.5rem;
      margin: 0.938rem auto 1.313rem;

      $badges: (
        'novice',
        'beginner',
        'amateur',
        'professional',
        'expert',
        'champion',
        'elite',
        'star',
        'super-star',
        'legend'
      );

      @each $img in $badges {
        &.badge-#{$img} {
          background-image: url($path-profile + 'badges/badge-#{$img}.avif');
        }
      }
    }

    .profile-left-box-name {
      display: flex;
      align-items: baseline;
      justify-content: center;

      &__wrapper {
        display: flex;
        justify-content: center;
        height: 2.5rem;
        align-items: center;
      }
      .profile-left-box-flag {
        position: relative;
        top: 0.125rem;
        width: 3.063rem;
        height: 2.063rem;
        margin: 0 0.938rem;
        background-size: cover;
      }
    }

    .club-info {
      &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.625rem;
      }
    }

    .profile-strip-margin {
      margin: 0.938rem auto;
    }

    .profile-left-box-club {
      &-logo {
        width: 3.188rem;
        height: 3.625rem;
        margin: 0 1rem;
      }
    }

    .profile-left-box-avatar {
      margin: 2.763rem auto 4.188rem;

      .avatar-circle {
        position: relative;
        width: 14.375rem;
        height: 14.375rem;
        border-radius: 50%;
        margin: auto;

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: url($path-images + 'components/profileAvatar/profile-avatar-circle1.avif')
            center no-repeat;
          background-size: contain;
          z-index: 1;
        }

        &-person {
          width: 100%;
          height: 100%;
          margin: 0;
          z-index: 0;
        }

        .profile-player-level-box {
          position: absolute;
          right: -1.5rem;
          bottom: -1rem;
          z-index: 1;
        }

        .profile-player-vip-level-box {
          position: absolute;
          left: -1rem;
          top: -1.5rem;
        }
      }
    }

    .admin-icon-margin {
      margin-top: -5rem;
    }

    .profile-left-box-status {
      position: relative;
      width: 100%;
      height: 7.625rem;
      @if $isWsm {
        box-shadow: inset 0.75rem 0 2.688rem 0 rgba(27, 47, 71, 0.39);
        background-image: linear-gradient(
          to right,
          transparent,
          rgba(21, 78, 121, 0.8),
          transparent
        );
      }
      @if $isSsm {
        background-image: linear-gradient(
          to right,
          transparent,
          rgba(39, 50, 73, 0.85),
          rgba(52, 65, 93, 1),
          rgba(39, 50, 73, 0.85),
          transparent
        );
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        height: 0.125rem;
        @if $isWsm {
          background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.5), transparent);
        }
        @if $isSsm {
          background: linear-gradient(to right, transparent, rgba(#586b9d, 0.5), transparent);
        }
      }

      &:before {
        top: 0rem;
      }

      &:after {
        bottom: 0rem;
      }

      &-title {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 2.875rem;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0rem;
          height: 0.125rem;
          background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.5), transparent);
        }
      }

      &-count {
        height: 4.75rem;
        position: relative;
      }
    }

    &-btn {
      margin-top: 2.5rem;

      &.not-me {
        justify-content: space-evenly;

        &--double-btn {
          gap: 1rem;
          padding: 0 2.75rem;
        }

        .not-me-btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          max-width: 100%;
          gap: $not-me-btn-gap;

          &__icon {
            flex: 0 0 auto;
          }

          &__text {
            flex: 1 0 auto;
            max-width: calc(100% - #{$not-me-btn-icon-size} - #{$not-me-btn-gap});
          }
        }
      }

      &-max {
        max-width: 15rem;
        margin-left: 1rem;
      }
    }

    &__event-badge {
      position: absolute;
      right: -3.25rem;
      top: -0.5rem;
    }
  }

  .component-loading {
    margin-left: 2rem;
  }
}
</style>
