<template>
  <div class="lab-cell absolute">
    <lab-connection-line
      v-if="cellData.parent || (!cellData.parent && cellData.positionLevel === 0)"
      :direction="LineDirection.Top"
      :top-height="cellData.lineSize"
      :line-style="
        cellData.maxLevel === cellData.currentLevel ? LineStyle.Special : LineStyle.Normal
      "
    />
    <lab-icons
      class="cursor-pointer"
      :icon-data="cellData"
      :has-ongoing-research-tree="hasOngoingResearchTree"
      @click="$emit('selectSkill', cellData.id)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LabIcons from '@/components/Lab/LabIcons.vue'
import LabConnectionLine from '@/components/Lab/LabConnectionLine.vue'
import type { PropType } from 'vue'
import type { Cell } from '@/interfaces/lab/Cell'
import { LineDirection, LineStyle } from '@/interfaces/lab/Cell'

interface ComponentData {
  LineDirection: typeof LineDirection
  LineStyle: typeof LineStyle
}

export default defineComponent({
  name: 'LabCampsColumn',
  components: {
    LabIcons,
    LabConnectionLine,
  },
  props: {
    cellData: {
      type: Object as PropType<Cell>,
      required: true,
    },
    hasOngoingResearchTree: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectSkill'],
  data(): ComponentData {
    return {
      LineDirection,
      LineStyle,
    }
  },
})
</script>

<style lang="scss" scoped>
.lab-cell:last-child::after {
  // Important: Offset required for used absolute positioning, visible from below behind the last child
  content: '';
  position: relative;
  display: block;
  height: 3rem;
}
</style>
