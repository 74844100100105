<template>
  <header class="milestones-progress flex flex-row items-center justify-between">
    <p class="gradient-text-light gradient-text-last-letter-cut-helper text-60 font-bold uppercase">
      {{ $t('tournaments.tournamentMilestones') }}
    </p>
    <milestones-progress-bar />
  </header>
</template>

<script lang="ts">
import MilestonesProgressBar from './MilestonesProgressBar.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { MilestonesProgressBar },
})
</script>

<style lang="scss" scoped>
.milestones-progress {
  height: 7.5rem;
  border-style: solid;
  border-width: 0.188rem;
  border-image-slice: 1;
  padding: 0 1.875rem;
  @if $isSsm {
    border-image-source: linear-gradient(
      to right,
      transparent 0%,
      rgba(162, 209, 255, 0.4) 10%,
      rgba(162, 209, 255, 0.8) 20%,
      rgba(162, 209, 255, 0.1) 35%,
      rgba(162, 209, 255, 0.4) 70%,
      rgba(162, 209, 255, 0.7) 80%,
      rgba(162, 209, 255, 0.2) 90%,
      transparent 100%
    );
    background: linear-gradient(
      to right,

      transparent 0%,
      rgba($color: #525479, $alpha: 0.4) 10%,
      rgba($color: #525479, $alpha: 0.8) 20%,
      rgba($color: #525479, $alpha: 0.1) 35%,
      rgba($color: #525479, $alpha: 0.4) 70%,
      rgba($color: #525479, $alpha: 0.7) 80%,
      rgba($color: #525479, $alpha: 0.2) 90%,
      transparent 100%
    );
  }
  @if $isWsm {
    border-image-source: linear-gradient(
      to right,
      transparent 0%,
      rgba(81, 114, 179, 0.4) 10%,
      rgba(81, 114, 179, 0.8) 20%,
      rgba(81, 114, 179, 0.1) 35%,
      rgba(81, 114, 179, 0.4) 70%,
      rgba(81, 114, 179, 0.7) 80%,
      rgba(81, 114, 179, 0.2) 90%,
      transparent 100%
    );
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(36, 88, 142, 0.4) 10%,
      rgba(36, 88, 142, 0.8) 20%,
      rgba(36, 88, 142, 0.1) 35%,
      rgba(36, 88, 142, 0.4) 70%,
      rgba(36, 88, 142, 0.7) 80%,
      rgba(36, 88, 142, 0.2) 90%,
      transparent 100%
    );
  }
}
</style>
