import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { loadAtlasData, loadImageData, loadSpriteData } from './specificAssetTypeLoaders'
import {
  GAMES_KEYS,
  LOAD_CLUBS_DATA,
  LOAD_EVENT_ASSETS,
  MECHANIC_CLUB,
  MECHANIC_DISCIPLINE_EVENT,
  MECHANIC_EVENT,
} from '@/map-phaser-new/constants'
import type {
  AtlasDataInterface,
  ImageLoadData,
  SpriteDataInterface,
} from '@/map-phaser-new/interfaces'
import { CoreHandler } from '../../abstractClasses/coreHandler'
import { EventType } from '@/interfaces/events/EventInfo'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'

import * as WsmMainAtlasExporter from '../../../config/winter-sports/mechanics/main/atlas/exporter'
import * as SsmMainAtlasExporter from '../../../config/summer-sports/mechanics/main/atlas/exporter'

import * as WsmMainImageExporter from '../../../config/winter-sports/mechanics/main/image/exporter'
import * as SsmMainImageExporter from '../../../config/summer-sports/mechanics/main/image/exporter'

import * as WsmSpriteExporter from '../../../config/winter-sports/mechanics/main/sprite/exporter'
import * as SsmSpriteExporter from '../../../config/summer-sports/mechanics/main/sprite/exporter'

import * as WsmClubsAtlasExporterer from '../../../config/winter-sports/mechanics/clubs/atlas/exporter'
import * as SsmClubsAtlasExporterer from '../../../config/summer-sports/mechanics/clubs/atlas/exporter'

import * as WsmClubsImageExporter from '../../../config/winter-sports/mechanics/clubs/image/exporter'
import * as SsmClubsImageExporter from '../../../config/summer-sports/mechanics/clubs/image/exporter'

import * as WsmEventsAtlasExproter from '../../../config/winter-sports/mechanics/events/atlas/exporter'
import * as SsmEventsAtlasExproter from '../../../config/summer-sports/mechanics/events/atlas/exporter'

import * as WsmEventsImageExporter from '../../../config/winter-sports/mechanics/events/image/exporter'
import * as SsmEventsImageExporter from '../../../config/summer-sports/mechanics/events/image/exporter'

import * as WsmTutorialImageExporter from '../../../config/winter-sports/mechanics/tutorial/image/exporter'
import * as SsmTutorialImageExporter from '../../../config/summer-sports/mechanics/tutorial/image/exporter'
import { currentGame } from '@/globalVariables'

const exporters = {
  [GAMES_KEYS.WinterSports]: {
    mainAtlas: WsmMainAtlasExporter,
    mainImage: WsmMainImageExporter,
    mainSprite: WsmSpriteExporter,
    clubsAtlas: WsmClubsAtlasExporterer,
    clubsImage: WsmClubsImageExporter,
    eventsAtlas: WsmEventsAtlasExproter,
    eventsImage: WsmEventsImageExporter,
    tutorialImage: WsmTutorialImageExporter,
  },
  [GAMES_KEYS.SummerSports]: {
    mainAtlas: SsmMainAtlasExporter,
    mainImage: SsmMainImageExporter,
    mainSprite: SsmSpriteExporter,
    clubsAtlas: SsmClubsAtlasExporterer,
    clubsImage: SsmClubsImageExporter,
    eventsAtlas: SsmEventsAtlasExproter,
    eventsImage: SsmEventsImageExporter,
    tutorialImage: SsmTutorialImageExporter,
  },
}

class AssetsHandler extends CoreHandler {
  protected game: Phaser.Game
  protected eventMechanicsLoaded: Record<string, Date> = {}

  public setUp(game: Phaser.Game, emitter: Phaser.Events.EventEmitter) {
    this.game = game
    this.registerEmittersActions(emitter)
  }

  private registerEmittersActions(emitter: Phaser.Events.EventEmitter): void {
    emitter.on(
      LOAD_CLUBS_DATA,
      (): void => {
        this.loadClubsData().catch((error: Error): void => console.error(error.toString()))
      },
      this,
    )

    emitter.on(
      LOAD_EVENT_ASSETS,
      (): void => {
        this.loadEventsData().catch((error: Error): void => console.error(error.toString()))
      },
      this,
    )
  }

  /**
   * This method load common assets, assets based on available mechanics, fonts
   */
  public async runLoaders(): Promise<void> {
    try {
      // loadEventsData - parameter createEventBuilding is set to false at init, that's because
      // event building will be created in buildingsHandler - at initialization - it's creation is
      // here only for case when event is unlocked during gaming session
      await Promise.all([
        this.loadCommonData(),
        this.loadClubsData(),
        this.loadTutorialData(),
        this.loadEventsData(false),
      ])
    } catch (error: unknown) {
      console.error(error)
    }
  }

  private async loadCommonData(): Promise<void> {
    try {
      const {
        mainAtlas: { exportedMainAtlases },
        mainImage: { exportedMainImages },
        mainSprite: { exportedMainSprites },
      } = exporters[currentGame]

      const [mainAtlases, mainImages, mainSprites]: [
        AtlasDataInterface[],
        ImageLoadData[],
        SpriteDataInterface[],
      ] = await Promise.all([exportedMainAtlases(), exportedMainImages(), exportedMainSprites()])

      await Promise.all([
        loadAtlasData(this.getActiveScene(), mainAtlases),
        loadImageData(this.getActiveScene(), mainImages),
        loadSpriteData(this.getActiveScene(), mainSprites),
      ])
    } catch (error: unknown) {
      console.error(error)
    }
  }

  private async loadClubsData(): Promise<void> {
    const hasMechanic = useResponseTaskStore().hasMechanic(MECHANIC_CLUB)
    if (!hasMechanic) return

    try {
      const {
        clubsAtlas: { exportedClubsAtlases },
        clubsImage: { exportedClubsImages },
      } = exporters[currentGame]

      const [clubsAtlases, clubsImages]: [AtlasDataInterface[], ImageLoadData[]] =
        await Promise.all([exportedClubsAtlases(), exportedClubsImages()])

      await Promise.all([
        loadAtlasData(this.getActiveScene(), clubsAtlases),
        loadImageData(this.getActiveScene(), clubsImages),
      ])
    } catch (error: unknown) {
      console.error(error)
    }
  }

  private async loadEventsData(createEventBuilding = true): Promise<void> {
    const eventInfoStore = useEventInfoStore()

    if (
      (!eventInfoStore.getEventActive && !eventInfoStore.getEventCollectionActive) ||
      eventInfoStore.getEventLocked
    )
      return

    const CURRENT_EVENT_MECHANIC =
      eventInfoStore.getEventType === EventType.DisciplineEvent
        ? MECHANIC_DISCIPLINE_EVENT
        : MECHANIC_EVENT

    Object.keys(this.eventMechanicsLoaded).forEach((key: string): void => {
      if (this.eventMechanicsLoaded[key] < new Date()) delete this.eventMechanicsLoaded[key]
    })

    if (this.eventMechanicsLoaded[CURRENT_EVENT_MECHANIC]) return

    const hasMechanic = useResponseTaskStore().hasMechanic(CURRENT_EVENT_MECHANIC)
    if (!hasMechanic) return

    this.eventMechanicsLoaded[CURRENT_EVENT_MECHANIC] = eventInfoStore.getEventCollectionEnd

    try {
      // FIXME: Import only neccessary data in case of only 1 active event.
      const {
        eventsAtlas: { exportedEventsAtlases },
        eventsImage: { exportedEventsImages },
      } = exporters[currentGame]

      // FIXME: Import only neccessary data in case of only 1 active event.
      const [eventsAtlases, eventsImages]: [AtlasDataInterface[], ImageLoadData[]] =
        await Promise.all([
          exportedEventsAtlases(),
          exportedEventsImages(eventInfoStore.getEventAssetsDirectory),
        ])
      const activeScene: Phaser.Scene = this.getActiveScene()

      await Promise.all([
        eventInfoStore.getEventType === EventType.DisciplineEvent
          ? loadAtlasData(activeScene, eventsAtlases)
          : loadImageData(activeScene, eventsImages),
      ])

      if (!createEventBuilding) return

      activeScene.load.once(Phaser.Loader.Events.COMPLETE, (): void => {
        usePhaserGameIntegrationStore().createEventBuilding()
      })
    } catch (error: unknown) {
      console.error(error)
    }
  }

  private async loadTutorialData(): Promise<void> {
    try {
      const {
        tutorialImage: { exportedTutorialImages },
      } = exporters[currentGame]

      const [tutorialImages]: [ImageLoadData[]] = await Promise.all([exportedTutorialImages()])
      await Promise.all([loadImageData(this.getActiveScene(), tutorialImages)])
    } catch (error: unknown) {
      console.error(error)
    }
  }
}

// This class have to be singleton because is used by all scenes. We don't want to allow create
// new instances every time is this class needed.
export const assetsHandler = new AssetsHandler()
