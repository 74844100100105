<template>
  <div
    class="offer-box-header w-full h-full text-texts-standard-default flex items-center justify-between"
  >
    <div
      class="flex-grow iconer offer-box-header-text uppercase text-28 text-left flex h-full items-center"
    >
      <app-discipline-icon
        v-if="getDisciplineId"
        theme="light"
        :size="70"
        :discipline-id="getDisciplineId"
        class="offer-box-header-icon mr-4"
      />
      <section class="flex flex-col justify-center w-full h-full">
        <shrink-text
          :text="$t(`premiumGroup.${getStoreId}`)"
          :font-size="40"
          container-class-list="leading-10 mt-2 mb-2"
          text-class-list="font-bold"
        />
        <shrink-text
          :text="getRemainingBuysText"
          :font-size="28"
          container-class-list="leading-7"
          text-class-list="text-texts-standard-additional"
        />
      </section>
    </div>
    <div class="ml-2">
      <app-icon v-if="visible" class="iconer" icon-name="question" @click.prevent="doChange()" />

      <div v-else class="control-icon-box iconer" @click.prevent="doChange()">
        <div class="clickable-element control-icon control-icon-close" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import ShrinkText from '@/components/ShrinkText.vue'
import { PREMIUM_EQUIPMENT_ITEM } from '@/globalVariables'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { Offer } from '@/interfaces/premium/Offer'
import type Reward from '@/interfaces/Reward'
import type { NameValueString } from '@/interfaces/Global'

interface ComponentData {
  visible: boolean
}

export default defineComponent({
  name: 'OfferBoxHead',
  components: {
    ShrinkText,
  },
  props: {
    offerHeadData: {
      type: Object as PropType<Nullable<Offer>>,
      default: () => null,
    },
  },
  emits: ['change', 'back'],
  data(): ComponentData {
    return {
      visible: true,
    }
  },
  computed: {
    getStoreId(): string {
      const storeId = this.offerHeadData.stats?.find(
        (stat: NameValueString): boolean => stat.name === 'texts_images_key',
      )

      if (storeId) {
        return storeId.value
      }

      return this.offerHeadData.store_id
    },
    getDisciplineId(): number {
      const equipment = (
        this.offerHeadData.offer_content?.find(
          (item: Reward): boolean => item.name === PREMIUM_EQUIPMENT_ITEM,
        ) as Reward
      )?.rewardData
      if (!equipment) return 0
      return equipment.discipline_id
    },
    getRemainingBuysText(): string {
      if (this.offerHeadData.remaining_buys) {
        return this.$t('premiumGroup.remaining_purchases') + ':' + this.offerHeadData.remaining_buys
      } else {
        return this.$replacePlaceholder(
          this.$t('event.eventPassActive'),
          '{pass}',
          this.$t('premiumGroup.' + this.getStoreId),
        )
      }
    },
  },
  methods: {
    doChange(): void {
      this.visible = !this.visible
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/control-icons.scss';

.offer-box-header {
  padding: 0 1rem;

  .iconer {
    z-index: 2;
    pointer-events: auto;
    min-width: 4.375rem;
  }

  &-icon {
    width: 4.5rem;
    height: 4.5rem;
    margin-top: 0.25rem;
  }
}
</style>
