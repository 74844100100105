<template>
  <div class="claim">
    <app-button btn-type="confirm" :btn-text="$te('commonClaim')" @click="$emit('claimReward')" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClaimButton',
  emits: ['claimReward'],
})
</script>

<style lang="scss" scoped>
.claim {
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -3rem;
  position: relative;

  .button {
    align-items: center;
  }
}
</style>
