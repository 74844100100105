<template>
  <div class="equipment-header w-full flex items-center relative">
    <app-icon icon-name="question" @click="showInfoPopup = true" />
    <div class="equipment-info h-full text-40 flex items-center font-bold" :class="{ premium }">
      <app-discipline-icon
        v-tippy="{
          theme: 'WSM',
          content: $translateDiscipline(disciplineId),
          placement: 'right',
          textAlign: 'center',
        }"
        :size="70"
        :discipline-id="disciplineId"
        class="logo"
      />
      <p class="text-texts-standard-important">
        {{ $t('equipmentShop.' + equipmentName) }}
      </p>
      <equipment-item-box-rank :rank-data="getRankData" :is-premium="isPremium" class="ml-7" />
    </div>
    <div class="accessory flex items-center text-36 absolute">
      <p v-if="isPremium" class="text-texts-standard-default">{{ $t('equipmentShop.noAddons') }}</p>
      <section v-else class="flex items-center">
        <p class="text-texts-standard-default mx-2">{{ $t('equipmentShop.addOns') }}:</p>
        <p class="text-texts-standard-default mx-2">
          {{ countUpgrades }}
        </p>
        <div class="icon-star-full-32" />
      </section>
      <tippy
        :theme="isPremium ? 'WSM1' : 'WSM'"
        :placement="premium === 'event' ? 'bottom-end' : 'left'"
        :max-width="premium === 'event' ? '60.25rem' : '50rem'"
      >
        <app-icon icon-name="info-70" class="ml-4" />
        <template #content>
          <template v-if="isPremium">
            <div v-if="premium === 'event'">
              <div class="bonus-tooltip__header flexing">
                <p class="text-texts-standard-default text-36 font-bold">
                  {{ $t('equipmentShop.premiumEquipment') }}
                </p>
              </div>
              <div class="px-11 py-6">
                <ul class="list-disc text-30">
                  <li class="mb-8">
                    <p class="text-left w-full">
                      {{
                        $replacePlaceholder(
                          $t('equipmentShop.premiumInfo4'),
                          '{number}',
                          `${timeLimit}`,
                        )
                      }}
                    </p>
                  </li>
                  <li class="mb-8">
                    <p class="text-left w-full">
                      {{ $t('equipmentShop.premiumInfo5') }}
                    </p>
                  </li>
                  <li class="mb-8">
                    <p class="text-left w-full">
                      {{ $t('equipmentShop.premiumInfo6') }}
                    </p>
                  </li>
                  <li>
                    <p class="text-left w-full">
                      {{ $t('equipmentShop.premiumInfo7') }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div v-else class="p-10">
              <ul class="list-disc text-left">
                <li>
                  <p>{{ $t('equipmentShop.premiumInfo1') }}</p>
                </li>
                <li>
                  <p>{{ $t('equipmentShop.premiumInfo2') }}</p>
                </li>
              </ul>
            </div>
          </template>
          <div v-else class="tooltip-content text-texts-standard-default text-24">
            <div class="tooltip-content-header flex items-center justify-between">
              <div class="text-texts-standard-important text-left leading-8">
                {{ $t('equipmentShop.equipmentParameters') }}
              </div>
              <div>{{ $t('equipmentShop.base') }}</div>
              <div>{{ $t('equipmentShop.addOns') }}</div>
              <div>{{ $t('equipmentShop.total') }}</div>
            </div>
            <div v-if="bonuses" class="tooltip-content-main">
              <div
                v-for="(bonus, key, i) in bonuses"
                :key="i"
                class="tooltip-content-main-row text text-texts-standard-default flex items-center justify-between"
              >
                <div>{{ $t(BONUS_TRANSLATION_MAPPING[key]) }}</div>
                <div>{{ getBaseBonus(key) }}</div>
                <div
                  :class="{
                    'text-texts-standard-danger': isEquipmentDamaged,
                  }"
                >
                  {{ getAddonBonus(bonus, key) }}
                </div>
                <div
                  :class="
                    isEquipmentDamaged
                      ? 'text-texts-standard-danger'
                      : 'text-texts-standard-important'
                  "
                >
                  {{ calculateTotal(bonus, key) }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </tippy>
    </div>
    <info-popup v-if="showInfoPopup" @close="showInfoPopup = false">
      <div class="popup-about" :class="{ 'popup-web': !$isMobile() }">
        <app-scrollbar width="100%" height="37rem" slide="y" scroll="y">
          <equipment-popup />
        </app-scrollbar>
      </div>
    </info-popup>
  </div>
</template>

<script lang="ts">
import EquipmentItemBoxRank from '@/components/Equipment/EquipmentItemBoxRank.vue'
import EquipmentPopup from '@/components/Equipment/EquipmentPopup.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import {
  EQUIPMENT_ATTRIBUTE_BONUS,
  EQUIPMENT_CASH_BONUS,
  EQUIPMENT_DURABILITY,
  EQUIPMENT_TP_BONUS,
} from '@/globalVariables'
import { BONUS_TRANSLATION_MAPPING } from './EquipmentDetail.vue'
import type { Bonuses, FormattedLevel, RankData, Upgrades } from '@/interfaces/Equipment'
import type { Nullable } from '@/interfaces/utils'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

type BonusKey = keyof Bonuses

type BonusValue = Bonuses[BonusKey]

interface ComponentData {
  BONUS_TRANSLATION_MAPPING: typeof BONUS_TRANSLATION_MAPPING
  EQUIPMENT_ATTRIBUTE_BONUS: typeof EQUIPMENT_ATTRIBUTE_BONUS
  showInfoPopup: boolean
}

export default defineComponent({
  components: {
    InfoPopup,
    EquipmentPopup,
    EquipmentItemBoxRank,
  },
  props: {
    disciplineId: {
      type: Number,
      required: true,
    },
    bonuses: {
      type: Object as PropType<Nullable<Bonuses>>,
      default: () => null,
    },
    currentLevel: {
      type: Object as PropType<FormattedLevel>,
      required: true,
    },
    upgrades: {
      type: Array as PropType<Upgrades>,
      default: () => [],
    },
    text: {
      type: String,
      default: 'Pro',
    },
    equipmentName: {
      type: String,
      default: '',
    },
    premium: {
      type: String,
      default: '',
    },
    timeLimit: {
      type: Number,
      default: 0,
    },
  },
  data(): ComponentData {
    return {
      BONUS_TRANSLATION_MAPPING,
      EQUIPMENT_ATTRIBUTE_BONUS,
      showInfoPopup: false,
    }
  },
  computed: {
    isPremium(): boolean {
      return !!this.premium
    },
    getRankData(): RankData {
      return {
        levelName: this.text,
        levelNumber: String(this.currentLevel.level),
      }
    },
    isEquipmentDamaged(): boolean {
      return this.currentLevel.parameters[EQUIPMENT_DURABILITY].currentValue === 0
    },
    countUpgrades(): string {
      if (!this.upgrades) return ''

      const current = this.upgrades.reduce((output: number, upgrade: Upgrades[0]): number => {
        output += upgrade.currentLevel
          ? upgrade.levels.filter(
              (upgradeLevel: Upgrades[0]['levels'][0]): boolean =>
                upgradeLevel.level <= upgrade.currentLevel.level,
            ).length
          : 0

        return output
      }, 0)

      const total = this.upgrades.length * this.currentLevel.level

      return `${current}/${total}`
    },
  },
  methods: {
    getBaseBonus(type: BonusKey): string {
      if (this.currentLevel.parameters[type].baseValue === 0) return '-'
      return String(this.currentLevel.parameters[type].baseValue)
    },
    calculateTotal(bonus: BonusValue, type: BonusKey): string {
      let total: BonusValue = 0

      if (this.currentLevel) {
        total = Number(this.currentLevel.parameters[type].value)
      } else {
        total = bonus
      }

      if (type === EQUIPMENT_ATTRIBUTE_BONUS && this.isEquipmentDamaged) {
        total = this.currentLevel.parameters.equipment_attribute_bonus.baseValue

        return String(total)
      }

      return total > 0 ? (this.hasPercentage(type) ? `${total}%` : String(total)) : '-'
    },
    hasPercentage(type: BonusKey): boolean {
      return [EQUIPMENT_CASH_BONUS, EQUIPMENT_TP_BONUS].includes(type)
    },
    getAddonBonus(bonus: BonusValue, type: BonusKey): string {
      if (bonus === 0) return '-'
      return `+${bonus}${this.hasPercentage(type) ? '%' : ''}`
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/stars-icons.scss';

.equipment-header {
  .equipment-info {
    width: 41.9375rem;
    height: 5.25rem;
    background: url($path-equipment + 'BG-PAGE-TITTLE-LOGO.avif') left center no-repeat;
    background-size: auto 100%;
    margin-left: 1rem;

    &.premium {
      width: 43.688rem;
      background: url($path-equipment + 'bg-equipment-header-premium.avif') center no-repeat;
      background-size: contain;
    }

    .logo {
      margin-left: 0.563rem;
      margin-right: 1.5rem;
    }
  }

  .equipment-info > &:nth-child(2) {
    margin-right: 0.2rem;
  }

  .accessory {
    right: 0rem;
  }
}

.bonus-tooltip__header {
  position: relative;
  height: 3.125rem;
  background: linear-gradient(to left, rgba(15, 40, 57, 0), #1e6c9b, rgba(15, 40, 57, 0));
  margin-bottom: 0.625rem;

  &:after {
    content: '';
    position: absolute;
    bottom: 0rem;
    left: 0;
    width: 100%;
    height: 0.063rem;
    background: linear-gradient(to right, #a4a4a400, #a4a4a4, #a4a4a4, #a4a4a400);
  }
}

.tooltip-content {
  &-header {
    width: 100%;
    height: 5rem;
    position: relative;

    & div {
      width: 20%;
    }

    & div:first-child {
      width: 40%;
      text-align: left;
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      bottom: 0.5rem;
      width: 33.625rem;
      height: 0.125rem;
      background: linear-gradient(to left, #11517e00, #37a1ed, #37a1ed, #11517e00);
    }
  }

  &-main {
    width: 41rem;
    height: 12rem;

    &-row {
      height: 3rem;

      & div {
        width: 20%;
      }

      & div:first-child {
        width: 40%;
        text-align: left;
      }

      & div:nth-child(3),
      & div:nth-child(4) {
        font-weight: bold;
      }
    }
  }
}
</style>
