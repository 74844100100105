import {
  TRAINING_HALL,
  disciplinesByGameEndpoint,
  gameDisciplinesEndpoint,
  trainerDisciplinesEndpoint,
  trainingDisciplinesEndpoint,
  trainingStatsEndpoint,
  userDisciplinesEndpoint,
} from '@/globalVariables'
import { gameToken } from '@/helpers'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import { useDisciplineStore } from './disciplinesStore'
import type Discipline from '@/interfaces/global/Discipline'
import type { Stat } from '@/interfaces/global/Discipline'
import type { DisciplineConfigResponse } from '@/interfaces/responses/disciplines/DisciplinesApiResponses'
import type { TrainingHallStats } from '@/interfaces/responses/training/TrainingApiResponse'
import { ApiService } from '@/services/ApiService'

export interface TrainingDisciplineStoreState {
  isLoading: boolean
  disciplines: Discipline[]
  playerDisciplines: Discipline[]
  disciplinesLoaded: boolean
  trainerDisciplinesLoaded: boolean
  disciplineStats: any // TODO any
  unlockDisciplines: number
}

interface MechanicStat {
  name: string
  value: number
}

interface UserStat {
  name: string
  value: number
}

interface DisciplinesApiResponse {
  discipline_id: number
  locked: boolean
  unlock_level: number
  temporary_lock: boolean
  mechanic_stats: MechanicStat[]
  user_attribute: number
  user_stats: UserStat[]
  training_points_target: number
}

interface TrainerSlotData {
  id: number
  slotId: string
  state: string
}

interface Slot {
  slot_id: string
  state: string
  discipline_id: number | null
}

interface TrainerResponseData {
  expiration: number
  slots: Slot[]
}

interface TrainerData {
  expiration: number
  slots: TrainerSlotData[]
}

interface Attributes {
  POWER: {
    BASE: number
    SEASONAL: number
    EQUIPMENT: number
    RESEARCH_BASE: number
    RESEARCH_PREMIUM: number
    RESEARCH_REPEATABLE: number
    EQUIPMENT_UPGRADE: number
    EQUIPMENT_PREMIUM_UPGRADE: number
    BENEFIT: number
  }
}

interface TrainingStat {
  user_games_id: string
  discipline_id: number
  stats: TrainingHallStats
  training_points: number
  next_level_training_points: number
  attributes: Attributes
  temporary_lock: boolean
}

export const useTrainingDisciplineStore = defineStore('trainingDisciplineStore', {
  state: (): TrainingDisciplineStoreState => ({
    isLoading: false,
    disciplines: [],
    playerDisciplines: [],
    disciplinesLoaded: false,
    trainerDisciplinesLoaded: false,
    disciplineStats: null,
    unlockDisciplines: 0,
  }),
  getters: {
    getDisciplinesLoaded(): boolean {
      return this.disciplinesLoaded
    },
    getDisciplines(): Discipline[] {
      return this.disciplines
    },
    getPlayerDisciplines(): Discipline[] {
      return this.playerDisciplines
    },
    getPlayerUnlockedDisciplines(): number[] {
      return this.playerDisciplines
        .reduce((finalArray: number[], discipline: Discipline): number[] => {
          if (!discipline.lock) finalArray.push(discipline.id)
          return finalArray
        }, [])
        .sort()
    },
    getDisciplineById(): (id: number) => Discipline {
      return (id: number): Discipline => {
        return this.disciplines.find((discipline: Discipline): boolean => discipline.id === id)
      }
    },
    getIsLoading(): boolean {
      return this.isLoading
    },
  },
  actions: {
    async loadInitialDisciplinesData(isTrainer?: boolean, force: boolean = false): Promise<void> {
      const response = await ApiService.get<DisciplinesApiResponse[]>(
        `${userDisciplinesEndpoint}/${TRAINING_HALL}`,
        { force },
      )

      if (response == null) return

      let trainerData = {} as TrainerData

      if (isTrainer) {
        const trainerResponse = await internalAxios.get<{}, TrainerResponseData>(
          trainerDisciplinesEndpoint,
        )

        const expiration = trainerResponse.expiration
        if (trainerResponse.slots) {
          const trainerSlots = trainerResponse.slots.map((slot: Slot): TrainerSlotData => {
            return {
              id: slot.discipline_id,
              slotId: slot.slot_id,
              state: slot.state,
            }
          })

          trainerData = { expiration, slots: trainerSlots }
        }
      }

      const disciplines = response.map((discipline: DisciplinesApiResponse): Discipline => {
        const trainerSlot =
          trainerData.slots?.find(
            (item: TrainerSlotData): boolean => item.id === discipline.discipline_id,
          ) ?? null

        return {
          id: discipline.discipline_id,
          locked: discipline.locked,
          levelToUnlock: discipline.unlock_level ?? null,
          temporaryLock: discipline.temporary_lock ?? false,
          mechanicStats: {
            disciplineLevel:
              discipline.mechanic_stats?.find(
                (item: MechanicStat): boolean => item.name === 'DISCIPLINE_LEVEL',
              ).value ?? null,
          },
          userAttribute: discipline.user_attribute ?? null,
          userStats: {
            trainingPoints:
              discipline.user_stats?.find(
                (item: UserStat): boolean => item.name === 'TRAINING_POINTS',
              ).value ?? null,
          },
          trainingPointsTarget: discipline.training_points_target ?? null,
          state: trainerSlot?.state ?? null,
          slotId: trainerSlot?.slotId ?? null,
          expiration: trainerData?.expiration ?? null,
        }
      })

      //  naplni do stavu discipliny, ktore sme dostali zo servera
      this.disciplines = disciplines
      this.disciplinesLoaded = true
    },
    async loadDisciplines(playerId?: string): Promise<void> {
      const finalDisciplines: Discipline[] = []
      this.isLoading = true
      // stiahnem si data s idckami vsetkych disciplin
      const allCountDisciplines = await ApiService.get<number[]>(
        disciplinesByGameEndpoint + gameToken,
      )

      // stiahnem si iba odomknute discipliny
      let availableDisciplines = []
      availableDisciplines = await ApiService.get<Discipline[]>(
        !playerId
          ? trainingDisciplinesEndpoint
          : `${trainingDisciplinesEndpoint}?user_games_id=${playerId}`,
      )

      if (!playerId) {
        this.unlockDisciplines = 0
        this.unlockDisciplines = availableDisciplines.length
      }

      const availableDisciplinesStr = availableDisciplines
        .map((discipline: Discipline) => discipline.id)
        .join('-')
      const availableIds = availableDisciplines.map((e: Discipline) => e.id)
      const lockDiscsStr = allCountDisciplines
        .filter((disciplineId: number): boolean => !availableIds.includes(disciplineId))
        .join('-')

      // stiahneme si stats k dostupnym disciplinam
      let trainingStats = []
      trainingStats = availableDisciplines.length
        ? await internalAxios.get<{}, TrainingStat[]>(
            playerId
              ? `${trainingStatsEndpoint}${availableDisciplinesStr}?user_games_id=${playerId}`
              : `${trainingStatsEndpoint}${availableDisciplinesStr}`,
          )
        : []

      // stiahneme si data pre zamknute discipliny
      const lockedDisciplines = lockDiscsStr
        ? await internalAxios.get<{}, Discipline[]>(`${gameDisciplinesEndpoint}${lockDiscsStr}`)
        : null

      const mutatedTrainingStats = trainingStats.reduce(
        (acc: Discipline, val: TrainingStat): Discipline => {
          // const stats = convertNameValue(val.stats)

          acc[val.discipline_id] = {
            maxValue: val.next_level_training_points || 0,
            currentValue: val.training_points || 0,
            level: val.stats.DISCIPLINE_LEVEL ?? 0,
            bestScore: val.stats.BEST_SCORE ?? 0,
            attributes: { ...val.attributes.POWER },
          }
          return acc
        },
        {},
      )
      // prejdem si dostupne discipliny
      availableDisciplines.forEach((disc: Discipline): void => {
        let totalStrength = 0
        const trainingStrength =
          mutatedTrainingStats[disc.id].attributes.BASE ??
          0 + mutatedTrainingStats[disc.id].attributes.SEASONAL ??
          0
        // eslint-disable-next-line
        Object.entries(mutatedTrainingStats[disc.id].attributes).forEach(([_, value]: [string, number]): void => {
            totalStrength += value
          },
        )

        finalDisciplines.push({
          id: disc.id,
          currentValue: mutatedTrainingStats[disc.id].currentValue,
          maxValue: mutatedTrainingStats[disc.id].maxValue,
          level: mutatedTrainingStats[disc.id].level,
          bestScore: mutatedTrainingStats[disc.id].bestScore,
          attributes: mutatedTrainingStats[disc.id].attributes,
          attribute:
            mutatedTrainingStats[disc.id].attributes.BASE +
            mutatedTrainingStats[disc.id].attributes.SEASONAL,
          lock: false,
          totalStrength: totalStrength,
          temporaryLock: disc.temporary_lock ?? false,
          temporaryLockLevel: disc.stats.find(
            (item: Stat): boolean => item.definitions?.name === 'unlock_level',
          )?.value,
          trainingStrength: trainingStrength,
        })
      })

      // if locked, add unlock level from disciplines config
      if (lockedDisciplines) {
        const disciplineStore = useDisciplineStore()
        const disciplinesConfig = disciplineStore.getDisciplinesUnlockConfig
        let unlockedDisciplinesCounter = availableDisciplines.length + 1
        lockedDisciplines.forEach((discipline: Discipline): void => {
          const disciplineUnlockDefault = discipline.stats?.find(
            (stat: Stat): boolean => stat.definitions?.name === 'unlock_level',
          )?.value
          const levelToUnlockNextDiscipline = disciplinesConfig.find(
            (config: DisciplineConfigResponse): boolean =>
              config.max_disciplines_unlocked === unlockedDisciplinesCounter,
          )?.level_required
          let lock: number
          if (disciplineUnlockDefault && Number(disciplineUnlockDefault) >= 99) {
            lock = 99
          } else {
            lock = levelToUnlockNextDiscipline
            unlockedDisciplinesCounter++
          }

          finalDisciplines.push({
            id: discipline.id,
            lock,
          })
        })
      }
      this.isLoading = false

      // mutacia nam naplni do stavu discipliny, ktore sme dostali zo servera
      this.disciplines = finalDisciplines
      this.disciplinesLoaded = true

      if (playerId) {
        this.playerDisciplines = finalDisciplines
      }
    },
  },
})
