<template>
  <div id="career-list-of-unlocks" class="career-list-of-unlocks app-page-wrapper absolute">
    <menu-component menu-type="career" :title="$t('careerQuestUnlocks.listOfUnlocks')" />

    <section class="w-full h-full safe-area">
      <div class="career-list-of-unlocks-menu">
        <discipline-menu
          id="career-discipline-menu"
          class="my-4"
          :type="MECHANIC_CAREER"
          :emit-click-event="true"
          :selected-default="0"
          @on-click-discipline="showSpecificListOfUnlocks"
        />
      </div>

      <div class="career-list-of-unlocks-content flex flex-col">
        <div class="career-list-of-unlocks-content-title">
          <p class="text-40 text-texts-standard-important uppercase font-bold">
            {{
              !selectedDisciplineId
                ? $t('careerQuestUnlocks.mainBranch')
                : $translateDiscipline(selectedDisciplineId)
            }}
          </p>
        </div>

        <app-scrollbar
          v-if="!isContentLoading"
          width="100%"
          height="auto"
          class="flex items-center"
          :class="$isMobile() ? 'pb-6' : 'pb-3'"
          scroll="x"
          slide="x"
          :arrows="true"
          :scroll-arrows-safe-zone-indented="true"
        >
          <div class="flex items-center career-list-of-unlocks-content-list">
            <div
              v-for="(item, i) in listOfUnlocks"
              :key="i"
              class="career-list-of-unlocks-content-list-item bordered-1 flex flex-col items-center"
              :class="{ 'career-list-of-unlocks-content-list-item-unlocked': item.isClaimed }"
            >
              <div
                class="career-list-of-unlocks-content-list-item-quest flexing"
                :class="{
                  'career-list-of-unlocks-content-list-item-quest-unlocked': item.isClaimed,
                }"
              >
                <p class="text-50 text-texts-standard-default">
                  {{ item.questOrder }}
                </p>
                <div
                  class="icon-locked-shield absolute career-list-of-unlocks-content-list-item-quest-icon-locked-shield"
                />
                <div
                  v-if="item.isClaimed"
                  class="icon-done-sm absolute career-list-of-unlocks-content-list-item-quest-icon-done"
                />
              </div>
              <div
                class="career-list-of-unlocks-content-list-item-img relative"
                :style="{
                  background: selectBackground(item),
                }"
              >
                <div
                  v-if="item.image === 'new_discipline_level'"
                  class="discipline-level text-texts-standard-important flexing"
                >
                  {{ item.unlocks.placeholder }}
                </div>
                <tippy
                  theme="WSM"
                  placement="top"
                  class="career-list-of-unlocks-content-list-item-img-icon absolute"
                >
                  <app-icon icon-name="info-50" />
                  <template #content>
                    <career-unlock-tooltip :unlock-item="item" />
                  </template>
                </tippy>
              </div>

              <p
                class="text-32 text-texts-standard-important career-list-of-unlocks-content-list-item-title font-bold"
              >
                {{
                  item.unlocks?.placeholder
                    ? $replacePlaceholder(
                        $t(`careerQuestUnlocks.${item.unlocks.text}`),
                        '%s',
                        item.unlocks.placeholder,
                      )
                    : $t(`careerQuestUnlocks.${item.unlocks?.text}`)
                }}
              </p>

              <p class="text-32 text-texts-standard-additional mb-2">
                {{ $replacePlaceholder($t('common.taskNumber'), '%s', item.questOrder) }}
              </p>
              <p
                v-if="item.isClaimed"
                class="text-36 text-texts-standard-upgrade mt-2 uppercase font-bold"
              >
                {{ $t('careerQuestUnlocks.unlocked') }}
              </p>
              <app-icon v-if="!item.isClaimed" icon-name="lock-md" />
            </div>
          </div>
        </app-scrollbar>
        <component-loading :is-loading="isContentLoading" width="100%" height="35rem" />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import CareerUnlockTooltip from '@/components/Career/CareerUnlockTooltip.vue'
import DisciplineMenu from '@/components/DisciplineMenu.vue'
import { LOCKED, MECHANIC_CAREER, UNLOCKED, pathImages } from '@/globalVariables'
import { useListOfUnlocksStore } from '@/store/pinia/career/listOfUnlocks'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { CareerUnlockItem } from '@/interfaces/CareerUnlockItem'
import type { DisciplineMenuData } from '@/interfaces/DisciplineMenu'

interface ComponentData {
  pathImages: typeof pathImages
  UNLOCKED: typeof UNLOCKED
  LOCKED: typeof LOCKED
  MECHANIC_CAREER: typeof MECHANIC_CAREER
  selectedDisciplineId: number
}

export default defineComponent({
  name: 'CareerListOfUnlocks',
  components: {
    DisciplineMenu,
    CareerUnlockTooltip,
  },
  data(): ComponentData {
    return {
      pathImages,
      UNLOCKED,
      LOCKED,
      MECHANIC_CAREER,
      selectedDisciplineId: 0,
    }
  },
  computed: {
    ...mapState(useListOfUnlocksStore, {
      listOfUnlocks: 'getListOfUnlocks',
      isContentLoading: 'isLoading',
    }),
  },
  async created(): Promise<void> {
    await this.loadListOfUnlocks()
  },
  methods: {
    ...mapActions(useListOfUnlocksStore, ['loadListOfUnlocks']),
    showSpecificListOfUnlocks(discipline: DisciplineMenuData): void {
      this.selectedDisciplineId = discipline.id
      this.loadListOfUnlocks(discipline.careerId.toString())
    },
    selectBackground(item: CareerUnlockItem): string {
      if (item.unlocks.equipmentCategory != null) {
        return `
        url(${pathImages}equipment/items/detail/${item.disciplineId}/${item.unlocks.equipmentCategory}-${item.unlocks.equipmentLevel}.avif) 
        center no-repeat`
      }

      return `url(${pathImages}career/detail/${item.image}.avif) center no-repeat`
    },
  },
})
</script>

<style lang="scss" scoped>
.career-list-of-unlocks {
  &-menu {
    width: 95%;
    margin: 0 auto;

    @if $isWsm {
      background-image: linear-gradient(to right, transparent 0, #144a73, transparent 100%);
    }

    @if $isSsm {
      background-image: linear-gradient(
        to right,
        transparent,
        rgba(86, 98, 127, 0.96),
        rgba(86, 98, 127, 0.96),
        transparent
      );
    }
  }

  &-content {
    width: 98%;
    margin: 0 auto;

    &-title {
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-bottom: 1rem;

      @if $isWsm {
        background-image: linear-gradient(to right, transparent 0, #144a73, transparent 100%);
      }

      @if $isSsm {
        background-image: linear-gradient(
          to right,
          transparent 0,
          rgba(86, 98, 127, 0.96),
          transparent 100%
        );
      }
    }

    &-list {
      &-item {
        height: 38.5rem;
        width: 25.938rem;
        background: url($path-career + 'detail/item-bg.avif') center no-repeat;
        background-size: 100% 100%;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        padding-top: 2rem;
        padding-bottom: 1rem;

        &-title {
          height: 5rem;
          line-height: 1.1;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 1rem;
        }

        &-unlocked {
          background: url($path-career + 'detail/item-bg-done.avif') center no-repeat;
          background-size: 100% 100%;
        }

        &-quest {
          width: 5.625rem;
          height: 5.625rem;
          position: relative;
          margin-left: 1.2rem;
          margin-right: 1.2rem;
          box-shadow: 0 0 1.125rem 0 rgb(0 0 0 / 50%);
          border-width: 0.125rem;
          border-style: solid;
          flex-direction: row;
          @if $isWsm {
            border-color: #3f6b93;
            background: linear-gradient(to top, #3f78a0, #254257);
          }
          @if $isSsm {
            border-color: #586b9d;
            background: linear-gradient(to right, rgba(52, 65, 93, 0.85), rgba(39, 50, 73, 0.85));
          }

          &-unlocked {
            @if $isWsm {
              background: linear-gradient(0deg, rgb(76, 179, 48) 0%, rgb(21, 147, 15) 100%);
            }
            @if $isSsm {
              border-color: #7bf4e5;
              background: linear-gradient(
                0deg,
                rgba(14, 231, 204, 0.85) 0%,
                rgba(8, 202, 178, 0.85) 100%
              );
            }
          }

          &-icon-locked-shield {
            right: 30%;
            top: -15%;
          }

          &-icon-done {
            right: -15%;
            bottom: -5%;
          }
        }

        &-img {
          background-size: contain !important;
          width: 25.938rem;
          height: 14.313rem;
          margin-top: 2rem;

          &-icon {
            right: 2rem;
            bottom: -1rem;
          }

          .discipline-level {
            font-size: 5.5em;
            margin-top: 3rem;
          }
        }
      }
    }
  }
}
</style>
