<template>
  <div
    class="main-duel app-page-wrapper absolute"
    :class="{ 'bg-none': isEventView }"
    data-cy="duel-duel-wrapper"
  >
    <div class="w-full h-full relative flex flex-col items-center">
      <arena-menu v-if="!isEventView" />
      <section class="w-full h-full safe-area">
        <seasons-recalculation-popup :is-event-view="isEventView" />

        <div v-if="!isEventView" class="main-duel-header flexing relative">
          <modal-head
            :title-popup="$t('arena.header')"
            :style="actualStage?.discipline ? 'z-index:6' : ''"
          >
            <discipline-menu
              v-if="loadMenu"
              id-discipline="arena-main-menu"
              :type="ARENA"
              :selected-default="activeDisciplineId"
              :emit-click-event="true"
              @on-click-discipline="getDisciplineContent"
            />
            <template #popupContent>
              <arena-about />
            </template>
          </modal-head>
        </div>
        <div class="main-wrapper flexing" :class="$isMobile() && 'relative'">
          <arrows-component
            v-if="!isEventView"
            class="main-wrapper-arrows w-full absolute"
            :array-of-ids="unlockDisciplinesIds"
          />
          <div class="main-duel-main flex justify-around item-center">
            <div class="main-duel-main-leftbox">
              <table-starts
                :opponents="duelOpponentInfoData"
                :discipline-id="activeDisciplineId"
                :benefits-slots="benefitArenaSlots"
              />
            </div>
            <arena-competitions-discipline-detail
              v-if="activeDisciplineId"
              :active-discipline-id="activeDisciplineId"
              :benefits-slots="benefitArenaSlots"
              :is-event-view="isEventView"
              :multiplier-context="Multiplier.Arena"
              @play-duel="playDuel"
            />
          </div>
        </div>
        <arena-competitions-footer
          :allowed-benefit-types="benefitTypes"
          :expiration="expiration"
          :benefits-slots="benefitArenaSlots"
          :required-benefits="startsCostConfig.base * activeMultiplicator(Multiplier.Arena).value"
          @load-opponents="loadOpponents"
          @load-specific-discipline="loadOpponentsWithDisciplineInfo"
          @switch-match-type="switchMatchType"
          @refresh-opponents="refreshOpponents"
        />

        <parameter-popup v-if="isPopupOpen" :parameter="'starts'" @close="isPopupOpen = false" />

        <skip-tutorial-popup
          v-if="showSkipTutorialPopup"
          :discipline-id="activeDisciplineId"
          :multiplier="activeMultiplicator(Multiplier.Arena).name"
          :no-tutorial-mode="mode.duel"
          @hide-popup="showSkipTutorialPopup = false"
        />
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import ArenaAbout from '@/components/Arena/ArenaAbout.vue'
import ArenaCompetitionsDisciplineDetail from '@/components/Arena/ArenaCompetitions/ArenaCompetitionsDisciplineDetail.vue'
import ArenaCompetitionsFooter from '@/components/Arena/ArenaCompetitions/ArenaCompetitionsFooter.vue'
import ArenaMenu from '@/components/Arena/ArenaMenu.vue'
import ArrowsComponent from '@/components/ArrowsComponent.vue'
import DisciplineMenu from '@/components/DisciplineMenu.vue'
import ModalHead from '@/components/ModalHead.vue'
import ParameterPopup from '@/components/Popup/Parameter/ParameterPopup.vue'
import SeasonsRecalculationPopup from '@/components/Seasons/SeasonsRecalculationPopup.vue'
import SkipTutorialPopup from '@/components/SkipTutorialPopup.vue'
import TableStarts from '@/components/Table/TableStarts.vue'
import {
  ARENA,
  BENEFITS_ARENA,
  LIVE,
  MECHANIC_BENEFIT,
  STARTS,
  MULTIPLIER,
  arenaMatchEndpoint,
  arenaNewOpponentsEndpoint,
  arenaOpponentsEndpoint,
  minigameUrl,
  openArenaEndpoint,
  pathImages,
  Multiplier,
} from '@/globalVariables'
import { getArenaDisciplineId, openMiniGame, playSound, setArenaDisciplineId } from '@/helpers'
import { useDuelStore } from '@/store/pinia/arena/duelStore'
import { useOponentsStore } from '@/store/pinia/arena/oponentsStore'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useModeStore } from '@/store/pinia/modeStore'
import { useOfferStore } from '@/store/pinia/offerStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type {
  ArenaOpponentsResponse,
  ArenaMatchResponse,
  ArenaOpponent,
} from '@/interfaces/responses/arena/ArenaResponses'
import type { DisciplineMenuData } from '@/interfaces/DisciplineMenu'
import type { RouteLocationNormalized } from 'vue-router'
import type { BenefitSlot, UserBenefitsSlotsApiResponse } from '@/interfaces/Benefits'

interface ComponentData {
  pathImages: typeof pathImages
  ARENA: typeof ARENA
  minigameUrl: typeof minigameUrl
  expiration: Nullable<number>
  duelOpponentInfoData: Nullable<ArenaOpponent>
  activeDisciplineId: Nullable<number>
  showSkipTutorialPopup: boolean
  isPopupOpen: boolean
  isLiveMatch: boolean
  Multiplier: typeof Multiplier
}

export default defineComponent({
  name: 'ArenaView',
  components: {
    ArenaMenu,
    ArenaCompetitionsDisciplineDetail,
    ArenaCompetitionsFooter,
    DisciplineMenu,
    ModalHead,
    TableStarts,
    ArenaAbout,
    ParameterPopup,
    SkipTutorialPopup,
    SeasonsRecalculationPopup,
    ArrowsComponent,
  },
  props: {
    isEventView: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
      ARENA,
      expiration: null,
      showSkipTutorialPopup: false,
      minigameUrl,
      activeDisciplineId: null,
      duelOpponentInfoData: null,
      isPopupOpen: false,
      isLiveMatch: false,
      Multiplier,
    }
  },
  computed: {
    ...mapState(useModeStore, { mode: 'getMode' }),
    ...mapState(useDuelStore, {
      startsCostConfig: 'getStartsCostConfig',
    }),
    ...mapState(useUserStore, {
      getShowResultScreen: 'getShowResultScreen',
      activeMultiplicator: 'getActiveMultiplicator',
    }),
    ...mapState(useTutorialStore, {
      actualStage: 'getActualStage',
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useDisciplineStore, {
      firstUnlockedDisciplineId: 'getFirstUnlockedDisciplineId',
      activeDiscipline: 'getSpecificDiscipline',
      unlockDisciplinesIds: 'getUnlockedDisciplinesIdsUnsorted',
    }),
    ...mapState(useResponseTaskStore, {
      benefitsSlots: 'getBenefitsSlots',
      hasMechanic: 'hasMechanic',
      userStarts: 'getStarts',
    }),
    ...mapState(useEventInfoStore, {
      eventDisciplineId: 'getEventDisciplineId',
    }),
    loadMenu(): boolean {
      return this.activeDisciplineId !== null
    },
    benefitArena(): UserBenefitsSlotsApiResponse[] {
      return (
        this.benefitsSlots?.filter(
          (benefit: UserBenefitsSlotsApiResponse): boolean =>
            benefit.benefit_building === BENEFITS_ARENA,
        ) ?? []
      )
    },
    benefitArenaSlots(): BenefitSlot['slots'] {
      return this.benefitArena.reduce(
        (acc: BenefitSlot['slots'], val: UserBenefitsSlotsApiResponse) => {
          acc[val?.slot_id] = val.benefit
          return acc
        },
        {},
      )
    },
    benefitTypes(): string[] {
      return this.benefitArena.map((benefit): string => benefit.benefit_slot_type)
    },
  },
  watch: {
    $route(to: RouteLocationNormalized, from: RouteLocationNormalized): void {
      if (
        (this.activeDisciplineId && to.name === this.$getWebView(this.$options.name)) ||
        to.query.reloadData
      ) {
        this.activeDisciplineId =
          parseInt(this.$route.params?.id as string) || getArenaDisciplineId()
        setArenaDisciplineId(this.activeDisciplineId)
        this.loadOpponentsWithDisciplineInfo()

        if (from.name !== this.$getWebView('ArenaView')) {
          this.loadBenefitsSlots()
        }

        if (to.query.reloadData) {
          this.$router.replace({
            ...this.$router.currentRoute,
            query: {
              reloadData: undefined,
            },
          })
        }
      }
    },
  },
  async created(): Promise<void> {
    try {
      await this.$axios.put<{}, true>(openArenaEndpoint)
    } catch (error: unknown) {
      console.error(error)
    }

    const unlockArenaDisciplineId = this.unlockDisciplinesIds.includes(getArenaDisciplineId())
      ? getArenaDisciplineId()
      : this.firstUnlockedDisciplineId

    const arenaDisciplineId = unlockArenaDisciplineId || this.firstUnlockedDisciplineId
    setArenaDisciplineId(arenaDisciplineId)

    if (this.isEventView) {
      this.activeDisciplineId = this.eventDisciplineId
    } else {
      this.activeDisciplineId = isNaN(parseInt(this.$route.params?.id as string))
        ? arenaDisciplineId
        : this.unlockDisciplinesIds.includes(parseInt(this.$route.params?.id as string))
          ? parseInt(this.$route.params?.id as string)
          : null
    }
    this.loadOpponentsWithDisciplineInfo()
    this.loadBenefitsSlots()
    this.loadOpponentsRefreshPrice()
    this.loadStartsConfig()
    playSound('building-main-arena')
  },
  beforeUnmount(): void {
    this.setSimulation(false)
  },
  methods: {
    openMiniGame,
    ...mapActions(useOponentsStore, ['loadOpponentsRefreshPrice']),
    ...mapActions(useDuelStore, {
      loadStartsConfig: 'setStartsCosts',
    }),
    ...mapActions(useUserStore, {
      setUserExperience: 'setUserDataExperience',
      setUserDataTargetExperience: 'setUserDataTargetExperience',
      setUserDataStartingExperience: 'setUserDataStartingExperience',
      setUserDataLevel: 'setUserDataLevel',
      loadUserData: 'loadUserData',
    }),
    ...mapActions(useOfferStore, ['setRefreshingBundle']),
    ...mapActions(useCoreStore, ['setSimulation']),
    ...mapActions(useResponseTaskStore, {
      loadBenefitsSlots: 'loadBenefitsSlots',
    }),
    ...mapActions(useDisciplineStore, {
      loadSpecificDiscipline: 'loadSpecificDiscipline',
    }),
    getDisciplineContent(discipline: DisciplineMenuData): void {
      this.activeDisciplineId = discipline.id
    },

    async loadOpponents(): Promise<void> {
      if (!this.activeDisciplineId) return

      this.expiration = null
      const supermatch = `?${MULTIPLIER}=${this.activeMultiplicator(Multiplier.Arena).name}`
      const response = await this.$axios.get<{}, ArenaOpponentsResponse>(
        arenaOpponentsEndpoint + this.activeDisciplineId + supermatch,
      )
      this.duelOpponentInfoData = response.opponents
      this.expiration = response.opponents_expiration
    },
    async refreshOpponents(): Promise<void> {
      this.expiration = null
      const supermatch = `?${MULTIPLIER}=${this.activeMultiplicator(Multiplier.Arena).name}`
      const response = await this.$axios.post<{}, ArenaOpponentsResponse>(
        arenaNewOpponentsEndpoint + supermatch,
        {
          discipline_id: this.activeDisciplineId,
        },
      )

      this.duelOpponentInfoData = response.opponents
      this.expiration = response.opponents_expiration
    },
    async playDuel(match: boolean): Promise<void> {
      if (!this.activeDisciplineId) return

      this.isLiveMatch = match
      const startsCount = match ? this.startsCostConfig.live : this.startsCostConfig.base

      const startsMultiply = startsCount * this.activeMultiplicator(Multiplier.Arena).value
      if (this.userStarts.value < startsMultiply) {
        this.setRefreshingBundle({
          type: STARTS,
          value: startsMultiply,
        })

        this.isPopupOpen = true
        return
      }
      playSound('click-button-duel-play')
      if (match) {
        if (this.activeDiscipline.isTutorialPassed || this.actualStage) {
          this.openMiniGame(
            minigameUrl,
            {
              params: {
                mode: this.mode.duel,
                discipline_id: this.activeDisciplineId,
                multiplier: this.activeMultiplicator(Multiplier.Arena).name,
                tutorial_id: this.actualStage?.name ?? '',
                ...(this.isEventView ? { redirect: this.$route.name } : null),
              },
            },
            this.$router,
          )
        } else {
          this.showSkipTutorialPopup = true
        }
        return
      }
      this.setSimulation(true)
      this.expiration = null
      const response = await this.$axios.post<{}, ArenaMatchResponse>(arenaMatchEndpoint, {
        type: this.mode.duel,
        discipline_id: this.activeDisciplineId,
        multiplier: this.activeMultiplicator(Multiplier.Arena).name,
        simulation: true,
      })
      // TODO: temp solution
      if (response?.EXPERIENCE) this.setUserExperience(response.EXPERIENCE)
      if (response?.TARGET_EXPERIENCE) this.setUserDataTargetExperience(response.TARGET_EXPERIENCE)
      if (response?.STARTING_EXPERIENCE)
        this.setUserDataStartingExperience(response.STARTING_EXPERIENCE)
      if (response?.LEVEL) this.setUserDataLevel(+response.LEVEL)

      this.expiration = response.opponents_expiration
      this.duelOpponentInfoData = response.newOpponents
      if (this.getShowResultScreen) {
        this.loadReport(response.match_id, this.activeDisciplineId)
        this.setSimulation(false)
        this.loadUserData()
        return
      }

      this.loadSpecificDiscipline({
        disciplineId: this.activeDisciplineId,
        type: ARENA,
      })
      this.loadUserData()
      this.setSimulation(false)
    },
    loadReport(matchId: string, disciplineId: number): void {
      this.$router.push({
        name: this.$getWebView(this.isTutorial ? 'ArenaReportTutorial' : 'ArenaReport'),
        query: {
          match_id: matchId,
          discipline_id: disciplineId,
          match_time: this.isLiveMatch ? LIVE : ARENA,
          ...(this.isEventView
            ? { redirect: this.$route.name.toString().replace(/Web$/, '') }
            : null),
        },
      })
    },
    async switchMatchType(): Promise<void> {
      if (this.hasMechanic(MECHANIC_BENEFIT)) {
        this.loadOpponentsWithDisciplineInfo()
      }

      if (this.$route.path.includes('tutorial') || this.$route.path === '/') {
        this.$router.push({ name: this.$getWebView('ArenaView') })
      }
    },
    loadOpponentsWithDisciplineInfo(): void {
      if (!this.activeDisciplineId) return

      this.loadOpponents().then(() => {
        this.loadSpecificDiscipline({
          disciplineId: this.activeDisciplineId,
          type: ARENA,
        })
      })
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/arena/arena.scss';
</style>
