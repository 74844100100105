<template>
  <teleport to="#app">
    <div
      class="building-level-up fullscreen-popup-wrapper z-100"
      :class="$isMobile() && 'building-level-up-mobile'"
    >
      <div class="building-level-up__content">
        <div
          ref="animation1"
          class="building-level-up-header-text not-italic uppercase m-auto animated-element--faded"
          :style="{ '--box-shadow-color': '#ffffff' }"
        >
          {{ $t('common.congratulations') }}
        </div>
        <div
          ref="animation2"
          class="building-level-up-header-congratulation not-italic uppercase font-bold m-auto animated-element--faded"
          :style="{ '--box-shadow-color': '#fff196' }"
        >
          {{
            levelData.name === 'arena'
              ? $t('arena.newArenaLevel')
              : $t('trainingHall.trainingHallUpgraded')
          }}
        </div>
        <div
          ref="animation3"
          class="building-level-up-building m-auto relative animated-element--faded"
          :style="{
            backgroundImage: dynamicBackgroundImage,
            '--level-up-building-image': dynamicBackgroundImage,
          }"
        >
          <div class="building-level-up-building-glow" />
          <div
            ref="animation5"
            class="building-level-up-building-badge absolute animated-element--faded"
          >
            {{ levelData.level }}
          </div>
        </div>
        <div ref="animation4" class="building-level-up-rewards m-auto">
          <section v-if="levelData.name === ARENA">
            <div class="building-level-up-rewards-bonus flexing">
              <span class="flexing">
                <p class="text-texts-standard-dark text-36 uppercase font-bold">
                  {{ $t('arena.competitionsRewards') + ':' }}
                </p>
                <p class="text-texts-standard-default text-50 uppercase font-bold ml-4">
                  {{ '+' + levelData.bonus + '%' }}
                </p>
                <app-main-icon :icon-size="56" icon-name="money" />
              </span>
            </div>
            <p class="text-texts-standard-default text-28">
              {{ $t('arena.seasonalRewardAbout') }}
            </p>
          </section>

          <section v-if="levelData.name === TRAINING">
            <div
              v-for="(item, index) in rowsData"
              :key="index"
              class="building-level-up-rewards-box animated-element animated-element--faded"
              :class="{ 'no-display': item.count === 0 }"
            >
              <section v-if="item.count > 0" class="building-level-up-rewards-box-wrap">
                <span>
                  <p class="text-texts-standard-default text-32">
                    {{ getRowText(item.name) }}
                  </p>
                </span>
                <span class="flex relative">
                  <p class="text-texts-standard-default text-32 font-bold">
                    {{ $filters.$formatNumber(item.count) }}
                  </p>
                  <app-main-icon
                    v-if="item.name === 'max_tp_limit'"
                    :icon-size="48"
                    :icon-name="'universal_training_points'"
                  />
                  <app-icon
                    v-if="item.name === 'max_attribute' || item.name === 'max_seasonal_attribute'"
                    :icon-name="
                      item.name === 'max_attribute' ? 'attribute-limit' : 'seasonal-attribute-limit'
                    "
                    class="ml-4"
                  />
                  <div class="triangle green triangle-up absolute top-0 -right-2" />
                </span>
              </section>
            </div>
          </section>

          <section v-if="levelData.name === CLUBHOUSE || levelData.name === CLUBARENA">
            <div class="building-level-up-rewards-bonus flexing">
              <span class="flexing">
                <p class="text-texts-standard-dark text-36 uppercase font-bold">
                  {{
                    levelData.name === CLUBHOUSE ? $t('club.maxMembers') : $t('club.regularIncome')
                  }}:
                </p>
                <p class="text-texts-standard-default text-50 uppercase font-bold ml-4">
                  {{ levelData.value }}
                </p>
                <app-icon
                  v-if="levelData.name === CLUBHOUSE"
                  class="ml-3"
                  :icon-size="56"
                  icon-name="club-members"
                />
                <app-main-icon
                  v-if="levelData.name === CLUBARENA"
                  :icon-size="56"
                  icon-name="money"
                />
              </span>
            </div>
          </section>
        </div>

        <div class="building-level-up-footer flex items-center justify-between m-auto">
          <div ref="animation6" class="building-level-up-wsm-logo animated-element--faded" />
          <div ref="animation7" class="animated-element--faded">
            <app-button
              class="layout-premium-btn"
              btn-id="level-up-building-btn"
              btn-type="secondary"
              :btn-text="$t('button.continue')"
              btn-size="sm"
              @click="hideLevelUp"
            />
          </div>
        </div>

        <video
          v-if="currentGame === GameType.Winter"
          ref="video"
          class="video-footage video-footage--snow"
          autoplay
          loop
          muted
          playsinline
          poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAArEAAAKxAFmbYLUAAAADUlEQVQImWP4//8/AwAI/AL+hc2rNAAAAABJRU5ErkJggg=="
        >
          <!-- If the browser's video player is unable to play the MP4 file, nothing will be rendered except the poster image (transparent  1x1px). -->
          <source type="video/mp4" :src="`${pathImages}videos/wsm-snowfall--medium.mp4`" />
        </video>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { ARENA, CLUBARENA, CLUBHOUSE, TRAINING, pathImages, GameType } from '@/globalVariables'
import type { Building, BuildingLevelData } from '@/interfaces/global/Level'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { defineComponent } from 'vue'
import { animateElement, skipAnimationOnClick, playSound } from '@/helpers'
import type { PropType } from 'vue'

interface BuildingInfo {
  text: string
  img: string
}

interface ComponentData {
  ARENA: typeof ARENA
  CLUBARENA: typeof CLUBARENA
  CLUBHOUSE: typeof CLUBHOUSE
  TRAINING: typeof TRAINING
  pathImages: typeof pathImages
  MAX_TP_LIMIT: string
  MAX_ATTRIBUTE: string
  MAX_SEASONAL_ATTRIBUTE: string
  parameters: string[]
  rowsData: BuildingLevelData[]
  GameType: typeof GameType
  currentGame: string
}

export default defineComponent({
  name: 'BuildingLevelUp',
  props: {
    levelData: {
      type: Object as PropType<BuildingLevelData>,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      ARENA,
      CLUBARENA,
      CLUBHOUSE,
      TRAINING,
      pathImages,
      MAX_TP_LIMIT: 'max_tp_limit',
      MAX_ATTRIBUTE: 'max_attribute',
      MAX_SEASONAL_ATTRIBUTE: 'max_seasonal_attribute',
      parameters: [],
      rowsData: [],
      GameType,
      currentGame: this.$gameName,
    }
  },
  computed: {
    dynamicBackgroundImage(): string {
      return `url(${pathImages}${this.getBuilding(this.levelData.name).img}${this.getBgLevel(
        this.levelData.level,
      )}.avif)`
    },
  },
  created(): void {
    this.parameters = [this.MAX_TP_LIMIT, this.MAX_ATTRIBUTE, this.MAX_SEASONAL_ATTRIBUTE]
    this.rowsData = this.reduceData()
  },
  mounted(): void {
    this.animateScene()
    playSound('training_hall_upgrade')

    // skip animation on click
    window.addEventListener('click', this.handleSkipAnimationClick)
  },
  beforeUnmount(): void {
    window.removeEventListener('click', this.handleSkipAnimationClick)
  },
  methods: {
    getBuilding(building: BuildingLevelData['name']): BuildingInfo {
      if (building === TRAINING) {
        return {
          text: this.$t('trainingHall.newTrainingHallLevel'),
          img: 'training/building/BUILDING_TRAINING_HALL_LARGE_LVL',
        }
      } else if (building === ARENA) {
        return {
          text: this.$t('arena.newArenaLevel'),
          img: 'arena/building/BUILDING_ARENA_LARGE_LVL',
        }
      } else if (building === CLUBHOUSE) {
        return {
          text: this.$t('club.clubHouseUpgraded'),
          img: 'clubs/buildings/building-clubhouse-',
        }
      } else if (building === CLUBARENA) {
        return {
          text: this.$t('club.clubArenaUpgraded'),
          img: 'clubs/buildings/building-stadium-',
        }
      }
    },
    getRowText(index: string): string {
      if (index === this.MAX_TP_LIMIT) return this.$t('trainingHall.newLimitOfTrainingPoints')
      else if (index === this.MAX_ATTRIBUTE) return this.$t('trainingHall.newLimitOfAttributes')
      else if (index === this.MAX_SEASONAL_ATTRIBUTE)
        return this.$t('trainingHall.newLimitOfSeasonalAttributes')
    },
    getBgLevel(level: number): number {
      if (level > 0 && level < 5) {
        return 1
      } else if (level >= 5 && level < 9) {
        return 2
      } else if (level >= 9 && level < 13) {
        return 3
      } else if (level >= 13 && level < 17) {
        return 4
      } else if (level >= 17 && level <= 20) {
        return 5
      }
      return level
    },
    hideLevelUp(): void {
      const responseStore = useResponseTaskStore()
      responseStore.hideLevelUp()
    },
    reduceData(): BuildingLevelData[] {
      return Object.entries(this.levelData).reduce(
        (
          finalArray: BuildingLevelData[],
          [key, value]: [Building, number],
        ): BuildingLevelData[] => {
          if (this.parameters.includes(key)) finalArray.push({ name: key, count: value })
          return finalArray
        },
        [],
      )
    },
    animateScene(): void {
      // assigning a class will start the animation
      this.$refs.animation3.classList.add('animated-element--glared')

      animateElement(this.$refs.animation1, 'fade-in, zoom-reset', '1s', 100)
      animateElement(this.$refs.animation2, 'fade-in, zoom-reset', '1s', 400)
      animateElement(this.$refs.animation3, 'fade-in, pop-bounce', '1.3s', 700)

      const animatedElements = this.$refs.animation4.querySelectorAll('.animated-element')
      if (!animatedElements) return
      animatedElements.forEach((element: HTMLElement, index: number): void => {
        const delay = 1500 + index * 400
        animateElement(element, 'fade-in', '1s', delay)
      })
      animateElement(this.$refs.animation5, 'fade-in, pop-bounce, flash-blur', '1.5s', 1700)
      animateElement(this.$refs.animation6, 'fade-in', '1s', 3000)
      animateElement(this.$refs.animation7, 'fade-in', '1s', 3200)
    },
    handleSkipAnimationClick(e: MouseEvent): void {
      skipAnimationOnClick(e, this.$el, this.$refs, !!this.levelData, [
        ['animation4', '.animated-element'],
      ])
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/animations.scss';

.building-level-up {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8); // rgba(0,0,0,0.8)
  backdrop-filter: blur(0.5rem);
  overflow: hidden;

  &-header-text {
    background: linear-gradient(to bottom, #fff 0%, #d7d7d7 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3.125rem;
  }

  &-header-congratulation {
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 4.3125rem;

    @if $isWsm {
      background-image: linear-gradient(to bottom, #fff196 0%, #fadd1e 100%);
    }

    @if $isSsm {
      background-image: linear-gradient(to top, #fec433, #ffe31d);
    }
  }

  &-building {
    width: 41.5rem;
    height: 27.5rem;
    z-index: 1;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    @if $isWsm {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 17.5rem;
        height: 1.5rem;
        border-radius: 50%;
        mix-blend-mode: overlay;
        background: #3e7fa74f;
        box-shadow: 0 0 8.9375rem 13.625rem rgb(54 104 133 / 39%);
      }
    }

    &-badge {
      height: 7rem;
      color: theme('colors.texts.standard.important');
      background: url($path-images + 'arena/building/BADGE_NUMBER_BG.avif') center no-repeat;
      background-size: contain;
      width: 5.4375rem;
      font-size: 3.3125rem;
      line-height: 7rem;
      padding-top: 0.4rem;
      font-weight: bold;
      text-shadow: 0.0401875rem 0.047875rem 0.058125rem rgba(0, 0, 0, 0.71);
      right: 3rem;
      z-index: 1;

      @if $isWsm {
        font-style: italic;
        top: 5rem;
      }

      @if $isSsm {
        top: 1.5rem;
        padding-left: 0.25rem;
      }
    }
  }

  &-rewards {
    width: 50rem;
    height: 13.125rem;

    &-box {
      display: flex;
      align-items: center;
      width: 49.6875rem;
      height: 3.9375rem;
      margin: 0.32rem 0;
      padding: 0 1rem;
      @if $isWsm {
        border: 0.125rem solid #4a84b3;
        background-color: rgba(3, 62, 86, 0.7);
        box-shadow:
          inset 1px 0px 1.25rem 0px rgba(25, 73, 105, 0.8),
          inset 0.75rem 0.1875rem 1.25rem 0px rgba(25, 73, 105, 0.8),
          inset 0px 0px 1.25rem 0px rgba(25, 73, 105, 0.8),
          inset -0.6875rem -0.1875rem 1.25rem 0px rgba(25, 73, 105, 0.8);
      }
      @if $isSsm {
        border: 0.125rem solid #7477a4;
        background-color: rgba(#525479, 0.7);
        &:nth-child(even) {
          background-color: rgba(#7272b0, 0.7);
        }
      }

      &.no-display {
        display: none;
      }

      &-wrap {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
      }
    }

    &-bonus {
      min-width: 37.813rem;
      height: 4.375rem;
      background: linear-gradient(
        to right,
        transparent,
        rgba(190, 153, 73, 0.75) 20%,
        rgba(203, 159, 63, 0.8) 30%,
        rgba(255, 254, 206, 0.9) 50%,
        rgba(203, 159, 63, 0.8) 70%,
        rgba(190, 153, 73, 0.75) 80%,
        transparent
      );
      position: relative;
      margin: 2.625rem auto 3.563rem;

      &::before,
      &::after {
        content: '';
        left: 0;
        position: absolute;
        height: 0.125rem;
        width: 100%;
        background: linear-gradient(to right, transparent, #fff3ad, transparent);
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }
    }
  }

  &-footer {
    margin-top: 2.0625rem;
    width: 116.25rem;
    height: 4.5rem;

    .building-level-up-wsm-logo {
      width: 13.625rem;
      height: 5.625rem;
      background: url($path-images + 'levelUp/Logo_PPS.avif') center no-repeat;
      background-size: contain;
    }
  }

  .video-footage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // Full screen video setting regardless of its resolution
    object-fit: cover;
    opacity: 0.4;
    z-index: -1;
  }
}

.building-level-up-mobile {
  width: 100vw;
  height: 100vh;
}
</style>
