import type { ExportedImagesResponse } from '@/map-phaser-new/interfaces'
import { eventsShipImageLoadData } from './eventsShipImageLoadData'

/**
 * This function is used to collect all imports of events images, to make loading at BootScene easier
 * @returns ImageLoadData[]
 */
export const exportedEventsImages: ExportedImagesResponse = async (
  eventAssetsDirectory: string,
) => [...eventsShipImageLoadData(eventAssetsDirectory)]
