<template>
  <article class="settings-box flex justify-between items-center text-white text-36">
    <div class="settings-box-corners corner-bl" />
    <div class="settings-box-corners corner-tr" />
    <div class="settings-box-text text-left">
      <p
        v-if="title"
        class="settings-box-text-title uppercase"
        :class="$isWsm ? 'text-texts-standard-name' : 'text-texts-standard-important'"
      >
        {{ title }}
      </p>
      <p v-if="description" class="settings-box-text-description">
        {{ description }}
      </p>
    </div>
    <div class="settings-box-controls">
      <slot />
    </div>
  </article>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SettingsBox',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
})
</script>

<style lang="scss" scoped>
.settings-box {
  width: 99%;
  height: 8.5rem;
  position: relative;
  margin: 1rem 0;
  padding: 1.25rem 2rem;
  border: 0.063rem solid transparent;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: -0.063rem;
    left: -0.063rem;
    z-index: 0;
    @if $isWsm {
      box-shadow: inset 0 0 0.625rem 0 rgba(133, 200, 250, 0.3);
      background: rgba(77, 128, 160, 0.5);
      border: 0.063rem solid #5ba3dc;
    }
    @if $isSsm {
      background: rgba(#172341, 0.5);
    }
  }

  &-corners {
    position: absolute;

    &.corner-bl {
      @if $isWsm {
        left: -0.063rem;
        bottom: 0.063rem;
        width: 1.563rem;
        height: 1.563rem;
        background: transparent;
        border-bottom: 0.063rem solid #b5deff;
        border-left: 0.063rem solid #b5deff;
      }
    }

    &.corner-tr {
      @if $isWsm {
        right: 0.063rem;
        top: -0.063rem;
        width: 1.563rem;
        height: 1.563rem;
        background: transparent;
        border-top: 0.063rem solid #b5deff;
        border-right: 0.063rem solid #b5deff;
      }
    }
  }

  &-text {
    position: relative;
    z-index: 1;
  }
}
</style>
