<template>
  <div id="profile-background" class="app-page-wrapper absolute profile-background">
    <section class="w-full h-full safe-area">
      <profile-choose :avatars="backgrounds" choose-type="background" />
    </section>
  </div>
</template>

<script lang="ts">
import ProfileChoose from '@/components/Profile/ProfileChoose.vue'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProfileBackgrounds',
  components: {
    ProfileChoose,
  },
  computed: {
    ...mapState(useUserStore, {
      backgrounds: 'getUserBackgrounds',
    }),
  },
  created(): void {
    this.loadBackgrounds()
  },
  methods: {
    ...mapActions(useUserStore, ['loadBackgrounds']),
  },
})
</script>
