<template>
  <aside
    v-if="skillData"
    class="lab-detail flexing flex-col absolute"
    :class="{ 'lab-detail--mobile': $isMobile() }"
  >
    <header class="flexing flex-col">
      <lab-icons :icon-data="iconData" />

      <div class="lab-detail-texts">
        <p class="text-texts-standard-important text-30">
          {{ $t(`${skillData.name}`) }}
        </p>
        <span class="text-texts-standard-default text-32 flexing">
          <p>
            Level
            <span
              :class="
                Number(skillData.currentLevel) > 0 ? 'text-texts-standard-important font-bold' : ''
              "
              >{{ skillData.currentLevel }}</span
            >
            <span>/{{ skillData.maxLevel }}</span>
          </p>
        </span>
      </div>
    </header>
    <main class="w-full h-full">
      <lab-detail-table :skill-type="skillType" :skill-data="skillData" />
      <div v-if="skillType === SkillType.Camps" class="lab-detail-duration">
        <p
          class="text-texts-standard-default text-28 uppercase"
          :class="$isSsm ? (skillData.estimatedDate ? 'text-texts-standard-important' : '') : ''"
        >
          {{ $t(skillData.estimatedDate ? 'common.timeRemaining' : 'research.timeBonus') }}
        </p>
        <span class="flexing h-full">
          <span class="icon-time mr-4" />
          <p
            class="text-texts-standard-default text-28"
            :class="
              $isSsm
                ? skillData.estimatedDate
                  ? 'text-texts-standard-important font-bold'
                  : 'font-bold'
                : ''
            "
          >
            <vue-countdown
              v-slot="{ days, hours, minutes, seconds }"
              :time="remainingTime"
              :auto-start="skillData.estimatedDate ? true : false"
            >
              {{ formatTime(days, hours, minutes, seconds) }}
            </vue-countdown>
          </p>
        </span>
      </div>
    </main>
    <lab-detail-footer
      :skill-type="skillType"
      :skill-data="skillData"
      @research-cell="startResearch()"
      @activate="$emit('activate')"
      @speedup="$emit('speedup')"
    />
  </aside>
</template>

<script lang="ts">
import LabDetailFooter from '@/components/Lab/LabDetailFooter.vue'
import LabDetailTable from '@/components/Lab/LabDetailTable.vue'
import LabIcons from '@/components/Lab/LabIcons.vue'
import { researchInfoCellEndpoint, MECHANIC_RESEARCH } from '@/globalVariables'
import { formatTime, mapResearchCellData, getEstimatedDateInSeconds } from '@/helpers'
import { defineComponent } from 'vue'

import { SkillType, type Cell, type Icon } from '@/interfaces/lab/Cell'
import type { PropType } from 'vue'
import type { CellEndpoint } from '@/interfaces/responses/lab/LabResponses'

interface ComponentData {
  skillData: Cell
  iconData: Icon
  SkillType: typeof SkillType
}

export default defineComponent({
  name: 'LabDetail',
  components: {
    LabDetailTable,
    LabDetailFooter,
    LabIcons,
  },
  props: {
    skillType: {
      type: String as PropType<SkillType>,
      default: MECHANIC_RESEARCH,
      validator(value: SkillType): boolean {
        return [SkillType.Camps, SkillType.Research].includes(value)
      },
    },
    skillId: {
      type: String,
      required: true,
    },
  },
  emits: ['researchCell', 'activate', 'speedup', 'researchTrees'],
  data(): ComponentData {
    return {
      skillData: null,
      iconData: null,
      SkillType,
    }
  },
  computed: {
    remainingTime(): number {
      if (this.skillData.estimatedDate) {
        return getEstimatedDateInSeconds(this.skillData.estimatedDate) * 1000
      }
      return parseInt(this.skillData.effectDuration.toString()) * 1000
    },
  },
  async created(): Promise<void> {
    await this.getSkillData(this.skillId)
  },
  methods: {
    formatTime,
    async getSkillData(skillId: string = ''): Promise<void> {
      if (skillId) {
        const response = await this.$axios.post<{}, CellEndpoint[]>(researchInfoCellEndpoint, {
          cell_ids: [skillId],
        })

        if (response.length) {
          this.skillData = mapResearchCellData(response[0])
          this.setIconData()
        }
      }
    },
    async startResearch(): Promise<void> {
      await this.$emit('researchCell', this.skillData.id)
    },
    setIconData(): void {
      this.iconData = {
        skillType: this.skillType,
        currentLevel: this.skillData.currentLevel ? Number(this.skillData.currentLevel) : 0,
        maxLevel: Number(this.skillData.maxLevel),
        gemOnly: this.skillData.gemOnly,
        locked: this.skillData.locked,
        isNew: this.skillData.isNew,
        icon: this.skillData.icon,
        isIconRepeatable: this.skillData.isIconRepeatable,
        unlockedAfterLevel: this.skillData.unlockedAfterLevel,
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';

.lab-detail {
  width: 41.25rem;
  height: 100%;
  top: 0;
  right: 0;
  padding: 2.125rem 0 1.25rem;
  box-shadow: 1rem 0px 2.375rem 0.125rem rgba(6, 17, 35, 0.4);
  z-index: 3;

  @if $isSsm {
    background-image: linear-gradient(to right, #1d273a, #373e5d, #1d273a);
  }

  @if $isWsm {
    background-image: linear-gradient(to right, #18334e, #14426c 40%, #14426c 60%, #061123);
  }

  &--mobile {
    width: calc(41.25rem + var(--safe-area-inset-right));
    --safe-area-inset-right: env(safe-area-inset-right);
    padding-right: var(--safe-area-inset-right);
  }

  header {
    .lab-detail-texts {
      margin: 2.063rem 0;
    }
  }

  main {
    margin-bottom: 1rem;

    .lab-detail-duration {
      height: 3.125rem;
      position: relative;
      top: 2.188rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;

      .icon-time {
        width: 2rem;
        height: 2rem;
      }

      @if $isSsm {
        background-color: #b2341b;
        border: solid 0.125rem #ff6344;
      }

      @if $isWsm {
        background: rgba(232, 20, 20, 0.65);
      }
    }
  }
}
</style>
