<template>
  <div class="seasons-end-tooltip flexing">
    <seasons-end-tooltip-season
      :current-ranking-points="Number(currentRankingPoints)"
      :season-end-stats="seasonEndStats"
      :is-previous-season="true"
    />

    <div class="flex flex-col justify-between items-center seasons-end-tooltip-arrows">
      <div class="seasons-end-tooltip-arrows-right arrow-yellow" />
      <div class="seasons-end-tooltip-arrows-content flex justify-between">
        <div class="seasons-end-tooltip-arrows-right arrow-red" />
        <span class="text-texts-standard-danger seasons-end-tooltip-arrows-content-value">
          {{
            `${getArenaLevelDifference !== 0 ? '-' : ''}` +
            $filters.$formatNumber(getArenaLevelDifference)
          }}
        </span>
        <div class="seasons-end-tooltip-arrows-right arrow-red" />
      </div>
      <div class="seasons-end-tooltip-arrows-content flex justify-between">
        <div class="seasons-end-tooltip-arrows-right arrow-red" />
        <span class="text-texts-standard-danger seasons-end-tooltip-arrows-content-value">
          {{
            `${getRankingPointsDifference !== 0 ? '-' : ''}` +
            $filters.$formatNumber(getRankingPointsDifference)
          }}
        </span>
        <div class="seasons-end-tooltip-arrows-right arrow-red" />
      </div>
    </div>

    <seasons-end-tooltip-season
      :current-ranking-points="Number(currentRankingPoints)"
      :season-end-stats="seasonEndStats"
    />
  </div>
</template>

<script lang="ts">
import SeasonsEndTooltipSeason from '@/components/Seasons/SeasonsEndTooltipSeason.vue'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { SeasonEnd } from '@/interfaces/Seasons'

export default defineComponent({
  name: 'SeasonsEndStatsChangeTooltip',
  components: {
    SeasonsEndTooltipSeason,
  },
  props: {
    seasonEndStats: {
      type: Object as PropType<SeasonEnd>,
      required: true,
    },
  },
  computed: {
    ...mapState(useUserStore, {
      currentRankingPoints: 'getRankingPoints',
    }),
    getRankingPointsDifference(): number {
      return Math.abs(this.seasonEndStats.seasonalRp - Number(this.currentRankingPoints))
    },
    getArenaLevelDifference(): number {
      return Math.abs(
        this.seasonEndStats.previousArenaLevel - this.seasonEndStats.currentArenaLevel,
      )
    },
  },
})
</script>

<style lang="scss" scoped>
.seasons-end-tooltip {
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 5.5rem;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);

    @if $isWsm {
      border-left: 0.125rem solid #75b6e7;
    }

    @if $isSsm {
      border-left: 0.125rem solid #4e5c6b;
    }
  }

  &-arrows {
    width: 20rem;
    height: 31rem;

    &-right {
      width: 0;
      height: 0;
      border-top: 1rem solid transparent;
      border-bottom: 1rem solid transparent;
    }

    .arrow-yellow {
      margin-left: 0.5rem;

      @if $isWsm {
        border-left: 1.7rem solid #fadd1e;
      }

      @if $isSsm {
        border-left: 1.7rem solid #feb942;
      }
    }

    .arrow-red {
      @if $isWsm {
        border-left: 1.7rem solid #ff1919;
      }

      @if $isSsm {
        border-left: 1.7rem solid #ff6344;
      }
    }

    &-content {
      width: 70%;
      align-items: center;

      &-value {
        padding-left: 2rem;
        padding-right: 2rem;

        @if $isWsm {
          background-image: linear-gradient(
            90deg,
            rgba(21, 78, 121, 0) 0%,
            rgba(21, 78, 121, 1) 50%,
            rgba(21, 78, 121, 0) 100%
          );
        }

        @if $isSsm {
          background-image: linear-gradient(
            to right,
            transparent,
            rgba(86, 98, 127, 0.9),
            transparent
          );
        }
      }
    }
  }
}
</style>
