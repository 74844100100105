<template>
  <div
    class="league-bg"
    :class="{
      'mt-2': $isSsm,
    }"
    :style="`background-image: url('https://appspowerplaymanager.vshcdn.net/images/winter-sports/events/playoff/rewards-${leagueType}.avif'); width: ${widthSizer};`"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LeagueComponent',
  props: {
    customWidth: {
      type: String,
      default: '',
    },
    full: {
      type: Boolean,
      default: false,
    },
    leagueType: {
      // TODO: Global league type after masters playoff merge.
      type: String,
      required: true,
      validator(propName: string): boolean {
        return ['diamond', 'platinum', 'silver', 'bronze', 'gold'].includes(propName)
      },
    },
  },
  computed: {
    widthSizer(): string {
      if (this.customWidth) return this.customWidth
      if (this.full) return '100%'
      return '31.125rem'
    },
  },
})
</script>

<style lang="scss" scoped>
.league-bg {
  height: 3.1875rem;
  width: 31.125rem;
  @include background(null);
}
</style>
