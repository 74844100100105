<template>
  <div id="club-notice" class="club-notice app-page-wrapper absolute">
    <clubs-managment-menu />
    <section class="w-full h-full safe-area">
      <main>
        <header-info>
          <h2 class="text-texts-standard-default text-28">
            {{ $t('club.noticeHeader') }}
          </h2>
        </header-info>
        <transition name="fade">
          <section v-show="showNotice">
            <div class="club-notice-current">
              <p class="text-texts-standard-default text-32">
                {{ currentMessage }}
              </p>

              <app-control-icon control="delete" class="close-icon" @click="showPopup = true" />
            </div>
          </section>
        </transition>
        <div class="mail-new-message club-notice-wrapper text-left w-full">
          <span class="flex justify-between w-full mb-2">
            <p class="text-texts-standard-default text-30 uppercase">
              {{ $t('club.informationMenu') }}
            </p>
            <p class="text-texts-standard-danger text-30 normal-case">
              {{ `*${$t('bugReport.required')}` }}
            </p>
          </span>
          <app-textarea
            class="club-notice-textarea"
            :message="message"
            :placeholder="$t('post.inputMessage')"
            :max-length="200"
            theme="theme-2"
            @input="setMessage($event)"
          />
        </div>
      </main>
      <footer
        class="flex justify-end club-notice-footer mr-8"
        :class="showNotice ? 'justify-between' : 'justify-end'"
      >
        <app-button
          v-if="showNotice"
          btn-type="danger"
          :btn-text="$t('post.delete')"
          @click="showPopup = true"
        />
        <app-button
          btn-type="secondary"
          :btn-text="$t('post.send')"
          :disabled="!isNoticeOk()"
          @click="sendNotice()"
        />
      </footer>
      <info-popup
        v-if="showPopup"
        :popup-title="$t('club.noticeDelete')"
        @close="showPopup = false"
      >
        <template #description>
          <div class="club-notice-tooltip">
            <p class="text-texts-standard-default text-40">
              {{ $t('club.noticeReallyDelete') }}
            </p>
          </div>
        </template>
        <template #buttons>
          <app-button btn-type="danger" :btn-text="$t('common.confirm')" @click="deleteField()" />
          <app-button btn-type="primary" :btn-text="$t('button.back')" @click="showPopup = false" />
        </template>
      </info-popup>
    </section>
  </div>
</template>

<script lang="ts">
import ClubsManagmentMenu from '@/components/Club/ClubsManagmentMenu.vue'
import AppTextarea from '@/components/GlobalComponents/AppTextarea.vue'
import HeaderInfo from '@/components/Header/HeaderInfo.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { useClubNoticeStore } from '@/store/pinia/clubs/noticeStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  message: string
  currentMessage: string
  showPopup: boolean
}

export default defineComponent({
  name: 'ClubsNotice',
  components: {
    ClubsManagmentMenu,
    HeaderInfo,
    InfoPopup,
    AppTextarea,
  },
  data(): ComponentData {
    return {
      message: '',
      currentMessage: '',
      showPopup: false,
    }
  },
  computed: {
    ...mapState(useClubNoticeStore, {
      getNotice: 'getCurrentNotice',
    }),
    showNotice(): boolean {
      return this.currentMessage && this.currentMessage.length > 0
    },
  },
  async created(): Promise<void> {
    await this.loadNotice()
    // nastavenie aktualne oznamu
    this.currentMessage = this.getNotice.message
  },
  methods: {
    ...mapActions(useClubNoticeStore, ['addNotice', 'deleteNotice', 'loadNotice']),
    async sendNotice(): Promise<void> {
      this.currentMessage = this.message
      this.message = ''
      // pridanie do databazy
      await this.addNotice({
        message: this.currentMessage,
      })
    },
    async deleteField(): Promise<void> {
      this.showPopup = false
      this.currentMessage = ''
      // vymazanie z databazy
      await this.deleteNotice()
    },
    isNoticeOk(): boolean {
      return this.message.length > 0
    },
    setMessage(input: Event): void {
      this.message = (input.target as HTMLInputElement).value
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/mail/mail.scss';

.club-notice {
  main {
    margin: 0.938rem 1.875rem;

    .club-notice-current {
      width: 100%;
      height: 5rem;
      border: solid 0.125rem;
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.75rem;
      text-align: left;
      word-break: break-all;

      @if $isWsm {
        background-color: rgba(35, 155, 125, 0.7);
        border-color: #6c9ec4;
      }

      @if $isSsm {
        background-color: rgba(3, 93, 82, 0.7);
        border-color: #0ee7cc;
      }

      .control-icon-box {
        // Correct scaling factor: Zeplin value / original value
        transform: scale(0.8);
        transform-origin: right;
      }
    }

    .club-notice-wrapper {
      height: 28.25rem;
      margin-top: 1rem;

      .new-message-textarea.is-long {
        height: 20.5rem;
      }

      .club-notice-textarea {
        height: 22rem;
      }

      :deep() {
        .club-notice-textarea .app-textarea-content {
          font-size: 2rem;
        }
      }
    }
  }

  .club-notice-footer {
    margin: 0 2.25rem;
  }
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
