import { CoreHandler } from '../../abstractClasses/coreHandler'
import { isMobile } from '@/plugins/isMobile'
import { ZOOM_IN, ZOOM_OUT, ZOOM_RESET } from '@/map-phaser-new/constants'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { CameraConfigurationResolver } from './cameraConfigurationResolver'
import { currentGame } from '@/globalVariables'

class CameraHandler extends CoreHandler {
  protected game: Phaser.Game
  private isDrag: boolean
  private wasWheelListenerSet: boolean
  private cameraConfigurationResolver: CameraConfigurationResolver
  private safeZone: {
    left: number
    right: number
    bottom: number
  }

  public setUp(game: Phaser.Game): void {
    this.game = game
    this.wasWheelListenerSet = false
    this.safeZone = {
      left: 0,
      right: 0,
      bottom: 0,
    }
  }

  public setUpCamera(mapImage: Phaser.GameObjects.Image, activeScene: Phaser.Scene): void {
    const mainCamera = activeScene.cameras.main
    const sceneInput = activeScene.input

    sceneInput.dragDistanceThreshold = 16
    let dpr: number = window.devicePixelRatio
    if (dpr > 2.7) {
      dpr = 2.7
    }
    const widthDPR: number = Math.round(window.innerWidth * dpr)
    const heightDPR: number = Math.round(window.innerHeight * dpr)

    mainCamera.setViewport(0, 0, widthDPR, heightDPR)
    this.setupSafeZone()

    mainCamera.setBounds(
      0,
      0,
      mapImage.width + (this.safeZone.left + this.safeZone.right) * dpr,
      mapImage.height + this.safeZone.bottom * dpr,
    )
    mainCamera.setRoundPixels(false)
    // CAMERA POSITION IS SET IN buildingHandler ->
    // MAIN_MAP - centered to ARENA
    // CLUBS_MAP - centered to CLUBARENA

    sceneInput.on('pointermove', (inputPointer: Phaser.Input.Pointer) =>
      this.moveCamera(inputPointer, mainCamera),
    )
    this.initZoom(mainCamera, activeScene)
  }

  private setupSafeZone(): void {
    const appElement = document.querySelector('#app')
    const properties = ['left', 'right', 'bottom']

    if (appElement) {
      const appElementStyle = getComputedStyle(appElement)

      properties.forEach((property: string): void => {
        const safeArea =
          parseInt(appElementStyle.getPropertyValue(`--safe-area-inset-${property}`)) || 0
        this.safeZone[property] = safeArea
      })
    }
  }

  public setIsDrag(newValue: boolean): void {
    this.isDrag = newValue
  }

  public isDragging(): boolean {
    return this.isDrag
  }

  public setCameraPostion(activeScene: Phaser.Scene, positionX: number, positionY: number): void {
    activeScene.cameras.main.centerOn(positionX, positionY)
  }

  private moveCamera(input: Phaser.Input.Pointer, mainCamera: Phaser.Cameras.Scene2D.Camera): void {
    if (!input.isDown) return

    if (Phaser.Math.Distance.Between(input.x, input.y, input.downX, input.downY) <= 16) {
      return
    }

    this.setIsDrag(true)
    mainCamera.scrollX -= (input.x - input.prevPosition.x) / mainCamera.zoom
    mainCamera.scrollY -= (input.y - input.prevPosition.y) / mainCamera.zoom
  }

  private initZoom(camera: Phaser.Cameras.Scene2D.Camera, activeScene: Phaser.Scene): void {
    const integrationStore = usePhaserGameIntegrationStore()
    const emitter = integrationStore.getPhaserEventEmitter
    this.cameraConfigurationResolver = new CameraConfigurationResolver(currentGame)

    const currentZoom: number = this.cameraConfigurationResolver.getDefaultZoom()

    integrationStore.setZoomLevel(currentZoom)
    camera.setZoom(currentZoom)
    this.setZoomEvents(camera, activeScene, currentZoom, emitter)
  }

  private setZoomEvents(
    camera: Phaser.Cameras.Scene2D.Camera,
    activeScene: Phaser.Scene,
    currentZoom: number,
    emitter?: Phaser.Events.EventEmitter,
  ): void {
    const minZoom = currentZoom - this.cameraConfigurationResolver.getMinZoom()
    const maxZoom = this.cameraConfigurationResolver.getMaxZoom()

    this.zoomInEvent(activeScene, camera, minZoom, maxZoom, emitter)
    this.zoomOutEvent(activeScene, camera, minZoom, maxZoom, emitter)
    this.zoomResetEvent(activeScene, camera, currentZoom, emitter)
    this.setWheelListener()

    if (isMobile()) {
      this.setPinch(activeScene, camera, minZoom, maxZoom)
    }
  }

  private setWheelListener(): void {
    if (this.wasWheelListenerSet) return

    this.wasWheelListenerSet = true
    window.addEventListener(
      'wheel',
      (event: WheelEvent) => {
        this.mouseWheelHandler(event)
      },
      false,
    )
  }

  private setPinch(
    activeScene: Phaser.Scene,
    camera: Phaser.Cameras.Scene2D.Camera,
    minZoom: number,
    maxZoom: number,
  ): void {
    const pinch = activeScene.rexGestures.add.pinch()
    if (!pinch) return

    if (!pinch.enabled) {
      pinch.setEnable(true)
    }

    const phaserStore = usePhaserGameIntegrationStore()
    pinch.on(
      'pinch',
      () => {
        if (!phaserStore.isZoomInProgress) return

        const scaleFactor = pinch.scaleFactor
        let newZoom: number
        if (camera.zoom * scaleFactor > camera.zoom) {
          newZoom =
            camera.zoom * scaleFactor + this.cameraConfigurationResolver.getZoomChangeValue()
        } else {
          newZoom =
            camera.zoom * scaleFactor - this.cameraConfigurationResolver.getZoomChangeValue()
        }
        const finalZoom = Phaser.Math.Clamp(newZoom, minZoom, maxZoom)
        camera.zoom = finalZoom
        this.setZoom(activeScene, camera, finalZoom)
      },
      activeScene,
    )

    // set zoom in progress for hide UI on map
    pinch.on(
      'pinchstart',
      () => {
        phaserStore.setZoomInProgress(true)
      },
      activeScene,
    )

    // set zoom in progress for show UI on map
    pinch.on(
      'pinchend',
      () => {
        phaserStore.setZoomInProgress(false)
      },
      activeScene,
    )
  }

  private zoomInEvent(
    activeScene: Phaser.Scene,
    camera: Phaser.Cameras.Scene2D.Camera,
    minZoom: number,
    maxZoom: number,
    emitter?: Phaser.Events.EventEmitter,
  ): void {
    emitter?.on(ZOOM_IN, (): void => {
      // TODO
      const newZoom = camera.zoom + this.cameraConfigurationResolver.getZoomChangeValue()
      const finalZoom = Phaser.Math.Clamp(newZoom, minZoom, maxZoom)
      this.setZoom(activeScene, camera, finalZoom)
    })
  }

  private zoomOutEvent(
    activeScene: Phaser.Scene,
    camera: Phaser.Cameras.Scene2D.Camera,
    minZoom: number,
    maxZoom: number,
    emitter?: Phaser.Events.EventEmitter,
  ): void {
    emitter?.on(ZOOM_OUT, (): void => {
      // TODO

      const newZoom = camera.zoom - this.cameraConfigurationResolver.getZoomChangeValue()
      const finalZoom = Phaser.Math.Clamp(newZoom, minZoom, maxZoom)
      this.setZoom(activeScene, camera, finalZoom)
    })
  }

  private zoomResetEvent(
    activeScene: Phaser.Scene,
    camera: Phaser.Cameras.Scene2D.Camera,
    defaultZoom: number,
    emitter?: Phaser.Events.EventEmitter,
  ): void {
    emitter?.on(ZOOM_RESET, (): void => {
      this.setZoom(activeScene, camera, defaultZoom)
    })
  }

  private mouseWheelHandler(event: WheelEvent): void {
    if (event.target instanceof HTMLCanvasElement) {
      const deltaValue = Math.sign(event.deltaY)
      const phaserStore = usePhaserGameIntegrationStore()

      if (deltaValue === -1) phaserStore.setZoomIn()
      if (deltaValue === 1) phaserStore.setZoomOut()
    }
  }

  private setZoom(
    activeScene: Phaser.Scene,
    camera: Phaser.Cameras.Scene2D.Camera,
    zoomLevel: number,
  ): void {
    activeScene.tweens.add({
      targets: camera,
      zoom: zoomLevel,
      duration: 100,
      ease: 'ease',
    })
    usePhaserGameIntegrationStore().setZoomLevel(zoomLevel)
  }
}

// This class have to be singleton because is used by all scenes. We don't want to allow create
// new instances every time is this class needed.
export const cameraHandler = new CameraHandler()
