<template>
  <div
    id="club-profile"
    class="club-profile app-page-wrapper flex flex-col absolute"
    :class="{ scroll: $isMobile() }"
  >
    <menu-component menu-type="club-profile" :title="$t('club.clubProfile')" />
    <section class="club-profile-view w-full h-full safe-area">
      <div class="club-profile-wrapper flex flex-col">
        <clubs-profile-header />

        <div class="club-profile-content flex justify-center items-start">
          <clubs-profile-table />
          <clubs-profile-info />
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import ClubsProfileHeader from '@/components/Club/ClubsProfileHeader.vue'
import ClubsProfileInfo from '@/components/Club/ClubsProfileInfo.vue'
import ClubsProfileTable from '@/components/Club/ClubsProfileTable.vue'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClubsProfile',
  components: {
    ClubsProfileHeader,
    ClubsProfileTable,
    ClubsProfileInfo,
  },
  async created(): Promise<void> {
    const clubId = this.$route.params.clubProfileId?.toString() ?? ''

    await this.loadClubInfo(clubId)
  },
  methods: {
    ...mapActions(useClubStore, {
      loadClubInfo: 'loadClubInfo',
    }),
  },
})
</script>

<style lang="scss" scoped>
.club-profile-view {
  min-height: 0;

  .club-profile-wrapper {
    width: 98%;
    height: 100%;
    margin: 0 auto;
    color: #ffffff;
    padding-bottom: 1.375rem;
    gap: 0.438rem;

    .club-profile-content {
      flex: 1 1 0;
      gap: 1rem;
      min-height: 0;
    }
  }
}

.scroll {
  overflow-y: scroll;
}
</style>
