import { LAB } from '@/globalVariables'
import { StageType } from '@/interfaces/Tutorial'
import type { TutorialStage } from '@/interfaces/Tutorial'

/**
  Tutorial for discipline unlocked
 */
export default class TutorialUnlockResearch {
  stages(): TutorialStage[] {
    return [
      // narative saying you unlocked a new discipline
      {
        id: 78,
        name: 'unlockResearchNarrative',
        type: StageType.Narrative,
        mechanic: 8,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        texts: [],
      },
      // focus on arena building with gsap animation
      {
        id: 79,
        name: 'clickOnResearchBuilding',
        mechanic: 8,
        type: StageType.Focus,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        clientId: [LAB],
        texts: [],
      },
    ]
  }
}
