/**
 * Handler na situaciu startovania appky z backgroundu pre android
 */
export const appStartErrorHandler = (): void => {
  setTimeout(() => handleSleepScenes, 2000)
}

/**
 * Handler funkcia na sleep
 */
// TODO zatial netypujem. preverit po skonceni refaktoru phasera, ci je este potrebne
const handleSleepScenes = (): void => {
  /** Stav totalne prveho zaciatku hry */
  if (!window && !window.game && !window.game.scene && !window.game.scene.scenes) return
  window.game.scene.scenes.forEach((val) => {
    const isSleepingScene = val.scene.isSleeping(val.scene.key)
    if (isSleepingScene) val.scene.wake(val.scene.key)
  })
}
