import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const tunelTrainAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'trainTurnRightBottom2Top',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '01',
      delay: 0,
      duration: 3400,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 1,
        end: 7,
        zeroPad: 2,
      },
    },
  },
]
