<template>
  <main>
    <div class="flex flex-col justify-start items-center club-profile-info">
      <div class="club-profile-info-title flex flex-col items-center justify-center">
        <p class="text-32 uppercase">
          {{ $t('club.clubDescription') }}
        </p>
        <div class="yellow-strip club-profile-info-title-divider" />
      </div>
      <div class="text-28 club-profile-info-description bordered-1">
        {{ clubInfo.description }}
      </div>

      <div class="club-profile-info-title flex flex-col items-center justify-center">
        <p class="text-32 uppercase">
          {{ $t('club.newMembersRequests') }}
        </p>
        <div class="yellow-strip club-profile-info-title-divider" />
      </div>
      <div class="flexing club-profile-info-requests">
        <div class="flex flex-col justify-center items-center club-profile-info-requests-item">
          <div class="flex w-full mb-1">
            <shrink-text
              :text="$t('club.type')"
              :font-size="28"
              container-class-list="flexing"
              text-class-list="uppercase"
              :animated-overflow="true"
            />
          </div>
          <div
            class="flexing p-2 mt-1 skew box-border-3 text-36 uppercase text-texts-standard-important club-profile-info-requests-box"
          >
            <short-text
              :text="typeText"
              :first-cut="19"
              class="revert-skew"
              first-custom="text-24"
            />
          </div>
        </div>
        <div class="flex flex-col justify-center items-center club-profile-info-requests-item">
          <div class="flex w-full mb-1">
            <shrink-text
              :text="$t('club.requestedLevel')"
              :font-size="28"
              container-class-list="flexing"
              text-class-list="uppercase"
              :animated-overflow="true"
            />
          </div>
          <div
            class="flexing p-2 mt-1 skew box-border-3 text-36 uppercase text-texts-standard-important club-profile-info-requests-box"
          >
            <p class="revert-skew">
              {{ `lvl ${clubInfo.memberMinimumLevel}` }}
            </p>
          </div>
        </div>
      </div>
      <section v-if="hasMechanic(MECHANIC_CLUB)" class="w-full h-full flexing">
        <div
          v-if="clubInfo.type === CLUB_TYPES.closed || profileInfo.hasPendingRequests"
          class="flexing footer-text"
        >
          <p class="font-bold uppercase" :class="setFontStyles">
            {{ $t(setAvaiableTexts) }}
          </p>
        </div>
        <div v-else-if="isMyClub" class="club-profile-info-button">
          <app-button
            btn-size="md"
            :btn-type="buttonType"
            :btn-text="$t(buttonText)"
            class="club-profile-info-button-inner"
            @click="leaveOrSettings"
          />
        </div>

        <!-- TODO funkcne bez on-show, zistit naco sa to pridavalo -->
        <!-- :on-show="isDisabled" -->
        <tippy
          v-else
          theme="WSM"
          placement="top"
          class="club-profile-info-button"
          max-width="35rem"
        >
          <app-button
            btn-size="md"
            btn-type="secondary"
            :btn-text="btnText"
            class="club-profile-info-button-inner"
            :disabled="isDisabled"
            @click="joinClub"
          />
          <template #content>
            <div
              v-if="clubInfo.quarantineEndsIn && clubInfo.quarantineEndsIn > 0"
              class="flexing items-center flex-col"
            >
              <p>
                {{ $replacePlaceholder($t('club.clubCooldown'), '%s', 24) }}
              </p>
              <p>{{ $t('common.timeRemaining') }}</p>
              <p class="flex">
                <app-icon class="tooltip-text-icon-time mr-2" :icon-size="32" :icon-name="'time'" />
                <vue-countdown
                  v-slot="{ days, hours, minutes, seconds }"
                  :time="clubInfo.quarantineEndsIn * 1000"
                  :auto-start="true"
                >
                  {{ formatTime(days, hours, minutes, seconds) }}
                </vue-countdown>
              </p>
            </div>
            <div v-else-if="isDisabled">
              <p>
                {{ $t('club.' + getTooltip) }}
              </p>
            </div>
          </template>
        </tippy>
      </section>
      <section v-else class="w-full h-full flexing footer-text">
        <p class="text-texts-standard-danger text-32 font-bold uppercase">
          {{ $t('common.locked') }}
        </p>
      </section>
    </div>
    <clubs-leave-popup v-if="leaveClubPopup" @close="leaveClubPopup = false" />
  </main>
</template>

<script lang="ts">
import ClubsLeavePopup from '@/components/Club/Popups/ClubsLeavePopup.vue'
import ShortText from '@/components/ShortText.vue'
import ShrinkText from '@/components/ShrinkText.vue'
import { CLUB_TYPES, MECHANIC_CLUB, joinClubEndpoint, pathImages } from '@/globalVariables'
import { formatTime } from '@/helpers'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useInvitationStore } from '@/store/pinia/clubs/invitationsStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PopupData } from '@/interfaces/PopupData'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'

interface ComponentData {
  pathImages: typeof pathImages
  MECHANIC_CLUB: typeof MECHANIC_CLUB
  CLUB_TYPES: typeof CLUB_TYPES
  clubId: string
  leaveClubPopup: boolean
  rejoinClubConstant: number
}

export default defineComponent({
  name: 'ClubsProfileInfo',
  components: {
    ShortText,
    ShrinkText,
    ClubsLeavePopup,
  },
  data(): ComponentData {
    return {
      pathImages,
      MECHANIC_CLUB,
      CLUB_TYPES,
      clubId: '',
      leaveClubPopup: false,
      rejoinClubConstant: 24,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      hasClub: 'getHasClub',
      isClubManager: 'isClubManager',
      userData: 'getUserData',
    }),
    ...mapState(useClubStore, {
      clubInfo: 'getClubInfo',
      profileInfo: 'getProfileInfo',
    }),
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
    }),
    typeText(): string {
      const translatedClubTypes = {
        [CLUB_TYPES.open]: this.$t('club.open'),
        [CLUB_TYPES.request]: this.$t('club.onDemand'),
        [CLUB_TYPES.closed]: this.$t('club.close'),
      }
      return translatedClubTypes[this.clubInfo.type]
    },
    setFontStyles(): string {
      // font pre message namiesto buttonu (cakanie na schvalenie ( klub je plny))
      if (this.profileInfo.hasPendingRequests) return 'text-texts-standard-upgrade text-28'
      return 'text-32 not-accept text-texts-standard-danger'
    },
    setAvaiableTexts(): string {
      // texty: cakanie na schvalenie a plny klub
      if (this.profileInfo.hasPendingRequests) return 'club.requestSent'
      return 'club.notAcceptNewMembers'
    },

    isDisabled(): boolean {
      return !this.isFreeSpace || this.hasQuarantine || this.hasClub || this.hasLevel
    },
    hasLevel(): boolean {
      return this.clubInfo.memberMinimumLevel > Number(this.userData.stats.LEVEL)
    },
    btnText(): string {
      if (this.profileInfo.hasPendingInvitation) return this.$t('club.acceptInvitation')
      if (this.clubInfo.type === CLUB_TYPES.request) return this.$t('club.requestMembership')
      return this.$t('club.join')
    },
    isFreeSpace(): boolean {
      return this.clubInfo.membersMaxLength - this.clubInfo.members.length > 0
    },

    isMyClub(): boolean {
      if (!this.hasClub) return false
      return this.clubId === this.userData.club_stats.id
    },

    getTooltip(): string {
      // tooltipy na disablovany button
      if (this.hasClub) return 'anotherClubMember'
      if (!this.isFreeSpace && !this.hasClub) return 'noFreeSpaceInClub'
      if (this.hasLevel) return 'noLevelRequired'
      return 'club' // toto by nemalo nikdy nastat
    },
    hasQuarantine(): boolean {
      // convert to boolean
      return !!this.clubInfo.quarantineEndsIn
    },
    buttonType(): string {
      // vyber typu buttonu ak pozeram profil svojho klubu
      return this.isClubManager ? 'confirm' : 'danger'
    },
    buttonText(): string {
      // vyber textu pre button ak pozeram profil svojho klubu
      return this.isClubManager ? 'club.clubSettings' : 'club.leaveClub'
    },
    getLeaveClubData(): PopupData {
      return this.$filters.$submitPopupData({
        title: this.$t('club.leaveClub'),
        btnText: null,
        btnType: 'danger',
        btnId: 'confirm-leave-club-button',
      })
    },
  },
  async created(): Promise<void> {
    this.clubId = this.$route.params.clubProfileId?.toString() ?? ''
    await this.loadClubInfo(this.clubId)
  },
  methods: {
    formatTime,
    ...mapActions(useUserStore, ['loadUserData']),
    ...mapActions(useInvitationStore, ['acceptInvitation']),
    ...mapActions(useClubStore, {
      setJoinedToClub: 'setJoinedToClub',
      loadClubInfo: 'loadClubInfo',
    }),
    ...mapActions(usePhaserGameIntegrationStore, ['setClubChampionshipLabelInfo']),
    async joinClub(): Promise<void> {
      if (this.profileInfo.hasPendingInvitation) {
        await this.acceptInvitation(this.profileInfo.hasPendingInvitation)
        await this.loadUserData()
        this.setJoinedToClub()
        this.setClubChampionshipLabelInfo()
        this.$router.push({
          name: 'ClubsMap',
          params: { clubId: this.clubInfo.id },
        })
      } else {
        try {
          await this.$axios.post<{}, string>(joinClubEndpoint, {
            club_id: this.clubInfo.id,
          })
        } catch (error: unknown) {
          console.error(error)
        }
        if (this.clubInfo.type === CLUB_TYPES.request) {
          await this.loadClubInfo(this.clubId)
        } else if (this.clubInfo.type === CLUB_TYPES.open) {
          await this.loadUserData()
          this.setJoinedToClub()
          this.setClubChampionshipLabelInfo()
          this.$router.push({
            name: 'ClubsMap',
            params: { clubId: this.clubInfo.id },
          })
        }
      }
    },
    leaveOrSettings(): void {
      // ak som manazer tak club settings inak leave
      if (this.isClubManager) {
        this.$router.push({ name: this.$getWebView('ClubsEditClub') })
      } else {
        this.leaveClubPopup = true
      }
    },
  },
})
</script>

<style lang="scss" scoped>
main {
  width: 33.25rem;

  .club-profile-info {
    &-title {
      width: 100%;
      height: 4rem;

      &-divider {
        margin-top: 0.3rem;
        margin-bottom: 0.2rem;
      }
    }

    &-description {
      width: 100%;
      height: 16.625rem;
      padding: 1.5rem;
      text-align: left;
      margin-top: 0.375rem;
      margin-bottom: 0.5rem;
      @if $isWsm {
        background: #1b2f47;
        border: 0.06875rem solid #5ba3dc;
      }
      @if $isSsm {
        background: #202f53;
        border: 0.06875rem solid #4c648f;
      }
    }

    &-requests {
      margin-top: 1rem;
      width: 100%;
      gap: 1rem;

      &-item {
        flex: 1 0 0;
        min-width: 0;

        :deep(.animated-container) {
          // must have for line height consistency
          padding: 0;
        }
      }

      &-box {
        width: 100%;
        height: 4rem;

        @if $isWsm {
          background: #0f3251;
        }
        @if $isSsm {
          background: #363e5d;
        }
      }
    }

    &-button {
      width: 100%;
      margin-top: 1rem;

      &-inner {
        width: 100%;
      }
    }

    .not-accept {
      margin-top: 2rem;
    }

    .footer-text {
      height: 5rem;
      line-height: 2rem;
      margin-top: 0.75rem;
    }
  }
}
</style>
