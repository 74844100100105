type IsoDate = `${string}T${string}+${string}`

type BoardGamesEventState = 'active' | 'finished' | 'inactive'

export enum BoardGamesEventType {
  IceAndFire = 'ice_and_fire',
  BoardGames = 'board_games',
}

export default interface BoardGamesEventStateApiResponse {
  id: number
  teaser_start_time: IsoDate
  start_time: IsoDate
  end_time: IsoDate
  state: BoardGamesEventState
  event_locked: boolean
  required_mechanic: 'board_games_event'
  user_data: string
  event_type: BoardGamesEventType
}
