<template>
  <div class="p-offers app-page-wrapper flex flex-col absolute">
    <premium-menu />
    <section class="w-full h-full safe-area">
      <main v-if="!isContentLoading" class="p-offers-main w-full flex items-center">
        <app-scrollbar
          class="p-offers-main-scroll flex m-auto"
          width="98%"
          height="46.625rem"
          scroll="x"
          slide="x"
          :arrows="true"
          arrow-pos="arrow-position"
          :scroll-arrows-safe-zone-indented="true"
        >
          <offers-box
            v-for="(weeklyOffer, index) in weeklyOffers?.packs"
            :key="index"
            :offer-data="weeklyOffer"
          />
        </app-scrollbar>
      </main>

      <component-loading class="component-loading" :is-loading="isContentLoading" height="71%" />
    </section>
  </div>
</template>

<script lang="ts">
import OffersBox from '@/components/Premium/Offers/OffersBox.vue'
import PremiumMenu from '@/components/Premium/PremiumMenu.vue'
import { playSound } from '@/helpers'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PremiumWeeklyDeals',
  components: {
    PremiumMenu,
    OffersBox,
  },
  computed: {
    ...mapState(usePremiumStore, {
      weeklyOffers: 'getWeeklyOffers',
    }),
    isContentLoading(): boolean {
      return this.weeklyOffers?.packs?.length === 0
    },
  },
  watch: {
    async '$route.query.reloadData'(): Promise<void> {
      if (!this.$route.query.reloadData) return

      await this.loadWeeklyOffers(true)

      this.$router.replace({
        query: {
          ...this.$route.query,
          reloadData: undefined,
        },
      })
    },
  },
  async created(): Promise<void> {
    playSound('action-special-offer')
    await this.loadWeeklyOffers()
  },
  methods: {
    ...mapActions(usePremiumStore, ['loadWeeklyOffers']),
  },
})
</script>

<style lang="scss" scoped>
.p-offers {
  &-main {
    margin: 2.875rem 0 0 0;
    height: 71%;

    &-scroll {
      display: -webkit-inline-box;
    }
  }
}
</style>
