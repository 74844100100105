<template>
  <section class="clubs-stadium-levels relative">
    <header>
      <p class="text-36 uppercase text-texts-standard-default">
        {{ $t('club.arenaLevels') }}
      </p>
    </header>
    <main class="flexing relative">
      <div
        v-for="(item, index) in stadiumLevelsData"
        :key="index"
        class="clubs-stadium-levels-level flex-col flexing"
        :class="currentLevel > item.level ? 'transparent' : ''"
      >
        <div
          class="clubs-stadium-levels-level-text relative text-30 uppercase flexing"
          :class="
            currentLevel === item.level
              ? 'text-texts-standard-important'
              : 'text-texts-standard-additional'
          "
        >
          <p class="pr-4">
            {{ $replacePlaceholder($t('minigame.level'), '%s', String(item.level)) }}
          </p>
          <app-icon v-if="currentLevel === item.level" icon-name="done-sm" />
        </div>
        <div class="clubs-stadium-levels-level-image" :class="[`stadium-level-${item.level}`]" />
        <div v-if="item.income > 0" class="clubs-stadium-levels-level-income">
          <clubs-periodic-payment-box
            :reward-value="item.income"
            :reward-type="item.rewardType"
            :reward-interval="item.timeInterval"
            :reward-size="28"
            :reward-icon-size="32"
          />
        </div>
        <p
          v-else
          class="text-28 uppercase text-texts-standard-default clubs-stadium-levels-noincome-text"
        >
          {{ $t('club.noIncome') }}
        </p>
      </div>
    </main>
  </section>
</template>

<script lang="ts">
import ClubsPeriodicPaymentBox from '@/components/Club/ClubsPeriodicPaymentBox.vue'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { StadiumLevel } from '@/interfaces/responses/club/ClubBuildingsResponses'

export default defineComponent({
  name: 'ClubsStadiumLevels',
  components: {
    ClubsPeriodicPaymentBox,
  },
  props: {
    stadiumLevelsData: {
      type: Array as PropType<StadiumLevel[]>,
      required: true,
    },
    currentLevel: {
      type: Number,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.clubs-stadium-levels {
  width: 100%;
  height: 100%;

  & header {
    width: 100%;
    height: 4.375rem;
    line-height: 4.375rem;
    @if $isWsm {
      background: linear-gradient(to left, transparent, #144a73, transparent);
    }
    @if $isSsm {
      background: linear-gradient(to left, transparent, rgba(121, 127, 181, 0.99), transparent);
    }
  }

  & main {
    width: 100%;
    height: 22rem;
  }

  &-level {
    width: 18%;
    height: 85%;
    margin: 1rem;

    &.transparent {
      opacity: 0.4;
    }

    &-text {
      top: 1rem;
    }

    &-image {
      width: 100%;
      height: 12.75rem;
      position: relative;
      z-index: 1;

      @for $i from 1 through 5 {
        &.stadium-level-#{$i} {
          background: url($path-clubs + 'buildings/building-clubarena-' + $i + '.avif')
            center
            no-repeat;
          background-size: contain;
        }
      }
    }

    &-income {
      width: 15.95rem;
      height: 3.438rem;
      position: relative;
      top: -1.5rem;
    }
  }

  &-noincome-text {
    position: relative;
    top: -1rem;
  }
}
</style>
