import type Reward from '@/interfaces/Reward'

type RewardKey = keyof Reward

export interface MappedReward extends Pick<Reward, 'type' | 'rarity' | 'category'> {
  value: Reward[RewardKey]
}

export const formatRewards = (
  formattedRewardsArray: Reward[],
  switchValueName: RewardKey,
  rewardValuePropertyName: RewardKey,
): MappedReward[] => {
  return (
    formattedRewardsArray.map((reward: Reward): MappedReward => {
      let consumed: string
      switch (reward[switchValueName]) {
        case 'wildCard':
          consumed = 'wild_card'
          break
        case 'energyDrink':
          consumed = 'energy_drink'
          break
        case 'grand_prize':
          consumed = 'gp-' + reward.rarity
          break
        case 'PowerBoost':
          consumed = 'powerboost-' + reward.level
          break
        case 'ProTraining':
          consumed = 'protraining-' + reward.level
          break
        case 'Personal Sponsor':
          consumed = 'personal-' + reward.level
          break
        case 'benefit':
          consumed = 'benefit-' + reward.level
          break
        default:
          consumed = String(reward[switchValueName])
          break
      }

      return {
        type: consumed,
        value: reward[rewardValuePropertyName],
        rarity: reward.rarity ?? null,
        category: reward.category ?? '',
      }
    }) ?? []
  )
}
