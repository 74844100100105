<template>
  <div class="popup-wrapper">
    <info-text :text="$t(getInfoText)" />
    <app-scrollbar
      class="club-employees-boxes flex items-center"
      width="100%"
      height="43rem"
      scroll="x"
      slide="x"
    >
      <clubs-employees-box
        v-for="(employee, i) in getEmployeesData"
        :key="i"
        :employee="employee"
        :hide-title="true"
        @hire="$emit('close')"
      />
    </app-scrollbar>
  </div>
</template>

<script lang="ts">
import ClubsEmployeesBox from '@/components/Club/ClubsEmployeesBox.vue'
import InfoText from '@/components/InfoText.vue'
import { useEmployeeState } from '@/store/pinia/clubs/employeeStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { Employee } from '@/interfaces/clubs/Employees'
export default defineComponent({
  name: 'ClubsHireEmployeePopup',
  components: {
    InfoText,
    ClubsEmployeesBox,
  },
  props: {
    employeeType: {
      type: String,
      required: true,
      validator(value: string): boolean {
        return ['personal', 'club'].includes(value)
      },
    },
  },
  emits: ['close'],
  computed: {
    ...mapState(useEmployeeState, {
      getEmployees: 'getEmployees',
    }),
    getInfoText(): string {
      if (this.employeeType === 'personal') return 'club.clubHireEmployeeDes1'
      else return 'club.clubHireEmployeeDes2'
    },
    getEmployeesData(): Employee[] {
      const filteredEmployees = this.getEmployees.filter(
        (employee: Employee): boolean =>
          employee.employeeType === this.employeeType && !employee.isHired,
      )

      return filteredEmployees
    },
  },
})
</script>

<style lang="scss" scoped>
.popup-wrapper {
  width: 99.313rem;
  height: 51.5rem;
  padding: 1.5rem;

  .club-employees-boxes {
    gap: 1.438rem;

    .clubs-employees-box {
      height: 39.625rem;
      width: 31.25rem;

      :deep(.clubs-employees-box-header) {
        padding: 1.125rem 0 0;
      }
    }
  }
}
</style>
