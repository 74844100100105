<template>
  <info-popup
    v-if="isVisible"
    :popup-title="$te('aboutHeader')"
    width="76.125rem"
    height="46.625rem"
    @close="$emit('close')"
  >
    <section class="discipline-event-about justify-center">
      <app-scrollbar width="100%" height="100%" scroll="y" slice="y">
        <ul class="discipline-event-about-list text-32 text-texts-standard-default">
          <li v-for="(section, index) in sections" :key="index">
            {{ aboutText(section) }}
          </li>
        </ul>
      </app-scrollbar>
    </section>
  </info-popup>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  sections: number
}

export default defineComponent({
  name: 'DisciplineEventAboutPopup',
  components: {
    InfoPopup,
  },
  props: {
    isVisible: {
      type: Boolean,
      defualt: false,
    },
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      sections: 6,
    }
  },
  computed: {
    ...mapState(useDisciplineStore, ['getDisciplineUnlockLevel']),
    ...mapState(useEventInfoStore, {
      eventDisciplineId: 'getEventDisciplineId',
    }),
  },
  methods: {
    aboutText(section: number): string {
      let text: string = this.$te(`aboutText${section}`)

      if (text.includes('{discipline}')) {
        text = this.$replacePlaceholder(
          text,
          '{discipline}',
          this.$t(`discipline.discipline_${this.eventDisciplineId}`),
        )
      }

      if (text.includes('{level}')) {
        text = this.$replacePlaceholder(
          text,
          '{level}',
          this.getDisciplineUnlockLevel(this.eventDisciplineId).toString(),
        )
      }

      return text
    },
  },
})
</script>

<style lang="scss" scoped>
.discipline-event-about {
  height: 41.5rem;
  flex-direction: column;
  padding-top: 2.125rem;

  &-list {
    padding-left: 3.2rem;
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    text-align: left;

    li {
      list-style-type: square;
      margin-bottom: 1.5rem;

      &::marker {
        color: #a5e5ff;
      }
    }
  }
}
</style>
