<template>
  <div
    v-if="userData && userData.season && userData.season.seasonMonth && userData.season.seasonYear"
    class="season-box absolute cursor-pointer flex items-center clickable-element"
    @click="goToSeasonPage"
  >
    <div class="season-box-left justify-center items-center">
      <div
        class="season-box-left-up flex items-center justify-center text-texts-standard-important"
      >
        {{ userData.season.seasonMonth }}
      </div>
      <div
        class="season-box-left-down flex items-center justify-center text-texts-standard-additional"
      >
        {{ userData.season.seasonYear }}
      </div>
    </div>
    <div class="season-box-right flex items-center justify-center">
      <div
        class="season-box-right-number pl-2 text-texts-standard-default justify-center items-center flex italic text-36"
        :class="{ 'text-30': parseInt(userRankingPoints.toString()) >= 1000000 }"
      >
        {{ $filters.$formatNumber(parseInt(userRankingPoints.toString())) }}
      </div>
      <app-main-icon :icon-size="56" icon-name="ranking_points" class="item-center" />
    </div>
  </div>
</template>

<script lang="ts">
import { CURRENT_SEASON } from '@/globalVariables'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SeasonBox',
  computed: {
    ...mapState(useUserStore, {
      userRankingPoints: 'getRankingPoints',
      userData: 'getUserData',
    }),
  },
  methods: {
    goToSeasonPage(): void {
      this.$router.push({
        name: this.$getWebView('Seasons'),
        params: { type: CURRENT_SEASON },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.season-box {
  background: url($path-images + 'components/rightbox/BADGE_SEASON.avif') center no-repeat;
  background-size: contain;
  width: 20.1875rem;
  height: 6.9375rem;
  top: 6rem;
  right: $default-margin;

  &-left {
    width: 6.8rem;
    font-size: 1.875rem;
    height: 4.8rem;

    &-up {
      height: 2rem;
      width: 2rem;
      margin: auto;
      padding-top: 0.2rem;
    }

    &-down {
      margin: auto;
      height: 2rem;
      margin-top: 0.75rem;
      width: 2rem;
      padding-left: 0.2rem;
    }
  }

  &-right {
    height: 4rem;

    @if $isWsm {
      width: 13rem;
    }

    @if $isSsm {
      width: 13.5rem;
    }

    &-number {
      height: 100%;
      text-shadow: 0.643px 0.766px 0.93px rgba(0, 0, 0, 0.71);
    }
  }
}
</style>
