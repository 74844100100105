import {
  GAME_LIVE,
  LAYOUT_VIEW_ROUTE_NAME,
  MAINTENANCE_ROUTE_NAME,
  gameStateEndpoint,
} from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { loadTextsFromSessionStorage } from '@/plugins/getTexts'
import router from '@/router'
import { defineStore } from 'pinia'
import { useTextsStore } from './textStore'
import { useUserStore } from '@/store/pinia/userStore'

export type GameState = 'live' | 'maintenance' | 'updating'

export interface GameStateInterface {
  gameState: GameState
  gameReadyAt: string
}

export interface GameStateResponse {
  state: GameState
  readyAt: string
}

export const useGameStateStore = defineStore('GameStateStore', {
  state: (): GameStateInterface => ({
    gameState: 'live',
    gameReadyAt: '',
  }),
  getters: {
    getGameState(): GameState {
      return this.gameState
    },
    getGameReadyAt(): string {
      return this.gameReadyAt
    },
  },
  actions: {
    setGameState(data?: GameState): void {
      if (!data) return

      this.gameState = data
    },
    setGameReadyAt(data?: GameState): void {
      if (!data) return

      this.gameReadyAt = data
    },
    async loadGameState(): Promise<void> {
      const status = await internalAxios.get<{}, GameStateResponse>(gameStateEndpoint)

      this.gameReadyAt = status.readyAt
      this.gameState = status.state

      if (status.state !== GAME_LIVE && router.currentRoute.value.name !== MAINTENANCE_ROUTE_NAME) {
        router.push({
          name: MAINTENANCE_ROUTE_NAME,
        })
      }

      if (status.state === GAME_LIVE && router.currentRoute.value.name === MAINTENANCE_ROUTE_NAME) {
        const textStore = useTextsStore()
        textStore.setLoadedTexts(false)
        sessionStorage.removeItem('texts')
        if (useUserStore().getHasToken) {
          await useUserStore().loadUserData()
        }
        await loadTextsFromSessionStorage()

        router.push({
          name: LAYOUT_VIEW_ROUTE_NAME,
        })
      }
    },
  },
})
