<template>
  <info-popup
    v-if="isVisible"
    :popup-title="$te('frozenLab')"
    width="76.125rem"
    height="46.625rem"
    @close="$emit('close')"
  >
    <section class="warehouse-about justify-center">
      <app-scrollbar width="100%" height="100%" scroll="y" slice="y">
        <div v-if="getEventEnd && getExchangeDateEnd" class="warehouse-about-title">
          <p class="text-50 gradient-text-gold uppercase">{{ $te('frozenLab') }}</p>
        </div>
        <ul v-if="getEventEnd && getExchangeDateEnd" class="warehouse-about-text text-34">
          <li>{{ $te('frozenInfoText1') }}</li>
          <li>{{ $te('frozenInfoText2') }}</li>
          <li>{{ $te('frozenInfoText3') }}</li>
          <li>
            {{
              $replacePlaceholder(
                $te('frozenInfoText4'),
                '{datetime}',
                `${getFormattedDateFromYMD(getEventEnd)} ${getFormattedTimeFromHMS(getEventEnd)}`,
              )
            }}
          </li>
          <li>
            {{
              $replacePlaceholder(
                $te('frozenInfoText5'),
                '{datetime}',
                `${getFormattedDateFromYMD(getExchangeDateEnd)} ${getFormattedTimeFromHMS(
                  getExchangeDateEnd,
                )}`,
              )
            }}
          </li>
        </ul>

        <div v-if="getExchangeDateEnd" class="warehouse-about-title">
          <p class="text-50 gradient-text-gold uppercase">{{ $te('workshop') }}</p>
        </div>
        <ul v-if="getExchangeDateEnd" class="warehouse-about-text text-34">
          <li>{{ $te('frozenInfoText9') }}</li>
          <li>{{ $te('frozenInfoText10') }}</li>
          <li>
            {{
              $replacePlaceholder(
                $te('frozenInfoText11'),
                '{datetime}',
                `${getFormattedDateFromYMD(getExchangeDateEnd)} ${getFormattedTimeFromHMS(
                  getExchangeDateEnd,
                )}`,
              )
            }}
          </li>
        </ul>

        <div v-if="getLeagueConfig" class="warehouse-about-title">
          <p class="text-50 gradient-text-gold uppercase">{{ $te('frozenLeague') }}</p>
        </div>
        <ul v-if="getLeagueConfig" class="warehouse-about-text text-34">
          <li>
            {{
              $replacePlaceholder(
                $te('frozenInfoText6'),
                '{2to4}',
                getLeagueConfig.round_length.toString(),
              )
            }}
          </li>
          <li>{{ $te('frozenInfoText7') }}</li>
          <li>{{ $te('frozenInfoText8') }}</li>
        </ul>
      </app-scrollbar>
    </section>
  </info-popup>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { getFormattedDateFromYMD, getFormattedTimeFromHMS } from '@/helpers'
import { useLeagueEventStore } from '@/store/pinia/events/leagueStore'
import { useWarehouseStore } from '@/store/pinia/events/warehouseStore'
import { mapState, mapActions } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WarehouseAboutPopup',
  components: {
    InfoPopup,
  },
  props: {
    isVisible: {
      type: Boolean,
      defualt: false,
    },
  },
  emits: ['close'],
  computed: {
    ...mapState(useLeagueEventStore, {
      getLeagueConfig: 'getConfig',
    }),
    ...mapState(useWarehouseStore, {
      getExchangeDateEnd: 'getExchangeDateEnd',
      getEventEnd: 'getEventEnd',
      getWarehouseConfig: 'getConfig',
    }),
  },
  async created(): Promise<void> {
    if (this.getWarehouseConfig === null) await this.loadWarehouseConfig()
    if (this.getLeagueConfig === null) await this.loadLeagueConfig()
  },
  methods: {
    ...mapActions(useLeagueEventStore, {
      loadLeagueConfig: 'loadConfig',
    }),
    ...mapActions(useWarehouseStore, {
      loadWarehouseConfig: 'loadConfig',
    }),
    getFormattedDateFromYMD,
    getFormattedTimeFromHMS,
  },
})
</script>

<style lang="scss" scoped>
.warehouse-about {
  height: 41.5rem;

  &-info {
    height: 7.75rem;
    background: #0b1c30;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem;
  }

  &-title {
    background: linear-gradient(
      90deg,
      rgba(11, 28, 47, 0.1) 0%,
      rgba(11, 28, 47, 0.5) 25%,
      rgba(11, 28, 47, 0.5) 75%,
      rgba(11, 28, 47, 0.1) 100%
    );
    border-style: solid;
    border-width: 0.188rem;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      90deg,
      rgba(49, 80, 107, 0.1) 0%,
      rgba(49, 80, 107, 0.5) 25%,
      rgba(49, 80, 107, 0.5) 75%,
      rgba(49, 80, 107, 0.1) 100%
    );
    padding: 0.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  &-text {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    text-align: left;
    padding-left: 5rem;
    margin-top: 1.75rem;
    width: 100%;

    li {
      list-style-type: none;
      color: white;
      line-height: 1.5;
      font-stretch: condensed;
      font-weight: 400;
      margin-bottom: 1.5rem;

      @if $isWsm {
        font-style: italic;
      }

      &::before {
        content: '';
        width: 0.3em;
        height: 0.3em;
        border-radius: 50%;
        background-color: #fadd1e;
        float: left;
        line-height: 1em;
        margin: 0.5em 0.3em 0;
        margin-left: -1em;
      }
    }
  }
}
</style>
