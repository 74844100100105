<template>
  <header
    class="milestones-header relative flex items-center justify-between px-8 pt-2"
    :data-event-theme="namespace"
  >
    <event-pass-button :pass="PassType.Champion" />

    <div class="flexing space-x-4">
      <app-button
        v-for="(button, i) in buttons"
        :key="i"
        :btn-type="button === activeButton ? 'secondary' : 'inactive'"
        :btn-text="
          $te(button === MilestoneMainComponent.Personal ? 'yourMilestone' : 'clubMilestone')
        "
        btn-size="md"
        @click="setMilestonesSection(button)"
      />
    </div>

    <app-button
      btn-type="primary"
      :btn-text="$te('aboutMilestone')"
      btn-size="md"
      @click="$emit('showPopup')"
    />
  </header>
</template>

<script lang="ts">
import { MilestoneMainComponent } from '@/interfaces/events/Milestones'
import { PassType } from '@/interfaces/events/Passes'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import EventPassButton from '../GlobalEventsComponents/EventPassButton.vue'

interface ComponentData {
  MilestoneMainComponent: typeof MilestoneMainComponent
  PassType: typeof PassType
  activeButton: MilestoneMainComponent
  buttons: MilestoneMainComponent[]
}

export default defineComponent({
  name: 'MilestonesHeader',
  components: {
    EventPassButton,
  },
  emits: ['setSection', 'showPopup'],
  data(): ComponentData {
    return {
      MilestoneMainComponent,
      PassType,
      activeButton: MilestoneMainComponent.Personal,
      buttons: [MilestoneMainComponent.Personal, MilestoneMainComponent.Club],
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
  },
  methods: {
    setMilestonesSection(component: MilestoneMainComponent): void {
      this.activeButton = component
      this.$emit('setSection', { component })
    },
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='frozen'] {
  @if $isWsm {
    --events-chains-header-bg: rgba(0, 5, 25, 0.33);
  }

  @if $isSsm {
    --events-chains-header-bg: rgba(25, 27, 72, 0.6);
  }
}

.milestones-header {
  height: 7.625rem;
  background-color: var(--events-chains-header-bg);
}
</style>
