export enum TournamentState {
  Joined = 'joined',
  Running = 'running',
}

export interface TournamentsActiveStateApiResponse {
  discipline_id: number
  ends_in?: number
  starts_in?: number
  name: string
  position: number
  state: TournamentState
  tournament_id: number
}
