<template>
  <main class="rules-content text-left" :class="{ 'rules-content-homepage': homepage }">
    <app-scrollbar width="100%" height="95%" scroll="y" slide="y">
      <section>
        <h2
          class="uppercase text-36 text-texts-standard-important"
          v-html="$replacePlaceholder($t('info.globalRules'), '%s', PROJECT_NAME)"
        />
        <hr />
        <ul class="text-texts-standard-default text-30 list-disc list-inside">
          <li v-for="row in globalRules" :key="row">
            <p>{{ $t('info.' + row) }}</p>
          </li>
        </ul>
      </section>
      <section>
        <h2 class="uppercase text-36 text-texts-standard-important">
          {{ $t('info.forbidden') }}
        </h2>
        <hr />
        <ul class="text-texts-standard-default text-30 list-disc list-inside">
          <li v-for="row in forbiddenRules" :key="row">
            <p>{{ $t('info.' + row) }}</p>
          </li>
          <li>
            <p
              v-html="
                $replacePlaceholder(
                  $t('info.propagation'),
                  '%s',
                  `<a href='mailto:${supportMail}' target='_blank' class='text-texts-standard-name'>${supportMail} </a>`,
                )
              "
            />
          </li>
        </ul>
      </section>
    </app-scrollbar>
  </main>
</template>

<script lang="ts">
import { PROJECT_NAME, supportMail } from '@/globalVariables'
import { defineComponent } from 'vue'

interface ComponentData {
  PROJECT_NAME: typeof PROJECT_NAME
  supportMail: typeof supportMail
}

export default defineComponent({
  name: 'RulesContent',
  props: {
    homepage: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      PROJECT_NAME,
      supportMail,
    }
  },
  computed: {
    globalRules(): string[] {
      return [
        'forAll',
        'ignoranceRules',
        'adminRules',
        'adminPunishment',
        'blockAccount',
        'individualJudgement',
        'slovakVersion',
      ]
    },
    forbiddenRules(): string[] {
      return [
        'signToAnotherAccount',
        'cheating',
        'abuse',
        'searchWeb',
        'useInformation',
        'spam',
        'sendBad',
        'writeBad',
        'publishBad',
        'rulePlayers',
        'ordinarily',
        'noAuthorization',
        'outragePlayers',
        'propagationWeb',
      ]
    },
  },
})
</script>

<style lang="scss" scoped>
.rules-content {
  height: 78%;
  width: 113.75rem;
  margin: 4.313rem auto 0 auto;

  h1 {
    margin-bottom: 1.1rem;
  }

  hr {
    width: 83.5rem;
    margin-bottom: 1rem;
    @if $isWsm {
      border-top: 0.063rem solid #438eb8;
    }
    @if $isSsm {
      border-top: 0.063rem solid #fff;
      opacity: 0.3;
    }
  }

  ul {
    li {
      p {
        display: inline-flex;
        max-width: 110rem;
        position: relative;
        left: -1rem;
      }

      margin-bottom: 1rem;
    }
  }

  section {
    margin-bottom: 2.5rem;
  }

  &-homepage {
    width: 99%;
    height: 100%;
    margin-top: 1rem;

    ul {
      li {
        p {
          max-width: 90%;
          display: contents;
        }
      }
    }
  }
}
</style>
