import { BUILDING_LOCKED_TOOLTIP_POSITION } from '@/map-phaser-new/constants'
import type { BuildingConfigInterface } from '@/map-phaser-new/interfaces'

export const restaurantConfig: BuildingConfigInterface = {
  textureKey: 'clubsBuildings',
  frameKey: 'restaurant',
  routeConfig: {
    routeName: 'ClubsRestaurant',
    soundName: 'click-map-artefact',
    isRouteParamIdNeeded: false,
  },
  positionX: 502.25,
  positionY: 720.25,
  depth: 2,
  originX: 0,
  keyForTranslateTitle: 'club.restaurant',
  buildingTitleConfig: {
    positionX: 688.5,
    positionY: 960.5,
    depth: 5,
    textConfig: {
      originX: 0.5,
      fontName: 'Roboto Condensed',
      fontSize: '24px',
      fontWeight: 'normal',
      fontStyle: 'italic',
      fontColor: '#fff',
    },
    backgroundImageConfig: {
      textureName: 'common',
      frameNameRightSide: 'right_side',
      frameNameRightSideLocked: 'right_side_locked',
      originX: 0.5,
      widthCorrection: 100,
      heightCorrection: 10,
    },
  },
  outlineConfig: {
    framePostFix: '_outline',
    depth: 2,
    originX: 0,
    positionByLevel: {
      '1': { positionX: 502.8040618204753, positionY: 719.999994993211 },
    },
    textureKey: 'clubsBuildingsOutlines',
  },
  inputConfig: {
    useHandCursor: true,
  },
  buildingLockedConfig: {
    textureKey: 'clubsLockedBuildings',
    framePostfix: '_locked',
    lockImageTextureKey: 'lock',
    positionX: 667.5,
    positionY: 828,
    lockImageDepth: 2,
    depth: 6,
    tooltipConfig: {
      tooltipPosition: BUILDING_LOCKED_TOOLTIP_POSITION.Top,
      tooltipTextureHeight: '213px',
      tooltipTextureWidth: '444px',
      depth: 6,
      titleConfig: {
        originX: 0.5,
        fontSize: '30px',
        fontName: 'Roboto Condensed',
        fontWeight: 'normal',
        fontStyle: 'italic',
        fontColor: '#fff',
      },
      subtitleConfig: {
        originX: 0.5,
        originY: 0.5,
        fontName: 'Roboto Condensed',
        fontSize: '24px',
        fontWeight: 'normal',
        fontStyle: 'italic',
        fontColor: '#fff',
        replaceFontColor: '#fec742',
        tooltipSubtitleTranslateKey: 'club.unlockLevel',
        tooltipSubtitleReplaceFlag: '%s',
        tooltipSubtitleReplaceTags: { start: '{b}', end: '{/b}' },
      },
      tooltipHideDelay: 2000,
      tooltipLeftTextureName: 'building_tooltip_left',
      tooltipRightTextureName: 'building_tooltip_right',
      tooltipTopTextureName: 'building_tooltip_top',
    },
  },
}
