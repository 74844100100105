<template>
  <div v-if="dailyTaskContributions" class="club-house-table bordered-1">
    <ranking-table-head :ranking-type="'clubActivity'" :is-club="true" />
    <app-scrollbar class="ranking-global-scrollbar" width="100%" height="100%" scroll="y" slide="y">
      <ranking-row-table :rows="getDailyTaskContributors" table="clubActivity" width="100%" />
    </app-scrollbar>
  </div>
</template>

<script lang="ts">
import RankingRowTable from '@/components/Rankings/RankingRowTable.vue'
import RankingTableHead from '@/components/Rankings/RankingTableHead.vue'
import { useClubsDailyTasks } from '@/store/pinia/clubs/clubsDailyTasks'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { ClubDailyTasksContributor } from '@/interfaces/clubs/ClubDailyTasks'
import type { EventBadgeInterface } from '@/interfaces/EventBadgeInterface'

interface ClubContributorData {
  name: string
  country: string
  points: number
  type: string
  eventBadges: EventBadgeInterface[]
}

export default defineComponent({
  name: 'ClubsHouseTable',
  components: {
    RankingTableHead,
    RankingRowTable,
  },
  computed: {
    ...mapState(useClubsDailyTasks, {
      dailyTaskContributions: 'getClubDailyTaskContributions',
    }),
    getDailyTaskContributors(): ClubContributorData[] {
      return this.dailyTaskContributions.map(
        (contributor: ClubDailyTasksContributor): ClubContributorData => {
          return {
            name: contributor.username,
            country: contributor.country.three_letter_code,
            points: contributor.value,
            type: contributor.type,
            eventBadges: contributor.eventBadges,
          }
        },
      )
    },
  },
  async created(): Promise<void> {
    await this.loadClubDailyTaskContributions()
  },
  methods: {
    ...mapActions(useClubsDailyTasks, {
      loadClubDailyTaskContributions: 'loadClubDailyTaskContributions',
    }),
  },
})
</script>

<style lang="scss" scoped>
.club-house-table {
  width: 99%;
  border: 0.125rem solid #5ba3dc;

  :deep() {
    // removal of redundant scrollbar due to height 100%
    .y-scroll {
      overflow: hidden;
    }
  }
}
</style>
