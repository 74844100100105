<template>
  <div
    class="staroftheday-podium-rank"
    :class="[
      `staroftheday-${viewType}-podium-rank`,
      `rank-${rank}`,
      rank === 2 ? 'order-first' : '',
    ]"
  >
    <div class="rank-label flex flex-col">
      <span class="flexing">
        <app-user
          :id="userId || ''"
          :country="country"
          :name="userName"
          :is-bot="isBot"
          no-country-text
          dark-text
          bold-name
          :badges-data="eventBadges ?? null"
        />
      </span>
      <div class="ranking-points flex items-center self-end">
        <div class="w-full flex justify-between items-baseline">
          <span class="points-text">{{ $t(getPointsText()) }}:</span>
          <span class="points">{{ points }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { EventBadgeInterface } from '@/interfaces/EventBadgeInterface'
import type { Nullable } from '@/interfaces/utils'
import { DailyLeagueViewType } from '@/interfaces/DailyLeague'

export default defineComponent({
  name: 'StarofthedayPodiumRank',
  components: {
    AppUser,
  },
  props: {
    viewType: {
      type: String as PropType<DailyLeagueViewType>,
      default: DailyLeagueViewType.Standings,
      validator(value: DailyLeagueViewType): boolean {
        return [DailyLeagueViewType.Athlete, DailyLeagueViewType.Standings].includes(value)
      },
    },
    rank: {
      type: Number,
      default: 1,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    isBot: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      required: true,
    },
    userName: {
      type: String,
      required: true,
    },
    points: {
      type: [Number, String],
      default: 0,
    },
    eventBadges: {
      type: Array as PropType<Nullable<EventBadgeInterface[]>>,
      default: null,
    },
  },
  methods: {
    getPointsText(): string {
      return this.viewType === 'standings' ? 'rankingsGroup.points' : 'rankingsGroup.rankingPoints'
    },
  },
})
</script>

<style lang="scss" scoped>
.staroftheday-podium-rank {
  position: relative;

  .rank-label {
    position: absolute;
    right: 1.125rem;
    bottom: 0.875rem;

    .ranking-points {
      width: 20.625rem;
      height: 4.0625rem;
      margin: 1.25rem 0 0;
      padding: 1.25rem 1.625rem 0.9375rem 1.5625rem;
      border-width: 0.125rem;
      border-image-slice: 1;
      transform: $skewX-value;
      color: #fff;
      box-shadow:
        inset -0.438rem 0px 1.313rem 0 rgba(51, 123, 214, 0.19),
        inset -0.019rem -0.188rem 2.5rem 0 rgba(6, 34, 55, 0.48);
      @if $isWsm {
        background-color: #0f3251;
        border-image-source: linear-gradient(to top, #fff284, #f6c717);
        font-style: italic;
      }
      @if $isSsm {
        background-color: #363e5d;
        border-image-source: linear-gradient(to top, #fff284, #f6c717);
      }
      .points {
        font-size: 2.25rem;
        font-weight: bold;
      }

      .points-text {
        font-size: 1.75rem;
      }
    }

    .name-text {
      font-style: italic;
      color: #233441;
      font-size: 2rem;
      font-weight: bold;
    }
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: top;
    transform: translate(0, -50%);
  }
}

.rank-1 {
  width: 41.25rem;
  height: 14.375rem;
  background: url($path-images + 'staroftheday/BG_GOLD.avif') center no-repeat;
  margin: 0 1.125rem;
  background-size: cover;

  &::before {
    width: 16.6875rem;
    height: 18.5rem;
    background: url($path-images + 'staroftheday/TROPHY_GOLD.avif') center no-repeat;
    background-size: contain;
    left: 1.3125rem;
  }

  &::after {
    width: 2.4375rem;
    height: 5.75rem;
    background: url($path-images + 'staroftheday/NUMBER_GOLD.avif') center no-repeat;
    background-size: contain;
    right: 10.25rem;
  }
}

.rank-2 {
  width: 36.25rem;
  height: 11.875rem;
  background: url($path-images + 'staroftheday/BG_SILVER.avif') center no-repeat;
  background-size: cover;

  &::before {
    width: 13.25rem;
    height: 16.375rem;
    background: url($path-images + 'staroftheday/TROPHY_SILVER.avif') center no-repeat;
    background-size: contain;
    left: 0.4375rem;
  }

  &::after {
    width: 3.5625rem;
    height: 4.4375rem;
    background: url($path-images + 'staroftheday/NUMBER_SILVER.avif') center no-repeat;
    background-size: contain;
    right: 9.625rem;
  }
}

.rank-3 {
  width: 36.25rem;
  height: 11.875rem;
  background: url($path-images + 'staroftheday/BG_BRONZE.avif') center no-repeat;
  background-size: cover;

  &::before {
    width: 13.25rem;
    height: 16.375rem;
    background: url($path-images + 'staroftheday/TROPHY_BRONZE.avif') center no-repeat;
    background-size: contain;
    left: 0.4375rem;
  }

  &::after {
    width: 5.3125rem;
    height: 4.4375rem;
    background: url($path-images + 'staroftheday/NUMBER_BRONZE.avif') center no-repeat;
    background-size: contain;
    right: 9.375rem;
  }
}
</style>
