<template>
  <div class="circle-wrapper">
    <tippy theme="WSM1" placement="right" max-width="68.75rem">
      <div
        :class="[
          `circle-item circle-item--${item.level} circle-item-${item.discipline_id}-${item.item_category}-${item.level}`,
          { 'item-none': !item.item_category },
        ]"
      >
        <div class="inner-circle-item flexing">
          <equipment-item-attributes-icons
            :icon-type="item.icon"
            :discipline-id="item.discipline_id"
          />
        </div>
      </div>

      <template #content>
        <div class="relative attribute-bonus-tooltip__wrapper flex flex-col">
          <div class="attribute-bonus-tooltip__header flexing">
            <p class="text-texts-standard-default text-36 font-bold">
              {{ $t('equipmentShop.attributeBonus') }}
            </p>
          </div>

          <div class="attribute-bonus-tooltip__bonus-subheader flexing">
            <div class="attribute-bonus-tooltip__bonus-container flex items-center justify-between">
              <div class="bonus-container__item flexing flex-col">
                <p class="text-28">{{ $t('equipmentShop.basicEquipment') }}</p>

                <div class="discipline-box-value flexing">
                  <p class="text-texts-standard-default text-36 font-bold attributeText">
                    {{ getValue(item.attributes.basic_equipment.value) }}
                  </p>
                  <div
                    v-if="item.icon === 'discipline'"
                    class="attribute-icon--32 m-2.5"
                    :class="'discipline icon-discipline-' + item.discipline_id + '-light-32'"
                  />
                  <app-main-icon v-else :icon-size="32" :icon-name="item.icon" />
                </div>
              </div>
              <app-icon :icon-name="'plus-gold-sm'" />
              <div class="bonus-container__item flexing flex-col">
                <p class="text-texts-standard-important text-28">
                  {{ $t('equipmentShop.premiumBonus') }}
                </p>
                <div class="discipline-box-value flexing">
                  <p class="text-texts-standard-important text-36 font-bold attributeText">
                    {{ getValue(item.attributes.premium_bonus.value) }}
                  </p>
                  <div
                    v-if="item.icon === 'discipline'"
                    class="attribute-icon--32 m-2.5"
                    :class="'discipline icon-discipline-' + item.discipline_id + '-light-32'"
                  />
                  <app-main-icon v-else :icon-size="32" :icon-name="item.icon" />
                </div>
              </div>
              <div class="separator" />
              <div class="bonus-container__item flexing flex-col">
                <p class="text-28">{{ $t('equipmentShop.total') }}</p>
                <div class="discipline-box-value flexing">
                  <p class="text-texts-standard-important text-50 font-bold attributeText">
                    {{ getValue(item.attributes.total.value) }}
                  </p>
                  <div
                    v-if="item.icon === 'discipline'"
                    class="attribute-icon--48 m-2.5"
                    :class="'discipline icon-discipline-' + item.discipline_id + '-light-48'"
                  />
                  <app-main-icon v-else :icon-size="48" :icon-name="item.icon" />
                </div>
              </div>
            </div>
          </div>

          <p class="text-texts-standard-default text-30 m-7">
            {{ $t('equipmentShop.premiumInfo5') }}
          </p>

          <div class="attribute-bonus-tooltip__bonus-subheader flexing">
            <div
              v-if="!item.item_category"
              class="attribute-bonus-tooltip__bonus-container flexing flex-row"
            >
              <p class="text-texts-standard-default text-32">
                {{ $t('equipmentShop.noEquipmentForDiscipline') }}
              </p>
            </div>
            <div v-else class="attribute-bonus-tooltip__bonus-container flexing flex-row">
              <div
                class="attribute-bonus-item"
                :class="`attribute-bonus-item-${item.discipline_id}-${item.item_category}-${item.level}`"
              />
              <div class="flexing flex-col">
                <p
                  class="text-texts-standard-important text-36 font-bold"
                  :class="`equipmentShop.${item.name}`"
                >
                  {{ $t(`equipmentShop.${item.name}`) }}
                </p>
                <div class="flexing flex-row">
                  <span>{{ getBonusText }}</span>
                  <div class="discipline-box-value flexing ml-4">
                    <p class="text-texts-standard-important text-36 attributeText">
                      {{ '+' + getValue(item.attributes.premium_bonus.value) }}
                    </p>
                    <div
                      class="attribute-icon--32 m-2.5"
                      :class="'discipline icon-discipline-' + item.discipline_id + '-light-32'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p class="text-texts-standard-important text-30 m-7">
            {{ $t('equipmentShop.premiumInfo6') }}
          </p>
        </div>
      </template>
    </tippy>

    <span v-if="!isLastItem" class="circle-separator"></span>
  </div>
</template>
<script lang="ts">
import EquipmentItemAttributesIcons from '@/components/Equipment/EquipmentItemAttributesIcons.vue'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { EquipmentOfferContent } from '@/interfaces/premium/Offer'

export default defineComponent({
  name: 'AttributeCircleTooltip',
  components: {
    EquipmentItemAttributesIcons,
  },

  props: {
    item: {
      type: Object as PropType<Nullable<EquipmentOfferContent>>,
      default: () => null,
    },
    isLastItem: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getBonusText(): string {
      if (this.item.icon === 'money') {
        return this.$t('equipmentShop.moneyBonus')
      }
      if (this.item.icon === 'universal_training_points') {
        return this.$t('equipmentShop.trainingBonus')
      }
      return this.$t('equipmentShop.attributeBonus')
    },
  },
  methods: {
    getValue(value: number) {
      return `${value}${this.item.icon !== 'discipline' ? '%' : ''}`
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';

.circle-wrapper {
  display: flex;
}

.circle-separator {
  display: flex;
  width: 0.125rem;
  height: 3.75rem;
  margin: 0.625rem 2.188rem;
  box-shadow: 0.063rem 0.106rem 0.188rem 0 rgba(0, 0, 0, 0.5);
  background-color: #ffcc70;
}

.circle-item {
  position: relative;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  box-shadow: 0.063rem 0.106rem 0.188rem 0 rgba(0, 0, 0, 0.5);
  border-style: solid;
  border-width: 0.125rem;
  border-radius: 50%;

  &--1 {
    background-image: linear-gradient(to right top, #9f9f9f, #9f9f9f, #727272);
  }

  &--2 {
    background-image: linear-gradient(to right top, #2a9d53, #2a9d53, #157240);
  }

  &--3 {
    background-image: linear-gradient(to right top, #247cbd, #247cbd, #2b5676);
  }

  &--4 {
    background-image: linear-gradient(to right top, #7d52b6, #7d52b6, #6534a5);
  }

  &--5 {
    background-image: linear-gradient(to right top, #fdd044, #fdd044, #d69501);
  }

  &--6 {
    background-image: linear-gradient(to right top, #4158d0, #c850c0, #ffcc70);
  }

  @for $discipline from 1 through 14 {
    @for $category from 1 through 6 {
      @for $level from 1 through 5 {
        &.circle-item-#{$discipline}-#{$category}-#{$level}:not(.item-none)::after {
          content: '';
          position: absolute;
          width: 5rem;
          height: 5rem;
          top: -0.125rem;
          left: -0.125rem;
          background-image: url($path-equipment + 'items/circle/'+#{$discipline}+'/'+#{$category}+'-'+#{$level}+'.webp?v2');
          background-size: cover;
        }

        &.item-none:after {
          content: '';
          position: absolute;
          width: 5.125rem;
          height: 5.125rem;
          top: -0.125rem;
          left: -0.125rem;
          background-image: url($path-icons + 'ico-none-82.webp');
          background-size: cover;
        }
      }
    }
  }
}

.inner-circle-item {
  position: absolute;
  width: 2.7rem;
  height: 2.7rem;
  background: linear-gradient(to top, #e9d27a, #f6c717);
  border-radius: 50%;
  bottom: -0.625rem;
  right: -1.25rem;
  box-shadow: 1px 1.7px 3px 0 rgba(0, 0, 0, 0.5);
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: #0f3251;
    z-index: -1;
  }
}

.attribute-bonus-tooltip {
  &__header {
    position: relative;
    height: 3.125rem;
    background: linear-gradient(to left, rgba(15, 40, 57, 0), #1e6c9b, rgba(15, 40, 57, 0));
    margin-bottom: 0.625rem;

    &:after {
      content: '';
      position: absolute;
      bottom: 0rem;
      left: 0;
      width: 100%;
      height: 0.063rem;
      background: linear-gradient(to right, #a4a4a400, #a4a4a4, #a4a4a4, #a4a4a400);
    }
  }

  &__bonus-subheader {
    height: 9.375rem;
    background-image: linear-gradient(
      to right,
      rgba(108, 158, 196, 0) 15%,
      rgba(108, 158, 196, 0.6) 45%,
      rgba(108, 158, 196, 0.6) 55%,
      rgba(108, 158, 196, 0) 85%
    );
  }

  &__bonus-container {
    max-width: 59.375rem;
    margin: 0 auto;
    gap: 2.25rem;
    flex-wrap: nowrap;

    .bonus-container__item {
      position: relative;
      flex-grow: 1;
      flex-basis: 0;
      min-width: 11.5rem;
    }

    .separator {
      position: relative;
      width: 0.188rem;
      height: 8.125rem;
      opacity: 0.3;
      border: solid 0 #010101;
      background-color: #a0dcff;
    }
  }
}

.attribute-icon {
  &--32 {
    width: 2rem;
    height: 2rem;
  }

  &--48 {
    width: 3rem;
    height: 3rem;
  }
}

.attribute-bonus-item {
  position: relative;
  background-size: contain;
  background-position: center;
  width: 11.125rem;
  height: 7.063rem;
  background-repeat: no-repeat;

  @for $discipline from 1 through 14 {
    @for $category from 1 through 6 {
      @for $level from 1 through 5 {
        &.attribute-bonus-item-#{$discipline}-#{$category}-#{$level} {
          background-image: url($path-equipment + 'items/list/'+#{$discipline}+'/'+#{$category}+'-'+#{$level}+'.avif');
        }
      }
    }
  }
}
</style>
