import { grandPrizeInfoEndPoint } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import type { GrandPrizeTierInfo } from '@/interfaces/responses/grandPrize/GrandPrizeApiResponse'
import { GrandPrizeHistoryPreference } from '@/interfaces/GrandPrize'
import type {
  GrandPrizeTier,
  GrandPrizeTierData,
  GrandPrizeTierSlot,
} from '@/interfaces/GrandPrize'
import type { NameValueString } from '@/interfaces/Global'
import type { Nullable } from '@/interfaces/utils'
interface GrandPrizeMainState {
  tiers: GrandPrizeTier
  historyPreference: GrandPrizeHistoryPreference
}

export const useGrandPrizeStore = defineStore('useGrandPrize', {
  state: (): GrandPrizeMainState => ({
    tiers: {},
    historyPreference: GrandPrizeHistoryPreference.All,
  }),
  getters: {
    getTierInfo:
      (state: GrandPrizeMainState) =>
      (id: number): Nullable<GrandPrizeTierData> =>
        id in state.tiers ? state.tiers[id] : null,

    getHistoryPreference(): GrandPrizeMainState['historyPreference'] {
      return this.historyPreference
    },
  },
  actions: {
    async loadTierInfo(tier: number): Promise<void> {
      if (this.getTierInfo(tier)) {
        return
      }

      try {
        const response = await internalAxios.get<{}, GrandPrizeTierInfo>(
          `${grandPrizeInfoEndPoint}/${tier.toString()}`,
        )

        const data = {
          gpName: '',
          gpSlots: [],
          tier,
          badges: [],
        } as GrandPrizeTierData
        data.gpName = response.parameters.find(
          (param: NameValueString): boolean => param.name === 'grand_prize_name',
        )?.value
        data.gpSlots = response.grand_prize_slots?.reverse()
        data.gpSlots?.forEach((slot: GrandPrizeTierSlot): void => {
          ;[...Array(slot.amount)].forEach((): number => data.badges.push(slot.slot_rarity))
        })

        this.tiers[data.tier] = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    loadHistoryPreferenceFromSession(): void {
      const sessionPrizeHistoryPreference = sessionStorage.getItem('grandPrizeHistoryPreference')
      if (sessionPrizeHistoryPreference) {
        sessionStorage.setItem('grandPrizeHistoryPreference', sessionPrizeHistoryPreference)
        this.historyPreference = sessionPrizeHistoryPreference as GrandPrizeHistoryPreference
      }
    },
    setHistoryPreference(value: GrandPrizeHistoryPreference): void {
      sessionStorage.setItem('grandPrizeHistoryPreference', value)
      this.historyPreference = value
    },
  },
})
