<template>
  <section
    class="explanation-text flex items-center"
    :class="$isMobile() && 'explanation-text-mobile'"
  >
    <div class="flexing">
      <h1 class="gradient-text-gold text-72 font-bold uppercase">Lucky Wheel</h1>
      <tippy theme="WSM" placement="right" max-width="43rem">
        <template #content>
          <div class="information-tooltip">
            <div v-if="rewardingEndDate" class="information-tooltip-text">
              <p class="text-texts-standard-additional">
                {{ $te('infoSpinChance') }}
                {{
                  $replacePlaceholder(
                    $replacePlaceholder(
                      $te('infoSpinChanceAvailability'),
                      '{date}',
                      new Date(rewardingEndDate).toLocaleString(),
                    ),
                  )
                }}
              </p>
              <div class="tooltip-text-strip"></div>
            </div>
            <div class="information-tooltip-text">
              <p class="text-texts-standard-additional">
                {{ $te('infoSpinMultiplier') }}
              </p>
            </div>
          </div>
        </template>
        <app-icon icon-name="info-70 -mt-2 ml-3" />
      </tippy>
    </div>
    <p class="text-texts-standard-default text-32">
      {{ $te('spinInfo') }}
    </p>
  </section>
</template>

<script lang="ts">
import { getLuckyWheelConfig } from '@/globalVariables'
import { defineComponent } from 'vue'
import type LuckyWheelConfigApiResponse from '@/interfaces/responses/events/lucky-wheel/LuckyWheelConfigApiResponse'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  rewardingEndDate: Nullable<LuckyWheelConfigApiResponse['rewarding_end_date']>
}

export default defineComponent({
  name: 'LuckyWheelExplanationText',
  data(): ComponentData {
    return {
      rewardingEndDate: null,
    }
  },
  async created(): Promise<void> {
    await this.getSpinChanceAvailabilityDate()
  },
  methods: {
    async getSpinChanceAvailabilityDate(): Promise<void> {
      try {
        const spinAvailabilityData = await this.$axios.get<{}, LuckyWheelConfigApiResponse>(
          getLuckyWheelConfig,
        )
        this.rewardingEndDate = spinAvailabilityData?.rewarding_end_date ?? null
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

section.explanation-text {
  flex-direction: column;
  width: 50rem;
  @include background(url($path-events + 'luckyWheel/lucky-wheel-text.avif'), contain);
  padding-bottom: 3rem;
  padding-top: 1rem;
  margin-bottom: 1rem;

  & h1 {
    background-image: linear-gradient(to top, #ffd562 13%, #ffe49b 100%);
    background-clip: text;
    text-shadow: none;
    color: transparent;

    @if $isWsm {
      font-style: italic;
    }
  }

  & p {
    padding: 0 8rem 0 4rem;
  }
}

.tooltip-text-strip {
  width: 100%;
  height: 0.125rem;
  border-bottom: 0.125rem solid gray;
  margin: 1rem 0;
}

.explanation-text-mobile {
  width: 100% !important;
}
</style>
