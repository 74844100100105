<template>
  <multiplier-button class="multiplier-position" :disabled="isDisabled" @click="changeMultiplier">
    {{ activeMultiplier.multiplier }}x
  </multiplier-button>
</template>

<script lang="ts">
import MultiplierButton from './MultiplierButton.vue'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { LuckyWheelMultipliers, LuckyWheelMultiplier } from '../LuckyWheelController.vue'

interface ComponentData {
  activeIndex: number
}

export default defineComponent({
  name: 'MultiplierController',
  components: {
    MultiplierButton,
  },
  props: {
    activeMultiplier: {
      type: Object as PropType<LuckyWheelMultiplier>,
      required: true,
    },
    multiplierInfo: {
      type: Array as PropType<LuckyWheelMultipliers>,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['multiplierChange'],
  data(): ComponentData {
    return {
      activeIndex: 0,
    }
  },
  created(): void {
    this.activeIndex = this.multiplierInfo.findIndex(
      (data: LuckyWheelMultiplier): boolean => data.multiplier === this.activeMultiplier.multiplier,
    )
  },
  methods: {
    changeMultiplier(): void {
      const length = this.multiplierInfo.length
      const nextVal = this.activeIndex + 1
      this.activeIndex = nextVal >= length ? 0 : nextVal
      const newMultiplier = this.multiplierInfo[this.activeIndex]
      this.$emit('multiplierChange', newMultiplier)
    },
  },
})
</script>

<style lang="scss" scoped>
.multiplier-position {
  position: absolute;
  right: 0%;
  bottom: 0%;
}
</style>
