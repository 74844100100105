import { defineStore } from 'pinia'

interface EventBossFightStoreState {
  eventBenefitInfo: number
}

export const useEventBossFightsStore = defineStore('eventbossfight', {
  state: (): EventBossFightStoreState => ({
    eventBenefitInfo: 0,
  }),
  getters: {
    getEventBenefitInfo(): EventBossFightStoreState['eventBenefitInfo'] {
      return this.eventBenefitInfo
    },
  },
  actions: {
    setEventBenefits(data: number): void {
      this.eventBenefitInfo = data
    },
  },
})
