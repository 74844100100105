<template>
  <div class="gems-big-pack back text-texts-standard-default text-32 uppercase absolute">
    <p class="back-header-text flexing">
      {{ $t('premiumGroup.' + packageData.storeId) }}
    </p>
    <section
      class="back-price m-auto w-full flexing text-60 font-bold text-texts-standard-important"
    >
      <p>
        {{
          $filters.$formatNumber(
            (isNaN(packageData?.reward) ? 0 : packageData.reward) +
              (isNaN(packageData?.bonus) ? 0 : packageData.bonus) +
              (isNaN(packageData?.firstTimeBonus) ? 0 : packageData.firstTimeBonus) +
              (isNaN(packageData?.gemBonus) ? 0 : packageData.gemBonus),
          )
        }}
      </p>
      <app-main-icon class="icon-margin" :icon-name="GEMS" :icon-size="56" />
    </section>
    <section class="back-bonuses w-full">
      <app-scrollbar
        :id="createRandomId('randomId')"
        class="pr-2"
        width="100%"
        height="29.5rem"
        scroll="y"
        slide="y"
      >
        <gems-pack-back-line
          v-if="packageData.reward"
          :text="$t('premiumGroup.basicPack')"
          :gems="packageData.reward"
          :show-plus="false"
        />
        <gems-pack-back-line
          v-if="packageData.bonus"
          :text="$t('premiumGroup.quantityBonus')"
          :gems="packageData.bonus"
        />
        <gems-pack-back-line
          v-if="packageData.firstTimeBonus"
          :text="$t('premiumGroup.firstPurchaseBonus')"
          :gems="packageData.firstTimeBonus"
        />
        <gems-pack-back-line
          v-if="packageData.gemBonus"
          :text="$t('premiumGroup.dealBonus')"
          :gems="packageData.gemBonus"
        />
      </app-scrollbar>
    </section>
    <!-- <p class="back-more">
      Get more with
    </p>
    <section class="back-vip-bonuses">
      <article
        v-for="i in 3"
        :key="i"
        class="flex items-center justify-between text-texts-standard-danger"
      >
        <p>VIP bonus lvl {{ i }}</p>
        <div class="flexing">
          <p class="font-bold">
            {{ packageData.vipBonuses[i-1]}}
          </p>
          <app-main-icon
            class="icon-margin"
            :icon-name="GEMS"
            :icon-size="32"
          />
        </div>
      </article>
    </section> -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { createRandomId } from '@/helpers'
import { GEMS } from '@/globalVariables'
import GemsPackBackLine from '@/components/Premium/GemsPack/GemsPackBackLine.vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { GemsPack } from '@/interfaces/premium/GemsPack'

interface ComponentData {
  GEMS: typeof GEMS
}

export default defineComponent({
  name: 'GemsPackBack',
  components: {
    GemsPackBackLine,
  },
  props: {
    packageData: {
      type: Object as PropType<Nullable<GemsPack>>,
      default: () => null,
    },
  },
  data(): ComponentData {
    return {
      GEMS,
    }
  },
  methods: {
    createRandomId,
  },
})
</script>

<style lang="scss" scoped>
.back {
  width: 33rem;
  height: 39.75rem;
  background: url($path-premium + 'gemsPackage/BG_OFFER_BOX_3.webp') center no-repeat;
  background-size: contain;

  &-header-text {
    padding-top: 0.8rem;
    padding-bottom: 0.5rem;
  }

  &-price {
    position: relative;
    background: linear-gradient(to left, transparent, rgba(56, 79, 120, 0.99), transparent);
    height: 4.375rem;
    width: 25rem;
    line-height: 4.375rem;

    &:before {
      content: '';
      position: absolute;
      height: 0.125rem;
      width: 80%;
      background-image: linear-gradient(
        to left,
        rgba(125, 156, 205, 0),
        rgba(125, 156, 205, 1),
        rgba(125, 156, 205, 0)
      );
      top: 0;
    }
  }

  &-bonuses {
    padding: 0.5rem 0.5rem 0 2.0625rem;

    &-basic {
      line-height: 2rem;
      margin: 0.7rem 0;
      padding: 0.5rem 0;

      &-text {
        width: 16.5rem;
      }
    }
  }

  &-more {
    height: 4.4375rem;
    line-height: 4.4375rem;
  }

  &-vip-bonuses {
    padding: 0 2.0625rem 0 2.0625rem;
  }
}
</style>
