<template>
  <teleport to="#app">
    <div
      v-if="questCompletedData"
      class="fullscreen-popup-wrapper career-rewards w-full h-full absolute"
      :class="{ 'career-rewards--mobile': $isMobile() }"
    >
      <section
        ref="animation1"
        class="branch-icon-wrapper flexing animated-element--faded"
        :class="{ 'main-branch': !secondaryBranch }"
      >
        <app-discipline-icon
          v-if="secondaryBranch"
          class="m-auto discipline-i"
          theme="light"
          :size="184"
          :discipline-id="questCompletedData.discipline_id"
        />
        <div v-else class="main-career-trophy-icon m-auto icon-career-gold-184 discipline-i" />
      </section>
      <header
        ref="animation2"
        class="career-rewards-header uppercase font-bold text-48 text-texts-standard-default mb-4 animated-element--faded"
      >
        <p>
          {{ secondaryBranch ? disciplineName : $t('career.mainCareer') }}
        </p>
      </header>
      <div v-if="questCompletedData.boss_fight_progress" ref="animation2a" class="flexing w-full">
        <div
          v-for="(star, indexStar) in starsCount(Number(questCompletedData.boss_fight_progress))"
          :key="indexStar"
          class="star-item animated-element--faded"
          :class="star ? 'icon-star-full-80 ' : 'icon-star-empty-80'"
        />
      </div>
      <main ref="animation3" class="career-rewards-main relative animated-element--faded">
        <div
          v-if="!secondaryBranch || $isSsm"
          ref="animation3a"
          class="career-task-avatar animated-element--faded"
          :style="careerBossStyle"
        />
        <section
          ref="animation4"
          class="career-rewards-main-header text-texts-standard-default flexing uppercase animated-element--faded"
        >
          <p
            class="text-40 ml-1"
            v-html="
              $replacePlaceholder(
                $replaceHtmlPlaceholders(
                  $replacePlaceholder($t('career.questNumberCompleted'), '%s', '{b} %s {/b}'),
                  'b',
                  'font-bold text-texts-standard-important text-48',
                ),
                '%s',
                questCompletedData.quest_number,
              )
            "
          />
        </section>
        <p
          ref="animation5"
          class="rewards-text relative font-bold text-texts-standard-default text-40 uppercase animated-element--faded"
        >
          {{ $t('common.rewardsClaimed') }}
        </p>
        <section ref="animation6" class="career-rewards-main-reward flexing">
          <div
            v-for="(reward, index) in formatedRewards"
            :key="index"
            class="reward flexing font-bold text-36 text-texts-standard-default animated-element--faded"
          >
            <p>{{ reward.value }}</p>
            <app-main-icon
              :icon-size="56"
              :icon-name="reward.parameter"
              :tooltip="translateIconNames(reward)"
            />
          </div>
        </section>
        <section
          v-if="unlocked"
          ref="animation7"
          class="career-rewards-main-unlocked relative m-auto uppercase font-bold animated-element--faded"
        >
          <p ref="animation7a" class="text-texts-standard-default animated-element--faded">
            {{ $t('common.youUnlocked') }}
          </p>
          <div ref="animation7b" class="relative inline-block animated-element--faded">
            <div v-if="!secondaryBranch">
              <p class="unlocked-mechanic text-texts-standard-important">
                {{ $t('mechanics.' + questCompletedData.unlocks.mechanic_unlock) }}
              </p>
            </div>
            <div v-else-if="questCompletedData.unlocks.quest_discipline_levelup">
              <p class="unlocked-mechanic text-texts-standard-important">
                {{ $replacePlaceholder($t('career.trainingLevel'), '%s', disciplineLevel) }}
              </p>
            </div>
            <div
              v-else
              v-tippy="{
                theme: 'WSM',
                content: questCompletedData.unlocks.unlock_equipment_name ?? '',
                placement: 'top',
                textAlign: 'center',
              }"
              :class="itemClass"
            />
            <div class="new-level-eq absolute wave-animation wave-animation--slow">
              <div
                v-show="questCompletedData.unlocks?.unlock_equipment_level"
                class="triangle-up green triangle"
              />
            </div>

            <div class="new-item absolute flexing">
              <p class="new-item-text text-texts-standard-default">
                {{
                  questCompletedData.unlocks?.unlock_equipment_max_level
                    ? `LVL ${questCompletedData.unlocks.unlock_equipment_max_level}`
                    : $t('common.new')
                }}
              </p>
            </div>
          </div>
        </section>
      </main>
      <div ref="animation8" class="animated-element--faded">
        <footer
          v-if="secondaryBranch && questCompletedData.next_quest"
          class="career-rewards-footer flex items-center justify-center"
        >
          <app-button
            class="btn-rewards"
            btn-type="confirm"
            :btn-text="$t('button.continue')"
            @click="close"
          />
        </footer>
        <footer v-else class="career-rewards-footer flex items-center justify-center">
          <app-button
            class="btn-rewards"
            btn-type="confirm"
            :btn-text="$t('button.continue')"
            @click="close"
          />
        </footer>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import {
  capitalize,
  playSound,
  animateElement,
  skipAnimationOnClick,
  getDisciplineBossSlug,
} from '@/helpers'
import {
  EVENT_CREATE_EVENT_BUILDING,
  GRAND_PRIZE,
  GRAND_PRIZE_RARITY,
  MECHANIC_CLUB,
  pathFigures,
} from '@/globalVariables'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTrainingDisciplineStore } from '@/store/pinia/trainingDisciplinesStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useUserStore } from '@/store/pinia/userStore'

import type { PropType } from 'vue'
import type { QuestReward } from '@/interfaces/Quest'
import type { ResponseTasksQuestCompleted } from '@/store/pinia/responseTaskStore'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'

export default defineComponent({
  props: {
    questCompletedData: {
      type: Object as PropType<ResponseTasksQuestCompleted>,
      required: true,
    },
  },
  computed: {
    ...mapState(useTrainingDisciplineStore, {
      disciplines: 'getDisciplines',
    }),
    ...mapState(useResponseTaskStore, {
      mechanicUnlock: 'getMechanicUnlock',
    }),
    ...mapState(useEventInfoStore, {
      eventMechanicByType: 'getEventMechanicByType',
    }),
    ...mapState(useUserStore, {
      firstDisciplineId: 'getFirstDisciplineId',
    }),
    unlocked(): Record<string, string> {
      return this.questCompletedData.unlocks
    },
    secondaryBranch(): number {
      return this.questCompletedData.discipline_id
    },
    disciplineName(): string {
      return this.$translateDiscipline(this.questCompletedData.discipline_id ?? '')
    },
    formatedRewards(): QuestReward[] {
      return (
        this.questCompletedData.rewards?.map((val: QuestReward) => {
          let consumed: string
          switch (val.parameter) {
            case 'wildCard':
              consumed = 'wild_card'
              break
            case 'energyDrink':
              consumed = 'energy_drink'
              break
            case 'grand_prize':
              consumed = 'gp-' + GRAND_PRIZE_RARITY[val.rarity]
              break
            case 'PowerBoost':
              consumed = val.level ? 'benefit-' + val.level : 'powerboost-' + val.rarity
              break
            case 'ProTraining':
              consumed = 'protraining-' + val.rarity
              break
            case 'Personal Sponsor':
              consumed = 'personal-' + val.rarity
              break
            default:
              consumed = val.parameter
              break
          }

          return {
            parameter: consumed,
            value: val.value,
            rarity: val.rarity ?? null,
          }
        }) ?? []
      )
    },
    itemClass(): string {
      if (this.questCompletedData.unlocks.unlock_equipment) {
        return `item-${this.questCompletedData.discipline_id}-${this.questCompletedData.unlocks.unlock_equipment}-1`
      } else if (this.questCompletedData.unlocks.unlock_equipment_level) {
        return `item-${this.questCompletedData.discipline_id}-${this.questCompletedData.unlocks.unlock_equipment_level}-${this.questCompletedData.unlocks.unlock_equipment_max_level}`
      } else return ''
    },
    disciplineLevel(): string {
      for (const disc in this.disciplines) {
        if (
          parseInt(this.disciplines[disc]?.id?.toString()) ===
          this.questCompletedData?.discipline_id
        ) {
          return this.disciplines[disc]?.level?.toString()
        }
      }
      return ''
    },
    isEventUnlock(): boolean {
      if (!this.eventMechanicByType) return false
      return this.questCompletedData.quest_number === this.mechanicUnlock(this.eventMechanicByType)
    },
    careerBossStyle(): string {
      const firstDisciplineId = this.$isSsm ? this.firstDisciplineId : 0

      let slug = getDisciplineBossSlug(this.questCompletedData.discipline_id || firstDisciplineId)

      // V kariere WSM nemame Annie, ale Anne...
      if (this.$isWsm) slug = slug.replace('Annie', 'Anne')

      return `background-image: url(${pathFigures}/${slug}-sm.avif);`
    },
  },
  created(): void {
    this.loadDisciplines()
    this.loadNotifications()

    // skip animation on click
    window.addEventListener('click', this.handleSkipAnimationClick)
  },
  beforeUnmount(): void {
    window.removeEventListener('click', this.handleSkipAnimationClick)
  },
  mounted(): void {
    this.animateScene()
  },
  methods: {
    ...mapActions(useTutorialStore, ['loadTutorial']),
    ...mapActions(useTrainingDisciplineStore, ['loadDisciplines']),
    ...mapActions(useResponseTaskStore, {
      hideQuestCompleted: 'hideQuestCompleted',
      showDisciplineBranchCompleted: 'showDisciplineBranchCompleted',
      loadNotifications: 'loadNotifications',
    }),
    ...mapActions(usePhaserGameIntegrationStore, {
      loadClubAssets: 'loadClubAssets',
    }),
    close(): void {
      this.hideQuestCompleted()

      const unlockedMechanic = this.questCompletedData.unlocks?.mechanic_unlock
      if (unlockedMechanic) {
        if (unlockedMechanic === MECHANIC_CLUB) {
          this.loadClubAssets()
        }
        this.loadTutorial()
      }

      if (!this.questCompletedData.next_quest && this.secondaryBranch) {
        this.showDisciplineBranchCompleted(this.questCompletedData.discipline_id)
      }

      if (this.isEventUnlock) {
        document.dispatchEvent(new Event(EVENT_CREATE_EVENT_BUILDING))
      }

      playSound('career_add_task')
    },
    starsCount(count: number): Record<string, boolean> {
      const stars = {}
      for (let index = 1; index <= 3; index++) {
        if (index <= count) {
          stars[index] = true
        } else {
          stars[index] = false
        }
      }
      return stars
    },
    translateIconNames(reward: QuestReward): string {
      if (reward.rarity != null && reward.parameter === 'gp-' + reward.rarity) {
        return this.$replacePlaceholder(
          this.$t(`grandPrize.tier${capitalize(reward.rarity)}`),
          '{grandprize}',
          GRAND_PRIZE,
        )
      } else {
        return reward.parameter
      }
    },
    animateScene(): void {
      if (this.secondaryBranch) {
        animateElement(
          this.$refs.animation1,
          'fade-in, zoom-in-short, flash-blur-end',
          '0.67s',
          166,
        )
      } else {
        animateElement(this.$refs.animation1, 'fade-in, zoom-in-short', '0.67s', 166)
      }
      animateElement(this.$refs.animation2, 'fade-in, slide-in-to-left', '0.67s', 400)

      const conditionallyAnimatedElement = this.$refs.animation2a
      if (conditionallyAnimatedElement) {
        const animatedElements = this.$refs.animation2a.querySelectorAll('.star-item')
        animatedElements.forEach((element: HTMLElement, index: number): void => {
          const delay = 400 + index * 166
          animateElement(element, 'fade-in, pop-bounce', '0.67s', delay)
        })
      }

      animateElement(this.$refs.animation3, 'fade-in', '0.67s', 666)

      const conditionallyAnimatedElement2 = this.$refs.animation3a
      if (conditionallyAnimatedElement2) {
        animateElement(conditionallyAnimatedElement2, 'fade-in, slide-in-to-right', '0.67s', 666)
      }

      animateElement(this.$refs.animation4, 'fade-in', '0.67s', 866)
      animateElement(this.$refs.animation5, 'fade-in', '0.67s', 1066)

      const animatedElements = this.$refs.animation6.querySelectorAll('.reward')
      animatedElements.forEach((element: HTMLElement, index: number): void => {
        const delay = 1266 + index * 200
        animateElement(element, 'fade-in', '0.67s', delay)
      })

      const conditionallyAnimatedElement3 = this.$refs.animation7
      if (conditionallyAnimatedElement3) {
        animateElement(conditionallyAnimatedElement3, 'fade-in', '0.67s', 1666)
        animateElement(this.$refs.animation7a, 'fade-in', '0.67s', 1766)
        animateElement(this.$refs.animation7b, 'fade-in, pop-bounce', '0.67s', 1833)
        animateElement(this.$refs.animation8, 'fade-in', '0.67s', 1066)
      } else {
        animateElement(this.$refs.animation8, 'fade-in', '0.67s', 1066)
      }
    },
    handleSkipAnimationClick(e: MouseEvent): void {
      skipAnimationOnClick(e, this.$el, this.$refs, !!this.questCompletedData, [
        ['animation2a', '.star-item'],
        ['animation6', '.reward'],
      ])
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/stars-icons.scss';
@import '@/assets/styles/components/icons/career-icons.scss';
@import '@/assets/styles/animations.scss';

@for $discipline from 1 through 20 {
  @for $category from 1 through 6 {
    @for $level from 1 through 5 {
      .item-#{$discipline}-#{$category}-#{$level} {
        background-image: url($path-equipment + 'items/mainFooter/'+#{$discipline}+'/'+#{$category}+'-'+#{$level}+'.avif');
        background-repeat: no-repeat;
        background-size: contain;
        width: 6.6875rem;
        height: 5.125rem;
      }
    }
  }
}

.career-rewards {
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(0.5rem);

  &--mobile {
    .career-rewards-main {
      margin-bottom: 3rem;
    }

    &.career-rewards .branch-icon-wrapper {
      margin-bottom: 0.5rem;
    }

    .career-rewards-main-unlocked {
      height: auto;
      padding-bottom: 1rem;
    }
  }

  .branch-icon-wrapper {
    width: 11.5rem;
    height: 11.5rem;
    margin-bottom: 2rem;
    &.main-branch {
      @if $isSsm {
        background: linear-gradient(to right, rgba(52, 65, 93, 0.85), rgba(39, 50, 73, 0.85));
        border: 0.125rem solid #586b9d;
      }
    }
  }

  &-main {
    width: 80%;
    margin-top: 2rem;
    margin-bottom: 12rem;
    min-height: 13.125rem;
    @if $isWsm {
      background: linear-gradient(
        to right,
        transparent,
        #0f2a4e 40%,
        #0b1e37 50%,
        #0f2a4e 60%,
        transparent
      );
    }

    @if $isSsm {
      background: linear-gradient(
        to right,
        transparent,
        #16203b 40%,
        rgba(35, 43, 74, 0.99) 50%,
        #16203b 60%,
        transparent
      );
    }

    &-header {
      background: linear-gradient(
        to right,
        transparent,
        #0f2a4e 40%,
        #154e79,
        #0f2a4e 60%,
        transparent
      );
      height: 5rem;
      @if $isWsm {
        margin-top: 2.5rem;
      }
      @if $isSsm {
        background: linear-gradient(
          to right,
          transparent,
          rgba(#605e8f, 0.5) 40%,
          rgba(96, 94, 143, 0.99),
          rgba(#605e8f, 0.5) 60%,
          transparent
        );
      }
    }

    .rewards-text {
      height: 3.75rem;
      width: 34.375rem;
      margin: 0 auto;
      margin-top: 1.5625rem;
      @if $isWsm {
        background: linear-gradient(to left, #d2942000, #be9949b1, #be9949, #be9949b1, #ffe88e00);
      }
      @if $isSsm {
        background: linear-gradient(
          to right,
          rgba(209, 192, 118, 0) 0%,
          #c7a655 50%,
          rgba(164, 126, 40, 0) 100%
        );
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        height: 0.125rem;
        width: 100%;
        left: 50%;
        transform: translate(-50%);
        @if $isWsm {
          background: linear-gradient(to right, transparent, #fff3ad, transparent);
        }
        @if $isSsm {
          background: linear-gradient(to right, transparent, rgba(244, 233, 167, 1), transparent);
        }
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }
    }

    &-reward {
      height: 7.8125rem;

      .reward {
        margin: 0 1.25rem;
      }
    }

    &-unlocked {
      font-size: 2.25rem;
      height: 12.125rem;
      width: 100%;
      padding-top: 0.5rem;

      .new-level-eq {
        left: -1.5rem;
        top: 2.7rem;
      }

      &:before {
        content: '';
        height: 0.125rem;
        width: 22rem;
        background: linear-gradient(to left, transparent, #ffffff5c, transparent);
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%);
      }

      .unlocked-mechanic {
        font-size: 3.125rem;
      }

      .new-item {
        width: 4.5625rem;
        height: 1.875rem;
        background: #ff0606;
        top: 0;
        right: -5.5625rem;
        font-size: 1.625rem;

        &-text {
          font-size: 1.625rem;
        }
      }
    }
  }

  &-footer {
    width: 116.25rem;

    .btn-rewards {
      margin: 0 0.625rem;
    }
  }

  .career-task-avatar {
    @if $isWsm {
      background: url($path-figures + 'Anne-Collins-sm.avif') center no-repeat;
      width: 24.25rem;
      height: 29rem;
    }

    @if $isSsm {
      @include background(null);
      width: 23.188rem;
      height: 25rem;
    }

    position: absolute;
    bottom: 0;
    left: -10rem;
    background-size: contain;
  }
}
</style>
