<template>
  <div class="equipment-footer flex items-center justify-center">
    <section class="flex items-center justify-between h-full w-full">
      <div class="equipment-footer-left flex items-center">
        <div class="flexing">
          <app-discipline-icon :discipline-id="processItem.disciplineId" :size="70" />
          <div
            v-if="
              processItem.disciplineId && processItem.parentCategoryId && processItem.parentLevelId
            "
            class="item-icon mx-5"
            :class="`item-icon-${processItem.disciplineId}-${processItem.parentCategoryId}-${processItem.parentLevelId}`"
          />
        </div>
        <p class="text-texts-standard-default text-32">
          {{ $t(`equipmentShop.${processItem.item.parentName}`) }}
        </p>
      </div>
      <div class="equipment-footer-right flex justify-end items-center">
        <p :class="itemTypes[processItem.stats.operation_type]?.class ?? ''">
          {{ $t(itemTypes[processItem.stats.operation_type]?.text ?? '') }}
        </p>
        <app-timer
          class="equipment-timer-footer"
          :time="processItem.stats.operation_end"
          @countdown-action="$emit('finishOperation')"
        />
        <app-button
          btn-size="md"
          btn-type="credit"
          :btn-text="$t('common.speedUp')"
          :disabled="gems.value < Number(processItem.stats.speedup_price)"
          @click="showConfirm = true"
        />
      </div>
    </section>
    <confirm-popup
      v-if="showConfirm"
      :button-data="{
        btnId: 'btn-speed-up',
        btnType: 'credit',
        btnSize: 'md',
      }"
      :button-text-left="$t('common.speedUp')"
      :button-text-right="processItem.stats.speedup_price"
      :popup-title="$t('equipmentShop.addOn')"
      @close="showConfirm = false"
      @action="onClick()"
    >
      <template #popupHeader>
        <p>{{ $t('equipmentShop.changeEquipment') }}</p>
      </template>
      <div class="container-upgrade text-36 text-texts-standard-default">
        <p>
          {{ $t(itemTypes[processItem.stats.operation_type]?.popUpText ?? '') }}
        </p>
        <p class="text-texts-standard-important">
          {{ $t(`equipmentShop.${processItem.item.name}`) }}
        </p>
      </div>
    </confirm-popup>
  </div>
</template>

<script lang="ts">
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import { OPERATION_DELIVERY, OPERATION_REPAIR, OPERATION_UPGRADE } from '@/globalVariables'
import { useEquipmentSpeedUpStore } from '@/store/pinia/equipment/speedUpStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import type { ItemInProgressData } from '@/interfaces/Equipment'
import { mapState, mapActions } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { CLEAR_AND_HIDE_TIMER, SHOP } from '@/map-phaser-new/constants/common'

interface ItemType {
  class: string
  text: string
  popUpText: string
}

interface ComponentData {
  OPERATION_REPAIR: typeof OPERATION_REPAIR
  OPERATION_UPGRADE: typeof OPERATION_UPGRADE
  OPERATION_DELIVERY: typeof OPERATION_DELIVERY
  showConfirm: boolean
  itemTypes: {
    [OPERATION_REPAIR]: ItemType
    [OPERATION_UPGRADE]: ItemType
    [OPERATION_DELIVERY]: ItemType
  }
}

export default defineComponent({
  name: 'EquipmentFooter',
  components: {
    ConfirmPopup,
  },
  props: {
    processItem: {
      type: Object as PropType<ItemInProgressData>,
      required: true,
    },
  },
  emits: ['finishOperation'],
  data(): ComponentData {
    return {
      OPERATION_REPAIR,
      OPERATION_UPGRADE,
      OPERATION_DELIVERY,
      showConfirm: false,
      itemTypes: {
        [OPERATION_REPAIR]: {
          class: 'text-texts-standard-positive text-30',
          text: 'equipmentShop.repairing',
          popUpText: 'equipmentShop.repairSpeedUpConfirm',
        },
        [OPERATION_UPGRADE]: {
          class: 'text-texts-standard-positive text-30',
          text: 'equipmentShop.upgrading',
          popUpText: 'equipmentShop.upgradeSpeedUpConfirm',
        },
        [OPERATION_DELIVERY]: {
          class: 'text-texts-standard-default text-30',
          text: 'equipmentShop.delivering',
          popUpText: 'equipmentShop.deliverySpeedUpConfirm',
        },
      },
    }
  },
  computed: {
    ...mapState(usePhaserGameIntegrationStore, { phaserEmitter: 'getPhaserEventEmitter' }),

    ...mapState(useResponseTaskStore, {
      gems: 'getGems',
    }),
  },
  methods: {
    ...mapActions(useEquipmentSpeedUpStore, ['repairSpeedup', 'upgradeSpeedup', 'offerSpeedup']),
    async onClick(): Promise<void> {
      if (this.gems.value < Number(this.processItem.stats.speedup_price)) return

      const type = this.processItem.stats.operation_type
      const id = this.processItem.item.id
      this.showConfirm = false

      switch (type) {
        case OPERATION_REPAIR:
          await this.repairSpeedup(String(id))
          break
        case OPERATION_UPGRADE:
          await this.upgradeSpeedup({
            itemId: +this.processItem.parentId,
            addonId: id,
          })
          break
        case OPERATION_DELIVERY:
          await this.offerSpeedup(String(id))
          break
      }

      this.phaserEmitter?.emit(CLEAR_AND_HIDE_TIMER, SHOP)
      this.$emit('finishOperation')
    },
  },
})
</script>

<style lang="scss" scoped>
.equipment-footer {
  position: relative;
  height: 6.3rem;
  width: 100%;
  bottom: 0;
  background: #0e1e36;
  border-top: 0.13rem solid #377fb8;
  z-index: 2;

  & > section {
    width: 117rem;
  }

  .item-icon {
    background-size: contain;
    width: 6.688rem;
    height: 5.125rem;
    background-repeat: no-repeat;

    @for $discipline from 1 through 14 {
      @for $category from 1 through 6 {
        @for $level from 1 through 5 {
          &.item-icon-#{$discipline}-#{$category}-#{$level} {
            background-image: url($path-equipment + 'items/mainFooter/'+#{$discipline}+'/'+#{$category}+'-'+#{$level}+'.avif');
          }
        }
      }
    }
  }

  &-left {
    width: 26rem;
  }

  &-right {
    width: 62rem;

    button {
      width: 22.563rem;
    }

    > p {
      margin-right: 4rem;
    }
  }
}

.item-info {
  max-width: 25rem;
  max-height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.equipment-timer-footer {
  margin-right: -2rem;
}
</style>
