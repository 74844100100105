import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const clubBallonTopMiddlePath: PathObjectInterface = {
  mainPath: { x: -20, y: -10 },
  spline: [
    59, 96, 121, 134, 254, 213, 301, 215, 369, 215, 508, 215, 596, 226, 685, 231, 755, 240, 849,
    253, 1054, 256, 1183, 286, 1277, 309, 1339, 312, 1403, 325, 1485, 334, 1641, 354, 1705, 366,
    1763, 401, 1742, 443, 1697, 448, 1607, 458, 1529, 459, 1485, 466, 1453, 476, 1424, 511, 1427,
    551, 1441, 583, 1470, 601, 1538, 620, 1589, 629, 1699, 630, 1825, 633,
  ],
}
