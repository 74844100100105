<template>
  <div class="w-1/2">
    <div
      class="bossfight-player-avatar flex flex-col"
      :class="[
        winner ? 'winner' : 'loser',
        { 'mr-auto ml-32': position === 'left' },
        { 'ml-auto mr-32': position === 'right' },
        position,
      ]"
    >
      <div class="w-full h-full mx-auto relative">
        <div class="avatar-circle flexing">
          <div
            class="avatar relative"
            :style="{
              backgroundImage: `url(${player.avatarUrl})`,
            }"
          />
          <div v-if="winner" class="avatar-winner absolute" />
          <div v-if="boss" class="avatar-boss absolute" />
        </div>
      </div>
    </div>
    <div
      class="bossfight-player-info text-texts-standard-default text-60 mt-12 relative"
      :class="[
        winner ? 'winner' : 'loser',
        { 'mr-20': position === 'left' },
        { 'ml-20': position === 'right' },
        position,
      ]"
    >
      <div class="relative z-10">
        <app-user
          class="justify-center"
          user-class="uppercase text-50 z-10"
          :country="player.country.three_letter_code"
          :no-country-text="true"
          :name="player.name"
        />
        <p
          class="text-60 font-bold italics mt-2"
          :class="winner ? 'text-texts-standard-important' : 'text-texts-standard-danger'"
        >
          {{
            player.stats.fail_status
              ? $t(`arena.${player.stats.fail_status}`)
              : formatResult(player.stats.result, player.disciplineId)
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import { pathImages } from '@/globalVariables'
import { formatResult } from '@/helpers'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { ReportPopupData } from '../TasksBoss.vue'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  components: {
    AppUser,
  },
  props: {
    player: {
      type: Object as PropType<ReportPopupData['player'] | ReportPopupData['boss']>,
      required: true,
    },
    winner: {
      type: Boolean,
      default: false,
    },
    boss: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String as PropType<'left' | 'right'>,
      required: true,
      validator: (value: string): boolean => ['left', 'right'].includes(value),
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  methods: {
    formatResult,
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/components/icons/discipline-icons.scss';

.bossfight-player-avatar {
  width: 23.75rem;
  height: 21rem;

  @each $rank in ('winner', 'loser') {
    &.#{$rank} .avatar-circle {
      background-image: url('#{$path-events}shared/taskchain/tasks/bossFight/bossfight-circle-avatar-#{$rank}.avif');
    }
  }

  .avatar-circle {
    width: 25.0625rem;
    height: 21.625rem;
    @include background(null, contain);
    margin: 0 -0.65625rem;
  }

  .avatar {
    $size-avatar: 17.5rem;
    width: $size-avatar;
    height: $size-avatar;
    @include background(null, contain);
    top: 0.525rem;
    left: -0.325rem;
  }

  .avatar-winner {
    width: 12rem;
    height: 2.625rem;
    top: 0.75rem;
    left: 50%;
    transform: translateX(-50%);
    @include background(
      url('#{$path-events}shared/taskchain/tasks/bossFight/bossfight-text-winner.avif'),
      contain
    );
  }

  .avatar-boss {
    width: 12rem;
    height: 2.625rem;
    bottom: 0.25rem;
    left: 50%;
    transform: translateX(-50%);
    @include background(
      url('#{$path-events}shared/taskchain/tasks/bossFight/bossfight-text-boss.avif'),
      contain
    );
  }
}

.bossfight-player-info {
  &::after {
    content: '';
    display: block;
    width: 45.625rem;
    height: 11.75rem;
    position: absolute;
    top: 0;
    @include background(null, contain);
  }

  @each $position in ('left', 'right') {
    @each $rank in ('winner', 'loser') {
      &.#{$position}.#{$rank}::after {
        background-image: url('#{$path-events}shared/taskchain/tasks/bossFight/bossfight-#{$position}-box-#{$rank}.avif');
      }
    }
  }
}
</style>
