import { availablePlayersEndpoint, invitePlayerToClubEndpoint } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import type { ClubAvailablePlayersResponse } from '@/interfaces/responses/club/ClubAvailablePlayersResponse'
import type { ClubAvailablePlayer } from '@/interfaces/clubs/ClubAvailablePlayer'
import type { ClubFilter } from '@/interfaces/clubs/Clubs'

interface ClubAvailablePlayersState {
  clubsPlayers: ClubAvailablePlayer[]
}

export const useClubsPlayersStore = defineStore('clubsPlayersStore', {
  state: (): ClubAvailablePlayersState => ({
    clubsPlayers: [],
  }),
  getters: {
    getAvailablePlayers(): ClubAvailablePlayersState['clubsPlayers'] {
      return this.clubsPlayers
    },
  },
  actions: {
    async loadAvailablePlayers(filter: ClubFilter = {}): Promise<void> {
      const response = await internalAxios.post<{}, ClubAvailablePlayersResponse[]>(
        availablePlayersEndpoint,
        filter,
      )

      const data = response.map((player: ClubAvailablePlayersResponse): ClubAvailablePlayer => {
        return {
          id: player.user_id,
          userId: player.user_id,
          username: player.username,
          country: player.country,
          level: player.level,
          prestige: player.prestige,
          rankingPoints: player.ranking_points,
        }
      })

      this.clubsPlayers = data
    },

    async sendRequestToJoinClub(playerId: string) {
      try {
        await internalAxios.post<{}, string>(invitePlayerToClubEndpoint, {
          user_games_id: playerId,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
