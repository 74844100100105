<template>
  <info-popup
    v-if="qualificationRewards && drawRewards"
    :popup-title="$te('commonRewards')"
    width="76.125rem"
    height="41.5rem"
    class="rewards"
    @close="$emit('close')"
  >
    <header class="rewards-header flexing space-x-4">
      <app-button
        :btn-type="qualification ? 'secondary' : 'inactive'"
        :btn-text="$te('qualification')"
        btn-size="md"
        @click="qualification = true"
      />
      <app-button
        :btn-type="!qualification ? 'secondary' : 'inactive'"
        btn-size="md"
        :btn-text="$te('playoff')"
        @click="qualification = false"
      />
    </header>
    <div class="rewards-subheader flexing">
      <app-icon icon-name="info-rounded" class="rewards-subheader-icon" />
      <p class="text-28 text-texts-standard-additional">
        {{
          $replacePlaceholder(
            $te('playoffRulesFour'),
            '%s',
            playoffConfig?.advances_to_draw.toString() || 'unknown',
          )
        }}
      </p>
    </div>
    <section class="rewards-info justify-center">
      <app-scrollbar width="100%" height="24rem" scroll="y" slice="y">
        <section v-if="qualification" class="qualification">
          <div
            class="qualification-header flex items-center m-auto uppercase text-texts-standard-default text-22"
          >
            <p>{{ $te('positionShort') }}</p>
            <p>{{ $te('league') }}</p>
            <p>{{ $te('commonReward') }}</p>
          </div>
          <div class="table-standard m-auto">
            <div
              v-for="(reward, i) in qualificationRewards.rewards"
              :key="i + 315135"
              class="table-standard-row text-texts-standard-default text-32 flex items-center qualification-row"
            >
              <!-- Ten style musi ostat lebo sa pokazi. Asi by som celu componentu precsskoval radsej ale tak co uz -->
              <span class="spaner" style="width: 35.125rem">
                <p>{{ i + 1 }}</p>
                <league-component
                  v-if="reward.league"
                  style="height: 6.1875rem"
                  :league-type="reward.league"
                />
                <div v-else class="qualification-row-rewards__league-space-placeholder"></div>
              </span>
              <div class="qualification-row-rewards h-full flexing">
                <div
                  v-for="(subRew, index) in reward.rewards"
                  :key="index"
                  class="marginer wrapper flexing"
                >
                  <app-main-icon :icon-name="subRew.type" :icon-size="48" />
                  <p class="font-fix">{{ subRew.value }}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section v-else class="playoff">
          <article v-for="(rew, j) in drawRewards.rewards" :key="j" class="rewards-info-box m-auto">
            <league-component :full="true" :league-type="rew.league" />
            <div
              class="rewards-info-box-subheader w-full uppercase text-texts-standard-default flex items-center justify-around"
            >
              <p>{{ $te('playoffDrawRound2') }}</p>
              <p>{{ $te('playoffDrawRound3') }}</p>
              <p>{{ $te('playoffDrawRound4') }}</p>
            </div>
            <div class="rewards-info-box-rewards w-full flex items-center justify-around">
              <div
                v-for="(round, i) in rew.rounds"
                :key="i"
                class="rewards-section h-full relative flexing"
              >
                <div
                  v-for="(rewRound, h) in round.rewards"
                  :key="h + 333"
                  class="h-full relative text-texts-standard-default flexing marginer"
                  :class="{
                    'text-50': $isWsm,
                    'text-32 font-bold space-x-2': $isSsm,
                  }"
                >
                  <app-main-icon
                    :icon-size="$isWsm ? 56 : 32"
                    :icon-name="rewRound.type"
                  ></app-main-icon>
                  <span>{{ rewRound.value }}</span>
                </div>
              </div>
            </div>
          </article>
        </section>
      </app-scrollbar>
    </section>
  </info-popup>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { playoffRewards } from '@/globalVariables'
import { useEventPlayoffStore } from '@/store/pinia/events/playoffStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import LeagueComponent from './LeagueComponent.vue'
import { PlayoffState } from '@/interfaces/events/Playoff'
import type {
  PlayoffRewardsApiResponse_Qualification,
  PlayoffRewardsApiResponse_Draw,
} from '@/interfaces/responses/events/playoff/PlayoffRewardsApiResponse'

interface ComponentData {
  qualification: boolean
  qualificationRewards: PlayoffRewardsApiResponse_Qualification
  drawRewards: PlayoffRewardsApiResponse_Draw
}

export default defineComponent({
  name: 'PlayoffRewardsPopup',
  components: {
    InfoPopup,
    LeagueComponent,
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      qualification: true,
      qualificationRewards: null,
      drawRewards: null,
    }
  },
  computed: {
    ...mapState(useEventPlayoffStore, {
      getPlayoffId: 'getPlayoffId',
      playoffConfig: 'getPlayoffConfig',
    }),
  },
  async created(): Promise<void> {
    await this.getRewardData()
  },
  methods: {
    async getRewardData(): Promise<void> {
      try {
        const qualificationPromise = this.$axios.post<{}, PlayoffRewardsApiResponse_Qualification>(
          playoffRewards,
          {
            playoff_id: this.getPlayoffId,
            state: PlayoffState.Qualification,
          },
        )
        const drawPromise = this.$axios.post<{}, PlayoffRewardsApiResponse_Draw>(playoffRewards, {
          playoff_id: this.getPlayoffId,
          state: PlayoffState.Draw,
        })
        const [qualificationRewards, drawRewards] = await Promise.all([
          qualificationPromise,
          drawPromise,
        ])
        this.qualificationRewards = qualificationRewards
        this.drawRewards = drawRewards
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.rewards {
  &-header {
    margin-top: 2rem;
    margin-bottom: 1.25rem;
  }

  &-subheader {
    &-icon {
      width: 2.3125rem;
      height: 2.3125rem;
      margin-right: 0.7rem;
    }
  }

  &-info {
    margin-top: 1rem;

    &-box {
      height: 12.5625rem;
      width: 73.25rem;
      margin-bottom: 1.375rem;

      @if $isWsm {
        background-color: #236d9b;
      }

      @if $isSsm {
        background-color: rgba(82, 84, 121, 0.7);
        border: 0.125rem solid #7477a4;
      }

      .league-img {
        @include background(url($path-events + 'playoff/playoff-diamond.avif'), contain);
        width: 62rem;
        height: 4.9375rem;
      }

      &-subheader {
        height: 2.375rem;
        font-size: 1.375rem;

        @if $isWsm {
          background-color: #09172a;
        }

        @if $isSsm {
          background-color: #292d30;
        }

        p {
          width: 24rem;
        }
      }

      &-rewards {
        height: 4.5625rem;
        margin-top: 0.3rem;

        .rewards-section {
          width: 24rem;

          @if $isWsm {
            background-color: #4d80a0;
            border: 0.0625rem solid #6c9ec4;
          }

          @if $isSsm {
            background-color: rgba(114, 114, 176, 0.7);
            border: 0.125rem solid #7477a4;
          }
        }
      }
    }

    .qualification {
      &-header {
        height: 2.375rem;
        width: 73.375rem;

        @if $isWsm {
          background-color: #09172a;
        }

        @if $isSsm {
          background-color: #292d30;
        }

        & > :first-child {
          width: 3.75rem;
        }

        & > :nth-child(2) {
          width: 31.125rem;
        }

        & > :nth-child(3) {
          width: 30.875rem;
          margin-left: 15.5rem;
        }
      }

      &-row {
        height: 4.5625rem;
        width: 73.375rem;
        margin: 0.1875rem auto;

        & > :first-child {
          width: 3.75rem;
        }

        &-rewards {
          width: 30.875rem;

          &__league-space-placeholder {
            width: 31.125rem;
          }
        }
      }
    }
  }
}

.marginer {
  margin: 0 0.6rem;
}

.table-standard-row {
  justify-content: space-between;
}

.font-fix {
  font-size: 1.8rem;
}

.spaner {
  display: flex;

  p {
    margin: 1.5rem;
  }
}
</style>
