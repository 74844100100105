<template>
  <div
    :id="'benefit-type-' + benefitData.benefit_gdd_id"
    class="benefit-type"
    :class="[
      'benefit-type-' + benefitData.benefit_rarity,
      {
        selected: isSelected,
        inactive: (benefitData.amount <= 0 && !possibleSelect) || inactive,
      },
    ]"
  >
    <div class="benefit-type-head" :class="'benefit-type-head-' + benefitData.benefit_rarity">
      <span class="benefit-type-head-texts">
        <p class="text-texts-standard-default text-30" :class="{ 'font-bold': $isSsm }">
          {{ benefitData.benefit_name }}
        </p>
        <p
          v-if="isSelected"
          class="text-texts-standard-default text-34"
          :class="{ 'font-bold uppercase': $isWsm }"
        >
          {{ $t('benefits.selected') }}
        </p>
        <span
          v-if="benefitData.benefit_rarity !== 'gold' && possibleUpgrade && possibleSelect"
          class="flexing"
        >
          <p class="text-texts-standard-upgrade text-30 uppercase font-bold text-upgrade">
            {{ $t('benefits.upgrade') }}
          </p>
          <span class="triangle triangle-up green mx-2" />
        </span>
      </span>
    </div>
    <div class="benefit-type-inside" />
    <div class="benefit-type-content">
      <div class="benefit-type-content-image">
        <app-main-icon :icon-size="120" :icon-name="'benefit-' + benefitData.benefit_gdd_id" />
      </div>
      <div
        class="benefit-type-content-count flexing"
        :class="'benefit-type-content-count-' + benefitData.benefit_rarity"
      >
        <p class="text-texts-standard-default text-50 font-bold">
          {{ benefitData.amount }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { BenefitSlotData } from '@/interfaces/Benefits'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'BenefitBox',
  props: {
    benefitData: {
      type: Object as PropType<BenefitSlotData>,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    possibleSelect: {
      type: Boolean,
      default: false,
    },
    inactive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    possibleUpgrade(): boolean {
      return Number(this.benefitData.amount) >= Number(this.benefitData.benefit_count_to_upgrade)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/benefits/benefitBox.scss';
</style>
