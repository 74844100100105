<template>
  <div class="w-full h-full flex flex-col justify-center items-center">
    <button
      v-if="state === MilestoneState.Open && !isClaimed && !isRepeatable"
      class="claim-btn clickable-element flex flex-col justify-center items-center cursor-pointer absolute z-1"
      :class="[
        {
          '-bottom-10 pt-2': isPassReward,
          '-top-8 pb-4': !isPassReward,
          'is-pass-reward': isPassReward,
          'is-pass-reward-locked mb-2 pointer-events-none':
            isPassReward && !isTournamentsPassActive,
        },
      ]"
      :disabled="isClaiming"
      @click="claim"
    >
      <p
        v-if="!isClaiming"
        class="text-36 font-bold"
        :class="
          isPassReward && !isTournamentsPassActive
            ? 'text-texts-standard-default'
            : 'text-texts-standard-dark'
        "
      >
        {{ $t('tournaments.claim') }}
      </p>
      <component-loading v-else :is-loading="true" height="100%" />
    </button>
    <div
      class="milestone-reward flex flex-col justify-center items-center relative"
      :class="[
        `is-${state}`,
        {
          'is-pass-reward': isPassReward,
          'is-claimed': isClaimed,
          'opacity-50': state === MilestoneState.Locked && !isPassReward,
        },
      ]"
    >
      <app-icon
        v-if="isPassReward && state === MilestoneState.Locked"
        icon-name="lock-sm"
        class="absolute top-0 -right-4"
      />
      <app-icon v-if="isClaimed" icon-name="done-md" class="absolute -bottom-4 -right-6" />
      <div class="milestone-reward-icon mt-4">
        <app-main-icon :icon-name="rewardIcon" :icon-size="72" />
      </div>
      <div class="milestone-reward-value text-40">
        <p>{{ reward.value }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useTournamentsMilestonesStore } from '@/store/pinia/tournaments/useTournamentsMilestonesStore'
import {
  MilestoneState,
  type MilestoneReward,
} from '@/interfaces/responses/tournaments/TournamentsMilestonesBoardGamesEventStateApiResponse'
import { defineComponent, type PropType } from 'vue'
import { mapActions, mapState } from 'pinia'

interface ComponentData {
  MilestoneState: typeof MilestoneState
  isClaiming: boolean
}

export default defineComponent({
  props: {
    milestoneId: {
      type: Number,
      required: true,
    },
    state: {
      type: String as PropType<MilestoneState>,
      required: true,
    },
    reward: {
      type: Object as PropType<MilestoneReward>,
      required: true,
    },
    isClaimed: {
      type: Boolean,
      default: false,
    },
    isPassReward: {
      type: Boolean,
      default: false,
    },
    isRepeatable: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      MilestoneState,
      isClaiming: false,
    }
  },
  computed: {
    ...mapState(useTournamentsMilestonesStore, ['isTournamentsPassActive']),
    rewardIcon(): string {
      if (this.reward.name === 'benefit') return `benefit-${this.reward.level}`
      if (this.reward.name === 'grand_prize') return `gp-${this.reward.rarity}`
      return this.reward.name
    },
  },
  methods: {
    ...mapActions(useTournamentsMilestonesStore, ['claimReward']),
    async claim(): Promise<void> {
      if (this.state === MilestoneState.Locked) return
      if (this.isPassReward && !this.isTournamentsPassActive) return

      this.isClaiming = true
      await this.claimReward(this.milestoneId)
      this.isClaiming = false
    },
  },
})
</script>

<style lang="scss" scoped>
.claim-btn {
  width: 12.5rem;
  height: 4.563rem;
  @include background(url('#{$path-images}/tournaments/milestones/claim-bg.avif?v1'), contain);

  &.is-pass-reward {
    background-image: url('#{$path-images}/tournaments/milestones/claim-bottom-bg.avif?v1');

    &.is-pass-reward-locked {
      width: 12.125rem;
      height: 4.125rem;
      background-image: url('#{$path-images}/tournaments/milestones/claim-bottom-locked-bg.avif?v1');
    }
  }
}

.milestone-reward {
  @include background(null, contain);

  &-icon {
    height: 5rem;
  }

  &.is-open {
    width: 10rem;
    height: 12.5rem;
    background-image: url('#{$path-images}/tournaments/milestones/rewards/milestone-reward-normal-bg.avif?v1');
  }

  &.is-locked {
    width: 8.625rem;
    height: 11.125rem;
    background-image: url('#{$path-images}/tournaments/milestones/rewards/milestone-reward-locked-bg.avif?v1');
  }

  &.is-pass-reward {
    width: 8.625rem;
    height: 11.125rem;
    background-image: url('#{$path-images}/tournaments/milestones/rewards/milestone-reward-champions-bg.avif?v1');
  }

  &.is-claimed {
    width: 8.625rem;
    height: 11.125rem;
    background-image: url('#{$path-images}/tournaments/milestones/rewards/milestone-reward-done-individual-bg.avif?v1');

    &.is-pass-reward {
      background-image: url('#{$path-images}/tournaments/milestones/rewards/milestone-reward-done-premium-bg.avif?v1');
    }
  }
}
</style>
