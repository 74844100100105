<template>
  <section :data-event-theme="namespace" class="personal-milestone">
    <milestones-sub-header :is-repeatable="currentMilestone === repeatable?.milestone" />
    <section class="personal-mls-middle flexing relative">
      <app-scrollbar
        class="flex personal-mls-middle-scroll items-center"
        width="100%"
        height="100%"
        scroll="x"
        slice="x"
        :arrows-z-index="10"
        :arrows="true"
        :scroll-to-element="`#milestone-${currentMilestone}`"
      >
        <div
          v-for="(task, index) in combinedTasks"
          :id="`milestone-${index}`"
          :key="index"
          class="rewards-section h-full flex justify-center items-center relative"
          :class="{
            'rewards-section--locked-future': index > currentMilestone,
            'rewards-section--early-access': task.individual.reward.some(
              (reward) => reward.type === EVENT_EARLY_ACCESS,
            ),
            'rewards-section--repeatable': task.individual.repeatable,
          }"
        >
          <div v-if="index !== 0" class="rewards-section-box-wrapper flexing">
            <div
              v-for="(reward, rewardIndex) in task.individual.reward"
              :key="rewardIndex"
              class="rewards-section-box flexing relative"
              :class="{
                'claim-box':
                  currentMilestone >= task.individual.milestone &&
                  task.individual.claimed === false,
                'claimed-box':
                  currentMilestone >= task.individual.milestone &&
                  task.individual.claimed &&
                  !task.individual.repeatable,
                'is-early-access': reward.type === EVENT_EARLY_ACCESS,
              }"
            >
              <div
                v-if="
                  currentMilestone >= task.individual.milestone && task.individual.claimed === false
                "
                class="top-box cursor-pointer absolute top-0"
                @click="claim(task.individual.milestone)"
              >
                <p class="font-bold text-36 text-texts-standard-dark claim-text">
                  {{ $te('commonClaim') }}
                </p>
              </div>
              <template v-if="reward.type === EVENT_EARLY_ACCESS && 'discipline_id' in reward">
                <div
                  v-tippy="
                    reward.type === EVENT_EARLY_ACCESS && {
                      theme: 'WSM',
                      content: $replacePlaceholder(
                        $te('milestones.earlyAccessMilestoneText'),
                        '{discipline}',
                        $t(`discipline.discipline_${reward.discipline_id}`),
                      ),
                      placement: 'top',
                      maxWidth: '40rem',
                    }
                  "
                  class="milestone-discipline-icon flexing"
                >
                  <app-discipline-icon
                    :discipline-id="reward.discipline_id"
                    :size="90"
                    :has-tooltip="false"
                  />
                </div>
                <div class="milestone-ribbon flexing mt-2">
                  <p class="milestone-ribbon-text text-24 font-bold lowercase">
                    {{ $te('milestones.earlyAccess') }}
                  </p>
                </div>
              </template>
              <template v-else>
                <app-main-icon :icon-name="reward.type" :icon-size="90" />
                <p class="text-texts-standard-default text-40 mt-1">
                  {{ $filters.$formatNumber(reward.value) }}
                </p>
              </template>
              <app-icon
                v-if="task.individual.claimed && !task.individual.repeatable"
                icon-name="done-md"
                class="absolute bottom-0 right-0"
              />
            </div>
          </div>
          <div v-else class="rewards-section-basic text-40 uppercase flexing font-bold">
            <p class="gradient-text-light pr-4">
              {{ $te('basicRewards') }}
            </p>
          </div>
          <tippy
            theme="WSM1"
            class="flexing relative rewards-section-box-spacing"
            placement="top"
            max-width="50rem"
          >
            <template #content>
              <div class="lock-tooltip">
                <div class="lock-tooltip-title flexing">
                  <p class="uppercase font-bold text-30">
                    {{
                      $te(namespace === 'discipline' ? 'reputationRequired' : 'eventPointsRequired')
                    }}
                  </p>
                </div>
                <p class="lock-tooltip-text-margin flexing text-28">
                  <b>{{ $filters.$formatNumber(task.individual.points) }}</b>
                  <app-main-icon
                    v-if="['challenge_cup', 'frozen'].includes(namespace)"
                    :icon-size="56"
                    :icon-name="EVENT_POINTS"
                    class="ml-2"
                  />
                  <app-icon
                    v-else-if="namespace === 'discipline'"
                    :icon-name="EVENT_REPUTATION"
                    class="ml-2"
                  />
                </p>
              </div>
              <div v-if="task.individual.repeatable" class="lock-tooltip">
                <div class="lock-tooltip-title flexing">
                  <p class="uppercase font-bold text-30">
                    {{ $te('milestones.repeatableMilestoneTitle') }}
                  </p>
                </div>
                <p class="lock-tooltip-text-margin flexing text-28">
                  {{ $te('milestones.repeatableMilestoneText') }}
                </p>
              </div>
            </template>
            <div
              class="rewards-section-milestone flexing text-60 relative"
              :class="{
                'is-claimed': task.individual.claimed || index === 0,
                'is-active': currentMilestone === task.individual.milestone,
                'is-repeatable': task.individual.repeatable,
              }"
            >
              <p
                v-if="!task.individual.repeatable"
                class="gradient-text-light font-bold"
                :class="index <= 9 ? 'centrer--one-digit-number' : 'centrer--two-digit-number'"
              >
                {{ index }}
              </p>
              <app-icon
                v-if="index > currentMilestone"
                class="absolute box-icon-lock"
                :icon-name="'lock-sm'"
              />
            </div>
          </tippy>
          <div
            v-if="index !== combinedTasks.length - 1"
            class="progress-wrapper absolute"
            :class="{
              'is-early-access--prev':
                combinedTasks.length - 1 !== index &&
                combinedTasks[index + 1].individual.reward.some(
                  (reward) => reward.type === EVENT_EARLY_ACCESS,
                ),
              'is-early-access--next': task.individual.reward.some(
                (reward) => reward.type === EVENT_EARLY_ACCESS,
              ),
              'is-repeatable flexing':
                combinedTasks.length - 1 !== index &&
                combinedTasks[index + 1].individual.repeatable,
            }"
          >
            <div
              class="progress-wrapper-slide"
              :class="
                currentMilestone === task.individual.milestone
                  ? 'progress-wrapper-slide--current-progress'
                  : ''
              "
              :style="{ width: calculateMilestoneProgress(task.individual.milestone) + '%' }"
            />
          </div>
          <div
            v-if="index !== 0"
            class="rewards-section-pass-box flexing relative"
            :class="{
              'claim-pass-box':
                currentMilestone >= task.premium.milestone && task.premium.claimed === false,
              'claimed-pass-box':
                isPassActive &&
                currentMilestone >= task.premium.milestone &&
                task.premium.claimed &&
                !task.premium.repeatable,
            }"
            @click="redirectToPasses"
          >
            <app-main-icon :icon-name="task.premium.reward[0].type" :icon-size="90" />
            <p class="text-texts-standard-default text-40 mt-1">
              {{ $filters.$formatNumber(task.premium.reward[0].value) }}
            </p>
            <template v-if="!isPassActive">
              <div
                v-if="!task.premium.claimed && task.premium.milestone <= currentMilestone"
                class="bottom-box bottom-box--inactive absolute flexing"
              >
                <p class="font-bold text-36 text-texts-standard-default">
                  {{ $te('commonClaim') }}
                </p>
              </div>
              <app-icon class="absolute box-icon-lock" icon-name="lock-sm" />
            </template>
            <template v-if="isPassActive && task.premium.milestone <= currentMilestone">
              <div
                v-if="!task.premium.claimed"
                class="bottom-box cursor-pointer absolute flexing"
                @click="claim(task.premium.milestone, true)"
              >
                <p class="font-bold text-36 text-texts-standard-dark">
                  {{ $te('commonClaim') }}
                </p>
              </div>
              <app-icon
                v-else-if="!task.premium.repeatable"
                icon-name="done-md"
                class="absolute bottom-0 right-0"
              />
            </template>
          </div>
          <div
            v-else
            class="rewards-section-pass-basic flexing text-40 font-bold uppercase px-8"
            :class="[isPassActive ? 'pass-claimed' : 'pass-available cursor-pointer']"
            @click="redirectToPasses"
          >
            <p v-if="!isPassActive" class="gradient-text-light">
              {{ $te('buttonPurchase') }}
            </p>
            <p class="pass-name gradient-text-light">{{ $te(`${passType}pass`) }}</p>
          </div>
        </div>
      </app-scrollbar>
    </section>
  </section>
</template>

<script lang="ts">
import { EVENT_EARLY_ACCESS, EVENT_POINTS, EVENT_REPUTATION } from '@/globalVariables'
import { PassType } from '@/interfaces/events/Passes'
import { useMilestonesStore } from '@/store/pinia/events/milestonesStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import MilestonesSubHeader from '../MilestonesSubHeader.vue'
import { MilestoneType, MilestoneMainComponent } from '@/interfaces/events/Milestones'
import type MilestonesConfigApiResponse from '@/interfaces/responses/events/milestones/MilestonesConfigApiResponse'
import type MilestonesStateApiResponse from '@/interfaces/responses/events/milestones/MilestonesStateApiResponse'

interface CombinedMilestone {
  [MilestoneType.Individual]: MilestonesConfigApiResponse['branches'][0]['milestones'][0] & {
    repeatable: boolean
    claimed: MilestonesStateApiResponse['rewarded'][0]['milestones'][0]['claimed']
  }
  [MilestoneType.Premium]: MilestonesConfigApiResponse['branches'][0]['milestones'][0] & {
    repeatable: boolean
    claimed: MilestonesStateApiResponse['rewarded'][0]['milestones'][0]['claimed']
  }
}

interface ComponentData {
  EVENT_POINTS: typeof EVENT_POINTS
  EVENT_REPUTATION: typeof EVENT_REPUTATION
  EVENT_EARLY_ACCESS: typeof EVENT_EARLY_ACCESS
  individualClaimButtonLock: Record<number, boolean>
  premiumClaimButtonLock: Record<number, boolean>
}

export default defineComponent({
  name: MilestoneMainComponent.Personal,
  components: {
    MilestonesSubHeader,
  },
  data(): ComponentData {
    return {
      EVENT_POINTS,
      EVENT_REPUTATION,
      EVENT_EARLY_ACCESS,
      individualClaimButtonLock: {},
      premiumClaimButtonLock: {},
    }
  },
  computed: {
    ...mapState(useMilestonesStore, {
      repeatable: 'getRepeatable',
      individualTasks: 'getIndividualBranchTasks',
      premiumTasks: 'getPremiumBranchTasks',
      individualRewards: 'getIndividualBranchRewards',
      premiumRewards: 'getPremiumBranchRewards',
      currentMilestonePoints: 'getCurrentMilestonePoints',
      currentMilestone: 'getCurrentMilestoneNumber',
      isPassActive: 'isPassActive',
    }),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
      eventDisciplineId: 'getEventDisciplineId',
      eventCustomProps: 'getEventCustomProps',
    }),
    combinedTasks(): CombinedMilestone[] {
      const combinedTasks: CombinedMilestone[] = [
        {
          [MilestoneType.Individual]: {
            milestone: 0,
            points: 0,
            reward: [],
            repeatable: false,
            claimed: false,
          },
          [MilestoneType.Premium]: {
            milestone: 0,
            points: 0,
            reward: [],
            repeatable: false,
            claimed: false,
          },
        },
      ]

      for (let i = 0; i < this.individualTasks.length; i++) {
        combinedTasks.push({
          [MilestoneType.Individual]: {
            ...this.individualTasks[i],
            repeatable: this.individualRewards[i].milestone === this.repeatable?.milestone,
            claimed: this.individualRewards[i].claimed,
          },
          [MilestoneType.Premium]: {
            ...this.premiumTasks[i],
            repeatable: this.premiumTasks[i].milestone === this.repeatable?.milestone,
            claimed: this.premiumRewards[i].claimed,
          },
        })
      }

      return combinedTasks
    },
    passType(): PassType {
      return [PassType.Champion, PassType.Master].find((passType: PassType): boolean =>
        this.eventCustomProps.passes.includes(passType),
      )
    },
  },
  methods: {
    ...mapActions(useMilestonesStore, ['claimRewards']),
    async claim(milestone: number, premium: boolean = false): Promise<void> {
      if (
        milestone > this.currentMilestone ||
        this[premium ? 'premiumClaimButtonLock' : 'individualClaimButtonLock'][milestone]
      )
        return
      this[premium ? 'premiumClaimButtonLock' : 'individualClaimButtonLock'][milestone] = true

      await this.claimRewards({ milestone, premium, type: MilestoneType.Individual })
    },
    calculateMilestoneProgress(value: number): number {
      if (value === this.currentMilestone)
        return (this.currentMilestonePoints.points / this.currentMilestonePoints.next_points) * 100
      else if (value < this.currentMilestone) return 100
      else return 0
    },
    redirectToPasses(): void {
      if (this.isPassActive) return
      this.$router.push({
        name: this.$getWebView('EventsPasses'),
        query: { pass: this.passType },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';

[data-event-theme='challenge_cup'] {
  @if $isWsm {
    --milestones-progress-bg: url('#{$path-events}shared/milestones/milestones-progress-wrapper.avif');
    --milestones-circle: url('#{$path-events}shared/milestones/milestones-circle.avif');
    --milestones-circle-active: url('#{$path-events}shared/milestones/milestones-progress-active.avif');
    --milestones-circle-done: url('#{$path-events}shared/milestones/milestones-circle.avif');
  }

  @if $isSsm {
    --milestones-progress-bg: url('#{$path-events}shared/milestones/milestones-progress-wrapper-cc.avif');
    --milestones-circle: url('#{$path-events}shared/milestones/milestones-circle-cc.avif');
    --milestones-circle-active: url('#{$path-events}shared/milestones/milestones-progress-active-cc.avif');
    --milestones-circle-done: url('#{$path-events}shared/milestones/milestones-circle-done-cc.avif');
  }

  --milestones-basic-box: url('#{$path-events}challenge-cup/milestones/milestones-basic-box.avif');
  --milestones-reward-box: url('#{$path-events}challenge-cup/milestones/milestones-club-reward-box.avif');
  --milestones-reward-box-claim: url('#{$path-events}challenge-cup/milestones/milestones-box-claim.avif');
  --milestones-reward-box-done: url('#{$path-events}shared/milestones/milestones-box-done.avif');
}

[data-event-theme='frozen'] {
  --milestones-progress-bg: url('#{$path-events}shared/milestones/milestones-progress-wrapper.avif');
  --milestones-circle: url('#{$path-events}shared/milestones/milestones-circle.avif');
  --milestones-circle-active: url('#{$path-events}shared/milestones/milestones-progress-active.avif');

  @if $isWsm {
    --milestones-circle-done: url('#{$path-events}shared/milestones/milestones-circle.avif');
  }

  @if $isSsm {
    --milestones-circle-done: url('#{$path-events}shared/milestones/milestones-circle-done.avif');
  }

  --milestones-basic-box: url('#{$path-events}frozen-championship/milestones/milestones-basic-box.avif');
  --milestones-reward-box: url('#{$path-events}frozen-championship/milestones/milestones-club-reward-box.avif');
  --milestones-reward-box-claim: url('#{$path-events}frozen-championship/milestones/milestones-box-claim.avif');
  --milestones-reward-box-done: url('#{$path-events}shared/milestones/milestones-box-done.avif');
}

[data-event-theme='discipline'] {
  --milestones-progress-bg: url('#{$path-events}shared/milestones/milestones-progress-wrapper.avif');
  --milestones-circle: url('#{$path-events}shared/milestones/milestones-circle.avif');
  --milestones-circle-active: url('#{$path-events}shared/milestones/milestones-progress-active.avif');

  @if $isWsm {
    --milestones-circle-done: url('#{$path-events}shared/milestones/milestones-circle.avif');
  }

  @if $isSsm {
    --milestones-circle-done: url('#{$path-events}shared/milestones/milestones-circle-done.avif');
  }

  --milestones-basic-box: url('#{$path-events}discipline-event/milestones/milestones-box-basic.webp');

  --milestones-reward-box: url('#{$path-events}discipline-event/milestones/milestones-reward-box.webp');
  --milestones-reward-box-claim: url('#{$path-events}discipline-event/milestones/milestones-reward-box-claim.webp');
  --milestones-reward-box-done: url('#{$path-events}discipline-event/milestones/milestones-reward-box-done.webp');

  --milestones-reward-box-early-access: url('#{$path-events}discipline-event/milestones/milestones-reward-box-early-access.webp');
  --milestones-reward-box-early-access-claim: url('#{$path-events}discipline-event/milestones/milestones-reward-box-early-access-claim.webp');
  --milestones-reward-box-early-access-done: url('#{$path-events}discipline-event/milestones/milestones-reward-box-early-access-done.webp');

  --milestones-reward-box-early-access-wrapper-bg: url('#{$path-events}discipline-event/milestones/milestones-reward-box-early-access-wrapper-bg.webp');
  --milestones-reward-box-early-access-ribbon-bg: url('#{$path-events}discipline-event/milestones/milestones-reward-box-early-access-ribbon-bg.webp');
}

[data-event-theme='autumn'] {
  --milestones-progress-bg: url('#{$path-events}shared/milestones/milestones-progress-wrapper.avif');
  --milestones-circle: url('#{$path-events}shared/milestones/milestones-circle.avif');
  --milestones-circle-active: url('#{$path-events}shared/milestones/milestones-progress-active.avif');

  @if $isWsm {
    --milestones-circle-done: url('#{$path-events}shared/milestones/milestones-circle.avif');
  }

  @if $isSsm {
    --milestones-circle-done: url('#{$path-events}shared/milestones/milestones-circle-done.avif');
  }

  --milestones-basic-box: url('#{$path-events}autumn-fair/milestones/milestones-basic-box.avif');
  --milestones-reward-box: url('#{$path-events}autumn-fair/milestones/milestones-club-reward-box.avif');
  --milestones-reward-box-claim: url('#{$path-events}autumn-fair/milestones/milestones-box-claim.avif');
  --milestones-reward-box-done: url('#{$path-events}shared/milestones/milestones-box-done.avif');
}

.personal-mls-middle {
  position: relative;
  top: -0.75rem;
  height: 41.625rem;

  &-scroll {
    margin: 0 2.125rem;

    .scroll__arrow {
      z-index: 11 !important;
    }
  }

  .rewards-section {
    flex-direction: column;
    width: 14rem;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 50%;
      top: 0;
      z-index: 0;
    }

    &:nth-child(even) {
      &::before {
        background: rgba(15, 21, 44, 0.6);
        background: linear-gradient(0deg, rgba(15, 21, 44, 0.6) 0%, rgba(15, 21, 44, 0) 100%);
      }
    }

    &:nth-child(2n - 1) {
      &::before {
        background: rgba(52, 63, 105, 0.6);
        background: linear-gradient(0deg, rgba(52, 63, 105, 0.6) 0%, rgba(52, 63, 105, 0) 100%);
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 50%;
      bottom: 0;
      z-index: 0;
    }

    &:nth-child(even) {
      &::after {
        background: rgba(160, 90, 4, 0.3);
        background: linear-gradient(0deg, rgba(160, 90, 4, 0.3) 30%, rgba(160, 90, 4, 0) 100%);
      }
    }

    &:nth-child(2n - 1) {
      &::after {
        background: rgba(222, 133, 24, 0.3);
        background: linear-gradient(0deg, rgba(222, 133, 24, 0.3) 30%, rgba(222, 133, 24, 0) 100%);
      }
    }

    &:first-child {
      width: 16rem;

      .progress-wrapper {
        width: 15rem;
        left: 8rem;
      }
    }

    &:first-child {
      &::before,
      &::after {
        content: none;
      }
    }

    &--locked-future {
      .rewards-section-box {
        opacity: 0.4;
      }
    }

    &--early-access {
      width: 28.5rem;

      .rewards-section-box-wrapper {
        position: relative;
        width: 28.5rem;
        justify-content: space-around;

        &::before {
          content: '';
          position: absolute;
          bottom: -3.75rem;
          left: 0;
          width: 28.5rem;
          height: 23.063rem;
          @include background(var(--milestones-reward-box-early-access-wrapper-bg), contain);
        }
      }
    }

    &-box {
      @include background(var(--milestones-reward-box), contain);
      width: 11.3125rem;
      height: 13.8125rem;
      flex-direction: column;
      z-index: 1;

      &.is-early-access {
        background-image: var(--milestones-reward-box-early-access);

        .milestone-ribbon {
          width: 11.875rem;
          height: 3.563rem;
          @include background(var(--milestones-reward-box-early-access-ribbon-bg), contain);

          &-text {
            color: #233441;
          }
        }
      }

      .top-box {
        @include background(
          url('#{$path-events}shared/milestones/milestones-top-claim.avif'),
          contain
        );
        width: 12.5rem;
        height: 4.5625rem;
        top: -1.5rem;

        &:active {
          transform: scale(0.95);
        }

        .claim-text {
          padding-top: 0.1rem;
        }
      }

      &-spacing {
        margin: 1.5rem 6.937rem 0;
        top: -1.25rem;
      }
    }

    .claim-box {
      @include background(var(--milestones-reward-box-claim), contain);

      &.is-early-access {
        background-image: var(--milestones-reward-box-early-access-claim);
      }
    }

    .claimed-box {
      @include background(var(--milestones-reward-box-done), contain);

      &.is-early-access {
        background-image: var(--milestones-reward-box-early-access-done);
      }
    }

    &-basic {
      @include background(var(--milestones-basic-box), contain);
      width: 15.25rem;
      height: 13.75rem;
      z-index: 1;
    }

    &-milestone {
      @include background(var(--milestones-circle), contain);
      width: 7.313rem;
      height: 7.375rem;
      z-index: 9;

      &.is-repeatable {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 3.625rem;
          height: 3.688rem;
          margin-top: 0.1875rem;
          margin-left: 0.0625rem;
          @include background(url('#{$path-events}shared/milestones/ico-repeat.webp'), contain);
        }
      }

      .box-icon-lock {
        right: 0.15rem;
        bottom: 0.5rem;
      }

      p {
        margin-top: 0.1rem;
        margin-left: -0.1rem;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .centrer {
        &--one-digit-number {
          margin-left: 0rem;
          margin-bottom: -0.15rem;
        }

        &--two-digit-number {
          margin-left: -0.35rem;
          margin-bottom: -0.15rem;
        }
      }
    }

    .is-active {
      @include background(var(--milestones-circle-active), contain);
    }

    .is-claimed {
      @include background(var(--milestones-circle-done), contain);
    }

    .progress-wrapper {
      @include background(var(--milestones-progress-bg), contain, center, repeat);
      width: 14rem;
      height: 8.5625rem;
      left: 7rem;
      top: 15.5rem;
      z-index: 7;

      &-slide {
        @include background(
          url('#{$path-events}shared/milestones/progress-bar-fill--glowless.avif'),
          contain,
          center,
          repeat
        );
        height: 1.875rem;
        left: 0;
        position: absolute;
        top: 52.5%;
        transform: translate(0, -52%);
        -webkit-filter: drop-shadow(0px 0px 5px rgb(249 227 152));
        filter: drop-shadow(0px 0px 5px rgb(249 227 152));

        &--current-progress {
          &:last-of-type::after {
            content: '';
            position: absolute;
            right: -1.188rem;
            top: 0;
            @include background(
              url('#{$path-events}shared/milestones/progress-bar-fill-end--glowless.avif'),
              contain,
              left center
            );
            height: 100%;
            width: 1.25rem;
          }
        }
      }

      &.is-early-access--prev,
      &.is-early-access--next {
        width: 21.25rem;
      }

      &.is-early-access--next {
        left: 14.25rem;
      }

      &.is-repeatable {
        background-image: none;

        .progress-wrapper-slide {
          position: unset;
          background-image: url('#{$path-events}shared/milestones/milestones-repeatable-arrow.webp');
          background-repeat: no-repeat;
          width: 4.438rem;
          height: 4.75rem;
          transform: none;
        }
      }
    }
  }

  .rewards-section-pass {
    flex-direction: column;
    width: 12rem;

    &:first-child {
      width: 16rem;

      .progress-wrapper {
        width: 6.9rem;
        left: 11rem;
      }
    }

    &-box {
      @include background(
        url('#{$path-events}shared/milestones/milestones-box-pass.avif'),
        contain
      );
      width: 11.3125rem;
      height: 13.8125rem;
      flex-direction: column;
      z-index: 1;

      &.claim-pass-box {
        background-image: url('#{$path-events}shared/milestones/milestones-pass-box-claim.avif');
      }

      &.claimed-pass-box {
        background-image: url('#{$path-events}shared/milestones/milestones-pass-box-done.avif');
      }

      .box-icon-lock {
        right: 0.5rem;
        top: 1.25rem;
      }

      .bottom-box {
        @include background(
          url('#{$path-events}shared/milestones/milestones-bottom-pass-claim.avif'),
          contain
        );
        width: 12.5rem;
        height: 4.5625rem;
        bottom: -1.5rem;

        &:active {
          transform: scale(0.95);
        }

        &--inactive {
          @include background(
            url('#{$path-events}shared/milestones/milestones-bottom-lock-claim.avif'),
            contain
          );

          &:active {
            transform: none;
          }
        }
      }
    }

    &-basic {
      width: 15.25rem;
      height: 13.75rem;
      flex-direction: column;
      padding-top: 2.25rem;
      padding-bottom: 1.75rem;
      z-index: 1;
    }
  }

  .pass-claimed {
    @include background(
      url('#{$path-events}shared/milestones/milestones-pass-claimed-v1.webp'),
      contain
    );
  }

  .pass-available {
    @include background(
      url('#{$path-events}shared/milestones/milestones-pass-basic-v1.webp'),
      contain
    );
  }

  .pass-name {
    line-height: 1.125;
    margin-top: 0.25rem;
  }
}
</style>
