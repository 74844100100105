<template>
  <info-popup
    v-if="showPopup"
    width="85rem"
    height="46.25rem"
    :popup-title="$t('seasons.rankingPoints')"
    @close="$emit('close')"
  >
    <div class="flex flex-col ranking-points-wrapper">
      <div class="ranking-points-popup flex flex-col justify-center items-center">
        <p class="text-30 italic text-white ranking-points-popup-text">
          {{ $t('seasons.rankingPointsText1') }}
        </p>
        <div class="flex flex-col justify-center items-center ranking-points-popup-box">
          <p class="text-36 text-texts-standard-important italic">
            {{ $t('seasons.yourRankingPoints') }}
          </p>
          <parameter-box :value="Number(userRankingPoints)" />
        </div>
        <p class="text-30 text-texts-standard-important italic">
          {{ $t('seasons.rankingPointsText2') }}
        </p>
      </div>

      <div class="ranking-points-popup-rows">
        <div class="ranking-points-popup-rows-row flex justify-between items-center">
          <div class="ranking-points-popup-rows-row-arena" />
          <div class="ranking-points-popup-rows-row-text flex flex-col justify-start">
            <p class="text-36 text-white uppercase">
              {{ $t('map.arenaName') }}
            </p>
            <p class="text-28 text-texts-standard-additional">
              {{ $t('seasons.arenaBoxText') }}
            </p>
          </div>
          <app-button
            btn-type="primary"
            btn-size="xsm"
            :btn-text="$t('seasons.open')"
            @click="$router.push({ name: $getWebView('ArenaView') })"
          />
        </div>

        <div class="ranking-points-popup-rows-row flex justify-between items-center">
          <div class="ranking-points-popup-rows-row-ranking" />
          <div class="ranking-points-popup-rows-row-text flex flex-col justify-start">
            <p class="text-36 text-white uppercase">
              {{ $t('rankingsGroup.rankings') }}
            </p>
            <p class="text-28 text-texts-standard-additional">
              {{ $t('seasons.rankingPointsText3') }}
            </p>
          </div>
          <app-button
            btn-type="primary"
            btn-size="xsm"
            :btn-text="$t('seasons.open')"
            @click="$router.push({ name: $getWebView('RankingGlobal') })"
          />
        </div>
      </div>
    </div>
  </info-popup>
</template>

<script lang="ts">
import ParameterBox from '@/components/ParameterBox.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SeasonsSeasonalAttributesPopup',
  components: {
    InfoPopup,
    ParameterBox,
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  computed: {
    ...mapState(useUserStore, {
      userRankingPoints: 'getRankingPoints',
    }),
  },
})
</script>

<style lang="scss" scoped>
.ranking-points-wrapper {
  margin: 1rem;
}

.ranking-points-popup {
  &-text {
    margin: 1rem 3rem 2rem 3rem;
  }

  &-box {
    margin-bottom: 2rem;
  }

  &-rows {
    margin-top: 2rem;

    &-row {
      height: 7.625rem;
      width: 100%;
      padding: 1rem 2rem 1rem 2rem;
      margin-top: 0.5rem;

      @if $isWsm {
        background-color: #236d9b;
        border: 0.063rem solid #6c9ec4;
      }

      @if $isSsm {
        background-color: rgba(82, 84, 121, 0.7);
        border: 0.125rem solid #7477a4;
      }

      &:nth-child(even) {
        @if $isWsm {
          background-color: #417491;
        }

        @if $isSsm {
          background-color: rgba(114, 114, 176, 0.7);
        }
      }

      &-arena {
        background: url($path-benefits + '/benefit_building_arena.avif') center no-repeat;
        background-size: contain;
        width: 11.5rem;
        height: 5.938rem;
      }

      &-ranking {
        width: 10.188rem;
        height: 7.313rem;
        background: url($path-images + 'seasons/RANKING-ICO.avif') center no-repeat;
        background-size: contain;
      }

      &-text {
        text-align: left;
        width: 55%;
      }
    }
  }
}
</style>
