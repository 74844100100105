import { CREATE_PIGGY_BANK_OSRA, DESTROY_PIGGY_BANK_OSRA } from '@/map-phaser-new/constants'
import { SpecificObjectHandler } from '@/map-phaser-new/utils/abstractClasses'
import { PiggyBankOsra } from '@/map-phaser-new/models/objects/piggyBankOsra'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import type {
  AdditionalConfigsInterface,
  ExclamationNotificationConfigInterface,
  PiggyBankOsraConfigInterface,
} from '@/map-phaser-new/interfaces'

import { MECHANIC_PIGGY_BANK } from '@/globalVariables'
import { usePiggyBankStore } from '@/store/pinia/piggyBankStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'

export class PiggyBankOsraHandler extends SpecificObjectHandler {
  private osraInstance: PiggyBankOsra

  constructor() {
    super()
  }

  public async setUp(
    activeScene: Phaser.Scene,
    osraConfig: PiggyBankOsraConfigInterface,
    additionalConfigs: AdditionalConfigsInterface,
  ): Promise<void> {
    return new Promise((resolve: CallableFunction): void => {
      this.createOsra(activeScene, osraConfig, additionalConfigs.exclamationNotification)

      const eventEmitter = usePhaserGameIntegrationStore().getPhaserEventEmitter
      this.addOsraActionsToEmitter(
        eventEmitter,
        activeScene,
        osraConfig,
        additionalConfigs.exclamationNotification,
      )

      resolve()
    })
  }

  private createOsra(
    activeScene: Phaser.Scene,
    config: PiggyBankOsraConfigInterface,
    notificationConfig: ExclamationNotificationConfigInterface,
  ): void {
    if (this.osraInstance) return
    if (!useResponseTaskStore().getMechanics.includes(MECHANIC_PIGGY_BANK)) return
    if (!usePiggyBankStore().isActive) return

    this.osraInstance = new PiggyBankOsra(config.positionX, config.positionY)
    this.osraInstance.addToMap(activeScene, config, notificationConfig)
  }

  private destroyOsra(activeScene: Phaser.Scene): void {
    if (!this.osraInstance) return

    this.osraInstance.removeOsra(activeScene)
    this.osraInstance = null
  }

  private addOsraActionsToEmitter(
    eventEmitter: Phaser.Events.EventEmitter,
    activeScene: Phaser.Scene,
    osraConfig: PiggyBankOsraConfigInterface,
    notificationConfig: ExclamationNotificationConfigInterface,
  ): void {
    if (this.osraInstance) return

    eventEmitter.on(
      CREATE_PIGGY_BANK_OSRA,
      (): void => {
        this.createOsra(activeScene, osraConfig, notificationConfig)
      },
      this,
    )

    eventEmitter.on(
      DESTROY_PIGGY_BANK_OSRA,
      (): void => {
        this.destroyOsra(activeScene)
      },
      this,
    )
  }
}
