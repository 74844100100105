import type { SpriteDataInterface } from '@/map-phaser-new/interfaces'
import { mainObjectsSpriteLoadData } from './mainObjectsSpriteLoadData'

/**
 * This function is used to collect all imports of main sprites, to make loading at BootScene easier
 * @returns Promise<SpriteDataInterface[]>
 */
export const exportedMainSprites: () => Promise<SpriteDataInterface[]> = () => {
  return Promise.resolve([...mainObjectsSpriteLoadData()])
}
