import { defineStore } from 'pinia'
// import { useTutorialStore } from './tutorialStore'
import { RenderQueueComponent } from '@/enums/RenderQueueTypes'

interface RenderQueueState {
  queue: RenderQueueComponent[]
}

export const useRenderQueueStore = defineStore('renderQueueStore', {
  state: (): RenderQueueState => ({
    queue: [] as RenderQueueComponent[],
  }),
  getters: {
    getRenderQueue(): RenderQueueState['queue'] {
      return this.queue
    },
    getCurrentComponentInQueue(): RenderQueueComponent {
      return this.queue[0] ?? null
    },
  },
  actions: {
    addToRenderQueue(component: RenderQueueComponent): void {
      // TODO: pre Peta
      // const tutorialStore = useTutorialStore()
      if (window.isTutorial()) return

      if (
        [
          RenderQueueComponent.SeasonsEnd,
          RenderQueueComponent.OfferDetail,
          RenderQueueComponent.Minigame,
        ].includes(component)
      ) {
        if (
          component === RenderQueueComponent.OfferDetail &&
          this.queue.includes(RenderQueueComponent.SeasonsEnd)
        ) {
          const modifiedQueue = [...this.queue]
          const indexOfSeasonEnd = modifiedQueue.indexOf(RenderQueueComponent.SeasonsEnd)
          modifiedQueue.splice(indexOfSeasonEnd + 1, 0, RenderQueueComponent.OfferDetail)

          this.queue = modifiedQueue
          return
        }

        this.queue = [component, ...this.queue]
        return
      }

      this.queue = [...this.queue, component]
    },
    setNextInRenderQueue(): void {
      if (this.queue.length === 0) return

      const data = this.queue.slice(1)
      this.queue = data
    },
  },
})
