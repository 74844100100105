<template>
  <section class="club-chat-no-messages-wrapper">
    <div class="club-chat-no-messages">
      <p class="text-texts-standard-additional text-36 uppercase">
        {{ $t('club.chatNoMessages') }}
      </p>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ChatNoMessages',
})
</script>

<style lang="scss" scoped>
.club-chat {
  &-no-messages {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &-wrapper {
      margin-top: 1.25rem;
      flex: 1 1 auto;
    }
  }
}
</style>
