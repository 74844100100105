import { TOURNAMENTS } from '@/globalVariables'

import { StageType } from '@/interfaces/Tutorial'
import type { TutorialStage } from '@/interfaces/Tutorial'

/**
  Tutorial for unlocked mechanic TOURNAMENTS
 */

export default class TutorialUnlockTournaments {
  stages(): TutorialStage[] {
    return [
      // narative saying you unlocked a new mechanic - tournaments
      {
        name: 'unlockTournamentsNarrative',
        type: StageType.Narrative,
        overlay: false,
        mechanic: 10,
        pageName: 'LayoutViewTutorial',
        texts: [],
      },
      // focus on tutorial building
      {
        name: 'clickOnTournamentsBuilding',
        type: StageType.Focus,
        mechanic: 10,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        clientId: [TOURNAMENTS],
        texts: [],
      },
    ]
  }
}
