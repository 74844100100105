<template>
  <menu-component
    :menu-type="'clubs-centrum'"
    :title="$t('club.clubCentrum')"
    :menu-items="{
      1: {
        text: $t('club.buildings'),
        route: $getWebView('ClubsBuildings'),
      },
      2: {
        text: $t('club.employees'),
        route: $getWebView('ClubsEmployees'),
      },
    }"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClubsCentrumMenu',
})
</script>
