<template>
  <header
    class="events-warehouse-header relative flex items-center justify-between px-8 pt-2"
    :data-event-theme="namespace"
  >
    <event-pass-button :pass="PassType.Event" :show-active-pass-bg="true" />

    <div class="flex items-center">
      <app-button
        v-for="button in buttons"
        :key="button"
        :btn-type="button.name === activeName ? 'secondary' : 'inactive'"
        :btn-text="$te(button.name)"
        btn-size="md"
        class="ml-4"
        @click="milestonesSection(button)"
      />
      <app-icon class="iconer ml-4" icon-name="question" @click="showAboutPopup = true" />
    </div>
  </header>
  <div v-if="activeName === 'workshop'" class="info-panel w-full flexing p-2 relative">
    <h1 class="text-30 gradient-text-light font-bold italic">
      {{ $te('workshopInfoText') }}
    </h1>
  </div>

  <warehouse-about-popup
    v-if="showAboutPopup"
    :is-visible="showAboutPopup"
    @close="showAboutPopup = false"
  />
</template>

<script lang="ts">
import WarehouseAboutPopup from '@/components/Events/EventsPopups/WarehouseAboutPopup.vue'
import EventPassButton from '@/components/Events/GlobalEventsComponents/EventPassButton.vue'
import { PassType } from '@/interfaces/events/Passes'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  PassType: typeof PassType
  activeName: string
  showAboutPopup: boolean
  buttons: { name: string; component: string }[]
}

export default defineComponent({
  name: 'EventsWarehouseHeader',
  components: {
    EventPassButton,
    WarehouseAboutPopup,
  },
  emits: ['section'],
  data(): ComponentData {
    return {
      PassType,
      activeName: 'frozenLab',
      showAboutPopup: false,
      buttons: [
        {
          name: 'frozenLab',
          component: 'EventsWarehouseLab',
        },
        {
          name: 'workshop',
          component: 'ConvertorMain',
        },
        {
          name: 'frozenLeague',
          component: 'EventsWarehouseLeague',
        },
      ],
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
  },
  methods: {
    milestonesSection(section: { name: string }): void {
      this.activeName = section.name
      this.$emit('section', section)
    },
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='frozen'] {
  @if $isWsm {
    --events-chains-header-bg: rgba(0, 5, 25, 0.33);
  }

  @if $isSsm {
    --events-chains-header-bg: rgba(25, 27, 72, 0.6);
  }
}

.events-warehouse-header {
  height: 7.625rem;
  background-color: var(--events-chains-header-bg);
}

.info-panel {
  position: relative;
  padding: 1.375rem 0;
  height: 4.5rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include background(
      url($path-events + 'frozen-championship/warehouse/convertor/infoheader-bg.avif'),
      cover
    );
  }

  & > * {
    z-index: 1;
  }
}
</style>
