<template>
  <div class="avatar-menu-club-logo flex items-center cursor-pointer" @click="openClubs">
    <div
      v-if="hasClub"
      class="avatar-menu-club-logo-img"
      :style="{
        backgroundImage: `url(${pathImages}clubs/logos/logo/club-logo-${clubLogo}.avif)`,
      }"
    />
    <div v-else class="avatar-menu-club-logo-plus" />
  </div>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { NavigationFailure } from 'vue-router'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'AvatarMenuClubLogo',
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      clubLogo: 'getClubLogo',
      hasClub: 'getHasClub',
      myClubId: 'getPlayerClubId',
    }),
  },
  methods: {
    openClubs(): Promise<void | NavigationFailure> {
      if (this.hasClub) {
        return this.$router.push({
          name: 'ClubsMap',
          params: { clubId: this.myClubId },
        })
      } else {
        return this.$router.push({
          name: this.$getWebView('ClubsAvailable'),
          query: { homepage: 'true' },
        })
      }
    },
  },
})
</script>
