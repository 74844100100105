<template>
  <section class="ranking-submenu w-full flex items-center justify-between">
    <p
      class="discipline-text relative uppercase text-40 font-bold"
      :class="{
        'text-texts-standard-name': $isWsm,
        'text-texts-standard-default': $isSsm,
      }"
    >
      {{ discipline }}
    </p>
    <slot />
    <tippy theme="WSM" placement="left" max-width="35rem">
      <app-icon icon-name="info-70" />
      <template #content>
        <div class="tooltip-content text-texts-standard-default text-30">
          <p
            class="tooltip-content-bottom w-full"
            v-html="
              $replacePlaceholder(
                $t('rankingsGroup.rankingRefreshTime'),
                '%s',
                `<b class='text-texts-standard-important'>30</b>`,
              )
            "
          />
        </div>
      </template>
    </tippy>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    discipline: {
      type: String,
      default: 'Discipline name',
    },
  },
})
</script>

<style lang="scss" scoped>
.ranking-submenu {
  height: 4.375rem;

  .discipline-text {
    &:after {
      content: '';
      position: absolute;
      width: 22.5rem;
      height: 0.25rem;
      background-image: linear-gradient(to right, #fadd1e 80%, #fadd1e12);
      bottom: 0;
      left: 0;
    }
  }
}
</style>
