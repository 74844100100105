<template>
  <div class="lab-disciplines-tree flex justify-around">
    <lab-disciplines-column
      v-for="(col, i) in mutatedLabData"
      :key="i"
      :column-data="col"
      :column-index="i"
      :has-ongoing-research-tree="hasOngoingResearchTree"
      @select-skill="$emit('selectSkill', $event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { Cell } from '@/interfaces/lab/Cell'
import { LineDirection, LineStyle } from '@/interfaces/lab/Cell'
import LabDisciplinesColumn from '@/components/Lab/Disciplines/LabDisciplinesColumn.vue'
import type { PropType } from 'vue'

interface ComponentData {
  LineDirection: typeof LineDirection
  LineStyle: typeof LineStyle
}

export default defineComponent({
  name: 'LabDisciplinesTree',
  components: {
    LabDisciplinesColumn,
  },
  props: {
    labData: {
      type: Array as PropType<Cell[][]>,
      required: true,
    },
    hasOngoingResearchTree: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectSkill'],
  data(): ComponentData {
    return {
      LineDirection,
      LineStyle,
    }
  },
  computed: {
    mutatedLabData(): Cell[][] {
      // sluzi na doplnenie urovne (riadku) v strome schopnosti, hlavne pre pripady ak su dve bunky s rovnakym parentom na jednej urovni
      const data = this.labData

      this.labData.forEach((_: Cell[], colIndex: number): void => {
        let positionLevel = colIndex === 1 ? 1 : 0

        data[colIndex].map((cell: Cell, index: number) => {
          const prevCell = data[colIndex][index - 1]
          if (prevCell && prevCell?.parent !== cell.parent) {
            positionLevel++
          }
          data[colIndex][index].positionLevel = positionLevel
        })
      })

      return data
    },
  },
})
</script>

<style lang="scss" scoped>
.lab-disciplines-tree {
  height: 100%;
  gap: 4.313rem;
  justify-content: center;
  padding: 3rem 0;
}
</style>
