<template>
  <div v-if="showLoading" class="loading-screen flexing flex-col">
    <div class="loading-screen-logo" />
    <div class="loading-screen-athlets" />

    <section class="loading-screen-progressbar-wrapper flexing flex-col">
      <p class="text-50 text-texts-standard-default uppercase mb-3">loading...</p>
      <app-progress-bar
        class="loading-screen-progressbar"
        :bar-width="28.438"
        :bar-height="1.313"
        :text-size="2.25"
        :actual="currProgress"
        :goal="100"
      />
    </section>
  </div>
</template>

<script lang="ts">
import { CLUB_ASSISTANT, CLUB_MANAGER } from '@/globalVariables'
import { getIsMobileLocalStorage } from '@/helpers'
import { useNotificationStore } from '@/store/pinia/clubs/notificationStore'
import { useCoreStore } from '@/store/pinia/coreStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import { ApiService } from '@/services/ApiService'

interface ComponentData {
  showLoading: boolean
  actualProgress: number
  currProgress: number
  progressTimer: ReturnType<typeof setInterval> | undefined
  waitTimer: ReturnType<typeof setTimeout> | undefined
}

export default defineComponent({
  name: 'AppLoadingScreen',
  data(): ComponentData {
    return {
      showLoading: false,
      actualProgress: 0,
      currProgress: 0,
      progressTimer: undefined,
      waitTimer: undefined,
    }
  },
  computed: {
    ...mapState(useCoreStore, {
      isLoadingScreen: 'isLoadingScreen',
    }),
    ...mapState(useNotificationStore, {
      promotedRole: 'getPromotedRole',
    }),
    timeoutValue(): number {
      return getIsMobileLocalStorage() === 1 ||
        this.$isMobile() ||
        [CLUB_MANAGER, CLUB_ASSISTANT].includes(this.promotedRole)
        ? 0
        : 1500
    },
  },
  watch: {
    isLoadingScreen(value: boolean): void {
      if (!value) {
        this.finishProgress()
        setTimeout((): void => {
          this.showLoading = value
          ApiService.setIsFirstLoad(false)
        }, this.timeoutValue)
      } else {
        this.showLoading = value
      }
    },
  },

  created(): void {
    this.startProgress()
  },
  beforeUnmount(): void {
    this.finishProgress()
  },
  methods: {
    randomFrom(min: number, max: number): number {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    startProgress(): void {
      this.$nextTick(() => {
        window.requestAnimationFrame((): void => {
          this.currProgress = 0
          this.$nextTick((): void => {
            window.requestAnimationFrame(() => {
              const initProgress = this.randomFrom(20, 80)
              this.currProgress = initProgress

              this.progressTimer = setInterval((): void => {
                if (this.currProgress >= 100) {
                  clearInterval(this.progressTimer)
                  this.progressTimer = undefined
                }
                this.currProgress += (100 - this.currProgress) / this.randomFrom(10, 50)
              }, 400)
            })
          })
        })
      })
    },
    finishProgress(): void {
      this.currProgress = 100
      if (this.progressTimer) {
        clearInterval(this.progressTimer)
        clearInterval(this.waitTimer)
        this.progressTimer = undefined
        this.waitTimer = undefined
      }
    },
  },
})
</script>

<style lang="scss">
.loading-screen {
  background: burlywood;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1002;
  background: url($path-images + 'loading/loading-screen-bg.avif') center no-repeat;
  background-size: cover;

  &-logo {
    background: url($path-images + 'loading/loading-screen-logo.webp') center no-repeat;
    background-size: contain;
    width: 36.813rem;
    height: 19.063rem;
  }

  @if $isWsm {
    &-athlets {
      background: url($path-images + 'loading/loading-screen-athlets2.webp') center no-repeat;
      width: 119.188rem;
      height: 47.375rem;
      background-size: contain;
      top: 4.688rem;
    }
  }

  &-progressbar {
    &-wrapper {
      position: absolute;
      bottom: 3.5rem;
    }
  }
}
</style>
