<template>
  <video-background :video-id="YOUTUBE_ID" />
  <main class="w-full h-full homepage relative">
    <header class="w-full flex items-center justify-between">
      <div class="social flex items-center text-40">
        <p class="uppercase text-texts-standard-default">
          {{ $t('homepage.visitUs') }}
        </p>
        <a
          v-for="link in socialUrls"
          :key="link"
          class="social-img clickable-element"
          :href="link"
          target="_blank"
        />
      </div>
    </header>
    <transition name="welcome" appear>
      <section class="homepage-welcome absolute">
        <p class="text-texts-standard-additional welcome-text uppercase">Welcome to</p>
        <article class="homepage-welcome-img" />
        <footer class="homepage-welcome-buttons flex items-center justify-between m-auto">
          <a class="a-store h-full cursor-pointer" :href="appleStoreUrl" target="_blank" />
          <a
            class="g-store h-full cursor-pointer clickable-element"
            :href="googleStoreUrl"
            target="_blank"
          />
        </footer>
      </section>
    </transition>
    <transition name="login" appear>
      <div class="homepage-reset absolute">
        <login-form-with-flow :context="LoginFormContext.PASSWORD_RESET" />
      </div>
    </transition>
    <footer
      class="homepage-footer items-center flex justify-between text-30 text-texts-standard-default absolute w-full"
    >
      <div class="left-text flex items-center justify-between">
        <a
          class="clickable-element"
          :href="`${ppsSiteUrl}${languageCode}/license/`"
          target="_blank"
        >
          {{ $t('homepage.license') }}
        </a>
        /
        <a
          class="clickable-element"
          :href="`${ppsSiteUrl}${languageCode}/privacy-policy/`"
          target="_blank"
        >
          {{ $t('homepage.privacyPolicy') }}
        </a>
      </div>
      <p class="copyright">
        Copyright © 2011-{{ new Date().getFullYear() }} - POWERPLAY MANAGER, s.r.o. -
        {{ $t('homepage.allRightsReserved') }}
      </p>
    </footer>
  </main>
</template>

<script lang="ts">
import LoginFormWithFlow from '@/components/Login/LoginFormWithFlow.vue'
import VideoBackground from '@/components/VideoBackground.vue'
import {
  YOUTUBE_ID,
  appleStoreUrl,
  googleStoreUrl,
  ppsSiteUrl,
  wsmFbUrl,
  wsmIgUrl,
  ppsIgUrl,
} from '@/globalVariables'
import { defineComponent } from 'vue'
import { LoginFormContext } from '@/enums/LoginForm'

import type { LoginInputInterface } from '@/interfaces/Login'

interface ComponentData {
  YOUTUBE_ID: typeof YOUTUBE_ID
  googleStoreUrl: typeof googleStoreUrl
  appleStoreUrl: typeof appleStoreUrl
  ppsSiteUrl: typeof ppsSiteUrl
  form: LoginInputInterface[]
  link_to: {
    bool: boolean
    link: string
    text: string
  }
  socialUrls: string[]
  LoginFormContext: typeof LoginFormContext
}

export default defineComponent({
  name: 'ResetPassword',
  components: {
    LoginFormWithFlow,
    VideoBackground,
  },
  data(): ComponentData {
    return {
      YOUTUBE_ID,
      googleStoreUrl,
      appleStoreUrl,
      ppsSiteUrl,
      form: [
        {
          message: '',
          type: 'password',
          name: 'password',
          placeholder: 'newPassword',
          errors: [],
          pass: 'newPassword',
        },
        {
          message: '',
          type: 'password',
          name: 'password',
          placeholder: 'repeatNewPassword',
          errors: [],
          pass: 'repeatNewPassword',
        },
      ],
      socialUrls: this.$isWsm ? [wsmFbUrl, wsmIgUrl] : [wsmFbUrl, ppsIgUrl],
      link_to: {
        bool: true,
        link: '/',
        text: 'Back to login',
      },
      LoginFormContext,
    }
  },
  computed: {
    languageCode(): string {
      return localStorage.getItem('language') === 'sk' || localStorage.getItem('language') === 'cs'
        ? 'sk'
        : 'en'
    },
  },
})
</script>

<style lang="scss" scoped>
.homepage {
  background-size: cover;

  header {
    height: 8.9375rem;
    padding-left: 4.8125rem;

    .social {
      margin-right: 4.875rem;

      &-img {
        height: 4.375rem;
        width: 4.375rem;
        background: url($path-icons + 'ICO_SPRITE_SOCIAL_70px.avif') center no-repeat;
        background-position: 0rem;
        background-size: cover;
        margin: 0 0.5rem;

        &:nth-child(3) {
          background-position: -4.4rem;
        }

        &:nth-child(2) {
          margin-left: 1.125rem;
        }
      }
    }
  }

  .welcome-enter-to {
    left: 10%;
  }

  .welcome-enter-from {
    left: -10rem;
    opacity: 0;
  }

  .welcome-enter-active {
    transition: all 0.5s ease-in;
  }

  .login-enter-to {
    right: 10%;
  }

  .login-enter-from {
    right: -10rem;
    opacity: 0;
  }

  .login-enter-active {
    transition: all 0.5s ease-in;
  }

  &-welcome {
    top: 50%;
    transform: translate(0, -50%);
    height: 44.375rem;
    width: 49.75rem;
    left: 10%;

    &-img {
      @include background(null);
      width: 49.75rem;
      height: 40.3125rem;
      margin-top: -6rem;

      @if $isWsm {
        background-image: url('#{$path-homepage}/LOGO_WSM_LOGIN_NEW.avif');
      }

      @if $isSsm {
        background-image: url('#{$path-homepage}/LOGO_SSM_LOGIN_NEW.avif');
      }
    }

    .welcome-text {
      font-size: 4.5rem;
    }

    &-buttons {
      width: 34.25rem;
      height: 5.0625rem;
      margin-top: -3.5rem;

      .g-store {
        width: 17.125rem;
        background: url($path-homepage + 'BTN_GOOGLE_PLAY.avif') center no-repeat;
        background-size: contain;
      }

      .a-store {
        width: 15.1875rem;
        background: url($path-homepage + 'BTN_APP_STORE.avif') center no-repeat;
        background-size: contain;
      }
    }
  }

  &-reset {
    right: 10%;
    top: 50%;
    transform: translate(-10%, -50%);
  }

  &-footer {
    bottom: 2.375rem;
    padding: 0 4.9375rem;

    .left-text > a:nth-child(2) {
      margin-left: 1rem;
      text-decoration: underline;
    }

    .left-text > a:nth-child(1) {
      margin-right: 1rem;
      text-decoration: underline;
    }
  }
}
</style>
