<template>
  <div
    class="table-standard-row my my-staroftheday relative flex items-center"
    :class="{ 'font-bold': $isWsm }"
  >
    <div
      class="staroftheday-table-pos my-staroftheday flexing text-36 font-bold"
      :class="[
        `staroftheday-${viewType}-table-pos`,
        {
          'text-texts-standard-default': $isWsm,
          'text-texts-standard-dark': $isSsm,
        },
      ]"
    >
      {{ myResult.position }}
    </div>
    <div
      class="staroftheday-table-player flex items-center justify-start"
      :class="`staroftheday-${viewType}-table-player`"
    >
      <p
        class="staroftheday-table-player-country text-24 uppercase"
        :class="[
          `staroftheday-${viewType}-table-player-country`,
          { 'text-texts-standard-name': $isWsm, 'text-texts-standard-default': $isSsm },
        ]"
      >
        {{ myResult.country }}
      </p>
      <span
        class="staroftheday-table-player-flag"
        :class="`staroftheday-${viewType}-table-player-flag`"
        :style="{
          backgroundImage: 'url(' + pathImages + `flags/102x68/${myResult.country}` + '.avif)',
        }"
      />
      <p
        class="staroftheday-table-player-name table-standard-row-username flexing text-texts-standard-default text-32"
        :class="[
          !myResult.bot && myResult.id ? 'cursor-pointer' : '',
          `staroftheday-${viewType}-table-player-name`,
        ]"
        @click="
          !myResult.bot && myResult.id
            ? $router.push({
                name: $getWebView('ProfileOverview'),
                params: { id: 1, user: myResult.id },
              })
            : ''
        "
      >
        {{ myResult.name }}
      </p>
      <event-badge :current-badges="myResult.event_badges" class="ml-2" />
    </div>
    <div
      class="staroftheday-table-played text-texts-standard-default text-32"
      :class="`staroftheday-${viewType}-table-played`"
    >
      {{ myResult.playedDisciplines }}
    </div>
    <div
      class="staroftheday-table-points flexing text-texts-standard-default text-32 italic"
      :class="`staroftheday-${viewType}-table-points`"
    >
      {{ getPoints() }}
    </div>
    <div
      v-if="myResult.rewards && myResult.rewards.length > 0"
      class="staroftheday-table-rewards flex justify-center items-center"
      :class="`staroftheday-${viewType}-table-rewards`"
    >
      <div
        v-for="(reward, rewardIndex) in myResult.rewards"
        :key="rewardIndex"
        class="flex justify-end items-center mx-2"
      >
        <div class="text-3xl text-texts-standard-default">
          {{ $filters.$formatNumber(reward.value) }}
        </div>
        <app-main-icon
          :icon-size="48"
          :icon-name="
            reward.name.split('_')[0] === 'benefit'
              ? `benefit-${reward.name.split('_')[3]}`
              : reward.name
          "
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import EventBadge from '@/components/GlobalComponents/EventBadge.vue'
import { pathImages, STAR_OF_THE_DAY_STANDINGS } from '@/globalVariables'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { DailyLeagueOpponent } from '@/interfaces/responses/starOfTheDay/DailyLeagueResponse'
import { DailyLeagueRowViewType } from '@/interfaces/DailyLeague'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'StarofthedayMyResultRow',
  components: { EventBadge },
  props: {
    viewType: {
      type: String as PropType<DailyLeagueRowViewType>,
      default: DailyLeagueRowViewType.Standings,
      validator(value: DailyLeagueRowViewType): boolean {
        return [DailyLeagueRowViewType.Athlete, DailyLeagueRowViewType.Standings].includes(value)
      },
    },
    myResult: {
      type: Object as PropType<Nullable<DailyLeagueOpponent>>,
      default: () => null,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  methods: {
    getPoints(): number {
      return (
        (this.viewType === STAR_OF_THE_DAY_STANDINGS
          ? this.myResult.points
          : this.myResult.rankingPoints) ?? 0
      )
    },
  },
})
</script>

<style lang="scss" scoped>
.table-standard-row {
  width: 117.5rem;
  margin-bottom: 0.375rem;
  height: 4rem;

  &.my {
    margin-bottom: 1.625rem;
  }
}

.staroftheday-table-pos {
  width: 4.938rem;
  height: 100%;
  position: relative;
  @if $isWsm {
    background-color: #233441;
  }
  @if $isSsm {
    background-color: #fff;
  }

  &:before {
    content: '';
    height: 100%;
    width: 1rem;
    position: absolute;
    right: -0.975rem;
    background: rgba(20, 56, 88, 0.5);
    clip-path: polygon(0% 0%, 100% 0%, 0% 100%, 0% 100%);
  }

  &.gold {
    background: linear-gradient(to top, #e9d27a, #f6c717);

    &:before {
      background: linear-gradient(to top, #e9d27a, #f6c717);
    }
  }

  &.silver {
    background: linear-gradient(to top, #8e8e8e, #dbdbdb);

    &:before {
      background: linear-gradient(to top, #8e8e8e, #dbdbdb);
    }
  }

  &.bronze {
    background: linear-gradient(to top, #b75a32, #ef9f73);

    &:before {
      background: linear-gradient(to top, #b75a32, #ef9f73);
    }
  }

  &.my-staroftheday {
    @if $isWsm {
      background-color: #233441;
    }
    @if $isSsm {
      background-color: #fff;
    }

    &:before {
      @if $isWsm {
        background-color: #233441;
      }
      @if $isSsm {
        background-color: #fff;
      }
    }
  }
}
</style>
