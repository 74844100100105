import { mastersTextChange } from '@/globalVariables'
import type { PlayoffTextExchange } from '@/interfaces/events/MastersPlayoffInterfaces'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'

interface MastersEvetsStoreState {
  textsChanger: PlayoffTextExchange | null
}

export const useMastersEventStore = defineStore('textchanger', {
  state: (): MastersEvetsStoreState => ({
    textsChanger: null,
  }),
  actions: {
    async loadTextsChanger(): Promise<PlayoffTextExchange> {
      try {
        if (this.textsChanger) return this.textsChanger
        const data = await internalAxios.post<{}, PlayoffTextExchange>(mastersTextChange)
        if (!data) return
        this.textsChanger = data
        return data
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
