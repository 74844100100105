<template>
  <div class="ranking-records app-page-wrapper absolute">
    <ranking-menu :current="3" />
    <section class="w-full h-full safe-area">
      <main class="ranking-records-global m-auto">
        <header class="ranking-records-global-menu">
          <discipline-menu
            id-discipline="arena-main-menu"
            emit-click-event
            :selected-default="selectedDiscipline"
            @on-click-discipline="selectDiscipline"
          />
        </header>
        <ranking-sub-menu :discipline="$t('discipline.discipline_' + selectedDiscipline)" />
        <section class="ranking-records-global-table">
          <ranking-table-head ranking-type="records" />
          <app-scrollbar
            width="100%"
            :height="$isMobile() ? '30rem' : '29rem'"
            scroll="y"
            slide="y"
          >
            <ranking-row-table :rows="rankings" table="records" width="116.125rem" />
          </app-scrollbar>
          <ranking-player :player-data="myRanking" ranking-type="records" />
        </section>
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import DisciplineMenu from '@/components/DisciplineMenu.vue'
import RankingMenu from '@/components/Rankings/RankingMenu.vue'
import RankingPlayer from '@/components/Rankings/RankingPlayer.vue'
import RankingRowTable from '@/components/Rankings/RankingRowTable.vue'
import RankingSubMenu from '@/components/Rankings/RankingSubMenu.vue'
import RankingTableHead from '@/components/Rankings/RankingTableHead.vue'
import { arenaRankingRecordAllDisciplineEndpoint, pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'

import type { Nullable } from '@/interfaces/utils'
import type { RankingUser } from '@/interfaces/RankingsInterfaces'
import type { DisciplineMenuData } from '@/interfaces/DisciplineMenu'
import type { RankingsApiResponse } from '@/interfaces/responses/rankings/RankingsApiResponse'
import { mapState } from 'pinia'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'

interface ComponentData {
  pathImages: typeof pathImages
  selectedDiscipline: number
  myRanking: RankingsApiResponse['ranking']['player']
  rankings: Nullable<RankingUser[]>
}

export default defineComponent({
  name: 'RankingRecords',
  components: {
    RankingMenu,
    RankingRowTable,
    DisciplineMenu,
    RankingSubMenu,
    RankingTableHead,
    RankingPlayer,
  },
  data(): ComponentData {
    return {
      pathImages,
      selectedDiscipline: 1,
      myRanking: {} as RankingsApiResponse['ranking']['player'],
      rankings: null,
    }
  },
  computed: {
    ...mapState(useDisciplineStore, {
      firstUnlockedDisciplineId: 'getFirstUnlockedDisciplineId',
    }),
  },
  async created(): Promise<void> {
    if (this.$route.params.id) {
      this.selectedDiscipline = Number(this.$route.params.id)
    } else {
      this.selectedDiscipline = this.firstUnlockedDisciplineId
    }
    this.getRankings()
  },
  methods: {
    async getRankings(): Promise<void> {
      const response = await this.$axios.get<{}, RankingsApiResponse['ranking']>(
        arenaRankingRecordAllDisciplineEndpoint + '/' + this.selectedDiscipline,
      )

      this.myRanking = response?.player
      this.rankings =
        Object.keys(response).reduce((finalArray: RankingUser[], userId: string): RankingUser[] => {
          if (userId !== 'player') {
            finalArray.push({
              id: userId,
              name: response[userId].username,
              level: response[userId].LEVEL,
              clubId: response[userId].club_id,
              clubLogoId: response[userId].club_logo_id,
              clubLogoBgId: response[userId].club_background_logo_id,
              eventBadges: response[userId].event_badges ?? null,
              ...response[userId],
            })
          }
          return finalArray
        }, []) ?? []
    },
    selectDiscipline(disciplineData: DisciplineMenuData): void {
      if (this.selectedDiscipline !== disciplineData.id) {
        this.selectedDiscipline = disciplineData.id
        this.getRankings()
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.ranking-records-global {
  width: 116.25rem;
  height: 48.0625rem;
  margin-top: 1.1875rem;

  &-menu {
    height: 5.625rem;
    margin-bottom: 1.25rem;
    @if $isWsm {
      background: linear-gradient(to right, transparent 0, #144a73, transparent 100%);
    }
    @if $isSsm {
      background: linear-gradient(
        to right,
        transparent 0,
        rgba(86, 98, 127, 0.9),
        transparent 100%
      );
    }
  }

  &-table {
    margin-top: 1.25rem;
  }

  &-scrollbar {
    max-height: 27.25rem;
  }
}
</style>
