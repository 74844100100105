import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const clubCarsMiddleToLeftPath: PathObjectInterface = {
  mainPath: { x: 2080, y: 1031 },
  spline: [
    2080, 1031, 2073, 1082, 2051, 1117, 2031, 1158, 1989, 1212, 1950, 1251, 1907, 1277, 1858, 1302,
    1810, 1322, 1746, 1340, 1689, 1350, 1614, 1350, 1542, 1355, 1474, 1354, 1414, 1352, 1374, 1345,
    1331, 1314, 1310, 1281, 1284, 1235, 1267, 1207, 1219, 1172, 1180, 1150, 1133, 1132, 1089, 1116,
    995, 1102, 927, 1098, 879, 1084, 858, 1059, 858, 1035, 875, 1004, 924, 955, 972, 911, 1005, 880,
    1023, 851, 1032, 827, 1023, 799, 998, 780, 945, 751, 870, 718, 811, 689, 766, 655, 742, 625,
    735, 591, 742, 561, 752, 528, 772, 491, 788, 456, 817, 419,
  ],
}
