export const saveGroupIdToLocalStorage = (
  key: string,
  value: number | string,
  ttl: number,
): void => {
  const data = JSON.stringify({
    value,
    expiry: Date.now() + ttl,
  })
  localStorage.setItem(key, data)
}
