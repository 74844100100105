<template>
  <div
    class="matches-report app-page-wrapper absolute"
    :class="{
      'is-history': isHistory,
    }"
    data-cy="matches-report-wrapper"
  >
    <section class="w-full h-full safe-area">
      <div v-if="historyData" class="matches-report-wrapper justify-center">
        <header class="mx-auto" :class="$isMobile() ? 'mt-16' : 'mt-4'">
          <div class="header-left flex items-center">
            <app-discipline-icon :discipline-id="disciplineId" :size="90" />
            <div class="header-left-texts flex flex-col justify-center">
              <p class="text-40 text-texts-standard-default uppercase font-bold">
                {{ $translateDiscipline(disciplineId) }}
              </p>
              <p class="text-34 text-texts-standard-additional uppercase">
                {{ $t('rankingsGroup.rankingCompetition') }}
              </p>
            </div>
          </div>
          <div class="header-right flex flex-col justify-evenly">
            <p class="text-40 text-texts-standard-default uppercase font-bold leading-10">
              {{ $t('minigame.finalStandings') }}
            </p>
            <app-check-box
              :label="$t('arena.showReport')"
              :is-checked="getShowResultScreen"
              size="sm"
              @checked-action="
                setProfileAttributes({
                  name: 'show_result_screen',
                  value: !getShowResultScreen,
                })
              "
            />
          </div>
        </header>
        <p
          v-if="matchId && !isHistory"
          class="matches-report-place text-texts-standard-default text-50 uppercase"
          :class="setPlaceBg"
          v-html="$replacePlaceholder($t('common.placement'), '%s', `<b>${matchData.position}</b>`)"
        />
        <table-report
          v-if="matchId"
          :rows-data="matchData.final_standings"
          :discipline-id="disciplineId"
        />

        <footer class="bordered-1-sm relative">
          <arrow-animation position="left" border="box" :tutorial="isAfterMatchExplanation">
            <section class="flex items-center rewards-wrapper">
              <div class="footer-text uppercase text-texts-standard-additional text-36">
                {{ $t('common.yourRewards') }}
              </div>
              <arrow-animation position="left" border="box" :tutorial="isTutorialStep">
                <div class="footer-rewards flexing">
                  <tippy
                    v-for="(reward, key) in matchData.rewards"
                    :key="key"
                    theme="WSM"
                    placement="top"
                    max-width="50rem"
                  >
                    <reward-box
                      :reward-icon-size="56"
                      :reward-icon-name="getIconName(reward)"
                      :reward-number="getRewardValue(reward)"
                      :reward-data="getRewardData(reward)"
                    />
                    <template v-if="reward.name === MONEY && moneyRewards" #content>
                      <div
                        class="tooltip-content tooltip-report rewards text-texts-standard-default text-24"
                      >
                        <div class="tooltip-report-header">
                          {{ $t('arena.totalReward') }}
                        </div>
                        <div class="tooltip-report-total flexing text-texts-standard-important">
                          <span>{{ moneyRewards.total }}</span>
                          <app-main-icon icon-name="money" :icon-size="32" />
                        </div>
                        <div
                          v-for="(bonus, i) in moneyRewards.data"
                          :key="i"
                          class="tooltip-report-row with-plus with-line flex items-center justify-between"
                        >
                          <div
                            class="text-texts-standard-default text-left tooltip-report-row-first"
                          >
                            {{ $t(bonus.title) }}
                          </div>
                          <div class="value-text text-center tooltip-report-row-second">
                            {{ bonus.value > 0 ? bonus.value : '-' }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </tippy>
                </div>
              </arrow-animation>
              <div
                v-if="isAdRewardCaseAllowed && isRewardedAdLoaded"
                class="matches-report-rewarded-ads flexing"
              >
                <app-icon
                  :icon-name="'plus-gold'"
                  class="mx-2"
                  :class="
                    isVideoRewardEarned || isClaimedGamePassRewards ? 'opacity-100' : 'opacity-50'
                  "
                />
                <div class="relative">
                  <reward-box
                    v-tippy="{
                      theme: 'WSM',
                      content:
                        isVideoRewardEarned || isClaimedGamePassRewards
                          ? ''
                          : $t('premiumGroup.adsExtraBonus'),
                      placement: 'top',
                      maxWidth: '50rem',
                    }"
                    :highlighted-reward="true"
                    :reward-icon-size="56"
                    reward-icon-name="money"
                    :reward-number="matchData.ads.reward[0].value"
                    :class="
                      isVideoRewardEarned || isClaimedGamePassRewards ? 'opacity-100' : 'opacity-50'
                    "
                  />
                  <div
                    v-if="!isVideoRewardEarned && !isClaimedGamePassRewards"
                    class="matches-report-watch-btn"
                  >
                    <ad-reward-arrow-button
                      :is-claimable="isNoVideoAds"
                      :is-loading="isLoadingGamePassRewards || isMobilePlayingVideo"
                      :is-disabled="isLoadingGamePassRewards || isClaimedGamePassRewards"
                      @watch="watchVideo"
                      @claim="claimGamePassRewards"
                    />
                  </div>
                </div>
              </div>
              <div v-if="!isHistory" class="matches-report-button-claim">
                <arrow-animation position="right" border="button" :tutorial="isTutorialStep">
                  <app-button
                    id="matches-report-claim"
                    class="matches-report-claim-btn"
                    btn-type="secondary"
                    :btn-text="$t('button.continue')"
                    :class="{ 'button-anim': isTutorialStep }"
                    @click="$debounce(reportClick, [false])"
                  />
                </arrow-animation>
              </div>
            </section>
          </arrow-animation>
        </footer>
      </div>

      <component-loading :is-loading="historyData == null" />
      <currency-explanator
        v-if="isExplanationStep && historyData"
        :currencies="[RANKING_POINTS, EXPERIENCE, MONEY]"
      />
    </section>
  </div>
</template>

<script lang="ts">
import ArrowAnimation from '@/components/ArrowAnimation.vue'
import CurrencyExplanator from '@/components/CurrencyExplanator.vue'
import TableReport from '@/components/Table/TableReport.vue'
import AdRewardArrowButton from '@/components/GlobalComponents/AdRewardArrowButton.vue'
import {
  ARENA_DISCIPLINE_ID,
  EXPERIENCE,
  MONEY,
  RANKING_POINTS,
  pathImages,
  VIDEO_ADS_FREE_MONEY,
  VIDEO_AD_DURATION,
} from '@/globalVariables'
import { getReportValues, playSound, requestWebAd } from '@/helpers'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { useGamePassStore } from '@/store/pinia/gamePassStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type Reward from '@/interfaces/Reward'
import type { Nullable } from '@/interfaces/utils'
import type ArenaMatchReportApiResponse from '@/interfaces/responses/reports/ArenaMatchReportApiResponse'
import { useBoardGamesEventStore } from '@/store/pinia/boardGamesEventStore'

interface FormattedMoneyReportRewardData {
  title: string
  value: number
}

interface FormattedMoneyReportReward {
  total: number
  data: {
    base: FormattedMoneyReportRewardData
    stadium: FormattedMoneyReportRewardData
    research: FormattedMoneyReportRewardData
    equipment: FormattedMoneyReportRewardData
    benefit: FormattedMoneyReportRewardData
  }
}

interface ComponentData {
  MONEY: typeof MONEY
  EXPERIENCE: typeof EXPERIENCE
  RANKING_POINTS: typeof RANKING_POINTS
  pathImages: typeof pathImages
  matchId: Nullable<string>
  disciplineId: number
  subscriptionActive: boolean
  isVideoViewRewarded: boolean
  isLoadingGamePassRewards: boolean
  isClaimedGamePassRewards: boolean
}

export default defineComponent({
  name: 'ArenaReport',
  components: {
    ArrowAnimation,
    TableReport,
    CurrencyExplanator,
    AdRewardArrowButton,
  },
  props: {
    historyData: {
      type: Object as PropType<Nullable<ArenaMatchReportApiResponse>>,
      default: () => null,
    },
    redirectLink: {
      type: String,
      default: 'ArenaView',
    },
    isHistory: {
      type: Boolean,
      default: false,
    },
    isVideoRewardEarned: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['watches', 'rewardedWatch'],
  data(): ComponentData {
    return {
      MONEY,
      EXPERIENCE,
      RANKING_POINTS,
      pathImages,
      matchId: null,
      disciplineId: 0,
      subscriptionActive: false,
      isVideoViewRewarded: false,
      isLoadingGamePassRewards: false,
      isClaimedGamePassRewards: false,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      isMobilePlayingVideo: 'getMobileVideoPlaying',
      isRewardedAdLoaded: 'getRewardedAdLoaded',
    }),
    ...mapState(useUserStore, { getShowResultScreen: 'getShowResultScreen' }),
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
    }),
    ...mapState(useDisciplineStore, {
      firstUnlockedDisciplineId: 'getFirstUnlockedDisciplineId',
    }),
    ...mapState(useEventInfoStore, {
      eventActive: 'getEventActive',
    }),
    ...mapState(useBoardGamesEventStore, {
      isBoardGamesEventActive: 'isEventActive',
    }),
    ...mapState(useGamePassStore, ['isNoVideoAds']),
    isAdRewardCaseAllowed(): boolean {
      return (
        !this.eventActive &&
        !this.isBoardGamesEventActive &&
        this.$route.name === this.$getWebView('ArenaReport') &&
        !this.isHistory &&
        this.matchData.position === 1 &&
        this.matchData.ads.available_ads > 0
      )
    },
    isExplanationStep(): boolean {
      return this.actualStageData?.name === 'afterMatchExplanation'
    },
    matchData(): ArenaMatchReportApiResponse {
      const matchHistoryData = this.historyData

      if (
        this.$route.name !== this.$getWebView('ArenaHistoryReport') &&
        this.$route.name !== this.$getWebView('ClubsChampionshipHistory')
      ) {
        if (matchHistoryData?.position < 4) {
          playSound('action-dialog-report-win')
        } else {
          playSound('action-dialog-report-lost')
        }
      }

      return matchHistoryData
    },
    moneyRewards(): Nullable<FormattedMoneyReportReward> {
      const reward = this.historyData?.rewards.find(
        (reward: Reward): boolean => reward.name === MONEY,
      )

      if (!reward) return null

      const result: FormattedMoneyReportReward = {
        total: 0,
        data: {
          base: { value: 0, title: 'arena.baseReward' },
          stadium: { value: 0, title: 'arena.arenaBonus' },
          research: { value: 0, title: 'arena.researchBonus' },
          equipment: { value: 0, title: 'arena.equipmentBonus' },
          benefit: { value: 0, title: 'arena.benefitBonus' },
        },
      }

      reward.sources.forEach((source: Reward): void => {
        if (source.name === 'total') {
          result.total = source.value
        } else {
          result.data[source.name].value = source.value
        }
      })

      return result
    },
    isTutorialStep(): boolean {
      return [
        'afterMatchContinue',
        'reportAfterSimulation',
        'closeArenaAfterSimulations',
        'closeArenaNarrative',
      ].includes(this.actualStageData?.name)
    },
    isAfterMatchExplanation(): boolean {
      return this.actualStageData?.name === 'afterMatchExplanation'
    },
    setPlaceBg(): string {
      switch (this.historyData?.position) {
        case 1:
          return 'position-first'
        case 2:
          return 'position-second'
        case 3:
          return 'position-third'
        default:
          return ''
      }
    },
  },
  created(): void {
    const { matchId, disciplineId } = getReportValues(this.$route)

    this.matchId = matchId
    const firstDiscipline =
      parseInt(localStorage.getItem(ARENA_DISCIPLINE_ID)) ?? this.firstUnlockedDisciplineId
    this.disciplineId = disciplineId !== 0 ? disciplineId : firstDiscipline
  },
  methods: {
    ...mapActions(usePremiumStore, {
      webVideoPlay: 'setWebVideoPlay',
      setVideoPlay: 'setVideoPlay',
    }),
    ...mapActions(useUserStore, ['setProfileAttributes']),
    getIconName(reward: ArenaMatchReportApiResponse['rewards'][0]): string {
      return reward?.name ?? ''
    },
    getRewardData(reward: ArenaMatchReportApiResponse['rewards'][0]): Object | null {
      const returnVal = typeof reward !== 'number' && 'eventCurrency' in reward && reward
      return returnVal || null
    },
    getRewardValue(reward: ArenaMatchReportApiResponse['rewards'][0]): number {
      if (reward.name === MONEY) {
        return this.moneyRewards?.total ?? 0
      }
      return reward?.value ?? 0
    },
    watchVideo(): void {
      this.setVideoPlay(true)
      setTimeout((): void => {
        this.setVideoPlay(false)
      }, VIDEO_AD_DURATION)
      this.$emit('watches', VIDEO_ADS_FREE_MONEY)

      if (!this.$isMobile()) {
        requestWebAd({
          id: 'video-ad-web-default',
          onRequest: (): void => {
            this.webVideoPlay(true)
          },
          onSuccess: (): void => {
            this.webVideoPlay(true)
          },
          onError: (): void => {
            this.webVideoPlay(false)
          },
          onCallbackComplete: (): void => {
            this.$emit('rewardedWatch')
            this.webVideoPlay(false)
          },
        })
      }
    },
    reportClick() {
      this.$router.push({
        name:
          this.isTutorialStep || this.isExplanationStep
            ? 'ArenaViewTutorial'
            : this.$getWebView(this.redirectLink),
        params: { id: this.disciplineId },
        query: {
          redirectFrom: this.$route.query.redirectFrom ?? undefined,
        },
      })
    },
    async claimGamePassRewards(): Promise<void> {
      if (!this.isNoVideoAds) return
      if (this.isLoadingGamePassRewards || this.isClaimedGamePassRewards) return

      try {
        this.isLoadingGamePassRewards = true
        this.isClaimedGamePassRewards = false
        this.$emit('watches', VIDEO_ADS_FREE_MONEY)
        this.isClaimedGamePassRewards = true
      } catch (error: unknown) {
        console.error(error)
      } finally {
        this.isLoadingGamePassRewards = false
      }
    },
  },
})
</script>

<style lang="scss">
.matches-report {
  &.is-history {
    padding-top: 5.875rem;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: auto 0;
  }

  header {
    width: 117.5rem;
    height: 5.625rem;
    display: flex;
    justify-content: space-between;

    .header-left {
      &-texts {
        text-align: left;
        margin-left: 1rem;
      }
    }

    .header-right {
      text-align: right;

      .app-checkbox {
        margin-left: 1rem;
      }
    }
  }

  &-place {
    width: 114.688rem;
    height: 6.375rem;
    line-height: 6.375rem;
    margin: 1.688rem auto 0;
    background-image: url($path-backgrounds + 'BG_POSITION_PLACE_X.avif');
    background-size: contain;

    &.position-first {
      background-image: url($path-backgrounds + 'BG_POSITION_PLACE_1.avif');
    }

    &.position-second {
      background-image: url($path-backgrounds + 'BG_POSITION_PLACE_2.avif');
    }

    &.position-third {
      background-image: url($path-backgrounds + 'BG_POSITION_PLACE_3.avif');
    }
  }

  footer {
    .rewards-wrapper {
      height: 100%;

      @if $isWsm {
        background-color: #1b2f47;
      }

      @if $isSsm {
        background-color: #252f45;
      }
    }

    margin: 1rem auto;
    width: 117.688rem;
    height: 9.375rem;

    @if $isWsm {
      border: solid 0.063rem #5ba3dc;
    }

    @if $isSsm {
      border: solid 0.125rem #4c648f;
    }

    .footer {
      &-text {
        margin: 0 2.5rem;
      }
    }

    button {
      position: relative;
      right: 0;
    }

    .matches-report-button-claim {
      max-width: 28rem;
      position: absolute;
      right: 3.25rem;
    }

    .matches-report-claim-btn {
      position: relative;
    }
  }
}

.tooltip-report {
  width: 35rem;
  height: 35rem;
  font-size: 1.875rem;

  &.info {
    text-transform: uppercase;
    font-size: 2.125rem;

    &.small {
      height: 15rem;
    }
  }

  &.rewards {
    width: 30rem;
    height: 30.5rem;
  }

  &-header {
    width: 100%;
    height: 4rem;
    position: relative;
    text-transform: uppercase;
    font-size: 2rem;
  }

  &-total {
    height: 4rem;
    line-height: 4rem;
    font-size: 2.5rem;
    font-weight: bold;

    @if $isWsm {
      background-image: linear-gradient(to left, transparent, #1e6c9b, transparent);
    }

    @if $isSsm {
      background-image: linear-gradient(to right, transparent, #7c7bb4, transparent);
    }
  }

  &-row {
    position: relative;
    height: 4.5rem;
    margin-bottom: 0.5rem;

    @if $isSsm {
      color: theme('colors.texts.standard.additional');
      font-size: 2.125rem;
    }

    @if $isWsm {
      &:first-child {
        font-size: 2rem;
      }
    }

    &.highlight {
      @if $isWsm {
        background-image: linear-gradient(to left, transparent #1e6c9b, transparent);
      }

      @if $isSsm {
        color: theme('colors.texts.standard.default');
        background-image: linear-gradient(
          to left,
          transparent,
          rgba(124, 123, 180, 0.8),
          rgba(124, 123, 180, 0.8),
          transparent
        );
      }
    }

    &.muted {
      @if $isWsm {
        font-size: 1.75rem;
        color: #a0dcff;
      }
    }

    &-first {
      width: 20rem;
    }

    &-second {
      width: 7rem;
    }

    &:not(:last-child) div:last-child {
      width: 6rem;
    }

    &.with-line::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      bottom: 0;
      width: 33.625rem;
      height: 0.125rem;

      @if $isWsm {
        background-image: linear-gradient(to left, transparent, #37a1ed, #37a1ed, transparent);
      }

      @if $isSsm {
        background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.05),
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.05)
        );
      }
    }

    &.with-plus::before {
      content: '+';
      position: absolute;
      right: 2.75rem;
      width: 1rem;
      height: 1rem;
      font-size: 2rem;
      font-weight: bold;

      @if $isWsm {
        bottom: 0.8rem;
      }

      @if $isSsm {
        bottom: 0.5rem;
      }
    }

    &.subscription-bonus {
      background: linear-gradient(to left, rgba(15, 40, 57, 0), #1e6c9b, rgba(15, 40, 57, 0));

      &.active {
        font-weight: bold;
        background: linear-gradient(
          to left,
          rgba(210, 149, 32, 0),
          rgba(190, 153, 73, 0.5),
          rgba(255, 233, 142, 0.5),
          rgba(203, 159, 63, 0.5),
          rgba(255, 233, 142, 0)
        );
      }

      &:not(.active) div:first-child {
        filter: grayscale(1);
      }

      & .inactive {
        font-weight: bold;
        text-transform: uppercase;
        color: #c11014;
      }
    }

    .value-text {
      width: 6rem;
    }
  }
}

.button-anim {
  margin-left: 0.125rem;
  width: 28rem;

  &:active {
    margin-left: -0.063rem;
  }
}

.matches-report-watch-btn {
  position: absolute;
  top: -1.5rem;
  transform: scale(1.2);
  transform-origin: center;
}
</style>
