<template>
  <div v-if="inputIngredient && outputIngredient" class="conversion-visualization">
    <div
      class="conversion-visualization__before"
      :class="[
        { 'size-sm': size === VisualizationSizes.Sm },
        { 'size-lg': size === VisualizationSizes.Lg },
      ]"
    >
      <app-icon
        :icon-name="inputIngredient.icon"
        :tooltip-text="$te(inputIngredient.name)"
        class="ingredient"
        :class="[
          { 'ingredient-sm': size === VisualizationSizes.Sm },
          { 'ingredient-lg': size === VisualizationSizes.Lg },
        ]"
      />

      <div class="conversion-visualization-amount-wrapper flexing">
        <p
          class="font-bold gradient-text-gold uppercase"
          :class="[
            { 'text-34': size === VisualizationSizes.Sm },
            { 'text-50': size === VisualizationSizes.Lg },
          ]"
        >
          {{ inputValue || 0 }}
        </p>
      </div>
    </div>
    <div class="conversion-visualization__icon" />
    <div
      class="conversion-visualization__after"
      :class="[
        { 'size-sm': size === VisualizationSizes.Sm },
        { 'size-lg': size === VisualizationSizes.Lg },
      ]"
    >
      <app-icon
        :icon-name="outputIngredient.icon"
        :tooltip-text="$te(outputIngredient.name)"
        class="ingredient"
        :class="[
          { 'ingredient-sm': size === VisualizationSizes.Sm },
          { 'ingredient-lg': size === VisualizationSizes.Lg },
        ]"
      />

      <div class="conversion-visualization-amount-wrapper flexing">
        <p
          class="font-bold gradient-text-gold uppercase"
          :class="[
            { 'text-34': size === VisualizationSizes.Sm },
            { 'text-50': size === VisualizationSizes.Lg },
          ]"
        >
          {{ outputValue || 0 }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import type { Ingredient } from '@/interfaces/responses/events/warehouse/WarehouseSharedInterface'
import type { PropType } from 'vue'

enum VisualizationSizes {
  Sm = 'sm',
  Lg = 'lg',
}

interface ComponentData {
  VisualizationSizes: typeof VisualizationSizes
}

export default defineComponent({
  name: 'ConvertorSideExchangeVisualization',
  props: {
    inputIngredient: {
      type: Object as PropType<Ingredient>,
      required: true,
    },
    outputIngredient: {
      type: Object as PropType<Ingredient>,
      required: true,
    },
    inputValue: {
      type: Number,
      default: 0,
    },
    outputValue: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: 'sm',
      validator(value: VisualizationSizes): boolean {
        return [VisualizationSizes.Sm, VisualizationSizes.Lg].includes(value)
      },
    },
  },
  data(): ComponentData {
    return {
      VisualizationSizes,
    }
  },
})
</script>

<style lang="scss" scoped>
.conversion-visualization {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &__before .ingredient::before {
    @include background(var(--conversion-ingredient-before-bg), cover);
  }

  &__after .ingredient::before {
    @include background(var(--conversion-ingredient-after-bg), cover);
  }

  .size-lg {
    width: 20rem;
    height: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .size-sm {
    width: 14.188rem;
  }

  &__before,
  &__after {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 7.313rem;
    @include background(
      url($path-events + 'frozen-championship/warehouse/convertor/ico-num-cell.avif'),
      cover
    );
    z-index: 1;

    & .ingredient-lg {
      width: 7rem;
      height: 7rem;
      margin-left: 1.4rem;
    }

    & .ingredient-sm {
      width: 5rem;
      height: 5rem;
      margin-left: 1rem;
    }

    & .ingredient {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 6.5rem;
        height: 5rem;
        transform: translate(-50%, -50%);
      }
    }

    & p {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4.25rem;
      padding: 2.063rem 0 1.438rem;
    }
  }

  &__icon {
    width: 2.063rem;
    height: 2.188rem;
    @include background(
      url($path-events + 'frozen-championship/warehouse/convertor/arrow.avif'),
      cover
    );
    margin-left: 1.25rem;
  }

  &-amount-wrapper {
    width: 50%;
  }
}
</style>
