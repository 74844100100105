import type { App, Plugin } from 'vue'

export const replaceLinkPlaceholder = (
  text: string,
  link: string,
  className: string = '',
): string => {
  if (!text || !link) return ''

  // normalize text
  text = text.replace('{end_link}', '{/link}')

  return text.replace(
    /{link}(.+?){\/link}/g,
    (_: string, title: string): string =>
      `<a class='${className}' target='_blank' href='${link}'>${title}</a>`,
  )
}

export const replaceLinkPlaceholderPlugin: Plugin = {
  install: (app: App): void => {
    app.config.globalProperties.$replaceLinkPlaceholder = replaceLinkPlaceholder
  },
}
