<template>
  <div class="events-sm h-full">
    <div
      class="events-sm-panel w-full flexing text-34 font-bold uppercase text-texts-standard-default"
    >
      <p>{{ $t('common.type') }}</p>
    </div>
    <section
      v-for="(menuItem, index) in menu"
      :key="index"
      class="events-sm-url w-full text-28 text-texts-standard-default uppercase relative"
      :class="{
        'active-state': isRouteActive(menuItem),
      }"
    >
      <router-link :to="menuItem.route">
        <app-icon class="url-icon" :icon-name="menuItem.icon" />
        <p class="url-text m-auto">
          {{ menuItem.name }}
        </p>
      </router-link>
    </section>
  </div>
</template>

<script lang="ts">
import { OfferType } from '@/interfaces/events/Offers'
import { PassType } from '@/interfaces/events/Passes'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { LocationAsRelativeRaw, RouteQueryAndHash } from 'vue-router'

interface EventOfferSideMenuItem {
  icon: string
  name: string
  route: LocationAsRelativeRaw & RouteQueryAndHash
}

export default defineComponent({
  name: 'EventsSideMenu',
  computed: {
    ...mapState(useEventInfoStore, {
      eventCustomProps: 'getEventCustomProps',
      eventCollectionActive: 'getEventCollectionActive',
    }),
    menu(): EventOfferSideMenuItem[] {
      return [
        !this.eventCollectionActive &&
          this.eventCustomProps.offers.includes(OfferType.Offer) && {
            icon: 'energy_drink',
            name: 'EVENT OFFERS',
            route: { name: this.$getWebView('PremiumEventOffers') },
          },
        !this.eventCollectionActive &&
          this.eventCustomProps.passes.includes(PassType.Event) && {
            icon: 'wild_card',
            name: 'EVENT PASS',
            route: {
              name: this.$getWebView('PremiumEventPasses'),
              query: { pass: PassType.Event },
            },
          },
        this.eventCustomProps.passes.includes(PassType.Champion) && {
          icon: 'energy_drink',
          name: 'CHAMPION PASS',
          route: {
            name: this.$getWebView('PremiumEventPasses'),
            query: { pass: PassType.Champion },
          },
        },
        this.eventCustomProps.passes.includes(PassType.Master) && {
          icon: 'energy_drink',
          name: 'MASTER PASS',
          route: {
            name: this.$getWebView('PremiumEventPasses'),
            query: { pass: PassType.Master },
          },
        },
        // {
        //   icon: 'energy_drink',
        //   name: 'EVENT DEALS',
        //   route: { name: this.$getWebView('PremiumEventDeals') },
        // },
      ].filter(Boolean)
    },
  },
  methods: {
    isRouteActive(menuItem: EventOfferSideMenuItem): boolean {
      if (menuItem.route.name === this.$getWebView('PremiumEventPasses')) {
        return (
          menuItem.route.name === this.$route.name &&
          menuItem.route.query?.pass === this.$route.query?.pass
        )
      }
      return menuItem.route.name === this.$route.name
    },
  },
})
</script>

<style lang="scss" scoped>
.events-sm {
  width: 11.625rem;

  @if $isWsm {
    background-color: #1b2f47;
  }

  @if $isSsm {
    background-color: rgba(38, 48, 77, 0.5);
  }

  &-panel {
    height: 3.0625rem;

    @if $isWsm {
      background-color: #122132;
    }

    @if $isSsm {
      background-color: #192130;

      p {
        // font offset correction
        padding-top: 0.25rem;
      }
    }
  }

  &-url {
    margin-top: 2rem;
    height: 10.25rem;

    &:hover,
    &:active {
      content: '';
      width: 11.625rem;
      height: 10.25rem;

      @if $isWsm {
        background-image: linear-gradient(to top, #7adefe 0.25rem, #2e5f81 4%, transparent 43%);
      }

      @if $isSsm {
        background-image: linear-gradient(
          to top,
          #feb942 0.25rem,
          rgba(255, 171, 60, 0.31) 4%,
          transparent 43%
        );
      }
    }

    .notification-side-menu {
      top: 0;
      right: 2.5rem;
    }

    .url-icon {
      margin: 0 auto;
    }

    .url-text {
      margin-top: 0.5rem;
      width: 10rem;
      word-spacing: 2.5rem;
      line-height: 1.8rem;
    }
  }

  .active-state {
    width: 11.625rem;
    height: 10.25rem;

    @if $isWsm {
      background-image: linear-gradient(to top, #7adefe 0.25rem, #2e5f81 4%, transparent 43%);
    }

    @if $isSsm {
      background-image: linear-gradient(
        to top,
        #feb942 0.25rem,
        rgba(255, 171, 60, 0.31) 4%,
        transparent 43%
      );
    }
  }
}
</style>
