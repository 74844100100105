<template>
  <template v-if="showEventBadge">
    <div
      v-if="namespace === EventType.FrozenChampionship"
      v-tippy="{
        content: $replacePlaceholder($te('infoBadgeTooltip'), '{event}', eventName),
        placement: 'top',
        theme: 'WSM',
      }"
      class="event-discipline-badge absolute"
      :class="[
        type === TRAINING_HALL
          ? 'event-discipline-badge-training-hall'
          : 'event-discipline-badge-arena',
        `is-${assetsDirectory}`,
      ]"
      :data-event-theme="namespace"
    />
    <div
      v-if="
        namespace === EventType.DisciplineEvent &&
        !isEventView &&
        activeDisciplineId === eventDisciplineId
      "
      class="event-discipline-badge flexing flex-col space-y-1 cursor-pointer absolute"
      :class="[
        type === TRAINING_HALL
          ? 'event-discipline-badge-training-hall'
          : 'event-discipline-badge-arena',
        `is-${assetsDirectory}`,
      ]"
      :data-event-theme="namespace"
      @click="
        $router.push({
          name: $getWebView('EventsOverview'),
        })
      "
    >
      <p v-if="$isWsm" class="text-28 text-texts-standard-default leading-tight">
        {{ eventName }}
      </p>
      <app-timer
        :time="eventTimer"
        :no-min-width="true"
        class="timer"
        :class="{ 'mt-5': !$isWsm }"
      />
    </div>
  </template>
</template>

<script lang="ts">
import { ARENA, TRAINING_HALL } from '@/globalVariables'
import { EventType } from '@/interfaces/events/EventInfo'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  ARENA: typeof ARENA
  TRAINING_HALL: typeof TRAINING_HALL
  EventType: typeof EventType
}

export default defineComponent({
  name: 'EventDisciplineBadge',
  props: {
    type: {
      type: String,
      default: ARENA,
      validator(value: string): boolean {
        return [ARENA, TRAINING_HALL].includes(value)
      },
    },
    activeDisciplineId: {
      type: Number,
      required: true,
    },
    isEventView: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      ARENA,
      TRAINING_HALL,
      EventType,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
      eventName: 'getEventName',
      eventDisciplineId: 'getEventDisciplineId',
      assetsDirectory: 'getEventAssetsDirectory',
      eventActive: 'getEventActive',
      eventTimer: 'getEventLeftBoxTimer',
    }),
    showEventBadge(): boolean {
      return (
        this.eventActive &&
        [EventType.FrozenChampionship, EventType.DisciplineEvent].includes(this.namespace)
      )
    },
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='frozen'] {
  --events-badge-training-hall: url('#{$path-events}frozen-championship/frozen-badge-th.avif');
  --events-badge-arena: url('#{$path-events}frozen-championship/frozen-badge-arena.avif');
}

[data-event-theme='discipline'] {
  --events-discipline-badge-bg: url('#{$path-events}discipline-event/event-discipline-badge-bg.webp');
}

.is-frozen-championship.absolute {
  &.event-discipline-badge {
    width: 23rem;
    height: 10.75rem;

    &-training-hall {
      @include background(var(--events-badge-training-hall));
      bottom: 11.6rem;
      right: 0.8rem;
    }

    &-arena {
      @include background(var(--events-badge-arena));
      bottom: 12.4rem;
      right: 0;
    }
  }
}

.is-discipline-event.absolute {
  &.event-discipline-badge {
    left: 0;
    width: 20rem;
    height: 10.5rem;
    padding-top: 3.75rem;
    padding-right: 2.5rem;
    @include background(var(--events-discipline-badge-bg));

    &-training-hall {
      top: 9rem;
    }

    &-arena {
      top: 11rem;
    }

    .timer {
      width: 15rem;
    }
  }
}
</style>
