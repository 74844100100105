import { InteractiveObject } from '../abstractClasses'
import type { IndicatorConfigInterface } from '@/map-phaser-new/interfaces'

export class Indicator extends InteractiveObject {
  private indicatorImage: Phaser.GameObjects.Image
  private indicatorAnimation: Phaser.Tweens.Tween
  private isFlipped: boolean

  constructor(positionX: number, positionY: number, isFlipped = false) {
    super(positionX, positionY)
    this.isFlipped = isFlipped
  }

  public addToMap(activeScene: Phaser.Scene, indicatorConfig: IndicatorConfigInterface): void {
    this.createIndicatorImage(activeScene, indicatorConfig)
    this.createIndicatorAnimation(activeScene, indicatorConfig)
  }

  public removeFromMap(activeScene: Phaser.Scene): void {
    activeScene.tweens.killTweensOf(this.indicatorImage)
    this.removeIndicatorAnimation()
    this.removeIndicatorImage()
  }

  public setIndicatorVisibility(visible: boolean): void {
    if (!this.indicatorImage) return

    this.indicatorImage.setVisible(visible)
  }

  private createIndicatorImage(
    activeScene: Phaser.Scene,
    indicatorConfig: IndicatorConfigInterface,
  ): void {
    if (this.indicatorImage) return

    this.indicatorImage = activeScene.add
      .image(this.positionX, this.positionY, indicatorConfig.textureKey)
      .setOrigin(indicatorConfig.originX, indicatorConfig.originY)
      .setDepth(indicatorConfig.depth)

    // if the isFlipped property in the configuration is true,
    // flip the image vertically to display it upside down
    if (this.isFlipped) {
      this.indicatorImage.setFlipY(true)
    }
  }

  private createIndicatorAnimation(
    activeScene: Phaser.Scene,
    indicatorConfig: IndicatorConfigInterface,
  ): void {
    if (this.indicatorAnimation) return

    this.indicatorAnimation = activeScene.tweens.add({
      targets: this.indicatorImage,
      y: { value: indicatorConfig.animation.y.value, ease: indicatorConfig.animation.y.ease },
      yoyo: indicatorConfig.animation.yoyo,
      repeat: indicatorConfig.animation.repeat,
      duration: indicatorConfig.animation.duration,
    })
  }

  private removeIndicatorImage(): void {
    if (!this.indicatorImage) return

    this.indicatorImage.destroy()
    this.indicatorImage = null
  }

  private removeIndicatorAnimation(): void {
    if (!this.indicatorAnimation) return

    this.indicatorAnimation.destroy()
    this.indicatorAnimation = null
  }
}
