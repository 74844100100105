import { capitalize } from '@/helpers'
import { replacePlaceholder, translate } from '@/plugins'
import type { CareerQuest, TaskTrackerQuest } from '@/interfaces/Quest'

export const formatCareerQuestText = (quest: CareerQuest | TaskTrackerQuest): string => {
  if (!quest) return

  const text = translate('newCareerQuest.' + quest.parameters?.id.toString())

  return replaceQuestPlaceholders(text, quest)
}

export const replaceQuestPlaceholders = (
  text: string,
  quest: CareerQuest | TaskTrackerQuest,
): string => {
  text = replacePlaceholder(
    text,
    /{value}|%s|{2to4}/gi,
    (quest as TaskTrackerQuest).progress?.targetValue ?? quest.parameters?.target_value,
  )
  text = replacePlaceholder(text, '{grandprize}', translate('grandPrize.header'))

  if (text.includes('{speed}')) {
    text = replacePlaceholder(
      text,
      '{speed}',
      `${quest.parameters?.target_value_unit_threshold} km/h`,
    )
  }

  if (text.includes('{equipment}')) {
    text = replacePlaceholder(
      text,
      '{equipment}',
      capitalize(quest.parameters?.target_value_unit_threshold),
    )
  }

  if (text.includes('{points}')) {
    text = replacePlaceholder(text, '{points}', quest.parameters?.target_value_unit_threshold)
  }

  if (text.includes('{cash}')) {
    text = replacePlaceholder(
      text,
      '{cash}',
      (quest as TaskTrackerQuest).progress?.targetValue ?? quest.parameters.target_value,
    )
  }

  if (quest.parameters?.boss_fight) {
    text = replacePlaceholder(
      translate('career.internationalCupPlaceIn'),
      '%s',
      (quest as TaskTrackerQuest).progress?.targetValue,
    )
  }

  return text
}

interface IconTexts {
  beforeIcon: string
  afterIcon: string
}

export const getBeforeAfterIconTexts = (
  text: string,
  quest: CareerQuest | TaskTrackerQuest,
): IconTexts => {
  if (
    !quest.parameters.text_icon ||
    !quest.parameters.text_icon_separator ||
    !text.includes(quest.parameters.text_icon_separator)
  ) {
    return { beforeIcon: text, afterIcon: '' }
  }
  const separator = quest.parameters.text_icon_separator
  const index = text.indexOf(separator)
  const separatorLength = separator.length
  const splittedText = [text.slice(0, index + separatorLength), text.slice(index + separatorLength)]

  return { beforeIcon: splittedText[0], afterIcon: splittedText[1] }
}

export const getTextWithIcon = (quest: CareerQuest | TaskTrackerQuest): IconTexts => {
  if (!quest) return
  const text = translate('newCareerQuest.' + quest.parameters?.id.toString())
  const iconText = getBeforeAfterIconTexts(text, quest)
  iconText.beforeIcon = replaceQuestPlaceholders(iconText.beforeIcon, quest)

  return iconText
}
