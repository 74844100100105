<template>
  <div class="lab-disciplines app-page-wrapper flex flex-col absolute">
    <menu-component
      menu-type="lab"
      :title="$t('research.lab')"
      right-box-icon="research_points"
      :right-box-value="researchPoints.value"
      :is-tooltip="true"
      :sub-menu-active="true"
      :sub-menu="{
        1: {
          text: $t('research.Disciplines'),
          route: $getWebView('LabDisciplines'),
          id: 1,
        },
        2: {
          text: $t('research.economics'),
          route: $getWebView('LabEconomics'),
        },
        3: {
          text: $t('research.camps'),
          route: $getWebView('LabCamps'),
        },
      }"
    />
    <section class="relative w-full h-full flex flex-col">
      <div v-if="showDetail" class="lab-overlay" @click="hideDetail" />
      <div class="relative w-full h-full flex flex-col safe-area">
        <header class="lab-disciplines-header flexing relative">
          <modal-head :title-popup="$t('research.lab')">
            <discipline-menu id-discipline="lab-menu" notification-type="laboratory" />
            <template #popupContent>
              <lab-popup />
            </template>
          </modal-head>
        </header>
        <main
          v-if="showContent"
          class="lab-disciplines-main m-auto flex justify-center relative"
          :class="{
            'lab-disciplines-main--active-reasearch':
              selectedDiscipline === currentResearchDiscipline,
          }"
        >
          <arrows-component :array-of-ids="unlockDisciplinesIds" class="component-arrows--lab" />
          <div class="lab-left-side absolute">
            <lab-info
              :researched="[currentlyResearched, researchMaxCount]"
              :finished-branches="[currentlyFinished, getSkillCount]"
              :discipline="selectedDiscipline"
            />
          </div>
          <div class="lab-right-side flexing">
            <app-scrollbar width="100%" height="100%" scroll="y" slide="y">
              <lab-disciplines-tree
                :key="labTreeComponentKey"
                :lab-data="skillTrees?.disciplines[selectedDiscipline]"
                :has-ongoing-research-tree="selectedDiscipline === currentResearchDiscipline"
                @select-skill="selectSkill"
            /></app-scrollbar>
          </div>
        </main>
        <lab-detail
          v-if="showDetail && selectedSkillId"
          ref="researchDetail"
          :skill-id="selectedSkillId"
          :skill-type="SkillType.Research"
          @research-cell="researchCell"
          @finished-research="finishedResearch"
          @speedup="speedup"
        />
        <component-loading height="100%" :is-loading="!showContent" />

        <lab-footer ref="researchStatus" @speedup="speedup" @finished-research="finishedResearch" />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import ArrowsComponent from '@/components/ArrowsComponent.vue'
import DisciplineMenu from '@/components/DisciplineMenu.vue'
import LabDetail from '@/components/Lab/LabDetail.vue'
import LabFooter from '@/components/Lab/LabFooter.vue'
import LabInfo from '@/components/Lab/LabInfo.vue'
import LabPopup from '@/components/Lab/LabPopup.vue'
import ModalHead from '@/components/ModalHead.vue'
import LabDisciplinesTree from '@/components/Lab/Disciplines/LabDisciplinesTree.vue'

import {
  researchCellEndpoint,
  researchSeenEndpoint,
  speedUpResearchEndpoint,
} from '@/globalVariables'
import {
  allSkillsCount,
  currentlyFinishedSkillCount,
  currentlyResearchedSkillCount,
  newUnlockedCells,
  playSound,
} from '@/helpers'
import router from '@/router'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useLabStore } from '@/store/pinia/labStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { SkillType } from '@/interfaces/lab/Cell'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import type {
  ResearchCellApiRespopnse,
  ResearchSeenApiResponse,
} from '@/interfaces/responses/lab/LabResponses'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { CLEAR_AND_HIDE_TIMER, LABORATORY } from '@/map-phaser-new/constants/common'

interface ComponentData {
  selectedDiscipline: number
  activateStarted: boolean
  showDetail: boolean
  selectedSkillId: string
  SkillType: typeof SkillType
  labTreeComponentKey: number
}

export default defineComponent({
  name: 'LabDisciplines',
  components: {
    DisciplineMenu,
    ModalHead,
    LabPopup,
    ArrowsComponent,
    LabInfo,
    LabFooter,
    LabDetail,
    LabDisciplinesTree,
  },
  beforeRouteEnter(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): void {
    const useDiscipline = useDisciplineStore()
    const unlockedDisciplinesIds = useDiscipline.getUnlockedDisciplinesIds
    if (!to.params.id) {
      router.push({ name: to.name, params: { id: unlockedDisciplinesIds[0] } })
    }
    next()
  },
  data(): ComponentData {
    return {
      selectedDiscipline: 1,
      activateStarted: false,
      showDetail: false,
      selectedSkillId: '',
      SkillType,
      labTreeComponentKey: 0,
    }
  },
  computed: {
    ...mapState(usePhaserGameIntegrationStore, { phaserEmitter: 'getPhaserEventEmitter' }),

    ...mapState(useDisciplineStore, {
      unlockDisciplinesIds: 'getUnlockedDisciplinesIdsUnsorted',
    }),
    ...mapState(useLabStore, {
      skillTreeLoaded: 'getSkillTreesLoaded',
      skillTrees: 'getSkillTrees',
      getSkillCount: 'getSkillCount',
      researchInProgress: 'getResearchInProgress',
      currentResearchDiscipline: 'getCurrentResearchDiscipline',
    }),
    ...mapState(useResponseTaskStore, {
      researchPoints: 'getResearchPoints',
    }),
    showContent(): boolean {
      const show =
        this.skillTreeLoaded &&
        this.skillTrees?.disciplines &&
        this.skillTrees?.disciplines[this.selectedDiscipline]?.length > 0
      return show
    },
    currentlyResearched(): number {
      if (this.skillTrees?.disciplines[this.selectedDiscipline]) {
        return currentlyResearchedSkillCount(this.skillTrees.disciplines[this.selectedDiscipline])
      }
      return 0
    },
    researchMaxCount(): number {
      if (this.skillTrees?.disciplines[this.selectedDiscipline]) {
        return allSkillsCount(this.skillTrees.disciplines[this.selectedDiscipline])
      }
      return 0
    },
    currentlyFinished(): number {
      if (this.skillTrees?.disciplines[this.selectedDiscipline]) {
        return currentlyFinishedSkillCount(this.skillTrees.disciplines[this.selectedDiscipline])
      }
      return 0
    },
    unlockedUnseenCells(): string[] {
      if (this.skillTrees?.disciplines[this.selectedDiscipline]) {
        return newUnlockedCells(this.skillTrees.disciplines[this.selectedDiscipline])
      }
      return []
    },
  },

  watch: {
    $route(route: RouteLocationNormalized): void {
      if (route.name === this.$getWebView(this.$options.name)) {
        if (this.$route.params.id) {
          this.initMethod()
        }
      }
    },
  },

  async created(): Promise<void> {
    this.initMethod()
    playSound('building-research')
  },
  async mounted(): Promise<void> {
    // load research data for currentResearchDiscipline
    await this.setResearchStatus()
  },
  methods: {
    ...mapActions(useLabStore, {
      loadDisciplineTree: 'loadDisciplineTree',
      setResearchStatus: 'setResearchStatus',
    }),
    ...mapActions(usePhaserGameIntegrationStore, {
      manageProgressTimerVisibility: 'manageProgressTimerVisibility',
    }),
    async initMethod(): Promise<void> {
      this.hideDetail()
      this.selectedDiscipline = Number(this.$route.params.id)
      await this.loadTree()
      this.markSeen()
    },
    hideDetail(): void {
      this.selectedSkillId = ''
      this.showDetail = false
    },
    async markSeen(): Promise<void> {
      try {
        await this.$axios.put<{}, ResearchSeenApiResponse>(researchSeenEndpoint, {
          cell_ids: this.unlockedUnseenCells,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadTree(disciplineId: number = 0): Promise<void> {
      if (disciplineId) {
        this.selectedDiscipline = disciplineId
      }
      await this.loadDisciplineTree(this.selectedDiscipline)
    },
    async researchCell(skillId: string): Promise<void> {
      if (this.activateStarted) {
        return
      }
      if (skillId) {
        this.activateStarted = true
        Promise.all([
          await this.$axios.put<{}, ResearchCellApiRespopnse>(researchCellEndpoint, {
            cell_id: skillId,
          }),
          await this.setResearchStatus(),
          this.loadTree(),
        ])
          .then((): void => {
            if (this.researchInProgress.remaining_time) {
              const timerStart = Date.now()
              const timerEnd = timerStart + this.researchInProgress.remaining_time * 1000

              this.manageProgressTimerVisibility({
                buildingName: LABORATORY,
                heading: this.$t('research.researchCounting'),
                timerStart: timerStart,
                timerEnd: timerEnd,
              })
            }

            this.$refs.researchStatus.getStatus()
            if (this.showDetail) {
              this.$refs.researchDetail.getSkillData(skillId)
            }
            this.initMethod()
            this.activateStarted = false
          })
          .catch((error: unknown): void => {
            console.error(error)
          })

        this.forceRerender()
      }
    },
    forceRerender(): void {
      // Changing the key causes the component to be rerendered
      this.labTreeComponentKey += 1
    },
    async speedup(operationId: string): Promise<void> {
      Promise.all([
        this.$axios.put<{}, null>(speedUpResearchEndpoint, { operation_id: operationId }),
        this.setResearchStatus(),
        this.loadTree(),
      ])
        .then((): void => {
          this.phaserEmitter?.emit(CLEAR_AND_HIDE_TIMER, LABORATORY)

          this.$refs.researchStatus.getStatus()
          if (this.showDetail) {
            this.$refs.researchDetail.getSkillData()
          }
          this.initMethod()
        })
        .catch((error: unknown): void => {
          console.error(error)
        })
    },
    async finishedResearch(): Promise<void> {
      Promise.all([this.setResearchStatus(), this.loadTree()])
        .then((): void => {
          this.phaserEmitter?.emit(CLEAR_AND_HIDE_TIMER, LABORATORY)

          this.$refs.researchStatus.getStatus()
          if (this.showDetail) {
            this.$refs.researchDetail.getSkillData()
          }
          this.initMethod()
        })
        .catch((error: unknown): void => {
          console.error(error)
        })
    },
    selectSkill(id: string): void {
      this.selectedSkillId = id
      this.showDetail = true
    },
  },
})
</script>

<style lang="scss" scoped>
.lab-disciplines {
  &.app-page-wrapper {
    background: url($path-backgrounds + 'lab-wrapper-bg.jpg?v2') center bottom no-repeat;
    background-size: cover;
  }

  &-header {
    width: 97%;
    margin-top: 1.125rem;
  }
}
.lab-disciplines-main {
  width: calc(100% - 14rem);
  height: 100%;

  .lab-left-side {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }

  .lab-right-side {
    width: 85rem;
    left: 12rem;
    position: relative;
  }
}

.lab-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
}
</style>
