<template>
  <div class="report-item w-full relative flex justify-between items-center">
    <div class="flexing">
      <img :src="reportIconUrl" class="report-item-icon" />
      <div class="report-item-texts relative text-left">
        <div class="report-item-texts-top flex items-baseline">
          <p class="text-texts-standard-important uppercase text-36">
            {{ reportTitle }}
          </p>
          <p class="text-texts-standard-additional text-24">
            <app-time-formatter :timer="reportData.timestamp" />
          </p>
        </div>
        <div v-if="reportData.text && reportText" class="report-item-texts-bottom">
          <p
            v-tippy="{
              theme: 'WSM',
              content: textIsTooLong ? reportText : '',
              placement: 'right',
            }"
            class="text-texts-standard-default text-32"
            :class="textIsTooLong ? 'long-text' : ''"
          >
            {{ reportText }}
          </p>
        </div>
      </div>
    </div>

    <div class="report-item-details relative flex items-center justify-end">
      <div
        v-if="reportData.rewards.length"
        class="report-item-details-rewards flex items-center justify-end"
        :class="{ claimed: reportData.rewardsClaimed }"
      >
        <div v-for="(reward, i) in shownRewards" :key="i">
          <reward-box v-if="avatarReward(reward)" :reward-data="reward" />
          <reward-box
            v-else
            :reward-type="reward?.name ?? ''"
            :reward-icon-size="72"
            :reward-icon-name="getIconName(reward)"
            :reward-number="reward.value"
            :is-claimable="reward?.claimable || true"
            :is-disabled="reward?.name === EVENT_POINTS && eventCollectionActive"
            class="pt-3"
          />
        </div>
        <tippy theme="WSM" placement="top">
          <div
            v-if="reportData.rewards.length > maxRewardsInRow"
            class="reward-box-empty mx-2 flexing"
          >
            <p class="hidden-rewards-count font-bold text-50">
              +{{ reportData.rewards.length - maxRewardsInRow }}
            </p>
          </div>
          <template #content>
            <report-rewards-tooltip :rewards="hiddenRewards" />
          </template>
        </tippy>
      </div>

      <div
        v-if="!hideRightBlock"
        class="text-28 report-item-details-right text-texts-standard-default flexing flex-col"
      >
        <div
          v-if="showRewardsClaimed"
          class="text-32 report-item-details-right claimed text-texts-standard-positive uppercase flexing font-bold ml-8 flex-row"
        >
          <p class="pr-2">
            {{ $t('common.rewardsClaimed') }}
          </p>
          <app-icon icon-name="done-md" />
        </div>

        <div v-else-if="rewardsToClaim" class="flexing flex-col ml-8">
          <div class="flex mb-2">
            <p class="pr-2">{{ $t('common.timeRemaining') }}:</p>
            <div class="icon-time" />
            <vue-countdown
              v-slot="{ days, hours, minutes, seconds }"
              :time="reportData.timer"
              @end="$emit('timeOut')"
            >
              <span class="text-texts-standard-default not-italic pl-2 font-bold">
                {{ formatTime(days, hours, minutes, seconds) }}
              </span>
            </vue-countdown>
          </div>

          <app-button
            class="report-item-details-right-button"
            :btn-type="$isWsm ? 'secondary' : 'confirm'"
            :btn-text="$t('common.claim')"
            @click="$emit('claim', reportData)"
          />
        </div>

        <app-button
          v-else-if="showButton"
          class="report-item-details-right-button"
          btn-type="primary"
          :btn-text="$t(reportData.btnText)"
          @click="processBtnClick"
        />

        <div v-else />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ReportRewardsTooltip from '@/components/Reports/ReportRewardsTooltip.vue'
import { EVENT_POINTS, PROJECT_NAME, REWARD_AVATAR_TYPES, pathImages } from '@/globalVariables'
import { formatTime, getEventAssetsDirectory } from '@/helpers'
import type Reward from '@/interfaces/Reward'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface ComponentData {
  EVENT_POINTS: typeof EVENT_POINTS
  maxRewardsInRow: number
}

export default defineComponent({
  name: 'ReportItem',
  components: {
    ReportRewardsTooltip,
  },
  props: {
    reportData: {
      // TODO: any -> Typ zatial neznamy, musi byt najskor definovany vo view.
      type: Object as PropType<any>,
      default: () => null,
    },
  },
  emits: ['claim', 'showPopup', 'timeOut'],
  data(): ComponentData {
    return {
      EVENT_POINTS,
      maxRewardsInRow: 4,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userData: 'getUserData',
    }),
    ...mapState(useEventInfoStore, {
      eventCollectionActive: 'getEventCollectionActive',
    }),
    textIsTooLong(): boolean {
      return this.reportText.length > 90
    },
    shownRewards(): Reward[] {
      return this.reportData.rewards.slice(0, this.maxRewardsInRow)
    },
    hiddenRewards(): Reward[] {
      return this.reportData.rewards.slice(this.maxRewardsInRow - this.reportData.rewards.length)
    },
    showRewardsClaimed(): boolean {
      return !!(this.reportData.rewards.length && this.reportData.rewardsClaimed)
    },
    rewardsToClaim(): boolean {
      return !!(this.reportData.rewards.length && this.reportData.timer)
    },
    showButton(): boolean {
      if (this.reportData.title.startsWith('boardGames.') && this.reportData.rewards.length === 0)
        return false
      return !!this.reportData.btnText
    },
    hideRightBlock(): boolean {
      return (
        ['dailyLeague', 'starOfTheDay'].includes(this.reportData.image) &&
        !this.reportData.rewards.length
      )
    },
    reportIconUrl(): string {
      const eventsTitlesToCheck = ['event.', 'boardGames.']
      const isEventTitle = eventsTitlesToCheck.some((title: string): boolean =>
        this.reportData.title.startsWith(title),
      )

      if (this.reportData.image !== 'game' && isEventTitle)
        return `${pathImages}events/${getEventAssetsDirectory(
          this.reportData.image,
        )}/event-report-icon.avif`
      return `${pathImages}icons/reports/report-icon-${this.reportData.image}.avif`
    },
    reportTitle(): string {
      let title = this.$t(this.reportData.title)
      title = this.$replacePlaceholder(title, '{game}', PROJECT_NAME)

      if (typeof this.reportData.replacementValue === 'object') {
        if (title.includes('{league}') && this.reportData.replacementValue.league) {
          title = this.$replacePlaceholder(
            title,
            '{league}',
            this.$t(this.reportData.replacementValue.league),
          )
        }
        if (title.includes('{tournament}') && this.reportData.replacementValue.tournament) {
          title = this.$replacePlaceholder(
            title,
            '{tournament}',
            this.reportData.replacementValue.tournament.startsWith('event.')
              ? this.$t(this.reportData.replacementValue.tournament)
              : this.reportData.replacementValue.tournament,
          )
        }
      }

      return title
    },
    reportText(): string {
      let text = this.$t(this.reportData.text)
      text = this.$replacePlaceholder(text, '{username}', this.userData?.username)
      text = this.$replacePlaceholder(
        text,
        '{skill}',
        this.$t(this.reportData?.replacementValue.toString() || ''),
      )
      text = this.$replacePlaceholder(text, '{club}', this.reportData.replacementValue)
      text = this.$replacePlaceholder(text, '{name}', this.reportData.replacementValue)
      text = this.$replacePlaceholder(text, '{pass}', this.reportData.replacementValue)
      // BoardGames
      text = this.$replacePlaceholder(text, '{event}', this.$t(this.reportData.title))

      if (typeof this.reportData.replacementValue === 'object') {
        if (typeof this.reportData.replacementValue === 'object') {
          if (text.includes('{league}') && this.reportData.replacementValue.league) {
            text = this.$replacePlaceholder(
              text,
              '{league}',
              this.$t(this.reportData.replacementValue.league),
            )
          }
          if (text.includes('{rank}') && this.reportData.replacementValue.rank) {
            text = this.$replacePlaceholder(text, '{rank}', this.reportData.replacementValue.rank)
          }
          if (text.includes('{tournament}') && this.reportData.replacementValue.tournament) {
            text = this.$replacePlaceholder(
              text,
              '{tournament}',
              this.reportData.replacementValue.tournament.startsWith('event.')
                ? this.$t(this.reportData.replacementValue.tournament)
                : this.reportData.replacementValue.tournament,
            )
          }
          if (text.includes('%s') && this.reportData.replacementValue['%s']) {
            text = this.$replacePlaceholder(text, '%s', this.reportData.replacementValue['%s'])
          }
          if (text.includes('{venue}') && this.reportData.replacementValue['venue']) {
            text = this.$replacePlaceholder(
              text,
              '{venue}',
              this.reportData.replacementValue['venue'],
            )
          }
          if (text.includes('{discipline}') && this.reportData.replacementValue['discipline']) {
            text = this.$replacePlaceholder(
              text,
              '{discipline}',
              this.$translateDiscipline(this.reportData.replacementValue['discipline']),
            )
          }
        }
      }

      text = this.$replacePlaceholder(text, /%s/gi, this.reportData.replacementValue)
      if (text.includes('{equipment}') && this.reportData.replacementValue) {
        text = this.$replacePlaceholder(
          text,
          '{equipment}',
          this.$t('equipmentShop.' + this.reportData.replacementValue),
        )
      }

      return text
    },
  },
  created(): void {
    this.setMaximumRewards()
  },
  methods: {
    formatTime,
    getIconName(reward): string {
      if (['grandPrize', 'grandprize'].includes(reward.name)) return 'gp-' + reward.rarity
      return reward.name
    },
    processBtnClick(): void {
      if (this.reportData.btnRoute) {
        this.$router.push({
          name: this.$getWebView(this.reportData.btnRoute),
        })
      } else {
        this.$emit('showPopup', this.reportData.id)
      }
    },
    setMaximumRewards(): void {
      if (this.reportData.rewards.length > 4) this.maxRewardsInRow = 3
    },
    avatarReward(rewardData: Reward): boolean {
      return REWARD_AVATAR_TYPES.includes(rewardData?.name)
    },
  },
})
</script>

<style lang="scss" scoped>
.report-item {
  height: 10rem;
  background-color: theme('backgroundColor.table-odd');
  border: solid 0.063rem theme('borderColor.table-odd');
  margin-bottom: 0.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 99%;

  &.not-claimable {
    background-color: red;
  }

  &:nth-child(even) {
    border: solid 0.063rem theme('borderColor.table-even');
    background-color: theme('backgroundColor.table-even');
  }

  &:nth-last-child(2) {
    margin-bottom: 0;
  }

  &-icon {
    width: 7.5rem;
    max-height: 7.5rem;
  }

  &-texts {
    height: 90%;
    margin-left: 3rem;

    &-top p {
      white-space: nowrap;
      margin-right: 0.5rem;
    }

    &-bottom {
      width: 100%;

      & p.long-text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 2.5rem;
      }
    }
  }

  &-details {
    &-rewards {
      &.claimed {
        opacity: 0.6;
      }

      .reward-box-empty {
        width: 6.875rem;
        height: 8.5rem;
        border: 0.063rem solid #ffe98e;
        background-color: rgba(3, 62, 86, 0.7);
        box-shadow:
          inset 0.063rem 0 1.25rem 0 rgba(25, 73, 105, 0.8),
          inset 0.75rem 0.188rem 1.25rem 0 rgba(25, 73, 105, 0.8),
          inset 0 0 1.25rem 0 rgba(25, 73, 105, 0.8),
          inset -0.688rem -0.188rem 1.25rem 0 rgba(25, 73, 105, 0.8);
        position: relative;
        border-bottom-right-radius: 0.875rem;

        &:after {
          content: '';
          width: 0.938rem;
          height: 0.938rem;
          position: absolute;
          border-right: 0.063rem solid #fff;
          border-top: 0.063rem solid #fff;
          top: -0.063rem;
          right: -0.063rem;
        }

        &:before {
          content: '';
          width: 0.938rem;
          height: 0.938rem;
          bottom: -0.063rem;
          left: -0.063rem;
          position: absolute;
          border-left: 0.063rem solid #fff;
          border-bottom: 0.063rem solid #fff;
        }

        & .hidden-rewards-count {
          background: linear-gradient(to bottom, #f6c717 0%, #fff284 100%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    &-right {
      &.claimed {
        width: 25.625rem;
      }
    }
  }
}
</style>
