import { internalAxios } from '@/plugins/vueAxios'
import { connectedAccountsEndpoint as CONNECTED_ACCOUNTS_ENDPOINT } from '@/globalVariables'
import { ConnectedAccountStatus, ConnectedAccountType } from '@/enums/LoginForm'
import { isMobile, isIphone, isIpad, isAndroid } from '@/plugins'
import { defineStore } from 'pinia'

type ConnectedAccountsApiResponse = Record<ConnectedAccountType, ConnectedAccountStatus> | null

interface AccountStoreState {
  connectedAccounts: ConnectedAccountsApiResponse | null
}

export const useAccountStore = defineStore('accountStore', {
  state: (): AccountStoreState => ({
    connectedAccounts: null,
  }),
  getters: {
    getConnectedAccounts(): AccountStoreState['connectedAccounts'] | null {
      return this.connectedAccounts ?? null
    },
    hasVerifiedAccount(): boolean {
      const accounts = this.connectedAccounts || {}
      return Object.values(accounts).some(
        (status: ConnectedAccountStatus): boolean => status === ConnectedAccountStatus.Connected,
      )
    },
  },
  actions: {
    async loadConnectedAccounts(force: boolean = false): Promise<void> {
      if (this?.connectedAccounts !== null && !force) return

      try {
        this.connectedAccounts = await internalAxios.get<{}, ConnectedAccountsApiResponse>(
          CONNECTED_ACCOUNTS_ENDPOINT,
        )

        const platform = isMobile()
          ? isIphone() || isIpad()
            ? 'iOS'
            : isAndroid()
              ? 'Android'
              : 'OtherMobile'
          : 'Web'

        const standardAccountTypes = [
          ConnectedAccountType.Google,
          ConnectedAccountType.Facebook,
          ConnectedAccountType.Email,
        ]

        const allowedTypes = {
          iOS: [
            ConnectedAccountType.Apple,
            ...standardAccountTypes.filter(
              (type: ConnectedAccountType): boolean => type !== ConnectedAccountType.Google,
            ),
          ],
          Android: [...standardAccountTypes],
          Web: [...standardAccountTypes],
        }

        const filteredAccounts = Object.entries(this.connectedAccounts).filter(
          ([key]: [ConnectedAccountType, ConnectedAccountStatus]): boolean =>
            allowedTypes[platform].includes(key),
        )

        this.connectedAccounts = Object.fromEntries(
          filteredAccounts,
        ) as ConnectedAccountsApiResponse
      } catch (error: unknown) {
        console.error(error)
      }
    },
    clearConnectedAccounts(): void {
      this.connectedAccounts = null
    },
  },
})

export type AccountStore = ReturnType<typeof useAccountStore>
