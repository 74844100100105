<template>
  <button
    class="watch-button clickable-element"
    :class="{ 'watch-button--disabled': isDisabled }"
    @click="handleClick"
  >
    <app-svg-icon
      class="watch-button-background"
      :class="{
        'watch-button-background--claim': isClaimable,
        'watch-button-background--loading': isLoading,
      }"
      icon-name="watchButton"
    />
    <component-loading v-if="isLoading" height="2rem" width="2rem" :is-loading="true" />
    <div v-else-if="isClaimable" class="watch-button-content">
      <shrink-text
        :text="$t('common.claim')"
        :forced-font-size="22"
        container-class-list="flexing"
        text-class-list="uppercase font-medium"
        :animated-overflow="true"
      />
    </div>
    <div v-else class="watch-button-content">
      <div class="watch-button-content-left-side relative">
        <shrink-text
          :text="$t('button.watch')"
          :forced-font-size="22"
          container-class-list="flexing"
          text-class-list="uppercase font-medium"
          :animated-overflow="true"
        />
      </div>
      <div class="watch-button-content-right-side">
        <app-icon :icon-name="'play-ad'" />
      </div>
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ShrinkText from '@/components/ShrinkText.vue'

export default defineComponent({
  name: 'AdRewardArrowButton',
  components: {
    ShrinkText,
  },
  props: {
    isClaimable: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['claim', 'watch'],
  methods: {
    handleClick(): void {
      if (this.isClaimable) return this.$emit('claim')
      this.$emit('watch')
    },
  },
})
</script>

<style lang="scss" scoped>
.watch-button {
  position: relative;

  &--disabled {
    opacity: 0.6;
    // due to: button is not clickable
    pointer-events: none;
  }

  &-background {
    position: relative;
  }

  &-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.1rem;
    color: theme('colors.texts.buttons.bright');

    &-left-side {
      display: flex;
      flex: 1 1 0;
      min-width: 0;
      height: 100%;
      padding-bottom: 2%;
    }

    &-right-side {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30%;
      height: 100%;
      padding-bottom: 8%;

      .icon-play-ad {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}

:deep() {
  .watch-button-background--claim,
  .watch-button-background--loading {
    .watch-button-group {
      .right-side {
        fill: transparent;
      }
    }
  }

  .component-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
