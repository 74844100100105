<template>
  <div id="career-detail" class="career-detail app-page-wrapper absolute" data-cy="career-detail">
    <menu-component menu-type="career" :title="$t('career.career')">
      <app-button
        class="mr-8"
        btn-size="md"
        :btn-text="$t('careerQuestUnlocks.whatYouUnlockHere')"
        @click="$router.push({ name: $getWebView('CareerListOfUnlocks') })"
      />
      <app-icon icon-name="question" @click="showCareerPopup = true" />
    </menu-component>
    <section class="w-full h-full safe-area">
      <main class="flexing">
        <side-menu-box
          :disciplines-menu-data="disciplinesMenuData"
          class="career-detail-side-menu"
          @click-on-discipline="goToCareerBranch"
        />
        <div class="main-wrap flex flex-col">
          <career-quests-navigator
            v-if="!isLoading && !isDisciplineLocked"
            class="career-detail-quest-navigator"
            :class="isTutorial ? 'z-0' : 'z-1'"
            :career-id="careerDetail.careerId"
            :all-quests="careerDetail.quests"
          />
          <career-box
            v-if="getQuestById(questId) != null && !isLoading && !isDisciplineLocked"
            :quest="getQuestById(questId)"
            :discipline-id="disciplineId"
            :career-id="careerDetail.careerId"
            :unlock-item="getUnlockItem"
            @load-detail="loadAfterClaim"
          />
          <career-footer
            v-if="getQuestById(questId) != null && !isLoading && !isDisciplineLocked"
            :quest="getQuestById(questId)"
            :discipline-id="disciplineId"
            :career-id="careerId"
          />
          <career-branch-locked
            v-if="isDisciplineLocked && !isLoading"
            :discipline-id="disciplineId"
            :unlock-level="levelToUnlock"
            :temporary-lock="temporaryLock"
            :completed-quests="completedQuests"
          />

          <component-loading width="100%" height="100%" :is-loading="isLoading" />
        </div>
      </main>

      <career-popup :show-popup="showCareerPopup" @close="showCareerPopup = false" />
    </section>
  </div>
</template>

<script lang="ts">
import CareerBox from '@/components/Career/CareerBox.vue'
import CareerBranchLocked from '@/components/Career/CareerBranchLocked.vue'
import CareerFooter from '@/components/Career/CareerFooter.vue'
import CareerPopup from '@/components/Career/CareerPopup.vue'
import CareerQuestsNavigator from '@/components/Career/CareerQuestsNavigator.vue'
import SideMenuBox from '@/components/Tasks/SideMenuBox.vue'
import { MECHANIC_CAREER } from '@/globalVariables'
import { playSound } from '@/helpers'
import { useCareerDetailStore } from '@/store/pinia/career/careerDetail'
import { useListOfUnlocksStore } from '@/store/pinia/career/listOfUnlocks'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { RouteLocationNormalized } from 'vue-router'
import type { Nullable } from '@/interfaces/utils'
import type { DisciplineMenuData } from '@/interfaces/DisciplineMenu'
import type { CareerUnlockItem } from '@/interfaces/CareerUnlockItem'

interface ComponentData {
  questId: Nullable<number>
  branchId: Nullable<number>
  disciplineId: Nullable<number>
  levelToUnlock: number
  careerId: Nullable<string>
  isDisciplineLocked: boolean
  temporaryLock: boolean
  completedQuests: number
  showCareerPopup: boolean
  TUTORIAL_MAIN_BRANCH_STEPS: string[]
}

export default defineComponent({
  name: 'CareerDetail',
  components: {
    CareerBox,
    CareerQuestsNavigator,
    SideMenuBox,
    CareerPopup,
    CareerFooter,
    CareerBranchLocked,
  },
  data(): ComponentData {
    return {
      questId: null,
      branchId: null,
      disciplineId: null,
      levelToUnlock: 0,
      careerId: null,
      isDisciplineLocked: false,
      temporaryLock: false,
      completedQuests: 0,
      showCareerPopup: false,
      TUTORIAL_MAIN_BRANCH_STEPS: ['mainBranchDetailNarrative', 'clickCloseBranchDetail'],
    }
  },
  computed: {
    ...mapState(useCareerDetailStore, {
      careerDetail: 'getCareerDetail',
      isLoading: 'isQuestsLoading',
      getQuestById: 'getQuestById',
      getActiveQuest: 'getActiveQuest',
      showFinalQuest: 'showFinalQuest',
      getFinalQuest: 'getFinalQuest',
    }),
    ...mapState(useListOfUnlocksStore, {
      listOfUnlocks: 'getListOfUnlocks',
    }),
    ...mapState(useTutorialStore, {
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useDisciplineStore, {
      disciplinesMenuData: 'getDisciplinesMenuData',
    }),
    getUnlockItem(): CareerUnlockItem {
      const quest = this.getQuestById(Number(this.$route.params?.task)) ?? this.getActiveQuest
      return this.listOfUnlocks.find(
        (item: CareerUnlockItem): boolean => item.questOrder === quest.parameters?.order,
      )
    },
  },
  watch: {
    $route(route: RouteLocationNormalized): void {
      if (route.name === this.$getWebView(this.$options.name)) {
        this.questId = Number(this.$route.params.task)
        this.disciplineId = this.$route.params?.id != null ? Number(this.$route.params?.id) : 0

        if (route.params.task == null || route.params.task?.length === 0) {
          this.loadCareerDetail(this.careerId)
        }
      }
    },
  },
  async created(): Promise<void> {
    playSound('career_open')
    await this.loadDisciplinesForMenu(MECHANIC_CAREER)

    if (!this.$route.params?.careerId || !this.$route.params?.careerId?.length) {
      await this.loadCareerDetail()
    } else {
      await this.loadCareerDetail(this.$route.params?.careerId.toString())
    }

    this.disciplineId = this.$route.params?.id != null ? Number(this.$route.params?.id) : 0

    const selectedId = this.disciplinesMenuData?.find(
      (item: DisciplineMenuData): boolean => item.id === this.disciplineId,
    )

    if (selectedId?.locked) {
      this.isDisciplineLocked = true
      this.levelToUnlock = selectedId?.levelToUnlock ?? 99
      this.temporaryLock = selectedId?.temporaryLock ?? false
      this.completedQuests = selectedId?.completedQuests ?? 0
    }
    let quest = this.getQuestById(Number(this.$route.params?.task))
    if (!quest) {
      quest = this.showFinalQuest ? this.getFinalQuest : this.getActiveQuest
    }
    this.questId = quest.parameters.id
    this.careerId =
      this.$route.params?.careerId != null
        ? this.$route.params?.careerId.toString()
        : this.careerDetail.careerId.toString()

    this.loadListOfUnlocks(this.careerId)
  },
  methods: {
    ...mapActions(useCareerDetailStore, ['loadCareerDetail']),
    ...mapActions(useListOfUnlocksStore, ['loadListOfUnlocks']),
    ...mapActions(useDisciplineStore, ['loadDisciplinesForMenu']),
    async goToCareerBranch(discipline: DisciplineMenuData): Promise<void> {
      if (!discipline.lock) {
        await this.loadCareerDetail(discipline.careerId.toString())

        this.careerId = discipline.careerId.toString()
        this.questId = this.getActiveQuest.parameters.id

        this.loadListOfUnlocks(this.careerId)
      } else {
        this.levelToUnlock = discipline?.levelToUnlock ?? 0
        this.temporaryLock = discipline?.temporaryLock ?? false
        this.completedQuests = discipline?.completedQuests ?? 0
      }

      this.isDisciplineLocked = discipline.locked
      this.disciplineId = discipline.id

      this.$router.push({
        name: this.isTutorial
          ? this.$isMobile()
            ? 'CareerDetailTutorial'
            : 'CareerDetailWebTutorial'
          : this.$getWebView('CareerDetail'),
        params: {
          careerId: discipline.careerId,
          id: discipline.id,
          task: this.questId,
        },
      })
    },
    async loadAfterClaim(): Promise<void> {
      if (this.getActiveQuest.isDone) {
        this.$router.push({
          name: this.$getWebView('CareerDetail'),
          params: {
            careerId: this.$route.params?.careerId ?? null,
            id: this.$route.params?.id ?? 0,
            task:
              this.getActiveQuest.parameters.order === this.careerDetail.quests.length &&
              !this.careerDetail.careerBranch.disciplineId
                ? this.$route.params.task
                : this.getActiveQuest.parameters.id + 1,
          },
        })
      }

      await this.loadCareerDetail(this.careerId)
    },
  },
})
</script>

<style lang="scss" scoped>
.career-detail {
  main {
    width: 98%;
    margin: 0 auto;
    height: 80%;

    .main-wrap {
      display: flex;
      width: 80%;
      height: 100%;
      padding-left: 1rem;
      padding-right: 1rem;

      .career-detail {
        &-career-box {
          display: inline-flex;
          vertical-align: middle;
          flex-direction: column;
        }

        &-side-menu {
          height: 52rem;
        }
      }

      .career-detail-career-box {
        display: inline-flex;
        vertical-align: middle;
        flex-direction: column;
      }
    }
  }
}
</style>
