<template>
  <div class="w-full h-full">
    <section v-if="btnVariant === 'avatar'" class="flex h-full w-full justify-start">
      <map-buttons
        :text="$t('map.attributes')"
        :theme="MapButtonThemes.Theme3"
        class="button-attribute"
        @click="$emit('showAtt')"
      />

      <map-buttons
        :image="'benefits'"
        :is-disabled="!hasMechanic(MECHANIC_BENEFIT)"
        :theme="MapButtonThemes.Theme2"
        class="button-profile"
        :route="$getWebView('BenefitsInventory')"
        sound="benefits_open"
        lock-icon="lock-sm"
        :lock-tooltip="
          $replacePlaceholder(
            $t('common.mainCareerTaskRequired'),
            '%s',
            getMechanicUnlock(MECHANIC_BENEFIT),
          )
        "
      />
    </section>
    <section v-else class="flex h-full w-full">
      <map-buttons
        :text="String($filters.$formatNumber(getPrestige ?? 0))"
        icon="prestige"
        :theme="MapButtonThemes.Theme3"
        class="button-attribute"
        @click="$emit('prestigeInfo')"
      />
    </section>
  </div>
</template>

<script lang="ts">
import MapButtons from '@/components/Layout/MapButtons.vue'
import { MECHANIC_BENEFIT, pathImages } from '@/globalVariables'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import { MapButtonThemes } from '@/enums/MapButtonThemes'
import { isMyClub } from '@/helpers'
import { useUserStore } from '@/store/pinia/userStore'

interface ComponentData {
  pathImages: typeof pathImages
  MECHANIC_BENEFIT: typeof MECHANIC_BENEFIT
  MapButtonThemes: typeof MapButtonThemes
}

export default defineComponent({
  name: 'AvatarMenuButtons',
  components: {
    MapButtons,
  },
  props: {
    btnVariant: {
      type: String,
      default: 'avatar',
    },
  },
  emits: ['showAtt', 'prestigeInfo'],
  data(): ComponentData {
    return {
      pathImages,
      MECHANIC_BENEFIT,
      MapButtonThemes,
    }
  },
  computed: {
    ...mapState(useClubStore, {
      clubInfo: 'getClubInfo',
    }),
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
      getMechanicUnlock: 'getMechanicUnlock',
    }),
    ...mapState(useUserStore, {
      club: 'getClub',
    }),
    getPrestige(): number {
      return isMyClub(this.$router) ? this.club.prestige : this.clubInfo.prestige
    },
  },
})
</script>

<style lang="scss" scoped>
.avatar-box-content-logo {
  &-avatar,
  &-bg {
    width: 100%;
    height: 100%;
    background-size: contain;
  }

  &-avatar {
    position: absolute;
    top: 0;
    left: 0;
  }

  &-circle {
    background-image: url($path-images + 'components/profileAvatar/profile-avatar-circle1.avif');
    background-size: contain;
    position: absolute;
    top: -0.063rem;
    left: 0;
    width: 14.75rem;
    height: 14.75rem;
  }
}
</style>
