interface GenericObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

interface MappedGenericObject<T extends GenericObject> {
  index: number
  value: number | T | T[keyof T]
}

export const sortArrayByObjectValue = <T extends GenericObject>(
  arrayData: T[],
  pathToId: string,
): T[] => {
  const splitted: string[] = pathToId.split('.')

  const mapped: MappedGenericObject<T>[] = arrayData.map(
    (value: T, index: number): MappedGenericObject<T> => {
      splitted.forEach((key: string): void => {
        if (key in value) value = value[key]
      })
      return { index, value }
    },
  )

  mapped.sort((a: MappedGenericObject<T>, b: MappedGenericObject<T>): number => {
    a.value = (a.value as T[keyof T])?.length === 0 ? mapped.length + 1 : a.value
    b.value = (b.value as T[keyof T])?.length === 0 ? mapped.length + 1 : b.value
    if (a.value > b.value) return 1
    if (a.value < b.value) return -1
    return 0
  })

  return mapped.map(({ index }: MappedGenericObject<T>): T => arrayData[index])
}
