<template>
  <div
    v-if="getGameName"
    :id="'offer-' + offerData.type"
    :data-cy="'offer-' + offerData.type"
    class="offer-box relative cursor-pointer"
    :style="{
      background: `url(${pathImages}premium/offers/offer-${getGameName}_tournament_pass.avif?v1) center no-repeat`,
      backgroundSize: 'contain',
    }"
    @click="offerData.remaining_buys ? buyPack(offerData.store_id) : () => {}"
  >
    <main class="offer-box-main relative text-30 text-texts-standard-default font-bold uppercase">
      <p class="offer-box-main-title flexing text-50">{{ $t('tournaments.tournamentPass') }}</p>
      <p class="offer-box-main-text flexing px-4">{{ $t('tournaments.tournamentPassInfo') }}</p>
    </main>
    <footer class="offer-box-footer flex items-center absolute w-full justify-between">
      <app-timer v-if="offerData.remaining_time" :time="offerData.remaining_time" />
      <p
        v-if="!offerData.remaining_buys"
        class="pass-activated absolute text-texts-standard-default text-36 flexing uppercase font-bold"
      >
        <span>
          {{
            $replacePlaceholder(
              $t('event.eventPassActive'),
              '{pass}',
              $t('tournaments.tournamentPass'),
            )
          }}
        </span>
      </p>
      <div v-else class="offer-box-footer-price absolute text-texts-standard-default">
        <app-price
          v-if="offerData.price"
          :old-price="!offerValue ? '' : Math.round(oldPrice) + ' ' + offerData.price?.currency"
          :exchange-price="getForceRate ? formatForceRate(offerData.price?.price) : ''"
          decoration-color="#5199c6"
        >
          <p>
            {{ offerData.price?.price + ' ' + offerData.price?.currency }}
          </p>
        </app-price>
      </div>
      <div v-if="offerData.remaining_buys" class="offer-box-footer-value">
        <app-plate-value
          v-if="offerValue"
          :color="$isWsm ? 'purple' : 'orange'"
          :text-value="getOfferValue"
        />
      </div>
    </footer>
    <payment-terminal
      v-if="terminal"
      :item-id="offerData.store_id"
      :wire-transfer-off="true"
      @close="terminal = false"
    />
  </div>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useGamePassStore } from '@/store/pinia/gamePassStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'

import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { Offer } from '@/interfaces/premium/Offer'
import type { OfferParameter } from '@/interfaces/premium/Offer'
import type PremiumPayApiResponse from '@/interfaces/responses/premium/PremiumPayApiResponse'
import type PremiumPackApiResponse from '@/interfaces/responses/premium/PremiumPackApiResponse'

import AppPlateValue from '@/components/GlobalComponents/AppPlateValue.vue'
import AppPrice from '@/components/GlobalComponents/AppPrice.vue'
import PaymentTerminal from '@/components/Popup/Premium/PaymentTerminal.vue'

import { getIsMobileLocalStorage, sendToFlutter } from '@/helpers'
import { metaPremiumPayEndpoint, metaPremiumPackEndpoint } from '@/globalVariables'

interface ComponentData {
  pathImages: typeof pathImages
  terminal: boolean
}

export default defineComponent({
  name: 'OffersTournamentPass',
  components: {
    AppPrice,
    AppPlateValue,
    PaymentTerminal,
  },
  props: {
    offerData: {
      type: Object as PropType<Nullable<Offer>>,
      default: () => null,
    },
  },
  emits: ['reloadData'],
  data(): ComponentData {
    return {
      pathImages,
      terminal: false,
    }
  },
  computed: {
    ...mapState(useGamePassStore, ['isPassEnding']),
    ...mapState(useResponseTaskStore, {
      getForceRate: 'getForceRate',
      formatForceRate: 'formatForceRate',
    }),
    offerValue(): string | number {
      return (
        this.offerData?.parameters?.find(
          (parameter: OfferParameter): boolean => parameter.name === 'value',
        )?.value ?? ''
      )
    },
    getOfferValue(): string {
      return '<b>' + this.offerValue + '%</b>' + ' value'
    },
    getInfoText(): string {
      const replacements = {
        '{pass}': this.$t('gamePass.gamePass'),
        '{passelite}': this.$t('gamePass.gamePassElite'),
        '{passpro}': this.$t('gamePass.gamePassPro'),
      }
      let str = this.isPassEnding ? this.$t('gamePass.tagline') : this.$t('gamePass.popUpInfo')

      str = str.replace(/{\w+}/g, function (all: string): string {
        return replacements[all] || all
      })

      return str
    },
    getGameName(): string {
      if (this.$isWsm) {
        return 'wsm'
      }
      if (this.$isSsm) {
        return 'ssm'
      }
      return ''
    },
    isMobileLocalStorage(): boolean {
      return getIsMobileLocalStorage() === 1
    },
    oldPrice(): number {
      return (this.offerData.price.price * Number(this.offerValue)) / 100
    },
  },
  methods: {
    getIsMobileLocalStorage,
    ...mapActions(usePremiumStore, {}),
    ...mapActions(useResponseTaskStore, {
      showPaymentLoading: 'showPaymentLoading',
      showPaymentMessage: 'showPaymentMessage',
    }),
    async buyPack(storeId: string): Promise<void> {
      if (!this.offerData.remaining_buys) return
      if (!this.isMobileLocalStorage && this.offerData.price.price > 0) {
        this.terminal = true
        return
      }

      this.showPaymentLoading()

      const responsePay = await this.$axios.get<{}, PremiumPayApiResponse>(
        `${metaPremiumPayEndpoint}/pack/${storeId}${
          !this.isMobileLocalStorage ? '/bank_transfer' : ''
        }`,
      )
      if (responsePay?.event) {
        const message = {
          event: responsePay.event,
          productId: responsePay.productId,
        }
        sendToFlutter(JSON.stringify(message))
        return
      }

      const responsePack = await this.$axios.get<{}, PremiumPackApiResponse>(
        `${metaPremiumPackEndpoint}${storeId}`,
      )
      this.showPaymentMessage({
        pack: storeId,
        rewards: responsePack.rewards,
        failed: false,
        af_quantity: responsePack.firebaseData.af_quantity,
        af_store_id: responsePack.firebaseData.af_store_id,
        gtm_currency: responsePack.firebaseData.gtm_currency,
        gtm_revenue: responsePack.firebaseData.gtm_revenue,
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.offer-box {
  height: 44.875rem;
  width: 78.75rem;
  margin-right: 1.5rem;
  position: relative;
  &-main {
    height: 18.5rem;
    flex-direction: column;
    &-title {
      text-align: center;
      height: 5.5rem;
      text-shadow: 0.125rem 0.219rem 1.038rem #3e1400;
      background-image: linear-gradient(to top, #fcd994, #f1f4ff);
      background-clip: text;
      text-shadow: none;
      color: transparent;
    }
    &-text {
      text-align: center;
      height: 12.875rem;
      text-shadow: 0.125rem 0.219rem 0 rgba(0, 0, 0, 0.33);
      background-image: linear-gradient(to top, #ffedbd, #fffcf1);
      background-clip: text;
      text-shadow: none;
      color: transparent;
    }
  }

  &-footer {
    bottom: 0.75rem;
    padding-left: 2rem;
    padding-right: 2rem;

    &-price {
      left: 50%;
      bottom: 0.5rem;
      transform: translate(-50%);

      &-before:before {
        content: '';
        position: absolute;
        width: 5rem;
        height: 0.2rem;
        background: #b52e2d;
        top: 1.3rem;
        left: 1rem;
        transform: skewY(-14deg);
      }
    }

    .pass-activated {
      left: 50%;
      bottom: 0rem;
      transform: translate(-50%);
      width: 43.75rem;
      height: 4rem;
      background: linear-gradient(to right, transparent 5%, #e09e2e, transparent 95%);
      span {
        text-shadow: 0.125rem 0.219rem 0 rgba(0, 0, 0, 0.33);
        background-image: linear-gradient(to top, #ffe08b, #fff5da);
        background-clip: text;
        text-shadow: none;
        color: transparent;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 0.125rem;
        left: 0rem;
        background: linear-gradient(to right, transparent 5%, #e09e2e, transparent 95%);
      }

      &:before {
        top: 0rem;
      }

      &:after {
        bottom: 0rem;
      }
    }
  }
}
</style>
