<template>
  <section class="header w-full safe-area-x">
    <menu-component
      menu-type="tournaments"
      :title="title"
      :menu-items="menu"
      :sub-menu="subMenu"
      :sub-menu-active="true"
    />
  </section>
</template>

<script lang="ts">
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import type { MenuObject } from '@/interfaces/MenuObject'
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

export default defineComponent({
  name: 'TournamentsHeader',
  computed: {
    ...mapState(useResponseTaskStore, { notifications: 'getNotifications' }),
    tournamentsNotification(): number {
      return this.notifications?.tournaments?.daily_join_count ?? 0
    },
    milestonesNotification(): number {
      return this.notifications?.tournaments?.unclaimed_milestones ?? 0
    },
    title(): string {
      if (this.$route.name.toString().replace(/Web$/, '') === 'TournamentsDetail')
        return this.$t('tournaments.tournament')

      return this.$t('tournaments.tournaments')
    },
    currentMenuKey(): number {
      switch (this.$route.name.toString().replace(/Web$/, '')) {
        case 'TournamentsCalendar':
        case 'TournamentsHistory':
          return 1
        case 'TournamentsMilestones':
          return 2
        case 'TournamentsRankingsPoints':
        case 'TournamentsRankingsTitles':
          return 3
        default:
          return 0
      }
    },
    menu(): Record<number, MenuObject> {
      if (this.$route.name.toString().replace(/Web$/, '') === 'TournamentsDetail') return null

      return {
        1: {
          text: this.$t('tournaments.tournaments'),
          route: this.$getWebView('TournamentsCalendar'),
          current: this.currentMenuKey === 1 && this.$route.name.toString(),
          notification: this.tournamentsNotification,
        },
        2: {
          text: this.$t('tournaments.milestones'),
          route: this.$getWebView('TournamentsMilestones'),
          notification: this.milestonesNotification,
        },
        3: {
          text: this.$t('tournaments.rankings'),
          route: this.$getWebView('TournamentsRankingsPoints'),
          current: this.currentMenuKey === 3 && this.$route.name.toString(),
        },
      }
    },
    subMenu(): Record<number, MenuObject> {
      switch (this.currentMenuKey) {
        case 1:
          return {
            1: {
              text: this.$t('tournaments.calendar'),
              route: this.$getWebView('TournamentsCalendar'),
            },
            2: {
              text: this.$t('tournaments.myTournaments'),
              route: this.$getWebView('TournamentsHistory'),
            },
          }
        case 3:
          return {
            1: {
              text: this.$t('tournaments.points'),
              route: this.$getWebView('TournamentsRankingsPoints'),
            },
            2: {
              text: this.$t('tournaments.titles'),
              route: this.$getWebView('TournamentsRankingsTitles'),
            },
          }
        default:
          return null
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.header {
  @include background(var(--event-header-background), cover);

  @if $isWsm {
    background-color: #143157;
  }

  @if $isSsm {
    background-color: rgba(45, 56, 83, 0.6);
  }

  :deep(.menu-wrapper) {
    @apply mt-0;
  }
}
</style>
