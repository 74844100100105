<template>
  <div class="piggy-bank-info-timer relative flexing">
    <app-timer v-if="time" :time="time" :italic="false" @countdown-action="$emit('loadData')" />
    <app-icon icon-name="question" @click="showPopup = true" />

    <info-popup v-if="showPopup" :popup-title="$t('piggyBank.about')" @close="showPopup = false">
      <div class="piggy-bank-info-about text-30 text-texts-standard-default text-left">
        <app-scrollbar
          width="100%"
          height="100%"
          scroll="y"
          slide="y"
          class="flex flex-col gap-8 pr-4"
        >
          <p>{{ $t('piggyBank.aboutPiggyBank') }}</p>
          <p>{{ $t('piggyBank.aboutPiggyBank2') }}</p>
          <div class="piggy-bank-info-table my-10">
            <table-component
              :columns="headerColumns"
              :items="formattedPiggyBankData"
              force-scroll-size="100%"
              :pagination="false"
            />
          </div>
          <p>{{ $t('piggyBank.aboutPiggyBank3') }}</p>
          <p>
            {{
              $replacePlaceholder(
                $t('piggyBank.aboutPiggyBank4'),
                '{piggybank}',
                $t('piggyBank.piggyBank'),
              )
            }}
          </p>
        </app-scrollbar>
      </div>
    </info-popup>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import TableComponent from '@/components/Table/TableComponent.vue'
import { clubTableKeys } from '@/components/Table/tableKeys'

import type { PiggyBankProgressData } from '@/store/pinia/piggyBankStore'

interface ComponentData {
  showPopup: boolean
  headerColumns: any
}

export default defineComponent({
  name: 'PiggyBankInfoTimer',
  components: {
    InfoPopup,
    TableComponent,
  },
  props: {
    time: {
      type: Number,
      default: 0,
    },
    about: {
      type: Object as PropType<PiggyBankProgressData['config_table']>,
      default: null,
    },
  },
  emits: ['loadData'],
  data(): ComponentData {
    return {
      showPopup: false,
      headerColumns: [
        {
          label: this.$t('rankingsGroup.pos'),
          disableSort: true,
          key: 'pos',
          align: 'center',
          coloredPos: true,
          width: 10,
        },
        {
          label: this.$t('common.rewards'),
          disableSort: true,
          key: clubTableKeys.commodityPoints,
          align: 'right',
          width: 90,
        },
      ],
    }
  },
  computed: {
    formattedPiggyBankData() {
      // TODO BE chodi ako { 1: {…}, 2: {…}, 3: {…} }
      const data = Object.values(this.about)
      data.push({ name: '', value: 0 })

      return data.map((a, i) => {
        return {
          pos: i + 1 === 4 ? '4+' : i + 1,
          commodityPoints: { icon: a.name, value: a.value },
        }
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.piggy-bank-info-timer {
  .timer-component {
    min-width: 14.875rem;
  }
}

.piggy-bank-info-table {
  :deep() {
    .table-component-header tr th:last-child .cell-container {
      padding: 0 2rem;
    }
    .table-component-body tr td:last-child .cell-container {
      padding: 0 1rem;
    }
  }
}

.piggy-bank-info-about {
  width: 76.125rem;
  height: 34.625rem;
  padding: 2rem;
}
</style>
