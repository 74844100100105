<template>
  <info-popup
    v-if="showPopup"
    width="74.375rem"
    :popup-title="$t('premiumGroup.level_up_bags')"
    @close="$emit('close')"
  >
    <div class="level-bags-about-popup flex flex-col justify-start text-30 text-white">
      <ul class="list-disc mx-8 text-left">
        <li v-for="i in 4" :key="`level-bags-text-${i}`" class="mb-8">
          <p class="text-texts-standard-default text-36">
            {{ $t(`premiumGroup.level_up_bags_info_${i}`) }}
          </p>
        </li>
      </ul>
    </div>
  </info-popup>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LevelBagsAboutPopup',
  components: {
    InfoPopup,
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
})
</script>

<style lang="scss" scoped>
.level-bags-about-popup {
  padding: 2rem 2rem 0 2rem;
}
</style>
