import type { ExportedAtlasesResponse } from '@/map-phaser-new/interfaces'
import { eventsOsraAtlasLoadData } from './eventsOsraAtlasLoadData'

/**
 * This function is used to collect all imports of events atlases, to make loading at BootScene easier
 * @returns Promise<AtlasDataInterface[]>
 */
export const exportedEventsAtlases: ExportedAtlasesResponse = async () => [
  ...eventsOsraAtlasLoadData(),
]
