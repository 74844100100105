<template>
  <div class="upgrade-change">
    <equipment-popup-header-parameters />
    <main v-if="!maximumLevel || currentLevel.level === 1" class="flex justify-between">
      <equipment-upgrade-change-box
        :is-current="true"
        :type="type"
        :current-parameters="itemBefore.parameters"
        :levels="levels.length"
        :current-level="levelBefore"
        :discipline-id="disciplineId"
      />
      <div class="upgrade-change-middle">
        <div class="triangle yellow" />
        <div
          v-for="(change, i) in changes"
          :key="i"
          class="change-status-line text-28 flex justify-between"
          :class="'item-' + (i + 1)"
        >
          <template v-if="change.show">
            <div class="triangle" :class="change.positive ? 'green' : 'red'" />
            <p
              class="change-status-line-text flexing"
              :class="change.positive ? 'positive' : 'negative'"
            >
              {{ change.value }}
            </p>
            <div class="triangle" :class="change.positive ? 'green' : 'red'" />
          </template>
        </div>
      </div>
      <equipment-upgrade-change-box
        :is-current="false"
        :type="type"
        :current-parameters="itemAfter.parameters"
        :previous-parameters="itemBefore.parameters"
        :levels="levels.length"
        :current-level="levelAfter"
        :discipline-id="disciplineId"
        :premium="itemAfter.premium ? true : false"
      />
    </main>
    <main v-else class="flexing">
      <equipment-upgrade-change-box
        :is-current="true"
        :type="type"
        :current-parameters="itemBefore.parameters"
        :levels="levels.length"
        :current-level="levelBefore"
        :discipline-id="disciplineId"
      />
    </main>
    <footer class="upgrade-change-footer absolute w-full left-0 bottom-0 flex items-center">
      <div v-if="type === UPGRADE" class="flex items-center">
        <p class="text-texts-standard-default text-34">{{ $t('common.price') }}:</p>
        <div class="upgrade-change-footer-rewards flex h-full">
          <price-per-purchase
            v-for="item in prices"
            :key="item.type"
            :price-type="item.type"
            :purchased-price="item.value"
            class="ml-4"
          />
        </div>
      </div>
      <p
        v-else-if="type === CHANGE"
        class="text-texts-standard-default text-34 upgrade-change-footer-text absolute"
      >
        {{ $t('equipmentShop.changeEquipment') }}
      </p>
      <app-multi-button1
        v-if="type === UPGRADE"
        v-tippy="{
          theme: 'WSM',
          allowHTML: true,
          content: missingSources,
          placement: 'top',
        }"
        btn-theme="primary"
        :btn-text="$t('button.upgrade')"
        :disabled="notEnoughSources"
        class="app-multi-button--timer"
        @click.stop
        @click="upgrade"
      >
        <template #rightPart>
          <span class="icon-time mr-2" />
          <p class="text-texts-standard-default text-36">
            <vue-countdown
              v-slot="{ days, hours, minutes, seconds }"
              :time="nextLevel.delivery.time"
              :auto-start="false"
            >
              {{ formatTime(days, hours, minutes, seconds) }}
            </vue-countdown>
          </p>
        </template>
      </app-multi-button1>
      <app-button
        v-if="type === CHANGE"
        btn-id="change-equip-btn"
        btn-type="secondary"
        :btn-text="$t('equipmentShop.change')"
        btn-size="md"
        @click="showEquipPopup"
      />
    </footer>
    <confirm-popup
      v-if="showEquip"
      :not-multi="true"
      :button-data="{
        btnId: 'btn-changed',
        btnType: 'secondary',
        btnSize: 'xl',
      }"
      :popup-title="$t('equipmentShop.changeEquipment')"
      :single-button-text="$t('common.confirm')"
      @close="showEquip = false"
      @action="hideEquipPopup"
    >
      <template #popupHeader>
        <p>{{ $t('equipmentShop.changeEquipment') }}</p>
      </template>
      <div class="container-text text-36 text-texts-standard-default italic">
        <p class="text-texts-standard-default">
          {{ $t('equipmentShop.useWoreEquipmentConfirm') }}
        </p>
        <p class="popup-red-text">
          {{ $t('equipmentShop.addonBonusInactive') }}
        </p>
      </div>
    </confirm-popup>
  </div>
</template>

<script lang="ts">
import EquipmentPopupHeaderParameters from '@/components/Equipment/EquipmentPopupHeaderParameters.vue'
import EquipmentUpgradeChangeBox from '@/components/Equipment/EquipmentUpgradeChangeBox.vue'
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import PricePerPurchase from '@/components/PricePerPurchase.vue'
import { EQUIPMENT_DURABILITY, UPGRADE_PRICE_CASH, UPGRADE_PRICE_RP } from '@/globalVariables'
import { formatTime } from '@/helpers'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import type {
  FormattedEquipmentData,
  FormattedEquippedItem,
  FormattedLevel,
} from '@/interfaces/Equipment'
import type { Nullable } from '@/interfaces/utils'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

const MISSING_SOURCES_MAPPING = {
  [UPGRADE_PRICE_CASH]: 'common.notEnoughMoney',
  [UPGRADE_PRICE_RP]: 'common.notEnoughResearchPoints',
}

const PRICE_MAPPING = {
  [UPGRADE_PRICE_CASH]: {
    icon: 'money',
    data: 'money',
  },
  [UPGRADE_PRICE_RP]: {
    icon: 'research_points',
    data: 'researchPoints',
  },
}

const UPGRADE = 'upgrade'
const CHANGE = 'change'

interface Change {
  value: string
  show: boolean
  positive: boolean
}

interface Price {
  type: string
  value: number
  available: boolean
}

interface ComponentData {
  UPGRADE: typeof UPGRADE
  CHANGE: typeof CHANGE
  changes: Change[]
  showEquip: boolean
  notEnoughSources: boolean
  missingSources: string
  prices: Price[]
  maximumLevel: boolean
}

export default defineComponent({
  name: 'EquipmentUpgradeChange',
  components: {
    EquipmentUpgradeChangeBox,
    EquipmentPopupHeaderParameters,
    ConfirmPopup,
    PricePerPurchase,
  },
  props: {
    levels: {
      type: Array as PropType<FormattedEquipmentData['levels']>,
      default: () => [],
    },
    currentLevel: {
      type: Object as PropType<Nullable<FormattedLevel>>,
      default: () => null,
    },
    nextLevel: {
      type: Object as PropType<Nullable<FormattedLevel>>,
      default: () => null,
    },
    equippedItem: {
      type: Object as PropType<Nullable<FormattedEquippedItem>>,
      default: () => null,
    },
    equipped: {
      type: Boolean,
    },
    damaged: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      default: UPGRADE,
      validator(value: string): boolean {
        return [UPGRADE, CHANGE].includes(value)
      },
    },
    disciplineId: {
      type: Number,
      required: true,
    },
  },
  emits: ['equip', 'upgrade'],
  data(): ComponentData {
    return {
      UPGRADE,
      CHANGE,
      changes: [],
      showEquip: false,
      notEnoughSources: false,
      missingSources: '',
      prices: [],
      maximumLevel: false,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      money: 'getMoney',
      researchPoints: 'getResearchPoints',
    }),
    itemBefore(): FormattedEquippedItem {
      return this.type === UPGRADE ? this.currentLevel : this.equippedItem
    },
    itemAfter(): FormattedLevel {
      return this.type === UPGRADE ? this.nextLevel : this.currentLevel
    },
    levelBefore(): FormattedLevel['level'] {
      return this.type === UPGRADE ? this.currentLevel.level : this.equippedItem.level
    },
    levelAfter(): FormattedLevel['level'] {
      return this.type === UPGRADE ? this.currentLevel.level + 1 : this.currentLevel.level
    },
  },
  created(): void {
    this.initChanges()
    this.initPrices()
  },
  methods: {
    formatTime,
    upgrade(): void {
      if (this.notEnoughSources) return
      this.$emit('upgrade')
    },
    initPrices(): void {
      if (this.nextLevel) {
        type PriceKey = keyof FormattedLevel['delivery']['prices']
        type PriceValue = FormattedLevel['delivery']['prices'][PriceKey]

        Object.entries(this.nextLevel.delivery.prices).forEach(
          ([key, price]: [PriceKey, PriceValue]): void => {
            let available = true
            if (!PRICE_MAPPING[key]) return
            if (this[PRICE_MAPPING[key].data].value < price) {
              available = false
              this.notEnoughSources = true
              this.missingSources += '<p>' + this.$t(MISSING_SOURCES_MAPPING[key]) + '</p>'
            }
            this.prices.push({
              type: PRICE_MAPPING[key].icon,
              value: price,
              available: available,
            })
          },
        )
      } else {
        this.maximumLevel = true
      }
    },
    initChanges(): void {
      type ParamKey = keyof FormattedEquippedItem['parameters']
      type ParamValue = FormattedEquippedItem['parameters'][ParamKey]

      Object.entries(this.itemBefore.parameters).forEach(
        ([key, parameter]: [ParamKey, ParamValue]): void => {
          const change =
            this.itemAfter.premium && key === EQUIPMENT_DURABILITY
              ? 0
              : this.itemAfter.parameters[key].value - parameter.value
          this.changes.push({
            value: (change > 0 ? '+' : '') + change + parameter.suffix,
            show: change !== 0,
            positive: change > 0,
          })
        },
      )
    },
    showEquipPopup(): void {
      if (this.damaged) {
        this.showEquip = true
        return
      }
      this.$emit('equip')
    },
    hideEquipPopup(): void {
      this.$emit('equip')
      this.showEquip = false
    },
  },
})
</script>

<style lang="scss" scoped>
.info-popup-box-content {
  margin: 0;
}

.upgrade-change {
  width: 64.375rem;
  height: 30.938rem;

  &-middle {
    height: 18.75rem;
    width: 13rem;
    position: relative;
    top: 4.4rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    .triangle {
      &.yellow {
        position: relative;
        top: 2.5rem;
      }
    }

    .change-status-line {
      height: 2.875rem;
      width: 90%;
      position: absolute;

      &.item-1 {
        top: 6.7rem;
      }

      &.item-2 {
        top: 9.7rem;
      }

      &.item-3 {
        top: 12.7rem;
      }

      &.item-4 {
        top: 16.3rem;
      }

      &-text {
        width: 6.25rem;
        height: 1.875rem;
        background: linear-gradient(to right, transparent, #154e79, #154e79, transparent);

        &.positive {
          color: theme('colors.texts.standard.positive');
        }

        &.negative {
          color: theme('colors.texts.standard.danger');
        }
      }
    }
  }

  &-footer {
    height: 6.875rem;
    justify-content: flex-end;
    padding-right: 1.25rem;
    @if $isWsm {
      background: linear-gradient(to bottom, #154e79, #154e79),
        linear-gradient(to top, #154e79, #154e79, #1b2f47);
    }
    @if $isSsm {
      background: linear-gradient(to right, #1d1f2c, #2e3c63, #1d1f2c);
    }

    &-text {
      left: 1.75rem;
      max-width: 35rem;
    }

    &-rewards {
      margin-right: 2rem;
    }
  }
}
</style>
