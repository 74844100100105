<template>
  <header>
    <aside class="left-aside" :class="$isMobile() && 'left-aside-mobile'">
      <p class="text-40 font-bold uppercase gp-title" :class="'gp-title-' + headerData.gpType">
        {{ tierTitle }}
      </p>
      <app-icon class="m-4" icon-name="info-50" @click="showPopup = true" />

      <popup-window
        v-if="showPopup"
        popup-type="info"
        :popup-title="$t('grandPrize.dropRatesHeader')"
        @close="showPopup = false"
      >
        <grand-prize-popup :level="headerData.level" />
      </popup-window>
    </aside>

    <aside class="right-aside">
      <p
        class="gp-info-text text-texts-standard-additional"
        :class="{
          'text-28': $isWsm,
          'text-32': $isSsm,
        }"
      >
        {{ $t('common.allRewards') }}
      </p>
      <div
        class="gp-header-rewards"
        :class="{
          'space-x-4': $isWsm,
          'space-x-2': $isSsm,
        }"
      >
        <div v-for="(item, i) in getGrandPrizeRarities" :key="i" class="gp-header-rewards-part">
          <grand-prize-badges
            v-if="openHelmets < 2"
            :badge="item.rarity"
            size="md"
            :class="{ 'badge-complete': item.opened }"
          />

          <grand-prize-badges
            v-else
            :badge="item.rarity"
            size="md"
            :class="{ 'badge-complete': item.opened }"
            :reward="item"
            :has-badge-text="false"
          />

          <transition name="fadeIn">
            <app-icon v-if="item.opened" icon-name="done-md absolute gp-icon-done" />
          </transition>
        </div>
      </div>
    </aside>
  </header>
</template>

<script lang="ts">
import GrandPrizeBadges from '@/components/GrandPrize/GrandPrizeBadges.vue'
import GrandPrizePopup from '@/components/GrandPrize/GrandPrizePopup.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import { GRAND_PRIZE } from '@/globalVariables'
import { useGrandPrizeDetailStore } from '@/store/pinia/grandPrize/detailStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'

interface GrandPrizeHeaderData {
  gpType: string
  level: number
}

interface ComponentData {
  showPopup: boolean
}

export default defineComponent({
  name: 'GrandPrizeMainHeader',
  components: {
    GrandPrizeBadges,
    PopupWindow,
    GrandPrizePopup,
  },
  props: {
    headerData: {
      type: Object as PropType<Nullable<GrandPrizeHeaderData>>,
      default: () => null,
    },
    openHelmets: {
      type: Number,
      default: null,
    },
  },

  data(): ComponentData {
    return {
      showPopup: false,
    }
  },
  computed: {
    ...mapState(useGrandPrizeDetailStore, {
      getGrandPrizeRarities: 'getGrandPrizeRarities',
    }),
    tierTitle(): string {
      return this.$replacePlaceholder(this.$t(this.formatTitle), '{grandprize}', GRAND_PRIZE)
    },
    formatTitle(): string {
      // formatovanie typu (bronze, silver, gold... na Bronze, Silver, Gold...)
      return (
        'grandPrize.tier' +
        this.headerData?.gpType[0].toUpperCase() +
        this.headerData?.gpType?.slice(1)
      )
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/animations.scss';

.fadeIn-enter-active,
.fadeIn-leave-active {
  opacity: 0;
  animation: 0.5s fade-in;
  -webkit-animation: 0.5s fade-in;
  -moz-animation: 0.5s fade-in;
  -ms-animation: 0.5s fade-in;
}
</style>
