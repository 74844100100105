import type { App } from 'vue'
import { isAndroid } from './isAndroid'
import { isIphone } from './isIphone'
import { isIpad } from './isIpad'

export const screenOrientation = (): string => {
  // Correct check cause we only look at userAngent and not at isMobile (Flutter edition)
  const isMobileBrowser = isAndroid() || isIphone() || isIpad()

  // As a result, we check that the UA is a mobile device and has a portrait orientation
  return isMobileBrowser && window.matchMedia('(orientation: portrait)').matches
    ? 'portrait'
    : 'landscape'
}

export default {
  install: (app: App): void => {
    app.config.globalProperties.$screenOrientation = screenOrientation
  },
}
