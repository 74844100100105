<template>
  <header
    class="passes-header flexing w-full relative space-x-4 px-8 pt-2"
    :class="`is-${$route.query?.pass}-pass`"
    :data-event-theme="namespace"
  >
    <template v-if="eventCustomProps.passes.length > 1">
      <app-button
        v-for="(button, i) in eventCustomProps.passes"
        :key="i"
        :btn-type="$route.query?.pass === button ? 'secondary' : 'inactive'"
        :btn-text="$te(`${button}pass`)"
        btn-size="md"
        @click="$router.push({ query: { pass: button } })"
      />
    </template>
    <passes-info-element
      v-if="!eventCollectionActive && $route.query?.pass === PassType.Event"
      class="absolute top-6 right-6"
    />
  </header>
</template>

<script lang="ts">
import { PassType } from '@/interfaces/events/Passes'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import PassesInfoElement from './PassesInfoElement.vue'

interface ComponentData {
  PassType: typeof PassType
}

export default defineComponent({
  name: 'PassesHeader',
  components: { PassesInfoElement },
  data(): ComponentData {
    return {
      PassType,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
      eventCustomProps: 'getEventCustomProps',
      eventCollectionActive: 'getEventCollectionActive',
    }),
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='challenge_cup'] {
  --events-passes-event-pass-header-bg: rgba(15, 12, 9, 0.42);
  --events-passes-champion-pass-header-bg: rgba(15, 12, 9, 0.73);
}

[data-event-theme='frozen'] {
  @if $isWsm {
    --events-passes-event-pass-header-bg: rgba(15, 12, 9, 0.42);
    --events-passes-champion-pass-header-bg: rgba(15, 12, 9, 0.73);
  }

  @if $isSsm {
    --events-passes-event-pass-header-bg: transparent;
    --events-passes-champion-pass-header-bg: transparent;
  }
}

[data-event-theme='discipline'] {
  --events-passes-master-pass-header-bg: rgba(15, 12, 9, 0.73);
}

.passes-header {
  height: 7.625rem;

  &.is-event-pass {
    background-color: var(--events-passes-event-pass-header-bg);
  }

  &.is-champion-pass {
    background-color: var(--events-passes-champion-pass-header-bg);
  }

  &.is-master-pass {
    @if $isWsm {
      background-color: var(--events-passes-master-pass-header-bg);
    }
  }
}
</style>
