<template>
  <div class="flex flex-col">
    <p
      class="text-30"
      :class="{
        'text-texts-standard-additional': $isWsm,
        'text-texts-standard-default': $isSsm,
      }"
    >
      {{ $t('seasons.leaderboardsMaximum') }}
    </p>
    <parameter-box :value="value" />
  </div>
</template>

<script lang="ts">
import ParameterBox from '@/components/ParameterBox.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SeasonsLeaderboardsMaximum',
  components: { ParameterBox },
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
})
</script>
