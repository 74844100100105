<template>
  <section :style="dynamicBackground" class="person-component" />
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'PersonComponent',
  props: {
    isMan: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    dynamicBackground(): string {
      return `background: url('${this.pathImages}events/luckyWheel/lucky-wheel-${
        this.isMan ? 'man' : 'woman'
      }.avif') center no-repeat; background-size: contain;`
    },
  },
})
</script>

<style lang="scss" scoped>
section.person-component {
  width: 43.125rem;
  height: 53.125rem;
}
</style>
