<template>
  <div v-if="bossFightData">
    <section
      class="career-report-result flex flex-col justify-between relative w-full"
      :class="successTemplate"
    >
      <div class="career-report-result-header header flexing w-full" :class="successTemplate">
        <p class="uppercase font-bold text-texts-standard-default text-40">
          {{ $replacePlaceholder($t('common.placement'), '%s', resultPosition) }}
        </p>
      </div>
      <div class="career-report-result-task flexing">
        <p class="uppercase text-texts-standard-default text-40">
          {{ isSpecialBossFight ? specialBossFightText : taskText }}
        </p>
        <span class="ml-4">
          <app-icon
            v-if="isSpecialBossFight ? isBossFightSuccessful : hasStars"
            icon-name="done-md"
          />
          <app-icon
            v-if="isSpecialBossFight ? !isBossFightSuccessful : !hasStars"
            icon-name="undone-md"
          />
        </span>
      </div>
    </section>
    <section class="career-report-footer flex items-center justify-between">
      <div v-show="!hasStars" class="career-report-footer-info flexing">
        <app-icon icon-name="info-70" />
        <p class="text-texts-standard-default text-30 uppercase ml-4 h-8">
          {{ $t('career.taskFailedInfo') }}
        </p>
      </div>
      <div v-show="hasStars" class="career-report-footer-stars flexing relative">
        <div
          v-for="i in 3"
          :key="i"
          :class="
            i <= bossFightData?.stars.bestStars
              ? 'star-full icon-star-full-56'
              : 'star-empty icon-star-empty-56'
          "
        />
      </div>
      <div class="career-report-footer-button">
        <app-button
          id="career-report-continue"
          :btn-text="$t('button.continue')"
          btn-type="confirm"
          @click="continueAction"
        />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { DisciplineMenuData } from '@/interfaces/DisciplineMenu'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'ReportBossFightFooter',
  props: {
    bossFightData: {
      // TODO: Aktualne cez refaktor mi nefunguje boss fight, doplni sa neskor.
      type: Object as PropType<any>,
      required: true,
    },
    disciplineId: {
      type: Number,
      required: true,
    },
    resultPosition: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState(useDisciplineStore, {
      disciplinesMenuData: 'getDisciplinesMenuData',
    }),
    hasStars(): boolean {
      return this.bossFightData?.stars.actualStars !== 0
    },
    isSpecialBossFight(): boolean {
      return this.bossFightData?.boss_fight_type_rounds != null
    },
    isBossFightSuccessful(): boolean {
      return this.resultPosition === 1
    },
    successTemplate(): 'success' | 'fail' {
      if (this.isSpecialBossFight) {
        return this.isBossFightSuccessful ? 'success' : 'fail'
      }
      return this.hasStars ? 'success' : 'fail'
    },

    specialBossFightText(): string {
      const currentRound: number = this.bossFightData?.boss_fight_type_rounds ?? 0
      switch (currentRound) {
        case 1:
          return this.$t('career.internationalCupSemi')
        case 2:
          return this.$t('career.internationalCupFinal')
        case 3:
          return this.$t('career.internationalCupWin')
        default:
          return ''
      }
    },
    taskText(): string {
      const textObject = {
        0: this.$replacePlaceholder(this.$t('career.competitionPlaceIn'), '%s', 10),
        1: this.$replacePlaceholder(this.$t('career.competitionPlaceIn'), '%s', 10),
        2: this.$replacePlaceholder(this.$t('career.competitionPlaceIn'), '%s', 3),
        3: this.$t('career.winCompetition'),
      }
      return textObject[this.bossFightData?.stars.bestStars ?? 1]
    },
  },
  methods: {
    continueAction(): void {
      const discipline = this.disciplinesMenuData.find(
        (discipline: DisciplineMenuData): boolean => discipline.id === this.disciplineId,
      )
      this.$router.push({
        name: this.$getWebView('CareerDetail'),
        params: {
          careerId: discipline.careerId,
          id: discipline.id,
          task: this.bossFightData.questId,
        },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/stars-icons.scss';

.career-report {
  &-result {
    height: 10.125rem;

    &.success {
      background: linear-gradient(
        to left,
        rgba(36, 149, 31, 0),
        rgba(21, 81, 18, 1),
        rgba(36, 149, 31, 0)
      );

      & .header {
        background: linear-gradient(
          to left,
          rgba(36, 149, 31, 0),
          rgba(36, 149, 31, 1),
          rgba(36, 149, 31, 0)
        );
      }
    }

    &.fail {
      background: linear-gradient(
        to left,
        rgba(212, 44, 23, 0),
        rgba(132, 30, 17, 1),
        rgba(212, 44, 23, 0)
      );

      & .header {
        background: linear-gradient(
          to left,
          rgba(212, 44, 23, 0),
          rgba(212, 44, 23, 1),
          rgba(212, 44, 23, 0)
        );
      }
    }

    &-header {
      height: 3.438rem;
      padding: 0.25rem 0;
    }

    &-task {
      height: 6.688rem;
      line-height: 6.688rem;
    }
  }

  &-footer {
    width: 100%;
    height: 6rem;
    margin-top: 1rem;
    padding: 0 2rem;

    &-info {
      min-width: 5rem;
    }

    &-stars {
      width: 15.625rem;
      height: 4.125rem;
      left: 50%;
      transform: translateX(-50%);

      & > div {
        width: 4.5rem;
        height: 4.5rem;
        background-size: 4.5rem;

        &.icon-star-empty-56 {
          background-position: 0 -4.5rem;
        }
      }
    }
  }
}
</style>
