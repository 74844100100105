<template>
  <div class="accessories-sm">
    <div
      class="accessories-sm-panel w-full flexing text-34 font-bold uppercase text-texts-standard-default"
    >
      <p>{{ $t('common.type') }}</p>
    </div>
    <section
      v-for="(menuItem, i) in menu"
      :key="i"
      class="accessories-sm-url w-full text-texts-standard-default uppercase relative"
      :class="{ 'active-state': menuItem.route === $route.name }"
    >
      <router-link :to="{ name: menuItem.route }">
        <app-icon class="url-icon" :icon-name="menuItem.icon" />
        <shrink-text
          :text="$t(menuItem.name)"
          :font-size="28"
          :max-line-limit="2"
          container-class-list="url-text flexing m-auto"
          text-class-list="leading-7"
        />
        <app-notification
          v-if="showNotifications(menuItem.icon)"
          class="absolute notification-side-menu"
          :count="1"
        />
      </router-link>
    </section>
  </div>
</template>

<script lang="ts">
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import ShrinkText from '@/components/ShrinkText.vue'

interface ComponentData {
  menu: {
    icon: string
    name: string
    route: string
  }[]
}

export default defineComponent({
  name: 'SideMenu',
  components: {
    ShrinkText,
  },
  data(): ComponentData {
    return {
      menu: [
        {
          icon: 'wild_card',
          name: 'parameters.wild_card',
          route: this.$getWebView('PremiumWildCard'),
        },
        {
          icon: 'energy_drink',
          name: 'parameters.energy_drink',
          route: this.$getWebView('PremiumEnergyDrink'),
        },
      ],
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      notifications: 'getNotifications',
    }),
  },
  methods: {
    showNotifications(type: string): boolean {
      if (type === 'wild_card' && this.notifications.video_ads_free_starts_show) return true
      if (type === 'energy_drink' && this.notifications.video_ads_free_energy_show) return true
      return false
    },
  },
})
</script>

<style lang="scss" scoped>
.accessories-sm {
  width: 11.625rem;

  @if $isSsm {
    background: rgba(38, 48, 77, 0.5);
  }

  @if $isWsm {
    background: #1b2f47;
  }

  &-panel {
    height: 3.0625rem;

    @if $isSsm {
      background: #192130;

      p {
        // font offset correction
        padding-top: 0.25rem;
      }
    }

    @if $isWsm {
      background: #122132;
    }
  }

  &-url {
    margin-top: 2rem;
    height: 10.25rem;

    &:hover,
    &:active {
      content: '';
      width: 11.625rem;
      height: 10.25rem;

      @if $isSsm {
        background: linear-gradient(
          to top,
          #feb942 0.25rem,
          rgba(255, 171, 60, 0.31) 4%,
          transparent 43%
        );
      }

      @if $isWsm {
        background: linear-gradient(to top, #7adefe 0.25rem, #2e5f81 4%, transparent 43%);
      }
    }

    .notification-side-menu {
      top: 0;
      right: 2.5rem;
    }

    .url-icon {
      margin: 0 auto;
    }

    .url-text {
      margin-top: 0.5rem;
      width: 10rem;
      // font-28 double line
      height: 3.5rem;
    }
  }

  .active-state {
    width: 11.625rem;
    height: 10.25rem;

    @if $isSsm {
      background: linear-gradient(
        to top,
        #feb942 0.25rem,
        rgba(255, 171, 60, 0.31) 4%,
        transparent 43%
      );
    }

    @if $isWsm {
      background: linear-gradient(to top, #7adefe 0.25rem, #2e5f81 4%, transparent 43%);
    }
  }
}
</style>
