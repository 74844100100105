import {
  AFTER_MATCH_NARRATIVE,
  CAREER,
  CLOSE_TRAINING_HALL_NARRATIVE,
  FOCUS_CAREER_BUILDING_NARRATIVE,
  MAP_ARENA,
  MAP_TRAINING_HALL,
  TRAINING_HALL_NARRATIVE,
} from '@/globalVariables'
import { useTutorialStore } from '@/store/pinia/tutorialStore'

import { StageType } from '@/interfaces/Tutorial'
import type { TutorialStage } from '@/interfaces/Tutorial'

export const logToSpecificStep = (stepName: string): void => {
  const useTutorial = useTutorialStore()
  const actualStageName = useTutorial.getActualStage.name
  const tutorialClass = new TutorialInit()
  const from = tutorialClass
    .stages()
    .findIndex((stage: TutorialStage): boolean => stage.name === actualStageName)
  const to = tutorialClass
    .stages()
    .findIndex((stage: TutorialStage): boolean => stage.name === stepName)
  const i = to - from
  if (i <= 0) return
}

export default class TutorialInit {
  stages(): TutorialStage[] {
    return [
      {
        name: 'chooseFirstDisciplineNarrative',
        type: StageType.Narrative,
        overlay: false,
        pageName: 'ChooseDisciplineTutorial',
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'chooseFirstDiscipline',
        type: StageType.ClientInput,
        overlay: false,
        pageName: 'ChooseDisciplineTutorial',
        clientId: ['choose-discipline-btn'],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'chooseFirstDisciplineContinue',
        skipStep: true,
        type: StageType.ClientInput,
        overlay: false,
        pageName: 'ChooseDisciplineTutorial',
        clientId: ['choose-discipline-btn'],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'chooseFirstDisciplineReturnFromMinigame',
        skipStep: true,
        type: StageType.ClientInput,
        overlay: false,
        pageName: 'ChooseDisciplineTutorial',
        clientId: ['choose-discipline-btn'],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: TRAINING_HALL_NARRATIVE,
        type: StageType.Narrative,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'clickOnTrainingHall',
        type: StageType.Focus,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        clientId: [MAP_TRAINING_HALL],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        type: StageType.Narrative,
        name: 'clickOnDisciplineTHNarrative',
        overlay: true,
        clientId: [],
        texts: [],
        map: 'main',
        pageName: 'TrainingDisciplinesTutorial',
      },
      {
        name: 'clickOnDisciplineTH',
        type: StageType.ClientInput,
        overlay: true,
        pageName: 'TrainingDisciplinesTutorial',
        clientId: [
          'training-discipline-box-1',
          'training-discipline-box-2',
          'training-discipline-box-3',
          'training-discipline-box-4',
          'training-discipline-box-5',
          'training-discipline-box-6',
          'training-discipline-box-7',
          'training-discipline-box-8',
          'training-discipline-box-9',
          'training-discipline-box-10',
          'training-discipline-box-11',
          'training-discipline-box-12',
          'training-discipline-box-13',
          'training-discipline-box-14',
          'training-discipline-box-15',
          'training-discipline-box-16',
          'training-discipline-box-17',
          'training-discipline-box-18',
          'training-discipline-box-19',
          'training-discipline-box-20',
        ],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'clickOnLevelUpAttributeNarrative',
        type: StageType.Narrative,
        pageName: 'TrainingDisciplineDetailTutorial',
        overlay: true,
        clientId: [],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'clickOnLiveTrainingNarrative',
        type: StageType.Narrative,
        pageName: 'TrainingDisciplineDetailTutorial',
        overlay: true,
        clientId: [],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'clickOnLevelUpAttribute',
        type: StageType.ClientInput,
        overlay: true,
        pageName: 'TrainingDisciplineDetailTutorial',
        clientId: ['training-instant-up-btn'],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'trainingNarrative',
        type: StageType.Narrative,
        overlay: true,
        pageName: 'TrainingDisciplineDetailTutorial',
        clientId: [],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'returnFromTrainingLive',
        type: StageType.ClientInput,
        overlay: true,
        skipStep: true,
        pageName: 'TrainingDisciplineDetailTutorial',
        clientId: ['arena-start-live'],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: CLOSE_TRAINING_HALL_NARRATIVE,
        type: StageType.Narrative,
        overlay: true,
        pageName: 'TrainingDisciplineDetailTutorial',
        clientId: [],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'closeTrainingHall',
        type: StageType.ClientInput,
        overlay: true,
        pageName: 'TrainingDisciplineDetailTutorial',
        clientId: ['control-icon-home', 'control-icon-close'],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'clickOnArenaBuilding',
        type: StageType.Focus,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        clientId: [MAP_ARENA],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'liveOrSimulationDuelNarrative',
        type: StageType.Narrative,
        overlay: true,
        pageName: 'ArenaViewTutorial',
        clientId: [],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'returnFromLiveDuel',
        type: StageType.ClientInput,
        overlay: true,
        skipStep: true,
        pageName: 'ArenaViewTutorial',
        clientId: ['arena-start-live', 'arena-start-simulation'],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'firstDuelLiveDone',
        type: StageType.BackFromMinigame,
        overlay: false,
        pageName: '',
        clientId: [],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
        onPreload: {
          checkStateValidity: (): void => {
            logToSpecificStep(AFTER_MATCH_NARRATIVE)
          },
        },
      },
      {
        name: 'afterMatchExplanation',
        type: StageType.ClientInput,
        overlay: true,
        pageName: 'ArenaReportTutorial',
        clientId: ['matches-report-claim'],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: AFTER_MATCH_NARRATIVE,
        type: StageType.Narrative,
        overlay: true,
        pageName: 'ArenaReportTutorial',
        clientId: [],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'afterMatchContinue',
        type: StageType.ClientInput,
        overlay: true,
        pageName: 'ArenaReportTutorial',
        clientId: ['matches-report-claim'],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'closeArenaNarrative',
        type: StageType.Narrative,
        overlay: true,
        pageName: 'ArenaViewTutorial',
        clientId: [],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'closeArenaWindow',
        type: StageType.ClientInput,
        overlay: true,
        clientId: ['control-icon-home', 'control-icon-close'],
        pageName: 'ArenaViewTutorial',
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },

      {
        name: 'openArenaAfterEquipmentShop',
        type: StageType.Focus,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        clientId: [MAP_ARENA],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'simulationDuelNarrative',
        type: StageType.Narrative,
        overlay: true,
        pageName: 'ArenaViewTutorial',
        clientId: [],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'simulationDuelClick',
        type: StageType.ClientInput,
        overlay: true,
        pageName: 'ArenaViewTutorial',
        clientId: ['arena-start-simulation'],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'reportAfterSimulation',
        type: StageType.ClientInput,
        overlay: true,
        pageName: 'ArenaReportTutorial',
        clientId: ['matches-report-claim'],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'closeArenaAfterSimulations',
        type: StageType.ClientInput,
        overlay: true,
        pageName: 'ArenaViewTutorial',
        clientId: ['control-icon-home', 'control-icon-close'],
        texts: [],
        map: 'main',
      },
      {
        name: 'setProfileNarrative',
        type: StageType.Narrative,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'setUserNameNarrative',
        type: StageType.Narrative,
        overlay: false,
        pageName: 'CreateProfileTutorial',
        redirect: 'CreateProfileTutorial',
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'setUserName',
        type: StageType.ClientInput,
        overlay: false,
        pageName: 'CreateProfileTutorial',
        redirect: 'LayoutViewTutorial',
        clientId: ['profile-footer-button'],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'clickLevelUpBtn',
        type: StageType.ClientInput,
        overlay: false,
        logToStepAfterError: FOCUS_CAREER_BUILDING_NARRATIVE,
        pageName: 'CreateProfileTutorial',
        clientId: ['level-up-btn'],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: FOCUS_CAREER_BUILDING_NARRATIVE,
        type: StageType.Narrative,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'focusCareerBuilding',
        type: StageType.Focus,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        clientId: [CAREER],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'openCareerWindowNarrativeCareer',
        type: StageType.Narrative,
        overlay: false,
        pageName: 'CareerDetailTutorial',
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'disciplineBranchNarrativeCareer',
        type: StageType.Narrative,
        overlay: false,
        pageName: 'CareerDetailTutorial',
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'focusDisciplineBranchCareer',
        type: StageType.ClientInput,
        overlay: true,
        pageName: 'CareerDetailTutorial',
        clientId: ['side-menu-box-1'],
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'clickDisciplineBranchDetailNarrativeCareer',
        type: StageType.Narrative,
        overlay: false,
        pageName: 'CareerDetailTutorial',
        texts: [],
        map: 'main',
        isTaskTrackerHidden: true,
      },
      {
        name: 'clickTrackTaskCareer',
        type: StageType.ClientInput,
        overlay: true,
        pageName: 'CareerDetailTutorial',
        clientId: ['tracker-btn'],
        texts: [],
        map: 'main',
      },
      {
        name: 'openDisciplineBranchDetailNarrativeCareer',
        type: StageType.Narrative,
        overlay: false,
        pageName: 'CareerDetailTutorial',
        texts: [],
        map: 'main',
      },
      {
        name: 'focusCareerHomeButtonCareer',
        type: StageType.ClientInput,
        overlay: true,
        pageName: 'CareerDetailTutorial',
        clientId: ['control-icon-home', 'control-icon-close'],
        texts: [],
        map: 'main',
      },
      {
        name: 'careerMapNarrativeCareer',
        type: StageType.Narrative,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        texts: [],
        map: 'main',
        isAnnieFlipped: true,
      },
      {
        name: 'valleyTutorialEndCareer',
        type: StageType.ClientInput,
        clientId: ['finalButtonContinue'],
        overlay: false,
        pageName: 'LayoutViewTutorial',
        texts: [],
        map: 'main',
      },
    ]
  }
}
