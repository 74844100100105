import type { App, Plugin } from 'vue'

const ANDROID_DEVICE_REGEXP = /Android/i

let cache: boolean

export const isAndroid = (): boolean => {
  if (cache !== undefined) return cache
  return (cache = ANDROID_DEVICE_REGEXP.test(navigator.userAgent))
}

export const isAndroidPlugin: Plugin = {
  install: (app: App): void => {
    app.config.globalProperties.$isAndroid = isAndroid
  },
}
