<template>
  <div
    v-for="(item, index) in avatarData"
    :key="index"
    class="profile-choose-box"
    :class="{ selected: actualChoice === item.number }"
  >
    <aside class="overflow-hidden">
      <p
        class="avatar-name text-32 truncate px-2"
        :class="
          actualChoice === item.number
            ? 'text-texts-standard-important'
            : 'text-texts-standard-default'
        "
      >
        {{ item.name }}
      </p>
      <avatar-box
        v-if="imageChoice === AvatarType.Avatar"
        class="profile-choose-box-avatar"
        :avatar-id="item.number"
        :avatar-gender="sex"
        :is-background="false"
        @click="choiceAvatar(item.number)"
      />
      <avatar-box
        v-if="imageChoice === AvatarType.Background"
        class="profile-choose-box-avatar"
        :background-id="item.number"
        :is-avatar="false"
        @click="setBgAvatar(item.number)"
      />
    </aside>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import AvatarBox from '@/components/Profile/AvatarBox.vue'
import { useUserStore } from '@/store/pinia/userStore'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { UpdatedAvatar, UpdatedBackgrounds } from '@/interfaces/User'

enum AvatarType {
  Avatar = 'avatar',
  Background = 'background',
}

interface ComponentData {
  AvatarType: typeof AvatarType
  actualChoice: Nullable<number>
}

export default defineComponent({
  name: 'ProfileChooseAvatar',
  components: {
    AvatarBox,
  },
  props: {
    avatarData: {
      type: Array as PropType<UpdatedAvatar[] | UpdatedBackgrounds[]>,
      default: () => [],
    },
    imageChoice: {
      type: String,
      default: '',
    },
  },
  emits: ['setAvatar', 'setBg'],
  data(): ComponentData {
    return {
      AvatarType,
      actualChoice: null,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      avatar: 'getAvatar',
      avatarBg: 'getAvatarBg',
      sex: 'getSex',
    }),
  },
  created(): void {
    if (this.imageChoice === AvatarType.Avatar) {
      const idAvatarFromStorage = sessionStorage.getItem('idAvatar')
      if (idAvatarFromStorage) {
        this.actualChoice = parseInt(idAvatarFromStorage)
      } else {
        this.actualChoice = this.avatar
      }
    }
    if (this.imageChoice === AvatarType.Background) {
      const idBgFromStorage = sessionStorage.getItem('idBg')
      if (idBgFromStorage) {
        this.actualChoice = parseInt(idBgFromStorage)
      } else {
        this.actualChoice = this.avatarBg
      }
    }
  },
  methods: {
    selectChoice(id: number): void {
      this.actualChoice = id
    },
    choiceAvatar(id: number): void {
      this.selectChoice(id)
      this.$emit('setAvatar', id)
    },
    setBgAvatar(id: number): void {
      this.selectChoice(id)
      this.$emit('setBg', id)
    },
  },
})
</script>

<style lang="scss" scoped>
.profile-choose-box {
  width: 13.875rem;
  height: 17rem;
  border-width: 0.063rem;
  border-style: solid;
  position: relative;
  border-bottom-right-radius: 0.875rem;
  display: inline-flex;
  margin: 0.625rem;
  @if $isWsm {
    border-color: #22638c;
    background-color: rgba(3, 62, 86, 0.7);
    box-shadow:
      inset 0.063rem 0 1.25rem 0 rgba(25, 73, 105, 0.8),
      inset 0.75rem 0.188rem 1.25rem 0 rgba(25, 73, 105, 0.8),
      inset 0 0 1.25rem 0 rgba(25, 73, 105, 0.8),
      inset -0.688rem -0.188rem 1.25rem 0 rgba(25, 73, 105, 0.8);
  }
  @if $isSsm {
    border-color: #586b9d;
    background-color: rgba(52, 65, 93, 0.85);
  }

  &.selected {
    @if $isWsm {
      border-color: #fadd1e;
    }
    @if $isSsm {
      border-color: #feb942;
    }
  }

  &:nth-child(-n + 4) {
    margin-top: 0;
  }

  .avatar-name {
    margin: 0.25rem 0;
    width: 100%;
    text-align: center;
  }

  &-avatar {
    width: 12.5rem;
    height: 12.5rem;
    margin: 0 0.5rem;
  }
}
</style>
