<template>
  <div class="pass-active-info flexing">
    <div
      v-if="type === TRAINING_POINTS"
      class="left-side text-32 text-texts-standard-default whitespace-nowrap flexing"
    >
      {{ textBefore }}
      <app-main-icon v-if="type === TRAINING_POINTS" :icon-size="32" :icon-name="TRAINING_POINTS" />
      {{ textAfter }}
    </div>
    <div
      v-if="type === MECHANIC_EVENT"
      class="left-side text-32 text-texts-standard-default whitespace-nowrap flexing"
    >
      {{ trainingText }}
    </div>
    <tippy theme="WSM" :placement="tooltipPosition" max-width="35rem" class="info-side">
      <app-icon icon-name="info-70" />
      <template #content>
        <div class="tooltip-content text-texts-standard-default text-30">
          <p class="tooltip-content-bottom w-full" v-html="tooltipText"></p>
          <p class="tooltip-content-bottom w-full" v-html="untilText"></p>
        </div>
      </template>
    </tippy>
  </div>
</template>

<script lang="ts">
import { ENERGY_DRINK, MECHANIC_EVENT, TRAINING_POINTS, WILD_CARD } from '@/globalVariables'
import { getFormattedDateFromYMD } from '@/helpers'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type Reward from '@/interfaces/Reward'

interface ComponentData {
  TRAINING_POINTS: typeof TRAINING_POINTS
  ENERGY_DRINK: typeof ENERGY_DRINK
  WILD_CARD: typeof WILD_CARD
  MECHANIC_EVENT: typeof MECHANIC_EVENT
  textBefore: string
  textAfter: string
  textsByType: {
    [key: string]: string
  }
}

enum TooltipPosition {
  Top = 'top',
  Bottom = 'bottom',
  Right = 'right',
  Left = 'left',
}

enum ItemType {
  TrainingPoints = TRAINING_POINTS,
  EnergyDrink = ENERGY_DRINK,
  WildCard = WILD_CARD,
  MechanicEvent = MECHANIC_EVENT,
}

export default defineComponent({
  name: 'PassActivatedInfo',
  props: {
    tooltipPosition: {
      type: String,
      default: 'top',
      validator(value: string): boolean {
        return Object.values(TooltipPosition).includes(value as TooltipPosition)
      },
    },
    type: {
      type: String,
      required: true,
      validator(value: string): boolean {
        return Object.values(ItemType).includes(value as ItemType)
      },
    },
    dateTime: {
      type: String,
      default: '',
    },
    passName: {
      type: String,
      default: '',
    },
    rewards: {
      type: Array as PropType<Reward[]>,
      default: () => [],
    },
  },
  data(): ComponentData {
    return {
      TRAINING_POINTS,
      ENERGY_DRINK,
      WILD_CARD,
      MECHANIC_EVENT,
      textBefore: '',
      textAfter: '',
      textsByType: {
        [TRAINING_POINTS]: 'premiumGroup.percentage_tp_from_training',
        [ENERGY_DRINK]: 'premiumGroup.wsm_starter_pass_content_energy_drink_bonus',
        [WILD_CARD]: 'premiumGroup.wsm_starter_pass_content_wild_card_bonus',
        [MECHANIC_EVENT]: 'event.eventPassTrainingLess',
      },
    }
  },
  computed: {
    rewardsByType(): string {
      const typeWithPostfix = `${this.type}_bonus`
      let prefixMark = '+'
      let postfixMark = ''
      if (this.type === TRAINING_POINTS || this.type === MECHANIC_EVENT) {
        prefixMark = ''
        postfixMark = '%'
      }

      const value =
        this.type === MECHANIC_EVENT
          ? this.rewards?.find((reward: Reward) => reward.name === 'event_pass_training_discount')
              ?.value
          : this.rewards.find((reward: Reward) => reward.name === typeWithPostfix)?.value

      return `${prefixMark}${value}${postfixMark}`
    },
    trainingText(): string {
      if (this.rewardsByType == null) return ''

      const text =
        this.type === MECHANIC_EVENT
          ? this.$t('event.trainingDiscount')
          : this.$t('premiumGroup.percentage_from_training')

      return this.$replacePlaceholder(text, '{percent}', this.rewardsByType)
    },
    tooltipText(): string {
      if (this.rewardsByType == null) return ''

      const text = this.$t(this.textsByType[this.type] ?? '')

      if (text.includes('{percent}') || text.includes('{number}')) {
        return this.$replacePlaceholder(
          text,
          /{percent}|{number}/gi,
          `<span class="text-texts-standard-important">${this.rewardsByType}</span>`,
        )
      }
      return this.$t(this.textsByType[this.type]) ?? ''
    },
    untilText(): string {
      const text = this.$t(
        this.type === MECHANIC_EVENT
          ? 'event.eventPassDurationTooltip'
          : 'premiumGroup.bonus_valid_until',
      )
      let passReplace = this.$replacePlaceholder(text, '{pass}', this.$t(this.passName))
      const dateTimeData = new Date(this.dateTime)
      passReplace = this.$replacePlaceholder(
        passReplace,
        '{datetime}',
        getFormattedDateFromYMD(dateTimeData),
      )
      return passReplace
    },
  },
  mounted(): void {
    this.setIconPlaceholder()
  },
  methods: {
    setIconPlaceholder(): void {
      if (this.trainingText?.includes('{icon}')) {
        const textValue = this.trainingText
        const separator = '{icon}'
        const index = textValue.indexOf(separator)
        const separatorLength = separator.length
        const splittedText = [textValue.slice(0, index), textValue.slice(index + separatorLength)]

        this.textBefore = splittedText[0]
        this.textAfter = splittedText[1]
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.pass-active-info {
  .left-side {
    width: 25rem;
    height: 4.5rem;
    background-image: linear-gradient(to right, transparent 5%, #8c00b2 100%);
    border: 0.188rem solid;
    border-left: 0;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, transparent 5%, #8c00b2 100%);
    padding-left: 5rem;
  }

  .info-side {
    margin-left: -0.5rem;
  }
}
</style>
