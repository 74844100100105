<template>
  <div class="bounty-board-event-box flexing">
    <div v-if="loaded" class="bounty-board-event-box__content">
      <div v-if="activeTask">
        <bounty-board-task :task="activeTask" />
      </div>
      <div v-else class="flexing flex-col">
        <span class="text-36 font-medium text-texts-standard-default">{{
          $te('noActiveEventTasks')
        }}</span>
        <app-button
          btn-id="bounty-board-event-box-btn"
          btn-type="primary"
          :btn-text="$te('noticeBoard')"
          class="mt-6"
          :disabled="false"
          @click="redirectView"
        />
      </div>
    </div>
    <component-loading v-else :is-loading="true" height="100%" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BountyBoardTask from '@/components/Events/BoardComponents/BountyBoard/BountyBoardTask.vue'
import { mapActions, mapState } from 'pinia'
import { useTaskBoardStore } from '@/store/pinia/events/taskBoardStore'
import type { BoardTask } from '@/interfaces/responses/events/task-board/TaskBoardStateApiResponse'

interface ComponentData {
  loaded: boolean
}

export default defineComponent({
  name: 'BountyBoardEventBox',
  components: {
    BountyBoardTask,
  },
  data(): ComponentData {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapState(useTaskBoardStore, {
      tasks: 'getTasks',
    }),
    activeTask(): BoardTask {
      const tasks = Object.values(this.tasks)
      return tasks.find((task: BoardTask): boolean => !!task.active)
    },
  },
  async created(): Promise<void> {
    this.loaded = false
    await this.loadState()
    this.loaded = true
  },
  methods: {
    ...mapActions(useTaskBoardStore, {
      loadState: 'loadState',
    }),
    redirectView(): void {
      this.$router.push({ name: this.$getWebView('EventsBoard') })
    },
  },
})
</script>

<style lang="scss" scoped>
.bounty-board-event-box {
  width: 89.063rem;
  height: 44.5rem;
  background: url($path-events + 'autumn-fair/BoardMain-bg.avif') no-repeat center;
  background-size: cover;
  position: relative;
}
</style>
