import { isSsm, isWsm } from '@/plugins'

export const getDisciplineBossSlug = (value: number | string): string => {
  switch (value) {
    case 'Anne':
    case 'Annie':
      return 'Annie-Collins'
    case 11:
    case 'Ján Volko':
      return 'Jan-Volko'
    case 12:
    case 'Alexandra Longová':
      return 'Alexandra-Longova'
    case 13:
    case 'Zuzana Rehák-Štefečeková':
      return 'Zuzana-Stefecekova'
    case 15:
    case 'Matej Beňuš ':
      return 'Matej-Benus'
    default:
      if (isWsm) return 'Annie-Collins'
      if (isSsm) return 'Sam-Walker'
      return ''
  }
}
