import type { SpriteDataInterface } from '@/map-phaser-new/interfaces'

export const mainObjectsSpriteLoadData: () => SpriteDataInterface[] = () => [
  {
    name: 'observatory',
    path: 'sprite_observatory.avif',
    settings: {
      frameWidth: 50,
      frameHeight: 50,
    },
  },
]
