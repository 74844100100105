import { changeMainTitle } from './globals/configs/titleConfig'
changeMainTitle()

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'

const pinia = createPinia()
const app = createApp(App)
app.use(pinia)

import router from './router'
import './index.scss'
import '@/assets/styles/global.scss'
import { visibilityListener, globalErrorListener } from './globals/handlers/globalListeners'
import { configureGsap } from './globals/configs/gsapConfig'
import * as Sentry from '@sentry/vue'
import { version } from '../package.json'

// TODO
import {
  replacePlaceholderPlugin,
  replaceLinkPlaceholderPlugin,
  replaceUntranslatablePlaceholderPlugin,
  replaceHtmlPlaceholdersPlugin,
  debouncePlugin,
  filtersPlugin,
  gamePlugin,
  // getWebViewPlugin,
  // isMobilePlugin,
  isAndroidPlugin,
  isIphonePlugin,
  isIpadPlugin,
  isFirefoxPlugin,
  translatorPlugin,
  routeToExternalUrlPlugin,
  beforeTimeTextPlugin,
} from '@/plugins'
import flutterPlugin from './plugins/flutter'
import { translateEventPlugin } from '@/plugins/translateEvent'

// EXTERNAL COMPONENTS
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import VueCountdown from '@chenfengyuan/vue-countdown'
import gsap from 'gsap'
import translateDiscipline from './plugins/translateDiscipline'

// INTERNAL GLOBAL COMPONENTS
import AppButton from '@/components/GlobalComponents/AppButton.vue'
import AppControlIcon from '@/components/GlobalComponents/AppControlIcon.vue'
import AppIcon from '@/components/GlobalComponents/AppIcon.vue'
import AppSvgIcon from '@/components/GlobalComponents/AppSvgIcon.vue'
import AppInput from '@/components/GlobalComponents/AppInput.vue'
import AppSelect from '@/components/GlobalComponents/AppSelect.vue'
import AppDisciplineIcon from '@/components/GlobalComponents/AppDisciplineIcon.vue'
import AppMainIcon from '@/components/GlobalComponents/AppMainIcon.vue'
import AppMultiButton from '@/components/GlobalComponents/AppMultiButton.vue'
import AppMultiButton1 from '@/components/GlobalComponents/AppMultiButton1.vue'
import AppProgressBar from '@/components/GlobalComponents/AppProgressBar.vue'
import AppScrollbar from '@/components/GlobalComponents/AppScrollbar.vue'
import AppTimeFormatter from '@/components/GlobalComponents/AppTimeFormatter.vue'
import AppNotification from '@/components/GlobalComponents/AppNotification.vue'
import AppCheckBox from '@/components/GlobalComponents/AppCheckBox.vue'
import AppTimer from '@/components/GlobalComponents/AppTimer.vue'
import AppSlider from '@/components/GlobalComponents/AppSlider.vue'
import MenuComponent from '@/components/GlobalComponents/MenuComponent.vue'
import RewardBox from '@/components/GlobalComponents/RewardBox.vue'
import ComponentLoading from '@/components/GlobalComponents/ComponentLoading.vue'

// INTERNAL PLUGINS
import axiosPlugin from '@/plugins/vueAxios'
import { gameNamePlugin } from './plugins/gameNamePlugin'
import isMobile from '@/plugins/isMobile'
import getWebView from '@/plugins/getWebView'
import { isApplePlugin } from '@/plugins'
import { ApiService } from './services/ApiService'
import screenOrientation from './plugins/screenOrientation'

// Configuracie
configureGsap(gsap)

app.component('AppControlIcon', AppControlIcon)
app.component('VueCountdown', VueCountdown)
app.component('AppButton', AppButton)
app.component('AppInput', AppInput)
app.component('AppSelect', AppSelect)
app.component('AppIcon', AppIcon)
app.component('AppSvgIcon', AppSvgIcon)
app.component('AppTimer', AppTimer)
app.component('MenuComponent', MenuComponent)
app.component('AppMainIcon', AppMainIcon)
app.component('AppDisciplineIcon', AppDisciplineIcon)
app.component('AppMultiButton', AppMultiButton)
app.component('AppMultiButton1', AppMultiButton1)
app.component('AppCheckBox', AppCheckBox)
app.component('AppProgressBar', AppProgressBar)
app.component('AppScrollbar', AppScrollbar)
app.component('AppSlider', AppSlider)
app.component('AppTimeFormatter', AppTimeFormatter)
app.component('AppNotification', AppNotification)
app.component('RewardBox', RewardBox)
app.component('ComponentLoading', ComponentLoading)

// Directives
app.directive('focus', {
  mounted: (el: HTMLElement): void => el.focus(),
})

// Listeners
globalErrorListener(app)
visibilityListener()

window.activeEvent = ''
window.gameHasListener = false
window.gameName = import.meta.env.VITE_GAME
window.version = version
window.isTutorial = (): boolean => false

const sentryEnabled = true
if (import.meta.env.VITE_ENV === 'prod' && sentryEnabled) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: version,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      // Sentry.replayIntegration(),
      Sentry.replayCanvasIntegration(),
    ],
    tracePropagationTargets: ['wintersportsmania.com', 'summersportsmania.com', /^\//],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    // Set sample rates for replays.
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

ApiService.setIsFirstLoad(true)

app
  .use(router)
  .use(axiosPlugin)
  .use(translatorPlugin)
  .use(translateEventPlugin)
  //   .use(isMobilePlugin)
  .use(isMobile)
  .use(isAndroidPlugin)
  .use(isIphonePlugin)
  .use(isIpadPlugin)
  .use(isApplePlugin)
  .use(isFirefoxPlugin)
  .use(screenOrientation)
  //   .use(getWebViewPlugin)
  .use(getWebView)
  .use(filtersPlugin)
  .use(gamePlugin)
  .use(replacePlaceholderPlugin)
  .use(replaceLinkPlaceholderPlugin)
  .use(replaceUntranslatablePlaceholderPlugin)
  .use(replaceHtmlPlaceholdersPlugin)
  .use(routeToExternalUrlPlugin)
  .use(beforeTimeTextPlugin)
  .use(gameNamePlugin)
  .use(translateDiscipline)
  .use(VueTippy, {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
  })
  .use(debouncePlugin)
  .use(flutterPlugin)
  .mount('#app')
