<template>
  <div class="milestones-extension-wrapper">
    <div class="milestones-extension flex flex-col items-center">
      <p class="text-texts-standard-important text-36">{{ $t('gamePass.doneAlready') }}</p>
      <div class="milestones-extension-decoration"></div>
      <p
        class="extra-info-text text-36 text-texts-standard-default"
        v-html="
          replaceHighlightedText($t('gamePass.extraMilestonesInfo'), extraData.extra_milestones)
        "
      />
      <footer class="milestones-extension-footer flex flex-col items-center">
        <p class="text-texts-standard-important text-36">{{ $t('gamePass.canGet') }}</p>
        <p
          class="text-texts-standard-default text-36 font-bold"
          v-html="
            `${replaceHighlightedNumber(
              $t('gamePass.numberMilestones'),
              extraData.extra_milestones,
            )} / ${replaceHighlightedNumber(
              $t('gamePass.numberRewards'),
              extraData.total_milestones,
            )}`
          "
        />
        <app-multi-button1
          btn-id="milestones-extension-btn"
          btn-type="credit"
          :btn-text="$t('gamePass.unlock')"
          :btn-count="extraData.price"
          :popup-data="
            $filters.$confirmPopupData({
              title: $t('gamePass.gamePass'),
              btnId: 'btn-gp-extend-milestones',
              btnText: $t('gamePass.unlock'),
              btnValue: extraData.price,
            })
          "
          class="milestones-extension-button mt-8"
          @action="extendMilestones()"
          @click="showConfirm = true"
        >
          <template #popupSlot>
            <div class="text-36 text-texts-standard-default">
              <p>
                {{ $t('gamePass.extraMilestonesConfirmation') }}
              </p>
            </div>
          </template>
        </app-multi-button1>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { GamePassExtraMilestones } from '@/interfaces/responses/gamePass/GamePassStateApiResponse'
import { shopOfferEndPoint } from '@/globalVariables'

interface ComponentData {
  showConfirm: boolean
}

export default defineComponent({
  name: 'GamePassMilestonesExtension',
  props: {
    extraData: {
      type: Object as PropType<GamePassExtraMilestones>,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      showConfirm: false,
    }
  },
  methods: {
    replaceHighlightedText(text: string, replaceValue: number): string {
      const replacedNumber = this.$replacePlaceholder(text, '{number}', replaceValue)
      return this.$replaceHtmlPlaceholders(
        replacedNumber,
        'b',
        'text-texts-standard-important uppercase',
      )
    },
    replaceHighlightedNumber(text: string, replaceValue: number): string {
      return this.$replacePlaceholder(
        text,
        '{number}',
        `<span class="text-texts-standard-important">${replaceValue}</span>`,
      )
    },
    extendMilestones(): void {
      try {
        this.$axios.post(shopOfferEndPoint, { offer_id: this.extraData.id })
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.milestones-extension {
  width: 33.125rem;
  height: 43.5rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url($path-premium + 'gamePass/main/extra-milestones-bg.avif');
  padding: 1.5rem;
  &-wrapper {
    flex-shrink: 0;
    margin-top: 0.75rem;
    margin-left: 1rem;
    padding-right: 1rem;
    padding-left: 1.75rem;
    height: 43.5rem;

    @if $isWsm {
      border-left: 0.13rem solid #fadd1e;
    }
    @if $isSsm {
      border-left: 0.13rem solid #feb942;
    }
  }
  &-decoration {
    width: 32.75rem;
    height: 16.563rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url($path-premium + 'gamePass/main/extra-rewards-bg.avif');
    flex-shrink: 0;
    position: relative;
    top: -1rem;
  }
  .extra-info-text {
    position: relative;
    top: -2rem;
  }
  &-footer {
    width: 100%;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 0.125rem solid #438eb8;
    height: 16rem;
    position: relative;
    top: -3rem;
  }
}
</style>
