<template>
  <div class="flexing skew parameter-box">
    <p
      class="text-texts-standard-default text-36 revert-skew"
      :class="{
        'font-bold': $isWsm,
        'font-semibold': $isSsm,
      }"
    >
      {{ $filters.$formatNumber(value) }}
    </p>
    <app-main-icon :icon-name="iconName" :icon-size="48" />
  </div>
</template>

<script lang="ts">
import { RANKING_POINTS } from '@/globalVariables'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ParameterBox',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    iconName: {
      type: String,
      default: RANKING_POINTS.toLowerCase(),
    },
  },
})
</script>

<style lang="scss" scoped>
.parameter-box {
  width: 16.875rem;
  height: 4.5rem;
  line-height: 2rem;
  margin: 0 auto;
  margin-top: 0.1rem;

  @if $isWsm {
    background-color: #0f3251;
    border: 0.125rem solid #f4d91e;
  }

  @if $isSsm {
    background-color: #363e5d;
    border-style: solid;
    border-width: 0.125rem;
    border-image-source: linear-gradient(to right, #f6c717, #fff284);
    border-image-slice: 1;
    box-shadow:
      inset -3px 0px 40px 0 rgba(6, 34, 55, 0.48),
      inset -0.3px -3px 40px 0 rgba(6, 34, 55, 0.48);
  }
}
</style>
