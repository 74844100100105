import { MAP_ARENA } from '@/globalVariables'
import { GameType } from '@/globalVariables/gameTypes'

import { StageType } from '@/interfaces/Tutorial'
import type { TutorialStage } from '@/interfaces/Tutorial'

const summerStages: TutorialStage[] = [
  // narative saying you unlocked a new discipline
  {
    id: 65,
    name: 'unlockTrapNarrative',
    type: StageType.Narrative,
    overlay: false,
    pageName: 'LayoutViewTutorial',
    texts: [],
  },
  // focus on arena building with gsap animation
  {
    id: 66,
    name: 'clickOnArenaBuildingTrap',
    discipline: 13,
    type: StageType.Focus,
    overlay: false,
    pageName: 'LayoutViewTutorial',
    clientId: [MAP_ARENA],
    texts: [],
  },
  // focus on unlocked discipline with overlay and gsap animation
  {
    id: 67,
    name: 'clickOnDisciplineTrap',
    type: StageType.ClientInput,
    overlay: true,
    pageName: 'ArenaViewTutorial',
    clientId: ['arena-discipline-13'],
    discipline: 13,
    texts: [],
  },
]

const winterStages: TutorialStage[] = [
  // narative saying you unlocked a new discipline
  {
    id: 65,
    name: 'unlockSkijumpNarrative',
    type: StageType.Narrative,
    overlay: false,
    pageName: 'LayoutViewTutorial',
    texts: [],
  },
  // focus on arena building with gsap animation
  {
    id: 66,
    name: 'clickOnArenaBuildingSkijump',
    discipline: 3,
    type: StageType.Focus,
    overlay: false,
    pageName: 'LayoutViewTutorial',
    clientId: [MAP_ARENA],
    texts: [],
  },
  // focus on unlocked discipline with overlay and gsap animation
  {
    id: 67,
    name: 'clickOnDisciplineSkijump',
    type: StageType.ClientInput,
    overlay: true,
    pageName: 'ArenaViewTutorial',
    clientId: ['arena-discipline-3'],
    discipline: 3,
    texts: [],
  },
]

/**
  Tutorial for discipline unlocked
 */
export default class TutorialUnlockDiscipline3 {
  stages(): TutorialStage[] {
    return window.gameName === GameType.Winter ? winterStages : summerStages
  }
}
