<template>
  <teleport to="#app">
    <transition appear name="container">
      <div v-if="showPopup" class="window-overlay club-reminder-container top-0 left-0">
        <div
          v-if="$isMobile()"
          id="club-reminder-overlay"
          class="absolute w-full h-full top-0 left-0 z-10"
        />
        <div class="club-reminder-wrapper absolute w-full h-full flexing z-10">
          <div class="club-reminder-wrapper-tutor absolute" :style="tutorialBossStyle" />

          <section class="absolute club-reminder-dialog">
            <header class="text-texts-standard-important text-40 uppercase text-left">
              <div
                v-if="!tutorial"
                class="control-icon-box absolute club-reminder-close"
                @click="hide()"
              >
                <div class="clickable-element control-icon control-icon-close" />
              </div>
              <p>{{ $t('club.chatLockText2') }}</p>
            </header>
            <div class="club-reminder-dialog-wrapper">
              <p v-if="!tutorial" class="text-texts-standard-important text-32 center-p text-left">
                {{ $t('tutorialNarrative.firstTimeOpenClub2') }}
              </p>
              <p class="text-texts-standard-default text-28 center-p text-left">
                {{ $t('club.joinClubReminder') }}
              </p>
              <section class="club-reminder-benefits flex justify-around">
                <div
                  v-for="(benefit, index) in benefits"
                  :key="index"
                  class="club-reminder-benefit"
                >
                  <p
                    class="font-bold uppercase"
                    :class="{
                      'text-32 text-texts-standard-default': $isWsm,
                      'text-30 text-texts-standard-additional': $isSsm,
                    }"
                  >
                    {{ $t(benefit.text) }}
                  </p>
                  <div class="yellow-strip" />
                  <div
                    class="club-reminder-benefit-img"
                    :style="{
                      backgroundImage:
                        'url(' +
                        pathImages +
                        'clubs/reminder/clubs-reminder-' +
                        benefit.name +
                        '.avif)',
                    }"
                  />
                </div>
              </section>
              <footer v-if="tutorial" class="club-reminder-footer flex justify-end items-center">
                <p
                  v-if="$isMobile()"
                  id="club-reminder-footer-btn"
                  class="text-texts-standard-important text-34 flex justify-end club-reminder-footer-tap"
                >
                  {{ $t('minigame.clickToContinue') }}
                </p>
                <app-button
                  v-else
                  btn-id="club-reminder-footer-btn"
                  btn-type="secondary"
                  :btn-text="$t('button.continue')"
                  btn-size="md"
                  @click="() => {}"
                />
              </footer>
              <footer v-else class="club-reminder-footer flex justify-between items-center">
                <app-check-box
                  :label="$t('common.doNotShowMessage')"
                  label-place="right"
                  :is-checked="dontShowMessage"
                  size="sm"
                  @checked-action="dontShowMessage = !dontShowMessage"
                />

                <app-button
                  btn-id="club-reminder-footer-btn"
                  btn-type="confirm"
                  :btn-text="$t('club.club')"
                  btn-size="md"
                  @click="goToClubs()"
                />
              </footer>
            </div>
          </section>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
import { pathFigures, pathImages } from '@/globalVariables'
import { getIsMobileLocalStorage, getDisciplineBossSlug } from '@/helpers'
import { useUserStore } from '@/store/pinia/userStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import gsap from 'gsap'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  showPopup: boolean
  pathImages: typeof pathImages
  gsapedOne: Nullable<gsap.core.Tween>
  gsapedTwo: Nullable<gsap.core.Tween>
  dontShowMessage: boolean
  benefits: { name: string; text: string }[]
}

export default defineComponent({
  name: 'ClubsReminder',
  props: {
    tutorial: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      showPopup: true,
      pathImages,
      gsapedOne: null,
      gsapedTwo: null,
      dontShowMessage: false,
      benefits: [
        {
          name: 'champs',
          text: 'common.clubChampionship',
        },
        {
          name: 'centrum',
          text: 'club.clubCentrum',
        },
        {
          name: 'events',
          text: 'common.events',
        },
      ],
    }
  },
  computed: {
    ...mapState(useTutorialStore, ['getTutorialBossName', 'getTutorialBossPose']),
    tutorialBossStyle(): string {
      const slug = getDisciplineBossSlug(this.getTutorialBossName)
      return `background-image: url(${pathFigures}/${slug.replace('-', '')}/${slug}-${
        this.getTutorialBossPose
      }.avif);`
    },
  },
  mounted(): void {
    this.gsapMethod()
  },
  updated(): void {
    this.gsapMethod()
  },

  methods: {
    getIsMobileLocalStorage,
    ...mapActions(useUserStore, ['setProfileAttributes']),
    gsapMethod(): void {
      if (!this.gsapedOne) {
        this.gsapedOne = gsap.to('.club-reminder-wrapper-tutor', {
          x: '8rem',
          duration: 1,
          ease: 'power2.inOut',
          opacity: 1,
        })
      }
      if (!this.gsapedTwo) {
        this.gsapedTwo = gsap.to('.club-reminder-dialog', {
          duration: 2,
          ease: 'power2.inOut',
          opacity: 1,
        })
      }
    },
    hide(): void {
      this.showPopup = false
      this.setProfileAttributes({
        name: 'join_club_reminder',
        value: !this.dontShowMessage,
      })
    },
    goToClubs(): void {
      this.hide()
      this.$router.push({
        name: this.$getWebView('ClubsAvailable'),
        query: { homepage: 'true' },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/control-icons.scss';

.club-reminder {
  &-container {
    z-index: 4;
  }

  &-wrapper {
    outline: 1500rem solid transparent;

    &-tutor {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 38.188rem;
      height: 59.563rem;
      bottom: 0;
      left: -5rem;
      outline: 1500rem solid transparent;
      opacity: 0;
    }
  }

  &-dialog {
    width: 75rem;
    min-height: 41.875rem;
    left: 42.3rem;
    outline: 1500rem solid transparent;
    opacity: 0;

    @if $isWsm {
      background-color: rgba(24, 60, 88, 0.5);
      border: 0.125rem solid rgb(69, 129, 175);
      box-shadow:
        inset 0.062rem 0 1.25rem 0 rgba(25, 73, 105, 0.8),
        inset 0.75rem 0.188rem 1.25rem 0px rgba(25, 73, 105, 0.8),
        inset 0 0 1.25rem 0 rgba(25, 73, 105, 0.8),
        inset -0.688rem -0.188rem 1.25rem 0 rgba(25, 73, 105, 0.8);
    }

    @if $isSsm {
      background-color: #252f45;
      border: 0.125rem solid #5672a4;
    }

    header {
      min-height: 3.9rem;
      padding-left: 2.3125rem;
      margin-top: 1rem;

      @if $isWsm {
        background-image: linear-gradient(to right, rgba(20, 74, 115, 1), rgba(20, 74, 115, 0));
      }

      @if $isSsm {
        background-image: linear-gradient(to right, #4f5373, rgba(79, 83, 115, 0.8), transparent);
      }

      & > p {
        max-width: 90%;
      }

      .control-icon-box {
        right: 1rem;
        top: 0.5rem;
      }
    }

    &-wrapper {
      .center-p {
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-left: 2.4375rem;
      }
    }
  }

  &-benefits {
    height: 23.125rem;
    padding-top: 0.8rem;

    @if $isWsm {
      background-color: #0a2e4a;
    }

    @if $isSsm {
      background-color: #232a40;
    }
  }

  &-benefit {
    .yellow-strip {
      margin-bottom: 0.5rem;

      @if $isSsm {
        height: 0.25rem;
        background-color: theme('colors.texts.standard.important');
        background-image: none;
      }
    }

    &-img {
      width: 22.5rem;
      height: 16.875rem;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      &.centrum {
        background: url($path-clubs + 'reminder/centrum.avif') center no-repeat;
      }

      &.champs {
        background: url($path-clubs + 'reminder/champs.avif') center no-repeat;
      }
    }
  }

  &-footer {
    height: 5.625rem;
    padding: 0 2.3125rem;

    &-tap {
      opacity: 1;
      animation: bounce 2s ease-in-out;
      width: 24%;

      &:active {
        transform: scale(0.95);
        padding: 0;
      }

      @keyframes bounce {
        0% {
          opacity: 1;
        }

        25% {
          opacity: 0;
        }

        50% {
          opacity: 1;
        }

        75% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }

    .app-checkbox-text {
      text-transform: initial;
    }
  }
}

.container-enter-from {
  background: rgba(0, 0, 0, 0);
}

.container-enter-to {
  background: rgba(0, 0, 0, 0.5);
}

.container-enter-active {
  transition: all 1s ease;
}
</style>
