<template>
  <footer v-if="skillData" class="lab-footer">
    <!-- <section v-if="!researchInProgress" class="w-full h-full flexing">
      <p class="text-texts-standard-additional text-30">
        {{ $t('research.noResearching') }}
      </p>
    </section> -->
    <section class="lab-footer-content w-full h-full relative flex items-center justify-between">
      <div class="lab-footer-left h-full flexing">
        <app-discipline-icon v-if="disciplineId" :discipline-id="disciplineId" :size="70" />
        <tippy theme="WSM1" placement="top" max-width="43rem">
          <div class="footer-lab-icon-wrapper">
            <lab-icons v-if="iconData" :icon-data="iconData" />
          </div>
          <template #content>
            <app-discipline-icon
              v-if="disciplineId"
              :discipline-id="disciplineId"
              :size="70"
              class="lab-footer-left-tooltip-discipline"
            />
            <div class="lab-footer-left-tooltip-icon__wrapper">
              <lab-icons
                v-if="iconData"
                :icon-data="iconData"
                class="skill-icon lab-footer-left-tooltip-icon"
              />
            </div>
            <div
              class="lab-footer-left-tooltip-title relative font-bold text-texts-standard-default text-30"
            >
              {{ $t(`${skillData.name}`) }}
            </div>
            <lab-detail-table
              :skill-type="SkillType.Research"
              :skill-data="skillData"
              class="lab-footer-left-tooltip-table relative"
              :discipline-id="disciplineId"
            />
          </template>
        </tippy>

        <p class="text-texts-standard-default text-30" :class="[skillData.name]">
          {{ $t(`${skillData.name}`) }}
        </p>
      </div>
      <div class="lab-footer-right h-full flexing">
        <p class="lab-footer-right-text text-texts-standard-important text-30">
          {{ $t('research.researchCounting') }}
        </p>
        <div class="flexing lab-time">
          <p class="text-texts-standard-default">
            <app-timer
              v-if="skillData.researchTimer"
              v-tippy="{
                content: $t('research.researchCounting'),
                placement: 'top',
                theme: 'WSM',
              }"
              :time="Number(skillData.researchTimer)"
              class="font-bold"
              :transparent="true"
              :text-size="'1.75rem'"
              @countdown-action="$emit('finishedResearch')"
            />
          </p>
        </div>
        <app-multi-button1
          v-tippy="enoughGems ? {} : { theme: 'WSM', content: $t('research.dontGems') }"
          btn-type="credit"
          :btn-icon="'gems'"
          btn-size="md"
          :disabled="!enoughGems"
          class="lab-button"
          :btn-text="$t('research.speedUp')"
          :is-custom-action="true"
          @click="enoughGems ? (showConfirm = true) : ''"
        >
          <template #rightPart>
            <div class="flexing">
              <span class="price">
                {{ skillData.speedupPrice }}
              </span>
            </div>
          </template>
        </app-multi-button1>
      </div>
    </section>

    <confirm-popup
      v-if="showConfirm && skillData"
      :button-data="btnConfirm"
      :popup-title="$t('research.speedUp')"
      :button-text-left="$t('research.speedUp')"
      :button-text-right="skillData.speedupPrice"
      @close="showConfirm = false"
      @action="confirmSpeedup"
    >
      <div class="container-upgrade text-36 text-texts-standard-default italic">
        <p>
          {{ $t('research.reallySpeed') }}
        </p>
      </div>
    </confirm-popup>
  </footer>
</template>

<script lang="ts">
import LabDetailTable from '@/components/Lab/LabDetailTable.vue'
import LabIcons from '@/components/Lab/LabIcons.vue'
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import { researchInfoCellEndpoint, MECHANIC_RESEARCH } from '@/globalVariables'
import { formatTime, mapResearchCellData } from '@/helpers'
import { useLabStore } from '@/store/pinia/labStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { type Icon, type Cell, SkillType } from '@/interfaces/lab/Cell'
import type { CellEndpoint } from '@/interfaces/responses/lab/LabResponses'

interface BtnData {
  btnId: string
  btnType: string
  btnSize: string
}

interface ComponentData {
  SkillType: typeof SkillType
  disciplineId: number
  skillData: Cell
  showConfirm: boolean
  btnConfirm: BtnData
}
export default defineComponent({
  name: 'LabFooter',
  components: {
    LabDetailTable,
    ConfirmPopup,
    LabIcons,
  },
  emits: ['speedup', 'finishedResearch'],
  data(): ComponentData {
    return {
      disciplineId: null,
      skillData: null,
      showConfirm: false,
      btnConfirm: {
        btnId: 'btn-research-speedup',
        btnType: 'credit',
        btnSize: 'md',
      },
      SkillType,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      gems: 'getGems',
    }),
    ...mapState(useLabStore, {
      researchInProgress: 'getResearchInProgress',
    }),
    enoughGems(): boolean {
      return this.skillData.speedupPrice
        ? this.gems.value > Number(this.skillData.speedupPrice)
        : false
    },
    iconData(): Icon {
      return {
        skillType: MECHANIC_RESEARCH,
        currentLevel: this.skillData?.currentLevel ? Number(this.skillData?.currentLevel) : 0,
        maxLevel: Number(this.skillData?.maxLevel),
        gemOnly: this.skillData?.gemOnly,
        locked: this.skillData?.locked,
        isNew: this.skillData?.isNew,
        icon: this.skillData?.icon,
        isIconRepeatable: this.skillData.isIconRepeatable,
        unlockedAfterLevel: this.skillData?.unlockedAfterLevel,
      }
    },
  },
  async created(): Promise<void> {
    await this.getStatus()
  },
  methods: {
    formatTime,
    ...mapActions(useLabStore, {
      setResearchStatus: 'setResearchStatus',
    }),
    async getStatus(): Promise<void> {
      this.skillData = null
      await this.setResearchStatus()
      const cellId = this.researchInProgress?.research_cells_user_games_id
      if (cellId) {
        const response = await this.$axios.post<{}, CellEndpoint[]>(researchInfoCellEndpoint, {
          cell_ids: [cellId],
        })
        if (response.length) {
          this.skillData = mapResearchCellData(response[0])
          this.disciplineId = response[0].discipline_id
        }
      }
    },
    confirmSpeedup(): void {
      this.showConfirm = false
      this.$emit('speedup', this.skillData.operationId)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';

.lab-footer {
  width: 100%;
  height: 7.125rem;
  bottom: 0;

  @if $isSsm {
    border-top: solid 0.125rem rgba(88, 107, 157, 1);
    background-color: #202941;
  }

  @if $isWsm {
    border-top: solid 0.125rem rgba(160, 220, 255, 0.6);
    background-color: #1b2f47;
  }

  &-content {
    padding: 0 3rem 0 7rem;
  }

  &-left {
    height: 100%;

    .footer-lab-icon-wrapper {
      transform: scale(0.75);
    }

    &-tooltip {
      &-discipline {
        position: absolute;
        top: 1rem;
        left: 1rem;
      }

      &-icon {
        &__wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1.563rem 0 0;
        }
      }

      &-title {
        width: 27.5rem;
        height: 3.5rem;
        line-height: 3.5rem;
        margin: 0.313rem auto 0.938rem;

        @if $isSsm {
          background: linear-gradient(
            to right,
            transparent,
            #34415d,
            rgba(52, 65, 93, 0.85),
            rgba(39, 50, 73, 0.85),
            #273249,
            transparent
          );
        }

        @if $isWsm {
          background: linear-gradient(
            to left,
            rgba(21, 78, 121, 0),
            rgba(21, 78, 121, 1),
            rgba(21, 78, 121, 0)
          );
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 0.125rem;
          left: 0;

          @if $isSsm {
            background: linear-gradient(
              90deg,
              transparent,
              #586b9d,
              rgba(255, 255, 255, 0.5),
              rgba(255, 255, 255, 0.5),
              #586b9d,
              transparent
            );
          }

          @if $isWsm {
            background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
          }
        }

        &::before {
          top: 0;
        }

        &::after {
          bottom: 0;
        }
      }

      &-table {
        width: 41.25rem;
      }
    }
  }

  &-right {
    &-text {
      width: 25rem;
      line-height: 2rem;
      text-align: right;
    }

    .lab-time {
      margin: 0 1.5rem 0 1rem;

      .timer-component {
        min-width: 10rem;

        &.with-icon {
          padding-left: 0;
        }

        &:deep(.icon-time) {
          width: 2rem;
          height: 2rem;
          margin-right: 0;
        }
      }
    }
  }
}

.skill-icon {
  margin: 0 1.75rem;
}

.lab-tooltip {
  width: 35.6rem;
  height: 24.063rem;

  &-icons {
    flex-direction: row;
    display: flex;
    height: 10.125rem;
  }

  &-skill-icon {
    width: 7.5rem;
    height: 6.5rem;
    background: red;
    position: absolute;
    left: 50%;
    top: 2.375rem;
    transform: translateX(-50%);
  }

  &-skill-name {
    background: linear-gradient(to right, transparent, #154e79, transparent);
    width: 100%;
    height: 3.125rem;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 0.125rem;
      background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
      left: 0;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  &-footer {
    width: 41.252rem;
    height: 8.75rem;
    position: absolute;
    bottom: 0.688rem;
    left: -2rem;
    border-top: 0.063rem solid #d9ecff;
    border-bottom: 0.063rem solid #d9ecff;
    box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.5);

    &-box {
      width: 7.813rem;
      height: 100%;
      box-shadow: inset 0 0 20px 0 #1b2f47;
      background-color: rgba(55, 127, 184, 0.3);

      &-discipline {
        height: 5.438rem;
        width: 5.438rem;
        margin: 0 auto;
      }

      &-count {
        border-top: 0.063rem solid #d9ecff;
        height: 3.2rem;
        box-shadow: inset 0 0 20px 0 #1b2f47;
        background-color: rgba(55, 127, 184, 0.3);
      }

      &:first-child {
        width: 10rem;
        background-color: rgba(55, 127, 184, 0.8);
        box-shadow: none;

        .lab-tooltip-footer-box-count {
          background-color: rgba(55, 127, 184, 0.8);
          box-shadow: none;
        }
      }
    }
  }
}
</style>
