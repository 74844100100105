import type { App, Plugin } from 'vue'

const FIREFOX_BROWSER_REGEXP = /Firefox/i

let cache: boolean

export const isFirefox = (): boolean => {
  if (cache !== undefined) return cache
  return (cache = FIREFOX_BROWSER_REGEXP.test(navigator.userAgent))
}

export const isFirefoxPlugin: Plugin = {
  install: (app: App): void => {
    app.config.globalProperties.$isFirefox = isFirefox
  },
}
