<template>
  <div
    id="club-championship-history"
    class="club-championship-history app-page-wrapper absolute w-full h-full"
  >
    <clubs-championship-menu />
    <section class="w-full h-full safe-area">
      <matches-history :history="getMatchesHistory" redirect-link="ClubsChampionshipReport" />
    </section>
  </div>
</template>

<script lang="ts">
import ClubsChampionshipMenu from '@/components/Club/Championship/ClubsChampionshipMenu.vue'
import MatchesHistory from '@/components/Reports/MatchesHistory.vue'
import { ARENA, REPORT_MATCH_TIME } from '@/globalVariables'
import { useMatchesStore } from '@/store/pinia/matchesStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClubsChampionshipHistory',
  components: {
    ClubsChampionshipMenu,
    MatchesHistory,
  },
  computed: {
    ...mapState(useMatchesStore, ['getMatchesHistory']),
  },
  async created(): Promise<void> {
    localStorage.setItem(REPORT_MATCH_TIME, ARENA)
    await this.loadMatchesHistory({ isClubChampionship: true })
  },

  methods: {
    ...mapActions(useMatchesStore, ['loadMatchesHistory']),
  },
})
</script>
