import {
  CATEGORY_BASE,
  CATEGORY_EQUIPMENT,
  CATEGORY_EQUIPMENT_PREMIUM_UPGRADE,
  CATEGORY_EQUIPMENT_UPGRADE,
  CATEGORY_RESEARCH_BASE,
  CATEGORY_RESEARCH_PREMIUM,
  CATEGORY_RESEARCH_REPEATABLE,
  CATEGORY_SEASONAL,
  EQUIPMENT,
  POWER,
  RESEARCH,
} from '@/globalVariables'
import type Country from '@/interfaces/Country'
import type Reward from '@/interfaces/Reward'

interface Attribute {
  attribute: {
    id: number
    category: string
    max_value: number
    name: string
  }
  attribute_value: number
}

export interface DisciplineData {
  discipline: {
    id: number
    name: string
  }
  usersAvailableDisciplines: {
    user: {
      id: string
      username: string
      country: Country
      event_badges?: string[]
    }
    attributes: Attribute[]
  }[]
}

// TODO: what a name for this interface
export interface FinalObject {
  attributes?: Record<string, number>
  [EQUIPMENT]?: {
    translateKey: string
    value: number
  }
  [RESEARCH]?: {
    translateKey: string
    value: number
  }
  [CATEGORY_BASE]?: {
    translateKey: string
    value: number
  }
  [POWER]?: {
    translateKey: string
    value: number
  }
  id?: number
  consumableCost?: number
  consumableCostDiscounted?: number
  buildingLimitReached?: boolean
  locked?: boolean
  levelToUnlock?: number
  mechanicStats?: {
    disciplineLevel: number
    bestScore: number
  }
  userAttributes?: {
    [key: string]: {
      value: number
      maxValue: number
    }
  }
  userStats?: {
    trainingPoints: number
    rankingPoints: number
  }
  trainingPointsTarget?: number
  rewards?: Reward[][]
  rewardsWithoutBenefit?: Reward[][]
  isTutorialPassed?: boolean
  slotId?: string
  state?: string
  expiration?: number
}

export const calculateAttributes = (
  disciplineData: DisciplineData,
  finalObject: FinalObject,
): void => {
  finalObject.attributes = disciplineData.usersAvailableDisciplines[0].attributes.reduce(
    (prev: FinalObject['attributes'], currentVal: Attribute): FinalObject['attributes'] => {
      prev[currentVal.attribute.category] = currentVal.attribute_value
      return prev
    },
    {},
  )

  finalObject[EQUIPMENT] = {
    translateKey: 'common.equipment',
    value:
      (finalObject.attributes[CATEGORY_EQUIPMENT] || 0) +
      (finalObject.attributes[CATEGORY_EQUIPMENT_UPGRADE] || 0) +
      (finalObject.attributes[CATEGORY_EQUIPMENT_PREMIUM_UPGRADE] || 0),
  }
  finalObject[RESEARCH] = {
    translateKey: 'common.research',
    value:
      (finalObject.attributes[CATEGORY_RESEARCH_BASE] || 0) +
      (finalObject.attributes[CATEGORY_RESEARCH_PREMIUM] || 0) +
      (finalObject.attributes[CATEGORY_RESEARCH_REPEATABLE] || 0),
  }
  finalObject[CATEGORY_BASE] = {
    translateKey: 'common.basicAttribute',
    value: finalObject.attributes[CATEGORY_BASE] || 0,
  }
  finalObject[POWER] = {
    translateKey: 'common.totalPower',
    value:
      finalObject[EQUIPMENT].value +
      finalObject[RESEARCH].value +
      finalObject[CATEGORY_BASE].value +
      (finalObject.attributes[CATEGORY_SEASONAL] || 0),
  }
  finalObject[CATEGORY_SEASONAL] = {
    translateKey: 'common.seasonalAttribute',
    value: finalObject.attributes[CATEGORY_SEASONAL] || 0,
  }
}
