import type { RewardedItemsPositionsInterface } from '@/map-phaser-new/interfaces'

export const rewardedItemsPositionsData: RewardedItemsPositionsInterface[] = [
  { x: 805, y: 1555 },
  { x: 1438, y: 1323 },
  { x: 1053, y: 989 },
  { x: 1087, y: 829 },
  { x: 1500, y: 985 },
  { x: 1673, y: 1019 },
  { x: 1523, y: 631 },
  { x: 1882, y: 969 },
  { x: 2295, y: 905 },
  { x: 2120, y: 521 },
  { x: 2429, y: 496 },
  { x: 2878, y: 871 },
  { x: 2966, y: 1250 },
  { x: 3244, y: 1273 },
  { x: 2152, y: 1328 },
  { x: 2653, y: 1587 },
  { x: 3035, y: 1464 },
]
