<template>
  <aside class="left-side box-border-1 bordered-1">
    <avatar-box :class="'left-side-avatar'" :avatar-id="Number(avatar)" :avatar-gender="sex" />
    <section class="left-side-player text-texts-standard-default text-32 relative flexing">
      <app-user
        :id="playerData.userId"
        :country="playerData.country"
        :name="playerData.name"
        :badges-data="playerData.eventBadges ?? null"
      />
    </section>
    <section class="left-side-club flexing">
      <app-club-name
        v-if="club"
        :club-id="club.id"
        :name="club.name"
        :club-logo-id="club.logo_id"
        :clubs-background-id="club.logo_background_id"
      />
      <div v-else class="text-texts-standard-default text-30 uppercase">
        {{ $t('profile.withoutClub') }}
      </div>
    </section>
    <footer class="left-side-footer">
      <p class="power-text text-32 text-texts-standard-default relative flexing">
        {{ $t('common.totalPower') }}
      </p>
      <div class="left-side-footer-bottom flex relative items-center justify-center">
        <div class="discipline-icon-wrapper">
          <div :class="`icon-discipline-${activeDisciplineId}-light-70 discipline`" />
        </div>
        <p v-if="discipline[POWER]" class="power font-bold text-texts-standard-important text-50">
          {{ $filters.$formatNumber(discipline[POWER].value) }}
        </p>
        <div class="tooltip">
          <tippy theme="WSM1" placement="top">
            <app-icon :icon-name="'info-50'" class="info" />
            <template #content>
              <div class="power-tooltip tooltip-content text-texts-standard-default text-30">
                <p class="tooltip-content-top text-36 font-bold w-full relative">
                  {{ $t('common.totalPower') }}
                </p>
                <div class="tooltip-content-sub-top m-auto flexing w-full">
                  <div class="discipline-icon-wrapper">
                    <div :class="`icon-discipline-${activeDisciplineId}-light-70 discipline`" />
                  </div>
                  <p
                    v-if="discipline[POWER]"
                    class="power font-bold text-texts-standard-important text-50"
                  >
                    {{ $filters.$formatNumber(discipline[POWER].value) }}
                  </p>
                </div>
                <div class="tooltip-content-attributes">
                  <section
                    v-for="(attributeKey, index) in attributesKeys"
                    :key="index"
                    class="tooltip-content-rows m-auto flex items-center text-30 text-texts-standard-default relative justify-between"
                  >
                    <p>
                      {{ $t(`${discipline[attributeKey]?.translateKey}`) }}
                    </p>
                    <p class="text-texts-standard-important">
                      {{ $filters.$formatNumber(discipline[attributeKey]?.value) }}
                    </p>
                    <app-icon
                      v-show="index < attributesKeys.length - 1"
                      :icon-name="'plus-blue'"
                      class="absolute tooltip-plus"
                    />
                  </section>
                </div>
              </div>
            </template>
          </tippy>
        </div>
      </div>
    </footer>
  </aside>
</template>

<script lang="ts">
import AppClubName from '@/components/GlobalComponents/AppClubName.vue'
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import AvatarBox from '@/components/Profile/AvatarBox.vue'
import { pathImages, POWER } from '@/globalVariables'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { DailyLeaguePlayerData } from '@/interfaces/DailyLeague'
import type { Nullable } from '@/interfaces/utils'
import type { FinalObject } from '@/helpers'

interface ComponentData {
  pathImages: typeof pathImages
  POWER: typeof POWER
}

export default defineComponent({
  name: 'StarofthedayDetailLeft',
  components: {
    AvatarBox,
    AppClubName,
    AppUser,
  },
  props: {
    playerData: {
      type: Object as PropType<Nullable<DailyLeaguePlayerData>>,
      default: () => null,
    },
    activeDisciplineId: {
      type: Number,
      required: true,
    },
    discipline: {
      type: Object as PropType<Nullable<FinalObject>>,
      default: () => null,
    },
    attributesKeys: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
      POWER,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      avatar: 'getAvatar',
      sex: 'getSex',
      club: 'getClub',
    }),
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';
@import '@/assets/styles/views/staroftheday/disciplineDetail.scss';

.tooltip-content {
  &-top {
    height: 3.25rem;
    background: radial-gradient(circle farthest-corner at top center, #377fb8 7%, #fffdfd00 78%);

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      height: 0.125rem;
      opacity: 0.5;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.016),
        #fff,
        rgba(255, 255, 255, 0)
      );
    }
  }

  &-sub-top {
    height: 7.5625rem;
    width: 75%;
    background: radial-gradient(
      rgb(55, 127, 184, 0.6) 10%,
      rgb(55, 127, 184, 0.6) 20%,
      transparent 60%,
      transparent 100%
    );

    .discipline-icon-wrapper {
      width: 4.375rem;
      height: 4.375rem;
    }
  }

  .tooltip-content-attributes {
    padding: 0 2rem;

    .tooltip-content-rows {
      width: 22.4375rem;
      height: 4.125rem;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        height: 0.125rem;
        opacity: 0.5;
        background: linear-gradient(
          to left,
          rgba(255, 255, 255, 0.016),
          #fff,
          rgba(255, 255, 255, 0)
        );
      }

      &:last-child {
        &:after {
          content: '';
          display: none;
        }
      }

      .tooltip-plus {
        right: -0.5rem;
        bottom: -1rem;
        transform: scale(0.7);
      }
    }
  }
}
</style>
