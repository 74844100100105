<template>
  <header class="chains-header flexing w-full px-8 relative" :data-event-theme="namespace">
    <section class="flex flex-row items-center justify-start w-full ml-6">
      <app-icon class="info-icon mr-4 pointer-events-none" icon-name="info-rounded" />
      <p
        class="chains-header-text text-28 text-texts-standard-default text-left font-bold italic leading-tight"
      >
        {{ $te('chainsHeaderText') }}
      </p>
    </section>
    <app-button
      class="absolute top-7 right-10 z-10"
      btn-type="primary"
      :btn-text="$te('eventInformation')"
      btn-size="xsm"
      style="width: 18.75rem"
      @click="isOpen = true"
    />
  </header>
  <info-popup
    v-if="isOpen"
    :popup-title="$te('chainsPopupTitle')"
    class="chains-popup"
    width="76.125rem"
    @close="isOpen = false"
  >
    <section class="chains-popup-info text-34 text-texts-standard-default">
      <p class="chains-popup-info-text">
        {{ $te('chainsPopupText') }}
      </p>
    </section>
  </info-popup>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  isOpen: boolean
}

export default defineComponent({
  name: 'ChainsHeader',
  components: {
    InfoPopup,
  },
  data(): ComponentData {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='frozen'] {
  @if $isWsm {
    --events-chains-header-bg: rgba(0, 5, 25, 0.33);
  }

  @if $isSsm {
    --events-chains-header-bg: rgba(25, 27, 72, 0.6);
  }
}

.chains-header {
  height: 7.625rem;
  background-color: var(--events-chains-header-bg);

  &-text {
    max-width: 75%;
  }

  .info-icon {
    min-width: 3.5rem;
  }
}

.chains-popup {
  &-info {
    height: 16.5625rem;
    padding: 3.1875rem 6.25rem;
    line-height: 2.5rem;

    &-text {
      line-height: 3rem;
    }
  }
}
</style>
