<template>
  <span
    v-if="(!refreshTime || refreshTime <= 0) && isRewardedAdLoaded"
    class="watch-button-wrapper"
  >
    <app-button
      v-if="isNoVideoAds"
      btn-id="game-pass-claim-button"
      btn-size="md"
      btn-type="confirm"
      :btn-text="$t('common.claim')"
      :loading="isLoadingGamePassRewards"
      :disabled="isLoadingGamePassRewards"
      @click="claimGamePassRewards"
    />
    <app-multi-button
      v-else
      btn-type="secondary"
      btn-size="md"
      :btn-text="$t('button.watch')"
      multi-width="25.313rem"
      :loading="isMobilePlayingVideo"
      @click="playVideo"
    >
      <template #rightPart>
        <app-icon :icon-name="'play-ad'" />
      </template>
    </app-multi-button>
  </span>
  <div
    v-if="refreshTime > 0"
    class="cooldown-timer text-texts-standard-default font-bold text-40 flex items-center relative"
  >
    <app-svg-icon icon-name="bracket" height="2.75rem" :fill="PREMIUM_LINE_BLUE_COLOR" />
    <app-timer
      :time="refreshTime"
      transparent
      centered
      text-size="3.125rem"
      :style="{ 'min-width': '5rem' }"
      @countdown-action="$emit('reloadContent')"
    />
    <app-svg-icon
      icon-name="bracket"
      height="2.75rem"
      :fill="PREMIUM_LINE_BLUE_COLOR"
      :vertical-flip="true"
    />
  </div>
</template>

<script lang="ts">
import { VideoAdState } from '@/enums/VideoAdStates'
import {
  PREMIUM_LINE_BLUE_COLOR,
  VIDEO_AD_DURATION,
  metaPremiumAdsClaimEndpoint,
} from '@/globalVariables'
import { requestWebAd, sendToFlutter } from '@/helpers'
import type { PremiumVideoAdsClaimApiResponse } from '@/interfaces/responses/premium/PremiumVideoAdsClaimApiResponse'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useGamePassStore } from '@/store/pinia/gamePassStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  PREMIUM_LINE_BLUE_COLOR: typeof PREMIUM_LINE_BLUE_COLOR
  isLoadingGamePassRewards: boolean
}

export default defineComponent({
  name: 'WatchForReward',
  props: {
    refreshTime: {
      type: Number,
      default: 0,
    },
    adType: {
      type: String,
      required: true,
    },
  },
  emits: ['reloadContent'],

  data(): ComponentData {
    return {
      PREMIUM_LINE_BLUE_COLOR,
      isLoadingGamePassRewards: false,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      isMobilePlayingVideo: 'getMobileVideoPlaying',
      isRewardedAdLoaded: 'getRewardedAdLoaded',
    }),
    ...mapState(useGamePassStore, ['isNoVideoAds']),
  },
  mounted(): void {
    window.rewardedVideoEarned = this.rewardedVideoEarned
    window.failedToLoadVideoAds = this.rewardedVideoFailed
    window.rewardedVideoEnd = this.rewardedVideoEnd
  },
  methods: {
    ...mapActions(usePremiumStore, {
      webVideoPlay: 'setWebVideoPlay',
      setVideoPlay: 'setVideoPlay',
    }),
    rewardedVideoFailed(): void {
      this.setVideoPlay(false)
    },
    rewardedVideoEnd(): void {
      this.setVideoPlay(false)
    },
    mobileVideoPlay(): void {
      this.setVideoPlay(true)
      setTimeout((): void => {
        this.setVideoPlay(false)
      }, VIDEO_AD_DURATION)
    },
    async playVideo(): Promise<void> {
      try {
        // 1. Call API to start video state
        await this.$axios.put<{}, PremiumVideoAdsClaimApiResponse>(metaPremiumAdsClaimEndpoint, {
          type: this.adType,
          state: VideoAdState.Started,
        })

        // 2. This point, after the API call, is crucial for ensuring correct theme music stopping/playing
        if (this.$isMobile()) {
          this.mobileVideoPlay()
        } else {
          this.webVideoPlay(true)
        }
      } catch (error: unknown) {
        console.error(error)
      } finally {
        if (this.$isMobile()) {
          sendToFlutter('{\r\n "event":"playRewarded"\r\n}')
        } else {
          requestWebAd({
            id: 'video-ad-web-default',
            onCallbackComplete: (): void => {
              this.rewardedVideoEarned()
              this.setVideoPlay(false)
            },
          })
        }
      }
    },
    async rewardedVideoEarned(): Promise<void> {
      try {
        await this.$axios.put<{}, PremiumVideoAdsClaimApiResponse>(metaPremiumAdsClaimEndpoint, {
          type: this.adType,
          state: VideoAdState.Finished,
        })

        this.webVideoPlay(false)
        this.$emit('reloadContent')
        this.setVideoPlay(false)
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async claimGamePassRewards(): Promise<void> {
      if (!this.isNoVideoAds) return
      if (this.isLoadingGamePassRewards) return
      if (this.refreshTime > 0) return

      try {
        this.isLoadingGamePassRewards = true
        await this.$axios.put<{}, PremiumVideoAdsClaimApiResponse>(metaPremiumAdsClaimEndpoint, {
          type: this.adType,
          state: VideoAdState.Started,
        })
        this.$emit('reloadContent')
      } catch (error: unknown) {
        console.error(error)
      } finally {
        this.isLoadingGamePassRewards = false
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.watch-button-wrapper {
  position: relative;

  @if $isSsm {
    .multi.btn-secondary {
      color: #233441;
      background-image: linear-gradient(to right, #ff9b4a, #f6bc68 60%);
      border: solid 0.219rem #ffcfa8;

      &::before {
        background-color: #e36420;
      }

      &:deep(.btn-count) {
        padding: 0 2.75rem;
      }

      .icon.icon-play-ad {
        width: 3.5rem;
        height: 3.5rem;
      }
    }
  }
}

.cooldown-timer {
  &-left,
  &-right {
    height: 2.0625rem;
    width: 0.125rem;
    background-color: #5199c6;

    &:after,
    &:before {
      background-color: #5199c6;
      height: 0.125rem;
      width: 0.375rem;
      content: '';
      position: absolute;
    }

    &:after {
      top: 0;
    }

    &:before {
      bottom: 0;
    }
  }

  &-left {
    left: 0;
    margin-right: 1rem;
  }

  &-right {
    right: -1rem;
    margin-left: 1rem;

    &:after,
    &:before {
      left: -0.3rem;
    }
  }

  & .timer-component.with-icon {
    padding-left: 0;
  }
}
</style>
