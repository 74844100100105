import type { ExclamationNotificationConfigInterface } from '@/map-phaser-new/interfaces'

export const exclamationNotification: ExclamationNotificationConfigInterface = {
  textureKey: 'exclamationMarkNotification',
  depth: 8,
  origin: 0,
  animationYIndent: '+15',
  animationYEase: 'Back',
  yoyo: true,
  duration: 850,
  repeat: -1,
  yoyoHold: 3000,
}
