<template>
  <div
    class="clubs-employees-box-name text-34 uppercase"
    :class="[
      `name-color-${boxColor}`,
      isEmptyBox ? 'font-bold text-texts-standard-important' : 'text-texts-standard-default',
    ]"
  >
    <p>{{ text }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClubsEmployeesBoxName',
  props: {
    boxColor: {
      type: String,
      default: 'blue',
      validator(value: string): boolean {
        return ['blue', 'gold', 'purple', 'green'].includes(value)
      },
    },
    text: {
      type: String,
      required: true,
    },
    isEmptyBox: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/club/employees.scss';
</style>
