<template>
  <section class="app-page-wrapper piggy-bank-view flex flex-col safe-area">
    <component-loading :is-loading="!dataLoaded" />
    <div v-if="dataLoaded" class="piggy-bank-wrapper">
      <div class="piggy-bank-header flex flex-row justify-between">
        <div class="piggy-bank-logo" />
        <piggy-bank-info-timer :time="remainingTime" :about="progress.config_table" />
      </div>
      <div class="piggy-bank-subheader">
        <info-text :text-size="28" :text="infoText" />
      </div>
      <div class="piggy-bank-body">
        <div v-for="(offer, index) in offers" :key="index">
          <piggy-bank-item :offer-data="offer" @buy="buyPack(offer)" />
        </div>
      </div>
    </div>
    <payment-terminal
      v-if="terminal"
      :item-id="selectedPackId"
      :wire-transfer-off="true"
      @close="terminal = false"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import { getIsMobileLocalStorage, sendToFlutter } from '@/helpers'
import PiggyBankItem from '@/components/Premium/PiggyBank/PiggyBankItem.vue'
import PiggyBankInfoTimer from '@/components/Premium/PiggyBank/PiggyBankInfoTimer.vue'
import InfoText from '@/components/InfoText.vue'
import PaymentTerminal from '@/components/Popup/Premium/PaymentTerminal.vue'
import { usePiggyBankStore } from '@/store/pinia/piggyBankStore'
import { useRenderQueueStore } from '@/store/pinia/renderQueueStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { RenderQueueComponent } from '@/enums/RenderQueueTypes'
import {
  metaPremiumPayEndpoint as META_PREMIUM_PAY_ENDPOINT,
  metaPremiumPackEndpoint as META_PREMIUM_PACK_ENDPOINT,
} from '@/globalVariables/endpoints'
import { PaymentMethods } from '@/interfaces/Payments'

import type { PiggyBankOfferPacksData } from '@/interfaces/premium/Offer'
import type PremiumPayApiResponse from '@/interfaces/responses/premium/PremiumPayApiResponse'
import type PremiumPackApiResponse from '@/interfaces/responses/premium/PremiumPackApiResponse'

interface ComponentData {
  terminal: boolean
  selectedPackId: string
}

export default defineComponent({
  name: 'PremiumPiggyBank',
  components: {
    InfoText,
    PiggyBankInfoTimer,
    PiggyBankItem,
    PaymentTerminal,
  },
  data(): ComponentData {
    return {
      terminal: false,
      selectedPackId: null,
    }
  },
  computed: {
    ...mapState(usePiggyBankStore, {
      offers: 'getOffers',
      progress: 'getProgress',
    }),
    ...mapState(useRenderQueueStore, ['getCurrentComponentInQueue']),
    dataLoaded(): boolean {
      return !!this.offers && !!this.progress
    },
    remainingTime(): number {
      return this.progress.time_remaining
    },
    rewardType(): string {
      return this.offers[0].reward.name.toLowerCase()
    },
    infoText(): string {
      let text: string
      if (this.rewardType === 'money') text = 'piggyBank.piggyBankInfoMoney'
      if (this.rewardType === 'gems') text = 'piggyBank.piggyBankInfoGems'
      if (this.rewardType === 'universal_training_points') text = 'piggyBank.piggyBankInfoTP'

      return this.$replacePlaceholder(this.$t(text), '{piggybank}', this.$t('piggyBank.piggyBank'))
    },
    isMobileLocalStorage(): boolean {
      return getIsMobileLocalStorage() === 1
    },
  },
  watch: {
    async '$route.query.reloadData'(): Promise<void> {
      if (!this.$route.query.reloadData) return

      await Promise.all([this.loadOffers(), this.loadProgress()])

      this.$router.replace({
        query: {
          ...this.$route.query,
          reloadData: undefined,
        },
      })
    },
  },
  async mounted(): Promise<void> {
    await Promise.all([this.loadOffers(), this.loadProgress()])
  },
  beforeUnmount(): void {
    if (this.getCurrentComponentInQueue === RenderQueueComponent.PiggyBank) {
      this.setNextInRenderQueue()
    }
  },
  methods: {
    ...mapActions(usePiggyBankStore, ['loadOffers', 'loadProgress']),
    ...mapActions(useResponseTaskStore, ['showPaymentLoading', 'showPaymentMessage']),
    ...mapActions(useRenderQueueStore, ['setNextInRenderQueue']),
    async buyPack(packData: PiggyBankOfferPacksData): Promise<void> {
      this.selectedPackId = packData.pack_id
      if (!this.isMobileLocalStorage) {
        this.terminal = true
        return
      }

      this.showPaymentLoading()

      const responsePay = await this.$axios.get<{}, PremiumPayApiResponse>(
        `${META_PREMIUM_PAY_ENDPOINT}/pack/${this.selectedPackId}/${
          !this.isMobileLocalStorage ? PaymentMethods.BankTransfer : ''
        }`,
      )

      if (responsePay?.event) {
        sendToFlutter(
          JSON.stringify({
            event: responsePay.event,
            productId: responsePay.productId,
          }),
        )
        return
      }

      const responsePack = await this.$axios.get<{}, PremiumPackApiResponse>(
        META_PREMIUM_PACK_ENDPOINT + this.selectedPackId,
      )

      this.showPaymentMessage({
        pack: this.selectedPackId,
        rewards: responsePack.rewards,
        failed: false,
        af_quantity: responsePack.firebaseData.af_quantity,
        af_store_id: responsePack.firebaseData.af_store_id,
        gtm_currency: responsePack.firebaseData.gtm_currency,
        gtm_revenue: responsePack.firebaseData.gtm_revenue,
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.piggy-bank {
  &-view {
    background: url($path-premium + 'piggy-bank/bg-piggy-bank.avif') center bottom no-repeat;
    background-size: auto 100%;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    width: 98%;
    height: 100%;
    margin: 0 auto;
    padding-top: 9rem;

    .piggy-bank-header {
      padding-right: 1rem;

      .piggy-bank-logo {
        width: 47.875rem;
        height: 5.5rem;
        background: url($path-premium + 'piggy-bank/header-logo-piggy-bank.avif') center bottom
          no-repeat;
        background-size: contain;
      }
    }

    .piggy-bank-subheader {
      margin-top: 1rem;
      padding-left: 1rem;

      :deep(.info-text p) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .piggy-bank-body {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex: 1 1 auto;
      gap: 3.25rem;
      margin-top: 3.25rem;
    }
  }
}
</style>
