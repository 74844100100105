<template>
  <div
    v-for="pack in smallPack"
    :key="pack.id"
    class="small-card text-texts-standard-default font-bold relative cursor-pointer clickable-element"
    :class="[
      `card-background-${pack.parameters.type}-${pack.parameters.order}`,
      { 'pointer-events-none': pack.time_remaining > 0 },
    ]"
    @click="buyPack(pack.id, pack.parameters.price_gems)"
  >
    <p
      v-show="Number(pack.parameters.order) === 1"
      class="text-28 uppercase font-bold small-card-claim"
      :class="{ 'text-texts-standard-upgrade': pack.time_remaining > 0 }"
    >
      {{
        pack.time_remaining == null || pack.time_remaining < 0
          ? $t('common.claim')
          : $t('common.claimed')
      }}
    </p>
    <div :class="'absolute small-card-img-' + pack.parameters.type" />
    <section
      :class="
        'small-card-amount relative text-texts-standard-important text-60 flexing bg-' +
        pack.parameters.type
      "
    >
      <p>{{ `${pack.parameters.amount}x` }}</p>
    </section>
    <app-price
      class="price"
      :decoration-color="getDecorationColor(pack.parameters.type, pack.parameters.order)"
      decoration-height="2.75rem"
    >
      <p
        v-if="pack.time_remaining == null || pack.time_remaining < 0"
        class="text-50 uppercase flexing"
      >
        {{
          Number(pack.parameters.price_gems) != 0
            ? $filters.$formatNumber(pack.parameters.price_gems)
            : 'free'
        }}
        <app-main-icon
          v-show="Number(pack.parameters.price_gems) > 0"
          class="icon-margin price-icon"
          :icon-name="'gems'"
          :icon-size="56"
        />
      </p>
      <app-timer v-else :icon="'refresh'" class="text-50 timer" :time="pack.time_remaining" />
    </app-price>
    <div
      v-show="pack.time_remaining != null && pack.time_remaining > 0"
      class="bought-wrapper absolute w-full h-full"
    />
  </div>
</template>

<script lang="ts">
import AppPrice from '@/components/GlobalComponents/AppPrice.vue'
import { formatTime } from '@/helpers'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { PremiumPack } from '@/interfaces/premium/PremiumPack'

export default defineComponent({
  components: {
    AppPrice,
  },
  props: {
    smallPack: {
      type: Array as PropType<PremiumPack[]>,
      default: (): PremiumPack[] => [],
    },
  },
  emits: ['pack', 'popup', 'buyPack', 'loadData'],
  created(): void {
    this.smallPack.map((item: PremiumPack): number => {
      if (item?.time_remaining) return this.countdownTimer(item.time_remaining)
      return null
    })
  },
  methods: {
    formatTime,
    buyPack(id: number, amount: string): void {
      this.$emit('pack', id, amount)

      if (amount !== '0') this.$emit('popup')
      else this.$emit('buyPack')
    },
    countdownTimer(time: number): number | undefined {
      const timer = setInterval((): void => {
        if (time > 0) {
          time--
        } else {
          clearInterval(timer)
          this.$emit('loadData')
        }
      }, 1000)

      return timer
    },
    getDecorationColor(type: string, order: string): string {
      switch (type + '-' + order) {
        case 'energy_drink-2':
          return '#d684ff'
        case 'wild_card-2':
          return '#c99dff'
        default:
          return ''
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.small-card {
  width: 35.625rem;
  height: 19.1875rem;

  &:first-child {
    margin-bottom: 0;
  }

  &-img-wild_card,
  &-img-energy_drink {
    width: 11.75rem;
    height: 12.4375rem;
    right: 2rem;
    top: 2.125rem;
  }

  &-img-wild_card {
    background: url($path-premium + 'WildEnergy/SMALL_BOX_WILD_CARDS.avif') center no-repeat;
    background-size: contain;
  }

  &-img-energy_drink {
    background: url($path-premium + 'WildEnergy/SMALL_BOX_ENERGY.avif') center no-repeat;
    background-size: contain;
  }

  &-claim {
    position: absolute;
    width: 25rem;
    top: 5rem;
    z-index: 2;
  }

  &-amount {
    width: 25rem;
    height: 4.375rem;
    top: 7.5rem;

    &:before {
      content: '';
      position: absolute;
      width: 80%;
      height: 0.125rem;
      background: linear-gradient(to right, transparent, #bc91cf, transparent);
      top: 0;
    }
  }

  .bg-energy_drink {
    @if $isSsm {
      background: linear-gradient(to right, transparent, rgba(149, 25, 48, 1), transparent);
    }

    @if $isWsm {
      background: linear-gradient(to right, transparent, #cd0136bd, transparent);
    }
  }

  .bg-wild_card {
    @if $isSsm {
      background: linear-gradient(to right, transparent, rgba(132, 68, 160, 1), transparent);
    }

    @if $isWsm {
      background: linear-gradient(to right, transparent, rgba(132, 68, 160, 0.8), transparent);
    }
  }

  .price {
    z-index: 2;
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translate(-50%);

    .timer {
      min-width: 7.063rem;
      background: none;
      padding-left: 0;

      &:before {
        background: none;
      }
    }
  }

  .icon-margin.price-icon {
    margin-right: 0;
  }

  .bought-wrapper {
    top: 0;
    background: rgba(0, 0, 0, 0.3);
  }
}

@for $i from 1 through 2 {
  .card-background-wild_card-#{$i} {
    background: url($path-premium + 'WildEnergy/smallBox-wildCard-' + #{$i} + '.avif')
      center
      no-repeat;
    background-size: contain;
  }

  .card-background-energy_drink-#{$i} {
    background: url($path-premium + 'WildEnergy/smallBox-energyDrink-' + #{$i} + '.avif')
      center
      no-repeat;
    background-size: contain;
  }
}
</style>
