<template>
  <div class="board-header relative flex items-center justify-between px-8 pt-2">
    <event-pass-button :pass="PassType.Event" :show-active-pass-bg="true" />

    <div class="flexing space-x-4">
      <app-button
        v-for="(button, i) in buttons"
        :key="i"
        :btn-type="button === activeButton ? 'secondary' : 'inactive'"
        :btn-text="$te(button === BoardMainComponent.BountyBoard ? 'noticeBoard' : 'autumnLeague')"
        btn-size="md"
        :notifications-count="notificationsCount[button]"
        :force-notification="!!notificationsCount[button]"
        @click="setBoardSection(button)"
      />
    </div>

    <app-button
      btn-type="primary"
      :btn-text="$te('eventInformation')"
      btn-size="md"
      @click="$emit('showPopup')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { PassType } from '@/interfaces/events/Passes'
import EventPassButton from '@/components/Events/GlobalEventsComponents/EventPassButton.vue'
import { BoardMainComponent } from '@/interfaces/events/Board'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'

interface ComponentData {
  PassType: typeof PassType
  BoardMainComponent: typeof BoardMainComponent
  activeButton: string
  buttons: string[]
}

export default defineComponent({
  name: 'BoardHeader',
  components: {
    EventPassButton,
  },
  emits: ['setSection', 'showPopup'],
  data(): ComponentData {
    return {
      PassType,
      BoardMainComponent,
      activeButton: BoardMainComponent.BountyBoard,
      buttons: [BoardMainComponent.BountyBoard, BoardMainComponent.BountyLeague],
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
    ...mapState(useResponseTaskStore, {
      notifications: 'getNotifications',
    }),
    notificationsCount(): Record<BoardMainComponent, number> {
      return {
        [BoardMainComponent.BountyBoard]: 0,
        [BoardMainComponent.BountyLeague]:
          this.notifications?.events?.[this.namespace]?.unclaimed_competition_milestones ?? 0,
      }
    },
  },
  methods: {
    setBoardSection(component: string): void {
      this.activeButton = component
      this.$emit('setSection', { component })
    },
  },
})
</script>

<style lang="scss" scoped>
.board-header {
  width: 100%;
  margin: 0 auto;
  height: 7.625rem;

  .active-pass-bg {
    width: 30%;
  }
}
</style>
