import { useAdStore } from '@/store/pinia/adStore'
interface AdData {
  id: string
  onRequest?: () => void
  onSuccess?: () => void
  onError?: () => void
  onCallbackComplete: () => void
}
export const requestWebAd = async ({
  id,
  onRequest,
  onSuccess,
  onError,
  onCallbackComplete,
}: AdData): Promise<void> => {
  const adStore = useAdStore()
  // eslint-disable-next-line no-undef
  if (AyetVideoSdk.isAdAvailable()) {
    adStore.setAdBlockState(false)
    if (onSuccess) onSuccess()
    // eslint-disable-next-line no-undef
    AyetVideoSdk.playInPageAd(id)
  } else {
    // eslint-disable-next-line no-undef
    await AyetVideoSdk.requestAd(
      import.meta.env.VITE_WEB_AD_PROVIDER_AD_SLOT_NAME,
      (): void => {
        adStore.setAdBlockState(false)

        if (onRequest) onRequest()
        // eslint-disable-next-line no-undef
        AyetVideoSdk.playInPageAd(id)
      },
      (msg: string) => {
        if (msg === 'no fill.') {
          // eslint-disable-next-line no-undef
          AyetVideoSdk.destroy()
        } else {
          adStore.setAdBlockState(true)
        }
        if (onError) onError(msg)
        console.error('requestAd failed: ' + msg)
      },
    )
  }

  // eslint-disable-next-line no-undef
  AyetVideoSdk.callbackComplete = (): void => {
    onCallbackComplete()
  }

  // eslint-disable-next-line no-undef
  AyetVideoSdk.callbackError = (): void => {
    onError()
  }
}
