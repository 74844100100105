<template>
  <div class="arena-about-popup flexing">
    <popup-about-type-one v-if="arenaData" about-id="arena-about-popup" :popup-data="arenaData" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { STARTS_LIVE_COST_LEVELS } from '@/globalVariables'
import PopupAboutTypeOne from '@/components/Popup/PopupAboutTypeOne.vue'

interface ArenaAboutData {
  img: string
  title: string
  text: string
  list?: string[]
  listWidth?: string
}

interface ComponentData {
  arenaData: ArenaAboutData[] | null
}

export default defineComponent({
  name: 'ArenaAbout',
  components: {
    PopupAboutTypeOne,
  },
  data(): ComponentData {
    return {
      arenaData: null,
    }
  },
  created(): void {
    this.arenaData = [
      {
        img: 'components/aboutPopup/arena/icon-cup.webp',
        title: this.$t('arena.competitions'),
        text: this.$replacePlaceholder(this.$t('arena.competitionsAbout'), '%s', '30'),
      },
      {
        img: 'components/aboutPopup/arena/icon-live.webp',
        title: this.$t('arena.livePlaying'),
        text: this.$replacePlaceholder(this.$t('arena.livePlayingAbout'), '%s', '30'),
        list: STARTS_LIVE_COST_LEVELS.map((item) => {
          return (
            '<div class="w-1/2">' +
            this.$replacePlaceholder(this.$t('arena.livePlayingCostLevel'), '%s', item.level) +
            '</div><div class="w-1/2">' +
            this.$replacePlaceholder(this.$t('arena.livePlayingCostStarts'), '{2to4}', item.cost) +
            '</div>'
          )
        }),
        listWidth: '28rem',
      },
      {
        img: 'components/aboutPopup/arena/icon-super-competition.webp',
        title: this.$t('arena.supermatch'),
        text: this.$replacePlaceholder(this.$t('arena.supermatchAbout'), '{number}', '5'),
      },
      {
        img: 'components/aboutPopup/arena/icon-arena.webp',
        title: this.$t('common.building'),
        text: this.$t('arena.buildingAbout'),
      },
      {
        img: 'components/aboutPopup/arena/icon-season-badge.webp',
        title: this.$t('arena.seasonalReward'),
        text: this.$t('arena.seasonalRewardAbout'),
      },
    ]
  },
})
</script>
