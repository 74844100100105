<template>
  <menu-component
    menu-type="ranking-global"
    :show-homepage-scene="true"
    :title="$t('rankingsGroup.rankings')"
    :menu-items="{
      1: {
        text: $t('rankingsGroup.overall'),
        route: $getWebView('RankingGlobal'),
        current: current === 1 && (customCurrentRoute ?? $getWebView('RankingGlobalClubs')),
      },
      2: {
        text: $t('rankingsGroup.disciplines'),
        route: $getWebView('RankingDisciplines'),
        current: current === 2 && $getWebView('RankingDisciplinesCountry'),
      },
      3: {
        text: $t('rankingsGroup.records'),
        route: $getWebView('RankingRecords'),
        current: current === 3 && $getWebView('RankingRecordsCountry'),
      },
    }"
    :sub-menu-active="true"
    :sub-menu="showSubMenu"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { MenuObject } from '@/interfaces/MenuObject'

export default defineComponent({
  name: 'RankingMenu',
  props: {
    current: {
      type: Number,
      default: null,
    },
    customCurrentRoute: {
      type: String,
      default: null,
    },
  },
  computed: {
    showSubMenu(): Record<number, MenuObject> {
      if (this.current === 1) {
        return {
          1: {
            text: this.$t('rankingsGroup.world'),
            route: this.$getWebView('RankingGlobal'),
          },
          2: {
            text: this.$t('rankingsGroup.country'),
            route: this.$getWebView('RankingGlobalCountry'),
          },
          3: {
            text: this.$t('rankingsGroup.clubs'),
            route: this.$getWebView('RankingGlobalClubs'),
          },
        }
      } else if (this.current === 2) {
        return {
          1: {
            text: this.$t('rankingsGroup.world'),
            route: this.$getWebView('RankingDisciplines'),
          },
          2: {
            text: this.$t('rankingsGroup.country'),
            route: this.$getWebView('RankingDisciplinesCountry'),
          },
        }
      } else if (this.current === 3) {
        return {
          1: {
            text: this.$t('rankingsGroup.world'),
            route: this.$getWebView('RankingRecords'),
          },
          2: {
            text: this.$t('rankingsGroup.country'),
            route: this.$getWebView('RankingRecordsCountry'),
          },
        }
      }

      return {}
    },
  },
})
</script>
