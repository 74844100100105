import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const clubCarsRightTopToLeftTopPath: PathObjectInterface = {
  mainPath: { x: 3456, y: 389 },
  spline: [
    3456, 389, 3410, 395, 3357, 408, 3301, 433, 3240, 447, 3196, 452, 3153, 452, 3110, 457, 3049,
    449, 3011, 442, 2943, 433, 2892, 429, 2840, 423, 2791, 422, 2751, 422, 2701, 428, 2644, 432,
    2616, 432, 2569, 429, 2518, 423, 2452, 408, 2323, 374, 2222, 345, 2180, 332, 2128, 318, 2099,
    314, 2030, 304, 1968, 299, 1885, 290, 1812, 281, 1765, 272, 1713, 253, 1685, 236, 1672, 209,
    1654, 166, 1648, 123,
  ],
}
