import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const longDistanceBalloonPath: PathObjectInterface = {
  mainPath: {
    x: -100,
    y: 1420,
  },
  nextPaths: [
    {
      x: 2850,
      y: 8,
    },
  ],
}
