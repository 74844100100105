<template>
  <button class="multiplier-button" @click="$emit('click')">
    <span class="rectangle" />
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MultiplierButton',
  emits: ['click'],
})
</script>

<style lang="scss" scoped>
.multiplier-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url($path-events + 'luckyWheel/lucky-wheel-button.png') center no-repeat;
  background-size: contain;
  border: none;
  width: 10.5rem;
  height: 10.5rem;
  color: white;
  padding-top: 1rem;
  border-radius: 50%;
  font-size: 4.375rem;
  font-weight: bold;
  cursor: pointer;
  position: relative;

  @if $isWsm {
    font-style: italic;
  }

  .rectangle {
    position: absolute;
    width: 2.125rem;
    height: 1.375rem;
    top: 2rem;
    background: url($path-events + 'luckyWheel/lucky-wheel-arrow.png') center no-repeat;
    background-size: contain;
  }
}
</style>
