<template>
  <div class="header-wrapper">
    <div
      class="modal-title flex items-center justify-left absolute m-4 text-texts-standard-default"
    >
      <div class="left-strip absolute left-0 h-full" />
      <div v-if="$isWsm" class="middle-strip absolute left-0 h-full" />
      <span class="modal-title-text">{{ title }}</span>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppModalHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.header-wrapper {
  background: #0a1c35;
  box-shadow: 0 0.05rem 0.25rem #1b1b1b;
  height: 7rem;

  .modal-title {
    left: 4rem;
    height: 5rem;
    min-width: 23rem;
    @if $isWsm {
      background: linear-gradient(
        90deg,
        rgba(21, 77, 119, 1) 0%,
        rgba(13, 39, 74, 1) 42%,
        rgba(10, 28, 53, 0.5) 100%
      );
    }
    transform: $skewX-value;
    font-size: 3.25rem;
    text-transform: uppercase;
    padding-left: 1.25rem;

    .left-strip {
      width: 0.438rem;
      background: #f4d91e;
      margin-left: -2.375rem;
    }

    .middle-strip {
      width: 1.5rem;
      background: #154d77;
      margin-left: -1.688rem;
    }
  }
}
</style>
