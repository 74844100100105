import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const landingHelicopterCheckpoints: CheckpointsObjectInterface = {
  key: 'landingHelicopter',
  data: [
    {
      x: 1878,
      y: 264,
      width: 4,
      height: 4,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.PauseAction,
        action: {
          duration: 5000,
        },
      },
    },
    {
      x: 1873,
      y: 150,
      width: 2,
      height: 2,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'landing_helicopter_ascending',
        },
      },
    },
    {
      x: 1800,
      y: 200,
      width: 8,
      height: 8,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'landing_helicopter_descending',
        },
      },
    },
  ],
}
