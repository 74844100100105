<template>
  <section class="chains-stages flexing flex-grow w-full space-x-12" :data-event-theme="namespace">
    <div
      v-for="(chain, index) in chains"
      :key="chain.chain_id"
      class="flex flex-col justify-between h-full relative"
      :class="'chain-' + (index + 1)"
      @click="setChain({ chainId: chain.chain_id })"
    >
      <div
        class="chain-text flexing mt-4 z-10"
        :class="{ done: chain.selections && !Object.keys(chain.selections).length }"
        :title="$te(`chain${index + 1}Tooltip`)"
      >
        <div class="relative bottom-1" :class="'chain-text-' + (index + 1)" />
      </div>
      <div
        class="chain-tier flexing z-10"
        :class="{
          done: chain.selections && !Object.keys(chain.selections).length,
          'done-left': chain.selections && !chain.selections['1'],
          'done-right': chain.selections && !chain.selections['2'],
          'mb-8': $isWsm,
          'mb-10': $isSsm,
        }"
      >
        <div class="team-1 flexing relative left-1.5 z-10">
          <app-main-icon :icon-size="120" :icon-name="ENERGY" />
          <app-icon
            v-if="chain.selections && !chain.selections['1']"
            class="absolute -bottom-2 -right-2"
            icon-name="done-md"
          />
        </div>
        <div class="w-full relative bottom-2 -mx-4" :class="'chain-tier-' + chain.difficulty" />
        <div class="team-2 flexing relative right-1.5 z-10">
          <app-main-icon :icon-size="120" :icon-name="STARTS" />
          <app-icon
            v-if="chain.selections && !chain.selections['2']"
            class="absolute -bottom-2 -right-2"
            icon-name="done-md"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ENERGY, STARTS } from '@/globalVariables'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useTaskChainStore } from '@/store/pinia/events/taskChainStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  ENERGY: typeof ENERGY
  STARTS: typeof STARTS
}

export default defineComponent({
  name: 'ChainsStages',
  data(): ComponentData {
    return { ENERGY, STARTS }
  },
  computed: {
    ...mapState(useTaskChainStore, {
      chains: 'getChains',
    }),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
  },
  methods: {
    ...mapActions(useTaskChainStore, ['setChain']),
  },
})
</script>

<style lang="scss" scoped>
$stages: (1, 2, 3);
$tiers: (1, 2, 3);

[data-event-theme='frozen'] {
  --events-chains-stage-bg: url('#{$path-events}frozen-championship/taskchain/chains/stage-image-bg.avif');
  --events-chains-stage-bg-hover: url('#{$path-events}frozen-championship/taskchain/chains/stage-image-bg-hover.avif');
  @each $stage in $stages {
    --events-chains-stage-image-#{$stage}: url('#{$path-events}frozen-championship/taskchain/chains/stage-image-#{$stage}.avif');
    --events-chains-text-title-#{$stage}-bg: url('#{$path-events}frozen-championship/taskchain/chains/text-#{$stage}.avif');
  }
  --events-chains-text-title-bg: url('#{$path-events}frozen-championship/taskchain/chains/text-bg.avif');
  --events-chains-text-title-done-bg: url('#{$path-events}frozen-championship/taskchain/chains/text-done-bg.avif');
  --events-chains-tier-bg: url('#{$path-events}frozen-championship/taskchain/chains/tier-bg.avif');
  --events-chains-tier-done-bg: url('#{$path-events}frozen-championship/taskchain/chains/tier-done-bg.avif');
  --events-chains-circle: url('#{$path-events}frozen-championship/taskchain/chains/circle.avif');
  --events-chains-circle-done: url('#{$path-events}frozen-championship/taskchain/chains/circle-done.avif');
  @each $tier in $tiers {
    --events-chains-text-tier-#{$tier}: url('#{$path-events}frozen-championship/taskchain/chains/tier-#{$tier}.avif');
  }
}

.chains-stages {
  @each $stage in $stages {
    .chain-#{$stage} {
      width: 36.25rem;
      height: 42rem;
      @include background(var(--events-chains-stage-bg), contain, center 1rem);

      &:hover {
        cursor: pointer;
      }

      &:hover::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include background(null, contain, center 1rem);
      }

      &:hover::before {
        background-image: var(--events-chains-stage-bg-hover);
      }

      &::after {
        background-image: var(--events-chains-stage-image-#{$stage});
      }
    }

    .chain-text {
      width: 37.563rem;
      height: 6.188rem;
      @include background(var(--events-chains-text-title-bg), contain);

      &.done {
        background-image: var(--events-chains-text-title-done-bg);
      }

      .chain-text-#{$stage} {
        width: 100%;
        height: 4.875rem;
        @include background(var(--events-chains-text-title-#{$stage}-bg), contain);
      }
    }
  }

  .chain-tier {
    width: 100%;
    height: 7.25rem;
    @include background(var(--events-chains-tier-bg), contain);

    &::before,
    &::after {
      position: absolute;
      bottom: 1.25rem;
      width: 9.375rem;
      height: 9.125rem;
      @include background(null, contain);
    }

    &::before {
      content: '';
      left: 0;
      background-image: var(--events-chains-circle);
    }

    &::after {
      content: '';
      right: 0;
      background-image: var(--events-chains-circle);
    }

    &.done {
      background-image: var(--events-chains-tier-done-bg);

      &-left::before {
        background-image: var(--events-chains-circle-done);
      }

      &-right::after {
        background-image: var(--events-chains-circle-done);
      }
    }

    @each $tier in $tiers {
      .chain-tier-#{$tier} {
        height: 11.875rem;
        @include background(var(--events-chains-text-tier-#{$tier}), contain);
      }
    }
  }
}
</style>
