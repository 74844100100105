import { ObjectsHandler, cameraHandler } from '@/map-phaser-new/utils'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import Phaser from 'phaser'

import { mapImageConfig as WsmMainMapSceneMapImagesConfigExporter } from '../../config/winter-sports/scenes/MainMapScene'
import { mapImageConfig as SsmMainMapSceneMapImagesConfigExporter } from '../../config/summer-sports/scenes/MainMapScene'

import { mapImageConfig as WsmClubsMapSceneMapImagesConfigExporter } from '../../config/winter-sports/scenes/ClubsMapScene'
import { mapImageConfig as SsmClubsMapSceneMapImagesConfigExporter } from '../../config/summer-sports/scenes/ClubsMapScene'
import { GAMES_KEYS } from '@/map-phaser-new/constants'
import type { MapImageConfigInterface } from '@/map-phaser-new/interfaces'
import { currentGame } from '@/globalVariables'

const exporters = {
  [GAMES_KEYS.WinterSports]: {
    MainMapScene: WsmMainMapSceneMapImagesConfigExporter,
    ClubsMapScene: WsmClubsMapSceneMapImagesConfigExporter,
  },
  [GAMES_KEYS.SummerSports]: {
    MainMapScene: SsmMainMapSceneMapImagesConfigExporter,
    ClubsMapScene: SsmClubsMapSceneMapImagesConfigExporter,
  },
}

export default class CustomCoreMapScene extends Phaser.Scene {
  constructor(private sceneKey: string) {
    super(sceneKey)
  }

  init(): void {
    this.showLoadingScreen()
  }

  private showLoadingScreen(): void {
    const tutorialStore = useTutorialStore()
    if (tutorialStore.getIsTutorial) return

    const useCore = useCoreStore()
    useCore.setLoadingScreen(true)
  }

  protected async setUpCommonSceneFunctionality(): Promise<void> {
    this.setUpMapImageWithCamera()

    const objectHandler = new ObjectsHandler(currentGame, this)
    await objectHandler.createSceneObjects()
  }

  private setUpMapImageWithCamera(): void {
    const mapImageConfig: MapImageConfigInterface = exporters[currentGame][this.sceneKey]

    const image = this.add.image(0, 0, mapImageConfig.textureKey).setOrigin(0, 0)
    image.setInteractive()

    for (const mapOverlayConfig of mapImageConfig.mapOverlaysConfig) {
      this.add
        .image(0, 0, mapOverlayConfig.textureKey)
        .setOrigin(0, 0)
        .setDepth(mapOverlayConfig.depth)
    }

    cameraHandler.setUpCamera(image, this)
  }
}
