import type { ImageLoadData } from '@/map-phaser-new/interfaces'
import { clubsMapImageLoadData } from './clubsMapImageLoadData'

/**
 * This function is used to collect all imports of clubs images, to make loading at BootScene easier
 * @returns Promise<ImageLoadData[]>
 */
export const exportedClubsImages: () => Promise<ImageLoadData[]> = async () => [
  ...clubsMapImageLoadData(),
]
