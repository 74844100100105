import { clubBuildingsEmployeesEndpoint, clubEmployeesHireEndpoint } from '@/globalVariables'
import { getIconName } from '@/helpers'
import { internalAxios } from '@/plugins/vueAxios'
import { useUserStore } from '@/store/pinia/userStore'
import { defineStore } from 'pinia'
import type EmployeesResponse from '@/interfaces/responses/club/EmployeesResponse'
import type {
  ClubEmployeesHireApiResponse,
  EmployeeData,
} from '@/interfaces/responses/club/EmployeesResponse'
import type {
  Employee,
  EmployeesSlot,
  SlotInterface,
  EmployeesApiBodyInterface,
  loadEmployees,
  EmployeeHire,
  EmployeeHireApiBodyInterface,
} from '@/interfaces/clubs/Employees'
import type { Nullable } from '@/interfaces/utils'
import type { EmployeesFilter } from '@/interfaces/clubs/Employees'
interface EmployeesState {
  employeesFilter: EmployeesFilter
  allEmployeesLoaded: boolean
  employees: Employee[]
  employeesSlots: Nullable<EmployeesSlot[]>
  signedContract: Nullable<Employee>
}

export const useEmployeeState = defineStore('employeeStore', {
  state: (): EmployeesState => ({
    employeesFilter: {
      filter_type: 'default',
      filter_hired: 'default',
    },
    allEmployeesLoaded: false,
    employees: [],
    employeesSlots: [],
    signedContract: null,
  }),
  getters: {
    getEmployeesFilter(): EmployeesState['employeesFilter'] {
      return this.employeesFilter
    },
    getEmployees(): EmployeesState['employees'] {
      return this.employees
    },
    getEmployeesSlots(): EmployeesState['employeesSlots'] {
      return this.employeesSlots
    },
    getEmployeesSlot() {
      return (buildingTypeId: number): EmployeesSlot => this.employeesSlots[buildingTypeId]
    },
    getSignedContract(): EmployeesState['signedContract'] {
      return this.signedContract
    },
  },
  actions: {
    setEmployee(data: Employee[]): void {
      this.employees = data
    },
    setEmployeeSlots(data: EmployeesSlot[]): void {
      this.employeesSlots = data
    },
    setEmployeesFilter(data: EmployeesState['employeesFilter']): void {
      const filterData = { ...this.employeesFilter }
      if (['club', 'personal', 'default'].includes(data.filter_type)) {
        filterData.filter_type = data.filter_type
      }
      if (['active', 'inactive', 'default'].includes(data.filter_hired)) {
        filterData.filter_hired = data.filter_hired
      }
      this.employeesFilter = filterData
    },
    hideHiredEmployee(): void {
      this.signedContract = null
    },
    async hireEmployee({ employee, reloadAll = false }: EmployeeHire): Promise<void> {
      try {
        await internalAxios.post<{}, ClubEmployeesHireApiResponse>(clubEmployeesHireEndpoint, {
          employee_id: employee.employeeId,
        } as EmployeeHireApiBodyInterface)
      } catch (error: unknown) {
        console.error(error)
      }
      this.allEmployeesLoaded = false
      this.signedContract = employee
      this.loadEmployees({
        buildingTypeId: reloadAll ? 0 : employee.buildingTypeId,
        refresh: false,
      })
    },
    async loadEmployees({ buildingTypeId = 0, refresh = false }: loadEmployees): Promise<void> {
      if (refresh) this.allEmployeesLoaded = false
      const body = {} as EmployeesApiBodyInterface
      if (!buildingTypeId && this.allEmployeesLoaded) return
      if (buildingTypeId) {
        body.building_type_id = buildingTypeId
        // todo check if loaded
      }

      const response = await internalAxios.post<{}, EmployeesResponse[]>(
        clubBuildingsEmployeesEndpoint,
        body,
      )

      const slots = []
      const employees = response.reduce((output, building) => {
        building.building_type.employees.forEach((employee: EmployeeData): void => {
          const isPersonal = employee.parameters.employee_type === 'personal'
          const isClub = employee.parameters.employee_type === 'club'
          const data = {
            clubBuildingId: building.clubs_building_id,
            isHired: employee.is_hired ?? false,
            buildingTypeId: building.building_type.building_type_id,
            boxColor: employeeColor(employee), // box color: 'blue', 'gold', 'purple', 'green'
            warning: employee.is_hired, // red border around box
            rewardsToClaim: (employee.contract?.next_claim_at ?? 0) < 0 ? 1 : 0, // red notification circle with number in top right corner of the box
            canHire: false, // can I hire this employee?
            unlockLevel: employee.locked ? employee.parameters.level_unlock : 0, // locked box, level needed to unlock
            locked: employee.locked,
            building: 'club.' + building.building_type.parameters.name,
            tier: employee.parameters.tier, // employee tier
            employeeName: employee.parameters.name,
            employeeId: employee.employee_id,
            contractSeconds: employee.contract?.contract_ends_on ?? 0, // seconds to count down in a timer
            rewardSeconds: employee.contract?.next_claim_at,
            countdown: true, // contract header timer
            rewardValue: Number(employee.reward.value), // employee reward amount
            rewardType: getIconName(employee.reward), // employee reward type
            rewardTime: employee.parameters.frequency_hours * 60 * 60,
            detailBtn: false, // show detail button in bottom part
            validForDays: employee.parameters.contract_days, // for how long is the employee available
            hiringPrice: employee.parameters.price, // amount needed to hire this employee
            hiringCurrency: employee.parameters.currency, // type of resource needed to hire this employee
            isPersonal, // is this personal or club employee?
            employeeType: employee.parameters.employee_type,
            isDisabled:
              (!building.can_hire_club && isClub) || (!building.can_hire_personal && isPersonal),
          }
          slots[data.buildingTypeId] = getSlotData(data)
          output.push(data)
        })
        return output
      }, [])
      this.employees = employees
      this.employeesSlots = slots
      //  this.allEmployeesLoaded = buildingTypeId  this is for caching

      function getSlotData(employee: Employee): EmployeesSlot {
        const slotData = {
          club: undefined,
          personal: undefined,
        }
        if (slots[employee.buildingTypeId]) {
          slotData.club = slots[employee.buildingTypeId].club.isHired
            ? slots[employee.buildingTypeId].club
            : !employee.isPersonal && employee.isHired
              ? employee
              : undefined
          slotData.personal = slots[employee.buildingTypeId].personal.isHired
            ? slots[employee.buildingTypeId].personal
            : employee.isPersonal && employee.isHired
              ? employee
              : undefined
        } else {
          slotData[employee.employeeType] = employee.isHired ? employee : undefined
        }
        if (slotData.club === undefined) slotData.club = emptyBox(employee, true)
        if (slotData.personal === undefined) slotData.personal = emptyBox(employee, false)

        return slotData
      }

      function emptyBox(employee: Employee, isClubSlot: boolean): SlotInterface {
        const useUser = useUserStore()
        const management = useUser.isClubManagerOrAssistant
        return {
          boxColor: isClubSlot ? 'gold' : 'blue',
          building: employee.building,
          boxEmpty: true,
          canHire: !isClubSlot ? true : !!management,
          employeeType: isClubSlot ? 'club' : 'personal',
        }
      }

      function employeeColor(employee: EmployeeData): string {
        // available types:  gems, club, personal
        let type = employee.parameters.employee_type
        type = employee.parameters.currency === 'gems' ? 'gems' : type
        const MAPPING = {
          hired: {
            gems: 'green',
            personal: 'green',
            club: 'gold',
          },
          notHired: {
            gems: 'purple',
            personal: 'blue',
            club: 'gold',
          },
        }

        return employee.is_hired ? MAPPING.hired[type] : MAPPING.notHired[type]
      }
    },
  },
})
