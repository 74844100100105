<template>
  <div class="profile-tooltip-content">
    <div class="profile-tooltip-content-head underlined-box flexing">
      <p class="text-texts-standard-default text-36 font-bold">
        {{ title }}
      </p>
    </div>
    <div v-if="discId || totalValue" class="profile-tooltip-content-discipline flexing">
      <div class="flexing" :class="{ 'discipline-gradient': discId }">
        <div v-if="discId" class="icon-wrapper">
          <div :class="'icon-discipline-' + discId + '-light-70'" />
        </div>
        <p v-if="totalValue" class="text-texts-standard-important font-bold text-50">
          {{ totalValue }}
        </p>
      </div>
    </div>
    <div
      v-for="(row, index) in rows"
      :key="index"
      class="profile-tooltip-content-row underlined-box"
      :class="{ 'with-plus': withPlus }"
    >
      <p
        class="text-30 text-left"
        :class="row.highlight ? 'text-texts-standard-important' : 'text-texts-standard-default'"
      >
        {{ row.text }}
      </p>
      <p
        class="text-30 how-width"
        :class="row.highlight ? 'text-texts-standard-important' : 'text-texts-standard-default'"
      >
        {{ row.value !== 0 ? row.value : '-' }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface ProfileTooltipRow {
  text: string
  value: number
  highlight?: boolean
}

export default defineComponent({
  name: 'ProfileTooltip',
  props: {
    rows: {
      type: Array as PropType<ProfileTooltipRow[]>,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    discId: {
      type: Number,
      default: null,
    },
    totalValue: {
      type: Number,
      default: 0,
    },
    withPlus: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';

.profile-tooltip-content {
  width: 23.75rem;

  .underlined-box {
    position: relative;
    &:not(.with-plus)::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0rem;
      width: 100%;
      height: 0.063rem;
      @if $isWsm {
        background: linear-gradient(to right, #a4a4a400, #a4a4a4, #a4a4a4, #a4a4a400);
      }
      @if $isSsm {
        background: linear-gradient(
          to right,
          rgba(190, 81, 154, 0.2),
          rgba(253, 150, 205, 0.8),
          rgba(253, 150, 205, 1),
          rgba(253, 150, 205, 0.8),
          rgba(191, 82, 155, 0.2)
        );
      }
    }

    &.with-plus {
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0rem;
        width: 95%;
        height: 0.063rem;
        @if $isWsm {
          background: linear-gradient(to right, #a4a4a400, #a4a4a4, #a4a4a4, #a4a4a400);
        }
        @if $isSsm {
          background: linear-gradient(
            to right,
            rgba(190, 81, 154, 0.2),
            rgba(253, 150, 205, 0.8),
            rgba(253, 150, 205, 1),
            rgba(253, 150, 205, 0.8),
            rgba(191, 82, 155, 0.2)
          );
        }
      }
      &:before {
        content: '+';
        position: absolute;
        right: 1rem;
        bottom: 0.75rem;
        width: 1rem;
        height: 1rem;
        font-size: 2.4rem;
        font-weight: bold;
        color: theme('colors.texts.standard.additional');
        text-shadow: 0 0 0.313rem rgba(160, 220, 255, 0.77);
      }

      &:after {
        bottom: -0.063rem;
      }
      &:last-child {
        &::before,
        &::after {
          content: unset;
        }
      }
    }
  }

  &-head {
    height: 4rem;
    @if $isWsm {
      background: linear-gradient(to left, rgba(15, 40, 57, 0), #1e6c9b, rgba(15, 40, 57, 0));
    }
    @if $isSsm {
      background: linear-gradient(
        to right,
        rgba(179, 82, 162, 0.01),
        rgba(179, 82, 162, 0.7),
        #a83c75,
        #a83c75,
        rgba(179, 82, 162, 0.7),
        rgba(179, 82, 162, 0.01)
      );
    }
  }

  &-discipline {
    @if $isWsm {
      width: 12.813rem;
    }
    @if $isSsm {
      width: 90%;
    }
    height: 4.313rem;
    margin: 1rem auto;
    display: flex;

    .discipline-gradient {
      position: relative;
      width: 100%;
      @if $isSsm {
        background: linear-gradient(to right, transparent, #c93d90, transparent);
      }
      &:before,
      &:after {
        @if $isWsm {
          content: '';
          background: #377fb8ba;
          position: absolute;
          top: 2rem;
          width: 50%;
          left: 50%;
          transform: translate(-50%);
          height: 0rem;
          border-radius: 50%;
          box-shadow: 0 0 2.188rem 1.563rem #377fb8;
        }
      }

      @if $isSsm {
        &::before,
        &::after {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          height: 0.063rem;
          background: linear-gradient(to right, transparent, #d9ecff, transparent);
        }
        &::before {
          top: 0rem;
        }
        &::after {
          bottom: 0rem;
        }
      }

      & > div,
      p {
        position: relative;
        z-index: 1;
      }
    }

    .icon-wrapper {
      width: 4.15rem;
      height: 4.15rem;
    }
  }

  &-row {
    height: 4rem;
    width: 100%;
    position: relative;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .how-width {
      width: 4rem;
      text-align: right;
    }
  }
}
</style>
