<template>
  <div :class="`report-icon-${iconName}${selected ? '-selected' : ''}`" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppReportIcon',
  props: {
    iconName: {
      type: String,
      required: true,
      validator(value: string): boolean {
        return [
          'web',
          'mobile',
          'general',
          'graphical',
          'other',
          'purchase',
          'technical',
          'translation',
        ].includes(value)
      },
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/report-icons.scss';
</style>
