import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const helicopterLeftToRightAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'helicopterLeftToRightAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '14',
      delay: 0,
      duration: 1600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 14,
        end: 18,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'helicopterLeftToRightAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '18',
      delay: 0,
      duration: 1600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 18,
        end: 17,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'helicopterLeftToRightAnimations3',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '17',
      delay: 0,
      duration: 1600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 17,
        end: 20,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'helicopterLeftToRightAnimations4',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '20',
      delay: 0,
      duration: 1600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 20,
        end: 19,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'helicopterLeftToRightAnimations5',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '19',
      delay: 0,
      duration: 1600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 19,
        end: 18,
        zeroPad: 2,
      },
    },
  },
]
