<template>
  <div data-event-handler />
</template>

<script lang="ts">
import { EVENT_CREATE_EVENT_BUILDING } from '@/globalVariables'
import { getRouteWebName } from '@/plugins/getWebView'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useUserStore } from '@/store/pinia/userStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useRenderQueueStore } from '@/store/pinia/renderQueueStore'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { RenderQueueComponent } from '@/enums/RenderQueueTypes'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MapEventHandler',
  computed: {
    ...mapState(useEventInfoStore, {
      eventMechanicByType: 'getEventMechanicByType',
      isEventTeaserActive: 'getEventTeaserActive',
      isEventActive: 'getEventActive',
      isEventCollectionActive: 'getEventCollectionActive',
      isEventLocked: 'getEventLocked',
    }),
    ...mapState(useUserStore, {
      isEventTeaserSeen: 'isEventTeaserSeen',
    }),
    ...mapState(useTutorialStore, {
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useResponseTaskStore, {
      mechanics: 'getMechanics',
    }),
    ...mapState(useRenderQueueStore, {
      currentComponentInQueue: 'getCurrentComponentInQueue',
    }),
    ...mapState(usePhaserGameIntegrationStore, {
      isMainMapEventBuildingCreated: 'getIsMainMapEventBuildingCreated',
    }),
  },
  watch: {
    async currentComponentInQueue(): Promise<void> {
      if (this.currentComponentInQueue !== RenderQueueComponent.EventsTeaser) return

      await this.loadEvent(true)

      await this.setProfileAttributes({
        name: 'event_teaser_seen',
        value: 1,
      })

      this.$router.push({ name: getRouteWebName('EventsTeaser') })
    },
    // Riesi sa az pri kliknuti na tlacidlo v CareerRewards.
    // mechanics: {
    //   async handler(): Promise<void> {
    //     if (!this.mechanics.includes(this.eventMechanicByType)) return
    //     await this.createEventBuilding()
    //   },
    //   deep: true,
    // },
    async isEventActive(): Promise<void> {
      if (!this.isEventActive) return
      await this.createEventBuilding()
    },
    async isEventCollectionActive(): Promise<void> {
      if (!this.isEventCollectionActive) return
      await this.createEventBuilding()
    },
    async isEventLocked(): Promise<void> {
      if (this.isEventLocked) return
      await this.createEventBuilding()
    },
  },
  async created(): Promise<void> {
    await this.createEventBuilding()

    if (this.isTutorial) return
    if (!this.mechanics.includes(this.eventMechanicByType)) return
    if (!this.isEventTeaserActive && !this.isEventActive && !this.isEventCollectionActive) return
    if (this.isEventTeaserActive && this.isEventTeaserSeen) return
    if (this.isEventActive || this.isEventCollectionActive) return

    this.addToRenderQueue(RenderQueueComponent.EventsTeaser)
  },
  mounted(): void {
    document.addEventListener('visibilitychange', this.onVisibilityChange, { passive: true })
    document.addEventListener(EVENT_CREATE_EVENT_BUILDING, this.createEventBuilding, {
      passive: true,
    })
  },
  beforeUnmount(): void {
    document.removeEventListener('visibilitychange', this.onVisibilityChange)
    document.removeEventListener(EVENT_CREATE_EVENT_BUILDING, this.createEventBuilding)
  },
  methods: {
    ...mapActions(useEventInfoStore, ['loadEvent']),
    ...mapActions(useUserStore, ['setProfileAttributes']),
    ...mapActions(useRenderQueueStore, ['addToRenderQueue']),
    ...mapActions(usePhaserGameIntegrationStore, ['loadEventBuilding']),
    async onVisibilityChange(): Promise<void> {
      if (document.visibilityState === 'hidden') return
      await this.createEventBuilding()
    },
    async createEventBuilding(): Promise<void> {
      if (this.isTutorial) return
      if (!this.mechanics.includes(this.eventMechanicByType)) return

      await this.loadEvent(true)

      if (this.isEventTeaserActive) return
      if (!this.isEventActive && !this.isEventCollectionActive) return
      if (this.isEventLocked) return
      if (this.isMainMapEventBuildingCreated) return

      this.loadEventBuilding()
    },
  },
})
</script>
