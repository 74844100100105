<template>
  <div
    class="clubs-periodic-payment-box relative flexing text-texts-standard-default"
    :class="'text-' + rewardSize"
  >
    <div
      class="clubs-periodic-payment-box-value flexing relative"
      :class="!rewardValue ? 'no-income' : ''"
    >
      <rewards-component
        v-if="rewardValue"
        :reward-data="[{ count: rewardValue, type: rewardType }]"
        :icon-size="rewardIconSize"
        :custom-font-size="36"
        :is-font-bold="false"
      />
      <p v-else class="text-texts-standard-important">
        {{ $t('club.noIncome') }}
      </p>
    </div>
    <div v-if="rewardInterval && rewardValue" class="clubs-periodic-payment-box-time flexing">
      <span
        class="text-texts-standard-important text-36"
        :class="{ 'revert-skew': currentGame === GameType.Winter }"
      >
        {{ Math.round(rewardInterval / 60 / 60) }}{{ 'h' }}
      </span>
      <app-icon icon-name="time" />
    </div>
  </div>
</template>

<script lang="ts">
import RewardsComponent from '@/components/Rewards.vue'
import { defineComponent } from 'vue'
import { GameType } from '@/globalVariables/gameTypes'

interface ComponentData {
  GameType: typeof GameType
  currentGame: string
}

export default defineComponent({
  name: 'ClubsPeriodicPaymentBox',
  components: {
    RewardsComponent,
  },
  props: {
    rewardValue: {
      type: Number,
      required: true,
    },
    rewardInterval: {
      type: Number,
      required: true,
    },
    rewardType: {
      type: String,
      default: 'money',
    },
    rewardSize: {
      type: Number,
      default: 30,
    },
    rewardIconSize: {
      type: Number,
      default: 56,
    },
  },
  data(): ComponentData {
    return {
      GameType,
      currentGame: this.$gameName,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/club/employees.scss';

.clubs-periodic-payment-box {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 0.125rem;
  box-shadow:
    inset -0.4375rem 0 1.3125rem 0 rgba(51, 123, 214, 0.19),
    inset -0.019625rem -0.1865rem 2.5rem 0 rgba(6, 34, 55, 0.48);
  transform: $skewX-value;
  @if $isWsm {
    border: 0.125rem solid #f4d91e;
    background: #0f3251;
  }
  @if $isSsm {
    border: 0.125rem solid #fff284;
    background: #363e5d;
  }

  &-value {
    width: 60%;
    height: 100%;

    @if $isWsm {
      left: 1rem;
      transform: $skewX-revert-value;
    }

    line-height: 1.75rem;

    &.no-income {
      width: 100%;
      left: 0;
    }

    & p {
      font-size: 2.25rem !important;
    }

    & .rewards :not(.reward):last-child {
      position: relative;
    }
  }

  &-time {
    width: 8.5rem;
    height: 100%;

    @if $isWsm {
      background: #21517c;
    }

    @if $isSsm {
      background: #202544;
    }

    & :first-child {
      margin-right: 0.5rem;
    }

    & :last-child {
      width: 2rem;
      height: 2rem;
      background-size: auto 100%;

      @if $isWsm {
        transform: $skewX-revert-value;
      }
    }
  }
}
</style>
