<template>
  <div class="lab-camps-header flexing">
    <template v-if="!isContentLoading">
      <div
        v-for="(col, i) in headerData"
        :key="i"
        class="text-28 text-texts-standard-default lab-camps-head-cols"
      >
        <div v-if="col.disciplines" class="lab-camps-disciplines flex items-center justify-center">
          <app-discipline-icon
            v-for="discId in col.disciplines"
            :key="discId"
            theme="light"
            :size="70"
            :discipline-id="discId"
            :is-coming-soon="!unlockedDisciplinesList.includes(discId)"
          />
        </div>
        <p v-if="col.text">{{ $t(col.text) }}</p>
      </div>
    </template>

    <component-loading :is-loading="isContentLoading" />
  </div>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { AvailableDisciplinesApiResponse } from '@/interfaces/responses/disciplines/DisciplinesApiResponses'

interface HeaderData {
  disciplines: number[]
  text: string
}
export default defineComponent({
  name: 'LabCampsHeader',
  props: {
    headerData: {
      type: Array as PropType<HeaderData[]>,
      required: true,
    },
  },
  computed: {
    ...mapState(useDisciplineStore, {
      unlockedDisciplines: 'getUnlockedDisciplines',
      isLoading: 'getIsLoading',
    }),
    isContentLoading(): boolean {
      return this.isLoading || this.unlockedDisciplines === null || this.headerData === null
    },
    unlockedDisciplinesList(): number[] {
      if (!this.isContentLoading && this.unlockedDisciplines) {
        return this.unlockedDisciplines.map(
          (discipline: AvailableDisciplinesApiResponse): number => discipline.games_disciplines_id,
        )
      }
      return []
    },
  },
  async mounted(): Promise<void> {
    await this.loadDisciplines()
  },
  methods: {
    ...mapActions(useDisciplineStore, {
      loadDisciplines: 'loadDisciplines',
    }),
  },
})
</script>

<style lang="scss" scoped>
.lab-camps-header {
  width: 100%;
  height: 4.875rem;

  .lab-camps-head-cols {
    width: 15.23rem;
    line-height: 2rem;
  }
  .lab-camps-disciplines {
    & > div {
      margin: 0 0.2rem;
    }
  }
}
</style>
