<template>
  <div class="bounty-board-task-wrapper">
    <div class="bounty-board-task">
      <div
        :class="`bounty-board-task-difficulty bounty-board-task-difficulty--${task.difficulty}`"
      />
      <div class="bounty-board-task-description-wrapper flexing">
        <p class="bounty-board-task-description text-32 text-texts-standard-default">
          {{ formattedTaskDescription }}
        </p>
      </div>
      <div v-if="!isActiveTask" class="bounty-board-task-panel flex">
        <reward-panel :rewards="task.rewards" />
        <app-button
          btn-id="bounty-board-footer-select-btn"
          btn-size="md"
          btn-type="secondary"
          :btn-text="$te('choose')"
          @click="$debounce(handleSelectBtnClick)"
        />
        <popup-window
          v-if="showConfirmation && showSelectPopup"
          popup-type="info"
          :popup-title="$te('taskSelection')"
          @close="showSelectPopup = false"
        >
          <div class="select-popup flexing flex-col">
            <div class="select-popup-cta flexing">
              <span class="gradient-text-gold text-50 font-bold">
                {{ $te('taskSelectionConfirm') }}
              </span>
            </div>
            <p class="text-34 text-texts-standard-default">{{ `(${$te('oneTaskWarning')})` }}</p>
            <div class="select-popup-description flex text-36 gap-2.5">
              <div>
                <span class="text-texts-standard-important font-bold">
                  {{ `${$te('task')}:` }}
                </span>
              </div>
              <div class="text-left">
                <p class="text-texts-standard-default">
                  {{ formattedTaskDescription }}
                </p>
              </div>
            </div>
            <div class="select-popup-checkbox flexing gap-6">
              <app-check-box
                :is-checked="hideConfirmPopup"
                :is-transparent-background="false"
                size="sm"
                @checked-action="hideConfirmPopup = !hideConfirmPopup"
              />
              <span class="text-30 text-texts-standard-default">{{
                $te('dontShowThisMessage')
              }}</span>
            </div>
            <app-button
              class="mt-8"
              btn-type="secondary"
              :btn-text="$te('acceptTask')"
              @click="
                $debounce((): void => {
                  $emit('activateTask', task.id_task, hideConfirmPopup)
                })
              "
            />
          </div>
        </popup-window>
      </div>
      <div v-else class="flexing">
        <app-progress-bar
          :bar-width="46.375"
          :bar-height="1.625"
          :actual="task.actual"
          :goal="task.target"
          show-custom-content
        >
          <template #customContent>
            <div class="flex w-full justify-between">
              <p class="text-texts-standard-default text-36 font-normal">
                <span
                  class="progress-status-bottom--actual text-texts-standard-important font-bold"
                >
                  {{ $filters.$formatNumber(task.actual) }}
                </span>
                /
                <span class="progress-status-bottom--goal">
                  {{ $filters.$formatNumber(task.target) }}
                </span>
              </p>
              <div v-if="task.reward_ready" class="flexing gap-2 text-36 font-normal">
                <span>{{ $te('eventTaskDone') }}</span>
                <app-icon icon-name="done-sm" class="relative" />
              </div>
            </div>
          </template>
        </app-progress-bar>
      </div>
    </div>
    <div v-if="isActiveTask" class="bounty-board-task-footer flexing gap-6">
      <reward-panel :rewards="task.rewards" is-active />
      <app-button
        btn-size="md"
        btn-type="confirm"
        :btn-text="$t('common.claim')"
        :disabled="!task.reward_ready"
        @click="$debounce(claim)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { useTaskBoardStore } from '@/store/pinia/events/taskBoardStore'
import RewardPanel from '@/components/Events/BoardComponents/BountyBoard/RewardPanel.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'

import type { PropType } from 'vue'
import type { BoardTask } from '@/interfaces/responses/events/task-board/TaskBoardStateApiResponse'

interface ComponentData {
  showSelectPopup: boolean
  hideConfirmPopup: boolean
}

export default defineComponent({
  name: 'BountyBoardTask',
  components: {
    RewardPanel,
    PopupWindow,
  },
  props: {
    task: {
      type: Object as PropType<BoardTask>,
      required: true,
    },
  },
  emits: ['activateTask'],
  data(): ComponentData {
    return {
      showSelectPopup: false,
      hideConfirmPopup: false,
    }
  },
  computed: {
    ...mapState(useTaskBoardStore, {
      showConfirmation: 'getShowConfirmation',
    }),
    isActiveTask(): boolean {
      return !!this.task.active
    },
    isLoading(): boolean {
      return false
    },
    formattedTaskDescription(): string {
      const { text, target, discipline } = this.task
      const targetString = target.toString()
      const disciplineName = discipline
        ? this.getValidDiscipline(parseInt(discipline.toString()))
        : ''

      return this.$replaceUntranslatablePlaceholder(
        this.$replacePlaceholder(
          this.$replacePlaceholder(
            this.$replacePlaceholder(
              this.$replacePlaceholder(this.$t(`event.${text}`), '%s', targetString),
              '{2to4}',
              targetString,
            ),
            '{cash}',
            targetString,
          ),
          '{discipline}',
          disciplineName,
        ),
        '{grandprize}',
      )
    },
  },
  methods: {
    ...mapActions(useTaskBoardStore, {
      claim: 'claim',
    }),
    getValidDiscipline(disciplineId: number | 'random'): string {
      if (disciplineId === 'random') {
        return 'random'
      }
      // Ponechavam bez $te, lebo tieto su genericke na vsetky appky
      return this.$t(`discipline.discipline_${disciplineId}`)
    },
    handleSelectBtnClick(): void {
      // If no confirmation window is shown, task is selected directly
      if (!this.showConfirmation)
        this.$emit('activateTask', this.task.id_task, this.hideConfirmPopup)
      this.showSelectPopup = true
    },
  },
})
</script>

<style lang="scss" scoped>
.bounty-board-task {
  position: relative;
  width: 55.688rem;
  height: 15.813rem;
  background: url($path-events + 'autumn-fair/bounty-board/wooden-box-bg.avif');
  background-size: contain;
  padding: 2.5rem 2.875rem 2.875rem;

  &-difficulty {
    position: absolute;
    right: -0.25rem;
    top: -0.375rem;
    width: 7.813rem;
    height: 7.375rem;

    $difficulty-levels: (
      1: 'easy',
      2: 'normal',
      3: 'hard',
      4: 'very-hard',
    );

    @each $id, $label in $difficulty-levels {
      &--#{$id} {
        background: url($path-events + 'autumn-fair/bounty-board/task-label--' + $label + '.avif');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  &-description {
    line-height: 2.5rem;
    @include line-clamp(2);

    &-wrapper {
      height: 5rem;
      margin-bottom: 1rem;
    }
  }

  &-panel {
    position: relative;
    width: calc(100% + 3rem);
    left: -3rem;
    gap: 0.75rem;
  }

  &-footer {
    margin-top: 1.625rem;
    width: 54.5rem;
  }

  :deep() {
    .progress-bar-main .progress-bar-counts .progress-status-bottom {
      bottom: -3.5rem;
    }
  }
}

.select-popup {
  width: 70.75rem;
  min-height: 35.375rem;
  padding: 1.5rem;

  &-cta,
  &-checkbox {
    position: relative;
    width: 100%;
    height: 5.188rem;
    margin: 0.25rem 0 1.125rem;
    background: linear-gradient(
      to right,
      transparent,
      rgba(11, 28, 47, 0.7) 25%,
      rgba(11, 28, 47, 1),
      rgba(11, 28, 47, 1),
      rgba(11, 28, 47, 0.7) 75%,
      transparent
    );

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.125rem;
      background: linear-gradient(
        to right,
        transparent,
        rgba(49, 80, 107, 0.7) 25%,
        rgba(49, 80, 107, 1),
        rgba(49, 80, 107, 1),
        rgba(49, 80, 107, 0.7) 75%,
        transparent
      );
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  &-description {
    margin-top: 2.875rem;
    padding: 1rem 14rem;
    background: linear-gradient(
      to right,
      transparent,
      rgba(11, 28, 47, 0.7) 25%,
      rgba(11, 28, 47, 1),
      rgba(11, 28, 47, 1),
      rgba(11, 28, 47, 0.7) 75%,
      transparent
    );
  }

  &-checkbox {
    margin-top: 2.625rem;
    margin-bottom: 0;
  }
}
</style>
