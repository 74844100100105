<template>
  <div class="app-page-wrapper absolute">
    <div id="seasons-end-view" class="seasons-end flex flex-col w-full h-full safe-area">
      <div
        class="flex flex-col justify-between seasons-end-wrapper text-white"
        :class="{
          'seasons-end-wrapper--mobile': $isMobile(),
        }"
      >
        <div v-if="!isContentLoading" class="flex flex-col justify-between">
          <p
            class="text-60 uppercase align-center italic text-texts-standard-additional"
            :class="{
              'font-bold': $isSsm,
            }"
          >
            {{ $t('seasons.season') }}
            <span class="text-texts-standard-important">
              {{ seasonEndStats.seasonMonth + '/' + seasonEndStats.seasonYear }}
            </span>
          </p>
          <seasons-about class="seasons-end-wrapper-about" />

          <p
            v-if="seasonEndStats.season === PREVIOUS_SEASON"
            class="seasons-end-wrapper-end text-36 mt-2"
          >
            {{ $t('seasons.seasonEnded') }}
          </p>

          <div
            v-if="seasonEndStats.season === CURRENT_SEASON"
            class="flex flex-col items-center mt-4"
          >
            <p class="text-texts-standard-important text-30">
              {{ $t('seasons.timeTillSeasonEnd') }}
            </p>
            <app-timer
              class="seasons-end-wrapper-time text-36"
              centered
              :time="seasonEndStats.remainingTime"
              :timer-end-text="$t('seasons.ended')"
              :style="{
                width: $isSsm ? 'auto' : undefined,
              }"
            />
            <p
              class="text-32"
              :class="{
                'text-texts-standard-additional': $isWsm,
                'text-texts-standard-default': $isSsm,
              }"
            >
              {{
                $replacePlaceholder(
                  $t('seasons.seasonEndingSoon'),
                  '{season}',
                  `${seasonEndStats.seasonMonth}/${seasonEndStats.seasonYear}`,
                )
              }}
            </p>
          </div>

          <div class="seasons-end-wrapper-boxes flexing">
            <div
              v-for="(box, key) in getBoxes"
              :key="key"
              class="seasons-end-wrapper-boxes-box bordered-1 flex flex-col items-center"
            >
              <img
                class="seasons-end-wrapper-boxes-box-img"
                :style="{ width: box.imgWidth }"
                :src="pathImages + box.img"
              />
              <p
                class="text-32 uppercase"
                :class="{
                  'text-texts-standard-name': $isWsm,
                  'text-texts-standard-default': $isSsm,
                }"
              >
                {{ $t(box.title) }}
              </p>
              <p
                class="text-28"
                :class="{
                  'text-texts-standard-name': $isWsm,
                  'text-texts-standard-default': $isSsm,
                }"
              >
                {{ $replacePlaceholder($t(box.text), '%s', TOP_PLAYERS) }}:
                <span
                  class="text-32 font-bold"
                  :class="[
                    { 'text-texts-standard-danger': box.value === 0 },
                    { 'text-texts-standard-important': box.value > 0 },
                  ]"
                >
                  {{ box.value }}
                </span>
              </p>
            </div>
          </div>

          <div
            v-if="seasonEndStats.season === PREVIOUS_SEASON"
            class="flexing seasons-end-wrapper-description"
          >
            <div class="flex flex-col">
              <p
                v-for="(text, index) in seasonEndStats.seasonTexts"
                :key="index"
                class="text-32"
                :class="{
                  'text-texts-standard-additional': $isWsm,
                  'text-texts-standard-default': $isSsm,
                }"
              >
                {{
                  $replacePlaceholder($t(`seasons.${text}`), '%s', seasonEndStats.currentArenaLevel)
                }}
              </p>
            </div>

            <tippy theme="WSM" placement="left" max-width="75rem">
              <app-icon
                v-if="$isWsm"
                class="seasons-end-wrapper-description-icon"
                icon-name="info-70"
              />
              <app-icon
                v-if="$isSsm"
                class="seasons-end-wrapper-description-icon"
                icon-name="info-44"
              />
              <template #content>
                <seasons-end-tooltip :season-end-stats="seasonEndStats" />
              </template>
            </tippy>
          </div>

          <div class="seasons-end-wrapper-rewards flex flex-col space-y-4">
            <p
              class="text-32 text-texts-standard-important font-bold seasons-end-wrapper-rewards-title"
            >
              {{
                $t(
                  seasonEndStats.season === PREVIOUS_SEASON
                    ? 'seasons.yourRewards'
                    : 'season.currentRewards',
                )
              }}
            </p>

            <div v-if="seasonEndStats.rewards.length" class="flexing flex-col flex-grow">
              <div class="flexing flex-col">
                <div class="flexing flex-row">
                  <reward-box
                    v-for="(reward, i) in seasonEndStats.rewards"
                    :key="i"
                    :reward-number="reward.amount"
                    :reward-icon-name="getRewardName(reward)"
                    :reward-icon-rarity="reward.rarity ?? ''"
                    :reward-icon-size="56"
                  />
                </div>
                <p
                  v-if="seasonEndStats.season === PREVIOUS_SEASON"
                  class="text-28 mt-4"
                  :class="{
                    'text-texts-standard-name': $isWsm,
                    'text-texts-standard-additional': $isSsm,
                  }"
                >
                  {{ $t('seasons.rewardsOnYourAccount') }}
                </p>
              </div>
            </div>

            <div
              v-if="!seasonEndStats.rewards.length"
              class="flex flex-col seasons-end-wrapper-rewards-no-rewards"
            >
              <p
                class="text-32 mt-4"
                :class="{
                  'text-texts-standard-additional': $isWsm,
                  'text-texts-standard-default': $isSsm,
                }"
              >
                {{ $t('seasons.noRewards') }}
              </p>
              <p
                v-if="seasonEndStats.season === PREVIOUS_SEASON"
                class="text-28"
                :class="{
                  'text-texts-standard-name': $isWsm,
                  'text-texts-standard-additional': $isSsm,
                }"
              >
                {{ $t('seasons.noRewardsInLastSeason') }}
              </p>
            </div>
          </div>
        </div>
        <component-loading :is-loading="isContentLoading" width="100%" />
        <div class="flex justify-between seasons-end-wrapper-buttons">
          <app-button :btn-text="$t('seasons.detail')" @click="goToSeasonDetail" />
          <app-button btn-type="secondary" :btn-text="$t('seasons.continue')" @click="hideModal" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import SeasonsAbout from '@/components/Seasons/SeasonsAbout.vue'
import SeasonsEndTooltip from '@/components/Seasons/SeasonsEndTooltip.vue'
import {
  ARENA,
  CURRENT_SEASON,
  PREVIOUS_SEASON,
  SEASONAL_ATTRIBUTES,
  TOP_PLAYERS,
  WORLD_RANKING,
  pathImages,
} from '@/globalVariables'
import { useRenderQueueStore } from '@/store/pinia/renderQueueStore'
import { useMainSeasonStore } from '@/store/pinia/seasons/mainSeasonsStore'
import { useSeasonEndStore } from '@/store/pinia/seasons/seasonEndStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { SeasonEndRewardSource } from '@/interfaces/Seasons'
import type Reward from '@/interfaces/Reward'

interface SeasonsEndBox {
  img: string
  imgWidth: string
  title: string
  text: string
  value: number
}

interface ComponentData {
  pathImages: typeof pathImages
  CURRENT_SEASON: typeof CURRENT_SEASON
  PREVIOUS_SEASON: typeof PREVIOUS_SEASON
  TOP_PLAYERS: typeof TOP_PLAYERS
  isContentLoading: boolean
}

export default defineComponent({
  name: 'SeasonsEnd',
  components: {
    SeasonsAbout,
    SeasonsEndTooltip,
  },
  data(): ComponentData {
    return {
      pathImages,
      CURRENT_SEASON,
      PREVIOUS_SEASON,
      TOP_PLAYERS,
      isContentLoading: false,
    }
  },
  computed: {
    ...mapState(useSeasonEndStore, {
      seasonEndStats: 'getSeasonEndStats',
    }),
    getBoxes(): SeasonsEndBox[] {
      const boxes = [
        {
          img: 'benefits/benefit_building_arena.avif',
          imgWidth: '13.5rem',
          title: 'map.arenaName',
          text: 'arena.highestLevel',
          value:
            this.seasonEndStats?.rewardsSources.find(
              (source: SeasonEndRewardSource): boolean => source.type === ARENA,
            )?.topValue ?? 0,
        },
        {
          img: 'seasons/season-attribute-bg.avif',
          imgWidth: '7rem',
          title: 'seasons.seasonalAttributes',
          text: 'seasons.trainedAttributes',
          value:
            this.seasonEndStats?.rewardsSources.find(
              (source: SeasonEndRewardSource): boolean => source.type === SEASONAL_ATTRIBUTES,
            )?.topValue ?? 0,
        },
        {
          img: 'seasons/WORLD-RANKING-ICO.avif',
          imgWidth: '8.5rem',
          title: 'seasons.worldRanking',
          text: 'seasons.topInDisciplines',
          value:
            this.seasonEndStats?.rewardsSources.find(
              (source: SeasonEndRewardSource): boolean => source.type === WORLD_RANKING,
            )?.topValue ?? 0,
        },
      ]

      return boxes
    },
  },
  async created(): Promise<void> {
    this.isContentLoading = true
    await this.loadSeasonEndStats()
    this.isContentLoading = false
  },
  methods: {
    ...mapActions(useMainSeasonStore, ['setSeasonModalState']),
    ...mapActions(useSeasonEndStore, ['loadSeasonEndStats']),
    ...mapActions(useRenderQueueStore, ['setNextInRenderQueue']),
    hideModal(): void {
      this.setSeasonModalState(false)
      this.setNextInRenderQueue()
      this.$router.push({ name: 'LayoutView' })
    },
    goToSeasonDetail(): void {
      this.hideModal()

      this.$router.push({
        name: this.$getWebView('Seasons'),
        params: { type: 'previous' },
      })
    },
    getRewardName(reward: Reward): string {
      if (
        reward.type === 'attribute' ||
        reward.type === 'training_points' ||
        reward.type === 'cash'
      )
        return `${reward.name}_${reward.rarity}`

      return reward.name
    },
  },
})
</script>

<style lang="scss" scoped>
.seasons-end {
  background: url($path-images + 'seasons/seasons-end-bg.avif') center no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;

  &-wrapper {
    position: relative;
    flex: 1 1 auto;
    overflow: hidden;
    padding: 2rem;

    &--mobile {
      .seasons-end-wrapper-about {
        top: 3rem;
        right: 4rem;
      }
    }

    &-about {
      position: absolute;
      top: 2.5rem;
      right: 2rem;
    }

    &-end {
      width: 30%;
      margin: 0 auto;
      margin-top: 0.5rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      background: linear-gradient(
        90deg,
        rgba(195, 24, 24, 0) 0%,
        rgba(195, 24, 24, 0.8) 50%,
        rgba(195, 24, 24, 0) 100%
      );
    }

    &-time {
      width: 15%;
      padding-right: 4rem;
      margin-left: 3rem;
      margin-bottom: 2rem;
      margin-top: 0.5rem;
    }

    &-boxes {
      margin-top: 5%;

      &-box {
        height: 10rem;
        width: 100%;
        margin-right: 0.5rem;
        margin-left: 0.5rem;

        @if $isWsm {
          background-color: rgba(18, 61, 87, 0.8);
          border: 0.063rem solid #22638c;
        }

        @if $isSsm {
          background-color: #3a425d;
          border: 0.063rem solid #7b87b8;
        }

        &-img {
          height: 7rem;
          margin-top: -4.5rem;
          margin-bottom: 1rem;
        }
      }
    }

    &-description {
      width: 49%;
      margin: 0 auto;
      margin-top: 2%;

      &-icon {
        margin-left: 1rem;

        @if $isWsm {
          margin-bottom: 1.5rem;
        }
      }
    }

    &-rewards {
      height: 18.438rem;
      margin-top: 2%;
      padding-top: 1rem;
      padding-bottom: 1rem;

      @if $isWsm {
        background-image: linear-gradient(
          90deg,
          rgba(15, 48, 79, 0) 0%,
          rgba(15, 48, 79, 0.84) 25%,
          rgba(15, 48, 79, 0.9) 50%,
          rgba(15, 48, 79, 0.84) 75%,
          rgba(15, 48, 79, 0) 100%
        );
        border-style: solid;
        border-width: 0.063rem;
        border-image-source: linear-gradient(
          90deg,
          rgba(217, 236, 255, 0) 0%,
          rgba(217, 236, 255, 1) 50%,
          rgba(217, 236, 255, 0) 100%
        );
        border-image-slice: 1;
      }

      @if $isSsm {
        background-image: linear-gradient(
          to right,
          transparent,
          rgba(52, 65, 93, 0.85),
          rgba(39, 50, 73, 1),
          rgba(52, 65, 93, 0.85),
          transparent
        );
        border-style: solid;
        border-width: 0.125rem;
        border-image-source: linear-gradient(to right, transparent, #586b9d, #586b9d, transparent);
        border-image-slice: 1;
      }

      &-title {
        width: 35%;
        margin: 0 auto;

        @if $isWsm {
          background-image: linear-gradient(
            90deg,
            rgba(108, 158, 196, 0) 0%,
            rgba(108, 158, 196, 1) 50%,
            rgba(108, 158, 196, 0) 100%
          );
        }

        @if $isSsm {
          background-image: linear-gradient(to right, transparent, #56627f, #56627f, transparent);
        }
      }

      &-no-rewards {
        height: 75%;
        justify-content: center;
      }
    }

    &-buttons {
      margin-top: 2%;
    }
  }
}
</style>
