<template>
  <div class="price-per-purchased flexing">
    <p class="font-bold" :class="[`text-${fontSize}`, setFontColor]">
      {{ $filters.$formatNumber(purchasedPrice) }}
    </p>
    <app-main-icon :icon-size="48" :icon-name="priceType" />
  </div>
</template>

<script lang="ts">
import { GEMS, MONEY, RESEARCH_POINTS } from '@/globalVariables'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PricePerPurchase',

  props: {
    purchasedPrice: {
      type: Number,
      required: true,
    },
    priceType: {
      type: String,
      required: true,
    },
    fontSize: {
      type: Number,
      default: 36,
    },
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      [GEMS]: 'getGems',
      [MONEY]: 'getMoney',
      [RESEARCH_POINTS]: 'getResearchPoints',
    }),
    setFontColor(): string {
      if (this[this.priceType] && this[this.priceType].value < this.purchasedPrice)
        return 'text-texts-standard-danger'
      return 'text-texts-standard-default'
    },
  },
})
</script>
