import type { ImageLoadData } from '@/map-phaser-new/interfaces'

export const clubsMapImageLoadData: () => ImageLoadData[] = () => [
  {
    name: 'clubsMap',
    compressed: true,
    data: {
      astc: {
        type: 'KTX',
        textureURL: 'map/compressed/club_map_BG-astc12x12.ktx',
      },
      etc: {
        type: 'KTX',
        textureURL: 'map/compressed/club_map_BG-etc2RGBA.ktx',
      },
      img: {
        textureURL: 'map/club_map_BG.avif',
      },
      place: 'map/club_map_BG.avif',
    },
  },
]
