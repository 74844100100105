<template>
  <teleport to="#app">
    <div
      v-show="showMechanics"
      class="overlay absolute w-full h-full"
      @click="$emit('hideMechanic')"
    >
      <section v-if="buildings" class="mechanics-menu absolute" @click.stop>
        <ul class="w-full h-full">
          <!-- TODO FIXME - vytiahnut do zvlast komponentu -->
          <li
            v-for="(menu, index) in mechanics"
            :key="index"
            class="relative cursor-pointer w-full flex items-center text-texts-standard-name uppercase text-36"
            :class="[
              {
                'pointer-events-none': hasLockedState(menu.mechanic),
                'hover-state': !hasLockedState(menu.mechanic),
              },
            ]"
            @click="handleButtonClick(menu.url.toString(), menu.mechanic)"
          >
            <app-icon
              :icon-name="`mechanics-${hasLockedState(menu.mechanic) ? 'lock-' : ''}${
                menu.mechanic
              }`"
            />
            <p class="text-left">
              {{ $t(`map.${menu.name}`) }}
            </p>
            <app-icon
              v-if="hasLockedState(menu.mechanic)"
              v-tippy="{
                theme: 'WSM',
                content:
                  menu.mechanic == 'star_of_the_day'
                    ? $replacePlaceholder($t('common.mainCareerTaskRequired'), '%s', 20)
                    : 'Coming soon',
                placement: 'bottom',
              }"
              class="icon-tooltip"
              :icon-name="lockedIconState(menu.mechanic)"
            />
          </li>
        </ul>
        <div class="triangle triangle-down yellow absolute" />
      </section>
    </div>
  </teleport>
</template>

<script lang="ts">
import {
  ARENA,
  MECHANIC_CLUB_CHAMPIONSHIP,
  MECHANIC_STAR_OF_THE_DAY,
  MECHANIC_TOURNAMENTS,
  TRAINING_HALL,
} from '@/globalVariables'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { BuildingMainMapResponseInterface } from '@/map-phaser-new/interfaces'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'

interface Mechanic {
  name: string
  mechanic: string
  url?: string
}
interface ComponentData {
  mechanics: Mechanic[]
}

export default defineComponent({
  name: 'MechanicsMenu',
  props: {
    showMechanics: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['hideMechanic'],
  data(): ComponentData {
    return {
      mechanics: [
        {
          name: 'tournamentsName',
          mechanic: MECHANIC_TOURNAMENTS,
        },
        {
          name: 'starOfTheDayName',
          mechanic: MECHANIC_STAR_OF_THE_DAY,
          url: this.$getWebView('StarofthedayDisciplines'),
        },
        {
          name: 'clubChampionshipsName',
          mechanic: MECHANIC_CLUB_CHAMPIONSHIP,
          url: this.$getWebView('ClubsChampionshipCompetitions'),
        },
        {
          name: 'trainingHallName',
          mechanic: TRAINING_HALL,
          url: this.$getWebView('TrainingDisciplines'),
        },
        {
          name: 'arenaName',
          mechanic: ARENA,
          url: this.$getWebView('ArenaView'),
        },
      ],
    }
  },
  computed: {
    ...mapState(usePhaserGameIntegrationStore, {
      buildings: 'getMainMapBuildings',
    }),
  },
  methods: {
    hasLockedState(mechanic: string): boolean {
      return (
        this.buildings.find(
          (building: BuildingMainMapResponseInterface): boolean => building.name === mechanic,
        )?.locked ?? false
      )
    },
    lockedIconState(state: string): string {
      switch (state) {
        case MECHANIC_CLUB_CHAMPIONSHIP:
        case MECHANIC_TOURNAMENTS:
          return 'time'
        case MECHANIC_STAR_OF_THE_DAY:
          return 'lock-md'
      }
    },
    sendToUrl(url: string): void {
      this.$router.push({ name: url, query: { homepage: 'true' } })
    },
    handleButtonClick(url: string, mechanic: string): void {
      this.sendToUrl(url)
      if (!this.hasLockedState(mechanic)) {
        this.$emit('hideMechanic')
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/mechanics-icons.scss';

.overlay {
  z-index: 2;
}

.mechanics-menu {
  z-index: 2;
  width: 35.3125rem;
  height: 34.3rem;
  box-shadow:
    inset 0.063rem 0 1.25rem 0 rgba(25, 73, 105, 0.8),
    inset 0.75rem 0.188rem 6.25rem 0 rgba(25, 73, 105, 0.8),
    inset 0 0 1.25rem 0 rgba(25, 73, 105, 0.8),
    inset -0.688rem -0.188rem 1.25rem 0 rgba(25, 73, 105, 0.8);
  background: #081624;
  right: 1.9rem;
  bottom: 8.5rem;
  border: 0.125rem solid #75b6e7;
  border-bottom: 0.25rem solid #f4d91e;

  li {
    padding-left: 1rem;
    height: 6.8125rem;

    &:after {
      position: absolute;
      content: '';
      height: 0.1rem;
      width: 100%;
      left: 50%;
      bottom: 0;
      transform: translate(-50%);
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.01),
        #fff,
        rgba(255, 255, 255, 0)
      );
    }

    &:last-child:after {
      display: none;
    }

    p {
      width: 23.4375rem;
      margin-left: 1.875rem;
    }

    .icon-tooltip {
      z-index: 3;
      pointer-events: auto;
    }
  }

  .hover-state {
    &:active,
    &:hover {
      background: linear-gradient(to right, #b58804, #fadd1eb3, #b58804);
      box-shadow: inset 5px 0 30px 0 rgb(209 130 5 / 73%);
      color: #233441;
    }
  }

  .triangle {
    left: 67%;
  }
}
</style>
