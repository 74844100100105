<template>
  <div :id="aboutId">
    <div class="popup-about-typeOne">
      <div v-for="(box, index) in popupData" :key="index" class="popup-about-box">
        <div class="popup-about-box-pic">
          <img :src="pathImages + box.img" />
        </div>
        <div class="popup-about-box-textarea">
          <div class="popup-about-box-title text-texts-standard-important text-36">
            {{ box.title }}
          </div>
          <div class="popup-about-box-text text-texts-standard-default text-30">
            {{
              box.text.includes('{grandprize}')
                ? $replacePlaceholder(box.text, '{grandprize}', 'grand prize')
                : box.text
            }}
            <ul v-if="box?.list" class="list-disc ml-8" :style="{ width: box.listWidth }">
              <li v-for="listItem in box.list" :key="listItem">
                <div class="flex" v-html="listItem" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { createRandomId } from '@/helpers'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface PopupData {
  img: string
  text: string
  title: string
  list?: string[]
  listWidth?: string
}

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'PopupAboutTypeOne',

  props: {
    popupData: {
      type: Array as PropType<PopupData[]>,
      required: true,
    },
    aboutId: {
      type: String,
      default: createRandomId('button'),
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
})
</script>

<style lang="scss" scoped>
.popup-about-typeOne {
  width: 102.75rem;
  height: 38.375rem;

  .popup-about-box {
    margin: 0.625rem 0;

    &-pic {
      width: 15%;
      height: 12rem;
      background: transparent;
      display: inline-block;
      vertical-align: top;
      position: relative;

      img {
        max-height: 100%;
        max-width: 7.5rem;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    &-textarea {
      width: 85%;
      font-size: 1.25rem;
      display: inline-block;

      @if $isWsm {
        font-style: italic;
      }

      .popup-about-box-title {
        font-size: 2rem;
        width: 95%;
        height: 3rem;
        line-height: 3rem;
        display: block;
        text-align: left;
        text-transform: uppercase;

        @if $isWsm {
          border-bottom: 0.063rem solid skyblue;
        }

        @if $isSsm {
          border-bottom: 0.063rem solid #4c648f;
        }
      }

      .popup-about-box-text {
        font-size: 1.75rem;
        width: 95%;
        display: block;
        text-align: left;
        padding: 1rem 0;
      }
    }
  }
}
</style>
