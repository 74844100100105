<template>
  <div id="progress-bar" class="progress-bar flex justify-start items-center">
    <div
      :style="{
        width: calculateProgressBarWidth(param),
        background: getProgressBarColor(param),
      }"
      class="progress-bar-inner"
    />
  </div>
</template>

<script lang="ts">
import { parameterTypes } from '@/globalVariables'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { AnimatedNumber } from '@/interfaces/global/AnimatedNumber'
import type { Nullable } from '@/interfaces/utils'

export default defineComponent({
  name: 'AnimatedNumberProgressBar',
  props: {
    param: {
      type: Object as PropType<Nullable<AnimatedNumber>>,
      default: () => null,
    },
  },
  methods: {
    calculateProgressBarWidth(param: AnimatedNumber): string {
      if (param == null) return
      const timeTotal = parameterTypes[param.parameter_name]?.autoTransactionInterval * 60
      const relevantTime =
        param.value < param.max_value ? timeTotal - param.next_refresh : param.next_refresh
      const progressBarWidth = Math.round((relevantTime * 100) / timeTotal)

      if (isNaN(progressBarWidth)) return

      return progressBarWidth + '%'
    },
    getProgressBarColor(param: AnimatedNumber): string {
      if (param.value > param.max_value) {
        return 'linear-gradient(90deg, rgba(255,24,24,1) 0%, rgba(254,127,127,1) 100%)'
      } else if (param.value < param.max_value) {
        return 'linear-gradient(90deg, rgba(246,199,23,1) 0%, rgba(255,239,132,1) 100%)'
      } else return 'transparent'
    },
  },
})
</script>

<style scoped lang="scss">
.progress-bar-inner {
  width: 100%;
  height: 2px;
  transition: width 0.5;
}
</style>
