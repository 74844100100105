<template>
  <div
    v-if="isSeasonRecalculation"
    class="season-recalculation-popup"
    :class="{
      'h-full': isEventView,
    }"
  >
    <div
      class="season-recalculation-popup-item text-white flex flex-col items-center justify-center"
    >
      <p class="text-32 italic">
        {{ $t('seasons.seasonRecalculation') }}
      </p>
      <p class="text-28">
        {{ $t('seasons.newSeasonStartsInFewMinutes') }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SeasonsRecalculationPopup',
  props: {
    isEventView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      isSeasonRecalculation: 'getSeasonRecalculation',
    }),
  },
})
</script>

<style lang="scss">
.season-recalculation-popup {
  position: absolute;
  width: 100%;
  height: 81%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;

  &-item {
    position: absolute;
    width: 100%;
    height: 16.938rem;
    top: 25%;
    box-shadow: 0px 0.313rem 0.813rem 0 #272727;
    z-index: 2;

    @if $isWsm {
      background-image: linear-gradient(
        90deg,
        rgba(35, 109, 155, 0) 0%,
        rgba(33, 98, 140, 1) 15%,
        rgba(28, 70, 103, 1) 50%,
        rgba(34, 102, 146, 1) 85%,
        rgba(35, 109, 155, 0) 100%
      );
    }

    @if $isSsm {
      background-image: linear-gradient(
        to right,
        transparent,
        #2f3457,
        #4f4d79,
        #2f3457,
        transparent
      );
    }
  }
}
</style>
