const IS_MOBILE = 'is_mobile'
const IS_FLUTTER = 'is_flutter'

const convertIsMobileValue = (value: number): number => (value === 1 ? 1 : 0)

export const setIsMobileLocalStorage = (value: number, isFlutter: boolean): void => {
  localStorage.setItem(IS_MOBILE, convertIsMobileValue(value).toString())
  localStorage.setItem(IS_FLUTTER, isFlutter.toString())
}

export const getIsMobileLocalStorage = (): number => {
  const storedValue = localStorage.getItem(IS_MOBILE)
  const isMobile: number = storedValue ? parseInt(storedValue) : 0
  return convertIsMobileValue(isMobile)
}

export const isFlutterApp = (): boolean => {
  const flutterLocalStorage = localStorage.getItem(IS_FLUTTER)

  if (flutterLocalStorage === null) {
    return document.URL.includes('/isFlutter=true')
  }

  return flutterLocalStorage === 'true'
}
