import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const carsLeftTopToRightTopCheckpoints: CheckpointsObjectInterface = {
  key: 'carsLeftTopToRightTopCheckpoints',
  data: [
    {
      x: 683,
      y: 601,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsLeftTopToRightTopAnimations1',
        },
      },
    },
    {
      x: 667,
      y: 708,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsLeftTopToRightTopAnimations2',
        },
      },
    },
    {
      x: 398,
      y: 976,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsLeftTopToRightTopAnimations3',
        },
      },
    },
    {
      x: 467,
      y: 1103,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsLeftTopToRightTopAnimations4',
        },
      },
    },
    {
      x: 592,
      y: 1139,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsLeftTopToRightTopAnimations5',
        },
      },
    },
    {
      x: 715,
      y: 1115,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsLeftTopToRightTopAnimations6',
        },
      },
    },
    {
      x: 820,
      y: 1069,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsLeftTopToRightTopAnimations7',
        },
      },
    },
    {
      x: 851,
      y: 1007,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsLeftTopToRightTopAnimations8',
        },
      },
    },
    {
      x: 842,
      y: 954,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsLeftTopToRightTopAnimations9',
        },
      },
    },
    {
      x: 1586,
      y: 707,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsLeftTopToRightTopAnimations10',
        },
      },
    },
    {
      x: 2987,
      y: 545,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsLeftTopToRightTopAnimations11',
        },
      },
    },
    {
      x: 3069,
      y: 568,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsLeftTopToRightTopAnimations12',
        },
      },
    },
    {
      x: 3175,
      y: 618,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsLeftTopToRightTopAnimations13',
        },
      },
    },
    {
      x: 3266,
      y: 704,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsLeftTopToRightTopAnimations14',
        },
      },
    },
    {
      x: 3308,
      y: 754,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.PauseAction,
        action: {
          duration: 2000,
        },
      },
    },
    {
      x: 3325,
      y: 768,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsLeftTopToRightTopAnimations15',
        },
      },
    },
    {
      x: 3424,
      y: 719,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsLeftTopToRightTopAnimations16',
        },
      },
    },
    {
      x: 3448,
      y: 664,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carsLeftTopToRightTopAnimations17',
        },
      },
    },
  ],
}
