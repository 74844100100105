<template>
  <section class="lucky-wheel safe-area">
    <aside v-if="dataLoaded" class="lucky-wheel-left">
      <div class="lucky-button flexing">
        <event-pass-button :pass="PassType.Event" class="mb-2" />
      </div>
      <lucky-wheel-explanation-text />
      <lucky-wheel-progress :config-properties="configProgressProperties" />
      <lucky-wheel-spins
        v-if="refreshSpinIn !== null"
        :free-spins="freeSpins"
        :refresh-in="refreshSpinIn"
        :daily-spins="dailyFreeSpins"
        @refresh-spin="reloadData"
      />
    </aside>
    <aside v-if="dataLoaded" class="lucky-wheel-right">
      <lucky-wheel-controller
        :previous-reward="previousReward"
        :init-state="isSpinned"
        :disabled-buttons-parent="disabledButtons"
        :free-spins="freeSpins"
        @wheel-spinned="reloadData"
        @claimed-reward="reloadData"
      />
    </aside>
    <component-loading :is-loading="!dataLoaded" width="100%" />
    <section class="shadower" />
  </section>
</template>

<script lang="ts">
import { getLuckyWheelState } from '@/globalVariables'
import { PassType } from '@/interfaces/events/Passes'
import { defineComponent } from 'vue'
import EventPassButton from '../GlobalEventsComponents/EventPassButton.vue'
import LuckyWheelController from './LuckyWheelController.vue'
import LuckyWheelExplanationText from './LuckyWheelExplanationText.vue'
import LuckyWheelProgress from './LuckyWheelProgress.vue'
import LuckyWheelSpins from './LuckyWheelSpins.vue'
import type LuckyWheelStateApiResponse from '@/interfaces/responses/events/lucky-wheel/LuckyWheelStateApiResponse'
import type { Nullable } from '@/interfaces/utils'

export interface LuckyWheelConfigProgressProperties {
  actual: LuckyWheelStateApiResponse['event_points_progress']
  goal: LuckyWheelStateApiResponse['event_points_per_spin']
  pointPerSpin: LuckyWheelStateApiResponse['event_points_per_spin']
}

interface ComponentData {
  PassType: typeof PassType
  dataLoaded: boolean
  freeSpins: LuckyWheelStateApiResponse['spins_left']
  refreshSpinIn: Nullable<LuckyWheelStateApiResponse['free_spin_refresh_in']>
  isSpinned: boolean
  previousReward: Nullable<LuckyWheelStateApiResponse['last_reward'][0]>
  disabledButtons: boolean
  dailyFreeSpins: LuckyWheelStateApiResponse['daily_free_spins']
  configProgressProperties: Nullable<LuckyWheelConfigProgressProperties>
}

export default defineComponent({
  name: 'LuckyWheelMain',
  components: {
    LuckyWheelExplanationText,
    LuckyWheelController,
    LuckyWheelProgress,
    LuckyWheelSpins,
    EventPassButton,
  },
  data(): ComponentData {
    return {
      PassType,
      dataLoaded: false,
      freeSpins: 0,
      refreshSpinIn: null,
      isSpinned: false,
      previousReward: null,
      disabledButtons: false,
      dailyFreeSpins: 1,
      configProgressProperties: null,
    }
  },
  async created(): Promise<void> {
    await this.getLuckyWheelState()
  },
  methods: {
    async getLuckyWheelState(): Promise<void> {
      try {
        const stateData = await this.$axios.get<{}, LuckyWheelStateApiResponse>(getLuckyWheelState)
        if (!stateData || Array.isArray(stateData)) {
          throw new Error('No data on spinning wheel error')
        }
        this.configProgressProperties = {
          actual: stateData.event_points_progress,
          goal: stateData.event_points_per_spin,
          pointPerSpin: stateData.event_points_per_spin,
        }
        this.dailyFreeSpins = stateData.daily_free_spins
        this.freeSpins = stateData.spins_left
        if (this.freeSpins === 0) this.disabledButtons = true
        this.refreshSpinIn = stateData.free_spin_refresh_in || null
        if (stateData.last_reward && stateData.last_reward.length > 0) {
          this.previousReward = stateData.last_reward[0]
          this.isSpinned = true
        }
        this.dataLoaded = true
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async reloadData(): Promise<void> {
      await this.getLuckyWheelState()
    },
  },
})
</script>

<style lang="scss" scoped>
section.lucky-wheel {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  height: 100vh;

  .lucky-wheel-left {
    display: flex;
    flex-direction: column;
    width: 40%;
    z-index: 1;

    .lucky-button {
      width: 50.813rem;
      height: 7rem;
      @include background(url($path-events + 'shared/event-pass-active-bg.webp'), contain);
      margin-top: 1.75rem;
      margin-bottom: 1rem;
    }
  }

  .lucky-wheel-right {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
  }

  .shadower {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    box-shadow: inset 0 -38px 38.85px -12px rgb(0 0 0 / 51%);
  }
}
</style>
