<template>
  <div>
    <app-icon icon-name="question-70" @click="isOpen = true" />
    <info-popup
      v-if="isOpen"
      :popup-title="$t('tournaments.tournaments')"
      class="tournaments-info-popup"
      width="76rem"
      @close="isOpen = false"
    >
      <app-scrollbar
        class="tournaments-info-popup-scrollbar"
        width="100%"
        height="41.5rem"
        scroll="y"
        slide="y"
      >
        <div
          class="tournaments-info-popup-content w-full flex flex-col text-32 text-texts-standard-default space-y-12"
        >
          <div v-for="(section, i) in texts" :key="i" class="space-y-4">
            <p
              class="tournaments-info-popup-content-title text-36 text-texts-standard-important text-left uppercase"
            >
              {{ section.title }}
            </p>
            <p
              v-for="(text, j) in section.texts"
              :key="j"
              class="tournaments-info-popup-content-text text-30 text-texts-standard-default text-left ml-8 relative"
            >
              <template v-if="i === 0 && j === 3">
                {{ $replacePlaceholder(text, '{number}', 30) }}
              </template>
              <template v-else-if="i === 1 && j === 2">
                {{ $replacePlaceholder(text, '{pass}', $t('tournaments.tournamentPass')) }}
              </template>
              <template v-else>
                {{ text }}
              </template>
            </p>
          </div>
        </div>
      </app-scrollbar>
    </info-popup>
  </div>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { defineComponent } from 'vue'

interface ComponentData {
  isOpen: boolean
}

export default defineComponent({
  components: {
    InfoPopup,
  },
  data(): ComponentData {
    return {
      isOpen: false,
    }
  },
  computed: {
    texts(): any {
      return [
        {
          title: this.$t('tournaments.basicInfo'),
          texts: Array.from({ length: 7 }, (_: undefined, i: number): string =>
            this.$t(`tournaments.tournamentInfo${i + 1}`),
          ),
        },
        {
          title: this.$t('tournaments.milestones'),
          texts: Array.from({ length: 4 }, (_: undefined, i: number): string =>
            this.$t(`tournaments.tournamentInfo${i + 1 + 7}`),
          ),
        },
      ]
    },
  },
})
</script>

<style lang="scss" scoped>
.tournaments-info-popup {
  &-content {
    padding: 2rem 4.5rem;

    &-title {
      @if $isWsm {
        border-bottom: 0.063rem solid skyblue;
      }

      @if $isSsm {
        border-bottom: 0.063rem solid #4c648f;
      }
    }

    &-text {
      &::before {
        content: '';
        position: absolute;
        left: -2rem;
        top: 1.15rem;
        width: 0.625rem;
        height: 0.625rem;
        background-color: #fff;
      }
    }
  }
}
</style>
