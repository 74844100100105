<template>
  <header class="arena-header flexing w-full">
    <event-discipline-header class="my-4">
      <event-reputation-box direction="row-reverse" class="mr-auto ml-6" />
    </event-discipline-header>
  </header>
</template>

<script lang="ts">
import EventDisciplineHeader from '@/components/Events/EventDisciplineHeader.vue'
import EventReputationBox from '@/components/Events/EventReputationBox.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ArenaHeader',
  components: {
    EventDisciplineHeader,
    EventReputationBox,
  },
})
</script>
