<template>
  <section class="teams-rewards flexing h-full">
    <app-button
      btn-type="confirm"
      btn-size="sm"
      class="relative bottom-20"
      style="width: 21.875rem"
      :disabled="!rewards.length"
      @click="isOpen = true"
    >
      <component-loading v-if="!rewards.length" :is-loading="true" />
      <template v-else>{{ $t('common.rewards') }}</template>
    </app-button>
    <info-popup
      v-if="isOpen"
      :popup-title="$t('common.rewards')"
      class="teams-rewards-popup"
      width="76.125rem"
      @close="isOpen = false"
    >
      <section
        v-if="rewards"
        class="teams-rewards-popup-content text-34 text-texts-standard-default py-6"
      >
        <app-scrollbar width="100%" height="100%" scroll="y" slice="y" class="flex">
          <article
            v-for="reward in rewards"
            :key="reward.selection"
            class="w-1/2 font-bold space-y-4"
          >
            <p class="text-60 uppercase">
              {{ reward.selection === 1 ? $te('leftTeam') : $te('rightTeam') }}
            </p>
            <div class="teams-rewards-popup-content-tier flexing w-full uppercase relative">
              <p class="gradient-text-gold text-50">{{ $te('tier') }} {{ currentDifficulty }}</p>
            </div>
            <p
              v-for="(icon, i) in reward.rewards"
              :key="i"
              class="teams-rewards-popup-content-number flexing text-50 text-texts-standard-default relative"
            >
              <app-main-icon :icon-name="icon.type" :icon-size="56" />
              {{ icon.value }}
            </p>
          </article>
        </app-scrollbar>
      </section>
    </info-popup>
  </section>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { taskchainRewardsSummary as TASKCHAIN_REWARDS_SUMMARY_ENDPOINT } from '@/globalVariables'
import type TaskchainSummedRewardsApiResponse from '@/interfaces/responses/events/TaskchainSummedRewardsApiResponse'
import { useTaskChainStore } from '@/store/pinia/events/taskChainStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  isOpen: boolean
  rewards: TaskchainSummedRewardsApiResponse['rewards']
}

export default defineComponent({
  name: 'TeamsRewards',
  components: { InfoPopup },
  data(): ComponentData {
    return {
      isOpen: false,
      rewards: [],
    }
  },
  computed: {
    ...mapState(useTaskChainStore, {
      currentChainId: 'getCurrentChainId',
      currentDifficulty: 'getCurrentDifficulty',
    }),
  },
  async created(): Promise<void> {
    await this.loadRewards()
  },
  methods: {
    async loadRewards(): Promise<void> {
      try {
        this.rewards =
          (
            await this.$axios.post<{}, TaskchainSummedRewardsApiResponse>(
              TASKCHAIN_REWARDS_SUMMARY_ENDPOINT,
              {
                chain_id: this.currentChainId,
                difficulty: this.currentDifficulty,
              },
            )
          )?.rewards || []
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.teams-rewards {
  align-items: flex-end;
}

.teams-rewards-popup {
  &-content {
    height: 41.5rem;
    box-shadow: inset 0rem 0rem 2rem #1b2f47;

    &-tier {
      height: 5.25rem;

      @if $isWsm {
        background-image: linear-gradient(to right, transparent 17%, #15466e, transparent 83%);
      }

      @if $isSsm {
        background-image: linear-gradient(
          to right,
          transparent,
          rgba(144, 144, 193, 0.5),
          transparent
        );
      }

      &::before,
      &::after {
        content: '';

        position: absolute;
        width: 80%;
        left: 50;
        transform: translate(0, -50%);
        height: 0.125rem;

        @if $isWsm {
          background-image: linear-gradient(to right, transparent, #335a7a, transparent);
        }

        @if $isSsm {
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.01),
            #fff,
            rgba(255, 255, 255, 0.01)
          );
        }
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }

    &-number {
      background: linear-gradient(to right, transparent 17%, #0b1c2f, transparent 83%);
      height: 5.25rem;
      margin: 0.625rem 0;

      &::before,
      &::after {
        content: '';
        background: linear-gradient(to right, transparent, #31506b, transparent);
        position: absolute;
        width: 80%;
        left: 50;
        transform: translate(0, -50%);
        height: 0.125rem;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }
  }
}
</style>
