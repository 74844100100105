<template>
  <div class="league-information">
    <div class="league-information-logo" :class="'badge-' + toKebabCase(getLeagueText) + '-100'" />
    <div class="league-information-title">
      <h2
        class="mr-4"
        :class="$isWsm ? 'text-texts-standard-name' : 'text-texts-standard-additional'"
      >
        {{ `${$t('club.' + getLeagueText)} ${rookieLeagueNumber ? rookieLeagueNumber : ''}` }}
      </h2>
      <h2 class="text-texts-standard-important">
        {{ $replacePlaceholder($t('club.division'), '%s', division.toString()) }}
      </h2>
    </div>
    <div class="league-information-right">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { CLUB_CHAMPIONSHIP_DIVISIONS, CLUB_CHAMPIONSHIP_LEAGUES } from '@/globalVariables'
import { toKebabCase } from '@/helpers'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClubsChampionshipLeageInfo',
  props: {
    league: {
      type: String,
      default: CLUB_CHAMPIONSHIP_LEAGUES.rookie,
    },
    division: {
      type: [Number, String],
      default: CLUB_CHAMPIONSHIP_DIVISIONS._32,
    },
    rookieLeagueNumber: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getLeagueText(): string {
      if (this.league.includes(CLUB_CHAMPIONSHIP_LEAGUES.rookie)) {
        return CLUB_CHAMPIONSHIP_LEAGUES.rookie
      }
      return this.league
    },
  },
  methods: {
    toKebabCase,
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/clubs-championship-leagues.scss';

.league-information {
  width: 100%;
  height: 6.063rem;
  position: relative;
  display: flex;
  align-items: center;
  @if $isWsm {
    background: linear-gradient(
      to left,
      transparent 0%,
      #144a73 10%,
      #144a73 90%,
      transparent 100%
    );
  }
  @if $isSsm {
    background: linear-gradient(
      to left,
      transparent 0%,
      rgba(52, 65, 93, 0.85) 10%,
      rgba(39, 50, 73, 0.85) 90%,
      transparent 100%
    );
    &::before,
    &::after {
      content: '';
      position: absolute;
      background: linear-gradient(to right, transparent, #586b9d, transparent);
      width: 80%;
      height: 0.1875rem;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }
  &-logo {
    width: 6.125rem;
    height: 6.125rem;
  }

  &-title {
    font-size: theme('fontSize.40');
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin: 0 1.5rem;
  }

  &-right {
    margin-left: auto;
  }
}
</style>
