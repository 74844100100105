<template>
  <div class="time-format">
    {{ getTimerFormat }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppTimeFormatter',
  props: {
    timer: {
      type: [Date, String],
      default: Date.now(),
    },
  },
  computed: {
    getTimerFormat(): string {
      let dateData: Date | number = new Date(this.timer).getTime()
      const actualDate = new Date(Date.now()).getTime()

      const seconds = (actualDate - dateData) / 1000
      return this.$beforeTimeText(seconds)
    },
  },
})
</script>
