<template>
  <div id="club-buildings" class="club-buildings app-page-wrapper absolute">
    <clubs-centrum-menu />
    <section class="w-full h-full safe-area">
      <main v-if="buildings">
        <app-scrollbar
          width="100%"
          height="100%"
          scroll="y"
          slide="y"
          class="club-buildings-scroll"
        >
          <template v-for="(building, i) in buildings" :key="i">
            <section v-if="building && building.name !== CLUB_CHAMPIONSHIP">
              <clubs-centrum-building-big-box
                v-if="building?.box === 'big'"
                :building-data="building"
                @click="goTo(building.name)"
              />
              <clubs-centrum-building-small-box
                v-else
                :building-data="building"
                @click="goToBuilding(building)"
              />
            </section>
          </template>
        </app-scrollbar>
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import ClubsCentrumBuildingBigBox from '@/components/Club/ClubsCentrumBuildingBigBox.vue'
import ClubsCentrumBuildingSmallBox from '@/components/Club/ClubsCentrumBuildingSmallBox.vue'
import ClubsCentrumMenu from '@/components/Club/ClubsCentrumMenu.vue'
import {
  CLUBARENA,
  CLUBHOUSE,
  CLUB_EMPLOYEE,
  DIRECTORATE,
  FITNESS,
  HOTEL,
  MEDIA,
  MEDICAL,
  PARKING,
  PERSONAL_EMPLOYEE,
  RELAX,
  RESTAURANT,
  SWIMMING,
  WELLNESS,
  CLUB_CHAMPIONSHIP,
  clubBuildingsEmployeesEndpoint,
} from '@/globalVariables'
import { getIconName, capitalize } from '@/helpers'
import { useBuildingsStore } from '@/store/pinia/clubs/buildingStore'
import { useRewardStore } from '@/store/pinia/clubs/rewardStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { ClubBuildingData } from '@/interfaces/clubs/Clubs'
import type { ClubBuildingsResponse } from '@/interfaces/responses/club/ClubBuildingsResponses'
import type EmployeesResponse from '@/interfaces/responses/club/EmployeesResponse'
import type { EmployeeData } from '@/interfaces/responses/club/EmployeesResponse'

const TEXT_MAPPER = {
  [CLUBARENA]: 'map.arenaName',
  [CLUBHOUSE]: 'club.clubHouse',
}

interface RewardData {
  rewardValue: number
  rewardInterval: number
  rewardName: string
}

interface ComponentData {
  buildingsData: ClubBuildingData[]
  buildings: ClubBuildingData[]
  CLUB_CHAMPIONSHIP: typeof CLUB_CHAMPIONSHIP
}

export default defineComponent({
  name: 'ClubsBuildings',
  components: {
    ClubsCentrumMenu,
    ClubsCentrumBuildingBigBox,
    ClubsCentrumBuildingSmallBox,
  },
  data(): ComponentData {
    return {
      buildingsData: [
        {
          name: CLUBHOUSE,
          box: 'big',
          level: 1,
          text: 'club.clubHouse',
        },
        {
          name: CLUBARENA,
          box: 'big',
          level: 2,
          text: 'map.arenaName',
        },
        {
          name: PARKING,
          box: 'small',
          hasReward: false,
          lock: false,
          employees: [
            {
              type: 'personal',
              active: true,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: false,
            },
            {
              type: 'club',
              active: false,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: false,
            },
          ],
        },
        {
          name: FITNESS,
          box: 'small',
          hasReward: false,
          lock: false,
          employees: [
            {
              type: 'personal',
              active: true,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: false,
            },
            {
              type: 'club',
              active: true,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: false,
            },
          ],
        },
        {
          name: RELAX,
          box: 'small',
          hasReward: true,
          lock: false,
          employees: [
            {
              type: 'personal',
              active: true,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: true,
            },
            {
              type: 'club',
              active: true,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: true,
            },
          ],
        },
        {
          name: WELLNESS,
          box: 'small',
          hasReward: false,
          lock: false,
          employees: [
            {
              type: 'personal',
              active: false,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: false,
            },
            {
              type: 'club',
              active: false,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: false,
            },
          ],
        },
        {
          name: SWIMMING,
          box: 'small',
          hasReward: true,
          lock: false,
          employees: [
            {
              type: 'personal',
              active: true,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: true,
            },
            {
              type: 'club',
              active: true,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: false,
            },
          ],
        },
        {
          name: RESTAURANT,
          box: 'small',
          hasReward: false,
          lock: true,
          employees: [
            {
              type: 'personal',
              active: false,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: false,
            },
            {
              type: 'club',
              active: false,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: false,
            },
          ],
        },
        {
          name: MEDICAL,
          box: 'small',
          hasReward: false,
          lock: true,
          employees: [
            {
              type: 'personal',
              active: false,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: false,
            },
            {
              type: 'club',
              active: false,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: false,
            },
          ],
        },
        {
          name: HOTEL,
          box: 'small',
          hasReward: false,
          lock: false,
          employees: [
            {
              type: 'personal',
              active: true,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: false,
            },
            {
              type: 'club',
              active: true,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: false,
            },
          ],
        },
        {
          name: MEDIA,
          box: 'small',
          hasReward: false,
          lock: true,
          employees: [
            {
              type: 'personal',
              active: false,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: false,
            },
            {
              type: 'club',
              active: false,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: false,
            },
          ],
        },
        {
          name: DIRECTORATE,
          box: 'small',
          hasReward: false,
          lock: false,
          employees: [
            {
              type: 'personal',
              active: false,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: false,
            },
            {
              type: 'club',
              active: true,
              rewards: {
                type: 'money',
                value: 10000,
              },
              canClaim: false,
            },
          ],
        },
      ],
      buildings: [],
      CLUB_CHAMPIONSHIP,
    }
  },
  computed: {
    ...mapState(useRewardStore, {
      canClaim: 'getAllClubReadyForClaimRewardsBySpecificId',
    }),
    ...mapState(useBuildingsStore, {
      clubBuildings: 'getBuildings',
    }),
  },
  created() {
    this.initBuildings()
  },
  methods: {
    ...mapActions(useRewardStore, ['loadAllClubRewards']),
    ...mapActions(useBuildingsStore, {
      loadBuildings: 'loadBuildings',
    }),
    loadEmployees(): Promise<EmployeesResponse[]> {
      return this.$axios.post<{}, EmployeesResponse[]>(clubBuildingsEmployeesEndpoint, {
        filter_hired: true,
      })
    },
    async initBuildings(): Promise<void> {
      const employeesPromise = this.loadEmployees()
      const buldingsPromise = this.loadBuildings()
      const rewardsPromise = this.loadAllClubRewards()
      await buldingsPromise
      await rewardsPromise
      this.buildings = this.clubBuildings.reduce(
        (output: ClubBuildingData[], building: ClubBuildingsResponse): ClubBuildingData[] => {
          const canClaimReward = this.canClaim(building.building_type_id).length > 0
          output[building.building_type_id] = {
            buildingTypeId: building.building_type_id,
            name: building.parameters?.name,
            text: TEXT_MAPPER[building.parameters.name] ?? '',
            box: [CLUBHOUSE, CLUBARENA].includes(building.parameters?.name) ? 'big' : 'small',
            level: building.stats.level,
            hasReward: canClaimReward,
            incomeData: this.formatIncomeData(building),
            lock: building.stats.locked,
            clubHouseLevelRequired: building.parameters?.club_house_level_required ?? 0,
            employees: [],
          }
          return output
        },
        [],
      )

      const buildingEmployees = await employeesPromise
      buildingEmployees.forEach((building: EmployeesResponse): void => {
        if (!this.buildings[building.building_type?.building_type_id]) return
        const personalEmployee = building.building_type.employees.find(
          (employee: EmployeeData): boolean =>
            employee.parameters.employee_type === PERSONAL_EMPLOYEE,
        )
        const clubEmployee = building.building_type.employees.find(
          (employee: EmployeeData) => employee.parameters.employee_type === CLUB_EMPLOYEE,
        )
        this.buildings[building.building_type?.building_type_id].employees = [
          {
            type: PERSONAL_EMPLOYEE,
            active: !!personalEmployee,
            rewards: {
              type: getIconName(personalEmployee?.reward),
              value: personalEmployee?.reward?.value ?? 0,
            },
            canClaim: false,
          },
          {
            type: CLUB_EMPLOYEE,
            active: !!clubEmployee,
            rewards: {
              type: getIconName(clubEmployee?.reward),
              value: clubEmployee?.reward?.value ?? 0,
            },
            canClaim: false,
          },
        ]
      })
    },
    formatIncomeData(building: ClubBuildingsResponse): RewardData {
      const data = {
        rewardValue: 0,
        rewardInterval: 0,
        rewardName: '',
      }

      const levelData = building.levels?.find((level) => level.level === building.stats.level)
      if (!levelData?.reward) {
        return data
      }
      data.rewardName = levelData.reward.type ?? ''
      data.rewardValue = levelData.reward.value ?? 0
      data.rewardInterval = (levelData.parameters.frequency ?? 0) * 60 * 60

      return data
    },
    goToBuilding(building: ClubBuildingData): void {
      if (!building.lock) {
        this.$router.push({
          name: this.$getWebView('Clubs' + capitalize(building.name)),
        })
      }
    },
    goTo(name: string): void {
      if (name === CLUBHOUSE) this.$router.push({ name: this.$getWebView('ClubsHouse') })
      else this.$router.push({ name: this.$getWebView('ClubsStadium') })
    },
  },
})
</script>

<style lang="scss" scoped>
.club-buildings {
  main {
    width: 99.5%;
    height: 81%;

    .club-buildings-scroll {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 2.5rem;
      row-gap: 1.875rem;
      padding: 2.25rem 0;
    }

    section {
      display: flex;
    }
  }
}
</style>
