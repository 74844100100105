<template>
  <button
    type="button"
    class="facebook-login-button flexing w-full text-28 px-4 space-x-4"
    :class="`is-${status}`"
    :disabled="isDisabled"
    @click="login"
  >
    <svg
      class="facebook-login-icon"
      xml:space="preserve"
      viewBox="0 0 666.667 664.667"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="fb" clipPathUnits="userSpaceOnUse"><path d="M0 700h700V0H0Z" /></clipPath>
      </defs>
      <g clip-path="url(#fb)" transform="matrix(1.33333 0 0 -1.33333 -133.333 800)">
        <path
          d="M0 0c0 138.071-111.929 250-250 250S-500 138.071-500 0c0-117.245 80.715-215.622 189.606-242.638v166.242h-51.552V0h51.552v32.919c0 85.092 38.508 124.532 122.048 124.532 15.838 0 43.167-3.105 54.347-6.211V81.986c-5.901.621-16.149.932-28.882.932-40.993 0-56.832-15.528-56.832-55.9V0h81.659l-14.028-76.396h-67.631v-171.773C-95.927-233.218 0-127.818 0 0"
          :style="`fill: ${
            !monochrome ? '#0866ff' : 'currentColor'
          }; fill-opacity: 1; fill-rule: nonzero; stroke: none`"
          transform="translate(600 350)"
        />
        <path
          v-if="!monochrome"
          d="m0 0 14.029 76.396H-67.63v27.019c0 40.372 15.838 55.899 56.831 55.899 12.733 0 22.981-.31 28.882-.931v69.253c-11.18 3.106-38.509 6.212-54.347 6.212-83.539 0-122.048-39.441-122.048-124.533V76.396h-51.552V0h51.552v-166.242a250.559 250.559 0 0 1 60.394-7.362c10.254 0 20.358.632 30.288 1.831V0Z"
          style="fill: #fff; fill-opacity: 1; fill-rule: nonzero; stroke: none"
          transform="translate(447.918 273.604)"
        />
      </g>
    </svg>
    <span
      class="facebook-login-title"
      :class="{
        'font-bold': $isWsm,
        'font-medium': $isSsm,
        '!mr-auto': status !== ConnectedAccountStatus.NotConnected,
      }"
      v-html="
        context === LoginFormContext.LOGIN || status === ConnectedAccountStatus.NotConnected
          ? $replacePlaceholder($t('homepage.continue_with_platform'), '{platform}', 'Facebook')
          : getConnectedAccountStatusText(status)
      "
    />
    <!-- <app-icon
      v-if="status === ConnectedAccountStatus.CannotBeVerified"
      v-tippy="{
        content: '...',
        theme: 'WSM',
        placement: 'top',
      }"
      icon-name="info-44"
      class="pointer-events-auto"
    /> -->
    <app-icon v-if="status === ConnectedAccountStatus.Connected" icon-name="done-sm" />
  </button>
</template>

<script lang="ts">
import {
  dispatchGameLogin as DISPATCH_GAME_LOGIN,
  registerFacebookEndpoint as REGISTER_FACEBOOK_ENDPOINT,
  addLoginMethodEndpoint as CONNECT_FACEBOOK_ENDPOINT,
} from '@/globalVariables'
import {
  gameToken as GAME_TOKEN,
  getConnectedAccountStatusText,
  saveTokenToLocalStorage,
} from '@/helpers'
import { useCoreStore } from '@/store/pinia/coreStore'
import { defineComponent, type PropType } from 'vue'
import { mapActions, mapState } from 'pinia'
import { ConnectedAccountStatus, ConnectedAccountType, LoginFormContext } from '@/enums/LoginForm'
import { useUserStore } from '@/store/pinia/userStore'

import type { TrueFalseInterface } from '@/interfaces/utils'
import type { FbResponse } from '@/helpers'
import type {
  FacebookAuthApiPayload,
  FacebookAuthApiResponse,
  FacebookConnectApiPayload,
} from '@/interfaces/responses/login/LoginApiResponse'

interface ComponentData {
  LoginFormContext: typeof LoginFormContext
  ConnectedAccountStatus: typeof ConnectedAccountStatus
}

export default defineComponent({
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    monochrome: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String as PropType<ConnectedAccountStatus>,
      default: ConnectedAccountStatus.NotConnected,
      validator: (value: ConnectedAccountStatus) =>
        Object.values(ConnectedAccountStatus).includes(value),
    },
    context: {
      type: String as PropType<LoginFormContext>,
      default: '',
      validator: (value: LoginFormContext) => Object.values(LoginFormContext).includes(value),
    },
  },
  emits: ['facebookConnection'],
  data(): ComponentData {
    return {
      LoginFormContext,
      ConnectedAccountStatus,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userId: 'getUserId',
    }),
  },
  mounted(): void {
    if (this.status !== ConnectedAccountStatus.NotConnected) return
    if (localStorage.getItem('token') || !window.FB) return

    // TODO check usage
    window.FB.api('/me', { fields: 'email' }, (responseApi: FbResponse): void => {
      if (responseApi.error) {
        window.FB.getLoginStatus((responseLogIn: FbResponse): void => {
          if (!responseLogIn) return
          if (responseLogIn.status !== 'connected') return

          window.FB.logout((responseLogOut: FbResponse): void => {
            if (!responseLogOut) return
            if (responseLogOut.status === 'unknown') this.login()
          })
        })

        return
      }

      window.FB.getLoginStatus((responseLogIn: FbResponse): void => {
        if (!responseLogIn) return
        if (responseLogIn.status !== 'connected') return

        const idGroup = JSON.parse(localStorage.getItem('id_group'))

        this.$axios
          .post<{}, FacebookAuthApiResponse, FacebookAuthApiPayload>(REGISTER_FACEBOOK_ENDPOINT, {
            game_token: GAME_TOKEN,
            fb_access_token: responseLogIn.authResponse.accessToken,
            id_group: parseInt(idGroup?.value) ?? null,
          })
          .then((responseAuth: FacebookAuthApiResponse): void => {
            if (!responseAuth) return

            saveTokenToLocalStorage(responseAuth.access_token)
            this.setLoadingScreen(true)

            this.$axios
              .post<{}, true>(DISPATCH_GAME_LOGIN)
              .then((): void => {
                this.$router.push('/')
              })
              .catch(console.error)
          })
          .catch(console.error)
      })
    })
  },
  methods: {
    ...mapActions(useCoreStore, ['setLoadingScreen']),
    getConnectedAccountStatusText,
    login(): void {
      if (this.status !== ConnectedAccountStatus.NotConnected) return

      window.FB.login(
        (responseLogIn: FbResponse): void => {
          if (!responseLogIn) return

          const fbAccessToken = responseLogIn.authResponse.accessToken
          if (!fbAccessToken) return

          const idGroup = JSON.parse(localStorage.getItem('id_group'))
          const lastIdGroup = JSON.parse(localStorage.getItem('last_id_group'))?.value ?? null

          // CONNECT FACEBOOK
          if (this.context === LoginFormContext.CONNECTION) {
            this.connectFacebook(fbAccessToken)
            return
          }

          // REGISTER OR LOGIN WITH FACEBOOK
          this.$axios
            .post<{}, FacebookAuthApiResponse, FacebookAuthApiPayload>(REGISTER_FACEBOOK_ENDPOINT, {
              game_token: GAME_TOKEN,
              fb_access_token: fbAccessToken,
              id_group: parseInt(idGroup?.value) ?? null,
              last_id_group: lastIdGroup,
            })
            .then((responseAuth: FacebookAuthApiResponse): void => {
              if (!responseAuth) return

              localStorage.removeItem('last_id_group')
              saveTokenToLocalStorage(responseAuth.access_token)
              this.setLoadingScreen(true)

              this.$axios
                .post<{}, true>(DISPATCH_GAME_LOGIN)
                .then((): void => {
                  this.$router.push('/')
                })
                .catch(console.error)
            })
            .catch(console.error)
        },
        { scope: 'public_profile,email' },
      )
    },
    connectFacebook(fbAccessToken: string): void {
      try {
        this.$axios
          .post<{}, TrueFalseInterface, FacebookConnectApiPayload>(CONNECT_FACEBOOK_ENDPOINT, {
            fb_access_token: fbAccessToken,
            loginType: ConnectedAccountType.Facebook,
            game_token: GAME_TOKEN,
            uuid: this.userId,
            platform: 'web',
          })
          .then((res: TrueFalseInterface): void => {
            if (res) {
              // emit data refresh event to parent
              this.$emit('facebookConnection')
            }
          })
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.facebook-login {
  &-button {
    height: 4.25rem;
    color: #fff;
    background-color: #2374f2;
    border: 0.188rem solid darken(#2374f2, 5%);

    &:hover {
      background-color: darken(#2374f2, 5%);
    }

    &:active {
      transform: scale(0.95);
    }

    &:not(.is-not-connected) {
      color: #fff;
      background-color: transparent;
      background-image: linear-gradient(to top, rgba(#3be6c9, 0.25) 0%, transparent);
      border: solid 0.125rem #3be6c9;
      pointer-events: none;
      cursor: default;

      &:hover {
        background-color: inherit;
      }

      &:active {
        transform: none;
      }
    }
  }

  &-icon {
    width: 1.5em;
    height: 1.5em;
    margin: -0.25rem;
  }

  &-title {
    margin-top: 0.25rem;
  }
}

// Ked zacneme pouzivat mode: 'jit', tak toto moze ist doprec.
// https://v2.tailwindcss.com/docs/just-in-time-mode
.\!mr-auto {
  margin-right: auto !important;
}
</style>
