import { CLUB } from '@/globalVariables'
import router from '@/router'
import { getRouteWebName } from '@/plugins/getWebView'

import { StageType } from '@/interfaces/Tutorial'
import type { TutorialStage } from '@/interfaces/Tutorial'

/**
  Tutorial for unlocked mechanic CLUBS
 */

export default class TutorialUnlockClubs {
  stages(): TutorialStage[] {
    return [
      // narative saying you unlocked a new mechanic - club
      {
        id: 84,
        name: 'unlockClubsNarrative',
        type: StageType.Narrative,
        overlay: false,
        mechanic: 4,
        pageName: 'LayoutViewTutorial',
        texts: [],
      },
      // focus on club building
      {
        id: 85,
        name: 'focusOnClubsBuilding',
        type: StageType.Focus,
        mechanic: 4,
        overlay: false,
        pageName: 'LayoutViewTutorial',
        clientId: [CLUB],
        texts: [],
      },
      {
        id: 93,
        name: 'openClubBuilding',
        type: StageType.ClientInput,
        overlay: false,
        pageName: 'ClubsAvailableTutorial',
        clientId: ['club-reminder-footer-btn', 'club-reminder-overlay'],
        texts: [],
        onSuccess: {
          sendData: (): void => {
            router.push({ name: getRouteWebName('ClubsAvailable') })
          },
        },
      },
    ]
  }
}
