import type { App, Plugin } from 'vue'

export const routeToExternalUrl = (url: string): void => {
  window.location.href = url
}

export const routeToExternalUrlPlugin: Plugin = {
  install: (app: App): void => {
    app.config.globalProperties.$routeToExternalUrl = routeToExternalUrl
  },
}
