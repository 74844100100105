export const clubTableKeys = {
  country: 'country',
  level: 'level',
  name: 'name',
  username: 'username',
  rankingPoints: 'rankingPoints',
  prestige: 'prestige',
  button: 'button',
  type: 'type',
  members: 'membersMenu',
  delete: 'delete',
  id: 'id',
  userId: 'userId',
  pos: 'pos',
  onlineStatus: 'onlineStatus',
  nameComponent: 'nameComponent',
  clubNameComponent: 'clubNameComponent',
  championshipPoints: 'championshipPoints',
  tournamentTitles: 'tournamentTitles',
  settings: 'settings',
  rewards: 'rewards',
  commodityPoints: 'commodityPoints',
  progress: 'progress',
  reputation: 'reputation',
  rank: 'rank',
}
