<template>
  <template v-if="country">
    <p class="country font-bold text-24 text-texts-standard-name uppercase">
      {{ country }}
    </p>
    <div
      class="flag"
      :style="{
        backgroundImage: 'url(' + pathImages + 'flags/102x68/' + country + '.avif)',
      }"
    />
  </template>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'FlagComponent',
  props: {
    country: {
      type: String,
      default: '',
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
})
</script>

<style lang="scss" scoped>
.flag {
  width: 3.0625rem;
  min-width: 3.0625rem;
  height: 2rem;
  background: url($path-images + 'flags/70x49/FLAG_SVK.avif') center no-repeat;
  background-size: contain;
  margin-right: 1.0625rem;
  margin-left: 0.5625rem;
}
</style>
