<template>
  <form :id="formId" @submit.prevent="onSubmit">
    <clubs-info-form-row :label="$t('club.clubName')">
      <div class="flexing w-full">
        <app-input
          :id="'inputClubName'"
          class="club-info-row-input"
          :value="club.name"
          :name="'clubName'"
          :placeholder="$t('club.clubName')"
          :max-length="25"
          :error="
            errors.some((item) => item.errorField === 'clubName') &&
            $replacePlaceholder(
              $replacePlaceholder($t('club.clubNameRules'), '{min}', 3),
              '{max}',
              25,
            )
          "
          :tooltip-placement="'top'"
          @input="(value) => (club.name = value)"
          @input-watch="$emit('inputWatch', $event)"
        />
        <div class="relative right-4">
          <app-icon
            v-tippy="{
              theme: 'WSM',
              content: getTooltip('clubName'),
              placement: 'left',
            }"
            icon-name="info-70"
            class="club-info-row-input-icon"
          />
        </div>
      </div>
    </clubs-info-form-row>

    <clubs-info-form-row :label="$t('club.clubDescription')">
      <div class="flexing w-full">
        <app-input
          :id="'inputClubDescription'"
          class="club-info-row-input"
          :value="club.description"
          :name="'clubDescription'"
          :placeholder="$t('club.clubDescription')"
          :max-length="255"
          @input="(value) => (club.description = value)"
        />
        <div class="relative right-4">
          <app-icon
            v-tippy="{
              theme: 'WSM',
              content: getTooltip('clubDescription'),
              placement: 'left',
            }"
            icon-name="info-70"
            class="club-info-row-input-icon"
          />
        </div>
      </div>
    </clubs-info-form-row>

    <clubs-info-form-row :label="$t('club.clubLogo')">
      <div class="club-info-row-logos">
        <clubs-info-form-logos
          :logo-ids="logoIds"
          :selected-logo="club.logoId"
          @select-logo="(logoId) => (club.logoId = logoId)"
        />
      </div>
    </clubs-info-form-row>

    <clubs-info-form-row :label="$t('club.clubBackground')">
      <div class="club-info-row-logos">
        <clubs-info-form-logos
          :show-only-background="true"
          :background-ids="backgroundIds"
          :selected-background="club.logoBgId"
          @select-background="(backgroundId) => (club.logoBgId = backgroundId)"
        />
      </div>
    </clubs-info-form-row>

    <clubs-info-form-row :label="$t('map.country')" :flex-direction="ClubInfoFormFlex.Row">
      <app-select
        v-if="club.country"
        :style="{ zIndex: 4 }"
        :options="getCountriesOptions"
        :default="club.country"
        @select="(country) => (club.country = country)"
      />
    </clubs-info-form-row>

    <clubs-info-form-row
      :label="$t('club.clubAvailability')"
      :sub-label="$t('club.clubAvailabilityDesc')"
      :flex-direction="ClubInfoFormFlex.Row"
    >
      <app-select
        v-if="club.type"
        :style="{ zIndex: 3 }"
        :options="getClubTypesOptions"
        :default="club.type"
        @select="(type) => (club.type = type)"
      />
    </clubs-info-form-row>

    <clubs-info-form-row
      :label="$t('club.levelNeeded')"
      :sub-label="$t('club.levelNeededDesc')"
      :flex-direction="ClubInfoFormFlex.Row"
    >
      <app-select
        v-if="club.memberMinimumLevel"
        :style="{ zIndex: 2 }"
        :options="getClubRequiredLevels(false, false)"
        :default="club.memberMinimumLevel"
        @select="(levels) => (club.memberMinimumLevel = levels)"
      />
    </clubs-info-form-row>

    <clubs-info-form-row
      v-if="isDeleteClubVisible"
      :label="$t('club.deleteClub')"
      :sub-label="$replacePlaceholder($t('club.deleteClubDesc'), '{number}', 6)"
      :flex-direction="ClubInfoFormFlex.Row"
    >
      <app-button
        type="button"
        :btn-type="'danger'"
        :btn-text="$t('club.deleteClub')"
        @click="showDeletePopup = true"
      />
    </clubs-info-form-row>
  </form>

  <!-- POPUP DELETE CLUB POPUP -->
  <popup-window
    v-if="showDeletePopup"
    :popup-type="'submit'"
    :popup-data="getDeleteClubPopupData"
    @close="showDeletePopup = false"
    @action="deleteClub"
  />
</template>

<script lang="ts">
import ClubsInfoFormLogos from '@/components/Club/ClubsInfoFormLogos.vue'
import ClubsInfoFormRow from '@/components/Club/ClubsInfoFormRow.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import { CLUB_LEVELS, CLUB_TYPES } from '@/globalVariables'
import { formatCountries, getClubRequiredLevels } from '@/helpers'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useCountriesStore } from '@/store/pinia/countriesStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { ClubInfo } from '@/types/clubStoreTypes'
import { ClubInfoFormFlex } from '@/interfaces/clubs/Clubs'
import type { PopupData } from '@/interfaces/PopupData'
import type Country from '@/interfaces/Country'

interface ComponentData {
  formatCountries: typeof formatCountries
  ClubInfoFormFlex: typeof ClubInfoFormFlex
  showDeletePopup: boolean
  club: ClubInfo
  logoIds: number[]
  backgroundIds: number[]
  errors: { errorField: string }[]
}

export default defineComponent({
  name: 'ClubsInfoForm',
  components: {
    ClubsInfoFormRow,
    ClubsInfoFormLogos,
    PopupWindow,
  },
  props: {
    formId: {
      type: String,
      default: 'form-id',
    },
    isDeleteClubVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['submit', 'scrollToError', 'inputWatch'],
  data(): ComponentData {
    return {
      formatCountries,
      ClubInfoFormFlex,
      showDeletePopup: false,
      club: {
        id: null,
        name: '',
        description: '',
        logoId: 1,
        logoBgId: 1,
        country: null,
        type: null,
        memberMinimumLevel: null,
        paidInGems: false,
      },
      logoIds: Array.from({ length: 24 }, (_, i) => i + 1), // aktualne 24 lôg
      backgroundIds: Array.from({ length: 7 }, (_, i) => i + 1), // aktualne 7 pozadí
      errors: [],
    }
  },
  computed: {
    ...mapState(useUserStore, {
      selectedCountry: 'getCountry',
    }),
    ...mapState(useCountriesStore, {
      countries: 'getCountries',
    }),
    ...mapState(useClubStore, {
      clubGetter: 'getClubInfo',
    }),
    getCountriesOptions(): Country[] {
      const data = formatCountries(this.countries)
      return data
    },
    getClubTypesOptions(): { value: string; name: string }[] {
      return [
        { value: CLUB_TYPES.open, name: this.$t('club.open') },
        { value: CLUB_TYPES.request, name: this.$t('club.onDemand') },
        { value: CLUB_TYPES.closed, name: this.$t('club.close') },
      ]
    },

    // TODO: refactor this filter
    getDeleteClubPopupData(): PopupData {
      return this.$filters.$submitPopupData({
        title: this.$t('club.deleteClub'),
        btnText: null,
        btnType: 'danger',
        btnId: 'confirm-delete-club-button',
        text: this.$t('club.deleteConfirm'),
      })
    },
  },
  async created(): Promise<void> {
    if (this.$router.currentRoute.value.name === this.$getWebView('ClubsEditClub')) {
      await this.loadClubAction()
      this.club = this.clubGetter
    } else {
      this.club = {
        ...this.club,
        country: this.selectedCountry,
        type: CLUB_TYPES.open,
        memberMinimumLevel: CLUB_LEVELS._5,
      }
    }
  },
  methods: {
    getClubRequiredLevels,
    ...mapActions(useUserStore, {
      loadUserData: 'loadUserData',
    }),
    ...mapActions(useClubStore, {
      loadClubAction: 'loadClubInfo',
      deleteClubAction: 'deleteClub',
      clearUserClub: 'clearUserClub',
    }),
    validateFields(): ComponentData['errors'] {
      this.errors = []
      if (this.club.name.length < 3) {
        this.errors.push({ errorField: 'clubName' })
      }

      return this.errors
    },
    onSubmit(): void {
      this.validateFields()
      if (this.errors.length === 0) {
        this.$emit('submit', this.club)
      }
    },
    getTooltip(type): string {
      if (type === 'clubName') {
        return this.$replacePlaceholder(
          this.$replacePlaceholder(this.$t('club.clubNameRules'), '{min}', 3),
          '{max}',
          25,
        )
      } else if (type === 'clubDescription') {
        return this.$replacePlaceholder(this.$t('club.clubDescriptionRules'), '%s', 255)
      } else return ''
    },
    async deleteClub(): Promise<void> {
      await this.deleteClubAction(this.club.id)
      this.showDeletePopup = false
      await this.$router.push({ name: 'LayoutView' })
      this.clearUserClub()
      this.loadUserData()
    },
  },
})
</script>

<style lang="scss">
#editClubForm {
  width: 99%;
}

.club-info-row {
  &-input {
    width: 100% !important;
    height: 5rem;

    &::before,
    &::after {
      border: none !important;
    }

    input {
      background: rgba(21, 36, 54, 0.8);
      border: 1px solid rgba(68, 122, 165, 0.36);
      text-align: center;
    }

    &-icon {
      @if $isWsm {
        transform: skew(-3deg);
      }
    }
  }

  &-logos {
    width: 82%;
    transform: translate(0, 0);
    @if $isWsm {
      background: linear-gradient(90deg, transparent, rgba(13, 38, 69, 0.71), transparent);
    }
    @if $isSsm {
      background: linear-gradient(90deg, transparent, rgba(124, 123, 178, 0.7), transparent);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.125rem;
      @if $isWsm {
        background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
      }
      @if $isSsm {
        background: linear-gradient(90deg, transparent, rgba(#ffffff, 0.5), transparent);
      }
    }
  }
}
</style>
