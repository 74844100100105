<template>
  <div class="zoom-controls">
    <div class="zoom-controls-value">
      <p class="uppercase font-bold text-36 text-texts-standard-default">{{ getZoomValue }}%</p>
    </div>
    <div class="flexing zoom-controls-buttons">
      <app-button
        class="text-40 zoom-controls-buttons-item"
        btn-type="inactive"
        btn-size="sm"
        @click="zoomIn"
      >
        <span class="revert-skew font-bold">+</span>
      </app-button>

      <app-button
        class="text-40 zoom-controls-buttons-item"
        btn-type="inactive"
        btn-size="sm"
        @click="zoomOut"
      >
        <span class="revert-skew font-bold">-</span>
      </app-button>
    </div>
    <p class="zoom-controls-reset text-texts-standard-name text-30 uppercase" @click="zoomReset">
      Reset
    </p>
  </div>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'

export default defineComponent({
  name: 'ZoomControls',
  computed: {
    ...mapState(usePhaserGameIntegrationStore, ['getZoomLevel']),
    isClubMap(): boolean {
      return this.$route.path.includes('clubs')
    },
    getZoomValue(): string {
      const value = (Math.round(Number(this.getZoomLevel) * 100) / 100).toFixed(2)
      const output = value.slice(2)

      if (Number(value) >= 1) {
        return value.charAt(0) + output
      }

      return output
    },
  },

  methods: {
    ...mapActions(usePhaserGameIntegrationStore, ['setZoomIn', 'setZoomOut', 'setZoomReset']),
    zoomIn(): void {
      this.setZoomIn()
    },
    zoomOut(): void {
      this.setZoomOut()
    },
    zoomReset(): void {
      this.setZoomReset()
    },
  },
})
</script>

<style lang="scss" scoped>
.zoom-controls {
  &-value {
    background: linear-gradient(to right, transparent, #236d9b, transparent);
    height: 3.125rem;
    width: 100%;
    padding-top: 0.1rem;
  }

  &-buttons {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 1.3rem;

    &-item {
      width: 4.375rem;
      height: 4.375rem;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }

  &-reset {
    text-decoration: underline;
    cursor: pointer;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
}
</style>
