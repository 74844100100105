<template>
  <header
    class="event-pass-header flexing w-full mb-2 z-1 absolute top-0 left-0"
    :class="{ 'is-premium': $route.meta.isPremiumRoute }"
    :data-event-theme="namespace"
  >
    <p
      class="gradient-text-light font-bold italic uppercase"
      :class="$route.meta.isPremiumRoute ? 'text-34' : 'text-36'"
    >
      {{ $te('eventPassInfo') }}
    </p>
  </header>
</template>

<script lang="ts">
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EventPass1Header',
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='challenge_cup'] {
  --events-passes-sub-header-bg: url('#{$path-events}challenge-cup/passes/event-pass-sub-header-bg.webp?v=2');
}

.event-pass-header {
  height: 5.875rem;
  @include background(var(--events-passes-sub-header-bg), contain);

  &.is-premium {
    height: 4.375rem;
    background-image: none;
  }
}
</style>
