<template>
  <!-- TODO: change naming of this component - why is it info popup? -->
  <div>
    <div v-if="false">
      <!-- POZOOOOR !!!!  v prípade ze dakedy v auguste stale budu vyzerat confirm okna a info okna velmi podobne
     tak mozme ich zjednotit ako jeden komponent.
     Zatial nechame dva aby sme mali lepsiu flexibilitu a menej podmienok v kode ak by vymyslali zasa hovadiny  PS.
     pred vydanim hry vymazat takyto komentarik - alebo nastavit webpack nech maze defaultne? :D  -->
    </div>
    <teleport to="#app">
      <div
        class="popup-overlay fixed top-0 left-0"
        :class="{ 'popup-overlay--bg-blur': unpackedClass.includes('overlay-bg-blur') }"
        @click="closePopup"
      />
      <div
        class="info-popup-box absolute left-1/2 top-1/2 flex flex-col items-center"
        :style="customSize"
        :class="[
          unpackedClass,
          { 'complete-border': completeTask },
          { 'padd-cancel': popupPad },
          { 'web-scale': !$isMobile() },
        ]"
      >
        <slot v-if="$slots.header" name="header" />
        <popup-header v-else :title-text="popupTitle" @close="closePopup" />
        <div class="info-popup-box-content">
          <slot />

          <div v-if="$slots.description" class="info-popup-box-description flexing">
            <slot name="description" />
          </div>

          <div v-if="$slots.buttons" class="info-popup-box-buttons flexing">
            <slot name="buttons" />
          </div>
        </div>
      </div>
      <video
        v-if="isAnimatedOverlay"
        ref="video"
        class="video-footage video-footage--snow"
        autoplay
        loop
        muted
        playsinline
        poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAArEAAAKxAFmbYLUAAAADUlEQVQImWP4//8/AwAI/AL+hc2rNAAAAABJRU5ErkJggg=="
      >
        <!-- If the browser's video player is unable to play the MP4 file, nothing will be rendered except the poster image (transparent  1x1px). -->
        <source type="video/mp4" :src="`${pathImages}videos/wsm-snowfall--medium.mp4`" />
      </video>
    </teleport>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType, CSSProperties } from 'vue'
import PopupHeader from './PopupHeader.vue'
import { pathImages, GameType } from '@/globalVariables'

interface ComponentData {
  currentGame: string
  GameType: typeof GameType
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'InfoPopup',
  components: {
    PopupHeader,
  },
  props: {
    classList: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    completeTask: {
      type: Boolean,
      default: false,
    },
    popupTitle: {
      type: String,
      default: 'Info',
    },
    popupPad: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      GameType,
      currentGame: this.$gameName,
      pathImages,
    }
  },
  computed: {
    unpackedClass(): string {
      return this.classList.join(',')
    },
    customSize(): CSSProperties {
      const size = {} as CSSProperties
      if (this.width) size.width = this.width
      if (this.height) size.height = this.height

      return size
    },
    isAnimatedOverlay(): boolean {
      return this.unpackedClass.includes('animated-overlay') && this.currentGame === GameType.Winter
    },
  },
  methods: {
    closePopup(): void {
      this.$emit('close', false)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/popups.scss';

.info-popup-box {
  &-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0;
  }

  &-description {
    min-height: 14.5rem;
    flex: 1 1 auto;
    padding: 0 2rem;
  }

  &-buttons {
    margin: 1.25rem;
    gap: 1rem;

    :deep() {
      button {
        margin: 0;
        flex: 1;
      }
    }
  }
}

.popup-overlay {
  &--bg-blur {
    backdrop-filter: blur(0.5rem);
  }
}

.scale-popup {
  transform: translate(-50%, -50%) scale(0.85);
}

.video-footage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // Full screen video setting regardless of its resolution
  object-fit: cover;
  opacity: 0.4;
  z-index: 2;
}
</style>
