import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const cableCarSlopePath: PathObjectInterface = {
  mainPath: {
    x: 382,
    y: 545,
  },
  nextPaths: [
    {
      x: -10,
      y: 210,
      control1X: 128,
      control1Y: 345,
      control2X: 128,
      control2Y: 345,
    },
  ],
}
