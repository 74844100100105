<template>
  <div class="app-page-wrapper absolute" data-cy="staroftheday-athlete-wrapper">
    <div class="staroftheday-athlete w-full h-full absolute flex flex-col items-center">
      <menu-component
        menu-type="training"
        :title="$t('map.starOfTheDayName')"
        :menu-items="{
          1: {
            text: $t('benefits.dailyLeague'),
            route: $getWebView('StarofthedayDisciplines'),
          },
          2: {
            text: $t('starOfTheDay.athleteOfTheDay'),
            route: $getWebView('StarofthedayAthlete'),
          },
        }"
      />

      <section v-if="standingsData" class="w-full h-full flex flex-col items-center safe-area">
        <template v-if="standingsData.state === IN_PROGRESS">
          <header class="text-32 uppercase flex justify-between items-center">
            <app-timer
              v-tippy="{
                content: $t('starOfTheDay.timeToEnd'),
                placement: 'right',
                theme: 'WSM',
              }"
              :time="standingsData.time_remaining"
              icon="refresh"
              @countdown-action="loadData"
            />
            <app-icon
              v-tippy="{
                content: $t('starOfTheDay.athleteOfTheDayInfo'),
                placement: 'left',
                theme: 'WSM',
                trigger: 'click',
              }"
              icon-name="info-60"
            />
          </header>
          <staroftheday-podium
            :view-type="DailyLeagueViewType.Athlete"
            :podium-data="standingsData?.standings?.slice(0, 3)"
          />
          <div
            class="staroftheday-athlete-table-head table-head-standard flex items-center relative"
          >
            <p class="staroftheday-athlete-table-head-pos">
              {{ $t('rankingsGroup.pos') }}
            </p>
            <p class="staroftheday-athlete-table-head-name">
              {{ $t('common.name') }}
            </p>
            <p class="staroftheday-athlete-table-head-points">
              {{ $t('rankingsGroup.rankingPoints') }}
            </p>
            <p class="staroftheday-athlete-table-head-rewards">
              {{ $t('common.reward') }}
            </p>
          </div>
          <app-scrollbar v-if="true" width="117.5rem" height="27rem" scroll="y" slide="y">
            <div class="table-standard">
              <staroftheday-table-row
                v-for="(player, index) in standingsData.standings"
                :key="index"
                :view-type="DailyLeagueRowViewType.Athlete"
                :rank="index + 1"
                :player-data="player"
              />
            </div>
          </app-scrollbar>
          <staroftheday-my-result-row
            :view-type="DailyLeagueRowViewType.Athlete"
            :my-result="standingsData.playerResults"
          />
        </template>
        <staroftheday-locked
          v-else
          :time="standingsData.time_remaining"
          :timer-tooltip="$t('starOfTheDay.timeToStart')"
        />
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import StarofthedayLocked from '@/components/Staroftheday/StarofthedayLocked.vue'
import StarofthedayMyResultRow from '@/components/Staroftheday/StarofthedayMyResultRow.vue'
import StarofthedayPodium from '@/components/Staroftheday/StarofthedayPodium.vue'
import StarofthedayTableRow from '@/components/Staroftheday/StarofthedayTableRow.vue'
import {
  IN_PROGRESS,
  STAR_OF_THE_DAY_ATHLETE,
  dailyLeagueAthleteEndpoint,
  pathImages,
} from '@/globalVariables'
import { useStarOfTheDayStore } from '@/store/pinia/starOfThedayStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'
import { DailyLeagueViewType, DailyLeagueRowViewType } from '@/interfaces/DailyLeague'
import type { AthleteOfTheDayApiResponse } from '@/interfaces/responses/starOfTheDay/AthleteOfTheDay'

interface ComponentData {
  DailyLeagueViewType: typeof DailyLeagueViewType
  DailyLeagueRowViewType: typeof DailyLeagueRowViewType
  STAR_OF_THE_DAY_ATHLETE: typeof STAR_OF_THE_DAY_ATHLETE
  IN_PROGRESS: typeof IN_PROGRESS
  pathImages: typeof pathImages
  standingsData: AthleteOfTheDayApiResponse
}

export default defineComponent({
  name: 'StarofthedayStandings',
  components: {
    StarofthedayLocked,
    StarofthedayPodium,
    StarofthedayTableRow,
    StarofthedayMyResultRow,
  },
  data(): ComponentData {
    return {
      DailyLeagueViewType,
      DailyLeagueRowViewType,
      STAR_OF_THE_DAY_ATHLETE,
      IN_PROGRESS,
      pathImages,
      standingsData: null,
    }
  },
  created(): void {
    this.loadData()
    this.loadConfig()
  },
  methods: {
    ...mapActions(useStarOfTheDayStore, ['loadConfig']),
    async loadData(): Promise<void> {
      this.standingsData = await this.$axios.get<{}, AthleteOfTheDayApiResponse>(
        dailyLeagueAthleteEndpoint,
      )
    },
  },
})
</script>

<style lang="scss">
.staroftheday-athlete {
  .table-standard-row {
    width: 117.5rem;
    margin-bottom: 0.375rem;
    height: 4rem;

    &.my.my-staroftheday {
      margin-bottom: 1.625rem;
    }
  }

  header {
    width: 117.5rem;
    height: 3.625rem;
    margin: 0.875rem 0;
  }

  &-podium {
    width: 117.5rem;
    height: 14.375rem;

    &-rank {
      position: relative;

      .rank-label {
        position: absolute;
        right: 1.125rem;
        bottom: 0.875rem;

        .ranking-points {
          width: 20.625rem;
          height: 4.0625rem;
          margin: 1.25rem 0 0;
          padding: 1.25rem 1.625rem 0.9375rem 1.5625rem;
          box-shadow:
            inset -7px 0px 21px 0 rgba(51, 123, 214, 0.19),
            inset -0.3px -3px 40px 0 rgba(6, 34, 55, 0.48);
          border-width: 0.125rem;
          border-image-source: linear-gradient(to top, #fff284, #f6c717);
          border-image-slice: 1;
          background-color: #0f3251;
          transform: $skewX-value;
          font-style: italic;
          color: #fff;

          .points {
            font-size: 2.25rem;
            font-weight: bold;
          }

          .points-text {
            font-size: 1.75rem;
          }
        }

        .name-text {
          font-style: italic;
          color: #233441;
          font-size: 2rem;
          font-weight: bold;
        }
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: top;
        transform: translate(0, -50%);
      }
    }

    .rank-1 {
      width: 41.25rem;
      height: 14.375rem;
      background: url($path-images + 'staroftheday/BG_GOLD.avif') center no-repeat;
      margin: 0 1.125rem;
      background-size: cover;

      &::before {
        width: 16.6875rem;
        height: 18.5rem;
        background: url($path-images + 'staroftheday/TROPHY_GOLD.avif') center no-repeat;
        background-size: contain;
        left: 1.3125rem;
      }

      &::after {
        width: 2.4375rem;
        height: 5.75rem;
        background: url($path-images + 'staroftheday/NUMBER_GOLD.avif') center no-repeat;
        background-size: contain;
        right: 10.25rem;
      }
    }

    .rank-2 {
      width: 36.25rem;
      height: 11.875rem;
      background: url($path-images + 'staroftheday/BG_SILVER.avif') center no-repeat;
      background-size: cover;

      &::before {
        width: 13.25rem;
        height: 16.375rem;
        background: url($path-images + 'staroftheday/TROPHY_SILVER.avif') center no-repeat;
        background-size: contain;
        left: 0.4375rem;
      }

      &::after {
        width: 3.5625rem;
        height: 4.4375rem;
        background: url($path-images + 'staroftheday/NUMBER_SILVER.avif') center no-repeat;
        background-size: contain;
        right: 9.625rem;
      }
    }

    .rank-3 {
      width: 36.25rem;
      height: 11.875rem;
      background: url($path-images + 'staroftheday/BG_BRONZE.avif') center no-repeat;
      background-size: cover;

      &::before {
        width: 13.25rem;
        height: 16.375rem;
        background: url($path-images + 'staroftheday/TROPHY_BRONZE.avif') center no-repeat;
        background-size: contain;
        left: 0.4375rem;
      }

      &::after {
        width: 5.3125rem;
        height: 4.4375rem;
        background: url($path-images + 'staroftheday/NUMBER_BRONZE.avif') center no-repeat;
        background-size: contain;
        right: 9.375rem;
      }
    }
  }

  &-table-head {
    width: 117.5rem;
    margin-top: 0.8125rem;
    background: #09172a;
    text-transform: none;
    margin-bottom: 0.6875rem;

    &-pos {
      width: 4.938rem;
    }

    &-name {
      text-align: start;
      padding-left: 11rem;
      width: 55rem;
    }

    &-points {
      width: 18rem;
    }

    &-rewards {
      width: 40rem;
      position: absolute;
      right: 0;
    }
  }

  &-table {
    &-pos {
      width: 4.938rem;
      background: rgba(20, 56, 88, 0.5);
      height: 100%;
      position: relative;

      &:before {
        content: '';
        height: 100%;
        width: 1rem;
        position: absolute;
        right: -0.975rem;
        background: rgba(20, 56, 88, 0.5);
        clip-path: polygon(0% 0%, 100% 0%, 0% 100%, 0% 100%);
      }

      &.gold {
        background: linear-gradient(to top, #e9d27a, #f6c717);

        &:before {
          background: linear-gradient(to top, #e9d27a, #f6c717);
        }
      }

      &.silver {
        background: linear-gradient(to top, #8e8e8e, #dbdbdb);

        &:before {
          background: linear-gradient(to top, #8e8e8e, #dbdbdb);
        }
      }

      &.bronze {
        background: linear-gradient(to top, #b75a32, #ef9f73);

        &:before {
          background: linear-gradient(to top, #b75a32, #ef9f73);
        }
      }

      &.my-staroftheday {
        background-color: #233441;

        &:before {
          background-color: #233441;
        }
      }
    }

    &-player {
      width: 56rem;
      justify-content: flex-start;
      padding-left: 2rem;

      &-country {
        width: 3.75rem;
        font-size: 1.4375rem;
        font-style: italic;
        font-weight: bold;
        margin: 1.8rem 0 0.875rem 0;
      }

      &-flag {
        background-repeat: no-repeat;
        background-size: contain;
        width: 3.15rem;
        min-width: 3.15rem;
        height: 2.2rem;
        margin: 1.25rem 1rem 0 0.5rem;
      }
    }

    &-points {
      width: 16rem;
    }

    &-rewards {
      width: 40rem;
    }
  }
}
</style>
