<template>
  <div class="equipment-detail-footer-task-tooltip">
    <header>
      <p class="text-texts-standard-default text-32">
        {{ $t('equipmentShop.finishQuestsToUnlock') }}
      </p>
      <div class="gray-strip" />
    </header>
    <main>
      <div
        v-for="(level, i) in getTaskUnlockInfo"
        :key="i"
        class="flex items-center justify-between my-4"
      >
        <div class="task-tooltip-stars flex">
          <div v-for="star in level.star" :key="star" :class="'icon-star-empty-48'" />
        </div>
        <p class="text-texts-standard-default text-32">
          {{ $replacePlaceholder($t('common.taskNumber'), '%s', level.task) }}
        </p>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { useEquipmentStore } from '@/store/pinia/equipment/equipmentStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EquipmentDetailFooterTaskTooltip',
  computed: {
    ...mapState(useEquipmentStore, {
      getTaskUnlockInfo: 'getTaskUnlockInfo',
    }),
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/stars-icons.scss';

.equipment-detail-footer-task-tooltip {
  header {
    .gray-strip {
      width: 90%;
      height: 0.094rem;
      margin: 1rem auto;
    }
  }
}
</style>
