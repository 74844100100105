<template>
  <div
    class="lab-connection-line absolute"
    :class="[`direction-${direction}`, topHeight, { special: lineStyle === 'special' }]"
  >
    <div v-if="direction !== LineDirection.Top" class="flex flex-col">
      <section class="line-part line-part-1" />
      <section class="line-part line-part-2" />
    </div>
    <div v-else class="direction-top-line" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { LineDirection, LineHeight, LineStyle } from '@/interfaces/lab/Cell'

interface ComponentData {
  LineDirection: typeof LineDirection
}

export default defineComponent({
  name: 'LabConnectionLine',
  props: {
    direction: {
      type: String as PropType<LineDirection>,
      required: true,
      validator(value: LineDirection): boolean {
        return [LineDirection.Top, LineDirection.Left, LineDirection.Right].includes(value)
      },
    },
    lineStyle: {
      type: String as PropType<LineStyle>,
      default: LineStyle.Normal,
      validator(value: LineStyle): boolean {
        return [LineStyle.Normal, LineStyle.Special].includes(value)
      },
    },
    topHeight: {
      type: String as PropType<LineHeight>,
      default: LineHeight.Xs,
      validator(value: LineHeight): boolean {
        return [LineHeight.Xs, LineHeight.Sm, LineHeight.Md, LineHeight.Xl].includes(value)
      },
    },
  },
  data(): ComponentData {
    return {
      LineDirection,
    }
  },
})
</script>

<style lang="scss" scoped>
.lab-connection-line {
  left: 3.7rem;
  top: -1.6rem;
  width: 10rem;
  .line-part {
    border-width: 0.188rem;
    border-color: #b8d6f4;
  }
  .direction-top-line {
    width: 0.188rem;
    background-color: #b8d6f4;
  }
  &.direction-top {
    &.xs {
      .direction-top-line {
        height: 2.2rem;
      }
    }
    &.sm {
      top: -5.9rem;
      .direction-top-line {
        height: 6.3rem;
      }
    }
    &.md {
      top: -9.9rem;
      .direction-top-line {
        height: 10.3rem;
      }
    }
    &.xl {
      top: -31.3rem;
      .direction-top-line {
        height: 32rem;
      }
    }
  }
  &.special {
    .direction-top-line {
      background-color: #fbdb5b;
      width: 0.313rem;
    }
    .line-part {
      border-color: #fbdb5b;
      border-width: 0.313rem;
      z-index: 1;
    }
  }
  &.direction-right {
    .line-part-1 {
      height: 0.75rem;
      border-top-width: 0;
      border-bottom-width: 0;
      border-left-width: 0;
    }
    .line-part-2 {
      height: 1.438rem;
      border-bottom-width: 0;
      border-right-width: 0;
    }
  }
  &.direction-left {
    left: -6rem;
    .line-part-1 {
      height: 0.75rem;
      border-top-width: 0;
      border-bottom-width: 0;
      border-right-width: 0;
    }
    .line-part-2 {
      height: 1.438rem;
      border-bottom-width: 0;
      border-left-width: 0;
    }
  }
}
</style>
