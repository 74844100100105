import type { CameraConfigInterface } from '@/map-phaser-new/interfaces/camera/cameraConfigInterface'

export const cameraConfig: CameraConfigInterface = {
  breakpointMobile: 1000,
  breakpointTablet: 1370,
  breakpoint4K: 3200,
  breakpoint2K: 2400,
  zoomChangeValue: 0.05,
  zoomLevels: {
    subTwo: {
      // done - testovane na apple macbooku
      WebDefault: {
        defaultZoom: 0.7,
        minZoom: 0.2,
        maxZoom: 1.0,
        defaultZoomApple: 0.7,
        minZoomApple: 0.2,
        maxZoomApple: 1.0,
      },
      // done - testovane na apple macbooku
      Web2k: {
        defaultZoom: 0.9,
        minZoom: 0.2,
        maxZoom: 1.1,
        defaultZoomApple: 0.9,
        minZoomApple: 0.2,
        maxZoomApple: 1.1,
      },
      // done - testovane na apple macbooku
      Web4k: {
        defaultZoom: 1.3,
        minZoom: 0.4,
        maxZoom: 1.8,
        defaultZoomApple: 1.3,
        minZoomApple: 0.3,
        maxZoomApple: 1.5,
      },
      MobileDefault: {
        defaultZoom: 1.1,
        minZoom: 0.17,
        maxZoom: 1.2,
        defaultZoomApple: 1.1,
        minZoomApple: 0.17,
        maxZoomApple: 1.2,
      },
      // done - apple SE,XR, done android
      MobileSmall: {
        defaultZoom: 0.5,
        minZoom: 0.12,
        maxZoom: 0.7,
        defaultZoomApple: 0.6,
        minZoomApple: 0.2,
        maxZoomApple: 0.7,
      },
      // ipad pro, mini, air done , andorid - done
      Tablet: {
        defaultZoom: 0.7,
        minZoom: 0.1,
        maxZoom: 1.0,
        defaultZoomApple: 0.95,
        minZoomApple: 0.16,
        maxZoomApple: 1.3,
      },
    },
    subThree: {
      WebDefault: {
        defaultZoom: 0.65,
        minZoom: 0.15,
        maxZoom: 0.9,
        defaultZoomApple: 0.65,
        minZoomApple: 0.15,
        maxZoomApple: 1.2,
      },
      Web2k: {
        defaultZoom: 0.9,
        minZoom: 0.15,
        maxZoom: 1.2,
        defaultZoomApple: 0.9,
        minZoomApple: 0.15,
        maxZoomApple: 1.2,
      },
      Web4k: {
        defaultZoom: 1.5,
        minZoom: 0.4,
        maxZoom: 1.8,
        defaultZoomApple: 1.5,
        minZoomApple: 0.4,
        maxZoomApple: 1.8,
      },
      MobileDefault: {
        defaultZoom: 1.1,
        minZoom: 0.17,
        maxZoom: 1.2,
        defaultZoomApple: 1.1,
        minZoomApple: 0.17,
        maxZoomApple: 1.2,
      },
      // android done - pixel 7, z fold , iphone done
      MobileSmall: {
        defaultZoom: 0.8,
        minZoom: 0.15,
        maxZoom: 1.2,
        defaultZoomApple: 0.8,
        minZoomApple: 0.05,
        maxZoomApple: 1.05,
      },
      Tablet: {
        defaultZoom: 1.2,
        minZoom: 0.17,
        maxZoom: 1.5,
        defaultZoomApple: 1.2,
        minZoomApple: 0.17,
        maxZoomApple: 1.5,
      },
    },
    aboveThree: {
      WebDefault: {
        defaultZoom: 0.65,
        minZoom: 0.15,
        maxZoom: 0.9,
        defaultZoomApple: 0.65,
        minZoomApple: 0.15,
        maxZoomApple: 0.9,
      },
      Web2k: {
        defaultZoom: 0.9,
        minZoom: 0.15,
        maxZoom: 1.2,
        defaultZoomApple: 0.9,
        minZoomApple: 0.15,
        maxZoomApple: 1.2,
      },
      Web4k: {
        defaultZoom: 1.5,
        minZoom: 0.4,
        maxZoom: 1.8,
        defaultZoomApple: 1.5,
        minZoomApple: 0.4,
        maxZoomApple: 1.8,
      },
      MobileDefault: {
        defaultZoom: 1.1,
        minZoom: 0.17,
        maxZoom: 1.2,
        defaultZoomApple: 1.1,
        minZoomApple: 0.17,
        maxZoomApple: 1.2,
      },
      // android done - samsung s20 ultra, iphone 12,14
      MobileSmall: {
        defaultZoom: 1.15,
        minZoom: 0.3,
        maxZoom: 1.5,
        defaultZoomApple: 0.95,
        minZoomApple: 0.15,
        maxZoomApple: 1.2,
      },
      Tablet: {
        defaultZoom: 1.2,
        minZoom: 0.17,
        maxZoom: 1.5,
        defaultZoomApple: 1.2,
        minZoomApple: 0.17,
        maxZoomApple: 1.5,
      },
    },
  },
}
