<template>
  <avatar-menu @show-att="showAttribute = true" />
  <attribute-menu
    v-if="!actualStageData"
    :show="showAttribute"
    @hide-attribute="showAttribute = false"
  />
  <div class="map-left-wrapper">
    <map-task-tracker
      v-if="
        hasMechanic(MECHANIC_CAREER) || (actualStageData && !actualStageData.isTaskTrackerHidden)
      "
    />
    <left-box />
  </div>

  <right-box />
  <season-box />
  <layout-news />
  <account-deletion />
</template>

<script lang="ts">
import AttributeMenu from '@/components/AttributeMenu.vue'
import AvatarMenu from '@/components/AvatarMenu.vue'
import LeftBox from '@/components/Layout/LeftBoxComponent.vue'
import RightBox from '@/components/Layout/RightBox.vue'
import SeasonBox from '@/components/Layout/SeasonBox.vue'
import MapTaskTracker from '@/components/MapTaskTracker/index.vue'
import LayoutNews from '@/components/News/LayoutNews.vue'
import AccountDeletion from '@/components/Settings/AccountDeletion.vue'
import { MECHANIC_CAREER, MECHANIC_CLUB } from '@/globalVariables'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { useUserStore } from '@/store/pinia/userStore'
import { useClubStore } from '@/store/pinia/clubs/clubStore'

interface ComponentData {
  showAttribute: boolean
  MECHANIC_CAREER: typeof MECHANIC_CAREER
}

export default defineComponent({
  name: 'MainMapLayout',
  components: {
    AvatarMenu,
    SeasonBox,
    AttributeMenu,
    LayoutNews,
    MapTaskTracker,
    RightBox,
    AccountDeletion,
    LeftBox,
  },
  data(): ComponentData {
    return {
      showAttribute: false,
      MECHANIC_CAREER,
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
    }),
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanicLocalStorage',
    }),
    ...mapState(useUserStore, {
      hasClub: 'getHasClub',
      myClubId: 'getPlayerClubId',
    }),
    ...mapState(useClubStore, {
      getClubInfo: 'getClubInfo',
    }),
  },
  watch: {
    $route(): void {
      if (this.$route.name === 'LayoutView') this.loadUserClub()
    },
  },
  created() {
    this.loadUserClub()
  },
  methods: {
    ...mapActions(useClubStore, {
      loadClubInfo: 'loadClubInfo',
    }),
    async loadUserClub(): Promise<void> {
      if (
        this.hasMechanic(MECHANIC_CLUB) &&
        this.hasClub &&
        this.getClubInfo?.id !== this.myClubId
      ) {
        await this.loadClubInfo()
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.map-left-wrapper {
  position: absolute;
  top: 21.8125rem;
  left: $default-margin;
  height: auto;
  pointer-events: none !important;
}
</style>
