<template>
  <div v-if="state !== null" class="convertor">
    <convertor-side type="deconstructor" :is-tier-3="isTier3" />
    <div class="convertor__middle">
      <div class="convertor__middle-heading"></div>
      <div class="convertor__middle-circle-group">
        <div class="frozen-essence" />
      </div>
      <div class="convertor__middle-number-label">
        <div class="number-block">
          <animated-number
            class="text-50 font-bold gradient-text-gold uppercase h-16"
            :class="{
              '-mt-2': $isSsm,
            }"
            :param="{ value: universalIngredient?.amount }"
            :show-max-value="false"
            :show-progress-bar="false"
          />
        </div>
      </div>
    </div>
    <convertor-side type="manufacture" :state="state" :is-tier-3="isTier3" @set-state="setState" />
  </div>

  <component-loading :is-loading="state === null" height="100%" />
</template>

<script lang="ts">
import AnimatedNumber from '@/components/AnimatedNumber.vue'
import ConvertorSide from '@/components/Events/EventsWarehouse/Convertor/ConvertorSide.vue'
import { pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'

import type { IngredientAmount } from '@/interfaces/responses/events/warehouse/WarehouseSharedInterface'
import { useWarehouseStore } from '@/store/pinia/events/warehouseStore'
import { mapActions, mapState } from 'pinia'

export enum OperationStates {
  Default = 'default',
  Production = 'production',
  Claim = 'claim',
}

interface ComponentData {
  OperationStates: typeof OperationStates
  pathImages: typeof pathImages
  state: string
}

export default defineComponent({
  name: 'ConvertorMain',
  components: {
    ConvertorSide,
    AnimatedNumber,
  },
  data(): ComponentData {
    return {
      OperationStates,
      pathImages,
      state: null,
    }
  },
  computed: {
    ...mapState(useWarehouseStore, {
      getIngredientsState: 'getIngredientsState',
      getConvertingState: 'getConvertingState',
      isFreeForward: 'isFreeForward',
    }),
    universalIngredient(): IngredientAmount {
      if (!this.getIngredientsState) return

      return this.getIngredientsState[this.getIngredientsState.length - 1]
    },
    isTier3(): boolean {
      return this.isFreeForward ?? false
    },
  },
  async created(): Promise<void> {
    await Promise.all([this.loadStoreConfig(), this.loadStoreState()])

    this.setDefaultState()
  },
  methods: {
    ...mapActions(useWarehouseStore, {
      loadStoreConfig: 'loadConfig',
      loadStoreState: 'loadState',
    }),
    setState(state: OperationStates): void {
      this.state = state
    },
    setDefaultState(): void {
      if (this.getConvertingState === undefined) return

      if (
        !this.getConvertingState.converting &&
        this.getConvertingState.converting_operation !== null
      ) {
        this.state = OperationStates.Claim
      } else if (
        this.getConvertingState.converting &&
        this.getConvertingState.converting_operation !== null
      ) {
        this.state = OperationStates.Production
      } else {
        this.state = OperationStates.Default
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.convertor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;

  &__middle {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30.5rem;
    padding-top: 1.5rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0.5;
      background-image: linear-gradient(
        to top,
        rgba(1, 0, 29, 0),
        #01001d,
        #01001d,
        rgba(1, 0, 29, 0.98),
        rgba(1, 0, 29, 0)
      );
      width: 100%;
      height: 37.438rem;
      z-index: 0;
    }

    &-heading {
      position: relative;
      width: 100%;
      height: 5.375rem;
      margin-top: 0.625rem;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 5.875rem;
        @include background(
          url($path-events + 'frozen-championship/warehouse/convertor/frozen-essence-txt-bg.avif'),
          cover
        );
        opacity: 0.6;
        z-index: 0;
      }

      &::after {
        content: '';
        position: absolute;
        top: calc(50% + 0.35rem);
        left: 50%;
        width: 27.75rem;
        height: 4.875rem;
        @include background(
          url($path-events + 'frozen-championship/warehouse/convertor/frozen-essence-txt.avif'),
          cover
        );
        transform: translateX(-50%) translateY(-50%);
        z-index: 1;
      }
    }

    &-circle-group {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: baseline;
      width: 30.5rem;
      height: 19.938rem;
      @include background(
        url($path-events + 'frozen-championship/warehouse/convertor/frozen-essence-bg.avif'),
        cover
      );

      .frozen-essence {
        position: relative;
        width: 18.625rem;
        height: 18.938rem;
        @include background(
          url($path-events + 'frozen-championship/warehouse/convertor/frozen-essence-img.avif'),
          170%
        );
      }
    }

    &-number-label {
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 5.375rem;
      top: -1rem;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 5.875rem;
        @include background(
          url($path-events + 'frozen-championship/warehouse/convertor/ice-block-bg.avif'),
          cover
        );
        z-index: 0;
      }

      .number-block {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2.063rem 0 1.438rem;
        position: relative;
        width: 14.125rem;
        height: 5.75rem;
        @include background(
          url($path-events + 'frozen-championship/warehouse/convertor/ice-block.avif'),
          cover
        );

        @if $isWsm {
          margin-top: 0.5rem;
        }
      }
    }
  }
}
</style>
