<template>
  <div class="app-page-wrapper profile absolute flex text-texts-standard-default text-lg uppercase">
    <section class="w-full h-full safe-area">
      <div
        class="profile-flag-background absolute w-full h-full top-0 left-0"
        :class="selectedCountry ?? country"
        :style="{
          background:
            'url(' +
            pathImages +
            'flags/bg-flags-2400x1080/bg_flag_' +
            selectedCountry +
            '.avif)center no-repeat',
          backgroundSize: 'cover',
        }"
      />
      <div class="profile__container">
        <section
          ref="animation1"
          class="profile-header relative flex items-center animated-element--faded"
        >
          <div class="profile-header-info cursor-pointer">
            <app-icon
              v-tippy="{
                theme: 'WSM',
                content: $t('map.profile_info'),
                placement: 'right',
              }"
              icon-name="info-70"
            />
          </div>
          <div class="profile-header-title flexing">
            <p>
              {{ $t('map.profile_create') }}
            </p>
          </div>
        </section>
        <section
          ref="animation2"
          class="profile-details flex justify-around items-center animated-element--faded"
        >
          <div class="profile-details-name">
            <div class="profile-section-title profile-details-name-title">
              <p>
                {{ $t('map.name') }}
              </p>
            </div>
            <arrow-animation
              position="right"
              border="box"
              :tutorial="actualStage?.name === 'setUserName'"
            >
              <tippy ref="nameErrorTooltip" theme="RED" placement="top" trigger="manual">
                <app-input
                  id="UserUsername"
                  :value="name"
                  :placeholder="$t('map.name')"
                  :name="'username'"
                  :error="nameError"
                  :is-ok="isUsernameValid"
                  :max-length="MAX_USERNAME_LENGTH"
                  no-tooltip
                  @input="onInputName"
                />
                <template #content>
                  <span v-show="errorType === 'min_length'">
                    {{
                      $replacePlaceholder($t('map.name_too_short'), '{2to4}', MIN_USERNAME_LENGTH)
                    }}
                  </span>
                  <span v-show="errorType === 'max_length'">
                    {{ $replacePlaceholder($t('map.name_too_long'), '{max}', MAX_USERNAME_LENGTH) }}
                  </span>
                  <span v-show="errorType === 'name_invalid'">{{ $t('map.name_invalid') }}</span>
                  <span v-show="errorType === 'name'">{{ $t('map.name_unavailable') }}</span>
                </template>
              </tippy>
            </arrow-animation>
          </div>
          <div class="profile-details-country">
            <div class="profile-section-title profile-details-country-title">
              <p>
                {{ $t('map.country') }}
              </p>
            </div>
            <app-select
              :options="formatedCountries"
              :default="country"
              direction="bottom"
              @select="selectCountry"
            />
          </div>
        </section>
        <section class="profile-avatar">
          <div
            ref="animation3"
            class="profile-section-title profile-avatar-title animated-element--faded"
          >
            <p>
              {{ $t('map.avatar') }}
            </p>
          </div>
          <div ref="animation4" class="profile-avatar-list flexing">
            <avatar-box
              v-for="avatar in avatars"
              :key="avatar.number"
              class="profile-avatar-list-item animated-element--faded"
              :avatar-id="avatar.number"
              :avatar-gender="selectedGender"
              :is-selected="selectedAvatar === avatar.number"
              @on-click="selectAvatar(avatar.number)"
            />
          </div>
        </section>
        <section ref="animation5" class="profile-gender animated-element--faded">
          <div class="profile-section-title profile-gender-title">
            <p>
              {{ $t('map.gender') }}
            </p>
          </div>
          <div class="profile-gender-options flex justify-around items-center">
            <app-button
              btn-size="md"
              :btn-type="selectedGender === 'male' ? 'secondary' : 'inactive'"
              :btn-text="$t('map.gender_male')"
              class="profile-gender-options-button"
              :class="selectedGender === 'male' ? 'selected' : ''"
              @click="selectedGender = 'male'"
            />
            <app-button
              btn-size="md"
              :btn-type="selectedGender === 'female' ? 'secondary' : 'inactive'"
              :btn-text="$t('map.gender_female')"
              class="profile-gender-options-button"
              @click="selectedGender = 'female'"
            />
          </div>
        </section>
        <section
          ref="animation6"
          class="profile-footer flex justify-end items-center animated-element--faded"
        >
          <app-button
            btn-size="md"
            btn-type="secondary"
            :btn-text="$t('button.continue')"
            btn-id="profile-footer-button"
            class="profile-footer-button"
            :loading="isLoading"
            :disabled="
              name.length > MAX_USERNAME_LENGTH || name.length < MIN_USERNAME_LENGTH || isLoading
            "
            @click="createProfile"
          />
        </section>
      </div>

      <video
        v-if="currentGame === GameType.Winter"
        ref="video"
        class="video-footage video-footage--snow"
        autoplay
        loop
        muted
        playsinline
        poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAArEAAAKxAFmbYLUAAAADUlEQVQImWP4//8/AwAI/AL+hc2rNAAAAABJRU5ErkJggg=="
      >
        <!-- If the browser's video player is unable to play the MP4 file, nothing will be rendered except the poster image (transparent  1x1px). -->
        <source type="video/mp4" :src="`${pathImages}videos/wsm-snowfall--medium.mp4`" />
      </video>
    </section>
  </div>
</template>

<script lang="ts">
import ArrowAnimation from '@/components/ArrowAnimation.vue'
import AvatarBox from '@/components/Profile/AvatarBox.vue'
import {
  MAX_USERNAME_LENGTH,
  MIN_USERNAME_LENGTH,
  pathImages,
  userProfileEndpoint,
  userProfileUsernameCheckEndpoint,
  GameType,
} from '@/globalVariables'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { useCountriesStore } from '@/store/pinia/countriesStore'
import { animateElement, playSound, validateUsername } from '@/helpers'
import type { NewValues } from '@/interfaces/User'

interface ComponentData {
  MIN_USERNAME_LENGTH: typeof MIN_USERNAME_LENGTH
  MAX_USERNAME_LENGTH: typeof MAX_USERNAME_LENGTH
  pathImages: typeof pathImages
  nameError: boolean
  isUsernameValid: boolean
  selectedGender: string
  selectedAvatar: number
  name: string
  selectedCountry: string
  isClicked: boolean
  errorType: string
  existingName: boolean
  isLoading: boolean
  GameType: typeof GameType
  currentGame: string
}

export default defineComponent({
  name: 'CreateProfile',
  components: {
    AvatarBox,
    ArrowAnimation,
  },
  data(): ComponentData {
    return {
      MIN_USERNAME_LENGTH,
      MAX_USERNAME_LENGTH,
      pathImages,
      nameError: false,
      isUsernameValid: false,
      selectedGender: 'male',
      selectedAvatar: 1,
      name: '',
      selectedCountry: '',
      isClicked: false,
      errorType: '',
      existingName: false,
      isLoading: false,
      GameType,
      currentGame: this.$gameName,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      country: 'getCountry',
      avatars: 'getUserAvatars',
      userData: 'getUserData',
    }),
    ...mapState(useTutorialStore, {
      actualStage: 'getActualStage',
    }),
    ...mapState(useCountriesStore, {
      formatedCountries: 'getFormattedCountries',
    }),
  },
  watch: {
    name(): void {
      this.isUsernameValid = false
      this.checkUsernameLength()
    },
    avatars(): void {
      if (this.avatars.length) {
        // *must have – for loop in avatar-box before animation
        this.$nextTick((): void => {
          this.animateScene()
        })
      }
    },
    selectedGender(): void {
      const animatedElements = this.$refs.animation4.querySelectorAll('.profile-avatar-list-item')
      animatedElements.forEach((element: HTMLElement, index: number): void => {
        animateElement(element, 'fade-out', '0s', 0)

        const delay = 100 + index * 100
        setTimeout((): void => {
          animateElement(element, 'fade-in', '1s', delay)
        }, 50)
      })
    },
  },
  async created(): Promise<void> {
    await this.loadAvatars()
    this.selectedCountry = this.country
    if (this.actualStage.name === 'clickLevelUpBtn' && this.userData.stats.LEVEL !== 1) {
      this.$router.push('/tutorial/layout')
    }
    playSound('character_creation')
  },
  methods: {
    ...mapActions(useUserStore, ['loadAvatars', 'setUpdatedProfile']),
    ...mapActions(useTutorialStore, ['setValidButtonId']),
    selectAvatar(avatarId: number): void {
      this.selectedAvatar = avatarId
    },
    showTooltip(): void {
      this.$refs.nameErrorTooltip.show()
    },
    hideTooltip(): void {
      this.$refs.nameErrorTooltip.hide()
    },
    checkUsernameLength(): void {
      if (this.name.length < MIN_USERNAME_LENGTH) {
        this.nameError = true
        this.errorType = 'min_length'
        this.showTooltip()
      } else if (this.name.length > MAX_USERNAME_LENGTH) {
        this.nameError = true
        this.errorType = 'max_length'
        this.showTooltip()
      } else {
        this.hideTooltip()
        this.nameError = false
      }
    },
    async validateUsername(): Promise<boolean> {
      if (!validateUsername(this.name)) {
        this.nameError = true
        this.errorType = 'name_invalid'
        this.showTooltip()
        this.isUsernameValid = false
        return false
      }

      let response: boolean
      try {
        response = await this.$axios.post<{}, boolean>(userProfileUsernameCheckEndpoint, {
          username: this.name,
        })
      } catch (error: unknown) {
        console.error(error)
      }
      if (response) {
        this.nameError = true
        this.errorType = 'name'
        this.showTooltip()
        this.isUsernameValid = false
      } else {
        this.hideTooltip()
        this.nameError = false
        this.isUsernameValid = true
      }
      return this.isUsernameValid
    },
    onInputName(name: string): void {
      this.name = name.trim()
    },
    selectCountry(country: string): void {
      this.selectedCountry = country
    },
    async createProfile(): Promise<void> {
      this.isLoading = true
      const isNameAvailable = await this.validateUsername()
      if (isNameAvailable) {
        const updatedProfile = await this.$axios.post<{}, NewValues>(userProfileEndpoint, {
          profile_attributes: [
            {
              name: 'sex',
              value: this.selectedGender,
            },
            {
              name: 'avatar',
              value: this.selectedAvatar,
            },
          ],
          username: this.name,
          country: this.selectedCountry ?? this.country,
        })

        await this.setUpdatedProfile(updatedProfile)
        this.setValidButtonId('profile-footer-button')
      }

      this.isLoading = false
    },
    animateScene(): void {
      animateElement(this.$refs.animation1, 'fade-in, zoom-reset', '0.67s', 133)
      animateElement(this.$refs.animation2, 'fade-in', '0.67s', 267)
      animateElement(this.$refs.animation3, 'fade-in', '0.67s', 400)

      const animatedElements = this.$refs.animation4.querySelectorAll('.profile-avatar-list-item')
      animatedElements.forEach((element: HTMLElement, index: number): void => {
        const delay = 533 + index * 133
        animateElement(element, 'fade-in', '0.67s', delay)
      })

      animateElement(this.$refs.animation5, 'fade-in', '0.67s', 1333)
      animateElement(this.$refs.animation6, 'fade-in', '0.67s', 1667)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/profile/profile.scss';
@import '@/assets/styles/animations.scss';

#profile-footer-button.disabled {
  pointer-events: none;
}

.video-footage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // Full screen video setting regardless of its resolution
  object-fit: cover;
  opacity: 0.4;
  z-index: 1;
}

.profile {
  &-header {
    &-title {
      width: 60%;
      height: 4.875rem;
      font-size: 3.125rem;
      font-weight: bold;
      background: #4487b4;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(68, 135, 180, 0.5) 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
  &__container {
    position: relative;
    z-index: 2;
  }

  &-footer {
    width: auto;
    margin: 4rem 4rem 0;
  }

  &-gender-options,
  &-details {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
  }

  &-details {
    gap: 3.75rem;
  }

  &-gender-options {
    gap: 1.875rem;
  }
}
</style>
