<template>
  <header class="mail-header flex">
    <app-check-box
      :label="$t('post.selectAll')"
      label-place="right"
      :is-checked="selectedAll"
      size="sm"
      @checked-action="$emit('selectAll')"
    />
    <app-select
      class="selectbox"
      :options="options"
      :placeholder="$t('map.options')"
      reset-after-select
      @select="selectAction"
    />
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'MailHeader',
  props: {
    options: {
      type: Array as PropType<{ value: number; name: string }[]>,
      default: () => [],
    },
    selectedAll: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectOption', 'selectAll'],
  methods: {
    selectAction(action: number): void {
      this.$emit('selectOption', action)
    },
  },
})
</script>
<style lang="scss" scoped>
.mail-header {
  width: 98%;
  margin: 2rem auto;
  justify-content: space-between;

  .app-checkbox-wrapper {
    margin-left: 0.6rem;
  }

  .app-select {
    width: 33rem;
  }
}
</style>
