<template>
  <div id="club-house" class="club-house-view flex flex-col app-page-wrapper absolute">
    <menu-component menu-type="club-house" :title="$t('club.clubHouse')">
      <app-icon
        v-tippy="{
          theme: 'WSM',
          content: $t('club.clubHouseInfo'),
          placement: 'left',
          maxWidth: '62rem',
        }"
        icon-name="info-70"
        class="modal-title-icon"
      />
    </menu-component>
    <section class="club-house-wrapper flex w-full h-full safe-area">
      <main v-if="isClubHouseLoaded" class="club-house flexing flex-row">
        <clubs-building-panel
          :building-type="CLUBHOUSE"
          :data-for-upgrade="dataForUpgrade"
          @do-action="upgradeBulding"
        />
        <div class="club-house-right">
          <app-scrollbar class="clubhouse-scroll" width="100%" height="100%" scroll="y" slide="y">
            <clubs-house-progress-box :daily-task="clubDailyTask" />
            <clubs-house-daily-task :daily-task="clubDailyTask" />
            <clubs-house-table />
          </app-scrollbar>
        </div>
      </main>
      <component-loading :is-loading="!isClubHouseLoaded" width="100%" height="100%" />
    </section>
  </div>
</template>

<script lang="ts">
import ClubsBuildingPanel from '@/components/Club/ClubsBuildingPanel.vue'
import ClubsHouseDailyTask from '@/components/Club/ClubsHouseDailyTask.vue'
import ClubsHouseProgressBox from '@/components/Club/ClubsHouseProgressBox.vue'
import ClubsHouseTable from '@/components/Club/ClubsHouseTable.vue'
import { CLUBHOUSE } from '@/globalVariables'
import { useBuildingsStore } from '@/store/pinia/clubs/buildingStore'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useClubsDailyTasks } from '@/store/pinia/clubs/clubsDailyTasks'
import { mapActions, mapState } from 'pinia'
import type {
  ClubBuildingsResponse,
  ClubBuildingLevel,
} from '@/interfaces/responses/club/ClubBuildingsResponses'

interface DataForUpgrade {
  name: string
  level: number
  maxLevel: number
  currentValue: number
  type: string
  nextValue: number
  price: number
  time: number
}

interface ComponentData {
  CLUBHOUSE: typeof CLUBHOUSE
}

export default {
  name: 'ClubsHouse',
  components: {
    ClubsBuildingPanel,
    ClubsHouseProgressBox,
    ClubsHouseDailyTask,
    ClubsHouseTable,
  },
  data(): ComponentData {
    return {
      CLUBHOUSE,
    }
  },
  computed: {
    ...mapState(useClubsDailyTasks, {
      clubDailyTask: 'getClubDailyTask',
    }),
    ...mapState(useBuildingsStore, {
      getBuildings: 'getBuildings',
      getBuildingsIds: 'getBuildingsIds',
    }),
    dataForUpgrade(): DataForUpgrade {
      if (this.clubhouseData) {
        const currentLevel = this.clubhouseData.levels.find(
          (level: ClubBuildingLevel): boolean => level.level === this.clubhouseData.stats.level,
        )
        const nextLevel =
          this.clubhouseData.stats.level < this.clubhouseData.levels.length
            ? this.clubhouseData.levels.find(
                (level: ClubBuildingLevel): boolean =>
                  level.level === this.clubhouseData.stats.level + 1,
              )
            : this.clubhouseData.levels.find(
                (level: ClubBuildingLevel): boolean =>
                  level.level === this.clubhouseData.levels.length,
              )
        return {
          name: CLUBHOUSE,
          level: this.clubhouseData.stats.level,
          maxLevel: this.clubhouseData.levels.length,
          currentValue: currentLevel.parameters.members_limit ?? 0,
          type: currentLevel.reward?.type ?? '',
          nextValue: nextLevel.parameters.members_limit ?? 0,
          price: nextLevel.parameters.upgrade_price,
          time: currentLevel.parameters?.frequency
            ? this.clubhouseData.levels[this.clubhouseData.stats.level].parameters?.frequency * 3600
            : 0,
        }
      }
      return null
    },
    clubhouseData(): ClubBuildingsResponse {
      const buildings = this.getBuildings
      if (!buildings?.length) return null
      return buildings.find(
        (building: ClubBuildingsResponse): boolean => building.parameters.name === CLUBHOUSE,
      )
    },
    isClubHouseLoaded(): boolean {
      return !!this.clubDailyTask && !!this.clubhouseData
    },
  },
  async created(): Promise<void> {
    if (!this.getBuildingsIds?.clubhouse) {
      await this.loadBuildings()
    }
    this.loadBuildings({ buildingTypeId: this.getBuildingsIds.clubhouse })
    await this.loadClubDailyTask()
  },
  methods: {
    ...mapActions(useClubsDailyTasks, {
      loadClubDailyTask: 'loadClubDailyTask',
    }),
    ...mapActions(useBuildingsStore, ['loadBuildings', 'upgradeBuilding']),
    ...mapActions(useClubStore, {
      loadClubInfo: 'loadClubInfo',
    }),
    async upgradeBulding(): Promise<void> {
      await this.upgradeBuilding({
        clubsBuildingId: this.clubhouseData.clubs_buildings_id,
        buildingTypeId: this.getBuildingsIds.clubhouse,
      })
      this.loadClubDailyTask()
      this.loadClubInfo()
    },
  },
}
</script>

<style lang="scss" scoped>
.club-house-view {
  gap: 1.375rem;

  .club-house-wrapper {
    flex: 1 1 auto;
    min-height: 0;

    .club-house {
      width: 98%;
      margin: 0 auto 1.375rem;
      gap: 1.25rem;

      &-right {
        flex: 1 1 auto;
        height: 100%;
      }
    }
  }
}
</style>
