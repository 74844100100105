export const parameterTypes = {
  energy: {
    value: 'energy',
    icon: 'energy',
    headerText: 'parameters.rechargeEnergy',
    initialText: 'parameters.rechargeEnergyNoWait',
    addParameterText: 'parameters.rechargeEnergyAmount',
    rechargeInfoLimitText: 'parameters.energyRechargeInfoLimit',
    rechargeInfoText: 'parameters.energyRechargeInfo',
    everyMinuteText: 'common.everyMinute',
    autoTransactionValue: 5,
    autoTransactionInterval: 5,
    nextRefresh: 'getNextRefreshEnergy',
    amount: 'getEnergy',
    specialConsumable: {
      value: 'energy_drink',
      img: 'energy-splash.avif',
      amountText: 'parameters.yourEnergyDrinks',
      useText: 'button.useEnergyDrinks',
      buyText: 'button.buyEnergyDrinks',
    },
  },
  starts: {
    value: 'starts',
    icon: 'starts',
    headerText: 'parameters.rechargeStarts',
    initialText: 'parameters.rechargeStartsNoWait',
    addParameterText: 'parameters.rechargeStartsAmount',
    rechargeInfoLimitText: 'parameters.startsRechargeInfoLimit',
    rechargeInfoText: 'parameters.startsRechargeInfo',
    everyMinuteText: 'common.everyMinutes',
    autoTransactionValue: 1,
    autoTransactionInterval: 20,
    nextRefresh: 'getNextRefreshStarts',
    amount: 'getStarts',
    specialConsumable: {
      value: 'wild_card',
      img: 'card-fire.avif',
      amountText: 'parameters.yourWildCards',
      useText: 'button.useWildCards',
      buyText: 'button.buyWildCards',
    },
  },
}
