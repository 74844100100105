<template>
  <main
    class="teams-main flex flex-col justify-between w-full h-full relative safe-area"
    :data-event-theme="namespace"
  >
    <teams-header :title="$te(headerTranslationKey)" />
    <teams-sub-header :tier="currentDifficulty" />
    <section class="teams-main-middle flexing flex-grow w-full">
      <teams-team-info :team-id="1" :done="!availableTeams.includes(1)" />
      <teams-rewards />
      <teams-team-info :team-id="2" :done="!availableTeams.includes(2)" />
    </section>
  </main>
</template>

<script lang="ts">
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useTaskChainStore } from '@/store/pinia/events/taskChainStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import TeamsHeader from './TeamsHeader.vue'
import TeamsRewards from './TeamsRewards.vue'
import TeamsSubHeader from './TeamsSubHeader.vue'
import TeamsTeamInfo from './TeamsTeamInfo.vue'

export default defineComponent({
  name: 'TeamsMain',
  components: {
    TeamsHeader,
    TeamsSubHeader,
    TeamsTeamInfo,
    TeamsRewards,
  },
  computed: {
    ...mapState(useTaskChainStore, {
      currentDifficulty: 'getCurrentDifficulty',
      availableTeams: 'getAvailableTeams',
    }),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
    headerTranslationKey(): string {
      if (this.availableTeams.length === 0) return 'doneAllTasks'
      if (!this.availableTeams.includes(1)) return 'doneAllLeftTasks'
      if (!this.availableTeams.includes(2)) return 'doneAllRightTasks'
      if (this.currentDifficulty > 1) return 'doneAllTeamTaskTier2'
      return 'chooseTeam'
    },
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='challenge_cup'] {
  --events-teams-main-bg: url('#{$path-events}challenge-cup/taskchain/teams/teams-vs-bg.avif');
}

[data-event-theme='frozen'] {
  --events-teams-main-bg: url('#{$path-events}frozen-championship/taskchain/teams/teams-vs-bg.avif');
}

.teams-main {
  @include background(var(--events-teams-main-bg), cover, bottom);

  &-middle {
    justify-content: space-between;
  }

  @if $isWsm {
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 26.25rem;
      background: linear-gradient(0deg, rgba(2, 20, 34, 0.7) 40%, rgba(35, 52, 65, 0) 100%);
    }
  }
}
</style>
