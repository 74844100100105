import type { ImageLoadData } from '@/map-phaser-new/interfaces'
import { mainMapImageLoadData } from './mainMapImageLoadData'

/**
 * This function is used to collect all imports of main images, to make loading at BootScene easier
 * @returns Promise<ImageLoadData[]>
 */
export const exportedMainImages: () => Promise<ImageLoadData[]> = () => {
  return Promise.resolve([...mainMapImageLoadData()])
}
