<template>
  <header class="training-sub-header flexing w-full">
    <section class="flex flex-row items-center justify-start w-full ml-6 mt-4">
      <app-icon class="info-icon mr-4 pointer-events-none" icon-name="info-rounded" />
      <p class="text-28 text-texts-standard-default text-left font-bold leading-tight">
        {{
          $replacePlaceholder(
            $te('training.headerText'),
            '{level}',
            getDisciplineUnlockLevel(eventDisciplineId).toString(),
          )
        }}
      </p>
    </section>
  </header>
</template>

<script lang="ts">
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TrainingSubHeader',
  computed: {
    ...mapState(useDisciplineStore, ['getDisciplineUnlockLevel']),
    ...mapState(useEventInfoStore, {
      eventDisciplineId: 'getEventDisciplineId',
    }),
  },
})
</script>

<style lang="scss" scoped>
.training-sub-header {
  height: 4.5rem;
  padding-left: $default-margin;
  padding-right: $default-margin;

  .info-icon {
    min-width: 3.5rem;
  }
}
</style>
