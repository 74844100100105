import type { AnimatedObjectWithoutPathsInterface } from '@/map-phaser-new/interfaces'

export const animatedObjectsWithoutPathsConfig: AnimatedObjectWithoutPathsInterface[] = [
  {
    animationName: 'observatory_dome',
    textureKey: 'observatory',
    position: {
      x: 2568,
      y: 1202,
    },
    frameDataForGenerate: {
      start: 0,
      end: 23,
    },
    frameRate: 3,
    repeat: -1,
  },
]
