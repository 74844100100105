<template>
  <iframe
    v-if="isIframeOpened"
    id="inlineFrameExample"
    ref="minigameIframe"
    class="framer"
    allow="fullscreen"
    :src="getIframeUrl"
    height="100%"
    width="100%"
    frameborder="0"
  />
</template>

<script lang="ts">
import { useIframeStore } from '@/store/pinia/iframeStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isIframeOpened: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(useIframeStore, ['getIframeUrl']),
  },
  watch: {
    isIframeOpened(value: boolean): void {
      if (!value) return
      this.$nextTick(() => {
        const iframeRef = this.$refs.minigameIframe
        if (!iframeRef) console.error('IFRAME MISSING')

        if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
          iframeRef.style.width = getComputedStyle(iframeRef).width
          iframeRef.style.height = getComputedStyle(iframeRef).height
          iframeRef.setAttribute('scrolling', 'no')
        }
        iframeRef.focus()
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.framer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 999999;
}
</style>
