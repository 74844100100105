<template>
  <maintenance-popup
    v-if="useStore.getGameState === GAME_MAINTENANCE || useStore.getGameState === GAME_UPDATE"
    :game-state="useStore.getGameState"
    :time="useStore.gameReadyAt"
  />
</template>

<script lang="ts">
import MaintenancePopup from '@/components/Popup/MaintenancePopup.vue'
import { GAME_LIVE, GAME_MAINTENANCE, GAME_UPDATE, LAYOUT_VIEW_ROUTE_NAME } from '@/globalVariables'
import { loadTextsFromSessionStorage } from '@/plugins/getTexts'
import { useTextsStore } from '@/store/pinia/textStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'
import { useGameStateStore } from '@/store/pinia/gameStateStore'

interface ComponentData {
  GAME_MAINTENANCE: typeof GAME_MAINTENANCE
  GAME_UPDATE: typeof GAME_UPDATE
}

export default defineComponent({
  name: 'GameInMaintenance',
  components: {
    MaintenancePopup,
  },
  setup() {
    const useStore = useGameStateStore()
    return { useStore }
  },
  data(): ComponentData {
    return {
      GAME_MAINTENANCE,
      GAME_UPDATE,
    }
  },
  async created(): Promise<void> {
    if (this.useStore.getGameState === GAME_LIVE) {
      this.setLoadedTexts(false)
      sessionStorage.removeItem('texts')
      await loadTextsFromSessionStorage()

      this.$router.push({
        name: LAYOUT_VIEW_ROUTE_NAME,
      })
    }
  },
  methods: {
    ...mapActions(useTextsStore, ['setLoadedTexts']),
  },
})
</script>
