import { metaBuildingsEndpoint } from '@/globalVariables'
import { usePhaserStore } from '@/store/pinia/map/phaser'
import { defineStore } from 'pinia'
import type { BuildingRequestApiResponse } from '@/interfaces/responses/building/BuildingRequestApiResponse'
import { ApiService } from '@/services/ApiService'

interface BuildingInterface {
  [key: string]: BuildingRequestApiResponse
}

interface BuildingsStoreState {
  buildingsLoaded: boolean
  buildingsStatus: BuildingInterface | null
}

export const usePhaserBuildingsStore = defineStore('buildingsStore', {
  state: (): BuildingsStoreState => ({
    buildingsLoaded: false,
    buildingsStatus: null,
  }),
  getters: {
    getBuildingsLoaded: (state: BuildingsStoreState): BuildingsStoreState['buildingsLoaded'] =>
      state.buildingsLoaded,
    getBuildingsStatus: (state: BuildingsStoreState): BuildingsStoreState['buildingsStatus'] =>
      state.buildingsStatus,
    getClubChampionship: (
      state: BuildingsStoreState,
    ): BuildingsStoreState['buildingsStatus']['club_championship'] | null =>
      state.buildingsStatus?.club_championship ?? null,
    getClub: (state: BuildingsStoreState): BuildingsStoreState['buildingsStatus']['club'] | null =>
      state.buildingsStatus?.club,
  },
  actions: {
    async loadBuildingsStatus(force: boolean = false): Promise<void> {
      if (this.buildingsStatus && this.buildingsLoaded && !force) return

      let tempBuildings: BuildingInterface = {}
      const metaBuildingsData = await ApiService.get<BuildingRequestApiResponse[]>(
        metaBuildingsEndpoint,
        { force },
      )

      metaBuildingsData.forEach((value: BuildingRequestApiResponse): void => {
        tempBuildings = { ...tempBuildings, ...{ [value.name]: value } }
      })
      this.buildingsStatus = tempBuildings
      this.buildingsLoaded = true
    },
    async reloadBuildings(): Promise<void> {
      const usePhaser = usePhaserStore()
      const emitter = usePhaser.getEmitter
      await this.loadBuildingsStatus(true)
      if (emitter) emitter.emit('reloadBuildings')
    },
    reloadNotifications(): void {
      const usePhaser = usePhaserStore()
      const emitter = usePhaser.getEmitter
      if (emitter) emitter.emit('reloadNotifications')
    },
  },
})
