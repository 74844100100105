import type { AtlasDataInterface } from '@/map-phaser-new/interfaces'
import { mainMapAtlasLoadData } from './mainMapAtlasLoadData'

/**
 * This function is used to collect all imports of main atlases, to make loading at BootScene easier
 * @returns Promise<AtlasDataInterface[]>
 */
export const exportedMainAtlases: () => Promise<AtlasDataInterface[]> = () => {
  return Promise.resolve([...mainMapAtlasLoadData()])
}
