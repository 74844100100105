import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const carsRightTopToLeftTopAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '10',
      delay: 0,
      duration: 1500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 10,
        end: 9,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '09',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 9,
        end: 8,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations3',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '08',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 8,
        end: 6,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations4',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '06',
      delay: 0,
      duration: 900,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GeneratedFrameNames,
      data: [
        { key: 'animations', frame: '06' },
        { key: 'animations', frame: '05' },
        { key: 'animations', frame: '04' },
        { key: 'animations', frame: '03' },
        { key: 'animations', frame: '02' },
        { key: 'animations', frame: '01' },
        { key: 'animations', frame: '23' },
        { key: 'animations', frame: '22' },
      ],
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations5',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '22',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 22,
        end: 24,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations6',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '24',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 24,
        end: 1,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations7',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '01',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 1,
        end: 6,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations8',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '06',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 6,
        end: 5,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations9',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '05',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 5,
        end: 6,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations10',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '06',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 6,
        end: 8,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations11',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '08',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 8,
        end: 11,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations12',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '11',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 11,
        end: 8,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations13',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '08',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 8,
        end: 5,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations14',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '05',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 5,
        end: 3,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations15',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '03',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 3,
        end: 1,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations16',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '01',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GeneratedFrameNames,
      data: [
        { key: 'animations', frame: '01' },
        { key: 'animations', frame: '24' },
      ],
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations17',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '24',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 24,
        end: 23,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations18',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '23',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 23,
        end: 21,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations19',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '21',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 21,
        end: 20,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsRightTopToLeftTopAnimations20',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '20',
      delay: 0,
      duration: 2000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 20,
        end: 24,
        zeroPad: 2,
      },
    },
  },
]
