<template>
  <span>
    <h1 class="text-60 italic">
      {{ $t('club.clubChampionship') }}
    </h1>
    <h2 class="text-36">
      {{ setSubtitle }}
    </h2>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ChampionshipSummaryHeader',
  props: {
    isPromotion: {
      type: Boolean,
      default: false,
    },
    isWinner: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    setSubtitle(): string {
      if (this.isWinner) return this.$t('club.winner')
      if (this.isPromotion) return this.$t('club.winDivision')
      return this.$t('club.seasonEnd')
    },
  },
})
</script>
