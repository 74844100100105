import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const clubCarsRightTopToLeftTopCheckpoints: CheckpointsObjectInterface = {
  key: 'clubCarsRightTopToLeftTopCheckpoints',
  data: [
    {
      x: 2804,
      y: 420,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsRightTopToLeftTopAnimations1',
        },
      },
    },
    {
      x: 2639,
      y: 430,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsRightTopToLeftTopAnimations2',
        },
      },
    },
    {
      x: 2510,
      y: 423.5,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsRightTopToLeftTopAnimations3',
        },
      },
    },
    {
      x: 2116,
      y: 315.5,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsRightTopToLeftTopAnimations4',
        },
      },
    },
    {
      x: 1807,
      y: 277,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsRightTopToLeftTopAnimations5',
        },
      },
    },
    {
      x: 1672,
      y: 227,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsRightTopToLeftTopAnimations6',
        },
      },
    },
  ],
}
