import { BUILDING_TITLE_CONTAINER_NAMES } from '@/map-phaser-new/constants'
import type {
  BuildingTitleBackgroundImageConfig,
  BuildingTitleConfigInterface,
  BuildingHtmlTextConfigInterface,
} from '@/map-phaser-new/interfaces'

export class BuildingTitle {
  private titleContainer: Phaser.GameObjects.Container

  /**
   *
   * @param activeScene Scene where title will be added.
   * @param config Contains config data for title.
   * @param isVisible Show title after render (user has ability to show/hide building title in settings).
   * @param isLocked Image version is chosen based on this param.
   * @param title Text for title.
   */
  public addToBuilding(
    activeScene: Phaser.Scene,
    config: BuildingTitleConfigInterface,
    isVisible: boolean,
    isLocked: boolean,
    title: string,
  ): void {
    this.titleContainer = activeScene.add
      .container(config.positionX, config.positionY)
      .setDepth(config.depth)
      .setVisible(isVisible)

    const titleText = this.addTitleText(activeScene, config.textConfig, title)
    titleText.setName(BUILDING_TITLE_CONTAINER_NAMES.TextTitle)
    const titleBackgroundRight = this.addTitleBackgroundRightSide(
      activeScene,
      config.backgroundImageConfig,
      titleText.width,
      titleText.height,
      isLocked,
    )
    this.titleContainer.add([titleBackgroundRight, titleText])

    const titleBackgroundLeft = this.addTitleBackgroundLeftSide(
      activeScene,
      config.backgroundImageConfig,
      titleBackgroundRight.displayWidth,
      titleBackgroundRight.displayHeight,
      isLocked,
    )
    if (!titleBackgroundLeft) return

    this.titleContainer.add(titleBackgroundLeft)
  }

  public removeTitle(): void {
    if (!this.titleContainer) return

    this.titleContainer.removeAll(true)
    this.titleContainer.destroy()
    this.titleContainer = null
  }

  public setTitleDepth(depthValue: number): void {
    this.titleContainer.setDepth(depthValue)
  }

  public setTitleVisibility(visible: boolean): void {
    this.titleContainer.setVisible(visible)
  }

  public reloadTitle(
    activeScene: Phaser.Scene,
    config: BuildingTitleConfigInterface,
    isLocked: boolean,
    title: string,
  ): void {
    const titleTextOld: Phaser.GameObjects.DOMElement = this.titleContainer.getByName(
      BUILDING_TITLE_CONTAINER_NAMES.TextTitle,
    )
    const titleTextNew = this.addTitleText(activeScene, config.textConfig, title)
    this.titleContainer.replace(titleTextOld, titleTextNew, true)
    titleTextNew.setName(BUILDING_TITLE_CONTAINER_NAMES.TextTitle)

    this.changeTitleBackground(activeScene, config, isLocked)
  }

  public getTitleTextWidth(): number {
    const textObject: Phaser.GameObjects.DOMElement = this.titleContainer.getByName(
      BUILDING_TITLE_CONTAINER_NAMES.TextTitle,
    )
    return textObject.displayWidth
  }

  public changeTitleBackground(
    activeScene: Phaser.Scene,
    config: BuildingTitleConfigInterface,
    isLocked: boolean,
  ): void {
    const titleText: Phaser.GameObjects.Image = this.titleContainer.getByName(
      BUILDING_TITLE_CONTAINER_NAMES.TextTitle,
    )
    const titleBackgroundRightOld: Phaser.GameObjects.Image = this.titleContainer.getByName(
      BUILDING_TITLE_CONTAINER_NAMES.BackgroundTitleRight,
    )
    const titleBackgroundRightNew = this.addTitleBackgroundRightSide(
      activeScene,
      config.backgroundImageConfig,
      titleText.width,
      titleText.height,
      isLocked,
    )
    this.titleContainer.replace(titleBackgroundRightOld, titleBackgroundRightNew, true)
    titleBackgroundRightNew.setName(BUILDING_TITLE_CONTAINER_NAMES.BackgroundTitleRight)

    const titleBackgroundLeftOld: Phaser.GameObjects.Image = this.titleContainer.getByName(
      BUILDING_TITLE_CONTAINER_NAMES.BackgroundTitleLeft,
    )
    if (!titleBackgroundLeftOld) return

    const titleBackgroundLeftNew = this.addTitleBackgroundLeftSide(
      activeScene,
      config.backgroundImageConfig,
      titleBackgroundRightNew.displayWidth,
      titleBackgroundRightNew.displayHeight,
      isLocked,
    )
    this.titleContainer.replace(titleBackgroundLeftOld, titleBackgroundLeftNew, true)
    titleBackgroundLeftNew.setName(BUILDING_TITLE_CONTAINER_NAMES.BackgroundTitleLeft)
  }

  private addTitleText(
    activeScene: Phaser.Scene,
    config: BuildingHtmlTextConfigInterface,
    title: string,
  ): Phaser.GameObjects.DOMElement {
    return activeScene.add.dom(
      config.positionX ?? 0,
      0.5,
      'span',
      `color: ${config.fontColor}; font-size: ${config.fontSize}; font-family: ${config.fontName}; text-transform: uppercase; font-weight: ${config.fontWeight}; font-style: ${config.fontStyle}`,
      title,
    )
  }

  private addTitleBackgroundRightSide(
    activeScene: Phaser.Scene,
    config: BuildingTitleBackgroundImageConfig,
    textWidth: number,
    textHeight: number,
    isLocked: boolean,
  ): Phaser.GameObjects.Image {
    const frameName = isLocked ? config.frameNameRightSideLocked : config.frameNameRightSide
    return activeScene.add
      .image(0 + (config.positionX || 0), 0, config.textureName, frameName)
      .setOrigin(config.originX)
      .setName(BUILDING_TITLE_CONTAINER_NAMES.BackgroundTitleRight)
      .setDisplaySize(
        textWidth + (config.widthCorrection ?? 0),
        textHeight + (config.heightCorrection ?? 0),
      )
  }

  private addTitleBackgroundLeftSide(
    activeScene: Phaser.Scene,
    config: BuildingTitleBackgroundImageConfig,
    backgroundWidth: number,
    backgroundHeight: number,
    isLocked: boolean,
  ): Phaser.GameObjects.Image | null {
    const frameName = isLocked ? config.frameNameLeftSideLocked : config.frameNameLeftSide
    if (!frameName) return null

    return activeScene.add
      .image(-backgroundWidth / 2 + (config.positionX || 0), 0, config.textureName, frameName)
      .setOrigin(config.originX)
      .setName(BUILDING_TITLE_CONTAINER_NAMES.BackgroundTitleLeft)
      .setDisplaySize(3, backgroundHeight)
  }

  public setTitleTextAlpha(alpha: number): void {
    const titleText: Phaser.GameObjects.DOMElement = this.titleContainer.getByName(
      BUILDING_TITLE_CONTAINER_NAMES.TextTitle,
    )
    titleText.setAlpha(alpha)
  }
}
