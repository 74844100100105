<template>
  <div class="club-create-price flexing">
    <p class="text-texts-standard-default text-34 uppercase">
      {{ $t('club.createPrice') }}
    </p>
    <p class="text-texts-standard-important text-34 uppercase font-bold ml-2">
      {{ $filters.$formatNumber(prices[0]) }}
    </p>
    <app-main-icon :icon-size="48" icon-name="money" />
    <p class="text-texts-standard-default text-34 uppercase ml-2">
      {{ $t('homepage.orUse') }}
    </p>
    <p class="text-texts-standard-important text-34 uppercase font-bold ml-2">
      {{ $filters.$formatNumber(prices[1]) }}
    </p>
    <app-main-icon :icon-size="48" icon-name="gems" />
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClubsCreatePrice',
  props: {
    prices: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
  },
})
</script>

<style lang="scss" scoped>
.club-create-price {
  height: 4.938rem;
  margin: 0.8125rem auto;
  background-color: rgba(237, 49, 24, 0.5);
  @if $isWsm {
    background: linear-gradient(
      90deg,
      transparent 0%,
      rgba(237, 49, 24, 0.5) 40%,
      rgba(237, 49, 24, 0.5) 60%,
      transparent 100%
    );
  }
  @if $isSsm {
    background: linear-gradient(to right, transparent, #b2341b, transparent);
  }
  display: flex;
}
</style>
