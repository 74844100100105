import type { SpecificObjectInterface, SpecificObjectsInterface } from '@/map-phaser-new/interfaces'

export abstract class BaseObjectHandler<
  C extends SpecificObjectsInterface | SpecificObjectInterface[],
> {
  protected constructor(
    protected gameFromEnv: string,
    protected activeScene: Phaser.Scene,
  ) {}

  public abstract setUp(configData: C): void
}
