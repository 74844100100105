<template>
  <div
    v-if="$route.path !== '/' && !$route.meta?.hideWebWindowComponent && !$isMobile()"
    class="web-modal absolute w-full h-full top-0 left-0"
    :class="$route.path"
  >
    <div class="web-modal-overlay w-full h-full pointer-events-none" />
    <div
      class="web-modal-wrapper absolute top-1/2 left-1/2"
      :class="{
        'is-board-games-event': $route.name.toString() === 'BoardGamesEventViewWeb',
      }"
    >
      <slot />
      <app-control-icon
        v-if="!$route.meta?.hideWebWindowHeaderComponent && !$isMobile()"
        class="absolute left-3 top-2 clickable-element"
        control="back"
      />
      <app-control-icon
        v-if="!$route.meta?.hideWebWindowHeaderComponent && !$isMobile()"
        class="absolute right-3 top-2 clickable-element"
        control="close"
        @click="$router.push('/')"
      />
      <div class="web-modal-content w-full h-full">
        <header-component
          v-if="!$route.meta?.hideWebWindowHeaderComponent && !$isMobile()"
          v-show="!hideForPages.includes($route.name)"
          :is-web-modal-header="true"
          :modal-web-overlay="true"
          :modal-web-icon="true"
        />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import { defineComponent } from 'vue'
import type { RouteRecordName } from 'vue-router'

interface ComponentData {
  hideForPages: RouteRecordName[]
}

export default defineComponent({
  name: 'WebWindowComponent',
  components: {
    HeaderComponent,
  },
  data: (): ComponentData => {
    return {
      hideForPages: [
        'ArenaReportTutorialWeb',
        'ArenaReportWeb',
        'ArenaReportTutorial',
        'ClubsChampionshipReportWeb',
        'CareerReportWeb',
        'GdprViewWeb',
      ],
    }
  },
})
</script>

<style lang="scss" scoped>
.web-modal {
  z-index: 3;

  &-wrapper {
    width: 122rem;
    height: 68.625rem;
    transform: translateX(-50%) translateY(-50%) $scale-default;

    @if $isWsm {
      border: 0.1875rem solid #305a6e;
      box-shadow: 3px 1px 4px 0px rgba(0, 0, 0, 0.59);
    }

    @if $isSsm {
      border: 0.1875rem solid #5672a4;
    }

    &.is-board-games-event {
      width: 120rem;
      background-color: #1d2943;
    }
  }

  &-overlay {
    background: rgba(0, 0, 0, 0.8);
  }

  button {
    width: 4.375rem;
    height: 4.375rem;
    background: linear-gradient(to bottom, #2c4f69, #4481ad);
    border: 0.1875rem solid #528ebf;
    font-size: 3rem;
    z-index: 2;
    font-style: normal;
    outline: none;
  }
}

// for board games event
.is-fullscreen {
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  transform: translate(0, 0) !important;
}
</style>
