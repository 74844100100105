<template>
  <div class="item-box-center" :class="itemClass(imageData)" />
</template>

<script lang="ts">
import { GDD_ID } from '@/globalVariables'
import type { ImageData } from '@/interfaces/Equipment'
import type { Nullable } from '@/interfaces/utils'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'EquipmentItemBox',
  props: {
    imageData: {
      type: Object as PropType<Nullable<ImageData>>,
      default: () => null,
    },
  },
  methods: {
    itemClass(item: ImageData): string {
      const itemCategory = !item.isOffer
        ? Number(
            item.properties.find(
              (prop: ImageData['properties'][0]): boolean => prop.name === GDD_ID,
            ).value,
          )
        : item.items[0].properties.find(
            (prop: ImageData['properties'][0]): boolean => prop.name === GDD_ID,
          ).value

      return `item-${item.disciplineId}-${itemCategory}-${!item.locked ? item.level : '1'}`
    },
  },
})
</script>

<style lang="scss" scoped>
.item-box-center {
  background-repeat: no-repeat;
  background-size: contain;
  width: 85%;
  height: 15rem;
  margin: 1rem auto 0;

  @for $discipline from 1 through 16 {
    @for $category from 1 through 6 {
      @for $level from 1 through 5 {
        &.item-#{$discipline}-#{$category}-#{$level} {
          background-image: url($path-equipment + 'items/list/' + #{$discipline} + '/' + #{$category} + '-' + #{$level} + '.avif');
        }
      }
    }
  }
}
</style>
