import { SpecificObjectHandler } from '@/map-phaser-new/utils/abstractClasses'
import type {
  AdditionalConfigsInterface,
  ConnectAccountOsraConfigInterface,
  ExclamationNotificationConfigInterface,
} from '@/map-phaser-new/interfaces'
import { ConnectAccountOsra } from '@/map-phaser-new/models'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { DESTROY_CONNECT_ACCOUNT_OSRA } from '@/map-phaser-new/constants'
import { useAccountStore } from '@/store/pinia/accountStore'

export class ConnectAccountOsraHandler extends SpecificObjectHandler {
  private osraInstance: ConnectAccountOsra

  constructor() {
    super()
  }

  public async setUp(
    activeScene: Phaser.Scene,
    osraConfig: ConnectAccountOsraConfigInterface,
    additionalConfigs: AdditionalConfigsInterface,
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      const gameIntegrationStore = usePhaserGameIntegrationStore()
      this.createOsra(activeScene, osraConfig, additionalConfigs.exclamationNotification)

      const eventEmitter = gameIntegrationStore.getPhaserEventEmitter
      this.addOsraActionsToEmitter(eventEmitter, activeScene)
      resolve()
    })
  }

  private createOsra(
    activeScene: Phaser.Scene,
    config: ConnectAccountOsraConfigInterface,
    notificationConfig: ExclamationNotificationConfigInterface,
  ): void {
    this.osraInstance = new ConnectAccountOsra(config.positionX, config.positionY)
    const useAccount = useAccountStore()

    if (!useAccount.hasVerifiedAccount) {
      this.osraInstance.addToMap(activeScene, config, notificationConfig)
    }
  }

  private destroyOsra(activeScene: Phaser.Scene): void {
    if (!this.osraInstance) return

    this.osraInstance.removeOsraAndNotification(activeScene)
    this.osraInstance = null
  }

  private addOsraActionsToEmitter(
    eventEmitter: Phaser.Events.EventEmitter,
    activeScene: Phaser.Scene,
  ): void {
    eventEmitter.on(
      DESTROY_CONNECT_ACCOUNT_OSRA,
      () => {
        this.destroyOsra(activeScene)
      },
      this,
    )
  }
}
