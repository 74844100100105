<template>
  <component-loading :is-loading="true" />
</template>

<script lang="ts">
import { tokenValidationEndpoint } from '@/globalVariables'
import { saveTokenToLocalStorage } from '@/helpers'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AdminTokenExtractor',
  async created(): Promise<void> {
    sessionStorage.setItem('isLoggedFromAdmin', 'true')
    const tokenId = this.$route.params.tokenId
    const res = await this.$axios.get<{}, { is_valid: boolean }>(tokenValidationEndpoint + tokenId)
    localStorage.clear()
    if (res.is_valid) {
      saveTokenToLocalStorage(tokenId.toString())
      await this.resetTutorialState()
      await this.$router.push('/')
    } else {
      sessionStorage.removeItem('isLoggedFromAdmin')
      this.$router.push('/login')
    }
  },
  methods: {
    ...mapActions(useTutorialStore, ['resetTutorialState']),
  },
})
</script>
