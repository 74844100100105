<template>
  <div class="select-benefit">
    <header>
      <info-text :text="$t('benefits.description')" />
    </header>
    <main>
      <app-scrollbar v-if="benefits.length" width="100%" height="44rem" scroll="y" slide="y">
        <benefit-category
          v-for="(slotContent, slotId, index) in currentSlots"
          :key="index"
          :benefits="benefitsByType(allowedBenefitTypes[index])"
          :required-benefits="requiredBenefits"
          :category-title="$t(setText(allowedBenefitTypes[index]))"
          :active-benefit="slotContent"
          @select-benefit="selectBenefitInSlot($event, slotId)"
          @benefit-upgraded="loadBenefits()"
        />
      </app-scrollbar>
    </main>
    <footer>
      <app-button
        btn-id="select-benefit-btn-confirm"
        btn-size="md"
        btn-type="confirm"
        :btn-text="$t('common.confirm')"
        :disabled="changedSlots.length === 0"
        @click="confirm()"
      />
    </footer>
  </div>
</template>

<script lang="ts">
import BenefitCategory from '@/components/Benefits/BenefitCategory.vue'
import InfoText from '@/components/InfoText.vue'
import {
  ARENA,
  ATTRIBUTE,
  CASH,
  TRAINING_POINTS,
  userBenefitsSlotsEndpoint,
} from '@/globalVariables'
import { getArenaDisciplineId, playSound } from '@/helpers'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import type { BenefitData, BenefitSlotData } from '@/interfaces/Benefits'
import type { Nullable } from '@/interfaces/utils'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface ComponentData {
  selectedBenefits: Nullable<Record<string, number>>
  changedSlots: string[]
}

export default defineComponent({
  name: 'SelectBenefit',
  components: {
    BenefitCategory,
    InfoText,
  },
  props: {
    allowedBenefitTypes: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    currentSlots: {
      type: Object as PropType<Nullable<Record<string, Nullable<number>>>>,
      default: () => null,
    },
    requiredBenefits: {
      type: Number,
      default: 1,
    },
  },
  emits: ['closeSelectPopup', 'loadOpponents'],
  data(): ComponentData {
    return {
      selectedBenefits: null,
      changedSlots: [],
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      _benefits: 'getBenefits',
    }),
    benefits(): BenefitSlotData[] {
      return this._benefits
    },
  },
  async created(): Promise<void> {
    await this.loadBenefits()
    this.selectedBenefits = this.currentSlots
  },
  methods: {
    ...mapActions(useDisciplineStore, {
      loadSpecificDiscipline: 'loadSpecificDiscipline',
    }),
    ...mapActions(useResponseTaskStore, ['loadBenefits', 'loadBenefitsSlots']),
    benefitsByType(benefitType: string): BenefitSlotData[] {
      return this.benefits.filter(
        (benefit: BenefitSlotData): boolean => benefit.benefit_type === benefitType,
      )
    },

    async confirm(): Promise<void> {
      const benefitRequestData = []

      for (const slotId in this.selectedBenefits) {
        if (this.changedSlots.includes(slotId)) {
          const benefitData = this.benefits.find(
            (benefit: BenefitSlotData): boolean =>
              benefit.benefit_gdd_id === this.selectedBenefits[slotId],
          )
          const benefit = {
            slot_id: slotId,
            benefit_type: benefitData?.benefit_type,
            benefit_rarity: benefitData?.benefit_rarity,
          }
          benefitRequestData.push(benefit)
        }
      }

      try {
        await this.$axios.put<{}, BenefitData>(userBenefitsSlotsEndpoint, {
          slots: benefitRequestData,
        })
      } catch (error: unknown) {
        console.error(error)
      }

      if (this.$route.name === this.$getWebView('ArenaView')) {
        const disciplineId = this.$route.params?.id
          ? parseInt(this.$route.params.id.toString())
          : getArenaDisciplineId()
        this.loadSpecificDiscipline({
          disciplineId: disciplineId,
          type: ARENA,
        })
        this.$emit('loadOpponents')
      }

      if (this.$route.name === this.$getWebView('ClubsChampionshipCompetitions')) {
        this.loadSpecificDiscipline({ isClubChampionship: true })
        this.$emit('loadOpponents')
      }

      this.changedSlots = []
      await this.loadBenefitsSlots()
      playSound('benefits_use')
      this.$emit('closeSelectPopup')
    },
    selectBenefitInSlot(benefitId: number, slotId: string): void {
      if (!this.selectedBenefits) return

      if (this.selectedBenefits[slotId] !== benefitId) this.changedSlots.push(slotId)
      this.selectedBenefits[slotId] = benefitId
    },
    setText(name: string): string {
      if (name === ATTRIBUTE) return 'benefits.attributes'
      if (name === CASH) return 'benefits.moneyRewards'
      if (name === TRAINING_POINTS) return 'benefits.training'
      return ''
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/benefits/selectBenefit.scss';
</style>
