<template>
  <div
    class="gems-big-pack text-texts-standard-default relative cursor-pointer"
    :class="[
      { 'most-popular': packageData.popular },
      { 'gems-big-pack-back': firstPage === index },
    ]"
    @click="$emit('buy')"
  >
    <div
      class="spin-border w-full h-full"
      :class="packageData.popular ? 'spin-border--gold' : 'spin-border--none'"
    >
      <div v-if="firstPage !== index" class="gems-big-pack-front absolute w-full h-full">
        <div v-if="packageData.firstTimeBonus" class="bonus uppercase text-30 absolute">
          <div class="bonus-up">
            <div class="flex items-center pl-3 italic overflow-hidden whitespace-nowrap">
              <p v-html="getBonusText" />
            </div>
          </div>
          <div class="bonus-down text-24 text-left pl-3">
            <p>{{ $t('premiumGroup.firstPurchaseBonus') }}</p>
          </div>
        </div>
        <p
          v-if="packageData.popular"
          class="most-popular absolute uppercase text-32"
          :class="{ 'font-bold': $isWsm }"
        >
          {{ $t('premiumGroup.popular') }}
        </p>
        <div
          v-if="packageData.isBestValue"
          class="best-value absolute uppercase text-32"
          :class="{ 'font-bold': $isWsm }"
        >
          <p>{{ $t('premiumGroup.bestValue') }}</p>
        </div>
        <div v-if="isGemBonus" class="badge">
          <gems-bonus-badge :bonus="packageData.gemBonusPercentage" size="medium" />
        </div>
        <div class="relative">
          <div
            class="pack-img m-auto"
            :style="{
              backgroundImage:
                'url(' + pathImages + 'premium/gemsPackage/' + packageData.imageName + '.avif)',
            }"
          />
          <span class="pack-shine" />
          <span class="pack-shine" />
          <span class="pack-shine" />
          <span class="pack-shine" />
        </div>
        <div v-if="!packageData.oldReward" class="pack-sell placeholder" />
        <div v-else class="pack-sell text-40 flexing">
          <app-striketrough>
            <div class="pack-sell-text italic">
              {{ $filters.$formatNumber(packageData.oldReward) }}
            </div>
            <app-main-icon class="icon-margin" :icon-name="'gems'" :icon-size="32" />
          </app-striketrough>
        </div>
        <section
          class="pack-price w-full text-60 font-bold flexing text-texts-standard-important"
          :class="{ 'popular-shadow': packageData.popular }"
        >
          <p>{{ $filters.$formatNumber(packageData.rewardTotal) }}</p>
          <app-main-icon class="icon-margin" :icon-name="'gems'" :icon-size="56" />
        </section>
        <p class="pack-name text-30 uppercase">
          {{ getPackName }}
        </p>
        <app-icon
          :icon-name="'question'"
          class="absolute question-icon"
          @click.stop="firstPage = index"
        />
        <footer
          class="price relative m-auto"
          :class="!$isMobile() && packageData.alternativePrice ? '' : 'single-price-tag'"
        >
          <app-price
            :decoration-color="
              packageData.popular ? PREMIUM_LINE_GOLD_COLOR : PREMIUM_LINE_BLUE_COLOR
            "
          >
            <p class="text-36 m-auto">
              {{ getPrice }}
            </p>
          </app-price>
          <div
            v-if="(!$isMobile() && packageData.alternativePrice) || getForceRate"
            class="text-texts-standard-additional price-exchanged text-32"
          >
            ( {{ packageData.alternativePrice }} )
          </div>
        </footer>
      </div>
      <!--  BACK SIDE OF GEM PACKAGE , WHEN YOU CLIK ON QUESTION ICON -->
      <gems-pack-back
        v-show="firstPage === index"
        :package-data="packageData"
        @click.stop="firstPage = -1"
      />
    </div>
  </div>
</template>

<script lang="ts">
import AppPrice from '@/components/GlobalComponents/AppPrice.vue'
import AppStriketrough from '@/components/GlobalComponents/AppStriketrough.vue'
import GemsBonusBadge from '@/components/Premium/GemsPack/GemsBonusBadge.vue'
import GemsPackBack from '@/components/Premium/GemsPack/GemsPackBack.vue'
import { pathImages, PREMIUM_LINE_BLUE_COLOR, PREMIUM_LINE_GOLD_COLOR } from '@/globalVariables'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { GemsPack } from '@/interfaces/premium/GemsPack'

interface ComponentData {
  firstPage: number
  pathImages: typeof pathImages
  PREMIUM_LINE_BLUE_COLOR: typeof PREMIUM_LINE_BLUE_COLOR
  PREMIUM_LINE_GOLD_COLOR: typeof PREMIUM_LINE_GOLD_COLOR
}

export default defineComponent({
  name: 'PremiumGemsBigPack',
  components: {
    GemsPackBack,
    AppPrice,
    GemsBonusBadge,
    AppStriketrough,
  },
  props: {
    packageData: {
      type: Object as PropType<Nullable<GemsPack>>,
      default: () => null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  emits: ['buy'],
  data(): ComponentData {
    return {
      firstPage: -1,
      pathImages,
      PREMIUM_LINE_BLUE_COLOR,
      PREMIUM_LINE_GOLD_COLOR,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      getForceRate: 'getForceRate',
    }),
    isGemBonus(): boolean {
      return this.packageData.storeId.includes('gems_bonus')
    },
    getPrice(): string {
      return this.packageData.price + ' ' + this.packageData.currencySymbol
    },
    getPackName(): string {
      return this.$t('premiumGroup.' + this.packageData.packName)
    },
    getBonusText(): string {
      let bonusText = this.$t('premiumGroup.bonus')
      bonusText = this.$replacePlaceholder(bonusText, '%s', '{b} %s {icon}{/b}')
      bonusText = this.$replaceHtmlPlaceholders(bonusText, 'b', 'font-bold')
      bonusText = bonusText.replace(/{icon}/g, '<div class="bonus-icon-gems"></div>')
      bonusText = this.$replacePlaceholder(bonusText, '%s', '+' + this.packageData.firstTimeBonus)

      return bonusText
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/animations.scss';

.gems-big-pack {
  position: relative;
  width: 31.25rem;
  height: 39.75rem;
  background: url($path-premium + 'gemsPackage/BG_OFFER_BOX_1.webp?v2') center no-repeat;
  background-size: contain;
  margin-right: 1.5rem;

  &::before {
    content: '';
    width: 1.125rem;
    height: 100%;
    position: absolute;
    left: -0.562rem;
    top: 0;
    background: url($path-premium + 'gemsPackage/effect-blue-left.webp?v2') center no-repeat;
    background-size: cover;
  }

  &::after {
    content: '';
    width: 1.125rem;
    height: 100%;
    position: absolute;
    right: -0.562rem;
    top: 0;
    background: url($path-premium + 'gemsPackage/effect-blue-right.webp?v2') center no-repeat;
    background-size: cover;
  }

  .bonus {
    width: 21.0625rem;
    height: 4.9375rem;
    margin-top: 0.6875rem;
    margin-left: 0.625rem;

    &-up {
      position: absolute;
      top: 0;
      max-width: 100%;

      @if $isSsm {
        background-image: linear-gradient(to right, #ed1616, #ff3f3f);
        padding-right: 0.875rem;
      }

      @if $isWsm {
        clip-path: polygon(100% -100%, 0% 0%, 0% 100%, 90% 100%);
        background-image: linear-gradient(to left, #d92b2b, #ff2c2c);
        padding-right: 2rem;
      }

      p {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:deep(.bonus-icon-gems) {
        width: 2rem;
        height: 2rem;
        position: relative;
        top: 0.175rem;
        left: -0.5rem;
        background: url($path-icons + 'main/main-icons-120_v4.avif') -6rem 0 no-repeat;
        background-size: 26rem;
        margin-left: 0.5rem;
        display: inline-block;
      }
    }

    &-down {
      position: absolute;
      bottom: 0;
      max-width: 100%;

      @if $isSsm {
        background-image: linear-gradient(to right, #8727c4, #9c39db);
        padding-right: 0.563rem;
      }

      @if $isWsm {
        clip-path: polygon(100% -100%, 0% 0%, 0% 100%, 90% 100%);
        background-image: linear-gradient(to left, #8727c4, #8727c4);
        padding-right: 2rem;
      }

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .most-popular {
    top: -1rem;
    right: -1rem;
    width: 15.4rem;
    height: 2.75rem;
    background: url($path-premium + 'gemsPackage/NOT_MOST_POPULAR.avif?v2') center no-repeat;
    background-size: cover;
  }

  .best-value {
    top: -1rem;
    right: -1rem;
    width: 15.4rem;
    height: 2.75rem;
    background: url($path-premium + 'gemsPackage/NOT_MOST_POPULAR.avif') center no-repeat;
    background-size: cover;
    z-index: 1;
  }

  .pack-img {
    width: 28.75rem;
    height: 21.25rem;
    margin-top: 3.315rem;
    margin-bottom: -2rem;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .pack-sell {
    &.placeholder {
      height: 3.75rem;
    }

    &-text {
      color: rgb(255 255 255 / 76%);
      position: relative;
    }
  }

  .pack-price {
    position: relative;
    width: 25rem;
    height: 4.375rem;
    line-height: 4.375rem;
    padding-top: 0.2rem;
    margin: -0.325rem auto 0.5rem;

    @if $isSsm {
      background: linear-gradient(to left, transparent, rgba(88, 107, 157, 0.99), transparent);
    }

    @if $isWsm {
      background: linear-gradient(to left, transparent, rgba(56, 79, 120, 0.99), transparent);
    }

    &:before {
      content: '';
      position: absolute;
      height: 0.125rem;
      width: 80%;
      background-image: linear-gradient(
        to left,
        transparent,
        rgba(255, 255, 255, 80%),
        transparent
      );
      top: 0;
    }
  }

  .pack-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 1rem;
  }

  .question-icon {
    right: 1rem;
    bottom: 1rem;
    z-index: 1;
  }

  .price {
    margin-top: 0.5rem;

    &-exchanged {
      width: 100%;
      font-weight: lighter;

      @if $isWsm {
        font-style: italic;
      }
    }

    &.single-price-tag {
      margin-top: 2.75rem;
    }
  }

  .icon-margin {
    margin-bottom: 0.5rem;
  }

  .badge {
    position: absolute;
    right: 1rem;
    top: 33%;
    z-index: 1;
  }
}

.most-popular {
  background: url($path-premium + 'gemsPackage/BG_OFFER_BOX_2.webp?v2') center no-repeat;
  background-size: contain;

  &.gems-big-pack::before {
    content: '';
    width: 1.125rem;
    height: 100%;
    position: absolute;
    left: -0.562rem;
    top: 0;
    background: url($path-premium + 'gemsPackage/effect-gold-left.webp?v2') center no-repeat;
    background-size: cover;
  }

  &.gems-big-pack::after {
    content: '';
    width: 1.125rem;
    height: 100%;
    position: absolute;
    right: -0.562rem;
    top: 0;
    background: url($path-premium + 'gemsPackage/effect-gold-right.webp?v2') center no-repeat;
    background-size: cover;
  }

  &.gems-big-pack.gems-big-pack-back::before,
  &.gems-big-pack.gems-big-pack-back::after {
    background: transparent;
  }
}

.popular-shadow {
  background: linear-gradient(
    to left,
    transparent,
    rgba(125, 81, 32, 0.99),
    transparent
  ) !important;
}

.back {
  background: url($path-premium + 'gemsPackage/BG_OFFER_BOX_3.webp') center no-repeat;
  background-size: contain;
  margin-right: 0;

  &-header-text {
    height: 4.3125rem;
  }

  &-price {
    position: relative;
    background: linear-gradient(to left, transparent, rgb(193 193 193 / 26%), transparent);
    height: 4.375rem;
    width: 20rem;
    line-height: 4.375rem;

    &:before {
      content: '';
      position: absolute;
      height: 0.125rem;
      width: 80%;
      background-image: linear-gradient(
        to left,
        rgba(125, 156, 205, 0),
        rgba(125, 156, 205, 1),
        rgba(125, 156, 205, 0)
      );
      top: 0;
    }
  }

  &-bonuses {
    height: 16.375rem;
    padding: 0.5rem 1.5rem 0;

    &-basic {
      &-text {
        width: 14.5rem;
      }
    }
  }

  &-more {
    height: 4.4375rem;
    line-height: 4.4375rem;
  }

  &-vip-bonuses {
    padding: 0 2.0625rem 0 2.0625rem;
  }
}

.pack-shine {
  position: absolute;
  width: 2.75rem;
  height: 2.75rem;
  top: 0;
  left: 0;
  // TODO switch assetu od Erika
  background-image: url(https://appspowerplaymanager.vshcdn.net/images/winter-sports/grandPrize/badges/badge-glow-1.webp?v2);
  background-size: cover;
  opacity: 0;
  z-index: 1;
  animation-name: twinkle;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &:nth-of-type(1) {
    top: 4rem;
    left: 10.5rem;
    animation-delay: 1950ms;
  }
  &:nth-of-type(2) {
    top: 2.5rem;
    left: 19rem;
    animation-delay: 1650ms;
  }
  &:nth-of-type(3) {
    top: 11rem;
    left: 6.5rem;
    animation-delay: 1350ms;
  }
  &:nth-of-type(4) {
    top: 15rem;
    left: 18.5rem;
    animation-delay: 1000ms;
  }
}
</style>
