import type { AnimatedObjectWithoutPathsInterface } from '@/map-phaser-new/interfaces'
import type { SpecificObjectInterface } from '@/map-phaser-new/interfaces/scene/objects/specificObjects/specificObjectInterface'
import { BaseObjectHandler } from '@/map-phaser-new/utils/abstractClasses'

export class AnimatedObjectsWithoutPathsHandler extends BaseObjectHandler<
  SpecificObjectInterface[]
> {
  constructor(gameFromEnv: string, activeScene: Phaser.Scene) {
    super(gameFromEnv, activeScene)
  }

  public async setUp(configData: AnimatedObjectWithoutPathsInterface[]): Promise<void> {
    const promises = configData.map((animationConfig) =>
      this.createAnimatedObjects(animationConfig),
    )
    await Promise.all(promises)
  }

  private async createAnimatedObjects(
    animationConfig: AnimatedObjectWithoutPathsInterface,
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      const generatedFrame = this.activeScene.anims.generateFrameNumbers(
        animationConfig.textureKey,
        animationConfig.frameDataForGenerate,
      )
      const config = {
        key: animationConfig.animationName,
        frames: generatedFrame,
        frameRate: animationConfig.frameRate,
        repeat: animationConfig.repeat,
      }

      this.activeScene.anims.create(config)
      this.activeScene.add
        .sprite(animationConfig.position.x, animationConfig.position.y, animationConfig.textureKey)
        .play(animationConfig.animationName)
        .setOrigin(0)
      resolve()
    })
  }
}
