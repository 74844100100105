import {
  taskchainClaimRewards as TASKCHAIN_CLAIM_REWARDS_ENDPOINT,
  taskchainConfig as TASKCHAIN_CONFIG_ENDPOINT,
  taskchainSetTeam as TASKCHAIN_SET_TEAM_ENDPOINT,
  taskchainSkipTask as TASKCHAIN_SKIP_TASK_ENDPOINT,
  taskchainState as TASKCHAIN_STATE_ENDPOINT,
} from '../../../globalVariables'
import { internalAxios } from '@/plugins/vueAxios'

import type Reward from '@/interfaces/Reward'
import { defineStore } from 'pinia'
import type TaskchainClaimRewardsApiResponse from '../../../interfaces/responses/events/TaskchainClaimRewardsApiResponse'
import type TaskchainConfigApiResponse from '../../../interfaces/responses/events/TaskchainConfigApiResponse'
import type SignpostApiResponse from '../../../interfaces/responses/events/TaskchainSignpostApiResponse'
import type TaskchainSkipApiResponse from '../../../interfaces/responses/events/TaskchainSkipApiResponse'
import type TaskchainStateApiResponse from '../../../interfaces/responses/events/TaskchainStateApiResponse'
import type { ProgressData } from '@/interfaces/global/Progress'

export interface TaskchainState {
  config: TaskchainConfigApiResponse | null
  state: TaskchainStateApiResponse | null
  sessionModalData: { rewards: Reward[] } | null
}

export interface TaskchainSetTeamActionPayload {
  chainId?: number | null
  teamId: number
}

export interface TaskchainSkipTaskActionPayload {
  taskId: string
}

export const useTaskChainStore = defineStore('taskChainStore', {
  state: (): TaskchainState => ({
    config: null,
    state: null,
    sessionModalData: null,
  }),
  getters: {
    getChains():
      | (TaskchainState['config']['chains'][0] & TaskchainState['state']['actual_selections'][0])[]
      | null {
      return (
        this.config?.chains?.map(
          (
            chain: TaskchainState['config']['chains'][0],
          ): TaskchainState['config']['chains'][0] &
            TaskchainState['state']['actual_selections'][0] => {
            const selection =
              (this.state?.actual_selections ?? null)?.find(
                ({ chain_id }: TaskchainState['state']['actual_selections'][0]): boolean =>
                  chain_id === chain.chain_id,
              ) ?? null
            return { ...chain, ...selection }
          },
        ) ?? null
      )
    },
    getCurrentChainId(): TaskchainState['state']['chain_id'] | null {
      return this.state?.chain_id ?? null
    },
    getCurrentChain():
      | (TaskchainState['config']['chains'][0] & TaskchainState['state']['actual_selections'][0])
      | null {
      return (
        this.getChains?.find(
          ({ chain_id }: TaskchainState['config']['chains'][0]): boolean =>
            chain_id === this.getCurrentChainId,
        ) ?? null
      )
    },
    getIsChainsCompleted(): boolean {
      return (
        this.getChains?.every(
          ({
            max_completed_chain_difficulty,
          }:
            | (TaskchainState['config']['chains'][0] &
                TaskchainState['state']['actual_selections'][0])
            | null): boolean => max_completed_chain_difficulty === 3,
        ) ?? false
      )
    },
    getAvailableTeams(): (1 | 2)[] {
      return Object.keys(this.getCurrentChain?.selections ?? {}).map((string: string): number =>
        Number(string),
      ) as (1 | 2)[]
    },
    getTasks(): TaskchainState['state']['tasks'] | null {
      return this.state?.tasks ?? null
    },
    getIsBossFight(): boolean {
      return (this.getTasks?.length === 1 && this.getTasks[0]?.task.includes('boss_fight')) ?? false
    },
    getBossData(): TaskchainState['state']['boss_data'] | null {
      return this.state?.boss_data ?? null
    },
    getCurrentTeamId(): TaskchainState['state']['selection'] | null {
      return this.state?.selection ?? null
    },
    getCurrentDifficulty(): TaskchainState['state']['difficulty'] | null {
      return this.getCurrentChain?.difficulty ?? this.state?.difficulty ?? null
    },
    getCurrentStep(): TaskchainState['state']['step'] | null {
      return this.state?.step ?? null
    },
    getCanClaimRewards(): TaskchainState['state']['reward_ready'] | null {
      return this.state?.reward_ready ?? null
    },
    getRewards(): TaskchainState['state']['rewards'] | null {
      return this.state?.rewards ?? null
    },
    getSessionModalRewards(): TaskchainState['sessionModalData']['rewards'] | null {
      return this.sessionModalData?.rewards ?? null
    },
  },
  actions: {
    setTaskChainSessionModalData({ rewards }: ProgressData): void {
      this.sessionModalData = rewards ? { rewards } : null
    },
    setChain({ chainId }: { chainId: number }): void {
      if (!this.state) throw new Error('Taskchain state not loaded yet!')
      this.state.chain_id = chainId
    },
    async loadConfig(force: boolean = false): Promise<void> {
      if (this.config && !force) return
      try {
        if (this.config !== null) return
        const data = await internalAxios.get<{}, TaskchainConfigApiResponse>(
          TASKCHAIN_CONFIG_ENDPOINT,
        )
        if (!data) return
        this.config = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadState(): Promise<void> {
      try {
        const data = await internalAxios.get<{}, TaskchainStateApiResponse>(
          TASKCHAIN_STATE_ENDPOINT,
        )
        if (!data) return
        if (data.active_chains_ids.length === 1) data.chain_id = data.active_chains_ids[0]
        this.state = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async setTeam({ chainId, teamId }: TaskchainSetTeamActionPayload): Promise<void> {
      try {
        const { state: data } = await internalAxios.post<{}, SignpostApiResponse>(
          TASKCHAIN_SET_TEAM_ENDPOINT,
          {
            chain_id: chainId || this.getCurrentChainId,
            team: teamId,
          },
        )

        if (data) {
          if (data.active_chains_ids.length === 1) data.chain_id = data.active_chains_ids[0]
          this.state = data
          return
        }

        await this.loadState()
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async skipTask({ taskId }: TaskchainSkipTaskActionPayload): Promise<void> {
      try {
        const { state: data } = await internalAxios.post<{}, TaskchainSkipApiResponse>(
          TASKCHAIN_SKIP_TASK_ENDPOINT,
          { task: taskId },
        )

        if (data) {
          if (data.active_chains_ids.length === 1) data.chain_id = data.active_chains_ids[0]
          this.state = data
          return
        }

        await this.loadState()
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async claimRewards(): Promise<void> {
      try {
        const { state: data } = await internalAxios.post<{}, TaskchainClaimRewardsApiResponse>(
          TASKCHAIN_CLAIM_REWARDS_ENDPOINT,
        )

        if (data) {
          if (data.active_chains_ids.length === 1) data.chain_id = data.active_chains_ids[0]
          this.state = data
          return
        }

        await this.loadState()
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
