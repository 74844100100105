<template>
  <div v-if="!messages?.length">
    <div class="mail-table">
      <mail-table-header :sent="sent" />
    </div>
    <div class="no-mail-wrapper flexing">
      <div class="no-mail flexing text-texts-standard-default text-50">
        <p>{{ $t('post.noMessages') }}</p>
      </div>
    </div>
  </div>
  <div v-else class="mail-table">
    <mail-table-header :sent="sent" />
    <app-scrollbar
      :id="'mail-table'"
      width="98%"
      height="56%"
      scroll="y"
      slice="y"
      class="absolute"
      emit-on-scrolled-bottom
      @scrolled-to-bottom="$emit('loadMore')"
    >
      <div class="table-standard skew-right">
        <div
          v-for="message in messages"
          :key="message.id"
          class="mail-table-row cursor-pointer table-standard-row relative text-34 text-texts-standard-default flex items-center"
          @click="
            $router.push({
              name: $getWebView('MailDetail'),
              params: { id: message.id },
            })
          "
        >
          <div class="table-standard-col h-full flexing col-checkbox">
            <app-check-box
              size="sm"
              :is-checked="isChecked(message.id)"
              @click.stop
              @checked-action="$emit('selectMessage', message.id)"
            />
          </div>
          <div class="table-standard-col h-full flex items-center col-subject">
            <p class="text-32 truncate">
              {{ message.subject }}
            </p>
          </div>
          <div class="table-standard-col h-full flexing col-user">
            <app-user
              :id="message.user.id || ''"
              :country="message.user.country"
              :name="message.user.name"
              :badges-data="message.user.eventBadges ?? null"
            />
          </div>
          <div class="table-standard-col h-full flexing col-date flex-col">
            <p class="font-bold text-32">
              {{ getFormattedDateFromYMD(message.date) }}
            </p>
            <p class="text-28">
              {{ getFormattedTimeFromHMS(message.date) }}
            </p>
          </div>
          <div class="table-standard-col h-full flexing col-icon">
            <span v-if="message.status === 'new'" class="mail-unread-icon" />
            <span v-else class="mail-opened-icon" />
          </div>
        </div>
      </div>
    </app-scrollbar>
  </div>
</template>

<script lang="ts">
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import MailTableHeader from '@/components/Mail/MailTableHeader.vue'
import { pathImages } from '@/globalVariables'
import { getFormattedDateFromYMD, getFormattedTimeFromHMS } from '@/helpers'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { FormattedMailMessage } from '@/interfaces/MailMessage'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'MailTable',
  components: {
    MailTableHeader,
    AppUser,
  },
  props: {
    messages: {
      type: Array as PropType<FormattedMailMessage[]>,
      default: () => [],
    },
    selectedMessageIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    sent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectMessage', 'loadMore'],
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  methods: {
    getFormattedDateFromYMD,
    getFormattedTimeFromHMS,
    isChecked(messageId: string): boolean {
      return this.selectedMessageIds.includes(messageId)
    },
  },
})
</script>
<style lang="scss" scoped>
.mail-table {
  width: 98%;
  margin: 0 auto;

  &-row {
    height: 5.125rem;
    margin-top: 1rem;
    line-height: 2.2rem;

    &:last-child {
      margin-bottom: 5rem;
    }
  }
}

.mail-opened-icon,
.mail-unread-icon,
.mail-reply-icon {
  width: 3.625rem;
  height: 2.688rem;
  background: url($path-mail + 'message-statuses.avif') 0 0 no-repeat;
  background-size: cover;
  transform: scale(1.5);
}

.mail-unread-icon {
  background-position: 0rem 0;
}

.mail-reply-icon {
  background-position: -4rem 0;
}

.mail-opened-icon {
  background-position: -8rem 0;
}

.col {
  &-checkbox {
    width: 4rem;
  }

  &-subject {
    width: 30rem;
  }

  &-subject {
    padding: 0 5rem 0 3rem;
  }

  &-user {
    width: 30rem;
  }

  &-user {
    justify-content: left;
    padding-left: 2rem;

    &-flag {
      width: 3.063rem;
      height: 2rem;
      margin-right: 1.5rem;
      background-size: contain;
    }
  }

  &-date {
    width: 30rem;
  }

  &-icon {
    width: 18.875rem;
    position: absolute;
    right: 0;
  }
}

.no-mail {
  background: linear-gradient(
    to right,
    transparent,
    rgba($color: #4787b4, $alpha: 0.3) 10%,
    rgba($color: #4787b4, $alpha: 0.3) 40%,
    rgba($color: #4787b4, $alpha: 0.3) 50%,
    rgba($color: #4787b4, $alpha: 0.3) 60%,
    rgba($color: #4787b4, $alpha: 0.3) 90%,
    transparent
  );
  width: 72rem;
  height: 11rem;
  margin: 0 auto;

  &-wrapper {
    height: 50%;
    margin-top: 10rem;
  }
}
</style>
