import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const boatLeftToRightAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'boatLeftToRightAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '19',
      delay: 200,
      duration: 3000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 19,
        end: 15,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatLeftToRightAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '15',
      delay: 0,
      duration: 1300,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 15,
        end: 16,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatLeftToRightAnimations3',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '16',
      delay: 200,
      duration: 1300,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 16,
        end: 15,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatLeftToRightAnimations4',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '15',
      delay: 200,
      duration: 1300,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 15,
        end: 18,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatLeftToRightAnimations5',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '18',
      delay: 200,
      duration: 1500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 18,
        end: 14,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatLeftToRightAnimations6',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '14',
      delay: 0,
      duration: 1300,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 14,
        end: 16,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatLeftToRightAnimations7',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '16',
      delay: 0,
      duration: 1300,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 16,
        end: 18,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatLeftToRightAnimations8',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '18',
      delay: 0,
      duration: 1300,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 18,
        end: 20,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatLeftToRightAnimations9',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '20',
      delay: 0,
      duration: 3000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 20,
        end: 27,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatLeftToRightAnimations10',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '27',
      delay: 0,
      duration: 4000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 27,
        end: 18,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatLeftToRightAnimations11',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '18',
      delay: 0,
      duration: 2000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 18,
        end: 13,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatLeftToRightAnimations12',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '13',
      delay: 0,
      duration: 1500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 13,
        end: 15,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'boatLeftToRightAnimations13',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '15',
      delay: 0,
      duration: 2500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 15,
        end: 18,
        zeroPad: 2,
      },
    },
  },
]
