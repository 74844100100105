<template>
  <div class="w-full h-full">
    <section v-if="logoType === 'avatar'" class="w-full h-full">
      <div class="w-full h-full" @click="openProfile()">
        <div
          class="avatar-menu-content-logo-bg"
          :style="{
            backgroundImage: `url(${pathImages}avatars/backgrounds/${avatarBg}.avif)`,
          }"
        />
        <div
          class="avatar-menu-content-logo-avatar"
          :style="{
            backgroundImage: `url(${pathImages}avatars/${sex}/${avatar}${
              animatedAvatars.includes(avatar.toString()) ? '.gif' : '.avif'
            })`,
          }"
        />
        <div class="avatar-menu-content-logo-circle" />
      </div>
      <avatar-menu-club-logo v-if="isClubLogoVisible" />
    </section>
    <section v-else class="w-full h-full" @click="openClubMember">
      <div
        class="avatar-menu-content-logo-bg"
        :style="{
          backgroundImage: `url(${pathImages}clubs/logos/background/club-bg-${getLogoBgId}.avif)`,
        }"
      />
      <div
        class="avatar-menu-content-logo-avatar"
        :style="{
          backgroundImage: `url(${pathImages}clubs/logos/logo/club-logo-${getLogoId}.avif)`,
        }"
      />
    </section>
  </div>
</template>

<script lang="ts">
import AvatarMenuClubLogo from '@/components/AvatarMenu/AvatarMenuClubLogo.vue'
import { MECHANIC_CLUB, animatedAvatars, pathImages } from '@/globalVariables'
import { isMyClub, playSound } from '@/helpers'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  pathImages: typeof pathImages
  animatedAvatars: typeof animatedAvatars
}

export default defineComponent({
  name: 'AvatarMenuLogo',
  components: {
    AvatarMenuClubLogo,
  },
  props: {
    logoType: {
      type: String,
      default: 'avatar',
    },
  },
  data(): ComponentData {
    return {
      pathImages,
      animatedAvatars,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      sex: 'getSex',
      avatar: 'getAvatar',
      avatarBg: 'getAvatarBg',
      clubLogo: 'getClubLogo',
      clubLogoBg: 'getClubLogoBg',
    }),
    ...mapState(useDisciplineStore, {
      getUnlockedDisciplinesIds: 'getUnlockedDisciplinesIds',
    }),
    ...mapState(useClubStore, {
      clubInfo: 'getClubInfo',
    }),
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
    }),

    isClubLogoVisible(): boolean {
      return this.hasMechanic(MECHANIC_CLUB)
    },
    getLogoId(): number {
      return isMyClub(this.$router) ? this.clubLogo : this.clubInfo.logoId
    },
    getLogoBgId(): number {
      return isMyClub(this.$router) ? this.clubLogoBg : this.clubInfo.logoBgId
    },
  },
  methods: {
    openProfile(): void {
      playSound('profil_open')
      this.$router.push({
        name: this.$getWebView('ProfileOverview'),
        params: { id: this.getUnlockedDisciplinesIds[0] },
      })
    },
    openClubMember(): void {
      if (!isMyClub(this.$router)) return

      this.$router.push({ name: this.$getWebView('ClubsMembers') })
    },
  },
})
</script>
