import { BUILDING_LOCKED_TOOLTIP_POSITION } from '@/map-phaser-new/constants'
import type { BuildingConfigInterface } from '@/map-phaser-new/interfaces'

export const clubChampionshipConfig: BuildingConfigInterface = {
  textureKey: 'buildings',
  frameKey: 'club_championship',
  routeConfig: {
    routeName: 'ClubsChampionshipCompetitions',
    soundName: 'click-map-artefact',
    isRouteParamIdNeeded: false,
    changeScene: true,
  },
  positionX: 2677,
  positionY: 924,
  depth: 4,
  originX: 0,
  keyForTranslateTitle: 'map.clubChampionshipsName',
  buildingTitleConfig: {
    positionX: 2822,
    positionY: 1170,
    depth: 7,
    textConfig: {
      originX: 0.5,
      positionX: -40,
      fontName: 'TT Lakes Condensed',
      fontSize: '24px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontColor: '#fff',
    },
    backgroundImageConfig: {
      textureName: 'common',
      frameNameRightSide: 'right_side',
      frameNameRightSideLocked: 'right_side_locked',
      frameNameLeftSide: 'left_side',
      frameNameLeftSideLocked: 'left_side_locked',
      originX: 0.5,
      widthCorrection: 100,
      heightCorrection: 10,
    },
  },
  outlineConfig: {
    framePostFix: '_outline',
    depth: 6,
    originX: 0,
    positionByLevel: {
      '1': { positionX: 2677, positionY: 924 },
    },
    textureKey: 'buildingsOutlines',
  },
  inputConfig: {
    useHandCursor: true,
  },
  buildingLockedConfig: {
    textureKey: 'lockedBuildings',
    framePostfix: '_locked',
    lockImageTextureKey: 'lock',
    positionX: 2793,
    positionY: 1053,
    lockImageDepth: 6,
    depth: 5,
    tooltipConfig: {
      tooltipPosition: BUILDING_LOCKED_TOOLTIP_POSITION.Top,
      tooltipTextureHeight: '213px',
      tooltipTextureWidth: '444px',
      depth: 10,
      titleConfig: {
        originX: 0.5,
        fontSize: '30px',
        fontName: 'TT Lakes Condensed',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontColor: '#fff',
      },
      subtitleConfig: {
        originX: 0.5,
        originY: 0.5,
        fontName: 'TT Lakes Condensed',
        fontSize: '24px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontColor: '#fff',
        replaceFontColor: '#fec742',
        tooltipSubtitleTranslateKey: 'common.mainCareerTaskRequired',
        tooltipSubtitleReplaceFlag: '%s',
      },
      tooltipHideDelay: 2000,
      tooltipLeftTextureName: 'building_tooltip_left',
      tooltipRightTextureName: 'building_tooltip_right',
      tooltipTopTextureName: 'building_tooltip_top',
    },
  },
  buildingLabelNewConfig: {
    textureName: 'building_new_label',
    positionX: 2812,
    positionY: 1197,
    originX: 0.5,
    depth: 8,
  },
  buildingClLabelInfoConfig: {
    texture: 'common',
    backgroundFrame: 'bg',
    positionX: 2812,
    positionY: 1238,
    arrowLineFrame: 'line',
    arrowFramePostfix: 'Arrow',
    arrowOriginX: 0.5,
    arrowX: 35,
    arrowY: -2.5,
    badgeX: -35,
    badgeY: -5,
    badgeOriginX: 0.5,
    badgeFramePostfix: 'Badge',
    depth: 8,
    htmlTextConfig: {
      originX: 0.5,
      fontName: 'TT Lakes Condensed',
      fontSize: '32px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontColor: '#fff',
    },
  },
}
