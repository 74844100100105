<template>
  <div v-if="!isContentLoading" class="club-panel text-texts-standard-default text-32">
    <div class="club-panel-avatar">
      <clubs-avatar :background-id="getClubInfo.logoBgId" :logo-id="getClubInfo.logoId" />
    </div>
    <section class="club-panel-name flexing font-bold">
      <app-user :country="getClubInfo.country" :name="getClubInfo.name" />
    </section>

    <section class="club-panel-parameters">
      <article v-for="(param, index) in getPatameters" :key="index" class="parameter flexing">
        <app-main-icon v-if="param.icon === 'prestige'" :icon-name="param.icon" :icon-size="56" />
        <app-icon
          v-else
          v-tippy="{
            theme: 'WSM',
            content: $t('club.' + param.tooltip),
            placement: 'top',
          }"
          class="parameter-icon"
          :icon-name="param.icon"
        />
        <div class="parameter-box flexing">
          <p class="revert-skew text-36">
            {{ $filters.$formatNumber(param.value) }}
          </p>
        </div>
      </article>
    </section>
    <div class="club-panel-info text-32 font-bold flexing">
      <div class="club-panel-info-level flexing">
        <app-icon
          v-tippy="{
            theme: 'WSM',
            content: $t('club.clubHouse'),
            placement: 'top',
          }"
          icon-name="clubhouse"
          class="mx-4"
        />
        <p>{{ getClubInfo.clubhouseLevel }}</p>
      </div>
      <div class="club-panel-info-players flexing ml-4">
        <app-icon
          v-tippy="{
            theme: 'WSM',
            content: $t('club.clubPlayers'),
            placement: 'top',
          }"
          icon-name="members"
        />
        <aside class="flex">
          <p>{{ getClubInfo.members.length }}</p>
          <p>/</p>
          <p>{{ getClubInfo.membersMaxLength }}</p>
        </aside>
      </div>
    </div>
    <p class="text-28 uppercase">
      {{ $t('club.' + clubAvailability(getClubInfo.type)) }}
    </p>
    <app-button
      class="m-auto panel-button"
      :btn-type="panelButton"
      :btn-text="$t(buttonText)"
      @click="statusChange()"
    />

    <clubs-leave-popup v-if="leaveClubPopup" @close="leaveClubPopup = false" />
  </div>

  <component-loading
    width="29.25rem"
    height="48.625rem"
    type="secondary"
    :is-loading="isContentLoading"
  />
</template>

<script lang="ts">
import ClubsAvatar from '@/components/Club/ClubsAvatar.vue'
import ClubsLeavePopup from '@/components/Club/Popups/ClubsLeavePopup.vue'
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import { pathImages } from '@/globalVariables'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PopupData } from '@/interfaces/PopupData'

interface ComponentData {
  leaveClubPopup: boolean
  rejoinClubConstant: number
  pathImages: typeof pathImages
}
interface ClubPanelParam {
  icon: string
  value: number
  tooltip?: string
}

export default defineComponent({
  name: 'ClubsPanel',
  components: {
    ClubsAvatar,
    ClubsLeavePopup,
    AppUser,
  },
  data(): ComponentData {
    return {
      leaveClubPopup: false,
      rejoinClubConstant: 24,
      pathImages,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      isClubManager: 'isClubManager',
    }),
    ...mapState(useClubStore, {
      getClubInfo: 'getClubInfo',
    }),
    panelButton(): string {
      return this.isClubManager ? 'confirm' : 'danger'
    },
    buttonText(): string {
      return this.isClubManager ? 'club.clubSettings' : 'club.leaveClub'
    },
    getLeaveClubData(): PopupData {
      return this.$filters.$submitPopupData({
        title: this.$t('club.leaveClub'),
        btnText: null,
        btnType: 'danger',
        btnId: 'confirm-leave-club-button',
      })
    },
    getPatameters(): ClubPanelParam[] {
      return [
        { icon: 'prestige', value: this.getClubInfo?.prestige ?? 0 },
        {
          icon: 'tournament-titles',
          value: this.getClubInfo?.tournament_titles ?? 0,
          tooltip: 'tournamentTitles',
        },
        {
          icon: 'master-titles',
          value: this.getClubInfo?.master_titles ?? 0,
          tooltip: 'championshipTitles',
        },
      ]
    },
    isContentLoading(): boolean {
      return this.getClubInfo.id === ''
    },
  },
  async created(): Promise<void> {
    await this.loadClubInfo()
  },

  methods: {
    ...mapActions(useClubStore, {
      loadClubInfo: 'loadClubInfo',
    }),
    statusChange(): void {
      if (this.isClubManager) {
        this.$router.push({ name: this.$getWebView('ClubsEditClub') })
      } else {
        this.leaveClubPopup = true
      }
    },
    clubAvailability(type: string): string {
      if (type === 'open') return 'open'
      else if (type === 'request') return 'onDemand'
      else return 'closed'
    },
  },
})
</script>

<style lang="scss" scoped>
.club-panel {
  width: 29.25rem;
  height: 48.625rem;
  background: url($path-clubs + 'components/clubPanel/BG_CLUB_LEFT_BOX.avif') center no-repeat;
  background-size: 100% 100%;
  padding-top: 1.3125rem;
  flex: 0 0 auto;

  &-avatar {
    margin: 0 auto;
    width: 14.625rem;
    height: 14.625rem;
  }

  &-name {
    margin: 0.9375rem auto 1.375rem auto;
    height: 3.875rem;
    width: 25.75rem;
    @if $isWsm {
      background: linear-gradient(to left, transparent, #144a73, transparent);
    }
    @if $isSsm {
      background: linear-gradient(to left, transparent, #7c7bb4, transparent);
    }

    .flag {
      width: 3.0625rem;
      height: 2.0625rem;
      margin-right: 0.8125rem;
      margin-left: 0.5625rem;
    }
  }

  &-parameters {
    .parameter {
      margin-bottom: 1.125rem;

      &-box {
        width: 13.5rem;
        height: 3.25rem;
        transform: $skewX-value;
        border-radius: 0.125rem;
        font-size: 2.25rem;
        box-shadow:
          inset -0.4375rem 0 1.3125rem 0 rgba(51, 123, 214, 0.19),
          inset -0.019625rem -0.1865rem 2.5rem 0 rgba(6, 34, 55, 0.48);
        @if $isWsm {
          background: rgb(15, 50, 81);
          border: 0.125rem solid #f4d91e;
        }
        @if $isSsm {
          background: #363e5d;
          border: 0.125rem solid #fff284;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .parameter-icon {
        width: 3.5rem;
        height: 3.5rem;
        margin: 0 0.5rem;
      }
    }
  }

  &-info {
    height: 2.875rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .panel-button {
    margin-top: 0.4375rem;
  }
}
</style>
