<template>
  <teleport to="#app">
    <div v-if="elementVisible" class="popup-overlay fixed left-0 top-0" @click="hideInfoMessage" />
    <transition name="fade">
      <div v-if="elementVisible" class="info-message flex flex-col items-center">
        <header class="flexing">
          <div v-if="normalizedInfoMessageData.hasDecoration" class="info-message-decor" />
          <h2
            class="text-40 uppercase"
            :class="`text-texts-standard-${normalizedInfoMessageData.headStyle}`"
          >
            {{ normalizedInfoMessageData.headerText }}
          </h2>
        </header>
        <main class="flexing flex-col mt-4">
          <p class="text-36 text-texts-standard-default uppercase">
            {{ normalizedInfoMessageData.infoText }}
          </p>
          <slot />
        </main>
        <footer class="w-full">
          <slot name="footer" />
        </footer>
        <app-button
          v-if="normalizedInfoMessageData.buttonText"
          btn-id="hide-info-message"
          btn-type="secondary"
          :btn-text="normalizedInfoMessageData.buttonText"
          btn-size="md"
          @click="hideInfoMessage"
        />
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useManagementStore } from '@/store/pinia/clubs/managementStore'
import { useNotificationStore } from '@/store/pinia/clubs/notificationStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface ComponentData {
  elementVisible: boolean
}

type HeadStyle = 'additional' | 'default' | 'important' | 'name'

export interface InfoMessageData {
  hasDecoration?: boolean
  headerText?: string
  buttonText?: string
  infoText?: string
  headStyle?: HeadStyle
  footText?: string
  footStyle?: string
}

export default defineComponent({
  name: 'InfoMessage',
  props: {
    infoMessageData: {
      type: Object as PropType<InfoMessageData>,
      default(): InfoMessageData {
        return {
          hasDecoration: false,
          headerText: '',
          buttonText: '',
          infoText: '',
          headStyle: 'additional', // 'additional', 'default', 'important', 'name'
        }
      },
    },
  },
  emits: ['infoBtnClick'],
  data(): ComponentData {
    return {
      elementVisible: true,
    }
  },
  computed: {
    normalizedInfoMessageData(): InfoMessageData {
      return Object.assign(
        {
          hasDecoration: false,
          headerText: '',
          buttonText: '',
          infoText: '',
          headStyle: 'additional',
        },
        this.infoMessageData,
      )
    },
  },
  created(): void {
    if (!this.normalizedInfoMessageData.buttonText) {
      setTimeout((): void => {
        this.hideInfoMessage()
      }, 4000)
    }
  },
  methods: {
    ...mapActions(usePremiumStore, ['setReward']),
    hideInfoMessage(): void {
      const clubStore = useClubStore()
      const notifStore = useNotificationStore()
      const managementStore = useManagementStore()
      managementStore.setUserPromotedStatus(false)
      clubStore.setChangesSaved(false)
      clubStore.setClubCreated(false)
      clubStore.setClubDeleted(false)
      notifStore.removeUpdatedRoleNotification()
      this.setReward(false)
      if (this.normalizedInfoMessageData.buttonText) {
        this.$emit('infoBtnClick')
      }
      this.elementVisible = false
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/popups.scss';
@import '@/assets/styles/components/info-message.scss';

.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
