import type { AtlasDataInterface } from '@/map-phaser-new/interfaces'

/**
 * This method is used to load atlas of images, compressed or base.
 * @param scene - instance of Phaser.Scene
 * @param loadArray - array of atlases
 * @returns A Promise that resolves when all loadings of atlases are complete.
 */

export const loadAtlasData = (
  scene: Phaser.Scene,
  loadArray: AtlasDataInterface[],
): Promise<void> => {
  return new Promise<void>((resolve) => {
    const promises: Promise<void>[] = []

    loadArray.forEach((atlasData) => {
      const loadPromise = new Promise<void>((innerResolve) => {
        if (atlasData.compressed) {
          if (atlasData.multiatlas) {
            loadCompressedMultiAtlas(scene, atlasData)
          } else {
            loadCompressedAtlas(scene, atlasData)
          }
        } else {
          if (atlasData.multiatlas) {
            scene.load.multiatlas(atlasData.name, atlasData.data.img.multiAtlasURL)
          } else {
            scene.load.atlas(atlasData.name, atlasData.data.image, atlasData.data.json)
          }
        }

        innerResolve()
      })
      promises.push(loadPromise)
    })

    Promise.all(promises).then(() => {
      scene.load.start()
      resolve()
    })
  })
}

function loadCompressedAtlas(scene: Phaser.Scene, atlasData: AtlasDataInterface): void {
  const { data } = atlasData
  const { astc, etc, img } = data

  scene.load.texture(atlasData.name, {
    ASTC: {
      type: astc.type,
      textureURL: astc.textureURL,
      atlasURL: astc.dataFile,
    },
    ETC: {
      type: etc.type,
      textureURL: etc.textureURL,
      atlasURL: etc.dataFile,
    },
    IMG: {
      textureURL: img.textureURL,
      atlasURL: img.dataFile,
    },
  })
}

function loadCompressedMultiAtlas(scene: Phaser.Scene, atlasData: AtlasDataInterface): void {
  const { data } = atlasData
  const { astc, etc, img } = data

  scene.load.texture(atlasData.name, {
    ASTC: {
      type: astc.type,
      multiAtlasURL: astc.multiAtlasURL,
    },
    ETC: {
      type: etc.type,
      multiAtlasURL: etc.multiAtlasURL,
    },
    IMG: {
      multiAtlasURL: img.multiAtlasURL,
    },
  })
}
