<template>
  <div class="header-money-tootlip">
    <div class="header-money-tootlip-title">
      <span class="text-texts-standard-default flexing">
        <p class="font-bold uppercase">{{ $t('map.money') }}:</p>
        <p class="ml-4">{{ $filters.$formatNumber(value) }}</p>
      </span>
      <div class="yellow-strip" />
    </div>
    <div class="header-money-tootlip-text">
      <p class="text-texts-standard-additional">
        {{ $t('map.moneyText1') }}
      </p>
      <div class="header-money-tootlip-text-strip" />
    </div>
    <div class="header-money-tootlip-text">
      <p class="text-texts-standard-additional">
        {{ $t('map.moneyText2') }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HeaderMoneyTooltip',
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">
.header-money-tootlip {
  width: 22rem;

  .yellow-strip {
    height: 0.125rem;
    margin: 1rem 0;
  }

  .header-money-tootlip-text-strip {
    width: 100%;
    height: 0.125rem;
    border-bottom: 0.125rem solid gray;
    margin: 1rem 0;
  }
}
</style>
