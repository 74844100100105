<template>
  <clubs-reminder v-if="tutorial" :tutorial="true" />
  <div id="club-available" class="club-available app-page-wrapper absolute flex flex-col">
    <menu-component menu-type="club-available" :title="$t('club.availableClubs')">
      <div v-if="countries" class="club-available-select-area flexing">
        <app-select
          :options="countriesData"
          :default="allCountries.name"
          :placeholder="allCountries.name"
          direction="bottom"
          @select="selectCountry"
        />
      </div>
    </menu-component>
    <section class="flex w-full h-full flexing">
      <div
        v-if="!isAvailableClubsLoading"
        class="club-available-content w-full h-full flex flex-col"
        :class="$isMobile() && 'relative'"
      >
        <div class="club-available-content-table flex flex-col flexing safe-area-x">
          <table-component
            :columns="columns"
            :items="getAvailableClubs"
            :show-tools="showToolsData"
            :no-data-text="$t('club.noClubsFound')"
            force-scroll-size="100%"
            which-id="clubId"
            @button-click="openClubDetail"
            @search-event="searchByName"
            @sort-event="orderBy"
          />
        </div>

        <footer class="safe-area">
          <clubs-available-footer
            class="py-2"
            @emit-invitations="openInvitations"
            @emit-create="openCreateClub"
          />
        </footer>
      </div>

      <component-loading height="80%" :is-loading="isAvailableClubsLoading" />
    </section>
  </div>
</template>

<script lang="ts">
import ClubsAvailableFooter from '@/components/Club/ClubsAvailableFooter.vue'
import ClubsReminder from '@/components/Club/ClubsReminder.vue'
import TableComponent from '@/components/Table/TableComponent.vue'
import { clubTableKeys } from '@/components/Table/tableKeys'
import { formatCountries, getOrderBodyContent } from '@/helpers'
import { useAvailableClubStore } from '@/store/pinia/clubs/availableClubsStore'
import { useCountriesStore } from '@/store/pinia/countriesStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { BodyContent } from '@/helpers/orderBy'
import type { NameValueString } from '@/interfaces/Global'
import type { ClubsColumnData } from '@/interfaces/clubs/Clubs'

interface ComponentData {
  formatCountries: typeof formatCountries
  selectedCountry: string
  clubTableKeys: typeof clubTableKeys
  columns: ClubsColumnData[]
  showToolsData: {
    search: boolean
    filter: boolean
    about: boolean
  }
}

export default defineComponent({
  name: 'ClubsAvailable',
  components: {
    TableComponent,
    ClubsAvailableFooter,
    ClubsReminder,
  },
  data(): ComponentData {
    return {
      formatCountries,
      selectedCountry: '',
      clubTableKeys,
      columns: [
        // if it's icon pass to label icon name and set isIcon to true
        { label: this.$t('settings.country'), key: clubTableKeys.country, width: 10 },
        {
          label: this.$t('common.name'),
          key: clubTableKeys.clubNameComponent,
          align: 'left',
          width: 20,
          avatar: true,
        },
        { label: this.$t('club.clubPlayers'), key: clubTableKeys.members, width: 20 },
        { label: 'prestige', key: clubTableKeys.prestige, isIcon: true, width: 10 },
        { label: this.$t('common.type'), key: clubTableKeys.type, width: 10 },
        {
          label: '',
          disableSort: true,
          key: clubTableKeys.button,
          buttonData: { type: 'primary', size: 'md', text: 'club.clubProfile' },
          align: 'right',
          width: 30,
        },
      ],
      showToolsData: {
        search: true,
        filter: false,
        about: true,
      },
    }
  },

  computed: {
    ...mapState(useCountriesStore, {
      countries: 'getCountries',
    }),
    ...mapState(useTutorialStore, {
      tutorial: 'getIsTutorial',
    }),
    ...mapState(useAvailableClubStore, ['getAvailableClubs', 'isAvailableClubsLoading']),
    allCountries(): NameValueString {
      return { value: 'all', name: this.$t('club.allCountries') }
    },
    countriesData(): NameValueString[] {
      const defaultCountry = [{ value: 'all', name: this.$t('club.allCountries') }]
      const countries = this.formatCountries(this.countries)
      const mergedCountries = defaultCountry.concat(countries)
      return mergedCountries
    },
  },
  async created(): Promise<void> {
    await this.loadAvailableClubs()
  },
  methods: {
    getOrderBodyContent,
    ...mapActions(useAvailableClubStore, ['loadAvailableClubs']),
    selectCountry(country: string): void {
      this.selectedCountry = country !== 'all' ? country : null
      this.loadAvailableClubs({ filter_country: this.selectedCountry })
    },
    searchByName(name: string): void {
      let bodyContent = name ? { filter_name: name } : {}
      if (this.selectedCountry) {
        bodyContent = {
          ...bodyContent,
          ...{ filter_country: this.selectedCountry },
        }
      }
      this.loadAvailableClubs(bodyContent)
    },
    orderBy(order: BodyContent): void {
      let bodyContent = this.getOrderBodyContent(order)
      if (this.selectedCountry) {
        bodyContent = { ...bodyContent, filter_country: this.selectedCountry }
      }
      this.loadAvailableClubs(bodyContent)
    },
    openClubDetail(id: string): void {
      this.$router.push({
        name: this.$getWebView('ClubsProfile'),
        params: { clubProfileId: id },
        query: { homepage: 'true' },
      })
    },
    openInvitations(): void {
      this.$router.push({
        name: this.$getWebView('ClubsInvitations'),
        query: { homepage: 'true' },
      })
    },
    openCreateClub(): void {
      this.$router.push({
        name: this.$getWebView('ClubsCreateClub'),
        params: { create: 'create' },
        query: { homepage: 'true' },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.club-available {
  &-content {
    flex: 1 1 auto;

    &-table {
      width: 98%;
      margin: 0 auto;
      padding-bottom: 0.5rem;
      flex: 1 1 0;
      min-height: 0;
    }
  }

  // TODO if the global table system is solved, we can delete this deep
  :deep() {
    .table-tools {
      margin: 2rem 0;
      width: 100%;
    }

    .table-component {
      display: flex;
      width: 100%;
      flex: 1 1 auto;
      overflow: hidden;
    }

    .table-component-wrapper {
      // Positional correction of the table in contrast to display: table
      display: flex;
      height: 100%;

      .y-scroll {
        // Offset for the scrollbar without blending with the table
        padding-right: 1.375rem;
      }
    }
  }

  footer {
    position: relative;
    width: 100%;
    // Size adjustment with regard to iOS
    height: calc(9.25rem + (var(--safe-area-inset-bottom) / 2));
    bottom: 0;
    left: 0;

    @if $isWsm {
      background-color: rgba(11, 30, 55, 0.9);
    }
    @if $isSsm {
      background-color: #252f45;
      border-top: 0.125rem solid #5672a4;
    }
  }
}
</style>
