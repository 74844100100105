<template>
  <div class="cursor-pointer uppercase flexing" @click="sort">
    <span
      :class="[
        {
          'text-texts-standard-important': highlighted,
        },
      ]"
    >
      {{ label }}
    </span>
    <sort-arrow ref="sortArrow" :highlighted="highlighted" :arrow-up="sortDesc" />
  </div>
</template>

<script lang="ts">
import { ORDER_TYPES } from '@/globalVariables'
import { defineComponent } from 'vue'
import SortArrow from './SortArrow.vue'

export default defineComponent({
  name: 'SortHeader',
  components: {
    SortArrow,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['sortEvent'],
  data() {
    return {
      sortAsc: false,
      sortDesc: false,
      orderType: ORDER_TYPES.default,
    }
  },
  methods: {
    sort() {
      if ((!this.sortAsc && !this.sortDesc) || !this.highlighted || this.sortDesc) {
        this.sortAsc = true
        this.sortDesc = false
        this.orderType = ORDER_TYPES.asc
      } else {
        this.sortDesc = true
        this.sortAsc = false
        this.orderType = ORDER_TYPES.desc
      }
      this.$emit('sortEvent', this.orderType)
    },
  },
})
</script>
