import type ParamStatReward from '@/interfaces/lab/ParamStatReward'
import type { Nullable } from '../utils'

export interface Improvement {
  name: string
  value: string
}

export interface Cell {
  id: string
  parent: ParamStatReward['value']
  placement: LineDirection
  positionLevel: number
  branchName: string
  currentLevel: ParamStatReward['value']
  gddId: ParamStatReward['value']
  state: string
  name: ParamStatReward['value']
  description: ParamStatReward['value']
  maxLevel: ParamStatReward['value']
  isNew: boolean
  icon: string
  gemOnly: ParamStatReward['value']
  requiredForResearch: {
    money: ParamStatReward['value']
    researchPoints: ParamStatReward['value']
    gems: ParamStatReward['value']
  }
  researchTimeDuration: ParamStatReward['value']
  locked: boolean
  unlockedAfterLevel: ParamStatReward['value']
  improvements: Improvement[]
  affectedDisciplines: ParamStatReward['value']
  effectDuration: ParamStatReward['value']
  repeatable: boolean
  isIconRepeatable: Nullable<boolean>
  blocksCells: ParamStatReward['value']
  researchInProgress: boolean
  researchTimer: number
  speedupPrice: number
  estimatedDate: ParamStatReward['value']
  lineSize: LineHeight
  skillType: SkillType
  operationId?: string
}

export enum LineHeight {
  Xs = 'xs',
  Sm = 'sm',
  Md = 'md',
  Xl = 'xl',
}

export enum LineStyle {
  Normal = 'normal',
  Special = 'special',
}

export enum LineDirection {
  Top = 'top',
  Left = 'left',
  Right = 'right',
}

export enum SkillType {
  Camps = 'camps',
  Research = 'research',
}

export enum CellType {
  Gem = 'gem',
  Unavailable = 'unavailable',
  Complete = 'complete',
  Basic = 'basic',
}

export interface CellInput {
  id: string
  parameters: ParamStatReward[]
  rewards: ParamStatReward[]
  state: { name: string }
  stats: ParamStatReward[]
  remaining_time: number
  speedup_price: number
  positionLevel: number
  lineSize: LineHeight
  skillType: string
}

export interface EconomicsColumnCell {
  gddId: number
  lineSize: LineHeight
  positionLevel: number
}

export interface BranchInput {
  name: string
  cells: CellInput[]
}

export interface EconomicsInput {
  name: string
  text: string
  cells: EconomicsColumnCell[]
}

export interface Icon {
  skillType: string
  currentLevel: ParamStatReward['value']
  maxLevel: ParamStatReward['value']
  gemOnly: ParamStatReward['value']
  locked: boolean
  isNew: boolean
  icon: string
  unlockedAfterLevel: ParamStatReward['value']
  state?: string
  estimatedDate?: ParamStatReward['value']
  isIconRepeatable: Nullable<boolean>
}

export enum TreeType {
  Camps = 'camps',
  Economics = 'economics',
  Disciplines = 'disciplines',
}
