import {
  clubAcceptInvitationEndpoint,
  clubDeclineInvitationEndpoint,
  clubRequestDeclineAllEndpoint,
  clubRequestsEndpoint,
} from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { translate } from '@/plugins/translate'
import { defineStore } from 'pinia'
import type { ClubFilter } from '@/interfaces/clubs/Clubs'
import type {
  ClubInvitationsResponse,
  ClubInvitationResponse,
  AcceptInvitationResponse,
} from '@/interfaces/responses/club/ClubInvitationsResponse'
import type { ClubInvitation } from '@/interfaces/clubs/ClubInvitations'
import { ClubRequestType } from '@/enums/ClubRequestTypes'
interface ClubInvitationsState {
  invitations: ClubInvitation[]
  quarantineEndsIn: number
  isFullClub: boolean
}

export const useInvitationStore = defineStore('useInvitation', {
  state: (): ClubInvitationsState => ({
    invitations: [] as ClubInvitation[],
    quarantineEndsIn: 0,
    isFullClub: false,
  }),
  getters: {
    getInvitations(): ClubInvitationsState['invitations'] {
      return this.invitations
    },
    getInvitationsLength(): number {
      return this.invitations.length
    },
    getQuarantineEnd(): ClubInvitationsState['quarantineEndsIn'] {
      return this.quarantineEndsIn
    },
    getIsClubFull(): ClubInvitationsState['isFullClub'] {
      return this.isFullClub
    },
  },
  actions: {
    async loadInvitations(filter: ClubFilter = null): Promise<void> {
      let requestsBody = {
        request_types: 'invitation',
      }
      if (filter) {
        requestsBody = { ...requestsBody, ...filter }
      }
      const data = await internalAxios.post<{}, ClubInvitationsResponse>(
        clubRequestsEndpoint,
        requestsBody,
      )
      const mutatedData = data.invitation.map((club: ClubInvitationResponse): ClubInvitation => {
        return {
          id: club.id,
          clubId: club.clubs_id,
          country: club.stats.country.three_letter_code,
          name: club.stats.name,
          clubCapacity: club.max_count,
          membersCount: club.members_count,
          type: translate(
            `club.${club.stats.club_type === 'request' ? 'forRequest' : club.stats.club_type}`,
          ),
          prestige: club.prestige,
          logoId: club.stats.logo_id,
          backgroundId: club.stats.logo_background_id,
          quarantineEndsIn: data.quarantine_ends_in,
        }
      })
      this.quarantineEndsIn = data.quarantine_ends_in

      this.invitations = mutatedData
    },
    async inviteToClub(userId: string): Promise<void> {
      await internalAxios.post<{}, ClubInvitationResponse[]>(`${clubRequestsEndpoint}/invite`, {
        user_games_id: userId,
      })
    },
    async acceptInvitation(id: string): Promise<void> {
      this.isFullClub = false
      const data = await internalAxios.post<{}, AcceptInvitationResponse>(
        clubAcceptInvitationEndpoint,
        {
          request_id: id,
        },
      )
      if (data.isClubFull) {
        this.isFullClub = true
      }
    },
    async declineInvitation(id: string): Promise<void> {
      await internalAxios.delete(`${clubDeclineInvitationEndpoint}${id}`)
    },
    async declineAllInvitations(): Promise<void> {
      try {
        await internalAxios.post<{}, string>(clubRequestDeclineAllEndpoint, {
          request_type: ClubRequestType.Invitation,
        })
      } catch (error: unknown) {
        console.error(error)
      }
      this.invitations = []
    },
  },
})
