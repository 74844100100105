import { grandPrizeEndPoint, grandPrizeShowEndPoint } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import type {
  GrandPrizeShowApiResponse,
  GrandPrizeOpenApiResponse,
  GrandPrizePossibleReward,
  GrandPrizeParameter,
} from '@/interfaces/responses/grandPrize/GrandPrizeApiResponse'
import type Reward from '@/interfaces/Reward'

interface GrandPrizeDetailState {
  detailData: {
    level: number
    freeSlotsCount: number
    extraOpenPrice: number
    instantOpenPrice: number
    gpType: string
    gpId: string
    rarities: GrandPrizePossibleReward[]
    canOpenCount: number
    rewards: Reward[]
    adSlots: number
    openHelmets: GrandPrizePossibleReward[]
    showAdButton: boolean
    available: GrandPrizePossibleReward[]
  }
  openData: GrandPrizeOpenApiResponse
}

export const useGrandPrizeDetailStore = defineStore('grandprizedetail', {
  state: (): GrandPrizeDetailState => ({
    detailData: {} as GrandPrizeDetailState['detailData'],
    openData: {} as GrandPrizeDetailState['openData'],
  }),
  getters: {
    getGrandPrizeDetail(): GrandPrizeDetailState['detailData'] {
      return this.detailData
    },
    getGrandPrizeRarities(): GrandPrizeDetailState['detailData']['rarities'] {
      return this.detailData.rarities
    },
    getOpenHelmetsLength(): number {
      return this.detailData.openHelmets.length
    },
    getOpenGps(): GrandPrizeDetailState['openData'] {
      return this.openData
    },
    unopenedRewards(): GrandPrizeDetailState['detailData']['rarities'] {
      return this.detailData.rarities.filter(
        (reward: GrandPrizePossibleReward): boolean => reward.opened === false,
      )
    },
  },
  actions: {
    async loadGrandPrizeDetail(id: string): Promise<void> {
      const endpoint = grandPrizeShowEndPoint + id
      const data = await internalAxios.get<{}, GrandPrizeShowApiResponse>(endpoint)

      const mutatedData: GrandPrizeDetailState['detailData'] = {
        level: data.stats.level,
        freeSlotsCount: data.parameters.find(
          (item: GrandPrizeParameter) => item.name === 'grand_prize_free_slots',
        )?.value as number,
        extraOpenPrice: data.parameters.find(
          (item: GrandPrizeParameter) => item.name === 'grand_prize_extra_open_gems_price',
        )?.value as number,
        instantOpenPrice: data.parameters.find(
          (item: GrandPrizeParameter) => item.name === 'grand_prize_instant_open_gems_price',
        )?.value as number,
        gpType: data.parameters.find(
          (item: GrandPrizeParameter) => item.name === 'grand_prize_name',
        )?.value as string,
        gpId: data.id,
        rarities: data.rarities,
        canOpenCount: data.stats.can_open_grand_prize_slots_count,
        rewards: data.rewards,
        adSlots: data.parameters.find(
          (item: GrandPrizeParameter) => item.name === 'grand_prize_ad_slots',
        )?.value as number,
        openHelmets: data.rarities.filter(
          (reward: GrandPrizePossibleReward): boolean => reward.opened === true,
        ),
        showAdButton: data.show_ad_button,
        available: data.available,
      }

      this.detailData = mutatedData
    },
    async setOpenGp({ gpId, slotNumber }: { gpId: string; slotNumber: number }): Promise<void> {
      const response = await internalAxios.post<{}, GrandPrizeOpenApiResponse>(grandPrizeEndPoint, {
        grand_prizes_id: gpId,
        slot_number: slotNumber,
      })

      this.openData = response
    },
  },
})
