import { ConnectedAccountStatus } from '@/enums/LoginForm'
import { translate } from '@/plugins'

export const getConnectedAccountStatusText = (
  status: ConnectedAccountStatus,
  platform?: string,
): string => {
  const platformPrefix = platform ? `<span>${translate(`settings.${platform}`)}</span>:&nbsp;` : ''

  switch (status) {
    case ConnectedAccountStatus.NotConnected:
      return `${platformPrefix}<span class="text-texts-standard-danger">${translate(
        'settings.notLinked',
      )}</span>`
    case ConnectedAccountStatus.Connected:
      return `${platformPrefix}<span class="text-texts-standard-positive">${translate(
        'settings.linked',
      )} / ${translate('settings.verified')}</span>`
    case ConnectedAccountStatus.VerificationPending:
      return `${platformPrefix}<span class="text-texts-standard-positive">${translate(
        'settings.linked',
      )}</span> / ${translate('settings.unverifiedEmail')}`
    case ConnectedAccountStatus.CannotBeVerified:
      return `${platformPrefix}<span class="text-texts-standard-positive">${translate(
        'settings.linked',
      )}</span> / ${translate('settings.emailNotProvided')}`
    default:
      return ''
  }
}
