import { DUEL_CONFIG, gamesConfigEndpoint } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'

interface StartsCostConfig {
  base: number
  live: number
  multiplier: number
}

interface DuelStoreState {
  startsCostConfig: StartsCostConfig
}

export interface DuelCostsInterface {
  cost: number
  costLive: number
  equipment_durability: number
  benefit: number
  benefitLive: string
  parameter: string
  multiplier: number
}

export const useDuelStore = defineStore('duelStore', {
  state: (): DuelStoreState => ({
    startsCostConfig: {
      base: 1,
      live: 2,
      multiplier: 5,
    },
  }),
  getters: {
    getStartsCostConfig(): StartsCostConfig {
      return this.startsCostConfig
    },
  },
  actions: {
    async setStartsCosts(): Promise<boolean> {
      const duelConfig = await internalAxios.post<{}, DuelCostsInterface, { keys: string }>(
        gamesConfigEndpoint,
        {
          keys: DUEL_CONFIG,
        },
      )
      this.startsCostConfig = {
        base: duelConfig.cost,
        live: duelConfig.costLive,
        multiplier: duelConfig.multiplier,
      }
      return true
    },
  },
})
