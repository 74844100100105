<template>
  <div class="history-empty w-full h-full flexing">
    <div class="trophies flex flex-col justify-center items-center space-y-8">
      <p class="message w-full flexing text-32 text-texts-standard-default">
        {{
          $replacePlaceholder(
            $t('tournaments.noTournaments'),
            '%s',
            TOURNAMENTS_HISTORY_LIMIT.toString(),
          )
        }}
      </p>
      <app-button
        class="join-button"
        btn-size="md"
        btn-type="confirm"
        :btn-text="$t('tournaments.signUpForTournament')"
        @click="$router.push({ name: $getWebView('TournamentsCalendar') })"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { TOURNAMENTS_HISTORY_LIMIT } from '@/globalVariables'
import { defineComponent } from 'vue'

interface ComponentData {
  TOURNAMENTS_HISTORY_LIMIT: typeof TOURNAMENTS_HISTORY_LIMIT
}

export default defineComponent({
  data(): ComponentData {
    return {
      TOURNAMENTS_HISTORY_LIMIT,
    }
  },
})
</script>

<style lang="scss" scoped>
.history-empty {
  .trophies {
    width: 50.75rem;
    height: 40.313rem;
    @include background(url('#{$path-images}/tournaments/calendar/trophies.avif'), contain);

    .message {
      height: 4.875rem;
      @if $isSsm {
        background-image: linear-gradient(to right, transparent, #56627f, #56627f, transparent);
      }
      @if $isWsm {
        background-image: linear-gradient(
          to right,
          transparent,
          rgba($color: #144a73, $alpha: 0.7),
          rgba($color: #144a73, $alpha: 0.7),
          transparent
        );
      }
    }
  }
}
</style>
