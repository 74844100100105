<template>
  <section class="app-page-wrapper premium-bags-packs flex flex-col safe-area">
    <header class="flexing">
      <div class="packs-title flexing">
        <div class="packs-logo"></div>
        <app-button btn-id="bags-rewards-btn" btn-type="primary" btn-size="md" @click="goToBags()">
          <div class="flexing">
            <p class="rewards-text">{{ $t('common.rewards') }}</p>
            <span class="rewards-icon icon-present-56 ml-4"> </span>
          </div>
        </app-button>
      </div>
      <section class="packs-info text-left">
        <app-icon icon-name="question" @click="showAboutPopup = true" />

        <level-bags-about-popup :show-popup="showAboutPopup" @close="showAboutPopup = false" />
      </section>
      <p class="packs-about text-32 text-texts-standard-dark font-bold absolute">
        {{ $t('premiumGroup.level_up_bags_about') }}
      </p>
    </header>
    <component-loading :is-loading="!levelBagOfferPacksData" />

    <main v-if="levelBagOfferPacksData" class="flexing relative">
      <level-bag-big
        v-for="pack in levelBagOfferPacksData"
        :key="`big-pack-${pack.type}`"
        :pack-type="pack.type"
        :purchased="pack.purchased"
        :value="pack.value"
        :price="pack.price"
        :store-id="pack.storeId"
      />
    </main>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { levelBagsEndpoint } from '@/globalVariables'

import LevelBagBig from '@/components/Premium/LevelBags/LevelBagBig.vue'
import LevelBagsAboutPopup from '@/components/Premium/LevelBags/LevelBagsAboutPopup.vue'
import type { RouteLocationNormalized } from 'vue-router'
import type { PremiumLevelBagsApiResponse } from '@/interfaces/responses/premium/PremiumLevelBagsApiResponse'
import type { LevelBagOfferPacksData, Offer, OfferParameter } from '@/interfaces/premium/Offer'

interface ComponentData {
  showAboutPopup: boolean
  levelBagOfferPacksData: LevelBagOfferPacksData[]
}

export default defineComponent({
  name: 'PremiumLevelBagsPacks',
  components: {
    LevelBagsAboutPopup,
    LevelBagBig,
  },
  data(): ComponentData {
    return {
      showAboutPopup: false,
      levelBagOfferPacksData: null,
    }
  },
  watch: {
    $route(route: RouteLocationNormalized): void {
      if (route.query.reloadData) {
        this.loadData()
        if (route.query.reloadData) {
          this.$router.replace({
            ...this.$router.currentRoute,
            query: {
              reloadData: undefined,
            },
          })
        }
      }
    },
  },
  created(): void {
    this.loadData()
  },
  methods: {
    async loadData(): Promise<void> {
      const response = await this.$axios.get<{}, PremiumLevelBagsApiResponse>(levelBagsEndpoint)
      const mutatedPacks = response.offers.map((pack: Offer): LevelBagOfferPacksData => {
        let type = ''
        if (pack.store_id.includes('silver')) {
          type = 'silver'
        }
        if (pack.store_id.includes('gold')) {
          type = 'gold'
        }
        if (pack.store_id.includes('diamond')) {
          type = 'diamond'
        }
        return {
          type: type,
          purchased: pack.remaining_buys < 1,
          value:
            parseInt(
              pack.parameters?.find((param: OfferParameter): boolean => param.name === 'value')
                ?.value,
            ) ?? 0,
          price: pack.price,
          storeId: pack.store_id,
        }
      })
      this.levelBagOfferPacksData = mutatedPacks
    },
    goToBags(): void {
      this.$router.push({ name: this.$getWebView('PremiumLevelBags') })
    },
  },
})
</script>

<style lang="scss" scoped>
.premium-bags-packs {
  background: url($path-premium + 'bags/packs/bg-detail.avif') center bottom no-repeat;
  background-size: cover;

  & > header {
    position: relative;
    top: 8rem;

    .packs-info {
      right: 3.625rem;
    }

    .packs-title {
      width: 85%;
      height: 6rem;
      border-style: solid;
      border-width: 0.25rem;
      border-image-slice: 1;
      @if $isWsm {
        background: linear-gradient(
          90deg,
          transparent 0%,
          rgba(19, 52, 146, 0.9) 40%,
          rgba(19, 52, 146, 0.9) 60%,
          transparent 100%
        );

        border-image-source: linear-gradient(
          90deg,
          transparent 0%,
          rgba(246, 237, 176, 1) 25%,
          rgba(246, 237, 176, 1) 75%,
          transparent 100%
        );
      }
      @if $isSsm {
        background: linear-gradient(
          90deg,
          transparent 0%,
          rgba(98, 49, 2, 0.9) 40%,
          rgba(98, 49, 2, 0.9) 60%,
          transparent 100%
        );

        border-image-source: linear-gradient(
          90deg,
          transparent 0%,
          rgba(249, 186, 39, 0.9) 25%,
          rgba(249, 186, 39, 0.9) 75%,
          transparent 100%
        );
      }
    }

    .packs-about {
      width: 75rem;
      height: 2.875rem;
      border-style: solid;
      border-width: 0.125rem;
      border-image-slice: 1;
      bottom: -5rem;
      @if $isWsm {
        background: linear-gradient(
          90deg,
          transparent 0%,
          rgba(237, 191, 71, 0.8) 25%,
          rgba(247, 212, 101, 0.8) 50%,
          rgba(237, 191, 71, 0.8) 75%,
          transparent 100%
        );
        border-image-source: linear-gradient(
          90deg,
          transparent 0%,
          rgba(249, 229, 133, 1) 25%,
          rgba(249, 229, 133, 1) 75%,
          transparent 100%
        );
      }
      @if $isSsm {
        background: linear-gradient(
          to right,
          transparent,
          #edbf47,
          #f7d465,
          rgba(237, 191, 71, 0.98),
          transparent
        );
        border-image-source: linear-gradient(
          90deg,
          transparent 0%,
          #f9e585 25%,
          #f9e585 75%,
          transparent 100%
        );
      }
    }

    .packs-logo {
      width: 57.875rem;
      height: 4.875rem;
      background: url($path-premium + 'bags/packs/level-up-bags-logo.webp') center bottom no-repeat;
      background-size: contain;
    }

    #bags-rewards-btn {
      position: absolute;
      left: 4rem;
      width: 23.813rem;
    }
  }

  & > main {
    top: 13.5rem;
  }
}
</style>
