<template>
  <template v-if="loaded">
    <session-modal v-if="sessionModalRewards" :rewards="sessionModalRewards" />
    <div
      v-else
      id="event-box"
      class="z-1 event-box bordered-lg box-border-3"
      :class="{ 'event-box-done': isChainsCompleted }"
      data-cy="event-box"
      :data-event-theme="namespace"
    >
      <div v-if="isChainsCompleted" class="w-full h-full relative">
        <p
          class="event-box-done-message flexing w-full text-32 text-texts-standard-important font-bold absolute"
        >
          {{ $te('tasksDoneText') }}
        </p>
      </div>
      <template v-else>
        <header v-if="tasks">
          <div class="w-full flex items-center tasks-subheader">
            <span class="gradient-text-light text-60 font-bold uppercase italic">
              {{ $replacePlaceholder($te('eventTaskNumber'), '%s', currentStep) }}
            </span>
            <sup v-if="isBossFight" class="ml-4">{{ $te('eventBoss') }}</sup>
          </div>
        </header>
        <main
          class="w-full h-full flex justify-center"
          :class="{ 'items-center': !isBossFight, 'no-active-event-tasks': !tasks }"
        >
          <div v-if="tasks" class="w-full h-full flex flex-col items-center justify-center">
            <tasks-boss
              v-if="isBossFight && tasks"
              :task="tasks[0]"
              :boss="bossData"
              @skip-boss="skipTask"
            />
            <template v-else-if="currentDifficulty === 1">
              <tasks-box-vertical
                v-for="(task, index) in tasks"
                :key="index"
                :task="task"
                @skip="skipTask"
              />
            </template>
            <template v-else>
              <tasks-box-horizontal
                v-for="(task, index) in tasks"
                :key="index"
                :class="{ 'mb-4': index !== tasks.length - 1 }"
                :task="task"
                @skip="skipTask"
              />
            </template>
          </div>
          <div v-else class="w-full h-full flex flex-col items-center justify-center">
            <p class="text-36 text-white italic mb-16">{{ $te('noActiveEventTasks') }}</p>
            <app-button
              btn-type="confirm"
              :btn-text="$te('eventTasks')"
              btn-size="md"
              @click="startEvent"
            />
          </div>
        </main>
        <footer v-if="tasks" class="flexing" :class="{ 'justify-between': canClaimRewards }">
          <div class="event-box-rewards flex justify-start">
            <p class="text-texts-standard-important text-34 mr-2">{{ $te('commonRewards') }}:</p>
            <rewards
              reward-id="event-box-rewards"
              class="ml-10"
              :reward-data="formatRewards(rewards, 'type', 'value')"
              :icon-size="56"
            />
          </div>
          <div class="event-box-button">
            <app-button
              btn-type="confirm"
              btn-size="md"
              :disabled="!canClaimRewards || isClaiming"
              @click="claimRewards"
            >
              <component-loading v-if="isClaiming" :is-loading="true" />
              <template v-else>{{ $te('commonTakeRewards') }}</template>
            </app-button>
          </div>
        </footer>
      </template>
    </div>
  </template>
  <component-loading v-else :is-loading="true" height="56.75rem" />
</template>

<script lang="ts">
import SessionModal from '@/components/Events/TaskchainComponents/SessionModal.vue'
import TasksBoss from '@/components/Events/TaskchainComponents/TasksComponents/TasksBoss.vue'
import TasksBoxHorizontal from '@/components/Events/TaskchainComponents/TasksComponents/TasksBoxHorizontal.vue'
import TasksBoxVertical from '@/components/Events/TaskchainComponents/TasksComponents/TasksBoxVertical.vue'
import Rewards from '@/components/Rewards.vue'
import { ADMIN_FORWARD } from '@/globalVariables'
import { formatRewards } from '@/helpers'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useTaskChainStore } from '@/store/pinia/events/taskChainStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  ADMIN_FORWARD: typeof ADMIN_FORWARD
  loaded: boolean
  isClaiming: boolean
  showBossFightPopup: boolean
}

export default defineComponent({
  name: 'EventBox',
  components: {
    Rewards,
    TasksBoxVertical,
    TasksBoxHorizontal,
    TasksBoss,
    SessionModal,
  },
  data(): ComponentData {
    return {
      ADMIN_FORWARD,
      loaded: false,
      isClaiming: false,
      showBossFightPopup: false,
    }
  },
  computed: {
    ...mapState(useTaskChainStore, {
      tasks: 'getTasks',
      currentStep: 'getCurrentStep',
      currentDifficulty: 'getCurrentDifficulty',
      isBossFight: 'getIsBossFight',
      bossData: 'getBossData',
      canClaimRewards: 'getCanClaimRewards',
      rewards: 'getRewards',
      isChainsCompleted: 'getIsChainsCompleted',
    }),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
    ...mapState(useTaskChainStore, {
      sessionModalRewards: 'getSessionModalRewards',
    }),
  },
  async created(): Promise<void> {
    await Promise.all([this.loadConfig(), this.loadState()])
    this.loaded = true
  },
  methods: {
    ...mapActions(useTaskChainStore, {
      loadConfig: 'loadConfig',
      loadState: 'loadState',
      skipTask: 'skipTask',
      _claimRewards: 'claimRewards',
    }),
    formatRewards,
    async claimRewards(): Promise<void> {
      if (this.isClaiming) return
      this.isClaiming = true
      await this._claimRewards()
      this.isClaiming = false
    },
    startEvent(): void {
      this.$router.push({ name: this.$getWebView('EventsTaskchain') })
    },
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='challenge_cup'] {
  --events-tasks-bg: url('#{$path-events}challenge-cup/tasks/tasks-bg.avif');
  --events-tasks-done-bg: url('#{$path-events}challenge-cup/tasks/tasks-done-bg.avif');
  --events-tasks-done-text-bg: url('#{$path-events}challenge-cup/tasks/tasks-done-text-bg.avif');
  --events-tasks-header-bg: url('#{$path-events}shared/tasks/tasks-header-bg.avif');
  --events-tasks-rewards-bg: url('#{$path-events}shared/tasks/tasks-rewards-bg.avif');
}

[data-event-theme='frozen'] {
  --events-tasks-bg: url('#{$path-events}frozen-championship/tasks/tasks-bg.avif');
  --events-tasks-done-bg: url('#{$path-events}frozen-championship/tasks/tasks-done-bg.avif');
  --events-tasks-done-text-bg: url('#{$path-events}frozen-championship/tasks/tasks-done-text-bg.avif');
  --events-tasks-header-bg: url('#{$path-events}shared/tasks/tasks-header-bg.avif');
  --events-tasks-rewards-bg: url('#{$path-events}shared/tasks/tasks-rewards-bg.avif');
}

.event-box {
  width: 89.063rem;
  height: 44.5rem;
  @include background(var(--events-tasks-bg), 100% 100%);
  position: relative;

  &-done {
    background-image: var(--events-tasks-done-bg);

    &-message {
      bottom: 9rem;
      height: 4.125rem;
      @include background(var(--events-tasks-done-text-bg), contain);
    }
  }

  header,
  footer {
    width: 100%;
    height: 6.438rem;
    display: flex;
    align-items: center;
  }

  header {
    padding-left: 2.125rem;

    .event-box-icon {
      width: 5.625rem;
      height: 5.625rem;
      margin: 0 0.4rem;
    }

    .event-box-titles {
      width: 75%;
      display: flex;
      align-items: center;
      justify-content: left;
      flex-direction: column;
      text-align: left;
      line-height: 2.75rem;

      p {
        width: 100%;
        margin-left: 0.5rem;
      }
    }

    .event-box-task {
      width: 25%;
      margin: 0 auto;

      &-locked-icon {
        background-size: 100% 100%;
      }
    }

    .more-width {
      width: 30%;
    }
  }

  main {
    height: 31.25rem;
    position: relative;

    &.completed {
      height: auto;
    }
  }

  .header-completed {
    background: linear-gradient(160deg, rgba(20, 43, 66, 1) 0%, rgba(18, 95, 37, 1) 100%);
  }

  .header-locked {
    background: linear-gradient(160deg, rgba(23, 41, 64, 1) 0%, rgba(89, 99, 112, 1) 100%);
  }

  footer {
    height: 6.5rem;
    @include background(var(--events-tasks-rewards-bg), contain);

    .event-box-rewards {
      margin-left: 4rem;

      .single-reward {
        margin-left: 3.188rem;
      }
    }

    .event-box-reward-boss {
      width: 100% !important;
    }

    .event-box-button {
      margin: 0 2.5rem;
      margin-left: auto;
    }

    .reward-claimed {
      height: 100%;
      width: 25%;
      line-height: 2.75rem;
      margin-right: 1rem;
      margin-left: auto;
    }
  }

  .footer-locked {
    background: rgba(0, 0, 0, 0.42);
  }

  .locked-opacity {
    opacity: 0.6;
  }

  .notification-rewards {
    top: 0;
    right: 0.5rem;
  }

  .no-active-event-tasks {
    height: 45rem;
  }
}

.tasks {
  &-subheader {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 1.25rem;
      top: 1rem;
      width: 31.625rem;
      height: 3.125rem;
      @include background(var(--events-tasks-header-bg), contain);
      z-index: -1;
    }
  }
}
</style>
