<template>
  <div class="gp-leave-confirm-popup">
    <h2 class="text-texts-standard-default text-36">
      {{ $t('grandPrize.continueMessage') }}
    </h2>
    <div class="gp-leave-confirm-popup-rewards flexing">
      <grand-prize-badges
        v-for="(reward, i) in unopenedRewards"
        :key="i"
        :badge="reward.rarity"
        size="md"
        :reward="reward"
        :has-badge-text="false"
      />
    </div>
    <p class="text-texts-standard-default text-36">
      {{ $t('grandPrize.reallyContinue') }}
    </p>
    <app-check-box size="sm" :is-checked="check" @checked-action="changedLeavingChecked">
      <p
        class="text-texts-standard-additional text-36 m-4"
        :class="{
          uppercase: $isWsm,
        }"
      >
        {{ $t('trainingHall.attributeLimitDontDisplay') }}
      </p>
    </app-check-box>

    <section class="gp-leave-confirm-popup-buttons flexing">
      <app-multi-button1
        btn-id="gp-leave-confirm-popup-open-all"
        btn-type="credit"
        btn-size="md"
        :btn-text="$t('grandPrize.openAll')"
        :btn-count="gainAllPrice"
        :popup-data="{
          title: $t('grandPrize.getAllRewards'),
          btnText: $t('grandPrize.openAll'),
          btnType: 'confirm',
          btnValue: gainAllPrice,
          btnId: 'gp-leave-confirm-gain-all-btn',
          text: $t('grandPrize.getAllRewardsConfirm'),
        }"
        @action="gainAll()"
      />
      <app-button
        btn-id="gp-leave-confirm-popup-claim"
        btn-type="confirm"
        :btn-text="$t('button.continue')"
        btn-size="md"
        @click="claimIt"
      />
    </section>
  </div>
</template>

<script lang="ts">
import GrandPrizeBadges from '@/components/GrandPrize/GrandPrizeBadges.vue'
import { useGrandPrizeDetailStore } from '@/store/pinia/grandPrize/detailStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  check: boolean
  showConfirmGainAlls: boolean
}

export default defineComponent({
  name: 'GrandPrizeConfirmLeavePopup',
  components: {
    GrandPrizeBadges,
  },
  props: {
    gainAllPrice: {
      type: Number,
      default: 100,
    },
  },
  emits: ['claim', 'isChecked', 'gainAll'],

  data(): ComponentData {
    return {
      check: false,
      showConfirmGainAlls: false,
    }
  },

  computed: {
    ...mapState(useGrandPrizeDetailStore, {
      unopenedRewards: 'unopenedRewards',
    }),
  },

  methods: {
    claimIt(): void {
      this.$emit('claim', true)
    },

    changedLeavingChecked(): void {
      this.isCheck()
      this.$emit('isChecked', this.check)
    },

    isCheck(): void {
      this.check = !this.check
    },
    gainAll(): void {
      this.$emit('gainAll', true)
    },
  },
})
</script>

<style lang="scss" scoped>
.gp-leave-confirm-popup {
  min-height: 27rem;

  h2 {
    margin-top: 2rem;
  }

  &-rewards {
    position: relative;
    top: 1.125rem;
    height: 8.75rem;
    max-width: 40rem;
    margin: 0 auto 2rem;

    @if $isWsm {
      background-image: linear-gradient(
        to right,
        transparent 0%,
        rgba(17, 80, 126, 0.6) 50%,
        transparent 100%
      );
    }

    @if $isSsm {
      border-style: solid;
      background-image: linear-gradient(
        to right,
        transparent,
        #373e5d,
        #49527a,
        #373e5d,
        transparent
      );
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      height: 0.125rem;

      @if $isWsm {
        background-image: linear-gradient(to right, transparent, #8bc5f7, transparent);
      }

      @if $isSsm {
        background-image: linear-gradient(to right, transparent, #586b9d, #586b9d, transparent);
      }
    }

    &::before {
      top: 0rem;
    }

    &::after {
      bottom: 0rem;
    }

    .grand-prize-badges {
      margin: 0 0.344rem;
      width: 6.125rem;
      height: 7.5rem;

      .gp-types {
        width: 6.125rem;
        height: 7.5rem;
      }
    }
  }

  .app-checkbox-wrapper {
    margin-top: 1.125rem;
  }

  &-buttons {
    margin: 1rem 0;

    button {
      margin: 0 1rem;
    }
  }
}
</style>
