<template>
  <div
    v-if="isGamePassUnlocked"
    class="game-pass-parameters-prompt flex items-center text-28 uppercase justify-between"
    :class="{ 'cursor-pointer': showGetPass }"
    @click="goToPassPage"
  >
    <p class="info-text text-texts-standard-default" :class="{ 'text-left text-30': !showGetPass }">
      {{ displayText }}
    </p>
    <section v-if="showGetPass" class="flex right-side justify-between items-center">
      <p class="text-texts-standard-important">
        {{ passText }}
      </p>
      <span class="prompt-arrow" />
    </section>
    <section v-else class="flex right-side items-center">
      <tippy theme="WSM2" placement="top" max-width="51.125rem">
        <app-icon icon-name="info-50" class="info" />

        <template #content>
          <div class="prompt-tooltip">
            <div class="prompt-tooltip-head mb-4">
              <p class="text-texts-standard-default text-30 uppercase">
                {{ $t('gamePass.activeBonuses') }}
              </p>
            </div>
            <p
              v-for="(bonus, i) in activeBonuses"
              :key="i"
              class="text-texts-standard-default text-30"
            >
              {{ effectText({ name: bonus.name, bonus: bonus.value }) }}
              <span class="text-texts-standard-important font-bold ml-4">
                {{ `${effectSign(bonus.name)}${bonus.value}` }}
              </span>
            </p>
          </div>
        </template>
      </tippy>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  GAME_PASS_DOUBLE_ENERGY_REGEN,
  GAME_PASS_DOUBLE_START_REGEN,
  GAME_PASS_ENERGY_CAP,
  GAME_PASS_STARTS_CAP,
} from '@/globalVariables'
import type GamePassEffectApiResponse from '@/interfaces/responses/gamePass/GamePassEffectsApiResponse'
import { useGamePassStore } from '@/store/pinia/gamePassStore'
import { mapState } from 'pinia'
import { gamePassRewardsParser, getResourceUnit } from '@/helpers'

export default defineComponent({
  name: 'GamePassParametersPrompt',

  emits: ['closePopup'],

  computed: {
    ...mapState(useGamePassStore, ['getActiveEffects', 'isGamePassUnlocked']),
    hasActiveBonuses(): boolean {
      return this.getActiveEffects && this.getActiveEffects.length > 0
    },
    activeBonuses(): GamePassEffectApiResponse[] {
      if (!this.hasActiveBonuses) return []

      const availableEffects = [
        GAME_PASS_DOUBLE_ENERGY_REGEN,
        GAME_PASS_DOUBLE_START_REGEN,
        GAME_PASS_ENERGY_CAP,
        GAME_PASS_STARTS_CAP,
      ]

      return this.getActiveEffects.filter((effect: GamePassEffectApiResponse): boolean =>
        availableEffects.includes(effect.name),
      )
    },
    showGetPass(): boolean {
      // (i) Marketing prompt is shown if player has already purchased a bonus and received at least 1 of the game pass bonuses that affect regeneration speed or increased maximum
      return this.activeBonuses.length < 1
    },
    displayText(): string {
      return !this.showGetPass
        ? this.$replacePlaceholder(
            this.$t('gamePass.passBonusesActive'),
            '{pass}',
            this.$t('gamePass.gamePass'),
          )
        : this.$t('gamePass.promptInfo')
    },
    passText(): string {
      return this.$replacePlaceholder(
        this.$t('premiumGroup.get_pass'),
        '{pass}',
        this.$t('gamePass.gamePass'),
      )
    },
  },
  methods: {
    effectText(effect: { name: string; bonus: string }): string {
      return this.$t(gamePassRewardsParser(effect).text)
    },
    effectSign(effect: string): string {
      return getResourceUnit(effect)
    },
    goToPassPage(): void {
      if (!this.showGetPass) return

      this.$router.push({ name: this.$getWebView('PremiumGamePass') })
      this.$emit('closePopup')
    },
  },
})
</script>

<style lang="scss" scoped>
.game-pass-parameters-prompt {
  width: 82.5rem;
  height: 6.25rem;
  background: url($path-premium + 'gamePass/parametersPrompt/prompt-bg.avif') no-repeat center;
  background-size: contain;
  .info-text {
    width: 42rem;
    margin-left: 20rem;
    line-height: 2.1rem;
  }
  .right-side {
    padding-right: 1rem;
    & > p {
      width: 20rem;
      line-height: 2.1rem;
      margin-right: 1rem;
      text-align: right;
    }
    .prompt-arrow {
      width: 1.625rem;
      height: 3rem;
      background: url($path-premium + 'gamePass/parametersPrompt/arrow-right-yellow.avif') no-repeat
        center;
      background-size: contain;
      display: inline-block;
    }
  }
}
.prompt-tooltip {
  width: 24rem;
  padding-bottom: 1rem;
  &-head {
    height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: 500;
    width: 100%;

    @if $isWsm {
      background: linear-gradient(to left, rgba(15, 40, 57, 0), #1e6c9b, rgba(15, 40, 57, 0));
    }
    @if $isSsm {
      background: linear-gradient(
        to right,
        rgba(179, 82, 162, 0.01),
        rgba(179, 82, 162, 0.7),
        #a83c75,
        #a83c75,
        rgba(179, 82, 162, 0.7),
        rgba(179, 82, 162, 0.01)
      );
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.063rem;
      @if $isWsm {
        background: linear-gradient(to right, #a4a4a400, #a4a4a4, #a4a4a4, #a4a4a400);
      }
      @if $isSsm {
        background: linear-gradient(
          to right,
          rgba(190, 81, 154, 0.2),
          rgba(253, 150, 205, 0.8),
          rgba(253, 150, 205, 1),
          rgba(253, 150, 205, 0.8),
          rgba(191, 82, 155, 0.2)
        );
      }
    }
    &::before {
      content: '';
      top: 0rem;
    }
    &::after {
      content: '';
      bottom: 0rem;
    }
  }
}
</style>
