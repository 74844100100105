import {
  ARENA,
  LIVE,
  MINIGAME,
  REPORT_MATCH_ID,
  REPORT_MATCH_TIME,
  arenaHistoryEndpoint,
  arenaMatchEndpoint,
} from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import type { Nullable } from '@/interfaces/utils'
import type ArenaMatchReportApiResponse from '@/interfaces/responses/reports/ArenaMatchReportApiResponse'
import type ArenaMatchHistoryReportApiResponse from '@/interfaces/responses/reports/ArenaMatchHistoryReportApiResponse'

interface MatchesStoreState {
  history: Nullable<ArenaMatchHistoryReportApiResponse>
  report: Record<string, ArenaMatchReportApiResponse>
}

export const useMatchesStore = defineStore('matchesStore', {
  state: (): MatchesStoreState => ({
    history: null,
    report: {},
  }),
  getters: {
    getMatchesHistory(): Nullable<MatchesStoreState['history']> {
      return this.history
    },
    getMatchReport(): (id: string) => Nullable<ArenaMatchReportApiResponse> {
      return (id: string): ArenaMatchReportApiResponse => this.report?.[id] ?? null
    },
  },
  actions: {
    async loadMatchesHistory({ isClubChampionship = false } = {}): Promise<void> {
      const path = isClubChampionship ? '/clubs' : ''

      try {
        const data = await internalAxios.get<{}, ArenaMatchHistoryReportApiResponse>(
          `${arenaHistoryEndpoint}${path}`,
        )

        this.history = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadMatchReport({
      matchId,
      matchTime,
    }: {
      matchId: string
      matchTime: string
    }): Promise<string> {
      localStorage.setItem(REPORT_MATCH_TIME, matchTime)
      localStorage.setItem(REPORT_MATCH_ID, matchId)

      const mode = matchTime === LIVE ? MINIGAME : ARENA

      try {
        const data = await internalAxios.get<{}, ArenaMatchReportApiResponse>(
          arenaMatchEndpoint + mode + '/' + matchId,
        )

        const id = matchId ?? data.match_id
        this.report[id] = data

        return id
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
