<template>
  <div
    v-if="disciplineId"
    v-tippy="{
      theme: 'WSM',
      content: hasTooltip ? $translateDiscipline(disciplineId) : '',
      placement: 'right',
      textAlign: 'center',
    }"
    class="discipline-icon flexing"
    :class="'discipline-icon-' + size"
    :style="isTransparentBg && { background: 'transparent', outline: 'none', boxShadow: 'none' }"
  >
    <div
      class="discipline-box"
      :class="'icon-discipline-' + disciplineId + '-' + theme + '-' + size"
    />
    <div v-if="isLocked || isComingSoon" class="discipline-icon__lock-background flexing">
      <app-icon v-if="isComingSoon" icon-name="time" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppDisciplineIcon',
  props: {
    disciplineId: {
      type: Number,
      required: true,
    },
    theme: {
      type: String,
      default: 'light',
    },
    size: {
      type: Number,
      default: 90,
    },
    hasTooltip: {
      type: Boolean,
      default: true,
    },
    isTransparentBg: {
      type: Boolean,
      default: false,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    isComingSoon: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';

.discipline-icon {
  position: relative;

  @if $isWsm {
    background-image: linear-gradient(to top, #3f78a0, #254257);
    box-shadow:
      inset 0 0 0 0 #010101,
      inset 0 0 0 0.125rem #3f6b93;
  }

  @if $isSsm {
    background-image: linear-gradient(to right, rgba(52, 65, 93, 0.85), rgba(39, 50, 73, 0.85));
    border: solid 0.125rem #586b9d;
    box-shadow: 0.6px 5px 3px 0 rgba(0, 0, 0, 0.36);
  }

  &__lock-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(11, 30, 53, 0.8);
    box-shadow:
      inset 0 0 0 0 #010101,
      inset 0 0 0 0.125rem #3f6b93;
    left: 0;
    top: 0;
  }

  &-48 {
    width: 3rem;
    height: 3rem;
  }

  &-70 {
    width: 4.375rem;
    height: 4.375rem;
  }

  &-80 {
    width: 5rem;
    height: 5rem;
  }

  &-90 {
    width: 5.625rem;
    height: 5.625rem;
  }

  &-120 {
    width: 7.5rem;
    height: 7.5rem;
  }

  &-184 {
    width: 11.5rem;
    height: 11.5rem;
  }

  .discipline-box {
    box-shadow: 0 0 1.125rem 0 rgba(0, 0, 0, 0.5);
    @if $isSsm {
      box-shadow: none;
    }
  }

  &--shadowless {
    .discipline-box {
      box-shadow: none;
    }
  }
}
</style>
