import type { InteractiveObjectsInterface } from '@/map-phaser-new/interfaces'
import { rewardedItems } from './rewardedItems'
import { mainMapBuildings } from './mainMapBuildings'
import { boardGamesEventOsra, iceAndFireEventOsra } from './boardGamesEventOsra'
import { piggyBankOsra } from './piggyBankOsra'
import { connectAccountOsra } from './connectAccountOsra'
import { weatherConfig } from './weather'
import {
  exclamationNotification,
  bubbleNotification,
  indicator,
  tutorial,
} from '@/map-phaser-new/config/winter-sports/commonObjects'

export const interactiveObjectsConfig: InteractiveObjectsInterface = {
  buildings: mainMapBuildings,
  rewardedItems,
  boardGamesEventOsra,
  iceAndFireEventOsra,
  piggyBankOsra,
  connectAccountOsra,
  weather: weatherConfig,
  additionalConfigs: {
    tutorial,
    exclamationNotification,
    bubbleNotification,
    indicator,
  },
}
