import type { App, Plugin } from 'vue'

const IPAD_DEVICE_REGEXP = /iPad/i

let cache: boolean

export const isIpad = (): boolean => {
  if (cache !== undefined) return cache
  return (cache = IPAD_DEVICE_REGEXP.test(navigator.userAgent))
}

export const isIpadPlugin: Plugin = {
  install: (app: App): void => {
    app.config.globalProperties.$isIpad = isIpad
  },
}
