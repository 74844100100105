import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const clubCarsLeftTopToRightTopCheckpoints: CheckpointsObjectInterface = {
  key: 'clubCarsLeftTopToRightTopCheckpoints',
  data: [
    {
      x: 1672,
      y: 247,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsLeftTopToRightTopAnimations1',
        },
      },
    },
    {
      x: 1734,
      y: 275,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsLeftTopToRightTopAnimations2',
        },
      },
    },
    {
      x: 2122,
      y: 329.5,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsLeftTopToRightTopAnimations3',
        },
      },
    },
    {
      x: 2500,
      y: 435,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsLeftTopToRightTopAnimations4',
        },
      },
    },
    {
      x: 2639,
      y: 442.5,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsLeftTopToRightTopAnimations5',
        },
      },
    },
    {
      x: 2800,
      y: 433.5,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubCarsLeftTopToRightTopAnimations6',
        },
      },
    },
  ],
}
