import type { BuildingsConfigInterface } from '@/map-phaser-new/interfaces'
import {
  arenaConfig,
  careerConfig,
  clubChampionshipConfig,
  clubConfig,
  galleryConfig,
  laboratoryConfig,
  legendsConfig,
  premiumConfig,
  shopConfig,
  starOfTheDayConfig,
  tournamentsConfig,
  trainingHallConfig,
  eventOsraConfig,
  eventShipConfig,
} from './specificBuildings'

export const mainMapBuildings: BuildingsConfigInterface = {
  training_hall: trainingHallConfig,
  arena: arenaConfig,
  premium: premiumConfig,
  career: careerConfig,
  shop: shopConfig,
  club: clubConfig,
  club_championship: clubChampionshipConfig,
  laboratory: laboratoryConfig,
  star_of_the_day: starOfTheDayConfig,
  tournaments: tournamentsConfig,
  gallery: galleryConfig,
  legends: legendsConfig,
  event_osra: eventOsraConfig,
  event_ship: eventShipConfig,
}
