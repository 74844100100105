export const pathImages = `${import.meta.env.VITE_PATH_IMAGES}${import.meta.env.VITE_GAME}/`
export const pathFigures = `${pathImages}figures/`
export const authApiUrl = `${import.meta.env.VITE_AUTH_API_URL}`
export const webUrl = `${import.meta.env.VITE_WEB_URL}`
export const minigameUrl = `${import.meta.env.VITE_MINIGAME_URL}`
export const FacebookUrl = `${import.meta.env.VITE_FACEBOOK_URL}`
export const supportMail = `${import.meta.env.VITE_SUPPORT_EMAIL}`
export const adsVideoLink = `${import.meta.env.VITE_ADS_VIDEO_LINK}`
export const googleStoreUrl = `${import.meta.env.VITE_GOOGLE_STORE}`
export const appleStoreUrl = `${import.meta.env.VITE_APPLE_STORE}`
export const ppsSiteUrl = `${import.meta.env.VITE_PPS_SITE}`
export const ppsFbUrl = `${import.meta.env.VITE_PPS_FB}`
export const ppsIgUrl = `${import.meta.env.VITE_PPS_INSTAGRAM}`
export const wsmFbUrl = `${import.meta.env.VITE_FB_LINK}`
export const wsmIgUrl = `${import.meta.env.VITE_INSTAGRAM}`
export const amUrl = `${import.meta.env.VITE_PPS_AM}`
export const sjm3Url = `${import.meta.env.VITE_PPS_SJM3}`
export const bmUrl = `${import.meta.env.VITE_PPS_BM}`
export const gtmUrl = `${import.meta.env.VITE_GTM_URL}`
export const gtmId = `${import.meta.env.VITE_GTM_ID}`
export const fbId = `${import.meta.env.VITE_FB_ID}`
export const googleId = `${import.meta.env.VITE_GOOGLE_ID}`
export const cookiePluginUrl = `${import.meta.env.VITE_COOKIE_PLUGIN_URL}`
export const currentGame = `${import.meta.env.VITE_GAME}`
export const currentEnv = `${import.meta.env.VITE_ENV}`
