<template>
  <div class="map-button-notification flex justify-center">
    <p>
      <slot />
    </p>
    <div class="triangle triangle-down yellow absolute" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'NotificationTooltip',
})
</script>

<style lang="scss" scoped>
.map-button-notification {
  color: #fff;
  box-shadow:
    0.025rem 0.188rem 0.188rem 0 rgba(0, 0, 0, 0.59),
    inset 0 0 2.188rem 0 #e81414;
  border: solid 0.125rem #e81414;
  background-color: #630001;
  font-size: 1.75rem;
  text-align: center;
  padding: 1rem 3.5rem;
  border-bottom: 0.25rem solid #f4d91e;
  white-space: nowrap;

  .triangle {
    bottom: -1.8rem;
  }
}
</style>
