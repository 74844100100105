<template>
  <div class="avatar-menu-name flex items-center">
    <div
      v-tippy="{
        theme: 'WSM',
        content: $t(userData?.country?.name),
        placement: 'top',
      }"
      class="avatar-menu-name-flag"
      :style="{
        background: `url(${pathImages}flags/102x68/${country}.avif) center no-repeat`,
        backgroundSize: 'contain',
      }"
    />
    <p class="text-texts-standard-default text-36">
      {{ nameProp }}
    </p>
    <div v-if="badgesData?.length > 0" class="flex ml-3.5">
      <event-badge :current-badges="badgesData" />
    </div>
  </div>
</template>

<script lang="ts">
import EventBadge from '@/components/GlobalComponents/EventBadge.vue'
import { pathImages } from '@/globalVariables'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { EventBadgeInterface } from '@/interfaces/EventBadgeInterface'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'AvatarMenuName',

  components: {
    EventBadge,
  },
  props: {
    nameProp: {
      type: String,
      default: '',
    },
    badgesData: {
      type: Array as PropType<EventBadgeInterface[]>,
      default: () => [],
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userData: 'getUserData',
      country: 'getCountry',
    }),
  },
})
</script>
