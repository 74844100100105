<template>
  <div :class="`box-color-${boxColor}`" class="clubs-employees-box-empty">
    <header
      class="clubs-employees-box-header text-34 font-bold text-texts-standard-default uppercase"
    >
      <p class="clubs-employees-box-header-title">
        {{ $t(title) }}
      </p>
    </header>
    <div class="clubs-employees-box-empty-image relative">
      <div
        class="clubs-employees-box-empty-image-person w-full h-full"
        :class="`person-${boxColor}`"
      />
      <div class="clubs-employees-box-empty-image-plus w-full h-full absolute flexing">
        <div class="clubs-employees-box-empty-image-plus-img" />
      </div>
    </div>
    <clubs-employees-box-name
      :box-color="boxColor"
      :text="$t('club.vacantPosition')"
      is-empty-box
    />
    <div class="clubs-employees-box-empty-footer flexing">
      <app-button
        v-if="canHire"
        btn-type="secondary"
        :btn-text="$t('club.hireEmployee')"
        class="clubs-employees-box-empty-footer-btn"
        @click="$emit('hire')"
      />
      <p v-else class="clubs-employees-box-empty-footer-text text-28 text-texts-standard-default">
        {{ $t('club.cantHire') }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import ClubsEmployeesBoxName from '@/components/Club/ClubsEmployeesBoxName.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClubsEmployeesBoxEmpty',
  components: {
    ClubsEmployeesBoxName,
  },
  props: {
    boxColor: {
      type: String,
      default: 'blue',
      validator(value: string): boolean {
        return ['blue', 'gold', 'purple'].includes(value)
      },
    },
    title: {
      type: String,
      default: '',
    },
    canHire: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['hire'],
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/club/employees.scss';

.clubs-employees-box-empty {
  position: relative;
  width: 36.875rem;
  height: 46.875rem;

  &-image {
    width: 100%;
    height: 25rem;

    &-person {
      background: url($path-clubs-employees + 'IMG_EMPLOYEE_CLUB.avif') center no-repeat;
      background-size: contain;

      &.person-blue {
        background: url($path-clubs-employees + 'IMG_EMPLOYEE_PERSONAL.avif') center no-repeat;
        background-size: contain;
      }
    }

    & img {
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }

    &-plus {
      top: 5rem;
      left: 0;

      &-img {
        width: 9rem;
        height: 9rem;
        background: url($path-clubs-employees + 'BTN_ADD_EMPLOYEE.avif') center no-repeat;
        background-size: contain;
      }
    }
  }

  &-footer {
    padding: 0 2rem;
    height: 9rem;

    &-btn {
      margin: 0 auto;
    }
  }

  &.box-color-blue {
    background: url($path-clubs-employees + 'BOX_EMPTY_BLUE.avif') top left no-repeat;
    background-size: 100% 100%;
  }

  &.box-color-gold {
    background: url($path-clubs-employees + 'BOX_EMPTY_GOLD.avif') top left no-repeat;
    background-size: 100% 100%;
  }
}
</style>
