<template>
  <header class="event-pass-detail-header flexing w-full" :class="`tier-${$route.query?.tier}`">
    <app-control-icon
      control="back"
      class="back-button-icon"
      :class="$isMobile() ? 'back-button-icon-mobile' : 'ml-3'"
    />
    <p class="flexing text-48 text-texts-standard-default italic uppercase ml-20 mr-auto">
      {{ $te(`${$route.query?.pass}pass`) }}
    </p>
    <app-control-icon
      :control="$isMobile() ? 'home' : 'close'"
      :icon-id="$isMobile() ? 'control-icon-home' : 'control-icon-close'"
      class="home-control-icon"
      :class="$isMobile() ? 'mr-6' : 'mr-3'"
    />
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EventPass3DetailHeader',
})
</script>

<style lang="scss" scoped>
.event-pass-detail-header {
  height: 5.875rem;
  justify-content: space-between;
  background-color: #0b1e37;

  .back-button-icon-mobile {
    justify-content: flex-start;
    padding-left: 1.5rem;
  }
}
</style>
