import type { AnimatedObjectWithPathsInterface } from '@/map-phaser-new/interfaces'
import {
  balloonAnimations,
  cableCarObservatoryPath,
  cableCarSlopePath,
  carParkCheckpoints,
  carParkPath,
  carsAnimations,
  carsCheckpoints,
  carsPath,
  flyingHelicopterAnimations,
  flyingHelicopterCheckpoints,
  flyingHelicopterPath,
  landingHelicopterAnimations,
  landingHelicopterCheckpoints,
  landingHelicopterPath,
  longDistanceBalloonPath,
  shortDistanceBalloonPath,
  stationTrainCheckpoints,
  stationTrainPath,
  tunelTrainAnimations,
  tunelTrainCheckpoints,
  tunelTrainPath,
} from './animations'
import { FRAMES_ANIMATION_TYPES } from '@/map-phaser-new/constants'

export const animatedObjectsWithPathsConfig: AnimatedObjectWithPathsInterface[] = [
  {
    animationName: 'cars',
    textureKey: 'animations',
    pathsData: carsPath,
    checkpointsData: carsCheckpoints,
    animationsData: carsAnimations,
    variations: ['v01', 'v02', 'v03', 'v04', 'v05', 'v06', 'v07', 'v08', 'v09', 'v10'],
    framePrefix: 'WSM_cars_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '13',
    },
    yoyoFrameData: {
      yoyoFrameType: FRAMES_ANIMATION_TYPES.ReverseFrameType,
      yoyoFrameKey: '02',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: true,
    yoyoCalculateNewVariation: true,
    repeatCalculateNewVariation: true,
    yoyo: true,
    tweenBuilderConfig: {
      duration: 60000,
      from: 1,
      to: 0,
    },
    followersDepth: 0,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'car1',
        startDelay: 0,
        yoyoDelayRandom: {
          mathBetweenMin: 5000,
          mathBetweenMax: 6500,
        },
      },
      {
        name: 'car2',
        startDelay: 6000,
        repeatDelay: 4000,
        yoyoDelayRandom: {
          mathBetweenMin: 6000,
          mathBetweenMax: 7500,
        },
      },
    ],
  },
  {
    animationName: 'carPark',
    textureKey: 'animations',
    pathsData: carParkPath,
    checkpointsData: carParkCheckpoints,
    variations: ['v01', 'v02', 'v03', 'v04', 'v05', 'v06', 'v07', 'v08', 'v09', 'v10'],
    framePrefix: 'WSM_cars_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '02',
    },
    yoyoFrameData: {
      yoyoFrameType: FRAMES_ANIMATION_TYPES.ReverseFrameType,
      yoyoFrameKey: '13',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: true,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: true,
    tweenBuilderConfig: {
      duration: 60000,
    },
    followersDepth: 0,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'carPark1',
        startDelay: 0,
        yoyoDelayRandom: {
          mathBetweenMin: 5000,
          mathBetweenMax: 6500,
        },
      },
      {
        name: 'carPark2',
        startDelay: 10000,
        repeatDelay: 3000,
        yoyoDelayRandom: {
          mathBetweenMin: 6000,
          mathBetweenMax: 7500,
        },
      },
    ],
  },
  {
    animationName: 'train',
    textureKey: 'animations',
    pathsData: tunelTrainPath,
    checkpointsData: tunelTrainCheckpoints,
    animationsData: tunelTrainAnimations,
    variations: ['v01'],
    framePrefix: 'WSM_train_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '01',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 15000,
    },
    followersDepth: 0,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'tunelTrain1',
        startDelay: 0,
        repeatDelay: 30000,
      },
    ],
  },
  {
    animationName: 'train',
    textureKey: 'animations',
    pathsData: stationTrainPath,
    checkpointsData: stationTrainCheckpoints,
    variations: ['v01'],
    framePrefix: 'WSM_train_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '01',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 25000,
    },
    followersDepth: 0,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'stationTrain1',
        startDelay: 15000,
        repeatDelay: 15000,
      },
    ],
  },
  {
    animationName: 'cableCarObservatory',
    textureKey: 'animations',
    pathsData: cableCarObservatoryPath,
    variations: [''],
    framePrefix: 'WSM_cable_car_right',
    framePostfix: '',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '',
    },
    turnOnRepeatListener: false,
    turnOnUpdateListener: false,
    turnOnYoyoListener: false,
    repeatCalculateNewVariation: false,
    yoyo: true,
    tweenBuilderConfig: {
      duration: 20000,
    },
    followersDepth: 0,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'cableCarObservatory1',
        startDelay: 0,
        repeatDelay: 10000,
        yoyoDelayRandom: {
          mathBetweenMin: 0,
          mathBetweenMax: 25000,
        },
      },
    ],
  },
  {
    animationName: 'cableCarSlope',
    textureKey: 'animations',
    pathsData: cableCarSlopePath,
    variations: [''],
    framePrefix: 'WSM_cable_car_left',
    framePostfix: '',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '',
    },
    turnOnRepeatListener: false,
    turnOnUpdateListener: false,
    turnOnYoyoListener: false,
    repeatCalculateNewVariation: false,
    yoyo: true,
    tweenBuilderConfig: {
      duration: 20000,
    },
    followersDepth: 0,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'cableCarSlope1',
        startDelay: 0,
        repeatDelay: 10000,
        yoyoDelayRandom: {
          mathBetweenMin: 0,
          mathBetweenMax: 25000,
        },
      },
    ],
  },
  {
    animationName: 'flying',
    textureKey: 'animations',
    pathsData: shortDistanceBalloonPath,
    animationsData: balloonAnimations,
    variations: ['v01', 'v02', 'v03'],
    framePrefix: 'WSM_balloon_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    playAnimationWithoutCheckpoints: {
      animationKeyPostfix: 'balloonRotate',
    },
    turnOnRepeatListener: false,
    turnOnUpdateListener: false,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: true,
    tweenBuilderConfig: {
      duration: 10000,
    },
    followersDepth: 3,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'shortDistanceBalloon1',
        startDelay: 0,
        yoyoDelay: 250,
      },
    ],
  },
  {
    animationName: 'flying',
    textureKey: 'animations',
    pathsData: longDistanceBalloonPath,
    variations: ['v01', 'v02', 'v03'],
    framePrefix: 'WSM_balloon_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    yoyoFrameData: {
      yoyoFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      yoyoFrameKey: '03',
    },
    playAnimationWithoutCheckpoints: {
      animationKeyPostfix: 'balloonRotate',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: false,
    turnOnYoyoListener: true,
    yoyoCalculateNewVariation: true,
    repeatCalculateNewVariation: true,
    yoyo: true,
    tweenBuilderConfig: {
      duration: 100000,
    },
    followersDepth: 3,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'longDistanceBalloon1',
        startDelay: 0,
        repeatDelay: 10000,
        yoyoDelayRandom: {
          mathBetweenMin: 0,
          mathBetweenMax: 25000,
        },
      },
    ],
  },
  {
    animationName: 'flying',
    textureKey: 'animations',
    pathsData: flyingHelicopterPath,
    animationsData: flyingHelicopterAnimations,
    checkpointsData: flyingHelicopterCheckpoints,
    variations: ['v01'],
    framePrefix: 'WSM_helicopter_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '20',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 30000,
    },
    followersDepth: 4,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'flyingHelicopter1',
        startDelay: 0,
        repeatDelay: 22000,
      },
    ],
  },
  {
    animationName: 'flying',
    textureKey: 'animations',
    pathsData: landingHelicopterPath,
    animationsData: landingHelicopterAnimations,
    checkpointsData: landingHelicopterCheckpoints,
    variations: ['v01'],
    framePrefix: 'WSM_helicopter_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '15',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 15000,
    },
    followersDepth: 4,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'landingHelicopter1',
        startDelay: 32000,
        repeatDelay: 32000,
      },
    ],
  },
]
