import type { App, Plugin } from 'vue'
import { UNTRANSLATABLE_WORDS } from '@/globalVariables'

export const replaceUntranslatablePlaceholder = (
  text: string = '',
  pattern: keyof typeof UNTRANSLATABLE_WORDS,
): string => {
  const untranslatableKeys = Object.keys(UNTRANSLATABLE_WORDS)
  if (untranslatableKeys.includes(pattern)) {
    return text.replace(pattern, UNTRANSLATABLE_WORDS[pattern])
  }
  return ''
}

export const replaceUntranslatablePlaceholderPlugin: Plugin = {
  install: (app: App): void => {
    app.config.globalProperties.$replaceUntranslatablePlaceholder = replaceUntranslatablePlaceholder
  },
}
