<template>
  <header class="flex items-center">
    <app-discipline-icon :discipline-id="disciplineId" :size="90" class="box-icon" />
    <p
      class="text-40 uppercase"
      :class="{
        'text-texts-standard-name': $isWsm,
        'text-texts-standard-default': $isSsm,
      }"
    >
      {{ disciplineName(disciplineId) }} -
      <span class="text-texts-standard-important">boss fight</span>
    </p>
    <app-control-icon
      class="close-button cursor-pointer absolute right-1 top-1 flex items-center"
      control="hide"
      @click="$emit('close', false)"
    />
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BossFightHeader',
  props: {
    disciplineId: {
      type: Number,
      default: 7,
    },
  },
  emits: ['close'],
  methods: {
    disciplineName(disciplineId: number): string {
      return this.$translateDiscipline(disciplineId ?? '')
    },
  },
})
</script>

<style lang="scss" scoped>
header {
  height: 5.0625rem;
  @include background(
    url('#{$path-events}shared/taskchain/tasks/bossFight/boss-fight-header.avif'),
    cover
  );

  .box-icon {
    margin: 2.375rem 1.375rem 0 1.375rem;
  }
}
</style>
