<template>
  <div class="level-up-title" :class="{ 'level-up-title--mobile': $isMobile() }">
    <h2 ref="animation1" class="level-up-title-first text-50 not-italic animated-element--faded">
      {{ $t(texts.first) }}
    </h2>
    <h2 ref="animation2" class="level-up-title-second text-60 not-italic animated-element--faded">
      {{ `${$t(texts.second)} ${texts.third ? $t(texts.third) : ''}` }}
    </h2>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { animateElement, skipAnimationOnClick } from '@/helpers'

import type { PropType } from 'vue'

interface LevelUpTitle {
  first: string
  second: string
  third?: string
}

export default defineComponent({
  name: 'LevelUpTitle',
  props: {
    texts: {
      type: Object as PropType<LevelUpTitle>,
      required: true,
    },
  },
  mounted(): void {
    this.animateScene()

    // skip animation on click
    window.addEventListener('click', this.handleSkipAnimationClick)
  },
  beforeUnmount(): void {
    window.removeEventListener('click', this.handleSkipAnimationClick)
  },
  methods: {
    animateScene(): void {
      animateElement(this.$refs.animation1, 'fade-in', '.6s', 200)
      animateElement(this.$refs.animation2, 'fade-in', '1s', 600)
    },
    handleSkipAnimationClick(e: MouseEvent): void {
      skipAnimationOnClick(e, this.$el, this.$refs, !!this.texts, null)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/animations.scss';

.level-up-title {
  position: absolute;
  left: 50%;
  top: 6.563rem;
  transform: translateX(-50%);
  text-transform: uppercase;
  font-weight: bold;

  &--mobile {
    top: 4.56rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .level-up-title-first {
      font-size: 3.5rem;
      line-height: 3.5rem;
    }

    .level-up-title-second {
      font-size: 4.25rem;
      line-height: 4.25rem;
    }
  }

  &-first {
    font-weight: normal;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    @if $isWsm {
      background-image: linear-gradient(to bottom, #fff 0%, #d7d7d7 100%);
    }

    @if $isSsm {
      background-image: linear-gradient(to top, #d7d7d7, #fff),
        linear-gradient(to bottom, #fff, #fff);
    }
  }

  &-second {
    background-clip: text;
    -webkit-text-fill-color: transparent;

    @if $isWsm {
      background-image: linear-gradient(to bottom, #fff196 0%, #fadd1e 100%);
    }

    @if $isSsm {
      background-image: linear-gradient(to top, #fec433, #ffe31d),
        linear-gradient(to bottom, #fbb642, #fbb642);
    }
  }
}
</style>
