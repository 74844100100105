import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const clubHelicopterRoundCheckpoints: CheckpointsObjectInterface = {
  key: 'clubHelicopterRoundCheckpoints',
  data: [
    {
      x: 3247,
      y: 940,
      width: 12,
      height: 12,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubHelicopterRoundAnimations1',
        },
      },
    },
    {
      x: 1514,
      y: 661,
      width: 12,
      height: 12,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubHelicopterRoundAnimations2',
        },
      },
    },
    {
      x: 1310,
      y: 784,
      width: 12,
      height: 12,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.PauseAction,
        action: {
          duration: 3000,
        },
      },
    },
    {
      x: 1315,
      y: 692,
      width: 12,
      height: 12,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubHelicopterRoundAnimations3',
        },
      },
    },
    {
      x: 1043,
      y: 695,
      width: 12,
      height: 12,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubHelicopterRoundAnimations4',
        },
      },
    },
    {
      x: 770,
      y: 937,
      width: 12,
      height: 12,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubHelicopterRoundAnimations5',
        },
      },
    },
    {
      x: 748,
      y: 1309,
      width: 12,
      height: 12,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubHelicopterRoundAnimations6',
        },
      },
    },
    {
      x: 1199,
      y: 1669,
      width: 12,
      height: 12,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubHelicopterRoundAnimations7',
        },
      },
    },
    {
      x: 2008,
      y: 1724,
      width: 12,
      height: 12,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubHelicopterRoundAnimations8',
        },
      },
    },
    {
      x: 3055,
      y: 1619,
      width: 12,
      height: 12,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubHelicopterRoundAnimations9',
        },
      },
    },
  ],
}
