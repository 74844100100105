<template>
  <div class="result-position">
    <div
      v-if="numberIcon"
      v-tippy="{
        theme: 'WSM',
        content: tooltipContent,
        placement: 'left',
        textAlign: 'center',
      }"
      class="position flexing"
      :class="iconColor"
    >
      <p class="text-texts-standard-default text-36">
        {{ penalizationContent }}
      </p>
    </div>

    <tippy
      v-if="disciplineId === DISCIPLINE_ID.skiJump"
      theme="WSM"
      placement="left"
      max-width="50rem"
    >
      <app-icon icon-name="info-50" />
      <template #content>
        <div class="tooltip-content tooltip-report info text-texts-standard-default text-24">
          <div class="tooltip-report-row with-line flex items-center justify-between">
            <div class="tooltip-report-row-first" />
            <div>{{ $t('arena.roundOne') }}</div>
            <div v-if="!dailyLeague">
              {{ $t('arena.roundTwo') }}
            </div>
          </div>
          <arena-report-result-tooltip-line
            v-for="(col, i) in skijumpReportColumns"
            :key="i"
            :text="'arena.' + col"
            :columns="getColumnDataInSkijump(col)"
            :highlighted="col === 'points'"
            :muted="
              ['drivingWindow', 'distancePoints', 'stylePoints', 'windCompensation'].includes(col)
            "
          />
        </div>
      </template>
    </tippy>

    <tippy
      v-else-if="disciplineId === DISCIPLINE_ID.shortTrack || disciplineId === DISCIPLINE_ID.sbx"
      theme="WSM"
      placement="left"
      max-width="50rem"
    >
      <app-icon icon-name="info-50" />
      <template #content>
        <div class="tooltip-content tooltip-report info small text-texts-standard-default text-24">
          <div class="tooltip-report-row with-line flex items-center justify-between">
            <div class="tooltip-report-row-first" />
            <div>Semi</div>
            <div>Final</div>
          </div>
          <arena-report-result-tooltip-line
            v-for="(row, i) in shorttrackMockResults"
            :key="i"
            :text="row.title"
            :columns="row.columns"
            :with-line="row.title === 'Rank'"
          />
        </div>
      </template>
    </tippy>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { DISCIPLINE_ID } from '@/globalVariables'
import ArenaReportResultTooltipLine from '@/components/Arena/ArenaReportResultTooltipLine.vue'

import type { PropType } from 'vue'

enum SkiJumpColumns {
  Distance = 'distance',
  DrivingWindow = 'drivingWindow',
  Points = 'points',
  DistancePoints = 'distancePoints',
  StylePoints = 'stylePoints',
  WindCompensation = 'windCompensation',
}

interface RoundDataSkiJump {
  distance?: number
  distancePoints?: number
  drivingWindow?: number
  points?: number
  stylePoints?: number
  windCompensation?: number
}

interface ReportData extends Partial<RoundDataSkiJump> {
  // downhill, biathlon, giant slalom, cross-country skiing
  totalPenalization?: number
  // biathlon, cross-country skiing
  missedTargets?: number
}

interface ComponentData {
  DISCIPLINE_ID: typeof DISCIPLINE_ID
  skijumpReportColumns: SkiJumpColumns[]
  drivingWindowConstant: number
  shorttrackMockResults: { title: string; columns: number[] }[]
}

export default defineComponent({
  name: 'ArenaReportResultIcon',
  components: {
    ArenaReportResultTooltipLine,
  },
  props: {
    disciplineId: {
      type: Number,
      required: true,
    },
    reportData: {
      type: [Object, Array] as PropType<ReportData | ReportData[]>,
      required: true,
    },
    dailyLeague: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      DISCIPLINE_ID,
      skijumpReportColumns: [
        SkiJumpColumns.Distance,
        SkiJumpColumns.DrivingWindow,
        SkiJumpColumns.Points,
        SkiJumpColumns.DistancePoints,
        SkiJumpColumns.StylePoints,
        SkiJumpColumns.WindCompensation,
      ],
      drivingWindowConstant: 21, // local const that should not change
      // TODO: edit if we add shorttrack or snowboard into the game
      shorttrackMockResults: [
        { title: 'Rank', columns: [2, 1] },
        { title: 'Time', columns: [113.2, 116.7] },
      ],
    }
  },
  computed: {
    numberIcon(): boolean {
      return [DISCIPLINE_ID.downhill, DISCIPLINE_ID.biathlon, DISCIPLINE_ID.giantSlalom].includes(
        this.disciplineId,
      )
    },
    tooltipContent(): string {
      return this.disciplineId === DISCIPLINE_ID.biathlon
        ? this.$t('arena.missedTargets')
        : this.$t('arena.penalizationSeconds')
    },
    penalizationContent(): number {
      let data: ReportData = this.reportData
      let penalization = 0
      if (data?.totalPenalization) {
        penalization =
          this.disciplineId === DISCIPLINE_ID.biathlon
            ? data?.missedTargets
            : data?.totalPenalization
      }
      return Number(penalization)
    },
    iconColor(): string {
      return this.disciplineId === DISCIPLINE_ID.biathlon ? 'black' : 'red'
    },
  },
  methods: {
    getColumnDataInSkijump(key: SkiJumpColumns): string[] {
      const data: string[] =
        (this.reportData as ReportData[]).reduce(
          (finalArray: string[], roundData: RoundDataSkiJump): string[] => {
            if (key === SkiJumpColumns.DrivingWindow) {
              finalArray.push(
                (roundData[key] ?? this.drivingWindowConstant) + '/' + this.drivingWindowConstant,
              )
            } else finalArray.push(roundData[key]?.toFixed(1))

            return finalArray
          },
          [],
        ) ?? []

      if (this.disciplineId === DISCIPLINE_ID.skiJump && this.dailyLeague) {
        return [data[0] ?? data[1] ?? '']
      }

      return data
    },
  },
})
</script>

<style lang="scss">
.result-position {
  position: absolute;
  left: -2.2rem;
  z-index: 1;

  .position {
    width: 3.938rem;
    height: 3.938rem;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-size: contain;

    &.black {
      background-image: url($path-arena + 'report/black-ring.avif');
    }

    &.red {
      background-image: url($path-arena + 'report/red-ring.avif');
    }
  }
}

.tooltip-report {
  width: 35rem;
  font-size: 1.875rem;

  &.info {
    text-transform: uppercase;
    font-size: 2.125rem;

    &.small {
      height: 15rem;
    }
  }

  &-row {
    position: relative;
    height: 4.5rem;
    margin-bottom: 0.5rem;

    @if $isSsm {
      color: theme('colors.texts.standard.additional');
      font-size: 2.125rem;
    }

    @if $isWsm {
      &:first-child {
        font-size: 2rem;
      }
    }

    &.highlight {
      @if $isWsm {
        background-image: linear-gradient(to left, transparent, #1e6c9b, transparent);
      }

      @if $isSsm {
        color: theme('colors.texts.standard.default');
        background-image: linear-gradient(
          to right,
          transparent,
          rgba(124, 123, 180, 0.8),
          rgba(124, 123, 180, 0.8),
          transparent
        );
      }
    }

    &.muted {
      @if $isWsm {
        font-size: 1.75rem;
        color: #a0dcff;
      }
    }

    &:not(:last-child) div:last-child {
      width: 6rem;
    }

    &.with-line::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      bottom: 0;
      width: 33.625rem;
      height: 0.125rem;

      @if $isWsm {
        background-image: linear-gradient(to left, transparent, #37a1ed, #37a1ed, transparent);
      }

      @if $isSsm {
        background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.05),
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.05)
        );
      }
    }

    &-first {
      width: 20rem !important;
    }

    &-second {
      width: 7rem;
    }
  }
}
</style>
