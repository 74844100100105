<template>
  <div class="career-unlock-tooltip-header font-bold text-36">
    {{
      $replacePlaceholder(
        $t(`careerQuestUnlocks.${unlockItem.unlocks.text}`),
        '%s',
        unlockItem.unlocks.placeholder ?? '',
      )
    }}
  </div>
  <div v-if="unlockItem.branchType === CareerBranch.Main">
    <ul class="career-unlock-tooltip-list text-28">
      <li v-for="number in getCountOfTexts" :key="number">
        {{
          $replacePlaceholder(
            $t(`careerQuestUnlocks.${unlockItem.image}_tooltip${number.toString()}`),
            '%s',
            5,
          )
        }}
      </li>
    </ul>
  </div>
  <div v-else>
    {{ $t(geUnlockTooltip) }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { CareerBranch } from '@/enums/CareerBranch'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { CareerUnlockItem } from '@/interfaces/CareerUnlockItem'

interface ComponentData {
  CareerBranch: typeof CareerBranch
}

export default defineComponent({
  name: 'CareerUnlockTooltip',
  props: {
    unlockItem: {
      type: Object as PropType<Nullable<CareerUnlockItem>>,
      default: () => null,
    },
  },
  data(): ComponentData {
    return {
      CareerBranch,
    }
  },
  computed: {
    geUnlockTooltip(): string {
      const equipmentLevel = this.unlockItem?.unlocks?.equipmentLevel
      if (!equipmentLevel) return `careerQuestUnlocks.${this.unlockItem?.image}_tooltip`

      return `careerQuestUnlocks.equipment_${
        equipmentLevel === 1 ? 'beginner' : 'new_level'
      }_tooltip`
    },
    getCountOfTexts(): number {
      if (['map_items', 'supertraining'].includes(this.unlockItem.image)) return 1

      return 2
    },
  },
})
</script>

<style lang="scss" scoped>
.career-unlock-tooltip {
  &-header {
    transform: translate(0, 0);
    margin-bottom: 0.8rem;
    margin-top: -1.8rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    @if $isWsm {
      background-image: linear-gradient(
        90deg,
        transparent 0%,
        rgba(35, 109, 155, 1) 50%,
        transparent 100%
      );
    }

    @if $isSsm {
      background-image: linear-gradient(
        to right,
        transparent,
        rgba(179, 82, 162, 0.7),
        #a83c75,
        #a83c75,
        rgba(179, 82, 162, 0.7),
        transparent
      );
      border-style: solid;
      border-width: 0.125rem;
      border-image-source: linear-gradient(
        to right,
        transparent,
        rgba(190, 81, 154, 0.2),
        rgba(253, 150, 205, 0.8),
        rgba(253, 150, 205, 1),
        rgba(253, 150, 205, 0.8),
        rgba(191, 82, 155, 0.2),
        transparent
      );
      border-image-slice: 1;
    }

    @if $isWsm {
      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 0.125rem;
        background: linear-gradient(
          90deg,
          transparent 0%,
          rgba(106, 139, 195, 1) 50%,
          transparent 100%
        );
        left: 0;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }
  }

  &-list {
    text-align: left;
    list-style-type: disc;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
</style>
