<template>
  <div v-if="!isContentLoading" class="flex flex-col flex-grow justify-center items-center w-full">
    <div v-show="!isRecalculating" class="events-rankings-rounds-header flexing w-full">
      <div v-if="state?.round" class="flexing">
        <div
          class="events-rankings-rounds-header-arrow mr-4"
          :class="{ 'opacity-50': isFirstRound }"
          @click="onPreviousRoundClick"
        />
        <p
          class="text-32 text-texts-standard-default font-bold"
          :class="selectedRound === state?.round ? 'mr-8' : 'mr-4'"
        >
          {{ $te('roundRankings') }}
          <span class="text-texts-standard-important">{{ selectedRound }}</span> /
          {{ config?.rounds }}
        </p>
        <app-timer
          v-if="selectedRound === state?.round"
          :time="state?.time_left"
          @countdown-action="loadData"
        />
        <div
          class="events-rankings-rounds-header-arrow events-rankings-arrow-right"
          :class="{ 'opacity-50': isLastRound }"
          @click="onNextRoundClick"
        />
      </div>
    </div>

    <table-component
      v-if="!isRecalculating"
      :columns="headerColumns"
      :items="tableItems"
      :scroll-size="{ mobileY: 42, webY: 43 }"
      :show-logged-user="true"
      :logged-user="loggedUser"
      :pagination="false"
    />

    <div
      v-if="isRecalculating"
      class="events-rankings-recalculation font-bold text-50 text-texts-standard-default"
    >
      <p
        v-html="
          $replacePlaceholder(
            $replaceHtmlPlaceholders(
              $replacePlaceholder($te('updateInProgress'), '{time}', '{b}{time}{/b}'),
              'b',
              'text-texts-standard-important',
            ),
            '{time}',
            getFormattedTimeFromHMS(config?.date_round_start),
          )
        "
      />
    </div>
  </div>

  <component-loading v-if="isContentLoading" :is-loading="true" height="42rem" />
</template>

<script lang="ts">
import TableComponent from '@/components/Table/TableComponent.vue'
import { clubTableKeys } from '@/components/Table/tableKeys'
import { getFormattedTimeFromHMS } from '@/helpers'
import type {
  PlayerInfo,
  PlayerStats,
} from '@/interfaces/responses/events/league/LeagueStateApiResponse'
import { defineComponent } from 'vue'

import type Reward from '@/interfaces/Reward'
import { useLeagueEventStore } from '@/store/pinia/events/leagueStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import type { Nullable } from '@/interfaces/utils'

// TODO: add as interface into table component
interface HeaderColumn {
  label: string
  disableSort: boolean
  key: string
  align: string
  width: number
  coloredPos?: boolean
  headerAlign?: string
  onlyHeaderPadding?: boolean
  minWidth?: string
  background?: string
  isBackgroundSkew?: boolean
  tooltip?: string
}

interface TableItem {
  pos: number
  userId: string
  name: {
    name: string
    country: string
  }
  points: number
  rewards: Reward[]
}

interface ComponentData {
  selectedRound: Nullable<number>
  clubTableKeys: typeof clubTableKeys
  headerColumns: HeaderColumn[]
}

export default defineComponent({
  name: 'EventsRankingsTable',
  components: {
    TableComponent,
  },
  data(): ComponentData {
    return {
      selectedRound: null,
      clubTableKeys,
      headerColumns: [
        {
          label: this.$t('rankingsGroup.pos'),
          disableSort: true,
          key: clubTableKeys.pos,
          align: 'center',
          coloredPos: true,
          width: 3,
        },
        {
          label: this.$t('common.name'),
          disableSort: true,
          key: clubTableKeys.nameComponent,
          onlyHeaderPadding: true,
          align: 'left',
          headerAlign: 'left',
          width: 45,
        },
        {
          label: this.$te('eventReputation'),
          disableSort: true,
          key: clubTableKeys.reputation,
          align: 'center',
          width: 20,
        },
        {
          label: this.$t('common.rewards'),
          disableSort: true,
          key: clubTableKeys.rewards,
          background: this.$isWsm ? '#234965' : undefined,
          isBackgroundSkew: true,
          tooltip: this.$te('rewardsInfoRound'),
          align: 'center',
          width: 32,
        },
      ],
    }
  },
  computed: {
    ...mapState(useLeagueEventStore, {
      state: 'getState',
      config: 'getConfig',
      isRecalculating: 'getIsRecalculating',
    }),
    ...mapState(useUserStore, {
      userData: 'getUserData',
    }),
    tableItems(): TableItem[] {
      return this.state?.results?.players.map((player: PlayerStats) => {
        const selectedPlayerConfig = this.state.players.find(
          (item: PlayerInfo): boolean => item.user_games_id === player.user_games_id,
        )

        return {
          pos: player?.position,
          userId: player?.user_games_id,
          name: {
            name: selectedPlayerConfig?.username,
            country: selectedPlayerConfig?.country,
          },
          points: player?.points,
          rewards: player?.rewards,
        }
      })
    },
    loggedUser(): TableItem {
      return this.tableItems?.find((item: TableItem): boolean => item.userId === this.userData.id)
    },
    isFirstRound(): boolean {
      return this.selectedRound === this.state?.started_in_round
    },
    isLastRound(): boolean {
      return this.selectedRound === this.config?.rounds || this.selectedRound === this.state?.round
    },
    isContentLoading(): boolean {
      return this.state == null && this.config == null && this.selectedRound == null
    },
  },
  async created(): Promise<void> {
    await this.loadData()
  },
  methods: {
    ...mapActions(useLeagueEventStore, {
      loadConfig: 'loadConfig',
      loadState: 'loadState',
    }),
    getFormattedTimeFromHMS,
    async loadData(): Promise<void> {
      await this.loadConfig()
      await this.loadState()
      this.selectedRound = this.state?.round
    },
    async onPreviousRoundClick(): Promise<void> {
      if (this.isFirstRound) return
      this.selectedRound--

      await this.loadState(this.selectedRound)
    },
    async onNextRoundClick(): Promise<void> {
      if (this.isLastRound) return
      this.selectedRound++

      await this.loadState(this.selectedRound)
    },
  },
})
</script>

<style lang="scss" scoped>
.events-rankings-rounds {
  &-header {
    background: linear-gradient(
      90deg,
      rgba(71, 135, 180, 0) 0%,
      rgba(71, 135, 180, 0.6) 50%,
      rgba(71, 135, 180, 0) 100%
    );
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    height: 3.875rem;

    &-arrow {
      width: 2.188rem;
      height: 2.188rem;
      cursor: pointer;
      @include background(
        url($path-events + 'discipline-event/rankings/events-rankings-arrow.avif'),
        cover
      );
    }

    .events-rankings-arrow-right {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
    }
  }
}

.events-rankings-recalculation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  border-style: solid;
  border-width: 0.125rem;
  border-image-slice: 1;

  @if $isWsm {
    background-image: linear-gradient(
      90deg,
      rgba(22, 80, 138, 0) 0%,
      rgba(22, 80, 138, 0.5) 25%,
      rgba(22, 80, 138, 0.5) 75%,
      rgba(22, 80, 138, 0) 100%
    );
    border-image-source: linear-gradient(
      90deg,
      rgba(32, 109, 186, 0) 0%,
      rgba(32, 109, 186, 0.5) 25%,
      rgba(32, 109, 186, 0.5) 75%,
      rgba(32, 109, 186, 0) 100%
    );
  }

  @if $isSsm {
    width: 87.5rem;
    height: 14.5rem;
    background-image: linear-gradient(to right, transparent, #1a2840, transparent);
    border-image-source: linear-gradient(to right, transparent, #bdbddf, transparent);
  }
}
</style>
