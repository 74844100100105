<template>
  <div class="currency-explanator w-full h-full flexing">
    <div class="annie" :style="tutorialBossStyle" />
    <div class="currency-anne-table">
      <p
        class="currency-anne-table-header text-texts-standard-important text-40 uppercase"
        :class="{
          'font-bold': $isSsm,
        }"
      >
        {{ getTutorialBossName }}
      </p>
      <p class="currency-anne-table-text text-texts-standard-default text-34 text-left">
        {{ $t('explanation.header') }}
      </p>
      <section class="currencies flex flex-col justify-around">
        <div
          v-for="(currency, index) in currencies"
          :key="index"
          class="currency flex items-center justify-evenly"
        >
          <app-main-icon
            :icon-size="152"
            :icon-name="currenciesData[currency.toUpperCase()]?.icon"
          />
          <div class="currency-texts flex items-start flex-col">
            <p class="text-texts-standard-important text-36 uppercase">
              {{ $t(currenciesData[currency.toUpperCase()]?.head) }}
            </p>

            <p class="text-texts-standard-default text-30 text-left">
              {{ $t(currenciesData[currency.toUpperCase()]?.text) }}
            </p>
          </div>
        </div>
      </section>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { EXPERIENCE, MONEY, RANKING_POINTS, pathFigures } from '@/globalVariables'
import { getDisciplineBossSlug } from '@/helpers'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface CurrenciesDataItem {
  icon: string
  head: string
  text: string
}

interface CurrenciesData {
  EXPERIENCE: CurrenciesDataItem
  MONEY: CurrenciesDataItem
  RANKING_POINTS: CurrenciesDataItem
}

interface ComponentData {
  currenciesData: CurrenciesData
}

export default defineComponent({
  name: 'CurrencyExplanator',
  props: {
    currencies: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      currenciesData: {
        EXPERIENCE: {
          icon: EXPERIENCE,
          head: 'common.experiences',
          text: 'explanation.experience',
        },
        MONEY: {
          icon: MONEY,
          head: 'map.money',
          text: 'explanation.money',
        },
        RANKING_POINTS: {
          icon: RANKING_POINTS,
          head: 'rankingsGroup.rankingPoints',
          text: 'explanation.ranking_points',
        },
      },
    }
  },
  computed: {
    ...mapState(useTutorialStore, ['getTutorialBossName', 'getTutorialBossPose']),
    tutorialBossStyle(): string {
      const slug = getDisciplineBossSlug(this.getTutorialBossName)
      return `background-image: url(${pathFigures}/${slug.replace('-', '')}/${slug}-${
        this.getTutorialBossPose
      }.avif);`
    },
  },
})
</script>

<style lang="scss" scoped>
.currency-explanator {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  .annie {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 41.25rem;
    height: 64.75rem;
    outline: 1500rem solid transparent;
    position: relative;
    left: -3rem;
  }

  .currency-anne-table {
    width: 70.75rem;
    height: 49.188rem;
    position: relative;
    top: -3rem;

    @if $isWsm {
      background-color: rgba(24, 60, 88, 0.8);
      border: solid 0.125rem #4581af;
    }

    @if $isSsm {
      background-color: #232a40;
      border: solid 0.125rem #4c648f;
    }

    &-header {
      margin: 0.078rem 0;
      width: 50rem;
      height: 3.938rem;
      text-shadow: 0.069rem 0.106rem 0.069rem rgba(0, 0, 0, 0.46);
      display: flex;
      align-items: center;
      padding-left: 2.5rem;

      @if $isWsm {
        background-image: linear-gradient(to right, #144a73, transparent);
      }

      @if $isSsm {
        background-image: linear-gradient(to right, #4c648f, transparent);
      }
    }

    &-text {
      position: relative;
      top: 1rem;
      padding-left: 2.5rem;
    }
  }

  .currencies {
    height: 80%;

    .currency {
      &-texts {
        width: 44.625rem;
      }
    }
  }
}
</style>
