<template>
  <div v-if="Object.keys(mappedRewards).length" class="club-map-right-box">
    <div v-for="(reward, index) in mappedRewards" :key="index" class="club-map-right-box-rewards">
      <p class="text-texts-standard-default text-34">
        {{ $filters.$formatNumber($filters.$abbrNumber(reward.value)) }}
      </p>
      <app-main-icon :icon-size="48" :icon-name="getIconName(reward)" />
    </div>
    <app-button
      class="club-map-right-box-btn"
      btn-id="club-map-right-box-btn"
      btn-type="secondary"
      :btn-text="$t('common.takeRewards')"
      btn-size="md"
      @click="claimRewards()"
    />
  </div>
</template>

<script lang="ts">
import { useRewardStore } from '@/store/pinia/clubs/rewardStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { ClubRewardResponse, ClubReward, MappedRewards } from '@/interfaces/clubs/ClubRewards'

export default defineComponent({
  name: 'ClubsMapRightBox',
  computed: {
    ...mapState(useRewardStore, {
      allClubReadyForClaimRewards: 'getAllClubReadyForClaimRewards',
    }),
    mappedRewards(): MappedRewards {
      return this.allClubReadyForClaimRewards.reduce(
        (finalObject: MappedRewards, element: ClubRewardResponse): MappedRewards => {
          const rewardData = element.rewards
          rewardData.value = parseInt(rewardData.value.toString())
          const finalNameOfReward = `${rewardData?.type ?? ''}_${rewardData.benefit_type ?? ''}_${
            rewardData.rarity ?? ''
          }`
          if (finalObject[finalNameOfReward]?.value)
            finalObject[finalNameOfReward].value += rewardData.value
          else finalObject[finalNameOfReward] = { ...rewardData } // we have to lose reference to parent, because it will mutate element in allRewards

          return finalObject
        },
        {},
      )
    },
  },
  created(): void {
    this.loadAllClubRewards(true)
  },

  methods: {
    ...mapActions(useRewardStore, ['loadAllClubRewards', 'claimRewards']),
    getIconName(reward: ClubReward): string {
      return reward.gdd_id ? `${reward.type}-${reward.gdd_id}` : reward.type
    },
  },
})
</script>

<style lang="scss" scoped>
.club-map-right-box {
  position: absolute;
  top: 6rem;
  right: calc(1.5rem + var(--safe-area-inset-right));

  &-rewards {
    width: 14.75rem;
    height: 3.5rem;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0.188rem;
    @if $isWsm {
      background-image: linear-gradient(
        to right,
        transparent 0%,
        #3d6b90 25%,
        #3d6b90 75%,
        #0f3255 100%
      );
    }
    @if $isSsm {
      background-image: linear-gradient(
        to right,
        rgba(79, 83, 115, 0.01) 0%,
        rgba(66, 79, 105, 0.78) 20%,
        #323d55 100%
      );
      position: relative;
      &::before {
        content: '';
        width: 0.188rem;
        height: 2.125rem;
        background-color: #feb942;
        position: absolute;
        left: 2.5rem;
      }
    }
  }

  &-btn {
    width: 19.375rem;
    margin-top: 0.5rem;
    margin-right: 0.35rem;
  }
}
</style>
