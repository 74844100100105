<template>
  <info-popup
    v-if="isVisible"
    :popup-title="$te('totalStandingsRewards')"
    width="76.125rem"
    height="46.625rem"
    @close="$emit('close')"
  >
    <section class="league-reward-tiers-popup justify-center">
      <table-component
        :columns="headerColumns"
        :items="tableItems"
        :scroll-size="{ mobileY: 40, webY: 40 }"
        :pagination="false"
      />
    </section>
  </info-popup>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import TableComponent from '@/components/Table/TableComponent.vue'
import { clubTableKeys } from '@/components/Table/tableKeys'
import { defineComponent } from 'vue'

import type { RankingRewardTier } from '@/interfaces/responses/events/league/LeagueConfigApiResponse'
import type Reward from '@/interfaces/Reward'
import { useLeagueEventStore } from '@/store/pinia/events/leagueStore'
import { mapState } from 'pinia'

interface HeaderColumn {
  label: string
  disableSort: boolean
  key: string
  align: string
  width: number
  onlyHeaderPadding?: boolean
  coloredPos?: boolean
  background?: string
  isBackgroundSkew?: boolean
  minWidth?: string
}

interface TableItem {
  rank: number | string
  rewards: Reward[]
}

interface ComponentData {
  headerColumns: HeaderColumn[]
}

export default defineComponent({
  name: 'LeagueRewardTiersPopup',
  components: {
    InfoPopup,
    TableComponent,
  },
  props: {
    isVisible: {
      type: Boolean,
      defualt: false,
    },
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      headerColumns: [
        {
          label: this.$t('event.position'),
          onlyHeaderPadding: true,
          disableSort: true,
          key: clubTableKeys.rank,
          align: 'left',
          width: 70,
        },
        {
          label: this.$t('common.rewards'),
          disableSort: true,
          key: clubTableKeys.rewards,
          background: this.$isWsm ? '#234965' : undefined,
          isBackgroundSkew: true,
          align: 'center',
          width: 29,
          minWidth: '28.75rem',
        },
      ],
    }
  },
  computed: {
    ...mapState(useLeagueEventStore, {
      config: 'getConfig',
    }),
    tableItems(): TableItem[] {
      return this.config.top_ranking_rewards.map((tier: RankingRewardTier): TableItem => {
        const position: string | number =
          tier.place_from === tier.place_to
            ? tier.place_from
            : tier.place_to === -1
              ? `${this.$filters.$formatNumber(tier.place_from)} +`
              : `${this.$filters.$formatNumber(tier.place_from)} - ${this.$filters.$formatNumber(
                  tier.place_to,
                )}`

        return {
          rank: position,
          rewards: tier.rewards,
        }
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.league-reward-tiers-popup {
  height: 41.5rem;
}
</style>
