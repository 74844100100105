import type PremiumOfferApiResponse from '@/interfaces/responses/premium/PremiumOfferApiResponse'

type Pack = PremiumOfferApiResponse['packs'][0]

type Parameter = Pack['parameters'][0]

const OLD_PRICE_PARAM_NAME = 'old_price'

export const hasOfferOldPrice = (pack: Pick<Pack, 'parameters'>): boolean =>
  pack.parameters
    .map(({ name }: Parameter): Parameter['name'] => name)
    .includes(OLD_PRICE_PARAM_NAME)

export const getOfferOldPrice = (pack: Pick<Pack, 'parameters'>): number =>
  Number(
    pack.parameters.find(({ name }: Parameter): boolean => name === OLD_PRICE_PARAM_NAME)?.value,
  )
