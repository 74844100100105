<template>
  <div
    v-if="getGameName"
    :id="'offer-' + offerData.type"
    :data-cy="'offer-' + offerData.type"
    class="offer-box relative cursor-pointer"
    :style="{
      background: `url(${pathImages}premium/offers/offer-${getGameName}_level_bags.avif) center no-repeat`,
      backgroundSize: 'contain',
    }"
    @click="showOffer()"
  >
    <main
      class="offer-box-main relative text-36 text-texts-standard-dark font-bold flex justify-center"
    >
      <div class="offer-box-main-title flexing">
        <p
          class="offer-box-main-text text-center"
          v-html="$t('premiumGroup.level_up_bags_about')"
        ></p>
      </div>
    </main>
    <footer class="offer-box-footer flex items-center absolute w-full flex-col justify-center">
      <app-button
        btn-id="bags-offer-button"
        btn-type="secondary"
        :btn-text="$t('button.detail')"
        btn-size="md"
      />
      <span class="bags-value absolute">
        <app-plate-value
          color="red"
          :text-value="`<span class='font-bold'>${offerValue}x</span> value`"
        />
      </span>
    </footer>
  </div>
</template>

<script lang="ts">
import AppPlateValue from '@/components/GlobalComponents/AppPlateValue.vue'
import { pathImages } from '@/globalVariables'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { Offer } from '@/interfaces/premium/Offer'
import type { NameValueString } from '@/interfaces/Global'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'OffersBags',
  components: { AppPlateValue },
  props: {
    offerData: {
      type: Object as PropType<Nullable<Offer>>,
      default: () => null,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    offerValue(): string | number {
      return (
        this.offerData?.parameters?.find((item: NameValueString): boolean => item.name === 'value')
          ?.value ?? ''
      )
    },
    getGameName(): string {
      if (this.$isWsm) {
        return 'wsm'
      }
      if (this.$isSsm) {
        return 'ssm'
      }
      return ''
    },
  },
  methods: {
    ...mapActions(usePremiumStore, {
      hideOfferDetail: 'hideOfferDetail',
    }),
    showOffer(): void {
      this.hideOfferDetail()
      this.$router.push({ name: this.$getWebView('PremiumLevelBagsPacks') })
    },
  },
})
</script>

<style lang="scss" scoped>
.offer-box {
  height: 44.875rem;
  width: 78.75rem;
  margin-right: 1.5rem;
  position: relative;

  &-main {
    height: 31.5rem;

    &-title {
      text-align: center;
      position: absolute;
      top: 7rem;

      width: 90%;
      height: 2.875rem;
      border: 0.188rem solid;
      border-left: 0;
      border-image-slice: 1;
      @if $isWsm {
        background-image: linear-gradient(
          90deg,
          transparent 0%,
          rgba(237, 191, 71, 0.8) 25%,
          rgba(247, 212, 101, 0.8) 50%,
          rgba(237, 191, 71, 0.8) 75%,
          transparent 100%
        );
        border-image-source: linear-gradient(
          90deg,
          transparent 0%,
          rgba(249, 229, 133, 1) 25%,
          rgba(249, 229, 133, 1) 75%,
          transparent 100%
        );
      }
      @if $isSsm {
        background-image: linear-gradient(
          to right,
          transparent,
          rgba(237, 191, 71, 0.8),
          rgba(247, 212, 101, 0.8),
          rgba(237, 191, 71, 0.79),
          transparent
        );
        border-image-source: linear-gradient(
          90deg,
          transparent 0%,
          #f9e585 25%,
          #f9e585 75%,
          transparent 100%
        );
      }
    }
  }

  &-footer {
    bottom: 0.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 7.3rem;
  }

  .bags-value {
    right: 2rem;
  }
}
</style>
