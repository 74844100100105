<template>
  <div class="game-pass-reward-wrapper">
    <div
      class="game-pass-reward flexing relative"
      :class="[
        `reward-${rewardType}`,
        {
          claimed: isClaimed,
          'clickable-element cursor-pointer not-claimed': isNotClaimed,
          'opacity-50': isUnobtained,
        },
        rewardStatus,
      ]"
      @click="$debounce(claimReward, [false])"
    >
      <span
        ref="rewardItem"
        class="reward-wrapper flexing flex-col mt-4"
        :class="{ unlocked: isNotClaimed }"
      >
        <app-icon v-if="isBonusReward" :icon-name="`${bonusRewardIcon}-90`" />
        <app-main-icon
          v-else
          :icon-size="90"
          :icon-name="formatRewards([reward], reward.name ? 'name' : 'type', 'value')[0].type"
          :tooltip="translateIconName(formatRewards([reward], 'type', 'value'))"
          class="relative bottom-3"
        />
        <p
          class="reward-text text-texts-standard-default mt-4"
          :class="isBonusReward ? 'text-30' : 'text-36'"
        >
          {{ isBonusReward ? bonusRewardText : $filters.$formatNumber(reward.value) }}
        </p>
      </span>
      <span v-if="isNotClaimed" class="claim-button absolute flexing">
        <p class="text-28 text-texts-standard-dark font-bold mb-4">{{ $t('common.claim') }}</p>
      </span>
      <app-notification
        v-if="isNotClaimed && !isLocked"
        type="exclamationLabel"
        class="notification-icon-33"
      />
      <app-icon v-if="isLocked" icon-name="lock-sm" class="reward-lock" />
      <section v-if="isClaimed" class="reward-claimed flex-col">
        <p class="uppercase text-texts-standard-positive text-28">{{ $t('common.claimed') }}</p>
        <app-icon
          v-if="isClaimed"
          icon-name="done-xl"
          class="reward-claimed-icon absolute top-0 left-0"
        />
      </section>
    </div>
    <info-message
      v-if="activatedBonus"
      :info-message-data="{
        headerText: $t('gamePass.bonusActive'),
        hasDecoration: true,
        buttonText: $t('button.continue'),
      }"
      @info-btn-click="activatedBonus = null"
    >
      <app-icon
        :icon-name="`${effectIcon(activatedBonus)}-90`"
        class="message-icon pointer-events-none"
      />
      <p class="text-texts-standard-default text-48 uppercase font-bold">
        {{ effectTitle(activatedBonus) }}
        <span v-if="!activatedBonus.bonus?.includes('x')" class="text-texts-standard-important">
          {{ activatedBonus.bonus }}
        </span>
      </p>
      <p class="text-texts-standard-default text-30 mt-4" v-html="effectText1(activatedBonus)"></p>
      <p class="text-texts-standard-default text-30 mt-4" v-html="effectText2(activatedBonus)"></p>
    </info-message>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { formatRewards, gamePassRewardsParser, capitalize } from '@/helpers'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type Reward from '@/interfaces/Reward'
import { GamePassType, GamePassMilestoneStatus } from '@/interfaces/premium/enums'
import {
  GRAND_PRIZE,
  gamePassClaimMilestone,
  GAME_PASS_STARTS_CAP,
  GAME_PASS_ENERGY_CAP,
  GAME_PASS_DOUBLE_START_REGEN,
  GAME_PASS_ARENA_MULTIPLIER,
  GAME_PASS_TRAINING_MULTIPLIER,
  GAME_PASS_DOUBLE_ENERGY_REGEN,
  GAME_PASS_RESEARCH_SPEED_UP,
  GAME_PASS_INSTANT_EQUIPMENT_REPAIR,
  GAME_PASS_SKIP_REWARDED_ADS,
  GAME_PASS_DAILY_TASK_SLOT,
} from '@/globalVariables'
import InfoMessage from '@/components/InfoMessage.vue'

interface ComponentData {
  activatedBonus: Effect
}

interface Effect {
  name: string
  bonus: string
}

export default defineComponent({
  name: 'GamePassReward',
  components: { InfoMessage },
  props: {
    rewardType: {
      type: String,
      required: true,
      validator(propName: GamePassType): boolean {
        return [GamePassType.Basic, GamePassType.Elite, GamePassType.Pro].includes(propName)
      },
    },
    rewardStatus: {
      type: String,
      required: true,
      validator(propName: GamePassMilestoneStatus): boolean {
        return [
          GamePassMilestoneStatus.Unoptained,
          GamePassMilestoneStatus.UnoptainedLocked,
          GamePassMilestoneStatus.NotClaimed,
          GamePassMilestoneStatus.NotClaimedLocked,
          GamePassMilestoneStatus.Claimed,
        ].includes(propName)
      },
    },
    reward: {
      type: Object as PropType<Nullable<Reward>>,
      default: null,
    },
    milestoneId: {
      type: Number,
      required: true,
    },
  },
  emits: ['reloadData'],
  data(): ComponentData {
    return {
      activatedBonus: null,
    }
  },
  computed: {
    isClaimed(): boolean {
      return this.rewardStatus === GamePassMilestoneStatus.Claimed
    },
    isUnobtained(): boolean {
      return this.rewardStatus === GamePassMilestoneStatus.Unoptained
    },
    isUnobtainedLocked(): boolean {
      return this.rewardStatus === GamePassMilestoneStatus.UnoptainedLocked
    },
    isNotClaimedLocked(): boolean {
      return this.rewardStatus === GamePassMilestoneStatus.NotClaimedLocked
    },
    isNotClaimed(): boolean {
      return (
        this.rewardStatus === GamePassMilestoneStatus.NotClaimed ||
        this.rewardStatus === GamePassMilestoneStatus.NotClaimedLocked
      )
    },
    isLocked(): boolean {
      return this.isUnobtained || this.isNotClaimedLocked || this.isUnobtainedLocked
    },
    isBonusReward(): boolean {
      if (!this.reward?.name) return false

      return [
        GAME_PASS_STARTS_CAP,
        GAME_PASS_ENERGY_CAP,
        GAME_PASS_DOUBLE_START_REGEN,
        GAME_PASS_ARENA_MULTIPLIER,
        GAME_PASS_TRAINING_MULTIPLIER,
        GAME_PASS_SKIP_REWARDED_ADS,
        GAME_PASS_DOUBLE_ENERGY_REGEN,
        GAME_PASS_RESEARCH_SPEED_UP,
        GAME_PASS_INSTANT_EQUIPMENT_REPAIR,
        GAME_PASS_DAILY_TASK_SLOT,
      ].includes(this.reward.name)
    },
    bonusRewardIcon(): string {
      return gamePassRewardsParser(this.reward).icon
    },
    bonusRewardText(): string {
      return `${this.reward.bonus?.includes('x') ? this.reward.bonus : ''} ${this.$t(
        gamePassRewardsParser(this.reward).text,
      )} ${this.reward.bonus?.includes('+') ? this.reward.bonus : ''}`
    },
  },
  methods: {
    formatRewards,
    effectTitle(effect: Effect): string {
      return this.$t(gamePassRewardsParser(effect).text)
    },
    effectIcon(effect: Effect): string {
      return gamePassRewardsParser(effect).icon
    },
    effectText1(effect: Effect): string {
      const replacements = {
        '{number}': effect.bonus?.replace(/[+ x]/g, ''),
        '{2to4}': 3,
      }
      let str = this.$t(gamePassRewardsParser(effect).subText1)

      str = str.replace(/{\w+}/g, (match: string): string => {
        const replacement = replacements[match] || match
        return `<span class="text-texts-standard-important">${replacement}</span>`
      })
      return str
    },
    effectText2(effect: Effect): string {
      return this.$replacePlaceholder(
        this.$t(gamePassRewardsParser(effect).subText2),
        '{pass}',
        `<span class="text-texts-standard-important">${this.$t('gamePass.gamePass')}</span>`,
      )
    },
    translateIconName(reward: Reward): string {
      const rewardType = reward.type || reward.name
      if (reward.rarity && rewardType === 'gp-' + reward.rarity) {
        return this.$replacePlaceholder(
          this.$t('grandPrize.tier' + capitalize(reward.rarity)),
          '{grandprize}',
          GRAND_PRIZE,
        )
      } else {
        return rewardType
      }
    },
    async claimReward(): Promise<void> {
      // TODO DS: Externalize this functionality
      // 1. Cloned item
      const rewardItem = this.$refs.rewardItem as Nullable<HTMLElement>

      // 2. Get element into which we will insert animated element
      const wrapper = document.querySelector('#app_id')
      const placeholder = document.createElement('div')
      // 3. Inject cloned item content
      placeholder.innerHTML = rewardItem.innerHTML

      // 4. Calculate top and left position for placeholder
      const rect = rewardItem.getBoundingClientRect()
      const top = rect.y
      const left = rect.x
      // 5. Save top, left as CSS custom properties to placeholder
      placeholder.classList.add('animation-fixed-placeholder')
      placeholder?.style.setProperty('--top', `${top}px`)
      placeholder?.style.setProperty('--left', `${left}px`)

      if (!this.isNotClaimed) return

      if (this.rewardStatus === GamePassMilestoneStatus.NotClaimedLocked) {
        this.$router.push({ name: this.$getWebView('PremiumGamePassPacks') })
        return
      }

      // 7. Remove placeholder after animation
      placeholder.addEventListener('animationend', (): void => {
        placeholder.remove()
      })

      try {
        const claimData = await this.$axios.post<{}, Effect>(gamePassClaimMilestone, {
          milestoneId: this.milestoneId,
        })

        if (!wrapper || !rewardItem) return
        // 6. Insert placeholder into wrapper & start animation
        wrapper.appendChild(placeholder)

        if (claimData?.name) {
          this.activatedBonus = claimData
        }
      } catch (error: unknown) {
        console.error(error)
      }

      this.$emit('reloadData')
    },
  },
})
</script>

<style lang="scss" scoped>
.game-pass-reward-wrapper {
  position: relative;

  .game-pass-reward {
    width: 13.875rem;
    height: 12.125rem;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
    flex-shrink: 0;

    &.reward-elite {
      background-image: url($path-premium + 'gamePass/main/reward-box-elite.avif');
      &.claimed {
        background-image: url($path-premium + 'gamePass/main/reward-box-elite-claimed.avif');
      }
      &.not-claimed {
        background-image: url($path-premium + 'gamePass/main/reward-box-elite-claim.avif');
      }
    }

    &.reward-pro {
      margin-top: 0.7rem;
      margin-bottom: 1.425rem;
      background-image: url($path-premium + 'gamePass/main/reward-box-pro.avif');
      &.claimed {
        background-image: url($path-premium + 'gamePass/main/reward-box-pro-claimed.avif');
      }
      &.not-claimed {
        background-image: url($path-premium + 'gamePass/main/reward-box-pro-claim.avif');
      }
    }

    &.reward-basic {
      background-image: url($path-premium + 'gamePass/main/reward-box-basic.avif');
      &.claimed {
        background-image: url($path-premium + 'gamePass/main/reward-box-basic-claimed.avif');
      }
      &.not-claimed {
        background-image: url($path-premium + 'gamePass/main/reward-box-basic-claim.avif');
      }
    }

    .reward-text {
      line-height: 2rem;
      width: 13.1rem;
    }

    .reward-lock {
      top: -1rem;
      right: -0.75rem;
      position: absolute;
    }
    &.claimed {
      .reward-wrapper {
        opacity: 0.6;
      }
    }
    .reward-claimed {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      &-icon {
        background-image: url($path-premium + 'gamePass/main/ico-checkmark.avif');
        width: 13.875rem;
        height: 12.125rem;
        background-size: contain;
      }
    }

    .claim-button {
      width: 10.188rem;
      height: 3.75rem;
      top: -0.5rem;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url($path-premium + 'gamePass/main/btn-claim.avif');
    }
  }
}

.message-icon {
  position: absolute;
  top: -8rem;
  transform: scale(1.5);
}
</style>
