import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const clubBoatLeftToRightCheckpoints: CheckpointsObjectInterface = {
  key: 'clubBoatLeftToRightCheckpoints',
  data: [
    {
      x: 1994,
      y: 1897,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubBoatLeftToRightAnimations1',
        },
      },
    },
    {
      x: 1202,
      y: 1808,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubBoatLeftToRightAnimations2',
        },
      },
    },
    {
      x: 770,
      y: 1631,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubBoatLeftToRightAnimations3',
        },
      },
    },
    {
      x: 595,
      y: 1396,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubBoatLeftToRightAnimations4',
        },
      },
    },
    {
      x: 571,
      y: 1211,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubBoatLeftToRightAnimations5',
        },
      },
    },
    {
      x: 643,
      y: 1052,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubBoatLeftToRightAnimations6',
        },
      },
    },
    {
      x: 1005,
      y: 724,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubBoatLeftToRightAnimations7',
        },
      },
    },
    {
      x: 1384,
      y: 640,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubBoatLeftToRightAnimations8',
        },
      },
    },
    {
      x: 1838,
      y: 649,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubBoatLeftToRightAnimations9',
        },
      },
    },
  ],
}
