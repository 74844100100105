import { appStartErrorHandler } from '@/globals/handlers/appStartErrorHandler'
import { usePhaserStore } from '@/store/pinia/map/phaser'
/**
 * Handler co preventivne riesi stav appky nez nastane error stav appky ktory vieme zachytit
 * @returns
 */
export const gameStateHandler = (): void => {
  const useStore = usePhaserStore()
  if (useStore.getGameState) return
  appStartErrorHandler()
}
