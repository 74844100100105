import { MilestoneType } from './TournamentsMilestonesConfigApiResponse'

export { MilestoneType } from './TournamentsMilestonesConfigApiResponse'

export enum MilestoneState {
  Progressable = 'progressable',
  Claimable = 'claimable',
  Claimed = 'claimed',
}

export interface Milestone {
  current_milestone: number
  progress: {
    total_progress: number
    current_progress: number
    next_progress: number
  }
  locked: boolean
  state: Record<string, MilestoneState>
}

export interface TournamentsMilestonesStateApiResponse {
  milestones: {
    [MilestoneType.Free]: Milestone
    [MilestoneType.Premium]: Milestone
  }
}
