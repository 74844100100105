<template>
  <div
    ref="textContainer"
    class="flex text-container w-full"
    :class="[containerClassList, { 'animated-container': animatedOverflow && isOverflowing }]"
  >
    <div v-if="isOverflowing && animatedOverflow" class="animated-container__inner">
      <span
        ref="shrinkText"
        class="base shrink-text"
        :class="[
          `text-${forcedFontSize ? forcedFontSize : localFontSize}`,
          textClassList,
          {
            'italic-text': italic,
            'animated-text': animatedOverflow && isOverflowing,
          },
        ]"
        :title="truncatedText"
      >
        {{ truncatedText }}
      </span>
    </div>
    <span
      v-else
      ref="shrinkText"
      class="base shrink-text"
      :class="[
        `text-${forcedFontSize ? forcedFontSize : localFontSize}`,
        textClassList,
        {
          'italic-text': italic,
          'overflow-hidden': isOverflowing && !animatedOverflow,
          [`multi-line line-clamp-${maxLineLimit}`]: maxLineLimit && isOverflowing,
        },
      ]"
      :title="isOverflowing ? truncatedText : ''"
    >
      {{ truncatedText }}
    </span>
  </div>
</template>

<script lang="ts">
import { FONT_SIZES } from '@/globalVariables'
import type { ClassValue } from '@/interfaces/utils'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface ComponentData {
  fontSizePoints: number[]
  localFontSize: number
  truncatedText: string
  isOverflowing: boolean
  scrollHeight: number
  resizeCount: number
}

export default defineComponent({
  name: 'ShrinkText',
  props: {
    text: {
      type: String,
      required: true,
    },
    italic: {
      type: Boolean,
      default: true,
    },
    fontSize: {
      type: Number,
      default: 24,
      validator: (value: number): boolean => FONT_SIZES.includes(value),
    },
    forcedFontSize: {
      type: Number,
      default: null,
    },
    containerClassList: {
      type: [String, Array, Object] as PropType<ClassValue>,
      default: () => [],
    },
    textClassList: {
      type: [String, Array, Object] as PropType<ClassValue>,
      default: () => [],
    },
    animatedOverflow: {
      type: Boolean,
      default: null,
    },
    maxLineLimit: {
      type: Number,
      default: null,
    },
  },
  data(): ComponentData {
    return {
      fontSizePoints: FONT_SIZES,
      localFontSize: this.fontSize,
      truncatedText: this.text,
      isOverflowing: false,
      scrollHeight: 0,
      resizeCount: 0,
    }
  },
  watch: {
    text(): void {
      this.truncatedText = this.text
      this.checkOverflow(true)
    },
    fontsize(): void {
      this.localFontSize = this.fontSize
      this.checkOverflow(true)
    },
  },
  mounted(): void {
    // resize only once via true
    this.checkOverflow(true)
  },
  methods: {
    checkOverflow(resize: boolean = false): void {
      const textContainer = this.$refs.textContainer
      const textElement = this.$refs.shrinkText

      if (textContainer && textElement) {
        const containerWidth = textContainer.offsetWidth
        const textWidth = textElement.offsetWidth

        if (textWidth > containerWidth) {
          this.isOverflowing = true
          if (resize) {
            this.localFontSize = this.findAndRetrieveElement(this.localFontSize)
            this.resizeCount++

            // INFO: at 110 to 44 reduction by 1 jump in font size
            // 40 & lower by 2 jumps
            if (this.fontSize <= 40 && this.resizeCount === 1) {
              // second resize & check for isOverflowing
              this.checkOverflow(true)
            } else {
              // only one check for isOverflowing & :title after resizing
              this.checkOverflow()
            }
          }
        } else {
          this.isOverflowing = false
        }
      }
    },
    findAndRetrieveElement(size: number): number {
      const index = this.fontSizePoints.indexOf(size)

      if (index > 0) {
        return this.fontSizePoints[index - 1]
      } else {
        return this.fontSizePoints[0]
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.base {
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* This class is associated with .line-clamp-* */
.multi-line {
  white-space: normal;
}

/* if the text still exceeds after resizing, apply the 'hidden' style */
.overflow-hidden {
  overflow: hidden;
}

.italic-text {
  @if $isSsm {
    font-style: normal;
    padding-right: 0;
  }

  @if $isWsm {
    font-style: italic;
    /* italic fix for cutting off the end of letters: X, K ... */
    padding-right: 0.3rem;
  }
}

.animated-container {
  position: relative;
  justify-content: left;
  padding: 5px 10px;
  min-width: 0;

  &__inner {
    overflow: hidden;
    mask-image: linear-gradient(to right, transparent, black 20%, black 80%, transparent 100%);

    &:hover {
      & .animated-text {
        animation-play-state: paused;
      }
    }
  }

  .animated-text {
    position: relative;
    animation: leftright 6s infinite linear;
    display: inline-block;
    white-space: nowrap;
    // initial offset
    padding-left: 100%;
  }
}

@keyframes leftright {
  to {
    transform: translateX(-100%);
  }
}
</style>
