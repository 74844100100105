<template>
  <div id="settings" class="settings-wrapper app-page-wrapper absolute">
    <user-id-box class="absolute id-box" :class="{ 'id-box-web': !$isMobile() }" />
    <menu-component
      menu-type="settings"
      :title="$t('settings.settings')"
      :sub-menu-active="true"
      :sub-menu="{
        1: {
          text: $t('settings.global'),
          route: $getWebView('Settings'),
        },
        2: {
          text: $t('settings.audio'),
          route: $getWebView('Audio'),
        },
        3: {
          text: $t('settings.account'),
          route: $getWebView('Account'),
          current: $getWebView('Account'),
        },
      }"
    />
    <section class="w-full h-full safe-area">
      <main class="settings relative w-full">
        <app-scrollbar
          :height="$isMobile() ? '75vh' : '49rem'"
          width="100%"
          slide="y"
          scroll="y"
          class="settings-scroll"
          :options="{ suppressScrollY: false, suppressScrollX: true }"
        >
          <settings-box :title="$t('settings.playerName')">
            <div class="flexing">
              <app-input
                id="input-username"
                :value="user.username"
                name="username"
                class="account-input-margin"
                disabled
              />
              <app-button
                class="settings-btn"
                btn-id="settings-btn-pwd"
                btn-type="primary"
                :btn-text="$t('settings.change')"
                @click="showPopupName = true"
              />
            </div>
          </settings-box>
          <settings-box :title="$t('settings.gender')">
            <app-toggle
              :texts="[$t('settings.male'), $t('settings.female')]"
              :selected-button="sex === 'male' ? 1 : 2"
              @action="toggleGender"
            />
          </settings-box>
          <settings-box :title="$t('settings.country')">
            <div
              v-if="flagBackground"
              class="settings-box-flag-background absolute top-0 left-0"
              :style="{
                backgroundImage:
                  'url(' +
                  pathImages +
                  'flags/bg-flags-overlay-box-740x116/' +
                  flagBackground +
                  '.avif)',
              }"
            />
            <app-select
              :options="formattedCountries"
              :default="selectedCountry"
              @select="selectCountry"
            />
          </settings-box>
          <div class="separator" />
          <settings-box :title="$t('settings.account')">
            <connected-accounts />
          </settings-box>
          <settings-box :title="$t('mobileApp.email')">
            <div class="flexing">
              <app-input
                id="input-email"
                name="email-disabled"
                :value="user.email"
                :disabled="true"
                class="account-input-margin"
              />
              <app-button
                class="settings-btn"
                btn-id="settings-btn-pwd"
                btn-type="primary"
                :btn-text="$t('settings.change')"
                @click="showPopupEmail = true"
              />
            </div>
          </settings-box>
          <settings-box :title="$t('mobileApp.password')">
            <app-button
              class="settings-btn"
              btn-id="settings-btn-pwd"
              btn-type="primary"
              :btn-text="$t('settings.change')"
              @click="showPopupPassword = true"
            />
          </settings-box>
          <settings-box :title="$t('settings.deleteAccount')" :description="setDeleteDescription">
            <app-button
              v-if="!deletingInProcess"
              class="settings-btn"
              btn-id="settings-btn-delete"
              btn-type="danger"
              :btn-text="$t('post.delete')"
              @click="showDeleteAccount = true"
            />
            <div v-else class="delete-account flexing">
              <p class="text-texts-standard-default text-28 uppercase">
                {{ $t('settings.timeToDeleteAccount') }}:
              </p>
              <app-timer
                :icon="'refresh-md'"
                :time="timeToAccountDeletion ?? 10"
                class="ml-8"
                @countdown-action="finishDeletion"
              />
              <app-button
                class="settings-btn"
                btn-id="settings-btn-cancel-deletion"
                btn-type="secondary"
                :btn-text="$t('settings.cancelDeleteAccount')"
                @click="cancelDeletion"
              />
            </div>
          </settings-box>
        </app-scrollbar>

        <popup-window
          v-if="showDeleteAccount"
          popup-type="submit"
          :popup-data="getDeleteSubmit"
          @close="showDeleteAccount = false"
          @action="deleteAccount"
        >
          <div class="flexing flex-col">
            <p class="text-texts-standard-additional text-32 my-8">
              {{ $t('settings.deleteAccountQuestion') }}
            </p>
            <p class="text-texts-standard-default text-36 mb-8">
              {{ $replacePlaceholder($t('settings.cancelDeleteAccountInfo'), '%s', 30) }}
            </p>
          </div>
        </popup-window>

        <info-popup
          v-if="showPopupEmail"
          :popup-title="$t('settings.changeEmail')"
          width="28.25rem"
          @close="showPopupEmail = false"
        >
          <login-form-with-flow
            :context="LoginFormContext.EMAIL_CHANGE"
            @reload-user-data="loadUserData"
          />
        </info-popup>

        <!--
        <confirm-popup
          v-if="showPopupEmail"
          :button-data="popupBtnEmail"
          :popup-title="$t('settings.changeEmail')"
          :not-multi="true"
          :single-button-text="$t('settings.change')"
          @close="closeConfirmPopup"
          @action="changeEmail"
        >
          <section class="settings-popup">
            <p class="settings-popup-label">
              {{ $t('settings.newEmail') }}
            </p>
            <app-input
              id="newEmail-id"
              :value="newEmail"
              name="new-email"
              :error="newEmailError"
              class="settings-popup-input mx-auto"
              @input="(value) => onInput(value, 'email')"
            />
            <p class="settings-popup-label">
              {{ $t('mobileApp.password') }}
            </p>
            <app-input
              id="password-id"
              :value="currentPassword"
              type="password"
              name="password"
              :error="currentPasswordError"
              class="settings-popup-input mx-auto"
              @input="(pwd) => onInput(pwd, 'current')"
            />
          </section>
        </confirm-popup>
        -->

        <confirm-popup
          v-if="showPopupName"
          :button-data="popupBtnName"
          :popup-title="$t('settings.changeName')"
          :not-multi="true"
          :single-button-text="$t('settings.change')"
          @close="closeWithPopupName"
          @action="changeName"
        >
          <section class="settings-popup username">
            <p class="settings-popup-label">
              {{ $t('settings.newName') }}
            </p>
            <app-input
              id="newUserName-id"
              :value="newUserName"
              name="username"
              :error="nameError"
              :max-length="20"
              :placeholder="user.username"
              class="settings-popup-input mx-auto"
              @input="(value) => onInput(value, 'name')"
            />
          </section>
        </confirm-popup>

        <confirm-popup
          v-if="showPopupPassword"
          :button-data="popupBtnPassword"
          :popup-title="$t('settings.changePassword')"
          :not-multi="true"
          :single-button-text="$t('settings.change')"
          @close="closePasswordPopup"
          @action="changePassword"
        >
          <section class="settings-popup password">
            <p class="settings-popup-label">
              {{ $t('settings.currentPassword') }}
            </p>
            <app-input
              id="currentPassword-id"
              :value="currentPassword"
              type="password"
              :error="currentPasswordError"
              class="settings-popup-input mx-auto"
              @input="(pwd) => onInput(pwd, 'current')"
            />
            <p class="settings-popup-label">
              {{ $t('settings.newPassword') }}
            </p>
            <app-input
              id="newPassword-id"
              :value="newPassword"
              type="password"
              :error="newPasswordError"
              class="settings-popup-input mx-auto"
              @input="(pwd) => onInput(pwd, 'new')"
            />
            <p class="settings-popup-label">
              {{ $t('settings.repeatNewPassword') }}
            </p>
            <app-input
              id="retypePassword-id"
              :value="retypePassword"
              type="password"
              :error="retypePasswordError"
              class="settings-popup-input mx-auto"
              @input="(pwd) => onInput(pwd, 'retype')"
            />
          </section>
        </confirm-popup>
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import AppToggle from '@/components/GlobalComponents/AppToggle.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import SettingsBox from '@/components/Settings/SettingsBox.vue'
import UserIdBox from '@/components/Settings/UserIdBox.vue'
import ConnectedAccounts from '@/components/Settings/ConnectedAccounts.vue'
import LoginFormWithFlow from '@/components/Login/LoginFormWithFlow.vue'
import { LoginFormContext } from '@/enums/LoginForm'
import {
  MAX_USERNAME_LENGTH,
  MIN_USERNAME_LENGTH,
  addLoginMethodEndpoint,
  cancelDeleteRequest,
  deleteAccount,
  deleteRequest,
  pathImages,
  userProfileEndpoint,
  userProfileUsernameCheckEndpoint,
} from '@/globalVariables'
import { gameToken, sendToFlutter, validateEmail, validateUsername } from '@/helpers'
import { logoutUser } from '@/helpers/misc'
import { useCountriesStore } from '@/store/pinia/countriesStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type Country from '@/interfaces/Country'
import type { AccountRequestDeleteApiResponse } from '@/interfaces/responses/account/AccountRequestDeleteApiResponse'
import type { TrueFalseInterface } from '@/interfaces/utils'
import type { UserProfile } from '@/types/userProfile'

interface ComponentData {
  MIN_USERNAME_LENGTH: typeof MIN_USERNAME_LENGTH
  MAX_USERNAME_LENGTH: typeof MAX_USERNAME_LENGTH
  LoginFormContext: typeof LoginFormContext
  pathImages: string
  showPopupEmail: boolean
  showPopupName: boolean
  showPopupPassword: boolean
  showDeleteAccount: boolean
  deletingInProcess: boolean
  popupBtnEmail: {
    btnId: string
    btnType: string
    btnSize: string
  }
  name: string
  email: string
  popupBtnName: {
    btnId: string
    btnType: string
    btnSize: string
  }
  popupBtnPassword: {
    btnId: string
    btnType: string
    btnSize: string
  }
  newUserName: string
  nameError: string
  newEmail: string
  newEmailError: string
  currentPassword: string
  currentPasswordError: string
  newPassword: string
  newPasswordError: string
  retypePassword: string
  retypePasswordError: string
}

export default defineComponent({
  name: 'AccountComponent',
  components: {
    UserIdBox,
    SettingsBox,
    AppToggle,
    ConfirmPopup,
    ConnectedAccounts,
    PopupWindow,
    InfoPopup,
    LoginFormWithFlow,
  },
  data(): ComponentData {
    return {
      MIN_USERNAME_LENGTH,
      MAX_USERNAME_LENGTH,
      LoginFormContext,
      pathImages,
      showPopupEmail: false,
      showPopupName: false,
      showPopupPassword: false,
      showDeleteAccount: false,
      deletingInProcess: false,
      popupBtnEmail: {
        btnId: 'btn-change-email',
        btnType: 'primary',
        btnSize: 'md',
      },
      name: '',
      email: '',
      popupBtnName: {
        btnId: 'btn-change-name',
        btnType: 'primary',
        btnSize: 'md',
      },
      popupBtnPassword: {
        btnId: 'btn-change-password',
        btnType: 'primary',
        btnSize: 'md',
      },
      newUserName: '',
      nameError: '',
      newEmail: '',
      newEmailError: '',
      currentPassword: '',
      currentPasswordError: '',
      newPassword: '',
      newPasswordError: '',
      retypePassword: '',
      retypePasswordError: '',
    }
  },
  computed: {
    ...mapState(useCountriesStore, {
      formattedCountries: 'getFormattedCountries',
    }),
    ...mapState(useUserStore, {
      selectedCountry: 'getCountry',
      user: 'getUserData',
      sex: 'getSex',
      timeToAccountDeletion: 'getTimeToAccountDeletion',
    }),
    ...mapState(useResponseTaskStore, {
      validationErros: 'getValidationErrors',
    }),
    flagBackground(): string {
      return this.formattedCountries.find(
        (country: Country): boolean => country.value === this.selectedCountry,
      )?.abbr
    },
    getDeleteSubmit(): {
      title: string
      btnText: string
      btnType: string
      btnId: string
      text: string
    } {
      return this.$filters.$submitPopupData({
        title: this.$t('settings.deleteAccount'),
        btnText: this.$t('post.delete'),
        btnType: 'danger',
        btnId: 'delete-account-button',
      })
    },
    setDeleteDescription(): string {
      return this.deletingInProcess
        ? this.$t('settings.deletingMessageSent')
        : this.$replacePlaceholder(this.$t('settings.cancelDeleteAccountInfo'), '%s', 30)
    },
  },
  created(): void {
    this.name = this.user.username
    this.newEmail = this.email
    if (this.timeToAccountDeletion) this.deletingInProcess = true
  },
  methods: {
    logoutUser,
    ...mapActions(useUserStore, {
      setUserData: 'setUserData',
      setProfileAttributes: 'setProfileAttributes',
      loadUserData: 'loadUserData',
    }),
    ...mapActions(useResponseTaskStore, {
      removeValidationErrors: 'removeValidationErrors',
    }),
    closeConfirmPopup(): void {
      this.showPopupEmail = false
      this.closePopup()
    },
    closeWithPopupName(): void {
      this.showPopupName = false
      this.closePopup()
    },
    closePasswordPopup(): void {
      this.showPopupPassword = false
      this.closePopup()
    },
    showValidationErrors(): void {
      const errors = this.validationErros
      if (errors.includes('invalidPassword'))
        this.currentPasswordError = this.$t('settings.invalidPassword')
      if (errors.includes('email-not-valid'))
        this.newEmailError = this.$t('mobileApp.errorEmailInvalidFormat')
      if (errors.includes('emailAlreadyRegistered'))
        this.newEmailError = this.$t('settings.emailAlreadyRegistered')
      this.removeValidationErrors()
    },
    closePopup(): void {
      this.newPassword = ''
      this.currentPassword = ''
      this.retypePassword = ''
      this.newUserName = ''
      this.newEmail = ''
    },
    toggleGender(selectedOption: number): void {
      this.setProfileAttributes({
        name: 'sex',
        value: selectedOption === 1 ? 'male' : 'female',
      })
    },
    async changeName(): Promise<void> {
      if (this.user.username === this.newUserName) {
        this.showPopupName = false
        return
      }
      if (this.validateUserName()) {
        const wait = await this.checkExistingName()
        if (!wait) return
        this.resetErrors()
        const response = await this.$axios.post<{}, UserProfile, { username: string }>(
          userProfileEndpoint,
          {
            username: this.newUserName,
          },
        )
        this.user.username = response.user_data.username
        this.setUserData(this.user)
        this.showPopupName = false
      }
    },
    async changePassword(): Promise<void> {
      if (this.validatePassword()) {
        this.resetErrors()
        await this.$axios.post<{}, UserProfile, { new_password: string; current_password: string }>(
          userProfileEndpoint,
          {
            new_password: this.newPassword,
            current_password: this.currentPassword,
          },
        )
        if (!this.validationErros.length) {
          this.showPopupPassword = false
          return
        }
        this.showValidationErrors()
      }
    },
    async changeEmail(): Promise<void> {
      if (this.user.platforms.web) {
        if (this.validateEmailInput() && this.validateCurrentPassword()) {
          this.resetErrors()
          const response = await this.$axios.post<
            {},
            UserProfile,
            { email: string; password: string }
          >(userProfileEndpoint, {
            email: this.newEmail,
            password: this.currentPassword,
          })
          if (!this.validationErros.length) {
            this.user.email = response.user_data.email
            this.setUserData(this.user)
            this.showPopupEmail = false
            return
          }
          this.showValidationErrors()
        }
      } else {
        if (this.validateEmailInput()) {
          this.connectEmail(this.newEmail, this.currentPassword)
          this.showPopupEmail = false
        }
      }
    },
    validatePassword(): boolean {
      const min = 6
      const max = 20
      if (this.newPassword.length < min || this.newPassword.length > max) {
        this.newPasswordError = this.$t('homepage.errorPasswordInvalidFormat')
          .replace('%s', min.toString())
          .replace('%s', max.toString())
        return false
      }
      if (this.newPassword !== this.retypePassword) {
        this.retypePasswordError = this.$t('settings.newPasswordsDoesNotMatch')
        return false
      }
      if (!this.validateCurrentPassword()) {
        return false
      }
      return true
    },
    validateCurrentPassword(): boolean {
      if (!this.currentPassword.length) {
        this.currentPasswordError = this.$t('settings.invalidPassword')
        return false
      }
      return true
    },
    validateUserName(): boolean {
      if (this.newUserName.length < MIN_USERNAME_LENGTH) {
        this.nameError = this.$replacePlaceholder(
          this.$t('settings.map.name_too_short'),
          '{2to4}',
          MIN_USERNAME_LENGTH.toString(),
        )
        return false
      }

      if (this.newUserName.length > MAX_USERNAME_LENGTH) {
        this.nameError = this.$replacePlaceholder(
          this.$t('settings.map.name_too_long'),
          '{max}',
          MAX_USERNAME_LENGTH.toString(),
        )
        return false
      }

      if (!validateUsername(this.newUserName)) {
        this.nameError = this.$t('map.name_invalid')
        return false
      }

      return true
    },
    validateEmailInput(): boolean {
      if (!validateEmail(this.newEmail)) {
        this.newEmailError = this.$t('mobileApp.errorEmailInvalidFormat')
        return false
      }
      return true
    },
    resetErrors(): void {
      this.newPasswordError = ''
      this.retypePasswordError = ''
      this.currentPasswordError = ''
      this.newEmailError = ''
      this.nameError = ''
    },
    async selectCountry(country: string): Promise<void> {
      if (country) {
        const response = await this.$axios.post<{}, UserProfile, { country: string }>(
          userProfileEndpoint,
          {
            country,
          },
        )
        this.user.country = response.user_data.country
        this.setUserData(this.user)
      }
    },
    onInput(value: string, type: string): void {
      this.resetErrors()
      value = value.trim()
      switch (type) {
        case 'current':
          this.currentPassword = value
          return
        case 'new':
          this.newPassword = value
          return
        case 'retype':
          this.retypePassword = value
          return
        case 'email':
          this.newEmail = value
          return
        case 'name':
          this.newUserName = value
      }
    },
    async connectEmail(email: string, password: string): Promise<void> {
      if (!this.$isMobile()) {
        const res = await this.$axios.post<
          {},
          TrueFalseInterface,
          {
            email: string
            password: string
            loginType: string
            game_token: string
            uuid: string
            platform: string
          }
        >(addLoginMethodEndpoint, {
          email,
          password,
          loginType: 'email',
          game_token: gameToken,
          uuid: this.user.id,
          platform: 'web',
        })
        if (!res) return
        this.user.anonymous = false
        this.user.platforms.web = true
        this.setUserData(this.user)
      } else {
        sendToFlutter('link-account')
      }
    },
    async checkExistingName(): Promise<boolean> {
      const response = await this.$axios.post<{}, TrueFalseInterface, { username: string }>(
        userProfileUsernameCheckEndpoint,
        {
          username: this.newUserName,
        },
      )

      if (response) {
        this.nameError = this.$t('map.name_unavailable')
        return false
      } else {
        return true
      }
    },
    async userDataLoading(): Promise<void> {
      await this.loadUserData()
    },
    async deleteAccount(): Promise<void> {
      await this.$axios.put<{}, AccountRequestDeleteApiResponse>(deleteRequest)
      this.userDataLoading()

      this.showDeleteAccount = false
      this.deletingInProcess = true
    },
    async cancelDeletion(): Promise<void> {
      await this.$axios.put<{}, TrueFalseInterface>(cancelDeleteRequest)
      this.userDataLoading()

      this.deletingInProcess = false
      if (sessionStorage.getItem('hideDeleteAccountMessage'))
        sessionStorage.removeItem('hideDeleteAccountMessage')
    },
    async finishDeletion(): Promise<void> {
      await this.$axios.delete<{}, TrueFalseInterface>(deleteAccount)
      if (this.$isMobile())
        sendToFlutter(
          JSON.stringify({
            event: 'disconnect',
          }),
        )
      this.logoutUser()
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/settings/settings.scss';
</style>
