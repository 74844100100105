<template>
  <app-button
    btn-icon="arena"
    :btn-text="$t('career.home')"
    class="btn-home"
    @click="goToHomePage"
  />
</template>

<script lang="ts">
import { MECHANIC_MAP_ITEMS } from '@/globalVariables'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MapBackButton',
  computed: {
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
    }),
  },
  methods: {
    ...mapActions(useResponseTaskStore, {
      checkInterstitial: 'checkInterstitial',
    }),
    ...mapActions(usePhaserGameIntegrationStore, [
      'refresProgressTimers',
      'refreshMainMapRewardedItems',
    ]),
    goToHomePage(): void {
      this.$router.push({ name: 'LayoutView' })
      this.checkInterstitial()

      this.refresProgressTimers()

      if (this.hasMechanic(MECHANIC_MAP_ITEMS)) {
        this.refreshMainMapRewardedItems()
      }
    },
  },
})
</script>

<style lang="scss" scoped>
button.btn-home {
  color: #fff;
  @if $isWsm {
    background: linear-gradient(80deg, #4386b6, #305e7f);
  }
  @if $isSsm {
    background: linear-gradient(to right, #414368, #5f5d88);
    border-color: #a3a1d2;
  }

  &:before {
    content: '';
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 2.2rem;
    left: 2.5rem;
    transform: translate(-50%, -50%) rotate(135deg);
    border-right: 0.19rem solid #fff;
    border-bottom: 0.19rem solid #fff;
    box-shadow: inset -1px -1px 1px #00000099;
    filter: drop-shadow(0px 0px 4px white);
  }
}
</style>
