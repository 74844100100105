<template>
  <div class="login-form-with-flow flexing flex-col" :class="`login-form-with-flow--${context}`">
    <transition
      :name="transitionName"
      mode="out-in"
      @before-leave="onBeforeLeave"
      @enter="onEnter"
      @after-enter="onAfterEnter"
    >
      <template v-if="currentStep === LoginFlowStep.EnterEmail">
        <div class="flexing flex-col w-full">
          <form class="w-full p-6 space-y-6" @submit.prevent>
            <h1
              v-if="context !== LoginFormContext.CONNECTION"
              class="text-texts-standard-important font-medium text-36"
            >
              {{ $t('homepage.loginOrReg') }}
            </h1>
            <div
              v-if="
                context !== LoginFormContext.CONNECTION || isEmailNotConnected || isEmailNotVerified
              "
            >
              <div class="mb-4">
                <div class="v-field--variant-outlined relative">
                  <input
                    v-model.trim="form.email"
                    v-focus
                    :class="{ 'is-error': errors.email.size > 0 }"
                    type="text"
                    name="email"
                    :placeholder="$t('homepage.email')"
                    :disabled="isLoading"
                  />
                  <div class="v-field__outline" v-html="inputLabel('homepage.email')" />
                </div>
                <div class="validation-text__wrapper">
                  <div
                    class="validation-text"
                    :class="{ 'validation-text--error': errors.email.size > 0 }"
                  >
                    <span v-for="(error, i) in errors.email" :key="i">
                      {{ error }}
                    </span>
                  </div>
                </div>
              </div>
              <app-button
                type="submit"
                btn-type="confirm"
                :disabled="isLoading || form.email.length === 0 || errors.email.size > 0"
                @click="onContinueClick"
              >
                {{ $t('homepage.continue') }}
                <component-loading class="absolute inset-0" :is-loading="isLoading" />
              </app-button>
            </div>
            <div v-else>
              <p class="mb-6 text-texts-standard-important font-medium text-36">
                {{ user.email }}
              </p>
              <div class="email-connect text-28 px-4">
                <div class="flexing flex-row gap-4">
                  <app-icon class="email-connect-icon" icon-name="mail-light" />
                  <div
                    class="email-connect-title"
                    :class="{
                      'font-bold': $isWsm,
                      'font-medium': $isSsm,
                    }"
                    v-html="getConnectedAccountStatusText(connectedAccounts?.email)"
                  />
                </div>
                <app-icon
                  v-if="connectedAccounts?.email === ConnectedAccountStatus.Connected"
                  icon-name="done-sm"
                />
              </div>
            </div>

            <div class="use-text flexing text-texts-standard-default uppercase m-auto text-28">
              <div class="use-text__divider use-text__divider--left" />
              <span
                v-if="context !== LoginFormContext.CONNECTION || !allSocialConnected"
                class="use-text__label"
              >
                {{ $t('homepage.orUse') }}
              </span>
              <div class="use-text__divider use-text__divider--right" />
            </div>
            <div class="flex flex-col w-full space-y-6">
              <facebook-button
                :is-disabled="isLoading"
                :monochrome="
                  context !== LoginFormContext.CONNECTION ||
                  connectedAccounts?.facebook === ConnectedAccountStatus.NotConnected
                "
                :status="connectedAccounts?.facebook"
                :context="context"
                @facebook-connection="onFacebookConnection"
              />
              <google-button
                :is-disabled="isLoading"
                :status="connectedAccounts?.google"
                :context="context"
                @google-connection="onGoogleConnection"
              />
            </div>
          </form>
          <div class="continue-policy__wrapper" :class="`continue-policy__wrapper--${context}`">
            <p v-if="context === 'login'" v-html="registrationText" />
          </div>
        </div>
      </template>

      <template
        v-else-if="
          [
            LoginFlowStep.EnterPasswordAndSignIn,
            LoginFlowStep.EnterPasswordAndSignUp,
            LoginFlowStep.EnterPasswordAndConnect,
          ].includes(currentStep)
        "
      >
        <div class="flexing flex-col w-full">
          <form class="w-full p-6 space-y-6" @submit.prevent>
            <h1
              class="text-texts-standard-important font-medium text-36"
              :class="{
                'relative -bottom-2': currentStep === LoginFlowStep.EnterPasswordAndConnect,
              }"
            >
              <pps-account-tooltip :text="$t(messages[currentStep])" :games="games" />
            </h1>
            <p
              class="text-texts-standard-default text-28 font-medium"
              :class="{
                'relative -top-2': currentStep === LoginFlowStep.EnterPasswordAndConnect,
              }"
            >
              <template v-if="currentStep === LoginFlowStep.EnterPasswordAndSignIn">
                {{ $t('homepage.emailLinkedToPPSAccount') }}
              </template>
              <template v-else-if="currentStep === LoginFlowStep.EnterPasswordAndSignUp">
                {{ $t('homepage.PPSNewAccountInfo') }}
              </template>
              <template v-else-if="currentStep === LoginFlowStep.EnterPasswordAndConnect">
                {{ $t('homepage.PPSNewAccountInfo') }}
              </template>
            </p>
            <div>
              <div class="v-field--variant-outlined relative">
                <input type="text" name="email" :value="form.email" disabled />
                <div class="v-field__outline" v-html="inputLabel('homepage.email')" />
                <div
                  class="cursor-pointer absolute top-1/2 transform -translate-y-1/2 right-4 text-texts-standard-default text-32 underline uppercase"
                  @click="currentStep = LoginFlowStep.EnterEmail"
                >
                  <div class="input-icon is-edit-icon" />
                </div>
              </div>
              <div class="validation-text__wrapper">
                <div
                  class="validation-text"
                  :class="{ 'validation-text--error': errors.email.size > 0 }"
                >
                  <span v-for="(error, i) in errors.email" :key="i">
                    {{ error }}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div class="v-field--variant-outlined relative">
                <input
                  v-model.trim="form.password"
                  v-focus
                  :class="{ 'is-error': errors.password.size > 0 }"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  name="password"
                  :placeholder="$t('homepage.password')"
                  :disabled="isLoading"
                />
                <div class="v-field__outline" v-html="inputLabel('homepage.password')" />
                <div
                  class="input-icon cursor-pointer absolute top-1/2 transform -translate-y-1/2 right-16"
                  :class="
                    isPasswordVisible ? 'is-password-visible-icon' : 'is-password-hidden-icon'
                  "
                  @click="isPasswordVisible = !isPasswordVisible"
                />
                <tippy
                  class="absolute top-1/2 transform -translate-y-1/2 right-4"
                  theme="WSM"
                  placement="top"
                >
                  <div class="input-icon is-info-icon cursor-pointer" />
                  <template #content>
                    <p>{{ passwordTooltip }}</p>
                  </template>
                </tippy>
              </div>
              <div class="validation-text__wrapper">
                <div
                  class="validation-text"
                  :class="{ 'validation-text--error': errors.password.size > 0 }"
                >
                  <span v-for="(error, i) in errors.password" :key="i">
                    {{ error }}
                  </span>
                </div>
              </div>
            </div>
            <p
              v-if="currentStep === LoginFlowStep.EnterPasswordAndSignIn"
              class="text-texts-standard-default text-28"
            >
              <span
                class="uppercase underline cursor-pointer"
                @click="currentStep = LoginFlowStep.ForgottenPassword"
              >
                {{ $t('homepage.forgotPassword') }}
              </span>
            </p>
            <app-button
              type="submit"
              :btn-type="
                currentStep === LoginFlowStep.EnterPasswordAndSignUp ? 'secondary' : 'confirm'
              "
              :disabled="
                isLoading ||
                form.email.length === 0 ||
                form.password.length < PASSWORD_MIN_LENGTH ||
                form.password.length > PASSWORD_MAX_LENGTH ||
                errors.email.size > 0 ||
                errors.password.size > 0
              "
              @click="
                currentStep === LoginFlowStep.EnterPasswordAndConnect
                  ? onConnectClick()
                  : onPlayNowClick()
              "
            >
              {{ accountActionButtonLabel }}
              <component-loading class="absolute inset-0" :is-loading="isLoading" />
            </app-button>
            <p
              v-if="currentStep !== LoginFlowStep.EnterPasswordAndConnect"
              class="text-texts-standard-default text-28"
            >
              <span
                class="uppercase underline cursor-pointer"
                @click="currentStep = LoginFlowStep.EnterEmail"
              >
                {{ $t('homepage.back') }}
              </span>
            </p>
          </form>
        </div>
      </template>

      <template v-else-if="currentStep === LoginFlowStep.EmailVerification">
        <div
          class="flexing flex-col w-full py-6 gap-6"
          :class="{
            'flex-grow px-6': isEmailChange,
          }"
        >
          <div
            class="flex flex-row gap-4 text-texts-standard-default text-28 relative -bottom-2"
            :class="{
              'text-32': isEmailChange,
            }"
          >
            <span>{{ $t('settings.verificationEmailSent') }}</span>
            <app-icon
              v-tippy="{
                content: $replacePlaceholder(
                  $t('settings.verificationEmailNotReceived'),
                  '{email}',
                  supportMail,
                ),
                theme: 'WSM',
                placement: 'top',
                maxWidth: '35rem',
              }"
              icon-name="info-44"
            />
          </div>
          <div class="relative flexing flex-row gap-4">
            <span class="text-texts-standard-important font-bold text-36 relative -top-2">
              {{ form.email }}
            </span>
          </div>
          <app-button
            type="submit"
            btn-type="primary"
            :disabled="isLoading"
            @click="currentStep = LoginFlowStep.EmailChange"
          >
            {{ $t('settings.changeEmail') }}
            <component-loading class="absolute inset-0" :is-loading="isLoading" />
          </app-button>
          <template v-if="!isEmailChange">
            <div class="use-text flexing text-texts-standard-default uppercase m-auto text-28">
              <div class="use-text__divider use-text__divider--left" />
              <span
                v-if="context !== LoginFormContext.CONNECTION || !allSocialConnected"
                class="use-text__label"
              >
                {{ $t('homepage.orUse') }}
              </span>
              <div class="use-text__divider use-text__divider--right" />
            </div>
            <div class="flex flex-col w-full space-y-6">
              <facebook-button
                :is-disabled="isLoading"
                :monochrome="
                  context !== LoginFormContext.CONNECTION ||
                  connectedAccounts?.facebook === ConnectedAccountStatus.NotConnected
                "
                :status="connectedAccounts?.facebook"
                :context="context"
                @facebook-connection="onFacebookConnection"
              />
              <google-button
                :is-disabled="isLoading"
                :status="connectedAccounts?.google"
                :context="context"
                @google-connection="onGoogleConnection"
              />
            </div>
          </template>
        </div>
      </template>

      <template v-else-if="currentStep === LoginFlowStep.ForgottenPassword">
        <div class="flexing flex-col w-full">
          <form class="w-full p-6 space-y-6" @submit.prevent>
            <h1 class="text-texts-standard-important font-medium text-36">
              {{ $t('homepage.forgotPassword') }}
            </h1>
            <p class="text-texts-standard-default text-28 font-medium !mt-2">
              {{ $t('homepage.forgotPasswordEmailText') }}
            </p>
            <div>
              <div class="v-field--variant-outlined relative">
                <input
                  v-model.trim="form.email"
                  v-focus
                  :class="{ 'is-error': errors.email.size > 0 }"
                  type="text"
                  name="email"
                  :placeholder="$t('homepage.email')"
                  :disabled="isLoading"
                />
                <div class="v-field__outline" v-html="inputLabel('homepage.email')" />
              </div>
              <div class="validation-text__wrapper">
                <div
                  class="validation-text"
                  :class="{ 'validation-text--error': errors.email.size > 0 }"
                >
                  <span v-for="(error, i) in errors.email" :key="i">
                    {{ error }}
                  </span>
                </div>
              </div>
            </div>
            <app-button
              type="submit"
              btn-type="confirm"
              :disabled="isLoading || form.email.length === 0 || errors.email.size > 0"
              @click="onForgottenPasswordSubmit"
            >
              {{ $t('homepage.send') }}
              <component-loading class="absolute inset-0" :is-loading="isLoading" />
            </app-button>
            <p class="text-texts-standard-additional text-28">
              <span
                v-if="
                  [LoginFormContext.CONNECTION, LoginFormContext.EMAIL_CHANGE].includes(context)
                "
                class="uppercase underline cursor-pointer"
                @click="currentStep = LoginFlowStep.EmailChange"
              >
                {{ $t('homepage.back') }}
              </span>
              <template v-else>
                {{ $t('homepage.rememberPassword') }}
                <span
                  class="text-texts-standard-default font-bold uppercase underline cursor-pointer ml-2"
                  @click="onEnterEmailWithEmailPrefilledClick"
                >
                  {{ $t('homepage.loginBtn') }}
                </span>
              </template>
            </p>
          </form>
        </div>
      </template>

      <template v-else-if="currentStep === LoginFlowStep.ForgottenPasswordMessage">
        <div class="flexing flex-col w-full">
          <div class="w-full p-6 space-y-6">
            <h1
              class="font-medium text-36 relative"
              :class="isError ? 'text-texts-standard-danger' : 'text-texts-standard-important'"
            >
              <template v-if="isError">Error</template>
              <template v-else>
                {{ $t('homepage.emailSent') }}
                <app-icon
                  v-tippy="{
                    content: $replacePlaceholder(
                      $t('settings.verificationEmailNotReceived'),
                      '{email}',
                      supportMail,
                    ),
                    theme: 'WSM',
                    placement: 'left',
                  }"
                  icon-name="info-44"
                  class="absolute top-0 right-0"
                />
              </template>
            </h1>
            <p class="text-texts-standard-default text-28 font-medium py-8">
              <template v-if="isError">E-mail could not be sent. Please try again later.</template>
              <template v-else>
                {{ $t('homepage.forgotPasswordSuccess') }}
              </template>
            </p>
            <app-button
              v-if="isError"
              type="submit"
              btn-type="confirm"
              @click="currentStep = LoginFlowStep.ForgottenPassword"
            >
              {{ $t('homepage.back') }}
              <component-loading class="absolute inset-0" :is-loading="isLoading" />
            </app-button>
            <app-button
              v-else
              type="submit"
              btn-type="confirm"
              @click="onEnterEmailWithEmailPrefilledClick"
            >
              {{ $t('homepage.loginBtn') }}
              <component-loading class="absolute inset-0" :is-loading="isLoading" />
            </app-button>
          </div>
        </div>
      </template>

      <template v-else-if="currentStep === LoginFlowStep.EmailAlreadySentErrorMessage">
        <div class="flexing flex-col w-full">
          <div class="w-full p-6 space-y-6">
            <h1 class="text-texts-standard-danger font-medium text-36">
              {{ $t('homepage.emailAlreadySent') }}
            </h1>
            <p class="text-texts-standard-default text-28 font-medium py-8">
              {{
                $replacePlaceholder(
                  $t('homepage.password_reset_request_too_frequent'),
                  '%s',
                  CONNECT_ACCOUNT_TRESHOLD,
                )
              }}
            </p>
            <app-button
              type="submit"
              btn-type="confirm"
              @click="currentStep = LoginFlowStep.ForgottenPassword"
            >
              {{ $t('homepage.back') }}
              <component-loading class="absolute inset-0" :is-loading="isLoading" />
            </app-button>
          </div>
        </div>
      </template>

      <template v-else-if="currentStep === LoginFlowStep.EnterNewPassword">
        <div class="flexing flex-col w-full">
          <form class="w-full p-6 space-y-6" @submit.prevent>
            <h1 class="text-texts-standard-important font-medium text-36">
              {{ $t('passwordResetEmail.passwordResetSubject') }}
            </h1>
            <p class="text-texts-standard-default text-28 font-medium">
              {{ $t('settings.enterNewPassword') }}
            </p>
            <div>
              <div class="v-field--variant-outlined relative">
                <input
                  v-model.trim="form.password"
                  v-focus
                  :class="{ 'is-error': errors.password.size > 0 }"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :placeholder="$t('homepage.password')"
                  :disabled="isLoading"
                />
                <div class="v-field__outline" v-html="inputLabel('homepage.password')" />
                <div
                  class="input-icon cursor-pointer absolute top-1/2 transform -translate-y-1/2 right-16"
                  :class="
                    isPasswordVisible ? 'is-password-visible-icon' : 'is-password-hidden-icon'
                  "
                  @click="isPasswordVisible = !isPasswordVisible"
                />
                <tippy
                  class="absolute top-1/2 transform -translate-y-1/2 right-4"
                  theme="WSM"
                  placement="top"
                >
                  <div class="input-icon is-info-icon cursor-pointer" />
                  <template #content>
                    <p>{{ passwordTooltip }}</p>
                  </template>
                </tippy>
              </div>
              <div class="validation-text__wrapper">
                <div
                  class="validation-text"
                  :class="{ 'validation-text--error': errors.password.size > 0 }"
                >
                  <span v-for="(error, i) in errors.password" :key="i">
                    {{ error }}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div class="v-field--variant-outlined relative">
                <input
                  v-model.trim="form.passwordConfirmation"
                  :class="{ 'is-error': errors.passwordConfirmation.size > 0 }"
                  :type="isPasswordConfirmationVisible ? 'text' : 'password'"
                  :placeholder="$t('settings.repeatNewPassword')"
                  :disabled="isLoading"
                />
                <div class="v-field__outline" v-html="inputLabel('settings.repeatNewPassword')" />
                <div
                  class="input-icon cursor-pointer absolute top-1/2 transform -translate-y-1/2 right-4"
                  :class="
                    isPasswordVisible ? 'is-password-visible-icon' : 'is-password-hidden-icon'
                  "
                  @click="isPasswordConfirmationVisible = !isPasswordConfirmationVisible"
                />
              </div>
              <div class="validation-text__wrapper">
                <div
                  class="validation-text"
                  :class="{ 'validation-text--error': errors.passwordConfirmation.size > 0 }"
                >
                  <span v-for="(error, i) in errors.passwordConfirmation" :key="i">
                    {{ error }}
                  </span>
                </div>
              </div>
            </div>
            <app-button
              type="submit"
              btn-type="confirm"
              :disabled="
                isLoading ||
                form.password.length < PASSWORD_MIN_LENGTH ||
                form.password.length > PASSWORD_MAX_LENGTH ||
                form.passwordConfirmation.length < PASSWORD_MIN_LENGTH ||
                form.passwordConfirmation.length > PASSWORD_MAX_LENGTH ||
                errors.password.size > 0 ||
                errors.passwordConfirmation.size > 0
              "
              @click="onNewPasswordSubmit"
            >
              {{ $t('settings.confirm') }}
              <component-loading class="absolute inset-0" :is-loading="isLoading" />
            </app-button>
            <p class="text-texts-standard-additional text-28">
              {{ $t('homepage.rememberPassword') }}
              <span
                class="text-texts-standard-default font-bold uppercase underline cursor-pointer ml-2"
                @click="onEnterEmailWithEmailPrefilledClick"
              >
                {{ $t('homepage.loginBtn') }}
              </span>
            </p>
          </form>
        </div>
      </template>

      <template v-else-if="currentStep === LoginFlowStep.EnterNewPasswordMessage">
        <div class="flexing flex-col w-full">
          <div class="w-full p-6 space-y-6">
            <h1
              class="font-medium text-36"
              :class="isError ? 'text-texts-standard-danger' : 'text-texts-standard-important'"
            >
              <template v-if="isError">Error</template>
              <template v-else>{{ $t('settings.passwordChanged') }}</template>
            </h1>
            <p class="text-texts-standard-default text-28 font-medium py-8">
              <template v-if="isError">E-mail could not be sent. Please try again later.</template>
              <template v-else>
                {{ $t('homepage.passwordChangedInfo') }}
              </template>
            </p>
            <app-button
              v-if="isError"
              type="submit"
              btn-type="confirm"
              @click="currentStep = LoginFlowStep.EnterNewPassword"
            >
              {{ $t('homepage.back') }}
              <component-loading class="absolute inset-0" :is-loading="isLoading" />
            </app-button>
            <app-button
              v-else
              type="submit"
              btn-type="confirm"
              @click="onEnterEmailWithEmailPrefilledClick"
            >
              {{ $t('homepage.loginBtn') }}
              <component-loading class="absolute inset-0" :is-loading="isLoading" />
            </app-button>
          </div>
        </div>
      </template>

      <template v-else-if="currentStep === LoginFlowStep.EmailChange">
        <div class="flexing flex-col w-full">
          <form class="w-full p-6 space-y-6" @submit.prevent>
            <h1 class="text-texts-standard-important font-medium text-36">
              {{ $t('settings.changeEmail') }}
            </h1>
            <p class="text-texts-standard-default text-28 font-medium !mt-2">
              {{ $t('settings.enterEmailToVerify') }}
            </p>
            <div>
              <div class="v-field--variant-outlined relative">
                <input
                  v-model.trim="form.email"
                  v-focus
                  :class="{ 'is-error': errors.email.size > 0 }"
                  type="text"
                  name="email"
                  :placeholder="$t('settings.newEmail')"
                  :disabled="isLoading"
                />
                <div class="v-field__outline" v-html="inputLabel('settings.newEmail')" />
              </div>
              <div class="validation-text__wrapper">
                <div
                  class="validation-text"
                  :class="{ 'validation-text--error': errors.email.size > 0 }"
                >
                  <span v-for="(error, i) in errors.email" :key="i">
                    {{ error }}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div class="v-field--variant-outlined relative">
                <input
                  v-model.trim="form.password"
                  :class="{ 'is-error': errors.password.size > 0 }"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  name="password"
                  :placeholder="$t('settings.currentPassword')"
                  :disabled="isLoading"
                />
                <div class="v-field__outline" v-html="inputLabel('settings.currentPassword')" />
                <div
                  class="input-icon cursor-pointer absolute top-1/2 transform -translate-y-1/2 right-16"
                  :class="
                    isPasswordVisible ? 'is-password-visible-icon' : 'is-password-hidden-icon'
                  "
                  @click="isPasswordVisible = !isPasswordVisible"
                />
                <tippy
                  class="absolute top-1/2 transform -translate-y-1/2 right-4"
                  theme="WSM"
                  placement="top"
                >
                  <div class="input-icon is-info-icon cursor-pointer" />
                  <template #content>
                    <p>{{ passwordTooltip }}</p>
                  </template>
                </tippy>
              </div>
              <div class="validation-text__wrapper">
                <div
                  class="validation-text"
                  :class="{ 'validation-text--error': errors.password.size > 0 }"
                >
                  <span v-for="(error, i) in errors.password" :key="i">
                    {{ error }}
                  </span>
                </div>
              </div>
            </div>
            <p class="text-texts-standard-default text-28 !mt-2">
              <span
                class="uppercase underline cursor-pointer"
                @click="currentStep = LoginFlowStep.ForgottenPassword"
              >
                {{ $t('homepage.forgotPassword') }}
              </span>
            </p>
            <app-button
              type="submit"
              btn-type="confirm"
              :disabled="isLoading"
              @click="onEmailChangeSubmit"
            >
              {{ $t('settings.change') }}
              <component-loading class="absolute inset-0" :is-loading="isLoading" />
            </app-button>
          </form>
        </div>
      </template>
    </transition>
  </div>
</template>

<script lang="ts">
import ComponentLoading from '../GlobalComponents/ComponentLoading.vue'
import FacebookButton from './FacebookButton.vue'
import GoogleButton from './GoogleButton.vue'
import PpsAccountTooltip from './PpsAccountTooltip.vue'
import {
  LOGIN_VIEW_ROUTE_NAME,
  RESET_PASSWORD_ROUTE_NAME,
  checkEmailRegisteredEndpoint as CHECK_EMAIL_REGISTERED_ENPOINT,
  loginEndpoint as LOGIN_ENDPOINT,
  registerEndpoint as REGISTER_ENDPOINT,
  dispatchGameLogin as DISPATCH_GAME_LOGIN,
  passwordForgotEndpoint as PASSWORD_FORGOT_ENDPOINT,
  passwordResetEndpoint as PASSWORD_RESET_ENDPOINT,
  addLoginMethodEndpoint as ADD_LOGIN_METHOD_ENDPOINT,
  userProfileEndpoint as USER_PROFILE_ENDPOINT,
  supportMail,
  GameId,
} from '@/globalVariables'
import {
  gameToken as GAME_TOKEN,
  detectLanguage,
  gameToken,
  saveTokenToLocalStorage,
  sendToFlutter,
  validateEmail,
  getConnectedAccountStatusText,
} from '@/helpers'
import type CheckEmailRegisteredRequest from '@/interfaces/responses/login-flow/CheckEmailRegisteredRequest'
import type CheckEmailRegisteredResponse from '@/interfaces/responses/login-flow/CheckEmailRegisteredResponse'
import type {
  LoginApiResponse,
  LoginApiRequest,
  RegistrationApiResponse,
  RegistrationApiRequest,
  ForgotPasswordApiResponse,
  ForgotPasswordApiRequest,
  ResetPasswordApiRequest,
  ResetPasswordApiResponse,
} from '@/interfaces/responses/login/LoginApiResponse'
import { useCountriesStore } from '@/store/pinia/countriesStore'
import { useUserStore } from '@/store/pinia/userStore'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useAccountStore } from '@/store/pinia/accountStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { defineComponent, type PropType } from 'vue'
import { mapActions, mapState } from 'pinia'
import { ConnectedAccountStatus, ConnectedAccountType, LoginFormContext } from '@/enums/LoginForm'
import type { Nullable, TrueFalseInterface } from '@/interfaces/utils'
import type { UserProfile as UserProfileApiResponse } from '@/interfaces/User'

const AGE_REQUIRED = 16 // year
const CONNECT_ACCOUNT_TRESHOLD = 60 // minutes
const PASSWORD_MIN_LENGTH = 8
const PASSWORD_MAX_LENGTH = 20

enum LoginFlowStep {
  EnterEmail,
  EnterPasswordAndConnect,
  EnterPasswordAndSignIn,
  EnterPasswordAndSignUp,
  EmailVerification,
  ForgottenPassword,
  ForgottenPasswordMessage,
  EmailAlreadySentErrorMessage,
  EnterNewPassword,
  EnterNewPasswordMessage,
  EmailChange,
}

interface Form {
  email: string
  password: string
  passwordConfirmation: string
}

interface FormErrors {
  email: Set<string>
  password: Set<string>
  passwordConfirmation: Set<string>
}

interface ComponentData {
  LOGIN_VIEW_ROUTE_NAME: typeof LOGIN_VIEW_ROUTE_NAME
  CONNECT_ACCOUNT_TRESHOLD: typeof CONNECT_ACCOUNT_TRESHOLD
  PASSWORD_MIN_LENGTH: typeof PASSWORD_MIN_LENGTH
  PASSWORD_MAX_LENGTH: typeof PASSWORD_MAX_LENGTH
  supportMail: typeof supportMail
  LoginFormContext: typeof LoginFormContext
  ConnectedAccountStatus: typeof ConnectedAccountStatus
  GameId: typeof GameId
  LoginFlowStep: typeof LoginFlowStep
  currentStep: Nullable<LoginFlowStep>
  prevHeight: `${number}px` | 'auto'
  transitionName: 'slide-next' | 'slide-back' | ''
  form: Form
  errors: FormErrors
  games: Set<GameId>
  isPasswordVisible: boolean
  isPasswordConfirmationVisible: boolean
  isLoading: boolean
  isError: boolean
}

export default defineComponent({
  components: {
    ComponentLoading,
    FacebookButton,
    GoogleButton,
    PpsAccountTooltip,
  },
  props: {
    context: {
      type: String as PropType<LoginFormContext>,
      default: '',
      validator: (value: LoginFormContext) => Object.values(LoginFormContext).includes(value),
    },
  },
  emits: ['reload-user-data'],
  data(): ComponentData {
    return {
      LOGIN_VIEW_ROUTE_NAME,
      CONNECT_ACCOUNT_TRESHOLD,
      PASSWORD_MIN_LENGTH,
      PASSWORD_MAX_LENGTH,
      supportMail,
      GameId,
      LoginFormContext,
      ConnectedAccountStatus,
      LoginFlowStep,
      currentStep: null,
      prevHeight: 'auto',
      transitionName: 'slide-next',
      form: {
        email: (this.$route.query.email as string) || '',
        password: '',
        passwordConfirmation: '',
      },
      errors: {
        email: new Set<string>(),
        password: new Set<string>(),
        passwordConfirmation: new Set<string>(),
      },
      games: new Set<GameId>(),
      isPasswordVisible: false,
      isPasswordConfirmationVisible: false,
      isLoading: false,
      isError: false,
    }
  },
  computed: {
    ...mapState(useCoreStore, [
      'hasBadCredentials',
      'emailAlreadySent',
      'emailRegisteredElsewhere',
    ]),
    ...mapState(useUserStore, {
      user: 'getUserData',
      userLanguage: 'getLanguage',
    }),
    ...mapState(useAccountStore, ['connectedAccounts']),
    ...mapState(useResponseTaskStore, {
      validationErrors: 'getValidationErrors',
    }),
    isRegistration(): boolean {
      return this.games.size === 0
    },
    isPasswordReset(): boolean {
      return (
        this.context === LoginFormContext.PASSWORD_RESET ||
        this.$route.name === RESET_PASSWORD_ROUTE_NAME
      )
    },
    isEmailChange(): boolean {
      return this.context === LoginFormContext.EMAIL_CHANGE
    },
    isEmailNotConnected(): boolean {
      return (
        this.context === LoginFormContext.CONNECTION &&
        this.connectedAccounts?.email === ConnectedAccountStatus.NotConnected
      )
    },
    isEmailVerified(): boolean {
      return (
        this.context === LoginFormContext.CONNECTION &&
        this.connectedAccounts?.email === ConnectedAccountStatus.Connected
      )
    },
    isEmailNotVerified(): boolean {
      return (
        this.context === LoginFormContext.CONNECTION &&
        this.connectedAccounts?.email === ConnectedAccountStatus.VerificationPending
      )
    },
    accountActionButtonLabel(): string {
      if (this.currentStep === LoginFlowStep.EnterPasswordAndConnect) {
        return this.$t('homepage.continue')
      }
      if (this.currentStep === LoginFlowStep.EnterPasswordAndSignIn) {
        return this.$t('homepage.loginBtn')
      }
      return this.$t('homepage.registerBtn')
    },
    languageCode(): string {
      if (this.userLanguage)
        return this.userLanguage === 'sk' || this.userLanguage === 'cs' ? 'sk' : 'en'

      return localStorage.getItem('language') === 'sk' || localStorage.getItem('language') === 'cs'
        ? 'sk'
        : 'en'
    },
    registrationText(): string {
      let text = this.$t('homepage.registrationText')

      text = this.$replacePlaceholder(text, /%s/gi, AGE_REQUIRED.toString())

      text = this.$replacePlaceholder(
        text,
        /{link1}/gi,
        `<a target="_blank" class="clickable-element text-texts-standard-important underline" href="https://www.powerplay.studio/${this.languageCode}/license/">`,
      )

      text = this.$replacePlaceholder(
        text,
        /{link2}/gi,
        `<a target="_blank" class="clickable-element text-texts-standard-important underline" href="https://www.powerplay.studio/${this.languageCode}/privacy-policy/">`,
      )

      text = this.$replacePlaceholder(
        text,
        /{link3}/gi,
        '<a class="clickable-element text-texts-standard-important underline" href="?rules=true">',
      )

      text = this.$replacePlaceholder(text, /{\/link}/gi, '</a>')

      return text
    },
    passwordTooltip(): string {
      return this.$replacePlaceholder(
        this.$replacePlaceholder(
          this.$t('homepage.errorPasswordInvalidFormat'),
          /%s/,
          this.PASSWORD_MIN_LENGTH.toString(),
        ),
        /%s/,
        this.PASSWORD_MAX_LENGTH.toString(),
      )
    },
    messages(): Record<number, string> {
      return {
        [LoginFlowStep.EnterPasswordAndSignIn]: 'homepage.loginWithPPSAccount',
        [LoginFlowStep.EnterPasswordAndSignUp]: 'homepage.createNewPPSAccount',
        [LoginFlowStep.EnterPasswordAndConnect]: 'settings.linkPPSAccountWithEmail',
      }
    },
    allSocialConnected(): boolean {
      return (
        this.connectedAccounts?.facebook !== ConnectedAccountStatus.NotConnected &&
        this.connectedAccounts?.google !== ConnectedAccountStatus.NotConnected
      )
    },
    hasErrors(): boolean {
      return (
        this.errors.email.size > 0 ||
        this.errors.password.size > 0 ||
        this.errors.passwordConfirmation.size > 0
      )
    },
  },
  watch: {
    hasBadCredentials(value: boolean): void {
      if (!value) return
      this.errors.password.add(this.$t('error.badCredentials'))
      this.removeAxiosErrors()
    },
    emailRegisteredElsewhere(value: boolean): void {
      if (!value) return
      this.errors.email.add(this.$t('homepage.errorEmailRegisteredElsewhere'))
      this.removeAxiosErrors()
    },
    emailAlreadySent(value: boolean): void {
      if (!value) return
      this.currentStep = LoginFlowStep.EmailAlreadySentErrorMessage
      this.removeAxiosErrors()
    },
    validationErrors(value: Nullable<string[]>): void {
      if (!value || !value.length) return
      if (!value.includes('emailAlreadyRegistered')) return
      this.errors.email.add(this.$t('settings.emailAlreadyRegistered'))
      this.removeValidationErrors()
    },
    currentStep(to: LoginFlowStep, from: LoginFlowStep | null): void {
      if (from === LoginFlowStep.EnterEmail) this.transitionName = 'slide-next'
      if (to === LoginFlowStep.EnterEmail) this.transitionName = 'slide-back'

      if (from === LoginFlowStep.EnterPasswordAndSignIn && to === LoginFlowStep.ForgottenPassword)
        this.transitionName = 'slide-next'
      if (from === LoginFlowStep.ForgottenPassword && to === LoginFlowStep.EnterPasswordAndSignIn)
        this.transitionName = 'slide-back'

      if (from === LoginFlowStep.EnterNewPassword && to === LoginFlowStep.EnterNewPassword)
        this.transitionName = 'slide-next'
      if (from === LoginFlowStep.EnterNewPassword && to === LoginFlowStep.EnterPasswordAndSignIn)
        this.transitionName = 'slide-back'

      if (
        from === LoginFlowStep.EnterNewPasswordMessage &&
        to === LoginFlowStep.EnterPasswordAndSignIn
      )
        this.transitionName = 'slide-back'

      if (from === LoginFlowStep.EmailChange && to === LoginFlowStep.EmailVerification)
        this.transitionName =
          this.context === LoginFormContext.CONNECTION ? 'slide-back' : 'slide-next'
      if (from === LoginFlowStep.EmailVerification && to === LoginFlowStep.EmailChange)
        this.transitionName =
          this.context === LoginFormContext.CONNECTION ? 'slide-next' : 'slide-back'

      if (from === LoginFlowStep.EmailChange && to === LoginFlowStep.ForgottenPassword)
        this.transitionName = 'slide-next'
      if (from === LoginFlowStep.ForgottenPassword && to === LoginFlowStep.EmailChange)
        this.transitionName = 'slide-back'

      if (from === null) {
        this.transitionName = ''
      }

      // console.debug(
      //   `${LoginFlowStep[from]} -> ${LoginFlowStep[to]}: ${this.transitionName || 'none'}`,
      // )

      if (this.currentStep === LoginFlowStep.EmailChange) {
        this.form.email = ''
      }

      this.form.password = ''
      this.form.passwordConfirmation = ''

      this.errors.email.clear()
      this.errors.password.clear()
    },
    'form.email'(): void {
      this.errors.email.clear()
      this.removeAxiosErrors()

      if (this.validationErrors?.length) {
        this.removeValidationErrors()
      }
    },
    'form.password'(): void {
      this.errors.password.clear()
      this.removeAxiosErrors()

      if (this.validationErrors?.length) {
        this.removeValidationErrors()
      }
    },
    'form.passwordConfirmation'(): void {
      this.errors.passwordConfirmation.clear()
      this.removeAxiosErrors()

      if (this.validationErrors?.length) {
        this.removeValidationErrors()
      }
    },
    '$route.query.email': {
      handler(): void {
        if (
          !this.$route.query.email ||
          this.$route.name !== LOGIN_VIEW_ROUTE_NAME ||
          this.currentStep !== LoginFlowStep.EnterEmail
        )
          return

        this.onContinueClick()
      },
      immediate: true,
    },
    'connectedAccounts.email': {
      handler(): void {
        if (this.context !== LoginFormContext.CONNECTION) return
        this.initCurrentStep()
      },
      deep: true,
    },
  },
  async created(): Promise<void> {
    await this.loadLanguages(false)

    if (this.context === LoginFormContext.CONNECTION) {
      await this.loadConnectedAccounts()
    }

    this.initCurrentStep()

    sendToFlutter('{"event":"logout"}')
  },
  methods: {
    ...mapActions(useCountriesStore, ['loadLanguages']),
    ...mapActions(useCoreStore, ['removeAxiosErrors', 'setLoadingScreen']),
    ...mapActions(useAccountStore, ['loadConnectedAccounts']),
    ...mapActions(useResponseTaskStore, ['removeValidationErrors']),
    getConnectedAccountStatusText,
    initCurrentStep(): void {
      if (this.isEmailVerified) {
        this.currentStep = LoginFlowStep.EnterEmail
        this.form.email = this.user.email
        return
      }

      if (this.isEmailNotVerified) {
        this.currentStep = LoginFlowStep.EmailVerification
        this.form.email = this.user.email
        return
      }

      if (this.isPasswordReset) {
        this.currentStep = LoginFlowStep.EnterNewPassword
        return
      }

      if (this.isEmailChange) {
        this.currentStep = LoginFlowStep.EmailChange
        return
      }

      this.currentStep = LoginFlowStep.EnterEmail
    },
    validate(): boolean {
      // validate e-mail field
      if (
        [
          LoginFlowStep.EnterEmail,
          LoginFlowStep.EnterPasswordAndSignIn,
          LoginFlowStep.EnterPasswordAndSignUp,
          LoginFlowStep.ForgottenPassword,
          LoginFlowStep.EnterPasswordAndConnect,
          LoginFlowStep.EmailChange,
        ].includes(this.currentStep)
      ) {
        this.errors.email.clear()

        if (!this.form.email) this.errors.email.add(this.$t('homepage.errorEmailInvalidFormat'))
        else if (!validateEmail(this.form.email))
          this.errors.email.add(this.$t('homepage.errorEmailInvalidFormat'))
      }

      // validate password field
      if (
        [
          LoginFlowStep.EnterPasswordAndSignIn,
          LoginFlowStep.EnterPasswordAndSignUp,
          LoginFlowStep.EnterNewPassword,
          LoginFlowStep.EnterPasswordAndConnect,
          LoginFlowStep.EmailChange,
        ].includes(this.currentStep)
      ) {
        this.errors.password.clear()

        if (!this.form.password)
          this.errors.password.add(this.$t('homepage.errorPasswordInvalidFormat'))
        else if (this.form.password.length < PASSWORD_MIN_LENGTH)
          this.errors.password.add(this.$t('homepage.errorShortPassword'))
        else if (this.form.password.length > PASSWORD_MAX_LENGTH)
          this.errors.password.add(this.$t('homepage.errorLongPassword'))
        // else if (validatePassword(this.form.password))
        //   this.errors.password.add(this.$t('homepage.errorPasswordInvalidFormat'))
      }

      // validate password confirmation field
      if (this.currentStep === LoginFlowStep.EnterNewPassword) {
        this.errors.passwordConfirmation.clear()

        if (!this.form.passwordConfirmation)
          this.errors.passwordConfirmation.add(this.$t('homepage.errorPasswordInvalidFormat'))
        else if (this.form.passwordConfirmation.length < PASSWORD_MIN_LENGTH)
          this.errors.passwordConfirmation.add(this.$t('homepage.errorShortPassword'))
        else if (this.form.passwordConfirmation.length > PASSWORD_MAX_LENGTH)
          this.errors.passwordConfirmation.add(this.$t('homepage.errorLongPassword'))
        // else if (validatePassword(this.form.passwordConfirmation))
        //   this.errors.passwordConfirmation.add(this.$t('homepage.errorPasswordInvalidFormat'))

        if (this.form.passwordConfirmation !== this.form.password)
          this.errors.passwordConfirmation.add(this.$t('settings.newPasswordsDoesNotMatch'))
      }

      return (
        this.errors.email.size === 0 &&
        this.errors.password.size === 0 &&
        this.errors.passwordConfirmation.size === 0
      )
    },
    async onContinueClick(): Promise<void> {
      if (!this.validate()) return

      try {
        this.isLoading = true

        const response = await this.$axios.post<
          {},
          CheckEmailRegisteredResponse,
          CheckEmailRegisteredRequest
        >(CHECK_EMAIL_REGISTERED_ENPOINT, {
          email: this.form.email,
        })
        if (!response.games) return

        this.games = new Set(response.games)

        if (this.context === LoginFormContext.CONNECTION) {
          this.currentStep = LoginFlowStep.EnterPasswordAndConnect
        } else {
          this.currentStep = this.isRegistration
            ? LoginFlowStep.EnterPasswordAndSignUp
            : LoginFlowStep.EnterPasswordAndSignIn
        }
      } catch (error: unknown) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    onEnterEmailWithEmailPrefilledClick(): void {
      this.currentStep = LoginFlowStep.EnterEmail
      this.$router.push({
        name: LOGIN_VIEW_ROUTE_NAME,
        query: { email: this.form.email || undefined },
      })
    },
    async onPlayNowClick(): Promise<void> {
      return this.isRegistration ? this.onSignUpSubmit() : this.onSignInSubmit()
    },
    async onConnectClick(): Promise<void> {
      if (!this.validate()) return

      try {
        this.isLoading = true

        await this.$axios.post<
          {},
          TrueFalseInterface,
          {
            email: string
            password: string
            loginType: string
            game_token: string
            uuid: string
            platform: string
          }
        >(ADD_LOGIN_METHOD_ENDPOINT, {
          email: this.form.email,
          password: this.form.password,
          loginType: ConnectedAccountType.Email,
          game_token: gameToken,
          uuid: this.user.id,
          platform: 'web',
        })

        await this.loadConnectedAccounts(true)

        this.currentStep = LoginFlowStep.EmailVerification
      } catch (error: unknown) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    async onSignInSubmit(): Promise<void> {
      if (!this.validate()) return

      const lastIdGroup = JSON.parse(localStorage.getItem('last_id_group'))?.value ?? null

      try {
        this.isLoading = true

        const response = await this.$axios.post<{}, LoginApiResponse, LoginApiRequest>(
          LOGIN_ENDPOINT,
          {
            email: this.form.email,
            password: this.form.password,
            game_token: GAME_TOKEN,
            group_id: lastIdGroup,
          },
        )
        if (!response.access_token) return

        saveTokenToLocalStorage(response.access_token)
        this.setLoadingScreen(true)

        await this.$axios.post<{}, true>(DISPATCH_GAME_LOGIN)

        this.$router.push('/')
      } catch (error: unknown) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    async onSignUpSubmit(): Promise<void> {
      if (!this.validate()) return

      const lastIdGroup = JSON.parse(localStorage.getItem('last_id_group'))?.value ?? null

      try {
        this.isLoading = true

        const data = {
          email: this.form.email,
          password: this.form.password,
          game_token: GAME_TOKEN,
          language: detectLanguage(),
          last_id_group: lastIdGroup,
          id_group: null,
        }

        const idGroup = JSON.parse(localStorage.getItem('id_group'))
        if (idGroup) data.id_group = parseInt(idGroup.value)
        localStorage.clear()

        const response = await this.$axios.post<
          {},
          RegistrationApiResponse,
          RegistrationApiRequest
        >(REGISTER_ENDPOINT, data)
        if (!response.access_token) return

        saveTokenToLocalStorage(response.access_token)

        this.$router.push({
          name: this.$getWebView('ChooseDisciplineTutorial'),
        })
      } catch (error: unknown) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    async onForgottenPasswordSubmit(): Promise<void> {
      if (!this.validate()) return

      try {
        this.isError = false
        this.isLoading = true

        await this.$axios.post<{}, ForgotPasswordApiResponse, ForgotPasswordApiRequest>(
          PASSWORD_FORGOT_ENDPOINT,
          {
            email: this.form.email,
          },
        )

        this.currentStep = LoginFlowStep.ForgottenPasswordMessage
      } catch (error: unknown) {
        console.error(error)
        this.isError = true
      } finally {
        this.isLoading = false
      }
    },
    async onNewPasswordSubmit(): Promise<void> {
      if (!this.validate()) return

      try {
        this.isError = false
        this.isLoading = true

        await this.$axios.post<{}, ResetPasswordApiResponse, ResetPasswordApiRequest>(
          PASSWORD_RESET_ENDPOINT,
          {
            token: this.$route.query.token as string,
            email: this.form.email,
            platform: 'web',
            password: this.form.password,
            password_confirmation: this.form.passwordConfirmation,
          },
        )

        this.currentStep = LoginFlowStep.EnterNewPasswordMessage
      } catch (error: unknown) {
        console.error(error)
        this.isError = true
      } finally {
        this.isLoading = false
      }
    },
    async onEmailChangeSubmit(): Promise<void> {
      if (!this.validate()) return

      try {
        this.isError = false
        this.isLoading = true

        const response = await this.$axios.post<
          {},
          UserProfileApiResponse,
          { email: string; password: string }
        >(USER_PROFILE_ENDPOINT, {
          email: this.form.email,
          password: this.form.password,
        })

        // Toto by bolo vhodne popridavat do vsetkych akcii, ale to si zase vyziada testing celeho komponentu.
        if (this.hasErrors || !response.user_data.email) return

        this.form.email = response.user_data.email

        this.$emit('reload-user-data')

        // Nepotrebujeme await, moze sa dotiahnut na pozadi.
        this.loadConnectedAccounts(true)

        this.currentStep = LoginFlowStep.EmailVerification
      } catch (error: unknown) {
        console.error(error)
        this.isError = true
      } finally {
        this.isLoading = false
      }
    },
    onBeforeLeave(element: HTMLElement): void {
      this.prevHeight = getComputedStyle(element).height as `${number}px`
    },
    onEnter(element: HTMLElement): void {
      const { height } = getComputedStyle(element)
      element.style.height = this.prevHeight
      requestAnimationFrame((): void => {
        element.style.height = height
      })
    },
    onAfterEnter(element: HTMLElement): void {
      element.style.height = 'auto'
    },
    async onGoogleConnection(): Promise<void> {
      await this.loadConnectedAccounts(true)
    },
    async onFacebookConnection(): Promise<void> {
      await this.loadConnectedAccounts(true)
    },
    inputLabel(translatedLabel: string): string {
      return `
        <div class="v-field__outline__start"></div>
        <div class="v-field__outline__notch">
          <label class="v-label v-field-label" aria-hidden="true">
            ${this.$t(translatedLabel)}
          </label>
        </div>
        <div class="v-field__outline__end"></div>
      `
    },
  },
})
</script>

<style lang="scss" scoped>
:deep() {
  input {
    position: relative;
    width: 100%;
    height: 5.25rem;
    font-size: 2rem;
    padding-left: 1.8125rem;
    padding-right: 1.8125rem;
    color: #fff;
    outline: none;
    border: none;

    &::placeholder {
      color: #fff;
    }

    @if $isWsm {
      font-style: italic;
      --input-background-color: #0a1b2f;
      background-color: var(--input-background-color);
    }

    @if $isSsm {
      --input-background-color: #1d1f2c;
      background-color: var(--input-background-color);
    }
  }

  // when input is focused, hide the placeholder text
  input:focus::placeholder {
    opacity: 0;
  }

  // when the input is autofilled
  input:-webkit-autofill {
    -webkit-text-fill-color: #fff; // autofilled text color
    caret-color: #fff; // cursor color
    -webkit-box-shadow: inset 0 0 0px 9999px var(--input-background-color); // autofilled background color
  }

  // when the input has text, show the label
  // also show the label when the input is focused
  input:not(:placeholder-shown),
  input:focus {
    & ~ .v-field__outline .v-label {
      opacity: 1;
      margin: 0 1rem;
      width: auto;
    }
  }

  input:not(:placeholder-shown) {
    padding-top: 0.75rem;

    &[disabled] ~ .v-field__outline .v-label {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  input.is-error {
    & ~ .v-field__outline {
      & .v-field__outline__start,
      & .v-field__outline__notch,
      & .v-field__outline__notch::before,
      & .v-field__outline__notch::after,
      & .v-field__outline__end {
        --v-field-border-width: 2px;

        @if $isWsm {
          border-color: #e81414 !important;
        }
        @if $isSsm {
          border-color: #ff6344 !important;
        }
      }
    }
  }

  .v-field--variant-outlined {
    .v-field__outline {
      --v-field-border-width: 1px;
      --v-field-border-opacity: 0.38;
      position: absolute;
      contain: layout;
      display: flex;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;

      &__start,
      &__notch,
      &__notch::before,
      &__notch::after,
      &__end {
        transition: border-color 0.125s linear;

        @if $isWsm {
          border-color: #5ba3dc;
        }

        @if $isSsm {
          border-color: #4c648f;
        }
      }

      &__start {
        flex: 0 0 0.8rem;
        border-top-width: var(--v-field-border-width);
        border-bottom-width: var(--v-field-border-width);
        border-inline-start-width: var(--v-field-border-width);
        border-start-start-radius: inherit;
        border-start-end-radius: 0;
        border-end-end-radius: 0;
        border-end-start-radius: inherit;
      }

      &__notch {
        flex: none;
        position: relative;
        max-width: calc(100% - 12px);

        &::before {
          opacity: 0 !important;
          border-width: var(--v-field-border-width) 0 0;
        }

        &::after {
          bottom: 0;
          opacity: 1 !important;
          border-width: 0 0 var(--v-field-border-width);
        }

        &::before,
        &::after {
          opacity: var(--v-field-border-opacity);
          transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      &__end {
        flex: 1;
        border-top-width: var(--v-field-border-width);
        border-bottom-width: var(--v-field-border-width);
        border-inline-end-width: var(--v-field-border-width);
        border-start-start-radius: 0;
        border-start-end-radius: inherit;
        border-end-end-radius: inherit;
        border-end-start-radius: 0;
      }
    }

    @if $isSsm {
      input {
        &:focus,
        &:active {
          & + .v-field__outline .v-field__outline {
            &__start,
            &__notch,
            &__notch::before,
            &__notch::after,
            &__end {
              border-color: #e5e7eb;
            }
          }
        }
      }
    }

    .v-label.v-field-label {
      position: static;
      transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
      font-size: 1.375rem;
      max-width: 100%;
      align-items: center;
      display: inline-flex;
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // init animation state
      transform: translateY(-50%);
      transform-origin: center center;
      opacity: 0;
      margin: 0;
      width: 0;
      z-index: 1;

      @if $isWsm {
        color: #326194;
        font-style: italic;
      }

      @if $isSsm {
        color: theme('colors.texts.standard.additional');
      }
    }
  }
}

.login-form-with-flow {
  width: 40rem;

  &--login,
  &--password-reset {
    background-color: #1a253f;
    border: 0.125rem solid #4c648f;
  }

  &--connection,
  &--email-change {
    width: 100%;
    flex-grow: 1;

    .v-field--variant-outlined,
    .validation-text__wrapper,
    .facebook-login-button,
    .google-login-button,
    .email-connect {
      max-width: 40rem;
      margin: 0 auto;
    }
  }

  &--connection {
    .btn {
      max-width: 40rem;
      margin: 0 auto;
    }
  }

  &--email-change {
    p {
      max-width: 40rem;
      margin: 0 auto;
    }
  }

  input,
  button {
    transition:
      opacity 0.25s ease,
      border-color 0.25s ease;
  }

  label {
    position: absolute;
    top: 0;
    left: 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5.25rem;
    font-size: 2.5rem;
    pointer-events: none;
    transition: 0.2s ease all;

    @if $isWsm {
      font-style: italic;
    }
  }

  button {
    width: 100% !important;
  }

  .validation-text {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    align-items: flex-start;
    font-size: 1.25rem;
    min-height: 1.375rem;
    min-width: 0.063rem;
    opacity: 0;

    span {
      line-height: 1.375rem;
    }

    &--error {
      color: theme('colors.texts.standard.danger');
      opacity: 1;
    }

    &__wrapper {
      display: flex;
      min-height: 1.375rem;
      padding-top: 0.375rem;
      padding-inline: 1rem;
      overflow: hidden;
      transition: all 0.25s ease-in-out;
    }
  }

  .use-text {
    position: relative;
    margin: 1rem 0;

    &__divider {
      content: '';
      position: relative;
      width: 13.75rem;
      height: 0.125rem;
    }

    &__label {
      padding: 0 1rem;
    }

    &__divider--right {
      background-image: linear-gradient(to right, #fff, transparent);
    }

    &__divider--left {
      background-image: linear-gradient(to left, #fff, transparent);
    }
  }

  .continue-policy__wrapper {
    width: 100%;
    padding: 1rem;
    margin-top: 0.5rem;

    display: flex;
    font-size: 1.375rem;
    justify-content: center;
    align-items: center;
    color: white;

    &--login,
    &--password-reset {
      min-height: 9rem;
      border-top: 0.125rem solid #4c648f;
      box-shadow: inset 0 0 1.5rem #000000;
    }
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.input-icon {
  $icon-size: 2.5rem;
  $icon-names: ('edit', 'info', 'password-hidden', 'password-visible');

  @include background(
    url('#{$path-icons}/account/input-icons-40.avif'),
    $icon-size(length($icon-names) * $icon-size)
  );
  @include square($icon-size);

  @each $icon-name in $icon-names {
    &.is-#{$icon-name}-icon {
      background-position: 0 (index($icon-names, $icon-name) - 1) * -$icon-size;
    }
  }
}

.email-connect {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 4.25rem;
  color: #fff;
  background-color: transparent;
  background-image: linear-gradient(to top, rgba(59, 230, 201, 0.25) 0%, transparent);
  border: solid 0.125rem #3be6c9;
  pointer-events: none;
  cursor: default;

  &-icon {
    width: 1.5em;
    height: 1.5em;
  }
}

// Ked zacneme pouzivat mode: 'jit', tak toto moze ist doprec.
// https://v2.tailwindcss.com/docs/just-in-time-mode
.\!mt-2 {
  margin-top: 0.25rem !important;
}
</style>
