<template>
  <info-message :info-message-data="popupObject" @info-btn-click="hideHiredEmployee">
    <div class="flexing">
      <section class="flexing mb-7">
        <div class="clubs-employees-box-wrapper relative">
          <div class="clubs-employees-box-decoration" />
          <clubs-employees-box-center :employee-data="getSignedContract" />
        </div>
        <div class="clubs-employees-periodic-wrapper">
          <p
            class="clubs-employees-box-footer-reward-text uppercase text-32 text-texts-standard-additional"
          >
            {{ $t('club.regularReward') }}
          </p>
          <div class="clubs-employees-periodic-box">
            <clubs-periodic-payment-box
              :reward-value="getSignedContract.rewardValue"
              :reward-type="getSignedContract.rewardType"
              :reward-interval="getSignedContract.rewardTime"
              :reward-size="30"
              :reward-icon-size="48"
            />
          </div>
        </div>
      </section>
    </div>
    <template #footer>
      <div class="clubs-signed-footer text-34 text-texts-standard-default w-full flexing uppercase">
        <p>{{ $t('club.valid') }}</p>
        <p class="text-texts-standard-important ml-4">{{ getSignedContract.validForDays }}D</p>
        <app-icon icon-name="time" />
      </div>
    </template>
  </info-message>
</template>

<script lang="ts">
import ClubsEmployeesBoxCenter from '@/components/Club/ClubsEmployeesBoxCenter.vue'
import ClubsPeriodicPaymentBox from '@/components/Club/ClubsPeriodicPaymentBox.vue'
import InfoMessage, { type InfoMessageData } from '@/components/InfoMessage.vue'
import { useEmployeeState } from '@/store/pinia/clubs/employeeStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

type HeadStyle = 'additional' | 'default' | 'important' | 'name'
interface ComponentData {
  rewardValue: number
  rewardType: string
  rewardTime: number
  employee: {
    boxColor: string
    seconds: number
    noCountdown: boolean
    employeeName: string
    employeeId: number
    warning: boolean
  }
}
export default defineComponent({
  name: 'ClubsSignedContract',
  components: {
    InfoMessage,
    ClubsPeriodicPaymentBox,
    ClubsEmployeesBoxCenter,
  },
  data(): ComponentData {
    return {
      rewardValue: 1000,
      rewardType: 'money',
      rewardTime: 100,
      employee: {
        boxColor: 'gold',
        seconds: 0,
        noCountdown: false,
        employeeName: 'Jozef',
        employeeId: 1,
        warning: true,
      },
    }
  },
  computed: {
    ...mapState(useEmployeeState, {
      getSignedContract: 'getSignedContract',
    }),
    // TODO doriesit default
    popupObject(): InfoMessageData {
      const popupData = {
        headerText: this.$t('common.congratulations'),
        headStyle: 'important' as HeadStyle,
        infoText: this.$t('club.contractSigned'),
        hasDecoration: true,
        buttonText: this.$t('button.continue'),
      }
      return popupData
    },
  },
  methods: {
    ...mapActions(useEmployeeState, {
      hideHiredEmployee: 'hideHiredEmployee',
    }),
  },
})
</script>
<style lang="scss" scoped>
@import '@/assets/styles/components/icons/stars-icons.scss';

.clubs-employees-periodic-box {
  width: 100%;
  height: 4.063rem;
}

.clubs-signed {
  &-footer {
    height: 5rem;
    @if $isWsm {
      background: linear-gradient(
        to right,
        transparent,
        #154e79 10%,
        #154e79 40%,
        #154e79 50%,
        #154e79 60%,
        #154e79 90%,
        transparent
      );
    }
    @if $isSsm {
      background: linear-gradient(
        to right,
        transparent,
        #605e8f 10%,
        #605e8f 40%,
        #605e8f 50%,
        #605e8f 60%,
        #605e8f 90%,
        transparent
      );
    }

    .icon-time {
      width: 2rem;
      height: 2rem;
      margin-left: 0.25rem;
    }
  }
}

.clubs-employees-box {
  &-wrapper {
    width: 27.375rem;
  }

  &-decoration {
    background: url($path-clubs-employees + 'contract-decoration.avif') 0 0 no-repeat;
    width: 100%;
    height: 17.75rem;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
