<template>
  <app-icon v-if="hideTooltip" class="ml-1 info-icon icon-in-table" icon-name="info-rounded" />
  <tippy v-else theme="WSM" placement="left" max-width="35rem">
    <app-icon class="ml-1 info-icon icon-in-table" icon-name="info-rounded" />
    <template #content>
      <div class="tooltip-content text-texts-standard-default text-30 pb-3 mb-3">
        <p class="text-40 font-bold text-texts-standard-important">
          {{ $replacePlaceholder($te('round'), '%s', round.toString()) }}
        </p>
        <hr class="row-fixer" />
        <div class="text-texts-standard-default data-row">
          <p class="font-styler text-texts-standard-additional">{{ $te('score') }}</p>
          <p class="flexing">
            {{ ('points' in informationData && informationData?.points) || '-' }}
            <app-main-icon :icon-size="48" :icon-name="iconName" class="ml-2 mb-2" />
          </p>
        </div>
        <hr class="row-fixer" />
        <div class="text-texts-standard-default data-row">
          <p class="font-styler text-texts-standard-additional">{{ $te('reward') }}</p>
          <p class="flexing">
            {{ ('medals' in informationData && informationData?.medals) || '-' }}
            <app-main-icon :icon-size="48" icon-name="medals" class="ml-2 mb-2" />
          </p>
        </div>
      </div>
    </template>
  </tippy>
</template>

<script lang="ts">
import type { InformationData } from '@/interfaces/events/QualificationTable'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'

export default defineComponent({
  props: {
    informationData: {
      type: Object as PropType<Nullable<InformationData>>,
      default: () => null,
    },
    round: {
      type: Number,
      required: true,
    },
    iconName: {
      type: String,
      default: '',
    },
    hideTooltip: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.tooltip-content {
  width: 18rem;
}

.data-row {
  height: 4.5rem;
  width: 100%;
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row-fixer {
  border: 1px solid rgb(34, 61, 91);
  width: 100%;
  margin: 1rem 0;
}

.font-styler {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: rgb(179, 179, 179);
}
</style>
