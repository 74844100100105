<template>
  <div
    class="form-row flex items-center text-texts-standard-default text-28 bordered-1"
    :class="{
      'flex-col justify-center': flexDirection === ClubInfoFormFlex.Col,
      'flex-row justify-between': flexDirection === ClubInfoFormFlex.Row,
    }"
  >
    <div class="flex flex-col items-start justify-start">
      <p
        class="text-texts-standard-name text-36 uppercase"
        :class="{ 'form-row-label': flexDirection === 'col' }"
      >
        {{ label }}
      </p>
      <p v-if="subLabel" class="text-texts-standard-default text-30">
        {{ subLabel }}
      </p>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { ClubInfoFormFlex } from '@/interfaces/clubs/Clubs'

interface ComponentData {
  ClubInfoFormFlex: typeof ClubInfoFormFlex
}

export default defineComponent({
  name: 'ClubsInfoFormRow',
  props: {
    flexDirection: {
      type: String as PropType<ClubInfoFormFlex>,
      default: ClubInfoFormFlex.Col,
      validator(value: ClubInfoFormFlex): boolean {
        return [ClubInfoFormFlex.Col, ClubInfoFormFlex.Row].includes(value)
      },
    },
    label: {
      type: String,
      default: '',
    },
    subLabel: {
      type: String,
      default: '',
    },
  },
  data(): ComponentData {
    return {
      ClubInfoFormFlex,
    }
  },
})
</script>

<style lang="scss" scoped>
.form-row {
  width: 100%;
  min-height: 7.5rem;
  padding: 2rem;
  margin-bottom: 1.5rem;
  @if $isWsm {
    background: rgba(35, 109, 155, 0.7);
    border: 0.063rem solid #6c9ec4;
  }
  @if $isSsm {
    background: rgba(#172341, 0.5);
  }

  &-label {
    margin-bottom: 1rem;
  }
}
</style>
