<template>
  <div class="history-footer w-full flex justify-center">
    <div class="history-footer-wrapper h-full flex items-center relative">
      <p class="text-texts-standard-important text-34">{{ $t('common.allRewards') }}:</p>
      <div class="history-footer-rewards flex h-full">
        <rewards
          reward-id="history-footer-rewards"
          :reward-data="(rewards as Reward[]) || []"
          :icon-size="48"
        />
      </div>
      <app-button
        class="history-footer-btn absolute right-0"
        btn-type="confirm"
        :btn-text="$t('common.takeRewards')"
        btn-size="md"
        @click="$emit('claim')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Rewards from '@/components/Rewards.vue'

import type { PropType } from 'vue'
import type Reward from '@/interfaces/Reward'

interface ComponentData {
  showPopup: boolean
}

export default defineComponent({
  name: 'GrandPrizeHistoryFooter',
  components: {
    Rewards,
  },
  props: {
    rewards: {
      type: Array as PropType<Reward[]>,
      default: () => [],
    },
  },
  emits: ['claim'],
  data(): ComponentData {
    return {
      showPopup: false,
    }
  },
})
</script>

<style lang="scss" scoped>
.history-footer {
  position: relative;
  height: 6.875rem;
  bottom: 0;
  left: 0;

  @if $isWsm {
    background-image: linear-gradient(to right, #0b1e37 0%, #103658 75%, #154e79 100%);
    border-top: solid 0.188rem #fadd1e;
    box-shadow: 0rem -1rem 2.5rem 0rem rgb(0 0 0 / 60%);
  }

  @if $isSsm {
    background-image: linear-gradient(to right, #232e46, #4c648f);
    border-top: solid 0.188rem #feb942;
  }

  &-wrapper {
    width: 117.5rem;
  }

  &-rewards {
    margin-left: 1.25rem;
  }
}
</style>
