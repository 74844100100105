import type { ImageLoadData } from '@/map-phaser-new/interfaces'

export const tutorialImageLoadData: () => ImageLoadData[] = () => [
  {
    name: 'tutorial-circle',
    compressed: false,
    data: {
      place: 'tutorial/circle-building.avif',
    },
  },
  {
    name: 'tutorial-arrow',
    compressed: false,
    data: {
      place: 'tutorial/arrow-shadow.avif',
    },
  },
]
