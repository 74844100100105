<template>
  <div class="game-pass-info-timer relative flexing">
    <app-timer v-if="time" :time="time" :italic="false" @countdown-action="$emit('loadData')" />
    <div class="tooltip absolute">
      <tippy theme="WSM2" placement="left" max-width="41.125rem">
        <app-icon icon-name="info-50" class="info" />
        <app-notification
          v-if="isPassEnding"
          type="exclamationLabel"
          class="notification-icon-33"
        />
        <template #content>
          <div class="px-8 py-4">
            <p class="text-texts-standard-important">{{ $t('common.timeRemaining') }}</p>
            <div class="yellow-strip my-4"></div>
            <p v-if="isPassEnding" class="mb-4 text-texts-standard-danger uppercase">
              {{ replaceInText($t('gamePass.passExpirationWarning')) }}
            </p>
            <p class="mb-4">{{ replaceInText($t('gamePass.passExpirationInfo')) }}</p>
            <p>{{ replaceInText($t('gamePass.passExpirationInfo2')) }}</p>
          </div>
        </template>
      </tippy>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { replaceGamePassPlaceholders } from '@/helpers'

export default defineComponent({
  name: 'GamePassInfoTimer',
  props: {
    time: {
      type: Number,
      default: 0,
    },
    isPassEnding: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['loadData'],

  methods: {
    replaceInText(text: string): string {
      return replaceGamePassPlaceholders(text)
    },
  },
})
</script>

<style lang="scss" scoped>
.game-pass-info-timer {
  .tooltip {
    right: 2rem;
    top: 0.2rem;
  }
}
</style>
