<template>
  <div
    class="table-standard-row relative flex items-center"
    :class="{ my: playerData.id === userId }"
  >
    <div
      class="staroftheday-table-pos flexing font-bold italic text-36"
      :class="[
        `staroftheday-${viewType}-table-pos`,
        rank < 4 || $isSsm ? 'text-texts-standard-dark' : 'text-texts-standard-default',
        { gold: rank === 1, silver: rank === 2, bronze: rank === 3 },
      ]"
    >
      {{ rank }}
    </div>
    <app-user
      :id="playerData.id || ''"
      class="staroftheday-table-player"
      :class="`staroftheday-${viewType}-table-player`"
      :country="playerData.country"
      :name="playerData.name"
      :is-bot="playerData.bot"
      :badges-data="playerData.event_badges ?? null"
    />
    <div
      class="staroftheday-table-played text-texts-standard-default text-32"
      :class="`staroftheday-${viewType}-table-played`"
    >
      {{ playerData.playedDisciplines }}
    </div>
    <div
      class="staroftheday-table-points flexing text-texts-standard-important text-32 italic"
      :class="`staroftheday-${viewType}-table-points`"
    >
      {{ getPoints() }}
    </div>
    <div
      v-if="playerData.rewards && playerData.rewards.length > 0"
      class="staroftheday-table-rewards flex justify-center items-center"
      :class="`staroftheday-${viewType}-table-rewards`"
    >
      <div
        v-for="(reward, rewardIndex) in playerData.rewards"
        :key="rewardIndex"
        class="flex justify-end items-center mx-2"
      >
        <div class="text-3xl text-texts-standard-default">
          {{ $filters.$formatNumber(reward.value) }}
        </div>
        <app-main-icon
          :icon-size="48"
          :icon-name="
            reward.name.split('_')[0] === 'benefit'
              ? `benefit-${reward.name.split('_')[3]}`
              : reward.name
          "
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import { pathImages, STAR_OF_THE_DAY_STANDINGS } from '@/globalVariables'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { DailyLeagueOpponent } from '@/interfaces/responses/starOfTheDay/DailyLeagueResponse'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'StarofthedayTableRow',
  components: {
    AppUser,
  },
  props: {
    viewType: {
      type: String,
      default: STAR_OF_THE_DAY_STANDINGS,
    },
    rank: {
      type: Number,
      default: 1,
      required: true,
    },
    playerData: {
      type: Object as PropType<Nullable<DailyLeagueOpponent>>,
      default: () => null,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userId: 'getUserId',
    }),
  },
  methods: {
    getPoints(): number {
      return (
        (this.viewType === STAR_OF_THE_DAY_STANDINGS
          ? this.playerData.points
          : this.playerData.rankingPoints) ?? 0
      )
    },
  },
})
</script>

<style lang="scss" scoped>
.table-standard-row {
  width: 117.5rem;
  margin-bottom: 0.375rem;
  height: 4rem;
}

.staroftheday-table-pos {
  width: 4.938rem;
  height: 100%;
  position: relative;
  @if $isWsm {
    background: rgba(20, 56, 88, 0.5);
  }
  @if $isSsm {
    background: #fff;
  }

  &:before {
    content: '';
    height: 100%;
    width: 1rem;
    position: absolute;
    right: -0.975rem;
    clip-path: polygon(0% 0%, 100% 0%, 0% 100%, 0% 100%);
    @if $isWsm {
      background: rgba(20, 56, 88, 0.5);
    }
    @if $isSsm {
      background: #fff;
    }
  }

  &.gold {
    background: linear-gradient(to top, #e9d27a, #f6c717);

    &:before {
      background: linear-gradient(to top, #e9d27a, #f6c717);
    }
  }

  &.silver {
    background: linear-gradient(to top, #8e8e8e, #dbdbdb);

    &:before {
      background: linear-gradient(to top, #8e8e8e, #dbdbdb);
    }
  }

  &.bronze {
    background: linear-gradient(to top, #b75a32, #ef9f73);

    &:before {
      background: linear-gradient(to top, #b75a32, #ef9f73);
    }
  }
}
</style>
