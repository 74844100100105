import {
  AnimatedObjectsWithPathsHandler,
  AnimatedObjectsWithoutPathsHandler,
  InteractiveObjectsHandler,
} from './specificHandlers'
import type {
  AnimatedObjectWithPathsInterface,
  AnimatedObjectWithoutPathsInterface,
  InteractiveObjectsInterface,
  ObjectsConfigInterface,
} from '@/map-phaser-new/interfaces'

import * as WsmMainMapSceneObjectsMainConfigExporter from '../../../config/winter-sports/scenes/MainMapScene/objects/mainConfig'
import * as SsmMainMapSceneObjectsMainConfigExporter from '../../../config/summer-sports/scenes/MainMapScene/objects/mainConfig'

import * as WsmClubsMapSceneObjectsMainConfigExporter from '../../../config/winter-sports/scenes/ClubsMapScene/objects/mainConfig'
import * as SsmClubsMapSceneObjectsMainConfigExporter from '../../../config/summer-sports/scenes/ClubsMapScene/objects/mainConfig'
import { GAMES_KEYS } from '@/map-phaser-new/constants'
import { useTutorialStore } from '@/store/pinia/tutorialStore'

const exporters = {
  [GAMES_KEYS.WinterSports]: {
    MainMapScene: WsmMainMapSceneObjectsMainConfigExporter,
    ClubsMapScene: WsmClubsMapSceneObjectsMainConfigExporter,
  },
  [GAMES_KEYS.SummerSports]: {
    MainMapScene: SsmMainMapSceneObjectsMainConfigExporter,
    ClubsMapScene: SsmClubsMapSceneObjectsMainConfigExporter,
  },
}

export class ObjectsHandler {
  constructor(
    private gameFromEnv: string,
    private scene: Phaser.Scene,
  ) {}

  public async createSceneObjects(): Promise<void> {
    try {
      const { objectsConfig } = exporters[this.gameFromEnv][this.scene.scene.key] as {
        objectsConfig: ObjectsConfigInterface
      }

      const tutorialStore = useTutorialStore()
      if (!tutorialStore.getIsTutorial) {
        // we don't have to wait for animated objects
        this.createAnimatedObjectsWithPaths(objectsConfig.animatedObjectsWithPaths)
        this.createAnimatedObjectsWithoutPaths(objectsConfig.animatedObjectsWithoutPaths)
      }

      // we have to wait for interactive objects - buildings
      await this.createInteractiveObjects(objectsConfig.interactiveObjects)
    } catch (error: unknown) {
      console.error(error)
    }
  }

  private async createInteractiveObjects(
    interactiveObjectsConfig?: InteractiveObjectsInterface,
  ): Promise<void> {
    if (!interactiveObjectsConfig) return

    const interactiveObjectsHandler = new InteractiveObjectsHandler(this.gameFromEnv, this.scene)
    await interactiveObjectsHandler.setUp(interactiveObjectsConfig)
  }

  private async createAnimatedObjectsWithPaths(
    animatedObjectsWithPathsConfig?: AnimatedObjectWithPathsInterface[],
  ): Promise<void> {
    if (!animatedObjectsWithPathsConfig) return

    const animatedObjectsWithPathsHandler = new AnimatedObjectsWithPathsHandler(
      this.gameFromEnv,
      this.scene,
    )

    await animatedObjectsWithPathsHandler.setUp(animatedObjectsWithPathsConfig)
  }

  private async createAnimatedObjectsWithoutPaths(
    animatedObjectsWithoutPathsConfig?: AnimatedObjectWithoutPathsInterface[],
  ): Promise<void> {
    if (!animatedObjectsWithoutPathsConfig) return

    const animatedObjectsWithoutPathsHandler = new AnimatedObjectsWithoutPathsHandler(
      this.gameFromEnv,
      this.scene,
    )
    await animatedObjectsWithoutPathsHandler.setUp(animatedObjectsWithoutPathsConfig)
  }
}
