<template>
  <avatar-menu box-type="club" @prestige-info="showPrestigeInfo = true" />
  <hamburger-menu
    :show="displayHamburger"
    @toggle-hamburger-menu="$emit('toggleHamburgerMenu')"
    @hide-hamburger-menu="$emit('hideHamburgerMenu')"
  />
  <clubs-map-right-box v-if="isMyClub($router)" />
  <popup-window
    v-if="showPrestigeInfo"
    popup-type="info"
    :popup-title="$t('club.prestige')"
    @close="showPrestigeInfo = false"
  >
    <prestige-info-popup />
  </popup-window>
</template>

<script lang="ts">
import AvatarMenu from '@/components/AvatarMenu.vue'
import HamburgerMenu from '@/components/HamburgerMenu.vue'
import ClubsMapRightBox from '@/components/Layout/ClubsMapRightBox.vue'
import PrestigeInfoPopup from '@/components/Popup/Info/PrestigeInfoPopup.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import { isMyClub } from '@/helpers'
import { useBuildingsStore } from '@/store/pinia/clubs/buildingStore'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useNotificationStore } from '@/store/pinia/clubs/notificationStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { SCENES_KEYS } from '@/map-phaser-new/constants'

interface ComponentData {
  showPrestigeInfo: boolean
}

export default defineComponent({
  name: 'ClubsMapLayout',
  components: {
    AvatarMenu,
    HamburgerMenu,
    PopupWindow,
    PrestigeInfoPopup,
    ClubsMapRightBox,
  },
  props: {
    displayHamburger: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggleHamburgerMenu', 'hideHamburgerMenu'],
  data(): ComponentData {
    return {
      showPrestigeInfo: false,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      myClubId: 'getPlayerClubId',
    }),
    ...mapState(useBuildingsStore, ['lastShowedMapClubId']),
    ...mapState(useClubStore, {
      getClubInfo: 'getClubInfo',
    }),
  },
  watch: {
    $route(): void {
      if (
        this.$route.name === 'ClubsMap' &&
        this.lastShowedMapClubId !== this.$route.params.clubId
      ) {
        this.loadClubData()
      }
    },
  },
  created(): void {
    if (this.$route.name === 'ClubsMap' || this.$route.name === 'ClubsMapTutorial') {
      if (!this.$route.params.clubId) {
        this.$router.push({
          name: this.$route.name,
          params: { clubId: this.myClubId },
        })
      }
      this.loadClubData()
    }
  },
  methods: {
    ...mapActions(useNotificationStore, ['loadNotifications']),
    ...mapActions(useBuildingsStore, ['setLastShowedMapClubId']),
    ...mapActions(usePhaserGameIntegrationStore, ['reloadBuildings']),
    ...mapActions(useClubStore, {
      loadClubInfo: 'loadClubInfo',
    }),
    isMyClub,
    async loadClubData(): Promise<void> {
      const clubId =
        this.$route.params?.clubId != null ? this.$route.params.clubId.toString() : this.myClubId

      if (this.lastShowedMapClubId !== '') {
        this.reloadBuildings(SCENES_KEYS.ClubsMapScene, clubId !== this.myClubId)
      }

      if (this.getClubInfo.id !== clubId) {
        await this.loadClubInfo(clubId)
      }

      this.setLastShowedMapClubId(clubId)
      if (this.isMyClub(this.$router)) this.loadNotifications()
    },
  },
})
</script>
