<template>
  <info-popup
    :class-list="['padd-cancel']"
    :popup-title="$t('premiumGroup.paymentMethod')"
    @close="$emit('close')"
  >
    <div class="payment-popup text-texts-standard-default text-28 uppercase">
      <main class="payment-popup-main w-full">
        <template v-for="(terminal, index) in getTerminals" :key="index">
          <section
            v-if="!isTerminalHidden(terminal)"
            class="payment-popup-main-box text-40 cursor-pointer m-auto flex items-center font-bold"
            :class="{ 'border-active': index === border }"
            @click="pay(index, terminal.provider)"
          >
            <p v-show="index === 0" class="credit-text text-texts-standard-important">
              {{ $t('premiumGroup.card') }}
            </p>
            <div
              class="payment-popup-main-box-img"
              :style="{
                background: 'url(' + pathImages + terminal.img + ') center no-repeat',
                backgroundSize: 'contain',
              }"
            />
            <p v-show="index === 4" class="text-bank">
              {{ $t('premiumGroup.transfer') }}
            </p>
          </section>
        </template>
      </main>
      <footer class="payment-popup-footer flexing">
        <p class="payment-popup-footer-text m-auto" v-html="conditionsTextLink" />
      </footer>
    </div>
  </info-popup>
  <info-popup
    v-if="showTransferPopup"
    :popup-title="$t('premiumGroup.transfer')"
    :class-list="['transfer-popup']"
    @close="showTransferPopup = false"
  >
    <iframe class="transfer-iframe" :src="transferPopupSrc" />
  </info-popup>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { metaPremiumPackInfoEndpoint, metaPremiumPayEndpoint, pathImages } from '@/globalVariables'
import { sendToFlutter } from '@/helpers'
import { useUserStore } from '@/store/pinia/userStore'
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { PaymentMethods } from '@/interfaces/Payments'
import type { MetaPremiumPackInfoApiResponse } from '@/interfaces/responses/premium/metaPremiumPackInfoApiResponse'

interface Terminal {
  img: string
  provider: string
}

interface ComponentData {
  showTransferPopup: boolean
  transferPopupSrc: string
  pathImages: typeof pathImages
  border: number
  terminals: Terminal[]
  currentItemPrice: number | null
}

export default defineComponent({
  name: 'PaymentTerminal',
  components: {
    InfoPopup,
  },
  props: {
    itemId: {
      type: String,
      default: '',
    },
    wireTransferOff: {
      type: Boolean,
      default: false,
    },
    isGems: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      showTransferPopup: false,
      transferPopupSrc: '',
      pathImages,
      border: 0,
      terminals: [
        { img: 'premium/paymentTerminal/cards.avif', provider: PaymentMethods.CreditCard },
        { img: 'premium/paymentTerminal/paypal.avif', provider: PaymentMethods.Paypal },
        {
          img: 'premium/paymentTerminal/paysafe.avif',
          provider: 'pay_safe_card',
        },
        { img: 'premium/paymentTerminal/skrill.avif', provider: PaymentMethods.Skrill },
        { img: 'premium/paymentTerminal/bank.avif', provider: PaymentMethods.BankTransfer },
      ],
      currentItemPrice: null,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userLanguage: 'getLanguage',
    }),
    languageCode(): string {
      return this.userLanguage === 'sk' || this.userLanguage === 'cs' ? 'sk' : 'en'
    },
    conditionsTextLink(): string {
      let text = this.isGems
        ? this.$t('premiumGroup.conditions1a')
        : this.$t('premiumGroup.conditions1b')
      text = this.$replacePlaceholder(text, '%s', 16)
      text = text.replace(
        /{link}/g,
        `<a class="clickable-element text-texts-standard-important underline" href="https://www.powerplay.studio/${this.languageCode}/license/" target="_blank">`,
      )
      text = text.replace(/{\/link}/g, '</a>')
      text += ` ${this.$t('premiumGroup.conditions2')}`
      return text
    },
    getTerminals(): Terminal[] {
      const withoutWire = this.terminals.filter(
        (terminal: Terminal): boolean => terminal.provider !== PaymentMethods.BankTransfer,
      )
      return this.wireTransferOff ? withoutWire : this.terminals
    },
  },
  async mounted(): Promise<void> {
    try {
      const responsePack = await this.$axios.get<{}, MetaPremiumPackInfoApiResponse>(
        `${metaPremiumPackInfoEndpoint}${this.itemId}`,
      )
      this.currentItemPrice = responsePack.pack.price.price
    } catch (error: unknown) {
      console.error(error)
    }
  },
  methods: {
    sendToFlutter,
    async pay(index: number, provider: string): Promise<void> {
      this.border = index
      if (this.itemId && provider) {
        const responsePay = await this.$axios.get<{}, string>(
          `${metaPremiumPayEndpoint}/pack/${this.itemId}/${provider}`,
        )
        if (provider === PaymentMethods.BankTransfer) {
          this.transferPopupSrc = responsePay
          this.showTransferPopup = true
          return
        }
        this.$routeToExternalUrl(responsePay)
      }
    },
    isTerminalHidden(terminal: Terminal): boolean {
      // Skrill payments - limitation only for payments from 9.99 euros
      return terminal.provider === PaymentMethods.Skrill && this.currentItemPrice < 9.99
    },
  },
})
</script>

<style lang="scss" scoped>
.transfer-iframe {
  width: 78rem;
  height: 38rem;
}

.payment-popup {
  width: 80rem;
  padding-top: 1rem;

  &-main {
    display: flex;
    flex-direction: column;
    gap: 0.813rem;

    &-box {
      width: 98%;
      height: 6.25rem;
      padding-left: 1.8125rem;

      @if $isSsm {
        background: rgb(82, 84, 121, 0.7);
        border: 0.125rem solid #7477a4;
      }

      @if $isWsm {
        background: #236d9b1c;
        border: 0.0625rem solid #6c9ec4;
      }

      &-img {
        height: 3.6875rem;
        width: 26.25rem;
      }

      .credit-text {
        margin-right: 1.875rem;
      }

      .text-bank {
        color: #bababa;
      }
    }

    .border-active {
      position: relative;

      @if $isSsm {
        border: 0.125rem solid #feb942;
        background: #8b5f13;
        box-shadow: inset 0 0 1rem rgb(254, 185, 66, 0.8);

        &::after {
          display: none;
        }
      }

      @if $isWsm {
        border: 0.125rem solid #fad742;
        background: #655e23;
      }

      &:after {
        content: '';
        position: absolute;
        right: -0.5rem;
        bottom: -0.6rem;
        width: 0;
        height: 0;
        border-top: 1.7rem solid transparent;
        border-bottom: 0.813rem solid transparent;
        border-left: 1.2rem solid transparent;
        border-left-color: #fad742;
        transform: rotate(34deg);
      }
    }
  }

  &-footer {
    height: 12.875rem;

    &-text {
      width: 90%;

      span {
        text-decoration: underline;
      }
    }
  }
}

.payment-popup-main-box:nth-child(2) > .payment-popup-main-box-img:nth-child(2) {
  width: 14.25rem;
}

.payment-popup-main-box:nth-child(3) > .payment-popup-main-box-img:nth-child(2) {
  width: 19.1875rem;
}

.payment-popup-main-box:nth-child(4) > .payment-popup-main-box-img:nth-child(2) {
  width: 8.8125rem;
}

.payment-popup-main-box:nth-child(5) > .payment-popup-main-box-img:nth-child(2) {
  height: 3.8125rem;
  width: 3.875rem;
  margin-right: 1.875rem;
}

.popup-padd {
  padding-bottom: 0;
}
</style>
