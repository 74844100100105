<template>
  <teleport to="#app">
    <div class="popup-overlay absolute left-0 top-0" @click="closePopup" />
    <div
      :class="{ 'scale-popup': !$isMobile() }"
      class="confirm-popup-box absolute left-1/2 top-1/2 flex flex-col items-center"
      :style="{ width: customWidth }"
    >
      <popup-header :title-text="popupTitle" :hide-close="hideClose" @close="closePopup" />
      <div class="confirm-popup-box-content">
        <div class="confirm-popup-box-content-textarea flexing">
          <slot />
        </div>
      </div>
      <app-button
        v-if="notMulti"
        v-tippy="
          disabled
            ? {
                theme: 'WSM',
                content: $t('research.dontGems'),
                placement: 'top',
                textAlign: 'center',
              }
            : {}
        "
        :btn-id="buttonData.btnId"
        :btn-type="buttonData.btnType"
        :btn-text="singleButtonText"
        :btn-size="buttonData.btnSize"
        :disabled="disabled"
        @click="$emit('action', $event)"
      />
      <app-multi-button1
        v-else
        :btn-id="buttonData.btnId"
        :btn-type="buttonData.btnType"
        :btn-size="buttonData.btnSize"
        :btn-text="buttonTextLeft"
        :btn-count="!$slots.buttonTextRight ? buttonTextRight : undefined"
        :is-icon="!buttonData.noIcon"
        @enough-resources-btn-click="$emit('action', $event)"
      >
        <template #rightPart>
          <slot name="buttonTextRight" />
        </template>
      </app-multi-button1>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import PopupHeader from './PopupHeader.vue'
import type { Nullable } from '@/interfaces/utils'

interface ButtonData {
  btnId: string
  btnSize: string
  btnType: string
  customLeftWidth?: number
  customRightWidth?: number
  noIcon?: boolean
}

export default defineComponent({
  name: 'ConfirmPopup',
  components: {
    PopupHeader,
  },
  props: {
    buttonData: {
      type: Object as PropType<Nullable<ButtonData>>,
      default: () => null,
    },
    notMulti: {
      type: Boolean,
      default: false,
    },
    popupTitle: {
      type: String,
      required: true,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customWidth: {
      type: String,
      default: '51rem',
    },
    singleButtonText: {
      type: String,
      default: null,
    },
    buttonTextLeft: {
      type: String,
      default: null,
    },
    buttonTextRight: {
      type: Number || String,
      default: null,
    },
  },
  emits: ['close', 'action'],

  methods: {
    closePopup() {
      this.$emit('close', false)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/popups.scss';

.scale-popup {
  transform: translate(-50%, -50%) scale(0.7);
}
</style>
