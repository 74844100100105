<template>
  <div class="events-warehouse-item flex flex-col items-center">
    <div class="events-warehouse-item-circle flexing">
      <img
        class="events-warehouse-item-circle-icon"
        :src="`${pathImages}events/frozen-championship/icons/ico-${item.icon}.avif`"
      />
    </div>
    <div
      class="events-warehouse-item-title flexing"
      :class="{
        'pt-2': $isSsm,
      }"
    >
      <shrink-text
        :text="$te(item.name)"
        :forced-font-size="32"
        container-class-list="flexing z-1"
        text-class-list="text-texts-standard-important uppercase font-bold"
        :animated-overflow="true"
      />
    </div>
    <div class="events-warehouse-item-output" :class="rewards.length > 2 ? 'flex-row' : 'flex-col'">
      <div
        v-for="(cost, index) in rewards"
        :key="index"
        class="flexing events-warehouse-item-output-reward"
      >
        <p
          class="text-texts-standard-important"
          :class="rewards.length > 2 ? 'text-36' : 'text-50'"
        >
          {{ $filters.$formatNumber(showValueWithMultiplier(cost.value)) }}
        </p>
        <app-icon
          v-if="cost.type === COMMODITY_POINTS"
          :icon-name="getCommodityPointsConfig.icon"
          class="ml-2"
          :tooltip-text="$te(getCommodityPointsConfig.name)"
        />
        <app-main-icon v-else :icon-size="rewards.length > 3 ? 48 : 56" :icon-name="cost.type" />
      </div>
    </div>
    <div
      class="events-warehouse-item-ingredients-title flexing"
      :class="{
        'pt-2': $isSsm,
      }"
    >
      <p class="text-32 text-texts-standard-additional uppercase">{{ $te('youNeed') }}</p>
    </div>
    <div class="events-warehouse-item-ingredients-crafting flex flex-col items-center">
      <div
        class="events-warehouse-item-ingredients-crafting-items"
        :class="item.building_costs.length > 2 ? 'flex-row' : 'flex-col'"
      >
        <div
          v-for="(ingredient, index) in item.building_costs"
          :key="index"
          class="flexing events-warehouse-item-ingredients-crafting-items-ingredient"
        >
          <p
            class="mr-4"
            :class="[
              item.building_costs.length > 3 ? 'text-36' : 'text-50',
              isEnoughAmountOfIngredient(ingredient)
                ? 'text-texts-standard-important'
                : 'text-texts-standard-danger',
            ]"
          >
            {{ $filters.$formatNumber(showValueWithMultiplier(ingredient.amount)) }}
          </p>
          <app-icon
            :icon-name="ingredient.ingredient.icon"
            class="mr-2"
            :tooltip-text="$te(ingredient.ingredient.name)"
          />
        </div>
      </div>
      <div class="events-warehouse-item-ingredients-crafting-buttons flexing">
        <event-button
          id="btn-craft-item"
          :disabled="isCraftingButtonDisabled"
          @on-click="craftArtefact(item)"
        >
          <template #leftSide>
            <p v-if="!isCraftingButtonDisabled" class="uppercase">
              {{ $te('build') }}
            </p>
            <component-loading v-else :is-loading="true" />
          </template>
          <template #rightSide>
            <p>{{ craftingMultiplier }}X</p>
          </template>
        </event-button>
        <app-button
          btn-size="xxsm"
          add-class="plus-button ml-4"
          :disabled="isCraftingButtonDisabled"
          @click="useMultiplier"
        >
          <span class="text-50">+</span>
        </app-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { COMMODITY_POINTS, pathImages } from '@/globalVariables'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import EventButton from '../EventButton.vue'
import ShrinkText from '@/components/ShrinkText.vue'

import type { ArtefactBuild } from '@/interfaces/responses/events/warehouse/WarehouseConfigApiResponse'
import type { IngredientAmount } from '@/interfaces/responses/events/warehouse/WarehouseSharedInterface'
import { useWarehouseStore } from '@/store/pinia/events/warehouseStore'
import type { PropType } from 'vue'

interface MissingIngredient extends IngredientAmount {
  buildId: number
}

interface ComponentData {
  COMMODITY_POINTS: typeof COMMODITY_POINTS
  pathImages: typeof pathImages
  craftingMultiplier: number
  craftingMultiplierIndex: number
  missingIngredientsOfArtefact: MissingIngredient[]
  isLoading: boolean
  isCraftingButtonDisabled: boolean
}

export default defineComponent({
  name: 'EventsWarehouseItem',
  components: {
    EventButton,
    ShrinkText,
  },
  props: {
    item: {
      type: Object as PropType<ArtefactBuild>,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      COMMODITY_POINTS,
      pathImages,
      craftingMultiplier: 1,
      craftingMultiplierIndex: 0,
      missingIngredientsOfArtefact: [],
      isLoading: false,
      isCraftingButtonDisabled: false,
    }
  },
  computed: {
    ...mapState(useWarehouseStore, {
      ingredientsState: 'getIngredientsState',
      getIngredientStateById: 'getIngredientStateById',
      getArtefactBuildMultipliers: 'getArtefactBuildMultipliers',
      getCommodityPointsConfig: 'getCommodityPointsConfig',
      getIsRecalculating: 'getIsRecalculating',
    }),
    rewards(): ArtefactBuild['rewards'] {
      if (!this.getIsRecalculating) return this.item.rewards
      return this.item.rewards.filter(
        ({ type }: ArtefactBuild['rewards'][0]): boolean => type !== COMMODITY_POINTS,
      )
    },
  },
  methods: {
    ...mapActions(useWarehouseStore, {
      buildArtefact: 'buildArtefact',
      setModalState: 'setWarehouseModalState',
    }),
    validateIngredients(): void {
      this.missingIngredientsOfArtefact = []

      this.item.building_costs.forEach((ingredient: IngredientAmount): void => {
        const userIngredientValue = this.getIngredientStateById(ingredient.ingredient.id)

        const isEnough: boolean =
          userIngredientValue?.amount >= this.showValueWithMultiplier(ingredient.amount)

        if (!isEnough) {
          const difference: number = Math.abs(
            userIngredientValue?.amount - this.showValueWithMultiplier(ingredient.amount),
          )

          this.missingIngredientsOfArtefact.push({
            ...ingredient,
            amount: difference,
            buildId: this.item.build,
          })
        }
      })
    },
    async craftArtefact(artefact: ArtefactBuild): Promise<void> {
      this.isCraftingButtonDisabled = true
      this.validateIngredients()

      const modalConfig = {
        isModalOpen: true,
        isChest: false,
        selectedArtefact: artefact,
        multiplier: this.craftingMultiplier,
      }

      if (this.missingIngredientsOfArtefact.length === 0) {
        try {
          const response = await this.buildArtefact({
            build: artefact.build,
            multiplier: this.craftingMultiplier,
          })

          this.setModalState({
            ...modalConfig,
            rewards: response?.rewards ?? [],
            isCraftedSuccessfully: true,
            missingIngredients: [],
          })
        } catch (error: unknown) {
          console.error(error)
        }
      } else {
        this.setModalState({
          ...modalConfig,
          rewards: [],
          isCraftedSuccessfully: false,
          missingIngredients: this.missingIngredientsOfArtefact,
        })
      }

      this.isCraftingButtonDisabled = false
    },
    useMultiplier(): [number] | void {
      if (this.getArtefactBuildMultipliers.length === 0) return [1]

      const enabledValues: number[] = this.getArtefactBuildMultipliers

      this.craftingMultiplierIndex = (this.craftingMultiplierIndex + 1) % enabledValues.length
      this.craftingMultiplier = enabledValues[this.craftingMultiplierIndex]
    },
    showValueWithMultiplier(value: number): number {
      return value * this.craftingMultiplier
    },
    isEnoughAmountOfIngredient(ingredientAmountConfig: IngredientAmount): boolean {
      if (this.ingredientsState == null) return

      const userIngredientValue = this.getIngredientStateById(ingredientAmountConfig.ingredient.id)

      return (
        userIngredientValue?.amount >= this.showValueWithMultiplier(ingredientAmountConfig.amount)
      )
    },
  },
})
</script>

<style lang="scss" scoped>
.events-warehouse-item {
  width: 26.25rem;
  height: 36.375rem;
  position: relative;

  @if $isSsm {
    @include background(url($path-events + 'frozen-championship/warehouse/box-bg.avif'), cover);
  }

  &-circle {
    position: absolute;
    left: -3rem;
    top: -3.25rem;
    width: 10rem;
    height: 11rem;
    @include background(
      url($path-events + 'frozen-championship/warehouse/item-title-circle.avif'),
      cover
    );

    &-icon {
      width: auto;
      height: 7rem;
    }
  }

  &-title {
    width: 100%;
    height: 4.125rem;

    @if $isWsm {
      background-image: linear-gradient(
        90deg,
        rgba(159, 139, 33, 0.4) 0%,
        rgba(159, 139, 33, 1) 30%,
        rgba(159, 139, 33, 1) 70%,
        rgba(159, 139, 33, 0.4) 100%
      );
      border-style: solid;
      border-width: 0.188rem;
      border-image-source: linear-gradient(90deg, #61622c, #9f8a21, #b2981d, #958121, #62662f);
      box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.33);
      border-image-slice: 1;
      padding-left: 5rem;
    }

    @if $isSsm {
      // Potrebne pre animovany text.
      padding-left: 5.5rem;
      padding-right: 0.5rem;
    }
  }

  &-output {
    width: 100%;
    height: 12.813rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 0.5rem;

    @if $isWsm {
      @include background(
        url($path-events + 'frozen-championship/warehouse/item-output-bg.avif'),
        cover
      );
    }

    &-reward {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 50%;
    }
  }

  &-ingredients-title {
    width: 100%;
    height: 2.938rem;

    @if $isWsm {
      padding-top: 0.1rem;
      background-image: linear-gradient(
        90deg,
        rgba(64, 88, 113, 0.4) 0%,
        rgba(64, 88, 113, 0.9) 30%,
        rgba(64, 88, 113, 0.9) 70%,
        rgba(64, 88, 113, 0.4) 100%
      );
      border-style: solid;
      border-width: 0.188rem;
      border-image-source: linear-gradient(
        90deg,
        rgba(95, 117, 137, 0.4) 0%,
        rgba(95, 117, 137, 1) 30%,
        rgba(95, 117, 137, 1) 70%,
        rgba(95, 117, 137, 0.4) 100%
      );
      border-image-slice: 1;
      box-shadow: 0px 7px 3px 0 rgba(0, 0, 0, 0.8);
    }
  }

  &-ingredients-crafting {
    width: 100%;
    height: 16.5rem;

    @if $isWsm {
      background-image: linear-gradient(
        to top,
        rgba(6, 10, 38, 0.1),
        rgba(6, 10, 38, 0.3),
        rgba(6, 10, 38, 0.8)
      );
      border-style: solid;
      border-width: 0.188rem;
      border-image-source: linear-gradient(to top, #0e324a, #0e324a, #747474);
      border-image-slice: 1;
    }

    &-items {
      height: 70%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      &-ingredient {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 50%;
      }
    }

    &-buttons {
      height: 30%;
      width: 100%;

      .plus-button {
        width: 5.75rem;
        height: 4.375rem;
      }
    }
  }
}
</style>
