import { defineStore } from 'pinia'

interface ChatMessagesState {
  hasNewChatMessage: boolean
}

export const useChatMessageStore = defineStore('useChatStore', {
  state: (): ChatMessagesState => ({
    hasNewChatMessage: false,
  }),
  getters: {
    isNewChatMessage(): ChatMessagesState['hasNewChatMessage'] {
      return this.hasNewChatMessage
    },
  },
  actions: {
    setNewNotification(data: boolean): void {
      this.hasNewChatMessage = data
    },
  },
})
