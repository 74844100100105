import type { App, Plugin } from 'vue'

export const replaceHtmlPlaceholders = (
  text: string = '',
  replacementTag: string,
  replacementClass: string = '',
): string => {
  const inputRegFirst = new RegExp('{' + replacementTag + '}', 'g')
  const inputRegSecond = new RegExp('{/' + replacementTag + '}', 'g')
  text = text.replace(inputRegFirst, `<span class="${replacementClass}">`)
  text = text.replace(inputRegSecond, '</span>')

  return text
}

export const replaceHtmlPlaceholdersPlugin: Plugin = {
  install: (app: App): void => {
    app.config.globalProperties.$replaceHtmlPlaceholders = replaceHtmlPlaceholders
  },
}
