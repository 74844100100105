<template>
  <div
    id="career-quests-navigator"
    class="career-quests-navigator"
    data-cy="career-quests-navigator"
  >
    <app-scrollbar
      id="career-career-quests-navigator-scrollbar"
      class="career-quests-navigator-scrollbar"
      slide="x"
      scroll="x"
      width="100%"
      height="8.5rem"
      scroll-to-element=".career-quests-navigator-nav.selected"
      scroll-to-element-align="start"
    >
      <arrows-component
        class="career-quests-navigator-arrows absolute"
        :array-of-ids="allQuests.map((quest) => quest.parameters.id)"
        :is-career="true"
      />
      <div class="career-quests-navigator-wrapper inline-flex flex-row relative">
        <router-link
          v-for="(quest, index) in allQuests"
          :key="index"
          :to="{
            params: { task: quest.parameters.id },
            query: $route.query.homepage
              ? {
                  homepage: 'true',
                }
              : {},
          }"
          class="career-quests-navigator-nav flexing"
          :class="[
            {
              lock: quest.isLocked,
              selected: selectedTaskTab === quest.parameters.id,
              available: quest.isActive && !quest.isDone,
              done: quest.isDone,
              'locked-selected':
                (selectedTaskTab === quest.parameters.id && quest.isLocked) || quest.isFinalQuest,
            },
          ]"
          @click="onMenuQuestClick(quest)"
        >
          <p
            v-if="!quest.isFinalQuest"
            class="text-50 font-bold"
            :class="
              quest.isActive && !quest.isDone
                ? 'text-texts-standard-dark'
                : 'text-texts-standard-default'
            "
          >
            {{ quest.parameters.order }}
          </p>
          <app-main-icon
            v-if="quest.isFinalQuest"
            class="final-quest-icon"
            :icon-size="80"
            icon-name="career-gold"
            :hide-tooltip="true"
          />

          <app-icon v-if="quest.isLocked" class="status-icon" icon-name="lock-xsm" />

          <div v-if="quest.hasUnlocks" class="icon-locked-shield absolute shield-icon" />

          <div v-if="quest.isDone" class="status-icon icon-done-sm" />
          <div
            v-if="quest.parameters?.boss_fight"
            :class="'boss ' + (quest.isLocked || quest.isDone ? 'opacity-80' : '')"
          >
            <div
              v-for="star in 3"
              :key="star"
              class="absolute"
              :class="
                quest.parameters.boss_fight
                  ? star <= quest.stats?.currentValue
                    ? 'icon-star-full-24'
                    : 'icon-star-empty-24'
                  : 'icon-star-empty-24'
              "
            />
          </div>
          <app-notification v-if="isNotification(quest)" :count="1" />
        </router-link>
      </div>
    </app-scrollbar>
  </div>
</template>

<script lang="ts">
import ArrowsComponent from '@/components/ArrowsComponent.vue'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { CareerQuest } from '@/interfaces/Quest'
import type { NotificationCareerQuest } from '@/interfaces/global/Notification'

interface ComponentData {
  selectedTaskTab: Nullable<number>
  selectedDiscipline: number
}

export default defineComponent({
  name: 'CareerQuestsNavigator',
  components: {
    ArrowsComponent,
  },
  props: {
    allQuests: {
      type: Array as PropType<CareerQuest[]>,
      default: () => [],
    },
  },

  data(): ComponentData {
    return {
      selectedTaskTab: null,
      selectedDiscipline: 0,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      notifications: 'getNotifications',
    }),
  },
  watch: {
    $route(): void {
      this.setIds()
    },
  },
  created(): void {
    this.setIds()
  },
  methods: {
    setIds(): void {
      this.selectedTaskTab = this.$route.params.task ? Number(this.$route.params.task) : null

      if (this.selectedTaskTab == null) {
        const activeQuest = this.allQuests.find((quest: CareerQuest): boolean => quest.isActive)

        if (activeQuest) {
          this.selectedTaskTab = activeQuest.parameters.id
        }
      }

      this.selectedDiscipline = this.$route.params.id ? Number(this.$route.params.id) : 0
    },
    onMenuQuestClick(quest: CareerQuest): number {
      if (quest.parameters.id == null) return

      this.selectedTaskTab = quest.parameters.id
      return this.selectedTaskTab
    },
    isNotification(quest: CareerQuest): boolean {
      const mainBranch = this.notifications.career_quests.filter(
        (notification: NotificationCareerQuest): boolean =>
          Number(notification.id) === quest.parameters.id && notification.discipline == null,
      )

      const disciplineBranch = this.notifications.career_quests.filter(
        (notification: NotificationCareerQuest): boolean =>
          Number(notification.id) === quest.parameters.id &&
          notification.discipline === this.selectedDiscipline,
      )

      return this.selectedDiscipline === 0 ? mainBranch.length > 0 : disciplineBranch.length > 0
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/career-icons.scss';
@import '@/assets/styles/components/icons/stars-icons.scss';

.career-quests-navigator {
  width: 90%;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  &-arrows {
    width: 100%;
  }

  &-wrapper {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &-nav {
    width: 5.625rem;
    height: 5.625rem;
    position: relative;
    margin-left: 1.2rem;
    margin-right: 1.2rem;

    box-shadow: 0 0 1.125rem 0 rgb(0 0 0 / 50%);
    cursor: pointer;
    flex-direction: row;
    @if $isWsm {
      transform: skew(-5deg);
      border: solid 0.125rem #3f6b93;
      background: #0d2c4c;
    }
    @if $isSsm {
      border: solid 0.125rem #586b9d;
      background: linear-gradient(to top, rgba(52, 65, 93, 0.85) 0%, rgba(39, 50, 73, 0.85) 100%);
    }

    &:first-child {
      margin-left: 0.5rem;
    }

    &:last-child {
      margin-right: 0.5rem;
    }

    &:active {
      transform: scale(0.95);
    }

    &:hover {
      background-image: linear-gradient(to top, #d2bf58 0%, #a89947 25%, transparent 100%);
      border: solid 0.125rem #f7cd27;
      transform: scale(0.95);
      @if $isWsm {
        transform: skew(-5deg);
      }
    }

    &.available {
      background-image: linear-gradient(to top, #ffef84, #f6c717);
    }

    &.done {
      @if $isWsm {
        background: linear-gradient(0deg, rgba(76, 179, 48, 1) 0%, rgba(21, 147, 15, 1) 100%);
      }
      @if $isSsm {
        border-color: #7bf4e5;
        background: linear-gradient(
          0deg,
          rgba(14, 231, 204, 0.85) 0%,
          rgba(8, 202, 178, 0.85) 100%
        );
      }
    }

    &.locked-selected {
      @if $isWsm {
        background-image: linear-gradient(to top, #3f78a0, #254257);
      }
      @if $isSsm {
        background-image: linear-gradient(to top, #3e4f79, #697faf);
      }

      &:before {
        content: '';
        width: 7rem;
        height: 1.625rem;
        position: absolute;
        top: 35%;
        background-size: 100% 100% !important;
        background: url($path-career + 'selected-quest-arrows.avif') center no-repeat;
      }
    }

    &.selected {
      @if $isWsm {
        box-shadow:
          inset 0.188rem 0 0 0 rgba(255, 255, 255, 0.4),
          inset -0.188rem 0 0 0 rgba(255, 255, 255, 0.4),
          inset 0 -0.125rem 0.063rem 0 rgba(255, 255, 255, 0.4),
          inset 0 0.25rem 0 0 rgba(255, 255, 255, 0.64);
      }
      @if $isSsm {
        border-style: solid;
        border-width: 0.188rem;
        border-image-source: linear-gradient(90deg, rgba(255, 170, 60, 1) 0%, #ffe719 100%);
        border-image-slice: 1;
      }

      &:before {
        content: '';
        width: 7rem;
        height: 1.625rem;
        position: absolute;
        top: 35%;
        background-size: 100% 100% !important;
        background: url($path-career + 'selected-quest-arrows.avif') center no-repeat;
      }
    }

    .status-icon {
      position: absolute;
      top: 95%;
      left: 50%;
      transform: skew(5deg) translate(-50%, -50%);
      background-size: contain;
      z-index: 1;

      &:active {
        transform: scale(0.95) translate(-50%, -50%);
      }
    }

    .shield-icon {
      right: 35%;
      top: -15%;
    }

    .boss {
      width: 5.5rem;
      height: 2.2rem;
      position: absolute;
      bottom: -1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      & > div {
        &:first-child {
          left: 0;
        }

        &:last-child {
          right: 0;
        }
      }
    }

    .special-task {
      width: 1.25rem;
      height: 1.25rem;
      background: #000;
      position: absolute;
      top: 0;
      right: 0;
    }

    .final-quest-icon {
      width: 4.5rem;
      height: 4.5rem;
      background-size: 4.5rem;
      background-position: 0 0.1rem;
    }
  }
}
</style>
