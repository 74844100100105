import { manageNotice } from '@/globalVariables'
import type { ClubManageNoticeApiResponse } from '@/interfaces/responses/club/ClubResponses'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'

interface ClubNoticeState {
  message: {
    message: string
  }
}

export const useClubNoticeStore = defineStore('noticeStore', {
  state: (): ClubNoticeState => ({
    message: {
      message: '',
    },
  }),
  getters: {
    getCurrentNotice(): ClubNoticeState['message'] {
      return this.message
    },
  },
  actions: {
    async addNotice(notice: { message: string }): Promise<void> {
      const data = {
        message: notice.message,
      }

      try {
        await internalAxios.post<{}, ClubManageNoticeApiResponse>(manageNotice, data)
      } catch (error: unknown) {
        console.error(error)
      }
    },

    async deleteNotice(): Promise<void> {
      const data = {
        message: '',
      }

      try {
        await internalAxios.post<{}, ClubManageNoticeApiResponse>(manageNotice, data)
      } catch (error: unknown) {
        console.error(error)
      }
    },

    async loadNotice() {
      const data = await internalAxios.get<{}, string>(manageNotice)

      const mutatedData = {
        message: data,
      }

      this.message = mutatedData
    },
  },
})
