<template>
  <div class="eq-item-box-empty flexing" :class="{ premium: isPremium }">
    <div v-if="expiredTime > 0" class="eq-item-box-empty-content flexing w-full h-full">
      <span class="ml-10 timer-wrapper">
        <app-timer
          :time="expiredTime"
          no-min-width
          :italic="false"
          @countdown-action="$emit('loadData')"
        />
      </span>
      <p class="text-40 font-bold text-texts-standard-default expired-text flexing uppercase mt-4">
        {{ $t('equipmentShop.extend') }}
      </p>
    </div>
    <div v-else class="eq-item-box-empty-content flexing w-full h-full">
      <app-icon icon-name="lock-lg" />
      <p class="locked-text text-36 uppercase text-texts-standard-additional">
        {{ $t('common.locked') }}
      </p>
      <p class="text-quest text-texts-standard-default text-30">
        <!-- TODO NASTAVIT SPRAVNY REPLACE PO ZAPRACOVANI NA BE -->
        {{
          isPremium
            ? $t('equipmentShop.availableInOffer')
            : $replacePlaceholder(
                $t('common.secondaryCareerTaskRequired'),
                '%s',
                String(unlockLevel),
              )
        }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EquipmentItemBoxEmpty',
  props: {
    unlockLevel: {
      type: Number,
      required: true,
    },
    isPremium: {
      type: Boolean,
      default: false,
    },
    expiredTime: {
      type: Number,
      default: 0,
    },
  },
  emits: ['loadData'],
})
</script>

<style lang="scss" scoped>
.eq-item-box-empty {
  width: 56rem;
  height: 18.75rem;
  position: absolute;
  left: 0.25rem;
  bottom: 0.45rem;
  z-index: 2;
  @if $isWsm {
    background: rgba($color: #0b1e35, $alpha: 0.8);
  }
  @if $isSsm {
    background: rgba(59, 67, 74, 0.5);
  }

  &.premium {
    width: 31rem;
    bottom: 0.6rem;
    height: 18.7rem;
    background: url($path-equipment + 'box-backgrounds/new/premium-locked-overlay.avif') no-repeat
      center;
    background-size: cover;
  }

  &-content {
    flex-direction: column;

    .locked-text {
      height: 2.3125rem;
      line-height: 2.3125rem;
      border-right: solid 0.125rem #377fb8;
      border-left: solid 0.125rem #377fb8;
      margin-top: 1rem;
      padding: 0 1.5rem;
      @if $isSsm {
        border-color: #cb3d7e;
      }
    }

    .text-quest {
      margin-top: 3.1875rem;
      width: 90%;
    }
  }

  .timer-wrapper {
    width: 16rem;
  }

  .expired-text {
    width: 22.5rem;
    height: 3.25rem;
    background: linear-gradient(
      90deg,
      rgba(55, 127, 184, 0) 0%,
      rgba(55, 127, 184, 0.8) 25%,
      rgba(55, 127, 184, 0.8) 75%,
      rgba(55, 127, 184, 0) 100%
    );
  }
}
</style>
