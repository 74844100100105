<template>
  <div class="lab-economics-tree flex justify-around">
    <lab-economics-header :header-data="researchColumnsEconomy" />
    <lab-economics-column
      v-for="(col, i) in labData"
      :key="i"
      :column-data="col"
      :has-ongoing-research-tree="hasOngoingResearchTree"
      @select-skill="$emit('selectSkill', $event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LabEconomicsHeader from '@/components/Lab/Economics/LabEconomicsHeader.vue'
import LabEconomicsColumn from '@/components/Lab/Economics/LabEconomicsColumn.vue'
import { researchColumnsEconomy } from '@/helpers/lab'
import type { Cell } from '@/interfaces/lab/Cell'
import { LineDirection, LineStyle, type EconomicsInput } from '@/interfaces/lab/Cell'
import type { PropType } from 'vue'

interface ComponentData {
  LineDirection: typeof LineDirection
  LineStyle: typeof LineStyle
  researchColumnsEconomy: EconomicsInput[]
}

export default defineComponent({
  name: 'LabEconomicsTree',
  components: {
    LabEconomicsHeader,
    LabEconomicsColumn,
  },
  props: {
    labData: {
      type: Array as PropType<Cell[][]>,
      required: true,
    },
    hasOngoingResearchTree: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectSkill'],
  data(): ComponentData {
    return {
      researchColumnsEconomy,
      LineDirection,
      LineStyle,
    }
  },
})
</script>

<style lang="scss" scoped>
.lab-economics-tree {
  padding-top: 2rem;
}
</style>
