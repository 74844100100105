<template>
  <section class="tasks-boss w-full h-full">
    <header
      :class="[isEventTaskView ? 'pl-16' : 'pl-6 mt-4']"
      class="tasks-boss-header w-full flex font-bold items-center justify-between text-40 text-texts-standard-default relative pr-10"
    >
      <div class="tasks-boss-header-left">
        <p>{{ $te('bossFightTask') }}</p>
      </div>
      <div class="tasks-boss-header-right flexing">
        <tasks-skip-button
          v-if="!task.done"
          :amount="task.skip"
          btn-type="verticalColBtn"
          @skip="onSkipBossClick"
        />
      </div>
    </header>
    <section class="tasks-boss-info flex items-center" :class="{ flexing: isEventTaskView }">
      <div class="flexing" :class="{ 'mr-16': isEventTaskView }">
        <div class="avatar-container relative">
          <div
            class="avatar absolute"
            :style="{
              backgroundImage: `url(${pathImages}events/avatars/${boss.sex}/${boss.avatar_id}.avif)`,
            }"
          />
          <div class="avatar-circle absolute" />
        </div>
      </div>
      <aside
        class="boss-info h-full flexing flex-col font-bold text-texts-standard-default uppercase mt-8"
        :class="{ 'w-full': !isEventTaskView }"
      >
        <div class="flex justify-center items-baseline">
          <div
            class="flag"
            :style="{
              backgroundImage: 'url(' + pathImages + 'flags/102x68/' + boss.country + '.avif)',
            }"
          />
          <p class="text-50">{{ boss.name }}</p>
        </div>
        <div class="boss-attribute flexing">
          <div class="boss-attribute-icon mr-6">
            <app-discipline-icon
              :discipline-id="boss.discipline_id"
              :size="70"
              :is-transparent-bg="true"
              :has-tooltip="false"
            />
          </div>
          <p class="boss-attribute-text text-90 text-texts-standard-important">
            {{ boss.boss_attribute.value }}
          </p>
        </div>
      </aside>
    </section>
    <footer
      class="tasks-boss-footer flex items-center justify-between text-60 text-texts-standard-default relative font-bold uppercase pl-8 pr-10"
      :class="{ 'done-boss': task.done, 'pl-16': isEventTaskView }"
    >
      <div class="tasks-boss-footer-left flex flex-row items-center">
        <app-discipline-icon :discipline-id="boss.discipline_id" :size="70" />
        <p class="text-50 uppercase ml-6">{{ disciplineName(boss.discipline_id) }}</p>
      </div>
      <div class="tasks-boss-footer-right flexing">
        <app-button
          v-if="!task.done"
          btn-type="confirm"
          :btn-text="$t('button.start')"
          btn-size="xsm"
          :disabled="task.done"
          @click="showBossFightPopup = true"
        />
        <app-icon v-else icon-name="done-lg" />
      </div>
    </footer>
  </section>
  <boss-fight-popup
    v-if="showBossFightPopup"
    :boss-info="boss"
    @close="showBossFightPopup = false"
  />
  <boss-fight-report-popup
    v-if="showReportPopup && reportPopupData"
    :player="reportPopupData.player"
    :boss="reportPopupData.boss"
    @play-again="playAgain"
    @close="showReportPopup = false"
  />
</template>

<script lang="ts">
import { LIVE, pathImages } from '@/globalVariables'
import { getReportValues } from '@/helpers'
import type TaskchainBossfightReportApiResponse from '@/interfaces/responses/events/TaskchainBossfightReportApiResponse'
import type TaskchainStateApiResponse from '@/interfaces/responses/events/TaskchainStateApiResponse'
import { useTaskChainStore } from '@/store/pinia/events/taskChainStore'
import { useMatchesStore } from '@/store/pinia/matchesStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import BossFightPopup from './Popups/BossFightPopup.vue'
import BossFightReportPopup from './Popups/BossFightReportPopup.vue'
import TasksSkipButton from './TasksSkipButton.vue'
import type { Nullable } from '@/interfaces/utils'

// Tento interface sa pouziva iba pre child komponenty.
export interface ReportPopupData {
  player: TaskchainBossfightReportApiResponse['final_standings'][0] & {
    avatarUrl: string
    disciplineId: number
  }
  boss: TaskchainBossfightReportApiResponse['final_standings'][0] & {
    avatarUrl: string
    disciplineId: number
  }
}

interface ComponentData {
  pathImages: string
  showBossFightPopup: boolean
  showSkipBossPopup: boolean
  showReportPopup: boolean
  reportPopupData: Nullable<ReportPopupData>
}

export default defineComponent({
  name: 'TasksBoss',
  components: {
    TasksSkipButton,
    BossFightPopup,
    BossFightReportPopup,
  },
  props: {
    task: {
      type: Object as PropType<Nullable<TaskchainStateApiResponse['tasks'][0]>>,
      default: () => null,
    },
    boss: {
      type: Object as PropType<Nullable<TaskchainStateApiResponse['boss_data']>>,
      default: () => null,
    },
  },
  emits: ['skipBoss'],
  data(): ComponentData {
    return {
      pathImages,
      showBossFightPopup: false,
      showSkipBossPopup: false,
      showReportPopup: false,
      reportPopupData: null,
    }
  },
  computed: {
    ...mapState(useMatchesStore, {
      matchReport: 'getMatchReport',
    }),
    ...mapState(useUserStore, {
      userAttributes: 'getAttributes',
    }),
    taskId(): TaskchainStateApiResponse['tasks'][0]['task'] {
      return this.task.task
    },
    isEventTaskView(): boolean {
      return this.$route.name === this.$getWebView('TasksEvent')
    },
  },
  watch: {
    async '$route.query'(): Promise<void> {
      if (!this.$route.query?.match_id) return

      const { matchId, disciplineId }: { matchId: string; disciplineId: number } = getReportValues(
        this.$route,
      )

      const matchIdResponse: string = await this.loadMatchReport({
        matchId,
        matchTime: LIVE,
      })

      const response: TaskchainBossfightReportApiResponse = this.matchReport(matchIdResponse)
      const isPlayerWinner = response.position === 1

      const player: ReportPopupData['player'] = {
        ...response.final_standings[isPlayerWinner ? 0 : 1],
        avatarUrl: `${pathImages}avatars/${this.userAttributes.sex}/${this.userAttributes.avatar}.avif`,
        disciplineId,
      }

      const boss: ReportPopupData['boss'] = {
        ...response.final_standings[!isPlayerWinner ? 0 : 1],
        avatarUrl: `${pathImages}events/avatars/${this.boss.sex}/${this.boss.avatar_id}.avif`,
        disciplineId,
      }

      this.reportPopupData = {
        player,
        boss,
      }

      this.showReportPopup = true

      if (isPlayerWinner) {
        const taskChainStore = useTaskChainStore()
        await taskChainStore.loadState()
      }

      // remove query params from url
      this.$router.push({ name: this.$getWebView('EventsTaskchain') })
    },
  },
  methods: {
    ...mapActions(useMatchesStore, ['loadMatchReport']),
    disciplineName(disciplineId: string | number) {
      return this.$translateDiscipline(disciplineId ?? '')
    },
    onSkipBossClick(): void {
      this.showSkipBossPopup = false
      this.$emit('skipBoss', { taskId: this.taskId })
    },
    playAgain(): void {
      this.showReportPopup = false
      this.showBossFightPopup = true
      this.reportPopupData = null
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';
@import '@/assets/styles/variables.scss';

.tasks-boss {
  &-header {
    height: 5.9375rem;
    background: linear-gradient(to right, transparent, #24588e70, transparent);
  }

  .done-boss {
    @if $isWsm {
      background-image: linear-gradient(to right, transparent, #30c20e30, transparent);
    }

    @if $isSsm {
      background-image: linear-gradient(
        to right,
        transparent 0%,
        rgba(0, 122, 89, 0.5) 15%,
        rgba(0, 122, 89, 0.5) 85%,
        transparent 100%
      );
    }
  }

  &-header {
    &-right {
      min-width: 16.875rem;
    }
  }

  &-info {
    height: 17.5rem;

    .avatar-container {
      width: 15.625rem;
      height: 15.625rem;

      .avatar {
        top: 0.5rem;
        left: 1.5rem;
        width: 12.625rem;
        height: 14.625rem;
        @include background(null, contain);
      }

      .avatar-circle {
        top: 0;
        left: 0;
        width: 15.625rem;
        height: 15.625rem;
        @include background(
          url('#{$path-events}shared/taskchain/tasks/tasks-avatar-circle.avif'),
          contain
        );
      }
    }

    .boss-info {
      padding-right: $default-margin;

      .flag {
        width: 3.875rem;
        height: 2.5625rem;
        @include background(null, contain);
        margin-right: 1.25rem;
      }

      .boss-attribute {
        margin-top: -1.5rem;

        &-icon {
          width: 4.375rem;
          height: 4.375rem;
        }
      }
    }
  }

  &-footer {
    height: 5.375rem;
    background: linear-gradient(to right, transparent, #24588e70, transparent);

    &-right {
      min-width: 16.875rem;
    }
  }
}
</style>
