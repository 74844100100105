<template>
  <section
    v-if="!eventCollectionActive && eventPass?.length"
    class="event-pass-body flexing flex-grow w-full"
    :class="{ 'is-premium': $route.meta.isPremiumRoute && !isPaymentView }"
    :data-event-theme="namespace"
  >
    <div
      class="event-pass-body-cards flexing w-full h-full"
      :class="{ 'mt-2 space-x-6': isPaymentView }"
    >
      <div
        v-for="(reward, i) in eventPass?.[0]?.offer_content || []"
        :key="i"
        v-tippy="{
          theme: 'WSM',
          content: isPaymentView ? getDescription(reward, i + 1) : '',
          placement: 'top',
          textAlign: 'center',
        }"
        class="event-pass-body-card"
        :class="[
          {
            'is-premium': $route.meta.isPremiumRoute && !isPaymentView,
            'is-payment-view': isPaymentView,
            '-mx-12': !$route.meta.isPremiumRoute && !isPaymentView,
          },
          `card-${i + 1}`,
        ]"
      >
        <p
          class="text-texts-standard-important font-bold uppercase mx-auto"
          :class="$route.meta.isPremiumRoute ? 'text-40' : 'text-50'"
        >
          {{ $te(`eventPassPerk${i + 1}Title`) }}
        </p>
        <p v-if="!isPaymentView" class="text-texts-standard-default text-28 mx-auto">
          {{ getDescription(reward, i + 1) }}
        </p>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { getResourceUnit } from '@/helpers'
import type PremiumEventPassApiResponse from '@/interfaces/responses/premium/PremiumEventPassApiResponse'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EventPass1Body',
  props: {
    isPaymentView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
      eventCollectionActive: 'getEventCollectionActive',
    }),
    ...mapState(usePremiumStore, {
      eventPass: 'getEventPass',
    }),
  },
  methods: {
    getDescription(
      reward: PremiumEventPassApiResponse['packs'][0]['offer_content'][0],
      index: number,
    ): string {
      return this.$replacePlaceholder(
        this.$te(`eventPassPerk${index}Text`),
        '{percent}|{2to4}',
        `${reward.value}${getResourceUnit(reward.name, true)}`,
      )
    },
  },
})
</script>

<style lang="scss" scoped>
$rewards: (1, 2, 3);

[data-event-theme='challenge_cup'] {
  @each $reward in $rewards {
    --events-passes-card-#{$reward}-bg: url('#{$path-events}challenge-cup/passes/event-pass/card-#{$reward}-bg.webp');
    --events-passes-payment-card-#{$reward}-bg: url('#{$path-events}challenge-cup/passes/event-pass/payment-card-#{$reward}-bg.webp');
  }
}

.event-pass-body {
  &-card {
    width: 42.875rem;
    height: 45rem;
    padding-top: 26.875rem;

    &.is-premium {
      width: 33.33%;
      height: 100%;
      padding-top: 22.5rem;

      p {
        width: 85%;
      }
    }

    &.is-payment-view {
      width: 34.25rem;
      height: 23.125rem;
      padding-top: 19rem;

      p {
        width: 95%;
      }
    }

    p {
      width: 75%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    @each $reward in $rewards {
      &:not(.is-premium).card-#{$reward} {
        @include background(var(--events-passes-card-#{$reward}-bg), contain);

        &.is-payment-view {
          background-image: var(--events-passes-payment-card-#{$reward}-bg);
        }
      }
    }
  }
}
</style>
