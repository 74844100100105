<template>
  <info-popup :popup-title="$t('premiumGroup.paymentFail')" @close="setPaymentFailed(false)">
    <section class="payment-failed-popup w-full h-full flex items-center flex-col">
      <div class="payment-failed-popup-image" />
      <p class="text-36 text-texts-standard-default">
        {{ $t('premiumGroup.paymentFailText') }}
      </p>
      <app-button
        btn-id="payment-failed-popup-btn"
        btn-size="md"
        btn-type="secondary"
        :btn-text="$t('premiumGroup.close')"
        @click="setPaymentFailed(false)"
      />
    </section>
  </info-popup>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PaymentStatusMessage',
  components: {
    InfoPopup,
  },
  methods: {
    ...mapActions(useResponseTaskStore, { setPaymentFailed: 'setPaymentFailed' }),
  },
})
</script>

<style lang="scss" scoped>
.payment-failed-popup {
  &-image {
    width: 9.875rem;
    height: 8.625rem;
    margin: 2rem 0;
    background: url($path-premium + 'afterPayment/payment-failed.avif') center no-repeat;
    background-size: contain;
  }

  & p {
    margin-bottom: 2rem;
  }

  & #payment-failed-popup-btn {
    width: 92%;
  }
}
</style>
