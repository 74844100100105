export abstract class InteractiveObject {
  constructor(
    private _positionX: number,
    private _positionY: number,
  ) {}

  /**
   * The horizontal position of this Game Object in the world.
   */
  get positionX() {
    return this._positionX
  }

  set positionX(newPositionX: number) {
    this._positionX = newPositionX
  }
  /**
   * The vertical position of this Game Object in the world.
   */
  get positionY() {
    return this._positionY
  }

  set positionY(newPositionY: number) {
    this._positionY = newPositionY
  }

  abstract addToMap(activeScene: Phaser.Scene, ...customParams: any[]): void
}
