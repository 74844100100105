<template>
  <div
    class="app-select"
    :class="{ disabled, 'app-select--revert-skew': currentGame === GameType.Winter }"
  >
    <multi-select
      v-model="model"
      :options="options"
      :placeholder="placeholder"
      :can-deselect="false"
      :open-direction="direction"
      :class="hasDisabledOption ? 'disable-options select-witch-avatar' : ''"
      @select="selectOption()"
      @open="$emit('open')"
      @close="$emit('close')"
    >
      <!-- placeholder -->
      <template #placeholder="{}">
        <slot name="select-option" />
      </template>
      <!-- selected option -->
      <template #singlelabel="{ value }">
        <div
          class="multiselect-single-label app-select-selected"
          :class="{ 'revert-skew': currentGame === GameType.Winter }"
        >
          <selectWithFlag v-if="value.icon" :option-data="value" />
          <span v-else>{{ value.name }}</span>
        </div>
      </template>
      <!-- options -->
      <template #option="{ option }">
        <selectWithFlag v-if="option.icon" :option-data="option" />
        <selectWithAvatar v-else-if="option.avatar" :option-data="option" />
        <span v-else>{{ option.name }}</span>
      </template>
    </multi-select>
  </div>
</template>

<script lang="ts">
import SelectWithAvatar from '@/components/Select/SelectWithAvatar.vue'
import SelectWithFlag from '@/components/Select/SelectWithFlag.vue'
import { pathImages, GameType } from '@/globalVariables'
import MultiSelect from '@vueform/multiselect'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'

interface SelectOption {
  abbr: string
  icon: string
  name: string
  value: string
}

interface ComponentData {
  GameType: typeof GameType
  currentGame: string
  pathImages: typeof pathImages
  model: any
}

export default defineComponent({
  name: 'AppSelect',
  components: {
    MultiSelect,
    SelectWithFlag,
    SelectWithAvatar,
  },
  props: {
    options: {
      type: Array as PropType<SelectOption[]>,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    default: {
      type: [String, Number],
      default: '',
    },
    direction: {
      type: String,
      default: 'bottom',
      validator(value: string): boolean {
        return ['top', 'bottom'].includes(value)
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    resetAfterSelect: {
      type: Boolean,
      default: false,
    },
    hasDisabledOption: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select', 'open', 'close'],
  data(): ComponentData {
    return {
      GameType,
      pathImages,
      model: '',
      currentGame: this.$gameName,
    }
  },
  watch: {
    options(): void {
      this.model = this.default || ''
    },
  },
  mounted(): void {
    if (this.default) {
      this.model = this.default
    }
  },
  methods: {
    selectOption(): void {
      this.$emit('select', this.model)
      if (this.resetAfterSelect) {
        this.model = this.default || {}
      }
    },
  },
})
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
.app-select {
  width: 27rem;
  height: 4.375rem;
  font-size: 1rem;
  position: relative;
  z-index: 9;
  @if $isWsm {
    transform: skewX(-8deg);
    background-image: linear-gradient(10deg, #4787b4, #2b4e67);
    border: 0.125rem solid #528ebf;
  }
  @if $isSsm {
    background-color: #22222c;
    border: 0.125rem solid #22222c;
  }

  & .multiselect {
    width: 100%;
    height: 100%;
    background: transparent;
    text-align: center;
    border: none;

    &.is-active {
      box-shadow: none;
    }

    &-dropdown {
      max-height: 30rem;
      padding: 0.938rem;
      font-size: 2rem;
      letter-spacing: 0.05rem;
      text-transform: uppercase;
      @if $isWsm {
        background-image: linear-gradient(to top, #0f2839, #021b2c),
          linear-gradient(to bottom, #1b2f47, #1b2f47);
        box-shadow:
          0 0.188rem 0.313rem 0 rgba(0, 0, 0, 0.35),
          inset 0 0 0.5rem 0.5rem rgba(117, 182, 231, 0.2);
        border: 0.125rem solid #75b6e7;
        font-weight: bold;
        font-style: italic;
      }
      @if $isSsm {
        background: #1d1f2c;
        border: 0.125rem solid #4c648f;
        font-weight: 500;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #0c1724;
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: #0c1724;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #9cc2dd;
      }
    }

    &-options {
      width: 100%;
      max-height: 30rem;
      color: #fff;
    }

    &-option {
      font-size: 2rem;
      background: none;

      &.is-pointed {
        background: #f6c717;
        color: #233441;
        @if $isWsm {
          background-image: linear-gradient(to top, #fff284, #f6c717);
        }
        @if $isSsm {
          background-image: linear-gradient(to top, #feb942, #ffc868);
        }
      }
    }

    &-placeholder,
    &-single-label {
      color: #fff;
      font-size: 2rem;
      letter-spacing: 0.05rem;
      text-transform: uppercase;
      line-height: 2rem;
      @if $isWsm {
        font-weight: bold;
        font-style: italic;
      }
      @if $isSsm {
        font-weight: 500;
      }
    }

    &-placeholder {
      width: 100%;
      justify-content: center;
    }

    &-caret {
      background-color: #fff;
      width: 3rem;
      height: 3.5rem;
      z-index: unset;
    }

    &-clear {
      display: none;
    }
  }

  &-selected-icon,
  &-option-icon {
    width: 4.375rem;
    height: 3.063rem;
    margin-right: 1.5rem;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.disable-options {
  .multiselect-options {
    .multiselect-option {
      pointer-events: none;
      cursor: default;

      &.is-pointed {
        background: unset;
        color: unset;
        cursor: default;
      }
    }
  }
}

.app-select--revert-skew {
  .multiselect-dropdown {
    width: 100%;
    left: 50%;
    transform: skew(8deg) translateY(100%) translateX(calc(-50%));
    /* y-offset-keyword | x-offset-keyword */
    transform-origin: bottom center;
  }

  .multiselect-caret {
    transform: rotate(0deg) skew(8deg);

    &.is-open {
      transform: rotate(180deg) skew(8deg);
    }
  }

  .multiselect-placeholder {
    transform: skew(8deg);
  }
}

.select-witch-avatar {
  .multiselect-dropdown {
    width: 36.25rem;
    left: unset;
  }
}
</style>
