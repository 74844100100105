<template>
  <p v-if="hideTooltip" class="flexing">
    {{ medal }}
    <app-main-icon :hide-tooltip="true" :icon-size="32" icon-name="medals" class="ml-2 mb-2" />
  </p>
  <tippy v-else theme="WSM" placement="left" max-width="35rem">
    <p class="flexing">
      {{ medal }}
      <app-main-icon :hide-tooltip="true" :icon-size="32" icon-name="medals" class="ml-2 mb-2" />
    </p>
    <template #content>
      <div class="tooltip-content text-texts-standard-default text-30 pb-3 mb-3">
        <p class="text-40 font-bold text-texts-standard-important">
          {{ $te('medals') }}
        </p>
        <hr class="row-fixer" />
        <div v-for="(round, i) in roundData" :key="i" class="text-texts-standard-default data-row">
          <div class="info-row">
            <p>R{{ round.round }}</p>
            <p class="flexing">
              {{ round.medals ?? '-' }}
              <app-main-icon
                :hide-tooltip="true"
                :icon-size="32"
                icon-name="medals"
                class="ml-2 mb-2"
              />
            </p>
          </div>
          <hr v-if="i + 1 !== roundData.length" class="row-fixer" />
        </div>
      </div>
    </template>
  </tippy>
</template>

<script lang="ts">
import type { Round } from '@/interfaces/events/QualificationTable'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

export default defineComponent({
  props: {
    roundData: {
      type: Array as PropType<Round[]>,
      default: () => [],
    },
    medal: {
      type: Number,
      required: true,
    },
    hideTooltip: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.info-icon {
  width: 2.5rem;
  height: 2.5rem;
}

.tooltip-content {
  width: 18rem;
}

.data-row {
  width: 100%;
  height: 3rem;
}

.info-row {
  width: 100%;
  padding: 0;
  display: flex;
  margin: 1rem 0;
  align-items: center;
  justify-content: space-between;
}

.row-fixer {
  border: 1px solid rgb(34, 61, 91);
  width: 100%;
}
</style>
