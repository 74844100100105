<template>
  <section class="tasks-task relative flex justify-between pl-0 pr-10" :class="setBackground">
    <aside class="tasks-task-left w-full">
      <p
        class="tasks-task-left-text w-full text-28 text-texts-standard-default text-left font-bold pr-10 mb-4"
      >
        <!-- Nechavam $t, lebo je to generovane z BE -->
        {{
          $replaceUntranslatablePlaceholder(
            $replacePlaceholder(
              $replacePlaceholder(
                $replacePlaceholder(
                  $replacePlaceholder($t(`event.${task.text}`), '%s', task.target.toString()),
                  '{2to4}',
                  task.target.toString(),
                ),
                '{cash}',
                task.target.toString(),
              ),
              '{discipline}',
              task.discipline ? getValidDiscipline(parseInt(task.discipline.toString())) : '',
            ),
            '{grandprize}',
          )
        }}
      </p>
      <app-progress-bar
        :bar-width="isEventTaskView ? 27.5 : 25"
        :bar-height="1.75"
        :text-size="2.25"
        :actual="task.progress"
        :goal="task.target"
        :counts="true"
        :show-bottom-status="false"
        :bar-background="$isWsm ? '#073c61' : undefined"
        class="task-progress"
      />
    </aside>
    <aside
      class="tasks-task-right font-bold"
      :class="[task.done ? 'flexing' : 'flex flex-col justify-end mb-6']"
    >
      <tasks-skip-button
        v-if="!task.done"
        :amount="task.skip"
        btn-type="verticalColBtn"
        @skip="skipTask"
      />
      <app-icon v-else icon-name="done-lg" />
    </aside>
  </section>
</template>

<script lang="ts">
import type TaskchainStateApiResponse from '@/interfaces/responses/events/TaskchainStateApiResponse'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TasksSkipButton from './TasksSkipButton.vue'
import type { Nullable } from '@/interfaces/utils'

export default defineComponent({
  name: 'TasksBoxHorizontal',
  components: { TasksSkipButton },
  props: {
    task: {
      type: Object as PropType<Nullable<TaskchainStateApiResponse['tasks'][0]>>,
      default: () => null,
    },
  },
  emits: ['skip'],
  computed: {
    taskId(): TaskchainStateApiResponse['tasks'][0]['task'] {
      return this.task.task
    },
    setBackground(): 'done-task' | 'incomplete-task' {
      return this.task.done ? 'done-task' : 'incomplete-task'
    },
    isEventTaskView(): boolean {
      return this.$route.name === this.$getWebView('TasksEvent')
    },
  },
  methods: {
    skipTask(): void {
      this.$emit('skip', { taskId: this.taskId })
    },
    getValidDiscipline(disciplineId: number | 'random'): string {
      if (disciplineId === 'random') {
        console.error('Random dicipline error')
        return 'random'
      }
      // Ponechavam bez $te, lebo tieto su genericke na vsetky appky
      return this.$t(`discipline.discipline_${disciplineId}`)
    },
  },
})
</script>

<style lang="scss">
.tasks-task {
  width: 64.0625rem;
  height: 9.6875rem;

  &-left {
    flex-direction: column;
    display: flex;
    justify-content: space-around;

    &-text {
      width: 18.75rem;
    }

    .task-progress {
      margin-bottom: 1.5rem;
      margin-left: 7rem;
      margin-right: 6rem;
    }
  }

  &-right {
    min-width: 16.875rem;
  }
}

.incomplete-task {
  background: linear-gradient(to right, transparent, #24588e66, transparent);

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 80%;
    height: 0.125rem;
    background: linear-gradient(to right, transparent, #5172b3, transparent);
    left: 50%;
    transform: translate(-50%);
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

.done-task {
  @if $isWsm {
    background-image: linear-gradient(to right, transparent, #30c20e30, transparent);
  }

  @if $isSsm {
    background-image: linear-gradient(
      to right,
      transparent 0%,
      rgba(0, 122, 89, 0.5) 15%,
      rgba(0, 122, 89, 0.5) 85%,
      transparent 100%
    );
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 80%;
    height: 0.125rem;
    left: 50%;
    transform: translate(-50%);

    @if $isWsm {
      background-image: linear-gradient(to right, transparent, #65fa6161, transparent);
    }

    @if $isSsm {
      background-image: linear-gradient(
        to right,
        transparent 0%,
        rgba(14, 231, 204, 0.6) 15%,
        rgba(14, 231, 204, 0.6) 85%,
        transparent 100%
      );
    }
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}
</style>
