<template>
  <div class="fortune-basket-row flex items-center justify-between">
    <div
      class="fortune-basket-row__panel-box flexing"
      :class="{
        'fortune-basket-row__panel-box--completed opacity-50': isCompleted,
      }"
    />
    <div
      class="fortune-basket-row__targets flexing"
      :class="{
        'fortune-basket-row__targets--completed pointer-events-none': isCompleted,
        'opacity-40': isCompleted,
      }"
    >
      <target-item
        v-for="(reward, index) in chunkRewards"
        :key="index"
        :target-data="{
          position: reward.position,
          targetIcon: getRewardIcon(reward),
          targetValue: reward?.value,
          isShot: reward?.claimed,
          isBest: reward?.best,
        }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TargetItem from '@/components/Events/CarnivalStandComponents/FortuneBasket/TargetItem.vue'

import type { PropType } from 'vue'
import type { Reward } from '@/interfaces/responses/events/fortune-basket/FortuneBasketStateApiResponse'

export default defineComponent({
  name: 'FortuneBasketRow',
  components: {
    TargetItem,
  },
  props: {
    chunkRewards: {
      type: Array as PropType<Reward[]>,
      default: null,
    },
  },
  computed: {
    isCompleted(): boolean {
      return false
    },
  },
  methods: {
    getRewardIcon(reward: Reward): string {
      if (reward.type === 'fortune_basket_multiplier') {
        return `fortune_basket_${reward.multiple === 2 ? 'double' : 'triple'}`
      }
      return reward.type
    },
  },
})
</script>

<style lang="scss" scoped>
.fortune-basket-row {
  position: relative;

  &__panel-box {
    position: absolute;
    width: 100%;
    height: 5rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;

    background: linear-gradient(
      to right,
      rgba(37, 46, 75, 0) 0%,
      rgba(37, 46, 75, 0.2) 35%,
      rgba(38, 48, 77, 1) 47.5%,
      rgba(38, 48, 77, 1) 72.5%,
      rgba(37, 46, 75, 0.2) 85%
    );

    &--completed {
      background: linear-gradient(
        to right,
        rgba(37, 46, 75, 0) 0%,
        rgba(14, 116, 105, 0.2) 35%,
        rgba(14, 116, 105, 1) 47.5%,
        rgba(14, 116, 105, 1) 72.5%,
        rgba(14, 116, 105, 0.2) 85%
      );
    }
  }

  &__targets {
    position: relative;
    gap: 2.5rem;
    z-index: 1;
    top: -0.5rem;
  }
}
</style>
