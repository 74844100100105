import type { BuildingsConfigInterface } from '@/map-phaser-new/interfaces'
import {
  arenaConfig,
  mediaConfig,
  clubhouseConfig,
  relaxConfig,
  medicalConfig,
  hotelConfig,
  parkingConfig,
  wellnessConfig,
  swimmingConfig,
  clubChampionshipConfig,
  directorateConfig,
  fitnessConfig,
  restaurantConfig,
} from './specificBuildings'

export const clubsMapBuildings: BuildingsConfigInterface = {
  clubArena: arenaConfig,
  media: mediaConfig,
  clubHouse: clubhouseConfig,
  relax: relaxConfig,
  medical: medicalConfig,
  hotel: hotelConfig,
  parking: parkingConfig,
  wellness: wellnessConfig,
  swimming: swimmingConfig,
  club_championship: clubChampionshipConfig,
  directorate: directorateConfig,
  fitness: fitnessConfig,
  restaurant: restaurantConfig,
}
