<template>
  <div
    v-tippy
    class="reward-box mx-2 flexing flex-col"
    :class="{
      'reward-box--highlighted': highlightedReward,
    }"
  >
    <app-main-icon
      v-if="!isUnlock"
      class="mx-auto reward-icon"
      :class="{ 'reward-icon--ad-scale': isAdContext === true }"
      :icon-size="isAdContext ? adRewardIconSize : rewardIcon"
      :icon-name="rewardIconName"
    />
    <div v-else>
      <tippy theme="WSM" placement="top" max-width="30rem">
        <level-up-rewards-images :icon-name="rewardIconName" :param="paramType" />
        <template #content>
          <div class="level-up-tooltip-title">
            <p class="text-texts-standard-default text-36">
              {{ mainTitle }}
            </p>
          </div>
          <div class="level-up-tooltip-infoText">
            <p class="text-texts-standard-important text-36 uppercase">
              {{ subtitle }}
            </p>
          </div>
          <div class="level-up-tooltip-image flexing">
            <level-up-rewards-images
              :icon-name="rewardIconName"
              :param="paramType"
              :is-tooltip="true"
            />
          </div>
          <div v-if="bottomText">
            <div class="white-strip" />
            <p class="text-texts-standard-default text-30 mt-4">
              {{ bottomText }}
            </p>
          </div>
        </template>
      </tippy>
    </div>
    <div v-if="increaseMax" class="reward-box-new-max">
      <p
        class="text-36 uppercase"
        :class="{
          'text-texts-standard-notImportant': $isWsm,
          'text-texts-standard-default': $isSsm,
        }"
      >
        Max
      </p>
      <div class="up-arrow" />
    </div>
    <div class="reward-box-text font-bold flexing" :class="!isUnlock ? 'text-36' : 'text-28'">
      <p v-if="increaseMax" class="text-texts-standard-important">+</p>
      <p
        class="text-texts-standard-default"
        :class="{
          'reward-box-text-wrap': !increaseMax,
          'text-28 font-normal': isAdContext === true,
          // lvl up discipline reward text
          'reward-box-text--discipline': rewardIconName === 'discipline',
          'reward-box-text--level-up-bags': rewardIconName === 'level-up-bags',
          'reward-box-text--career-gold': rewardIconName === 'career-gold',
          'reward-box-text--building': rewardedBuildings.includes(rewardIconName),
        }"
      >
        <span>{{ rewardValue }}</span>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import LevelUpRewardsImages from '@/components/LevelUp/LevelUpRewardsImages.vue'
import { ARENA, pathImages, TRAINING_HALL } from '@/globalVariables'
import { defineComponent } from 'vue'

interface ComponentData {
  ARENA: typeof ARENA
  pathImages: typeof pathImages
  TRAINING_HALL: typeof TRAINING_HALL
  rewardedBuildings: string[]
}

export default defineComponent({
  name: 'LevelUpRewards',
  components: {
    LevelUpRewardsImages,
  },
  props: {
    highlightedReward: {
      type: Boolean,
      default: false,
    },
    rewardValue: {
      type: String,
      default: null,
    },
    rewardIcon: {
      type: Number,
      default: 120,
    },
    adRewardIconSize: {
      type: Number,
      default: 56,
    },
    rewardIconName: {
      type: String,
      default: null,
    },
    increaseMax: {
      type: Boolean,
      default: false,
    },
    isUnlock: {
      type: Boolean,
      default: false,
    },
    paramType: {
      type: String,
      default: '',
    },
    mainTitle: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    bottomText: {
      type: String,
      default: '',
    },
    isAdContext: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      ARENA,
      pathImages,
      TRAINING_HALL,
      rewardedBuildings: [TRAINING_HALL, ARENA],
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/level-up/rewards.scss';
</style>
