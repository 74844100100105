<template>
  <div
    v-if="!isContentLoading"
    class="main-duel-main-rightbox bordered-lg"
    :class="{ 'is-event-view': isEventView }"
    :style="{
      background:
        'url(' + pathImages + 'disciplines/' + activeDisciplineId + '-arena.avif) center no-repeat',
      backgroundSize: 'cover',
    }"
  >
    <event-discipline-badge
      :active-discipline-id="activeDisciplineId"
      :is-event-view="isEventView"
    />
    <div class="main-duel-main-rightbox-header flex items-center">
      <div class="main-duel-main-rightbox-header-logo">
        <app-discipline-icon :discipline-id="activeDisciplineId" />
      </div>
      <div class="main-duel-main-rightbox-header-info">
        <div class="main-duel-main-rightbox-header-info-title text-texts-standard-additional">
          {{ $translateDiscipline(activeDisciplineId) }}
        </div>
        <div class="main-duel-main-rightbox-header-info-text">
          {{ $t('common.personalBest') + ':' }}
          <span class="text-texts-standard-important">
            {{
              formatResult(activeDiscipline.mechanicStats?.bestScore, activeDiscipline.id) ?? '-'
            }}
          </span>
        </div>
      </div>
      <div class="main-duel-main-rightbox-header-ranking flexing">
        <span
          class="main-duel-main-rightbox-header-ranking-text text-texts-standard-additional text-28 uppercase font-bold"
        >
          <short-text
            class="default-font-size"
            :text="`${$t('rankingsGroup.rankingPoints')}:`"
            first-custom="text-24"
          />
        </span>
        <div class="main-duel-main-rightbox-header-ranking-points flexing">
          <div class="main-duel-main-rightbox-header-ranking-points-count">
            <p class="text-texts-standard-default text-60 font-bold">
              {{ activeDiscipline.userStats?.rankingPoints }}
            </p>
          </div>
          <app-main-icon :icon-size="48" icon-name="ranking_points" />
        </div>
      </div>
    </div>

    <div class="main-duel-main-rightbox-footer absolute">
      <reward-icon class="absolute" :position="1" />
      <div class="main-duel-main-rightbox-footer-header">
        <div
          v-if="activeDiscipline.rewards"
          class="main-duel-main-rightbox-footer-header-rewards flexing relative"
        >
          <p class="text-34 text-texts-standard-default">{{ isGroupB ? '' : '(' }}</p>
          <template v-for="reward in activeDiscipline.rewards[0]" :key="reward.name">
            <span class="flex relative mx-4">
              <p class="text-34 text-texts-standard-default">
                {{ reward?.value }}
              </p>
              <app-icon
                v-if="reward.name === EVENT_REPUTATION"
                :icon-name="reward.name"
                :tooltip-text="$te('reputation')"
                class="icon-in-arena ml-2"
              />
              <app-main-icon v-else :icon-size="48" :icon-name="reward.name" />
              <not-enough-benefits-for-live
                v-if="reward.name === MONEY && !enoughCashBenefits && !isGroupB"
                type="cash"
                :discipline-id="activeDisciplineId"
                :live-value="
                  activeDiscipline?.rewardsWithoutBenefit[0].find(
                    (reward: Reward): boolean => reward.name === MONEY,
                  )?.value
                "
                :simulation-value="reward?.value"
              />
            </span>
          </template>
          <p v-if="!isGroupB" class="text-34 text-texts-standard-default flexing">
            ) x <app-main-icon :icon-size="48" icon-name="starts" />
          </p>
        </div>
        <app-icon
          icon-name="question-60"
          class="icon-info main-duel-main-rightbox-footer-header-info"
          @click="showRewardsPopUp = true"
        />
        <info-popup
          v-if="showRewardsPopUp"
          :popup-title="$t('common.allRewards')"
          @close="showRewardsPopUp = false"
        >
          <arena-rewards
            :discipline-id="activeDisciplineId"
            :discipline-title="$translateDiscipline(activeDisciplineId)"
            :rewards="activeDiscipline.rewards"
            :rewards-base="!enoughCashBenefits ? activeDiscipline.rewardsWithoutBenefit : null"
            :starts-base-number="baseStartsCost"
            :starts-live-number="liveStartsCost"
          />
        </info-popup>
      </div>
      <div class="main-duel-main-rightbox-footer-header-buttons flexing">
        <arrow-animation
          v-if="!(isGroupB && !isTutorial)"
          :tooltip-content="$t('tutorial.firstDuelLiveInfo')"
          position="right"
          border="button"
          :tutorial="isTutorialLiveDuelClick"
        >
          <app-multi-button
            v-tippy="getTippyContent(true)"
            btn-id="arena-start-live"
            btn-type="secondary"
            :btn-text="$t('button.start')"
            btn-size="sm"
            multi-width="25.125rem"
            class="inline-flex align-top"
            :class="{ 'button-anim': isTutorialLiveDuelClick }"
            :disabled="disabledActionButton"
            :loading="isMinigame || isButtonDisabled === PlayButtonLock.Live"
            :is-cool-down="isCoolDown"
            @click="isCoolDown ? null : onButtonClick(true)"
          >
            <template #rightPart>
              <span class="text-texts-standard-default">
                {{ liveStartsCost }}
              </span>
              <app-main-icon :icon-size="48" :icon-name="'starts'" />
            </template>
          </app-multi-button>
        </arrow-animation>
        <arrow-animation
          :tooltip-content="$t('tutorial.firstDuelSimInfo')"
          position="right"
          border="button"
          :tutorial="isTutorialSimulationDuelClick"
        >
          <app-multi-button
            v-tippy="getTippyContent(false)"
            btn-id="arena-start-simulation"
            btn-type="primary"
            :btn-text="$t('button.simulate')"
            btn-size="sm"
            multi-width="25.125rem"
            class="inline-flex align-top"
            :class="{ 'button-anim': isTutorialSimulationDuelClick }"
            :disabled="disabledActionButton"
            :loading="isSimulation || isButtonDisabled === PlayButtonLock.Simulation"
            @click="isSimulation ? null : onButtonClick(false)"
          >
            <template #rightPart>
              <span class="text-texts-standard-default">
                {{ baseStartsCost }}
              </span>
              <app-main-icon :icon-size="48" :icon-name="'starts'" />
            </template>
          </app-multi-button>
        </arrow-animation>
      </div>
      <parameter-popup v-if="isPopupOpen" :parameter="STARTS" @close="isPopupOpen = false" />
    </div>
  </div>

  <component-loading :is-loading="isContentLoading" width="55.625rem" type="secondary" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import {
  CASH,
  EVENT_REPUTATION,
  MONEY,
  Multiplier,
  RANKING_POINTS,
  STARTS,
  pathImages,
} from '@/globalVariables'
import { formatResult, rewardIconsMapping } from '@/helpers'
import ArenaRewards from '@/components/Arena/ArenaRewards.vue'
import RewardIcon from '@/components/Arena/RewardIcon.vue'
import ArrowAnimation from '@/components/ArrowAnimation.vue'
import NotEnoughBenefitsForLive from '@/components/Benefits/NotEnoughBenefitsForLive.vue'
import EventDisciplineBadge from '@/components/Events/EventDisciplineBadge.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import ShortText from '@/components/ShortText.vue'
import { useDuelStore } from '@/store/pinia/arena/duelStore'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { PlayButtonLock } from '@/interfaces/Buttons'
import { ApiService } from '@/services/ApiService'
import ParameterPopup from '@/components/Popup/Parameter/ParameterPopup.vue'

import type { PropType } from 'vue'
import type { Slots } from '../ArenaSharedInterface'
import type { Nullable } from '@/interfaces/utils'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type Reward from '@/interfaces/Reward'
import type { BenefitSlot } from '@/interfaces/Benefits'

interface ComponentData {
  EVENT_REPUTATION: typeof EVENT_REPUTATION
  isButtonDisabled: PlayButtonLock
  isPopupOpen: boolean
  MONEY: typeof MONEY
  pathImages: typeof pathImages
  PlayButtonLock: typeof PlayButtonLock
  RANKING_POINTS: typeof RANKING_POINTS
  showRewardsPopUp: boolean
  STARTS: typeof STARTS
}

export default defineComponent({
  name: 'ArenaCompetitionsDisciplineDetail',
  components: {
    ArenaRewards,
    RewardIcon,
    ShortText,
    InfoPopup,
    ArrowAnimation,
    NotEnoughBenefitsForLive,
    EventDisciplineBadge,
    ParameterPopup,
  },
  props: {
    activeDisciplineId: {
      type: Number,
      default: 1,
      required: true,
    },
    benefitsSlots: {
      type: Object as PropType<Nullable<BenefitSlot['slots']>>,
      default: () => null,
    },
    isEventView: {
      type: Boolean,
      default: false,
    },
    multiplierContext: {
      type: String as PropType<Multiplier>,
      default: Multiplier.Arena,
      validator: (value: string): boolean => {
        return Object.values(Multiplier).includes(value as Multiplier)
      },
    },
  },
  emits: ['playDuel'],
  data(): ComponentData {
    return {
      EVENT_REPUTATION,
      isButtonDisabled: PlayButtonLock.Unset,
      isPopupOpen: false,
      MONEY,
      pathImages,
      PlayButtonLock,
      RANKING_POINTS,
      showRewardsPopUp: false,
      STARTS,
    }
  },
  computed: {
    ...mapState(useDuelStore, {
      startsCostConfig: 'getStartsCostConfig',
    }),
    ...mapState(useUserStore, {
      activeMultiplicator: 'getActiveMultiplicator',
      isGroupB: 'isGroupB',
      isGroupCD: 'isGroupCD',
    }),
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useCoreStore, {
      isSimulation: 'isSimulation',
      isMinigame: 'isMinigame',
    }),
    ...mapState(useResponseTaskStore, {
      userStarts: 'getStarts',
      getCoolDown: 'getCoolDown',
    }),
    ...mapState(useDisciplineStore, {
      activeDiscipline: 'getSpecificDiscipline',
    }),
    enoughCashBenefits(): boolean {
      if (!this.benefitsSlots) return true
      for (const value of Object.values(this.benefitsSlots)) {
        if (value) {
          const filteredSlot = Object.values(value).find(
            (slot: Slots[0]): boolean => slot?.benefit_type === CASH,
          )
          if (filteredSlot) {
            return (
              Number(filteredSlot?.amount) >= this.liveStartsCost ||
              this.baseStartsCost > Number(filteredSlot.amount)
            )
          }
        }
      }
      return true
    },
    liveStartsCost(): number {
      return this.startsCostConfig.live * this.activeMultiplicator(this.multiplierContext).value
    },
    baseStartsCost(): number {
      return this.startsCostConfig.base * this.activeMultiplicator(this.multiplierContext).value
    },
    isContentLoading(): boolean {
      return this.activeDiscipline == null || this.activeDiscipline.rewards == null
    },

    isTutorialLiveDuelClick(): boolean {
      return this.actualStageData?.name === 'returnFromLiveDuel'
    },
    isTutorialSimulationDuelClick(): boolean {
      return this.actualStageData?.secondStage === 'simulationDuelClick'
    },
    isCoolDown(): boolean {
      return this.isGroupCD && this.getCoolDown > 0
    },
    disabledActionButton(): boolean {
      // Final trick is for initial tutorial specifics In case any
      // other tutorial will make people loose starts this has to be amended
      return (
        this.isSimulation ||
        this.isButtonDisabled !== PlayButtonLock.Unset ||
        (this.isTutorial && !this.isTutorialSimulationDuelClick)
      )
    },
  },
  watch: {
    isMinigame(newVal: boolean): void {
      if (newVal) return
      this.isButtonDisabled = PlayButtonLock.Unset
    },
    isSimulation(newVal: boolean): void {
      if (newVal) return
      this.isButtonDisabled = PlayButtonLock.Unset
    },
  },
  methods: {
    rewardIconsMapping,
    formatResult,
    onButtonClick(isLive: boolean): void {
      if (this.isPlayDisabled(isLive)) {
        this.isPopupOpen = true
        return
      }

      if (this.isButtonDisabled !== PlayButtonLock.Unset || this.isSimulation || this.isMinigame)
        return

      if (!this.isPlayDisabled(isLive))
        this.isButtonDisabled = isLive ? PlayButtonLock.Live : PlayButtonLock.Simulation

      ApiService.$clear()
      this.$debounce((): void => {
        this.$emit('playDuel', isLive)
      })
    },
    isPlayDisabled(isLive: boolean): boolean {
      if (isLive) {
        return this.userStarts.value < this.liveStartsCost
      }
      return this.userStarts.value < this.baseStartsCost
    },
    getTippyContent(isLive: boolean): {
      theme: string
      content: string
      placement: string
      textAlign: string
      appendTo: string
    } {
      let content = this.isPlayDisabled(isLive) ? this.$t('arena.notEnoughStarts') : ''
      if (isLive && !content.length && this.isCoolDown) {
        content = this.$t('common.cooldown')
      }
      return {
        theme: 'WSM',
        content: content,
        placement: 'top',
        textAlign: 'center',
        appendTo: 'parent',
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.main-duel-main-rightbox {
  &.is-event-view {
    border-style: solid;
    border-width: 0.125rem;
    border-image-source: linear-gradient(to top, #5ba3dc, #bfe3ff, #5ba3dc);
    border-image-slice: 1;
    background-color: #236d9b;
  }
}
</style>
