<template>
  <div id="main-layout" class="w-screen h-screen absolute main-layout-wrapper">
    <clubs-map-layout v-if="isClubSceneRoute" />
    <main-map-layout v-else />
    <hamburger-menu
      :show="displayHamburger"
      @toggle-hamburger-menu="$emit('toggleHamburgerMenu')"
      @hide-hamburger-menu="$emit('hideHamburgerMenu')"
    />
    <web-window-component />
  </div>
</template>

<script lang="ts">
import HamburgerMenu from '@/components/HamburgerMenu.vue'
import WebWindowComponent from '@/components/Layout/WebWindowComponent.vue'
import { MECHANIC_CAREER } from '@/globalVariables'
import ClubsMapLayout from '@/views/layouts/ClubsMapLayout.vue'
import MainMapLayout from '@/views/layouts/MainMapLayout.vue'
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { SCENES_KEYS } from '@/map-phaser-new/constants'

interface ComponentData {
  showAttribute: boolean
  MECHANIC_CAREER: typeof MECHANIC_CAREER
}

export default defineComponent({
  name: 'LayoutView',
  components: {
    HamburgerMenu,
    WebWindowComponent,
    MainMapLayout,
    ClubsMapLayout,
  },
  props: {
    displayHamburger: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['toggleHamburgerMenu', 'hideHamburgerMenu'],
  data(): ComponentData {
    return {
      showAttribute: false,
      MECHANIC_CAREER,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      unlockedMechanics: 'getMechanics',
    }),
    isUnlockedMechanicsLoaded() {
      return this.unlockedMechanics?.length > 0
    },
    isClubSceneRoute(): boolean {
      return this.$route.meta.activeScene === SCENES_KEYS.ClubsMapScene
    },
  },
})
</script>
<style lang="scss">
#main-layout {
  pointer-events: none;
  & > * {
    pointer-events: all;
  }
}
</style>
