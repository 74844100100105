<template>
  <div class="equipment-items">
    <app-scrollbar
      v-if="!isContentLoading"
      scroll="y"
      slide="y"
      width="100%"
      height="100%"
      class="flex justify-center"
    >
      <div
        class="equipment-items-scroll relative"
        :class="{ 'equipment-items-scroll--mobile': $isMobile() }"
      >
        <equipment-items-boxes :items="items" @finish-operation="$emit('finishOperation')" />
      </div>
    </app-scrollbar>
    <component-loading :is-loading="isContentLoading" />
  </div>
</template>

<script lang="ts">
import EquipmentItemsBoxes from '@/components/Equipment/EquipmentItemsBoxes.vue'
import type { NormalizedItemData } from '@/interfaces/Equipment'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'EquipmentItems',
  components: {
    EquipmentItemsBoxes,
  },
  props: {
    items: {
      type: Array as PropType<NormalizedItemData[]>,
      required: true,
    },
  },
  emits: ['finishOperation'],
  computed: {
    isContentLoading(): boolean {
      return this.items === null
    },
  },
})
</script>

<style lang="scss" scoped>
.equipment-items {
  display: flex;
  width: 96%;
  margin: 0 auto;
  margin-top: 1rem;
  align-items: center;
  flex: 1 1 0;
  min-height: 0;

  .equipment-items-scroll {
    width: 117rem;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    &::after {
      content: '';
      // Important: Minimum relative height for fixing cut off flex margin from last inner children
      position: relative;
      height: 0.5rem;
      width: 100%;
      top: 0;
      left: 0;
      background: transparent;
    }

    &--mobile {
      gap: 2.125rem;
      justify-content: center;
    }
  }
}

#item-box-unlocked {
  * {
    pointer-events: none;
  }
}
</style>
