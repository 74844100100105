<template>
  <div class="country-select flexing">
    <a
      class="clickable-element"
      :href="'https://www.powerplay.studio/' + languageCode + '/license/'"
      target="_blank"
    >
      <p class="country-select-text text-texts-standard-additional text-32">
        {{ $t('common.conditions') }}
      </p>
    </a>
    <app-select
      v-if="!$isMobile()"
      :options="formattedCountries"
      :default="selectedCountry"
      class="selectbox"
      @select="selectCountry"
    />
  </div>
</template>

<script lang="ts">
import { userProfileEndpoint } from '@/globalVariables'
import { getIsMobileLocalStorage } from '@/helpers'
import { useCountriesStore } from '@/store/pinia/countriesStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { UserProfile } from '@/types/userProfile'

interface ComponentData {
  selectedCountry: string
}

export default defineComponent({
  name: 'PremiumCountrySelect',
  emits: ['selectCountry'],
  data(): ComponentData {
    return {
      selectedCountry: '',
    }
  },
  computed: {
    ...mapState(useCountriesStore, {
      formattedCountries: 'getFormattedCountries',
    }),
    ...mapState(useUserStore, {
      userLanguage: 'getLanguage',
      usersCountryPayment: 'getCountryPayment',
    }),
    languageCode(): string {
      return this.userLanguage === 'sk' || this.userLanguage === 'cs' ? 'sk' : 'en'
    },
  },
  created(): void {
    this.selectedCountry = localStorage.getItem('country_payment') ?? this.usersCountryPayment
  },
  methods: {
    async selectCountry(country: string): Promise<void> {
      if (this.selectedCountry === country) return

      if (getIsMobileLocalStorage() === 0) {
        await this.$axios.post<
          {},
          Omit<UserProfile, 'group' | 'multiplicators'>,
          { profile_attributes: [{ name: string; value: string }] }
        >(userProfileEndpoint, {
          profile_attributes: [
            {
              name: 'country_payment',
              value: country,
            },
          ],
        })

        localStorage.setItem('country_payment', country)
      }

      this.selectedCountry = country
      this.$emit('selectCountry', this.selectedCountry)
    },
  },
})
</script>

<style lang="scss" scoped>
.country-select {
  right: 2rem;
  top: 6rem;

  &-text {
    text-decoration: underline;
    margin-right: 2.4375rem;
  }

  &-payment {
    margin-right: 2.4375rem;
  }
}
</style>
