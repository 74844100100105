<template>
  <div
    class="milestone-column h-full flex flex-col flex-shrink-0 justify-center items-center text-texts-standard-default text-60 relative"
    :class="{ 'is-extended': isFirstMilestone }"
  >
    <div
      v-if="isFirstMilestone"
      class="rewards-basic flex flex-col justify-center items-center text-40 font-bold uppercase relative top-4"
    >
      <p class="gradient-text-light">{{ $t('tournaments.baseRewards') }}</p>
    </div>
    <section
      v-else
      class="milestone-reward-wrapper w-full flex flex-col justify-center items-center relative z-1"
    >
      <milestones-reward
        :milestone-id="milestone.individual.milestoneId"
        :state="milestone.state"
        :reward="milestone.individual.reward"
        :is-claimed="milestone.individual.claimed"
        :is-repeatable="milestone.repeatable"
      />
    </section>
    <tippy
      class="info-button flex flex-col justify-center items-center my-4"
      :class="{ 'relative top-7': isFirstMilestone }"
      theme="WSM1"
      placement="top"
      max-width="38.125rem"
    >
      <div
        class="milestone-indicator flexing text-texts-standard-default text-60 relative z-2"
        :class="[
          `is-${milestone.state}`,
          {
            'top-0': isFirstMilestone,
            'is-active': isActiveMilestone,
            'is-locked relative': milestone.state === MilestoneState.Locked,
            'is-repeatable relative': milestone.repeatable,
          },
        ]"
      >
        <p v-if="!milestone.repeatable">{{ milestone.milestone }}</p>
        <app-icon
          v-if="milestone.state === MilestoneState.Locked"
          icon-name="lock-sm"
          class="absolute -right-1 bottom-2"
        />
      </div>
      <template v-if="milestone.points" #content>
        <div class="lock-tooltip">
          <div class="lock-tooltip-title flexing">
            <p class="uppercase font-bold text-30">{{ $t('tournaments.tournamentPoints') }}</p>
          </div>
          <div class="lock-tooltip-text-margin flexing text-28 space-x-2">
            <p>{{ $filters.$formatNumber(milestone.points) }}</p>
            <app-main-icon :icon-size="56" :icon-name="TOURNAMENT_POINTS" />
          </div>
        </div>
      </template>
    </tippy>
    <div
      v-if="isFirstMilestone"
      class="rewards-pass flex flex-col justify-center items-center pt-2 text-40 font-bold uppercase relative"
    >
      <section
        v-if="!isTournamentsPassActive"
        class="buy-pass flex flex-col justify-center items-center text-36 uppercase cursor-pointer"
        @click="onTournamentPassClick"
      >
        {{ $t('tournaments.buy') }}
      </section>
      <p class="gradient-text-light" :class="{ 'is-pass-active': isTournamentsPassActive }">
        {{ $t('tournaments.tournamentPass') }}
      </p>
    </div>
    <section
      v-else
      class="milestone-reward-wrapper w-full flex flex-col justify-center items-center relative z-1"
    >
      <milestones-reward
        :milestone-id="milestone.premium.milestoneId"
        :state="milestone.state"
        :reward="milestone.premium.reward"
        :is-claimed="milestone.premium.claimed"
        :is-pass-reward="true"
        :is-repeatable="milestone.repeatable"
      />
    </section>
    <div
      v-if="!isLastMilestone"
      class="progress-bar flex flex-row justify-start items-center absolute z-1"
      :class="{
        'is-extended': isFirstMilestone,
        'is-repeatable justify-center': nextMilestone?.repeatable,
      }"
    >
      <div
        class="progress-bar-fill relative"
        :class="{
          'progress-bar-fill-end': currentProgress > 0 && currentProgress < 100,
        }"
        :style="{ width: !nextMilestone?.repeatable ? `${currentProgress * 100}%` : undefined }"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { TOURNAMENT_PASS, TOURNAMENT_POINTS } from '@/globalVariables'
import { isTournamentPass } from '@/helpers'
import MilestonesReward from './MilestonesReward.vue'
import { useTournamentsMilestonesStore } from '@/store/pinia/tournaments/useTournamentsMilestonesStore'
import { usePremiumStore, type OfferInterface } from '@/store/pinia/premiumStore'
import {
  MilestoneState,
  type Milestone,
  type MilestoneProgress,
} from '@/interfaces/responses/tournaments/TournamentsMilestonesBoardGamesEventStateApiResponse'
import { defineComponent, type PropType } from 'vue'
import { mapActions, mapState } from 'pinia'

interface ComponentData {
  MilestoneState: typeof MilestoneState
  TOURNAMENT_POINTS: typeof TOURNAMENT_POINTS
}

export default defineComponent({
  components: { MilestonesReward },
  props: {
    milestone: {
      type: Object as PropType<Milestone>,
      required: true,
    },
    progress: {
      type: Object as PropType<MilestoneProgress | null>,
      default: null,
    },
    nextMilestone: {
      type: Object as PropType<Milestone | null>,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      MilestoneState,
      TOURNAMENT_POINTS,
    }
  },
  computed: {
    ...mapState(useTournamentsMilestonesStore, ['lastMilestone', 'isTournamentsPassActive']),
    ...mapState(usePremiumStore, {
      rightboxes: 'getAllRightBoxes',
    }),
    isFirstMilestone(): boolean {
      return this.milestone.milestone === 0
    },
    isLastMilestone(): boolean {
      return this.milestone.milestone === this.lastMilestone
    },
    isActiveMilestone(): boolean {
      return this.milestone.milestone === this.progress?.milestone
    },
    currentProgress(): number {
      if (!this.progress || !this.milestone || this.milestone.state !== MilestoneState.Open)
        return 0
      if (this.progress.milestone > this.milestone.milestone) return 100

      const { points: previousPoints } = this.milestone
      const { points: currentPoints, next_points: nextPoints } = this.progress

      return (currentPoints - previousPoints) / (nextPoints - previousPoints)
    },
  },
  methods: {
    ...mapActions(usePremiumStore, ['showOfferDetail']),
    onTournamentPassClick(): void {
      const offer = this.rightboxes.find(
        (box: OfferInterface): boolean =>
          box.type.includes(TOURNAMENT_PASS) &&
          isTournamentPass({ store_id: box.content.store_id }),
      )

      if (offer) {
        this.showOfferDetail({ offerData: offer.content })
        return
      }

      this.$router.push({ name: this.$getWebView('PremiumOffers') })
    },
  },
})
</script>

<style lang="scss" scoped>
.milestone-column {
  width: 14.188rem;

  &.is-extended {
    width: 17rem;
  }

  &:nth-child(odd):not(:first-child) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 50%;
    }

    &::before {
      top: 0;
      background-image: linear-gradient(
        to top,
        rgba(52, 63, 105, 0.6) 0%,
        rgba(52, 63, 105, 0) 100%
      );
    }

    &::after {
      bottom: 0;
      background-image: linear-gradient(
        to top,
        rgba(222, 133, 24, 0.3) 30%,
        rgba(222, 133, 24, 0) 100%
      );
    }
  }
}

.milestone-reward-wrapper {
  height: 12.5rem;
}

.milestone-indicator {
  height: 6.875rem;
  width: 6.875rem;
  @include background(
    url('#{$path-images}/tournaments/milestones/milestone-default.avif?v1'),
    contain
  );

  &.is-active {
    background-image: url('#{$path-images}/tournaments/milestones/milestone-active.avif?v1');
  }

  &.is-locked,
  &.is-repeatable {
    background-image: url('#{$path-images}/tournaments/milestones/milestone-locked.avif?v1');
  }

  &.is-repeatable {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% + 0.125rem), calc(-50% + 0.125rem));
      width: 3.625rem;
      height: 3.688rem;
      @include background(
        url('#{$path-images}/tournaments/milestones/ico-repeat.avif?v1'),
        contain
      );
    }
  }
}

.progress-bar {
  top: 50%;
  left: 9.5rem;
  transform: translateY(-50%);
  width: 9.5rem;
  height: 7.5rem;
  @include background(
    url('#{$path-images}/tournaments/milestones/progress-bg.avif?v1'),
    contain,
    center,
    repeat-x
  );

  &.is-extended {
    width: 13.5rem;
  }

  &.is-repeatable {
    background-image: none;

    .progress-bar-fill {
      width: 4.438rem;
      height: 4.75rem;
      @include background(
        url('#{$path-events}shared/milestones/milestones-repeatable-arrow.webp'),
        contain
      );

      &-end::after {
        display: none;
      }
    }
  }

  &-fill {
    height: 7.5rem;
    @include background(
      url('#{$path-images}/tournaments/milestones/progress-fill-bg.avif?v1'),
      contain,
      center,
      repeat-x
    );

    &.progress-bar-fill-end::after {
      content: '';
      position: absolute;
      right: 0;
      width: 0.625rem;
      height: 7.5rem;
      @include background(
        url('#{$path-images}/tournaments/milestones/progress-fill-end-bg.avif?v1'),
        contain,
        center,
        repeat-x
      );
    }
  }
}

.rewards-basic {
  @include background(
    url('#{$path-images}/tournaments/milestones/rewards-basic-bg.avif?v1'),
    contain
  );
  width: 16.25rem;
  height: 11rem;

  p {
    line-height: 1.3;
  }
}

.rewards-pass {
  @include background(
    url('#{$path-images}/tournaments/milestones/rewards-pass-bg.avif?v1'),
    contain
  );
  width: 16.5rem;
  height: 14.563rem;

  p {
    line-height: 1.2;

    &.is-pass-active {
      margin-top: 2rem;
    }
  }

  .buy-pass {
    @include background(url('#{$path-images}/tournaments/milestones/buy-pass-bg.avif?v1'), contain);
    width: 16.438rem;
    height: 4.5rem;
  }
}
</style>
