<template>
  <div class="toggle-switch" :style="{ width: width + 'rem', height: height + 'rem' }">
    <div
      v-for="i in 2"
      :key="i"
      class="text-36 toggle-switch-choice"
      :class="{ active: choice === i }"
      v-on="choice === i ? {} : { click: () => chooseOption(i) }"
    >
      <div :class="{ 'revert-skew': currentGame === GameType.Winter }">{{ texts[i - 1] }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { GameType } from '@/globalVariables/gameTypes'

interface ComponentData {
  choice: number
  GameType: typeof GameType
  currentGame: string
}

export default defineComponent({
  name: 'AppToggle',
  props: {
    width: {
      type: Number,
      default: 32.625,
    },
    height: {
      type: Number,
      default: 4.375,
    },
    texts: {
      type: Array,
      default: () => ['Yes', 'No'],
      validator(value: string[]): boolean {
        return value.length === 2
      },
    },
    selectedButton: {
      type: Number,
      default: 2,
    },
  },
  emits: ['action'],
  data(): ComponentData {
    return {
      choice: 2,
      GameType,
      currentGame: this.$gameName,
    }
  },
  created(): void {
    this.choice = this.selectedButton
  },
  methods: {
    chooseOption(option: number): void {
      this.choice = option
      this.$emit('action', option)
    },
  },
})
</script>

<style lang="scss">
.toggle-switch {
  width: 32.625rem;
  height: 4.375rem;
  transform: $skewX-value;
  cursor: pointer;
  border-style: solid;
  border-width: 0.125rem;
  @if $isWsm {
    background-image: linear-gradient(10deg, rgb(71, 135, 180) 0%, rgb(43, 78, 103) 100%);
    border-color: rgb(82, 142, 191);
  }
  @if $isSsm {
    background-color: #22222c;
    border-color: #010101;
  }

  &-choice {
    width: 50%;
    height: 100%;
    display: inline-block;
    line-height: 4.313rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    @if $isSsm {
      font-weight: 500;
    }

    &.active {
      cursor: default;
      color: #233441;
      @if $isWsm {
        background-image: linear-gradient(
          4deg,
          rgb(198, 233, 255) 0%,
          rgba(204, 239, 255, 0.7) 100%
        );
      }
      @if $isSsm {
        background-image: linear-gradient(to right, #d7d7d7 0%, #fff 100%);
      }
    }
  }
}
</style>
