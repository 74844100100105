<template>
  <div
    v-if="ranking"
    class="tournaments-ranking-panel flexing absolute cursor-pointer"
    @click="
      $router.push({
        name: $getWebView('TournamentsRankingsPoints'),
        query: { period: 'yesterday' },
      })
    "
  >
    <div class="image relative">
      <p class="time flexing text-24 text-texts-standard-default absolute">
        {{ getFormattedDateFromYMD(date) }}
      </p>
    </div>
    <div class="ranking h-full flex flex-col justify-evenly relative">
      <p class="title text-28 font-bold uppercase absolute">
        {{ $t('tournaments.topTournamentPlayers') }}
      </p>
      <div v-for="(user, i) in ranking" :key="user.id" class="user w-full flex flex-row pr-2">
        <p
          class="user-position flex-shrink-0 h-full text-22 font-bold"
          :class="[
            `is-rank-${i + 1}`,
            {
              'pt-0.5': $isWsm,
              'pt-1': $isSsm,
            },
          ]"
        >
          {{ i + 1 }}
        </p>
        <app-user
          :id="user.id"
          class="user-name h-full pointer-events-none"
          :country="user.country"
          :name="user.username"
          :badges-data="user.eventBadges ?? null"
        />
        <p
          class="user-points flex flex-row items-center h-full text-22 text-texts-standard-default ml-auto"
          :class="{
            'pt-0.5': $isWsm,
            'pt-1': $isSsm,
          }"
        >
          {{ $filters.$formatNumber(user.tournament_points) }}
          <app-main-icon icon-name="tournament_points" :icon-size="32" />
        </p>
      </div>
    </div>
    <app-icon
      icon-name="tournament-btn-close"
      class="btn-close clickable-element cursor-pointer absolute"
      @click.stop="$emit('close')"
    />
  </div>
</template>

<script lang="ts">
import { tournamentsRankingsStateEndpoint as TOURNAMENTS_RANKINGS_STATE_ENDPOINT } from '@/globalVariables'
import { getFormattedDateFromYMD } from '@/helpers'
import AppUser from '../GlobalComponents/AppUser.vue'
import { RankingType, type RankingUser } from '@/interfaces/RankingsInterfaces'
import type { TournamentsRankingsStateApiResponse } from '@/interfaces/responses/tournaments/TournamentsRankingsStateApiResponse'
import { ApiService } from '@/services/ApiService'
import { defineComponent } from 'vue'

const YESTERDAY_DATE = new Date()
YESTERDAY_DATE.setDate(YESTERDAY_DATE.getDate() - 1)

interface RankingPayload {
  type: string
  days: number
  limit: number
}

interface ComponentData {
  RankingType: typeof RankingType
  date: Date
  ranking: RankingUser[] | null
  isLoading: boolean
}

export default defineComponent({
  components: { AppUser },
  emits: ['close'],
  data: (): ComponentData => ({
    RankingType,
    date: YESTERDAY_DATE,
    ranking: null,
    isLoading: false,
  }),
  async created(): Promise<void> {
    await this.loadRanking()
  },
  methods: {
    getFormattedDateFromYMD,
    async loadRanking(): Promise<void> {
      this.isLoading = true
      try {
        const response =
          (
            await ApiService.post<TournamentsRankingsStateApiResponse, RankingPayload>(
              TOURNAMENTS_RANKINGS_STATE_ENDPOINT,
              {
                type: 'points',
                days: 1,
                limit: 3,
              },
              { force: true },
            )
          )?.ranking ?? null

        if (!response || !Object.keys(response).length) return this.$emit('close')

        delete response.player

        this.ranking = Object.entries(response).map(
          ([id, entry]: [string, RankingUser], index: number): RankingUser => ({
            position: index + 1,
            id,
            ...entry,
          }),
        )
      } catch (error: unknown) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.tournaments-ranking-panel {
  width: 73.75rem;
  height: 10.688rem;
  bottom: 8rem;
  border-style: solid;
  border-width: 0.125rem;
  border-image-slice: 1;
  transition: height 0.5s;

  @if $isWsm {
    border-image-source: linear-gradient(to right, transparent, #528ebf, #528ebf, transparent);
    background-image: linear-gradient(
      to right,
      transparent,
      rgba(38, 74, 100, 0.85),
      rgba(38, 74, 100, 0.85),
      transparent
    );
  }

  @if $isSsm {
    border-image-source: linear-gradient(to right, transparent, #586b9d, #586b9d, transparent);
    background-image: linear-gradient(
      to right,
      transparent,
      rgba(52, 65, 93, 0.85),
      rgba(52, 65, 93, 0.85),
      transparent
    );
  }

  .image {
    top: -1.5rem;
    @include background(
      url('#{$path-images}/tournaments/ui-ranking-panel/cup-medium.avif'),
      contain
    );
    width: 7.125rem;
    height: 10.875rem;

    .time {
      bottom: -1rem;
      left: 50%;
      transform: translateX(-50%);
      width: 11.25rem;
      height: 1.75rem;
      background-image: linear-gradient(
        to right,
        transparent,
        rgba(35, 40, 58, 0.9),
        rgba(35, 40, 58, 0.9),
        transparent
      );
    }
  }

  .ranking {
    margin-left: 2.5rem;

    .title {
      width: 33.75rem;
      height: 2.5rem;
      top: -2.75rem;
      left: 50%;
      transform: translateX(-50%);
      color: #333435;
      background-image: linear-gradient(
        to right,
        transparent,
        rgba(208, 188, 114, 0.8),
        #e0c572,
        #c7a655,
        rgba(169, 134, 53, 0.8),
        transparent
      );
      border-style: solid;
      border-width: 0.125rem;
      border-image-source: linear-gradient(
        to right,
        transparent,
        rgba(244, 233, 167, 0.7),
        rgba(244, 233, 167, 1),
        rgba(244, 233, 167, 0.7),
        transparent
      );
      border-image-slice: 1;
    }

    .user {
      width: 27.5rem;
      height: 2.5rem;

      &:nth-child(even) {
        @if $isWsm {
          border: solid 0.066rem #6c9ec4;
          background-color: #236d9b;
        }

        @if $isSsm {
          background-image: linear-gradient(
            to right,
            rgba(74, 75, 117, 0.95),
            rgba(81, 78, 123, 0.95),
            rgba(74, 75, 117, 0.95)
          );
        }
      }

      &:nth-child(odd) {
        @if $isWsm {
          border: solid 0.066rem #5883a3;
          background-color: #417491;
        }

        @if $isSsm {
          background-image: linear-gradient(
            to right,
            rgba(74, 75, 117, 0.95),
            rgba(114, 114, 176, 0.7 * 0.95),
            rgba(74, 75, 117, 0.95)
          );
        }
      }

      &-position {
        color: #233441;
        clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);

        @if $isWsm {
          width: 3.75rem;
        }

        @if $isSsm {
          width: 3.25rem;
        }

        &.is-rank-1 {
          @if $isWsm {
            background-image: linear-gradient(to top, #ffef84, #f6c717);
          }
          @if $isSsm {
            background-image: linear-gradient(to top, #daab36, #ffdf91);
          }
        }

        &.is-rank-2 {
          @if $isWsm {
            background-image: linear-gradient(to top, #a4a4a4, #fff);
          }
          @if $isSsm {
            background-image: linear-gradient(to top, #c3c3c3, #f5f0f0);
          }
        }

        &.is-rank-3 {
          @if $isWsm {
            background-image: linear-gradient(to top, #e77447, #ffb99e);
          }
          @if $isSsm {
            background-image: linear-gradient(to top, #f58d57, #ffb28a);
          }
        }
      }

      :deep(.app-user) {
        margin-left: 1rem;
        @apply space-x-2;
      }

      :deep(.app-user .ml-4) {
        margin-left: 0;
      }

      :deep(.app-user-country) {
        width: 2.875rem;
        margin: 0;
        margin-top: auto;
        text-align: right;
        @apply text-18;
      }

      :deep(.app-user-flag) {
        width: 2.313rem;
        height: 1.5rem;
      }

      :deep(.app-user-name) {
        max-width: 12rem;
        @apply text-24 truncate;
      }
    }
  }

  .btn-close {
    top: 0;
    right: 10.5rem;
    transform: translateY(-50%);
  }
}
</style>
