import type { ImageLoadData } from '@/map-phaser-new/interfaces'
import { tutorialImageLoadData } from './tutorialImageLoadData'

/**
 * This function is used to collect all imports of tutorial images, to make loading at BootScene easier
 * @returns Promise<ImageLoadData[]>
 */
export const exportedTutorialImages: () => Promise<ImageLoadData[]> = () => {
  return Promise.resolve([...tutorialImageLoadData()])
}
