<template>
  <main v-if="championPass?.length" class="champion-pass flexing flex-col flex-grow w-full">
    <p
      class="font-bold uppercase mx-auto"
      :class="$route.meta.isPremiumRoute ? 'text-32 mt-8' : 'text-36 mt-10'"
    >
      {{ $te('championPassInfo') }}
    </p>
    <footer class="flexing w-full" :class="[$route.meta.isPremiumRoute ? 'mb-6' : 'mb-8']">
      <buy-pass-button
        :item-id="itemId"
        :price="price"
        :currency="currency"
        :purchased="isPassActivated"
      />
    </footer>
  </main>
</template>

<script lang="ts">
import type PremiumChampionPassApiResponse from '@/interfaces/responses/premium/PremiumChampionPassApiResponse'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import BuyPassButton from '../BuyPassButton.vue'

export default defineComponent({
  name: 'ChampionsPassMain',
  components: { BuyPassButton },
  computed: {
    ...mapState(usePremiumStore, {
      championPass: 'getChampionPass',
    }),
    isPassActivated(): boolean {
      return this.championPass?.[0]?.remaining_buys === 0
    },
    itemId(): PremiumChampionPassApiResponse['packs'][0]['store_id'] {
      return this.championPass?.[0]?.store_id
    },
    price(): PremiumChampionPassApiResponse['packs'][0]['price']['price'] {
      return this.championPass?.[0]?.price?.price
    },
    currency(): PremiumChampionPassApiResponse['packs'][0]['price']['currency'] {
      return this.championPass?.[0]?.price?.currency
    },
  },
})
</script>

<style lang="scss" scoped>
.champion-pass {
  justify-content: space-between;

  p {
    width: 85%;
    @if $isWsm {
      color: #ffedbd;
    }
    @if $isSsm {
      background-image: linear-gradient(to bottom, #ffedbd, #fffcf1),
        linear-gradient(to bottom, #fbb642, #fbb642);
      color: #fbb642;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
</style>
