/**
 * Extrahovana logika listenerov
 * @param {*} context - app context
 */
import { isMobile } from '@/plugins'

export const windowListeners = (context: any): void => {
  window.addEventListener('offline', (): void => {
    context.setOfflineState(true)
  })
  window.addEventListener('online', (): void => {
    context.setOfflineState(false)
    location.reload()
  })
  if (isMobile() && !localStorage.getItem('firstClick')) {
    window.addEventListener('touchend', context.setFirstClick, true)
  }

  window.onpopstate = (): void => {
    if (context.isTutorial) location.reload()
  }

  window.purchaseFinished = context.showPaymentSessionMessage
  window.addLoginFinished = context.addLoginFinished
  window.accountConnected = context.accountConnected
  window.reloadConnections = context.reloadConnections
  window.isWebLoaded = context.checkLoadingDone
  window.failedLoadingRewardedAds = context.failedLoadingRewardedAds
  window.loadedRewardedAds = context.loadedRewardedAds
  window.failedLoadingInterstitialAds = context.failedLoadingInterstitialAds
  window.loadedInterstitialAds = context.loadedInterstitialAds
  window.showPrivacyOptionsSettings = context.showPrivacyOptionsSettings
}
