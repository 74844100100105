<template>
  <structure-component v-if="isAuthorized && !forceMaintenance" />
  <maintenance-popup
    v-if="forceMaintenance"
    game-state="maintenance"
    time="Mon Apr 22 2024 06:00:00 GMT+0200 (Central European Summer Time)"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import StructureComponent from './initComponents/StructureComponent.vue'
import MaintenancePopup from './components/Popup/MaintenancePopup.vue'

export default defineComponent({
  components: {
    StructureComponent,
    MaintenancePopup,
  },
  data() {
    return {
      isAuthorized: false,
      forceMaintenance: false,
    }
  },
  created() {
    if (this.forceMaintenance) return
    if (window.location.href.includes('set-login')) {
      window.localStorage.clear()
      localStorage.setItem('is_mobile', '1') // timing fallback value for iPad
      this.isAuthorized = true
    } else {
      this.isAuthorized = true
    }
  },
})
</script>

<style lang="scss">
@import '@/assets/styles/mediaQueries.scss';
@import '@/assets/styles/safeAreas.scss';
@import '@/assets/styles/lockedTooltip.scss';
@import '@/assets/styles/eqStyles.scss';
@import '@/assets/styles/cookieConsent.scss';
</style>
