<template>
  <div id="news-view" class="app-page-wrapper news absolute">
    <menu-component menu-type="news" :title="$t('common.news')" />
    <section class="w-full h-full safe-area">
      <app-scrollbar width="98%" height="75%" scroll="y" slice="y" class="mt-10">
        <news-row v-for="newsItem in news" :key="newsItem.id" :news-data="newsItem" />
      </app-scrollbar>
    </section>
  </div>
</template>

<script lang="ts">
import NewsRow from '@/components/News/NewsRow.vue'
import { useNewsStore } from '@/store/pinia/newsStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'
import type { NewsRequestResponseInterface } from '@/interfaces/News'

interface ComponentData {
  news: NewsRequestResponseInterface[]
}

export default defineComponent({
  name: 'NewsComponent',
  components: {
    NewsRow,
  },
  data(): ComponentData {
    return {
      news: [] as NewsRequestResponseInterface[],
    }
  },
  async created(): Promise<void> {
    this.news = await this.getNews()
    this.loadUnreadNewsCount()
  },
  methods: {
    ...mapActions(useNewsStore, ['getNews', 'loadUnreadNewsCount']),
  },
})
</script>
../../interfaces/newsTypes
