<template>
  <div class="flex justify-center items-center skew" :class="`flex-${direction}`">
    <p
      class="text-32 uppercase text-texts-standard-important whitespace-nowrap mx-4 revert-skew w-full"
      :class="{ 'mb-2': direction === 'col' }"
    >
      {{ $te('eventYourReputation') }}
    </p>
    <tippy theme="WSM1" :class="['flexing w-full h-full']" placement="bottom" max-width="25rem">
      <div class="event-reputation-box flexing">
        <p
          class="flexing text-36 text-texts-standard-default font-bold whitespace-nowrap revert-skew"
        >
          {{ $filters.$formatNumber(totalPoints) || 0 }}
          <app-icon :icon-name="EVENT_REPUTATION" class="ml-2" />
        </p>
      </div>
      <template #content>
        <div class="lock-tooltip">
          <div class="lock-tooltip-title flexing">
            <p class="uppercase font-bold text-30 pt-2">{{ $te('reputation') }}</p>
          </div>
          <p
            class="lock-tooltip-text-margin text-28"
            v-html="
              $replacePlaceholder(
                $te('reputationInfoTooltip'),
                '{discipline}',
                $t(`discipline.discipline_${eventDisciplineId}`),
              )
            "
          />
        </div>
      </template>
    </tippy>
  </div>
</template>

<script lang="ts">
import { EVENT_REPUTATION } from '@/globalVariables'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useMilestonesStore } from '@/store/pinia/events/milestonesStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  EVENT_REPUTATION: typeof EVENT_REPUTATION
}

export default defineComponent({
  name: 'EventReputationBox',
  props: {
    direction: {
      type: String,
      default: 'row',
    },
  },
  data(): ComponentData {
    return { EVENT_REPUTATION }
  },
  computed: {
    ...mapState(useMilestonesStore, {
      totalPoints: 'getMilestonesTotalPoints',
    }),
    ...mapState(useEventInfoStore, {
      eventDisciplineId: 'getEventDisciplineId',
    }),
  },
  async created(): Promise<void> {
    await this.loadMilestonesRuntimeData()
  },
  methods: {
    ...mapActions(useMilestonesStore, {
      loadMilestonesRuntimeData: 'loadRuntimeData',
    }),
  },
})
</script>

<style lang="scss" scoped>
.event-reputation-box {
  background: #0f3251;
  border: 1px solid #f6c717;
  padding: 1rem 3rem;
  width: 16.25rem;
  height: 3.75rem;
}
</style>
