<template>
  <div id="scroll-wrapper" class="scroll">
    <div v-if="showNavigationArrows">
      <span v-if="activeSection !== 0" class="navigation-arrows navigation-top" @click="moveUp">
        <i class="icon-arrow arrow-up" />
      </span>
      <span
        v-if="activeSection !== offsets.length - 1"
        class="navigation-arrows navigation-bottom"
        @click="moveDown"
      >
        <i class="icon-arrow arrow-down" />
      </span>
    </div>
    <div v-if="showNavigation" class="navigation-menu">
      <span
        v-for="(offset, index) in offsets"
        :key="index"
        class="navigation-menu-point"
        :class="{ active: activeSection == index }"
        @click="scrollToSection(index)"
      />
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import { isSafariBrowser } from '@/helpers'
import { defineComponent } from 'vue'

interface ComponentData {
  inMove: boolean
  activeSection: number
  offsets: number[]
  isTrackpad: boolean
}

export default defineComponent({
  name: 'FullPageScroll',
  props: {
    id: {
      type: String,
      default: 'scroll',
      required: true,
    },
    showNavigationArrows: {
      type: Boolean,
      default: true,
    },
    showNavigation: {
      type: Boolean,
      default: true,
    },
  },
  data(): ComponentData {
    return {
      inMove: false,
      activeSection: 0,
      offsets: [],
      isTrackpad: false,
    }
  },

  mounted(): void {
    const scrollElement = document.getElementById('scroll-wrapper')
    scrollElement.addEventListener('wheel', this.handleMouseWheel, {
      passive: true,
    }) // Other browsers
    this.calculateSectionOffsets()
  },
  unmounted(): void {
    const scrollElement = document.getElementById('scroll-wrapper')
    if (!scrollElement) {
      return
    }
    scrollElement.removeEventListener('wheel', this.handleMouseWheel) // Other browsers
  },
  methods: {
    isSafariBrowser,
    isScrollableElementVisible(): boolean {
      const multiselect = document.querySelector('.multiselect') as HTMLElement
      const homepage = document.querySelector('.homepage') as HTMLElement
      const modal = document.querySelector('.info-popup-box') as HTMLElement | null

      const isElementVisible = multiselect.classList.contains('is-open') || !!modal

      if (isElementVisible) homepage.classList.add('overflow-hidden')

      return isElementVisible
    },
    calculateSectionOffsets(): void {
      const sections = document.getElementsByClassName(this.id) as HTMLCollectionOf<HTMLElement>
      if (!sections) console.error('No sections found')
      for (let i = 0; i < sections.length; i++) {
        const sectionOffset = sections[i].offsetTop
        this.offsets.push(sectionOffset)
      }
    },
    scrollToSection(sectionId: number, force: boolean = false): boolean {
      if (this.inMove && !force) return false
      this.activeSection = sectionId
      const sectionToScroll = (
        document.getElementsByClassName(this.id) as HTMLCollectionOf<HTMLElement>
      )[sectionId]
      if (!sectionToScroll) console.error('No section to scroll')

      if (this.isSafariBrowser()) {
        sectionToScroll.scrollIntoView()
      } else {
        sectionToScroll.scrollIntoView({
          behavior: 'smooth',
        })
      }

      setTimeout(
        (): void => {
          this.inMove = false
        },
        this.isTrackpad ? 1500 : 500,
      )
    },
    handleMouseWheel(e: WheelEvent): boolean {
      if (this.isScrollableElementVisible()) return false

      this.detectTrackPad(e)
      this.decideMovement(e)
      return false
    },
    detectTrackPad(e: WheelEvent): void {
      this.isTrackpad = false
      if (e.deltaY) {
        if (e.deltaY === e.deltaY * -3) this.isTrackpad = true
      } else if (e.deltaMode === 0) {
        this.isTrackpad = true
      }
    },
    decideMovement(e: WheelEvent): void {
      const deltaValue = Math.sign(e.deltaY)
      if (deltaValue === 1) this.moveDown()
      else if (deltaValue === -1) this.moveUp()
    },
    moveUp(): void {
      if (this.inMove) return

      this.inMove = true
      this.activeSection--
      if (this.activeSection < 0) this.activeSection = 0
      this.scrollToSection(this.activeSection, true)
    },
    moveDown(): void {
      if (this.inMove) return

      this.inMove = true
      this.activeSection++
      if (this.activeSection > this.offsets.length - 1) this.activeSection = this.offsets.length - 1
      this.scrollToSection(this.activeSection, true)
    },
  },
})
</script>

<style lang="scss" scoped>
.scroll {
  width: 100%;
  height: 100%;

  .navigation-menu {
    position: fixed;
    left: 5rem;
    top: 50%;
    transform: translateY(-50%);

    &-point {
      background-color: #c3c3c3;
      display: block;
      opacity: 0.6;
      transition: 0.4s ease all;
      width: 1.75rem;
      height: 1.75rem;
      margin-bottom: 0.375rem;
      border-radius: 13.6px;
      cursor: pointer;
    }

    .active {
      opacity: 1;
      width: 1.75rem;
      height: 3.938rem;

      @if $isWsm {
        background-color: #fadd1e;
      }

      @if $isSsm {
        background-color: theme('colors.texts.standard.important');
      }
    }
  }

  .navigation-arrows {
    width: 30px;
    height: 30px;
    border: 1px solid white;
    position: fixed;
    border-radius: 50%;
    right: 50%;
    background: transparent;
    animation: fadeIn 2s;
    z-index: 99;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-arrow {
      border: solid white;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
    }

    .arrow-up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      margin-top: 2px;
    }

    .arrow-down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      margin-bottom: 2px;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }

  .navigation-top {
    top: 2rem;
  }

  .navigation-bottom {
    bottom: 2rem;
  }
}
</style>
