import router from '@/router'
import { eventShipSound, isCanvasInactive, playSound } from '@/helpers'
import type {
  AdditionalBuildingBackgroundImageConfigInterface,
  BubbleNotificationConfigInterface,
  BuildingClLabelInfoConfigInterface,
  BuildingCommonResponseInterface,
  BuildingConfigInterface,
  BuildingLabelInfoInterface,
  BuildingLabelNewConfigInterface,
  BuildingOutlineConfigInterface,
  BuildingRouteConfigInterface,
  BuildingTitleConfigInterface,
  IndicatorConfigInterface,
  ExclamationNotificationConfigInterface,
  TutorialConfigInterface,
  useRouterFunctionInterface,
  BuildingRewardConfigInterface,
  BuildingRewardDataInterface,
  ProgressTimerDataInterface,
  BuildingLockedConfigInterface,
} from '@/map-phaser-new/interfaces'
import { InteractiveObject } from '../abstractClasses'
import { BuildingLockedTooltip } from './buildingLockedTooltip'
import { BuildingReward } from './buildingReward'
import { TutorialItems } from './tutorialItems'
import { Indicator } from './indicator'
import { BuildingTitle } from './buildingTitle'
import { cameraHandler } from '@/map-phaser-new/utils'
import { getRouteWebName, translate } from '@/plugins'
import { internalAxios } from '@/plugins/vueAxios'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import type { RouteParamsRaw } from 'vue-router'
import { useUserStore } from '@/store/pinia/userStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { useRenderQueueStore } from '@/store/pinia/renderQueueStore'
import { RenderQueueComponent } from '@/enums/RenderQueueTypes'
import { BuildingLabelNew } from './buildingLabelNew'
import { BuildingLabelChampionship } from './buildingLabelChampionship'
import { BubbleNotification } from './bubbleNotification'
import { ExclamationNotification } from '@/map-phaser-new/models'
import { ProgressTimer } from './progressTimer'

export class Building extends InteractiveObject {
  private buildingImage: Phaser.GameObjects.Image
  private outlineImage: Phaser.GameObjects.Image
  private titleObject: BuildingTitle | null = null
  private lockedBuildingLock?: Phaser.GameObjects.Image
  private lockedBuildingTooltip?: BuildingLockedTooltip
  private tutorialItems: TutorialItems | null = null
  private indicator: Indicator | null = null
  private progressTimer: ProgressTimer | null = null
  private buildingLabelNew: BuildingLabelNew | null = null
  private labelChampionship: BuildingLabelChampionship | null = null
  private premiumNotification: ExclamationNotification | null = null
  private eventOsraNotification: ExclamationNotification | null = null
  private bubbleNotification: BubbleNotification | null
  private buildingReward: BuildingReward | null = null

  constructor(
    public level: number,
    public name: string,
    public isLocked: boolean,
    public unlock: string,
    positionX: number,
    positionY: number,
  ) {
    super(positionX, positionY)
  }

  public addToMap(
    activeScene: Phaser.Scene,
    baseConfig: BuildingConfigInterface,
    buildingResponseConfig: BuildingCommonResponseInterface,
    showTitle: boolean,
  ): void {
    // we are changing starting position of building if we have animation
    if (baseConfig.tween) Object.assign(this, baseConfig.tween.from)

    const frameName = this.getFrameName(baseConfig.frameKey, baseConfig.frameLevelDivider)
    this.buildingImage = this.addBuildingImage(activeScene, baseConfig, frameName)
    this.outlineImage = this.addBuildingOutlineImage(
      activeScene,
      baseConfig,
      frameName,
      baseConfig.frameLevelDivider,
    )
    this.addBuildingTitle(activeScene, showTitle, baseConfig.buildingTitleConfig, baseConfig)
    this.addAdditionalBackgroundImage(
      activeScene,
      baseConfig.additionalBuildingBackgroundImageConfig,
    )
    this.setBaseEvents()

    if (buildingResponseConfig.isForeign) {
      this.disableInteractivity()
    }

    this.addEventBuildingAnimation(activeScene, baseConfig)
  }

  /**
   * Destroy building object.
   * Currently works only for event buildings. For other buildings, it is necessary to implement the destruction of all objects.
   */
  public removeBuilding(): void {
    this.buildingImage.removeAllListeners()
    this.buildingImage.destroy()
    this.buildingImage = null
    this.outlineImage.destroy()
    this.outlineImage = null
    this.titleObject?.removeTitle()
    this.titleObject = null
  }

  public unlockBuilding(
    activeScene: Phaser.Scene,
    baseConfig: BuildingConfigInterface,
    newLevel: number,
  ): void {
    this.isLocked = false
    this.level = newLevel
    this.buildingImage.removeAllListeners()
    // we have to use optional chaining, because this method is also used for foreign clubs map
    this.lockedBuildingTooltip?.destroyTooltipAndTimer(activeScene)
    this.lockedBuildingTooltip = null
    this.lockedBuildingLock.destroy()
    this.lockedBuildingLock = null
    const frameName = this.getFrameName(baseConfig.frameKey, baseConfig.frameLevelDivider)
    this.buildingImage.setTexture(baseConfig.textureKey, frameName)

    this.addUnlockedBuildingListeners(baseConfig.routeConfig, this.buildingImage)
    this.setBaseEvents()
    this.changeOutline(baseConfig.outlineConfig, frameName, baseConfig.frameLevelDivider)
    this.titleObject.changeTitleBackground(
      activeScene,
      baseConfig.buildingTitleConfig,
      this.isLocked,
    )
  }

  public lockBuilding(activeScene: Phaser.Scene, baseConfig: BuildingConfigInterface): void {
    this.buildingImage.removeAllListeners()
    this.isLocked = true
    this.level = 1

    const frameName = baseConfig.frameKey + baseConfig.buildingLockedConfig.framePostfix
    this.buildingImage.setTexture(baseConfig.buildingLockedConfig.textureKey, frameName)
    this.lockedBuildingLock = this.addLockToBuilding(activeScene, baseConfig.buildingLockedConfig)

    this.setBaseEvents()
    this.addLockedBuildingTooltip(activeScene, this.buildingImage, baseConfig)
    this.addLockedBuildingListener(
      activeScene,
      baseConfig.buildingLockedConfig.tooltipConfig.tooltipHideDelay,
      this.buildingImage,
    )
    const frameNameOutline = this.getFrameName(baseConfig.frameKey)
    this.changeOutline(baseConfig.outlineConfig, frameNameOutline)
    this.titleObject.changeTitleBackground(
      activeScene,
      baseConfig.buildingTitleConfig,
      this.isLocked,
    )
  }

  public changeLevel(baseConfig: BuildingConfigInterface, newLevel: number): void {
    this.level = newLevel
    const frameName = this.getFrameName(baseConfig.frameKey, baseConfig.frameLevelDivider)
    this.buildingImage.setTexture(baseConfig.textureKey, frameName)

    this.changeOutline(baseConfig.outlineConfig, frameName, baseConfig.frameLevelDivider)
  }

  public setTitleVisibility(visible: boolean): void {
    this.titleObject?.setTitleVisibility(visible)
  }

  public addPremiumNotification(
    activeScene: Phaser.Scene,
    baseConfig: BuildingConfigInterface,
    notificationConfig: ExclamationNotificationConfigInterface,
  ): void {
    if (this.premiumNotification) return

    const calculatedX =
      baseConfig.buildingTitleConfig.positionX -
      baseConfig.buildingExclamationNotificationConfig.correctionPositionX
    const calculatedY =
      baseConfig.buildingTitleConfig.positionY -
      baseConfig.buildingExclamationNotificationConfig.correctionPositionY

    this.premiumNotification = new ExclamationNotification(calculatedX, calculatedY)
    this.premiumNotification.addToMap(activeScene, notificationConfig)
  }

  public removePremiumNotification(activeScene: Phaser.Scene): void {
    if (!this.premiumNotification) return

    this.premiumNotification.removeFromMap(activeScene)
    this.premiumNotification = null
  }

  public addEventOsraNotification(
    activeScene: Phaser.Scene,
    baseConfig: BuildingConfigInterface,
    notificationConfig: ExclamationNotificationConfigInterface,
  ): void {
    if (this.eventOsraNotification) return

    const calculatedX =
      baseConfig.positionX + baseConfig.buildingExclamationNotificationConfig.correctionPositionX
    const calculatedY =
      baseConfig.positionY + baseConfig.buildingExclamationNotificationConfig.correctionPositionY

    this.eventOsraNotification = new ExclamationNotification(calculatedX, calculatedY)
    this.eventOsraNotification.addToMap(activeScene, notificationConfig)
  }

  public removeEventOsraNotification(activeScene: Phaser.Scene): void {
    if (!this.eventOsraNotification) return

    this.eventOsraNotification.removeFromMap(activeScene)
    this.eventOsraNotification = null
  }

  public addLabelNew(
    activeScene: Phaser.Scene,
    labelConfig: BuildingLabelNewConfigInterface,
  ): void {
    if (this.buildingLabelNew || !labelConfig) return

    this.buildingLabelNew = new BuildingLabelNew(labelConfig.positionX, labelConfig.positionY)
    this.buildingLabelNew.addToBuilding(activeScene, labelConfig)
  }

  public addLabelChampionshipInfo(
    activeScene: Phaser.Scene,
    infoData: BuildingLabelInfoInterface,
    configData: BuildingClLabelInfoConfigInterface,
  ): void {
    if (this.labelChampionship || !configData) return

    this.labelChampionship = new BuildingLabelChampionship(
      configData.positionX,
      configData.positionY,
    )
    this.labelChampionship.addToBuilding(activeScene, infoData, configData)
  }

  public removeLabelNew(): void {
    if (!this.buildingLabelNew) return

    this.buildingLabelNew.removeLabelNewImage()
    this.buildingLabelNew = null
  }

  public removeLabelChampionshipInfo(): void {
    if (!this.labelChampionship) return

    this.labelChampionship.removeLabel()
    this.labelChampionship = null
  }

  public reloadTexts(activeScene: Phaser.Scene, config: BuildingConfigInterface): void {
    const titleString = translate(config.keyForTranslateTitle)

    this.titleObject?.reloadTitle(
      activeScene,
      config.buildingTitleConfig,
      this.isLocked,
      titleString,
    )

    if (!this.lockedBuildingTooltip) return
    this.lockedBuildingTooltip.reloadTooltipTexts(
      activeScene,
      config.buildingLockedConfig,
      titleString,
      this.unlock,
    )
  }

  private addEventBuildingAnimation(
    activeScene: Phaser.Scene,
    baseConfig: BuildingConfigInterface,
  ): void {
    if (!baseConfig.tween || baseConfig.textureKey !== 'buildings.event_ship') return

    this.disableInteractivity()
    this.titleObject?.setTitleVisibility(false)
    activeScene.input.enabled = false
    activeScene.cameras.main.pan(
      baseConfig.tween.camera.pan.positionX,
      baseConfig.tween.camera.pan.positionY,
      baseConfig.tween.camera.pan.duration,
      baseConfig.tween.camera.pan.ease,
      true,
    )

    activeScene.tweens
      .add({
        targets: this.buildingImage,
        x: { value: baseConfig.tween.to.positionX, ease: baseConfig.tween.ease },
        y: { value: baseConfig.tween.to.positionY, ease: baseConfig.tween.ease },
        duration: baseConfig.tween.duration,
      })
      .on('complete', (): void => {
        this.enableInteractivity(baseConfig)
        this.titleObject?.setTitleVisibility(true)
        activeScene.input.enabled = true

        useUserStore().setProfileAttributes({
          name: 'event_ship_arrived',
          value: 1,
        })

        if (useUserStore().isSoundEnabled) {
          eventShipSound.play()
        }

        useRenderQueueStore().addToRenderQueue(RenderQueueComponent.EventsTeaser)
      })

    router.push('/')
  }

  private changeOutline(
    outlineConfig: BuildingOutlineConfigInterface,
    frameName: string,
    divider?: number,
  ): void {
    const frameLevel = this.getFrameLevel(divider)
    const { positionX, positionY } = outlineConfig.positionByLevel[frameLevel]
    this.outlineImage.setPosition(positionX, positionY)
    this.outlineImage.setTexture(outlineConfig.textureKey, frameName + outlineConfig.framePostFix)
  }

  private addBuildingTitle(
    activeScene: Phaser.Scene,
    showTitle: boolean,
    titleConfig?: BuildingTitleConfigInterface,
    baseConfig?: BuildingConfigInterface,
  ): void {
    if (!titleConfig) return

    this.titleObject = new BuildingTitle()

    const titleString = translate(baseConfig.keyForTranslateTitle)
    this.titleObject.addToBuilding(activeScene, titleConfig, showTitle, this.isLocked, titleString)
  }

  private getFrameName(frameKey?: string, divider?: number): string | undefined {
    if (!frameKey) return undefined

    const frameLevel = this.getFrameLevel(divider)
    return `${frameKey}_${frameLevel}`
  }

  private getFrameLevel(frameLevelDivider?: number): number {
    return frameLevelDivider ? Math.ceil(this.level / frameLevelDivider) : this.level
  }

  private addBuildingImage(
    activeScene: Phaser.Scene,
    baseConfig: BuildingConfigInterface,
    frameName: string | undefined,
  ): Phaser.GameObjects.Image {
    const buildingImage = this.isLocked
      ? this.addLockedBuilding(activeScene, baseConfig)
      : this.addUnlockedBuilding(activeScene, baseConfig, frameName)
    // name for pixi devtools
    buildingImage.setName(`Building: ${this.name}`)

    buildingImage.setInteractive({
      useHandCursor: baseConfig.inputConfig.useHandCursor,
      hitArea: baseConfig.inputConfig.hitArea,
      hitAreaCallback: baseConfig.inputConfig.hitCallback,
    })

    return buildingImage
  }

  private addBuildingOutlineImage(
    activeScene: Phaser.Scene,
    baseConfig: BuildingConfigInterface,
    frameName: string | undefined,
    divider?: number,
  ): Phaser.GameObjects.Image {
    const frameLevel = this.getFrameLevel(divider)
    const position = baseConfig.outlineConfig.positionByLevel[frameLevel]

    const buildingOutlineImage = this.addImageToScene(
      activeScene,
      position.positionX,
      position.positionY,
      baseConfig.outlineConfig.textureKey,
      baseConfig.outlineConfig.originX,
      baseConfig.outlineConfig.depth,
      frameName ? frameName + baseConfig.outlineConfig.framePostFix : undefined,
    ).setVisible(false)
    // name for pixi devtools
    buildingOutlineImage.setName(`Building: ${this.name}_outline`)

    return buildingOutlineImage
  }

  private addUnlockedBuilding(
    activeScene: Phaser.Scene,
    baseConfig: BuildingConfigInterface,
    frameName: string | undefined,
  ): Phaser.GameObjects.Image {
    const addedImage = this.addImageToScene(
      activeScene,
      this.positionX,
      this.positionY,
      baseConfig.textureKey,
      baseConfig.originX,
      baseConfig.depth,
      frameName,
    )

    this.addUnlockedBuildingListeners(baseConfig.routeConfig, addedImage)

    return addedImage
  }

  private addAdditionalBackgroundImage(
    activeScene: Phaser.Scene,
    additionalImageConfig?: AdditionalBuildingBackgroundImageConfigInterface,
  ): void {
    if (!additionalImageConfig) return

    const { textureName, positionX, positionY, originX, depth } = additionalImageConfig
    this.addImageToScene(activeScene, positionX, positionY, textureName, originX, depth)
  }

  private addLockedBuilding(
    activeScene: Phaser.Scene,
    baseConfig: BuildingConfigInterface,
  ): Phaser.GameObjects.Image {
    const lockedBuildingImage = this.addImageToScene(
      activeScene,
      this.positionX,
      this.positionY,
      baseConfig.buildingLockedConfig.textureKey,
      baseConfig.originX,
      baseConfig.depth,
      baseConfig.frameKey + baseConfig.buildingLockedConfig.framePostfix,
    )

    this.lockedBuildingLock = this.addLockToBuilding(activeScene, baseConfig.buildingLockedConfig)
    this.addLockedBuildingTooltip(activeScene, lockedBuildingImage, baseConfig)
    this.addLockedBuildingListener(
      activeScene,
      baseConfig.buildingLockedConfig.tooltipConfig.tooltipHideDelay,
      lockedBuildingImage,
    )

    return lockedBuildingImage
  }

  public addBuildingReward(
    activeScene: Phaser.Scene,
    rewardData: BuildingRewardDataInterface,
    rewardConfig: BuildingRewardConfigInterface,
  ): void {
    if (this.buildingReward) return

    this.buildingReward = new BuildingReward()
    this.buildingReward.addToBuilding(activeScene, this.buildingImage, rewardConfig, rewardData)
  }

  public removeBuildingReward(): void {
    if (!this.buildingReward) return

    this.buildingReward.destroyReward()
    this.buildingReward = null
  }

  public changeOutlineVisibility(state: boolean): void {
    this.outlineImage.setVisible(state)
  }

  private addLockToBuilding(
    activeScene: Phaser.Scene,
    lockedConfig: BuildingLockedConfigInterface,
  ): Phaser.GameObjects.Image {
    const lockImage = activeScene.add
      .image(lockedConfig.positionX, lockedConfig.positionY, lockedConfig.lockImageTextureKey)
      .setDepth(lockedConfig.lockImageDepth)

    return lockImage
  }

  private addLockedBuildingTooltip(
    activeScene: Phaser.Scene,
    lockedBuildingImage: Phaser.GameObjects.Image,
    baseConfig: BuildingConfigInterface,
  ): void {
    const titleString = translate(baseConfig.keyForTranslateTitle)

    this.lockedBuildingTooltip = new BuildingLockedTooltip()
    this.lockedBuildingTooltip.addToBuilding(
      activeScene,
      lockedBuildingImage,
      baseConfig.buildingLockedConfig,
      titleString,
      this.unlock,
    )
  }

  private addLockedBuildingListener(
    activeScene: Phaser.Scene,
    delay: number,
    lockedBuildingImage: Phaser.GameObjects.Image,
  ): void {
    lockedBuildingImage.on(
      'pointerup',
      (event: (MouseEvent | TouchEvent) & { downElement: HTMLCanvasElement }): void => {
        if (cameraHandler.isDragging() || isCanvasInactive(event)) return

        this.lockedBuildingTooltip.showTooltip(activeScene, delay)
      },
    )
    this.disableDragListener(lockedBuildingImage)
  }

  private addUnlockedBuildingListeners(
    routeConfig: BuildingRouteConfigInterface | useRouterFunctionInterface,
    unlockedBuildingImage: Phaser.GameObjects.Image,
  ): void {
    unlockedBuildingImage.on(
      'pointerup',
      (event: (MouseEvent | TouchEvent) & { downElement: HTMLCanvasElement }): void => {
        this.openBuilding(routeConfig, event)
      },
    )
    this.disableDragListener(unlockedBuildingImage)
  }

  private disableDragListener(image: Phaser.GameObjects.Image): void {
    image.on(
      'pointerdown',
      (event: (MouseEvent | TouchEvent) & { downElement: HTMLCanvasElement }): void => {
        if (isCanvasInactive(event)) return

        cameraHandler.setIsDrag(false)
      },
    )
  }

  private async openBuilding(
    routeConfig: BuildingRouteConfigInterface | useRouterFunctionInterface,
    event: (MouseEvent | TouchEvent) & { downElement: HTMLCanvasElement },
  ): Promise<void> {
    if (cameraHandler.isDragging() || isCanvasInactive(event)) return

    if (typeof routeConfig === 'function') {
      routeConfig(router)
      return
    }

    if (routeConfig.openBuildingEndpoint)
      internalAxios
        .put(routeConfig.openBuildingEndpoint)
        .catch((error: Error): void => console.error(error.toString()))

    playSound(routeConfig.soundName)
    const routeParamId = routeConfig.isRouteParamIdNeeded
      ? this.getRouteParamId(routeConfig.routeIdLocalStorageKey)
      : null
    let routeNameToPush = routeConfig.routeName
    if (routeConfig.routeNameForClubMembership) {
      routeNameToPush = this.getRouteNameAccordingClubMembership(
        routeConfig.routeName,
        routeConfig.routeNameForClubMembership,
      )
    }

    const tutorialPostfix = await this.checkTutorial()
    router.push({
      name: getRouteWebName(routeNameToPush) + tutorialPostfix,
      params: routeParamId,
      ...(routeConfig.changeScene && {
        query: { redirectFrom: router.currentRoute?.value?.name.toString() ?? undefined },
      }),
    })
  }

  private async checkTutorial(): Promise<string> {
    const useTutorial = useTutorialStore()
    if (!useTutorial.getActualStage) return ''

    await useTutorial.logTutorialProgress(useTutorial.getActualStage.name)
    const phaserStore = usePhaserGameIntegrationStore()
    phaserStore.hideMainMapTutorial()

    // check if tutorial is active -> useTutorial.getActualStage .. have to be again - case if
    // tutorial end with focus on building
    return useTutorial.getActualStage ? 'Tutorial' : ''
  }

  private getRouteNameAccordingClubMembership(
    withoutClubRouteName: string,
    withClubRouteName: string,
  ): string {
    const useUser = useUserStore()
    const hasClub = useUser['getHasClub']
    if (hasClub) return withClubRouteName

    return withoutClubRouteName
  }

  private getRouteParamId(routeIdLocalStorageKey?: string): RouteParamsRaw {
    const routeId = this.getIdFromLocalStorage(routeIdLocalStorageKey)

    return { id: routeId ?? useDisciplineStore().getFirstUnlockedDisciplineId }
  }

  private getIdFromLocalStorage = (idKey?: string): number | null => {
    if (!idKey) return null

    const id = parseInt(localStorage.getItem(idKey))
    return typeof id === 'number' && !isNaN(id) ? id : null
  }

  private addImageToScene(
    activeScene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    originX: number,
    depth: number,
    frameName?: string,
  ): Phaser.GameObjects.Image {
    return activeScene.add.image(x, y, texture, frameName).setOrigin(originX).setDepth(depth)
  }

  private setBaseEvents(): void {
    this.buildingImage.on('pointerover', (): void => {
      this.changeOutlineVisibility(true)
    })

    this.buildingImage.on('pointerout', (): void => {
      this.changeOutlineVisibility(false)
    })
  }

  public setBubbleNotification(
    activeScene: Phaser.Scene,
    baseConfig: BuildingConfigInterface,
    bubbleNotificationConfig: BubbleNotificationConfigInterface,
  ): void {
    if (this.bubbleNotification) {
      return
    }
    const titleWidth = this.titleObject.getTitleTextWidth()
    this.bubbleNotification = new BubbleNotification(
      baseConfig.buildingTitleConfig.positionX +
        titleWidth / bubbleNotificationConfig.xCorrectionValue,
      baseConfig.buildingTitleConfig.positionY + bubbleNotificationConfig.yCorrectionValue,
    )
    this.bubbleNotification.addToMap(activeScene, bubbleNotificationConfig)
  }

  public removeBubbleNotification(): void {
    if (!this.bubbleNotification) return

    this.bubbleNotification.removeFromMap()
    this.bubbleNotification = null
  }

  public setDepth(tutorialConfigData: TutorialConfigInterface): void {
    this.buildingImage.setDepth(tutorialConfigData.tutorialDepth)
  }

  public addTutorial(activeScene: Phaser.Scene, tutorialConfigData: TutorialConfigInterface): void {
    this.tutorialItems = new TutorialItems()
    this.tutorialItems.addToMap(activeScene, this.buildingImage, tutorialConfigData)
    this.buildingImage.setDepth(tutorialConfigData.tutorialDepth)
    this.titleObject?.setTitleDepth(tutorialConfigData.tutorialDepth)
    this.tutorialItems.focusCamera(activeScene, tutorialConfigData, this.buildingImage)
  }

  public removeTutorial(activeScene: Phaser.Scene, config: BuildingConfigInterface): void {
    this.tutorialItems?.destroyTutorialElements(activeScene)
    this.enableInteractivity(config)
    this.tutorialItems = null
    this.buildingImage.setDepth(config.depth)
    this.titleObject?.setTitleDepth(config.depth)
    this.titleObject?.setTitleTextAlpha(1)
  }

  public disableInteractivity(): void {
    this.buildingImage.disableInteractive()
  }

  public setTitleAlpha(alpha: number): void {
    this.titleObject?.setTitleTextAlpha(alpha)
  }

  public enableInteractivity(config: BuildingConfigInterface): void {
    this.buildingImage.setInteractive({
      useHandCursor: config.inputConfig.useHandCursor,
      hitArea: config.inputConfig.hitArea,
      hitAreaCallback: config.inputConfig.hitCallback,
    })
  }

  public addIndicator(
    activeScene: Phaser.Scene,
    config: BuildingConfigInterface,
    indicatorConfig: IndicatorConfigInterface,
  ): void {
    // dynamically determine the best X-axis center for rendering the indicator
    const calculatedX =
      config.buildingTitleConfig.positionX - config.positionIndicatorConfig.xCorrectionValue
    // using frameKey, the specific image's name in focus, we determine the Y-coordinate correction for the indicator
    const correctionY = config.positionIndicatorConfig.yCorrectionValue
    let calculatedY = config.positionY - correctionY

    const isFlipped = config.isIndicatorFlipped ?? false
    if (isFlipped) {
      calculatedY = config.positionY + correctionY
    }

    this.indicator = new Indicator(calculatedX, calculatedY, isFlipped)
    this.indicator.addToMap(activeScene, indicatorConfig)
  }

  public getIndicator(): Indicator | null {
    return this.indicator
  }

  public removeIndicator(activeScene: Phaser.Scene): void {
    if (!this.indicator) return

    this.indicator.removeFromMap(activeScene)
    this.indicator = null
  }

  public setIndicatorVisibility(visible: boolean): void {
    if (!this.indicator) return

    this.indicator.setIndicatorVisibility(visible)
  }

  public addProgressTimer(
    activeScene: Phaser.Scene,
    config: BuildingConfigInterface,
    timerData: ProgressTimerDataInterface,
  ): void {
    // handling the redundant rendering of the progressTimer above the building
    if (this.progressTimer) return

    // handling the presence of a configuration for progressTimer
    if (!config?.progressTimerConfig) return

    // early exit if timer should be 3 (GamePass)/1 second or less (to avoid rendering timer with 0 seconds left)
    if (Math.trunc((timerData.timerEnd - timerData.timerStart) / 1e3) <= 3) return

    this.progressTimer = new ProgressTimer()
    this.progressTimer.addToBuilding(activeScene, config.progressTimerConfig, timerData, true)
  }

  public removeProgressTimer(activeScene: Phaser.Scene): void {
    if (!this.progressTimer) return

    this.progressTimer.removeFromMap(activeScene)
    this.progressTimer = null
  }

  public getBuildingSize(): { width: number; height: number } {
    return { width: this.buildingImage.width, height: this.buildingImage.height }
  }
}
