<template>
  <div
    id="arena-building-wrapper"
    class="arena-building app-page-wrapper absolute"
    daty-cy="arena-building-wrapper"
  >
    <div class="w-full h-full relative flex flex-col items-center">
      <arena-menu />

      <div class="arena-building-main flex justify-between safe-area">
        <arena-building-season-info :current-building="currentBuilding" />
        <arena-building-grades :current-building="currentBuilding" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { arenaUserBuildingEndpoint } from '@/globalVariables'
import ArenaBuildingGrades from '@/components/Arena/ArenaBuilding/ArenaBuildingGrades.vue'
import ArenaBuildingSeasonInfo from '@/components/Arena/ArenaBuilding/ArenaBuildingSeasonInfo.vue'
import ArenaMenu from '@/components/Arena/ArenaMenu.vue'
import type { CurrentArenaBuildingResponse } from '@/components/Arena/ArenaSharedInterface'

interface ComponentData {
  currentBuilding: CurrentArenaBuildingResponse | null
}

export default defineComponent({
  name: 'ArenaBuilding',
  components: {
    ArenaBuildingSeasonInfo,
    ArenaBuildingGrades,
    ArenaMenu,
  },
  data(): ComponentData {
    return {
      currentBuilding: null,
    }
  },
  created(): void {
    this.getCurrentBuilding()
  },
  methods: {
    async getCurrentBuilding(): Promise<void> {
      this.currentBuilding = await this.$axios.get<{}, CurrentArenaBuildingResponse>(
        arenaUserBuildingEndpoint,
      )
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/arena/buildings.scss';
</style>
