<template>
  <div
    class="task-tracker-header flexing cursor-pointer pointer-events-auto"
    @click="$emit('click')"
  >
    <div class="task-tracker-header-title flex justify-between items-center">
      <p class="text-28 font-bold text-texts-standard-dark revert-skew">Task tracker</p>
      <p class="text-28 text-texts-standard-dark revert-skew">
        {{ activeBranches }}/{{ allBranches }}
      </p>
    </div>
    <div class="task-tracker-header-arrow flexing">
      <div
        class="task-tracker-header-arrow-item"
        :style="isTrackerExtended && { transform: 'none', marginRight: '1rem', marginLeft: 0 }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MapTaskTrackerHeader',
  props: {
    isTrackerExtended: {
      type: Boolean,
      default: false,
    },
    allBranches: {
      type: Number,
      default: 0,
    },
    activeBranches: {
      type: Number,
      default: 0,
    },
  },
  emits: ['click'],
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/discipline-icons.scss';

.task-tracker-header {
  width: 20.4rem;
  height: 2.938rem;
  transform: $skewX-value;

  @if $isWsm {
    border: 0.125rem solid #75b6e7;
  }

  @if $isSsm {
    border: solid 0.125rem #586b9d;
  }

  &-title {
    width: 80%;
    height: 100%;
    background-image: url($path-career + 'taskTracker/task_tracker_header_bg.avif');
    background-size: cover;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &-arrow {
    width: 20%;
    height: 100%;

    @if $isWsm {
      background-image: linear-gradient(45deg, rgba(35, 52, 65, 1) 0%, rgba(55, 127, 184, 1) 100%);
    }

    @if $isSsm {
      background-image: linear-gradient(to right, #34415d, #273249);
    }

    &-item {
      border-width: 1rem;
      border-style: solid;
      margin-left: 1.5rem;
      transform: scaleX(-1);

      @if $isWsm {
        border-color: transparent #fadd1e transparent transparent;
      }

      @if $isSsm {
        border-color: transparent theme('colors.texts.standard.important') transparent transparent;
      }
    }
  }
}
</style>
