<template>
  <div id="equipment" class="equipment app-page-wrapper absolute">
    <menu-component
      menu-type="equipment"
      :title="$t('equipmentShop.detailHeader')"
      right-box-icon="research_points"
      :right-box-value="researchPoints.value"
      :is-tooltip="true"
    />

    <section class="w-full h-full safe-area">
      <div class="flex items-center h-full flex-col">
        <modal-head :title-popup="$t('equipmentShop.detailHeader')">
          <discipline-menu
            id-discipline="equipment-menu"
            notification-type="shop"
            :selected-default="activeDisciplineId"
          />
          <template #popupContent>
            <equipment-popup />
          </template>
          <template #rightSide>
            <div class="clickable-element">
              <div class="icon-settings-wrapper flexing">
                <app-icon icon-name="settings" @click="showSettings = true" />
              </div>
            </div>
          </template>
          <equipment-settings
            v-if="openSettings"
            :visible-settings="showSettings"
            @close="showSettings = false"
          />
        </modal-head>
        <equipment-discipline-info
          v-if="activeDisciplineId"
          ref="disciplineInfo"
          :discipline-id="activeDisciplineId"
        />
        <main v-if="!isContentLoading" class="" :class="$isMobile() && 'justify-center'">
          <equipment-items :items="items" @finish-operation="reloadItemsByDiscipline" />
          <equipment-footer
            v-if="isOperation"
            :process-item="itemInProgress"
            @finish-operation="reloadItemsByDiscipline"
          />
        </main>

        <component-loading height="70%" :is-loading="isContentLoading" />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import DisciplineMenu from '@/components/DisciplineMenu.vue'
import EquipmentDisciplineInfo from '@/components/Equipment/EquipmentDisciplineInfo.vue'
import EquipmentFooter from '@/components/Equipment/EquipmentFooter.vue'
import EquipmentItems from '@/components/Equipment/EquipmentItems.vue'
import EquipmentPopup from '@/components/Equipment/EquipmentPopup.vue'
import EquipmentSettings from '@/components/Equipment/EquipmentSettings.vue'
import ModalHead from '@/components/ModalHead.vue'
import {
  EQUIPMENT_BROKEN,
  EQUIPMENT_EQUIPPED,
  EQUIPMENT_FUNCTIONAL,
  EQUIPMENT_LOCKED,
  EQUIPMENT_PURCHASED,
  EQUIPMENT_UNLOCKED,
  GDD_ID,
} from '@/globalVariables'
import { clone, convertNameValue, playSound } from '@/helpers'
import type {
  OfferData,
  ItemData,
  NormalizedItemData,
  ItemInProgressData,
} from '@/interfaces/Equipment'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useEquipmentStore } from '@/store/pinia/equipment/equipmentStore'
import type {
  EquipmentItemData as BasicEquipmentItemData,
  EquipmentPremiumItemData,
  EquipmentShopOffersData,
} from '@/store/pinia/equipment/equipmentStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import type { Nullable } from '@/interfaces/utils'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { RouteLocationNormalized } from 'vue-router'

interface ComponentData {
  isContentLoading: boolean
  showSettings: boolean
  openSettings: boolean
  activeDisciplineId: Nullable<number>
}

export default defineComponent({
  name: 'EquipmentComponent',
  components: {
    EquipmentDisciplineInfo,
    EquipmentItems,
    EquipmentFooter,
    EquipmentPopup,
    EquipmentSettings,
    DisciplineMenu,
    ModalHead,
  },
  data(): ComponentData {
    return {
      isContentLoading: false,
      showSettings: false,
      openSettings: false,
      activeDisciplineId: null,
    }
  },
  computed: {
    ...mapState(useEquipmentStore, {
      _shopOffers: 'getShopOffers',
      _equipmentItem: 'getSpecificEquipmentItem',
      _premiumEquipmentItem: 'getSpecificPremiumEquipmentItem',
    }),
    ...mapState(useResponseTaskStore, {
      researchPoints: 'getResearchPoints',
    }),
    ...mapState(useDisciplineStore, {
      firstUnlockedDisciplineId: 'getFirstUnlockedDisciplineId',
      unlockDisciplinesIds: 'getUnlockedDisciplinesIdsUnsorted',
    }),
    isOperation(): boolean {
      return this.itemInProgress !== null && !!this.itemInProgress.stats?.operation_type
    },
    shopOffers(): OfferData {
      if (!this._shopOffers) return { availableOffers: [], operation: null }
      return this.normalizeShopOffers(this._shopOffers)
    },
    equipmentItem(): ItemData[] {
      if (!this._equipmentItem) return []
      return this.normalizeEquipmentItems(this._equipmentItem)
    },
    premiumEquipmentItem(): ItemData[] {
      if (!this._premiumEquipmentItem) return []
      return this.normalizeEquipmentItems(this._premiumEquipmentItem)
    },
    items(): Nullable<NormalizedItemData[]> {
      if (this.isContentLoading) return null
      if (!this.shopOffers?.availableOffers || !this.equipmentItem || !this.premiumEquipmentItem)
        return null

      const items: NormalizedItemData[] = [
        ...(this.premiumEquipmentItem as unknown as NormalizedItemData[]),
        ...(this.equipmentItem as unknown as NormalizedItemData[]),
        ...(this.shopOffers.availableOffers as unknown as NormalizedItemData[]),
      ]

      const equippedIndex = items.findIndex(
        (item: NormalizedItemData): boolean =>
          !item.premium && item?.itemState?.name === EQUIPMENT_EQUIPPED,
      )
      if (equippedIndex !== -1) {
        items.splice(0, 0, items.splice(equippedIndex, 1)[0])
      }

      const premiumIndex = items.findIndex(
        (item: NormalizedItemData): boolean => 'premium' in item && !!item.premium,
      )
      if (premiumIndex !== -1) {
        items.splice(0, 0, items.splice(premiumIndex, 1)[0])
      }

      return items
    },
    itemInProgress(): Nullable<ItemInProgressData> {
      if (!this.shopOffers.operation) return null
      return {
        disciplineId: this.shopOffers.operation.discipline_id,
        item: this.shopOffers.operation.item,
        stats: convertNameValue(this.shopOffers.operation.stats),
        parentLevelId: this.shopOffers.operation.item.parentLevel.level,
        parentCategoryId: this.shopOffers.operation.item.parentProperties.find(
          (prop: OfferData['operation']['item']['parentProperties'][0]): boolean =>
            prop.name === GDD_ID,
        ).value,
        parentName: this.shopOffers.operation.item.parentName,
        parentId: this.shopOffers.operation.parent_item_id,
      }
    },
  },
  watch: {
    firstUnlockedDisciplineId(newValue: number): void {
      if (this.activeDisciplineId) return
      this.activeDisciplineId = newValue
      this.loadUserEquipment()
    },
    showSettings(): void {
      if (this.showSettings === true) {
        this.openSettings = true
        return
      }

      this.openSettings = false
      this.showSettings = false
    },
    $route(route: RouteLocationNormalized): void {
      if (route.name === this.$getWebView('Equipment')) {
        this.activeDisciplineId =
          +(this.$route.params.id as string) || this.firstUnlockedDisciplineId
        if (this.activeDisciplineId === +(this.$route.params.id as string)) {
          this.loadUserEquipment()
        }
      }
      if (route.name === this.$getWebView(this.$options.name)) {
        this.reloadItemsByDiscipline()
      }
    },
  },
  async created(): Promise<void> {
    this.activeDisciplineId =
      (this.unlockDisciplinesIds.includes(+(this.$route.params.id as string))
        ? +(this.$route.params.id as string)
        : null) || this.firstUnlockedDisciplineId
    if (this.activeDisciplineId) {
      await this.loadUserEquipment()
    }
    playSound('building-shop')
  },
  methods: {
    ...mapActions(useEquipmentStore, [
      'loadEquipmentByDisciplineId',
      'loadPremiumEquipmentByDisciplineId',
      'loadShopOffers',
    ]),
    async loadUserEquipment(): Promise<void> {
      this.isContentLoading = true
      await Promise.all([
        this.loadEquipmentByDisciplineId(this.activeDisciplineId),
        this.loadPremiumEquipmentByDisciplineId(this.activeDisciplineId),
        this.loadShopOffers(this.activeDisciplineId),
      ])
      this.isContentLoading = false
    },
    normalizeShopOffers(input: EquipmentShopOffersData): OfferData {
      const data = clone(input) as OfferData

      if (data.operation) {
        if (!data.operation.item) {
          data.operation.item = data.operation
            .offer as unknown as EquipmentShopOffersData['operation']['item']
          data.operation.discipline_id = data.operation.offer.discipline
        }

        if (!data.operation.item.parentName) {
          data.operation.item.parentName = data.operation.item.name
          data.operation.item.parentLevel = data.operation.item.level ?? { level: 1 }
          data.operation.item.parentProperties = data.operation.item.properties
        }
      }

      // Id of the first available offer in the shop.
      const firstAvailableOffer = data.availableOffers[0]?.id || 0

      data.availableOffers = data.availableOffers.map(
        (
          offer: EquipmentShopOffersData['availableOffers'][0],
        ): OfferData['availableOffers'][0] => ({
          ...offer,
          isOffer: true,
          isFirstOffer: offer.id === firstAvailableOffer,
          itemDurabilityState: EQUIPMENT_FUNCTIONAL,
          itemStats: { level: offer.unlocked ? '1' : '0' },
          parameters: convertNameValue(offer.items[0].levels[0].parameters),
          hookedItems: offer.items[0].hooked_items,
        }),
      )

      return data
    },
    normalizeEquipmentItems(
      input: BasicEquipmentItemData[] | EquipmentPremiumItemData[],
    ): ItemData[] {
      return input.map((item: BasicEquipmentItemData | EquipmentPremiumItemData): ItemData => {
        const data = clone(item) as ItemData

        data.isOffer = false
        if (item.itemState?.name) data[item.itemState.name] = item.itemState.name
        data.itemStats = convertNameValue(item.itemStats)
        data.itemDurabilityState =
          data.itemStats?.durability === 0 ? EQUIPMENT_BROKEN : EQUIPMENT_FUNCTIONAL
        data.disciplineId = this.activeDisciplineId
        data.parameters = convertNameValue(
          'premium' in item && !!item.premium ? item.properties : item.levelParameters,
        )
        data.levels = new Array(data.parameters?.equipment_max_level)
          .fill(null)
          .map((_: null, index: number): ItemData['levels'][0] => ({
            type: this.getLevelType(++index, data),
          }))

        if (data.operation) {
          if (data.operation.item?.id || data.operation.offer?.id) {
            data.operationStats = convertNameValue(this.shopOffers.operation?.stats)
            data[data.operationStats?.operation_type] = data.operationStats?.operation_type
          }

          data.timeToEnd = Number(data.operationStats?.operation_end) ?? 0
        }

        return data
      })
    },
    getLevelType(
      levelNumber: number,
      item: ItemData,
    ): typeof EQUIPMENT_PURCHASED | typeof EQUIPMENT_UNLOCKED | typeof EQUIPMENT_LOCKED {
      if (levelNumber <= Number(item.itemStats?.level)) return EQUIPMENT_PURCHASED
      if (levelNumber <= item.equipmentLevelsAvailable) return EQUIPMENT_UNLOCKED
      return EQUIPMENT_LOCKED
    },
    async reloadItemsByDiscipline(): Promise<void> {
      this.loadUserEquipment()
      await this.$refs.disciplineInfo?.loadDisciplineData()
    },
  },
})
</script>
<style lang="scss" scoped>
@import '@/assets/styles/components/icons/equipment-icons.scss';

#equipment {
  &.equipment {
    display: flex;
    flex-direction: column;
    height: 100%;

    main {
      width: 100%;
      flex: 1 1 0%;
      display: flex;
      flex-direction: column;

      .left-side,
      .right-side {
        height: 42.75rem;
      }

      .left-side {
        width: 27.375rem;
        margin-left: 2.25rem;
        margin-right: 1rem;
      }

      .right-side {
        width: 100%;
        position: relative;
      }
    }

    .icon-settings-wrapper {
      width: 5rem;
      height: 4.375rem;
      transform: $skewX-value;
      @if $isWsm {
        border: 0.125rem solid #315f7f;
        background-image: linear-gradient(to top, #112437, #1b4d6d);
      }
      @if $isSsm {
        border: 0.125rem solid #456786;
        background-image: linear-gradient(to top, #354d69, #446286);
      }

      & > div {
        transform: $skewX-revert-value;
        width: 2.563rem;
        height: 2.563rem;
      }
    }

    .eq-discipline {
      background: linear-gradient(180deg, #254257, #3c7196);
      border: 0.125rem solid #3f6b93;
      width: 4.375rem;
      height: 4.375rem;
    }
  }
}
</style>
