<template>
  <transition name="hamburger">
    <aside
      v-show="show"
      ref="wrapper"
      class="hamburger-menu-wrapper h-full w-auto absolute top-0 right-0"
      @click="clickOutside"
    >
      <header class="hamburger-menu-header w-full relative flexing">
        <p class="font-bold text-texts-standard-important text-34 flexing uppercase">
          {{ $t('map.options') }}
        </p>

        <app-control-icon control="close" class="close-icon absolute" @click="toggle" />
      </header>

      <section class="hamburger-menu-content w-full">
        <app-scrollbar width="100%" height="70%" scroll="y" slide="y" class="hamburger-menu-scroll">
          <menu class="hamburger-menu">
            <div v-for="(item, index) in menuItems" :key="index" class="hamburger-menu-box">
              <router-link
                v-if="item.condition || item.condition === false ? item.condition : true"
                class="hamburger-menu-item relative flex items-center"
                :to="{ name: $getWebView(item.route) }"
                @click="doAction(item)"
              >
                <app-hamburger-icon class="hamburger-menu-item-icon" :icon-name="item.icon" />
                <p class="text-texts-standard-additional text-34 font-bold uppercase">
                  {{ getText(item.text) }}
                </p>
                <div
                  v-if="item.route === 'News' && getUnreadNewsCount"
                  class="hamburger-menu-item-notifications not-italic absolute right-20 flexing text-22"
                >
                  {{ getUnreadNewsCount }}
                </div>
                <div
                  v-else-if="item.route === 'MailReceived' && getUnreadMailCount"
                  class="hamburger-menu-item-notifications not-italic absolute right-20 flexing text-22"
                >
                  {{ getUnreadMailCount }}
                </div>
                <div
                  v-else-if="item.notifications"
                  class="hamburger-menu-item-notifications not-italic absolute right-20 flexing text-22"
                >
                  {{ item.notifications }}
                </div>
                <div
                  v-else-if="item.showNotificationExclamationMark"
                  class="hamburger-menu-item-notifications not-italic absolute right-20 flexing text-22"
                >
                  !
                </div>
              </router-link>
            </div>
          </menu>
        </app-scrollbar>
      </section>

      <footer class="hamburger-menu-footer absolute bottom-0 w-full">
        <section class="hamburger-menu-footer-controls flexing">
          <section class="hamburger-menu-footer-controls-section items-center" @click="toggleSound">
            <p
              class="not-italic text-24 uppercase"
              :class="{
                'text-texts-standard-default': $isSsm,
              }"
            >
              {{ $t('map.sounds') }}
            </p>
            <app-hamburger-icon :class="sound ? '' : 'off'" icon-name="sound" />
          </section>
          <section class="hamburger-menu-footer-controls-section items-center" @click="toggleMusic">
            <p
              class="not-italic text-24 uppercase"
              :class="{
                'text-texts-standard-default': $isSsm,
              }"
            >
              {{ $t('map.music') }}
            </p>
            <app-hamburger-icon :class="music ? '' : 'off'" icon-name="music" />
          </section>
        </section>

        <user-id-box v-if="show" />
      </footer>
      <info-popup
        v-if="showLogoutPopup"
        :popup-title="$t('map.logoutText')"
        @close="showLogoutPopup = false"
      >
        <div class="popup-logout" :class="{ 'popup-web': !isMobileLocalStorage }">
          <div class="popup-text-styles">
            <p
              v-if="userData?.anonymous && isMobileLocalStorage"
              class="text-texts-standard-important text-40"
            >
              {{ $t('map.warningLogout') }}
            </p>
            <p class="text-texts-standard-default text-36 mt-4">
              {{ $t('map.logoutQuestion') }}
            </p>
          </div>
          <aside class="flexing flex-col">
            <div class="flex justify-evenly items-center w-full mb-6">
              <app-button
                :btn-type="'confirm'"
                :btn-text="$t('minigame.yes')"
                @click="callLogout()"
              />
              <app-button
                :btn-type="'danger'"
                :btn-text="$t('map.cancel')"
                @click="showLogoutPopup = false"
              />
            </div>
            <app-button
              v-if="userData?.anonymous && isMobileLocalStorage"
              :btn-type="'primary'"
              :btn-text="$t('map.linkAccount')"
              btn-size="xl"
              class="m-2"
              @click="doFlutterCall('link-account')"
            />
          </aside>
        </div>
      </info-popup>
    </aside>
  </transition>
</template>

<script lang="ts">
import AppHamburgerIcon from '@/components/GlobalComponents/AppHamburgerIcon.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import UserIdBox from '@/components/Settings/UserIdBox.vue'
import { CLUBS_ROUTE_NAME, DEV, QA, ROLE } from '@/globalVariables'
import {
  getIsMobileLocalStorage,
  isFlutterApp,
  musicTheme,
  sendToFlutter,
  sfxSound,
} from '@/helpers'
import { logoutUser } from '@/helpers/misc'
import { useMailStore } from '@/store/pinia/mailStore'
import { useNewsStore } from '@/store/pinia/newsStore'
import { useUserStore } from '@/store/pinia/userStore'
import { useAccountStore } from '@/store/pinia/accountStore'
import { SystemConfigNames } from '@/interfaces/User'
import { isMobile as isMobilePlugin } from '@/plugins'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  getIsMobileLocalStorage: typeof getIsMobileLocalStorage
  sendToFlutter: typeof sendToFlutter
  isFlutterApp: typeof isFlutterApp
  setMusic: string
  appEnv: ImportMetaEnv['VITE_ENV']
  DEV: typeof DEV
  QA: typeof QA
  ROLE: typeof ROLE
  showLogoutPopup: boolean
}

interface HamburgerMenuItemData {
  id?: string
  text: string
  icon: string
  route: string
  notifications?: number
  showNotificationExclamationMark?: boolean
  condition?: boolean
  flutterCall?: string
  action?: string
}

export default defineComponent({
  name: 'HamburgerMenu',
  components: {
    AppHamburgerIcon,
    UserIdBox,
    InfoPopup,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['toggleHamburgerMenu', 'hideHamburgerMenu'],
  data(): ComponentData {
    return {
      getIsMobileLocalStorage,
      sendToFlutter,
      isFlutterApp,
      setMusic: '{\r\n  "event": "setMusic",\r\n "enabled": true\r\n}',
      appEnv: import.meta.env.VITE_ENV,
      DEV,
      QA,
      ROLE,
      showLogoutPopup: false,
    }
  },
  computed: {
    ...mapState(useMailStore, ['getUnreadMailCount']),
    ...mapState(useNewsStore, ['getUnreadNewsCount']),
    ...mapState(useAccountStore, ['hasVerifiedAccount']),
    ...mapState(useUserStore, {
      sound: 'getSoundVolume',
      music: 'getMusicVolume',
      userData: 'getUserData',
    }),
    isMobileLocalStorage(): boolean {
      return getIsMobileLocalStorage() === 1
    },
    menuItems(): HamburgerMenuItemData[] {
      return [
        {
          id: 'instructions',
          text: 'instructions',
          icon: 'tutorial',
          route: 'Instructions',
        },
        // { text: 'faq', icon: 'faq', route: 'Faq' },
        { id: 'rules', text: 'rules', icon: 'rules', route: 'Rules' },
        { id: 'contact', text: 'contact', icon: 'contact', route: 'Contact' },
        {
          id: 'settings',
          text: 'settings',
          icon: 'settings',
          route: 'Settings',
          showNotificationExclamationMark: !this.hasVerifiedAccount,
        },
        {
          id: 'news',
          text: 'news',
          icon: 'news',
          notifications: 0,
          route: 'News',
        },
        { text: 'post', icon: 'post', notifications: 0, route: 'MailReceived' },
        { id: 'bugs', text: 'bugs', icon: 'bugs', route: 'BugReport' },
        {
          id: 'link-account',
          text: 'linkAccount',
          icon: 'connectAccount',
          route: isMobilePlugin() ? 'LayoutView' : 'CrossAccountLinking',
          condition: !this.hasVerifiedAccount,
          // eslint-disable-next-line no-useless-escape
          flutterCall: '{\r\n  "event": "connectAccount"\r\n}',
        },
        {
          id: 'logout',
          text: 'logout',
          icon: 'log-off',
          route: this.$route.name === CLUBS_ROUTE_NAME ? CLUBS_ROUTE_NAME : 'LayoutView',
          action: 'logout',
          // eslint-disable-next-line no-useless-escape
          flutterCall: `{\r\n  "event": "${isFlutterApp() ? 'disconnect' : 'login'}"\r\n}`,
        },
        {
          id: 'flutter-test',
          text: 'flutter',
          icon: 'admin',
          route: 'FlutterTest',
          condition:
            this.appEnv === this.DEV || this.appEnv === this.QA || this.userData?.role === 'Admin',
        },
      ]
    },
  },
  methods: {
    ...mapActions(useUserStore, ['setSystemConfig']),
    toggle(): void {
      this.$emit('toggleHamburgerMenu')
    },
    hide(): void {
      this.$emit('hideHamburgerMenu')
    },
    clickOutside(e: Event): void {
      if (e.target !== this.$refs.wrapper) return
      this.hide()
    },
    doAction(param: HamburgerMenuItemData): void {
      if (param.action !== 'logout') {
        if (param.flutterCall) sendToFlutter(param.flutterCall)
      }
      if (param.action) {
        if (param.action === 'logout') {
          this.showLogoutPopup = true
        }
      }
      this.toggle()
    },
    callLogout(): void {
      if (this.isMobileLocalStorage) this.doFlutterCall('logout')
      // ak je mobil zavolaj flutter call ktory presmeruje na flutter okno s vyberom uctu
      else logoutUser() // ak je web tak zavolame logoutUser ktory vymaze token coz nas presmeruje na login obrazovku
    },
    doFlutterCall(what: string): void {
      const link = this.menuItems.find((item: HamburgerMenuItemData): boolean => item.id === what)
      sendToFlutter(link.flutterCall)
      this.showLogoutPopup = false
      this.toggle()
    },
    getText(text: string): string {
      if (text === 'flutter') return 'Flutter Test'
      return this.$t('map.' + text)
    },
    toggleMusic(): void {
      const isMobile = isMobilePlugin()

      if (!isMobile) {
        this.music ? musicTheme.stop() : musicTheme.play()
      }

      // eslint-disable-next-line no-useless-escape
      this.setMusic = '{\r\n  "event": "setMusic",\r\n "enabled": ' + !this.music + ' \r\n}'
      sendToFlutter(this.setMusic)
      this.setSystemConfig([{ name: SystemConfigNames.Music, value: !this.music ? 1 : 0 }])
    },
    toggleSound(): void {
      sfxSound.volume(!this.sound ? 1 : 0)
      this.setSystemConfig([{ name: SystemConfigNames.Sounds, value: !this.sound ? 1 : 0 }])
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/menu.scss';
@import '@/assets/styles/components/hamburger-menu.scss';

.popup-logout {
  width: 56.125rem;
  max-height: 28.625rem;
}

.popup-text-styles {
  padding: 2rem 4rem;
  height: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
