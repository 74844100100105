import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const carsLeftTopToRightTopAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'carsLeftTopToRightTopAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '12',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 12,
        end: 9,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsLeftTopToRightTopAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '09',
      delay: 8,
      duration: 600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 9,
        end: 8,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsLeftTopToRightTopAnimations3',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '08',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 8,
        end: 12,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsLeftTopToRightTopAnimations4',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '12',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 12,
        end: 15,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsLeftTopToRightTopAnimations5',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '15',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 15,
        end: 18,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsLeftTopToRightTopAnimations6',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '18',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 18,
        end: 19,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsLeftTopToRightTopAnimations7',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '19',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 19,
        end: 20,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsLeftTopToRightTopAnimations8',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '20',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 20,
        end: 22,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsLeftTopToRightTopAnimations9',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '22',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 22,
        end: 19,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsLeftTopToRightTopAnimations10',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '19',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 19,
        end: 17,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsLeftTopToRightTopAnimations11',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '17',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 17,
        end: 14,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsLeftTopToRightTopAnimations12',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '14',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 14,
        end: 13,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsLeftTopToRightTopAnimations13',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '13',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 13,
        end: 12,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsLeftTopToRightTopAnimations14',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '12',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 12,
        end: 11,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsLeftTopToRightTopAnimations15',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '11',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 11,
        end: 20,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsLeftTopToRightTopAnimations16',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '20',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 20,
        end: 21,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carsLeftTopToRightTopAnimations17',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '21',
      delay: 0,
      duration: 1100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 21,
        end: 22,
        zeroPad: 2,
      },
    },
  },
]
