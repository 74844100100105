import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const carsRightToBottomTunelPath: PathObjectInterface = {
  mainPath: { x: 3435, y: 586 },
  spline: [
    3435, 586, 3437, 618, 3441, 669, 3456, 718, 3492, 836, 3512, 905, 3529, 957, 3543, 1021, 3549,
    1068, 3546, 1152, 3536, 1195, 3517, 1229, 3478, 1250, 3426, 1254, 3353, 1260, 3296, 1273, 3238,
    1290, 3143, 1316, 3086, 1332, 3049, 1343, 3012, 1361, 2989, 1375, 2950, 1415, 2930, 1439, 2868,
    1469, 2785, 1476, 2718, 1459, 2536, 1389, 2457, 1350, 2400, 1324, 2384, 1312, 2435, 1300, 2550,
    1276, 2606, 1266, 2673, 1248, 2700, 1243,
  ],
}
