import type { RewardedItemsPositionsInterface } from '@/map-phaser-new/interfaces'

export const rewardedItemsPositionsData: RewardedItemsPositionsInterface[] = [
  { x: 923, y: 864 },
  { x: 2412, y: 1166 },
  { x: 1283.7499923147263, y: 1147.9999924302103 },
  { x: 1830, y: 1234 },
  { x: 1681.2499863915148, y: 1102.9999931007626 },
  { x: 1759.9999852180486, y: 1182.9999919086697 },
  { x: 1811.249984454364, y: 1126.74999274686 },
  { x: 1919.9999828338625, y: 1250.4999909028415 },
  { x: 2139.9999795556078, y: 1242.9999910146003 },
  { x: 1377, y: 872 },
  { x: 2591.2499728314588, y: 781.7499978877606 },
  { x: 2687, y: 709 },
  { x: 2789, y: 666 },
  { x: 2627.4999722912917, y: 421.7500032521785 },
  { x: 2541.249973576517, y: 291.75000518932933 },
  { x: 2867.499968715013, y: 298.00000509619707 },
  { x: 2695, y: 377 },
  { x: 1881.249983411283, y: 361.75000414624805 },
  { x: 2288, y: 1090 },
  { x: 1663.749986652285, y: 568.0000010728836 },
  { x: 1451.2499898187818, y: 500.50000207871204 },
  { x: 1320, y: 293 },
  { x: 1511, y: 483 },
  { x: 1373.7499909736218, y: 315.00000484287733 },
  { x: 2233, y: 435 },
]
