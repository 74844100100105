<template>
  <div
    :id="id"
    ref="wsm_scroll"
    :class="`wsm-scrollbar ${scrollPosition} ${randomClass} ${hideScrollbar ? 'hide-scroll' : ''}`"
    :style="{ height: height, scrollSnapType: slide, width: width }"
    @scroll="checkPosition"
  >
    <div
      v-if="verticalArrows"
      class="scroll-arrow-up"
      :class="{ disabled: !up }"
      @click="arrowScrollUp"
    />
    <slot />
    <div
      v-if="emitOnScrolledBottom"
      ref="observer-trigger"
      class="observer-trigger relative bottom-48 right-48"
    />
    <div
      v-show="arrows"
      ref="arrows"
      class="arrows"
      :class="{
        'arrows--mobile': $isMobile(),
        'arrows--mobile-safe-indented': scrollArrowsSafeZoneIndented,
      }"
    >
      <div
        v-show="right"
        ref="arrowRight"
        :class="`arrow-right scroll__arrow absolute right-0 ${arrowPos}`"
        @click="arrowScrollRight()"
        @mousedown="arrowHoldAutoScroll('right')"
        @mouseup="clearScrollInterval()"
      />
      <div
        v-show="left"
        ref="arrowLeft"
        :class="`arrow-left scroll__arrow absolute left-0 ${arrowPos}`"
        @click="arrowScrollLeft()"
        @mousedown="arrowHoldAutoScroll('left')"
        @mouseup="clearScrollInterval()"
      />
    </div>
    <div
      v-if="verticalArrows"
      class="scroll-arrow-down"
      :class="{ disabled: !bottom }"
      @click="arrowScrollBottom"
    />
  </div>
</template>

<script lang="ts">
import { createRandomId } from '@/helpers'
import { defineComponent } from 'vue'

import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  left: boolean
  right: boolean
  up: boolean
  bottom: boolean
  randomClass: string
  scrollTimeOut: ReturnType<typeof setInterval> | null
  observer: Nullable<IntersectionObserver>
}

export default defineComponent({
  props: {
    height: {
      type: String,
      default: '5rem',
    },
    width: {
      type: String,
      default: '5rem',
    },
    slide: {
      type: String,
      default: 'x',
      validator(value: string): boolean {
        return ['x', 'y'].includes(value)
      },
    },
    scroll: {
      type: String,
      default: 'x',
      validator(value: string): boolean {
        return ['x', 'y'].includes(value)
      },
    },
    scrollTo: {
      type: Number,
      default: 0,
    },
    scrollToElement: {
      type: String,
      default: '',
    },
    scrollToElementAlign: {
      type: String,
      default: 'center',
      validator(value: string): boolean {
        return ['center', 'start', 'end', 'nearest'].includes(value)
      },
    },
    arrows: {
      // CLICK ARROW ON BOTH SIDES OF SCROLLBAR
      type: Boolean,
      default: false,
    },
    arrowClickScrollSize: {
      // SET LENGTH SIZE OF SLIDE AFTER CLICK ON ARROW
      type: Number,
      default: 200,
    },
    arrowPos: {
      type: String,
      default: '',
    },
    arrowPosLeft: {
      // ak nechceme sipky na left:0
      type: String,
      default: '0rem',
    },
    arrowPosRight: {
      // ak nechceme sipky na right:0
      type: String,
      default: '0rem',
    },
    arrowsZIndex: {
      // ak potrebujeme nastavit sipkam konkretny z-index
      type: Number,
      default: 2,
    },
    // ID needed to emit scrolledToBottom event
    id: {
      type: String,
      default: createRandomId('default-scroll'),
    },
    hideScrollbar: {
      type: Boolean,
      default: false,
    },
    verticalArrows: {
      type: Boolean,
      default: false,
    },
    scrollArrowsSafeZoneIndented: {
      type: Boolean,
      default: false,
    },
    emitOnScrolledBottom: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['scrolledToBottom'],
  data(): ComponentData {
    return {
      left: false,
      right: true,
      up: false,
      bottom: true,
      randomClass: createRandomId('scroll'),
      scrollTimeOut: null,
      observer: null,
    }
  },
  computed: {
    scrollSlide(): string {
      return this.slide === 'x' ? 'x proximity' : 'y proximity'
    },
    scrollPosition(): string {
      return this.scroll === 'x' ? 'x-scroll' : 'y-scroll'
    },
    maxScroll(): number {
      return (
        document.querySelector(`.${this.randomClass}`)?.scrollWidth -
        document.querySelector(`.${this.randomClass}`)?.clientWidth
      )
    },
    maxScrollVertical(): number {
      return (
        document.querySelector(`.${this.randomClass}`)?.scrollHeight -
        document.querySelector(`.${this.randomClass}`)?.clientHeight -
        5
      )
    },
    xContainer(): HTMLElement {
      return document.querySelector(`.${this.randomClass}`)
    },
  },
  watch: {
    scrollTo(): void {
      this.$nextTick((): void => this.scrollToPosition())
    },
    $route(): void {
      if (this.scrollToElement) {
        this.$nextTick((): void => this.scrollToPosition())
      }
    },
  },
  created(): void {
    if (this.scrollTo !== 0 || this.scrollToElement)
      this.$nextTick((): void => this.scrollToPosition())
  },
  mounted(): void {
    const scrollXContainer = document.querySelector(this.id ? `.x-scroll#${this.id}` : '.x-scroll')
    if (scrollXContainer) {
      scrollXContainer.addEventListener(
        'wheel',
        (evt: WheelEvent) => this.wheelListenerX(evt, scrollXContainer),
        { passive: true },
      )
    }

    if (this.id && !this.$isMobile()) {
      const scrollYContainer = document.querySelector(`#${this.id}`)
      if (scrollYContainer) {
        scrollYContainer.addEventListener(
          'wheel',
          (evt: WheelEvent) => this.scrollYListener(evt, scrollYContainer),
          { passive: true },
        )
      }
    }

    if (this.emitOnScrolledBottom) {
      this.observer = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]): void => {
          entries.forEach(({ isIntersecting }: IntersectionObserverEntry): void => {
            if (!isIntersecting) return
            if (this.scroll === 'y') {
              this.$emit('scrolledToBottom')
            }
          })
        },
        {
          root: this.$refs.wsm_scroll as HTMLElement,
          rootMargin: '0px',
          threshold: 0,
        },
      )

      this.observer.observe(this.$refs['observer-trigger'] as HTMLElement)
    }

    // setup web arrows styles through props
    this.$refs.arrowLeft.style.setProperty('--arrow-pos-left', this.arrowPosLeft)
    this.$refs.arrowRight.style.setProperty('--arrow-pos-right', this.arrowPosRight)
    this.$refs.arrows.style.setProperty('--arrows-z-index', this.arrowsZIndex)

    this.checkPosition()
  },
  beforeUnmount(): void {
    if (this.observer) {
      this.observer.disconnect()
      this.observer = null
    }
  },
  methods: {
    checkPosition(): void {
      if (!this.$refs.wsm_scroll) return

      const wsmScrollElement = this.$refs.wsm_scroll as HTMLElement
      this.left = wsmScrollElement.scrollLeft > 0
      this.right = !(Math.ceil(wsmScrollElement.scrollLeft) >= this.maxScroll)
      this.up = wsmScrollElement.scrollTop > 0
      this.bottom = !(Math.ceil(wsmScrollElement.scrollTop) >= this.maxScrollVertical)
    },
    wheelListenerX(evt: WheelEvent, container: Nullable<Element>): void {
      evt.stopPropagation()
      if (container) container.scrollLeft += evt.deltaY
    },
    scrollYListener(evt: WheelEvent, container: Nullable<Element>): void {
      evt.stopPropagation()
      if (container) container.scrollTop += evt.deltaY
    },
    scrollToPosition(): void {
      if (this.scrollToElement) {
        const scrolledElement = document.querySelector(this.scrollToElement)
        scrolledElement?.scrollIntoView({
          behavior: 'smooth',
          inline: this.scrollToElementAlign as ScrollLogicalPosition,
          block: 'center',
        })
        return
      }
      const scrollXContainer = document.querySelector(
        this.id ? `.x-scroll#${this.id}` : '.x-scroll',
      )
      const scrollYContainer = document.querySelector(
        this.id ? `.y-scroll#${this.id}` : '.y-scroll',
      )
      if (scrollXContainer) {
        scrollXContainer.scrollLeft = this.scrollTo
      }
      if (scrollYContainer) {
        scrollYContainer.scrollTop = this.scrollTo
      }
    },
    arrowHoldAutoScroll(side: 'left' | 'right'): void {
      this.scrollTimeOut = setInterval(() => {
        side === 'right' ? this.arrowScrollRight() : this.arrowScrollLeft()

        if (
          (side === 'left' && this.xContainer.scrollLeft === 0) ||
          (side === 'right' && this.xContainer.scrollLeft >= this.maxScroll)
        ) {
          this.clearScrollInterval()
        }
      }, 90)
    },
    arrowScrollRight(): void {
      this.left = this.xContainer.scrollLeft > 0
      this.right = !(this.xContainer.scrollLeft >= this.maxScroll)
      this.xContainer.scrollLeft += this.arrowClickScrollSize
    },
    arrowScrollLeft(): void {
      this.right = this.xContainer.scrollLeft < this.maxScroll
      this.xContainer.scrollLeft -= this.arrowClickScrollSize
    },
    arrowScrollBottom(): void {
      if (!this.bottom) return
      this.up = this.xContainer.scrollTop > 0
      this.bottom = !(this.xContainer.scrollTop >= this.maxScrollVertical)
      this.xContainer.scrollTop += this.arrowClickScrollSize
    },
    arrowScrollUp(): void {
      if (!this.up) return
      this.bottom = this.xContainer.scrollTop < this.maxScrollVertical
      this.xContainer.scrollTop -= this.arrowClickScrollSize
    },
    clearScrollInterval(): void {
      clearInterval(this.scrollTimeOut)
    },
  },
})
</script>

<style lang="scss" scoped>
.wsm-scrollbar {
  width: 30rem;
  scrollbar-width: thin;

  @if $isWsm {
    scrollbar-color: #9cc2dd #0f3248;
  }

  @if $isSsm {
    scrollbar-color: #ededed rgba(12, 23, 36, 0.5);
  }
}

.x-scroll {
  overflow-x: auto;
  overflow-y: hidden;
}

.y-scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

.wsm-scrollbar::-webkit-scrollbar-thumb {
  background: #9cc2dd;
}

.wsm-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #0f3248;
}

.wsm-scrollbar::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  -webkit-appearance: none;
}

.hide-scroll {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.wsm-scrollbar::-webkit-scrollbar-corner {
  background: #3e0e0e00;
}

.arrow-position {
  position: absolute;
  top: 60%;
}

.arrows {
  .arrow {
    &-left {
      left: var(--arrow-pos-left);
    }
    &-right {
      right: var(--arrow-pos-right);
    }

    &-left,
    &-right {
      z-index: var(--arrows-z-index);
    }
  }

  &--mobile {
    &-safe-indented {
      .arrow {
        &-left {
          left: env(safe-area-inset-left);
        }
        &-right {
          right: env(safe-area-inset-right);
        }
      }
    }
  }
}

.scroll-arrow-up,
.scroll-arrow-down {
  background-size: cover;
  background-repeat: no-repeat;
  width: 5.5rem;
  height: 2.75rem;
  cursor: pointer;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: auto;

  &.disabled {
    cursor: default;
  }
}

.scroll-arrow-up {
  background-image: url($path-components + 'arrows/arrow-up.avif');
  top: -3rem;

  &.disabled {
    background-image: url($path-components + 'arrows/arrow-up-disabled.avif');
  }
}

.scroll-arrow-down {
  bottom: -4rem;
  background-image: url($path-components + 'arrows/arrow-down.avif');

  &.disabled {
    background-image: url($path-components + 'arrows/arrow-down-disabled.avif');
  }
}
</style>
