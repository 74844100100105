<template>
  <iframe
    ref="iframe"
    class="w-full h-full absolute inset-0"
    :src="`${IFRAME_URL}/set-params?${queryString}`"
  />
</template>

<script lang="ts">
import { gameToken } from '@/helpers'
import { useBoardGamesEventStore } from '@/store/pinia/boardGamesEventStore'
import { BoardGamesEventPostMessageType } from '@/interfaces/BoardGamesEventPostMessageInterface'
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'

export interface MessageData {
  data: {
    type: BoardGamesEventPostMessageType
    storeId?: string
  }
}

interface ComponentData {
  IFRAME_URL: string
}

export default defineComponent({
  data(): ComponentData {
    return {
      IFRAME_URL: import.meta.env.VITE_BOARD_GAMES_EVENT_URL,
    }
  },
  computed: {
    ...mapState(useBoardGamesEventStore, ['eventQueryString']),
    queryString(): string {
      return (
        this.eventQueryString +
        (Object.keys(this.$route.query).length
          ? `&${new URLSearchParams(this.$route.query as Record<string, string>).toString()}`
          : '') +
        `&gameToken=${gameToken}`
      )
    },
  },
  async created(): Promise<void> {
    await this.loadEvent()
  },
  mounted(): void {
    window.addEventListener('message', this.onMessage, { passive: true })
  },
  beforeUnmount(): void {
    window.removeEventListener('message', this.onMessage)
  },
  methods: {
    ...mapActions(useBoardGamesEventStore, ['loadEvent']),
    onMessage({ data }: MessageData | any): void {
      // FIXME: Bolo by dobre opravit priamo v minihre.
      // Preposleme si povodnu spravu do iframe, lebo minihra ju emituje svojmu parentovi, co nie je spravne v kontexte minihry ako modulu.
      if (this.$refs.iframe) {
        this.$refs.iframe.contentWindow.postMessage(data, '*')
      }

      const { type } = data as MessageData['data']
      if (!type?.startsWith('BGE/')) return

      switch (type) {
        case BoardGamesEventPostMessageType.Close: {
          this.$router.replace('/')
          break
        }
        case BoardGamesEventPostMessageType.MiniGameOpen:
          if (!this.$refs.iframe) return
          this.$refs.iframe.closest('.web-modal-wrapper').classList.add('is-fullscreen')
          break
        case BoardGamesEventPostMessageType.MiniGameClose:
          if (!this.$refs.iframe) return
          this.$refs.iframe.closest('.web-modal-wrapper').classList.remove('is-fullscreen')
          break
      }
    },
  },
})
</script>

<style lang="scss" scoped></style>
