<template>
  <main>
    <div
      :class="getParamData(paramType.value).next_refresh ? 'justify-between' : 'justify-center'"
      class="parameter-info flex items-center"
    >
      <div v-if="getParamData(paramType.value).next_refresh" class="parameter-info-value">
        <animated-number
          :param="getParamData(paramType.value)"
          class="text-texts-standard-default text-36"
        />
      </div>

      <div
        class="main-header text-texts-standard-default flexing text-50 relative"
        :class="{ 'active-pass': isRookiePassActive }"
      >
        <p>1</p>
        <app-main-icon :icon-name="paramType.specialConsumable.value" :icon-size="56" />
        <p>= {{ valuePerConsumable ?? '-' }}</p>
        <app-main-icon :icon-name="paramType.icon" :icon-size="56" />
        <div v-if="isRookiePassActive" class="absolute pass-info">
          <pass-activated-info
            :pass-name="`premiumGroup.wsm_starter_pass`"
            :type="paramType.specialConsumable.value"
            :date-time="rookiePassInfo.remaining_datetime"
          />
        </div>
      </div>

      <div v-if="nextRefresh" class="flexing timer text-28 text-texts-standard-default">
        <p class="not-italic revert-skew">
          {{ plusOrMinus }}{{ getParamData(paramType.value).refresh_amount }}
        </p>
        <app-main-icon :icon-name="paramType.icon" :icon-size="32" class="revert-skew" />
        <app-icon icon-name="time" class="timer-icon revert-skew" />
        <p class="text-texts-standard-important not-italic revert-skew">
          {{ formatTime(...formatSecondsToHMS(nextRefresh)) }}
        </p>
      </div>
    </div>
    <section>
      <div class="counter-wrapper flex flex-col">
        <div v-if="isFreeRedeem" class="event-free-redeem-message mx-auto relative">
          <p class="text-32 font-bold italic uppercase">{{ $te('eventpass') }}</p>
          <tippy
            theme="WSM"
            placement="top"
            class="absolute -top-1/2 right-0"
            max-width="32.5rem"
            max-height="15rem"
          >
            <app-icon icon-name="info-50" />
            <template #content>
              <img
                :src="`${pathImages}/events/${eventAssetsDirectory}/free-redeem-event-logo.webp`"
                alt="Event logo"
              />
              <hr class="divider mt-2 mb-4" />
              <p
                class="text-24 font-normal italic uppercase"
                v-html="
                  $replacePlaceholder(
                    $replaceHtmlPlaceholders(
                      $replacePlaceholder($te('freeUsagePerDay'), '{number}', '{b}{number}{/b}'),
                      'b',
                      'text-texts-standard-important',
                    ),
                    '{number}',
                    1,
                  )
                "
              />
              <p class="text-24 font-normal italic">
                {{
                  $replacePlaceholder(
                    $replacePlaceholder(
                      $te('freeUsageExpiration'),
                      '{datetime}',
                      getFormattedEventEndDate,
                    ),
                    '{pass}',
                    $te('eventpass'),
                  )
                }}
              </p>
            </template>
          </tippy>
        </div>
        <p v-else class="text-texts-standard-additional text-32 text-main">
          {{ $t(paramType.addParameterText) }}
        </p>
        <div
          class="add-supplement m-auto flex items-center text-60 font-bold relative"
          :class="{
            'opacity-50 pointer-events-none': isFreeRedeem,
          }"
        >
          <div
            class="text-texts-standard-dark minus flexing cursor-pointer"
            @click="countDownNumber"
          >
            <span :class="{ 'revert-skew': isWinterGame }"> - </span>
          </div>
          <div class="number-supplement text-texts-standard-default flexing">
            <span :class="{ 'revert-skew': isWinterGame }">
              {{ selectedAmount }}
            </span>
            <app-main-icon :icon-size="56" :icon-name="paramType.icon" />
          </div>
          <div
            v-show="showAddingAnimation"
            class="adding-animation absolute text-texts-standard-positive flexing"
          >
            <span :class="{ 'revert-skew': isWinterGame }"> +{{ selectedAmount }} </span>
            <app-main-icon :icon-size="56" :icon-name="paramType.icon" />
          </div>
          <div class="text-texts-standard-dark plus flexing cursor-pointer" @click="addNumber">
            <span :class="{ 'revert-skew': isWinterGame }">+</span>
          </div>
        </div>
      </div>
    </section>
    <section v-if="isFreeRedeem" class="buttons event-free-redeem-buttons flexing relative -top-4">
      <app-button
        btn-id="event-free-redeem-exclamation"
        btn-type="confirm"
        :notifications-count="1"
        class="event-free-redeem-button"
        :disabled="isClaimingFreeResources"
        @click="useFreeResource"
      >
        <component-loading v-if="isClaimingFreeResources" :is-loading="true" :height="'25%'" />
        <template v-else>{{ $te('freeUsageAction') }}</template>
      </app-button>
    </section>
    <section v-else class="buttons flex items-center justify-around">
      <app-multi-button1
        :btn-id="'add-parameter-' + paramType.specialConsumable.value + '-id'"
        :btn-type="paramType.specialConsumable.value"
        :btn-text="$t(paramType.specialConsumable.useText)"
        :btn-count="consumableToUse"
        :disabled="isConsumableDisabled || isDisabled"
        :loading="isDisabled"
        :check-count-props="isConsumableDisabled"
        @enough-resources-btn-click="useConsumables"
      >
      </app-multi-button1>
      <div class="line" />
      <app-multi-button1
        :btn-id="'add-parameter-' + paramType.specialConsumable.value + '-by-gems-id'"
        btn-type="credit"
        :btn-text="$t('button.useGems')"
        :btn-count="gemsToUse"
        :disabled="isGemDisabled"
        :popup-data="
          $filters.$confirmPopupData({
            title: $t(getConfirmTitle),
            text: $t(getConfirmText),
            btnId: 'add-parameter-by-gems-confirm-id',
            btnText: $t('common.recharge'),
            btnValue: gemsToUse,
          })
        "
        @enough-resources-btn-click="showGemConfirm = true"
        @redirect-close="closePopup"
        @action="useGems"
      />
    </section>
  </main>
</template>

<script lang="ts">
import AnimatedNumber from '@/components/AnimatedNumber.vue'
import PassActivatedInfo from '@/components/PassActivatedInfo.vue'
import {
  ENERGY,
  OFFER_STATES,
  pathImages,
  premiumEventPassClaimFreeResources,
} from '@/globalVariables'
import {
  formatSecondsToHMS,
  formatTime,
  getFormattedDateFromYMD,
  getFormattedTimeFromHMS,
  toKebabCase,
} from '@/helpers'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { ParamType, HeaderParam } from '@/interfaces/HeaderParams'
import type { Nullable } from '@/interfaces/utils'
import { GameType } from '@/globalVariables/gameTypes'

interface ComponentData {
  pathImages: typeof pathImages
  selectedAmount: number
  gemsToUse: number
  consumableToUse: number
  showAddingAnimation: boolean
  showGemConfirm: boolean
  isClaimingFreeResources: boolean
  GameType: typeof GameType
}

export default defineComponent({
  name: 'ParameterPopupBody',
  components: {
    AnimatedNumber,
    PassActivatedInfo,
  },
  props: {
    paramType: {
      type: Object as PropType<Nullable<HeaderParam>>,
      default: () => null,
    },
    totalSpecialConsumables: {
      type: Number,
      default: 0,
    },
    valuePerConsumable: {
      type: Number,
      default: 0,
    },
    gemValuePerConsumable: {
      type: Number,
      default: 0,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['useSpecialConsumables', 'buyOffer', 'closeOffer'],
  data(): ComponentData {
    return {
      pathImages,
      selectedAmount: 0,
      gemsToUse: 0,
      consumableToUse: 0,
      showAddingAnimation: false,
      showGemConfirm: false,
      isClaimingFreeResources: false,
      GameType,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      rookiePassInfo: 'getRookiePassInfo',
    }),
    ...mapState(useResponseTaskStore, {
      headerData: 'getHeaderParams',
      getNextRefreshStarts: 'getNextRefreshStarts',
      getNextRefreshEnergy: 'getNextRefreshEnergy',
      gems: 'getGems',
      getEnergy: 'getEnergy',
      getStarts: 'getStarts',
    }),
    ...mapState(useEventInfoStore, {
      eventAssetsDirectory: 'getEventAssetsDirectory',
      eventEnd: 'getEventEnd',
    }),
    isGemDisabled(): boolean {
      return this.gemsToUse === 0
    },
    isConsumableDisabled(): boolean {
      return this.consumableToUse > this.totalSpecialConsumables || this.consumableToUse === 0
    },
    nextRefresh(): number {
      return this.paramType.nextRefresh in this &&
        typeof this[this.paramType.nextRefresh] === 'number'
        ? this[this.paramType.nextRefresh]
        : null
    },
    isEnergyParamType(): boolean {
      return this.paramType?.value === ENERGY
    },
    plusOrMinus(): string {
      const parameter = this.isEnergyParamType ? this.getEnergy : this.getStarts
      return parameter.value > parameter.max_value ? '' : '+'
    },
    getConfirmText(): string {
      if (this.isEnergyParamType) return 'parameters.rechargeEnergyConfirm'
      else return 'parameters.rechargeStartsConfirm'
    },
    getConfirmTitle(): string {
      if (this.isEnergyParamType) return 'parameters.energy_drink'
      else return 'parameters.wild_card'
    },
    isRookiePassActive(): boolean {
      return this.rookiePassInfo.state === OFFER_STATES.active
    },
    isFreeRedeem(): boolean {
      return !!(this.isEnergyParamType ? this.getEnergy : this.getStarts)?.free_redeem
    },
    isWinterGame() {
      return this.$gameName === GameType.Winter
    },
    getFormattedEventEndDate(): string {
      if (!this.eventEnd) return ''
      const date = getFormattedDateFromYMD(this.eventEnd)
      const time = getFormattedTimeFromHMS(this.eventEnd)
      return `${date} ${time}`
    },
  },
  watch: {
    paramType(): void {
      this.selectedAmount = 0
      this.gemsToUse = 0
      this.consumableToUse = 0
    },
  },
  mounted(): void {
    this.addNumber()
  },
  methods: {
    formatTime,
    formatSecondsToHMS,
    toKebabCase,
    countDownNumber(): void {
      if (this.selectedAmount > 0) {
        this.selectedAmount -= this.valuePerConsumable
        this.gemsToUse -= this.gemValuePerConsumable
        this.consumableToUse -= 1
      }
    },
    addNumber(): void {
      if (this.gems.value > this.gemsToUse || this.totalSpecialConsumables > this.consumableToUse) {
        this.selectedAmount += this.valuePerConsumable
        this.gemsToUse += this.gemValuePerConsumable
        this.consumableToUse += 1
      }
    },
    getParamData(paramName: string): ParamType {
      return this.headerData.find((item: ParamType): boolean => item.parameter_name === paramName)
    },
    triggerAddingAnimation(): void {
      this.showAddingAnimation = true
      setTimeout((): void => {
        this.showAddingAnimation = false
      }, 1500)
    },
    useConsumables(): void {
      if (this.isConsumableDisabled || this.isDisabled) return

      this.$emit('useSpecialConsumables', this.selectedAmount / this.valuePerConsumable)
      this.triggerAddingAnimation()
    },
    useGems(): void {
      if (this.isGemDisabled) return
      this.$emit('buyOffer', this.selectedAmount / this.valuePerConsumable)
      this.triggerAddingAnimation()
      this.showGemConfirm = false
    },
    async useFreeResource(): Promise<void> {
      this.isClaimingFreeResources = true
      try {
        await this.$axios.post<{}, true>(premiumEventPassClaimFreeResources, {
          type: `event_pass_free_daily_${this.paramType?.value}`,
        })
      } catch (error: unknown) {
        console.error(error)
      }
      this.isClaimingFreeResources = false
    },
    closePopup(): void {
      this.$emit('closeOffer', true)
    },
  },
})
</script>

<style lang="scss" scoped>
main {
  display: grid;
  height: 31.125rem;
  position: relative;
  background: url($path-components + 'header/BG_BOX_TEXTURE.avif') center no-repeat;
  background-size: contain;
  background-position-x: 100%;

  .timer {
    height: 3.563rem;
    width: 13.5rem;
    background: linear-gradient(
      90deg,
      rgba(159, 29, 34, 0.8) 0%,
      rgba(195, 24, 24, 0.8) 42%,
      transparent 100%
    );
    transform: $skewX-value;

    &:before {
      content: '';
      width: 0.313rem;
      height: 100%;
      background: #ff1717;
      position: absolute;
      left: -0.45rem;
    }

    .timer-icon {
      transform: scale(0.7) $skewX-revert-value;
    }
  }

  .main-header {
    width: 27.4375rem;
    height: 4.5rem;

    @if $isWsm {
      background-image: linear-gradient(to right, transparent, #71c2ff54, transparent);
    }

    @if $isSsm {
      background-image: linear-gradient(
        to right,
        transparent,
        rgba(198, 207, 255, 0.3),
        transparent
      );
    }

    &.active-pass {
      background: linear-gradient(to right, transparent, #f6c717, transparent);
    }
  }

  .buttons {
    width: 85%;
    margin: 0 auto;

    .line {
      width: 0.1rem;
      height: 5rem;
      background: #a0dcff;
    }
  }

  .add-supplement {
    width: 51rem;
    height: 7.5rem;

    .minus,
    .plus {
      width: 5.6875rem;
      height: 5rem;
      transform: $skewX-value;

      @if $isWsm {
        background-image: linear-gradient(
          to top,
          rgba(154, 231, 255, 0.7),
          rgba(204, 239, 255, 0.7),
          #c6e9ff
        );
      }

      @if $isSsm {
        background-image: linear-gradient(to right, #6f7da6, #c6cfff);
        border: solid 0.125rem #d1d9ff;
      }

      &:active {
        transform: scale(0.95) $skewX-value;
      }
    }

    .number-supplement {
      width: 35.125rem;
      height: 5rem;
      transform: $skewX-value;
      border-radius: 0.125rem;
      margin: 0 1rem;

      @if $isWsm {
        background-color: rgb(37, 89, 126);
        box-shadow:
          inset -0.4375rem 0 1.3125rem 0 rgba(51, 123, 214, 0.19),
          inset -0.019625rem -0.1865rem 0.25rem 0 rgba(125, 190, 239, 0.48);
        text-shadow: 0.0401875rem 0.047875rem 0.058125rem rgba(0, 0, 0, 0.71);
      }

      @if $isSsm {
        background-image: linear-gradient(to right, transparent, #636790, #636790, transparent);
        text-shadow: 1.1px 1.7px 1px rgba(0, 0, 0, 0.46);
      }
    }

    .adding-animation {
      top: 1rem;
      right: 10rem;
      width: 35.125rem;
      height: 5rem;
      transform: $skewX-value;
      background: linear-gradient(to right, rgba(12, 30, 54, 0), #0c1e36, rgba(12, 30, 54, 0));
      text-shadow: 0.0401875rem 0.047875rem 0.058125rem rgba(0, 0, 0, 0.71);
      animation: fadeOut 1.5s;

      @keyframes fadeOut {
        0% {
          top: 1rem;
          opacity: 1;
        }

        100% {
          top: -10rem;
          opacity: 0;
        }
      }
    }
  }

  .counter-wrapper {
    margin-top: 2rem;
  }

  .parameter-info {
    width: 90%;
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 0.5rem;

    @if $isWsm {
      border-bottom: 0.063rem solid rgba(160, 220, 255, 0.6);
    }

    @if $isSsm {
      border-bottom: 0.063rem solid rgba(114, 114, 176, 0.6);
    }

    &-value {
      width: 10rem;
      background: linear-gradient(to right, rgba(1, 11, 28, 0), #010b1c, rgba(1, 11, 28, 0));
    }
  }

  .pass-info {
    transform: scale(0.8);
    right: 2rem;
  }

  .event-free-redeem {
    &-message {
      width: 23.125rem;
      color: #212a32;
      background: linear-gradient(
        90deg,
        rgba(222, 194, 108, 0) 0%,
        rgba(222, 194, 108, 1) 25%,
        rgba(221, 195, 113, 1) 48%,
        rgba(199, 167, 84, 1) 75%,
        rgba(222, 194, 108, 0) 100%
      );
      border-style: solid;
      border-width: 0.125rem;
      border-image-slice: 1;
      border-image-source: linear-gradient(
        90deg,
        rgba(246, 237, 176, 0) 0%,
        rgba(246, 237, 176, 1) 25%,
        rgba(246, 237, 176, 1) 75%,
        rgba(246, 237, 176, 0) 100%
      );
    }

    &-buttons {
      width: 56.25rem;
      height: 5.625rem;
      background-image: linear-gradient(
        to right,
        transparent,
        rgba(108, 158, 196, 0.5),
        rgba(108, 158, 196, 0.5),
        transparent
      );
    }

    &-button {
      width: 31.5rem;
    }
  }
}

.divider {
  @if $isWsm {
    border-color: #75b6e7;
  }

  @if $isSsm {
    border-color: #4c648f;
  }
}
</style>
