<template>
  <div v-if="hideTooltip" :style="leagueBackgroundImage" class="league-bg" />
  <tippy v-else theme="WSM" placement="left">
    <div :style="leagueBackgroundImage" class="league-bg" />
    <template #content>
      <div class="tooltip-content text-texts-standard-default text-30">
        {{ $t(`event.league${capitalizeFirstLetter(league)}`) }}
      </div>
    </template>
  </tippy>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  props: {
    league: {
      type: String,
      default: '',
    },
    hideTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    leagueBackgroundImage(): string {
      return `background-image: url(${this.pathImages}events/playoff/league-${this.league}.avif);`
    },
  },
  methods: {
    capitalizeFirstLetter(string: string): string {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },
})
</script>

<style lang="scss" scoped>
.league-bg {
  width: 6.75rem;
  height: 2.375rem;
  @include background(null);
}
</style>
