export const LOGIN_VIEW_ROUTE_NAME = 'LoginView'
export const RESET_PASSWORD_ROUTE_NAME = 'ResetPassword'
export const SET_LOGIN_ROUTE_NAME = 'SetLogin'
export const EQUIPMENT_ROUTE_NAME = 'Equipment'
export const EQUIPMENT_WEB_ROUTE_NAME = 'EquipmentWeb'
export const EQUIPMENT_DETAIL_ROUTE_NAME = 'EquipmentDetail'
export const EQUIPMENT_DETAIL_WEB_ROUTE_NAME = 'EquipmentDetailWeb'
export const FOCUS_DISCIPLINE_BRANCH_DETAIL_CLOSE_BUTTON = 'focusDisciplineBranchDetailCloseButton'
export const ADMIN_TOKEN_EXTRACTOR_ROUTE_NAME = 'AdminTokenExtractor'
export const CLUBS_ROUTE_NAME = 'ClubsMap'
export const MAINTENANCE_ROUTE_NAME = 'MaintenanceView'
export const LAYOUT_VIEW_ROUTE_NAME = 'LayoutView'
export const LAB_DISCIPLINES_ROUTE_NAME = 'LabDisciplines'
export const LAB_ECONOMICS_ROUTE_NAME = 'LabEconomics'
export const LAB_CAMPS_ROUTE_NAME = 'LabCamps'
export const LAB_DISCIPLINES_WEB_ROUTE_NAME = 'LabDisciplinesWeb'
export const LAB_ECONOMICS_WEB_ROUTE_NAME = 'LabEconomicsWeb'
export const LAB_CAMPS_WEB_ROUTE_NAME = 'LabCampsWeb'
