<template>
  <div
    :id="'offer-' + offerData.type"
    :data-cy="'offer-' + offerData.type"
    class="offer-box relative cursor-pointer"
    :style="{
      background: `url(${pathImages}premium/offers/offer-${getGameName}_piggy-bank-${reward}.avif) center no-repeat`,
      backgroundSize: 'contain',
    }"
    @click="showOffer"
  >
    <footer class="offer-box-footer flex items-center absolute w-full flex-col justify-around">
      <app-timer
        v-if="offerData.remaining_time"
        icon="refresh-md"
        :time="offerData.remaining_time"
        @countdown-action="$emit('reloadData')"
      />
      <app-button
        btn-id="piggy-bank-offer-button"
        btn-type="secondary"
        :btn-text="$t('button.detail')"
        btn-size="md"
      />
    </footer>
  </div>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { usePremiumStore } from '@/store/pinia/premiumStore'

import { mapActions } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { Offer, Property } from '@/interfaces/premium/Offer'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'OffersPiggyBank',
  props: {
    offerData: {
      type: Object as PropType<Nullable<Offer>>,
      default: () => null,
    },
  },
  emits: ['reloadData'],
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    getGameName(): string {
      if (this.$isWsm) {
        return 'wsm'
      }
      if (this.$isSsm) {
        return 'ssm'
      }
      return ''
    },
    reward(): string {
      if (this.offerData?.parameter?.name) {
        return this.offerData.parameter.name.toLowerCase()
      }
      if (this.offerData?.content) {
        return (this.offerData?.content as Property).parameter_name.toString().toLowerCase()
      }
      return ''
    },
  },
  methods: {
    ...mapActions(usePremiumStore, {
      hideOfferDetail: 'hideOfferDetail',
    }),
    showOffer(): void {
      this.hideOfferDetail()
      this.$router.push({ name: this.$getWebView('PremiumPiggyBank') })
    },
  },
})
</script>

<style lang="scss" scoped>
.offer-box {
  height: 44.875rem;
  width: 78.75rem;
  margin-right: 1.5rem;
  position: relative;

  &-main {
    height: 31.5rem;

    &-title {
      text-align: center;
      position: absolute;
      top: 7.5rem;
      height: 2.875rem;
    }
  }

  &-footer {
    bottom: 0.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 11.6rem;
  }
}
</style>
