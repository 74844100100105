<template>
  <section
    class="teaser-scroll flex flex-1 justify-end overflow-hidden"
    :class="{
      'is-discipline-event': namespace === 'discipline',
      'is-teaser-active': eventTeaserActive,
    }"
    :data-event-theme="namespace"
  >
    <app-scrollbar width="71.375rem" height="auto" scroll="y" slide="y" class="activer">
      <p class="text-32 teaser-scroll-info text-texts-standard-default m-auto">
        {{
          $replacePlaceholder(
            $replacePlaceholder(
              $te(
                eventTeaserActive
                  ? eventDisciplineNew
                    ? 'eventStartingNew'
                    : 'eventStarting'
                  : 'eventStarted',
              ),
              '{event}',
              eventName,
            ),
            '{discipline}',
            $t(`discipline.discipline_${eventDisciplineId}`),
          )
        }}
      </p>
      <article
        v-for="(article, i) in articles"
        :key="i"
        class="flex flex-col items-center m-auto mb-4"
      >
        <div v-if="article.header" class="header flexing px-4 -mt-2 relative">
          <p class="gradient-text-light uppercase font-bold">
            {{ article.header }}
          </p>
        </div>
        <div v-if="article.header && !i" class="img flex mx-auto my-4" />
        <section
          v-else-if="article.text || article.urls"
          class="text-wrapper w-full flex items-center justify-between text-texts-standard-default"
          :class="{ reverse: i % 2 === 0, 'text-wrapper--spaced': !article.urls }"
        >
          <p
            class="text-wrapper-text text-28 text-left -mt-2"
            :class="{ 'text-wrapper-text--base': !article.urls }"
          >
            {{ !!article.urls ? '' : '•' }} {{ article.text }}
          </p>
          <teaser-dynamic-image
            v-if="article.urls"
            :class="{ reverse: i % 2 === 0 }"
            :image-data="article.urls"
          />
        </section>
      </article>
    </app-scrollbar>
  </section>
</template>

<script lang="ts">
import { teaserSummary as TEASER_SUMMARY_ENDPOINT, pathImages } from '@/globalVariables'
import { EventType } from '@/interfaces/events/EventInfo'
import type TeaserSummaryApiResponse from '@/interfaces/responses/events/teaser/TeaserSummaryApiResponse'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import TeaserDynamicImage from './TeaserDynamicImage.vue'
import type { Nullable } from '@/interfaces/utils'

interface Article {
  header?: string
  text?: string
  urls?: string[]
}

interface ComponentData {
  summaryData: Nullable<TeaserSummaryApiResponse>
}

export default defineComponent({
  name: 'TeaserContent',
  components: {
    TeaserDynamicImage,
  },
  data(): ComponentData {
    return {
      summaryData: null,
    }
  },
  computed: {
    ...mapState(useDisciplineStore, ['getDisciplineUnlockLevel']),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
      eventName: 'getEventName',
      eventType: 'getEventType',
      eventDisciplineId: 'getEventDisciplineId',
      eventDisciplineNew: 'getEventDisciplineNew',
      eventAssetsDirectory: 'getEventAssetsDirectory',
      eventTeaserActive: 'getEventTeaserActive',
    }),
    articles(): Article[] {
      const isChallengeCup = this.namespace === 'challenge_cup'
      const isFrozenChampionship = this.namespace === 'frozen'
      // const isDisciplineEvent = this.namespace === 'discipline'
      const isAutumnFair = this.namespace === 'autumn'

      return [
        // Tento 1. nadpis obsahuje obrazok.
        { header: this.$te('availableRewards') },
        { header: this.$te('eventMechanics') },
        {
          text: this.$replacePlaceholder(
            this.$te('eventInfoMechanic1'),
            '{discipline}',
            this.$t(`discipline.discipline_${this.eventDisciplineId}`),
          ),
          urls: [
            `${pathImages}events/${this.eventAssetsDirectory}/teaser/teaser-article-img-1-0.avif`,
            `${pathImages}events/${this.eventAssetsDirectory}/teaser/teaser-article-img-1-1.avif`,
          ],
        },
        {
          text: this.$replacePlaceholder(
            this.$replacePlaceholder(
              this.$replacePlaceholder(
                this.$replacePlaceholder(this.$te('eventInfoMechanic2'), '{event}', this.eventName),
                '{discipline}',
                this.$t(`discipline.discipline_${this.eventDisciplineId}`),
              ),
              '{level}',
              this.getDisciplineUnlockLevel(this.eventDisciplineId).toString(),
            ),
            '{2to4}',
            4, // TODO BE hodnota
          ),
          urls: [
            `${pathImages}events/${this.eventAssetsDirectory}/teaser/teaser-article-img-2-0.avif`,
            `${pathImages}events/${this.eventAssetsDirectory}/teaser/teaser-article-img-2-1.avif`,
          ],
        },
        {
          text: this.$te('eventInfoMechanic3'),
          urls: [
            `${pathImages}events/${this.eventAssetsDirectory}/teaser/teaser-article-img-3-0.avif`,
            `${pathImages}events/${this.eventAssetsDirectory}/teaser/teaser-article-img-3-1.avif`,
          ],
        },
        this.$te('eventInfoMechanic4') && {
          text: this.$te('eventInfoMechanic4'),
          urls: [
            `${pathImages}events/${this.eventAssetsDirectory}/teaser/teaser-article-img-4-0.avif`,
            `${pathImages}events/${this.eventAssetsDirectory}/teaser/teaser-article-img-4-1.avif`,
          ],
        },
        this.$te('eventInfoMechanic5') && {
          text: this.$te('eventInfoMechanic5'),
          urls: [
            `${pathImages}events/${this.eventAssetsDirectory}/teaser/teaser-article-img-5-0.avif`,
            `${pathImages}events/${this.eventAssetsDirectory}/teaser/teaser-article-img-5-1.avif`,
          ],
        },
        (isChallengeCup || isFrozenChampionship || isAutumnFair) && { header: this.$te('summary') },
        isChallengeCup && {
          text: this.$te('eventSummaryPlayoff'),
        },
        (isChallengeCup || isFrozenChampionship) && {
          text: this.$replacePlaceholder(
            this.$te('eventSummaryTasks'),
            '%s',
            this.summaryData?.tasks ? this.summaryData.tasks.toString() : '',
          ),
        },
        isFrozenChampionship && {
          text: this.$replacePlaceholder(
            this.$te('eventSummaryFrozenMastersPlayoff'),
            '%s',
            this.summaryData?.masters ? this.summaryData.masters.toString() : '',
          ),
        },
        isAutumnFair && {
          text: this.$te('autumnFairSummary1'),
        },
        isAutumnFair && {
          text: this.$te('autumnLeague'),
        },
        isAutumnFair && {
          text: this.$te('carnival'),
        },
        isAutumnFair && {
          text: this.$te('autumnFairSummary4'),
        },
        (isChallengeCup || isFrozenChampionship || isAutumnFair) && {
          text: this.$replacePlaceholder(
            this.$te('eventSummaryMilestones'),
            '%s',
            this.summaryData?.milestones?.individual
              ? this.summaryData.milestones.individual.toString()
              : '',
          ),
        },
        isFrozenChampionship && {
          text: this.$te('eventSummaryFrozenLeague'),
        },
        (isChallengeCup || isFrozenChampionship || isAutumnFair) && {
          text: this.$replaceUntranslatablePlaceholder(
            this.$te('eventSummaryEventPass'),
            '{eventpass}',
          ),
        },
        (isChallengeCup || isFrozenChampionship || isAutumnFair) && {
          text: this.$replaceUntranslatablePlaceholder(
            this.$te('eventSummaryChampionPass'),
            '{championpass}',
          ),
        },
        (isChallengeCup || isFrozenChampionship || isAutumnFair) && {
          text: this.$te('eventSummaryOffers'),
        },
        isChallengeCup && {
          text: this.$te('eventSummaryLuckyWheel'),
        },
      ].filter(Boolean)
    },
  },
  async created(): Promise<void> {
    if (this.eventType === EventType.DisciplineEvent) return
    await this.loadSummary()
  },
  methods: {
    async loadSummary(): Promise<void> {
      try {
        this.summaryData = await this.$axios.get<{}, TeaserSummaryApiResponse>(
          TEASER_SUMMARY_ENDPOINT,
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='challenge_cup'] {
  --events-teaser-content-img: url('#{$path-events}challenge-cup/teaser/teaser-article-img.webp');

  @if $isWsm {
    --event-teaser-gradient: linear-gradient(to right, transparent 13%, #24588e, transparent 87%);
    --event-teaser-gradient-outline: linear-gradient(
      to right,
      transparent 13%,
      #5172b3,
      transparent 87%
    );
  }

  @if $isSsm {
    --event-teaser-gradient: linear-gradient(to right, transparent 13%, #24588e, transparent 87%);
    --event-teaser-gradient-outline: linear-gradient(
      to right,
      transparent 13%,
      #5172b3,
      transparent 87%
    );
  }
}

[data-event-theme='frozen'] {
  --events-teaser-content-img: url('#{$path-events}frozen-championship/teaser/teaser-article-img.webp');

  @if $isWsm {
    --event-teaser-gradient: linear-gradient(to right, transparent 13%, #166abd, transparent 87%);
    --event-teaser-gradient-outline: linear-gradient(
      to right,
      transparent 13%,
      #399dff,
      transparent 87%
    );
  }

  @if $isSsm {
    --event-teaser-gradient: linear-gradient(to right, transparent 13%, #24588e, transparent 87%);
    --event-teaser-gradient-outline: linear-gradient(
      to right,
      transparent 13%,
      #5172b3,
      transparent 87%
    );
  }
}

[data-event-theme='discipline'] {
  --events-teaser-content-img: url('#{$path-events}discipline-event/teaser/teaser-article-img.webp');

  @if $isWsm {
    --event-teaser-gradient: linear-gradient(to right, transparent 13%, #24588e, transparent 87%);
    --event-teaser-gradient-outline: linear-gradient(
      to right,
      transparent 13%,
      #5172b3,
      transparent 87%
    );
  }

  @if $isSsm {
    --event-teaser-gradient: linear-gradient(
      to right,
      transparent,
      #30446c 20%,
      #30446c 80%,
      transparent 100%
    );
    --event-teaser-gradient-outline: linear-gradient(
      to right,
      transparent 0%,
      rgba(255, 255, 255, 0.3) 25%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0.3) 75%,
      transparent 100%
    );
  }
}

[data-event-theme='autumn'] {
  --events-teaser-content-img: url('#{$path-events}autumn-fair/teaser/teaser-article-img.webp');

  @if $isWsm {
    --event-teaser-gradient: linear-gradient(to right, transparent 13%, #166abd, transparent 87%);
    --event-teaser-gradient-outline: linear-gradient(
      to right,
      transparent 13%,
      #399dff,
      transparent 87%
    );
  }

  @if $isSsm {
    --event-teaser-gradient: linear-gradient(to right, transparent 13%, #24588e, transparent 87%);
    --event-teaser-gradient-outline: linear-gradient(
      to right,
      transparent 13%,
      #5172b3,
      transparent 87%
    );
  }
}

.teaser-scroll {
  margin-right: 1rem;
  margin-bottom: 2rem;

  &.is-teaser-active {
    margin-bottom: 4.5rem;
  }

  .activer {
    margin-top: 0.5rem;
  }

  &-info {
    padding: 1.5rem 5rem 1rem 5rem;
  }

  .header {
    width: 72.25rem;
    height: 2.375rem;
    margin: 2.375rem 0;
    background-image: var(--event-teaser-gradient);
    border-width: 0.188rem;
    border-style: solid;
    border-image-source: var(--event-teaser-gradient-outline);
    border-image-slice: 1;

    p {
      height: 4rem;
      font-size: 3.75rem;
      line-height: 4rem;
    }
  }

  article {
    .text-wrapper {
      padding: 1rem 3rem;

      &.reverse {
        flex-direction: row-reverse;
      }

      &-text {
        width: 44.625rem;

        &--base {
          width: 100%;
          text-align: left;
        }
      }

      &--spaced {
        margin: 0;
        padding: 0 3rem;
      }
    }

    .img {
      width: 54.25rem;
      height: 14.1875rem;
      @include background(var(--events-teaser-content-img), contain);
    }
  }
}
</style>
