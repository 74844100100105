import {
  GEMS,
  ENERGY,
  STARTS,
  MONEY,
  UNIVERSAL_TRAINING_POINTS,
  PRESTIGE,
  RANKING_POINTS,
  EXPERIENCE,
  EVENT_REPUTATION,
} from '@/globalVariables'

const items = [
  MONEY,
  GEMS,
  UNIVERSAL_TRAINING_POINTS,
  UNIVERSAL_TRAINING_POINTS.toLowerCase(),
  ENERGY,
  STARTS,
  PRESTIGE,
  EXPERIENCE,
  EXPERIENCE.toLowerCase(),
  RANKING_POINTS,
  RANKING_POINTS.toLowerCase(),
  EVENT_REPUTATION,
  'piggy_bank_universal_training_points',
  'piggy_bank_gems',
  'piggy_bank_money',
]

// TODO presunut do type utils
type Flatten<T> = T extends (infer U)[] ? U : T

type Item = Flatten<typeof items>

export const setMultiplierText = (item: Item): string => (!items.includes(item) ? 'x' : '')
