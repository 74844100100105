import type { InteractiveObjectsInterface } from '@/map-phaser-new/interfaces'
import { clubsMapBuildings } from './clubsMapBuildings'
import { tutorial, buildingRewards } from '@/map-phaser-new/config/summer-sports/commonObjects'

export const interactiveObjectsConfig: InteractiveObjectsInterface = {
  buildings: clubsMapBuildings,
  additionalConfigs: {
    tutorial,
    buildingRewards,
  },
}
