<template>
  <button
    :id="btnId"
    :data-cy="btnId"
    class="clickable-skew-element flexing btn mini-btn"
    :class="['btn-' + btnType, 'btn-size-' + addClass]"
    @click="$emit('click', $event)"
  >
    <div v-if="btnIcon" :data-cy="btnId + '-icon'" :class="`btn-icon icon-${btnIcon}-dark`" />
  </button>
</template>

<script lang="ts">
import { createRandomId } from '@/helpers'
import { defineComponent } from 'vue'

interface ComponentData {
  icon: string
}

export default defineComponent({
  name: 'AppMiniButton',
  props: {
    btnId: {
      type: String,
      default: createRandomId('button'),
    },
    btnType: {
      type: String,
      default: 'primary',
      validator(value: string): boolean {
        return ['primary', 'secondary', 'credit', 'danger', 'inactive', 'confirm'].includes(value)
      },
    },
    btnIcon: {
      type: String,
      required: true,
      validator(value: string): boolean {
        return ['save', 'edit'].includes(value)
      },
    },
    addClass: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  data(): ComponentData {
    return {
      icon: '',
    }
  },
})
</script>

<style lang="scss" scoped>
button.btn.mini-btn {
  width: 5.375rem;
  height: 3.688rem;
  justify-content: center;
  align-items: center;

  .btn-icon {
    width: 2.375rem;
    height: 2.375rem;
  }
}
</style>
