<template>
  <div
    class="level-bag-big items-center relative flex flex-col justify-center"
    :class="[`bag-${packType}`, { 'clickable-element cursor-pointer': !purchased }]"
    @click="!purchased ? buyPack(storeId) : () => {}"
  >
    <span class="bag-name text-texts-standard-default text-48 font-bold uppercase absolute flexing">
      <p>
        {{ packType }}
      </p>
    </span>
    <span class="bag-icon absolute flexing" :class="{ purchased }" />
    <span class="bag-value absolute">
      <app-plate-value color="red" :text-value="`<span class='font-bold'>${value}x</span> value`" />
    </span>
    <div class="flexing bag-footer">
      <p
        v-if="purchased"
        class="claimed-text text-texts-standard-positive text-40 uppercase font-bold"
      >
        {{ $t('premiumGroup.purchased') }}
      </p>
      <app-price v-else>
        <p>{{ `${$filters.$formatNumber(price.price)} ${price.currency}` }}</p>
      </app-price>
    </div>
    <payment-terminal
      v-if="terminal"
      :item-id="storeId"
      :wire-transfer-off="true"
      @close="terminal = false"
    />
  </div>
</template>

<script lang="ts">
import AppPlateValue from '@/components/GlobalComponents/AppPlateValue.vue'
import AppPrice from '@/components/GlobalComponents/AppPrice.vue'
import PaymentTerminal from '@/components/Popup/Premium/PaymentTerminal.vue'
import { metaPremiumPackEndpoint, metaPremiumPayEndpoint } from '@/globalVariables'
import { getIsMobileLocalStorage, sendToFlutter } from '@/helpers'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type Price from '@/interfaces/Price'
import type PremiumPackApiResponse from '@/interfaces/responses/premium/PremiumPackApiResponse'
import type PremiumPayApiResponse from '@/interfaces/responses/premium/PremiumPayApiResponse'

interface ComponentData {
  terminal: boolean
}

export default defineComponent({
  name: 'LevelBagBig',
  components: {
    AppPlateValue,
    PaymentTerminal,
    AppPrice,
  },
  props: {
    packType: {
      type: String,
      required: true,
      validator(propName: string): boolean {
        return ['silver', 'gold', 'diamond'].includes(propName)
      },
    },
    purchased: {
      type: Boolean,
      required: true,
    },
    price: {
      type: Object as PropType<Nullable<Price>>,
      default: () => null,
    },
    value: {
      type: Number,
      default: 0,
    },
    storeId: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      terminal: false,
    }
  },
  computed: {
    isMobileLocalStorage(): boolean {
      return getIsMobileLocalStorage() === 1
    },
  },
  methods: {
    ...mapActions(useResponseTaskStore, {
      showPaymentLoading: 'showPaymentLoading',
      showPaymentMessage: 'showPaymentMessage',
    }),
    async buyPack(storeId: string): Promise<void> {
      if (!this.isMobileLocalStorage && this.price.price > 0) {
        this.terminal = true
        return
      }

      this.showPaymentLoading()

      const responsePay = await this.$axios.get<{}, PremiumPayApiResponse>(
        `${metaPremiumPayEndpoint}/pack/${storeId}${
          !this.isMobileLocalStorage ? '/bank_transfer' : ''
        }`,
      )
      if (responsePay?.event) {
        const message = {
          event: responsePay.event,
          productId: responsePay.productId,
        }
        sendToFlutter(JSON.stringify(message))
        return
      }

      const responsePack = await this.$axios.get<{}, PremiumPackApiResponse>(
        `${metaPremiumPackEndpoint}${storeId}`,
      )
      this.showPaymentMessage({
        pack: storeId,
        rewards: responsePack.rewards,
        failed: false,
        af_quantity: responsePack.firebaseData.af_quantity,
        af_store_id: responsePack.firebaseData.af_store_id,
        gtm_currency: responsePack.firebaseData.gtm_currency,
        gtm_revenue: responsePack.firebaseData.gtm_revenue,
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.level-bag-big {
  width: 31.625rem;
  height: 45.375rem;
  background-repeat: no-repeat;
  background-size: contain;

  .bag-icon {
    width: 27.375rem;
    height: 34.875rem;
    background-repeat: no-repeat;
    background-size: contain;
    top: 8.5rem;
  }

  .bag-name {
    width: 27.375rem;
    height: 6.25rem;
    top: 2rem;
  }

  .bag-value {
    top: 9.3rem;
    right: 3.5rem;
  }

  .bag-footer {
    position: absolute;
    bottom: 2rem;
    width: 22.5rem;
    height: 9.5rem;
  }

  &.bag-silver {
    background-image: url($path-premium + 'bags/packs/big-box-bg-silver.webp');

    .bag-icon {
      background-image: url($path-premium + 'bags/packs/bag-img-silver.webp');

      &.purchased {
        background-image: url($path-premium + 'bags/packs/bag-img-silver-purchased.webp');
      }
    }
  }

  &.bag-gold {
    background-image: url($path-premium + 'bags/packs/big-box-bg-gold.webp');

    .bag-icon {
      background-image: url($path-premium + 'bags/packs/bag-img-gold.webp');

      &.purchased {
        background-image: url($path-premium + 'bags/packs/bag-img-gold-purchased.webp');
      }
    }
  }

  &.bag-diamond {
    background-image: url($path-premium + 'bags/packs/big-box-bg-diamond.webp');

    .bag-icon {
      background-image: url($path-premium + 'bags/packs/bag-img-diamond.webp');

      &.purchased {
        background-image: url($path-premium + 'bags/packs/bag-img-diamond-purchased.webp');
      }
    }
  }

  .claimed-text {
    &:before {
      content: '[';
      margin-right: 1rem;
      font-style: normal;
      @if $isWsm {
        color: #5199c6;
      }
      @if $isSsm {
        color: #7b87b8;
      }
    }

    &:after {
      content: ']';
      margin-left: 1rem;
      font-style: normal;
      @if $isWsm {
        color: #5199c6;
      }
      @if $isSsm {
        color: #7b87b8;
      }
    }
  }
}
</style>
