import { useCoreStore } from '@/store/pinia/coreStore'
import CustomCoreMapScene from './parentClass/CustomCoreMapScene'
import { SCENES_KEYS } from '../constants'
import { isIpad, isIphone } from '@/plugins'

export default class MainMapScene extends CustomCoreMapScene {
  constructor() {
    super(SCENES_KEYS.MainMapScene)
  }

  create() {
    this.runInitMethods()
  }

  private async runInitMethods(): Promise<void> {
    await this.setUpCommonSceneFunctionality()

    const useCore = useCoreStore()
    if (isIphone() || isIpad()) {
      // this fix is related to bug: SSM-1895
      // for some reason - from time to time - calculations at cameraHandler
      // are not correct,
      // so we have to refresh scale to correct that values
      // after everything is set up
      this.game.scale.refresh()
    }

    useCore.setLoadingScreen(false)
  }
}
