<template>
  <transition name="container">
    <div
      v-if="isType"
      class="w-full h-full ts-container absolute"
      :class="{ 'hide-overlay': hideOverlay }"
    >
      <div
        v-if="tutorial && tutorialText"
        class="tutorial-wrapper absolute w-full h-full"
        @click.passive="isFullText ? $emit('clickToNarrative') : showFullText()"
      >
        <div
          ref="tutorialTutor"
          class="tutorial-wrapper-tutor absolute"
          :style="[{ '--isTutorFlipped': isAnnieFlipped ? '1' : '0' }, tutorialBossStyle]"
          :class="{ 'tutorial-wrapper-tutor-flipped': isAnnieFlipped }"
        />

        <section
          ref="tutorialDialog"
          class="absolute tutorial-dialog"
          :class="{ 'tutorial-dialog-flipped': isAnnieFlipped }"
        >
          <header class="text-texts-standard-important text-40 uppercase text-left font-bold">
            <p>{{ tutorialBoss }}</p>
          </header>
          <div class="tutorial-dialog-wrapper">
            <p
              class="text-texts-standard-default center-p text-left"
              :class="{
                'text-36': $isWsm,
                'text-34': $isSsm,
              }"
            >
              <typewriter-effect
                :key="tutorialText"
                :type-speed="isFullText ? 0 : 20"
                :array="[tutorialText]"
                :icon-replace="UNIVERSAL_TRAINING_POINTS"
                @show-full-text="showFullText()"
              />
            </p>

            <p
              v-show="isFullText"
              class="text-texts-standard-important text-34 flex justify-end bottom-p"
            >
              {{ $t('minigame.clickToContinue') }}
            </p>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import TypewriterEffect from '@/components/TypewriterEffect.vue'
import { pathFigures, UNIVERSAL_TRAINING_POINTS } from '@/globalVariables'
import { getDisciplineBossSlug } from '@/helpers'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  trainer: boolean
  boss: boolean
  isFullText: boolean
  UNIVERSAL_TRAINING_POINTS: typeof UNIVERSAL_TRAINING_POINTS
}

export default defineComponent({
  name: 'StoryContainer',
  components: { TypewriterEffect },
  props: {
    story: {
      type: Boolean,
      default: false,
    },
    tutorial: {
      type: Boolean,
      default: false,
    },
    tutorialText: {
      type: String,
      default: '',
    },
    tutorialBoss: {
      type: String,
      default: 'anne',
    },
    tutorialPose: {
      type: String,
      default: 'pose_1',
    },
    // TODO: rename in the project to a universal story character
    isAnnieFlipped: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['clickToNarrative'],
  data(): ComponentData {
    return {
      trainer: false,
      boss: false,
      isFullText: false,
      UNIVERSAL_TRAINING_POINTS,
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      actualStage: 'getActualStage',
    }),
    isType(): boolean {
      return this.story || this.tutorial
    },
    tutorialBossStyle(): string {
      const slug = getDisciplineBossSlug(this.tutorialBoss)
      return `background-image: url(${pathFigures}/${slug.replace('-', '')}/${slug}-${
        this.tutorialPose
      }.avif);`
    },
    hideOverlay(): boolean {
      return this.actualStage?.name === 'openCareerWindowNarrativeCareer'
    },
  },
  watch: {
    tutorialText(val: string): void {
      if (val) {
        this.isFullText = false
      }
    },
  },
  mounted(): void {
    this.animate()
  },
  updated(): void {
    this.resetAnimation()
    this.animate()
  },
  beforeUnmount(): void {
    this.resetAnimation()
  },
  methods: {
    hide(): void {
      this.trainer = true
    },
    showFullText(): void {
      if (!this.isFullText) {
        this.isFullText = true
      }
    },
    animate(): void {
      // setTimeout is key to achieving animated states with classes
      // in this case, the ideal is 100, or even smaller values may be suitable
      setTimeout((): void => {
        this.$refs.tutorialTutor.classList.add('tutorial-wrapper-tutor--moved')
        this.$refs.tutorialDialog.classList.add('tutorial-dialog--revealed')
      }, 100)
    },
    resetAnimation(): void {
      this.$refs.tutorialTutor.classList.remove('tutorial-wrapper-tutor--moved')
      this.$refs.tutorialDialog.classList.remove('tutorial-dialog--revealed')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/main-icons.scss';

.ts-container {
  z-index: 4;
  background: rgba(0, 0, 0, 0.6);
  top: 0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 10%;
    left: 0;
    background: rgb(0, 0, 0);
    z-index: -1;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  &.hide-overlay {
    background: none;
  }

  .career-story {
    &-header {
      height: 9.1875rem;
      background: rgb(0, 0, 0);

      &-text {
        width: 71.6875rem;
        height: 4.875rem;
        background: linear-gradient(
          to right,
          rgba(20, 74, 115, 0),
          rgba(20, 74, 115, 1),
          rgba(20, 74, 115, 0)
        );
      }
    }

    &-player {
      background: url($path-career + 'components/FIGURE_1.avif') center no-repeat;
      background-size: contain;
      bottom: 0rem;
      z-index: 1;
      width: 40.9375rem;
      height: 54.8125rem;
    }

    .bossTalk {
      z-index: -1;
      opacity: 0.7;
    }

    &-boss {
      background: url($path-career + 'components/FIGURE_2.avif') center no-repeat;
      background-size: contain;
      bottom: 0rem;
      right: 1.375rem;
      z-index: 1;
      width: 29.75rem;
      height: 52.8125rem;
    }

    &-dialog {
      bottom: 17.375rem;
      left: 36.625rem;
      width: 63.625rem;
      min-height: 14rem;
      border: solid 2px rgb(69, 129, 175);
      background-color: rgba(24, 60, 88, 0.9);
      box-shadow:
        inset 1px 0px 20px 0px rgba(25, 73, 105, 0.8),
        inset 12px 3px 20px 0px rgba(25, 73, 105, 0.8),
        inset 0px 0px 20px 0px rgba(25, 73, 105, 0.8),
        inset -11px -3px 20px 0px rgba(25, 73, 105, 0.8);
      z-index: 2;

      .content-header {
        height: 3.25rem;
        margin-top: 1rem;

        header {
          width: 35rem;
          height: 3.25rem;
          background: linear-gradient(to right, rgba(20, 74, 115, 1), rgba(20, 74, 115, 0));
          padding-left: 2.3125rem;
        }
      }

      &-text {
        margin-top: 1.75rem;
        width: 58.437rem;
        height: auto;
        margin-left: 1.875rem;
        line-height: 1.2;
      }

      &-reward {
        height: 27.9375rem;
        margin-top: 1.5625rem;

        &:before {
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          content: '';
          top: 0rem;
          width: 60.75rem;
          height: 0.0625rem;
          background: #438eb8;
        }

        .text-quest {
          width: 35.875rem;
          margin: 0 auto;
          padding: 3.375rem 0 2.25rem 0;
          text-shadow: 1.1px 1.7px 1px rgba(0, 0, 0, 0.46);
        }

        .progress-numbers {
          margin-top: 2.4375rem;
          text-shadow: 1.1px 1.7px 1px rgba(0, 0, 0, 0.46);
        }

        footer {
          height: 5.8125rem;
          background: #0b2c4f;
          margin-top: 2.4375rem;
          text-shadow: 1.1px 1.7px 1px rgba(0, 0, 0, 0.46);

          .footer-rewards {
            margin-left: 1.9375rem;
          }
        }
      }
    }

    &-footer {
      height: 9.1875rem;
      background: rgb(0, 0, 0);
      bottom: 0rem;

      .footer-btn-story {
        left: 1.875rem;
        top: 25%;
        z-index: 1;
      }

      .footer-btn-continue {
        top: 25%;
        right: 1.875rem;
        z-index: 1;
      }
    }
  }

  .tutorial-wrapper {
    outline: 1500rem solid transparent;

    & * {
      pointer-events: none;
    }

    &-tutor {
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      @if $isWsm {
        width: 41.25rem;
      }

      @if $isSsm {
        width: 46.75rem;
      }

      height: 64.75rem;
      bottom: 0;
      left: -5rem;
      outline: 1500rem solid transparent;
      transform: translateX(0);
      opacity: 0;
      transition:
        transform 1s ease,
        opacity 1s ease;

      &-flipped {
        right: 5rem;
        left: auto;
      }

      &--moved {
        opacity: 1;
        // When --isTutorFlipped is 1, it translates by 3rem, otherwise, it translates by 8rem
        transform: translateX(
          calc(3rem * var(--isTutorFlipped, 0) + 8rem * (1 - var(--isTutorFlipped, 0)))
        );
      }
    }

    .tutorial-dialog {
      width: 80.5rem;
      min-height: 16.9375rem;
      bottom: 12%;
      left: 42.3rem;
      outline: 1500rem solid transparent;
      opacity: 0;
      transition: opacity 2s ease;

      &--revealed {
        opacity: 1;
      }

      @if $isWsm {
        background-color: rgba(24, 60, 88, 0.9);
        border: solid 2px rgb(69, 129, 175);
        box-shadow:
          inset 1px 0px 20px 0px rgba(25, 73, 105, 0.8),
          inset 12px 3px 20px 0px rgba(25, 73, 105, 0.8),
          inset 0px 0px 20px 0px rgba(25, 73, 105, 0.8),
          inset -11px -3px 20px 0px rgba(25, 73, 105, 0.8);
      }

      @if $isSsm {
        background-color: #252f45;
        border: solid 0.125rem #5672a4;
      }

      &-flipped {
        left: 30.3rem;
      }

      header {
        width: 35rem;
        height: 3.25rem;
        padding-left: 2.3125rem;
        margin-top: 1rem;
        white-space: nowrap;

        @if $isWsm {
          background-image: linear-gradient(to right, rgba(20, 74, 115, 1), transparent);
        }

        @if $isSsm {
          background-image: linear-gradient(to right, #4f5373, rgba(79, 83, 115, 0.8), transparent);
        }
      }

      &-wrapper {
        display: flex;
        justify-content: space-between;

        .center-p {
          width: 71%;
          margin-top: 2.3125rem;
          margin-bottom: 2.3125rem;
          margin-left: 2.4375rem;
        }

        .bottom-p {
          opacity: 1;
          animation: bounce 2s ease-in-out;
          position: absolute;
          right: 1.5rem;
          bottom: 1.5rem;
          width: 24%;

          &:active {
            transform: scale(0.95);
            padding: 0;
          }

          @keyframes bounce {
            0% {
              opacity: 1;
            }

            25% {
              opacity: 0;
            }

            50% {
              opacity: 1;
            }

            75% {
              opacity: 0;
            }

            100% {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.container-enter-from {
  background: rgba(0, 0, 0, 0);
}

.container-enter-to {
  background: rgba(0, 0, 0, 0.5);
}

.container-enter-active {
  transition: all 1s ease;
}
</style>
