import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const boatLeftToRightCheckpoints: CheckpointsObjectInterface = {
  key: 'boatLeftToRightCheckpoints',
  data: [
    {
      x: 571,
      y: 1468,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatLeftToRightAnimations1',
        },
      },
    },
    {
      x: 945,
      y: 1381,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatLeftToRightAnimations2',
        },
      },
    },
    {
      x: 1019,
      y: 1357,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatLeftToRightAnimations3',
        },
      },
    },
    {
      x: 1149,
      y: 1292,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatLeftToRightAnimations4',
        },
      },
    },
    {
      x: 1415,
      y: 1247,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatLeftToRightAnimations5',
        },
      },
    },
    {
      x: 1598,
      y: 1120,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatLeftToRightAnimations6',
        },
      },
    },
    {
      x: 1823,
      y: 1026,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatLeftToRightAnimations7',
        },
      },
    },
    {
      x: 2248,
      y: 964,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatLeftToRightAnimations8',
        },
      },
    },
    {
      x: 2546,
      y: 1024,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatLeftToRightAnimations9',
        },
      },
    },
    {
      x: 2585,
      y: 1161,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatLeftToRightAnimations10',
        },
      },
    },
    {
      x: 2882,
      y: 1194,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatLeftToRightAnimations11',
        },
      },
    },
    {
      x: 3129,
      y: 1055,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatLeftToRightAnimations12',
        },
      },
    },
    {
      x: 3628,
      y: 937,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'boatLeftToRightAnimations13',
        },
      },
    },
  ],
}
