import {
  RANKING_POINTS,
  arenaRankingClubsEndpoint,
  arenaRankingGlobalDisciplineEndpoint,
  arenaRankingGlobalEndpoint,
  arenaRankingNationalEndpoint,
} from '@/globalVariables'
import { addRanks } from '@/helpers/rankings'
import { internalAxios } from '@/plugins/vueAxios'
import type {
  ClubFormattedRanks,
  GlobalRankingInterface,
  Ranking,
  RankingClubsResponse,
} from '@/types/rankingTypes'
import { defineStore } from 'pinia'

const updateRankings = (rankings: Partial<GlobalRankingInterface>): void => {
  if (rankings?.ranking && Object.keys(rankings.ranking).length) {
    // sort and add rank for each user
    rankings.ranking = addRanks(rankings.ranking) as Ranking
  }
}

interface RankingStoreState {
  globalRankings: GlobalRankingInterface
  nationalRankings: Partial<GlobalRankingInterface>
  globalDisciplineRankings: Partial<GlobalRankingInterface>
  nationalDisciplineRankings: Partial<GlobalRankingInterface>
  clubRankings: ClubFormattedRanks[]
  playerClubRanking: ClubFormattedRanks
  isClubRankingsLoading: boolean
}

export const useRankingStore = defineStore('rankingStore', {
  state: (): RankingStoreState => ({
    globalRankings: {} as GlobalRankingInterface,
    nationalRankings: {} as Partial<GlobalRankingInterface>,
    globalDisciplineRankings: {} as Partial<GlobalRankingInterface>,
    nationalDisciplineRankings: {} as Partial<GlobalRankingInterface>,
    clubRankings: [] as ClubFormattedRanks[],
    playerClubRanking: {} as ClubFormattedRanks,
    isClubRankingsLoading: false,
  }),
  getters: {
    getGlobalRankings(): RankingStoreState['globalRankings'] {
      return this.globalRankings
    },
    getNationalRankings(): RankingStoreState['nationalRankings'] {
      return this.nationalRankings
    },
    getGlobalPosition(): RankingStoreState['globalRankings']['ranking']['player']['position'] {
      return this.globalRankings?.ranking?.player?.position
    },
    getNationalPosition(): RankingStoreState['nationalRankings']['ranking']['player']['position'] {
      return this.nationalRankings?.ranking?.player?.position
    },
    getGlobalDisciplineRankings(): RankingStoreState['globalDisciplineRankings'] {
      return this.globalDisciplineRankings
    },
    getNationalDisciplineRankings(): RankingStoreState['nationalDisciplineRankings'] {
      return this.nationalDisciplineRankings
    },
    getGlobalDisciplinePosition(): RankingStoreState['globalDisciplineRankings']['ranking']['player']['position'] {
      return this.globalDisciplineRankings?.ranking?.player?.position
    },
    getNationalDisciplinePosition(): RankingStoreState['nationalDisciplineRankings']['ranking']['player']['position'] {
      return this.nationalDisciplineRankings?.ranking?.player?.position
    },
    getClubRankings(): RankingStoreState['clubRankings'] {
      return this.clubRankings
    },
    getPlayerClubRanking(): RankingStoreState['playerClubRanking'] {
      return this.playerClubRanking
    },
    getTopClubRankings(): RankingStoreState['clubRankings'] {
      return this.clubRankings?.slice(0, 3)
    },
  },
  actions: {
    async loadGlobalRankings(): Promise<void> {
      const rankings = await internalAxios.get<{}, GlobalRankingInterface>(
        arenaRankingGlobalEndpoint,
      )
      updateRankings(rankings)
      this.globalRankings = rankings
    },
    async loadNationalRankings(country: string): Promise<void> {
      const rankings = await internalAxios.get<{}, Partial<GlobalRankingInterface>>(
        `${arenaRankingNationalEndpoint}/${country}`,
      )
      updateRankings(rankings)
      this.nationalRankings = rankings
    },
    async loadGlobalDisciplineRankings(id: number): Promise<void> {
      const rankings = await internalAxios.get<{}, Partial<GlobalRankingInterface>>(
        `${arenaRankingGlobalDisciplineEndpoint}/${id}`,
      )
      updateRankings(rankings)
      this.globalDisciplineRankings = rankings
    },
    async loadNationalDisciplineRankings(info: {
      country: string
      disciplineId: number
    }): Promise<void> {
      const rankings = await internalAxios.get<{}, Partial<GlobalRankingInterface>>(
        `${arenaRankingNationalEndpoint}/${info.country}/discipline/${info.disciplineId}`,
      )
      updateRankings(rankings)
      this.nationalDisciplineRankings = rankings
    },
    async loadClubRankings(): Promise<void> {
      this.isClubRankingsLoading = true
      const response = await internalAxios.get<{}, RankingClubsResponse>(arenaRankingClubsEndpoint)

      const finalRankings = Object.keys(response).reduce(
        (finalArray: ClubFormattedRanks[], userId: string): ClubFormattedRanks[] => {
          const formatRankings = (userId: string | number): ClubFormattedRanks => {
            return {
              clubId: response[userId].club_id,
              name: response[userId].name ?? '',
              points: Number(response[userId].RANKING_POINTS) ?? 0,
              members: response[userId].members ?? 0,
              type: RANKING_POINTS.toLowerCase() ?? '',
              clubLogoBgId: response[userId].club_background_logo_id ?? 0,
              clubLogoId: response[userId].club_logo_id ?? 0,
              position: response[userId].position ?? 0,
            }
          }

          if (userId === 'player') this.playerClubRanking = formatRankings('player')
          else finalArray.push(formatRankings(userId))

          return finalArray
        },
        [],
      )

      this.isClubRankingsLoading = false
      this.clubRankings = finalRankings.sort(
        (a: ClubFormattedRanks, b: ClubFormattedRanks): number => b.points - a.points,
      )
    },
  },
})
