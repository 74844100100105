<template>
  <div id="club-house-daily-task" class="relative">
    <div class="flexing flex-col bordered-5 text-texts-standard-default club-house-daily-task">
      <div class="club-house-daily-task-title w-full flex justify-between items-center">
        <div class="club-house-daily-task-title-empty" />
        <p
          class="text-36 font-bold uppercase flexing text-texts-standard-dark club-house-daily-task-title-text"
        >
          {{ $t('club.dailyTask') }}
        </p>
        <div class="flexing club-house-daily-task-title-timer">
          <app-timer
            class="club-house-daily-task-title-timer-component"
            :time="dailyTask.timeRemaining"
          />
        </div>
      </div>
      <div v-if="!isContentLoading" class="flexing text-30 club-house-daily-task-description">
        <p v-if="!isTaskCompleted">
          {{ $t(dailyTask.task.description) }}
        </p>
        <div v-else class="flexing">
          <p class="uppercase">
            {{ $t('club.taskCompleted') }}
          </p>
          <div class="icon icon-done-md ml-2" />
        </div>
      </div>

      <component-loading :height="'8.8rem'" :is-loading="isContentLoading" />
    </div>

    <div v-if="isTaskCompleted" class="is-completed" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { ClubDailyTask } from '@/interfaces/clubs/ClubDailyTasks'

export default defineComponent({
  name: 'ClubsHouseDailyTask',
  props: {
    dailyTask: {
      type: Object as PropType<ClubDailyTask>,
      required: true,
    },
  },
  computed: {
    isTaskCompleted(): boolean {
      if (this.dailyTask.state == null) return

      return this.dailyTask.state === 'completed'
    },
    isContentLoading(): boolean {
      return this.dailyTask.state == null || this.dailyTask.task.description == null
    },
  },
})
</script>

<style lang="scss" scoped>
main {
  position: relative;

  .club-house-daily-task {
    width: 99%;
    height: 12.813rem;
    background: url($path-clubs + 'components/clubhouse/BG_TASK_BOX.avif') center no-repeat;
    background-size: 100% 100%;
    border: 0.125rem solid #f6c717;
    margin-bottom: 0.688rem;

    &-title {
      height: 4.375rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      @if $isWsm {
        background: linear-gradient(
          90deg,
          rgba(210, 169, 14, 0) 0%,
          rgba(210, 169, 14, 1) 50%,
          rgba(210, 169, 14, 0) 100%
        );
      }
      @if $isSsm {
        background: linear-gradient(
          90deg,
          rgba(210, 169, 14, 0) 0%,
          rgba(210, 169, 14, 0.99) 50%,
          rgba(210, 169, 14, 0) 100%
        );
      }

      &-empty,
      &-timer {
        width: 26rem;
      }

      &-timer {
        justify-content: flex-end;
      }

      &-text {
        height: 4.375rem;
        text-shadow: none;
      }

      &-timer {
        &-component {
          min-width: 14rem;
        }
      }
    }

    &-description {
      width: 100%;
      height: 100%;
    }
  }

  .is-completed {
    background: rgba(54, 34, 10, 0.6);
    width: 99%;
    height: -webkit-fill-available;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
