<template>
  <div class="info-text">
    <app-icon :icon-name="icon" />
    <p class="text-texts-standard-default m-4 text-left" :class="'text-' + textSize">
      {{ text }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InfoText',
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'info-rounded',
    },
    textSize: {
      type: Number,
      default: 28,
    },
  },
})
</script>

<style lang="scss" scoped>
.info-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
</style>
