<template>
  <div v-if="!tooltipText" :style="style" :class="'clickable-element icon icon-' + iconName" />
  <div
    v-else
    v-tippy="
      tooltipText.length > 0
        ? {
            theme: 'WSM',
            content: tooltipText,
            placement: 'top',
          }
        : ''
    "
    :style="style"
    :class="'icon icon-' + iconName"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppIcon',
  props: {
    iconName: {
      type: String,
      required: true,
    },
    style: {
      type: String,
      default: '',
    },
    tooltipText: {
      type: String,
      default: '',
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/icons.scss';
</style>
