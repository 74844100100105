<template>
  <div id="mail-received-view" class="app-page-wrapper mail absolute">
    <menu-component
      menu-type="mail"
      :title="$t('post.heeader')"
      :menu-items="{
        1: {
          text: $t('post.received'),
          route: $getWebView('MailReceived'),
          notifications: getUnreadMailCount,
        },
        2: {
          text: $t('post.sent'),
          route: $getWebView('MailSent'),
        },
      }"
    />
    <section class="w-full h-full safe-area">
      <mail-header
        :options="options"
        :selected-all="selectedAll"
        @select-option="selectOption"
        @select-all="selectAll"
      />
      <mail-table
        :messages="messages"
        :selected-message-ids="selectedMessageIds"
        :sent="sent"
        @select-message="selectMessage"
        @load-more="loadMore"
      />
      <mail-footer :blocked-users="hasBlockedUsers" />
      <confirm-popup
        v-if="showConfirmDelete"
        :button-data="popupButton"
        :popup-title="popupTexts.title"
        :single-button-text="$t('common.confirm')"
        not-multi
        @close="showConfirmDelete = false"
        @action="deleteMessages"
      >
        <p class="text-texts-standard-default mt-10">
          {{ popupTexts.text }}
        </p>
      </confirm-popup>
    </section>
  </div>
</template>

<script lang="ts">
import MailFooter from '@/components/Mail/MailFooter.vue'
import MailHeader from '@/components/Mail/MailHeader.vue'
import MailTable from '@/components/Mail/MailTable.vue'
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import {
  mailMarkReadEndpoint,
  mailMarkUnreadEndpoint,
  mailReceivedEndpoint,
  mailSentEndpoint,
} from '@/globalVariables'
import { useMailStore } from '@/store/pinia/mailStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { MailMessage, FormattedMailMessage } from '@/interfaces/MailMessage'
import type { Nullable } from '@/interfaces/utils'

interface AllMailMessagesResponse {
  has_blocked_users: boolean
  messages: MailMessage[]
  unread_count: number
}

interface ComponentData {
  isLoading: boolean
  loadNoMore: boolean
  selectedAll: boolean
  messages: FormattedMailMessage[]
  selectedMessageIds: string[]
  hasBlockedUsers: boolean
  showConfirmDelete: boolean
  popupTexts: { title: string; text: string }
  popupButton: {
    btnId: string
    btnType: string
    btnSize: string
  }
  sentMessagesLimit: Nullable<number>
  receivedMessagesLimit: Nullable<number>
}

export default defineComponent({
  name: 'MailMessages',
  components: {
    MailHeader,
    MailTable,
    MailFooter,
    ConfirmPopup,
  },
  data(): ComponentData {
    return {
      isLoading: false,
      loadNoMore: false,
      selectedAll: false,
      messages: [],
      selectedMessageIds: [],
      hasBlockedUsers: false,
      showConfirmDelete: false,
      popupTexts: { title: 'Delete', text: 'Delete' },
      popupButton: {
        btnId: 'post-btn-confirm',
        btnType: 'danger',
        btnSize: 'md',
      },
      sentMessagesLimit: null,
      receivedMessagesLimit: null,
    }
  },
  computed: {
    ...mapState(useMailStore, {
      getUnreadMailCount: 'getUnreadMailCount',
    }),
    options(): { value: number; name: string }[] {
      return this.sent
        ? [{ value: 1, name: this.$t('post.delete') }]
        : [
            { value: 1, name: this.$t('post.delete') },
            { value: 2, name: this.$t('post.markAsRead') },
            { value: 3, name: this.$t('post.markAsUnread') },
          ]
    },
    sent(): boolean {
      return this.$route.path.includes('sent')
    },
  },
  watch: {
    $route(): void {
      this.loadMessages()
    },
  },
  created(): void {
    this.loadMessages()
  },
  methods: {
    ...mapActions(useMailStore, ['setMailUnread']),
    formatMessage(message: MailMessage): FormattedMailMessage {
      return {
        id: message.id,
        subject: message.subject,
        user: {
          country: this.sent
            ? message.receiver?.country?.three_letter_code
            : message.sender?.country?.three_letter_code,
          name: this.sent ? message.receiver?.username : message.sender?.username,
          id: this.sent ? message.receiver?.id : message.sender?.id,
          eventBadges: this.sent ? message.receiver?.event_badges : message.sender?.event_badges,
        },
        date: message.sent_at,
        status: message.read || this.sent ? 'opened' : 'new',
      }
    },
    reset(): void {
      this.selectedMessageIds = []
      this.selectedAll = false
      this.loadNoMore = false
      const scrollContainer = document.querySelector('#mail-table')
      if (scrollContainer) {
        scrollContainer.scrollTop = 0
      }
    },
    async loadMessages(): Promise<void> {
      let limitString = ''
      if (this.sent) {
        limitString = this.sentMessagesLimit ? `/limit/${this.sentMessagesLimit.toString()}` : ''
      } else {
        limitString = this.receivedMessagesLimit
          ? `/limit/${this.receivedMessagesLimit.toString()}`
          : ''
      }
      const response = await this.$axios.get<{}, AllMailMessagesResponse>(
        (this.sent ? mailSentEndpoint : mailReceivedEndpoint) + '/offset/0' + limitString,
      )

      if (!this.sent) {
        this.setMailUnread(response.unread_count)
      }
      this.hasBlockedUsers = response.has_blocked_users
      this.reset()

      if (!response.messages) {
        return
      }
      this.messages = response.messages.map(
        (message: MailMessage): FormattedMailMessage => this.formatMessage(message),
      )

      if (this.sent && this.messages.length) {
        this.sentMessagesLimit = this.messages.length
      } else {
        this.receivedMessagesLimit = this.messages.length
      }
    },
    selectMessage(messageId: string): void {
      if (this.selectedMessageIds.includes(messageId)) {
        this.selectedMessageIds = this.selectedMessageIds.filter(
          (id: string): boolean => id !== messageId,
        )
      } else {
        this.selectedMessageIds.push(messageId)
      }
    },
    selectAll(): string | void {
      this.selectedAll = !this.selectedAll
      if (!this.selectedAll) {
        this.selectedMessageIds = []
      } else {
        this.selectedMessageIds = this.messages.map((msg: FormattedMailMessage): string => {
          return msg.id
        })
      }
    },
    async selectOption(action: number = 0): Promise<void> {
      if (this.selectedMessageIds?.length && action) {
        switch (action) {
          case 1:
            this.popupTexts = {
              title:
                this.selectedMessageIds.length > 1
                  ? this.$t('post.deleteMessages')
                  : this.$t('post.deleteMessage'),
              text:
                this.selectedMessageIds.length > 1
                  ? this.$t('post.deleteMessagesConfirm')
                  : this.$t('post.deleteMessageConfirm'),
            }
            this.showConfirmDelete = true
            break

          case 2:
            if (!this.sent) {
              await this.$axios.put<{}, number, { messages_ids: string[] }>(mailMarkReadEndpoint, {
                messages_ids: this.selectedMessageIds,
              })
              this.loadMessages()
            }
            break

          default:
            if (!this.sent) {
              await this.$axios.put<{}, number, { messages_ids: string[] }>(
                mailMarkUnreadEndpoint,
                {
                  messages_ids: this.selectedMessageIds,
                },
              )
              this.loadMessages()
            }
            break
        }
      }
    },
    async deleteMessages(): Promise<void> {
      if (this.selectedMessageIds?.length) {
        await this.$axios.delete<{}, number, { messages_ids: string[] }>(
          this.sent ? mailSentEndpoint : mailReceivedEndpoint,
          {
            data: { messages_ids: this.selectedMessageIds },
          },
        )

        this.showConfirmDelete = false
        this.loadMessages()
      }
    },
    async loadMore(): Promise<void> {
      if (this.loadNoMore || this.isLoading) {
        return
      }

      this.isLoading = true
      const response = await this.$axios.get<{}, AllMailMessagesResponse>(
        (this.sent ? mailSentEndpoint : mailReceivedEndpoint) + '/offset/' + this.messages.length,
      )

      if (!response.messages?.length) {
        this.loadNoMore = true
        this.isLoading = false
        return
      }

      const loadedMessages = response.messages.map(
        (message: MailMessage): FormattedMailMessage => this.formatMessage(message),
      )
      this.messages = [...this.messages, ...loadedMessages]
      this.isLoading = false
    },
  },
})
</script>
