import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const tunelTrainCheckpoints: CheckpointsObjectInterface = {
  key: 'tunelTrain',
  data: [
    {
      x: 1334.9999953657393,
      y: 277.50000158697367,
      width: 8,
      height: 8,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'trainTurnRightBottom2Top',
        },
      },
    },
  ],
}
