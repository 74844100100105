import type { RewardedItemConfigInterface } from '@/map-phaser-new/interfaces'
import { rewardedItemsPositionsData } from './rewarderItemsPositions'
export const rewardedItems: RewardedItemConfigInterface = {
  textureKey: 'common',
  frameKey: 'helmet',
  scale: 0.22,
  fontData: {
    color: '#ffffff',
    font: 'Roboto Condensed',
    fontStyle: 'bold italic',
    size: 48,
    shadow: {
      offsetX: 1,
      offsetY: 0.5,
      color: 'rgba(0,0,0,0.9)',
      blur: 3,
    },
    outlineColor: '#000000',
    outlineThickness: 3,
  },
  claimEndpoint: 'us/parameter/map-item',
  updateParamEndpoint: 'us/parameter/update/',
  depth: 2,
  useHandCursor: true,
  hitAreaPadding: 100,
  rewardedItemsPositions: rewardedItemsPositionsData,
  claimRewardSound: 'click-map-longTimeReward',
  // ANIMATION WHEN REWARD ITEM IMAGE IS CLICKED
  claimRewardAnimationEase: 'Circ',
  claimRewardAnimationDuration: 250,
  // CLAIMED REWARD TEXT
  claimedRewardTextTint: 0xffffffff,
  claimedRewardTextOriginX: 0.5,
  claimedRewardTextDepth: 2,
  // CLAIMED REWARD ICON
  claimedRewardIconDepth: 2,
  claimedRewardIconScale: 0.6,
  // CLAIMED REWARD ANIMATION OF TEXT AND ICON
  claimedRewardAnimationEase: 'Sine',
  claimedRewardAnimationDelay: 100,
  claimedRewardAnimationScale: 1.1,
  claimedRewardAnimationDuration: 600,
  // CLAIMED REWARD ANIMATION, USED FOR HIDING (TEXT, ICON)
  claimedRewardAnimationCompleteEase: 'Sine.easeOut',
  claimedRewardAnimationCompleteDuration: 400,
  claimedRewardAnimationCompleteAlpha: 0,
  positionIndicatorConfig: {
    xCorrectionValue: 10,
    yCorrectionValue: -40,
  },
}
