<template>
  <div data-cy="gp-warehouse" class="app-page-wrapper absolute">
    <menu-component
      menu-type="training"
      title="Grand Prize"
      :menu-items="{
        1: {
          text: $t('grandPrize.warehouse'),
          route: $getWebView('GrandPrizeWarehouse'),
        },
        2: {
          text: $t('grandPrize.progress'),
          route: $getWebView('GrandPrizeProgress'),
        },
        3: {
          text: $t('grandPrize.history'),
          route: $getWebView('GrandPrizeHistory'),
        },
      }"
    />
    <section class="w-full h-full safe-area">
      <div class="grandprize-warehouse-content flex-col flexing">
        <div
          class="text-36 text-texts-standard-additional flex items-centerjustify-between warehouse-subtitle"
          :class="{
            uppercase: $isWsm,
          }"
        >
          <div class="flex items-center">
            <app-check-box
              :size="$isWsm ? 'md' : 'sm'"
              :is-checked="getNotShowConfirm"
              @checked-action="changedConfirmChecked"
            >
              <p class="pl-5 not-italic">
                {{ $t('common.noConfirmWindow') }}
              </p>
            </app-check-box>
          </div>
          <div class="flex items-center">
            <p
              class="pr-5"
              :class="{
                uppercase: $isSsm,
              }"
            >
              {{ $replacePlaceholder($t('grandPrize.info'), '{grandprize}', GRAND_PRIZE) }}
            </p>
            <app-icon icon-name="question" @click="showPopup = true" />
            <info-popup
              v-if="showPopup"
              :popup-title="$replacePlaceholder($t('grandPrize.info'), '{grandprize}', GRAND_PRIZE)"
              @close="showPopup = false"
            >
              <grand-prize-about />
            </info-popup>
          </div>
        </div>
        <div v-if="grandPrizesData.grandPrizes.size" class="grandprize-warehouse-boxes">
          <div v-for="[key, grandPrizeElement] in grandPrizesData.grandPrizes" :key="key">
            <grand-prize-card
              :badge-type="key"
              :number-of-rewards="grandPrizeElement.grand_prizes_count"
              :insta-open-gems="
                Number(
                  (grandPrizeElement as GrandPrizeWarehouseData).parameters.find(
                    (item: GrandPrizeParameter): boolean =>
                      item.name === 'grand_prize_instant_open_gems_price',
                  )?.value,
                )
              "
              :level="grandPrizeElement.level"
              @buttons-action="openButtonClicked"
            />
          </div>
        </div>

        <component-loading :height="'40rem'" :is-loading="grandPrizesData.grandPrizes.size === 0" />
        <grand-prize-last-opened
          v-if="grandPrizesData.grandPrizes.size"
          ref="lastOpened"
          :remaining-ads="grandPrizesData.remainingAds"
          :total-ads="grandPrizesData.totalAds"
        />
      </div>
    </section>
    <confirm-popup
      v-if="showRewardsPopup"
      :button-data="openInstaRewardPopUp.confirmButtonData"
      :popup-title="$t(openInstaRewardPopUp.title)"
      :button-text-left="$t(openInstaRewardPopUp.buttonText)"
      :button-text-right="openInstaRewardPopUp.gems"
      @action="openGrandPrize(true)"
      @close="showRewardsPopup = false"
    >
      <div class="main text-white">
        {{ $t(openInstaRewardPopUp.text) }}
      </div>
    </confirm-popup>
  </div>
</template>

<script lang="ts">
import GrandPrizeAbout from '@/components/GrandPrize/GrandPrizeAbout.vue'
import GrandPrizeCard from '@/components/GrandPrize/GrandPrizeCard.vue'
import GrandPrizeLastOpened from '@/components/GrandPrize/GrandPrizeLastOpened.vue'
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import {
  GRAND_PRIZE,
  GRAND_PRIZE_OPENED,
  GRAND_PRIZE_UNOPENED,
  grandPrizeEndPoint,
  grandPrizeInstantEndPoint,
  grandPrizesEndPoint,
} from '@/globalVariables'
import { convertNameValue } from '@/helpers'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type {
  GrandPrizeInstantApiResponse,
  GrandPrizeTierApiResponse,
  GrandPrizeTierInfo,
  OpenGrandPrizeApiResponse,
  GrandPrizeWarehouseData,
} from '@/interfaces/responses/grandPrize/GrandPrizeApiResponse'

interface ComponentData {
  GRAND_PRIZE: typeof GRAND_PRIZE
  grandPrizesData: {
    grandPrizes: Map<string, GrandPrizeWarehouseData | GrandPrizeTierInfo>
    remainingAds: number
    totalAds: number
  }
  showRewardsPopup: boolean
  openInstaRewardPopUp: {
    text: string
    buttonText: string
    title: string
    gems: number
    confirmButtonData: {
      btnId: string
      btnType: string
      btnSize: string
      customLeftWidth: number
    }
  }
  showPopup: boolean
  clickedCardType: string
}

export default defineComponent({
  name: 'GrandPrizeWarehouse',
  components: {
    GrandPrizeCard,
    InfoPopup,
    GrandPrizeAbout,
    GrandPrizeLastOpened,
    ConfirmPopup,
  },
  data(): ComponentData {
    return {
      GRAND_PRIZE,
      grandPrizesData: {
        grandPrizes: new Map(),
        remainingAds: 0,
        totalAds: 0,
      },
      showRewardsPopup: false,
      openInstaRewardPopUp: {
        text: 'grandPrize.getAllRewardsConfirm',
        buttonText: 'grandPrize.openInstant',
        title: 'grandPrize.getAllRewards',
        gems: 0,
        confirmButtonData: {
          btnId: 'gp-rewards-confirm-btn',
          btnType: 'credit',
          btnSize: 'md',
          customLeftWidth: 20,
        },
      },
      showPopup: false,
      clickedCardType: '',
    }
  },
  computed: {
    ...mapState(useUserStore, {
      getNotShowConfirm: 'getNotShowConfirm',
    }),
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
    }),
  },
  created(): void {
    this.loadGrandPrizes()
  },
  methods: {
    ...mapActions(useUserStore, ['setNotShowConfirm']),
    changedConfirmChecked(): void {
      this.setNotShowConfirm()
    },
    showAboutPopUp(): void {
      this.$refs.aboutPopUp.showPopUp()
    },
    openButtonClicked(badgeType: string, gems: number): void {
      this.clickedCardType = badgeType
      if (!this.getNotShowConfirm && gems) {
        this.openInstaRewardPopUp.gems = gems
        this.showRewardsPopup = true
        return
      }
      this.openGrandPrize(gems !== undefined)
    },
    async loadGrandPrizes(): Promise<void> {
      const response = await this.$axios.get<{}, GrandPrizeTierApiResponse>(grandPrizesEndPoint)
      const isOpened = (gp: OpenGrandPrizeApiResponse): boolean => {
        let isOpened = false
        if (gp.item_state === GRAND_PRIZE_OPENED) {
          this.$router.push({
            name: this.$getWebView('GrandPrizeMain'),
            params: { id: gp.id },
          })
          isOpened = true
        }
        return isOpened
      }
      for (const gpElement of response.grand_prizes) {
        if (gpElement.grand_prizes.some(isOpened)) {
          return
        }
      }
      this.grandPrizesData = {
        grandPrizes: new Map(
          response.grand_prizes.map((obj: GrandPrizeTierInfo): [string, GrandPrizeTierInfo] => {
            const params = convertNameValue(obj.parameters)
            return [params.grand_prize_name, obj]
          }),
        ),
        remainingAds: response.remaining_ads,
        totalAds: response.total_ads,
      }
    },
    async openGrandPrize(isInsta: boolean): Promise<void> {
      this.showRewardsPopup = false
      const mainView =
        this.actualStageData?.name === 'clickOnOpenGrandPrize'
          ? 'GrandPrizeMainTutorial'
          : 'GrandPrizeMain'
      const unopenedGps = this.grandPrizesData.grandPrizes
        .get(this.clickedCardType)
        .grand_prizes.filter(
          (gp: OpenGrandPrizeApiResponse): boolean => gp.item_state === GRAND_PRIZE_UNOPENED,
        )
      const firstUnopenedGpId = unopenedGps[0].id
      const response = await this.$axios.put<{}, GrandPrizeInstantApiResponse>(
        isInsta ? grandPrizeInstantEndPoint : grandPrizeEndPoint,
        {
          grand_prize_id: firstUnopenedGpId,
        },
      )

      if (!isInsta) {
        this.$router.push({
          name: this.$getWebView(mainView),
          params: { id: firstUnopenedGpId },
        })
        return
      }
      this.$refs.lastOpened.updateLastOpenedGp(response.grand_prize)
      this.loadGrandPrizes()
    },
  },
})
</script>

<style lang="scss" scoped>
.grandprize-warehouse-content {
  .warehouse-subtitle {
    margin: 2rem 3rem 0 3rem;
    width: 98%;
    justify-content: space-between;
  }

  .grandprize-warehouse-boxes {
    width: 98%;
    display: flex;
    justify-content: space-between;
  }
}
</style>
