import type { App } from 'vue'

export const translateDiscipline = (key: string | number): string => {
  const textsFromSS = JSON.parse(sessionStorage.getItem('texts'))
  if (!textsFromSS) return ''
  const newKey = 'discipline.discipline_' + key
  if (newKey in textsFromSS) {
    return textsFromSS[newKey] as string
  }
  return ''
}

export default {
  install: (app: App): void => {
    app.config.globalProperties.$translateDiscipline = translateDiscipline
  },
}
