<template>
  <div
    v-if="rightboxes?.length || announcements?.length || isBoardGamesEventRightBoxActive"
    class="main-rightbox absolute"
  >
    <app-scrollbar
      width="100%"
      :height="maxHeight"
      scroll="y"
      slide="y"
      :vertical-arrows="
        rightboxes.length + announcements?.length + (isBoardGamesEventRightBoxActive ? 1 : 0) > 3
      "
      :hide-scrollbar="true"
      :arrow-click-scroll-size="88"
    >
      <div class="rightbox-wrapper" :style="{ maxHeight: maxHeight }">
        <announcements-box
          v-for="(announcement, i) in announcements"
          :key="i"
          :announcement="announcement"
        />
        <board-games-event-r-box />
        <r-box v-for="(box, i) in rightboxes" :key="i" :box="box" @reload-boxes="loadBoxData()" />
      </div>
    </app-scrollbar>
  </div>
</template>

<script lang="ts">
import { DESTROY_PIGGY_BANK_OSRA } from '@/map-phaser-new/constants'
import BoardGamesEventRBox from '@/components/RightBox/BoardGamesEventRBox.vue'
import RBox from '@/components/RightBox/RBox.vue'
import AnnouncementsBox from '@/components/RightBox/AnnouncementsBox.vue'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { useBoardGamesEventStore } from '@/store/pinia/boardGamesEventStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { isPiggyBank } from '@/helpers'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
// https://github.com/lukaszflorczak/vue-agile

interface BoxReloadPayload {
  storeId?: string
}

interface ComponentData {
  isLoading: boolean
  maxHeight: string
}

export default defineComponent({
  name: 'RightboxComponent',
  components: {
    BoardGamesEventRBox,
    RBox,
    AnnouncementsBox,
  },
  data(): ComponentData {
    return {
      isLoading: false,
      maxHeight: '0',
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      rightboxes: 'getVisibleRightBoxes',
      announcements: 'getAnnouncements',
    }),
    ...mapState(usePhaserGameIntegrationStore, { phaserEventEmitter: 'getPhaserEventEmitter' }),
    ...mapState(useBoardGamesEventStore, ['isEventTeaserActive', 'isEventActive']),
    ...mapState(useTutorialStore, { isTutorial: 'getIsTutorial' }),
    isBoardGamesEventRightBoxActive(): boolean {
      // Rovnaka podmienka ako ma v sebe samotny right box.
      return (this.isEventTeaserActive || this.isEventActive) && !this.isTutorial
    },
  },
  watch: {
    rightboxes(): void {
      if (
        this.rightboxes?.length ||
        this.announcements?.length ||
        this.isBoardGamesEventRightBoxActive
      ) {
        this.$nextTick((): void => {
          this.maxHeight = this.getMaxHeight()
        })
      }
    },
  },
  async created(): Promise<void> {
    await this.loadBoxData()

    // mobile po otvoreni/zatvoreni budovy sa udeje create
    if (
      this.rightboxes?.length ||
      this.announcements?.length ||
      this.isBoardGamesEventRightBoxActive
    ) {
      this.$nextTick((): void => {
        this.maxHeight = this.getMaxHeight()
      })
    }
  },
  methods: {
    ...mapActions(usePremiumStore, ['loadRightboxes', 'loadAnnouncements']),
    async loadBoxData(box?: BoxReloadPayload): Promise<void> {
      if (box?.storeId && isPiggyBank({ store_id: box.storeId })) {
        this.phaserEventEmitter.emit(DESTROY_PIGGY_BANK_OSRA)
      }

      if (!this.isLoading) {
        this.isLoading = true
        // Pri prichode z klubovej mapy nepotrebujeme aktualizovat RB.
        await Promise.all([this.loadRightboxes(false), this.loadAnnouncements(true)])
        this.isLoading = false
      }
    },
    getMaxHeight(): string {
      const windowHeight = window.innerHeight

      // rightbox content wrapper
      const rightboxTop = document.querySelector('.main-rightbox')
      const rightboxTopHeight = rightboxTop ? rightboxTop.getBoundingClientRect().top : 0

      const mainFooterElement = document.querySelector('.main-footer')
      const mainFooterHeight = mainFooterElement
        ? mainFooterElement.getBoundingClientRect().height
        : 0

      let safeAreaInsetBottom = 0

      if (mainFooterElement) {
        const style = window.getComputedStyle(mainFooterElement)

        // dynamic values are received on mobile, desktop is 0
        safeAreaInsetBottom = parseInt(style.getPropertyValue('--safe-area-inset-bottom'), 10)
      }

      const arrowsHeightConstant = this.$isMobile() ? 40 : 60

      const maxHeight =
        windowHeight -
        rightboxTopHeight -
        mainFooterHeight -
        safeAreaInsetBottom -
        arrowsHeightConstant

      return `${maxHeight}px`
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/rightbox.scss';

.mask-mobile {
  mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
  -webkit-mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
}

.wsm-scrollbar::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.rightbox-wrapper .r-box:not(:first-child) {
  margin-top: 1.8rem;
}
</style>
