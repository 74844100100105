<template>
  <div
    :class="`club-building-panel bordered-1 type-${
      isStadiumType || isClubhouseType ? buildingType : 'building'
    }`"
  >
    <header class="club-building-panel-header flexing">
      <p class="uppercase text-40 text-texts-standard-important">
        {{ isStadiumType || isClubhouseType ? levelString : $t('common.building') }}
      </p>
    </header>
    <main class="club-building-panel-main flexing flex-col">
      <section v-if="isStadiumType" class="club-panel-income flexing flex-col">
        <p class="club-panel-income-text uppercase text-36 text-texts-standard-default font-bold">
          {{ $t('club.regularIncome') }}
        </p>
        <div class="club-panel-income-value">
          <clubs-periodic-payment-box
            :reward-value="dataForUpgrade.currentValue"
            reward-type="money"
            :reward-interval="dataForUpgrade.time"
            :reward-size="30"
            :reward-icon-size="48"
          />
        </div>
      </section>
      <div
        class="club-building-panel-image"
        :style="{
          '--background-image-path': `url(${pathImages}clubs/buildings/building-${buildingString}.avif)`,
        }"
      />
      <p
        v-if="isClubhouseType"
        class="text-36 text-texts-standard-default font-bold"
        v-html="
          $replacePlaceholder(
            $replaceHtmlPlaceholders(
              $replacePlaceholder($t('club.clubhouseFor'), '%s', '{b} %s {/b}'),
              'b',
              'text-texts-standard-important',
            ),
            '%s',
            dataForUpgrade.currentValue,
          )
        "
      />
    </main>
    <clubs-building-panel-clubhouse-footer
      v-if="isClubhouseType || isStadiumType"
      :data-for-upgrade="dataForUpgrade"
      @do-action-footer="$emit('doAction')"
    />
    <clubs-building-panel-building-footer
      v-if="isBuildingType"
      :building-name="buildingType"
      @do-action-footer="$emit('doAction')"
    />
  </div>
</template>

<script lang="ts">
import ClubsBuildingPanelBuildingFooter from '@/components/Club/ClubsBuildingPanelBuildingFooter.vue'
import ClubsBuildingPanelClubhouseFooter from '@/components/Club/ClubsBuildingPanelClubhouseFooter.vue'
import ClubsPeriodicPaymentBox from '@/components/Club/ClubsPeriodicPaymentBox.vue'
import {
  CLUBARENA,
  CLUBHOUSE,
  DIRECTORATE,
  FITNESS,
  HOTEL,
  MEDIA,
  MEDICAL,
  PARKING,
  RELAX,
  RESTAURANT,
  SWIMMING,
  WELLNESS,
  pathImages,
} from '@/globalVariables'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { ClubUpgradeData } from '@/interfaces/clubs/Clubs'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'ClubsBuildingPanel',
  components: {
    ClubsBuildingPanelClubhouseFooter,
    ClubsBuildingPanelBuildingFooter,
    ClubsPeriodicPaymentBox,
  },
  props: {
    buildingType: {
      type: String,
      required: true,
      validator(value: string) {
        return [
          CLUBHOUSE,
          CLUBARENA,
          DIRECTORATE,
          FITNESS,
          HOTEL,
          MEDIA,
          MEDICAL,
          PARKING,
          RELAX,
          RESTAURANT,
          SWIMMING,
          WELLNESS,
        ].includes(value)
      },
    },
    dataForUpgrade: {
      type: Object as PropType<Nullable<ClubUpgradeData>>,
      default: () => null,
    },
  },
  emits: ['doAction'],
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    isStadiumType(): boolean {
      return this.buildingType === CLUBARENA
    },
    isBuildingType(): boolean {
      const buildings = [
        'directorate',
        'fitness',
        'hotel',
        'media',
        'medical',
        'parking',
        'relax',
        'restaurant',
        'swimming',
        'wellness',
      ]
      return buildings.includes(this.buildingType)
    },
    isClubhouseType(): boolean {
      return this.buildingType === CLUBHOUSE
    },
    levelString(): string {
      const translatedLevelString = this.$t('minigame.level')
      let levelString = ''
      if (this.isStadiumType) {
        levelString = this.$replacePlaceholder(
          translatedLevelString,
          '%s',
          this.dataForUpgrade.level,
        )
      }
      if (this.isClubhouseType) {
        levelString = this.$replacePlaceholder(
          translatedLevelString,
          '%s',
          this.dataForUpgrade.level,
        )
      }
      return levelString
    },
    buildingString(): string {
      let buildingString = ''
      switch (this.buildingType) {
        case CLUBARENA:
          buildingString = `${this.buildingType}-${this.dataForUpgrade.level}`
          break
        case CLUBHOUSE:
          buildingString = `${this.buildingType}-${this.dataForUpgrade.level}`
          break
        default:
          buildingString = this.buildingType
      }
      return buildingString.toLowerCase()
    },
  },
})
</script>

<style lang="scss" scoped>
.club-building-panel {
  width: 34.25rem;
  height: 52.188rem;
  border: 0.063rem solid #5ba3dc;
  background: url($path-clubs + 'components/leftbox/bg-left-box--glowless.avif') 0 0 no-repeat;
  background-size: cover;

  &-main {
    & > * {
      z-index: 1;
    }
  }

  &-header {
    width: 100%;
    height: 3.75rem;
    background: url($path-clubs + 'components/leftbox/bg-left-box-header.avif') 0 0 no-repeat;
    background-size: cover;
  }

  &-image {
    position: relative;
    width: 30rem;
    height: 21.25rem;
    z-index: 0;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 10%;
      width: 100%;
      height: 50%;
      border-radius: 50%;
      filter: blur(4rem);
      z-index: -1;

      @if $isWsm {
        background: #2794c1;
      }

      @if $isSsm {
        background: #6e71a8;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--background-image-path);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;
    }
  }

  &.type {
    &-clubHouse,
    &-building {
      .club-building-panel-image {
        margin-top: 5.5rem;
      }
    }
  }
}

.club-panel-income {
  &-text {
    margin-top: 3.125rem;
  }

  &-value {
    width: 19.5rem;
    height: 4.063rem;
    margin-top: 1.375rem;
  }
}
</style>
