import { sortArrayByObjectValue } from '@/helpers'
import { LineDirection, SkillType, LineHeight } from '@/interfaces/lab/Cell'
import type { Cell, CellInput, Improvement } from '@/interfaces/lab/Cell'
import type ParamStatReward from '@/interfaces/lab/ParamStatReward'

export const mapResearchCellData = (cell: CellInput, branchName: string = ''): Cell => {
  const position = cell.parameters.find(
    (item: ParamStatReward): boolean => item.name === 'position',
  )?.value
  return {
    id: cell.id,
    branchName: branchName,
    parent:
      cell.parameters.find((item: ParamStatReward): boolean => item.name === 'parent_gdd_id')
        ?.value ?? null,
    placement: position === 1 ? LineDirection.Left : position === 2 ? LineDirection.Right : null,
    gddId: cell.parameters.find((item: ParamStatReward): boolean => item.name === 'gdd_id').value,
    state: cell.state?.name,
    name: cell.parameters.find((item: ParamStatReward): boolean => item.name === 'name').value,
    description: cell.parameters.find((item: ParamStatReward): boolean => item.name === 'name')
      .value,
    currentLevel: cell.stats?.find(
      (item: ParamStatReward): boolean => item.name === 'researched_level',
    ).value,
    maxLevel: cell.parameters.find((item: ParamStatReward): boolean => item.name === 'level').value,
    isNew:
      !(cell.state?.name !== 'unlocked' && cell.state?.name.includes('locked')) &&
      cell.stats?.find((item: ParamStatReward): boolean => item.name === 'seen').value === false,
    icon: `icon_${
      cell.parameters.find((item: ParamStatReward): boolean => item.name === 'cell_icon')?.value
    }`,
    gemOnly: cell.parameters.find((item: ParamStatReward): boolean => item.name === 'premium')
      ?.value,
    requiredForResearch: {
      money: cell.parameters.find((item: ParamStatReward): boolean => item.name === 'price_cash')
        ?.value,
      researchPoints: cell.parameters.find((item: ParamStatReward) => item.name === 'price_rp')
        ?.value,
      gems: cell.parameters.find((item: ParamStatReward): boolean => item.name === 'price_gems')
        ?.value,
    },
    researchTimeDuration: cell.parameters.find(
      (item: ParamStatReward): boolean => item.name === 'research_time_duration',
    )?.value,
    locked: cell.state?.name !== 'unlocked' && cell.state?.name.includes('locked'),
    unlockedAfterLevel: cell.parameters.find(
      (item: ParamStatReward): boolean => item.name === 'unlock_level',
    )?.value,
    improvements: sortArrayByObjectValue(cell.rewards, 'name') as Improvement[],
    affectedDisciplines: cell.parameters.find(
      (item: ParamStatReward): boolean => item.name === 'applies_for_disciplines',
    )?.value,
    effectDuration: cell.parameters.find(
      (item: ParamStatReward): boolean => item.name === 'duration',
    )?.value,
    repeatable: !!cell.parameters.find(
      (item: ParamStatReward): boolean => item.name === 'repeatable',
    )?.value,
    isIconRepeatable: cell.parameters.find(
      (item: ParamStatReward) => item.name === 'is_icon_repeatable',
    )
      ? true
      : null,
    blocksCells: cell.parameters.find(
      (item: ParamStatReward): boolean => item.name === 'blocks_cells',
    )?.value,
    researchInProgress: !!cell.remaining_time,
    researchTimer: cell.remaining_time,
    speedupPrice: cell.speedup_price,
    estimatedDate: cell.stats?.find(
      (item: ParamStatReward): boolean => item.name === 'speedup_price',
    )?.value,
    positionLevel: cell.positionLevel ?? 0,
    lineSize: cell.lineSize ?? LineHeight.Sm,
    skillType: cell.skillType === SkillType.Camps ? SkillType.Camps : SkillType.Research,
  }
}

export const currentlyResearchedSkillCount = (tree: Cell[][]): number => {
  return (
    tree.reduce((acc: number, branch: Cell[]) => {
      const cellsResearchedLevelsAmount =
        branch.reduce((amount: number, cell: Cell): number => {
          amount += cell.currentLevel ? Number(cell.currentLevel) : 0
          return amount
        }, 0) ?? 0
      acc += cellsResearchedLevelsAmount ?? 0
      return acc
    }, 0) ?? 0
  )
}

export const allSkillsCount = (tree: Cell[][]): number => {
  return (
    tree.reduce((acc: number, branch: Cell[]) => {
      const cellsLevelsAmount =
        branch.reduce((amount: number, cell: Cell): number => {
          amount += cell.maxLevel ? Number(cell.maxLevel) : 0
          return amount
        }, 0) ?? 0
      acc += cellsLevelsAmount ?? 0
      return acc
    }, 0) ?? 0
  )
}

export const currentlyFinishedSkillCount = (tree: Cell[][]): number => {
  return (
    tree.reduce((acc: number, branch: Cell[]) => {
      const finishedCellsAmount = branch.filter((cell: Cell): boolean => {
        return cell.state === 'researched'
      })?.length
      acc += finishedCellsAmount ?? 0
      return acc
    }, 0) ?? 0
  )
}

export const newUnlockedCells = (tree: Cell[][]): Cell['id'][] => {
  return tree.flatMap((branch: Cell[]): Cell['id'][] => {
    return branch
      .filter((cell: Cell): boolean => {
        return !cell.locked && cell.isNew
      })
      ?.map((cell: Cell): Cell['id'] => {
        return cell.id
      })
  })
}
