import type { App, Plugin } from 'vue'

type Timer = ReturnType<typeof setTimeout>
let timeoutID: Timer
/**
 * Odsunie event na posledny volany a ten zavola po delay
 * @param callbackFn - funkcia co treba volat
 * @param args - argumenty ktore idu do danej funkcie
 * @param delay - cas odsunutia
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = (
  callbackFn: CallableFunction,
  args: Array<unknown> = [],
  delay: number = 500,
): void => {
  clearTimeout(timeoutID)
  timeoutID = setTimeout((): Timer => callbackFn(...(Array.isArray(args) ? args : [args])), delay)
}

export const debouncePlugin: Plugin = {
  install: (app: App): void => {
    app.config.globalProperties.$debounce = debounce
  },
}
